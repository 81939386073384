import React, { useState, useRef } from 'react';
import { shape, func, node, string } from 'prop-types';
import './drag-drop-list.scss';

function DragDropList(props) {
  const { children, items, emptyMessage, onChange } = props;

  const [currentListData, setCurrentListData] = useState(items);

  const draggingItem = useRef();
  const dragOverItem = useRef();

  const handleDragOver = (e) => e.preventDefault();

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
  };

  const handleDragEnter = (e, position) => {
    dragOverItem.current = position;
    const listCopy = [...currentListData];
    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1);
    listCopy.splice(dragOverItem.current, 0, draggingItemContent);

    draggingItem.current = dragOverItem.current;
    dragOverItem.current = null;
    setCurrentListData(listCopy);
  };

  const handleDragEnd = () => {
    // when drag end, set data rank change by page
    setCurrentListData((newList) => [...newList]);
    onChange(currentListData);
    draggingItem.current = null;
  };

  return (
    <div className="drag-drop-list">
      <div className="text-center">
        {items.length <= 0 && emptyMessage && emptyMessage}
      </div>
      <ul>
        {currentListData.map((item, index) => {
          const key = `item_${index}`;
          const cls =
            draggingItem?.current === Number(index) ? 'item-dragging' : '';
          return (
            <li
              draggable
              key={key}
              className={cls}
              onDragOver={handleDragOver}
              onDragStart={(e) => handleDragStart(e, index)}
              onDragEnter={(e) => handleDragEnter(e, index)}
              onDragEnd={handleDragEnd}
            >
              {React.cloneElement(children, { ...item })}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

DragDropList.propTypes = {
  items: shape([]).isRequired,
  children: node.isRequired,
  emptyMessage: string,
  onChange: func,
};

DragDropList.defaultProps = {
  emptyMessage: '',
  onChange: () => {},
};

export default DragDropList;
