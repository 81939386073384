import { call, put, throttle, takeLatest } from 'redux-saga/effects';
import LtvOtherApi from 'services/api/LtvOtherApi';
import handleError from 'services/error/handleScopeError';
import types from './types';
import actions from './actions';

function* fetch() {
  try {
    yield put(actions.requestStart());
    const response = yield call(LtvOtherApi.fetch);
    const otherSetting = response.data.data.detail;
    const data = {
      newReJudgmentPeriod: otherSetting.new_rejudgment_period,
      orderIdOption: otherSetting.order_id,
      memberIdOption: otherSetting.member_id,
    };
    yield put(actions.fetched({ data }));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

function* update(action) {
  try {
    yield put(actions.requestStart());
    // eslint-disable-next-line no-unused-vars
    const data = {
      data: {
        new_rejudgment_period: action.payload.newReJudgmentPeriod,
        order_id: action.payload.orderIdOption,
        member_id: action.payload.memberIdOption,
      },
    };
    yield call(LtvOtherApi.update, data);

    yield put(actions.fetch());
    // yield put(actions.updated(data));
  } catch (error) {
    yield put(
      actions.requestFailed({ error: handleError(error.response, [400, 403]) })
    );
    yield put(actions.fetch());
  }
}

export default function* watchView() {
  yield takeLatest(types.FETCH, fetch);
  yield throttle(1000, types.UPDATE, update);
}
