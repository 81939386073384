import types from 'store/customview/types';
import helper from 'store/helper';

const requestStart = () => helper.createAction(types.REQUEST_START);
const requestFailed = (error, scope = null) =>
  helper.createAction(types.REQUEST_FAILED, error, scope);
const requestStartForView = () =>
  helper.createAction(types.REQUEST_START_FOR_VIEW);
const requestFailedForView = (error, scope = null) =>
  helper.createAction(types.REQUEST_FAILED_FOR_VIEW, error, scope);
const setLoadedViewTab = () => helper.createAction(types.SET_LOADED_VIEW_TAB);
const fetchSucceeded = (data) =>
  helper.createAction(types.FETCH_SUCCEEDED, data);
const fetchEditSucceeded = (data) =>
  helper.createAction(types.FETCH_EDIT_SUCCEEDED, data);
const fetchSwitchSucceeded = (data) =>
  helper.createAction(types.FETCH_SWITCH_SUCCEEDED, data);
const fetchListSucceeded = (data) =>
  helper.createAction(types.FETCH_LIST_SUCCEEDED, data);
const createSucceeded = (data) =>
  helper.createAction(types.CREATE_SUCCEEDED, data);
const updateSucceeded = (data) =>
  helper.createAction(types.UPDATE_SUCCEEDED, data);
const removeSucceeded = (data) =>
  helper.createAction(types.DELETE_SUCCEEDED, data);
const applySucceeded = (data) =>
  helper.createAction(types.APPLY_SUCCEEDED, data);
const applyIsChanged = (data) =>
  helper.createAction(types.APPLY_IS_CHANGED, data);
const toggleFavoriteSucceeded = (data) =>
  helper.createAction(types.TOGGLE_FAVORITE_SUCCEEDED, data);
const fetch = (data) => helper.createAction(types.FETCH, data);
const fetchEdit = (data) => helper.createAction(types.FETCH_EDIT, data);
const fetchList = (data) => helper.createAction(types.FETCH_LIST, data);
const create = (data) => helper.createAction(types.CREATE, data);
const update = (data) => helper.createAction(types.UPDATE, data);
const overwrite = (data) => helper.createAction(types.OVERWRITE, data);
const remove = (id) => helper.createAction(types.DELETE, id);
const apply = (data) => helper.createAction(types.APPLY, data);
const toggleFavorite = (data) =>
  helper.createAction(types.TOGGLE_FAVORITE, data);

export default {
  requestStart,
  requestFailed,
  requestStartForView,
  requestFailedForView,
  setLoadedViewTab,
  fetchSucceeded,
  fetchEditSucceeded,
  fetchSwitchSucceeded,
  fetchListSucceeded,
  createSucceeded,
  updateSucceeded,
  removeSucceeded,
  applySucceeded,
  applyIsChanged,
  toggleFavoriteSucceeded,
  fetchEdit,
  fetch,
  fetchList,
  create,
  update,
  overwrite,
  remove,
  apply,
  toggleFavorite,
};
