import { API_DATE_FORMAT } from 'domain/consts';
import moment from 'moment';
import { arrayOf, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import EbisSingleDatePickerInput from 'views/molecules/single-date-picker-input';
import './filterTypes.scss';

function PeriodFilter(props) {
  const { onChange, presets, minDate, maxDate } = props;

  const [presetStart, presetEnd] = presets.values || [];
  const [startDate, setStartDate] = useState(
    (presetStart && moment(presetStart)) || undefined
  );
  const [endDate, setEndDate] = useState(
    (presetEnd && moment(presetEnd)) || undefined
  );

  const onDateChange = (dates, type) => {
    let [start, end] = dates;
    if (start && end && start.isAfter(end)) {
      if (type === 'startDate') {
        end = start;
      } else {
        start = end;
      }
    }

    // There are chances that either start or end date is undefined
    const newStart = start?.format(API_DATE_FORMAT) || '';
    const newEnd = end?.format(API_DATE_FORMAT) || '';
    setStartDate(start);
    setEndDate(end);
    onChange([newStart, newEnd]);
  };

  return (
    <Form inline className="filter-type__period">
      <Form.Group>
        <EbisSingleDatePickerInput
          date={startDate}
          minDate={moment(minDate)}
          maxDate={moment(maxDate)}
          onChange={(date) => onDateChange([date, endDate], 'startDate')}
        />
        <span>～</span>
        <EbisSingleDatePickerInput
          date={endDate}
          minDate={moment(minDate)}
          maxDate={moment(maxDate)}
          onChange={(date) => onDateChange([startDate, date], 'endDate')}
        />
      </Form.Group>
    </Form>
  );
}

PeriodFilter.propTypes = {
  onChange: func,
  presets: shape({ values: arrayOf(string) }),
  minDate: string,
  maxDate: string,
};

PeriodFilter.defaultProps = {
  onChange: () => {},
  presets: {},
  minDate: '2004/01/01',
  maxDate: `${new Date().getFullYear() + 5}/12/31`,
};

export default PeriodFilter;
