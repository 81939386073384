const FETCH_REFLECTION_TIME_START =
  'reflectiontime/FETCH_REFLECTION_TIME_START';
const FETCH_REFLECTION_TIME_SUCCESS =
  'reflectiontime/FETCH_REFLECTION_TIME_SUCCESS';
const FETCH_REFLECTION_TIME_ERROR =
  'reflectiontime/FETCH_REFLECTION_TIME_ERROR';

export default {
  FETCH_REFLECTION_TIME_START,
  FETCH_REFLECTION_TIME_SUCCESS,
  FETCH_REFLECTION_TIME_ERROR,
};
