import React, { useRef } from 'react';
import { shape, func, bool, string } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import ViewDetail from 'views/pages/tag-management/components/ViewDetail/ViewDetail';

function ViewDetailModal({
  handleCloseModal,
  openDetail,
  infoDetail,
  currentTab,
  isLoading,
}) {
  const { isCapiSingleContract } = useCheckPermissionCapi();

  const scrollbarWrapperRef = useRef(null);

  return (
    <Modal
      show={openDetail}
      centered
      size="w800"
      dialogClassName="modal-tag-detail"
    >
      <Modal.Header className="justify-content-start">
        <Modal.Title>タグ詳細</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={scrollbarWrapperRef}>
        <DataSyncLoader isLoading={isLoading}>
          <ScrollbarWrapper
            ref={scrollbarWrapperRef}
            maxContent={533}
            alignScroll={-20}
          >
            <ViewDetail
              infoDetail={infoDetail}
              currentTab={currentTab}
              isCapiSingle={isCapiSingleContract}
            />
          </ScrollbarWrapper>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="link" onClick={handleCloseModal}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ViewDetailModal.propTypes = {
  handleCloseModal: func.isRequired,
  openDetail: bool.isRequired,
  currentTab: string.isRequired,
  infoDetail: shape({}).isRequired,
  isLoading: bool.isRequired,
};

export default ViewDetailModal;
