import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  checkIsDataExport,
  checkIsRegularReport,
  formatDataListViewHistory,
} from 'services/data-export/dataExportService';
import settingsSelectors from 'store/settings/selectors';
import masterdataSelectors from 'store/master-data/selectors';
import { CALENDAR_DAY_FORMAT } from 'domain/consts';
import {
  REPORT_TYPE_CHANNEL,
  REPORT_TYPE_CATEGORY,
  DEFAULT_DETAIL_REPORT,
  REPORT_TYPE_AD_REPO,
  REPORT_FIELDS,
  REPORT_TYPE_PERIOD,
  REPORT_TYPE_CATEGORY_PERIOD,
  REPORT_TYPE_DETAIL_PERIOD,
  TIME_AXIS_DAILY,
} from 'domain/data-export/consts';
import { SORT_DIRECTION_ASC } from 'domain/settings/display-items';
import {
  getPermissions,
  checkAllowedSettingReport,
} from 'store/auth/selectors';
import { SETTING_SYSTEM_EDIT } from 'domain/permissions/contractPermissions';
import { MEMBER_NAME, AMOUNT } from 'domain/fields';

const {
  DIMENSIONS,
  METRICS,
  DISPLAY_ITEMS,
  REPORT_CHANNEL,
  PERIOD_START,
  PERIOD_END,
  REPORT_NAME,
  REPORT_TYPE,
  SERVICE_STORE,
  SUMMARY_MODE,
} = REPORT_FIELDS;

const getList = (state) => state.cache.dataExport.data.list.items;
const getSettings = (state) => state.cache.dataExport.settings;
const getStatusList = (state) => state.cache.dataExport.status.list;
const getMetadata = (state) => state.cache.dataExport.status.metadata;

const getPagination = (state) => state.cache.dataExport.settings.pagination;
const getSort = (state) => state.cache.dataExport.settings.sort;

const getListHistory = (state) => state.cache.dataExport.data.history.items;
const getStatusListHistory = (state) => state.cache.dataExport.status.history;

const getDataSystemSync = (state) =>
  state.cache.dataExport.settings.statusSystemSync;
const getStatusSystemSync = (state) => state.cache.dataExport.status.systemSync;

const getSortRequest = createSelector([getSort], (sort) => {
  const { field, direction } = sort;
  return `${direction === SORT_DIRECTION_ASC ? '' : '-'}${field}`;
});

const getStatesForList = createSelector(
  [getList, getSettings, settingsSelectors.getLoadingByStatus([getStatusList]), getDataSystemSync],
  (listData, settings, isLoading, statusSystemSync) => ({ listData, settings, isLoading, statusSystemSync })
);

const getStatesForListHistory = createSelector(
  [
    getPermissions,
    getListHistory,
    settingsSelectors.getLoadingByStatus([getStatusListHistory]),
  ],
  (permissions, lists, isLoadingListHistory) => {
    const isSettingSystem = permissions.includes(SETTING_SYSTEM_EDIT);
    const listHistory = formatDataListViewHistory(lists, isSettingSystem);

    return { isLoadingListHistory, listHistory };
  }
);

const getDetail = (state) => state.cache.dataExport.data.detail.item;
const getStatusDetail = (state) => state.cache.dataExport.status.detail;

const getMasterdata = (state) => state.cache.dataExport.data.masterData;
const getStatusMasterdata = (state) => state.cache.dataExport.status.masterData;

const getDefaultReportName = (state) =>
  state.cache.dataExport.data.masterData.reportName;

const getConversion = createSelector(
  [
    settingsSelectors.getLoadingByStatus([masterdataSelectors.getStatus]),
    masterdataSelectors.getCv,
    masterdataSelectors.getSearchResult,
    masterdataSelectors.getSearchType,
  ],
  (isLoading, defaultItems, searchItems, searchType) => {
    const items = searchType === 'conv_id' ? searchItems : defaultItems;

    return {
      isLoading,
      defaultOptions: Object.keys(defaultItems).reduce(
        (acc, key) => ({
          ...acc,
          [key]: { value: defaultItems[key].id, label: defaultItems[key].name },
        }),
        {}
      ),
      displayOptions: Object.keys(items).reduce(
        (acc, key) => ({
          ...acc,
          [key]: { value: items[key].id, label: items[key].name },
        }),
        {}
      ),
    };
  }
);

const getStatesForSystemSync = createSelector(
  [
    getDataSystemSync,
    settingsSelectors.getLoadingByStatus([getStatusSystemSync]),
  ],
  (statusSystemSync, isFetchingDataSystemSync) => ({
    statusSystemSync,
    isFetchingDataSystemSync,
  })
);

const getDefaultReportType = createSelector(
  [checkAllowedSettingReport, getDataSystemSync, getSettings],
  (alowedSettingReport, statusSystemSync, settings) => {
    const {
      isAllowedSettingDataExport,
      isAllowedSettingAdrepoReport,
    } = alowedSettingReport;
    const {
      regularReport: isAllowedRegisterRegularReport,
      normalReport: isAllowedRegisterDataExport,
    } = settings.allowRegister;

    let type = REPORT_TYPE_CHANNEL;
    if (!statusSystemSync) {
      type = REPORT_TYPE_CATEGORY;
    } else if (!isAllowedSettingDataExport) {
      type = REPORT_TYPE_CATEGORY;
      if (!isAllowedRegisterRegularReport && isAllowedSettingAdrepoReport) {
        type = REPORT_TYPE_AD_REPO;
      }
    } else if (!isAllowedRegisterDataExport) {
      if (isAllowedRegisterRegularReport) {
        type = REPORT_TYPE_CATEGORY;
      } else if (isAllowedSettingAdrepoReport) {
        type = REPORT_TYPE_AD_REPO;
      }
    }

    return type;
  }
);

const getDefaultReport = createSelector(
  [getDetail, getDefaultReportName, getDefaultReportType, getMasterdata],
  (detail, defaultReportName, type, masterdata) => {
    if (!isEmpty(detail)) return { ...DEFAULT_DETAIL_REPORT, ...detail };

    const defaultPeriod = moment().format(CALENDAR_DAY_FORMAT);

    const data = {
      ...DEFAULT_DETAIL_REPORT,
      [SERVICE_STORE]:
        type === REPORT_TYPE_AD_REPO
          ? REPORT_TYPE_AD_REPO
          : DEFAULT_DETAIL_REPORT[SERVICE_STORE],
      [REPORT_TYPE]: type,
      [REPORT_NAME]: defaultReportName[type],
      [PERIOD_START]: defaultPeriod,
      [PERIOD_END]: defaultPeriod,
    };

    if (checkIsRegularReport(type)) {
      const channel = DEFAULT_DETAIL_REPORT[REPORT_CHANNEL];
      return {
        ...data,
        [DISPLAY_ITEMS]:
          masterdata.displayItem?.[type]?.[channel]?.settingDefault,
      };
    }
    if (checkIsDataExport(type)) {
      return {
        ...data,
        [DIMENSIONS]: masterdata.dimension.type[type]?.default,
        [METRICS]: masterdata.metric.type[type]?.default,
      };
    }

    return data;
  }
);

const getStatesForSetting = createSelector(
  [
    settingsSelectors.getLoadingByStatus([getStatusDetail]),
    getMasterdata,
    getDefaultReportName,
    getDefaultReport,
    getPermissions,
    masterdataSelectors.getDisplay,
    getConversion,
  ],
  (
    isLoading,
    masterdata,
    defaultReportName,
    defaultReport,
    permissions,
    display,
    conversion
  ) => {
    const { type, channel } = defaultReport;

    const summaryMode = [
      REPORT_TYPE_PERIOD,
      REPORT_TYPE_CATEGORY_PERIOD,
      REPORT_TYPE_DETAIL_PERIOD,
    ].reduce((acc, key) => {
      return {
        ...acc,
        [key]: key === type ? defaultReport[SUMMARY_MODE] : TIME_AXIS_DAILY,
      };
    }, {});

    return {
      isLoading,
      defaultReport,
      masterdata: {
        ...masterdata,
        defaultReportName,
        conversion,
        permissions,
        summaryMode,
        display: {
          ...display,
          [MEMBER_NAME]: 'ユーザー名',
          [AMOUNT]: '売上金額',
        },
        dimension: {
          ...masterdata.dimension,
          type: {
            ...masterdata.dimension.type,
            [type]: {
              ...masterdata.dimension.type[type],
              default: defaultReport[DIMENSIONS],
            },
          },
        },
        metric: {
          ...masterdata.metric,
          type: {
            ...masterdata.metric.type,
            [type]: {
              ...masterdata.metric.type[type],
              default: defaultReport[METRICS],
            },
          },
        },
        displayItem: {
          ...masterdata.displayItem,
          [type]: {
            ...masterdata.displayItem[type],
            [channel]: {
              ...masterdata.displayItem[type]?.[channel],
              settingDefault: defaultReport[DISPLAY_ITEMS],
            },
          },
        },
      },
    };
  }
);

const dataExportSelectors = {
  getList,
  getStatusList,
  getMetadata,
  getMasterdata,
  getStatesForSetting,
  getStatesForList,
  getStatesForListHistory,
  getPagination,
  getSortRequest,
  getStatesForSystemSync,
  getSettings,
  getStatusMasterdata,
};

export default dataExportSelectors;
