import { put, takeLatest, call } from 'redux-saga/effects';
import { communicationStatus } from 'services/utils';
import UsageStatusApi from 'services/api/UsageStatusApi';
import types from './types';
import actions from './actions';

const { LOADING } = communicationStatus;

function* handleGetDataUsageStatus() {
  yield put(actions.setUsageStatus(LOADING));
  const { data } = yield call(UsageStatusApi.fetchDataUsageStatus);

  yield put(actions.setDataUsageStatus({ data }));
}

export default function* watch() {
  yield takeLatest(types.GET_DATA_USAGE_STATUS, handleGetDataUsageStatus);
}
