import { all, call, put, select, takeLatest, take } from 'redux-saga/effects';
import moment from 'moment';
import ViewApi from 'services/api/ViewApi';
import displayItemsActions from 'store/display-items/actions';
import displayItemsForViewActions from 'store/display-items-for-view/actions';
import displayItemsSelectors from 'store/display-items/selectors';
import displayItemsForViewSelectors from 'store/display-items-for-view/selectors';
import displayItemsTypes from 'store/display-items/types';
import filterActions from 'store/filters/actions';
import commonActions from 'store/common/actions';
import ViewService from 'domain/ViewService';
import settingsSelectors from 'store/settings/selectors';
import settingsForViewActions from 'store/settings-for-view/actions';
import settingsForViewSelectors from 'store/settings-for-view/selectors';
import settingsViewSelectors from 'store/customview/selectors';
import redirectSelectors from 'store/redirect/selectors';
import { getPermissions, checkHasContractLog } from 'store/auth/selectors';
import masterDataActions from 'store/master-data/actions';
import masterDataSelectors from 'store/master-data/selectors';
import masterDataTypes from 'store/master-data/types';
import filtersSelectors from 'store/filters/selectors';
import loggerConstants from 'store/logger/constant';
import { loggerActions } from 'store/logger';
import messages from 'services/validations/constants';
import apiContants from 'services/api/constants';
import FilterService from 'domain/FilterService';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { MEDIA_SYNC } from 'domain/fields';
import types from './types';
import actions from './actions';

function* handleErrorResponse(error, isView = false) {
  try {
    if (error?.response?.data?.errors[0] === undefined)
      throw Error('Data have no error!');
    if (error?.response?.status !== 400)
      throw Error('Error status not === 400');

    const { code } = error.response.data.errors[0];

    switch (code) {
      case apiContants.ERROR_CODE_NOT_EXIST:
        yield put(
          loggerActions.logConfirmModal({
            title: 'データ更新に失敗しました',
            content:
              'このビューは既に削除されています。 画面を再読み込みし、最新の状態をご確認ください。',
          })
        );
        yield put(
          actions.requestFailed(
            { error: error.response.data.errors },
            loggerConstants.SCOPE_DONT_SHOW
          )
        );
        yield put(actions.fetchList());
        break;
      default:
        throw Error('Error type not in handle');
    }
  } catch (err) {
    const finalError = error.response || error;
    if (!isView) {
      yield put(actions.requestFailed({ error: finalError }));
    } else {
      yield put(actions.requestFailedForView({ error: finalError }));
    }
  }
}

function* fetch(action) {
  try {
    const { id } = action.payload;
    yield put(actions.requestStart());
    const response = yield call(ViewApi.fetch, id);
    yield put(actions.fetchSucceeded({ selected: response.data.data }));
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

// for CustomViewModal
function* fetchEdit(action) {
  try {
    const { id } = action.payload;
    yield put(actions.requestStartForView());
    const response = yield call(ViewApi.fetch, id);

    const { filters, display_items: displayItems } = response.data.data;
    const masterDataIds = {
      conv_id: [],
      media_id: [],
      ad_group1_id: [],
      ad_group2_id: [],
      agency_id: [],
      media_side_campaign_id: [],
      media_side_group_id: [],
      media_account_id: [],
      content_category_id: [],
    };

    filters.map((filter) => {
      const { field, value } = filter;
      if (field in masterDataIds && Array.isArray(value) && value.length > 0) {
        const ebisKey = FilterService.convertToEbisMasterNameFromMediaSideItemsMasterName(
          field
        );
        if (ebisKey === '') {
          masterDataIds[field] = value;
        } else {
          const {
            ebisItemsIds,
            mediaSideItemsIds,
          } = FilterService.parsePairKeyIds(value);
          masterDataIds[field] = mediaSideItemsIds;
          masterDataIds[ebisKey] = ebisItemsIds;
        }
      }
      return field;
    });
    // TODO: only load need master data
    yield put(masterDataActions.updateMasterData({ masterDataIds }));

    // 202309. Get funcId from view-settings instead of NavBar
    // const funcId = yield select(settingsForViewSelectors.getFuncId);
    const funcId = Object.keys(displayItems).shift();

    // 202309. changePage has been completed when switching views, but it has not been executed when editing starts, so execute it.
    // Even if changePage is executed twice, there is no problem
    const screenId = DisplayItemsService.getPageIdByFuncId(funcId);
    yield put(displayItemsForViewActions.changeFuncId(funcId));
    yield put(settingsForViewActions.changePage(screenId));

    // Custom displayItems
    if (displayItems[funcId]) {
      const hasContractLog = yield select(checkHasContractLog);
      const data = [];
      const allItems = yield select(
        displayItemsForViewSelectors.getUserPermittedItems
      );
      const viewItems = displayItems[funcId];
      const defaultItems = yield select(
        displayItemsForViewSelectors.getSettingsDefault
      );
      const enabledPriorityAxis = yield select(
        displayItemsForViewSelectors.enabledPriorityAxis
      );
      if (enabledPriorityAxis && typeof viewItems[MEDIA_SYNC] === 'undefined') {
        data[MEDIA_SYNC] = false;
      }
      // Extract only items that exist in the target funcId.
      Object.keys(viewItems).forEach((key) => {
        if (
          typeof allItems[key] !== 'undefined' ||
          (enabledPriorityAxis && key === MEDIA_SYNC)
        ) {
          data[key] = viewItems[key];
        }
      });
      // Supplement items that exist in the target funcId but are not in viewItems.
      Object.keys(allItems).forEach((key) => {
        if (
          (data[MEDIA_SYNC] && !allItems[key].denySync) ||
          (!data[MEDIA_SYNC] && !allItems[key].denyEbis)
        ) {
          if (typeof viewItems[key] === 'undefined') {
            data[key] =
              typeof defaultItems[key] !== 'undefined'
                ? defaultItems[key]
                : false;
          }
        }
      });
      const { items, sorting } = DisplayItemsService.getItemsByContractLog(
        funcId,
        // eslint-disable-next-line no-underscore-dangle
        { ...data, __sorting__: displayItems[funcId].__sorting__ },
        hasContractLog
      );
      displayItems[funcId] = { ...items, __sorting__: sorting };
    }

    yield put(
      actions.fetchEditSucceeded({
        edit: {
          ...response.data.data,
          display_items: displayItems,
        },
      })
    );
  } catch (error) {
    yield handleErrorResponse(error, true);
  }
}

function* fetchList() {
  try {
    const isAppliedView = yield select(settingsSelectors.isAppliedCustomView);

    // Đặt gạch trước khi chờ display items lấy xong
    yield put(actions.requestStart());

    if (isAppliedView) {
      yield take(displayItemsTypes.FETCH_SUCCEEDED);
    }
    const response = yield call(ViewApi.fetchAll);
    const byIds = ViewService.excludeBlacklist(response.data.data);
    yield put(actions.fetchListSucceeded({ byIds }));
    const redirectStatus = yield select(redirectSelectors.getStatus);
    if (redirectStatus !== 'redirecting') {
      // Apply favorited view if have
      const favorite = byIds.find((view) => view.status)?.id;
      if (favorite || isAppliedView) {
        // Favorite apply only one time, check and skip it
        const applied = yield select(
          settingsViewSelectors.getCurrentAppliedInfo
        );
        const funcId = yield select(settingsSelectors.getFuncId);
        // Continue apply if don't have any change
        // because display items continue fetch from server
        if (isAppliedView) {
          const currentApplyId = byIds.find(
            (view) => applied && applied.current === view.id
          )?.id;
          if (
            applied?.current === currentApplyId &&
            applied.changed === false
          ) {
            yield put(actions.apply({ id: currentApplyId }));
          }
        } else if (
          applied?.current === null ||
          (applied?.current === favorite &&
            applied.byFunc[funcId] === true &&
            applied.changed === false)
        ) {
          // applied, not changed => re-apply when back
          // not yet apply -> apply first time
          yield put(actions.apply({ id: favorite }));
        }
      }
    }
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

// for CustomViewModal
function* create(action) {
  try {
    const funcId = yield select(settingsForViewSelectors.getFuncId);
    const viewList = yield select(settingsViewSelectors.getList);
    const data = ViewService.prepareForApi(action.payload, funcId);

    // Validate duplicate name
    if (Array.isArray(viewList) && viewList.length > 0) {
      const nameExist = viewList.some((view) => view.name === data.view_name);
      if (nameExist) {
        throw new Error(apiContants.ERROR_CODE_VALUE_IS_EXISTS);
      }
    }

    yield put(actions.requestStartForView());
    const response = yield call(ViewApi.create, data);
    yield put(actions.createSucceeded({ data: response.data.data }));
  } catch (error) {
    let errors = [];
    if (error.message === apiContants.ERROR_CODE_VALUE_IS_EXISTS) {
      // Validate name
      errors.push({
        code: apiContants.ERROR_CODE_VALUE_IS_EXISTS,
        field: 'view_name',
        message: messages.MSG_NAME_EXIST.replace('{name}', 'ビュー名'),
      });

      yield put(
        actions.requestFailedForView(
          {
            error: errors,
          },
          loggerConstants.SCOPE_DONT_SHOW
        )
      );
    } else {
      // API error
      errors = error.response.data.errors;
      yield put(
        actions.requestFailedForView({
          error: errors,
        })
      );
    }
  }
}

// for CustomViewModal
function* overwrite(action) {
  try {
    const { id } = action.payload;
    const funcId = yield select(settingsForViewSelectors.getFuncId);
    const viewList = yield select(settingsViewSelectors.getList);
    const data = ViewService.prepareForApi(action.payload, funcId);

    yield put(actions.requestStartForView());
    const response = yield call(ViewApi.update, id, data);
    yield put(actions.updateSucceeded({ ...data, id }));
  } catch (error) {
    yield put(
      actions.requestFailedForView({ error }, loggerConstants.SCOPE_DONT_SHOW)
    );
  }
}

// for CustomViewModal
function* update(action) {
  try {
    const { id } = action.payload;
    const viewEditing = yield select(settingsViewSelectors.getEditing);
    if (Object.keys(viewEditing).length <= 0) {
      throw new Error('Not found view info to update!');
    }
    // 202309. Get funcId from latest view not from DB
    // const displayItemsByFuncId = Object.keys(viewEditing.displayItems);
    // const funcId = displayItemsByFuncId.length > 0 ? displayItemsByFuncId[0] : '';
    const funcId = yield select(settingsForViewSelectors.getFuncId);
    const data = ViewService.prepareForApi(action.payload, funcId);
    yield put(actions.requestStartForView());
    yield call(ViewApi.update, id, data);
    yield put(actions.updateSucceeded({ ...data, id }));
  } catch (error) {
    yield put(
      actions.requestFailedForView({ error }, loggerConstants.SCOPE_DONT_SHOW)
    );
  }
}

function* remove(action) {
  try {
    const { id } = action.payload;
    yield put(actions.requestStart());
    yield call(ViewApi.delete, id);
    yield put(actions.removeSucceeded({ id }));
  } catch (error) {
    yield handleErrorResponse(error);
  }
}

function* apply(action) {
  try {
    const { id } = action.payload;
    const funcId = yield select(settingsSelectors.getFuncId);
    const hasContractLog = yield select(checkHasContractLog);
    yield put(actions.requestStart());
    // yield put(displayItemsActions.requestStart());
    const response = yield call(ViewApi.fetch, id);
    const {
      filters,
      display_items: displayItemsByFuncId,
      period_start_date: periodStart,
      period_end_date: periodEnd,
      period_preset: periodPreset,
      compare_period_start_date: comparePeriodStart,
      compare_period_end_date: comparePeriodEnd,
      compare_period_preset: comparePeriodPreset,
    } = response.data.data;

    // fetch master data
    // const masterData = {};
    const masterDataIds = {
      conv_id: [],
      media_id: [],
      ad_group1_id: [],
      ad_group2_id: [],
      media_account_id: [],
      media_side_campaign_id: [],
      media_side_group_id: [],
      agency_id: [],
      content_category_id: [],
    };
    // const masterData = yield select(masterDataSelectors.getAll);
    filters.map((filter) => {
      const { field, value } = filter;
      if (field in masterDataIds && Array.isArray(value) && value.length > 0) {
        if (
          [
            'media_account_id',
            'media_side_campaign_id',
            'media_side_group_id',
          ].includes(field)
        ) {
          const { mediaSideItemsIds } = FilterService.parsePairKeyIds(value);
          masterDataIds[field] = mediaSideItemsIds;
        } else {
          masterDataIds[field] = value;
        }
      }
      return field;
    });
    // TODO: only load need master data
    yield put(masterDataActions.updateMasterData({ masterDataIds }));
    yield take(masterDataTypes.UPDATE_MASTERDATA_SUCCEEDED);
    const masterData = yield select(masterDataSelectors.getAll);

    // Define list of actions
    const applyActions = [];

    // Apply period
    if (periodStart) {
      applyActions.push(
        put(
          commonActions.updatePeriodWithCompared({
            period: {
              start: periodStart
                ? moment(periodStart)
                : moment().subtract(1, 'day'),
              end: periodEnd ? moment(periodEnd) : moment().subtract(1, 'day'),
              preset: periodPreset,
            },
            comparedPeriod: {
              enabled: !!comparePeriodStart,
              start: comparePeriodStart ? moment(comparePeriodStart) : null,
              end: comparePeriodEnd ? moment(comparePeriodEnd) : null,
              preset: comparePeriodPreset,
            },
          })
        )
      );
    }

    // Apply filters
    const userPermissions = yield select(getPermissions);
    const existingFilters = yield select(filtersSelectors.getSettings);
    const updatedFilters = FilterService.filterNonContractValues(
      ViewService.filterNormalize(filters, masterData),
      userPermissions
    );

    if (!FilterService.isSameFilter(existingFilters, updatedFilters)) {
      applyActions.push(put(filterActions.updateFilters(updatedFilters)));
    }

    // Waiting display items finish loading
    const status = yield select(displayItemsSelectors.getStatus);
    if (status === 'loading') {
      yield take(displayItemsTypes.FETCH_SUCCEEDED);
    }

    // Apply display items
    const displayItems = displayItemsByFuncId[funcId] || {};
    const { items, sorting } = DisplayItemsService.getItemsByContractLog(
      funcId,
      displayItems,
      hasContractLog
    );

    const {
      formatItems,
      formatSorting,
    } = DisplayItemsService.getformatForDisplayItems(funcId, items, sorting);

    applyActions.push(
      put(
        displayItemsActions.fetchSucceeded({
          items: formatItems,
          sorting: formatSorting,
        })
      )
    );
    applyActions.push(
      put(displayItemsForViewActions.fetchSucceeded({ items, sorting }))
    );

    // yield all actions at once
    yield all(applyActions);

    yield put(actions.applySucceeded({ id, funcId }));
  } catch (error) {
    yield handleErrorResponse(error);
  }
}

function* toggleFavorite(action) {
  try {
    const { id } = action.payload;
    yield put(actions.requestStart());
    yield put(actions.toggleFavoriteSucceeded({ id }));
    yield call(ViewApi.toggleFavorite, id);
  } catch (error) {
    yield handleErrorResponse(error);
  }
}

export default function* watchView() {
  yield takeLatest(types.FETCH_EDIT, fetchEdit);
  yield takeLatest(types.FETCH, fetch);
  yield takeLatest(types.FETCH_LIST, fetchList);
  yield takeLatest(types.CREATE, create);
  yield takeLatest(types.UPDATE, update);
  yield takeLatest(types.OVERWRITE, overwrite);
  yield takeLatest(types.DELETE, remove);
  yield takeLatest(types.APPLY, apply);
  yield takeLatest(types.TOGGLE_FAVORITE, toggleFavorite);
}
