import crypto from 'crypto';
import isEmpty from 'lodash/isEmpty';
import GTM from 'services/gtm';
import { v4 as uuidv4 } from 'uuid';
import AuthApi from 'services/api/AuthApi';
import logging from 'domain/logging';
import HttpStatus from 'domain/httpUtils';
import { loadAuth, saveAuth } from 'services/LocalStorageService';

import screenPermissionConfigs from 'domain/permissions/screenPermissionConfigs';
import { CAPI_SINGLE_LIMIT } from 'domain/permissions/contractPermissions';
import screenTitleConfigs from 'services/common/screenTitleConfigs';

const AuthFactory = () => {
  /**
   * Request access_token, refresh_token
   * using authorization_code, code_verifier
   * Store access_token, refresh_token to LocalStorage
   * Beta version: redirect to Hotei EBiS
   *
   * @param object { code, codeVerifier }
   */
  const fetchToken = async ({ code, codeVerifier }) => {
    try {
      const res = await AuthApi.fetchToken({
        grant_type: 'authorization_code',
        code,
        code_verifier: codeVerifier,
      });
      if (!res) {
        throw new Error('Request fetch token error');
      }
      const { data } = res;
      if (!data) {
        throw new Error('Invalid format response from server');
      }
      const { access_token: accessToken, refresh_token: refreshToken } = data;
      return {
        accessToken,
        refreshToken,
      };
    } catch (error) {
      logging.warn('Fetch token error', error);
      // Back to login page
      // window.location = process.env.REACT_APP_ID_EBIS_HOST;
    }
    return undefined;
  };

  /**
   * Get callback_uri from query string
   * Create code_verifier
   * Store code_verifier, callback_uri to localStorage
   * Redirect to id_ebis/oauth2 for request authorization_code
   *
   * @param str callbackUri
   */
  const requestAuthorizationCode = (callbackUri) => {
    const codeVerifier = uuidv4();
    localStorage.setItem('code_verifier', codeVerifier);
    localStorage.setItem('callback_uri', decodeURIComponent(callbackUri));
    const callbackUrl = `${process.env.REACT_APP_HOST}/callback`;
    // create code_verifier
    // redirect to idweb/oauth2
    const idEbisOAuth2 = new URL(
      `${process.env.REACT_APP_ID_EBIS_HOST}/oauth2`
    );
    idEbisOAuth2.searchParams.set('response_type', 'code');
    idEbisOAuth2.searchParams.set('redirect_uri', callbackUrl);
    idEbisOAuth2.searchParams.set(
      'code_challenge',
      crypto.createHash('sha256').update(codeVerifier).digest('hex')
    );
    idEbisOAuth2.searchParams.set('code_challenge_method', 'S256');
    window.location = idEbisOAuth2;
  };

  const getMaintenanceData = (data) => {
    if (isEmpty(data.maintenance)) {
      return null;
    }
    const { status, permissions, message } = data.maintenance;
    return {
      status,
      permissions,
      message,
    };
  };

  const filteringPermission = async (permissions) => {
    let newPermissions = permissions;
    const keys = Object.keys(screenPermissionConfigs);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const permissionsConfigs = screenPermissionConfigs[key];
      const { permissionFilter = null } = screenTitleConfigs[key];
      if (permissionFilter !== null) {
        logging.debug(`permissionsBefore:${key}`, permissionsConfigs);
        // eslint-disable-next-line no-await-in-loop
        newPermissions = await permissionFilter.permissionFilter(
          newPermissions,
          permissionsConfigs
        );
        logging.debug(`permissionsAfter:${key}`, newPermissions);
      }
    }
    return newPermissions;
  };

  const fetchUser = async (accessToken) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const res = await AuthApi.fetchUser(config);
      if (!res) {
        throw new Error('Request fetch user info error');
      }
      const { data } = res.data;
      if (!data) {
        throw new Error('Invalid format response from server');
      }
      const {
        account: {
          account_id: accountId,
          account_str: accountStr,
          client_name: clientName,
          client_name_kana: clientNameKana,
          client_sub_name: clientSubName,
          client_sub_name_kana: clientSubNameKana,
          ad_cv_cost_mode: adCvCostMode,
          measurement_hosts: measurementHosts,
          acquisition_media_click_id: acquisitionMediaClickId,
        },
        email,
        charge_name: chargeName,
        company_name: companyName,
        user_id: userId,
        agency_id: agencyId,
        agency_name: agencyName,
        agent_flag: agentFlag,
        tutorial: { type, is_completed: isCompleted },
        user_roles: userRoles,
        is_cj_contract: isCJContract,
      } = data;
      const newPermissions = await filteringPermission(data.permissions);
      const newDate = { ...data, permissions: newPermissions };

      const maintenanceData = getMaintenanceData(newDate);

      return {
        account: {
          accountId,
          accountStr,
          clientName,
          clientNameKana,
          clientSubName,
          clientSubNameKana,
          adCvCostMode,
          measurementHosts,
          acquisitionMediaClickId,
        },
        permissions: newPermissions,
        email,
        chargeName,
        companyName,
        userId,
        agencyId,
        agencyName,
        agentFlag,
        tutorial: {
          type,
          isCompleted,
        },
        maintenance: maintenanceData,
        userRoles,
        isCJContract,
      };
    } catch (error) {
      logging.warn('Get user info error', error);
      if (
        error.response &&
        error.response.status === HttpStatus.SERVICE_UNAVAILABLE
      ) {
        return error.response;
      }
    }
    return undefined;
  };

  const authentication = async () => {
    const callbackPath = '/callback';
    const params = new URLSearchParams(window.location.search);
    // /oauth2?callback_uri=callback_uri
    const hoteiCallbackUri = params.get('callback_uri');
    if (window.location.pathname === '/oauth2' && hoteiCallbackUri) {
      requestAuthorizationCode(hoteiCallbackUri);
    } else if (
      window.location.pathname === callbackPath &&
      params.get('code')
    ) {
      // get __authorization_code_new__
      // fetch token
      const codeVerifier = localStorage.getItem('code_verifier');
      const token = await fetchToken({
        code: params.get('code'),
        codeVerifier,
      });
      if (!token) {
        window.location = process.env.REACT_APP_ID_EBIS_HOST;
        return undefined;
      }

      saveAuth(token);
      let callbackUrl = localStorage.getItem('callback_uri');
      localStorage.removeItem('callback_uri');
      localStorage.removeItem('code_verifier');

      // Get latest page visited before user signed
      const returnTo = localStorage.getItem('latest_path_visited');
      if (returnTo) {
        localStorage.removeItem('latest_path_visited');
        callbackUrl = returnTo;
      }

      // Beta version redirect to Hotei
      window.location = callbackUrl || process.env.REACT_APP_HOTEI_HOST;
      return undefined;
    } else {
      const token = loadAuth();
      if (!token) {
        // Store latest page visited before user signed
        localStorage.setItem('latest_path_visited', window.location.pathname);
        window.location = process.env.REACT_APP_ID_EBIS_HOST;
        return undefined;
      }

      const { accessToken } = token;
      const user = await fetchUser(accessToken);
      if (user?.permissions && isEmpty(user.permissions)) {
        window.location = `${process.env.REACT_APP_HOST}/403.html`;
        return undefined;
      }
      if (user?.status === HttpStatus.SERVICE_UNAVAILABLE) {
        window.location = `${process.env.REACT_APP_HOST}/503.html`;
        return undefined;
      }

      if (!user) {
        // Store latest page visited before user signed
        localStorage.setItem('latest_path_visited', window.location.pathname);
        // show message contact admin & redirect to login
        window.location = process.env.REACT_APP_ID_EBIS_HOST;
        return undefined;
      }

      const { permissions } = user;
      const isCapiSingleContract = permissions.includes(CAPI_SINGLE_LIMIT);
      GTM.addUserInfo(user, isCapiSingleContract);
      return {
        ...token,
        ...user,
        isLoggedIn: true,
      };
    }
    return undefined;
  };

  return {
    authentication: () => authentication(),
    fetchUser: (accessToken) => fetchUser(accessToken),
  };
};

const Auth = AuthFactory();
export default Auth;
