import React, { useEffect, useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import googleApi from 'services/api/googleApi';
import {
  GOOGLE_SCOPE_DRIVE_FILE,
  GOOGLE_RESPONSE_TYPE_CODE,
  GOOGLE_ACCESS_TYPE_OFFLINE,
  GOOGLE_APPROVAL_PROMPT_FORCE,
  GOOGLE_REDIRECT_URI,
  GOOGLE_OAUTH2_AUTH_URL,
  GOOGLE_AUTH_ERROR,
  GOOOLE_SC_SCOPE,
} from 'domain/consts';
import { displaySCDomainWithoutPrefix } from 'services/search-console-setting/searchConsoleSettingsService';

const STORAGE_KEY = `googleAuth.code`;

const compareValueSortSiteURl = (
  site = { siteUrl: '', permissionLevel: '' }
) => {
  if (site.permissionLevel === 'siteUnverifiedUser') {
    return 1;
  }
  return -1;
};

const useGoogleAuth = (props) => {
  const {
    scope,
    storageKey = STORAGE_KEY,
    accessType = GOOGLE_ACCESS_TYPE_OFFLINE,
    responseType = GOOGLE_RESPONSE_TYPE_CODE,
    approvalPrompt = GOOGLE_APPROVAL_PROMPT_FORCE,
    clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirectUri = `${process.env.REACT_APP_HOST}${GOOGLE_REDIRECT_URI}`,
    onSuccess = () => {},
    onFailure = () => {},
  } = props;

  const [isFetchingGoogleInfo, setFetching] = useState(false);

  let newWindow = false;

  const openGoogleAuthWindow = async (e) => {
    if (e) e.preventDefault(); // to prevent submit if used within form

    const googleOAuth2 = new URL(GOOGLE_OAUTH2_AUTH_URL);
    googleOAuth2.searchParams.set('scope', scope);
    googleOAuth2.searchParams.set('client_id', clientId);
    googleOAuth2.searchParams.set('access_type', accessType);
    googleOAuth2.searchParams.set('response_type', responseType);
    googleOAuth2.searchParams.set('redirect_uri', redirectUri);
    googleOAuth2.searchParams.set('approval_prompt', approvalPrompt);

    if (newWindow && !newWindow.closed) {
      // checks to see if window is open
      newWindow.focus();
    } else {
      newWindow = window.open(
        googleOAuth2,
        '_blank',
        'width=500,height=600,toolbar=yes,menubar=yes,scrollbars=yes'
      );
    }
  };

  const getInfoByScope = async (key, accessToken) => {
    const info = {};

    switch (key) {
      case GOOGLE_SCOPE_DRIVE_FILE:
        {
          const res = await googleApi.fetchDriveV3About(accessToken);
          if (res.error) {
            info.errors = [{ code: GOOGLE_AUTH_ERROR }];
          } else {
            info.user = {
              name: res.user.displayName,
              email: res.user.emailAddress,
            };
          }
        }
        break;

      case GOOOLE_SC_SCOPE:
        {
          const res = await googleApi.fetchSCProperties(accessToken);
          if (res.error) {
            info.errors = res.error;
          } else {
            const convertSearchableData = isEmpty(res.data)
              ? {}
              : res.data.siteEntry
                  .sort(compareValueSortSiteURl)
                  .map((item, index) => {
                    const siteUrlNotVerified =
                      item.permissionLevel === 'siteUnverifiedUser';
                    return {
                      id: index + 1,
                      name: (
                        <div>
                          {displaySCDomainWithoutPrefix(item.siteUrl)}
                          {siteUrlNotVerified ? (
                            <span style={{ color: 'red', fontSize: 11 }}>
                              {'  '}所有権未確認
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      ),
                      siteUrl: item.siteUrl,
                      disabled: siteUrlNotVerified,
                    };
                  });
            info.properties = convertSearchableData;
          }
        }
        break;

      default:
        break;
    }

    return info;
  };

  const getInfo = useCallback(
    async (e) => {
      if (e.key === storageKey && e.newValue && e.newValue !== e.oldValue) {
        setFetching(true);
        const code = localStorage.getItem(storageKey);

        // get token & info
        const tokenInfo = await googleApi.fetchToken(
          code,
          clientId,
          redirectUri
        );
        let errors = tokenInfo?.errors;
        if (isEmpty(errors)) {
          // get info by scope
          const infoByScope = await getInfoByScope(
            scope,
            tokenInfo.access_token
          );
          errors = infoByScope.errors;
          if (isEmpty(errors)) {
            onSuccess({ token_info: { ...tokenInfo }, ...infoByScope });
          }
        }

        if (!isEmpty(errors)) {
          onFailure(errors);
        }

        localStorage.removeItem(storageKey);
        setFetching(false);
      }
    },
    [clientId, onFailure, onSuccess, redirectUri, scope]
  );

  useEffect(() => {
    window.addEventListener('storage', getInfo);
    return () => window.removeEventListener('storage', getInfo);
  }, [getInfo]);

  return { isFetchingGoogleInfo, openGoogleAuthWindow, getInfoByScope };
};

export default useGoogleAuth;
