import { DATE_RANGE_TYPE } from 'domain/consts';
import moment from 'moment';
import { getComparedPeriodDates, getCurrentPeriodDates } from 'services/utils';
import { initialState as commonStateInit } from 'store/common/reducers';
import { initialState as appVersionStateInit } from 'store/version/reducers';
import { initialState as authStateInit } from 'store/auth/reducers';
import { initialState as viewStateInit } from 'store/customview/reducer';
import { initialState as settingsStateInit } from 'store/settings/reducer';
import { initialState as filtersStateInit } from 'store/filters/reducers';
import { initialState as displayItemsStateInit } from 'store/display-items/reducer';
import { initialState as adManagementSettingsInit } from 'store/ad-management-settings/reducers';
import logging from 'domain/logging';
import get from 'lodash/get';

// eslint-disable-next-line no-underscore-dangle
let _userId;

const clearSettingsExpired = (currentKey) => {
  Object.keys(localStorage).map((key) => {
    if (
      key !== currentKey &&
      key.indexOf(`${process.env.REACT_APP_LOCAL_STORAGE_KEY}.settings.`) !== -1
    ) {
      const settingsState = localStorage.getItem(key);
      if (settingsState) {
        const settingsData = JSON.parse(settingsState);
        if (settingsData) {
          const { expired } = settingsData;
          if (expired && moment().diff(moment(expired), 'seconds') > 0) {
            localStorage.removeItem(key);
          }
        }
      }
    }
    return key;
  });
};

const loadAuth = () => {
  try {
    const dataStr = localStorage.getItem(
      `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.auth`
    );
    if (!dataStr) {
      throw new Error(
        `Not found data for key: ${process.env.REACT_APP_LOCAL_STORAGE_KEY}.auth`
      );
    }
    const data = JSON.parse(dataStr);
    if (!data) {
      throw new Error('JSON.parse error', dataStr);
    }
    const { accessToken, refreshToken } = data;
    return {
      accessToken,
      refreshToken,
    };
  } catch (err) {
    logging.info(err);
  }
  return undefined;
};

const saveAuth = (data) => {
  try {
    const { accessToken, refreshToken } = data;
    const dataStr = JSON.stringify({
      accessToken,
      refreshToken,
    });
    localStorage.setItem(
      `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.auth`,
      dataStr
    );
  } catch (err) {
    logging.info('Save data auth', err);
  }
};

const loadState = (user) => {
  try {
    const { userId } = user;
    let serializedData = {};

    serializedData.user = {
      ...authStateInit,
      ...serializedData.user,
      ...user,
    };

    if (userId) {
      const serializedSettingsState = localStorage.getItem(
        `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.settings.${userId}`
      );
      if (serializedSettingsState) {
        const { expired, ...serializedSettingsData } = JSON.parse(
          serializedSettingsState
        );
        serializedSettingsData.settings.report = {
          ...settingsStateInit,
          ...serializedSettingsData.settings.report,
        };
        const {
          start,
          end,
          preset,
        } = serializedSettingsData.commonState.CommonState.settings.period;

        const period =
          !start || !end
            ? {
                start: moment().subtract(1, 'day'),
                end: moment().subtract(1, 'day'),
                preset: DATE_RANGE_TYPE.YESTERDAY,
              }
            : getCurrentPeriodDates({
                start,
                end,
                preset,
              });

        let comparedPeriod = {
          ...serializedSettingsData.commonState.CommonState.settings
            .comparedPeriod,
        };
        if (
          comparedPeriod.enabled &&
          comparedPeriod.start &&
          comparedPeriod.end
        ) {
          comparedPeriod = getComparedPeriodDates(comparedPeriod, period);
        }

        const transitionModal = {
          disabled: get(
            serializedSettingsData.commonState.CommonState.settings,
            `transitionModal.disabled`,
            false
          ),
          disabledHome: get(
            serializedSettingsData.commonState.CommonState.settings,
            `transitionModal.disabledHome`,
            false
          ),
        };

        serializedSettingsData.commonState.CommonState = {
          ...commonStateInit,
          ...serializedSettingsData.commonState.CommonState,
          settings: {
            ...commonStateInit.settings,
            period,
            comparedPeriod,
            transitionModal,
          },
        };

        serializedData = {
          ...serializedData,
          ...serializedSettingsData,
        };
      }
    }

    const serializedSessionState = sessionStorage.getItem(
      process.env.REACT_APP_LOCAL_STORAGE_KEY
    );

    if (serializedSessionState) {
      const serializedSessionData = JSON.parse(serializedSessionState);

      serializedSessionData.appVersion = {
        ...appVersionStateInit,
        ...serializedSessionData.appVersion,
      };

      serializedData = {
        ...serializedData,
        ...serializedSessionData,
      };
    }

    if (userId) {
      const serializedSessionSettingsState = sessionStorage.getItem(
        `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.settings.${userId}`
      );
      if (serializedSessionSettingsState) {
        const serializedSessionSettingsData = JSON.parse(
          serializedSessionSettingsState
        );

        // settings exist on LocalStorage and SessionStorage
        serializedSessionSettingsData.settings = {
          ...serializedData.settings,
          ...serializedSessionSettingsData.settings,
        };

        serializedSessionSettingsData.settings.filters = {
          ...filtersStateInit,
          ...serializedSessionSettingsData.settings.filters,
        };

        serializedSessionSettingsData.settings.view = {
          ...viewStateInit,
          ...serializedSessionSettingsData.settings.view,
        };
        serializedSessionSettingsData.settings.displayItems = {
          ...displayItemsStateInit,
          ...serializedSessionSettingsData.settings.displayItems,
        };

        const serializedAdManagementFilters = get(
          serializedSessionSettingsData,
          'settings.adManagement.filters',
          {}
        );
        serializedSessionSettingsData.settings.adManagement = {
          ...adManagementSettingsInit,
          filters: {
            ...adManagementSettingsInit.filters,
            ...serializedAdManagementFilters,
          },
        };

        serializedData = {
          ...serializedData,
          ...serializedSessionSettingsData,
        };
      }
    }

    return serializedData;
  } catch (err) {
    logging.error('loadState', err);
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const {
      user: { userId },
    } = state;

    _userId = userId;

    const stateSettingsStorage = {
      settings: {
        report: { tab: state.settings.report.tab },
      },
      commonState: {
        CommonState: {
          settings: {
            period: state.commonState.CommonState.settings.period,
            comparedPeriod:
              state.commonState.CommonState.settings.comparedPeriod,
            transitionModal: {
              disabled:
                state.commonState.CommonState.settings.transitionModal.disabled,
              disabledHome:
                state.commonState.CommonState.settings.transitionModal
                  .disabledHome,
            },
          },
        },
        NotificationsState: {
          control: {
            status: state.commonState.NotificationsState.control.status,
          },
        },
      },
    };
    if (userId) {
      // Clean user settings after x days user don't access(default 30 days)
      stateSettingsStorage.expired = moment().add(
        process.env.REACT_APP_USER_SETTINGS_EXPIRES || 30,
        'days'
      );

      const serializedSettingState = JSON.stringify(stateSettingsStorage);
      localStorage.setItem(
        `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.settings.${userId}`,
        serializedSettingState
      );

      // clear user settings expired
      clearSettingsExpired(
        `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.${userId}`
      );
    }

    const stateSessionStorage = {
      appVersion: {
        reloaded: state.appVersion.reloaded,
        reloadedLastest: state.appVersion.reloadedLastest,
      },
    };
    const serializedSessionState = JSON.stringify(stateSessionStorage);
    sessionStorage.setItem(
      process.env.REACT_APP_LOCAL_STORAGE_KEY,
      serializedSessionState
    );

    const stateSessionSettingsStorage = {
      settings: {
        filters: state.settings.filters,
        view: {
          applied: state.settings.view.applied,
        },
        adManagement: {
          filters: state.settings.adManagement.filters,
        },
      },
    };
    if (userId) {
      const serializedSessionSettingsState = JSON.stringify(
        stateSessionSettingsStorage
      );
      sessionStorage.setItem(
        `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.settings.${userId}`,
        serializedSessionSettingsState
      );
    }
  } catch (err) {
    logging.error('saveState', err);
    // Ignore write errors
  }
};

const clearLocalStorage = () =>
  localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY);

const clearSessionStorage = () => {
  const keys = ['settings', 'tutorial'];

  keys.forEach((key) => {
    sessionStorage.removeItem(
      `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.${key}.${_userId}`
    );
  });
};

const clearState = () => {
  clearLocalStorage();
  clearSessionStorage();
};

export {
  loadState,
  saveState,
  clearLocalStorage,
  clearSessionStorage,
  clearState,
  loadAuth,
  saveAuth,
};
