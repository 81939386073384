import React from 'react';
import { shape, string, func } from 'prop-types';
import EbisRadio from 'views/atoms/radio/EbisRadio';

const TimeAxisRadio = (props) => {
  const { name, item, selected, onChange } = props;

  return (
    <div className="ebis-time-axis-radio">
      {Object.keys(item).map((key) => {
        return (
          <EbisRadio
            key={key}
            name={name}
            label={item[key].label}
            value={item[key].value}
            checked={item[key].value === selected}
            disabled={!!item[key].disabled}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
};

TimeAxisRadio.propTypes = {
  name: string.isRequired,
  item: shape({}).isRequired,
  onChange: func.isRequired,
  selected: string,
};

TimeAxisRadio.defaultProps = {
  selected: '',
};

export default React.memo(TimeAxisRadio);
