import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';

const DeleteModal = ({ isShow, title, content, onHide, onSubmit, loading }) => (
  <ConfirmModal
    isShow={isShow}
    isLoading={loading}
    title={title}
    content={content}
    onHide={onHide}
    onSubmit={onSubmit}
    cancelBtn
  />
);

DeleteModal.propTypes = {
  isShow: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
DeleteModal.defaultProps = {
  isShow: false,
  loading: false,
  content: undefined,
};

export default React.memo(DeleteModal);
