import { FALSE_FLAG, TRUE_FLAG } from 'domain/consts';
import React from 'react';
import {
  MEDIA_NAME,
  MEDIA_DISPLAY_NAME,
  ADVERTISER_ID,
  AGENCY_NAME,
  AGENCY_ID,
  ADVERTISING_PARAMETER,
  EBIS_MEDIA_ID,
  EBIS_MEDIA_NAME,
  IS_IMPORTING_COSTS,
  REGIST_DATE,
  UPDATE_DATE,
  LINKED_DATE,
  SYNC_MEDIA_ID,
  AGENCY,
  EBIS_MEDIA,
  IS_ACTIVATED_FIELD,
  PASSWORD,
} from 'domain/fields';
import iconStop from 'assets/images/icon-stop.svg';
import iconStart from 'assets/images/icon-started.svg';

// Define heading for "Edit window" and "Confirm window"
export const FIELD_FORM = {
  [MEDIA_NAME]: '媒体',
  [MEDIA_DISPLAY_NAME]: '媒体（表示名）',
  [ADVERTISER_ID]: '広告アカウントID',
  [AGENCY_ID]: '担当代理店',
  [EBIS_MEDIA_ID]: '媒体種別',
};

export const MODAL_ID = {
  SETTINGS: 'settings',
  ERROR: 'error',
  DELETE: 'delete',
  STATUS: 'status',
  MULTI_STATUS: 'multi_status',
};

export const STYLES = {
  table: 'freeze-table--settings media-sync-management-table',
};

// Specify the name of the column to display the copy button.
export const COPY_BTN_DISPLAY_COLUMN_NAME = ['advertising_parameter'];

// Specify the name of the column to display the "Show / Hide Toggle Button".
export const HIDE_BTN_DISPLAY_COLUMN_NAME = ['advertising_parameter'];

export const DEFAULT_COLUMN_WIDTH = {
  // The width of 'edit_btn' is specified as a fixed value in 'Table.jsx'.
  [MEDIA_NAME]: 180,
  [IS_ACTIVATED_FIELD]: 132,
  [MEDIA_DISPLAY_NAME]: 180,
  [ADVERTISER_ID]: 150,
  [ADVERTISING_PARAMETER]: 350,
  [AGENCY_NAME]: 200,
  [EBIS_MEDIA_NAME]: 200,
  [IS_IMPORTING_COSTS]: 180,
  [REGIST_DATE]: 170,
  [UPDATE_DATE]: 170,
  [LINKED_DATE]: 190,
};

export const DEFAULT_TABLE_WIDTH = 750;

export const urlMediaSyncAdRepoSettingPage =
  'https://sync.ad-repo.com/ag/login/';

export const FACEBOOK_LINK = 'https://www.facebook.com/';
export const SUPPORT_LINE_LINK =
  'https://support.ebis.ne.jp/s/article/000004715';

// Sync media id
export const SYNC_MEDIA_ID_YH = 'yd';
export const SYNC_MEDIA_ID_YS = 'ys';
export const SYNC_MEDIA_ID_GG = 'gg';
export const SYNC_MEDIA_ID_FB = 'fb';
export const SYNC_MEDIA_ID_LN = 'ln';

export const SYNC_MEDIA = {
  [SYNC_MEDIA_ID_YH]: 'Yahoo!ディスプレイ広告',
  [SYNC_MEDIA_ID_YS]: 'Yahoo!検索広告',
  [SYNC_MEDIA_ID_GG]: 'Google広告',
  [SYNC_MEDIA_ID_FB]: 'Facebook広告',
  [SYNC_MEDIA_ID_LN]: 'LINE広告',
};

// Sendmail
export const SENDMAIL_FLAG_FIELD = 'email_enabled';
export const SENDMAIL_USER_FIELD = 'email_user_ids';
export const SENDMAIL = {
  [TRUE_FLAG]: 'する',
  [FALSE_FLAG]: 'しない',
};
export const SENDMAIL_USER_HEADERS = [
  {
    isFreeze: true,
    field: 'rowId',
    width: '45px',
    type: 'checkbox',
  },
  {
    isFreeze: true,
    field: 'user_id',
    name: 'ユーザーID',
    width: 'max-content',
    minWidth: '151px',
  },
  {
    field: 'company_name',
    name: '会社名',
    width: '151px',
  },
  {
    field: 'charge_name',
    name: '名前',
    width: '151px',
  },
  {
    field: 'email',
    name: 'メールアドレス',
    width: 'max-content',
    minWidth: '226px',
  },
  {
    field: 'agent',
    name: 'エージェント',
    width: '109px',
    variant: 'icon-check',
  },
];

// Default value setting
export const DEFAULT_DETAIL_SETTING = {
  [SYNC_MEDIA_ID]: SYNC_MEDIA_ID_YH,
  [MEDIA_DISPLAY_NAME]: null,
  [AGENCY]: null,
  [EBIS_MEDIA]: null,
  [SENDMAIL_FLAG_FIELD]: FALSE_FLAG,
  [SENDMAIL_USER_FIELD]: [],
};

// Setting basic fields
export const SETTING_BASIC_FIELD = {
  [SYNC_MEDIA_ID]: '媒体',
  [MEDIA_DISPLAY_NAME]: '媒体（表示名）',
  [AGENCY]: '担当代理店',
  [EBIS_MEDIA]: '媒体種別',
  [SENDMAIL_FLAG_FIELD]: 'メール通知',
  [SENDMAIL_USER_FIELD]: 'メール通知',
};

// Actions button submit
export const ACTION_SAVE = 'save';
export const ACTION_SETTING_BASIC = 'setting-basic';
export const ACTION_SETTING_ACCOUNT = 'setting-account';
export const MAXIMUM_ITEMS_MEDIA = 100;
export const WAIT_MEDIA_SYNC_TOOLTIP =
  'ただいま媒体シンク機能を準備中ですのでしばらくお待ちください。最大1日かかります。';
export const MAXIMUM_ITEMS_MEDIA_TOOLTIP =
  '登録上限数100件に達しているため追加できません';

export const STATUS_NOT_LINKED = 0;

// Steps ad account setting
export const AUTHENTICATION_SETTING = 1;
export const OPTION_SETTING = 2;

export const LINE_AUTH_STEP_ONE = 1;
export const LINE_AUTH_STEP_TWO = 2;
export const LINE_AUTH_STEP_THREE = 3;
export const LINE_AUTH_STEP_FOUR = 4;
export const LINE_AUTH_STEP_FIVE = 5;

export const STEPS_AD_ACCOUNT_SETTING = [
  {
    step: AUTHENTICATION_SETTING,
    label: 'アカウント認証',
    active: false,
  },
  {
    step: OPTION_SETTING,
    label: 'オプション設定',
    active: false,
  },
];

// margin types
export const NONE = 'NONE';
const INNER = 'INNER';
const OUTER = 'OUTER';

// margin rate min,max
export const MIN_MARGIN_RATE = 0.0001;
export const MAX_MARGIN_RATE = 99.9999;
export const MARGIN_DECIMAL_PLACE = 4;

// request margin types
export const REQUEST_TYPE_REGISTER = 'register';
export const REQUEST_TYPE_UPDATE = 'update';
export const REQUEST_TYPE_DELETE = 'delete';

export const MARGIN_LABELS = {
  [NONE]: '設定しない',
  [INNER]: '内掛け',
  [OUTER]: '外掛け',
};

export const MARGIN_OPTIONS = [
  {
    key: NONE,
    name: MARGIN_LABELS[NONE],
  },
  {
    key: INNER,
    name: MARGIN_LABELS[INNER],
  },
  {
    key: OUTER,
    name: MARGIN_LABELS[OUTER],
  },
];

export const STORAGE_KEY = 'mediaSync.code';
export const LIMIT_MARGINS = 100;

// password length of media line
export const PASSWORD_MAX_LENGTH = 120;
export const PASSWORD_MIN_LENGTH = 6;

// steps check condition authentication line
export const LINE_AUTHENTICATION_STEPS = [
  LINE_AUTH_STEP_THREE,
  LINE_AUTH_STEP_FOUR,
  LINE_AUTH_STEP_FIVE,
];

export const LIST_CONTENT_AUTH_LINE = [
  {
    step: LINE_AUTH_STEP_ONE,
    content: '広告アカウント情報を入力してください。',
    label: '広告アカウントID',
    name: ADVERTISER_ID,
    type: 'text',
  },
  {
    step: LINE_AUTH_STEP_TWO,
    content: (
      <>
        パスワードを設定してください。
        <span className="settings-line-red-text">
          <br />
          今後、媒体シンクにおけるLINE広告の設定変更を行う際に必要となります。
          <br />
          ※紛失すると広告アカウントの削除および再登録（再認証）が必要になるためご注意ください。
        </span>
      </>
    ),
    label: 'パスワード',
    name: PASSWORD,
    type: PASSWORD,
    note: '※6～120文字の半角英数字・記号',
  },
  {
    step: LINE_AUTH_STEP_THREE,
    content: (
      <>
        LINE広告認証を開始します。
        <br />
        認証開始ボタンをクリックすると、LINE管理画面にリンクリクエストが送信されます。
        <br />
      </>
    ),
  },
  {
    step: LINE_AUTH_STEP_FOUR,
    content: (
      <>
        LINE管理画面上に、リンクのリクエストを送信しました。
        <br />
        承認が完了したら「次へ」をクリックしてください。
        <br />
      </>
    ),
  },
  {
    step: LINE_AUTH_STEP_FIVE,
  },
];

export const LIST_CONTENT_AUTH_LINE_ERROR = {
  [LINE_AUTH_STEP_ONE]: {
    step: LINE_AUTH_STEP_ONE,
    title: '登録エラー',
    content: 'この広告アカウントIDはすでに登録済みです。',
  },
  [LINE_AUTH_STEP_THREE]: {
    step: LINE_AUTH_STEP_THREE,
    title: 'リンクリクエスト送信エラー',
    content:
      'リンクリクエストを送信できませんでした。<br/> 広告アカウントIDを再度お確かめください。',
  },
  [LINE_AUTH_STEP_FOUR]: {
    step: LINE_AUTH_STEP_FOUR,
    title: 'リンクリクエスト承認エラー',
    content:
      '承認が確認できませんでした。<br/>もう一度リンクリクエストをご確認ください。',
  },
  [LINE_AUTH_STEP_FIVE]: {
    step: LINE_AUTH_STEP_FIVE,
    title: '画像アップロードエラー',
    content:
      '画像ファイルが確認できませんでした。<br/>もう一度画像ファイル名をご確認ください。',
  },
};
export const ACTIVE_FLAG = true;
export const INACTIVE_FLAG = false;
export const EBIS_PAUSE_FLAG = {
  [ACTIVE_FLAG]: {
    title: '連携',
    value: ACTIVE_FLAG,
    icon: iconStart,
  },
  [INACTIVE_FLAG]: {
    title: '一時停止',
    value: INACTIVE_FLAG,
    icon: iconStop,
  },
};
export const LINE = 'ln';
export const ACCOUNT_STATUS_NOT_LINKED = 0;
export const ACCOUNT_STATUS_ISSUE_PROCESSING = 1;
export const ACCOUNT_STATUS_ISSUE_COMPLETED = 2;

export const HEADER_CONFIG = [
  {
    name: 'check_all',
    isDimension: true,
    sort: false,
    isEdit: true,
    listDropdown: [
      {
        text: '基本設定',
        action: ACTION_SETTING_BASIC,
        tooltip: '編集の権限がありません',
      },
      {
        text: '広告アカウント設定',
        action: ACTION_SETTING_ACCOUNT,
      },
    ],
  },
  {
    text: '連携ステータス',
    name: IS_ACTIVATED_FIELD,
    isDimension: true,
    sort: false,
  },
  {
    text: '媒体',
    name: MEDIA_NAME,
    isDimension: true,
    sort: 'none',
  },
  {
    text: '媒体（表示名）',
    name: MEDIA_DISPLAY_NAME,
    isDimension: true,
    sort: 'none',
  },
  {
    text: '広告アカウントID',
    name: ADVERTISER_ID,
    isDimension: true,
    sort: 'none',
  },
  {
    text: '入稿用パラメータ',
    name: ADVERTISING_PARAMETER,
    isDimension: false,
    sort: 'none',
    copyBtn: true,
  },
  {
    text: '担当代理店',
    name: AGENCY_NAME,
    isDimension: false,
    sort: 'none',
  },
  {
    text: '媒体種別',
    name: EBIS_MEDIA_NAME,
    isDimension: false,
    sort: 'none',
  },
  {
    text: '表示回数/広告コスト',
    name: IS_IMPORTING_COSTS,
    isDimension: false,
    sort: 'none',
  },
  {
    text: '登録日時',
    name: REGIST_DATE,
    isDimension: false,
    sort: 'none',
  },
  {
    text: '最終更新日時',
    name: UPDATE_DATE,
    isDimension: false,
    sort: 'none',
  },
  {
    text: '媒体データ更新日時',
    name: LINKED_DATE,
    isDimension: false,
    sort: 'none',
    isIgnoneFormat: true,
  },
];
export const HEADER_CONFIRM_TABLE = [
  {
    field: MEDIA_DISPLAY_NAME,
    name: '媒体（表示名）',
    width: '195px',
  },
  {
    field: ADVERTISER_ID,
    name: '広告アカウントID',
    width: '180px',
  },
  {
    field: AGENCY_NAME,
    name: '担当代理店',
    width: '180px',
  },
  {
    field: LINKED_DATE,
    name: '媒体データ更新日時',
    width: '180px',
  },
];
export const EXPLAIN_FUNCTION_TERMS =
  'https://support.ebis.ne.jp/s/article/000004708';
export const LIST_MEDIA_LINK_TERMS =
  'https://support.ebis.ne.jp/s/article/000004708#001';

export const YAHOO_NEW_SUPPORT_LINK =
  'https://support.ebis.ne.jp/s/article/000004712';

export const MARGIN_ID_DEFAULT = '1';

// margins default object
export const MARGINS_DEFAULT = {
  [MARGIN_ID_DEFAULT]: {
    deletable: false,
    editable: true,
    margin_rate: 0,
    margin_type: NONE,
    start_date: null,
    request_type: REQUEST_TYPE_REGISTER,
    order: 1,
    stateType: 'default',
  },
};

// consts define that user go to setting from where
export const ORIGIN_SCREEN_LIST = 'list';
export const ORIGIN_SCREEN_BASIC = 'basic';
export const ORIGIN_SCREEN_LIST_ADD = 'list-add';

// prefix check advertiser id not exist
export const ADVERTISER_ID_PREFIX_DEFAULT = 'yrglm';

export const ADVERTISER_ID_NOT_EXIST_TEXT = '設定されていません。';

// consts for new flow yahoo search, display
export const AUTHENTICATE_ETL = 'etl';
export const AUTHENTICATE_DATAHUB = 'datahub';
