import types from 'store/data-export/types';
import helper from 'store/helper';
import { communicationStatus } from 'services/utils';
import {
  REPORT_TYPES_FOR_SETTING,
  REPORT_TYPE_CHANNEL,
  REPORT_TYPE_CV_ATTRIBUTE,
  REPORT_TYPE_ACCESS,
  REPORT_TYPE_AD_REPO,
  REPORT_TYPE_CATEGORY,
  REPORT_TYPE_DETAIL,
  REPORT_TYPE_PERIOD,
  REPORT_TYPE_CONVERSION,
  REPORT_TYPE_CATEGORY_PERIOD,
  REPORT_TYPE_DETAIL_PERIOD,
} from 'domain/data-export/consts';

const { IDLE, SUCCEEDED, FAILED } = communicationStatus;
const FIRST_PAGE = 1;

const initialState = {
  data: {
    list: {
      items: [],
      errors: [],
    },
    detail: {
      item: {},
      errors: [],
    },
    history: {
      items: [],
      errors: [],
    },
    masterData: {
      dimension: {
        item: {},
        type: {},
      },
      metric: {
        item: {},
        type: {},
      },
      reportName: {
        [REPORT_TYPE_CATEGORY]:
          REPORT_TYPES_FOR_SETTING[REPORT_TYPE_CATEGORY].label,
        [REPORT_TYPE_DETAIL]:
          REPORT_TYPES_FOR_SETTING[REPORT_TYPE_DETAIL].label,
        [REPORT_TYPE_PERIOD]:
          REPORT_TYPES_FOR_SETTING[REPORT_TYPE_PERIOD].label,
        [REPORT_TYPE_CONVERSION]:
          REPORT_TYPES_FOR_SETTING[REPORT_TYPE_CONVERSION].label,
        [REPORT_TYPE_CATEGORY_PERIOD]:
          REPORT_TYPES_FOR_SETTING[REPORT_TYPE_CATEGORY_PERIOD].label,
        [REPORT_TYPE_DETAIL_PERIOD]:
          REPORT_TYPES_FOR_SETTING[REPORT_TYPE_DETAIL_PERIOD].label,
        [REPORT_TYPE_CHANNEL]:
          REPORT_TYPES_FOR_SETTING[REPORT_TYPE_CHANNEL].label,
        [REPORT_TYPE_CV_ATTRIBUTE]:
          REPORT_TYPES_FOR_SETTING[REPORT_TYPE_CV_ATTRIBUTE].label,
        [REPORT_TYPE_ACCESS]:
          REPORT_TYPES_FOR_SETTING[REPORT_TYPE_ACCESS].label,
        [REPORT_TYPE_AD_REPO]: 'アドレポ連携',
      },
      displayItem: {},
      allowDisplayRowZero: [],
      users: [],
      views: [],
      errors: [],
    },
  },
  settings: {
    maxItems: {
      maxItemRegularReport: 0,
      maxItemDataExport: 0,
      maxItemAdrepo: 0,
    },
    pagination: {
      totalItems: {
        countRegularReport: 0,
        countAllRegularReport: 0,
        countDataExport: 0,
        countAllDataExport: 0,
        countAdrepo: 0,
      },
      currentPage: FIRST_PAGE,
    },
    sort: {
      field: '',
      direction: '',
    },
    statusSystemSync: false,
    allowRegister: {
      regularReport: true,
      normalReport: false,
      adrepoReport: false,
    },
  },
  status: {
    list: IDLE,
    detail: IDLE,
    history: IDLE,
    masterData: IDLE,
    systemSync: IDLE,
    metadata: IDLE,
  },
};

const setMetadata = (state, action) => {
  const {
    report_name: reportName,
    max_create_data_export: maxItemDataExport,
    count_data_export: countDataExport,
    count_all_data_export: countAllDataExport,
    max_create_regular_report: maxItemRegularReport,
    count_regular_report: countRegularReport,
    count_all_regular_report: countAllRegularReport,
    max_create_report_adrepo: maxItemAdrepo,
    count_adrepo: countAdrepo,
  } = action.payload.data;

  const { statusSystemSync } = state.settings;

  return {
    ...state,
    data: {
      ...state.data,
      masterData: {
        ...state.data.masterData,
        reportName,
      },
    },
    settings: {
      ...state.settings,
      maxItems: {
        ...state.settings.maxItems,
        maxItemDataExport: statusSystemSync ? maxItemDataExport : 0,
        maxItemAdrepo: statusSystemSync ? maxItemAdrepo : 0,
        maxItemRegularReport,
      },
      pagination: {
        ...state.settings.pagination,
        totalItems: {
          ...state.settings.pagination.totalItems,
          countDataExport,
          countAdrepo,
          countRegularReport,
          countAllRegularReport,
          countAllDataExport,
        },
      },
      allowRegister: {
        ...state.settings.allowRegister,
        regularReport: maxItemRegularReport > countAllRegularReport,
        normalReport:
          maxItemDataExport > countAllDataExport && statusSystemSync,
        adrepoReport: maxItemAdrepo > countAdrepo && statusSystemSync,
      },
    },
    status: {
      ...state.status,
      metadata: SUCCEEDED,
    },
  };
};

const setStatusList = (state, action) => {
  return {
    ...state,
    status: {
      ...state.status,
      list: action.payload.status,
    },
  };
};

const setDataList = (state, action) => {
  const { lists } = action.payload.data;
  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        items: lists,
        errors: [],
      },
    },
    status: {
      ...state.status,
      list: SUCCEEDED,
    },
  };
};

const setErrorList = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        errors: action.payload.errors,
      },
    },
    status: {
      ...state.status,
      list: FAILED,
    },
  };
};

const setStatusListHistory = (state, action) => {
  return {
    ...state,
    status: {
      ...state.status,
      history: action.payload.status,
    },
  };
};

const setDataListHistory = (state, action) => {
  const { data } = action.payload;

  return {
    ...state,
    data: {
      ...state.data,
      history: {
        ...state.data.history,
        items: data,
        errors: [],
      },
    },
    status: {
      ...state.status,
      history: SUCCEEDED,
    },
  };
};

const setErrorListHistory = (state, action) => {
  const { errors } = action.payload;

  return {
    ...state,
    data: {
      ...state.data,
      history: {
        ...state.data.history,
        errors,
      },
    },
    status: {
      ...state.status,
      history: FAILED,
    },
  };
};

const setDataDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: { ...state.data.detail, item: action.payload.data, errors: [] },
  },
  status: { ...state.status, detail: SUCCEEDED },
});

const setErrorDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: { ...state.data.detail, errors: action.payload.errors },
  },
  status: { ...state.status, detail: FAILED },
});

const setStatusDetail = (state, action) => ({
  ...state,
  status: { ...state.status, detail: action.payload.status },
});

const setMasterdata = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    masterData: {
      ...state.data.masterData,
      ...action.payload.data,
      errors: [],
    },
  },
  status: { ...state.status, masterData: SUCCEEDED },
});

const setStatusMasterdata = (state, action) => ({
  ...state,
  status: { ...state.status, masterData: action.payload.status },
});

const setStatusSystemSync = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    statusSystemSync: action.payload.statusSystemSync,
  },
  status: { ...state.status, systemSync: action.payload.statusFetching },
});

const DataExportReducer = helper.createReducer(initialState, {
  [types.SET_METADATA]: setMetadata,
  [types.SET_DATA_LIST]: setDataList,
  [types.SET_STATUS_LIST]: setStatusList,
  [types.SET_ERROR_LIST]: setErrorList,
  [types.SET_DATA_LIST_HISTORY]: setDataListHistory,
  [types.SET_STATUS_LIST_HISTORY]: setStatusListHistory,
  [types.SET_ERROR_LIST_HISTORY]: setErrorListHistory,
  [types.SET_DATA_DETAIL]: setDataDetail,
  [types.SET_ERROR_DETAIL]: setErrorDetail,
  [types.SET_STATUS_DETAIL]: setStatusDetail,
  [types.SET_MASTERDATA]: setMasterdata,
  [types.SET_STATUS_MASTERDATA]: setStatusMasterdata,
  [types.SET_STATUS_SYSTEM_SYNC]: setStatusSystemSync,
});

export default DataExportReducer;
