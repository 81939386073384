import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CheckboxElement.scss';
import {
  TRANSITION_RATE,
  LINK_PAGE_TITLE,
} from 'domain/settings/display-items';
import settingsSelector from 'store/settings/selectors';
import * as pages from 'services/routes/constants';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import MediaSyncIcon from '../MediaSyncIcon/MediaSyncIcon';

const CheckboxElement = (props) => {
  const {
    name,
    label,
    disabled,
    checked,
    onChange,
    hasMediaSyncIcon,
    tooltipMessage,
  } = props;

  const [isChecked, toggleChecked] = useState(checked);
  const [hover, toggleHover] = useState(false);
  const screenId = useSelector(settingsSelector.getPage, shallowEqual);

  const iconClass = classNames({
    'fad fa-square checkbox-element__icon': !disabled && !isChecked,
    'fas fa-check-square checkbox-element__icon checkbox-element__icon--checked':
      !disabled && isChecked,
    'fad fa-square checkbox-element__icon checkbox-element__icon--disabled':
      disabled && !isChecked,
    'fad fa-check-square checkbox-element__icon checkbox-element__icon--checked checkbox-element__icon--disabled':
      disabled && isChecked,
    'checkbox-element__icon--hover': hover,
  });

  const labelClass = classNames({
    'checkbox-element__label': true,
    'checkbox-element__label--disabled': disabled,
  });

  const handClick = (e) => {
    e.preventDefault();
    if (!disabled) {
      toggleChecked((prev) => !prev);
      onChange(name, !isChecked);
    }
  };

  useEffect(() => {
    toggleChecked(checked);
  }, [checked]);

  return (
    <div
      className="checkbox-element"
      onClick={handClick}
      aria-hidden="true"
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
    >
      {disabled &&
      [TRANSITION_RATE, LINK_PAGE_TITLE].includes(name) &&
      [pages.LPO_LINK, pages.LPO_PERIOD].includes(screenId) ? (
        <OverlayTrigger
          trigger={disabled ? ['hover', 'focus'] : ''}
          placement="bottom"
          overlay={
            <Tooltip className="dragonball-tooltip variant-view">
              {tooltipMessage}
            </Tooltip>
          }
        >
          <span>
            <i className={iconClass} aria-hidden="true" />
            <span className={labelClass}>{label}</span>
          </span>
        </OverlayTrigger>
      ) : (
        <span>
          <i className={iconClass} aria-hidden="true" />
          <span className={labelClass}>
            {label}
            {hasMediaSyncIcon ? (
              <MediaSyncIcon className="checkbox-element__media-sync-icon" />
            ) : null}
          </span>
        </span>
      )}
    </div>
  );
};

CheckboxElement.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  hasMediaSyncIcon: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

CheckboxElement.defaultProps = {
  disabled: false,
  checked: false,
  onChange: () => {},
  hasMediaSyncIcon: false,
  tooltipMessage: '',
};

export default React.memo(CheckboxElement);
