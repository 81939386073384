import React, {
  memo,
  useCallback,
  useMemo,
  useReducer,
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import classNames from 'classnames';
import { Table as BootTable } from 'react-bootstrap';
import { FreezeTable } from 'views/organism/table/Table';
import Cell from 'views/organism/table/Cell';
import CellCheckbox from 'views/organism/table/CellCheckbox';
import {
  HeaderGroup,
  HeaderItem,
  HeaderTotal,
} from 'views/organism/table/Header';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import withFreezeTableResize from 'views/organism/table/withFreezeTableResize';
import useResizeObserver from 'views/organism/table/useResizeObserver';
import useVirtualScroll from 'services/custom-hooks/useVirtualScroll';
import * as FIELDS from 'domain/fields';
import EbisAxisTooltip from 'views/atoms/EbisAxisTooltip/EbisAxisTooltip';
import { convertMediaSideItemsMetaData } from 'domain/responseUtils';
import useEbisAxisTooltipHook from 'services/custom-hooks/useEbisAxisTooltipHook';
import ActionIcon from 'views/atoms/icon/ActionIcon';
import { LPO_LINK } from 'services/routes/constants';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import { TAB_AD } from 'services/consts';
import { AD_ANALYSIS_LPO_VIEW } from 'domain/permissions/contractPermissions';
import TableContext from './table-context';

const FreezeTableResize = withFreezeTableResize(FreezeTable);

// TODO 共通処理なので、ScreenTitleConfig.jsみたいな処理にしたい
const getBadCssStyle = (metric, value) => {
  let badFlg;
  switch (metric) {
    case FIELDS.PAYBACK_PERIOD_DIFFERENCE:
      badFlg = value > 0;
      break;
    case FIELDS.LTV_PROFIT:
    case FIELDS.LTV_PROFIT_TOTAL:
    case FIELDS.MARGINAL_PROFIT:
      badFlg = value < 0;
      break;
    default:
      badFlg = false;
      break;
  }
  return badFlg;
};

const TableDataCellWrapper = ({
  metrics,
  tdClassName,
  height,
  width,
  axis,
  children,
}) => {
  const {
    hook,
    cellRef,
    hookRef,
    isOpenTooltip,
    handleStayOpenTooltip,
  } = useEbisAxisTooltipHook();
  return (
    <>
      <td
        key={metrics}
        className={tdClassName}
        style={{
          height,
          width,
        }}
        axis={axis}
        ref={cellRef}
      >
        {children}
        {hook}
      </td>
      <EbisAxisTooltip
        axis={axis}
        targetRef={hookRef}
        isOpenTooltip={isOpenTooltip}
        handleStayOpenTooltip={handleStayOpenTooltip}
      />
    </>
  );
};

TableDataCellWrapper.propTypes = {
  metrics: PropTypes.string,
  tdClassName: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  axis: PropTypes.string,
  children: PropTypes.node,
};

TableDataCellWrapper.defaultProps = {
  metrics: '',
  tdClassName: '',
  height: 0,
  width: 0,
  axis: '',
  children: {},
};

const RowTable = (prop) => {
  const {
    data,
    selectedRows,
    isFreeze,
    onMouseOverRow,
    onMouseOutRow,
    onRowSelect,
    headers,
    handleColorCode,
    columnsWidth,
    isAutoResize,
    rowsHeight,
    screenId,
    handleViewDetail,
    priorityAxis,
    handleResizeColumn,
    copyBtnDisplayColumnName,
    toolTipMessages,
    hideBtnDisplayColumnName,
    measuredRef,
    handleEdit,
    handleCheckRow,
  } = prop;
  let prevCheckFirstColumn = true;
  const [isExpand, setExpand] = useState(false);
  const { checkPermissions } = useCheckPermissions();
  const rowNumber = data.primaryKey;
  const isSelected =
    get(data, 'selected', false) || selectedRows.includes(rowNumber);

  const lpoPermission = checkPermissions('anyOf', [AD_ANALYSIS_LPO_VIEW]);

  return (
    <tr
      id={(isFreeze ? 'freeze_' : 'main_') + rowNumber}
      key={rowNumber}
      onMouseOver={() => onMouseOverRow(rowNumber)}
      onMouseOut={() => onMouseOutRow(rowNumber)}
      onClick={() => onRowSelect(rowNumber, isSelected)}
      onFocus={() => {}}
      onBlur={() => {}}
      className={
        rowNumber % 2 ? 'freeze-table__row--odd' : 'freeze-table__row--even'
      }
    >
      {headers.map((header) => {
        if (header.isDimension !== isFreeze) {
          return null;
        }

        const isArrayData = isArray(data[header.name]);

        const clsColor = handleColorCode
          ? handleColorCode(data, header, '').split(' ').pop()
          : '';
        const badflg = getBadCssStyle(header.name, data[header.name]);

        const tdClassName = classNames({
          [`data-row data-cell data-row-${rowNumber}`]: true,
          selected: isSelected,
          [clsColor]: !!clsColor,
          prediction: header.isPrediction,
          bad_results: badflg,
          'text-center': header.isContentCenter,
        });

        const isFirstColumn = prevCheckFirstColumn;
        if (prevCheckFirstColumn) {
          prevCheckFirstColumn = false;
        }
        const width =
          header.name in columnsWidth && !isAutoResize
            ? columnsWidth[header.name]
            : null;
        const height =
          rowNumber in rowsHeight && isFirstColumn
            ? rowsHeight[rowNumber]
            : null;

        let onClick = () => {};

        if (
          get(header, 'cellDisplay', false) &&
          header.cellDisplay === 'viewDetail'
        ) {
          onClick = (e) => {
            // Prevent underlying row being selected
            e.stopPropagation();
            let adId = '';
            if (screenId === LPO_LINK) {
              adId = data.ad_id;
            } else {
              adId = data.rowId;
            }
            handleViewDetail(adId);
          };
        }

        const onExpand = (isExpandFlag) => {
          setExpand(isExpandFlag);
          // isExpand = isExpandFlag;
        };

        const {
          ebisViewPattern,
          syncViewPattern,
          displayAxis,
        } = convertMediaSideItemsMetaData(
          screenId,
          priorityAxis,
          header.name,
          data
        );

        const propCell = {
          options: {
            ...header,
            isArrayData,
            onClick,
            rowNumber,
            handleResizeColumn,
            ebisViewPattern,
            syncViewPattern,
          },
          metric: header.name,
          data,
          width,
          copyBtnDisplayColumnName,
          toolTipMessages,
          hideBtnDisplayColumnName,
          isExpand,
          onExpand,
        };

        if (isFirstColumn) {
          propCell.ref = measuredRef;
        }
        if (data.copy_btn_disable) {
          propCell.copyBtnDisplayColumnName = [];
        }
        if (data.hide_btn_disable) {
          propCell.hideBtnDisplayColumnName = [];
        }

        // eslint-disable-next-line react/jsx-props-no-spreading
        let cell = <Cell {...propCell} />;

        // Column check all, define name is check_all
        let widthColumn = null;
        if (header.name === 'check_all') {
          widthColumn = header.isEdit ? 78 : 46;
          // Existing LPO ads cannot be registered or edited
          // if the LPO ad in Ad Management has already been cancelled.
          let newDisabled = !!data?.isDisabledEdit;
          if ('ad_type' in data && data.ad_type === 'lpo') {
            newDisabled = !lpoPermission;
          }
          let disabledEdit = newDisabled;
          // handle disabled edit for each item on dropdown list menu
          if (header.listDropdown) {
            disabledEdit = data.disabledEdit;
          }
          cell = (
            <CellCheckbox
              id={data.rowId}
              className={header.name}
              width={widthColumn}
              checked={data.selected}
              disabledCheckbox={!!data?.isDisabledCheckbox}
              disabledEdit={disabledEdit}
              isEdit={header.isEdit}
              listDropdown={header.listDropdown}
              onEdit={handleEdit}
              onChange={handleCheckRow}
              onClick={(e) => {
                e.stopPropagation();
                onRowSelect(rowNumber, data.selected);
              }}
              mode={
                'ad_type' in data && data.ad_type === 'lpo' ? data.ad_type : ''
              }
            />
          );

          // Processing when you want to display only the edit button
        } else if (header.name === 'edit_btn') {
          widthColumn = 46;
          let newDisabled = !!data?.isDisabledEdit;
          // Existing LPO ads cannot be registered or edited
          // if the LPO ad in Ad Management has already been cancelled.
          if ('ad_type' in data && data.ad_type === 'lpo') {
            newDisabled = !lpoPermission;
          }
          const disabled = newDisabled;
          cell = (
            <div
              className={`freeze-table__cell-content ${header.name}`}
              style={{ widthColumn }}
            >
              <ActionIcon
                disabled={disabled}
                tooltip={disabled ? '編集の権限がありません' : ''}
                onClick={(e) => {
                  // Prevent underlying components being clicked
                  e.stopPropagation();
                  handleEdit(data.rowId);
                }}
              />
            </div>
          );
        }
        return (
          <TableDataCellWrapper
            key={header.name}
            metrics={header.name}
            width={widthColumn}
            height={height}
            tdClassName={tdClassName}
            axis={displayAxis}
          >
            {cell}
          </TableDataCellWrapper>
        );
      })}
    </tr>
  );
};

RowTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  selectedRows: PropTypes.oneOfType([PropTypes.array]),
  isFreeze: PropTypes.bool,
  onMouseOverRow: PropTypes.func,
  onMouseOutRow: PropTypes.func,
  onRowSelect: PropTypes.func,
  headers: PropTypes.oneOfType([PropTypes.array]),
  handleColorCode: PropTypes.func,
  columnsWidth: PropTypes.oneOfType([PropTypes.object]),
  isAutoResize: PropTypes.bool,
  rowsHeight: PropTypes.oneOfType([PropTypes.object]),
  screenId: PropTypes.string,
  handleViewDetail: PropTypes.func,
  priorityAxis: PropTypes.string,
  handleResizeColumn: PropTypes.func.isRequired,
  copyBtnDisplayColumnName: PropTypes.oneOfType([PropTypes.array]),
  toolTipMessages: PropTypes.oneOfType([PropTypes.object]),
  hideBtnDisplayColumnName: PropTypes.oneOfType([PropTypes.array]),
  measuredRef: PropTypes.func,
  handleEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleCheckRow: PropTypes.func,
};

RowTable.defaultProps = {
  data: {},
  selectedRows: [],
  isFreeze: false,
  onMouseOverRow: () => {},
  onMouseOutRow: () => {},
  onRowSelect: () => {},
  headers: [],
  handleColorCode: null,
  columnsWidth: {},
  isAutoResize: false,
  rowsHeight: {},
  screenId: '',
  handleViewDetail: () => {},
  priorityAxis: '',
  copyBtnDisplayColumnName: [],
  toolTipMessages: {},
  hideBtnDisplayColumnName: [],
  measuredRef: () => {},
  handleEdit: false,
  handleCheckRow: () => {},
};

const RenderTable = memo(
  ({
    dataTable,
    isFreeze,
    headerData,
    handleSort,
    handleCheckAll,
    handleCheckRow,
    handleEdit,
    handleViewDetail,
    columnsWidth,
    handleResizeColumn,
    onResizeColumn,
    headers,
    sumData,
    handleColorCode,
    rowsHeight,
    resizeObserver,
    isAutoResize,
    totalHeight,
    offsetY,
    setSelectedRows,
    selectedRows,
    priorityAxis,
    screenId,
    copyBtnDisplayColumnName,
    toolTipMessages,
    hideBtnDisplayColumnName,
    channel,
  }) => {
    const isEmptyData = dataTable.length <= 0;

    const onMouseOverRow = (index) => {
      Array.from(
        document.getElementsByClassName(`data-row-${index}`)
      ).forEach((elem) => elem.classList.add('hover'));
    };

    const onMouseOutRow = (index) => {
      Array.from(
        document.getElementsByClassName(`data-row-${index}`)
      ).forEach((elem) => elem.classList.remove('hover'));
    };

    const onRowSelect = (rowNumber, isSelected) => {
      const newSelectedRows = isSelected
        ? selectedRows.filter((item) => item !== rowNumber)
        : [...selectedRows, rowNumber];
      setSelectedRows(newSelectedRows);
    };

    const measuredRef = useCallback(
      (node) => {
        if (node !== null) {
          resizeObserver.observe(node);
        }
      },
      [resizeObserver]
    );

    const { refScroll } = useContext(TableContext);

    return (
      <>
        <div className="freeze-table__wrapper-header" style={{ top: 160 }}>
          <BootTable bordered className="freeze-table__header">
            <thead>
              <HeaderGroup
                data={headerData}
                isFreeze={isFreeze}
                allowSort={!isEmptyData}
                onSort={handleSort}
                onCheckAll={handleCheckAll}
                disabledCheckAll={isEmptyData}
                columnsWidth={columnsWidth}
                onResizeColumn={{
                  setRowsHeight: handleResizeColumn,
                  setColumnsWidth: onResizeColumn,
                }}
                screenId={screenId}
                channel={channel}
              />
              <HeaderItem
                groups={headerData}
                headers={headers}
                isFreeze={isFreeze}
                allowSort={!isEmptyData}
                onSort={handleSort}
                columnsWidth={columnsWidth}
                onResizeColumn={{
                  setRowsHeight: handleResizeColumn,
                  setColumnsWidth: onResizeColumn,
                }}
              />
            </thead>
            {!isEmpty(sumData) && (
              <tbody>
                <HeaderTotal
                  headers={headers}
                  data={sumData}
                  columnsWidth={columnsWidth}
                  isFreeze={isFreeze}
                />
              </tbody>
            )}
          </BootTable>
        </div>
        <div
          className="viewport"
          style={{
            overflow: 'hidden',
            willChange: 'transform',
            height: totalHeight,
            position: 'relative',
          }}
        >
          <div
            ref={refScroll}
            style={{
              willChange: 'transform',
              transform: `translateY(${offsetY}px)`,
            }}
          >
            <div className="freeze-table__wrapper-body">
              <BootTable striped bordered hover>
                <tbody>
                  {dataTable.map((data) => {
                    return (
                      <RowTable
                        data={data}
                        selectedRows={selectedRows}
                        isFreeze={isFreeze}
                        onMouseOverRow={onMouseOverRow}
                        onMouseOutRow={onMouseOutRow}
                        onRowSelect={onRowSelect}
                        headers={headers}
                        handleColorCode={handleColorCode}
                        columnsWidth={columnsWidth}
                        isAutoResize={isAutoResize}
                        rowsHeight={rowsHeight}
                        screenId={screenId}
                        handleViewDetail={handleViewDetail}
                        priorityAxis={priorityAxis}
                        handleResizeColumn={handleResizeColumn}
                        copyBtnDisplayColumnName={copyBtnDisplayColumnName}
                        toolTipMessages={toolTipMessages}
                        hideBtnDisplayColumnName={hideBtnDisplayColumnName}
                        measuredRef={measuredRef}
                        handleEdit={handleEdit}
                        handleCheckRow={handleCheckRow}
                      />
                    );
                  })}
                </tbody>
              </BootTable>
            </div>
          </div>
        </div>
      </>
    );
  }
);

RenderTable.propTypes = {
  dataTable: PropTypes.oneOfType([PropTypes.array]),
  isFreeze: PropTypes.bool.isRequired,
  headerData: PropTypes.oneOfType([PropTypes.array]),
  handleSort: PropTypes.func.isRequired,
  handleCheckAll: PropTypes.func,
  handleCheckRow: PropTypes.func,
  handleEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleViewDetail: PropTypes.func,
  columnsWidth: PropTypes.oneOfType([PropTypes.object]),
  handleResizeColumn: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.func.isRequired,
  headers: PropTypes.oneOfType([PropTypes.array]),
  sumData: PropTypes.oneOfType([PropTypes.object]),
  handleColorCode: PropTypes.func,
  rowsHeight: PropTypes.oneOfType([PropTypes.object]),
  resizeObserver: PropTypes.oneOfType([PropTypes.object]),
  isAutoResize: PropTypes.bool,
  totalHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  offsetY: PropTypes.number.isRequired,
  setSelectedRows: PropTypes.func,
  selectedRows: PropTypes.oneOfType([PropTypes.array]),
  priorityAxis: PropTypes.string,
  screenId: PropTypes.string,
  copyBtnDisplayColumnName: PropTypes.oneOfType([PropTypes.array]),
  toolTipMessages: PropTypes.oneOfType([PropTypes.object]),
  hideBtnDisplayColumnName: PropTypes.oneOfType([PropTypes.array]),
  channel: PropTypes.string,
};

RenderTable.defaultProps = {
  dataTable: [],
  headerData: [],
  columnsWidth: {},
  headers: [],
  sumData: {},
  rowsHeight: {},
  handleEdit: false,
  handleColorCode: () => {},
  handleCheckAll: () => {},
  handleCheckRow: () => {},
  handleViewDetail: () => {},
  resizeObserver: {},
  isAutoResize: false,
  setSelectedRows: () => {},
  selectedRows: [],
  priorityAxis: '',
  screenId: '',
  copyBtnDisplayColumnName: [],
  toolTipMessages: {},
  hideBtnDisplayColumnName: [],
  channel: TAB_AD,
};

const tableReducer = (state, action) => {
  switch (action.type) {
    case 'setColumnAttrs':
      return {
        ...state,
        columns: {
          ...state.columns,
          ...action.payload,
        },
      };
    case 'setHeightItems':
      return {
        ...state,
        itemsHeight: action.payload,
      };
    default:
      throw new Error(`Invalid type ${action.type}`);
  }
};

const Table = (props) => {
  const {
    screenId,
    screenName,
    classData,
    headerData,
    listData: dataOrigin,
    emptyMessage,
    columnsWidth,
    tablesWidth,
    isLoading,
    handleSort,
    onResizeColumn,
    onResizeTable,
    sumData,
    allowTotalRowOnly,
    handleColorCode,
    handleCheckAll,
    handleCheckRow,
    handleEdit,
    handleViewDetail,
    priorityAxis,
    copyBtnDisplayColumnName,
    toolTipMessages,
    hideBtnDisplayColumnName,
    channel,
  } = props;

  const [selectedRows, setSelectedRows] = useState([]);

  const [
    resizeObserver,
    handleResizeColumn,
    rowsHeight,
    isAutoResize,
    itemsHeight,
  ] = useResizeObserver(screenId);

  const headers = headerData.reduce((acc, item, index) => {
    headerData[index].mergeColumn = item.mergeColumn || false;
    if (item.children) {
      const children = item.children.map((child) => ({
        ...child,
        isDimension: item.isDimension,
      }));
      return [...acc, ...children];
    }
    return [...acc, item];
  }, []);

  const initialTableAttrs = useMemo(
    () => ({
      columns: {
        /* column_name: {
          width: 0,
          minWidth: 0,
        }, */
      },
      freezeColumns: headers
        .filter((header) => header.isDimension === true)
        .map((header) => header.name),
    }),
    [headers]
  );

  const [tableAttrs, dispatch] = useReducer(tableReducer, initialTableAttrs);

  const [isAllItemsRendered, setAllItemsRendered] = useState(false);

  const {
    startNode,
    visibleNodeCount,
    offsetY,
    totalHeight,
    refScroll,
  } = useVirtualScroll({
    itemCount: dataOrigin.length,
    isAllItemsRendered,
    columnsWidth,
    renderAhead: 10,
    minHeight: 38,
    scrollHeight: 9,
  });
  const visibleData = useMemo(() => {
    // always keep first row for issue column width
    return dataOrigin
      .slice(startNode, startNode + visibleNodeCount)
      .map((item, index) => {
        return { ...item, primaryKey: index + startNode + 1 };
      });
  }, [dataOrigin, startNode, visibleNodeCount]);

  useEffect(() => {
    setAllItemsRendered(visibleData.length === dataOrigin.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: 'setHeightItems', payload: itemsHeight });
  }, [itemsHeight]);

  return (
    <TableContext.Provider
      value={{ screenId, screenName, attrs: tableAttrs, dispatch, refScroll }}
    >
      <DataSyncLoader isLoading={isLoading}>
        <FreezeTableResize
          top={120}
          variant={classData}
          tablesWidth={tablesWidth}
          freeze={
            <RenderTable
              dataTable={visibleData}
              isFreeze
              headerData={headerData}
              handleSort={handleSort}
              handleCheckAll={handleCheckAll}
              handleCheckRow={handleCheckRow}
              handleEdit={handleEdit}
              handleViewDetail={handleViewDetail}
              columnsWidth={columnsWidth}
              handleResizeColumn={handleResizeColumn}
              onResizeColumn={onResizeColumn}
              headers={headers}
              sumData={sumData}
              handleColorCode={handleColorCode}
              rowsHeight={rowsHeight}
              resizeObserver={resizeObserver}
              isAutoResize={isAutoResize}
              totalHeight={totalHeight}
              offsetY={offsetY}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              priorityAxis={priorityAxis}
              screenId={screenId}
              copyBtnDisplayColumnName={copyBtnDisplayColumnName}
              toolTipMessages={toolTipMessages}
              hideBtnDisplayColumnName={hideBtnDisplayColumnName}
              channel={channel}
            />
          }
          main={
            <RenderTable
              dataTable={visibleData}
              isFreeze={false}
              headerData={headerData}
              handleSort={handleSort}
              columnsWidth={columnsWidth}
              handleResizeColumn={handleResizeColumn}
              onResizeColumn={onResizeColumn}
              headers={headers}
              sumData={sumData}
              handleColorCode={handleColorCode}
              rowsHeight={rowsHeight}
              resizeObserver={resizeObserver}
              isAutoResize={isAutoResize}
              totalHeight={totalHeight}
              offsetY={offsetY}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              priorityAxis={priorityAxis}
              screenId={screenId}
              copyBtnDisplayColumnName={copyBtnDisplayColumnName}
              toolTipMessages={toolTipMessages}
              hideBtnDisplayColumnName={hideBtnDisplayColumnName}
              channel={channel}
            />
          }
          empty={
            dataOrigin.length <= 0 && (!allowTotalRowOnly || isEmpty(sumData))
          }
          emptyMessage={emptyMessage}
          onResize={{
            setRowsHeight: handleResizeColumn,
            setTablesWidth: onResizeTable,
            setColumnsWidth: onResizeColumn,
          }}
        />
      </DataSyncLoader>
    </TableContext.Provider>
  );
};

Table.propTypes = {
  screenId: PropTypes.string,
  screenName: PropTypes.string,
  classData: PropTypes.string,
  emptyMessage: PropTypes.string,
  headerData: PropTypes.oneOfType([PropTypes.array]),
  listData: PropTypes.oneOfType([PropTypes.array]),
  columnsWidth: PropTypes.oneOfType([PropTypes.object]),
  tablesWidth: PropTypes.oneOfType([PropTypes.object]),
  isLoading: PropTypes.bool.isRequired,
  handleSort: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.func.isRequired,
  onResizeTable: PropTypes.func.isRequired,
  sumData: PropTypes.oneOfType([PropTypes.object]),
  allowTotalRowOnly: PropTypes.bool,
  handleColorCode: PropTypes.func,
  handleCheckAll: PropTypes.func,
  handleCheckRow: PropTypes.func,
  handleEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleViewDetail: PropTypes.func,
  priorityAxis: PropTypes.string,
  copyBtnDisplayColumnName: PropTypes.oneOfType([PropTypes.array]),
  toolTipMessages: PropTypes.oneOfType([PropTypes.object]),
  hideBtnDisplayColumnName: PropTypes.oneOfType([PropTypes.array]),
  channel: PropTypes.string,
};

Table.defaultProps = {
  screenId: '',
  screenName: '',
  classData: '',
  emptyMessage: '',
  headerData: [],
  listData: [],
  columnsWidth: {},
  tablesWidth: {},
  sumData: {},
  allowTotalRowOnly: false,
  handleColorCode: null,
  handleEdit: false,
  handleCheckAll: () => {},
  handleCheckRow: () => {},
  handleViewDetail: () => {},
  priorityAxis: '',
  copyBtnDisplayColumnName: [],
  toolTipMessages: {},
  hideBtnDisplayColumnName: [],
  channel: TAB_AD,
};

export default Table;
