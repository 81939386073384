import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loggerSelectors, loggerActions } from 'store/logger';

import ConfirmModal from 'views/molecules/modal/ConfirmModal';

/**
 *
 * import { loggerActions } from 'store/logger';
 *
 * dispatch(
 *   loggerActions.logConfirmModal({
 *     title: 'データ更新に失敗しました',
 *     content: 'Your content'
 *     callback: handleRefreshData,
 *   })
 * )
 *
 */

const ConfirmModalContainer = ({
  isShow,
  onSubmit,
  data: { title, content, callback, cancelBtn = false, confirmText = 'OK', confirmBtnType='secondary' },
}) => {
  const handleSubmit = () => {
    onSubmit();
    callback();
  };

  return (
    <ConfirmModal
      isShow={isShow}
      title={title}
      content={content}
      confirmText={confirmText}
      onSubmit={handleSubmit}
      cancelBtn={cancelBtn}
      onHide={onSubmit}
      confirmBtnType={confirmBtnType}
    />
  );
};

ConfirmModalContainer.propTypes = {
  isShow: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    cancelBtn: PropTypes.bool,
    callback: PropTypes.func,
    confirmText: PropTypes.string,
    confirmBtnType: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isShow: loggerSelectors.getIrregularModalShow(state),
  data: loggerSelectors.getIrregularModalData(state),
});
const mapDispatchToProps = (dispatch) => ({
  onSubmit: () => dispatch(loggerActions.closeConfirmModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmModalContainer);
