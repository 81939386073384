import React from 'react';
import { Table } from 'react-bootstrap';
import { arrayOf, string, shape } from 'prop-types';
import classNames from 'classnames';
import 'views/organism/table-view-detail/table-view-detail.scss';
import { get, isArray, isEmpty } from 'lodash';
import CopyToClipboard from 'views/atoms/CopyToClipboard';
import { customLineAddFriendButtonUrl } from 'services/line-add-friend-service/lineAddFriendServices';

function TableViewDetail(props) {
  const { classTable, data, emptyMessage } = props;

  const isShowRowEmpty = data.length <= 0 && emptyMessage;

  const cls = classNames({
    'table-view-detail': true,
    'table-view-detail--empty': isShowRowEmpty,
    [classTable]: !!classTable,
  });

  return (
    <Table bordered className={cls}>
      <tbody>
        {isShowRowEmpty && (
          <tr>
            <td colSpan={2}>{emptyMessage}</td>
          </tr>
        )}
        {data.map((item) => {
          return (
            <tr key={item.title}>
              {item.custom || (
                <>
                  <td>
                    {item.title}
                    {!isEmpty(item.subTitle) &&
                      (!isEmpty(item.subTitle) && isArray(item.subTitle)
                        ? item.subTitle.map((item) => (
                            <div className="sub-title">{item}</div>
                          ))
                        : item.subTitle)}
                  </td>
                  <td>
                    {item.type === 'link' ? customLineAddFriendButtonUrl(get(item, 'value', '')) : get(item, 'value', '')}
                    {item.copyField && (
                      <CopyToClipboard value={customLineAddFriendButtonUrl(get(item, 'value', ''))} className='copy-btn' />
                    )}
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

TableViewDetail.propTypes = {
  classTable: string,
  emptyMessage: string,
  data: arrayOf(shape({})),
};

TableViewDetail.defaultProps = {
  classTable: '',
  emptyMessage: '',
  data: [],
};

export default TableViewDetail;
