import React, { useMemo, useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { oneOfType, node, string, array, bool, func, object, number } from 'prop-types';
import { has, isEmpty } from 'lodash';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import {
  GridTable,
  GridTableItem,
  GridTableSeparator,
  GridTableHeaderWrapper,
  GridTableBodyWrapper,
  GridTableHeader,
  GridTableRow,
} from 'views/organism/GridTable';
import withGridTablePage from 'views/templates/GridTableTemplate/withGridTablePage';
import tooltips from 'services/common/TableTooltips';
import resizeTableActions from 'store/resize-table/actions';
import resizeTableSelectors from 'store/resize-table/selectors';
import commonSelectors from 'store/common/selectors';
import * as DisplayItems from 'domain/settings/display-items';
import { getPrefix, getSuffix } from 'services/utils';
import { formatValueForTable, getFieldApiResponse } from 'domain/utils';
import { convertApiDetail } from 'domain/responseUtils';
import {
  COLUMN_NOWRAP,
  COLUMN_NOWRAP_WITH_TOOLTIP,
  NUMBER_FIELDS,
  FIELD_PREFIX,
} from 'domain/consts';

const GridTablePage = withGridTablePage(GridTable);

function GridTableTemplate(props) {
  const {
    isLoading,
    isTableCenter,
    isResizable,
    isSortable,
    isHoverable,
    isIgnoreLoading,
    isShowRowTotal,
    pageId,
    currentTab,
    header,
    rows,
    maxHeight,
    isApplyTableScrolling,
    rowsSelected,
    sortColumn,
    variant,
    tableControl,
    emptyContent,
    onSelectRow,
    onEditRow,
    onSortColumn,
    onViewDetail,
    idFreedHead,
  } = props;

  const toolTipMessages = {
    ...tooltips.toolTipMessages,
    ...(tooltips.tooltipOverrideMessages[pageId] || {}),
  };

  const [widthTable, widthColumn, filters] = [
    useSelector(
      resizeTableSelectors.getWidthTableByTab(currentTab),
      shallowEqual
    ),
    useSelector(
      resizeTableSelectors.getWidthColumnByTab(currentTab),
      shallowEqual
    ),
    useSelector(commonSelectors.getMappedFilterKeyList, shallowEqual),
  ];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { headers, hasTableFreeze, hasTableMain } = useMemo(() => {
    const columns = header.map(function iter(item) {
      const column = item;

      let { field } = item;
      const prefix = getPrefix(field, Object.values(FIELD_PREFIX));
      if (prefix) {
        const suffix = getSuffix(field, Object.values(FIELD_PREFIX));
        field = DisplayItems[suffix.toUpperCase()] ? suffix : prefix;
      }

      if (item.field === 'rowId' && item.type === 'checkbox') {
        const rowsDisabled = rows.filter((row) => row.isDisabledCheckbox);
        column.sort = 'none';
        column.width = item.isEdit ? '78px' : '46px';
        column.disabled =
          rows.length <= 0 || rowsDisabled.length === rows.length;
      }
      if (onEditRow && item.isEdit) {
        column.onEdit = onEditRow;
      }
      if (onViewDetail && item.isViewDetail) {
        column.onClick = onViewDetail;
      }
      if (!has(item, 'defaultWidth') && !column.width) {
        column.defaultWidth = 'max-content';
      }
      if (!has(item, 'type')) {
        let type = '';
        if (COLUMN_NOWRAP.includes(field)) {
          type = 'inlineNowrap';
        } else if (COLUMN_NOWRAP_WITH_TOOLTIP.includes(field)) {
          type = 'inlineTruncate';
        }
        column.type = type;
      }
      if (!has(item, 'textAlign')) {
        column.textAlign = NUMBER_FIELDS.includes(field) ? 'right' : null;
      }
      if (!isEmpty(item.children)) {
        item.children.forEach(iter);
      }

      return column;
    });

    return {
      headers: columns,
      hasTableFreeze: columns.reduce(
        (acc, column) => acc || column.isFreeze,
        false
      ),
      hasTableMain: columns.reduce(
        (acc, column) => acc || !column.isFreeze,
        false
      ),
    };
  }, [header, onEditRow, onViewDetail, rows]);

  const rowsFormatted = useMemo(() => {
    // Get columns need format
    let fieldsFormat = [];
    headers.forEach((item) => {
      if (
        item.needFormat ||
        item.fieldResponse ||
        item.callback ||
        item.isShowZero
      ) {
        fieldsFormat.push(item);
      }
      fieldsFormat = fieldsFormat.concat(
        (item.children || []).filter((child) => {
          return (
            child.needFormat ||
            child.fieldResponse ||
            child.callback ||
            child.isShowZero
          );
        })
      );
    });

    if (fieldsFormat.length <= 0) return rows;

    // Format data
    return rows.map((row) => {
      let dataConvert = convertApiDetail(row);

      fieldsFormat.map((item) => {
        const {
          fieldResponse,
          field: fieldDisplay,
          callback,
          isShowZero = false,
        } = item;

        if (fieldResponse) {
          dataConvert[fieldDisplay] = row[fieldResponse];
        }

        // Use common to format data
        const { value: fieldConvert } = getFieldApiResponse(fieldDisplay);

        dataConvert[fieldDisplay] = formatValueForTable(
          dataConvert[fieldConvert],
          fieldConvert,
          { showZeros: isShowZero, useTranslation: t }
        );

        // callback to custom format data
        if (callback) {
          dataConvert = callback(dataConvert);
        }

        return fieldConvert;
      });

      return dataConvert;
    });
  }, [t, headers, rows]);

  const actualPage = currentTab ? `${pageId}-${currentTab}` : pageId;

  const onResizeColumn = useCallback(
    (columnsWidth) => {
      if (!isResizable) return;
      dispatch(resizeTableActions.updateColumnsWidth(actualPage, columnsWidth));
    },
    [dispatch, actualPage, isResizable]
  );

  const onResizeTable = useCallback(
    (isFreeze, width) => {
      if (isFreeze && isResizable) return;
      dispatch(resizeTableActions.updateTableWidth(actualPage, width));
    },
    [dispatch, actualPage, isResizable]
  );

  return (
    <>
      {tableControl && (
        <div id={idFreedHead} className="bg-white">
          {tableControl}
        </div>
      )}
      <DataSyncLoader isLoading={isLoading}>
        <GridTablePage
          isIgnoreLoading={isIgnoreLoading}
          isTableCenter={isTableCenter}
          isResizable={isResizable}
          isHoverable={isHoverable}
          isSortable={isSortable && rows.length > 0}
          isShowRowTotal={isShowRowTotal}
          header={headers}
          rows={rowsFormatted}
          maxHeight={maxHeight}
          isApplyTableScrolling={isApplyTableScrolling}
          rowsSelected={rowsSelected}
          variant={variant}
          emptyContent={emptyContent}
          onSelectRow={onSelectRow}
        >
          {hasTableFreeze && (
            <GridTableItem
              isFreeze
              width={widthTable.freeze}
              tooltips={toolTipMessages}
              filters={filters}
              templateColumn={widthColumn}
              sortColumn={sortColumn}
              onResizeTable={onResizeTable}
              onResizeColumn={onResizeColumn}
              onSortColumn={onSortColumn}
            >
              <GridTableHeaderWrapper>
                <GridTableHeader />
              </GridTableHeaderWrapper>
              <GridTableBodyWrapper>
                <GridTableRow />
              </GridTableBodyWrapper>
            </GridTableItem>
          )}
          {hasTableFreeze && hasTableMain && <GridTableSeparator />}
          {hasTableMain && (
            <GridTableItem
              tooltips={toolTipMessages}
              filters={filters}
              templateColumn={widthColumn}
              sortColumn={sortColumn}
              onResizeColumn={onResizeColumn}
              onSortColumn={onSortColumn}
            >
              <GridTableHeaderWrapper>
                <GridTableHeader />
              </GridTableHeaderWrapper>
              <GridTableBodyWrapper>
                <GridTableRow />
              </GridTableBodyWrapper>
            </GridTableItem>
          )}
        </GridTablePage>
      </DataSyncLoader>
    </>
  );
}

GridTableTemplate.propTypes = {
  isLoading: bool.isRequired,
  isIgnoreLoading: bool,
  isTableCenter: bool,
  isResizable: bool,
  isSortable: bool,
  isShowRowTotal: bool,
  isHoverable: bool,
  pageId: string,
  currentTab: string,
  emptyContent: string,
  isApplyTableScrolling: bool,
  maxHeight: number,
  tableControl: oneOfType([bool, node]),
  idFreedHead: string,
  header: oneOfType([array]),
  rows: oneOfType([array]),
  rowsSelected: oneOfType([array]),
  sortColumn: oneOfType([object]),
  variant: string,
  onSelectRow: oneOfType([bool, func]),
  onEditRow: oneOfType([bool, func]),
  onSortColumn: oneOfType([bool, func]),
  onViewDetail: oneOfType([bool, func]),
};

GridTableTemplate.defaultProps = {
  isIgnoreLoading: false,
  isTableCenter: false,
  isResizable: true,
  isSortable: false,
  isShowRowTotal: false,
  isHoverable: true,
  pageId: '',
  currentTab: '',
  tableControl: false,
  isApplyTableScrolling: false,
  maxHeight: null,
  idFreedHead: 'freeze-head-area',
  header: [],
  rows: [],
  rowsSelected: [],
  sortColumn: { field: '', direction: '' },
  variant: '',
  emptyContent: '表示するデータがありません',
  onSelectRow: false,
  onEditRow: false,
  onSortColumn: false,
  onViewDetail: false,
};

export default React.memo(GridTableTemplate);
