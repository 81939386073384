import axios from 'axios';
import { LtvApi as Api } from 'services/api/Api';
import pages from 'services/routes/pages';
import { LTV_SETTINGS_PRODUCT } from 'services/routes/constants';
import apiUtils from 'services/apiUtils';

const url = pages[LTV_SETTINGS_PRODUCT].endpoint;
const baseURL = process.env.REACT_APP_LTV_API_HOST;
// Dummy data
// const url = 'http://localhost:3000/data/table-ltv-product.json';
const fetchAll = async (data) =>
  Api.get(url, {
    baseURL,
    params: data,
  });
const create = async (data) => {
  return Api.post(url, data, { baseURL });
};
const update = async ({ targetId, item }) => {
  return Api.put(`${url}/${targetId}`, item, { baseURL });
};
const remove = async (ids) => {
  const query = { ids };
  return Api.delete(apiUtils.buildUrlWithQueryStrings(url, query), { baseURL });
};
const getPresignedUrl = async () => Api.post(`${url}/csv`, {}, { baseURL });

const upload = async (uploadPath, file) => {
  await axios.put(uploadPath, file, {
    headers: { 'Content-Type': 'text/csv' },
  });
};

const doImport = async (file) =>
  Api.requestAsync(`${url}/validation`, { file }, 'POST', {
    pollTokenKey: 'token',
    pollUrlFormat: `${url}/validation/{token}`,
  });

export default {
  url,
  urlCsv: `${url}/csv`,
  baseUrl: baseURL,
  fetchAll,
  create,
  update,
  remove,
  getPresignedUrl,
  upload,
  doImport,
};
