import mapValues from 'lodash/mapValues';
import {
  DATA_TYPE_STRING,
  SORT_DIRECTION_NONE,
} from 'domain/settings/display-items';
import { AD_MANAGEMENT_MODE } from 'domain/ad-management/consts';

/** ======== Ad Management specific items ======== */
/**
 * 広告種別
 */
export const LPO_TYPE = 'lpo_type';
/**
 * 広告種別
 */
export const AD_TYPE = 'ad_type';
/**
 * 広告ID
 */
export const AD_ID = 'ad_id';
/**
 * 広告名
 */
export const AD_NAME = 'keyword';
/**
 * 担当代理店
 */
export const AGENCY = 'agency';
/**
 * 媒体種別
 */
export const MEDIA_ID = 'media_id';
/**
 * 広告グループ01
 */
export const AD_GROUP1 = 'ad_group1';
/**
 * 広告グループ02
 */
export const AD_GROUP2 = 'ad_group2';
/**
 * 備考
 */
export const AD_NOTE = 'ad_note';
/**
 * クリック単価
 */
export const BASE_CPC = 'base_cpc';
/**
 * CV単価
 */
export const BASE_CPCONV = 'base_cpconv';
/**
 * 出稿開始日
 */
export const AD_START_DATE = 'ad_start_date';
/**
 * 出稿終了日
 */
export const AD_END_DATE = 'ad_end_date';
/**
 * テキスト原稿
 */
export const TEXT_DATA = 'text_data';
/**
 * 計測方式
 */
export const MEASUREMENT_TYPE = 'measurement_type';
/**
 * 地域
 */
export const PREFS = 'prefs';
/**
 * リンク先URL
 */
export const LINK_URL = 'url';
/**
 * ランディングページ
 */
export const LANDING_PAGE = 'page_title';
/**
 * 遷移割合
 */
export const TRANSITION_RATE = 'transition_rate';
/**
 * 自動最適化
 */
export const LPO_AUTO = 'lpo_auto_flag';
/**
 * タイトル
 */
export const TITLE = 'page_title';
/**
 * デバイス
 */
export const DEVICE = 'terminal_type';
/**
 * 親広告ID
 */
export const PARENT_AD_ID = 'parent_ad_id';
/**
 * 入稿用URL
 */
export const PASTE_URL = 'paste_url';
/**
 * 入稿用URL(Google 広告/YSS用)
 */
export const PASTE_URL_PARALLEL = 'paste_url_parallel';
/**
 * 入稿用URL(NS/CNAME用)
 */
export const PASTE_URL_NS_CNAME = 'paste_url_ns_cname';
/**
 * 登録日時
 */
export const REGISTER_DATE = 'regist_date';
/**
 * 最終更新日時
 */
export const UPDATED_DATE = 'update_date';

/**
 * 媒体キャンペーン
 * @type {string}
 */
export const MEDIA_SIDE_CAMPAIGN = 'media_side_campaign';
/**
 * 媒体グループ
 * @type {string}
 */
export const MEDIA_SIDE_GROUP = 'media_side_group';
/**
 * 媒体アカウント
 * @type {string}
 */
export const MEDIA_ACCOUNT = 'media_account';
/**
 * 媒体広告ID
 *  @type {string}
 */
export const MEDIA_SIDE_AD_ID = 'media_side_ad_id';
/**
 * 媒体広告名
 * @type {string}
 */
export const MEDIA_SIDE_AD_NAME = 'media_side_ad_name';
/**
 * 媒体最終リンク先URL
 * @type {string}
 */
export const MEDIA_SIDE_FINAL_URL = 'media_side_final_url';

/**
 * Default values of most display items. Override this to define the correct item
 * @type {{sortDefault: string, sortDisabled: boolean, permissionSet: [], decimalPoint: null, dataType: string, disabled: boolean, title: null, displayFreeze: boolean, required: boolean, order: null, displayDefault: boolean}}
 */
const defaultItem = {
  title: null,
  // 表示: Indicate this item to be displayed. Turned off so all items are displayed by default
  disabled: false,
  // 表示形式
  dataType: DATA_TYPE_STRING,
  // 小数点
  decimalPoint: null,
  // belong to report settings
  // 列幅固定内
  displayFreeze: false,
  // 表の表示順
  order: null,
  displayDefault: false,
  sortDisabled: false,
  sortDefault: SORT_DIRECTION_NONE,
  required: false,
  permissionSet: [],
};

/**
 * Include basic properties of display items, in all tabs
 */
const baseItems = {
  [AD_TYPE]: {
    title: '広告種別',
    order: 1,
  },
  [AD_ID]: {
    title: '広告ID',
    order: 2,
  },
  [AD_NAME]: {
    title: '広告名',
    order: 3,
  },
  [MEDIA_SIDE_AD_NAME]: {
    title: '媒体広告名',
    order: 4,
  },
  [AGENCY]: {
    title: '担当代理店',
    order: 5,
  },
  [MEDIA_SIDE_AD_ID]: {
    title: '媒体広告ID',
    order: 6,
  },
  [MEDIA_ACCOUNT]: {
    title: '媒体',
    order: 7,
  },
  [MEDIA_SIDE_CAMPAIGN]: {
    title: '媒体キャンペーン',
    order: 8,
  },
  [MEDIA_SIDE_GROUP]: {
    title: '媒体広告グループ',
    order: 9,
  },
  [MEDIA_SIDE_FINAL_URL]: {
    title: '媒体最終リンク先URL',
    order: 10,
  },
  [MEDIA_ID]: {
    title: '媒体種別',
    order: 11,
  },
  [AD_GROUP1]: {
    title: '広告グループ01',
    order: 12,
  },
  [AD_GROUP2]: {
    title: '広告グループ02',
    order: 13,
  },
  [AD_NOTE]: {
    title: '備考',
    order: 14,
  },
  [BASE_CPC]: {
    title: 'クリック単価',
    order: 15,
  },
  [BASE_CPCONV]: {
    title: 'CV単価',
    order: 16,
  },
  [AD_START_DATE]: {
    title: '出稿開始日',
    order: 17,
  },
  [AD_END_DATE]: {
    title: '出稿終了日',
    order: 18,
  },
  [TEXT_DATA]: {
    title: 'テキスト原稿',
    order: 19,
  },
  [MEASUREMENT_TYPE]: {
    title: '計測方式',
    order: 20,
  },
  [PREFS]: {
    title: '地域',
    order: 21,
  },
  [LINK_URL]: {
    title: 'リンク先URL',
    order: 22,
  },
  [TITLE]: {
    title: 'リンク先タイトル',
    order: 23,
  },
  [TRANSITION_RATE]: {
    title: '遷移割合',
    order: 24,
  },
  [LPO_AUTO]: {
    title: '自動最適化',
    order: 25,
  },
  [DEVICE]: {
    title: 'デバイス',
    order: 26,
  },
  [PARENT_AD_ID]: {
    title: '親広告ID',
    order: 27,
  },
  [PASTE_URL]: {
    title: '入稿用URL',
    sortDisabled: true,
    order: 28,
  },
  [PASTE_URL_PARALLEL]: {
    title: '入稿用URL(Google 広告/YSS用)',
    sortDisabled: true,
    order: 29,
  },
  [PASTE_URL_NS_CNAME]: {
    title: '入稿用URL(NS/CNAME用)',
    sortDisabled: true,
    order: 30,
  },
  [REGISTER_DATE]: {
    title: '登録日時',
    order: 31,
  },
  [UPDATED_DATE]: {
    title: '最終更新日時',
    order: 32,
  },
};

const getAd = () => {
  const adItems = {
    ...baseItems,
    // Override with ad mode specific definitions
    [AD_TYPE]: {
      ...baseItems[AD_TYPE],
      disabled: true,
    },
    [AD_ID]: {
      ...baseItems[AD_ID],
      displayFreeze: true,
      displayDefault: true,
      required: true,
    },
    [AD_NAME]: {
      ...baseItems[AD_NAME],
      displayFreeze: true,
      displayDefault: true,
    },
    [MEDIA_SIDE_AD_NAME]: {
      ...baseItems[MEDIA_SIDE_AD_NAME],
      displayFreeze: true,
    },
    [MEDIA_SIDE_AD_ID]: {
      ...baseItems[MEDIA_SIDE_AD_ID],
    },
    [MEDIA_ACCOUNT]: {
      ...baseItems[MEDIA_ACCOUNT],
    },
    [MEDIA_SIDE_CAMPAIGN]: {
      ...baseItems[MEDIA_SIDE_CAMPAIGN],
    },
    [MEDIA_SIDE_GROUP]: {
      ...baseItems[MEDIA_SIDE_GROUP],
    },
    [MEDIA_SIDE_FINAL_URL]: {
      ...baseItems[MEDIA_SIDE_FINAL_URL],
    },
    [MEDIA_ID]: {
      ...baseItems[MEDIA_ID],
      displayDefault: true,
    },
    [AD_GROUP1]: {
      ...baseItems[AD_GROUP1],
      displayDefault: true,
    },
    [AD_GROUP2]: {
      ...baseItems[AD_GROUP2],
      displayDefault: true,
    },
    [AD_NOTE]: {
      ...baseItems[AD_NOTE],
      displayDefault: true,
    },
    [MEASUREMENT_TYPE]: {
      ...baseItems[MEASUREMENT_TYPE],
      displayDefault: true,
    },
    [PREFS]: {
      ...baseItems[PREFS],
      disabled: true,
    },
    [BASE_CPCONV]: {
      ...baseItems[BASE_CPCONV],
      displayDefault: true,
    },
    [LINK_URL]: {
      ...baseItems[LINK_URL],
      displayDefault: true,
    },
    [TRANSITION_RATE]: {
      ...baseItems[TRANSITION_RATE],
      disabled: true,
    },
    [LPO_AUTO]: {
      ...baseItems[LPO_AUTO],
      disabled: true,
    },
    [TITLE]: {
      ...baseItems[TITLE],
      displayDefault: true,
    },
    [DEVICE]: {
      ...baseItems[DEVICE],
      disabled: true,
    },
    [PARENT_AD_ID]: {
      ...baseItems[PARENT_AD_ID],
      disabled: true,
    },
    [REGISTER_DATE]: {
      ...baseItems[REGISTER_DATE],
      displayDefault: true,
    },
    [UPDATED_DATE]: {
      ...baseItems[UPDATED_DATE],
      displayDefault: true,
    },
  };

  const withDefaultValues = mapValues(adItems, (v) => ({
    ...defaultItem,
    ...v,
  }));

  return withDefaultValues;
};

const getLpo = () => {
  const adItems = {
    ...baseItems,
    // Override with ad mode specific definitions
    [AD_TYPE]: {
      ...baseItems[AD_TYPE],
      disabled: true,
    },
    [AD_ID]: {
      ...baseItems[AD_ID],
      displayFreeze: true,
      displayDefault: true,
      required: true,
    },
    [AD_NAME]: {
      ...baseItems[AD_NAME],
      displayFreeze: true,
      displayDefault: true,
    },
    [MEDIA_SIDE_AD_NAME]: {
      ...baseItems[MEDIA_SIDE_AD_NAME],
      disabled: true,
    },
    [MEDIA_SIDE_AD_ID]: {
      ...baseItems[MEDIA_SIDE_AD_ID],
      disabled: true,
    },
    [MEDIA_ACCOUNT]: {
      ...baseItems[MEDIA_ACCOUNT],
      disabled: true,
    },
    [MEDIA_SIDE_CAMPAIGN]: {
      ...baseItems[MEDIA_SIDE_CAMPAIGN],
      disabled: true,
    },
    [MEDIA_SIDE_GROUP]: {
      ...baseItems[MEDIA_SIDE_GROUP],
      disabled: true,
    },
    [MEDIA_SIDE_FINAL_URL]: {
      ...baseItems[MEDIA_SIDE_FINAL_URL],
      disabled: true,
    },
    [MEDIA_ID]: {
      ...baseItems[MEDIA_ID],
      displayDefault: true,
    },
    [AD_GROUP1]: {
      ...baseItems[AD_GROUP1],
      displayDefault: true,
    },
    [AD_GROUP2]: {
      ...baseItems[AD_GROUP2],
      displayDefault: true,
    },
    [AD_NOTE]: {
      ...baseItems[AD_NOTE],
      displayDefault: true,
    },
    [MEASUREMENT_TYPE]: {
      ...baseItems[MEASUREMENT_TYPE],
      disabled: true,
    },
    [PREFS]: {
      ...baseItems[PREFS],
      disabled: true,
    },
    [BASE_CPCONV]: {
      ...baseItems[BASE_CPCONV],
      disabled: true,
    },
    [LINK_URL]: {
      ...baseItems[LINK_URL],
      displayDefault: true,
      sortDisabled: true,
    },
    [TRANSITION_RATE]: {
      ...baseItems[TRANSITION_RATE],
      sortDisabled: true,
    },
    [LPO_AUTO]: {
      ...baseItems[LPO_AUTO],
      disabled: true,
    },
    [TITLE]: {
      ...baseItems[TITLE],
      displayDefault: true,
      sortDisabled: true,
    },
    [DEVICE]: {
      ...baseItems[DEVICE],
      disabled: true,
    },
    [PARENT_AD_ID]: {
      ...baseItems[PARENT_AD_ID],
      disabled: true,
    },
    [REGISTER_DATE]: {
      ...baseItems[REGISTER_DATE],
      displayDefault: true,
    },
    [UPDATED_DATE]: {
      ...baseItems[UPDATED_DATE],
      displayDefault: true,
    },
    [PASTE_URL_PARALLEL]: {
      ...baseItems[PASTE_URL_PARALLEL],
      disabled: true,
    },
  };

  const withDefaultValues = mapValues(adItems, (v) => ({
    ...defaultItem,
    ...v,
  }));

  return withDefaultValues;
};

const getListing = () => {
  const listingItems = {
    ...baseItems,
    [AD_TYPE]: {
      ...baseItems[AD_TYPE],
      disabled: true,
    },
    [AD_ID]: {
      ...baseItems[AD_ID],
      displayFreeze: true,
      displayDefault: true,
      required: true,
    },
    [AD_NAME]: {
      ...baseItems[AD_NAME],
      displayFreeze: true,
      displayDefault: true,
    },
    [MEDIA_SIDE_AD_NAME]: {
      ...baseItems[MEDIA_SIDE_AD_NAME],
      disabled: true,
    },
    [MEDIA_SIDE_AD_ID]: {
      ...baseItems[MEDIA_SIDE_AD_ID],
      disabled: true,
    },
    [MEDIA_ACCOUNT]: {
      ...baseItems[MEDIA_ACCOUNT],
      disabled: true,
    },
    [MEDIA_SIDE_CAMPAIGN]: {
      ...baseItems[MEDIA_SIDE_CAMPAIGN],
      disabled: true,
    },
    [MEDIA_SIDE_GROUP]: {
      ...baseItems[MEDIA_SIDE_GROUP],
      disabled: true,
    },
    [MEDIA_SIDE_FINAL_URL]: {
      ...baseItems[MEDIA_SIDE_FINAL_URL],
      disabled: true,
    },
    [MEDIA_ID]: {
      ...baseItems[MEDIA_ID],
      displayDefault: true,
    },
    [AD_GROUP1]: {
      ...baseItems[AD_GROUP1],
      displayDefault: true,
    },
    [AD_GROUP2]: {
      ...baseItems[AD_GROUP2],
      displayDefault: true,
    },
    [AD_NOTE]: {
      ...baseItems[AD_NOTE],
      displayDefault: true,
    },
    [MEASUREMENT_TYPE]: {
      ...baseItems[MEASUREMENT_TYPE],
      displayDefault: true,
    },
    [PREFS]: {
      ...baseItems[PREFS],
      disabled: true,
    },
    [BASE_CPCONV]: {
      ...baseItems[BASE_CPCONV],
      disabled: true,
    },
    [LINK_URL]: {
      ...baseItems[LINK_URL],
      displayDefault: true,
    },
    [TRANSITION_RATE]: {
      ...baseItems[TRANSITION_RATE],
      disabled: true,
    },
    [LPO_AUTO]: {
      ...baseItems[LPO_AUTO],
      disabled: true,
    },
    [TITLE]: {
      ...baseItems[TITLE],
      displayDefault: true,
    },
    [DEVICE]: {
      ...baseItems[DEVICE],
      displayDefault: true,
    },
    [PARENT_AD_ID]: {
      ...baseItems[PARENT_AD_ID],
      disabled: true,
    },
    [REGISTER_DATE]: {
      ...baseItems[REGISTER_DATE],
      displayDefault: true,
    },
    [UPDATED_DATE]: {
      ...baseItems[UPDATED_DATE],
      displayDefault: true,
    },
    [PASTE_URL_NS_CNAME]: {
      ...baseItems[PASTE_URL_NS_CNAME],
      disabled: true,
    },
  };
  const withDefaultValues = mapValues(listingItems, (v) => ({
    ...defaultItem,
    ...v,
  }));

  return withDefaultValues;
};

const getAll = () => {
  const allItems = {
    ...baseItems,
    // Override with ad mode specific definitions
    [AD_TYPE]: {
      ...baseItems[AD_TYPE],
      displayFreeze: true,
      displayDefault: true,
    },
    [AD_ID]: {
      ...baseItems[AD_ID],
      displayFreeze: true,
      displayDefault: true,
      required: true,
    },
    [AD_NAME]: {
      ...baseItems[AD_NAME],
      displayFreeze: true,
      displayDefault: true,
    },
    [MEDIA_SIDE_AD_NAME]: {
      ...baseItems[MEDIA_SIDE_AD_NAME],
      displayFreeze: true,
    },
    [MEDIA_SIDE_AD_ID]: {
      ...baseItems[MEDIA_SIDE_AD_ID],
    },
    [MEDIA_ACCOUNT]: {
      ...baseItems[MEDIA_ACCOUNT],
    },
    [MEDIA_SIDE_CAMPAIGN]: {
      ...baseItems[MEDIA_SIDE_CAMPAIGN],
    },
    [MEDIA_SIDE_GROUP]: {
      ...baseItems[MEDIA_SIDE_GROUP],
    },
    [MEDIA_SIDE_FINAL_URL]: {
      ...baseItems[MEDIA_SIDE_FINAL_URL],
    },
    [MEDIA_ID]: {
      ...baseItems[MEDIA_ID],
      displayDefault: true,
    },
    [AD_GROUP1]: {
      ...baseItems[AD_GROUP1],
      displayDefault: true,
    },
    [AD_GROUP2]: {
      ...baseItems[AD_GROUP2],
      displayDefault: true,
    },
    [AD_NOTE]: {
      ...baseItems[AD_NOTE],
      displayDefault: true,
    },
    [MEASUREMENT_TYPE]: {
      ...baseItems[MEASUREMENT_TYPE],
      displayDefault: true,
    },
    [PREFS]: {
      ...baseItems[PREFS],
      disabled: true,
    },
    [LINK_URL]: {
      ...baseItems[LINK_URL],
      displayDefault: false,
    },
    [TITLE]: {
      ...baseItems[TITLE],
      displayDefault: true,
    },
    [LPO_AUTO]: {
      ...baseItems[LPO_AUTO],
      disabled: true,
    },
    [DEVICE]: {
      ...baseItems[DEVICE],
      disabled: true,
    },
    [PARENT_AD_ID]: {
      ...baseItems[PARENT_AD_ID],
      disabled: true,
    },
    [REGISTER_DATE]: {
      ...baseItems[REGISTER_DATE],
      displayDefault: true,
    },
    [UPDATED_DATE]: {
      ...baseItems[UPDATED_DATE],
      displayDefault: true,
    },
  };

  const withDefaultValues = mapValues(allItems, (v) => ({
    ...defaultItem,
    ...v,
  }));

  return withDefaultValues;
};

const getDeleted = () => {
  const deletedItems = {
    ...baseItems,
    // Override with ad mode specific definitions
    [AD_TYPE]: {
      ...baseItems[AD_TYPE],
      displayFreeze: true,
      displayDefault: true,
    },
    [AD_ID]: {
      ...baseItems[AD_ID],
      displayFreeze: true,
      displayDefault: true,
      required: true,
    },
    [AD_NAME]: {
      ...baseItems[AD_NAME],
      displayFreeze: true,
      displayDefault: true,
    },
    [MEDIA_SIDE_AD_NAME]: {
      ...baseItems[MEDIA_SIDE_AD_NAME],
      displayFreeze: true,
    },
    [MEDIA_SIDE_AD_ID]: {
      ...baseItems[MEDIA_SIDE_AD_ID],
    },
    [MEDIA_ACCOUNT]: {
      ...baseItems[MEDIA_ACCOUNT],
    },
    [MEDIA_SIDE_CAMPAIGN]: {
      ...baseItems[MEDIA_SIDE_CAMPAIGN],
    },
    [MEDIA_SIDE_GROUP]: {
      ...baseItems[MEDIA_SIDE_GROUP],
    },
    [MEDIA_SIDE_FINAL_URL]: {
      ...baseItems[MEDIA_SIDE_FINAL_URL],
    },
    [MEDIA_ID]: {
      ...baseItems[MEDIA_ID],
      displayDefault: true,
    },
    [AD_GROUP1]: {
      ...baseItems[AD_GROUP1],
      displayDefault: true,
    },
    [AD_GROUP2]: {
      ...baseItems[AD_GROUP2],
      displayDefault: true,
    },
    [AD_NOTE]: {
      ...baseItems[AD_NOTE],
      displayDefault: true,
    },
    [BASE_CPC]: {
      ...baseItems[BASE_CPC],
      disabled: true,
    },
    [AD_START_DATE]: {
      ...baseItems[AD_START_DATE],
      disabled: true,
    },
    [AD_END_DATE]: {
      ...baseItems[AD_END_DATE],
      disabled: true,
    },
    [TEXT_DATA]: {
      ...baseItems[TEXT_DATA],
      disabled: true,
    },
    [MEASUREMENT_TYPE]: {
      ...baseItems[MEASUREMENT_TYPE],
      disabled: true,
    },
    [PREFS]: {
      ...baseItems[PREFS],
      disabled: true,
    },
    [LINK_URL]: {
      ...baseItems[LINK_URL],
      disabled: true,
    },
    [TITLE]: {
      ...baseItems[TITLE],
      disabled: true,
    },
    [TRANSITION_RATE]: {
      ...baseItems[TRANSITION_RATE],
      disabled: true,
    },
    [LPO_AUTO]: {
      ...baseItems[LPO_AUTO],
      disabled: true,
    },
    [DEVICE]: {
      ...baseItems[DEVICE],
      disabled: true,
    },
    [PARENT_AD_ID]: {
      ...baseItems[PARENT_AD_ID],
      disabled: true,
    },
    [PASTE_URL]: {
      ...baseItems[PASTE_URL],
      disabled: true,
    },
    [PASTE_URL_PARALLEL]: {
      ...baseItems[PASTE_URL_PARALLEL],
      disabled: true,
    },
    [PASTE_URL_NS_CNAME]: {
      ...baseItems[PASTE_URL_NS_CNAME],
      disabled: true,
    },
    [REGISTER_DATE]: {
      ...baseItems[REGISTER_DATE],
      displayDefault: true,
    },
    [UPDATED_DATE]: {
      ...baseItems[UPDATED_DATE],
      displayDefault: true,
    },
  };
  const withDefaultValues = mapValues(deletedItems, (v) => ({
    ...defaultItem,
    ...v,
  }));

  return withDefaultValues;
};

const getByMode = (mode) => {
  switch (mode) {
    case AD_MANAGEMENT_MODE.AD:
      return getAd();
    case AD_MANAGEMENT_MODE.LPO:
      return getLpo();
    case AD_MANAGEMENT_MODE.LISTING:
      return getListing();
    case AD_MANAGEMENT_MODE.ALL:
      return getAll();
    case AD_MANAGEMENT_MODE.DELETED:
      return getDeleted();
    default:
      return {};
  }
};

const DisplayItemsAdManagement = () => {
  return {
    getDefault: () => {},
    getAll: () => {},
    getByMode: (mode) => getByMode(mode),
  };
};

const DisplayItemsAdManagementService = DisplayItemsAdManagement();

export default DisplayItemsAdManagementService;
