const getStatus = (state) => {
  const { status } = state.cache.ltvSettingsCsvOrders;
  return status;
};

const getData = (state) => {
  const { data } = state.cache.ltvSettingsCsvOrders;
  return data;
};

const getApiError = (state) => {
  return state.cache.ltvSettingsCsvOrders.error?.data?.errors;
};

export default {
  getStatus,
  getApiError,
  getData,
};
