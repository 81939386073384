import {
  CHART_PATTERNS,
  CONTEXT_MENU_OPTION,
  DEFAULT_SORT_CHART,
  DIMENSIONS_TO_KEY_VALUES,
} from 'domain/category-analyze/consts';
import {
  API_DATE_FORMAT,
  API_UNLIMITED_VALUE,
  BOOKMARK_FUNC_NAME,
  CHART_COLORS,
  COST_LINKING_EMPTY_IDS,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_MEDIA_SYNC,
  TABLE_ROWS_PER_PAGE,
  UNREGISTERED_DISPLAY_VALUE,
  UNREGISTERED_IDS,
  UNREGISTERED_UNSYNCED_DISPLAY_VALUE,
  UNSYNCED_DISPLAY_VALUE,
} from 'domain/consts';
import {
  AD_GROUP1,
  AD_GROUP1_ID,
  AD_GROUP2,
  AD_GROUP2_ID,
  CATEGORY,
  LANDING_PAGE_DOMAIN,
  MEDIA_ID,
  PERIOD,
  MEDIA_SIDE_CAMPAIGN_ID,
  MEDIA_SIDE_GROUP_ID,
  SYNC_CATEGORY,
  MEDIA_SIDE_CAMPAIGN,
  MEDIA_SIDE_GROUP,
  MEDIA_ACCOUNT_ID,
  MEDIA_SYNC,
} from 'domain/fields';
import {
  CATEGORY_ANALYZE,
  COMPARE_PERIOD,
  COST_ALLOCATION,
  DETAILS_ANALYSIS,
} from 'services/routes/constants';
import { containsByAllKeys, equalsAllKeys } from 'services/utils';
import * as domainUtils from 'domain/utils';
import { createSeriesName } from 'domain/utils';
import * as FIELD from 'domain/fields';
import bookmarkService from 'services/common/bookmarkService';
import uniq from 'lodash/uniq';

import SummaryApi from 'services/api/SummaryApi';
import { convertApiReportResponse } from 'domain/responseUtils';
import apiUtils from 'services/apiUtils';
import FilterService from 'domain/FilterService';
import cloneDeep from 'lodash/cloneDeep';
import {
  FILTER_KEY_MEDIA_SIDE_CAMPAIGN,
  FILTER_KEY_MEDIA_SIDE_GROUP,
  FILTER_KEY_MEDIA_ACCOUNT,
} from 'services/consts';

const saveBookmark = async (options) => {
  const { x, y } = options;
  const request = {
    x,
    y,
  };
  return SummaryApi.createBookmark(request);
};

const loadBookmark = async () => {
  return SummaryApi.getBookmark();
};

const deleteBookmark = async () => {
  return SummaryApi.deleteBookmark();
};

const getDimensionKeyValue = (dimension) =>
  DIMENSIONS_TO_KEY_VALUES.find((d) => d.field === dimension);

const getDimensionKeyValueList = (dimensions) =>
  DIMENSIONS_TO_KEY_VALUES.filter((dimension) =>
    dimensions.includes(dimension.field)
  );

const createDimensionKeyObject = (obj, dimensions) => {
  const dimensionsWithAxis = cloneDeep(dimensions);
  // append priority axis info
  dimensionsWithAxis.push(FIELD.PRIORITY_AXIS);
  return dimensionsWithAxis
    .map((dimension) =>
      DIMENSIONS_TO_KEY_VALUES.find((d) => d.field === dimension)
    )
    .reduce((acc, { key }) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

const createGetTableDataRequest = ({
  pagination,
  sorts,
  start,
  end,
  channel,
  dimensions,
  filters,
  metrics,
  axis,
}) => {
  const { currentPage } = pagination;
  let sortQuery;
  if (sorts.length > 0) {
    sortQuery = apiUtils.buildSort(
      domainUtils.getValidSortFields(sorts, dimensions, metrics)
    );
  } else {
    sortQuery = '';
  }
  const sortedDimensions = DIMENSIONS_TO_KEY_VALUES.filter(
    (d) => dimensions.includes(d.field) && d.field !== FIELD.CHANNEL_ACCESS_TYPE
  ).map((d) => d.field);

  return {
    dimensions: uniq(sortedDimensions),
    metrics: uniq(metrics),
    channel,
    filters,
    start_date: start.format(API_DATE_FORMAT),
    end_date: end.format(API_DATE_FORMAT),
    offset: (currentPage - 1) * TABLE_ROWS_PER_PAGE,
    limit: TABLE_ROWS_PER_PAGE,
    sort: sortQuery,
    sum: true,
    axis,
    // compared,
  };
};

const createGetChartDataRequest = ({
  start,
  end,
  channel,
  dimensions,
  filters,
  axis,
}) => {
  const metricsWithDuplicated = CHART_PATTERNS.map((pattern) => [
    pattern.x,
    pattern.y,
  ]).flatMap((cols) => [...cols]);

  const sortQuery = apiUtils.buildSort(DEFAULT_SORT_CHART);
  const sortedDimensions = DIMENSIONS_TO_KEY_VALUES.filter(
    (d) => dimensions.includes(d.field) && d.field !== FIELD.CHANNEL_ACCESS_TYPE
  ).map((d) => d.field);
  return {
    dimensions: uniq(sortedDimensions),
    metrics: uniq(metricsWithDuplicated),
    channel,
    filters,
    start_date: start.format(API_DATE_FORMAT),
    end_date: end.format(API_DATE_FORMAT),
    offset: 0,
    sort: sortQuery,
    limit: API_UNLIMITED_VALUE,
    sum: false,
    axis,
    // compared,
  };
};

const createTableSumData = ({ sum, period, comparedPeriodEnabled }) => {
  if (comparedPeriodEnabled) {
    return {
      ...sum,
      period: domainUtils.formatDateRange(period.start, period.end),
    };
  }
  return sum;
};

const getTableData = async (options) => {
  const request = createGetTableDataRequest(options);
  const response = await SummaryApi.getDataTable(request);
  return convertApiReportResponse(response);
};

const getChartData = async (options) => {
  const request = createGetChartDataRequest(options);
  const response = await SummaryApi.getDataChart(request);
  return convertApiReportResponse(response);
};

const createLegends = (selectedCategories, list, visibleList) => {
  return selectedCategories.map((category) => {
    const matchingItem = list.find((item) =>
      Object.keys(category).every((key) => item[key] === category[key])
    );
    return {
      key: category,
      display: createSeriesName(matchingItem, category),
      visible: containsByAllKeys(visibleList, category),
    };
  });
};

/**
 * Convert apiList to usable series data for category analyze graph
 * @param {{channel,category,media_name,ad_group1_name,
 *  ad_gropu2_name,media_id,ad_group1_id,ad_group2_id}[]} list
 * @param {{x:string,y:string}} chartAxis
 * @param selectedCategories
 * @param {string[]} dimensions
 * @param comparedPeriod
 * @param comparedList
 * @return {{data[], name, visible,}[]}
 */
const apiListToSeries = (
  list,
  {
    chartAxis,
    selectedCategories,
    dimensions,
    comparedPeriod,
    comparedList,
    // selectedRows,
  }
) => {
  let comparedMap;
  if (comparedPeriod && comparedPeriod.enabled && comparedList) {
    comparedMap = comparedList
      .filter((item) =>
        selectedCategories.some((elem) =>
          dimensions.every((dimension) => elem[dimension] === item[dimension])
        )
      )
      .map((item) => {
        const key = createDimensionKeyObject(item, dimensions);
        // const selected = containsByAllKeys(selectedRows, key);
        return {
          key,
          data: { x: item[chartAxis.x], y: item[chartAxis.y], selected: false },
        };
      });
  }

  const dimensionKeyFields = getDimensionKeyValueList(dimensions).map(
    (dkv) => dkv.key
  );

  return list
    .filter((item) =>
      selectedCategories.some((elem) =>
        dimensionKeyFields.every(
          (dimension) => elem[dimension] === item[dimension]
        )
      )
    )
    .map((item, index) => {
      const key = createDimensionKeyObject(item, dimensions);
      // const selected = containsByAllKeys(selectedRows, key);
      const data = [
        {
          x: item[chartAxis.x],
          y: item[chartAxis.y],
          selected: false,
          // color: CHART_COLORS[index + 1].primary,
        },
      ];
      if (comparedPeriod.enabled && comparedList) {
        const comparedData = comparedMap.find((comparedItem) =>
          equalsAllKeys(key, comparedItem.key)
        );
        if (comparedData) {
          data.push({
            ...comparedData.data,
            // color: CHART_COLORS[index + 1].primary,
          });
        }
      }
      return {
        dimensionKey: key,
        defaultColor: CHART_COLORS[index > 20 ? 20 : index].primary,
        data,
        name: createSeriesName(item, key),
        // visible: true,
        color: CHART_COLORS[index > 20 ? 20 : index].primary,
      };
    });
};

// Bookmark
const updateBookmark = async ({ y: yAxis, x: xAxis, enabled }) => {
  if (enabled) {
    return bookmarkService.saveMetrics({
      metric: yAxis,
      compareMetric: xAxis,
      funcId: BOOKMARK_FUNC_NAME.CATEGORY_ANALYZE,
    });
  }
  return bookmarkService.removeMetrics({
    metric: yAxis,
    compareMetric: xAxis,
    funcId: BOOKMARK_FUNC_NAME.CATEGORY_ANALYZE,
  });
};

const getBookmark = async () => {
  const data = await bookmarkService.get({
    funcId: BOOKMARK_FUNC_NAME.CATEGORY_ANALYZE,
  });

  const {
    metric,
    compare_metric: compareMetric,
    status,
    show_chart: showChart,
  } = data;

  return {
    y: metric,
    x: compareMetric,
    enabled: status,
    showChart,
  };
};

const updateShowChart = async (show) => {
  if (show) {
    return bookmarkService.showChart({
      funcId: BOOKMARK_FUNC_NAME.CATEGORY_ANALYZE,
    });
  }
  return bookmarkService.hideChart({
    funcId: BOOKMARK_FUNC_NAME.CATEGORY_ANALYZE,
  });
};

const buildAnalysisOptions = (
  targetScreen,
  items,
  filters = {},
  allowComparedPeriod = false
) => {
  return {
    targetScreen,
    items,
    filters,
    allowComparedPeriod,
  };
};

const buildAnalysisFilterOption = (fields, row, masterData = null) => {
  const filters = {};
  Object.keys(fields).forEach((key, index) => {
    const data = row.cells.find((cell) => cell.idKey === key);
    if (
      data &&
      data.idValue &&
      !COST_LINKING_EMPTY_IDS.includes(data.idValue)
    ) {
      const id = UNREGISTERED_IDS.includes(data.idValue) ? 0 : data.idValue;
      let ids = [];
      let { value } = data;
      if (FILTER_KEY_MEDIA_SIDE_CAMPAIGN === fields[key]) {
        value =
          data.value === UNREGISTERED_DISPLAY_VALUE ||
          data.value === UNSYNCED_DISPLAY_VALUE
            ? UNREGISTERED_UNSYNCED_DISPLAY_VALUE
            : data.value;
        const pairKeys = FilterService.getPairKeysFromName(
          value,
          masterData.mediaSideCampaign,
          masterData.adGroup1
        );
        ids = [...pairKeys];
      } else if (FILTER_KEY_MEDIA_SIDE_GROUP === fields[key]) {
        value =
          data.value === UNREGISTERED_DISPLAY_VALUE ||
          data.value === UNSYNCED_DISPLAY_VALUE
            ? UNREGISTERED_UNSYNCED_DISPLAY_VALUE
            : data.value;
        const pairKeys = FilterService.getPairKeysFromName(
          value,
          masterData.mediaSideGroup,
          masterData.adGroup2
        );
        ids = [...pairKeys];
      } else if (FILTER_KEY_MEDIA_ACCOUNT === fields[key]) {
        const pairKeys = FilterService.getPairKeysFromName(
          value,
          masterData.mediaAccount,
          masterData.media
        );
        ids = [...pairKeys];
      } else {
        ids = [id];
      }
      filters[fields[key]] = {
        type: 1,
        values: [value],
        ids: ids.map(String),
        option: null,
        title: data.title,
        order: index,
      };
    }
  });

  return filters;
};

const buildToPeriodAnalysisOptions = (row, axis, masterData) => {
  let filters = {};
  if (axis === PRIORITY_AXIS_MEDIA_SYNC) {
    filters = { [SYNC_CATEGORY]: MEDIA_ACCOUNT_ID };
  } else if (axis === PRIORITY_AXIS_EBIS) {
    filters = { [CATEGORY]: MEDIA_ID };
  }
  return buildAnalysisOptions(
    COMPARE_PERIOD,
    {
      [PERIOD]: false,
      [CATEGORY]: true,
      [AD_GROUP1]: false,
      [AD_GROUP2]: false,
      [SYNC_CATEGORY]: true,
      [MEDIA_SIDE_CAMPAIGN]: false,
      [MEDIA_SIDE_GROUP]: false,
      [MEDIA_SYNC]: axis === PRIORITY_AXIS_MEDIA_SYNC,
    },
    buildAnalysisFilterOption(filters, row, masterData),
    true
  );
};

const buildToCostAllocAnalysisOptions = (row, axis, masterData) => {
  let filters = {};
  if (axis === PRIORITY_AXIS_MEDIA_SYNC) {
    filters = { [SYNC_CATEGORY]: MEDIA_ACCOUNT_ID };
  } else if (axis === PRIORITY_AXIS_EBIS) {
    filters = { [CATEGORY]: MEDIA_ID };
  }
  return buildAnalysisOptions(
    COST_ALLOCATION,
    {
      [CATEGORY]: true,
      [AD_GROUP1]: true,
      [AD_GROUP2]: false,
      [SYNC_CATEGORY]: true,
      [MEDIA_SIDE_CAMPAIGN]: true,
      [MEDIA_SIDE_GROUP]: false,
      [MEDIA_SYNC]: axis === PRIORITY_AXIS_MEDIA_SYNC,
    },
    buildAnalysisFilterOption(filters, row, masterData)
  );
};

const buildToDetailAnalysisOfAdNameOptions = (row) => {
  return buildAnalysisOptions(
    DETAILS_ANALYSIS,
    {
      ad_id: true,
      ad_name: true,
      ad_note: false,
      ad_date: false,
      terminal_type: false,
      [LANDING_PAGE_DOMAIN]: false,
      category: false,
      ad_group1: false,
      ad_group2: false,
      media_side_ad_id: true,
      media_side_ad_name: true,
      media_side_campaign: false,
      media_side_group: false,
      sync_category: false,
      media_sync: false,
    },
    buildAnalysisFilterOption(
      {
        [CATEGORY]: MEDIA_ID,
        [AD_GROUP1]: AD_GROUP1_ID,
        [AD_GROUP2]: AD_GROUP2_ID,
      },
      row
    )
  );
};

const buildAdGroup1AnalysisOptions = (row) => {
  return buildAnalysisOptions(
    CATEGORY_ANALYZE,
    {
      [CATEGORY]: true,
      [AD_GROUP1]: true,
      [AD_GROUP2]: false,
    },
    buildAnalysisFilterOption(
      {
        [CATEGORY]: MEDIA_ID,
        [AD_GROUP1]: AD_GROUP1_ID,
      },
      row
    ),
    true
  );
};

const buildAdGroup2AnalysisOptions = (row) => {
  return buildAnalysisOptions(
    CATEGORY_ANALYZE,
    {
      [CATEGORY]: true,
      [AD_GROUP1]: true,
      [AD_GROUP2]: true,
    },
    buildAnalysisFilterOption(
      {
        [CATEGORY]: MEDIA_ID,
        [AD_GROUP1]: AD_GROUP1_ID,
        [AD_GROUP2]: AD_GROUP2_ID,
      },
      row
    ),
    true
  );
};

const buildMediaSideCampaignAnalysisOptions = (row, masterData) => {
  return buildAnalysisOptions(
    CATEGORY_ANALYZE,
    {
      [FIELD.SYNC_CATEGORY]: true,
      [FIELD.MEDIA_SIDE_CAMPAIGN]: true,
      [FIELD.MEDIA_SIDE_GROUP]: false,
      [FIELD.MEDIA_SIDE_AD_NAME]: false,
      [FIELD.MEDIA_SYNC]: true,
    },
    buildAnalysisFilterOption(
      {
        [SYNC_CATEGORY]: FIELD.MEDIA_ACCOUNT_ID,
        [MEDIA_SIDE_CAMPAIGN]: MEDIA_SIDE_CAMPAIGN_ID,
      },
      row,
      masterData
    ),
    true
  );
};

const buildMediaSideGroupAnalysisOptions = (row, masterData) => {
  return buildAnalysisOptions(
    CATEGORY_ANALYZE,
    {
      [FIELD.SYNC_CATEGORY]: true,
      [FIELD.MEDIA_SIDE_CAMPAIGN]: true,
      [FIELD.MEDIA_SIDE_GROUP]: true,
      [FIELD.MEDIA_SIDE_AD_NAME]: false,
      [FIELD.MEDIA_SYNC]: true,
    },
    buildAnalysisFilterOption(
      {
        [SYNC_CATEGORY]: MEDIA_ACCOUNT_ID,
        [MEDIA_SIDE_CAMPAIGN]: MEDIA_SIDE_CAMPAIGN_ID,
        [MEDIA_SIDE_GROUP]: MEDIA_SIDE_GROUP_ID,
      },
      row,
      masterData
    ),
    true
  );
};

const buildToDetailAnalysisOfSyncAdNameOptions = (row, masterData) => {
  return buildAnalysisOptions(
    DETAILS_ANALYSIS,
    {
      ad_id: true,
      ad_name: true,
      ad_note: false,
      ad_date: false,
      terminal_type: false,
      [LANDING_PAGE_DOMAIN]: false,
      category: false,
      ad_group1: false,
      ad_group2: false,
      media_side_ad_id: true,
      media_side_ad_name: true,
      media_side_campaign: false,
      media_side_group: false,
      sync_category: false,
      media_sync: true,
    },
    buildAnalysisFilterOption(
      {
        [SYNC_CATEGORY]: MEDIA_ACCOUNT_ID,
        [MEDIA_SIDE_CAMPAIGN]: MEDIA_SIDE_CAMPAIGN_ID,
        [MEDIA_SIDE_GROUP]: MEDIA_SIDE_GROUP_ID,
      },
      row,
      masterData
    )
  );
};
/**
 *
 * @param option
 * @param row
 * @return {{targetScreen, filters: {}, items, allowComparedPeriod: boolean}}
 */
const getAnalysisOption = (option, row, axis, masterData) => {
  const {
    AD_GROUP1_FILTER,
    AD_GROUP2_FILTER,
    TO_AD_NAME_ANALYZE,
    TO_COST_ALLOC_SCREEN,
    TO_PERIOD_SCREEN,
    MEDIA_SIDE_CAMPAIGN_FILTER,
    MEDIA_SIDE_GROUP_FILTER,
    TO_MEDIA_SIDE_AD_NAME_ANALYZE,
  } = CONTEXT_MENU_OPTION;
  let options;
  switch (option) {
    case TO_PERIOD_SCREEN:
      options = buildToPeriodAnalysisOptions(row, axis, masterData);
      break;

    case TO_COST_ALLOC_SCREEN:
      options = buildToCostAllocAnalysisOptions(row, axis, masterData);
      break;

    case TO_AD_NAME_ANALYZE:
      options = buildToDetailAnalysisOfAdNameOptions(row);
      break;

    case AD_GROUP1_FILTER:
      options = buildAdGroup1AnalysisOptions(row);
      break;

    case AD_GROUP2_FILTER:
      options = buildAdGroup2AnalysisOptions(row);
      break;

    case MEDIA_SIDE_CAMPAIGN_FILTER:
      options = buildMediaSideCampaignAnalysisOptions(row, masterData);
      break;

    case MEDIA_SIDE_GROUP_FILTER:
      options = buildMediaSideGroupAnalysisOptions(row, masterData);
      break;

    case TO_MEDIA_SIDE_AD_NAME_ANALYZE:
      options = buildToDetailAnalysisOfSyncAdNameOptions(row, masterData);
      break;

    default:
      throw new Error('Invalid analysis option specified');
  }
  return options;
};

export default {
  saveBookmark,
  loadBookmark,
  deleteBookmark,
  createDimensionKeyObject,
  createGetTableDataRequest,
  createTableSumData,
  getTableData,
  getChartData,
  apiListToSeries,
  createSeriesName,
  createLegends,
  getDimensionKeyValue,
  getDimensionKeyValueList,

  updateBookmark,
  getBookmark,
  updateShowChart,
  getAnalysisOption,
};

export const testables = {
  createGetChartDataRequest,
};
