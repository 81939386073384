import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LTV_SETTINGS_CLEAR_CSV_ORDERS } from 'services/routes/constants';

const url = pages[LTV_SETTINGS_CLEAR_CSV_ORDERS].endpoint;
const baseURL = process.env.REACT_APP_LTV_API_HOST;
const clearSetting = async () => {
  return Api.delete(url, { baseURL });
};

export default {
  delete: clearSetting
}