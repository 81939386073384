import {
  inputMediaError,
  msgLineError,
} from 'domain/ad-management/media-group/errorMessages';
import { FilterConfig } from 'domain/commonbar/FilterConfig';
import { MEDIA_ID, AD_GROUP1, AD_GROUP2 } from 'domain/fields';
import DomainFilterService from 'domain/FilterService';
import { getSelectedConversions } from 'domain/utils';
import { createSelector } from 'reselect';
import { FILTER_KEY_AD_GROUP1, FILTER_KEY_AD_GROUP2 } from 'services/consts';
import { formatDataOptions } from 'services/dashboard/settingMailService';
import { getErrorMessageByCode } from 'services/utils';
import filterSelector from 'store/filters/selectors';
import { reflectionTimeSelectors } from 'store/reflectiontime';

const getDisplay = (state) => state.cache.masterData.display;
const getCv = (state) => state.cache.masterData.cv;
const getAgency = (state) => state.cache.masterData.agency;
const getContentCategory = (state) => state.cache.masterData.contentCategory;
const getMedia = (state) => state.cache.masterData.media;
const getUsers = (state) => state.cache.masterData.user;
const getAdGroup1 = (state) => state.cache.masterData.adGroup1;
const getAdGroup2 = (state) => state.cache.masterData.adGroup2;
const getMediaSideCampaign = (state) =>
  state.cache.masterData.mediaSideCampaign;
const getMediaSideGroup = (state) => state.cache.masterData.mediaSideGroup;
const getMediaAccount = (state) => state.cache.masterData.mediaAccount;

const conversionsSelector = (state) => state.cache.masterData.conversions;

const getStatus = (state) => state.cache.masterData.status;
const getStatusConversions = (state) =>
  state.cache.masterData.statusConversions;
const getError = (state) => state.cache.masterData.error;

const conversionsForDisplaySelector = createSelector(
  [filterSelector.getSettings, conversionsSelector],
  (filters, conversions) => {
    return getSelectedConversions(conversions, filters);
  }
);

const getAll = createSelector(
  [
    getCv,
    getDisplay,
    getMedia,
    getAdGroup1,
    getAdGroup2,
    getAgency,
    getMediaSideCampaign,
    getMediaSideGroup,
    getMediaAccount,
    getContentCategory,
  ],
  (
    cv,
    display,
    media,
    adGroup1,
    adGroup2,
    agency,
    mediaSideCampaign,
    mediaSideGroup,
    mediaAccount,
    contentCategory
  ) => ({
    cv,
    display,
    media,
    adGroup1,
    adGroup2,
    agency,
    mediaSideCampaign,
    mediaSideGroup,
    mediaAccount,
    contentCategory,
  })
);

const getDisplayForFilters = createSelector([getDisplay], (display) => {
  const extendedDisplay = { ...display };
  if (display[AD_GROUP1]) {
    extendedDisplay[FILTER_KEY_AD_GROUP1] = display[AD_GROUP1];
  }
  if (display[AD_GROUP2]) {
    extendedDisplay[FILTER_KEY_AD_GROUP2] = display[AD_GROUP2];
  }
  return extendedDisplay;
});

const getExpires = (state) => {
  const { expires } = state.cache.masterData;
  return expires;
};

const getType = (state) => {
  const { type } = state.cache.masterData;
  return type;
};

const getSearchResult = (state) => {
  const { searchResult } = state.cache.masterData;
  return searchResult;
};

const getTotalSearchResult = (state) => {
  const { searchResultTotal } = state.cache.masterData;
  return searchResultTotal;
};

const getSearchResultType = (type) => (state) => {
  const {
    cache: {
      masterData: { searchResult, searchType },
    },
  } = state;

  return searchType !== type ? null : searchResult;
};

const getSearchType = (state) => {
  const { searchType } = state.cache.masterData;
  return searchType;
};

const getForAdManagement = createSelector(
  [
    getStatus,
    getMedia,
    getAdGroup1,
    getAdGroup2,
    getAgency,
    getMediaAccount,
    getMediaSideCampaign,
    getMediaSideGroup,
    getDisplayForFilters,
    getSearchResult,
    getTotalSearchResult,
    getSearchType,
    reflectionTimeSelectors.getIsDisplayAdListing,
  ],
  (
    status,
    medias,
    adGroup1s,
    adGroup2s,
    agencies,
    mediaAccounts,
    mediaSideCampaigns,
    mediaSideGroups,
    display,
    searchResult,
    searchTotal,
    searchType,
    isDisplayAdListing
  ) => {
    const adTypes = isDisplayAdListing
      ? FilterConfig.adTypes
      : FilterConfig.adTypes.filter((adType) => adType.key !== 'listing');
    return {
      status,
      display,
      media: DomainFilterService.uiMapperData(medias),
      adGroup1: DomainFilterService.uiMapperData(adGroup1s),
      adGroup2: DomainFilterService.uiMapperData(adGroup2s),
      agency: DomainFilterService.uiMapperData(agencies),
      mediaAccount: DomainFilterService.uiMapperData(mediaAccounts),
      mediaSideCampaign: DomainFilterService.uiMapperData(mediaSideCampaigns),
      mediaSideGroup: DomainFilterService.uiMapperData(mediaSideGroups),
      devices: FilterConfig.devices.filter((device) => device.key !== '1'),
      adTypes,
      measurementTypes: FilterConfig.measurementTypes,
      search: {
        items: DomainFilterService.uiMapperData(searchResult),
        total: searchTotal,
        type: searchType,
      },
    };
  }
);

const getMasterMedia = createSelector(
  [getMedia, getStatus, getError],
  (list, status, errors) => ({
    status,
    list: formatDataOptions(list),
    error: errors,
  })
);

const getMasterdata = createSelector(
  [getMasterMedia, getStatus, getSearchResult, getSearchType],
  (media, status, searchResult, searchType) => {
    const searchItems = formatDataOptions(searchResult);
    const message = { ...inputMediaError, ...msgLineError };
    return {
      isLoading: status === 'loading',
      media: {
        ...media,
        list: searchType === MEDIA_ID ? searchItems : media.list,
        error: getErrorMessageByCode(media.error, message, {
          label: '媒体種別',
        }),
      },
    };
  }
);

export default {
  conversionsSelector,
  conversionsForDisplaySelector,
  getMedia,
  getUsers,
  getAdGroup1,
  getAdGroup2,
  getAgency,
  getContentCategory,
  getDisplay,
  getCv,
  getAll,
  getType,
  getExpires,
  getSearchResult,
  getSearchType,
  getTotalSearchResult,
  getSearchResultType,
  getForAdManagement,
  getDisplayForFilters,
  getStatus,
  getStatusConversions,
  getMediaSideCampaign,
  getMediaSideGroup,
  getMediaAccount,
  getMasterdata,
  getMasterMedia,
};
