/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ebisLogo from 'assets/images/logo.svg';
import capiSingleLogo from 'assets/images/logo-capi-single.svg';
import NotificationsContainer from 'views/molecules/notification/NotificationsContainer';
import Support from 'views/molecules/topbar/components/Support';
import Reflection from 'views/molecules/topbar/components/Reflection';
import UserContainer from 'views/molecules/topbar/components/UserContainer';
import Setting from 'views/molecules/topbar/components/Setting';
import {
  DATA_EXPORT,
  DASHBOARD,
  CAPI_SETTING,
} from 'services/routes/constants';
import PermissionWrapperRPC from 'services/appconfig/PermissionWrapperRPC';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';

import { dataExportPermissionsAccess } from 'domain/permissions/screenPermissionConfigs';

import 'views/molecules/topbar/topbar.scss';
import SettingSingleCapi from './components/SettingSingleCapi';

const renderForCapiContractSingle = () => {
  return (
    <>
      <Nav variant="pills" className="mr-auto">
        <Link to={`/${CAPI_SETTING}`} className="logo">
          <img height="40" src={capiSingleLogo} alt="AD Ebis Logo" />
        </Link>
      </Nav>
      <SettingSingleCapi />
    </>
  );
};

function Topbar({ isShowNavbar, toggleNavbar, screenId }) {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  const wrapper = classNames('top-bar');

  const { isCapiSingleContract } = useCheckPermissionCapi();

  return (
    <div id="top-bar" className={wrapper} screen-name={screenId}>
      <Navbar collapseOnSelect expand variant="light" bg="white">
        {isCapiSingleContract ? (
          renderForCapiContractSingle()
        ) : (
          <>
            <Nav variant="pills" className="mr-auto">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className="tooltip-common">
                    {!isShowNavbar ? 'サイドナビを開く' : 'サイドナビを閉じる'}
                  </Tooltip>
                }
              >
                <span
                  className={`d-flex align-items-center justify-content-center btn-icon-menu ${
                    isShowNavbar ? '' : 'is-hide'
                  }`}
                  onClick={() => toggleNavbar(!isShowNavbar)}
                  aria-hidden="true"
                >
                  <i className="fas fa-arrow-left" />
                  <i className="fas fa-bars" />
                </span>
              </OverlayTrigger>
              <Link to={`/${DASHBOARD}`} className="logo">
                <img height="40" src={ebisLogo} alt="AD Ebis Logo" />
              </Link>
            </Nav>
            <Setting />
            <PermissionWrapperRPC
              requiredPermissions={dataExportPermissionsAccess}
              resolveType="anyOf"
              render={(isAllowed) => (
                <Nav
                  className={classNames({
                    'navbar-nav-custom': true,
                    disabled: !isAllowed,
                    actived: screenId === DATA_EXPORT,
                  })}
                >
                  <div className="nav-item">
                    <Link
                      to={`/${DATA_EXPORT}`}
                      className={classNames({
                        'nav-link': true,
                        'ebis--disabled': !isAllowed,
                      })}
                    >
                      データエクスポート
                    </Link>
                  </div>
                </Nav>
              )}
            />
          </>
        )}

        <Nav className="navbar-nav-icon">
          {!isCapiSingleContract && <Reflection />}
          <Support />
          <NotificationsContainer />
        </Nav>
        <Nav className="navbar-user">
          <UserContainer />
        </Nav>
      </Navbar>
    </div>
  );
}

Topbar.defaultProps = {
  isShowNavbar: true,
  toggleNavbar: () => {},
};

Topbar.propTypes = {
  isShowNavbar: PropTypes.bool,
  toggleNavbar: PropTypes.func,
  screenId: PropTypes.string.isRequired,
};

export default Topbar;
