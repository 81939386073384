import React, { useRef, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { oneOfType, bool, object, node, string, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import FormGroup from 'views/molecules/FormGroup';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import NumericInput from 'views/atoms/numeric-input/NumericInput';

function FormGroupInput(props) {
  const {
    required,
    label,
    icon,
    note,
    tooltip,
    name,
    value,
    type,
    placeholder,
    style,
    error,
    variant,
    disabled,
    onChange,
  } = props;

  const ref = useRef();
  const isError = !isEmpty(error);

  const handleValueChanged = (e) => {
    const { value: newValue } = e.target;
    onChange(name, newValue);
  };

  const propInput = useMemo(() => {
    return {
      size: 'sm',
      type,
      name,
      value,
      placeholder,
      style,
      className: `${isError ? 'error' : ''}`,
      disabled,
    };
  }, [type, name, value, placeholder, style, disabled, isError]);

  return (
    <FormGroup
      variant={variant}
      required={required}
      label={label}
      icon={icon}
      note={note}
      tooltip={tooltip}
    >
      <div className="d-flex align-items-center form-input" ref={ref}>
        {icon}
        <ErrorTooltipWrapper isError={isError} errorMess={error} ref={ref}>
          {type === 'number' ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <NumericInput {...propInput} onChange={handleValueChanged} />
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Form.Control {...propInput} onChange={handleValueChanged} />
          )}
        </ErrorTooltipWrapper>
      </div>
    </FormGroup>
  );
}

FormGroupInput.propTypes = {
  required: bool,
  disabled: bool,
  label: string,
  note: string,
  icon: oneOfType([string, node]),
  tooltip: oneOfType([string, node]),
  name: string,
  value: string,
  type: string,
  placeholder: string,
  style: oneOfType([object]),
  error: string,
  variant: string,
  onChange: func,
};

FormGroupInput.defaultProps = {
  required: false,
  disabled: false,
  label: '',
  note: '',
  icon: '',
  tooltip: '',
  name: '',
  value: '',
  type: 'text',
  placeholder: '値を入力',
  style: {},
  error: '',
  variant: '',
  onChange: () => {},
};

export default FormGroupInput;
