import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import timeoutNotify from 'domain/timeout-notify';
import logging from 'domain/logging';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import TableModal from 'views/organism/TableModal';
import './media-group-confirm-upload-csv.scss';

const ListItems = ({ list }) => {
  return list && list.length > 0 ? (
    list.map((item) => {
      return (
        <tr key={item.content_category_id}>
          <td>{item.content_category_id}</td>
          <td>{item.content_category_name}</td>
          <td>{item.rank}</td>
        </tr>
      );
    })
  ) : (
    <></>
  );
};

ListItems.propTypes = {
  children: PropTypes.shape({}),
  list: PropTypes.arrayOf(PropTypes.shape({})),
};

ListItems.defaultProps = {
  children: {},
  list: [],
};

const ContentCategoryCsvUploadModal = ({
  isShow,
  loading,
  list,
  title,
  jsxContent,
  onHide,
  onSubmit,
  confirmText,
  cancelText,
  preventClickOutside,
}) => {
  let jsxContentBody = jsxContent;

  if (timeoutNotify.count()) {
    timeoutNotify.reset();
    jsxContentBody = `タイムアウトが発生しました。お手数ですが再度お試しください。`;
    logging.info('timeoutNotify', jsxContentBody);
  }

  return (
    <Modal
      backdrop={preventClickOutside ? 'static' : true}
      show={isShow}
      centered
      size="w600"
      className="modal-main-content upload-csv-modal-confirm"
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={loading}>
          <>
            <TableModal
              variant="table-modal--confirm"
              header={
                <tr>
                  <th>ID</th>
                  <th>ページカテゴリ</th>
                  <th>並び順</th>
                </tr>
              }
              /* eslint-disable-next-line react/prop-types */
              body={<ListItems list={list} />}
              /* eslint-disable-next-line react/prop-types */
              bodyLength={list.length}
              // maxContent={258}
              alignScroll={-20}
            />
            {jsxContentBody}
          </>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button size="sm" variant="link" disabled={loading} onClick={onHide}>
            {cancelText}
          </Button>

          <Button
            size="sm"
            variant="secondary"
            disabled={loading}
            onClick={onSubmit}
          >
            {confirmText}
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

ContentCategoryCsvUploadModal.propTypes = {
  isShow: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onHide: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  preventClickOutside: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  jsxContent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

ContentCategoryCsvUploadModal.defaultProps = {
  isShow: false,
  loading: false,
  title: 'アップロード変更点確認一覧', // to do update
  preventClickOutside: true,
  confirmText: 'OK',
  cancelText: 'キャンセル',
  jsxContent: false,
  onHide: () => ({}),
  list: [],
};

export default ContentCategoryCsvUploadModal;
