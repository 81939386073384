import types from 'store/ad-management-adgroup2/types';
import helper from 'store/helper';

const create = (name, agencyId) =>
  helper.createAction(types.CREATE, { name, agencyId });

const setDataDetail = (data) =>
  helper.createAction(types.SET_DATA_DETAIL, { data });
const setErrorDetail = (errors, scope) =>
  helper.createAction(types.SET_ERROR_DETAIL, { errors }, scope);
const setStatusDetail = (status) =>
  helper.createAction(types.SET_STATUS_DETAIL, { status });

const adManagementAdGroup2Actions = {
  create,

  setDataDetail,
  setErrorDetail,
  setStatusDetail,
};

export default adManagementAdGroup2Actions;
