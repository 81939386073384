import { get, isEmpty } from 'lodash';
import { FIELD_PREFIX, CV_METRICS } from 'domain/consts';
import { getSuffix, getPrefix } from 'services/utils';
import {
  NONE_SPAN,
  HEIGHT_ROW_PARENT,
  HEIGHT_ROW_CHILD,
  MIN_WIDTH_TABLE,
  MIN_WIDTH_COLUMN,
  MIN_WIDTH_COLUMN_FREEZE,
  FULL_WIDTH_COLUMN,
  MAX_CONTENT_WIDTH_COLUMN,
} from './consts';

const getColSpan = (data) => {
  return data.reduce(
    (acc, item) => acc + (item.children ? getColSpan(item.children) : 1),
    0
  );
};

const getTotalRow = (data, countRow = 1) => {
  const counts = data.reduce((acc, item) => {
    const count = [...acc];
    count.push(countRow);
    if (item.children) {
      count.push(getTotalRow(item.children, countRow + 1));
    }
    return count;
  }, []);

  return Math.max(...counts);
};

const getColumns = (data, totalRow, gridRow = 1, gridColumn = 1) => {
  return data.reduce((acc, item, index, arrs) => {
    const items = [...acc];
    const hasChildren = item.children?.length > 0;

    // Calculate span rows and columns
    const rowSpan = hasChildren ? NONE_SPAN : totalRow;
    const colSpan = hasChildren ? getColSpan(item.children) : NONE_SPAN;

    // Calculate the position column
    let gridColumnActual = gridColumn + index;
    const lastItem = acc.pop();
    if (lastItem) {
      gridColumnActual = lastItem.gridColumn + 1;
    }

    const column = {
      gridRow,
      gridColumn: gridColumnActual,
      gridArea: `${gridRow} / ${gridColumnActual} / span ${rowSpan} / span ${colSpan}`,
      isFreeze: get(item, 'isFreeze', false),
      isParent: hasChildren,
      isResizable: !hasChildren && !get(item, 'width', false),
      isResizeGroup: !hasChildren && index === arrs.length - 1,
      sort: hasChildren ? 'none' : 'other',
      ...item,
    };

    items.push(column);

    if (hasChildren) {
      const childItems = getColumns(
        item.children,
        totalRow - 1,
        column.gridRow + 1,
        column.gridColumn
      );

      items.push(...childItems);
    }

    return items;
  }, []);
};

export const getKeyTable = (isFreeze) => {
  return isFreeze ? 'freeze' : 'main';
};

export const checkHasOtherTable = (data, isFreeze) => {
  return data.find((item) => !!item.isFreeze !== isFreeze);
};

export const getTemplate = (data, isFreeze, isRowEmpty) => {
  const hasOtherTable = checkHasOtherTable(data, isFreeze);
  const isFreezeTable = hasOtherTable && isFreeze;
  const totalRow = getTotalRow(data);
  const columns = getColumns(
    data.filter((item) => !!item.isFreeze === isFreeze),
    totalRow
  ).filter((item) => !item.children);

  const minWidthDefault = isFreezeTable
    ? MIN_WIDTH_COLUMN_FREEZE
    : `${isRowEmpty ? MAX_CONTENT_WIDTH_COLUMN : MIN_WIDTH_COLUMN}`;

  const gridTemplateColumns = columns.reduce((acc, column) => {
    const { field, width } = column;
    let { minWidth = width, maxWidth = width, defaultWidth = null } = column;

    defaultWidth = isRowEmpty || isFreeze || width ? null : defaultWidth;

    minWidth = defaultWidth || minWidth || `${minWidthDefault}px`;
    maxWidth =
      defaultWidth ||
      maxWidth ||
      (isFreezeTable ? `${minWidthDefault}px` : FULL_WIDTH_COLUMN);

    const actualWidth =
      minWidth === maxWidth ? minWidth : `minmax(${minWidth}, ${maxWidth})`;

    return { ...acc, [field]: `[${field}] ${actualWidth}` };
  }, {});

  const gridTemplateRows = [];
  if (totalRow > 1) {
    for (let i = 0; i < totalRow; i += 1) {
      const heightRow = i === 0 ? HEIGHT_ROW_PARENT : HEIGHT_ROW_CHILD;
      gridTemplateRows.push(`${heightRow}px`);
    }
  }

  return {
    gridTemplateColumns,
    gridTemplateRows: gridTemplateRows.join(' '),
  };
};

export const getHeader = (data, isFreeze, isResizable, isSortable) => {
  const totalRow = getTotalRow(data);
  const headers = getColumns(
    data.filter((item) => !!item.isFreeze === isFreeze),
    totalRow
  );

  if (isEmpty(headers)) return [];

  const indexLastColumn = headers.reduce((acc, item, index) => {
    // Turn off resize columns
    if (!isResizable) {
      headers[index].isResizable = false;
    }
    // Turn off sort columns
    if (!isSortable) {
      headers[index].sort = 'none';
    }

    // Find last column allow resize to set max-width = 100%
    return !isResizable || get(item, 'isResizable', true) ? index : acc;
  }, 0);

  headers[indexLastColumn].isLastColumn = true;
  headers[headers.length - 1].isResizable = false;

  return headers;
};

export const getHeaderForBody = (headers) => {
  const data = headers
    .filter((item) => !('children' in item))
    .reduce(
      (acc, item) => {
        const { field, parentField, isMergeColumn = false } = item;

        if (!isMergeColumn) {
          return { ...acc, list: { ...acc.list, [field]: item } };
        }

        const newParent = {
          isMergeColumns: true,
          field: parentField,
          children: [],
        };
        const parent = { ...(acc.list[parentField] || newParent) };
        return {
          ...acc,
          parentFields: [...acc.parentFields, parentField],
          list: {
            ...acc.list,
            [parentField]: {
              ...parent,
              children: [...parent.children, item],
            },
          },
        };
      },
      { list: {}, parentFields: [] }
    );

  return {
    headers: Object.values(data.list),
    parentFields: data.parentFields,
  };
};

export const getTooltip = (column, tooltips) => {
  const suffix = getSuffix(column, Object.values(FIELD_PREFIX));
  const prefix = getPrefix(column, Object.values(FIELD_PREFIX));
  return tooltips[suffix] || tooltips[prefix];
};
export const getWidthTable = (
  currentWidth,
  totalWidth,
  isFreeze = true,
  isResizing = true
) => {
  if (!isFreeze) return currentWidth;

  // Case of Initial: max width 40% of table width
  // Case of Resizing: max width 80% of table width & min width 100px
  const maxWidth = isResizing ? totalWidth * 0.8 : totalWidth * 0.4;
  return Math.min(Math.max(currentWidth, MIN_WIDTH_TABLE), maxWidth);
};

export const getPositionShadow = (widthFreeze, widthMain, isScrolling) => {
  let shadow = '';
  if (widthFreeze > 0) {
    shadow = 'left';
  } else if (widthMain > 0 && isScrolling) {
    shadow = 'right';
  }

  return shadow;
};

export const getHeightRow = (rowEl, callback = () => {}) => {
  const gridEl = rowEl.closest('[role="grid"]');
  if (!gridEl) return {};

  const rowIndex = rowEl.attributes['aria-rowIndex'].value;
  const cellEls = gridEl.querySelectorAll(
    `[role="rowgroup"] [aria-rowindex="${rowIndex}"] [role="cell"]`
  );

  const calculateHeight = (el) => {
    const { paddingTop, paddingBottom } = window.getComputedStyle(el);
    const padding = parseInt(paddingTop, 10) + parseInt(paddingBottom, 10);
    return el.firstChild.getBoundingClientRect().height + padding;
  };

  const heights = [];
  Array.from(cellEls).forEach((cellEl) => {
    const parentEl = cellEl.parentNode;
    const isSubgrid = !!parentEl.getAttribute('aria-colspan');
    let heightRow = 0;
    if (isSubgrid) {
      const cellIndex = Array.prototype.indexOf.call(parentEl.children, cellEl);
      const rowSubgridEls = cellEl
        .closest('[aria-rowspan]')
        .querySelectorAll('[aria-colspan]');
      Array.from(rowSubgridEls).forEach((rowSubgridEl) => {
        heightRow += calculateHeight(rowSubgridEl.childNodes[cellIndex]);
      });
    } else {
      heightRow = calculateHeight(cellEl);
    }

    heights.push(heightRow);
  });

  const height = { [rowIndex]: Math.ceil(Math.max(...heights)) };

  callback({ type: 'setHeightRow', payload: height });

  return height;
};

export const formatGridTemplateRows = (heights) => {
  let count = 1;
  const gridTemplateRows = heights.reduce((acc, height, index) => {
    if (height === heights[index + 1]) {
      count += 1;
    } else {
      acc.push(count === 1 ? `${height}px` : `repeat(${count}, ${height}px)`);
      count = 1;
    }
    return acc;
  }, []);

  return gridTemplateRows.join(' ');
};

export const createHeadersByDisplayItems = ({
  currentTab,
  displayItems,
  dimensions = [],
  metrics,
  conversions,
  groups = [],
}) => {
  const settings = [...dimensions, ...metrics];

  const headers = Object.values(
    displayItems.reduce((acc, item) => {
      const { tab, displayFreeze, key: field } = item;
      if (!settings.includes(field) || (tab && tab !== currentTab)) {
        return acc;
      }

      const newItem = {
        field,
        name: item.title,
        isFreeze: !!displayFreeze,
        needFormat: true,
      };

      const group = groups.find((g) => g.children.includes(field));
      if (group) {
        return {
          ...acc,
          [group.field]: {
            ...group,
            isFreeze: !!displayFreeze,
            children: [...(acc[group.field]?.children || []), newItem],
          },
        };
      }

      return { ...acc, [field]: newItem };
    }, {})
  );

  const notFound = -1;
  const conversionIndex = CV_METRICS.reduce((acc, metric) => {
    const index = headers.findIndex((column) => metric === column.field);
    if (index === notFound) return acc;
    headers.splice(index, 1);
    return index; // save index order by descending
  }, notFound);

  if (conversionIndex > notFound && !isEmpty(conversions)) {
    const itemsCv = CV_METRICS.filter((metric) => metrics.includes(metric)).map(
      (metric) => {
        const data = displayItems.find((item) => item.key === metric);
        return { field: data.key, name: data.titleTable || data.title };
      }
    );

    const conversionColumns = conversions.map((conversion) => ({
      field: `cnt_direct_${conversion.id}`,
      name: conversion.name,
      sort: 'none',
      children: itemsCv.map((itemCv) => ({
        ...itemCv,
        field: `${itemCv.field}_${conversion.id}`,
        needFormat: true,
      })),
    }));

    headers.splice(conversionIndex, 0, ...conversionColumns);
  }

  return headers;
};
