import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { number, string, func, arrayOf, objectOf, shape } from 'prop-types';
import { FilterTypeTwo } from 'views/organism/filter/FilterTypes';

const initialItem = { option: null, values: [] };

const FilterTypeTwoMultiple = (props) => {
  const { name, options, presets, note, maxItem, error, onChange } = props;
  const [items, setItems] = useState(
    presets.values
      ? presets.values
      : [{ ...initialItem, option: options[0].key }]
  );

  const totalItem = items.length;
  const optionsSelected = items.map((item) => item.option);

  const handleAddItem = () => {
    setItems((prevData) => {
      const optionsRemain = options.filter(
        (option) => !optionsSelected.includes(option.key)
      );
      return [...prevData, { ...initialItem, option: optionsRemain[0].key }];
    });
  };

  const handleDeleteItem = (index) => () => {
    setItems((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleChangeItem = (index) => (values, option) => {
    setItems((prevData) =>
      prevData.map((item, i) => {
        if (index !== i) return item;
        return { ...item, values, option };
      })
    );
  };

  useEffect(() => {
    onChange(items);
  }, [items, onChange]);

  return (
    <>
      {items.map((item, index) => {
        const displaySeparator = totalItem > 1 && index < totalItem - 1;
        const allowDelete = totalItem > 1;
        const key = `${name}_${index}`;
        const errorByIndex = error?.[key] ? { [key]: error?.[key] } : null;
        let optionsRemain = options;
        if (totalItem > 1) {
          optionsRemain = options.filter(
            (option) =>
              option.key === item.option ||
              !optionsSelected.includes(option.key)
          );
        }

        return (
          <>
            <FilterTypeTwo
              keepOption
              key={`${key}_${totalItem}`}
              name={name}
              presets={item}
              options={optionsRemain}
              note={note}
              error={errorByIndex}
              onDelete={allowDelete && handleDeleteItem(index)}
              onChange={handleChangeItem(index)}
            />
            {displaySeparator && (
              <div className="filter-type__separator">OR（または）</div>
            )}
          </>
        );
      })}
      {totalItem < maxItem && (
        <Button
          size="sm"
          variant="link"
          className="mt-10 ml-15 mr-15 mb-15 p-0 border-0"
          onClick={handleAddItem}
        >
          追加
        </Button>
      )}
    </>
  );
};

FilterTypeTwoMultiple.propTypes = {
  name: string.isRequired,
  options: arrayOf({}).isRequired,
  presets: objectOf(shape({})),
  error: objectOf(shape({})),
  note: string,
  maxItem: number,
  onChange: func,
};

FilterTypeTwoMultiple.defaultProps = {
  note: '',
  maxItem: 1,
  presets: {},
  error: {},
  onChange: () => {},
};

export default React.memo(FilterTypeTwoMultiple);
