import { createSelector } from 'reselect';

// data
const getMetadata = (state) => state.cache.dashboard.data.metadata;
const getPeriodList = (state) => state.cache.dashboard.data.metadata.periods;
const getKpiBorders = (state) => state.cache.dashboard.data.borders;
const getKpiList = (state) => state.cache.dashboard.data.pickups;
const getKpiCompare = (state) => state.cache.dashboard.data.compare;
const getQuickReport = (state) => state.cache.dashboard.data.chart;
const getMediaReport = (state) => state.cache.dashboard.data.report;
const getSettingMailData = (state) =>
  state.cache.dashboard.data.settingMail.data;
const getCurrentKpiPeriod = (state) =>
  state.cache.dashboard.data.metadata.currentKpiPeriod;

// settings
const getPeriodSelected = (state) => state.cache.dashboard.settings.period;
const getPeriodId = (state) => state.cache.dashboard.settings.targetPeriod;
const getKpiItem = (state) => state.cache.dashboard.settings.pickup;
const getQuickReportDisplay = (state) => state.cache.dashboard.settings.chart;
const getTargetCompare = (state) =>
  state.cache.dashboard.settings.comparePeriod;
const getShowKpiBorder = (state) =>
  state.cache.dashboard.settings.showKpiStatusModal;
const getShowSettingMail = (state) =>
  state.cache.dashboard.settings.showSettingMailModal;

// apiStatus
const getApiStatus = (state) => state.cache.dashboard.apiStatus;
const getStatusMetadata = (state) =>
  state.cache.dashboard.apiStatus.metadata.status;
const getStatusBorder = (state) =>
  state.cache.dashboard.apiStatus.border.status;
const getStatusPickup = (state) =>
  state.cache.dashboard.apiStatus.pickup.status;
const getStatusChart = (state) => state.cache.dashboard.apiStatus.chart.status;
const getStatusReport = (state) =>
  state.cache.dashboard.apiStatus.report.status;
const getSettingMailStatus = (state) =>
  state.cache.dashboard.apiStatus.settingMail.status;
const getStatusListUser = (state) => state.cache.dashboard.apiStatus.listUsers;

const getHeader = createSelector(() => {
  return [
    {
      field: 'rowId',
      name: '',
      type: 'checkbox',
      isFreeze: true,
      width: '46px',
    },
    {
      field: 'user_id',
      name: 'ユーザーID',
      isFreeze: true,
      width: '113px',
    },
    {
      field: 'company_name',
      name: '会社名',
      width: '133px',
    },
    {
      field: 'charge_name',
      name: '名前',
      width: '122px',
    },
    {
      field: 'view_all',
      // name: '閲覧 （全トラフィック）',
      name: ['閲覧', '（全トラフィック）'],
      variant: 'icon-check',
      width: '148px',
      isSettingMail: true,
    },
    {
      field: 'view_ad',
      // name: '閲覧 （広告）',
      name: ['閲覧', '（広告）'],
      variant: 'icon-check',
      width: '83px',
      isSettingMail: true,
    },
    {
      field: 'email',
      name: 'メールアドレス',
      width: '189px',
    },
  ];
});

export default {
  getApiStatus,
  getStatusMetadata,
  getStatusBorder,
  getStatusPickup,
  getStatusChart,
  getStatusReport,
  getMediaReport,
  getQuickReport,
  getQuickReportDisplay,
  getShowKpiBorder,
  getTargetCompare,
  getKpiCompare,
  getPeriodId,
  getMetadata,
  getPeriodSelected,
  getPeriodList,
  getKpiBorders,
  getKpiList,
  getKpiItem,
  getHeader,
  getSettingMailData,
  getShowSettingMail,
  getSettingMailStatus,
  getCurrentKpiPeriod,
  getStatusListUser,
};
