import React, { useState, useRef } from 'react';
import { Dropdown, Col, Row, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SettingSingleCapiContractConfig } from 'domain/topbar/SettingConfig';

import useClickOutside from 'services/custom-hooks/useClickOutside';
import ExternalTransitionWrapper from 'services/routes/ExternalTransitionWrapper';
import PermissionWrapperRPC from 'services/appconfig/PermissionWrapperRPC';

const SettingSingleCapi = () => {
  const { t } = useTranslation();

  const settingKeys = Object.keys(SettingSingleCapiContractConfig);

  const [showSettingDropdown, toggleSettingDropdown] = useState(false);

  const handleDropdownToggle = (isOpen) => {
    toggleSettingDropdown(isOpen);
  };

  const clickRef = useRef();
  const onClickOutside = () => {
    if (showSettingDropdown) {
      toggleSettingDropdown(false);
    }
  };

  useClickOutside(clickRef, onClickOutside);

  return (
    <Nav className={`navbar-nav-custom `}>
      <Dropdown
        ref={clickRef}
        onToggle={(isOpen) => handleDropdownToggle(isOpen)}
        show={showSettingDropdown}
        bsPrefix={`dropdown nav-item w-100 h-100 ${''}`}
      >
        <Dropdown.Toggle
          as="a"
          role="button"
          bsPrefix="nav-link view w-100 h-100"
        >
          {t('topbar.page_setting.toggle')}
        </Dropdown.Toggle>
        <Dropdown.Menu
          alignRight="true"
          variant="custom-view"
          className="nav-setting nav-setting__single-capi navbar--border-top-red"
        >
          <Row>
            <Col>
              {settingKeys.map((item) => {
                const settingItem = SettingSingleCapiContractConfig[item];
                return (
                  <PermissionWrapperRPC
                    key={settingItem.key}
                    requiredPermissions={settingItem.permissions}
                    resolveType="anyOf"
                    render={(isAllowed) => {
                      return (
                        <ExternalTransitionWrapper
                          link={settingItem.url}
                          type={settingItem.type}
                          newTab={settingItem.newTab}
                          disabled={!isAllowed}
                        >
                          <Dropdown.Item
                            as="div"
                            className={isAllowed ? '' : 'ebis--disabled'}
                            disabled={!isAllowed}
                          >
                            {t(settingItem.key)}
                          </Dropdown.Item>
                        </ExternalTransitionWrapper>
                      );
                    }}
                  />
                );
              })}
            </Col>
          </Row>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  );
};

export default React.memo(SettingSingleCapi);
