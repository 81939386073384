import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import settingsSelectors from 'store/settings/selectors';
import tagManagementSelectors from 'store/tag-management/selectors';
import tagManagementActions from 'store/tag-management/actions';
import TagManagementContainer from 'views/pages/tag-management';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import { SORT_DIRECTION_ASC } from 'domain/settings/display-items';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';
import { RANK, REGIST_DATE } from 'domain/fields';

/**
 * Will trigger loading when active tab and path is not matching.
 * Also dispatch change tab action
 * @return {JSX.Element}
 */
function TagManagementLoader() {
  const mode = useSelector(settingsSelectors.getMode);
  const currentMode = useSelector(tagManagementSelectors.getTab);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentMode === mode) return;

    dispatch(
      tagManagementActions.cleanStates({
        tab: mode,
        sort: {
          field:
            mode === TAG_MANAGEMENT_TAB.CONTENT_CATEGORY ? RANK : REGIST_DATE,
          direction: SORT_DIRECTION_ASC,
        },
      })
    );
  },[dispatch, currentMode, mode])

  return (
    <DataSyncLoader isLoading={currentMode !== mode}>
      <TagManagementContainer />
    </DataSyncLoader>
  );
}

export default TagManagementLoader;
