import pages from 'services/routes/pages';
import * as pathConstant from 'services/routes/constants';

const NavbarConfig = {
  aside: {
    self: {
      submenuKey: ['サイト分析', 'ユーザー分析'],
    },
    itemsLeft: [
      {
        screenId: pathConstant.DASHBOARD,
        title: pages[pathConstant.DASHBOARD].key,
        iconClass: 'far fa-border-all',
        url: pages[pathConstant.DASHBOARD].path,
        svg: 'dashboard',
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.CATEGORY_ANALYZE,
        title: pages[pathConstant.CATEGORY_ANALYZE].key,
        iconClass: 'far fa-analytics',
        url: pages[pathConstant.CATEGORY_ANALYZE].path,
        svg: 'category',
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.DETAILS_ANALYSIS,
        title: pages[pathConstant.DETAILS_ANALYSIS].key,
        iconClass: 'far fa-search-plus fa-flip-horizontal',
        url: pages[pathConstant.DETAILS_ANALYSIS].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.COMPARE_PERIOD,
        title: pages[pathConstant.COMPARE_PERIOD].key,
        iconClass: 'far fa-calendar-alt',
        url: pages[pathConstant.COMPARE_PERIOD].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.LANDING_PAGE_ANALYZE,
        title: pages[pathConstant.LANDING_PAGE_ANALYZE].key,
        iconClass: 'far fa-browser',
        url: pages[pathConstant.LANDING_PAGE_ANALYZE].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.CV_ATTRIBUTE,
        title: pages[pathConstant.CV_ATTRIBUTE].key,
        iconClass: 'far fa-flag-alt',
        url: pages[pathConstant.CV_ATTRIBUTE].path,
        svg: 'cv_attr',
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.CV_FLOW,
        title: pages[pathConstant.CV_FLOW].key,
        iconClass: 'far fa-random',
        url: pages[pathConstant.CV_FLOW].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.COST_ALLOCATION,
        title: pages[pathConstant.COST_ALLOCATION].key,
        iconClass: 'far fa-yen-sign',
        url: pages[pathConstant.COST_ALLOCATION].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.LPO_EBIS,
        title: pages[pathConstant.LPO_EBIS].key,
        iconClass: 'far fa-browser',
        svg: 'lpo_ebis',
        url: pathConstant.HYPERLINK_HASH,
        submenu: [
          {
            screenId: pathConstant.LPO_LINK,
            title: pages[pathConstant.LPO_LINK].key,
            iconClass: 'far fa-arrow-right',
            url: pages[pathConstant.LPO_LINK].path,
          },
          {
            screenId: pathConstant.LPO_PERIOD,
            title: pages[pathConstant.LPO_PERIOD].key,
            iconClass: 'far fa-arrow-right',
            url: pages[pathConstant.LPO_PERIOD].path,
          },
        ],
      },
      {
        screenId: pathConstant.LTV_ANALYZE,
        title: pages[pathConstant.LTV_ANALYZE].key,
        iconClass: 'far fa-user-chart',
        url: pages[pathConstant.LTV_ANALYZE].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
    ],
    itemsRight: [
      {
        screenId: pathConstant.DASHBOARD,
        title: pages[pathConstant.DASHBOARD].key,
        iconClass: 'far fa-border-all',
        url: pages[pathConstant.DASHBOARD].path,
        svg: 'dashboard',
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.CATEGORY_ANALYZE,
        title: pages[pathConstant.CATEGORY_ANALYZE].key,
        iconClass: 'far fa-analytics',
        url: pages[pathConstant.CATEGORY_ANALYZE].path,
        svg: 'category',
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.DETAILS_ANALYSIS,
        title: pages[pathConstant.DETAILS_ANALYSIS].key,
        iconClass: 'far fa-search-plus fa-flip-horizontal',
        url: pages[pathConstant.DETAILS_ANALYSIS].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.COMPARE_PERIOD,
        title: pages[pathConstant.COMPARE_PERIOD].key,
        iconClass: 'far fa-calendar-alt',
        url: pages[pathConstant.COMPARE_PERIOD].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.LANDING_PAGE_ANALYZE,
        title: pages[pathConstant.LANDING_PAGE_ANALYZE].key,
        iconClass: 'far fa-browser',
        url: pages[pathConstant.LANDING_PAGE_ANALYZE].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.CV_ATTRIBUTE,
        title: pages[pathConstant.CV_ATTRIBUTE].key,
        iconClass: 'far fa-flag-alt',
        url: pages[pathConstant.CV_ATTRIBUTE].path,
        svg: 'cv_attr',
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.CV_FLOW,
        title: pages[pathConstant.CV_FLOW].key,
        iconClass: 'far fa-random',
        url: pages[pathConstant.CV_FLOW].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
      {
        screenId: pathConstant.LOG_EBIS,
        title: pages[pathConstant.LOG_EBIS].key,
        iconClass: 'far fa-browser',
        svg: 'log_ebis',
        url: pathConstant.HYPERLINK_HASH,
        submenu: [
          {
            screenId: pathConstant.LOG_PAGE_ANALYZE,
            title: pages[pathConstant.LOG_PAGE_ANALYZE].key,
            svg: 'log_analyze',
            url: pages[pathConstant.LOG_PAGE_ANALYZE].path,
          },
          {
            screenId: pathConstant.LOG_PERIOD_ANALYZE,
            title: pages[pathConstant.LOG_PERIOD_ANALYZE].key,
            svg: 'log_analyze',
            url: pages[pathConstant.LOG_PERIOD_ANALYZE].path,
          },
          {
            screenId: pathConstant.LOG_ROUTE_ANALYZE,
            title: pages[pathConstant.LOG_ROUTE_ANALYZE].key,
            svg: 'log_analyze',
            url: pages[pathConstant.LOG_ROUTE_ANALYZE].path,
          },
        ],
      },
      {
        screenId: pathConstant.USER_ANALYSIS_SCREEN,
        title: 'ユーザー分析',
        iconClass: 'far fa-browser',
        svg: 'audience',
        url: pathConstant.HYPERLINK_HASH,
        submenu: [
          {
            screenId: pathConstant.CUSTOM_JOURNEY_SCREEN,
            title: 'カスタマージャーニー分析',
            iconClass: 'far fa-arrow-right',
            url: pathConstant.CUSTOM_JOURNEY,
            type: pathConstant.EXTERNAL,
            newTab: true,
          },
          {
            screenId: pathConstant.RATE_ANALYZE,
            title: 'アクション喚起率分析',
            iconClass: 'far fa-arrow-right',
            url: pathConstant.RATE_ANALYSIS,
            type: pathConstant.EXTERNAL,
            newTab: true,
          },
        ],
      },
      {
        screenId: pathConstant.LTV_ANALYZE,
        title: pages[pathConstant.LTV_ANALYZE].key,
        iconClass: 'far fa-user-chart',
        url: pages[pathConstant.LTV_ANALYZE].path,
        type: pathConstant.INTERNAL,
        newTab: false,
      },
    ],
  },
};

export default NavbarConfig;
