import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import './scrollbar-wrapper.scss';

const ScrollbarWrapper = React.forwardRef((props, ref) => {
  const {
    children,
    maxContent,
    sizeScroll,
    alignScroll,
    scrollIntoView,
    setScrollTop,
  } = props;

  const scrollableContentRef = useRef(null);
  const scrollableScrollRef = useRef(null);
  const scrollerRef = useRef(null);
  const [positionScroller, setPositionScroller] = useState(0);
  const [heightScroller, setHeightScroller] = useState(0);

  const MIN_HEIGHT_SCROLLER = 17;

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.classList.add('scrollbar-wrapper');
    }
  }, [ref]);

  useEffect(() => {
    const scrollableContentEl = scrollableContentRef.current;
    const scrollableScrollEl = scrollableScrollRef.current;
    const scrollerEl = scrollerRef.current;

    let contentPosition = 0;
    let scrollerBeingDragged = false;
    let normalizedPosition;

    const startDrag = (e) => {
      normalizedPosition = e.pageY;
      contentPosition = scrollableContentEl.scrollTop;
      scrollerBeingDragged = true;
    };

    const stopDrag = () => {
      scrollerBeingDragged = false;
    };

    const clickScroller = (event) => {
      if (event.target.className === scrollableScrollEl.className) {
        const visibleRatio =
          scrollableContentEl.offsetHeight / scrollableContentEl.scrollHeight;
        const top =
          event.offsetY > scrollableContentEl.scrollTop * visibleRatio
            ? (event.offsetY - heightScroller) / visibleRatio
            : event.offsetY / visibleRatio;
        scrollableContentEl.scroll({
          top,
          behavior: 'smooth',
        });
      }
    };

    const moveScrollerBrowser = (e) => {
      if (scrollerBeingDragged === true) {
        const mouseDifferential = e.pageY - normalizedPosition;
        const scrollEquivalent =
          mouseDifferential *
          (scrollableContentEl.scrollHeight / scrollableContentEl.offsetHeight);
        scrollableContentEl.scrollTop = contentPosition + scrollEquivalent;
      }
    };

    const moveScrollerCustom = () => {
      const scrollPercentage =
        scrollableContentEl.scrollTop / scrollableContentEl.scrollHeight;
      let topPosition = scrollPercentage * scrollableContentEl.offsetHeight;
      if (heightScroller > 0 && heightScroller < MIN_HEIGHT_SCROLLER) {
        topPosition -=
          scrollPercentage * (MIN_HEIGHT_SCROLLER - heightScroller);
      }
      setPositionScroller(Math.floor(topPosition));
    };
    moveScrollerCustom();

    const setSizeScroller = () => {
      setTimeout(() => {
        const visibleRatio =
          scrollableContentEl.offsetHeight / scrollableContentEl.scrollHeight;
        const scrollerHeight = visibleRatio * scrollableContentEl.offsetHeight;
        setHeightScroller(visibleRatio < 1 ? scrollerHeight : 0);
      }, 100);
    };

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        setSizeScroller();
        moveScrollerCustom();
      });
    });

    const mutationObserver = new MutationObserver(() => {
      window.requestAnimationFrame(() => {
        setSizeScroller();
        moveScrollerCustom();
      });
    });

    // Event
    scrollerEl.addEventListener('mousedown', startDrag);
    window.addEventListener('mouseup', stopDrag);
    window.addEventListener('mousemove', moveScrollerBrowser);
    scrollableContentEl.addEventListener('scroll', moveScrollerCustom);
    scrollableScrollEl.addEventListener('click', clickScroller);
    resizeObserver.observe(scrollableContentEl);
    mutationObserver.observe(scrollableContentEl, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    return () => {
      scrollerEl.removeEventListener('mousedown', startDrag);
      window.removeEventListener('mouseup', stopDrag);
      window.removeEventListener('mousemove', moveScrollerBrowser);
      scrollableContentEl.removeEventListener('scroll', moveScrollerCustom);
      scrollableScrollEl.removeEventListener('click', clickScroller);
      resizeObserver.unobserve(scrollableContentEl);
      mutationObserver.disconnect(scrollableContentEl);
    };
  }, [scrollableContentRef, heightScroller]);

  useEffect(() => {
    if (isEmpty(scrollIntoView)) return;

    const scrollableContentEl = scrollableContentRef.current;
    scrollableContentEl.scroll({
      behavior: 'smooth',
      top: scrollIntoView === 'top' ? 0 : scrollableContentEl.scrollHeight,
    });
  }, [heightScroller, scrollIntoView]);

  useEffect(() => {
    setScrollTop(positionScroller);
  }, [setScrollTop, positionScroller]);

  return (
    <>
      <div
        ref={scrollableContentRef}
        className="scrollbar-wrapper__content"
        style={{
          maxHeight: `${maxContent}px`,
        }}
      >
        {children}
      </div>
      <div
        ref={scrollableScrollRef}
        className="scrollbar-wrapper__scroll"
        style={{
          width: `${sizeScroll}px`,
          right: `${alignScroll}px`,
        }}
      >
        <div
          ref={scrollerRef}
          style={{
            top: positionScroller,
            height:
              heightScroller > 0 && heightScroller < MIN_HEIGHT_SCROLLER
                ? MIN_HEIGHT_SCROLLER
                : heightScroller,
          }}
        />
      </div>
    </>
  );
});

ScrollbarWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  maxContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scrollIntoView: PropTypes.oneOf([false, 'top', 'bottom']),
  sizeScroll: PropTypes.number,
  alignScroll: PropTypes.number,
  setScrollTop: PropTypes.func,
};

ScrollbarWrapper.defaultProps = {
  maxContent: '',
  scrollIntoView: false,
  sizeScroll: 10,
  alignScroll: 0,
  setScrollTop: () => {},
};

export default ScrollbarWrapper;
