// About Table
const FETCH = 'media-sync-management/FETCH';
const UPDATE_SORT = 'media-sync-management/UPDATE_SORT';
const SET_DATA_TABLE = 'media-sync-management/SET_DATA_TABLE';
const SET_STATUS_TABLE = 'media-sync-management/SET_STATUS_TABLE';
const REQUEST_FAILED_TABLE = 'media-sync-management/REQUEST_FAILED_TABLE';

// About Detail
const GET = 'media-sync-management/GET';
const SET_DATA_DETAIL = 'media-sync-management/SET_DATA_DETAIL';
const SET_STATUS_DETAIL = 'media-sync-management/SET_STATUS_DETAIL';

// About Validate
const VALIDATE = 'media-sync-management/VALIDATE';
const SET_STATUS_VALIDATE = 'media-sync-management/SET_STATUS_VALIDATE';

const SET_STATUS = 'media-sync-management/SET_STATUS';
const SET_ERRORS = 'media-sync-management/SET_ERRORS';

// Settings
const CREATE = 'media-sync-management/CREATE';
const UPDATE = 'media-sync-management/UPDATE';
const SET_STATUS_UPDATE = 'media-sync-management/SET_STATUS_UPDATE';

const GET_MASTER_DATA = 'media-sync-management/GET_MASTER_DATA';
const GET_STATUS_ACCOUNT = 'media-sync-management/GET_STATUS_ACCOUNT';
const SET_STATUS_ACCOUNT = 'media-sync-management/SET_STATUS_ACCOUNT';
const ISSUE_ACCOUNT = 'media-sync-management/ISSUE_ACCOUNT';
const DELETE_ACCOUNT = 'media-sync-management/DELETE_ACCOUNT';
const SET_STATUS_DELETE = 'media-sync-management/SET_STATUS_DELETE';
const CHANGE_STATUS = 'media-sync-management/CHANGE_STATUS';
const SET_STATUS_STOP = 'media-sync-management/SET_STATUS_STOP';

// Ad account setting
// get detail ad account
const GET_AD_ACCOUNT = 'media-sync-management/GET_AD_ACCOUNT';
const SET_DATA_AD_ACCOUNT = 'media-sync-management/SET_DATA_AD_ACCOUNT';
const SET_STATUS_AD_ACCOUNT = 'media-sync-management/SET_STATUS_AD_ACCOUNT';

const GENERATE_AUTH_URL = 'media-sync-management/GENERATE_AUTH_URL';

const GET_LIST_ADVERTISER = 'media-sync-management/GET_LIST_ADVERTISER';
const SET_LIST_ADVERTISER = 'media-sync-management/SET_LIST_ADVERTISER';

const GET_MARGINS = 'media-sync-management/GET_MARGINS';
const SET_MARGINS = 'media-sync-management/SET_MARGINS';

const VERIFY_ADVERTISER_ID_REGISTERED =
  'media-sync-management/VERIFY_ADVERTISER_ID_REGISTERED';

const CHECK_ADVERTISER_ID = 'media-sync-management/CHECK_ADVERTISER_ID';
const SEND_LINK_AUTH_LINE = 'media-sync-management/SEND_LINK_AUTH_LINE';
const VERIFY_CLIENT_APPROVAL = 'media-sync-management/VERIFY_CLIENT_APPROVAL';
const VERIFY_DOWLOADED_IMAGE = 'media-sync-management/VERIFY_DOWLOADED_IMAGE';
const SET_STATUS_AUTH_LINE = 'media-sync-management/SET_STATUS_AUTH_LINE';
const VERIFY_PASSWORD_LINE = 'media-sync-management/VERIFY_PASSWORD_LINE';

export default {
  FETCH,
  UPDATE_SORT,
  SET_DATA_TABLE,
  SET_STATUS_TABLE,
  REQUEST_FAILED_TABLE,
  GET_STATUS_ACCOUNT,
  DELETE_ACCOUNT,
  SET_STATUS_DELETE,
  GET,
  SET_DATA_DETAIL,
  SET_STATUS_DETAIL,
  VALIDATE,
  SET_STATUS_VALIDATE,

  SET_STATUS,
  SET_ERRORS,

  CREATE,
  UPDATE,
  SET_STATUS_UPDATE,
  GET_MASTER_DATA,
  SET_STATUS_ACCOUNT,
  ISSUE_ACCOUNT,

  GENERATE_AUTH_URL,
  GET_LIST_ADVERTISER,
  SET_LIST_ADVERTISER,
  GET_MARGINS,
  SET_MARGINS,

  GET_AD_ACCOUNT,
  SET_DATA_AD_ACCOUNT,
  SET_STATUS_AD_ACCOUNT,
  CHECK_ADVERTISER_ID,
  SEND_LINK_AUTH_LINE,
  VERIFY_CLIENT_APPROVAL,
  VERIFY_DOWLOADED_IMAGE,
  SET_STATUS_AUTH_LINE,
  CHANGE_STATUS,
  SET_STATUS_STOP,
  VERIFY_PASSWORD_LINE,
  VERIFY_ADVERTISER_ID_REGISTERED,
};
