import { put, call, select, takeLatest } from 'redux-saga/effects';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';
import { CV_ATTRIBUTE } from 'services/routes/constants';
import types from 'store/cv-attribute/types';
import actions from 'store/cv-attribute/actions';
import CVAttributeApi from 'services/api/CVAttributeApi';
import cvAttributeSelectors from 'store/cv-attribute/selectors';
import settingsSelectors from 'store/settings/selectors';
import filterSelectors from 'store/filters/selectors';
import commonSelectors from 'store/common/selectors';
import handleError from 'services/error/handleScopeError';
import sharedSagas from 'store/sharedSagas';
import { DisplayItemsSelectors } from 'store/display-items';

function* fetchReport() {
  try {
    const [page, period, pagination, sort] = [
      yield select(settingsSelectors.getPage),
      yield select(commonSelectors.periodSelector),
      yield select(cvAttributeSelectors.getPagination),
      yield select(cvAttributeSelectors.sortSelector),
    ];

    if (page !== CV_ATTRIBUTE) return;

    yield put(actions.requestStart());

    const { data } = yield call(CVAttributeApi.fetchReport, {
      channel: yield select(settingsSelectors.getTab),
      start_date: period.start.format('YYYY-MM-DD'),
      end_date: period.end.format('YYYY-MM-DD'),
      metrics: yield select(cvAttributeSelectors.getMetricsRequest),
      filters: yield select(filterSelectors.getForApi),
      sum: false,
      limit: TABLE_ROWS_PER_PAGE,
      offset: (pagination.currentPage - 1) * TABLE_ROWS_PER_PAGE,
      axis: yield select(DisplayItemsSelectors.getDisplayItemPriorityAxis),
      ...sort,
    });

    yield put(actions.fetchReportSucceeded(data));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

export default function* cvAttributeOperations() {
  yield takeLatest(types.FETCH_REPORT, sharedSagas.skipIfNotReady(fetchReport));
}
