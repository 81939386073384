const SET_COMMON_DATA = 'common/SET_COMMON_DATA';
const GET_COMMON_DATA = 'common/GET_COMMON_DATA';

const DOWNLOAD_IMAGE = 'common/DOWNLOAD_IMAGE';
const TOGGLE_DOWNLOAD = 'common/TOGGLE_DOWNLOAD';

const CHANGE_CURRENT_URL_LOCATION = 'common/CHANGE_CURRENT_URL_LOCATION';
const OPEN_TRANSITION_MODAL = 'common/OPEN_TRANSITION_MODAL';
const CLOSE_TRANSITION_MODAL = 'common/CLOSE_TRANSITION_MODAL';
const CLOSE_TRANSITION_MODAL_HOME = 'common/CLOSE_TRANSITION_MODAL_HOME';
const CLOSE_TRANSITION_MODAL_LISTING = 'common/CLOSE_TRANSITION_MODAL_LISTING';

const DOWNLOAD_CSV = 'common/DOWNLOAD_CSV';
const DOWNLOAD_CSV_ERROR = 'common/DOWNLOAD_CSV_ERROR';
const UPDATE_PERIOD_WITH_COMPARED = 'common/UPDATE_PERIOD_WITH_COMPARED';

const CLEAR_CACHE = 'common/CLEAR_CACHE';

const SET_DOWNLOAD_NOTIFY = 'common/SET_DOWNLOAD_NOTIFY';
const SET_DOWNLOAD_STATUS = 'common/SET_DOWNLOAD_STATUS';

const CHANGE_TUTORIAL_STATUS = 'common/CHANGE_TUTORIAL_STATUS';
const TOGGLE_MODAL_STATUS = 'common/TOGGLE_MODAL_STATUS';
const TUTORIAL_UPDATE_FAILED = 'common/TUTORIAL_UPDATE_FAILED';

const CHECK_URL = 'common/CHECK_URL';

export default {
  SET_COMMON_DATA,
  GET_COMMON_DATA,
  DOWNLOAD_IMAGE,
  DOWNLOAD_CSV,
  DOWNLOAD_CSV_ERROR,
  TOGGLE_DOWNLOAD,
  UPDATE_PERIOD_WITH_COMPARED,
  CHANGE_CURRENT_URL_LOCATION,
  OPEN_TRANSITION_MODAL,
  CLOSE_TRANSITION_MODAL,
  CLOSE_TRANSITION_MODAL_HOME,
  CLOSE_TRANSITION_MODAL_LISTING,
  CLEAR_CACHE,
  SET_DOWNLOAD_NOTIFY,
  SET_DOWNLOAD_STATUS,
  CHANGE_TUTORIAL_STATUS,
  TOGGLE_MODAL_STATUS,
  TUTORIAL_UPDATE_FAILED,
  CHECK_URL,
};
