import types from 'store/page-management/types';
import helper from 'store/helper';
import { communicationStatus } from 'services/utils';

const { IDLE, SUCCEEDED, FAILED } = communicationStatus;
const FIRST_PAGE = 1;

const initialState = {
  data: {
    list: {
      items: [],
      errors: [],
    },
    objPageById: {},
    detail: {
      item: {},
      errors: [],
    },
    upload: {
      fileName: null,
      list: null,
      errors: [],
    },
  },
  settings: {
    pagination: {
      totalItems: 0,
      currentPage: FIRST_PAGE,
    },
    sort: {
      field: '',
      direction: '',
    },
    isShowRemindSC: true,
    isShowRemindNotSC: true,
    countDeletedPages: false,
  },
  status: {
    list: IDLE,
    detail: IDLE,
    metadata: IDLE,
    upload: IDLE,
  },
};

const setStatusList = (state, action) => {
  return {
    ...state,
    status: {
      ...state.status,
      list: action.payload.status,
    },
  };
};

const setDataList = (state, action) => {
  const { lists, metadata, objPageById } = action.payload.data;
  const { currentPage } = state.settings.pagination;
  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        items: lists,
        errors: [],
      },
      objPageById,
    },
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        totalItems: metadata.total,
        currentPage,
      },
      isShowRemindSC: metadata.is_remind_sc_page_management,
      isShowRemindNotSC: metadata.is_remind_not_sc_page_management,
      countDeletedPages: metadata.count_deleted_pages,
    },
    status: {
      ...state.status,
      list: SUCCEEDED,
    },
  };
};

const setErrorList = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        errors: action.payload.errors,
      },
    },
    status: {
      ...state.status,
      list: FAILED,
    },
  };
};

const setDataDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: { ...state.data.detail, item: action.payload.data, errors: [] },
  },
  status: { ...state.status, detail: SUCCEEDED },
});

const setErrorDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: { ...state.data.detail, errors: action.payload.errors },
  },
  status: { ...state.status, detail: FAILED },
});

const setStatusDetail = (state, action) => ({
  ...state,
  status: { ...state.status, detail: action.payload.status },
});

const updateSort = (state, action) => {
  const { field, direction } = action.payload;

  return {
    ...state,
    settings: {
      ...state.settings,
      sort: {
        field,
        direction,
      },
      pagination: {
        ...state.settings.pagination,
        currentPage: FIRST_PAGE,
      },
    },
  };
};

const updatePage = (state, action) => {
  const { page } = action.payload;

  return {
    ...state,
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        currentPage: page,
      },
    },
  };
};

const setCsvFileName = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    upload: {
      ...state.data.upload,
      fileName: action.payload.fileName,
    },
  },
});

const PageManagementReducer = helper.createReducer(initialState, {
  [types.SET_DATA_LIST]: setDataList,
  [types.SET_STATUS_LIST]: setStatusList,
  [types.SET_ERROR_LIST]: setErrorList,
  [types.SET_DATA_DETAIL]: setDataDetail,
  [types.SET_ERROR_DETAIL]: setErrorDetail,
  [types.SET_STATUS_DETAIL]: setStatusDetail,
  [types.UPDATE_SORT]: updateSort,
  [types.UPDATE_PAGE]: updatePage,
  [types.SET_CSV_FILE_NAME]: setCsvFileName,
});

export default PageManagementReducer;
