const FETCH_REPORT_USER_BEHAVIORS = 'user_profile/FETCH_USER_BEHAVIORS';
const REQUEST_USER_BEHAVIORS = 'user_profile/REQUEST_USER_BEHAVIORS';
const FETCH_REPORT_USER_BEHAVIORS_ERROR =
  'user_profile/FETCH_USER_BEHAVIORS_ERROR';
const FETCH_REPORT_USER_BEHAVIORS_SUCCESS =
  'user_profile/FETCH_USER_BEHAVIORS_SUCCESS';
const REQUEST_USER_PROFILE = 'user_profile/REQUEST_USER_PROFILE';
const FETCH_REPORT_USER_PROFILE = 'user_profile/FETCH_USER_PROFILE';
const FETCH_REPORT_USER_PROFILE_ERROR = 'user_profile/FETCH_USER_PROFILE_ERROR';
const FETCH_REPORT_USER_PROFILE_SUCCESS =
  'user_profile/FETCH_USER_PROFILE_SUCCESS';
const SORT_USER_BEHAVIORS = 'user_profile/SORT_USER_BEHAVIORS';
const UPDATE_SORT_ORDER = 'user_profile/UPDATE_SORT_ORDER';
const UPDATE_PAGE = 'user_profile/UPDATE_PAGE';
const UPDATE_BASE_DATE = 'user_profile/UPDATE_BASE_DATE';
const UPDATE_AD_EXPIRE_TIME = 'user_profile/UPDATE_AD_EXPIRE_TIME';
const DOWNLOAD_CSV = 'user_profile/DOWNLOAD_CSV';
const UPDATE_PARAMETER = 'user_profile/UPDATE_PARAMETER';
const OPEN_HIERARCHY = 'user_profile/OPEN_HIERARCHY';
const OPEN_HIERARCHY_ALL = 'user_profile/OPEN_HIERARCHY_ALL';

export default {
  REQUEST_USER_BEHAVIORS,
  FETCH_REPORT_USER_BEHAVIORS,
  FETCH_REPORT_USER_BEHAVIORS_ERROR,
  FETCH_REPORT_USER_BEHAVIORS_SUCCESS,
  REQUEST_USER_PROFILE,
  FETCH_REPORT_USER_PROFILE,
  FETCH_REPORT_USER_PROFILE_ERROR,
  FETCH_REPORT_USER_PROFILE_SUCCESS,
  SORT_USER_BEHAVIORS,
  UPDATE_SORT_ORDER,
  UPDATE_PAGE,
  UPDATE_BASE_DATE,
  UPDATE_AD_EXPIRE_TIME,
  DOWNLOAD_CSV,
  UPDATE_PARAMETER,
  OPEN_HIERARCHY,
  OPEN_HIERARCHY_ALL,
};
