import {
  PRODUCT_NAME,
  PRODUCT_ID,
  OFFER_NAME,
  OFFER_ID,
  LINKED_ADS,
  AD_NAME,
  AD_ID,
  ID,
  MAX_AD_ID_DISPLAY,
} from 'domain/ltv/settings/constants';

const getHeader = (selectedRows, isCheckedAll, sort) => {
  const headerData = [
    {
      text: '',
      name: 'check_all',
      isDimension: true,
      isEdit: true,
      sort: false,
      checked: selectedRows.length > 0,
      variant: selectedRows.length > 0 && !isCheckedAll ? 'mixed' : '',
    },
    {
      isDimension: true,
      name: PRODUCT_NAME,
      sort: 'none',
      text: '商品',
    },
    {
      isDimension: true,
      name: OFFER_NAME,
      sort: 'none',
      text: 'オファー',
    },
    {
      isDimension: true,
      name: LINKED_ADS,
      sort: 'none',
      text: '紐づけ広告ID',
      cellDisplay: 'collapse',
      maxHeight: 48, // 3 line text
    },
  ];

  if (sort) {
    return headerData.map((item) => {
      const newObj = { ...item };
      if (newObj.name === sort.field) {
        newObj.sort = sort.direction;
      }
      return newObj;
    });
  }

  return headerData;
};

const getAdsString = (ads) => {
  let value = '未登録';
  if (ads && ads.length > 0) {
    const adsDisplay = ads.slice(0, MAX_AD_ID_DISPLAY);
    value = adsDisplay.reduce((prevVal, currVal, idx) => {
      return idx === 0
        ? `${currVal[AD_ID]}[${currVal[AD_NAME]}]`
        : `${prevVal}, ${currVal[AD_ID]}[${currVal[AD_NAME]}]`;
    }, '');

    if (ads.length > MAX_AD_ID_DISPLAY) {
      value = `${value}, ...`;
    }
  }
  return value;
};

const formatData = (data) => {
  const series = data.map((item) => {
    return {
      [ID]: `${item[PRODUCT_ID]}-${item[OFFER_ID]}`,
      [PRODUCT_NAME]: item[PRODUCT_NAME],
      [OFFER_NAME]: item[OFFER_NAME],
      [LINKED_ADS]: getAdsString(item[LINKED_ADS]),
    };
  });

  return series;
};

const formatAds = (ads) => {
  const adObject = {};
  if (ads.length) {
    ads.map((ad, index) => {
      adObject[ad[AD_ID]] = {
        value: ad[AD_ID],
        label: ad[AD_NAME],
        order: index,
      };
      return index;
    });
  }
  return adObject;
};

const updateDataListSelected = (list, selectedRows) => {
  return list.map((item) => {
    return {
      ...item,
      rowId: item[ID],
      selected: selectedRows.includes(item[ID]),
    };
  });
};

const updateHeaderCheckAll = (headers, isChecked) => {
  const newHeaders = [...headers];
  newHeaders[0].checked = isChecked;
  return newHeaders;
};

const convertToArray = (adObject) => {
  return Object.keys(adObject).map((key) => key);
};

export default {
  formatData,
  formatAds,
  convertToArray,
  updateDataListSelected,
  updateHeaderCheckAll,
  getHeader,
};
