// async actions
const REQUEST_START = 'user/REQUEST_START';
const REQUEST_FAILED = 'user/REQUEST_FAILED';
const FETCH_LIST = 'user/FETCH_LIST';
const CREATE = 'user/CREATE';
const UPDATE = 'user/UPDATE';
const DELETE = 'user/DELETE';
const TOGGLE_SELECT = 'user/TOGGLE_SELECT';
const SHOW_HIDE_MODAL = 'user/SHOW_HIDE_MODAL';

// sync actions
const FETCH_LIST_SUCCEEDED = 'user/FETCH_LIST_SUCCEEDED';
const CHANGE_SELECTED_ITEM = 'user/CHANGE_SELECTED_ITEM';
const UPDATE_CURRENT_ACCOUNT = 'user/UPDATE_CURRENT_ACCOUNT';
const FETCH_LIST_BY_CODE = 'user/FETCH_LIST_BY_CODE';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  CREATE,
  UPDATE,
  DELETE,
  TOGGLE_SELECT,
  FETCH_LIST,
  FETCH_LIST_SUCCEEDED,
  SHOW_HIDE_MODAL,
  CHANGE_SELECTED_ITEM,
  UPDATE_CURRENT_ACCOUNT,
  FETCH_LIST_BY_CODE,
};
