export default {
  // types for list function
  GET_DATA: 'capi-setting/GET_DATA',
  SET_DATA: 'capi-setting/SET_DATA',
  SET_STATUS: 'capi-setting/SET_STATUS',
  SET_ERRORS: 'capi-setting/SET_ERRORS',
  UPDATE_SORT: 'capi-setting/UPDATE_SORT',
  CHANGE_STATUS: 'capi-setting/CHANGE_STATUS',
  DELETE: 'capi-setting/DELETE',
  // types for history function
  GET_HISTORY: 'capi-setting/GET_HISTORY',
  SET_HISTORY: 'capi-setting/SET_HISTORY',
  // types for setting function
  SET_STATUS_SETTING: 'capi-setting/SET_STATUS_SETTING',
  AUTHENTICATION_CHECK: 'capi-setting/AUTHENTICATION_CHECK',
  GOOGLE_AUTHENTICATION_CHECK: 'capi-setting/GOOGLE_AUTHENTICATION_CHECK',
  LINE_AUTHENTICATION_CHECK: 'capi-setting/LINE_AUTHENTICATION_CHECK',
  YAHOO_AUTHENTICATION_CHECK: 'capi-setting/YAHOO_AUTHENTICATION_CHECK',
  TIKTOK_AUTHENTICATION_CHECK: 'capi-setting/TIKTOK_AUTHENTICATION_CHECK',
  SET_ERROR_SETTING: 'capi-setting/SET_ERROR_SETTING',
  SET_DATA_SETTING: 'capi-setting/SET_DATA_SETTING',
  CREATE: 'capi-setting/CREATE',
  GET_DETAIL: 'capi-setting/GET_DETAIL',
  UPDATE: 'capi-setting/UPDATE',
  GET_MASTERDATA: 'capi-setting/GET_MASTERDATA',
  SET_MASTERDATA: 'capi-setting/SET_MASTERDATA',
  DOWNLOAD_CSV_POSTBACK_HISTORY: 'capi-setting/DOWNLOAD_CSV_POSTBACK_HISTORY',
  GET_YAHOO_ACCOUNTS: 'capi-setting/GET_YAHOO_ACCOUNTS',
  GET_GOOGLE_ACCOUNTS: 'capi-setting/GET_GOOGLE_ACCOUNTS',
};
