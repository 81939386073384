import isEmpty from 'lodash/isEmpty';
import { UPDATE_TIME_FORMAT } from 'domain/consts';
import moment from 'moment';

class FormatData {
  static getPeriodText(period) {
    const date = moment(period, UPDATE_TIME_FORMAT);
    if (isEmpty(date)) {
      return '';
    }

    // format YYYY年MM月分
    return `${date.format('YYYY')}年${date.format('MM')}月分`;
  }
}

export default FormatData;
