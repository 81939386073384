import React from 'react';
import PropTypes from 'prop-types';

const Sort = React.memo(({ direction }) => {
  if (direction === 'desc') {
    return <i className="fas fa-arrow-down icon-sort" />;
  }
  if (direction === 'asc') {
    return <i className="fas fa-arrow-up icon-sort" />;
  }
  return <i className="fas fa-arrow-down icon-sort d-none" />;
});

Sort.propTypes = {
  direction: PropTypes.string,
};

Sort.defaultProps = {
  direction: 'none',
};

export default Sort;
