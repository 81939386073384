const UPDATE_COLUMNS_WIDTH = 'resize-table/UPDATE_COLUMNS_WIDTH';
const UPDATE_COLUMN_WIDTH = 'resize-table/UPDATE_COLUMN_WIDTH';
const UPDATE_TABLE_WIDTH = 'resize-table/UPDATE_TABLE_WIDTH';
const UPDATE_TABLE_WIDTH_MULITIPLE =
  'resize-table/UPDATE_COLUMN_WIDTH_MULITIPLE';

export default {
  UPDATE_COLUMNS_WIDTH,
  UPDATE_COLUMN_WIDTH,
  UPDATE_TABLE_WIDTH,
  UPDATE_TABLE_WIDTH_MULITIPLE,
};
