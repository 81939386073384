import types from 'store/cost-allocation/types';
import * as FIELD from 'domain/fields';
import { communicationStatus } from 'services/utils';

const { IDLE, LOADING, SUCCEEDED, FAILED } = communicationStatus;
const initialState = {
  apiStatus: {
    chart: {
      status: IDLE,
      error: false,
    },
    table: {
      status: IDLE,
      error: false,
    },
  },
  settings: {
    chart: {
      yAxisLine: FIELD.REALLOCATION_CV_RATIO,
      savedYAxis: null,
      instance: null,
    },
    table: {
      pagination: {
        currentPage: 1,
      },
      sorts: [
        {
          field: 'rcv_cost_effectiveness',
          direction: 'desc',
        },
      ],
    },
    simulation: {
      type: 1,
      productivity: null,
      productivity_up: null,
      cost: null,
    },
  },

  data: {
    dataFull: [],
    dataTable: [],
    tableDisplayData: [],
    selectedRows: [],
    chart: [],
    conversions: [],
  },
};

const CostAllocationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_FULL_DATA:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            status: LOADING,
            error: false,
          },
        },
      };
    case types.GET_DATA:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          table: {
            status: LOADING,
            error: false,
          },
        },
      };
    case types.CHANGE_SELECTED_YAXISLINE:
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            yAxisLine: payload,
          },
        },
      };
    case types.CLEAR_SIMULATION_DATA:
      return {
        ...state,
        settings: {
          ...state.settings,
          simulation: {
            ...state.settings.simulation,
            productivity: null,
            productivity_up: null,
            cost: null,
          },
        },
      };
    case types.SAVE_SIMULATION_DATA:
      return {
        ...state,
        settings: {
          ...state.settings,
          simulation: payload,
        },
      };
    case types.SAVE_RESULT_SIMULATION_DATA:
      return {
        ...state,
        settings: {
          ...state.settings,
          simulation: {
            ...state.settings.simulation,
            result: payload,
          },
        },
      };
    case types.GET_SUCCESS:
      return {
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            pagination: {
              ...state.settings.table.pagination,
              totalItems: payload.data.metadata.count,
              itemsPerPage: payload.data.metadata.limit,
            },
            sort: payload.data.metadata.sort,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          table: {
            status: SUCCEEDED,
            error: false,
          },
        },
        data: {
          ...state.data,
          dataTable: payload.data.data,
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          table: {
            status: FAILED,
            error: true,
          },
        },
      };
    case types.GET_FULL_DATA_SUCCESS:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            status: SUCCEEDED,
            error: false,
          },
        },

        data: {
          ...state.data,
          dataFull: payload.data.data,
        },
      };
    case types.GET_FULL_DATA_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            status: FAILED,
            error: true,
          },
        },
      };

    case types.CHANGE_PAGE:
      // get data
      return {
        ...state,
        apiStatus: {
          status: LOADING,
        },
        settings: {
          table: {
            ...state.settings.table,
            pagination: {
              currentPage: payload,
            },
          },
        },
      };
    case types.SELECT_ROW: {
      const row = payload.dimensionKey;
      const { selectedRows } = state.data;
      if (selectedRows.indexOf(row) === -1) {
        return {
          ...state,
          data: {
            ...state.data,
            selectedRows: [...selectedRows, row],
          },
        };
      }
      return state;
    }

    case types.DESELECT_ROW: {
      const row = payload.dimensionKey;
      const { selectedRows } = state.data;
      return {
        ...state,
        data: {
          ...state.data,
          selectedRows: selectedRows.filter((item) => item !== row),
        },
      };
    }

    case types.GET_BOOKMARK_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            savedYAxis: payload.status === true ? payload.metric : null,
            yAxisLine:
              payload.status === true
                ? payload.metric
                : state.settings.chart.yAxisLine,
          },
        },
      };

    case types.GET_BOOKMARK_ERROR:
      return {
        ...state,
        apiStatus: {
          status: FAILED,
          error: true,
        },
      };

    case types.UPDATE_BOOKMARK:
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            savedYAxis: payload.status ? payload.metric : null,
          },
        },
      };

    case types.UPDATE_BOOKMARK_ERROR:
      return {
        ...state,
        apiStatus: {
          status: FAILED,
          error: true,
        },
      };

    case types.SET_TABLE_COLUMN_SORT: {
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            sorts: payload,
            pagination: {
              ...state.settings.table.pagination,
              currentPage: 1,
            },
          },
        },
      };
    }

    case types.SET_TABLE_CURRENT_PAGE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            pagination: {
              ...state.settings.table.pagination,
              currentPage: payload.page,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default CostAllocationReducer;
