import moment from 'moment';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import DashboardService from 'services/dashboard/DashboardService';
import ReflectionTimeService from 'services/common/reflectiontime/ReflectionTimeService';
import { isCurrentMonth, isLastMonth, isValidDate } from 'services/utils';
import {
  COMPARE_MODE_TARGET_VALUE,
  COMPARE_MODE_LAST_MONTH_VALUE,
  COMPARE_MODE_SAME_MONTH_LAST_YEAR_VALUE,
  NOT_SETTING_KPI_PERIOD,
} from 'store/dashboard/constants';

class FormatData {
  static buildMetadata(channel, metadata) {
    const {
      metrics,
      conversions,
      kpi_periods: periods,
      main_service: service,
    } = metadata;

    const updatedTime = ReflectionTimeService.getPreviousDate(
      service?.updated_time
    );

    return {
      service,
      conversions: [{ conv_id: 0, conv_name: 'すべて' }, ...conversions],
      metrics: FormatData.formatMetricDisplay(metrics, channel),
      periods: FormatData.formatPeriodDisplay(periods, updatedTime),
      currentKpiPeriod: FormatData.getCurrentKpiPeriod(periods),
    };
  }

  static buildDataKpiStatus(kpiStatus) {
    if (isEmpty(kpiStatus) || !isObject(kpiStatus)) return {};

    const results = {};
    const metrics = ['cnt_conv', 'sale_amount', 'roas', 'cnt_access', 'cpa'];

    Object.entries(kpiStatus).forEach(([metric, status]) => {
      results[metric] = { ...status };
      if (metrics.includes(metric)) {
        const keyStatus = metric === 'cpa' ? 'bad' : 'good';
        results[metric] = {
          ...status,
          [keyStatus]: status.normal + 1,
        };
      }
    });

    return results;
  }

  static buildDataChart(chart, period, metadata) {
    if (isEmpty(chart)) return {};

    const metricId = this.getMetricId(chart.pickup_id);
    const dates = DashboardService.enumerateDaysBetweenDates(
      period.start_date,
      period.end_date
    );

    const pickupName = DashboardService.getMetricDisplayName(
      chart.pickup_id,
      metadata
    );

    return {
      ...chart,
      pickup_name: pickupName,
      data: dates.map((date) => {
        const data = chart.data.find((item) => item.date === date) || {
          date,
          total: 0,
        };

        return {
          ...data,
          name: pickupName,
          total: FormatData.getUnitDisplay(metricId, data.total),
          y: FormatData.formatNumberDisplay(metricId, data.total, true),
        };
      }),
    };
  }

  static getUnitDisplay(metricId, number) {
    const isCurrency = FormatData.isCurrency(metricId);
    const value = FormatData.formatNumberDisplay(metricId, number);
    const unit = FormatData.getUnitText(metricId);

    return isCurrency ? unit + value : value + unit;
  }

  static formatMetricDisplay(metrics, channel) {
    const textDisplay = {
      cnt_access: {
        ad: 'クリック数',
        all: '流入数',
      },
    };

    return metrics.map((metric) => {
      if (isEmpty(textDisplay[metric.metric_id])) {
        return { ...metric };
      }

      return {
        ...metric,
        metric_name: textDisplay[metric.metric_id][channel],
      };
    });
  }

  /**
   * Get Kpi Period of Current Month
   * If current date is day 1, It will get latest Kpi Period setting of the most recent month
   */
  static getCurrentKpiPeriod(periods) {
    return periods.length === NOT_SETTING_KPI_PERIOD ? [] : periods[0];
  }

  static formatPeriodDisplay(periods, updatedTime) {
    const currentDate = moment();
    const previousDate = currentDate
      .clone()
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
    const lastTime = isValidDate(updatedTime) ? updatedTime : previousDate;
    const updatedTimeDate = moment(lastTime);

    // Remove period greater than updated time
    let periodDisplay = periods
      .filter((period) => {
        return moment(period.start_date).isSameOrBefore(lastTime);
      })
      .map((period) => {
        let endDate = period.end_date;

        // Update end date based on the updated time
        if (
          updatedTimeDate.isBetween(
            moment(period.start_date),
            moment(period.end_date),
            undefined,
            '[]'
          )
        ) {
          endDate = updatedTimeDate.format('YYYY-MM-DD');
        } else if (isCurrentMonth(period.start_date)) {
          endDate = previousDate;
        }

        return {
          ...period,
          end_date: endDate,
        };
      });

    // If period is empty, get month closest to updated time
    if (periodDisplay.length === 0) {
      const lastPeriod = periods[periods.length - 1];
      let endDate = lastPeriod.end_date;

      // Update end date based on the updated time
      if (
        updatedTimeDate.isBetween(
          moment(lastPeriod.start_date),
          moment(lastPeriod.end_date),
          undefined,
          '[]'
        )
      ) {
        endDate = updatedTimeDate.format('YYYY-MM-DD');
      } else if (isCurrentMonth(lastPeriod.start_date)) {
        endDate = previousDate;
      }

      periodDisplay = [{ ...lastPeriod, end_date: endDate }];
    }

    return periodDisplay;
  }

  static formatNumberDisplay(metricId, value, returnNumber = false) {
    let result;
    if (typeof value !== 'number') {
      return value;
    }

    switch (metricId) {
      case 'roas':
        result = value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        break;
      case 'cpa':
      case 'ad_cost':
      case 'sale_amount':
      case 'cnt_conv':
      case 'cnt_access':
        result = value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        break;
      default:
        break;
    }

    return returnNumber ? Number(result.split(',').join('')) : result;
  }

  static getUnitText(metricId, isText) {
    switch (metricId) {
      case 'ad_cost':
      case 'cpa':
      case 'sale_amount':
        return isText ? '円' : '¥';

      case 'roas':
        return '%';

      case 'cnt_conv':
      case 'cnt_access':
        return '件';
      default:
        break;
    }

    return '';
  }

  static getActualPercent(actual, target) {
    return Math.round((actual / target) * 100);
  }

  static getMetricId(pickupId) {
    return pickupId.split('__')[0];
  }

  static getConvId(pickupId) {
    return pickupId.split('__')[1];
  }

  static getExpected(actual, isThisMonth) {
    const passedDays = moment().date() - 1;

    if (!isThisMonth || passedDays === 0) {
      return actual;
    }

    const numOfday = moment().daysInMonth();
    return Math.round((actual / passedDays) * numOfday);
  }

  static getExpectedRate(actual, compareTarget, isThisMonth) {
    if (includes(['0', 0, null], compareTarget)) return null;

    const expect = FormatData.getExpected(actual, isThisMonth);
    return Math.round((expect / compareTarget) * 100);
  }

  static getCompareDateByMode(id, periods, compareMode) {
    const period = periods.find((item) => item.kpi_period_id === id);

    if (!period) return {};

    switch (compareMode) {
      case COMPARE_MODE_LAST_MONTH_VALUE: {
        const startDate = moment(period.start_date)
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

        return { startDate, endDate };
      }
      case COMPARE_MODE_SAME_MONTH_LAST_YEAR_VALUE: {
        const startDate = moment(period.start_date)
          .subtract(1, 'years')
          .startOf('month')
          .format('YYYY-MM-DD');
        const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

        return { startDate, endDate };
      }
      default:
        return {};
    }
  }

  static getTarget(targetValue, comparePeriod, compare, pickupId) {
    if (comparePeriod === COMPARE_MODE_TARGET_VALUE) {
      return targetValue || null;
    }
    // Get from compare
    if (compare) {
      const pickup = compare.find((item) => {
        return item.pickup_id === pickupId;
      });
      if (pickup) {
        return pickup.actual;
      }
    }
    return null;
  }

  static getPeriodText(periodId, periods) {
    const period = periods.find((item) => {
      return item.kpi_period_id === periodId;
    });

    if (isEmpty(period)) {
      return '';
    }

    const endDate = moment(period.end_date);
    const startDate = endDate.clone().startOf('month');

    // 期間：今月（YYYY/MM/DD ～ YYYY/MM/DD）
    if (isCurrentMonth(startDate.format('YYYY/MM/DD'))) {
      return `期間：今月（${startDate.format('YYYY/MM/DD')} ～ ${endDate.format(
        'YYYY/MM/DD'
      )}）`;
    }

    // 期間：前月（YYYY/MM/DD ～ YYYY/MM/DD）
    if (isLastMonth(startDate.format('YYYY/MM/DD'))) {
      return `期間：前月（${startDate.format('YYYY/MM/DD')} ～ ${endDate.format(
        'YYYY/MM/DD'
      )}）`;
    }

    // format YYYY年MM月分
    return `${startDate.format('YYYY')}年${startDate.format('MM')}月分`;
  }

  static getCompareText(key, flag = true) {
    const a = { 1: '対目標', 2: '対前月', 3: '対前年同月' };
    const b = { 1: '目標', 2: '前月', 3: '前年同月' };
    if (flag) {
      return a[key];
    }
    return b[key];
  }

  static isCurrency(metricId) {
    if (['ad_cost', 'cpa', 'sale_amount'].indexOf(metricId) !== -1) {
      return true;
    }
    return false;
  }
}

export default FormatData;
