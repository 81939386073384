import {
  AD_MANAGEMENT,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  MEDIA_SYNC_MANAGEMENT,
  SYSTEM_SETTING_COOKIE_PROGRAM,
} from 'services/routes/constants';

export const getTooltipDisabledEdit = (screenId, screenName) => {
  if (!screenId) return '';

  switch (screenId) {
    case AD_MANAGEMENT:
      return `この${screenName}には編集の権限がありません`;
    case AD_MANAGEMENT_AD_GROUP_1:
      return `この${screenName}には削除・編集の権限がありません`;
    case AD_MANAGEMENT_AD_GROUP_2:
      return `この${screenName}には削除・編集の権限がありません`;
    case SYSTEM_SETTING_COOKIE_PROGRAM:
      return `該当の計測対象サイトが削除されているため、本設定は無効です。`;
    case MEDIA_SYNC_MANAGEMENT:
      return `編集の権限がありません`;
    default:
      return '';
  }
};

export const getTooltipDisabledDelete = (screenId, screenName) => {
  if (!screenId) return '';

  switch (screenId) {
    case AD_MANAGEMENT:
      return `この${screenName}には削除の権限がありません`;
    case AD_MANAGEMENT_AD_GROUP_1:
      return `この${screenName}には削除・編集の権限がありません`;
    case AD_MANAGEMENT_AD_GROUP_2:
      return `この${screenName}には削除・編集の権限がありません`;
    case MEDIA_SYNC_MANAGEMENT:
      return `編集の権限がありません`;
    default:
      return '';
  }
};
