import {
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_OTHER,
  LTV_SETTINGS_CLEAR_CSV_ORDERS,
} from 'services/routes/constants';
import {
  MANAGE_LTV_EDIT,
  SETTING_LTV_AD_LINKING_EDIT,
  SETTING_LTV_BASE_EDIT,
} from 'domain/permissions/contractPermissions';
import pages from 'services/routes/pages';

export const MAXIMUM_CONDITION_ITEMS = 50000;
export const PRODUCT_ID = 'product_id';
export const PRODUCT_NAME = 'product_name';
export const OFFER_ID = 'offer_id';
export const OFFER_NAME = 'offer_name';
export const DELETE_FLAG = 'delete_flag';
export const EDIT = 'edit';
export const ADD_NEW = 'add';
export const COPY = 'copy';
export const ID = 'product_id-offer_id';
export const FIELD_IS_USING = 'product_offer_ad_link';
export const CHANNEL_TYPE = 'channel';
export const CHANNEL_TYPE_AD = 'ad';
export const CHANNEL_TYPE_ALL = 'all';
export const CHANNEL_TYPE_ALL_ID = '0-0';

export const TYPE_PRODUCT = 'product';
export const TYPE_OFFER = 'offer';
export const TYPE_CONDITION = 'condition';
export const TYPE_ORDER = 'order';
export const TYPE_AD = 'ad';

export const MARGIN_F1 = 'marginal_profit_margin_f1';
export const MARGIN_F2 = 'marginal_profit_margin_f2';
export const MARGIN_F3 = 'marginal_profit_margin_f3';
export const PAY_BACK = 'investment_payback_period';
export const EFFECTIVE_MONTH = 'application_start_year_month';
export const ORDER = 'order';

export const MARGIN_MIN = -999;
export const MARGIN_MAX = 100;
export const PAYBACK_MIN = 1;
export const PAYBACK_MAX = 24;
export const CSV_MAX_SIZE = 30 * 1024 * 1024;

export const MAX_AD_ID_DISPLAY = 50;

export const LINKED_ADS = 'ads';
export const AD_NAME = 'ad_name';
export const AD_ID = 'ad_id';

// Order
export const ORDER_ID = 'order_id';
export const ORDERED_TIME = 'ordered_time';
export const MEMBER_ID = 'member_id';
export const REVENUE = 'revenue';

export const FILE_IMPORTING = 'FILE_IMPORTING';

export const TABS = [
  {
    label: '商品',
    path: pages[LTV_SETTINGS_PRODUCT].path,
    permission: SETTING_LTV_BASE_EDIT,
  },
  {
    label: 'オファー',
    path: pages[LTV_SETTINGS_OFFER].path,
    permission: SETTING_LTV_BASE_EDIT,
  },
  {
    label: '集計条件',
    path: pages[LTV_SETTINGS_CONDITION].path,
    permission: SETTING_LTV_BASE_EDIT,
  },
  {
    label: '広告の紐づけ',
    path: pages[LTV_SETTINGS_AD].path,
    permission: SETTING_LTV_AD_LINKING_EDIT,
  },
  {
    label: 'その他',
    path: pages[LTV_SETTINGS_OTHER].path,
    permission: SETTING_LTV_BASE_EDIT,
  },
  {
    label: '受注データの初期化',
    path: pages[LTV_SETTINGS_CLEAR_CSV_ORDERS].path,
    permission: MANAGE_LTV_EDIT,
  },
];
