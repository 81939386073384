import types from 'store/ltv/settings/condition/types';

const start = (type) => ({
  type,
});

const succeeded = (type, data) => ({
  type,
  payload: data,
});

const failed = (type, { error }) => ({
  type,
  payload: error,
  scope: error.scope,
});

const handleShowHideModal = (data) => ({
  type: types.SHOW_HIDE_MODAL,
  payload: data,
});

const updateCurrentItemNumber = (num) => ({
  type: types.UPDATE_CURRENT_ITEM_NUMBER,
  payload: num,
});

const updateSort = (sorts) => ({
  type: types.UPDATE_SORT,
  payload: sorts,
});

const updatePage = (page) => ({
  type: types.UPDATE_PAGE,
  payload: { page },
});

const upload = (payload) => ({
  type: types.UPLOAD,
  payload,
});

const submitFailed = ({ error, scope }) => ({
  type: types.SUBMIT_FAILED,
  payload: error,
  scope,
});

const fetchListStart = () => start(types.FETCH_LIST_START);
const requestStart = () => start(types.REQUEST_START);
const requestFailed = (error) => failed(types.REQUEST_FAILED, error);
const fetched = (data) => succeeded(types.FETCH_SUCCEEDED, data);
const fetchedList = (data) => succeeded(types.FETCH_LIST_SUCCEEDED, data);
const created = (data) => succeeded(types.CREATE_SUCCEEDED, data);
const updated = (data) => succeeded(types.UPDATE_SUCCEEDED, data);
const removed = (data) => succeeded(types.DELETE_SUCCEEDED, data);
const fetch = (data) => succeeded(types.FETCH, data);
const fetchList = (data) => succeeded(types.FETCH_LIST, data);
const create = (data) => succeeded(types.CREATE, data);
const update = (data) => succeeded(types.UPDATE, data);
const remove = (id) => succeeded(types.DELETE, id);
const changeSelectedItem = (id) => succeeded(types.CHANGE_SELECTED_ITEM, id);
const uploaded = (data) => succeeded(types.UPLOAD_SUCCEEDED, data);
const clearApiError = () => start(types.CLEAR_API_RESULT);

export default {
  requestStart,
  requestFailed,
  fetched,
  fetchedList,
  created,
  updated,
  removed,
  fetch,
  fetchList,
  fetchListStart,
  create,
  update,
  remove,
  handleShowHideModal,
  changeSelectedItem,
  updateCurrentItemNumber,
  updateSort,
  updatePage,
  upload,
  uploaded,
  submitFailed,
  clearApiError,
};
