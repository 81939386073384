import {
  put,
  call,
  select,
  takeLatest,
  all,
  fork,
  takeEvery,
} from 'redux-saga/effects';
import sharedSagas from 'store/sharedSagas';
import commonSelectors from 'store/common/selectors';
import settingsSelectors from 'store/settings/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import filterSelectors from 'store/filters/selectors';
import periodAnalyzeSelectors from 'store/log/period-analyze/selectors';
import masterDataActions from 'store/master-data/actions';
import PeriodAnalyzeApi from 'services/api/log/PeriodAnalyzeApi';
import bookmarkApi from 'services/api/bookmarkApi';
import handleError from 'services/error/handleScopeError';
import periodAnalysisService from 'services/period-analyze/periodAnalysisService';
import * as logPeriodAnalyzeService from 'services/log/periodAnalyzeService';
import { communicationStatus, HttpStatus } from 'services/utils';
import {
  API_DATE_FORMAT,
  API_UNLIMITED_VALUE,
  BOOKMARK_FUNC_NAME,
} from 'domain/consts';
import { LOG_PERIOD_ANALYZE } from 'services/routes/constants';
import { PERIOD, TERMINAL_TYPE } from 'domain/settings/display-items';
import { PRIORITY_AXIS } from 'domain/fields';
import chartService from 'services/period-analyze/chartService';
import { convertApiReportResponse } from 'domain/responseUtils';
import actions from './actions';
import types from './types';

const { BAD_REQUEST } = HttpStatus;
const { LOADING } = communicationStatus;

function* getStatesRequest(action) {
  const isGetDataTable = action === types.GET_DATA_TABLE;

  const [
    channel,
    period,
    filters,
    dimensions,
    metricsForTable,
    metricsForChart,
    periodType,
    sort,
  ] = [
    yield select(settingsSelectors.getTab),
    yield select(commonSelectors.periodSelector),
    yield select(filterSelectors.getForApi),
    yield select(periodAnalyzeSelectors.dimensionsSelector),
    yield select(periodAnalyzeSelectors.metricsSelector),
    yield select(periodAnalyzeSelectors.metricForChart),
    yield select(periodAnalyzeSelectors.getPeriodTypeForApi),
    yield select(periodAnalyzeSelectors.sortSelector),
  ];

  // Default request param for chart
  let param = {
    channel,
    filters,
    dimensions,
    metrics: Object.keys(metricsForChart),
    limit: API_UNLIMITED_VALUE,
    start_date: period.start.format(API_DATE_FORMAT),
    end_date: period.end.format(API_DATE_FORMAT),
    summary_mode: periodType,
    sum: false,
  };

  if (isGetDataTable) {
    param = {
      ...param,
      ...sort,
      sum: true,
      metrics: metricsForTable,
    };
  }

  return param;
}

function* handleGetSettingBookmark() {
  const comparedPeriod = yield select(commonSelectors.comparePeriodSelector);
  const res = yield call(bookmarkApi.get, {
    func_id: BOOKMARK_FUNC_NAME.LOG_PERIOD_ANALYZE,
  });

  const {
    metric,
    compare_metric: compareMetric,
    status,
    show_chart: showChart,
  } = res.data.data;

  const settings = {
    primary: metric,
    secondary: compareMetric,
    enabled: status,
    showChart,
  };

  const shouldApplyMetrics = periodAnalysisService.shouldApplyBookmarkMetrics(
    settings,
    comparedPeriod.enabled
  );
  yield put(actions.setSetting(settings, shouldApplyMetrics));
}

function* handleGetDataTable() {
  yield put(actions.setStatusTable(LOADING));
  const [
    pageId,
    conversions,
    { enabled: comparedEnabled },
    comparedPeriod,
    dimensions,
  ] = [
    yield select(settingsSelectors.getPage),
    yield select(masterDataSelectors.conversionsSelector),
    yield select(commonSelectors.comparePeriodSelector),
    yield select(commonSelectors.comparePeriodSelector),
    yield select(periodAnalyzeSelectors.dimensionsSelector),
  ];

  if (pageId !== LOG_PERIOD_ANALYZE || !dimensions.includes(PERIOD)) {
    return;
  }

  if (!conversions || conversions.length <= 0) {
    yield put(masterDataActions.fetchConversions());
  }
  const normalTableRequestParams = yield getStatesRequest(types.GET_DATA_TABLE);
  const requestArr = [normalTableRequestParams];
  if (comparedEnabled) {
    const comparedTableRequestParams = {
      ...normalTableRequestParams,
      start_date: comparedPeriod.start.format('YYYY-MM-DD'),
      end_date: comparedPeriod.end.format('YYYY-MM-DD'),
    };
    requestArr.push(comparedTableRequestParams);
  }

  const apis = requestArr.map((obj) => call(PeriodAnalyzeApi.getData, obj));

  const response = yield all(apis);
  if (response.length > 1 && comparedEnabled) {
    const [{ data }, { data: dataCompared }] = response;
    yield put(actions.setDataTable(data));
    yield put(actions.setDataComparedTable(dataCompared));
  } else {
    const [{ data }] = response;
    yield put(actions.setDataTable(data));
  }
}

function* handleGetDataChart() {
  yield put(actions.setStatusChart(LOADING));
  const [
    pageId,
    usePeriodAxisOnly,
    periodType,
    comparedPeriod,
    dimensionsArray,
  ] = [
    yield select(settingsSelectors.getPage),
    yield select(periodAnalyzeSelectors.usePeriodAxisOnlySelector),
    yield select(periodAnalyzeSelectors.periodTypeSelector),
    yield select(commonSelectors.comparePeriodSelector),
    yield select(periodAnalyzeSelectors.dimensionsSelector),
  ];

  if (pageId !== LOG_PERIOD_ANALYZE || !dimensionsArray.includes(PERIOD))
    return;

  const statesRequest = yield getStatesRequest(types.GET_DATA_CHART);

  const callApiActions = [call(PeriodAnalyzeApi.getData, statesRequest)];
  if (comparedPeriod.enabled) {
    callApiActions.push(
      call(PeriodAnalyzeApi.getData, {
        ...statesRequest,
        start_date: comparedPeriod.start.format(API_DATE_FORMAT),
        end_date: comparedPeriod.end.format(API_DATE_FORMAT),
      })
    );
  }

  const [res, comparedRes] = yield all(callApiActions);

  const dimensions = usePeriodAxisOnly
    ? statesRequest.dimensions
    : statesRequest.dimensions.filter((item) => item !== PERIOD);

  const mapperKeys = [
    { field: PERIOD, key: PERIOD, value: PERIOD },
    { field: TERMINAL_TYPE, key: TERMINAL_TYPE, value: TERMINAL_TYPE },
    { field: PRIORITY_AXIS, key: PRIORITY_AXIS, value: PRIORITY_AXIS },
  ];

  const converters = periodAnalysisService.addConverters({ periodType });

  // Prepare data for chart
  const rawList = convertApiReportResponse(res, { converters });
  let list = periodAnalysisService.buildCategoriesList(
    rawList.data.data.detail,
    dimensions,
    usePeriodAxisOnly,
    mapperKeys
  );

  let categories = [{ [PERIOD]: PERIOD }];
  if (!usePeriodAxisOnly) {
    list = list.filter((item) => item.terminal_type !== null);
    categories = list.map((item) => item.dimensionKey);
  }

  // Prepare data compare for chart
  let comparedList = null;
  if (comparedPeriod.enabled) {
    const rawComparedList = convertApiReportResponse(comparedRes, {
      converters,
    });
    comparedList = periodAnalysisService.buildCategoriesList(
      rawComparedList.data.data.detail,
      dimensions,
      usePeriodAxisOnly,
      mapperKeys
    );
    if (!usePeriodAxisOnly) {
      comparedList = comparedList.filter((item) => item.terminal_type !== null);
    }
  }

  yield put(actions.setDataChart({ list, categories, comparedList }));
}

function* handleToggleChart(action) {
  const { enabled } = action.payload;
  yield call(bookmarkApi.saveChartSetting, {
    func_id: BOOKMARK_FUNC_NAME.LOG_PERIOD_ANALYZE,
    show_chart: enabled,
  });
}

function* handleSaveBookmark(action) {
  const { enabled } = action.payload;

  const axis = yield select(periodAnalyzeSelectors.getSettingChartAxis);
  const setting = {
    metric: axis.primary,
    func_id: BOOKMARK_FUNC_NAME.LOG_PERIOD_ANALYZE,
    status: enabled,
  };
  if (axis.secondary) {
    setting.compare_metric = axis.secondary;
  }

  yield call(bookmarkApi.save, setting);
}

function* errorHandler(err, action) {
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [];

  yield put(actions.setErrors(action.type, errors, scope));
}

function* updateMemoSeriesForChart() {
  const memoEnabled = yield select(periodAnalyzeSelectors.memoEnabledSelector);
  const memoList = yield select(periodAnalyzeSelectors.memoListSelector);
  const displayList = yield select(
    periodAnalyzeSelectors.chartDisplayDataSelector
  );
  const periodRange = yield select(periodAnalyzeSelectors.periodRangeSelector);
  const updatedDisplayList = chartService.updateMemoListForChart({
    periodRange,
    memoEnabled,
    memoList,
    displayRows: displayList,
  });
  yield put(actions.updateChartDisplayMemo(updatedDisplayList));
}

function* fetchMemo() {
  const period = yield select(commonSelectors.periodSelector);
  const memoList = yield call(logPeriodAnalyzeService.loadMemo, {
    start: period.start,
    end: period.end,
  });
  const memoListWithIndex = periodAnalysisService.buildMemoListWithDisplayIndex(
    memoList
  );
  return memoListWithIndex;
}

function* loadMemo() {
  const memoList = yield fetchMemo();
  yield put(actions.loadMemoSuccess(memoList));

  yield fork(updateMemoSeriesForChart);
}

function* loadMemoIfNecessary() {
  let memoList = yield select(periodAnalyzeSelectors.memoListSelector);
  const memoEnabled = yield select(periodAnalyzeSelectors.memoEnabledSelector);
  const memoHidden = yield select(periodAnalyzeSelectors.memoHiddenSelector);

  if (memoEnabled && !memoHidden && (!memoList || memoList.length <= 0)) {
    memoList = yield fetchMemo();
    yield put(actions.loadMemoSuccess(memoList));
  }

  yield fork(updateMemoSeriesForChart);
}

function* deleteMemo(action) {
  yield put(actions.hideDeleteModal());
  yield put(actions.hideMemoModal());
  yield put(actions.setMemoErrors([]));
  yield put(actions.setMemoSubmitting(true));
  const memoId = action.payload;
  const response = yield call(PeriodAnalyzeApi.deleteMemo, memoId);
  if (periodAnalysisService.isMemoNotExistError(response)) {
    yield put(actions.showMemoErrorModal());
  }
  yield fork(loadMemo);
  yield put(actions.setMemoSubmitting(false));
}

function* updateMemo(action) {
  yield put(actions.setMemoErrors([]));
  yield put(actions.setMemoSubmitting(true));
  const memo = action.payload;
  const response = yield call(logPeriodAnalyzeService.updateMemo, memo);
  if (periodAnalysisService.isMemoNotExistError(response)) {
    yield put(actions.showMemoErrorModal());
  } else {
    const { status, data } = response;
    if (status === HttpStatus.OK) {
      yield put(actions.hideMemoModal());
      yield fork(loadMemo);
    } else if (status === HttpStatus.BAD_REQUEST) {
      yield put(actions.setMemoErrors(data.errors));
    }
  }

  yield put(actions.setMemoSubmitting(false));
}

function* createMemo(action) {
  yield put(actions.setMemoErrors([]));
  yield put(actions.setMemoSubmitting(true));
  const { category, content, memoStart: start, memoEnd: end } = action.payload;
  const response = yield call(logPeriodAnalyzeService.createMemo, {
    category,
    content,
    start,
    end,
  });
  const { status, data } = response;
  if (status === HttpStatus.OK) {
    yield put(actions.hideMemoModal());
    yield fork(loadMemo);
  } else if (status === HttpStatus.BAD_REQUEST) {
    // TODO handle create error
    yield put(actions.setMemoErrors(data.errors));
  }
  yield put(actions.setMemoSubmitting(false));
}

export default function* watch() {
  yield takeLatest(
    types.GET_SETTING,
    sharedSagas.safe(errorHandler, handleGetSettingBookmark)
  );
  yield takeLatest(
    types.GET_DATA_TABLE,
    sharedSagas.safe(errorHandler, handleGetDataTable)
  );
  yield takeLatest(
    types.GET_DATA_CHART,
    sharedSagas.safe(errorHandler, handleGetDataChart)
  );
  yield takeLatest(
    types.TOGGLE_CHART,
    sharedSagas.safe(errorHandler, handleToggleChart)
  );
  yield takeLatest(
    types.SAVE_BOOKMARK,
    sharedSagas.safe(errorHandler, handleSaveBookmark)
  );
  yield takeLatest(types.LOAD_MEMO, sharedSagas.safe(errorHandler, loadMemo));
  yield takeLatest(
    types.TOGGLE_MEMO,
    sharedSagas.safe(errorHandler, loadMemoIfNecessary)
  );
  yield takeEvery(
    types.DELETE_MEMO,
    sharedSagas.safe(errorHandler, deleteMemo)
  );
  yield takeEvery(types.CREATE_MEMO, createMemo);
  yield takeEvery(types.UPDATE_MEMO, updateMemo);
}
