import screenPermissionConfigs from 'domain/permissions/screenPermissionConfigs';
import * as DisplayItems from 'domain/settings/display-items';
import _ from 'lodash';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import range from 'lodash/range';
import moment from 'moment';
import { FILTER_KEY_CV, FILTER_OPERATOR_INCLUDE } from 'services/consts';
import displayService from 'services/display';
import pages from 'services/routes/pages';
import {
  LTV_SETTINGS_AD,
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_ORDER,
  TEMPLATE_CSV_PRODUCT_PATH,
  TEMPLATE_CSV_OFFER_PATH,
  TEMPLATE_CSV_CONDITION_PATH,
  TEMPLATE_CSV_PRODUCT_LINK_AD_PATH,
  TEMPLATE_CSV_ORDER_PATH,
  AGENCY_MANAGEMENT,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  AD_MANAGEMENT_MEDIA_TYPE,
  TEMPLATE_CSV_MEDIA_TYPE_PATH,
  TAG_MANAGEMENT_PV,
  TEMPLATE_CSV_TAG_MANAGEMENT_PV,
  TEMPLATE_CSV_TAG_MANAGEMENT_PV_LOG,
  IMPRESSION_AD_COST,
  APP_HELP_IMPRESSION_AD_COST_UPLOAD_MODAL,
  MEDIA_SYNC_MANAGEMENT,
  TAG_MANAGEMENT_CONTENT_CATEGORY,
  TEMPLATE_CSV_TAG_MANAGEMENT_CONTENT_CATEGORY,
  APP_HELP_AGENCY_MANAGEMENT_PAGE,
  TEMPLATE_CSV_AGENCY_MANAGEMENT,
} from 'services/routes/constants';
import cloneDeep from 'lodash/cloneDeep';
import {
  CHANNEL_NAME,
  DIMENSIONS_TO_KEY_VALUES,
  ITEM_KEY_RESOLUTION,
  MEDIAN_POINT_MAX_DECIMAL_DIGIT,
  MEDIAN_POINT_MAX_DIGIT,
} from './category-analyze/consts';
import {
  API_DATE_FORMAT,
  API_MONTH_FORMAT,
  CALENDAR_DAY_FORMAT,
  CHANNNEL_FIELDS,
  CURRENCY_FIELDS,
  CURRENCY_SYMBOL,
  CV_FIELD_PREFIX,
  NEW_CV_FIELD_PREFIX,
  CVR_FIELD_PREFIX,
  NEW_CVR_FIELD_PREFIX,
  DATE_FIELDS,
  DATE_RAGNGE_FIELDS,
  DATE_TIME_FIELDS,
  DECIMAL_FIELDS,
  FIELD_API_RESPONSE,
  FREQUENCY_FIELDS,
  FREQUENCY_MAXIMUM,
  HYPHEN_FIELDS,
  MASTER_DATA_FILTER_LIMIT,
  MEASUREMENT_FIELDS,
  NUMBER_FIELDS,
  PERCENTAGE_FIELDS,
  PERCENTAGE_SYMBOL,
  PERIOD_FIELDS,
  PERIOD_MONTHS_FIELDS,
  SCREEN_DATE_TIME_FORMAT,
  TERMINAL_FIELDS,
  AD_TYPE_FIELDS,
  URL_FIELDS,
  TABLE_ROWS_PER_PAGE,
  JA_JP,
  JPY,
  NO_PERMISSION_DISPLAY_VALUE,
  MATCH_TYPE,
  MATCH_TYPE_FIELDS,
  ARRTOSTR_FIELDS,
  MAX_ITEMS_DISPLAY_IN_COLUMN,
  COMMA_SEPARATOR,
  ONE_BYTE_COMMA_SEPERATOR,
  BOOLEAN_FIELDS,
  TRUE_FLAG,
  FALSE_FLAG,
  FIELD_PREFIX,
  DEFAULT_MAX_LINE_CSV,
} from './consts';
import * as FIELD from './fields';
import { PERIOD_TYPE } from './period-analyze/consts';
import { PV_MAXIMUM_LINE_CSV } from './tag-management/consts';
import {
  CONTENT_CATEGORY,
  OWNED_MEDIA,
  PAGE_ID,
  PAGE_NAME,
  URL,
} from './labels';

export const formatPeriod = (value, showZeros = false) => {
  const days = Math.floor(value / 86400);
  const hours = Math.floor(value / 3600) % 24;
  const minutes = Math.floor(value / 60) % 60;
  const seconds = Math.floor(value % 60);

  let period = '';

  if (days > 0) {
    period = `${days}日`;
  }
  if (hours > 0 || (!hours && days && (minutes > 0 || seconds > 0))) {
    period += `${hours}時間`;
  }
  if (minutes > 0 || (!minutes && seconds > 0 && (days > 0 || hours > 0))) {
    period += `${minutes}分`;
  }
  if (seconds > 0) {
    period += `${seconds}秒`;
  }

  if (isEmpty(period) && showZeros) {
    return '0秒';
  }

  return period;
};

export const formatPeriodMonths = (value) => {
  if (value === null || value === '') return '-';
  const MAX_MONTHS = 36;
  const suffix = value >= MAX_MONTHS ? 'ヶ月以上' : 'ヶ月';
  return `${value}${suffix}`;
};

/**
 * @param {string}dateString
 * @return {string}
 */
export const formatDate = (dateString, withTime = false) => {
  const date = moment(dateString);
  const format = withTime ? SCREEN_DATE_TIME_FORMAT : CALENDAR_DAY_FORMAT;
  return date.isValid() ? date.format(format) : '';
};

/**
 * @param {array[object]}urls
 * @return {array[string]}
 */
export const formatMatchTypeUrl = (urls) => {
  if (isEmpty(urls)) return urls;

  if (isArray(urls)) {
    return urls.map((item) => `${item.url} ${MATCH_TYPE[item.match_type]}`);
  }
  if (isObject(urls)) {
    return `${urls.url} ${MATCH_TYPE[urls.match_type]}`;
  }

  return urls;
};

/**
 * @param {array}arr
 * @return {string}
 */
export const formatArrToStr = (arr, limit = 0, jpComma = false) => {
  if (!isArray(arr) || arr.length === 0) return '';
  if (arr.length === 1) return arr[0];

  const separator = jpComma ? COMMA_SEPARATOR : `${ONE_BYTE_COMMA_SEPERATOR} `;

  if (limit > 0 && arr.length > limit) {
    return `${arr.slice(0, limit).join(separator)}${separator}...`;
  }

  return arr.join(separator);
};

/**
 * @param {string} name
 * @param {boolean} value
 * @return {string}
 */
export const formatBooleanValue = (name, value, useTranslation) => {
  const valueConverted = value ? TRUE_FLAG : FALSE_FLAG;
  return displayService.getKey(useTranslation)(`${name}.${valueConverted}`);
};

const nf = new Intl.NumberFormat();
const nffd = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const nffd2 = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const nfcurrencyjp = new Intl.NumberFormat(JA_JP, {
  style: 'currency',
  currency: JPY,
});

export const formatValueForTable = (
  value,
  metric,
  options = { showZeros: false, useTranslation: false }
) => {
  const { showZeros } = options;
  if (metric === FIELD.TRANSITION_RATE && value === 0)
    return `${value}${PERCENTAGE_SYMBOL}`;
  if (!showZeros && !value && !BOOLEAN_FIELDS.includes(metric)) return '';

  if (value === NO_PERMISSION_DISPLAY_VALUE) return NO_PERMISSION_DISPLAY_VALUE;
  if ((value === null || value === '') && HYPHEN_FIELDS.includes(metric)) {
    return '-';
  }

  const actualValue =
    NUMBER_FIELDS.includes(metric) ||
    metric.startsWith(CV_FIELD_PREFIX) ||
    metric.startsWith(NEW_CV_FIELD_PREFIX) ||
    metric.startsWith(CVR_FIELD_PREFIX) ||
    metric.startsWith(NEW_CVR_FIELD_PREFIX) ||
    metric.startsWith(FIELD_PREFIX.CNT_CV_CONTRIBUTE) ||
    metric.startsWith(FIELD_PREFIX.CNT_CVR_CONTRIBUTE)
      ? +value
      : value;

  let formattedValue = actualValue;

  if (isNaN(formattedValue)) {
    return value;
  }
  if (
    NUMBER_FIELDS.includes(metric) ||
    metric.startsWith(CV_FIELD_PREFIX) ||
    metric.startsWith(NEW_CV_FIELD_PREFIX) ||
    metric.startsWith(FIELD_PREFIX.CNT_CV_CONTRIBUTE) ||
    metric.startsWith(FIELD_PREFIX.CNT_CVR_CONTRIBUTE)
  ) {
    const metrics = [...DECIMAL_FIELDS, ...PERCENTAGE_FIELDS];
    if (!metrics.includes(metric)) {
      formattedValue = nffd.format(actualValue);
    } else {
      formattedValue = nf.format(actualValue);
    }
    if (metric.startsWith(FIELD_PREFIX.CNT_CVR_CONTRIBUTE)) {
      formattedValue = actualValue;
    }
    if (!showZeros && +formattedValue === 0) {
      return '';
    }
  }
  if (
    DECIMAL_FIELDS.includes(metric) ||
    metric.startsWith(CVR_FIELD_PREFIX) ||
    metric.startsWith(NEW_CVR_FIELD_PREFIX) ||
    metric.startsWith(FIELD_PREFIX.CNT_CVR_CONTRIBUTE)
  ) {
    formattedValue = nffd2.format(actualValue);
  }
  if (PERIOD_FIELDS.includes(metric)) {
    if (!value) return value;
    return formatPeriod(value, showZeros);
  }
  if (PERIOD_MONTHS_FIELDS.includes(metric)) {
    let periodValue = value;
    if (metric === FIELD.PAYBACK_PERIOD_DIFFERENCE && value > 0) {
      periodValue = `+${value}`;
    }
    return formatPeriodMonths(periodValue);
  }
  if (CURRENCY_FIELDS.includes(metric)) {
    return nfcurrencyjp.format(actualValue).replace('￥', CURRENCY_SYMBOL);
  }
  if (
    PERCENTAGE_FIELDS.includes(metric) ||
    metric.startsWith(CVR_FIELD_PREFIX) ||
    metric.startsWith(NEW_CVR_FIELD_PREFIX) ||
    metric.startsWith(FIELD_PREFIX.CNT_CVR_CONTRIBUTE)
  ) {
    return `${formattedValue}${PERCENTAGE_SYMBOL}`;
  }
  if (URL_FIELDS.includes(metric)) {
    if (!value) return value;
    return value.replace(/(https?:\/\/)?(www.)?/i, '');
  }
  if (FREQUENCY_FIELDS.includes(metric)) {
    if (!value) return value;
    if (value >= FREQUENCY_MAXIMUM) {
      return `${FREQUENCY_MAXIMUM}回以上`;
    }
    return `${formattedValue}回`;
  }
  if (DATE_FIELDS.includes(metric)) {
    return formatDate(value);
  }
  if (DATE_TIME_FIELDS.includes(metric)) {
    return formatDate(value, true);
  }
  if (DATE_RAGNGE_FIELDS.includes(metric)) {
    if (!value) return value;
    let [startDate, endDate] = value.split('~');
    startDate = moment(startDate.trim());
    endDate = moment(endDate.trim());
    return startDate.isValid() && endDate.isValid()
      ? `${startDate.format('YYYY/MM/DD')} ~ ${endDate.format('YYYY/MM/DD')}`
      : '';
  }
  if (CHANNNEL_FIELDS.includes(metric) && options.useTranslation) {
    if (!value) return value;

    formattedValue = displayService.getChannelName(options.useTranslation)(
      value
    );

    return formattedValue;
  }
  if (TERMINAL_FIELDS.includes(metric) && options.useTranslation) {
    if (!value) return value;

    formattedValue = displayService.getTerminalTypeName(options.useTranslation)(
      value
    );

    return formattedValue;
  }
  if (MEASUREMENT_FIELDS.includes(metric) && options.useTranslation) {
    if (!value) return value;

    formattedValue = displayService.getMeasurementTypeName(
      options.useTranslation
    )(value);

    return formattedValue;
  }
  if (AD_TYPE_FIELDS.includes(metric) && options.useTranslation) {
    if (!value) return value;

    formattedValue = displayService.getAdTypeName(options.useTranslation)(
      value
    );

    return formattedValue;
  }
  if (BOOLEAN_FIELDS.includes(metric) && options.useTranslation) {
    return formatBooleanValue(metric, value, options.useTranslation);
  }
  if (MATCH_TYPE_FIELDS.includes(metric)) {
    return formatMatchTypeUrl(value);
  }
  if (ARRTOSTR_FIELDS.includes(metric)) {
    return formatArrToStr(value, MAX_ITEMS_DISPLAY_IN_COLUMN, true);
  }

  return formattedValue;
};

export const formatCurrency = (value) => {
  const formattedValue = nffd.format(+value);

  return `${CURRENCY_SYMBOL}${formattedValue}`;
};

export const formatNumber = (value) => {
  const formattedValue = nffd.format(+value);

  return formattedValue;
};

export const formatDecimal = (value, digits = 2) => {
  return nf.format((+value).toFixed(digits));
};

export const formatDateRangeStr = (startDateStr, endDateStr) => {
  return `${startDateStr} ～ ${endDateStr}`;
};

/**
 * Format a date range string
 * @param {moment}startDate
 * @param {moment}endDate
 * @return {string}
 */
export const formatDateRange = (startDate, endDate) => {
  return formatDateRangeStr(
    startDate.format(CALENDAR_DAY_FORMAT),
    endDate.format(CALENDAR_DAY_FORMAT)
  );
};

/**
 * format position unit
 * @param {number/string} num
 * @param {string} unit
 * @param {bool} isCurrency
 * @returns string
 */
export const formatPositionUnit = (num, unit, isCurrency) => {
  if (isCurrency) {
    return `${unit}${num}`;
  }
  return `${num}${unit}`;
};

export const buildPeriodRange = (periodType, { start, end }) => {
  const buildIncrementalPeriod = () => {
    let timeUnit;
    let periodFormat;
    switch (periodType) {
      case PERIOD_TYPE.MONTH:
        timeUnit = 'month';
        periodFormat = API_MONTH_FORMAT;
        break;
      case PERIOD_TYPE.DAY:
        timeUnit = 'day';
        periodFormat = API_DATE_FORMAT;
        break;
      default:
        return [];
    }
    const dates = [];
    const endMoment = moment(end).startOf(timeUnit);
    const current = moment(start).startOf(timeUnit);
    while (endMoment.isSameOrAfter(current)) {
      dates.push(current.format(periodFormat));
      current.add(1, timeUnit);
    }
    return dates;
  };

  switch (periodType) {
    case PERIOD_TYPE.HOUR:
      return range(0, 24);
    case PERIOD_TYPE.MONTH:
      return buildIncrementalPeriod();
    case PERIOD_TYPE.DOW:
      return range(0, 7);
    case PERIOD_TYPE.DAY:
      return buildIncrementalPeriod();
    default:
      return [];
  }
};

export const checkIsLastMonth = (date) => {
  if (date === null || date === undefined) return false;

  return (
    moment(date).year() === moment().year() &&
    moment(date).month() === moment().month() - 1
  );
};

export const getFieldApiResponse = (field) => {
  if (FIELD_API_RESPONSE[field]) {
    return FIELD_API_RESPONSE[field];
  }

  return {
    field,
    key: field,
    value: field,
  };
};

export const validateMedianPoint = (value) => {
  // Skip if value is empty
  if (!value || value === '') return true;

  // Validate is a number
  const numberValue = Number(value);

  if (!numberValue) return false;

  // Validate min max
  if (numberValue < 0.01) return false;
  const maxNumber = 10 ** MEDIAN_POINT_MAX_DIGIT - 1;
  if (numberValue > maxNumber) return false;

  // Validate decimal digits
  const parts = value.toString().split('.');
  if (parts.length > 2) return false;
  if (parts.length === 2) {
    if (parts[1] && parts[1].length > MEDIAN_POINT_MAX_DECIMAL_DIGIT) {
      return false;
    }
    if (+parts[0] === maxNumber) {
      return false;
    }
  }
  return true;
};

export const getDownloadImageName = (functionName) => {
  const date = moment().local().format('YYYYMMDDhhmmss');
  return `${date}_${functionName}`;
};

export const getFormatMetric = (metric) => {
  let baseMetric;
  if (metric.startsWith(CV_FIELD_PREFIX)) {
    baseMetric = FIELD.CNT_DIRECT_CV;
  } else if (metric.startsWith(NEW_CV_FIELD_PREFIX)) {
    baseMetric = FIELD.CNT_NEW_CV;
  } else if (metric.startsWith(CVR_FIELD_PREFIX)) {
    baseMetric = FIELD.CNT_DIRECT_RATE_CV;
  } else if (metric.startsWith(NEW_CVR_FIELD_PREFIX)) {
    baseMetric = FIELD.NEW_CVR;
  } else {
    baseMetric = metric;
  }
  return baseMetric;
};

export const isCvField = (field) =>
  field.startsWith(CV_FIELD_PREFIX) || field.startsWith(NEW_CV_FIELD_PREFIX);
export const isCvrField = (field) =>
  field.startsWith(CVR_FIELD_PREFIX) || field.startsWith(NEW_CVR_FIELD_PREFIX);

export const getValidSortFields = (sorts, dimensions, metrics) => {
  const validFields = dimensions.concat(metrics);
  return sorts
    .filter((sort) => {
      if (isCvField(sort.field)) {
        return validFields.includes(getFormatMetric(sort.field));
      }

      if (isCvrField(sort.field)) {
        return validFields.includes(getFormatMetric(sort.field));
      }

      return validFields.includes(sort.field);
    })
    .map((sort) => {
      if (sort.field === FIELD.CHANNEL_ACCESS_TYPE) {
        return {
          ...sort,
          field: FIELD.CHANNEL,
        };
      }
      return sort;
    });
};

export const isItemDisplayable = (item, settingValue, priorityAxis) => {
  return (
    item.getRequired(priorityAxis) ||
    settingValue ||
    (isNil(settingValue) && item.displayDefault)
  );
};

export const createFieldsFromSettingItems = (items, settings, priorityAxis) => {
  return Object.keys(items)
    .filter((key) => isItemDisplayable(items[key], settings[key], priorityAxis))
    .sort((key1, key2) => items[key1].order - items[key2].order)
    .map((key) => ({
      ...items[key],
      key,
    }));
};

export const getSelectedConversions = (conversions, filters) => {
  if (!filters[FILTER_KEY_CV]) {
    return conversions;
  }
  return conversions.filter((conversion) => {
    return filters[FILTER_KEY_CV].ids.includes(`${conversion.id}`);
  });
};

export const createFieldsFromConversions = (conversions, metrics) => {
  if (!isArray(conversions) || !isArray(metrics)) {
    return [];
  }

  const displayItemsBase = DisplayItems.getDisplayItemsBase();
  return conversions.map((conversion) => ({
    name: `cnt_direct_${conversion.id}`,
    text: conversion.name,
    isDimension: false,
    sort: false,
    children: metrics.map((metric) => ({
      name: `${metric}_${conversion.id}`,
      text: displayItemsBase[metric].titleTable,
    })),
  }));
};

export const createNewCpaFields = (metrics) => {
  if (!isArray(metrics)) {
    return [];
  }

  const displayItemsBase = DisplayItems.getDisplayItemsBase();
  return {
    name: 'new_cpa_group',
    text: DisplayItems.NEW_CPA_COLUMN_GROUP_LABEL,
    isDimension: false,
    sort: false,
    children: metrics.map((metric) => ({
      name: metric,
      text: displayItemsBase[metric].titleTable,
    })),
  };
};

export const createPaybackPeriodFields = (metrics) => {
  if (!isArray(metrics)) {
    return [];
  }

  const displayItemsBase = DisplayItems.getDisplayItemsBase();
  return {
    name: 'period_payback_group',
    text: DisplayItems.PAYBACK_PERIOD_COLUMN_GROUP_LABEL,
    isDimension: false,
    sort: false,
    children: metrics.map((metric) => ({
      name: metric,
      text: displayItemsBase[metric].titleTable,
    })),
  };
};

export const createDimensionKeyString = (item, dimensions) => {
  const dm = cloneDeep(dimensions);
  dm.push(FIELD.PRIORITY_AXIS);
  const sortedDimensions = dm.concat().sort();
  const abbreviation = {
    [FIELD.PRIORITY_AXIS]: 'pa',
    [FIELD.CHANNEL]: 'ch',
    [FIELD.CATEGORY]: 'ct',
    [FIELD.CHANNEL_ACCESS_TYPE]: 'ch',
    [FIELD.AD_GROUP1]: 'a1',
    [FIELD.AD_GROUP2]: 'a2',
    [FIELD.AGENCY]: 'ag',
    [FIELD.SYNC_CATEGORY]: 'syct',
    [FIELD.MEDIA_SIDE_CAMPAIGN]: 'mc',
    [FIELD.MEDIA_SIDE_GROUP]: 'mg',
    [FIELD.MEDIA_SIDE_AD_NAME]: 'syad',
  };
  return sortedDimensions
    .map(
      (dimension) =>
        `${abbreviation[dimension]}:${item[ITEM_KEY_RESOLUTION[dimension].id]}`
    )
    .join('-');
};

export const createSeriesName = (item, dimensionKey) => {
  const ids = Object.keys(dimensionKey);
  return DIMENSIONS_TO_KEY_VALUES.filter(
    (dimension) =>
      ids.includes(dimension.key) && dimension.key !== FIELD.PRIORITY_AXIS
  )
    .map((dimension) => {
      if (dimension.field === FIELD.CHANNEL_ACCESS_TYPE) {
        return CHANNEL_NAME[item[dimension.value]];
      }
      return item[dimension.value];
    })
    .filter((name) => name)
    .join('/');
};

export const convertArray2Object = (
  data,
  idKey = 'id',
  callbackCustomData = (item) => item
) => {
  const dataObject = {};
  data.map((item) => {
    dataObject[item[idKey]] = callbackCustomData(item);
    return item;
  });
  return dataObject;
};

export const convertArrayToObject = (array = [], keyId = 'id') =>
  array.reduce(
    (obj, item, index) => ({
      ...obj,
      [item[keyId]]: { ...item, order: array.length - index + 1 },
    }),
    {}
  );

export const getScreenPermissions = (screenId, tab) => {
  if (typeof screenId !== 'string') throw new Error('Not valid screenId input');
  // if (typeof tab !== 'string') throw new Error('Not valid tab input');

  const { entry } = screenPermissionConfigs[screenId];

  const type = entry.length ? 'entry' : tab;

  return screenPermissionConfigs[screenId][type];
};

export const getScreenResolveType = (screenId) => {
  if (typeof screenId !== 'string') throw new Error('Not valid screenId input');

  return screenPermissionConfigs[screenId].resolveType || 'anyOf';
};

export const getScreenPath = (screenId) => pages[screenId]?.path;

// num = 10 => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export const createArrayFromNumber = (num) => [...Array(num).keys()];

/**
 *
 * @param {Record<string, {key, value, order}>} masterData
 */
export const masterDataRecordsToSelectItems = (masterData, excludeIds = []) =>
  _.chain(masterData)
    .filter((v) => !excludeIds.includes(v.key))
    .mapValues((v) => ({ id: `${v.key}`, name: `${v.value}`, order: v.order }))
    .values()
    .sortBy((v) => v.order)
    .value();

export const buildSearchMasterDataParams = (
  selected = [],
  offset = 0,
  limit = MASTER_DATA_FILTER_LIMIT,
  search = ''
) => {
  const params = new URLSearchParams();
  if (selected.length > 0) {
    params.append('selected', selected);
  }
  if (offset > 0) {
    params.append('offset', offset);
  }
  if (offset !== MASTER_DATA_FILTER_LIMIT && limit > -1) {
    params.append('limit', limit);
  }
  if (search !== '') {
    params.append('filter_name', search);
  }
  return { params };
};

export const buildSearchMasterDataBody = (
  search = '',
  selected = [],
  offset = 0,
  limit = MASTER_DATA_FILTER_LIMIT,
  agencyId = '',
  isConvertArray = true
) => {
  const params = {
    offset,
    limit,
  };

  if (selected.length > 0 && isConvertArray) {
    params.selected = Array.from(selected, Number);
  } else if (selected.length > 0) {
    params.selected = cloneDeep(selected);
  }

  if (search !== '') {
    params.filter_name = search;
  }

  if (agencyId !== '') {
    params.agency_id = agencyId;
  }

  return params;
};

export const buildSearchMasterdataPage = ({
  field,
  value = '',
  selected = [],
  operator = FILTER_OPERATOR_INCLUDE,
  limit = MASTER_DATA_FILTER_LIMIT,
  offset = 0,
}) => {
  const params = {
    offset,
    limit,
  };

  if (selected.length > 0) {
    params.selected = selected;
  }
  if (value !== '') {
    params.filters = [{ field, operator, value }];
  }

  return params;
};

export const getNextSort = (currentSort) => {
  let direction = 'desc';
  if (currentSort === 'asc') {
    direction = 'desc';
  }
  if (currentSort === 'desc') {
    direction = 'asc';
  }

  return direction;
};

export const getSortString = (sort) => {
  return sort.direction === 'desc' ? `-${sort.field}` : `${sort.field}`;
};

export const getSortObject = (sort = '') => {
  if (isEmpty(sort)) return {};

  const value = sort.split('-');
  if (value.length === 2) {
    return { field: value[1], direction: DisplayItems.SORT_DIRECTION_DESC };
  }
  return { field: value[0], direction: DisplayItems.SORT_DIRECTION_ASC };
};

export const getOffset = (pagination) => {
  return (pagination.currentPage - 1) * TABLE_ROWS_PER_PAGE;
};

export const formatForDelete = (ids) => {
  return ids.join(',');
};

export const offset = (el) => {
  const rect = el.getBoundingClientRect();
  return {
    top: rect.top + rect.height + 6,
    left: rect.left,
    width: rect.width,
    height: rect.height,
  };
};

export const getTemplatePathByScreenId = (screenId, hasContract = false) => {
  switch (screenId) {
    case LTV_SETTINGS_PRODUCT:
      return TEMPLATE_CSV_PRODUCT_PATH;
    case LTV_SETTINGS_OFFER:
      return TEMPLATE_CSV_OFFER_PATH;
    case LTV_SETTINGS_CONDITION:
      return TEMPLATE_CSV_CONDITION_PATH;
    case LTV_SETTINGS_AD:
      return TEMPLATE_CSV_PRODUCT_LINK_AD_PATH;
    case LTV_SETTINGS_ORDER:
      return TEMPLATE_CSV_ORDER_PATH;
    case AD_MANAGEMENT_MEDIA_TYPE:
      return TEMPLATE_CSV_MEDIA_TYPE_PATH;
    case TAG_MANAGEMENT_CONTENT_CATEGORY:
      return TEMPLATE_CSV_TAG_MANAGEMENT_CONTENT_CATEGORY;
    case AGENCY_MANAGEMENT:
      return TEMPLATE_CSV_AGENCY_MANAGEMENT;
    case TAG_MANAGEMENT_PV:
      return hasContract
        ? TEMPLATE_CSV_TAG_MANAGEMENT_PV_LOG
        : TEMPLATE_CSV_TAG_MANAGEMENT_PV;
    default:
      return '';
  }
};

export const getUploadModalTitle = (screenId, customSubjectText) => {
  switch (screenId) {
    case LTV_SETTINGS_PRODUCT:
      return '商品のCSV一括登録';
    case LTV_SETTINGS_OFFER:
      return 'オファーのCSV一括登録';
    case LTV_SETTINGS_CONDITION:
      return '集計条件のCSV一括登録';
    case LTV_SETTINGS_AD:
      return '紐づけ広告IDのCSV一括登録';
    case LTV_SETTINGS_ORDER:
      return '受注データを登録';
    case AD_MANAGEMENT_MEDIA_TYPE:
      return '媒体種別のCSV一括登録';
    case AGENCY_MANAGEMENT:
      return '広告カテゴリのCSV一括登録';
    case AD_MANAGEMENT_AD_GROUP_1:
      return customSubjectText
        ? `${customSubjectText}のCSV一括登録`
        : '広告グループ01のCSV一括登録';
    case AD_MANAGEMENT_AD_GROUP_2:
      return customSubjectText
        ? `${customSubjectText}のCSV一括登録`
        : '広告グループ02のCSV一括登録';
    case TAG_MANAGEMENT_PV:
      return 'PV計測タグのCSV一括登録';
    case TAG_MANAGEMENT_CONTENT_CATEGORY:
      return '優先順位の並べ替え（CSVアップロード）';
    case IMPRESSION_AD_COST:
      return '表示回数/広告コストのCSV一括登録';
    default:
      return '';
  }
};

export const getDowloadTitle = (screenId) => {
  switch (screenId) {
    case TAG_MANAGEMENT_CONTENT_CATEGORY:
      return 'CSVファイル';
    default:
      return 'CSVフォーマット';
  }
};

export const getSubjectStr = (screenId) => {
  switch (screenId) {
    case LTV_SETTINGS_PRODUCT:
      return '商品';
    case LTV_SETTINGS_OFFER:
      return 'オファー';
    case LTV_SETTINGS_CONDITION:
      return '集計条件';
    case LTV_SETTINGS_AD:
      return '紐づけ広告ID';
    case LTV_SETTINGS_ORDER:
      return '受注データを';
    case AD_MANAGEMENT_MEDIA_TYPE:
      return '媒体種別';
    case AD_MANAGEMENT_AD_GROUP_1:
      return '広告グループ01';
    case AD_MANAGEMENT_AD_GROUP_2:
      return '広告グループ02';
    case TAG_MANAGEMENT_PV:
      return 'PV計測タグ';
    default:
      return '';
  }
};

export const getButtonToolTip = (screenId, actionStr) => {
  switch (screenId) {
    case LTV_SETTINGS_PRODUCT:
      return `商品の${actionStr}`;
    case LTV_SETTINGS_OFFER:
      return `オファーの${actionStr}`;
    case LTV_SETTINGS_CONDITION:
      return `集計条件を${actionStr}`;
    case AGENCY_MANAGEMENT:
      return `代理店を${actionStr}`;
    case AD_MANAGEMENT_MEDIA_TYPE:
      return `媒体種別を${actionStr}`;
    case AD_MANAGEMENT_AD_GROUP_1:
      return `広告グループ1を${actionStr}`;
    case AD_MANAGEMENT_AD_GROUP_2:
      return `広告グループ2を${actionStr}`;
    case MEDIA_SYNC_MANAGEMENT:
      return `媒体シンク設定を${actionStr}`;
    default:
      return actionStr;
  }
};

export const getDisplayString = (obj, fieldStr, glue = '/') => {
  const fields = fieldStr.split(',');
  const array = fields.map((field) => obj[field]);
  return array.join(glue);
};

export const getUploadModalNote = (screenId) => {
  switch (screenId) {
    case LTV_SETTINGS_ORDER:
      return '3か月前までのデータをアップロードできます。受注IDと会員IDの組み合わせが過去のアップロードデータと同じ場合、商品や売上金額の情報は上書きされます。<br/>※初回アップロード時に限り、2年前までのデータをアップロードできます。';
    case TAG_MANAGEMENT_PV:
      return `※サイトコンテンツが「対象外」で登録されていても、<a class="link_redirect" target="_blank" href=/${TAG_MANAGEMENT_CONTENT_CATEGORY} >[コンテンツカテゴリ]画面<i class="fas fa-external-link-alt color-gray-dark txt-standard"> </i> </a>のURL指定条件の設定次第ではサイトコンテンツ「対象」として分析画面に反映されます。<br/>※サイトコンテンツが「対象」で登録された場合、<a class="link_redirect" target="_blank" href=/${TAG_MANAGEMENT_CONTENT_CATEGORY} >[コンテンツカテゴリ]画面<i class="fas fa-external-link-alt color-gray-dark txt-standard"> </i></a>の設定にかかわらず、本ページIDの設定が分析画面に反映されます。`;
    case AGENCY_MANAGEMENT:
      return `※設定方法・注意点は<a href="${APP_HELP_AGENCY_MANAGEMENT_PAGE}" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください<br /> ※代理店や広告カテゴリの新規登録はできないためご注意ください`;
    case TAG_MANAGEMENT_CONTENT_CATEGORY:
      return '※CSVアップロードによるコンテンツカテゴリの新規登録ならびに削除はできません <br/> ※登録されているすべてのコンテンツカテゴリが記載された状態でCSVアップロードしてください';
    case IMPRESSION_AD_COST:
      return `※CSVの登録方法については<a href="${APP_HELP_IMPRESSION_AD_COST_UPLOAD_MODAL}" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください`;
    default:
      return '※画面への反映まで数分かかる場合がございます';
  }
};

export const getNoteUpload = (screenId) => {
  switch (screenId) {
    case LTV_SETTINGS_PRODUCT:
    case LTV_SETTINGS_OFFER:
    case TAG_MANAGEMENT_PV:
      return '30MB以下、20,000行まで登録可';
    case LTV_SETTINGS_CONDITION:
    case LTV_SETTINGS_AD:
      return '30MB以下、50,000行まで登録可';
    case LTV_SETTINGS_ORDER:
      return '30MB以下、400,000行まで登録可';
    case AD_MANAGEMENT_AD_GROUP_1:
    case AD_MANAGEMENT_AD_GROUP_2:
    case AD_MANAGEMENT_MEDIA_TYPE:
      return 'IDはシステムで自動採番されます。新規登録の際にはIDを空欄でご登録ください。';
    case IMPRESSION_AD_COST:
      return 'CSVの登録上限は、30MB以下、100,000行までです。';
    case TAG_MANAGEMENT_CONTENT_CATEGORY:
      return '30MB以下、10,000行まで登録可';
    default:
      return '30MB以下、100,000行まで登録可';
  }
};

export const crossDeviceItems = {
  cvTotalCross: [
    { text: '紐づけ前', name: DisplayItems.CV_TOTAL_CROSS_LINKED_BEFORE },
    { text: '紐づけ後', name: DisplayItems.CV_TOTAL_CROSS_LINKED_AFTER },
    { text: '差分', name: DisplayItems.CV_TOTAL_CROSS_DIFFERENCE },
    { text: '比率', name: DisplayItems.CV_TOTAL_CROSS_RATE },
  ],
  cntFirstCross: [
    { text: '紐づけ前', name: DisplayItems.CNT_FIRST_CROSS_LINKED_BEFORE },
    { text: '紐づけ後', name: DisplayItems.CNT_FIRST_CROSS_LINKED_AFTER },
    { text: '差分', name: DisplayItems.CNT_FIRST_CROSS_DIFFERENCE },
    { text: '比率', name: DisplayItems.CNT_FIRST_CROSS_RATE },
  ],
  rcvCross: [
    { text: '紐づけ前', name: DisplayItems.RCV_CROSS_LINKED_BEFORE },
    { text: '紐づけ後', name: DisplayItems.RCV_CROSS_LINKED_AFTER },
    { text: '差分', name: DisplayItems.RCV_CROSS_DIFFERENCE },
    { text: '比率', name: DisplayItems.RCV_CROSS_RATE },
  ],
  ramountCross: [
    { text: '紐づけ前', name: DisplayItems.RAMOUNT_CROSS_LINKED_BEFORE },
    { text: '紐づけ後', name: DisplayItems.RAMOUNT_CROSS_LINKED_AFTER },
    { text: '差分', name: DisplayItems.RAMOUNT_CROSS_DIFFERENCE },
    { text: '比率', name: DisplayItems.RAMOUNT_CROSS_RATE },
  ],
};

export const createCrossDeviceLinkege = (metric) => {
  switch (metric) {
    case FIELD.CV_TOTAL:
      return {
        name: `${FIELD.CV_TOTAL}_cross`,
        text: 'CV（合計）',
        isDimension: false,
        sort: false,
        children: crossDeviceItems.cvTotalCross,
      };
    case FIELD.CNT_FIRST:
      return {
        name: `${FIELD.CNT_FIRST}_cross`,
        text: '初回接触',
        isDimension: false,
        sort: false,
        children: crossDeviceItems.cntFirstCross,
      };
    case FIELD.RCV:
      return {
        name: `${FIELD.RCV}_cross`,
        text: '再配分CV',
        isDimension: false,
        sort: false,
        children: crossDeviceItems.rcvCross,
      };
    case FIELD.RAMOUNT:
      return {
        name: `${FIELD.RAMOUNT}_cross`,
        text: '再配分売上',
        isDimension: false,
        sort: false,
        children: crossDeviceItems.ramountCross,
      };
    case FIELD.CROSSDEVICE_DIFF_COMPARISON:
      return null;
    default:
      return null;
  }
};
