/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { object, objectOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';

function CalendarDay(props) {
  const { day, className } = props;

  const content = day.day?.date() || '0';

  const disabled =
    content !== '0' &&
    (day.isOutsideDay ||
      day.modifiers?.has('blocked') ||
      day.modifiers?.has('blocked-out-of-range'));

  const isToday = day.modifiers?.has('today');

  const selected = !disabled && day.modifiers?.has('selected');

  const clsName = classNames({
    'single-date-picker__day--empty': content === '0',
    'single-date-picker__day--disabled': disabled,
    'single-date-picker__day': content !== '' && !day.isOutsideDay,
    'single-date-picker__day--today': isToday,
    'single-date-picker__day--selected': selected,
    [className]: !!className,
  });

  return (
    <div
      className={clsName}
      onClick={(e) => {
        day.onDayClick(day.day, e);
      }}
    >
      {content}
    </div>
  );
}

CalendarDay.propTypes = {
  day: objectOf(oneOfType([object])).isRequired,
  className: string,
};

CalendarDay.defaultProps = {
  className: '',
};

export default CalendarDay;
