import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LOG_ROUTE_ANALYZE } from 'services/routes/constants';

const PageAnalyzeApiFactory = () => {
  const { endpoint } = pages[LOG_ROUTE_ANALYZE];

  return {
    getData: async (request) => Api.postRequest(endpoint, request),
    downloadCsv: async (request) => {
      return Api.downloadCsv(endpoint, request, {
        url: `${endpoint}/csv`,
      });
    },
  };
};

const PageAnalyzeApi = PageAnalyzeApiFactory();
export default PageAnalyzeApi;
