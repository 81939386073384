import helper from 'store/helper';
import types from './types';

const updateFilters = (items) =>
  helper.createAction(types.UPDATE_FILTERS, { items });

const updateAgencyFilters = (items) =>
  helper.createAction(types.UPDATE_AGENCY_FILTERS, { items });

const updateFiltersTagManagement = (items) =>
  helper.createAction(types.UPDATE_FILTERS_TAG_MANAGEMENT, { items });

const updateFiltersPageManagement = (items) =>
  helper.createAction(types.UPDATE_FILTERS_PAGE_MANAGEMENT, { items });

export default {
  updateFilters,
  updateAgencyFilters,
  updateFiltersTagManagement,
  updateFiltersPageManagement,
};
