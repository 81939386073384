export const ANALYSIS_SCREEN = 'main';
export const SITE_CONTENT = 'site_content';
export const LTV_ANALYZED_RESULT = 'ltv_analyzed_result';
export const CV_UNIT_PRICE = 'cv_cost';
export const COST_LINKING = 'cost_linking';
export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';
export const YDN = 'ydn';
export const YSS = 'yss';
export const TWITTER = 'twitter';
export const CRITEO = 'criteo';
export const CROSS_DEVICE = 'cross_device';
export const LOG = 'log';
export const GOOGLE_SEARCH_CONSOLE = 'google_search_console';

// Media Sync. Management
export const MEDIA_SYNC = 'media_sync';
export const MEDIA_SYNC_GG = 'gg';
export const MEDIA_SYNC_YS = 'ys';
export const MEDIA_SYNC_YD = 'yd';
export const MEDIA_SYNC_FB = 'fb';
export const MEDIA_SYNC_LN = 'ln';

export const PAID_CONTRACT = 2;
