import React, { useState } from 'react';
import {
  func,
  bool,
  element,
  string,
  number,
  oneOfType,
  node,
  shape,
  arrayOf,
} from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import CrossDeviceModal from 'views/pages/system-setting/components/CrossDeviceModal';
import {
  CROSS_DEVICE_MODE,
  CROSS_DEVICE_MODE_AI,
  CROSS_DEVICE_MODE_MEMBER,
} from 'domain/system-setting/consts';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import EbisStep from 'views/molecules/Step/EbisStep';
import './modal.scss';

function SettingModal(props) {
  const {
    loading,
    isShow,
    onSubmit,
    onHide,
    title,
    steps,
    dialogClassName,
    children,
    elementBelowLoading,
    cancelButtonText,
    confirmText,
    disabledSubmit,
    isShowCheckbox,
    pairType,
    modalId,
    pairTypeBeforeChange,
    tooltip,
    tooltipStayOpen,
    note,
    submitButtonText,
    otherButtonOption,
  } = props;
  const [onModal, setOnModal] = useState(false);
  const [onCrossDeviceModal, setOnCrossDeviceModal] = useState(false);
  const [isMemberNameModalShow, setIsMemberNameModalShow] = useState(false);
  const [isDefaultModalShow, setIsDefaultModalShow] = useState(false);
  const [isConfirmSubmit, setConfirmSubmit] = useState(false);

  const onCrossDeviceLinkedOkClick = () => {
    if (pairType === CROSS_DEVICE_MODE_MEMBER) {
      setIsMemberNameModalShow(true);
    } else {
      setIsDefaultModalShow(true);
    }
    setOnModal(true);
  };

  const onCrossDeviceOkClick = () => {
    setIsMemberNameModalShow(false);
    // If unchanged from the original "member_name", show the member_name popup and not the default popup
    if (pairTypeBeforeChange === CROSS_DEVICE_MODE_MEMBER) {
      setOnCrossDeviceModal(false);
      setIsDefaultModalShow(false);
      onSubmit();
    } else {
      setOnCrossDeviceModal(true);
      setIsDefaultModalShow(true);
    }
  };

  const onCrossDeviceCancelClick = () => {
    setIsDefaultModalShow(false);
    setIsMemberNameModalShow(false);
  };

  const onCrossDeviceSubmit = () => {
    setIsDefaultModalShow(false);
    onSubmit();
  };

  const onOkClick = () => {
    if (modalId === CROSS_DEVICE_MODE) {
      // If not originally changed in "predict", do not show popup
      if (
        pairTypeBeforeChange === pairType &&
        pairTypeBeforeChange === CROSS_DEVICE_MODE_AI
      ) {
        return onSubmit();
      }
      return onCrossDeviceLinkedOkClick();
    }
    return onSubmit();
  };

  return (
    <Modal
      show={isShow}
      centered
      size="w800"
      dialogClassName={dialogClassName}
      backdrop="static"
      className="modal--scroll"
    >
      <Modal.Header className={steps ? "justify-content-start" : ""}>
        <Modal.Title>
          <>
            {title}
            {tooltip && (
              <DragonBall variant="title" stayOpen={tooltipStayOpen}>
                {tooltip}
              </DragonBall>
            )}
            {note && <div className="modal__note">{note}</div>}
          </>
        </Modal.Title>
        {steps && (
          <EbisStep steps={steps} />
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="modal-scroll">
          {elementBelowLoading && loading ? (
            <div className="modal-loading__contain-text">
              <DataSyncLoader isLoading={loading}>{children}</DataSyncLoader>
              <div>{elementBelowLoading}</div>
            </div>
          ) : (
            <DataSyncLoader isLoading={loading}>{children}</DataSyncLoader>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isShowCheckbox && (
          <div className="mr-auto">
            <EbisCheckbox
              name="force-delete"
              text={confirmText}
              checked={isConfirmSubmit}
              disabled={loading}
              changeSelectBoxValue={() => setConfirmSubmit(!isConfirmSubmit)}
            />
          </div>
        )}
        <div className="modal-footer__control-button">
          <Button size="sm" variant="link" disabled={loading} onClick={onHide}>
            {cancelButtonText}
          </Button>
          <Button
            size="sm"
            variant="secondary"
            disabled={
              loading || disabledSubmit || (!isConfirmSubmit && confirmText)
            }
            onClick={onSubmit}
          >
            {submitButtonText}
          </Button>
          {otherButtonOption.onClick && (
            <Button
              size="sm"
              variant={otherButtonOption.variant}
              onClick={otherButtonOption.onClick}
              disabled={otherButtonOption.disabled}
            >
              {otherButtonOption.text}
            </Button>
          )}
        </div>
        {onModal && (
          <CrossDeviceModal
            isShow
            onSubmit={onCrossDeviceSubmit}
            dialogClassName="measurement-setting-modal"
            pairType={pairType}
            onModal={onCrossDeviceModal}
            isMemberNameModalShow={isMemberNameModalShow}
            isDefaultModalShow={isDefaultModalShow}
            onOkClick={onCrossDeviceOkClick}
            onCancelClick={onCrossDeviceCancelClick}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

SettingModal.propTypes = {
  loading: bool.isRequired,
  isShow: bool.isRequired,
  onSubmit: func.isRequired,
  onHide: func.isRequired,
  children: element,
  title: string,
  steps: arrayOf({}),
  dialogClassName: string,
  pairType: number.isRequired,
  modalId: string.isRequired,
  pairTypeBeforeChange: number.isRequired,
  elementBelowLoading: element,
  cancelButtonText: string,
  confirmText: string,
  isShowCheckbox: bool,
  disabledSubmit: bool,
  tooltip: oneOfType([string, node]),
  tooltipStayOpen: bool,
  note: oneOfType([string, node]),
  submitButtonText: string,
  otherButtonOption: shape({
    onClick: func,
    disabled: bool,
    text: string,
    variant: string,
  }),
};

SettingModal.defaultProps = {
  children: '',
  title: '',
  steps: [],
  dialogClassName: '',
  elementBelowLoading: null,
  cancelButtonText: 'キャンセル',
  confirmText: '',
  isShowCheckbox: false,
  disabledSubmit: false,
  tooltip: '',
  tooltipStayOpen: false,
  note: '',
  submitButtonText: 'OK',
  otherButtonOption: {
    onClick: null,
    disabled: false,
    text: '',
    variant: 'secondary',
  },
};

export default SettingModal;
