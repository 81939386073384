/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import './loader.scss';

const Loader = (props) => {
  const { height, top, className } = props;
  return (
    <div
      className={`spinner-container d-flex align-items-center justify-content-center ${className}`}
      style={{ height, top }}
    >
      <Spinner />
    </div>
  );
};

Loader.propTypes = {
  height: PropTypes.string,
  top: PropTypes.string,
  className: PropTypes.string,
};
Loader.defaultProps = {
  height: '',
  top: '',
  className: '',
};

export default Loader;
