/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import ErrorModal from 'views/molecules/modal/ErrorModal';

import {
  SETTINGS_SPECIFICATIONS_SITE,
  OLD_EBIS_HOME_SITE,
} from 'services/routes/constants';

const SystemCrashContainer = (props) => {
  return (
    <ErrorModal
      title={
        <>
          もう一度お試しいただいてもエラーが発生する場合は、下記エラーメッセージを記載の上、
          <a
            href={SETTINGS_SPECIFICATIONS_SITE}
            target="_blank"
            rel="noopener noreferrer"
          >
            サポートセンター
          </a>
          にお問い合わせください。
        </>
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default React.memo(SystemCrashContainer);
