import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { USER_PROFILE } from 'services/routes/constants';
import { saveAs } from 'services/utils';

const UserProfileApiFactory = () => {
  const url = pages[USER_PROFILE].endpoint;
  return {
    fetchUserProfile: async (data) => {
      return Api.getRequest(
        `${url}/${data.trackingData}?base_cv_record_id=${data.baseCvRecordId}`
      );
    },
    fetchUserBehaviors: async (data) => {
      return Api.postRequest(`${url}/${data.tracking_data}`, data);
    },
    downloadCsv: async (trackingData, baseCvRecordId, sort) => {
      const urlCsv = `${url}/${trackingData}/exports`;
      const response = await Api.post(
        urlCsv,
        {
          base_cv_record_id: baseCvRecordId,
          sort,
        },
        {
          url: urlCsv,
          batchRequest: true,
          timeout: 0,
        }
      );
      const { location } = response.data.data;
      if (location) {
        saveAs(location);
      }
    },
  };
};

const userProfileApi = UserProfileApiFactory();

export default {
  userProfileApi,
};
