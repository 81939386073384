import { call, put, throttle, takeLatest } from 'redux-saga/effects';
import { saveAs } from 'services/utils';
import LtvOrderApi from 'services/api/LtvOrderApi';
import handleError from 'services/error/handleScopeError';
import uploadActions from 'store/upload/actions';
import commonActions from 'store/common/actions';
import types from './types';
import actions from './actions';
// TODO get constants from routes/constants
// import { LTV_SETTINGS_CSV_ORDERS } from 'services/routes/constants';
const LTV_SETTINGS_CSV_ORDERS = 'ltv-settings-csv-order';
function* fetchList() {
  try {
    yield put(actions.requestStart());
    const response = yield call(LtvOrderApi.fetchAll);
    yield put(actions.fetchedList({ data: response.data.data }));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

function* downloadCsvOrderHistory(action) {
  try {
    yield put(actions.requestStart());
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    const response = yield call(LtvOrderApi.fetchFileUrl, action.payload);
    if (response.status === 200) {
      const { location } = response.data.data;
      saveAs(location);
    }
    yield put(commonActions.setDownloadNotify(''));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

function* upload(action) {
  try {
    const { file } = action.payload;
    yield put(uploadActions.requestStart(LTV_SETTINGS_CSV_ORDERS));

    const {
      data: {
        data: { location: uploadPath },
      },
    } = yield call(LtvOrderApi.getPresignedUrl);

    yield call(LtvOrderApi.upload, uploadPath, file);
    const url = new URL(uploadPath);
    // Get last element after `/` character
    const filename = url.pathname.split('/').slice(-1).pop();

    // validate & import data
    yield call(LtvOrderApi.doImport, filename);
    yield put(uploadActions.uploadSucceeded());
    yield put(actions.fetchList());
  } catch (error) {
    const { response = { status: 500 } } = error;
    const status = response?.status ?? 500;
    if (status === 400) {
      // validate error
      yield put(uploadActions.requestFailed(response.data.errors));
    } else {
      // api or app error
      yield put(uploadActions.requestError(handleError(response)));
    }
  }
}

export default function* watchView() {
  yield takeLatest(types.FETCH_LIST, fetchList);
  yield takeLatest(types.DOWNLOAD_CSV_ORDER_HISTORY, downloadCsvOrderHistory);
  yield throttle(3000, types.UPLOAD, upload);
}
