import * as FIELD from 'domain/fields';

const DIMENSIONS_TO_KEY_VALUES = [
  {
    field: FIELD.CHANNEL_ACCESS_TYPE,
    key: FIELD.CHANNEL,
    value: FIELD.CHANNEL,
  },
  {
    field: FIELD.CATEGORY,
    key: FIELD.CATEGORY_ID,
    value: FIELD.CATEGORY,
  },
  {
    field: FIELD.AD_GROUP1,
    key: FIELD.AD_GROUP1_ID,
    value: FIELD.AD_GROUP1_NAME,
  },
  {
    field: FIELD.AD_GROUP2,
    key: FIELD.AD_GROUP2_ID,
    value: FIELD.AD_GROUP2_NAME,
  },
  {
    field: FIELD.SYNC_CATEGORY,
    key: FIELD.SYNC_CATEGORY_ID,
    value: FIELD.SYNC_CATEGORY,
  },
  {
    field: FIELD.MEDIA_SIDE_CAMPAIGN,
    key: FIELD.MEDIA_SIDE_CAMPAIGN_ID,
    value: FIELD.MEDIA_SIDE_CAMPAIGN_NAME,
  },
  {
    field: FIELD.MEDIA_SIDE_GROUP,
    key: FIELD.MEDIA_SIDE_GROUP_ID,
    value: FIELD.MEDIA_SIDE_GROUP_NAME,
  },
  {
    field: FIELD.AGENCY,
    key: FIELD.AGENCY_ID,
    value: FIELD.AGENCY_NAME,
  },
  {
    field: FIELD.MEDIA_SIDE_AD_ID,
    key: FIELD.MEDIA_SIDE_AD_ID,
    value: FIELD.MEDIA_SIDE_AD_ID,
  },
  {
    field: FIELD.MEDIA_SIDE_AD_NAME,
    key: FIELD.MEDIA_SIDE_AD_NAME,
    value: FIELD.MEDIA_SIDE_AD_NAME,
  },
  {
    field: FIELD.PRIORITY_AXIS,
    key: FIELD.PRIORITY_AXIS,
    value: FIELD.PRIORITY_AXIS,
  },
];

const ITEM_KEY_RESOLUTION = {
  [FIELD.PRIORITY_AXIS]: {
    id: FIELD.PRIORITY_AXIS,
    name: FIELD.PRIORITY_AXIS,
  },
  [FIELD.AD_GROUP1]: {
    id: FIELD.AD_GROUP1_ID,
    name: FIELD.AD_GROUP1_NAME,
  },
  [FIELD.AD_GROUP2]: {
    id: FIELD.AD_GROUP2_ID,
    name: FIELD.AD_GROUP2_NAME,
  },
  [FIELD.CATEGORY]: {
    id: FIELD.CATEGORY_ID,
    name: FIELD.CATEGORY,
  },
  [FIELD.CHANNEL_ACCESS_TYPE]: {
    id: FIELD.CHANNEL,
    name: FIELD.CHANNEL,
  },
  [FIELD.AGENCY]: {
    id: FIELD.AGENCY_ID,
    name: FIELD.AGENCY_NAME,
  },
  [FIELD.SYNC_CATEGORY]: {
    id: FIELD.SYNC_CATEGORY_ID,
    name: FIELD.SYNC_CATEGORY,
  },
  [FIELD.MEDIA_SIDE_CAMPAIGN]: {
    id: FIELD.MEDIA_SIDE_CAMPAIGN_ID,
    name: FIELD.MEDIA_SIDE_CAMPAIGN_NAME,
  },
  [FIELD.MEDIA_SIDE_GROUP]: {
    id: FIELD.MEDIA_SIDE_GROUP_ID,
    name: FIELD.MEDIA_SIDE_GROUP_NAME,
  },
  [FIELD.MEDIA_SIDE_AD_NAME]: {
    id: FIELD.MEDIA_SIDE_AD_NAME,
    name: FIELD.MEDIA_SIDE_AD_NAME,
  },
};

const AVAILABLE_METRICS = [
  FIELD.IMPRESSION,
  FIELD.CNT_ACCESS,
  FIELD.CTR,
  FIELD.CNT_SESSION,
  FIELD.CNT_PV,
  FIELD.PER_PAGE_VIEW,
  FIELD.CNT_RETURN,
  FIELD.PER_RETURN,
  FIELD.TOTAL_STAY_TIME,
  FIELD.TOTAL_STAY_TIME_AVG,
  FIELD.TOTAL_LATENCY_TIME,
  FIELD.LATENCY_TIME_AVG,
  FIELD.CNT_DIRECT_CV,
  FIELD.CV_TOTAL,
  FIELD.CNT_DIRECT_RATE_CV,
  FIELD.CVR_TOTAL,
  FIELD.CNT_INDIRECT_2,
  FIELD.CNT_INDIRECT_3,
  FIELD.CNT_INDIRECT_4,
  FIELD.CNT_INDIRECT_5,
  FIELD.CNT_INDIRECT_OTHER,
  FIELD.INDIRECT_TOTAL,
  FIELD.DIRECT_RATE,
  FIELD.CNT_FIRST,
  FIELD.RCV,
  FIELD.AMOUNT,
  FIELD.RAMOUNT,
  FIELD.COST,
  FIELD.CPC,
  FIELD.CPA,
  FIELD.TCPA,
  FIELD.ROAS,
  FIELD.TROAS,
];

const CHART_PATTERNS = [
  { y: FIELD.CV_TOTAL, x: FIELD.CPA, pattern: 1 },
  { y: FIELD.CV_TOTAL, x: FIELD.CVR_TOTAL, pattern: 2 },
  { y: FIELD.AMOUNT, x: FIELD.ROAS, pattern: 2 },
  { y: FIELD.AMOUNT, x: FIELD.CVR_TOTAL, pattern: 2 },
  { y: FIELD.CNT_ACCESS, x: FIELD.CPC, pattern: 1 },
  { y: FIELD.RCV, x: FIELD.TCPA, pattern: 1 },
  { y: FIELD.RAMOUNT, x: FIELD.TROAS, pattern: 2 },
  { y: FIELD.INDIRECT_TOTAL, x: FIELD.PER_PAGE_VIEW, pattern: 2 },
  { y: FIELD.INDIRECT_TOTAL, x: FIELD.TOTAL_STAY_TIME_AVG, pattern: 2 },
  { y: FIELD.INDIRECT_TOTAL, x: FIELD.PER_RETURN, pattern: 1 },
];

const CONTEXT_MENU_OPTION = {
  TO_PERIOD_SCREEN: 1,
  TO_COST_ALLOC_SCREEN: 2,
  AD_GROUP1_FILTER: 3,
  AD_GROUP2_FILTER: 4,
  TO_AD_NAME_ANALYZE: 5,
  MEDIA_SIDE_CAMPAIGN_FILTER: 6,
  MEDIA_SIDE_GROUP_FILTER: 7,
  TO_MEDIA_SIDE_AD_NAME_ANALYZE: 8,
};

const MEDIAN_POINT_MAX_DECIMAL_DIGIT = 2;

const MEDIAN_POINT_MAX_DIGIT = 9;

const DEFAULT_SORT_CHART = [{ field: FIELD.CV_TOTAL, asc: false }];
const DEFAULT_SORT_TABLE = [{ field: FIELD.CV_TOTAL, asc: false }];

const CHANNEL_NAME = {
  '10': '広告クリック',
  '20': '自然検索',
  '30': '外部リンク',
  '40': 'ダイレクト',
  '50': 'サイト内',
};

export {
  CONTEXT_MENU_OPTION,
  CHART_PATTERNS,
  AVAILABLE_METRICS,
  DIMENSIONS_TO_KEY_VALUES,
  MEDIAN_POINT_MAX_DECIMAL_DIGIT,
  MEDIAN_POINT_MAX_DIGIT,
  DEFAULT_SORT_CHART,
  DEFAULT_SORT_TABLE,
  CHANNEL_NAME,
  ITEM_KEY_RESOLUTION,
};
