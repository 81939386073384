import { createSelector } from 'reselect';
import settingsSelectors from 'store/settings/selectors';
import { communicationStatus } from 'services/utils';
import {
  prepareLabelForDisplay,
  prepareConversionForDisplay,
  prepareConversionForSetting,
} from 'services/system-setting/systemSettingDisplayServices';
import { getPermissions } from 'store/auth/selectors';
import {
  SYSTEM_SETTING_DISPLAY,
  SETTING_SYSTEM_EDIT,
} from 'domain/permissions/contractPermissions';

const getData = (state) => state.cache.systemSettingDisplay.data;
const getStatusFetchAction = (state) =>
  state.cache.systemSettingDisplay.status.fetchAction;
const getStatusUpdateAction = (state) =>
  state.cache.systemSettingDisplay.status.updateAction;

const getStates = createSelector(
  [
    getData,
    settingsSelectors.getLoadingByStatus([getStatusFetchAction]),
    settingsSelectors.getLoadingByStatus(
      [getStatusUpdateAction],
      [communicationStatus.IDLE]
    ),
    getPermissions,
  ],
  (data, isLoading, isLoadingSetting, permissions) => {
    const isAllowedSettingDisplay =
      permissions.includes(SYSTEM_SETTING_DISPLAY) ||
      permissions.includes(SETTING_SYSTEM_EDIT);
    return {
      isLoading,
      isLoadingSetting,
      label: {
        display: prepareLabelForDisplay(data.label, isAllowedSettingDisplay),
        setting: data.label,
      },
      conversion: {
        display: prepareConversionForDisplay(
          data.conversions,
          isAllowedSettingDisplay
        ),
        setting: prepareConversionForSetting(data.conversions),
      },
      rcv: data.rcv,
    };
  }
);

const systemSettingDisplaySelectors = {
  getStates,
};

export default systemSettingDisplaySelectors;
