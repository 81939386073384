import Api from 'services/api/Api';
import { ACCOUNT_ENDPOINT } from 'services/routes/constants';

const fakeAlertData = {
  data: {
    alert: [
      {
        service: 'traffic_click',
        notice:
          'クリック数、PV数が上限の70％に到達しました。超過した場合、翌朝から計測停止となります。（継続計測をされる場合は%URL%）',
        url: `${process.env.REACT_APP_HOTEI_HOST}/billing_contract/?tabMode=5`,
        limit_low: 70,
        limit_high: 85,
        priority: 'high',
      },
      {
        service: 'traffic_pv',
        notice:
          '計測用DNSの設定対象ドメインにエラーが発生しています。計測用DNSの対象ドメインの再設定を行ってください。詳しくは%URL%',
        url: `${process.env.REACT_APP_HOTEI_HOST}/billing_contract/?tabMode=5`,
        limit_low: 70,
        limit_high: 85,
        priority: 'high',
      },
      {
        service: 'traffic_click_pv',
        notice:
          'クリック数が上限の70%、PV数が上限の20%に到達しました。超過した場合、計測量に応じて従量課金となり、当月の基本料金とあわせてご請求致します。%URL%',
        url: `${process.env.REACT_APP_HOTEI_HOST}/setup`,
        limit_low: 70,
        limit_high: 85,
        priority: 'high_low',
      },
    ],
    warning: [
      {
        service: 'warning_capi',
        notice:
          'CAPiCO設定でCV送信数が上限の70％に到達しました。%URL%でご確認いただき、必要に応じて「一時停止」のステータス変更を行ってください。',
        page_min: null,
        page_max: null,
        priority: null,
      },
      {
        service: 'warning_log',
        notice:
          'LOGエビスの取得ページ登録数が上限の10,000件に達しました。不要なページを削除ください。（複数のページをまとめて計測する場合は%URL%）',
        url: 'https://support.ebis.ne.jp/s/article/23962',
        page_min: 90000,
        page_max: 100000,
        priority: 'high',
      },
      {
        service: 'warning_cname',
        notice:
          '計測用DNSの設定対象ドメインにエラーが発生しています。計測用DNSの対象ドメインの再設定を行ってください。詳しくは%URL%）',
        url: 'https://support.ebis.ne.jp/s/article/29550',
        priority: '',
      },
    ],
  },
};

const AlertsApiFactory = () => {
  const url = `${ACCOUNT_ENDPOINT}/system/alerts`;
  return {
    getAlerts: async () => Api.get(url),
    getFakeAlerts: () => fakeAlertData,
  };
};

const AlertsApi = AlertsApiFactory();
export default AlertsApi;
