import React, { useCallback, useEffect } from 'react';
import { func, string, number, bool, shape, node } from 'prop-types';
import UploadSelectors from 'store/upload/selectors';
import { useSelector, shallowEqual } from 'react-redux';
import { STATUS_SUCCEEDED } from 'store/helper';
import { UNLIMITED_VALUE, CSV_MAX_SIZE } from 'domain/consts';
import UploadCsvPopupMenu from './UploadCsvPopupMenu';

const UploadCsvPopupMenuContainer = ({
  note,
  onChange,
  screenId,
  onShowHistory,
  onDownloadTemplate,
  customSubjectText,
  tooltipMessage,
  isAble,
  config,
  handleOpenUploadModal,
  uploadModalOptionNode,
  disableDefaultTemplateBtn,
  messageValidate,
}) => {
  const status = useSelector(UploadSelectors.getStatus, shallowEqual);
  const handleSend = useCallback(
    (file) => {
      onChange(file);
    },
    [onChange]
  );

  useEffect(() => {
    if (status?.status === STATUS_SUCCEEDED) {
      onChange();
    }
  }, [onChange, status]);
  return (
    <UploadCsvPopupMenu
      screenId={screenId}
      config={config}
      onShowHistory={onShowHistory}
      onDownloadTemplate={onDownloadTemplate}
      onSend={handleSend}
      status={status}
      note={note}
      customSubjectText={customSubjectText}
      tooltipMessage={tooltipMessage}
      isAble={isAble}
      handleOpenUploadModal={handleOpenUploadModal}
      uploadModalOptionNode={uploadModalOptionNode}
      disableDefaultTemplateBtn={disableDefaultTemplateBtn}
      messageValidate={messageValidate}
    />
  );
};

UploadCsvPopupMenuContainer.propTypes = {
  onChange: func,
  onShowHistory: func,
  onDownloadTemplate: func,
  screenId: string,
  note: string,
  customSubjectText: string,
  isAble: bool,
  tooltipMessage: string,
  config: shape({
    isMergeError: bool,
    maxLine: number,
    maxSize: number,
    field: shape({}),
    minSize: number,
  }),
  handleOpenUploadModal: func,
  uploadModalOptionNode: node,
  disableDefaultTemplateBtn: bool,
  messageValidate: shape({
    maxSize: string,
    minSize: string,
  }),
};

UploadCsvPopupMenuContainer.defaultProps = {
  onChange: () => {},
  onShowHistory: null,
  onDownloadTemplate: null,
  screenId: '',
  note: '',
  customSubjectText: '',
  tooltipMessage: 'CSV一括登録',
  isAble: true,
  config: {
    isMergeError: false,
    maxLine: UNLIMITED_VALUE,
    maxSize: CSV_MAX_SIZE,
    field: {},
    minSize: null,
  },
  handleOpenUploadModal: () => {},
  uploadModalOptionNode: <></>,
  disableDefaultTemplateBtn: false,
  messageValidate: {},
};

export default UploadCsvPopupMenuContainer;
