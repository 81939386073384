import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PropTypes, shape } from 'prop-types';
import { Button, Modal, Container, Form, Col, Badge } from 'react-bootstrap';
import classNames from 'classnames';
import moment from 'moment';
import { getPermissions } from 'store/auth/selectors';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import { DATE_RANGE_TYPE, CALENDAR_DAY_FORMAT } from 'domain/consts';

import 'views/organism/customview/customview.scss';
import './CustomViewModal.scss';

import ViewNavBarContainer from 'views/molecules/viewNavBar';
import ViewNavBarConfig from 'domain/commonbar/ViewNavBarConfig';
import EbisDateRangePicker from 'views/molecules/calendar';
import FilterForm from 'views/organism/FilterForm';
import SettingsForViewActions from 'store/settings-for-view/actions';
import SettingsForViewSelector from 'store/settings-for-view/selectors';
import selectorCustomView from 'store/customview/selectors';
import selectorCommon from 'store/common/selectors';
import actions from 'store/customview/actions';
import DisplayItemsForViewActions from 'store/display-items-for-view/actions';
import DisplayItemsForViewSelector from 'store/display-items-for-view/selectors';
import FilterAction from 'store/filters/actions';
import FilterSelector from 'store/filters/selectors';
import DisplayItemsForm from 'views/organism/DisplayItemsForm';
import DisplayItemsCvForm from 'views/organism/DisplayItemsCvForm';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';
import Loader from 'views/atoms/loader/Loader';
import {
  FUNC_CODE_INFLOW_ANALYSIS,
  DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  CROSSDEVICE_DIFF_COMPARISON,
  CONTACT_DEVICE,
  SEARCH_WORD,
} from 'domain/settings/display-items';
import apiContants from 'services/api/constants';
import Validator from 'services/validations';
import useFilterData from 'services/custom-hooks/useFilterData';
import { isEqual, differenceWith } from 'lodash';
import { createSelector } from 'reselect';
import * as DefinedConstant from 'domain/consts';
import {
  CATEGORY_ANALYZE,
  COMPARE_PERIOD,
  COST_ALLOCATION,
  INFLOW_ANALYSIS,
} from 'services/routes/constants';
import DisplayItemsApi from 'services/api/DisplayItemsApi';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import screenPermissionConfigs from 'domain/permissions/screenPermissionConfigs';
import screenTitleConfigs from 'services/common/screenTitleConfigs';
import { isWarningFilter } from 'services/utils';
import {
  TAB_AD,
  TAB_ALL,
  TAB_COMMON,
  FILTER_KEY_CROSS_DEVICE,
  FILTER_KEY_SEARCH_CONSOLE,
} from 'services/consts';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';

const formatInputValue = ({ start, end }) => {
  const startDate = start ? start.format(CALENDAR_DAY_FORMAT) : '';
  const endDate = end ? end.format(CALENDAR_DAY_FORMAT) : '';
  return `${startDate} ～ ${endDate}`;
};

const isEmpty = (str) => {
  let empty = false;
  if (!str || str.length === 0 || str.trim().length === 0) {
    empty = true;
  }
  return empty;
};

const nameValidDefault = { status: true, message: '' };

const contactHistoryDefault = Object.keys(
  DefinedConstant.FILTER_CONTACT_HISTORY
);

const CustomViewModal = React.memo(
  (props) => {
    const {
      attrs: {
        onHide,
        show,
        isEdit,
        isOverwrite,
        overwriteViewValue,
        viewsCount,
        crossDeviceReflectionData,
        errors,
        fetchEditStatus,
        viewRequestErrors,
        isLoadedViewTab,
        filterred,
        topPeriod,
        topComparePeriod,
        userPermissions,
        displayItemsBase,
        displayItems,
        displayItemsSettingsDefault,
        displayItemsAxis,
        displayItemsSorting,
        enabledPriorityAxis,
        displayPriorityAxis,
        viewEditing,
        currentTab,
        pageId,
        funcId,
        filterableList,
        mergedMasterData,
      },
    } = props;
    const { t } = useTranslation();
    const { checkPermissions } = useCheckPermissions();
    const [permissionErrorOccured, setPermissionErrorOccured] = useState(false);

    useEffect(() => {
      const closeModalErrorList = [apiContants.ERROR_CODE_NOT_EXIST];
      const errorCode = errors && errors[0]?.code;

      if (closeModalErrorList.includes(errorCode)) {
        onHide();
      }
    }, [errors, onHide]);

    const customViewModalRef = useRef(null);
    const containerFilterRef = useRef(null);
    const scrollbarWrapperRef = useRef(null);
    const { itemsCommon } = ViewNavBarConfig.aside;
    const targetScreen = itemsCommon.find((item) => {
      return funcId !== FUNC_CODE_INFLOW_ANALYSIS
        ? item.screenId === pageId
        : item.screenId === INFLOW_ANALYSIS;
    });
    const integrationTitle = itemsCommon
      .filter(
        (item) =>
          item.screenId !== INFLOW_ANALYSIS &&
          DisplayItemsService.getFuncIdByPageId(item.screenId) ===
            FUNC_CODE_INFLOW_ANALYSIS &&
          (checkPermissions(
            'anyOf',
            screenPermissionConfigs[item.screenId][TAB_AD]
          ) ||
            checkPermissions(
              'anyOf',
              screenPermissionConfigs[item.screenId][TAB_ALL]
            ))
      )
      .map((item) => t(item.title))
      .reduce((acc, cur) => `${acc}・${cur}`);

    // Store value for template name
    const [name, setName] = useState('');
    const [editLoading, setEditLoading] = useState(true);
    const [isDateSpecify, setDateSpecify] = useState(false);
    const [isSwitched, setSwitched] = useState(false);
    const [isShowOverwriteModal, setShowOverwriteModal] = useState(false);
    const [isShowSwitchingModal, setShowSwitchingModal] = useState(false);
    const [targetView, setTargetView] = useState('');

    const [period, setPeriod] = useState({
      start: moment().subtract(1, 'day'),
      end: moment().subtract(1, 'day'),
      preset: DATE_RANGE_TYPE.YESTERDAY,
    });
    const [comparePeriod, setComparePeriod] = useState({
      enabled: false,
      start: null,
      end: null,
      preset: null,
    });

    // Handle filters logics
    const [currentFilters, updateCurrentFilters] = useState({});
    const [viewId, updateViewId] = useState(null);
    const [priorityAxis, updatePriorityAxis] = useState(displayPriorityAxis);

    // handle error validate
    const [validateError, setValidateError] = useState({ filter: '' });

    const handleUpdateFilters = useCallback(
      (filters) => {
        updateCurrentFilters(filters);
        if (validateError.filter) {
          setValidateError((prevValidateError) => ({
            ...prevValidateError,
            filter: '',
          }));
        }
      },
      [validateError.filter]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const items = useMemo(() => displayItemsBase, [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(displayItemsBase),
    ]);
    const [displayItemsDefault, updateDisplayItemsDefault] = useState({});
    const [displayItemsSortDefault, updateDisplayItemsSortDefault] = useState(
      displayItemsSorting
    );
    const [displayItemsState, updateDisplayItemsState] = useState({});
    const [isNameValid, updateNameValid] = useState(nameValidDefault);
    // 202309. Fixed message is not displayed when an error occurs twice in a row
    const [isShowError, setShowError] = useState(false);

    // 優先軸フラグ
    // true: 媒体軸優先
    // false: エビス軸優先
    const mediaSync = useMemo(() => {
      if (Object.keys(displayItemsState).length === 0) {
        return priorityAxis === DefinedConstant.PRIORITY_AXIS_MEDIA_SYNC;
      }
      const { media_sync: sync } = displayItemsState;
      return sync;
    }, [displayItemsState, priorityAxis]);

    const handleDisplayItemsChange = useCallback(
      (data) => {
        updateDisplayItemsState(data);
      },
      [updateDisplayItemsState]
    );

    // Check permissions and select tabs
    const dispatch = useDispatch();
    const checkAndSetPermittedTab = (chkPageId) => {
      setPermissionErrorOccured(false);
      if (
        (screenPermissionConfigs[chkPageId][TAB_AD].length === 0 ||
          checkPermissions(
            'anyOf',
            screenPermissionConfigs[chkPageId][TAB_AD]
          )) &&
        checkPermissions('anyOf', screenPermissionConfigs[chkPageId][TAB_ALL])
      ) {
        dispatch(SettingsForViewActions.changeTab(TAB_COMMON));
        dispatch(actions.setLoadedViewTab());
        return true;
      }
      if (
        checkPermissions('anyOf', screenPermissionConfigs[chkPageId][TAB_AD])
      ) {
        dispatch(SettingsForViewActions.changeTab(TAB_AD));
        dispatch(actions.setLoadedViewTab());
        return true;
      }
      setPermissionErrorOccured(true);
      return false;
    };

    const handleChangePageConfirm = (screenId) => {
      setShowSwitchingModal(true);
      setTargetView(screenId);
    };
    const handleChangePage = (screenId) => {
      setShowSwitchingModal(false);
      setSwitched(true);
      const currentFiltersBefore = { ...currentFilters };
      const periodBefore = { ...period };
      const comparePeriodBefore = { ...comparePeriod };
      dispatch(actions.requestStartForView());
      dispatch(SettingsForViewActions.changePage(screenId));
      const funcId = DisplayItemsService.getFuncIdByPageId(screenId);
      const { displayitemsApi = DisplayItemsApi } = screenTitleConfigs[
        screenId
      ];
      if (!checkAndSetPermittedTab(screenId)) {
        return;
      }

      if (isEdit) {
        const displayItemsByFuncId = Object.keys(
          viewEditing.displayItems
        ).shift();
        if (funcId === displayItemsByFuncId) {
          // Get data from view template settings
          dispatch(actions.fetchEdit({ id: viewId }));
        } else {
          // Get data from default settings
          dispatch(
            DisplayItemsForViewActions.fetchDefault({ funcId, displayitemsApi })
          );
          dispatch(actions.fetchSwitchSucceeded());
        }
      } else {
        // Get data from display item settings
        dispatch(DisplayItemsForViewActions.fetch({ funcId, displayitemsApi }));
        dispatch(actions.fetchEditSucceeded({ edit: {} }));
      }
      // Update state to keep filters and periods after switching screens
      dispatch(FilterAction.updateFilters(currentFiltersBefore));
    };

    useEffect(() => {
      if (permissionErrorOccured) {
        throw new Error('Unauthorized pageId selected');
      }
    }, [permissionErrorOccured]);

    const [formProcessing, updateFormProcessing] = useState(false);
    const handleSubmit = (e, create = true) => {
      e.preventDefault();
      if (isOverwrite || isEdit) {
        setShowOverwriteModal(false);
      }

      if (isEmpty(name)) {
        updateNameValid({
          status: false,
          message: 'ビュー名を入力してください。',
        });
        return;
      }
      let currentDisplayItems = { ...displayItemsState };
      // User do not change display items
      if (Object.keys(displayItemsState).length === 0) {
        currentDisplayItems = { ...displayItemsDefault };
        if (displayItemsSortDefault.length > 0) {
          currentDisplayItems = {
            ...displayItemsDefault,
            __sorting__: displayItemsSortDefault,
          };
        }
      }
      // landing page analyze: handle validate when user choose display include search word but don't select filter google search console
      if (
        SEARCH_WORD in currentDisplayItems &&
        currentDisplayItems[SEARCH_WORD] &&
        !(FILTER_KEY_SEARCH_CONSOLE in currentFilters)
      ) {
        const scrollbarFilterEl = scrollbarWrapperRef.current.querySelector(
          '.filter-container__content'
        );
        scrollbarFilterEl.scrollIntoView({
          behavior: 'smooth',
        });
        setValidateError({
          ...validateError,
          filter:
            '集計軸で検索ワードを選択する場合は、フィルタで「Google Search Console連携」のデータを反映するにチェックをいれてください。',
        });
        return;
      }

      // Update display items when cross-device filters are cleared or removed.
      if (!(FILTER_KEY_CROSS_DEVICE in currentFilters)) {
        if (CROSSDEVICE_DIFF_COMPARISON in currentDisplayItems) {
          currentDisplayItems.crossdevice_diff_comparison = false;
        }
        if (CONTACT_DEVICE in currentDisplayItems) {
          currentDisplayItems.contact_device = false;
        }
      }
      const periodNew = { ...period };
      const comparePeriodNew = { ...comparePeriod };
      if (!isDateSpecify) {
        periodNew.enabled = false;
        periodNew.start = null;
        periodNew.end = null;
        periodNew.preset = null;
        comparePeriodNew.enabled = false;
        comparePeriodNew.start = null;
        comparePeriodNew.end = null;
        comparePeriodNew.preset = null;
      }
      const data = {
        displayItems: currentDisplayItems,
        filters: currentFilters,
        period: periodNew,
        comparePeriod: comparePeriodNew,
        name,
        priorityAxis,
      };
      // 202309. Fixed message is not displayed when an error occurs twice in a row
      setShowError(false);
      if (create) {
        dispatch(actions.create(data));
      } else if (isOverwrite) {
        dispatch(actions.overwrite({ ...data, id: viewId }));
      } else {
        dispatch(actions.update({ ...data, id: viewId }));
      }
      updateFormProcessing(true);
    };

    const viewEditingKeys = useMemo(() => JSON.stringify(viewEditing), [
      viewEditing,
    ]);
    const itemsKeys = useMemo(() => items.toString(), [items]);
    const displayItemsKeys = useMemo(() => JSON.stringify(displayItems), [
      displayItems,
    ]);

    useEffect(() => {
      if (!formProcessing) {
        return;
      }
      if (fetchEditStatus === 'failed') {
        let nameInvalid = false;
        // const overMax = false;
        if (Array.isArray(viewRequestErrors)) {
          let msg;
          nameInvalid = viewRequestErrors.some(
            (error) => error.code === apiContants.ERROR_CODE_VALUE_IS_EXISTS
          );
          if (nameInvalid) {
            // msg = messages.MSG_NAME_EXIST.replace('{name}', 'ビュー名');
            msg = Validator.getErrorMessage('isExist', ['ビュー名']);
          }
          if (nameInvalid === false) {
            nameInvalid = viewRequestErrors.some(
              (error) => error.code === apiContants.ERROR_CODE_MAX_LENGTH
            );
            msg = Validator.getErrorMessage('isLengthmax', ['ビュー名', '30']);
          }
          if (nameInvalid) {
            // 202309. Fixed message is not displayed when an error occurs twice in a row
            setShowError(true);
            updateNameValid({
              status: false,
              message: msg,
            });
          }
        }
        // system error
        if (!nameInvalid) {
          onHide();
          updateFormProcessing(false);
        }
      } else if (fetchEditStatus === 'succeeded') {
        onHide();
        updateFormProcessing(false);
      }
    }, [
      viewRequestErrors,
      fetchEditStatus,
      formProcessing,
      onHide,
      isShowError,
    ]);

    const isLoading = useMemo(() => {
      // If tab settings are not completed successfully, the status will be Loading.
      if (!isLoadedViewTab) {
        return true;
      }
      if (isEdit) {
        return fetchEditStatus === 'loading' || editLoading;
      }
      return fetchEditStatus === 'loading';
    }, [fetchEditStatus, viewEditing, editLoading]);

    useEffect(() => {
      if (Object.keys(viewEditing).length > 0) {
        const editViewFuncId = Object.keys(viewEditing.displayItems).shift();
        if (!isSwitched) {
          // load data from exist view
          setName(viewEditing.name);
          updateViewId(viewEditing.id);
          const filters = { ...viewEditing.filters };
          if ('contact_history' in filterableList) {
            filters.contact_history = {
              values: filters?.contact_history?.values || contactHistoryDefault,
              ...{
                order: filterableList.contact_history.order,
                title: filterableList.contact_history.title,
                type: filterableList.contact_history.type,
              },
            };
          }
          updateCurrentFilters(filters);
          if (JSON.stringify(viewEditing.period.start) === 'null') {
            setDateSpecify(false);
          } else {
            setDateSpecify(true);
            setPeriod(viewEditing.period);
            setComparePeriod(viewEditing.comparePeriod);
          }
        } else {
          const filters = { ...filterred };
          if ('contact_history' in filterableList) {
            const { order, title, type } = filterableList.contact_history;
            let values = contactHistoryDefault;
            if ('contact_history' in filters) {
              values = filters.contact_history.values;
            }
            filters.contact_history = { order, title, type, values };
          }
          updateCurrentFilters(filters);
        }

        if (editViewFuncId === funcId) {
          // Display items
          let displayItemsByFuncId = Object.values(viewEditing.displayItems);
          displayItemsByFuncId =
            displayItemsByFuncId.length > 0 ? displayItemsByFuncId[0] : {};

          const {
            __sorting__: sorting = [],
            ...currentDisplayItems
          } = displayItemsByFuncId;
          const { media_sync: sync } = currentDisplayItems;

          // updateDisplayItemsDefault(currentDisplayItems);
          updateDisplayItemsDefault(() => {
            if (
              ![CATEGORY_ANALYZE, COMPARE_PERIOD, COST_ALLOCATION].includes(
                pageId
              )
            ) {
              return currentDisplayItems;
            }
            return DisplayItemsService.getSettingFallback({
              items,
              settings: currentDisplayItems,
              priorityAxis,
            });
          });
          updateDisplayItemsSortDefault(sorting);
          updatePriorityAxis(
            sync && enabledPriorityAxis
              ? DefinedConstant.PRIORITY_AXIS_MEDIA_SYNC
              : DefinedConstant.PRIORITY_AXIS_EBIS
          );
        } else {
          // Reset to default state
          updateDisplayItemsDefault(displayItems);
          // Select ebis axis in the default state
          updatePriorityAxis(false);
        }
        setEditLoading(false);
      } else {
        // Create new
        if (!isSwitched) {
          if (isOverwrite) {
            setName(overwriteViewValue.name);
            updateViewId(overwriteViewValue.id);
          } else {
            setName('');
            updateViewId(null);
          }
          setPeriod(topPeriod);
          setComparePeriod(
            topComparePeriod.enabled
              ? topComparePeriod
              : { enabled: false, start: null, end: null }
          );
        }
        const filters = { ...filterred };
        if ('contact_history' in filterableList) {
          const { order, title, type } = filterableList.contact_history;
          let values = contactHistoryDefault;
          if ('contact_history' in filters) {
            values = filters.contact_history.values;
          }
          filters.contact_history = { order, title, type, values };
        }
        updateCurrentFilters(filters);
        updateDisplayItemsDefault(displayItems);
      }
      // due to issue compare object/array of js
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      viewEditingKeys,
      topPeriod,
      topComparePeriod,
      displayItemsKeys,
      itemsKeys,
      filterred,
      // Will do complex logic after each show/hide
      show,
    ]);

    // for CustomViewModal screen switching
    useEffect(() => {
      // Create new
      if (Object.keys(viewEditing).length === 0) {
        updatePriorityAxis(displayPriorityAxis);
      }
    }, [displayPriorityAxis]);

    const handlePeriodChange = (data) => {
      setPeriod(data.period);
      setComparePeriod(data.comparedPeriod);
    };

    const handleNameChange = (e) => {
      setName(e.target.value);
      if (!isEmpty(e.target.value)) {
        updateNameValid(nameValidDefault);
      }
    };

    // TODO: need clearly logic detect cv analysis
    const isCvAnalysis =
      'cv_name' in items &&
      items.cv_name.getGroup(mediaSync) === DISPLAY_GROUP_CONVERSION_ATTRIBUTES;

    const nameClass = classNames({
      'custom-view-modal__view-name': true,
      'custom-view-modal__view-name--error': !isNameValid.status,
    });

    const titleClass = classNames({
      'display-items-form-title': true,
      'display-items-form-title-inflow':
        typeof targetScreen !== 'undefined' &&
        targetScreen.screenId === INFLOW_ANALYSIS,
      'display-items-form-title-not-inflow':
        typeof targetScreen !== 'undefined' &&
        targetScreen.screenId !== INFLOW_ANALYSIS,
    });

    const handleCancel = () => {
      onHide();
      // reset validate message
      updateNameValid(nameValidDefault);

      // reset filter local state
      if (Object.keys(viewEditing).length > 0) {
        // case edit
        updateCurrentFilters(viewEditing.filters);
      } else {
        // case new
        updateCurrentFilters(filterred);
      }
    };

    /* 202309. [デフォルトに戻す]を表示項目・集計軸の隣に移動
    const [isCvDisplayDefault, toggleCvDisplayDefault] = useState(true);

    const [resetCvSettings, updateResetCvSettings] = useState(0);
    const handleResetCvForm = () => {
      if (!isCvDisplayDefault) {
        updateResetCvSettings((prev) => prev + 1);
      }
    };

    const linkResetCvFormClass = classNames({
      'custom-view-modal__reset-link': true,
      'custom-view-modal__reset-link--active': !isCvDisplayDefault,
    });
*/

    const calendarActiveFunc = () => {
      // setTimeout 100ms to wait for the calendar display on the screen
      setTimeout(() => {
        const scrollbarEl = scrollbarWrapperRef.current.querySelector(
          '.scrollbar-wrapper__content'
        );

        scrollbarEl.scroll({
          top: scrollbarEl.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    };

    const handleChangeRadio = (value) => {
      const isEnable = value === DefinedConstant.TRUE_FLAG;
      setDateSpecify(isEnable);
    };

    const conditionCross = () => {
      const warningFilter = isWarningFilter(
        FILTER_KEY_CROSS_DEVICE,
        currentFilters,
        pageId,
        period,
        crossDeviceReflectionData
      );
      return (
        FILTER_KEY_CROSS_DEVICE in currentFilters && warningFilter.isWarning
      );
    };

    return (
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        centered
        size="w800"
        className="custom-view-modal"
      >
        <Modal.Header>
          <Modal.Title>
            <>
              {!isCvAnalysis &&
                (isEdit || isOverwrite
                  ? '既存のビューを編集'
                  : '新しいビューを作成')}
              {isCvAnalysis && (
                <div className="display-items-modal__title">
                  <div className="display-items-modal_title-text">
                    {isEdit || isOverwrite
                      ? '既存のビューを編集'
                      : '新しいビューを作成'}
                  </div>
                  {/* 202309. [デフォルトに戻す]を表示項目・集計軸の隣に移動
                    !isLoading && (
                      <div
                        onClick={handleResetCvForm}
                        className={linkResetCvFormClass}
                        aria-hidden="true"
                      >
                        デフォルトに戻す
                      </div>
                    )
                  */}
                </div>
              )}
            </>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          ref={scrollbarWrapperRef}
          bsPrefix="custom-view-modal__body"
        >
          {isLoading ? (
            <Loader height={500} />
          ) : (
            <ScrollbarWrapper ref={scrollbarWrapperRef} maxContent={500}>
              <Container className="custom-view-modal__items custom-view-modal__items--margin-bottom">
                <h6 className="custom-view-modal__item-title">
                  ビュー名
                  <Badge
                    pill
                    variant="required"
                    className="badge-required badge-required-middle"
                  >
                    必須
                  </Badge>
                </h6>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Form.Row className="align-items-center">
                    <Col xs="auto">
                      <ErrorTooltipWrapper
                        isError={!isNameValid.status}
                        errorMess={isNameValid.message}
                        ref={customViewModalRef}
                      >
                        <Form.Control
                          type="text"
                          onChange={handleNameChange}
                          bsPrefix={nameClass}
                          placeholder="値を入力"
                          value={name}
                          maxLength="30"
                        />
                      </ErrorTooltipWrapper>
                    </Col>
                  </Form.Row>
                  <div className="note-wrapper">
                    <span className="note">30文字以内</span>
                  </div>
                </Form>
              </Container>
              <Container className="custom-view-modal__items custom-view-modal__items--margin-bottom">
                <h6 className="custom-view-modal__item-title">分析画面</h6>
                <ViewNavBarContainer
                  handleChangePage={handleChangePageConfirm}
                />
              </Container>
              <Container className="custom-view-modal__items custom-view-modal__items--margin-bottom">
                <h6 className="custom-view-modal__item-title">分析画面設定</h6>
                <div className="display-items-form-container">
                  <div className={titleClass}>
                    <p className="mb-0">
                      {targetScreen.screenId !== INFLOW_ANALYSIS
                        ? t(targetScreen.title)
                        : integrationTitle}
                    </p>
                    {targetScreen.screenId === INFLOW_ANALYSIS && (
                      <small className="custom-view-modal__note">
                        ※分析画面によってフィルタと集計軸・表示項目が一部異なります。条件・項目が一致するもののみ適用されます。
                      </small>
                    )}
                  </div>
                  <div className="display-items-form-container-sub">
                    {isCvAnalysis === false && (
                      <DisplayItemsForm
                        funcId={funcId}
                        items={items}
                        axisList={displayItemsAxis}
                        sortItems={displayItemsSortDefault}
                        defaultValue={displayItemsDefault}
                        onChange={handleDisplayItemsChange}
                        priorityAxis={priorityAxis}
                        enabledPriorityAxis={enabledPriorityAxis}
                        isWarning={conditionCross()}
                        filters={currentFilters}
                        customView
                        viewScreenId={pageId}
                        viewTabId={currentTab}
                      />
                    )}
                    {isCvAnalysis && (
                      <DisplayItemsCvForm
                        items={items}
                        defaultValue={displayItemsDefault}
                        settingsDefault={displayItemsSettingsDefault}
                        onChange={handleDisplayItemsChange}
                        // 202309. [デフォルトに戻す]を表示項目・集計軸の隣に移動
                        // resetCvSettings={resetCvSettings}
                        // onCheckDisplayDefault={toggleCvDisplayDefault}
                        priorityAxis={priorityAxis}
                        enabledPriorityAxis={enabledPriorityAxis}
                        resetLink
                        isWarning={conditionCross()}
                        filters={currentFilters}
                        customView
                      />
                    )}
                  </div>
                </div>
              </Container>
              <Container
                className="custom-view-modal__items custom-view-modal__items--margin-bottom"
                ref={containerFilterRef}
              >
                <h6 className="custom-view-modal__item-title">フィルタ</h6>
                <ErrorTooltipWrapper
                  isError={!!validateError.filter}
                  errorMess={validateError.filter}
                  ref={containerFilterRef}
                >
                  <FilterForm
                    customView
                    masterData={mergedMasterData}
                    filterableList={filterableList}
                    selectedFilters={currentFilters}
                    onChange={handleUpdateFilters}
                    userPermissions={userPermissions}
                    pageId={pageId}
                    currentTab={currentTab}
                    priorityAxis={
                      mediaSync
                        ? DefinedConstant.PRIORITY_AXIS_MEDIA_SYNC
                        : DefinedConstant.PRIORITY_AXIS_EBIS
                    }
                    period={period}
                    isViewMode
                    crossDeviceReflectionData={crossDeviceReflectionData}
                    displayItems={{
                      ...displayItemsDefault,
                      ...displayItemsState,
                    }}
                  />
                </ErrorTooltipWrapper>
              </Container>
              <Container className="custom-view-modal__items custom-view-modal__items--margin-bottom">
                <h6 className="custom-view-modal__item-title">対象期間</h6>
                <Container className="custom-view-modal__date-specify">
                  <EbisRadio
                    name="date-specify"
                    label="指定する"
                    value={DefinedConstant.TRUE_FLAG}
                    checked={isDateSpecify}
                    onChange={handleChangeRadio}
                  />
                  <EbisRadio
                    name="date-specify"
                    value={DefinedConstant.FALSE_FLAG}
                    label="指定しない"
                    checked={!isDateSpecify}
                    onChange={handleChangeRadio}
                  />
                </Container>
                {isDateSpecify && (
                  <>
                    <EbisDateRangePicker
                      isShowCompare={false}
                      onChange={handlePeriodChange}
                      period={period}
                      comparedPeriod={comparePeriod}
                      drop="right"
                      onActive={calendarActiveFunc}
                    />
                    <h6 className="custom-view-modal__item-title-sub">
                      期間比較
                    </h6>
                    <div className="custom-view-modal__compare-period">
                      {comparePeriod && comparePeriod.start
                        ? formatInputValue(comparePeriod)
                        : '指定なし'}
                    </div>
                  </>
                )}
              </Container>
            </ScrollbarWrapper>
          )}
        </Modal.Body>
        {!isLoading && (
          <Modal.Footer>
            <Button onClick={handleCancel} size="sm" variant="link">
              キャンセル
            </Button>
            {Object.keys(viewEditing).length > 0 && (
              <Button
                onClick={() => setShowOverwriteModal(true)}
                size="sm"
                variant="secondary"
              >
                上書き保存
              </Button>
            )}
            {isOverwrite && Object.keys(viewEditing).length === 0 && (
              <Button
                onClick={() => setShowOverwriteModal(true)}
                size="sm"
                variant="secondary"
              >
                上書き保存
              </Button>
            )}
            {!isOverwrite && Object.keys(viewEditing).length === 0 && (
              <Button onClick={handleSubmit} size="sm" variant="secondary">
                保存
              </Button>
            )}
          </Modal.Footer>
        )}
        {isShowSwitchingModal && (
          <ConfirmModal
            isShow="true"
            title="設定中の分析画面を切り替えます。よろしいですか？"
            jsxContent={
              <>
                <div className="sub-description">
                  別の分析画面に切り替えると、現在設定中の内容は破棄されますのでご注意ください。
                </div>
                <div className="notice-description">
                  ※複数の分析画面の設定を１つのビューに保存することはできません。
                </div>
              </>
            }
            onHide={() => setShowSwitchingModal(false)}
            onSubmit={() => handleChangePage(targetView)}
            cancelBtn
            confirmText="OK"
          />
        )}
        {isShowOverwriteModal && (
          <ConfirmModal
            isShow="true"
            title="既存のビューを上書き保存します。よろしいですか？"
            onHide={() => setShowOverwriteModal(false)}
            onSubmit={(e) => handleSubmit(e, false)}
            cancelBtn
            confirmText="OK"
          />
        )}
      </Modal>
    );
  },
  (prevProps, nextProps) => {
    return differenceWith([prevProps], [nextProps], isEqual).length === 0;
  }
);

CustomViewModal.propTypes = {
  attrs: PropTypes.objectOf({}).isRequired,
};

const selectCustomViewAttrs = createSelector(
  [
    (state) => selectorCustomView.getErrors(state),
    (state) => selectorCustomView.getStatusForView(state),
    (state) => selectorCustomView.getErrors(state),
    (state) => selectorCustomView.getLoadedViewTab(state),
    (state) => FilterSelector.getSettingsForView(state),
    (state) => selectorCommon.periodSelector(state),
    (state) => selectorCommon.comparePeriodSelector(state),
    (state) => getPermissions(state),
    (state) => DisplayItemsForViewSelector.getUserPermittedItems(state),
    (state) => DisplayItemsForViewSelector.getSettings(state),
    (state) => DisplayItemsForViewSelector.getSettingsDefault(state),
    (state) => DisplayItemsForViewSelector.getAxis(state),
    (state) => DisplayItemsForViewSelector.getSorting(state),
    (state) => DisplayItemsForViewSelector.enabledPriorityAxis(state),
    (state) => DisplayItemsForViewSelector.getDisplayItemPriorityAxis(state),
    (state) => selectorCustomView.getEditing(state),
    (state) => SettingsForViewSelector.getTab(state),
    (state) => SettingsForViewSelector.getPage(state),
    (state) => SettingsForViewSelector.getFuncId(state),
  ],
  (
    errors,
    fetchEditStatus,
    viewRequestErrors,
    isLoadedViewTab,
    filterred,
    topPeriod,
    topComparePeriod,
    userPermissions,
    displayItemsBase,
    displayItems,
    displayItemsSettingsDefault,
    displayItemsAxis,
    displayItemsSorting,
    enabledPriorityAxis,
    displayPriorityAxis,
    viewEditing,
    currentTab,
    pageId,
    funcId
  ) => ({
    errors,
    fetchEditStatus,
    viewRequestErrors,
    isLoadedViewTab,
    filterred,
    topPeriod,
    topComparePeriod,
    userPermissions,
    displayItemsBase,
    displayItems,
    displayItemsSettingsDefault,
    displayItemsAxis,
    displayItemsSorting,
    enabledPriorityAxis,
    displayPriorityAxis,
    viewEditing,
    currentTab,
    pageId,
    funcId,
  })
);

const CustomViewModalContainer = (props) => {
  const {
    onHide,
    show,
    isEdit,
    isOverwrite,
    overwriteViewValue,
    viewsCount,
    crossDeviceReflectionData,
  } = props;

  const attrs = useSelector(selectCustomViewAttrs);

  const { pageId, currentTab } = attrs;

  const [filterableList, mergedMasterData] = useFilterData(pageId, currentTab);

  return (
    <CustomViewModal
      attrs={{
        onHide,
        show,
        isEdit,
        isOverwrite,
        overwriteViewValue,
        viewsCount,
        crossDeviceReflectionData,
        filterableList,
        mergedMasterData,
        ...attrs,
      }}
    />
  );
};

CustomViewModalContainer.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  isEdit: PropTypes.bool,
  isOverwrite: PropTypes.bool,
  overwriteViewValue: PropTypes.object,
  viewsCount: PropTypes.bool.isRequired,
  crossDeviceReflectionData: shape([]),
};

CustomViewModalContainer.defaultProps = {
  onHide: () => {},
  show: false,
  isEdit: false,
  isOverwrite: false,
  overwriteViewValue: {},
  crossDeviceReflectionData: [],
};

export default CustomViewModalContainer;
