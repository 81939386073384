import { call, put, takeLatest } from 'redux-saga/effects';
import sharedSagas from 'store/sharedSagas';
import handleError from 'services/error/handleScopeError';
import Api from 'services/api/Api';
import apiServiceApi from 'services/api/ApiManagementApi';
import { communicationStatus, HttpStatus } from 'services/utils';

import actions from './actions';
import types from './types';

const { BAD_REQUEST } = HttpStatus;
const { LOADING } = communicationStatus;

function* handleGetRefreshToken() {
  yield put(actions.setStatusRefreshToken(LOADING));
  const { data } = yield call(apiServiceApi.fetchRefreshToken);
  yield put(actions.setRefreshToken(data?.refresh_token));
}

function* handleGenerateRefreshToken() {
  yield put(actions.setStatusRefreshToken(LOADING));
  const { data } = yield call(apiServiceApi.generateRefreshToken);
  yield put(actions.setRefreshToken(data?.refresh_token));
}

function* errorHandler(err, action) {
  const { error, scope } = handleError(err?.response || {});
  const errors = error?.data?.errors || [];

  switch (action.type) {
    case types.GENERATE_REFRESH_TOKEN:
      if (error?.status === BAD_REQUEST) {
        yield call(Api.refreshToken); // renew access token of content api
        yield put(actions.generateRefreshToken());
      } else {
        yield put(actions.setErrorRefreshToken(errors, scope));
      }
      break;

    default:
      yield put(actions.setErrorRefreshToken(errors, scope));
      break;
  }
}

export default function* watch() {
  yield takeLatest(
    types.GET_REFRESH_TOKEN,
    sharedSagas.safe(errorHandler, handleGetRefreshToken)
  );
  yield takeLatest(
    types.GENERATE_REFRESH_TOKEN,
    sharedSagas.safe(errorHandler, handleGenerateRefreshToken)
  );
}
