import {
  CostLinkServicesConfig,
  MediaSyncServicesConfig,
} from 'domain/topbar/RefectionTimeConfig';
import { API_DATE_FORMAT, CALENDAR_DAY_FORMAT } from 'domain/consts';

import moment from 'moment';
import logging from 'domain/logging';

let freshTimer;
const refreshInterval = (fn) => {
  try {
    clearInterval(freshTimer);
  } catch (error) {
    // ingore eror
  }
  freshTimer = setInterval(() => {
    fn();
    logging.debug('reflesh data');
  }, process.env.REACT_APP_REFLECTION_TIME_REFRESH_RATE);
};

class ReflectionTimeService {
  static startRelectionTimeService(fn) {
    clearInterval(refreshInterval);
    fn();
    refreshInterval(fn);
  }

  static getPreviousDate(timestamp) {
    return moment(timestamp)
      .subtract(1, 'days')
      .format('YYYY/MM/DD')
      .toString();
  }

  static formatDateTime(timestamp) {
    return moment(timestamp).format('YYYY/MM/DD HH:mm');
  }

  static formatDate(timestamp) {
    return moment(timestamp).format('YYYY/MM/DD');
  }

  static getServiceName(serviceKey) {
    return CostLinkServicesConfig[serviceKey].name;
  }

  static isDateFormat(date) {
    return moment(date, API_DATE_FORMAT).format(API_DATE_FORMAT) === date;
  }

  static getMediaName(syncMediaId) {
    return MediaSyncServicesConfig[syncMediaId];
  }

  static isCurrentDate(timestamp) {
    const currentDate = moment().format(CALENDAR_DAY_FORMAT);
    const updatedDate = moment(timestamp).format(CALENDAR_DAY_FORMAT);
    const diff = moment(currentDate).diff(moment(updatedDate), 'days');
    return diff <= 0;
  }
}

export default ReflectionTimeService;
