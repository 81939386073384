/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { PERIOD_COMPARABLE_SCREEN_IDS } from 'domain/consts';
import FilterService from 'domain/FilterService';
import { getScreenPermissions, getScreenResolveType } from 'domain/utils';
import PropTypes, {
  array,
  bool,
  func,
  object,
  oneOfType,
  string,
  shape,
  arrayOf,
} from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Nav, Navbar, Overlay, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import FilterContainer from 'views/organism/FilterForm/FilterContainer';
import LtvSettingFilterContainer from 'views/organism/FilterForm/LtvSettingFilterContainer';
import MediaGroupFilterContainer from 'views/organism/FilterForm/MediaGroupFilterContainer';
import { TAB_AD } from 'services/consts';
import DashboardBarContainer from 'views/molecules/commonbar/DashboardBarContainer';
import UserManagementBarContainer from 'views/molecules/commonbar/components/UserManagementBarContainer';
import RouteSettingContainer from 'views/pages/log/route-analyze/RouteSettingContainer';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import { useTranslation } from 'react-i18next';
import screenTitleConfigs from 'services/common/screenTitleConfigs';
import useAuth from 'services/custom-hooks/useAuth';
import {
  AD_MANAGEMENT,
  COMPARE_PERIOD,
  DASHBOARD,
  USER_MANAGEMENT,
  LTV_SETTINGS_OTHER,
  LTV_SETTINGS_CLEAR_CSV_ORDERS,
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  EXTERNAL_SERVICE_SETTINGS,
  USER_PROFILE,
  MEDIA_SYNC_MANAGEMENT,
  USAGE_STATUS_SITE,
  IMPRESSION_AD_COST,
  LOG_ROUTE_ANALYZE,
} from 'services/routes/constants';
import EbisDateRangePicker from 'views/molecules/calendar';
import AdManagementCommonBarContainer from 'views/molecules/commonbar/components/AdManagementCommonBarContainer';
import DownloadFilesContainer from 'views/molecules/commonbar/components/DownloadFilesContainer';
import CustomViewDropdown from 'views/organism/customview';
import DisplayItems from 'views/organism/displayitems';
import IconSettingsLtv from 'views/molecules/commonbar/components/IconSettingsLtv';
import isEmpty from 'lodash/isEmpty';

import PeriodDropDown from 'views/molecules/calendar/PeriodDropDown';

import 'views/molecules/commonbar/commonbar.scss';
import DashboardActions from 'store/dashboard/actions';
import DashboardSelectors from 'store/dashboard/selectors';
import DisplayItemsApi from 'services/api/DisplayItemsApi';
import usePrevious from 'services/custom-hooks/usePrevious';
import IconMediaSync from 'assets/images/icon-media-sync.svg'; // Screen Title Icon ( Media Sync. Management )
import { isWarningFilter } from 'services/utils';
import { reflectionTimeSelectors } from 'store/reflectiontime';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import { CROSS_DEVICE_VIEW } from 'domain/permissions/contractPermissions';
import { CROSS_DEVICE } from 'services/common/reflectiontime/constants';
import UserProfileDownloadFileContainer from './UserProfileDownloadFileContainer';

const FilterIcon = ({
  showFilter,
  setShowFilter,
  filters,
  onActive,
  filterChanged,
  variant,
  screenType,
  onFilter,
}) => {
  const [hover, setHover] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
    if (!showFilter) {
      onActive({ target: 'FilterIcon' });
    }
  };
  const prevScreenType = usePrevious(screenType);
  useEffect(() => {
    if (prevScreenType !== screenType && showFilter) {
      setShowFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevScreenType, screenType]);

  const filterClass = classNames({
    'icon-svg': true,
    'icon-filter': true,
    [variant]: !isEmpty(variant),
    fafilter: filterChanged,
    'filter--applied':
      hover === false &&
      !showFilter &&
      (Object.keys(filters).length === 0 || filters.length <= 0),
  });

  const toggleHover = () => {
    setHover((prev) => !prev);
  };

  return (
    <div
      onClick={toggleFilter}
      className={`nav-link nav-link--icon ${onFilter ? 'active' : ''}`}
      aria-hidden="true"
    >
      <div onMouseOver={toggleHover} onMouseOut={toggleHover}>
        <div className="d-flex justify-content-center">
          <span className={filterClass} />
        </div>
        <div>フィルタ</div>
      </div>
    </div>
  );
};

FilterIcon.propTypes = {
  showFilter: PropTypes.bool.isRequired,
  setShowFilter: PropTypes.func.isRequired,
  filters: oneOfType([object]).isRequired,
  onActive: PropTypes.func,
  filterChanged: PropTypes.bool,
  variant: PropTypes.oneOf(['sub-icon-settings']),
  screenType: PropTypes.string,
  onFilter: PropTypes.bool.isRequired,
};

FilterIcon.defaultProps = {
  onActive: () => {},
  filterChanged: false,
  variant: undefined,
  screenType: PropTypes.string,
};

const FreeOverlayTrigger = ({ children, text, positionRef }) => {
  const [showTooltip, toggleShowTooltip] = useState(false);
  const handleMouseEnter = useCallback(() => {
    toggleShowTooltip(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    toggleShowTooltip(false);
  }, []);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      <Overlay target={positionRef} show={showTooltip}>
        <Tooltip className="dragonball-tooltip variant-base">{text}</Tooltip>
      </Overlay>
    </div>
  );
};

FreeOverlayTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  positionRef: PropTypes.shape({}).isRequired,
  text: PropTypes.string.isRequired,
};

const CommonBar = ({
  filters,
  filtersSelected,
  applyFilter,
  currentTab,
  screenId,
  period,
  comparedPeriod,
  changePeriod,
  comparedPeriodDisabled,
  permissions,
  downloadCsvOptions,
  downloadDisabled,
  screenType,
  priorityAxis,
}) => {
  const { t } = useTranslation();
  const { checkPermissions } = useCheckPermissions();

  const {
    title: screenTitle,
    breadcrumbs,
    dragonBall,
    dragonBallToolTipPlacement = 'top',
    btnCalendar = true,
    disabledCalendar = false,
    messageWhenDisabledCalendar = '',
    btnDisplayItems = true,
    btnFilters = true,
    btnViewSettings = true,
    btnDownload = true,
    btnPeriodDropDown = false,
    periodActions = DashboardActions,
    periodSelectors = DashboardSelectors,
    displayitemsApi = DisplayItemsApi,
    displayConditions = false,
    isMenuSetting = false,
  } = screenTitleConfigs[screenId];

  const [activeFunc, setActiveFunc] = useState('');
  const [newFilters, setNewFilters] = useState(filters);
  const [showFilter, setShowFilter] = useState(activeFunc === 'FilterIcon');
  const [onFilter, setOnFilter] = useState(showFilter);

  const { isAllowed: allowedScreen } = useAuth(
    getScreenPermissions(screenId, currentTab),
    getScreenResolveType(screenId)
  );

  const reflectionData = useSelector(
    reflectionTimeSelectors.getReflectionTimeData,
    shallowEqual
  );

  const crossDeviceDisplay = checkPermissions('anyOf', [CROSS_DEVICE_VIEW]);

  const crossDeviceReflectionData = useMemo(() => {
    if (isEmpty(reflectionData)) return [];

    const data = [];
    if (!isEmpty(reflectionData[CROSS_DEVICE]) && crossDeviceDisplay) {
      data.push(...reflectionData[CROSS_DEVICE]);
    }

    return data;
  }, [reflectionData, crossDeviceDisplay]);

  const toggleActiveFunc = useCallback(({ target }) => {
    setActiveFunc(target);
  }, []);

  const onShowFilter = (isShow) => {
    const customFilters = FilterService.prepareForUI(
      filters,
      screenId,
      currentTab
    );
    Object.keys(customFilters).forEach((key) => {
      const isFilter = isWarningFilter(
        key,
        filters,
        screenId,
        period,
        crossDeviceReflectionData
      );
      if (isFilter.isWarning) {
        setShowFilter(true);
        setOnFilter(false);
      } else {
        setShowFilter(isShow);
        setOnFilter(isShow);
      }
    });
    if (Object.keys(customFilters).length === 0) {
      setShowFilter(isShow);
      setOnFilter(isShow);
    }

    setActiveFunc(isShow ? 'FilterIcon' : '');
  };

  useEffect(() => {
    const customFilters = FilterService.prepareForUI(
      filters,
      screenId,
      currentTab
    );
    // If no filter, filter closes
    if (activeFunc !== 'FilterIcon') {
      setShowFilter(false);
      setOnFilter(false);
    } else {
      setOnFilter(true);
    }
    // Force opening of filters if they contain invalid filters
    Object.keys(customFilters).forEach((key) => {
      const isFilter = isWarningFilter(
        key,
        filters,
        screenId,
        period,
        crossDeviceReflectionData
      );
      if (isFilter.isWarning) {
        setShowFilter(true);
        setOnFilter(false);
      }
    });

    return setNewFilters(customFilters);
  }, [
    screenId,
    currentTab,
    filters,
    activeFunc,
    period,
    setActiveFunc,
    crossDeviceReflectionData,
  ]);

  const isFilterChanged = FilterService.isFilterChanged(
    newFilters,
    screenId,
    currentTab,
    permissions,
    priorityAxis
  );

  useEffect(() => {}, [toggleActiveFunc, screenId]);

  if (screenId === AD_MANAGEMENT) {
    return (
      <AdManagementCommonBarContainer
        title={t(screenTitle)}
        tooltip={dragonBall}
        allowedScreen={allowedScreen}
      />
    );
  }

  if (screenId === USER_MANAGEMENT) {
    return (
      <div className="clearfix common-bar">
        <UserManagementBarContainer
          screenTitle={t(screenTitle)}
          dragonBall={dragonBall}
        />
      </div>
    );
  }

  if (screenId.startsWith('ltv/settings')) {
    return (
      <div className="clearfix common-bar">
        <Navbar>
          <Navbar.Brand as="div" className="mr-auto">
            <div className="channel">
              {currentTab === TAB_AD ? '広告＞' : '全トラフィック＞'}
              {breadcrumbs &&
                breadcrumbs.map((breadcrumb) => (
                  <Link className="common-bar__breadcrumb" to={breadcrumb.url}>
                    {breadcrumb.text}
                  </Link>
                ))}
            </div>
            <div className="page d-flex align-items-center">
              {t(screenTitle)}
              <DragonBall
                variant="title"
                icon="far fa-question-circle"
                stayOpen
              >
                {dragonBall}
              </DragonBall>
            </div>
          </Navbar.Brand>
          {allowedScreen && (
            <Nav>
              {btnFilters && (
                <FilterIcon
                  showFilter={showFilter}
                  setShowFilter={onShowFilter}
                  filters={filters}
                  onActive={toggleActiveFunc}
                  filterChanged={isFilterChanged}
                  variant="sub-icon-settings"
                  onFilter={onFilter}
                />
              )}
              {btnDownload && (
                <DownloadFilesContainer
                  toggleActiveFunc={toggleActiveFunc}
                  variant="sub-icon-settings"
                />
              )}
            </Nav>
          )}
        </Navbar>
        <div
          style={{
            display:
              showFilter &&
              screenId !== LTV_SETTINGS_CLEAR_CSV_ORDERS &&
              screenId !== LTV_SETTINGS_OTHER
                ? 'block'
                : 'none',
          }}
        >
          <LtvSettingFilterContainer />
        </div>
      </div>
    );
  }

  if (
    [
      AD_MANAGEMENT_MEDIA_TYPE,
      AD_MANAGEMENT_AD_GROUP_1,
      AD_MANAGEMENT_AD_GROUP_2,
    ].includes(screenId)
  ) {
    return (
      <div className="clearfix common-bar">
        <Navbar>
          <Navbar.Brand as="div" className="mr-auto">
            <div className="page d-flex align-items-center">
              {t(screenTitle)}
              <DragonBall
                variant="title"
                icon="far fa-question-circle"
                stayOpen
              >
                {dragonBall}
              </DragonBall>
            </div>
          </Navbar.Brand>
          {allowedScreen && (
            <Nav>
              {btnFilters && (
                <FilterIcon
                  showFilter={showFilter}
                  setShowFilter={onShowFilter}
                  filters={filters}
                  onActive={toggleActiveFunc}
                  filterChanged={isFilterChanged}
                  variant="sub-icon-settings"
                  onFilter={onFilter}
                />
              )}
              {btnDownload && (
                <DownloadFilesContainer
                  toggleActiveFunc={toggleActiveFunc}
                  variant="sub-icon-settings"
                />
              )}
            </Nav>
          )}
        </Navbar>
        <div
          style={{
            display: showFilter ? 'block' : 'none',
          }}
        >
          <MediaGroupFilterContainer />
        </div>
      </div>
    );
  }

  if (screenId === EXTERNAL_SERVICE_SETTINGS) {
    return (
      <div className="clearfix common-bar">
        <Navbar>
          <Navbar.Brand as="div" className="mr-auto">
            <div className="page d-flex align-items-center">
              {t(screenTitle)}
            </div>
          </Navbar.Brand>
        </Navbar>
      </div>
    );
  }

  if (screenId === MEDIA_SYNC_MANAGEMENT) {
    return (
      <div className="clearfix common-bar">
        <Navbar>
          <Navbar.Brand as="div" className="mr-auto">
            <div className="page d-flex align-items-center">
              <img
                src={IconMediaSync}
                alt="Screen Title Icon"
                className="screen-title-icon"
              />
              {t(screenTitle)}
              <DragonBall
                variant="title"
                icon="far fa-question-circle"
                stayOpen
              >
                {dragonBall}
              </DragonBall>
            </div>
          </Navbar.Brand>
        </Navbar>
      </div>
    );
  }

  if (screenId === USAGE_STATUS_SITE) {
    return (
      <div className="clearfix common-bar">
        <Navbar>
          <Navbar.Brand as="div" className="mr-auto">
            <div className="page d-flex align-items-center">
              {t(screenTitle)}
            </div>
          </Navbar.Brand>
        </Navbar>
      </div>
    );
  }

  if (screenId === IMPRESSION_AD_COST) {
    return (
      <div className="clearfix common-bar">
        <Navbar>
          <Navbar.Brand as="div" className="mr-auto">
            <div className="page d-flex align-items-center">
              {t(screenTitle)}
              <DragonBall
                variant="title"
                icon="far fa-question-circle"
                stayOpen
                placement="bottom"
              >
                {dragonBall}
              </DragonBall>
            </div>
          </Navbar.Brand>
        </Navbar>
      </div>
    );
  }

  const wrapper = classNames('clearfix', 'common-bar');
  const comparedPeriodSupported = PERIOD_COMPARABLE_SCREEN_IDS.includes(
    screenId
  );
  return (
    <div className={wrapper} screen-name={screenId}>
      <Navbar>
        <Navbar.Brand as="div" className="mr-auto">
          <div className="channel">
            {!isMenuSetting && (
              <>{currentTab === TAB_AD ? '広告＞' : '全トラフィック＞'}</>
            )}
            {breadcrumbs &&
              breadcrumbs.map((breadcrumb) => {
                if (breadcrumb.url) {
                  return (
                    <Link
                      className="common-bar__breadcrumb"
                      to={breadcrumb.url}
                    >
                      {breadcrumb.text}
                    </Link>
                  );
                }
                return breadcrumb.text;
              })}
          </div>
          <div className="page d-flex align-items-center">
            {t(screenTitle)}
            {dragonBall && (
              <DragonBall
                variant="title"
                icon="far fa-question-circle"
                placement={dragonBallToolTipPlacement}
                stayOpen
              >
                {dragonBall}
              </DragonBall>
            )}
          </div>
        </Navbar.Brand>
        {screenId === DASHBOARD && allowedScreen ? (
          <DashboardBarContainer />
        ) : (
          screenId &&
          allowedScreen && (
            <Nav>
              {btnCalendar && (
                <EbisDateRangePicker
                  period={period}
                  comparedPeriod={comparedPeriod}
                  onChange={changePeriod}
                  comparedPeriodDisabled={
                    !comparedPeriodSupported ||
                    (comparedPeriodSupported && comparedPeriodDisabled)
                  }
                  onActive={toggleActiveFunc}
                  active={activeFunc === 'EbisDateRangePicker'}
                  comparedPeriodSupported={comparedPeriodSupported}
                  disabled={disabledCalendar}
                  disabledMessage={messageWhenDisabledCalendar}
                />
              )}
              {btnPeriodDropDown && (
                <PeriodDropDown
                  periodActions={periodActions}
                  periodSelectors={periodSelectors}
                  allowedScreen={allowedScreen}
                  currentTab={currentTab}
                />
              )}
              {btnDisplayItems && (
                <DisplayItems
                  onActive={toggleActiveFunc}
                  active={activeFunc === 'DisplayItems'}
                  displayitemsApi={displayitemsApi}
                  displayConditions={displayConditions}
                  crossDeviceReflectionData={crossDeviceReflectionData}
                />
              )}
              {btnFilters && (
                <FilterIcon
                  showFilter={showFilter}
                  setShowFilter={onShowFilter}
                  filters={filters}
                  screenId={screenId}
                  onActive={toggleActiveFunc}
                  filterChanged={isFilterChanged}
                  variant={isMenuSetting && 'sub-icon-settings'}
                  screenType={screenType}
                  onFilter={onFilter}
                />
              )}
              {btnViewSettings && (
                <CustomViewDropdown
                  onActive={toggleActiveFunc}
                  crossDeviceReflectionData={crossDeviceReflectionData}
                  pageId={screenId}
                  tabId={currentTab}
                  // active={activeFunc === 'CustomViewDropdown'}
                />
              )}
              {screenId === LOG_ROUTE_ANALYZE && <RouteSettingContainer />}
              {btnDownload &&
                (screenId === USER_PROFILE ? (
                  <UserProfileDownloadFileContainer
                    toggleActiveFunc={toggleActiveFunc}
                  />
                ) : (
                  <DownloadFilesContainer
                    disabled={downloadDisabled}
                    toggleActiveFunc={toggleActiveFunc}
                    variant={isMenuSetting && 'sub-icon-settings'}
                    downloadCsvOptions={downloadCsvOptions}
                  />
                ))}
              <IconSettingsLtv screenId={screenId} />
            </Nav>
          )
        )}
      </Navbar>
      {btnFilters && (
        <div
          style={{
            display:
              showFilter && btnFilters && allowedScreen ? 'block' : 'none',
          }}
        >
          <FilterContainer
            handleApplyFilter={applyFilter}
            selectedFilters={filtersSelected}
            crossDeviceReflectionData={crossDeviceReflectionData}
            storeFilters={filters}
          />
        </div>
      )}
    </div>
  );
};

CommonBar.propTypes = {
  filters: oneOfType([array, object]),
  permissions: oneOfType([array]),
  currentTab: string.isRequired,
  comparedPeriodDisabled: bool,
  period: oneOfType([object]),
  comparedPeriod: oneOfType([object]),
  changePeriod: func,
  screenId: string,
  screenType: string,
  applyFilter: func,
  filtersSelected: shape({}),
  downloadCsvOptions: arrayOf(shape({})),
  downloadDisabled: bool,
  priorityAxis: string,
};

CommonBar.defaultProps = {
  filters: [],
  permissions: [],
  comparedPeriodDisabled: false,
  period: {},
  comparedPeriod: {},
  changePeriod: () => {},
  screenId: undefined,
  applyFilter: () => {},
  filtersSelected: {},
  downloadCsvOptions: [],
  downloadDisabled: false,
  screenType: '',
  priorityAxis: '',
};

export default React.memo(CommonBar);
