import { UPDATE_BOOKMARK_DEBOUNCE_LENGTH } from 'services/consts';
import { communicationStatus } from 'services/utils';
import caSelectors from 'store/category-analyze/selectors';
import {
  all,
  call,
  debounce,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import actions from 'store/category-analyze/actions';
import summaryService from 'services/category-analyze/summaryService';
import chartService from 'services/category-analyze/chartService';
import tableService from 'services/category-analyze/tableService';
import masterDataSelectors from 'store/master-data/selectors';
import masterDataActions from 'store/master-data/actions';
import displayItemsSelectors from 'store/display-items/selectors';
import sharedSagas from 'store/sharedSagas';
import { createDimensionKeyString } from 'domain/utils';
import settingsSelector from 'store/settings/selectors';
import { DEFAULT_SELECTED_CATEGORIES } from 'domain/consts';
import filterSelector from 'store/filters/selectors';
import loggerConstants from 'store/logger/constant';
import logging from 'domain/logging';
import { CATEGORY_ANALYZE } from 'services/routes/constants';
import { DisplayItemsSelectors } from 'store/display-items';
import types from './types';

const { LOADING, SUCCEEDED } = communicationStatus;

function* getStatesForChartAPIRequest() {
  const {
    dimensionsSelector,
    periodSelector,
    comparePeriodSelector,
  } = caSelectors;
  return {
    dimensions: yield select(dimensionsSelector),
    period: yield select(periodSelector),
    comparedPeriod: yield select(comparePeriodSelector),
    channel: yield select(settingsSelector.getTab),
    filters: yield select(filterSelector.getForApi),
    axis: yield select(DisplayItemsSelectors.getDisplayItemPriorityAxis),
  };
}

function* getStatesForTableAPIRequest() {
  const {
    dimensionsSelector,
    metricsSelector,
    periodSelector,
    comparePeriodSelector,
    sortsSelector,
    paginationSelector,
  } = caSelectors;
  const { conversionsSelector } = masterDataSelectors;
  return {
    dimensions: yield select(dimensionsSelector),
    period: yield select(periodSelector),
    comparedPeriod: yield select(comparePeriodSelector),
    metrics: yield select(metricsSelector),
    sorts: yield select(sortsSelector),
    pagination: yield select(paginationSelector),
    channel: yield select(settingsSelector.getTab),
    conversions: yield select(conversionsSelector),
    filters: yield select(filterSelector.getForApi),
    axis: yield select(DisplayItemsSelectors.getDisplayItemPriorityAxis),
  };
}

function* getChartReport() {
  const apiOptions = yield getStatesForChartAPIRequest();
  const {
    period,
    dimensions,
    channel,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (dimensions.length <= 0) return;
  yield put(actions.setChartStatus(LOADING));
  const res = yield sharedSagas.getChartReportByAPICall(
    {
      start: period.start,
      end: period.end,
      dimensions,
      channel: channel.toLowerCase(),
      filters,
      axis: priorityAxis,
    },
    summaryService.getChartData
  );

  const rawList = res.data.data.detail;

  // Prepare a dimension key for each object
  const list = rawList.map((elem) => ({
    ...elem,
    dimensionKey: summaryService.createDimensionKeyObject(elem, dimensions),
    dimensionKeyStr: createDimensionKeyString(elem, dimensions),
  }));

  const categories = chartService.buildCategoriesFromDimensionKey(list);
  const axis = yield select(caSelectors.chartAxisSelector);
  const selectedRows = yield select(caSelectors.selectedRowsSelector);
  const selectedCategories = categories.slice(0, DEFAULT_SELECTED_CATEGORIES);

  const displayData = chartService.buildDisplayData({
    list,
    axis,
    selectedCategories,
    selectedRows,
    visibleList: selectedCategories,
  });

  yield put(
    actions.updateChartData({
      list,
      selectedCategories,
      displayData,
    })
  );
  yield put(actions.setChartStatus(SUCCEEDED));
}

function* getComparedChartReport() {
  const apiOptions = yield getStatesForChartAPIRequest();
  const {
    period,
    comparedPeriod,
    dimensions,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (dimensions.length <= 0) return;
  yield put(actions.setChartStatus(LOADING));
  const [res, comparedRes] = yield all([
    call(summaryService.getChartData, {
      start: period.start,
      end: period.end,
      dimensions,
      channel: apiOptions.channel.toLowerCase(),
      filters,
      axis: priorityAxis,
    }),
    call(summaryService.getChartData, {
      start: comparedPeriod.start,
      end: comparedPeriod.end,
      dimensions,
      channel: apiOptions.channel.toLowerCase(),
      compared: true,
      filters,
      axis: priorityAxis,
    }),
  ]);

  const rawList = res.data.data.detail;

  // Prepare a dimension key for each object
  const list = rawList.map((elem) => ({
    ...elem,
    dimensionKey: summaryService.createDimensionKeyObject(elem, dimensions),
  }));

  const rawComparedList = comparedRes.data.data.detail;
  const comparedList = rawComparedList.map((elem) => ({
    ...elem,
    dimensionKey: summaryService.createDimensionKeyObject(elem, dimensions),
  }));

  const categories = chartService.buildCategoriesFromDimensionKey(list);

  const axis = yield select(caSelectors.chartAxisSelector);
  const selectedRows = yield select(caSelectors.selectedRowsSelector);
  const selectedCategories = categories.slice(0, DEFAULT_SELECTED_CATEGORIES);

  const displayData = chartService.buildComparedDisplayData({
    list,
    comparedList,
    axis,
    selectedRows,
    selectedCategories,
    visibleList: selectedCategories,
  });

  yield put(
    actions.updateComparedChartData({
      list,
      comparedList,
      selectedCategories,
      displayData,
    })
  );

  yield put(actions.setChartStatus(SUCCEEDED));
}

function createDisplayData(
  list,
  dimensions,
  metrics,
  conversions,
  sum,
  sorts,
  selectedRows,
  period,
  channel,
  items,
  settingItems,
  priorityAxis
) {
  // Verify selected items
  const dimensionsObj = {};
  dimensions.map((key) => {
    dimensionsObj[key] = true;
    return key;
  });
  const displayHeaders = tableService.buildDisplayHeaders({
    items,
    settingItems: { ...settingItems, ...dimensionsObj },
    conversions,
    sorts,
    priorityAxis,
  });

  const displayRows = tableService.buildDisplayRows({
    list,
    selectedRows,
    headers: displayHeaders,
  });

  const displaySum = tableService.buildDisplaySum({
    sumObject: sum,
    headers: displayHeaders,
    period,
  });

  return {
    displayRows,
    displayHeaders,
    displaySum,
  };
}

const updateDisplayData = ({
  list,
  selectedRows,
  displayHeaders,
  sum,
  period,
}) => {
  const displayRows = tableService.buildDisplayRows({
    list,
    selectedRows,
    headers: displayHeaders,
  });

  const displaySum = tableService.buildDisplaySum({
    sumObject: sum,
    headers: displayHeaders,
    period,
  });
  return {
    displayRows,
    displaySum,
  };
};

const updateComparedDisplayData = ({
  displayHeaders,
  period,
  comparedPeriod,
  sum,
  list,
  comparedList,
  comparedSum,
  selectedRows,
}) => {
  const displayRows = tableService.buildDisplayComparedRows({
    list,
    comparedList,
    selectedRows,
    headers: displayHeaders,
    period,
    comparedPeriod,
  });

  const displaySum = tableService.buildDisplayComparedSum({
    sumObject: sum,
    comparedSumObject: comparedSum,
    headers: displayHeaders,
    period,
    comparedPeriod,
  });

  return {
    displayRows,
    displaySum,
  };
};

const createComparedDisplayData = (
  list,
  comparedList,
  dimensions,
  metrics,
  conversions,
  sum,
  comparedSum,
  sorts,
  selectedRows,
  period,
  comparedPeriod,
  channel,
  items,
  settingItems,
  priorityAxis
) => {
  // Verify selected items
  const dimensionsObj = {};
  dimensions.map((key) => {
    dimensionsObj[key] = true;
    return key;
  });

  const displayHeaders = tableService.buildDisplayHeaders({
    compared: true,
    conversions,
    sorts,
    items,
    settingItems: { ...settingItems, ...dimensionsObj },
    priorityAxis,
  });

  const displayRows = tableService.buildDisplayComparedRows({
    list,
    comparedList,
    selectedRows,
    headers: displayHeaders,
    period,
    comparedPeriod,
  });

  const displaySum = tableService.buildDisplayComparedSum({
    sumObject: sum,
    headers: displayHeaders,
    period,
    comparedPeriod,
    comparedSumObject: comparedSum,
  });

  return {
    displayRows,
    displayHeaders,
    displaySum,
  };
};

function* getTableReportByAPICall({
  period,
  dimensions,
  metrics,
  sorts,
  pagination,
  channel,
  filters,
  axis,
}) {
  return yield sharedSagas.getTableReportByAPICall(
    {
      start: period.start,
      end: period.end,
      dimensions,
      metrics,
      sorts,
      pagination,
      channel: channel.toLowerCase(),
      filters,
      axis,
    },
    summaryService.getTableData
  );
}

function* initTableReport() {
  const apiOptions = yield getStatesForTableAPIRequest();
  const {
    dimensions,
    metrics,
    sorts,
    period,
    pagination,
    channel,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (apiOptions.dimensions.length <= 0) return;
  yield put(actions.setTableStatus(LOADING));

  if (!apiOptions.conversions || apiOptions.conversions.length <= 0) {
    yield put(masterDataActions.fetchConversions());
  }

  const res = yield getTableReportByAPICall({
    dimensions,
    metrics,
    sorts,
    period,
    pagination,
    channel,
    filters,
    axis: priorityAxis,
  });

  const { sum, rawList, count } = res;

  const list = rawList.map((item) => ({
    ...item,
    dimensionKey: summaryService.createDimensionKeyObject(item, dimensions),
    dimensionKeyStr: createDimensionKeyString(item, dimensions),
  }));

  const selectedRows = [];

  const conversions = yield select(caSelectors.conversionsSelector);

  const items = yield select(displayItemsSelectors.getUserPermittedItems);
  const settingItems = yield select(displayItemsSelectors.getSettings);
  const { displayRows, displayHeaders, displaySum } = createDisplayData(
    list,
    dimensions,
    metrics,
    conversions,
    sum,
    sorts,
    selectedRows,
    period,
    channel,
    items,
    settingItems,
    priorityAxis
  );

  yield put(
    actions.updateTableData({
      list,
      sum,
      displayRows,
      displayHeaders,
      displaySum,
      count,
      selectedRows,
    })
  );
  yield put(actions.setTableStatus(SUCCEEDED));
}

function* initComparedTableReport() {
  const apiOptions = yield getStatesForTableAPIRequest();
  const {
    period,
    comparedPeriod,
    dimensions,
    metrics,
    sorts,
    pagination,
    channel,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (dimensions.length <= 0) return;
  yield put(actions.setTableStatus(LOADING));

  if (!apiOptions.conversions || apiOptions.conversions.length <= 0) {
    yield put(masterDataActions.fetchConversions());
  }

  const [res, comparedRes] = yield all([
    call(summaryService.getTableData, {
      dimensions,
      metrics,
      start: period.start,
      end: period.end,
      sorts,
      pagination,
      channel: channel.toLowerCase(),
      filters,
      axis: priorityAxis,
    }),
    call(summaryService.getTableData, {
      dimensions,
      metrics,
      start: comparedPeriod.start,
      end: comparedPeriod.end,
      sorts,
      pagination,
      channel: channel.toLowerCase(),
      compared: true,
      filters,
      axis: priorityAxis,
    }),
  ]);

  const { sum, detail: rawList } = res.data.data;
  const { count } = res.data.metadata;
  const list = rawList.map((item) => ({
    ...item,
    dimensionKey: summaryService.createDimensionKeyObject(item, dimensions),
  }));

  const { sum: comparedSum, detail: rawComparedList } = comparedRes.data.data;
  const comparedList = rawComparedList.map((item) => ({
    ...item,
    dimensionKey: summaryService.createDimensionKeyObject(item, dimensions),
  }));

  const conversions = yield select(caSelectors.conversionsSelector);

  const selectedRows = [];

  const items = yield select(displayItemsSelectors.getUserPermittedItems);
  const settingItems = yield select(displayItemsSelectors.getSettings);
  const { displayRows, displayHeaders, displaySum } = createComparedDisplayData(
    list,
    comparedList,
    dimensions,
    metrics,
    conversions,
    sum,
    comparedSum,
    sorts,
    selectedRows,
    period,
    comparedPeriod,
    channel,
    items,
    settingItems,
    priorityAxis
  );

  yield put(
    actions.updateComparedTableData({
      list,
      comparedList,
      sum,
      comparedSum,
      displayRows,
      displayHeaders,
      displaySum,
      selectedRows,
      count,
    })
  );
  yield put(actions.setTableStatus(SUCCEEDED));
}

function* refreshTableDataNonCompared() {
  const apiOptions = yield getStatesForTableAPIRequest();
  const {
    period,
    dimensions,
    metrics,
    sorts,
    pagination,
    channel,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (dimensions.length <= 0) return;

  yield put(actions.setTableStatus(LOADING));

  const res = yield getTableReportByAPICall({
    pagination,
    period,
    dimensions,
    sorts,
    metrics,
    channel,
    filters,
    axis: priorityAxis,
  });
  const { sum, rawList, count } = res;
  const list = rawList.map((item) => ({
    ...item,
    dimensionKey: summaryService.createDimensionKeyObject(item, dimensions),
  }));
  const selectedRows = yield select(caSelectors.selectedRowsSelector);

  const displayHeaders = yield select(caSelectors.tableDisplayHeadersSelector);

  const { displayRows, displaySum } = updateDisplayData({
    period,
    list,
    selectedRows,
    displayHeaders,
    sum,
  });

  yield put(
    actions.updateTableData({
      list,
      sum,
      displayRows,
      displayHeaders,
      displaySum,
      count,
    })
  );
  yield put(actions.setTableStatus(SUCCEEDED));
}

function* refreshTableDataCompared() {
  const apiOptions = yield getStatesForTableAPIRequest();
  const {
    period,
    comparedPeriod,
    dimensions,
    metrics,
    sorts,
    pagination,
    channel,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (dimensions.length <= 0) return;

  yield put(actions.setTableStatus(LOADING));

  const [res, comparedRes] = yield all([
    call(summaryService.getTableData, {
      dimensions,
      metrics,
      start: period.start,
      end: period.end,
      sorts,
      pagination,
      channel: channel.toLowerCase(),
      filters,
      axis: priorityAxis,
    }),
    call(summaryService.getTableData, {
      dimensions,
      metrics,
      start: comparedPeriod.start,
      end: comparedPeriod.end,
      sorts,
      pagination,
      channel: channel.toLowerCase(),
      compared: true,
      filters,
      axis: priorityAxis,
    }),
  ]);

  const { sum, detail: rawList } = res.data.data;
  const { count } = res.data.metadata;
  const list = rawList.map((item) => ({
    ...item,
    dimensionKey: summaryService.createDimensionKeyObject(item, dimensions),
  }));

  const { sum: comparedSum, detail: rawComparedList } = comparedRes.data.data;
  const comparedList = rawComparedList.map((item) => ({
    ...item,
    dimensionKey: summaryService.createDimensionKeyObject(item, dimensions),
  }));

  const selectedRows = yield select(caSelectors.selectedRowsSelector);

  const displayHeaders = yield select(caSelectors.tableDisplayHeadersSelector);

  const { displayRows, displaySum } = updateComparedDisplayData({
    list,
    sum,
    selectedRows,
    displayHeaders,
    period,
    comparedPeriod,
    comparedList,
    comparedSum,
  });

  yield put(
    actions.updateComparedTableData({
      list,
      comparedList,
      sum,
      comparedSum,
      displayRows,
      displayHeaders,
      displaySum,
      count,
    })
  );

  yield put(actions.setTableStatus(SUCCEEDED));
}

function* refreshTableData() {
  const comparedPeriod = yield select(caSelectors.comparePeriodSelector);
  if (!comparedPeriod.enabled) {
    yield refreshTableDataNonCompared();
  } else {
    yield refreshTableDataCompared();
  }
}

// Bookmark
function* getBookmark() {
  const bookmark = yield call(summaryService.getBookmark);
  yield put(actions.applyBookmark(bookmark));
}

function* updateBookmark(action) {
  const request = action.payload;
  yield call(summaryService.updateBookmark, request);
}

function* updateShowChart(action) {
  const show = action.payload;
  yield call(summaryService.updateShowChart, show);
}

function* errorHandler(err) {
  const page = yield select(settingsSelector.getPage);
  if (page !== CATEGORY_ANALYZE) {
    logging.error('Category Analyze unmount error', err);
    return;
  }

  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];

  yield put(actions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

function* handleGetChartActions(action) {
  yield sharedSagas.waitingRedirect();

  const page = yield select(settingsSelector.getPage);
  if (page !== CATEGORY_ANALYZE) return;

  switch (action.type) {
    case types.GET_CHART_REPORT:
      yield getChartReport();
      break;
    case types.GET_COMPARED_CHART_REPORT:
      yield getComparedChartReport();
      break;
    default:
      break;
  }
}

function* handleInitTableActions(action) {
  yield sharedSagas.waitingRedirect();

  const page = yield select(settingsSelector.getPage);
  if (page !== CATEGORY_ANALYZE) return;

  switch (action.type) {
    case types.GET_TABLE_REPORT:
      yield initTableReport();
      break;
    case types.GET_COMPARED_TABLE_REPORT:
      yield initComparedTableReport();
      break;
    default:
      break;
  }
}

export default function* saga() {
  const {
    GET_CHART_REPORT,
    GET_COMPARED_CHART_REPORT,
    GET_TABLE_REPORT,
    GET_COMPARED_TABLE_REPORT,
    UPDATE_BOOKMARK,
    GET_BOOKMARK,
    UPDATE_SORT,
    SET_SHOW_CHART,
    REFRESH_TABLE_DATA,
  } = types;

  yield takeLatest(
    [GET_CHART_REPORT, GET_COMPARED_CHART_REPORT],
    sharedSagas.safe(
      errorHandler,
      sharedSagas.skipIfNotReady(handleGetChartActions)
    )
  );

  yield takeLatest(
    [GET_TABLE_REPORT, GET_COMPARED_TABLE_REPORT],
    sharedSagas.safe(
      errorHandler,
      sharedSagas.skipIfNotReady(handleInitTableActions)
    )
  );

  yield debounce(
    UPDATE_BOOKMARK_DEBOUNCE_LENGTH,
    UPDATE_BOOKMARK,
    sharedSagas.safe(errorHandler, updateBookmark)
  );
  yield takeLatest(GET_BOOKMARK, sharedSagas.safe(errorHandler, getBookmark));

  yield takeLatest(REFRESH_TABLE_DATA, refreshTableData);

  yield takeLatest(
    UPDATE_SORT,
    sharedSagas.safe(errorHandler, refreshTableData)
  );

  yield debounce(
    UPDATE_BOOKMARK_DEBOUNCE_LENGTH,
    SET_SHOW_CHART,
    updateShowChart
  );
}
