import helper from 'store/helper';
import { communicationStatus } from 'services/utils';

const { IDLE } = communicationStatus;

const initialState = {
  data: {
    contactDemand: {},
  },
  status: {
    contactDemand: IDLE,
  },
};

const ContactDemandManagementReducer = helper.createReducer(initialState, {});

export default ContactDemandManagementReducer;
