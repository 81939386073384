const { useEffect } = require('react');

const useClickOutside = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick, 1);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useClickOutside;
