import { isItemDisplayable } from 'domain/utils';
import * as domainUtils from 'domain/utils';
import { CHART_PATTERNS } from 'domain/category-analyze/consts';
import { resolve } from 'domain/permissions/permissionTypes';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import apiUtils from 'services/apiUtils';
import summaryService from 'services/category-analyze/summaryService';
import displayItemsSelectors from 'store/display-items/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import chartService from 'services/category-analyze/chartService';
import {
  DISPLAY_GROUP_AGGREGATION_AXIS,
  DISPLAY_GROUP_ITEM,
} from 'domain/settings/display-items';
import { TAB_AD } from 'store/settings/constant';
import settingsSelector from 'store/settings/selectors';
import filterSelector from 'store/filters/selectors';
import { getPermissions } from 'store/auth/selectors';

const chartListSelector = (state) =>
  state.cache.categoryAnalysis.data.chart.list;
const chartAxisSelector = (state) =>
  state.cache.categoryAnalysis.settings.chart.axis;
const selectedCategoriesSelector = (state) =>
  state.cache.categoryAnalysis.settings.chart.selectedCategories;
// const dimensionsSelector = displayItemsSelectors.getDimensionsSelector;
const dimensionsSelector = createSelector(
  [
    displayItemsSelectors.getUserPermittedItems,
    displayItemsSelectors.getSettings,
    displayItemsSelectors.getDisplayItemPriorityAxis,
    settingsSelector.getTab,
    settingsSelector.getPage,
  ],
  (items, settingItems, priorityAxis) => {
    const dimensions = Object.keys(items)
      .filter(
        (key) =>
          items[key].getGroup(priorityAxis) === DISPLAY_GROUP_AGGREGATION_AXIS
      ) // only select "dimensions"
      .filter((key) =>
        isItemDisplayable(items[key], settingItems[key], priorityAxis)
      )
      .sort((key1, key2) => items[key1].order - items[key2].order);

    // Switch all(select channel only) -> ad
    // get default display DISPLAY_GROUP_AGGREGATION_AXIS
    if (dimensions.length === 0) {
      return Object.keys(items)
        .filter(
          (key) =>
            items[key].getGroup(priorityAxis) === DISPLAY_GROUP_AGGREGATION_AXIS
        )
        .filter((key) => {
          return items[key].displayDefault;
        })
        .sort((key1, key2) => items[key1].order - items[key2].order);
    }
    return dimensions;
  }
);

const getDimensions = (state) => {
  return dimensionsSelector(state);
};
const periodSelector = (state) => state.commonState.CommonState.settings.period;
const comparePeriodSelector = (state) =>
  state.commonState.CommonState.settings.comparedPeriod;
const comparedPeriodEnabledSelector = (state) =>
  state.commonState.CommonState.settings.comparedPeriod.enabled;
const chartComparedListSelector = (state) =>
  state.cache.categoryAnalysis.data.chart.comparedList;
// const metricsSelector = displayItemsSelectors.getMetricsSelector;
const metricsSelector = createSelector(
  [
    displayItemsSelectors.getUserPermittedItems,
    displayItemsSelectors.getSettings,
    displayItemsSelectors.getDisplayItemPriorityAxis,
    settingsSelector.getTab,
    settingsSelector.getPage,
  ],
  (items, settingItems, priorityAxis) => {
    return Object.keys(items)
      .filter((key) => items[key].getGroup(priorityAxis) === DISPLAY_GROUP_ITEM) // only select "metrics"
      .filter((key) =>
        isItemDisplayable(items[key], settingItems[key], priorityAxis)
      )
      .sort((key1, key2) => items[key1].order - items[key2].order); // sort by order
  }
);
const getMetrics = (state) => {
  return metricsSelector(state);
};

const conversionsSelector = masterDataSelectors.conversionsForDisplaySelector;

const sortsSelector = (state) =>
  state.cache.categoryAnalysis.settings.table.sorts;
const getSortsForApiRequest = (state) => {
  const dimensions = dimensionsSelector(state);
  const metrics = metricsSelector(state);
  const sorts = sortsSelector(state);
  return {
    sort:
      apiUtils.buildSort(
        domainUtils.getValidSortFields(sorts, dimensions, metrics)
      ) || undefined,
  };
};

const tableListSelector = (state) =>
  state.cache.categoryAnalysis.data.table.list;
const comparedTableListSelector = (state) =>
  state.cache.categoryAnalysis.data.table.comparedList;
const selectedRowsSelector = (state) =>
  state.cache.categoryAnalysis.data.table.selectedRows;
const sumSelector = (state) => state.cache.categoryAnalysis.data.table.sum;
const comparedSumSelector = (state) =>
  state.cache.categoryAnalysis.data.table.comparedSum;
const bookmarkSelector = (state) =>
  state.cache.categoryAnalysis.settings.chart.bookmark;
const paginationSelector = (state) =>
  state.cache.categoryAnalysis.settings.table.pagination;
const middlePointSelector = (state) =>
  state.cache.categoryAnalysis.settings.chart.median;
const visibleListSelector = (state) =>
  state.cache.categoryAnalysis.settings.chart.visibleList;
const tableStatusSelector = (state) =>
  state.cache.categoryAnalysis.apiStatus.table.status;
const chartStatusSelector = (state) =>
  state.cache.categoryAnalysis.apiStatus.chart.status;
const chartInstanceSelector = (state) =>
  state.cache.categoryAnalysis.settings.chart.instance;

const showChartSelector = (state) =>
  state.cache.categoryAnalysis.settings.display.showChart;

const chartDisplayDataSelector = (state) =>
  state.cache.categoryAnalysis.displayData.chart.list;
const chartDisplayDataComparedSelector = (state) =>
  state.cache.categoryAnalysis.displayData.chart.compared;

const tableDisplayHeadersSelector = (state) =>
  state.cache.categoryAnalysis.displayData.table.headers;

const tableDisplaySumSelector = (state) =>
  state.cache.categoryAnalysis.displayData.table.sum;

const tableDisplayRowsSelector = (state) =>
  state.cache.categoryAnalysis.displayData.table.rows;
// Get chart series selector
const getChartSeriesSelector = createSelector(
  [
    chartListSelector,
    chartAxisSelector,
    selectedCategoriesSelector,
    dimensionsSelector,
    comparePeriodSelector,
    chartComparedListSelector,
  ],
  (
    chartList,
    chartAxis,
    selectedCategories,
    dimensions,
    comparedPeriod,
    chartComparedList
  ) => {
    return summaryService.apiListToSeries(chartList, {
      chartAxis,
      selectedCategories,
      dimensions,
      comparedPeriod,
      comparedList: chartComparedList,
    });
  }
);

const getChartOptionsSelector = createSelector(
  [
    chartDisplayDataSelector,
    chartAxisSelector,
    middlePointSelector,
    periodSelector,
    comparePeriodSelector,
    chartDisplayDataComparedSelector,
  ],
  (
    displayData,
    axis,
    middlePoint,
    period,
    comparedPeriod,
    displayDataCompared
  ) => {
    return chartService.buildChartOptions({
      displayData,
      axis,
      comparedPeriod,
      period,
      middlePoint,
      compared: displayDataCompared,
    });
  }
);

const getAvailableChartPatterns = createSelector(
  [getPermissions, settingsSelector.getTab],
  (userPermissions, tab) => {
    const displayItems =
      tab === TAB_AD
        ? DisplayItemsService.getCategoryAnalysisAd()
        : DisplayItemsService.getCategoryAnalysisAll();
    return CHART_PATTERNS.filter((chartPattern) => {
      const { x: xMetric, y: yMetric } = chartPattern;
      return [xMetric, yMetric].every((metric) => {
        const permissionSet = get(
          displayItems,
          `${metric}.permissionSet.${tab}`
        );
        if (!permissionSet) {
          return true;
        }
        return resolve(permissionSet, userPermissions);
      });
    });
  }
);

const csvDownloadEnabledSelector = createSelector(
  [
    settingsSelector.getLoadingByStatus([
      tableStatusSelector,
      displayItemsSelectors.getStatus,
    ]),
    paginationSelector,
  ],
  (tableLoading, pagination) => {
    return !tableLoading && pagination.totalRows > 0;
  }
);

const imageDownloadEnabledSelector = createSelector(
  [
    settingsSelector.getLoadingByStatus([
      chartStatusSelector,
      displayItemsSelectors.getStatus,
    ]),
    chartDisplayDataSelector,
    showChartSelector,
  ],
  (chartFetching, chartDisplayData, showChart) => {
    return chartDisplayData.length > 0 && !chartFetching && showChart;
  }
);


export default {
  chartListSelector,
  chartAxisSelector,
  selectedCategoriesSelector,
  dimensionsSelector,
  periodSelector,
  comparePeriodSelector,
  comparedPeriodEnabledSelector,
  chartComparedListSelector,
  metricsSelector,
  conversionsSelector,
  sortsSelector,
  getSortsForApiRequest,
  tableListSelector,
  comparedTableListSelector,
  selectedRowsSelector,
  sumSelector,
  comparedSumSelector,
  bookmarkSelector,
  paginationSelector,
  middlePointSelector,
  visibleListSelector,
  // getHeadersSelector,
  getChartSeriesSelector,
  tableStatusSelector,
  chartStatusSelector,
  chartInstanceSelector,
  chartDisplayDataComparedSelector,
  getChartOptionsSelector,
  chartDisplayDataSelector,
  tableDisplayHeadersSelector,
  tableDisplaySumSelector,
  tableDisplayRowsSelector,
  showChartSelector,
  filterSelector,
  currentPageSelector: settingsSelector.getPage,
  getDimensions,
  getMetrics,
  getAvailableChartPatterns,
  csvDownloadEnabledSelector,
  imageDownloadEnabledSelector,
};

export function useCategoryAnalyzeSelectors() {
  return {
    currentTab: useSelector(settingsSelector.getTab),
    chartList: useSelector(chartListSelector),
    chartAxis: useSelector(chartAxisSelector),
    selectedCategories: useSelector(selectedCategoriesSelector),
    dimensions: useSelector(dimensionsSelector),
    period: useSelector(periodSelector),
    comparePeriod: useSelector(comparePeriodSelector),
    comparedPeriodEnabled: useSelector(comparedPeriodEnabledSelector),
    chartComparedList: useSelector(chartComparedListSelector),
    metrics: useSelector(metricsSelector),
    conversions: useSelector(conversionsSelector),
    sorts: useSelector(sortsSelector),
    tableList: useSelector(tableListSelector),
    comparedTableList: useSelector(comparedTableListSelector),
    selectedRows: useSelector(selectedRowsSelector),
    sum: useSelector(sumSelector),
    comparedSum: useSelector(comparedSumSelector),
    bookmark: useSelector(bookmarkSelector),
    bookmarkLoaded: useSelector(bookmarkSelector).loaded,
    pagination: useSelector(paginationSelector),
    middlePoint: useSelector(middlePointSelector),
    visibleList: useSelector(visibleListSelector),
    // headers: useSelector(getHeadersSelector),
    chartSeries: useSelector(getChartSeriesSelector),
    tableStatus: useSelector(tableStatusSelector),
    chartStatus: useSelector(chartStatusSelector),
    chartInstance: useSelector(chartInstanceSelector),
    chartDisplayDataCompared: useSelector(chartDisplayDataComparedSelector),

    chartOptions: useSelector(getChartOptionsSelector),

    chartDisplayData: useSelector(chartDisplayDataSelector),
    tableDisplayHeaders: useSelector(tableDisplayHeadersSelector),
    tableDisplaySum: useSelector(tableDisplaySumSelector),
    tableDisplayRows: useSelector(tableDisplayRowsSelector),
    showChart: useSelector(showChartSelector),
    filters: useSelector(filterSelector.getApplicableSettings),
    currentPage: useSelector(settingsSelector.getPage),
    tableFetching: useSelector(
      settingsSelector.getLoadingByStatus([
        tableStatusSelector,
        displayItemsSelectors.getStatus,
      ])
    ),
    chartFetching: useSelector(
      settingsSelector.getLoadingByStatus([
        chartStatusSelector,
        displayItemsSelectors.getStatus,
      ])
    ),
    availableChartPatterns: useSelector(getAvailableChartPatterns),
  };
}
