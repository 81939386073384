import React, { useCallback, useEffect } from 'react';
import filterActions from 'store/filters/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import filterSelectors from 'store/filters/selectors';
import settingsSelector from 'store/settings/selectors';
import masterDataActions from 'store/master-data/actions';
import settingViewActions from 'store/customview/actions';
import useFilterData from 'services/custom-hooks/useFilterData';
import { getPermissions } from 'store/auth/selectors';
import FilterService from 'domain/FilterService';
import isEqual from 'lodash/isEqual';
import { LTV_SETTINGS_OTHER } from 'services/routes/constants';
import FilterForm from './FilterForm';

const LtvSettingFilterContainer = () => {
  const selectedFilters = useSelector(
    filterSelectors.getSettings,
    shallowEqual
  );

  const currentFilters = useSelector(filterSelectors.getFilters);
  const dispatch = useDispatch();

  const pageId = useSelector(settingsSelector.getPage, shallowEqual);
  const currentTab = useSelector(settingsSelector.getTab);

  const userPermissions = useSelector(getPermissions);

  const [filterableList, mergedMasterData] = useFilterData(pageId);

  const handleApplyFilter = useCallback(
    (filters) => {
      const mergedFilters = FilterService.getMergedObj(
        filterableList,
        currentFilters,
        filters
      );

      dispatch(filterActions.updateFilters(mergedFilters));
      dispatch(settingViewActions.applyIsChanged({ changed: true }));
    },
    [currentFilters, dispatch, filterableList]
  );
  useEffect(() => {
    // Handle case where values of disabled contracts still remain in session storage
    const contractFilteredValues = FilterService.filterNonContractValues(
      selectedFilters,
      userPermissions
    );
    if (!isEqual(contractFilteredValues, selectedFilters)) {
      handleApplyFilter(contractFilteredValues);
    }
  }, [handleApplyFilter, selectedFilters, userPermissions]);

  useEffect(() => {
    if (pageId === LTV_SETTINGS_OTHER) {
      dispatch(masterDataActions.fetchDisplay());
    }
  }, [dispatch, pageId]);

  return (
    <FilterForm
      masterData={mergedMasterData}
      filterableList={filterableList}
      selectedFilters={selectedFilters}
      applyFilter={handleApplyFilter}
      userPermissions={userPermissions}
      currentTab={currentTab}
    />
  );
};

export default LtvSettingFilterContainer;
