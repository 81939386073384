import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { func, string, bool } from 'prop-types';

import './action-icon.scss';

function ActionIcon(props) {
  const { icon, disabled, tooltip, onClick } = props;

  return (
    <OverlayTrigger
      trigger={disabled && tooltip ? ['hover', 'focus'] : ''}
      placement="bottom"
      overlay={
        <Tooltip variant="disabled" placement="bottom">
          {tooltip}
        </Tooltip>
      }
    >
      <span
        aria-hidden="true"
        className="action-icon"
        disabled={disabled}
        onClick={disabled ? () => {} : onClick}
      >
        <i className={icon} aria-hidden="true" />
      </span>
    </OverlayTrigger>
  );
}

ActionIcon.propTypes = {
  icon: string,
  disabled: bool,
  tooltip: string,
  onClick: func,
};

ActionIcon.defaultProps = {
  icon: 'far fa-pen',
  disabled: false,
  tooltip: '',
  onClick: () => {},
};

export default React.memo(ActionIcon);
