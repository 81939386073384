const MSG_NAME_EXIST = '{name}が重複しています。別名で保存してください';
const MSG_REGISTER_MAX = '最大{number}件登録することができます';
const MSG_NO_SPTAB =
  '{label}をスペース、タブ、改行のみで登録することはできません';
const MSG_NO_TAB = '{name}にタブは含めないで下さい';
const MSG_NO_LINE_BREAK = '{name}に改行は含めないで下さい';
const MSG_LENGTH_MAX = '{length}文字以内で入力してください';
const MSG_LENGTH_MIN = '{length}文字以上で入力してください';
const MSG_NUMBER_INTEGER = '{name}は半角整数の数字で入力してください';
const MSG_EMAIL = 'メールアドレスの形式が不正です。';
const MSG_NUMBER_MAX = '{name}は{number}以内の半角整数で入力してください';
const MSG_REQUIRED = '{label}を入力してください';
const MSG_RESERVED_KEYWORDS = '{label}の先頭に{reserved_words}は使用できません';
const MSG_VALID_ID =
  '{label}に、半角英数字・ハイフン・アンダーバー以外は使用できません';
const MSG_CONTAINS_CONTROL_CHARACTERS =
  '{label}に制御文字を含めて登録することはできません。制御文字を削除してください';
const MSG_NUMBER_IN_RANGE =
  '{label}は{min}以上{max}以下の半角整数となるよう修正してください';
const MSG_NUMBER_HALF_WIDTH = '半角数字で入力してください';
const MSG_INVALID_URL =
  '{label}に不正な情報が入力されています。入力内容をご確認ください';
const MSG_INVALID_LABEL = '{label}に許可されていないタグが含まれています';
const MSG_INVALID_URL_QUESTION_MARK =
  '{label}のパラメータを、「?」が1つになるように修正してください';
const MSG_INVALID_FILENAME =
  '{label}に、半角英数字・ハイフン・アンダーバー・ドット以外は使用できません';
const MSG_BETWEEN_PERIOD =
  '入力する値が、登録可能期間({period})に収まるよう設定してください';
const MSG_FILE_REQUIRED = '{label}ファイルがアップロードされていません';
const MSG_FILE_SIZE = '{label}の容量が{size}MBを超えています';
const MSG_MIN_FILE_SIZE =
  '添付ファイルのファイルサイズは1バイト以上のものを使用してください。';
const MSG_FILE_EXTENSION_IMAGE =
  '{label}で使用できる拡張子は「.{extension}」です';
const MSG_FILE_EXTENSION_CSV = 'CSVファイルをアップロードしてください';
const MSG_FILE_EXTENSION = '{label}で許可されている形式は、{extension}です。';
const MSG_FILE_ALLOW_EXTENSION =
  '添付ファイルで許可されている形式は、txt・csv・tsv・xls・xlsx・doc・docx・ppt・pptx・pdf・jpg・jpeg・gif・png・zip・lzhです。';
const MSG_NO_EQUAL = '{label}は{value}名称以外で入力してください';
const MSG_UNREGISTER_PARENT_AD_ID =
  'デバイス別計測の親広告IDとして使用されているため、削除できませんでした。<br/>親広告IDとそれに紐付く子広告IDをすべて同時に削除するか、親広告IDに紐付く子広告IDをすべて削除してから、再度削除を実行してください。';
const MSG_MAX_LINE = '1度に登録できる{label}は{max}件までです';
const MSG_MIN_LINE = '1度に登録できる{label}は{min}件からです';
const MSG_DUPLICATE = '登録された{label}の内容が一部重複しています';
const CSV_FILE_MAX_LINE_CHECK = 'CSVの登録上限は、{length} 行までです';
const MSG_ACCEPT = '{label}を正しく選択してください';
const IP_ADDRESS_CHECK =
  '上から {index}番目の{label}を正しく入力してください。';
const QUERY_PARAMS_FORMAT_CHECK =
  '{label}に使える文字は半角英数字と｢_｣｢-｣のみです。';
const DIRECTORY_INDEX_FORMAT_CHECK =
  '{label}に使える文字は半角英数小文字と｢.｣｢_｣｢-｣のみです。';
const URL_CHECK = '上から {index}番目の{label}を正しく入力してください。';
const ALERT_EMAIL_CHECK = '上から {index}番目の{label}の形式が不正です。';

// Error Code String
const CODE_MAX_LENGTH_CHECK = 'MAX_LENGTH_CHECK';
const CODE_NUM_RANGE_CHECK = 'NUM_RANGE_CHECK';
const CODE_FORMAT_DATE_ACCEPTED_CHECK = 'FORMAT_DATE_ACCEPTED_CHECK';
const CODE_MIN_DATE_CHECK = 'MIN_DATE_CHECK';
const CODE_MAX_DATE_CHECK = 'MAX_DATE_CHECK';
const CODE_TOTAL_NUM_RANGE_CHECK = 'TOTAL_NUM_RANGE_CHECK';
const CODE_DUPLICATE_CHECK = 'DUPLICATE_CHECK';
const CODE_FILE_HEADER_INVALID = 'FILE_HEADER_INVALID';
const CODE_FILE_MAX_LINE_CHECK = 'FILE_MAX_LINE_CHECK';
const CODE_FILE_CONTENT_VALID_CHECK = 'FILE_CONTENT_VALID_CHECK';
const CODE_ACCEPT_VALUE_CHECK = 'ACCEPT_VALUE_CHECK';
const CODE_FILE_INVALID = 'FILE_INVALID';

export default {
  MSG_NAME_EXIST,
  MSG_REGISTER_MAX,
  MSG_NO_SPTAB,
  MSG_NO_TAB,
  MSG_NO_LINE_BREAK,
  MSG_LENGTH_MAX,
  MSG_LENGTH_MIN,
  MSG_NUMBER_INTEGER,
  MSG_EMAIL,
  MSG_NUMBER_MAX,
  MSG_REQUIRED,
  MSG_RESERVED_KEYWORDS,
  MSG_VALID_ID,
  MSG_CONTAINS_CONTROL_CHARACTERS,
  MSG_NUMBER_IN_RANGE,
  MSG_NUMBER_HALF_WIDTH,
  MSG_INVALID_URL,
  MSG_INVALID_URL_QUESTION_MARK,
  MSG_INVALID_LABEL,
  MSG_INVALID_FILENAME,
  MSG_BETWEEN_PERIOD,
  MSG_FILE_REQUIRED,
  MSG_FILE_SIZE,
  MSG_MIN_FILE_SIZE,
  MSG_FILE_EXTENSION_IMAGE,
  MSG_FILE_EXTENSION_CSV,
  MSG_FILE_EXTENSION,
  MSG_FILE_ALLOW_EXTENSION,
  MSG_NO_EQUAL,
  MSG_UNREGISTER_PARENT_AD_ID,
  MSG_MAX_LINE,
  MSG_MIN_LINE,
  MSG_DUPLICATE,
  CSV_FILE_MAX_LINE_CHECK,
  MSG_ACCEPT,
  IP_ADDRESS_CHECK,
  QUERY_PARAMS_FORMAT_CHECK,
  DIRECTORY_INDEX_FORMAT_CHECK,
  URL_CHECK,
  ALERT_EMAIL_CHECK,

  CODE_MAX_LENGTH_CHECK,
  CODE_NUM_RANGE_CHECK,
  CODE_FORMAT_DATE_ACCEPTED_CHECK,
  CODE_MIN_DATE_CHECK,
  CODE_MAX_DATE_CHECK,
  CODE_TOTAL_NUM_RANGE_CHECK,
  CODE_DUPLICATE_CHECK,
  CODE_FILE_HEADER_INVALID,
  CODE_FILE_MAX_LINE_CHECK,
  CODE_FILE_CONTENT_VALID_CHECK,
  CODE_ACCEPT_VALUE_CHECK,
  CODE_FILE_INVALID,
};
