import React from 'react';
import { Pagination } from 'react-bootstrap';
import classNames from 'classnames';
import range from 'lodash/range';
import { func, number, string, bool } from 'prop-types';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';

function EbPagination({
  totalItems,
  itemsPerPage,
  changePage,
  currentPage,
  maxPagesToDisplay,
  pagesToShowEllipsis,
  note,
  visiblePageNum,
  variant,
}) {
  const totalPages = () => Math.ceil(totalItems / itemsPerPage) || 1;
  const getMin = () =>
    totalItems <= 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  const getMax = () =>
    totalItems <= 0 ? 0 : Math.min(currentPage * itemsPerPage, totalItems);
  const minPagesToDisplay = 1;
  const getPager = () => {
    const numPages = totalPages();
    if (numPages < minPagesToDisplay) {
      return [];
    }
    if (numPages <= maxPagesToDisplay) {
      return range(1, numPages + 1);
    }
    const remainingRight = numPages - currentPage;
    const remainingLeft = currentPage - 1;
    let leftPages;
    if (currentPage <= pagesToShowEllipsis) {
      leftPages = currentPage - 1;
    } else if (remainingLeft > pagesToShowEllipsis) {
      if (remainingRight < pagesToShowEllipsis) {
        leftPages = maxPagesToDisplay - 1 - remainingRight;
      } else {
        leftPages = pagesToShowEllipsis;
      }
    } else {
      leftPages = pagesToShowEllipsis;
    }
    return range(
      currentPage - leftPages,
      currentPage + maxPagesToDisplay - leftPages
    );
  };

  const formatNumber = (value) => new Intl.NumberFormat().format(+value);

  const classPagination = classNames({
    'd-flex': true,
    'align-items-center': true,
    'pagination-wrap': true,
    [`pagination-wrap--${variant}`]: !!variant,
  });

  return (
    <div className={classPagination}>
      <span className="total-page">
        {`計${formatNumber(totalItems)}件${
          totalItems
            ? `（${formatNumber(getMin())}～${formatNumber(getMax())}件）`
            : ''
        }`}
        {note}
      </span>
      {visiblePageNum && (
        <Pagination size="sm">
          {getPager().length > minPagesToDisplay && getPager()[0] > 1 && (
            <Pagination.Item onClick={() => changePage(1)} key="first-page">
              先頭へ
            </Pagination.Item>
          )}
          {getPager().length > minPagesToDisplay && getPager()[0] > 1 && (
            <Pagination.Item onClick={() => changePage(currentPage - 1)}>
              <i className="far fa-chevron-left" />
            </Pagination.Item>
          )}
          {getPager().length > minPagesToDisplay && getPager()[0] > 1 && (
            <Pagination.Ellipsis className="ellipsis" disabled />
          )}
          {getPager().map((pageNum) => (
            <Pagination.Item
              active={currentPage === pageNum}
              onClick={() => {
                if (currentPage === pageNum) {
                  return;
                }
                changePage(pageNum);
              }}
              key={pageNum}
            >
              {formatNumber(pageNum)}
            </Pagination.Item>
          ))}
          {getPager().length >= maxPagesToDisplay &&
            getPager()[maxPagesToDisplay - 1] < totalPages() && (
              <Pagination.Ellipsis className="ellipsis" disabled />
            )}
          {getPager().length >= maxPagesToDisplay &&
            getPager()[maxPagesToDisplay - 1] < totalPages() && (
              <Pagination.Item onClick={() => changePage(currentPage + 1)}>
                <i className="far fa-chevron-right" />
              </Pagination.Item>
            )}
        </Pagination>
      )}
    </div>
  );
}

EbPagination.propTypes = {
  totalItems: number,
  itemsPerPage: number,
  changePage: func,
  currentPage: number,
  maxPagesToDisplay: number,
  pagesToShowEllipsis: number,
  note: string,
  visiblePageNum: bool,
  variant: string,
};

EbPagination.defaultProps = {
  totalItems: 0,
  itemsPerPage: TABLE_ROWS_PER_PAGE,
  changePage: (pageNumber) => <EbPagination currentPage={pageNumber} />,
  currentPage: 1,
  maxPagesToDisplay: 9,
  pagesToShowEllipsis: 4,
  note: '',
  visiblePageNum: true,
  variant: '',
};

export default EbPagination;
