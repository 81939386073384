import React from 'react';
import { isEmpty, chain, isArray, isNaN, pick } from 'lodash';
import {
  CAPI_SETTING_NAME,
  MEDIUM_ID,
  PIXEL_ID,
  ACCESS_TOKEN,
  AUTHEN_WITH_MCC_ACCOUNT,
  CUSTOMER_ID,
  LOGIN_CUSTOMER_ID,
  REFRESH_TOKEN,
  LINE_TAG_ID,
  LINE_TAG_ACCESS_TOKEN,
  YAHOO_ACCOUNT_ID,
  YAHOO_YDN_CONV_IO,
  YAHOO_BASE_ACCOUNT_ID,
  PIXEL_CODE,
  EMAIL_NOTIFICATION,
  SEND_MAIL_USERS,
  CV_POINTS,
  SEND_WITH_PRIVATE_INFO,
  NOTIFICATION_CONTENT,
} from 'domain/fields';
import {
  STEP_SETTING_MEDIA,
  STEP_SETTING_CV_POINTS,
  MEDIUM_TITLE,
  EVENT_NAME_MAX_LENGTH,
  FACEBOOK,
  GOOGLE,
  GOOGLE_OFFLINE,
  LINE,
  YAHOO_SEARCH_AD,
  YAHOO_DISPLAY_AD,
  YAHOO_CONVERSION_API,
  TIKTOK,
  CREDENTIAL_OBJ,
  DELETE_CREDENTIAL_BY_MEDIUM,
  AUTHEN_FLAG,
  CLIENT_ID,
  YAHOO_OFF_CONV_NAME_MAX_LENGTH,
  YAHOO_YDN_CONV_LABEL_MAX_LENGTH,
  DEFAULT_YAHOO_ACCOUNT_ID,
  MAX_LIMIT_SEARCH,
} from 'domain/capi-setting/consts';
import {
  capiSettingRules,
  credentialRules,
} from 'services/validations/capiSettingRules';
import { getErrorMessageByCode } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';
import { formatDisplayEmailInfo } from 'services/data-export/dataExportService';
import PauseComponent from 'views/pages/capi-setting/components/PauseComponent';
import StatusComponent from 'views/pages/capi-setting/components/StatusComponent';

export const formatDataList = (data, onChangeStatus) => {
  return data.map((item) => ({
    ...item,
    medium_id: MEDIUM_TITLE[item.medium_id],
    pause_flag: (
      <PauseComponent
        onChangeStatus={onChangeStatus}
        id={item.id}
        defaultValue={item.pause_flag}
      />
    ),
    status: <StatusComponent status={item.status} />,
    isAllowSelectRow: true,
  }));
};

// CAPI Setting
/**
 * Validate field
 */
export const validateDataSettings = (values, labels, rules) => {
  if (isEmpty(values) || isEmpty(rules)) return {};

  return chain(values)
    .mapValues((value, field) => ({
      label: labels[field],
      value: value || null,
    }))
    .mapValues((value, field) => {
      if (isEmpty(rules[field])) return [];

      const rulesByField = [...rules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();
};

const validateCvPointsData = (mediumId = FACEBOOK, values, labels, rules) => {
  const { cv_points: cvPoints } = values;
  const cvPointLabel = 'CVポイント対応設定';
  const errors = {};
  const cvPointsData = Object.keys(cvPoints);

  if (
    cvPointsData.filter((key) => !isEmpty(cvPoints[key].event_name.trim()))
      .length === 0
  ) {
    errors[CV_POINTS] = `${cvPointLabel}を入力してください。`;
  }

  let eventNameMaxLength = EVENT_NAME_MAX_LENGTH;

  if ([YAHOO_SEARCH_AD, YAHOO_DISPLAY_AD].includes(mediumId)) {
    eventNameMaxLength = YAHOO_OFF_CONV_NAME_MAX_LENGTH;
  }

  if (mediumId === YAHOO_CONVERSION_API) {
    eventNameMaxLength = YAHOO_YDN_CONV_LABEL_MAX_LENGTH;
  }

  if (
    cvPointsData.filter(
      (key) => cvPoints[key].event_name.length > eventNameMaxLength
    ).length > 0
  ) {
    errors[CV_POINTS] = `${eventNameMaxLength}文字以内で入力してください`;
  }

  if (
    mediumId === LINE &&
    cvPointsData.filter(
      (key) =>
        !isEmpty(cvPoints[key].event_name.trim()) &&
        !/^[A-Za-z0-9-_]{1,20}$/.test(cvPoints[key].event_name)
    ).length > 0
  ) {
    errors[CV_POINTS] =
      '半角英数字・ハイフン・アンダーバーのみを使用して、20文字以内で入力してください';
  }

  if (
    [GOOGLE, GOOGLE_OFFLINE].includes(mediumId) &&
    cvPointsData.filter(
      (key) =>
        !isEmpty(cvPoints[key].event_name.trim()) &&
        !/^[0-9]{1,255}$/.test(cvPoints[key].event_name)
    ).length > 0
  ) {
    errors[CV_POINTS] = '半角数字で入力してください';
  }

  if (
    [YAHOO_SEARCH_AD, YAHOO_DISPLAY_AD].includes(mediumId) &&
    cvPointsData.filter(
      (key) =>
        !isEmpty(cvPoints[key].event_name.trim()) &&
        !/^[^/\\<>:*?"|#{}%&~@;]+$/.test(cvPoints[key].event_name)
    ).length > 0
  ) {
    errors[CV_POINTS] = `入力された文字は使用できません。`;
  }

  return errors;
};

const formatValueSetting = (mediumId = FACEBOOK, values = {}) => {
  if (
    ![
      GOOGLE,
      GOOGLE_OFFLINE,
      YAHOO_SEARCH_AD,
      YAHOO_DISPLAY_AD,
      YAHOO_CONVERSION_API,
    ].includes(mediumId)
  )
    return values;

  return Object.keys(values).reduce((obj, field) => {
    let formatValue = values[field];
    switch (field) {
      case CUSTOMER_ID:
      case YAHOO_ACCOUNT_ID:
        formatValue = values[field].trim();
        break;
      default:
        break;
    }

    return {
      ...obj,
      [field]: formatValue,
    };
  }, {});
};

/**
 * Validate CAPI Setting data
 * @param {object} data
 * @param {number} currentStep
 * @return object
 */
export const validateCapiSettingData = (
  mediumId = FACEBOOK,
  data,
  currentStep
) => {
  // Handle rules to validate data
  const ruleValidate = {
    ...capiSettingRules,
    ...credentialRules[mediumId],
  };

  const dataValidate = { ...data };

  // Only get fields of current step to validate data
  const { values, labels } = Object.keys(data).reduce(
    (acc, field) => {
      const item = { ...data[field] };
      const skipValidateField = currentStep !== item.step;

      if (skipValidateField) return acc;

      return {
        values: { ...acc.values, [field]: item.value },
        labels: { ...acc.labels, [field]: item.label },
      };
    },
    { values: {}, labels: {} }
  );

  let error = {};
  if (currentStep === STEP_SETTING_MEDIA) {
    const validateValues = formatValueSetting(mediumId, values);
    error = validateDataSettings(validateValues, labels, ruleValidate);
  }
  if (currentStep === STEP_SETTING_CV_POINTS) {
    error = validateCvPointsData(mediumId, values, labels, ruleValidate);
  }

  return Object.keys(error).reduce((acc, field) => {
    return { ...acc, [field]: { ...acc[field], error: error[field] } };
  }, dataValidate);
};

/**
 * Check data has errors
 * @param {object} data
 * @return object
 */
export const checkDataSettingError = (
  data,
  currentStep,
  isValidate = false
) => {
  return Object.keys(data).reduce(
    (acc, field) => {
      const item = data[field];
      if (currentStep !== item.step && isValidate) return acc;

      const hasError =
        acc.hasError || !isEmpty(item.error) || item.error === true;

      return {
        hasError,
        stepError: hasError
          ? Math.min(acc.stepError, item.step)
          : acc.stepError,
      };
    },
    { hasError: false, stepError: STEP_SETTING_CV_POINTS }
  );
};

/**
 * Format data when error
 */
export const getCapiSettingDataError = (form, errors, params = {}) => {
  if (isEmpty(errors) || !isArray(errors)) return form;

  return errors.reduce((acc, error) => {
    const [field, index, name] = error.field.split('.');
    const item = { ...acc[field] };

    if (isEmpty(item)) return acc;

    const { label } = item;
    const message = getErrorMessageByCode(error, messageError, {
      ...params,
      label,
    });

    switch (field) {
      default:
        item.error = message;
        break;
    }

    return { ...acc, [field]: item };
  }, form);
};

/**
 * Format CAPI Setting data
 * @return object
 */
export const formatCapiSettingData = (tagListData = [], dataField = {}) => {
  if (isEmpty(tagListData)) return dataField;

  const conversionData = tagListData.reduce((obj, tag) => {
    return {
      ...obj,
      [`conversionid_${tag.conversionid}`]: {
        cv_title: tag.cv_title,
        event_name: '',
        error: '',
      },
    };
  }, {});

  return {
    ...dataField,
    [CV_POINTS]: {
      ...dataField[CV_POINTS],
      value: conversionData,
    },
  };
};

const getValueField = ({ field, conversionData, fieldSettingValue, data }) => {
  if (field === CV_POINTS) {
    return conversionData;
  }

  if (field === NOTIFICATION_CONTENT) {
    const notificationContent = Object.keys(fieldSettingValue).reduce(
      (obj, key) => ({
        ...obj,
        [key]: {
          ...data.value[key],
          value: fieldSettingValue[key],
        },
      }),
      {}
    );
    return notificationContent;
  }
  return fieldSettingValue;
};

export const updateCapiSettingForm = (mediumId = FACEBOOK, dataField = {}) => {
  return {
    ...dataField,
    ...CREDENTIAL_OBJ[mediumId],
  };
};

/**
 * Format CAPI Detail Setting data
 * @return object
 */
export const formatCapiSettingDetailData = (
  tagListData = [],
  dataField = {},
  detailSetting = {}
) => {
  if (isEmpty(tagListData) || isEmpty(detailSetting)) {
    return updateCapiSettingForm(FACEBOOK, dataField);
  }

  const { [MEDIUM_ID]: mediumId, [CV_POINTS]: cvPoints } = detailSetting;
  dataField = updateCapiSettingForm(mediumId, dataField);

  const detailCvPoints = Object.keys(cvPoints).reduce((obj, key) => {
    return {
      ...obj,
      [`conversionid_${cvPoints[key].conversionid}`]: cvPoints[key].event_name,
    };
  }, {});

  const conversionData = tagListData.reduce((obj, tag) => {
    const { conversionid, cv_title: cvTitle } = tag;
    const conversionId = `conversionid_${conversionid}`;

    return {
      ...obj,
      [`conversionid_${conversionid}`]: {
        cv_title: cvTitle,
        event_name: detailCvPoints[conversionId] || '',
        error: '',
      },
    };
  }, {});

  return Object.keys(dataField).reduce((obj, field) => {
    return {
      ...obj,
      [field]: {
        ...dataField[field],
        value: getValueField({
          field,
          conversionData,
          fieldSettingValue: detailSetting[field],
          data: dataField[field],
        }),
      },
    };
  }, {});
};

const getCredentialSetting = (mediumId = FACEBOOK, data) => {
  let credentialSetting = {};
  if (mediumId === FACEBOOK) {
    const {
      [PIXEL_ID]: { value: pixelId },
      [ACCESS_TOKEN]: { value: accessToken },
    } = data;

    credentialSetting = {
      [PIXEL_ID]: parseInt(pixelId, 10),
      [ACCESS_TOKEN]: accessToken,
    };
  }

  if ([GOOGLE, GOOGLE_OFFLINE].includes(mediumId)) {
    const {
      [CUSTOMER_ID]: { value: customerId },
      [REFRESH_TOKEN]: { value: refreshToken },
      [AUTHEN_WITH_MCC_ACCOUNT]: { value: authenWithMCCAccount },
      [LOGIN_CUSTOMER_ID]: { value: loginCustomerId },
    } = data;

    credentialSetting = {
      [CUSTOMER_ID]: customerId,
      [REFRESH_TOKEN]: refreshToken,
      [CLIENT_ID]: process.env.REACT_APP_GOOGLE_ADS_CLIENT_ID,
    };

    if (authenWithMCCAccount) {
      credentialSetting = {
        ...credentialSetting,
        [LOGIN_CUSTOMER_ID]: loginCustomerId,
      };
    }
  }

  if (mediumId === LINE) {
    const {
      [LINE_TAG_ID]: { value: lineTagId },
      [LINE_TAG_ACCESS_TOKEN]: { value: lineTagAccessToken },
    } = data;

    credentialSetting = {
      [LINE_TAG_ID]: lineTagId,
      [LINE_TAG_ACCESS_TOKEN]: lineTagAccessToken,
    };
  }

  if ([YAHOO_SEARCH_AD, YAHOO_DISPLAY_AD].includes(mediumId)) {
    const {
      [YAHOO_ACCOUNT_ID]: { value: yahooAccountId },
      [REFRESH_TOKEN]: { value: refreshToken },
      [YAHOO_BASE_ACCOUNT_ID]: { value: yahooBaseAccountId },
    } = data;

    credentialSetting = {
      [YAHOO_ACCOUNT_ID]: yahooAccountId,
      [REFRESH_TOKEN]: refreshToken,
      [YAHOO_BASE_ACCOUNT_ID]: yahooBaseAccountId,
    };
  }

  if (YAHOO_CONVERSION_API === mediumId) {
    const {
      [YAHOO_YDN_CONV_IO]: { value: yahooYdnConvIo },
    } = data;

    credentialSetting = {
      [YAHOO_YDN_CONV_IO]: yahooYdnConvIo,
    };
  }

  if (mediumId === TIKTOK) {
    const {
      [PIXEL_CODE]: { value: pixelCode },
      [ACCESS_TOKEN]: { value: accessToken },
    } = data;

    credentialSetting = {
      [PIXEL_CODE]: pixelCode,
      [ACCESS_TOKEN]: accessToken,
    };
  }

  return credentialSetting;
};

export const getCapiSettingDataRequest = (
  data = {},
  hasUserEditPermission = false
) => {
  const {
    [CAPI_SETTING_NAME]: { value: capiName },
    [MEDIUM_ID]: { value: mediumId },
    [EMAIL_NOTIFICATION]: { value: sendMailFlag },
    [SEND_WITH_PRIVATE_INFO]: { value: sendWithPrivateInfo },
    [SEND_MAIL_USERS]: { value: sendMailUsers },
    [CV_POINTS]: { value: cvPoints },
  } = data;

  const cvPointData = Object.keys(cvPoints)
    .filter((key) => !isEmpty(cvPoints[key].event_name))
    .map((key) => {
      const item = cvPoints[key];
      const [, id] = key.split('_');
      const { event_name: eventName } = item;

      return {
        conversionid: parseInt(id, 10),
        event_name: eventName.trim(),
      };
    });

  const credentialSetting = getCredentialSetting(mediumId, data);

  const capiSetting = {
    [CAPI_SETTING_NAME]: capiName.trim(),
    [MEDIUM_ID]: mediumId,
    [EMAIL_NOTIFICATION]: sendMailFlag,
    [SEND_WITH_PRIVATE_INFO]: mediumId === GOOGLE ? true : sendWithPrivateInfo,
    [SEND_MAIL_USERS]:
      hasUserEditPermission && sendMailFlag ? sendMailUsers : [],
    [CV_POINTS]: cvPointData,
  };

  return {
    ...capiSetting,
    ...credentialSetting,
  };
};

export const handleErrorResponse = (action, isError, errors) => {
  let contentError = '';
  let isErrorCommon = false;
  const isReloadList = !isError;

  if (isError && !isEmpty(errors) && isArray(errors)) {
    const [error] = errors;
    isErrorCommon = [
      'SETTING_ID_EXIST_CHECK',
      'USER_PERMISSION_CHECK',
      'MAX_SETTING_ALLOW_REGIST_CHECK',
      'VALID_SEND_MAIL_USERS_CHECK',
    ].includes(error.code);

    if (isErrorCommon) {
      const label = 'CAPiCO設定';
      contentError = getErrorMessageByCode(error, messageError, { label });
    }
  }

  return {
    title: `データ更新に失敗しました`,
    content: contentError,
    isErrorCommon,
    isReloadList: isReloadList || isErrorCommon,
  };
};

export const formatUsersData = (users = []) => {
  if (isEmpty(users)) return [];

  const icon = <i className="fas fa-check" />;
  return users.map((user) => {
    const { agent_flag: agentFlag } = user;

    let email = user.email || '';
    const emails = email.split(',').map((item) => ({ email: item }));
    if (emails.length > 1) {
      email = formatDisplayEmailInfo(emails);
    }

    return {
      ...user,
      email,
      agent: agentFlag ? icon : null,
      rowId: user.user_id,
    };
  });
};

export const formatCapiSettingDetail = (data = {}) => {
  const { notification_content: notification = '{}', ...restData } = data;

  return {
    ...restData,
  };
};

export const isDisabledBtnNextStep = (
  mediumId = FACEBOOK,
  dataField = {},
  isAuthenticated = false,
  isCancelYahooAdAccount = false
) => {
  let isDisabled = false;
  if (mediumId === FACEBOOK) {
    const {
      [PIXEL_ID]: { value: pixelId },
      [ACCESS_TOKEN]: { value: accessToken },
    } = dataField;
    isDisabled = !pixelId || !accessToken;
  }

  if ([GOOGLE, GOOGLE_OFFLINE].includes(mediumId) && !isAuthenticated) {
    isDisabled = true;
  }

  if ([YAHOO_SEARCH_AD, YAHOO_DISPLAY_AD].includes(mediumId)) {
    if (isCancelYahooAdAccount) {
      return true;
    }

    if (!isAuthenticated) {
      isDisabled = true;
    } else {
      const {
        [YAHOO_ACCOUNT_ID]: { value: yahooAccountId },
      } = dataField;
      isDisabled = yahooAccountId === DEFAULT_YAHOO_ACCOUNT_ID;
    }
  }

  if (mediumId === LINE) {
    const {
      [LINE_TAG_ID]: { value: lineTagId },
      [LINE_TAG_ACCESS_TOKEN]: { value: lineTagAccessToken },
    } = dataField;
    isDisabled = !lineTagId || !lineTagAccessToken;
  }

  if (mediumId === YAHOO_CONVERSION_API) {
    const {
      [YAHOO_YDN_CONV_IO]: { value: yahooYdnConvIo },
    } = dataField;
    isDisabled = !yahooYdnConvIo;
  }

  if (mediumId === TIKTOK) {
    const {
      [PIXEL_CODE]: { value: pixelCode },
      [ACCESS_TOKEN]: { value: accessToken },
    } = dataField;
    isDisabled = !pixelCode || !accessToken;
  }

  return isDisabled;
};

export const getKeyCredentialDelete = (mediumId = FACEBOOK) => {
  return DELETE_CREDENTIAL_BY_MEDIUM[mediumId];
};

export const getAuthenticationData = (
  mediumId = FACEBOOK,
  dataValidated = {}
) => {
  let authenticateData = {};
  if (mediumId === FACEBOOK) {
    authenticateData = {
      pixelId: dataValidated.pixel_id.value,
      accessToken: dataValidated.access_token.value,
    };
  }

  if ([GOOGLE, GOOGLE_OFFLINE].includes(mediumId)) {
    authenticateData = {
      [MEDIUM_ID]: dataValidated.medium_id.value,
      [CUSTOMER_ID]: dataValidated.customer_id.value,
      [REFRESH_TOKEN]: dataValidated.refresh_token.value,
      [AUTHEN_FLAG]: true,
      [CLIENT_ID]: process.env.REACT_APP_GOOGLE_ADS_CLIENT_ID,
      [AUTHEN_WITH_MCC_ACCOUNT]: dataValidated.authen_with_mcc_account.value,
    };

    if (dataValidated.authen_with_mcc_account.value) {
      authenticateData = {
        ...authenticateData,
        [LOGIN_CUSTOMER_ID]: dataValidated.login_customer_id.value,
      };
    }
  }

  if (mediumId === LINE) {
    authenticateData = {
      [MEDIUM_ID]: dataValidated.medium_id.value,
      [LINE_TAG_ID]: dataValidated.line_tag_id.value,
      [LINE_TAG_ACCESS_TOKEN]: dataValidated.line_tag_access_token.value,
      [AUTHEN_FLAG]: true,
    };
  }

  if ([YAHOO_SEARCH_AD, YAHOO_DISPLAY_AD].includes(mediumId)) {
    authenticateData = {
      [MEDIUM_ID]: dataValidated.medium_id.value,
      [YAHOO_ACCOUNT_ID]: dataValidated.account_id.value,
      [REFRESH_TOKEN]: dataValidated.refresh_token.value,
      [AUTHEN_FLAG]: true,
      [CLIENT_ID]: process.env.REACT_APP_YAHOO_ADS_CLIENT_ID,
      [YAHOO_BASE_ACCOUNT_ID]: dataValidated.x_z_base_account_id.value,
    };
  }

  if (YAHOO_CONVERSION_API === mediumId) {
    authenticateData = {
      [MEDIUM_ID]: dataValidated.medium_id.value,
      [YAHOO_YDN_CONV_IO]: dataValidated.yahoo_ydn_conv_io.value,
      [AUTHEN_FLAG]: true,
    };
  }

  if (mediumId === TIKTOK) {
    authenticateData = {
      [MEDIUM_ID]: dataValidated.medium_id.value,
      [PIXEL_CODE]: dataValidated.pixel_code.value,
      [ACCESS_TOKEN]: dataValidated.access_token.value,
      [AUTHEN_FLAG]: true,
    };
  }

  return authenticateData;
};

export const getCredentialsByMediumId = (
  mediumId = FACEBOOK,
  credentialInfo = []
) => {
  let credentialObj = CREDENTIAL_OBJ[mediumId];
  if ([GOOGLE, GOOGLE_OFFLINE].includes(mediumId)) {
    credentialObj = pick(CREDENTIAL_OBJ[mediumId], [CUSTOMER_ID]);
  }

  if ([YAHOO_SEARCH_AD, YAHOO_DISPLAY_AD].includes(mediumId)) {
    credentialObj = pick(CREDENTIAL_OBJ[mediumId], credentialInfo);
  }

  if ([YAHOO_CONVERSION_API].includes(mediumId)) {
    credentialObj = pick(CREDENTIAL_OBJ[mediumId], [YAHOO_YDN_CONV_IO]);
  }

  return credentialObj;
};

export const filterSearchList = (
  list = [],
  searchText = '',
  limit = MAX_LIMIT_SEARCH
) => {
  if (searchText.length >= 1) {
    let count = 0;
    const filteredOptions = list.filter((item) => {
      const targetSearch = item.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
      if (count < limit && targetSearch) {
        count += 1;
        return true;
      }
      return false;
    });
    return filteredOptions;
  }
  return list.slice(0, limit);
};
