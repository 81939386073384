import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import TableStatisticsTemplate from 'views/templates/TableStatisticsTemplate';
import Spinner from 'views/atoms/loader/Spinner';
import {
  DEFAULT_COOKIE_PROGRAM_COLUMN_WIDTH,
  CHECK_COLUMNS,
  STEPS,
  MODALIDS,
  ACTION,
  REGISTER_COLUMNS,
} from 'domain/system-setting/consts';
import {
  MEASUREMENT_FQDN,
  PROGRAM_URL,
  UPDATE_DATE,
  FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS,
  FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE,
} from 'domain/fields';
import { IconCautionYellow } from 'views/atoms/icon/SvgIcon';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import useModalController from 'services/custom-hooks/useModalController';
import { SYSTEM_SETTING_COOKIE_PROGRAM } from 'services/routes/constants';
import { getHeader, validateTargetUrl } from 'services/system-setting/systemSettingCookieProgramService';
import { communicationStatus } from 'services/utils';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import systemSettingActions from 'store/system-setting/actions';
import systemSettingSelectors from 'store/system-setting/selectors';
import resizeTableSelectors from 'store/resize-table/selectors';
import resizeTableActions from 'store/resize-table/actions';
import CookieProgramCheckModal from 'views/pages/system-setting/components/CookieProgramCheckModal';
import CookieProgramSettingModal from 'views/pages/system-setting/components/CookieProgramSettingModal';
import CookieProgramDeleteModal from 'views/pages/system-setting/components/CookieProgramDeleteModal';

import TableControlTabCookieProgram from './TableControlTabCookieProgram';

import 'views/pages/system-setting/system-setting.scss';
import 'views/molecules/Tabs/tabs.scss';


const CookieProgram = () => {
  const dispatch = useDispatch();
  const { LOADING, UPDATING, FAILED} = communicationStatus;
// variables
  const [
    rawListData,
    statusData,
    currentTab,
    columnsWidth,
    tablesWidth,
    measurementFqdnList,
    pageStatus,
    checkingStatus,
    remain,
  ] = [
    useSelector(systemSettingSelectors.getCookieProgramSettings,shallowEqual),
    useSelector(systemSettingSelectors.getCookieProgramSettingsCheckStatus,shallowEqual),
    useSelector(systemSettingSelectors.getTab, shallowEqual),
    useSelector(resizeTableSelectors.getWidthColumnByMode, shallowEqual),
    useSelector(resizeTableSelectors.getWidthTableByMode, shallowEqual),
    useSelector(systemSettingSelectors.getMeasurementFqdn, shallowEqual),
    useSelector(systemSettingSelectors.getCookiePersisntenceStatus, shallowEqual),
    useSelector(systemSettingSelectors.getCookiePersisntenceCheckingStatus, shallowEqual),
    useSelector(systemSettingSelectors.getRemainCookieProgramSetting, shallowEqual),
  ];

  const isFailed = (pageStatus === FAILED);
  const isLoadingPage = (pageStatus === LOADING);
  const isUpdatingData = (pageStatus === UPDATING);
  const isChecking = (checkingStatus === UPDATING);

  const [selectedRows, setSelectedRows] = useState([]);
  const [isDisableCheck, setIsDisableCheck] = useState(true);
  const [step, setStep] = useState(1);
  const [checked, checkedClick] = useState(false);
  const [actionSettings, setActionSettings] = useState('');
  const [registerModalData, setRegisterModalData] = useState({});
  const [registerTargetId, setRegisterTargetId] = useState(null);
  const steps = STEPS.map((item) => ({
    ...item,
    active: item.step === step,
  }));
  const loadingStatus = useMemo(() => (
    (
      <>
        確認中
        <Spinner />
      </>
      )
  ),[]);

// top table create
  const [listData, setListData] = useState([]);   // dict型にして、特定の行だけ更新する機構の方が、レンダリング回数が少なくて済むかも？？
  useEffect(() => {
    const data = [];
    Object.values(rawListData).forEach((item) => {
      const measurementFqdn = item[MEASUREMENT_FQDN];
      let status = 'loading';
      let cookieValue =  '-';
      if (statusData !== undefined && measurementFqdn in statusData && statusData[measurementFqdn].status !== null) {
        cookieValue = statusData[measurementFqdn].cookieValue;
        status = statusData[measurementFqdn].status;
        if (status !== null && status !== 200) {
          status = (
          <>
            {status}
            <span style={{marginLeft:'3px'}}>
              <IconCautionYellow />
            </span>
          </>);
        }
      } else {
        status = loadingStatus;
        cookieValue = loadingStatus;
      }
      data.push({
        [MEASUREMENT_FQDN]: measurementFqdn,
        [PROGRAM_URL]: item[PROGRAM_URL],
        [UPDATE_DATE]: item[UPDATE_DATE],
        [FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS]: status,
        [FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE]: cookieValue,
        isDisabledEdit:!measurementFqdnList.includes(measurementFqdn),
      })
    });
    setListData(data);
  }, [dispatch, rawListData, statusData, setListData, measurementFqdnList]);

  const getList = useCallback(
    () => dispatch(systemSettingActions.getCookieProgramSettings()),
    [dispatch]
  );

  const getStatus = useCallback(
    () => {
      const checkListTargets = {};
      rawListData.forEach((item) => {
        if (item.program_url !== undefined && item.program_url !== null && item.program_url !== '') {
          checkListTargets[item.measurement_fqdn] = item.program_url;
        }
      });
      dispatch(systemSettingActions.checkCookieProgramSettings(checkListTargets));
    },
    [dispatch, systemSettingActions, rawListData]
  );

// select logic
  const handleCheckRow = useCallback(
    (checked, id) => {
      const findIndex = selectedRows.findIndex((item) => item === id);
      if (findIndex === -1) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows(selectedRows.filter((item) => item !== id));
      }
    },
    [selectedRows]
  );

  const addNewKeyForDataList = (list, rows) => {
    return list.map((item) => {
      return {
        ...item,
        rowId: item.measurement_fqdn,
        selected: rows.includes(item.measurement_fqdn),
      };
    });
  };

  const allItems = useMemo(() => listData.map((item) => item.measurement_fqdn), [
    listData,
  ]);

  const isCheckedAll =
  listData.length > 0 && listData.length === selectedRows.length;

  const newListData = useMemo(
    () => addNewKeyForDataList(listData, selectedRows),
    [listData, selectedRows]
  );

  const newHeaderData = useMemo(
    () =>
      getHeader(
        selectedRows,
        isCheckedAll,
      ),
    [selectedRows, isCheckedAll]
  );


  const handleCheckAll = useCallback(
    (checked) => {
      setSelectedRows(checked ? allItems : []);
    },
    [allItems]
  );


// modal logic
  const dispatchCheckURL = useCallback((targets) => {
    dispatch(systemSettingActions.checkCookieProgramSettings(targets));
  },[dispatch, systemSettingActions]);

  useEffect(() => {
    const values = {};
    let status = loadingStatus;
    let cookieValue =  loadingStatus;
    const settingKeys = rawListData.map((item) => (item.measurement_fqdn));
    const rawListItems = {};
    rawListData.forEach((item) => {rawListItems[item.measurement_fqdn]=item.program_url;});
    if (registerTargetId === null) {
      measurementFqdnList.forEach(ele => {
        if (settingKeys.includes(ele)) {
          return;
        }
        if (statusData !== undefined && ele in statusData) {
          status = statusData[ele]?.status;
          cookieValue = statusData[ele]?.cookieValue;
          if (status !== undefined && status !== null && status !== 200) {
            status = (
            <>
              {status}
              <span style={{marginLeft:'3px'}}>
                <IconCautionYellow />
              </span>
            </>);
          }
        }
        values[ele] = {
          measurement_fqdn: ele,
          error: (registerModalData[ele]?.error ?? '' ),
          program_url:(registerModalData[ele]?.program_url ?? rawListItems[registerTargetId] ),
          first_party_cookie_program_url_status:(status ?? loadingStatus),
          first_party_cookie_program_cookie_value:(cookieValue ?? loadingStatus),
        };
      });
    } else {
      status = statusData[registerTargetId]?.status;
      cookieValue = statusData[registerTargetId]?.cookieValue;
      if (status !== undefined && status !== null && status !== 200) {
        status = (
        <>
          {status}
          <span style={{marginLeft:'3px'}}>
            <IconCautionYellow />
          </span>
        </>);
      }
      values[registerTargetId] = {
        measurement_fqdn: registerTargetId,
        error: (registerModalData[registerTargetId]?.error ?? '' ),
        program_url: (registerModalData[registerTargetId]?.program_url ?? rawListItems[registerTargetId]),
        first_party_cookie_program_url_status:(status ?? loadingStatus),
        first_party_cookie_program_cookie_value:(statusData[registerTargetId]?.cookieValue ?? loadingStatus),
      };
    }
    setRegisterModalData(values);
  }, [measurementFqdnList, registerTargetId, rawListData, statusData]);

  useEffect(() => {
    setIsDisableCheck(
      !Object.values(registerModalData).some(
        values => values?.program_url !== undefined && values?.program_url !== ''
      )
    );
  }, [registerModalData, setIsDisableCheck]);

  const onCheckSubmit = useCallback(
    () => {
      const targets = {};
      rawListData.forEach((item) => {
        if (selectedRows.includes(item.measurement_fqdn)) {
          targets[item.measurement_fqdn] = item.program_url;
        }
      });
      dispatchCheckURL(targets);
    },[dispatch, dispatchCheckURL, selectedRows, rawListData]
  );

  const { openModal, closeModals, isOpen } = useModalController({ids: Object.values(MODALIDS),});

  const resetModalData = useCallback(() => {
    setRegisterModalData((prev)=> {
      Object.keys(prev).forEach((key) => {
        prev[key].program_url = '';
        prev[key].error = '';
      });
      return prev;
    });
  },[setRegisterModalData]);

  const reCheckStatus = useCallback((targetId) => {
    const targetArray = rawListData.filter((item) => (item.measurement_fqdn === targetId));
    const target = {};
    if (Array.isArray(targetArray) && targetArray.length) {
      target[targetArray[0].measurement_fqdn] = targetArray[0].program_url;
      dispatchCheckURL(target);
    }
  },[rawListData, dispatchCheckURL]);

  const onCancel = useCallback(
    () => {
      closeModals();
      setStep(1);
      resetModalData();
      setIsDisableCheck(true);
      setActionSettings((prev) => {
        if (prev === ACTION.EDIT) {
          reCheckStatus(registerTargetId);
        }
        return '';
      });
      setRegisterTargetId(null);
    },[closeModals, setStep, resetModalData, setActionSettings,setIsDisableCheck,setRegisterTargetId, reCheckStatus, registerTargetId]
  );

  const onCancelCheck = useCallback(
    () => {
      setStep(1);
      if (isOpen(MODALIDS.ADD)) {
        setActionSettings(ACTION.REGISTER);
      } else {
        setActionSettings(ACTION.EDIT);
      }
    },[closeModals, isOpen]
  );

  const onDelete = useCallback(
    () => {
      openModal(MODALIDS.DELETE);
      setActionSettings(ACTION.DELETE);
    },
    []
  );

  const onCancelDelete = useCallback(() => {
    closeModals();
    checkedClick(false);
  },[closeModals,checkedClick]);

  const getDeleteList = (newListData, selectRows) => {
    return newListData.filter((item) => {
      return selectRows.includes(item.measurement_fqdn);
    });
  }

  const onRegister = useCallback(() => {
    setRegisterTargetId(null);
    openModal(MODALIDS.ADD);
    setActionSettings(ACTION.REGISTER);
  },[setRegisterTargetId, openModal]);

  const handleChangeValue = useCallback(
    (name, value) => {
      setRegisterModalData((state) => ({
        ...state,
        [name]: {
          ...state[name],
          program_url: value
        },
      }));
    },[setRegisterModalData]
  );

  const onSubmitDelete = useCallback(
    () => {
      const targets = getDeleteList(newListData,selectedRows);
      const targetMeasurementFqdn = targets.map((item) =>(item.measurement_fqdn));
      dispatch(systemSettingActions.deleteCookieProgramSettings({measurement_fqdns:targetMeasurementFqdn}));
    },
    [dispatch, newListData, selectedRows]
  );

  const onSubmit = useCallback(() => {
    const rawTargets = Object.values(registerModalData).filter((item) => (item.program_url !== undefined && item.program_url !== ''));
    const targets = rawTargets.map((item) => ({measurement_fqdn:item.measurement_fqdn, program_url:item.program_url}));
    const records = {records:targets};
    dispatch(systemSettingActions.updateCookieProgramSettings(records));
    resetModalData();
    setRegisterTargetId(null);
  },[registerModalData,closeModals, dispatch,setRegisterModalData]);

  const onRegisterModalSubmit = useCallback(
    () => {
      const errorList = validateTargetUrl(registerModalData);
      const isError = Object.keys(errorList).some((key) => Array.isArray(errorList[key]) && errorList[key].length > 0);
      if (!isError) {
        setStep(2);
        setActionSettings(ACTION.CHECK);
        setRegisterModalData((prev)=> {
          Object.keys(prev).forEach((key) => {
            prev[key].error = '';
          });
          return prev;
        });
        return;
      }
      Object.keys(errorList).forEach((key) => {
        setRegisterModalData((prev) =>({
          ...prev,
          [key]:{
            ...prev[key],
            error:errorList[key],
          }
        }));
      });
    },
    [registerModalData, setRegisterModalData]
  );

  const handleEdit = useCallback((measurementFqdn) => {
      setRegisterTargetId(measurementFqdn);
      openModal(MODALIDS.EDIT)
      setActionSettings(ACTION.EDIT);
  },[openModal,setRegisterTargetId, setActionSettings]);


// table settings
  const countItemSelected = useMemo(
    () =>selectedRows.length,
    [selectedRows.length]
  );

  const totalItems = useMemo(
    () => (listData.length),[listData.length]
  );

  const CookieProgramTableControle = useMemo(() => {
    return (
      <TableControlTabCookieProgram
        count={countItemSelected}
        totalItems={totalItems}
        remain={remain}
        onRegister={onRegister}
        onDelete={onDelete}
        handleCheckURL={onCheckSubmit}
      />
    );
  }, [
    onRegister,
    onDelete,
    onCheckSubmit,
    remain,
    countItemSelected,
    totalItems
  ]);

  const onResizeColumn = useCallback(
    (column, width) => {
      dispatch(
        resizeTableActions.updateColumnWidth(SYSTEM_SETTING_COOKIE_PROGRAM, column, width)
      );
    },
    [dispatch]
  );

  const onResizeTable = useCallback(
    (freezeWidth) => {
      dispatch(resizeTableActions.updateTableWidth(SYSTEM_SETTING_COOKIE_PROGRAM, freezeWidth));
    },
    [dispatch]
  );


// useEffects
  useEffect(() => {
    getList();
  }, [getList, currentTab]);

  useEffect(() => {
    getStatus();
  },[getStatus, rawListData]);

  useEffect(() =>{
    dispatch(
      resizeTableActions.updateColumnWidthMulitiple(
        SYSTEM_SETTING_COOKIE_PROGRAM,
        DEFAULT_COOKIE_PROGRAM_COLUMN_WIDTH
      )
    );
    onResizeTable(1040);
  },[dispatch, onResizeTable, resizeTableActions]
  );

  useEffect(() => {
    if (actionSettings === ACTION.CHECK) {
      const targets = {};
      Object.values(registerModalData).forEach((item) => {
        if (item.program_url !== '') {
          targets[item.measurement_fqdn] = item.program_url;
        }
      });
      dispatchCheckURL(targets);
    }
  },[dispatch, actionSettings]);

  useEffect(() => {
    if (!isLoadingPage) {
      closeModals();
      checkedClick(false);
      setSelectedRows([]);
      resetModalData();
      setStep(1);
    }
  },[isLoadingPage]);

  useEffect(() =>{
    if(isFailed){
      onCancel();
    }
  },[isFailed]);

  const CookieProgramTableStatisticsTemplate = useMemo(() => {
    // FIXME I used this template because I referred to ad_management
    const tableClass = newListData.length >0 ? 'freeze-table--settings' : 'freeze-table--settings noscroller-list';
    return (
      <TableStatisticsTemplate
        screenId='system-setting/cookie-program'
        screenName='cookie_program_name'
        isLoading={isLoadingPage}
        isLoadingTable={isLoadingPage}
        headerData={newHeaderData}
        listData={newListData}
        styles={{
          container: 'cookie_program__list',
          secondContainer: 'clearfix',
          pagination: 'visuallyHidden',
          table: tableClass,
        }}
        handleEdit={handleEdit}
        columnsWidth={columnsWidth}
        tablesWidth={tablesWidth}
        onResizeColumn={onResizeColumn}
        onResizeTable={onResizeTable}
        handleCheckRow={handleCheckRow}
        handleCheckAll={handleCheckAll}
        tableControl={CookieProgramTableControle}
        // The following props are unnecessary, so modify the implementation without templates
        handleChangePage={()=>{}}
        handleSort={()=>{}}
      />
    );
  },[
    isLoadingPage,
    statusData,
    rawListData,
    newHeaderData,
    newListData,
    handleEdit,
    columnsWidth,
    tablesWidth,
    onResizeColumn,
    onResizeTable,
    handleCheckRow,
    handleCheckAll,
    CookieProgramTableControle,
  ]);

  return (
    <DataSyncLoader isLoading={isLoadingPage}>
      <div className="cookie-program">
      {(isOpen(MODALIDS.ADD) || isOpen(MODALIDS.EDIT)) && (
        <>
        {step === 1 ? (
          <CookieProgramSettingModal
            execType={actionSettings}
            closeModals={closeModals}
            steps={steps}
            handleChangeValue={handleChangeValue}
            registerColumns={REGISTER_COLUMNS}
            registerModalData={registerModalData}
            onCancel={onCancel}
            isDisableCheck={isDisableCheck}
            onRegisterModalSubmit={onRegisterModalSubmit}
          />
          ) : (
          <CookieProgramCheckModal
            isAdd={isOpen(MODALIDS.ADD)}
            steps={steps}
            checkColumns={CHECK_COLUMNS}
            checkList={Object.values(registerModalData).filter((item) => (item.program_url !== undefined && item.program_url !== ''))}
            onCancel={onCancelCheck}
            onSubmit={onSubmit}
            isLoading={isUpdatingData}
            isChecking={isChecking}
          />
        )}
        </>
      )}
      {isOpen(MODALIDS.DELETE) && (
        <CookieProgramDeleteModal
          getDeleteList={getDeleteList}
          newListData={newListData}
          selectedRows={selectedRows}
          checked={checked}
          checkedClick={checkedClick}
          onCancelDelete={onCancelDelete}
          onSubmitDelete={onSubmitDelete}
          isLoading={isUpdatingData}
        />
      )}
        {CookieProgramTableStatisticsTemplate}
      </div>
    </DataSyncLoader>
  );
};

export default CookieProgram;
