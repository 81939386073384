import helper from 'store/helper';
import { MASTER_DATA_TYPE } from 'domain/consts';
import types from './types';

const fetchConversions = () => helper.createAction(types.FETCH_CONVERSIONS);

const updateConversions = (conversions) =>
  helper.createAction(types.UPDATE_CONVERSIONS, conversions);

const updateExpires = (expires) =>
  helper.createAction(types.UPDATE_EXPIRES, expires);

const requestStart = () => helper.createAction(types.REQUEST_START);
const requestFailed = (error) =>
  helper.createAction(types.REQUEST_FAILED, error);

const fetchDisplaySucceeded = (params) =>
  helper.createAction(types.FETCH_DISPLAY_SUCCEEDED, params);
const fetchDisplay = (params) =>
  helper.createAction(types.FETCH_DISPLAY, params);

const fetchCvSucceeded = (params) =>
  helper.createAction(types.FETCH_CV_SUCCEEDED, params);
const fetchCv = (params) => helper.createAction(types.FETCH_CV, params);

const fetchMediaSucceeded = (params) =>
  helper.createAction(types.FETCH_MEDIA_SUCCEEDED, params);
const fetchMedia = (params) => helper.createAction(types.FETCH_MEDIA, params);

const fetchUser = () => helper.createAction(types.FETCH_USER);
const fetchUserSucceeded = (params) =>
  helper.createAction(types.FETCH_USER_SUCCEEDED, params);

const fetchAdGroup1Succeeded = (params) =>
  helper.createAction(types.FETCH_AD_GROUP1_SUCCEEDED, params);
const fetchAdGroup1 = (params) =>
  helper.createAction(types.FETCH_AD_GROUP1, params);

const fetchAdGroup2Succeeded = (params) =>
  helper.createAction(types.FETCH_AD_GROUP2_SUCCEEDED, params);
const fetchAdGroup2 = (params) =>
  helper.createAction(types.FETCH_AD_GROUP2, params);

const fetchMediaSideCampaignSucceeded = (params) =>
  helper.createAction(types.FETCH_MEDIA_SIDE_CAMPAIGN_SUCCEEDED, params);
const fetchMediaSideCampaign = (params) =>
  helper.createAction(types.FETCH_MEDIA_SIDE_CAMPAIGN, params);

const fetchMediaSideGroupSucceeded = (params) =>
  helper.createAction(types.FETCH_MEDIA_SIDE_GROUP_SUCCEEDED, params);
const fetchMediaSideGroup = (params) =>
  helper.createAction(types.FETCH_MEDIA_SIDE_GROUP, params);

const fetchMediaAccountSucceeded = (params) =>
  helper.createAction(types.FETCH_MEDIA_ACCOUNT_SUCCEEDED, params);
const fetchMediaAccountGroup = (params) =>
  helper.createAction(types.FETCH_MEDIA_ACCOUNT, params);

const fetchAgency = () => helper.createAction(types.FETCH_AGENCY);
const fetchAgencySucceeded = (params) =>
  helper.createAction(types.FETCH_AGENCY_SUCCEEDED, params);

const fetchContentCategory = () =>
  helper.createAction(types.FETCH_CONTENT_CATEGORY);
const fetchContentCategorySucceeded = (params) =>
  helper.createAction(types.FETCH_CONTENT_CATEGORY_SUCCEEDED, params);

const searchSucceeded = (params) =>
  helper.createAction(types.SEARCH_SUCCEEDED, params);
const searchCv = (params) => helper.createAction(types.SEARCH_CV, params);
const searchMedia = (params) => helper.createAction(types.SEARCH_MEDIA, params);
const searchAdGroup1 = (params) =>
  helper.createAction(types.SEARCH_AD_GROUP1, params);
const searchAdGroup2 = (params) =>
  helper.createAction(types.SEARCH_AD_GROUP2, params);
const searchMediaSideCampaign = (params) =>
  helper.createAction(types.SEARCH_MEDIA_SIDE_CAMPAIGN, params);
const searchMediaSideGroup = (params) =>
  helper.createAction(types.SEARCH_MEDIA_SIDE_GROUP, params);
const searchMediaAccount = (params) =>
  helper.createAction(types.SEARCH_MEDIA_ACCOUNT, params);

const searchAgency = (params) =>
  helper.createAction(types.SEARCH_AGENCY, params);
const searchContentCategory = (params) =>
  helper.createAction(types.SEARCH_CONTENT_CATEGORY, params);

const updateMasterData = (params) =>
  helper.createAction(types.UPDATE_MASTERDATA, params);
const updateMasterDataSucceeded = (params) =>
  helper.createAction(types.UPDATE_MASTERDATA_SUCCEEDED, params);

const fetchAll = (params) =>
  helper.createAction(types.FETCH_ALL, {
    type: MASTER_DATA_TYPE.REPORT,
    ...params,
  });
const fetchAllForAdManagement = (params) =>
  helper.createAction(types.FETCH_ALL_FOR_AD_MANAGEMENT, params);
const fetchAllForSettings = (pageId) =>
  helper.createAction(types.FETCH_ALL_FOR_SETTINGS, { pageId });
const fetchAllSucceeded = (params) =>
  helper.createAction(types.FETCH_ALL_SUCCEEDED, params);

const setType = (type) => helper.createAction(types.SET_TYPE, { type });

const fetchMediaBySettingMail = (params) =>
  helper.createAction(types.FETCH_MEDIA_BY_SETTING_MAIL, params);

export default {
  fetchConversions,
  updateConversions,
  updateExpires,
  requestStart,
  requestFailed,
  fetchDisplaySucceeded,
  fetchDisplay,
  fetchCvSucceeded,
  fetchCv,
  fetchMediaSucceeded,
  fetchMedia,
  fetchAgency,
  fetchAgencySucceeded,
  fetchAdGroup1Succeeded,
  fetchAdGroup1,
  fetchAdGroup2Succeeded,
  fetchAdGroup2,
  fetchMediaSideCampaignSucceeded,
  fetchMediaSideCampaign,
  fetchMediaSideGroupSucceeded,
  fetchMediaSideGroup,
  fetchMediaAccountGroup,
  fetchMediaAccountSucceeded,
  fetchContentCategory,
  fetchContentCategorySucceeded,
  searchSucceeded,
  searchCv,
  searchMedia,
  searchAdGroup1,
  searchAdGroup2,
  searchMediaSideCampaign,
  searchMediaSideGroup,
  searchMediaAccount,
  searchAgency,
  searchContentCategory,
  updateMasterData,
  updateMasterDataSucceeded,
  fetchAll,
  fetchAllForAdManagement,
  fetchAllForSettings,
  fetchAllSucceeded,
  setType,
  fetchMediaBySettingMail,
  fetchUser,
  fetchUserSucceeded,
};
