import React, { useRef } from 'react';
import { arrayOf, string, func } from 'prop-types';
import {
  GridTable,
  GridTableItem,
  GridTableHeaderWrapper,
  GridTableBodyWrapper,
  GridTableHeader,
  GridTableRow,
  GridTableSeparator,
} from 'views/organism/GridTable';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';

const AlertAuthSetting = (props) => {
  const { users, selectedAuthIds, error, onChange, header } = props;
  const ref = useRef();

  return (
    <ErrorTooltipWrapper isError={!!error} errorMess={error} ref={ref}>
      <div className="measurement-setting__content mb-10">
        <GridTable
          isApplyTableScrolling
          isTableCenter
          maxHeight={261}
          header={header}
          rows={users}
          rowsSelected={selectedAuthIds}
          onSelectRow={onChange}
        >
          <GridTableItem isFreeze>
            <GridTableHeaderWrapper>
              <GridTableHeader />
            </GridTableHeaderWrapper>
            <GridTableBodyWrapper>
              <GridTableRow />
            </GridTableBodyWrapper>
          </GridTableItem>
          <GridTableSeparator />
          <GridTableItem>
            <GridTableHeaderWrapper>
              <GridTableHeader />
            </GridTableHeaderWrapper>
            <GridTableBodyWrapper>
              <GridTableRow />
            </GridTableBodyWrapper>
          </GridTableItem>
        </GridTable>
      </div>
    </ErrorTooltipWrapper>
  );
};

AlertAuthSetting.propTypes = {
  users: arrayOf({}),
  selectedAuthIds: arrayOf(string),
  error: string,
  onChange: func,
  header: arrayOf({}),
};

AlertAuthSetting.defaultProps = {
  users: [],
  selectedAuthIds: [],
  error: '',
  onChange: () => {},
  header: [],
};

export default React.memo(AlertAuthSetting);
