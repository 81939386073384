import React, { useState, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import { Button, Form } from 'react-bootstrap';

import EbisDropdown from 'views/atoms/dropdown/EbisDropdown';
import FormGroup from 'views/molecules/FormGroup';

import {
  MODE_OPTIONS_PV,
  MODE_OPTIONS_CV,
  TAGS,
  MODE_OPTIONS_CV_CAPI_SINGLE,
} from 'domain/tag-management/consts';
import { APP_HELP_TAG_MANAGEMENT_MANUAL_VARIOUS_TAG } from 'services/routes/constants';

const VariousTag = ({
  isPvTag,
  isCapiSingle,
  isUsingGtm,
  tagOption,
  tagText,
  tagCommon,
  handleChangeMode,
  handleDownloadTag,
}) => {
  const { MOBILE } = TAGS;
  const modeOptionCv = isCapiSingle
    ? MODE_OPTIONS_CV_CAPI_SINGLE
    : MODE_OPTIONS_CV;
  const variousList = isPvTag ? MODE_OPTIONS_PV : modeOptionCv;
  const variousSelected = variousList.find((item) => item.key === tagOption);

  const [valueTagText, setValueTagText] = useState(tagText);
  const [valueTagCommon, setValueTagCommon] = useState(tagCommon);

  const handleFocus = (event) => event.target.select();

  useEffect(() => {
    setValueTagText(tagText);
    setValueTagCommon(tagCommon);
  }, [tagText, tagCommon]);

  return (
    <div className="tag-various">
      <div>
        <EbisDropdown
          optionObject={variousList}
          defaultVal={tagOption}
          selectCallback={handleChangeMode}
        />

        <Button
          variant="secondary"
          size="sm"
          className="tag-various__button"
          onClick={handleDownloadTag}
        >
          <span className="icon-fontawesome ">
            <i className="far fa-arrow-to-bottom" aria-hidden="true" />
          </span>
          <div>テキストファイル</div>
        </Button>
        <div className="tag-various__container">
          <FormGroup
            label={`${isPvTag ? 'PV' : 'CV'}ページ用タグ`}
            note={
              <>
                ※タグの設定については
                <a
                  href={
                    variousSelected.url ||
                    APP_HELP_TAG_MANAGEMENT_MANUAL_VARIOUS_TAG
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
                をご確認ください
              </>
            }
            tooltip="属性を取得する場合、詳細な実装方法はサイトの仕様によって異なりますので、サイト制作のご担当者様にご確認の上実装ください。"
          />
          <Form.Control
            id="tagText"
            as="textarea"
            className="tag-various__content"
            rows="4"
            value={valueTagText}
            defaultValue={tagText}
            onFocus={handleFocus}
            onChange={(e) => setValueTagText(e.target.value)}
          />
        </div>
        {tagOption !== MOBILE && (
          <div>
            <FormGroup
              label={`すべての計測対象ページ用共通タグ${
                isPvTag ? '' : '（CVページを除く）'
              }`}
              note={
                <>
                  {isUsingGtm && (
                    <>
                      ※CVページでは発火しないようなトリガーの設定をすることを推奨します
                      <br />
                    </>
                  )}
                  ※同一ページですでに共通タグが設置されている場合は不要です
                </>
              }
            />
            <Form.Control
              as="textarea"
              className="tag-various__content"
              rows="4"
              value={valueTagCommon}
              defaultValue={tagCommon}
              onFocus={handleFocus}
              onChange={(e) => setValueTagCommon(e.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

VariousTag.propTypes = {
  tagOption: string.isRequired,
  handleChangeMode: func.isRequired,
  handleDownloadTag: func.isRequired,
  tagText: string.isRequired,
  tagCommon: string.isRequired,
  isPvTag: bool.isRequired,
  isUsingGtm: bool.isRequired,
  isCapiSingle: bool.isRequired
};

export default VariousTag;
