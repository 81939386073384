import types from 'store/cv-attribute/types';
import helper from 'store/helper';

const requestStart = () => helper.createAction(types.REQUEST_START);
const requestFailed = (error, scope = null) =>
  helper.createAction(types.REQUEST_FAILED, error, scope);

const fetchReport = () => helper.createAction(types.FETCH_REPORT);
const fetchReportSucceeded = (data) =>
  helper.createAction(types.FETCH_REPORT_SUCCEEDED, data);

const updatePage = (page) => helper.createAction(types.UPDATE_PAGE, { page });
const updateSort = (field, direction) =>
  helper.createAction(types.UPDATE_SORT, { field, direction });

export default {
  requestStart,
  requestFailed,

  fetchReport,
  fetchReportSucceeded,

  updatePage,
  updateSort,
};
