import { useSelector, shallowEqual } from 'react-redux';
import {
  SETTING_AGENCY_VIEW,
  SETTING_AGENCY_EDIT,
} from 'domain/permissions/contractPermissions';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import { getAgentFlag, getAgencyName } from 'store/auth/selectors';

const useCheckPermissionAgency = () => {
  const { checkPermissions } = useCheckPermissions();
  const isAgentUser = useSelector(getAgentFlag, shallowEqual);
  const agencyName = useSelector(getAgencyName, shallowEqual);

  return {
    agencyName, // Agency
    isAgentUser, // User agent
    hasContractAgent: checkPermissions('anyOf', [SETTING_AGENCY_VIEW]), // User normal
    allowedEditAgency: checkPermissions('anyOf', [SETTING_AGENCY_EDIT]), // User normal
  };
};

export default useCheckPermissionAgency;
