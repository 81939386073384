import * as DisplayItems from './display-items';

const SettingDisplayItems = {
  groupList: {
    [DisplayItems.PERIOD]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS, // from period-analyze
    // [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.CATEGORY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AD_GROUP1]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AD_GROUP2]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AGENCY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.SYNC_CATEGORY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.MEDIA_SIDE_GROUP]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.MEDIA_SIDE_AD_NAME]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS, // from cost-allocation
  },
  // 媒体シンク軸優先時に非表示にする項目
  denyMediaSyncList: [
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
  ],
  // エビス軸優先時に非表示にする項目
  denyEbisList: [
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
  ],
  displayFreezeList: [DisplayItems.PERIOD],
  orderList: {
    [DisplayItems.PERIOD]: 1,
    [DisplayItems.CHANNEL]: 2,
    [DisplayItems.SYNC_CATEGORY]: 3,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]: 4,
    [DisplayItems.MEDIA_SIDE_GROUP]: 5,
    [DisplayItems.CATEGORY]: 6,
    [DisplayItems.AD_GROUP1]: 7,
    [DisplayItems.AD_GROUP2]: 8,
    [DisplayItems.AGENCY]: 9,
    [DisplayItems.IMPRESSION]: 10,
    [DisplayItems.CNT_ACCESS]: 11,
    [DisplayItems.CTR]: 12,
    [DisplayItems.CNT_SESSION]: 13,
    [DisplayItems.CNT_PV]: 14,
    [DisplayItems.PV_AVG]: 15,
    [DisplayItems.CNT_RETURN]: 16,
    [DisplayItems.PER_RETURN]: 17,
    [DisplayItems.TOTAL_STAY_TIME]: 18,
    [DisplayItems.TOTAL_STAY_TIME_AVG]: 19,
    [DisplayItems.TOTAL_LATENCY_TIME]: 20,
    [DisplayItems.LATENCY_TIME_AVG]: 21,
    [DisplayItems.CNT_CV_DETAIL]: 22,
    [DisplayItems.CVR_DETAIL]: 23,
    [DisplayItems.CNT_CV_TOTAL]: 24,
    [DisplayItems.CVR_TOTAL]: 25,
    [DisplayItems.CNT_INDIRECT2]: 26,
    [DisplayItems.CNT_INDIRECT3]: 27,
    [DisplayItems.CNT_INDIRECT4]: 28,
    [DisplayItems.CNT_INDIRECT5]: 29,
    [DisplayItems.CNT_INDIRECT_OTHER]: 30,
    [DisplayItems.INDIRECT_TOTAL]: 31,
    [DisplayItems.DIRECT_RATE]: 32,
    [DisplayItems.INDIRECT_RATE]: 33,
    [DisplayItems.CNT_FIRST]: 34,
    [DisplayItems.RCV]: 35, // from cost-allocation
    [DisplayItems.RCV_RATE]: 36, // from cost-allocation
    [DisplayItems.PRODUCTIVITY_RCV]: 37, // from cost-allocation
    [DisplayItems.RAMOUNT]: 38, // from cost-allocation
    [DisplayItems.RAMOUNT_RATE]: 39, // from cost-allocation
    [DisplayItems.PRODUCTIVITY_AMOUNT]: 40, // from cost-allocation
    [DisplayItems.AMOUNT]: 41,
    [DisplayItems.COST]: 42,
    [DisplayItems.COST_RATE]: 43, // from cost-allocation
    [DisplayItems.CPC]: 44,
    [DisplayItems.CPA]: 45,
    [DisplayItems.TCPA]: 46,
    [DisplayItems.ROAS]: 47,
    [DisplayItems.TROAS]: 48,
    [DisplayItems.CROSSDEVICE_DIFF_COMPARISON]: 49,
  },
  displayDefaultList: [
    DisplayItems.PERIOD,
    // Same settings as category-analysis
    DisplayItems.CATEGORY,
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.IMPRESSION,
    DisplayItems.CNT_ACCESS,
    DisplayItems.CTR,
    DisplayItems.CNT_CV_DETAIL,
    DisplayItems.CNT_CV_TOTAL,
    DisplayItems.CVR_DETAIL,
    DisplayItems.CVR_TOTAL,
    DisplayItems.CNT_INDIRECT2,
    DisplayItems.CNT_INDIRECT3,
    DisplayItems.CNT_INDIRECT4,
    DisplayItems.CNT_INDIRECT5,
    DisplayItems.CNT_INDIRECT_OTHER,
    DisplayItems.INDIRECT_TOTAL,
    // DisplayItems.DIRECT_RATE,
    // DisplayItems.INDIRECT_RATE,
    DisplayItems.CNT_FIRST,
    DisplayItems.RCV,
    DisplayItems.AMOUNT,
    DisplayItems.COST,
    DisplayItems.CPA,
    DisplayItems.TCPA,
    DisplayItems.ROAS,
  ],
  sortDefaultList: {
    [DisplayItems.PERIOD]: DisplayItems.SORT_DIRECTION_ASC,
  },
  sortDisabledList: [DisplayItems.CNT_CV_DETAIL, DisplayItems.CVR_DETAIL],
};

const SettingAdDisplayItems = {
  ...SettingDisplayItems,
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    // DisplayItems.CATEGORY,
    // DisplayItems.IMPRESSION,
    // DisplayItems.CNT_ACCESS,
    // DisplayItems.CTR,
    // DisplayItems.CNT_CV_DETAIL,
    // DisplayItems.CNT_CV_TOTAL,
    // DisplayItems.CVR_DETAIL,
    // DisplayItems.CVR_TOTAL,
    // DisplayItems.CNT_INDIRECT2,
    // DisplayItems.CNT_INDIRECT3,
    // DisplayItems.CNT_INDIRECT4,
    // DisplayItems.CNT_INDIRECT5,
    // DisplayItems.CNT_INDIRECT_OTHER,
    // DisplayItems.INDIRECT_TOTAL,
    DisplayItems.DIRECT_RATE,
    DisplayItems.INDIRECT_RATE,
    // DisplayItems.CNT_FIRST,
    // DisplayItems.RCV,
    // DisplayItems.AMOUNT,
    // DisplayItems.COST,
    // DisplayItems.CPA,
    // DisplayItems.TCPA,
    // DisplayItems.ROAS,
  ],
};
const SettingAllDisplayItems = {
  ...SettingDisplayItems,
  groupList: {
    ...SettingDisplayItems.groupList,
    [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  displayFreezeList: [
    ...SettingDisplayItems.displayFreezeList,
    DisplayItems.CHANNEL,
  ],
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    DisplayItems.CHANNEL,
    // DisplayItems.CATEGORY,
    // DisplayItems.IMPRESSION,
    // DisplayItems.CNT_ACCESS,
    // DisplayItems.CTR,
    // DisplayItems.CNT_CV_DETAIL,
    // DisplayItems.CNT_CV_TOTAL,
    // DisplayItems.CVR_DETAIL,
    // DisplayItems.CVR_TOTAL,
    // DisplayItems.CNT_INDIRECT2,
    // DisplayItems.CNT_INDIRECT3,
    // DisplayItems.CNT_INDIRECT4,
    // DisplayItems.CNT_INDIRECT5,
    // DisplayItems.CNT_INDIRECT_OTHER,
    // DisplayItems.INDIRECT_TOTAL,
    // DisplayItems.DIRECT_RATE,
    // DisplayItems.INDIRECT_RATE,
    // DisplayItems.CNT_FIRST,
    // DisplayItems.RCV,
    // DisplayItems.AMOUNT,
    // DisplayItems.COST,
    // DisplayItems.CPA,
    // DisplayItems.TCPA,
    // DisplayItems.ROAS,
  ],
  requiredList: [DisplayItems.CHANNEL],
};

export const getAd = () => {
  const displayItemsBase = DisplayItems.getAdInflowAnalysis();
  return DisplayItems.getItems({
    ...SettingAdDisplayItems,
    displayItemsBase,
  });
};

export const getAdDefault = () => {
  const displayItemsBase = getAd();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getCommon = () => {
  const displayItemsBase = DisplayItems.getCommonInflowAnalysis();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getCommonDefault = () => {
  const displayItemsBase = getCommon();
  return DisplayItems.getDefault(displayItemsBase);
};
