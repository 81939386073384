import { useRef, useMemo, useCallback, useState } from 'react';
import { getHeightRow } from 'views/organism/GridTable/GridTableService';

const useResizeObserver = () => {
  const [heightRow, updateRowsHeight] = useState({});
  const heightRowKeys = JSON.stringify(heightRow);
  // only set height value for case received from ResizeObserver
  const animationFrame = useRef();
  const detectHeightChange = useCallback(
    ([entity]) => {
      if (entity.target.classList.contains('grid-table--resizing')) return;

      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
      animationFrame.current = requestAnimationFrame(() => {
        let maxRowsHeight = { ...heightRow };
        const rowEls = entity.target
          .querySelector('[role="rowgroup"]')
          .querySelectorAll('[role="row"]');

        rowEls.forEach((rowEl) => {
          maxRowsHeight = { ...maxRowsHeight, ...getHeightRow(rowEl) };
        });
        updateRowsHeight(maxRowsHeight);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [heightRowKeys]
  );

  const resizeObserver = useMemo(
    () => new MutationObserver(detectHeightChange),
    [detectHeightChange]
  );

  return { resizeObserver, heightRow };
};

export default useResizeObserver;
