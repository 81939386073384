import { SettingsApi as Api } from 'services/api/Api';
import pages from 'services/routes/pages';
import { IMPRESSION_AD_COST } from 'services/routes/constants';
import axios from 'axios';
import { saveAs } from 'services/utils';

const IMPRESSION_AD_COST_API_ENDPOINT = pages[IMPRESSION_AD_COST].endpoint;

const fetchAll = async (data) =>
  Api.post(`${IMPRESSION_AD_COST_API_ENDPOINT}/list`, data);

const downloadCsvFormat = async (importingType) => {
  const response = await Api.get(
    `${IMPRESSION_AD_COST_API_ENDPOINT}/template/${importingType}`
  );
  const { location } = response.data.data;
  if (location) {
    saveAs(location);
  }
};

const getPresignedUrl = async () =>
  Api.post(
    `${IMPRESSION_AD_COST_API_ENDPOINT}/csv?content-type=application/gzip`
  );

const upload = async ({
  filename,
  importingType,
  csvRegisterType,
  callback,
}) => {
  const url = `${IMPRESSION_AD_COST_API_ENDPOINT}/upload`;
  const data = {
    file_name: filename,
    importing_type: importingType,
    csv_register_type: csvRegisterType,
  };
  return Api.requestAsync(
    url,
    data,
    'POST',
    {
      pollTokenKey: 'token',
      pollUrlFormat: `${url}/{token}`,
    },
    callback
  );
};

const downloadUploadedCsv = async (token) =>
  Api.get(`${IMPRESSION_AD_COST_API_ENDPOINT}/uploaded-csv/${token}`);

const getBlobByPresignedUrl = async (location) =>
  axios.get(location, { responseType: 'blob' });

export default {
  fetchAll,
  downloadCsvFormat,
  getPresignedUrl,
  upload,
  downloadUploadedCsv,
  getBlobByPresignedUrl,
};
