import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'views/atoms/CopyToClipboard';
import UserConfigs, { UserConfigsCapiSingle } from 'domain/topbar/UserConfigs';
import ExternalTransitionWrapper from 'services/routes/ExternalTransitionWrapper';
import PermissionWrapperRPC from 'services/appconfig/PermissionWrapperRPC';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';
import logout from 'domain/logout';

const User = ({ userData, userId, chargeName }) => {
  const [textCopy, setTextCopy] = useState('IDをコピーする');
  const updateTextTooltip = (text) => {
    setTextCopy(text);
  };

  const handleLogout = () => {
    logout();
  };
  const { isCapiSingleContract } = useCheckPermissionCapi();

  const configs = isCapiSingleContract ? UserConfigsCapiSingle : UserConfigs;
  const nameUser = () => {
    if (chargeName === null || chargeName === '') {
      return (
        <>
          {userData.clientName}
          <br />
        </>
      );
    }
    return (
      <>
        {userData.clientName}
        <br />
        {chargeName}　様
      </>
    );
  };

  const tooltipCopy = (copyValue) => {
    return (
      <OverlayTrigger
        trigger={['hover']}
        placement="top"
        overlay={<Tooltip className="tooltip-common">{textCopy}</Tooltip>}
        onExited={() => updateTextTooltip('IDをコピーする')}
      >
        <span>
          <CopyToClipboard
            className="copy-icon"
            value={copyValue}
            callback={() => updateTextTooltip('コピーしました')}
            isShowPopup={false}
          />
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <Dropdown bsPrefix="dropdown nav-item">
      <Dropdown.Toggle as="div" role="button" bsPrefix="dropdown-toggle">
        <div className="d-flex align-items-center">
          <div className="text-truncate dropdown-icon">
            <div className="dropdown-text client-name">
              {userData.clientName}
            </div>
            <div className="dropdown-text user-id">{userData.accountId}</div>
          </div>
          <i className="fas fa-chevron-down pullright" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight="true" className="dropdown-menu-shadow">
        <div className="dropdown-submenu top">
          <Dropdown.Header className="text-wrap text-truncate ">
            {nameUser()}
          </Dropdown.Header>
          <Dropdown.Header bsPrefix="user-info">
            <div className="div-title">
              <span className="span-title">アカウントID：</span>
              <span>
                {userData.accountId}
                {tooltipCopy(userData.accountId)}
              </span>
            </div>
            <div className="div-title">
              <span className="span-title">ユーザーID：</span>
              <span>
                {userId}
                {tooltipCopy(userId)}
              </span>
            </div>
            <Button variant="secondary" size="xs" onClick={handleLogout}>
              ログアウト
            </Button>
          </Dropdown.Header>
        </div>
        {configs.map(({ text, url, order, type, newTab, permissions }) => (
          <PermissionWrapperRPC
            key={order}
            requiredPermissions={permissions}
            resolveType="anyOf"
            render={(isAllowed) => (
              <ExternalTransitionWrapper
                link={url}
                type={type}
                newTab={newTab}
                disabled={!isAllowed}
              >
                <Dropdown.Item
                  // need to adds these in as DropdownItem render as <a>
                  as="div"
                  className={isAllowed ? '' : 'ebis--disabled'}
                  disabled={!isAllowed}
                >
                  {text}
                </Dropdown.Item>
              </ExternalTransitionWrapper>
            )}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

User.propTypes = {
  userData: PropTypes.shape({
    clientName: PropTypes.string,
    accountId: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  chargeName: PropTypes.string.isRequired,
};

export default User;
