import React, { useMemo } from 'react';
import { objectOf, arrayOf, shape, func, string } from 'prop-types';
import FilterService from 'domain/FilterService';
import FilterForm from 'views/organism/FilterForm';
import useFilterData from 'services/custom-hooks/useFilterData';
import {
  FILTER_KEY_CONTACT_HISTORY,
  TAB_AD,
  FILTER_KEY_CHANNEL_DISPLAY_RANGE,
} from 'services/consts';
import { REPORT_FIELDS } from 'domain/data-export/consts';
import { FILTER_CONTACT_HISTORY } from 'domain/consts';
import { getPageIdByReportType } from 'services/data-export/dataExportService';
import { CV_ATTRIBUTE } from 'services/routes/constants';

const { FILTERS } = REPORT_FIELDS;

const FilterSettingForRegularReport = (props) => {
  const { reportType, channel, selected, permissions, onChange } = props;

  const pageId = getPageIdByReportType(reportType);
  const [filterableList, mergedMasterData] = useFilterData(pageId, channel);

  // handle value for contact history
  if (FILTER_KEY_CONTACT_HISTORY in filterableList) {
    selected[FILTER_KEY_CONTACT_HISTORY] = {
      order: filterableList[FILTER_KEY_CONTACT_HISTORY].order,
      type: filterableList[FILTER_KEY_CONTACT_HISTORY].type,
      values: selected[FILTER_KEY_CONTACT_HISTORY]
        ? selected[FILTER_KEY_CONTACT_HISTORY].values
        : Object.keys(FILTER_CONTACT_HISTORY),
    };
  }

  // Handle filters to apply or keep
  const { selectedFilters, keepFilters } = useMemo(() => {
    // prepare filter to display UI
    const applyFilters = FilterService.prepareForUI(selected, pageId, channel);

    return Object.keys(selected).reduce(
      (acc, key) => {
        if (
          (key in filterableList && key in applyFilters) ||
          (key === FILTER_KEY_CHANNEL_DISPLAY_RANGE && pageId === CV_ATTRIBUTE)
        ) {
          return {
            ...acc,
            selectedFilters: {
              ...acc.selectedFilters,
              [key]: selected[key],
            },
          };
        }
        return {
          ...acc,
          keepFilters: {
            ...acc.keepFilters,
            [key]: selected[key],
          },
        };
      },
      { selectedFilters: {}, keepFilters: {} }
    );
  }, [channel, selected, filterableList, pageId]);

  const handleUpdateFilters = (filters) => {
    onChange(FILTERS, { ...keepFilters, ...filters });
  };

  return (
    <div className="data-export-setting__item data-export-setting__item--filter mb-30">
      <div className="data-export-setting__title">フィルタ</div>
      <div className="data-export-setting__content d-block">
        <FilterForm
          customView
          masterData={mergedMasterData}
          filterableList={filterableList}
          selectedFilters={selectedFilters}
          onChange={handleUpdateFilters}
          userPermissions={permissions}
          pageId={pageId}
          currentTab={channel}
        />
      </div>
    </div>
  );
};

FilterSettingForRegularReport.propTypes = {
  reportType: string,
  channel: string,
  selected: objectOf(shape({})),
  permissions: arrayOf(string),
  onChange: func,
};

FilterSettingForRegularReport.defaultProps = {
  reportType: '',
  channel: TAB_AD,
  selected: {},
  permissions: [],
  onChange: () => {},
};

export default React.memo(FilterSettingForRegularReport);
