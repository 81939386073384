import React from 'react';
import {
  oneOfType,
  node,
  string,
  number,
  func,
  arrayOf,
  shape,
  array,
  object,
  bool,
} from 'prop-types';
import FormGroup from 'views/molecules/FormGroup';
import SearchableSelect from 'views/molecules/SearchableSelect';
import InputCollapse from 'views/molecules/Collapse/InputCollapse';

function FormGroupSelect(props) {
  const {
    isLoading,
    required,
    label,
    name,
    tooltip,
    items,
    value,
    error,
    createStatus,
    createRules,
    createItem,
    createError,
    onCreate,
    onSearch,
    onSelect,
    onClear,
    tooltipStayOpen,
  } = props;

  const handleValueChanged = (valueSelected) => {
    const itemSelected = items.find((item) => item.id === valueSelected);
    onSelect(itemSelected);
  };

  return (
    <FormGroup
      required={required}
      label={label}
      tooltip={tooltip}
      tooltipStayOpen={tooltipStayOpen}
    >
      <SearchableSelect
        isLoading={isLoading}
        title={label}
        items={items}
        selected={value}
        error={error}
        onSearch={onSearch}
        onClear={onClear}
        onSelect={handleValueChanged}
        footer={
          onCreate && (
            <InputCollapse
              show={onCreate}
              label={label}
              name={name}
              status={createStatus}
              error={createError}
              rules={createRules}
              onSubmit={onCreate}
              onFinish={() => {
                onSelect(createItem);
                onSearch(''); // Update list for FormGroupSelect
                document.body.click(); // Close FormGroupSelect
              }}
            />
          )
        }
      />
    </FormGroup>
  );
}

FormGroupSelect.propTypes = {
  isLoading: bool,
  required: bool,
  label: string,
  name: string,
  value: string,
  tooltip: oneOfType([string, node]),
  items: arrayOf(shape({ id: number, name: string })),
  error: string,
  createStatus: string,
  createError: string,
  createRules: oneOfType([array]),
  createItem: oneOfType([object]),
  onCreate: oneOfType([func, bool]),
  onSearch: func,
  onSelect: func,
  onClear: func,
  tooltipStayOpen: bool,
};

FormGroupSelect.defaultProps = {
  isLoading: false,
  required: false,
  label: '',
  name: '',
  value: '',
  tooltip: '',
  items: [],
  error: '',
  createStatus: '',
  createError: '',
  createRules: [],
  createItem: {},
  onCreate: false,
  onSearch: () => {},
  onSelect: () => {},
  onClear: () => {},
  tooltipStayOpen: false,
};

export default FormGroupSelect;
