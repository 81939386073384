import React, { useState, useRef, useMemo } from 'react';
import { func, bool, shape } from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import SettingModal from 'views/pages/system-setting/components/Modal';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import EbisDropdown from 'views/atoms/dropdown/EbisDropdown';
import SelectPopup from 'views/organism/SelectPopup';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import { MASTER_DATA_FILTER_LIMIT } from 'domain/consts';
import {
  OPERATOR_OPTIONS,
  DISPLAY_CHANNELS,
  DISPLAY_LAYERS,
  DISPLAY_ITEMS,
  INFLOW_MODES,
  INFLOW_MODE_AD,
  INFLOW_MODE_URL,
  ROUTE_SETTING_FIELDS,
} from 'domain/log/route-analyze/consts';
import {
  handleDisplayItemOptions,
  validateSettings,
  checkSettingRoute,
} from 'services/log/LogService';

const {
  DISPLAY_CHANNEL,
  INFLOW_MODE,
  START_PAGE_ID,
  TRANSIT_PAGE_ID,
  END_PAGE_ID,
  DISPLAY_LAYER,
  DISPLAY_RATE,
  DISPLAY_ITEM,
  MEDIA_ID,
  AD_GROUP1_ID,
  AD_GROUP2_ID,
  AD_ID,
  LINK_URL,
  INFLOW_AD,
} = ROUTE_SETTING_FIELDS;

const AD_FIELDS = [MEDIA_ID, AD_GROUP1_ID, AD_GROUP2_ID, AD_ID];

const mapperKeyAd = { value: 'id', label: 'name' };
const submenuPage = [
  { value: 'page_id', label: 'ページIDで検索' },
  { value: 'page_title', label: 'ページタイトルで検索' },
  { value: 'page_url', label: 'ページURLで検索' },
];

const TooltipPageSelected = (title, page = {}) => {
  if (isEmpty(page)) return false;

  return (
    <div className="hook-tooltip hook-tooltip--page-selected">
      <div>{title}</div>
      <div>ページID：{page.id}</div>
      <div>ページタイトル：{page.name}</div>
      <div>ページURL：{page.url}</div>
    </div>
  );
};

function RouteSettingModal(props) {
  const {
    loading,
    defaultSettings,
    masterdata,
    onSearchMasterdata,
    onSubmit,
    onHide,
  } = props;
  const { display, media, adGroup1, adGroup2, page } = masterdata;

  const [settings, setSettings] = useState(defaultSettings);
  const [keySearchPage, setKeySearchPage] = useState('page_id');

  const [error, setError] = useState({});

  const inflowRef = useRef();

  const errorInflowAd = error[INFLOW_AD];
  const displayItems = handleDisplayItemOptions(DISPLAY_ITEMS);

  const { isSettingRate, isSettingChannel, isSettingInflow } = useMemo(() => {
    return checkSettingRoute(settings);
  }, [settings]);

  const mapperKeyPage = useMemo(() => {
    return {
      value: 'id',
      selected: 'name',
      label: keySearchPage === 'page_url' ? 'url' : 'name',
    };
  }, [keySearchPage]);

  const inflowCls = classNames({
    'route-setting__inflow-mode': true,
    'route-setting__inflow-mode--error': !!errorInflowAd,
  });

  const handleChangeValue = (name, value) => {
    let field = name;
    let cloneValue = value;
    if (AD_FIELDS.includes(name)) {
      field = INFLOW_AD;
    }
    if ([INFLOW_MODE, DISPLAY_LAYER, DISPLAY_RATE].includes(name)) {
      cloneValue = value ? Number(value) : value;
    }

    setSettings((prev) => ({ ...prev, [name]: cloneValue }));
    setError((prev) => ({ ...prev, [name]: null, [field]: null }));
  };

  const handleChangeLinkUrl = (name) => (operator, value) => {
    handleChangeValue(LINK_URL, {
      ...settings[LINK_URL],
      [name]: name === 'operator' ? +operator : value,
    });
  };

  const handleSelectValue = (name) => (value) => {
    let cloneValue = value;

    switch (name) {
      case DISPLAY_CHANNEL:
      case DISPLAY_ITEM:
        cloneValue = { ...settings[name], [value]: !settings[name]?.[value] };
        break;

      case START_PAGE_ID:
      case TRANSIT_PAGE_ID:
      case END_PAGE_ID:
        setKeySearchPage('page_id');
        break;

      default:
        break;
    }

    handleChangeValue(name, cloneValue);
  };

  const handleSubmit = () => {
    const errorValidate = validateSettings(settings);
    setError(errorValidate);
    if (isEmpty(errorValidate)) {
      onSubmit(settings);
    }
  };

  return (
    <SettingModal
      isShow
      loading={loading}
      onHide={onHide}
      onSubmit={handleSubmit}
      disabledSubmit={!isSettingChannel || !isSettingInflow || !isSettingRate}
      title="経路分析の条件を指定する"
      dialogClassName="modal-dialog--route-setting"
    >
      <div className="route-setting route-setting--channel">
        <div className="route-setting__title">流入チャネル</div>
        <FormGroup required label="流入チャネル">
          {DISPLAY_CHANNELS.map((item) => (
            <EbisCheckbox
              name={item.key}
              text={item.value}
              checked={settings[DISPLAY_CHANNEL][item.key]}
              changeSelectBoxValue={handleSelectValue(DISPLAY_CHANNEL)}
            />
          ))}
        </FormGroup>
      </div>
      <div className="route-setting route-setting--inflow" ref={inflowRef}>
        <div className="route-setting__title">
          詳細条件<span>1つ以上指定必須</span>
        </div>
        <FormGroup label="流入元">
          {Object.values(INFLOW_MODES).map((item) => (
            <EbisRadio
              name={INFLOW_MODE}
              label={item.label}
              value={item.value}
              checked={settings[INFLOW_MODE] === item.value}
              onChange={handleSelectValue(INFLOW_MODE)}
            />
          ))}
          {settings[INFLOW_MODE] === INFLOW_MODE_AD && (
            <ErrorTooltipWrapper
              ref={inflowRef}
              isError={!!errorInflowAd}
              errorMess={errorInflowAd}
            >
              <div className={inflowCls}>
                <FormGroup label="媒体種別">
                  <SelectPopup
                    multiple
                    loading={media.loading}
                    title="媒体種別"
                    value={settings[MEDIA_ID]}
                    options={media.options}
                    limitDisplay={MASTER_DATA_FILTER_LIMIT}
                    mapperKey={mapperKeyAd}
                    onChange={handleSelectValue(MEDIA_ID)}
                    onSearch={onSearchMasterdata('media')}
                  />
                </FormGroup>
                <FormGroup label={display.ad_group1}>
                  <SelectPopup
                    multiple
                    loading={adGroup1.loading}
                    title={display.ad_group1}
                    value={settings[AD_GROUP1_ID]}
                    mapperKey={mapperKeyAd}
                    options={adGroup1.options}
                    limitDisplay={MASTER_DATA_FILTER_LIMIT}
                    onChange={handleSelectValue(AD_GROUP1_ID)}
                    onSearch={onSearchMasterdata('adGroup1')}
                  />
                </FormGroup>
                <FormGroup label={display.ad_group2}>
                  <SelectPopup
                    multiple
                    loading={adGroup2.loading}
                    title={display.ad_group2}
                    value={settings[AD_GROUP2_ID]}
                    mapperKey={mapperKeyAd}
                    options={adGroup2.options}
                    limitDisplay={MASTER_DATA_FILTER_LIMIT}
                    onChange={handleSelectValue(AD_GROUP2_ID)}
                    onSearch={onSearchMasterdata('adGroup2')}
                  />
                </FormGroup>
                <FormGroup label="広告ID" note="カンマ区切りで複数検索可">
                  <FormInput
                    name={AD_ID}
                    value={settings[AD_ID]}
                    error={error[AD_ID]}
                    onChange={handleChangeValue}
                  />
                </FormGroup>
              </div>
            </ErrorTooltipWrapper>
          )}
          {settings[INFLOW_MODE] === INFLOW_MODE_URL && (
            <div className="route-setting__inflow-mode route-setting__inflow-mode--url">
              <FormGroup label="遷移元サイト">
                <EbisDropdown
                  optionObject={OPERATOR_OPTIONS}
                  defaultVal={settings[LINK_URL].operator}
                  selectCallback={handleChangeLinkUrl('operator')}
                />
                <FormInput
                  name={LINK_URL}
                  value={settings[LINK_URL].value}
                  error={error[LINK_URL]}
                  placeholder="https://"
                  onChange={handleChangeLinkUrl('value')}
                />
              </FormGroup>
            </div>
          )}
        </FormGroup>
        <FormGroup label="始点とするページ">
          <SelectPopup
            title="始点とするページ"
            loading={page.loading}
            value={settings[START_PAGE_ID]}
            valueTootip={TooltipPageSelected(
              '始点とするページ',
              settings[START_PAGE_ID]
            )}
            options={page.options}
            mapperKey={mapperKeyPage}
            limitDisplay={MASTER_DATA_FILTER_LIMIT}
            onChange={handleSelectValue(START_PAGE_ID)}
            onSearch={onSearchMasterdata('page', keySearchPage)}
            onChangeSubmenu={setKeySearchPage}
            submenu={submenuPage}
          />
        </FormGroup>
        <FormGroup label="経由するページ">
          <SelectPopup
            title="経由するページ"
            loading={page.loading}
            value={settings[TRANSIT_PAGE_ID]}
            valueTootip={TooltipPageSelected(
              '経由するページ',
              settings[TRANSIT_PAGE_ID]
            )}
            options={page.options}
            mapperKey={mapperKeyPage}
            limitDisplay={MASTER_DATA_FILTER_LIMIT}
            onChange={handleSelectValue(TRANSIT_PAGE_ID)}
            onSearch={onSearchMasterdata('page', keySearchPage)}
            onChangeSubmenu={setKeySearchPage}
            submenu={submenuPage}
          />
        </FormGroup>
        <FormGroup label="終点とするページ">
          <SelectPopup
            title="終点とするページ"
            loading={page.loading}
            value={settings[END_PAGE_ID]}
            valueTootip={TooltipPageSelected(
              '終点とするページ',
              settings[END_PAGE_ID]
            )}
            options={page.options}
            mapperKey={mapperKeyPage}
            limitDisplay={MASTER_DATA_FILTER_LIMIT}
            onChange={handleSelectValue(END_PAGE_ID)}
            onSearch={onSearchMasterdata('page', keySearchPage)}
            onChangeSubmenu={setKeySearchPage}
            submenu={submenuPage}
          />
        </FormGroup>
      </div>
      <div className="route-setting route-setting--display">
        <div className="route-setting__title">表示設定</div>
        <FormGroup
          label="表示する階層数"
          tooltip="ユーザーが訪問したページを何階層まで表示するかを選択します。"
        >
          <EbisDropdown
            optionObject={Object.values(DISPLAY_LAYERS)}
            defaultVal={settings[DISPLAY_LAYER]}
            selectCallback={handleSelectValue(DISPLAY_LAYER)}
          />
        </FormGroup>
        <FormGroup
          required
          label="表示する経路の割合"
          note="半角数字のみ"
          tooltip="条件に合致するSUのうち、何％以上のSUがあった経路を表示するかを設定します。"
        >
          <FormInput
            name={DISPLAY_RATE}
            value={settings[DISPLAY_RATE]}
            regex="^[0-9]+(\.?)([0-9]+)?$"
            maxLength={5}
            placeholder="0以上の値を入力"
            error={error[DISPLAY_RATE]}
            onChange={handleChangeValue}
          />
          <div className="form-note">％以上</div>
        </FormGroup>
        <FormGroup label="表示項目">
          {displayItems.map((item) => (
            <EbisCheckbox
              name={item.name}
              text={item.text}
              checked={item.required || settings[DISPLAY_ITEM][item.name]}
              disabled={item.required}
              changeSelectBoxValue={handleSelectValue(DISPLAY_ITEM)}
            />
          ))}
        </FormGroup>
      </div>
    </SettingModal>
  );
}

RouteSettingModal.propTypes = {
  loading: bool.isRequired,
  defaultSettings: shape({}).isRequired,
  onSubmit: func.isRequired,
  onHide: func.isRequired,
  masterdata: shape({}).isRequired,
  onSearchMasterdata: func.isRequired,
};

RouteSettingModal.defaultProps = {};

export default RouteSettingModal;
