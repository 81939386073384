import types from 'store/user-profile/types';
import helper from 'store/helper';

const requestUserBehaviors = () =>
  helper.createAction(types.REQUEST_USER_BEHAVIORS);
const fetchReportUserBehaviors = (payload) =>
  helper.createAction(types.FETCH_REPORT_USER_BEHAVIORS, payload);
const fetchReportUserBehaviorsFailed = (error, scope = null) =>
  helper.createAction(types.FETCH_REPORT_USER_BEHAVIORS_ERROR, error, scope);
const fetchReportUserBehaviorsSucceeded = (userBehaviors) =>
  helper.createAction(types.FETCH_REPORT_USER_BEHAVIORS_SUCCESS, userBehaviors);
const requestUserProfile = () =>
  helper.createAction(types.REQUEST_USER_PROFILE);
const fetchReportUserProfile = (payload) =>
  helper.createAction(types.FETCH_REPORT_USER_PROFILE, payload);
const fetchReportUserProfileFailed = (error, scope = null) =>
  helper.createAction(types.FETCH_REPORT_USER_PROFILE_ERROR, error, scope);
const fetchReportUserProfileSucceeded = (userProfile) =>
  helper.createAction(types.FETCH_REPORT_USER_PROFILE_SUCCESS, userProfile);
const sortUserBehaviors = () => helper.createAction(types.SORT_USER_BEHAVIORS);
const updateSortOrder = (order) =>
  helper.createAction(types.UPDATE_SORT_ORDER, order);
const updatePage = (page) => helper.createAction(types.UPDATE_PAGE, page);
const updateBaseDate = (baseDate) =>
  helper.createAction(types.UPDATE_BASE_DATE, baseDate);
const updateAdExpireTime = (AdExpireTime) =>
  helper.createAction(types.UPDATE_AD_EXPIRE_TIME, AdExpireTime);
const downloadCsv = (payload) =>
  helper.createAction(types.DOWNLOAD_CSV, payload);
const updateParameter = (payload) =>
  helper.createAction(types.UPDATE_PARAMETER, payload);
const openHierarchy = (payload) =>
  helper.createAction(types.OPEN_HIERARCHY, payload);
const openHierarchyAll = (payload) =>
  helper.createAction(types.OPEN_HIERARCHY_ALL, payload);
export default {
  requestUserBehaviors,
  fetchReportUserBehaviors,
  fetchReportUserBehaviorsFailed,
  fetchReportUserBehaviorsSucceeded,
  requestUserProfile,
  fetchReportUserProfile,
  fetchReportUserProfileFailed,
  fetchReportUserProfileSucceeded,
  sortUserBehaviors,
  updateSortOrder,
  updatePage,
  updateBaseDate,
  updateAdExpireTime,
  downloadCsv,
  updateParameter,
  openHierarchy,
  openHierarchyAll,
};
