import React from 'react';
import { oneOfType, node, string, number } from 'prop-types';
import { formatNumber } from 'domain/utils';

import './count-item-selected.scss';

function CountItemSelected(props) {
  const { count, children } = props;

  return (
    <div className="count-item-selected">
      <span className="count-item-selected__text">{`${formatNumber(
        count
      )}件選択中`}</span>
      {children}
    </div>
  );
}

CountItemSelected.propTypes = {
  count: number,
  children: oneOfType([string, node]),
};

CountItemSelected.defaultProps = {
  count: 0,
  children: '',
};

export default CountItemSelected;
