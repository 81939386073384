import { bool, func } from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import adManagementSettingsSelectors from 'store/ad-management-settings/selectors';
import adManagementSettingsActions from 'store/ad-management-settings/actions';
import AdManagementDisplayItemsModal from 'views/organism/DisplayItemsModal/AdManagementDisplayItemsModal';
import { DisplayItemsSelectors } from 'store/display-items';

function AdManagementDisplayItemsModalContainer(props) {
  const { show, onHide } = props;
  const [defaultItems, settingItems, priorityAxis] = [
    useSelector(
      adManagementSettingsSelectors.getDisplayItemsForDefaultsModal,
      shallowEqual
    ),
    useSelector(
      adManagementSettingsSelectors.getDisplayItemsForSettingsModal,
      shallowEqual
    ),
    useSelector(
      DisplayItemsSelectors.getDisplayItemPriorityAxis,
      shallowEqual
    )
  ];
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (newItems) => {
      dispatch(adManagementSettingsActions.createDisplayItems(newItems));
    },
    [dispatch]
  );

  return (
    <AdManagementDisplayItemsModal
      show={show}
      onHide={onHide}
      onSubmit={onSubmit}
      settingItems={settingItems}
      defaultItems={defaultItems}
      priorityAxis={priorityAxis}
    />
  );
}

AdManagementDisplayItemsModalContainer.propTypes = {
  show: bool.isRequired,
  onHide: func.isRequired,
};

export default AdManagementDisplayItemsModalContainer;
