import { createSelector } from 'reselect';
import { PRODUCT_ID, PRODUCT_NAME } from 'domain/ltv/settings/constants';
import { getSortString } from 'domain/utils';

const getList = (state) => {
  const { list } = state.cache.ltvSettingsProduct;
  return list;
};

const getStatus = (state) => {
  const { status } = state.cache.ltvSettingsProduct;
  return status;
};

const getFetchStatus = (state) => {
  const { fetchStatus } = state.cache.ltvSettingsProduct;
  return fetchStatus;
};

const getSelectedItem = (state) => {
  const { selected } = state.cache.ltvSettingsProduct;
  return selected;
};

const isShowModal = (state) => {
  const { isShowSettingModal } = state.cache.ltvSettingsProduct.settings;
  return isShowSettingModal;
};

const getApiError = (state) => {
  return state.cache.ltvSettingsProduct.error;
};

const getPagination = (state) =>
  state.cache.ltvSettingsProduct.settings.pagination;

const getSort = (state) => state.cache.ltvSettingsProduct.settings.sort;

const getCurrentItems = (state) =>
  state.cache.ltvSettingsProduct.settings.pagination.totalItems;

// get options for render select popup
const getOptions = createSelector(
  (state) => state.cache.ltvSettingsProduct.list,
  (products) => {
    const data = {};
    products.map((product, index) => {
      data[product[PRODUCT_ID]] = {
        value: product[PRODUCT_ID],
        label: product[PRODUCT_NAME],
        order: index + 1,
      };
      return product;
    });
    return data;
  }
);

const csvDownloadEnabledSelector = createSelector(
  [getStatus, getPagination],
  (status, pagination) => {
    return status !== 'loading' && pagination.totalItems > 0;
  }
);

const sortSelector = createSelector(
  (state) => state.cache.ltvSettingsProduct.settings.sort,
  (sort) => {
    return { sort: getSortString(sort) };
  }
);

export default {
  getList,
  getStatus,
  getFetchStatus,
  getSelectedItem,
  isShowModal,
  getApiError,
  getPagination,
  getSort,
  getCurrentItems,
  getOptions,
  csvDownloadEnabledSelector,
  sortSelector,
};
