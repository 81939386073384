import { EBIS_LOG_OUT_SITE } from 'services/routes/constants';

import { saveAuth, clearSessionStorage } from 'services/LocalStorageService';
import logging from 'domain/logging';

const cleaningHelper = async () => {
  // Clear token
  logging.info('Clearing tokens ...');
  saveAuth({
    accessToken: null,
    refreshToken: null,
  });

  // Clear session storage
  logging.info('Clearing sessions...');
  clearSessionStorage();
};

const logout = (options = { fn: null, logoutSite: true }) => {
  const { fn, logoutSite } = options;
  logging.info('Start logging out user...');
  // Execute callback if have
  if (fn && typeof fn === 'function') fn();

  cleaningHelper().then(() => {
    // Redirect to logout site or login site
    logging.info('Redirecting...');
    if (logoutSite) {
      window.location = EBIS_LOG_OUT_SITE;
    } else {
      window.location = process.env.REACT_APP_ID_EBIS_HOST;
    }
  });
};

export default logout;
