const FETCH_DATA_ALL = 'dashboard/FETCH_DATA_ALL';

const FETCH_DATA_METADATA = 'dashboard/FETCH_DATA_METADATA';
const FETCH_DATA_METADATA_SUCCESS = 'dashboard/FETCH_DATA_METADATA_SUCCESS';
const FETCH_DATA_METADATA_ERROR = 'dashboard/FETCH_DATA_METADATA_ERROR';

const FETCH_DATA_STATICTIS = 'dashboard/FETCH_DATA_STATICTIS';
const FETCH_DATA_STATICTIS_SUCCESS = 'dashboard/FETCH_DATA_STATICTIS_SUCCESS';
const FETCH_DATA_STATICTIS_ERROR = 'dashboard/FETCH_DATA_STATICTIS_ERROR';

const FETCH_DATA_CHART = 'dashboard/FETCH_DATA_CHART';
const FETCH_DATA_CHART_SUCCESS = 'dashboard/FETCH_DATA_CHART_SUCCESS';
const FETCH_DATA_CHART_ERROR = 'dashboard/FETCH_DATA_CHART_ERROR';

const FETCH_DATA_REPORT = 'dashboard/FETCH_DATA_REPORT';
const FETCH_DATA_REPORT_SUCCESS = 'dashboard/FETCH_DATA_REPORT_SUCCESS';
const FETCH_DATA_REPORT_ERROR = 'dashboard/FETCH_DATA_REPORT_ERROR';

const FETCH_DATA_COMPARE = 'dashboard/FETCH_DATA_COMPARE';
const FETCH_DATA_COMPARE_SUCCESS = 'dashboard/FETCH_DATA_COMPARE_SUCCESS';
const FETCH_DATA_COMPARE_ERROR = 'dashboard/FETCH_DATA_COMPARE_ERROR';

const FETCH_DATA_KPI_STATUS = 'dashboard/FETCH_DATA_KPI_STATUS';

const CHANGE_SELECTED_YAXISLINE = 'dashboard/CHANGE_SELECTED_YAXISLINE';
const CHANGE_SELECTED_YAXISCOLUMN = 'dashboard/CHANGE_SELECTED_YAXISCOLUMN';
const CHANGE_SELECTED_COMPARE = 'dashboard/CHANGE_SELECTED_COMPARE';
const CHANGE_SELECTED_TARGET = 'dashboard/CHANGE_SELECTED_TARGET';

const ADD_INDICATOR = 'dashboard/ADD_INDICATOR';
const ADD_INDICATOR_SUCCESS = 'dashboard/ADD_INDICATOR_SUCCESS';
const ADD_INDICATOR_ERROR = 'dashboard/ADD_INDICATOR_ERROR';
const EDIT_INDICATOR = 'dashboard/EDIT_INDICATOR';
const EDIT_INDICATOR_SUCCESS = 'dashboard/EDIT_INDICATOR_SUCCESS';
const EDIT_INDICATOR_ERROR = 'dashboard/EDIT_INDICATOR_ERROR';
const DELETE_INDICATOR = 'dashboard/DELETE_INDICATOR';
const DELETE_INDICATOR_SUCCESS = 'dashboard/DELETE_INDICATOR_SUCCESS';
const DELETE_INDICATOR_ERROR = 'dashboard/DELETE_INDICATOR_ERROR';

const UPDATE_DATA_CHART = 'dashboard/UPDATE_DATA_CHART';
const UPDATE_DATA_CHART_SUCCESS = 'dashboard/UPDATE_DATA_CHART_SUCCESS';
const UPDATE_DATA_CHART_ERROR = 'dashboard/UPDATE_DATA_CHART_ERROR';

const UPDATE_DATA_REPORT = 'dashboard/UPDATE_DATA_REPORT';
const UPDATE_DATA_REPORT_SUCCESS = 'dashboard/UPDATE_DATA_REPORT_SUCCESS';
const UPDATE_DATA_REPORT_ERROR = 'dashboard/UPDATE_DATA_REPORT_ERROR';

const UPDATE_DATA_BORDER = 'dashboard/UPDATE_DATA_BORDER';
const UPDATE_DATA_BORDER_SUCCESS = 'dashboard/UPDATE_DATA_BORDER_SUCCESS';
const UPDATE_DATA_BORDER_ERROR = 'dashboard/UPDATE_DATA_BORDER_ERROR';

const SHOW_KPI_STATUS_MODAL = 'dashboard/SHOW_KPI_STATUS_MODAL';

const SHOW_SETTING_MAIL_MODAL = 'dashboard/SHOW_SETTING_MAIL_MODAL';
const GET_SETTING_MAIL_INFO = 'dashboard/GET_SETTING_MAIL_INFO';
const SET_STATUS_SETTING_MAIL = 'dashboard/SET_STATUS_SETTING_MAIL';
const SET_ERROR_SETTING_MAIL = 'dashboard/SET_ERROR_SETTING_MAIL';
const SET_DATA_SETTING_MAIL = 'dashboard/SET_DATA_SETTING_MAIL';
const GET_SETTING_MAIL_ERROR = 'dashboard/GET_SETTING_MAIL_ERROR';
const SAVE_SETTING_MAIL = 'dashboard/SAVE_SETTING_MAIL';
const GET_LIST_USERS = 'dashboard/GET_LIST_USERS';
const SET_LIST_USERS = 'dashboard/SET_LIST_USERS';
const SET_STATUS_LIST_USER = 'dashboard/SET_STATUS_LIST_USER';
const UPDATE_SETTING_MAIL = 'dashboard/UPDATE_SETTING_MAIL';

export default {
  FETCH_DATA_ALL,
  FETCH_DATA_METADATA,
  FETCH_DATA_METADATA_SUCCESS,
  FETCH_DATA_METADATA_ERROR,
  FETCH_DATA_STATICTIS,
  FETCH_DATA_STATICTIS_SUCCESS,
  FETCH_DATA_STATICTIS_ERROR,
  FETCH_DATA_CHART,
  FETCH_DATA_CHART_SUCCESS,
  FETCH_DATA_CHART_ERROR,
  FETCH_DATA_REPORT,
  FETCH_DATA_REPORT_SUCCESS,
  FETCH_DATA_REPORT_ERROR,
  FETCH_DATA_COMPARE,
  FETCH_DATA_COMPARE_SUCCESS,
  FETCH_DATA_COMPARE_ERROR,

  FETCH_DATA_KPI_STATUS,

  CHANGE_SELECTED_YAXISLINE,
  CHANGE_SELECTED_YAXISCOLUMN,
  CHANGE_SELECTED_COMPARE,
  CHANGE_SELECTED_TARGET,

  ADD_INDICATOR,
  ADD_INDICATOR_SUCCESS,
  ADD_INDICATOR_ERROR,
  EDIT_INDICATOR,
  EDIT_INDICATOR_SUCCESS,
  EDIT_INDICATOR_ERROR,
  DELETE_INDICATOR,
  DELETE_INDICATOR_SUCCESS,
  DELETE_INDICATOR_ERROR,

  UPDATE_DATA_CHART,
  UPDATE_DATA_CHART_SUCCESS,
  UPDATE_DATA_CHART_ERROR,

  UPDATE_DATA_REPORT,
  UPDATE_DATA_REPORT_SUCCESS,
  UPDATE_DATA_REPORT_ERROR,

  UPDATE_DATA_BORDER,
  UPDATE_DATA_BORDER_SUCCESS,
  UPDATE_DATA_BORDER_ERROR,

  SHOW_KPI_STATUS_MODAL,
  SHOW_SETTING_MAIL_MODAL,
  GET_SETTING_MAIL_INFO,
  SET_STATUS_SETTING_MAIL,
  SET_ERROR_SETTING_MAIL,
  SET_DATA_SETTING_MAIL,
  GET_SETTING_MAIL_ERROR,
  SAVE_SETTING_MAIL,
  GET_LIST_USERS,
  SET_LIST_USERS,
  SET_STATUS_LIST_USER,
  UPDATE_SETTING_MAIL,
};
