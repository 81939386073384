import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { COST_ALLOCATION, USER_ENDPOINT } from 'services/routes/constants';

const CostAllocationApiFactory = () => {
  const url = `${USER_ENDPOINT}/display-settings/bookmark`;
  const costAllocationURL = pages[COST_ALLOCATION].endpoint;

  return {
    url: costAllocationURL,
    bookmarkUrl: url,
    getBookmark: async () => {
      return Api.getRequest(`${url}?func_id=FUNC_COST_ALLOCATE`);
    },
    getDataTable: async (data) => {
      return Api.post(costAllocationURL, data);
    },
    create: async (data) => Api.post(URL, data),
    update: async (id, data) => Api.put(`${url}/${id}`, data),
    delete: async (id) => Api.delete(`${url}/${id}`),
    updateBookmark: async (data) => {
      return Api.post(url, data);
    },
  };
};

const CostAllocationApi = CostAllocationApiFactory();
export default CostAllocationApi;
