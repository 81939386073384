import React, { useCallback, useEffect } from 'react';
import { PropTypes, shape } from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import DisplayItemsSelector from 'store/display-items/selectors';
import SettingSelectors from 'store/settings/selectors';
import DisplayItemsActions from 'store/display-items/actions';
import * as DisplayItems from 'domain/settings/display-items';
import { DETAILS_ANALYSIS, CV_ATTRIBUTE, CATEGORY_ANALYZE } from 'services/routes/constants';
import { TAB_ALL } from 'services/consts';
import useCheckPermissionSC from 'services/custom-hooks/useCheckPermissionSC';
import { getUserId } from 'store/auth/selectors';
import DisplayItemsModal from './DisplayItemsModal';

const DisplayItemsModalContainer = (props) => {
  const {
    show,
    onHide,
    displayitemsApi,
    displayConditions,
    crossDeviceReflectionData,
  } = props;
  let items = useSelector(
    DisplayItemsSelector.getUserPermittedItems,
    shallowEqual
  );
  let defaultValue = useSelector(
    DisplayItemsSelector.getSettings,
    shallowEqual
  );
  const displayItemsSettingsFallback = useSelector(
    DisplayItemsSelector.getSettingsFallback,
    shallowEqual
  );
  const settingsDefault = useSelector(
    DisplayItemsSelector.getSettingsDefault,
    shallowEqual
  );
  const priorityAxis = useSelector(
    DisplayItemsSelector.getDisplayItemPriorityAxis,
    shallowEqual
  );

  const axisList = useSelector(DisplayItemsSelector.getAxis, shallowEqual);
  const sortItems = useSelector(DisplayItemsSelector.getSorting, shallowEqual);

  const funcId = useSelector(SettingSelectors.getFuncId);
  const userId = useSelector(getUserId, shallowEqual);
  const dispatch = useDispatch();
  const enabledPriorityAxis = useSelector(
    DisplayItemsSelector.enabledPriorityAxis
  );

  const displayConditionsList = DisplayItems.getDisplayConditions();
  const { hasSEOContractPermissions } = useCheckPermissionSC();

  const screenId = useSelector(SettingSelectors.getPage);
  const [currentTab, isPageSettings] = [
    useSelector(SettingSelectors.getCurrentTab),
    useSelector(SettingSelectors.isPageSettings),
  ];

  if (displayConditions) {
    items = Object.assign(items, displayConditionsList);
  }

  // Category anaylysis, override display settings
  // Due with issue switch tab all(select only channel) -> ad
  if (displayItemsSettingsFallback) {
    defaultValue = displayItemsSettingsFallback;
  }

  const stateSettingsStr = sessionStorage.getItem(
    `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.settings.${userId}`
  );
  const dataSettings = JSON.parse(stateSettingsStr);
  const displayItems = dataSettings?.settings?.displayItems?.items;
  
  useEffect(() => {
    if (displayItems && screenId !== CATEGORY_ANALYZE) {
      dispatch(
        DisplayItemsActions.fetchSucceeded({
          items: { ...displayItems },
          funcId,
        })
      );
      return;
    }
    if (funcId) {
      dispatch(DisplayItemsActions.fetch({ funcId, displayitemsApi }));
    }
  
  }, [dispatch, funcId, displayitemsApi, userId]);

  useEffect(() => {
    if (
      hasSEOContractPermissions &&
      [DETAILS_ANALYSIS, CV_ATTRIBUTE].includes(screenId) &&
      currentTab === TAB_ALL &&
      !isPageSettings
    ) {
      dispatch(
        DisplayItemsActions.fetchDisplayItemsLandingPageAnalyze({
          funcId: DisplayItems.FUNC_CODE_LANDING_PAGE_ANALYZE,
          displayitemsApi,
        })
      );
    }
  }, [
    dispatch,
    currentTab,
    screenId,
    displayitemsApi,
    isPageSettings,
    hasSEOContractPermissions,
  ]);

  // データを送信する。
  const handleSubmit = useCallback(
    (data) => {
      dispatch(
        DisplayItemsActions.create({
          data,
          funcId,
          displayitemsApi,
        })
      );
      onHide();
    },
    [dispatch, funcId, onHide, displayitemsApi]
  );

  return (
    <DisplayItemsModal
      show={show}
      items={items}
      axisList={axisList}
      sortItems={sortItems}
      funcId={funcId}
      defaultValue={defaultValue}
      settingsDefault={settingsDefault}
      onSubmit={handleSubmit}
      onHide={onHide}
      priorityAxis={priorityAxis}
      enabledPriorityAxis={enabledPriorityAxis}
      crossDeviceReflectionData={crossDeviceReflectionData}
    />
  );
};

DisplayItemsModalContainer.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  displayitemsApi: PropTypes.func.isRequired,
  displayConditions: PropTypes.bool.isRequired,
  crossDeviceReflectionData: shape([]),
};

DisplayItemsModalContainer.defaultProps = {
  show: false,
  onHide: () => {},
  crossDeviceReflectionData: [],
};

export default DisplayItemsModalContainer;
