import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import appVersionSelectors from 'store/version/selectors';
import appVersionActions from 'store/version/actions';
import { loggerActions } from 'store/logger';
/**
 * Process periodic check new version for `production` environment only
 * `development` or `test` environment, source code is new.
 * Case use already reloaded, but can not get new version
 * Do not force reload in next 1 hours
 */
const useCheckNewVersion = () => {
  let hasNewVersion = useSelector(appVersionSelectors.hasNewVersion);
  const canReload = useSelector(appVersionSelectors.canReload);
  // Skip check new version at develop env
  if (process.env.NODE_ENV !== 'production' || canReload === false) {
    hasNewVersion = false;
  }

  const dispatch = useDispatch();
  const handleSubmit = useCallback(() => {
    dispatch(appVersionActions.forceReload());
    // Waiting for session storage saved before reload
    setTimeout(() => window.location.reload(), 1000);
  }, [dispatch]);

  // Periodic check app version
  useEffect(() => {
    let timer;
    if (process.env.NODE_ENV === 'production') {
      timer = setInterval(() => {
        dispatch(appVersionActions.checkVersion());
      }, process.env.REACT_APP_VERSION_CHECK_INTERVAL || 600000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [dispatch]);

  if (hasNewVersion === true) {
    dispatch(
      loggerActions.logConfirmModal({
        title: '新バージョンがリリースされました',
        content:
          '引き続きお使いいただくために、画面の再読み込みをお願いします。',
        callback: handleSubmit,
      })
    );
  }

  return [hasNewVersion, handleSubmit];
};

export default useCheckNewVersion;
