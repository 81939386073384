import classNames from 'classnames';
import PropTypes, { bool } from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

const FilterToggle = ({
  showFilter,
  setShowFilter,
  onActive,
  filterChanged,
  variant,
  isActive,
}) => {
  const toggleFilter = () => {
    setShowFilter(!showFilter);
    if (!showFilter) {
      onActive({ target: 'FilterIcon' });
    }
  };

  const filterClass = classNames({
    'icon-svg': true,
    'icon-filter': true,
    [variant]: !isEmpty(variant),
    fafilter: filterChanged,
    active: isActive,
  });

  return (
    <div
      onClick={toggleFilter}
      className={`nav-link nav-link--icon ${showFilter ? 'active' : ''}`}
      aria-hidden="true"
    >
      <div>
        <div className="d-flex justify-content-center">
          <span className={filterClass} />
        </div>
        <div>フィルタ</div>
      </div>
    </div>
  );
};

FilterToggle.propTypes = {
  showFilter: PropTypes.bool.isRequired,
  setShowFilter: PropTypes.func,
  onActive: PropTypes.func,
  filterChanged: PropTypes.bool,
  variant: PropTypes.oneOf(['sub-icon-settings']),
  isActive: bool,
};

FilterToggle.defaultProps = {
  onActive: () => {},
  filterChanged: false,
  variant: 'default',
  isActive: false,
  setShowFilter: () => {},
};

export default FilterToggle;
