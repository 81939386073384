/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import withAuth from 'services/auth/withAuth';

const ProtectedRoute = ({ component, screenId, currentTab, ...args }) => {
  return (
    <Route
      component={withAuth(component, { screenId, currentTab })}
      {...args}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  screenId: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export default ProtectedRoute;
