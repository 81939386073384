import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';
import types from 'store/system-setting/display/types';

const systemSettingDisplayActions = {
  getData: () => helper.createAction(types.GET_DATA),
  setData: (data) => helper.createAction(types.SET_DATA, { data }),

  setStatus: (status, type) =>
    helper.createAction(types.SET_STATUS, { status, type }),
  setErrors: (errors, type, scope = loggerConstant.SCOPE_DONT_SHOW) =>
    helper.createAction(types.SET_ERRORS, { errors, type }, scope),

  updateVision: (data, callback) =>
    helper.createAction(types.UPDATE_VISION, { data, callback }),
  updateRcv: (data, callback) =>
    helper.createAction(types.UPDATE_RCV, { data, callback }),
};

export default systemSettingDisplayActions;
