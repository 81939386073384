import React, { useRef, useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { Dropdown } from 'react-bootstrap';
import FormatData from 'domain/ltv/analyze/FormatData';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import SettingSelectors from 'store/settings/selectors';

import PropTypes from 'prop-types';
import './periodDropDown.scss';
import moment from 'moment-timezone';
import { UPDATE_TIME_FORMAT } from 'domain/consts';
import { timezoneFormat } from 'services/utils';

const PeriodDropDown = (props) => {
  const { periodActions, periodSelectors, allowedScreen, currentTab } = props;
  const dispatch = useDispatch();
  const scrollbarWrapperRef = useRef(null);
  const funcId = useSelector(SettingSelectors.getFuncId);

  const targetPeriod = useSelector(
    periodSelectors.getTargetPeriod,
    shallowEqual
  );

  const [targetPeriodState, updateTargetPeriodState] = useState(targetPeriod);

  const date = useSelector(periodSelectors.getLastOrderDate, shallowEqual);
  const lastOrderDate = useSelector(
    periodSelectors.getLastOrderDate,
    shallowEqual
  );

  useEffect(() => {
    if (funcId && allowedScreen) {
      // Run fetch status on dropdown date change action.
      if (targetPeriodState !== null) {
        dispatch(periodActions.changeTargetPeriod(targetPeriodState));
      }
    }
  }, [
    dispatch,
    funcId,
    allowedScreen,
    periodActions,
    targetPeriodState,
    currentTab,
    lastOrderDate,
  ]);

  const getPeriodList = (periodDate) => {
    const periods = [];
    const period = timezoneFormat(periodDate);
    for (let i = 0; i <= 24; i += 1) {
      periods.push(
        moment(period, UPDATE_TIME_FORMAT)
          .subtract(i, 'month')
          .format(UPDATE_TIME_FORMAT)
      );
    }
    return periods;
  };
  // Get Period Dropdown dates after getStatus request
  let periodList = [];
  if (date !== null) {
    periodList = getPeriodList(date);
  }
  const handleSelectPeriod = (value) => {
    const targetPeriodId = value;
    updateTargetPeriodState(targetPeriodId);
  };

  const displayMaxLength = 2;

  return (
    <>
      <Dropdown
        className="dropdown-month  align-self-center"
        onSelect={(value) => handleSelectPeriod(value)}
      >
        {periodList.length > 0 && (
          <>
            <Dropdown.Toggle size="sm" variant="select">
              <span className="d-inline-block">
                {FormatData.getPeriodText(
                  targetPeriodState === null ? periodList[0] : targetPeriodState
                )}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {periodList.slice(0, displayMaxLength).map((item) => (
                <Dropdown.Item key={item} eventKey={item}>
                  {FormatData.getPeriodText(item)}
                </Dropdown.Item>
              ))}
              {periodList.length >= displayMaxLength && (
                <OtherPeriod
                  periodList={periodList}
                  handleSelectPeriod={handleSelectPeriod}
                  scrollbarWrapperRef={scrollbarWrapperRef}
                  displayMaxLength={displayMaxLength}
                />
              )}
            </Dropdown.Menu>
          </>
        )}
      </Dropdown>
    </>
  );
};

PeriodDropDown.propTypes = {
  periodActions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  periodSelectors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  allowedScreen: PropTypes.oneOfType([PropTypes.bool]).isRequired,
  currentTab: PropTypes.oneOfType([PropTypes.string]).isRequired,
};

const OtherPeriod = ({
  periodList,
  handleSelectPeriod,
  scrollbarWrapperRef,
  displayMaxLength,
}) => {
  return (
    <Dropdown onSelect={(value) => handleSelectPeriod(value)} drop="right">
      <Dropdown.Toggle
        size="sm"
        variant="select"
        className="m-0 d-flex align-items-center"
      >
        <span className="mr-auto">それ以前</span>
        <i className="far fa-angle-right" />
      </Dropdown.Toggle>
      <Dropdown.Menu ref={scrollbarWrapperRef} className="custom-view">
        <ScrollbarWrapper
          ref={scrollbarWrapperRef}
          maxContent={130}
          sizeScroll={5}
          alignScroll={5}
        >
          {periodList.slice(displayMaxLength).map((item) => (
            <Dropdown.Item key={item} eventKey={item}>
              {FormatData.getPeriodText(item)}
            </Dropdown.Item>
          ))}
        </ScrollbarWrapper>
      </Dropdown.Menu>
    </Dropdown>
  );
};

OtherPeriod.propTypes = {
  periodList: PropTypes.oneOfType([PropTypes.array]),
  handleSelectPeriod: PropTypes.func.isRequired,
  scrollbarWrapperRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  displayMaxLength: PropTypes.oneOfType([PropTypes.number]).isRequired,
};

OtherPeriod.defaultProps = {
  periodList: [],
};

export default PeriodDropDown;
