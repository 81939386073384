const buildSort = (sorts = []) => {
  const sortsString = sorts
    .slice(0, 1)
    .map((sort) => {
      const indicator = sort.asc ? '' : '-';
      return `${indicator}${sort.field}`;
    })
    .join('');

  return sortsString !== '' ? sortsString : undefined;
};

/**
 *
 * @param endpoint
 * @param {{}}queryObject
 */
const buildUrlWithQueryStrings = (endpoint, queryObject = {}) => {
  const queryString = Object.keys(queryObject)
    .map((key) => `${key}=${encodeURIComponent(queryObject[key])}`)
    .join('&');
  return `${endpoint}?${queryString}`;
};

export default {
  buildSort,
  buildUrlWithQueryStrings,
};
