import React from 'react';
import { connect } from 'react-redux';

import commonActions from 'store/common/actions';
import settingsSelector from 'store/settings/selectors';
import Topbar from 'views/molecules/topbar/Topbar';

// eslint-disable-next-line react/jsx-props-no-spreading
const TopbarContainer = (props) => <Topbar {...props} />;

const mapStateToProps = (state) => ({
  isShowNavbar: state.commonState.CommonState.isShowNavbar,
  screenId: settingsSelector.getPage(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleNavbar: (data) => dispatch(commonActions.setData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopbarContainer);
