import { SettingsApi as Api } from 'services/api/Api';
import pages from 'services/routes/pages';
import { AGENCY_MANAGEMENT, COMMON_VALIDATE } from 'services/routes/constants';
import { MAX_ITEMS_REGISTER_BY_SYNC } from 'domain/agency-management/consts';
import apiUtils from 'services/apiUtils';
import { saveAs } from 'services/utils';
import axios from 'axios';

const AgencyManagementApiFactory = () => {
  const url = pages[AGENCY_MANAGEMENT].endpoint;
  const urlAgenySync = `${url}/agency-sync`;
  const urlValidate = pages[COMMON_VALIDATE].endpoint;

  const checkRequestAsync = (urlRequest, data) => {
    const {
      media_ids: mediaIds = [],
      ad_group1_ids: adAroup1Ids = [],
      ad_group2_ids: adAroup2Ids = [],
    } = data;

    const totalItems =
      mediaIds.length + adAroup1Ids.length + adAroup2Ids.length;
    const isAsync = totalItems > MAX_ITEMS_REGISTER_BY_SYNC;
    const endpoint = isAsync ? `${urlRequest}/async` : urlRequest;

    return [isAsync, endpoint];
  };

  return {
    getList: async (filters) => Api.post(`${url}/list`, { filters }),
    getDetail: async (id, convert) => {
      let response = await Api.get(
        apiUtils.buildUrlWithQueryStrings(`${url}/${id}`, {
          convert,
        })
      );

      response = await Api.get(response.data.data.location);
      return response;
    },
    validate: async (data) => {
      const [isAsync, endpoint] = checkRequestAsync(urlValidate, data);
      if (!isAsync) {
        return Api.post(endpoint, data);
      }

      const response = await Api.requestAsync(endpoint, data, 'POST', {
        pollUrlFormat: `${endpoint}/{task}`,
        pollTokenKey: 'task',
      });

      return response;
    },
    create: async (data) => {
      const [isAsync, endpoint] = checkRequestAsync(url, data);
      if (!isAsync) {
        return Api.post(endpoint, data);
      }

      const response = await Api.requestAsync(endpoint, data, 'POST', {
        pollUrlFormat: `${endpoint}/{task}`,
        pollTokenKey: 'task',
      });

      return response;
    },
    update: async (id, data) => {
      const [isAsync, endpoint] = checkRequestAsync(`${url}/${id}`, data);
      if (!isAsync) {
        return Api.put(endpoint, data);
      }

      const response = await Api.requestAsync(endpoint, data, 'PUT', {
        pollUrlFormat: `${endpoint}/{task}`,
        pollTokenKey: 'task',
      });

      return response;
    },
    delete: async (ids) =>
      Api.delete(apiUtils.buildUrlWithQueryStrings(url, { ids })),
    getAgent: async () => {
      const urlList = `${urlAgenySync}/list/async`;
      let response = await Api.requestAsync(urlList, null, 'GET', {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlList}/{task}`,
      });
      response = await Api.get(response.data.data.location);
      return response;
    },
    syncAgent: async (userIds) => {
      const urlSync = `${urlAgenySync}/async`;
      const response = await Api.requestAsync(
        urlSync,
        { user_ids: userIds },
        'POST',
        {
          pollTokenKey: 'task',
          pollUrlFormat: `${urlSync}/{task}`,
        }
      );
      return response;
    },
    downloadCsvAgent: async () => {
      const urlCsv = `${urlAgenySync}/csv`;
      const response = await Api.requestAsync(urlCsv, null, 'GET', {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlCsv}/{task}`,
      });
      const { location } = response.data.data;
      if (location) {
        saveAs(location);
      }
    },
    downloadCsvAdAgent: async (data) => {
      const urlCsv = `${urlAgenySync}/csv/ad`;
      const response = await Api.requestAsync(urlCsv, data, 'POST', {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlCsv}/{task}`,
      });
      const { location } = response.data.data;
      if (location) {
        saveAs(location);
      }
    },
    uploadFile: async (file, url, contentType) => {
      return axios.put(url, file, {
        headers: {
          'Content-Type': contentType,
          'X-Amz-Acl': 'bucket-owner-full-control',
        },
      });
    },
    getUploadUrl: async (contentType) => {
      const query = { 'content-type': contentType };
      const urlUploadCSV = apiUtils.buildUrlWithQueryStrings(
        `${url}/upload-url`,
        query
      );
      const response = await Api.get(urlUploadCSV);
      const { upload_url: uploadUrl, file_name: fileName } = response.data.data;
      return {
        uploadUrl,
        fileName,
      };
    },
    submitCsv: async (filename) => {
      const urlSubmitCsv = `${url}/csv-import`;

      const response = await Api.requestAsync(
        urlSubmitCsv,
        { file_name: filename },
        'PUT',
        {
          pollTokenKey: 'task',
          pollUrlFormat: `${urlSubmitCsv}/{task}`,
        }
      );

      return response;
    },
    downloadCsvAgencyManagement: async (filters) => {
      const urlCsv = `${url}/export-csv`;
      const request = { filters };
      const response = await Api.requestAsync(urlCsv, request, 'POST', {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlCsv}/{task}`,
      });
      const { location } = response.data.data;
      if (location) {
        saveAs(location);
      }
    },
  };
};

const AgencyManagementApi = AgencyManagementApiFactory();
export default AgencyManagementApi;
