export default {
  GET_DATA: 'system-setting-display/GET_DATA',
  SET_DATA: 'system-setting-display/SET_DATA',

  SET_STATUS: 'system-setting-display/SET_STATUS',
  SET_ERRORS: 'system-setting-display/SET_ERRORS',

  UPDATE_VISION: 'system-setting-display/UPDATE_VISION',
  UPDATE_RCV: 'system-setting-display/UPDATE_RCV',
};
