import helper from 'store/helper';
import types from './types';

const requestStart = () => helper.createAction(types.REQUEST_START);
const requestFailed = (error) => helper.createAction(types.REQUEST_FAILED, error);
const fetchSucceeded = (data) => helper.createAction(types.FETCH_SUCCEEDED, data);
const createSucceeded = (modalItems, sorting) => helper.createAction(types.CREATE_SUCCEEDED, { modalItems, sorting });
const fetch = (data) => helper.createAction(types.FETCH, data);
const create = (data) => helper.createAction(types.CREATE, data);

export default {
  requestStart,
  requestFailed,
  fetchSucceeded,
  createSucceeded,
  fetch,
  create,
};
