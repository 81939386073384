import FilterService from 'domain/FilterService';
import DisplayItemsAdManagementService, {
  AD_GROUP1,
  AD_GROUP2,
  AD_NOTE,
  AGENCY,
} from 'domain/settings/display-items-ad-management';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  adManagementFilterables,
  FILTER_KEY_ADMNG_DEVICE,
  FILTER_KEY_AGENCY,
} from 'services/consts';
import { masterDataSelectors } from 'store/master-data';
import { checkDenyFilterTerminalType } from 'services/ad-management/adManagementService';
import { getAgentFlag, checkHasContractAgent } from 'store/auth/selectors';
import { DisplayItemsSelectors } from 'store/display-items';
import { MEDIA_SIDE_ITEMS_AD_MANAGEMENT } from 'domain/consts';


const getTab = (state) => state.cache.adManagement.settings.tab;

const getFilters = (state) => state.settings.adManagement.filters.items;
const getDisplayItemsStatus = (state) =>
  state.settings.adManagement.displayItemsStatus;

const getAvailableFilters = createSelector(
  [getTab, masterDataSelectors.getDisplayForFilters, checkHasContractAgent],
  (mode, display, hasContractAgent) => {
    const filterables = { ...adManagementFilterables };

    // Not allow filter agency if has no contract agent
    if (!hasContractAgent) {
      filterables[FILTER_KEY_AGENCY].allowScreen = [];
    }

    return (
      _.chain(filterables)
        // Only pick allowed screen & mode
        .pickBy(
          (filter) =>
            !_.isEmpty(filter.allowScreen) &&
            (!filter.allowModes || filter.allowModes.includes(mode))
        )
        // Update title
        .mapValues((filter, key) => ({
          ...filter,
          title: display[key] || filter.title,
        }))
        .value()
    );
  }
);

const getFilterSettings = createSelector(
  [getTab, getFilters, getAvailableFilters],
  (mode, allFilters, availableFilters) => {
    return _.pickBy(allFilters, (filter, key) => {
      if (Object.keys(availableFilters).includes(key)) {
        const { allowModes } = availableFilters[key] || {};

        const denyFilterTerminalType = checkDenyFilterTerminalType(
          allFilters,
          mode
        );

        if (key === FILTER_KEY_ADMNG_DEVICE && denyFilterTerminalType) {
          return false;
        }

        return !allowModes || allowModes.includes(mode);
      }
      return false;
    });
  }
);

const getFiltersForApi = createSelector(
  [getFilterSettings],
  (filterSettings) => {
    return FilterService.prepareForApi(filterSettings);
  }
);

const getDisplayItems = createSelector(
  [
    getTab,
    masterDataSelectors.getDisplay,
    checkHasContractAgent,
    getAgentFlag,
    DisplayItemsSelectors.hasContractMediaSync,
  ],
  (
    mode,
    configDisplay,
    hasContractAgent,
    isAgentUser,
    hasContractMediaSync
  ) => {
    const items = DisplayItemsAdManagementService.getByMode(mode);

    // Change name based on config
    [AD_GROUP1, AD_GROUP2, AD_NOTE].forEach((key) => {
      if (_.get(items, key, false)) {
        items[key].title = configDisplay[key];
      }
    });

    // Not allow display agency if has no contract agent
    const isDisplayItemAgency = hasContractAgent || isAgentUser;
    if (!isDisplayItemAgency) {
      items[AGENCY].disabled = true;
    }

    if (!hasContractMediaSync) {
      MEDIA_SIDE_ITEMS_AD_MANAGEMENT.forEach((key) => {
        if (_.get(items, key, false)) {
          items[key].disabled = true;
        }
      });
    }

    return items;
  }
);

/**
 * Get user settings
 * @param state
 * @return {*}
 */
const getDisplayItemsSettings = (state) =>
  state.settings.adManagement.displayItems.items;

const getDisplayItemsDefault = createSelector(
  [getDisplayItems],
  (displayItems) => {
    return _.chain(displayItems)
      .pickBy((v) => !v.disabled && (v.required || v.displayDefault))
      .value();
  }
);

// TODO get settings from state or default. Also handle required
/**
 * Get user settings or default
 */
const getDisplayItemsSettingsOrDefault = createSelector(
  [getDisplayItemsSettings, getDisplayItemsDefault],
  (settings, defaultItems) => {
    const defaults = _.chain(defaultItems)
      .mapValues(() => true)
      .value();

    return { ...defaults, ...settings };
  }
);

const getDisplayItemsForDefaultsModal = createSelector(
  [getDisplayItemsDefault, getDisplayItems],
  (defaults, displayItems) => {
    return _.chain(displayItems)
      .pickBy((v) => !v.disabled)
      .mapValues((v, k) => ({
        title: v.title,
        required: v.required,
        checked: !!defaults[k],
      }))
      .value();
  }
);

const getDisplayItemsForSettingsModal = createSelector(
  [getDisplayItemsSettingsOrDefault, getDisplayItems],
  (settings, displayItems) => {
    return _.chain(displayItems)
      .pickBy((v) => !v.disabled)
      .mapValues((v, k) => ({
        title: v.title,
        required: v.required,
        checked: !!settings[k],
      }))
      .value();
  }
);

const getDisplayItemsForTable = createSelector(
  [getDisplayItemsSettingsOrDefault, getDisplayItems],
  (settings, displayItems) => {
    return _.chain(displayItems)
      .pickBy((v, k) => !v.disabled && settings[k])
      .value();
  }
);

const adManagementSettingsSelectors = {
  getFilters, // all filters
  /**
   * Get settings for current tab, omit disabled items
   */
  getFilterSettings,
  getAvailableFilters,
  getFiltersForApi,
  getDisplayItems, // all display items
  getDisplayItemsSettingsOrDefault,
  getDisplayItemsSettings,
  getDisplayItemsForDefaultsModal,
  getDisplayItemsForSettingsModal,
  getDisplayItemsStatus,
  getDisplayItemsForTable,
};

export default adManagementSettingsSelectors;
