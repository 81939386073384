import React from 'react';
import { string } from 'prop-types';
import iconDrag from 'assets/images/icon_drag.svg';
import './drag-drop-item-default.scss';

function DragDropItemDefault({ name }) {
  return (
    <div className="drag-drop-item-default">
      <img src={iconDrag} alt="icon-drag" />
      <span>{name ?? ''}</span>
    </div>
  );
}

DragDropItemDefault.propTypes = {
  name: string.isRequired,
};

DragDropItemDefault.defaultProps = {};

export default DragDropItemDefault;
