import React, { useState } from 'react';
import { func, bool, shape } from 'prop-types';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getErrorMessageByCode } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';

import { validateBasicSettingForm } from 'services/system-setting/systemSettingServices';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import systemSettingActions from 'store/system-setting/actions';
import systemSettingSelectors from 'store/system-setting/selectors';
import TableConfirmDetail from 'views/organism/TableConfirmDetail';
import { COLUMNS_TABLE_CONFIRM_CONTENT_INVALID } from 'services/consts';

import {
  INFO_FORM_BASIC_SETTING as FORM,
  HOST_NAME,
  EMERGENCY_URL,
  TRAFFIC_CONTROL_FLAG,
  ITP_FLAG,
  CROSS_DOMAIN_FLAG,
  CROSS_DOMAIN_LIST,
} from 'domain/system-setting/consts';
import { FALSE_FLAG, TRUE_FLAG } from 'domain/consts';
import SettingModal from './Modal';

function BasicSettingModal(props) {
  const {
    isShow,
    onHide,
    isLoading,
    data,
    isAllowedSettingBasic,
    isCapiSingleContract,
  } = props;

  const dispatch = useDispatch();
  const { crossDomainList } = useSelector(
    systemSettingSelectors.getStatesBasicSetting
  );

  const [valueForm, setValueForm] = useState(data);
  const [errorForm, setErrorForm] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorsLine, setErrorsLine] = useState({
    errorsLineMessage: [],
    errorMainMessage: null,
  });

  const handleResponseGetTld = (crossDomains) => {
    if (crossDomains.split(/[\n,]+/).length < 2) {
      setValueForm((prevValueForm) => ({
        ...prevValueForm,
        [CROSS_DOMAIN_FLAG]: false,
      }));
    }
  };

  const getTld = (value) =>
    dispatch(
      systemSettingActions.getCrossDomainList(
        {
          host_name: value,
        },
        handleResponseGetTld
      )
    );

  const isDisabledCrossDomainFlag =
    crossDomainList.split(/[\n,]+/).length < 2 ||
    valueForm[HOST_NAME].trim().split(/[\n,]+/).length < 2;

  const handleChangeValue = (name, newValue) => {
    const isHostName = name === HOST_NAME;
    const isEnableCrossDomain = name === CROSS_DOMAIN_FLAG && newValue;
    if (isHostName || isEnableCrossDomain) {
      getTld(
        isHostName
          ? newValue.replaceAll('\n', ',')
          : valueForm[HOST_NAME].replaceAll('\n', ',')
      );
    }
    if (isHostName && newValue.trim().split(/[\n,]+/).length < 2) {
      setValueForm((prevValueForm) => ({
        ...prevValueForm,
        [CROSS_DOMAIN_FLAG]: false,
      }));
    }
    setValueForm((prevValueForm) => ({
      ...prevValueForm,
      [name]: newValue,
    }));
    setErrorForm((prevErrorForm) => ({
      ...prevErrorForm,
      [name]: null,
    }));
  };

  const handleChangeRadio = (value, name) => {
    const isEnable = value === TRUE_FLAG;
    handleChangeValue(name, isEnable);
  };

  // Set error message in per line
  const setMessageErrorLine = (arrError = []) => {
    setErrorsLine({
      errorMainMessage: getErrorMessageByCode(
        { code: 'ERROR_LINE_MAIN_MESSAGE' },
        messageError,
        { length: arrError.length, label: FORM[HOST_NAME].label }
      ),
      errorsLineMessage: arrError,
    });
  };

  const handleResponseUpdate = ({ errors }) => {
    setIsUpdating(false);
    if (isEmpty(errors)) {
      onHide();
    } else {
      const errorByLine = [];
      errors.forEach((error) => {
        if (error.code === 'URL_CHECK') {
          const index = error.field.split('.')[1];
          errorByLine.push({
            line: `${index}行目`,
            message: getErrorMessageByCode(
              { code: 'URL_CHECK' },
              messageError,
              {
                label: '計測対象サイト',
              }
            ),
          });
        }
      });
      if (isEmpty(errorByLine)) {
        const errorResponse = getErrorMessageByCode(errors[0], messageError, {
          label: '計測対象サイト',
        });
        setErrorForm({ host_name: errorResponse });
        setErrorsLine({ errorsLineMessage: [], errorMainMessage: null });
      } else {
        setErrorForm({});
        setMessageErrorLine(errorByLine);
      }
    }
  };

  const handleSubmit = () => {
    const { errors, errorsByLine, isShowOnTable } = validateBasicSettingForm({
      host_name: valueForm.host_name.trim()
        ? valueForm[HOST_NAME].replaceAll('\n', ',')
        : valueForm[HOST_NAME].replaceAll('\n', ' '),
    });

    if (!isEmpty(errors) || !isEmpty(errorsByLine)) {
      if (isShowOnTable) {
        setErrorForm({});
        setMessageErrorLine(errorsByLine);
      } else {
        setErrorsLine({ errorsLineMessage: [], errorMainMessage: null });
        setErrorForm(errors);
      }
    } else {
      setIsUpdating(true);
      dispatch(
        systemSettingActions.updateBasicSetting(
          {
            [HOST_NAME]: valueForm[HOST_NAME].replaceAll('\n', ','),
            [EMERGENCY_URL]: valueForm[EMERGENCY_URL],
            [ITP_FLAG]: isCapiSingleContract ? false : valueForm[ITP_FLAG],
            [TRAFFIC_CONTROL_FLAG]: valueForm[TRAFFIC_CONTROL_FLAG] || false,
            [CROSS_DOMAIN_FLAG]: valueForm[CROSS_DOMAIN_FLAG],
          },
          handleResponseUpdate
        )
      );
    }
  };

  return (
    <SettingModal
      loading={isLoading || isUpdating}
      isShow={isShow}
      onSubmit={handleSubmit}
      onHide={onHide}
      title="基本設定を編集"
      dialogClassName="basic-setting-modal"
    >
      <div>
        {!isEmpty(errorsLine.errorsLineMessage) && (
          <div className="error-table-container">
            <p className="color-bad error-message">
              {errorsLine.errorMainMessage}
            </p>
            <TableConfirmDetail
              maxContent={297}
              columns={COLUMNS_TABLE_CONFIRM_CONTENT_INVALID}
              rows={errorsLine.errorsLineMessage}
            />
          </div>
        )}
        <FormGroup
          label={FORM[HOST_NAME].label}
          note={FORM[HOST_NAME].note}
          styleError="keep-position"
          required
        >
          <FormInput
            as="textarea"
            name={HOST_NAME}
            value={valueForm[HOST_NAME].replaceAll(',', '\r\n')}
            placeholder={FORM[HOST_NAME].placeholder}
            error={errorForm?.[HOST_NAME]}
            onChange={handleChangeValue}
          />
        </FormGroup>
        {!isCapiSingleContract && (
          <FormGroup
            label={FORM[EMERGENCY_URL].label}
            note={FORM[EMERGENCY_URL].note}
          >
            <FormInput
              name={EMERGENCY_URL}
              value={valueForm?.[HOST_NAME].split(/[\n,]+/)[0] || ''}
              error={errorForm?.[EMERGENCY_URL]}
              onChange={handleChangeValue}
              placeholder=""
              disabled
            />
          </FormGroup>
        )}
        {isAllowedSettingBasic && !isCapiSingleContract && (
          <FormGroup
            label={FORM[TRAFFIC_CONTROL_FLAG].label}
            note={FORM[TRAFFIC_CONTROL_FLAG].note}
          >
            <EbisRadio
              name={TRAFFIC_CONTROL_FLAG}
              label="有効"
              value={TRUE_FLAG}
              checked={valueForm[TRAFFIC_CONTROL_FLAG]}
              onChange={handleChangeRadio}
            />
            <EbisRadio
              name={TRAFFIC_CONTROL_FLAG}
              label="無効"
              value={FALSE_FLAG}
              checked={!valueForm[TRAFFIC_CONTROL_FLAG]}
              onChange={handleChangeRadio}
            />
          </FormGroup>
        )}
        {!isCapiSingleContract && (
          <FormGroup
            label={FORM[ITP_FLAG].label}
            tooltip={FORM[ITP_FLAG].tooltip}
            note={FORM[ITP_FLAG].note}
            tooltipStayOpen
          >
            <EbisRadio
              name={ITP_FLAG}
              label="3rd Party Cookieで計測する"
              value={FALSE_FLAG}
              checked={!valueForm[ITP_FLAG]}
              onChange={handleChangeRadio}
            />
            <EbisRadio
              name={ITP_FLAG}
              label="1st Party Cookieで計測する（一部のブラウザのみ）"
              value={TRUE_FLAG}
              checked={valueForm[ITP_FLAG]}
              onChange={handleChangeRadio}
            />
          </FormGroup>
        )}
        <FormGroup
          label={FORM[CROSS_DOMAIN_FLAG].label}
          tooltip={FORM[CROSS_DOMAIN_FLAG].tooltip}
          note={FORM[CROSS_DOMAIN_FLAG].note}
        >
          <EbisRadio
            name={CROSS_DOMAIN_FLAG}
            value={TRUE_FLAG}
            label="有効"
            disabled={isDisabledCrossDomainFlag}
            checked={valueForm[CROSS_DOMAIN_FLAG]}
            onChange={handleChangeRadio}
          />
          <EbisRadio
            name={CROSS_DOMAIN_FLAG}
            label="無効"
            value={FALSE_FLAG}
            checked={!valueForm[CROSS_DOMAIN_FLAG]}
            onChange={handleChangeRadio}
          />
        </FormGroup>
        <FormGroup
          label={FORM[CROSS_DOMAIN_LIST].label}
          tooltip={FORM[CROSS_DOMAIN_LIST].tooltip}
          note={FORM[CROSS_DOMAIN_LIST].note}
        >
          <textarea
            className="basic-setting__textarea"
            name={CROSS_DOMAIN_LIST}
            disabled={
              !valueForm[CROSS_DOMAIN_FLAG] || isDisabledCrossDomainFlag
            }
            value={
              valueForm[CROSS_DOMAIN_FLAG]
                ? crossDomainList.replaceAll(',', '\r\n')
                : ''
            }
            readOnly
          />
        </FormGroup>
      </div>
    </SettingModal>
  );
}

BasicSettingModal.propTypes = {
  isShow: bool.isRequired,
  onHide: func.isRequired,
  isLoading: bool.isRequired,
  data: shape({}).isRequired,
  isAllowedSettingBasic: bool.isRequired,
  isCapiSingleContract: bool.isRequired,
};

export default BasicSettingModal;
