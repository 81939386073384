import React, { useCallback, useMemo, useRef } from 'react';
import { arrayOf, string, func } from 'prop-types';
import {
  GridTable,
  GridTableItem,
  GridTableHeaderWrapper,
  GridTableBodyWrapper,
  GridTableHeader,
  GridTableRow,
  GridTableSeparator,
} from 'views/organism/GridTable';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import { formatUsersData } from 'services/data-export/dataExportService';
import { HEADER_USER, REPORT_FIELDS } from 'domain/data-export/consts';
import iconusers from 'assets/images/icon-users.svg';

const { SHARED_USERS } = REPORT_FIELDS;

const ShareUserSetting = (props) => {
  const { users, selected, error, onChange } = props;
  const ref = useRef();

  const usersFormatted = useMemo(() => formatUsersData(users), [users]);

  const handleSelectRow = useCallback(
    (ids) => {
      onChange(SHARED_USERS, ids);
    },
    [onChange]
  );

  return (
    <div className="data-export-setting__item mb-30" ref={ref}>
      <div className="data-export-setting__title">共有ユーザー設定</div>
      <ErrorTooltipWrapper isError={!!error} errorMess={error} ref={ref}>
        <div className="data-export-setting__content mb-10">
          <GridTable
            isApplyTableScrolling
            isTableCenter
            maxHeight={261}
            header={HEADER_USER}
            rows={usersFormatted}
            rowsSelected={selected}
            onSelectRow={handleSelectRow}
          >
            <GridTableItem isFreeze>
              <GridTableHeaderWrapper>
                <GridTableHeader />
              </GridTableHeaderWrapper>
              <GridTableBodyWrapper>
                <GridTableRow />
              </GridTableBodyWrapper>
            </GridTableItem>
            <GridTableSeparator />
            <GridTableItem>
              <GridTableHeaderWrapper>
                <GridTableHeader />
              </GridTableHeaderWrapper>
              <GridTableBodyWrapper>
                <GridTableRow />
              </GridTableBodyWrapper>
            </GridTableItem>
          </GridTable>
        </div>
      </ErrorTooltipWrapper>
      <div className="data-export-setting__note">
        <p className="d-flex align-items-center">
          <img src={iconusers} alt="" />
          <span className="color-primary">
            複数メールアドレスが登録されています。カーソルを合わせるとすべてのメールアドレスがご確認いただけます。
          </span>
        </p>
        <p>
          ※閲覧権限にかかわらず、レポート内容は共有ユーザーにすべて表示されます。
        </p>
        <p>※管理者ユーザーは常に共有対象となります。</p>
      </div>
    </div>
  );
};

ShareUserSetting.propTypes = {
  users: arrayOf({}),
  selected: arrayOf(string),
  error: string,
  onChange: func,
};

ShareUserSetting.defaultProps = {
  users: [],
  selected: [],
  error: '',
  onChange: () => {},
};

export default React.memo(ShareUserSetting);
