import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import isObject from 'lodash/isObject';
import { FormContext } from 'views/molecules/Form';

const SetInnerHTML = ({ content }) => {
  if (isObject(content)) {
    return <Popover.Content>{content}</Popover.Content>;
  }
  return <Popover.Content dangerouslySetInnerHTML={{ __html: content }} />;
};

SetInnerHTML.propTypes = {
  content: PropTypes.string,
};
SetInnerHTML.defaultProps = {
  content: undefined,
};

const scrollIntoView = (ref) => {
  ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
};

const ErrorTooltipWrapper = React.forwardRef(
  ({ children, isError, errorMess }, ref) => {
    const { dispatch, isSubmit, isApplyFormWrapper } = useContext(FormContext);

    const [isShow, toggleShow] = useState(false);
    const [container, setContainer] = useState(document.body);

    const innerRef = React.useRef(null);

    useEffect(() => {
      if (ref && ref.current) {
        setContainer(ref.current);
      }
    }, [ref]);

    useEffect(() => {
      toggleShow(isError);
    }, [isError]);

    useEffect(() => {
      const scrollRef = innerRef?.current ? innerRef : ref;
      if (!isError || !scrollRef?.current) return;
      if (!isApplyFormWrapper) {
        scrollIntoView(scrollRef);
      } else if (isSubmit) {
        scrollIntoView(scrollRef);
        dispatch({ type: 'setSubmit', payload: false });
      }
    });

    return (
      <OverlayTrigger
        show={isShow && !!errorMess}
        trigger=""
        container={container}
        overlay={
          <Popover className="is-error" transition="Fade">
            <SetInnerHTML content={errorMess} />
          </Popover>
        }
      >
        {/* {React.cloneElement(children, {
          onMouseEnter: () => isError && toggleShow(true),
          onMouseLeave: () => isError && toggleShow(false),
        })} */}
        {/* {children} */}
        {React.cloneElement(children, {
          ref: children.ref ? children.ref : innerRef,
        })}
      </OverlayTrigger>
    );
  }
);

ErrorTooltipWrapper.defaultProps = {
  errorMess: undefined,
};

ErrorTooltipWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMess: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ErrorTooltipWrapper;
