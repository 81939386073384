import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  arrayOf,
  func,
  number,
  oneOf,
  shape,
  string,
  bool,
  oneOfType,
  object,
} from 'prop-types';
import BelowPanel from 'views/atoms/BelowPanel';
import './panel.scss';

import isEmpty from 'lodash/isEmpty';
import Validator, {
  validateNParisValueMinMax,
  validateInRange,
  validateTwoValuesMinMax,
} from 'services/validations';
import { MASTER_DATA_FILTER_LIMIT, Metric } from 'domain/consts';
import FilterService from 'domain/FilterService';
import {
  FILTER_KEY_AD_ID,
  FILTER_KEY_AD_ID_EXCLUDE,
  FILTER_KEY_AD_NAME,
  FILTER_KEY_AD_NAME_EXCLUDE,
  FILTER_KEY_MEMBER_NAME,
  FILTER_KEY_MEMBER_NAME_EXCLUDE,
  FILTER_KEY_OTHER1,
  FILTER_KEY_OTHER1_EXCLUDE,
  FILTER_KEY_OTHER2,
  FILTER_KEY_OTHER2_EXCLUDE,
  FILTER_KEY_OTHER3,
  FILTER_KEY_OTHER3_EXCLUDE,
  FILTER_KEY_OTHER4,
  FILTER_KEY_OTHER4_EXCLUDE,
  FILTER_KEY_OTHER5,
  FILTER_KEY_OTHER5_EXCLUDE,
  FILTER_KEY_SEARCH_WORD,
  FILTER_KEY_SEARCH_WORD_EXCLUDE,
  FILTER_KEY_USER_ID,
  FILTER_KEY_AD_NOTE,
  FILTER_KEY_LINK_URL,
  FILTER_KEY_LANDING_PAGE_URL,
  FILTER_KEY_CHANNEL_DISPLAY,
  FILTER_KEY_CHANNEL_DISPLAY_RANGE,
  ONLY_MEDIA_SYNC_AXIS_FILTERS,
  FILTER_KEY_TARGET_EXPIREDAYS,
} from 'services/consts';
import * as pages from 'services/routes/constants';
import MediaSyncIcon from 'views/atoms/MediaSyncIcon/MediaSyncIcon';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import FilterType from './FilterTypes';
import PanelWrapper from './PanelWrapper';

const ruleObject = {
  [FILTER_KEY_USER_ID]: { max: 1000 },
  [FILTER_KEY_MEMBER_NAME]: { max: 50 },
  [FILTER_KEY_MEMBER_NAME_EXCLUDE]: { max: 50 },
  [FILTER_KEY_OTHER1]: { max: 200 },
  [FILTER_KEY_OTHER1_EXCLUDE]: { max: 200 },
  [FILTER_KEY_OTHER2]: { max: 200 },
  [FILTER_KEY_OTHER2_EXCLUDE]: { max: 200 },
  [FILTER_KEY_OTHER3]: { max: 200 },
  [FILTER_KEY_OTHER3_EXCLUDE]: { max: 200 },
  [FILTER_KEY_OTHER4]: { max: 200 },
  [FILTER_KEY_OTHER4_EXCLUDE]: { max: 200 },
  [FILTER_KEY_OTHER5]: { max: 200 },
  [FILTER_KEY_OTHER5_EXCLUDE]: { max: 200 },
  [FILTER_KEY_AD_ID]: { max: 10000 },
  [FILTER_KEY_AD_ID_EXCLUDE]: { max: 10000 },
  [FILTER_KEY_AD_NAME]: { max: 300 },
  [FILTER_KEY_AD_NAME_EXCLUDE]: { max: 300 },
  [FILTER_KEY_SEARCH_WORD]: { max: 300 },
  [FILTER_KEY_SEARCH_WORD_EXCLUDE]: { max: 300 },
  [FILTER_KEY_AD_NOTE]: { max: 400 },
  [FILTER_KEY_LINK_URL]: { max: 2083 },
  [FILTER_KEY_LANDING_PAGE_URL]: { title: 'URL', max: 300 },
};

const transformValuesType8 = (data, values) => {
  Object.keys(values).forEach((key) => {
    const value = values[key];
    data[key] = {
      min: value.min === '' ? null : Number.parseInt(value.min, 10),
      max: value.max === '' ? null : Number.parseInt(value.max, 10),
    };
  });
};

// transform values for validation
const transformValues = (key, values, type, pageId, filterInfo) => {
  const data = {};

  switch (key) {
    case Metric.amount.key: {
      const [amountMin, amountMax] = values;
      if (values[0]) data.amount_min = amountMin;
      if (values[1]) data.amount_max = amountMax;

      data[Metric.amount.key] = values;
      break;
    }
    default:
      data[key] = values;
      break;
  }

  switch (type) {
    case 2:
      if (filterInfo.multiple && !isEmpty(values)) {
        values.forEach((item, index) => {
          data[`${key}_${index}`] = item.values.join(',');
        });
      }
      data[key] = values.join(',');
      break;
    case 3:
      if (
        pageId === pages.CV_ATTRIBUTE &&
        (key === FILTER_KEY_CHANNEL_DISPLAY ||
          key === FILTER_KEY_CHANNEL_DISPLAY_RANGE)
      ) {
        transformValuesType8(data, values);
      } else {
        data[key] = values;
      }
      break;
    case 8:
      transformValuesType8(data, values);
      break;
    default:
      data[key] = values;
      break;
  }

  return data;
};

const rulesObject = (key, values, filterInfo) => {
  const { multiple = false } = filterInfo;
  const title = ruleObject[key]?.title || filterInfo.title;

  switch (key) {
    case FILTER_KEY_USER_ID:
    case FILTER_KEY_MEMBER_NAME:
    case FILTER_KEY_MEMBER_NAME_EXCLUDE:
    case FILTER_KEY_OTHER1:
    case FILTER_KEY_OTHER1_EXCLUDE:
    case FILTER_KEY_OTHER2:
    case FILTER_KEY_OTHER2_EXCLUDE:
    case FILTER_KEY_OTHER3:
    case FILTER_KEY_OTHER3_EXCLUDE:
    case FILTER_KEY_OTHER4:
    case FILTER_KEY_OTHER4_EXCLUDE:
    case FILTER_KEY_OTHER5:
    case FILTER_KEY_OTHER5_EXCLUDE:
    case FILTER_KEY_AD_ID:
    case FILTER_KEY_AD_ID_EXCLUDE:
    case FILTER_KEY_AD_NAME:
    case FILTER_KEY_AD_NAME_EXCLUDE:
    case FILTER_KEY_AD_NOTE:
    case FILTER_KEY_LINK_URL:
    case FILTER_KEY_SEARCH_WORD:
    case FILTER_KEY_SEARCH_WORD_EXCLUDE:
    case FILTER_KEY_LANDING_PAGE_URL: {
      let keys = [key];
      if (multiple && !isEmpty(values)) {
        keys = values.reduce(
          (acc, _, index) => [...acc, `${key}_${index}`],
          []
        );
      }

      return keys.reduce((acc, field) => {
        return [
          ...acc,
          {
            field,
            method: 'isEmpty',
            validWhen: false,
            messageParams: [title],
          },
          {
            field,
            method: 'isLength',
            args: [{ max: ruleObject[key].max }],
            validWhen: true,
            messageParams: [title, ruleObject[key].max],
          },
        ];
      }, []);
    }

    case Metric.amount.key:
      return [
        {
          field: Metric.amount.key,
          method: validateTwoValuesMinMax(),
          validWhen: true,
          methodName: 'valueMinMax',
          messageParams: ['売上金額'],
        },
        {
          field: 'amount_min',
          method: 'isLength',
          args: [{ max: 12 }],
          validWhen: true,
          messageParams: [Metric.amount.title, '12'],
        },
        {
          field: 'amount_max',
          method: 'isLength',
          args: [{ max: 12 }],
          validWhen: true,
          messageParams: [Metric.amount.title, '12'],
        },
      ];

    case FILTER_KEY_TARGET_EXPIREDAYS:
      return [
        {
          field: FILTER_KEY_TARGET_EXPIREDAYS,
          method: validateInRange(),
          args: [{ min: 1, max: 366 }],
          validWhen: true,
          message: 'トラッキング対象期間は半角英数の整数で入力してください',
        },
      ];

    default:
      return [];
  }
};

function NewDisplayChannelRules() {
  const channels = [10, 20, 30, 40];
  return channels.map((value) => {
    return {
      field: value,
      method: validateNParisValueMinMax(),
      validWhen: true,
      methodName: 'valueMinMax',
      messageParams: ['チャネル回数'],
    };
  });
}

function Panel(props) {
  const {
    pageId,
    name,
    title,
    onCancel,
    onSubmit,
    type,
    items,
    presets,
    show,
    filterKey,
    defaultProps,
    maxItemDisplay,
    maxItemSelect,
    filters,
    filterInfo,
    status,
    tooltip,
    onApplyClick,
    onChangeFilter,
    disabledApply,
    customView,
    selectedFilters,
    filterableMenuList,
  } = props;
  const menuEl = useRef(null);
  const panelEl = useRef(null);

  const [toggleMenu, setToggleMenu] = useState(show);
  const [toggleNext, setToggleNext] = useState(type === 8);
  const [mode, setMode] = useState(0);
  const [filter, setFilter] = useState(null);

  const nextButtonEnabled = () => {
    switch (type) {
      case 8: {
        const values = filter?.ids || filter?.values;
        return values && values.length > 0 && values.length <= maxItemSelect;
      }
      default:
        return false;
    }
  };

  const [error, setError] = useState(null);
  let validator = new Validator(
    rulesObject(filterKey, filter?.values, filterInfo)
  );
  if (
    (name === FILTER_KEY_CHANNEL_DISPLAY ||
      name === FILTER_KEY_CHANNEL_DISPLAY_RANGE) &&
    pageId === pages.CV_ATTRIBUTE
  ) {
    validator = new Validator(NewDisplayChannelRules());
  }

  // Clear error when panel close
  useEffect(() => {
    if (toggleMenu === false) setError('');
  }, [toggleMenu]);

  const clearError = useCallback((value = null) => {
    setError(() => value);
  }, []);

  const onNextClick = () => {
    switch (type) {
      case 8:
        setMode(1);
        setToggleNext(false);
        clearError();
        break;
      default:
        break;
    }
  };

  const onBackClick = () => {
    switch (type) {
      case 8:
        setMode(0);
        setToggleNext(true);
        clearError();
        break;
      default:
        break;
    }
  };

  const handleAddtionalClick = () => {
    const santizedFilter = { ...filter };

    const validated = validator.validate(
      transformValues(
        filterKey,
        santizedFilter.values,
        type,
        pageId,
        filterInfo
      )
    );

    if (!isEmpty(validated)) {
      setError(validated);
    } else {
      onSubmit(santizedFilter);
    }
  };

  /**
   * @param {{type:number,values[],option:any}} data
   */
  const updateFilter = useCallback(
    (data) => {
      const filterUpdate = {
        ...data,
        title,
      };
      setFilter(filterUpdate);

      const validated = validator.validate(
        transformValues(
          filterKey,
          filterUpdate.values,
          type,
          pageId,
          filterInfo
        )
      );

      if (!isEmpty(validated)) {
        setError(validated);
      }
      if (!customView) {
        onChangeFilter(filterKey, filterUpdate);
      }
    },
    [filterInfo, filterKey, pageId, title, type]
  );

  const handleApply = () => {
    if (filter) {
      const validated = validator.validate(
        transformValues(filterKey, filter.values, type, pageId, filterInfo)
      );

      if (!isEmpty(validated)) {
        setError(validated);
        return;
      }
    }
    onApplyClick(filterKey, filter);
  };

  const isHideOkBtn = useMemo(() => {
    if (!customView) {
      // if the filter list select exist 1 filter => will be hide the Ok btn
      const filtersSelectionList = Object.keys(filterableMenuList);
      if (
        filtersSelectionList.length === 1 &&
        filtersSelectionList[0] === filterKey
      )
        return true;
      return Object.keys(selectedFilters).includes(filterKey);
    }
    return false;
  }, [customView, filterKey, filterableMenuList, selectedFilters]);

  const onCancelClick = () => {
    onCancel(filter);
  };

  const memorizedSetError = useCallback((value) => {
    setError(() => value);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuEl && !menuEl.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="panel"
      ref={menuEl}
      style={{ display: toggleMenu ? 'block' : 'none' }}
    >
      <div className="panel__title" ref={panelEl}>
        <PanelWrapper ref={panelEl}>
          <div className="panel__title_text">{title}</div>
        </PanelWrapper>
        {ONLY_MEDIA_SYNC_AXIS_FILTERS.includes(name) ? (
          <MediaSyncIcon className="panel__title--media-sync-icon" />
        ) : null}
        {!!tooltip && (
          <DragonBall variant="title" icon="far fa-question-circle" stayOpen>
            {tooltip}
          </DragonBall>
        )}
      </div>
      <div id="filter-panel-tooltip" style={{ display: 'none' }} />
      <div className="panel__content">
        {toggleMenu && (
          <FilterType
            pageId={pageId}
            name={name}
            type={type}
            items={items}
            mode={mode}
            onUpdate={updateFilter}
            presets={presets}
            error={error}
            clearError={clearError}
            setError={memorizedSetError}
            defaultProps={defaultProps}
            maxItemDisplay={maxItemDisplay}
            maxItemSelect={maxItemSelect}
            filters={filters}
            filterInfo={filterInfo}
            status={status}
          />
        )}
      </div>
      <div className="panel__footer">
        {toggleNext ? (
          <BelowPanel
            okText="次へ"
            onOkClick={onNextClick}
            onCancelClick={onCancelClick}
            okDisabled={!nextButtonEnabled()}
            disabledApply={disabledApply}
            onApply={handleApply}
            customView={customView}
            isHideOkBtn={isHideOkBtn}
          />
        ) : (
          <BelowPanel
            showBack={type === 8}
            onBackClick={onBackClick}
            onOkClick={handleAddtionalClick}
            onCancelClick={onCancelClick}
            okDisabled={
              !FilterService.getEnabledOkButton({
                type,
                filter,
                pageId,
                name,
                presets,
                maxItemSelect,
                filterInfo,
              })
            }
            disabledApply={disabledApply}
            onApply={handleApply}
            okText={customView ? 'OK' : '条件を追加'}
            variant={customView ? 'secondary' : 'link'}
            customView={customView}
            isHideOkBtn={isHideOkBtn}
          />
        )}
      </div>
    </div>
  );
}

Panel.propTypes = {
  pageId: string,
  name: string.isRequired,
  title: string.isRequired,
  type: oneOf([0, 1, 2, 3, 4, 5]).isRequired,
  onSubmit: func,
  onCancel: func,
  items: arrayOf(shape({ key: string, value: string })).isRequired,
  presets: shape({ values: arrayOf(string), option: oneOf([string, number]) }),
  show: bool,
  filterKey: string.isRequired,
  defaultProps: oneOfType([object]),
  maxItemDisplay: number,
  maxItemSelect: number,
  filters: shape({}),
  filterInfo: oneOfType([object]).isRequired,
  status: string,
  tooltip: string,
  onApplyClick: func,
  onChangeFilter: func,
  disabledApply: bool,
  customView: bool,
  selectedFilters: shape({}),
  filterableMenuList: shape({}),
};

Panel.defaultProps = {
  pageId: '',
  onSubmit: () => {},
  onCancel: () => {},
  presets: {},
  defaultProps: {},
  show: false,
  maxItemDisplay: MASTER_DATA_FILTER_LIMIT,
  maxItemSelect: MASTER_DATA_FILTER_LIMIT,
  filters: shape({}),
  status: '',
  tooltip: '',
  onApplyClick: () => {},
  onChangeFilter: () => {},
  disabledApply: false,
  customView: false,
  selectedFilters: {},
  filterableMenuList: {},
};

export default Panel;
