import React, { useState, useMemo } from 'react';
import { func, bool, shape, arrayOf, string } from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import {
  DOMAIN_NAME,
  REDIRECT_FLAG,
  EMAIL_NOTIFICATION,
  SEND_MAIL_USERS,
} from 'domain/fields';
import {
  FIELD_EMAIL_SETTING_FORM,
  STEP_SETTING_DNS_SUBDOMAIN,
  STEP_SETTING_DNS_EMAIL,
  STEP_CONFIRM,
  STEP_SETTING_DNS_EMAIL_EDIT,
} from 'domain/system-setting/consts';

import { getEmail, getUserId } from 'store/auth/selectors';
import systemSettingActions from 'store/system-setting/actions';
import TableModal from 'views/organism/TableModal';

import {
  formatDataDnsRequest,
  getTitleDnsSetting,
} from 'services/system-setting/systemSettingServices';
import * as messageError from 'services/validations/messageErrorByCode';
import { SETTINGS_SPECIFICATIONS_SITE } from 'services/routes/constants';
import { getErrorMessageByCode } from 'services/utils';
import ConfirmModal from  'views/molecules/modal/ConfirmModal';
import SettingModal from './Modal';

const COLUMN_DNS_CONFIRM = [
  { name: 'value', title: 'ドメイン' },
  { name: 'redirect', title: 'リダイレクト計測' },
  { name: 'send_mail', title: 'アラートメール' },
];

const RowItems = (props) => {
  const { rows } = props;
  return (
    <>
      {rows.map((row, index) => {
        const keyIndex = index;
        return (
          <tr key={keyIndex}>
            {COLUMN_DNS_CONFIRM.map((column) => {
              if (column.name === 'value') {
                return (
                  <td>
                    <div>
                      <span>
                        {row.domain_name &&
                          Object.keys(row.domain_name).map(key => (
                            <div>{row.domain_name[key]}</div>
                          ))
                        }
                      </span>
                    </div>
                  </td>
                );
              }
              if (column.name === 'redirect') {
                return (
                  <td>
                    <div>
                      <span>
                        {row.redirect_flag}
                      </span>
                    </div>
                  </td>
                );
              }
              return (
                <td>
                  <div>
                    {row.send_mail_flag}
                  </div>
                  <div>
                    {row.send_mail_flag === "する" && (
                      <>
                        <span>
                          {row.send_mail_address}
                        </span>
                      </>
                    )}
                  </div>
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

RowItems.propTypes = {
  rows: arrayOf(shape({})).isRequired,
};

function DnsSettingConfirmModal(props) {
  const {
    isShow,
    onHide,
    isLoading,
    isAddEmailMode,
    tab,
    setCurrentStep,
    steps,
    isEdit,
    dnsList,
    selectedItems,
    domainFormValue,
    mailFormValue,
    userMasterEmailData,
    hasUserEditPermission,
    errorForm,
    setErrorForm,
    errorMainMessage,
    setErrorMainMessage,
  } = props;
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);
  const [isOpenErrorModal, setOpenErrorModal] = useState(false);
  const [errorContent, setErrorContent] = useState({});
  const email = useSelector(getEmail, shallowEqual);
  const userId = useSelector(getUserId, shallowEqual);

  const onHideConfirmModal = (status) => setOpenConfirmModal(status);
  const handleFocus = (event) => event.target.select();

  const handleResponse = ({ errors, isError }) => {
    setErrorMainMessage('');
    if (!isError) {
      onHide();
      dispatch(systemSettingActions.setStatusScreenComplete(true));
    } else if (isEmpty(errors)) {
      onHide();
    } else {
      if (['MAX_REGISTERED_DOMAINS_CHECK'].includes(errors[0].code)) {
        setIsCreating(false);
        setErrorMainMessage(
          `${errors[0].metadata}件までの計測対象サイトに対してDNS登録が可能です。`
        );
      } else if (['REQUIRED_CHECK'].includes(errors[0].code)) {
        setIsCreating(false);
        if (errors[0].field === "domains") {
          setErrorMainMessage(
            'ドメインを入力してください。'
          );
        } else if (errors[0].field === "email_authentication") {
          setErrorMainMessage(
            'メール審査で予期せぬエラーが発生しました。'
          );
        }
      } else if (['TYPE_CHECK'].includes(errors[0].code) || ['ELEMENT_TYPE_CHECK'].includes(errors[0].code)) {
        setIsCreating(false);
        if (errors[0].field === "domains") {
          setErrorMainMessage(
            'ドメインの形式が不正です。'
          );
        } else if (errors[0].field === "email_authentication") {
          setErrorMainMessage(
            'メール審査の形式が不正です。'
          );
        }
      } else if (['MAX_ENTRIES_PER_REGIST_CHECK'].includes(errors[0].code)) {
        setIsCreating(false);
        if (errors[0].field === "domains") {
          setErrorMainMessage(
            `一度に登録ができるドメインの上限は${errors[0].metadata.param}件までです。`
          );
        }
      }
      const listEtld1 = dnsList.map(item =>
        item.etld1
      );
      const EmailSettingFormFields = [REDIRECT_FLAG, EMAIL_NOTIFICATION, SEND_MAIL_USERS];
      const errorResponse = errors.reduce(
        (acc, error) => {
          let labelValue = "対象カラム";
          if (listEtld1.includes(error.field) || error.field === "domains") {
            labelValue = 'ドメイン';
          } else if (error.field === "email_authentication") {
            labelValue = 'メール審査';
          } else if (EmailSettingFormFields.includes(error.field)) {
            labelValue = FIELD_EMAIL_SETTING_FORM[error.field].label;
          }
          return {
            ...acc,
            [error.field]: getErrorMessageByCode(error, messageError, {label: labelValue})
          }
        },
        {}
      );
      setErrorForm(errorResponse);
      let targetStep = 0;
      if (!isEdit) {
        // Priority: 3.STEP_CONFIRM > 1.STEP_SETTING_DNS_SUBDOMAIN > 2.STEP_SETTING_EMAIL
        errors.forEach(function(item) {
          if (targetStep < STEP_SETTING_DNS_SUBDOMAIN && (listEtld1.includes(item.field) || item.field === "domains")) {
            targetStep = STEP_SETTING_DNS_SUBDOMAIN;
          } else {
            targetStep = STEP_CONFIRM;
          }
        });
      }
      if (!isEdit && targetStep === STEP_SETTING_DNS_SUBDOMAIN) {
        setCurrentStep(STEP_SETTING_DNS_SUBDOMAIN);
      } else {
        const errorFormValue = {};
        if (errorMainMessage !== "") Object.assign(errorFormValue, errorMainMessage);
        if (errorResponse !== "") Object.assign(errorFormValue, errorResponse);
        Object.assign(errorFormValue, errors);
        setErrorContent(errorFormValue);
        setErrorMainMessage('');
        setErrorForm({});
        setOpenErrorModal(true);
      }
      setIsCreating(false);
    }
  };

  const handleBack = (isEdit) => {
    if (!isEdit) {
      setCurrentStep(STEP_SETTING_DNS_EMAIL);
    } else {
      setCurrentStep(STEP_SETTING_DNS_EMAIL_EDIT);
    }
  }

  const handleSubmit = (isEdit) => {
    if (!isEdit) {
      const dataValidate = Object.entries(domainFormValue).filter(
        ([, value]) => !isEmpty(value.trim())
      );
      if (isAddEmailMode) {
        setOpenConfirmModal(true);
      } else {
        setIsCreating(true);
        dispatch(
          systemSettingActions.addDnsSetting(
            formatDataDnsRequest(dataValidate, mailFormValue, tab, isAddEmailMode),
            handleResponse
          )
        );
      }
    } else {
      setIsCreating(true);
      const etld1Values = selectedItems.map(item =>
        item.etld1
      );
      const data = {
        // etld1: [mailFormValue[ETLD1].value],
        etld1: etld1Values,
        redirect_flag: mailFormValue[REDIRECT_FLAG].value,
        send_mail_flag: mailFormValue[EMAIL_NOTIFICATION].value,
        send_mail_users: mailFormValue[SEND_MAIL_USERS].value,
      }
      dispatch(
        systemSettingActions.updateDnsSetting(data, handleResponse)
      );
    }
  };

  const handleSubmitAfterCorfirm = () => {
    setOpenConfirmModal(false);
    setIsCreating(true);
    const dataValidate = Object.entries(domainFormValue).filter(
      ([, value]) => !isEmpty(value.trim())
    );
    dispatch(
      systemSettingActions.addDnsSetting(
        formatDataDnsRequest(dataValidate, mailFormValue, tab, isAddEmailMode),
        handleResponse
      )
    );
  };

  const getSendMailUsers = (sendMailUsers) => {
    return Object.keys(sendMailUsers).map(user => {
      if (hasUserEditPermission) {
        const sendMailAddress = Object.keys(userMasterEmailData.users)
          .filter(key =>
            userMasterEmailData.users[key].user_id === sendMailUsers[user]
          )
          .map(key =>
            userMasterEmailData.users[key].email
          )
        return (
          <div className="dns-setting-email-settings">
            {sendMailUsers[user]}：<br />
            {sendMailAddress.join("<br />")}
          </div>
        )
      }
      if (sendMailUsers[user] === userId) {
        return (
          <div className="dns-setting-email-settings">
            {userId}：<br />
            {email}
          </div>
        )
      }
      return (
        <></>
      )
    });
  }

  const rows = useMemo(() => {
    return [mailFormValue].map((item) => ({
      domain_name: item[DOMAIN_NAME].value,
      redirect_flag: item[REDIRECT_FLAG].value ? 'する' : 'しない',
      send_mail_flag: item[EMAIL_NOTIFICATION].value ? 'する' : 'しない',
      send_mail_users: item[SEND_MAIL_USERS].value,
      send_mail_address: getSendMailUsers(item[SEND_MAIL_USERS].value),
      // error: errorForm?.[item.etld1] || '',
      error: errorForm || '',
    }));
  }, [mailFormValue, errorForm]);

  return (
    <>
      <SettingModal
        loading={isLoading || isCreating}
        cancelButtonText="戻る"
        isShow={isShow}
        onSubmit={() => handleSubmit(isEdit)}
        onHide={() => handleBack(isEdit)}
        title={getTitleDnsSetting(tab, isAddEmailMode, isEdit)}
        steps={steps}
        dialogClassName="dns-setting-modal dns-confirm-modal"
        elementBelowLoading={
          isEdit ? (
            <>
              更新中…
            </>
          ) : (
            <>
              証明書審査用レコード
              <br /> 取得中…
            </>
          )
        }
      >
        <div className="dns-description">
          <div>
            下記内容でよろしければOKボタンを押してください。
          </div>
        </div>
        <div>
          {errorMainMessage && (
            <div className="dns-error__main-message">{errorMainMessage}</div>
          )}
          <TableModal
            header={
              <tr>
                {COLUMN_DNS_CONFIRM.map((column) => (
                  <th>{column.title}</th>
                ))}
              </tr>
            }
            body={
              <RowItems
                columns={COLUMN_DNS_CONFIRM}
                rows={rows}
              />
            }
            maxContent={258}
            alignScroll={-20}
          />
        </div>
      </SettingModal>
      {isOpenConfirmModal && (
        <ConfirmModal
          isShow={isOpenConfirmModal}
          title="入力されたドメイン宛てにメールを送信します"
          content="ドメイン管理者様へのご確認はお済みですか？"
          confirmText="OK"
          cancelText="キャンセル"
          cancelBtn
          onHide={onHideConfirmModal}
          onSubmit={() => handleSubmitAfterCorfirm()}
        />
      )}
      {isOpenErrorModal && (
        <ConfirmModal
          isShow={isOpenErrorModal}
          title="システムエラーが発生しました"
          jsxContent={
            <>
              <div className="error-title">
                もう一度お試しいただいてもエラーが発生する場合は、下記エラーメッセージを記載の上、
                <a
                  href={SETTINGS_SPECIFICATIONS_SITE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  サポートセンター
                </a>
                にお問い合わせください。
              </div>
              <Form.Group className="mb-0" controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  as="textarea"
                  className="error-content"
                  rows="4"
                  value={JSON.stringify(errorContent)}
                  onChange={() => {}}
                  onFocus={handleFocus}
                />
              </Form.Group>
            </>
          }
          confirmText="OK"
          cancelBtn={false}
          onSubmit={() => setOpenErrorModal(false)}
        />
      )}
    </>
  );
}

DnsSettingConfirmModal.propTypes = {
  isShow: bool.isRequired,
  onHide: func.isRequired,
  isLoading: bool.isRequired,
  isAddEmailMode: bool.isRequired,
  tab: string.isRequired,
  setCurrentStep: func.isRequired,
  steps: arrayOf({}),
  isEdit: bool,
  dnsList: arrayOf({}),
  selectedItems: arrayOf({}),
  domainFormValue: arrayOf({}),
  mailFormValue: arrayOf({}),
  userMasterEmailData: arrayOf({}),
  hasUserEditPermission: bool,
  errorForm: arrayOf({}),
  setErrorForm: arrayOf({}),
  errorMainMessage: string,
  setErrorMainMessage: func.isRequired,
};

DnsSettingConfirmModal.defaultProps = {
  steps: [],
  isEdit: false,
  dnsList: [],
  selectedItems: [],
  domainFormValue: [],
  mailFormValue: [],
  userMasterEmailData: [],
  hasUserEditPermission: false,
  errorForm: [],
  setErrorForm: [],
  errorMainMessage: '',
};

export default DnsSettingConfirmModal;
