import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { bool, func, number, string } from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';
import iconCautionYellow from 'assets/images/icon-caution-yellow.svg';

import {
  CAPI_PAUSE_FLAG,
  ACTIVE_FLAG,
  BATCH_PAUSE_FLAG,
  USER_PAUSE_FLAG,
  OVER_LIMIT_FLAG,
} from 'domain/capi-setting/consts';

const PAUSE_WARNING = [BATCH_PAUSE_FLAG, OVER_LIMIT_FLAG];

const Item = ({ icon = '', title = '' }) => (
  <>
    <img src={icon} alt="icon" />
    <span>{title}</span>
  </>
);

Item.propTypes = {
  icon: string.isRequired,
  title: string.isRequired,
};

function PauseComponent({ id, onChangeStatus, defaultValue }) {
  const [status, setStatus] = useState(defaultValue);
  const [activeMenu, showActiveMenu] = useState(false);

  const handleShowActiveMenu = () => {
    showActiveMenu(!activeMenu);
  };

  const handleChangeStatus = (value) => () => {
    if (value !== status) {
      setStatus(value);
      onChangeStatus(id, value);
    }
    showActiveMenu(!activeMenu);
  };

  const clsName = classNames({
    'capi-dropdown-select': true,
    active: activeMenu,
  });

  const target = useRef();

  if (
    ![...PAUSE_WARNING, ACTIVE_FLAG, USER_PAUSE_FLAG].includes(defaultValue)
  ) {
    return '';
  }

  return (
    <div className="capi-dropdown">
      {PAUSE_WARNING.includes(defaultValue) ? (
        <div className="capi-status">
          <img src={iconCautionYellow} alt="icon-caution" />
          <span>自動停止中</span>
        </div>
      ) : (
        <OverlayTrigger
          placement="bottom-start"
          target={target}
          show={activeMenu}
          trigger="click"
          overlay={
            <div className="capi-menu">
              {Object.keys(CAPI_PAUSE_FLAG).map((keyFlag) => {
                const { title, icon, value } = CAPI_PAUSE_FLAG[keyFlag];
                return (
                  <div
                    className="capi-menu-item"
                    onClick={handleChangeStatus(value)}
                    aria-hidden="true"
                  >
                    <Item icon={icon} title={title} />
                  </div>
                );
              })}
            </div>
          }
        >
          <div
            className={clsName}
            role="button"
            onClick={handleShowActiveMenu}
            aria-hidden="true"
          >
            <div className="dropdown-label">
              <Item
                icon={CAPI_PAUSE_FLAG[status]?.icon}
                title={`${CAPI_PAUSE_FLAG[status]?.title}中`}
              />
            </div>
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
}

PauseComponent.propTypes = {
  id: number.isRequired,
  onChangeStatus: func.isRequired,
  defaultValue: bool.isRequired,
};

export default PauseComponent;
