import { DATE_RANGE_TYPE } from 'domain/consts';
import moment from 'moment';
import { getComparedPeriodDates, getCurrentPeriodDates } from 'services/utils';
import types from 'store/common/types';

import constants from 'store/common/constant';

export const initialState = {
  isShowNavbar: true,
  apiStatus: {
    status: 'idle',
    error: null,
  },
  settings: {
    period: {
      start: moment().subtract(1, 'day').startOf('day'),
      end: moment().subtract(1, 'day').startOf('day'),
      preset: DATE_RANGE_TYPE.YESTERDAY,
    },
    comparedPeriod: {
      // start: moment().subtract(14, 'day'),
      // end: moment().subtract(8, 'day'),
      start: null,
      end: null,
      enabled: false,
      preset: DATE_RANGE_TYPE.LAST_PERIOD,
    },
    transitionModal: {
      isOpen: false,
      headerText: null,
      imgSrc: null,
      externalLink: null,
      isNewTab: false,
      disabled: false,
    },
    download: {
      [constants.CSV]: {
        status: false,
        enabled: false,
      },
      [constants.PNG]: {
        status: false,
        enabled: false,
      },
      [constants.JPEG]: {
        status: false,
        enabled: false,
      },
      [constants.ALLCSV]: {
        status: false,
        enabled: false,
      },
    },
  },
  data: {
    downloadNotify: {
      value: '',
      pageTransitionLinkTitle: null,
      pageTransitionTarget: null,
    },
  },
  // For current status of the tutorial modal
  // Temperary solve the conflicts with news modal when start page
  tutorial: {
    modal: false,
  },
  user: {},
  location: {
    parentUrl: '',
    fullPath: '',
    prevPath: '',
    screenId: '',
  },
};

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COMMON_DATA:
      return {
        ...state,
        isShowNavbar: action.payload,
      };
    case types.CHANGE_CURRENT_URL_LOCATION:
      if (action.payload.fullPath === state.location.fullPath) return state;

      return {
        ...state,
        location: {
          parentUrl: action.payload.parentUrl,
          fullPath: action.payload.fullPath,
          prevPath: state.location.fullPath,
          screenId: action.payload.screenId,
        },
      };
    case types.OPEN_TRANSITION_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          transitionModal: {
            isOpen: true,
            headerText: action.payload.headerText,
            imgSrc: action.payload.imgSrc,
            externalLink: action.payload.externalLink,
            isNewTab: action.payload.isNewTab,
            disabled: state.settings.transitionModal.disabled,
            disabledHome: state.settings.transitionModal.disabledHome,
          },
        },
      };
    case types.CLOSE_TRANSITION_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          transitionModal: {
            ...state.settings.transitionModal,
            isOpen: false,
            disabled: action.payload.transitionToOldScreenDisabled,
          },
        },
      };
    case types.CLOSE_TRANSITION_MODAL_HOME:
      return {
        ...state,
        settings: {
          ...state.settings,
          transitionModal: {
            ...state.settings.transitionModal,
            isOpen: false,
            disabledHome: action.payload.transitionToOldScreenDisabledHome,
          },
        },
      };
    case types.CLOSE_TRANSITION_MODAL_LISTING:
      return {
        ...state,
        settings: {
          ...state.settings,
          transitionModal: {
            ...state.settings.transitionModal,
            isOpen: false,
          },
        },
      };
    case types.UPDATE_PERIOD_WITH_COMPARED: {
      const { period, comparedPeriod } = action.payload;
      const calculatedCurrentPeriod = getCurrentPeriodDates(period);
      const calculatedComparedPeriod = getComparedPeriodDates(
        comparedPeriod,
        calculatedCurrentPeriod
      );
      return {
        ...state,
        settings: {
          ...state.settings,
          period: calculatedCurrentPeriod,
          comparedPeriod: calculatedComparedPeriod,
        },
      };
    }
    case types.TOGGLE_DOWNLOAD: {
      const {
        csv = state.settings.download.csv,
        png = state.settings.download.png,
        jpeg = state.settings.download.jpeg,
        allcsv = state.settings.download.allcsv,
      } = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          download: {
            [constants.CSV]: csv,
            [constants.PNG]: png,
            [constants.JPEG]: jpeg,
            [constants.ALLCSV]: allcsv,
          },
        },
      };
    }
    case types.SET_DOWNLOAD_NOTIFY:
      return {
        ...state,
        data: {
          ...state.data,
          downloadNotify: {
            value: action.payload?.value || action.payload,
            pageTransitionLinkTitle: action.payload?.pageTransitionLinkTitle,
            pageTransitionTarget: action.payload?.pageTransitionTarget,
          },
        },
      };
    case types.SET_DOWNLOAD_STATUS: {
      const {
        status = state.apiStatus.status,
        error = state.apiStatus.error,
      } = action.payload;

      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          status,
          error,
        },
      };
    }
    case types.DOWNLOAD_CSV_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.TOGGLE_MODAL_STATUS: {
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          modal: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default CommonReducer;
