import React, {
  useContext,
  useRef,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import {
  oneOfType,
  bool,
  node,
  func,
  number,
  shape,
  arrayOf,
  string,
} from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {
  GridTableContext,
  GridTableItemContext,
} from 'views/organism/GridTable/GridTableContext';
import {
  GridTableItemReducer,
  initialItemState,
} from 'views/organism/GridTable/GridTableReducer';
import GridTableScrollbarWrapper from 'views/organism/GridTable/GridTableScrollbarWrapper';
import {
  getHeader,
  getHeaderForBody,
  getTemplate,
  getKeyTable,
  getWidthTable,
  checkHasOtherTable,
} from './GridTableService';

function GridTableItem(props) {
  const {
    children,
    isFreeze,
    width,
    templateColumn,
    sortColumn,
    tooltips,
    filters,
    onResizeTable,
    onResizeColumn,
    onSortColumn,
  } = props;
  const {
    dispatch,
    header,
    isEmptyData,
    isResizable,
    isSortable,
    gridTableRef,
    settings: { tableInfo },
  } = useContext(GridTableContext);

  const widthTable = tableInfo[getKeyTable(isFreeze)].widthTable || width;

  const {
    headers,
    headersForBody,
    hasOtherTable,
    isFreezeTable,
    gridTemplateColumns,
    gridTemplateRows,
  } = useMemo(() => {
    const otherTable = checkHasOtherTable(header, isFreeze);
    const commonHeaders = getHeader(
      header,
      isFreeze,
      isResizable,
      isSortable
    ).map((item) => {
      return {
        ...item,
        isFiltered: filters.includes(item.field),
      };
    });
    return {
      headers: commonHeaders,
      headersForBody: getHeaderForBody(commonHeaders),
      hasOtherTable: otherTable,
      isFreezeTable: otherTable && isFreeze,
      ...getTemplate(header, isFreeze, isEmptyData),
    };
  }, [header, isFreeze, isResizable, isSortable, isEmptyData, filters]);

  const [attrs, dispatchItem] = useReducer(GridTableItemReducer, {
    ...initialItemState,
    gridTemplateRows,
    gridTemplateColumns: Object.keys(gridTemplateColumns).reduce((acc, key) => {
      return {
        ...acc,
        [key]: templateColumn[key] || gridTemplateColumns[key],
      };
    }, {}),
  });

  const gridTableItemRef = useRef(null);

  const valueContext = useMemo(
    () => ({
      dispatchItem,
      onSortColumn,
      isFreeze,
      sortColumn,
      tooltips,
      headers,
      headersForBody,
      gridTableItemRef,
      ...attrs,
    }),
    [
      onSortColumn,
      isFreeze,
      sortColumn,
      tooltips,
      headers,
      headersForBody,
      attrs,
    ]
  );

  const wrrapperClass = classNames({
    'grid-table__wrapper': true,
    'grid-table__wrapper--maxwidth': isFreezeTable && !widthTable,
    'grid-table__wrapper--freeze': isFreezeTable,
    'grid-table__wrapper--main': !isFreeze && hasOtherTable,
  });

  // Set width for each table
  useEffect(() => {
    if (!gridTableRef?.current || !gridTableItemRef?.current) return;
    const tableWrapperEl = gridTableItemRef.current;
    const gridTableEl = gridTableRef.current;
    const currentWidth = getWidthTable(
      tableWrapperEl.offsetWidth,
      gridTableEl.clientWidth,
      isFreeze,
      !!widthTable
    );

    dispatch({
      type: 'setInfoTable',
      payload: { isFreeze, info: { widthTable: currentWidth } },
    });
  }, [dispatch, onResizeTable, isFreeze, gridTableRef, widthTable]);

  useEffect(() => {
    return () => {
      // Save data to store || last column always set width auto according to table width
      const { field } = headers.find((item) => item.isLastColumn);
      onResizeTable(isFreeze, widthTable);
      onResizeColumn(omit(attrs.gridTemplateColumns, field));
    };
  }, [
    attrs.gridTemplateColumns,
    isFreeze,
    onResizeColumn,
    onResizeTable,
    widthTable,
    headers,
  ]);

  return (
    <GridTableItemContext.Provider value={valueContext}>
      <div
        ref={gridTableItemRef}
        className={wrrapperClass}
        style={{
          width: isFreezeTable && isResizable ? widthTable : null,
        }}
      >
        <GridTableScrollbarWrapper widthTable={widthTable}>
          {children}
        </GridTableScrollbarWrapper>
      </div>
    </GridTableItemContext.Provider>
  );
}

GridTableItem.propTypes = {
  children: oneOfType([node]).isRequired,
  isFreeze: bool,
  width: number,
  templateColumn: shape({}),
  tooltips: shape({}),
  filters: arrayOf(string),
  sortColumn: shape({}),
  onResizeTable: func,
  onResizeColumn: func,
  onSortColumn: oneOfType([func, bool]),
};

GridTableItem.defaultProps = {
  isFreeze: false,
  width: null,
  templateColumn: {},
  tooltips: {},
  filters: [],
  sortColumn: { field: '', direction: '' },
  onResizeTable: () => {},
  onResizeColumn: () => {},
  onSortColumn: () => {},
};

export default React.memo(GridTableItem);
