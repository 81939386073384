import React from 'react';
import { connect } from 'react-redux';
import UserManagementBar from 'views/molecules/commonbar/components/UserManagementBar';
import userActions from 'store/user-management/actions';
import { userManagementSelectors } from 'store/user-management';

// eslint-disable-next-line react/jsx-props-no-spreading
const UserManagementBarContainer = (props) => <UserManagementBar {...props} />;

const mapStateToProps = (state) => ({
  currentUsers: userManagementSelectors.getCurrentUserNumber(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleShowHideModal: (params) =>
    dispatch(userActions.handleShowHideModal(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementBarContainer);
