import {
  SETTING_AD_MEDIA_EDIT,
  SETTING_AD_AD_GROUP1_EDIT,
  SETTING_AD_AD_GROUP2_EDIT,
} from 'domain/permissions/contractPermissions';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';

const useCheckPermissionMediaGroup = () => {
  const { checkPermissions } = useCheckPermissions();

  return {
    hasPemissionMedia: checkPermissions('anyOf', [SETTING_AD_MEDIA_EDIT]),
    hasPemissionAdgroup1: checkPermissions('anyOf', [
      SETTING_AD_AD_GROUP1_EDIT,
    ]),
    hasPemissionAdgroup2: checkPermissions('anyOf', [
      SETTING_AD_AD_GROUP2_EDIT,
    ]),
  };
};

export default useCheckPermissionMediaGroup;
