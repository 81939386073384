import types from 'store/dashboard/types';
import helper from 'store/helper';

const fetchDataAll = (channel) => ({
  type: types.FETCH_DATA_ALL,
  payload: channel,
});

const fetchDataMetadata = () => ({
  type: types.FETCH_DATA_METADATA,
});

const fetchDataMetadataSuccess = (data) => ({
  type: types.FETCH_DATA_METADATA_SUCCESS,
  payload: data,
});

const fetchDataMetadataError = (error, scope) => ({
  type: types.FETCH_DATA_METADATA_ERROR,
  payload: error,
  scope,
});

const fetchDataStatictis = () => ({
  type: types.FETCH_DATA_STATICTIS,
});

const fetchDataStatictisSuccess = (data) => ({
  type: types.FETCH_DATA_STATICTIS_SUCCESS,
  payload: data,
});

const fetchDataStatictisError = (error, scope) => ({
  type: types.FETCH_DATA_STATICTIS_ERROR,
  payload: error,
  scope,
});

const fetchDataChart = () => ({
  type: types.FETCH_DATA_CHART,
});

const fetchDataChartSuccess = (data) => ({
  type: types.FETCH_DATA_CHART_SUCCESS,
  payload: data,
});

const fetchDataChartError = (error, scope) => ({
  type: types.FETCH_DATA_CHART_ERROR,
  payload: error,
  scope,
});

const fetchDataReport = () => ({
  type: types.FETCH_DATA_REPORT,
});

const fetchDataReportSuccess = (data) => ({
  type: types.FETCH_DATA_REPORT_SUCCESS,
  payload: data,
});

const fetchDataReportError = (error, scope) => ({
  type: types.FETCH_DATA_REPORT_ERROR,
  payload: error,
  scope,
});

const fetchDataCompare = (startDate, endDate) => ({
  type: types.FETCH_DATA_COMPARE,
  payload: {
    start_date: startDate,
    end_date: endDate,
  },
});

const fetchDataCompareSuccess = (data) => ({
  type: types.FETCH_DATA_COMPARE_SUCCESS,
  payload: data,
});

const fetchDataCompareError = (error, scope) => ({
  type: types.FETCH_DATA_COMPARE_ERROR,
  payload: error,
  scope,
});

const fetchDataKpiStatus = (type, data, scope) => ({
  type: types.FETCH_DATA_KPI_STATUS,
  payload: {
    type,
    data,
  },
  scope,
});

const changeSelectedAxisLine = (selectedValue) => ({
  type: types.CHANGE_SELECTED_YAXISLINE,
  payload: selectedValue,
});

const changeSelectedAxisColumn = (selectedValue) => ({
  type: types.CHANGE_SELECTED_YAXISCOLUMN,
  payload: selectedValue,
});

const changeComparePeriod = (selectedValue) => ({
  type: types.CHANGE_SELECTED_COMPARE,
  payload: selectedValue,
});

const changeTargetPeriod = (selectedValue) => ({
  type: types.CHANGE_SELECTED_TARGET,
  payload: selectedValue,
});

const addIndicator = (data, scope) => ({
  type: types.ADD_INDICATOR,
  payload: data,
  scope,
});

const addIndicatorSuccess = (data) => ({
  type: types.ADD_INDICATOR_SUCCESS,
  payload: data,
});

const addIndicatorError = (error, scope) => ({
  type: types.ADD_INDICATOR_ERROR,
  payload: error,
  scope,
});

const editIndicator = (data, pickupId) => ({
  type: types.EDIT_INDICATOR,
  payload: {
    data,
    pickup_id: pickupId,
  },
});

const editIndicatorSuccess = (data, pickupId) => ({
  type: types.EDIT_INDICATOR_SUCCESS,
  payload: {
    data,
    pickup_id: pickupId,
  },
});

const editIndicatorError = (error, scope) => ({
  type: types.EDIT_INDICATOR_ERROR,
  payload: error,
  scope,
});

const deleteIndicator = (pickupId) => ({
  type: types.DELETE_INDICATOR,
  payload: pickupId,
});

const deleteIndicatorSuccess = (pickupId) => ({
  type: types.DELETE_INDICATOR_SUCCESS,
  payload: pickupId,
});

const deleteIndicatorError = (error, scope) => ({
  type: types.DELETE_INDICATOR_ERROR,
  payload: error,
  scope,
});

const updateDataChart = (pickupIdNew, pickupIdOld, isLoading) => ({
  type: types.UPDATE_DATA_CHART,
  payload: {
    pickup_id_new: pickupIdNew,
    pickup_id_old: pickupIdOld,
    loading: isLoading,
  },
});

const updateDataChartSuccess = (data, pickupId) => ({
  type: types.UPDATE_DATA_CHART_SUCCESS,
  payload: {
    data,
    pickup_id: pickupId,
  },
});

const updateDataChartError = (error, scope) => ({
  type: types.UPDATE_DATA_CHART_ERROR,
  payload: error,
  scope,
});

const updateDataReport = (pickupId) => ({
  type: types.UPDATE_DATA_REPORT,
  payload: pickupId,
});

const updateDataReportSuccess = (data) => ({
  type: types.UPDATE_DATA_REPORT_SUCCESS,
  payload: data,
});

const updateDataReportError = (error, scope) => ({
  type: types.UPDATE_DATA_REPORT_ERROR,
  payload: error,
  scope,
});

const updateDataBorder = (data) => ({
  type: types.UPDATE_DATA_BORDER,
  payload: data,
});

const updateDataBorderSuccess = (data) => ({
  type: types.UPDATE_DATA_BORDER_SUCCESS,
  payload: data,
});

const updateDataBorderError = (error, scope) => ({
  type: types.UPDATE_DATA_BORDER_ERROR,
  payload: error,
  scope,
});

const showKpiStatusModal = (isShow) => ({
  type: types.SHOW_KPI_STATUS_MODAL,
  payload: isShow,
});

const showSettingMailModal = (isShow) => ({
  type: types.SHOW_SETTING_MAIL_MODAL,
  payload: isShow,
});

const getSettingMailInfo = () =>
  helper.createAction(types.GET_SETTING_MAIL_INFO);

const setStatusSettingMail = (status) =>
  helper.createAction(types.SET_STATUS_SETTING_MAIL, { status });

const setErrorSettingMail = (errors, scope) =>
  helper.createAction(types.SET_ERROR_SETTING_MAIL, { errors }, scope);

const setDataSettingMail = (data) =>
  helper.createAction(types.SET_DATA_SETTING_MAIL, { data });

const getSettingMailError = (error, scope) => ({
  type: types.GET_SETTING_MAIL_ERROR,
  payload: error,
  scope,
});

const saveSettingMail = (data, callback) =>
  helper.createAction(types.SAVE_SETTING_MAIL, { data, callback });
const updateSettingMail = (data) =>
  helper.createAction(types.UPDATE_SETTING_MAIL, { data });

const getListUsers = () => helper.createAction(types.GET_LIST_USERS);
const setListUsers = (data) =>
  helper.createAction(types.SET_LIST_USERS, { data });
const setStatusListUsers = (status) =>
  helper.createAction(types.SET_STATUS_LIST_USER, { status });

export default {
  fetchDataAll,
  fetchDataMetadata,
  fetchDataMetadataSuccess,
  fetchDataMetadataError,
  fetchDataStatictis,
  fetchDataStatictisSuccess,
  fetchDataStatictisError,
  fetchDataChart,
  fetchDataChartSuccess,
  fetchDataChartError,
  fetchDataReport,
  fetchDataReportSuccess,
  fetchDataReportError,
  fetchDataCompare,
  fetchDataCompareSuccess,
  fetchDataCompareError,
  fetchDataKpiStatus,
  changeSelectedAxisLine,
  changeSelectedAxisColumn,
  changeComparePeriod,
  changeTargetPeriod,
  addIndicator,
  addIndicatorSuccess,
  addIndicatorError,
  editIndicator,
  editIndicatorSuccess,
  editIndicatorError,
  deleteIndicator,
  deleteIndicatorSuccess,
  deleteIndicatorError,
  updateDataChart,
  updateDataChartSuccess,
  updateDataChartError,
  updateDataReport,
  updateDataReportSuccess,
  updateDataReportError,
  updateDataBorder,
  updateDataBorderSuccess,
  updateDataBorderError,
  showKpiStatusModal,
  showSettingMailModal,
  getSettingMailInfo,
  setStatusSettingMail,
  setErrorSettingMail,
  setDataSettingMail,
  getSettingMailError,
  saveSettingMail,
  getListUsers,
  setListUsers,
  setStatusListUsers,
  updateSettingMail,
};
