import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { createTableHeaders } from 'services/utils';
import { createFieldsFromConversions } from 'domain/utils';
import { CV_METRICS } from 'domain/consts';
import * as FIELDS from 'domain/fields';
import * as DisplayItems from 'domain/settings/display-items';
import displayItemsSelectors from 'store/display-items/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import settingsSelectors from 'store/settings/selectors';
import FormatData from 'domain/cost-allocation/FormatData';

const COLUMN_GROUP_SIMULATION = 'simulation';
const overrideValue = '-';
const overrideFields = [
  DisplayItems.RCV_RATE,
  DisplayItems.PRODUCTIVITY_RCV,
  DisplayItems.RAMOUNT_RATE,
  DisplayItems.PRODUCTIVITY_AMOUNT,
  DisplayItems.COST_RATE,
];

const getSorts = (state) => state.cache.costAllocation.settings.table.sorts;
const getPagination = (state) =>
  state.cache.costAllocation.settings.table.pagination;
const getSimulation = (state) => state.cache.costAllocation.settings.simulation;
const getSelectedRows = (state) => state.cache.costAllocation.data.selectedRows;
const getDataTable = (state) => state.cache.costAllocation.data.dataTable;
const getDataFull = (state) => state.cache.costAllocation.data.dataFull.detail;
const getYAxisLine = (state) =>
  state.cache.costAllocation.settings.chart.yAxisLine;
const getSavedYAxis = (state) =>
  state.cache.costAllocation.settings.chart.savedYAxis;

const getStatusTable = (state) =>
  state.cache.costAllocation.apiStatus.table.status;

const getStatusChart = (state) =>
  state.cache.costAllocation.apiStatus.chart.status;

const getCvMetrics = (state) => {
  const conversions = masterDataSelectors.conversionsForDisplaySelector(state);
  const metricSelected = displayItemsSelectors.getMetricsSelector(state);

  return createFieldsFromConversions(
    conversions,
    metricSelected.filter((metric) => CV_METRICS.includes(metric))
  );
};

const getStatusApplySimulation = (state) =>
  FormatData.isApplySimulation(getSimulation(state));

const simulationFieldsSelector = createSelector(
  [getSimulation, getYAxisLine],
  (simulation, yAxisLine) => {
    const col1 = FIELDS.COL1;
    const col2 =
      simulation.type === 2 && yAxisLine === FIELDS.REALLOCATION_CV_RATIO
        ? FIELDS.COL2_NUM
        : FIELDS.COL2_COST;
    const col3 =
      simulation.type === 2 && yAxisLine === FIELDS.REALLOCATION_CV_RATIO
        ? FIELDS.COL3_NUM
        : FIELDS.COL3_COST;

    return [col1, col2, col3];
  }
);

const simulationItemsSelector = createSelector(
  [
    getSimulation,
    getYAxisLine,
    simulationFieldsSelector,
    getStatusApplySimulation,
  ],
  (simulation, yAxisLine, simulationFields, isApplySimulation) => {
    if (!isApplySimulation) return {};

    const [col1, col2, col3] = simulationFields;

    const simulationProductivity = {
      name: COLUMN_GROUP_SIMULATION,
      text: 'シミュレーション結果',
      isDimension: false,
      children: [
        {
          name: col1,
          text: '抑制対象',
          sort: false,
        },
        {
          name: col2,
          text: '抑制コスト',
          sort: false,
        },
        {
          name: col3,
          text: 'コスト差分',
          sort: false,
        },
      ],
    };

    const simulationLimitCost = {
      name: COLUMN_GROUP_SIMULATION,
      text: 'シミュレーション結果',
      isDimension: false,
      children: [
        {
          name: col1,
          text: '削減対象',
          sort: false,
        },
        {
          name: col2,
          sort: false,
          text:
            yAxisLine === FIELDS.REALLOCATION_CV_RATIO
              ? '減少再配分CV'
              : '減少再配分売上',
        },
        {
          name: col3,
          sort: false,
          text:
            yAxisLine === FIELDS.REALLOCATION_CV_RATIO
              ? '再配分CV差分'
              : '再配分売上差分',
        },
      ],
    };

    return simulation.type === 1 ? simulationProductivity : simulationLimitCost;
  }
);

const simulationDataSelector = createSelector(
  [
    getSelectedRows,
    getYAxisLine,
    getSimulation,
    getDataFull,
    simulationFieldsSelector,
  ],
  (selectedRows, yAxisLine, simulation, dataFull, simulationFields) => {
    const [col1, col2, col3] = simulationFields;

    // Re calculate sum data for simulation
    const sortByKey =
      yAxisLine === FIELDS.REALLOCATION_CV_RATIO
        ? FIELDS.RCV_COST_EFFECTIVENESS
        : FIELDS.RAMOUNT_COST_EFFECTIVENESS;
    const sortedFullData = FormatData.sortByKey(dataFull, sortByKey);

    return FormatData.getSimulationDataForTable(
      sortedFullData,
      selectedRows,
      yAxisLine,
      simulation,
      col1,
      col2,
      col3
    );
  }
);

// Get plotband value (from)for chart
const getPlotBandFromVal = createSelector(
  [simulationDataSelector, simulationFieldsSelector, getStatusApplySimulation],
  (simulationData, simulationFields, isApplySimulation) => {
    if (!isApplySimulation) return 0;

    return FormatData.getMinValue(
      simulationData,
      simulationFields[0],
      FIELDS.AD_COST_RATIO
    );
  }
);

const getDetailData = createSelector(
  [
    getDataTable,
    simulationDataSelector,
    simulationFieldsSelector,
    getStatusApplySimulation,
  ],
  (dataTable, simulationData, simulationFields, isApplySimulation) => {
    if (!isApplySimulation) return dataTable.detail;

    // map Sorted table, have simulation data to Rawtable
    return FormatData.mergeTable(
      simulationData,
      dataTable.detail,
      simulationFields[0],
      simulationFields[1],
      simulationFields[2]
    );
  }
);

const getSumData = createSelector(
  [
    getDataTable,
    simulationDataSelector,
    simulationFieldsSelector,
    getStatusApplySimulation,
  ],
  (dataTable, simulationData, simulationFields, isApplySimulation) => {
    const { sum } = dataTable;
    if (!isEmpty(sum)) {
      Object.keys(sum)
        .filter((key) => overrideFields.includes(key))
        .forEach((key) => {
          sum[key] = overrideValue;
        });
    }

    if (!isApplySimulation) return sum;

    return FormatData.getSimulationSumRow(
      sum,
      simulationData,
      simulationFields[1],
      simulationFields[2]
    );
  }
);

const getSimulationResult = createSelector(
  [
    getSumData,
    getSimulation,
    getYAxisLine,
    simulationFieldsSelector,
    getStatusApplySimulation,
  ],
  (sumData, simulation, yAxisLine, simulationFields, isApplySimulation) => {
    if (!isApplySimulation) return '';

    const decimals =
      yAxisLine === FIELDS.REALLOCATION_CV_RATIO && simulation.type === 2
        ? 2
        : 0;
    return FormatData.formatNum(sumData[simulationFields[1]], decimals);
  }
);

const dimensionsSelector = createSelector(
  [
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (dimensions, displayItems) =>
    displayItems
      .filter((item) => item.displayFreeze && dimensions.includes(item.key))
      .map((item) => item.key)
);

const metricsSelector = createSelector(
  [
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (metrics, displayItems) => {
    return displayItems
      .filter((item) => !item.displayFreeze && metrics.includes(item.key))
      .map((item) => item.key);
  }
);

const sortSelector = createSelector(
  [dimensionsSelector, metricsSelector, getCvMetrics, getSorts],
  (dimensions, metrics, cvMetrics, sorts) => {
    const [sort] = sorts;
    const newMetrics = [...metrics];
    cvMetrics
      .filter((cntDirect) => cntDirect.children.length > 0)
      .forEach((cntDirect) =>
        cntDirect.children.map((conversion) => newMetrics.push(conversion.name))
      );

    if (sort && dimensions.concat(newMetrics).includes(sort.field)) {
      return { sort: (sort.direction === 'asc' ? '' : '-') + sort.field };
    }

    return {};
  }
);

// Create header data
const dimensionsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const dimensions = displayItems
    .filter((item) => item.displayFreeze)
    .map((item) => {
      return {
        text: item.title,
        name: item.key,
      };
    });

  return dimensions;
};

const metricsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const cvMetrics = getCvMetrics(state);
  const simulationItems = simulationItemsSelector(state);

  const metrics = [];
  displayItems
    .filter((item) => !item.displayFreeze)
    .map((item) => {
      if (CV_METRICS.includes(item.key)) {
        metrics.push(...cvMetrics);
      } else {
        metrics.push({
          text: item.title,
          name: item.key,
        });
      }
      return metrics;
    });

  metrics.push(simulationItems);

  return metrics;
};

const groupsConfig = () => [
  {
    name: 'indirect',
    text: '間接効果',
    children: [
      DisplayItems.CNT_INDIRECT2,
      DisplayItems.CNT_INDIRECT3,
      DisplayItems.CNT_INDIRECT4,
      DisplayItems.CNT_INDIRECT5,
      DisplayItems.CNT_INDIRECT_OTHER,
      DisplayItems.INDIRECT_TOTAL,
    ],
  },
  {
    name: 'rate',
    text: '直間比率',
    children: [DisplayItems.DIRECT_RATE, DisplayItems.INDIRECT_RATE],
  },
];

const getConfig = (state) => {
  return {
    dimensions: dimensionsConfig(state),
    metrics: metricsConfig(state),
    groups: groupsConfig(),
  };
};

const headerSelector = createSelector(
  [
    settingsSelectors.getTab,
    dimensionsSelector,
    metricsSelector,
    getCvMetrics,
    getSorts,
    getConfig,
    getStatusApplySimulation,
  ],
  (
    channel,
    dimensions,
    metrics,
    cvMetrics,
    sorts,
    itemsConfig,
    isApplySimulation
  ) => {
    const newMetrics = [...metrics];
    if (isApplySimulation) {
      newMetrics.push(COLUMN_GROUP_SIMULATION);
    }

    return createTableHeaders({
      channel,
      dimensions,
      metrics: [...newMetrics, ...cvMetrics.map((cntDirect) => cntDirect.name)],
      sorts,
      itemsConfig,
    });
  }
);

export default {
  getSorts,
  getPagination,
  getSimulation,
  getSelectedRows,
  getYAxisLine,
  getSavedYAxis,
  getStatusTable,
  getStatusChart,
  getStatusApplySimulation,
  getDataFull,
  getDetailData,
  getSumData,
  getPlotBandFromVal,
  getSimulationResult,
  dimensionsSelector,
  metricsSelector,
  sortSelector,
  headerSelector,
};
