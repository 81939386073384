import { useState, useEffect, useCallback } from 'react';

const useCheckSwitchUser = () => {
  const [isSwitchUser, setSwitchUser] = useState(false);

  const handleSubmit = () => window.location.reload();

  const checkSwitchUser = useCallback(({ key }) => {
    if (key === 'code_verifier') {
      setSwitchUser(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('storage', checkSwitchUser);
    return () => window.removeEventListener('storage', checkSwitchUser);
  }, [checkSwitchUser]);

  return [isSwitchUser, handleSubmit];
};

export default useCheckSwitchUser;
