import * as ReflectionServiceConst from 'services/common/reflectiontime/constants';

const CostLinkServicesConfig = {
  [ReflectionServiceConst.GOOGLE]: {
    service: ReflectionServiceConst.GOOGLE,
    name: 'topbar.reflection_time.google',
  },
  [ReflectionServiceConst.FACEBOOK]: {
    service: ReflectionServiceConst.FACEBOOK,
    name: 'topbar.reflection_time.facebook',
  },
  [ReflectionServiceConst.YDN]: {
    service: ReflectionServiceConst.YDN,
    name: 'topbar.reflection_time.ydn',
  },
  [ReflectionServiceConst.YSS]: {
    service: ReflectionServiceConst.YSS,
    name: 'topbar.reflection_time.yss',
  },
  [ReflectionServiceConst.TWITTER]: {
    service: ReflectionServiceConst.TWITTER,
    name: 'topbar.reflection_time.twitter',
  },
  [ReflectionServiceConst.CRITEO]: {
    service: ReflectionServiceConst.CRITEO,
    name: 'topbar.reflection_time.criteo',
  },
};

const MediaSyncServicesConfig = {
  [ReflectionServiceConst.MEDIA_SYNC_GG]: 'topbar.reflection_time.media_sync_gg',
  [ReflectionServiceConst.MEDIA_SYNC_YS]: 'topbar.reflection_time.media_sync_ys',
  [ReflectionServiceConst.MEDIA_SYNC_YD]: 'topbar.reflection_time.media_sync_yd',
  [ReflectionServiceConst.MEDIA_SYNC_FB]: 'topbar.reflection_time.media_sync_fb',
  [ReflectionServiceConst.MEDIA_SYNC_LN]: 'topbar.reflection_time.media_sync_ln',
};

export { CostLinkServicesConfig, MediaSyncServicesConfig }
