import types from 'store/alerts/types';

const fetchAlertsStart = (type = null) => ({
  type: types.FETCH_ALERTS_START,
  payload: { type },
});

const fetchAlertsSuccess = (data, type = null) => ({
  type: types.FETCH_ALERTS_SUCCESS,
  payload: { type, data },
});

const fetchAlertsError = (error) => ({
  type: types.FETCH_ALERTS_ERROR,
  payload: error,
});

export default {
  fetchAlertsStart,
  fetchAlertsSuccess,
  fetchAlertsError,
};
