/* eslint-disable react/no-danger */
import ebisLogo from 'assets/images/logo-2.svg';
import { string } from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { removeNewLineTokens } from 'services/utils';
import Footer from 'views/molecules/footer';

import './maintenanceMode.scss';

function MaintenanceMode(props) {
  const { message } = props;

  const displayMessage = removeNewLineTokens(message);

  return (
    <div className="maintenance-page">
      <div className="maintenance__container">
        <div>
          <img height="70" src={ebisLogo} alt="AD Ebis Logo" />
        </div>
        <div className="maintenance-content">
          <div className="maintenance-content__header text-modal-title">
            システムメンテナンスのため、現在このページはご利用いただけません
          </div>
          <div
            className="maintenance-content__body"
            dangerouslySetInnerHTML={{ __html: displayMessage }}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

MaintenanceMode.propTypes = {
  message: string.isRequired,
};

export default MaintenanceMode;
