import isEmpty from 'lodash/isEmpty';
import { FilterConfig } from 'domain/commonbar/FilterConfig';
import { LOG_ROUTE_ANALYZE } from 'services/routes/constants';
import { FilterTypeTwoOptions, FILTER_OPERATOR_INCLUDE } from 'services/consts';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { RANK } from 'domain/settings/display-items';
import { CHANNEL_TYPE_ID } from 'domain/consts';

export const EXPORT_IMAGE_ROUTE = 'export-image-route';
export const LIMIT_EXPORT_IMAGE_ROUTE = 200;

export const PATTERN_DISPLAY_TOTURIAL = 1;
export const PATTERN_DISPLAY_TABLE = 2;
export const PATTERN_DISPLAY_ROUTE_ENTRANCE = 3;
export const PATTERN_DISPLAY_ROUTE_EXIT = 4;

export const CUSTOMVIEW_ROUTE_NAME = 'yrglm_route_analyze';
export const DISPLAY_RATE_MIN = 0;
export const DISPLAY_RATE_MAX = 99.99;

export const INFLOW_MODE_AD = 1;
export const INFLOW_MODE_URL = 2;
export const INFLOW_MODE_IGNORE = 3;
export const INFLOW_MODES = {
  [INFLOW_MODE_AD]: {
    label: '広告を指定',
    value: INFLOW_MODE_AD,
  },
  [INFLOW_MODE_URL]: {
    label: '遷移元サイトを指定',
    value: INFLOW_MODE_URL,
  },
  [INFLOW_MODE_IGNORE]: {
    label: '指定しない',
    value: INFLOW_MODE_IGNORE,
  },
};

export const DISPLAY_LAYER_5 = 5;
export const DISPLAY_LAYER_10 = 10;
export const DISPLAY_LAYER_15 = 15;
export const DISPLAY_LAYER_20 = 20;
export const DISPLAY_LAYERS = {
  [DISPLAY_LAYER_5]: {
    name: `${DISPLAY_LAYER_5}階層`,
    key: DISPLAY_LAYER_5,
  },
  [DISPLAY_LAYER_10]: {
    name: `${DISPLAY_LAYER_10}階層`,
    key: DISPLAY_LAYER_10,
  },
  [DISPLAY_LAYER_15]: {
    name: `${DISPLAY_LAYER_15}階層`,
    key: DISPLAY_LAYER_15,
  },
  [DISPLAY_LAYER_20]: {
    name: `${DISPLAY_LAYER_20}階層`,
    key: DISPLAY_LAYER_20,
  },
};

export const OPERATOR_OPTIONS = FilterTypeTwoOptions;
export const DISPLAY_ITEMS = DisplayItemsService.getLogRouteAnalysisAll();

export const DISPLAY_CHANNELS = FilterConfig.displayChannels
  .reduce((acc, item, index) => {
    const { allowScreen = [], key, value } = item;
    if (isEmpty(allowScreen) || allowScreen.includes(LOG_ROUTE_ANALYZE)) {
      return [
        ...acc,
        {
          ...item,
          value,
          order: key === CHANNEL_TYPE_ID.REFERRAL ? index + 2 : index,
        },
      ];
    }
    return acc;
  }, [])
  .sort((a, b) => a.order - b.order);

export const ROUTE_SETTING_FIELDS = {
  DISPLAY_CHANNEL: 'display_channel',
  INFLOW_MODE: 'inflow_mode',
  START_PAGE_ID: 'start_page_id',
  TRANSIT_PAGE_ID: 'transit_page_id',
  END_PAGE_ID: 'end_page_id',
  DISPLAY_LAYER: 'display_layer',
  DISPLAY_RATE: 'display_rate',
  DISPLAY_ITEM: 'display_item',
  MEDIA_ID: 'media_id',
  AD_GROUP1_ID: 'ad_group1_id',
  AD_GROUP2_ID: 'ad_group2_id',
  AD_ID: 'ad_id',
  LINK_URL: 'link_url',
  INFLOW_AD: `inflow_mode_${INFLOW_MODE_AD}`,
};

export const ROUTE_SETTING_DEFAULT = {
  [ROUTE_SETTING_FIELDS.DISPLAY_CHANNEL]: DISPLAY_CHANNELS.reduce(
    (acc, { key }) => ({ ...acc, [key]: true }),
    {}
  ),
  [ROUTE_SETTING_FIELDS.DISPLAY_ITEM]: Object.keys(DISPLAY_ITEMS).reduce(
    (acc, key) => {
      if (key === RANK) return acc;
      return { ...acc, [key]: DISPLAY_ITEMS[key].displayDefault };
    },
    {}
  ),
  [ROUTE_SETTING_FIELDS.LINK_URL]: {
    operator: FILTER_OPERATOR_INCLUDE,
    value: '',
  },
  [ROUTE_SETTING_FIELDS.INFLOW_MODE]: INFLOW_MODE_IGNORE,
  [ROUTE_SETTING_FIELDS.DISPLAY_LAYER]: DISPLAY_LAYER_10,
  [ROUTE_SETTING_FIELDS.MEDIA_ID]: {},
  [ROUTE_SETTING_FIELDS.AD_GROUP1_ID]: {},
  [ROUTE_SETTING_FIELDS.AD_GROUP2_ID]: {},
  [ROUTE_SETTING_FIELDS.START_PAGE_ID]: null,
  [ROUTE_SETTING_FIELDS.TRANSIT_PAGE_ID]: null,
  [ROUTE_SETTING_FIELDS.END_PAGE_ID]: null,
  [ROUTE_SETTING_FIELDS.DISPLAY_RATE]: 0.1,
  [ROUTE_SETTING_FIELDS.AD_ID]: null,
};
