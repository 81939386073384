import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames';

const ViewMenuItem = (props) => {
  const { title, screenId, active, disabled, handleChangePage } = props;

  const [isActive, setActive] = useState(false);

  const clsMenu = classNames({
    'ebis--disabled': disabled,
    'view-menu-form__menu-text--hidden': disabled,
    active: isActive,
  });

  useEffect(() => {
    setActive(active);
  }, [screenId, active]);

  const Item = () => (
    <>
      <span>{title}</span>
    </>
  );

  return (
    <Dropdown.Item
      className={clsMenu}
      onClick={(e) => { e.preventDefault(); handleChangePage(screenId); }}
    >
      <Item />
    </Dropdown.Item>
  );
};

ViewMenuItem.defaultProps = {
  disabled: false,
};

ViewMenuItem.propTypes = {
  title: string.isRequired,
  screenId: string.isRequired,
  active: bool.isRequired,
  disabled: bool,
  handleChangePage: func.isRequired,
};

export default React.memo(ViewMenuItem);
