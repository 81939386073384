import {
  MEASUREMENT_FQDN,
  PROGRAM_URL,
  UPDATE_DATE,
  FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK,
  FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS,
  FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE,
} from 'domain/fields';
import { COOKIE_PROGRAM_CHECK_TOOLTIP_MESSAGE } from 'domain/system-setting/consts';
import { parse_host as parseHost } from 'tld-extract'

const fqdnToEtld1 = (fqdn) => {
  if (!fqdn.includes('.')) {
    return fqdn;
  }
  return parseHost(fqdn, {allowPrivateTLD: true, allowUnknownTLD: true}).domain;
}

const urlToEtld1 = (url) => {
  return fqdnToEtld1((new URL(url)).hostname);
}

const validateTargetUrl = (registerModalData = {}) => {
  const isUrlRegex = new RegExp(/^https?:\/\/([a-zA-Z0-9_!~*'()[\];:@$?&=+,#%./-])+$/);
  const errorList = {};
  Object.keys(registerModalData).forEach((key) => {
    errorList[key] = [];

    if (
      registerModalData[key].program_url === undefined ||
      registerModalData[key].program_url === ''
    ) {
      errorList[key] = '';
      return;
    }

    if (registerModalData[key].program_url.length > 2083) {
      errorList[key].push('※2083文字以内で入力してください');
      return;
    }

    if (!isUrlRegex.test(registerModalData[key].program_url)) {
      errorList[key].push('※正しいURL形式で入力してください');
      return;
    }

    if (urlToEtld1(registerModalData[key].program_url) !== fqdnToEtld1(registerModalData[key].measurement_fqdn)) {
      errorList[key].push('※設定するドメインを含んだものを入力してください');
    }
  });

  return errorList;
};

const getHeader = (selectedRows, isCheckedAll) => {
  const headerData = [
    {
      name: 'check_all',
      text: '',
      isDimension: true,
      isEdit: true,
      sort: false,
      checked: selectedRows.length > 0,
      variant: selectedRows.length > 0 && !isCheckedAll ? 'mixed' : '',
    },
    {
      name: MEASUREMENT_FQDN,
      text: 'ドメイン（サブドメイン）',
      isDimension: true,
      sort: false,
    },
    {
      name: PROGRAM_URL,
      text: 'プログラムURL',
      isDimension: true,
      sort: false,
    },
    {
      name: UPDATE_DATE,
      text: '最終更新日',
      isDimension: true,
      sort: false,
    },
    {
      name: FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK,
      text: '動作確認結果',
      tooltip: COOKIE_PROGRAM_CHECK_TOOLTIP_MESSAGE,
      isDimension: true,
      sort: false,
      children: [
        {
          name: FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS,
          text: 'ステータス',
          isDimension: true,
          sort: false,
        },
        {
          name: FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE,
          text: 'Cookie付与',
          isDimension: true,
          sort: false,
        },
      ],
    },
  ];

  return headerData;
};

export { validateTargetUrl, getHeader };
