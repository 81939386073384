const getReflectionTimeData = (state) =>
  state.commonState.ReflectionTimeState.data;

const getIsDisplayAdListing = (state) =>
  state.commonState.ReflectionTimeState.data.is_display_ad_listing;

export default {
  getReflectionTimeData,
  getIsDisplayAdListing,
};
