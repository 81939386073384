import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import routeAnalyzeActions from 'store/log/route-analyze/actions';
import routeAnalyzeSelectors from 'store/log/route-analyze/selectors';
import settingsSelectors from 'store/settings/selectors';
import commonSelectors from 'store/common/selectors';
import useModalController from 'services/custom-hooks/useModalController';
import CommonbarIcon from 'views/atoms/icon/CommonbarIcon';
import RouteSettingModal from 'views/pages/log/route-analyze/components/RouteSettingModal';

import './route-setting.scss';

const SETTING_MODAL = 'SETTING_MODAL';

function RouteSettingContainer() {
  const dispatch = useDispatch();

  const [isStayingPage, isAlreadySetting, settings, masterdata, period, isLoading] = [
    useSelector(routeAnalyzeSelectors.checkStayingPage, shallowEqual),
    useSelector(routeAnalyzeSelectors.checkAlreadySetting, shallowEqual),
    useSelector(routeAnalyzeSelectors.getSettings, shallowEqual),
    useSelector(routeAnalyzeSelectors.getMasterdata, shallowEqual),
    useSelector(commonSelectors.periodSelector, shallowEqual),
    useSelector(
      settingsSelectors.getLoadingByStatus([
        routeAnalyzeSelectors.getStatusSettings,
      ]),
      shallowEqual
    ),
  ];

  const { openModal, closeModals, isOpen } = useModalController({
    ids: ['SETTING_MODAL'],
  });

  const handleSubmit = (data) => {
    dispatch(routeAnalyzeActions.changeSettings(data));
    closeModals();
  };

  const handleSearchMasterdata = (type, field = '') => (value = '') => {
    dispatch(routeAnalyzeActions.searchMasterdata({ type, field, value }));
  };

  // Call api get setting
  useEffect(() => {
    if (isStayingPage) return; // Handle case click on this menu will clear cache
    dispatch(routeAnalyzeActions.getSettings());
  }, [dispatch, isStayingPage]);

  useEffect(() => {
    if (isLoading || isAlreadySetting) return;
    dispatch(routeAnalyzeActions.getPageViewBiggest());
  }, [dispatch, period, isAlreadySetting, isLoading]);

  return (
    <>
      <CommonbarIcon
        name="条件指定"
        actived={isOpen(SETTING_MODAL)}
        onClick={() => openModal(SETTING_MODAL)}
      />
      {isOpen(SETTING_MODAL) && (
        <RouteSettingModal
          loading={isLoading}
          defaultSettings={settings}
          masterdata={masterdata}
          onSubmit={handleSubmit}
          onSearchMasterdata={handleSearchMasterdata}
          onHide={closeModals}
        />
      )}
    </>
  );
}

export default RouteSettingContainer;
