import { call, put, takeLatest } from 'redux-saga/effects';
import sharedSagas from 'store/sharedSagas';
import handleError from 'services/error/handleScopeError';
import SystemSettingDisplayApi from 'services/api/system-setting/SystemSettingDisplayApi';
import { prepareLabelForSetting } from 'services/system-setting/systemSettingDisplayServices';
import { communicationStatus, HttpStatus } from 'services/utils';
import { CONVERSIONS, RCV_DATA, RCV_MODEL_OPTION } from 'domain/fields';

import actions from './actions';
import types from './types';

const { BAD_REQUEST } = HttpStatus;
const { LOADING, SUCCEEDED } = communicationStatus;

function* handleGetData(action) {
  yield put(actions.setStatus(LOADING, action.type));

  const {
    data: { data },
  } = yield call(SystemSettingDisplayApi.fetchData);

  yield put(
    actions.setData({
      label: prepareLabelForSetting(data),
      conversions: data[CONVERSIONS],
      rcv: {
        [RCV_DATA]: data[RCV_DATA],
        [RCV_MODEL_OPTION]: data[RCV_MODEL_OPTION],
      },
    })
  );
}

function* handleUpdateVision(action) {
  const { data, callback } = action.payload;
  yield put(actions.setStatus(LOADING, action.type));
  yield call(SystemSettingDisplayApi.updateVision, data);
  callback({ isError: false });
  yield put(actions.setStatus(SUCCEEDED, action.type));
}

function* handleUpdateRcv(action) {
  const { data, callback } = action.payload;
  yield put(actions.setStatus(LOADING, action.type));
  yield call(SystemSettingDisplayApi.updateRcv, data);
  callback({ isError: false });
  yield put(actions.setStatus(SUCCEEDED, action.type));
}

function* errorHandler(err, action) {
  const { callback = () => {} } = action.payload || {};
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [];

  yield put(actions.setErrors(errors, action.type, scope));

  if ([types.UPDATE_VISION, types.UPDATE_RCV].includes(action.type)) {
    callback({ isError: true, errors });
  }
}

export default function* watch() {
  yield takeLatest(
    types.GET_DATA,
    sharedSagas.safe(errorHandler, handleGetData)
  );
  yield takeLatest(
    types.UPDATE_VISION,
    sharedSagas.safe(errorHandler, handleUpdateVision)
  );
  yield takeLatest(
    types.UPDATE_RCV,
    sharedSagas.safe(errorHandler, handleUpdateRcv)
  );
}
