import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';
import types from 'store/search-console-setting/types';

const getData = () => helper.createAction(types.GET_DATA);
const setData = (data) => helper.createAction(types.SET_DATA, { data });

const updateSort = (field, direction) =>
  helper.createAction(types.UPDATE_SORT, { field, direction });

const changeStatus = (id, status, closeModal) =>
  helper.createAction(types.CHANGE_STATUS, { id, status, closeModal });

const deleteSCSettings = (ids, callback) =>
  helper.createAction(types.DELETE, { ids, callback });

const setStatus = (status, key) =>
  helper.createAction(types.SET_STATUS, { status, key });

const getMasterData = () => helper.createAction(types.GET_MASTERDATA);
const setMasterData = (data) =>
  helper.createAction(types.SET_MASTERDATA, { data });

const create = (data, callback = () => {}) =>
  helper.createAction(types.CREATE, { data, callback });
const getDetail = (id, callback = () => {}) =>
  helper.createAction(types.GET_DETAIL, { id, callback });
const update = (id, data, callback = () => {}) =>
  helper.createAction(types.UPDATE, { id, data, callback });

const setDataSetting = (data) =>
  helper.createAction(types.SET_DATA_SETTING, { data });

const setErrorSetting = (errors, scope) =>
  helper.createAction(types.SET_ERROR_SETTING, { errors }, scope);

const setErrors = (errors, type, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERRORS, { errors, type }, scope);

const SCSettingActions = {
  getData,
  setData,
  updateSort,
  changeStatus,
  deleteSCSettings,
  getMasterData,
  setMasterData,
  getDetail,
  create,
  update,
  setDataSetting,
  setStatus,
  setErrorSetting,
  setErrors,
};

export default SCSettingActions;
