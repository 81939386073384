import React, { useMemo, useCallback, useRef } from 'react';
import { arrayOf, func, string, shape } from 'prop-types';
import { SEND_MAIL_USERS, EMAIL_NOTIFICATION } from 'domain/fields';
import { HEADER_SEND_MAIL_USERS } from 'domain/system-setting/consts';
import { formatUsersData } from 'services/system-setting/mailSettingServices';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import {
  GridTable,
  GridTableItem,
  GridTableHeaderWrapper,
  GridTableBodyWrapper,
  GridTableHeader,
  GridTableRow,
  GridTableSeparator,
} from 'views/organism/GridTable';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import iconUsers from 'assets/images/icon-users.svg';

function SendMailUserSetting(props) {
  const { info, users, selected, error, onChange, onSelectRow } = props;
  const ref = useRef();
  const formattedUsers = useMemo(() => formatUsersData(users), [users]);
  const header = HEADER_SEND_MAIL_USERS;

  const handleSelectRow = useCallback(
    (ids) => {
      onSelectRow(ids);
      onChange(SEND_MAIL_USERS, ids);
    },
    [onChange, onSelectRow]
  );

  return (
    <div className="form-media-info-setting__send-mail-user">
      <div className="form-media-info-setting__send-mail-user__title">
        <FormGroup required={info[EMAIL_NOTIFICATION].value} label="送信先" />
      </div>
      <ErrorTooltipWrapper isError={!!error} errorMess={error} ref={ref}>
        <div className="form-media-info-setting__send-mail-user__content mb-10">
          <GridTable
            isApplyTableScrolling
            isTableCenter
            maxHeight={261}
            header={header}
            rows={formattedUsers}
            rowsSelected={selected}
            onSelectRow={handleSelectRow}
          >
            <GridTableItem isFreeze>
              <GridTableHeaderWrapper>
                <GridTableHeader />
              </GridTableHeaderWrapper>
              <GridTableBodyWrapper>
                <GridTableRow />
              </GridTableBodyWrapper>
            </GridTableItem>
            <GridTableSeparator />
            <GridTableItem>
              <GridTableHeaderWrapper>
                <GridTableHeader />
              </GridTableHeaderWrapper>
              <GridTableBodyWrapper>
                <GridTableRow />
              </GridTableBodyWrapper>
            </GridTableItem>
          </GridTable>
        </div>
      </ErrorTooltipWrapper>
      <div className="form-media-info-setting__send-mail-user__note">
        <p className="d-flex align-items-center">
          <img src={iconUsers} alt="" />
          <span className="color-primary">
            複数メールアドレスが登録されています。カーソルをあわせるとすべてのメールアドレスがご確認いただけます
          </span>
        </p>
        <p className="user-notice">
          ※「設定/管理＞基本設定＞ユーザー管理」よりユーザーのメールアドレスを設定いただけます。メールアドレスが設定されていない場合、送信先に選択されていても通知されません。
        </p>
      </div>
    </div>
  );
}

SendMailUserSetting.propTypes = {
  info: shape({}).isRequired,
  users: arrayOf({}),
  selected: arrayOf(string),
  error: string,
  onChange: func,
  onSelectRow: func,
};

SendMailUserSetting.defaultProps = {
  users: [],
  selected: [],
  error: '',
  onChange: () => {},
  onSelectRow: () => {},
};

export default SendMailUserSetting;
