import { combineReducers } from 'redux';
import SettingsItemForViewReducer from './reducer';

const SettingForViewReducers = combineReducers({
  reportForView: SettingsItemForViewReducer,
});

export { default as settingsForViewSelectors } from 'store/settings-for-view/selectors';

export default SettingForViewReducers;
