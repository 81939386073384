import {
  FIELD_IS_USING,
  MAX_PRODUCT,
  FIELD_NAME,
  FIELD_ID,
} from 'services/ltv/settings/product/constants';
import { ErrorCode } from 'services/utils';
import findIndex from 'lodash/findIndex';

const allowFields = [FIELD_NAME];

const getByName = (productName, list) => {
  const selectedIndex = Object.keys(list).find(
    (key) => list[key].name === productName
  );
  if (selectedIndex) {
    return selectedIndex;
  }
  return null;
};

const getErrorMess = (errorCode) => {
  switch (errorCode) {
    case ErrorCode.OVER_USER:
      return `※ 商品登録上限（${MAX_PRODUCT}件）を超えています。`;
    case ErrorCode.NOT_EXISTS_DB:
      return '※ 商品が削除されました';
    default:
      return 'システムエラーが発生しました。何度も失敗する際はお手数ですがエビスサポートセンターまでご連絡ください';
  }
};

/**
 * Validate field by name
 * @param  string itemName
 * @param bool isRequired
 * @param int maxLength
 * @returns {*}
 */
const validate = (value, isRequired, maxLength, action, products) => {
  const message = `商品を入力してください。`;
  // Check field is empty
  if (isRequired) {
    if (Boolean(value) === false) {
      return { result: false, message };
    }
  }

  if (maxLength && value && value.length > maxLength) {
    return {
      result: false,
      message: `商品は${maxLength}字以下で入力してください。`,
    };
  }

  // Check user_id is exists
  if (action !== 'edit' && getByName(value, products)) {
    return {
      result: false,
      message: '利用されている商品名です。別の商品名を入力してください。',
    };
  }

  // Check invalid character
  return { result: true, message: '' };
};

const isApiValidationError = ({ data, status }) => {
  if (status !== 400) {
    return false;
  }
  const { errors } = data;
  if (errors.length > 0) {
    return errors.some((error) => {
      return allowFields.includes(error.field);
    });
  }
  return false;
};

const getIdsString = (ids) => {
  const initialValue = '';
  return ids.reduce((prevVal, currVal, idx) => {
    return idx === 0 ? currVal[FIELD_ID] : `${prevVal},${currVal[FIELD_ID]}`;
  }, initialValue);
};

const getHeader = (selectedRows, isCheckedAll, sort) => {
  const headerData = [
    {
      text: '',
      name: 'check_all',
      isDimension: true,
      isEdit: true,
      sort: false,
      checked: selectedRows.length > 0,
      variant: selectedRows.length > 0 && !isCheckedAll ? 'mixed' : '',
    },
    {
      text: '商品',
      name: 'product_name',
      isDimension: true,
      sort: true,
    },
  ];

  if (sort) {
    return headerData.map((item) => {
      const newObj = { ...item };
      if (newObj.name === sort.field) {
        newObj.sort = sort.direction;
      }
      return newObj;
    });
  }

  return headerData;
};

const addNewKeyForDataList = (list, selectedRows) => {
  return list.map((item) => {
    return {
      ...item,
      rowId: item[FIELD_ID],
      selected: selectedRows.includes(item[FIELD_ID]),
    };
  });
};

const findByProductId = (id, items) => {
  if (id) {
    const index = findIndex(
      items,
      (item) => item[FIELD_ID].toString() === id.toString()
    );

    return index !== -1 ? items[index] : {};
  }

  return {};
};

const getItemsByUsingStatus = (selectedIds, items, isUsing) => {
  return items.filter((item) => {
    return (
      item[FIELD_IS_USING] === isUsing && selectedIds.includes(item[FIELD_ID])
    );
  });
};

export default {
  validate,
  isApiValidationError,
  getErrorMess,
  getIdsString,
  getHeader,
  addNewKeyForDataList,
  findByProductId,
  getItemsByUsingStatus,
};
