import {
  CSV_MAX_DOWNLOADABLE_RECORDS,
  FILTER_CONTACT_HISTORY_DEFAULT,
} from 'domain/consts';
import {
  CATEGORY_ANALYZE,
  COMPARE_PERIOD,
  COST_ALLOCATION,
  CV_ATTRIBUTE,
  CV_FLOW,
  DETAILS_ANALYSIS,
  LANDING_PAGE_ANALYZE,
  AD_MANAGEMENT,
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
  LTV_ANALYZE,
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  LPO_LINK,
  LPO_PERIOD,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  LOG_ROUTE_ANALYZE,
} from 'services/routes/constants';
import categoryAnalyzeSelectors from 'store/category-analyze/selectors';
import periodAnalysisSelectors from 'store/period-analyze/selectors';
import costAllocationSelectors from 'store/cost-allocation/selectors';
import cvFlowSelectors from 'store/cv-flow/selectors';
import cvAttributeSelectors from 'store/cv-attribute/selectors';
import detailAnalysisSelectors from 'store/detail-analyze/selectors';
import landingPageSelectors from 'store/landing-page-analyze/selectors';
import ltvSettingsProductSelectors from 'store/ltv/settings/product/selectors';
import ltvSettingsOfferSelectors from 'store/ltv/settings/offer/selectors';
import ltvSettingsConditionSelectors from 'store/ltv/settings/condition/selectors';
import ltvSettingsAdSelectors from 'store/ltv/settings/ad/selectors';
import ltvAnalyzeSelectors from 'store/ltv/analyze/selectors';
import adManagementMediaSelectors from 'store/ad-management-media/selectors';
import adManagementSettingsSelectors from 'store/ad-management-settings/selectors';
import logPageAnalyzeSelectors from 'store/log/page-analyze/selectors';
import logPeriodAnalyzeSelectors from 'store/log/period-analyze/selectors';
import logRouteAnalyzeSelectors from 'store/log/route-analyze/selectors';
import filterSelector from 'store/filters/selectors';
import settingsSelector from 'store/settings/selectors';
import lpoLinkSelectors from 'store/lpo/link/selectors';
import lpoPeriodSelectors from 'store/lpo/period/selectors';

import FilterService from 'domain/FilterService';
import { createSelector } from 'reselect';
import { DisplayItemsSelectors } from 'store/display-items';
import { LIMIT_EXPORT_IMAGE_ROUTE } from 'domain/log/route-analyze/consts';
import { formatNumber } from 'domain/utils';

const periodSelector = (state) => state.commonState.CommonState.settings.period;

const comparePeriodSelector = (state) =>
  state.commonState.CommonState.settings.comparedPeriod;

const getStatusTransitionModal = (state) =>
  state.commonState.CommonState.settings.transitionModal.isOpen;

const getDisabledTransition = (state) =>
  state.commonState.CommonState.settings.transitionModal.disabled;

const getCurrentUrl = (state) =>
  state.commonState.CommonState.location.parentUrl;

const isCsvDownloadDisabled = (screenId) => (state) => {
  let rowCount;
  switch (screenId) {
    case CATEGORY_ANALYZE:
      rowCount = categoryAnalyzeSelectors.paginationSelector(state).totalRows;
      break;
    case COMPARE_PERIOD:
      rowCount = periodAnalysisSelectors.paginationSelector(state).totalRows;
      break;
    case COST_ALLOCATION:
      rowCount = costAllocationSelectors.getPagination(state).currentPage;
      break;
    case CV_FLOW:
      rowCount = cvFlowSelectors.getPagination(state).totalItems || 0;
      break;
    case CV_ATTRIBUTE:
      rowCount = cvAttributeSelectors.getPagination(state).totalItems || 0;
      break;
    case DETAILS_ANALYSIS:
      rowCount = detailAnalysisSelectors.getPagination(state).totalItems || 0;
      break;
    case LANDING_PAGE_ANALYZE:
      rowCount = landingPageSelectors.getPagination(state).totalItems || 0;
      break;
    case LOG_PAGE_ANALYZE:
      rowCount = logPageAnalyzeSelectors.getMetadataTotal(state) || 0;
      break;
    case LOG_ROUTE_ANALYZE:
      rowCount = logRouteAnalyzeSelectors.getMetadataTotal(state) || 0;
      break;
    case LOG_PERIOD_ANALYZE:
      rowCount = logPeriodAnalyzeSelectors.getMetadataTotal(state) || 0;
      break;
    case AD_MANAGEMENT:
      return false;
    case LTV_SETTINGS_PRODUCT:
      rowCount =
        ltvSettingsProductSelectors.getPagination(state).totalItems || 0;
      break;
    case LTV_SETTINGS_OFFER:
      rowCount = ltvSettingsOfferSelectors.getPagination(state).totalItems || 0;
      break;
    case LTV_SETTINGS_CONDITION:
      rowCount =
        ltvSettingsConditionSelectors.getPagination(state).totalItems || 0;
      break;
    case LTV_SETTINGS_AD:
      rowCount = ltvSettingsAdSelectors.getPagination(state).totalItems || 0;
      break;
    case LTV_ANALYZE:
      rowCount = ltvAnalyzeSelectors.getPagination(state).totalItems || 0;
      break;
    case AD_MANAGEMENT_MEDIA_TYPE:
    case AD_MANAGEMENT_AD_GROUP_1:
    case AD_MANAGEMENT_AD_GROUP_2:
      rowCount =
        adManagementMediaSelectors.getPagination(state).totalItems || 0;
      break;
    case LPO_LINK:
      rowCount = lpoLinkSelectors.getPagination(state).totalItems || 0;
      break;
    case LPO_PERIOD:
      rowCount = lpoPeriodSelectors.paginationSelector(state).totalRows;
      break;
    default:
      return -1;
  }
  // if (rowCount > 0 && rowCount <= CSV_MAX_DOWNLOADABLE_RECORDS) {
  if (rowCount <= CSV_MAX_DOWNLOADABLE_RECORDS) {
    return false;
  }

  return true;
};

const downloadImageDisabled = (screenId) => (state) => {
  let rowCount;
  let maxCount;
  let tooltip = '';

  switch (screenId) {
    case LOG_ROUTE_ANALYZE:
      maxCount = LIMIT_EXPORT_IMAGE_ROUTE;
      rowCount = logRouteAnalyzeSelectors.getDataDetail(state).length || 0; // need to get row length because spec described some merge route into on row
      tooltip = `経路数が出力の上限数を超えるため出力できません。${formatNumber(
        maxCount
      )}件以下になるよう条件を絞り込んでください。`;
      break;
    default:
      return -1;
  }

  return { tooltip, disabled: rowCount > maxCount };
};

const getDownloadConfigs = (state) =>
  state.commonState.CommonState.settings.download;

const getDownloadNotify = (state) =>
  state.commonState.CommonState.data.downloadNotify;

const isShowNavbar = (state) => state.commonState.CommonState.isShowNavbar;

const getCommonStatus = (state) =>
  state.commonState.CommonState.apiStatus.status;

const getTutorialStatus = (state) =>
  state.commonState.CommonState.tutorial.status;

const getTutorialModalStatus = (state) =>
  state.commonState.CommonState.tutorial.modal;

// Prepare a mapping between the filter key list and the table's display column name
const isDefaultContactHistory = (filters) => {
  return (
    Object.keys(filters).filter((key) => filters[key].values.length > 0)
      .length > 0 && !('contact_history' in filters)
  );
};
const getMappedFilterKeyList = createSelector(
  [
    filterSelector.getApplicableSettings,
    settingsSelector.getPage,
    settingsSelector.getTab,
    adManagementSettingsSelectors.getFilterSettings,
    DisplayItemsSelectors.getDisplayItemPriorityAxis,
  ],
  (filters, pageId, currentTab, adManagementFilters, priorityAxis) => {
    let filteredList = filters;
    if (pageId === AD_MANAGEMENT) {
      filteredList = adManagementFilters;
    }
    if (pageId === CV_ATTRIBUTE || pageId === CV_FLOW) {
      // Add contact_history object to filters in case selected filters && contact history default values
      // To display the filter icon in this case
      filteredList = isDefaultContactHistory(filters)
        ? Object.assign(filters, FILTER_CONTACT_HISTORY_DEFAULT)
        : filters;
    }

    filteredList = FilterService.prepareForUI(filteredList, pageId, currentTab);

    return FilterService.prepareFilterKeyMapping(
      filteredList,
      pageId,
      priorityAxis
    );
  }
);

export default {
  periodSelector,
  comparePeriodSelector,
  getStatusTransitionModal,
  getCurrentUrl,
  isCsvDownloadDisabled,
  downloadImageDisabled,
  getDownloadConfigs,
  getDownloadNotify,
  isShowNavbar,
  getCommonStatus,
  getTutorialStatus,
  getTutorialModalStatus,
  getMappedFilterKeyList,
  getDisabledTransition,
};
