import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LTV_DISPLAY_SETTINGS } from 'services/routes/constants';
import isEmpty from 'lodash/isEmpty';

const LtvDisplayApiFactory = () => {
  const url = pages[LTV_DISPLAY_SETTINGS].endpoint;
  const baseURL = process.env.REACT_APP_LTV_API_HOST;

  return {
    fetch: async (funcId) => {
      const response = await Api.get(url, { baseURL });
      let responseData = {};
      if (!isEmpty(response.data.data.detail)) {
        responseData =
          response.data.data.detail[funcId]?.display_settings ?? {};
      }
      const data = {
        data: {
          data: {
            ...responseData,
          },
        },
      };
      return data;
    },
    // Delete and create
    create: async (funcId, data) => {
      const response = await Api.put(
        url,
        { func_id: funcId, display_settings: data },
        { baseURL }
      );
      const responseData = response.data.data.detail[funcId].display_settings;
      return {
        data: {
          data: {
            ...responseData,
          },
        },
      };
    },
  };
};

const LtvDisplayApi = LtvDisplayApiFactory();

export default LtvDisplayApi;
