import types from 'store/category-analyze/types';
import tableService from 'services/category-analyze/tableService';
import {
  communicationStatus,
  containsByAllKeys,
  equalsAllKeys,
} from 'services/utils';
import * as FIELD from 'domain/fields';
import chartService from 'services/category-analyze/chartService';
import { DEFAULT_SORT_TABLE } from 'domain/category-analyze/consts';

const {
  GET_REPORT,
  GET_CHART_REPORT,
  GET_COMPARED_CHART_REPORT,
  GET_TABLE_REPORT,
  GET_COMPARED_TABLE_REPORT,
  UPDATE_CHART_DATA,
  UPDATE_COMPARED_CHART_DATA,
  UPDATE_TABLE_DATA,
  UPDATE_COMPARED_TABLE_DATA,
  APPLY_BOOKMARK,
  CLEAN_STATES,
  CLEAN_TABLE,
  SET_TABLE_STATUS,
  SET_CHART_STATUS,
} = types;

const { IDLE, LOADING } = communicationStatus;

const initialState = {
  settings: {
    chart: {
      axis: {
        x: FIELD.CVR_TOTAL,
        y: FIELD.CV_TOTAL,
      },
      bookmark: {
        x: null,
        y: null,
        enabled: false,
        loaded: false,
      },
      median: {
        x: null,
        y: null,
        enabled: false,
      },
      selectedCategories: [],
      instance: null,
      visibleList: [],
    },
    table: {
      pagination: {
        totalRows: 0,
        currentPage: 1,
      },
      sorts: DEFAULT_SORT_TABLE,
    },
    display: {
      showChart: true,
      showCategoriesModal: false,
    },
  },
  data: {
    chart: {
      list: [],
      comparedList: [],
    },
    table: {
      list: [],
      sum: {},
      comparedList: [],
      comparedSum: {},
      selectedRows: [],
    },
  },
  displayData: {
    chart: {
      list: [],
      compared: false,
    },
    table: {
      headers: [],
      rows: [],
      sum: [],
      compared: false,
    },
  },
  apiStatus: {
    chart: {
      status: IDLE,
    },
    table: {
      status: IDLE,
    },
  },
};

const getUpdatedChartDisplayData = (
  state,
  { selectedRows, visibleList, list, axis, selectedCategories, comparedList }
) => {
  const showComparedChart = state.displayData.chart.compared;

  const fSelectedRows = selectedRows || state.data.table.selectedRows;
  const fVisibleList = visibleList || state.settings.chart.visibleList;
  const fList = list || state.data.chart.list;
  const fAxis = axis || state.settings.chart.axis;
  const fSelectedCategories =
    selectedCategories || state.settings.chart.selectedCategories;
  const fComparedList = comparedList || state.data.chart.comparedList;

  if (!showComparedChart) {
    return chartService.buildDisplayData({
      list: fList,
      visibleList: fVisibleList,
      selectedRows: fSelectedRows,
      axis: fAxis,
      selectedCategories: fSelectedCategories,
    });
  }

  return chartService.buildComparedDisplayData({
    list: fList,
    comparedList: fComparedList,
    visibleList: fVisibleList,
    selectedRows: fSelectedRows,
    axis: fAxis,
    selectedCategories: fSelectedCategories,
  });
};

const SummaryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_AXIS: {
      const axis = payload;
      const selectedRows = [];
      const displayData = getUpdatedChartDisplayData(state, {
        axis,
        selectedRows,
      });
      const tableDisplayData = state.displayData.table.rows;
      const newTableDisplayData = tableService.updateSelectedRows({
        displayRows: tableDisplayData,
        selectedRows,
      });
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            axis,
            median: initialState.settings.chart.median,
          },
        },
        data: {
          ...state.data,
          table: {
            ...state.data.table,
            selectedRows,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: displayData,
          },
          table: {
            ...state.displayData.table,
            rows: newTableDisplayData,
          },
        },
      };
    }

    case types.UPDATE_BOOKMARK: {
      const { x, y, enabled } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            bookmark: {
              ...state.settings.chart.bookmark,
              x,
              y,
              enabled,
            },
          },
        },
      };
    }

    case types.GET_BOOKMARK: {
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            bookmark: {
              ...state.settings.chart.bookmark,
              loaded: false,
            },
          },
        },
      };
    }

    case types.GET_BOOKMARK_SUCCESS: {
      const { metric: y, compare_metric: x } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            bookmark: {
              x,
              y,
              saved: true,
            },
          },
        },
      };
    }

    case types.DELETE_BOOKMARK:
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            bookmark: {
              x: null,
              y: null,
              saved: false,
            },
          },
        },
      };

    case types.SET_SHOW_CHART: {
      const newState = { ...state };
      newState.settings.display.showChart = payload;
      return newState;
    }

    case types.SET_SHOW_CATEGORIES_MODAL: {
      const newState = { ...state };
      newState.settings.display.showCategoriesModal = payload;
      return newState;
    }

    case types.UPDATE_CATEGORIES: {
      const newSelectedCategories = payload;
      const { selectedCategories } = state.settings.chart;
      const { visibleList } = state.settings.chart;

      const hiddenList = selectedCategories.filter(
        (category) => !containsByAllKeys(visibleList, category)
      );

      const newVisibleList = newSelectedCategories.filter(
        (category) => !containsByAllKeys(hiddenList, category)
      );

      const displayData = getUpdatedChartDisplayData(state, {
        selectedCategories: newSelectedCategories,
        visibleList: newVisibleList,
      });

      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            selectedCategories: newSelectedCategories,
            visibleList: newVisibleList,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: displayData,
          },
        },
      };
    }

    case types.UPDATE_MEDIAN: {
      const newState = { ...state };
      newState.settings.chart.median = payload;
      return newState;
    }

    case types.SET_CHART_INSTANCE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            instance: payload,
          },
        },
      };
    }

    case types.SET_TABLE_CURRENT_PAGE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            pagination: {
              ...state.settings.table.pagination,
              currentPage: payload,
            },
          },
        },
      };
    }

    case types.UPDATE_SORT: {
      const sorts = payload;
      const displayHeaders = tableService.updateDisplayHeadersSorts(
        state.displayData.table.headers,
        sorts
      );
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            sorts,
            pagination: {
              ...state.settings.table.pagination,
              currentPage: 1,
            },
          },
        },
        displayData: {
          ...state.displayData,
          table: {
            ...state.displayData.table,
            headers: displayHeaders,
          },
        },
      };
    }
    case types.SELECT_ROW: {
      const row = payload;
      const { selectedRows } = state.data.table;

      if (!containsByAllKeys(selectedRows, row)) {
        const newSelectedRows = selectedRows.concat(row);
        const chartDisplayData = state.displayData.chart.list;
        const displayData = chartService.selectRow({
          displayData: chartDisplayData,
          row,
          selected: true,
        });

        const tableCurrentDisplayRows = state.displayData.table.rows;
        const tableDisplayRows = tableService.updateSelectedRow({
          displayRows: tableCurrentDisplayRows,
          row,
          selected: true,
        });
        return {
          ...state,
          data: {
            ...state.data,
            table: {
              ...state.data.table,
              selectedRows: newSelectedRows,
            },
          },
          displayData: {
            ...state.displayData,
            chart: {
              ...state.displayData.chart,
              list: displayData,
            },
            table: {
              ...state.displayData.table,
              rows: tableDisplayRows,
            },
          },
        };
      }
      return state;
    }

    case types.DESELECT_ROW: {
      const row = payload;
      const { selectedRows } = state.data.table;
      const newSelectedRows = selectedRows.filter(
        (selectedRow) => !equalsAllKeys(row, selectedRow)
      );
      const chartDisplayData = state.displayData.chart.list;
      const displayData = chartService.selectRow({
        displayData: chartDisplayData,
        row,
        selected: false,
      });

      const tableCurrentDisplayRows = state.displayData.table.rows;
      const tableDisplayRows = tableService.updateSelectedRow({
        displayRows: tableCurrentDisplayRows,
        selected: false,
        row,
      });
      return {
        ...state,
        data: {
          ...state.data,
          table: {
            ...state.data.table,
            selectedRows: newSelectedRows,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: displayData,
          },
          table: {
            ...state.displayData.table,
            rows: tableDisplayRows,
          },
        },
      };
    }

    case types.SET_VISIBLE: {
      const { categories } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            visibleList: categories,
          },
        },
      };
    }

    case types.UPDATE_VISIBLE: {
      const { dimensionKey, visible } = payload;
      const { visibleList } = state.settings.chart;
      let newVisibleList;
      if (visible) {
        newVisibleList = visibleList.concat(dimensionKey);
      } else {
        newVisibleList = visibleList.filter(
          (item) => !equalsAllKeys(item, dimensionKey)
        );
      }

      const displayData = chartService.setVisibleRow({
        displayData: state.displayData.chart.list,
        row: dimensionKey,
        visible,
      });

      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            visibleList: newVisibleList,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: displayData,
          },
        },
      };
    }

    case GET_REPORT: {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            fetching: true,
          },
          table: {
            ...state.apiStatus.table,
            fetching: true,
          },
        },
      };
    }

    case GET_TABLE_REPORT:
    case GET_COMPARED_TABLE_REPORT: {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          table: {
            ...state.apiStatus.table,
            status: LOADING,
          },
        },
      };
    }

    case GET_CHART_REPORT:
    case GET_COMPARED_CHART_REPORT: {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            status: LOADING,
          },
        },
      };
    }

    case UPDATE_CHART_DATA: {
      const { list, selectedCategories, displayData } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            selectedCategories,
            visibleList: selectedCategories,
          },
        },
        data: {
          ...state.data,
          chart: {
            ...state.data.chart,
            list,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: displayData,
            compared: false,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            fetching: false,
          },
        },
      };
    }

    case UPDATE_COMPARED_CHART_DATA: {
      const { list, comparedList, selectedCategories, displayData } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            selectedCategories,
            visibleList: selectedCategories,
          },
        },
        data: {
          ...state.data,
          chart: {
            ...state.data.chart,
            list,
            comparedList,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: displayData,
            compared: true,
          },
        },
      };
    }

    case UPDATE_TABLE_DATA: {
      const {
        list,
        displayRows,
        displayHeaders,
        displaySum,
        count,
        selectedRows,
      } = payload;
      const newSelectedRows = selectedRows || state.data.table.selectedRows;
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            pagination: {
              ...state.settings.table.pagination,
              totalRows: count,
            },
          },
        },
        data: {
          ...state.data,
          table: {
            ...state.data.table,
            list,
            selectedRows: newSelectedRows,
          },
        },
        displayData: {
          ...state.displayData,
          table: {
            ...state.displayData.table,
            headers: displayHeaders || state.displayData.table.headers,
            rows: displayRows,
            sum: displaySum,
          },
          chart: {
            ...state.displayData.chart,
            list: getUpdatedChartDisplayData(state, {
              selectedRows: newSelectedRows,
            }),
          },
        },
        apiStatus: {
          ...state.apiStatus,
          table: {
            ...state.apiStatus.table,
            fetching: false,
          },
        },
      };
    }

    case UPDATE_COMPARED_TABLE_DATA: {
      const {
        list,
        sum,
        comparedList,
        comparedSum,
        displayRows,
        displayHeaders,
        displaySum,
        selectedRows,
        count,
      } = payload;
      const newSelectedRows = selectedRows || state.data.table.selectedRows;

      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            pagination: {
              ...state.settings.table.pagination,
              totalRows: count,
            },
          },
        },
        data: {
          ...state.data,
          table: {
            ...state.data.table,
            list,
            selectedRows: newSelectedRows,
            comparedSum,
            comparedList,
            sum,
          },
        },
        displayData: {
          ...state.displayData,
          table: {
            ...state.displayData.table,
            headers: displayHeaders,
            rows: displayRows,
            sum: displaySum,
          },
          chart: {
            ...state.displayData.chart,
            list: getUpdatedChartDisplayData(state, {
              selectedRows: newSelectedRows,
            }),
          },
        },
        apiStatus: {
          ...state.apiStatus,
          table: {
            ...state.apiStatus.table,
            fetching: false,
          },
        },
      };
    }

    case APPLY_BOOKMARK: {
      const { x, y, enabled, showChart } = payload;
      if (!enabled) {
        return {
          ...state,
          settings: {
            ...state.settings,
            display: {
              ...state.settings.display,
              showChart: showChart !== false,
            },
            chart: {
              ...state.settings.chart,
              bookmark: {
                ...state.settings.chart.bookmark,
                enabled: false,
                loaded: true,
              },
            },
          },
        };
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          display: {
            ...state.settings.display,
            showChart: showChart !== false,
          },
          chart: {
            ...state.settings.chart,
            axis: {
              x,
              y,
            },
            bookmark: {
              enabled,
              x,
              y,
              loaded: true,
            },
          },
        },
      };
    }

    case CLEAN_STATES: {
      return initialState;
    }

    case CLEAN_TABLE: {
      return {
        ...state,
        data: {
          ...state.data,
          table: initialState.data.table,
        },
        displayData: {
          ...state.displayData,
          table: initialState.displayData.table,
        },
      };
    }

    case SET_CHART_STATUS: {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            status: payload,
          },
        },
      };
    }

    case SET_TABLE_STATUS: {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          table: {
            ...state.apiStatus.table,
            status: payload,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default SummaryReducer;
