import React from 'react';
import iconDrag from 'assets/images/icon_drag.svg';
import { func, string, number } from 'prop-types';
import './sort-item.scss';

const SortItem = ({
  name,
  index,
  handleDragOver,
  handleDragStart,
  handleDragEnd,
  className,
  handleDragEnter,
}) => {
  return (
    <li
      key={index}
      draggable
      onDragOver={handleDragOver}
      onDragStart={(e) => handleDragStart(e, index)}
      onDragEnter={(e) => handleDragEnter(e, index)}
      onDragEnd={handleDragEnd}
      className={className}
    >
      <img src={iconDrag} alt="icon-drag" />
      <span>{name ?? ''}</span>
    </li>
  );
};

SortItem.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  handleDragOver: func.isRequired,
  handleDragStart: func.isRequired,
  handleDragEnd: func.isRequired,
  className: string.isRequired,
  handleDragEnter: func.isRequired,
};

export default SortItem;
