import helper from 'store/helper';
import types from './types';

const redirectStart = () => helper.createAction(types.REDIRECT_START);

const redirectRequestStart = (screens, items, period, comparedPeriod) =>
  helper.createAction(types.REDIRECT_REQUEST_START, {
    screens,
    items,
    period,
    comparedPeriod,
  });

const redirectSucceeded = () => helper.createAction(types.REDIRECT_SUCCEEDED);

export default {
  redirectStart,
  redirectRequestStart,
  redirectSucceeded,
};
