import React, {
  useReducer,
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { oneOfType, array, string, node, number, func, bool } from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {
  GridTableReducer,
  initialState,
} from 'views/organism/GridTable/GridTableReducer';
import { GridTableContext } from 'views/organism/GridTable/GridTableContext';
import useResizeObserver from 'views/organism/GridTable/useResizeObserver';
import useVirtualScroll from 'views/organism/GridTable/useVirtualScroll';
import GridTableScrollbarVertical from 'views/organism/GridTable/GridTableScrollbarVertical';
import Spinner from 'views/atoms/loader/Spinner';
import { GRID_TABLE_ID } from './consts';

import './grid-table.scss';

function GridTable(props) {
  const {
    children,
    top,
    header,
    rows,
    rowsSelected,
    variant,
    emptyContent,
    onSelectRow,
    isResizable,
    isSortable,
    isHoverable,
    isApplyTableScrolling,
    isTableCenter,
    isIgnoreLoading,
    isShowRowTotal,
    maxHeight,
    rowHeight,
    renderAhead,
  } = props;
  const [settings, dispatch] = useReducer(GridTableReducer, {
    ...initialState,
    hasSelectRowFunc: !!onSelectRow,
    selectedRows: rowsSelected,
  });
  const { tableInfo, selectedRows, isResizing } = settings;
  const totalRow = rows.length;
  const isEmptyData = totalRow <= 0;
  const hasScrollerHorizontal =
    tableInfo.freeze.widthScroller || tableInfo.main.widthScroller;

  const [renderedNode, setRenderedNode] = useState(0);
  const [widthGridTable, setWidthGridTable] = useState(null);
  const [isFirstRender, setFirstRender] = useState(
    !isIgnoreLoading && !isEmptyData
  );

  const gridTableRef = useRef(null);

  const gridTableClass = classNames({
    'grid-table': true,
    'grid-table--empty': isEmptyData,
    'grid-table--scrolling': isApplyTableScrolling,
    'grid-table--center': isTableCenter,
    'grid-table--rendering': isFirstRender,
    'grid-table--resizing': isResizing,
    'grid-table--hover': isHoverable,
    'grid-table--has-scroller-horizontal': hasScrollerHorizontal,
    [`grid-table--${variant}`]: !!variant,
  });

  const emptyClass = classNames({
    'grid-table__empty': true,
    'grid-table__empty--has-scroller': hasScrollerHorizontal,
  });

  const { resizeObserver, heightRow } = useResizeObserver();

  const isRenderedAllNode = renderedNode >= totalRow;
  const { startNode, visibleNode, viewportHeight } = useVirtualScroll({
    ref: isApplyTableScrolling && !isRenderedAllNode ? gridTableRef : null,
    totalRow,
    rowHeight,
    rowsHeight: heightRow,
    renderAhead,
  });

  const measuredRowHeightRef = useCallback(
    (body) => {
      if (body !== null) {
        resizeObserver.observe(body, {
          attributes: true,
          attributeFilter: ['class'],
        });
      }
    },
    [resizeObserver]
  );

  useEffect(() => {
    setRenderedNode((prev) => {
      const renderNode = startNode + visibleNode;
      return prev < renderNode ? renderNode : prev;
    });
  }, [startNode, visibleNode]);

  useEffect(() => {
    dispatch({ type: 'setHeightRow', payload: heightRow });
  }, [heightRow]);

  useEffect(() => {
    dispatch({ type: 'setSelectedRow', payload: rowsSelected });
  }, [rowsSelected]);

  useEffect(() => {
    if (onSelectRow) {
      onSelectRow(selectedRows);
    }
  }, [onSelectRow, selectedRows]);

  useEffect(() => {
    if (!gridTableRef?.current) return () => {};

    const gridTableEl = gridTableRef.current;
    const rowGroupEl = gridTableEl.querySelector('[role="rowgroup"]');

    // Listen resize table/column
    const widthObserver = new ResizeObserver(([entry]) => {
      window.requestAnimationFrame(() => {
        if (entry.contentRect.width === widthGridTable) {
          dispatch({ type: 'setResizing', payload: false });
        } else {
          dispatch({ type: 'setResizing', payload: true });
          setWidthGridTable(entry.contentRect.width);
        }
      });
    });

    // Listen add new row
    const nodeObserver = new MutationObserver(([mutation]) => {
      if (isFirstRender) return;

      dispatch({
        type: 'setResizing',
        payload: !isResizing && mutation.type === 'childList',
      });
    });

    widthObserver.observe(gridTableEl);
    nodeObserver.observe(rowGroupEl, { childList: true });
    return () => {
      widthObserver.disconnect(gridTableEl);
      nodeObserver.disconnect(rowGroupEl);
    };
  }, [dispatch, gridTableRef, isFirstRender, isResizing, widthGridTable]);

  useEffect(() => {
    if (!isFirstRender) return;

    setFirstRender(isEmpty(heightRow));
  }, [isFirstRender, heightRow]);

  return (
    <GridTableContext.Provider
      value={{
        dispatch,
        top,
        header,
        settings,
        gridTableRef,
        isEmptyData,
        isResizable,
        isSortable,
        isShowRowTotal,
        rows,
        rowsRendering: isRenderedAllNode ? rows : rows.slice(0, renderedNode),
        viewportHeight: isRenderedAllNode ? null : viewportHeight,
      }}
    >
      <div
        ref={measuredRowHeightRef}
        id={GRID_TABLE_ID}
        className={gridTableClass}
      >
        <div
          className="grid-table__container"
          role="grid"
          ref={gridTableRef}
          style={{ maxHeight }}
        >
          {children}
          {isEmptyData && <div className={emptyClass}>{emptyContent}</div>}
        </div>
        {isApplyTableScrolling && <GridTableScrollbarVertical />}
        {isFirstRender && (
          <div className="grid-table__loading">
            <Spinner />
          </div>
        )}
      </div>
    </GridTableContext.Provider>
  );
}

GridTable.propTypes = {
  children: oneOfType([node]).isRequired,
  header: oneOfType([array]),
  rows: oneOfType([array]),
  rowsSelected: oneOfType([array]),
  top: number,
  maxHeight: number,
  variant: string,
  isResizable: bool,
  isSortable: bool,
  isHoverable: bool,
  isApplyTableScrolling: bool,
  isTableCenter: bool,
  isIgnoreLoading: bool,
  isShowRowTotal: bool,
  emptyContent: oneOfType([string, node]),
  onSelectRow: oneOfType([bool, func]),
  rowHeight: number,
  renderAhead: number,
};

GridTable.defaultProps = {
  header: [],
  rows: [],
  rowsSelected: [],
  top: null,
  variant: null,
  maxHeight: null,
  isResizable: false,
  isSortable: false,
  isHoverable: true,
  isApplyTableScrolling: false,
  isTableCenter: false,
  isIgnoreLoading: false,
  isShowRowTotal: false,
  onSelectRow: false,
  emptyContent: '表示するデータがありません',
  rowHeight: 38,
  renderAhead: 10,
};

export default GridTable;
