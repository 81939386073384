import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import jaLocale from 'moment/locale/ja';
import isEmpty from 'lodash/isEmpty';
import { CALENDAR_DAY_FORMAT } from 'domain/consts';
import { bool, func, object, objectOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler';

import EbisSingleDatePicker from 'views/molecules/single-date-picker';
import './singleDatepickerInput.scss';
import { Form } from 'react-bootstrap';

function EbisSingleDatePickerInput(props) {
  const {
    date: defaultDate,
    onChange,
    maxDate,
    minDate,
    onMouseEnter,
    onMouseLeave,
    error,
    disabled,
    className,
  } = props;
  moment.locale('ja', jaLocale);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState(defaultDate);

  const formatInputValue = (newDate) => {
    return newDate?.format(CALENDAR_DAY_FORMAT);
  };

  const [inputValue, setInputValue] = useState(formatInputValue(date));
  const [renderKey, setRenderKey] = useState(new Date().getTime());
  const setFocus = (focused) => setShowModal(focused);

  const onDateChange = (newDate) => {
    setInputValue(formatInputValue(newDate));
    setDate(newDate?.clone());
    setShowModal(false);
    onChange(newDate?.clone());
  };

  const onInputDateChange = (e) => {
    const { value } = e.target;
    // Handle clear value
    if (isEmpty(value)) {
      onDateChange(null);
    } else {
      let inputDate = moment(value, CALENDAR_DAY_FORMAT, true);
      if (inputDate.isValid()) {
        if (maxDate && inputDate.isAfter(maxDate)) {
          inputDate = maxDate.clone();
        }
        if (minDate && inputDate.isBefore(minDate)) {
          inputDate = minDate.clone();
        }
        setDate(inputDate);
        setInputValue(inputDate.format(CALENDAR_DAY_FORMAT));
      } else {
        setInputValue(value);
      }
    }

    if (!showModal) {
      setShowModal(true);
    }
  };

  const onInputKeyPress = (e) => {
    if (e.key !== 'Enter') {
      return;
    }
    setInputValue(date?.format(CALENDAR_DAY_FORMAT));
    onChange(date);
    setShowModal(!showModal);
  };

  const onOutsideClick = () => {
    if (showModal) {
      onChange(date);
      setInputValue(date?.format(CALENDAR_DAY_FORMAT));
      setShowModal(false);
    }
  };

  const inputClassName = classNames({
    'single-date-picker-input': true,
    error,
  });

  const clsName = classNames({
    'single-date-picker-input__modal': true,
    'single-date-picker-input__modal-visible': showModal,
    [className]: !!className,
  });

  useEffect(() => {
    setDate(defaultDate);
    setInputValue(formatInputValue(defaultDate));
  }, [defaultDate]);

  useEffect(() => {
    if (!showModal) {
      setRenderKey(new Date().getTime());
    }
  }, [showModal]);

  return (
    <div className="single-date-picker-input">
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <Form.Control
          size="sm"
          type="text"
          value={inputValue}
          onChange={onInputDateChange}
          className={inputClassName}
          onClick={() => setShowModal(!showModal)}
          onKeyPress={onInputKeyPress}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          disabled={disabled}
        />
        <div className={clsName}>
          <EbisSingleDatePicker
            key={renderKey}
            focus={showModal}
            setFocus={setFocus}
            date={date}
            maxDate={maxDate}
            minDate={minDate}
            onDateChange={(newDate) => {
              onDateChange(newDate);
            }}
          />
        </div>
      </OutsideClickHandler>
    </div>
  );
}

EbisSingleDatePickerInput.propTypes = {
  /**
   * <p>
   *  Set default value for input. <br />
   *  type: Moment
   * </p>
   */
  // eslint-disable-next-line react/forbid-prop-types
  date: object,
  /**
   * <p>
   * Handler when dates are changed. <br />
   * Args: date: type of Moment Object
   * </p>
   */
  onChange: func,

  onMouseEnter: func,
  onMouseLeave: func,

  maxDate: objectOf(oneOfType([object])),
  minDate: objectOf(oneOfType([object])),
  error: bool,
  disabled: bool,
  className: string,
};

EbisSingleDatePickerInput.defaultProps = {
  date: null,
  onChange: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  maxDate: moment(),
  minDate: moment().subtract(2, 'year'),
  error: false,
  disabled: false,
  className: '',
};

export default EbisSingleDatePickerInput;
