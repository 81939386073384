import React, { useState, useEffect, useRef } from 'react';
import { func, bool, shape } from 'prop-types';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import {
  MATCH_TYPES,
  MATCH_TYPE_DEFAULT,
  STEPS_FORM_CONTENT_CATEGORY,
} from 'domain/tag-management/consts';
import { PAGE_URLS, CONTENT_CATEGORY_NAME } from 'domain/fields';
import {
  getErrorsDataUrls,
  validateFormContentCategory,
  checkContainFieldError,
  formatErrorMessage,
} from 'services/tag-management/tagManagementServices';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import FormMatching from 'views/molecules/FormGroup/components/FormMatching';
import EbisStep from 'views/molecules/Step/EbisStep';
import { formatMatchTypeUrl } from 'domain/utils';

const defaultPageUrls = [{ url: '', match_type: MATCH_TYPE_DEFAULT }];

function ContentCategorySettingModal({
  isAdd,
  loading = false,
  isShow,
  detail,
  onSubmit,
  onHide,
}) {
  const {
    content_category_name: contentCategoryName = '',
    content_category_id: contentCategoryId,
    page_urls: pageUrls = [],
  } = detail;

  const scrollbarWrapperRef = useRef(null);

  const [valueField, setValueField] = useState({
    [CONTENT_CATEGORY_NAME]: '',
    [PAGE_URLS]: defaultPageUrls,
  });

  const [errorValueField, setErrorValueField] = useState({
    [CONTENT_CATEGORY_NAME]: null,
    [PAGE_URLS]: [],
  });

  const [currentStep, setCurrentStep] = useState(1);

  const steps = STEPS_FORM_CONTENT_CATEGORY.map((item) => ({
    ...item,
    active: item.step === currentStep,
  }));

  const isStep1 = currentStep === 1;

  useEffect(() => {
    if (isAdd) return;
    setValueField(() => ({
      [CONTENT_CATEGORY_NAME]: contentCategoryName,
      [PAGE_URLS]: pageUrls.length > 0 ? pageUrls : defaultPageUrls,
    }));
  }, [isAdd, pageUrls, contentCategoryName]);

  const handleChangeFieldValue = (name, value) => {
    setValueField((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    setErrorValueField((prevValue) => ({
      ...prevValue,
      [name]: name === PAGE_URLS ? [] : null,
    }));
  };
  const handleErrorsResponse = (errors = []) => {
    if (!isEmpty(errors)) {
      // check errors includes error page urls
      const includesErrorPageUrls = checkContainFieldError(errors, PAGE_URLS);

      if (includesErrorPageUrls) {
        const errorsPageUrls = getErrorsDataUrls({
          pageUrls: valueField[PAGE_URLS],
          errorsResponse: errors,
        });
        setValueField((prevValue) => ({
          ...prevValue,
          [PAGE_URLS]: errorsPageUrls,
        }));
      }

      // get errors not includes data errors page urls
      const errorsNotIncludesPageUrls = errors.filter(
        (error) => !error.field.includes(PAGE_URLS)
      );
      // get errorField
      const errorField = formatErrorMessage(errorsNotIncludesPageUrls);
      setErrorValueField(errorField);
    } else {
      setCurrentStep(2);
    }
  };

  const onSubmitSetting = () => {
    const dataSubmit = {
      [CONTENT_CATEGORY_NAME]: valueField[CONTENT_CATEGORY_NAME],
      [PAGE_URLS]: valueField[PAGE_URLS].filter((item) => !isEmpty(item.url)),
    };
    const validateResults = validateFormContentCategory(dataSubmit);
    const errors = {
      ...errorValueField,
      ...validateResults,
    };

    const error = Object.keys(errors)
      .filter((key) => !isEmpty(errors[key]))
      .reduce((acc, key) => ({ ...acc, [key]: errors[key] }), {});

    if (
      validateResults &&
      validateResults.page_urls &&
      validateResults.page_urls.length > 0
    ) {
      setValueField((prevValue) => ({
        ...prevValue,
        [PAGE_URLS]: validateResults.page_urls,
      }));
    }

    setErrorValueField(error);
    if (isEmpty(error)) {
      onSubmit(dataSubmit, handleErrorsResponse, contentCategoryId);
    }
  };

  const handleChangePageUrls = (item) => {
    handleChangeFieldValue(PAGE_URLS, item);
  };

  const handleClose = () => onHide(!isStep1);

  return (
    <Modal
      show={isShow}
      centered
      size="w800"
      dialogClassName="content-category__modal-setting"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {isAdd ? 'コンテンツカテゴリを追加' : 'コンテンツカテゴリを編集'}
        </Modal.Title>
        <EbisStep steps={steps} />
      </Modal.Header>
      <Modal.Body ref={scrollbarWrapperRef}>
        {isStep1 ? (
          <DataSyncLoader
            isLoading={loading}
            className={loading && 'content-category__modal-loading'}
          >
            <Form autoComplete="off" className='content-category__form'>
              <FormGroup
                required
                variant="content-category-name"
                label="コンテンツカテゴリ"
                note="255文字以内"
              >
                <FormInput
                  name={CONTENT_CATEGORY_NAME}
                  value={valueField[CONTENT_CATEGORY_NAME]}
                  error={errorValueField[CONTENT_CATEGORY_NAME] || ''}
                  onChange={handleChangeFieldValue}
                />
              </FormGroup>
              <FormGroup
                label="指定条件"
                tooltip="コンテンツカテゴリを特定ページのPVに紐づける際の条件をURLで指定できます。"
              />
              <div className="content-category__designation">
                <FormMatching
                  items={valueField[PAGE_URLS]}
                  options={MATCH_TYPES}
                  optionDefault={MATCH_TYPE_DEFAULT}
                  optionKey="match_type"
                  inputKey="url"
                  note="100件まで登録可、1,000文字以内"
                  placeholder="https://"
                  onChange={handleChangePageUrls}
                  maxItem={100}
                  minItem={1}
                />
              </div>
            </Form>
          </DataSyncLoader>
        ) : (
          <ScrollbarWrapper
            ref={scrollbarWrapperRef}
            maxContent={533}
            alignScroll={-20}
          >
          <Table striped bordered>
            <thead>
              <tr>
                <th className="content-category__left-column">
                  コンテンツカテゴリ
                </th>
                <th className="content-category__right-column">
                  {valueField[CONTENT_CATEGORY_NAME]}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="content-category__left-column">指定条件</td>
                <td>
                  {valueField[PAGE_URLS].length > 0 &&
                  !valueField[PAGE_URLS].every((item) => isEmpty(item.url)) ? (
                    formatMatchTypeUrl(
                      valueField[PAGE_URLS].filter((item) => !isEmpty(item.url))
                    ).map((pageUrl) => (
                      <div className="content-category__pd-bottom">
                        {pageUrl}
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="content-category__pd-bottom">未登録</div>
                      <span className="content-category__note">
                        ※ページIDで指定する場合は、「PV計測タグ」ページの追加・編集よりコンテンツカテゴリをご選択ください。
                      </span>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          </ScrollbarWrapper>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="link"
          disabled={loading}
          onClick={handleClose}
        >
          {isStep1 ? 'キャンセル' : '閉じる'}
        </Button>
        {isStep1 && (
          <Button
            size="sm"
            variant="secondary"
            disabled={loading || !valueField[CONTENT_CATEGORY_NAME]}
            onClick={onSubmitSetting}
          >
            OK
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

ContentCategorySettingModal.propTypes = {
  isAdd: bool.isRequired,
  loading: bool.isRequired,
  isShow: bool.isRequired,
  detail: shape({}).isRequired,
  onHide: func.isRequired,
  onSubmit: func.isRequired,
};

export default ContentCategorySettingModal;
