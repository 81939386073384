export const GRID_TABLE_ID = 'grid-table';
export const FIELD_TOTAL = 'field_total';
export const ROW_TOTAL = -1;
export const NONE_SPAN = 1;
export const WRONG_NUMBER = 1;// Allow for browser reporting a difference of 1px when no scrollbar is present  (a small arbitrary number)
export const HEIGHT_ROW_PARENT = 31; // px
export const HEIGHT_ROW_CHILD = 28; // px
export const MIN_WIDTH_TABLE = 100; // px
export const MAX_WIDTH_COLUMN = 800; // px
export const MIN_WIDTH_COLUMN = 100; // px
export const MIN_WIDTH_COLUMN_FREEZE = 151; // px
export const MAX_CONTENT_WIDTH_COLUMN = 'max-content';
export const FULL_WIDTH_COLUMN = '100%';
