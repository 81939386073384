import React, {
  useRef,
  useCallback,
  useEffect,
  useState
} from 'react';
import { bool, arrayOf, func, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import {
  REDIRECT_FLAG,
  EMAIL_NOTIFICATION,
  SEND_MAIL_USERS,
} from 'domain/fields';
import {
  STEP_SETTING_DNS_SUBDOMAIN,
  STEP_CONFIRM,
  STEP_CONFIRM_EDIT,
} from 'domain/system-setting/consts';
import { NS_CNAME_EMAIL_HELP_LINK } from 'services/routes/constants';
import { formatDnsSettingEmailData, validateDnsEmailSetting } from 'services/system-setting/systemSettingServices';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import { getEmail, getUserId } from 'store/auth/selectors';
import MailInfoSetting from 'views/pages/system-setting/components/MailInfoSetting';
import 'views/pages/system-setting/components/mail-setting-modal.scss';
import EbisStep from 'views/molecules/Step/EbisStep';

function DnsSettingEmailModal(props) {
  const {
    onHide,
    isLoading,
    listItemUpdate,
    userMasterEmailData,
    steps,
    title,
    isEdit,
    handleSelectRow,
    setCurrentStep,
    mailFormValue,
    SetMailFormValue,
    hasUserEditPermission,
    errorForm,
    setErrorForm,
  } = props;

  const scrollbarWrapperRef = useRef(null);

  const email = useSelector(getEmail, shallowEqual);
  const userId = useSelector(getUserId, shallowEqual);

  const handleChangeValue = useCallback(
    (name, value) => {
      SetMailFormValue((prevData) => ({
        ...prevData,
        [name]: { ...prevData[name], error: null, value },
      }));
    },
    []
  );

  const isEmailNotSet = () => {
    if (hasUserEditPermission && mailFormValue.send_mail_flag.value && mailFormValue.send_mail_users.value.length === 0) {
      return true;
    }
    return false;
  };

  const handleNextStep = (nextStep) => {
    if (!hasUserEditPermission && mailFormValue.send_mail_flag.value) {
      mailFormValue.send_mail_users.value = [userId];
    }
    if (!isEmpty(validateDnsEmailSetting(mailFormValue))) {
      setErrorForm(validateDnsEmailSetting(mailFormValue));
    } else {
      setErrorForm({});
      setCurrentStep(nextStep);
    }
  };

  const handleClose = (isReload = false) => () => onHide(isReload);

  useEffect(() => {
    if (listItemUpdate !== "undefined") {
      const data = formatDnsSettingEmailData(listItemUpdate, mailFormValue, isEdit);
      SetMailFormValue(data);
    }
  }, [listItemUpdate]);

  const loaderClass = classNames({
    'modal-loader': true,
  });

  const footerClass = classNames({
    'modal-footer': true,
  });

  return (
    <Modal
      show
      centered
      size="w800"
      dialogClassName="modal-email-setting"
      contentClassName="modal-email--settings"
    >
      <Modal.Header className="justify-content-start">
        <Modal.Title>{title}</Modal.Title>
        {steps && (
          <EbisStep steps={steps} />
        )}
      </Modal.Header>
      <Modal.Body ref={scrollbarWrapperRef}>
        <DataSyncLoader
          isLoading={isLoading}
          className={loaderClass}
        >
          <>
            <div className="dns-description-email">
              登録されているドメインに対して一定時間疎通が確認できなかった場合に、アラートメールを送信します。<br />
              リダイレクト計測にチェックをいれたドメインは、アラートメールの送信先を1件以上設定いただく必要があります。<br />
              詳しくは<a href={NS_CNAME_EMAIL_HELP_LINK} target="_blank" rel="noopener noreferrer">こちら</a>。
            </div>
            {errorForm && errorForm[REDIRECT_FLAG] && (
              <div className="dns-error__email-main-message">{errorForm[REDIRECT_FLAG]}</div>
            )}
            {errorForm && errorForm[EMAIL_NOTIFICATION] && (
              <div className="dns-error__email-main-message">{errorForm[EMAIL_NOTIFICATION]}</div>
            )}
            {errorForm && errorForm[SEND_MAIL_USERS] && (
              <div className="dns-error__email-main-message">{errorForm[SEND_MAIL_USERS]}</div>
            )}
            <MailInfoSetting
              info={mailFormValue}
              onChange={handleChangeValue}
              onSelectRow={handleSelectRow}
              hasUserEditPermission={hasUserEditPermission}
              listItemUpdate={listItemUpdate}
              userMasterEmailData={userMasterEmailData}
              email={email}
            />
          </>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer className={footerClass}>
        {isEdit ? (
          <>
            <Button
              size="sm"
              variant="link"
              disabled={isLoading}
              onClick={handleClose(true)}
            >
              キャンセル
            </Button>
            <Button
              size="sm"
              variant="secondary"
              className="m-0"
              disabled={isLoading || isEmailNotSet()}
              onClick={() => handleNextStep(STEP_CONFIRM_EDIT)}
            >
              次へ
            </Button>
          </>
        ) : (
          <>
            <Button
              size="sm"
              variant="link"
              disabled={isLoading}
              onClick={() => setCurrentStep(STEP_SETTING_DNS_SUBDOMAIN)}
            >
              戻る
            </Button>
            <Button
              size="sm"
              variant="secondary"
              className="m-0"
              disabled={isLoading || isEmailNotSet()}
              onClick={() => handleNextStep(STEP_CONFIRM)}
            >
              次へ
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

DnsSettingEmailModal.propTypes = {
  onHide: func.isRequired,
  isLoading: bool,
  listItemUpdate: arrayOf({}),
  userMasterEmailData: arrayOf({}),
  steps: arrayOf({}),
  title: string,
  isEdit: bool,
  handleSelectRow: func.isRequired,
  setCurrentStep: func,
  mailFormValue: arrayOf({}),
  SetMailFormValue: arrayOf({}),
  hasUserEditPermission: bool,
  errorForm: arrayOf({}),
  setErrorForm: arrayOf({}),
};

DnsSettingEmailModal.defaultProps = {
  isLoading: false,
  listItemUpdate: [],
  userMasterEmailData: [], // original var name is "masterData"
  steps: [],
  title: '',
  isEdit: false,
  setCurrentStep: () => {},
  mailFormValue: [], // original var name is "mailFormValue"
  SetMailFormValue: [], // original var name is "setDataField"
  hasUserEditPermission: false,
  errorForm: [],
  setErrorForm: [],
};

export default DnsSettingEmailModal;
