import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { bool, string } from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useCheckPermissionSettingReport from 'services/custom-hooks/useCheckPermissionSettingReport';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ListReportContainer from 'views/pages/data-export/ListReportContainer';
import SettingReportContainer from 'views/pages/data-export/SettingReportContainer';
import { FormWrapper } from 'views/molecules/Form';
import { loggerActions } from 'store/logger';
import pages from 'services/routes/pages';
import { DATA_EXPORT } from 'services/routes/constants';
import dataExportActions from 'store/data-export/actions';
import dataExportSelectors from 'store/data-export/selectors';
import settingsSelectors from 'store/settings/selectors';
import './data-export.scss';

/**
 * Will trigger loading when access screen.
 * @return {JSX.Element}
 */

const DataExportScreen = ({
  isSettingMode,
  reportId,
  statusSystemSync,
  isAllowedSettingAdrepoReport,
  isAllowRegisterAdrepoReport,
  isAllowedSettingNormalReport,
  isAllowRegisterNormalReport,
  isAllowedSettingDataExport,
  isAllowRegisterRegularReport,
  isAllowRegisterDataExport,
}) => {
  return isSettingMode ? (
    <FormWrapper>
      <SettingReportContainer
        reportId={reportId}
        statusSystemSync={statusSystemSync}
        isAllowedSettingAdrepoReport={isAllowedSettingAdrepoReport}
        isAllowRegisterAdrepoReport={isAllowRegisterAdrepoReport}
        isAllowedSettingNormalReport={isAllowedSettingNormalReport}
        isAllowRegisterNormalReport={isAllowRegisterNormalReport}
        isAllowedSettingDataExport={isAllowedSettingDataExport}
        isAllowRegisterRegularReport={isAllowRegisterRegularReport}
        isAllowRegisterDataExport={isAllowRegisterDataExport}
      />
    </FormWrapper>
  ) : (
    <ListReportContainer />
  );
};

DataExportScreen.propTypes = {
  isSettingMode: bool.isRequired,
  reportId: string.isRequired,
  statusSystemSync: bool.isRequired,
  isAllowedSettingAdrepoReport: bool.isRequired,
  isAllowRegisterAdrepoReport: bool.isRequired,
  isAllowedSettingNormalReport: bool.isRequired,
  isAllowRegisterNormalReport: bool.isRequired,
  isAllowedSettingDataExport: bool.isRequired,
  isAllowRegisterRegularReport: bool.isRequired,
  isAllowRegisterDataExport: bool.isRequired,
};

function DataExportLoader() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mode, reportId } = useParams();

  const isLoadingMetadata = useSelector(
    settingsSelectors.getLoadingByStatus([dataExportSelectors.getMetadata]),
    shallowEqual
  );

  const isLoadingMasterdata = useSelector(
    settingsSelectors.getLoadingByStatus([
      dataExportSelectors.getStatusMasterdata,
    ]),
    shallowEqual
  );

  const {
    maxItems: { maxItemDataExport, maxItemAdrepo, maxItemRegularReport },
    allowRegister: {
      regularReport: isAllowRegisterRegularReport,
      normalReport: isAllowRegisterDataExport,
      adrepoReport: isAllowRegisterAdrepoReport,
    },
  } = useSelector(dataExportSelectors.getSettings, shallowEqual);

  const isAllowRegisterNormalReport =
    isAllowRegisterRegularReport || isAllowRegisterDataExport;

  const {
    isAllowedSettingReport,
    isAllowedSettingAdrepoReport,
    isAllowedSettingNormalReport,
    isAllowedSettingDataExport,
  } = useCheckPermissionSettingReport();

  const isSettingMode = mode === 'setting';

  const [{ statusSystemSync, isFetchingDataSystemSync }] = [
    useSelector(dataExportSelectors.getStatesForSystemSync, shallowEqual),
  ];

  useEffect(() => {
    dispatch(dataExportActions.getDataSystemSync());
  }, [dispatch]);

  useEffect(() => {
    if (isSettingMode && isLoadingMetadata) {
      dispatch(dataExportActions.getMetadata());
    }
  }, [dispatch, isSettingMode, isLoadingMetadata]);

  useEffect(() => {
    if (isSettingMode) {
      dispatch(dataExportActions.getMasterdata());
    }
  }, [dispatch, isSettingMode]);

  // Show error modal if no access permission
  if (isSettingMode) {
    let contentError = '';
    if (!isAllowedSettingReport) {
      contentError = 'データエクスポートを操作する権限がありません。';
    } else if (!reportId) {
      if (
        isAllowedSettingNormalReport &&
        !isAllowRegisterNormalReport &&
        (!isAllowedSettingAdrepoReport || !isAllowRegisterAdrepoReport)
      ) {
        contentError = `データエクスポートの登録上限数(${
          maxItemRegularReport + maxItemDataExport
        }件)を超えてしまうため、登録することができません。`;
      } else if (
        isAllowedSettingAdrepoReport &&
        !isAllowRegisterAdrepoReport &&
        (!isAllowedSettingNormalReport || !isAllowRegisterNormalReport)
      ) {
        contentError = `最大${maxItemAdrepo}件登録することができます。`;
      }
    }

    if (contentError) {
      dispatch(
        loggerActions.logConfirmModal({
          title: 'データ更新に失敗しました',
          content: contentError,
          callback: () => history.push(pages[DATA_EXPORT].path),
        })
      );
      return <></>;
    }
  }

  return (
    <DataSyncLoader
      isLoading={
        isFetchingDataSystemSync ||
        (isSettingMode && (isLoadingMetadata || isLoadingMasterdata))
      }
    >
      <DataExportScreen
        isSettingMode={isSettingMode}
        reportId={reportId}
        statusSystemSync={statusSystemSync}
        isAllowedSettingAdrepoReport={isAllowedSettingAdrepoReport}
        isAllowRegisterAdrepoReport={isAllowRegisterAdrepoReport}
        isAllowedSettingNormalReport={isAllowedSettingNormalReport}
        isAllowRegisterNormalReport={isAllowRegisterNormalReport}
        isAllowedSettingDataExport={isAllowedSettingDataExport}
        isAllowRegisterRegularReport={isAllowRegisterRegularReport}
        isAllowRegisterDataExport={isAllowRegisterDataExport}
      />
    </DataSyncLoader>
  );
}

export default DataExportLoader;
