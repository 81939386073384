export default {
  // normal table types
  GET_DATA_TABLE: 'log-period-analyze/GET_DATA_TABLE',
  SET_DATA_TABLE: 'log-period-analyze/SET_DATA_TABLE',
  SET_STATUS_TABLE: 'log-period-analyze/SET_STATUS_TABLE',

  GET_DATA_CHART: 'log-period-analyze/GET_DATA_CHART',
  SET_STATUS_CHART: 'log-period-analyze/SET_STATUS_CHART',
  SET_DATA_CHART: 'log-period-analyze/SET_DATA_CHART',

  GET_SETTING: 'log-period-analyze/GET_SETTING',
  SET_SETTING: 'log-period-analyze/SET_SETTING',

  SET_ERRORS: 'log-period-analyze/SET_ERRORS',

  CHANGE_SORT: 'log-period-analyze/CHANGE_SORT',
  CHANGE_AXIS: 'log-period-analyze/CHANGE_AXIS',
  TOGGLE_CHART: 'log-period-analyze/TOGGLE_CHART',
  SAVE_BOOKMARK: 'log-period-analyze/SAVE_BOOKMARK',
  CHANGE_CATEGORY: 'log-period-analyze/CHANGE_CATEGORY',
  CHANGE_SORT_TABLE: 'log-period-analyze/CHANGE_SORT_TABLE',
  REMOVE_SECONDARY_METRIC: 'log-period-analyze/REMOVE_SECONDARY_METRIC',

  // compared table types
  GET_DATA_COMPARED_TABLE: 'log-period-analyze/GET_DATA_COMPARED_TABLE',
  SET_DATA_COMPARED_TABLE: 'log-period-analyze/SET_DATA_COMPARED_TABLE',
  SET_STATUS_COMPARED_TABLE: 'log-period-analyze/SET_STATUS_COMPARED_TABLE',
  CHANGE_ACTIVE_COMPARED_TABLE:
    'log-period-analyze/CHANGE_ACTIVE_COMPARED_TABLE',
  CHANGE_SORT_COMPARED_TABLE: 'log-period-analyze/CHANGE_SORT_COMPARED_TABLE',

  // memo types
  CHANGE_ACTIVE_PERIOD: 'log-period-analyze/CHANGE_ACTIVE_PERIOD',
  TOGGLE_MEMO: 'log-period-analyze/TOGGLE_MEMO',
  CREATE_MEMO: 'log-period-analyze/CREATE_MEMO',
  CREATE_MEMO_SUCCESS: 'log-period-analyze/CREATE_MEMO_SUCCESS',
  CREATE_MEMO_ERROR: 'log-period-analyze/CREATE_MEMO_ERROR',
  CHANGE_ACTIVE_TABLE: 'log-period-analyze/CHANGE_ACTIVE_TABLE',
  SHOW_MEMO_MODAL: 'log-period-analyze/SHOW_MEMO_MODAL',
  HIDE_MEMO_MODAL: 'log-period-analyze/HIDE_MEMO_MODAL',
  SHOW_DELETE_MODAL: 'log-period-analyze/SHOW_DELETE_MODAL',
  HIDE_DELETE_MODAL: 'log-period-analyze/HIDE_DELETE_MODAL',
  SHOW_MEMO_ERROR_MODAL: 'log-period-analyze/SHOW_MEMO_ERROR_MODAL',
  HIDE_MEMO_ERROR_MODAL: 'log-period-analyze/HIDE_MEMO_ERROR_MODAL',
  UPDATE_CHART_DISPLAY_MEMO: 'periodAnalysis/UPDATE_CHART_DISPLAY_MEMO',

  LOAD_MEMO: 'log-period-analyze/LOAD_MEMO',
  LOAD_MEMO_SUCCESS: 'log-period-analyze/LOAD_MEMO_SUCCESS',
  LOAD_MEMO_FAILED: 'log-period-analyze/LOAD_MEMO_FAILED',

  DELETE_MEMO: 'log-period-analyze/DELETE_MEMO',
  DELETE_MEMO_SUCCESS: 'log-period-analyze/DELETE_MEMO_SUCCESS',
  DELETE_MEMO_FAILED: 'log-period-analyze/DELETE_MEMO_FAILED',

  UPDATE_MEMO: 'log-period-analyze/UPDATE_MEMO',
  UPDATE_MEMO_SUCCESS: 'log-period-analyze/UPDATE_MEMO_SUCCESS',
  UPDATE_MEMO_FAILED: 'log-period-analyze/UPDATE_MEMO_FAILED',

  UPDATE_MEMO_LIST: 'log-period-analyze/UPDATE_MEMO_LIST',
  SET_MEMO_SUBMITTING: 'log-period-analyze/SET_MEMO_SUBMITTING',
  SET_MEMO_ERRORS: 'log-period-analyze/SET_MEMO_ERRORS',

  CHANGE_PERIOD_TYPE: 'log-period-analyze/CHANGE_PERIOD_TYPE',
};
