import { put, takeLatest, select, call, all, take } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import sharedSagas from 'store/sharedSagas';
import loggerConstants from 'store/logger/constant';
import loggerActions from 'store/logger/actions';
import commonActions from 'store/common/actions';
import masterdataActions from 'store/master-data/actions';
import masterdataSelectors from 'store/master-data/selectors';
import masterdataTypes from 'store/master-data/types';
import {
  getAccountId,
  getUserId,
  getPermissions,
  checkAllowedSettingReport,
} from 'store/auth/selectors';
import { communicationStatus, HttpStatus, sortByKey } from 'services/utils';
import handleError from 'services/error/handleScopeError';
import DataExportApi from 'services/api/DataExportApi';
import {
  customizeDisplayName,
  formatDisplayItemByReportTypes,
  getStatusSystemSync,
  prepareFilterForUIFromView,
  prepareSettingReportForUI,
  getDisplayItemIdsByView,
  masterDataIdsSelected,
  getMasterdataDisplayItem,
  checkIsRegularReport,
  prepareFilterForUIRegularReport,
  prepareDisplayItemForUIRegularReport,
  prepareView,
} from 'services/data-export/dataExportService';
import {
  REPORT_TYPE_CHANNEL,
  REPORT_TYPE_CV_ATTRIBUTE,
  REPORT_TYPE_ACCESS,
  DISPLAYITEM_GROUP_TIME,
  DISPLAYITEM_KEY_MAPPER,
  CONTACT_HISTORIES,
  MAX_DIMENSION,
  MAX_METRIC,
  REPORT_TYPES_DATA_EXPORT,
  REPORT_FIELDS,
} from 'domain/data-export/consts';
import { SETTING_USER_EDIT } from 'domain/permissions/contractPermissions';
import { API_UNLIMITED_VALUE } from 'domain/consts';
import FilterService from 'domain/FilterService';
import types from './types';
import actions from './actions';
import selectors from './selectors';

const { BAD_REQUEST } = HttpStatus;
const { LOADING, SUCCEEDED } = communicationStatus;
const { LOG_LEVEL_ERROR } = loggerConstants;

const {
  DISPLAY_ITEMS,
  FILTERS,
  QUICK_SEARCH,
  DIMENSIONS,
  METRICS,
  CONTACT_HISTORY,
} = REPORT_FIELDS;

function* errorHandler(err, action) {
  const { callback = () => {}, data = {} } = action.payload || {};
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [];

  switch (action.type) {
    case types.GET_DETAIL:
    case types.CREATE:
    case types.UPDATE:
      yield put(actions.setErrorDetail(errors, scope));
      break;
    case types.GET_LIST:
    case types.DOWNLOAD_CSV:
    case types.DELETE_REPORT:
      yield put(actions.setErrorList(errors, scope));
      break;
    default:
      yield put(loggerActions.logError(errors, LOG_LEVEL_ERROR));
      break;
  }

  if (
    [
      types.GET_DETAIL,
      types.CREATE,
      types.UPDATE,
      types.GET_LIST_HISTORY,
      types.DELETE_REPORT,
      types.DOWNLOAD_CSV,
    ].includes(action.type)
  ) {
    callback({ action: action.type, isError: true, errors, data });
  }
}

function* handleGetMetadata() {
  yield put(actions.setSatusMetadata(LOADING));
  const { data } = yield call(DataExportApi.fetchMetadata);
  yield put(actions.setMetadata(data.data));
}

function* getMasterdata() {
  yield put(actions.setStatusMasterdata(LOADING));

  yield put(masterdataActions.fetchAll());

  const display = yield select(masterdataSelectors.getDisplay);
  const permissions = yield select(getPermissions);
  const { isAllowedSettingDataExport } = yield select(
    checkAllowedSettingReport
  );

  let response = {};

  // Get displayitem for regular report
  response.displayItem = getMasterdataDisplayItem({ permissions, display });

  // Get list users
  if (permissions.includes(SETTING_USER_EDIT)) {
    const userRes = yield call(DataExportApi.fetchUsers);
    const [accountId, userId] = [
      yield select(getAccountId),
      yield select(getUserId),
    ];
    response.users = userRes.data.data.detail.filter(
      (user) => ![accountId, userId].includes(user.user_id)
    );
  }

  // get views/dimensions/metrics
  const callApiActions = [call(DataExportApi.fetchViews)];

  // Only get masterdata for the first time
  const { dimension, metric } = yield select(selectors.getMasterdata);
  const isMasterdataEmpty = isEmpty(dimension.item) || isEmpty(metric.item);
  if (isMasterdataEmpty && isAllowedSettingDataExport) {
    callApiActions.push(call(DataExportApi.fetchDimensions));
    callApiActions.push(call(DataExportApi.fetchMetrics));
  }

  const [viewRes, dimensionRes, metricRes] = yield all(callApiActions);

  response.views = prepareView(viewRes.data.data);

  if (isMasterdataEmpty && isAllowedSettingDataExport) {
    const metrics = sortByKey(metricRes.data.data, 'display_order');
    const dimensions = customizeDisplayName(
      sortByKey(dimensionRes.data.data, 'display_order'),
      {
        ...display,
        group1_name: display.ad_group1,
        group2_name: display.ad_group2,
      }
    );
    response = {
      ...response,
      dimension: {
        item: dimensions.reduce((acc, item) => {
          return { ...acc, [item.id]: item };
        }, {}),
        type: formatDisplayItemByReportTypes(
          dimensions,
          REPORT_TYPES_DATA_EXPORT
        ),
      },
      metric: {
        item: metrics.reduce((acc, item) => {
          return { ...acc, [item.id]: item };
        }, {}),
        type: formatDisplayItemByReportTypes(metrics, [
          REPORT_TYPE_CHANNEL,
          REPORT_TYPE_ACCESS,
        ]),
      },
      allowDisplayRowZero: dimensions
        .filter(
          (item) =>
            item.group_name === DISPLAYITEM_GROUP_TIME ||
            ['latency_time_group', 'contact_count'].includes(item.field_name)
        )
        .map((item) => item.id),
    };
  }

  yield put(actions.setMasterdata(response));
}

function* handleGetList() {
  yield put(actions.setStatusList(LOADING));
  const { data } = yield call(DataExportApi.fetchList);

  yield put(actions.setMetadata(data.metadata));
  yield put(actions.setDataList({ lists: data.data }));
}

function* handleGetListHistory(action) {
  const { id } = action.payload;
  yield put(actions.setStatusListHistory(LOADING));
  const {
    data: { data },
  } = yield call(DataExportApi.fetchListHistory, id);
  yield put(actions.setDataListHistory(data));
}

function* getDetail(action) {
  yield put(actions.setStatusDetail(LOADING));

  const {
    data: { data },
  } = yield call(DataExportApi.fetchDetail, action.payload.id);

  // get & save to store masterdata selected
  const masterDataIds = masterDataIdsSelected(data);
  yield put(masterdataActions.updateMasterData({ masterDataIds }));
  yield take(masterdataTypes.UPDATE_MASTERDATA_SUCCEEDED);

  const permissions = yield select(getPermissions);
  const masterdata = yield select(masterdataSelectors.getAll);
  const { displayItem } = yield select(selectors.getMasterdata);

  yield put(
    actions.setDataDetail(
      prepareSettingReportForUI(data, masterdata, permissions, displayItem)
    )
  );
}

function* create(action) {
  yield put(actions.setStatusDetail(LOADING));

  const { callback, data: dataRequest } = action.payload;

  const endpointFunc = checkIsRegularReport(dataRequest.type)
    ? DataExportApi.createRegularReport
    : DataExportApi.create;

  yield call(endpointFunc, dataRequest);

  callback({ action: action.type });
}

function* update(action) {
  yield put(actions.setStatusDetail(LOADING));

  const { callback, id, data: dataRequest } = action.payload;

  const endpointFunc = checkIsRegularReport(dataRequest.type)
    ? DataExportApi.updateRegularReport
    : DataExportApi.update;

  yield call(endpointFunc, id, dataRequest);

  callback({ action: action.type });
}

function* handleDelete(action) {
  const { ids, callback } = action.payload;
  yield call(DataExportApi.delete, ids);
  callback({ isError: false });
}

function* handleDownloadCsv(action) {
  const { id } = action.payload;
  yield put(commonActions.setDownloadNotify('CSVファイルを作成しています...'));
  yield call(DataExportApi.downloadCsv, id);
  yield put(commonActions.setDownloadNotify(''));
}

function* handleGetDataSystemSync() {
  const {
    data: { data },
  } = yield call(DataExportApi.fetchStatusSystemSyncDataExport);
  yield put(
    actions.setStatusSystemSync({
      statusFetching: SUCCEEDED,
      statusSystemSync: getStatusSystemSync(data),
    })
  );
}

function* handleApplyView(action) {
  const {
    id,
    params: { type, channel },
    callback = () => {},
  } = action.payload;
  const {
    data: { data },
  } = yield call(DataExportApi.fetchView, id);

  const permissions = yield select(getPermissions);

  // Apply filter
  const { filters } = data;
  const masterDataIds = FilterService.getMasterDataIdsSelected(filters);
  yield put(masterdataActions.updateMasterData({ masterDataIds }));
  yield take(masterdataTypes.UPDATE_MASTERDATA_SUCCEEDED);
  const masterdata = yield select(masterdataSelectors.getAll);

  // Apply displayitems
  const { displayItem, dimension, metric } = yield select(
    selectors.getMasterdata
  );
  const [viewDisplayItems] = Object.values(data.display_items);

  let response = {};

  if (checkIsRegularReport(type)) {
    response = {
      ...response,
      [DISPLAY_ITEMS]: prepareDisplayItemForUIRegularReport({
        type,
        items: displayItem[type][channel].item,
        settings: {
          ...displayItem[type][channel].settingDefault,
          ...viewDisplayItems,
        },
      }),
      [FILTERS]: prepareFilterForUIRegularReport({
        type,
        channel,
        masterdata,
        permissions,
        filters,
      }),
    };
  } else {
    const isCvReport = type === REPORT_TYPE_CV_ATTRIBUTE;
    response = {
      ...response,
      [QUICK_SEARCH]: prepareFilterForUIFromView({
        filters,
        reportType: type,
        permissions,
        masterdata,
      }),
      [DIMENSIONS]: getDisplayItemIdsByView({
        displayItems: dimension.type[type]?.groups || [],
        view: viewDisplayItems,
        maxItems: isCvReport ? API_UNLIMITED_VALUE : MAX_DIMENSION,
      }),
    };
    if (isCvReport) {
      response[CONTACT_HISTORY] = CONTACT_HISTORIES.reduce((acc, item) => {
        const isChecked = (DISPLAYITEM_KEY_MAPPER[item.name] || []).some(
          (field) => viewDisplayItems[field]
        );
        if (isChecked || viewDisplayItems[item.name]) {
          return [...acc, item.name];
        }
        return acc;
      }, []);
    } else {
      response[METRICS] = getDisplayItemIdsByView({
        displayItems: metric.type[type]?.groups || [],
        view: viewDisplayItems,
        maxItems: MAX_METRIC,
      });
    }
  }

  callback({
    view: { id: data.id, name: data.view_name },
    data: response,
  });
}

export default function* dataExportWatchView() {
  yield takeLatest(
    types.GET_METADATA,
    sharedSagas.safe(errorHandler, handleGetMetadata)
  );
  yield takeLatest(
    types.GET_MASTERDATA,
    sharedSagas.safe(errorHandler, getMasterdata)
  );
  yield takeLatest(
    types.GET_LIST,
    sharedSagas.safe(errorHandler, handleGetList)
  );
  yield takeLatest(
    types.GET_LIST_HISTORY,
    sharedSagas.safe(errorHandler, handleGetListHistory)
  );
  yield takeLatest(types.GET_DETAIL, sharedSagas.safe(errorHandler, getDetail));
  yield takeLatest(types.CREATE, sharedSagas.safe(errorHandler, create));
  yield takeLatest(types.UPDATE, sharedSagas.safe(errorHandler, update));
  yield takeLatest(
    types.DELETE_REPORT,
    sharedSagas.safe(errorHandler, handleDelete)
  );
  yield takeLatest(
    types.DOWNLOAD_CSV,
    sharedSagas.safe(errorHandler, handleDownloadCsv)
  );
  yield takeLatest(
    types.GET_DATA_SYSTEM_SYNC,
    sharedSagas.safe(errorHandler, handleGetDataSystemSync)
  );
  yield takeLatest(
    types.APPLY_VIEW,
    sharedSagas.safe(errorHandler, handleApplyView)
  );
}
