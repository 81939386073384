import {
  takeLatest,
  delay,
  call,
  put,
  all,
  select,
  fork,
} from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { MASTER_DATA_TYPE } from 'domain/consts';
import { AGENCY_ID, CONTENT_CATEGORY_ID } from 'domain/fields';
import metadataService from 'services/common/metadataService';
import MasterDataApi from 'services/api/MasterDataApi';
import { convertArray2Object } from 'domain/utils';
import {
  AGENCY_MANAGEMENT,
  MEASUREMENT_SITE_PAGE_MANAGEMENT,
  TAG_MANAGEMENT,
} from 'services/routes/constants';

import mediaService from 'services/api/settings/MediaService';
import adGroup1Service from 'services/api/settings/AdGroup1Service';
import adGroup2Service from 'services/api/settings/AdGroup2Service';
import {
  mediaAccountService,
  mediaSideCampaignService,
  mediaSideGroupService,
} from 'services/api/settings/MediaSideService';

import adManagementActions from 'store/ad-management/actions';
import {
  checkHasContractAgent,
  checkHasContractLog,
  checkHasContractLogForPageManagement,
} from 'store/auth/selectors';

import TagManagementApi from 'services/api/TagManagementApi';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';

import filtersSelector from 'store/filters/selectors';
import filterActions from 'store/filters/actions';
import FilterService from 'domain/FilterService';

import settingsSelectors from 'store/settings/selectors';
import { DisplayItemsSelectors } from 'store/display-items';

import {
  FILTER_KEY_MEDIA_ACCOUNT,
  FILTER_KEY_MEDIA_SIDE_CAMPAIGN,
  FILTER_KEY_MEDIA_SIDE_GROUP,
} from 'services/consts';
import types from './types';
import actions from './actions';
import selectors from './selectors';

const { REPORT, SETTING } = MASTER_DATA_TYPE;

function* fetchConversions() {
  const conversions = yield call(metadataService.fetchConversions);
  yield put(actions.updateConversions(conversions));
}

const isExpired = (expires) => {
  return new Date().getTime() > expires;
};

function* fetchCv(action) {
  try {
    const { search, selected, limit, offset } = action.payload;
    yield put(actions.requestStart());
    const cv = yield call(
      MasterDataApi.fetchCv,
      search,
      selected,
      offset,
      limit
    );
    yield put(actions.fetchCvSucceeded(convertArray2Object(cv.data.data)));
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchAgency() {
  try {
    yield put(actions.requestStart());
    const response = yield call(MasterDataApi.fetchAgency);
    yield put(
      actions.fetchAgencySucceeded(convertArray2Object(response.data.data))
    );

    // Reset search result to empty
    const searchType = yield select(selectors.getSearchType);
    if (searchType === AGENCY_ID) {
      yield put(actions.searchSucceeded({ items: {}, total: 0, type: null }));
    }
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchContentCategory() {
  try {
    yield put(actions.requestStart());
    const {
      data: { data },
    } = yield call(TagManagementApi.getContentCategory);
    yield put(actions.fetchContentCategorySucceeded(convertArray2Object(data)));

    // Reset search result to empty
    const searchType = yield select(selectors.getSearchType);
    if (searchType === CONTENT_CATEGORY_ID) {
      yield put(actions.searchSucceeded({ items: {}, total: 0, type: null }));
    }
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchMedia(action) {
  try {
    yield put(actions.requestStart());
    const agencyId = action.payload;
    const response = yield call(
      mediaService.search,
      '',
      [],
      undefined,
      undefined,
      agencyId
    );
    const { data: mediaData, selected: mediaSelected } = response;

    yield put(
      actions.fetchMediaSucceeded(
        convertArray2Object([...mediaSelected, ...mediaData])
      )
    );

    // Reset search result to empty
    yield put(actions.searchSucceeded({ items: {}, total: 0, type: null }));
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchAll(action) {
  const { type: typeRequest, ignoreAgency = false, isAgencyScreen = false } =
    action.payload || {};
  const isTypeReport = typeRequest === REPORT;
  try {
    const [type, expires] = [
      yield select(selectors.getType),
      yield select(selectors.getExpires),
    ];

    // check expires time to skip fetch data
    if (!isExpired(expires) && type === REPORT && isTypeReport) return;

    yield put(actions.setType(typeRequest));

    yield put(actions.requestStart());

    // get data filters
    const filters = yield select(filtersSelector.getFilters);
    const selectedFilters = yield select(filtersSelector.getSettings);

    const filtersContainer = { ...filters, ...selectedFilters };
    const {
      convIds,
      mediaIds,
      adGroup1Ids,
      adGroup2Ids,
      agencyIds,
      mediaSideCampaignIds: combineMediaSideCampaignIds,
      mediaSideGroupIds: combineMediaSideGroupIds,
      mediaAccountIds: combineMediaAccountIds,
      contentCategoryIds,
    } = yield select(filtersSelector.getIdsSettings);

    // get masterdata: Display/Agency/CV/Media/Adgroup/ContentCategory
    const hasContractLog = yield select(checkHasContractLog);
    const hasContractAgent = yield select(checkHasContractAgent);
    const hasContractMediaSync = yield select(
      DisplayItemsSelectors.hasContractMediaSync
    );
    const isAllowGetAgency = hasContractAgent && !ignoreAgency;

    const callApiActions = [
      call(MasterDataApi.fetchDisplay),
      isAllowGetAgency ? call(MasterDataApi.fetchAgency, '', agencyIds) : {},
    ];

    let display = {};
    let agency = {};
    let cv = {};
    let media = {};
    let adGroup1 = {};
    let adGroup2 = {};
    let mediaSideCampaign = {};
    let mediaSideGroup = {};
    let mediaAccount = {};
    let contentCategory = {};
    let subMedia = {};
    let subAdGroup1 = {};
    let subAdGroup2 = {};
    let conversions = [];

    const {
      ebisItemsIds: subMediaIds,
      mediaSideItemsIds: mediaAccountIds,
    } = FilterService.tryParsePairKeyIds(
      combineMediaAccountIds,
      FILTER_KEY_MEDIA_ACCOUNT
    );
    const {
      ebisItemsIds: subAdGroup1Ids,
      mediaSideItemsIds: mediaSideCampaignIds,
    } = FilterService.tryParsePairKeyIds(
      combineMediaSideCampaignIds,
      FILTER_KEY_MEDIA_SIDE_CAMPAIGN
    );
    const {
      ebisItemsIds: subAdGroup2Ids,
      mediaSideItemsIds: mediaSideGroupIds,
    } = FilterService.tryParsePairKeyIds(
      combineMediaSideGroupIds,
      FILTER_KEY_MEDIA_SIDE_GROUP
    );

    if (isTypeReport) {
      callApiActions.push(
        call(MasterDataApi.fetchCv, '', convIds),
        call(MasterDataApi.fetchMedia, '', mediaIds),
        call(MasterDataApi.fetchAdGroup1, '', adGroup1Ids),
        call(MasterDataApi.fetchAdGroup2, '', adGroup2Ids),
        hasContractMediaSync &&
          call(MasterDataApi.fetchMediaSideCampaign, '', mediaSideCampaignIds),
        hasContractMediaSync &&
          call(MasterDataApi.fetchMediaSideGroup, '', mediaSideGroupIds),
        hasContractMediaSync &&
          call(MasterDataApi.fetchMediaAccount, '', mediaAccountIds),
        hasContractMediaSync && call(MasterDataApi.fetchMedia, '', subMediaIds),
        hasContractMediaSync &&
          call(MasterDataApi.fetchAdGroup1, '', subAdGroup1Ids),
        hasContractMediaSync &&
          call(MasterDataApi.fetchAdGroup2, '', subAdGroup2Ids),
        hasContractLog &&
          call(MasterDataApi.fetchContentCategory, '', contentCategoryIds),
        call(metadataService.fetchConversions)
      );
      const [
        displayRes,
        agencyRes,
        convRes,
        mediaRes,
        adGroup1Res,
        adGroup2Res,
        mediaSideCampaignRes = {},
        mediaSideGroupRes = {},
        mediaAccountRes = {},
        subMediaRes = {},
        subAdGroup1Res = {},
        subAdGroup2Res = {},
        contentCategoryRes = {},
        conversionsRes,
      ] = yield all(callApiActions);

      display = displayRes?.data?.data || {};
      agency = agencyRes?.data || {};
      cv = convRes?.data || {};
      media = mediaRes?.data || {};
      adGroup1 = adGroup1Res?.data || {};
      adGroup2 = adGroup2Res?.data || {};
      mediaSideCampaign = mediaSideCampaignRes?.data || {};
      mediaSideGroup = mediaSideGroupRes?.data || {};
      mediaAccount = mediaAccountRes?.data || {};
      subMedia = subMediaRes?.data || {};
      subAdGroup1 = subAdGroup1Res?.data || {};
      subAdGroup2 = subAdGroup2Res?.data || {};
      contentCategory = contentCategoryRes?.data || {};
      conversions = conversionsRes;
    } else {
      callApiActions.push(
        call(mediaService.search, '', mediaIds),
        call(adGroup1Service.search, '', adGroup1Ids),
        call(adGroup2Service.search, '', adGroup2Ids),
        hasContractMediaSync &&
          call(mediaSideCampaignService.search, '', mediaSideCampaignIds),
        hasContractMediaSync &&
          call(mediaSideGroupService.search, '', mediaSideGroupIds),
        hasContractMediaSync &&
          call(mediaAccountService.search, '', mediaAccountIds)
      );
      const [
        displayRes,
        agencyRes,
        mediaRes,
        adGroup1Res,
        adGroup2Res,
        mediaSideCampaignRes = {},
        mediaSideGroupRes = {},
        mediaAccountRes = {},
      ] = yield all(callApiActions);

      display = displayRes?.data?.data || {};
      agency = agencyRes?.data || {};
      media = mediaRes;
      adGroup1 = adGroup1Res;
      adGroup2 = adGroup2Res;
      mediaSideCampaign = mediaSideCampaignRes;
      mediaSideGroup = mediaSideGroupRes;
      mediaAccount = mediaAccountRes;
    }

    const [
      { data: agencyData = [], selected: agencySelected = [] },
      { data: cvData = [], selected: cvSelected = [] },
      { data: mediaData, selected: mediaSelected },
      { data: adGroup1Data, selected: adGroup1Selected },
      { data: adGroup2Data, selected: adGroup2Selected },
      {
        data: mediaSideCampaignData = [],
        selected: mediaSideCampaignSelected = [],
      },
      { data: mediaSideGroupData = [], selected: mediaSideGroupSelected = [] },
      { data: mediaAccountData = [], selected: mediaAccountSelected = [] },
      { selected: subMediaSelected = [] },
      { selected: subAdGroup1Selected = [] },
      { selected: subAdGroup2Selected = [] },
      {
        data: contentCategoryData = [],
        selected: contentCategorySelected = [],
      },
    ] = [
      agency,
      cv,
      media,
      adGroup1,
      adGroup2,
      mediaSideCampaign,
      mediaSideGroup,
      mediaAccount,
      subMedia,
      subAdGroup1,
      subAdGroup2,
      contentCategory,
    ];
    // set data to store
    yield put(
      actions.fetchAllSucceeded({
        display,
        cv: convertArray2Object([...cvSelected, ...cvData]),
        conversions,
        media: convertArray2Object([...mediaSelected, ...mediaData]),
        adGroup1: convertArray2Object([...adGroup1Selected, ...adGroup1Data]),
        adGroup2: convertArray2Object([...adGroup2Selected, ...adGroup2Data]),
        agency: convertArray2Object([...agencySelected, ...agencyData]),
        mediaSideCampaign: convertArray2Object([
          ...mediaSideCampaignSelected,
          ...mediaSideCampaignData,
        ]),
        mediaSideGroup: convertArray2Object([
          ...mediaSideGroupSelected,
          ...mediaSideGroupData,
        ]),
        mediaAccount: convertArray2Object([
          ...mediaAccountSelected,
          ...mediaAccountData,
        ]),
        contentCategory: convertArray2Object([
          ...contentCategorySelected,
          ...contentCategoryData,
        ]),
        isFetchAgencyScreen: isAllowGetAgency,
      })
    );

    // Update data filters
    if (!isAgencyScreen) {
      yield put(
        filterActions.updateFilters(
          FilterService.combineFilters(filtersContainer, {
            media_id: mediaSelected,
            ad_group1_id: adGroup1Selected,
            ad_group2_id: adGroup2Selected,
            agency_id: agencySelected,
            media_side_campaign_id: isTypeReport
              ? FilterService.createPairKeyItems(
                  mediaSideCampaignSelected,
                  subAdGroup1Selected
                )
              : mediaSideCampaignSelected,
            media_side_group_id: isTypeReport
              ? FilterService.createPairKeyItems(
                  mediaSideGroupSelected,
                  subAdGroup2Selected
                )
              : mediaSideGroupSelected,
            media_account_id: isTypeReport
              ? FilterService.createPairKeyItems(
                  mediaAccountSelected,
                  subMediaSelected
                )
              : mediaAccountSelected,
            content_category_id: contentCategorySelected,
          })
        )
      );
    }

    yield put(
      actions.updateExpires(
        new Date().getTime() +
          parseInt(process.env.REACT_APP_MASTER_DATA_EXPIRES, 10)
      )
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchAllForAdManagement(action) {
  const { ignoreAgency = false } = action.payload || {};
  yield put(actions.fetchAll({ type: MASTER_DATA_TYPE.SETTING, ignoreAgency }));
}

function* fetchAllForAgencyManagement() {
  yield put(
    actions.fetchAll({
      type: MASTER_DATA_TYPE.SETTING,
      ignoreAgency: false,
      isAgencyScreen: true,
    })
  );
}

function* fetchAllForTagManagement() {
  try {
    const hasContractLog = yield select(checkHasContractLog);

    const currentTab = yield select(settingsSelectors.getMode);
    if (!hasContractLog || currentTab !== TAG_MANAGEMENT_TAB.PV) return;

    const { contentCategoryIds } = yield select(filtersSelector.getIdsSettings);
    // get data filters
    const filters = yield select(filtersSelector.getFilters);
    const selectedFilters = yield select(filtersSelector.getSettings);

    const filtersContainer = { ...filters, ...selectedFilters };
    const {
      data: { data, selected },
    } = yield call(TagManagementApi.getContentCategory, '', contentCategoryIds);

    // Save data to store
    yield put(
      actions.fetchContentCategorySucceeded(
        convertArray2Object([...data, ...selected])
      )
    );

    // Update data filters
    yield put(
      filterActions.updateFiltersTagManagement(
        FilterService.combineFilters(filtersContainer, {
          content_category_id: selected,
        })
      )
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchAllForPageManagement() {
  try {
    const hasContractLog = yield select(checkHasContractLogForPageManagement);
    if (hasContractLog) {
      const { contentCategoryIds } = yield select(
        filtersSelector.getIdsSettings
      );
      // get data filters
      const filters = yield select(filtersSelector.getFilters);
      const selectedFilters = yield select(filtersSelector.getSettings);

      const filtersContainer = { ...filters, ...selectedFilters };
      const {
        data: { data, selected },
      } = yield call(
        TagManagementApi.getContentCategory,
        '',
        contentCategoryIds
      );

      // Save data to store
      yield put(
        actions.fetchContentCategorySucceeded(
          convertArray2Object([...data, ...selected])
        )
      );

      // Update data filters
      yield put(
        filterActions.updateFiltersPageManagement(
          FilterService.combineFilters(filtersContainer, {
            content_category_id: selected,
          })
        )
      );
    }
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchAllForSettings(action) {
  try {
    const { pageId } = action.payload;
    yield put(actions.requestStart());

    switch (pageId) {
      case TAG_MANAGEMENT:
        yield fork(fetchAllForTagManagement);
        break;
      case AGENCY_MANAGEMENT:
        yield fork(fetchAllForAgencyManagement);
        break;
      case MEASUREMENT_SITE_PAGE_MANAGEMENT:
        yield fork(fetchAllForPageManagement);
        break;
      default:
        break;
    }
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
  yield put(actions.setType(SETTING));
}

function* fetchDisplay() {
  try {
    const [expires] = [
      yield select(selectors.getType),
      yield select(selectors.getExpires),
    ];

    // check expires time to skip fetch data
    if (!isExpired(expires)) return;

    yield put(actions.requestStart());

    const display = yield call(MasterDataApi.fetchDisplay);

    yield put(actions.fetchDisplaySucceeded(display.data.data));

    yield put(
      actions.updateExpires(
        new Date().getTime() +
          parseInt(process.env.REACT_APP_MASTER_DATA_EXPIRES, 10)
      )
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* searchCv(action) {
  yield delay(200);
  try {
    const { search, selected: searchSelected, limit, offset } = action.payload;
    yield put(actions.requestStart());
    const res = yield call(
      MasterDataApi.fetchCv,
      search,
      searchSelected,
      offset,
      limit
    );
    // Merge selected and data
    const { data, selected, metadata } = res.data;
    yield put(
      actions.searchSucceeded({
        items: convertArray2Object([...data, ...selected]),
        total: metadata.count,
        type: 'conv_id',
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* searchMedia(action) {
  try {
    const {
      search,
      selected: searchSelected,
      limit,
      offset,
      type = 'media_id',
    } = action.payload;
    yield put(actions.requestStart());
    const res = yield call(
      MasterDataApi.fetchMedia,
      search,
      searchSelected,
      offset,
      limit
    );
    // Merge selected and data
    const { data, selected, metadata } = res.data;
    yield put(
      actions.searchSucceeded({
        items: convertArray2Object([...data, ...selected]),
        total: metadata.count,
        type,
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* searchAdGroup1(action) {
  try {
    const {
      search,
      selected: searchSelected,
      limit,
      offset,
      type = 'ad_group1_id',
    } = action.payload;
    yield put(actions.requestStart());
    const res = yield call(
      MasterDataApi.fetchAdGroup1,
      search,
      searchSelected,
      offset,
      limit
    );
    // Merge selected and data
    const { data, selected, metadata } = res.data;
    yield put(
      actions.searchSucceeded({
        items: convertArray2Object([...data, ...selected]),
        total: metadata.count,
        type,
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* searchAdGroup2(action) {
  try {
    const {
      search,
      selected: searchSelected,
      limit,
      offset,
      type = 'ad_group2_id',
    } = action.payload;
    yield put(actions.requestStart());
    const res = yield call(
      MasterDataApi.fetchAdGroup2,
      search,
      searchSelected,
      offset,
      limit
    );
    // Merge selected and data
    const { data, selected, metadata } = res.data;
    yield put(
      actions.searchSucceeded({
        items: convertArray2Object([...data, ...selected]),
        total: metadata.count,
        type,
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* searchMediaSideCampaign(action) {
  try {
    const { search, selected: searchSelected, limit, offset } = action.payload;
    yield put(actions.requestStart());

    const { ebisItemsIds, mediaSideItemsIds } = FilterService.parsePairKeyIds(
      searchSelected
    );

    const mediaSideCampaignRes = yield call(
      MasterDataApi.fetchMediaSideCampaign,
      search,
      mediaSideItemsIds,
      offset,
      limit
    );
    const adGroup1Res = yield call(
      MasterDataApi.fetchAdGroup1,
      search,
      ebisItemsIds,
      offset,
      limit
    );
    // Merge selected and data
    const {
      data: mscData,
      selected: mscSelected,
      metadata: mscMetadata,
    } = mediaSideCampaignRes.data;
    const {
      data: ag1Data,
      selected: ag1Selected,
      metadata: ag1Metadata,
    } = adGroup1Res.data;
    const data = FilterService.createPairKeyItems(mscData, ag1Data);
    const selected = FilterService.createPairKeyItems(mscSelected, ag1Selected);
    const count = mscMetadata.count + ag1Metadata.count;
    yield put(
      actions.searchSucceeded({
        items: convertArray2Object([...data, ...selected]),
        total: count,
        type: 'media_side_campaign_id',
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* searchMediaSideGroup(action) {
  try {
    const { search, selected: searchSelected, limit, offset } = action.payload;
    yield put(actions.requestStart());

    const { ebisItemsIds, mediaSideItemsIds } = FilterService.parsePairKeyIds(
      searchSelected
    );

    const mediaSideGroupRes = yield call(
      MasterDataApi.fetchMediaSideGroup,
      search,
      mediaSideItemsIds,
      offset,
      limit
    );
    const adGroup2Res = yield call(
      MasterDataApi.fetchAdGroup2,
      search,
      ebisItemsIds,
      offset,
      limit
    );
    const {
      data: msgData,
      selected: msgSelected,
      metadata: msgMetadata,
    } = mediaSideGroupRes.data;
    const {
      data: ag2Data,
      selected: ag2Selected,
      metadata: ag2Metadata,
    } = adGroup2Res.data;

    // Merge selected and data
    const data = FilterService.createPairKeyItems(msgData, ag2Data);
    const selected = FilterService.createPairKeyItems(msgSelected, ag2Selected);
    const count = msgMetadata.count + ag2Metadata.count;
    yield put(
      actions.searchSucceeded({
        items: convertArray2Object([...data, ...selected]),
        total: count,
        type: 'media_side_group_id',
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* searchMediaAccount(action) {
  try {
    const { search, selected: searchSelected, limit, offset } = action.payload;
    yield put(actions.requestStart());

    const { ebisItemsIds, mediaSideItemsIds } = FilterService.parsePairKeyIds(
      searchSelected
    );

    const mediaAccountRes = yield call(
      MasterDataApi.fetchMediaAccount,
      search,
      mediaSideItemsIds,
      offset,
      limit
    );
    const mediaRes = yield call(
      MasterDataApi.fetchMedia,
      search,
      ebisItemsIds,
      offset,
      limit
    );
    // Merge selected and data
    const {
      data: mediaAccountData,
      selected: mediaAccountSelected,
      metadata: mediaAccountMetadata,
    } = mediaAccountRes.data;
    const {
      data: mediaData,
      selected: mediaSelected,
      metadata: mediaMetadata,
    } = mediaRes.data;

    const data = FilterService.createPairKeyItems(mediaAccountData, mediaData);
    const selected = FilterService.createPairKeyItems(
      mediaAccountSelected,
      mediaSelected
    );
    const count = mediaMetadata.count + mediaAccountMetadata.count;
    yield put(
      actions.searchSucceeded({
        items: convertArray2Object([...data, ...selected]),
        total: count,
        type: 'media_account_id',
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* searchAgency(action) {
  try {
    const { search, selected: selectedItems } = action.payload;
    yield put(actions.requestStart());
    const res = yield call(MasterDataApi.fetchAgency, search, selectedItems);

    // Merge selected and data
    const { data, selected, metadata } = res.data;
    yield put(
      actions.searchSucceeded({
        items: convertArray2Object([...data, ...selected]),
        total: metadata.count,
        type: 'agency_id',
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* searchContentCategory(action) {
  try {
    yield put(actions.requestStart());

    const { search, selected: selectedItems } = action.payload;
    const type = yield select(selectors.getType);

    let response = {};

    if (type === MASTER_DATA_TYPE.REPORT) {
      response = yield call(
        MasterDataApi.fetchContentCategory,
        search,
        selectedItems
      );
    } else if (type === MASTER_DATA_TYPE.SETTING) {
      response = yield call(
        TagManagementApi.getContentCategory,
        search,
        selectedItems
      );
    }

    // Merge selected and data
    const {
      data: { data, selected, metadata },
    } = response;
    yield put(
      actions.searchSucceeded({
        items: convertArray2Object([...selected, ...data]),
        total: metadata.count,
        type: CONTENT_CATEGORY_ID,
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* handleSearchMedia(action) {
  const type = action?.payload?.isSettingMail
    ? MASTER_DATA_TYPE.REPORT
    : yield select(selectors.getType);
  if (type === MASTER_DATA_TYPE.REPORT) {
    yield searchMedia(action);
  } else if (type === MASTER_DATA_TYPE.SETTING) {
    const { search: searchTerm, selected, agencyId } = action.payload;
    yield put(adManagementActions.searchMedia(searchTerm, selected, agencyId));
  }
}

function* handleSearchAdGroup1(action) {
  const type = yield select(selectors.getType);
  if (type === MASTER_DATA_TYPE.REPORT) {
    yield searchAdGroup1(action);
  } else if (type === MASTER_DATA_TYPE.SETTING) {
    const { search: searchTerm, selected, agencyId } = action.payload;
    yield put(
      adManagementActions.searchAdGroup1(searchTerm, selected, agencyId)
    );
  }
}

function* handleSearchAdGroup2(action) {
  const type = yield select(selectors.getType);
  if (type === MASTER_DATA_TYPE.REPORT) {
    yield searchAdGroup2(action);
  } else if (type === MASTER_DATA_TYPE.SETTING) {
    const { search: searchTerm, selected, agencyId } = action.payload;
    yield put(
      adManagementActions.searchAdGroup2(searchTerm, selected, agencyId)
    );
  }
}

function* handleSearchMediaAccount(action) {
  const type = yield select(selectors.getType);
  if (type === MASTER_DATA_TYPE.REPORT) {
    yield searchMediaAccount(action);
  } else if (type === MASTER_DATA_TYPE.SETTING) {
    const { search: searchTerm, selected, agencyId } = action.payload;
    yield put(
      adManagementActions.searchMediaAccount(searchTerm, selected, agencyId)
    );
  }
}

function* handleSearchMediaSideCampaign(action) {
  const type = yield select(selectors.getType);
  if (type === MASTER_DATA_TYPE.REPORT) {
    yield searchMediaSideCampaign(action);
  } else if (type === MASTER_DATA_TYPE.SETTING) {
    const { search: searchTerm, selected, agencyId } = action.payload;
    yield put(
      adManagementActions.searchMediaSideCampaign(
        searchTerm,
        selected,
        agencyId
      )
    );
  }
}

function* handleSearchMediaSideGroup(action) {
  const type = yield select(selectors.getType);
  if (type === MASTER_DATA_TYPE.REPORT) {
    yield searchMediaSideGroup(action);
  } else if (type === MASTER_DATA_TYPE.SETTING) {
    const { search: searchTerm, selected, agencyId } = action.payload;
    yield put(
      adManagementActions.searchMediaSideGroup(searchTerm, selected, agencyId)
    );
  }
}

function* updateMasterData(action) {
  try {
    yield put(actions.requestStart());
    const masterData = yield select(selectors.getAll);
    const hasContractAgent = yield select(checkHasContractAgent);
    const hasContractLog = yield select(checkHasContractLog);
    const hasContractMediaSync = yield select(
      DisplayItemsSelectors.hasContractMediaSync
    );

    const {
      callback = () => {},
      masterDataIds: {
        conv_id: convIds = [],
        media_id: mediaIds = [],
        ad_group1_id: group1Ids = [],
        ad_group2_id: group2Ids = [],
        agency_id: agencyIds = [],
        media_side_campaign_id: mediaSideCampaignIds = [],
        media_side_group_id: mediaSideGroupIds = [],
        media_account_id: mediaAccountIds = [],
        content_category_id: contentCategoryids = [],
      },
    } = action.payload;

    const [
      cvRes,
      mediaRes,
      group1Res,
      group2Res,
      agencyRes,
      mediaSideCampaignRes,
      mediaSideGroupRes,
      mediaAccountRes,
      contentCategoryRes,
    ] = yield all([
      !isEmpty(convIds) && call(MasterDataApi.fetchCv, '', convIds),
      !isEmpty(mediaIds) && call(MasterDataApi.fetchMedia, '', mediaIds),
      !isEmpty(group1Ids) && call(MasterDataApi.fetchAdGroup1, '', group1Ids),
      !isEmpty(group2Ids) && call(MasterDataApi.fetchAdGroup2, '', group2Ids),
      !isEmpty(agencyIds) &&
        hasContractAgent &&
        call(MasterDataApi.fetchAgency, '', agencyIds),
      !isEmpty(mediaSideCampaignIds) &&
        hasContractMediaSync &&
        call(MasterDataApi.fetchMediaSideCampaign, '', mediaSideCampaignIds),
      !isEmpty(mediaSideGroupIds) &&
        hasContractMediaSync &&
        call(MasterDataApi.fetchMediaSideGroup, '', mediaSideGroupIds),
      !isEmpty(mediaAccountIds) &&
        hasContractMediaSync &&
        call(MasterDataApi.fetchMediaAccount, '', mediaAccountIds),
      !isEmpty(contentCategoryids) &&
        hasContractLog &&
        call(MasterDataApi.fetchContentCategory, '', contentCategoryids),
    ]);

    const master = {
      cv: cvRes
        ? convertArray2Object([...cvRes.data.data, ...cvRes.data.selected])
        : masterData.cv,
      media: mediaRes
        ? convertArray2Object([
            ...mediaRes.data.data,
            ...mediaRes.data.selected,
          ])
        : masterData.media,
      adGroup1: group1Res
        ? convertArray2Object([
            ...group1Res.data.data,
            ...group1Res.data.selected,
          ])
        : masterData.adGroup1,
      adGroup2: group2Res
        ? convertArray2Object([
            ...group2Res.data.data,
            ...group2Res.data.selected,
          ])
        : masterData.adGroup2,
      mediaSideCampaign: mediaSideCampaignRes
        ? convertArray2Object([
            ...mediaSideCampaignRes.data.data,
            ...mediaSideCampaignRes.data.selected,
          ])
        : masterData.mediaSideCampaign,
      mediaSideGroup: mediaSideGroupRes
        ? convertArray2Object([
            ...mediaSideGroupRes.data.data,
            ...mediaSideGroupRes.data.selected,
          ])
        : masterData.mediaSideGroup,
      mediaAccount: mediaAccountRes
        ? convertArray2Object([
            ...mediaAccountRes.data.data,
            ...mediaAccountRes.data.selected,
          ])
        : masterData.mediaAccount,
      agency: agencyRes
        ? convertArray2Object([
            ...agencyRes.data.data,
            ...agencyRes.data.selected,
          ])
        : masterData.agency,
      contentCategory: contentCategoryRes
        ? convertArray2Object([
            ...contentCategoryRes.data.data,
            ...contentCategoryRes.data.selected,
          ])
        : masterData.contentCategory,
    };

    yield put(actions.updateMasterDataSucceeded(master));
    callback(master);
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchMediaBySettingMail(action) {
  try {
    yield put(actions.requestStart());
    const { selected } = action.payload;
    let media = {};
    const mediaRes = yield call(MasterDataApi.fetchMedia, '', selected);
    media = mediaRes?.data || {};
    const { data: mediaData, selected: mediaSelected } = media;

    yield put(
      actions.fetchMediaSucceeded(
        convertArray2Object([...mediaSelected, ...mediaData])
      )
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchUser() {
  try {
    yield put(actions.requestStart());
    const userRes = yield call(MasterDataApi.fetchUsers);
    const response = userRes.data.data.detail;
    yield put(actions.fetchUserSucceeded(response));
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

export default function* masterDataOperations() {
  const { FETCH_CONVERSIONS } = types;
  yield takeLatest(FETCH_CONVERSIONS, fetchConversions);
  yield takeLatest(types.FETCH_CV, fetchCv);
  yield takeLatest(types.FETCH_CONTENT_CATEGORY, fetchContentCategory);
  yield takeLatest(types.FETCH_ALL, fetchAll);
  yield takeLatest(types.FETCH_DISPLAY, fetchDisplay);
  yield takeLatest(types.FETCH_AGENCY, fetchAgency);
  yield takeLatest(types.FETCH_MEDIA, fetchMedia);
  yield takeLatest(types.FETCH_USER, fetchUser);
  yield takeLatest(types.SEARCH_CV, searchCv);
  yield takeLatest(types.SEARCH_MEDIA, handleSearchMedia);
  yield takeLatest(types.SEARCH_AD_GROUP1, handleSearchAdGroup1);
  yield takeLatest(types.SEARCH_AD_GROUP2, handleSearchAdGroup2);
  yield takeLatest(
    types.SEARCH_MEDIA_SIDE_CAMPAIGN,
    handleSearchMediaSideCampaign
  );
  yield takeLatest(types.SEARCH_MEDIA_SIDE_GROUP, handleSearchMediaSideGroup);
  yield takeLatest(types.SEARCH_MEDIA_ACCOUNT, handleSearchMediaAccount);
  yield takeLatest(types.SEARCH_AGENCY, searchAgency);
  yield takeLatest(types.SEARCH_CONTENT_CATEGORY, searchContentCategory);
  yield takeLatest(types.UPDATE_MASTERDATA, updateMasterData);
  yield takeLatest(types.FETCH_ALL_FOR_AD_MANAGEMENT, fetchAllForAdManagement);
  yield takeLatest(types.FETCH_ALL_FOR_SETTINGS, fetchAllForSettings);
  yield takeLatest(types.FETCH_MEDIA_BY_SETTING_MAIL, fetchMediaBySettingMail);
}
