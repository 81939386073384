import { useSelector, shallowEqual } from 'react-redux';
import {
  DATA_EXPORT_VIEW,
  DATA_EXPORT_AD_REPO_VIEW,
  ALL_LOG_VIEW,
  AD_LISTING_GA_VIEW,
  AD_LISTING_YD_VIEW,
  AD_LISTING_YH_VIEW,
  COST_VIEW_MEDIA_LINK_VIEW,
  MEDIA_SYNC_VIEW,
  ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
} from 'domain/permissions/contractPermissions';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import { checkAllowedSettingReport } from 'store/auth/selectors';
import useCheckPermissionAgency from './useCheckPermissionAgency';

const useCheckPermissionSettingReport = () => {
  const { checkPermissions } = useCheckPermissions();
  const { isAgentUser } = useCheckPermissionAgency();

  const {
    isAllowedSettingRegularReport,
    isAllowedSettingDataExport,
    isAllowedSettingAdrepoReport,
  } = useSelector(checkAllowedSettingReport, shallowEqual);

  const isAllowedSettingReport =
    !isAgentUser &&
    (isAllowedSettingRegularReport ||
      isAllowedSettingDataExport ||
      isAllowedSettingAdrepoReport);

  const isAllowedSettingNormalReport =
    isAllowedSettingDataExport || isAllowedSettingRegularReport;

  const isAllowedSettingWaitCost = checkPermissions('anyOf', [
    AD_LISTING_GA_VIEW,
    AD_LISTING_YD_VIEW,
    AD_LISTING_YH_VIEW,
    COST_VIEW_MEDIA_LINK_VIEW,
    MEDIA_SYNC_VIEW,
  ]);

  const isAllowedSettingChannelAll = checkPermissions('anyOf', [
    ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
  ]);

  const isHavePermissionChannelAccess = checkPermissions('anyOf', [
    ALL_LOG_VIEW,
  ]);

  const isHavePermissionDataExport = checkPermissions('anyOf', [
    DATA_EXPORT_VIEW,
  ]);
  const isHavePermissionAdrepo = checkPermissions('anyOf', [
    DATA_EXPORT_AD_REPO_VIEW,
  ]);

  return {
    isAllowedSettingReport,
    isHavePermissionChannelAccess,
    isAllowedSettingAdrepoReport,
    isAllowedSettingNormalReport,
    isAllowedSettingWaitCost,
    isHavePermissionAdrepo,
    isHavePermissionDataExport,
    isAllowedSettingDataExport,
    isAllowedSettingChannelAll,
  };
};

export default useCheckPermissionSettingReport;
