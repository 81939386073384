import * as ErrorMessages from 'services/validations/messageErrorByCode';
import { REPORT_FIELDS } from 'domain/data-export/consts';

const ACCEPT_VALUE_CHECK = '{label}を正しく選択してください。';

export const ERROR_MESSAGE_COMMON = {
  ALLOWED_REPORT_CHECK: 'データエクスポートを操作する契約がありまあせん。',
  LIMIT_REGISTER_CHECK:
    'データエクスポートの登録上限数({index.0}件)を超えてしまうため、登録することができません。',
  REPORT_ID_EXISTS_CHECK:
    '選択されたレポートはすでに削除されています。<br />[OK]をクリックすることで、最新の状態を読み込みます。',
  ALLOW_USER_MODIFY_REPORT_CHECK:
    '選択されたレポートを編集する権限がありません。<br />[OK]をクリックすることで、最新の状態を読み込みます。',
  VALID_SHARED_USERS_CHECK:
    'このレポート設定は期限切れまたは他のユーザーが削除したため存在せず、編集ができません。',
  MAX_REPORT_ALLOW_REGIST_CHECK: '最大{index.0}件登録することができます。',
};

export const ERROR_MESSAGE_BY_FIELD = {
  [REPORT_FIELDS.SERVICE_STORE]: {
    param: { label: '出力タイプ' },
    message: { ACCEPT_VALUE_CHECK },
  },
  [REPORT_FIELDS.REPORT_CHANNEL]: {
    param: { label: 'レポートタイプ' },
    message: {
      ALLOWED_CHANNEL_REPORT_CHECK: 'この対象チャネルを閲覧する契約または機能権限がありません。'
    },
  },
  [REPORT_FIELDS.REPORT_TYPE]: {
    param: { label: 'レポートタイプ' },
    message: {
      ACCEPT_VALUE_CHECK,
      ALLOWED_ACCESS_REPORT_CHECK:
        'ご指定のレポートを作成する契約または機能権限がありません。',
    },
  },
  [REPORT_FIELDS.REPORT_NAME]: {
    param: { label: 'レポート名' },
    message: {
      EMPTY_CHECK: ErrorMessages.EMPTY_CHECK,
      SPTAB_CHECK: ErrorMessages.SPTAB_CHECK,
      MAX_LENGTH_CHECK: ErrorMessages.MAX_LENGTH_CHECK,
      DUPLICATE_REPORT_NAME_CHECK:
        '{label}が重複しています。別名で保存してください。',
    },
  },
  [REPORT_FIELDS.DISPLAY_ITEMS]: {
    param: { label: '集計軸・表示項目' },
    message: {
      ACCEPT_VALUE_CHECK,
      DIMENSION_PATTERN_CHECK: '集計軸を正しく設定してください。',
      ACCEPTED_REQUIRED: '集計軸・表示項目を正しく設定してください。'
    },
  },
  [REPORT_FIELDS.DIMENSIONS]: {
    param: { label: '集計軸' },
    message: {
      EMPTY_CHECK: '{label}と指標をそれぞれ1件以上選択してください。',
      EMPTY_CHECK_CV_ATTR_CHECK: '表示項目を1件以上選択してください。',
      DIMENSION_ID_EXISTS_CHECK: '{label}を正しく選択してください。',
      DUPLICATED_VALUE_CHECK: '{label}を正しく選択してください。',
      MAX_SELECTED_CHECK: '選択できる{label}は最大{index.0}件です。',
    },
  },
  [REPORT_FIELDS.METRICS]: {
    param: { label: '指標を' },
    message: {
      EMPTY_CHECK: '集計軸と{label}それぞれ1件以上選択してください。',
      METRIC_ID_EXISTS_CHECK: '{label}正しく選択してください。',
      DUPLICATED_VALUE_CHECK: '{label}正しく選択してください。',
      MAX_SELECTED_CHECK: '選択できる指標は最大{index.0}件です。',
    },
  },
  [REPORT_FIELDS.PERIOD_MODE]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK: '集計期間を正しく設定してください。',
    },
  },
  [REPORT_FIELDS.PERIOD_START]: {
    param: { label: '' },
    message: {
      EMPTY_WHEN_CHECK: '集計期間(開始日)を設定してください。',
      FORMAT_DATE_ACCEPTED_CHECK: '集計期間(開始日)を正しく設定してください。',
      WRONG_START_DATE_CHECK_1:
        '集計期間の開始日は、終了日より過去となるよう日付を設定してください。',
      WRONG_START_DATE_CHECK_2:
        '集計期間の開集計期間は、3か月以内で設定してください。',
      WRONG_START_DATE_CHECK_3:
        '集計期間(開始日)は、本日より過去2年間で設定してください。',
      WRONG_START_DATE_CHECK_4:
        '集計期間の開始日に明日以降の日付を設定することはできません。',
    },
  },
  [REPORT_FIELDS.PERIOD_END]: {
    param: { label: '' },
    message: {
      EMPTY_WHEN_CHECK: '集計期間(終了日)を設定してください。',
      FORMAT_DATE_ACCEPTED_CHECK: '集計期間(終了日)を正しく設定してください。',
      WRONG_START_DATE_CHECK_4:
        '集計期間の開始日に明日以降の日付を設定することはできません。',
    },
  },
  [REPORT_FIELDS.PERIOD_INTERVAL]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK: '集計期間を正しく設定してください。',
    },
  },
  [REPORT_FIELDS.PERIOD_RECEIVE_REPORT_DAY]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK: '集計期間を正しく設定してください。',
    },
  },
  [REPORT_FIELDS.WAIT_COST]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK:
        'レポートフォーマットに誤りがあります。項目を正しく設定してください。',
      ALLOWED_WAIT_COST_REPORT_CHECK:
        'レポートフォーマットに誤りがあります。項目を正しく設定してください。',
    },
  },
  [REPORT_FIELDS.DISPLAY_TOTAL]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK:
        'レポートフォーマットに誤りがあります。項目を正しく設定してください。',
    },
  },
  [REPORT_FIELDS.DISPLAY_ROW_ZERO]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK:
        'レポートフォーマットに誤りがあります。項目を正しく設定してください。',
    },
  },
  [REPORT_FIELDS.DISPLAY_HEADER]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK:
        'レポートフォーマットに誤りがあります。項目を正しく設定してください。',
    },
  },
  [REPORT_FIELDS.DISPLAY_CLICK_LP_URL]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK:
        'レポートフォーマットに誤りがあります。項目を正しく設定してください。',
    },
  },
  [REPORT_FIELDS.SEND_EMAIL_MODE]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK:
        'メール配信設定に誤りがあります。正しく設定してください。',
    },
  },
  [REPORT_FIELDS.EMAIL_TITLE_MODE]: {
    param: { label: '' },
    message: {
      ACCEPT_VALUE_CHECK:
        'メールタイトルに誤りがあります。正しく設定してください。',
    },
  },
  [REPORT_FIELDS.EMAIL_TITLE]: {
    param: { label: 'メールタイトル' },
    message: {
      EMPTY_WHEN_CHECK:
        '{label}が入力されていません。{label}を入力してください。',
      SPTAB_CHECK:
        '{label}をスペース、タブ、改行のみで登録することはできません。',
      MAX_LENGTH_CHECK: '{label}は{index.0}字以下で入力してください。',
    },
  },
  [REPORT_FIELDS.CUSTOM_FILENAME]: {
    param: { label: 'ファイル名' },
    message: {
      EMPTY_CHECK: ErrorMessages.EMPTY_CHECK,
      FILE_NAME_CHECK: ErrorMessages.FILE_NAME_CHECK,
      MAX_LENGTH_CHECK: ErrorMessages.MAX_LENGTH_CHECK,
    },
  },
  [REPORT_FIELDS.SORT]: {
    param: { label: 'ソート項目' },
    message: {
      ACCEPTED_VALUE: '表示項目を設定してください',
    },
  },
};
