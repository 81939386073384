import { call, put, takeEvery, select } from 'redux-saga/effects';
import { checkHasContractLog } from 'store/auth/selectors';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import types from './types';
import actions from './actions';
import selectors from './selectors';

function* fetch(action) {
  try {
    const { funcId, displayitemsApi } = action.payload;
    const hasContractLog = yield select(checkHasContractLog);
    yield put(actions.requestStart());
    const {
      data: { data },
    } = yield call(displayitemsApi.fetch, funcId);
    const { items, sorting } = DisplayItemsService.getItemsByContractLog(
      funcId,
      data,
      hasContractLog
    );

    yield put(
      actions.fetchSucceeded({
        override: true,
        funcId,
        items,
        sorting,
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchDefault(action) {
  try {
    const { funcId } = action.payload;
    const hasContractLog = yield select(checkHasContractLog);
    yield put(actions.requestStart());
    const data = yield select(selectors.getSettingsDefault);
    const { items, sorting } = DisplayItemsService.getItemsByContractLog(
      funcId,
      data,
      hasContractLog
    );

    yield put(
      actions.fetchSucceeded({
        override: true,
        funcId,
        items,
        sorting,
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

export default function* watchView() {
  yield takeEvery(types.FETCH, fetch);
  yield takeEvery(types.FETCH_DEFAULT, fetchDefault);
}
