import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withAuthHoC } from 'services/auth/withAuth';
import {
  AD_MANAGEMENT,
  TAG_MANAGEMENT,
  DATA_EXPORT,
  SYSTEM_SETTING,
} from 'services/routes/constants';
import pages from 'services/routes/pages';

import routes from 'services/routes/routes';

import {
  getPageNotAuthorizedPath,
  getPageNotFoundPath,
} from 'services/routes/page-helpers';

import PageLoader from 'views/atoms/loader/PageLoader';
import ProtectedRoute from 'services/routes/ProtectedRoute';
import PageNotAuthorized from 'views/pages/403';
import AdManagementLoader from 'views/pages/ad-management/AdManagementLoader';
import TagManagementLoader from 'views/pages/tag-management/TagManagementLoader';
import DataExportLoader from 'views/pages/data-export/DataExportLoader';
import SystemSettingContainer from 'views/pages/system-setting';

const MainRoutes = ({ currentTab }) => (
  <React.Suspense fallback={<PageLoader />}>
    <Switch>
      {routes.map((route) => (
        <ProtectedRoute
          key={route.screenId}
          path={route.path}
          exact={route.exact}
          component={route.component}
          screenId={route.screenId}
          currentTab={currentTab}
        />
      ))}
      <Route exact path="/ad-management/:mode">
        {withAuthHoC({ currentTab, screenId: pages[AD_MANAGEMENT].screenId })(
          <AdManagementLoader />
        )}
      </Route>
      <Route exact path="/tag-management/:mode">
        {withAuthHoC({
          currentTab,
          screenId: pages[TAG_MANAGEMENT].screenId,
        })(<TagManagementLoader />)}
      </Route>
      <Route exact path="/system-setting/:mode">
        {withAuthHoC({
          currentTab,
          screenId: pages[SYSTEM_SETTING].screenId,
        })(<SystemSettingContainer />)}
      </Route>
      <Route exact path="/data-export/:mode?/:reportId?">
        {withAuthHoC({ currentTab, screenId: pages[DATA_EXPORT].screenId })(
          <DataExportLoader />
        )}
      </Route>
      <Route exact path={getPageNotAuthorizedPath()}>
        <PageNotAuthorized />
      </Route>
      <Route exact path={getPageNotFoundPath()}>
        <Redirect to="/" />
      </Route>
    </Switch>
  </React.Suspense>
);

MainRoutes.propTypes = {
  currentTab: PropTypes.string.isRequired,
};

export default React.memo(MainRoutes);
