import React from 'react';
import { func, bool, arrayOf } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { HEADER_VIEW_HISTORY, EMAIL_INFO } from 'domain/data-export/consts';
import { SETTING_USER_EDIT } from 'domain/permissions/contractPermissions';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import TableModal from 'views/organism/TableModal';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';

const Header = ({ headers }) => {
  return (
    <tr>
      {headers.map((column) => (
        <th key={column.field} style={{ width: column.width }}>
          {column.name}
        </th>
      ))}
    </tr>
  );
};

Header.propTypes = {
  headers: arrayOf({}).isRequired,
};

const Body = ({ data, headers }) => {
  return data.map((row) => (
    <tr>
      {headers.map((column) => (
        <td style={{ width: column.width }}>{row[column.field]}</td>
      ))}
    </tr>
  ));
};

function ViewHistory({ handleCloseModal, show, isLoading, data = [] }) {
  const { checkPermissions } = useCheckPermissions();

  const isHavePermissionUserEdit = checkPermissions('anyOf', [
    SETTING_USER_EDIT,
  ]);
  const headers = isHavePermissionUserEdit
    ? HEADER_VIEW_HISTORY
    : HEADER_VIEW_HISTORY.filter((item) => item.field !== EMAIL_INFO);

  const modalClass = classNames({
    'modal--view-history': true,
    'modal--loading': isLoading,
  });

  return (
    <Modal show={show} centered size="w800" className={modalClass}>
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center">
          レポート作成履歴
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={isLoading}>
          <TableModal
            isEmpty={data?.length < 1}
            emptyMessage={data?.length < 1 && '履歴はありません。'}
            header={<Header headers={headers} />}
            body={<Body data={data} headers={headers} />}
            maxContent={518}
          />
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="link" onClick={handleCloseModal}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ViewHistory.propTypes = {
  handleCloseModal: func.isRequired,
  show: bool.isRequired,
  isLoading: bool.isRequired,
  data: arrayOf({}).isRequired,
};

export default ViewHistory;
