const MAX_ACTIONS = 50;
const SCOPE_DONT_SHOW = 'DONT_SHOW_ERROR_UI';
const SKIP_WRITE_LOG = 'SKIP_WRITE_LOG';

const LOG_LEVEL_INFO = 'LOG_LEVEL:INFO';
const LOG_LEVEL_DEBUG = 'LOG_LEVEL:DEBUG';
const LOG_LEVEL_TRACE = 'LOG_LEVEL:TRACE';
const LOG_LEVEL_ERROR = 'LOG_LEVEL:ERROR';
const LOG_LEVEL_FATAL = 'LOG_LEVEL:FATAL';
const LOG_LEVEL_WARN = 'LOG_LEVEL:WARN';

export default {
  MAX_ACTIONS,
  SCOPE_DONT_SHOW,
  SKIP_WRITE_LOG,
  LOG_LEVEL_INFO,
  LOG_LEVEL_DEBUG,
  LOG_LEVEL_TRACE,
  LOG_LEVEL_ERROR,
  LOG_LEVEL_FATAL,
  LOG_LEVEL_WARN,
};
