import React, { useContext, useCallback } from 'react';
import { bool, func, string, number, oneOfType } from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import EbisCustomCheckbox from 'views/atoms/checkbox/EbisCustomCheckbox';
import ActionIcon from 'views/atoms/icon/ActionIcon';
import { GridTableContext } from 'views/organism/GridTable/GridTableContext';

function GirdTableCellCheckbox(props) {
  const {
    id,
    variant,
    checked,
    disabledCheckbox,
    tooltipCheckbox,
    onChange,
    isEdit,
    disabledEdit,
    tooltipEdit,
    onEdit,
  } = props;

  const {
    dispatch,
    settings: { selectedRows, hasSelectRowFunc },
  } = useContext(GridTableContext);

  const handleChange = useCallback(() => {
    onChange();

    if (!hasSelectRowFunc) return;

    const newSelectedRows = !checked
      ? [...selectedRows, id]
      : selectedRows.filter((item) => item !== id);

    dispatch({ type: 'setSelectedRow', payload: newSelectedRows });
  }, [checked, dispatch, hasSelectRowFunc, id, onChange, selectedRows]);

  return (
    <>
      <OverlayTrigger
        trigger={disabledCheckbox && tooltipCheckbox ? ['hover', 'focus'] : ''}
        placement="bottom"
        overlay={
          <Tooltip variant="disabled" placement="bottom">
            {tooltipCheckbox}
          </Tooltip>
        }
      >
        <span>
          <EbisCustomCheckbox
            id={id}
            checked={checked}
            disabled={disabledCheckbox}
            variant={variant}
            onChange={handleChange}
            onClick={(e) => e.stopPropagation()}
          />
        </span>
      </OverlayTrigger>
      {isEdit && (
        <ActionIcon
          onClick={(e) => {
            // Prevent underlying components being clicked
            e.stopPropagation();
            onEdit(id);
          }}
          tooltip={tooltipEdit}
          disabled={disabledEdit}
        />
      )}
    </>
  );
}

GirdTableCellCheckbox.propTypes = {
  id: oneOfType([string, number]).isRequired,
  variant: string,
  tooltipCheckbox: string,
  tooltipEdit: string,
  checked: bool,
  disabledCheckbox: bool,
  isEdit: bool,
  onChange: func,
  onEdit: func,
  disabledEdit: bool,
};

GirdTableCellCheckbox.defaultProps = {
  variant: '',
  checked: false,
  disabledCheckbox: false,
  tooltipCheckbox: '',
  isEdit: false,
  disabledEdit: false,
  tooltipEdit: '',
  onChange: () => {},
  onEdit: () => {},
};

export default React.memo(GirdTableCellCheckbox);
