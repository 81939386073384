import React, { useState } from 'react';
import { func, shape, bool } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames';
import SettingModal from 'views/pages/system-setting/components/Modal';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import EbisDropdown from 'views/atoms/dropdown/EbisDropdown';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import {
  RCV_PATTERN,
  RCV_PATTERN_CUSTOM,
  RCV_TOTAL,
  MAX_LENGTH_RCV_VALUE,
} from 'domain/system-setting/consts-display-setting';
import { RCV_DATA, RCV_MODEL_OPTION } from 'domain/fields';
import {
  prepareDataRcvForRequest,
  validateDataRcv,
  convertErrorsRcv,
  isCorrectRcvTotal,
} from 'services/system-setting/systemSettingDisplayServices';

function RcvSettingModal(props) {
  const { loading, data, onSubmit, onHide } = props;

  const [dataSetting, setDataSetting] = useState(data);
  const [error, setError] = useState({});

  const pattern = dataSetting[RCV_MODEL_OPTION];
  const patterns = dataSetting[RCV_DATA][pattern] ?? [];
  const rcvPatterns = Object.values(RCV_PATTERN);
  const isPatternCustom = pattern === RCV_PATTERN_CUSTOM;

  const classTable = classNames({
    'rcv-table': true,
    'rcv-table--custom': isPatternCustom,
  });

  const handleChangePattern = (value) => {
    setDataSetting((prev) => ({ ...prev, [RCV_MODEL_OPTION]: +value }));
    setError({});
  };

  const handleChangeValue = (rowIndex, cellIndex) => (_, value) => {
    const clonePatterns = cloneDeep(patterns);
    clonePatterns[rowIndex - 1][cellIndex - 1] = value;

    setDataSetting((prev) => ({
      ...prev,
      [RCV_DATA]: { ...prev[RCV_DATA], [pattern]: clonePatterns },
    }));

    setError((prev) => {
      const newError = Object.keys(prev).reduce((acc, key) => {
        if ([`${cellIndex}`, `${rowIndex}-total`].includes(key)) return acc;
        return { ...acc, [key]: prev[key] };
      }, {});

      return newError;
    });
  };

  const handleError = (errors) => {
    const errorResponse = convertErrorsRcv(errors);
    setError(errorResponse);
  };

  const handleSubmit = () => {
    const dataRequest = prepareDataRcvForRequest(dataSetting);
    const errorValidate = validateDataRcv(dataRequest);
    setError(errorValidate);

    if (isEmpty(errorValidate)) {
      onSubmit(dataRequest, handleError);
    }
  };

  return (
    <SettingModal
      isShow
      loading={loading}
      onSubmit={handleSubmit}
      onHide={onHide}
      title="再配分コンバージョンモデル"
      dialogClassName="display-setting__modal-rcv"
    >
      <div className="mb-10">
        使用するモデルを変更してください。モデルを変更すると再配分CV、TCPAが変動します。
      </div>
      {!isEmpty(error) && (
        <div className=" color-bad mb-10">
          {Object.values(error).map((message) => (
            <div>{message}</div>
          ))}
        </div>
      )}
      <div className="rcv-pattern">
        <EbisDropdown
          optionObject={rcvPatterns}
          defaultVal={pattern}
          selectCallback={handleChangePattern}
        />
        <span>{RCV_PATTERN[pattern].description}</span>
      </div>
      <div className={classTable}>
        <div className="rcv-row">
          <div className="rcv-cell" />
          <div className="rcv-cell">直接</div>
          <div className="rcv-cell">間接2</div>
          <div className="rcv-cell">間接3</div>
          <div className="rcv-cell">間接4</div>
          <div className="rcv-cell">間接5</div>
          <div className="rcv-cell">間接6</div>
          <div className="rcv-cell">間接7</div>
          <div className="rcv-cell">間接8</div>
          <div className="rcv-cell">間接9</div>
          <div className="rcv-cell">間接10</div>
          <div className="rcv-cell">
            初回
            <DragonBall>
              広告接触回数が11回以上ある場合の
              <br />
              「初回接触広告」に対する重みづけを行います。
            </DragonBall>
          </div>
          <div className="rcv-cell">合計</div>
        </div>
        {patterns.map((cells, rIndex) => {
          const rowIndex = rIndex + 1;
          let total = 0;
          return (
            <div key={rowIndex} className="rcv-row">
              <div className="rcv-cell">{rowIndex}</div>
              {cells.map((cell, cIndex) => {
                total += +cell;
                const cellIndex = cIndex + 1;
                const cellName = `${rowIndex}-${cellIndex}`;
                const isFirstCell = rowIndex === 1 && cellIndex === 1;

                return (
                  <div key={cellName} className="rcv-cell">
                    {(() => {
                      if (cellIndex > rowIndex) {
                        return '';
                      }
                      if (isFirstCell || !isPatternCustom) {
                        return cell;
                      }
                      return (
                        <FormInput
                          isDecimal
                          type="number"
                          placeholder=""
                          maxLength={MAX_LENGTH_RCV_VALUE}
                          name={cellName}
                          value={cell}
                          onChange={handleChangeValue(rowIndex, cellIndex)}
                        />
                      );
                    })()}
                  </div>
                );
              })}
              <div
                className={`rcv-cell${
                  isPatternCustom && !isCorrectRcvTotal(total)
                    ? ' rcv-cell--highlight'
                    : ''
                }`}
              >
                {!isPatternCustom ? RCV_TOTAL.toFixed(2) : total.toFixed(2)}
              </div>
            </div>
          );
        })}
      </div>
    </SettingModal>
  );
}

RcvSettingModal.propTypes = {
  loading: bool.isRequired,
  data: shape({}).isRequired,
  onSubmit: func.isRequired,
  onHide: func.isRequired,
};

export default RcvSettingModal;
