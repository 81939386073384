const getModalStatus = (state) => state.settings.csvSetting.modalStatus;

const getModalSettings = (state) => {
  const { modalItems } = state.settings.csvSetting;
  return modalItems;
};

export default {
  getModalStatus,
  getModalSettings,
};
