/* eslint-disable prefer-destructuring */
import isEmpty from 'lodash/isEmpty';
import types from 'store/dashboard/types';
import { communicationStatus, getIndexOfArrayObject } from 'services/utils';
import FormatData from 'domain/dashboard/FormatData';
import { COMPARE_MODE_TARGET_VALUE } from 'store/dashboard/constants';

const { IDLE, LOADING, UPDATING, SUCCEEDED, FAILED } = communicationStatus;

const initialState = {
  data: {
    metadata: {
      metrics: [],
      conversions: [],
      periods: [],
      service: {},
      listUsers: [],
      currentKpiPeriod: {
        pickup_ids: [],
      },
    },
    pickups: [],
    borders: {},
    chart: [],
    report: {
      media_currrent: [],
      media_past: [],
    },
    compare: [],
    settingMail: {
      data: {},
      errors: [],
    },
  },
  settings: {
    comparePeriod: COMPARE_MODE_TARGET_VALUE,
    targetPeriod: 0,
    showKpiStatusModal: false,
    showSettingMailModal: false,
    period: {},
    chart: {
      yAxisLine: {},
      yAxisColumn: {},
    },
    pickup: {
      action: '',
      data: '',
      pickup_id: '',
    },
  },
  apiStatus: {
    loading: false,
    error: false,
    metadata: {
      status: IDLE,
      errors: null,
    },
    pickup: {
      status: IDLE,
      errors: null,
    },
    border: {
      status: IDLE,
      errors: null,
    },
    chart: {
      status: IDLE,
      errors: null,
    },
    report: {
      status: IDLE,
      errors: null,
    },
    settingMail: {
      status: IDLE,
      errors: null,
    },
    listUsers: IDLE,
  },
};

const DashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_DATA_ALL: {
      const { metrics } = state.data.metadata;
      const channel = payload;

      return {
        ...state,
        data: {
          ...state.data,
          metadata: {
            ...state.data.metadata,
            metrics: FormatData.formatMetricDisplay(metrics, channel),
          },
        },
        apiStatus: {
          ...state.apiStatus,
          error: false,
        },
      };
    }

    case types.FETCH_DATA_METADATA:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          loading: true,
          metadata: {
            ...state.apiStatus.metadata,
            status: LOADING,
          },
        },
      };

    case types.FETCH_DATA_METADATA_SUCCESS: {
      // Set default KPI period is current month
      let { period, targetPeriod } = state.settings;
      if (isEmpty(period)) {
        period = payload.periods[0];
        targetPeriod = period.kpi_period_id;
      }

      return {
        ...state,
        data: {
          ...state.data,
          metadata: payload,
        },
        settings: {
          ...state.settings,
          period,
          targetPeriod,
        },
        apiStatus: {
          ...state.apiStatus,
          loading: false,
          metadata: {
            ...state.apiStatus.metadata,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.FETCH_DATA_METADATA_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          loading: false,
          error: types.FETCH_DATA_METADATA_ERROR,
          metadata: {
            ...state.apiStatus.metadata,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.FETCH_DATA_STATICTIS:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          pickup: {
            ...state.apiStatus.pickup,
            status: LOADING,
          },
        },
      };

    case types.FETCH_DATA_STATICTIS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          pickups: payload,
        },
        settings: {
          ...state.settings,
          pickup: {
            action: '',
            data: '',
            pickup_id: '',
          },
        },
        apiStatus: {
          ...state.apiStatus,
          loading: false,
          pickup: {
            ...state.apiStatus.pickup,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.FETCH_DATA_STATICTIS_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          loading: false,
          pickup: {
            ...state.apiStatus.pickup,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.FETCH_DATA_CHART:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            status: LOADING,
          },
        },
      };

    case types.FETCH_DATA_CHART_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          chart: payload.chart,
        },
        settings: {
          ...state.settings,
          chart: {
            yAxisLine: payload.chartLine,
            yAxisColumn: payload.chartColumn,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          loading: false,
          chart: {
            ...state.apiStatus.chart,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.FETCH_DATA_CHART_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          loading: false,
          chart: {
            ...state.apiStatus.chart,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.FETCH_DATA_REPORT:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          report: {
            ...state.apiStatus.report,
            status: LOADING,
          },
        },
      };

    case types.FETCH_DATA_REPORT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          report: payload,
        },
        apiStatus: {
          ...state.apiStatus,
          loading: false,
          report: {
            ...state.apiStatus.report,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.FETCH_DATA_REPORT_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          loading: false,
          report: {
            ...state.apiStatus.report,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.FETCH_DATA_COMPARE:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          pickup: {
            ...state.apiStatus.pickup,
            status: LOADING,
          },
        },
      };

    case types.FETCH_DATA_COMPARE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          compare: payload,
        },
        apiStatus: {
          ...state.apiStatus,
          pickup: {
            ...state.apiStatus.pickup,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.FETCH_DATA_COMPARE_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          error: types.FETCH_DATA_COMPARE_ERROR,
          pickup: {
            ...state.apiStatus.pickup,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.FETCH_DATA_KPI_STATUS: {
      if (payload.type === LOADING) {
        return {
          ...state,
          apiStatus: {
            ...state.apiStatus,
            border: {
              ...state.apiStatus.pickup,
              status: LOADING,
            },
          },
        };
      }
      if (payload.type === SUCCEEDED) {
        return {
          ...state,
          data: {
            ...state.data,
            borders: payload.data,
          },
          apiStatus: {
            ...state.apiStatus,
            border: {
              ...state.apiStatus.border,
              status: SUCCEEDED,
            },
          },
        };
      }
      if (payload.type === FAILED) {
        return {
          ...state,
          apiStatus: {
            ...state.apiStatus,
            border: {
              ...state.apiStatus.border,
              status: FAILED,
              errors: payload.data,
            },
          },
        };
      }

      return { ...state };
    }

    case types.CHANGE_SELECTED_YAXISLINE:
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            yAxisLine: payload,
          },
        },
      };

    case types.CHANGE_SELECTED_YAXISCOLUMN:
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            yAxisColumn: payload,
          },
        },
      };

    case types.CHANGE_SELECTED_TARGET: {
      const [...periods] = state.data.metadata.periods;
      const index = getIndexOfArrayObject(periods, 'kpi_period_id', payload);

      return {
        ...state,
        settings: {
          ...state.settings,
          targetPeriod: payload,
          period: periods[index],
        },
      };
    }

    case types.SHOW_KPI_STATUS_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          showKpiStatusModal: payload,
        },
      };

    case types.CHANGE_SELECTED_COMPARE:
      return {
        ...state,
        data: {
          ...state.data,
          compare:
            payload === COMPARE_MODE_TARGET_VALUE ? [] : state.data.compare,
        },
        settings: {
          ...state.settings,
          comparePeriod: payload,
        },
      };

    case types.ADD_INDICATOR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          error: false,
          pickup: {
            ...state.apiStatus.pickup,
            status: LOADING,
          },
        },
      };

    case types.ADD_INDICATOR_SUCCESS: {
      const pickups = [...state.data.pickups];
      pickups.push(payload);
      return {
        ...state,
        data: {
          ...state.data,
          pickups,
          metadata: {
            ...state.data.metadata,
            // Add Dashboard Kpi setting for Setting Mail
            currentKpiPeriod: {
              ...state.data.metadata.currentKpiPeriod,
              pickup_ids: [
                ...state.data.metadata.currentKpiPeriod.pickup_ids,
                payload.pickup_id,
              ],
            },
          },
        },
        settings: {
          ...state.settings,
          pickup: {
            action: types.ADD_INDICATOR,
            data: payload,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          pickup: {
            ...state.apiStatus.pickup,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.ADD_INDICATOR_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          error: types.ADD_INDICATOR_ERROR,
          pickup: {
            ...state.apiStatus.pickup,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.EDIT_INDICATOR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          error: false,
          pickup: {
            ...state.apiStatus.pickup,
            status: LOADING,
          },
        },
      };

    case types.EDIT_INDICATOR_SUCCESS: {
      const pickups = [...state.data.pickups];
      const index = getIndexOfArrayObject(
        pickups,
        'pickup_id',
        payload.pickup_id
      );
      pickups[index] = payload.data;

      // Update Dashboard Kpi setting for Setting Mail
      const pickupIds = [...state.data.metadata.currentKpiPeriod.pickup_ids];
      const indexCurrentKpi = pickupIds.indexOf(payload.pickup_id);
      pickupIds[indexCurrentKpi] = payload.data.pickup_id;

      return {
        ...state,
        data: {
          ...state.data,
          pickups,
          metadata: {
            ...state.data.metadata,
            // Update Dashboard Kpi setting for Setting Mail
            currentKpiPeriod: {
              ...state.data.metadata.currentKpiPeriod,
              pickup_ids: pickupIds,
            },
          },
        },
        settings: {
          ...state.settings,
          pickup: {
            action: types.EDIT_INDICATOR,
            data: payload.data,
            pickup_id: payload.pickup_id,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          pickup: {
            ...state.apiStatus.pickup,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.EDIT_INDICATOR_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          error: types.EDIT_INDICATOR_ERROR,
          pickup: {
            ...state.apiStatus.pickup,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.DELETE_INDICATOR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          error: false,
          pickup: {
            ...state.apiStatus.pickup,
            status: LOADING,
          },
        },
      };

    case types.DELETE_INDICATOR_SUCCESS: {
      const pickups = [...state.data.pickups];
      const index = getIndexOfArrayObject(pickups, 'pickup_id', payload);
      pickups.splice(index, 1);

      // Delete Dashboard Kpi setting for Setting Mail
      const pickupIds = [...state.data.metadata.currentKpiPeriod.pickup_ids];
      const indexCurrentKpi = pickupIds.indexOf(payload);
      pickupIds.splice(indexCurrentKpi, 1);

      return {
        ...state,
        data: {
          ...state.data,
          pickups,
          metadata: {
            ...state.data.metadata,
            // Delete Dashboard Kpi setting for Setting Mail
            currentKpiPeriod: {
              ...state.data.metadata.currentKpiPeriod,
              pickup_ids: pickupIds,
            },
          },
        },
        settings: {
          ...state.settings,
          pickup: {
            action: types.DELETE_INDICATOR,
            data: {
              pickup_id: '',
            },
            pickup_id: payload,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          pickup: {
            ...state.apiStatus.pickup,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.DELETE_INDICATOR_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          error: types.DELETE_INDICATOR_ERROR,
          pickup: {
            ...state.apiStatus.pickup,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.UPDATE_DATA_CHART:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            status: payload.loading ? LOADING : UPDATING,
          },
        },
      };

    case types.UPDATE_DATA_CHART_SUCCESS: {
      const chart = [...state.data.chart];
      let { yAxisLine, yAxisColumn } = state.settings.chart;
      if (!payload.pickup_id) {
        // Add data
        chart.push(payload.data);
        if (!yAxisColumn) {
          yAxisColumn = payload.data;
        }
      } else {
        const index = getIndexOfArrayObject(
          chart,
          'pickup_id',
          payload.pickup_id
        );
        const dataOld = chart[index];

        if (payload.data) {
          // Edit data
          chart[index] = payload.data;
          // Update yaxis
          if (yAxisLine && dataOld.pickup_id === yAxisLine.pickup_id) {
            yAxisLine = payload.data;
          }
          if (yAxisColumn && dataOld.pickup_id === yAxisColumn.pickup_id) {
            yAxisColumn = payload.data;
          }
        } else {
          // Delete data
          chart.splice(index, 1);
          // Update yaxis
          if (
            dataOld.pickup_id === yAxisLine.pickup_id ||
            dataOld.pickup_id === yAxisColumn.pickup_id
          ) {
            yAxisLine = chart[0];
            yAxisColumn = chart.length > 1 ? chart[1] : undefined;
          }
        }
      }

      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            status: SUCCEEDED,
          },
        },
        data: {
          ...state.data,
          chart,
        },
        settings: {
          ...state.settings,
          chart: {
            yAxisLine,
            yAxisColumn,
          },
        },
      };
    }

    case types.UPDATE_DATA_CHART_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.UPDATE_DATA_REPORT:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          report: {
            ...state.apiStatus.report,
            status: LOADING,
          },
        },
      };

    case types.UPDATE_DATA_REPORT_SUCCESS: {
      const mediaCurrent = [...state.data.report.media_current];
      mediaCurrent.unshift(payload.media_current);
      const mediaPast = [...state.data.report.media_past];
      mediaPast.unshift(payload.media_past);

      return {
        ...state,
        data: {
          ...state.data,
          report: {
            ...state.data.report,
            media_current: mediaCurrent,
            media_past: mediaPast,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          report: {
            ...state.apiStatus.report,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.UPDATE_DATA_REPORT_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          report: {
            ...state.apiStatus.report,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.UPDATE_DATA_BORDER:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          border: {
            ...state.apiStatus.border,
            status: UPDATING,
          },
          pickup: {
            ...state.apiStatus.pickup,
            status: LOADING,
          },
        },
      };

    case types.UPDATE_DATA_BORDER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          borders: payload,
        },
        apiStatus: {
          ...state.apiStatus,
          border: {
            ...state.apiStatus.border,
            status: SUCCEEDED,
          },
          pickup: {
            ...state.apiStatus.pickup,
            status: SUCCEEDED,
          },
        },
      };
    }

    case types.UPDATE_DATA_BORDER_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          error: types.UPDATE_DATA_BORDER_ERROR,
          border: {
            ...state.apiStatus.border,
            status: FAILED,
            errors: payload,
          },
          pickup: {
            ...state.apiStatus.pickup,
            status: IDLE,
            errors: null,
          },
        },
      };

    case types.SHOW_SETTING_MAIL_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          showSettingMailModal: payload,
        },
      };

    case types.SET_STATUS_SETTING_MAIL:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          settingMail: {
            ...state.apiStatus.settingMail,
            status: payload.status,
          },
        },
      };

    case types.SET_DATA_SETTING_MAIL:
      return {
        ...state,
        data: {
          ...state.data,
          settingMail: payload.data,
        },
        apiStatus: {
          ...state.apiStatus,
          settingMail: {
            ...state.apiStatus.settingMail,
            status: SUCCEEDED,
          },
        },
      };
    case types.UPDATE_SETTING_MAIL:
      return {
        ...state,
        data: {
          ...state.data,
          settingMail: payload.data,
        },
      };

    case types.GET_SETTING_MAIL_ERROR:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          error: types.GET_SETTING_MAIL_ERROR,
          settingMail: {
            ...state.apiStatus.settingMail,
            status: FAILED,
            errors: payload,
          },
        },
      };

    case types.SET_LIST_USERS:
      return {
        ...state,
        data: {
          ...state.data,
          metadata: {
            ...state.data.metadata,
            listUsers: payload.data,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          listUsers: SUCCEEDED,
        },
      };

    case types.SET_STATUS_LIST_USER:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          listUsers: LOADING,
        },
      };

    case types.SET_ERROR_SETTING_MAIL:
      return {
        ...state,
        data: {
          ...state.data,
          settingMail: {
            ...state.data.settingMail,
            errors: payload.errors,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          settingMail: {
            ...state.apiStatus.settingMail,
            status: FAILED,
          },
        },
      };

    default:
      return state;
  }
};

export default DashboardReducer;
