import { call, put, throttle, takeLatest, select } from 'redux-saga/effects';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';
import LtvAdApi from 'services/api/LtvAdApi';
import handleError from 'services/error/handleScopeError';
import ltvAdSelectors from 'store/ltv/settings/ad/selectors';
import filterSelectors from 'store/filters/selectors';
import { getSortString, getOffset } from 'domain/utils';
import uploadActions from 'store/upload/actions';
import { LTV_SETTINGS_AD } from 'services/routes/constants';
import loggerConstants from 'store/logger/constant';
import types from './types';
import actions from './actions';

function* fetchList() {
  try {
    const pagination = yield select(ltvAdSelectors.getPagination);
    const sort = yield select(ltvAdSelectors.getSort);
    yield put(actions.fetchListStart());
    const response = yield call(LtvAdApi.fetchAll, {
      sort: getSortString(sort),
      limit: TABLE_ROWS_PER_PAGE,
      filters: yield select(filterSelectors.getForApi),
      offset: getOffset(pagination),
    });
    yield put(actions.fetchedList({ data: response.data.data }));
    yield put(actions.updateCurrentItemNumber(response.data.metadata.count));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

function* getDetail(action) {
  try {
    yield put(actions.requestStart());
    const response = yield call(LtvAdApi.fetchAdById, action.payload);
    const data = response?.data?.data?.detail || {};
    yield put(actions.setDetail(data));
    yield put(actions.requestSucceeded());
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

function* search(action) {
  try {
    const {
      keyword,
      product_id: productId,
      offer_id: offerId,
    } = action.payload;
    yield put(actions.searchStart());
    const response = yield call(LtvAdApi.fetchAd, {
      limit: 50,
      offset: 0,
      sort: 'ad_name',
      filter_name: keyword,
      product_id: productId,
      offer_id: offerId,
    });
    yield put(actions.searchSuccess({ data: response.data.data.detail }));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

function* update(action) {
  try {
    yield put(actions.requestStart());
    // eslint-disable-next-line no-unused-vars
    const response = yield call(LtvAdApi.update, action.payload);
    if (response.status === 200) {
      yield put(actions.fetchListStart());
      yield put(actions.updated({ data: response.data.data }));
      yield put(actions.fetchList());
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      yield put(
        actions.submitFailed({
          error: error.response,
          scope: loggerConstants.SCOPE_DONT_SHOW,
        })
      );
    } else {
      yield put(
        actions.requestFailed({
          error: handleError(error.response, [400, 403]),
        })
      );
    }
  }
}

function* remove(action) {
  try {
    const ids = action.payload;
    yield put(actions.requestStart());
    const response = yield call(LtvAdApi.clearLink, ids);
    if (response.status === 200) {
      yield put(actions.removed({ ids }));
      yield put(actions.fetchListStart());
      yield put(actions.fetchList());
    }
  } catch (error) {
    yield put(
      actions.requestFailed({ error: handleError(error.response, [400, 403]) })
    );
  }
}

function* upload(action) {
  try {
    const { file } = action.payload;
    yield put(uploadActions.requestStart(LTV_SETTINGS_AD));

    const {
      data: {
        data: { location: uploadPath },
      },
    } = yield call(LtvAdApi.getPresignedUrl);

    yield call(LtvAdApi.upload, uploadPath, file);
    const url = new URL(uploadPath);
    // Get last element after `/` character
    const filename = url.pathname.split('/').slice(-1).pop();

    // validate & import data
    yield call(LtvAdApi.doImport, filename);
    yield put(uploadActions.uploadSucceeded());

    // refresh data list
    yield put(actions.updatePage(1));
    yield put(actions.fetchList());
  } catch (error) {
    const { response = { status: 500 } } = error;
    const status = response?.status ?? 500;
    if (status === 400) {
      // validate error
      yield put(uploadActions.requestFailed(response.data.errors));
    } else {
      // api or app error
      yield put(uploadActions.requestError(handleError(response)));
    }
  }
}

export default function* watchView() {
  yield takeLatest(types.FETCH_LIST, fetchList);
  yield takeLatest(types.SEARCH, search);
  yield takeLatest(types.GET_DETAIL, getDetail);
  yield throttle(1000, types.UPDATE, update);
  yield throttle(1000, types.DELETE, remove);
  yield throttle(3000, types.UPLOAD, upload);
}
