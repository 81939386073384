import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { loggerActions } from 'store/logger';
import loggerConstants from 'store/logger/constant';
import handleError from 'services/error/handleScopeError';
import sharedSagas from 'store/sharedSagas';
import { communicationStatus, HttpStatus } from 'services/utils';
import { convertArrayToObject, getSortString } from 'domain/utils';
import Api from 'services/api/Api';
import mediaSyncManagementServiceApi from 'services/api/MediaSyncManagementApi';
import mediaSyncManagementActions from 'store/media-sync-management/actions';
import mediaSyncManagementSelectors from 'store/media-sync-management/selectors';
import {
  checkHasContractAgent,
  checkHasPermissionSettingUser,
  getUser,
  getAgencyId,
  getAgentFlag,
} from 'store/auth/selectors';
import { masterDataActions } from 'store/master-data';
import { MASTER_DATA_TYPE } from 'domain/consts';
import {
  LINE_AUTH_STEP_FIVE,
  LINE_AUTH_STEP_FOUR,
  LINE_AUTH_STEP_ONE,
  LINE_AUTH_STEP_THREE,
  ACCOUNT_STATUS_ISSUE_COMPLETED,
  ACCOUNT_STATUS_ISSUE_PROCESSING,
  SETTING_BASIC_FIELD,
  MARGINS_DEFAULT,
  ADVERTISER_ID_PREFIX_DEFAULT,
} from 'domain/media-sync-management/consts';
import { loadAuth } from 'services/LocalStorageService';
import { prepareDataRequestForAPI } from 'domain/media-sync-management/Services';
import types from './types';

const { IDLE, LOADING, SUCCEEDED, FAILED } = communicationStatus;

function* handleGetTable() {
  try {
    yield put(mediaSyncManagementActions.setStatusTable(LOADING));
    yield put(mediaSyncManagementActions.setStatusDetail(IDLE));
    yield put(mediaSyncManagementActions.setStatusValidate(IDLE));
    yield put(mediaSyncManagementActions.setStatusUpdate(IDLE));
    const sort = yield select(mediaSyncManagementSelectors.getSort);
    const params = {};
    if (sort.field !== '' && sort.direction !== '') {
      params.sort = getSortString(sort);
    }
    const response = yield call(mediaSyncManagementServiceApi.fetchAll, params);
    if (response.status === 200) {
      yield put(mediaSyncManagementActions.setDataTable(response?.data || []));
    }
  } catch (e) {
    // Since the API does not return a dedicated error code, if an error occurs, it will be unconditionally "Show general error modals".
    yield put(mediaSyncManagementActions.requestFailedTable());
  }
}

function* handleGetDetail(action) {
  const { id } = action.payload;
  yield put(mediaSyncManagementActions.setStatus(action.type, LOADING));
  const response = yield call(mediaSyncManagementServiceApi.fetchDetail, id);
  yield put(
    mediaSyncManagementActions.setDataDetail(response?.data?.data || {})
  );
}

function* handleValidate(action) {
  const { id, data, callback } = action.payload;
  yield put(mediaSyncManagementActions.setStatus(action.type, LOADING));
  yield call(mediaSyncManagementServiceApi.update, id, {
    ...prepareDataRequestForAPI(data, Object.keys(SETTING_BASIC_FIELD)),
    is_validate_only: true,
  });
  yield put(mediaSyncManagementActions.setStatus(action.type, SUCCEEDED));
  callback({ type: action.type, data });
}

function* handleCreate(action) {
  const { data, callback } = action.payload;
  yield put(mediaSyncManagementActions.setStatus(action.type, LOADING));
  const response = yield call(mediaSyncManagementServiceApi.create, data);
  if (response.status === 200) {
    yield put(mediaSyncManagementActions.setStatus(action.type, IDLE));
    yield fork(handleGetTable);
    callback({});
  }
}

function* handleUpdate(action) {
  const { id, data, callback } = action.payload;
  yield put(mediaSyncManagementActions.setStatus(action.type, LOADING));
  const response = yield call(mediaSyncManagementServiceApi.update, id, data);
  if (response.status === 200) {
    yield put(mediaSyncManagementActions.setDataDetail({}));
    yield put(mediaSyncManagementActions.setStatus(action.type, IDLE));
    yield fork(handleGetTable);
    callback({});
  }
}

function* getMasterData() {
  const [
    hasPermissionSettingUser,
    hasContractAgent,
    agencyId,
    isAgent,
    user,
  ] = [
    yield select(checkHasPermissionSettingUser),
    yield select(checkHasContractAgent),
    yield select(getAgencyId),
    yield select(getAgentFlag),
    yield select(getUser),
  ];

  const callApiActions = [
    put(masterDataActions.setType(MASTER_DATA_TYPE.SETTING)),
    put(masterDataActions.fetchMedia(isAgent ? agencyId : '')),
  ];

  if (hasContractAgent && !isAgent) {
    callApiActions.push(put(masterDataActions.fetchAgency()));
  }

  if (hasPermissionSettingUser && !isAgent) {
    callApiActions.push(put(masterDataActions.fetchUser()));
  } else {
    // Always display itself
    yield put(
      masterDataActions.fetchUserSucceeded([
        {
          user_id: user.userId,
          user_roles: user.userRoles,
          company_name: user.companyName,
          charge_name: user.chargeName,
          email: user.email,
          agent_flag: user.agentFlag,
          agency_id: user.agencyId,
          agency_name: user.agencyName,
        },
      ])
    );
  }

  yield all(callApiActions);
}

function* handleStatusAccount(action) {
  const { callback } = action.payload;
  const {
    data: {
      data: { status },
    },
  } = yield call(mediaSyncManagementServiceApi.getStatusAccount);

  if (status === ACCOUNT_STATUS_ISSUE_COMPLETED) {
    yield call(Api.refreshToken);
    const token = loadAuth();
    yield call(Api.updateUserInfo, token?.accessToken);
  }

  yield put(mediaSyncManagementActions.setStatusAccount(status));

  callback();
}

function* handleIssueAccount() {
  const {
    data: {
      data: { result },
    },
  } = yield call(mediaSyncManagementServiceApi.issueAccount);

  if (result) {
    yield put(
      mediaSyncManagementActions.setStatusAccount(
        ACCOUNT_STATUS_ISSUE_PROCESSING
      )
    );
  }
}

function* handleDeleteAccount(action) {
  const {
    data,
    callback: { handleErrorResponse, closeModals },
  } = action.payload;
  try {
    yield put(mediaSyncManagementActions.setStatusDelete(LOADING));
    const response = yield call(
      mediaSyncManagementServiceApi.deleteAccount,
      data
    );
    if (response.status === 200) {
      yield put(mediaSyncManagementActions.setStatusDelete(SUCCEEDED));
      closeModals();
      yield put(mediaSyncManagementActions.getDataTable());
    }
  } catch (e) {
    const { error: response } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    closeModals();
    handleErrorResponse(errors, action.type);
  }
}

function* handleChangeStatus(action) {
  const {
    requestObj,
    callback: { handleErrorResponse, closeModals },
  } = action.payload;
  try {
    yield put(mediaSyncManagementActions.setStatusStop(LOADING));
    yield call(mediaSyncManagementServiceApi.changeStatus, requestObj);
    yield put(mediaSyncManagementActions.setStatusStop(SUCCEEDED));
    closeModals();
    yield put(mediaSyncManagementActions.getDataTable());
  } catch (e) {
    const { error: response } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    yield put(mediaSyncManagementActions.setStatusStop(FAILED));
    const errors = response?.data?.errors || [];
    closeModals();
    handleErrorResponse(errors, action.type);
  }
}

function* handleGetListAdvertiser(action) {
  const {
    syncMediaId,
    data: mediaData,
    advertiserId,
    callback,
    id, // said
  } = action.payload;
  const payloadData = {
    sync_media_id: syncMediaId,
    ...mediaData,
  };
  yield put(mediaSyncManagementActions.setStatus(action.type, LOADING));
  const {
    data: { data, data_account: account },
  } = yield call(mediaSyncManagementServiceApi.getListAdvertiser, payloadData);
  const formatData = data.map((item) => ({
    advertiser_id: item.i,
    advertiser_name: item.n,
    id: item.i,
    name: `${item.n ? item.n : ''}（ID：${item.i}）`,
  }));
  if (formatData.length > 0) {
    const advertiser =
      advertiserId &&
      !String(advertiserId).startsWith(ADVERTISER_ID_PREFIX_DEFAULT)
        ? // eslint-disable-next-line
          formatData.find((ad) => ad.advertiser_id == advertiserId) || {}
        : formatData[0];
    callback(advertiser, account);
  } else {
    callback(null, account);
  }
  yield put(mediaSyncManagementActions.setListAdvertiser(formatData, account));
  yield put(mediaSyncManagementActions.setStatus(action.type, SUCCEEDED));
  if (id && formatData.length > 0) {
    yield fork(handleGetDetail, {
      action: types.GET,
      payload: { id },
    });
  }
}

function* handleGetMargins(action) {
  const { data: payloadData, callback, id } = action.payload;
  yield put(mediaSyncManagementActions.setStatus(action.type, LOADING));
  const {
    data: { data },
  } = yield call(mediaSyncManagementServiceApi.getMargins, payloadData);
  // use this key for margin id
  const keyIdMargin = 'margin_id';
  const isFirstSettingMargins = data.margins.length < 1;
  const dataMediaAccount = {
    ...data,
    margins: isFirstSettingMargins
      ? MARGINS_DEFAULT
      : convertArrayToObject(data.margins, keyIdMargin),
    used_margins: convertArrayToObject(data.used_margins, keyIdMargin),
  };
  callback(dataMediaAccount);
  yield put(mediaSyncManagementActions.setMargins(dataMediaAccount));
  yield put(mediaSyncManagementActions.setStatus(action.type, SUCCEEDED));
  if (id) {
    yield fork(handleGetDetail, {
      action: types.GET,
      payload: { id },
    });
  }
}

function* handleGenerateAuthUrl(action) {
  const { data: dataPayload, callback } = action.payload;
  const {
    data: {
      data: { auth_url: authUrl },
    },
  } = yield call(mediaSyncManagementServiceApi.getAuthUrl, dataPayload);
  callback({ authUrl });
}

// handle call api check advertiser registered with any setting media
function* handleVerifyAdvertiserIdRegistered(action) {
  const { data, handleResponse } = action.payload;
  yield put(mediaSyncManagementActions.setStatus(action.type, LOADING));
  const {
    data: { data: dataResponse },
  } = yield call(mediaSyncManagementServiceApi.checkAdvertiserId, data);
  handleResponse(dataResponse);
  yield put(mediaSyncManagementActions.setStatus(action.type, SUCCEEDED));
}

// handle call api authentication Line step 1
function* handleCheckAdvertiserId(action) {
  const { data, handleResponse } = action.payload;
  yield put(mediaSyncManagementActions.setStatusAuthLine(LOADING));
  const {
    data: { data: dataResponse },
  } = yield call(mediaSyncManagementServiceApi.checkAdvertiserId, data);
  yield put(mediaSyncManagementActions.setStatusAuthLine(SUCCEEDED));
  handleResponse({ status: SUCCEEDED, data: dataResponse });
}

// handle call api authentication Line step 3
function* handleSendLinkAuthLine(action) {
  const { data, handleResponse } = action.payload;
  yield put(mediaSyncManagementActions.setStatusAuthLine(LOADING));
  const {
    data: { data: dataResponse },
  } = yield call(mediaSyncManagementServiceApi.sendLinkAuthLine, data);
  handleResponse({
    status: SUCCEEDED,
    data: {
      authFileName: dataResponse.auth_file_name,
      alreadyAuthorized: dataResponse.already_authorized,
      linkRequestApproved: dataResponse.link_request_approved,
    },
  });
  yield put(mediaSyncManagementActions.setStatusAuthLine(SUCCEEDED));
}

// handle call api authentication Line step 4
function* handleVerifyClientApproval(action) {
  const { data, handleResponse } = action.payload;
  yield put(mediaSyncManagementActions.setStatusAuthLine(LOADING));
  yield call(mediaSyncManagementServiceApi.verifyClientApproval, data);
  yield put(mediaSyncManagementActions.setStatusAuthLine(SUCCEEDED));
  handleResponse({ status: SUCCEEDED });
}

// handle call api authentication Line step 5
function* handleVerifyDowloadedImage(action) {
  const { data, handleResponse } = action.payload;
  yield put(mediaSyncManagementActions.setStatusAuthLine(LOADING));
  yield call(mediaSyncManagementServiceApi.verifyDowloadedImage, data);
  handleResponse({ status: SUCCEEDED });
  yield put(mediaSyncManagementActions.setStatusAuthLine(SUCCEEDED));
}

// handle verify password when edit line media
function* handleVerifyPasswordLine(action) {
  const { data, handleResponse } = action.payload;
  yield put(mediaSyncManagementActions.setStatusAuthLine(LOADING));
  yield call(mediaSyncManagementServiceApi.verifyPasswordLine, data);
  handleResponse({ status: SUCCEEDED });
  yield put(mediaSyncManagementActions.setStatusAuthLine(SUCCEEDED));
}

function* errorHandler(err, action) {
  const { callback = () => {} } = action.payload || {};
  const { error, scope } = handleError(err?.response || {}, [
    HttpStatus.BAD_REQUEST,
  ]);
  const errors = error?.data?.errors || [];

  switch (action.type) {
    case types.GET:
    case types.CREATE:
    case types.UPDATE:
    case types.VALIDATE:
      yield put(mediaSyncManagementActions.setErrors(action.type, scope));
      break;

    default:
      yield put(
        loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR)
      );
      break;
  }

  callback({
    isErrorValidate: err?.response?.status === HttpStatus.BAD_REQUEST,
    type: action.type,
    errors,
  });
}

function* errorHandleAuthLine(err, action) {
  const { handleResponse = () => {} } = action.payload || {};
  yield put(mediaSyncManagementActions.setStatusAuthLine(FAILED));
  const { error, scope } = handleError(err?.response || {}, [
    HttpStatus.BAD_REQUEST,
    HttpStatus.NOT_FOUND,
  ]);
  yield put(mediaSyncManagementActions.setErrors(action.type, scope));
  if (error.status === HttpStatus.NOT_FOUND) {
    switch (action.type) {
      case types.SEND_LINK_AUTH_LINE:
        handleResponse({ status: FAILED, step: LINE_AUTH_STEP_THREE });
        break;
      case types.VERIFY_CLIENT_APPROVAL:
        handleResponse({ status: FAILED, step: LINE_AUTH_STEP_FOUR });
        break;
      case types.VERIFY_DOWLOADED_IMAGE:
        handleResponse({ status: FAILED, step: LINE_AUTH_STEP_FIVE });
        break;
      default:
    }
  }
  if (error.status === HttpStatus.BAD_REQUEST) {
    switch (action.type) {
      case types.VERIFY_PASSWORD_LINE:
        handleResponse({
          status: FAILED,
          step: LINE_AUTH_STEP_ONE,
          errors: error?.data?.errors || [],
        });
        break;
      default:
        break;
    }
  }
}

export default function* watch() {
  yield takeLatest(types.FETCH, sharedSagas.safe(errorHandler, handleGetTable));
  yield takeLatest(types.GET, sharedSagas.safe(errorHandler, handleGetDetail));
  yield takeLatest(
    types.VALIDATE,
    sharedSagas.safe(errorHandler, handleValidate)
  );
  yield takeLatest(types.CREATE, sharedSagas.safe(errorHandler, handleCreate));
  yield takeLatest(types.UPDATE, sharedSagas.safe(errorHandler, handleUpdate));
  yield takeLatest(
    types.GET_MASTER_DATA,
    sharedSagas.safe(errorHandler, getMasterData)
  );
  yield takeLatest(
    types.ISSUE_ACCOUNT,
    sharedSagas.safe(errorHandler, handleIssueAccount)
  );
  yield takeLatest(
    types.GET_STATUS_ACCOUNT,
    sharedSagas.safe(errorHandler, handleStatusAccount)
  );
  yield takeLatest(
    types.GET_LIST_ADVERTISER,
    sharedSagas.safe(errorHandler, handleGetListAdvertiser)
  );
  yield takeLatest(
    types.GET_MARGINS,
    sharedSagas.safe(errorHandler, handleGetMargins)
  );

  yield takeLatest(
    types.GENERATE_AUTH_URL,
    sharedSagas.safe(errorHandler, handleGenerateAuthUrl)
  );
  yield takeLatest(
    types.CHECK_ADVERTISER_ID,
    sharedSagas.safe(errorHandleAuthLine, handleCheckAdvertiserId)
  );
  yield takeLatest(
    types.SEND_LINK_AUTH_LINE,
    sharedSagas.safe(errorHandleAuthLine, handleSendLinkAuthLine)
  );
  yield takeLatest(
    types.VERIFY_CLIENT_APPROVAL,
    sharedSagas.safe(errorHandleAuthLine, handleVerifyClientApproval)
  );
  yield takeLatest(
    types.VERIFY_DOWLOADED_IMAGE,
    sharedSagas.safe(errorHandleAuthLine, handleVerifyDowloadedImage)
  );
  yield takeLatest(
    types.VERIFY_PASSWORD_LINE,
    sharedSagas.safe(errorHandleAuthLine, handleVerifyPasswordLine)
  );
  yield takeLatest(
    types.DELETE_ACCOUNT,
    sharedSagas.safe(errorHandler, handleDeleteAccount)
  );
  yield takeLatest(
    types.CHANGE_STATUS,
    sharedSagas.safe(errorHandler, handleChangeStatus)
  );
  yield takeLatest(
    types.VERIFY_ADVERTISER_ID_REGISTERED,
    sharedSagas.safe(errorHandler, handleVerifyAdvertiserIdRegistered)
  );
}
