import React, { useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';

import './switch.scss';

const Switch = (props) => {
  const { disabled, active, label, onChange, className } = props;

  const [isActive, setIsActive] = useState(active);

  const getClassName = () => {
    // className="eb-switch d-flex align-items-center"
    let name = 'eb-switch';
    if (isActive) {
      name += '--active';
    } else {
      name += '--default';
    }

    if (disabled) {
      name += ' disabled';
    }
    return `${className || ''} ${name}`;
  };

  const getContentClassName = () => {
    let name = 'eb-switch__content';
    if (isActive) {
      name += ' active';
    } else {
      name += ' default';
    }
    return name;
  };

  const handleClick = () => {
    setIsActive(!isActive);
    onChange(!isActive);
  };

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <div className={getClassName()}>
      <span
        role="button"
        onClick={handleClick}
        className={getContentClassName()}
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <span className="eb-switch__text">{label}</span>
        <span className="eb-switch__toggle" aria-label="toggle" />
      </span>
    </div>
  );
};

Switch.propTypes = {
  label: string.isRequired,
  disabled: bool,
  active: bool,
  onChange: func,
  className: string,
};

Switch.defaultProps = {
  disabled: false,
  active: false,
  onChange: () => {},
  className: null,
};

export default Switch;
