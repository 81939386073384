import { resolve } from 'domain/permissions/permissionTypes';
import {
  PRIORITY_AXIS_BOTH,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_MEDIA_SYNC,
} from 'domain/consts';
import {
  DISPLAY_GROUP_AGGREGATION_AXIS,
  CROSSDEVICE_DIFF_COMPARISON,
  CONTACT_DEVICE,
  FUNC_CODE_LOG_PAGE_ANALYZE,
} from 'domain/settings/display-items';
import { createSelector } from 'reselect';
import get from 'lodash/get';

import DisplayItemsService from 'domain/settings/DisplayItemsService';
import DisplayItemsForViewService from 'domain/settings/DisplayItemsForViewService';
import { settingsSelectors } from 'store/settings';
import { settingsForViewSelectors } from 'store/settings-for-view';
import * as constant from 'store/settings/constant';
import * as pages from 'services/routes/constants';
import { getPermissions } from 'store/auth/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import { MEDIA_SYNC_PERMISSIONS } from 'domain/permissions/contractGroups';
import filterSelectors from 'store/filters/selectors';
import { FILTER_KEY_CROSS_DEVICE } from 'services/consts';
import { isWarningFilter } from 'services/utils';
import reflectionTimeSelectors from 'store/reflectiontime/selectors';

const actions = {
  [constant.TAB_AD]: {
    [pages.CATEGORY_ANALYZE]: DisplayItemsService.getCategoryAnalysisAd(),
    [pages.COST_ALLOCATION]: DisplayItemsService.getCostAllocationAnalysisAd(),
    [pages.DETAILS_ANALYSIS]: DisplayItemsService.getDetailAnalysisAd(),
    [pages.COMPARE_PERIOD]: DisplayItemsService.getPeriodAnalysisAd(),
    [pages.CV_ATTRIBUTE]: DisplayItemsService.getCvAttributesAd(),
    [pages.CV_FLOW]: DisplayItemsService.getCvFlowAd(),
    // [pages.LTV_ANALYZE]: DisplayItemsService.getLtvAnalysisAd(),
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsService.getLandingPageAnalysisAd(),
    [pages.LPO_LINK]: DisplayItemsService.getLpoLinkAd(),
    [pages.LPO_PERIOD]: DisplayItemsService.getLpoPeriodAd(),
    [pages.INFLOW_ANALYSIS]: DisplayItemsForViewService.getInflowAnalysisAd(),
  },
  [constant.TAB_COMMON]: {
    // [pages.CATEGORY_ANALYZE]: DisplayItemsForViewService.getCategoryAnalysisView(),
    [pages.DETAILS_ANALYSIS]: DisplayItemsForViewService.getDetailAnalysisView(),
    // [pages.COMPARE_PERIOD]: DisplayItemsForViewService.getPeriodAnalysisView(),
    [pages.CV_ATTRIBUTE]: DisplayItemsForViewService.getCvAttributesView(),
    [pages.CV_FLOW]: DisplayItemsForViewService.getCvFlowView(),
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsForViewService.getLandingPageAnalysisView(),
    // [pages.COST_ALLOCATION]: DisplayItemsService.getCostAllocationAnalysisAd(),
    [pages.LPO_LINK]: DisplayItemsService.getLpoLinkAd(),
    [pages.LPO_PERIOD]: DisplayItemsService.getLpoPeriodAd(),
    [pages.LOG_PAGE_ANALYZE]: DisplayItemsService.getLogPageAnalysisAll(),
    [pages.LOG_PERIOD_ANALYZE]: DisplayItemsService.getLogPeriodAnalysisAll(),
    [pages.INFLOW_ANALYSIS]: DisplayItemsForViewService.getInflowAnalysisView(),
  },
};

const displayDefault = {
  [constant.TAB_AD]: {
    [pages.CATEGORY_ANALYZE]: DisplayItemsService.getCategoryAnalysisAdDefault(),
    [pages.COST_ALLOCATION]: DisplayItemsService.getCostAllocationAnalysisAdDefault(),
    [pages.DETAILS_ANALYSIS]: DisplayItemsService.getDetailAnalysisAdDefault(),
    [pages.COMPARE_PERIOD]: DisplayItemsService.getPeriodAnalysisAdDefault(),
    [pages.CV_ATTRIBUTE]: DisplayItemsService.getCvAttributesAdDefault(),
    [pages.CV_FLOW]: DisplayItemsService.getCvFlowAdDefault(),
    // [pages.LTV_ANALYZE]: DisplayItemsService.getLtvAnalysisAdDefault(),
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsService.getLandingPageAnalysisAdDefault(),
    [pages.LPO_LINK]: DisplayItemsService.getLpoLinkAdDefault(),
    [pages.LPO_PERIOD]: DisplayItemsService.getLpoPeriodAdDefault(),
    [pages.INFLOW_ANALYSIS]: DisplayItemsForViewService.getInflowAnalysisAdDefault(),
  },
  [constant.TAB_COMMON]: {
    // [pages.CATEGORY_ANALYZE]: DisplayItemsForViewService.getCategoryAnalysisViewDefault(),
    [pages.DETAILS_ANALYSIS]: DisplayItemsForViewService.getDetailAnalysisViewDefault(),
    // [pages.COMPARE_PERIOD]: DisplayItemsForViewService.getPeriodAnalysisViewDefault(),
    [pages.CV_ATTRIBUTE]: DisplayItemsForViewService.getCvAttributesViewDefault(),
    [pages.CV_FLOW]: DisplayItemsForViewService.getCvFlowViewDefault(),
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsForViewService.getLandingPageAnalysisViewDefault(),
    // [pages.COST_ALLOCATION]: DisplayItemsService.getCostAllocationAnalysisAdDefault(),
    [pages.LPO_LINK]: DisplayItemsService.getLpoLinkAdDefault(),
    [pages.LPO_PERIOD]: DisplayItemsService.getLpoPeriodAdDefault(),
    [pages.LOG_PAGE_ANALYZE]: DisplayItemsService.getLogPageAnalysisAllDefault(),
    [pages.LOG_PERIOD_ANALYZE]: DisplayItemsService.getLogPeriodAnalysisAllDefault(),
    [pages.INFLOW_ANALYSIS]: DisplayItemsForViewService.getInflowAnalysisViewDefault(),
  },
};

const axis = {
  [constant.TAB_AD]: {
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsService.getLandingPageAnalysisAdAxis(),
  },
  [constant.TAB_COMMON]: {
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsForViewService.getLandingPageAnalysisViewAxis(),
    [pages.LOG_PAGE_ANALYZE]: DisplayItemsService.getLogPageAnalysisAllAxis(),
  },
};

const getSorting = (state) => state.settings.displayItemsForView.sorting || [];
const getDisplayItemFuncId = (state) => state.settings.displayItemsForView.funcId;

const getAxis = (state) => {
  const { tab, page } = state.settings.reportForView;

  return axis[tab]?.[page] || [];
};

const getSettings = (state) => {
  const { items } = state.settings.displayItemsForView;
  const filters = filterSelectors.getSettings(state);
  const screenId = settingsForViewSelectors.getPage(state);
  const { period } = state.commonState.CommonState.settings;
  const date = reflectionTimeSelectors.getReflectionTimeData(state);

  // Update display items if there are invalid filters.
  if (
    FILTER_KEY_CROSS_DEVICE in filters ||
    CROSSDEVICE_DIFF_COMPARISON in items
  ) {
    const warningFilter = isWarningFilter(
      FILTER_KEY_CROSS_DEVICE,
      filters,
      screenId,
      period,
      date.cross_device
    ).isWarning;
    if (warningFilter && CROSSDEVICE_DIFF_COMPARISON in items) {
      items.crossdevice_diff_comparison = false;
    }
    if (
      !(FILTER_KEY_CROSS_DEVICE in filters) &&
      items.crossdevice_diff_comparison
    ) {
      items.crossdevice_diff_comparison = false;
    }
    if (warningFilter && CONTACT_DEVICE in items) {
      items.contact_device = false;
    }
  }

  return items;
};

const getDisplayItemPriorityAxis = createSelector(
  [
    getSettings,
    getPermissions,
    settingsForViewSelectors.getTab,
    settingsForViewSelectors.getPage,
  ],
  (settings, permissions, tab, pageId) => {
    if (resolve(MEDIA_SYNC_PERMISSIONS[tab], permissions)) {
      if (pageId === pages.AD_MANAGEMENT) {
        return PRIORITY_AXIS_BOTH;
      }
      return settings.media_sync
        ? PRIORITY_AXIS_MEDIA_SYNC
        : PRIORITY_AXIS_EBIS;
    }
    return PRIORITY_AXIS_EBIS;
  }
);

/**
 * <pre>
 *   {
 *     title: "string",
 *     group: "aggregation_axis", // defined in group_list attribute of each screen, or "item" by default
 *     disabled: boolean,
 *     dataType: boolean: string
 *     decimalPoint: number,
 *     displayFreeze: boolean,
 *     order: number,
 *     displayDefault: boolean,
 *     sortDisabled: boolean,
 *     sortDefault: "none",
 *     required: boolean,
 *   }
 * </pre>
 */
const getItems = createSelector(
  [
    settingsForViewSelectors.getPage,
    settingsForViewSelectors.getTab,
    masterDataSelectors.getDisplay,
    getDisplayItemPriorityAxis,
    getSorting,
  ],
  (page, tab, display, priorityAxis, sortItems) => {
    if (actions[tab] && actions[tab][page]) {
      const items = DisplayItemsService.mergeSettingLabels(
        actions[tab][page],
        display
      );
      return sortItems.reduce((acc, key, index) => {
        if (
          items[key]?.getGroup(priorityAxis) !== DISPLAY_GROUP_AGGREGATION_AXIS
        ) {
          return acc;
        }
        return { ...acc, [key]: { ...acc[key], order: index } };
      }, items);
    }
    return {};
  }
);

const getItemPermissions = createSelector(
  [getItems, getPermissions, settingsForViewSelectors.getTab],
  (items, userPermissions, tab) => {
    return Object.keys(items).reduce((acc, key) => {
      const permissionDef = get(items[key], `permissionSet.${tab}`);
      const permitted =
        !permissionDef || resolve(permissionDef, userPermissions);
      acc[key] = permitted;
      return acc;
    }, {});
  }
);

const getUserPermittedItems = createSelector(
  [getItems, getItemPermissions],
  (items, itemPermissions) => {
    return Object.keys(items)
      .filter((key) => itemPermissions[key])
      .reduce((acc, key) => {
        acc[key] = items[key];
        return acc;
      }, {});
  }
);

const getDisplayDefault = (state) => {
  const { tab, page } = state.settings.reportForView;
  if (displayDefault[tab] && displayDefault[tab][page]) {
    return displayDefault[tab][page];
  }
  return {};
};

const getSettingsDefault = (state) => {
  const settings = getDisplayDefault(state);
  const settingsDefault = {};
  Object.keys(settings).map((key) => {
    if (settings[key].displayDefault) {
      settingsDefault[key] = true;
    }
    return key;
  });
  return settingsDefault;
};

const hasContractMediaSync = createSelector(
  [getPermissions, settingsSelectors.getTab],
  (permissions, tab) => {
    return resolve(MEDIA_SYNC_PERMISSIONS[tab], permissions);
  }
);

const enabledPriorityAxis = createSelector(
  [getDisplayItemFuncId, hasContractMediaSync],
  (funcId, hasContract) => {
    return hasContract && ![FUNC_CODE_LOG_PAGE_ANALYZE].includes(funcId);
  }
);

export default {
  getUserPermittedItems,
  getSettingsDefault,
  getSettings,
  getDisplayItemFuncId,
  getDisplayItemPriorityAxis,
  getAxis,
  getSorting,
  enabledPriorityAxis,
};
