import { CATEGORY_NAME, SETTING_VALUE, COMPANY_NAME } from 'domain/fields';
import {
  FILE_ENCODING_CHECK,
  FILE_EXIST_CHECK,
} from 'services/validations/messageErrorByCodeForCsv';

const LINE_SPTAB_CHECK = `{label}を「スペース、タブ、改行」のみで登録することはできません。「スペース、タブ、改行」以外の文字列を入力してください。`;
const EMPTY_CHECK = `{label}を入力してください`;
const EMPTY_LINE_CHECK =
  '{label}は必須入力項目です。{label}を入力してください。';
const MAX_LENGTH_CHECK = `{label}は{length}字以下で入力してください。`;
const CONTROL_CHARACTER_CHECK = `制御文字を削除してください。`;

export const msgAgencyManagementCsvError = {
  FILE_SIZE_CHECK: `1ファイルあたり{size}MB以下`,
  FILE_MAX_LINE_CHECK: `CSVの登録上限は、{length}行までです。`,
  FILE_CSV_HEADER_VALID_CHECK: `CSVフォーマットの形式が異なります。\n代理店管理用のフォーマットでCSVを再アップロードしてください。`,
  FILE_INVALID: `CSVフォーマットの形式が異なります。\n 代理店管理用のフォーマットでCSVを再アップロードしてください。`,
  REQUIRED_CHECK: EMPTY_CHECK,
  TOO_MAX_ERROR:
    '{label}エラーが{length}件以上あります。<a href="{link}" target="_blank" rel="noopener noreferrer">こちら</a>を参考に、修正したCSVをアップロードしてください。',
  EMPTY_CHECK,
  FILE_ENCODING_CHECK,
  FILE_EXIST_CHECK,
};

const ACCEPTED_VALUE_CHECK =
  '未登録の広告カテゴリが入力されています。\n広告カテゴリ名は「媒体種別」「広告グループ1」「広告グループ2」のいずれかを記載してください。\n※広告カテゴリのラベル名を編集されている場合は、<a href="{link}" target="_blank" rel="noopener noreferrer">こちら</a>から設定内容を確認してください。';
const SETTING_VALUE_NOT_EXIST_CHECK =
  '未登録の広告カテゴリ名が入力されています。\n[広告設定>広告カテゴリ管理]で広告カテゴリ名を登録してください。';
const COMPANY_NAME_EXIST_CHECK =
  '未登録の会社名が入力されています。\n[システム設定>ユーザー管理]で会社名を登録してください。';

export const msgLineAgencyCsvError = {
  REQUIRED_CHECK: EMPTY_CHECK,
  EMPTY_CHECK: EMPTY_LINE_CHECK,
  MAX_LENGTH_CHECK,
  CONTROL_CHARACTER_CHECK,
  SPTAB_CHECK: LINE_SPTAB_CHECK,
  ACCEPTED_VALUE_CHECK,
  SETTING_VALUE_NOT_EXIST_CHECK,
  COMPANY_NAME_EXIST_CHECK,
};

export const agencyManagementCsvfields = {
  [COMPANY_NAME]: {
    label: '会社名',
  },
  [CATEGORY_NAME]: {
    label: '広告カテゴリ名',
  },
  [SETTING_VALUE]: {
    label: '設定値',
  },
};
