import {
  AD_ID,
  AD_NOTE,
  BASE_CPC,
  BASE_CPCONV,
  KEYWORD,
  PAGE_TITLE,
  TEXT_DATA,
  URL,
  BANNER,
  AD_END_DATE,
  AD_START_DATE,
} from 'domain/fields';
import {
  BANNER_MAX_BYTES,
  AD_ID_MAX,
  AD_KEYWORD_MAX,
  AD_NOTE_MAX,
  URL_MAX,
  PAGE_TITLE_MAX,
  TEXT_DATA_MAX,
  BASE_COST_RANGE,
  DATE_RANGE,
} from 'domain/ad-management/consts';
import {
  validId,
  maxLength,
  notBeginWith,
  notContainControlCharacters,
  notContainSpaces,
  numberInRange,
  requiredValue,
  validAdUrl,
  isBetweenPeriod,
  imageFile,
  maxFileSize,
} from 'services/validations/commonValidations';
import adErrorMessages from 'services/validations/adErrorMessages';

const adManagementRules = {
  [AD_ID]: [
    validId(adErrorMessages.MSG_TOOLTIP_AD_ID_REGISTER_CHECK),
    notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
    notBeginWith(
      ['tad', 'tdv', 'cnv'],
      adErrorMessages.MSG_TOOLTIP_AD_ID_RESERVED_CHECK
    ),
    maxLength(AD_ID_MAX, adErrorMessages.MSG_TOOLTIP_MAX_LENGTH_CHECK),
  ],
  [KEYWORD]: [
    requiredValue(adErrorMessages.MSG_TOOLTIP_EMPTY_CHECK),
    notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
    notContainControlCharacters(
      adErrorMessages.MSG_TOOLTIP_CONTROL_CHARACTER_CHECK
    ),
    maxLength(AD_KEYWORD_MAX, adErrorMessages.MSG_TOOLTIP_MAX_LENGTH_CHECK),
  ],
  [URL]: [
    requiredValue(adErrorMessages.MSG_TOOLTIP_EMPTY_CHECK),
    notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
    validAdUrl([
      adErrorMessages.MSG_TOOLTIP_AD_URL_FORMAT_CHECK,
      adErrorMessages.MSG_TOOLTIP_AD_URL_NOT_QUESTION_MARK_CHECK,
    ]),
    maxLength(URL_MAX, adErrorMessages.MSG_TOOLTIP_MAX_LENGTH_CHECK),
  ],
  [PAGE_TITLE]: [
    notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
    notContainControlCharacters(
      adErrorMessages.MSG_TOOLTIP_CONTROL_CHARACTER_CHECK
    ),
    maxLength(PAGE_TITLE_MAX, adErrorMessages.MSG_TOOLTIP_MAX_LENGTH_CHECK),
  ],
  [AD_NOTE]: [
    notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
    notContainControlCharacters(
      adErrorMessages.MSG_TOOLTIP_CONTROL_CHARACTER_CHECK
    ),
    maxLength(AD_NOTE_MAX, adErrorMessages.MSG_TOOLTIP_MAX_LENGTH_CHECK),
  ],
  [BASE_CPC]: [
    notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
    numberInRange(
      BASE_COST_RANGE[0],
      BASE_COST_RANGE[1],
      adErrorMessages.MSG_TOOLTIP_NUM_RANGE_CHECK
    ),
  ],
  [BASE_CPCONV]: [
    notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
    numberInRange(
      BASE_COST_RANGE[0],
      BASE_COST_RANGE[1],
      adErrorMessages.MSG_TOOLTIP_NUM_RANGE_CHECK
    ),
  ],
  [AD_START_DATE]: [
    isBetweenPeriod(
      DATE_RANGE[0],
      DATE_RANGE[1],
      adErrorMessages.MSG_TOOLTIP_FORMAT_DATE_ACCEPTED_CHECK
    ),
  ],
  [AD_END_DATE]: [
    isBetweenPeriod(
      DATE_RANGE[0],
      DATE_RANGE[1],
      adErrorMessages.MSG_TOOLTIP_FORMAT_DATE_ACCEPTED_CHECK
    ),
  ],
  [TEXT_DATA]: [
    notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
    maxLength(TEXT_DATA_MAX, adErrorMessages.MSG_TOOLTIP_MAX_LENGTH_CHECK),
  ],
  [BANNER]: [
    imageFile(
      ['gif', 'jpg', 'png'],
      adErrorMessages.MSG_TOOLTIP_FILE_EXTENSION_CHECK
    ),
    maxFileSize(BANNER_MAX_BYTES, adErrorMessages.MSG_TOOLTIP_FILE_SIZE_CHECK),
  ],
};

export default adManagementRules;
