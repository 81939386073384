import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isString } from 'lodash';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import { ResizeBar } from 'views/organism/table/Table';
import { FIELD_PREFIX, MEDIA_SIDE_ITEMS_AD_MANAGEMENT } from 'domain/consts';
import { getLinkRedirect, getSuffix } from 'services/utils';
import Cell from 'views/organism/table/Cell';
import CellCheckbox from 'views/organism/table/CellCheckbox';
import Sort from 'views/organism/table/Sort';
import TableTooltips from 'services/common/TableTooltips';
import classNames from 'classnames';
import TableContext from 'views/templates/TableStatisticsTemplate/components/table-context';
import { useSelector } from 'react-redux';
import commonSelectors from 'store/common/selectors';
import * as FIELDS from 'domain/fields';
import { AD_MANAGEMENT } from 'services/routes/constants';
import MediaSyncIcon from 'views/atoms/MediaSyncIcon/MediaSyncIcon';
import { TAB_ALL } from 'services/consts';

// TODO 共通処理なので、ScreenTitleConfig.jsみたいな処理にしたい
const getBadCssStyle = (metric, value) => {
  let badFlg;
  switch (metric) {
    case FIELDS.PAYBACK_PERIOD_DIFFERENCE:
      badFlg = value > 0;
      break;
    case FIELDS.LTV_PROFIT:
    case FIELDS.LTV_PROFIT_TOTAL:
    case FIELDS.MARGINAL_PROFIT:
      badFlg = value < 0;
      break;
    default:
      badFlg = false;
      break;
  }
  return badFlg;
};

const getNextSort = (currentSort) => {
  let direction = 'desc';
  if (currentSort === 'asc') {
    direction = 'desc';
  }
  if (currentSort === 'desc') {
    direction = 'asc';
  }

  return direction;
};

const getTooltip = (metric, screenId = '', channel = '') => {
  // handle get tooltip message by screen and tab
  if (
    screenId in TableTooltips.tooltipOverrideByTabMessages &&
    channel in TableTooltips.tooltipOverrideByTabMessages[screenId] &&
    metric in TableTooltips.tooltipOverrideByTabMessages[screenId][TAB_ALL]
  ) {
    return TableTooltips.tooltipOverrideByTabMessages[screenId][TAB_ALL][
      metric
    ];
  }
  if (
    screenId in TableTooltips.tooltipOverrideMessages &&
    metric in TableTooltips.tooltipOverrideMessages[screenId]
  ) {
    return TableTooltips.tooltipOverrideMessages[screenId][metric];
  }
  const suffix = getSuffix(metric, Object.values(FIELD_PREFIX));
  return TableTooltips.toolTipMessages[suffix];
};

const NOT_TOOLTIP = [FIELDS.RCV, FIELDS.RAMOUNT];
const ROWSPAN1_POINTER_ITEMS = [FIELDS.RCV_CROSS, FIELDS.RAMOUNT_CROSS];

const HeaderGroup = (props) => {
  const {
    data,
    isFreeze,
    allowSort,
    columnsWidth,
    disabledCheckAll,
    onSort,
    onCheckAll,
    onResizeColumn,
    screenId,
    channel,
  } = props;

  const mappedFilterKeyList = useSelector(
    commonSelectors.getMappedFilterKeyList
  );

  const { attrs } = useContext(TableContext);

  const handleClickSort = (currentSort, field) => {
    if (currentSort === false || !allowSort) return;
    onSort([
      {
        direction: getNextSort(currentSort),
        field,
      },
    ]);
  };

  return (
    <tr className="freeze-table__header-group">
      {data
        .filter((item) => item.isDimension === isFreeze)
        .map((item) => {
          let widthColumn = null;
          let rowSpan = 2;
          let colSpan = 1;
          let resizeBar = (
            <ResizeBar column={item.name} onResize={onResizeColumn} />
          );
          if (item.children && item.children.length > 0) {
            rowSpan = 1;
            colSpan = item.children.length;
            resizeBar = '';
          }
          const classHeader = classNames({
            'freeze-table__header': true,
            'merge-left-group': item.mergeColumn,
            'verical-middle': !!getLinkRedirect(item.name),
          });

          let { isPrediction } = item;
          if (item.children) {
            // column group
            isPrediction = item.children.every((child) => child.isPrediction);
          }

          // Prepare a mapping between the filter key list and the table's display column name
          let isFilteredItem;
          if (mappedFilterKeyList.includes(item.name)) {
            isFilteredItem = true;
          }

          const classCellContent = classNames({
            'freeze-table__cell-content': true,
            prediction: isPrediction,
            [item.name]: true,
            [`column__${item.name}`]: true,
            'event-none': item.sort === false || !allowSort,
            'filtered-item': isFilteredItem,
            'contain-link': !!getLinkRedirect(item.name),
            'rowspan1-pointer':
              rowSpan === 1 && ROWSPAN1_POINTER_ITEMS.includes(item.name),
          });

          let styles = {};

          if (item.name in columnsWidth) {
            styles = { ...styles, width: columnsWidth[item.name] };
          }
          if ('columns' in attrs && item.name in attrs.columns) {
            styles = { ...attrs.columns[item.name] };
          }

          const predictionIconClass = classNames({
            'prediction-default': true,
            'prediction-icon': isPrediction,
          });
          const predictionIconSettingClass = classNames({
            'prediction-setting': isPrediction,
            'filtered-item-setting': isFilteredItem,
          });

          const filteredItemIconClass = classNames({
            'filtered-group-item-icon': isFilteredItem,
          });

          let cell = (
            <div
              className={classCellContent}
              onClick={() => handleClickSort(item.sort, item.name)}
              aria-hidden="true"
              style={styles}
            >
              <span
                className={predictionIconSettingClass}
                data-text={item.text}
              >
                {item.text}
                {screenId !== AD_MANAGEMENT &&
                  item.tooltip !== false &&
                  getTooltip(item.name, screenId, channel) && (
                    <DragonBall
                      stayOpen={TableTooltips.stayOpenFields.includes(
                        item.name
                      )}
                    >
                      {isString(item.tooltip)
                        ? item.tooltip
                        : getTooltip(item.name, screenId, channel)}
                    </DragonBall>
                  )}
                {screenId === AD_MANAGEMENT &&
                  MEDIA_SIDE_ITEMS_AD_MANAGEMENT.includes(item.name) && (
                    <MediaSyncIcon className="freeze-table__media-sync-icon" />
                  )}
                {isFilteredItem && (
                  <span className={`icon-svg ${filteredItemIconClass}`} />
                )}
                {isPrediction && (
                  <div>
                    <i className={predictionIconClass}>
                      <span>予測</span>
                    </i>
                  </div>
                )}
                {rowSpan > 1 && allowSort && <Sort direction={item.sort} />}
              </span>
              {!!getLinkRedirect(item.name) && (
                <Link
                  className="d-block txt-note"
                  to={getLinkRedirect(item.name)}
                >
                  設定へ
                </Link>
              )}
            </div>
          );

          if (item.name === 'check_all') {
            widthColumn = item.isEdit && !disabledCheckAll ? 78 : 46;
            resizeBar = '';
            cell = (
              <CellCheckbox
                id="check_all"
                className={classCellContent}
                checked={item.checked}
                variant={item.variant}
                onChange={onCheckAll}
                isEdit={false}
                disabled={disabledCheckAll}
                width={widthColumn}
              />
            );
          } else if (item.name === 'edit_btn') {
            widthColumn = 46;
            resizeBar = '';
            cell = <div className={classCellContent} width={widthColumn} />;
          }

          return (
            <th
              className={classHeader}
              key={item.name}
              rowSpan={rowSpan}
              colSpan={colSpan}
              style={{ width: widthColumn }}
            >
              {cell}
              {resizeBar}
            </th>
          );
        })}
    </tr>
  );
};

HeaderGroup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  columnsWidth: PropTypes.oneOfType([PropTypes.object]),
  isFreeze: PropTypes.bool.isRequired,
  allowSort: PropTypes.bool,
  disabledCheckAll: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func,
  onResizeColumn: PropTypes.shape({
    setRowsHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    setColumnsWidth: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  }),
  screenId: PropTypes.string,
  channel: PropTypes.string,
};

HeaderGroup.defaultProps = {
  data: [],
  columnsWidth: {},
  allowSort: false,
  disabledCheckAll: false,
  onCheckAll: () => {},
  onResizeColumn: {
    setRowsHeight: false,
    setColumnsWidth: false,
  },
  screenId: '',
  channel: '',
};

const HeaderItem = (props) => {
  const {
    groups,
    headers,
    isFreeze,
    allowSort,
    columnsWidth,
    onSort,
    onResizeColumn,
  } = props;

  const mappedFilterKeyList = useSelector(
    commonSelectors.getMappedFilterKeyList
  );

  const { attrs } = useContext(TableContext);

  const handleClickSort = (currentSort, field) => {
    if (currentSort === false || !allowSort) return;
    onSort([
      {
        direction: getNextSort(currentSort),
        field,
      },
    ]);
  };
  const headerNames = headers.map((item) => {
    return item.name;
  });

  return (
    <tr className="freeze-table__header-all">
      {headers
        .filter((item) => item.isDimension === isFreeze)
        .map((item) => {
          let display = '';
          let classname = classNames({
            'freeze-table__header': true,
          });

          if (groups.find((element) => element.name === item.name)) {
            display = 'none';
          } else {
            const group = groups.find(
              (element) =>
                element.children &&
                element.children.find((child) => child.name === item.name)
            );
            const lastItem = group.children[group.children.length - 1];

            classname = classNames({
              'freeze-table__header': false,
              [`group__${group.name}`]: true,
              'last-child': lastItem.name === item.name,
            });
          }

          // Prepare a mapping between the filter key list and the table's display column name
          let isFilteredItem;
          if (mappedFilterKeyList.includes(item.name)) {
            isFilteredItem = true;
          }

          const classCellContent = classNames({
            'freeze-table__cell-content': true,
            prediction: item.isPrediction,
            [item.name]: true,
            [`column__${item.name}`]: true,
            'event-none': item.sort === false || !allowSort,
            'filtered-item': isFilteredItem,
          });

          let styles = {};
          if (item.name in columnsWidth) {
            styles = { ...styles, width: columnsWidth[item.name] };
          }
          if ('columns' in attrs && item.name in attrs.columns) {
            styles = { ...attrs.columns[item.name] };
          }

          const predictionIconClass = classNames({
            'prediction-default': true,
            'prediction-icon': item.isPrediction,
          });

          const filteredItemSettingClass = classNames({
            'filtered-item-setting': isFilteredItem,
          });
          const filteredItemIconClass = classNames({
            'filtered-item-icon': isFilteredItem,
          });

          // Disable tooltips for children when cross-device is enabled
          if (
            NOT_TOOLTIP.includes(item.name) &&
            headerNames.includes(`${item.name}_cross_linked_before`)
          ) {
            item.tooltip = false;
          }

          const childrenContent = (
            <>
              {item.text}
              {item.tooltip !== false && getTooltip(item.name) && (
                <DragonBall>{getTooltip(item.name)}</DragonBall>
              )}
              {isFilteredItem && (
                <span className={`icon-svg ${filteredItemIconClass}`} />
              )}
            </>
          );

          return (
            <th key={item.name} className={classname} style={{ display }}>
              <div
                className={classCellContent}
                onClick={() => handleClickSort(item.sort, item.name)}
                aria-hidden="true"
                style={styles}
              >
                <span
                  className={filteredItemSettingClass}
                  data-text={item.text}
                >
                  {item.isPrediction ? (
                    <i className={predictionIconClass}>
                      <span>{childrenContent}</span>
                    </i>
                  ) : (
                    <>{childrenContent}</>
                  )}
                  {display !== 'none' && allowSort && (
                    <Sort direction={item.sort} />
                  )}
                </span>
              </div>
              <ResizeBar onResize={onResizeColumn} column={item.name} />
            </th>
          );
        })}
    </tr>
  );
};

HeaderItem.propTypes = {
  groups: PropTypes.oneOfType([PropTypes.array]),
  headers: PropTypes.oneOfType([PropTypes.array]),
  columnsWidth: PropTypes.oneOfType([PropTypes.object]),
  isFreeze: PropTypes.bool.isRequired,
  allowSort: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.shape({
    setRowsHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    setColumnsWidth: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  }),
};

HeaderItem.defaultProps = {
  groups: [],
  headers: [],
  columnsWidth: {},
  allowSort: false,
  onResizeColumn: {
    setRowsHeight: false,
    setColumnsWidth: false,
  },
};

const HeaderTotal = (props) => {
  const { headers, data, columnsWidth, isFreeze } = props;

  const countFreeze = headers.reduce(
    (count, item) => (item.isDimension ? count + 1 : count + 0),
    0
  );

  return (
    <tr className="freeze-table__total">
      {isFreeze ? (
        <td className="data-row data-row-0" colSpan={countFreeze}>
          <div className="freeze-table__cell-content">合計</div>
        </td>
      ) : (
        headers
          .filter((header) => header.isDimension === false)
          .map((header) => {
            const badflg = getBadCssStyle(header.name, data[header.name]);

            const classname = classNames({
              'data-row': true,
              'data-row-0': true,
              'merge-left-group': header.mergeTotal,
              prediction: header.isPrediction,
              bad_results: badflg,
            });

            return (
              <td key={header.name} className={classname}>
                <Cell
                  metric={header.name}
                  data={data}
                  width={
                    header.name in columnsWidth
                      ? columnsWidth[header.name]
                      : null
                  }
                />
              </td>
            );
          })
      )}
    </tr>
  );
};

HeaderTotal.propTypes = {
  headers: PropTypes.oneOfType([PropTypes.array]),
  columnsWidth: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.array,
    ])
  ),
  isFreeze: PropTypes.bool.isRequired,
};

HeaderTotal.defaultProps = {
  data: {},
  headers: [],
  columnsWidth: {},
};

export { HeaderGroup, HeaderItem, HeaderTotal };
