export const anyOf = (permissions = []) => {
  return {
    type: 'anyOf',
    permissions,
  };
};

export const allOf = (permissions = []) => {
  return {
    type: 'allOf',
    permissions,
  };
};

export const allBy = (permissions = []) => {
  return {
    type: 'allBy',
    permissions,
  };
};

export const none = () => {
  return {
    type: 'none',
    permissions: [],
  };
};

export const permitted = () => {
  return {
    type: 'permitted',
    permissions: [],
  };
};

const resolveAnyOf = (permissionDef, permissions) => {
  return permissionDef.permissions.some((anyPermission) =>
    permissions.includes(anyPermission)
  );
};

const resolveAllOf = (permissionDef, permissions) => {
  return permissionDef.permissions.every((requiredPermission) =>
    permissions.includes(requiredPermission)
  );
};

const resolveAllBy = (permissionDef, permissions) => {
  return permissions.every((requiredPermission) =>
    permissionDef.permissions.includes(requiredPermission)
  );
};

const resolveNone = () => false;

const resolvePermitted = () => true;

export const resolve = (permissionDef, permissions = []) => {
  if (!permissionDef.type) {
    throw new Error('Must have permission type');
  }
  switch (permissionDef.type) {
    case 'anyOf':
      return resolveAnyOf(permissionDef, permissions);
    case 'allOf':
      return resolveAllOf(permissionDef, permissions);
    case 'allBy':
      return resolveAllBy(permissionDef, permissions);
    case 'none':
      return resolveNone();
    case 'permitted':
      return resolvePermitted();
    default:
      throw new Error(`Invalid permission type: ${permissionDef.type}`);
  }
};
