import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { oneOfType, object, bool, node, string } from 'prop-types';

import './dragon-ball.scss';

const DragonBall = React.forwardRef(
  ({ svg, icon, variant, placement, stayOpen, children }, ref) => {
    const [isOpen, toggleOpen] = useState(false);
    const handleStayOpen = (isToggleOpen) =>
      stayOpen && toggleOpen(isToggleOpen);
    const [container, setContainer] = useState(document.body);

    useEffect(() => {
      if (ref && ref.current) {
        setContainer(ref.current);
      }
    }, [ref]);

    return (
      <OverlayTrigger
        show={isOpen}
        key={placement}
        placement={placement}
        container={container}
        onToggle={(isShow) => toggleOpen(isShow)}
        overlay={
          <Tooltip
            onMouseOver={() => handleStayOpen(true)}
            onMouseOut={() => handleStayOpen(false)}
            className={`dragonball-tooltip variant-${variant} ${placement}`}
          >
            {children}
          </Tooltip>
        }
      >
        {svg ? (
          <span className="dragonball-icon">{svg}</span>
        ) : (
          <i className={`dragonball-icon ${icon}`} variant={variant} />
        )}
      </OverlayTrigger>
    );
  }
);

DragonBall.propTypes = {
  svg: oneOfType([object]),
  icon: string,
  variant: string,
  placement: string,
  children: node.isRequired,
  stayOpen: bool,
};

DragonBall.defaultProps = {
  svg: undefined,
  icon: 'fas fa-question-circle',
  variant: 'base',
  placement: 'top',
  stayOpen: false,
};

export default DragonBall;
