import React, { useContext, useEffect, useRef, useMemo } from 'react';
import { oneOfType, element } from 'prop-types';
import {
  GridTableContext,
  GridTableItemContext,
} from 'views/organism/GridTable/GridTableContext';
import { formatGridTemplateRows } from 'views/organism/GridTable/GridTableService';

function GridTableBodyWrapper(props) {
  const { children } = props;
  const {
    dispatch,
    isFreeze,
    viewportHeight,
    settings: { isResizing, heightRow },
  } = useContext(GridTableContext);
  const { dispatchItem, gridTemplateColumns: widthColumn } = useContext(
    GridTableItemContext
  );

  const bodyRef = useRef(null);

  const gridTemplateRows = useMemo(
    () => formatGridTemplateRows(Object.values(heightRow)),
    [heightRow]
  );

  const gridTemplateColumns = useMemo(
    () => Object.values(widthColumn).join(' '),
    [widthColumn]
  );

  useEffect(() => {
    if (!bodyRef?.current) return;

    dispatchItem({ type: 'setAttrs', payload: { bodyRef } });

    dispatch({
      type: 'setInfoTable',
      payload: {
        isFreeze,
        info: { widthTable: bodyRef.current.offsetWidth },
      },
    });
  }, [dispatch, dispatchItem, bodyRef, isResizing, isFreeze]);

  return (
    <div className="grid-table__body-wrapper">
      <div
        ref={bodyRef}
        role="table"
        className="grid-table__body-container"
        style={{ minHeight: viewportHeight }}
      >
        <div
          role="rowgroup"
          className="grid-table__body"
          style={{
            gridTemplateColumns,
            gridTemplateRows,
            gridColumn: `1/${Object.values(widthColumn).length + 1}`,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

GridTableBodyWrapper.propTypes = {
  children: oneOfType([element]).isRequired,
};

GridTableBodyWrapper.defaultProps = {};

export default GridTableBodyWrapper;
