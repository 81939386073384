const UPDATE_AXIS = 'lpo/period/UPDATE_AXIS';
const REMOVE_SECONDARY_METRIC = 'lpo/period/REMOVE_SECONDARY_METRIC';
const UPDATE_BOOKMARK = 'lpo/period/UPDATE_BOOKMARK';
const SET_SHOW_CHART = 'lpo/period/SET_SHOW_CHART';
const UPDATE_CATEGORIES = 'lpo/period/UPDATE_CATEGORIES';
const GET_BOOKMARK = 'lpo/period/GET_BOOKMARK';
const DELETE_BOOKMARK = 'lpo/period/DELETE_BOOKMARK';

const APPLY_BOOKMARK = 'lpo/period/APPLY_BOOKMARK';

const SET_TABLE_CURRENT_PAGE = 'lpo/period/SET_TABLE_CURRENT_PAGE';
const UPDATE_SORT = 'lpo/period/UPDATE_SORT';
const SELECT_ROW = 'lpo/period/SELECT_ROW';
const DESELECT_ROW = 'lpo/period/DESELECT_ROW';
const SET_TABLE_CURRENT_PAGE_COMPARED =
  'lpo/period/SET_TABLE_CURRENT_PAGE_COMPARED';
const UPDATE_SORT_COMPARED = 'lpo/period/UPDATE_SORT_COMPARED';
const SELECT_ROW_COMPARED = 'lpo/period/SELECT_ROW_COMPARED';
const DESELECT_ROW_COMPARED = 'lpo/period/DESELECT_ROW_COMPARED';
const SET_VISIBLE = 'lpo/period/SET_VISIBLE';
const UPDATE_VISIBLE = 'lpo/period/UPDATE_VISIBLE';
const UPDATE_PERIOD_TYPE = 'lpo/period/UPDATE_PERIOD_TYPE';
const TOGGLE_MEMO = 'lpo/period/TOGGLE_MEMO';
const CREATE_MEMO = 'lpo/period/CREATE_MEMO';
const CREATE_MEMO_SUCCESS = 'lpo/period/CREATE_MEMO_SUCCESS';
const CREATE_MEMO_ERROR = 'lpo/period/CREATE_MEMO_ERROR';
const CHANGE_ACTIVE_TABLE = 'lpo/period/CHANGE_ACTIVE_TABLE';
const SHOW_MODAL = 'lpo/period/SHOW_MODAL';
const HIDE_MODAL = 'lpo/period/HIDE_MODAL';
const SHOW_DELETE_MODAL = 'lpo/period/SHOW_DELETE_MODAL';
const HIDE_DELETE_MODAL = 'lpo/period/HIDE_DELETE_MODAL';
const SHOW_MEMO_ERROR_MODAL = 'lpo/period/SHOW_MEMO_ERROR_MODAL';
const HIDE_MEMO_ERROR_MODAL = 'lpo/period/HIDE_MEMO_ERROR_MODAL';

const LOAD_MEMO = 'lpo/period/LOAD_MEMO';
const LOAD_MEMO_SUCCESS = 'lpo/period/LOAD_MEMO_SUCCESS';
const LOAD_MEMO_FAILED = 'lpo/period/LOAD_MEMO_FAILED';

const DELETE_MEMO = 'lpo/period/DELETE_MEMO';
const DELETE_MEMO_SUCCESS = 'lpo/period/DELETE_MEMO_SUCCESS';
const DELETE_MEMO_FAILED = 'lpo/period/DELETE_MEMO_FAILED';

const UPDATE_MEMO = 'lpo/period/UPDATE_MEMO';
const UPDATE_MEMO_SUCCESS = 'lpo/period/UPDATE_MEMO_SUCCESS';
const UPDATE_MEMO_FAILED = 'lpo/period/UPDATE_MEMO_FAILED';

const UPDATE_MEMO_LIST = 'lpo/period/UPDATE_MEMO_LIST';
const SET_MEMO_SUBMITTING = 'lpo/period/SET_MEMO_SUBMITTING';

const GET_CHART_REPORT = 'lpo/period/GET_CHART_REPORT';
const GET_COMPARED_CHART_REPORT = 'lpo/period/GET_COMPARED_CHART_REPORT';
const GET_TABLE_REPORT = 'lpo/period/GET_TABLE_REPORT';
const GET_COMPARED_TABLE_REPORT = 'lpo/period/GET_COMPARED_TABLE_REPORT';

const UPDATE_CHART_DATA = 'lpo/period/UPDATE_CHART_DATA';
const UPDATE_COMPARED_CHART_DATA = 'lpo/period/UPDATE_COMPARED_CHART_DATA';
const UPDATE_TABLE_DATA = 'lpo/period/UPDATE_TABLE_DATA';
const UPDATE_COMPARED_TABLE_DATA = 'lpo/period/UPDATE_COMPARED_TABLE_DATA';
const UPDATE_TABLE_DISPLAY_ROWS = 'lpo/period/UPDATE_TABLE_DISPLAY_ROWS';

const CLEAN_STATES = 'lpo/period/CLEAN_STATES';

const SET_TABLE_STATUS = 'lpo/period/SET_TABLE_STATUS';
const SET_COMPARED_TABLE_STATUS = 'lpo/period/SET_COMPARED_TABLE_STATUS';
const SET_CHART_STATUS = 'lpo/period/SET_CHART_STATUS';

const UPDATE_CHART_DISPLAY_MEMO = 'lpo/period/UPDATE_CHART_DISPLAY_MEMO';

const SET_PERIOD_RANGE = 'lpo/period/SET_PERIOD_RANGE';
const SET_COMPARED_PERIOD_RANGE = 'lpo/period/SET_COMPARED_PERIOD_RANGE';

const UPDATE_CHART_DISPLAY_DATA = 'lpo/period/UPDATE_CHART_DISPLAY_DATA';

const SET_MEMO_ERRORS = 'lpo/period/SET_MEMO_ERRORS';

const LOG_ERROR = 'lpo/period/LOG_ERROR';

const REFRESH_TABLE_CURRENT = 'lpo/period/REFRESH_TABLE_CURRENT';

const REFRESH_TABLE_COMPARED = 'lpo/period/REFRESH_TABLE_COMPARED';

export default {
  UPDATE_AXIS,
  REMOVE_SECONDARY_METRIC,
  UPDATE_BOOKMARK,
  GET_BOOKMARK,
  DELETE_BOOKMARK,
  APPLY_BOOKMARK,
  SET_SHOW_CHART,
  UPDATE_CATEGORIES,
  SET_TABLE_CURRENT_PAGE,
  UPDATE_SORT,
  SELECT_ROW,
  DESELECT_ROW,
  SET_TABLE_CURRENT_PAGE_COMPARED,
  UPDATE_SORT_COMPARED,
  SELECT_ROW_COMPARED,
  DESELECT_ROW_COMPARED,
  SET_VISIBLE,
  UPDATE_VISIBLE,

  UPDATE_PERIOD_TYPE,
  TOGGLE_MEMO,
  CREATE_MEMO,
  CREATE_MEMO_SUCCESS,
  CREATE_MEMO_ERROR,
  CHANGE_ACTIVE_TABLE,

  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_DELETE_MODAL,
  HIDE_DELETE_MODAL,
  SHOW_MEMO_ERROR_MODAL,
  HIDE_MEMO_ERROR_MODAL,

  LOAD_MEMO,
  LOAD_MEMO_SUCCESS,
  LOAD_MEMO_FAILED,

  DELETE_MEMO,
  DELETE_MEMO_SUCCESS,
  DELETE_MEMO_FAILED,

  UPDATE_MEMO,
  UPDATE_MEMO_SUCCESS,
  UPDATE_MEMO_FAILED,

  UPDATE_MEMO_LIST,
  SET_MEMO_SUBMITTING,

  GET_CHART_REPORT,
  GET_COMPARED_CHART_REPORT,
  GET_TABLE_REPORT,
  GET_COMPARED_TABLE_REPORT,
  UPDATE_CHART_DATA,
  UPDATE_COMPARED_CHART_DATA,
  UPDATE_TABLE_DATA,
  UPDATE_COMPARED_TABLE_DATA,

  UPDATE_TABLE_DISPLAY_ROWS,

  CLEAN_STATES,
  SET_TABLE_STATUS,
  SET_COMPARED_TABLE_STATUS,
  SET_CHART_STATUS,

  UPDATE_CHART_DISPLAY_MEMO,

  SET_PERIOD_RANGE,
  SET_COMPARED_PERIOD_RANGE,

  UPDATE_CHART_DISPLAY_DATA,

  SET_MEMO_ERRORS,

  LOG_ERROR,

  REFRESH_TABLE_CURRENT,
  REFRESH_TABLE_COMPARED,
};
