import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'react-bootstrap';
import {
  bool,
  string,
  func,
  arrayOf,
  shape,
  number,
  oneOfType,
} from 'prop-types';
import classNames from 'classnames';
import isString from 'lodash/isString';
import EbisDropdown from 'views/atoms/dropdown/EbisDropdown';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';

import './form-matching.scss';

const MatchingItem = (props) => {
  const {
    option,
    input,
    placeholder,
    allowDelete,
    disabled,
    onUpdate,
    onDelete,
  } = props;
  const { name: optionName, value: optionValue, list: optionList } = option;
  const { name: inputName, value: inputValue, error: inputError } = input;

  const [data, setData] = useState({
    [optionName]: optionValue,
    [inputName]: inputValue,
  });

  const handleChangeOption = useCallback(
    (value) => {
      onUpdate({ ...data, [optionName]: value });
    },
    [onUpdate, optionName, data]
  );

  const handleChangeInput = useCallback(
    (name, value) => {
      onUpdate({ ...data, [name]: value });
    },
    [data, onUpdate]
  );

  useEffect(() => {
    const optionItem =
      optionList.find((item) => item.key === optionValue) || optionList[0];

    setData({ [optionName]: optionItem.key, [inputName]: inputValue });
  }, [optionList, optionValue, inputValue, optionName, inputName]);

  return (
    <div className="form-matching__item">
      <div className="form-matching__option">
        <EbisDropdown
          optionObject={optionList}
          defaultVal={data[optionName]}
          error={option.error}
          isDisabled={disabled}
          selectCallback={handleChangeOption}
        />
      </div>
      <div className="form-matching__input">
        <FormInput
          name={inputName}
          value={data[inputName]}
          error={inputError}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChangeInput}
        />
      </div>
      {allowDelete && (
        <div
          role="button"
          aria-hidden="true"
          className="form-matching__icon-delete icon-hover"
          onClick={onDelete}
        >
          <i className="fal fa-times" />
        </div>
      )}
    </div>
  );
};

MatchingItem.propTypes = {
  option: shape({
    name: string,
    selected: string,
    list: arrayOf({}),
  }),
  input: shape({ name: string, value: string }),
  placeholder: string,
  allowDelete: bool,
  disabled: bool,
  onUpdate: func,
  onDelete: func,
};

MatchingItem.defaultProps = {
  option: {},
  input: {},
  placeholder: '値を入力',
  allowDelete: false,
  disabled: false,
  onUpdate: () => {},
  onDelete: () => {},
};

function FormMatching(props) {
  const {
    items,
    options,
    optionDefault,
    optionKey,
    inputKey,
    note,
    error,
    placeholder,
    minItem,
    maxItem,
    disabled,
    onChange,
  } = props;

  const [actualItems, setItems] = useState(items);

  const refList = useRef(null);

  const { denyCreate, allowDelete } = useMemo(() => {
    return {
      denyCreate: actualItems.length >= maxItem,
      allowDelete: actualItems.length > minItem,
    };
  }, [actualItems, minItem, maxItem]);

  const initialItem = useMemo(
    () => ({
      [optionKey]: optionDefault,
      [inputKey]: '',
    }),
    [inputKey, optionDefault, optionKey]
  );

  const isErrorCommon = !!error && isString(error);

  const classList = classNames({
    'form-matching__list': true,
    'form-matching__list--error': isErrorCommon,
  });

  const handleCreateItem = () => {
    if (disabled) return;
    setItems((prevItems) => {
      const cloneItems = [...prevItems, initialItem];
      onChange(cloneItems);
      return cloneItems;
    });
  };

  const handleUpdateItem = useCallback(
    (indexItem) => (data) => {
      setItems((prevItems) => {
        const cloneItems = [...prevItems];
        cloneItems[indexItem] = data;
        onChange(cloneItems);
        return cloneItems;
      });
    },
    [onChange]
  );

  const handleDeleteItem = useCallback(
    (indexItem) => () => {
      setItems((prevItems) => {
        const cloneItems = [...prevItems];
        cloneItems.splice(indexItem, 1);
        onChange(cloneItems);
        return cloneItems;
      });
    },
    [onChange]
  );

  useEffect(() => {
    let cloneItems = [...items];
    if (items.length <= 0 && minItem > 0) {
      cloneItems = [...Array(minItem).keys()].map(() => initialItem);
    }
    setItems(cloneItems);
  }, [items, initialItem, minItem]);

  return (
    <div className="form-matching" disabled={disabled} ref={refList}>
      <ErrorTooltipWrapper
        isError={isErrorCommon}
        errorMess={isString(error) ? error : ''}
        ref={refList}
      >
        <div className={classList}>
          {actualItems.map((item, index) => {
            const indexItem = index;
            const option = {
              list: options,
              name: optionKey,
              value: item[optionKey] || optionDefault,
              error: item[`${optionKey}_error`] || null,
            };
            const input = {
              name: inputKey,
              value: item[inputKey],
              error: item[`${inputKey}_error`] || null,
            };

            return (
              <MatchingItem
                key={`form-matching-${indexItem}-${item[optionKey]}`}
                option={option}
                input={input}
                placeholder={placeholder}
                allowDelete={allowDelete}
                disabled={disabled}
                onUpdate={handleUpdateItem(indexItem)}
                onDelete={handleDeleteItem(indexItem)}
              />
            );
          })}
        </div>
      </ErrorTooltipWrapper>
      {note && <div className="form-matching__note">{note}</div>}
      <Button
        variant="link"
        size="sm"
        className="form-matching__addition"
        disabled={denyCreate || disabled}
        onClick={handleCreateItem}
      >
        追加
      </Button>
    </div>
  );
}

FormMatching.propTypes = {
  items: arrayOf(shape({})),
  options: arrayOf(shape({ key: string, name: string })),
  optionDefault: string,
  optionKey: string.isRequired,
  inputKey: string.isRequired,
  note: string,
  error: oneOfType([string, bool]),
  placeholder: string,
  minItem: number,
  maxItem: number,
  disabled: bool,
  onChange: func,
};

FormMatching.defaultProps = {
  items: [],
  options: {},
  optionDefault: '',
  note: '',
  error: '',
  placeholder: '',
  minItem: 1,
  maxItem: 10,
  disabled: false,
  onChange: () => {},
};

export default React.memo(FormMatching);
