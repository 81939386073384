import React from 'react';
import { isEmpty } from 'lodash';
import { formatDisplayEmailInfo } from 'services/data-export/dataExportService';

export const formatUsersData = (users = []) => {
  if (isEmpty(users)) return [];

  const icon = <i className="fas fa-check" />;
  return users.map((user) => {
    const { agent_flag: agentFlag } = user;

    let email = user.email || '';
    const emails = email.split(',').map((item) => ({ email: item }));
    if (emails.length > 1) {
      email = formatDisplayEmailInfo(emails);
    }

    return {
      ...user,
      email,
      agent: agentFlag ? icon : null,
      rowId: user.user_id,
    };
  });
};

export const formatCapiSettingDetail = (data = {}) => {
  const { notification_content: notification = '{}', ...restData } = data;

  return {
    ...restData,
  };
};
