import { resolve } from 'domain/permissions/permissionTypes';
import {
  PRIORITY_AXIS_BOTH,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_MEDIA_SYNC,
} from 'domain/consts';
import {
  DISPLAY_GROUP_AGGREGATION_AXIS,
  CROSSDEVICE_DIFF_COMPARISON,
  CONTACT_DEVICE,
  FUNC_CODE_LOG_PAGE_ANALYZE,
} from 'domain/settings/display-items';
import { createSelector } from 'reselect';
import get from 'lodash/get';

import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { settingsSelectors } from 'store/settings';
import * as constant from 'store/settings/constant';
import * as pages from 'services/routes/constants';
import * as DisplayItems from 'domain/settings/display-items';
import { getPermissions } from 'store/auth/selectors';
import { CATEGORY_ANALYZE, COMPARE_PERIOD } from 'services/routes/constants';
import masterDataSelectors from 'store/master-data/selectors';
import { MEDIA_SYNC_PERMISSIONS } from 'domain/permissions/contractGroups';
import filterSelectors from 'store/filters/selectors';
import {
  FILTER_KEY_CROSS_DEVICE,
  FILTER_KEY_SEARCH_CONSOLE,
} from 'services/consts';
import { isWarningFilter } from 'services/utils';
import reflectionTimeSelectors from 'store/reflectiontime/selectors';

const actions = {
  [constant.TAB_AD]: {
    [pages.CATEGORY_ANALYZE]: DisplayItemsService.getCategoryAnalysisAd(),
    [pages.COST_ALLOCATION]: DisplayItemsService.getCostAllocationAnalysisAd(),
    [pages.DETAILS_ANALYSIS]: DisplayItemsService.getDetailAnalysisAd(),
    [pages.COMPARE_PERIOD]: DisplayItemsService.getPeriodAnalysisAd(),
    [pages.CV_ATTRIBUTE]: DisplayItemsService.getCvAttributesAd(),
    [pages.CV_FLOW]: DisplayItemsService.getCvFlowAd(),
    [pages.LTV_ANALYZE]: DisplayItemsService.getLtvAnalysisAd(),
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsService.getLandingPageAnalysisAd(),
    [pages.LPO_LINK]: DisplayItemsService.getLpoLinkAd(),
    [pages.LPO_PERIOD]: DisplayItemsService.getLpoPeriodAd(),
  },
  [constant.TAB_ALL]: {
    [pages.CATEGORY_ANALYZE]: DisplayItemsService.getCategoryAnalysisAll(),
    [pages.DETAILS_ANALYSIS]: DisplayItemsService.getDetailAnalysisAll(),
    [pages.COMPARE_PERIOD]: DisplayItemsService.getPeriodAnalysisAll(),
    [pages.CV_ATTRIBUTE]: DisplayItemsService.getCvAttributesAll(),
    [pages.CV_FLOW]: DisplayItemsService.getCvFlowAll(),
    [pages.LTV_ANALYZE]: DisplayItemsService.getLtvAnalysisAll(),
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsService.getLandingPageAnalysisAll(),
    [pages.LOG_PAGE_ANALYZE]: DisplayItemsService.getLogPageAnalysisAll(),
    [pages.LOG_PERIOD_ANALYZE]: DisplayItemsService.getLogPeriodAnalysisAll(),
    [pages.LOG_ROUTE_ANALYZE]: DisplayItemsService.getLogRouteAnalysisAll(),
  },
};

const displayDefault = {
  [constant.TAB_AD]: {
    [pages.CATEGORY_ANALYZE]: DisplayItemsService.getCategoryAnalysisAdDefault(),
    [pages.COST_ALLOCATION]: DisplayItemsService.getCostAllocationAnalysisAdDefault(),
    [pages.DETAILS_ANALYSIS]: DisplayItemsService.getDetailAnalysisAdDefault(),
    [pages.COMPARE_PERIOD]: DisplayItemsService.getPeriodAnalysisAdDefault(),
    [pages.CV_ATTRIBUTE]: DisplayItemsService.getCvAttributesAdDefault(),
    [pages.CV_FLOW]: DisplayItemsService.getCvFlowAdDefault(),
    [pages.LTV_ANALYZE]: DisplayItemsService.getLtvAnalysisAdDefault(),
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsService.getLandingPageAnalysisAdDefault(),
    [pages.LPO_LINK]: DisplayItemsService.getLpoLinkAdDefault(),
    [pages.LPO_PERIOD]: DisplayItemsService.getLpoPeriodAdDefault(),
  },
  [constant.TAB_ALL]: {
    [pages.CATEGORY_ANALYZE]: DisplayItemsService.getCategoryAnalysisAllDefault(),
    [pages.DETAILS_ANALYSIS]: DisplayItemsService.getDetailAnalysisAllDefault(),
    [pages.COMPARE_PERIOD]: DisplayItemsService.getPeriodAnalysisAllDefault(),
    [pages.CV_ATTRIBUTE]: DisplayItemsService.getCvAttributesAllDefault(),
    [pages.CV_FLOW]: DisplayItemsService.getCvFlowAllDefault(),
    [pages.LTV_ANALYZE]: DisplayItemsService.getLtvAnalysisAllDefault(),
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsService.getLandingPageAnalysisAllDefault(),
    [pages.LOG_PAGE_ANALYZE]: DisplayItemsService.getLogPageAnalysisAllDefault(),
    [pages.LOG_PERIOD_ANALYZE]: DisplayItemsService.getLogPeriodAnalysisAllDefault(),
    [pages.LOG_ROUTE_ANALYZE]: DisplayItemsService.getLogRouteAnalysisAllDefault(),
  },
};

const axis = {
  [constant.TAB_AD]: {
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsService.getLandingPageAnalysisAdAxis(),
  },
  [constant.TAB_ALL]: {
    [pages.LANDING_PAGE_ANALYZE]: DisplayItemsService.getLandingPageAnalysisAllAxis(),
    [pages.LOG_PAGE_ANALYZE]: DisplayItemsService.getLogPageAnalysisAllAxis(),
  },
};

const getSorting = (state) => state.settings.displayItems.sorting || [];
const getStatus = (state) => state.settings.displayItems.status;
const getDisplayItemFuncId = (state) => state.settings.displayItems.funcId;

const getAxis = (state) => {
  const { tab, page } = state.settings.report;

  return axis[tab]?.[page] || [];
};

const getSettings = (state) => {
  const { items } = state.settings.displayItems;
  const { landing_page_analyze: lpAnalyze } = state.settings.displayItems;
  const filters = filterSelectors.getSettings(state);
  const screenId = settingsSelectors.getPage(state);
  const { period } = state.commonState.CommonState.settings;
  const date = reflectionTimeSelectors.getReflectionTimeData(state);

  // Update display items if there are invalid filters.
  if (
    FILTER_KEY_CROSS_DEVICE in filters ||
    CROSSDEVICE_DIFF_COMPARISON in items
  ) {
    const warningFilter = isWarningFilter(
      FILTER_KEY_CROSS_DEVICE,
      filters,
      screenId,
      period,
      date.cross_device
    ).isWarning;
    if (warningFilter && CROSSDEVICE_DIFF_COMPARISON in items) {
      items.crossdevice_diff_comparison = false;
    }
    if (
      !(FILTER_KEY_CROSS_DEVICE in filters) &&
      items.crossdevice_diff_comparison
    ) {
      items.crossdevice_diff_comparison = false;
    }
    if (warningFilter && CONTACT_DEVICE in items) {
      items.contact_device = false;
    }
  }
  if (
    [
      pages.DETAILS_ANALYSIS,
      pages.LANDING_PAGE_ANALYZE,
      pages.CV_ATTRIBUTE,
    ].includes(screenId)
  ) {
    const displayItemsCompare =
      screenId === pages.LANDING_PAGE_ANALYZE ? items : lpAnalyze;
    const warningFilter = isWarningFilter(
      FILTER_KEY_SEARCH_CONSOLE,
      filters,
      screenId,
      period,
      date.cross_device,
      displayItemsCompare
    ).isWarning;
    if (warningFilter) {
      items.search_word = true;
    } else {
      items.search_word = false;
    }
  }

  return items;
};

const getDisplayItemPriorityAxis = createSelector(
  [
    getSettings,
    getPermissions,
    settingsSelectors.getTab,
    settingsSelectors.getPage,
  ],
  (settings, permissions, tab, pageId) => {
    if (resolve(MEDIA_SYNC_PERMISSIONS[tab], permissions)) {
      if (
        pageId === pages.AD_MANAGEMENT ||
        pageId === pages.AGENCY_MANAGEMENT
      ) {
        return PRIORITY_AXIS_BOTH;
      }
      return settings.media_sync
        ? PRIORITY_AXIS_MEDIA_SYNC
        : PRIORITY_AXIS_EBIS;
    }
    return PRIORITY_AXIS_EBIS;
  }
);

/**
 * <pre>
 *   {
 *     title: "string",
 *     group: "aggregation_axis", // defined in group_list attribute of each screen, or "item" by default
 *     disabled: boolean,
 *     dataType: boolean: string
 *     decimalPoint: number,
 *     displayFreeze: boolean,
 *     order: number,
 *     displayDefault: boolean,
 *     sortDisabled: boolean,
 *     sortDefault: "none",
 *     required: boolean,
 *   }
 * </pre>
 */
const getItems = createSelector(
  [
    settingsSelectors.getPage,
    settingsSelectors.getTab,
    masterDataSelectors.getDisplay,
    getDisplayItemPriorityAxis,
    getSorting,
  ],
  (page, tab, display, priorityAxis, sortItems) => {
    if (actions[tab] && actions[tab][page]) {
      const items = DisplayItemsService.mergeSettingLabels(
        actions[tab][page],
        display
      );
      return sortItems.reduce((acc, key, index) => {
        if (
          items[key]?.getGroup(priorityAxis) !== DISPLAY_GROUP_AGGREGATION_AXIS
        ) {
          return acc;
        }
        return { ...acc, [key]: { ...acc[key], order: index } };
      }, items);
    }
    return {};
  }
);

const getItemPermissions = createSelector(
  [
    getItems,
    getPermissions,
    settingsSelectors.getTab,
    settingsSelectors.getPage,
    filterSelectors.getSettings,
  ],
  (items, userPermissions, tab, page, filters) => {
    return Object.keys(items).reduce((acc, key) => {
      const permissionDef = get(items[key], `permissionSet.${tab}`);
      const permitted =
        !permissionDef || resolve(permissionDef, userPermissions);
      acc[key] = permitted;
      if (
        key === DisplayItems.SEARCH_WORD &&
        page === pages.LANDING_PAGE_ANALYZE &&
        !Object.keys(filters).includes(FILTER_KEY_SEARCH_CONSOLE)
      ) {
        acc[DisplayItems.SEARCH_WORD] = false;
      }
      return acc;
    }, {});
  }
);

const getUserPermittedItems = createSelector(
  [
    getItems,
    getItemPermissions,
    settingsSelectors.getTab,
    settingsSelectors.getPage,
  ],
  (items, itemPermissions) => {
    return Object.keys(items)
      .filter((key) => itemPermissions[key])
      .reduce((acc, key) => {
        acc[key] = items[key];
        return acc;
      }, {});
  }
);

// handle refactor for getDisplayDefault
const getDisplayDefault = createSelector(
  [(state) => state.settings.report],
  ({ tab, page }) => {
    if (displayDefault[tab] && displayDefault[tab][page]) {
      return displayDefault[tab][page];
    }
    return {};
  }
);

// handle refactor for getSettingDefault
const getSettingsDefault = createSelector([getDisplayDefault], (settings) => {
  const settingsDefault = {};
  Object.keys(settings).map((key) => {
    if (settings[key].displayDefault) {
      settingsDefault[key] = true;
    }
    return key;
  });
  return settingsDefault;
});

const hasContractMediaSync = createSelector(
  [getPermissions, settingsSelectors.getTab],
  (permissions, tab) => {
    return resolve(MEDIA_SYNC_PERMISSIONS[tab], permissions);
  }
);

const enabledPriorityAxis = createSelector(
  [getDisplayItemFuncId, hasContractMediaSync],
  (funcId, hasContract) => {
    return hasContract && ![FUNC_CODE_LOG_PAGE_ANALYZE].includes(funcId);
  }
);

const includeDisplayItem = (
  key,
  items,
  settings,
  itemPermissions,
  priorityAxis
) => {
  const display = key in settings && settings[key] && items[key];
  const defaultDisplay = !(key in settings) && items[key].displayDefault;
  const deny = items[key].getDeny(priorityAxis);
  const required = items[key].getRequired(priorityAxis);
  const permitted = itemPermissions[key];
  return (display || defaultDisplay || required) && permitted && !deny;
};

const getDisplaySettings = (
  funcId,
  items,
  settings,
  itemPermissions,
  priorityAxis = PRIORITY_AXIS_EBIS,
  isDimension = true
) => {
  const displayItems = {};
  const groupDimensions = DisplayItemsService.getGroupDimensionsByFuncId(
    funcId
  );

  Object.keys(items).forEach((key) => {
    if (isDimension === groupDimensions.includes(key) && items[key]) {
      if (
        includeDisplayItem(key, items, settings, itemPermissions, priorityAxis)
      ) {
        if (
          items[key].getGroup(priorityAxis) ===
          DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY
        ) {
          displayItems[items[key].order + 1000] = key;
        } else {
          displayItems[items[key].order] = key;
        }
      }
    }
  });
  const keys = Object.keys(displayItems)
    .sort((a, b) => a - b)
    .map((key) => {
      return displayItems[key];
    });
  return keys;
};

// Using getItems, getSettings
const getDimensionsSelector = createSelector(
  getDisplayItemFuncId,
  getItems,
  getSettings,
  getItemPermissions,
  getDisplayItemPriorityAxis,
  () => true,
  getDisplaySettings
);

// Using getSettings
const getMetricsSelector = createSelector(
  getDisplayItemFuncId,
  getItems,
  getSettings,
  getItemPermissions,
  getDisplayItemPriorityAxis,
  () => false,
  getDisplaySettings
);

const getItemsReport = createSelector(
  [getUserPermittedItems],
  (displayItems) => {
    return Object.entries(displayItems)
      .sort((a, b) => a[1].order - b[1].order)
      .map(([key, value]) => {
        return {
          ...value,
          title: value.titleTable || value.title,
          key,
        };
      });
  }
);

/**
 * Switch tab all(select channel only) -> tab ad
 * Use default display dimension
 */
const getSettingsFallback = createSelector(
  [
    getSettings,
    getItems,
    settingsSelectors.getPage,
    getDisplayItemPriorityAxis,
  ],
  (displayItems, items, pageId, priorityAxis) => {
    if (![CATEGORY_ANALYZE, COMPARE_PERIOD].includes(pageId)) {
      return null;
    }
    return DisplayItemsService.getSettingFallback({
      items,
      settings: displayItems,
      priorityAxis,
    });
  }
);

const getFullDimensionsSelector = createSelector(
  [getUserPermittedItems],
  (items) => {
    return Object.keys(items)
      .filter(
        (key) =>
          items[key].displayFreeze &&
          ![
            DisplayItems.TERMINAL_TYPE,
            DisplayItems.LANDING_PAGE_DOMAIN,
            DisplayItems.LANDING_PAGE_URL,
            DisplayItems.AD_NOTE,
            DisplayItems.AD_DATE,
          ].includes(key)
      )
      .sort((key1, key2) => items[key1].order - items[key2].order)
      .map((key) => {
        return key;
      });
  }
);

const getFullMetricsSelector = createSelector(
  [getUserPermittedItems],
  (items) => {
    return Object.keys(items)
      .filter(
        (key) =>
          !items[key].displayFreeze &&
          ![
            DisplayItems.OPTIONAL_AD_ID,
            DisplayItems.OPTIONAL_MEDIA_SIDE_AD_ID,
          ].includes(key)
      )
      .sort((key1, key2) => items[key1].order - items[key2].order)
      .map((key) => {
        return key;
      });
  }
);

export default {
  getItems,
  getUserPermittedItems,
  getItemPermissions,
  getItemsReport,
  getDisplayDefault,
  getSettingsDefault,
  getSettings,
  getDimensionsSelector,
  getMetricsSelector,
  getStatus,
  getSettingsFallback,
  getDisplayItemFuncId,
  getDisplayItemPriorityAxis,
  hasContractMediaSync,
  getAxis,
  getSorting,
  getFullDimensionsSelector,
  getFullMetricsSelector,
  enabledPriorityAxis,
};
