/**
 * Based on AUA 2020 Phase 1 docs
 * Updated date: 2020/12/10
 * Ref: https://docs.google.com/spreadsheets/d/1J0uwatOF7vKAB4K0WUCRV5_SCNQQSrsl/edit#gid=1196540595
 */

import { anyOf, none } from 'domain/permissions/permissionTypes';

import * as contractPermissions from 'domain/permissions/contractPermissions';

// TODO: remove anyOf/none, leave only pure definitions here
/**
 * LOG permissions group for reports
 */
export const LOG_CONTRACT_PERMISSIONS = {
  ad: anyOf([contractPermissions.AD_ANALYSIS_ITEM_LOG_VIEW]),
  all: anyOf([contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW]),
  view: anyOf([
    contractPermissions.AD_ANALYSIS_ITEM_LOG_VIEW,
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
  ]),
};

/**
 * LOG permissions group for settings
 */
export const LOG_CONTRACT_PERMISSIONS_FOR_SETTINGS = anyOf([
  contractPermissions.SETTING_TAG_CONTENT_CATEGORY_EDIT,
]);

/**
 * SEO contract permissions group for search console filter, search word dimensions
 */
export const SEO_CONTRACT_DISPLAY_SEARCH_CONSOLE = {
  ad: none(),
  all: anyOf([
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
  ]),
  view: anyOf([
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
  ]),
};

// TODO: remove anyOf/none, leave only pure definitions here
export const LOG_CONTRACT_DISPLAY_CHANNEL = {
  ad: none(),
  all: anyOf([contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW]),
  view: anyOf([contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW]),
};

export const LOG_CONTRACT_DISPLAY_CHANNEL_SITE_CONTENT = {
  ad: none(),
  all: anyOf([contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_SC_VIEW]),
  view: anyOf([contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_SC_VIEW]),
};

/**
 * SEO (SEARCH) permissions group
 */
export const SEO_CONTRACT_PERMISSIONS = {
  ad: [],
  all: [
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
    contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
  ],
};

/**
 * AGENCY permissions group
 */
export const AGENCY_CONTRACT_PERMISSIONS = anyOf([
  contractPermissions.SETTING_AGENCY_VIEW,
]);

/**
 * View all ad only permissions group
 */
export const VIEW_ALL_AD_ONLY_PERMISSIONS = anyOf([
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_AD_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_ITEM_AD_VIEW,
]);

export const MEDIA_SYNC_PERMISSIONS = {
  ad: anyOf([contractPermissions.MEDIA_SYNC_VIEW]),
  all: anyOf([contractPermissions.MEDIA_SYNC_VIEW]),
  view: anyOf([contractPermissions.MEDIA_SYNC_VIEW]),
};

export const CROSS_DEVICE_PERMISSIONS = {
  ad: anyOf([contractPermissions.CROSS_DEVICE_VIEW]),
  all: anyOf([contractPermissions.CROSS_DEVICE_VIEW]),
  view: anyOf([contractPermissions.CROSS_DEVICE_VIEW]),
};

// Permissions by channel
export const CHANNEL_VIEW_PERMISSIONS = anyOf([
  contractPermissions.DATA_EXPORT_CHNL_VIEW,
]);
export const CHANNEL_CLICK_PERMISSIONS = anyOf([
  contractPermissions.AD_ANALYSIS_ITEM_LOG_VIEW,
  contractPermissions.AD_ANALYSIS_ITEM_EXCLUDE_LOG_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_AD_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_ITEM_AD_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_SC_VIEW,
]);
export const CHANNEL_SEARCH_PERMISSIONS = anyOf([
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_AD_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_ITEM_AD_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_SC_VIEW,
]);
export const CHANNEL_DIRECT_PERMISSIONS = anyOf([
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_AD_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
]);
export const CHANNEL_REFERAL_PERMISSIONS = anyOf([
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_AD_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
]);
export const CHANNEL_SITECONTENT_PERMISSIONS = anyOf([
  contractPermissions.ALL_DASHBOARD_CHNL_CLK_SRCH_PV_ITEM_AD_VIEW,
  contractPermissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_SC_VIEW,
]);
export const LPO_PERMISSIONS = {
  ad: anyOf([contractPermissions.AD_ANALYSIS_LPO_VIEW]),
  all: none(),
};
export const COST_ALLOCATION_PERMISSIONS = {
  ad: anyOf([contractPermissions.AD_ANALYSIS_COST_ALLOCATION_VIEW]),
  view: anyOf([contractPermissions.AD_ANALYSIS_COST_ALLOCATION_VIEW]),
};
