import React, { useState, useCallback, useRef, createRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ViewNavBarConfig from 'domain/commonbar/ViewNavBarConfig';
import screenPermissionConfigs from 'domain/permissions/screenPermissionConfigs';
import { FUNC_CODE_INFLOW_ANALYSIS } from 'domain/settings/display-items';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { TAB_AD, TAB_ALL, TAB_COMMON } from 'services/consts';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import * as pathConstant from 'services/routes/constants';

import settingsForViewSelector from 'store/settings-for-view/selectors';

import ViewMenuListItem from 'views/molecules/viewNavBar/component/ViewMenuListItem';
import PermissionWrapperRPC from 'services/appconfig/PermissionWrapperRPC';

// config reserved for submenus
const ViewMenuList = ({ menuItems, config, handleChangePage, integrationTitle }) => {
  const tab = TAB_COMMON;
  const { t } = useTranslation();

  const screenId = useSelector(
    (state) => settingsForViewSelector.getPage(state),
    shallowEqual
  );

  const { itemsCommon } = ViewNavBarConfig.aside;
  const targetScreen = itemsCommon.find((item) => {
    return DisplayItemsService.getFuncIdByPageId(screenId) !== FUNC_CODE_INFLOW_ANALYSIS ? item.screenId === screenId : item.screenId === pathConstant.INFLOW_ANALYSIS;
  })

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="select"
        disabled={menuItems.length === 0}
      >
        {targetScreen.screenId !== pathConstant.INFLOW_ANALYSIS ? t(targetScreen.title) : integrationTitle}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {menuItems.map((item) => (
          <PermissionWrapperRPC
            key={item.screenId}
            requiredPermissions={screenPermissionConfigs[item.screenId][tab]}
            resolveType="anyOf"
            screenId={item.screenId}
            render={(isAllowed) => {
              const screenIds = [item.screenId];
              const isActive =
                screenIds.includes(screenId) ||
                (screenId === pathConstant.USER_PROFILE && item.screenId === pathConstant.CV_ATTRIBUTE);

              return (
                <ViewMenuListItem
                  key={item.title}
                  screenId={screenId}
                  item={item}
                  active={isActive}
                  isDisabled={!isAllowed}
                  handleChangePage={handleChangePage}
                  integrationTitle={integrationTitle}
                />
              );
            }}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ViewMenuList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  menuItems: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  integrationTitle: PropTypes.string,
};

ViewMenuList.defaultProps = {
  integrationTitle: '',
};

const ViewNavBar = ({ pageId, handleChangePage }) => {
  const { t } = useTranslation();
  const { checkPermissions } = useCheckPermissions();
  const { itemsCommon, self } = ViewNavBarConfig.aside || false;

  const messageTargets = useRef([]);
  const tabItems = [
    {
      tabKey: TAB_COMMON,
      menuItems: itemsCommon,
      className: "common",
    },
  ];
  tabItems.forEach((item) => {
    messageTargets.current[item.tabKey] = createRef();
  });

  const targetScreen = itemsCommon.filter((item) => {
    return DisplayItemsService.getFuncIdByPageId(item.screenId) !== FUNC_CODE_INFLOW_ANALYSIS || item.screenId === pathConstant.INFLOW_ANALYSIS;
  })
  const integrationTitle = itemsCommon.filter((item) =>
      item.screenId !== pathConstant.INFLOW_ANALYSIS
      && DisplayItemsService.getFuncIdByPageId(item.screenId) === FUNC_CODE_INFLOW_ANALYSIS
      && (checkPermissions('anyOf', screenPermissionConfigs[item.screenId][TAB_AD])
        || checkPermissions('anyOf', screenPermissionConfigs[item.screenId][TAB_ALL])))
    .map((item) => t(item.title))
    .reduce((acc, cur) => `${acc}・${cur}`);

  return (
  <div id="viewNavBar" className="view-menu-form__menu-wrapper">
    {targetScreen && (
      <ViewMenuList
        menuItems={targetScreen}
        config={self}
        tab={TAB_COMMON}
        handleChangePage={handleChangePage}
        integrationTitle={integrationTitle}
      />
    )}
    </div>
  );
};

ViewNavBar.propTypes = {
  pageId: PropTypes.string.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};

export default React.memo(ViewNavBar);
