import React, { useState, useRef } from 'react';
import { Dropdown, Col, Row, Card, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useClickOutside from 'services/custom-hooks/useClickOutside';

import SettingConfig, {
  LeftCard,
  RightCard,
  MEDIA_GROUP_MANAGEMENT,
} from 'domain/topbar/SettingConfig';

import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import ExternalTransitionWrapper from 'services/routes/ExternalTransitionWrapper';
import PermissionWrapperRPC from 'services/appconfig/PermissionWrapperRPC';

import IconMediaSync from 'assets/images/icon-media-sync.svg';

const CAPI_SETTINGS = 'capi_settings';

// Load the image of the "Screen icon"
const menuIconConfig = {
  media_sync_management: IconMediaSync,
};

const Settings = () => {
  const { t } = useTranslation();

  const { checkPermissions } = useCheckPermissions();

  const mergeSettingsConfig = (settingConfig) => {
    const config = { ...settingConfig };

    // Set config adMediaManagement
    const adMediaManagement = config.ad_settings.children.find(
      (item) => item.name === MEDIA_GROUP_MANAGEMENT
    );
    const { urlByPermission } = adMediaManagement;
    adMediaManagement.url = Object.keys(urlByPermission)
      .sort(
        (first, second) =>
          urlByPermission[first].priority - urlByPermission[second].priority
      )
      .reduce((acc, permission) => {
        if (acc === undefined && checkPermissions('anyOf', [permission])) {
          return urlByPermission[permission].url;
        }
        return acc;
      }, undefined);

    return config;
  };

  const filterSettingsConfig = (settingConfig) => {
    // Filter according to the contents of "rejectionPermissions".
    const filterRejectionPermission = (child) => {
      let disabled = false;
      if (
        child.rejectionPermissions &&
        checkPermissions('anyOf', child.rejectionPermissions)
      ) {
        disabled = true;
      }
      return !disabled;
    };

    const settingConfigKeys = Object.keys(settingConfig);
    for (let i = 0; i < settingConfigKeys.length; i += 1) {
      const { children } = settingConfig[settingConfigKeys[i]];
      // Reassign with "Rejection Config" removed.
      const obj = children.filter((child) => {
        return filterRejectionPermission(child);
      });
      settingConfig[settingConfigKeys[i]].children = obj;
    }

    return settingConfig;
  };

  const settingConfig = filterSettingsConfig(
    mergeSettingsConfig(SettingConfig)
  );
  const settingKeys = Object.keys(settingConfig);

  const childrenEnable = React.useMemo(() => {
    return settingKeys.reduce((acc, panel) => {
      const { children } = settingConfig[panel];
      acc[panel] = {};
      children.forEach((childItems) => {
        acc[panel][childItems.name] =
          checkPermissions('anyOf', childItems.permissions) || false;
      });

      return acc;
    }, {});
  }, [checkPermissions, settingConfig, settingKeys]);

  // Calculate if disable left panel when all right panel items disabled
  const settingsKeysEnable = React.useMemo(() => {
    return settingKeys.reduce((acc, panel) => {
      acc[panel] = Object.values(childrenEnable[panel]).some(
        (item) => item === true
      );

      return acc;
    }, {});
  }, [settingKeys, childrenEnable]);

  const defaultOpenTab = settingKeys.find(
    (item) => settingsKeysEnable[item] === true
  );

  const [currentTab, toggleTabOpen] = useState(defaultOpenTab);

  const resetTabOnClose = (isOpen) => {
    if (isOpen === false) toggleTabOpen(defaultOpenTab);
  };

  const [showSettingDropdown, toggleSettingDropdown] = useState(false);

  const handleDropdownToggle = (isOpen) => {
    resetTabOnClose(isOpen);
    toggleSettingDropdown(isOpen);
  };

  const clickRef = useRef();
  const onClickOutside = () => {
    if (showSettingDropdown) {
      toggleSettingDropdown(false);
    }
  };

  useClickOutside(clickRef, onClickOutside);

  return (
    <Nav
      className={`navbar-nav-custom ${
        defaultOpenTab === undefined ? 'disabled' : ''
      }`}
    >
      <Dropdown
        ref={clickRef}
        onToggle={(isOpen) => defaultOpenTab && handleDropdownToggle(isOpen)}
        show={showSettingDropdown}
        bsPrefix={`dropdown nav-item w-100 h-100 ${
          defaultOpenTab === undefined ? 'ebis--disabled' : ''
        }`}
      >
        <Dropdown.Toggle
          as="a"
          role="button"
          bsPrefix="nav-link view w-100 h-100"
        >
          {' '}
          {t('topbar.page_setting.toggle')}
        </Dropdown.Toggle>
        <Dropdown.Menu
          alignRight="true"
          variant="custom-view"
          className="nav-setting navbar--border-top-red"
        >
          <Dropdown.Header as="div" bsPrefix="dropdown-header">
            {t('topbar.page_setting.smart_menu_title')}
          </Dropdown.Header>
          <Row className="card-box">
            {[LeftCard, RightCard].map((card, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={`setting_card_${index}`}>
                <PermissionWrapperRPC
                  key={card.url}
                  requiredPermissions={card.permissions}
                  resolveType="anyOf"
                  render={(isAllowed) => (
                    <ExternalTransitionWrapper
                      link={card.url}
                      type={card.type}
                      newTab={card.newTab}
                      disabled={!isAllowed}
                    >
                      <Card
                        onClick={() => isAllowed && handleDropdownToggle(false)}
                        className={`${index === 1 ? 'float-right' : ''} ${
                          isAllowed ? '' : 'ebis--disabled'
                        }`}
                      >
                        <Card.Img
                          variant="top"
                          src={card.img}
                          className="card-img"
                        />
                        <Card.Body>
                          <Card.Text>{t(card.key)}</Card.Text>
                        </Card.Body>
                      </Card>
                    </ExternalTransitionWrapper>
                  )}
                />
              </Col>
            ))}
          </Row>
          <Dropdown.Header>
            {t('topbar.page_setting.detailed_menu_title')}
          </Dropdown.Header>
          <Row className="detailed-menu">
            <Col>
              {settingKeys.map((item) => {
                const cls = classNames({
                  'dropdown-item': true,
                  active: item === currentTab,
                  'ebis--disabled': !settingsKeysEnable[item],
                });

                const hiddenNoContract =
                  item === CAPI_SETTINGS &&
                  settingConfig[item].contracts.length > 0 &&
                  !checkPermissions('anyOf', settingConfig[item].contracts);

                if (hiddenNoContract) {
                  return <></>;
                }

                return (
                  <div
                    key={item}
                    className={cls}
                    onMouseEnter={() => toggleTabOpen(item)}
                  >
                    {t(settingConfig[item].key)}
                    {item === currentTab &&
                      settingConfig[currentTab].children.length !== 0 && (
                        <i className="fas fa-chevron-right detail-menu-icon" />
                      )}
                  </div>
                );
              })}
            </Col>
            <Col>
              {currentTab &&
                settingConfig[currentTab].children.map((item) => (
                  <PermissionWrapperRPC
                    key={item.key}
                    requiredPermissions={item.permissions}
                    resolveType="anyOf"
                    render={(isAllowed) => {
                      const { contracts = [] } = item;
                      const hiddenNoContract =
                        contracts.length > 0 &&
                        !checkPermissions('anyOf', contracts);
                      if (hiddenNoContract) {
                        return <></>;
                      }

                      return (
                        <ExternalTransitionWrapper
                          link={item.url}
                          type={item.type}
                          newTab={item.newTab}
                          disabled={!isAllowed}
                        >
                          <Dropdown.Item
                            as="div"
                            className={`dropdown-item dropdown-item__children ${
                              isAllowed ? '' : 'ebis--disabled'
                            }`}
                            disabled={!isAllowed}
                          >
                            {t(item.key)}
                            {item.icon && (
                              <img
                                src={menuIconConfig[item.name]}
                                alt="Screen Icon"
                                className="menu-icon"
                              />
                            )}
                          </Dropdown.Item>
                        </ExternalTransitionWrapper>
                      );
                    }}
                  />
                ))}
            </Col>
          </Row>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  );
};

export default React.memo(Settings);
