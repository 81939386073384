const CREATE = 'ad-management-adgroup2/CREATE';

const SET_DATA_DETAIL = 'ad-management-adgroup2/SET_DATA_DETAIL';
const SET_ERROR_DETAIL = 'ad-management-adgroup2/SET_ERROR_DETAIL';
const SET_STATUS_DETAIL = 'ad-management-adgroup2/SET_STATUS_DETAIL';

export default {
  CREATE,
  SET_DATA_DETAIL,
  SET_ERROR_DETAIL,
  SET_STATUS_DETAIL,
};
