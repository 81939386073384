import { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';

// Generic hook for detecting scroll:
const useScrollAware = (ref) => {
  const [scrollTop, setScrollTop] = useState(0);
  const animationFrame = useRef();

  const onScroll = useCallback(() => {
    if (animationFrame.current) {
      cancelAnimationFrame(animationFrame.current);
    }
    animationFrame.current = requestAnimationFrame(() => {
      const el = ref?.current || document.documentElement;
      setScrollTop(el.scrollTop || 0);
    });
  }, [ref]);

  useEffect(() => {
    const el = ref?.current || window;
    el.addEventListener('scroll', throttle(onScroll, 16));
    return () => el.removeEventListener('scroll', onScroll);
  }, [onScroll, ref]);

  return scrollTop;
};

const findStartNode = (scrollTop, itemCount, minHeight) => {
  let startRange = 0;
  let endRange = itemCount - 1;
  while (endRange !== startRange) {
    const middle = Math.floor((endRange - startRange) / 2 + startRange);
    const start = middle * minHeight;
    const end = (middle + 1) * minHeight;

    if (start <= scrollTop && end > scrollTop) {
      return middle;
    }

    if (middle === startRange) {
      // edge case - start and end range are consecutive
      return endRange;
    }
    if (start <= scrollTop) {
      startRange = middle;
    } else {
      endRange = middle;
    }
  }
  return itemCount;
};

const findEndNode = (startNode, itemCount, height, minHeight) => {
  let endNode;
  const start = startNode * minHeight + height;
  for (endNode = startNode; endNode < itemCount; endNode += 1) {
    const end = endNode * minHeight;
    if (end > start) return endNode;
  }
  return endNode;
};

const useVirtualScroller = ({
  ref,
  itemCount,
  renderAhead,
  minHeight,
  isAllItemsRendered = false,
}) => {
  let offsetHeight = window.screen.height;
  if (ref?.current) {
    offsetHeight = ref.current.offsetHeight;
  }
  const scrollTop = useScrollAware(ref);
  let totalHeight = itemCount * minHeight;

  const firstVisibleNode = useMemo(() => {
    if (itemCount === 0) return 0;
    return findStartNode(scrollTop, itemCount, minHeight);
  }, [scrollTop, itemCount, minHeight]);
  let startNode = Math.max(0, firstVisibleNode - renderAhead);

  const lastVisibleNode = useMemo(() => {
    return findEndNode(firstVisibleNode, itemCount, offsetHeight, minHeight);
  }, [firstVisibleNode, itemCount, offsetHeight, minHeight]);
  const endNode = Math.min(itemCount - 1, lastVisibleNode + renderAhead);

  const visibleNodeCount = endNode - startNode + 1;
  let offsetY = startNode * minHeight;

  if (isAllItemsRendered) {
    totalHeight = null;
    offsetY = 0;
    startNode = 0;
  }

  return { startNode, visibleNodeCount, offsetY, totalHeight };
};

export default useVirtualScroller;
