import { MASTER_DATA_FILTER_LIMIT } from 'domain/consts';
import { buildSearchMasterDataBody } from 'domain/utils';
import { AD_MANAGEMENT_API_ENDPOINT } from 'services/api/AdManagementApi';
import { SettingsApi as Api } from 'services/api/Api';

const AdGroup1ServiceFactory = () => {
  const url = `${AD_MANAGEMENT_API_ENDPOINT}/adgroup1`;
  const baseUrl = process.env.REACT_APP_SETTINGS_API_HOST;
  const urlCsv = `${url}/csv`;

  const create = async (adGroupName, agencyId) => {
    const request = {
      name: adGroupName,
      agency_id: agencyId,
    };
    const response = await Api.post(url, request);
    const { id, name, order } = response.data.data.detail[0];
    return {
      id: `${id}`,
      name,
      order,
    };
  };

  /**
   * Search ad group 1
   * @param searchTerm
   * @param selected
   * @param offset
   * @param limit
   * @return {Promise<[]>}
   */
  const search = async (
    searchTerm = '',
    selected = [],
    offset = 0,
    limit = MASTER_DATA_FILTER_LIMIT + 1, // Plus 1 item to display message: 件数が多いため、500件まで表示しています。検索機能をご活用ください。
    agencyId = ''
  ) => {
    const params = buildSearchMasterDataBody(
      searchTerm,
      selected,
      offset,
      limit,
      agencyId
    );
    const response = await Api.post(`${url}/list`, params);

    const {
      data,
      data: items,
      metadata: { count: total },
      selected: selectedItems,
    } = response?.data;

    return { data, items, total, selected: selectedItems };
  };

  return {
    url,
    urlCsv,
    baseUrl,
    create,
    search,
  };
};

const adGroup1Service = AdGroup1ServiceFactory();

export default adGroup1Service;
