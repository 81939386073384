import types from 'store/notifications/types';
import notificationsSelectors from 'store/notifications/selectors';

const initialState = {
  control: {
    notRead: [],
    showNewsModal: false,
    status: false,
  },
  data: {
    viewList: [],
    newsModal: {},
  },
  communications: {
    start: false,
    fetching: false,
    success: false,
    error: false,
    errorMess: [],
  },
};

const NotificationsReducer = (state = initialState, action) => {
  // merge with preloaded state
  if (state && state.control.notRead === undefined) {
    // eslint-disable-next-line no-param-reassign
    state = {
      ...initialState,
      control: {
        notRead: initialState.control.notRead,
        showNewsModal: initialState.control.showNewsModal,
        ...state.control,
      },
    };
  }
  switch (action.type) {
    case types.FETCH_NOTIFICATIONS_START:
      return {
        ...state,
        communications: {
          ...state.communications,
          start: true,
          fetching: true,
          error: false,
          success: false,
        },
      };
    case types.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        control: {
          ...state.control,
          notRead: action.payload.isNotReadList,
        },
        communications: {
          ...state.communications,
          start: false,
          fetching: false,
          error: false,
          success: true,
        },
        data: {
          ...state.data,
          viewList: action.payload.data,
          newsModal: action.payload.newsModal,
        },
      };
    case types.FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        communications: {
          ...state.communications,
          start: false,
          fetching: false,
          error: true,
          success: false,
          errorMess: [...state.communications.errorMess, action.payload],
        },
      };
    case types.UPDATE_NOTIFICATION_READ:
      return {
        ...state,
        control: {
          ...state.control,
          notRead: state.control.notRead.filter(
            (id) => id !== action.payload.id
          ),
        },
      };
    case types.TOGGLE_NEWS_MODAL:
      return {
        ...state,
        control: {
          ...state.control,
          showNewsModal: !state.control.showNewsModal,
        },
      };
    case types.ADD_NOTIFICATION_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          newsModal: notificationsSelectors.getTargetNews(
            state.data.viewList,
            action.payload.id
          ),
        },
        control: {
          ...state.control,
          showNewsModal: true,
        },
      };
    case types.CHANGE_NEWS_MODAL_STATUS: {
      return {
        ...state,
        control: {
          ...state.control,
          status: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default NotificationsReducer;
