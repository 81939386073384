import axios from 'axios';
import { LtvApi as Api } from 'services/api/Api';
import { LTV_USER_ENDPOINT } from 'services/routes/constants';

const url = `${LTV_USER_ENDPOINT}/orders`;
const baseURL = process.env.REACT_APP_LTV_API_HOST;

const fetchAll = async (data) =>
  Api.get(url, {
    baseURL,
    params: data,
  });

const fetchFileUrl = async (data) =>
  Api.get(`${url}/${data}`, {
    baseURL,
  });

const getPresignedUrl = async () => Api.post(`${url}/csv`, {}, { baseURL });

const upload = async (uploadPath, file) => {
  await axios.put(uploadPath, file, {
    headers: { 'Content-Type': 'text/csv' },
  });
};

const doImport = async (file) =>
  Api.requestAsync(`${url}/validation`, { file }, 'POST', {
    pollTokenKey: 'token',
    pollUrlFormat: `${url}/validation/{token}`,
  });

export default {
  fetchAll,
  fetchFileUrl,
  getPresignedUrl,
  upload,
  doImport,
};
