import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes, { string, number, func, bool } from 'prop-types';
import { LINK_URL } from 'domain/settings/display-items-ad-management';

function ValuesExpandCellTable(props) {
  const {
    values,
    metric,
    rowNumber,
    handleResizeColumn,
    displayDefaultCount,
    isExpand,
    onExpand,
    cellFomatter,
    isLpoFlag,
  } = props;
  if (!values) {
    return <></>;
  }

  return (
    <>
      <div id={`collapse-${metric}-${rowNumber}`}>
        {isLpoFlag ? (
          <>
            {values.map((value, index) => {
              return (
                <>
                  {displayDefaultCount >= index + 1 ? (
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {metric === LINK_URL ? (
                        <a
                          href={value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {cellFomatter(value)}
                        </a>
                      ) : (
                        <span className="d-inline-block">
                          {cellFomatter(value)}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                      {metric === LINK_URL ? (
                        <a
                          hidden={!isExpand}
                          href={value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {cellFomatter(value)}
                        </a>
                      ) : (
                        <span hidden={!isExpand}>{cellFomatter(value)}</span>
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </>
        ) : (
          <>
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {metric === LINK_URL ? (
                <a href={values} target="_blank" rel="noopener noreferrer">
                  {cellFomatter(values)}
                </a>
              ) : (
                <span className="d-inline-block">{cellFomatter(values)}</span>
              )}
            </div>
          </>
        )}
      </div>
      {isLpoFlag && values.length > displayDefaultCount && metric === LINK_URL && (
        <Button
          size="xs"
          variant="link"
          className="p-0 border-0"
          onClick={(e) => {
            e.stopPropagation();
            onExpand(!isExpand);
            handleResizeColumn(metric);
          }}
        >
          {isExpand ? '省略して表示' : 'すべて表示'}
        </Button>
      )}
    </>
  );
}

ValuesExpandCellTable.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array]),
  metric: string.isRequired,
  rowNumber: number.isRequired,
  handleResizeColumn: func.isRequired,
  displayDefaultCount: number,
  isExpand: bool,
  onExpand: func,
  cellFomatter: func.isRequired,
  isLpoFlag: bool.isRequired,
};

ValuesExpandCellTable.defaultProps = {
  values: [],
  displayDefaultCount: 5,
  isExpand: false,
  onExpand: () => {},
};

export default ValuesExpandCellTable;
