import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import {
  oneOfType,
  bool,
  node,
  string,
  arrayOf,
  shape,
  func,
} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import FormGroup from 'views/molecules/FormGroup';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import NumericInput from 'views/atoms/numeric-input/NumericInput';
import { BASE_CPCONV } from 'domain/fields';

function FormGroupRadio(props) {
  const {
    required,
    label,
    note,
    tooltip,
    name,
    value,
    items,
    onChange,
    isLpo,
  } = props;

  const ref = useRef();

  const handleSelect = (e) => {
    const { value: newValue } = e.target;
    onChange(name, newValue);
  };

  const handleChange = (field, e) => {
    const { value: newValue } = e.target;
    onChange(field, newValue);
  };

  return (
    <FormGroup required={required} label={label} note={note} tooltip={tooltip}>
      {items.map((item, index) => {
        const checked = item.value === value;
        if (isLpo && item.value === BASE_CPCONV) {
          return <></>;
        }

        return (
          <Form.Check
            inline
            type="radio"
            style={{ marginLeft: index > 0 ? '30px' : 'initial' }}
          >
            <Form.Check.Label
              className="d-flex align-items-center"
              for={`chk-${item.value}`}
            >
              <Form.Check.Input
                inline
                type="radio"
                id={`chk-${item.value}`}
                name={name}
                value={item.value}
                checked={checked}
                disabled={item.disabled}
                onChange={handleSelect}
              />
              <span className="form-check-label">{item.label}</span>
            </Form.Check.Label>
            {item.input && (
              <div
                className={`form-check-type-input ${checked ? '' : 'disabled'}`}
              >
                <span>¥</span>
                <ErrorTooltipWrapper
                  isError={!isEmpty(item.input.error)}
                  errorMess={item.input.error}
                  ref={ref}
                >
                  <NumericInput
                    size="sm"
                    style={{ width: 100 }}
                    beginNumber="1-9"
                    className={`${isEmpty(item.input.error) ? '' : 'error'}`}
                    disabled={!checked}
                    name={item.input.name}
                    value={item.input.value}
                    onChange={(e) => handleChange(item.input.name, e)}
                  />
                </ErrorTooltipWrapper>
              </div>
            )}
          </Form.Check>
        );
      })}
    </FormGroup>
  );
}

FormGroupRadio.propTypes = {
  required: bool,
  label: string,
  note: string,
  tooltip: oneOfType([string, node]),
  name: string,
  value: string,
  items: arrayOf(shape({ label: string, value: string })),
  onChange: func,
  isLpo: bool,
};

FormGroupRadio.defaultProps = {
  required: false,
  label: '',
  note: '',
  tooltip: '',
  name: '',
  value: '',
  items: [],
  onChange: () => {},
  isLpo: false,
};

export default FormGroupRadio;
