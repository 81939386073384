const UPDATE_AXIS = 'categoryAnalysis/UPDATE_AXIS';
const UPDATE_BOOKMARK = 'categoryAnalysis/UPDATE_BOOKMARK';
const SET_SHOW_CHART = 'categoryAnalysis/SET_SHOW_CHART';
const UPDATE_MEDIAN = 'categoryAnalysis/UPDATE_MEDIAN';
const UPDATE_CATEGORIES = 'categoryAnalysis/UPDATE_CATEGORIES';
const GET_BOOKMARK = 'categoryAnalysis/GET_BOOKMARK';
const GET_BOOKMARK_ERROR = 'categoryAnalysis/GET_BOOKMARK_ERROR';
const DELETE_BOOKMARK = 'categoryAnalysis/DELETE_BOOKMARK';
const DELETE_BOOKMARK_ERROR = 'categoryAnalysis/DELETE_BOOKMARK_ERROR';
const SET_TABLE_CURRENT_PAGE = 'categoryAnalysis/SET_TABLE_CURRENT_PAGE';
const UPDATE_SORT = 'categoryAnalysis/UPDATE_SORT';
const SELECT_ROW = 'categoryAnalysis/SELECT_ROW';
const DESELECT_ROW = 'categoryAnalysis/DESELECT_ROW';
const SET_VISIBLE = 'categoryAnalysis/SET_VISIBLE';
const UPDATE_VISIBLE = 'categoryAnalysis/UPDATE_VISIBLE';
const APPLY_FILTER = 'categoryAnalysis/APPLY_FILTER';

// New types
const GET_REPORT = 'categoryAnalysis/GET_REPORT';
const GET_CHART_REPORT = 'categoryAnalysis/GET_CHART_REPORT';
const GET_COMPARED_CHART_REPORT = 'categoryAnalysis/GET_COMPARED_CHART_REPORT';
const GET_TABLE_REPORT = 'categoryAnalysis/GET_TABLE_REPORT';
const GET_COMPARED_TABLE_REPORT = 'categoryAnalysis/GET_COMPARED_TABLE_REPORT';

const UPDATE_CHART_DATA = 'categoryAnalysis/UPDATE_CHART_DATA';
const UPDATE_COMPARED_CHART_DATA =
  'categoryAnalysis/UPDATE_COMPARED_CHART_DATA';
const UPDATE_TABLE_DATA = 'categoryAnalysis/UPDATE_TABLE_DATA';
const UPDATE_COMPARED_TABLE_DATA =
  'categoryAnalysis/UPDATE_COMPARED_TABLE_DATA';

const APPLY_BOOKMARK = 'categoryAnalysis/APPLY_BOOKMARK';

const CLEAN_STATES = 'categoryAnalysis/CLEAN_STATES';
const CLEAN_TABLE = 'categoryAnalysis/CLEAN_TABLE';

const SET_TABLE_LOADING = 'categoryAnalysis/SET_TABLE_LOADING';
const SET_CHART_LOADING = 'categoryAnalysis/SET_CHART_LOADING';

const SET_TABLE_STATUS = 'categoryAnalysis/SET_TABLE_STATUS';
const SET_CHART_STATUS = 'categoryAnalysis/SET_CHART_STATUS';

const LOG_ERROR = 'categoryAnalysis/LOG_ERROR';

const REFRESH_TABLE_DATA = 'categoryAnalysis/REFRESH_TABLE_DATA';

export default {
  UPDATE_AXIS,
  UPDATE_BOOKMARK,
  GET_BOOKMARK,
  GET_BOOKMARK_ERROR,
  DELETE_BOOKMARK,
  DELETE_BOOKMARK_ERROR,
  SET_SHOW_CHART,
  UPDATE_MEDIAN,
  UPDATE_CATEGORIES,
  SET_TABLE_CURRENT_PAGE,
  UPDATE_SORT,
  SELECT_ROW,
  DESELECT_ROW,
  SET_VISIBLE,
  UPDATE_VISIBLE,
  APPLY_FILTER,

  // NEW TYPES
  GET_REPORT,
  GET_CHART_REPORT,
  GET_COMPARED_CHART_REPORT,
  GET_TABLE_REPORT,
  GET_COMPARED_TABLE_REPORT,

  UPDATE_CHART_DATA,
  UPDATE_COMPARED_CHART_DATA,
  UPDATE_TABLE_DATA,
  UPDATE_COMPARED_TABLE_DATA,
  APPLY_BOOKMARK,

  CLEAN_STATES,
  CLEAN_TABLE,
  SET_TABLE_LOADING,
  SET_CHART_LOADING,
  LOG_ERROR,
  REFRESH_TABLE_DATA,

  SET_TABLE_STATUS,
  SET_CHART_STATUS,
};
