import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { USER_MANAGEMENT } from 'services/routes/constants';
import { TABLE_ROWS_PER_PAGE } from 'domain/user-management/consts';
import apiUtils from 'services/apiUtils';

const UserManagementApiFactory = () => {
  const url = pages[USER_MANAGEMENT].endpoint;

  return {
    fetchAll: async ({ offset = 0, limit = TABLE_ROWS_PER_PAGE }) =>
      Api.get(apiUtils.buildUrlWithQueryStrings(url, { limit, offset })),
    create: async (data) => Api.post(url, data),
    update: async ({ targetId, apiItem }) =>
      Api.put(`${url}/${targetId}/`, apiItem),
    delete: async (id) => Api.delete(`${url}/${id}/`),
  };
};

const UserManagementApi = UserManagementApiFactory();
export default UserManagementApi;
