import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { arrayOf, string, bool, oneOfType, node, func } from 'prop-types';
import ListItemsCard from 'views/atoms/card/ListItemsCard';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';

import './delete-confirm-modal.scss';

function DeleteConfirmModal(props) {
  const {
    isShow,
    isLoading,
    title,
    content,
    items,
    size,
    forceText,
    confirmText,
    onHide,
    onSubmit,
    subContent,
    subItems,
    subItemsNotDelete,
    subItemsLineAddFriendNotDelete,
    contentItemNotDelete,
    hideText,
    isShowButtonSubmit,
  } = props;

  const [isForceDelete, setForceDelete] = useState(!forceText);
  const [isSubmit, setSubmit] = useState(false);

  const handleSubmit = () => {
    setSubmit(true);
    onSubmit();
  };
  const modalClass = classNames({
    'modal--delete-confirm': true,
    'modal--delete-confirm__loading': isLoading,
  });

  return (
    <Modal show={isShow} centered size={size} className={modalClass}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={isLoading}>
          {content && <>{content}</>}
          {items.length > 0 && <ListItemsCard items={items} />}
          {subContent && <>{subContent}</>}
          {subItems.length > 0 && <ListItemsCard items={subItems} />}
          {contentItemNotDelete && <>{contentItemNotDelete}</>}
          {subItemsNotDelete.length > 0 && (
            <ListItemsCard items={subItemsNotDelete} />
          )}
          {subItemsLineAddFriendNotDelete.length > 0 && (
            <ListItemsCard items={subItemsLineAddFriendNotDelete} />
          )}
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        {forceText && (
          <EbisCheckbox
            name="force-delete"
            text={forceText}
            checked={isForceDelete}
            disabled={isLoading}
            changeSelectBoxValue={() => setForceDelete(!isForceDelete)}
          />
        )}
        {hideText && (
          <Button
            size="sm"
            variant="link"
            disabled={isLoading && isSubmit}
            onClick={onHide}
          >
            {hideText}
          </Button>
        )}
        {isShowButtonSubmit && (
          <Button
            size="sm"
            variant="secondary"
            disabled={isLoading || !isForceDelete}
            onClick={handleSubmit}
          >
            {confirmText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

DeleteConfirmModal.propTypes = {
  isShow: bool.isRequired,
  isLoading: bool,
  title: string.isRequired,
  items: arrayOf([]),
  content: oneOfType([string, node]),
  forceText: string,
  size: string,
  confirmText: string,
  onHide: func,
  onSubmit: func,
  subContent: oneOfType([string, node]),
  subItems: arrayOf([]),
  subItemsNotDelete: arrayOf([]),
  subItemsLineAddFriendNotDelete: arrayOf([]),
  contentItemNotDelete: oneOfType([string, node]),
  hideText: string,
  isShowButtonSubmit: bool,
};

DeleteConfirmModal.defaultProps = {
  isLoading: false,
  content: null,
  items: [],
  subContent: null,
  contentItemNotDelete: null,
  size: 'w600',
  subItems: [],
  subItemsNotDelete: [],
  subItemsLineAddFriendNotDelete: [],
  forceText: null,
  isShowButtonSubmit: true,
  confirmText: '削除',
  hideText: 'キャンセル',
  onHide: () => {},
  onSubmit: () => {},
};

export default DeleteConfirmModal;
