import { bool, func, string } from 'prop-types';
import { Button } from 'react-bootstrap';
import React from 'react';
import './belowPanel.scss';

function BelowPanel(props) {
  const {
    okText,
    cancelText,
    backText,
    onOkClick,
    onCancelClick,
    onBackClick,
    okDisabled,
    showBack,
    variant,
    disabledApply,
    onApply,
    customView,
    isHideOkBtn,
  } = props;
  return (
    <div className="below-panel">
      {showBack ? (
        <Button onClick={onBackClick} variant="link" size="sm">
          {backText}
        </Button>
      ) : null}
      <Button size="sm" variant="link" onClick={onCancelClick}>
        {cancelText}
      </Button>
      {!isHideOkBtn && (
        <Button
          disabled={okDisabled}
          onClick={onOkClick}
          variant={variant}
          size="sm"
        >
          {okText}
        </Button>
      )}
      {!customView && (
        <Button
          disabled={disabledApply}
          onClick={onApply}
          variant="secondary"
          size="sm"
        >
          適用
        </Button>
      )}
    </div>
  );
}

BelowPanel.propTypes = {
  okText: string,
  backText: string,
  cancelText: string,
  onOkClick: func,
  onCancelClick: func,
  onBackClick: func,
  okDisabled: bool,
  showBack: bool,
  variant: string,
  disabledApply: bool,
  onApply: func,
  customView: bool,
  isHideOkBtn: bool,
};

BelowPanel.defaultProps = {
  okText: 'OK',
  cancelText: 'キャンセル',
  backText: '戻る',
  onOkClick: () => {},
  onCancelClick: () => {},
  onBackClick: () => {},
  okDisabled: false,
  showBack: false,
  isHideOkBtn: false,
  variant: 'secondary',
  disabledApply: false,
  customView: false,
  onApply: () => {},
};

export default BelowPanel;
