import { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';

// Generic hook for detecting scroll:
const useScrollAware = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const animationFrame = useRef();

  const onScroll = useCallback(() => {
    if (animationFrame.current) {
      cancelAnimationFrame(animationFrame.current);
    }
    animationFrame.current = requestAnimationFrame(() => {
      setScrollTop(
        document.body.scrollTop || document.documentElement.scrollTop
      );
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', throttle(onScroll, 100));
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return scrollTop;
};

const findStartNode = (
  scrollTop,
  nodePositions,
  itemCount,
  actualPositions
) => {
  let startRange = 0;
  let endRange = itemCount - 1;
  while (endRange !== startRange) {
    // console.log(startRange, endRange);
    const middle = Math.floor((endRange - startRange) / 2 + startRange);

    const start = actualPositions[middle] ?? nodePositions[middle];
    const end = actualPositions[middle + 1] ?? nodePositions[middle + 1];
    if (start <= scrollTop && end > scrollTop) {
      // console.log("middle", middle);
      return middle;
    }

    if (middle === startRange) {
      // edge case - start and end range are consecutive
      // console.log("endRange", endRange);
      return endRange;
    }
    if (start <= scrollTop) {
      startRange = middle;
    } else {
      endRange = middle;
    }
  }
  return itemCount;
};

const findEndNode = (
  nodePositions,
  startNode,
  itemCount,
  height,
  actualPositions
) => {
  let endNode;
  for (endNode = startNode; endNode < itemCount; endNode += 1) {
    const satrt = actualPositions[startNode] || nodePositions[startNode];
    const end = actualPositions[endNode] || nodePositions[endNode];
    // console.log(nodePositions[endNode], nodePositions[startNode]);
    if (end > satrt + height) {
      // console.log(endNode);
      return endNode;
    }
  }
  return endNode;
};

const useVirtualScroll = ({
  itemCount,
  isAllItemsRendered,
  columnsWidth,
  renderAhead = 10,
  minHeight = 38,
  scrollHeight = 9,
}) => {
  const { height } = window.screen;
  const [actualPositions, setActualPositions] = useState([0]);
  const childPositions = useMemo(() => {
    const results = [0];
    for (let i = 1; i < itemCount; i += 1) {
      results.push(results[i - 1] + minHeight);
    }
    return results;
  }, [minHeight, itemCount]);

  const scrollTop = useScrollAware();
  let totalHeight = childPositions[itemCount - 1] + minHeight;

  const [heightTotal, setHeightTotal] = useState(totalHeight);

  const firstVisibleNode = useMemo(() => {
    if (itemCount === 0) return 0;
    return findStartNode(scrollTop, childPositions, itemCount, actualPositions);
  }, [scrollTop, childPositions, itemCount, actualPositions]);

  let startNode = Math.max(0, firstVisibleNode - renderAhead);

  const lastVisibleNode = useMemo(
    () =>
      findEndNode(
        childPositions,
        firstVisibleNode,
        itemCount,
        height,
        actualPositions
      ),
    [childPositions, firstVisibleNode, itemCount, height, actualPositions]
  );
  const endNode = Math.min(itemCount - 1, lastVisibleNode + renderAhead);
  let visibleNodeCount = endNode - startNode + 1;

  const refScroll = useRef();

  useEffect(() => {
    const viewportEL = document.querySelector(
      '.scrollable:not(.d-none) .viewport'
    );
    if (!viewportEL) return;

    const tbodyEl = viewportEL.querySelector('tbody');
    const positions = [...actualPositions];

    for (let i = 0; i < itemCount; i += 1) {
      if (startNode + i >= itemCount - 1) {
        break;
      }
      positions[startNode + i + 1] =
        (positions[startNode + i] ?? childPositions[startNode + i]) +
        (tbodyEl.childNodes[i]?.offsetHeight ?? minHeight);
    }
    setActualPositions(positions);

    if (lastVisibleNode === endNode + 1) {
      const bottomTbody = tbodyEl.getBoundingClientRect().bottom;
      const bottomViewport = viewportEL.getBoundingClientRect().bottom;
      setHeightTotal(
        heightTotal + scrollHeight + (bottomTbody - bottomViewport)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startNode, endNode, columnsWidth]);

  const heightTotalAcual = actualPositions[itemCount - 1];
  useEffect(() => {
    setHeightTotal(
      heightTotalAcual > heightTotal ? heightTotalAcual : heightTotal
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heightTotalAcual]);

  let offsetY = actualPositions[startNode] ?? childPositions[startNode];
  totalHeight = heightTotal;

  if (isAllItemsRendered) {
    totalHeight = 'auto';
    offsetY = 0;
    startNode = 0;
    visibleNodeCount = itemCount;
  }

  return { startNode, visibleNodeCount, offsetY, totalHeight, refScroll };
};

export default useVirtualScroll;
