import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { element, string } from 'prop-types';

const TooltipWrapper = (props) => {
  const { children, tooltip, placement } = props;

  return (
    <OverlayTrigger overlay={tooltip} placement={placement}>
      <div className="d-inline-block">{children}</div>
    </OverlayTrigger>
  );
};

TooltipWrapper.propTypes = {
  children: element.isRequired,
  tooltip: element.isRequired,
  placement: string,
};

TooltipWrapper.defaultProps = {
  placement: 'top',
};

export default TooltipWrapper;
