import React from 'react';
import './LtvDummyMainContent.scss';
import lpLtvImage01 from 'assets/images/dummy/lp_ltv_image01.jpg';
import lpLtvImage02 from 'assets/images/dummy/lp_ltv_image02.png';
import lpLtvImage03 from 'assets/images/dummy/lp_ltv_image03.png';
import lpLtvImage04 from 'assets/images/dummy/lp_ltv_photo_taoka.jpg';

const LtvDummyMainContent = () => {
  return (
    <div className="lp_ltv_wrap">
      <div className="lp_ltv">
        <div className="lp_ltv_main">
          <div className="lp_ltv_main_hero">
            <img
              src={lpLtvImage01}
              alt="アドエビス上で未来のLTVを予測、D2C事業の効果的な広告投資判断を支援　未来の利益が、今わかる。LTV Forecast powered by AD EBiS"
              width="680"
              height="353"
            />
          </div>
          <div className="lp_ltv_hook">
            <strong>オンライン説明可能</strong>
            <span>
              「Zoom」や「Google
              Meet」など、オンライン通話で詳しくご説明させていただきます。
            </span>
          </div>
          <h3>LTVForecastの資料ダウンロードはこちらから！</h3>
          <h4 className="lp_ltv_main_title">
            最短1ヶ月で今後のLTVを予測し、広告投資判断が可能
          </h4>
          <p className="lp_ltv_main_text">
            従来1～3年かかっていたLTVの実測を独自ロジックで「予測」。最短１ヶ月でLTVの予測し広告投資判断が行えます。目標の投資回収期間に対して、どれくらいで着地するか差分を確認することもできます。
            <br />
            これにより、スピーディーな広告予算の再配分が行え、利益の最大化を支援します。
          </p>
          <div className="lp_ltv_main_image">
            <img
              src={lpLtvImage02}
              alt="利益を基に算出されたLTV（予測）がひと目でわかる！"
              width="680"
              height="275"
            />

            <h4 className="lp_ltv_main_title">3STEPで簡単分析</h4>
            <p className="lp_ltv_main_text">
              受注データのアップロード／広告費や商品原価など変動費の登録／環境設定の3STEPで、LTV分析に必要なデータの統合・集計・可視化を代替します。
            </p>
            <div className="lp_ltv_main_image">
              <img
                src={lpLtvImage03}
                alt="受注データのアップロード／広告費や商品原価など変動費の登録／環境設定"
                width="680"
                height="183"
              />
            </div>

            <h4 className="lp_ltv_main_title">
              共同開発パートナーにD2Cマーケティングの先導者 田岡 敬氏が参画
            </h4>
            <p className="lp_ltv_main_text">
              JIMOS、ETVOSなど数々のD2C企業のマーケティングを牽引してきた田岡氏のノウハウとアドエビスの技術を融合することで、これまで実現が困難だった利益ベースのLTVを予測し、効率的かつ効果的なマーケティングを実現します。
            </p>
            <div className="lp_ltv_subcolumn">
              <div className="lp_ltv_subcolumn_photo">
                <img
                  src={lpLtvImage04}
                  alt="田岡 敬氏"
                  width="100"
                  height="103"
                />
              </div>
              <div className="lp_ltv_subcolumn_text">
                <p className="lp_ltv_subcolumn_name">田岡 敬氏</p>
                <p className="lp_ltv_subcolumn_detail">
                  JIMOS代表取締役社長、ニトリホールディングス上席執行役員、エトヴォス取締役COOとして、D2C事業経営全般に10年間携わる。現在は日立グローバルライフソリューションズ常務取締役CDOとして、デジタル事業やLumada事業の成長を担う。2021年5月より、株式会社北の達人コーポレーション
                  社外取締役に就任。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lp_ltv_sub">
          <iframe
            title="LTV Forecast"
            src="https://go.ebis.ne.jp/lp_ltv_forecast_for_admin/"
            width="350"
            height="770"
            type="text/html"
            marginWidth="0"
            marginHeight="0"
            frameBorder="0"
            allowtransparency="true"
          />
        </div>
      </div>
    </div>
  );
};

export default LtvDummyMainContent;
