import { call, put, all, select, takeLatest, delay } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import actions from 'store/dashboard/actions';
import types from 'store/dashboard/types';
import DashboardApi from 'services/api/DashboardApi';
import {
  HttpStatus,
  communicationStatus,
  isCurrentMonth,
} from 'services/utils';
import moment from 'moment';
import loggerConstants from 'store/logger/constant';
import dashboardSelectors from 'store/dashboard/selectors';
import settingsSelectors from 'store/settings/selectors';
import FormatData from 'domain/dashboard/FormatData';
import sharedSagas from 'store/sharedSagas';
import { loggerActions } from 'store/logger';
import isArray from 'lodash/isArray';

const { LOADING, SUCCEEDED, FAILED } = communicationStatus;

function handleError(error) {
  const errors = error.response
    ? error.response.data.errors
    : [{ message: error.message }];

  const scope =
    !error.response || error.response.state >= HttpStatus.SERVER_ERROR
      ? null
      : loggerConstants.SCOPE_DONT_SHOW;

  return { errors, scope };
}

function* errorHandler(err) {
  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];

  yield put(loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

const handleErrors = (error, errorCodes = []) => {
  if (
    !isArray(errorCodes) ||
    errorCodes.length === 0 ||
    !errorCodes.includes(error?.status || null)
  ) {
    return { error, scope: null };
  }

  return { error, scope: loggerConstants.SCOPE_DONT_SHOW };
};

function* fetchDataAll() {
  const isLoading = yield select(
    settingsSelectors.getLoadingByStatus([dashboardSelectors.getStatusMetadata])
  );

  if (isLoading) return;

  yield all([
    put(actions.fetchDataStatictis()),
    put(actions.fetchDataChart()),
    put(actions.fetchDataReport()),
  ]);
}

function* fetchDataMetadata() {
  try {
    const response = yield call(DashboardApi.fetchMetadata);
    const { data, errors } = response.data;
    if (response.status === HttpStatus.OK) {
      let kpiPeriods = data.kpi_periods;
      let errorClone = null;
      if (
        isEmpty(kpiPeriods) ||
        (!isCurrentMonth(kpiPeriods[0].start_date) && moment().date() > 1)
      ) {
        const responsePeriod = yield call(DashboardApi.clonePeriod);
        if (responsePeriod.status === HttpStatus.OK) {
          kpiPeriods = responsePeriod.data.data;
        } else {
          errorClone = responsePeriod.data.errors;
        }
      }
      if (isEmpty(errorClone)) {
        const [channel] = [yield select(settingsSelectors.getTab)];
        yield put(
          actions.fetchDataMetadataSuccess(
            FormatData.buildMetadata(channel, {
              ...data,
              kpi_periods: kpiPeriods,
            })
          )
        );
      } else {
        yield put(actions.fetchDataMetadataError(errorClone));
      }
    } else {
      yield put(actions.fetchDataMetadataError(errors));
    }
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.fetchDataMetadataError(errors, scope));
  }
}

function* fetchDataKpiStatus(params) {
  try {
    if (params.payload.type === LOADING) {
      const response = yield call(DashboardApi.fetchKpiBorder);
      const { data } = response.data;
      yield put(
        actions.fetchDataKpiStatus(
          SUCCEEDED,
          FormatData.buildDataKpiStatus(data)
        )
      );
    }
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.fetchDataKpiStatus(FAILED, errors, scope));
  }
}

function* fetchDataStatictis() {
  try {
    const [period, channel] = [
      yield select(dashboardSelectors.getPeriodSelected),
      yield select(settingsSelectors.getTab),
    ];

    const response = yield call(
      DashboardApi.fetchStatistic,
      channel,
      period.kpi_period_id,
      period.start_date,
      period.end_date
    );
    const { data } = response.data;

    yield put(actions.fetchDataStatictisSuccess(data));
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.fetchDataStatictisError(errors, scope));
  }
}

function* fetchDataChart() {
  try {
    const [period, channel] = [
      yield select(dashboardSelectors.getPeriodSelected),
      yield select(settingsSelectors.getTab),
    ];

    const response = yield call(
      DashboardApi.fetchListChart,
      channel,
      period.kpi_period_id,
      period.start_date,
      period.end_date
    );
    const { data } = response.data;

    yield put(
      actions.fetchDataChartSuccess({
        chart: data,
        chartLine: data[0],
        chartColumn: data[1],
      })
    );
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.fetchDataChartError(errors, scope));
  }
}

function* fetchDataReport() {
  try {
    const [period, channel] = [
      yield select(dashboardSelectors.getPeriodSelected),
      yield select(settingsSelectors.getTab),
    ];
    const response = yield call(
      DashboardApi.fetchListMediaReport,
      channel,
      period.kpi_period_id,
      period.start_date,
      period.end_date
    );
    const { data } = response.data;

    yield put(actions.fetchDataReportSuccess(data));
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.fetchDataReportError(errors, scope));
  }
}

function* fetchDataCompare(params) {
  yield delay(100);
  try {
    const [period, channel] = [
      yield select(dashboardSelectors.getPeriodSelected),
      yield select(settingsSelectors.getTab),
    ];

    const response = yield call(
      DashboardApi.fetchStatisticCompare,
      channel,
      period.kpi_period_id,
      params.payload.start_date,
      params.payload.end_date
    );
    const { data } = response.data;

    yield put(actions.fetchDataCompareSuccess(data));
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.fetchDataCompareError(errors, scope));
  }
}

function* addIndicator(params) {
  try {
    const [channel] = [yield select(settingsSelectors.getTab)];
    const response = yield call(
      DashboardApi.createPickup,
      channel,
      params.payload
    );
    const { data } = response.data;

    yield put(actions.addIndicatorSuccess(data));
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.addIndicatorError(errors, scope));
  }
}

function* editIndicator(params) {
  try {
    const [channel] = [yield select(settingsSelectors.getTab)];

    const response = yield call(
      DashboardApi.updatePickup,
      params.payload.pickup_id,
      channel,
      params.payload.data
    );
    const { data } = response.data;

    yield put(actions.editIndicatorSuccess(data, params.payload.pickup_id));
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.editIndicatorError(errors, scope));
  }
}

function* deleteIndicator(params) {
  try {
    yield call(DashboardApi.deletePickup, params.payload);
    yield put(actions.deleteIndicatorSuccess(params.payload));
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.deleteIndicatorError(errors, scope));
  }
}

function* updateDataChart(params) {
  if (params.payload.pickup_id_new) {
    try {
      const [period, channel] = [
        yield select(dashboardSelectors.getPeriodSelected),
        yield select(settingsSelectors.getTab),
      ];
      const response = yield call(
        DashboardApi.fetchItemChart,
        params.payload.pickup_id_new,
        channel,
        period.kpi_period_id,
        period.start_date,
        period.end_date
      );
      const { data } = response.data;

      yield put(
        actions.updateDataChartSuccess(data, params.payload.pickup_id_old)
      );
    } catch (error) {
      const { errors, scope } = handleError(error);
      yield put(actions.updateDataChartError(errors, scope));
    }
  } else {
    yield put(
      actions.updateDataChartSuccess(false, params.payload.pickup_id_old)
    );
  }
}

function* updateDataReport(params) {
  try {
    const pickupId = params.payload;
    const [period, channel] = [
      yield select(dashboardSelectors.getPeriodSelected),
      yield select(settingsSelectors.getTab),
    ];

    const response = yield call(
      DashboardApi.fetchItemMediaReport,
      pickupId,
      channel,
      period.kpi_period_id,
      period.start_date,
      period.end_date
    );
    const { data } = response.data;

    yield put(actions.updateDataReportSuccess(data));
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.updateDataReportError(errors, scope));
  }
}

function* updateDataBorder(params) {
  try {
    yield call(DashboardApi.updateKpiBorder, params.payload);
    yield put(actions.updateDataBorderSuccess(params.payload));
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.updateDataBorderError(errors, scope));
  }
}

function* getSettingMailInfo() {
  try {
    yield put(actions.setStatusSettingMail(LOADING));
    const { data } = yield call(DashboardApi.getSettingMailInfo);
    yield put(actions.setDataSettingMail(data));
  } catch (error) {
    const { errors, scope } = handleError(error);
    yield put(actions.getSettingMailError(errors, scope));
  }
}

function* handleSaveSettingMail(action) {
  const { data, callback } = action.payload;
  try {
    yield put(actions.setStatusSettingMail(LOADING));
    yield call(DashboardApi.saveSettingMail, data);
    yield put(actions.setStatusSettingMail(SUCCEEDED));
    callback();
  } catch (e) {
    const { error: response, scope } = handleErrors(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data.errors || [];
    yield put(actions.setErrorSettingMail(errors, scope));
    callback(true, errors);
  }
}

function* getListUsers() {
  yield put(actions.setStatusListUsers(LOADING));
  const userRes = yield call(DashboardApi.fetchUsers);
  const response = userRes.data.data.detail.filter(
    (user) =>
      user.agent_flag === false && user.user_roles.includes('manage_account')
  );
  yield put(actions.setListUsers(response));
}

export default function* dashboardSaga() {
  yield takeLatest(types.FETCH_DATA_ALL, fetchDataAll);
  yield takeLatest(types.FETCH_DATA_METADATA, fetchDataMetadata);
  yield takeLatest(types.FETCH_DATA_KPI_STATUS, fetchDataKpiStatus);
  yield takeLatest(types.FETCH_DATA_STATICTIS, fetchDataStatictis);
  yield takeLatest(types.FETCH_DATA_CHART, fetchDataChart);
  yield takeLatest(types.FETCH_DATA_REPORT, fetchDataReport);
  yield takeLatest(types.FETCH_DATA_COMPARE, fetchDataCompare);

  yield takeLatest(types.ADD_INDICATOR, addIndicator);
  yield takeLatest(types.EDIT_INDICATOR, editIndicator);
  yield takeLatest(types.DELETE_INDICATOR, deleteIndicator);

  yield takeLatest(types.UPDATE_DATA_CHART, updateDataChart);
  yield takeLatest(types.UPDATE_DATA_REPORT, updateDataReport);
  yield takeLatest(types.UPDATE_DATA_BORDER, updateDataBorder);
  yield takeLatest(
    types.GET_SETTING_MAIL_INFO,
    sharedSagas.safe(handleError, getSettingMailInfo)
  );
  yield takeLatest(
    types.SAVE_SETTING_MAIL,
    sharedSagas.safe(errorHandler, handleSaveSettingMail)
  );
  yield takeLatest(
    types.GET_LIST_USERS,
    sharedSagas.safe(errorHandler, getListUsers)
  );
}
