import handleError from 'services/error/handleScopeError';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { loggerActions } from 'store/logger';
import loggerConstants from 'store/logger/constant';
import sharedSagas from 'store/sharedSagas';
import { communicationStatus } from 'services/utils';
import { getSortString } from 'domain/utils';
import uploadActions from 'store/upload/actions';
import commonActions from 'store/common/actions';
import mediaServiceApi from 'services/api/settings/MediaService';

import { IMPRESSION_AD_COST } from 'services/routes/constants';
import impressionAdCostApi from 'services/api/ImpressionAdCostApi';
import impressionAdCostSelectors from 'store/impression-ad-cost/selectors';
import impressionAdCostActions from 'store/impression-ad-cost/actions';
import types from 'store/impression-ad-cost/types';
import {
  TABLE_ROWS_PER_PAGE,
  CONFIG_SORT_FIELD_NAME_REPLACE,
} from 'domain/impression-ad-cost/consts';
import impressionAdCostServices from 'domain/impression-ad-cost/Services';

const { LOADING } = communicationStatus;

function* fetchDataTable() {
  try {
    yield put(impressionAdCostActions.setStatusTable(LOADING));
    const pagination = yield select(impressionAdCostSelectors.getPagination);
    const sort = { ...(yield select(impressionAdCostSelectors.getSort)) };
    sort.field = CONFIG_SORT_FIELD_NAME_REPLACE[sort.field] || sort.field;
    const params = {
      sort: getSortString(sort),
      limit: TABLE_ROWS_PER_PAGE,
      offset: (pagination.currentPage - 1) * TABLE_ROWS_PER_PAGE,
    };
    const response = yield call(impressionAdCostApi.fetchAll, params);
    if (response.status === 200) {
      yield put(impressionAdCostActions.setDataTable(response?.data || {}));
    }
  } catch (error) {
    // Since the API does not return a dedicated error code, if an error occurs, it will be unconditionally "Show general error modals".
    yield put(
      impressionAdCostActions.requestError({
        error: handleError(error.response),
      })
    );
  }
}

function sleep(sec) {
  return new Promise((resolve) => setTimeout(resolve, sec * 1000));
}

function* upload(action) {
  try {
    const { file, importingType, csvRegisterType, callback } = action.payload;
    yield put(uploadActions.requestStart(IMPRESSION_AD_COST));

    // Compress to gzip
    const gzipFile = yield call(
      impressionAdCostServices.compressCsvToGzip,
      file
    );

    // Get preSignedUrl
    const {
      data: {
        data: { location },
      },
    } = yield call(impressionAdCostApi.getPresignedUrl);

    // Upload gzipFile to S3
    yield call(mediaServiceApi.upload, location, gzipFile, 'application/gzip');

    // NOTE: Close Modal
    yield put(uploadActions.uploadSucceeded());

    // Display a message at the bottom of the screen
    yield put(
      commonActions.setDownloadNotify('CSVファイルをアップロードしています…')
    );

    const filename = impressionAdCostServices.getFileNameByPresignedUrl(
      location
    );
    const callbackWrap = async () => {
      // NOTE: If the process to immediately reacquire historical information is executed, there is a high possibility that the record being processed will not be successfully retrieved. To prevent this, wait a few seconds.
      await sleep(1);

      // OPTIMIZE: The contents of the process are identical to `impressionAdCostActions.getDataTable`. However, since did not know how to execute an action as a callback, decided to define it on the component side and call it for the time being.
      await callback();
    };
    // Play loading animation for the purpose of hiding the current history information because want to display the line whose status is being processed.
    yield put(impressionAdCostActions.setStatusTable(LOADING));
    // Request the process of importing CSV file contents to the API
    yield call(impressionAdCostApi.upload, {
      filename,
      importingType,
      csvRegisterType,
      callback: callbackWrap,
    });

    yield put(commonActions.setDownloadNotify('CSVアップロードが完了しました'));
  } catch (error) {
    const { response = { status: 500 } } = error;
    const status = response?.status ?? 500;
    if (status === 400) {
      yield put(
        commonActions.setDownloadNotify({
          value: 'CSVアップロードに失敗しました。',
          pageTransitionLinkTitle: 'アップロード結果を確認する',
          pageTransitionTarget: 'impression-ad-cost',
        })
      );
    } else {
      // API or App error
      yield put(uploadActions.requestError(handleError(response)));
    }
  } finally {
    yield put(impressionAdCostActions.resetPageAndSort());
    yield put(impressionAdCostActions.getDataTable());
  }
}

function* downloadUploadedCsv({ payload: token }) {
  try {
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    const response = yield call(impressionAdCostApi.downloadUploadedCsv, token);
    const { location, file_name: newFileName } = response.data.data;
    const blobResponse = yield call(
      impressionAdCostApi.getBlobByPresignedUrl,
      location
    );
    const blob = new Blob([blobResponse.data], { type: 'application/gzip' });
    yield call(impressionAdCostServices.decompressBlobToCsv, blob, newFileName);
  } catch (error) {
    yield put(
      impressionAdCostActions.requestError({
        error: handleError(error.response),
      })
    );
  } finally {
    yield put(commonActions.setDownloadNotify(''));
  }
}

function* downloadCsvFormat({ payload: importingType }) {
  try {
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    yield call(impressionAdCostApi.downloadCsvFormat, importingType);
  } catch (error) {
    yield put(
      impressionAdCostActions.requestError({
        error: handleError(error.response),
      })
    );
  } finally {
    yield put(commonActions.setDownloadNotify(''));
  }
}


function* errorHandler(err) {
  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];
  yield put(loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

export default function* watch() {
  yield takeLatest(types.FETCH, sharedSagas.safe(errorHandler, fetchDataTable));
  yield takeLatest(types.UPLOAD, sharedSagas.safe(errorHandler, upload));
  yield takeLatest(
    types.DOWNLOAD_UPLOADED_CSV,
    sharedSagas.safe(errorHandler, downloadUploadedCsv)
  );
  yield takeLatest(
    types.DOWNLOAD_CSV_FORMAT,
    sharedSagas.safe(errorHandler, downloadCsvFormat)
  );
}
