import { put, call, select, takeLatest, all } from 'redux-saga/effects';

import { TABLE_ROWS_PER_PAGE } from 'domain/ltv/analyze/constants';

import sharedSagas from 'store/sharedSagas';
import filterSelectors from 'store/filters/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import masterDataActions from 'store/master-data/actions';
import types from 'store/ltv/analyze/types';
import ltvAnalyzeSelectors from 'store/ltv/analyze/selectors';
import actions from 'store/ltv/analyze/actions';
import reflectiontimeActions from 'store/reflectiontime/actions';

import handleError from 'services/error/handleScopeError';
import { LTV_ANALYZE } from 'services/routes/constants';
import { LTV_ANALYZED_RESULT } from 'services/common/reflectiontime/constants';
import LtvAnalyzeApi from 'services/api/LtvAnalyzeApi';
import settingsSelectors from 'store/settings/selectors';

import LtvPeriodDropDownApi from 'services/api/ltv/LtvPeriodDropDownApi';
import displayItemsSelectors from 'store/display-items/selectors';
import { API_DATE_FORMAT } from 'domain/consts';

function* fetchReport() {
  try {
    yield sharedSagas.waitingRedirect();

    const [page, conversions, period, pagination, sort, displayConditions] = [
      yield select(settingsSelectors.getPage),
      yield select(masterDataSelectors.conversionsSelector),
      yield select(ltvAnalyzeSelectors.periodSelector),
      yield select(ltvAnalyzeSelectors.getPagination),
      yield select(ltvAnalyzeSelectors.sortSelector),
      yield select(displayItemsSelectors.getSettings),
    ];

    if (page !== LTV_ANALYZE) return;

    yield put(actions.requestStart());

    if (!conversions || conversions.length <= 0) {
      yield put(masterDataActions.fetchConversions());
    }

    const params = {
      channel: yield select(settingsSelectors.getTab),
      start_date: period.start.format(API_DATE_FORMAT),
      end_date: period.end.format(API_DATE_FORMAT),
      display_conditions: displayConditions,
      dimensions: yield select(ltvAnalyzeSelectors.dimensionsSelector),
      metrics: yield select(ltvAnalyzeSelectors.metricsSelector),
      filters: yield select(filterSelectors.getForApi),
      sum: true,
    };

    const response = yield call(LtvAnalyzeApi.fetchReport, params, {
      limit: TABLE_ROWS_PER_PAGE,
      offset: (pagination.currentPage - 1) * TABLE_ROWS_PER_PAGE,
      ...sort,
    });

    yield put(actions.fetchReportSucceeded(response.data));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

// 期間取得
function* fetchStatus() {
  try {
    const response = yield call(LtvPeriodDropDownApi.fetchStatus);
    const { status, analyzed_date: analyzedDate } = response.data.data.detail;
    yield all([
      put(
        actions.fetchStatusSucceeded({
          status: response.data.data,
        })
      ),
      put(
        reflectiontimeActions.fetchReflectionSuccess({
          [LTV_ANALYZED_RESULT]: {
            service: LTV_ANALYZED_RESULT,
            status,
            analyzed_date: analyzedDate,
          },
        })
      ),
    ]);
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

export default function* watchView() {
  yield takeLatest(types.FETCH_REPORT, sharedSagas.skipIfNotReady(fetchReport));
  yield takeLatest(types.FETCH_STATUS, fetchStatus);
}
