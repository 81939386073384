import moment from 'moment';
import {
  ADD_NEW,
  EDIT,
  COPY,
  TYPE_PRODUCT,
  PRODUCT_NAME,
  PRODUCT_ID,
  TYPE_OFFER,
  OFFER_NAME,
  OFFER_ID,
  MARGIN_F1,
  MARGIN_F2,
  MARGIN_F3,
  PAY_BACK,
  EFFECTIVE_MONTH,
  ID,
  MARGIN_MIN,
  MARGIN_MAX,
  PAYBACK_MIN,
  PAYBACK_MAX,
  CHANNEL_TYPE,
  CHANNEL_TYPE_AD,
  FIELD_IS_USING,
} from 'domain/ltv/settings/constants';
import Validator, {
  validateIsEmptyString,
  validateIsNumInRange,
  validateIsInteger,
} from 'services/validations';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { conditionError } from 'domain/ltv/settings/errorMessages';

const getTitleByAction = (action) => {
  const actions = {
    [ADD_NEW]: '集計条件を追加',
    [EDIT]: '集計条件を編集',
    [COPY]: '集計条件をコピー',
  };
  return actions?.[action] ?? '';
};

const getTypeName = (type) => {
  if (type === TYPE_PRODUCT) {
    return '商品';
  }
  if (type === TYPE_OFFER) {
    return 'オファー';
  }

  return '';
};

const findByProductIdOfferId = (id, items) => {
  const [pid, oid] = id.split('-');

  if (pid && oid) {
    const index = findIndex(
      items,
      (item) =>
        item[PRODUCT_ID].toString() === pid && item[OFFER_ID].toString() === oid
    );
    return index !== -1 ? items[index] : null;
  }

  return null;
};

const getItemsByIds = (ids = [], items = []) => {
  return items.filter((item) => {
    const itemId = `${item[PRODUCT_ID]}-${item[OFFER_ID]}`;
    return ids.indexOf(itemId) !== -1;
  });
};

const convertItemsToIdsString = (items = []) => {
  const ids = items.map((item) => `${item[PRODUCT_ID]}-${item[OFFER_ID]}`);
  return ids.join(',');
};

const getCurrentMonth = () => {
  return moment().format('YYYY-MM');
};

const convertMonth = (month) => {
  return moment(month, 'YYYY-MM-DD').format('YYYY-MM');
};

const toString = (value) => {
  if (value === null || value === undefined) {
    return '';
  }
  return value.toString();
};

const getMonthRows = (item) => {
  const rows = [];

  if (item) {
    const { conditions } = item;
    conditions.map((condition) => {
      const row = {
        [MARGIN_F1]: toString(condition[MARGIN_F1]),
        [MARGIN_F2]: toString(condition[MARGIN_F2]),
        [MARGIN_F3]: toString(condition[MARGIN_F3]),
        [PAY_BACK]: toString(condition[PAY_BACK]),
        [EFFECTIVE_MONTH]: convertMonth(condition[EFFECTIVE_MONTH]),
      };
      rows.push(row);
      return true;
    });
  }

  return rows;
};
const getDefaultError = () => {
  return {
    result: true,
    message: '',
  };
};

const getDefaultRow = () => {
  return {
    [MARGIN_F1]: '',
    [MARGIN_F2]: '',
    [MARGIN_F3]: '',
    [PAY_BACK]: '',
    [EFFECTIVE_MONTH]: getCurrentMonth(),
  };
};

const getHeader = (selectedRows, isCheckedAll, sort) => {
  const headerData = [
    {
      text: '',
      name: 'check_all',
      isDimension: true,
      isEdit: true,
      sort: false,
      checked: selectedRows.length > 0,
      variant: selectedRows.length > 0 && !isCheckedAll ? 'mixed' : '',
    },
    {
      isDimension: true,
      name: 'product_name',
      sort: 'none',
      text: '商品',
    },
    {
      isDimension: true,
      name: 'offer_name',
      sort: 'none',
      text: 'オファー',
    },
    {
      isDimension: false,
      name: MARGIN_F1,
      sort: 'none',
      text: 'F1限界利益率',
    },
    {
      isDimension: false,
      name: MARGIN_F2,
      sort: 'none',
      text: 'F2限界利益率',
    },
    {
      isDimension: false,
      name: MARGIN_F3,
      sort: 'none',
      text: 'F3以降限界利益率',
    },
    {
      isDimension: false,
      name: PAY_BACK,
      sort: 'none',
      text: '目標投資回収期間',
    },
    {
      isDimension: false,
      name: EFFECTIVE_MONTH,
      sort: 'none',
      text: '適用開始月',
    },
  ];
  if (sort) {
    return headerData.map((item) => {
      const newObj = { ...item };
      if (newObj.name === sort.field) {
        newObj.sort = sort.direction;
      }
      return newObj;
    });
  }

  return headerData;
};
const getYearMonthText = (dateStr) => {
  const date = moment(dateStr);
  return date.isValid() ? date.format('YYYY/MM') : '';
};
const formatData = (data) => {
  return data.map((item) => {
    return {
      [ID]: `${item[PRODUCT_ID]}-${item[OFFER_ID]}`,
      [PRODUCT_NAME]: item[PRODUCT_NAME],
      [OFFER_NAME]: item[OFFER_NAME],
      [MARGIN_F1]: item[MARGIN_F1] !== '' ? `${item[MARGIN_F1]}%` : '',
      [MARGIN_F2]: item[MARGIN_F2] !== '' ? `${item[MARGIN_F2]}%` : '',
      [MARGIN_F3]: item[MARGIN_F3] !== '' ? `${item[MARGIN_F3]}%` : '',
      [PAY_BACK]: item[PAY_BACK] !== '' ? `${item[PAY_BACK]}ヶ月` : '',
      [EFFECTIVE_MONTH]: getYearMonthText(item[EFFECTIVE_MONTH]),
    };
  });
};

const updateDataListSelected = (list, selectedRows) => {
  return list.map((item) => {
    return {
      ...item,
      rowId: item[ID],
      selected: selectedRows.includes(item[ID]),
    };
  });
};

const validateRow = (row) => {
  const rulesValidate = [
    {
      field: [MARGIN_F1],
      method: validateIsEmptyString(),
      validWhen: false,
      message: conditionError.MARGIN_F1,
    },
    {
      field: [MARGIN_F1],
      method: validateIsNumInRange(),
      args: [{ min: MARGIN_MIN, max: MARGIN_MAX }],
      validWhen: true,
      message: conditionError.MARGIN_F1,
    },
    {
      field: [MARGIN_F2],
      method: validateIsEmptyString(),
      validWhen: false,
      message: conditionError.MARGIN_F2,
    },
    {
      field: [MARGIN_F2],
      method: validateIsNumInRange(),
      args: [{ min: MARGIN_MIN, max: MARGIN_MAX }],
      validWhen: true,
      message: conditionError.MARGIN_F2,
    },
    {
      field: [MARGIN_F3],
      method: validateIsEmptyString(),
      validWhen: false,
      message: conditionError.MARGIN_F3,
    },
    {
      field: [MARGIN_F3],
      method: validateIsNumInRange(),
      args: [{ min: MARGIN_MIN, max: MARGIN_MAX }],
      validWhen: true,
      message: conditionError.MARGIN_F3,
    },
    {
      field: [PAY_BACK],
      method: validateIsEmptyString(),
      validWhen: false,
      message: conditionError.PAY_BACK,
    },
    {
      field: [PAY_BACK],
      method: validateIsInteger(),
      validWhen: true,
      message: conditionError.PAY_BACK,
    },
    {
      field: [PAY_BACK],
      method: validateIsNumInRange(),
      args: [{ min: PAYBACK_MIN, max: PAYBACK_MAX }],
      validWhen: true,
      message: conditionError.PAY_BACK,
    },
  ];

  const validator = new Validator(rulesValidate);
  const errors = validator.validate({
    [MARGIN_F1]: trim(row[MARGIN_F1]),
    [MARGIN_F2]: trim(row[MARGIN_F2]),
    [MARGIN_F3]: trim(row[MARGIN_F3]),
    [PAY_BACK]: trim(row[PAY_BACK]),
  });
  if (
    row[MARGIN_F1] === '' &&
    row[MARGIN_F2] === '' &&
    row[MARGIN_F3] === '' &&
    row[PAY_BACK] === ''
  ) {
    delete errors[MARGIN_F1];
    delete errors[MARGIN_F2];
    delete errors[MARGIN_F3];
    delete errors[PAY_BACK];
  }

  return errors;
};

const validateRows = (rows) => {
  const errors = [];
  rows.map((row) => {
    errors.push(validateRow(row));
    return true;
  });

  const monthUnique = [];
  const months = rows.map((row, index) => {
    const month = row[EFFECTIVE_MONTH];
    const result = { month, index, duplicate: monthUnique.includes(month) };
    if (!result.duplicate) {
      monthUnique.push(month);
    }
    return result;
  });

  const groupMonth = monthUnique.map((month) => {
    return months.filter((obj) => {
      return obj.month === month && obj.duplicate;
    });
  });

  const lastIndexDuplicateMonth = groupMonth
    .map((value) => {
      return value.slice(-1).pop();
    })
    .filter((value) => {
      return value !== undefined;
    });

  lastIndexDuplicateMonth.forEach((obj) => {
    errors[obj.index][EFFECTIVE_MONTH] = conditionError.EFFECTIVE_MONTH;
  });

  return errors;
};

const isAllValid = (errors) => {
  const arr = errors.filter((err) => {
    return !isEmpty(err);
  });
  return arr.length === 0;
};

const getEditableField = (items) => {
  return items.map((item) => {
    return {
      [PRODUCT_ID]: item[PRODUCT_ID],
      [OFFER_ID]: item[OFFER_ID],
      conditions: item.conditions,
    };
  });
};

const createNewItem = ({ product, offer, rows }) => {
  return {
    [PRODUCT_ID]: product ? product.value : null,
    [PRODUCT_NAME]: product ? product.label : null,
    [OFFER_ID]: offer ? offer.value : null,
    [OFFER_NAME]: offer ? offer.label : null,
    conditions: [...rows],
  };
};
const apiErrorCode = {
  INVALID_FORMAT: '※ 不正なCSVファイルです。',
};

const isApiValidationError = ({ data, status }) => {
  if (status !== 400) {
    return false;
  }
  const { errors } = data;
  if (errors.length > 0) {
    return true;
  }
  return false;
};

const createObjectByChannelType = ({ channel, product, offer, rows }) => {
  return channel === CHANNEL_TYPE_AD
    ? {
        [CHANNEL_TYPE]: channel,
        [PRODUCT_ID]: product.value,
        [OFFER_ID]: offer.value,
        conditions: rows,
      }
    : {
        [PRODUCT_ID]: 0,
        [OFFER_ID]: 0,
        [CHANNEL_TYPE]: channel,
        conditions: rows,
      };
};

const getItemsByUsingStatus = (selectedIds, items, isUsing) => {
  return items.filter((item) => {
    const itemId = `${item[PRODUCT_ID]}-${item[OFFER_ID]}`;
    return item[FIELD_IS_USING] === isUsing && selectedIds.includes(itemId);
  });
};

const isHasChannelAll = (items) => {
  if (items.length) {
    const first = items[0];
    if (first[PRODUCT_ID] === 0 && first[OFFER_ID] === 0) {
      return true;
    }
  }
  return false;
};
// Ex product_name_0 => return 0
// Ex product_id_1 => return 1
const getFieldIndex = (field) => {
  if (!field) {
    return 0;
  }
  const array = field.split('_');
  return parseInt(array[array.length - 1], 10);
};

export default {
  getYearMonthText,
  getDefaultRow,
  getDefaultError,
  formatData,
  getMonthRows,
  findByProductIdOfferId,
  getItemsByIds,
  getTitleByAction,
  getTypeName,
  updateDataListSelected,
  getHeader,
  validateRow,
  validateRows,
  isAllValid,
  getEditableField,
  convertMonth,
  createNewItem,
  apiErrorCode,
  isApiValidationError,
  createObjectByChannelType,
  getItemsByUsingStatus,
  isHasChannelAll,
  convertItemsToIdsString,
  getFieldIndex,
};
