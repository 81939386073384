import React from 'react';
import { arrayOf } from 'prop-types';

import './list-items-card.scss';

const ListItemsCard = ({ items }) => {
  return (
    <div className="list-items-card">
      {items.map((item, index) => {
        const keyIndex = `list-items-card-${index}`;
        return (
          <div key={keyIndex} style={{ order: item.order }}>
            {item.value}
          </div>
        );
      })}
    </div>
  );
};

ListItemsCard.propTypes = {
  items: arrayOf({}).isRequired,
};

ListItemsCard.defaultProps = {};

export default ListItemsCard;
