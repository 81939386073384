import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notifications from 'views/molecules/notification';
import { notificationsSelectors } from 'store/notifications';
import actions from 'store/notifications/actions';

const NotificationsContainer = ({ startNotificationService, ...restProps }) => {
  // Get data from API
  useEffect(() => {
    startNotificationService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Notifications {...restProps} />;
};

NotificationsContainer.propTypes = {
  startNotificationService: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notificationList: notificationsSelectors.getNotificationsList(state),
  newNotifications: notificationsSelectors.getUnreadNotificationsList(state),
  newsModalState: notificationsSelectors.getNewsModalState(state),
});

const mapDispatchToProps = (dispatch) => ({
  startNotificationService: () => dispatch(actions.fetchNotificationsStart()),
  updateNotification: (id) => dispatch(actions.updateNotificationRead(id)),
  addToNewsModal: (id) => dispatch(actions.addNotificationModal(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);
