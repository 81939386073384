import types from 'store/impression-ad-cost/types';
import helper from 'store/helper';

const getDataTable = () => helper.createAction(types.FETCH);
const setDataTable = (data) => helper.createAction(types.SET_DATA_TABLE, data);
const setStatusTable = (status) =>
  helper.createAction(types.SET_STATUS_TABLE, status);
const updateSort = (field, direction) =>
  helper.createAction(types.UPDATE_SORT, { field, direction });
const updatePage = (page) => helper.createAction(types.UPDATE_PAGE, page);
const upload = (data) => helper.createAction(types.UPLOAD, data);
const downloadUploadedCsv = (token) => helper.createAction(types.DOWNLOAD_UPLOADED_CSV, token);
const requestError = (error) =>
  helper.createAction(types.REQUEST_ERROR, error);
const resetPageAndSort = () => helper.createAction(types.RESET_PAGE_AND_SORT);
const downloadCsvFormat = (importingType) =>
  helper.createAction(types.DOWNLOAD_CSV_FORMAT, importingType);

export default {
  getDataTable,
  setDataTable,
  setStatusTable,
  updateSort,
  updatePage,
  upload,
  downloadUploadedCsv,
  requestError,
  resetPageAndSort,
  downloadCsvFormat,
};
