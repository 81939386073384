import { EMAIL_NOTIFICATION, SITE_URL } from 'domain/fields';
import { acceptedEmailNotifications } from 'domain/search-console-setting/constants';
import {
  requiredValue,
  acceptsValue,
} from 'services/validations/commonValidations';

const scSettingRules = {
  [EMAIL_NOTIFICATION]: [
    acceptsValue(
      acceptedEmailNotifications,
      'メール通知を正しく選択してください。'
    ),
  ],
  [SITE_URL]: [requiredValue()],
};

export default scSettingRules;
