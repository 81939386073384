import { useSelector, shallowEqual } from 'react-redux';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import {
  CAPI_SETTING_EDIT,
  CAPI_SETTING_VIEW,
  CAPI_SINGLE_LIMIT,
} from 'domain/permissions/contractPermissions';
import { getPermissions } from 'store/auth/selectors';

const useCheckPermissionCapi = () => {
  const { checkPermissions } = useCheckPermissions();
  const permissions = useSelector(getPermissions, shallowEqual);

  const isCapiSingleContract = checkPermissions('anyOf', [CAPI_SINGLE_LIMIT]);
  const isCapiContract = checkPermissions('anyOf', [
    CAPI_SETTING_EDIT,
    CAPI_SETTING_VIEW,
  ]);
  const isCapiEditPermission = checkPermissions('anyOf', [CAPI_SETTING_EDIT]);

  let isCapiSettingPermissionOnly = false;
  if (permissions.length <= 2) {
    isCapiSettingPermissionOnly = checkPermissions('anyOf', [
      CAPI_SETTING_EDIT,
      CAPI_SETTING_VIEW,
    ]);
  }

  return {
    isCapiSingleContract,
    isCapiContract,
    isCapiEditPermission,
    isCapiSettingPermissionOnly,
  };
};

export default useCheckPermissionCapi;
