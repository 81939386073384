import types from 'store/ltv/settings/ad/types';

const start = (type) => ({
  type,
});

const succeeded = (type, data) => ({
  type,
  payload: data,
});

const failed = (type, { error }) => ({
  type,
  payload: error,
  scope: error.scope,
});

const updateCurrentItemNumber = (num) => ({
  type: types.UPDATE_CURRENT_ITEM_NUMBER,
  payload: num,
});

const updateSort = (sorts) => ({
  type: types.UPDATE_SORT,
  payload: sorts,
});

const updatePage = (page) => ({
  type: types.UPDATE_PAGE,
  payload: { page },
});

const upload = (payload) => ({
  type: types.UPLOAD,
  payload,
});
const submitFailed = ({ error, scope }) => ({
  type: types.SUBMIT_FAILED,
  payload: error,
  scope,
});

const detail = (type, data) => ({
  type,
  payload: data,
});

const fetchListStart = () => start(types.FETCH_LIST_START);
const requestStart = () => start(types.REQUEST_START);
const requestSucceeded = () => start(types.REQUEST_SUCCEEDED);
const searchStart = () => start(types.SEARCH_START);
const requestFailed = (error) => failed(types.REQUEST_FAILED, error);
const fetched = (data) => succeeded(types.FETCH_SUCCEEDED, data);
const fetchedList = (data) => succeeded(types.FETCH_LIST_SUCCEEDED, data);
const updated = (data) => succeeded(types.UPDATE_SUCCEEDED, data);
const removed = (data) => succeeded(types.DELETE_SUCCEEDED, data);
const fetch = (data) => succeeded(types.FETCH, data);
const fetchList = (data) => succeeded(types.FETCH_LIST, data);
const update = (data) => succeeded(types.UPDATE, data);
const remove = (id) => succeeded(types.DELETE, id);
const changeSelectedItem = (id) => succeeded(types.CHANGE_SELECTED_ITEM, id);
const search = (payload) => succeeded(types.SEARCH, payload);
const searchSuccess = (data) => succeeded(types.SEARCH_SUCCEEDED, data);
const uploaded = (data) => succeeded(types.UPLOAD_SUCCEEDED, data);
const clearApiError = () => start(types.CLEAR_API_RESULT);

const getDetail = (id) => detail(types.GET_DETAIL, id);
const setDetail = (data) => detail(types.SET_DETAIL, data);

export default {
  requestStart,
  requestSucceeded,
  requestFailed,
  fetched,
  fetchedList,
  updated,
  removed,
  fetch,
  fetchList,
  fetchListStart,
  update,
  remove,
  changeSelectedItem,
  updateCurrentItemNumber,
  updateSort,
  updatePage,
  search,
  searchStart,
  searchSuccess,
  upload,
  uploaded,
  submitFailed,
  clearApiError,
  getDetail,
  setDetail,
};
