import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { arrayOf, string, bool, oneOfType, node, func } from 'prop-types';
import ListItemsCardCustom from 'views/atoms/card/ListItemsCard/ListItemsCardCustom';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';

import './delete-confirm-modal.scss';
import { result } from 'lodash';

// Copied from DeleteConfirmModal.jsx
// The number of checkboxes can be changed. (forceText: arrayOf([]))
// A Single checkbox can now be placed in the footer. (forceTextFooter: string)
function CustomConfirmModal(props) {
  const {
    isShow,
    isLoading,
    modalWidth,
    title,
    content,
    items,
    isLpo,
    forceText,
    forceTextFooter,
    confirmText,
    onHide,
    onSubmit,
    subContent,
    subItems,
    subItemsNotDelete,
    contentItemNotDelete,
    hideText,
    isShowButtonSubmit,
  } = props;

  const [isForceDelete, setForceDelete] = useState({});
  const [isForceDeleteFooter, setForceDeleteFooter] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const updateForceDelete = (key, value) => {
    setForceDelete((prevState) => ({ ...prevState, [key]: value }));
  };

  // Enable delete button only when all checkboxes are true
  const getForceDelete = () => {
    if (forceText) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < Object.keys(forceText).length; i++) {
        if (!isForceDelete[i]) {
          return false;
        }
      }
    }
    if (forceTextFooter && !isForceDeleteFooter) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    setSubmit(true);
    onSubmit();
  };
  const modalClass = classNames({
    'modal--delete-confirm': true,
    'modal--delete-confirm__loading': isLoading,
  });

  const modalFooterClass = classNames({
    'modal-footer__has-ebis-checkbox': forceTextFooter,
  });

  return (
    <Modal show={isShow} centered size={modalWidth} className={modalClass}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={isLoading}>
          {content && <>{content}</>}
          {items.length > 0 && !isLpo && <ListItemsCardCustom items={items} bgcolor="#F4F4F4" border="none" />}
          {items.length > 0 && isLpo && <ListItemsCardCustom items={items} bgcolor="#F4F4F4" border="none" maxHeight="143px" />}
          {subContent && <>{subContent}</>}
          {subItems.length > 0 && <ListItemsCardCustom items={subItems} />}
          {contentItemNotDelete && <>{contentItemNotDelete}</>}
          {subItemsNotDelete.length > 0 && (
            <ListItemsCardCustom items={subItemsNotDelete} />
          )}
          {forceText && (
            <div class="dns-setting-multi-checkbox">
              {forceText.map((text, index) => (
                <div>
                  <EbisCheckbox
                    name={`force-delete-${index}`}
                    text={text}
                    checked={isForceDelete[index]}
                    disabled={isLoading}
                    changeSelectBoxValue={() => updateForceDelete(index, !isForceDelete[index])}
                  />
                </div>
              ))}
            </div>
          )}
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer className={modalFooterClass}>
        {forceTextFooter && (
          <div className="d-flex align-items-center">
            <EbisCheckbox
              name="force-delete-footer"
              text={forceTextFooter}
              checked={isForceDeleteFooter}
              disabled={isLoading}
              changeSelectBoxValue={() => setForceDeleteFooter(!isForceDeleteFooter)}
            />
          </div>
        )}
        <div>
          <Button
            size="sm"
            variant="link"
            disabled={isLoading && isSubmit}
            onClick={onHide}
          >
            {hideText}
          </Button>
          {isShowButtonSubmit && (
            <Button
              size="sm"
              variant="secondary"
              disabled={isLoading || !getForceDelete()}
              onClick={handleSubmit}
            >
              {confirmText}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

CustomConfirmModal.propTypes = {
  isShow: bool.isRequired,
  isLoading: bool,
  modalWidth: string,
  title: string.isRequired,
  items: arrayOf([]),
  isLpo: bool,
  content: oneOfType([string, node]),
  forceText: arrayOf([]),
  forceTextFooter: string,
  confirmText: string,
  onHide: func,
  onSubmit: func,
  subContent: oneOfType([string, node]),
  subItems: arrayOf([]),
  subItemsNotDelete: arrayOf([]),
  contentItemNotDelete: oneOfType([string, node]),
  hideText: string,
  isShowButtonSubmit: bool,
};

CustomConfirmModal.defaultProps = {
  isLoading: false,
  modalWidth: 'w600',
  content: null,
  items: [],
  isLpo: false,
  subContent: null,
  contentItemNotDelete: null,
  subItems: [],
  subItemsNotDelete: [],
  forceText: null,
  forceTextFooter: null,
  isShowButtonSubmit: true,
  confirmText: '削除',
  hideText: 'キャンセル',
  onHide: () => {},
  onSubmit: () => {},
};

export default CustomConfirmModal;
