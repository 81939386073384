import React, {
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { Form } from 'react-bootstrap';
import {
  oneOfType,
  bool,
  object,
  node,
  string,
  func,
  oneOf,
  number,
} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import NumericInput from 'views/atoms/numeric-input/NumericInput';
import classNames from 'classnames';

function FormInput(props) {
  const {
    as,
    icon,
    name,
    value,
    type,
    placeholder,
    style,
    error,
    disabled,
    isDecimal,
    maxLength,
    onChange,
    regex,
    isAuthenticated,
    isAuthenticatedError,
  } = props;

  const [inputValue, setInputValue] = useState(null);

  const ref = useRef();
  const isError = !isEmpty(error);

  const propInput = useMemo(() => {
    return {
      type,
      name,
      placeholder,
      style,
      disabled,
      isDecimal,
      size: 'sm',
      className: `${isError ? 'error' : ''}`,
    };
  }, [type, name, placeholder, style, disabled, isError, isDecimal]);

  const handleValueChanged = useCallback(
    (e) => {
      const { value: newValue } = e.target;
      const isAllow = !newValue || !regex || new RegExp(regex).test(newValue);
      if (isAllow && (maxLength === -1 || newValue.length <= maxLength)) {
        setInputValue(newValue);
        onChange(name, newValue);
      }
    },
    [maxLength, regex, onChange, name]
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const authenGoogleClass = classNames({
    'authenticated-google': true,
    'authenticated-google--error': isAuthenticatedError,
  });

  return (
    <div className="d-flex align-items-center form-input" ref={ref}>
      {icon}
      <ErrorTooltipWrapper isError={isError} errorMess={error} ref={ref}>
        {type === 'number' ? (
          <NumericInput
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...propInput}
            value={inputValue}
            onChange={handleValueChanged}
          />
        ) : (
          <>
            <Form.Control
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...propInput}
              as={as}
              value={inputValue}
              onChange={handleValueChanged}
            />
            {isAuthenticated && (
              <span className={authenGoogleClass}>認証済み</span>
            )}
          </>
        )}
      </ErrorTooltipWrapper>
    </div>
  );
}

FormInput.propTypes = {
  disabled: bool,
  icon: oneOfType([string, node]),
  name: string,
  value: string,
  type: string,
  placeholder: string,
  style: oneOfType([object]),
  error: string,
  onChange: func,
  as: oneOf(['input', 'textarea']),
  isDecimal: bool,
  regex: string,
  maxLength: number,
  isAuthenticated: bool,
  isAuthenticatedError: bool,
};

FormInput.defaultProps = {
  disabled: false,
  icon: '',
  name: '',
  value: '',
  type: 'text',
  placeholder: '値を入力',
  style: {},
  error: '',
  as: 'input',
  isDecimal: false,
  regex: '',
  maxLength: -1, // unlimit
  onChange: () => {},
  isAuthenticated: false,
  isAuthenticatedError: false,
};

export default React.memo(FormInput);
