import { RANK, CONTENT_CATEGORY_NAME } from 'domain/fields';

const LINE_SPTAB_CHECK = `{label}を「スペース、タブ、改行」のみで登録することはできません。「スペース、タブ、改行」以外の文字列を入力してください。`;
const EMPTY_CHECK = `{label}を入力してください`;
const MAX_LENGTH_CHECK = `{label}は{length}字以下で入力してください。`;
const CONTROL_CHARACTER_CHECK = `制御文字を削除してください。`;
const NUM_CHECK = `優先順位は半角数字の整数で入力してください。`;

export const msgLineTagManagementCsvError = {
  REQUIRED_CHECK: EMPTY_CHECK,
  EMPTY_CHECK,
  CONTENT_CATEGORY_NAME_ACTIVE_CHECK: `登録されていない{label}がCSVに含まれているため、CSVから削除してください。<br/>※{label}の新規登録は管理画面から行ってください。`,
  DUPLICATE_CONTENT_CATEGORY_VALUE_CHECK: `{label}が重複しています。`,
  MAX_LENGTH_CHECK,
  NUM_CHECK,
  CONTROL_CHARACTER_CHECK,
  SPTAB_CHECK: LINE_SPTAB_CHECK,
};

export const contentCategoryCsvfields = {
  [CONTENT_CATEGORY_NAME]: {
    label: 'コンテンツカテゴリ',
  },
  [RANK]: {
    label: '優先順位',
  },
};
