// async actions
const REQUEST_START = 'ltv/settings/ad/REQUEST_START';
const REQUEST_SUCCEEDED = 'ltv/settings/ad/REQUEST_SUCCEEDED';
const REQUEST_FAILED = 'ltv/settings/ad/REQUEST_FAILED';
const FETCH = 'ltv/settings/ad/FETCH';
const FETCH_LIST = 'ltv/settings/ad/FETCH_LIST';
const UPDATE = 'ltv/settings/ad/UPDATE';
const DELETE = 'ltv/settings/ad/DELETE';
const TOGGLE_SELECT = 'ltv/settings/ad/TOGGLE_SELECT';
const SEARCH = 'ltv/settings/ad/SEARCH';
const UPLOAD = 'ltv/settings/ad/UPLOAD';

// sync actions
const FETCH_LIST_START = 'ltv/settings/ad/FETCH_LIST_START';
const FETCH_SUCCEEDED = 'ltv/settings/ad/FETCH_SUCCEEDED';
const FETCH_LIST_SUCCEEDED = 'ltv/settings/ad/FETCH_LIST_SUCCEEDED';
const UPDATE_SUCCEEDED = 'ltv/settings/ad/UPDATE_SUCCEEDED';
const DELETE_SUCCEEDED = 'ltv/settings/ad/DELETE_SUCCEEDED';
const CHANGE_SELECTED_ITEM = 'ltv/settings/ad/CHANGE_SELECTED_ITEM';
const UPDATE_CURRENT_ITEM_NUMBER = 'ltv/settings/ad/UPDATE_CURRENT_ITEM_NUMBER';
const SEARCH_SUCCEEDED = 'ltv/settings/ad/SEARCH_SUCCEEDED';
const SEARCH_START = 'ltv/settings/ad/SEARCH_START';
const UPLOAD_SUCCEEDED = 'ltv/settings/ad/UPLOAD_SUCCEEDED';
const UPDATE_PAGE = 'ltv/settings/ad/UPDATE_PAGE';
const UPDATE_SORT = 'ltv/settings/ad/UPDATE_SORT';
const SUBMIT_FAILED = 'ltv/settings/ad/SUBMIT_FAILED';

const CLEAR_API_RESULT = 'ltv/settings/ad/CLEAR_API_RESULT';

const GET_DETAIL = 'ltv/settings/ad/GET_DETAIL';
const SET_DETAIL = 'ltv/settings/ad/SET_DETAIL';

export default {
  REQUEST_START,
  REQUEST_SUCCEEDED,
  REQUEST_FAILED,
  FETCH,
  UPDATE,
  DELETE,
  TOGGLE_SELECT,
  FETCH_LIST,
  FETCH_LIST_START,
  FETCH_LIST_SUCCEEDED,
  FETCH_SUCCEEDED,
  UPDATE_SUCCEEDED,
  DELETE_SUCCEEDED,
  CHANGE_SELECTED_ITEM,
  UPDATE_CURRENT_ITEM_NUMBER,
  UPDATE_PAGE,
  UPDATE_SORT,
  SEARCH,
  SEARCH_START,
  SEARCH_SUCCEEDED,
  UPLOAD,
  UPLOAD_SUCCEEDED,
  SUBMIT_FAILED,
  CLEAR_API_RESULT,
  GET_DETAIL,
  SET_DETAIL,
};
