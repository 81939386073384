import { put, takeLatest, call, select, fork } from 'redux-saga/effects';
import sharedSagas from 'store/sharedSagas';

import { CONTENT_TYPE } from 'domain/consts';
import { LIMIT_PER_PAGE } from 'domain/page-management/consts';

import { communicationStatus, getFinalPage, HttpStatus } from 'services/utils';
import handleError from 'services/error/handleScopeError';
import pageManagementApi from 'services/api/PageManagementApi';

import loggerConstants from 'store/logger/constant';
import loggerActions from 'store/logger/actions';
import filterSelectors from 'store/filters/selectors';
import commonActions from 'store/common/actions';

import selectors from './selectors';
import types from './types';
import actions from './actions';

const { BAD_REQUEST } = HttpStatus;
const { LOADING } = communicationStatus;
const { LOG_LEVEL_ERROR } = loggerConstants;

function* errorHandler(err, action) {
  const { callback = () => {}, data = {} } = action.payload || {};
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [];
  switch (action.type) {
    case types.GET_LIST:
    case types.DELETE:
      yield put(actions.setErrorList(errors, scope));
      break;
    case types.SUBMIT_CSV:
    case types.UPLOAD_CSV_DELETE:
    case types.UPLOAD_CSV_UPDATE:
      yield put(actions.uploadCsvErrors(errors, scope));
      break;
    case types.GET_DETAIL:
    case types.UPDATE:
      yield put(actions.setErrorDetail(errors, scope));
      break;
    default:
      yield put(loggerActions.logError(errors, LOG_LEVEL_ERROR));
      break;
  }

  if (
    [
      types.GET_DETAIL,
      types.UPDATE,
      types.DELETE,
      types.SUBMIT_CSV,
      types.UPLOAD_CSV_DELETE,
      types.UPLOAD_CSV_UPDATE,
    ].includes(action.type)
  ) {
    callback({
      action: action.type,
      isError: true,
      errors,
      data,
      isNeedConfirm: action.type === types.UPLOAD_CSV_DELETE,
    });
  }
}

function* handleGetList() {
  yield put(actions.setStatusList(LOADING));
  const sort = yield select(selectors.getSortRequest);
  const { currentPage } = yield select(selectors.getPagination);

  const request = {
    sort,
    filters: yield select(filterSelectors.getForApi),
    limit: LIMIT_PER_PAGE,
    offset: (currentPage - 1) * LIMIT_PER_PAGE,
  };
  let {
    data: { data, metadata },
  } = yield call(pageManagementApi.getList, request);

  if (metadata.total > 0 && data.length < 1) {
    const finalPage = getFinalPage(metadata.total, LIMIT_PER_PAGE);
    const {
      data: { data: dataSecond, metadata: metaDataSecond },
    } = yield call(pageManagementApi.getList, {
      ...request,
      offset: (finalPage - 1) * LIMIT_PER_PAGE,
    });
    yield put(actions.updatePage(finalPage));
    data = dataSecond;
    metadata = metaDataSecond;
  }

  const objPageById = data.reduce(
    (prevItem, currentItem) => ({
      ...prevItem,
      [currentItem.page_id]: {
        pageTitle: currentItem.page_title,
        pageId: currentItem.page_id,
      },
    }),
    {}
  );
  yield put(
    actions.setDataList({
      lists: data,
      objPageById,
      metadata,
    })
  );
}

function* handleUploadCsvDelete(action) {
  const { file, callback } = action.payload;
  const { uploadUrl, filename } = yield call(
    pageManagementApi.getUploadUrl,
    CONTENT_TYPE.CSV
  );

  yield call(pageManagementApi.uploadFile, file, uploadUrl, CONTENT_TYPE.CSV);
  const response = yield call(pageManagementApi.confirmCsvDelete, filename);

  callback({
    isNeedConfirm: true,
    data: response.data.data,
    total: response.data.metadata.total,
    filenameUpload: filename,
  });
}

function* handleSubmitCsv(action) {
  const { filename, mode, callback } = action.payload;
  yield call(pageManagementApi.submitCsv, filename, mode);
  yield fork(handleGetList);
  callback({});
}

function* handleUploadCsvUpdate(action) {
  const { file, callback } = action.payload;
  const { uploadUrl, filename } = yield call(
    pageManagementApi.getUploadUrl,
    CONTENT_TYPE.CSV
  );

  yield call(pageManagementApi.uploadFile, file, uploadUrl, CONTENT_TYPE.CSV);
  yield fork(handleSubmitCsv, {
    action: types.SUBMIT_CSV,
    payload: { filename, mode: 'update', callback },
  });
}

function* handleDelete(action) {
  const { ids, callback } = action.payload;
  const pageIds = ids.map((id) => String(id).replace('#', ''));
  yield call(pageManagementApi.delete, pageIds);
  callback({ action: action.type });
}

function* handleGetDetail(action) {
  const { id } = action.payload;
  yield put(actions.setStatusDetail(LOADING));
  const pageId = id.replace('#', '');
  const {
    data: { data },
  } = yield call(pageManagementApi.getDetail, pageId);
  yield put(actions.setDataDetail(data));
}

function* handleUpdate(action) {
  const { callback, id, data: dataRequest } = action.payload;
  yield put(actions.setStatusDetail(LOADING));
  const pageId = String(id).replace('#', '');
  yield call(pageManagementApi.update, pageId, dataRequest);
  callback({ action: action.type });
}

function* handleDownloadCsv() {
  try {
    const [sort, filters] = [
      yield select(selectors.getSortRequest),
      yield select(filterSelectors.getForApi),
    ];
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    yield call(pageManagementApi.downloadCsv, sort, filters);
    yield put(commonActions.setDownloadNotify('CSVファイルを作成しました'));
  } catch (error) {
    yield put(loggerActions.logDownloadCsvError());
    yield put(commonActions.setDownloadNotify(''));
  }
}

function* handleExportCsvDeleted() {
  yield put(commonActions.setDownloadNotify('CSVファイルを作成しています...'));
  yield call(pageManagementApi.exportCsvDeletedPages);
  yield put(commonActions.setDownloadNotify('CSVファイルを作成しました'));
}

export default function* pageManagementWatchView() {
  yield takeLatest(
    types.GET_LIST,
    sharedSagas.safe(errorHandler, handleGetList)
  );
  yield takeLatest(
    types.GET_DETAIL,
    sharedSagas.safe(errorHandler, handleGetDetail)
  );
  yield takeLatest(types.UPDATE, sharedSagas.safe(errorHandler, handleUpdate));
  yield takeLatest(types.DELETE, sharedSagas.safe(errorHandler, handleDelete));
  yield takeLatest(
    types.DOWNLOAD_CSV,
    sharedSagas.safe(errorHandler, handleDownloadCsv)
  );
  yield takeLatest(
    types.UPLOAD_CSV_DELETE,
    sharedSagas.safe(errorHandler, handleUploadCsvDelete)
  );
  yield takeLatest(
    types.UPLOAD_CSV_UPDATE,
    sharedSagas.safe(errorHandler, handleUploadCsvUpdate)
  );
  yield takeLatest(
    types.SUBMIT_CSV,
    sharedSagas.safe(errorHandler, handleSubmitCsv)
  );
  yield takeLatest(
    types.EXPORT_CSV_DELETED_PAGES,
    sharedSagas.safe(errorHandler, handleExportCsvDeleted)
  );
}
