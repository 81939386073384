import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import 'views/molecules/modal/transitionmodal.scss';
import 'views/molecules/modal/transitionmodal-new.scss';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import imgTransition from 'assets/images/transition_img.png';
import { FUNCTION_COMPATIBLE_IN_NEW_SYSTEM_LINK, OLD_EBIS_HOME_SITE, OLD_EBIS_FEEDBUCK_GOOGLE_FORM } from 'services/routes/constants';

const TransitionModal = ({
  headerText,
  imgSrc,
  externalLink,
  onHide,
  onHideHome,
  onHideListing,
  show,
  isNewTab,
  settingDisabled,
  settingDisabledHome,
}) => {
  const [isRememberSettings, toggleRememberCheckbox] = useState(false);
  const [isRememberSettingsHome, toggleRememberCheckboxHome] = useState(false);

  const externalLinkType = () => {
    if (externalLink != null && externalLink.length > 0) {
      let pattern = new RegExp(`^${OLD_EBIS_HOME_SITE}$`);
      let matches = externalLink.match(pattern);
      if (matches != null && matches.length > 0) {
        return "home";
      }

      pattern = new RegExp(`${process.env.REACT_APP_HOTEI_HOST}/setup/listing/`);
      matches = externalLink.match(pattern);
      if (matches != null && matches.length > 0) {
        return "listing";
      }
    }
    return "other";
  }

  const isDisabledModal = () => {
    if ((externalLinkType() === "other" && settingDisabled) || (externalLinkType() === "home" && settingDisabledHome)) {
      return true;
    }
    return false;
  }
  
  // If disabled modal warning, auto redirect in new tab
  if (isDisabledModal()) {
    const openInNewTab = isNewTab ? '_blank' : '_self';
    window.open(externalLink, openInNewTab);
    if (externalLinkType() === "home") {
      onHideHome(isDisabledModal());
    } else if (externalLinkType() === "other") {
      onHide(isDisabledModal());
    }
  }

  const handleToggleRemember = () => {
    if (externalLinkType() === "home") {
      toggleRememberCheckboxHome(!isRememberSettingsHome);
    } else if (externalLinkType() === "other") {
      toggleRememberCheckbox(!isRememberSettings);
    } 
  };

  const handleSubmitSetting = () => {
    const openInNewTab = isNewTab ? '_blank' : '_self';
    window.open(externalLink, openInNewTab);
    if (externalLinkType() === "home") {
      onHideHome(isRememberSettingsHome);
    } else if (externalLinkType() === "listing") {
      onHideListing();
    } else if (externalLinkType() === "other") {
      onHide(isRememberSettings);
    }
  };

  return (
    <>
      { externalLinkType() === "other" && (
        <Modal
          show={show && !isDisabledModal()}
          onHide={onHide}
          centered
          size="w600"
          className="transition-modal"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>旧バージョンへ切り替えます</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{headerText}</div>
            <span>
              ※新バージョンでは一部機能が未対応となっております。新旧対応画面については
              <a
                href={FUNCTION_COMPATIBLE_IN_NEW_SYSTEM_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら
              </a>
              をご確認ください。
            </span>
            <div className="transition-modal__image d-flex align-items-center justify-content-center">
              <img src={imgSrc} alt="旧バージョンへ切り替えます" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="mr-auto">
              <EbisCheckbox
                text="次回以降このモーダルを表示しない"
                name="temp"
                checked={isRememberSettings}
                changeSelectBoxValue={handleToggleRemember}
                disabled={false}
              />
            </div>
            <Button onClick={() => onHide(false)} variant="link" size="sm">
              キャンセル
            </Button>
            {/* <a href={externalLink} target={isNewTab ? '_blank' : '_self'}> */}
            <Button onClick={handleSubmitSetting} variant="secondary" size="sm">
              OK
            </Button>
            {/* </a> */}
          </Modal.Footer>
        </Modal>
      )}
      { (externalLinkType() === "home") && (
        <Modal
          show={show && !isDisabledModal()}
          onHideHome={onHideHome}
          centered
          size="w600"
          className="transition-modal-new"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>旧バージョンの画面に移動します</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>※旧バージョンは提供終了予定となっております。</span>          
            <div>
              新バージョンとの機能比較表や、提供終了機能については
              <a
                href={FUNCTION_COMPATIBLE_IN_NEW_SYSTEM_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら
              </a>
              をご確認ください。
            </div>
            <div>
              新画面の機能についてぜひ
              <a
                href={OLD_EBIS_FEEDBUCK_GOOGLE_FORM}
                target="_blank"
                rel="noopener noreferrer"
              >
                フィードバック
              </a>
              をお寄せください。
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="mr-auto">
              <EbisCheckbox
                text="次回以降このモーダルを表示しない"
                name="temp"
                checked={isRememberSettingsHome}
                changeSelectBoxValue={handleToggleRemember}
                disabled={false}
              />
            </div>
            <Button onClick={() => onHideHome(false)} variant="link" size="sm">
              キャンセル
            </Button>
            <Button onClick={handleSubmitSetting} variant="secondary" size="sm">
              旧画面へ移動
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      { (externalLinkType() === "listing") && (
        <Modal
          show={show}
          centered
          size="w600"
          className="transition-modal-new"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>旧バージョンの画面に移動します</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>※「リスティング連携機能」は提供終了予定となっております。</span>
            <div>
              新バージョンとの機能比較表や、提供終了機能については
              <a
                href={FUNCTION_COMPATIBLE_IN_NEW_SYSTEM_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら
              </a>
              をご確認ください。
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => onHideListing()} variant="link" size="sm">
              キャンセル
            </Button>
            <Button onClick={handleSubmitSetting} variant="secondary" size="sm">
              旧画面へ移動
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

TransitionModal.defaultProps = {
  headerText: `新バージョンをご利用いただく場合は、管理画面左上の「新しいアドエビスを試す」をクリックし切り替えてください。`,
  imgSrc: imgTransition,
  externalLink: '',
  show: false,
  isNewTab: false,
  settingDisabled: false,
  settingDisabledHome: false,
};

TransitionModal.propTypes = {
  headerText: PropTypes.string,
  imgSrc: PropTypes.string,
  externalLink: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onHideHome: PropTypes.func.isRequired,
  onHideListing: PropTypes.func.isRequired,
  show: PropTypes.bool,
  isNewTab: PropTypes.bool,
  settingDisabled: PropTypes.bool,
  settingDisabledHome: PropTypes.bool,
};

export default TransitionModal;
