import { useSelector, shallowEqual } from 'react-redux';
import {
  getPermissions,
  getAgentFlag,
  getUserId,
  getAccountId,
} from 'store/auth/selectors';
import {
  resolve,
  anyOf,
  allOf,
  allBy,
} from 'domain/permissions/permissionTypes';

const useCheckPermissions = () => {
  const userPermissions = useSelector(getPermissions, shallowEqual);

  const checkPermissions = (resolveType, requiredPermissions) => {
    let type;
    switch (resolveType) {
      case 'anyOf':
        type = anyOf(userPermissions);
        break;
      case 'allOf':
        type = allOf(userPermissions);
        break;
      case 'allBy':
        type = allBy(userPermissions);
        break;
      default:
        type = anyOf(userPermissions);
    }

    return resolve(type, requiredPermissions);
  };
  return { userPermissions, checkPermissions };
};

export const useGetUserInfo = () => {
  return [
    useSelector(getAgentFlag, shallowEqual),
    useSelector(getUserId, shallowEqual),
    useSelector(getAccountId, shallowEqual),
  ];
};

export default useCheckPermissions;
