import { FUNC_CODE_DASHBOARD_ANALYSIS } from 'domain/settings/display-items';
import helper from 'store/helper';
import types from './types';

export const initialState = {
  items: {
    /* : {
      channel: true,
      category: false,
      ...
    }, */
  },
  sorting: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null, // string | null,
  funcId: FUNC_CODE_DASHBOARD_ANALYSIS,
  landing_page_analyze: {},
};

const requestStart = (state) => {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: 'failed',
    error,
  };
};

const fetchSucceeded = (state, action) => {
  const { funcId, items, sorting = [], override = false } = action.payload;

  let displayItems;
  if (override) {
    displayItems = items;
  } else {
    displayItems = { ...state.items, ...items };
  }

  return {
    ...state,
    items: displayItems,
    sorting,
    status: 'succeeded',
    funcId: funcId || state.funcId,
  };
};

const createSucceeded = (state, action) => {
  const { items, sorting = [] } = action.payload;
  return {
    ...state,
    items: {
      ...state.items,
      ...items,
    },
    sorting,
    status: 'succeeded',
  };
};

const fetchLPAnalyzeSucceed = (state, action) => {
  const { items } = action.payload;
  return {
    ...state,
    landing_page_analyze: items,
  };
};

const DisplayItemsReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_SUCCEEDED]: fetchSucceeded,
  [types.CREATE_SUCCEEDED]: createSucceeded,
  [types.FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE_SUCCEED]: fetchLPAnalyzeSucceed,
});

export default DisplayItemsReducer;
