/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import jaLocale from 'moment/locale/ja';
import { Button, Col, Row } from 'react-bootstrap';
import { DayPickerRangeController } from 'react-dates/esm';
import { START_DATE } from 'react-dates/constants';
import {
  CALENDAR_DAY_FORMAT,
  CALENDAR_INPUT_ALLOWED_FORMAT,
} from 'domain/consts';
import { getCurrentPeriodDates } from 'services/utils';
import PropTypes, {
  func,
  number,
  object,
  objectOf,
  any,
  shape,
  string,
  bool,
} from 'prop-types';
import classNames from 'classnames';
import merge from 'lodash/merge';
import OutsideClickHandler from 'react-outside-click-handler';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';

import './simpleDateRangePicker.scss';

function CalendarMonth(props) {
  const { month } = props;
  return (
    <div
      style={{
        justifyContent: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'black',
        marginBottom: '5px',
        lineHeight: '24px',
      }}
    >
      <span>
        {month.format('YYYY')}/{month.format('MM')}
      </span>
    </div>
  );
}

CalendarMonth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  month: object.isRequired,
};

function EbisSimpleDateRangePicker(props) {
  moment.locale('ja', jaLocale);
  const {
    period,
    onActive,
    active = false,
    drop,
    maxDate,
    minDate,
    maxDuration,
    disabled,
    error,
  } = props;

  const errorTooltipRef = useRef();

  const [focus, setFocus] = useState(START_DATE);
  const [showModal, setShowModal] = useState(false);

  const [renderKey, setRenderKey] = useState(new Date().getTime());

  const initialPeriod = getCurrentPeriodDates(period);

  const [currentMonth, setCurrentMonth] = useState(moment(initialPeriod));

  const [initialMonth, setInitialMonth] = useState(moment(initialPeriod));

  const [appliedState, setAppliedState] = useState({
    current: {
      startDate: initialPeriod.start
        ? initialPeriod.start.clone().startOf('day')
        : null,
      endDate: initialPeriod.end
        ? initialPeriod.end.clone().startOf('day')
        : null,
    },
  });

  const [tempState, setTempState] = useState(appliedState);

  const [customInput, setCustomInput] = useState({
    current: {
      startDate: tempState.current.startDate?.format(CALENDAR_DAY_FORMAT),
      endDate: tempState.current.endDate?.format(CALENDAR_DAY_FORMAT),
    },
  });

  const [firstToggle, setFirstToggle] = useState(true);

  const formatInputValue = ({ startDate, endDate }) => {
    const start = startDate ? startDate.format(CALENDAR_DAY_FORMAT) : '';
    const end = endDate ? endDate.format(CALENDAR_DAY_FORMAT) : '';
    return `${start} ～ ${end}`;
  };

  const inputValue =
    appliedState.current.startDate && appliedState.current.endDate
      ? formatInputValue({
          startDate: appliedState.current.startDate,
          endDate: appliedState.current.endDate,
        })
      : '指定なし';

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const datepickerRef = useRef(null);

  const updateInitialMonth = (date) => {
    if (
      currentMonth.year() !== date.year() ||
      (currentMonth.month() !== date.month() &&
        currentMonth.month() + 1 !== date.month())
    ) {
      setInitialMonth(date.clone());
      setCurrentMonth(date.clone());
      setRenderKey(new Date().getTime());
    } else if (
      initialMonth.year() !== date.year() ||
      (initialMonth.month() !== date.month() &&
        initialMonth.month() + 1 !== date.month())
    ) {
      setInitialMonth(currentMonth.clone());
    }
  };

  const resetStates = () => {
    setTempState({
      current: appliedState.current,
    });
    setCustomInput({
      current: {
        endDate: appliedState.current.endDate?.format(CALENDAR_DAY_FORMAT),
        startDate: appliedState.current.startDate?.format(CALENDAR_DAY_FORMAT),
      },
    });
    updateInitialMonth(appliedState.current.startDate.clone());
  };

  const convertToValidatedDate = (startDate, endDate) => {
    let start = startDate;
    let end = endDate;
    // const twoYearsAgo = moment().subtract(2, 'year');
    if (minDate && start.isBefore(minDate)) {
      start = minDate.clone();
    }
    if (maxDate && start.isAfter(maxDate)) {
      start = maxDate.clone();
    }
    if (maxDate && end.isAfter(maxDate)) {
      end = maxDate.clone();
    }
    if (end.isBefore(start)) {
      end = start.clone();
    }

    if (maxDuration) {
      const {
        timeUnit: maxDurationTimeUnit,
        value: maxDurationValue,
      } = maxDuration;
      if (
        end
          .clone()
          .subtract(maxDurationValue, maxDurationTimeUnit)
          .isSameOrAfter(start, 'day')
      ) {
        end = start
          .clone()
          .add(maxDurationValue, maxDurationTimeUnit)
          .subtract(1, 'day');
      }
    }

    return {
      start,
      end,
    };
  };

  const changeDate = ({ startDate, endDate }, isCurrent = true) => {
    const { start, end } = convertToValidatedDate(startDate, endDate);
    if (isCurrent) {
      setTempState((prev) => ({
        ...prev,
        current: {
          ...prev.current,
          startDate: start,
          endDate: end,
        },
      }));
      setCustomInput({
        ...customInput,
        current: {
          startDate: start?.format(CALENDAR_DAY_FORMAT),
          endDate: end?.format(CALENDAR_DAY_FORMAT),
        },
      });
      updateInitialMonth(start);
    }
  };

  const onDatesChange = ({ startDate, endDate }) => {
    let newStartDate = startDate;
    let newEndDate = endDate;
    if (!endDate) {
      newEndDate = startDate;
    } else if (!startDate) {
      newStartDate = endDate;
    } else if (
      startDate.isSame(tempState.current.startDate, 'day') &&
      endDate.isSame(tempState.current.endDate, 'day') &&
      !startDate.isSame(endDate, 'day')
    ) {
      newEndDate = startDate;
    }
    changeDate({ startDate: newStartDate, endDate: newEndDate }, true);
  };

  const onInputDateChange = (value, isStart) => {
    const date = moment(value, CALENDAR_DAY_FORMAT, true);
    if (date.isValid()) {
      if (isStart) {
        changeDate(
          { startDate: date, endDate: tempState.current.endDate },
          true
        );
      } else {
        changeDate(
          { startDate: tempState.current.startDate, endDate: date },
          true
        );
      }
    } else {
      // Change input
      const newCustomInput = {};
      if (isStart) {
        newCustomInput.current = { startDate: value };
      } else {
        newCustomInput.current = { endDate: value };
      }
      setCustomInput({
        // Since merge return the same object like Object.assign, must destructure it to trigger render
        ...merge(customInput, newCustomInput),
      });
      // Disable ok button
    }
  };

  const tryConvertInputDate = (value, isStart) => {
    if (moment(value, CALENDAR_DAY_FORMAT, true).isValid()) return;

    let newValue = value;
    const fallbackDate = moment(value, CALENDAR_INPUT_ALLOWED_FORMAT, true);
    if (fallbackDate.isValid()) {
      // set value as min date
      newValue = fallbackDate.format(CALENDAR_DAY_FORMAT);
    } else if (isStart) {
      // set value as previous value
      newValue = tempState.current.startDate;
    } else {
      // set value as previous value
      newValue = tempState.current.endDate;
    }

    // save date valid
    onInputDateChange(newValue, isStart);
  };

  const focusChange = (focusedInput) => {
    setFocus(!focusedInput ? START_DATE : focusedInput);
  };

  const onPrevMonthClick = (date) => {
    setCurrentMonth(moment([date.year(), date.month()]));
  };

  const onNextMonthClick = (date) => {
    setCurrentMonth(moment([date.year(), date.month()]));
  };

  const cloneIfNE = (current, existing) => {
    if (!current || !existing) return current;
    if (
      current &&
      existing &&
      current.format(CALENDAR_DAY_FORMAT) !==
        existing.format(CALENDAR_DAY_FORMAT)
    ) {
      return current.clone();
    }
    return current;
  };

  const onOkClick = () => {
    if (
      tempState.current.endDate === null ||
      tempState.current.endDate.isBefore(tempState.current.startDate)
    ) {
      tempState.current.endDate = tempState.current.startDate;
    }

    setAppliedState({
      ...tempState,
    });

    setShowModal(false);
    const { onChange } = props;

    const [currentStart, currentEnd] = [
      cloneIfNE(tempState.current.startDate, appliedState.current.startDate),
      cloneIfNE(tempState.current.endDate, appliedState.current.endDate),
    ];

    onChange({
      period: {
        start: currentStart,
        end: currentEnd,
      },
    });

    if (
      currentMonth.year() !== tempState.current.startDate.year() ||
      currentMonth.month() !== tempState.current.startDate.month()
    ) {
      setRenderKey(new Date().getTime());
    }
  };

  const onCancelClick = () => {
    resetStates();
    setShowModal(false);
  };

  const PrevIcon = () => (
    <span className="icon-hover">
      <i className="fas fa-chevron-left" aria-hidden="true" />
    </span>
  );

  const NextIcon = () => (
    <span className="icon-hover">
      <i className="fas fa-chevron-right" aria-hidden="true" />
    </span>
  );

  const toggleCalendar = (e) => {
    setShowModal(!showModal);
    if (!showModal) {
      onActive({ target: 'EbisDateRangePicker' });
    }

    // Since offsetHeight / offsetWidth of datepicker was calculated during Suspense, thus it has no offsetHeight/offsetWidth
    // Force underlying date picker component re-calculate offsetHeight / offsetWidth
    //  of captionRef element on first time it is clicked
    if (firstToggle) {
      setFirstToggle(false);
      // Trigger a new render
      setRenderKey(new Date().getTime());
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if (!active) {
      setShowModal(false);
    }
  }, [active]);

  const menuEl = useRef(null);

  useEffect(() => {
    setAppliedState((prev) => ({
      ...prev,
      current: {
        startDate: initialPeriod.start ? initialPeriod.start.clone() : null,
        endDate: initialPeriod.end ? initialPeriod.end.clone() : null,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  // Whenever appliedState changes, should update tempState
  useEffect(() => {
    resetStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedState]);

  const modelClass = classNames({
    'date-range-picker--simple__modal': true,
    'date-range-picker--drop-right': drop === 'right',
  });

  const wrappingClassNames = classNames({
    'date-range-picker--simple': true,
    'align-self-center': true,
    disabled,
  });

  const simpleInputClassNames = classNames({
    'date-range-picker--simple__input-container': true,
    'date-range-picker--simple__error': !!error,
    show: showModal,
  });

  return (
    <div className={wrappingClassNames}>
      <OutsideClickHandler
        onOutsideClick={() => {
          resetStates();
          setShowModal(false);
        }}
      >
        <div className="date-range-picker__info" ref={errorTooltipRef}>
          <ErrorTooltipWrapper
            isError={!!error}
            errorMess={error}
            ref={errorTooltipRef}
          >
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
              className={simpleInputClassNames}
              onClick={(e) => toggleCalendar(e)}
            >
              <span className="date-range-picker--simple__icon">
                <i className="fal fa-calendar-alt" />
              </span>
              <input
                type="text"
                value={inputValue}
                className="date-range-picker--simple__input"
                readOnly
              />
            </div>
          </ErrorTooltipWrapper>
        </div>
        <div
          ref={menuEl}
          className={modelClass}
          style={
            showModal
              ? {
                  visibility: 'visible',
                  overflow: '',
                  height: '',
                }
              : {
                  visibility: 'hidden',
                  overflow: 'hidden',
                  height: '0px',
                }
          }
        >
          <div className="main-panel" ref={datepickerRef}>
            <DayPickerRangeController
              key={renderKey}
              startDateId="startDateId"
              startDate={tempState.current.startDate}
              endDate={tempState.current.endDate}
              endDateId="endDateId"
              numberOfMonths={2}
              onDatesChange={onDatesChange}
              onFocusChange={focusChange}
              focusedInput={focus}
              monthFormat="YYYY/MM"
              showInputs
              hideKeyboardShortcutsPanel
              noBorder
              onPrevMonthClick={(month) => onPrevMonthClick(month)}
              onNextMonthClick={(month) => onNextMonthClick(month)}
              initialVisibleMonth={() => initialMonth}
              // navPrev={<PrevIcon />}
              // navNext={<NextIcon />}
              maxDate={(maxDate && maxDate.clone().add(1, 'day')) || undefined}
              minDate={minDate}
              firstDayOfWeek={1}
              daySize={26}
              minimumNights={0}
              isOutsideRange={(d) =>
                (minDate && !d.isBefore(minDate, 'day')) ||
                (maxDate && d.isAfter(maxDate))
              }
              renderMonthElement={(monthProps) => (
                <CalendarMonth month={monthProps.month} />
              )}
              transitionDuration={0}
            />

            <Row className="drp-buttons">
              <Col className="drp-inputed" md={7}>
                <div className="input-date">
                  期間：
                  <input
                    type="text"
                    placeholder="-"
                    value={customInput.current.startDate}
                    onChange={(e) => onInputDateChange(e.target.value, true)}
                    onBlur={(e) => tryConvertInputDate(e.target.value, true)}
                  />
                  <span>～</span>
                  <input
                    type="text"
                    placeholder="-"
                    value={customInput.current.endDate}
                    onChange={(e) => onInputDateChange(e.target.value, false)}
                    onBlur={(e) => tryConvertInputDate(e.target.value, false)}
                  />
                </div>
              </Col>
              <Col className="btn-control" md={5}>
                <Button variant="link" size="sm" onClick={onCancelClick}>
                  キャンセル
                </Button>
                <Button variant="secondary" size="sm" onClick={onOkClick}>
                  適用
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

EbisSimpleDateRangePicker.propTypes = {
  /**
   * Handler when dates are changed.
   * Args: {
   *  current: {startDate:moment, endDate:moment},
   * }
   */
  onChange: func,
  onActive: PropTypes.func,
  active: PropTypes.bool,
  period: any,
  drop: string,
  maxDuration: objectOf(shape({ timeUnit: any, value: number })),
  maxDate: any,
  minDate: any,
  disabled: bool,
  error: string,
};

EbisSimpleDateRangePicker.defaultProps = {
  onChange: () => {},
  onActive: () => {},
  active: false,
  period: {
    start: moment().subtract(1, 'day'),
    end: moment().subtract(1, 'day'),
  },
  drop: 'left',
  maxDuration: null,
  maxDate: null,
  minDate: null,
  disabled: false,
  error: '',
};

export default EbisSimpleDateRangePicker;
