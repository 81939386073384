import types from 'store/cost-allocation/types';

const getFullData = (data) => ({
  type: types.GET_FULL_DATA,
  payload: { data },
});

const getData = (data) => ({
  type: types.GET_DATA,
  payload: { data },
});

const getSuccess = (data) => ({
  type: types.GET_SUCCESS,
  payload: { data },
});

const getError = () => ({
  type: types.GET_ERROR,
});

const getFullDataSuccess = (data) => ({
  type: types.GET_FULL_DATA_SUCCESS,
  payload: { data },
});

const getFullDataError = () => ({
  type: types.GET_FULL_DATA_ERROR,
});

const getBookmark = (data) => ({
  type: types.GET_BOOKMARK,
  payload: data,
});

const updateBookmark = (data) => ({
  type: types.UPDATE_BOOKMARK,
  payload: data,
});

const getBookmarkSuccess = (data) => ({
  type: types.GET_BOOKMARK_SUCCESS,
  payload: data,
});

const getBookmarkError = (data, type) => ({
  type: types.GET_BOOKMARK_ERROR,
  payload: { data, type },
});

const updateBookmarkSuccess = (params) => ({
  type: types.UPDATE_BOOKMARK_SUCCESS,
  payload: params,
});

const updateBookmarkError = (data, type) => ({
  type: types.UPDATE_BOOKMARK_ERROR,
  payload: { data, type },
});

const saveYaxis = () => ({
  type: types.SAVE_Y_YAXIS,
});

const saveYaxisSuccess = (data) => ({
  type: types.SAVE_Y_AXIS_SUCCESS,
  payload: data,
});

const saveYaxisError = (error) => ({
  type: types.SAVE_Y_AXIS_ERROR,
  payload: error,
});

const changePage = (payload) => ({
  type: types.CHANGE_PAGE,
  payload,
});

const changeSelectedAxisLine = (selectedValue) => ({
  type: types.CHANGE_SELECTED_YAXISLINE,
  payload: selectedValue,
});

const selectRow = (dimensionKey, redrawChart = false) => ({
  type: types.SELECT_ROW,
  payload: { dimensionKey, redrawChart },
});

const deselectRow = (dimensionKey, redrawChart = false) => ({
  type: types.DESELECT_ROW,
  payload: { dimensionKey, redrawChart },
});

const clearSimulation = () => ({
  type: types.CLEAR_SIMULATION_DATA,
});

const applySimulation = (data) => ({
  type: types.SAVE_SIMULATION_DATA,
  payload: data,
});
const saveResultSimulation = (data) => ({
  type: types.SAVE_RESULT_SIMULATION_DATA,
  payload: data,
});

const setTableColumnSort = (sorts) => ({
  type: types.SET_TABLE_COLUMN_SORT,
  payload: sorts,
});

const setTableCurrentPage = (page) => ({
  type: types.SET_TABLE_CURRENT_PAGE,
  payload: { page },
});

export default {
  getData,
  getFullData,
  getFullDataSuccess,
  getFullDataError,
  getBookmark,
  getBookmarkSuccess,
  getBookmarkError,
  updateBookmark,
  updateBookmarkSuccess,
  updateBookmarkError,
  getSuccess,
  getError,
  changePage,
  selectRow,
  deselectRow,
  changeSelectedAxisLine,
  saveYaxis,
  saveYaxisError,
  saveYaxisSuccess,
  clearSimulation,
  applySimulation,
  saveResultSimulation,
  setTableColumnSort,
  setTableCurrentPage,
};
