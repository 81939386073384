import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  AD_CV_COST_MODE,
  REJECT_HOST,
  SESSION_EXPIRE,
  EXPIREDAYS,
  CONV_DUPLICATION_FLAG,
  QUERY_PARAMS,
  DIRECTORY_INDEX,
  ALERT_AUTH_IDS,
  CROSS_DEVICE_MODE,
  CROSS_DEVICE_MODE_LABELS,
  CROSS_DEVICE_MODE_AI,
  AD_CV_COST_MODE_LABELS,
  OPTION_AD_CV_COST_MODE_NONE,
  INFO_FORM_MEASUREMENT_SETTING as FORM,
  MEASUREMENT_MODAL_SETTING_IDS as MODAL_IDS,
  CLICK_ID_SETTING,
} from 'domain/system-setting/consts';
import systemSettingActions from 'store/system-setting/actions';
import systemSettingSelectors from 'store/system-setting/selectors';
import { getAgentFlag } from 'store/auth/selectors';
import useCheckPermissionSystemSetting from 'services/custom-hooks/useCheckPermissionSystemSetting';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import {
  getClickIdSettingMediaByKey,
  renderTextByStatus,
} from 'services/system-setting/systemSettingServices';
import { get, isEmpty } from 'lodash';
import MeasurementSettingModal from './MeasurementSettingModal';

function MeasurementSettingContainer() {
  const { isLoading, data } = useSelector(
    systemSettingSelectors.getStatesMeasurementSetting
  );
  const {
    isAllowedSettingMeasurementOption,
    isAllowedSettingCrossDevice,
  } = useCheckPermissionSystemSetting();
  const { isCapiSingleContract } = useCheckPermissionCapi();

  const isUserAgent = useSelector(getAgentFlag, shallowEqual);

  const dispatch = useDispatch();
  const [currentModalId, setCurrentModalId] = useState(null);

  const onOpenSettingModal = (modalId) => () => setCurrentModalId(modalId);
  const onHideSettingModal = () => setCurrentModalId(null);

  useEffect(() => {
    dispatch(systemSettingActions.getMeasurementSetting());
  }, [dispatch]);

  return (
    <DataSyncLoader isLoading={isLoading}>
      <div className="measurement-setting">
        <div className="measurement-setting__form">
          <FormGroup
            label={FORM[REJECT_HOST].label}
            tooltip={FORM[REJECT_HOST].tooltip}
          >
            <div>
              {data?.[REJECT_HOST] ? (
                <span className="measurement-setting__pre-wrap">
                  {data?.[REJECT_HOST].replaceAll(',', '\r\n')}
                </span>
              ) : (
                ''
              )}
            </div>
          </FormGroup>
          {!isCapiSingleContract && (
            <FormGroup
              label={FORM[SESSION_EXPIRE].label}
              tooltip={FORM[SESSION_EXPIRE].tooltip}
              required
            >
              <div>{data?.[SESSION_EXPIRE] || 0} 分</div>
            </FormGroup>
          )}
          <FormGroup
            label={FORM[EXPIREDAYS].label}
            tooltip={FORM[EXPIREDAYS].tooltip}
            required
          >
            <div>{data?.[EXPIREDAYS] || 0} 日</div>
          </FormGroup>
          <FormGroup
            label={FORM[CONV_DUPLICATION_FLAG].label}
            tooltip={FORM[CONV_DUPLICATION_FLAG].tooltip}
          >
            <div>
              {data?.[CONV_DUPLICATION_FLAG] ? '取得しない' : '取得する'}
            </div>
          </FormGroup>
          <Button
            size="sm"
            variant="secondary"
            onClick={onOpenSettingModal(MODAL_IDS.CONFIG)}
            disabled={isLoading}
          >
            編集
          </Button>
        </div>
        {!isCapiSingleContract && (
          <div className="measurement-setting__form">
            {isAllowedSettingMeasurementOption && (
              <>
                <FormGroup label={FORM[QUERY_PARAMS].label}>
                  {data?.[QUERY_PARAMS] ? (
                    <span className="measurement-setting__pre-wrap">
                      {data[QUERY_PARAMS].replaceAll(',', '\r\n')}
                    </span>
                  ) : (
                    <></>
                  )}
                </FormGroup>
                <FormGroup label={FORM[DIRECTORY_INDEX].label} required>
                  {data?.[DIRECTORY_INDEX] ? (
                    <span className="measurement-setting__pre-wrap">
                      {data[DIRECTORY_INDEX].replaceAll(',', '\r\n')}
                    </span>
                  ) : (
                    <></>
                  )}
                </FormGroup>
              </>
            )}
            <FormGroup
              label={FORM[ALERT_AUTH_IDS].label}
              tooltip={FORM[ALERT_AUTH_IDS].tooltip}
            >
              <div>
                {data?.userIds ? (
                  data.userIds.map((user) => <div>{user}</div>)
                ) : (
                  <></>
                )}
              </div>
            </FormGroup>
            <Button
              size="sm"
              variant="secondary"
              onClick={onOpenSettingModal(MODAL_IDS.LOG)}
              disabled={isLoading}
            >
              編集
            </Button>
          </div>
        )}
        {isAllowedSettingCrossDevice && (
          <div className="measurement-setting__form">
            <FormGroup label={FORM[CROSS_DEVICE_MODE].label}>
              <div>
                {CROSS_DEVICE_MODE_LABELS?.[data?.[CROSS_DEVICE_MODE]] ||
                  CROSS_DEVICE_MODE_LABELS[CROSS_DEVICE_MODE_AI]}
              </div>
            </FormGroup>
            <Button
              size="sm"
              variant="secondary"
              onClick={onOpenSettingModal(MODAL_IDS.CROSS_DEVICE_MODE)}
              disabled={isLoading}
            >
              編集
            </Button>
          </div>
        )}
        {!isCapiSingleContract && (
          <div className="measurement-setting__form">
            <FormGroup label={FORM[AD_CV_COST_MODE].label}>
              <div>
                {AD_CV_COST_MODE_LABELS?.[data?.[AD_CV_COST_MODE]] ||
                  AD_CV_COST_MODE_LABELS[OPTION_AD_CV_COST_MODE_NONE]}
              </div>
            </FormGroup>
            <Button
              size="sm"
              variant="secondary"
              onClick={onOpenSettingModal(MODAL_IDS.AD_CV_COST_MODE)}
              disabled={isLoading}
            >
              編集
            </Button>
          </div>
        )}
        {!isCapiSingleContract && isAllowedSettingMeasurementOption && !isUserAgent && (
          <div className="measurement-setting__form">
            <FormGroup
              label={FORM[CLICK_ID_SETTING].label}
              tooltip={FORM[CLICK_ID_SETTING].tooltip}
            >
              <div>
                {get(data.click_id_setting, 'enable_flag', false)
                  ? '取得する'
                  : '取得しない'}
              </div>
              {!isEmpty(get(data.click_id_setting, 'medium_ids', [])) && (
                <div>
                  媒体：
                  {getClickIdSettingMediaByKey(
                    get(data.click_id_setting, 'medium_ids', [])
                  )}
                </div>
              )}
            </FormGroup>
            <Button
              size="sm"
              variant="secondary"
              onClick={onOpenSettingModal(MODAL_IDS.CLICK_ID_SETTING)}
              disabled={isLoading}
            >
              編集
            </Button>
          </div>
        )}
        {currentModalId && (
          <MeasurementSettingModal
            isShow={!!currentModalId}
            onHide={onHideSettingModal}
            isLoading={false}
            modalId={currentModalId}
            data={data}
            isAllowedSettingMeasurementOption={
              isAllowedSettingMeasurementOption
            }
            isCapiSingleContract={isCapiSingleContract}
          />
        )}
      </div>
    </DataSyncLoader>
  );
}

export default MeasurementSettingContainer;
