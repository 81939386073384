import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { oneOfType, bool, node, string, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import FormGroup from 'views/molecules/FormGroup';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';

function FormGroupTextarea(props) {
  const {
    required,
    label,
    note,
    tooltip,
    name,
    value,
    placeholder,
    error,
    onChange,
  } = props;
  const isError = !isEmpty(error);

  const ref = useRef();

  const handleValueChanged = (e) => {
    const { value: newValue } = e.target;
    onChange(name, newValue);
  };

  return (
    <FormGroup required={required} label={label} note={note} tooltip={tooltip}>
      <ErrorTooltipWrapper isError={isError} errorMess={error} ref={ref}>
        <Form.Control
          size="sm"
          as="textarea"
          name={name}
          value={value}
          placeholder={placeholder}
          className={`${isError ? 'error' : ''}`}
          onChange={handleValueChanged}
        />
      </ErrorTooltipWrapper>
    </FormGroup>
  );
}

FormGroupTextarea.propTypes = {
  required: bool,
  label: string,
  note: string,
  tooltip: oneOfType([string, node]),
  name: string,
  value: string,
  placeholder: string,
  error: string,
  onChange: func,
};

FormGroupTextarea.defaultProps = {
  required: false,
  label: '',
  note: '',
  tooltip: '',
  name: '',
  value: '',
  placeholder: '内容を入力',
  error: '',
  onChange: () => {},
};

export default FormGroupTextarea;
