import methods from 'validator';
import errorMessages from 'services/validations/errorMessages';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';

/**
 * This class validate inputs using the methods
 * from https://github.com/validatorjs/validator.js
 */
class Validator {
  constructor(rules) {
    this.rules = rules && rules.flat(); // flatten 2D array in case usage of rules creator
    this.initiate();
  }

  initiate() {
    this.isValid = true;
    this.errors = {};
  }

  // Validate input object
  validate(state) {
    this.initiate();

    if (!isArray(this.rules)) return this.errors;
    if (this.rules.length === 0) return this.errors;

    // Loop throught each rule
    this.rules.forEach((rule) => {
      if (this.errors[rule.field]) return;

      const fieldValue = state[rule.field] || '';
      const args = rule.args || [];
      const methodName = rule.methodName || '';

      // If rule is string, check for existing validating mehotd
      // Else use passed in method
      const validationMethod =
        typeof rule.method === 'string' ? methods[rule.method] : rule.method;

      if (validationMethod(fieldValue, ...args, state) !== rule.validWhen) {
        // If no override error message, find in predefined messages
        const messParams = rule.messageParams || [];
        // Override error message method
        const method = methodName || rule.method;
        const message =
          rule.message ||
          Validator.getErrorMessage(method, messParams, ...args);

        // Append error mess into field name
        this.errors[rule.field] = message;
        this.isValid = false;
      }
    });
    return this.errors;
  }

  /**
   * Get and build error message
   *
   * @param {string} method error message key
   * @param {array} params replacing params in the message
   * @param {object} args concat the original key and args to get matching key
   */
  static getErrorMessage(method, params, args) {
    if (params.length === 0) return errorMessages[method];

    let mess = errorMessages[method];

    if (!isEmpty(args) && isObject(args)) {
      const argKeys = Object.keys(args).join('');
      mess = errorMessages[method.concat(argKeys)];
    }

    if (params[0])
      // Repalce params into placeholders
      params.forEach((param, index) => {
        mess = mess.replaceAll(`$${index}`, param);
      });

    return mess;
  }
}

export default Validator;
