import React from 'react';
import { PRIVATE_POLICIES, TERMS_OF_USE } from 'services/routes/constants';
import { useTranslation } from 'react-i18next';
import 'views/molecules/footer/footer.scss';

function Footer() {
  const { t } = useTranslation();

  return (
    <div id="footer">
      <span>Copyright©YRGLM Inc. All Rights Reserved.</span>
      <a href={PRIVATE_POLICIES} target="_blank" rel="noopener noreferrer">
        {t('footer.private_policies')}
      </a>
      <a href={TERMS_OF_USE} target="_blank" rel="noopener noreferrer">
        {t('footer.terms_of_use')}
      </a>
    </div>
  );
}

export default Footer;
