import React, { useMemo, useCallback } from 'react';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { commonActions } from 'store/common';
import settingViewActions from 'store/customview/actions';
import CommonBar from 'views/molecules/commonbar/components/CommonBar';
import settingsSelector from 'store/settings/selectors';
import filtersSelector from 'store/filters/selectors';
import { getPermissions } from 'store/auth/selectors';
import displayItemsSelector from 'store/display-items/selectors';
import filterActions from 'store/filters/actions';
import tagManagementActions from 'store/tag-management/actions';
import logRouteAnalyzeActions from 'store/log/route-analyze/actions';
import logRouteAnalyzeSelectors from 'store/log/route-analyze/selectors';
import {
  COMPARE_PERIOD,
  MEASUREMENT_SITE_PAGE_MANAGEMENT,
  TAG_MANAGEMENT,
  LOG_ROUTE_ANALYZE,
  AGENCY_MANAGEMENT,
} from 'services/routes/constants';
import {
  FILTER_KEY_PAGE_ID,
  FILTER_KEY_PAGE_TITLE,
  FILTER_OPERATOR_UNREGISTER,
} from 'services/consts';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';
import { CSV_DOWNLOAD_TYPE } from 'domain/consts';
import pageManagementActions from 'store/page-management/actions';
import logPeriodAnalyzeSelector from 'store/log/period-analyze/selectors';
import periodAnalyzeSelector from 'store/period-analyze/selectors';
import { PATTERN_DISPLAY_TOTURIAL } from 'domain/log/route-analyze/consts';
import agencyManagementActions from 'store/agency-management/actions';

const getPeriod = (state) => {
  return state.commonState.CommonState.settings.period;
};

const CommonBarContainer = (props) => {
  const dispatch = useDispatch();
  const screenId = useSelector(settingsSelector.getPage);
  const priorityAxis = useSelector(
    displayItemsSelector.getDisplayItemPriorityAxis,
    shallowEqual
  );
  const [currentTab, isPageSettings] = [
    useSelector(settingsSelector.getCurrentTab),
    useSelector(settingsSelector.isPageSettings),
  ];

  const handleApplyFilter = useCallback(
    (filters) => {
      switch (screenId) {
        case TAG_MANAGEMENT:
          dispatch(filterActions.updateFiltersTagManagement(filters));
          break;
        case AGENCY_MANAGEMENT:
          dispatch(filterActions.updateAgencyFilters(filters));
          break;
        case MEASUREMENT_SITE_PAGE_MANAGEMENT: {
          let filtersRequest = filters;
          const filterKeys = Object.keys(filters);
          const isFilterPageTitleUnregister =
            filterKeys.includes(FILTER_KEY_PAGE_TITLE) &&
            filters[FILTER_KEY_PAGE_TITLE].option ===
              FILTER_OPERATOR_UNREGISTER;
          if (isFilterPageTitleUnregister) {
            filtersRequest = {
              ...filtersRequest,
              [FILTER_KEY_PAGE_TITLE]: {
                ...filtersRequest[FILTER_KEY_PAGE_TITLE],
                values: '',
              },
            };
          }
          if (
            filterKeys.includes(FILTER_KEY_PAGE_ID) &&
            isFilterPageTitleUnregister
          ) {
            const { [FILTER_KEY_PAGE_ID]: pageId, ...restFilters } = filters;
            filtersRequest = restFilters;
          }
          dispatch(filterActions.updateFiltersPageManagement(filtersRequest));
          break;
        }

        default:
          dispatch(filterActions.updateFilters(filters));
          break;
      }

      if (!isPageSettings) {
        dispatch(settingViewActions.applyIsChanged({ changed: true }));
      }
    },
    [dispatch, isPageSettings, screenId]
  );

  const downloadCsvOptions = useMemo(() => {
    switch (screenId) {
      case TAG_MANAGEMENT:
        return [
          {
            downloadCsvText: '表を出力（CSV）',
            downloadCsv: () =>
              dispatch(
                tagManagementActions.downloadCsv(CSV_DOWNLOAD_TYPE.CONTENT)
              ),
          },
          {
            isHidden: currentTab === TAG_MANAGEMENT_TAB.CONTENT_CATEGORY,
            downloadCsvText: '各種タグを出力（CSV）',
            downloadCsv: () =>
              dispatch(
                tagManagementActions.downloadCsv(CSV_DOWNLOAD_TYPE.VARIOUS_TAGS)
              ),
          },
        ];
      case MEASUREMENT_SITE_PAGE_MANAGEMENT:
        return [
          {
            downloadCsvText: '表を出力（CSV）',
            downloadCsv: () => dispatch(pageManagementActions.downloadCsv()),
          },
        ];
      case LOG_ROUTE_ANALYZE:
        return [
          {
            downloadCsvText: '表を出力（CSV）',
            downloadCsv: () => dispatch(logRouteAnalyzeActions.downloadCsv()),
          },
        ];
      case AGENCY_MANAGEMENT:
        return [
          {
            downloadCsvText: '表を出力（CSV）',
            downloadCsv: () => dispatch(agencyManagementActions.downloadCsvAgencyManagement()),
          },
        ];  
      default:
        return [];
    }
  }, [dispatch, screenId, currentTab]);

  return (
    <CommonBar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      screenId={screenId}
      downloadCsvOptions={downloadCsvOptions}
      applyFilter={handleApplyFilter}
      priorityAxis={priorityAxis}
    />
  );
};

const mapStateToProps = (state) => {
  const screenId = settingsSelector.getPage(state);
  const routePattern = logRouteAnalyzeSelectors.getPattern(state);
  const periodAnalysisSecondMetric =
    screenId === COMPARE_PERIOD
      ? periodAnalyzeSelector.axisSelector(state).secondary
      : logPeriodAnalyzeSelector.getSettingChartAxis(state).secondary;
  return {
    filters: filtersSelector.getFilters(state),
    filtersSelected: filtersSelector.getSettings(state),
    currentTab: settingsSelector.getCurrentTab(state),
    screenId,
    period: getPeriod(state),
    downloadDisabled:
      screenId === LOG_ROUTE_ANALYZE &&
      routePattern === PATTERN_DISPLAY_TOTURIAL,
    comparedPeriod: state.commonState.CommonState.settings.comparedPeriod,
    comparedPeriodDisabled: !!periodAnalysisSecondMetric,
    permissions: getPermissions(state),
    screenType: state.cache.masterData.type,
  };
};
const mapDispatchToProps = (dispatch) => ({
  changePeriod: async ({ period, comparedPeriod }) => {
    dispatch(settingViewActions.applyIsChanged({ changed: true }));
    dispatch(
      commonActions.updatePeriodWithCompared({ period, comparedPeriod })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CommonBarContainer));
