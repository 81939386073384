import React, { useReducer } from 'react';
import { node } from 'prop-types';
import FormContext from 'views/molecules/Form/form-context';

const formReducer = (state, action) => {
  switch (action.type) {
    case 'setSubmit':
      return {
        ...state,
        isSubmit: action.payload,
      };
    default:
      throw new Error(`Invalid type ${action.type}`);
  }
};

const FormWrapper = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, {
    isSubmit: false,
    isApplyFormWrapper: !!children,
  });

  return (
    <FormContext.Provider value={{ ...state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};

FormWrapper.propTypes = {
  children: node.isRequired,
};

FormWrapper.defaultProps = {};

export default FormWrapper;
