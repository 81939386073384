/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'views/molecules/modal/newsmodal.scss';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';

import moment from 'moment';

const NewsModal = ({
  show,
  onHide,
  newsData,
  handleConfirm,
  showCheckbox,
  notRead,
}) => {
  const [isChecked, toggleCheck] = useState(false);

  const modalHeader = () => (
    <div className="d-flex flex-column align-items-start">
      <div>{moment(newsData.date_from).format('YYYY/MM/DD')}</div>
      <div className="news-modal__title">{newsData.title}</div>
    </div>
  );

  const handleOkBtn = () => {
    if ((isChecked || !showCheckbox) && notRead) handleConfirm(newsData.id);

    toggleCheck(() => false);
    onHide();
  };

  const handleCheckedButton = useCallback(() => {
    toggleCheck(!isChecked);
  }, [isChecked]);

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onHide}
      centered
      size="w800"
      dialogClassName="news-modal"
    >
      <Modal.Header>
        <Modal.Title as={modalHeader} />
      </Modal.Header>
      <Modal.Body bsPrefix="news-modal__body">
        <p dangerouslySetInnerHTML={{ __html: newsData.detail }} />
      </Modal.Body>
      <Modal.Footer>
        {showCheckbox && (
          <div className="mr-auto">
            <EbisCheckbox
              text="次回以降このモーダルを表示しない"
              name="temp"
              checked={isChecked}
              changeSelectBoxValue={handleCheckedButton}
              disabled={false}
            />
          </div>
        )}
        <Button onClick={handleOkBtn} variant="secondary">
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NewsModal.defaultProps = {
  show: false,
  onHide: () => {},
};

NewsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  newsData: PropTypes.object.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  notRead: PropTypes.bool.isRequired,
};

export default React.memo(NewsModal);
