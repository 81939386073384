import React, {
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { Form } from 'react-bootstrap';
import {
  oneOfType,
  bool,
  object,
  node,
  string,
  func,
  oneOf,
  number,
} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import NumericInput from 'views/atoms/numeric-input/NumericInput';

function CookieProgramSettingModalFormInput(props) {
  const {
    as,
    icon,
    name,
    value,
    type,
    placeholder,
    style,
    error,
    disabled,
    isDecimal,
    maxLength,
    onChange,
  } = props;

  const [inputValue, setInputValue] = useState(null);

  const ref = useRef();
  const isError = !isEmpty(error);

  const propInput = useMemo(() => {
    return {
      type,
      name,
      placeholder,
      style,
      disabled,
      isDecimal,
      size: 'sm',
      className: `${isError ? 'error' : ''}`,
    };
  }, [type, name, placeholder, style, disabled, isError, isDecimal]);

  const handleValueChanged = useCallback(
    (e) => {
      const { value: newValue } = e.target;
      if (maxLength === -1 || newValue.length <= maxLength) {
        setInputValue(newValue);
        onChange(name, newValue);
      }
    },
    [maxLength, onChange, name]
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className="d-flex align-items-center form-input" ref={ref}>
      {icon}
      {type === 'number' ? (
        <>
          <NumericInput
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...propInput}
            value={inputValue}
            onChange={handleValueChanged}
          />
          {isError && (
            // <ErrorTooltipWrapper isError={isError} errorMess={error} ref={ref}>
            <div ref={ref}>
              {error}
            </div>
          )}
        </>
      ) : (
        <>
          <Form.Control
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...propInput}
            as={as}
            value={inputValue}
            onChange={handleValueChanged}
            onKeyPress={event => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
          />
          {isError && Array.isArray(error) && error.length > 0 &&
            (
              <div ref={ref} className='modal-error-message'>
                {error.map((item) => {
                  return (
                    <>{item}<br/></>
                  );
                })}
              </div>
            )
          }
        </>
      )}
    </div>
  );
}

CookieProgramSettingModalFormInput.propTypes = {
  disabled: bool,
  icon: oneOfType([string, node]),
  name: string,
  value: string,
  type: string,
  placeholder: string,
  style: oneOfType([object]),
  error: string,
  onChange: func,
  as: oneOf(['input', 'textarea']),
  isDecimal: bool,
  maxLength: number,
};

CookieProgramSettingModalFormInput.defaultProps = {
  disabled: false,
  icon: '',
  name: '',
  value: '',
  type: 'text',
  placeholder: '値を入力',
  style: {},
  error: '',
  as: 'input',
  isDecimal: false,
  maxLength: -1, // unlimit
  onChange: () => {},
};

export default React.memo(CookieProgramSettingModalFormInput);
