// sync actions
const FETCH_TAB = 'settings/FETCH_TAB';
const CHANGE_TAB = 'settings/CHANGE_TAB';
const CHANGE_MODE = 'settings/CHANGE_MODE';
const FETCH_PAGE = 'settings/FETCH_PAGE';
const CHANGE_PAGE = 'settings/CHANGE_PAGE';

export default {
  FETCH_TAB,
  CHANGE_TAB,
  CHANGE_MODE,
  FETCH_PAGE,
  CHANGE_PAGE,
};
