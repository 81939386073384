import helper from 'store/helper';
import types from 'store/log/route-analyze/types';
import { CNT, SORT_DIRECTION_DESC } from 'domain/settings/display-items';
import { communicationStatus } from 'services/utils';
import { INFLOW_MODE_IGNORE } from 'domain/log/route-analyze/consts';

const { IDLE, FAILED, SUCCEEDED } = communicationStatus;
const FIRST_PAGE = 1;

const initialState = {
  data: {
    detail: [],
    metadata: {},
  },
  masterdata: {
    display: {
      ad_group1: '広告グループ１',
      ad_group2: '広告グループ２',
    },
    media: {
      data: {},
      search: {},
      isSearching: false,
      status: IDLE,
    },
    adGroup1: {
      data: {},
      search: {},
      isSearching: false,
      status: IDLE,
    },
    adGroup2: {
      data: {},
      search: {},
      isSearching: false,
      status: IDLE,
    },
    page: {
      data: {},
      search: {},
      isSearching: false,
      status: IDLE,
    },
  },
  settings: {
    isStayingPage: false, // Handle case click on this menu will clear cache, value is false will get all setting and data again
    isDisplayToturial: true,
    viewId: null,
    paging: FIRST_PAGE,
    sort: { field: CNT, direction: SORT_DIRECTION_DESC },
    inflowMode: INFLOW_MODE_IGNORE,
    displayItem: {},
    filters: [],
  },
  errors: [],
  status: {
    data: IDLE,
    settings: IDLE,
  },
};

const mapperKeyStatus = {
  [types.GET_DATA]: 'data',
  [types.GET_SETTINGS]: 'settings',
  [types.CHANGE_SETTINGS]: 'settings',
};

const setStatusMasterdata = (state, action) => {
  const { type, status } = action.payload;
  return {
    ...state,
    masterdata: {
      ...state.masterdata,
      [type]: { ...state.masterdata[type], status },
    },
  };
};

const setMasterdata = (state, action) => {
  const { type, data } = action.payload;
  return {
    ...state,
    masterdata: {
      ...state.masterdata,
      [type]: { ...state.masterdata[type], ...data },
    },
  };
};

const setStatus = (state, action) => {
  const { type, status } = action.payload;
  const key = mapperKeyStatus[type];
  if (!key) return state;
  return { ...state, status: { ...state.status, [key]: status } };
};

const setDisplayToturial = (state) => {
  return {
    ...state,
    settings: { ...state.settings, isDisplayToturial: true },
  };
};

const setSettings = (state, action) => {
  const { settings } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      ...settings,
      isDisplayToturial: false,
      isStayingPage: true,
    },
    status: { ...state.status, settings: SUCCEEDED },
  };
};

const setData = (state, action) => {
  const { data, metadata } = action.payload.data;
  return {
    ...state,
    data: { ...state.data, detail: data, metadata },
    status: { ...state.status, data: SUCCEEDED },
  };
};

const setErrors = (state, action) => {
  const { type, errors } = action.payload;
  const key = mapperKeyStatus[type];
  if (!key) return state;
  return { ...state, errors, status: { ...state.status, [key]: FAILED } };
};

const changePage = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    settings: { ...state.settings, paging: page },
  };
};

const LogRouteAnalyzeReducer = helper.createReducer(initialState, {
  [types.SET_STATUS]: setStatus,
  [types.SET_SETTINGS]: setSettings,
  [types.SET_DISPLAY_TOTURIAL]: setDisplayToturial,
  [types.SET_MASTERDATA]: setMasterdata,
  [types.SET_STATUS_MASTERDATA]: setStatusMasterdata,
  [types.SET_ERRORS]: setErrors,
  [types.SET_DATA]: setData,
  [types.CHANGE_PAGE]: changePage,
});

export default LogRouteAnalyzeReducer;
