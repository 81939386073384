import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import {
    object,
    func,
    bool,
    oneOfType,
    arrayOf,
    string,
  } from 'prop-types';
import CookieProgramSettingModalFormInput from 'views/pages/system-setting/components/CookieProgramSettingModalFormInput';
import EbisStep from 'views/molecules/Step/EbisStep';
import TableModal from 'views/organism/TableModal';


function CookieProgramSettingModal(props){

    const {
        execType,
        steps,
        handleChangeValue,
        registerColumns,
        registerModalData,
        onCancel,
        isDisableCheck,
        onRegisterModalSubmit,
    } = props;

    return (
      <Modal show centered size="w800" dialogClassName="modal-cookie-program-setting" contentClassName="modal-content--settings">
        <Modal.Header className="justify-content-start">
          <Modal.Title style={{ marginRight: 10 }}>
            {execType === 'add' ? (
              <>
                ITP対応プログラム設定を追加
              </>
            ) : (
              <>
                ITP対応プログラム設定を編集
              </>
            )}
          </Modal.Title>
          <EbisStep steps={steps} />
        </Modal.Header>
        <Modal.Body>
          <p className="modal-description note">
            設定したいドメインに、プログラムを配置したURLを登録してください。
          </p>
          <Form className="form-cookie-program-setting" autoComplete="off">
            <TableModal
              header={
                <tr>
                  {registerColumns.map((column) => (
                    <th>
                      {column.title}
                    </th>
                  ))}
                </tr>
              }
              body={
                Object.keys(registerModalData).map((key) => {
                  const values = registerModalData[key];
                  return (<>
                    <tr key={key}>
                      <td>{key}</td>
                      <td>
                        <CookieProgramSettingModalFormInput
                          name={key}
                          value={values.program_url}
                          error={values.error}
                          onChange={handleChangeValue}
                        />
                      </td>
                    </tr>
                  </>)
                })
              }
              maxContent='280'
              alignScroll='0'
              isNoScrollWrapper={false}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="link"
                size="sm"
                onClick={onCancel}
              >
                キャンセル
              </Button>
              <Button
                variant="secondary"
                size="sm"
                disabled={isDisableCheck}
                onClick={onRegisterModalSubmit}
              >
                次へ
              </Button>
        </Modal.Footer>
      </Modal>
    );
}

CookieProgramSettingModal.propTypes = {
  execType:string,
  steps:arrayOf(oneOfType([object])),
  handleChangeValue:func,
  registerColumns:arrayOf(oneOfType([object])),
  registerModalData:arrayOf(oneOfType([object])),
  onCancel:func,
  isDisableCheck:bool,
  onRegisterModalSubmit:func,
}

CookieProgramSettingModal.defaultProps = {
  execType:'',
  steps:[{}],
  handleChangeValue:() => {},
  registerColumns:[{}],
  registerModalData:[{}],
  onCancel:() => {},
  isDisableCheck:false,
  onRegisterModalSubmit:() => {},
};

export default CookieProgramSettingModal;