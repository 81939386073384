/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './icon.scss';
import classNames from 'classnames';

const EbisIcon = (props) => {
  const {
    isIconMenu,
    iconName,
    active,
    activeIcon,
    customBgColor,
    iconColor,
    staticBg,
    defaultCursor,
    newIconDisplay,
  } = props;
  const [isHover, toggleHover] = useState(false);
  const [isActive, toggleActive] = useState(false);

  useEffect(() => {
    if (active !== isActive) toggleActive(active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const iconClass = classNames({
    fas: true,
    'fa-circle': !newIconDisplay,
    'fa-stack-2x': true,
    'text-primary': customBgColor === null,
  });
  const iconMotionClass = classNames({
    [iconName]: true,
    'fa-stack-1x': true,
    'fa-inverse': staticBg,
    active: true,
    newicon: newIconDisplay,
  });
  const iconMotionStyles = newIconDisplay
    ? {
        background: customBgColor,
        lineHeight: '14px',
      }
    : {};
  const iconDefaultClass = classNames({
    [iconName]: true,
    'fa-stack-1x': true,
    newicon: newIconDisplay,
  });
  const wrapperClass = classNames({
    'fa-stack': true,
    'fa-stack--menu-item': isIconMenu,
  });
  const iconDefaultStyles = newIconDisplay
    ? {
        background: customBgColor,
        lineHeight: '14px',
        color: iconColor,
      }
    : {
        color: iconColor,
      };

  return (
    <span
      style={{ cursor: `${defaultCursor ? 'default' : 'pointer'}` }}
      onMouseEnter={() => !staticBg && toggleHover(true)}
      onMouseLeave={() => !staticBg && toggleHover(false)}
      onClick={() => toggleActive(!isActive)}
      role="button"
      aria-hidden
    >
      <span className={wrapperClass}>
        {isHover || isActive || staticBg ? (
          <>
            <i className={iconClass} style={{ color: customBgColor }} />
            {isActive && activeIcon !== null ? (
              <i className={`${activeIcon} fa-stack-1x fa-inverse active`} />
            ) : (
              <i className={iconMotionClass} style={iconMotionStyles}>
                {newIconDisplay && <i className="newicon-margin">NEW</i>}
              </i>
            )}
          </>
        ) : (
          <i className={iconDefaultClass} style={iconDefaultStyles}>
            {newIconDisplay && <i className="newicon-margin">NEW</i>}
          </i>
        )}
      </span>
    </span>
  );
};

EbisIcon.defaultProps = {
  isIconMenu: false,
  active: false,
  activeIcon: null,
  customBgColor: null,
  iconColor: null,
  staticBg: false,
  defaultCursor: false,
  newIconDisplay: false,
};

EbisIcon.propTypes = {
  isIconMenu: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  active: PropTypes.bool,
  activeIcon: PropTypes.string,
  customBgColor: PropTypes.string,
  iconColor: PropTypes.string,
  staticBg: PropTypes.bool,
  defaultCursor: PropTypes.bool,
  newIconDisplay: PropTypes.bool,
};

export default EbisIcon;
