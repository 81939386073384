const FETCH_NOTIFICATIONS_START = 'notifications/FETCH_NOTIFICATIONS_START';
const FETCH_NOTIFICATIONS_SUCCESS = 'notifications/FETCH_NOTIFICATIONS_SUCCESS';
const FETCH_NOTIFICATIONS_ERROR = 'notifications/FETCH_NOTIFICATIONS_ERROR';
const UPDATE_NOTIFICATION_READ = 'notifications/UPDATE_NOTIFICATION_READ';
const TOGGLE_NEWS_MODAL = 'notifications/TOGGLE_NEWS_MODAL';
const ADD_NOTIFICATION_MODAL = 'notifications/ADD_NOTIFICATION_MODAL';
const CHANGE_NEWS_MODAL_STATUS = 'notifications/CHANGE_NEWS_MODAL_STATUS';

export default {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATION_READ,
  TOGGLE_NEWS_MODAL,
  ADD_NOTIFICATION_MODAL,
  CHANGE_NEWS_MODAL_STATUS,
};
