import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import { string, func, bool, number, node, oneOfType } from 'prop-types';
import CopyToClipboard from 'views/atoms/CopyToClipboard';
import { GridTableContext } from '../GridTableContext';
import { getHeightRow } from '../GridTableService';

function GridTableCellCopyToClipboard(props) {
  const { value} = props;
  const { dispatch } = useContext(GridTableContext);
  const [isExpand, setExpand] = useState(false);

  const handleExpand = (e) => {
    e.stopPropagation();
    setExpand(!isExpand);
    debounce(() => {
      getHeightRow(e.target.closest('[role="row"]'), dispatch);
    }, 50)();
  };

  return (
    <div>
      <span onClick={handleExpand} aria-hidden="true" className='grid-table__column-link-copy'>
        {isExpand ? '非表示' : 'URLを表示'}
      </span>
      <CopyToClipboard value={value} />
      {isExpand && <div>{value}</div>}
    </div>
  );
}

GridTableCellCopyToClipboard.propTypes = {
  value: oneOfType([string, node]),
};

GridTableCellCopyToClipboard.defaultProps = {
  value: '',
};

export default GridTableCellCopyToClipboard;
