import actions from 'store/logger/actions';
import types from 'store/logger/types';
import constants from 'store/logger/constant';

// White list, ignore these action types
const whiteList = [
  // types.LOG_UI_ERROR,
  // types.SEND_REPORT,
  types.LOG_ERROR,
  types.CLOSE_ERROR,
  types.LOG_ACTION,
];

// Ignored action scopes
const errorStringsArr = ['ERROR', 'FAILED'];

const whiteListActionScopes = [constants.SCOPE_DONT_SHOW];

// Logging actions and errors for Store
const LoggerService = (store) => (next) => (action) => {
  // console.log('Middleware triggered:', action);

  if (
    action.type !== types.LOG_ACTION &&
    !whiteList.includes(action.type) &&
    action.scope !== constants.SKIP_WRITE_LOG
  ) {
    store.dispatch(actions.logAction(action));
  }

  if (
    // Check if action string contains key words from errorStringsArr
    errorStringsArr.some((substr) => action.type.includes(substr)) &&
    !whiteList.includes(action.type) &&
    /**
     * Check if the error action have scope showing on screen
     *
     * {
     *    type: 'SOME_ACTION_FAIL',
     *    scope: constants.SCOPE_DONT_SHOW
     * }
     */
    !whiteListActionScopes.includes(action.scope)
  ) {
    store.dispatch(actions.logError(action));
  }

  next(action);
};

export default LoggerService;
