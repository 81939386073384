export default {
  GET_DATA: 'log-routeroute-analyze/GET_DATA',
  GET_SETTINGS: 'log-route-analyze/GET_SETTINGS',
  GET_PAGE_VIEW_BIGGEST: 'log-route-analyze/GET_PAGE_VIEW_BIGGEST',

  SET_DATA: 'log-route-analyze/SET_DATA',
  SET_SETTINGS: 'log-route-analyze/SET_SETTINGS',
  SET_STATUS: 'log-route-analyze/SET_STATUS',
  SET_ERRORS: 'log-route-analyze/SET_ERRORS',

  SET_DISPLAY_TOTURIAL: 'log-route-analyze/SET_DISPLAY_TOTURIAL',

  SET_MASTERDATA: 'log-route-analyze/SET_MASTERDATA',
  SET_STATUS_MASTERDATA: 'log-route-analyze/SET_STATUS_MASTERDATA',
  SEARCH_MASTERDATA: 'log-route-analyze/SEARCH_MASTERDATA',

  CHANGE_SETTINGS: 'log-route-analyze/CHANGE_SETTINGS',
  CHANGE_PAGE: 'log-page-analyze/CHANGE_PAGE',

  DOWNLOAD_CSV: 'log-page-analyze/DOWNLOAD_CSV',
};
