import Api from 'services/api/Api';

import { ACCOUNT_ENDPOINT } from 'services/routes/constants';

const ReflectionTimeApiFactory = () => {
  const url = `${ACCOUNT_ENDPOINT}/system/sync`;
  return {
    getRelectionTime: async () => Api.get(url),
    getReflectionTimeByType: async (type) => Api.get(`${url}/${type}`),
  };
};

const ReflectionTimeApi = ReflectionTimeApiFactory();
export default ReflectionTimeApi;
