import types from 'store/notifications/types';

const fetchNotificationsStart = () => ({
  type: types.FETCH_NOTIFICATIONS_START,
});

const fetchNotificationsSuccess = (data, isNotReadList, newsModal = {}) => ({
  type: types.FETCH_NOTIFICATIONS_SUCCESS,
  payload: { data, isNotReadList, newsModal },
});

const addNotificationModal = (id) => ({
  type: types.ADD_NOTIFICATION_MODAL,
  payload: { id },
});

const fetchNotificationsError = ({ error, scope }) => ({
  type: types.FETCH_NOTIFICATIONS_ERROR,
  payload: error,
  scope,
});

const updateNotificationRead = (id) => ({
  type: types.UPDATE_NOTIFICATION_READ,
  payload: { id },
});

const toggleNewsModal = () => ({
  type: types.TOGGLE_NEWS_MODAL,
});

const changeNewsModalStatus = (isShow) => ({
  type: types.CHANGE_NEWS_MODAL_STATUS,
  payload: isShow,
});

export default {
  fetchNotificationsStart,
  fetchNotificationsSuccess,
  fetchNotificationsError,
  updateNotificationRead,
  toggleNewsModal,
  addNotificationModal,
  changeNewsModalStatus,
};
