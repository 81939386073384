import moment from 'moment';
import {
  MEMO_DATE_FORMAT,
  PERIOD_TYPE,
  TABLE_PERIOD_DATE_FORMAT,
  TABLE_PERIOD_MONTH_FORMAT,
} from 'domain/log/period-analyze/consts';
import { API_DATE_FORMAT, JP_DAY_OF_WEEK } from 'domain/consts';
import PeriodAnalyzeApi from 'services/api/log/PeriodAnalyzeApi';
import { PERIOD } from 'domain/fields';
import {
  allFilterableList,
  FILTER_KEY_PAGE_ID,
  FILTER_KEY_PAGE_URL,
  FILTER_KEY_PAGE_TITLE,
  FILTER_KEY_CONTENT_CATEGORY,
} from 'services/consts';

export const createLoadMemoRequest = ({ start, end }) => {
  return {
    start_date: start.format(MEMO_DATE_FORMAT),
    end_date: end.format(MEMO_DATE_FORMAT),
  };
};

export const mapApiResponseToMemo = (memo) => ({
  start: memo.start_date,
  end: memo.end_date,
  category: memo.category,
  content: memo.content,
  id: memo.memo_id,
});

export const loadMemo = async ({ start, end }) => {
  const request = createLoadMemoRequest({ start, end });
  const res = await PeriodAnalyzeApi.loadMemo(request);
  return res.data.data?.detail
    ?.map((memo) => mapApiResponseToMemo(memo))
    .sort((m1, m2) => {
      const diff =
        moment(m1.start, API_DATE_FORMAT).valueOf() -
        moment(m2.start, API_DATE_FORMAT).valueOf();
      if (diff !== 0) return diff;
      return m1.id - m2.id;
    });
};

const createSaveMemoRequest = ({ category, start, end, content }) => {
  return {
    start_date: start.format(MEMO_DATE_FORMAT),
    end_date: end.format(MEMO_DATE_FORMAT),
    category,
    content,
  };
};

export const updateMemo = async (memo) => {
  const { id, category, content, memoStart: start, memoEnd: end } = memo;
  const request = createSaveMemoRequest({ category, start, end, content });
  try {
    return await PeriodAnalyzeApi.updateMemo(id, request);
  } catch (e) {
    return e.response;
  }
};

export const createMemo = async (memo) => {
  const { category, content, start, end } = memo;
  const request = createSaveMemoRequest({ category, start, end, content });
  try {
    return await PeriodAnalyzeApi.createMemo(request);
  } catch (e) {
    return e.response;
  }
};

export const formatPeriodField = (periodType, value) => {
  switch (periodType) {
    case PERIOD_TYPE.DAY:
      return moment(value).format(TABLE_PERIOD_DATE_FORMAT);
    case PERIOD_TYPE.MONTH:
      return moment(value).format(TABLE_PERIOD_MONTH_FORMAT);
    case PERIOD_TYPE.DOW: {
      const dowAsInt = parseInt(value, 10);
      return `${JP_DAY_OF_WEEK[dowAsInt]}曜`;
    }
    case PERIOD_TYPE.HOUR:
      return `${value}時`;
    default:
      return value;
  }
};

export const changeInfoFilter = (key, filter) => {
  switch (key) {
    case FILTER_KEY_PAGE_ID:
      return {
        ...filter,
        note: '「CV」はページID単位で絞り込むことはできません。',
      };
    case FILTER_KEY_PAGE_TITLE:
      return {
        ...filter,
        note: '「CV」はページタイトル単位で絞り込むことはできません。',
      };
    case FILTER_KEY_PAGE_URL:
      return {
        ...filter,
        title: 'ページURL',
        note: '「CV」はページURL単位で絞り込むことはできません。',
        order: allFilterableList[FILTER_KEY_PAGE_TITLE].order + 1,
      };
    case FILTER_KEY_CONTENT_CATEGORY:
      return {
        ...filter,
        note: '「CV」はコンテンツカテゴリ単位で絞り込むことはできません。',
      };

    default:
      return filter;
  }
};
