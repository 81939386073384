import React, { useState } from 'react';
import { func, arrayOf, shape, bool } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import SettingModal from 'views/pages/system-setting/components/Modal';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import DragDropList from 'views/molecules/DragDropList';
import DragDropItemDefault from 'views/molecules/DragDropList/components/DragDropItemDefault';
import { SETTING_LABELS } from 'domain/system-setting/consts-display-setting';
import {
  prepareDataVisionForRequest,
  validateDataVision,
  convertErrorsVision,
} from 'services/system-setting/systemSettingDisplayServices';
import { CONVERSIONIDS } from 'domain/fields';

function LabelSettingModal(props) {
  const { loading, label, conversions, onSubmit, onHide } = props;

  const [dataSetting, setDataSetting] = useState({
    ...label,
    [CONVERSIONIDS]: conversions,
  });

  const [error, setError] = useState({});

  const handleChangeValue = (name, value) => {
    setDataSetting((prev) => ({ ...prev, [name]: value }));
    setError((prev) => ({ ...prev, [name]: null }));
  };

  const handleSortConversion = (data) => {
    handleChangeValue(CONVERSIONIDS, data);
  };

  const handleError = (errors) => {
    const errorResponse = convertErrorsVision(errors);
    setError(errorResponse);
  };

  const handleSubmit = () => {
    const dataRequest = prepareDataVisionForRequest(dataSetting);
    const errorValidate = validateDataVision(dataRequest);
    setError(errorValidate);

    if (isEmpty(errorValidate)) {
      onSubmit(dataRequest, handleError);
    }
  };

  return (
    <SettingModal
      isShow
      loading={loading}
      onSubmit={handleSubmit}
      onHide={onHide}
      title="表示オプションを編集"
      dialogClassName="display-setting__modal-label"
    >
      <FormGroup
        required
        label="ラベル"
        variant="label"
        tooltip={
          <>
            ラベル名を任意の名前に変更します。
            <br />
            設定した名前は各画面に反映されます。
          </>
        }
      >
        <div className="form-items">
          {SETTING_LABELS.map((item) => {
            const { name } = item;
            return (
              <>
                <div key={name} className="form-item">
                  <span>{item.title}</span>
                  <FormInput
                    name={name}
                    value={dataSetting[name]}
                    onChange={handleChangeValue}
                  />
                  <span className="txt-note color-gray-dark">{item.note}</span>
                </div>
                {error[name] && (
                  <div key={name} className="form-item--error">
                    {`※${error[name]}`}
                  </div>
                )}
              </>
            );
          })}
        </div>
      </FormGroup>
      <FormGroup label="コンバージョン表示順" variant="conversion">
        <DragDropList
          items={dataSetting[CONVERSIONIDS]}
          emptyMessage="コンバージョンが登録されていません"
          onChange={handleSortConversion}
        >
          <DragDropItemDefault />
        </DragDropList>
      </FormGroup>
    </SettingModal>
  );
}

LabelSettingModal.propTypes = {
  loading: bool.isRequired,
  label: shape({}),
  conversions: arrayOf(shape({})),
  onSubmit: func.isRequired,
  onHide: func.isRequired,
};

LabelSettingModal.defaultProps = {
  label: {},
  conversions: [],
};

export default LabelSettingModal;
