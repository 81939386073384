import React, { useContext, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isObject } from 'lodash';
import { bool, func, string, number, oneOfType, array } from 'prop-types';
import TableContext from 'views/templates/TableStatisticsTemplate/components/table-context';
import EbisCustomCheckbox from 'views/atoms/checkbox/EbisCustomCheckbox';
import ActionIcon from 'views/atoms/icon/ActionIcon';
import {
  getTooltipDisabledEdit,
  getTooltipDisabledDelete,
} from 'views/organism/table/TableService';
import TableTooltips from 'services/common/TableTooltips';
import useClickOutside from 'services/custom-hooks/useClickOutside';

const DropdownEdit = ({
  onEdit = () => {},
  disabledEdit = false,
  id = '',
  listDropdown = [],
}) => {
  const [activeMenu, setActiveMenu] = useState(false);

  const clickRef = useRef();

  const disabledAction =
    isObject(disabledEdit) &&
    Object.values(disabledEdit).length === listDropdown.length &&
    Object.values(disabledEdit).every((item) => item === true);

  const handleClickOutside = () => {
    if (activeMenu) {
      setActiveMenu(false);
    }
  };

  useClickOutside(clickRef, handleClickOutside);

  const handleShowMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const handleEdit = (action) => () => {
    if (!!disabledEdit[action] || !action) {
      return;
    }
    onEdit(id, action);
  };

  return (
    <div className="edit-dropdown" ref={clickRef}>
      <OverlayTrigger
        placement="bottom-start"
        show={activeMenu}
        trigger="click"
        overlay={
          <div className="edit-dropdown--menu">
            {listDropdown.map((item) => {
              return (
                <OverlayTrigger
                  key={item.action}
                  trigger={
                    disabledEdit[item.action] && item.tooltip
                      ? ['hover', 'focus']
                      : ''
                  }
                  placement="bottom"
                  overlay={
                    <Tooltip
                      variant="disabled"
                      placement="bottom"
                      className="edit-tooltip"
                    >
                      {item.tooltip}
                    </Tooltip>
                  }
                >
                  <div
                    className="edit-dropdown--menu-item"
                    onClick={handleEdit(item.action)}
                    disabled={!!disabledEdit[item.action]}
                    aria-hidden="true"
                  >
                    <span>{item.text}</span>
                  </div>
                </OverlayTrigger>
              );
            })}
          </div>
        }
      >
        <ActionIcon onClick={handleShowMenu} disabled={disabledAction} />
      </OverlayTrigger>
    </div>
  );
};

DropdownEdit.propTypes = {
  id: string.isRequired,
  onEdit: oneOfType([func, bool]).isRequired,
  listDropdown: oneOfType([array]).isRequired,
  disabledEdit: bool.isRequired,
};

function CellCheckbox(props) {
  const {
    id,
    width,
    variant,
    className,
    checked,
    disabledCheckbox,
    disabledEdit,
    onChange,
    onClick,
    onEdit,
    isEdit,
    mode,
    listDropdown,
  } = props;
  const { screenId, screenName } = useContext(TableContext);
  const tooltipCheckbox = getTooltipDisabledDelete(screenId, screenName);

  const isShowListDropdown = listDropdown && listDropdown.length > 0;
  return (
    <div
      className={`freeze-table__cell-content ${className}`}
      style={{ width }}
    >
      <OverlayTrigger
        trigger={disabledCheckbox && tooltipCheckbox ? ['hover', 'focus'] : ''}
        placement="bottom"
        overlay={
          <Tooltip variant="disabled" placement="bottom">
            {tooltipCheckbox}
          </Tooltip>
        }
      >
        <span>
          <EbisCustomCheckbox
            id={id}
            checked={checked}
            disabled={disabledCheckbox}
            variant={variant}
            onChange={() => onChange(!checked, id)}
            onClick={onClick}
          />
        </span>
      </OverlayTrigger>
      {isEdit && !isShowListDropdown && (
        <ActionIcon
          disabled={disabledEdit}
          tooltip={
            mode === 'lpo'
              ? TableTooltips.lpoCancelledTooltipMassage
              : getTooltipDisabledEdit(screenId, screenName)
          }
          onClick={(e) => {
            // Prevent underlying components being clicked
            e.stopPropagation();
            onEdit(id);
          }}
        />
      )}
      {isEdit && isShowListDropdown && (
        <DropdownEdit
          onEdit={onEdit}
          disabledEdit={disabledEdit}
          id={id}
          listDropdown={listDropdown}
        />
      )}
    </div>
  );
}

CellCheckbox.propTypes = {
  className: string,
  id: string.isRequired,
  variant: string,
  checked: bool,
  disabledCheckbox: bool,
  disabledEdit: bool,
  isEdit: bool,
  width: number,
  onChange: func,
  onClick: func,
  onEdit: oneOfType([func, bool]),
  mode: string,
  listDropdown: oneOfType([array]),
};

CellCheckbox.defaultProps = {
  className: '',
  variant: '',
  width: 46,
  checked: false,
  disabledCheckbox: false,
  disabledEdit: false,
  isEdit: false,
  onEdit: false,
  onChange: () => {},
  onClick: () => {},
  mode: '',
  listDropdown: [],
};

export default CellCheckbox;
