import { isObject } from 'highcharts';
import _, { isArray } from 'lodash';
import moment from 'moment';
import {
  formatMessage,
  getErrorMessageByCode,
  isStringEmpty,
} from 'services/utils';
import {
  REGEX_EMAIL,
  REGEX_URL,
  REGEX_FILENAME,
  REGEX_QUERY_PARAMS,
} from 'services/regexPatterns';
import ErrorMessages from 'services/validations/constants';
import { isEmpty, isDate, isNumeric } from 'validator';
import { formatNumber } from 'domain/utils';
import * as messageError from 'services/validations/messageErrorByCode';

export const notContainSpaces = (errMsg = ErrorMessages.MSG_NO_SPTAB) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value, label } = field;
  if (!isStringEmpty(value) && isEmpty(value, { ignore_whitespace: true })) {
    return formatMessage(errMsg, { label });
  }
  return '';
};

export const validId = (errMsg = ErrorMessages.MSG_VALID_ID) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value, label } = field;
  const validIdRegex = /^[A-Za-z0-9_-]*$/;
  if (isStringEmpty(value) || validIdRegex.test(value)) {
    return '';
  }
  return formatMessage(errMsg, { label });
};

export const validAdId = (errMsg = ErrorMessages.MSG_VALID_ID) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value, label } = field;
  const validIdRegex = /^[A-Za-z0-9_-|]*$/;
  if (isStringEmpty(value) || validIdRegex.test(value)) {
    return '';
  }
  return formatMessage(errMsg, { label });
};

export const notBeginWith = (
  args = [],
  errMsg = ErrorMessages.MSG_RESERVED_KEYWORDS
) => (/** @type {{value, label, name}} */ field) => {
  const { label, value } = field;
  if (!args.some((arg) => value.startsWith(arg))) {
    return '';
  }
  return formatMessage(errMsg, {
    label,
    reserved_words: args.join(','),
  });
};

export const maxLength = (length, errMsg = ErrorMessages.MSG_LENGTH_MAX) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value = '', label } = field;
  if (isStringEmpty(value) || _.isNil(length) || length >= value.length) {
    return '';
  }
  return formatMessage(errMsg, { label, length });
};

export const notContainControlCharacters = (
  errMsg = ErrorMessages.MSG_CONTAINS_CONTROL_CHARACTERS
) => (/** @type {{value, label, name}} */ field) => {
  const { value = '', label } = field;
  // eslint-disable-next-line no-control-regex
  const controlCharactersRegex = /[\x00-\x1F\x7F]/;
  if (!controlCharactersRegex.test(value)) {
    return '';
  }
  return formatMessage(errMsg, {
    label,
  });
};

export const requiredValue = (errMsg = ErrorMessages.MSG_REQUIRED) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value, label } = field;
  if (isStringEmpty(value)) {
    return formatMessage(errMsg, { label });
  }

  return '';
};

export const numberCheck = (key, errMsg = ErrorMessages.MSG_REQUIRED) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value, label } = field;
  if (!/^[0-9]{1,255}$/.test(value)) {
    return formatMessage(errMsg, { label, key });
  }
  return '';
};

export const isEmail = (errMsg = ErrorMessages.MSG_EMAIL) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value, label } = field;
  if (isStringEmpty(value)) {
    return '';
  }
  if (!REGEX_EMAIL.test(value)) {
    return formatMessage(errMsg, { name: label });
  }
  return '';
};

export const isInteger = (errMsg = ErrorMessages.MSG_NUMBER_INTEGER) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value, label } = field;
  if (isStringEmpty(value) || isNumeric(value, { no_symbols: true })) {
    return '';
  }
  return formatMessage(errMsg, { name: label });
};

/**
 * Valid range
 * @param {number} min
 * @param {number} max
 */
export const numberInRange = (
  min,
  max,
  errMsg = ErrorMessages.MSG_NUMBER_IN_RANGE
) => (/** @type {{value, label, name}} */ field) => {
  const { value, label } = field;

  const result =
    isStringEmpty(value) ||
    (!_.isNil(min) && !_.isNil(max) && min <= value && value <= max);
  if (result) {
    return '';
  }
  return formatMessage(errMsg, {
    label,
    max,
    min,
  });
};

export const numberHalfWidth = (
  errMsg = ErrorMessages.MSG_NUMBER_HALF_WIDTH
) => (/** @type {{value, label}} */ field) => {
  const { value, label } = field;

  if (value && !/^[0-9]+(\.?)([0-9]+)?$/.test(value)) {
    return formatMessage(errMsg, { label });
  }

  return '';
};

export const validUrl = (errMsg = ErrorMessages.MSG_INVALID_URL) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value, label } = field;

  if (isStringEmpty(value)) {
    return '';
  }

  if (!REGEX_URL.test(value)) {
    return formatMessage(errMsg, { label });
  }

  return '';
};

export const validFilename = (errMsg = ErrorMessages.MSG_INVALID_FILENAME) => (
  /** @type {{value, label, name}} */ field
) => {
  const { value, label } = field;

  if (isStringEmpty(value)) {
    return '';
  }

  if (!REGEX_FILENAME.test(value)) {
    return formatMessage(errMsg, { label });
  }

  return '';
};

export const validAdUrl = (
  errMsgs = [
    ErrorMessages.MSG_INVALID_URL,
    ErrorMessages.MSG_INVALID_URL_QUESTION_MARK,
  ]
) => (/** @type {{value, label, name}} */ field) => {
  const { value, label } = field;
  const validUrlRegex = /^(https?|market):\/\/([a-zA-Z0-9_!~*'()[\];:@$?&=+,#%./-])+$/;
  if (isStringEmpty(value)) {
    return '';
  }

  if (!validUrlRegex.test(value)) {
    return formatMessage(errMsgs[0], { label });
  }

  if (value.indexOf('?') !== value.lastIndexOf('?')) {
    return formatMessage(errMsgs[1], { label });
  }
  return '';
};

export const validLabel = (errMsg = ErrorMessages.MSG_INVALID_LABEL) => (
  /** @type {{value, label}} */ field
) => {
  const { value, label } = field;

  if (isStringEmpty(value)) {
    return '';
  }

  if (/<([/]?[a-zA-Z]+)/.test(value)) {
    return formatMessage(errMsg, { label });
  }

  return '';
};

export const isBetweenPeriod = (
  min,
  max,
  errMsg = ErrorMessages.MSG_BETWEEN_PERIOD
) => (/** @type {{value, label}} */ field) => {
  const { value, label } = field;

  if (
    isStringEmpty(value) ||
    (isDate(value) &&
      moment(value).isBetween(moment(min), moment(max), undefined, '[]'))
  ) {
    return '';
  }

  return formatMessage(errMsg, { label, min, max });
};

export const requiredUpload = (errMsg = ErrorMessages.MSG_FILE_REQUIRED) => (
  /** @type {{value, label}} */ field
) => {
  const { value, label } = field;
  if (value && isObject(value)) return '';
  return formatMessage(errMsg, { label });
};

export const maxFileSize = (size, errMsg = ErrorMessages.MSG_FILE_SIZE) => (
  /** @type {{value, label}} */ field
) => {
  const { label, value } = field;
  if (!value || !isObject(value) || value.size <= size) return '';

  const bytes = 1024 * 1024;
  const megabytes = size / bytes;
  return formatMessage(errMsg, { label, size: megabytes });
};

export const minFileSize = (
  size = 0,
  errMsg = ErrorMessages.MSG_MIN_FILE_SIZE
) => (/** @type {{value, label}} */ field) => {
  const { label, value } = field;
  if (!value || !isObject(value) || value.size > size) return '';

  return formatMessage(errMsg, { label });
};

export const csvFile = (errMsg = ErrorMessages.MSG_FILE_EXTENSION_CSV) => (
  /** @type {{value}} */ field
) => {
  const { value } = field;
  if (!value || !isObject(value)) return '';

  const ext = value.name.split('.').pop().toLowerCase();

  return ext === 'csv' ? '' : formatMessage(errMsg);
};

export const allowExtensionFile = (
  accepts,
  errMsg = ErrorMessages.MSG_FILE_EXTENSION
) => (/** @type {{value}} */ field) => {
  const { label, value } = field;
  if (!value || !isObject(value)) return '';

  const ext = value.name.split('.').pop().toLowerCase();

  if (accepts.includes(ext)) return '';

  return formatMessage(errMsg, { label, extension: accepts.join('・') });
};

export const checkOverLineAccepted = (
  max,
  totalLine,
  errMsg = ErrorMessages.CSV_FILE_MAX_LINE_CHECK
) => (/** @type {{value, label}} */ field) => {
  const { value, label } = field;
  if (isObject(value) && totalLine > max) {
    return formatMessage(errMsg, { label, length: formatNumber(max) });
  }
  return '';
};

export const imageFile = (
  accepts = ['png', 'jpg', 'gif', 'swf'],
  errMsg = ErrorMessages.MSG_FILE_EXTENSION_IMAGE
) => (/** @type {{value}} */ field) => {
  const { label, value } = field;
  if (!value || !isObject(value)) return '';

  const ext = value.name.split('.').pop().toLowerCase();

  const errors = accepts.map((accept) => {
    switch (accept) {
      case 'png':
        return ext === 'png' && value.type === 'image/png';
      case 'jpg':
        return (
          ['jpg', 'jpeg'].includes(ext) &&
          ['image/jpg', 'image/jpeg'].includes(value.type)
        );
      case 'gif':
        return ext === 'gif' && value.type === 'image/gif';
      case 'swf':
        return ext === 'swf' && value.type === 'application/x-shockwave-flash';
      default:
        return false;
    }
  });

  const valid = errors.reduce((acc, v) => (v === true ? acc + 1 : acc), 0);
  if (valid) {
    return '';
  }

  const extension = `.${accepts.join(' / .')}`;
  return formatMessage(errMsg, { label, extension });
};

export const notEqualValue = (array, errMsg = ErrorMessages.MSG_NO_EQUAL) => (
  /** @type {{value, label}} */ field
) => {
  const { value, label } = field;
  if (!isStringEmpty(value) && array.includes(value)) {
    return formatMessage(errMsg, { label, value });
  }
  return '';
};

export const maxLine = (max, errMsg = ErrorMessages.MSG_MAX_LINE) => (
  /** @type {{value, label}} */ field
) => {
  const { value, label } = field;
  if (_.isArray(value) && max >= value.length) {
    return '';
  }
  return formatMessage(errMsg, { label, max });
};

export const minLine = (min, errMsg = ErrorMessages.MSG_MIN_LINE) => (
  /** @type {{value, label}} */ field
) => {
  const { value, label } = field;
  if (_.isArray(value) && value.length >= min) {
    return '';
  }
  return formatMessage(errMsg, { label, min });
};

export const duplicateValue = (errMsg = ErrorMessages.MSG_DUPLICATE) => (
  /** @type {{value, label}} */ field
) => {
  const { value, label } = field;
  if (new Set(value).size === value.length) {
    return '';
  }
  return formatMessage(errMsg, { label });
};

export const acceptsValue = (accepts, errMsg = ErrorMessages.MSG_ACCEPT) => (
  /** @type {{value, label}} */ field
) => {
  const { value, label } = field;
  if (!isStringEmpty(value) && !accepts.includes(value)) {
    return formatMessage(errMsg, { label });
  }
  return '';
};

export const newlineIpCheck = (errMsg = ErrorMessages.IP_ADDRESS_CHECK) => (
  /** @type {{value, label}} */ field
) => {
  let errorMessage = '';
  const { value, label } = field;
  if (!isEmpty(value)) {
    // eslint-disable-next-line no-useless-escape
    const validIpRegx = /^[0-9\.\*]+$/;
    const arrIp = value.split(',');
    let index = 1;
    arrIp.forEach((ip) => {
      const trimIp = ip.trim();
      if (!validIpRegx.test(trimIp)) {
        errorMessage = formatMessage(errMsg, { label, index });
      }
      index += 1;
    });
  }
  return errorMessage;
};

export const newLineEmailCheck = (errMsg = ErrorMessages.ALERT_EMAIL_CHECK) => (
  /** @type {{value, label}} */ field
) => {
  let errorMessage = '';
  const { value, label } = field;
  if (!isEmpty(value)) {
    const validEmailRegx = /^[0-9a-zA-Z][0-9a-zA-Z_.-]+@[0-9a-zA-Z][0-9a-zA-Z_.-]+\.[a-zA-Z]+$/;
    const arrEmail = value.split(',');
    let index = 1;
    arrEmail.forEach((email) => {
      const trimEmail = email.trim();
      if (!validEmailRegx.test(trimEmail)) {
        errorMessage = formatMessage(errMsg, { label, index });
      }
      index += 1;
    });
  }
  return errorMessage;
};

export const newlineFormatCheck = (
  errMsg = ErrorMessages.QUERY_PARAMS_FORMAT_CHECK,
  regex = REGEX_QUERY_PARAMS
) => (/** @type {{value, label}} */ field) => {
  let errorMessage = '';
  const { value, label } = field;
  if (!isEmpty(value)) {
    const listCheck = value.split(',');
    listCheck.forEach((item) => {
      const trimItem = item.trim();
      if (!regex.test(trimItem)) {
        errorMessage = formatMessage(errMsg, { label });
      }
    });
  }
  return errorMessage;
};

export const maxLengthByLine = (
  length,
  errMsg = ErrorMessages.MSG_LENGTH_MAX
) => (/** @type {{value, label, name}} */ field) => {
  const { value = '', label } = field;
  let errorMessage = '';
  if (!isEmpty(value)) {
    const listCheck = value.split(',');
    listCheck.forEach((item) => {
      if (item.length > length) {
        errorMessage = formatMessage(errMsg, { label, length });
      }
    });
  }
  return errorMessage;
};

export const validUrlByLine = (
  errMsg = ErrorMessages.URL_CHECK,
  isShowOnTable
) => (/** @type {{value, label}} */ field) => {
  let errorMessage = '';
  const errorByLine = [];
  const { value, label } = field;
  if (!isEmpty(value)) {
    const arrUrl = value.split(',');
    arrUrl.forEach((url, index) => {
      const trimUrl = url.trim();
      if (!isEmpty(trimUrl) && !REGEX_URL.test(trimUrl)) {
        errorMessage = formatMessage(errMsg, { label, index: index + 1 });
        errorByLine.push({
          line: `${index + 1}行目`,
          message: getErrorMessageByCode({ code: 'URL_CHECK' }, messageError, {
            label: '計測対象サイト',
          }),
        });
      }
    });
  }
  return isShowOnTable ? errorByLine : errorMessage;
};

/**
 * requiredCheck
 * This function is used to check required field
 * With value is an array or object
 * @param {string} errMsg
 * @returns string
 */
export const requiredCheck = (errMsg = ErrorMessages.MSG_REQUIRED) => (
  /** @type {{ value, label, name }} */ field
) => {
  const { value, label } = field;
  let msg = '';

  if (isArray(value) && value.length === 0) {
    msg = formatMessage(errMsg, { label });
  }
  if (isObject(value) && Object.keys(value).length === 0) {
    msg = formatMessage(errMsg, { label });
  }

  return msg;
};
export const minimumURLsCheck = (
  errMsg = ErrorMessages.MSG_TOOLTIP_MINIMUM_URLS_CHECK,
  items
) => (/** @type {{label, name}} */ field) => {
  const { label } = field;
  if (items >= 2) {
    return formatMessage(errMsg, { label });
  }
  return '';
};

export const totalCheck = (errMsg = ErrorMessages.MSG_NO_SPTAB, totalNum) => (
  /** @type {{label, name}} */ field
) => {
  const { label } = field;

  if (totalNum !== 100) {
    return formatMessage(errMsg, { label });
  }
  return '';
};
