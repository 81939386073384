import { compose } from 'redux';
import * as FIELD from '../domain/fields';

const getFieldDisplayKey = (field, variant = 'default') => {
  switch (field) {
    case FIELD.CATEGORY:
    case FIELD.CATEGORY_ID:
    case FIELD.CATEGORY_NAME:
      return `field.${variant}.category`;
    case FIELD.AD_GROUP1:
    case FIELD.AD_GROUP1_ID:
    case FIELD.AD_GROUP1_NAME:
      return `field.${variant}.ad_group1`;
    case FIELD.AD_GROUP2:
    case FIELD.AD_GROUP2_ID:
    case FIELD.AD_GROUP2_NAME:
      return `field.${variant}.ad_group2`;
    case FIELD.SYNC_CATEGORY:
    case FIELD.SYNC_CATEGORY_ID:
    case FIELD.SYNC_CATEGORY_NAME:
      return `field.${variant}.sync_category`;
    case FIELD.MEDIA_SIDE_CAMPAIGN:
    case FIELD.MEDIA_SIDE_CAMPAIGN_ID:
    case FIELD.MEDIA_SIDE_CAMPAIGN_NAME:
      return `field.${variant}.media_side_campaign`;
    case FIELD.MEDIA_SIDE_GROUP:
    case FIELD.MEDIA_SIDE_GROUP_ID:
    case FIELD.MEDIA_SIDE_GROUP_NAME:
      return `field.${variant}.media_side_group`;
    default:
      return `field.${variant}.${field}`;
  }
};

const getFieldVariantFunc = (i18n) => (field, variant = 'default') => {
  const displayKey = getFieldDisplayKey(field, variant);
  if (i18n.exists(displayKey)) {
    return displayKey;
  }
  return `field.default.${field}`;
};

const getFieldVariantsFunc = (i18n) => (field, variants = ['default']) => {
  const defaultKey = `field.default.${field}`;
  if (!variants) {
    return defaultKey;
  }
  for (let i = 0; i < variants.length; i += 1) {
    const displayKey = getFieldDisplayKey(field, variants[i]);
    if (i18n.exists(displayKey)) {
      return displayKey;
    }
  }

  return defaultKey;
};

const getOrDefault = (i18n, defaultValue) => (key) => {
  if (i18n && i18n.exists(key)) {
    return key;
  }
  return defaultValue;
};

const getTooltipKey = (key) => {
  return `tooltip.${key}`;
};

const getCommonPhraseKey = (key) => {
  return `phrase.${key}`;
};

const getChannelNameKey = (key) => {
  return `channel.${key}`;
};

const getTerminalTypeKey = (key) => {
  return `terminal_type.${key}`;
};

const getMeasurementTypeKey = (key) => {
  return `measurement_type.${key}`;
};

const getAdTypeKey = (key) => {
  return `ad_type.${key}`;
};

const getErrorKey = (key) => `errors.${key}`;

const getPhrase = (t) => compose(t, getCommonPhraseKey);

const getField = (t, i18n) => compose(t, getFieldVariantFunc(i18n));

const getFieldWithVariants = (t, i18n) =>
  compose(t, getFieldVariantsFunc(i18n));

const getTooltip = (t) => compose(t, getTooltipKey);

const getChannelName = (t) => compose(t, getChannelNameKey);

const getTerminalTypeName = (t) => compose(t, getTerminalTypeKey);

const getMeasurementTypeName = (t) => compose(t, getMeasurementTypeKey);

const getAdTypeName = (t) => compose(t, getAdTypeKey);

const getError = (t, i18n) => (key, defaultValue = null) => {
  return compose(t, getOrDefault(i18n, defaultValue), getErrorKey)(key);
};

const getKey = (t) => (key) => t(key);

export default {
  getTooltipKey,
  getFieldDisplayKey,
  getCommonPhraseKey,
  getField,
  getFieldWithVariants,
  getPhrase,
  getTooltip,
  getChannelName,
  getTerminalTypeName,
  getMeasurementTypeName,
  getAdTypeName,
  getKey,
  getError,
};
