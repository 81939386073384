import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import {
  MAX_USER_HAS_CONTRACT_AGENT,
  MAX_USER_NO_HAS_CONTRACT_AGENT,
} from 'domain/user-management/consts';
import useCheckPermissionAgency from 'services/custom-hooks/useCheckPermissionAgency';

function UserManagementBar({
  screenTitle,
  dragonBall,
  handleShowHideModal,
  currentUsers,
}) {
  const handleClick = () => {
    handleShowHideModal(true);
  };

  // contract permissions
  const { hasContractAgent } = useCheckPermissionAgency();

  const maxUser = useMemo(() => {
    return hasContractAgent
      ? MAX_USER_HAS_CONTRACT_AGENT
      : MAX_USER_NO_HAS_CONTRACT_AGENT;
  }, [hasContractAgent]);

  return (
    <Navbar className="navbar-user-management">
      <Navbar.Brand as="div" className="d-flex align-items-center">
        <div className="page">
          {screenTitle}
          <DragonBall variant="title" icon="far fa-question-circle" stayOpen>
            {dragonBall}
          </DragonBall>
        </div>
        <OverlayTrigger
          placement="top"
          trigger={currentUsers < maxUser ? ['hover', 'click'] : ''}
          overlay={<Tooltip className="tooltip-common">ユーザーを追加</Tooltip>}
        >
          <div
            className={`nav-link ${currentUsers < maxUser ? '' : 'disabled'}`}
            onClick={currentUsers < maxUser && handleClick}
            aria-hidden="true"
          >
            <div className="icon-add-user">
              <i className="fal fa-plus" />
            </div>
          </div>
        </OverlayTrigger>
      </Navbar.Brand>
    </Navbar>
  );
}

UserManagementBar.propTypes = {
  screenTitle: PropTypes.string.isRequired,
  handleShowHideModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dragonBall: PropTypes.object.isRequired,
  currentUsers: PropTypes.number.isRequired,
};

UserManagementBar.defaultProps = {};

export default UserManagementBar;
