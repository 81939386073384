import helper from 'store/helper';
import types from 'store/user-profile/types';
import { communicationStatus, getToday } from 'services/utils';
import { CALENDAR_DAY_FORMAT } from 'domain/consts';
import cloneDeep from 'lodash/cloneDeep';

const { IDLE, SUCCEEDED, FAILED, LOADING } = communicationStatus;

const initialState = {
  order: 'desc',
  pagenation: {
    currentPage: 1,
    totalItem: 0,
    itemsPerPage: 100,
  },
  userBehaviors: {
    status: IDLE,
  },
  userProfile: {
    status: IDLE,
  },
  param: {},
  baseDate: getToday().format(CALENDAR_DAY_FORMAT),
  adExpireTime: getToday().format(CALENDAR_DAY_FORMAT),
  userBehaviorsViewSt: {},
};

const createHierarchies = (userBehaviors, open = undefined) => {
  const { days = [] } = userBehaviors;
  return days.reduce((previousDays, currentDay) => {
    // hierarchy of the day
    return {
      ...previousDays,
      [currentDay.date]: {
        open: open === undefined ? true : open,
        // hierarchy of the inflow
        inflows: currentDay.inflows.reduce((previousInflows, currentInflow) => {
          return {
            ...previousInflows,
            [currentInflow.key]: {
              open: open === undefined ? true : open,
              permission: currentInflow.has_permission,
              // hierarchy of the access
              accesses: currentInflow.accesses.reduce(
                (previousAccesses, currentAccess) => {
                  return {
                    ...previousAccesses,
                    [currentAccess.key]: {
                      open:
                        open === undefined || !currentAccess.has_permission
                          ? false
                          : open,
                      permission: currentAccess.has_permission,
                    },
                  };
                },
                {}
              ),
            },
          };
        }, {}),
      },
    };
  }, {});
};

const openHierarchy = (state, action) => {
  const { open, day, inflow = '', access = '' } = action.payload;
  let userBehaviorsViewSt = cloneDeep(state.userBehaviorsViewSt);
  if (day in userBehaviorsViewSt) {
    if (inflow === '') {
      userBehaviorsViewSt = {
        ...userBehaviorsViewSt,
        [day]: {
          ...userBehaviorsViewSt[day],
          open,
        },
      };
    } else if (inflow in userBehaviorsViewSt[day].inflows) {
      if (access === '') {
        userBehaviorsViewSt = {
          ...userBehaviorsViewSt,
          [day]: {
            ...userBehaviorsViewSt[day],
            inflows: {
              ...userBehaviorsViewSt[day].inflows,
              [inflow]: {
                ...userBehaviorsViewSt[day].inflows[inflow],
                open,
              },
            },
          },
        };
      } else if (access in userBehaviorsViewSt[day].inflows[inflow].accesses) {
        userBehaviorsViewSt = {
          ...userBehaviorsViewSt,
          [day]: {
            ...userBehaviorsViewSt[day],
            inflows: {
              ...userBehaviorsViewSt[day].inflows,
              [inflow]: {
                ...userBehaviorsViewSt[day].inflows[inflow],
                accesses: {
                  ...userBehaviorsViewSt[day].inflows[inflow].accesses,
                  [access]: {
                    ...userBehaviorsViewSt[day].inflows[inflow].accesses[
                      access
                    ],
                    open: userBehaviorsViewSt[day].inflows[inflow].accesses[
                      access
                    ].permission
                      ? open
                      : false,
                  },
                },
              },
            },
          },
        };
      }
    }
  }
  return {
    ...state,
    userBehaviorsViewSt,
  };
};

const openHierarchyAll = (state, action) => {
  const { open } = action.payload;
  return {
    ...state,
    userBehaviorsViewSt: createHierarchies(state.userBehaviors, open),
  };
};

const requestUserBehaviors = (state) => {
  return {
    ...state,
    userBehaviors: {
      ...state.userBehaviors,
      status: LOADING,
    },
  };
};

const fetchReportUserBehaviorsFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    userBehaviors: {
      ...state.userBehaviors,
      status: FAILED,
      error,
    },
  };
};

const fetchReportUserBehaviorsSucceeded = (state, action) => {
  const userBehaviors = action.payload;
  return {
    ...state,
    userBehaviors: {
      ...userBehaviors,
      status: SUCCEEDED,
    },
    userBehaviorsViewSt: createHierarchies(userBehaviors),
  };
};

const requestUserProfile = (state) => {
  return {
    ...state,
    userProfile: {
      ...state.userProfile,
      status: LOADING,
    },
  };
};

const fetchReportUserProfileFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    userProfile: {
      ...state.userProfile,
      status: FAILED,
      error,
    },
  };
};

const fetchReportUserProfileSucceeded = (state, action) => {
  const userProfile = action.payload;
  return {
    ...state,
    userProfile: {
      status: SUCCEEDED,
      ...userProfile,
    },
    pagenation: {
      ...state.pagenation,
      totalItem: userProfile.total_record_count,
    },
  };
};

const updateSortOrder = (state, action) => {
  return {
    ...state,
    order: action.payload,
  };
};

const updateBaseDate = (state, action) => {
  return {
    ...state,
    baseDate: action.payload,
  };
};

const updateAdExpireTime = (state, action) => {
  return {
    ...state,
    adExpireTime: action.payload,
  };
};

const updatePage = (state, action) => {
  return {
    ...state,
    pagenation: {
      ...state.pagenation,
      currentPage: action.payload,
    },
  };
};
const updateParameter = (state, action) => {
  return {
    ...state,
    param: action.payload,
  };
};

const UserProfileReducer = helper.createReducer(initialState, {
  [types.FETCH_REPORT_USER_BEHAVIORS_SUCCESS]: fetchReportUserBehaviorsSucceeded,
  [types.FETCH_REPORT_USER_PROFILE_SUCCESS]: fetchReportUserProfileSucceeded,
  [types.FETCH_REPORT_USER_BEHAVIORS_ERROR]: fetchReportUserBehaviorsFailed,
  [types.FETCH_REPORT_USER_PROFILE_ERROR]: fetchReportUserProfileFailed,
  [types.UPDATE_SORT_ORDER]: updateSortOrder,
  [types.UPDATE_BASE_DATE]: updateBaseDate,
  [types.UPDATE_AD_EXPIRE_TIME]: updateAdExpireTime,
  [types.UPDATE_PAGE]: updatePage,
  [types.REQUEST_USER_BEHAVIORS]: requestUserBehaviors,
  [types.REQUEST_USER_PROFILE]: requestUserProfile,
  [types.UPDATE_PARAMETER]: updateParameter,
  [types.OPEN_HIERARCHY]: openHierarchy,
  [types.OPEN_HIERARCHY_ALL]: openHierarchyAll,
});

export default UserProfileReducer;
