import React from 'react';
import { Form } from 'react-bootstrap';
import { oneOf, oneOfType, string, number, bool, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import DragonBall from 'views/atoms/dragonball/DragonBall';

import './ebis-custom-checkbox.scss';

function EbisCustomCheckbox(props) {
  const {
    id,
    label,
    variant,
    checked,
    disabled,
    onChange,
    onClick,
    dragonBall,
  } = props;

  const formCheckClassName = classNames({
    'form-checkbox': true,
    [`form-checkbox--${variant}`]: !isEmpty(variant) && checked && !disabled,
  });

  return (
    <span className="form-label">
      <Form.Check
        inline
        type="checkbox"
        className={formCheckClassName}
        id={`chk-${id}`}
        label={label || null}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        dragonBall={dragonBall}
      />
      {dragonBall !== '' && <DragonBall>{dragonBall}</DragonBall>}
    </span>
  );
}

EbisCustomCheckbox.propTypes = {
  id: oneOfType([string, number]),
  label: string,
  variant: oneOf(['', 'mixed']),
  checked: bool,
  disabled: bool,
  onChange: func,
  onClick: func,
  dragonBall: string,
};

EbisCustomCheckbox.defaultProps = {
  id: Math.random(),
  label: '',
  variant: '',
  checked: false,
  disabled: false,
  onChange: () => {},
  onClick: () => {},
  dragonBall: '',
};

export default EbisCustomCheckbox;
