import Api from './Api';

const AppVersionFactory = () => {
  const url = '/version.json';

  return {
    url,
    getVersion: async () => {
      return Api.get(url, { baseURL: '/' });
    },
  };
};

const AppVersionApi = AppVersionFactory();
export default AppVersionApi;
