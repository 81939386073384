import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import adManagementSelectors from 'store/ad-management/selectors';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import AdManagementContainer from 'views/pages/ad-management/AdManagementContainer';
import adManagementActions from 'store/ad-management/actions';

/**
 * Will trigger loading when active tab and path is not matching.
 * Also dispatch change tab action
 * @return {JSX.Element}
 */
function AdManagementLoader() {
  const { mode } = useParams();
  const tab = useSelector(adManagementSelectors.getTab);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tab !== mode) {
      dispatch(adManagementActions.cleanStates(mode));
      dispatch(adManagementActions.changeTab(mode));
    }
  }, [dispatch, tab, mode])

  return (
    <DataSyncLoader isLoading={mode !== tab}>
      <AdManagementContainer />
    </DataSyncLoader>
  );
}

export default AdManagementLoader;
