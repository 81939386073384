import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { bool, func } from 'prop-types';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';

import './ad-delete-trash-confirm-modal.scss';

function AdDeleteTrashConfirmModal(props) {
  const { isShow, isLoading, onHide, onSubmit } = props;

  const [isCheckedConfirm, setCheckedConfirm] = useState(false);

  return (
    <Modal
      centered
      show={isShow}
      backdrop="static"
      size="w600"
      className="modal--delete-trash"
    >
      <Modal.Header>
        <Modal.Title>広告を完全に削除しますか？</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={isLoading}>
          広告データを完全に削除した場合、復活させることができなくなります。
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        <EbisCheckbox
          name="confirm"
          text="広告データを完全に削除しても問題ありません"
          checked={isCheckedConfirm}
          disabled={isLoading}
          changeSelectBoxValue={() => setCheckedConfirm(!isCheckedConfirm)}
        />
        <Button
          size="sm"
          variant="link"
          disabled={isLoading}
          onClick={() => {
            onHide();
            setCheckedConfirm(false);
          }}
        >
          キャンセル
        </Button>
        <Button
          disabled={!isCheckedConfirm || isLoading}
          size="sm"
          variant="secondary"
          onClick={() => {
            onSubmit();
            setCheckedConfirm(false);
          }}
        >
          削除
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AdDeleteTrashConfirmModal.propTypes = {
  isShow: bool.isRequired,
  isLoading: bool,
  onHide: func,
  onSubmit: func,
};

AdDeleteTrashConfirmModal.defaultProps = {
  isLoading: false,
  onHide: () => {},
  onSubmit: () => {},
};

export default AdDeleteTrashConfirmModal;
