/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';

import { MASTER_DATA_FILTER_LIMIT } from 'domain/consts';
import SelectPopupMultiple from './SelectPopupMultiple';
import SelectPopupSingle from './SelectPopupSingle';

const SelectPopup = ({ multiple, ...props }) => {
  if (multiple) {
    return <SelectPopupMultiple {...props} />;
  }
  return <SelectPopupSingle {...props} />;
};

/*
options = {
  '1': { value: '1', label: '洗顔' },
  '2': { value: '2', label: '化粧水' },
};
onChange = ({ value: '1', label: '洗顔' })
onSearch = ('word')
*/
SelectPopup.propTypes = {
  title: string.isRequired,
  multiple: bool,
  displayMax: number,
  value: typeof [number, shape({})],
  options: shape({}).isRequired,
  onChange: func,
  onSearch: func,
  error: shape({}),
};
SelectPopup.defaultProps = {
  value: undefined,
  multiple: false,
  displayMax: MASTER_DATA_FILTER_LIMIT,
  onChange: () => {},
  onSearch: () => {},
  error: { result: true, message: '' },
};

export default SelectPopup;
