import moment from 'moment';

const hasNewVersion = (state) => {
  return state.appVersion.versionLastest !== process.env.REACT_APP_VERSION;
};

/**
 * Case use already reloaded, but can not get new version
 * Do not force reload in next 1 hour
 */
const canReload = (state) => {
  const { reloaded, reloadedLastest } = state.appVersion;
  const reloadedLastestMoment = moment(reloadedLastest, 'YYYY-MM-DD hh:mm:ss');
  const diffHours = moment().diff(reloadedLastestMoment, 'minutes');
  return reloaded === false || (reloaded === true && diffHours >= 60);
};

export default {
  hasNewVersion,
  canReload,
};
