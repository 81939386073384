import { SettingsApi } from 'services/api/Api';
import axios from 'axios';
import { USER_ENDPOINT } from 'services/routes/constants';
import apiUtils from 'services/apiUtils';
import { HttpStatus } from 'services/utils';
import {
  GOOGLE_AUTH_ERROR,
  GOOGLE_DRIVE_ABOUT_V3,
  SEARCH_CONSOLE_WEB_MASTER,
} from 'domain/consts';

const { BAD_REQUEST } = HttpStatus;

const GoogleApiFactory = () => {
  const url = USER_ENDPOINT;
  return {
    fetchToken: async (code, clientId, redirectUri) => {
      try {
        const response = await SettingsApi.get(
          apiUtils.buildUrlWithQueryStrings(`${url}/google/verify`, {
            code,
            client_id: clientId,
            redirect_uri: redirectUri,
          })
        );
        return response.data;
      } catch (error) {
        const { response } = error || {};
        if (response.status === BAD_REQUEST) {
          return response.data;
        }
        return { errors: [{ code: GOOGLE_AUTH_ERROR }] };
      }
    },
    fetchDriveV3About: async (accessToken) => {
      return axios
        .get(`${GOOGLE_DRIVE_ABOUT_V3}?fields=user(displayName,emailAddress)`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => response.data)
        .catch((error) => error.response.data);
    },
    fetchSCProperties: async (accessToken) => {
      return axios
        .get(SEARCH_CONSOLE_WEB_MASTER, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => response)
        .catch((error) => error.response.data);
    },
    getLoginCustomerIDs: async (refreshToken, clientId) => {
      try {
        const response = await SettingsApi.get(
          apiUtils.buildUrlWithQueryStrings(`${url}/google/accounts`, {
            refresh_token: refreshToken,
            client_id: clientId,
          })
        );
        return response.data;
      } catch (error) {
        const { response } = error || {};
        if (response.status === BAD_REQUEST) {
          return response.data;
        }
        return { errors: [{ code: GOOGLE_AUTH_ERROR }] };
      }
    },
  };
};

const GoogleApi = GoogleApiFactory();
export default GoogleApi;
