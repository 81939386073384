import { createSelector } from 'reselect';
import { omit } from 'lodash';
import { DEFAULT_DETAIL_SETTING } from 'domain/media-sync-management/consts';
import { getAgentFlag, getAgencyId, getAgencyName } from 'store/auth/selectors';
import masterdataSelectors from 'store/master-data/selectors';
import { AGENCY } from 'domain/fields';
import { UNREGISTERED_ID_FILTER_KEY } from 'domain/consts';
import { FILTER_KEY_AGENCY, FILTER_KEY_MEDIA } from 'services/consts';
import { communicationStatus } from 'services/utils';
import settingsSelectors from 'store/settings/selectors';
import { prepareDataRequestForUI } from 'domain/media-sync-management/Services';

const getSort = (state) => state.cache.mediaSyncManagement.data.table.sort;
const getDetail = (state) => state.cache.mediaSyncManagement.data.detail;
const getAccount = (state) => state.cache.mediaSyncManagement.statusAccount;
const getStatusDetail = (state) =>
  state.cache.mediaSyncManagement.apiStatus.detail;

const getMediaSyncManagementState = createSelector(
  [(state) => state.cache.mediaSyncManagement],
  (state) => {
    const {
      data: {
        table: { list: tableData, sort, count },
        detail: detailData,
      },
      apiStatus: {
        table: tableStatus,
        detail: detailStatus,
        validate: validateStatus,
        update: updateStatus,
        delete: deleteStatus,
        stop: stopStatus,
      },
      statusAccount,
    } = state;
    return {
      tableData,
      detailData,
      apiTableStatus: tableStatus,
      apiDetailStatus: detailStatus,
      apiValidateStatus: validateStatus,
      apiUpdateStatus: updateStatus,
      apiDeleteStatus: deleteStatus,
      apiStopStatus: stopStatus,
      sort,
      statusAccount,
      count,
    };
  }
);

const getDefaultDetail = createSelector(
  [getDetail, getAgentFlag, getAgencyId, getAgencyName],
  (data, isAgent, agencyId, agencyName) => {
    if (data?.id) {
      return prepareDataRequestForUI(data);
    }

    const agency = isAgent ? { id: agencyId, name: agencyName } : null;

    return { ...DEFAULT_DETAIL_SETTING, [AGENCY]: agency };
  }
);

const getMasterdata = createSelector(
  [
    masterdataSelectors.getUsers,
    masterdataSelectors.getMedia,
    masterdataSelectors.getAgency,
    masterdataSelectors.getSearchResult,
    masterdataSelectors.getSearchType,
    masterdataSelectors.getStatus,
  ],
  (userData, mediaData, agencyData, searchResult, searchType, status) => {
    const loading = status === communicationStatus.LOADING;

    const mediaOptions =
      searchType === FILTER_KEY_MEDIA
        ? searchResult
        : omit(mediaData, UNREGISTERED_ID_FILTER_KEY);

    const agencyOptions =
      searchType === FILTER_KEY_AGENCY
        ? searchResult
        : omit(agencyData, UNREGISTERED_ID_FILTER_KEY);

    return {
      media: {
        loading: loading && (!searchType || searchType === FILTER_KEY_MEDIA),
        options: mediaOptions,
      },
      agency: {
        loading: loading && (!searchType || searchType === FILTER_KEY_AGENCY),
        options: agencyOptions,
      },
      user: { loading: loading && !searchType, options: userData },
    };
  }
);

const getStateForSettings = createSelector(
  [
    settingsSelectors.getLoadingByStatus([getStatusDetail]),
    getDefaultDetail,
    getMasterdata,
  ],
  (isLoading, defaultData, masterdata) => {
    return {
      isLoading,
      defaultData,
      masterdata,
    };
  }
);

const getListAdvertiser = (state) =>
  state.cache.mediaSyncManagement.data.advertiserList;
const getAccountAdvertiserInfo = (state) =>
  state.cache.mediaSyncManagement.data.accountAdvertiser;
const getMargins = (state) =>
  state.cache.mediaSyncManagement.data.media_account;
const getStatusListAdvertiser = (state) =>
  state.cache.mediaSyncManagement.apiStatus.advertiser;
const getStatusMargins = (state) =>
  state.cache.mediaSyncManagement.apiStatus.margin;
const getStatusAuthLine = (state) =>
  state.cache.mediaSyncManagement.apiStatus.authLine;
const getStateMargins = createSelector(
  [getMargins, settingsSelectors.getLoadingByStatus([getStatusMargins])],
  (margins, isLoadingMargins) => ({ margins, isLoadingMargins })
);

const getStateAdveriser = createSelector(
  [
    getListAdvertiser,
    getAccountAdvertiserInfo,
    settingsSelectors.getLoadingByStatus(
      [getStatusListAdvertiser],
      [communicationStatus.IDLE]
    ),
  ],
  (advertiserList, accountAdvertiser, isLoadingAd) => ({
    advertiserList,
    accountAdvertiser,
    isLoadingAd,
  })
);

export default {
  getSort,
  getMediaSyncManagementState,
  getStateForSettings,
  getAccount,
  getListAdvertiser,
  getStateMargins,
  getStateAdveriser,
  getStatusAuthLine,
};
