import * as DisplayItems from './display-items';

const {
  LANDING_PAGE_URL,
  LANDING_PAGE_DOMAIN,
  CONTENT_CATEGORY,
  CHANNEL,
  CATEGORY,
  AD_GROUP1,
  AD_GROUP2,
  SYNC_CATEGORY,
  MEDIA_SIDE_CAMPAIGN,
  MEDIA_SIDE_GROUP,
  SEARCH_WORD,
  LANDING_PAGE_TITLE,
} = DisplayItems;

const SettingDisplayItems = {
  groupList: {
    [DisplayItems.LANDING_PAGE_URL]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.SEARCH_WORD]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.LANDING_PAGE_DOMAIN]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.CONTENT_CATEGORY]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.CATEGORY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AD_GROUP1]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AD_GROUP2]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.LANDING_PAGE_TITLE]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.SYNC_CATEGORY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.MEDIA_SIDE_GROUP]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  denyMediaSyncList: [
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
  ],
  denyEbisList: [
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
  ],
  displayFreezeList: [
    DisplayItems.LANDING_PAGE_DOMAIN,
    DisplayItems.CONTENT_CATEGORY,
    DisplayItems.LANDING_PAGE_URL,
    DisplayItems.LANDING_PAGE_TITLE,
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
  ],
  orderList: {
    [DisplayItems.LANDING_PAGE_DOMAIN]: 1,
    [DisplayItems.CONTENT_CATEGORY]: 2,
    [DisplayItems.LANDING_PAGE_URL]: 3,
    [DisplayItems.CHANNEL]: 4,
    [DisplayItems.CATEGORY]: 5,
    [DisplayItems.AD_GROUP1]: 6,
    [DisplayItems.AD_GROUP2]: 7,
    [DisplayItems.LANDING_PAGE_TITLE]: 8,
    [DisplayItems.SYNC_CATEGORY]: 5,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]: 6,
    [DisplayItems.MEDIA_SIDE_GROUP]: 7,
    [DisplayItems.SEARCH_WORD]: 3,
    [DisplayItems.CNT_ACCESS]: 8,
    [DisplayItems.CNT_CV_DETAIL]: 9,
    [DisplayItems.CVR_DETAIL]: 10,
    [DisplayItems.CNT_CV_TOTAL]: 11,
    [DisplayItems.CVR_TOTAL]: 12,
    [DisplayItems.CNT_INDIRECT2]: 13,
    [DisplayItems.CNT_INDIRECT3]: 14,
    [DisplayItems.CNT_INDIRECT4]: 15,
    [DisplayItems.CNT_INDIRECT5]: 16,
    [DisplayItems.CNT_INDIRECT_OTHER]: 17,
    [DisplayItems.INDIRECT_TOTAL]: 18,
    [DisplayItems.DIRECT_RATE]: 19,
    [DisplayItems.INDIRECT_RATE]: 20,
    [DisplayItems.CNT_FIRST]: 21,
    [DisplayItems.RCV]: 22,
    [DisplayItems.AMOUNT]: 23,
    [DisplayItems.RAMOUNT]: 24,
  },
  displayDefaultList: [
    DisplayItems.LANDING_PAGE_URL,
    DisplayItems.CNT_CV_DETAIL,
    DisplayItems.CNT_CV_TOTAL,
    DisplayItems.CVR_DETAIL,
    DisplayItems.CVR_TOTAL,
    DisplayItems.CNT_INDIRECT2,
    DisplayItems.CNT_INDIRECT3,
    DisplayItems.CNT_INDIRECT4,
    DisplayItems.CNT_INDIRECT5,
    DisplayItems.CNT_INDIRECT_OTHER,
    DisplayItems.INDIRECT_TOTAL,
    DisplayItems.CNT_FIRST,
    DisplayItems.RCV,
    DisplayItems.AMOUNT,
  ],
  sortDefaultList: {
    [DisplayItems.CNT_CV_TOTAL]: DisplayItems.SORT_DIRECTION_DESC,
  },
};

const SettingAdDisplayItems = {
  ...SettingDisplayItems,
  displayDefaultList: [...SettingDisplayItems.displayDefaultList],
};
const SettingAllDisplayItems = {
  ...SettingDisplayItems,
  groupList: {
    ...SettingDisplayItems.groupList,
    [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  displayFreezeList: [
    ...SettingDisplayItems.displayFreezeList,
    DisplayItems.CHANNEL,
    DisplayItems.SEARCH_WORD,
  ],
  displayDefaultList: [...SettingDisplayItems.displayDefaultList],
};

const SettingAdAxis = [
  {
    required: true,
    label: '1列目',
    name: 'axis1',
    callback: (selected, option) => {
      switch (selected) {
        case LANDING_PAGE_URL:
          return {
            ...option,
            [LANDING_PAGE_DOMAIN]: {
              ...option[LANDING_PAGE_DOMAIN],
              allowAxis: ['axis1'],
            },
            [CONTENT_CATEGORY]: {
              ...option[CONTENT_CATEGORY],
              title: 'コンテンツカテゴリ（表示項目）',
            },
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              title: 'ランディングページタイトル（表示項目）',
              allowAxis: ['axis2'],
            },
          };

        case LANDING_PAGE_DOMAIN:
          return {
            ...option,
            [LANDING_PAGE_URL]: {
              ...option[LANDING_PAGE_URL],
              allowAxis: ['axis1'],
            },
            [DisplayItems.LANDING_PAGE_TITLE]: {
              ...option[DisplayItems.LANDING_PAGE_TITLE],
              allowAxis: [],
            },
          };
        default:
          return {
            ...option,
            [DisplayItems.LANDING_PAGE_TITLE]: {
              ...option[DisplayItems.LANDING_PAGE_TITLE],
              allowAxis: [],
            },
          };
      }
    },
  },
  {
    label: '2列目',
    name: 'axis2',
    callback: (selected, option) => {
      switch (selected) {
        /*
          case LANDING_PAGE_DOMAIN:
            return {
              ...option,
              [LANDING_PAGE_URL]: {
                ...option[LANDING_PAGE_URL],
                allowAxis: ['axis3'],
              },
            };
        */
        case CATEGORY:
          return {
            ...option,
            [AD_GROUP2]: { ...option[AD_GROUP2], allowAxis: ['axis2'] },
          };
        case AD_GROUP1:
        case AD_GROUP2: {
          const keyApply = selected === AD_GROUP1 ? AD_GROUP2 : AD_GROUP1;
          return {
            ...option,
            [CATEGORY]: { ...option[CATEGORY], allowAxis: ['axis2'] },
            [keyApply]: { ...option[keyApply], allowAxis: ['axis2'] },
          };
        }
        case SYNC_CATEGORY:
          return {
            ...option,
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: ['axis2'],
            },
          };
        case MEDIA_SIDE_CAMPAIGN:
        case MEDIA_SIDE_GROUP: {
          const keyApply =
            selected === MEDIA_SIDE_CAMPAIGN
              ? MEDIA_SIDE_GROUP
              : MEDIA_SIDE_CAMPAIGN;
          return {
            ...option,
            [SYNC_CATEGORY]: { ...option[SYNC_CATEGORY], allowAxis: ['axis2'] },
            [keyApply]: { ...option[keyApply], allowAxis: ['axis2'] },
          };
        }
        case LANDING_PAGE_URL:
          return {
            ...option,
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              title: 'ランディングページタイトル（表示項目）',
              allowAxis: ['axis3'],
            },
          };
        default:
          return option;
      }
    },
  },
  {
    label: '3列目',
    name: 'axis3',
  },
];

const SettingAllAxis = [
  {
    required: true,
    label: '1列目',
    name: 'axis1',
    callback: (selected, option) => {
      switch (selected) {
        case LANDING_PAGE_URL:
          return {
            ...option,
            [LANDING_PAGE_DOMAIN]: {
              ...option[LANDING_PAGE_DOMAIN],
              allowAxis: ['axis1'],
            },
            [CONTENT_CATEGORY]: {
              ...option[CONTENT_CATEGORY],
              title: 'コンテンツカテゴリ（表示項目）',
            },
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              title: 'ランディングページタイトル（表示項目）',
              allowAxis: ['axis2'],
            },
          };

        case LANDING_PAGE_DOMAIN:
          return {
            ...option,
            [LANDING_PAGE_URL]: {
              ...option[LANDING_PAGE_URL],
              allowAxis: ['axis1'],
            },
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              allowAxis: [],
            },
          };
        default:
          return {
            ...option,
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              allowAxis: [],
            },
          };
      }
    },
  },
  {
    label: '2列目',
    name: 'axis2',
    callback: (selected, option, selectedList = []) => {
      const isIncludeSearchWord = selectedList.includes(SEARCH_WORD);
      const isIncludeSearchWordInAxis3 =
        selectedList.includes(SEARCH_WORD) &&
        selectedList.indexOf(SEARCH_WORD) !== 2;
      const axis3ForSelectedChannel = isIncludeSearchWord ? [] : ['axis3'];
      switch (selected) {
        case CHANNEL:
          return {
            ...option,
            [CATEGORY]: { ...option[CATEGORY], allowAxis: ['axis3'] },
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: axis3ForSelectedChannel,
            },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: axis3ForSelectedChannel,
            },
            [SYNC_CATEGORY]: { ...option[SYNC_CATEGORY], allowAxis: ['axis3'] },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: axis3ForSelectedChannel,
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: axis3ForSelectedChannel,
            },
            [SEARCH_WORD]: { ...option[SEARCH_WORD], allowAxis: [] },
          };
        case LANDING_PAGE_DOMAIN:
          return {
            ...option,
            [CONTENT_CATEGORY]: {
              ...option[CONTENT_CATEGORY],
              allowAxis: isIncludeSearchWord
                ? ['axis3']
                : option[CONTENT_CATEGORY]?.allowAxis || [],
            },
          };
        case LANDING_PAGE_URL:
          return {
            ...option,
            [CONTENT_CATEGORY]: {
              ...option[CONTENT_CATEGORY],
              allowAxis: isIncludeSearchWord
                ? ['axis3']
                : option[CONTENT_CATEGORY]?.allowAxis || [],
              title: 'コンテンツカテゴリ（表示項目）',
            },
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              title: 'ランディングページタイトル（表示項目）',
              allowAxis: ['axis3'],
            },
          };
        case CONTENT_CATEGORY:
          return {
            ...option,
            [CONTENT_CATEGORY]: {
              ...option[CONTENT_CATEGORY],
              allowAxis: isIncludeSearchWordInAxis3
                ? ['axis3']
                : option[CONTENT_CATEGORY]?.allowAxis || [],
              title: 'コンテンツカテゴリ（表示項目）',
            },
            [LANDING_PAGE_URL]: {
              ...option[LANDING_PAGE_URL],
              allowAxis: isIncludeSearchWordInAxis3
                ? ['axis3']
                : option[LANDING_PAGE_URL].allowAxis,
            },
            [LANDING_PAGE_DOMAIN]: {
              ...option[LANDING_PAGE_DOMAIN],
              allowAxis: isIncludeSearchWordInAxis3
                ? ['axis3']
                : option[LANDING_PAGE_DOMAIN].allowAxis,
            },
          };
        default:
          return option;
      }
    },
  },
  {
    label: '3列目',
    name: 'axis3',
    callback: (selected, option, selectedList = []) => {
      const isIncludeSearchWord = selectedList.includes(SEARCH_WORD);
      const axis4ForSelectedChannel =
        selected === SEARCH_WORD &&
        selectedList.indexOf(LANDING_PAGE_DOMAIN) === 0 &&
        selectedList.indexOf(CONTENT_CATEGORY) === 1
          ? []
          : ['axis4'];
      switch (selected) {
        case CATEGORY:
          return {
            ...option,
            [AD_GROUP2]: { ...option[AD_GROUP2], allowAxis: ['axis3'] },
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: isIncludeSearchWord ? [] : option[AD_GROUP1].allowAxis,
            },
          };
        case AD_GROUP1:
        case AD_GROUP2: {
          const keyApply = selected === AD_GROUP1 ? AD_GROUP2 : AD_GROUP1;
          return {
            ...option,
            [CATEGORY]: { ...option[CATEGORY], allowAxis: ['axis3'] },
            [keyApply]: { ...option[keyApply], allowAxis: ['axis3'] },
          };
        }
        case SYNC_CATEGORY:
          return {
            ...option,
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: ['axis3'],
            },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: isIncludeSearchWord
                ? []
                : option[MEDIA_SIDE_CAMPAIGN].allowAxis,
            },
          };
        case MEDIA_SIDE_CAMPAIGN:
        case MEDIA_SIDE_GROUP: {
          const keyApply =
            selected === MEDIA_SIDE_CAMPAIGN
              ? MEDIA_SIDE_GROUP
              : MEDIA_SIDE_CAMPAIGN;
          return {
            ...option,
            [SYNC_CATEGORY]: { ...option[SYNC_CATEGORY], allowAxis: ['axis3'] },
            [keyApply]: { ...option[keyApply], allowAxis: ['axis3'] },
          };
        }
        case CHANNEL: {
          if (AD_GROUP2 in option) {
            return {
              ...option,
              [AD_GROUP2]: {
                ...option[AD_GROUP2],
                allowAxis: isIncludeSearchWord
                  ? []
                  : option[AD_GROUP2].allowAxis,
              },
              [AD_GROUP1]: {
                ...option[AD_GROUP1],
                allowAxis: isIncludeSearchWord
                  ? []
                  : option[AD_GROUP1].allowAxis,
              },
            };
          }
          if (MEDIA_SIDE_CAMPAIGN in option) {
            return {
              ...option,
              [MEDIA_SIDE_CAMPAIGN]: {
                ...option[MEDIA_SIDE_CAMPAIGN],
                allowAxis: isIncludeSearchWord
                  ? []
                  : option[MEDIA_SIDE_CAMPAIGN].allowAxis,
              },
              [MEDIA_SIDE_GROUP]: {
                ...option[MEDIA_SIDE_GROUP],
                allowAxis: isIncludeSearchWord
                  ? []
                  : option[MEDIA_SIDE_GROUP].allowAxis,
              },
            };
          }
          return {
            ...option,
          };
        }
        case CONTENT_CATEGORY:
        case LANDING_PAGE_URL:
        case LANDING_PAGE_DOMAIN:
        case SEARCH_WORD:
          return {
            ...option,
            [CHANNEL]: {
              ...option[CHANNEL],
              allowAxis: axis4ForSelectedChannel,
            },
            [CATEGORY]: { ...option[CATEGORY], allowAxis: [] },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
            [SYNC_CATEGORY]: { ...option[SYNC_CATEGORY], allowAxis: [] },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
          };
        case LANDING_PAGE_TITLE:
          return {
            ...option,
            [CHANNEL]: {
              ...option[CHANNEL],
              allowAxis: [],
            },
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: [],
            },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: [],
            },
            [CATEGORY]: {
              ...option[CATEGORY],
              allowAxis: [],
            },
            [SYNC_CATEGORY]: { ...option[SYNC_CATEGORY], allowAxis: [] },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: [],
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: [],
            },
          };
        default:
          return option;
      }
    },
  },
  {
    label: '4列目',
    name: 'axis4',
  },
];

const SettingCommonAxis = [
  {
    required: true,
    label: '1列目',
    name: 'axis1',
    callback: (selected, option) => {
      switch (selected) {
        case LANDING_PAGE_URL:
          return {
            ...option,
            [LANDING_PAGE_DOMAIN]: {
              ...option[LANDING_PAGE_DOMAIN],
              allowAxis: ['axis1'],
            },
            [CONTENT_CATEGORY]: {
              ...option[CONTENT_CATEGORY],
              title: 'コンテンツカテゴリ（表示項目）',
            },
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              title: 'ランディングページタイトル（表示項目）',
              allowAxis: ['axis2'],
            },
          };
        case LANDING_PAGE_DOMAIN:
          return {
            ...option,
            [LANDING_PAGE_URL]: {
              ...option[LANDING_PAGE_URL],
              allowAxis: ['axis1'],
            },
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              allowAxis: [],
            },
          };
        case SEARCH_WORD:
          return {
            ...option,
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: [],
            },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: [],
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: [],
            },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: [],
            },
            [CATEGORY]: {
              ...option[CATEGORY],
              allowAxis: ['axis3'],
            },
            [SYNC_CATEGORY]: {
              ...option[SYNC_CATEGORY],
              allowAxis: ['axis3'],
            },
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              allowAxis: [],
            },
          };

        default:
          return {
            ...option,
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              allowAxis: [],
            },
          };
      }
    },
  },
  {
    label: '2列目',
    name: 'axis2',
    callback: (selected, option, selectedList = []) => {
      const isIncludeSearchWord = selectedList.includes(SEARCH_WORD);
      const isIncludeSearchWordInAxis3 =
        selectedList.includes(SEARCH_WORD) &&
        selectedList.indexOf(SEARCH_WORD) !== 2;
      const axis3ForSelectedChannel = isIncludeSearchWord ? [] : ['axis3'];
      switch (selected) {
        case CHANNEL:
          return {
            ...option,
            [CATEGORY]: {
              ...option[CATEGORY],
              title: 'カテゴリ',
              allowAxis: ['axis3'],
            },
            [SYNC_CATEGORY]: {
              ...option[SYNC_CATEGORY],
              title: 'カテゴリ',
              allowAxis: ['axis3'],
            },
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: axis3ForSelectedChannel,
            },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: axis3ForSelectedChannel,
            },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: axis3ForSelectedChannel,
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: axis3ForSelectedChannel,
            },
            [SEARCH_WORD]: { ...option[SEARCH_WORD], allowAxis: [] },
          };
        case CATEGORY:
          return {
            ...option,
            [CHANNEL]: { ...option[CHANNEL], allowAxis: ['axis2'] },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: isIncludeSearchWord ? [] : ['axis2'],
            },
            [SEARCH_WORD]: { ...option[SEARCH_WORD], allowAxis: [] },
          };
        case AD_GROUP1:
        case AD_GROUP2: {
          const keyApply = selected === AD_GROUP1 ? AD_GROUP2 : AD_GROUP1;
          return {
            ...option,
            [CHANNEL]: { ...option[CHANNEL], allowAxis: ['axis2'] },
            [CATEGORY]: { ...option[CATEGORY], allowAxis: ['axis2'] },
            [SEARCH_WORD]: { ...option[SEARCH_WORD], allowAxis: [] },
            [keyApply]: { ...option[keyApply], allowAxis: ['axis2'] },
          };
        }
        case SYNC_CATEGORY:
          return {
            ...option,
            [CHANNEL]: { ...option[CHANNEL], allowAxis: ['axis2'] },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: isIncludeSearchWord ? [] : ['axis2'],
            },
            [SEARCH_WORD]: { ...option[SEARCH_WORD], allowAxis: [] },
          };
        case MEDIA_SIDE_CAMPAIGN:
        case MEDIA_SIDE_GROUP: {
          const keyApply =
            selected === MEDIA_SIDE_CAMPAIGN
              ? MEDIA_SIDE_GROUP
              : MEDIA_SIDE_CAMPAIGN;
          return {
            ...option,
            [CHANNEL]: { ...option[CHANNEL], allowAxis: ['axis2'] },
            [SYNC_CATEGORY]: { ...option[SYNC_CATEGORY], allowAxis: ['axis2'] },
            [SEARCH_WORD]: { ...option[SEARCH_WORD], allowAxis: [] },
            [keyApply]: { ...option[keyApply], allowAxis: ['axis2'] },
          };
        }
        case LANDING_PAGE_DOMAIN:
        case LANDING_PAGE_URL:
          return {
            ...option,
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP1]?.allowAxis || [],
            },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: isIncludeSearchWord
                ? []
                : option[MEDIA_SIDE_CAMPAIGN]?.allowAxis || [],
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: isIncludeSearchWord
                ? []
                : option[MEDIA_SIDE_GROUP]?.allowAxis || [],
            },
            [CATEGORY]: {
              ...option[CATEGORY],
              allowAxis: isIncludeSearchWord ? ['axis4'] : ['axis3'],
            },
            [SYNC_CATEGORY]: {
              ...option[SYNC_CATEGORY],
              allowAxis: isIncludeSearchWord ? ['axis4'] : ['axis3'],
            },
            [CONTENT_CATEGORY]: {
              ...option[CONTENT_CATEGORY],
              allowAxis: isIncludeSearchWord
                ? ['axis3']
                : option[CONTENT_CATEGORY].allowAxis,
              title:
                selected === LANDING_PAGE_URL
                  ? 'コンテンツカテゴリ（表示項目）'
                  : 'コンテンツカテゴリ',
            },
            [LANDING_PAGE_TITLE]: {
              ...option[LANDING_PAGE_TITLE],
              title: 'ランディングページタイトル（表示項目）',
              allowAxis: selected === LANDING_PAGE_URL ? ['axis3'] : [],
            },
          };
        case CONTENT_CATEGORY:
          return {
            ...option,
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP1]?.allowAxis || [],
            },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: isIncludeSearchWord
                ? []
                : option[MEDIA_SIDE_CAMPAIGN]?.allowAxis || [],
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: isIncludeSearchWord
                ? []
                : option[MEDIA_SIDE_GROUP]?.allowAxis || [],
            },
            [CATEGORY]: {
              ...option[CATEGORY],
              allowAxis: isIncludeSearchWord ? ['axis4'] : ['axis3'],
            },
            [SYNC_CATEGORY]: {
              ...option[SYNC_CATEGORY],
              allowAxis: isIncludeSearchWord ? ['axis4'] : ['axis3'],
            },
            [LANDING_PAGE_URL]: {
              ...option[LANDING_PAGE_URL],
              allowAxis: isIncludeSearchWordInAxis3
                ? ['axis3']
                : option[LANDING_PAGE_URL].allowAxis,
            },
            [LANDING_PAGE_DOMAIN]: {
              ...option[LANDING_PAGE_DOMAIN],
              allowAxis: isIncludeSearchWordInAxis3
                ? ['axis3']
                : option[LANDING_PAGE_DOMAIN].allowAxis,
            },
          };
        case SEARCH_WORD:
          return {
            ...option,
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: [],
            },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: [],
            },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: [],
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: [],
            },
            [CATEGORY]: {
              ...option[CATEGORY],
              title: 'カテゴリ',
              allowAxis: ['axis4'],
            },
            [SYNC_CATEGORY]: {
              ...option[SYNC_CATEGORY],
              title: 'カテゴリ',
              allowAxis: ['axis4'],
            },
          };
        default:
          return option;
      }
    },
  },
  {
    label: '3列目',
    name: 'axis3',
    callback: (selected, option, selectedList = []) => {
      const isIncludeSearchWord = selectedList.includes(SEARCH_WORD);
      const axis4ForSelectedChannel =
        selected === SEARCH_WORD &&
        selectedList.indexOf(LANDING_PAGE_DOMAIN) === 0 &&
        selectedList.indexOf(CONTENT_CATEGORY) === 1
          ? []
          : ['axis4'];
      switch (selected) {
        case CHANNEL:
          return {
            ...option,
            [CATEGORY]: {
              ...option[CATEGORY],
              title: 'カテゴリ',
              allowAxis: ['axis4'],
            },
            [SYNC_CATEGORY]: {
              ...option[SYNC_CATEGORY],
              title: 'カテゴリ',
              allowAxis: ['axis4'],
            },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis,
            },
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP1]?.allowAxis,
            },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: isIncludeSearchWord
                ? []
                : option[MEDIA_SIDE_CAMPAIGN]?.allowAxis,
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: isIncludeSearchWord
                ? []
                : option[MEDIA_SIDE_GROUP]?.allowAxis,
            },
          };
        case CATEGORY:
          return {
            ...option,
            [CHANNEL]: { ...option[CHANNEL], allowAxis: ['axis3'] },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: isIncludeSearchWord ? [] : ['axis3'],
            },
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: [],
            },
          };
        case AD_GROUP1:
        case AD_GROUP2: {
          const keyApply = selected === AD_GROUP1 ? AD_GROUP2 : AD_GROUP1;
          return {
            ...option,
            [CHANNEL]: { ...option[CHANNEL], allowAxis: ['axis3'] },
            [CATEGORY]: { ...option[CATEGORY], allowAxis: ['axis3'] },
            [keyApply]: { ...option[keyApply], allowAxis: ['axis3'] },
          };
        }
        case SYNC_CATEGORY:
          return {
            ...option,
            [CHANNEL]: { ...option[CHANNEL], allowAxis: ['axis3'] },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: isIncludeSearchWord ? [] : ['axis3'],
            },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: [],
            },
          };
        case MEDIA_SIDE_CAMPAIGN:
        case MEDIA_SIDE_GROUP: {
          const keyApply =
            selected === MEDIA_SIDE_CAMPAIGN
              ? MEDIA_SIDE_GROUP
              : MEDIA_SIDE_CAMPAIGN;
          return {
            ...option,
            [CHANNEL]: { ...option[CHANNEL], allowAxis: ['axis3'] },
            [SYNC_CATEGORY]: { ...option[SYNC_CATEGORY], allowAxis: ['axis3'] },
            [keyApply]: { ...option[keyApply], allowAxis: ['axis3'] },
          };
        }
        case CONTENT_CATEGORY:
        case LANDING_PAGE_URL:
        case LANDING_PAGE_DOMAIN:
        case SEARCH_WORD:
          return {
            ...option,
            [CHANNEL]: {
              ...option[CHANNEL],
              allowAxis: axis4ForSelectedChannel,
            },
            [CATEGORY]: { ...option[CATEGORY], allowAxis: [] },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
            [SYNC_CATEGORY]: { ...option[SYNC_CATEGORY], allowAxis: [] },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: isIncludeSearchWord
                ? []
                : option[AD_GROUP2]?.allowAxis || [],
            },
          };
        case LANDING_PAGE_TITLE:
          return {
            ...option,
            [CHANNEL]: {
              ...option[CHANNEL],
              allowAxis: isIncludeSearchWord ? axis4ForSelectedChannel : [],
            },
            [AD_GROUP1]: {
              ...option[AD_GROUP1],
              allowAxis: [],
            },
            [AD_GROUP2]: {
              ...option[AD_GROUP2],
              allowAxis: [],
            },
            [CATEGORY]: {
              ...option[CATEGORY],
              allowAxis: [],
            },
            [SYNC_CATEGORY]: { ...option[SYNC_CATEGORY], allowAxis: [] },
            [MEDIA_SIDE_CAMPAIGN]: {
              ...option[MEDIA_SIDE_CAMPAIGN],
              allowAxis: [],
            },
            [MEDIA_SIDE_GROUP]: {
              ...option[MEDIA_SIDE_GROUP],
              allowAxis: [],
            },
          };
        default:
          return option;
      }
    },
  },
  {
    label: '4列目',
    name: 'axis4',
  },
];

export const getAd = () => {
  const displayItemsBase = DisplayItems.getAd();

  return DisplayItems.getItems({
    ...SettingAdDisplayItems,
    displayItemsBase: {
      ...displayItemsBase,
      content_category: {
        ...displayItemsBase.content_category,
        allowAxis: ['axis1', 'axis2'],
      },
      landing_page_url: {
        ...displayItemsBase.landing_page_url,
        allowAxis: ['axis1', 'axis2'],
        level: 2,
      },
      landing_page_title: {
        ...displayItemsBase.landing_page_title,
        allowAxis: ['axis2'],
      },
      landing_page_domain: {
        ...displayItemsBase.landing_page_domain,
        allowAxis: ['axis1', 'axis2'],
        level: 3,
      },
      category: {
        ...displayItemsBase.category,
        allowAxis: ['axis2', 'axis3'],
        level: 4,
      },
      ad_group1: {
        ...displayItemsBase.ad_group1,
        allowAxis: ['axis2', 'axis3'],
        level: 5,
      },
      ad_group2: {
        ...displayItemsBase.ad_group2,
        allowAxis: ['axis2', 'axis3'],
        level: 6,
      },
      [SYNC_CATEGORY]: {
        ...displayItemsBase.sync_category,
        allowAxis: ['axis2', 'axis3'],
        level: 4,
      },
      [MEDIA_SIDE_CAMPAIGN]: {
        ...displayItemsBase.media_side_campaign,
        allowAxis: ['axis2', 'axis3'],
        level: 5,
      },
      [MEDIA_SIDE_GROUP]: {
        ...displayItemsBase.media_side_group,
        allowAxis: ['axis2', 'axis3'],
        level: 6,
      },
    },
  });
};

export const getAdDefault = () => {
  const displayItemsBase = getAd();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAll = () => {
  const displayItemsBase = DisplayItems.getAll();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase: {
      ...displayItemsBase,
      content_category: {
        ...displayItemsBase.content_category,
        allowAxis: ['axis1', 'axis2'],
      },
      [SEARCH_WORD]: {
        ...displayItemsBase.search_word,
        allowAxis: ['axis1', 'axis2', 'axis3'],
      },
      landing_page_url: {
        ...displayItemsBase.landing_page_url,
        allowAxis: ['axis1', 'axis2'],
      },
      landing_page_title: {
        ...displayItemsBase.landing_page_title,
        allowAxis: ['axis2'],
      },
      landing_page_domain: {
        ...displayItemsBase.landing_page_domain,
        allowAxis: ['axis1', 'axis2'],
      },
      channel_access_type: {
        ...displayItemsBase.channel_access_type,
        allowAxis: ['axis2', 'axis3'],
      },
      category: {
        ...displayItemsBase.category,
        allowAxis: ['axis4'],
      },
      ad_group1: {
        ...displayItemsBase.ad_group1,
        allowAxis: ['axis4'],
      },
      ad_group2: {
        ...displayItemsBase.ad_group2,
        allowAxis: ['axis4'],
      },
      [SYNC_CATEGORY]: {
        ...displayItemsBase.sync_category,
        allowAxis: ['axis4'],
        level: 4,
      },
      [MEDIA_SIDE_CAMPAIGN]: {
        ...displayItemsBase.media_side_campaign,
        allowAxis: ['axis4'],
        level: 5,
      },
      [MEDIA_SIDE_GROUP]: {
        ...displayItemsBase.media_side_group,
        allowAxis: ['axis4'],
        level: 6,
      },
    },
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getCommon = () => {
  const displayItemsBase = DisplayItems.getCommon();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase: {
      ...displayItemsBase,
      content_category: {
        ...displayItemsBase.content_category,
        allowAxis: ['axis1', 'axis2'],
      },
      landing_page_url: {
        ...displayItemsBase.landing_page_url,
        allowAxis: ['axis1', 'axis2'],
      },
      landing_page_title: {
        ...displayItemsBase.landing_page_title,
        allowAxis: ['axis2'],
      },
      search_word: {
        ...displayItemsBase.search_word,
        allowAxis: ['axis1', 'axis2', 'axis3'],
      },
      landing_page_domain: {
        ...displayItemsBase.landing_page_domain,
        allowAxis: ['axis1', 'axis2'],
      },
      channel_access_type: {
        ...displayItemsBase.channel_access_type,
        allowAxis: ['axis2', 'axis3'],
      },
      category: {
        ...displayItemsBase.category,
        title: '媒体種別',
        allowAxis: ['axis2', 'axis3'],
      },
      ad_group1: {
        ...displayItemsBase.ad_group1,
        allowAxis: ['axis2', 'axis3', 'axis4'],
      },
      ad_group2: {
        ...displayItemsBase.ad_group2,
        allowAxis: ['axis2', 'axis3', 'axis4'],
      },
      [SYNC_CATEGORY]: {
        ...displayItemsBase.sync_category,
        title: '媒体',
        allowAxis: ['axis2', 'axis3'],
        level: 4,
      },
      [MEDIA_SIDE_CAMPAIGN]: {
        ...displayItemsBase.media_side_campaign,
        allowAxis: ['axis2', 'axis3', 'axis4'],
        level: 5,
      },
      [MEDIA_SIDE_GROUP]: {
        ...displayItemsBase.media_side_group,
        allowAxis: ['axis2', 'axis3', 'axis4'],
        level: 6,
      },
    },
  });
};

export const getCommonDefault = () => {
  const displayItemsBase = getCommon();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAdAxis = () => {
  return SettingAdAxis;
};

export const getAllAxis = () => {
  return SettingAllAxis;
};

export const getCommonAxis = () => {
  return SettingCommonAxis;
};
