import React from 'react';
import { oneOfType, number, string, func } from 'prop-types';
import FormInput from 'views/molecules/FormGroup/components/FormInput';

const FilterTypeTen = (props) => {
  const { name, value, unit, note, error, onChange } = props;

  const errorMess = (error && Object.values(error)[0]) || '';

  const handleChangeValue = (_, data) => {
    onChange(data);
  };

  return (
    <div className="filter-type__ten">
      <div className="d-flex align-items-center">
        <FormInput
          type="number"
          name={name}
          value={value}
          error={errorMess}
          onChange={handleChangeValue}
        />
        {unit && <span>{unit}</span>}
      </div>
      {note && <div className="filter-type__note">{note}</div>}
    </div>
  );
};

FilterTypeTen.propTypes = {
  name: string,
  unit: string,
  note: string,
  error: string,
  value: oneOfType([string, number]),
  onChange: func,
};

FilterTypeTen.defaultProps = {
  name: '',
  unit: '',
  note: '',
  error: '',
  value: '',
  onChange: () => {},
};

export default React.memo(FilterTypeTen);
