import { tabsSettings } from 'services/consts';

const getDocumentTitle = (t, pageData, currentTab) => {
  const PREFIX = 'EBiS';

  if (pageData === '' || pageData === null) {
    return PREFIX;
  }

  if (pageData.documentTitle) {
    return `${PREFIX} ${t(pageData.documentTitle)}`;
  }

  return `${PREFIX} ${t(tabsSettings[currentTab].key)} ${t(pageData.key)}`;
};

export default getDocumentTitle;
