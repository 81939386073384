import { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';

// Generic hook for detecting scroll:
const useScrollAware = (ref) => {
  const [scrollTop, setScrollTop] = useState(0);
  const animationFrame = useRef();

  const onScroll = useCallback(() => {
    if (animationFrame.current) {
      cancelAnimationFrame(animationFrame.current);
    }
    animationFrame.current = requestAnimationFrame(() => {
      const el = ref?.current || document.documentElement;
      setScrollTop(el.scrollTop || 0);
    });
  }, [ref]);

  useEffect(() => {
    const el = ref?.current || window;
    el.addEventListener('scroll', throttle(onScroll, 16));
    return () => el.removeEventListener('scroll', onScroll);
  }, [onScroll, ref]);

  return scrollTop;
};

const findStartNode = (scrollTop, positions, totalRow, rowHeight) => {
  let startRange = 0;
  let endRange = totalRow - 1;
  while (endRange !== startRange) {
    const middle = Math.floor((endRange - startRange) / 2 + startRange);
    const start = positions[middle] ?? middle * rowHeight;
    const end = positions[middle + 1] ?? (middle + 1) * rowHeight;

    if (start <= scrollTop && end > scrollTop) {
      return middle;
    }

    if (middle === startRange) {
      // edge case - start and end range are consecutive
      return endRange;
    }
    if (start <= scrollTop) {
      startRange = middle;
    } else {
      endRange = middle;
    }
  }
  return totalRow;
};

const findEndNode = (startNode, positions, totalRow, rootHeight, rowHeight) => {
  let endNode;

  for (endNode = startNode; endNode < totalRow; endNode += 1) {
    const start = positions[startNode] ?? startNode * rowHeight;
    const end = positions[endNode] ?? endNode * rowHeight;
    if (end > start + rootHeight) {
      return endNode;
    }
  }
  return endNode;
};

const useVirtualScroll = ({
  ref,
  totalRow,
  rowsHeight,
  rowHeight,
  renderAhead,
}) => {
  const scrollTop = useScrollAware(ref);
  const [positions, setPositions] = useState([0]);

  const { height: rootHeight } = window.screen;

  const firstNode = useMemo(() => {
    return findStartNode(scrollTop, positions, totalRow, rowHeight);
  }, [scrollTop, positions, totalRow, rowHeight]);
  const startNode = Math.max(0, firstNode - renderAhead);

  const lastNode = useMemo(() => {
    return findEndNode(firstNode, positions, totalRow, rootHeight, rowHeight);
  }, [firstNode, positions, totalRow, rootHeight, rowHeight]);
  const endNode = Math.min(totalRow - 1, lastNode + renderAhead);

  const visibleNode = endNode - startNode + 1;
  const viewportOffsetY = positions[startNode] ?? startNode * rowHeight;
  const viewportHeight = positions[totalRow] ?? totalRow * rowHeight;

  useEffect(() => {
    // calculate actual position of each row
    const newPositions = [...positions];

    for (let i = 0; i < totalRow; i += 1) {
      if (startNode + i >= totalRow) {
        break;
      }
      const prevRow = startNode + i;
      const currentRow = prevRow + 1;
      newPositions[currentRow] =
        (newPositions[prevRow] ?? prevRow * rowHeight) +
        (rowsHeight[prevRow] ?? rowHeight);
    }

    setPositions(newPositions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startNode, endNode, rowsHeight]);

  return { startNode, visibleNode, viewportOffsetY, viewportHeight };
};

export default useVirtualScroll;
