/* eslint-disable react/react-in-jsx-scope */
import {
  REGIST_DATE,
  UPDATE_DATE,
  CV_NAME,
  LINE_LOGIN_CHANNEL_ID,
  LINE_MESSAGING_API_CHANNEL_ID,
  ADD_FRIEND_BUTTON_URL,
  LINE_LOGIN_LIFF_ID,
  LINE_LOGIN_CHANNEL_SECRET,
  LINE_MESSAGING_API_CHANNEL_SECRET,
  CONVERSIONID,
  AUTH_ID,
} from 'domain/fields';
import * as LABELS from 'domain/labels';
import { APP_HELP_LINE_ADD_FRIEND } from 'services/routes/constants';

export const HEADER = [
  {
    field: 'rowId',
    fieldResponse: 'id',
    name: '',
    type: 'checkbox',
    isEdit: true,
    isFreeze: true,
    minWidth: '77px',
  },
  {
    field: CV_NAME,
    name: 'CV名',
    isFreeze: true,
    minWidth: '200px',
  },
  {
    field: 'channel_id',
    name: 'LINEログイン',
    children: [
      {
        field: LINE_LOGIN_CHANNEL_ID,
        name: 'チャネルID',
        needFormat: true,
        minWidth: '162px',
      },
      {
        field: LINE_LOGIN_LIFF_ID,
        name: 'LIFF ID',
        needFormat: true,
        minWidth: '200px',
      },
    ],
  },
  {
    field: LINE_MESSAGING_API_CHANNEL_ID,
    name: 'Messaging API チャネルID',
    minWidth: '200px',
  },
  {
    field: ADD_FRIEND_BUTTON_URL,
    name: '友だち追加用ボタンタグ',
    sort: 'none',
    type: 'link-copy',
    minWidth: '200px',
  },
  {
    field: AUTH_ID,
    name: '設定ユーザーID',
    minWidth: '200px',
  },
  {
    field: REGIST_DATE,
    name: LABELS.REGIST_DATE,
    minWidth: '200px',
  },
  {
    field: UPDATE_DATE,
    name: LABELS.UPDATE_DATE,
    minWidth: '200px',
  },
];

export const HEADER_DELETE_MODAL = [
  {
    field: 'cv_name_delete',
    name: 'CV名',
    needFormat: false,
    sort: 'none',
    width: '185px',
    type: 'inlineTruncate',
  },
  {
    field: 'channel_id_delete',
    name: 'LINEログイン',
    sort: 'none',
    minWidth: '338px',
    children: [
      {
        field: 'line_login_channel_id_delete',
        name: 'チャネルID',
        sort: 'none',
        width: '138px',
        type: 'inlineTruncate',
      },
      {
        field: 'line_login_liff_id_delete',
        name: 'LIFF ID',
        sort: 'none',
        width: '199px',
        type: 'inlineTruncate',
      },
    ],
  },
  {
    field: 'line_messaging_api_channel_id_delete',
    name: 'Messaging API チャネルID',
    width: '213px',
    sort: 'none',
    type: 'inlineTruncate',
  },
];

export const SETTING_FIELDS = {
  [CONVERSIONID]: {
    key: CONVERSIONID,
    label: 'CV名',
    value: '',
  },
  [LINE_LOGIN_CHANNEL_ID]: {
    key: LINE_LOGIN_CHANNEL_ID,
    label: 'チャネルID',
    value: '',
  },
  [LINE_LOGIN_CHANNEL_SECRET]: {
    key: LINE_LOGIN_CHANNEL_SECRET,
    label: 'チャネルシークレット',
    value: '',
  },
  [LINE_LOGIN_LIFF_ID]: {
    key: LINE_LOGIN_LIFF_ID,
    label: 'LIFF ID',
    value: '',
    tooltip: (
      <>
        LINE画面上「LIFF」に登録されているLIFF IDを入力してください。詳しくは
        <a
          href={APP_HELP_LINE_ADD_FRIEND}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
      </>
    ),
  },
  [LINE_MESSAGING_API_CHANNEL_ID]: {
    key: LINE_MESSAGING_API_CHANNEL_ID,
    label: 'チャネルID',
    value: '',
  },
  [LINE_MESSAGING_API_CHANNEL_SECRET]: {
    key: LINE_MESSAGING_API_CHANNEL_SECRET,
    label: 'チャネルシークレット',
    value: '',
  },
};

export const MODAL_IDS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  ERROR: 'error',
  ENDPOINT_URL: 'endpoint_url',
};

export const MAX_CREATE_LINE_ADD_FRIEND_SETTINGS = 20;

export const MAX_LENGTH_LINE_ADD_FRIEND_CHARACTER = 50;

// LINE ADD FRIEND Settings
export const STEP_BASIC_CONFIGURATION = 1;
export const STEP_COMPLETE = 2;

export const STEPS_LINE_ADD_FRIEND_SETTING_FORM = [
  {
    step: STEP_BASIC_CONFIGURATION,
    label: '基本設定',
    active: false,
  },
  {
    step: STEP_COMPLETE,
    label: '完了',
    active: false,
  },
];

export const LIST_STATUS_KEY = 'list';
export const SETTING_STATUS_KEY = 'setting';

export const TOOLTIP_USER_MANAGEMENT_FOR_AGENT_SETTING =
  '共通タグの発行、カスタムタグやコンテンツカテゴリの登録、編集を行うことができる画面です。';

export const subNameSettings = ['タグ管理', '計測サイトページ管理'];
