import React, { useState, useRef, useEffect } from 'react';
import { string, func, shape, arrayOf } from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import { Overlay, Popover } from 'react-bootstrap';
import './tabs.scss';

const MARGIN_TOP_DROP_DOWN = '-7px 0';
function NavTabs({ tabs, className = 'dropbtn', onClickSubTab }) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const history = useHistory();

  const showDropdown = (event) => {
    event.preventDefault();
    setTarget(event.target);
    setShow(true);
  };
  const hideDropdown = (e) => {
    e.preventDefault();
    setShow(false);
  };

  const handleHoverDropdownItem = (isOpen) => () => {
    setShow(isOpen);
  };

  const handleClickSubItem = (key, path) => () => {
    history.push(path);
    onClickSubTab(key);
    setShow(false);
  };

  useEffect(() => {
    const elementSubtab = document.getElementById('popover-sub-tab');
    const margin = elementSubtab?.style.getPropertyValue('margin');
    if (!margin || margin !== MARGIN_TOP_DROP_DOWN) {
      elementSubtab?.style.setProperty('margin', MARGIN_TOP_DROP_DOWN);
    }
  }, [show]);

  return (
    <div className="tabs nav-tabs__container">
      {tabs.map((tab) => {
        const isIncludeSubTab = tab?.subTabs?.length > 0;
        return (
          <>
            <NavLink
              key={tab.path}
              className={`tab ${className}`}
              to={tab.path}
              id="dropdown-basic"
              isActive={(match, location) => {
                if (
                  isIncludeSubTab &&
                  tab?.subTabsKeyPath?.includes(location.pathname)
                ) {
                  return true;
                }
                if (!match) {
                  return false;
                }
                return true;
              }}
              onMouseEnter={isIncludeSubTab ? showDropdown : undefined}
              onMouseLeave={isIncludeSubTab ? hideDropdown : undefined}
            >
              {tab.label}
            </NavLink>
            {isIncludeSubTab && (
              <Overlay
                show={show}
                target={target}
                placement="bottom-start"
                container={ref.current}
                containerPadding={10}
              >
                <Popover
                  id="popover-sub-tab"
                  onMouseEnter={handleHoverDropdownItem(true)}
                  onMouseLeave={handleHoverDropdownItem(false)}
                >
                  {tab?.subTabs &&
                    tab?.subTabs.map((subTab, index) => (
                      <Popover.Title
                        onClick={handleClickSubItem(subTab.key, subTab.path)}
                        key={subTab?.key || index}
                      >
                        {subTab.label}
                      </Popover.Title>
                    ))}
                </Popover>
              </Overlay>
            )}
          </>
        );
      })}
    </div>
  );
}

NavTabs.propTypes = {
  tabs: arrayOf(
    shape({
      path: string,
      label: string,
      subTabs: arrayOf(shape({ label: string, key: string })),
    })
  ),
  className: string,
  onClickSubTab: func,
};

NavTabs.defaultProps = {
  tabs: [],
  className: '',
  onClickSubTab: () => {},
};

export default NavTabs;
