import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { bool, func, number, string } from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';
import 'views/pages/search-console-setting/components/search-console-setting-modal.scss';
import useClickOutside from 'services/custom-hooks/useClickOutside';
import { SC_PAUSE_FLAG } from 'domain/search-console-setting/constants';

const Item = ({ icon = '', title = '' }) => (
  <>
    <img src={icon} alt="icon" />
    <span>{title}</span>
  </>
);

Item.propTypes = {
  icon: string.isRequired,
  title: string.isRequired,
};

function PauseComponent({ id, onChangeStatus, defaultValue }) {
  const [activeMenu, showActiveMenu] = useState(false);

  const clickRef = useRef();
  const handleShowActiveMenu = () => {
    showActiveMenu(!activeMenu);
  };

  const onClickOutside = () => {
    if (activeMenu) {
      showActiveMenu(false);
    }
  };
  useClickOutside(clickRef, onClickOutside);

  const handleChangeStatus = (value) => () => {
    if (value !== defaultValue) {
      onChangeStatus(id, value);
    }
    showActiveMenu(false);
  };

  const clsName = classNames({
    'sc-dropdown-select': true,
    active: activeMenu,
  });

  const target = useRef();

  return (
    <div className="sc-dropdown" ref={clickRef}>
      <OverlayTrigger
        placement="bottom-start"
        target={target}
        show={activeMenu}
        trigger="click"
        overlay={
          <div className="sc-menu">
            {Object.keys(SC_PAUSE_FLAG).map((keyFlag) => {
              const { title, icon, value } = SC_PAUSE_FLAG[keyFlag];
              return (
                <div
                  className="sc-menu-item"
                  onClick={handleChangeStatus(value)}
                  aria-hidden="true"
                >
                  <Item icon={icon} title={title} />
                </div>
              );
            })}
          </div>
        }
      >
        <div
          className={clsName}
          role="button"
          onClick={handleShowActiveMenu}
          aria-hidden="true"
        >
          <div className="dropdown-label">
            <Item icon={SC_PAUSE_FLAG[defaultValue]?.icon} />
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
}

PauseComponent.propTypes = {
  id: number.isRequired,
  onChangeStatus: func.isRequired,
  defaultValue: bool.isRequired,
};

export default PauseComponent;
