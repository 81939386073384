import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Button } from 'react-bootstrap';
import { string, number } from 'prop-types';
import qrcodeToDataUrl from 'services/qrcode/qrcodeToDataUrl';

import './qr-code.scss';

const qrTypes = [
  {
    value: 'jpeg',
    name: 'JPG',
  },
  {
    value: 'png',
    name: 'PNG',
  },
];

const qrSizes = [1, 2, 3, 4, 5];

function QRCode(props) {
  const { size, type, note, url } = props;
  const [qrSize, setSize] = useState(size);
  const [qrType, setType] = useState(type);
  const [qrImage, setImage] = useState('');

  useEffect(() => {
    setImage('');
  }, [qrSize, qrType]);

  const handleChangeSize = (value) => {
    setSize(+value);
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
  };

  const generateQR = () => {
    setImage(qrcodeToDataUrl(url, qrType, qrSize * 49));
  };

  return (
    <>
      <div className="d-flex align-items-center qr-code">
        <div className="d-flex align-items-center qr-code__size">
          <span className="qr-code__label">サイズ</span>
          <Dropdown onSelect={(value) => handleChangeSize(value)}>
            <Dropdown.Toggle size="sm" variant="select">
              {qrSize}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {qrSizes.map((item) => {
                return (
                  <Dropdown.Item key={item} eventKey={item}>
                    {item}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center qr-code__type">
          <span className="qr-code__label">タイプ</span>
          <Form.Group controlId="inputRadio" className="mb-0">
            {qrTypes.map((item) => {
              return (
                <Form.Check
                  inline
                  type="radio"
                  id={item.value}
                  key={item.value}
                >
                  <Form.Check.Label bsPrefix="mb-0 d-flex align-items-center">
                    <Form.Check.Input
                      type="radio"
                      name="qr-type"
                      value={item.value}
                      checked={qrType === item.value}
                      onChange={handleChangeType}
                    />
                    <span className="form-check-label">{item.name}</span>
                  </Form.Check.Label>
                </Form.Check>
              );
            })}
          </Form.Group>
        </div>
        <div className="qr-code__button">
          <Button size="xs" variant="secondary" onClick={generateQR}>
            発行
          </Button>
        </div>
      </div>
      {qrImage && (
        <div className="qr-code__image">
          <img src={qrImage} alt="qr-code" />
        </div>
      )}
      {note && <div className="qr-code__note">{note}</div>}
    </>
  );
}

QRCode.propTypes = {
  size: number,
  type: string,
  note: string,
  url: string.isRequired,
};

QRCode.defaultProps = {
  size: 3,
  type: 'jpeg',
  note: '',
};

export default QRCode;
