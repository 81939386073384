/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './DisplayItemsForm.scss';
import AxisGroup from 'views/molecules/AxisGroup/AxisGroup';
import FormGroup from 'views/atoms/FormGroup';
import CheckboxElement from 'views/atoms/CheckboxElement';
import {
  DISPLAY_GROUP_AGGREGATION_AXIS,
  DISPLAY_GROUP_DETAIL,
  DISPLAY_GROUP_ITEM,
  PERIOD,
  DISPLAY_GROUP_DISPLAY_CONDITIONS,
  F2_CV_PERIOD_DURATION_DAYS,
  F3_CV_PERIOD_DURATION_DAYS,
  LIST_FUNC_APPLY_SORTING,
  FUNC_CODE_DETAIL_ANALYSIS,
  FUNC_CODE_LTV_ANALYSIS,
  CROSSDEVICE_DIFF_COMPARISON,
  FUNC_CODE_LPO_PERIOD,
  AD_ID,
  LINK_PAGE_TITLE,
  FUNC_CODE_LPO_LINK,
  TRANSITION_RATE,
  FUNC_CODE_LOG_PAGE_ANALYZE,
  DISPLAY_GROUP_RADIO_OTIONS,
} from 'domain/settings/display-items';
import { INFLOW_ANALYSIS, LANDING_PAGE_ANALYZE } from 'services/routes/constants';
import { TAB_COMMON } from 'store/settings/constant';
import Switch from 'views/atoms/switch/Switch';
import RadioBtnElement from 'views/atoms/RadioBtnElement/RadioBtnElement';
import { PRIORITY_AXIS_EBIS, PRIORITY_AXIS_MEDIA_SYNC } from 'domain/consts';
import priorityAxisService from 'services/common/priorityAxisService';
import { MEDIA_SYNC, TERMINAL_TYPE } from 'domain/fields';
import EbisAxisGroup from 'views/molecules/AxisGroup/EbisAxisGroup';
import PriorityAxisCheckBox from 'views/atoms/PriorityAxisCheckBox/PriorityAxisCheckBox';
import TimeAxisRadio from 'views/molecules/TimeAxis/TimeAxisRadio';
import TableTooltips from 'services/common/TableTooltips';
import DisabledTooltipWrapper from 'views/atoms/tooltip/DisabledTooltipWrapper';
import EbisRadio from 'views/atoms/radio/EbisRadio';

const DisplayItemsForm = (props) => {
  const {
    funcId,
    axisList,
    items,
    sortItems,
    onChange,
    defaultValue,
    settingsDefault,
    priorityAxis,
    enabledPriorityAxis,
    resetLink,
    helperText,
    helperTextViewInflow,
    helperTextViewAll,
    helperTextViewLp,
    timeAxisRadio,
    isWarning,
    filters,
    customView,
    viewScreenId,
    viewTabId,
  } = props;

  const f2CvPeriodDurationDays = F2_CV_PERIOD_DURATION_DAYS;
  const f3CvPeriodDurationDays = F3_CV_PERIOD_DURATION_DAYS;

  const disabledItemKeys = Object.freeze({
    trigger: AD_ID,
    affected: [TRANSITION_RATE, LINK_PAGE_TITLE],
  });

  // true: 媒体軸優先(PRIORITY_AXIS_MEDIA_SYNC)
  // false:エビス軸優先(PRIORITY_AXIS_EBIS)
  const [mediaSync, toggleMediaSync] = useState(
    enabledPriorityAxis ? priorityAxis === PRIORITY_AXIS_MEDIA_SYNC : false
  );

  const metricKeys = useMemo(() => {
    return Object.keys(items).filter(
      (key) => items[key].getGroup(mediaSync) === DISPLAY_GROUP_ITEM
    );
  }, [items, mediaSync]);

  const radioOptionKeys = useMemo(() => {
    return Object.keys(items).filter(
      (key) => items[key].getGroup(mediaSync) === DISPLAY_GROUP_RADIO_OTIONS
    );
  }, [items, mediaSync]);

  const getDefaultValue = useCallback(
    (group, sync) => {
      const defaultState = {};
      const keysItems =
        LIST_FUNC_APPLY_SORTING.includes(funcId) && sortItems.length
          ? sortItems
          : Object.keys(items);
      keysItems.forEach((key) => {
        if (items[key]?.getGroup(sync) === group) {
          defaultState[key] = items[key].displayDefault;
          if (!items[key].getRequired(sync) && key in defaultValue) {
            defaultState[key] = defaultValue[key];
          }
          if (items[key].getRequired(sync)) {
            defaultState[key] = true;
          }
        }
      });
      return defaultState;
    },
    [defaultValue, items, sortItems, funcId]
  );

  const getSettingsDefault = useCallback(
    (group, sync) => {
      const settings = {};
      Object.keys(items).forEach((key) => {
        if (items[key].getGroup(sync) === group) {
          settings[key] = items[key].displayDefault;
          if (!items[key].getRequired(sync) && key in settingsDefault) {
            settings[key] = settingsDefault[key];
          }
          if (items[key].getRequired(sync)) {
            settings[key] = true;
          }
        }
      });
      return settings;
    },
    [settingsDefault, items]
  );
  const createDisplayItems = (displayItems, sync) => {
    if (funcId === FUNC_CODE_LTV_ANALYSIS) {
      if (MEDIA_SYNC in displayItems) {
        delete displayItems[MEDIA_SYNC];
      }
      return displayItems;
    }
    if (!LIST_FUNC_APPLY_SORTING.includes(funcId)) return displayItems;
    if (funcId === FUNC_CODE_LPO_LINK && !displayItems[AD_ID]) {
      displayItems[TRANSITION_RATE] = false;
      displayItems[LINK_PAGE_TITLE] = false;
    }

    // Ignore items between AD and ALL tabs
    const ignoreItems = Object.keys(defaultValue)
      .filter((key) => defaultValue[key] && !displayItems[key])
      .reduce((acc, key) => ({ ...acc, [key]: false }), {});

    // Save order items display
    const { dimensions, metrics } = Object.keys(displayItems).reduce(
      (acc, key) => {
        if (!displayItems[key]) return acc;
        if (items[key]?.getGroup(sync) === DISPLAY_GROUP_AGGREGATION_AXIS) {
          return { ...acc, dimensions: [...acc.dimensions, key] };
        }
        return { ...acc, metrics: [...acc.metrics, key] };
      },
      { dimensions: [], metrics: [] }
    );

    return {
      ...displayItems,
      ...ignoreItems,
      __sorting__: [...dimensions, ...metrics],
    };
  };

  /**
   * get radioBtn DefaultValue
   * group string
   * radioBtnListName string
   * defaultVal int
   */
  const getDefaultRadioValue = useCallback(
    (group) => {
      const defaultState = {};
      Object.keys(items).forEach((key) => {
        if (items[key].group === group) {
          defaultState[key] = items[key].displayDefault;
          if (!items[key].required && key in defaultValue) {
            defaultState[key] = defaultValue[key];
            return;
          }
          defaultState[key] = items[key].defaultValue;
        }
      });
      return defaultState;
    },
    [defaultValue, items]
  );

  const getSettingsRadioDefault = useCallback(
    (group) => {
      const settings = {};
      Object.keys(items).forEach((key) => {
        if (items[key].group === group) {
          settings[key] = items[key].displayDefault;
          if (!settings[key]) {
            settings[key] = items[key].defaultValue;
            return;
          }
          if (!items[key].required && key in settingsDefault) {
            settings[key] = settingsDefault[key];
          }
        }
      });
      return settings;
    },
    [settingsDefault, items]
  );

  const itemDefaultState = getDefaultValue(DISPLAY_GROUP_ITEM, mediaSync);
  const [ebisAxisDefaultState, setEbisAxisDefaultState] = useState(
    getSettingsDefault(DISPLAY_GROUP_AGGREGATION_AXIS, false)
  );
  const [syncAxisDefaultState, setSyncAxisDefaultState] = useState(
    getSettingsDefault(DISPLAY_GROUP_AGGREGATION_AXIS, true)
  );
  const axisDefaultState = useMemo(() => {
    return mediaSync ? syncAxisDefaultState : ebisAxisDefaultState;
  }, [mediaSync, syncAxisDefaultState, ebisAxisDefaultState]);

  const [ebisDetailDefaultState, setEbisDetailDefaultState] = useState(
    getSettingsDefault(DISPLAY_GROUP_AGGREGATION_AXIS, false)
  );
  const [syncDetailDefaultState, setSyncDetailDefaultState] = useState(
    getSettingsDefault(DISPLAY_GROUP_AGGREGATION_AXIS, true)
  );
  const detailDefaultState = useMemo(() => {
    return mediaSync ? syncDetailDefaultState : ebisDetailDefaultState;
  }, [mediaSync, syncDetailDefaultState, ebisDetailDefaultState]);

  const displayConditionsDefaultState = getDefaultRadioValue(
    DISPLAY_GROUP_DISPLAY_CONDITIONS
  );
  const itemSettingsDefault = getSettingsDefault(DISPLAY_GROUP_ITEM, mediaSync);
  const ebisAxisSettingsDefault = useMemo(() => {
    return getSettingsDefault(DISPLAY_GROUP_AGGREGATION_AXIS, false);
  }, [getSettingsDefault]);
  const syncAxisSettingsDefault = useMemo(() => {
    return getSettingsDefault(DISPLAY_GROUP_AGGREGATION_AXIS, true);
  }, [getSettingsDefault]);
  const axisSettingsDefault = useMemo(() => {
    return mediaSync ? syncAxisSettingsDefault : ebisAxisSettingsDefault;
  }, [mediaSync, syncAxisSettingsDefault, ebisAxisSettingsDefault]);

  const ebisDetailSettingsDefault = useMemo(() => {
    return getSettingsDefault(DISPLAY_GROUP_DETAIL, false);
  }, [getSettingsDefault]);
  const syncDetailSettingsDefault = useMemo(() => {
    return getSettingsDefault(DISPLAY_GROUP_DETAIL, true);
  }, [getSettingsDefault]);
  const detailSettingsDefault = useMemo(() => {
    return mediaSync ? syncDetailSettingsDefault : ebisDetailSettingsDefault;
  }, [mediaSync, syncDetailSettingsDefault, ebisDetailSettingsDefault]);

  const displayConditionsSettingsDefault = getSettingsRadioDefault(
    DISPLAY_GROUP_DISPLAY_CONDITIONS
  );

  const [itemFormState, updateItemFormState] = useState(itemDefaultState);
  const [axisFormState, updateAxisFormState] = useState(axisDefaultState);
  const [detailFormState, updateDetailFormState] = useState(detailDefaultState);
  const [radioFormState, updateRadioFormState] = useState(
    getSettingsDefault(DISPLAY_GROUP_RADIO_OTIONS, false)
  );
  const [isItemDefault, toggleItemDefault] = useState(false);
  const [isAxisDefault, toggleAxisDefault] = useState(false);
  const [isDetailDefault, toggleDetailDefault] = useState(false);
  const [isDisplayConditionsDefalt, toggleDisplayConditionsDefault] = useState(
    false
  );

  const [
    displayConditionsFormState,
    updateDisplayConditionsFormState,
  ] = useState(displayConditionsDefaultState);

  const formState = useMemo(
    () => ({
      ...axisFormState,
      ...detailFormState,
      ...itemFormState,
    }),
    [axisFormState, detailFormState, itemFormState]
  );

  // for CustomViewModal screen switching
  // Excerpt from updatePriorityAxis（優先軸更新関数）
  const updatePriorityAxisForView = useCallback(
    (axis) => {
      const newMediaSync = axis === PRIORITY_AXIS_MEDIA_SYNC;
      if (newMediaSync !== mediaSync) {
        toggleMediaSync(newMediaSync);
      }
    },
    [mediaSync]
  );

  useEffect(() => {
    const itemDefault = getDefaultValue(
      DISPLAY_GROUP_ITEM,
      priorityAxis === PRIORITY_AXIS_MEDIA_SYNC
    );
    const axisDefault = getDefaultValue(
      DISPLAY_GROUP_AGGREGATION_AXIS,
      priorityAxis === PRIORITY_AXIS_MEDIA_SYNC
    );
    const detailDefault = getDefaultValue(
      DISPLAY_GROUP_DETAIL,
      priorityAxis === PRIORITY_AXIS_MEDIA_SYNC
    );
    const displayRadioDefault = getDefaultValue(
      DISPLAY_GROUP_RADIO_OTIONS,
      false
    );
    const displayConditionsDefault = getDefaultRadioValue(
      DISPLAY_GROUP_DISPLAY_CONDITIONS
    );
    updateItemFormState(itemDefault);
    updateAxisFormState(axisDefault);
    updateDetailFormState(detailDefault);
    updateDisplayConditionsFormState(displayConditionsDefault);
    updateRadioFormState(displayRadioDefault);

    if (priorityAxis === PRIORITY_AXIS_MEDIA_SYNC) {
      setEbisAxisDefaultState(ebisAxisSettingsDefault);
      setEbisDetailDefaultState(ebisDetailSettingsDefault);
    } else {
      setSyncAxisDefaultState(syncAxisSettingsDefault);
      setSyncDetailDefaultState(syncDetailSettingsDefault);
    }

    // for CustomViewModal screen switching
    updatePriorityAxisForView(priorityAxis);
  }, [
    getDefaultValue,
    getDefaultRadioValue,
    priorityAxis,
    ebisAxisSettingsDefault,
    ebisDetailSettingsDefault,
    syncAxisSettingsDefault,
    syncDetailSettingsDefault,
  ]);

  // item group
  const handleItemChange = (field, isChecked) => {
    updateItemFormState((prev) => {
      return { ...prev, [field]: isChecked };
    });
    onChange(
      createDisplayItems(
        {
          media_sync: mediaSync,
          ...axisFormState,
          ...itemFormState,
          ...detailFormState,
          ...displayConditionsFormState,
          [field]: isChecked,
        },
        mediaSync
      )
    );
  };
  // detail group
  const handleDetailChange = (field, isChecked) => {
    updateDetailFormState((prev) => {
      return { ...prev, [field]: isChecked };
    });
    const currentItemFormState = { ...itemFormState };
    if (field === disabledItemKeys.trigger && !isChecked) {
      disabledItemKeys.affected.forEach((key) => {
        currentItemFormState[key] = false;
      });
      updateItemFormState(currentItemFormState);
    }
    onChange(
      createDisplayItems(
        {
          media_sync: mediaSync,
          ...axisFormState,
          ...currentItemFormState,
          ...detailFormState,
          ...displayConditionsFormState,
          [field]: isChecked,
        },
        mediaSync
      )
    );
  };

  const handleChangeRadio = (value, field) => {
    updateRadioFormState((prev) => {
      return { ...prev, [TERMINAL_TYPE]: field === TERMINAL_TYPE };
    });
    onChange(
      createDisplayItems(
        {
          media_sync: mediaSync,
          ...axisFormState,
          ...itemFormState,
          ...detailFormState,
          ...displayConditionsFormState,
          ...radioFormState,
          [TERMINAL_TYPE]: field === TERMINAL_TYPE,
        },
        false
      )
    );
  };

  // DisplayConditions RadioBtn onChange
  const handleDisplayConditionsChange = (field, value) => {
    let displayConditions = {};
    const displayConditionsValus = { [field]: value };
    updateDisplayConditionsFormState((prev) => {
      if (
        displayConditionsFormState[f3CvPeriodDurationDays] < value &&
        field === f2CvPeriodDurationDays
      ) {
        displayConditionsValus[f3CvPeriodDurationDays] = value;
      }
      displayConditions = { ...prev, ...displayConditionsValus };
      return displayConditions;
    });

    onChange(
      createDisplayItems(
        {
          media_sync: mediaSync,
          ...axisFormState,
          ...itemFormState,
          ...detailFormState,
          ...displayConditionsFormState,
          ...displayConditions,
        },
        mediaSync
      )
    );
  };

  const handleMetricsByAxis = useCallback(
    (axisItem, metricItem) => {
      const metricObject = { ...metricItem };
      switch (funcId) {
        case FUNC_CODE_LOG_PAGE_ANALYZE:
          metricKeys.forEach((key) => {
            const { callback = () => items[key] } = items[key];
            const { checked = true, disabled = false } = callback(
              items[key],
              axisItem
            );
            metricObject[key] = disabled
              ? false
              : checked && !!metricObject[key];
          });
          return metricObject;

        default:
          return metricObject;
      }
    },
    [funcId, items, metricKeys]
  );

  const checkDefaultSetting = (defaultSetting, currentSetting) => {
    let isDefaultSetting = true;
    Object.keys(defaultSetting).every((key) => {
      if (defaultSetting[key] !== !!currentSetting[key]) {
        isDefaultSetting = false;
      }

      return isDefaultSetting;
    });

    return isDefaultSetting;
  };

  useEffect(() => {
    // detect change with default
    toggleItemDefault(
      checkDefaultSetting(
        handleMetricsByAxis(axisFormState, itemSettingsDefault),
        itemFormState
      )
    );
  }, [itemSettingsDefault, itemFormState, axisFormState, handleMetricsByAxis]);

  // 優先軸に適応しない軸を除外する。
  const excludeAxisUnmatchedPriorityAxis = useCallback(
    (value) => {
      const itemKey = value[0];
      return priorityAxisService
        .getSelectableAxisListByMediaSync(mediaSync)
        .includes(itemKey);
    },
    [mediaSync]
  );

  useEffect(() => {
    const customAxisFormState =
      LIST_FUNC_APPLY_SORTING.includes(funcId) && sortItems.length
        ? { ...axisSettingsDefault, ...axisFormState }
        : axisFormState;
    toggleAxisDefault(
      Object.entries(customAxisFormState)
        .filter(excludeAxisUnmatchedPriorityAxis)
        .toString() ===
        Object.entries(axisSettingsDefault)
          .filter(excludeAxisUnmatchedPriorityAxis)
          .toString()
    );
  }, [
    axisSettingsDefault,
    axisFormState,
    sortItems,
    funcId,
    mediaSync,
    excludeAxisUnmatchedPriorityAxis,
  ]);

  useEffect(() => {
    toggleDetailDefault(
      Object.entries(detailFormState)
        .filter(excludeAxisUnmatchedPriorityAxis)
        .toString() ===
        Object.entries(detailSettingsDefault)
          .filter(excludeAxisUnmatchedPriorityAxis)
          .toString()
    );
  }, [
    detailSettingsDefault,
    detailFormState,
    mediaSync,
    excludeAxisUnmatchedPriorityAxis,
  ]);

  useEffect(() => {
    // RadioBtn change with default
    toggleDisplayConditionsDefault(
      Object.entries(displayConditionsFormState).toString() ===
        Object.entries(displayConditionsSettingsDefault).toString()
    );
  }, [displayConditionsSettingsDefault, displayConditionsFormState]);

  // axis group
  const axisItems = {};
  Object.keys(items).forEach((key) => {
    if (items[key].getGroup(mediaSync) === DISPLAY_GROUP_AGGREGATION_AXIS) {
      axisItems[key] = items[key];
    }
  });
  const detailItems = {};
  Object.keys(items).forEach((key) => {
    if (items[key].getGroup(mediaSync) === DISPLAY_GROUP_DETAIL) {
      detailItems[key] = items[key];
    }
  });

  // get DisplayCounditions Data
  const displayConditionsItems = {};
  Object.keys(items).forEach((key) => {
    if (items[key].group === DISPLAY_GROUP_DISPLAY_CONDITIONS) {
      displayConditionsItems[key] = items[key];
    }
  });

  // 優先軸更新関数
  const updatePriorityAxis = useCallback(
    (axis) => {
      const newMediaSync = axis === PRIORITY_AXIS_MEDIA_SYNC;
      if (newMediaSync !== mediaSync) {
        if (mediaSync) {
          setSyncAxisDefaultState(axisFormState);
          setSyncDetailDefaultState(detailFormState);
          updateAxisFormState(ebisAxisDefaultState);
          updateDetailFormState(ebisDetailDefaultState);
        } else {
          setEbisAxisDefaultState(axisFormState);
          setEbisDetailDefaultState(detailFormState);
          updateAxisFormState(syncAxisDefaultState);
          updateDetailFormState(syncDetailDefaultState);
        }
        toggleMediaSync(newMediaSync);
        onChange(
          createDisplayItems(
            {
              media_sync: newMediaSync,
              ...axisFormState,
              ...itemFormState,
              ...detailFormState,
            },
            newMediaSync
          )
        );
      }
    },
    [axisFormState, detailFormState, itemFormState, onChange, mediaSync]
  );

  /**
   * period ON:
   * + disabled axis(channel, category, ad_group1, ad_group2)
   * + user can not modify
   * + keep axis values(don't reset to false)
   * + on screen skip pass to API
   * + axis values will using cross screen
   * Tab is All
   * + Channel is required, always selected
   * + user can not remve channel
   *
   * @param {key: bool} axis
   */
  const handleAxisSelect = (axis) => {
    const axisObject = {};

    // Handle axis
    axis.forEach((item) => {
      axisObject[item] = true;
    });
    Object.keys(axisItems).forEach((key) => {
      axisObject[key] = axisObject[key] ?? false;
    });

    updateAxisFormState({ ...axisObject });

    // Handle metrics based on axiss
    const metricObject = handleMetricsByAxis(axisObject, itemFormState);
    updateItemFormState({ ...metricObject });

    onChange(
      createDisplayItems(
        {
          media_sync: mediaSync,
          ...metricObject,
          ...detailFormState,
          ...axisObject,
        },
        mediaSync
      )
    );
  };

  const handlePriorityAxisSelect = (axis) => {
    updatePriorityAxis(axis);
  };

  const handleResetDefaultItem = () => {
    const metricObject = handleMetricsByAxis(
      axisFormState,
      itemSettingsDefault
    );
    updateItemFormState(metricObject);
    onChange(
      createDisplayItems(
        {
          media_sync: mediaSync,
          ...axisFormState,
          ...itemFormState,
          ...detailFormState,
          ...metricObject,
        },
        mediaSync
      )
    );
  };
  const handleResetDefaultAxis = () => {
    updateAxisFormState(axisSettingsDefault);
    onChange(
      createDisplayItems(
        {
          media_sync: mediaSync,
          ...axisFormState,
          ...itemFormState,
          ...detailFormState,
          ...axisSettingsDefault,
        },
        false
      )
    );
  };
  const handleResetDefaultDetail = () => {
    updateDetailFormState(detailSettingsDefault);
    onChange(
      createDisplayItems(
        {
          media_sync: mediaSync,
          ...axisFormState,
          ...itemFormState,
          ...detailFormState,
          ...detailSettingsDefault,
        },
        false
      )
    );
  };
  // DisplayCounditions DefaultBtn
  const handleRadioBtnResetDefaultDetail = () => {
    updateDisplayConditionsFormState(displayConditionsSettingsDefault);

    onChange(
      createDisplayItems(
        {
          ...displayConditionsSettingsDefault,
        },
        mediaSync
      )
    );
  };
  const handleTogglePeriod = (checked) => {
    const periodState = checked ? [PERIOD] : [];
    // Keep axis init value
    const axisDefault = getDefaultValue(
      DISPLAY_GROUP_AGGREGATION_AXIS,
      mediaSync
    );
    Object.keys(axisDefault).forEach((key) => {
      if (key !== PERIOD && axisDefault[key]) {
        periodState.push(key);
      }
    });
    handleAxisSelect(periodState);
  };
  const handleTogglePeriodInflow = (checked) => {
    const periodState = checked ? [PERIOD] : [];
    // Keep axis form state value
    Object.keys(axisFormState).forEach((key) => {
      if (key !== PERIOD && axisFormState[key]) {
        periodState.push(key);
      }
    });
    handleAxisSelect(periodState);
  };

  const noteClass = classNames({
    'display-items-form__note': true,
    'display-items-form__note--disabled': axisFormState[PERIOD] && viewScreenId !== INFLOW_ANALYSIS,
  });

  const { [PERIOD]: period, ...axisItemsWithoutPeriod } = axisItems;

  const axisDefaultValue = useMemo(() => {
    return Object.keys(axisFormState).filter((key) => {
      if (key === PERIOD) {
        return false;
      }
      if (
        priorityAxisService
          .getSelectableAxisListByMediaSync(mediaSync)
          .includes(key) &&
        axisFormState[key]
      ) {
        return true;
      }
      return false;
    });
  }, [axisFormState, mediaSync]);

  const isDetailChecked = useCallback(
    (key) => {
      if (key in items && !detailFormState[key]) {
        return items[key].getRequired(mediaSync);
      }
      return detailFormState[key];
    },
    [items, detailFormState, mediaSync]
  );

  const isDisplayItems = (key) => {
    switch (key) {
      case PERIOD:
        return false;
      case CROSSDEVICE_DIFF_COMPARISON:
        if (filters.cross_device) {
          return !isWarning;
        }
        return false;
      default:
        return true;
    }
  };

  return (
    <form className="display-items-form">
      {Object.keys(axisItems).length > 0 && (
        <FormGroup
          heading="集計軸"
          linkActive={!isAxisDefault}
          onClick={handleResetDefaultAxis}
          resetLink={resetLink}
          customView={customView}
        >
          <>
            {viewScreenId !== INFLOW_ANALYSIS && PERIOD in axisItems && (
              <Switch
                label="期間軸のみ"
                className="compared-switch"
                active={axisFormState[PERIOD]}
                onChange={handleTogglePeriod}
              />
            )}
            {viewScreenId === INFLOW_ANALYSIS && PERIOD in axisItems && (
              <div className="display-items-form__inflow-period-list">
                <div className="display-items-form__inflow-period-title">期間分析の表示設定</div>
                <Switch
                  label="期間軸のみ"
                  className="compared-switch compared-switch__inflow"
                  active={axisFormState[PERIOD]}
                  onChange={handleTogglePeriodInflow}
                />
                <small className="custom-view-modal__note">※ONにした場合は、下記の集計軸を設定しても期間分析のグラフ/CSVには反映されません。</small>
              </div>
            )}
            {funcId !== FUNC_CODE_LPO_PERIOD && (
              <PriorityAxisCheckBox
                mediaSync={mediaSync}
                enabledPriorityAxis={enabledPriorityAxis}
                handlePriorityAxisSelect={handlePriorityAxisSelect}
              />
            )}
            {axisList.length ? (
              <>
                <EbisAxisGroup
                  axis={axisList}
                  option={axisItemsWithoutPeriod}
                  defaultValue={axisDefaultValue}
                  onSelect={handleAxisSelect}
                  priorityAxis={priorityAxis}
                  mediaSync={mediaSync}
                  customView={customView}
                />
                {!(customView && viewScreenId === LANDING_PAGE_ANALYZE) && (
                  <div className={noteClass}>※CSVに反映されます</div>
                )}
              </>
            ) : (
              <AxisGroup
                items={axisItemsWithoutPeriod}
                defaultValue={axisDefaultValue}
                onSelect={handleAxisSelect}
                disabled={axisFormState[PERIOD] && viewScreenId !== INFLOW_ANALYSIS}
                priorityAxis={priorityAxis}
                mediaSync={mediaSync}
                customView={customView}
              />
            )}
            {!customView && !!helperText && PERIOD in axisItems && (
              <div className={noteClass}>{helperText}</div>
            )}
            {customView && !!helperText && PERIOD in axisItems && (
              <div className={noteClass}>{helperTextViewInflow}</div>
            )}
            {/* funcId can't be used because the 3 screens are f10 and cannot be distinguished */}
            {customView && viewScreenId === INFLOW_ANALYSIS && viewTabId === TAB_COMMON && helperTextViewAll.map((text) => {
              return <div className={noteClass}>{text}</div>
            })}
            {customView && viewScreenId === LANDING_PAGE_ANALYZE && helperTextViewLp.map((text) => {
              return <div className={noteClass}>{text}</div>
            })}
          </>
        </FormGroup>
      )}
      {Object.keys(detailItems).length > 0 && (
        <FormGroup
          heading="集計軸"
          linkActive={!isDetailDefault}
          onClick={handleResetDefaultDetail}
          resetLink={resetLink}
          customView={customView}
        >
          {funcId === FUNC_CODE_DETAIL_ANALYSIS && (
            <PriorityAxisCheckBox
              mediaSync={mediaSync}
              enabledPriorityAxis={enabledPriorityAxis}
              handlePriorityAxisSelect={handlePriorityAxisSelect}
            />
          )}
          <div className="display-items-form__checkbox-list">
            {Object.keys(detailItems).map((key) => {
              const item = detailItems[key];
              return (
                <div
                  key={key}
                  className="display-items-form__checkbox-item"
                  style={{ order: item.order }}
                >
                  <CheckboxElement
                    name={key}
                    label={item.title}
                    checked={isDetailChecked(key)}
                    disabled={items[key].getRequired(mediaSync)}
                    onChange={handleDetailChange}
                  />
                </div>
              );
            })}
          </div>
        </FormGroup>
      )}
      {timeAxisRadio && (
        <FormGroup heading="時間軸" resetLink={false}>
          <TimeAxisRadio {...timeAxisRadio} />
        </FormGroup>
      )}
      {radioOptionKeys.length > 0 && (
        <FormGroup
          heading="集計軸"
          resetLink={false}
          customView={customView}
        >
          <div className="display-items-form__radio-list">
            <div
              className="display-items-form__radio-item"
              style={{ order: items[PERIOD].order }}
            >
              <EbisRadio
                name={PERIOD}
                label="期間軸"
                value={PERIOD}
                checked={
                  radioFormState[PERIOD] && !radioFormState[TERMINAL_TYPE]
                }
                onChange={handleChangeRadio}
              />
            </div>
            <div
              className="display-items-form__radio-item"
              style={{ order: items[TERMINAL_TYPE].order }}
            >
              <EbisRadio
                name={TERMINAL_TYPE}
                label="期間＋デバイス軸"
                value={TERMINAL_TYPE}
                checked={
                  radioFormState[TERMINAL_TYPE] && radioFormState[PERIOD]
                }
                onChange={handleChangeRadio}
              />
            </div>
          </div>
        </FormGroup>
      )}
      <FormGroup
        heading="表示項目"
        linkActive={!isItemDefault}
        onClick={handleResetDefaultItem}
        resetLink={resetLink}
        customView={customView}
      >
        <div className="display-items-form__checkbox-list">
          {metricKeys.map((key) => {
            // Skip display item
            if (!isDisplayItems(key)) {
              itemFormState[key] = false;
              return <></>;
            }

            let item = items[key];

            if (funcId === FUNC_CODE_LOG_PAGE_ANALYZE) {
              const { callback = () => items[key] } = items[key];
              item = callback(items[key], formState);
            }
            item.disabled = false;
            if (
              !detailFormState[disabledItemKeys.trigger] &&
              disabledItemKeys.affected.includes(key)
            ) {
              item.disabled = true;
              item.disabledTooltip = TableTooltips.displayItemTooltipMessage;
            }

            const { disabled = false, disabledTooltip = '' } = item;

            return (
              <div
                key={key}
                className="display-items-form__checkbox-item"
                style={{ order: item.order }}
              >
                <DisabledTooltipWrapper
                  disabled={disabled && disabledTooltip}
                  message={disabledTooltip}
                >
                  <div className="width-fit-content">
                    <CheckboxElement
                      name={key}
                      label={item.title}
                      checked={itemFormState[key]}
                      disabled={disabled || item.getRequired(mediaSync)}
                      onChange={handleItemChange}
                    />
                  </div>
                </DisabledTooltipWrapper>
              </div>
            );
          })}
        </div>
      </FormGroup>
      {Object.keys(displayConditionsItems).length > 0 && (
        <FormGroup
          heading="表示条件"
          linkActive={!isDisplayConditionsDefalt}
          onClick={handleRadioBtnResetDefaultDetail}
          resetLink={resetLink}
        >
          <div className="display-conditions-form__conversion-list">
            {Object.keys(displayConditionsItems).map((key) => {
              const item = displayConditionsItems[key];
              return (
                <DisplayConditions
                  item={item}
                  displayConditionsValue={displayConditionsFormState[key]}
                  handleChange={handleDisplayConditionsChange}
                  gropName={item.gropName}
                  listName={key}
                  disabled={key === f3CvPeriodDurationDays}
                  disabledComparison={
                    displayConditionsFormState[f2CvPeriodDurationDays]
                  }
                />
              );
            })}
          </div>
        </FormGroup>
      )}
    </form>
  );
};

DisplayItemsForm.propTypes = {
  funcId: PropTypes.string.isRequired,
  items: PropTypes.shape({}).isRequired,
  sortItems: PropTypes.arrayOf(PropTypes.string),
  axisList: PropTypes.arrayOf({}),
  defaultValue: PropTypes.shape({}),
  settingsDefault: PropTypes.shape({}),
  onChange: PropTypes.func,
  priorityAxis: PropTypes.string,
  enabledPriorityAxis: PropTypes.bool,
  resetLink: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextViewInflow: PropTypes.string,
  helperTextViewAll: PropTypes.arrayOf(PropTypes.string),
  helperTextViewLp: PropTypes.arrayOf(PropTypes.string),
  timeAxisRadio: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  isWarning: PropTypes.bool,
  filters: PropTypes.func,
  customView: PropTypes.bool,
  viewScreenId: PropTypes.string,
  viewTabId: PropTypes.string,
};

DisplayItemsForm.defaultProps = {
  sortItems: [],
  axisList: [],
  defaultValue: {},
  settingsDefault: {},
  onChange: () => {},
  priorityAxis: PRIORITY_AXIS_EBIS,
  enabledPriorityAxis: false,
  resetLink: true,
  helperText: '※グラフ/CSVに反映されます',
  helperTextViewInflow: '※期間分析ではグラフ/CSVのみに反映されます。',
  helperTextViewAll: ['※1列目の[チャネル種別]は全トラフィックの画面のみ反映されます。'],
  helperTextViewLp: ['※検索ワードおよびチャネル種別は全トラフィック画面でのみ反映されます。'],
  timeAxisRadio: false,
  isWarning: false,
  filters: {},
  customView: false,
  viewScreenId: null,
  viewTabId: null,
};

const DisplayConditions = (props) => {
  const {
    item,
    displayConditionsValue,
    handleChange,
    gropName,
    listName,
    disabled,
    disabledComparison,
  } = props;

  return (
    <>
      {Object.keys(item).map((key) => {
        const items = item[key];
        return (
          <>
            {key === 'title' && <div>{items}</div>}
            {key === 'options' && (
              <div className="display-items-form__radiobtn-items">
                {Object.keys(items).map((keys) => {
                  const optins = items[keys];
                  return (
                    <RadioBtnElement
                      name={keys}
                      label={optins.title}
                      checked={displayConditionsValue === optins.value}
                      disabled={
                        disabled ? disabledComparison > optins.value : false
                      }
                      onChange={handleChange}
                      grop={gropName}
                      value={optins.value}
                      listName={listName}
                    />
                  );
                })}
              </div>
            )}
          </>
        );
      })}
    </>
  );
};

DisplayConditions.propTypes = {
  item: PropTypes.shape({}).isRequired,
  displayConditionsValue: PropTypes.string,
  handleChange: PropTypes.func,
  gropName: PropTypes.string,
  listName: PropTypes.string,
  disabled: PropTypes.bool,
  disabledComparison: PropTypes.string,
};

DisplayConditions.defaultProps = {
  displayConditionsValue: null,
  handleChange: () => {},
  gropName: null,
  listName: null,
  disabled: false,
  disabledComparison: null,
};

export default DisplayItemsForm;
