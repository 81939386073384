export const ROLE_VIEW_AD = 'view_ad';
export const ROLE_VIEW_ALL = 'view_all';
export const ROLE_VIEW_ALL_AD_ONLY = 'view_all_ad_only';
export const ROLE_SETTING = 'setting';
export const ROLE_SETTING_LISTING = 'listing_setting';
export const ROLE_SETTING_COSTING = 'media_link_setting';
export const ROLE_SETTING_MEDIA_SYNC = 'media_sync_setting';
export const ROLE_SETTING_OTHER_AGENT = 'agent_setting';
export const ROLE_MANAGE_ACCOUNT = 'manage_account';
export const ROLE_LTV_UNLIMITED = 'ltv_unlimited';
export const ROLE_EBIS_API = 'ebis_api';
// role capi for user normal
export const ROLE_CAPI_SETTING = 'capi_setting';
// role capi for agency
export const ROLE_CAPI_AGENT_SETTING = 'capi_agent_setting';

export const ROLE_SEARCH_CONSOLE_SETTING = 'search_console_setting';

export const ROLE_LINE_ADD_FRIEND_SETTING = 'line_add_friend_agent_setting';
