
import React from 'react';
import PropTypes from 'prop-types';

const IconDropdown = React.forwardRef(({ children, onClick, disabled }) => {
  return (
    <div
      className="nav-link nav-link--icon nav-link--dropdown"
      role="button"
      aria-hidden
      onClick={(e) => {
        e.preventDefault();
        if (!disabled) {
          onClick(e);
        }
      }}

    >
      {children}
    </div>
  );
});

IconDropdown.defaultProps = {
  disabled: false,
};

IconDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default IconDropdown;
