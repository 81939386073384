import React from 'react';
import PropTypes from 'prop-types';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { ReactComponent as CloseIcon } from 'assets/images/tutorial/popover_close.svg';

import './ebis-popover.scss';

const EbisPopover = ({
  children,
  placement,
  trigger,
  defaultShow,
  content,
  closeBtn,
}) => {
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      defaultShow={defaultShow}
      overlay={
        <Popover bsPrefix="popover have-arrow ebis-popover">
          <Popover.Content>
            <div className="d-flex">
              <div className="ebis-popover__content">
                {content}
              </div>
              {closeBtn && (
                <div aria-hidden className="ebis-popover__icon" onClick={closeBtn} >
                  <CloseIcon />
                </div>
              )}
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

EbisPopover.defaultProps = {
  placement: 'top',
  trigger: 'click',
  defaultShow: false,
  closeBtn: null,
};

EbisPopover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  placement: PropTypes.string,
  defaultShow: PropTypes.bool,
  closeBtn: PropTypes.func,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default EbisPopover;
