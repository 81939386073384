// async actions
const REQUEST_START = 'customview/REQUEST_START';
const REQUEST_FAILED = 'customview/REQUEST_FAILED';
const REQUEST_START_FOR_VIEW = 'customview/REQUEST_START_FOR_VIEW';
const REQUEST_FAILED_FOR_VIEW = 'customview/REQUEST_FAILED_FOR_VIEW';
const SET_LOADED_VIEW_TAB = 'customview/SET_LOADED_VIEW_TAB';
const FETCH = 'customview/FETCH';
const FETCH_EDIT = 'customview/FETCH_EDIT';
const FETCH_LIST = 'customview/FETCH_LIST';
const CREATE = 'customview/CREATE';
const UPDATE = 'customview/UPDATE';
const OVERWRITE = 'customview/OVERWRITE';
const DELETE = 'customview/DELETE';
const APPLY = 'customview/APPLY';
const TOGGLE_FAVORITE = 'customview/TOGGLE_FAVORITE';
// sync actions
const FETCH_EDIT_SUCCEEDED = 'customview/FETCH_EDIT_SUCCEEDED';
const FETCH_SWITCH_SUCCEEDED = 'customview/FETCH_SWITCH_SUCCEEDED';
const FETCH_SUCCEEDED = 'customview/FETCH_SUCCEEDED';
const FETCH_LIST_SUCCEEDED = 'customview/FETCH_LIST_SUCCEEDED';
const CREATE_SUCCEEDED = 'customview/CREATE_SUCCEEDED';
const UPDATE_SUCCEEDED = 'customview/UPDATE_SUCCEEDED';
const DELETE_SUCCEEDED = 'customview/DELETE_SUCCEEDED';
const APPLY_SUCCEEDED = 'customview/APPLY_SUCCEEDED';
const APPLY_IS_CHANGED = 'customview/APPLY_IS_CHANGED';
const TOGGLE_FAVORITE_SUCCEEDED = 'customview/TOGGLE_FAVORITE_SUCCEEDED';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  REQUEST_START_FOR_VIEW,
  REQUEST_FAILED_FOR_VIEW,
  SET_LOADED_VIEW_TAB,
  FETCH,
  FETCH_EDIT,
  CREATE,
  UPDATE,
  OVERWRITE,
  DELETE,
  APPLY,
  TOGGLE_FAVORITE,
  FETCH_LIST,
  FETCH_LIST_SUCCEEDED,
  FETCH_EDIT_SUCCEEDED,
  FETCH_SWITCH_SUCCEEDED,
  FETCH_SUCCEEDED,
  CREATE_SUCCEEDED,
  UPDATE_SUCCEEDED,
  DELETE_SUCCEEDED,
  APPLY_SUCCEEDED,
  APPLY_IS_CHANGED,
  TOGGLE_FAVORITE_SUCCEEDED,
};
