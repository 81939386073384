import helper from 'store/helper';
import types from './types';

const requestStart = () => helper.createAction(types.REQUEST_START);
const requestFailed = (error) =>
  helper.createAction(types.REQUEST_FAILED, error);
const fetchSucceeded = (data) =>
  helper.createAction(types.FETCH_SUCCEEDED, data);
const createSucceeded = (items, sorting) =>
  helper.createAction(types.CREATE_SUCCEEDED, { items, sorting });

const fetch = (data) => helper.createAction(types.FETCH, data);
const create = (data) => helper.createAction(types.CREATE, data);

const fetchDisplayItemsLandingPageAnalyze = (data) =>
  helper.createAction(types.FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE, data);

const fetchDisplayItemsLandingPageAnalyzeSucceed = (data) =>
  helper.createAction(
    types.FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE_SUCCEED,
    data
  );

export default {
  requestStart,
  requestFailed,
  fetchSucceeded,
  createSucceeded,
  fetch,
  create,
  fetchDisplayItemsLandingPageAnalyzeSucceed,
  fetchDisplayItemsLandingPageAnalyze,
};
