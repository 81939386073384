import Api from 'services/api/Api';

import { ACCOUNT_ENDPOINT } from 'services/routes/constants';

const SystemApiFactory = () => {
  const endpoint = `${ACCOUNT_ENDPOINT}/system`;
  return {
    checkUrl: async (url) => Api.get(`${endpoint}/check_url?url=${url}`),
  };
};

const SystemApi = SystemApiFactory();
export default SystemApi;
