import { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { MEDIA_SYNC_MANAGEMENT } from 'services/routes/constants';

const useResizeObserver = (screenId = null) => {
  const [rowsHeight, updateRowsHeight] = useState({});
  const [itemsHeight, updateItemsHeight] = useState({});
  const [isResizeColumn, updateResizeColumn] = useState(false);
  const [isResizingScreen, updateResizingScreen] = useState(false);
  const rowsHeightKeys = JSON.stringify(rowsHeight);
  // only set height value for case received from ResizeObserver
  const autoResizeColumn = useRef(null);
  const animationFrame = useRef();

  const detectHeightItemsChange = (rowId, cellMultiItems) => {
    if (cellMultiItems.length <= 0) return;

    const height = {};
    Array.from(cellMultiItems).forEach((element) => {
      const itemEls = element.querySelectorAll('.text-item');
      Array.from(itemEls).forEach((itemEl, index) => {
        height[index] = Math.max(
          itemEl.firstChild.offsetHeight,
          height[index] ?? 0
        );
      });
    });

    updateItemsHeight((prev) => ({ ...prev, [rowId]: height }));
  };

  const detectHeightChange = useCallback(
    (entities) => {
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
      animationFrame.current = requestAnimationFrame(() => {
        const maxRowsHeight = { ...rowsHeight };
        const column = autoResizeColumn?.current || null;
        const newEntities = entities.filter((entity) => {
          return entity.target.closest('tr') != null;
        });

        newEntities.map((entity) => {
          // find wrapping <tr> of element
          const target = entity.target.closest('tr');
          // find row index
          const index = target.id.replace(
            /(\w+)_(\d+)$/,
            (match, name, i) => i
          );

          // get current maxHeight of row
          const maxHeight = maxRowsHeight[index];

          // check isResizing or loading? If resizing then column === null
          const isResizing = column
            ? target.querySelector(`.${column}`)
            : false;

          // find all 'contents' of current row
          let cellContentEls = target.querySelectorAll(
            '.freeze-table__cell-content'
          );

          // find all heights of current row
          const heights = [];
          const cellMultiItems = [];
          Array.from(cellContentEls).forEach((element) => {
            heights.push(element.offsetHeight);
            if (Array.from(element?.classList).includes('text-multi')) {
              cellMultiItems.push(element);
            }
          });

          // also get corresponding freeze / non-freeze row
          const prefix = target.id.replace(
            /(\w+)_(\d+)$/,
            (match, name) => name
          );
          const compareEl = document.getElementById(
            `${prefix === 'freeze' ? 'main' : 'freeze'}_${index}`
          );
          if (compareEl) {
            cellContentEls = compareEl.querySelectorAll(
              '.freeze-table__cell-content'
            );
            Array.from(cellContentEls).forEach((element) => {
              heights.push(element.offsetHeight);
            });
          }

          // get max height
          const height = Math.max(...heights);

          // !maxHeight || maxHeight < height: table is loading
          if (
            isResizing ||
            !maxHeight ||
            maxHeight < height ||
            isResizingScreen
          ) {
            maxRowsHeight[index] = height;
          }

          detectHeightItemsChange(index, cellMultiItems);
          return index;
        });

        updateRowsHeight(maxRowsHeight);
        // Set resize column value to false to trigger next change
        updateResizeColumn(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsHeightKeys, isResizeColumn]
  );

  // Set "true" to "isResizingScreen" only on specific screens in order to display a variable height value in the table according to the user's operation.
  useEffect(() => updateResizingScreen(screenId === MEDIA_SYNC_MANAGEMENT), [
    screenId,
  ]);

  const resizeObserver = useMemo(() => new ResizeObserver(detectHeightChange), [
    detectHeightChange,
  ]);

  const animationFrameResizeColumn = useRef();
  const handleResizeColumn = useCallback(
    (column) => {
      if (animationFrameResizeColumn.current) {
        cancelAnimationFrame(animationFrameResizeColumn.current);
      }
      animationFrameResizeColumn.current = requestAnimationFrame(() => {
        autoResizeColumn.current = column;
        // when begins to resize column, change this value to trigger detectHeightChange function
        updateResizeColumn(true);
      });
    },
    // Due with issue compare object
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsHeightKeys]
  );

  const isAutoResize = autoResizeColumn && autoResizeColumn.current;
  return [
    resizeObserver,
    handleResizeColumn,
    rowsHeight,
    isAutoResize,
    itemsHeight,
  ];
};

export default useResizeObserver;
