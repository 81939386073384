import * as ErrorMessages from 'services/validations/messageErrorByCode';
import {
  AD_ID_MAX,
  AD_KEYWORD_MAX,
  AD_NOTE_MAX,
  URL_MAX,
  PAGE_TITLE_MAX,
  TEXT_DATA_MAX,
  BASE_COST_RANGE,
  DATE_RANGE,
  BANNER_MAX_MEGABYTES,
} from 'domain/ad-management/consts';

const AD_ID_RESERVED_CHECK =
  '{label}の先頭に tad, tdv, cnv は使用できません。<br/>{label}の先頭が tad, tdv, cnv とならないよう修正してください。';
const EDIT_AD_DELIVERY_TDV_CHECK =
  '{label}が「tdv」から始まる広告は、広告管理のCSV一括登録機能で編集することができません。<br/>tdvから始まる{label}は配信管理画面から編集してください。';
const AD_ONLY_UPDATE_CHECK =
  'リスティング連携広告の画面では、既に登録済みの広告のみ編集が可能です。<br/>新規登録の場合は、AD広告の画面をご利用ください。';
const AD_EXIST_CHECK =
  '利用されている{label}です。別の{label}を入力してください。';
const AD_HAVE_PARENT_NOT_ALLOW_EDIT =
  '新バージョンでは親子広告は編集できません。旧バージョンの広告管理にてCSVを再アップロードしてください。';
const NUM_RANGE_CHECK =
  '{label}は{min}以上{max}以下の半角整数となるよう修正してください。';
const NUM_CHECK =
  '{label}は{min}以上{max}以下の半角整数となるよう修正してください。';
const ONLY_ONE_FIELD_EXIST_CHECK =
  '{label}、{label2}のいずれかを入力した状態に修正してください。';
const BASE_CPCONV_VALID_CHECK =
  '{label}を利用する場合は、システム設定の{label}の設定にて、コストの計上方法を選択してください。';
const AD_URL_CHECK =
  '{label}に入力した内容について、以下ご確認ください。<br/>・半角スペース、全角スペース、タブ、改行は除いてください。<br/>・全角の文字列は除いてください。<br/>・{label}の始まりは、「http、https、market」で始まるようにしてください。';
const AD_URL_NOT_QUESTION_MARK_CHECK =
  '{label}のパラメータを、「?」が1つになるように修正してください。';
const COMPARE_DATE_CHECK =
  '{label_ad_start_date}は{label_ad_end_date}より過去となるよう修正してください。';
const DATE_CHECK =
  '入力する値が、登録可能期間({min}-{max})に収まるよう設定してください。入力形式は、下記いずれかでお願いします。<br/>yyyy/m/d<br/>yyyy-m-d<br/>yyyymmdd';
const AD_MASTER_DATA_EXIST_CHECK =
  '未登録の{label}が入力されています。<br/>[広告設定>{menu}管理]で{label}を登録してください。';
const FILE_HEADER_CHECK =
  'CSVフォーマットの形式が異なります。<br/><a href={link} download={fileName}>最新フォーマット</a>でCSVを再アップロードしてください。';

const MSG_TOOLTIP_MAX_LENGTH_CHECK = '{length}文字以内で入力してください';
const MSG_TOOLTIP_AD_ID_REGISTER_CHECK =
  '広告IDに、半角英数字・ハイフン・アンダーバー以外は使用できません';
const MSG_TOOLTIP_AD_ID_RESERVED_CHECK =
  '{label}の先頭に tad, tdv, cnv は使用できません。<br/>{label}の先頭が tad, tdv, cnv とならないよう修正してください';
const MSG_TOOLTIP_SPTAB_CHECK =
  '{label}をスペース、タブ、改行のみで登録することはできません';
const MSG_TOOLTIP_CONTROL_CHARACTER_CHECK =
  '{label}に制御文字を含めて登録することはできません。制御文字を削除してください';
const MSG_TOOLTIP_NUM_RANGE_CHECK =
  '{label}は{min}以上{max}以下の半角整数となるよう修正してください';
const MSG_TOOLTIP_EMPTY_CHECK = '{label}を入力してください';
const MSG_TOOLTIP_BASE_CPCONV_VALID_CHECK =
  '{label}を利用する場合は、システム設定の{label}の設定にて、コストの計上方法を選択してください';
const MSG_TOOLTIP_AD_URL_NOT_QUESTION_MARK_CHECK =
  '{label}のパラメータを、「?」が1つになるように修正してください';
const MSG_TOOLTIP_AD_URL_FORMAT_CHECK =
  '{label}に不正な情報が入力されています。入力内容をご確認ください';
const MSG_TOOLTIP_AD_URL_EXIT_HASHTAG_CHECK =
  'ダイレクト計測の場合、{label}に「#」は設定できません';
const MSG_TOOLTIP_URL_IN_HOST_CONFIG_CHECK =
  '計測方式が「ダイレクト」の場合、{label}のドメインは「計測対象サイト」に登録されている必要があります';
const MSG_TOOLTIP_FORMAT_DATE_ACCEPTED_CHECK = DATE_CHECK;
const MSG_TOOLTIP_FILE_EXTENSION_CHECK =
  '{label}で使用できる拡張子は「{extension}」です';
const MSG_TOOLTIP_FILE_SIZE_CHECK =
  '{label}の容量が{size}MBを超えています。{size}MB以下の画像で再度登録してください';
const MSG_TOOLTIP_LIMIT_AD_CHECK =
  '広告の登録上限数({length}件)を超えてしまうため、広告を登録することができません。<br/>広告の完全削除、もしくはオプションサービスの広告登録数アップをご利用ください';
const MSG_TOOLTIP_AD_MASTER_DATA_EXIST_CHECK =
  '未登録の{label}が入力されています。<br/>[広告設定>{menu}管理]で{label}を登録してください';
// LPO URLs check
const MSG_TOOLTIP_TOTAL_RATE_CHECK =
  '遷移割合合計は100になるように入力してください。';
const MSG_TOOLTIP_MINIMUM_URLS_CHECK = 'リンク先は2つ以上入力してください。';
const MSG_TOOLTIP_NUMBER_INTEGER = '遷移割合は半角整数の数字で入力してください';

const MSG_CSV_IMPORT_ERROR = '処理に失敗しました。再度お試しください。';
const MSG_CSV_FILE_SIZE_CHECK = '1ファイルあたり{size}MB以下。';
const MSG_CSV_FILE_EXIST_CHECK = 'CSVファイルをアップロードしてください。';
const MSG_CSV_FILE_MAX_LINE_CHECK = 'CSVの登録上限は、{length} 行までです。';
const MSG_CSV_TOO_MAX_ERROR =
  '広告登録エラーが{length}件以上あります。<a href="{link}" target="_blank" rel="noopener noreferrer">こちら</a>を参考に、修正したCSVをアップロードしてください。';
const MSG_CSV_FILE_HEADER_IS_OLD_CHECK = FILE_HEADER_CHECK;
const MSG_CSV_FILE_HEADER_EXIST_GY =
  'P列([G/Y]の列)を削除の上、再度アップロードをお願いします。<br/>※ 2017/3/13よりCSVフォーマットから[G/Y]列を削除しました。';
const MSG_CSV_FILE_HEADER_VALID_CHECK = FILE_HEADER_CHECK;
const MSG_CSV_FILE_CONTENT_VALID_CHECK =
  '広告登録エラーが{length}件あります。対応方法を参考に、修正したCSVをアップロードしてください。';
const MSG_CSV_MAX_LENGTH_CHECK =
  '{label}の文字数を{length}文字以内になるよう修正してください。';
const MSG_CSV_SPTAB_CHECK =
  '{label}を「スペース、タブ、改行」のみで登録することはできません。<br/>{label}の入力欄に「スペース、タブ、改行」以外の文字列を入力してください。';
const MSG_CSV_EMPTY_CHECK =
  '{label}は必須入力項目です。{label}を入力してください。';
const MSG_CSV_CONTROL_CHARACTER_CHECK =
  '{label}に制御文字を含めて登録することはできません。制御文字を削除してください。';
const MSG_CSV_LIMIT_AD_CHECK =
  '広告の登録上限数({length}件)を超えてしまうため、広告を登録することができません。<br/>アドエビスの管理画面上で不要な広告IDを削除するか、広告登録上限UPのオプションサービスをお申込みいただいたうえで、CSVを再アップロードしてください。';
const MSG_CSV_UNREGISTER_PARENT_AD_ID = ErrorMessages.UNREGISTER_PARENT_AD_ID;
const MSG_CSV_FILE_ENCODING_CHECK =
  'CSVファイルの文字コードはSJIS形式にしてください。';
const MSG_CSV_MGROUP_AGENCY_EXIST =
  '選択された{label}に対する閲覧・編集権限がない代理店が指定されています。権限のある代理店を指定するか、代理店に{label}の閲覧・編集権限を設定してください。';
const ELEMENT_ENCODING_CHECK = `使用できない文字（特殊な記号や常用外漢字など）が含まれています`;
const MSG_CSV_FILE_INVALID = `指定されたCSVのフォーマットと異なっています。CSVフォーマットを再度ダウンロードし、正しいフォーマットで再度登録してください。`;

const MSG_BY_FIELD = {
  ad_id: {
    replace: {
      label: '広告ID',
      length: AD_ID_MAX,
      action: '編集',
    },
    message: {
      csv: {
        AD_ID_RESERVED_CHECK,
        EDIT_AD_DELIVERY_TDV_CHECK,
        AD_ONLY_UPDATE_CHECK,
        AD_EXIST_CHECK,
        MAX_LENGTH_CHECK: MSG_CSV_MAX_LENGTH_CHECK,
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
        LIMIT_AD_CHECK: MSG_CSV_LIMIT_AD_CHECK,
        AD_ID_REGISTER_CHECK:
          '{label}に「スペース、タブ、改行」が入力されています。<br/>入力欄内の「スペース、タブ、改行」を削除してください。',
        NOT_ALLOW_AD_PLAN_ID_FORMAT_CHECK:
          '{label}に、半角英数字・ハイフン・アンダーバー以外は使用できません。',
        AD_NOT_LISTING:
          'AD広告が入力されています。AD広告は、[広告管理>AD広告]より登録してください。',
        AD_NOT_EBIS:
          'リスティング連携広告が入力されています。リスティング連携広告は、[広告管理>リスティング連携広告]より登録してください。',
        DELIVERY_TYPE_CHECK_VIEW_THRU:
          'ViewThruエビスの計測で使用している広告であるため、広告管理画面で削除することができません。',
        EMPTY_CHECK: '{label}が存在しません。',
        AD_HAVE_PARENT_NOT_ALLOW_EDIT,
        AD_NOT_IN_AGENCY_CHECK: '広告を{action}する権限がありません。',
      },
      form: {
        MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
        AD_ID_REGISTER_CHECK: MSG_TOOLTIP_AD_ID_REGISTER_CHECK,
        AD_ID_RESERVED_CHECK: MSG_TOOLTIP_AD_ID_RESERVED_CHECK,
        AD_EXIST_CHECK:
          '利用されている{label}です。別の{label}を入力してください',
        AD_NOT_EXIST_CHECK:
          '編集中の広告は既に削除されています。画面を再読み込みし、最新の状態をご確認ください',
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        LIMIT_AD_CHECK: MSG_TOOLTIP_LIMIT_AD_CHECK,
        AD_HAVE_PARENT_NOT_ALLOW_EDIT:
          '新バージョンでは親子広告は編集できません。旧バージョンの広告管理にてCSVを再アップロードしてください',
        AD_NOT_IN_AGENCY_CHECK: '広告を{action}する権限がありません',
      },
    },
  },
  keyword: {
    replace: {
      label: '広告名',
      length: AD_KEYWORD_MAX,
    },
    message: {
      csv: {
        EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
        MAX_LENGTH_CHECK: MSG_CSV_MAX_LENGTH_CHECK,
        CONTROL_CHARACTER_CHECK: MSG_CSV_CONTROL_CHARACTER_CHECK,
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
        REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
      },
      form: {
        REQUIRED_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        EMPTY_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
        CONTROL_CHARACTER_CHECK: MSG_TOOLTIP_CONTROL_CHARACTER_CHECK,
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        ELEMENT_ENCODING_CHECK,
      },
    },
  },
  ad_note: {
    replace: {
      label: '備考',
      length: AD_NOTE_MAX,
    },
    message: {
      csv: {
        MAX_LENGTH_CHECK: MSG_CSV_MAX_LENGTH_CHECK,
        CONTROL_CHARACTER_CHECK: MSG_CSV_CONTROL_CHARACTER_CHECK,
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
      },
      form: {
        MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
        CONTROL_CHARACTER_CHECK: MSG_TOOLTIP_CONTROL_CHARACTER_CHECK,
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        ELEMENT_ENCODING_CHECK,
      },
    },
  },
  base_cpc: {
    replace: {
      label: 'クリック単価',
      label2: 'CV単価',
      min: BASE_COST_RANGE[0],
      max: BASE_COST_RANGE[1],
    },
    message: {
      csv: {
        NUM_CHECK,
        NUM_RANGE_CHECK,
        ONLY_ONE_FIELD_EXIST_CHECK,
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
      },
      form: {
        NUM_CHECK,
        NUM_RANGE_CHECK: MSG_TOOLTIP_NUM_RANGE_CHECK,
        ONLY_ONE_FIELD_EXIST_CHECK,
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
      },
    },
  },
  base_cpconv: {
    replace: {
      label: 'CV単価',
      label2: 'クリック単価',
      min: BASE_COST_RANGE[0],
      max: BASE_COST_RANGE[1],
    },
    message: {
      csv: {
        NUM_CHECK,
        NUM_RANGE_CHECK,
        BASE_CPCONV_VALID_CHECK,
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
        ONLY_ONE_FIELD_EXIST_CHECK,
      },
      form: {
        NUM_CHECK,
        NUM_RANGE_CHECK: MSG_TOOLTIP_NUM_RANGE_CHECK,
        BASE_CPCONV_VALID_CHECK: MSG_TOOLTIP_BASE_CPCONV_VALID_CHECK,
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        ONLY_ONE_FIELD_EXIST_CHECK,
      },
    },
  },
  url: {
    replace: {
      label: 'リンク先URL',
      length: URL_MAX,
    },
    message: {
      csv: {
        LINKS_MINIMUM_CHECK: MSG_TOOLTIP_MINIMUM_URLS_CHECK,
        AD_URL_NOT_QUESTION_MARK_CHECK,
        MAX_LENGTH_CHECK: MSG_CSV_MAX_LENGTH_CHECK,
        EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
        AD_URL_YSS_GOOGLE_CHECK: AD_URL_CHECK,
        AD_URL_FORMAT_CHECK: AD_URL_CHECK,
        AD_URL_EXIT_HASHTAG_CHECK:
          'ダイレクト計測の場合、{label}に「#」は設定できません。<br/>発行後の入稿用URL末尾に手動で付与してください。',
        URL_IN_HOST_CONFIG_CHECK:
          '計測方式が「ダイレクト」の場合、{label}のドメインは「計測対象サイト」に登録されている必要があります。{label}のドメインを計測対象サイトに登録してください。',
        URL_EXIST_ARGUMENT_CHECK: AD_URL_CHECK,
        REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
      },
      form: {
        AD_URL_NOT_QUESTION_MARK_CHECK: MSG_TOOLTIP_AD_URL_NOT_QUESTION_MARK_CHECK,
        MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
        REQUIRED_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        EMPTY_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        AD_URL_YSS_GOOGLE_CHECK: AD_URL_CHECK,
        AD_URL_FORMAT_CHECK: AD_URL_CHECK,
        AD_URL_EXIT_HASHTAG_CHECK: MSG_TOOLTIP_AD_URL_EXIT_HASHTAG_CHECK,
        URL_IN_HOST_CONFIG_CHECK: MSG_TOOLTIP_URL_IN_HOST_CONFIG_CHECK,
      },
    },
  },
  paste_url: {
    replace: {
      label: '入稿用URL',
    },
    message: {
      csv: {
        URL_EXIST_ARGUMENT_CHECK:
          '入稿用URLに不正な情報が入力されています。異なるアカウントの広告は登録できません。入力内容をご確認ください。',
      },
      form: {},
    },
  },
  page_title: {
    replace: {
      label: 'タイトル',
      length: PAGE_TITLE_MAX,
    },
    message: {
      csv: {
        MAX_LENGTH_CHECK: MSG_CSV_MAX_LENGTH_CHECK,
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
        CONTROL_CHARACTER_CHECK: MSG_CSV_CONTROL_CHARACTER_CHECK,
        EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
        REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
      },
      form: {
        MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        CONTROL_CHARACTER_CHECK: MSG_TOOLTIP_CONTROL_CHARACTER_CHECK,
        ELEMENT_ENCODING_CHECK,
      },
    },
  },
  text_data: {
    replace: {
      label: 'テキスト原稿',
      length: TEXT_DATA_MAX,
    },
    message: {
      csv: {
        MAX_LENGTH_CHECK: MSG_CSV_MAX_LENGTH_CHECK,
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
      },
      form: {
        MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        ELEMENT_ENCODING_CHECK,
      },
    },
  },
  ad_start_date: {
    replace: {
      label: '出稿開始日',
      label_ad_start_date: '出稿開始日',
      label_ad_end_date: '出稿終了日',
      min: DATE_RANGE[0],
      max: DATE_RANGE[1],
    },
    message: {
      csv: {
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
        EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
        LESS_THAN_COMPARE_DATE_CHECK: COMPARE_DATE_CHECK,
        MIN_DATE_CHECK: DATE_CHECK,
        FORMAT_DATE_ACCEPTED_CHECK: DATE_CHECK,
      },
      form: {
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        EMPTY_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        LESS_THAN_COMPARE_DATE_CHECK: COMPARE_DATE_CHECK,
        MIN_DATE_CHECK: DATE_CHECK,
        FORMAT_DATE_ACCEPTED_CHECK: DATE_CHECK,
      },
    },
  },
  ad_end_date: {
    replace: {
      label: '出稿終了日',
      label_ad_start_date: '出稿開始日',
      label_ad_end_date: '出稿終了日',
      min: DATE_RANGE[0],
      max: DATE_RANGE[1],
    },
    message: {
      csv: {
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
        EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
        MORE_THAN_COMPARE_DATE_CHECK: COMPARE_DATE_CHECK,
        MAX_DATE_CHECK: DATE_CHECK,
        FORMAT_DATE_ACCEPTED_CHECK: DATE_CHECK,
      },
      form: {
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        EMPTY_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        MORE_THAN_COMPARE_DATE_CHECK: COMPARE_DATE_CHECK,
        MAX_DATE_CHECK: DATE_CHECK,
        FORMAT_DATE_ACCEPTED_CHECK: DATE_CHECK,
      },
    },
  },
  ad_group1_id: {
    replace: {
      label: '広告グループ01',
      menu: '広告グループ1',
    },
    message: {
      csv: {
        AD_GROUP1_NAME_EXIST_CHECK: AD_MASTER_DATA_EXIST_CHECK,
        AD_GROUP1_NAME_AGENCY_EXIST_CHECK: MSG_CSV_MGROUP_AGENCY_EXIST,
      },
      form: {
        AD_GROUP1_EXIST_CHECK: MSG_TOOLTIP_AD_MASTER_DATA_EXIST_CHECK,
        AD_GROUP1_AGENCY_EXIST_CHECK:
          ErrorMessages.AD_GROUP1_AGENCY_EXIST_CHECK,
      },
    },
  },
  ad_group2_id: {
    replace: {
      label: '広告グループ02',
      menu: '広告グループ2',
    },
    message: {
      csv: {
        AD_GROUP2_NAME_EXIST_CHECK: AD_MASTER_DATA_EXIST_CHECK,
        AD_GROUP2_NAME_AGENCY_EXIST_CHECK: MSG_CSV_MGROUP_AGENCY_EXIST,
      },
      form: {
        AD_GROUP2_EXIST_CHECK: MSG_TOOLTIP_AD_MASTER_DATA_EXIST_CHECK,
        AD_GROUP2_AGENCY_EXIST_CHECK:
          ErrorMessages.AD_GROUP2_AGENCY_EXIST_CHECK,
      },
    },
  },
  media_id: {
    replace: {
      label: '媒体種別',
      menu: '媒体種別',
    },
    message: {
      csv: {
        EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
        MEDIA_NAME_EXIST_CHECK: AD_MASTER_DATA_EXIST_CHECK,
        MEDIA_NAME_AGENCY_EXIST_CHECK: MSG_CSV_MGROUP_AGENCY_EXIST,
        REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
      },
      form: {
        EMPTY_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        MEDIA_EXIST_CHECK: MSG_TOOLTIP_AD_MASTER_DATA_EXIST_CHECK,
        MEDIA_AGENCY_EXIST_CHECK: ErrorMessages.MEDIA_AGENCY_EXIST_CHECK,
      },
    },
  },
  agency_id: {
    replace: {
      label: '担当代理店',
    },
    message: {
      csv: {
        AGENCY_NAME_EXIST_CHECK:
          '選択された代理店はすでに削除されています。別の代理店を選択してください。',
        COMPARE_WITH_TARGET: '担当代理店は変更できません。',
      },
      form: {
        AGENCY_ID_EXIST_CHECK: ErrorMessages.AGENCY_ID_EXIST_CHECK,
        COMPARE_WITH_TARGET: '担当代理店は変更できません。',
      },
    },
  },
  measurement_type: {
    replace: {
      label: '計測方式',
    },
    message: {
      csv: {
        EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
        ACCEPT_VALUE_CHECK:
          '{label}に「リダイレクト、ダイレクト」のいずれかを入力してください。（空欄の場合は自動的に「ダイレクト」で登録されます）',
        EDIT_MEASUREMENT_TYPE_CHECK:
          '{label}に「リダイレクト、空欄」のいずれかを入力した状態に修正してください。（空欄の場合は自動的に「リダイレクト」で登録されます）',
        MEASURE_TYPE_EDIT_CHECK:
          'リスティング連携広告は、{label}の変更ができません。<br/>計測方法を元の値に修正してください。',
      },
      form: {
        ACCEPT_VALUE_CHECK:
          '{label}は「ダイレクト計測」「リダイレクト計測」のいずれかを選択してください',
        EDIT_MEASUREMENT_TYPE_CHECK:
          '{label}に「リダイレクト、空欄」のいずれかを入力した状態に修正してください。（空欄の場合は自動的に「リダイレクト」で登録されます）',
      },
    },
  },
  parent_ad_id: {
    replace: {
      label: '親広告ID',
    },
    message: {
      csv: {
        PARENT_AD_ID_TERMINAL_TYPE_VALID_CHECK: '{label}が指定されていません。',
        AD_ID_MULTI_DEVICE_URL_NOT_ALLOW_SET_PARENT_ID_CHECK:
          'この広告には{label}を設定する必要がありませんので、CSV内の{label}を削除して、再度アップロードしてください。',
        NOT_ALLOW_CANCEL_PARENT_AD_ID_CHECK:
          'リスティング連携済広告のデバイス別計測設定は解除できません。',
        NOT_ALLOW_CHANGE_PARENT_AD_ID_CHECK: '{label}は変更できません。',
        UNREGISTER_PARENT_AD_ID:
          '{label}として入力されたIDは、アドエビス管理画面上に広告IDとして登録されていません。',
        PARENT_AD_ID_NOT_IS_DELIVERY:
          'ViewThruエビスの計測で使用している広告であるため、{label}を指定できません',
        OVERLAP_TERMINAL_TYPE:
          '1つの{label}に同じデバイスの広告IDを複数紐付けることはできません',
        ONY_LISTING_IS_PARENT:
          '対象広告の広告ID列、{label}列が一致していません。',
        ONLY_CHILD_AD: '対象広告の広告ID列、{label}列が一致していません。',
      },
      form: {},
    },
  },
  terminal_type: {
    replace: {
      label: 'デバイス',
    },
    message: {
      csv: {
        NOT_ALLOW_CHANGE_TERMINAL_TYPE_CHECK: '{label}は変更できません',
      },
      form: {},
    },
  },
  banner: {
    replace: {
      label: 'バナー原稿',
      size: BANNER_MAX_MEGABYTES,
      extension: '.gif / .jpg / .png',
    },
    message: {
      csv: {},
      form: {
        FILE_EXIST_CHECK: '{label}ファイルをアップロードしてください',
        FILE_EXTENSION_CHECK: MSG_TOOLTIP_FILE_EXTENSION_CHECK,
        FILE_SIZE_CHECK: MSG_TOOLTIP_FILE_SIZE_CHECK,
      },
    },
  },
  transition_rate: {
    replace: {
      label: '遷移割合',
      min: 0,
      max: 100,
    },
    message: {
      // Remember to add TRANSITION_RATE_MASSAGE too
      csv: {
        REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
        SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
        NUM_CHECK,
        NUM_RANGE_CHECK,
        TOTAL_TRANSITION_RATE_CHECK: MSG_TOOLTIP_TOTAL_RATE_CHECK,
        EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
      },
      form: {
        REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
        NUM_CHECK,
        NUM_RANGE_CHECK,
        TOTAL_TRANSITION_RATE_CHECK: MSG_TOOLTIP_TOTAL_RATE_CHECK,
        EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
      },
    },
  },
  lpo_api_url: {
    replace: {
      label: 'リンク先URL',
      length: URL_MAX,
    },
    message: {
      form: {
        LINKS_MINIMUM_CHECK: MSG_TOOLTIP_MINIMUM_URLS_CHECK,
        REQUIRED_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
        AD_URL_NOT_QUESTION_MARK_CHECK: MSG_TOOLTIP_AD_URL_NOT_QUESTION_MARK_CHECK,
        AD_URL_FORMAT_CHECK: AD_URL_CHECK,
      },
    },
  },
  lpo_api_page_title: {
    replace: {
      label: 'タイトル',
      length: PAGE_TITLE_MAX,
    },
    message: {
      form: {
        REQUIRED_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
        CONTROL_CHARACTER_CHECK: MSG_TOOLTIP_CONTROL_CHARACTER_CHECK,
        MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
        ELEMENT_ENCODING_CHECK,
      },
    },
  },
  lpo_api_transition_rate: {
    replace: {
      label: '遷移割合',
      min: 0,
      max: 100,
    },
    message: {
      form: {
        REQUIRED_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
        NUM_CHECK,
        NUM_RANGE_CHECK,
        TOTAL_TRANSITION_RATE_CHECK: MSG_TOOLTIP_TOTAL_RATE_CHECK,
      },
    },
  },
};

const LINK_URL_MASSAGE = {
  csv: {
    LINKS_MINIMUM_CHECK: MSG_TOOLTIP_MINIMUM_URLS_CHECK,
    AD_URL_NOT_QUESTION_MARK_CHECK,
    MAX_LENGTH_CHECK: MSG_CSV_MAX_LENGTH_CHECK,
    EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
    SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
    AD_URL_YSS_GOOGLE_CHECK: AD_URL_CHECK,
    AD_URL_FORMAT_CHECK: AD_URL_CHECK,
    AD_URL_EXIT_HASHTAG_CHECK:
      'ダイレクト計測の場合、{label}に「#」は設定できません。<br/>発行後の入稿用URL末尾に手動で付与してください。',
    URL_IN_HOST_CONFIG_CHECK:
      '計測方式が「ダイレクト」の場合、{label}のドメインは「計測対象サイト」に登録されている必要があります。{label}のドメインを計測対象サイトに登録してください。',
    URL_EXIST_ARGUMENT_CHECK: AD_URL_CHECK,
    REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
  },
  form: {
    AD_URL_NOT_QUESTION_MARK_CHECK: MSG_TOOLTIP_AD_URL_NOT_QUESTION_MARK_CHECK,
    MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
    REQUIRED_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
    EMPTY_CHECK: MSG_TOOLTIP_EMPTY_CHECK,
    SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
    AD_URL_YSS_GOOGLE_CHECK: AD_URL_CHECK,
    AD_URL_FORMAT_CHECK: AD_URL_CHECK,
    AD_URL_EXIT_HASHTAG_CHECK: MSG_TOOLTIP_AD_URL_EXIT_HASHTAG_CHECK,
    URL_IN_HOST_CONFIG_CHECK: MSG_TOOLTIP_URL_IN_HOST_CONFIG_CHECK,
  },
};

const PAGE_TITLE_MASSAGE = {
  csv: {
    MAX_LENGTH_CHECK: MSG_CSV_MAX_LENGTH_CHECK,
    SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
    CONTROL_CHARACTER_CHECK: MSG_CSV_CONTROL_CHARACTER_CHECK,
    EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
    REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
  },
  form: {
    MAX_LENGTH_CHECK: MSG_TOOLTIP_MAX_LENGTH_CHECK,
    SPTAB_CHECK: MSG_TOOLTIP_SPTAB_CHECK,
    CONTROL_CHARACTER_CHECK: MSG_TOOLTIP_CONTROL_CHARACTER_CHECK,
    ELEMENT_ENCODING_CHECK,
  },
};

const TRANSITION_RATE_MASSAGE = {
  csv: {
    REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
    SPTAB_CHECK: MSG_CSV_SPTAB_CHECK,
    NUM_CHECK,
    NUM_RANGE_CHECK,
    TOTAL_TRANSITION_RATE_CHECK: MSG_TOOLTIP_TOTAL_RATE_CHECK,
    EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
  },
  form: {
    REQUIRED_CHECK: MSG_CSV_EMPTY_CHECK,
    NUM_CHECK,
    NUM_RANGE_CHECK,
    TOTAL_TRANSITION_RATE_CHECK: MSG_TOOLTIP_TOTAL_RATE_CHECK,
    EMPTY_CHECK: MSG_CSV_EMPTY_CHECK,
  },
};

export default {
  NUM_RANGE_CHECK,
  COMPARE_DATE_CHECK,

  MSG_CSV_IMPORT_ERROR,
  MSG_CSV_FILE_EXIST_CHECK,
  MSG_CSV_FILE_SIZE_CHECK,
  MSG_CSV_FILE_INVALID,
  MSG_CSV_FILE_MAX_LINE_CHECK,
  MSG_CSV_TOO_MAX_ERROR,
  MSG_CSV_FILE_HEADER_IS_OLD_CHECK,
  MSG_CSV_FILE_HEADER_EXIST_GY,
  MSG_CSV_FILE_HEADER_VALID_CHECK,
  MSG_CSV_FILE_CONTENT_VALID_CHECK,
  MSG_CSV_LIMIT_AD_CHECK,
  MSG_CSV_UNREGISTER_PARENT_AD_ID,
  MSG_CSV_FILE_ENCODING_CHECK,
  MSG_CSV_SPTAB_CHECK,
  MSG_CSV_MAX_LENGTH_CHECK,
  MSG_CSV_EMPTY_CHECK,

  MSG_TOOLTIP_MAX_LENGTH_CHECK,
  MSG_TOOLTIP_AD_ID_REGISTER_CHECK,
  MSG_TOOLTIP_AD_ID_RESERVED_CHECK,
  MSG_TOOLTIP_SPTAB_CHECK,
  MSG_TOOLTIP_EMPTY_CHECK,
  MSG_TOOLTIP_CONTROL_CHARACTER_CHECK,
  MSG_TOOLTIP_NUM_RANGE_CHECK,
  MSG_TOOLTIP_BASE_CPCONV_VALID_CHECK,
  MSG_TOOLTIP_AD_URL_NOT_QUESTION_MARK_CHECK,
  MSG_TOOLTIP_AD_URL_FORMAT_CHECK,
  MSG_TOOLTIP_AD_URL_EXIT_HASHTAG_CHECK,
  MSG_TOOLTIP_URL_IN_HOST_CONFIG_CHECK,
  MSG_TOOLTIP_FORMAT_DATE_ACCEPTED_CHECK,
  MSG_TOOLTIP_FILE_EXTENSION_CHECK,
  MSG_TOOLTIP_FILE_SIZE_CHECK,
  MSG_TOOLTIP_LIMIT_AD_CHECK,
  MSG_TOOLTIP_TOTAL_RATE_CHECK,
  MSG_TOOLTIP_MINIMUM_URLS_CHECK,
  MSG_TOOLTIP_NUMBER_INTEGER,

  MSG_BY_FIELD,
  LINK_URL_MASSAGE,
  PAGE_TITLE_MASSAGE,
  TRANSITION_RATE_MASSAGE,
};
