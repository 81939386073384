import React, { useState, useRef, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import {
  number,
  string,
  array,
  arrayOf,
  shape,
  oneOfType,
  bool,
} from 'prop-types';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import 'views/organism/TableConfirmDetail/table-confirm-detail.scss';

function TableConfirmDetail(props) {
  const {
    isTableHover,
    columns,
    rows,
    maxContent,
    alignScroll,
    isPureString,
  } = props;
  const scrollbarWrapperRef = useRef(null);
  const [classTableShadow, setClassTableShadow] = useState('');

  useEffect(() => {
    if (!scrollbarWrapperRef.current) return () => {};

    const scrollbarContentEl = scrollbarWrapperRef.current.querySelector(
      '.scrollbar-wrapper__content'
    );

    const setShadow = () => {
      setClassTableShadow(
        scrollbarContentEl.scrollTop > 0 ? 'table-shadow' : ''
      );
    };

    scrollbarContentEl.addEventListener('scroll', setShadow);
    return () => {
      scrollbarContentEl.removeEventListener('scroll', setShadow);
    };
  }, []);

  const cellContent = (value, width) => {
    if (isArray(value)) {
      return (
        <td width={width}>
          {value.map((item, index) => {
            const key = index;
            return (
              <div key={key} className="break-line">
                {item}
              </div>
            );
          })}
        </td>
      );
    }

    if (isString(value)) {
      const hasBreakLine = /<br\s*[/]?>/.test(value);
      return isPureString ? (
        <td width={width} className={hasBreakLine ? 'break-line' : ''}>
          {value}
        </td>
      ) : (
        <td
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: value }}
          width={width}
          className={hasBreakLine ? 'break-line' : ''}
        />
      );
    }

    return <td width={width}>{value}</td>;
  };

  return (
    <div className="table-confirm-detail">
      <div className="table-confirm-detail__thead">
        <Table bordered className={classTableShadow}>
          <thead>
            <tr>
              {columns.map((column) => {
                return <th width={column.width}>{column.title}</th>;
              })}
            </tr>
          </thead>
        </Table>
      </div>
      <div className="table-confirm-detail__tbody" ref={scrollbarWrapperRef}>
        <ScrollbarWrapper
          ref={scrollbarWrapperRef}
          maxContent={maxContent}
          alignScroll={alignScroll}
        >
          <Table bordered striped hover={isTableHover}>
            <tbody>
              {rows.map((row) => {
                return (
                  <tr>
                    {columns.map((column) =>
                      cellContent(row[column.name], column.width)
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ScrollbarWrapper>
      </div>
    </div>
  );
}

TableConfirmDetail.propTypes = {
  isTableHover: bool,
  columns: arrayOf(
    shape({
      name: string.isRequired,
      title: string.isRequired,
      width: number,
    })
  ),
  rows: oneOfType([array]),
  maxContent: number,
  alignScroll: number,
  isPureString: bool,
};

TableConfirmDetail.defaultProps = {
  isTableHover: false,
  columns: [],
  rows: [],
  maxContent: -1,
  alignScroll: -20,
  isPureString: false,
};

export default TableConfirmDetail;
