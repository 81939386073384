import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from 'lang/en-US.json';
import translationJP from 'lang/ja-JP.json';
import translationVN from 'lang/vi-VN.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN,
  },
  jp: {
    translation: translationJP,
  },
  vn: {
    translation: translationVN,
  },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'jp',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
