/* eslint-disable no-console */
const LoggingFactory = () => {
  const enabled =
    process.env.NODE_ENV !== 'production' &&
    process.env.REACT_APP_DEBUG === 'true';
  return {
    log: (...args) => {
      if (enabled) {
        console.log(...args);
      }
    },
    info: (...args) => {
      if (enabled) {
        console.info(...args);
      }
    },
    trace: (...args) => {
      if (enabled) {
        console.trace(...args);
      }
    },
    warn: (...args) => {
      if (enabled) {
        console.warn(...args);
      }
    },
    error: (...args) => {
      if (enabled) {
        console.error(...args);
      }
    },
    dir: (...args) => {
      if (enabled) {
        console.dir(...args);
      }
    },
    group: (...args) => {
      if (enabled) {
        console.group(...args);
      }
    },
    groupCollapsed: (...args) => {
      if (enabled) {
        console.groupCollapsed(...args);
      }
    },
    groupEnd: (...args) => {
      if (enabled) {
        console.groupEnd(...args);
      }
    },
    table: (...args) => {
      if (enabled) {
        console.table(...args);
      }
    },
    debug: (...args) => {
      if (enabled) {
        console.debug(...args);
      }
    },
  };
};

const logging = LoggingFactory();
export default logging;
