import React, { useState, useRef } from 'react';
import { func, bool, shape, objectOf, number, string } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import EbisPagination from 'views/molecules/stateless-pagination';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import SortItem from 'views/atoms/sort/SortItem';
import './sort-list-modal.scss';

function SortListModal(props) {
  const {
    isShow,
    onHide,
    onSubmit,
    list = [],
    pagination,
    title,
    subTitle,
    loading,
    isPagination,
  } = props;
  const [currentListData, setCurrentListData] = useState(list);

  // variable save value item is dragging and dragOver
  const draggingItem = useRef();
  const dragOverItem = useRef();

  const handleDragOver = (e) => e.preventDefault();

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
  };

  const handleDragEnter = (e, position) => {
    dragOverItem.current = position;
    const listCopy = [...currentListData];
    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1);
    listCopy.splice(dragOverItem.current, 0, draggingItemContent);

    draggingItem.current = dragOverItem.current;
    dragOverItem.current = null;
    setCurrentListData(listCopy);
  };

  const handleDragEnd = () => {
    // when drag end, set data rank change by page
    setCurrentListData((newList) => [...newList]);
    draggingItem.current = null;
  };

  const handleSubmit = () => onSubmit(currentListData);

  return (
    <Modal
      show={isShow}
      centered
      size="w800"
      dialogClassName="sort-list__modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        {subTitle && (
          <span>
            {subTitle.title}
            {subTitle?.dragonball && (
              <DragonBall>{subTitle.dragonball}</DragonBall>
            )}
          </span>
        )}
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader
          isLoading={loading}
          className={loading && 'sort-list__modal-loading'}
        >
          <div className="sort-list__container">
            {isPagination && (
              <EbisPagination
                variant="settings"
                totalItems={pagination.totalItems}
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                changePage={pagination.handleChangePage}
                visiblePageNum={pagination.visiblePageNum}
              />
            )}
            <div className="sort-list__box">
              <ul className="sort-list__box-table">
                {currentListData.map((item, index) => (
                  <SortItem
                    name={item.name}
                    index={index}
                    handleDragOver={handleDragOver}
                    handleDragStart={handleDragStart}
                    handleDragEnd={handleDragEnd}
                    draggingItem={draggingItem}
                    handleDragEnter={handleDragEnter}
                    className={
                      draggingItem.current &&
                      draggingItem.current === Number(index)
                        ? 'sort-item__dragging'
                        : ''
                    }
                  />
                ))}
              </ul>
            </div>
          </div>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="link" disabled={loading} onClick={onHide}>
          キャンセル
        </Button>
        <Button
          size="sm"
          variant="secondary"
          disabled={loading}
          onClick={handleSubmit}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SortListModal.propTypes = {
  loading: bool,
  isShow: bool.isRequired,
  onSubmit: func.isRequired,
  onHide: func.isRequired,
  list: shape([]).isRequired,
  pagination: objectOf(
    shape({
      totalItems: number,
      itemsPerPage: number,
      currentPage: number,
      handleChangePage: func,
    })
  ),
  title: string.isRequired,
  subTitle: objectOf(shape({ title: string, dragonball: string })),
  isPagination: bool,
};

SortListModal.defaultProps = {
  loading: false,
  isPagination: false,
  pagination: objectOf(
    shape({
      totalItems: 0,
      itemsPerPage: 0,
      currentPage: 1,
      visiblePageNum: false,
      handleChangePage: () => {},
    })
  ),
  subTitle: objectOf(shape({ title: '', dragonball: '' })),
};

export default SortListModal;
