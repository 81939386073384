import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button } from 'react-bootstrap';
import systemSettingDisplayActions from 'store/system-setting/display/actions';
import systemSettingDisplaySelectors from 'store/system-setting/display/selectors';
import useModalController from 'services/custom-hooks/useModalController';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import TableViewDetail from 'views/organism/table-view-detail/TableViewDetail';
import LabelSettingModal from 'views/pages/system-setting/display/components/LabelSettingModal';
import RcvSettingModal from 'views/pages/system-setting/display/components/RcvSettingModal';

import './display-setting.scss';
import { RCV_PATTERN } from 'domain/system-setting/consts-display-setting';
import { RCV_MODEL_OPTION } from 'domain/fields';

const MODAL_ID = {
  LABEL_SETTING: 'LABEL_SETTING',
  RCV_SETTING: 'RCV_SETTING',
};

function DisplaySettingContainer() {
  const dispatch = useDispatch();

  const { openModal, closeModals, isOpen } = useModalController({
    ids: Object.values(MODAL_ID),
  });

  // Get data from store
  const { isLoading, isLoadingSetting, label, conversion, rcv } = useSelector(
    systemSettingDisplaySelectors.getStates,
    shallowEqual
  );

  const handleGetData = useCallback(() => {
    dispatch(systemSettingDisplayActions.getData());
  }, [dispatch]);

  const handleResponse = (handleError = () => {}) => ({
    isError = false,
    errors = [],
  }) => {
    if (isError) {
      handleError(errors);
    } else {
      closeModals();
      handleGetData();
    }
  };

  const handleSubmitSetting = (data, callback) => {
    if (isOpen(MODAL_ID.LABEL_SETTING)) {
      dispatch(
        systemSettingDisplayActions.updateVision(data, handleResponse(callback))
      );
    } else if (isOpen(MODAL_ID.RCV_SETTING)) {
      dispatch(
        systemSettingDisplayActions.updateRcv(data, handleResponse(callback))
      );
    }
  };

  // Call api get data
  useEffect(() => {
    handleGetData();
  }, [dispatch, handleGetData]);

  return (
    <div className="display-setting h-100">
      <DataSyncLoader isLoading={isLoading}>
        <div className="display-setting__screen">
          <FormGroup
            required
            label="ラベル"
            tooltip={
              <>
                ラベル名を任意の名前に変更します。
                <br />
                設定した名前は各画面に反映されます。
              </>
            }
          >
            <TableViewDetail
              classTable="table-view-detail--label"
              data={label.display}
            />
          </FormGroup>
          <FormGroup
            variant="conversion"
            label={
              <>
                <span>コンバージョン表示順</span>
                <span>上位5件を表示</span>
              </>
            }
          >
            <TableViewDetail
              classTable="table-view-detail--conversion"
              emptyMessage="コンバージョンが登録されていません"
              data={conversion.display}
            />
          </FormGroup>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => openModal(MODAL_ID.LABEL_SETTING)}
          >
            編集
          </Button>
          <hr />
          <FormGroup label="再配分コンバージョンモデル" variant="rcv">
            {RCV_PATTERN[rcv[RCV_MODEL_OPTION]]?.name}
          </FormGroup>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => openModal(MODAL_ID.RCV_SETTING)}
          >
            編集
          </Button>
        </div>
      </DataSyncLoader>
      {isOpen(MODAL_ID.LABEL_SETTING) && (
        <LabelSettingModal
          loading={isLoadingSetting}
          label={label.setting}
          conversions={conversion.setting}
          onSubmit={handleSubmitSetting}
          onHide={closeModals}
        />
      )}
      {isOpen(MODAL_ID.RCV_SETTING) && (
        <RcvSettingModal
          loading={isLoadingSetting}
          data={rcv}
          onSubmit={handleSubmitSetting}
          onHide={closeModals}
        />
      )}
    </div>
  );
}

export default DisplaySettingContainer;
