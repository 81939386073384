import { v4 as uuidv4 } from 'uuid';
import { containsByAllKeys, equalsAllKeys } from 'services/utils';
import { CHART_COLORS, DECIMAL_FIELDS, PERCENTAGE_FIELDS } from 'domain/consts';
import { CHART_PATTERNS } from 'domain/category-analyze/consts';
import * as domainUtils from 'domain/utils';
import range from 'lodash/range';
import round from 'lodash/round';
import summaryService from './summaryService';
import { backgroundImgPattern1, backgroundImgPattern2 } from './backgroundImg';

const buildDisplayData = ({
  list,
  axis,
  selectedRows,
  selectedCategories,
  visibleList,
}) => {
  return list
    .filter((item) => containsByAllKeys(selectedCategories, item.dimensionKey))
    .map((item, index) => {
      const data = [
        {
          x: item[axis.x] || 0,
          y: item[axis.y] || 0,
          selected: containsByAllKeys(selectedRows, item.dimensionKey),
        },
      ];
      return {
        dimensionKey: item.dimensionKey,
        defaultColor: CHART_COLORS[index + 1].primary,
        data: data.map((point) => ({
          ...point,
          x: round(point.x, 2),
          y: round(point.y, 2),
        })),
        name: summaryService.createSeriesName(item, item.dimensionKey),
        visible: containsByAllKeys(visibleList, item.dimensionKey),
        color: CHART_COLORS[index + 1].primary,
        appendArrow: false,
        compared: false,
      };
    });
};

const buildComparedDisplayData = ({
  list,
  comparedList,
  axis,
  selectedRows,
  selectedCategories,
  visibleList,
}) => {
  const matchingComparedList = comparedList.filter((item) =>
    containsByAllKeys(selectedCategories, item.dimensionKey)
  );

  return list
    .filter((item) => containsByAllKeys(selectedCategories, item.dimensionKey))
    .map((item, index) => {
      const selected = containsByAllKeys(selectedRows, item.dimensionKey);
      const data = [];

      const comparedItem = matchingComparedList.find((elem) =>
        equalsAllKeys(item.dimensionKey, elem.dimensionKey)
      );
      if (comparedItem) {
        data.push({
          x: comparedItem[axis.x] || 0,
          y: comparedItem[axis.y] || 0,
          selected,
        });
      } else {
        data.push({
          x: 0,
          y: 0,
          selected,
        });
      }

      data.push({
        x: item[axis.x] || 0,
        y: item[axis.y] || 0,
        selected,
      });

      const visible = containsByAllKeys(visibleList, item.dimensionKey);
      const color = CHART_COLORS[index + 1].primary;
      return {
        id: uuidv4(),
        dimensionKey: item.dimensionKey,
        defaultColor: color,
        data: data.map((point) => ({
          ...point,
          x: round(point.x, 2),
          y: round(point.y, 2),
        })),
        name: summaryService.createSeriesName(item, item.dimensionKey),
        visible,
        color: visible ? color : 'rgba(255,255,255,0)',
        appendArrow: visible,
        compared: true,
      };
    });
};

const buildCategoriesFromDimensionKey = (list) =>
  list.map((elem) => elem.dimensionKey);

/*
 * Get background ABCD base on Axis selected from select option
 */
const getBackgroundImg = (axis) => {
  return CHART_PATTERNS.find(
    (pattern) => pattern.x === axis.x && pattern.y === axis.y
  )?.pattern === 1
    ? backgroundImgPattern1
    : backgroundImgPattern2;
};

/**
 * Format the content of tooltip in case: compare period and don't compare period
 * @return html
 */
const formatTooltip = (axis, period, comparedPeriod) => ({
  points,
  seriesName,
  color,
}) => (displayField) => {
  const pointDataHtml = (id) =>
    `<div class="highcharts-tooltip__point">
        <span class="point-y">${displayField(
          axis.y
        )} : <b>${domainUtils.formatValueForTable(points[id].y, axis.y, {
      showZeros: true,
    })}</b></span><span class="point-x">${displayField(
      axis.x
    )} : <b>${domainUtils.formatValueForTable(points[id].x, axis.x, {
      showZeros: true,
    })}</b></span>
      </div>`;

  let contentSeries = `<table cellpadding="0" cellspacing="0"><tr><td><div class="highcharts-tooltip__title"><span style="background-color: ${color};"></span><span>${seriesName}</span></div></td></tr>`;
  if (!comparedPeriod.enabled) {
    // In case don't compare
    contentSeries += `<tr><td>${pointDataHtml(0)}</td></tr></table>`;
  } else {
    // In case compare
    // if (isCompare)
    const dateOrig = domainUtils.formatDateRange(period.start, period.end);
    const dateCompare = domainUtils.formatDateRange(
      comparedPeriod.start,
      comparedPeriod.end
    );

    const p1 = `<td class="highcharts-tooltip__box-item">
        <div class="highcharts-tooltip__date">${dateCompare}</div>
        ${pointDataHtml(0)}
      </td>`;
    const p2 = `<td class="highcharts-tooltip__box-item">
        <div class="highcharts-tooltip__date">${dateOrig}</div>
        ${pointDataHtml(1)}
      </td>`;
    contentSeries += `<tr class="highcharts-tooltip__box">${p1}${p2}</tr></table>`;
  }

  return contentSeries;
};

const onPointClick = (e) => (selectRow, deselectRow) => {
  const { selected } = e.point.options;
  const { dimensionKey } = e.point.series.options;
  if (selected) {
    deselectRow(dimensionKey);
  } else {
    selectRow(dimensionKey);
  }
};

const formatBigNumber = (value) => {
  if (value < 10000) return `${value}`;
  const ttValue = value / 10000;
  return `${round(ttValue, 2)}万`;
};

const roundNumber = (number) => {
  let rounded;
  if (number >= 100) {
    rounded = round(number, 0);
  } else {
    rounded = round(number, 2);
  }
  return rounded;
};

const formatInteger = (number) => round(number, 2).toLocaleString();

const formatDecimal = (number) => {
  if (number > 100) {
    return number.toLocaleString('ja', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
  return number.toLocaleString('ja', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

const formatLabel = (value, metric) => {
  if (value === null || value === undefined) return '';

  if (![...DECIMAL_FIELDS, ...PERCENTAGE_FIELDS].includes(metric)) {
    // In case this is an integer number field
    // do not show any decimal value
    if (value > 0 && value < 10 && value % 1 !== 0) {
      // return '';
    }
  }

  const roundedValue = roundNumber(value);

  let formattedValue;
  if (roundedValue > 10000) {
    formattedValue = formatBigNumber(roundedValue);
  } else if (DECIMAL_FIELDS.includes(metric)) {
    formattedValue = formatDecimal(roundedValue);
  } else {
    formattedValue = formatInteger(roundedValue);
  }

  return formattedValue;
};

const buildChartOptions = ({
  displayData,
  middlePoint,
  axis,
  period,
  comparedPeriod,
}) => {
  const { x: middleX, y: middleY } = middlePoint;

  let xAxisMax = middleX
    ? middleX * 2
    : displayData
        .filter((item) => item.visible)
        .map((item) => item.data)
        .flatMap((data) => [...data])
        .map((data) => data.x)
        .reduce((x1, x2) => Math.max(x1, x2), 0);
  if (xAxisMax <= 0) {
    xAxisMax = 1;
  }
  let yAxisMax = middleY
    ? middleY * 2
    : displayData
        .filter((item) => item.visible)
        .map((item) => item.data)
        .flatMap((data) => [...data])
        .map((data) => data.y)
        .reduce((y1, y2) => Math.max(y1, y2), 0);
  if (yAxisMax <= 0) {
    yAxisMax = 1;
  }
  const xTicks = 9;
  const yTicks = 5;

  const xTickPositions = range(0, xTicks + 1).map(
    (index) => index * (xAxisMax / xTicks)
  );
  const yTickPositions = range(0, yTicks + 1).map(
    (index) => index * (yAxisMax / yTicks)
  );

  return {
    chart: {
      animation: true,
      type: 'scatter',
      width: 690,
      height: 341,
      spacingTop: 41,
      spacingLeft: -5,
      marginLeft: 70,
      backgroundColor: 'rgba(0,0,0,0)',
      plotBackgroundImage: getBackgroundImg(axis),
      style: {
        fontFamily: 'メイリオ, Meiryo, sans-serif',
        width: '100%',
      },
    },
    title: {
      text: null,
    },
    xAxis: {
      min: 0,
      // tickInterval: ChartService.getTickInterval('x', xAxisMax),
      // tickInterval: Math.floor(xAxisMax / 11),
      // max: xAxisMax + 0.001,
      max: xAxisMax + 0.0001,
      // tickAmount: 11,
      tickPositions: xTickPositions,
      endOnTick: false,
      lineColor: 'transparent',
      tickColor: '#DDDDDD',
      gridLineWidth: 1,
      gridLineColor: '#DDDDDD',
      labels: {
        // useHTML: true,
        style: {
          fontSize: '11px',
          color: '#000000',
          paddingTop: '4px',
          textOverflow: 'none',
        },
        // overflow: 'allow',
        // x: -10,
        y: 23,
        formatter() {
          return formatLabel(round(this.value, 2), axis.x);
        },
      },
      title: {
        text: axis.x,
        align: 'high',
        y: 3,
        x: 8,
        style: {
          color: '#000000',
          fontSize: '11px',
        },
      },
      plotLines: [
        {
          color: '#E6E6E6',
          width: 2,
        },
      ],
    },
    yAxis: {
      min: 0,
      max: yAxisMax + 0.001,
      // tickAmount: 6,
      // tickInterval: ChartService.getTickInterval('y', yAxisMax),
      // tickInterval: Math.floor(yAxisMax / 6),
      tickPositions: yTickPositions,
      endOnTick: false,
      lineColor: '#DDDDDD',
      gridLineWidth: 1,
      gridLineColor: '#DDDDDD',
      labels: {
        style: {
          fontSize: '11px',
          textOverflow: 'none',
          color: '#000000',
        },
        x: -15,
        // overflow: 'allow',
        formatter() {
          return formatLabel(round(this.value, 2), axis.y);
        },
      },
      title: {
        text: axis.y,
        align: 'high',
        offset: 69,
        rotation: 0,
        y: -20,
        textAlign: 'left',
        style: {
          color: '#000000',
          fontSize: '11px',
        },
      },
      plotLines: [
        {
          color: '#E6E6E6',
          width: 2,
        },
      ],
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        visible: true,
        stickyTracking: false,
        // Allow select event will make only one point selectable at a time
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}',
          style: {
            width: '200px',
            // textOverflow: 'ellipsis',
            // overflow: 'hidden',
          },
          allowOverlap: true,
        },
        marker: {
          // enabled: true,
          symbol: 'circle',
          radius: 5,
          states: {
            hover: {
              // lineWidth: 1,
              radiusPlus: 0.5,
            },
            select: {
              fillColor: null,
              lineColor: '#FFFFA9',
              lineWidth: 6,
              radius: 8,
            },
          },
        },
        // states: {
        //   hover: {
        //     marker: {
        //       enabled: false,
        //     },
        //   },
        // },
        point: {
          eventFunc: onPointClick,
          /* events: {
            click: onPointClick,
          }, */
        },
      },
      scatter: {
        lineWidth: 2,
        states: {
          // hover: {
          //   lineWidthPlus: 0,
          //   halo: {
          //     opacity: 0.2,
          //     size: 12,
          //   },
          // },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#FFFFFF',
      borderRadius: 6,
      borderWidth: 1,
      padding: 0,
      hideDelay: 200,
      style: {
        color: '#000000',
        fontSize: '11px',
      },
      shadow: {
        color: 'rgba(0,0,0,0.16)',
        width: 6,
      },
      formatterFunc: formatTooltip(axis, period, comparedPeriod),
    },
    series: displayData,
    exporting: {
      fallbackToExportServer: false,
      enabled: false,
    },
  };
};

const appendArrowToSingleSeries = (series) => {
  let arrowCheck = false;
  const arrowName = `arrow${series.name}`;

  if (series.arrow) {
    series.arrow.destroy();
    // eslint-disable-next-line no-param-reassign
    series.arrow = undefined;
  }

  if (!series.options.appendArrow) {
    return;
  }

  const arrowLength = 15;
  const arrowWidth = 5;
  const { data } = series;
  const len = data.length;
  const lastSeg = data[len - 1];
  const path = [];
  let lastPoint = null;
  let nextLastPoint = null;
  const distance = 17;

  let pathTag;

  if (!lastSeg) return;

  lastPoint = data[len - 1];
  nextLastPoint = data[len - 2];

  if (!lastPoint || !nextLastPoint) {
    return;
  }

  let angle = Math.atan(
    (lastPoint.plotX - nextLastPoint.plotX) /
      (lastPoint.plotY - nextLastPoint.plotY)
  );

  if (angle < 0) angle = Math.PI + angle;

  path.push('M', lastPoint.plotX, lastPoint.plotY);

  if (
    lastPoint.plotX > nextLastPoint.plotX ||
    (lastPoint.plotX === nextLastPoint.plotX &&
      lastPoint.plotY > nextLastPoint.plotY)
  ) {
    if (arrowCheck === true) {
      pathTag = document.getElementById(arrowName);
      if (pathTag != null) {
        pathTag.remove(pathTag);
      }
    }

    path.push(
      'L',
      lastPoint.plotX + arrowWidth * Math.cos(angle),
      lastPoint.plotY - arrowWidth * Math.sin(angle)
    );
    path.push(
      lastPoint.plotX + arrowLength * Math.sin(angle),
      lastPoint.plotY + arrowLength * Math.cos(angle)
    );
    path.push(
      lastPoint.plotX - arrowWidth * Math.cos(angle),
      lastPoint.plotY + arrowWidth * Math.sin(angle),
      'Z'
    );
  } else {
    if (arrowCheck === true) {
      pathTag = document.getElementById(arrowName);
      if (pathTag != null) {
        pathTag.remove(pathTag);
      }
    }

    path.push(
      'L',
      lastPoint.plotX - arrowWidth * Math.cos(angle),
      lastPoint.plotY + arrowWidth * Math.sin(angle)
    );
    path.push(
      lastPoint.plotX - arrowLength * Math.sin(angle),
      lastPoint.plotY - arrowLength * Math.cos(angle)
    );
    path.push(
      lastPoint.plotX + arrowWidth * Math.cos(angle),
      lastPoint.plotY - arrowWidth * Math.sin(angle),
      'Z'
    );
  }

  const p = series.chart.renderer
    .path(path)
    .attr({
      fill: series.color,
      // id: arrowName,
      class: 'chart-arrow-path',
    })
    .add(series.group);

  // Allow arrow to be displayed out of chart
  // Currently disabled since it causes the associated line to be drawn out of chart
  // series.group.attr('clip-path', 'none');

  const distBtwPoints = Math.sqrt(
    // eslint-disable-next-line no-restricted-properties
    Math.pow(nextLastPoint.plotX - lastPoint.plotX, 2) +
      // eslint-disable-next-line no-restricted-properties
      Math.pow(nextLastPoint.plotY - lastPoint.plotY, 2)
  );
  const distanceRatio = distance / distBtwPoints;

  const x = distanceRatio * (nextLastPoint.plotX - lastPoint.plotX);
  const y = distanceRatio * (nextLastPoint.plotY - lastPoint.plotY);

  p.translate(x, y);

  // eslint-disable-next-line no-param-reassign
  series.arrow = p;

  arrowCheck = true;
};

const removeArrowsFormSeries = () => {
  const arrows = document.getElementsByClassName('chart-arrow-path');
  Array.from(arrows).forEach((arrow) => arrow.remove());
};

/**
 *
 * @param {Highcharts} Highcharts
 * @return {*}
 */
const appendArrowToSeries = (Highcharts) => {
  Highcharts.wrap(Highcharts.Series.prototype, 'render', function (proceed) {
    removeArrowsFormSeries();
    const obj = this;
    // eslint-disable-next-line prefer-rest-params
    proceed.apply(obj, Array.prototype.slice.call(arguments, 1));

    obj.chart.series.forEach((series) => appendArrowToSingleSeries(series));
  });
};

const selectRow = ({ displayData, row, selected }) => {
  return displayData.map((displayRow) => {
    const match = equalsAllKeys(row, displayRow.dimensionKey);
    if (match) {
      return {
        ...displayRow,
        data: displayRow.data.map((data) => ({
          ...data,
          selected,
        })),
      };
    }
    return displayRow;
  });
};

const setVisibleRow = ({ displayData, row, visible }) => {
  const selectedRow = displayData.find((displayRow) =>
    equalsAllKeys(displayRow.dimensionKey, row)
  );
  if (selectedRow) {
    selectedRow.visible = visible;
  }

  return displayData.map((displayRow) => {
    const match = equalsAllKeys(row, displayRow.dimensionKey);
    if (match) {
      return {
        ...displayRow,
        visible,
        appendArrow: displayRow.compared && visible,
        color: visible ? displayRow.defaultColor : 'rgba(255, 255, 255, 0)',
      };
    }
    return displayRow;
  });
};

export default {
  buildDisplayData,
  buildComparedDisplayData,
  buildCategoriesFromDimensionKey,
  buildChartOptions,
  appendArrowToSeries,
  removeArrowsFormSeries,
  selectRow,
  setVisibleRow,
};

export const testables = {
  buildDisplayData,
  buildComparedDisplayData,
  getBackgroundImg,
  formatBigNumber,
  roundNumber,
};
