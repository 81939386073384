import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import commonActions from 'store/common/actions';

const withResetState = (Component) => {
  const WrappedComponent = () => {
    const dispatch = useDispatch();
    useLayoutEffect(() => {
      return () => {
        dispatch(commonActions.clearCache());
      };
    }, [dispatch]);

    return <Component />;
  };
  return WrappedComponent;
};

export default withResetState;
