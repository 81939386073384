import Api, { SettingsApi } from 'services/api/Api';
import pages from 'services/routes/pages';
import {
  DASHBOARD,
  USER_ENDPOINT,
  USER_MANAGEMENT,
} from 'services/routes/constants';

const buildParams = (channel = '', startDate = '', endDate = '') => {
  const params = new URLSearchParams();
  if (channel !== '') {
    params.append('channel', channel);
  }
  if (startDate !== '') {
    params.append('start_date', startDate);
  }
  if (endDate !== '') {
    params.append('end_date', endDate);
  }

  return { params };
};

const DashboardApiFactory = () => {
  const url = pages[DASHBOARD].endpoint;
  const endpointUser = pages[USER_MANAGEMENT].endpoint;

  return {
    fetchMetadata: async () => Api.get(`${url}/metadata`),
    clonePeriod: async () => Api.post(`${url}/kpi-period/clone`),

    fetchKpiBorder: async () => Api.get(`${url}/kpi-border`),
    updateKpiBorder: async (data) => Api.put(`${url}/kpi-border`, data),

    fetchStatistic: async (channel, periodId, startDate, endDate) =>
      Api.get(
        `${url}/statistic/${periodId}`,
        buildParams(channel, startDate, endDate)
      ),
    fetchStatisticCompare: async (channel, periodId, startDate, endDate) =>
      Api.get(
        `${url}/statistic/${periodId}/compare`,
        buildParams(channel, startDate, endDate)
      ),

    createPickup: async (channel, data) =>
      Api.post(`${url}/pickup?channel=${channel}`, data),
    updatePickup: async (id, channel, data) =>
      Api.put(`${url}/pickup/${id}?channel=${channel}`, data),
    deletePickup: async (id) => Api.deleteRequest(`${url}/pickup/${id}`),

    fetchListChart: async (channel, periodId, startDate, endDate) => {
      return Api.get(
        `${url}/chart/${periodId}`,
        buildParams(channel, startDate, endDate)
      );
    },
    fetchItemChart: async (pickupId, channel, periodId, startDate, endDate) => {
      return Api.get(
        `${url}/chart/${periodId}/${pickupId}`,
        buildParams(channel, startDate, endDate)
      );
    },

    fetchListMediaReport: async (channel, periodId, startDate, endDate) => {
      return Api.get(
        `${url}/media-report/${periodId}`,
        buildParams(channel, startDate, endDate)
      );
    },
    fetchItemMediaReport: async (
      pickupId,
      channel,
      periodId,
      startDate,
      endDate
    ) => {
      return Api.get(
        `${url}/media-report/${periodId}/${pickupId}`,
        buildParams(channel, startDate, endDate)
      );
    },
    fetchUsers: async () => Api.get(endpointUser),
    getSettingMailInfo: async () =>
      SettingsApi.get(`${USER_ENDPOINT}/dashboard/kpi/setting-mail`),
    saveSettingMail: async (data) =>
      SettingsApi.post(`${USER_ENDPOINT}/dashboard/kpi/setting-mail`, data),
  };
};

const DashboardApi = DashboardApiFactory();
export default DashboardApi;
