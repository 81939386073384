import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RadioBtnElement = (props) => {
  const {
    name,
    label,
    checked,
    disabled,
    onChange,
    grop,
    value,
    listName,
  } = props;
  const id = Math.random();
  const classname = classNames({
    'radio-item text-truncate': true,
    active: value,
    'ebis--disabled': disabled,
  });
  return (
    <Form.Check
      id={`radio-${name}-${id}`}
      inline
      label={label}
      name={grop}
      type="radio"
      checked={checked}
      disabled={disabled}
      onChange={() => onChange(listName, value)}
      className={classname}
      style={{ width: '68px' }}
    />
  );
};

RadioBtnElement.defaultProps = {
  label: '',
  checked: false,
  disabled: false,
};

RadioBtnElement.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  grop: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
};

export default React.memo(RadioBtnElement);
