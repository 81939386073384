import React, { useContext, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { GridTableContext } from 'views/organism/GridTable/GridTableContext';
import { getWidthTable, getPositionShadow } from './GridTableService';
import { GRID_TABLE_ID } from './consts';

function GridTableSeparator() {
  const {
    dispatch,
    isEmptyData,
    isResizable,
    gridTableRef,
    settings: { tableInfo },
  } = useContext(GridTableContext);

  const shadow = getPositionShadow(
    tableInfo.freeze.widthScroller,
    tableInfo.main.widthScroller,
    tableInfo.main.isScrolling
  );

  const separatorClass = classNames({
    'grid-table__separator': true,
    [`grid-table__separator--${shadow}`]: !!shadow,
    [`grid-table__separator--hidden`]: !shadow && isEmptyData,
  });

  const resizeBarRef = useRef(null);
  const widthTableRef = useRef(null);

  useEffect(() => {
    if (!resizeBarRef?.current) return () => {};
    const resizeBarEl = resizeBarRef.current;
    const gridTableContainerEl = gridTableRef.current;
    const gridTableEl = gridTableContainerEl.closest(`#${GRID_TABLE_ID}`);
    const gridTableItemFreezeEl = gridTableContainerEl.querySelector(
      '.grid-table__wrapper--freeze'
    );

    let beingDragged = false;

    const startDrag = () => {
      if (!beingDragged) {
        beingDragged = true;
        gridTableEl.classList.add('grid-table--resizing');
      }
    };

    const stopDrag = () => {
      if (beingDragged) {
        beingDragged = false;
        gridTableEl.classList.remove('grid-table--resizing');
        dispatch({
          type: 'setInfoTable',
          payload: {
            isFreeze: true,
            info: { widthTable: widthTableRef.current },
          },
        });
        dispatch({
          type: 'setInfoTable',
          payload: {
            isFreeze: false,
            info: {
              widthTable:
                gridTableContainerEl.clientWidth - widthTableRef.current,
            },
          },
        });
      }
    };

    const onResizable = (e) => {
      requestAnimationFrame(() => {
        if (beingDragged) {
          const widthTable = getWidthTable(
            e.clientX - gridTableContainerEl.getBoundingClientRect().left,
            gridTableContainerEl.clientWidth
          );
          widthTableRef.current = widthTable;
          gridTableItemFreezeEl.style.width = `${widthTable}px`;
        }
      });
    };

    resizeBarEl.addEventListener('mousedown', startDrag);
    window.addEventListener('mouseup', stopDrag);
    resizeBarEl.addEventListener('mousemove', onResizable);

    return () => {
      resizeBarEl.removeEventListener('mousedown', startDrag);
      window.removeEventListener('mouseup', stopDrag);
      resizeBarEl.removeEventListener('mousemove', onResizable);
    };
  }, [dispatch, gridTableRef, resizeBarRef]);

  return (
    <div className={separatorClass}>
      {isResizable && (
        <div ref={resizeBarRef} className="grid-table__separator-resize" />
      )}
    </div>
  );
}

export default React.memo(GridTableSeparator);
