import * as FIELD from 'domain/fields';

const getDisplayKey = (field, variant = 'default') => {
  switch (field) {
    case FIELD.CATEGORY:
    case FIELD.CATEGORY_ID:
    case FIELD.CATEGORY_NAME:
      return `field.${variant}.category`;
    case FIELD.AD_GROUP1:
    case FIELD.AD_GROUP1_ID:
    case FIELD.AD_GROUP1_NAME:
      return `field.${variant}.ad_group1`;
    case FIELD.AD_GROUP2:
    case FIELD.AD_GROUP2_ID:
    case FIELD.AD_GROUP2_NAME:
      return `field.${variant}.ad_group2`;
    case FIELD.MEDIA_SIDE_CAMPAIGN:
    case FIELD.MEDIA_SIDE_CAMPAIGN_ID:
    case FIELD.MEDIA_SIDE_CAMPAIGN_NAME:
      return `field.${variant}.media_side_campaign`;
    case FIELD.MEDIA_SIDE_GROUP:
    case FIELD.MEDIA_SIDE_GROUP_ID:
    case FIELD.MEDIA_SIDE_GROUP_NAME:
      return `field.${variant}.media_side_group`;
    case FIELD.SYNC_CATEGORY:
    case FIELD.SYNC_CATEGORY_ID:
    case FIELD.SYNC_CATEGORY_NAME:
      return `field.${variant}.sync_category`;
    case FIELD.MEDIA_SIDE_AD_ID:
    case FIELD.MEDIA_SIDE_AD_NAME:
      return `field.${variant}.media_side_ad_name`;
    default:
      return `field.${variant}.${field}`;
  }
};

const getMemoCategoryDisplayKey = (category) => {
  return `period_analyze.memo.category.${category}`;
};

const getPhrases = (key) => {
  return `period_analyze.phrases.${key}`;
};

const getPeriodType = (key) => `period_analyze.period.${key}`;

export { getDisplayKey, getMemoCategoryDisplayKey, getPhrases, getPeriodType };
