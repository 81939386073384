// async actions
const REQUEST_START = 'ltv/settings/other/REQUEST_START';
const REQUEST_FAILED = 'ltv/settings/other/REQUEST_FAILED';
const FETCH = 'ltv/settings/other/FETCH';
const UPDATE = 'ltv/settings/other/UPDATE';

// sync actions
const FETCH_SUCCEEDED = 'ltv/settings/other/FETCH_SUCCEEDED';
const UPDATE_SUCCEEDED = 'ltv/settings/other/UPDATE_SUCCEEDED';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  FETCH,
  UPDATE,
  FETCH_SUCCEEDED,
  UPDATE_SUCCEEDED,
};
