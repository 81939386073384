import {
  CONVERSIONID,
  LINE_LOGIN_CHANNEL_ID,
  LINE_LOGIN_CHANNEL_SECRET,
  LINE_LOGIN_LIFF_ID,
  LINE_MESSAGING_API_CHANNEL_ID,
  LINE_MESSAGING_API_CHANNEL_SECRET,
} from 'domain/fields';
import { MAX_LENGTH_LINE_ADD_FRIEND_CHARACTER } from 'domain/line-add-friend-settings/constants';
import {
  maxLength,
  numberCheck,
  requiredValue,
} from 'services/validations/commonValidations';

const lineAddFriendSettingRules = {
  [CONVERSIONID]: [requiredValue()],
  [LINE_LOGIN_CHANNEL_ID]: [
    requiredValue(),
    maxLength(
      MAX_LENGTH_LINE_ADD_FRIEND_CHARACTER,
      '{length}文字以内で入力してください'
    ),
    numberCheck(
      LINE_LOGIN_CHANNEL_ID,
      '{label}は半角英数の整数で入力してください'
    ),
  ],
  [LINE_LOGIN_CHANNEL_SECRET]: [
    requiredValue(),
    maxLength(
      MAX_LENGTH_LINE_ADD_FRIEND_CHARACTER,
      '{length}文字以内で入力してください'
    ),
  ],
  [LINE_LOGIN_LIFF_ID]: [
    requiredValue(),
    maxLength(
      MAX_LENGTH_LINE_ADD_FRIEND_CHARACTER,
      '{length}文字以内で入力してください'
    ),
  ],
  [LINE_MESSAGING_API_CHANNEL_ID]: [
    requiredValue(),
    maxLength(
      MAX_LENGTH_LINE_ADD_FRIEND_CHARACTER,
      '{length}文字以内で入力してください'
    ),
    numberCheck(
      LINE_LOGIN_CHANNEL_ID,
      '{label}は半角英数の整数で入力してください'
    ),
  ],
  [LINE_MESSAGING_API_CHANNEL_SECRET]: [
    requiredValue(),
    maxLength(
      MAX_LENGTH_LINE_ADD_FRIEND_CHARACTER,
      '{length}文字以内で入力してください'
    ),
  ],
};

export default lineAddFriendSettingRules;
