import helper from 'store/helper';
import types from 'store/cv-flow/types';
import * as DisplayItems from 'domain/settings/display-items';
import { communicationStatus } from 'services/utils';

const { IDLE, LOADING, SUCCEEDED, FAILED } = communicationStatus;
const FIRST_PAGE = 1;

const initialState = {
  table: {
    data: {
      list: [],
      sum: {},
    },
    settings: {
      sort: {
        field: DisplayItems.CV,
        direction: DisplayItems.SORT_DIRECTION_DESC,
      },
      pagination: {
        currentPage: FIRST_PAGE,
      },
    },
    status: IDLE,
    error: null,
  },
  status: IDLE,
  error: null,
};

const requestStart = (state) => {
  return {
    ...state,
    status: LOADING,
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: FAILED,
    error,
  };
};

const fetchReportSucceeded = (state, action) => {
  const { data, metadata } = action.payload;
  return {
    ...state,
    table: {
      ...state.table,
      data: {
        ...state.table.data,
        list: data.detail,
        sum: data.sum,
      },
      settings: {
        ...state.table.settings,
        pagination: {
          ...state.table.settings.pagination,
          totalItems: metadata.count,
          itemsPerPage: metadata.limit,
        },
      },
      status: SUCCEEDED,
      error: null,
    },
    status: SUCCEEDED,
  };
};

const updatePage = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    table: {
      ...state.table,
      settings: {
        ...state.table.settings,
        pagination: {
          ...state.table.settings.pagination,
          currentPage: page,
        },
      },
      status: LOADING,
    },
  };
};

const updateSort = (state, action) => {
  const { field, direction } = action.payload;
  return {
    ...state,
    table: {
      ...state.table,
      settings: {
        ...state.table.settings,
        sort: {
          ...state.table.settings.sort,
          field,
          direction,
        },
        pagination: {
          ...state.table.settings.pagination,
          currentPage: FIRST_PAGE,
        },
      },
      status: LOADING,
    },
  };
};

const CVFlowReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_REPORT_SUCCEEDED]: fetchReportSucceeded,
  [types.UPDATE_PAGE]: updatePage,
  [types.UPDATE_SORT]: updateSort,
});

export default CVFlowReducer;
