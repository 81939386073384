import React, { useMemo, useState } from 'react';
import { func, bool, shape, arrayOf, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { Button, Form } from 'react-bootstrap';

import {
  APP_HELP_SETUP_CNAME_ABOUT_EMAIL_AUTHENTICATION,
  APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_2,
  APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_3,
  APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_4,
  CNAME_SETTING,
  STEP_SETTING_DNS_EMAIL,
} from 'domain/system-setting/consts';

import TableModal from 'views/organism/TableModal';
import FormInput from 'views/molecules/FormGroup/components/FormInput';

import {
  validateDnsSetting,
  getTitleDnsSetting,
} from 'services/system-setting/systemSettingServices';
import SettingModal from './Modal';

const COLUMN_DNS_SETTING = [
  { name: 'activation', title: '有効化' },
  {
    name: 'value',
    title: 'ドメイン',
  },
];

const RowItems = (props) => {
  const {
    rows,
    onChange,
  } = props;

  const changeSelectBoxValue = (name, value) => () => {
    onChange(name, value);
  };
  return (
    <>
      {rows.map((row, index) => {
        const keyIndex = index;
        return (
          <tr key={keyIndex}>
            {COLUMN_DNS_SETTING.map((column) => {
              if (column.name === 'activation') {
                return (
                  <td>
                    {row?.aspFlag ? (
                      <Form.Check
                        id={`chk-${row.field}`}
                        className="checkbox-item"
                        inline
                        label=""
                        type="checkbox"
                        checked={row.checked}
                        onChange={changeSelectBoxValue(
                          row.field,
                          row.checked ? '' : row.subdomain,
                        )}
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                );
              }
              return (
                <td>
                  <div className="dns-modal-row">
                    {row?.aspFlag ? (
                      <span>
                        {row.subdomain}.{row.domain}*
                      </span>
                    ) : (
                      <>
                        <FormInput
                          name={row.field}
                          value={row.value}
                          onChange={onChange}
                          style={{ width: 250 }}
                        />
                        <span>.{row.domain}</span>
                      </>
                    )}
                  </div>
                  {row.error && (
                    <div
                      className="dns-modal-row__error"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: row.error }}
                    />
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

RowItems.propTypes = {
  rows: arrayOf(shape({})).isRequired,
  onChange: func.isRequired,
};

function DnsSettingModal(props) {
  const {
    isShow,
    onHide,
    isLoading,
    isAddEmailMode,
    setIsAddEmailMode,
    data,
    tab,
    setCurrentStep,
    steps,
    domainFormValue,
    setDomainFormValue,
    setListItemUpdate,
    errorForm,
    setErrorForm,
    errorMainMessage,
    setErrorMainMessage,
  } = props;

  const handleChangeSubdomainValue = (domain, value) => {
    setDomainFormValue((prevValue) => ({
      ...prevValue,
      [domain]: value,
    }));
    setErrorForm((prevValue) => ({
      ...prevValue,
      [domain]: null,
    }));
  };

  const handleNext = () => {
    if (errorMainMessage) setErrorMainMessage('');
    const dataValidate = Object.entries(domainFormValue).filter(
      ([, value]) => !isEmpty(value.trim())
    );
    const {
      errorMainMessage: errorValidateMainMessage,
      errorForm: errorValidateForm,
    } = validateDnsSetting(dataValidate);
    if (!isEmpty(errorValidateMainMessage)) {
      setErrorMainMessage(errorValidateMainMessage);
    } else if (!isEmpty(errorValidateForm)) {
      setErrorForm(errorValidateForm);
    } else {
      const dataRequest = Object.entries(domainFormValue).filter(
        ([, value]) => !isEmpty(value.trim())
      ).map(([key, value]) => {
        return {
          "etld1": key,
          "domain_name": `${value}.${key}`,
          "redirect_flag": false,
          "send_mail_flag": false,
          "send_mail_users": [],
        };
      });
      setListItemUpdate(dataRequest);
      setCurrentStep(STEP_SETTING_DNS_EMAIL);
    }
  };

  const rows = useMemo(() => {
    return data.map((item) => ({
      field: item.etld1,
      aspFlag: item.asp_flag,
      subdomain: item.subdomain,
      checked:
        `${domainFormValue[item.etld1]}.${item.etld1}` ===
        `${item.subdomain}.${item.etld1}`,
      value: domainFormValue[item.etld1],
      error: errorForm?.[item.etld1] || '',
      domain: item.etld1,
    }));
  }, [data, domainFormValue, errorForm]);

  const handleSwitchToAddEmail = () => {
    if (errorMainMessage) {
      setErrorMainMessage('');
    }
    if (!isEmpty(errorForm)) {
      setErrorForm({});
    }
    setIsAddEmailMode(true);
  };

  const isEmptyDomainFormValue = useMemo(() => {
    return Object.values(domainFormValue).every(value => isEmpty(value.trim()));
  }, [domainFormValue]);

  return (
    <SettingModal
      loading={isLoading}
      confirmText={isAddEmailMode ? '注意内容は確認済みです' : ''}
      submitButtonText="次へ"
      isShowCheckbox={isAddEmailMode}
      isShow={isShow}
      onSubmit={handleNext}
      onHide={onHide}
      title={getTitleDnsSetting(tab, isAddEmailMode, false)}
      steps={steps}
      dialogClassName="dns-setting-modal"
      disabledSubmit={isEmptyDomainFormValue}
      elementBelowLoading={
        <>
          証明書審査用レコード
          <br /> 取得中…
        </>
      }
    >
      {!isAddEmailMode ? (
        <div className="dns-description">
          <div>
            1ドメインにつき「NS」「CNAME」両方で設定されている場合、「CNAME」の設定は２週間で自動的に削除されます。
          </div>

          <div className="dns-note">
            ※[*]がついているドメインは、サービス提供元でDNS設定済のため、当サービス管理画面の設定のみで完了します。「有効化」のみにチェックしてご登録ください
            <br />
            ※400件までの計測対象サイトに対してDNS設定が可能です。一度に登録ができるのは20件までです
            <br />
            ※ドメインは64文字以内
          </div>
        </div>
      ) : (
        <div className="dns-description">
          <div className="dns-note">
            ※[*]がついているドメインは、サービス提供元でDNS設定済のため、当サービス管理画面の設定のみで完了します。「有効化」のみにチェックしてご登録ください
          </div>
        </div>
      )}
      {tab === CNAME_SETTING && !isAddEmailMode && (
        <div className="dns-cname__note">
          証明書審査用ホスト名の先頭に「_」が登録できない場合は、
          <Button variant="link" size="sm" onClick={handleSwitchToAddEmail}>
            こちら
          </Button>
          からメール審査の手続きを行ってください。
        </div>
      )}
      <div className={isAddEmailMode ? 'dns-cname__contain-table' : ''}>
        {errorMainMessage && (
          <div className="dns-error__main-message">{errorMainMessage}</div>
        )}
        <TableModal
          header={
            <tr>
              {COLUMN_DNS_SETTING.map((column) => (
                <th>{column.title}</th>
              ))}
            </tr>
          }
          body={
            <RowItems
              columns={COLUMN_DNS_SETTING}
              rows={rows}
              onChange={handleChangeSubdomainValue}
            />
          }
          variant={isAddEmailMode ? 'mt-20' : ''}
          maxContent={258}
          alignScroll={-20}
        />
      </div>
      {isAddEmailMode && (
        <div className="dns-email__note-container">
          <div className="dns-email__note-title">
            メール審査にあたり、下記注意事項を必ずご確認ください。
          </div>
          <div className="dns-email__note-content">
            ・
            <a
              href={APP_HELP_SETUP_CNAME_ABOUT_EMAIL_AUTHENTICATION}
              target="__blank"
            >
              概要
            </a>
            を必ず事前にご確認ください。
            <br />
            ・メール送付先はドメイン管理用メールアドレスとなり任意の変更はできません。「OK」ボタンをクリックするとAWSが審査メールを送信するため、必ず、ドメイン管理者様の許可を得た後にご登録ください。詳しくは
            <a
              href={APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_2}
              target="__blank"
            >
              こちら
            </a>
            <br />
            ・本登録後、メール審査手続きのほか、DNSに計測用CNAMEの登録が必要です。お手続きには72時間の有効期限があるため、登録後はお早めにご対応ください。詳しくは
            <a
              href={APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_3}
              target="__blank"
            >
              こちら
            </a>
            <br />
            ・証明書の有効期限は1年間となり、更新手続きが必要なケースがあります。更新手続きが必要な場合、AWSから更新手続きのメールが送付されますのでお手続きを行ってください。詳しくは
            <a
              href={APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_4}
              target="__blank"
            >
              こちら
            </a>
          </div>
        </div>
      )}
    </SettingModal>
  );
}

DnsSettingModal.propTypes = {
  isShow: bool.isRequired,
  onHide: func.isRequired,
  isLoading: bool.isRequired,
  setIsAddEmailMode: func.isRequired,
  isAddEmailMode: bool.isRequired,
  tab: string.isRequired,
  setCurrentStep: func.isRequired,
  steps: arrayOf({}),
  data: shape({}).isRequired,
  domainFormValue: arrayOf({}),
  setDomainFormValue: arrayOf({}),
  setListItemUpdate: arrayOf({}),
  errorForm: arrayOf({}),
  setErrorForm: arrayOf({}),
  errorMainMessage: string,
  setErrorMainMessage: func.isRequired,
};

DnsSettingModal.defaultProps = {
  steps: [],
  domainFormValue: [], // original name is "valueForm"
  setDomainFormValue: [], // original name is "setValueForm"
  setListItemUpdate: [],
  errorForm: [],
  setErrorForm: [],
  errorMainMessage: '',
};

export default DnsSettingModal;
