const FETCH = 'ad-management/FETCH';
const SET_DATA_TABLE = 'ad-management/SET_DATA_TABLE';
const SET_ERROR_TABLE = 'ad-management/SET_ERROR_TABLE';
const SET_STATUS_TABLE = 'ad-management/SET_STATUS_TABLE';

const CREATE = 'ad-management/CREATE';
const UPDATE = 'ad-management/UPDATE';
const DELETE = 'ad-management/DELETE';
const DROP = 'ad-management/DROP';
const RESTORE = 'ad-management/RESTORE';

const GET = 'ad-management/GET';
const SET_DETAIL = 'ad-management/SET_DETAIL';
const SET_ERROR_DETAIL = 'ad-management/SET_ERROR_DETAIL';
const SET_STATUS_DETAIL = 'ad-management/SET_STATUS_DETAIL';

const UPDATE_SORT = 'ad-management/UPDATE_SORT';
const UPDATE_PAGE = 'ad-management/UPDATE_PAGE';

const UPLOAD_CSV = 'ad-management/UPLOAD_CSV';
const UPLOAD_CSV_DELETE = 'ad-management/UPLOAD_CSV_DELETE';
const CSV_VALIDATION_SUCCEED = 'ad-management/CSV_VALIDATION_SUCCEED';
const CSV_VALIDATION_ERRORS = 'ad-management/CSV_VALIDATION_ERRORS';
const CLEAR_CSV_UPLOAD_DATA = 'ad-management/CLEAR_CSV_UPLOAD_DATA';
const SET_CSV_FILENAME = 'ad-management/SET_CSV_FILENAME';
const SUBMIT_CSV = 'ad-management/SUBMIT_CSV';
const SUBMIT_CSV_DELETE = 'ad-management/SUBMIT_CSV_DELETE';
const CSV_SUBMIT_SUCCEED = 'ad-management/CSV_SUBMIT_SUCCEED';

const SET_STATUS_CSV_UPLOAD = 'ad-management/SET_STATUS_CSV_UPLOAD';

const DELETE_BY_FILTERS = 'ad-management/DELETE_BY_FILTERS';
const DROP_BY_FILTERS = 'ad-management/DROP_BY_FILTERS';
const RESTORE_BY_FILTERS = 'ad-management/RESTORE_BY_FILTERS';
const CHANGE_TAB = 'ad-management/CHANGE_TAB';

const DOWNLOAD_CSV = 'ad-management/DOWNLOAD_CSV';

const FETCH_MASTERDATA_BY_AGENCY = 'ad-management/FETCH_MASTERDATA_BY_AGENCY';
const SEARCH_MEDIA = 'ad-management/SEARCH_MEDIA';
const SEARCH_AD_GROUP1 = 'ad-management/SEARCH_AD_GROUP1';
const SEARCH_AD_GROUP2 = 'ad-management/SEARCH_AD_GROUP2';

const SEARCH_MEDIA_ACCOUNT = 'ad-management/SEARCH_MEDIA_ACCOUNT';
const SEARCH_MEDIA_SIDE_CAMPAIGN = 'ad-management/SEARCH_MEDIA_SIDE_CAMPAIGN';
const SEARCH_MEDIA_SIDE_GROUP = 'ad-management/SEARCH_MEDIA_SIDE_GROUP';

const CLEAN_STATES = 'ad-management/CLEAN_STATES';

const AD_CHANGE = 'ad-management/AD_CHANGE';
const AD_CHANGE_BY_FILTERS = 'ad-management/AD_CHANGE_BY_FILTERS';
const CONTRACTED_LPO_BEFORE = 'ad-management/CONTRACTED_LPO_BEFORE';
const SET_LPO_DATA_COUNT = 'ad-management/SET_LPO_DATA_COUNT';

export default {
  FETCH,
  SET_DATA_TABLE,
  SET_ERROR_TABLE,
  CREATE,
  UPDATE,
  DELETE,
  DROP,
  RESTORE,
  GET,
  SET_DETAIL,
  SET_ERROR_DETAIL,

  UPDATE_SORT,
  UPDATE_PAGE,

  UPLOAD_CSV,
  UPLOAD_CSV_DELETE,
  CSV_VALIDATION_SUCCEED,
  CSV_VALIDATION_ERRORS,
  CLEAR_CSV_UPLOAD_DATA,
  SET_CSV_FILENAME,
  SUBMIT_CSV,
  SUBMIT_CSV_DELETE,
  CSV_SUBMIT_SUCCEED,

  CHANGE_TAB,

  SET_STATUS_TABLE,
  SET_STATUS_CSV_UPLOAD,
  SET_STATUS_DETAIL,

  DELETE_BY_FILTERS,
  DOWNLOAD_CSV,
  DROP_BY_FILTERS,
  RESTORE_BY_FILTERS,

  FETCH_MASTERDATA_BY_AGENCY,
  SEARCH_MEDIA,
  SEARCH_AD_GROUP1,
  SEARCH_AD_GROUP2,
  SEARCH_MEDIA_ACCOUNT,
  SEARCH_MEDIA_SIDE_CAMPAIGN,
  SEARCH_MEDIA_SIDE_GROUP,
  CLEAN_STATES,
  AD_CHANGE,
  AD_CHANGE_BY_FILTERS,
  CONTRACTED_LPO_BEFORE,
  SET_LPO_DATA_COUNT,
};
