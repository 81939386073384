import React from 'react'
import { Button, Modal} from 'react-bootstrap';
import {
    object,
    func,
    oneOfType,
    bool,
  } from 'prop-types';
import {
COOKIE_PROGRAM_NAME,
} from 'domain/system-setting/consts';
import ListItems  from 'views/pages/system-setting/components/ListItems';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import Spinner from 'views/atoms/loader/Spinner';

function cookieProgramDeleteModal(props){

    const {
        getDeleteList,
        newListData,
        selectedRows,
        checked,
        checkedClick,
        onCancelDelete,
        onSubmitDelete,
        isLoading,
        } = props;


    return (
        <Modal show centered size="w800" dialogClassName="modal-cookie-program-setting" contentClassName="modal-content--settings">
          <Modal.Header className="justify-content-start">
            <Modal.Title style={{ marginRight: 10 }}>
              {COOKIE_PROGRAM_NAME}を削除しますか？
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <div className='deleteModalSpinner'>
                <Spinner/>
              </div>
            ) : (
              <>
              <p className='note'>
                下記の設定が無効になります。
              </p>
              <ListItems items={getDeleteList(newListData,selectedRows)} type={COOKIE_PROGRAM_NAME} />
              </>
            )}
          </Modal.Body>
          <Modal.Footer className='modal-footer__has-ebis-checkbox'>
            <>
              <div>
                <EbisCheckbox
                  name="agree-for-delete"
                  text='設定を削除しても問題ありません。'
                  checked={checked}
                  changeSelectBoxValue={() => checkedClick(!checked)}
                  disabled={isLoading}
                />
              </div>
              <div>
                <Button
                    variant="link"
                    size="sm"
                    disabled={isLoading}
                    onClick={onCancelDelete}
                  >
                    キャンセル
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  disabled={!checked || isLoading}
                  onClick={onSubmitDelete}
                >
                  削除
                </Button>
              </div>
            </>
          </Modal.Footer>
        </Modal>
    )
}

cookieProgramDeleteModal.propTypes = {
    getDeleteList:func,
    newListData:oneOfType([object]),
    selectedRows:oneOfType([object]),
    checked:bool,
    checkedClick:func,
    onCancelDelete:func,
    onSubmitDelete:func,
}

cookieProgramDeleteModal.defaultProps = {
    getDeleteList:() => {},
    newListData:{},
    selectedRows:[],
    checked:false,
    checkedClick:() => {},
    onCancelDelete:() => {},
    onSubmitDelete:() => {},
};

export default cookieProgramDeleteModal;