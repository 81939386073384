import { createSelector } from 'reselect';
import { createTableHeaders } from 'services/utils';
import { createFieldsFromConversions, crossDeviceItems } from 'domain/utils';
import { CV_METRICS } from 'domain/consts';
import * as DisplayItems from 'domain/settings/display-items';
import {
  AD_NAME,
  CATEGORY,
  MEDIA_SIDE_AD_NAME,
  SYNC_CATEGORY,
  CV_TOTAL,
  CNT_FIRST,
  RCV,
  RAMOUNT,
} from 'domain/fields';
import displayItemsSelectors from 'store/display-items/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import settingsSelectors from 'store/settings/selectors';
import { TAB_AD } from 'store/settings/constant';
import filterSelectors from 'store/filters/selectors';

const listColumnDisabledTooltip = [
  CATEGORY,
  AD_NAME,
  SYNC_CATEGORY,
  MEDIA_SIDE_AD_NAME,
];

const getSort = (state) => state.cache.detailAnalysis.table.settings.sort;
const getPagination = (state) =>
  state.cache.detailAnalysis.table.settings.pagination;

const getListReport = (state) => state.cache.detailAnalysis.table.data.list;
const getSumReport = (state) => state.cache.detailAnalysis.table.data.sum;

const getStatus = (state) => state.cache.detailAnalysis.status;
const getStatusTable = (state) => state.cache.detailAnalysis.table.status;

const getCvMetrics = (state) => {
  const conversions = masterDataSelectors.conversionsForDisplaySelector(state);
  const metricSelected = displayItemsSelectors.getMetricsSelector(state);

  return createFieldsFromConversions(
    conversions,
    metricSelected.filter((metric) => CV_METRICS.includes(metric))
  );
};

const dimensionsSelector = createSelector(
  [
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (dimensions, displayItems) => {
    const items = displayItems
      .filter((item) => item.displayFreeze && dimensions.includes(item.key))
      .map((item) => item.key);

    return items;
  }
);

const metricsSelector = createSelector(
  [
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (metrics, displayItems) => {
    return displayItems
      .filter((item) => !item.displayFreeze && metrics.includes(item.key))
      .map((item) => item.key);
  }
);

const sortSelector = createSelector(
  [dimensionsSelector, metricsSelector, getCvMetrics, getSort],
  (dimensions, metrics, cvMetrics, sort) => {
    const newMetrics = [...metrics];
    cvMetrics
      .filter((cntDirect) => cntDirect.children.length > 0)
      .forEach((cntDirect) =>
        cntDirect.children.map((conversion) => newMetrics.push(conversion.name))
      );

    if (sort && dimensions.concat(newMetrics).includes(sort.field)) {
      return { sort: (sort.direction === 'asc' ? '' : '-') + sort.field };
    }

    return {};
  }
);

// Create header data
export const dimensionsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const dimensions = displayItems
    .filter((item) => item.displayFreeze)
    .map((item) => {
      return {
        text: item.title,
        name: item.key,
      };
    });

  return dimensions;
};

export const metricsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const cvMetrics = getCvMetrics(state);

  const metrics = [];
  displayItems
    .filter((item) => !item.displayFreeze)
    .map((item) => {
      if (CV_METRICS.includes(item.key)) {
        metrics.push(...cvMetrics);
      } else {
        metrics.push({
          text: item.title,
          name: item.key,
        });
      }
      return metrics;
    });

  return metrics;
};

export const groupsConfig = () => [
  {
    name: 'indirect',
    text: '間接効果',
    children: [
      DisplayItems.CNT_INDIRECT2,
      DisplayItems.CNT_INDIRECT3,
      DisplayItems.CNT_INDIRECT4,
      DisplayItems.CNT_INDIRECT5,
      DisplayItems.CNT_INDIRECT_OTHER,
      DisplayItems.INDIRECT_TOTAL,
    ],
  },
  {
    name: 'rate',
    text: '直間比率',
    children: [DisplayItems.DIRECT_RATE, DisplayItems.INDIRECT_RATE],
  },
];

export const crossGroupsConfig = () => [
  {
    name: `${CV_TOTAL}_cross`,
    text: 'CV（合計）',
    children: [
      DisplayItems.CV_TOTAL_CROSS_LINKED_BEFORE,
      DisplayItems.CV_TOTAL_CROSS_LINKED_AFTER,
      DisplayItems.CV_TOTAL_CROSS_DIFFERENCE,
      DisplayItems.CV_TOTAL_CROSS_RATE,
    ],
  },
  {
    name: `${CNT_FIRST}_cross`,
    text: '初回接触',
    children: [
      DisplayItems.CNT_FIRST_CROSS_LINKED_BEFORE,
      DisplayItems.CNT_FIRST_CROSS_LINKED_AFTER,
      DisplayItems.CNT_FIRST_CROSS_DIFFERENCE,
      DisplayItems.CNT_FIRST_CROSS_RATE,
    ],
  },
  {
    name: `${RCV}_cross`,
    text: '再配分CV',
    children: [
      DisplayItems.RCV_CROSS_LINKED_BEFORE,
      DisplayItems.RCV_CROSS_LINKED_AFTER,
      DisplayItems.RCV_CROSS_DIFFERENCE,
      DisplayItems.RCV_CROSS_RATE,
    ],
  },
  {
    name: `${RAMOUNT}_cross`,
    text: '再配分売上',
    children: [
      DisplayItems.RAMOUNT_CROSS_LINKED_BEFORE,
      DisplayItems.RAMOUNT_CROSS_LINKED_AFTER,
      DisplayItems.RAMOUNT_CROSS_DIFFERENCE,
      DisplayItems.RAMOUNT_CROSS_RATE,
    ],
  },
];

const getConfig = (state) => {
  return {
    dimensions: dimensionsConfig(state),
    metrics: metricsConfig(state),
    groups: groupsConfig(),
  };
};

const addCrossDeviceMetrics = (selectedMetrics, itemsConfig) => {
  const newMetrics = [];
  const itemsMetrics = [];
  itemsConfig.metrics.forEach((metric) => {
    switch (metric.name) {
      case DisplayItems.CNT_CV_TOTAL:
        if (selectedMetrics.includes(metric.name)) {
          const crossMetrics = crossDeviceItems.cvTotalCross.map(
            (cvTotalItems) => {
              return cvTotalItems.name;
            }
          );
          newMetrics.push(...crossMetrics);
        }
        return itemsMetrics.push(...crossDeviceItems.cvTotalCross);
      case DisplayItems.CNT_FIRST:
        if (selectedMetrics.includes(metric.name)) {
          const crossMetrics = crossDeviceItems.cntFirstCross.map(
            (cntFirstItems) => {
              return cntFirstItems.name;
            }
          );
          newMetrics.push(...crossMetrics);
        }
        return itemsMetrics.push(...crossDeviceItems.cntFirstCross);
      case DisplayItems.RCV:
        if (selectedMetrics.includes(metric.name)) {
          const crossMetrics = crossDeviceItems.rcvCross.map((rcvItems) => {
            return rcvItems.name;
          });
          newMetrics.push(...crossMetrics);
        }
        return itemsMetrics.push(...crossDeviceItems.rcvCross);
      case DisplayItems.RAMOUNT:
        if (selectedMetrics.includes(metric.name)) {
          const crossMetrics = crossDeviceItems.ramountCross.map(
            (ramountItems) => {
              return ramountItems.name;
            }
          );
          newMetrics.push(...crossMetrics);
        }
        return itemsMetrics.push(...crossDeviceItems.ramountCross);
      case DisplayItems.CROSSDEVICE_DIFF_COMPARISON:
        return itemsMetrics.push(metric);
      default:
        if (selectedMetrics.includes(metric.name)) {
          newMetrics.push(metric.name);
        }
        return itemsMetrics.push(metric);
    }
  });
  return { newMetrics, itemsMetrics };
};

const headerSelector = createSelector(
  [
    settingsSelectors.getTab,
    dimensionsSelector,
    metricsSelector,
    getCvMetrics,
    getSort,
    getConfig,
    filterSelectors.getSettings,
  ],
  (channel, dimensions, metrics, cvMetrics, sort, itemsConfig, filters) => {
    let newMetrics = [];
    const newItemsConfig = itemsConfig;
    const crossGrouops = crossGroupsConfig();
    // create corss device metrics
    if (
      metrics.includes(DisplayItems.CROSSDEVICE_DIFF_COMPARISON) &&
      filters.cross_device
    ) {
      // Create cross-device tied headers
      newItemsConfig.groups.push(...crossGrouops);
      const cross = addCrossDeviceMetrics(metrics, itemsConfig);
      newMetrics = cross.newMetrics;
      newItemsConfig.metrics = cross.itemsMetrics;
    } else {
      // delete crossdevice_diff_comparison header
      newMetrics = metrics.filter((metric) => {
        return metric !== DisplayItems.CROSSDEVICE_DIFF_COMPARISON;
      });
    }

    // create table header data
    const headerData = createTableHeaders({
      channel,
      dimensions,
      metrics: [...newMetrics, ...cvMetrics.map((cntDirect) => cntDirect.name)],
      sorts: [sort],
      itemsConfig,
    });
    // Remove tooltips in Tab AD's cateogry & ad_name columns since it confuse users
    const headers =
      channel === TAB_AD
        ? headerData.map((item) =>
          listColumnDisabledTooltip.includes(item.name)
            ? { ...item, tooltip: false }
            : item
        )
        : headerData;

    return headers;
  }
);

export default {
  getListReport,
  getSumReport,
  getPagination,
  getStatus,
  getStatusTable,
  dimensionsSelector,
  metricsSelector,
  headerSelector,
  sortSelector,
};
