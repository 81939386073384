export default {
  GET_DATA: 'log-page-analyze/GET_DATA',

  SET_DATA: 'log-page-analyze/SET_DATA',
  SET_STATUS: 'log-page-analyze/SET_STATUS',
  SET_ERRORS: 'log-page-analyze/SET_ERRORS',

  CHANGE_PAGE: 'log-page-analyze/CHANGE_PAGE',
  CHANGE_SORT: 'log-page-analyze/CHANGE_SORT',
};
