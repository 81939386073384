import { call, put, throttle, takeLatest, select } from 'redux-saga/effects';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';
import LtvProductApi from 'services/api/LtvProductApi';
import handleError from 'services/error/handleScopeError';
import ltvProductSelectors from 'store/ltv/settings/product/selectors';
import filterSelectors from 'store/filters/selectors';
import { getSortString, getOffset } from 'domain/utils';
import Services from 'domain/ltv/settings/product/Services';
import loggerConstants from 'store/logger/constant';
import uploadActions from 'store/upload/actions';
import { LTV_SETTINGS_PRODUCT } from 'services/routes/constants';
import types from './types';
import actions from './actions';

function* fetchList() {
  try {
    const pagination = yield select(ltvProductSelectors.getPagination);
    const sort = yield select(ltvProductSelectors.getSort);
    yield put(actions.fetchListStart());
    const response = yield call(LtvProductApi.fetchAll, {
      sort: getSortString(sort),
      limit: TABLE_ROWS_PER_PAGE,
      filters: yield select(filterSelectors.getForApi),
      offset: getOffset(pagination),
    });
    yield put(actions.fetchedList({ data: response.data.data }));
    yield put(actions.updateCurrentItemNumber(response.data.metadata.count));
  } catch (error) {
    yield put(actions.requestFailed(handleError(error.response)));
  }
}

function* search(action) {
  try {
    const response = yield call(LtvProductApi.fetchAll, {
      limit: 50,
      sort: 'product_name',
      filters: [
        { field: 'product_name', operator: 1, value: [action.payload] },
      ],
      offset: 0,
    });
    yield put(actions.fetchedList({ data: response.data.data }));
  } catch (error) {
    yield put(actions.requestFailed(handleError(error.response)));
  }
}

function* create(action) {
  try {
    yield put(actions.requestStart());
    const response = yield call(LtvProductApi.create, action.payload);
    if (response.status === 200) {
      yield put(actions.created({ data: response.data.data }));
      yield put(actions.fetchListStart());
      yield put(actions.fetchList());
    }
  } catch (error) {
    if (error.response && Services.isApiValidationError(error.response)) {
      yield put(
        actions.submitFailed({
          error: error.response,
          scope: loggerConstants.SCOPE_DONT_SHOW,
        })
      );
    } else {
      yield put(actions.requestFailed(handleError(error.response, [400, 403])));
    }
  }
}

function* update(action) {
  try {
    yield put(actions.requestStart());
    // eslint-disable-next-line no-unused-vars
    const response = yield call(LtvProductApi.update, action.payload);
    yield put(actions.fetchListStart());
    yield put(actions.fetchList());
    yield put(actions.updated(action.payload));
  } catch (error) {
    if (error.response && Services.isApiValidationError(error.response)) {
      yield put(
        actions.submitFailed({
          error: error.response,
          scope: loggerConstants.SCOPE_DONT_SHOW,
        })
      );
    } else {
      yield put(actions.requestFailed(handleError(error.response, [400, 403])));
    }
  }
}

function* remove(action) {
  try {
    const ids = action.payload;
    yield put(actions.requestStart());
    const response = yield call(LtvProductApi.remove, ids);
    if (response.status === 200) {
      yield put(actions.removed({ ids }));
      yield put(actions.fetchListStart());
      yield put(actions.fetchList());
    }
  } catch (error) {
    yield put(actions.requestFailed(handleError(error.response, [400, 403])));
  }
}

function* upload(action) {
  try {
    const { file } = action.payload;
    yield put(uploadActions.requestStart(LTV_SETTINGS_PRODUCT));

    const {
      data: {
        data: { location: uploadPath },
      },
    } = yield call(LtvProductApi.getPresignedUrl);

    yield call(LtvProductApi.upload, uploadPath, file);
    const url = new URL(uploadPath);
    // Get last element after `/` character
    const filename = url.pathname.split('/').slice(-1).pop();

    // validate & import data
    yield call(LtvProductApi.doImport, filename);
    yield put(uploadActions.uploadSucceeded());

    // refresh data list
    yield put(actions.fetchListStart());
    yield put(actions.updatePage(1));
    yield put(actions.fetchList());
  } catch (error) {
    const { response = { status: 500 } } = error;
    const status = response?.status ?? 500;
    if (status === 400) {
      // validate error
      yield put(uploadActions.requestFailed(response.data.errors));
    } else {
      // api or app error
      yield put(uploadActions.requestError(handleError(response)));
    }
  }
}

export default function* watchView() {
  yield takeLatest(types.FETCH_LIST, fetchList);
  yield takeLatest(types.SEARCH, search);
  yield throttle(1000, types.CREATE, create);
  yield throttle(1000, types.UPDATE, update);
  yield throttle(1000, types.DELETE, remove);
  yield throttle(3000, types.UPLOAD, upload);
}
