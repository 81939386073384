import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './CellContentWrapper.scss';
import isEmpty from 'lodash/isEmpty';

const tooltipElementId = 'cell-tooltip';
const CELL_PADDING = 30;

const getDivElement = (ref) => {
  const el =
    ref.current.tagName === 'DIV' ? ref.current : ref.current.parentNode;
  return el;
};

const getCellValue = (ref) => {
  const divEl = getDivElement(ref);
  return divEl.firstChild.innerHTML;
};

const needShowTooltip = (ref) => {
  if (isEmpty(ref?.current)) return false;
  const divEl = getDivElement(ref);
  if (!divEl) {
    return false;
  }
  const contentWidth = divEl.firstChild.offsetWidth;
  if (divEl.offsetWidth >= contentWidth + CELL_PADDING) {
    return false;
  }
  return true;
};
const CellContentWrapper = React.forwardRef(({ children }, ref) => {
  useEffect(() => {
    if (isEmpty(ref?.current)) return () => {};
    let tooltip = document.getElementById(tooltipElementId);
    if (!tooltip) {
      tooltip = document.createElement('DIV');
      tooltip.setAttribute('id', tooltipElementId);
      document.body.appendChild(tooltip);
    }

    const handleMouseOver = (event) => {
      if (!needShowTooltip(ref)) return () => {};

      tooltip.innerHTML = getCellValue(ref);

      const positionLeft =
        window.innerWidth - event.clientX - 50 > tooltip.clientWidth
          ? event.clientX + 2
          : event.clientX - tooltip.clientWidth + 10;

      Object.assign(tooltip.style, {
        position: 'fixed',
        top: `${event.clientY + 15}px`,
        left: `${positionLeft}px`,
        display: 'block',
      });
      return true;
    };

    const handelMouseOut = () => {
      if (tooltip) {
        tooltip.style.display = 'none';
      }
      return false;
    };

    // Event
    const el = getDivElement(ref);
    if (!el) return () => {};

    el.addEventListener('mouseover', handleMouseOver);
    el.addEventListener('mouseout', handelMouseOut);
    return () => {
      el.removeEventListener('mouseover', handleMouseOver);
      el.removeEventListener('mouseout', handelMouseOut);
    };
  }, [ref, children]);

  return <>{children}</>;
});

CellContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

CellContentWrapper.defaultProps = {};

export default CellContentWrapper;
