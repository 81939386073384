import {
  call,
  put,
  throttle,
  takeLatest,
  fork,
  select,
} from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import {
  TABLE_ROWS_PER_PAGE,
  LIST_ERROR_TO_FETCH_LIST,
} from 'domain/user-management/consts';
import UserManagementApi from 'services/api/UserManagementApi';
import handleError from 'services/error/handleScopeError';
import types from './types';
import actions from './actions';
import selectors from './selectors';

function* fetchList() {
  try {
    const currentPage = yield select(selectors.getCurrentPage);
    const offset = (currentPage - 1) * TABLE_ROWS_PER_PAGE;
    yield put(actions.requestStart(true));
    const {
      data: {
        data: { detail = [], metadata = {} },
      },
    } = yield call(UserManagementApi.fetchAll, { offset });
    yield put(
      actions.fetchedList({
        byIds: detail,
        metadata,
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

function* fetchListByCodeErrors() {
  const errors = yield select(selectors.getApiError);
  if (!isEmpty(errors)) {
    const listCode = errors.map((err) => err.code);
    const isFetchList = listCode.some((errorCode) =>
      LIST_ERROR_TO_FETCH_LIST.includes(errorCode)
    );
    if (isFetchList) {
      yield fork(fetchList);
    }
  }
}

function* create(action) {
  const { callback } = action;
  try {
    yield put(actions.requestStart());
    yield call(UserManagementApi.create, action.payload);
    yield fork(fetchList);
    callback();
  } catch (error) {
    yield put(
      actions.requestFailed({
        error: handleError(error.response, [400, 403]),
      })
    );
  }
}

function* update(action) {
  const { callback } = action;
  try {
    yield put(actions.requestStart());
    yield call(UserManagementApi.update, action.payload);
    yield fork(fetchList);
    callback();
  } catch (error) {
    yield put(
      actions.requestFailed({
        error: handleError(error.response, [400, 403]),
      })
    );
  }
}

function* remove(action) {
  try {
    const {
      pagination: { currentPage, totalItems },
    } = yield select(selectors.getSettings);
    const page = totalItems === TABLE_ROWS_PER_PAGE + 1 ? 1 : currentPage;
    const id = action.payload;
    yield put(actions.requestStart(true));
    yield call(UserManagementApi.delete, id);
    yield put(actions.fetchList(page));
  } catch (error) {
    yield put(
      actions.requestFailed({
        error: handleError(error.response, [400, 403]),
      })
    );
  }
}

export default function* watchView() {
  yield takeLatest(types.FETCH_LIST, fetchList);
  yield throttle(1000, types.CREATE, create);
  yield throttle(1000, types.UPDATE, update);
  yield throttle(1000, types.DELETE, remove);
  yield takeLatest(types.FETCH_LIST_BY_CODE, fetchListByCodeErrors);
}
