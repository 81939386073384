import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LTV_SETTINGS_OTHER } from 'services/routes/constants';

const baseURL = process.env.REACT_APP_LTV_API_HOST;
const url = pages[LTV_SETTINGS_OTHER].endpoint;

const getCurrentSetting = async () => Api.get(url, { baseURL });
const updateSetting = async ({ data }) => Api.put(`${url}`, data, { baseURL });

export default {
  fetch: getCurrentSetting,
  update: updateSetting,
}
