import { createSelector } from 'reselect';
import Services from 'domain/ltv/settings/ad/Services';
import { getSortString } from 'domain/utils';

const getList = (state) => {
  const { list } = state.cache.ltvSettingsAd;

  return Services.formatData(list);
};

const getDetail = (state) => {
  const { detail } = state.cache.ltvSettingsAd;
  return detail;
};

const getListDetail = (state) => {
  const { list } = state.cache.ltvSettingsAd;

  return list;
};

const getStatus = (state) => {
  const { status } = state.cache.ltvSettingsAd;
  return status;
};
const getAdStatus = (state) => {
  const { adStatus } = state.cache.ltvSettingsAd;
  return adStatus;
};

const getFetchStatus = (state) => {
  const { fetchStatus } = state.cache.ltvSettingsAd;
  return fetchStatus;
};

const getSelectedItem = (state) => {
  const { selected } = state.cache.ltvSettingsAd;
  return selected;
};

const isShowModal = (state) => {
  const { isShowSettingModal } = state.cache.ltvSettingsAd.settings;
  return isShowSettingModal;
};

const getApiError = (state) => {
  return state.cache.ltvSettingsAd.error;
};

const getPagination = (state) => state.cache.ltvSettingsAd.settings.pagination;

const getSort = (state) => state.cache.ltvSettingsAd.settings.sort;

const getCurrentItems = (state) =>
  state.cache.ltvSettingsAd.settings.pagination.totalItems;

// get options for render select popup
const getOptions = createSelector(
  (state) => state.cache.ltvSettingsAd.adByIds,
  (items) => {
    const data = {};
    Object.keys(items).map((adId, index) => {
      const item = items[adId];
      data[adId] = {
        value: item.ad_id,
        label: item.ad_name,
        order: item?.order ?? index + 1,
      };
      return adId;
    });
    return data;
  }
);

const csvDownloadEnabledSelector = createSelector(
  [getStatus, getPagination],
  (status, pagination) => {
    return status !== 'loading' && pagination.totalItems > 0;
  }
);

const sortSelector = createSelector(
  (state) => state.cache.ltvSettingsAd.settings.sort,
  (sort) => {
    return { sort: getSortString(sort) };
  }
);

export default {
  getList,
  getDetail,
  getListDetail,
  getStatus,
  getAdStatus,
  getFetchStatus,
  getSelectedItem,
  isShowModal,
  getApiError,
  getPagination,
  getSort,
  getCurrentItems,
  getOptions,
  csvDownloadEnabledSelector,
  sortSelector,
};
