import React, { useRef } from 'react';
import { shape, func, string } from 'prop-types';
import { Button } from 'react-bootstrap';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import {
  getFuncIdByReportType,
  checkIsReportTypePeriod,
} from 'services/data-export/dataExportService';
import DisplayItemsForm from 'views/organism/DisplayItemsForm';
import DisplayItemsCvForm from 'views/organism/DisplayItemsCvForm';
import DisabledTooltipWrapper from 'views/atoms/tooltip/DisabledTooltipWrapper';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import useCheckPermissionSettingReport from 'services/custom-hooks/useCheckPermissionSettingReport';
import {
  REPORT_FIELDS,
  TIME_AXIS_DAILY,
  TIME_AXIS_DAY_OF_WEEK,
  TIME_AXIS_HOURLY,
  TIME_AXIS_MONTHLY,
} from 'domain/data-export/consts';

const { SUMMARY_MODE } = REPORT_FIELDS;

const DisplayItemSettingForRegularReport = (props) => {
  const {
    reportType,
    items,
    settingsDefault,
    settingsCustom,
    summaryMode,
    error,
    onChange,
    onSetDisplayItems,
  } = props;

  const displayItemRef = useRef(null);

  const { isAllowedSettingDataExport } = useCheckPermissionSettingReport();

  const funcId = getFuncIdByReportType(reportType);
  const isCvAnalysis = DisplayItemsService.checkIsCvAnalysis(items);

  const handleFormChange = (data) => {
    onSetDisplayItems(data);
  };

  const handleTimeAxisChange = (data) => {
    onChange(SUMMARY_MODE, data);
  };

  const isReportTypePeriod = checkIsReportTypePeriod(reportType);

  const timeAxisRadio = {
    name: SUMMARY_MODE,
    selected: summaryMode,
    onChange: handleTimeAxisChange,
    item: {
      [TIME_AXIS_DAILY]: { label: '日', value: TIME_AXIS_DAILY },
      [TIME_AXIS_MONTHLY]: { label: '月', value: TIME_AXIS_MONTHLY },
      [TIME_AXIS_DAY_OF_WEEK]: { label: '曜日', value: TIME_AXIS_DAY_OF_WEEK },
      [TIME_AXIS_HOURLY]: { label: '時間帯', value: TIME_AXIS_HOURLY },
    },
  };

  return (
    <div
      ref={displayItemRef}
      className="data-export-setting__item data-export-setting__item--displayitem mb-30"
    >
      <ErrorTooltipWrapper
        isError={!!error}
        errorMess={error}
        ref={displayItemRef}
      >
        {isCvAnalysis ? (
          <DisplayItemsCvForm
            items={items}
            defaultValue={settingsCustom}
            settingsDefault={settingsDefault}
            onChange={handleFormChange}
          />
        ) : (
          <DisplayItemsForm
            helperText=""
            resetLink={false}
            funcId={funcId}
            items={items}
            defaultValue={settingsCustom}
            settingsDefault={settingsDefault}
            onChange={handleFormChange}
            timeAxisRadio={isReportTypePeriod ? timeAxisRadio : false}
          />
        )}
      </ErrorTooltipWrapper>
      <DisabledTooltipWrapper
        disabled
        message={
          isAllowedSettingDataExport
            ? 'このレポートタイプは並び替えに対応していません。'
            : '無償版では利用できません'
        }
        placement="bottom"
      >
        <div className="data-export-setting__button">
          <Button variant="secondary" size="sm" disabled>
            集計軸の並び替え
          </Button>
        </div>
      </DisabledTooltipWrapper>
    </div>
  );
};

DisplayItemSettingForRegularReport.propTypes = {
  reportType: string.isRequired,
  summaryMode: string,
  error: string,
  items: shape({}),
  settingsDefault: shape({}),
  settingsCustom: shape({}),
  onChange: func,
  onSetDisplayItems: func,
};

DisplayItemSettingForRegularReport.defaultProps = {
  summaryMode: '',
  error: '',
  items: {},
  settingsDefault: {},
  settingsCustom: {},
  onChange: () => {},
  onSetDisplayItems: () => {},
};

export default React.memo(DisplayItemSettingForRegularReport);
