import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './tabs.scss';

function Tabs({ items }) {
  return (
    items && (
      <div className="tabs">
        {items.map((tab) => (
          <NavLink key={tab.path} className="tab" to={tab.path}>
            {tab.label}
          </NavLink>
        ))}
      </div>
    )
  );
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ path: PropTypes.string, label: PropTypes.string })
  ),
};

Tabs.defaultProps = {
  items: [],
};

export default Tabs;
