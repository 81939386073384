import * as FIELD from 'domain/fields';
import { communicationStatus } from 'services/utils';

const { LOADING } = communicationStatus;

const COLUMN_TYPE_1 = 'col_type_1';
const COLUMN_TYPE_2 = 'col_type_2';
const COLUMN_TYPE_3 = 'col_type_3';
const MAX_RATIO = 100;
const DIMENSIONS = [
  'category',
  'ad_group1',
  'ad_group2',
  'sync_category',
  'media_side_campaign',
  'media_side_group',
  'media_side_ad_name',
];

const round = (number, decimals) => {
  return +`${Math.round(`${number}e+${decimals}`)}e-${decimals}`;
};

const isFetching = (statusData, statusDisplayItem) => {
  if (statusData === LOADING || statusDisplayItem === LOADING) {
    return true;
  }
  return false;
};

const formatNum = (num, fractionDigits = 2) => {
  const number = round(parseFloat(num || 0), fractionDigits)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  const split = number.toString().split('.');
  let count = fractionDigits;
  if (split[1]) {
    count = fractionDigits - split[1].length;
    return split.join('.') + '0'.repeat(count);
  }
  return split[0] > 0
    ? split[0] + (count > 0 ? `.${'0'.repeat(count)}` : '')
    : split[0];
};

const getSelectedKey = (selectedItems, items) => {
  const r = [];
  for (let i = 0; i < items.length; i += 1) {
    const key = items[i].name;
    if (selectedItems[key] === true) {
      r.push(key);
    }
  }
  return r;
};

const isValidDimensions = (selected) => {
  if (selected.length === 0) {
    return false;
  }
  for (let i = 0; i < selected.length; i += 1) {
    if (DIMENSIONS.indexOf(selected[i]) === -1) {
      return false;
    }
  }
  return true;
};

const getDimensionKey = (row) => {
  let r = '';
  if (row[FIELD.PRIORITY_AXIS]) r += row[FIELD.PRIORITY_AXIS];
  if (row[FIELD.CATEGORY_ID]) r += row[FIELD.CATEGORY_ID];
  if (row.media_id) r += `_${row.media_id}`;
  if (row[FIELD.AD_GROUP1_ID]) r += `_${row[FIELD.AD_GROUP1_ID]}`;
  if (row[FIELD.AD_GROUP2_ID]) r += `_${row[FIELD.AD_GROUP2_ID]}`;
  if (row[FIELD.SYNC_CATEGORY_ID]) r += `_${row[FIELD.SYNC_CATEGORY_ID]}`;
  if (row[FIELD.MEDIA_SIDE_CAMPAIGN_ID])
    r += `_${row[FIELD.MEDIA_SIDE_CAMPAIGN_ID]}`;
  if (row[FIELD.MEDIA_SIDE_GROUP_ID]) r += `_${row[FIELD.MEDIA_SIDE_GROUP_ID]}`;
  if (row[FIELD.MEDIA_SIDE_AD_NAME]) r += `_${row[FIELD.MEDIA_SIDE_AD_NAME]}`;
  return r;
};

const getMetricsExtend = (metrics = []) => {
  const ext = [
    // Required field use for sort, calc Axis on chart
    FIELD.RCV_COST_EFFECTIVENESS,
    FIELD.RAMOUNT_COST_EFFECTIVENESS,
    FIELD.REALLOCATION_CV_RATIO,
    FIELD.REALLOCATION_SALES_RATIO,
    FIELD.AD_COST_RATIO,
    FIELD.COST,
    FIELD.RCV,
    FIELD.RAMOUNT,
  ];
  const itemSet = new Set([...metrics, ...ext]);
  return [...itemSet];
};

const sumFromToByKey = (data, indexFrom, indexTo, key, maxValue = null) => {
  let sum = 0;
  for (let i = indexFrom; i <= indexTo; i += 1) {
    sum += parseFloat(data[i][key]);
  }
  if (maxValue && sum > maxValue) {
    return maxValue;
  }
  return sum;
};

const sumByKey = (data, key) => {
  return data.reduce((sum, item) => sum + parseFloat(item[key]), 0);
};

const sortByKey = (data, key) => {
  if (!data) {
    return [];
  }
  const copyData = [...data];
  return copyData.sort((a, b) => b[key] - a[key]);
};

const getProductivityKey = (metric) => {
  if (metric === FIELD.REALLOCATION_CV_RATIO) {
    return FIELD.RCV_COST_EFFECTIVENESS;
  }
  if (metric === FIELD.REALLOCATION_SALES_RATIO) {
    return FIELD.RAMOUNT_COST_EFFECTIVENESS;
  }
  return null;
};

const getMinValue = (data, keyToCompare, keyToGet) => {
  let min = 100;
  for (let i = 0; i < data.length; i += 1) {
    const sum = sumFromToByKey(data, 0, i, keyToGet, MAX_RATIO);
    if (data[i][keyToCompare] && sum < min) {
      min = sum;
    }
  }
  return min;
};

const formatSeriesData = (
  data,
  keyX = FIELD.AD_COST_RATIO,
  keyY = FIELD.REALLOCATION_CV_RATIO,
  selectedRows
) => {
  const series = data.map((item, index) => {
    const dimensionKey = getDimensionKey(item);
    return {
      ...item,
      x: sumFromToByKey(data, 0, index, keyX, MAX_RATIO),
      y: sumFromToByKey(data, 0, index, keyY, MAX_RATIO),
      selected: selectedRows.indexOf(dimensionKey) !== -1,
    };
  });
  return series;
};

/**
 * Check if table apply simulation
 * @param simulation object
 * @returns {boolean}
 */
const isApplySimulation = (simulation) => {
  if (simulation.type) {
    if (
      simulation.type === 1 &&
      simulation.productivity &&
      simulation.productivity_up
    ) {
      return true;
    }
    if (simulation.type === 2 && simulation.cost) {
      return true;
    }
  }
  return false;
};

const getSimulationSumRow = (rawSumData, detailData, col2, col3) => {
  const sumData = { ...rawSumData };
  sumData[col2] = sumByKey(detailData, col2).toFixed(2);
  sumData[col3] = sumByKey(detailData, col3).toFixed(2);

  return sumData;
};

/**
 *
 * @param key
 * @param obj
 * @param metric
 * @param simulation
 * @returns {*}
 */
const calcVal = (colType, obj, metric, simulation) => {
  const pKey = getProductivityKey(metric);
  const isTarget = parseFloat(obj[pKey]) <= parseFloat(simulation.productivity);
  if (colType === COLUMN_TYPE_1) {
    return isTarget === true ? '✓' : '';
  }
  if (colType === COLUMN_TYPE_2 && isTarget) {
    return (obj.cost * parseFloat(simulation.productivity_up)) / 100;
  }
  if (colType === COLUMN_TYPE_3) {
    return isTarget
      ? obj.cost - (obj.cost * parseFloat(simulation.productivity_up)) / 100
      : obj.cost;
  }
  return 0;
};
const calcValPattern2 = (colType, obj, metric, simulation, data, index) => {
  const keyToCompare =
    metric === FIELD.REALLOCATION_CV_RATIO
      ? FIELD.AD_COST_RATIO
      : FIELD.REALLOCATION_SALES_RATIO;
  const adCostTotal = sumByKey(data, FIELD.COST);
  const compare =
    (sumFromToByKey(data, index, data.length - 1, keyToCompare, MAX_RATIO) /
      100) *
    adCostTotal;
  const isTarget = compare <= parseFloat(simulation.cost);
  const targetMetric =
    metric === FIELD.REALLOCATION_CV_RATIO ? FIELD.RCV : FIELD.RAMOUNT;
  if (colType === COLUMN_TYPE_1) {
    return isTarget === true ? '✓' : '';
  }
  if (colType === COLUMN_TYPE_2 && isTarget) {
    return obj[targetMetric];
  }
  if (colType === COLUMN_TYPE_3) {
    return isTarget ? 0 : obj[targetMetric];
  }
  return 0;
};

const getSimulationDataForTable = (
  data,
  selectedRows,
  metric,
  simulation,
  col1,
  col2,
  col3
) => {
  if (!isApplySimulation(simulation)) {
    return data;
  }
  if (simulation.type === 1) {
    return data.map((rawItem) => {
      const item = { ...rawItem };
      const col1Val = calcVal(COLUMN_TYPE_1, item, metric, simulation);
      const col2Val = calcVal(COLUMN_TYPE_2, item, metric, simulation);
      const col3Val = calcVal(COLUMN_TYPE_3, item, metric, simulation);
      item[col1] = col1Val;
      item[col2] = col2Val;
      item[col3] = col3Val;
      return item;
    });
  }

  return data.map((rawItem, index) => {
    const item = { ...rawItem };
    const dimensionKey = getDimensionKey(item);
    item.dimension_key = dimensionKey;
    item.selected = false;
    if (selectedRows.indexOf(dimensionKey) !== -1) {
      item.selected = true;
    }
    const col1Val = calcValPattern2(
      COLUMN_TYPE_1,
      item,
      metric,
      simulation,
      data,
      index
    );
    const col2Val = calcValPattern2(
      COLUMN_TYPE_2,
      item,
      metric,
      simulation,
      data,
      index
    );
    const col3Val = calcValPattern2(
      COLUMN_TYPE_3,
      item,
      metric,
      simulation,
      data,
      index
    );
    item[col1] = col1Val;
    item[col2] = col2Val;
    item[col3] = col3Val;
    return item;
  });
};

const findByDimensionKey = (data, dimensionKey) => {
  return data.find((row) => getDimensionKey(row) === dimensionKey);
};

const mergeTable = (fromData = [], toData = [], col1, col2, col3) => {
  const merge = [];
  for (let i = 0; i < toData.length; i += 1) {
    const row = { ...toData[i] };
    const fromRow = findByDimensionKey(fromData, getDimensionKey(row));
    if (fromRow) {
      row[col1] = fromRow[col1];
      row[col2] = fromRow[col2];
      row[col3] = fromRow[col3];
    } else {
      row[col1] = '';
      row[col2] = '';
      row[col3] = '';
    }

    merge.push(row);
  }

  return merge;
};

export default {
  formatNum,
  isFetching,
  getMinValue,
  isValidDimensions,
  getProductivityKey,
  getSimulationDataForTable,
  getSimulationSumRow,
  getSelectedKey,
  sumByKey,
  sortByKey,
  sumFromToByKey,
  isApplySimulation,
  getDimensionKey,
  getMetricsExtend,
  formatSeriesData,
  mergeTable,
};
