import { createSelector } from 'reselect';
import { createTableHeaders } from 'services/utils';
import { FILTER_KEY_SEARCH_CONSOLE, TAB_AD } from 'services/consts';
import * as DisplayItems from 'domain/settings/display-items';
import {
  getAdAxis,
  getAllAxis,
} from 'domain/settings/display-items-landing-page-analysis';
import settingsSelectors from 'store/settings/selectors';
import displayItemsSelectors from 'store/display-items/selectors';
import { CV_METRICS } from 'domain/consts';
import masterDataSelectors from 'store/master-data/selectors';
import { createFieldsFromConversions } from 'domain/utils';
import { CATEGORY, LANDING_PAGE_DOMAIN, SYNC_CATEGORY } from 'domain/fields';
import filterSelectors from 'store/filters/selectors';
import { isEmpty } from 'lodash';

const listColumnDisabledTooltip = [CATEGORY, SYNC_CATEGORY];

const getStatesLandingPageAnalyze = (state) => state.cache.landingPageAnalyze;
const getSort = (state) => state.cache.landingPageAnalyze.table.settings.sort;
const getStatus = (state) => state.cache.landingPageAnalyze.status;
const getStatusTable = (state) => state.cache.landingPageAnalyze.table.status;
const getPagination = (state) =>
  state.cache.landingPageAnalyze.table.settings.pagination;
const getCvMetrics = (state) => {
  const conversions = masterDataSelectors.conversionsForDisplaySelector(state);
  const metricSelected = displayItemsSelectors.getMetricsSelector(state);

  return createFieldsFromConversions(
    conversions,
    metricSelected.filter((metric) => CV_METRICS.includes(metric))
  );
};

const dimensionsSelector = createSelector(
  [
    settingsSelectors.getTab,
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getItemsReport,
    displayItemsSelectors.getDisplayItemPriorityAxis,
    displayItemsSelectors.getSettingsDefault,
    filterSelectors.getSettings,
  ],
  (tab, dimensions, displayItems, priorityAxis, settingsDefault, filters) => {
    const axis = tab === TAB_AD ? getAdAxis() : getAllAxis();

    const option = displayItems.reduce((acc, item) => {
      if (
        item.getGroup(priorityAxis) !==
        DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS
      ) {
        return acc;
      }
      return { ...acc, [item.key]: item };
    }, {});

    const actualDisplayItems = displayItems
      .filter((item) => {
        if (
          item.key === DisplayItems.SEARCH_WORD &&
          !Object.keys(filters).includes(FILTER_KEY_SEARCH_CONSOLE)
        ) {
          return false;
        }
        if (isEmpty(dimensions)) {
          return (
            item.displayFreeze &&
            Object.keys(settingsDefault).includes(item.key)
          );
        }
        return item.displayFreeze && dimensions.includes(item.key);
      })
      .map((item) => item.key);
    const dimensionList = actualDisplayItems.filter((item, index, items) => {
      if (index === 0) return true;

      const { callback = () => {} } = axis[index - 1] || {};
      const actualOption =
        callback(items[index - 1], option, dimensions) || option;
      return (actualOption[item]?.allowAxis || []).includes(axis[index]?.name);
    });

    return dimensionList;
  }
);

const metricsSelector = createSelector(
  [
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (dimensions, metrics, displayItems) => {
    return displayItems
      .filter(
        (item) =>
          !item.displayFreeze && dimensions.concat(metrics).includes(item.key)
      )
      .map((item) => item.key);
  }
);

const sortSelector = createSelector(
  [dimensionsSelector, metricsSelector, getCvMetrics, getSort],
  (dimensions, metrics, cvMetrics, sort) => {
    const newMetrics = [...metrics];
    cvMetrics
      .filter((cntDirect) => cntDirect.children.length > 0)
      .forEach((cntDirect) =>
        cntDirect.children.map((conversion) => newMetrics.push(conversion.name))
      );
    if (sort && dimensions.concat(newMetrics).includes(sort.field)) {
      return { sort: (sort.direction === 'asc' ? '' : '-') + sort.field };
    }

    return {};
  }
);

// Create header data
export const dimensionsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const dimensions = displayItems
    .filter((item) => item.displayFreeze)
    .map((item) => {
      return {
        text: item.title,
        name: item.key,
      };
    });

  return dimensions;
};

export const metricsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const cvMetrics = getCvMetrics(state);

  const metrics = [];
  displayItems
    .filter((item) => !item.displayFreeze)
    .map((item) => {
      if (CV_METRICS.includes(item.key)) {
        metrics.push(...cvMetrics);
      } else {
        metrics.push({
          text: item.title,
          name: item.key,
        });
      }
      return metrics;
    });

  return metrics;
};

export const groupsConfig = () => [
  {
    name: 'indirect',
    text: '間接効果',
    children: [
      DisplayItems.CNT_INDIRECT2,
      DisplayItems.CNT_INDIRECT3,
      DisplayItems.CNT_INDIRECT4,
      DisplayItems.CNT_INDIRECT5,
      DisplayItems.CNT_INDIRECT_OTHER,
      DisplayItems.INDIRECT_TOTAL,
    ],
  },
  {
    name: 'rate',
    text: '直間比率',
    children: [DisplayItems.DIRECT_RATE, DisplayItems.INDIRECT_RATE],
  },
];
const getConfig = (state) => {
  return {
    dimensions: dimensionsConfig(state),
    metrics: metricsConfig(state),
    groups: groupsConfig(state),
  };
};

const headerSelector = createSelector(
  [
    settingsSelectors.getTab,
    dimensionsSelector,
    metricsSelector,
    getCvMetrics,
    getSort,
    getConfig,
  ],
  (channel, dimensions, metrics, cvMetrics, sort, itemsConfig) => {
    const headerData = createTableHeaders({
      channel,
      dimensions,
      metrics: [...metrics, ...cvMetrics.map((cntDirect) => cntDirect.name)],
      sorts: [sort],
      itemsConfig,
    });
    const customHeaders = headerData.map((item) =>
      item.name === LANDING_PAGE_DOMAIN
        ? {
            ...item,
            tooltip:
              '流入先としてユーザーに表示されたWebページのURLドメインです。サイトコンテンツ対象として設定されたページからの遷移の場合、流入先として計測されます。',
          }
        : item
    );
    const headers =
      channel === TAB_AD
        ? customHeaders.map((item) =>
            listColumnDisabledTooltip.includes(item.name)
              ? { ...item, tooltip: false }
              : item
          )
        : customHeaders;
    return headers;
  }
);

export default {
  getStatus,
  getStatusTable,
  getPagination,
  getStatesLandingPageAnalyze,
  dimensionsSelector,
  metricsSelector,
  headerSelector,
  sortSelector,
};
