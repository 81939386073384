import React, { useContext, useRef, useCallback, useMemo } from 'react';
import { bool, string } from 'prop-types';
import {
  GridTableContext,
  GridTableItemContext,
} from 'views/organism/GridTable/GridTableContext';
import {
  GRID_TABLE_ID,
  FULL_WIDTH_COLUMN,
  MIN_WIDTH_COLUMN,
  MAX_WIDTH_COLUMN,
} from './consts';

function GridTableResizeBar(props) {
  const { isResizeGroup, field } = props;
  const {
    settings: { heightHeader },
  } = useContext(GridTableContext);
  const {
    dispatchItem,
    headers,
    gridTemplateColumns,
    gridTableItemRef,
  } = useContext(GridTableItemContext);

  const resizeBarRef = useRef(null);
  const templateColumnsRef = useMemo(() => {
    return { current: gridTemplateColumns };
  }, [gridTemplateColumns]);

  const resizingColumnRef = useRef(null);

  const onMouseMove = useCallback(
    (e) => {
      if (!resizeBarRef.current) return;
      const columnEL = resizeBarRef.current.parentNode;
      const column = headers.find((item) => item.field === field);
      const gridTableItemEl = gridTableItemRef.current;
      requestAnimationFrame(() => {
        // get width
        const width = e.clientX - columnEL.getBoundingClientRect().left;
        const minWidth = `${Math.min(
          Math.max(width, parseInt(column.minWidth, 10) || MIN_WIDTH_COLUMN),
          MAX_WIDTH_COLUMN
        )}px`;
        const maxWidth = column.isLastColumn ? FULL_WIDTH_COLUMN : minWidth;

        const actualWidth =
          minWidth === maxWidth ? minWidth : `minmax(${minWidth}, ${maxWidth})`;

        templateColumnsRef.current = {
          ...gridTemplateColumns,
          [field]: `[${field}] ${actualWidth}`,
        };

        const templateColumnsStyle = Object.values(
          templateColumnsRef.current
        ).join(' ');

        // set width
        gridTableItemEl.querySelector(
          '[role="rowheader"]'
        ).style.gridTemplateColumns = templateColumnsStyle;
        gridTableItemEl.querySelector(
          '[role="rowgroup"]'
        ).style.gridTemplateColumns = templateColumnsStyle;
      });
    },
    [field, gridTableItemRef, gridTemplateColumns, headers, templateColumnsRef]
  );

  const onMouseUp = () => {
    const gridTableEl = resizeBarRef.current.closest(`#${GRID_TABLE_ID}`);
    gridTableEl.classList.remove('grid-table--resizing');
    dispatchItem({
      type: 'setTemplateColumns',
      payload: templateColumnsRef.current,
    });
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
    resizingColumnRef.current = null;
  };

  const onMouseDown = (e) => {
    const gridTableEl = e.target.closest(`#${GRID_TABLE_ID}`);
    gridTableEl.classList.add('grid-table--resizing');

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={resizeBarRef}
      className="grid-table__column-resize"
      onMouseDown={onMouseDown}
      style={{
        height: isResizeGroup ? heightHeader : null,
      }}
    />
  );
}

GridTableResizeBar.propTypes = {
  isResizeGroup: bool,
  field: string,
};

GridTableResizeBar.defaultProps = {
  isResizeGroup: false,
  field: '',
};

export default React.memo(GridTableResizeBar);
