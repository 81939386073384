import React from 'react';
import PropTypes, { string } from 'prop-types';
import classNames from 'classnames';
import Spinner from './Spinner';

import './PageLoader.scss';

const DataSyncLoader = ({ isLoading, children, className }) => {
  const clsName = classNames({
    'page-loader': true,
    'd-flex': true,
    'align-items-center': true,
    'justify-content-center': true,
    [className]: !!className,
  });

  return isLoading ? (
    <div className={clsName}>
      <Spinner />
    </div>
  ) : (
    children
  );
};

DataSyncLoader.defaultProps = {
  isLoading: false,
  className: undefined,
  children: undefined,
};
DataSyncLoader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
  className: string,
};

export default DataSyncLoader;
