import React from 'react';
import { chain, isEmpty } from 'lodash';
import {
  ETLD1,
  DOMAIN_NAME,
  EMAIL_NOTIFICATION,
  SEND_MAIL_USERS,
  REDIRECT_FLAG,
} from 'domain/fields';
import {
  INFO_FORM_MEASUREMENT_SETTING as MEASUREMENT_LABELS,
  INFO_FORM_BASIC_SETTING,
  INFO_FORM_OTHER_SETTING,
  DNS_SETTINGS_MAX_ENTRIES_PER_REGISTRATION,
  DNS_MAX_LENGTH,
  DNS_REGEX,
  NS_SETTING,
  CNAME_SETTING,
  HEADER_TABLE_DNS_SETTING,
  HEADER_DNS_COMPLETE_SCREEN,
  HEADER_TABLE_CNAME_SETTING,
  LIMIT_NUMBER_DNS_CALL_API,
  CLICK_MEDIA_SETTING_MEDIA,
} from 'domain/system-setting/consts';
import {
  ROLE_VIEW_AD,
  ROLE_VIEW_ALL,
  ROLE_VIEW_ALL_AD_ONLY,
} from 'domain/permissions/roles';
import {
  basicSettingRules,
  measurementConfigRules,
  measurementLogRules,
  otherSettingRules,
} from 'services/validations/systemSettingRules';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import { IconMultiplePerson } from 'views/atoms/icon/SvgIcon';
import * as messageError from 'services/validations/messageErrorByCode';
import { getErrorMessageByCode } from 'services/utils';
import ErrorMessages from 'services/validations/constants';
import { validUrlByLine } from 'services/validations/commonValidations';

export const renderTextByStatus = (status) => {
  return status ? '有効' : '無効';
};
export const renderTextByStatusITP = (status) => {
  return status ? '1st Party Cookieで計測する（一部のブラウザのみ）' : '3rd Party Cookieで計測する';
};

export const validateDataBasic = (data) => {
  return chain(data)
    .mapValues((value, field) => {
      return {
        label: INFO_FORM_BASIC_SETTING[field].label,
        value: value ?? null,
      };
    })
    .mapValues((value, field) => {
      if (isEmpty(basicSettingRules[field])) return [];
      const rulesByField = [...basicSettingRules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();
};

export const validateBasicSettingForm = (data) => {
  const error = {
    errors: {},
    errorsByLine: [],
    isShowOnTable: false,
  };

  const errorNormalData = validateDataBasic(data);
  if (!isEmpty(errorNormalData)) {
    error.errors = errorNormalData;
  } else {
    const errorByLine = validUrlByLine(
      ErrorMessages.URL_CHECK,
      true
    )({ field: 'host_name', value: data.host_name });
    if (!isEmpty(errorByLine)) {
      error.errorsByLine = errorByLine;
      error.isShowOnTable = true;
    }
  }

  return error;
};

export const formatDisplayEmailInfo = (emailInfo = []) => {
  const NO_EXIST_EMAIL = -1;
  if (emailInfo === NO_EXIST_EMAIL) {
    return '';
  }
  if (emailInfo?.length === 1) {
    return emailInfo[0].email;
  }
  if (emailInfo?.length > 1) {
    const email = emailInfo.reduce(
      (obj, item, index) => {
        const result = obj;
        if (index === 0) {
          result.displayEmail = item.email;
        } else {
          result.tooltipEmail = (
            <>
              {result.tooltipEmail} {isEmpty(result.tooltipEmail) ? '' : <br />}
              {item.email}
            </>
          );
        }
        return result;
      },
      {
        displayEmail: '',
        tooltipEmail: '',
      }
    );
    return (
      <div className="multiple-emails">
        <span>{email.displayEmail}</span>
        <DragonBall
          svg={<IconMultiplePerson />}
          variant="modal-white"
          placement="bottom-end"
        >
          {email.tooltipEmail}
        </DragonBall>
      </div>
    );
  }

  return '';
};

export const formatUsersData = (users) => {
  if (isEmpty(users)) return [];

  const icon = <i className="fas fa-check" />;
  return users.map((user) => {
    const { agent_flag: agentFlag, user_roles: userRoles = [] } = user;

    let email = user.email || '';
    const emails = email.split(',').map((item) => ({ email: item }));
    if (emails.length > 1) {
      email = formatDisplayEmailInfo(emails);
    }

    return {
      ...user,
      email,
      rowId: user.auth_id,
      agent: agentFlag ? icon : null,
      view_ad: userRoles.includes(ROLE_VIEW_AD) ? icon : null,
      view_all:
        userRoles.includes(ROLE_VIEW_ALL) ||
        (userRoles.includes(ROLE_VIEW_ALL_AD_ONLY) && agentFlag)
          ? icon
          : null,
    };
  });
};

export const validateMeasurementConfigSetting = (data) => {
  const resultError = chain(data)
    .mapValues((value, field) => {
      return {
        label: MEASUREMENT_LABELS[field].label,
        value,
      };
    })
    .mapValues((value, field) => {
      if (isEmpty(measurementConfigRules[field])) return [];
      const rulesByField = [...measurementConfigRules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();
  return resultError;
};

export const validateMeasurementLogSetting = (
  data,
  isAllowedSettingMeasurementOption
) => {
  if (isAllowedSettingMeasurementOption) {
    const resultError = chain(data)
      .mapValues((value, field) => {
        return {
          label: MEASUREMENT_LABELS[field].label,
          value,
        };
      })
      .mapValues((objValue, field) => {
        if (isEmpty(measurementLogRules[field])) return [];
        const keyRulesByField = Object.keys(measurementLogRules[field]);
        const errors = keyRulesByField
          .map((keyRule) => {
            const valueValidate = ['maxLine', 'duplicateValue'].includes(
              keyRule
            )
              ? { ...objValue, value: objValue.value.split(',') }
              : objValue;

            return measurementLogRules[field][keyRule](valueValidate);
          })
          .filter((rule) => !isEmpty(rule));
        return errors;
      })
      .pickBy((errors) => !isEmpty(errors))
      .mapValues((errors) => errors[0])
      .value();
    return resultError;
  }
  return {};
};

export const getErrorMessageResponse = (errors, params = {}) => {
  return errors.reduce((prevError, error) => {
    const { field } = error;
    return {
      ...prevError,
      [field]: getErrorMessageByCode(error, messageError, {
        ...params,
        label: MEASUREMENT_LABELS[field].label,
      }),
    };
  }, {});
};

export const validateOtherSettingForm = (data) => {
  const resultError = chain(data)
    .mapValues((value, field) => {
      return {
        label: INFO_FORM_OTHER_SETTING[field].label,
        value,
      };
    })
    .mapValues((value, field) => {
      if (isEmpty(otherSettingRules[field])) return [];
      const rulesByField = [...otherSettingRules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();

  return resultError;
};

export const getErrorResponseOtherSetting = (errors, params = {}) => {
  return errors.reduce((prevError, error) => {
    const { field } = error;
    return {
      ...prevError,
      [field]: getErrorMessageByCode(error, messageError, {
        ...params,
        label: INFO_FORM_OTHER_SETTING[field].label,
      }),
    };
  }, {});
};

export const formatDataDnsRequest = (
  dataValidate = [],
  mailFormValue,
  tab,
  isAddEmailMode
) => {
  const listDomain = dataValidate.map(([domain, value]) => `${value},${domain}`);
  const listDomainRequest = [];

  while (listDomain.length > 0) {
    const dataRequest = {
      domains: listDomain.splice(0, LIMIT_NUMBER_DNS_CALL_API),
      domain_params: {
        'redirect_flag': mailFormValue.redirect_flag.value,
        'send_mail_flag': mailFormValue.send_mail_flag.value,
        'send_mail_users': mailFormValue.send_mail_users.value,
      },
    };
    if (tab === CNAME_SETTING) {
      dataRequest.email_authentication = isAddEmailMode;
    }
    listDomainRequest.push(dataRequest);
  }
  return { listDomainRequest, isAddEmailMode };
};

export const validateDnsSetting = (data = []) => {
  const error = {
    errorMainMessage: '',
    errorForm: {},
  };
  if (data.length === 0) {
    error.errorMainMessage = `ドメインは一つ以上入力してください`;
  } else if (data.length > DNS_SETTINGS_MAX_ENTRIES_PER_REGISTRATION) {
    error.errorMainMessage = `一度に登録ができるドメインの上限は${DNS_SETTINGS_MAX_ENTRIES_PER_REGISTRATION}件までです。`;
  } else {
    data.forEach(([key, value]) => {
      if (value.length > DNS_MAX_LENGTH) {
        error.errorForm = {
          ...error.errorForm,
          [key]: 'ドメインは64字以下で入力してください。',
        };
      } else if (!DNS_REGEX.test(`${value}.${key}`)) {
        error.errorForm = {
          ...error.errorForm,
          [key]: 'ドメインの形式が不正です。',
        };
      }
    });
  }
  return error;
};

export const validateDnsEmailSetting = (data = []) => {
  let errorForm = {};
  if (!(Object.prototype.hasOwnProperty.call(data, REDIRECT_FLAG) && data[REDIRECT_FLAG].value !== null)) {
    errorForm = {
      ...errorForm,
      [REDIRECT_FLAG]: 'リダイレクト計測を入力してください。',
    };
  } else if (!(Object.prototype.hasOwnProperty.call(data, EMAIL_NOTIFICATION) && data[EMAIL_NOTIFICATION].value !== null)) {
    errorForm = {
      ...errorForm,
      [EMAIL_NOTIFICATION]: 'アラートメール通知を入力してください。',
    };
  } else if (data[REDIRECT_FLAG].value === true && data[EMAIL_NOTIFICATION].value === false) {
    // pj548リリース前のデータ編集時にのみ発生するエラー
    errorForm = {
      ...errorForm,
      [EMAIL_NOTIFICATION]: 'アラートメール通知を入力してください。',
    };
  } else if (!(typeof data[REDIRECT_FLAG].value === "boolean")) {
    errorForm = {
      ...errorForm,
      [REDIRECT_FLAG]: 'リダイレクト計測の形式が不正です。',
    };
  } else if (!(typeof data[EMAIL_NOTIFICATION].value === "boolean")) {
    errorForm = {
      ...errorForm,
      [EMAIL_NOTIFICATION]: 'アラートメール通知の形式が不正です。',
    };
  } else if (!(typeof data[SEND_MAIL_USERS].value === "object")) {
    errorForm = {
      ...errorForm,
      [SEND_MAIL_USERS]: '送信先の形式が不正です。',
    };
  }
  return errorForm;
};

export const getTitleDnsSetting = (tab, isAddEmailMode, isEdit) => {
  if (isAddEmailMode) {
    return (
      <div>
        <div className="modal-title h4">CNAMEドメインを追加（メール審査）</div>
        {/*
        <div className="dns-note">
          ※[*]がついているドメインは、サービス提供元でDNS設定済のため、当サービス管理画面の設定のみで完了します。「有効化」のみにチェックしてご登録ください
        </div>
        */}
      </div>
    );
  }
  return `計測用DNS（${
    tab === NS_SETTING ? 'NSドメイン' : 'CNAMEドメイン'
  }）を${
    isEdit ? '編集' : '追加'
  }`;
};

export const getHeaderDnsTable = (isScreenComplete, tab) => {
  if (isScreenComplete) return HEADER_DNS_COMPLETE_SCREEN;
  if (tab === CNAME_SETTING) return HEADER_TABLE_CNAME_SETTING;
  return HEADER_TABLE_DNS_SETTING;
};

export const getClickIdSettingMediaByKey = (listKey) => {
  const listKeyClickId = Object.keys(CLICK_MEDIA_SETTING_MEDIA)
    .filter((key) => listKey.includes(key))
    .map((key) => CLICK_MEDIA_SETTING_MEDIA[key].name);
  return listKeyClickId.join('、');
};

/**
 * Format CAPI Setting data
 * @return object
 */
export const formatDnsSettingEmailData = (tagListData = [], dataField = {}, isEdit = false) => {
  if (isEmpty(tagListData)) return dataField;

  const domainNames = tagListData.map((item) =>
    item.domain_name
  );
  const etld1Values = tagListData.map((item) =>
    item.etld1
  );
  const redirectFlags = tagListData.map((item) =>
    item.redirect_flag
  );
  const sendMailFlags = tagListData.map((item) =>
    item.send_mail_flag
  );
  const sendMailUsers = tagListData.map((item) =>
    item.send_mail_users
  );

  if (isEdit) {
    return {
      ...dataField,
      [DOMAIN_NAME]: {
        ...dataField[DOMAIN_NAME],
        value: domainNames,
      },
      [ETLD1]: {
        ...dataField[ETLD1],
        value: etld1Values,
      },
      [REDIRECT_FLAG]: {
        ...dataField[REDIRECT_FLAG],
        value: redirectFlags[0], // There is always one data item, so get [0].
      },
      [EMAIL_NOTIFICATION]: {
        ...dataField[EMAIL_NOTIFICATION],
        value: sendMailFlags[0], // There is always one data item, so get [0].
      },
      [SEND_MAIL_USERS]: {
        ...dataField[SEND_MAIL_USERS],
        value: sendMailUsers[0], // There is always one data item, so get [0].
      },
    };
  }
  return {
    ...dataField,
    [DOMAIN_NAME]: {
      ...dataField[DOMAIN_NAME],
      value: domainNames,
    },
    [ETLD1]: {
      ...dataField[ETLD1],
      value: etld1Values,
    },
  };
};
