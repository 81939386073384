import intersectionWith from 'lodash/intersectionWith';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import memoize from 'lodash/memoize';
import priorityAxisService from 'services/common/priorityAxisService';
import { AD_MANAGEMENT } from 'services/routes/constants';
import * as FIELD from './fields';
import {
  COST_LINKING_EMPTY_DISPLAY_VALUE,
  COST_LINKING_EMPTY_IDS,
  UNREGISTERED_DISPLAY_VALUE,
  UNREGISTERED_IDS,
  FIELD_PREFIX_CONTACT,
  CHANNEL_TYPE_ID,
  PERCENTAGE_FIELDS,
  CVR_FIELD_PREFIX,
  NEW_CVR_FIELD_PREFIX,
  HYPHEN_FIELDS,
  PRIORITY_AXIS_MEDIA_SYNC,
  MEDIA_SIDE_ITEMS,
  UNSYNCED_DISPLAY_VALUE,
  MEDIA_SIDE_ITEMS_AD_MANAGEMENT,
  PRIORITY_AXIS_EBIS,
  FIELD_PREFIX,
} from './consts';

const percentConverter = memoize((item) => {
  return Object.keys(item)
    .filter(
      (field) =>
        !isNaN(+item[field]) &&
        !(
          (item[field] === null || item[field] === '') &&
          HYPHEN_FIELDS.includes(field)
        ) &&
        (PERCENTAGE_FIELDS.includes(field) ||
          field.startsWith(CVR_FIELD_PREFIX) ||
          field.startsWith(NEW_CVR_FIELD_PREFIX) ||
          field.startsWith(FIELD_PREFIX.CNT_CVR_CONTRIBUTE)) &&
        field !== FIELD.TRANSITION_RATE

    )
    .reduce((acc, field) => {
      const obj = {
        ...acc,
        [field]: (item[field] * 100).toFixed(2).toString(),
      };
      return obj;
    }, {});
});

export const convertDimensionNameForSpecialCases = (id, name, sync = false) => {
  if (UNREGISTERED_IDS.includes(id)) {
    return UNREGISTERED_DISPLAY_VALUE;
  }
  if (COST_LINKING_EMPTY_IDS.includes(id)) {
    return COST_LINKING_EMPTY_DISPLAY_VALUE;
  }
  if (typeof id === 'string' && sync) {
    if (id === '') {
      return UNSYNCED_DISPLAY_VALUE;
    }
  }
  return name;
};

export const convertValueDependOnAxis = (column, value, priorityAxis) => {
  const axis = priorityAxisService.getAxisNameByAxisNumber(priorityAxis);
  if (axis === PRIORITY_AXIS_MEDIA_SYNC) {
    if ([FIELD.OPTIONAL_AD_ID].includes(column)) {
      return !value ? UNREGISTERED_DISPLAY_VALUE : value;
    }
  }
  return value;
};

const getContactGroup = memoize((item) =>
  intersectionWith(
    Object.keys(FIELD_PREFIX_CONTACT),
    Object.keys(item),
    (prefix, field) => field.startsWith(prefix)
  )
);

export const isContactMediaSideItem = (metrics) => {
  return MEDIA_SIDE_ITEMS.some((field) => metrics.endsWith(field));
};

export const getContactAxisName = (metrics) => {
  if (!isContactMediaSideItem(metrics)) {
    return {};
  }

  const contactItem = Object.keys(FIELD_PREFIX_CONTACT).filter((field) =>
    metrics.startsWith(field)
  );

  if (contactItem.length !== 1) {
    return {};
  }

  return {
    priorityAxis: `${contactItem[0]}_priority_axis`,
    channel: `${contactItem[0]}_channel`,
  };
};

export const convertMediaSideItemsMetaData = (pageId, axis, metrics, data) => {
  if (pageId === AD_MANAGEMENT) {
    const { nm_linkage_flag: nmLinkageFlag = 'f' } = data;
    if (
      nmLinkageFlag === 't' &&
      MEDIA_SIDE_ITEMS_AD_MANAGEMENT.includes(metrics)
    ) {
      return {
        displayAxis: PRIORITY_AXIS_MEDIA_SYNC,
        ebisViewPattern: '0',
        syncViewPattern: '0',
      };
    }
    return {
      displayAxis: '',
      ebisViewPattern: '0',
      syncViewPattern: '0',
    };
  }

  const {
    ebis_view_pattern: ebisViewPattern = '0',
    sync_view_pattern: syncViewPattern = '0',
    priority_axis: reportViewAxis = '0',
    channel = '10',
  } = data;

  let displayAxis = MEDIA_SIDE_ITEMS.includes(metrics) ? reportViewAxis : '0';

  const {
    priorityAxis: contactViewAxisName,
    channel: contactChannelName = '10',
  } = getContactAxisName(metrics);

  if (contactViewAxisName !== '' && contactViewAxisName in data) {
    displayAxis = data[contactViewAxisName];
  }

  if (
    axis === PRIORITY_AXIS_MEDIA_SYNC &&
    (MEDIA_SIDE_ITEMS.includes(metrics) || isContactMediaSideItem(metrics))
  ) {
    displayAxis = priorityAxisService.getAxisNameByAxisNumber(displayAxis);
  }

  if (
    (contactChannelName in data &&
      data[contactChannelName] !== CHANNEL_TYPE_ID.CLICK) ||
    channel !== CHANNEL_TYPE_ID.CLICK
  ) {
    displayAxis = '';
  }

  if (displayAxis !== PRIORITY_AXIS_EBIS) {
    displayAxis = '';
  }

  return {
    displayAxis,
    ebisViewPattern,
    syncViewPattern,
  };
};

export const convertApiDetail = (item, converters) => {
  const convertedDetail = { ...item, ...percentConverter(item) };
  const contactGroups = getContactGroup(item);

  if (Object.prototype.hasOwnProperty.call(item, FIELD.CATEGORY)) {
    const categoryId = convertedDetail[FIELD.CATEGORY_ID];

    convertedDetail[FIELD.CATEGORY] = convertDimensionNameForSpecialCases(
      categoryId,
      item.category,
      false
    );
  }

  if (Object.prototype.hasOwnProperty.call(item, FIELD.MEDIA_ID)) {
    convertedDetail[FIELD.MEDIA_NAME] = convertDimensionNameForSpecialCases(
      item[FIELD.MEDIA_ID],
      item[FIELD.MEDIA_NAME],
      false
    );
  }

  if (Object.prototype.hasOwnProperty.call(item, FIELD.AD_GROUP1_ID)) {
    const adGroup1Name = convertDimensionNameForSpecialCases(
      item[FIELD.AD_GROUP1_ID],
      item[FIELD.AD_GROUP1_NAME],
      false
    );
    convertedDetail[FIELD.AD_GROUP1_NAME] = adGroup1Name;
  }

  if (Object.prototype.hasOwnProperty.call(item, FIELD.AD_GROUP2_ID)) {
    const adGroup2Name = convertDimensionNameForSpecialCases(
      item[FIELD.AD_GROUP2_ID],
      item[FIELD.AD_GROUP2_NAME],
      false
    );
    convertedDetail[FIELD.AD_GROUP2_NAME] = adGroup2Name;
  }

  if (
    Object.prototype.hasOwnProperty.call(item, FIELD.MEDIA_SIDE_CAMPAIGN_ID)
  ) {
    const campaignName = convertDimensionNameForSpecialCases(
      item[FIELD.MEDIA_SIDE_CAMPAIGN_ID],
      item[FIELD.MEDIA_SIDE_CAMPAIGN_NAME],
      true
    );
    convertedDetail[FIELD.MEDIA_SIDE_CAMPAIGN_NAME] = campaignName;
  }

  if (Object.prototype.hasOwnProperty.call(item, FIELD.MEDIA_SIDE_GROUP_ID)) {
    const groupName = convertDimensionNameForSpecialCases(
      item[FIELD.MEDIA_SIDE_GROUP_ID],
      item[FIELD.MEDIA_SIDE_GROUP_NAME],
      true
    );
    convertedDetail[FIELD.MEDIA_SIDE_GROUP_NAME] = groupName;
  }

  if (Object.prototype.hasOwnProperty.call(item, FIELD.SYNC_CATEGORY)) {
    const syncCategoryId = convertedDetail[FIELD.SYNC_CATEGORY_ID];

    convertedDetail[FIELD.SYNC_CATEGORY] = convertDimensionNameForSpecialCases(
      syncCategoryId,
      item.sync_category,
      true
    );
  }

  if (Object.prototype.hasOwnProperty.call(item, FIELD.MEDIA_ACCOUNT_ID)) {
    const mediaAccountName = convertDimensionNameForSpecialCases(
      item[FIELD.MEDIA_ACCOUNT_ID],
      item[FIELD.MEDIA_ACCOUNT_NAME],
      true
    );
    convertedDetail[FIELD.MEDIA_ACCOUNT_NAME] = mediaAccountName;
  }

  if (Object.prototype.hasOwnProperty.call(item, FIELD.OPTIONAL_AD_ID)) {
    const adId = convertValueDependOnAxis(
      FIELD.OPTIONAL_AD_ID,
      item[FIELD.OPTIONAL_AD_ID],
      item[FIELD.PRIORITY_AXIS]
    );
    convertedDetail[FIELD.OPTIONAL_AD_ID] = adId;
  }

  if (Object.prototype.hasOwnProperty.call(item, FIELD.CONTENT_CATEGORY_ID)) {
    const contentCategoryName = convertDimensionNameForSpecialCases(
      item[FIELD.CONTENT_CATEGORY_ID],
      item[FIELD.CONTENT_CATEGORY_NAME],
      false
    );
    convertedDetail[FIELD.CONTENT_CATEGORY_NAME] = contentCategoryName;
  }

  if (!isEmpty(contactGroups)) {
    contactGroups.forEach((prefix) => {
      const { CLICK, SITE_CONTENT } = CHANNEL_TYPE_ID;
      const channel = convertedDetail[`${prefix}_${FIELD.CHANNEL}`];
      if (channel) {
        [
          FIELD.CATEGORY,
          FIELD.AD_GROUP1,
          FIELD.AD_GROUP2,
          FIELD.CONTENT_CATEGORY,
          FIELD.SYNC_CATEGORY,
          FIELD.MEDIA_SIDE_CAMPAIGN,
          FIELD.MEDIA_SIDE_GROUP,
        ].forEach((field) => {
          let isUnregistered = false;
          const isInvalidValue = !convertedDetail[`${prefix}_${field}`];
          const contactPriorityAxis =
            convertedDetail[`${prefix}_${FIELD.PRIORITY_AXIS}`];

          const fetchableColumn = [
            FIELD.SYNC_CATEGORY,
            FIELD.MEDIA_SIDE_CAMPAIGN,
            FIELD.MEDIA_SIDE_GROUP,
          ];

          switch (field) {
            case FIELD.CATEGORY:
            case FIELD.SYNC_CATEGORY:
              if (isInvalidValue && [CLICK, SITE_CONTENT].includes(channel)) {
                isUnregistered = true;
              }
              break;

            case FIELD.AD_GROUP1:
            case FIELD.AD_GROUP2:
            case FIELD.MEDIA_SIDE_CAMPAIGN:
            case FIELD.MEDIA_SIDE_GROUP:
              if (isInvalidValue && channel === CLICK) {
                isUnregistered = true;
              }
              break;

            case FIELD.CONTENT_CATEGORY:
              if (isInvalidValue) {
                isUnregistered = true;
              }
              break;

            default:
              break;
          }

          if (isUnregistered) {
            if (
              fetchableColumn.includes(field) &&
              priorityAxisService.getAxisNameByAxisNumber(
                contactPriorityAxis
              ) === PRIORITY_AXIS_MEDIA_SYNC
            ) {
              convertedDetail[`${prefix}_${field}`] = UNSYNCED_DISPLAY_VALUE;
            } else if (field !== FIELD.AD_NAME) {
              convertedDetail[
                `${prefix}_${field}`
              ] = UNREGISTERED_DISPLAY_VALUE;
            }
          }
        });
      }
    });
  }

  if (converters) {
    converters.forEach(({ field, converter }) => {
      convertedDetail[field] = converter(convertedDetail[field]);
    });
  }

  return convertedDetail;
};

export const getLinkParams = (data, metric) => {
  if (metric === FIELD.TRACKING_DATA) {
    return {
      record_id: data.cv_record_id,
      tracking_data: data.tracking_data,
    };
  }
  return {};
};

export const convertApiSum = (item) => {
  const convertedSum = { ...item, ...percentConverter(item) };

  return convertedSum;
};

export const dowConverter = (dowValue) => {
  const dow = (parseInt(dowValue, 10) - 1 + 7) % 7;
  return dow;
};

export const convertApiReportResponse = (res, options = {}) => {
  const { converters } = options;
  const sum = res?.data?.data?.sum || {};
  const convertedSum = convertApiSum(sum);

  const details = res?.data?.data?.detail || [];
  const convertedDetail = details.map((detail) =>
    convertApiDetail(detail, converters)
  );

  return {
    ...res,
    data: {
      ...res.data,
      data: {
        ...res.data.data,
        detail: convertedDetail,
        sum: convertedSum,
      },
    },
  };
};

export const convertApiDetailAdManagement = (data) => {
  const { BASE_CPC, BASE_CPCONV, AD_URL } = FIELD;
  const { base_cpc: baseCpc, base_cpconv: baseCpconv, ad_url: adUrls } = data;

  const newData = Object.keys(data).reduce((acc, key) => {
    if (![BASE_CPC, BASE_CPCONV, AD_URL].includes(key)) {
      acc[key] = data[key] ?? '';
    }
    return acc;
  }, {});

  const newDataUrls = adUrls.map((url) => {
    return Object.keys(url).reduce((acc, key) => {
      acc[key] = url[key] ?? '';
      return acc;
    }, {});
  });

  const convertedDetail = {
    ...newData,
    base_cpc: [null, '', '0', 0].includes(baseCpc) ? '' : baseCpc,
    base_cpconv: [null, '', '0', 0].includes(baseCpconv) ? '' : baseCpconv,
    ad_url: newDataUrls,
  };

  return convertedDetail;
};
