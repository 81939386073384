/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const NotificationItem = ({
  title,
  handleOnClickNews,
  date,
  id,
  isExpanded, // change to true false
  detail,
  isNew,
}) => (
  <div
    // Select the id of target notification
    onClick={() => handleOnClickNews(id)}
    className={`notification__content ${isNew ? 'font-weight-bold' : ''}`}
  >
    <div className="d-flex">
      <span>{moment(date).format('YYYY/MM/DD')}</span>
      {isNew && (
        <i className="fas fa-circle icon--unread d-flex align-items-center" />
      )}
    </div>
    <div className={!isExpanded ? 'text--truncate-two-lines' : ''}>
      {title}
      <br />
      <br />
      {/* Load detail of selected item */}
      {isExpanded && <div dangerouslySetInnerHTML={{ __html: detail }} />}
    </div>
  </div>
);

NotificationItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  handleOnClickNews: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  detail: PropTypes.any.isRequired,
  isNew: PropTypes.bool.isRequired,
};

export default React.memo(NotificationItem);
