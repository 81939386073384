import React from 'react';
import { string } from 'prop-types';
import screenTitleConfigs from 'services/common/screenTitleConfigs';

const DummyMainContentContainer = (props) => {
  const { screenId } = props;

  const { dummyMainContent = '' } = screenTitleConfigs[screenId];

  return <>{dummyMainContent}</>;
};

DummyMainContentContainer.propTypes = {
  screenId: string.isRequired,
};

export default DummyMainContentContainer;
