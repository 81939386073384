// import * as DisplayItemsCategoryAnalysis from './display-items-category-analysis';
import * as DisplayItemsDetailAnalysis from './display-items-detail-analysis';
// import * as DisplayItemsPeriodAnalysis from './display-items-period-analysis';
import * as DisplayItemsCvAttributes from './display-items-cv-attributes';
import * as DisplayItemsCvFlow from './display-items-cv-flow';
import * as DisplayItemsLandingPageAnalysis from './display-items-landing-page-analysis';
import * as DisplayItemsInflowAnalysis from './display-items-inflow-analysis';

const DisplayItemsForViewServiceFactory = () => {
  return {
    getDetailAnalysisView: () => DisplayItemsDetailAnalysis.getCommon(),
    getDetailAnalysisViewDefault: () =>
      DisplayItemsDetailAnalysis.getCommonDefault(),
    getCvAttributesView: () => DisplayItemsCvAttributes.getCommon(),
    getCvAttributesViewDefault: () =>
      DisplayItemsCvAttributes.getCommonDefault(),
    getCvFlowView: () => DisplayItemsCvFlow.getCommon(),
    getCvFlowViewDefault: () => DisplayItemsCvFlow.getCommonDefault(),
    getLandingPageAnalysisView: () =>
      DisplayItemsLandingPageAnalysis.getCommon(),
    getLandingPageAnalysisViewDefault: () =>
      DisplayItemsLandingPageAnalysis.getCommonDefault(),
    getLandingPageAnalysisViewAxis: () =>
      DisplayItemsLandingPageAnalysis.getCommonAxis(),
    getInflowAnalysisAd: () => DisplayItemsInflowAnalysis.getAd(),
    getInflowAnalysisAdDefault: () => DisplayItemsInflowAnalysis.getAdDefault(),
    getInflowAnalysisView: () => DisplayItemsInflowAnalysis.getCommon(),
    getInflowAnalysisViewDefault: () =>
      DisplayItemsInflowAnalysis.getCommonDefault(),
  };
};

const DisplayItemsForViewService = DisplayItemsForViewServiceFactory();
export default DisplayItemsForViewService;
