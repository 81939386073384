import { createSelector } from 'reselect';
import moment from 'moment';
import ViewService from 'domain/ViewService';
import FilterService from 'domain/FilterService';
import settingSelectors from 'store/settings/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import { getPermissions } from 'store/auth/selectors';

/* List to display on dropdown menu
[
  {
    id: {int},
    name: {string},
    favorite: {bool}
  },
  ...
] */
function getList(state) {
  const {
    byIds,
    applied: { current: applyViewId, changed: applyChanged },
  } = state.settings.view;
  const list = Object.keys(byIds).map((key) => {
    const { id, view_name: name, status: favorite } = byIds[key];
    return { id, name, favorite };
  });
  const listSorted = list.sort((first, second) => second.id - first.id);
  return listSorted.map((item, index) => {
    const { id, name, favorite } = item;
    let order = index + 2;
    if (favorite) {
      order = 1;
    }
    // skip 0: selected, 1: favorite
    let selected = false;
    if (applyViewId && applyChanged === false && applyViewId === id) {
      selected = true;
      order = 0;
    }
    return {
      id,
      name,
      favorite,
      selected,
      order,
    };
  });
}

const normalizeData = (
  data,
  userPermission,
  pageId,
  currentTab,
  masterData = null
) => {
  if (Object.keys(data).length === 0) {
    return {};
  }
  const {
    id,
    display_items: displayItems,
    filters,
    view_name: name,
    period_start_date: periodStart,
    period_end_date: periodEnd,
    period_preset: periodPreset,
    compare_period_start_date: comparePeriodStart,
    compare_period_end_date: comparePeriodEnd,
    compare_period_preset: comparePeriodPreset,
  } = data;
  const filtersNormalize = FilterService.filterNonContractValues(
    ViewService.filterNormalize(filters, masterData),
    userPermission,
    pageId,
    currentTab
  );
  const period = {
    start: moment(periodStart),
    end: moment(periodEnd),
    preset: periodPreset,
  };
  const comparePeriod = {
    start: comparePeriodStart ? moment(comparePeriodStart) : null,
    end: comparePeriodEnd ? moment(comparePeriodEnd) : null,
    preset: comparePeriodPreset,
  };
  const customFilters = FilterService.prepareForUI(
    filtersNormalize,
    pageId,
    currentTab
  );
  return {
    displayItems,
    name,
    filters: customFilters,
    period,
    comparePeriod,
    id,
  };
};

const getDataEdit = (state) => state.settings.view.edit;

const getEditing = createSelector(
  [
    getDataEdit,
    getPermissions,
    settingSelectors.getPage,
    settingSelectors.getTab,
    masterDataSelectors.getCv,
    masterDataSelectors.getMedia,
    masterDataSelectors.getAdGroup1,
    masterDataSelectors.getAdGroup2,
    masterDataSelectors.getAgency,
    masterDataSelectors.getMediaAccount,
    masterDataSelectors.getMediaSideCampaign,
    masterDataSelectors.getMediaSideGroup,
    masterDataSelectors.getContentCategory,
  ],
  (
    edit,
    permissions,
    pageId,
    currentTab,
    cv,
    media,
    adGroup1,
    adGroup2,
    agency,
    mediaAccount,
    mediaSideCampaign,
    mediaSideGroup,
    contentCategory
  ) =>
    normalizeData(edit, permissions, pageId, currentTab, {
      cv,
      media,
      adGroup1,
      adGroup2,
      agency,
      mediaAccount,
      mediaSideCampaign,
      mediaSideGroup,
      contentCategory,
    })
);

const getApplied = (state) => {
  const {
    byIds,
    applied: { current, changed, byFunc },
  } = state.settings.view;

  const favorites = Object.keys(byIds).filter((key) => byIds[key].status);

  let viewId = current;
  // only one faverite
  if (favorites.length > 0) {
    viewId = parseInt(favorites[0], 10);
  }

  const funcId = settingSelectors.getFuncId(state);
  if (
    viewId &&
    funcId in byFunc &&
    byFunc[funcId] === false &&
    changed === false
  ) {
    return viewId;
  }
  return undefined;
};

const getStatus = (state) => {
  return state.settings.view.status;
};

// for CustomViewModal
const getStatusForView = (state) => {
  return state.settings.view.statusForView;
};

// for CustomViewModal
const getLoadedViewTab = (state) => {
  return state.settings.view.isLoadedViewTab;
};

const getErrors = (state) => {
  return state.settings.view.error;
};

const getCurrentAppliedInfo = (state) => state.settings.view.applied;

const getListByIds = (state) => state.settings.view.byIds;

const getById = (viewListById, id) => {
  if (typeof viewListById !== 'object' || typeof id !== 'number') return null;

  return viewListById[id] || null;
};

const getFavoriteView = (viewListById) => {
  if (typeof viewListById !== 'object') return null;

  return Object.values(viewListById).find((item) => item.status) || null;
};

const getPriorityAxis = (state) => state.settings.view.priorityAxis;

export default {
  getList,
  getById,
  getListByIds,
  getEditing,
  getApplied,
  getStatus,
  getStatusForView,
  getLoadedViewTab,
  getErrors,
  getCurrentAppliedInfo,
  getFavoriteView,
  getPriorityAxis,
};
