import screenPermissionConfigs, {
  redirectScreenConfigs,
} from 'domain/permissions/screenPermissionConfigs';

import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';
import useCheckPermissionSearchConsole from 'services/custom-hooks/useCheckPermissionSC';
import useCheckPermissionLineAddFriend from 'services/custom-hooks/useCheckPermissionLineAddFriend';
import {
  CAPI_SETTING,
  MEDIA_SYNC_MANAGEMENT,
  SEARCH_CONSOLE_SETTING,
  LINE_ADD_FRIEND_SETTINGS,
} from 'services/routes/constants';
import useCheckPermissionMediaSync from './useCheckPermissionMediaSync';

const useAllowScreenOrder = () => {
  const { checkPermissions } = useCheckPermissions();
  const {
    isCapiSingleContract,
    isCapiSettingPermissionOnly,
  } = useCheckPermissionCapi();
  const { isSingleMediaSyncPermission } = useCheckPermissionMediaSync();
  const {
    isHaveOnlySearchConsolePermission,
  } = useCheckPermissionSearchConsole();

  const {
    isLineAddFriendSettingPermissionOnly,
  } = useCheckPermissionLineAddFriend();
  // Find the first allow screen when user enter page
  let firstAllowedScreen = redirectScreenConfigs.find((screen) => {
    const { screenId, tab, resolveType = 'anyOf' } = screen;
    const type = tab || 'entry';

    if (
      checkPermissions(resolveType, screenPermissionConfigs[screenId][type])
    ) {
      return true;
    }
    return false;
  });

  // if account is capi single contract or capi setting permission only will be redirect to capi setting list
  if (isCapiSingleContract || isCapiSettingPermissionOnly) {
    firstAllowedScreen = { screenId: CAPI_SETTING, tab: null };
  }

  // if account only have permission media sync management => will be redirect to media sync management screen
  if (isSingleMediaSyncPermission) {
    firstAllowedScreen = { screenId: MEDIA_SYNC_MANAGEMENT, tab: null };
  }

  // if account only have permission search console setting => will be redirect to search console setting screen
  if (isHaveOnlySearchConsolePermission) {
    firstAllowedScreen = { screenId: SEARCH_CONSOLE_SETTING, tab: null };
  }

  // if account only has line add friend setting permission will be redirect to line add friend setting list
  if (isLineAddFriendSettingPermissionOnly) {
    firstAllowedScreen = { screenId: LINE_ADD_FRIEND_SETTINGS, tab: null };
  }

  if (firstAllowedScreen === undefined) {
    window.location = process.env.REACT_APP_HOTEI_HOST;
  }

  return { firstAllowedScreen };
};

export default useAllowScreenOrder;
