/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useRef, createRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Overlay, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { TAB_AD, TAB_ALL } from 'services/consts';
import NavbarConfig from 'domain/commonbar/NavbarConfig';
import screenPermissionConfigs, {
  phase1AdPermissions,
  phase1AllPermissions,
} from 'domain/permissions/screenPermissionConfigs';
import {
  DASHBOARD,
  COST_ALLOCATION,
  LPO_EBIS,
  LOG_EBIS,
  USER_ANALYSIS,
  USER_PROFILE,
  CV_ATTRIBUTE,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  LOG_ROUTE_ANALYZE,
  LPO_LINK,
  LPO_PERIOD,
} from 'services/routes/constants';

import settingsSelector from 'store/settings/selectors';

import MenuListItem from 'views/molecules/navbar/component/MenuListItem';
import PermissionWrapperRPC from 'services/appconfig/PermissionWrapperRPC';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';

import 'views/molecules/navbar/navbar.scss';

const LIST_PAGE_DISABLED_TAB = {
  [TAB_AD]: [
    USER_PROFILE,
    LOG_PAGE_ANALYZE,
    LOG_PERIOD_ANALYZE,
    LOG_ROUTE_ANALYZE,
  ],
  [TAB_ALL]: [COST_ALLOCATION, LPO_LINK, LPO_PERIOD],
};

const SubMenuList = ({ menuItems }) => {
  const { t } = useTranslation();

  return (
    <ul className="menu-item-sub">
      {menuItems.map((item) => (
        <li className="menu-item" key={item.title}>
          <Link to="#" className="menu-text">
            {t(item.title)}
          </Link>
        </li>
      ))}
    </ul>
  );
};

SubMenuList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  menuItems: PropTypes.array.isRequired,
};

// config reserved for submenus
// eslint-disable-next-line no-unused-vars
const MenuList = ({ menuItems, config, tab }) => {
  const screenId = useSelector(
    (state) => settingsSelector.getPage(state),
    shallowEqual
  );
  const menuNavRef = useRef(null);

  // Handle hover
  const [hoverItem, changeHoverItem] = useState('');

  const handleHoverCallback = useCallback((key) => {
    changeHoverItem(key);
  }, []);

  const handleClickMenuItem = () => {
    const elementNav = menuNavRef.current;
    elementNav.scrollTop = elementNav.clientHeight;
  };

  return (
    <ul className="menu-nav" ref={menuNavRef}>
      {menuItems.map((item) => (
        <PermissionWrapperRPC
          key={item.screenId}
          requiredPermissions={screenPermissionConfigs[item.screenId][tab]}
          resolveType="anyOf"
          screenId={item.screenId}
          render={(isAllowed) => {
            const screenIds = isEmpty(item.submenu)
              ? [item.screenId]
              : item.submenu.map((submenu) => submenu.screenId);

            const isActive =
              screenIds.includes(screenId) ||
              (screenId.startsWith('ltv') && item.screenId.startsWith('ltv')) ||
              (screenId === USER_PROFILE && item.screenId === CV_ATTRIBUTE);

            return (
              <MenuListItem
                key={item.title}
                screenId={screenId}
                item={item}
                active={isActive}
                changeHoverItem={isAllowed ? handleHoverCallback : () => {}}
                tab={tab}
                activeHoverItem={item.title === hoverItem}
                isDisabled={!isAllowed}
                onClickMenuItem={handleClickMenuItem}
              />
            );
          }}
        />
      ))}
    </ul>
  );
};

MenuList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  menuItems: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
};

const NavBar = ({ changeTabType, currentTab, pageId }) => {
  const { itemsLeft, itemsRight, self } = NavbarConfig.aside || false;

  const { checkPermissions } = useCheckPermissions();
  const [showMessage, toggleShowMessage] = useState(false);

  const dasboardPermissions = screenPermissionConfigs[DASHBOARD];
  const costAllocationPermissions = screenPermissionConfigs[COST_ALLOCATION];
  const LPOPermissions = screenPermissionConfigs[LPO_EBIS];

  const checkPageDisabledOnTabAd = LIST_PAGE_DISABLED_TAB[TAB_AD].includes(
    pageId
  );
  const isAllowedAd =
    checkPermissions('anyOf', [
      ...phase1AdPermissions,
      ...dasboardPermissions[TAB_AD],
      ...LPOPermissions[TAB_AD],
      ...dasboardPermissions[TAB_AD],
      ...costAllocationPermissions[TAB_AD],
    ]) && !checkPageDisabledOnTabAd;

  const LOGPermissions = screenPermissionConfigs[LOG_EBIS];
  const UserAnalysisPermissions = screenPermissionConfigs[USER_ANALYSIS];
  const checkPageDisabledOnTabAll = LIST_PAGE_DISABLED_TAB[TAB_ALL].includes(
    pageId
  );
  const isAllowedAll =
    checkPermissions('anyOf', [
      ...phase1AllPermissions,
      ...dasboardPermissions[TAB_ALL],
      ...LOGPermissions[TAB_ALL],
      ...UserAnalysisPermissions[TAB_ALL],
    ]) && !checkPageDisabledOnTabAll;

  const tabItems = [
    {
      tabKey: TAB_AD,
      tabName: '広告',
      menuItems: itemsLeft,
      isAllowedThisTab: isAllowedAd,
      className: 'left',
      disabledTooltip: {
        message: 'この画面は「全トラフィック」でのみ表示できます。',
        targetTab: TAB_ALL,
      },
    },
    {
      tabKey: TAB_ALL,
      tabName: '全トラフィック',
      menuItems: itemsRight,
      isAllowedThisTab: isAllowedAll,
      className: 'right',
      disabledTooltip: {
        message: 'この画面は「広告」でのみ表示できます。',
        targetTab: TAB_AD,
      },
    },
  ];
  const messageTargets = useRef([]);
  tabItems.forEach((item) => {
    messageTargets.current[item.tabKey] = createRef();
  });

  // INFO: cannot wrap this into PermissioWrapperRPC because Tabs -> Tab do not render
  return (
    <div id="navbar" className="menu-wrapper">
      <Tabs
        onSelect={(key) => changeTabType(key)}
        activeKey={currentTab}
        transition={false}
      >
        {tabItems.map((item) => {
          return (
            <Tab
              key={item.tabKey}
              eventKey={item.tabKey}
              className={item.className}
              title={
                <>
                  <div
                    onMouseEnter={() => {
                      if (currentTab === TAB_AD && !checkPageDisabledOnTabAll) {
                        return;
                      }
                      toggleShowMessage(true);
                    }}
                    onMouseLeave={() => toggleShowMessage(false)}
                    ref={messageTargets.current[item.disabledTooltip.targetTab]}
                  >
                    {item.tabName}
                  </div>
                  <Overlay
                    target={messageTargets.current[currentTab]}
                    show={showMessage && !item.isAllowedThisTab}
                    placement="bottom"
                  >
                    <Tooltip variant="disabled" id={`tooltip-${item.tabKey}`}>
                      {item.disabledTooltip.message}
                    </Tooltip>
                  </Overlay>
                </>
              }
              disabled={!item.isAllowedThisTab}
              tabClassName={!item.isAllowedThisTab ? 'tab--disabled' : ''}
            >
              {item.menuItems && (
                <MenuList
                  menuItems={item.menuItems}
                  config={self}
                  tab={item.tabKey}
                />
              )}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

NavBar.propTypes = {
  changeTabType: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default React.memo(NavBar);
