import iconFaceGood from 'assets/images/icon-face-good-16x11.svg';
import iconFaceNormal from 'assets/images/icon-face-normal-16x10.svg';
import iconFaceBad from 'assets/images/icon-face-bad-18x15.svg';

const CHART_LINE = 'CHART_LINE';
const CHART_COLUMN = 'CHART_COLUMN';

const REQUIRED_CHECK = 'REQUIRED_CHECK';
const NUMBER_CHECK = 'NUMBER_CHECK';
const EXIST_CHECK = 'EXIST_CHECK';
const MAX_CHECK = 'MAX_CHECK';

const COMPARE_MODE_TARGET_VALUE = 1;
const COMPARE_MODE_LAST_MONTH_VALUE = 2;
const COMPARE_MODE_SAME_MONTH_LAST_YEAR_VALUE = 3;

// Not setting kpi period of dashboard
const NOT_SETTING_KPI_PERIOD = 0;

const KPI_STATUS_DEFAULT = { bad: 70, normal: 90, good: 91 };
const KPI_STATUS_DEFAULT_CPA = { good: 70, normal: 90, bad: 91 };
const KPI_STATUS_INFO = {
  good: {
    text: 'Good',
    status: 'good',
    class: 'kpi-item--good',
    image: iconFaceGood,
  },
  normal: {
    text: 'Normal',
    status: 'normal',
    class: 'kpi-item--normal',
    image: iconFaceNormal,
  },
  bad: {
    text: 'Bad',
    status: 'bad',
    class: 'kpi-item--bad',
    image: iconFaceBad,
  },
};

export {
  CHART_LINE,
  CHART_COLUMN,
  REQUIRED_CHECK,
  NUMBER_CHECK,
  EXIST_CHECK,
  MAX_CHECK,
  KPI_STATUS_INFO,
  KPI_STATUS_DEFAULT,
  KPI_STATUS_DEFAULT_CPA,
  COMPARE_MODE_TARGET_VALUE,
  COMPARE_MODE_LAST_MONTH_VALUE,
  COMPARE_MODE_SAME_MONTH_LAST_YEAR_VALUE,
  NOT_SETTING_KPI_PERIOD,
};
