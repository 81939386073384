import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { bool, func, number, oneOfType, shape, string, node } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import useHoverTooltip from 'services/custom-hooks/useHoverTooltip';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import useClickOutside from 'services/custom-hooks/useClickOutside';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';

import './SelectPopup.scss';

const SelectPopupSubmenu = ({ options, show, top, left, onHide, onChange }) => {
  const clickRef = useRef();
  const onClickOutside = () => {
    if (show) {
      onHide();
    }
  };
  useClickOutside(clickRef, onClickOutside);

  return (
    <div
      className="select-popup__submenu"
      style={{
        display: show ? 'block' : 'none',
        top,
        left,
        position: 'fixed',
      }}
      ref={clickRef}
    >
      {options.map((option) => (
        <div
          key={option.value}
          className="select-popup__submenu-item"
          onClick={() => onChange(option.value)}
          aria-hidden
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

SelectPopupSubmenu.propTypes = {
  options: shape([]).isRequired,
  show: bool.isRequired,
  top: number.isRequired,
  left: number.isRequired,
  onChange: func.isRequired,
  onHide: func.isRequired,
};

const SelectPopupMenuItem = ({ value, selected, order, onClick }) => {
  const tooltipRef = useRef();

  const itemClass = classNames('select-popup__item', {
    'select-popup__item--selected': selected,
  });

  useHoverTooltip(tooltipRef);

  return (
    <div
      aria-hidden
      role="menuitem"
      tabIndex="0"
      ref={tooltipRef}
      className={itemClass}
      style={{ order }}
      onClick={onClick}
    >
      {value}
    </div>
  );
};

SelectPopupMenuItem.propTypes = {
  value: string.isRequired,
  selected: bool.isRequired,
  order: number.isRequired,
  onClick: func.isRequired,
};

const SelectPopupMenu = ({
  loading,
  title,
  options,
  value,
  onChange,
  onSearch,
  onHide,
  show,
  top,
  left,
  mapperKey,
  limitDisplay,
}) => {
  const keyLabel = mapperKey.label;
  const keyValue = mapperKey.value;
  const totalItems = Object.keys(options).length;

  const [keyword, setKeyword] = useState();
  const scrollbarWrapperRef = useRef(null);
  const refInput = useRef(null);
  const clickRef = useRef();
  const onClickOutside = () => {
    if (show) {
      onHide();
    }
  };
  useClickOutside(clickRef, onClickOutside);

  const doSearch = debounce((query) => {
    onSearch(query);
  }, 200);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setKeyword(query);
    doSearch(query);
  };

  const handleChange = (option) => {
    onChange(option);
    onHide();
  };

  useEffect(() => {
    if (show) {
      refInput.current.focus();
    } else {
      setKeyword('');
      doSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div
      className="select-popup__content"
      style={{
        display: show ? 'block' : 'none',
        top,
        left,
        position: 'fixed',
      }}
      ref={clickRef}
    >
      <div className="select-popup__title">{title}</div>
      <div className="select-popup__body">
        <div className="select-popup__search-group">
          <input
            ref={refInput}
            placeholder="値を入力"
            value={keyword}
            type="text"
            className="select-popup__search-input"
            onChange={handleSearchChange}
          />
          <i className="select-popup__search-icon fa fa-search" />
        </div>
        <DataSyncLoader isLoading={loading}>
          <div className="select-popup__item-list" ref={scrollbarWrapperRef}>
            <ScrollbarWrapper
              ref={scrollbarWrapperRef}
              maxContent={160}
              sizeScroll={5}
              alignScroll={5}
            >
              <div className="select-popup__item-list--order">
                {Object.keys(options).map((key, index) => {
                  const option = options[key];
                  return (
                    <SelectPopupMenuItem
                      key={option[keyValue]}
                      value={option[keyLabel]}
                      selected={value === key}
                      order={option?.order ?? index + 1}
                      onClick={() => handleChange(option)}
                    />
                  );
                })}
              </div>
              {totalItems > limitDisplay && (
                <div className="select-popup__item-list--warning">
                  件数が多いため、{limitDisplay}件まで表示しています。
                  <br />
                  検索機能をご活用ください。
                </div>
              )}
            </ScrollbarWrapper>
          </div>
          {totalItems === 0 && (
            <div className="select-popup__empty">
              {`設定できる${title}がありません`}
            </div>
          )}
        </DataSyncLoader>
      </div>
    </div>
  );
};

SelectPopupMenu.propTypes = {
  loading: bool.isRequired,
  title: string.isRequired,
  options: shape({}).isRequired,
  mapperKey: shape({}).isRequired,
  limitDisplay: number.isRequired,
  value: number,
  top: number,
  left: number,
  show: bool,
  onChange: func,
  onSearch: func,
  onHide: func,
};

SelectPopupMenu.defaultProps = {
  value: undefined,
  show: false,
  top: 20,
  left: 0,
  onChange: () => {},
  onSearch: () => {},
  onHide: () => {},
};

function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  return {
    top: rect.top + rect.height,
    left: rect.left + scrollLeft,
    width: rect.width,
    height: rect.height,
  };
}

const SelectPopupSingle = ({
  loading,
  isShow,
  value,
  valueTootip,
  title,
  options,
  onChange,
  onSearch,
  error,
  mapperKey,
  limitDisplay,
  submenu,
  onChangeSubmenu,
}) => {
  const keySelected = mapperKey.selected || mapperKey.label;

  const [state, updateState] = useState({
    position: { top: 0, left: 0, width: 0, height: 0 },
    show: false,
    showSubmenu: false,
  });
  const [item, setItem] = useState(null);
  const [errorStr, changeErrorStr] = useState('');
  const tooltipErrorRef = useRef();
  const tooltipSelectedRef = useRef();
  const buttonRef = useRef();

  const selectedValue = !isEmpty(item);

  const clsSelectPopup = classNames({
    'select-popup': true,
    'select-popup--single': true,
    'select-popup--selected-value': selectedValue,
  });

  const handleChange = (val) => {
    changeErrorStr('');
    onChange(val);
    setItem(val);
    updateState((prev) => {
      return { ...prev, show: false };
    });
  };

  const handleClear = () => {
    if (item) {
      onChange(null);
      setItem(null);
    }
  };
  const handleClickChoice = (e) => {
    const position = offset(e.target);
    if (submenu) {
      updateState({ position, showSubmenu: true });
    } else {
      updateState({ position, show: true });
    }
  };
  const handleHide = () => {
    updateState((prev) => {
      return { ...prev, show: false };
    });
  };

  const handleHideSubmenu = () => {
    updateState((prev) => {
      return { ...prev, showSubmenu: false };
    });
  };

  const handleChangeSubmenu = (val) => {
    onChangeSubmenu(val);
    updateState((prev) => {
      return { ...prev, show: true, showSubmenu: false };
    });
  };

  useEffect(() => {
    changeErrorStr(error?.message || '');
  }, [error]);

  useEffect(() => {
    setItem(value);
  }, [value]);

  // Calculate position display when browser resizing
  useEffect(() => {
    if (!state.show) return () => {};

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        updateState({ position: offset(buttonRef.current), show: true });
      });
    });

    const htmlEl = document.getElementsByTagName('html')[0];
    resizeObserver.observe(htmlEl);

    return () => {
      resizeObserver.unobserve(htmlEl);
    };
  }, [buttonRef, state.show]);

  useHoverTooltip(tooltipSelectedRef, valueTootip);

  return (
    <div className={clsSelectPopup} ref={tooltipErrorRef}>
      <div className="select-popup__actions">
        {selectedValue && (
          <div className="select-popup__value" ref={tooltipSelectedRef}>
            {item[keySelected]}
          </div>
        )}
        {isShow && (
          <>
            <ErrorTooltipWrapper
              isError={errorStr !== ''}
              errorMess={errorStr}
              ref={tooltipErrorRef}
            >
              <Button
                variant="link"
                size="sm"
                className="select-popup__choice"
                onClick={handleClickChoice}
                ref={buttonRef}
              >
                {item ? '変更' : '選択'}
              </Button>
            </ErrorTooltipWrapper>
            <Button
              variant="link"
              size="sm"
              className="p-0"
              disabled={!item}
              onClick={handleClear}
            >
              クリア
            </Button>
          </>
        )}
      </div>
      {isShow && (
        <>
          {submenu && (
            <SelectPopupSubmenu
              options={submenu}
              show={state.showSubmenu}
              top={state.position.top}
              left={state.position.left}
              onHide={handleHideSubmenu}
              onChange={handleChangeSubmenu}
            />
          )}
          <SelectPopupMenu
            loading={loading}
            title={title}
            options={options}
            onChange={handleChange}
            onSearch={onSearch}
            onHide={handleHide}
            show={state.show}
            top={state.position.top}
            left={state.position.left}
            mapperKey={mapperKey}
            limitDisplay={limitDisplay}
          />
        </>
      )}
    </div>
  );
};

/*
options = {
  '1': { value: '1', label: '洗顔' },
  '2': { value: '2', label: '化粧水' },
};
value = { value: '1', label: '洗顔' }
onChange = ({ value: '1', label: '洗顔' })
onSearch = ('word')
*/
SelectPopupSingle.propTypes = {
  loading: bool,
  isShow: bool,
  title: string.isRequired,
  value: shape({}),
  options: shape({}).isRequired,
  onChange: func,
  onSearch: func,
  onChangeSubmenu: func,
  submenu: oneOfType([shape({}), bool]),
  error: shape({}),
  mapperKey: shape({}),
  limitDisplay: number,
  valueTootip: oneOfType([string, node, bool]),
};
SelectPopupSingle.defaultProps = {
  loading: false,
  isShow: true,
  value: {},
  onChange: () => {},
  onSearch: () => {},
  onChangeSubmenu: () => {},
  submenu: false,
  error: { result: true, message: '' },
  mapperKey: { value: 'value', label: 'label', selected: 'label' },
  limitDisplay: undefined,
  valueTootip: false,
};

export default SelectPopupSingle;
