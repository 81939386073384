import * as DisplayItems from 'domain/settings/display-items';
import helper from 'store/helper';
import types from 'store/ltv/analyze/types';

import {
  communicationStatus,
  timezoneFormat,
  getStartEndDate,
} from 'services/utils';
import { DATE_RANGE_TYPE } from 'domain/consts';
import logging from 'domain/logging';

const { IDLE, LOADING, SUCCEEDED, FAILED } = communicationStatus;
const FIRST_PAGE = 1;

const initialState = {
  table: {
    data: {
      metadata: {},
      list: [],
      sum: {},
      detail: {
        account_str: null,
        last_order_date: null,
        analyzed_date: null,
      },
    },
    settings: {
      targetPeriod: null,
      showKpiStatusModal: false,
      period: {
        start: null,
        end: null,
        preset: DATE_RANGE_TYPE.TODAY,
      },
      sort: {
        field: DisplayItems.CNT_CV_TOTAL,
        direction: DisplayItems.SORT_DIRECTION_DESC,
      },
      pagination: {
        currentPage: FIRST_PAGE,
      },
      selectedRows: [],
    },
    status: IDLE,
    error: null,
  },
  status: IDLE,
  error: null,
  apiStatus: {
    loading: false,
    error: false,
    metadata: {
      status: IDLE,
      errors: null,
    },
    pickup: {
      status: IDLE,
      errors: null,
    },
    border: {
      status: IDLE,
      errors: null,
    },
    chart: {
      status: IDLE,
      errors: null,
    },
    report: {
      status: IDLE,
      errors: null,
    },
    status: {
      status: IDLE,
      errors: null,
    },
  },
};

const requestStart = (state) => {
  return {
    ...state,
    status: LOADING,
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: FAILED,
    error,
  };
};

const fetchReportSucceeded = (state, action) => {
  const { data, metadata } = action.payload;
  return {
    ...state,
    table: {
      ...state.table,
      data: {
        ...state.table.data,
        list: data.detail,
        sum: data.sum,
      },
      settings: {
        ...state.table.settings,
        pagination: {
          ...state.table.settings.pagination,
          totalItems: metadata.count,
          itemsPerPage: metadata.limit,
        },
        predictionMetrics: metadata.prediction_columns,
      },
      status: SUCCEEDED,
      error: null,
    },
    status: SUCCEEDED,
  };
};

const updatePage = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    table: {
      ...state.table,
      settings: {
        ...state.table.settings,
        pagination: {
          ...state.table.settings.pagination,
          currentPage: page,
        },
      },
      status: LOADING,
    },
  };
};

const updateSort = (state, action) => {
  const { field, direction } = action.payload;
  return {
    ...state,
    table: {
      ...state.table,
      settings: {
        ...state.table.settings,
        sort: {
          ...state.table.settings.sort,
          field,
          direction,
        },
        pagination: {
          ...state.table.settings.pagination,
          currentPage: FIRST_PAGE,
        },
      },
      status: LOADING,
    },
  };
};

// 期間選択
const changeSelectedTarget = (state, action) => {
  const { start, end } = getStartEndDate(action.payload);
  return {
    ...state,
    table: {
      ...state.table,
      settings: {
        ...state.table.settings,
        targetPeriod: action.payload,
        period: {
          ...state.table.settings.period,
          start,
          end,
        },
      },
    },
  };
};

// 期間取得成功
const fetchStatusSucceeded = (state, action) => {
  const { status } = action.payload;
  const lastOrderDate = timezoneFormat(status.detail.last_order_date);
  const { start, end } = getStartEndDate(lastOrderDate);
  logging.debug('fetchStatusSucceeded', status, lastOrderDate, start);

  const newTargetPeriod = {};
  logging.debug(
    'fetchStatusSucceeded targetPeriod',
    state.table.settings.targetPeriod,
    status.detail.last_order_date
  );
  if (
    state.table.settings.targetPeriod === null ||
    state.table.settings.targetPeriod === ''
  ) {
    newTargetPeriod.targetPeriod = lastOrderDate;
  }

  const newDetail = {};
  logging.debug(
    'fetchStatusSucceeded last_order_date',
    state.table.data.detail.last_order_date,
    status.detail.last_order_date
  );
  logging.debug(
    'fetchStatusSucceeded analyzed_date',
    state.table.settings.analyzed_date,
    status.detail.analyzed_date
  );
  logging.debug(
    'fetchStatusSucceeded analyzed status',
    status.detail.status
  );
  if (
    state.table.data.detail.last_order_date === null ||
    state.table.data.detail.last_order_date === ''
  ) {
    newDetail.last_order_date = status.detail.last_order_date;
  }
  if (
    state.table.data.detail.analyzed_date === null ||
    state.table.data.detail.analyzed_date === ''
  ) {
    newDetail.analyzed_date = status.detail.analyzed_date;
  }

  newDetail.status = status.detail.status;

  return {
    ...state,
    table: {
      ...state.table,
      data: {
        ...state.table.data,
        detail: {
          ...state.table.data.detail,
          ...newDetail,
        },
      },
      settings: {
        ...state.table.settings,
        ...newTargetPeriod,
        period: {
          ...state.table.settings.period,
          start,
          end,
        },
      },
      status: SUCCEEDED,
      error: null,
    },
    apiStatus: {
      ...state.apiStatus,
      status: {
        status: SUCCEEDED,
        error: null,
      },
    },
  };
};

const LtvAnalyzeReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_REPORT_SUCCEEDED]: fetchReportSucceeded,
  [types.UPDATE_PAGE]: updatePage,
  [types.UPDATE_SORT]: updateSort,
  [types.CHANGE_SELECTED_TARGET]: changeSelectedTarget,

  [types.FETCH_STATUS_SUCCEEDED]: fetchStatusSucceeded,
});

export default LtvAnalyzeReducer;
