import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormatData from 'domain/dashboard/FormatData';
import { Dropdown, Nav, OverlayTrigger, Popover } from 'react-bootstrap';
import classNames from 'classnames';
import { checkIsLastMonth } from 'domain/utils';
import { isCurrentMonth } from 'services/utils';
import { EMAIL_SETTING, EXTERNAL } from 'services/routes/constants';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import ExternalTransitionWrapper from 'services/routes/ExternalTransitionWrapper';
import PermissionWrapperRPC from 'services/appconfig/PermissionWrapperRPC';
import { SETTING_GOAL_EDIT } from 'domain/permissions/contractPermissions';

const RenderTargetPeriodOpt = ({
  periodList,
  handleSelectPeriod,
  scrollbarWrapperRef,
}) => {
  if (periodList.length <= 0) {
    return '';
  }

  const FULL_LIST_LENGTH = 3;
  const CURRENT_MONTH_EMPTY_LENGTH = 2;

  const isThisMonthDataEmpty = checkIsLastMonth(periodList[0].start_date);
  const splitListLength = isThisMonthDataEmpty
    ? CURRENT_MONTH_EMPTY_LENGTH
    : FULL_LIST_LENGTH;

  return (
    <Dropdown.Menu className="p-0">
      <Dropdown.Item
        key={periodList[0].kpi_period_id}
        eventKey={periodList[0].kpi_period_id}
      >
        {FormatData.getPeriodText(periodList[0].kpi_period_id, periodList)}
      </Dropdown.Item>
      {periodList[1] && !isThisMonthDataEmpty && (
        <Dropdown.Item
          key={periodList[1].kpi_period_id}
          eventKey={periodList[1].kpi_period_id}
        >
          {FormatData.getPeriodText(periodList[1].kpi_period_id, periodList)}
        </Dropdown.Item>
      )}
      {periodList.length >= splitListLength && (
        <Dropdown onSelect={(value) => handleSelectPeriod(value)} drop="right">
          <Dropdown.Toggle
            size="sm"
            variant="select"
            className="m-0 d-flex align-items-center"
          >
            <span className="mr-auto">それ以前</span>
            <i className="far fa-angle-right" />
          </Dropdown.Toggle>
          <Dropdown.Menu ref={scrollbarWrapperRef} className="custom-view">
            <ScrollbarWrapper
              ref={scrollbarWrapperRef}
              maxContent={130}
              sizeScroll={5}
              alignScroll={5}
            >
              {periodList.slice(splitListLength - 1).map((period) => (
                <Dropdown.Item
                  key={period.kpi_period_id}
                  eventKey={period.kpi_period_id}
                >
                  {FormatData.getPeriodText(period.kpi_period_id, periodList)}
                </Dropdown.Item>
              ))}
            </ScrollbarWrapper>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Dropdown.Menu>
  );
};

RenderTargetPeriodOpt.propTypes = {
  periodList: PropTypes.oneOfType([PropTypes.array]),
  handleSelectPeriod: PropTypes.func.isRequired,
  scrollbarWrapperRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

RenderTargetPeriodOpt.defaultProps = {
  periodList: [],
};

function DashboardBar(props) {
  const {
    periodList,
    targetPeriod,
    comparePeriod,
    compareList,
    isLoadingKpiStatus,
    isShowKpiStatusModal,
    isOpenTransitionModal,
    showKpiStatusModal,
    handleSelectCompare,
    handleSelectPeriod,
    disabledSelectCompare,
    isAgentUser,
    showSettingMailModal,
    disabledIconEmail,
  } = props;

  const [isActiveIconEmail, setActiveIconEmail] = useState(false);
  const [isEnableIconSetting, setEnableIconSetting] = useState(false);
  const scrollbarWrapperRef = useRef(null);

  useEffect(() => {
    if (!isOpenTransitionModal) {
      setActiveIconEmail(isOpenTransitionModal);
    }
  }, [isOpenTransitionModal]);

  useEffect(() => {
    const period = periodList.find(
      (item) => item.kpi_period_id === targetPeriod
    );

    setEnableIconSetting(
      !isLoadingKpiStatus && period && isCurrentMonth(period.start_date)
    );
  }, [periodList, targetPeriod, isLoadingKpiStatus]);

  const findByKey = (key, arrayData) => {
    const item = arrayData.find((data) => data.key === key);
    return item ? item.value : '';
  };

  const onClickSetting = (isAllowed) => () => {
    if (isEnableIconSetting && isAllowed) {
      showKpiStatusModal(true);
    }
  };

  const onClickEmail = (isAllowed) => () => {
    if (isAllowed) {
      setActiveIconEmail(true);
      showSettingMailModal(true);
    }
  };

  return (
    <>
      <Dropdown
        className="dropdown-compare"
        onSelect={(value) => handleSelectCompare(value)}
        style={{ margin: '0 10px' }}
      >
        <Dropdown.Toggle
          size="sm"
          variant="select"
          disabled={disabledSelectCompare}
        >
          {disabledSelectCompare
            ? findByKey(2, compareList)
            : findByKey(comparePeriod, compareList)}
        </Dropdown.Toggle>
        <Dropdown.Menu className="overflow-hidden">
          {compareList.map((item) => (
            <Dropdown.Item
              key={item.key}
              eventKey={item.key}
              disabled={item.disable}
            >
              {item.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown
        className="dropdown-period"
        onSelect={(value) => handleSelectPeriod(value)}
        style={{ margin: '0 10px' }}
      >
        <Dropdown.Toggle size="sm" variant="select">
          <span className="d-inline-block">
            {FormatData.getPeriodText(targetPeriod, periodList)}
          </span>
        </Dropdown.Toggle>
        <RenderTargetPeriodOpt
          periodList={periodList}
          handleSelectPeriod={handleSelectPeriod}
          scrollbarWrapperRef={scrollbarWrapperRef}
        />
      </Dropdown>
      <Nav>
        <PermissionWrapperRPC
          requiredPermissions={[SETTING_GOAL_EDIT]}
          resolveType="anyOf"
          render={(isAllowed) => {
            const navClass = classNames({
              'nav-link': true,
              'nav-link--icon': true,
              'email-link--icon': true,
              'ebis--disabled': !isAllowed || disabledIconEmail,
              active: isActiveIconEmail,
            });

            return (
              <div
                className={navClass}
                onClick={onClickEmail(isAllowed)}
                aria-hidden="true"
              >
                {/* <ExternalTransitionWrapper
                  link={EMAIL_SETTING}
                  type={EXTERNAL}
                  newTab
                  disabled={!isAllowed}
                >
                  <div className="d-flex justify-content-center">
                    <span className="icon-svg icon-email-setting" />
                  </div>
                  <div className="text-dark">メール</div>
                </ExternalTransitionWrapper> */}
                <div className="d-flex justify-content-center">
                  <span className="icon-svg icon-email-setting" />
                </div>
                <div className="text-dark">メール</div>
              </div>
            );
          }}
        />

        <PermissionWrapperRPC
          requiredPermissions={[SETTING_GOAL_EDIT]}
          resolveType="anyOf"
          render={(isAllowed) => {
            const isEnabled = isEnableIconSetting && isAllowed && !isAgentUser;
            const navClass = classNames({
              'nav-link': true,
              'nav-link--icon': true,
              setting: true,
              disabled: !isEnabled,
              'ebis--disabled': !isEnabled,
            });
            const iconClass = classNames({
              'd-flex': true,
              'justify-content-center': true,
              active: isShowKpiStatusModal,
            });

            return (
              <OverlayTrigger
                trigger={isEnableIconSetting ? '' : ['hover', 'focus']}
                placement="bottom"
                overlay={
                  <Popover className="popover--delete">
                    <Popover.Content>
                      期間が今月ではないので設定できません
                    </Popover.Content>
                  </Popover>
                }
              >
                <div
                  className={navClass}
                  onClick={onClickSetting(isAllowed)}
                  aria-hidden="true"
                >
                  <div className={iconClass}>
                    <span className="icon-svg icon-setting" />
                  </div>
                  <div>評価設定</div>
                </div>
              </OverlayTrigger>
            );
          }}
        />
      </Nav>
    </>
  );
}

DashboardBar.propTypes = {
  periodList: PropTypes.oneOfType([PropTypes.array]),
  compareList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  targetPeriod: PropTypes.number,
  comparePeriod: PropTypes.number,
  isLoadingKpiStatus: PropTypes.bool,
  isShowKpiStatusModal: PropTypes.bool,
  isOpenTransitionModal: PropTypes.bool,
  showKpiStatusModal: PropTypes.func.isRequired,
  handleSelectCompare: PropTypes.func.isRequired,
  handleSelectPeriod: PropTypes.func.isRequired,
  isAgentUser: PropTypes.bool.isRequired,
  disabledSelectCompare: PropTypes.bool,
  showSettingMailModal: PropTypes.func.isRequired,
  disabledIconEmail: PropTypes.bool,
};

DashboardBar.defaultProps = {
  periodList: [],
  compareList: {},
  targetPeriod: 0,
  comparePeriod: 1,
  isLoadingKpiStatus: true,
  isShowKpiStatusModal: false,
  isOpenTransitionModal: false,
  disabledSelectCompare: false,
  disabledIconEmail: true,
};

export default DashboardBar;
