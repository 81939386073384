import React, { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { bool, func, shape } from 'prop-types';
import {
  INFO_FORM_OTHER_SETTING as FORM,
  ALERT_MAIL,
  LOGIN_IP,
  LOGIN_IP_FLAG,
  REMOTE_ADDR,
  MAX_LENGTH_LOGIN_IP,
  ALERT_AUTH_IDS,
  HEADER_USER_CAPI,
  HEADER_USER_CAPI_SINGLE,
} from 'domain/system-setting/consts';
import { FALSE_FLAG, TRUE_FLAG } from 'domain/consts';
import {
  validateOtherSettingForm,
  getErrorResponseOtherSetting,
  formatUsersData,
} from 'services/system-setting/systemSettingServices';
import systemSettingActions from 'store/system-setting/actions';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import { formatNumber } from 'domain/utils';
import iconusers from 'assets/images/icon-users.svg';
import SettingModal from './Modal';
import AlertAuthSetting from './AlertAuthSetting';

function OtherSettingModal(props) {
  const dispatch = useDispatch();

  const {
    isShow,
    onHide,
    data,
    isCapiSingleContract,
    isCapiEditPermission,
  } = props;

  const [valueForm, setValueForm] = useState(data);
  const [errorForm, setErrorForm] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [loginIpFlag, setLoginIpFlag] = useState(
    valueForm[LOGIN_IP].length > 0
  );

  const handleChangeValue = useCallback((name, newValue) => {
    setValueForm((prevValueForm) => ({
      ...prevValueForm,
      [name]: newValue,
    }));
    setErrorForm((prevErrorForm) => ({
      ...prevErrorForm,
      [name]: null,
    }));
  }, []);

  const handleChangeRadio = (value) => {
    const isEnable = value === TRUE_FLAG;
    setLoginIpFlag(isEnable);
  };

  const handleResponseUpdate = ({ errors }) => {
    setIsUpdating(false);
    if (isEmpty(errors)) {
      onHide();
    } else {
      const errorResponse = getErrorResponseOtherSetting(errors);
      setErrorForm(errorResponse);
    }
  };

  const handleSubmit = () => {
    const dataRequest = {
      alert_email: valueForm[ALERT_MAIL].replaceAll('\n', ','),
      alert_auth_ids: valueForm[ALERT_AUTH_IDS].join(','),
      login_ip: loginIpFlag ? valueForm[LOGIN_IP].replaceAll('\n', ',') : '',
    };

    const error = validateOtherSettingForm(dataRequest);
    if (!isEmpty(error)) {
      setErrorForm(error);
    } else {
      setIsUpdating(true);
      dispatch(
        systemSettingActions.updateOtherSetting(
          dataRequest,
          handleResponseUpdate
        )
      );
    }
  };

  const usersFormatted = useMemo(() => {
    return isCapiEditPermission ? formatUsersData(valueForm.dtb_user) : [];
  }, [isCapiEditPermission, valueForm.dtb_user]);

  const header = isCapiSingleContract
    ? HEADER_USER_CAPI_SINGLE
    : HEADER_USER_CAPI;

  const handleSelectRow = useCallback(
    (ids) => {
      handleChangeValue(ALERT_AUTH_IDS, ids);
    },
    [handleChangeValue]
  );
  return (
    <SettingModal
      loading={isUpdating}
      isShow={isShow}
      onHide={onHide}
      onSubmit={handleSubmit}
      title="通知/その他を編集"
      dialogClassName="other-setting-modal"
    >
      {isCapiEditPermission && (
        <FormGroup
          label={FORM[ALERT_AUTH_IDS].label}
          tooltip={FORM[ALERT_AUTH_IDS].tooltip}
        >
          <AlertAuthSetting
            users={usersFormatted}
            selectedAuthIds={valueForm[ALERT_AUTH_IDS]}
            error={errorForm[ALERT_AUTH_IDS]}
            onChange={handleSelectRow}
            header={header}
          />
          <div className="other-setting__user-note">
            <p className="d-flex align-items-center">
              <img src={iconusers} alt="" />
              <span className="color-primary">
                複数メールアドレスが登録されています。カーソルをあわせるとすべてのメールアドレスがご確認いただけます
              </span>
            </p>
            <p className="color-gray-dark">
              ※「設定/管理&gt;基本設定&gt;ユーザー管理」よりユーザーのメールアドレスを設定いただけます。メールアドレスが設定されていない場合、送信先に選択されていても通知されません。
            </p>
          </div>
        </FormGroup>
      )}
      <FormGroup
        label={FORM[ALERT_MAIL].label}
        tooltip={FORM[ALERT_MAIL].tooltip}
        note={FORM[ALERT_MAIL].note}
        styleError="keep-position"
      >
        <FormInput
          as="textarea"
          name={ALERT_MAIL}
          value={valueForm[ALERT_MAIL].replaceAll(',', '\r\n')}
          placeholder={FORM[ALERT_MAIL].placeholder}
          onChange={handleChangeValue}
          error={errorForm[ALERT_MAIL]}
        />
      </FormGroup>
      <FormGroup label={FORM[LOGIN_IP].label} note={FORM[LOGIN_IP].note}>
        <div className="form-group__login-ip-flag">
          <EbisRadio
            name={LOGIN_IP_FLAG}
            value={FALSE_FLAG}
            label="利用しない"
            checked={!loginIpFlag}
            onChange={handleChangeRadio}
          />
          <EbisRadio
            name={LOGIN_IP_FLAG}
            value={TRUE_FLAG}
            label="利用する"
            checked={loginIpFlag}
            onChange={handleChangeRadio}
          />
        </div>
        <FormInput
          as="textarea"
          name={LOGIN_IP}
          value={valueForm[LOGIN_IP].replaceAll(',', '\r\n')}
          placeholder={loginIpFlag ? FORM[LOGIN_IP].placeholder : ''}
          onChange={handleChangeValue}
          error={errorForm[LOGIN_IP]}
          disabled={!loginIpFlag}
        />
        <div className="form-group__login-ip-note">
          改行で複数登録可、{formatNumber(MAX_LENGTH_LOGIN_IP)}文字以内
          <br />
          ※あなたのIPアドレスは「{valueForm[REMOTE_ADDR]}」です
          <br />
          ※誤って登録するとログインできなくなる場合もありますので、ご注意ください
        </div>
      </FormGroup>
    </SettingModal>
  );
}

OtherSettingModal.propTypes = {
  isShow: bool.isRequired,
  isCapiSingleContract: bool.isRequired,
  isCapiEditPermission: bool.isRequired,
  onHide: func.isRequired,
  data: shape({}).isRequired,
};

export default OtherSettingModal;
