import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const withFreezeTableResize = (Component) => {
  const WrappedComponent = (props) => {
    const { top: scrollTopDefault, width } = props;
    const [scrollTop, setScrollTop] = useState(scrollTopDefault);
    const [widthContainer, setWidthContainer] = useState(width);

    useEffect(() => {
      const topbarEL = document.getElementById('top-bar');
      const commonBarEl = document.getElementById('common-bar');
      const paginationTableEl = document.getElementById('pagination-table');
      const mainContentEl = document.getElementById('main-content');
      const freezeTableEL = document.getElementsByClassName('freeze-table')[0];
      const priorityAxisBarEL = document.getElementById('priority-axis-bar');
      const freezeHeadAreaEl = document.getElementById('freeze-head-area');
      const cookieProgramEL = document.getElementsByClassName(
        'cookie-program'
      )[0];

      let freezeHeadAreaElHeight = 0;
      if (freezeHeadAreaEl !== null && cookieProgramEL !== null) {
        freezeHeadAreaElHeight = freezeHeadAreaEl.getBoundingClientRect()
          .height;
      }

      let priorityAxisBarHeight = 0;
      if (priorityAxisBarEL) {
        priorityAxisBarHeight = priorityAxisBarEL.offsetHeight;
      }

      if (mainContentEl && paginationTableEl) {
        let defaultTop =
          topbarEL.offsetHeight +
          paginationTableEl.offsetHeight +
          priorityAxisBarHeight;

        let { offsetHeight } = paginationTableEl;
        if (freezeHeadAreaEl !== null && cookieProgramEL !== null) {
          offsetHeight = paginationTableEl.getBoundingClientRect().height;
          defaultTop =
            topbarEL.getBoundingClientRect().height +
            offsetHeight +
            priorityAxisBarHeight +
            freezeHeadAreaElHeight;
        }
        Object.assign(paginationTableEl.style, {
          top: `${scrollTop - offsetHeight}px`,
        });

        if (priorityAxisBarEL) {
          Object.assign(priorityAxisBarEL.style, {
            top: `${
              scrollTop -
              paginationTableEl.offsetHeight -
              priorityAxisBarEL.offsetHeight
            }px`,
          });
        }

        const widthObserver = new ResizeObserver((entries) => {
          window.requestAnimationFrame(() => {
            if (
              Array.isArray(entries) &&
              entries.length &&
              entries[0].contentRect.width !== widthContainer
            ) {
              setWidthContainer(mainContentEl.offsetWidth);
            }
          });
        });

        const heightObserver = new ResizeObserver((entries) => {
          window.requestAnimationFrame(() => {
            if (
              Array.isArray(entries) &&
              entries.length &&
              entries[0].contentRect.height !== scrollTop - defaultTop
            ) {
              let commonBarElHeight = commonBarEl.offsetHeight;
              if (freezeHeadAreaEl !== null && cookieProgramEL !== null) {
                commonBarElHeight = commonBarEl.getBoundingClientRect().height;
              }
              setScrollTop(defaultTop + commonBarElHeight);
            }
          });
        });

        heightObserver.observe(commonBarEl);
        widthObserver.observe(freezeTableEL);
      }
    }, [scrollTop, widthContainer]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} top={scrollTop} width={widthContainer} />;
  };

  WrappedComponent.propTypes = {
    top: PropTypes.number,
    width: PropTypes.number,
  };

  WrappedComponent.defaultProps = {
    top: 0,
    width: 0,
  };

  return WrappedComponent;
};

export default withFreezeTableResize;
