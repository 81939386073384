import { SettingsApi as Api } from 'services/api/Api';
import apiUtils from 'services/apiUtils';
import axios from 'axios';
import pages from 'services/routes/pages';
import { CONTACT_MANAGEMENT } from 'services/routes/constants';

const ContactApiFactory = () => {
  const url = pages[CONTACT_MANAGEMENT].endpoint;

  const getUploadUrl = async (contentType) => {
    const query = {
      'content-type': contentType,
      mode: 'contact',
    };
    const urlGet = apiUtils.buildUrlWithQueryStrings(
      `${url}/upload-url`,
      query
    );
    const response = await Api.get(urlGet);
    const { upload_url: uploadUrl, file_name: fileName } = response.data.data;
    return {
      uploadUrl,
      fileName,
    };
  };

  const uploadFile = async (file, urlFile, contentType) => {
    const response = await axios.put(urlFile, file, {
      headers: {
        'Content-Type': contentType,
        'X-Amz-Acl': 'bucket-owner-full-control',
      },
    });
    return response;
  };

  return {
    sendRequest: async (data) => Api.post(`${url}/contact`, data),
    getUploadUrl,
    uploadFile,
  };
};

const ContactApi = ContactApiFactory();
export default ContactApi;
