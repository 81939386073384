import React from 'react';
import {
  SYSTEM_SETTING_BASIC,
  SYSTEM_SETTING_DISPLAY,
  SYSTEM_SETTING_MEASUREMENT,
  SYSTEM_SETTING_NOTIFICATIONS,
  COOKIE_PROGRAM_DRAGONBALL_LINK,
  SYSTEM_SETTING_CNAME,
  SYSTEM_SETTING_NS,
  REDIRECT_TRACKING_HELP_LINK,
} from 'services/routes/constants';
import {
  MEASUREMENT_FQDN,
  PROGRAM_URL,
  UPDATE_DATE,
  FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK,
  FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS,
  FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE,
  MODAL_MEASUREMENT_FQDN,
  MODAL_PROGRAM_URL,
  MODAL_FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK,
  MODAL_FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS,
  MODAL_FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE,
  CNAME_DOMAIN_FIELD,
  NS_DOMAIN_FIELD,
  USER_ID,
  COMPANY_NAME,
  CHARGE_NAME,
  EMAIL,
  GOOGLE,
  YAHOO_SEARCH_AD,
  YAHOO_DISPLAY_AD,
  FACEBOOK,
  TIKTOK,
  LINE,
  DOMAIN_NAME,
  REDIRECT_FLAG,
  EMAIL_NOTIFICATION,
  SEND_MAIL_USERS,
  ETLD1,
} from 'domain/fields';
import {
  LINE_ADS,
  META_ADS,
  TIKTOK_ADS,
  GOOGLE_ADS,
  YAHOO_DISPLAY_AD_LABEL,
  YAHOO_SEARCH_AD_LABEL,
} from 'domain/labels';

export const MAX_LENGTH_HOST_NAME = 8000;

export const REJECT_HOST_MAX_LENGTH = 4000;
export const SESSION_EXPIRE_MAX_VALUE = 30;
export const EXPIREDAYS_MAX_VALUE = 366;

export const QUERY_PARAMS_MAX_REGIST_LINE = 50;
export const QUERY_PARAMS_MAX_LENGTH_PER_LINE = 80;

export const DIRECTORY_INDEX_MAX_REGIST_LINE = 5;
export const DIRECTORY_INDEX_MAX_LENGTH_PER_LINE = 80;
// Other setting tab
export const MAX_LENGTH_ALERT_MAIL = 400;
export const MAX_LENGTH_LOGIN_IP = 4000;

export const CLICK_MEDIA_SETTING_MEDIA = {
  [GOOGLE]: {
    key: GOOGLE,
    name: GOOGLE_ADS,
  },
  [YAHOO_SEARCH_AD]: {
    key: YAHOO_SEARCH_AD,
    name: YAHOO_SEARCH_AD_LABEL,
  },
  [YAHOO_DISPLAY_AD]: {
    key: YAHOO_DISPLAY_AD,
    name: YAHOO_DISPLAY_AD_LABEL,
  },
  [FACEBOOK]: {
    key: FACEBOOK,
    name: META_ADS,
  },
  [TIKTOK]: {
    key: TIKTOK,
    name: TIKTOK_ADS,
  },
  [LINE]: {
    key: LINE,
    name: LINE_ADS,
  },
};

// Dns setting tab
export const MAX_ROW_DNS_SETTING = 400;
export const DNS_SETTINGS_MAX_ENTRIES_PER_REGISTRATION = 20;
export const DNS_MAX_LENGTH = 64;
export const DNS_REGEX = /^(((?!-)[A-Za-z0-9-]{0,62}[A-Za-z0-9])\.)+((?!-)[A-Za-z0-9-]{1,62}[A-Za-z0-9])$/;
export const LIMIT_NUMBER_DNS_CALL_API = 3;

export const DNS_AVAILABLE_STATUS = 'AVAILABLE';
export const DNS_ERROR_STATUS = 'ERROR';
export const DNS_WARMUP_STATUS = 'WARMUP';
export const DNS_SETTING_STATUS = 'SETTING';
export const DNS_UNHEALTHY_STATUS = 'UNHEALTHY';
export const MASTERDATA_STATUS_KEY = 'masterData';

export const APP_HELP_SETUP_CNAME_ABOUT_EMAIL_AUTHENTICATION =
  'https://support.ebis.ne.jp/s/article/000004791';
export const APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_2 =
  'https://support.ebis.ne.jp/s/article/35117#1-1';
export const APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_3 =
  'https://support.ebis.ne.jp/s/article/35117#1-2';
export const APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_4 =
  'https://support.ebis.ne.jp/s/article/35117';
export const DOMAIN_ERROR_STATUS_HELP_LINK_NS =
  'https://support.ebis.ne.jp/s/article/000004788';
export const DOMAIN_ERROR_STATUS_HELP_LINK_CNAME =
  'https://support.ebis.ne.jp/s/article/000004790';
export const DOMAIN_UNHEALTHY_STATUS_HELP_LINK_NS =
  'https://support.ebis.ne.jp/s/article/000004788#003';
export const DOMAIN_UNHEALTHY_STATUS_HELP_LINK_CNAME =
  'https://support.ebis.ne.jp/s/article/000004790#003';

export const ARLET_EMAIL = {
  label: '警告先メールアドレス',
  tooltip: 'TODO',
  note: '改行で複数登録可、400文字以内',
  name: 'alertemail',
  placeholder: '値を入力',
};

export const LOGIN_RESTRICTION = {
  label: '管理画面ログイン制限（ログイン許可IPアドレス）',
  tooltip: 'TODO',
  note:
    '※誤って登録するとログインできなくなる場合もありますので、ご注意ください',
  name: 'loginrestriction',
  placeholder: '値を入力',
};
export const BASIC_TAB_LABEL = '基本';
export const DNS_TAB_LABEL = '計測用DNS';
export const MEASUREMENT_OPTION_TAB_LABEL = '計測オプション';
export const DISPLAY_TAB_LABEL = '表示';
export const OTHER_TAB_LABEL = '通知/その他';

export const COOKIE_PROGRAM_NAME = 'ITP対応プログラム設定';

export const COOKIE_PROGRAM_CHECK_TOOLTIP_MESSAGE = (
  <>
    結果の見方や対処方法は
    <a
      href={COOKIE_PROGRAM_DRAGONBALL_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      こちら
    </a>
  </>
);

export const SYSTEM_SETTING_TAB = {
  BASIC: 'basic',
  DNS: 'dns',
  CNAME: 'cname',
  NS: 'ns',
  MEASUREMENT: 'measurement',
  DISPLAY: 'display',
  NOTIFICATIONS: 'notifications',
  COOKIE_PROGRAM: 'cookie-program',
};

export const NS_SETTING = 'ns';
export const CNAME_SETTING = 'cname';

export const TABS = [
  {
    key: SYSTEM_SETTING_TAB.BASIC,
    label: '基本',
    path: `/${SYSTEM_SETTING_BASIC}`,
  },
  {
    key: SYSTEM_SETTING_TAB.MEASUREMENT,
    label: '計測オプション',
    path: `/${SYSTEM_SETTING_MEASUREMENT}`,
  },
  {
    key: SYSTEM_SETTING_TAB.DISPLAY,
    label: '表示オプション',
    path: `/${SYSTEM_SETTING_DISPLAY}`,
  },
  {
    key: SYSTEM_SETTING_TAB.NOTIFICATIONS,
    label: '通知/その他',
    path: `/${SYSTEM_SETTING_NOTIFICATIONS}`,
  },
  {
    key: SYSTEM_SETTING_TAB.COOKIE_PROGRAM,
    label: 'ITP対応プログラム',
    path: SYSTEM_SETTING_TAB.COOKIE_PROGRAM,
  },
  {
    key: SYSTEM_SETTING_TAB.DNS,
    label: '計測用DNS',
    path: `/${SYSTEM_SETTING_NS}`,
    subTabs: [
      {
        key: NS_SETTING,
        label: 'NSドメイン',
        path: `/${SYSTEM_SETTING_NS}`,
      },
      {
        key: CNAME_SETTING,
        label: 'CNAMEドメイン',
        path: `/${SYSTEM_SETTING_CNAME}`,
      },
    ],
    subTabsKeyPath: [`/${SYSTEM_SETTING_CNAME}`, `/${SYSTEM_SETTING_NS}`],
  },
];

export const TABS_CAPI_SINGLE_CONTRACT = TABS.filter(
  (tab) => tab.key !== SYSTEM_SETTING_TAB.DISPLAY
);

export const DEFAULT_COOKIE_PROGRAM_COLUMN_WIDTH = {
  [MEASUREMENT_FQDN]: 354,
  [PROGRAM_URL]: 354,
  [UPDATE_DATE]: 194,
  [FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK]: 194,
  [FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS]: 97,
  [FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE]: 97,
};

export const CHECK_COLUMNS = [
  {
    name: MODAL_MEASUREMENT_FQDN,
    text: 'ドメイン（サブドメイン）',
    isDimension: true,
    sort: false,
  },
  {
    name: MODAL_PROGRAM_URL,
    text: 'プログラムURL',
    isDimension: true,
    sort: false,
  },
  {
    name: MODAL_FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK,
    text: '動作確認結果',
    isDimension: true,
    sort: false,
    children: [
      {
        name: MODAL_FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS,
        text: 'ステータス',
        isDimension: true,
        sort: false,
      },
      {
        name: MODAL_FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE,
        text: 'Cookie付与',
        isDimension: true,
        sort: false,
      },
    ],
  },
];

export const STEPS = [
  {
    step: 1,
    label: '設定',
    active: false,
  },
  {
    step: 2,
    label: '動作確認',
    active: false,
  },
];

export const MODALIDS = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  CHECK: 'check',
  ERROR: 'error',
};

export const ACTION = {
  REGISTER: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  CHECK: 'check',
};

export const REGISTER_COLUMNS = [
  { name: 'label', title: 'ドメイン（サブドメイン）' },
  { name: 'data', title: 'プログラムURL' },
];

export const REJECT_HOST = 'reject_host';
export const SESSION_EXPIRE = 'session_expire';
export const EXPIREDAYS = 'expiredays';
export const CONV_DUPLICATION_FLAG = 'conv_duplication_flag';
export const QUERY_PARAMS = 'query_params';
export const DIRECTORY_INDEX = 'directory_index';
export const ALERT_AUTH_IDS = 'alert_auth_ids';
export const CROSS_DEVICE_MODE = 'cross_device_mode';
export const AD_CV_COST_MODE = 'ad_cv_cost_mode';
export const CLICK_ID_SETTING = 'click_id_setting';

export const MEASUREMENT_MODAL_SETTING_IDS = {
  CONFIG: 'config',
  LOG: 'log',
  CROSS_DEVICE_MODE: 'cross_device_mode',
  AD_CV_COST_MODE: 'ad_cv_cost_mode',
  CLICK_ID_SETTING: 'click_id_setting',
};

export const MEASUREMENT_SETTING_ENDPOINTS = {
  config: 'config',
  log: 'log',
  cross_device_mode: 'cross-device-mode',
  ad_cv_cost_mode: 'ad-cv-cost-mode',
  click_id_setting: 'click-id-setting',
};

export const INFO_FORM_MEASUREMENT_SETTING = {
  [REJECT_HOST]: {
    label: '除外IPアドレス',
    tooltip:
      '除外したいIPアドレスを入力してください。\n※ 111.222.0.*で登録すると 111.222.0.0～111.222.0.255も除外されます。',
    note: '改行で複数登録可、3,000文字以内',
    placeholder: '値を入力',
  },
  [SESSION_EXPIRE]: {
    label: 'セッションインターバル',
    tooltip:
      '同一人物からアクセスがあった場合、何分経過した時点で別セッションとみなすかを設定します。',
    note: '1～30の整数のみ',
  },
  [EXPIREDAYS]: {
    label: 'トラッキング対象期間',
    tooltip:
      '広告がクリックされてから、ここで指定した日数を過ぎると、効果としてはカウントされません。',
    note: '1～366の整数のみ',
  },
  [CONV_DUPLICATION_FLAG]: {
    label: 'コンバージョン重複カウント',
    tooltip:
      '同一セッション（訪問）の中で、同じコンバージョンページが２回以上連続して\n読み込まれる場合、カウントを取得するかどうかを設定します',
  },
  [QUERY_PARAMS]: {
    label: '別のページとして計測するパラメータ',
    placeholder: '値を入力',
  },
  [DIRECTORY_INDEX]: {
    label: 'デフォルトのページ',
    placeholder: '値を入力',
  },
  [ALERT_AUTH_IDS]: {
    label: '計測ページ上限通知先ユーザー',
    tooltip:
      '計測サイトの取得ページ登録数が上限に近付いた場合、達した場合にお知らせメールを送信します。',
  },
  [CROSS_DEVICE_MODE]: {
    label: 'クロスデバイス紐付け',
  },
  [AD_CV_COST_MODE]: {
    label: 'コンバージョン単価',
  },
  [CLICK_ID_SETTING]: {
    label: '媒体クリックID等の取得',
    tooltip:
      '全トラフィックのコンバージョン属性のCSVダウンロードにて、媒体クリックID等の出力が可能になります。',
    note:
      '※CV属性のCSVダウンロードにて出力可能です。 \n ※設定完了後から媒体クリックID等が取得されます。',
    confirmText: '上記事項に同意します',
  },
};

const ROLES_FIELD = 'roles';
const AGENT_FIELD = 'agent';

export const HEADER_USER = [
  {
    isFreeze: true,
    field: 'rowId',
    name: '',
    sort: 'none',
    width: '45px',
    type: 'checkbox',
  },
  {
    isFreeze: true,
    field: USER_ID,
    name: 'ユーザーID',
    width: 'max-content',
    minWidth: '150px',
  },
  {
    field: COMPANY_NAME,
    name: '会社名',
    width: '201px',
  },
  {
    field: CHARGE_NAME,
    name: '名前',
    width: '121px',
  },
  {
    field: EMAIL,
    name: 'メールアドレス',
    width: 'max-content',
    minWidth: '231px',
  },
  {
    field: ROLES_FIELD,
    name: '閲覧',
    children: [
      {
        field: 'view_all',
        name: '全トラフィック',
        width: '122px',
        variant: 'icon-check',
      },
      {
        field: 'view_ad',
        name: '広告',
        width: '57px',
        variant: 'icon-check',
      },
    ],
  },
  {
    field: AGENT_FIELD,
    name: 'エージェント',
    width: '109px',
    variant: 'icon-check',
  },
];

export const HEADER_USER_CAPI = HEADER_USER.filter(
  (item) => item.field !== ROLES_FIELD
);

export const HEADER_USER_CAPI_SINGLE = HEADER_USER.filter(
  (item) => ![ROLES_FIELD, AGENT_FIELD].includes(item.field)
);

export const CROSS_DEVICE_MODE_AI = 1;
export const CROSS_DEVICE_MODE_MEMBER = 2;

export const CROSS_DEVICE_MODE_LABELS = {
  [CROSS_DEVICE_MODE_AI]: '機械学習',
  [CROSS_DEVICE_MODE_MEMBER]: 'ユーザー名',
};

export const OPTION_AD_CV_COST_MODE_NONE = 0;
export const OPTION_AD_CV_COST_MODE_DIRECT = 1;
export const OPTION_AD_CV_COST_MODE_AVERAGE = 2;

export const AD_CV_COST_MODE_LABELS = {
  [OPTION_AD_CV_COST_MODE_NONE]: 'コスト計上しない',
  [OPTION_AD_CV_COST_MODE_DIRECT]: '直接効果の広告のみ',
  [OPTION_AD_CV_COST_MODE_AVERAGE]: '間接効果の広告を含む',
};
export const HOST_NAME = 'host_name';
export const EMERGENCY_URL = 'emergency_url';
export const TRAFFIC_CONTROL_FLAG = 'traffic_control_flag';
export const ITP_FLAG = 'itp_flag';
export const CROSS_DOMAIN_FLAG = 'cross_domain_flag';
export const CROSS_DOMAIN_LIST = 'cross_domain_list';

export const INFO_FORM_BASIC_SETTING = {
  [HOST_NAME]: {
    label: '計測対象サイト',
    tooltip:
      '除外したいIPアドレスを入力してください。\n※ 111.222.0.*で登録すると 111.222.0.0～111.222.0.255も除外されます。',
    note: '改行で複数登録可、8,000文字以内',
    placeholder: 'https://',
  },
  [EMERGENCY_URL]: {
    label: '緊急遷移先URL',
    note: '※「計測対象サイト」の一番上に登録されているURLが自動的に入ります',
  },
  [TRAFFIC_CONTROL_FLAG]: {
    label: '上限枠の超過計測',
    note: '※変更の反映は毎朝10時となります。ご注意ください',
  },
  [ITP_FLAG]: {
    label: 'リダイレクト計測のトラッキング方式',
    tooltip: (<>リダイレクト計測の入稿用URLをお使いの場合のトラッキング方式について設定することができます。詳しくは<a href={REDIRECT_TRACKING_HELP_LINK} target="_blank" rel="noopener noreferrer">こちら</a></>),
    note: (<>※広告からの遷移先ページに「共通タグ」の設置がない場合は、クリックが計測されない可能性があるため<br />「1st Party Cookie計測」を選択しないでください。</>),
  },
  [CROSS_DOMAIN_FLAG]: {
    label: '自動クロスドメイン',
    tooltip:
      '1st Party Cookieを利用した計測の際に、複数ドメインを横断して計測する設定です。\n共通タグが設置されているページで計測対象サイト間遷移をするとリンク先URLに計測用のパラメータが付与されます。',
    note:
      '※「計測対象サイト」に複数のドメイン（サブドメインを除く）を入力した場合のみ「有効」を選択できます',
  },
  [CROSS_DOMAIN_LIST]: {
    label: '自動クロスドメインの対象ドメイン',
    tooltip:
      '自動クロスドメイン設定の対象とするドメイン一覧です。\n計測対象サイトのサブドメインを除いたドメインが対象となります。',
    note: '※「計測対象サイト」のドメインが自動的に入ります',
  },
};

// Other setting tab
export const ALERT_MAIL = 'alert_email';
export const LOGIN_IP = 'login_ip';
export const LOGIN_IP_FLAG = 'login_ip_flag';
export const REMOTE_ADDR = 'remote_addr';

export const INFO_FORM_OTHER_SETTING = {
  [ALERT_AUTH_IDS]: {
    label: '[CAPiCO]CV送信数上限超過時の通知先メールアドレス',
    tooltip: 'CV送信数の上限70%、85%、100% 到達時に通知します。',
  },
  [ALERT_MAIL]: {
    label: '警告先メールアドレス',
    tooltip:
      '計測対象サイトに登録されていないページでエビスタグが読み込まれた場合に\nお知らせメールを送信します',
    placeholder: '値を入力',
    note: '改行で複数登録可、400文字以内',
  },
  [LOGIN_IP]: {
    label: '管理画面ログイン制限（ログイン許可IPアドレス）',
    placeholder: '値を入力',
  },
};

export const HEADER_DNS_COMPLETE_SCREEN = [
  {
    field: NS_DOMAIN_FIELD,
    name: 'ドメイン',
  },
  {
    field: 'status_text',
    name: 'ステータス',
    width: 'max-content',
    whiteSpace: 'nowrap',
  },
];

// dns-cname
export const HEADER_TABLE_DNS_SETTING = [
  {
    field: 'rowId',
    fieldResponse: 'etld1',
    name: '',
    isEdit: true,
    type: 'checkbox',
  },
  ...HEADER_DNS_COMPLETE_SCREEN,
];

export const HEADER_TABLE_CNAME_SETTING = [
  {
    field: 'rowId',
    fieldResponse: 'etld1',
    name: '',
    isEdit: true,
    type: 'checkbox',
  },
  {
    field: CNAME_DOMAIN_FIELD,
    name: 'ドメイン',
  },
  {
    field: 'status_text',
    name: 'ステータス',
    width: 'max-content',
    whiteSpace: 'nowrap',
  },
];

export const STATUS_DNS_SETTING = {
  [DNS_SETTING_STATUS]: {
    ns:
      '設定期限は登録完了後62日間としておりますが、疎通確認の時間も含めた期限となっております。期限が切れる数時間前にDNSの登録を行ってください。期限内に設定・疎通確認が完了しない場合は状況が「エラー」となり、再設定が必要になります。',
    cname:
      '設定期限は登録完了後72時間としておりますが、疎通確認の時間も含めた期限となっております。期限が切れる数時間前にDNSの登録を行ってください。期限内に設定・疎通確認が完了しない場合は状況が「エラー」となり、再設定が必要になります。',
  },
  [DNS_WARMUP_STATUS]: {
    ns:
      '証明書の認証が完了しNS方式への切替設定を行っています。\n証明書の認証完了後、約24時間で切替が完了し利用可能となります。',
    cname:
      '証明書の認証が完了しCNAME方式への切替設定を行っています。\n証明書の認証完了後、約24時間で切替が完了し利用可能となります。',
  },
  [DNS_ERROR_STATUS]: {
    ns: (
      <>
        本画面から該当NSドメインの設定を削除の上、再設定を行ってください。再設定には、NSドメインの登録、DNSサーバーへの登録のすべてが必要になります。詳しくは<a href={DOMAIN_ERROR_STATUS_HELP_LINK_NS} target="_blank" rel="noopener noreferrer">こちら</a>
      </>
    ),
    cname: (
      <>
        本画面から該当CNAMEドメインの設定を削除の上、再設定を行ってください。再設定には、CNAMEドメインの登録、DNSサーバーへの登録のすべてが必要になります。詳しくは<a href={DOMAIN_ERROR_STATUS_HELP_LINK_CNAME} target="_blank" rel="noopener noreferrer">こちら</a>
      </>
    ),
  },
  [DNS_AVAILABLE_STATUS]: {
    ns:
      '設定期限は登録完了後62日間としておりますが、疎通確認の時間も含めた期限となっております。期限が切れる数時間前にDNSの登録を行ってください。期限内に設定・疎通確認が完了しない場合は状況が「エラー」となり、再設定が必要になります。',
    cname: '',
  },
  [DNS_UNHEALTHY_STATUS]: {
    ns: (
      <>
        DNSサーバーとの接続が切断されました。DNSサーバーにNSレコードを再設定してください。再設定後、疎通が確認できましたらステータスが「利用可能」に切り替わります。詳しくは<a href={DOMAIN_UNHEALTHY_STATUS_HELP_LINK_NS} target="_blank" rel="noopener noreferrer">こちら</a>
      </>
    ),
    cname: (
      <>
        DNSサーバーとの接続が切断されました。DNSサーバーにCNAMEレコードを再設定してください。再設定後、疎通が確認できましたらステータスが「利用可能」に切り替わります。詳しくは<a href={DOMAIN_UNHEALTHY_STATUS_HELP_LINK_CNAME} target="_blank" rel="noopener noreferrer">こちら</a>
      </>
    ),
  },
};

export const MODAL_TYPES = {
  ADD_NS: 'add-ns',
  ADD_CNAME: 'add-cname',
  ADD_EMAIL: 'add-email',
};

// NSCNAME Email Setting
export const FIELD_EMAIL_SETTING_FORM = {
  [DOMAIN_NAME]: {
    label: 'ドメイン',
    value: '',
  },
  // Not display (hidden parameter)
  [ETLD1]: {
    label: 'etld1',
    value: '',
  },
  [REDIRECT_FLAG]: {
    label: 'リダイレクト計測',
    value: false,
  },
  [EMAIL_NOTIFICATION]: {
    label: 'アラートメール通知',
    value: false,
  },
  [SEND_MAIL_USERS]: {
    label: '送信先',
    value: [],
  },
};

export const STEP_SETTING_DNS_SUBDOMAIN = 1;
export const STEP_SETTING_DNS_EMAIL = 2;
export const STEP_CONFIRM = 3;
export const STEPS_DNS_SETTING_FORM = [
  {
    step: STEP_SETTING_DNS_SUBDOMAIN,
    label: 'DNS追加',
    active: false,
  },
  {
    step: STEP_SETTING_DNS_EMAIL,
    label: 'アラートメール設定',
    active: false,
  },
  {
    step: STEP_CONFIRM,
    label: '確認',
    active: false,
  },
];

export const STEP_SETTING_DNS_EMAIL_EDIT = 1;
export const STEP_CONFIRM_EDIT = 2;
export const STEPS_DNS_SETTING_FORM_EDIT = [
  {
    step: STEP_SETTING_DNS_EMAIL_EDIT,
    label: 'アラートメール設定',
    active: false,
  },
  {
    step: STEP_CONFIRM_EDIT,
    label: '確認',
    active: false,
  },
];

export const HEADER_SEND_MAIL_USERS = [
  {
    isFreeze: true,
    field: 'rowId',
    name: '',
    width: '45px',
    type: 'checkbox',
  },
  {
    isFreeze: true,
    field: USER_ID,
    name: 'ユーザーID',
    width: 'max-content',
    minWidth: '150px',
  },
  {
    field: COMPANY_NAME,
    name: '会社名',
    width: '201px',
  },
  {
    field: CHARGE_NAME,
    name: '名前',
    width: '121px',
  },
  {
    field: EMAIL,
    name: 'メールアドレス',
    width: 'max-content',
    minWidth: '231px',
  },
  {
    field: AGENT_FIELD,
    name: 'エージェント',
    width: '109px',
    variant: 'icon-check',
  },
];
