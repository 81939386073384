import {
  DASHBOARD,
  CATEGORY_ANALYZE,
  COMPARE_PERIOD,
  DETAILS_ANALYSIS,
  CV_FLOW,
  CV_ATTRIBUTE,
  COST_ALLOCATION,
  LOG_EBIS,
  USER_ANALYSIS,
  LPO_EBIS,
  USER_MANAGEMENT,
  AGENCY_MANAGEMENT,
  TAG_MANAGEMENT,
  AD_MANAGEMENT,
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_OTHER,
  LTV_SETTINGS_CLEAR_CSV_ORDERS,
  LTV_ANALYZE,
  LANDING_PAGE_ANALYZE,
  EXTERNAL_SERVICE_SETTINGS,
  USER_PROFILE,
  MEDIA_SYNC_MANAGEMENT,
  DATA_EXPORT,
  USAGE_STATUS_SITE,
  API_MANAGEMENT,
  CONTACT_DEMAND_MANAGEMENT,
  CONTACT_MANAGEMENT,
  SYSTEM_SETTING,
  SYSTEM_SETTING_COOKIE_PROGRAM,
  MEASUREMENT_SITE_PAGE_MANAGEMENT,
  IMPRESSION_AD_COST,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  LOG_ROUTE_ANALYZE,
  CAPI_SETTING,
  SEARCH_CONSOLE_SETTING,
  LPO_LINK,
  LPO_PERIOD,
  INFLOW_ANALYSIS,
  LINE_ADD_FRIEND_SETTINGS,
} from 'services/routes/constants';
import * as permissions from 'domain/permissions/contractPermissions';
import { TAB_AD, TAB_ALL, TAB_COMMON } from 'services/consts';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';

export const phase1AdPermissions = [
  permissions.AD_ANALYSIS_ITEM_LOG_VIEW,
  permissions.AD_ANALYSIS_ITEM_EXCLUDE_LOG_VIEW,
];

export const phase1AllPermissions = [
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_AD_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_ITEM_AD_VIEW,
];

export const phase1CommonPermissions = [
  permissions.AD_ANALYSIS_ITEM_LOG_VIEW,
  permissions.AD_ANALYSIS_ITEM_EXCLUDE_LOG_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_AD_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_ITEM_AD_VIEW,
];

export const redirectScreenConfigs = [
  { screenId: DASHBOARD, tab: TAB_AD },
  { screenId: DASHBOARD, tab: TAB_ALL },
  { screenId: CATEGORY_ANALYZE, tab: TAB_AD },
  { screenId: CATEGORY_ANALYZE, tab: TAB_ALL },
  { screenId: COST_ALLOCATION, tab: TAB_AD },
  { screenId: USER_MANAGEMENT, tab: null },
  { screenId: AGENCY_MANAGEMENT, tab: null },
  {
    screenId: TAG_MANAGEMENT,
    tab: TAG_MANAGEMENT_TAB.CV,
  },
  {
    screenId: TAG_MANAGEMENT,
    tab: TAG_MANAGEMENT_TAB.PV,
  },
  {
    screenId: TAG_MANAGEMENT,
    tab: TAG_MANAGEMENT_TAB.CONTENT_CATEGORY,
    resolveType: 'allBy',
  },
  { screenId: AD_MANAGEMENT, tab: null },
  { screenId: LTV_ANALYZE, tab: TAB_AD },
  { screenId: LTV_ANALYZE, tab: TAB_ALL },
];

export const ltvAllPermissions = [
  permissions.AD_ANALYSIS_LTV_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_LTV_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_LTV_AD_ONLY_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_LTV_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_LTV_AD_ONLY_VIEW,
  permissions.SETTING_LTV_BASE_EDIT,
  permissions.SETTING_LTV_AD_LINKING_EDIT,
  permissions.MANAGE_LTV_EDIT,
];

export const dataExportPermissionsAccess = [
  permissions.DATA_EXPORT_VIEW,
  permissions.DATA_EXPORT_AD_REPO_VIEW,
  permissions.AD_ANALYSIS_ITEM_EXCLUDE_LOG_VIEW,
  permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
];

const permissionConfigs = {
  [DASHBOARD]: {
    entry: [],
    [TAB_AD]: [permissions.AD_DASHBOARD_VIEW],
    [TAB_ALL]: [
      permissions.ALL_DASHBOARD_CHNL_CLK_SRCH_VIEW,
      permissions.ALL_DASHBOARD_CHNL_CLK_SRCH_PV_VIEW,
      permissions.ALL_DASHBOARD_CHNL_CLK_SRCH_ITEM_AD_VIEW,
      permissions.ALL_DASHBOARD_CHNL_CLK_SRCH_PV_ITEM_AD_VIEW,
    ],
  },
  // Remember to change [INFLOW_ANALYSIS] when change this
  [CATEGORY_ANALYZE]: {
    entry: [],
    [TAB_AD]: phase1AdPermissions,
    [TAB_ALL]: phase1AllPermissions,
    [TAB_COMMON]: phase1CommonPermissions,
  },
  // Remember to change [INFLOW_ANALYSIS] when change this
  [COMPARE_PERIOD]: {
    entry: [],
    [TAB_AD]: phase1AdPermissions,
    [TAB_ALL]: phase1AllPermissions,
    [TAB_COMMON]: phase1CommonPermissions,
  },
  [DETAILS_ANALYSIS]: {
    entry: [],
    [TAB_AD]: phase1AdPermissions,
    [TAB_ALL]: phase1AllPermissions,
    [TAB_COMMON]: phase1CommonPermissions,
  },
  [LANDING_PAGE_ANALYZE]: {
    entry: [],
    [TAB_AD]: phase1AdPermissions,
    [TAB_ALL]: phase1AllPermissions,
    [TAB_COMMON]: phase1CommonPermissions,
  },
  [CV_FLOW]: {
    entry: [],
    [TAB_AD]: phase1AdPermissions,
    [TAB_ALL]: phase1AllPermissions,
    [TAB_COMMON]: phase1CommonPermissions,
  },
  [CV_ATTRIBUTE]: {
    entry: [],
    [TAB_AD]: phase1AdPermissions,
    [TAB_ALL]: phase1AllPermissions,
    [TAB_COMMON]: phase1CommonPermissions,
  },
  // Remember to change [INFLOW_ANALYSIS] when change this
  [COST_ALLOCATION]: {
    entry: [],
    [TAB_AD]: [permissions.AD_ANALYSIS_COST_ALLOCATION_VIEW],
    /**
     * Cost Allocation page handles the redirect
     * CA page inits logic to redirect but will not render page content
     */
    [TAB_ALL]: [permissions.AD_ANALYSIS_COST_ALLOCATION_VIEW],
    [TAB_COMMON]: [permissions.AD_ANALYSIS_COST_ALLOCATION_VIEW],
  },
  [LPO_EBIS]: {
    entry: [],
    [TAB_AD]: [permissions.AD_ANALYSIS_LPO_VIEW],
    [TAB_ALL]: [],
  },
  [LPO_LINK]: {
    entry: [],
    [TAB_AD]: [permissions.AD_ANALYSIS_LPO_VIEW],
    [TAB_ALL]: [],
    [TAB_COMMON]: [permissions.AD_ANALYSIS_LPO_VIEW],
  },
  [LPO_PERIOD]: {
    entry: [],
    [TAB_AD]: [permissions.AD_ANALYSIS_LPO_VIEW],
    [TAB_ALL]: [],
    [TAB_COMMON]: [permissions.AD_ANALYSIS_LPO_VIEW],
  },
  [USER_ANALYSIS]: {
    entry: [],
    [TAB_AD]: [],
    [TAB_ALL]: [permissions.ALL_USER_ANALYSIS_USER_PF_VIEW],
  },
  [USER_MANAGEMENT]: {
    entry: [permissions.SETTING_USER_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [AGENCY_MANAGEMENT]: {
    entry: [permissions.SETTING_AGENCY_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [TAG_MANAGEMENT]: {
    resolveType: 'allBy',
    entry: [],
    [TAG_MANAGEMENT_TAB.CV]: [permissions.SETTING_TAG_TAG_MANAGER_EDIT],
    [TAG_MANAGEMENT_TAB.PV]: [
      permissions.SETTING_TAG_TAG_MANAGER_EDIT,
      permissions.SETTING_TAG_PV_EDIT,
    ],
    [TAG_MANAGEMENT_TAB.CONTENT_CATEGORY]: [
      permissions.SETTING_TAG_TAG_MANAGER_EDIT,
      permissions.SETTING_TAG_CONTENT_CATEGORY_EDIT,
    ],
  },
  [AD_MANAGEMENT]: {
    entry: [permissions.SETTING_AD_AD_MANAGE_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [AD_MANAGEMENT_MEDIA_TYPE]: {
    entry: [permissions.SETTING_AD_MEDIA_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [AD_MANAGEMENT_AD_GROUP_1]: {
    entry: [permissions.SETTING_AD_AD_GROUP1_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [AD_MANAGEMENT_AD_GROUP_2]: {
    entry: [permissions.SETTING_AD_AD_GROUP2_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [LTV_ANALYZE]: {
    entry: [],
    [TAB_AD]: [permissions.AD_ANALYSIS_LTV_VIEW],
    [TAB_ALL]: [
      permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_LTV_VIEW,
      permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_LTV_AD_ONLY_VIEW,
      permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_LTV_VIEW,
      permissions.ALL_ANALYSIS_CHNL_CLK_SRCH_PV_LTV_AD_ONLY_VIEW,
    ],
  },
  [LTV_SETTINGS_PRODUCT]: {
    entry: [permissions.SETTING_LTV_BASE_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [LTV_SETTINGS_OFFER]: {
    entry: [permissions.SETTING_LTV_BASE_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [LTV_SETTINGS_CONDITION]: {
    entry: [permissions.SETTING_LTV_BASE_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [LTV_SETTINGS_AD]: {
    entry: [permissions.SETTING_LTV_AD_LINKING_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [LTV_SETTINGS_OTHER]: {
    entry: [permissions.SETTING_LTV_BASE_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [LTV_SETTINGS_CLEAR_CSV_ORDERS]: {
    entry: [permissions.MANAGE_LTV_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [USER_PROFILE]: {
    entry: [
      permissions.ALL_ANALYSIS_USER_PF_CHNL_CLK_SRCH_PV_ITEM_LOG_VIEW,
      permissions.ALL_ANALYSIS_USER_PF_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
    ],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [EXTERNAL_SERVICE_SETTINGS]: {
    entry: [permissions.SETTING_EXTERNAL_LINKS_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [MEDIA_SYNC_MANAGEMENT]: {
    entry: [permissions.MEDIA_SYNC_MANAGEMENT_VIEW],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [DATA_EXPORT]: {
    entry: dataExportPermissionsAccess,
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [API_MANAGEMENT]: {
    entry: [permissions.SETTING_EBIS_API_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [SYSTEM_SETTING]: {
    entry: [permissions.SETTING_SYSTEM_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [USAGE_STATUS_SITE]: {
    entry: [permissions.ACCOUNT_USAGE_VIEW],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [MEASUREMENT_SITE_PAGE_MANAGEMENT]: {
    entry: [permissions.SETTING_PAGE_MANAGEMENT_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [LINE_ADD_FRIEND_SETTINGS]: {
    entry: [permissions.LINE_SETTING_VIEW],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [SYSTEM_SETTING_COOKIE_PROGRAM]: {
    entry: [permissions.SETTING_SYSTEM_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [LOG_EBIS]: {
    entry: [],
    [TAB_AD]: [],
    [TAB_ALL]: [permissions.ALL_LOG_VIEW],
  },
  [LOG_PAGE_ANALYZE]: {
    entry: [],
    // [TAB_AD]: [], // If current tab is AD then logic will redirect to firstAllowedScreen
    [TAB_ALL]: [permissions.ALL_LOG_VIEW],
  },
  [LOG_PERIOD_ANALYZE]: {
    entry: [],
    // [TAB_AD]: [], // If current tab is AD then logic will redirect to firstAllowedScreen
    [TAB_ALL]: [permissions.ALL_LOG_VIEW],
  },
  [LOG_ROUTE_ANALYZE]: {
    entry: [],
    // [TAB_AD]: [], // If current tab is AD then logic will redirect to firstAllowedScreen
    [TAB_ALL]: [permissions.ALL_LOG_VIEW],
  },
  [LOG_EBIS]: {
    entry: [],
    [TAB_AD]: [],
    [TAB_ALL]: [permissions.ALL_LOG_VIEW],
  },
  [LOG_PAGE_ANALYZE]: {
    entry: [],
    [TAB_AD]: [],
    [TAB_ALL]: [permissions.ALL_LOG_VIEW],
    [TAB_COMMON]: [permissions.ALL_LOG_VIEW],
  },
  [LOG_PERIOD_ANALYZE]: {
    entry: [],
    [TAB_AD]: [],
    [TAB_ALL]: [permissions.ALL_LOG_VIEW],
    [TAB_COMMON]: [permissions.ALL_LOG_VIEW],
  },
  [LOG_ROUTE_ANALYZE]: {
    entry: [],
    [TAB_AD]: [],
    [TAB_ALL]: [permissions.ALL_LOG_VIEW],
  },
  // screens allow access without permission
  [CONTACT_DEMAND_MANAGEMENT]: {
    resolveType: 'permitted',
    entry: [],
  },
  [CONTACT_MANAGEMENT]: {
    resolveType: 'permitted',
    entry: [],
  },
  [IMPRESSION_AD_COST]: {
    entry: [permissions.SETTING_COST_VIEW_AD_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  [CAPI_SETTING]: {
    entry: [permissions.CAPI_SETTING_EDIT],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  // for CustomViewModal
  // Requires permission settings for [CATEGORY_ANALYZE], [COMPARE_PERIOD], [COST_ALLOCATION]
  [INFLOW_ANALYSIS]: {
    entry: [],
    [TAB_AD]: phase1AdPermissions.concat([
      permissions.AD_ANALYSIS_COST_ALLOCATION_VIEW,
    ]),
    [TAB_ALL]: phase1AllPermissions,
    [TAB_COMMON]: phase1CommonPermissions.concat([
      permissions.AD_ANALYSIS_COST_ALLOCATION_VIEW,
    ]),
  },
  [SEARCH_CONSOLE_SETTING]: {
    entry: [permissions.SC_SETTING_VIEW],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
  default: {
    entry: [],
    [TAB_AD]: [],
    [TAB_ALL]: [],
  },
};

const handler = {
  get(target, name) {
    return Object.prototype.hasOwnProperty.call(target, name)
      ? target[name]
      : permissionConfigs.default;
  },
};

const screenPermissionConfigs = new Proxy(permissionConfigs, handler);

export default screenPermissionConfigs;
