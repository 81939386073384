import { createSelector } from 'reselect';
import displayItemsSelectors from 'store/display-items/selectors';
import settingsSelectors from 'store/settings/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import commonSelectors from 'store/common/selectors';
import chartService from 'services/period-analyze/chartService';
import periodAnalysisService from 'services/period-analyze/periodAnalysisService';
import { formatPeriodField } from 'services/log/periodAnalyzeService';
import { createHeadersByDisplayItems } from 'views/organism/GridTable/GridTableService';
import { CV_METRICS } from 'domain/consts';
import { clonePeriod } from 'services/utils';
import { convertApiDetail } from 'domain/responseUtils';
import {
  CNT_PV,
  CNT_SESSION,
  CNT_UU,
  CNT_CV_TOTAL,
  DISPLAY_GROUP_ITEM,
  PERIOD,
} from 'domain/settings/display-items';
import {
  getSortString,
  createFieldsFromConversions,
  buildPeriodRange,
} from 'domain/utils';
import {
  PERIOD_TYPE,
  PERIOD_TYPE_TO_API_MAP,
} from 'domain/period-analyze/consts';

const getStatus = (state) => state.cache.logPeriodAnalyze.status.table;

const getSettingSort = (state) => state.cache.logPeriodAnalyze.settings.sort;
const getSettingComparedSort = (state) =>
  state.cache.logPeriodAnalyze.settings.comparedSort;
const getSettingPaging = (state) =>
  state.cache.logPeriodAnalyze.settings.paging;

const getDataSum = (state) => state.cache.logPeriodAnalyze.data.table.sum;
const getDataDetail = (state) => state.cache.logPeriodAnalyze.data.table.detail;
const getMetadataTotal = (state) =>
  state.cache.logPeriodAnalyze.data.table.metadata.count || 0;

const getDataComparedSum = (state) =>
  state.cache.logPeriodAnalyze.data.table.comparedSum;
const getDataComparedDetail = (state) =>
  state.cache.logPeriodAnalyze.data.table.comparedDetail;
const getMetadataComparedTotal = (state) =>
  state.cache.logPeriodAnalyze.data.table.comparedMetadata.count || 0;
const periodTypeSelector = (state) =>
  state.cache.logPeriodAnalyze.settings.periodType;

const getStatusChart = (state) => state.cache.logPeriodAnalyze.status.chart;
const getDataChartList = (state) =>
  state.cache.logPeriodAnalyze.data.chart.list;
const getDataChartListCompared = (state) =>
  state.cache.logPeriodAnalyze.data.chart.comparedList;
const getDataChartCategories = (state) =>
  state.cache.logPeriodAnalyze.data.chart.categories;
const getSettingChartCategories = (state) =>
  state.cache.logPeriodAnalyze.settings.chart.categories;
const getSettingChartAxis = (state) =>
  state.cache.logPeriodAnalyze.settings.chart.axis;
const getSettingChartEnabled = (state) =>
  state.cache.logPeriodAnalyze.settings.chart.enabled;
const getSettingChartBookmark = (state) =>
  state.cache.logPeriodAnalyze.settings.chart.bookmark;

const getPeriodTypeForApi = createSelector(
  [periodTypeSelector],
  (periodType) => PERIOD_TYPE_TO_API_MAP[periodType]
);

const periodSelector = (state) => state.commonState.CommonState.settings.period;

const showModalSelector = (state) =>
  state.cache.logPeriodAnalyze.settings.memo.showModal;
const showDeleteModalSelector = (state) =>
  state.cache.logPeriodAnalyze.settings.memo.showDeleteModal;
const memoModalDataSelector = (state) =>
  state.cache.logPeriodAnalyze.settings.memo.modalData;
const memoModalResetSelector = (state) =>
  state.cache.logPeriodAnalyze.settings.memo.modalReset;
const memoSubmittingSelector = (state) =>
  state.cache.logPeriodAnalyze.status.memo;
const memoErrorsSelector = (state) =>
  state.cache.logPeriodAnalyze.data.memo.errors;
const memoErrorModalSelector = (state) =>
  state.cache.logPeriodAnalyze.settings.memo.showErrorModal;
const memoListSelector = (state) => state.cache.logPeriodAnalyze.data.memo.list;

const memoEnabledSelector = (state) =>
  state.cache.logPeriodAnalyze.settings.memo.enabled;
const memoHiddenSelector = (state) =>
  state.cache.logPeriodAnalyze.settings.memo.hidden;
const periodRangeSelector = (state) =>
  state.cache.logPeriodAnalyze.settings.periodRange;
const chartDisplayDataSelector = (state) =>
  state.cache.logPeriodAnalyze.data.chart.list;

const getCurrentPeriodActive = (state) =>
  state.cache.logPeriodAnalyze.settings.currentPeriodActive;

const ConversionsByMetricsSelector = createSelector(
  [
    masterDataSelectors.conversionsForDisplaySelector,
    displayItemsSelectors.getMetricsSelector,
  ],
  (conversions, metrics) => {
    return createFieldsFromConversions(
      conversions,
      metrics.filter((metric) => CV_METRICS.includes(metric))
    );
  }
);

// updating and fixing
const dimensionsSelector = createSelector(
  [
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (dimensions, displayItems) => {
    const result = displayItems
      .filter((item) => dimensions.includes(item.key))
      .map((item) => item.key);
    return result;
  }
);

const metricsSelector = createSelector(
  [
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (metrics, displayItems) => {
    return displayItems
      .filter((item) => metrics.includes(item.key))
      .map((item) => item.key);
  }
);

const sortSelector = createSelector(
  [
    dimensionsSelector,
    metricsSelector,
    ConversionsByMetricsSelector,
    getSettingSort,
  ],
  (dimensions, metrics, conversions, sort) => {
    const allowItems = [...dimensions, ...metrics];
    conversions
      .filter((conversion) => conversion.children.length > 0)
      .forEach((conversion) =>
        conversion.children.map((item) => allowItems.push(item.name))
      );

    if (sort && allowItems.includes(sort.field)) {
      return { sort: getSortString(sort) };
    }

    return {};
  }
);

const comparedSortSelector = createSelector(
  [
    dimensionsSelector,
    metricsSelector,
    ConversionsByMetricsSelector,
    getSettingComparedSort,
  ],
  (dimensions, metrics, conversions, sort) => {
    const allowItems = [...dimensions, ...metrics];
    conversions
      .filter((conversion) => conversion.children.length > 0)
      .forEach((conversion) =>
        conversion.children.map((item) => allowItems.push(item.name))
      );

    if (sort && allowItems.includes(sort.field)) {
      return { sort: getSortString(sort) };
    }

    return {};
  }
);

const headerSelector = createSelector(
  [
    settingsSelectors.getTab,
    displayItemsSelectors.getItemsReport,
    dimensionsSelector,
    metricsSelector,
    masterDataSelectors.conversionsForDisplaySelector,
    periodTypeSelector,
  ],
  (currentTab, displayItems, dimensions, metrics, conversions, periodType) => {
    const headers = createHeadersByDisplayItems({
      currentTab,
      displayItems,
      dimensions,
      metrics,
      conversions,
    });

    // format data date column
    headers[0].callback = (data) => {
      const converters = periodAnalysisService.addConverters({ periodType });
      const dataConverted = convertApiDetail(data, converters);

      return {
        ...data,
        [PERIOD]: formatPeriodField(periodType, dataConverted[PERIOD]),
      };
    };

    return headers;
  }
);

const getStateForTable = createSelector(
  [
    settingsSelectors.getLoadingByStatus([
      getStatus,
      displayItemsSelectors.getStatus,
      masterDataSelectors.getStatusConversions,
    ]),
    headerSelector,
    getDataSum,
    getDataDetail,
    getMetadataTotal,
    getSettingSort,
    getCurrentPeriodActive,
  ],
  (isLoading, headers, sum, detail, totalItems, sort, currentPeriodActive) => {
    return {
      isLoading,
      headers,
      rows: totalItems > 0 ? [sum, ...detail] : [],
      totalItems,
      settings: { sort },
      currentPeriodActive,
    };
  }
);

const periodForChart = createSelector(
  [
    commonSelectors.periodSelector,
    commonSelectors.comparePeriodSelector,
    periodTypeSelector,
  ],
  (period, comparedPeriod, periodType) => {
    let periodRange = buildPeriodRange(periodType, {
      start: period.start,
      end: period.end,
    });
    if (comparedPeriod.enabled) {
      const { DAY, MONTH } = PERIOD_TYPE;
      periodRange = buildPeriodRange(
        periodType,
        chartService.buildExtendedPeriod(
          period,
          comparedPeriod,
          periodType === MONTH ? MONTH : DAY
        )
      );
    }

    return {
      period: clonePeriod(period),
      periodType,
      periodRange,
      comparedPeriod: clonePeriod(comparedPeriod),
    };
  }
);

const getStateForComparedTable = createSelector(
  [
    getDataComparedSum,
    getDataComparedDetail,
    getMetadataComparedTotal,
    getSettingComparedSort,
  ],
  (comparedSum, comparedDetail, comparedTotalItems, comparedSort) => {
    return {
      comparedRows:
        comparedTotalItems > 0 ? [comparedSum, ...comparedDetail] : [],
      comparedTotalItems,
      comparedSort,
    };
  }
);

const seriesSelector = createSelector(
  [
    getDataChartList,
    getDataChartListCompared,
    getSettingChartAxis,
    periodForChart,
    dimensionsSelector,
    getDataChartCategories,
    getSettingChartCategories,
    memoListSelector,
    memoEnabledSelector,
  ],
  (
    list,
    comparedList,
    axis,
    period,
    dimensions,
    visibleList,
    selectedCategories,
    memoList,
    memoEnabled
  ) => {
    if (list.length === 0) return [];

    return chartService.createChartSeriesList({
      ...period,
      dimensions,
      axis,
      list,
      comparedList,
      selectedCategories,
      visibleList,
      memoList,
      memoEnabled,
    });
  }
);

const metricForChart = createSelector(
  [
    displayItemsSelectors.getUserPermittedItems,
    displayItemsSelectors.getDisplayItemPriorityAxis,
  ],
  (displayItems, priorityAxis) => {
    const allowMetrics = [CNT_PV, CNT_SESSION, CNT_UU, CNT_CV_TOTAL];
    return Object.entries(displayItems)
      .sort((a, b) => a[1].order - b[1].order)
      .reduce((acc, [key]) => {
        const { title, getGroup = () => {} } = displayItems[key];
        if (
          getGroup(priorityAxis) !== DISPLAY_GROUP_ITEM ||
          !allowMetrics.includes(key)
        ) {
          return acc;
        }

        return { ...acc, [key]: title };
      }, {});
  }
);

const usePeriodAxisOnlySelector = createSelector(
  [displayItemsSelectors.getDimensionsSelector],
  (dimensions) => {
    return dimensions.length === 1 && dimensions[0] === PERIOD;
  }
);

const getStatesForChart = createSelector(
  [
    settingsSelectors.getLoadingByStatus([
      getStatusChart,
      displayItemsSelectors.getStatus,
    ]),
    getDataChartList,
    getSettingChartEnabled,
    getSettingChartBookmark,
    settingsSelectors.getTab,
    periodForChart,
    getSettingChartAxis,
    seriesSelector,
    metricForChart,
    getDataChartCategories,
    getSettingChartCategories,
    usePeriodAxisOnlySelector,
    memoEnabledSelector,
    memoHiddenSelector,
    periodTypeSelector,
  ],
  (
    isLoading,
    list,
    enabled,
    bookmark,
    currentTab,
    period,
    axis,
    series,
    metric,
    categories,
    selectedCategories,
    usePeriodAxisOnly,
    memoEnabled,
    memoHidden
  ) => {
    return {
      isLoading,
      isEmptyData: list.length === 0,
      enabled,
      bookmark,
      currentTab,
      period,
      axis,
      series,
      metric,
      categories,
      selectedCategories,
      usePeriodAxisOnly,
      memoEnabled,
      memoHidden,
    };
  }
);

const getMemoStates = createSelector(
  [
    memoModalDataSelector,
    showModalSelector,
    periodSelector,
    memoModalResetSelector,
    showDeleteModalSelector,
    memoSubmittingSelector,
    memoErrorsSelector,
    memoErrorModalSelector,
  ],
  (
    modalData,
    showModal,
    period,
    modalReset,
    showDelete,
    memoSubmitting,
    errors,
    showError
  ) => ({
    modalData,
    showModal,
    period,
    modalReset,
    showDelete,
    memoSubmitting,
    errors,
    showError,
  })
);

export default {
  getStateForTable,
  getStatesForChart,
  metricForChart,
  getMetadataTotal,
  getSettingPaging,
  sortSelector,
  comparedSortSelector,
  getStateForComparedTable,
  dimensionsSelector,
  metricsSelector,
  periodTypeSelector,
  getPeriodTypeForApi,
  usePeriodAxisOnlySelector,
  getSettingChartAxis,
  getMemoStates,
  getCurrentPeriodActive,
  memoEnabledSelector,
  memoHiddenSelector,
  memoListSelector,
  chartDisplayDataSelector,
  periodRangeSelector,
  getSettingChartEnabled,
};
