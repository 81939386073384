const getList = (state) => {
  const { byIds } = state.cache.ltvSettingsOther;
  return byIds;
};
const getById = (state, viewId) => {
  const { data } = state;
  if (!Object.prototype.hasOwnProperty.call(data, viewId)) {
    throw new Error(`Does not found view ${viewId}`);
  }
  const { id, view_name: name, status: selected } = data[viewId];
  return { id, name, selected };
};

const getData = (state) => {
  const { data } = state.cache.ltvSettingsOther;
  return data;
};

const getOptions = (state) => {
  const { options } = state.cache.ltvSettingsOther;
  return options;
};

const getStatus = (state) => {
  const { status } = state.cache.ltvSettingsOther;
  return status;
};

const getSelectedUser = (state) => {
  const { selected } = state.cache.ltvSettingsOther;
  return selected;
};

const getApiError = (state) => {
  return state.cache.ltvSettingsOther.error?.data?.errors;
};

const getCurrentUserNumber = (state) =>
  state.cache.ltvSettingsOther.control.currentUsers;

export default {
  getList,
  getById,
  getCurrentUserNumber,
  getStatus,
  getData,
  getSelectedUser,
  getApiError,
  getOptions,
};
