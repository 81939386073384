import types from 'store/period-analyze/types';
import helper from 'store/helper';
import loggerConstants from 'store/logger/constant';

const {
  GET_CHART_REPORT,
  GET_COMPARED_CHART_REPORT,
  GET_COMPARED_TABLE_REPORT,
  GET_TABLE_REPORT,
  APPLY_BOOKMARK,
  SET_SHOW_CHART,
  SET_TABLE_STATUS,
  SET_COMPARED_TABLE_STATUS,
  SET_CHART_STATUS,
  UPDATE_CHART_DATA,
  UPDATE_COMPARED_CHART_DATA,
  UPDATE_TABLE_DATA,
  UPDATE_COMPARED_TABLE_DATA,
  CLEAN_STATES,
  UPDATE_TABLE_DISPLAY_ROWS,
  UPDATE_CHART_DISPLAY_MEMO,
  SET_MEMO_SUBMITTING,
  SET_PERIOD_RANGE,
  SET_COMPARED_PERIOD_RANGE,
  SET_MEMO_ERRORS,
  LOG_ERROR,
  REMOVE_SECONDARY_METRIC,
  REFRESH_TABLE_CURRENT,
  REFRESH_TABLE_COMPARED,
} = types;

const changeAxis = ({ primary, secondary }) => ({
  type: types.UPDATE_AXIS,
  payload: { primary, secondary },
});

const removeSecondaryMetric = () =>
  helper.createAction(REMOVE_SECONDARY_METRIC);

const getBookmark = () => ({
  type: types.GET_BOOKMARK,
});

const updateBookmark = ({ primary, secondary, enabled }) => ({
  type: types.UPDATE_BOOKMARK,
  payload: { primary, secondary, enabled },
});

const updateCategories = (payload) => ({
  type: types.UPDATE_CATEGORIES,
  payload,
});

const setTableCurrentPage = (page) => ({
  type: types.SET_TABLE_CURRENT_PAGE,
  payload: page,
});

const updateSort = (sorts) => ({
  type: types.UPDATE_SORT,
  payload: sorts,
});

const selectRow = (dimensionKey) => ({
  type: types.SELECT_ROW,
  payload: dimensionKey,
});

const deselectRow = (dimensionKey) => ({
  type: types.DESELECT_ROW,
  payload: dimensionKey,
});

const setTableCurrentPageCompared = (page) => ({
  type: types.SET_TABLE_CURRENT_PAGE_COMPARED,
  payload: page,
});

const updateSortCompared = (sorts) => ({
  type: types.UPDATE_SORT_COMPARED,
  payload: sorts,
});

const selectRowCompared = (dimensionKey) => ({
  type: types.SELECT_ROW_COMPARED,
  payload: dimensionKey,
});

const deselectRowCompared = (dimensionKey) => ({
  type: types.DESELECT_ROW_COMPARED,
  payload: dimensionKey,
});

const updateVisible = ({ dimensionKey, visible }) => ({
  type: types.UPDATE_VISIBLE,
  payload: { dimensionKey, visible },
});

const updatePeriodType = ({ periodType }) => ({
  type: types.UPDATE_PERIOD_TYPE,
  payload: { periodType },
});

const toggleMemo = ({ enabled }) => ({
  type: types.TOGGLE_MEMO,
  payload: { enabled },
});

const createMemo = ({ category, memoStart, memoEnd, content }) => ({
  type: types.CREATE_MEMO,
  payload: { category, memoStart, memoEnd, content },
});

const createMemoSuccess = () => ({
  type: types.CREATE_MEMO_SUCCESS,
});

const createMemoError = ({ error }) => ({
  type: types.CREATE_MEMO_ERROR,
  payload: { error },
});

const changeActiveTable = ({ currentPeriodActive }) => ({
  type: types.CHANGE_ACTIVE_TABLE,
  payload: { currentPeriodActive },
});

const setShowChart = (show) => ({
  type: SET_SHOW_CHART,
  payload: show,
});

const setShowModal = ({ id, reset }) => ({
  type: types.SHOW_MODAL,
  payload: { id, reset },
});

const setHideModal = () => ({
  type: types.HIDE_MODAL,
});

const setShowDeleteModal = () => ({
  type: types.SHOW_DELETE_MODAL,
});

const setHideDeleteModal = () => ({
  type: types.HIDE_DELETE_MODAL,
});

const showMemoErrorModal = () => ({
  type: types.SHOW_MEMO_ERROR_MODAL,
  scope: loggerConstants.SCOPE_DONT_SHOW,
});

const hideMemoErrorModal = () => ({
  type: types.HIDE_MEMO_ERROR_MODAL,
  scope: loggerConstants.SCOPE_DONT_SHOW,
});

const loadMemo = () => ({
  type: types.LOAD_MEMO,
});

const loadMemoSuccess = (memoList) => ({
  type: types.LOAD_MEMO_SUCCESS,
  payload: memoList,
});

const deleteMemo = (id) => ({
  type: types.DELETE_MEMO,
  payload: id,
});

const deleteMemoSuccess = () => ({
  type: types.DELETE_MEMO_SUCCESS,
});

const updateMemo = ({ id, category, memoStart, memoEnd, content }) => ({
  type: types.UPDATE_MEMO,
  payload: { id, category, memoStart, memoEnd, content },
});

const updateMemoSuccess = () => ({
  type: types.UPDATE_MEMO_SUCCESS,
});

const updateMemoList = ({ memoList }) => ({
  type: types.UPDATE_MEMO_LIST,
  payload: { memoList },
});

const getChartReport = () => ({
  type: GET_CHART_REPORT,
});

const getComparedChartReport = () => ({
  type: GET_COMPARED_CHART_REPORT,
});

const getTableReport = () => ({
  type: GET_TABLE_REPORT,
});

const getComparedTableReport = () => ({
  type: GET_COMPARED_TABLE_REPORT,
});

const updateChartData = ({
  list,
  selectedCategories,
  displayData,
  categories,
  visibleList,
}) => ({
  type: UPDATE_CHART_DATA,
  payload: { list, selectedCategories, displayData, categories, visibleList },
});

const updateComparedChartData = ({
  list,
  comparedList,
  selectedCategories,
  displayData,
  visibleList,
}) => ({
  type: UPDATE_COMPARED_CHART_DATA,
  payload: { list, comparedList, selectedCategories, displayData, visibleList },
});

const updateTableData = ({
  list,
  sum,
  displayRows,
  displayHeaders,
  displaySum,
  count,
  selectedRows,
}) => ({
  type: UPDATE_TABLE_DATA,
  payload: {
    list,
    sum,
    displayRows,
    displayHeaders,
    displaySum,
    count,
    selectedRows,
  },
});

const updateComparedTableData = ({
  comparedList,
  comparedSum,
  displayRows,
  displayHeaders,
  displaySum,
  selectedRows,
  count,
}) => ({
  type: UPDATE_COMPARED_TABLE_DATA,
  payload: {
    comparedList,
    comparedSum,
    displayRows,
    displayHeaders,
    displaySum,
    selectedRows,
    count,
  },
});

const updateTableDisplayRows = (data) => ({
  type: UPDATE_TABLE_DISPLAY_ROWS,
  payload: data,
});

const applyBookmark = (
  { primary, secondary, enabled, showChart },
  shouldApplyMetrics
) => ({
  type: APPLY_BOOKMARK,
  payload: { primary, secondary, enabled, showChart, shouldApplyMetrics },
});

const cleanStates = () => ({
  type: CLEAN_STATES,
});

const setTableStatus = (status) => ({
  type: SET_TABLE_STATUS,
  payload: status,
});

const setComparedTableStatus = (status) => ({
  type: SET_COMPARED_TABLE_STATUS,
  payload: status,
});

const setChartStatus = (status) => ({
  type: SET_CHART_STATUS,
  payload: status,
});

const updateChartDisplayMemo = (displayData) => ({
  type: UPDATE_CHART_DISPLAY_MEMO,
  payload: displayData,
});

const setMemoSubmitting = (submitting) => ({
  type: SET_MEMO_SUBMITTING,
  payload: submitting,
});

const setPeriodRange = (period, comparedPeriod, periodType) =>
  helper.createAction(SET_PERIOD_RANGE, { period, comparedPeriod, periodType });
const setComparedPeriodRange = (period, periodType) =>
  helper.createAction(SET_COMPARED_PERIOD_RANGE, { period, periodType });

const updateChartDisplayData = (data) =>
  helper.createAction(types.UPDATE_CHART_DISPLAY_DATA, data);

const setMemoErrors = (errors) => ({
  type: SET_MEMO_ERRORS,
  payload: errors,
  scope: loggerConstants.SCOPE_DONT_SHOW,
});

const logError = (errors, scope) => ({
  type: LOG_ERROR,
  payload: errors,
  scope,
});

const refreshTableCurrent = () => ({
  type: REFRESH_TABLE_CURRENT,
});

const refreshTableCompared = () => ({
  type: REFRESH_TABLE_COMPARED,
});

export default {
  changeAxis,
  removeSecondaryMetric,
  getBookmark,
  updateBookmark,
  updateCategories,
  setTableCurrentPage,
  updateSort,
  selectRow,
  deselectRow,
  updateVisible,

  updatePeriodType,
  toggleMemo,
  createMemo,
  createMemoSuccess,
  createMemoError,
  changeActiveTable,
  setTableCurrentPageCompared,
  updateSortCompared,
  selectRowCompared,
  deselectRowCompared,
  setShowChart,
  setShowModal,
  setHideModal,
  setShowDeleteModal,
  setHideDeleteModal,
  showMemoErrorModal,
  hideMemoErrorModal,

  loadMemo,
  loadMemoSuccess,
  deleteMemo,
  deleteMemoSuccess,
  updateMemo,
  updateMemoSuccess,

  updateMemoList,
  applyBookmark,

  getChartReport,
  getComparedChartReport,
  getTableReport,
  getComparedTableReport,

  cleanStates,

  updateChartData,
  updateComparedChartData,
  updateTableData,
  updateComparedTableData,
  updateTableDisplayRows,

  setChartStatus,
  setTableStatus,
  setComparedTableStatus,

  updateChartDisplayMemo,
  setMemoSubmitting,

  setPeriodRange,
  setComparedPeriodRange,

  updateChartDisplayData,
  setMemoErrors,
  logError,

  refreshTableCurrent,
  refreshTableCompared,
};
