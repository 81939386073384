import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  CHANNEL,
  CATEGORY,
  AD_GROUP1,
  AD_GROUP2,
  AGENCY,
} from 'domain/settings/display-items';
import AxisSelectbox from 'views/atoms/AxisSelectbox';
import './AxisGroup.scss';
import {
  MEDIA_SIDE_CAMPAIGN,
  MEDIA_SIDE_GROUP,
  SYNC_CATEGORY,
} from 'domain/fields';
import { PRIORITY_AXIS_EBIS, PRIORITY_AXIS_MEDIA_SYNC } from 'domain/consts';

const axisGroups = [
  {
    label: '1列目',
    name: 'axis1',
    helperText: '※グラフ/CSVに反映されます',
    level: 1,
  },
  {
    label: '2列目',
    name: 'axis2',
    helperText: '',
    level: 2,
  },
  {
    label: '3列目',
    name: 'axis3',
    helperText: '',
    level: 3,
  },
  {
    label: '4列目',
    name: 'axis4',
    helperText: '',
    level: 4,
  },
  {
    label: '5列目',
    name: 'axis5',
    helperText: '',
    level: 5,
  },
];

const ebisAxisLevel = {
  [CHANNEL]: 1,
  [CATEGORY]: 2,
  [AD_GROUP1]: 3,
  [AD_GROUP2]: 4,
  [AGENCY]: 5,
};

const mediaSyncAxisLevel = {
  [CHANNEL]: 1,
  [SYNC_CATEGORY]: 2,
  [MEDIA_SIDE_CAMPAIGN]: 3,
  [MEDIA_SIDE_GROUP]: 4,
  [AGENCY]: 5,
};

const TOTAL_AXIS_HAS_AGENCY = 5;
const TOTAL_AXIS_HAS_NO_AGENCY = 4;

const AxisGroup = ({
  items,
  defaultValue,
  onSelect,
  disabled,
  priorityAxis,
  customView,
}) => {
  const axisLevel = useMemo(() => {
    return priorityAxis === PRIORITY_AXIS_MEDIA_SYNC
      ? mediaSyncAxisLevel
      : ebisAxisLevel;
  }, [priorityAxis]);

  const [newItems, lengthAxisCurrent, lengthAxisTotal, hasAgencyAxis] = useMemo(() => {
    const keys = Object.keys(items);
    const hasAgency = keys.includes(AGENCY);
    const totalAxis = hasAgency
      ? TOTAL_AXIS_HAS_AGENCY
      : TOTAL_AXIS_HAS_NO_AGENCY;

    let level = 0;
    const cloneItems = keys
    .sort((a, b) => {
      let compare = 0;
      if (axisLevel[a] > axisLevel[b]) {
        compare = 1;
      } else if (axisLevel[a] < axisLevel[b]) {
        compare = -1;
      }
      return compare;
    })
    .reduce((acc, key) => {
      level += 1;
      return {...acc, [key]: {...items[key], level}};
    }, {});

    return [cloneItems, keys.length, totalAxis, hasAgency];
  }, [items]);

  // sort defaultValue order by level
  const defaultValueSorted = defaultValue.sort((a, b) => {
    let compare = 0;
    if (axisLevel[a] > axisLevel[b]) {
      compare = 1;
    } else if (axisLevel[a] < axisLevel[b]) {
      compare = -1;
    }
    return compare;
  });

  const [
    defaultValue1 = null,
    defaultValue2 = null,
    defaultValue3 = null,
    defaultValue4 = null,
    defaultValue5 = null,
  ] = defaultValueSorted;

  const [axisSelected, updateAxisSelected] = useState({
    [axisGroups[0].name]: defaultValue1,
    [axisGroups[1].name]: defaultValue2,
    [axisGroups[2].name]: defaultValue3,
    [axisGroups[3].name]: defaultValue4,
    [axisGroups[4].name]: defaultValue5,
  });

  const getOptions = useCallback(
    (currentLevel, isFirst = false) => {
      const options = {};
      const itemsKey = Object.keys(newItems);
      itemsKey.map((key) => {
        if (isFirst && itemsKey.length === lengthAxisTotal - 1) {
          if (newItems[key].level >= currentLevel) {
            options[key] = newItems[key];
          }
        } else if (isFirst && itemsKey.length === lengthAxisTotal) {
          if (newItems[key].level === currentLevel) {
            options[key] = newItems[key];
          }
        } else if (itemsKey.length === lengthAxisTotal && currentLevel === 2) {
          if (newItems[key].level >= currentLevel) {
            options[key] = newItems[key];
          }
        } else if (newItems[key].level === currentLevel) {
          options[key] = newItems[key];
          if (hasAgencyAxis) {
            options[AGENCY] = newItems[AGENCY];
          }
        }
        return key;
      });
      return options;
    },
    [newItems, lengthAxisTotal, hasAgencyAxis]
  );

  const getLevelByName = useCallback(
    (name) => {
      if (name in newItems && 'level' in newItems[name]) {
        return newItems[name].level;
      }
      return 1;
    },
    [newItems]
  );

  const getDefaultValue = useCallback(() => {
    return {
      [axisGroups[0].name]: getOptions(1, true),
      [axisGroups[1].name]: {},
      [axisGroups[2].name]: {},
      [axisGroups[3].name]: {},
      [axisGroups[4].name]: {},
    };
  }, [getOptions]);

  const [axisOptions, updateAxisOptions] = useState(getDefaultValue());

  useEffect(() => {
    updateAxisOptions(getDefaultValue());
  }, [priorityAxis, getDefaultValue]);

  // const handleAxisSelect = useCallback(
  const handleAxisSelect = (name, value) => {
    let currentLevel = 99;
    const newAxisSelected = {};
    const newAxisOptions = {};
    if (value !== null) {
      currentLevel = getLevelByName(value);
    }
    newAxisSelected[name] = value;
    // reset selected value & items for next selectbox
    if (axisGroups[0].name === name) {
      newAxisOptions[axisGroups[1].name] =
        value === null ? {} : getOptions(currentLevel + 1, true);
      newAxisOptions[axisGroups[2].name] = {};
      newAxisOptions[axisGroups[3].name] = {};
      newAxisOptions[axisGroups[4].name] = {};
      newAxisSelected[axisGroups[1].name] = null;
      newAxisSelected[axisGroups[2].name] = null;
      newAxisSelected[axisGroups[3].name] = null;
      newAxisSelected[axisGroups[4].name] = null;
    } else if (axisGroups[1].name === name) {
      newAxisOptions[axisGroups[2].name] =
        value === null ? {} : getOptions(currentLevel + 1);
      newAxisOptions[axisGroups[3].name] = {};
      newAxisOptions[axisGroups[4].name] = {};
      newAxisSelected[axisGroups[2].name] = null;
      newAxisSelected[axisGroups[3].name] = null;
      newAxisSelected[axisGroups[4].name] = null;
    } else if (axisGroups[2].name === name) {
      newAxisOptions[axisGroups[3].name] =
        value === null ? {} : getOptions(currentLevel + 1);
      newAxisSelected[axisGroups[3].name] = null;
      newAxisSelected[axisGroups[4].name] = null;
    } else if (axisGroups[3].name === name) {
      newAxisOptions[axisGroups[4].name] =
        value === null ? {} : getOptions(currentLevel + 1);
      newAxisSelected[axisGroups[4].name] = null;
    }
    updateAxisSelected((prev) => ({ ...prev, ...newAxisSelected }));
    const selected = Object.values({
      ...axisSelected,
      ...newAxisSelected,
    }).filter((item) => item);
    onSelect(selected);
    updateAxisOptions((prev) => ({
      ...prev,
      ...newAxisOptions,
    }));
  };

  useEffect(() => {
    const newAxisOptions = {};
    axisGroups.map((axis, index) => {
      let options = {};
      if (defaultValueSorted[index]) {
        const lev = getLevelByName(defaultValueSorted[index]);
        if (axis.level === lev) {
          options = getOptions(lev + 1);
        }
      }
      newAxisOptions[`axis${index + 2}`] = options;
      return axis;
    });
    updateAxisOptions((prev) => ({
      ...prev,
      ...newAxisOptions,
    }));
    const [
      value1 = null,
      value2 = null,
      value3 = null,
      value4 = null,
      value5 = null,
    ] = defaultValueSorted;
    updateAxisSelected({
      [axisGroups[0].name]: value1,
      [axisGroups[1].name]: value2,
      [axisGroups[2].name]: value3,
      [axisGroups[3].name]: value4,
      [axisGroups[4].name]: value5,
    });
  }, [defaultValueSorted, getLevelByName, getOptions]);

  const axisClass = classNames({
    'axis-group': !customView,
    'axis-group--view': customView,
  });

  const axisItemClass = classNames({
    'axis-group__item': !customView,
    'axis-group__item--five': !customView && lengthAxisCurrent === TOTAL_AXIS_HAS_AGENCY,
    'axis-group__item--view': customView,
  });

  return (
    <div className={axisClass}>
      {axisGroups.slice(0, lengthAxisCurrent).map((axisItem) => (
        <AxisSelectbox
          key={axisItem.name}
          className={axisItemClass}
          label={axisItem.label}
          name={axisItem.name}
          options={axisOptions[axisItem.name]}
          defaultValue={axisSelected[axisItem.name]}
          onSelect={handleAxisSelect}
          required={axisItem.level === 1}
          disabled={
            disabled ||
            Object.keys(axisOptions[axisItem.name]).length === 0 ||
            (lengthAxisCurrent === lengthAxisTotal && axisItem.level === 1)
          }
          customView={customView}
        />
      ))}
    </div>
  );
};

AxisGroup.propTypes = {
  items: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  priorityAxis: PropTypes.string,
  customView: PropTypes.bool,
};

AxisGroup.defaultProps = {
  defaultValue: [],
  onSelect: () => {},
  disabled: false,
  priorityAxis: PRIORITY_AXIS_EBIS,
  customView: false,
};

export default React.memo(AxisGroup);
