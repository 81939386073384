import {
  EXTERNAL,
  INTERNAL,
  USER_MANAGEMENT,
  AGENCY_MANAGEMENT,
  DNS_SETTING_SITE,
  MEASUREMENT_SITE_PAGE_MANAGEMENT,
  DELIVERY_MNGT_SITE,
  MEDIA_DATA_LINK_SETTING_SITE,
  AD_MANAGEMENT,
  TAG_MANAGEMENT,
  EXTERNAL_SERVICE_SETTINGS,
  SEARCH_CONSOLE_SETTING,
  MEDIA_SYNC_MANAGEMENT,
  API_MANAGEMENT,
  SYSTEM_SETTING_COOKIE_PROGRAM,
  SYSTEM_SETTING_BASIC,
  CAPI_SETTING,
  LINE_ADD_FRIEND_SETTINGS,
} from 'services/routes/constants';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';

import iconAdvertiseIssue from 'assets/images/icon-advertise-issue.svg';
import iconAdvertiseCost from 'assets/images/icon-advertise-cost.svg';

import * as permissions from 'domain/permissions/contractPermissions';

export const AD_GROUP1_MNGT = 'ad_group1_management';
export const AD_GROUP2_MNGT = 'ad_group2_management';
export const MEDIA_GROUP_MANAGEMENT = 'media_group_management';

export const LeftCard = {
  name: 'ad_management',
  key: 'topbar.page_setting.card.issue_ad',
  url: `${AD_MANAGEMENT}/ad`,
  type: INTERNAL,
  newTab: false,
  permissions: [permissions.SETTING_AD_AD_MANAGE_EDIT],
  img: iconAdvertiseIssue,
};

export const RightCard = {
  name: 'impression_ad_cost',
  key: 'topbar.page_setting.card.impression_ad_cost',
  url: 'cost-management/impression-cost',
  type: INTERNAL,
  newTab: false,
  permissions: [permissions.SETTING_COST_VIEW_AD_EDIT],
  img: iconAdvertiseCost,
};

const SettingConfig = {
  basic_settings: {
    name: 'basic_settings',
    key: 'topbar.page_setting.detailed_menu.basic_settings',
    children: [
      {
        name: 'system_management',
        key: 'topbar.page_setting.detailed_menu.system_management',
        url: SYSTEM_SETTING_BASIC,
        type: INTERNAL,
        newTab: true,
        permissions: [permissions.SETTING_SYSTEM_EDIT],
      },
      {
        name: 'user_management',
        key: 'topbar.page_setting.detailed_menu.user_management',
        url: USER_MANAGEMENT,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.SETTING_USER_EDIT],
      },
      {
        name: 'agency_management',
        key: 'topbar.page_setting.detailed_menu.agency_management',
        url: AGENCY_MANAGEMENT,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.SETTING_AGENCY_EDIT],
      },
      {
        name: 'api_management',
        key: 'topbar.page_setting.detailed_menu.api_management',
        url: API_MANAGEMENT,
        type: INTERNAL,
        newTab: false,
        contracts: [permissions.SETTING_EBIS_API_VIEW],
        permissions: [permissions.SETTING_EBIS_API_EDIT],
      },
    ],
  },
  tag_settings: {
    name: 'tag_setting',
    key: 'topbar.page_setting.detailed_menu.tag_settings',
    children: [
      {
        name: TAG_MANAGEMENT,
        key: 'topbar.page_setting.detailed_menu.tag_management',
        url: `${TAG_MANAGEMENT}/${TAG_MANAGEMENT_TAB.CV}`,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.SETTING_TAG_TAG_MANAGER_EDIT],
      },
      {
        name: MEASUREMENT_SITE_PAGE_MANAGEMENT,
        key: 'topbar.page_setting.detailed_menu.page_management',
        url: MEASUREMENT_SITE_PAGE_MANAGEMENT,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.SETTING_PAGE_MANAGEMENT_EDIT],
      },
      {
        name: LINE_ADD_FRIEND_SETTINGS,
        key: 'topbar.page_setting.detailed_menu.line_add_friend_settings',
        url: LINE_ADD_FRIEND_SETTINGS,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.LINE_SETTING_EDIT],
      },
    ],
  },
  ad_settings: {
    name: 'ad_settings',
    key: 'topbar.page_setting.detailed_menu.ad_settings',
    children: [
      {
        name: AD_MANAGEMENT,
        key: 'topbar.page_setting.detailed_menu.ad_management',
        url: `${AD_MANAGEMENT}/ad`,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.SETTING_AD_AD_MANAGE_EDIT],
      },
      {
        name: MEDIA_GROUP_MANAGEMENT,
        key: 'topbar.page_setting.detailed_menu.media_group_management',
        url: `${AD_MANAGEMENT}/media`,
        type: INTERNAL,
        newTab: false,
        permissions: [
          permissions.SETTING_AD_MEDIA_EDIT,
          permissions.SETTING_AD_AD_GROUP1_EDIT,
          permissions.SETTING_AD_AD_GROUP2_EDIT,
        ],
        urlByPermission: {
          // Small number have higher priority
          [permissions.SETTING_AD_MEDIA_EDIT]: {
            priority: 1,
            url: `${AD_MANAGEMENT}/media`,
          },
          [permissions.SETTING_AD_AD_GROUP1_EDIT]: {
            priority: 2,
            url: `${AD_MANAGEMENT}/group1`,
          },
          [permissions.SETTING_AD_AD_GROUP2_EDIT]: {
            priority: 3,
            url: `${AD_MANAGEMENT}/group2`,
          },
        },
      },
      {
        name: 'media_sync_management',
        key: 'topbar.page_setting.detailed_menu.media_sync_management',
        url: MEDIA_SYNC_MANAGEMENT,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.MEDIA_SYNC_MANAGEMENT_VIEW],
        icon: true,
      },
      {
        name: 'delivery_management',
        key: 'topbar.page_setting.detailed_menu.delivery_management',
        url: DELIVERY_MNGT_SITE,
        type: EXTERNAL,
        newTab: true,
        permissions: [
          permissions.SETTING_AD_DELIVERY_EDIT,
          permissions.SETTING_AD_MEASUREMENT_EDIT,
        ],
      },
    ],
  },
  cost_settings: {
    name: 'cost_settings',
    key: 'topbar.page_setting.detailed_menu.cost_settings',
    children: [
      {
        name: 'impression_ad_cost',
        key: 'topbar.page_setting.detailed_menu.impression_ad_cost',
        url: 'cost-management/impression-cost',
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.SETTING_COST_VIEW_AD_EDIT],
      },
    ],
  },
  external_link_settings: {
    name: 'external_link_settings',
    key: 'topbar.page_setting.detailed_menu.external_link_settings',
    children: [
      {
        name: 'search_console',
        key: 'topbar.page_setting.detailed_menu.search_console',
        url: SEARCH_CONSOLE_SETTING,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.SC_SETTING_EDIT],
        contracts: [permissions.SC_SETTING_VIEW],
      },
      {
        name: 'media_data_linkage_management',
        key: 'topbar.page_setting.detailed_menu.external_link_settings',
        url: EXTERNAL_SERVICE_SETTINGS,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.SETTING_EXTERNAL_LINKS_EDIT],
      },
    ],
  },
  capi_settings: {
    name: 'capi_settings',
    key: 'topbar.page_setting.detailed_menu.capi_setting',
    children: [
      {
        name: CAPI_SETTING,
        key: 'topbar.page_setting.detailed_menu.capi_setting',
        url: `${CAPI_SETTING}`,
        type: INTERNAL,
        newTab: false,
        permissions: [permissions.CAPI_SETTING_EDIT],
      },
    ],
    contracts: [permissions.CAPI_SETTING_VIEW],
  },
};

export const SettingSingleCapiContractConfig = {
  system_setting: {
    name: 'system_management',
    key: 'topbar.page_setting.detailed_menu.system_management',
    url: SYSTEM_SETTING_BASIC,
    type: INTERNAL,
    newTab: true,
    permissions: [permissions.SETTING_SYSTEM_EDIT],
  },
  user_management: {
    name: 'user_management',
    key: 'topbar.page_setting.detailed_menu.user_management',
    url: USER_MANAGEMENT,
    type: INTERNAL,
    newTab: false,
    permissions: [permissions.SETTING_USER_EDIT],
  },
  tag_management: {
    name: TAG_MANAGEMENT,
    key: 'topbar.page_setting.detailed_menu.tag_management',
    url: `${TAG_MANAGEMENT}/${TAG_MANAGEMENT_TAB.CV}`,
    type: INTERNAL,
    newTab: false,
    permissions: [permissions.SETTING_TAG_TAG_MANAGER_EDIT],
  },
  capi_settings: {
    name: CAPI_SETTING,
    key: 'topbar.page_setting.detailed_menu.capi_setting',
    url: `${CAPI_SETTING}`,
    type: INTERNAL,
    newTab: false,
    permissions: [permissions.CAPI_SETTING_EDIT],
  },
};

export default SettingConfig;
