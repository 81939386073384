import * as DisplayItems from './display-items';

const SettingDisplayItems = {
  groupList: {
    // [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.CATEGORY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AD_GROUP1]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AD_GROUP2]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AGENCY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.SYNC_CATEGORY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.MEDIA_SIDE_GROUP]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  // 媒体シンク軸優先時に非表示にする項目
  denyMediaSyncList: [
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
  ],
  // エビス軸優先時に非表示にする項目
  denyEbisList: [
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
  ],
  displayFreezeList: [
    // DisplayItems.CHANNEL,
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
    DisplayItems.AGENCY,
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
    DisplayItems.MEDIA_SIDE_AD_NAME,
  ],
  orderList: {
    [DisplayItems.CHANNEL]: 1,
    [DisplayItems.SYNC_CATEGORY]: 2,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]: 3,
    [DisplayItems.MEDIA_SIDE_GROUP]: 4,
    [DisplayItems.CATEGORY]: 5,
    [DisplayItems.AD_GROUP1]: 6,
    [DisplayItems.AD_GROUP2]: 7,
    [DisplayItems.AGENCY]: 8,
    [DisplayItems.IMPRESSION]: 9,
    [DisplayItems.CNT_ACCESS]: 10,
    [DisplayItems.CTR]: 11,
    [DisplayItems.CNT_SESSION]: 12,
    [DisplayItems.CNT_PV]: 13,
    [DisplayItems.PV_AVG]: 14,
    [DisplayItems.CNT_RETURN]: 15,
    [DisplayItems.PER_RETURN]: 16,
    [DisplayItems.TOTAL_STAY_TIME]: 17,
    [DisplayItems.TOTAL_STAY_TIME_AVG]: 18,
    [DisplayItems.TOTAL_LATENCY_TIME]: 19,
    [DisplayItems.LATENCY_TIME_AVG]: 20,
    [DisplayItems.CNT_CV_DETAIL]: 21,
    [DisplayItems.CVR_DETAIL]: 22,
    [DisplayItems.CNT_CV_TOTAL]: 25,
    [DisplayItems.CVR_TOTAL]: 26,
    [DisplayItems.CNT_INDIRECT2]: 27,
    [DisplayItems.CNT_INDIRECT3]: 28,
    [DisplayItems.CNT_INDIRECT4]: 29,
    [DisplayItems.CNT_INDIRECT5]: 30,
    [DisplayItems.CNT_INDIRECT_OTHER]: 31,
    [DisplayItems.INDIRECT_TOTAL]: 32,
    [DisplayItems.DIRECT_RATE]: 33,
    [DisplayItems.INDIRECT_RATE]: 35,
    [DisplayItems.CNT_FIRST]: 35,
    [DisplayItems.RCV]: 36,
    [DisplayItems.AMOUNT]: 37,
    [DisplayItems.RAMOUNT]: 38,
    [DisplayItems.COST]: 39,
    [DisplayItems.CPC]: 40,
    [DisplayItems.CPA]: 41,
    [DisplayItems.TCPA]: 42,
    [DisplayItems.ROAS]: 43,
    [DisplayItems.TROAS]: 44,
    [DisplayItems.CROSSDEVICE_DIFF_COMPARISON]: 45,
  },
  displayDefaultList: [
    DisplayItems.CATEGORY,
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.IMPRESSION,
    DisplayItems.CNT_ACCESS,
    DisplayItems.CTR,
    DisplayItems.CNT_CV_DETAIL,
    DisplayItems.CNT_CV_TOTAL,
    DisplayItems.CVR_DETAIL,
    DisplayItems.CVR_TOTAL,
    DisplayItems.CNT_INDIRECT2,
    DisplayItems.CNT_INDIRECT3,
    DisplayItems.CNT_INDIRECT4,
    DisplayItems.CNT_INDIRECT5,
    DisplayItems.CNT_INDIRECT_OTHER,
    DisplayItems.INDIRECT_TOTAL,
    // DisplayItems.DIRECT_RATE,
    // DisplayItems.INDIRECT_RATE,
    DisplayItems.CNT_FIRST,
    DisplayItems.RCV,
    DisplayItems.AMOUNT,
    DisplayItems.COST,
    DisplayItems.CPA,
    DisplayItems.TCPA,
    DisplayItems.ROAS,
  ],
  sortDefaultList: {
    [DisplayItems.CNT_CV_TOTAL]: DisplayItems.SORT_DIRECTION_DESC,
  },
  sortDisabledList: [
    DisplayItems.CNT_CV_DETAIL,
    DisplayItems.CVR_DETAIL,
  ],
};

const SettingAdDisplayItems = {
  ...SettingDisplayItems,
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    // DisplayItems.CATEGORY,
    // DisplayItems.IMPRESSION,
    // DisplayItems.CNT_ACCESS,
    // DisplayItems.CTR,
    // DisplayItems.CNT_CV_DETAIL,
    // DisplayItems.CNT_CV_TOTAL,
    // DisplayItems.CVR_DETAIL,
    // DisplayItems.CVR_TOTAL,
    // DisplayItems.CNT_INDIRECT2,
    // DisplayItems.CNT_INDIRECT3,
    // DisplayItems.CNT_INDIRECT4,
    // DisplayItems.CNT_INDIRECT5,
    // DisplayItems.CNT_INDIRECT_OTHER,
    // DisplayItems.INDIRECT_TOTAL,
    DisplayItems.DIRECT_RATE,
    DisplayItems.INDIRECT_RATE,
    // DisplayItems.CNT_FIRST,
    // DisplayItems.RCV,
    // DisplayItems.AMOUNT,
    // DisplayItems.COST,
    // DisplayItems.CPA,
    // DisplayItems.TCPA,
    // DisplayItems.ROAS,
  ],
};
const SettingAllDisplayItems = {
  ...SettingDisplayItems,
  groupList: {
    ...SettingDisplayItems.groupList,
    [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  displayFreezeList: [
    ...SettingDisplayItems.displayFreezeList,
    DisplayItems.CHANNEL,
  ],
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    DisplayItems.CHANNEL,
    // DisplayItems.CATEGORY,
    // DisplayItems.IMPRESSION,
    // DisplayItems.CNT_ACCESS,
    // DisplayItems.CTR,
    // DisplayItems.CNT_CV_DETAIL,
    // DisplayItems.CNT_CV_TOTAL,
    // DisplayItems.CVR_DETAIL,
    // DisplayItems.CVR_TOTAL,
    // DisplayItems.CNT_INDIRECT2,
    // DisplayItems.CNT_INDIRECT3,
    // DisplayItems.CNT_INDIRECT4,
    // DisplayItems.CNT_INDIRECT5,
    // DisplayItems.CNT_INDIRECT_OTHER,
    // DisplayItems.INDIRECT_TOTAL,
    // DisplayItems.DIRECT_RATE,
    // DisplayItems.INDIRECT_RATE,
    // DisplayItems.CNT_FIRST,
    // DisplayItems.RCV,
    // DisplayItems.AMOUNT,
    // DisplayItems.COST,
    // DisplayItems.CPA,
    // DisplayItems.TCPA,
    // DisplayItems.ROAS,
  ],
  requiredList: [DisplayItems.CHANNEL],
};

export const getAd = () => {
  const displayItemsBase = DisplayItems.getAd();
  return DisplayItems.getItems({
    ...SettingAdDisplayItems,
    displayItemsBase,
  });
};

export const getAdDefault = () => {
  const displayItemsBase = getAd();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAll = () => {
  const displayItemsBase = DisplayItems.getAll();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return DisplayItems.getDefault(displayItemsBase);
};
