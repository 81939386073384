import types from 'store/system-setting/types';
import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';

const changeTab = (tab) => helper.createAction(types.CHANGE_TAB, { tab });

const getBasicSetting = () => helper.createAction(types.GET_DATA_SETTING_BASIC);

const setDataBasicSetting = (data) =>
  helper.createAction(types.SET_DATA_SETTING_BASIC, { data });

const setStatusBasicSetting = (status) =>
  helper.createAction(types.SET_STATUS_SETTING_BASIC, { status });

const updateBasicSetting = (data, callback) =>
  helper.createAction(types.UPDATE_DATA_SETTING_BASIC, { data, callback });

const updateBasicSettingFailed = (
  errors,
  scope = loggerConstant.SCOPE_DONT_SHOW
) =>
  helper.createAction(
    types.UPDATE_DATA_SETTING_BASIC_FAILED,
    { errors },
    scope
  );

const updateCookieProgramSettingFailed = (
  errors,
  scope = loggerConstant.SCOPE_DONT_SHOW
) =>
  helper.createAction(
    types.UPDATE_DATA_SETTING_COOKIE_PROGRAM_FAILED,
    { errors },
    scope
  );

const getMeasurementSetting = () =>
  helper.createAction(types.GET_DATA_MEASUREMENT_SETTING);

const setDataMeasurementSetting = (data) =>
  helper.createAction(types.SET_DATA_MEASUREMENT_SETTING, { data });

const setStatusMeasurementSetting = (status) =>
  helper.createAction(types.SET_STATUS_MEASUREMENT_SETTING, { status });

const updateMeasurementSetting = (endpoint, data, callback) =>
  helper.createAction(types.UPDATE_MEASUREMENT_SETTING, {
    endpoint,
    data,
    callback,
  });

const updateMeasurementSettingFailed = (
  errors,
  scope = loggerConstant.SCOPE_DONT_SHOW
) =>
  helper.createAction(
    types.UPDATE_MEASUREMENT_SETTING_FAILED,
    { errors },
    scope
  );

const getCrossDomainList = (data = {}, callback = () => {}) =>
  helper.createAction(types.GET_CROSS_DOMAIN_LIST, { data, callback });

const setCrossDomainList = (data = {}) =>
  helper.createAction(types.SET_CROSS_DOMAIN_LIST, { data });

const cleanStates = (data) =>
  helper.createAction(types.CLEAN_STATES, { ...data });

const getCookieProgramSettings = () =>
  helper.createAction(types.GET_COOKIE_PROGRAM_SETTINGS);

const setCookieProgramSettings = (data) =>
  helper.createAction(types.SET_COOKIE_PROGRAM_SETTINGS, { data });

const updateCookieProgramSettings = (data) =>
  helper.createAction(types.UPDATE_COOKIE_PROGRAM_SETTINGS, { data });

const deleteCookieProgramSettings = (data) =>
  helper.createAction(types.DELETE_COOKIE_PROGRAM_SETTINGS, { data });

const checkCookieProgramSettings = (targets) =>
  helper.createAction(types.CHECK_COOKIE_PROGRAM_SETTINGS, { targets });

const setCookieProgramSettingsCheckStatus = (data) =>
  helper.createAction(types.SET_COOKIE_PROGRAM_SETTINGS_CHECK_STATUS, {
    data,
  });

const setCheckingCookieProgramUrl = (measurementFqdns) =>
  helper.createAction(types.SET_CHECKING_COOKIE_PROGRAM_SETTINGS, {
    measurementFqdns,
  });

const setStatusCookieProgramSettings = (status) =>
  helper.createAction(types.SET_STATUS_COOKIE_PROGRAM_SETTINGS, { status });
const setStatusCookieProgramChecking = (status) =>
  helper.createAction(types.SET_STATUS_COOKIE_PROGRAM_CHECKING, { status });
const getOtherSetting = () => helper.createAction(types.GET_DATA_OTHER_SETTING);

const setStatusOtherSetting = (status) =>
  helper.createAction(types.SET_STATUS_OTHER_SETTING, { status });

const setDataOtherSetting = (data) =>
  helper.createAction(types.SET_DATA_OTHER_SETTING, { data });

const updateOtherSetting = (data, callback) =>
  helper.createAction(types.UPDATE_DATA_OTHER_SETTING, { data, callback });

const updateOtherSettingFailed = (
  errors,
  scope = loggerConstant.SCOPE_DONT_SHOW
) =>
  helper.createAction(
    types.UPDATE_DATA_OTHER_SETTING_FAILED,
    { errors },
    scope
  );

const getDnsSetting = (tab) =>
  helper.createAction(types.GET_DATA_DNS_SETTING, { tab });

const setDataDnsSetting = (data) =>
  helper.createAction(types.SET_DATA_DNS_SETTING, { data });

const setDataDnsAddSetting = (dnsAdd) =>
  helper.createAction(types.SET_DATA_DNS_ADD_SETTING, { dnsAdd });

const setStatusDnsSetting = (status) =>
  helper.createAction(types.SET_STATUS_DNS_SETTING, { status });

const getDnsSettingFailed = (errors, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERROR_DNS_SETTING, { errors }, scope);

const addDnsSetting = (data, callback) =>
  helper.createAction(types.ADD_DATA_DNS_SETTING, { data, callback });

const addDnsSettingFailed = (errors, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.ADD_DATA_DNS_SETTING_FAILED, { errors }, scope);

const deleteDnsSetting = (data, callback) =>
  helper.createAction(types.DELETE_DATA_DNS_SETTING, { data, callback });

const updateDnsSetting = (data, callback) =>
  helper.createAction(types.UPDATE_DATA_DNS_EMAIL_SETTING, { data, callback });

const setStatusScreenComplete = (status) =>
  helper.createAction(types.SET_STATUS_SCREEN_COMPLETE, { status });

const getMasterData = () => helper.createAction(types.GET_MASTERDATA);
const setMasterData = (data) =>
  helper.createAction(types.SET_MASTERDATA, { data });

const setStatus = (status, key) =>
  helper.createAction(types.SET_STATUS, { status, key });

const systemSettingActions = {
  changeTab,
  cleanStates,
  getCookieProgramSettings,
  setCookieProgramSettings,
  updateCookieProgramSettings,
  deleteCookieProgramSettings,
  checkCookieProgramSettings,
  setCookieProgramSettingsCheckStatus,
  setCheckingCookieProgramUrl,
  setStatusCookieProgramSettings,
  setStatusCookieProgramChecking,
  getBasicSetting,
  setDataBasicSetting,
  setStatusBasicSetting,
  updateBasicSetting,
  getCrossDomainList,
  setCrossDomainList,
  updateBasicSettingFailed,
  updateCookieProgramSettingFailed,
  getMeasurementSetting,
  setDataMeasurementSetting,
  setStatusMeasurementSetting,
  updateMeasurementSetting,
  updateMeasurementSettingFailed,
  getOtherSetting,
  setStatusOtherSetting,
  setDataOtherSetting,
  updateOtherSetting,
  updateOtherSettingFailed,
  getDnsSetting,
  setDataDnsSetting,
  setStatusDnsSetting,
  setDataDnsAddSetting,
  addDnsSetting,
  addDnsSettingFailed,
  deleteDnsSetting,
  updateDnsSetting,
  setStatusScreenComplete,
  getDnsSettingFailed,
  getMasterData,
  setMasterData,
  setStatus,
};

export default systemSettingActions;
