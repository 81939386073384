// async actions
const REQUEST_START = 'ltv/settings/condition/REQUEST_START';
const REQUEST_FAILED = 'ltv/settings/condition/REQUEST_FAILED';
const FETCH = 'ltv/settings/condition/FETCH';
const FETCH_LIST = 'ltv/settings/condition/FETCH_LIST';
const CREATE = 'ltv/settings/condition/CREATE';
const UPDATE = 'ltv/settings/condition/UPDATE';
const DELETE = 'ltv/settings/condition/DELETE';
const TOGGLE_SELECT = 'ltv/settings/condition/TOGGLE_SELECT';
const UPLOAD = 'ltv/settings/condition/UPLOAD';

// sync actions
const FETCH_LIST_START = 'ltv/settings/condition/FETCH_LIST_START';
const FETCH_SUCCEEDED = 'ltv/settings/condition/FETCH_SUCCEEDED';
const FETCH_LIST_SUCCEEDED = 'ltv/settings/condition/FETCH_LIST_SUCCEEDED';
const CREATE_SUCCEEDED = 'ltv/settings/condition/CREATE_SUCCEEDED';
const UPDATE_SUCCEEDED = 'ltv/settings/condition/UPDATE_SUCCEEDED';
const DELETE_SUCCEEDED = 'ltv/settings/condition/DELETE_SUCCEEDED';
const CHANGE_SELECTED_ITEM = 'ltv/settings/condition/CHANGE_SELECTED_ITEM';
const CLEAR_API_RESULT = 'ltv/settings/condition/CLEAR_API_RESULT';
const UPDATE_CURRENT_ITEM_NUMBER =
  'ltv/settings/condition/UPDATE_CURRENT_ITEM_NUMBER';
const UPDATE_PAGE = 'ltv/settings/condition/UPDATE_PAGE';
const UPDATE_SORT = 'ltv/settings/condition/UPDATE_SORT';
const UPLOAD_SUCCEEDED = 'ltv/settings/condition/UPLOAD_SUCCEEDED';
const SUBMIT_FAILED = 'ltv/settings/condition/SUBMIT_FAILED';


export default {
  REQUEST_START,
  REQUEST_FAILED,
  FETCH,
  CREATE,
  UPDATE,
  DELETE,
  TOGGLE_SELECT,
  FETCH_LIST,
  FETCH_LIST_START,
  FETCH_LIST_SUCCEEDED,
  FETCH_SUCCEEDED,
  CREATE_SUCCEEDED,
  UPDATE_SUCCEEDED,
  DELETE_SUCCEEDED,
  CHANGE_SELECTED_ITEM,
  UPDATE_CURRENT_ITEM_NUMBER,
  UPDATE_PAGE,
  UPDATE_SORT,
  UPLOAD,
  UPLOAD_SUCCEEDED,
  SUBMIT_FAILED,
  CLEAR_API_RESULT,
};
