import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { shape, func, bool, string } from 'prop-types';
import { isEmpty } from 'lodash';
import {
  DOMAIN_NAME,
  REDIRECT_FLAG,
  EMAIL_NOTIFICATION,
  SEND_MAIL_USERS,
} from 'domain/fields';
import { TRUE_FLAG, FALSE_FLAG } from 'domain/consts';
import classNames from 'classnames';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import { NS_CNAME_REDIRECT_HELP_LINK } from 'services/routes/constants';
import SendMailUserSetting from './SendMailUserSetting';

function MediaInfoSetting(props) {
  const scrollbarWrapperRef = useRef(null);
  const {
    info,
    onChange,
    onSelectRow,
    hasUserEditPermission,
    listItemUpdate,
    userMasterEmailData,
    email,
    errorMessage,
  } = props;

  const { users } = userMasterEmailData;
  const handleSelectEmailNotification = (value) => {
    const emailNotificationFlag = value === TRUE_FLAG;
    onChange(EMAIL_NOTIFICATION, emailNotificationFlag);
    if (!emailNotificationFlag) {
      onChange(SEND_MAIL_USERS, []);
    }
  };
  const handleToggleRedirectFlag = () => {
    if (info[REDIRECT_FLAG].value) {
      onChange(REDIRECT_FLAG, false);
    } else {
      handleSelectEmailNotification(TRUE_FLAG);
      onChange(REDIRECT_FLAG, true);
    }
  };

  const classForm = classNames({
    'form-media-info-setting--default': true,
    'form-media-info-setting': hasUserEditPermission,
    'scrollbar-wrapper': true,
  });

  const domainNames = listItemUpdate.map((item) =>
    item.domain_name
  );

  return (
    <Form className={classForm} autoComplete="off">
      <ScrollbarWrapper
        ref={scrollbarWrapperRef}
        maxContent={530}
        alignScroll={-20}
      >
        {!isEmpty(errorMessage) && (
          <div className="modal-error-container">
            <p className="color-bad error-message">{errorMessage}</p>
          </div>
        )}
        <FormGroup
          variant={DOMAIN_NAME}
          label={info[DOMAIN_NAME].label}
        >
          {domainNames &&
            Object.keys(domainNames).map(key => (
              <div>{domainNames[key]}</div>
            ))
          }
        </FormGroup>
        <FormGroup
          variant={REDIRECT_FLAG}
          label={info[REDIRECT_FLAG].label}
        >
          <EbisCheckbox
            name={REDIRECT_FLAG}
            text="有効にする"
            checked={info[REDIRECT_FLAG].value}
            changeSelectBoxValue={handleToggleRedirectFlag}
          />
          <div className="form-group__description">
            NS/CNAMEレコードを利用したリダイレクト計測につきましては、ViewThru計測、通常のリダイレクト計測、一部のユーザーID取得タグ（一部の外部連携での利用を含む）/ユーザーID上書きタグとの併用は非推奨です。
            詳しくは
            <a
              href={NS_CNAME_REDIRECT_HELP_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
          </div>
        </FormGroup>
        <FormGroup
          variant={EMAIL_NOTIFICATION}
          label={info[EMAIL_NOTIFICATION].label}
        >
          <EbisRadio
            name={EMAIL_NOTIFICATION}
            label="する"
            value={TRUE_FLAG}
            checked={info[EMAIL_NOTIFICATION].value}
            onChange={handleSelectEmailNotification}
          />
          <EbisRadio
            name={EMAIL_NOTIFICATION}
            label="しない"
            value={FALSE_FLAG}
            checked={!info[EMAIL_NOTIFICATION].value}
            disabled={info[REDIRECT_FLAG].value}
            onChange={handleSelectEmailNotification}
          />
        </FormGroup>
        {hasUserEditPermission && info[EMAIL_NOTIFICATION].value && (
          <SendMailUserSetting
            info={info}
            users={users}
            selected={info[SEND_MAIL_USERS].value}
            error={info[SEND_MAIL_USERS].error}
            onChange={onChange}
            onSelectRow={onSelectRow}
          />
        )}
        {!hasUserEditPermission && (
          <div className="form-confirm-setting__notice-description">
            {email ? (
              <div>
                {' '}
                ※ログイン中のユーザーに設定されているメールアドレス「{email}
                」に通知されます。
                <br />
                ※メールアドレスを変更したい場合、アカウント管理者へ「設定/管理
                {'>'}
                基本設定{'>'}
                ユーザー管理」画面でのメールアドレスの設定をご相談ください。
              </div>
            ) : (
              <div>
                ※ログイン中のユーザーはメールアドレスが登録されていません。
                <br />
                ※アカウント管理者へ「設定/管理{'>'}基本設定{'>'}
                ユーザー管理」画面でのメールアドレスの設定をご相談ください。メールアドレスが設定されていない場合、メール通知を「する」に選択されていても通知されません。
              </div>
            )}
          </div>
        )}
      </ScrollbarWrapper>
    </Form>
  );
}

MediaInfoSetting.propTypes = {
  info: shape({}).isRequired,
  onChange: func.isRequired,
  onSelectRow: func.isRequired,
  hasUserEditPermission: bool,
  listItemUpdate: shape({}).isRequired,
  userMasterEmailData: shape({}).isRequired, // original var name is "masterData"
  email: string.isRequired,
  errorMessage: string,
};

MediaInfoSetting.defaultProps = {
  hasUserEditPermission: true,
  errorMessage: '',
};

export default MediaInfoSetting;
