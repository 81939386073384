import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import dashboardActions from 'store/dashboard/actions';
import dashboardSelectors from 'store/dashboard/selectors';
import commonSelectors from 'store/common/selectors';
import settingsSelectors from 'store/settings/selectors';
import DashboardBar from 'views/molecules/commonbar/components/DashboardBar';
import FormatData from 'domain/dashboard/FormatData';
import {
  COMPARE_MODE_TARGET_VALUE,
  COMPARE_MODE_LAST_MONTH_VALUE,
  COMPARE_MODE_SAME_MONTH_LAST_YEAR_VALUE,
} from 'store/dashboard/constants';
import useCheckPermissionAgency from 'services/custom-hooks/useCheckPermissionAgency';
import { PICKUP_IDS } from 'domain/setting-mail/constants';

const DashboardBarContainer = () => {
  const dispatch = useDispatch();
  const showKpiStatusModal = useCallback(
    (isShow) => dispatch(dashboardActions.showKpiStatusModal(isShow)),
    [dispatch]
  );
  const showSettingMailModal = useCallback(
    (isShow) => dispatch(dashboardActions.showSettingMailModal(isShow)),
    [dispatch]
  );

  const [
    period,
    periodList,
    targetPeriod,
    comparePeriod,
    isShowKpiStatusModal,
    isOpenTransitionModal,
    isLoadingKpiStatus,
    currentKpiPeriod,
  ] = [
    useSelector(dashboardSelectors.getPeriodSelected, shallowEqual),
    useSelector(dashboardSelectors.getPeriodList, shallowEqual),
    useSelector(dashboardSelectors.getPeriodId, shallowEqual),
    useSelector(dashboardSelectors.getTargetCompare, shallowEqual),
    useSelector(dashboardSelectors.getShowKpiBorder, shallowEqual),
    useSelector(commonSelectors.getStatusTransitionModal, shallowEqual),
    useSelector(
      settingsSelectors.getLoadingByStatus([
        dashboardSelectors.getStatusBorder,
      ]),
      shallowEqual
    ),
    useSelector(dashboardSelectors.getCurrentKpiPeriod, shallowEqual),
  ];

  const { isAgentUser } = useCheckPermissionAgency();
  const [disabledSelectCompare, setDisabledSelectCompare] = useState(false);
  const [disabledIconEmail, setDisabledIconMail] = useState(true);

  const lastMonth = useMemo(
    () => moment(period.start_date).subtract(1, 'months'),
    [period.start_date]
  );

  const lastYear = useMemo(
    () => moment(period.start_date).subtract(1, 'years', [period.start_date]),
    [period.start_date]
  );

  const disabledCompareLastMonth = useMemo(() => {
    return !periodList.find((periodItem) =>
      lastMonth.isSame(periodItem.start_date)
    );
  }, [lastMonth, periodList]);

  const disabledComapreLastYear = useMemo(() => {
    return !periodList.find((periodItem) =>
      lastYear.isSame(periodItem.start_date)
    );
  }, [lastYear, periodList]);

  const compareList = useMemo(
    () => [
      {
        key: COMPARE_MODE_TARGET_VALUE,
        value: '目標と比較する',
        disable: isAgentUser,
      },
      {
        key: COMPARE_MODE_LAST_MONTH_VALUE,
        value: '前月と比較する',
        disable: disabledCompareLastMonth,
      },
      {
        key: COMPARE_MODE_SAME_MONTH_LAST_YEAR_VALUE,
        value: '前年同月と比較する',
        disable: disabledComapreLastYear,
      },
    ],
    [disabledComapreLastYear, disabledCompareLastMonth, isAgentUser]
  );

  useEffect(() => {
    const { [PICKUP_IDS]: pickupIds = [] } = currentKpiPeriod;
    if (pickupIds.length !== 0) {
      setDisabledIconMail(false);
    }
  }, [currentKpiPeriod]);

  useEffect(() => {
    const isDisabledSelectCompare =
      isAgentUser && disabledCompareLastMonth && disabledComapreLastYear;
    setDisabledSelectCompare(isDisabledSelectCompare);

    const isDisabledCompare = compareList.find(
      (item) => item.key === comparePeriod && item.disable === true
    );
    if (isDisabledCompare) {
      const compareModeDefault = isAgentUser
        ? COMPARE_MODE_LAST_MONTH_VALUE
        : COMPARE_MODE_TARGET_VALUE;
      dispatch(dashboardActions.changeComparePeriod(compareModeDefault));
    }
  }, [
    compareList,
    comparePeriod,
    disabledComapreLastYear,
    disabledCompareLastMonth,
    dispatch,
    isAgentUser,
  ]);

  useEffect(() => {
    if (isAgentUser && periodList.length > 1) {
      dispatch(
        dashboardActions.changeComparePeriod(COMPARE_MODE_LAST_MONTH_VALUE)
      );
    }
  }, [dispatch, isAgentUser, periodList.length]);

  useEffect(() => {
    if (comparePeriod !== COMPARE_MODE_TARGET_VALUE) {
      const { startDate, endDate } = FormatData.getCompareDateByMode(
        targetPeriod,
        periodList,
        comparePeriod
      );

      // check period exists
      const findIndex = periodList.findIndex(
        (item) => item.start_date === startDate
      );

      if (findIndex > -1) {
        dispatch(dashboardActions.fetchDataCompare(startDate, endDate));
      } else {
        dispatch(dashboardActions.fetchDataCompareSuccess([]));
      }
    }
  }, [dispatch, comparePeriod, targetPeriod, periodList]);

  const handleSelectCompare = useCallback(
    (value) => {
      const compareSelected = parseInt(value, 10);
      dispatch(dashboardActions.changeComparePeriod(compareSelected));
    },
    [dispatch]
  );

  const handleSelectPeriod = useCallback(
    (value) => {
      const periodId = value ? parseInt(value, 10) : 0;
      dispatch(dashboardActions.changeTargetPeriod(periodId));
    },
    [dispatch]
  );

  return (
    <DashboardBar
      period={period}
      periodList={periodList}
      targetPeriod={
        !targetPeriod && periodList.length > 0
          ? periodList[1].kpi_period_id
          : targetPeriod
      }
      comparePeriod={comparePeriod}
      compareList={compareList}
      isLoadingKpiStatus={isLoadingKpiStatus}
      isShowKpiStatusModal={isShowKpiStatusModal}
      isOpenTransitionModal={isOpenTransitionModal}
      showKpiStatusModal={showKpiStatusModal}
      handleSelectCompare={handleSelectCompare}
      handleSelectPeriod={handleSelectPeriod}
      disabledSelectCompare={disabledSelectCompare}
      isAgentUser={isAgentUser}
      showSettingMailModal={showSettingMailModal}
      disabledIconEmail={disabledIconEmail}
    />
  );
};

export default DashboardBarContainer;
