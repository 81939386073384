const GET_DATA = 'cost-allocation/GET_DATA';
const GET_SUCCESS = 'cost-allocation/GET_SUCCESS';
const GET_ERROR = 'cost-allocation/GET_ERROR';
const GET_FULL_DATA = 'cost-allocation/GET_FULL_DATA';
const GET_FULL_DATA_SUCCESS = 'cost-allocation/GET_FULL_DATA_SUCCESS';
const GET_FULL_DATA_ERROR = 'cost-allocation/GET_FULL_DATA_ERROR';
const CHANGE_PAGE = 'cost-allocation/CHANGE_PAGE';
const SELECT_ROW = 'cost-allocation/SELECT_ROW';
const DESELECT_ROW = 'cost-allocation/DESELECT_ROW';
const SAVE_Y_YAXIS = 'cost-allocation/SAVE_Y_YAXIS';
const CHANGE_SELECTED_YAXISLINE = 'cost-allocation/CHANGE_SELECTED_YAXISLINE';
const SAVE_Y_YAXIS_SUCCESS = 'cost-allocation/SAVE_Y_YAXIS_SUCCESS';
const SAVE_Y_YAXIS_ERROR = 'cost-allocation/SAVE_Y_YAXIS_ERROR';
const GET_BOOKMARK = 'cost-allocation/GET_BOOKMARK';
const GET_BOOKMARK_SUCCESS = 'cost-allocation/GET_BOOKMARK_SUCCESS';
const GET_BOOKMARK_ERROR = 'cost-allocation/GET_BOOKMARK_ERROR';

const UPDATE_BOOKMARK = 'cost-allocation/UPDATE_BOOKMARK';
const UPDATE_BOOKMARK_SUCCESS = 'cost-allocation/UPDATE_BOOKMARK_SUCCESS';
const UPDATE_BOOKMARK_ERROR = 'cost-allocation/UPDATE_BOOKMARK_ERROR';
const SAVE_SIMULATION_DATA = 'cost-allocation/SAVE_SIMULATION_DATA';
const CLEAR_SIMULATION_DATA = 'cost-allocation/CLEAR_SIMULATION_DATA';
const SAVE_RESULT_SIMULATION_DATA =
  'cost-allocation/SAVE_RESULT_SIMULATION_DATA';

const SET_TABLE_COLUMN_SORT = 'cost-allocation/SET_TABLE_COLUMN_SORT';
const SET_TABLE_CURRENT_PAGE = 'cost-allocation/SET_TABLE_CURRENT_PAGE';

export default {
  GET_DATA,
  GET_SUCCESS,
  GET_ERROR,
  GET_FULL_DATA,
  GET_FULL_DATA_SUCCESS,
  GET_FULL_DATA_ERROR,
  CHANGE_PAGE,
  SELECT_ROW,
  DESELECT_ROW,
  SAVE_Y_YAXIS,
  CHANGE_SELECTED_YAXISLINE,
  SAVE_Y_YAXIS_SUCCESS,
  SAVE_Y_YAXIS_ERROR,
  UPDATE_BOOKMARK,
  UPDATE_BOOKMARK_SUCCESS,
  UPDATE_BOOKMARK_ERROR,
  GET_BOOKMARK,
  GET_BOOKMARK_SUCCESS,
  GET_BOOKMARK_ERROR,
  CLEAR_SIMULATION_DATA,
  SAVE_SIMULATION_DATA,
  SAVE_RESULT_SIMULATION_DATA,
  SET_TABLE_COLUMN_SORT,
  SET_TABLE_CURRENT_PAGE,
};
