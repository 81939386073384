import React, { useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { func, bool, object, oneOfType } from 'prop-types';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import AdManagementDetailView from 'views/pages/ad-management/components/AdManagementDetailView';

const MAX_CONTENT = 532;
function AdDetailViewModal(props) {
  const { data, onHide, isLoading, masterData } = props;
  const { display } = masterData;
  const scrollbarWrapperRef = useRef(null);

  return (
    <Modal show centered size="w800" dialogClassName="modal-view-detail">
      <Modal.Header>
        <Modal.Title>広告詳細</Modal.Title>
      </Modal.Header>
      <Modal.Body
        ref={scrollbarWrapperRef}
        className={isLoading ? 'd-flex align-items-center' : ''}
        style={{
          overflow: 'hidden',
          marginRight: -30,
          paddingRight: 30,
          minHeight: isLoading ? MAX_CONTENT : null,
        }}
      >
        <DataSyncLoader isLoading={isLoading}>
          <ScrollbarWrapper
            ref={scrollbarWrapperRef}
            maxContent={MAX_CONTENT}
            alignScroll={10}
          >
            <AdManagementDetailView data={data} configDisplay={display} />
          </ScrollbarWrapper>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer className="mt-20">
        <Button onClick={onHide} size="sm" variant="link">
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AdDetailViewModal.propTypes = {
  onHide: func,
  data: oneOfType([object]),
  masterData: oneOfType([object]),
  isLoading: bool,
};

AdDetailViewModal.defaultProps = {
  onHide: () => {},
  data: {},
  masterData: {},
  isLoading: false,
};

export default AdDetailViewModal;
