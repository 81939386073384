/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import NewsModal from 'views/molecules/modal/NewsModal';
import { connect } from 'react-redux';
import { notificationsSelectors } from 'store/notifications';
import { commonSelectors } from 'store/common';
import actions from 'store/notifications/actions';

const NewsModalContainer = (props) => {
  const {
    newsData: { display_type, id },
    notReadIndex,
  } = props;
  /**
   * Show checkbox only display_type is 1 and not read
   */
  return (
    <NewsModal
      {...props}
      showCheckbox={display_type === 1 && notReadIndex.includes(id)}
      notRead={notReadIndex.includes(id)}
    />
  );
};

NewsModalContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newsData: PropTypes.object.isRequired,
  notReadIndex: PropTypes.arrayOf(PropTypes.number).isRequired,
  id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const isShow = notificationsSelectors.getNewsModalState(state);
  const tutorialModalStatus = commonSelectors.getTutorialModalStatus(state);
  return {
    notReadIndex: notificationsSelectors.getUnreadNotificationsList(state),
    newsData: notificationsSelectors.getNewsModalData(state),
    show: isShow && !tutorialModalStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleConfirm: (id) => dispatch(actions.updateNotificationRead(id)),
  onHide: () => dispatch(actions.toggleNewsModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(NewsModalContainer));
