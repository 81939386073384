import React from 'react';
import { useDispatch } from 'react-redux';
import { commonActions } from 'store/common';
import downloadTypeConfigs from 'services/common/downloadTypeConfigs';

import constants from 'store/common/constant';

const configsBuilder = (optionsKey = [], isEnable) => {
  const obj = {};

  const len = optionsKey.length;

  for (let i = 0; i < len; i += 1) {
    const item = optionsKey[i];
    obj[item] = {
      status: true,
      enabled: isEnable,
    };
  }

  return obj;
};

let currentScreen;

const useToggleDownload = (screenId) => {
  const dispatch = useDispatch();

  const toggleDownload = React.useCallback(
    (data) => {
      dispatch(commonActions.toggleDownload(data));
    },
    [dispatch]
  );

  const toggleCSV = React.useCallback(
    (isEnable) => {
      toggleDownload(configsBuilder([constants.CSV], isEnable));
    },
    [toggleDownload]
  );

  const toggleAllCSV = React.useCallback(
    (isEnable) => {
      toggleDownload(configsBuilder([constants.ALLCSV], isEnable));
    },
    [toggleDownload]
  );

  const toggleImage = React.useCallback(
    (isEnable) => {
      toggleDownload(configsBuilder([constants.PNG, constants.JPEG], isEnable));
    },
    [toggleDownload]
  );

  React.useEffect(() => {
    if (currentScreen !== screenId) {
      toggleDownload(downloadTypeConfigs(screenId));
      currentScreen = screenId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [toggleCSV, toggleImage, toggleAllCSV];
};

export default useToggleDownload;
