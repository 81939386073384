import React from 'react';
import { oneOfType, bool, element, node, string } from 'prop-types';
import { Form, Badge } from 'react-bootstrap';
import classNames from 'classnames';
import DragonBall from 'views/atoms/dragonball/DragonBall';

import './form-group.scss';

const FormGroup = React.forwardRef((props, ref) => {
  const {
    required,
    label,
    tooltip,
    note,
    children,
    variant,
    isError,
    disabled,
    styleError,
    tooltipStayOpen,
    supplementComment,
  } = props;

  const formGroupClass = classNames({
    'ebis-form-group': true,
    'ebis-form-group--error': isError,
    [`ebis-form-group--error-${styleError}`]: styleError,
    'ebis-form-group--disabled': disabled,
    [`ebis-form-group--${variant}`]: !!variant,
  });

  return (
    <Form.Group className={formGroupClass} ref={ref}>
      <Form.Label className={{'mb-0': supplementComment}}>
        <span className="label">{label}</span>
        {tooltip && (
          <DragonBall ref={ref} variant="form-label" stayOpen={tooltipStayOpen}>
            {tooltip}
          </DragonBall>
        )}
        {required && (
          <Badge pill variant="required">
            必須
          </Badge>
        )}
      </Form.Label>
      {supplementComment && (
        <div className="w-100 txt-note color-gray-dark mb-1"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: supplementComment }}
        />
      )}
      {children}
      {note && <Form.Text>{note}</Form.Text>}
    </Form.Group>
  );
});

FormGroup.propTypes = {
  required: bool,
  isError: bool,
  disabled: bool,
  label: string,
  variant: string,
  styleError: string,
  tooltip: oneOfType([string, node]),
  note: oneOfType([string, node]),
  children: element,
  tooltipStayOpen: bool,
  supplementComment: string,
};

FormGroup.defaultProps = {
  required: false,
  isError: false,
  disabled: false,
  label: '',
  tooltip: '',
  note: '',
  children: '',
  variant: '',
  styleError: '',
  tooltipStayOpen: false,
  supplementComment: '',
};

export default FormGroup;
