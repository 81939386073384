import {
  CONTENT_CATEGORY_NAME,
  OWNED_MEDIA_FLAG,
  PAGE_ID,
  PAGE_NAME,
  PAGE_URL,
} from 'domain/fields';
import * as labels from 'domain/labels';
import {
  PRODUCT_NAME,
  PRODUCT_ID,
  OFFER_NAME,
  OFFER_ID,
  MARGIN_F1,
  MARGIN_F2,
  MARGIN_F3,
  PAY_BACK,
  EFFECTIVE_MONTH,
  ORDER_ID,
  ORDERED_TIME,
  MEMBER_ID,
  REVENUE,
  DELETE_FLAG,
  AD_ID,
  TYPE_CONDITION,
} from 'domain/ltv/settings/constants';

export const productError = {
  VALUE_IS_EMPTY: '商品を入力してください。',
  VALUE_IS_EXISTS: '利用されている商品名です。別の商品名を入力してください。',
  OVER_MAX_ROW:
    '商品の登録上限数（20,000件）を超えてしまうため、登録することができません。不要な商品を削除してください。',
};
export const offerError = {
  VALUE_IS_EMPTY: 'オファーを入力してください。',
  VALUE_IS_EXISTS:
    '利用されているオファー名です。別のオファー名を入力してください。',
  OVER_MAX_ROW:
    'オファーの登録上限数（20,000件）を超えてしまうため、登録することができません。不要なオファーを削除してください。',
};

export const conditionError = {
  EMPTY_PRODUCT_ID: '商品を入力してください。',
  EMPTY_OFFER_ID: 'オファーを入力してください。',
  VALUE_IS_EXISTS:
    '利用されている商品・オファーの組み合わせです。別の組み合わせを入力してください。',
  PAY_BACK: '目標投資回収期間は1から24までの半角数字で入力してください。',
  MARGIN_F1: 'F1限界利益率は-999から100までの整数で入力してください。',
  MARGIN_F2: 'F2限界利益率は-999から100までの整数で入力してください。',
  MARGIN_F3: 'F3限界利益率は-999から100までの整数で入力してください。',
  EFFECTIVE_MONTH: 'すでに同じ適用開始年月の集計条件が登録されています。',
  NOT_EXISTS_DB: '未登録または削除済みの集計条件が入力されています。',
  NOT_EXISTS_DB_PRODUCT: '未登録または削除済みの商品名が入力されています。',
  NOT_EXISTS_DB_OFFER: '未登録または削除済みのオファー名が入力されています。',
  OVER_MAX_ROW:
    '集計条件の登録上限数（50,000件）を超えてしまうため、登録することができません。不要なオファーを削除してください。',
};

export const linkAdError = {
  NOT_EXISTS_DB: '未登録または削除済みの集計条件が入力されています。',
  VALUE_IS_EXISTS:
    '広告ID[%ad_id%]はすでに他の商品・オファーに紐づけられています。設定を削除するか、ほかの広告IDを入力してください。',
};
export const FILE_IMPORTING = 'FILE_IMPORTING';
export const FILE_IMPORTING_MESSAGE =
  'CSV一括登録処理中のため、登録できません。しばらく待ってからもう一度登録してください。';
export const msgValidateFileCsvError = {
  INVALID_HEADER:
    'CSVフォーマットの形式が異なります。<br/><a href="{link}">こちら</a>より最新のフォーマットをダウンロードし、再度アップロードしてください。',
  OVER_MAX_LINE: '※CSVの登録上限は、{length} 行までです。',
  OVER_MAX_SIZE: '1ファイルあたり{size}MB以下',
  OVER_MAX_ERROR:
    '{label}エラーが{length}件以上あります。<a href="{link}" target="_blank" rel="noopener noreferrer">こちら</a>を参考に、修正したCSVをアップロードしてください。',
  FILE_NOT_FOUND: 'CSVファイルをアップロードしてください。',
  FILE_IMPORTING:
    'CSV一括登録処理中のため、登録できません。CSV一括登録が完了してから登録してください。',
  OVER_MAX_ROW:
    '{label}の登録上限数（{length}件）を超えてしまうため、登録することができません。不要な<br/>{label}を削除してください。',
  FILE_ENCODING: 'CSVファイルの文字コードはSJIS形式にしてください。',
  INVALID_FILE: '一度にアップロード可能な文字数の上限は、 1,024文字までです。',
};

export const msgValidateContentCsvError = {
  VALUE_IS_EMPTY: '{label}は必須入力項目です。{label}を入力してください。',
  MUST_IS_NUMERIC: '{label}は半角整数の数字で入力してください。',
  NOT_EXISTS_DB:
    '未登録または削除済みの{label}が入力されています。<br />登録済みの{label}を指定するか、権限のあるユーザーにて[{label}]タブで{label}を登録してください。',
  NOT_EXISTS_DB_AD:
    '未登録または削除済みの紐づけ広告IDが入力されています。<br />[広告設定>広告管理]で登録済みの広告IDを指定するか、広告IDを登録してください。',
  VALUE_IS_EXISTS: '利用されている{label}です。別の{label}を入力してください。',
  MUST_IN_RANGE:
    '{label}は{index.0}から{index.1}までの整数で入力してください。',
  OVER_MAX_LENGTH:
    '{label}の文字数を{index.0}字以下になるよう修正してください。',
  ASSOCIATE_ID:
    '集計条件で使用されている商品は削除できません。<br/>削除する場合は、削除したい商品の集計条件を削除してから行ってください。',
  INVALID_FORMAT:
    '[削除]には0,1以外は入力できません。<br/>削除したい場合は1を入力、登録・更新の場合は0を入力するか未入力にしてください。',
  ROW_NOT_EMPTY:
    '{label}に改行を含めて登録することはできません。改行を削除してください。',
  MUST_IS_INTEGER: '{label}は整数で入力してください。',
  INVALID_ID_FORMAT:
    '存在しない{label}が含まれています。入力内容をご確認ください。',
  ROW_INVALID_FORMAT:
    '値が入力されていません。<br/>行を削除するか、各項目に値を入力してください。',
  MUST_BE_TRIMMED:
    'データに「スペース、タブ、改行」が入力されています。<br/>入力欄内の「スペース、タブ、改行」を削除してください。',
  NOT_AD_ID_IN_CHARGE:
    '閲覧・編集権限がない広告の広告IDが指定されています。権限のある広告を指定するか、通常ユーザーにて広告に担当代理店を設定してください。',
};

export const fields = {
  items: { label: '商品' }, // product
  offers: { label: 'オファー' }, // offer
  item_offers: { label: '集計条件' }, // condition
  item_offer_ads: { label: '紐づけ広告' }, // ad
  orders: { label: '注文' }, // order
  [PRODUCT_ID]: {
    label: '商品ID',
    message: {
      ASSOCIATE_ID:
        '集計条件で使用されている商品は削除できません。<br/>削除する場合は、削除したい商品の集計条件を削除してから行ってください。',
    },
  },
  [PRODUCT_NAME]: {
    label: '商品名',
    message: {
      NOT_EXISTS_DB: '存在しない{label}が入力されています。',
    },
  },
  [DELETE_FLAG]: {
    label: '削除',
    message: {
      ASSOCIATE_ID:
        '広告が紐づいている集計条件は削除できません。<br/>削除する場合は、削除したい集計条件の紐づけ広告をクリアしてから行ってください。',
    },
  },
  [OFFER_ID]: {
    label: 'オファーID',
    message: {
      ASSOCIATE_ID:
        '集計条件で使用されているオファーは削除できません。<br/>削除する場合は、削除したいオファーの集計条件を削除してから行ってください。',
    },
  },
  [OFFER_NAME]: { label: 'オファー名' },
  [PAY_BACK]: { label: '目標投資回収期間' },
  [MARGIN_F1]: { label: 'F1限界利益率' },
  [MARGIN_F2]: { label: 'F2限界利益率' },
  [MARGIN_F3]: { label: 'F3以降限界利益率' },
  [EFFECTIVE_MONTH]: {
    label: '適用開始年月',
    message: {
      INVALID_FORMAT:
        '{label}の入力形式は下記となるよう修正してください。<br/>YYYY/MM',
    },
  },
  [ORDER_ID]: { label: '注文ID' },
  [ORDERED_TIME]: {
    label: '注文日時',
    message: {
      INVALID_FORMAT:
        '注文日時の入力形式は下記いずれかとなるよう修正してください。<br/>YYYY/MM/DD<br/>YYYY/MM/DD HH:MM:SS<br/>YYYY-MM-DD<br/>YYYY-MM-DD HH:MM:SS<br/>',
      VALUE_IS_MIN_NUMBER:
        '{index.0}より前の受注データのアップロードはできません。',
    },
  },
  [MEMBER_ID]: { label: '会員ID' },
  [REVENUE]: {
    label: '売上',
    message: {
      INVALID_FORMAT: '{label}は半角整数で入力してください。',
      VALUE_IS_MIN_NUMBER:
        '{label}は0から{index.0}までの整数で入力してください。',
      VALUE_IS_MAX_NUMBER:
        '{label}は0から{index.0}までの整数で入力してください。',
    },
  },
  [AD_ID]: {
    label: '紐づけ広告ID',
    message: {
      ASSOCIATE_ID:
        '他の商品・オファーに紐づけられている広告です。<br/>紐づけを削除してから登録してください。',
    },
  },
  [TYPE_CONDITION]: { label: '集計条件' },
  [PAGE_ID]: { label: labels.PAGE_ID },
  [PAGE_NAME]: { label: labels.PAGE_NAME },
  [PAGE_URL]: { label: labels.PAGE_URL },
  [OWNED_MEDIA_FLAG]: { label: labels.OWNED_MEDIA },
  [CONTENT_CATEGORY_NAME]: { label: labels.CONTENT_CATEGORY },
};

export default {
  FILE_IMPORTING_MESSAGE,
};
