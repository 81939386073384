import Api, { SettingsApi } from 'services/api/Api';
import pages from 'services/routes/pages';
import { LINE_ADD_FRIEND_SETTINGS } from 'services/routes/constants';
import apiUtils from 'services/apiUtils';

const LineAddFriendApiFactory = () => {
  const endpoint = `${pages[LINE_ADD_FRIEND_SETTINGS].endpoint}`;

  return {
    fetchData: async (sort) => SettingsApi.get(`${endpoint}?sort=${sort}`),
    getDetail: async (id) => SettingsApi.get(`${endpoint}/${id}`),
    create: async (data) => SettingsApi.post(endpoint, data),
    update: async (id, data) => SettingsApi.put(`${endpoint}/${id}`, data),
    delete: async (ids) =>
      SettingsApi.delete(apiUtils.buildUrlWithQueryStrings(endpoint, { ids })),
  };
};

const LineAddFriendApi = LineAddFriendApiFactory();
export default LineAddFriendApi;
