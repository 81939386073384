import helper from 'store/helper';
import { communicationStatus } from 'services/utils';

const { IDLE } = communicationStatus;

const initialState = {
  data: {
    contact: {},
  },
  status: {
    contact: IDLE,
  },
};

const ContactManagementReducer = helper.createReducer(initialState, {});

export default ContactManagementReducer;
