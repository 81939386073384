// async actions
const REQUEST_START = 'ltv/settings/csv-orders/REQUEST_START';
const REQUEST_FAILED = 'ltv/settings/csv-orders/REQUEST_FAILED';

const DELETE_ALL_DATA = 'ltv/settings/csv-orders/DELETE_ALL_DATA';

// sync actions
const DELETE_SUCCEEDED = 'ltv/settings/csv-orders/DELETE_SUCCEEDED';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  DELETE_ALL_DATA,
  DELETE_SUCCEEDED,
};
