import { useCallback, useState } from 'react';
import _ from 'lodash';

/**
 * Control a group of modals. Only one modal can be shown at any time
 * @param {string[]} ids
 * @return {{openModal: openModal, closeModals: closeModals, isOpen: isOpen}}
 */
const useModalController = ({ ids }) => {
  const [statuses, setStatuses] = useState(
    _.chain(ids)
      .keyBy()
      .mapValues(() => false)
      .value()
  );

  const openModal = useCallback(
    (id) => {
      // open modal `id`
      setStatuses(
        _.chain(statuses)
          .mapValues((value, key) => {
            return key === id;
          })
          .value()
      );
    },
    [statuses]
  );

  const closeModals = useCallback(() => {
    setStatuses(
      _.chain(statuses)
        .mapValues(() => false)
        .value()
    );
  }, [statuses]);

  const isOpen = useCallback((id) => statuses[id] === true, [statuses]);

  return {
    openModal,
    closeModals,
    isOpen,
  };
};

export default useModalController;
