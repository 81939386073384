import helper from 'store/helper';
import {
  FUNC_CODE_INFLOW_ANALYSIS,
  FUNC_CODE_DETAIL_ANALYSIS,
  FUNC_CODE_CV_ATTR_ANALYSIS,
  FUNC_CODE_CV_FLOW_ANALYSIS,
  FUNC_CODE_LTV_ANALYSIS,
  FUNC_CODE_LPO_LINK,
  FUNC_CODE_LPO_PERIOD,
} from 'domain/settings/display-items';
import { PRIORITY_AXIS_EBIS } from 'domain/consts';
import types from './types';

/**
 * INFO: if change this initial state structure
 * check if conflict with state in file LocalStorageService.js
 * in saveState -> stateStorage -> settings -> view (line 68 -> 74)
 */
export const initialState = {
  byIds: {
    /* 1: {
      id: 1,
      name: 'View name',
      status: true,
    }, */
  },
  edit: {},
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  statusForView: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  isLoadedViewTab: false,
  error: null, // string | null,
  priorityAxis: PRIORITY_AXIS_EBIS,
  applied: {
    current: null, // int | null, view ID
    changed: false, // display items or filters changed: false -> true
    // Apply display setting by func ID
    // Filters shared all screen
    byFunc: {
      // Share display setting between category/period/cost allocation analysis
      [FUNC_CODE_INFLOW_ANALYSIS]: false,
      [FUNC_CODE_DETAIL_ANALYSIS]: false,
      [FUNC_CODE_CV_ATTR_ANALYSIS]: false,
      [FUNC_CODE_CV_FLOW_ANALYSIS]: false,
      [FUNC_CODE_LTV_ANALYSIS]: false,
      [FUNC_CODE_LPO_LINK]: false,
      [FUNC_CODE_LPO_PERIOD]: false,
    },
  },
};

const requestStart = (state) => {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: 'failed',
    error,
  };
};

// for CustomViewModal
const requestStartForView = (state) => {
  return {
    ...state,
    statusForView: 'loading',
    isLoadedViewTab: false,
    error: null,
  };
};

// for CustomViewModal
const requestFailedForView = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    statusForView: 'failed',
    error,
  };
};

// for CustomViewModal
const setLoadedViewTab = (state) => {
  return {
    ...state,
    isLoadedViewTab: true,
  };
};

const fetchListSucceeded = (state, action) => {
  const { byIds } = action.payload;
  // TODO: move logic to UiService
  const data = {};
  byIds.forEach((view) => {
    const { id } = view;
    data[id] = view;
  });
  return {
    ...state,
    byIds: data,
    status: 'succeeded',
  };
};

const fetchSucceeded = (state, action) => {
  const { selected } = action.payload;
  return {
    ...state,
    selected,
    status: 'succeeded',
  };
};

// for CustomViewModal
const fetchEditSucceeded = (state, action) => {
  const { edit } = action.payload;
  return {
    ...state,
    edit,
    statusForView: 'succeeded',
  };
};

// for CustomViewModal
const fetchSwitchSucceeded = (state) => {
  return {
    ...state,
    statusForView: 'succeeded',
  };
};

const removeSucceeded = (state, action) => {
  const { id } = action.payload;
  const { [id]: removed, ...remain } = state.byIds;
  return {
    ...state,
    byIds: remain,
    status: 'succeeded',
  };
};

// for CustomViewModal
const createSucceeded = (state, action) => {
  const { data } = action.payload;
  const { id } = data;
  return {
    ...state,
    byIds: {
      ...state.byIds,
      [id]: data,
    },
    statusForView: 'succeeded',
  };
};

// for CustomViewModal
const updateSucceeded = (state, action) => {
  const { id, view_name: viewName } = action.payload;
  // view item does not exist
  if (!id) {
    return state;
  }
  return {
    ...state,
    byIds: {
      ...state.byIds,
      [id]: {
        ...state.byIds[id],
        id,
        view_name: viewName,
      },
    },
    applied: {
      ...state.applied,
      current: id === state.applied.current ? null : state.applied.current,
    },
    statusForView: 'succeeded',
  };
};

const applyIsChanged = (state, action) => {
  const { current, changed: viewChanged } = state.applied;
  if (current && viewChanged === false) {
    const { changed } = action.payload;
    return {
      ...state,
      applied: {
        ...state.applied,
        changed,
      },
    };
  }
  return state;
};
const applySucceeded = (state, action) => {
  const { id, funcId } = action.payload;

  // Reset state when change apply view id
  let { byFunc: funcApplied, changed } = state.applied;
  if (state.applied.current !== id || state.applied.changed) {
    funcApplied = {
      [FUNC_CODE_INFLOW_ANALYSIS]: false,
      [FUNC_CODE_DETAIL_ANALYSIS]: false,
      [FUNC_CODE_CV_ATTR_ANALYSIS]: false,
      [FUNC_CODE_CV_FLOW_ANALYSIS]: false,
      [FUNC_CODE_LTV_ANALYSIS]: false,
      [FUNC_CODE_LPO_LINK]: false,
      [FUNC_CODE_LPO_PERIOD]: false,
    };
    changed = false;
  }
  return {
    ...state,
    applied: {
      current: id,
      changed,
      byFunc: {
        ...funcApplied,
        [funcId]: true,
      },
    },
    status: 'succeeded',
  };
};

const toggleFavoriteSucceeded = (state, action) => {
  const { id } = action.payload;
  // view item does not exist
  if (!Object.prototype.hasOwnProperty.call(state.byIds, id)) {
    return state;
  }
  const { status } = state.byIds[id];
  const prevFavorite = {};
  Object.keys(state.byIds).map((key) => {
    if (state.byIds[key].status) {
      prevFavorite[key] = {
        ...state.byIds[key],
        status: false,
      };
    }
    return key;
  });
  return {
    ...state,
    byIds: {
      ...state.byIds,
      ...prevFavorite,
      [id]: {
        ...state.byIds[id],
        status: !status,
      },
    },
    status: 'succeeded',
  };
};

const CustomViewReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.REQUEST_START_FOR_VIEW]: requestStartForView,
  [types.REQUEST_FAILED_FOR_VIEW]: requestFailedForView,
  [types.SET_LOADED_VIEW_TAB]: setLoadedViewTab,
  [types.FETCH_EDIT_SUCCEEDED]: fetchEditSucceeded,
  [types.FETCH_SWITCH_SUCCEEDED]: fetchSwitchSucceeded,
  [types.FETCH_SUCCEEDED]: fetchSucceeded,
  [types.FETCH_LIST_SUCCEEDED]: fetchListSucceeded,
  [types.CREATE_SUCCEEDED]: createSucceeded,
  [types.UPDATE_SUCCEEDED]: updateSucceeded,
  [types.DELETE_SUCCEEDED]: removeSucceeded,
  [types.APPLY_SUCCEEDED]: applySucceeded,
  [types.APPLY_IS_CHANGED]: applyIsChanged,
  [types.TOGGLE_FAVORITE_SUCCEEDED]: toggleFavoriteSucceeded,
});

export default CustomViewReducer;
