import { SettingsApi as Api } from 'services/api/Api';
import pages from 'services/routes/pages';
import { SYSTEM_SETTING } from 'services/routes/constants';
import apiUtils from 'services/apiUtils';

const CookieProgramApiFactory = () => {
  const url = `${pages[SYSTEM_SETTING].endpoint}/setcookie`;

  return {
    fetchAll: async () => {
      return Api.get(url);
      // return {
      //   data: {
      //     data: {
      //       setcookie_settings: [
      //         {
      //           measurement_fqdn: 'example.co.jp',
      //           program_url: 'https://sc.example.co.jp/setcookie.php',
      //           update_date: '2022-12-01T13:37:42.123456',
      //         },
      //       ],
      //       measurement_fqdn: [
      //         'my-domain.co.jp',
      //         'my-other-domain.com',
      //         'whatever.org',
      //         'example.co.jp',
      //       ],
      //       ns_cname_domains: ['my-domain.co.jp', 'my-other-domain.com'],
      //     },
      //   },
      //   status: 200,
      //   statusText: 'OK',
      //   headers: {},
      //   config: {},
      //   request: {},
      // };
    },
    update: async (records) => {
      return Api.put(url, records);
    },
    remove: async (measurementFqdn) => {
      return Api.delete(
        apiUtils.buildUrlWithQueryStrings(url, measurementFqdn)
      );
    },
    wait: async (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  };
};

const CookieProgramApi = CookieProgramApiFactory();
export default CookieProgramApi;
