/* eslint-disable no-console */
const TimeoutNotifyFactory = () => {
  let items = [];
  return {
    push: (error) => items.push(error),
    getAll: () => items,
    count: () => items.length,
    reset: () => {
      items = [];
    },
  };
};

const timeoutNotify = TimeoutNotifyFactory();
export default timeoutNotify;
