import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LPO_PERIOD, USER_ENDPOINT } from 'services/routes/constants';
import { BOOKMARK_FUNC_NAME } from 'domain/consts';
import apiUtils from 'services/apiUtils';

const ENDPOINT = pages[LPO_PERIOD].endpoint;
const MEMO_ENDPOINT = `${ENDPOINT}/memo`;

const PeriodAnalysisApiFactory = () => {
  const BOOKMARK_URL = `${USER_ENDPOINT}/display-settings/bookmark`;
  const REPORT_URL = ENDPOINT;

  return {
    url: REPORT_URL,
    bookmarkUrl: BOOKMARK_URL,
    getDataChart: async (request) => {
      return Api.postRequest(REPORT_URL, request);
    },
    getDataTable: async (request) => {
      return Api.postRequest(REPORT_URL, request);
    },
    getBookmark: async () => Api.get(BOOKMARK_URL),
    createBookmark: async ({ x, y }) => {
      const bookmark = {
        metric: y,
        compare_metric: x,
        func_id: BOOKMARK_FUNC_NAME.LPO_PERIOD,
      };
      return Api.postRequest(BOOKMARK_URL, bookmark);
    },
    deleteBookmark: async () => Api.delete(BOOKMARK_URL),
    createMemo: async (request) => Api.postRequest(`${MEMO_ENDPOINT}`, request),
    updateMemo: async (id, request) => {
      const url = `${MEMO_ENDPOINT}/${id}`;
      return Api.putRequest(url, request);
    },
    loadMemo: async (request) => {
      const url = apiUtils.buildUrlWithQueryStrings(
        `${MEMO_ENDPOINT}`,
        request
      );
      return Api.getRequest(url);
    },
    deleteMemo: async (id) => Api.deleteRequest(`${MEMO_ENDPOINT}/${id}`),
  };
};

const periodAnalysisApi = PeriodAnalysisApiFactory();

export default periodAnalysisApi;
