import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  INFO_FORM_OTHER_SETTING as FORM,
  ALERT_MAIL,
  LOGIN_IP,
  ALERT_AUTH_IDS,
} from 'domain/system-setting/consts';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';
import systemSettingActions from 'store/system-setting/actions';
import systemSettingSelectors from 'store/system-setting/selectors';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import OtherSettingModal from './OtherSettingModal';

function OtherSettingContainer() {
  const dispatch = useDispatch();
  const { isLoading, data } = useSelector(
    systemSettingSelectors.getStatesOtherSetting
  );
  const {
    isCapiSingleContract,
    isCapiEditPermission,
  } = useCheckPermissionCapi();

  const [isShowSettingModal, setShowSettingModal] = useState(false);
  const onOpenSettingModal = () => setShowSettingModal(true);
  const onHideSettingModal = () => setShowSettingModal(false);

  useEffect(() => {
    dispatch(systemSettingActions.getOtherSetting());
  }, [dispatch]);

  return (
    <DataSyncLoader isLoading={isLoading}>
      <div className="other-setting">
        {isCapiEditPermission && (
          <FormGroup
            label={FORM[ALERT_AUTH_IDS].label}
            tooltip={FORM[ALERT_AUTH_IDS].tooltip}
          >
            <div>
              {data?.userIds ? (
                data.userIds.map((user) => <div>{user}</div>)
              ) : (
                <></>
              )}
            </div>
          </FormGroup>
        )}
        <FormGroup
          label={FORM[ALERT_MAIL].label}
          tooltip={FORM[ALERT_MAIL].tooltip}
        >
          <div className="system-setting__pre-wrap">
            {data?.[ALERT_MAIL] ? data[ALERT_MAIL].replaceAll(',', '\r\n') : ''}
          </div>
        </FormGroup>
        <FormGroup label={FORM[LOGIN_IP].label}>
          <div className="system-setting__pre-wrap">
            {data?.[LOGIN_IP] ? data[LOGIN_IP].replaceAll(',', '\r\n') : ''}
          </div>
        </FormGroup>
        <Button
          size="sm"
          variant="secondary"
          disabled={isLoading}
          onClick={onOpenSettingModal}
        >
          編集
        </Button>
        {isShowSettingModal && (
          <OtherSettingModal
            isShow={isShowSettingModal}
            isCapiSingleContract={isCapiSingleContract}
            isCapiEditPermission={isCapiEditPermission}
            onHide={onHideSettingModal}
            data={data}
          />
        )}
      </div>
    </DataSyncLoader>
  );
}

export default OtherSettingContainer;
