/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import jaLocale from 'moment/locale/ja';
import {
  Button,
  Dropdown,
  Col,
  Row,
  FormCheck,
  Tooltip,
} from 'react-bootstrap';
import { DayPickerRangeController } from 'react-dates/esm';
import { START_DATE } from 'react-dates/constants';
import {
  CALENDAR_DAY_FORMAT,
  CALENDAR_INPUT_ALLOWED_FORMAT,
  DATE_RANGE_TYPE,
} from 'domain/consts';
import {
  dateRangeOf,
  getComparedPeriodDates,
  getCurrentPeriodDates,
  getToday,
} from 'services/utils';
import PropTypes, {
  bool,
  func,
  number,
  object,
  objectOf,
  any,
  shape,
  string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import display from 'services/display';
import TooltipWrapper from 'views/molecules/conditional-tooltip/TooltipWrapper';
import Switch from 'views/atoms/switch/Switch';
import classNames from 'classnames';
import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import OutsideClickHandler from 'react-outside-click-handler';

import './dateRangePicker.scss';
import DisabledTooltipWrapper from 'views/atoms/tooltip/DisabledTooltipWrapper';

function CalendarMonth(props) {
  const { month } = props;
  return (
    <div
      style={{
        justifyContent: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'black',
        marginBottom: '5px',
        lineHeight: '24px',
      }}
    >
      <span>
        {month.format('YYYY')}/{month.format('MM')}
      </span>
    </div>
  );
}

CalendarMonth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  month: object.isRequired,
};

function EbisDateRangePicker(props) {
  moment.locale('ja', jaLocale);
  const {
    period,
    comparedPeriod,
    comparedPeriodDisabled,
    comparedPeriodSupported,
    onActive,
    active = false,
    drop,
    isShowCompare,
    maxDate,
    minDate,
    maxDuration,
    disabled,
    disabledMessage,
  } = props;
  const [focus, setFocus] = useState(START_DATE);
  const [showModal, setShowModal] = useState(false);

  const [renderKey, setRenderKey] = useState(new Date().getTime());

  const initialPeriod = getCurrentPeriodDates(period);
  const initialComparedPeriod = getComparedPeriodDates(
    comparedPeriod,
    initialPeriod
  );

  const [currentMonth, setCurrentMonth] = useState(moment(initialPeriod));

  const [initialMonth, setInitialMonth] = useState(moment(initialPeriod));

  const [appliedState, setAppliedState] = useState({
    current: {
      startDate: initialPeriod.start
        ? initialPeriod.start.clone().startOf('day')
        : null,
      endDate: initialPeriod.end
        ? initialPeriod.end.clone().startOf('day')
        : null,
      preset: initialPeriod.preset,
    },
    comparedPeriod: {
      enabled: initialComparedPeriod.enabled,
      startDate: initialComparedPeriod.start,
      endDate: initialComparedPeriod.end,
      preset: initialComparedPeriod.preset,
    },
  });

  const [tempState, setTempState] = useState(appliedState);

  const [customInput, setCustomInput] = useState({
    current: {
      startDate: tempState.current.startDate?.format(CALENDAR_DAY_FORMAT),
      endDate: tempState.current.endDate?.format(CALENDAR_DAY_FORMAT),
    },
    comparedPeriod: {
      startDate: tempState.comparedPeriod.startDate?.format(
        CALENDAR_DAY_FORMAT
      ),
      endDate: tempState.comparedPeriod.endDate?.format(CALENDAR_DAY_FORMAT),
    },
  });

  const [firstToggle, setFirstToggle] = useState(true);

  // const currentPreset = tempState.current.preset;
  const setCurrentPreset = (preset) => {
    if (tempState.current.preset === preset) return;
    setTempState((prev) => ({
      ...prev,
      current: {
        ...prev.current,
        preset,
      },
    }));
  };

  const comparedPreset = tempState.comparedPeriod.preset;
  const setComparedPreset = (preset) => {
    if (tempState.comparedPeriod.preset === preset) return;
    setTempState((prev) => ({
      ...prev,
      comparedPeriod: {
        ...prev.comparedPeriod,
        preset,
      },
    }));
  };

  const formatInputValue = ({ startDate, endDate }) => {
    const start = startDate ? startDate.format(CALENDAR_DAY_FORMAT) : '';
    const end = endDate ? endDate.format(CALENDAR_DAY_FORMAT) : '';
    return `${start} ～ ${end}`;
  };

  const inputValue =
    appliedState.current.startDate && appliedState.current.endDate
      ? formatInputValue({
          startDate: appliedState.current.startDate,
          endDate: appliedState.current.endDate,
        })
      : '指定なし';

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const datepickerRef = useRef(null);

  const updateInitialMonth = (date) => {
    if (
      currentMonth.year() !== date.year() ||
      (currentMonth.month() !== date.month() &&
        currentMonth.month() + 1 !== date.month())
    ) {
      setInitialMonth(date.clone());
      setCurrentMonth(date.clone());
      setRenderKey(new Date().getTime());
    } else if (
      initialMonth.year() !== date.year() ||
      (initialMonth.month() !== date.month() &&
        initialMonth.month() + 1 !== date.month())
    ) {
      setInitialMonth(currentMonth.clone());
    }
  };

  const resetStates = () => {
    setTempState({
      comparedPeriod: appliedState.comparedPeriod,
      current: appliedState.current,
    });
    setCustomInput({
      current: {
        endDate: appliedState.current.endDate?.format(CALENDAR_DAY_FORMAT),
        startDate: appliedState.current.startDate?.format(CALENDAR_DAY_FORMAT),
      },
      comparedPeriod: {
        endDate: appliedState.comparedPeriod.endDate?.format(
          CALENDAR_DAY_FORMAT
        ),
        startDate: appliedState.comparedPeriod.startDate?.format(
          CALENDAR_DAY_FORMAT
        ),
      },
    });
    setComparedPreset(appliedState.comparedPeriod.preset);
    updateInitialMonth(appliedState.current.startDate.clone());
  };

  const convertToValidatedDate = (startDate, endDate) => {
    let start = startDate;
    let end = endDate;
    if (start.isBefore(minDate)) {
      start = minDate.clone();
    }
    if (start.isAfter(maxDate)) {
      start = maxDate.clone();
    }
    if (end.isAfter(maxDate)) {
      end = maxDate.clone();
    }
    if (end.isBefore(start)) {
      end = start.clone();
    }

    const {
      timeUnit: maxDurationTimeUnit,
      value: maxDurationValue,
    } = maxDuration;
    if (
      end
        .clone()
        .subtract(maxDurationValue, maxDurationTimeUnit)
        .isSameOrAfter(start, 'day')
    ) {
      end = start
        .clone()
        .add(maxDurationValue, maxDurationTimeUnit)
        .subtract(1, 'day');
    }

    return {
      start,
      end,
    };
  };

  const changeDate = ({ startDate, endDate }, isCurrent = true) => {
    const { start, end } = convertToValidatedDate(startDate, endDate);
    if (isCurrent) {
      setTempState((prev) => ({
        ...prev,
        current: {
          ...prev.current,
          startDate: start,
          endDate: end,
        },
      }));
      setCustomInput({
        ...customInput,
        current: {
          startDate: start?.format(CALENDAR_DAY_FORMAT),
          endDate: end?.format(CALENDAR_DAY_FORMAT),
        },
      });
      updateInitialMonth(start);
    } else {
      setTempState((prev) => ({
        ...prev,
        comparedPeriod: {
          ...prev.comparedPeriod,
          startDate: start,
          endDate: end,
          enabled: true,
        },
      }));
      setCustomInput({
        ...customInput,
        comparedPeriod: {
          startDate: start?.format(CALENDAR_DAY_FORMAT),
          endDate: end?.format(CALENDAR_DAY_FORMAT),
        },
      });
    }
  };

  const changeComparedEnabled = (enabled) => {
    if (enabled) {
      if (!comparedPreset) {
        setComparedPreset(DATE_RANGE_TYPE.LAST_PERIOD);
      }
      const comparedPeriodPresetType =
        comparedPreset || DATE_RANGE_TYPE.LAST_PERIOD;
      const { startDate, endDate } = dateRangeOf(comparedPeriodPresetType, {
        startDate: tempState.current.startDate,
        endDate: tempState.current.endDate,
      });
      changeDate(
        {
          startDate,
          endDate,
        },
        false
      );
    } else {
      setTempState((prev) => ({
        ...prev,
        comparedPeriod: {
          ...prev.comparedPeriod,
          enabled: false,
        },
      }));
    }
  };

  const onDatesChange = ({ startDate, endDate }) => {
    let newStartDate = startDate;
    let newEndDate = endDate;
    if (!endDate) {
      newEndDate = startDate;
    } else if (!startDate) {
      newStartDate = endDate;
    } else if (
      startDate.isSame(tempState.current.startDate, 'day') &&
      endDate.isSame(tempState.current.endDate, 'day') &&
      !startDate.isSame(endDate, 'day')
    ) {
      newEndDate = startDate;
    }
    changeDate({ startDate: newStartDate, endDate: newEndDate }, true);
    setCurrentPreset(DATE_RANGE_TYPE.CUSTOM);
  };

  const onInputDateChange = (value, isStart, isCompared) => {
    const date = moment(value, CALENDAR_DAY_FORMAT, true);
    if (date.isValid()) {
      if (!isCompared) {
        if (isStart) {
          changeDate(
            { startDate: date, endDate: tempState.current.endDate },
            true
          );
        } else {
          changeDate(
            { startDate: tempState.current.startDate, endDate: date },
            true
          );
        }
        setCurrentPreset(DATE_RANGE_TYPE.CUSTOM);
      } else {
        setComparedPreset(DATE_RANGE_TYPE.CUSTOM);
        if (isStart) {
          changeDate(
            { startDate: date, endDate: tempState.comparedPeriod.endDate },
            false
          );
        } else {
          changeDate(
            { startDate: tempState.comparedPeriod.startDate, endDate: date },
            false
          );
        }
      }
    } else {
      // Change input
      const newCustomInput = {};
      if (!isCompared) {
        if (isStart) {
          newCustomInput.current = { startDate: value };
        } else {
          newCustomInput.current = { endDate: value };
        }
      } else if (isStart) {
        newCustomInput.comparedPeriod = { startDate: value };
      } else {
        newCustomInput.comparedPeriod = { endDate: value };
      }
      setCustomInput({
        // Since merge return the same object like Object.assign, must destructure it to trigger render
        ...merge(customInput, newCustomInput),
      });
      // Disable ok button
    }
  };

  const tryConvertInputDate = (value, isStart, isCompared) => {
    if (moment(value, CALENDAR_DAY_FORMAT, true).isValid()) return;

    let newValue = value;
    const fallbackDate = moment(value, CALENDAR_INPUT_ALLOWED_FORMAT, true);
    if (fallbackDate.isValid()) {
      // set value as min date
      newValue = fallbackDate.format(CALENDAR_DAY_FORMAT);
    } else if (isStart) {
      // set value as previous value
      newValue = isCompared
        ? tempState.comparedPeriod.startDate
        : tempState.current.startDate;
    } else {
      // set value as previous value
      newValue = isCompared
        ? tempState.comparedPeriod.endDate
        : tempState.current.endDate;
    }

    // save date valid
    onInputDateChange(newValue, isStart, isCompared);
  };

  const focusChange = (focusedInput) => {
    setFocus(!focusedInput ? START_DATE : focusedInput);
  };

  const setDateRangeForType = (type) => {
    const { startDate, endDate } = dateRangeOf(type);

    changeDate({ startDate, endDate }, true);

    // setUseManualPreset(true);
    setCurrentPreset(type);
  };

  /**
   *
   * @param {number} type
   * @param {{startDate, endDate}} customValue
   */
  const setComparePeriodDateRangeForType = (type) => {
    let comparePeriod;
    if (isNil(type) || type === DATE_RANGE_TYPE.CUSTOM) {
      comparePeriod = dateRangeOf(DATE_RANGE_TYPE.LAST_PERIOD, {
        startDate: tempState.current.startDate,
        endDate: tempState.current.endDate,
      });
    } else {
      comparePeriod = dateRangeOf(type, {
        startDate: tempState.current.startDate,
        endDate: tempState.current.endDate,
      });
    }

    setComparedPreset(type);
    changeDate(
      { startDate: comparePeriod.startDate, endDate: comparePeriod.endDate },
      false
    );
  };

  const isOutOfRange = ({ month, year }, { startDate, endDate }) =>
    year < startDate.year() ||
    year > endDate.year() ||
    (year === startDate.year() && month < startDate.month() + 1) ||
    (year === endDate.year() && month > endDate.month() + 1);

  /**
   *
   * @param {HTMLDivElement} elem
   */
  const highlightDayIfSelected = (
    elem,
    { year, month },
    { startDate, endDate }
  ) => {
    const day = elem.innerHTML;
    const dateInChecking = moment([year, month - 1, day]);
    if (!tempState.comparedPeriod.enabled || comparedPeriodDisabled) {
      elem.classList.remove('compared__selected', 'compared__selected_span');
      return;
    }

    if (dateInChecking.isBetween(startDate, endDate, 'day', '[]')) {
      if (
        isOutOfRange(
          {
            month,
            year,
          },
          {
            startDate,
            endDate,
          }
        )
      ) {
        elem.classList.remove('compared__selected', 'compared__selected_span');
        return;
      }
      elem.classList.add('compared__selected_span');
      elem.classList.remove('compared__selected');
    } else if (
      endDate.isBefore(startDate) &&
      dateInChecking.format(CALENDAR_DAY_FORMAT) ===
        startDate.format(CALENDAR_DAY_FORMAT)
    ) {
      elem.classList.add('compared__selected_span');
    } else {
      elem.classList.remove('compared__selected', 'compared__selected_span');
    }
  };

  const highlightSelectedComparePeriodDaysInWeek = (
    elem,
    { year, month },
    { startDate, endDate }
  ) => {
    elem.childNodes.forEach((node) =>
      highlightDayIfSelected(
        node,
        {
          year,
          month,
        },
        {
          startDate,
          endDate,
        }
      )
    );
  };

  /**
   *
   * @param {HTMLDivElement} child
   * @param {moment.Moment} startDate
   * @param {moment.Moment} endDate
   */
  const highlightComparePeriodForCalendarElem = (
    child,
    { startDate, endDate }
  ) => {
    const [
      year,
      month,
    ] = child.firstChild.firstChild.firstChild.firstChild.innerHTML
      .split('/')
      .map((val) => parseInt(val, 10));
    child.firstChild.childNodes[1].firstChild.childNodes.forEach((elem) =>
      highlightSelectedComparePeriodDaysInWeek(
        elem,
        {
          year,
          month,
        },
        {
          startDate,
          endDate,
        }
      )
    );
  };

  const highlightComparePeriodForCalendar = ({ startDate, endDate }) => {
    Array.from(
      datepickerRef.current.getElementsByClassName('CalendarMonthGrid')[0]
        .children
    ).forEach((child) =>
      highlightComparePeriodForCalendarElem(child, {
        startDate,
        endDate,
      })
    );
  };

  const onPrevMonthClick = (date) => {
    // const { startDate, endDate } = tempState.comparedPeriod;
    // highlightComparePeriodForCalendar({ startDate, endDate });
    setCurrentMonth(moment([date.year(), date.month()]));
  };

  const onNextMonthClick = (date) => {
    // const { startDate, endDate } = tempState.comparedPeriod;
    // highlightComparePeriodForCalendar({ startDate, endDate });
    setCurrentMonth(moment([date.year(), date.month()]));
  };

  const cloneIfNE = (current, existing) => {
    if (!current || !existing) return current;
    if (
      current &&
      existing &&
      current.format(CALENDAR_DAY_FORMAT) !==
        existing.format(CALENDAR_DAY_FORMAT)
    ) {
      return current.clone();
    }
    return current;
  };

  const onOkClick = () => {
    if (
      tempState.current.endDate === null ||
      tempState.current.endDate.isBefore(tempState.current.startDate)
    ) {
      tempState.current.endDate = tempState.current.startDate;
    }

    if (
      tempState.comparedPeriod.endDate === null ||
      tempState.comparedPeriod.endDate.isBefore(
        tempState.comparedPeriod.startDate
      )
    ) {
      tempState.comparedPeriod.endDate = tempState.comparedPeriod.startDate;
    }

    setAppliedState({
      ...tempState,
    });

    setShowModal(false);
    const { onChange } = props;

    const [currentStart, currentEnd, comparedStart, comparedEnd] = [
      cloneIfNE(tempState.current.startDate, appliedState.current.startDate),
      cloneIfNE(tempState.current.endDate, appliedState.current.endDate),
      tempState.comparedPeriod.enabled
        ? cloneIfNE(
            tempState.comparedPeriod.startDate,
            appliedState.comparedPeriod.startDate
          )
        : null,
      tempState.comparedPeriod.enabled
        ? cloneIfNE(
            tempState.comparedPeriod.endDate,
            appliedState.current.endDate
          )
        : null,
    ];

    onChange({
      period: {
        start: currentStart,
        end: currentEnd,
        preset: tempState.current.preset,
      },
      comparedPeriod: {
        start: comparedStart,
        end: comparedEnd,
        enabled: tempState.comparedPeriod.enabled,
        preset: tempState.comparedPeriod.preset,
      },
    });

    if (
      currentMonth.year() !== tempState.current.startDate.year() ||
      currentMonth.month() !== tempState.current.startDate.month()
    ) {
      setRenderKey(new Date().getTime());
    }
  };

  const onCancelClick = () => {
    resetStates();
    setShowModal(false);
  };

  const PrevIcon = () => (
    <span className="icon-hover">
      <i className="fas fa-chevron-left" aria-hidden="true" />
    </span>
  );

  const NextIcon = () => (
    <span className="icon-hover">
      <i className="fas fa-chevron-right" aria-hidden="true" />
    </span>
  );

  const getComparePeriodTextDisplay = () => {
    const { comparedPeriod: comparePeriod } = appliedState;
    return comparePeriod.enabled
      ? `比較：${comparePeriod.startDate.format(
          CALENDAR_DAY_FORMAT
        )} ～ ${comparePeriod.endDate.format(CALENDAR_DAY_FORMAT)}`
      : '';
  };

  useEffect(() => {
    highlightComparePeriodForCalendar(tempState.comparedPeriod);
  });

  useEffect(() => {
    if (
      tempState.comparedPeriod.enabled &&
      comparedPreset !== DATE_RANGE_TYPE.CUSTOM
    ) {
      // Update compared period date
      setComparePeriodDateRangeForType(comparedPreset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempState.current.startDate, tempState.current.endDate]);

  const toggleCalendar = (e) => {
    setShowModal(!showModal);
    if (!showModal) {
      onActive({ target: 'EbisDateRangePicker' });
    }

    // Since offsetHeight / offsetWidth of datepicker was calculated during Suspense, thus it has no offsetHeight/offsetWidth
    // Force underlying date picker component re-calculate offsetHeight / offsetWidth
    //  of captionRef element on first time it is clicked
    if (firstToggle) {
      setFirstToggle(false);
      // Trigger a new render
      setRenderKey(new Date().getTime());
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if (!active) {
      setShowModal(false);
    }
  }, [active]);

  const menuEl = useRef(null);

  useEffect(() => {
    setAppliedState((prev) => ({
      ...prev,
      current: {
        startDate: initialPeriod.start ? initialPeriod.start.clone() : null,
        endDate: initialPeriod.end ? initialPeriod.end.clone() : null,
        preset: initialPeriod.preset,
      },
    }));
    // setUseManualPreset(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    setAppliedState((prev) => {
      // Find out the matching compared period preset in case it is null

      return {
        ...prev,
        comparedPeriod: {
          enabled: !!comparedPeriod.start,
          startDate: initialComparedPeriod.start,
          endDate: initialComparedPeriod.end,
          preset: initialComparedPeriod.preset,
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedPeriod]);

  // Whenever appliedState changes, should update tempState
  useEffect(() => {
    resetStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedState]);

  const modelClass = classNames({
    'date-range-picker__modal': true,
    'date-range-picker--drop-right': drop === 'right',
  });

  const wrapperClass = classNames({
    'date-range-picker': true,
    'align-self-center': true,
    disabled,
  });

  return (
    <div className={wrapperClass}>
      <OutsideClickHandler
        onOutsideClick={() => {
          resetStates();
          setShowModal(false);
        }}
      >
        <div className="date-range-picker__info">
          {isShowCompare && (
            <div
              className="date-range-picker__comparedPeriod"
              style={{
                display: appliedState.comparedPeriod.enabled ? 'block' : 'none',
              }}
            >
              {getComparePeriodTextDisplay()}
            </div>
          )}
          <DisabledTooltipWrapper
            disabled={disabled && disabledMessage}
            message={disabledMessage}
            placement="bottom"
          >
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
              className={`date-range-picker__input-container${
                showModal ? ' show' : ''
              }`}
              onClick={(e) => toggleCalendar(e)}
            >
              <span className="date-range-picker__icon">
                <i className="fal fa-calendar-alt" />
              </span>
              <input
                type="text"
                value={inputValue}
                className="date-range-picker__input"
                readOnly
              />
            </div>
          </DisabledTooltipWrapper>
        </div>
        <div
          ref={menuEl}
          className={modelClass}
          style={
            showModal
              ? {
                  visibility: 'visible',
                  overflow: '',
                  height: '',
                }
              : {
                  visibility: 'hidden',
                  overflow: 'hidden',
                  height: '0px',
                }
          }
        >
          <SidePanel
            setDateRangeForType={setDateRangeForType}
            setComparePeriodDateRangeForType={setComparePeriodDateRangeForType}
            comparedPeriodOn={tempState.comparedPeriod.enabled}
            comparedPeriodDisabled={comparedPeriodDisabled}
            onToggleComparePeriod={(enabled) => {
              changeComparedEnabled(enabled);
            }}
            selectedCurrentPreset={tempState.current.preset}
            selectedComparedPreset={tempState.comparedPeriod.preset}
            comparedPeriodSupported={comparedPeriodSupported}
          />
          <div className="main-panel" ref={datepickerRef}>
            <DayPickerRangeController
              key={renderKey}
              startDateId="startDateId"
              startDate={tempState.current.startDate}
              endDate={tempState.current.endDate}
              endDateId="endDateId"
              numberOfMonths={2}
              onDatesChange={onDatesChange}
              onFocusChange={focusChange}
              focusedInput={focus}
              monthFormat="YYYY/MM"
              showInputs
              hideKeyboardShortcutsPanel
              noBorder
              onPrevMonthClick={(month) => onPrevMonthClick(month)}
              onNextMonthClick={(month) => onNextMonthClick(month)}
              initialVisibleMonth={() => initialMonth}
              navPrev={<PrevIcon />}
              navNext={<NextIcon />}
              maxDate={maxDate.clone().add(1, 'day')}
              minDate={minDate}
              firstDayOfWeek={1}
              daySize={26}
              minimumNights={0}
              isOutsideRange={(d) =>
                !d.isBetween(minDate, maxDate, 'day', '[]')
              }
              renderMonthElement={(monthProps) => (
                <CalendarMonth month={monthProps.month} />
              )}
              transitionDuration={0}
            />

            <Row className="drp-buttons">
              <Col className="drp-inputed" md={7}>
                <div className="input-date">
                  期間：
                  <input
                    type="text"
                    placeholder="-"
                    value={customInput.current.startDate}
                    onChange={(e) =>
                      onInputDateChange(e.target.value, true, false)
                    }
                    onBlur={(e) =>
                      tryConvertInputDate(e.target.value, true, false)
                    }
                  />
                  <span>～</span>
                  <input
                    type="text"
                    placeholder="-"
                    value={customInput.current.endDate}
                    onChange={(e) =>
                      onInputDateChange(e.target.value, false, false)
                    }
                    onBlur={(e) =>
                      tryConvertInputDate(e.target.value, false, false)
                    }
                  />
                </div>
                <div
                  className={`input-date-compare ${
                    (!tempState.comparedPeriod.enabled ||
                      comparedPeriodDisabled) &&
                    'disabled'
                  }`}
                >
                  比較：
                  <input
                    type="text"
                    placeholder="-"
                    value={customInput.comparedPeriod.startDate}
                    onChange={(e) =>
                      onInputDateChange(e.target.value, true, true)
                    }
                    onBlur={(e) =>
                      tryConvertInputDate(e.target.value, true, true)
                    }
                    disabled={
                      !tempState.comparedPeriod.enabled ||
                      comparedPreset !== DATE_RANGE_TYPE.CUSTOM ||
                      comparedPeriodDisabled
                    }
                  />
                  <span>～</span>
                  <input
                    type="text"
                    placeholder="-"
                    value={customInput.comparedPeriod.endDate}
                    onChange={(e) =>
                      onInputDateChange(e.target.value, false, true)
                    }
                    onBlur={(e) =>
                      tryConvertInputDate(e.target.value, false, true)
                    }
                    disabled={
                      !tempState.comparedPeriod.enabled ||
                      comparedPreset !== DATE_RANGE_TYPE.CUSTOM ||
                      comparedPeriodDisabled
                    }
                  />
                </div>
              </Col>
              <Col className="btn-control" md={5}>
                <Button variant="link" size="sm" onClick={onCancelClick}>
                  キャンセル
                </Button>
                <Button variant="secondary" size="sm" onClick={onOkClick}>
                  適用
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

EbisDateRangePicker.propTypes = {
  /**
   * Handler when dates are changed.
   * Args: {
   *  current: {startDate:moment, endDate:moment},
   *  comparedPeriod: {enabled:boolean, startDate:moment, endDate:moment}
   * }
   */
  onChange: func,
  comparedPeriodDisabled: bool,
  onActive: PropTypes.func,
  active: PropTypes.bool,
  period: any,
  comparedPeriod: any,
  drop: string,
  isShowCompare: bool,
  maxDuration: objectOf(shape({ timeUnit: any, value: number })),
  maxDate: any,
  minDate: any,
  comparedPeriodSupported: bool,
  disabled: bool,
  disabledMessage: string,
};

EbisDateRangePicker.defaultProps = {
  onChange: () => {},
  comparedPeriodDisabled: false,
  onActive: () => {},
  active: false,
  period: {
    start: moment().subtract(1, 'day'),
    end: moment().subtract(1, 'day'),
  },
  comparedPeriod: {
    enabled: false,
    start: null,
    end: null,
  },
  drop: 'left',
  isShowCompare: true,
  maxDuration: {
    timeUnit: 'year',
    value: 1,
  },
  maxDate: moment(),
  minDate: moment().subtract(2, 'year'),
  comparedPeriodSupported: false,
  disabled: false,
  disabledMessage: '',
};

function SidePanel(props) {
  const {
    setDateRangeForType,
    setComparePeriodDateRangeForType,
    comparedPeriodOn,
    onToggleComparePeriod,
    comparedPeriodDisabled,
    selectedCurrentPreset,
    selectedComparedPreset,
    comparedPeriodSupported,
  } = props;

  const [showOthers, setShowOthers] = useState(false);
  const compareEnabled = comparedPeriodOn;

  const changeSelected = (id) => {
    setDateRangeForType(id);
  };

  const getSelectedOptionText = () => {
    switch (selectedCurrentPreset) {
      case DATE_RANGE_TYPE.DAYS_AGO_7_INCLUDED:
        return '過去7日';
      case DATE_RANGE_TYPE.DAYS_AGO_14_INCLUDED:
        return '過去14日';
      case DATE_RANGE_TYPE.DAYS_AGO_30_INCLUDED:
        return '過去30日';
      case DATE_RANGE_TYPE.DAYS_AGO_7_EXCLUDED:
        return '過去7日（当日含まない）';
      case DATE_RANGE_TYPE.DAYS_AGO_14_EXCLUDED:
        return '過去14日（当日含まない）';
      case DATE_RANGE_TYPE.DAYS_AGO_30_EXCLUDED:
        return '過去30日（当日含まない）';
      case DATE_RANGE_TYPE.LAST_WEEK_BUSINESS_DAYS:
        return '前週の営業日（月～金）';
      case DATE_RANGE_TYPE.THIS_MONTH_EXCLUDED:
        return '今月（当月含まない）';
      default:
        return 'その他';
      // throw new Error(
      //   `Invalid DATE_RANGE_TYPE: ${selectedValue}`
      // );
    }
  };

  const getSelectedCompareOptionText = () => {
    switch (selectedComparedPreset) {
      case DATE_RANGE_TYPE.CUSTOM:
        return 'カスタム';
      case DATE_RANGE_TYPE.LAST_WEEK_THIS_PERIOD:
        return '前週（月～日）';
      case DATE_RANGE_TYPE.LAST_MONTH_THIS_PERIOD:
        return '前月';
      case DATE_RANGE_TYPE.LAST_YEAR_SAME_PERIOD:
        return '前年同月';

      case DATE_RANGE_TYPE.LAST_PERIOD:
      default:
        return '前の期間';
    }
  };

  const changeSelectedCompareOption = (id, customValue = null) => {
    setComparePeriodDateRangeForType(id, customValue);
  };

  const isSelected = (id) => selectedCurrentPreset === id;
  const isComparePresetSelected = (id) => selectedComparedPreset === id;

  const Toggle = () => {
    const { t } = useTranslation();
    const displayTooltip = display.getTooltip(t);

    const toggle = () => {
      onToggleComparePeriod(!compareEnabled);
    };

    if (comparedPeriodDisabled) {
      const tooltip = comparedPeriodSupported
        ? 'compare_period_calendar_disabled_2nd_axis'
        : 'compare_period_calendar_disabled';
      return (
        <FormCheck.Label>
          <TooltipWrapper
            placement="bottom"
            tooltip={
              <Tooltip variant="disabled">{displayTooltip(tooltip)}</Tooltip>
            }
          >
            <Switch
              label="期間比較"
              className="compared-switch"
              active={compareEnabled}
              disabled
            />
          </TooltipWrapper>
        </FormCheck.Label>
      );
    }

    return (
      <Switch
        label="期間比較"
        className="compared-switch"
        active={compareEnabled}
        onChange={toggle}
      />
    );
  };

  return (
    <div className="side-panel">
      <SidePanelItem
        text="今日"
        selected={isSelected(DATE_RANGE_TYPE.TODAY)}
        onClick={() => changeSelected(DATE_RANGE_TYPE.TODAY)}
      />
      <SidePanelItem
        text="昨日"
        selected={isSelected(DATE_RANGE_TYPE.YESTERDAY)}
        onClick={() => changeSelected(DATE_RANGE_TYPE.YESTERDAY)}
      />
      <SidePanelItem
        text="今週（月～日）"
        selected={isSelected(DATE_RANGE_TYPE.THIS_WEEK)}
        onClick={() => changeSelected(DATE_RANGE_TYPE.THIS_WEEK)}
      />
      <SidePanelItem
        text="前週（月～日）"
        selected={isSelected(DATE_RANGE_TYPE.LAST_WEEK)}
        onClick={() => changeSelected(DATE_RANGE_TYPE.LAST_WEEK)}
      />
      <SidePanelItem
        text="今月"
        selected={isSelected(DATE_RANGE_TYPE.THIS_MONTH)}
        onClick={() => changeSelected(DATE_RANGE_TYPE.THIS_MONTH)}
      />
      <SidePanelItem
        text="前月"
        selected={isSelected(DATE_RANGE_TYPE.LAST_MONTH)}
        onClick={() => changeSelected(DATE_RANGE_TYPE.LAST_MONTH)}
      />

      <Dropdown onSelect={() => setShowOthers(!showOthers)}>
        <Dropdown.Toggle
          id="btnSelectOtherPeriod"
          className={selectedCurrentPreset > 6 ? 'selected' : ''}
        >
          <span>{getSelectedOptionText()}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <SidePanelItem
            text="過去7日"
            selected={isSelected(DATE_RANGE_TYPE.DAYS_AGO_7_INCLUDED)}
            onClick={() => changeSelected(DATE_RANGE_TYPE.DAYS_AGO_7_INCLUDED)}
          />
          <SidePanelItem
            text="過去14日"
            selected={isSelected(DATE_RANGE_TYPE.DAYS_AGO_14_INCLUDED)}
            onClick={() => changeSelected(DATE_RANGE_TYPE.DAYS_AGO_14_INCLUDED)}
          />
          <SidePanelItem
            text="過去30日"
            selected={isSelected(DATE_RANGE_TYPE.DAYS_AGO_30_INCLUDED)}
            onClick={() => changeSelected(DATE_RANGE_TYPE.DAYS_AGO_30_INCLUDED)}
          />
          <SidePanelItem
            text="過去7日（当日含まない）"
            selected={isSelected(DATE_RANGE_TYPE.DAYS_AGO_7_EXCLUDED)}
            onClick={() => changeSelected(DATE_RANGE_TYPE.DAYS_AGO_7_EXCLUDED)}
          />
          <SidePanelItem
            text="過去14日（当日含まない）"
            selected={isSelected(DATE_RANGE_TYPE.DAYS_AGO_14_EXCLUDED)}
            onClick={() => changeSelected(DATE_RANGE_TYPE.DAYS_AGO_14_EXCLUDED)}
          />
          <SidePanelItem
            text="過去30日（当日含まない）"
            selected={isSelected(DATE_RANGE_TYPE.DAYS_AGO_30_EXCLUDED)}
            onClick={() => changeSelected(DATE_RANGE_TYPE.DAYS_AGO_30_EXCLUDED)}
          />
          <SidePanelItem
            text="前週の営業日（月～金）"
            selected={isSelected(DATE_RANGE_TYPE.LAST_WEEK_BUSINESS_DAYS)}
            onClick={() =>
              changeSelected(DATE_RANGE_TYPE.LAST_WEEK_BUSINESS_DAYS)
            }
          />
          <SidePanelItem
            text="今月（当日含まない）"
            selected={isSelected(DATE_RANGE_TYPE.THIS_MONTH_EXCLUDED)}
            onClick={() => changeSelected(DATE_RANGE_TYPE.THIS_MONTH_EXCLUDED)}
            enabled={getToday().date() > 1}
          />
        </Dropdown.Menu>
      </Dropdown>

      <div style={{ backgroundColor: '#DDDDDD', height: '1px' }} />

      <div className="compare-switch-button">
        <Toggle />
      </div>
      <Dropdown
        className="dropdown-compare"
        onSelect={() => setShowOthers(!showOthers)}
      >
        <Dropdown.Toggle
          id="btnSelectComparePeriod"
          disabled={!compareEnabled || comparedPeriodDisabled}
          className={
            compareEnabled && !comparedPeriodDisabled ? 'selected' : ''
          }
        >
          <span>{getSelectedCompareOptionText()}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <SidePanelItem
            text="前の期間"
            selected={isComparePresetSelected(DATE_RANGE_TYPE.LAST_PERIOD)}
            onClick={() =>
              changeSelectedCompareOption(DATE_RANGE_TYPE.LAST_PERIOD)
            }
          />
          <SidePanelItem
            text="前週（月～日）"
            selected={isComparePresetSelected(
              DATE_RANGE_TYPE.LAST_WEEK_THIS_PERIOD
            )}
            onClick={() =>
              changeSelectedCompareOption(DATE_RANGE_TYPE.LAST_WEEK_THIS_PERIOD)
            }
          />
          <SidePanelItem
            text="前月"
            selected={isComparePresetSelected(
              DATE_RANGE_TYPE.LAST_MONTH_THIS_PERIOD
            )}
            onClick={() =>
              changeSelectedCompareOption(
                DATE_RANGE_TYPE.LAST_MONTH_THIS_PERIOD
              )
            }
          />
          <SidePanelItem
            text="前年同月"
            selected={isComparePresetSelected(
              DATE_RANGE_TYPE.LAST_YEAR_SAME_PERIOD
            )}
            onClick={() =>
              changeSelectedCompareOption(DATE_RANGE_TYPE.LAST_YEAR_SAME_PERIOD)
            }
          />
          <SidePanelItem
            text="カスタム"
            selected={isComparePresetSelected(DATE_RANGE_TYPE.CUSTOM)}
            onClick={() => changeSelectedCompareOption(DATE_RANGE_TYPE.CUSTOM)}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

SidePanel.propTypes = {
  setDateRangeForType: func,
  setComparePeriodDateRangeForType: func,
  onToggleComparePeriod: func,
  comparedPeriodDisabled: bool,
  selectedCurrentPreset: number,
  selectedComparedPreset: number,
  comparedPeriodOn: bool,
  comparedPeriodSupported: bool.isRequired,
};

SidePanel.defaultProps = {
  setDateRangeForType: () => {},
  setComparePeriodDateRangeForType: () => {},
  onToggleComparePeriod: () => {},
  comparedPeriodDisabled: false,
  selectedCurrentPreset: null,
  selectedComparedPreset: null,
  comparedPeriodOn: false,
};

function SidePanelItem(props) {
  const { text, onClick, selected, enabled } = props;
  const clsName = classNames({
    'side-panel-item': true,
    selected,
    disabled: !enabled,
  });

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={() => onClick()} className={clsName}>
      {text}
    </div>
  );
}

SidePanelItem.defaultProps = {
  text: '',
  onClick: () => {},
  selected: false,
  enabled: true,
};

SidePanelItem.propTypes = {
  text: string,
  onClick: func,
  selected: bool,
  enabled: bool,
};

export default React.memo(EbisDateRangePicker);
