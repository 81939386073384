import { createSelector } from 'reselect';
import { createTableHeaders } from 'services/utils';
import { createFieldsFromConversions } from 'domain/utils';
import { CV_METRICS } from 'domain/consts';
import * as DisplayItems from 'domain/settings/display-items';
import {
  AD_NAME,
  CATEGORY,
  MEDIA_SIDE_AD_NAME,
  SYNC_CATEGORY,
} from 'domain/fields';
import displayItemsSelectors from 'store/display-items/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import settingsSelectors from 'store/settings/selectors';

const listColumnDisabledTooltip = [
  CATEGORY,
  AD_NAME,
  SYNC_CATEGORY,
  MEDIA_SIDE_AD_NAME,
];

const getSort = (state) => state.cache.lpoLink.table.settings.sort;
const getPagination = (state) => state.cache.lpoLink.table.settings.pagination;

const getListReport = (state) => state.cache.lpoLink.table.data.list;
const getSumReport = (state) => state.cache.lpoLink.table.data.sum;

const getStatus = (state) => state.cache.lpoLink.status;
const getStatusTable = (state) => state.cache.lpoLink.table.status;

const getCvMetrics = (state) => {
  const conversions = masterDataSelectors.conversionsForDisplaySelector(state);
  const metricSelected = displayItemsSelectors.getMetricsSelector(state);

  return createFieldsFromConversions(
    conversions,
    metricSelected.filter((metric) => CV_METRICS.includes(metric))
  );
};

const dimensionsSelector = createSelector(
  [
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (metrics, dimensions, displayItems) => {
    const items = displayItems
      .filter(
        (item) =>
          (item.displayFreeze && dimensions.includes(item.key)) ||
          (metrics.includes(item.key) &&
            (item.key === 'transition_rate' || item.key === 'link_page_title'))
      )
      .map((item) => item.key);

    return items;
  }
);

const dimensionsSelectorApi = createSelector(
  [
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (dimensions, displayItems) => {
    const items = displayItems
      .filter((item) => item.displayFreeze && dimensions.includes(item.key))
      .map((item) => item.key);

    return items;
  }
);

const metricsSelector = createSelector(
  [
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (metrics, displayItems) => {
    return displayItems
      .filter((item) => !item.displayFreeze && metrics.includes(item.key))
      .map((item) => item.key);
  }
);

const metricsSelectorApi = createSelector(
  [
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (metrics, displayItems) => {
    return displayItems
      .filter(
        (item) =>
          (!item.displayFreeze && metrics.includes(item.key)) ||
          (metrics.includes(item.key) &&
            (item.key === 'transition_rate' || item.key === 'link_page_title'))
      )
      .map((item) => item.key);
  }
);

const sortSelector = createSelector(
  [dimensionsSelector, metricsSelector, getCvMetrics, getSort],
  (dimensions, metrics, cvMetrics, sort) => {
    const newMetrics = [...metrics];
    cvMetrics
      .filter((cntDirect) => cntDirect.children.length > 0)
      .forEach((cntDirect) =>
        cntDirect.children.map((conversion) => newMetrics.push(conversion.name))
      );

    if (sort && dimensions.concat(newMetrics).includes(sort.field)) {
      return { sort: (sort.direction === 'asc' ? '' : '-') + sort.field };
    }

    return {};
  }
);

// Create header data
export const dimensionsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const dimensions = displayItems
    .filter((item) => item.displayFreeze)
    .map((item) => {
      const items = {
        text: item.title,
        name: item.key,
      };
      // Remove tooltips in Tab AD's cateogry & ad_name columns since it confuse users
      if (listColumnDisabledTooltip.includes(item.key)) {
        items.tooltip = false;
      }
      // link display
      if (item.key === 'ad_id') {
        items.cellDisplay = 'viewDetail';
      }
      // Show Image
      if (item.key === 'transition_rate') {
        items.cellDisplay = 'imageView';
      }
      return items;
    });

  return dimensions;
};

export const metricsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const cvMetrics = getCvMetrics(state);

  const metrics = [];
  displayItems
    .filter((item) => !item.displayFreeze)
    .map((item) => {
      if (CV_METRICS.includes(item.key)) {
        metrics.push(...cvMetrics);
      } else {
        metrics.push({
          text: item.title,
          name: item.key,
        });
      }
      return metrics;
    });

  return metrics;
};

export const groupsConfig = () => [
  {
    name: 'indirect',
    text: '間接効果',
    children: [
      DisplayItems.CNT_INDIRECT2,
      DisplayItems.CNT_INDIRECT3,
      DisplayItems.CNT_INDIRECT4,
      DisplayItems.CNT_INDIRECT5,
      DisplayItems.CNT_INDIRECT_OTHER,
      DisplayItems.INDIRECT_TOTAL,
    ],
  },
  {
    name: 'immediately',
    text: '直間比率',
    children: [DisplayItems.DIRECT_RATE, DisplayItems.INDIRECT_RATE],
  },
];

const getConfig = (state) => {
  return {
    dimensions: dimensionsConfig(state),
    metrics: metricsConfig(state),
    groups: groupsConfig(),
  };
};

const headerSelector = createSelector(
  [
    settingsSelectors.getTab,
    dimensionsSelector,
    metricsSelector,
    getCvMetrics,
    getSort,
    getConfig,
  ],
  (channel, dimensions, metrics, cvMetrics, sort, itemsConfig) => {
    // create table header data
    const headerData = createTableHeaders({
      channel,
      dimensions,
      metrics: [...metrics, ...cvMetrics.map((cntDirect) => cntDirect.name)],
      sorts: [sort],
      itemsConfig,
    });

    return headerData;
  }
);

export default {
  getListReport,
  getSumReport,
  getPagination,
  getStatus,
  getStatusTable,
  dimensionsSelector,
  dimensionsSelectorApi,
  metricsSelector,
  metricsSelectorApi,
  headerSelector,
  sortSelector,
};
