import { createSelector } from 'reselect';
import { PAGES_REPORTS } from 'services/consts';
import {
  SETTING_AGENCY_VIEW,
  SETTING_AGENCY_EDIT,
  AD_ANALYSIS_ITEM_EXCLUDE_LOG_VIEW,
  ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
  DATA_EXPORT_EDIT,
  DATA_EXPORT_AD_REPO_EDIT,
  SETTING_PAGE_MANAGEMENT_EDIT,
  SETTING_USER_EDIT,
  MEDIA_SYNC_MANAGEMENT_EDIT,
} from 'domain/permissions/contractPermissions';
import {
  LOG_CONTRACT_PERMISSIONS,
  LOG_CONTRACT_PERMISSIONS_FOR_SETTINGS,
} from 'domain/permissions/contractGroups';
import { resolve } from 'domain/permissions/permissionTypes';
import settingsSelectors from 'store/settings/selectors';
import { ROLE_SETTING } from 'domain/permissions/roles';

const getCodeVerifier = (state) => state.user.codeVerifier;
const getCode = (state) => state.user.code;
const getAccessToken = (state) => state.user.accessToken;
const getRefreshToken = (state) => state.user.refreshToken;
const getIsLoggedIn = (state) => state.user.isLoggedIn;
// Beta version always back to Hotei Callback URl
const getReturnTo = (state) => state.user.callbackUrl;
const getPermissions = (state) => state.user.permissions || [];
const isTopUserId = (state) => state.user.userId === 'ebisadmin';
const isNextUserId = (state) =>
  state.user.userId === state.user.account.accountId;
const getAccountId = (state) => state.user.account.accountId;
const getAccountInfo = (state) => state.user.account;
const getUserId = (state) => state.user.userId;
const getAgencyId = (state) => state.user.agencyId;
const getAgencyName = (state) => state.user.agencyName;
const getAgentFlag = (state) => state.user.agentFlag || false;
const getChargeName = (state) => state.user.chargeName;
const getCompanyName = (state) => state.user.companyName;
const getTutorialData = (state) => state.user.tutorial;
const getMaintenanceData = (state) => state.user.maintenance;
const getConfigAdCvCostMode = (state) => state.user.account.adCvCostMode ?? '0';
const getConfigMeasurementHosts = (state) =>
  state.user.account.measurementHosts ?? [];
const getConfigAccountStr = (state) => state.user.account.accountStr ?? '';
const getUserRoles = (state) => state.user.userRoles ?? [];
const getUser = (state) => state.user;
const getEmail = (state) => state.user.email || '';
const getCJContractFlag = (state) => state.user.isCJContract || false;

const checkHasContractAgent = (state) => {
  const permissions = getPermissions(state);
  return permissions.includes(SETTING_AGENCY_VIEW);
};

const checkHasPermissionSettingUser = (state) => {
  const permissions = getPermissions(state);
  return permissions.includes(SETTING_USER_EDIT);
};

const checkHasMediaSyncEdit = (state) => {
  const permissions = getPermissions(state);
  return permissions.includes(MEDIA_SYNC_MANAGEMENT_EDIT);
};

const checkPermissionAgencyEdit = (state) => {
  const permissions = getUserRoles(state);
  return permissions.includes(ROLE_SETTING);
};

const checkAllowedSettingReport = (state) => {
  const permissions = getPermissions(state);
  const isAgentUser = getAgentFlag(state);

  return {
    isAllowedSettingRegularReport:
      !isAgentUser &&
      resolve(
        {
          type: 'anyOf',
          permissions: [
            AD_ANALYSIS_ITEM_EXCLUDE_LOG_VIEW,
            ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
          ],
        },
        permissions
      ),
    isAllowedSettingDataExport:
      !isAgentUser &&
      resolve(
        {
          type: 'anyOf',
          permissions: [DATA_EXPORT_EDIT],
        },
        permissions
      ),
    isAllowedSettingAdrepoReport:
      !isAgentUser &&
      resolve(
        {
          type: 'anyOf',
          permissions: [DATA_EXPORT_AD_REPO_EDIT],
        },
        permissions
      ),
  };
};

const checkHasContractLog = createSelector(
  [settingsSelectors.getPage, settingsSelectors.getCurrentTab, getPermissions],
  (page, tab, permissions) => {
    const logContractPermissions = PAGES_REPORTS.includes(page)
      ? LOG_CONTRACT_PERMISSIONS[tab]
      : LOG_CONTRACT_PERMISSIONS_FOR_SETTINGS;
    return resolve(logContractPermissions, permissions);
  }
);

const checkHasContractLogForPageManagement = (state) => {
  const permissions = getPermissions(state);
  return permissions.includes(SETTING_PAGE_MANAGEMENT_EDIT);
};

export {
  getCodeVerifier,
  getCode,
  getAccessToken,
  getRefreshToken,
  getIsLoggedIn,
  getReturnTo,
  getPermissions,
  isTopUserId,
  isNextUserId,
  getAccountId,
  getAccountInfo,
  getUserId,
  getAgencyId,
  getAgencyName,
  getAgentFlag,
  getChargeName,
  getCompanyName,
  getTutorialData,
  getMaintenanceData,
  getConfigAdCvCostMode,
  getConfigMeasurementHosts,
  checkHasContractAgent,
  checkPermissionAgencyEdit,
  checkHasContractLog,
  getUserRoles,
  getConfigAccountStr,
  checkAllowedSettingReport,
  checkHasContractLogForPageManagement,
  checkHasPermissionSettingUser,
  getUser,
  checkHasMediaSyncEdit,
  getEmail,
  getCJContractFlag,
};
