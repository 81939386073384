import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import DomainFilterService from 'domain/FilterService';
import { AGENCY_ID } from 'domain/fields';
import { UNREGISTERED_ID_FILTER_KEY } from 'domain/consts';
import { masterDataRecordsToSelectItems } from 'domain/utils';
import agencySelectors from 'store/agency-management/selectors';
import masterDataSelectors from 'store/master-data/selectors';

const getList = (state) => {
  const { byIds } = state.cache.userManagement;
  return byIds;
};
const getById = (state, viewId) => {
  const { data } = state;
  if (!Object.prototype.hasOwnProperty.call(data, viewId)) {
    throw new Error(`Does not found view ${viewId}`);
  }
  const { id, view_name: name, status: selected } = data[viewId];
  return { id, name, selected };
};

const getStatusList = (state) => {
  const { status } = state.cache.userManagement.apiStatus.list;
  return status;
};

const getSelectedUser = (state) => {
  const { selected } = state.cache.userManagement;
  return selected;
};

const isShowModal = (state) => {
  const { isShowSettingModal } = state.cache.userManagement.settings;
  return isShowSettingModal;
};

const getApiError = createSelector(
  [agencySelectors.getErrorDetail, (state) => state.cache.userManagement.error],
  (agencyDetailErrors, userApiErrors) => {
    return isEmpty(agencyDetailErrors)
      ? userApiErrors?.data?.errors || []
      : agencyDetailErrors;
  }
);

const getCurrentUserNumber = (state) =>
  state.cache.userManagement.settings.pagination.totalItems;

const getSettings = (state) => state.cache.userManagement.settings;

const getCurrentPage = (state) =>
  state.cache.userManagement.settings.pagination.currentPage;

const getListAgency = createSelector(
  [
    masterDataSelectors.getStatus,
    masterDataSelectors.getAgency,
    masterDataSelectors.getSearchResultType(AGENCY_ID),
  ],
  (status, agencies, searchResult) => {
    const agencySetting = masterDataRecordsToSelectItems(
      DomainFilterService.uiMapperData(agencies),
      [UNREGISTERED_ID_FILTER_KEY]
    );
    return {
      isLoading: status === 'loading',
      list: searchResult
        ? DomainFilterService.uiMapperData(searchResult)
        : agencySetting,
    };
  }
);

export default {
  getList,
  getById,
  getCurrentUserNumber,
  getStatusList,
  getSelectedUser,
  isShowModal,
  getApiError,
  getListAgency,
  getSettings,
  getCurrentPage,
};
