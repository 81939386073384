import { SettingsApi as Api } from 'services/api/Api';
import pages from 'services/routes/pages';
import { MEDIA_SYNC_MANAGEMENT } from 'services/routes/constants';
import apiUtils from 'services/apiUtils';
import {
  AUTHENTICATE_DATAHUB,
  AUTHENTICATE_ETL,
  SYNC_MEDIA_ID_YH,
  SYNC_MEDIA_ID_YS,
} from 'domain/media-sync-management/consts';

const MEDIA_SYNC_MANAGEMENT_API_ENDPOINT =
  pages[MEDIA_SYNC_MANAGEMENT].endpoint;

const requestSyncToAsync = async (
  syncUrl,
  asyncUrl,
  data = null,
  method = 'GET'
) => {
  return Api.requestSyncToAsync({
    syncUrl,
    asyncUrl,
    data,
    method,
    config: {
      pollTokenKey: 'token',
      pollUrlFormat: `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/polling/{token}`,
    },
  });
};

// Tips: If 'sort' is not specified, 'media_name descending order' is specified on the API side.
const fetchAll = async (data) =>
  Api.get(`${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}`, { params: data });

const fetchDetail = async (targetId) => {
  if (!targetId) return null;
  return Api.get(
    `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/${encodeURI(targetId)}`
  );
};

const create = async (data) => {
  const asyncUrl = MEDIA_SYNC_MANAGEMENT_API_ENDPOINT;
  return Api.requestAsync(asyncUrl, data, 'POST', {
    pollTokenKey: 'token',
    pollUrlFormat: `${asyncUrl}/create/{token}`,
  });
};

const getStatusAccount = async () => {
  return Api.get(`${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/status`);
};
const issueAccount = async () => {
  return Api.post(`${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/activate`);
};

// A request to receive only the result of the verification process without actually executing the update process is also made to the same URL.
// Tips: On the API side, processing is branched by looking at the value of "item.is_validate_only".
const update = async (id, data) => {
  if (!id) return null;
  const asyncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/${encodeURI(id)}`;
  return Api.requestAsync(asyncUrl, data, 'PUT', {
    pollTokenKey: 'token',
    pollUrlFormat: `${asyncUrl}/update/{token}`,
  });
};

const getListAdvertiser = async (data) => {
  const asyncUrl = apiUtils.buildUrlWithQueryStrings(
    `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers-async`,
    data
  );
  let response = null;
  response = await Api.requestAsync(
    asyncUrl,
    {
      ids: data,
    },
    'GET',
    {
      pollTokenKey: 'token',
      pollUrlFormat: `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/polling/{token}`,
    }
  );
  response = await Api.get(response.data.data.location);
  return response;
};

const getMargins = async (data) => {
  const url = apiUtils.buildUrlWithQueryStrings(
    `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/margins`,
    data
  );
  return Api.get(url);
};

const getAuthUrl = async (data) => {
  const syncUrl = apiUtils.buildUrlWithQueryStrings(
    `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/auth-url`,
    data
  );

  const asyncUrl = apiUtils.buildUrlWithQueryStrings(
    `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/auth-url-async`,
    data
  );

  return requestSyncToAsync(syncUrl, asyncUrl);
};

const checkAdvertiserId = async (data) => {
  const { advertiserId, syncMediaId } = data;
  const syncUrl = apiUtils.buildUrlWithQueryStrings(
    `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers/${advertiserId}`,
    { sync_media_id: syncMediaId }
  );

  const asyncUrl = apiUtils.buildUrlWithQueryStrings(
    `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers-async/${advertiserId}`,
    { sync_media_id: syncMediaId }
  );

  return requestSyncToAsync(syncUrl, asyncUrl);
};

const sendLinkAuthLine = async (data) => {
  const { advertiserId, syncMediaId } = data;
  const syncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers/${advertiserId}`;

  const asyncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers-async/${advertiserId}`;

  return requestSyncToAsync(
    syncUrl,
    asyncUrl,
    {
      sync_media_id: syncMediaId,
      advertiser_id: advertiserId,
    },
    'POST'
  );
};

const verifyClientApproval = async (data) => {
  const { syncMediaId, advertiserId, authFileName } = data;
  const syncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers/${advertiserId}/approval`;

  const asyncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers-async/${advertiserId}/approval`;

  return requestSyncToAsync(
    syncUrl,
    asyncUrl,
    {
      auth_file_name: authFileName,
      sync_media_id: syncMediaId,
    },
    'POST'
  );
};

const verifyDowloadedImage = async (data) => {
  const { advertiserId, authFileName, syncMediaId } = data;
  const syncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers/${advertiserId}/creative`;

  const asyncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers-async/${advertiserId}/creative`;

  return requestSyncToAsync(
    syncUrl,
    asyncUrl,
    {
      auth_file_name: authFileName,
      sync_media_id: syncMediaId,
    },
    'POST'
  );
};

const verifyPasswordLine = async (data) => {
  const { advertiserId, syncMediaId, password } = data;
  const syncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers/${advertiserId}`;

  const asyncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/advertisers-async/${advertiserId}`;

  return requestSyncToAsync(
    syncUrl,
    asyncUrl,
    {
      sync_media_id: syncMediaId,
      password,
    },
    'PATCH'
  );
};

const deleteAccount = async (data) => {
  const asyncUrl = `${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/delete`;
  return Api.requestAsync(
    asyncUrl,
    {
      ids: data,
    },
    'POST',
    {
      pollTokenKey: 'token',
      pollUrlFormat: `${asyncUrl}/{token}`,
    }
  );
};

const changeStatus = async (data) => {
  const { ids, status } = data;
  return Api.put(`${MEDIA_SYNC_MANAGEMENT_API_ENDPOINT}/status`, {
    ids,
    status,
  });
};

export default {
  fetchAll,
  fetchDetail,
  create,
  update,
  getStatusAccount,
  issueAccount,
  getListAdvertiser,
  getMargins,
  getAuthUrl,
  checkAdvertiserId,
  sendLinkAuthLine,
  verifyClientApproval,
  verifyDowloadedImage,
  verifyPasswordLine,
  deleteAccount,
  changeStatus,
};
