import React from 'react';
import { string, func, arrayOf } from 'prop-types';

import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import screenTitleConfigs from 'services/common/screenTitleConfigs';
import { getCJContractFlag, getAgentFlag } from 'store/auth/selectors';
import { shallowEqual, useSelector } from 'react-redux';
import {
  USER_ANALYSIS_SCREEN,
  CUSTOM_JOURNEY_SCREEN,
  RATE_ANALYZE,
} from 'services/routes/constants';

const PermissionWrapperRPC = ({
  requiredPermissions,
  resolveType,
  screenId,
  render,
}) => {
  const { userPermissions, checkPermissions } = useCheckPermissions();
  const isCJContract = useSelector(getCJContractFlag, shallowEqual);
  const isAgentUser = useSelector(getAgentFlag, shallowEqual);

  if (userPermissions === null) {
    return <></>;
  }

  let isAllowed = checkPermissions(resolveType, requiredPermissions);

  // only ltv config
  const { dummyMainContentDisplay = false } = screenTitleConfigs[screenId];
  if (dummyMainContentDisplay) {
    isAllowed = true;
  }

  if (
    isCJContract &&
    [USER_ANALYSIS_SCREEN, CUSTOM_JOURNEY_SCREEN, RATE_ANALYZE].includes(
      screenId
    ) &&
    !isAgentUser
  ) {
    isAllowed = true;
  }

  return render(isAllowed);
};

PermissionWrapperRPC.propTypes = {
  requiredPermissions: arrayOf(string).isRequired,
  resolveType: string.isRequired,
  render: func.isRequired,
  screenId: string,
};

PermissionWrapperRPC.defaultProps = {
  screenId: '',
};

export default PermissionWrapperRPC;
