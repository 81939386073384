import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { DETAILS_ANALYSIS } from 'services/routes/constants';

const DetailAnalyzeApiFactory = () => {
  const url = pages[DETAILS_ANALYSIS].endpoint;

  return {
    url,
    fetchReport: async (data) => {
      return Api.postRequest(url, data);
    },
  };
};

const DetailAnalyzeApi = DetailAnalyzeApiFactory();
export default DetailAnalyzeApi;
