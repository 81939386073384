import React, { useState } from 'react';
import { bool, element, string } from 'prop-types';
import { Collapse } from 'react-bootstrap';

import './ebis-collapse.scss';

function EbisCollapseCustom(props) {
  const { title, children, isShowIconExpand, isAlwayOpenExpand } = props;
  const [isExpand, setExpand] = useState(isAlwayOpenExpand);

  return (
    <div className="ebis-collapse">
      <div
        className="ebis-collapse__head"
        role={isShowIconExpand ? 'button' : 'div'}
        aria-hidden="true"
        onClick={() => setExpand(!isExpand)}
      >
        {isShowIconExpand && (
          <i className={`fas fa-caret-down ${isExpand ? 'show' : ''}`} />
        )}
        <span>{title}</span>
      </div>
      <Collapse in={isExpand}>{children}</Collapse>
    </div>
  );
}

EbisCollapseCustom.propTypes = {
  title: string.isRequired,
  children: element.isRequired,
  isShowIconExpand: bool,
  isAlwayOpenExpand: bool,
};

EbisCollapseCustom.defaultProps = {
  isShowIconExpand: true,
  isAlwayOpenExpand: false,
};

export default EbisCollapseCustom;
