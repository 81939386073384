import helper from 'store/helper';
import types from 'store/ltv/analyze/types';

const requestStart = () => helper.createAction(types.REQUEST_START);
const requestFailed = (error, scope = null) =>
  helper.createAction(types.REQUEST_FAILED, error, scope);

const fetchReport = () => helper.createAction(types.FETCH_REPORT);
const fetchReportSucceeded = (data) =>
  helper.createAction(types.FETCH_REPORT_SUCCEEDED, data);

const updatePage = (page) => helper.createAction(types.UPDATE_PAGE, { page });
const updateSort = (field, direction) =>
  helper.createAction(types.UPDATE_SORT, { field, direction });

// 期間選択
const changeTargetPeriod = (selectedValue) => ({
  type: types.CHANGE_SELECTED_TARGET,
  payload: selectedValue,
});

const showKpiStatusModal = (isShow) => ({
  type: types.SHOW_KPI_STATUS_MODAL,
  payload: isShow,
});

// 期間
const fetchStatus = () => helper.createAction(types.FETCH_STATUS);
const fetchStatusSucceeded = (data) =>
  helper.createAction(types.FETCH_STATUS_SUCCEEDED, data);

export default {
  requestStart,
  requestFailed,

  fetchReport,
  fetchReportSucceeded,

  updatePage,
  updateSort,

  changeTargetPeriod,
  showKpiStatusModal,

  fetchStatus,
  fetchStatusSucceeded,
};
