import displayItemsSelectors from 'store/display-items/selectors';

const getStatus = (state) => state.settings.redirect.status;

const getItems = (state) => {
  const settingsTtems = displayItemsSelectors.getSettings(state);
  const { data } = state.settings.redirect;
  if (data && data.items) {
    return {
      ...settingsTtems,
      ...data.items,
    };
  }

  return { ...settingsTtems };
};

const getFilters = (state) => {
  const { data } = state.settings.redirect;
  if (data && data.filters) {
    return data.filters;
  }

  return {};
};

const getPeriod = (state) => {
  const { data } = state.settings.redirect;
  if (data && data.period) {
    return data.period;
  }

  return {};
};

const getComparedPeriod = (state) => {
  const { data } = state.settings.redirect;
  if (data && data.comparedPeriod) {
    return data.comparedPeriod;
  }

  return {};
};

export default {
  getStatus,
  getItems,
  getFilters,
  getPeriod,
  getComparedPeriod,
};
