import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EXTERNAL } from 'services/routes/constants';

/**
 * Actual transition handle is in TransitionModal.jsx
 *
 * This component gives the wrapped component
 * the actual link appears in browser while hovering
 */
const ExternalTransitionWrapper = ({ link, type, children, disabled }) => {
  const pathname = useMemo(() => (type === EXTERNAL ? link : `/${link}`), [
    link,
    type,
  ]);

  const handleClickLink = (e) => {
    if (type === EXTERNAL) {
      e.preventDefault();
      window.open(pathname, '_blank');
    }
  };

  if (disabled) return children;

  return (
    <Link
      to={{ pathname }}
      onClick={(e) => handleClickLink(e)}
      className="txt-decoration-none"
    >
      {children}
    </Link>
  );
};

ExternalTransitionWrapper.defaultProps = {
  link: '',
  type: '',
  disabled: false,
};

ExternalTransitionWrapper.propTypes = {
  link: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  disabled: PropTypes.bool,
};

export default ExternalTransitionWrapper;
