import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import {
  arrayOf,
  func,
  number,
  oneOf,
  shape,
  string,
  object,
  bool,
  oneOfType,
} from 'prop-types';
import {
  FilterTypeTwoOptions,
  FILTER_KEY_CHANNEL_DISPLAY,
  FILTER_KEY_CHANNEL_DISPLAY_RANGE,
  FILTER_KEY_PAGE_TITLE,
  FILTER_KEY_UNREGISTER,
} from 'services/consts';
import useDisplay from 'services/custom-hooks/useDisplay';
import {
  getRandomId,
  arrToStr,
  splitStringToArrayByComma,
  communicationStatus,
} from 'services/utils';

import {
  UNLIMITED_VALUE,
  ONE_BYTE_COMMA_SEPERATOR,
  MASTER_DATA_FILTER_LIMIT,
  Metric,
} from 'domain/consts';
import FilterTypeTen from 'views/organism/filter/components/FilterTypeTen';
import FilterTypeEleven from 'views/organism/filter/components/FilterTypeEleven';
import FilterTypeTwoMultiple from 'views/organism/filter/components/FilterTypeTwoMultiple';

import EbisDropdown from 'views/atoms/dropdown/EbisDropdown';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import NumericInput from 'views/atoms/numeric-input/NumericInput';
import MultiNumericInput from 'views/atoms/numeric-input/MultiNumericInput';
import masterDataActions from 'store/master-data/actions';
import masterDataSelectors from 'store/master-data/selectors';
import DomainFilterService from 'domain/FilterService';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import Validator from 'services/validations';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import classNames from 'classnames';

import differenceBy from 'lodash/differenceBy';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
import { settingsSelectors } from 'store/settings';

import './filterTypes.scss';
import PeriodFilter from 'views/organism/filter/PeriodFilter';
import { cloneDeep } from 'lodash';
import * as pages from 'services/routes/constants';

const { LOADING } = communicationStatus;
const addOrRemoveByKey = (item, arr) => {
  let newArr;
  const inArray = arr.some((elem) => elem.key === item.key);
  if (inArray) {
    newArr = arr.filter((selectedItem) => selectedItem.key !== item.key);
  } else {
    newArr = arr.concat(item);
  }
  return newArr;
};

const filterOneRules = {
  [Metric.targetCv.key]: [
    {
      field: Metric.targetCv.key,
      method: 'isLength',
      args: [{ max: 255 }],
      validWhen: true,
      messageParams: [Metric.targetCv.title, '255'],
    },
  ],
  [Metric.adGroup1.key]: [
    {
      field: Metric.adGroup1.key,
      method: 'isLength',
      args: [{ max: 255 }],
      validWhen: true,
      messageParams: [Metric.adGroup1.title, '255'],
    },
  ],
  [Metric.adGroup2.key]: [
    {
      field: Metric.adGroup2.key,
      method: 'isLength',
      args: [{ max: 255 }],
      validWhen: true,
      messageParams: [Metric.adGroup2.title, '255'],
    },
  ],
  [Metric.media.key]: [
    {
      field: Metric.media.key,
      method: 'isLength',
      args: [{ max: 255 }],
      validWhen: true,
      messageParams: [Metric.media.title, '255'],
    },
  ],
};

const FilterTypeOneContainer = (props) => {
  const {
    name,
    items,
    onChange,
    presets,
    error,
    setError,
    clearError,
    maxItemDisplay,
    maxItemSelect,
    isShowAdvertiserId,
    status,
    note,
  } = props;

  const [selected, setSelected] = useState([]);
  const searchResult = useSelector(
    masterDataSelectors.getSearchResultType(name)
  );
  const searchTotal = useSelector(masterDataSelectors.getTotalSearchResult);
  const dispatch = useDispatch();

  const [useSearchResult, setUseSearchResult] = useState(false);

  const doSearch = useCallback(
    (params) => {
      const { type, search: value } = params;
      const validator = new Validator(filterOneRules[type]);
      const validated = validator.validate({ [type]: value });
      if (!isEmpty(validated)) {
        setError(validated);
        return;
      }

      clearError();

      const fetchTypes = {
        conv_id: () => dispatch(masterDataActions.searchCv(params)),
        media_id: () => dispatch(masterDataActions.searchMedia(params)),
        ad_group1_id: () => dispatch(masterDataActions.searchAdGroup1(params)),
        ad_group2_id: () => dispatch(masterDataActions.searchAdGroup2(params)),
        agency_id: () => dispatch(masterDataActions.searchAgency(params)),
        media_side_campaign_id: () =>
          dispatch(masterDataActions.searchMediaSideCampaign(params)),
        media_side_group_id: () =>
          dispatch(masterDataActions.searchMediaSideGroup(params)),
        media_account_id: () =>
          dispatch(masterDataActions.searchMediaAccount(params)),
        content_category_id: () =>
          dispatch(masterDataActions.searchContentCategory(params)),
        media_id_exclude: () => dispatch(masterDataActions.searchMedia(params)),
        ad_group1_id_exclude: () =>
          dispatch(masterDataActions.searchAdGroup1(params)),
        ad_group2_id_exclude: () =>
          dispatch(masterDataActions.searchAdGroup2(params)),
      };
      if (name in fetchTypes) {
        fetchTypes[name]();
      }
    },
    [clearError, name, setError, dispatch]
  );

  const currentSelected = useMemo(() => {
    if (presets && 'ids' in presets && presets.ids && presets.ids.length > 0) {
      return presets.ids;
    }
    return [];
  }, [presets]);

  const handleSearch = useCallback(
    (type, value) => {
      if (!value || trim(value) === '') {
        setUseSearchResult(false);
      } else {
        setUseSearchResult(true);
      }

      doSearch({
        type,
        search: value,
        selected: !value ? currentSelected : [],
      });
    },
    [doSearch, currentSelected]
  );

  useEffect(() => {
    doSearch({ type: name, selected: currentSelected });
  }, [doSearch, name, currentSelected]);

  const displayItems = useMemo(() => {
    let availableItems;
    if (useSearchResult) {
      availableItems = isNil(searchResult)
        ? []
        : DomainFilterService.uiMapperData(searchResult);
    } else {
      availableItems = items;
    }
    if (presets.ids && presets.ids.length > 0) {
      // Pick a weight to make non-selected items "heavier"
      const selectedWeight =
        availableItems.reduce((max, cur) => {
          const order = Number.parseInt(cur.order, 10);
          if (order > max) return order;
          return max;
        }, 0) + 1;

      availableItems = sortBy(availableItems, (item) => {
        const selectedIndex = findIndex(presets.ids, (id) => id === item.key);
        const weight =
          selectedIndex < 0 ? selectedWeight + item.order : selectedIndex;
        return weight;
      });
    }

    if (availableItems.length > maxItemDisplay) {
      return availableItems.slice(0, maxItemDisplay);
    }
    return availableItems;
  }, [items, searchResult, presets.ids, useSearchResult, maxItemDisplay]);

  return (
    <FilterTypeOne
      name={name}
      items={displayItems}
      onChange={onChange}
      onSearch={handleSearch}
      presets={presets}
      error={error}
      selected={selected}
      setSelected={setSelected}
      moreItems={!isNil(searchResult) && searchTotal > maxItemDisplay}
      maxItemSelect={maxItemSelect}
      isShowAdvertiserId={isShowAdvertiserId}
      status={status}
      note={note}
    />
  );
};

FilterTypeOneContainer.propTypes = {
  items: arrayOf(shape({ key: string, value: string })).isRequired,
  name: string.isRequired,
  onChange: func,
  presets: shape({ values: arrayOf(string) }),
  clearError: func,
  setError: func,
  // eslint-disable-next-line react/forbid-prop-types
  error: object,
  maxItemDisplay: number,
  maxItemSelect: number,
  isShowAdvertiserId: bool,
  status: string,
  note: string,
};

FilterTypeOneContainer.defaultProps = {
  onChange: () => {},
  presets: {},
  clearError: () => {},
  setError: () => {},
  error: null,
  maxItemDisplay: MASTER_DATA_FILTER_LIMIT,
  maxItemSelect: MASTER_DATA_FILTER_LIMIT,
  isShowAdvertiserId: false,
  status: '',
  note: '',
};

function FilterTypeOne(props) {
  const {
    name,
    items,
    onChange,
    presets,
    onSearch,
    error,
    moreItems,
    selected,
    setSelected,
    maxItemSelect,
    isShowAdvertiserId,
    status,
    note,
  } = props;

  // Values to display list of checkbox options
  const [displayItems, setDisplayItems] = useState([]);

  const scrollbarWrapperRef = useRef(null);

  const errorMess = (error && Object.values(error)[0]) || '';

  const [lastSelected, setLastSelected] = useState({
    index: -1,
    checked: false,
  });

  const { displayError } = useDisplay();

  const onCheckboxClick = (e, item, index) => {
    const selectedIndex = findIndex(selected, ['key', item.key]);
    const newSelected = [...selected];
    if (selectedIndex === -1) {
      newSelected.push(item);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);

    setLastSelected({
      index,
      checked: !(selectedIndex > -1),
    });
  };

  const removeAll = () => {
    setSelected((prevState) =>
      prevState.filter((selectedItem) =>
        displayItems.every((item) => item.key !== selectedItem.key)
      )
    );
  };

  const selectAll = () => {
    setSelected((prevState) => {
      const remainItems = differenceBy(displayItems, prevState, 'key');
      return [
        ...prevState,
        ...remainItems.slice(0, maxItemSelect - prevState.length),
      ];
    });
  };

  // Search on text
  const search = debounce((value) => {
    setDisplayItems(
      items.filter(
        (item) =>
          item.value && item.value.toLowerCase().includes(value.toLowerCase())
      )
    );
    if (
      [
        'conv_id',
        'media_id',
        'media_id_exclude',
        'ad_group1_id',
        'ad_group1_id_exclude',
        'ad_group2_id',
        'ad_group2_id_exclude',
        'agency_id',
        'media_side_campaign_id',
        'media_side_group_id',
        'media_account_id',
        'content_category_id',
      ].includes(name)
    ) {
      onSearch(name, value);
    }
  }, 200);

  const onShiftClick = (e, item, index) => {
    // const checked = !!selected.find(
    //   (selectedItem) => selectedItem.key === displayItems[index].key
    // );
    if (lastSelected.index >= 0) {
      // checked / uncheck all items between those indices, using lastSelected.checked
      const affectedItems = displayItems.slice(
        Math.min(lastSelected.index, index),
        Math.max(lastSelected.index, index) + 1
      );
      const isPositiveDirection = index - lastSelected.index > 0; // positive means going up

      setSelected((prevState) => {
        // If we checked, then we make sure it doesn't get past our limit
        if (lastSelected.checked) {
          let newItems = differenceBy(affectedItems, prevState, (o) => o.key);
          const totalItems = prevState.length + newItems.length;
          if (totalItems > maxItemSelect) {
            const remainingSlots = maxItemSelect - prevState.length;
            if (isPositiveDirection) {
              // Take items from beginning
              newItems = newItems.slice(0, remainingSlots);
            } else {
              newItems = newItems.slice(
                newItems.length - remainingSlots,
                newItems.length
              );
            }
          }
          return prevState.concat(newItems);
        }

        return prevState.filter((prevItem) =>
          affectedItems.every(
            (affectedItem) => affectedItem.key !== prevItem.key
          )
        );
      });
      setLastSelected((prevState) => ({
        ...prevState,
        index,
      }));
    } else {
      // simply check / uncheck
      onCheckboxClick(e, item, index);
    }
  };

  useEffect(() => {
    let values = presets.ids ? presets.ids : presets.values;
    if (!values) {
      values = [];
    }
    setSelected(
      values.map((value, index) => ({
        key: presets.ids[index],
        value: presets.values[index],
      }))
    );
  }, [presets, setSelected]);

  useEffect(() => {
    setDisplayItems(items);
    setLastSelected({ index: -1, checked: false });
  }, [items]);

  useEffect(() => {
    // Issue: useCallback auto sort ids
    // make sort by key before pass data for onChange callback
    const selectedSortByKey = sortBy(selected, (o) => o.key);
    onChange(
      selectedSortByKey.map((selectedItem) => selectedItem.value),
      null,
      selectedSortByKey.map((selectedItem) => selectedItem.key)
    );
  }, [onChange, selected]);

  const selectedKeys = useMemo(() => {
    return selected.map((selectedItem) => selectedItem.key);
  }, [selected]);

  const clearButtonDisabled = useMemo(() => {
    return displayItems.every((item) => !selectedKeys.includes(item.key));
  }, [selectedKeys, displayItems]);

  const isStatusLoading = status === LOADING;

  const selectAllButtonDisabled = useMemo(() => {
    return (
      selected.length >= maxItemSelect ||
      displayItems.every((item) => selectedKeys.includes(item.key)) ||
      isStatusLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys, displayItems, isStatusLoading]);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="filter-type__one">
      <Form
        // prevent enter leading to submit
        onSubmit={onSubmit}
      >
        <Form.Group className="has-icon">
          <ErrorTooltipWrapper
            isError={errorMess.length > 0}
            errorMess={errorMess}
          >
            <>
              <Form.Control
                type="text"
                size="sm"
                className={error ? 'error txt-search' : 'txt-search'}
                placeholder="値を入力"
                onChange={(e) => search(e.target.value)}
              />
              <i className="fa fa-search" />
            </>
          </ErrorTooltipWrapper>
        </Form.Group>
        <Form.Group>
          <Button
            variant="link"
            size="sm"
            onClick={selectAll}
            disabled={selectAllButtonDisabled}
            className="filter-type-one__checked-all"
          >
            すべて選択
          </Button>
          <Button
            size="sm"
            variant="link"
            // TODO reverse check
            disabled={clearButtonDisabled}
            onClick={removeAll}
            className="filter-type-one__unchecked-all"
          >
            クリア
          </Button>
          <span className="filter-type-one__limit">
            残り{maxItemSelect - selected.length}件設定可能
          </span>
        </Form.Group>
        <DataSyncLoader isLoading={isStatusLoading}>
          <div ref={scrollbarWrapperRef} className="panel__scrollbar">
            <ScrollbarWrapper
              ref={scrollbarWrapperRef}
              maxContent={160}
              sizeScroll={5}
              alignScroll={5}
            >
              <>
                {displayItems.map((item, index) => {
                  const checked = selected.some(
                    (selectedItem) => selectedItem.key === item.key
                  );
                  const disabled = !checked && selected.length >= maxItemSelect;
                  const classname = classNames({
                    'ebis--disabled': disabled,
                  });

                  const pairKeys = item.key.split(':');
                  let advertiserId = '';
                  if (pairKeys.length >= 2) {
                    const [, advID] = pairKeys;
                    advertiserId = advID;
                  }

                  let subtext = '';
                  if (isShowAdvertiserId) {
                    if (
                      advertiserId &&
                      advertiserId !== '#' &&
                      advertiserId !== 'n'
                    ) {
                      subtext = `広告主ID:${advertiserId}`;
                    }
                  }

                  return (
                    <>
                      {/* eslint-disable-next-line */}
                      <div
                        aria-disabled={disabled}
                        className={classname}
                        onClick={(e) => {
                          if (e.shiftKey) {
                            onShiftClick(e, item, index);
                          } else {
                            onCheckboxClick(e, item, index);
                          }
                          e.preventDefault();
                        }}
                      >
                        <EbisCheckbox
                          name={item.key}
                          text={item.value}
                          checked={checked}
                          subtext={subtext}
                          // disabled={disabled}
                        />
                      </div>
                    </>
                  );
                })}
                {moreItems && (
                  <div className="filter-message">
                    {displayError('max_filter_reached')}
                  </div>
                )}
              </>
            </ScrollbarWrapper>
          </div>
        </DataSyncLoader>
        {note && <div className="filter-type__note">{note}</div>}
      </Form>
    </div>
  );
}

FilterTypeOne.propTypes = {
  name: string.isRequired,
  items: arrayOf(shape({ key: string, value: string })).isRequired,
  onChange: func,
  onSearch: func,
  presets: shape({ values: arrayOf(string) }),
  // eslint-disable-next-line react/forbid-prop-types
  error: object,
  moreItems: bool,
  selected: arrayOf(shape({ key: string, value: string })),
  setSelected: func,
  maxItemSelect: number,
  isShowAdvertiserId: bool,
  status: string,
  note: string,
};

FilterTypeOne.defaultProps = {
  onChange: () => {},
  onSearch: () => {},
  presets: {},
  error: null,
  moreItems: false,
  selected: [],
  setSelected: () => {},
  maxItemSelect: MASTER_DATA_FILTER_LIMIT,
  isShowAdvertiserId: false,
  status: '',
  note: '',
};

export function FilterTypeTwo(props) {
  const {
    keepOption,
    options,
    onChange,
    onDelete,
    presets,
    note,
    error,
    clearError,
  } = props;
  // Since API return values in arr example ['item1', 'item2']
  const initialValues = presets.values
    ? [
        arrToStr(
          presets.values,
          false,
          UNLIMITED_VALUE,
          ONE_BYTE_COMMA_SEPERATOR
        ),
      ]
    : [''];
  const [values, setValues] = useState(initialValues);
  const initialOption = presets.option ? presets.option : options[0].key;
  const [option, setOption] = useState(initialOption);
  const [objOptionSelected, setObjOptionSelected] = useState(
    options.find((optionItem) => optionItem.key === option)
  );

  const errorMess = (error && Object.values(error)[0]) || '';
  const isShowOption = options.length > 1 || keepOption;

  const onTextChange = (e) => {
    const splittedValues = splitStringToArrayByComma(e.target.value, false);
    setValues([e.target.value]);
    onChange(splittedValues, option);

    if (errorMess) clearError();
  };

  const onOptionChange = (value) => {
    const splittedValues = splitStringToArrayByComma(values[0], false);
    const valueOption = parseInt(value, 10);
    const objOption = options.find(
      (optionItem) => optionItem.key === valueOption
    );
    setObjOptionSelected(objOption);
    setOption(valueOption);
    onChange(splittedValues, valueOption);
  };

  return (
    <div className="filter-type__two">
      {isShowOption && (
        <EbisDropdown
          optionObject={options}
          selectCallback={onOptionChange}
          defaultVal={option}
          isDisabled={options.length <= 1}
        >
          {onDelete && (
            <div
              className="filter-type__icon-delete icon-hover"
              role="button"
              aria-hidden="true"
              onClick={onDelete}
            >
              <i className="fal fa-times" />
            </div>
          )}
        </EbisDropdown>
      )}
      <Form.Group>
        <ErrorTooltipWrapper
          isError={errorMess.length > 0}
          errorMess={errorMess}
        >
          <Form.Control
            type="text"
            size="sm"
            placeholder={objOptionSelected?.disabledInput ? '' : '値を入力'}
            onChange={onTextChange}
            value={values}
            className={error ? 'error' : ''}
            disabled={!!objOptionSelected?.disabledInput}
          />
        </ErrorTooltipWrapper>
        {!isShowOption && <Form.Label>{options[0].label}</Form.Label>}
      </Form.Group>
      {note && <div className="filter-type__note">{note}</div>}
    </div>
  );
}

FilterTypeTwo.propTypes = {
  // name: string.isRequired,
  options: arrayOf(shape({ key: number, value: string })).isRequired,
  onChange: func,
  presets: shape({ values: arrayOf(string) }),
  // eslint-disable-next-line react/forbid-prop-types
  error: object,
  clearError: func,
  note: string,
  onDelete: oneOfType([bool, func]),
  keepOption: bool,
};

FilterTypeTwo.defaultProps = {
  onChange: () => {},
  presets: {},
  error: null,
  clearError: () => {},
  note: '',
  onDelete: false,
  keepOption: false,
};

function FilterTypeThree(props) {
  const { items, onChange, presets } = props;
  const initialValues = presets.values
    ? items.filter((item) => presets.values.includes(item.value))
    : [];

  const [selectedValues, setSelectedValues] = useState(initialValues);

  const onCheckboxClick = (e, item) => {
    const newSelected = addOrRemoveByKey(item, selectedValues);
    setSelectedValues(newSelected);
    onChange(
      newSelected.map((x) => x.value),
      null,
      newSelected.map((x) => x.key)
    );
  };

  return (
    <Form className="filter-type__three">
      {items.map((item, index) => (
        <>
          <EbisCheckbox
            name={item.key}
            text={item.value}
            checked={selectedValues.some(
              (selectedValue) => selectedValue.key === item.key
            )}
            changeSelectBoxValue={(e) => onCheckboxClick(e, item, index)}
            disabled={item.disabled}
          />
          {!!item.description && (
            <p className="filter-type__three-description">{item.description}</p>
          )}
        </>
      ))}
    </Form>
  );
}

FilterTypeThree.propTypes = {
  items: arrayOf(shape({ key: string, value: string })).isRequired,
  onChange: func,
  presets: shape({ values: arrayOf(string) }),
};

FilterTypeThree.defaultProps = {
  onChange: () => {},
  presets: {},
};

function FilterTypeFour(props) {
  const { items, onChange, presets } = props;

  const initialSelected = presets.values
    ? items.find((item) => presets.values.includes(item.value))
    : null;

  const [selected, setSelected] = useState(initialSelected);

  const onSelectionChange = (item) => {
    // If value === itself
    if (selected && selected.value === item.value) {
      return;
    }
    setSelected(item);

    // If selected value === initial value, don't add new value
    if (item.value === initialSelected?.value) {
      onChange([]);
      return;
    }

    onChange([item.value], null, [item.key]);
  };

  const renderRadioGroup = () => {
    const randomKey = `filter-4-${Math.random()}`;
    return items.map((item) => {
      const classname = classNames({
        'radio-item': true,
        active: selected && selected.value === item.value,
        'ebis--disabled': item.disabled,
      });
      return (
        <Form.Check
          key={item.key}
          id={getRandomId('filter-4')}
          inline
          type="radio"
          name={randomKey}
          disabled={item.disabled}
          label={item.value}
          checked={selected && selected.value === item.value}
          onClick={() => !item.disabled && onSelectionChange(item)}
          className={classname}
        />
      );
    });
  };

  return renderRadioGroup();
}

FilterTypeFour.propTypes = {
  name: string.isRequired,
  items: arrayOf(shape({ key: string, value: string })).isRequired,
  onChange: func,
  presets: shape({ values: arrayOf(string) }),
};

FilterTypeFour.defaultProps = {
  onChange: () => {},
  presets: {},
};

function FilterTypeFive(props) {
  const { onChange, presets, error, clearError } = props;
  const initialValues = presets.values || ['', ''];
  const [values, setValues] = useState(initialValues);

  const minError = error?.amount_min || error?.amount || false;
  const maxError = error?.amount_max || false;

  const onTextChange = (e, index) => {
    const newValues = [...values];
    newValues[index] = e.target.value;
    setValues(newValues);
    onChange(newValues);

    if (error && index === 0) {
      const { amount_min: value, amount: value2, ...restError } = error;
      clearError(restError);
    } else if (error && index === 1) {
      const { amount_max: value, ...restError } = error;
      clearError(restError);
    }
  };

  return (
    <Form inline className="filter-type__five">
      <Form.Group>
        <Form.Label>¥</Form.Label>
        <ErrorTooltipWrapper isError={minError} errorMess={minError}>
          <NumericInput
            type="number"
            size="sm"
            className={minError ? 'number-input error' : 'number-input'}
            placeholder="値を入力"
            onChange={(e) => onTextChange(e, 0)}
            value={values[0]}
          />
        </ErrorTooltipWrapper>
        <Form.Label>以上</Form.Label>
      </Form.Group>
      <Form.Group>
        <Form.Label>¥</Form.Label>
        <ErrorTooltipWrapper isError={maxError} errorMess={maxError}>
          <NumericInput
            type="number"
            size="sm"
            className={maxError ? 'number-input error' : 'number-input'}
            placeholder="値を入力"
            onChange={(e) => onTextChange(e, 1)}
            value={values[1]}
          />
        </ErrorTooltipWrapper>
        <Form.Label>以下</Form.Label>
      </Form.Group>
    </Form>
  );
}

FilterTypeFive.propTypes = {
  name: string.isRequired,
  onChange: func,
  presets: shape({ values: arrayOf(string), option: oneOf([string, number]) }),
  // eslint-disable-next-line react/forbid-prop-types
  error: object,
  clearError: func,
};

FilterTypeFive.defaultProps = {
  onChange: () => {},
  presets: {},
  error: null,
  clearError: () => {},
};

function FilterTypeSeven(props) {
  const { onChange, presets } = props;
  const initialValues = presets.values ? [arrToStr(presets.values)] : [''];
  const [values, setValues] = useState(initialValues);

  const onTextChange = (e) => {
    const splittedValues = splitStringToArrayByComma(e.target.value);
    setValues([e.target.value]);
    const matchTypeExact = 3; // 次に完全一致
    onChange(splittedValues, matchTypeExact);
  };

  return (
    <div className="filter-type__seven">
      <Form.Group>
        <MultiNumericInput
          type="number"
          size="sm"
          className="number-input"
          placeholder="値を入力"
          onChange={(e) => onTextChange(e)}
          value={values}
        />
        <Form.Label>に完全一致</Form.Label>
      </Form.Group>
    </div>
  );
}

FilterTypeSeven.propTypes = {
  onChange: func,
  presets: shape({ values: arrayOf(string) }),
};

FilterTypeSeven.defaultProps = {
  onChange: () => {},
  presets: {},
};

function CountRangeFilter(props) {
  const {
    disabled,
    onChange,
    onRemove,
    showRemove,
    text,
    error,
    value,
  } = props;
  const initialValues = [value.min, value.max];
  const [values, setValues] = useState(initialValues);

  const onTextChange = (e, index) => {
    const newValues = [...values];
    newValues[index] = e.target.value;
    setValues(newValues);
    onChange(newValues);
  };

  const onControlRemove = () => {
    onRemove();
  };

  let variant = 'trushable';
  if (!showRemove) {
    variant = 'non-trush';
  }

  const minClassName = classNames(
    error ? 'number-input error' : 'number-input'
  );
  const maxClassName = classNames(
    error ? 'number-input error' : 'number-input'
  );
  const wrapper = classNames(
    'filter-type__count-range',
    'error',
    `filter-type__count-range--${variant}`
  );

  const compareVariant = disabled ? 'disabled' : 'enabled';

  return (
    <Form inline className={wrapper}>
      <Form.Group>
        <Form.Label className="name">{text}</Form.Label>
      </Form.Group>
      <Form.Group>
        <ErrorTooltipWrapper isError={!!error} errorMess={error}>
          <NumericInput
            type="number"
            size="sm"
            className={minClassName}
            placeholder="値を入力"
            onChange={(e) => onTextChange(e, 0)}
            value={values[0]}
            disabled={disabled}
          />
        </ErrorTooltipWrapper>
        <Form.Label className={`compare--${compareVariant}`}>回以上</Form.Label>
        <NumericInput
          type="number"
          size="sm"
          className={maxClassName}
          placeholder="値を入力"
          onChange={(e) => onTextChange(e, 1)}
          value={values[1]}
          disabled={disabled}
        />
        <Form.Label className={`compare--${compareVariant}`}>回以下</Form.Label>
        {showRemove ? (
          <div
            className="trush"
            tabIndex={0}
            onClick={onControlRemove}
            role="button"
            onKeyDown={() => {}}
          >
            <i className="fal fa-times trush-icon" />
          </div>
        ) : null}
      </Form.Group>
    </Form>
  );
}
CountRangeFilter.propTypes = {
  disabled: bool,
  onChange: func.isRequired,
  onRemove: func.isRequired,
  showRemove: bool,
  text: string,
  error: func.isRequired,
  value: oneOfType([object]).isRequired,
};

CountRangeFilter.defaultProps = {
  disabled: false,
  showRemove: false,
  text: string,
};

export function getItemByKey(items, key) {
  return items.reduce((previos, current) => {
    if (current.key === key) {
      return {
        ...current,
      };
    }
    return previos;
  }, {});
}

function MultiCountRangeFilter(props) {
  const {
    ranges,
    items,
    errors,
    onRemove,
    onChange,
    onDisable,
    showDisable,
    showRemove,
    selectedValues,
    name,
  } = props;

  const [buttonClick, setbuttonClick] = useState(false);
  const scrollbarWrapperRef = useRef(null);

  const onButtonClick = () => {
    setbuttonClick(true);
  };
  // If the range is specified, make the range specification expand.
  useEffect(() => {
    Object.keys(ranges).forEach((key) => {
      if (ranges[key].min !== '' || ranges[key].max !== '') {
        onButtonClick();
      }
    });
  }, [name, ranges]);

  return (
    <>
      <Form className="filter-type__three">
        {Object.keys(ranges).map((key) => {
          const item = getItemByKey(items, key);
          let disabled = true;
          if (selectedValues !== undefined && key in selectedValues) {
            disabled = false;
          }
          return (
            <EbisCheckbox
              name={key}
              text={item.value}
              checked={!disabled}
              changeSelectBoxValue={() => {
                onDisable(key, !disabled);
              }}
              disabled={item.disabled}
            />
          );
        })}
      </Form>
      <hr className="filter-type__nine line" />
      {!buttonClick ? (
        <div className="filter-type__nine text">
          <Button
            size="sm"
            style={{ paddingTop: '10px' }}
            variant="link"
            onClick={() => onButtonClick()}
          >
            接触件数を指定
          </Button>
        </div>
      ) : (
        <>
          <Form inline>
            <Form.Group className="filter-type__nine text">
              <Form.Label style={{ color: '#ccc', paddingTop: '10px' }}>
                件数はand条件でフィルタされます。
              </Form.Label>
            </Form.Group>
          </Form>
          <div ref={scrollbarWrapperRef}>
            <ScrollbarWrapper
              ref={scrollbarWrapperRef}
              maxContent={160}
              sizeScroll={5}
              alignScroll={5}
            >
              {Object.keys(ranges).map((key) => {
                const value = ranges[key];
                const item = getItemByKey(items, key);
                let disabled = true;
                if (selectedValues !== undefined && key in selectedValues) {
                  disabled = false;
                }
                return (
                  <CountRangeFilter
                    disabled={disabled}
                    text={item.value}
                    showDisable={showDisable}
                    showRemove={Object.keys(ranges).length >= 2 && showRemove}
                    error={errors ? errors[key] : null}
                    onRemove={() => {
                      if (Object.keys(ranges).length >= 2) {
                        onRemove(key);
                      }
                    }}
                    onChange={(newValue) => {
                      onChange(key, newValue);
                    }}
                    onDisable={(disable) => {
                      onDisable(key, disable);
                    }}
                    value={value}
                  />
                );
              })}
            </ScrollbarWrapper>
          </div>
        </>
      )}
      <hr className="filter-type__nine line" />
    </>
  );
}

MultiCountRangeFilter.propTypes = {
  ranges: arrayOf(shape({ key: string, value: string })).isRequired,
  items: arrayOf(shape({ key: string, value: string })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: object.isRequired,
  onRemove: bool.isRequired,
  onChange: string.isRequired,
  onDisable: bool.isRequired,
  showDisable: bool.isRequired,
  showRemove: bool.isRequired,
  selectedValues: arrayOf(shape({ key: string, value: string })).isRequired,
  name: string.isRequired,
};

function Range(min = '', max = '') {
  this.min = min;
  this.max = max;
}

Range.prototype.toString = function rangeToString() {
  return `${this.min},${this.max}`;
};

// Keep the changed value.
const changeValues = (item, selectedValues) => {
  const newValues = selectedValues;
  // If you change the radio button
  if (item === undefined) {
    return newValues;
  }
  if (Object.keys(selectedValues).length === 0) {
    return item;
  }
  const key = Object.keys(item)[0];
  if (
    key in selectedValues &&
    item[key].min === newValues[key].min &&
    item[key].max === newValues[key].max
  ) {
    delete newValues[key];
  } else {
    newValues[key] = item[key];
  }
  return newValues;
};

function MultiCountRangeFilterContainer(props) {
  const {
    name,
    defaultDisabled,
    selected,
    items,
    errors,
    setSelected,
    onChange,
    showDisable,
    showRemove,
    showCondition,
    presets,
    filters,
  } = props;
  const [ranges, setRanges] = useState({});
  const [option, setOption] = useState(1);
  const [selectedValues, setSelectedValues] = useState({});

  // 初期値
  const initNotify = (newOption, newRanges) => {
    setOption(newOption);
    setRanges(newRanges);
    onChange(newRanges, newOption, null);
  };

  // 変更通知
  const changeNotify = (newOption, newRanges, text) => {
    setOption(newOption);
    setRanges(text);
    const newSelected = changeValues(newRanges, selectedValues);
    setSelectedValues(newSelected);
    onChange(newSelected, newOption, null);
  };

  // 初期化処理
  useEffect(() => {
    const newRanges = selected.reduce((previos, current) => {
      const range = ranges[current.key];
      if (range === undefined) {
        const newRange = new Range();
        return {
          ...previos,
          [current.key]: newRange,
        };
      }
      return {
        ...previos,
        [current.key]: {
          ...range,
        },
      };
    }, {});
    // 値の保持
    if (Object.keys(presets).length > 0) {
      const values = {};
      const oldIds = filters.display_channel.ids;
      oldIds.map((id) => {
        if (id in presets.values) {
          values[id] = {
            min: presets.values[id].min,
            max: presets.values[id].max,
          };
        } else {
          values[id] = {
            min: '',
            max: '',
          };
        }
        return values;
      });
      if (name === FILTER_KEY_CHANNEL_DISPLAY) {
        Object.keys(presets.values).map((mapKey) => {
          newRanges[mapKey].min = presets.values[mapKey].min;
          newRanges[mapKey].max = presets.values[mapKey].max;
          return newRanges;
        });
      }

      changeNotify(
        presets.option === null ? 1 : presets.option,
        values,
        newRanges
      );
    } else {
      initNotify(option, newRanges);
    }
  }, [selected, presets]);

  return (
    <MultiCountRangeFilter
      defaultDisabled={defaultDisabled}
      showCondition={showCondition}
      showDisable={showDisable}
      showRemove={showRemove}
      ranges={ranges}
      items={items}
      errors={errors}
      condition={option}
      onRemove={(key) => {
        let newSelected = cloneDeep(selected);
        newSelected = newSelected.reduce((previos, current) => {
          if (key !== current.key) {
            return [...previos, current];
          }
          return previos;
        }, []);
        setSelected(newSelected);
        changeNotify(option, ranges, ranges);
      }}
      onChange={(key, newValue) => {
        const newRanges = cloneDeep(ranges);
        const newRange = new Range(newValue[0], newValue[1]);
        newRanges[key] = newRange;
        changeNotify(option, { [key]: newRange }, newRanges);
      }}
      onDisable={(key, disable) => {
        const newRanges = cloneDeep(ranges);
        const range = newRanges[key];
        const newRange = new Range(range.min, range.max);
        newRanges[key] = newRange;
        changeNotify(option, { [key]: newRange }, newRanges);
      }}
      selectedValues={selectedValues}
      filters={filters}
      name={name}
    />
  );
}

MultiCountRangeFilterContainer.propTypes = {
  name: string.isRequired,
  selected: shape({ key: string, name: string }).isRequired,
  defaultDisabled: bool,
  items: arrayOf(shape({ key: string, value: string })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: object,
  onChange: func.isRequired,
  setSelected: func,
  showDisable: bool,
  showRemove: bool,
  showCondition: bool,
  presets: shape({ values: arrayOf(string) }),
  filters: oneOfType([object]),
};

MultiCountRangeFilterContainer.defaultProps = {
  setSelected: () => {},
  defaultDisabled: false,
  errors: null,
  showDisable: false,
  showRemove: false,
  showCondition: false,
  presets: {},
  filters: [],
};

function FilterTypeEight(props) {
  const { name, items, errors, mode, onChange, maxItemSelect } = props;
  const [selected, setSelected] = useState([]);
  const presets = useMemo(() => {
    return {
      ids: selected.reduce((previos, current) => {
        return [...previos, current.key];
      }, []),
      values: Object.values(selected),
    };
  }, [mode]);

  switch (mode) {
    case 0:
      return (
        <FilterTypeOne
          name={name}
          items={items}
          presets={presets}
          selected={selected}
          setSelected={setSelected}
          onChange={onChange}
          maxItemSelect={maxItemSelect}
        />
      );
    case 1:
      return (
        <MultiCountRangeFilterContainer
          name={name}
          defaultDisabled={false}
          items={items}
          errors={errors}
          selected={selected}
          setSelected={setSelected}
          onChange={onChange}
          showRemove
        />
      );
    default:
      return null;
  }
}

FilterTypeEight.propTypes = {
  name: string.isRequired,
  items: arrayOf(shape({ key: string, value: string })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: object.isRequired,
  mode: number.isRequired,
  onChange: func.isRequired,
  maxItemSelect: number.isRequired,
};

function FilterTypeNine(props) {
  const { name, items, onChange, errors, presets, filters } = props;
  const channels = useMemo(() => items.map((item) => ({ key: item.key })), [
    items,
  ]);
  return (
    <MultiCountRangeFilterContainer
      name={name}
      defaultDisabled
      selected={channels}
      items={items}
      errors={errors}
      onChange={onChange}
      showDisable
      showCondition
      presets={presets}
      filters={filters}
    />
  );
}

FilterTypeNine.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  items: arrayOf(shape({ key: string, value: string })),
  errors: shape({}).isRequired,
  presets: shape({ values: arrayOf(string) }).isRequired,
  filters: oneOfType([object]).isRequired,
};
FilterTypeNine.defaultProps = {
  items: [],
};

function FilterType(props) {
  const {
    pageId,
    name,
    type,
    mode,
    items,
    onUpdate,
    presets,
    error,
    clearError,
    setError,
    defaultProps,
    maxItemSelect,
    filters,
    filterInfo,
    status,
  } = props;

  const onChange = useCallback(
    (values, option = null, ids = null) => {
      const filterData = {
        type,
        values,
        ids,
        option,
      };
      onUpdate(filterData);
    },
    [onUpdate, type]
  );

  switch (type) {
    case 1:
      return (
        <FilterTypeOneContainer
          name={name}
          items={items}
          onChange={onChange}
          presets={presets}
          error={error}
          setError={setError}
          clearError={clearError}
          maxItemSelect={maxItemSelect}
          isShowAdvertiserId={pageId !== pages.AD_MANAGEMENT}
          status={status}
          note={filterInfo.note}
        />
      );
    case 2: {
      let filterTypes = FilterTypeTwoOptions;
      if (
        [pages.MEASUREMENT_SITE_PAGE_MANAGEMENT].includes(pageId) &&
        [FILTER_KEY_PAGE_TITLE].includes(name)
      ) {
        filterTypes = [...filterTypes, FILTER_KEY_UNREGISTER];
      }
      const options = filterTypes.filter(
        (option) => !(option.ignore?.[pageId] || []).includes(name)
      );
      const { multiple = false, note = '', maxItem = 1 } = filterInfo;

      if (multiple) {
        return (
          <FilterTypeTwoMultiple
            name={name}
            presets={presets}
            options={options}
            note={note}
            error={error}
            maxItem={maxItem}
            onChange={onChange}
          />
        );
      }

      return (
        <FilterTypeTwo
          name={name}
          note={note}
          options={options}
          onChange={onChange}
          presets={presets}
          error={error}
          clearError={clearError}
        />
      );
    }
    case 3:
      // Apply a new filter only for the display channel in the Conversion Attributes screen.
      if (
        pageId === pages.CV_ATTRIBUTE &&
        [FILTER_KEY_CHANNEL_DISPLAY, FILTER_KEY_CHANNEL_DISPLAY_RANGE].includes(
          name
        )
      ) {
        return (
          <FilterTypeNine
            name={name}
            items={items}
            onChange={onChange}
            presets={presets}
            errors={error}
            filters={filters}
          />
        );
      }
      return (
        <FilterTypeThree items={items} onChange={onChange} presets={presets} />
      );

    case 4:
      return (
        <FilterTypeFour
          name={name}
          items={items}
          onChange={onChange}
          presets={presets}
        />
      );
    case 5:
      return (
        <FilterTypeFive
          name={name}
          onChange={onChange}
          presets={presets}
          error={error}
          clearError={clearError}
        />
      );
    case 6:
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <PeriodFilter {...defaultProps} onChange={onChange} presets={presets} />
      );
    case 7:
      return (
        <FilterTypeSeven name={name} onChange={onChange} presets={presets} />
      );
    case 8:
      return (
        <FilterTypeEight
          name={name}
          items={items}
          mode={mode}
          onChange={onChange}
          errors={error}
          maxItemSelect={maxItemSelect}
        />
      );
    case 10:
      return (
        <FilterTypeTen
          name={name}
          value={presets.values}
          unit={filterInfo.unit}
          note={filterInfo.note}
          error={error}
          onChange={onChange}
        />
      );
    case 11:
      return (
        <FilterTypeEleven
          name={name}
          value={presets.values}
          note={filterInfo.note}
          error={error}
          onChange={onChange}
        />
      );
    default:
      return null;
  }
}

FilterType.propTypes = {
  pageId: string,
  name: string.isRequired,
  type: oneOf([0, 1, 2, 3, 4, 5, 6]).isRequired,
  mode: oneOf([0, 1, 2, 3, 4, 5, 6]).isRequired,
  items: arrayOf(shape({ key: string, value: string })),
  onUpdate: func,
  presets: shape({ values: arrayOf(string) }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: object,
  clearError: func,
  setError: func,
  defaultProps: oneOfType([object]),
  maxItemSelect: number,
  filters: oneOfType([object]).isRequired,
  filterInfo: oneOfType([object]).isRequired,
  status: string,
};

FilterType.defaultProps = {
  pageId: '',
  items: [],
  onUpdate: () => {},
  error: null,
  clearError: () => {},
  setError: () => {},
  defaultProps: {},
  maxItemSelect: MASTER_DATA_FILTER_LIMIT,
  status: '',
};

// TODO shift select
// TODO disable / enable OK button
export default FilterType;
