import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { func, bool, shape } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { PAGE_ID } from 'domain/fields';
import {
  STEP_PAGE,
  STEPS_FORM_PV,
  STEP_DETAIL_PV,
  FIELD_FORM_PV,
  TAG_MANAGEMENT_TAB,
  IS_REMIND_SC_TAG_MANAGEMENT,
  IS_REMIND_NOT_SC_TAG_MANAGEMENT,
} from 'domain/tag-management/consts';
import {
  getPvDataSettingInitial,
  getPvDataSettingRequest,
  getPvDataSettingError,
  validatePvDataSetting,
  checkDataSettingError,
} from 'services/tag-management/tagManagementServices';
import EbisStep from 'views/molecules/Step/EbisStep';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import PageSetting from 'views/pages/tag-management/components/PageSetting';
import ViewDetail from 'views/pages/tag-management/components/ViewDetail/ViewDetail';
import useCheckPermissionLog from 'services/custom-hooks/useCheckPermissionLog';
import RemindSiteContentModal from 'views/organism/RemindSiteContentModal';
import { TAG_MANAGEMENT } from 'services/routes/constants';
import { get } from 'lodash';

function PageViewSettingsModal(props) {
  const {
    isEditing,
    isLoading,
    detail,
    isShowRemindSC,
    isShowRemindNotSC,
    contentCategory,
    onSearchContentCategory,
    onCreateContentCategory,
    onSubmit,
    onHide,
  } = props;

  const fieldForm = FIELD_FORM_PV;

  const scrollbarWrapperRef = useRef(null);
  const [dataField, setDataField] = useState(fieldForm);
  const [currentId, setCurrentId] = useState(null);
  const [currentStep, setCurrentStep] = useState(STEP_PAGE);
  const [isCheckShowRemindModal, setIsCheckShowRemindModal] = useState(false);
  const steps = STEPS_FORM_PV.map((item) => ({
    ...item,
    active: item.step === currentStep,
  }));

  const { hasContractLog } = useCheckPermissionLog();

  const disabledBtnNextStep = useMemo(() => {
    const {
      page_id: { value: pageId },
      page_title: { value: pageTitle },
    } = dataField;

    return !pageId || !pageTitle;
  }, [dataField]);

  const handleChangeValue = useCallback((name, value) => {
    setDataField((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], error: null, value },
    }));
  }, []);

  const handleError = (errors) => {
    if (isEmpty(errors)) {
      setCurrentStep(STEP_DETAIL_PV);
      setIsCheckShowRemindModal(false);
    } else {
      const dataFieldError = getPvDataSettingError(dataField, errors);
      setDataField(dataFieldError);
    }
  };

  const handleHideRemindSC = () => {
    setIsCheckShowRemindModal(!isCheckShowRemindModal);
  };

  const handleChangeHideRemindSC = (value) => {
    if (dataField.owned_media_flag.value) {
      setDataField((prevData) => ({
        ...prevData,
        [IS_REMIND_SC_TAG_MANAGEMENT]: {
          ...prevData[IS_REMIND_SC_TAG_MANAGEMENT],
          error: null,
          value,
        },
      }));
    } else {
      setDataField((prevData) => ({
        ...prevData,
        [IS_REMIND_NOT_SC_TAG_MANAGEMENT]: {
          ...prevData[IS_REMIND_NOT_SC_TAG_MANAGEMENT],
          error: null,
          value,
        },
      }));
    }
  };

  const handleSubmit = () => {
    // Validate data
    const dataValidated = validatePvDataSetting(dataField);
    const { hasError } = checkDataSettingError(dataValidated);

    // Show error
    if (hasError) {
      setDataField(dataValidated);
      return;
    }

    // Submit data
    const dataRequest = getPvDataSettingRequest(dataField, hasContractLog);
    onSubmit(dataRequest, handleError, currentId);
  };

  const onConfirm = () => {
    if (
      (dataField.owned_media_flag.value && isShowRemindSC) ||
      (!dataField.owned_media_flag.value && isShowRemindNotSC)
    ) {
      const dataValidated = validatePvDataSetting(dataField);
      const { hasError } = checkDataSettingError(dataValidated);
      // Show error
      if (hasError) {
        setDataField(dataValidated);
        return;
      }
      setIsCheckShowRemindModal(true);
    } else {
      handleSubmit();
    }
  };

  const handleClose = (isReload = false) => () => onHide(isReload);

  useEffect(() => {
    if (!isEditing) return;
    setCurrentId(detail[PAGE_ID]);
    setDataField(getPvDataSettingInitial(fieldForm, detail));
  }, [isEditing, detail, fieldForm]);

  return (
    <Modal
      show
      centered
      size="w800"
      dialogClassName="modal-conversion-setting"
      contentClassName="modal-content--settings"
    >
      {isCheckShowRemindModal ? (
        <RemindSiteContentModal
          pageId={TAG_MANAGEMENT}
          isShow={isCheckShowRemindModal}
          isLoading={isLoading}
          ownedMediaFlag={dataField.owned_media_flag.value}
          onHide={handleHideRemindSC}
          onSubmit={handleSubmit}
          onChange={handleChangeHideRemindSC}
        />
      ) : (
        <>
          <Modal.Header className="justify-content-start">
            <Modal.Title className="mr-10">
              {isEditing ? 'タグを編集' : 'タグを追加'}
            </Modal.Title>
            <EbisStep steps={steps} />
          </Modal.Header>
          <Modal.Body ref={scrollbarWrapperRef}>
            <DataSyncLoader
              isLoading={isLoading}
              className={`page-loader--${
                hasContractLog ? 'pageview' : 'default'
              }`}
            >
              <ScrollbarWrapper
                ref={scrollbarWrapperRef}
                maxContent={533}
                alignScroll={10}
              >
                {currentStep === STEP_PAGE && (
                  <PageSetting
                    isEditing={isEditing}
                    currentTab={TAG_MANAGEMENT_TAB.PV}
                    info={dataField}
                    contentCategory={contentCategory}
                    hasContractLog={hasContractLog}
                    onSearchContentCategory={onSearchContentCategory}
                    onCreateContentCategory={onCreateContentCategory}
                    onChange={handleChangeValue}
                  />
                )}
                {currentStep === STEP_DETAIL_PV && (
                  <ViewDetail
                    infoDetail={detail}
                    currentTab={TAG_MANAGEMENT_TAB.PV}
                  />
                )}
              </ScrollbarWrapper>
            </DataSyncLoader>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="link"
              hidden={currentStep === STEP_DETAIL_PV}
              onClick={handleClose(false)}
            >
              キャンセル
            </Button>
            <Button
              size="sm"
              variant="secondary"
              className="m-0"
              disabled={isLoading || disabledBtnNextStep}
              hidden={currentStep === STEP_DETAIL_PV}
              onClick={onConfirm}
            >
              OK
            </Button>
            <Button
              size="sm"
              variant="link"
              hidden={currentStep !== STEP_DETAIL_PV}
              onClick={handleClose(true)}
            >
              閉じる
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

PageViewSettingsModal.propTypes = {
  onHide: func.isRequired,
  onSubmit: func.isRequired,
  onSearchContentCategory: func.isRequired,
  onCreateContentCategory: func.isRequired,
  isLoading: bool,
  isEditing: bool,
  isShowRemindSC: bool,
  isShowRemindNotSC: bool,
  detail: shape({}),
  contentCategory: shape({}).isRequired,
};

PageViewSettingsModal.defaultProps = {
  isLoading: false,
  isEditing: false,
  isShowRemindSC: true,
  isShowRemindNotSC: true,
  detail: {},
};

export default PageViewSettingsModal;
