import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { bool, shape, func, string } from 'prop-types';
import classNames from 'classnames';
import {
  PAGE_ID,
  PAGE_TITLE,
  PAGE_URL,
  OWNED_MEDIA_FLAG,
  CONTENT_CATEGORY_ID,
  CONTENT_CATEGORY,
  // CONTENT_CATEGORY_NAME,
} from 'domain/fields';
import { TRUE_FLAG, FALSE_FLAG } from 'domain/consts';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';
// import { contentCategoryNameRules } from 'services/validations/tagManagementRules';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import SearchableSelect from 'views/molecules/SearchableSelect';
import EbisRadio from 'views/atoms/radio/EbisRadio';
// import InputCollapse from 'views/molecules/Collapse/InputCollapse';

const PageSetting = (props) => {
  const {
    isEditing,
    currentTab,
    info,
    contentCategory,
    onChange,
    onSearchContentCategory,
    // onCreateContentCategory,
    hasContractLog,
    isCapiSingleContract,
  } = props;

  const [isOpenContentCategory, setOpenContentCategory] = useState(false);

  const classForm = classNames({
    'form-page-setting': true,
    'form-page-setting--min-height': isOpenContentCategory,
  });

  const handleSelectOwnedMedia = (value) => {
    const ownedMediaFlag = value === TRUE_FLAG;
    onChange(OWNED_MEDIA_FLAG, ownedMediaFlag);
  };

  const handleSelectContentCategory = (isSelect) => (id) => {
    let itemSelected = {};
    if (isSelect) {
      itemSelected = contentCategory.items.find((item) => item.id === +id);
    }

    onChange(CONTENT_CATEGORY_ID, itemSelected);
  };

  const handleSearchContentCategory = (searchValue) => {
    onSearchContentCategory(searchValue);
  };

  return (
    <Form className={classForm} autoComplete="off">
      <FormGroup
        required
        variant={PAGE_ID}
        label={info[PAGE_ID].label}
        note={info[PAGE_ID].note}
        isError={!!info[PAGE_ID].error}
        styleError="keep-position"
        tooltip={info[PAGE_ID].tooltip}
      >
        <FormInput
          disabled={isEditing}
          name={PAGE_ID}
          value={info[PAGE_ID].value}
          error={info[PAGE_ID].error}
          onChange={onChange}
        />
      </FormGroup>
      <FormGroup
        required
        variant={PAGE_TITLE}
        label={info[PAGE_TITLE].label}
        note={info[PAGE_TITLE].note}
        tooltip={info[PAGE_TITLE].tooltip}
      >
        <FormInput
          name={PAGE_TITLE}
          value={info[PAGE_TITLE].value}
          error={info[PAGE_TITLE].error}
          onChange={onChange}
        />
      </FormGroup>
      <FormGroup
        label={info[PAGE_URL].label}
        note={info[PAGE_URL].note}
        tooltip={isCapiSingleContract ? info[PAGE_URL].tooltipCapiSingle : info[PAGE_URL].tooltip}
      >
        <FormInput
          name={PAGE_URL}
          value={info[PAGE_URL].value}
          error={info[PAGE_URL].error}
          placeholder="https://"
          onChange={onChange}
        />
      </FormGroup>

      {currentTab === TAG_MANAGEMENT_TAB.PV && hasContractLog && (
        <>
          <FormGroup
            variant={OWNED_MEDIA_FLAG}
            label={info[OWNED_MEDIA_FLAG].label}
          >
            <EbisRadio
              name={OWNED_MEDIA_FLAG}
              label="対象外"
              value={FALSE_FLAG}
              checked={!info[OWNED_MEDIA_FLAG].value}
              onChange={handleSelectOwnedMedia}
            />
            <EbisRadio
              name={OWNED_MEDIA_FLAG}
              label="対象"
              value={TRUE_FLAG}
              checked={info[OWNED_MEDIA_FLAG].value}
              onChange={handleSelectOwnedMedia}
            />
          </FormGroup>
          <FormGroup
            variant={CONTENT_CATEGORY_ID}
            label={info[CONTENT_CATEGORY_ID].label}
            tooltip={info[CONTENT_CATEGORY_ID].tooltip}
          >
            <SearchableSelect
              isLoading={contentCategory.isLoading}
              title={info[CONTENT_CATEGORY_ID].label}
              items={contentCategory.items}
              selected={info[CONTENT_CATEGORY_ID].value}
              error={info[CONTENT_CATEGORY_ID].error}
              onToggle={setOpenContentCategory}
              onSearch={handleSearchContentCategory}
              onClear={handleSelectContentCategory(false)}
              onSelect={handleSelectContentCategory(true)}
              // footer={
              //   <InputCollapse
              //     show
              //     label={info[CONTENT_CATEGORY_ID].label}
              //     name={CONTENT_CATEGORY_ID}
              //     status={contentCategory.statusCreate}
              //     error={contentCategory.errorCreate}
              //     rules={contentCategoryNameRules[CONTENT_CATEGORY_NAME]}
              //     onSubmit={onCreateContentCategory}
              //     onFinish={() => {
              //       onChange(CONTENT_CATEGORY_ID, contentCategory.itemCreated);
              //       document.body.click(); // Close SearchableSelect
              //     }}
              //   />
              // }
            />
          </FormGroup>
          <div className="ebis-form-group--content_category_id__description">
            ※サイトコンテンツが「対象外」で登録されていても、
            <a href={TAG_MANAGEMENT_TAB.CONTENT_CATEGORY} target="_blank" rel="noopener noreferrer">
              [コンテンツカテゴリ]画面<i className="fas fa-external-link-alt color-gray-dark txt-standard" />
            </a>
            のURL指定条件の設定次第ではサイトコンテンツ「対象」として分析画面に反映されます。
            <br />
            {info[OWNED_MEDIA_FLAG].value === false? (
                <>
                  ※サイトコンテンツが「対象」で登録された場合、
                  <a href={TAG_MANAGEMENT_TAB.CONTENT_CATEGORY} target="_blank" rel="noopener noreferrer">
                    [コンテンツカテゴリ]画面<i className="fas fa-external-link-alt color-gray-dark txt-standard" />
                  </a>
                  の設定にかかわらず、本ページIDに設定されたコンテンツカテゴリが分析画面に反映されます。
                </>
              ) : (
                <>
                  ※サイトコンテンツが「対象」で登録された場合、
                  <a href={TAG_MANAGEMENT_TAB.CONTENT_CATEGORY} target="_blank" rel="noopener noreferrer">
                    [コンテンツカテゴリ]画面<i className="fas fa-external-link-alt color-gray-dark txt-standard" />
                  </a>
                  の設定にかかわらず、本ページIDの設定が分析画面に反映されます。
                </>
              )}
          </div>
        </>
      )}
    </Form>
  );
};

PageSetting.propTypes = {
  isEditing: bool,
  hasContractLog: bool,
  info: shape({}).isRequired,
  contentCategory: shape({}),
  currentTab: string.isRequired,
  onChange: func.isRequired,
  onSearchContentCategory: func,
  isCapiSingleContract: bool,
  // onCreateContentCategory: func,
};

PageSetting.defaultProps = {
  isEditing: false,
  hasContractLog: false,
  contentCategory: {},
  onSearchContentCategory: () => {},
  isCapiSingleContract: false,
  // onCreateContentCategory: () => {},
};

export default React.memo(PageSetting);
