import { createSelector } from 'reselect';
import displayItemsSelectors from 'store/display-items/selectors';
import settingsSelectors from 'store/settings/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import filterSelectors from 'store/filters/selectors';
import { createHeadersByDisplayItems } from 'views/organism/GridTable/GridTableService';
import { CV_METRICS } from 'domain/consts';
import { getAllAxis } from 'domain/settings/display-items-log-page-analysis';
import {
  getSortString,
  createFieldsFromConversions,
  convertArray2Object,
} from 'domain/utils';
import {
  CNT_CVR_CONTRIBUTE,
  CNT_CV_CONTRIBUTE,
  DISPLAY_GROUP_AGGREGATION_AXIS,
  PAGE_ID,
} from 'domain/settings/display-items';
import {
  FILTER_KEY_PAGE_ID,
  FILTER_KEY_PAGE_TITLE,
  FILTER_KEY_PAGE_URL,
  FILTER_KEY_CONTENT_CATEGORY,
  FILTER_KEY_DIRECTORY,
} from 'services/consts';
import { checkAggregationByDirectory } from 'services/log/pageAnalyzeServices';

const listFieldOverrideTitle = [CNT_CVR_CONTRIBUTE, CNT_CV_CONTRIBUTE];
const listTitleTableOverride = {
  [CNT_CV_CONTRIBUTE]: 'CV貢献度',
  [CNT_CVR_CONTRIBUTE]: 'CV貢献率',
};

const getStatus = (state) => state.cache.logPageAnalyze.status;

const getSettingSort = (state) => state.cache.logPageAnalyze.settings.sort;
const getSettingPaging = (state) => state.cache.logPageAnalyze.settings.paging;

const getDataSum = (state) => state.cache.logPageAnalyze.data.sum;
const getDataDetail = (state) => state.cache.logPageAnalyze.data.detail;
const getMetadataTotal = (state) =>
  state.cache.logPageAnalyze.data.metadata.count || 0;

const ConversionsByMetricsSelector = createSelector(
  [
    masterDataSelectors.conversionsForDisplaySelector,
    displayItemsSelectors.getMetricsSelector,
  ],
  (conversions, metrics) => {
    return createFieldsFromConversions(
      conversions,
      metrics.filter((metric) => CV_METRICS.includes(metric))
    );
  }
);

const dimensionsSelector = createSelector(
  [
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getItemsReport,
    displayItemsSelectors.getDisplayItemPriorityAxis,
  ],
  (dimensions, displayItems, priorityAxis) => {
    const axis = getAllAxis();

    const option = displayItems.reduce((acc, item) => {
      if (item.getGroup(priorityAxis) !== DISPLAY_GROUP_AGGREGATION_AXIS) {
        return acc;
      }
      return { ...acc, [item.key]: item };
    }, {});

    return displayItems
      .filter((item) => dimensions.includes(item.key))
      .map((item) => item.key)
      .filter((item, index, items) => {
        if (index === 0) return true;

        const { callback = () => {} } = axis[index - 1] || {};
        const actualOption = callback(items[index - 1], option) || option;
        const allowAxis = actualOption[item]?.allowAxis || [];
        return allowAxis.includes(axis[index]?.name);
      });
  }
);

const metricsSelector = createSelector(
  [
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (metrics, displayItems) => {
    return displayItems
      .filter((item) => metrics.includes(item.key))
      .map((item) => item.key);
  }
);

const sortSelector = createSelector(
  [
    dimensionsSelector,
    metricsSelector,
    ConversionsByMetricsSelector,
    getSettingSort,
  ],
  (dimensions, metrics, conversions, sort) => {
    const allowItems = [...dimensions, ...metrics];
    conversions
      .filter((conversion) => conversion.children.length > 0)
      .forEach((conversion) =>
        conversion.children.map((item) => allowItems.push(item.name))
      );

    if (sort && allowItems.includes(sort.field)) {
      return { sort: getSortString(sort) };
    }

    return {};
  }
);

const filtersSelector = createSelector(
  [filterSelectors.getForApi, displayItemsSelectors.getSettings],
  (filters, displayItem) => {
    const isAggregationByDirectory = checkAggregationByDirectory(displayItem);
    // Can't filter page when aggregate data by directory
    if (isAggregationByDirectory) {
      return filters.filter((item) => {
        return ![
          FILTER_KEY_PAGE_ID,
          FILTER_KEY_PAGE_TITLE,
          FILTER_KEY_PAGE_URL,
          FILTER_KEY_CONTENT_CATEGORY,
        ].includes(item.field);
      });
    }

    // Only allow filter directory when aggregate by directory
    return filters.filter(
      (item) => ![FILTER_KEY_DIRECTORY].includes(item.field)
    );
  }
);

const headerSelector = createSelector(
  [
    settingsSelectors.getTab,
    displayItemsSelectors.getItemsReport,
    dimensionsSelector,
    metricsSelector,
    masterDataSelectors.conversionsForDisplaySelector,
  ],
  (currentTab, displayItems, dimensions, metrics, conversions) => {
    // func for override title field
    const displayItemsForTable = displayItems.map((item) => {
      if (listFieldOverrideTitle.includes(item.key)) {
        return { ...item, title: listTitleTableOverride[item.key] };
      }
      return item;
    });

    const headers = createHeadersByDisplayItems({
      currentTab,
      displayItems: displayItemsForTable,
      dimensions,
      metrics,
      conversions,
    });

    return headers;
  }
);

const getStates = createSelector(
  [
    settingsSelectors.getLoadingByStatus([
      getStatus,
      displayItemsSelectors.getStatus,
      masterDataSelectors.getStatusConversions,
    ]),
    headerSelector,
    getDataSum,
    getDataDetail,
    getMetadataTotal,
    getSettingPaging,
    getSettingSort,
    masterDataSelectors.conversionsForDisplaySelector,
  ],
  (isLoading, headers, sum, detail, totalItems, paging, sort, conversions) => {
    return {
      isLoading,
      headers,
      rows: totalItems > 0 ? [sum, ...detail] : [],
      totalItems,
      settings: { paging, sort },
      conversions: convertArray2Object(conversions, PAGE_ID),
    };
  }
);

export default {
  getStates,
  getMetadataTotal,
  getSettingPaging,
  sortSelector,
  dimensionsSelector,
  metricsSelector,
  filtersSelector,
};
