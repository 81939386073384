const GET_LIST = 'external-services/GET_LIST';
const SET_DATA_LIST = 'external-services/SET_DATA_LIST';
const SET_ERROR_LIST = 'external-services/SET_ERROR_LIST';
const SET_STATUS_LIST = 'external-services/SET_STATUS_LIST';

const GET_DETAIL = 'external-services/GET_DETAIL';
const SET_DATA_DETAIL = 'external-services/SET_DATA_DETAIL';
const SET_ERROR_DETAIL = 'external-services/SET_ERROR_DETAIL';
const SET_STATUS_DETAIL = 'external-services/SET_STATUS_DETAIL';

const ACTIVE = 'external-services/ACTIVE';
const DEACTIVE = 'external-services/DEACTIVE';

export default {
  GET_LIST,
  SET_DATA_LIST,
  SET_ERROR_LIST,
  SET_STATUS_LIST,
  GET_DETAIL,
  SET_DATA_DETAIL,
  SET_STATUS_DETAIL,
  SET_ERROR_DETAIL,
  ACTIVE,
  DEACTIVE,
};
