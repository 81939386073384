import types from 'store/resize-table/types';
import helper from 'store/helper';

const updateColumnsWidth = (page, columnsWidth) =>
  helper.createAction(types.UPDATE_COLUMNS_WIDTH, { page, columnsWidth });
const updateColumnWidth = (page, column, width) =>
  helper.createAction(types.UPDATE_COLUMN_WIDTH, { page, column, width });

const updateTableWidth = (page, freezeWidth) =>
  helper.createAction(types.UPDATE_TABLE_WIDTH, {
    page,
    freezeWidth,
  });

const updateColumnWidthMulitiple = (page, config) =>
  helper.createAction(types.UPDATE_TABLE_WIDTH_MULITIPLE, { page, config });

export default {
  updateColumnsWidth,
  updateColumnWidth,
  updateTableWidth,
  updateColumnWidthMulitiple,
};
