/* eslint-disable camelcase */
import helper from 'store/helper';
import types from './types';

const initialState = {
  selected: null,
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null, // string | null,
  control: {
    currentUsers: 0,
  },
};

const requestStart = (state) => {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: 'failed',
    error,
  };
};

const remove = (state) => {
  return {
    ...state,
    status: 'succeeded',
  };
};

const LtvCsvOrdersReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.DELETE_SUCCEEDED]: remove,
});

export default LtvCsvOrdersReducer;
