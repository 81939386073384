import React, { useState, useCallback, useEffect, useRef } from 'react';
import { shape, func, string, bool, number, node } from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import UploadCsvModal from 'views/organism/UploadCsvModal';
import useHoverDropdown from 'services/custom-hooks/useHoverDropdown';
import {
  STATUS_ERROR,
  STATUS_FAIL,
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
} from 'store/helper';
import { UNLIMITED_VALUE, CSV_MAX_SIZE } from 'domain/consts';

import './UploadCsvPopupMenu.scss';

const UploadCsvPopupMenu = ({
  onSend,
  onShowHistory,
  onDownloadTemplate,
  status: { status, error },
  screenId,
  note,
  customSubjectText,
  tooltipMessage,
  isAble,
  config,
  handleOpenUploadModal,
  uploadModalOptionNode,
  disableDefaultTemplateBtn,
  messageValidate,
}) => {
  const [modal, toggleModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [, setShowMenu] = useState(false);

  const dropdownRel = useRef();

  useHoverDropdown(dropdownRel, setShowMenu);

  const handleOpenPopup = (isAbleValue) => () => {
    if (isAbleValue) {
      handleOpenUploadModal();
      setErrors([]);
      toggleModal(true);
    }
  };

  const handleModalHide = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  useEffect(() => {
    if ([STATUS_SUCCEEDED, STATUS_ERROR].includes(status)) {
      toggleModal(false);
    }
    // validate fail
    if (status === STATUS_FAIL) {
      setErrors(error);
    }
  }, [status, error]);

  const btnClass = classNames({
    'btn-icon--no-text': true,
    'rounded-circle': true,
    disabled: !isAble,
  });

  const variant = isAble ? 'enabled' : 'disabled';

  return (
    <>
      <OverlayTrigger
        placement="top"
        trigger={['hover']}
        overlay={
          <Tooltip variant={variant} className="tooltip-common">
            {tooltipMessage}
          </Tooltip>
        }
      >
        <Button
          variant="secondary"
          size="sm"
          className={btnClass}
          onClick={handleOpenPopup(isAble)}
        >
          <i className="far fa-arrow-from-bottom fs-18" />
        </Button>
      </OverlayTrigger>

      {modal && (
        <UploadCsvModal
          show
          loading={status === STATUS_LOADING}
          errors={errors}
          onHide={handleModalHide}
          onSend={onSend}
          screenId={screenId}
          config={config}
          note={note}
          onShowHistory={onShowHistory}
          onDownloadTemplate={onDownloadTemplate}
          customSubjectText={customSubjectText}
          uploadModalOptionNode={uploadModalOptionNode}
          disableDefaultTemplateBtn={disableDefaultTemplateBtn}
          messageValidate={messageValidate}
        />
      )}
    </>
  );
};

UploadCsvPopupMenu.propTypes = {
  onSend: func,
  onShowHistory: func,
  onDownloadTemplate: func,
  status: shape({}),
  screenId: string,
  note: string,
  customSubjectText: string,
  tooltipMessage: string,
  isAble: bool,
  config: shape({
    isMergeError: bool,
    maxLine: number,
    maxSize: number,
    field: shape({}),
    minSize: number,
  }),
  handleOpenUploadModal: func,
  uploadModalOptionNode: node,
  disableDefaultTemplateBtn: bool,
  messageValidate: shape({
    maxSize: string,
    minSize: string,
  }),
};

UploadCsvPopupMenu.defaultProps = {
  status: { status: STATUS_IDLE, error: null },
  onSend: () => {},
  onShowHistory: null,
  onDownloadTemplate: null,
  screenId: '',
  note: '',
  customSubjectText: null,
  tooltipMessage: 'CSV一括登録',
  isAble: true,
  config: {
    isMergeError: false,
    maxLine: UNLIMITED_VALUE,
    maxSize: CSV_MAX_SIZE,
    field: {},
    minSize: null,
  },
  handleOpenUploadModal: () => {},
  uploadModalOptionNode: <></>,
  disableDefaultTemplateBtn: false,
  messageValidate: {},
};

export default UploadCsvPopupMenu;
