import types from 'store/ad-management-adgroup2/types';
import helper from 'store/helper';

const initialState = {
  data: {
    detail: {
      data: {},
      errors: [],
    },
  },
  settings: {},
  apiStatus: {
    detail: {
      status: 'idle',
    },
  },
};

const setDataDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      data: action.payload.data,
      errors: [],
    },
  },
});

const setErrorDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      errors: action.payload.errors,
    },
  },
});

const setStatusDetail = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    detail: {
      ...state.apiStatus.detail,
      status: action.payload.status,
    },
  },
});

const AdManagementAdGroup2Reducer = helper.createReducer(initialState, {
  [types.SET_DATA_DETAIL]: setDataDetail,
  [types.SET_ERROR_DETAIL]: setErrorDetail,
  [types.SET_STATUS_DETAIL]: setStatusDetail,
});

export default AdManagementAdGroup2Reducer;
