import DashboardApi from 'services/api/DashboardApi';

const fetchConversions = async () => {
  const res = await DashboardApi.fetchMetadata();
  const { conversions } = res.data.data;
  return conversions.map((conversion) => ({
    id: conversion.conv_id,
    name: conversion.conv_name,
    page_id: conversion.page_id,
  }));
};

export default {
  fetchConversions,
};
