import axios from 'axios';
import { SettingsApi as Api } from 'services/api/Api';
import apiUtils from 'services/apiUtils';
import pages from 'services/routes/pages';
import { saveAs } from 'services/utils';
import { MEASUREMENT_SITE_PAGE_MANAGEMENT } from 'services/routes/constants';

const PageManagementFactoryApi = () => {
  const url = pages[MEASUREMENT_SITE_PAGE_MANAGEMENT].endpoint;
  const urlCsv = `${url}/csv`;

  const getUploadUrl = async (contentType) => {
    const query = { 'content-type': contentType };
    const urlUpload = apiUtils.buildUrlWithQueryStrings(
      `${url}/upload-url`,
      query
    );
    const response = await Api.get(urlUpload);
    const { upload_url: uploadUrl, file_name: filename } = response.data.data;
    return {
      uploadUrl,
      filename,
    };
  };

  const uploadFile = async (file, urlUploadFile, contentType) => {
    return axios.put(urlUploadFile, file, {
      headers: {
        'Content-Type': contentType,
        'X-Amz-Acl': 'bucket-owner-full-control',
      },
    });
  };

  const confirmCsvDelete = async (filename) => {
    const urlConfirm = `${url}/csv-confirm`;
    let response = await Api.requestAsync(
      urlConfirm,
      { file_name: filename, mode: 'delete' },
      'POST',
      {
        pollUrlFormat: `${urlConfirm}/{task}`,
        pollTokenKey: 'task',
      }
    );

    response = await Api.get(response.data.data.location);

    return response;
  };

  const submitCsv = async (filename, mode) => {
    const urlSubmitCsv = `${url}/csv-import`;

    const response = await Api.requestAsync(
      urlSubmitCsv,
      { file_name: filename, mode },
      'PUT',
      {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlSubmitCsv}/{task}`,
      }
    );

    return response;
  };

  return {
    urlCsv,
    getList: async (request) => Api.post(`${url}/list`, request),
    getDetail: async (id) => Api.get(`${url}/${encodeURIComponent(id)}`),
    update: async (id, data) =>
      Api.put(`${url}/${encodeURIComponent(id)}`, data),
    delete: async (ids) => {
      return Api.delete(
        apiUtils.buildUrlWithQueryStrings(`${url}/delete`, { ids })
      );
    },
    downloadCsv: async (sort, filters) => {
      const request = { sort, filters };
      const response = await Api.requestAsync(urlCsv, request, 'POST', {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlCsv}/{task}`,
      });
      const { location } = response.data.data;
      if (location) {
        saveAs(location);
      }
    },
    getUploadUrl,
    uploadFile,
    confirmCsvDelete,
    submitCsv,
    exportCsvDeletedPages: async () => {
      const urlCsvRecycleBin = `${url}/csv-recycle-bin`;
      const response = await Api.requestAsync(urlCsvRecycleBin, null, 'GET', {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlCsvRecycleBin}/{task}`,
      });
      const { location } = response.data.data;
      if (location) {
        saveAs(location);
      }
    },
  };
};

const PageManagementApi = PageManagementFactoryApi();
export default PageManagementApi;
