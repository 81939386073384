// async actions
const REQUEST_START = 'display-items/REQUEST_START';
const REQUEST_FAILED = 'display-items/REQUEST_FAILED';
const FETCH = 'display-items/FETCH';
const CREATE = 'display-items/CREATE';
// sync actions
const FETCH_SUCCEEDED = 'display-items/FETCH_SUCCEEDED';
const CREATE_SUCCEEDED = 'display-items/CREATE_SUCCEEDED';
const FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE =
  'display-items/FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE';
const FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE_SUCCEED =
  'display-items/FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE_SUCCEED';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  FETCH,
  CREATE,
  FETCH_SUCCEEDED,
  CREATE_SUCCEEDED,
  FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE,
  FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE_SUCCEED,
};
