import React from 'react';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames';
import DisabledTooltipWrapper from 'views/atoms/tooltip/DisabledTooltipWrapper';

import './tag-card.scss';

const TagCard = ({
  text,
  icon,
  variant,
  actived,
  disabled,
  onChange,
  messageTooltipDisabled,
}) => {
  const tagCardClass = classNames({
    'tag-card': true,
    [`tag-card--${variant}`]: !!variant,
    'tag-card--actived': actived,
    'tag-card--disabled': disabled,
  });

  return (
    <DisabledTooltipWrapper
      disabled={!!messageTooltipDisabled && disabled}
      message={messageTooltipDisabled}
      placement="bottom"
      style={{marginTop: -1}}
    >
      <div className='tag-card__container'>
        <div className={tagCardClass} onClick={onChange} aria-hidden>
          {icon && (
            <span className="tag-card__icon">
              <img src={icon} alt={text} />
            </span>
          )}
          <span className="tag-card__text">{text}</span>
        </div>
      </div>
    </DisabledTooltipWrapper>
  );
};

TagCard.propTypes = {
  text: string.isRequired,
  icon: string,
  messageTooltipDisabled: string,
  variant: string,
  actived: bool,
  disabled: bool,
  onChange: func,
};

TagCard.defaultProps = {
  icon: '',
  variant: '',
  messageTooltipDisabled: '',
  actived: false,
  disabled: false,
  onChange: () => {},
};

export default TagCard;
