import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import adManagementServiceApi from 'services/api/AdManagementApi';
import { communicationStatus } from 'services/utils';
import adManagementSettingsSelectors from 'store/ad-management-settings/selectors';
import types from 'store/ad-management-settings/types';
import adManagementSettingsActions from 'store/ad-management-settings/actions';
import sharedSagas from 'store/sharedSagas';
import loggerConstants from 'store/logger/constant';
import loggerActions from 'store/logger/actions';

const { LOADING, SUCCEEDED } = communicationStatus;

function* handleCreateDisplayItems(action) {
  yield put(adManagementSettingsActions.setStatusDisplayItems(LOADING));
  const { items } = action.payload;
  // Get current settings
  const settings = yield select(
    adManagementSettingsSelectors.getDisplayItemsSettings
  );
  // Merge between user settings and remaining settings
  const mergedSettings = {
    ...settings,
    ...items,
  };

  // Convert to valid request data
  const requestData = DisplayItemsService.getForApiRequest(mergedSettings);
  // Call API
  const response = yield call(
    adManagementServiceApi.createDisplayItems,
    requestData
  );

  const displayItems = response?.data?.data || {};

  yield put(
    adManagementSettingsActions.createDisplayItemsSucceeded(displayItems)
  );

  yield put(adManagementSettingsActions.setStatusDisplayItems(SUCCEEDED));
}

function* handleFetchDisplayItems() {
  yield put(adManagementSettingsActions.setStatusDisplayItems(LOADING));

  // Call API
  const response = yield call(adManagementServiceApi.fetchDisplayItems);

  const displayItems = response?.data?.data || {};

  yield put(
    adManagementSettingsActions.fetchDisplayItemsSucceeded(displayItems)
  );

  yield put(adManagementSettingsActions.setStatusDisplayItems(SUCCEEDED));
}

function* errorHandler(err) {
  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];

  yield put(loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

export default function* watch() {
  yield takeLatest(
    types.CREATE_DISPLAY_ITEMS,
    sharedSagas.safe(errorHandler, handleCreateDisplayItems)
  );
  yield takeLatest(
    types.FETCH_DISPLAY_ITEMS,
    sharedSagas.safe(errorHandler, handleFetchDisplayItems)
  );
}
