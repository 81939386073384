const getDataDetail = (state) =>
  state.cache.adManagementAdGroup1.data.detail.data;
const getErrorDetail = (state) =>
  state.cache.adManagementAdGroup1.data.detail.errors;

const getStatusDetail = (state) =>
  state.cache.adManagementAdGroup1.apiStatus.detail.status;

const adManagementAdGroup1Selectors = {
  getDataDetail,
  getErrorDetail,
  getStatusDetail,
};

export default adManagementAdGroup1Selectors;
