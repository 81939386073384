/* eslint-disable camelcase */
import types from 'store/ltv/settings/ad/types';
import helper, {
  STATUS_FAIL,
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
} from 'store/helper';

const initialState = {
  list: [],
  detail: {},
  settings: {
    pagination: {
      currentPage: 1,
      totalItems: 0,
    },
    sort: {
      field: '',
      direction: '',
    },
  },
  selected: null,
  status: STATUS_IDLE, // API request POST/ PUT/ DELETE status
  fetchStatus: STATUS_IDLE, // Fetch list status
  adStatus: STATUS_IDLE, // Fetch list status
  error: null, // string | null | array,
  /* {
    'ad_id_1': 'ad_name_1',
    'ad_id_2': 'ad_name_2',
  } */
  adByIds: {},
};

const requestStart = (state) => {
  return {
    ...state,
    status: STATUS_LOADING,
    error: null,
  };
};

const requestSucceeded = (state) => {
  return {
    ...state,
    status: STATUS_SUCCEEDED,
  };
};

const searchStart = (state) => {
  return {
    ...state,
    adStatus: STATUS_LOADING,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: STATUS_FAIL,
    error: error?.data?.errors || null,
  };
};

const fetchList = (state) => {
  return {
    ...state,
    fetchStatus: STATUS_LOADING,
  };
};

const fetchListStart = (state) => {
  return {
    ...state,
    fetchStatus: STATUS_LOADING,
    error: null,
  };
};

const fetchedList = (state, action) => {
  const { data } = action.payload;

  return {
    ...state,
    list: data.detail,
    fetchStatus: STATUS_SUCCEEDED,
  };
};

const searchSuccess = (state, action) => {
  const { data } = action.payload;

  const adByIds = {};
  data.map((item) => {
    const { ad_id } = item;
    adByIds[ad_id] = item;
    return item;
  });

  return {
    ...state,
    adByIds,
    adStatus: STATUS_SUCCEEDED,
  };
};

const fetch = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    data,
    status: STATUS_SUCCEEDED,
  };
};

const remove = (state) => {
  return {
    ...state,
    status: STATUS_SUCCEEDED,
  };
};

const changeSelectedItem = (state, action) => {
  const id = action.payload;

  return {
    ...state,
    selected: id,
  };
};

const create = (state) => {
  return {
    ...state,
    status: STATUS_SUCCEEDED,
  };
};

const update = (state) => {
  return {
    ...state,
    status: STATUS_SUCCEEDED,
  };
};

const updatePage = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    fetchStatus: STATUS_LOADING,
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        currentPage: page,
      },
    },
  };
};

const updateCurrentItemNumber = (state, action) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        totalItems: action.payload,
      },
    },
  };
};

const updateSort = (state, action) => {
  const { field, direction } = action.payload;
  return {
    ...state,
    fetchStatus: STATUS_LOADING,
    settings: {
      ...state.settings,
      sort: {
        field,
        direction,
      },
    },
  };
};

const submitFailed = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    status: STATUS_FAIL,
    error: data.errors,
  };
};

const setDetail = (state, action) => {
  return {
    ...state,
    detail: action.payload,
  };
};

const clearApiError = (state) => {
  return {
    ...state,
    status: STATUS_IDLE,
    error: null,
  };
};

const LtvAdReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_SUCCEEDED]: requestSucceeded,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_SUCCEEDED]: fetch,
  [types.FETCH_LIST_START]: fetchListStart,
  [types.FETCH_LIST]: fetchList,
  [types.FETCH_LIST_SUCCEEDED]: fetchedList,
  [types.CREATE_SUCCEEDED]: create,
  [types.UPDATE_SUCCEEDED]: update,
  [types.DELETE_SUCCEEDED]: remove,
  [types.CHANGE_SELECTED_ITEM]: changeSelectedItem,
  [types.UPDATE_CURRENT_ITEM_NUMBER]: updateCurrentItemNumber,
  [types.UPDATE_PAGE]: updatePage,
  [types.UPDATE_SORT]: updateSort,
  [types.SEARCH_SUCCEEDED]: searchSuccess,
  [types.SEARCH_START]: searchStart,
  [types.SUBMIT_FAILED]: submitFailed,
  [types.CLEAR_API_RESULT]: clearApiError,
  [types.SET_DETAIL]: setDetail,
});

export default LtvAdReducer;
