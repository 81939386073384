import React from 'react';
import { gzipSync, gunzipSync } from 'fflate';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import {
  HEADER_CONFIG,
  IMPORT_TYPE_AD_ID,
  IMPORT_TYPE_MEDIA,
  IMPORT_TYPE_MEDIA_AND_GROUP1,
  IMPORT_TYPE_MEDIA_AND_GROUP1_AND_GROUP2,
  STATUS_LABELS,
} from 'domain/impression-ad-cost/consts';
import { saveAs } from 'services/utils';

const formatTableHeader = (sort) => {
  return HEADER_CONFIG.map((item) => {
    const newObj = { ...item };
    if (newObj.sort === 'none' && newObj.name === sort.field) {
      newObj.sort = sort.direction;
    }
    return newObj;
  });
};

const formatImportTypeName = (importType, masterData) => {
  const items = {
    [IMPORT_TYPE_AD_ID]: '広告ID',
    [IMPORT_TYPE_MEDIA]: '媒体種別',
    [IMPORT_TYPE_MEDIA_AND_GROUP1]: `媒体種別×${masterData.ad_group1}`,
    [IMPORT_TYPE_MEDIA_AND_GROUP1_AND_GROUP2]: `媒体種別×${masterData.ad_group1}×${masterData.ad_group2}`,
  };
  return items[importType];
};

const formatCsvUploadModalImportingType = (masterData) => {
  const importingTypeRadio = [
    { value: `${IMPORT_TYPE_AD_ID}` },
    { value: `${IMPORT_TYPE_MEDIA}` },
    { value: `${IMPORT_TYPE_MEDIA_AND_GROUP1}` },
    { value: `${IMPORT_TYPE_MEDIA_AND_GROUP1_AND_GROUP2}` },
  ];
  return importingTypeRadio.map((row) => {
    return {
      ...row,
      label: formatImportTypeName(Number(row.value), masterData),
    };
  });
};

const formatDownloadBtn = (row, handleClick) => {
  const isDownloadable = row.downloadable === 'OK';
  let ele = (
    <Button
      disabled={!isDownloadable}
      variant="secondary"
      size="xs"
      className="btn-icon--with-text btn-no-focus download-btn-uploaded-csv"
      onClick={(e) => {
        e.stopPropagation();
        if (isDownloadable) {
          handleClick(row.token);
        }
      }}
    >
      <i className="far fa-arrow-to-bottom" />
      CSVファイル
    </Button>
  );
  if (!isDownloadable) {
    ele = (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="tooltip" variant="disabled">
            {row.downloadable === 'READY'
              ? '処理中のためダウンロードできません。'
              : 'ダウンロードする権限がありません。'}
          </Tooltip>
        }
      >
        <span>{ele}</span>
      </OverlayTrigger>
    );
  }
  return ele;
};

const formatStatus = (row, handleClick) => {
  const { status, errors } = row;
  const eleError = (
    <button
      className="error-modal-open-link"
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        handleClick(status, errors);
      }}
    >
      {STATUS_LABELS[status]}
    </button>
  );
  const items = {
    ...STATUS_LABELS,
    error: errors.length === 0 ? STATUS_LABELS[status] : eleError,
    system_error: eleError,
  };
  return items[status];
};

const formatTableData = (
  tableData,
  masterData,
  handleClickUploadedCsvDownloadBtn,
  handleClickErrorStatus
) => {
  // No sorting is performed here: Sorting is done on the API side. and display it as is.
  return tableData.map((row) => {
    return {
      execute_date: row.executed_at,
      import_type_name: formatImportTypeName(row.importing_type, masterData),
      download_btn_uploaded_csv: formatDownloadBtn(
        row,
        handleClickUploadedCsvDownloadBtn
      ),
      status: formatStatus(row, handleClickErrorStatus),
      execute_user: row.execute_user,
    };
  });
};

const getFileNameByPresignedUrl = (location) => {
  const url = new URL(location);
  // Get last element after `/` character
  return url.pathname.split('/').slice(-1).pop();
};

const compressCsvToGzip = async (csv) => {
  const arrayBuffer = await csv.arrayBuffer();
  const fileContent = new Uint8Array(arrayBuffer);
  const compressedFile = gzipSync(fileContent, {});
  return new File([compressedFile], 'compressed.csv.gz');
};

const decompressBlobToCsv = async (blob, filename) => {
  const arrayBuffer = await blob.arrayBuffer();
  const fileContent = new Uint8Array(arrayBuffer);
  const compressedFile = gunzipSync(fileContent);
  const gzipFile = new File([compressedFile], filename);
  const url = window.URL.createObjectURL(gzipFile);
  saveAs(url, filename);
};

export default {
  formatTableHeader,
  formatImportTypeName,
  formatCsvUploadModalImportingType,
  formatDownloadBtn,
  formatStatus,
  formatTableData,
  getFileNameByPresignedUrl,
  compressCsvToGzip,
  decompressBlobToCsv,
};
