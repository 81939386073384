import helper from 'store/helper';
import types from 'store/search-console-setting/types';
import { communicationStatus } from 'services/utils';
import { SORT_DIRECTION_DESC } from 'domain/settings/display-items';
import { UPDATE_DATE } from 'domain/fields';

const { SUCCEEDED, IDLE, FAILED } = communicationStatus;

const initialState = {
  data: {
    list: {
      data: [],
      setting: {},
      metadata: {},
    },
    setting: {},
    masterData: {
      users: [],
      errors: [],
    },
  },
  settings: {
    sort: {
      field: UPDATE_DATE,
      direction: SORT_DIRECTION_DESC,
    },
  },
  status: {
    list: IDLE,
    setting: IDLE,
    masterData: IDLE,
  },
};

const setData = (state, action) => {
  const {
    data: { data, metadata },
  } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        data,
        metadata,
      },
    },
  };
};

const updateSort = (state, action) => {
  const { field, direction } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      sort: {
        field,
        direction,
      },
    },
  };
};

const setDataSetting = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    setting: {
      ...state.data.setting,
      item: action.payload.data,
      errors: [],
    },
  },
});

const setMasterData = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    masterData: {
      ...state.data.masterData,
      ...action.payload.data,
      errors: [],
    },
  },
  status: { ...state.status, masterData: SUCCEEDED },
});

const setStatus = (state, action) => {
  const { status, key } = action.payload;
  return {
    ...state,
    status: { ...state.status, [key]: status },
  };
};

const setErrorSetting = (state) => {
  return {
    ...state,
    status: { ...state.status, setting: FAILED },
  };
};

const SCSettingReducer = helper.createReducer(initialState, {
  [types.SET_STATUS]: setStatus,
  [types.SET_DATA]: setData,
  [types.UPDATE_SORT]: updateSort,
  [types.SET_DATA_SETTING]: setDataSetting,
  [types.SET_MASTERDATA]: setMasterData,
  [types.SET_ERROR_SETTING]: setErrorSetting,
});

export default SCSettingReducer;
