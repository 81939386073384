import { createSelector } from 'reselect';
import Services from 'domain/ltv/settings/condition/Services';
import { getSortString } from 'domain/utils';

const getList = (state) => {
  const { list } = state.cache.ltvSettingsCondition;

  return Services.formatData(list);
};

const getListDetail = (state) => {
  const { list } = state.cache.ltvSettingsCondition;

  return list;
};

const getStatus = (state) => {
  const { status } = state.cache.ltvSettingsCondition;
  return status;
};

const getFetchStatus = (state) => {
  const { fetchStatus } = state.cache.ltvSettingsCondition;
  return fetchStatus;
};

const getSelectedItem = (state) => {
  const { selected } = state.cache.ltvSettingsCondition;
  return selected;
};

const isShowModal = (state) => {
  const { isShowSettingModal } = state.cache.ltvSettingsCondition.settings;
  return isShowSettingModal;
};

const getApiError = (state) => {
  return state.cache.ltvSettingsCondition.error;
};
const getPagination = (state) =>
  state.cache.ltvSettingsCondition.settings.pagination;

const getSort = (state) => state.cache.ltvSettingsCondition.settings.sort;

const getCurrentItems = (state) =>
  state.cache.ltvSettingsCondition.settings.pagination.totalItems;

const csvDownloadEnabledSelector = createSelector(
  [getStatus, getPagination],
  (status, pagination) => {
    return status !== 'loading' && pagination.totalItems > 0;
  }
);

const sortSelector = createSelector(
  (state) => state.cache.ltvSettingsCondition.settings.sort,
  (sort) => {
    return { sort: getSortString(sort) };
  }
);
export default {
  getList,
  getListDetail,
  getStatus,
  getFetchStatus,
  getSelectedItem,
  isShowModal,
  getApiError,
  getPagination,
  getSort,
  getCurrentItems,
  csvDownloadEnabledSelector,
  sortSelector,
};
