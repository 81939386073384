import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import pages from 'services/routes/pages';

import './ebis-alert.scss';

const EbisAlert = ({ content, isShow, bottom }) => (
  <Alert
    className="d-flex justify-center align-items-center text-white ebis-alert"
    show={isShow}
    style={{ bottom }}
  >
    {content.value}
    {content.pageTransitionTarget && (
      <Link
        to={pages[content.pageTransitionTarget].path}
        className="text-white text-decoration-underline ebis-alert-link"
      >
        {content.pageTransitionLinkTitle || '確認する'}
      </Link>
    )}
  </Alert>
);

EbisAlert.defaultProps = {
  content: { value: 'CSVファイルを作成しています...' },
  isShow: true,
  bottom: 0,
};

EbisAlert.propTypes = {
  content: PropTypes.shape({
    value: PropTypes.string,
    pageTransitionLinkTitle: PropTypes.string,
    pageTransitionTarget: PropTypes.string,
  }),
  isShow: PropTypes.bool,
  bottom: PropTypes.number,
};
export default React.memo(EbisAlert);
