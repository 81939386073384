import { CUSTOM_DISPLAY_FIELDS } from 'domain/consts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import displayService from 'services/display';
import masterDataSelectors from 'store/master-data/selectors';

/**
 *
 * @return {{
 * displayKey: (function(key:string): string),
 * displayError: (function(key:string, default:string): string),
 * displayPhrase: (function(key:string): string),
 * displayChannelName: (function(key:string): string),
 * displayField: (function(key:string, variant:string): string),
 * displayTooltip: (function(key:string): string),
 * displayTerminalType: (function(key:string): string),
 *   }}
 */
export default function useDisplay() {
  const { t, i18n } = useTranslation();

  const masterDataDisplay = useSelector(masterDataSelectors.getDisplay);

  return {
    displayError: displayService.getError(t, i18n),
    displayField: (field, variant) => {
      if (CUSTOM_DISPLAY_FIELDS.includes(field)) {
        return masterDataDisplay[field];
      }
      return displayService.getField(t, i18n)(field, variant);
    },
    displayPhrase: displayService.getPhrase(t),
    displayTooltip: displayService.getTooltip(t),
    displayChannelName: displayService.getChannelName(t),
    displayTerminalType: displayService.getTerminalTypeName(t),
    displayKey: displayService.getKey(t),
  };
}
