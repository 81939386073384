import moment from 'moment';
import FilterService from 'domain/FilterService';
import isEqual from 'lodash/isEqual';
import {
  FILTER_KEY_CHANNEL_DISPLAY_RANGE,
  FILTER_KEY_SEARCH_CONSOLE,
} from 'services/consts';
import { CUSTOMVIEW_ROUTE_NAME } from 'domain/log/route-analyze/consts';

const getMasterDataNameByPairKey = (id, ebisMaster, mediaSideItemsMaster) => {
  const idPair = id.split(':');
  if (idPair[0] !== 'n') {
    const ebisKey = idPair[0];
    return ebisKey in ebisMaster ? ebisMaster[ebisKey].name : null;
  }
  if (idPair[1] !== 'n') {
    const [, ...compositeKeys] = idPair;
    const mediaSyncKey = compositeKeys.join(':');
    return mediaSyncKey in mediaSideItemsMaster
      ? mediaSideItemsMaster[mediaSyncKey].name
      : null;
  }
  return null;
};

const newDisplayChanelFilterValues = (values) => {
  const data = {};
  Object.keys(values).forEach((key) => {
    const value = values[key];
    data[key] = {
      min: !value.min ? '' : Number.parseInt(value.min, 10),
      max: !value.max ? '' : Number.parseInt(value.max, 10),
    };
  });
  return data;
};

const filterNormalize = (filters, masterData = null) => {
  /**
   * {
   *    conv_id: {type: 1, values: [], ids: [], option: null, title: 'xxx', order: 1}
   * }
   */
  const filtersNormalize = {};
  filters.forEach((filter) => {
    const { field: key, value: valueOrigin, operator: option } = filter;
    // Using field(key) to get master info: type...
    const filterInfo = FilterService.getFilterByName(key);
    if (!filterInfo) {
      return;
    }
    let filterKey = key;

    // filter type 1, 3, 4: Convert id to text display
    let values = Array.isArray(valueOrigin) ? valueOrigin : [valueOrigin];
    let ids = [];
    if ([1, 3, 4].includes(filterInfo.type)) {
      // For the new display channel filter, ids does not exist.
      if (
        key !== FILTER_KEY_CHANNEL_DISPLAY_RANGE &&
        key !== FILTER_KEY_SEARCH_CONSOLE
      ) {
        // Consistent key ui is string
        ids = valueOrigin.map((idVal) => idVal.toString());
      }
      if (key === FILTER_KEY_SEARCH_CONSOLE) {
        // Consistent key ui is string
        ids = [valueOrigin].map((idVal) => idVal.toString());
      }

      // Filter list using id & name
      // need normalize to display text on ui
      // using id to find correct text
      const filterKeys = [
        'conv_id',
        'media_id',
        'ad_group1_id',
        'ad_group2_id',
        'agency_id',
        'media_account_id',
        'media_side_campaign_id',
        'media_side_group_id',
        'content_category_id',
      ];
      if (masterData && filterKeys.includes(filterKey)) {
        const mapper = {
          conv_id: (id) =>
            id in masterData.cv ? masterData.cv[id].name : null,
          media_id: (id) =>
            id in masterData.media ? masterData.media[id].name : null,
          ad_group1_id: (id) =>
            id in masterData.adGroup1 ? masterData.adGroup1[id].name : null,
          ad_group2_id: (id) =>
            id in masterData.adGroup2 ? masterData.adGroup2[id].name : null,
          media_side_campaign_id: (id) =>
            getMasterDataNameByPairKey(
              id,
              masterData.adGroup1,
              masterData.mediaSideCampaign
            ),
          media_side_group_id: (id) =>
            getMasterDataNameByPairKey(
              id,
              masterData.adGroup2,
              masterData.mediaSideGroup
            ),
          media_account_id: (id) =>
            getMasterDataNameByPairKey(
              id,
              masterData.media,
              masterData.mediaAccount
            ),
          agency_id: (id) =>
            id in masterData.agency ? masterData.agency[id].name : null,
          content_category_id: (id) =>
            id in masterData.contentCategory
              ? masterData.contentCategory[id].name
              : null,
        };

        ids = ids.filter(mapper[filterKey]);
        values = ids.map(mapper[filterKey]).filter((name) => {
          if (!name) {
            // TODO: logger warning
          }
          return name;
        });
      } else if (key === FILTER_KEY_CHANNEL_DISPLAY_RANGE) {
        ids = null;
        values = newDisplayChanelFilterValues(valueOrigin);
      } else {
        const master = FilterService.getMaster(filterKey);
        values = master
          .filter((item) => ids.includes(item.key))
          .map((item) => item.value);
      }
    }

    if (key === 'amount_min') {
      filterKey = 'amount';
      if (filtersNormalize[filterKey]) {
        values = [...values, ...filtersNormalize[filterKey].values];
      }
    }
    if (key === 'amount_max') {
      filterKey = 'amount';
      if (filtersNormalize[filterKey]) {
        values = [...filtersNormalize[filterKey].values, ...values];
      }
    }
    if (key === 'channel_access_type') {
      const master = FilterService.getMaster(filterKey);

      const foundValue = master.find((item) => isEqual(item.ids, valueOrigin));

      values = [foundValue.value];
    }
    if (filterKey === 'amount') {
      ids = null;
      values = values.map(String);
    }

    filtersNormalize[filterKey] = {
      ...filterInfo,
      values,
      ids,
      option,
    };
  });

  return filtersNormalize;
};

const prepareForApi = (data, funcId) => {
  const {
    displayItems,
    filters,
    name,
    period,
    comparePeriod = {},
    options = {},
    skipPrepareFilter = false,
  } = data;
  let filtersNomalized = [];
  if (skipPrepareFilter) {
    filtersNomalized = filters;
  } else {
    filtersNomalized = FilterService.prepareForApi(filters);
  }

  const returnPeriod = {};
  if (period.start) {
    returnPeriod.period_start_date = moment(period.start).format('YYYY-MM-DD');
    returnPeriod.period_end_date = moment(period.end).format('YYYY-MM-DD');
    returnPeriod.period_preset = period.preset;
  }
  const compare = {};
  if (comparePeriod.start) {
    compare.compare_period_start_date = moment(comparePeriod.start).format(
      'YYYY-MM-DD'
    );
    compare.compare_period_end_date = moment(comparePeriod.end).format(
      'YYYY-MM-DD'
    );
    compare.compare_period_preset = comparePeriod.preset;
  }
  return {
    ...options,
    display_items: { [funcId]: displayItems },
    filters: filtersNomalized,
    ...returnPeriod,
    ...compare,
    view_name: name,
  };
};

const excludeBlacklist = (data) => {
  return data.filter(
    (item) => ![CUSTOMVIEW_ROUTE_NAME].includes(item.view_name)
  );
};

export const ViewServiceFactory = () => ({
  filterNormalize,
  prepareForApi,
  excludeBlacklist,
});

const ViewService = ViewServiceFactory();
export default ViewService;
