import { put, call, select, takeLatest } from 'redux-saga/effects';
import { API_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from 'domain/consts';
import { LANDING_PAGE_ANALYZE } from 'services/routes/constants';
import types from 'store/landing-page-analyze/types';
import actions from 'store/landing-page-analyze/actions';
import LandingPageAnalyzeApi from 'services/api/LandingPageAnalyzeApi';
import landingPageAnalyzeSelectors from 'store/landing-page-analyze/selectors';
import settingsSelectors from 'store/settings/selectors';
import filterSelectors from 'store/filters/selectors';
import commonSelectors from 'store/common/selectors';
import masterDataActions from 'store/master-data/actions';
import masterDataSelectors from 'store/master-data/selectors';
import handleError from 'services/error/handleScopeError';
import sharedSagas from 'store/sharedSagas';
import DisplayItemsSelectors from 'store/display-items/selectors';

function* fetchReport() {
  try {
    const [page, conversions, period, pagination, sort] = [
      yield select(settingsSelectors.getPage),
      yield select(masterDataSelectors.conversionsSelector),
      yield select(commonSelectors.periodSelector),
      yield select(landingPageAnalyzeSelectors.getPagination),
      yield select(landingPageAnalyzeSelectors.sortSelector),
    ];

    if (page !== LANDING_PAGE_ANALYZE) return;
    yield put(actions.requestStart());

    if (!conversions || conversions.length <= 0) {
      yield put(masterDataActions.fetchConversions());
    }

    const { data } = yield call(LandingPageAnalyzeApi.fetchReport, {
      channel: yield select(settingsSelectors.getTab),
      start_date: period.start.format(API_DATE_FORMAT),
      end_date: period.end.format(API_DATE_FORMAT),
      dimensions: yield select(landingPageAnalyzeSelectors.dimensionsSelector),
      metrics: yield select(landingPageAnalyzeSelectors.metricsSelector),
      filters: yield select(filterSelectors.getForApi),
      sum: true,
      limit: TABLE_ROWS_PER_PAGE,
      offset: (pagination.currentPage - 1) * TABLE_ROWS_PER_PAGE,
      axis: yield select(DisplayItemsSelectors.getDisplayItemPriorityAxis),
      ...sort,
    });

    yield put(actions.fetchReportSucceeded(data));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

export default function* landingPageAnalyzeOperation() {
  yield takeLatest(types.FETCH_REPORT, sharedSagas.skipIfNotReady(fetchReport));
}
