import React, { useCallback, useContext, useMemo } from 'react';
import { oneOfType, object } from 'prop-types';
import { isArray } from 'lodash';
import classNames from 'classnames';
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
  SORT_DIRECTION_OTHER,
  SORT_DIRECTION_NONE,
} from 'domain/settings/display-items';
import GirdTableColumnCheckbox from 'views/organism/GridTable/components/GirdTableColumnCheckbox';
import GridTableResizeBar from 'views/organism/GridTable/GridTableResizeBar';
import GridTableSortIcon from 'views/organism/GridTable/GridTableSortIcon';
import { GridTableItemContext } from 'views/organism/GridTable/GridTableContext';
import { getTooltip } from 'views/organism/GridTable/GridTableService';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import TableTooltips from 'services/common/TableTooltips';

function GirdTableColumn(props) {
  const { data } = props;
  const {
    field,
    name,
    subNames,
    gridRow,
    gridColumn,
    gridArea,
    type = '',
    variant = '',
    sort = SORT_DIRECTION_OTHER, // asc,desc,none,other
    isFiltered = false,
    onSelect = () => {},
    onChange = () => {},
  } = data;

  const {
    onSortColumn,
    sortColumn,
    measuredColumnWidthRef,
    tooltips,
  } = useContext(GridTableItemContext);

  const columnName = isArray(name)
    ? name.map((item) => <div>{item}</div>)
    : name;

  const subNameData = isArray(subNames)
    ? subNames.map((item) => <div className='grid-table__column-subName'>{item}</div>)
    : subNames;

  const direction = useMemo(() => {
    if (sort === SORT_DIRECTION_NONE) return SORT_DIRECTION_NONE;
    return field === sortColumn.field
      ? sortColumn.direction
      : SORT_DIRECTION_OTHER;
  }, [field, sortColumn, sort]);

  const tooltip = useMemo(() => {
    return getTooltip(field, tooltips);
  }, [field, tooltips]);

  const columnClass = classNames({
    'grid-table__column': true,
    'grid-table__column--child': gridRow > 1,
    [`grid-table__column--${type}`]: !!type,
    [`grid-table__column--${variant}`]: !!variant,
  });

  const handleClickSort = useCallback(
    (column) => {
      if (direction === SORT_DIRECTION_NONE) return;

      onSortColumn({
        field: column,
        direction:
          direction === SORT_DIRECTION_DESC
            ? SORT_DIRECTION_ASC
            : SORT_DIRECTION_DESC,
      });
    },
    [onSortColumn, direction]
  );

  return (
    <div
      role="columnheader"
      className={columnClass}
      aria-label={field}
      aria-rowindex={gridRow}
      aria-colindex={gridColumn}
      style={{ gridArea }}
    >
      <div
        ref={measuredColumnWidthRef}
        className="grid-table__column-name"
        aria-sort={direction}
        onClick={() => handleClickSort(field)}
        aria-hidden="true"
      >
        {(() => {
          switch (type) {
            case 'checkbox':
              return (
                <GirdTableColumnCheckbox
                  data={data}
                  onChange={() => {
                    onSelect(!data.checked, field);
                    onChange(!data.checked, field);
                  }}
                />
              );

            default:
              return (
                <span>
                  {columnName}
                  {tooltip && (
                    <DragonBall
                      stayOpen={TableTooltips.stayOpenFields.includes(field)}
                    >
                      {tooltip}
                    </DragonBall>
                  )}
                  {isFiltered && <span className="grid-table__column-filter" />}
                  {direction !== SORT_DIRECTION_NONE && (
                    <GridTableSortIcon direction={direction} />
                  )}
                  {subNameData}
                </span>
              );
          }
        })()}
      </div>
      {data.isResizable && (
        <GridTableResizeBar field={field} isResizeGroup={data.isResizeGroup} />
      )}
    </div>
  );
}

GirdTableColumn.propTypes = {
  data: oneOfType([object]),
};

GirdTableColumn.defaultProps = {
  data: {},
};

export default React.memo(GirdTableColumn);
