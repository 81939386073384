/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes, { string } from 'prop-types';
import classNames from 'classnames';
import timeoutNotify from 'domain/timeout-notify';
import logging from 'domain/logging';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import './confirm-modal.scss';

const ConfirmModal = ({
  isShow,
  isLoading,
  title,
  modalWidth,
  content,
  jsxContent,
  onHide,
  onSubmit,
  cancelBtn,
  confirmBtnType,
  confirmText,
  cancelText,
  preventClickOutside,
  styles,
  forceText,
}) => {
  let jsxContentBody = jsxContent;
  if (timeoutNotify.count()) {
    timeoutNotify.reset();
    jsxContentBody =
      'タイムアウトが発生しました。お手数ですが再度お試しください。';
    logging.info('timeoutNotify', jsxContentBody);
  }

  const [isForceConfirm, setForceConfirm] = useState(!forceText);

  const modalClass = classNames({
    'confirm-modal': true,
    'confirm-modal--loading': isLoading,
  });

  return (
    <Modal
      backdrop={preventClickOutside ? 'static' : true}
      show={isShow}
      centered
      size={modalWidth}
      className={modalClass}
    >
      <Modal.Header className={styles?.modalHeader}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={isLoading}>
          {/* To use jsx content with redux, pass as string  */}
          {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
          {jsxContentBody}
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        {forceText && (
          <div className="force-confirm">
            <EbisCheckbox
              name="force-confirm"
              text={forceText}
              checked={isForceConfirm}
              disabled={isLoading}
              changeSelectBoxValue={() => setForceConfirm(!isForceConfirm)}
            />
          </div>
        )}
        {cancelBtn && (
          <Button
            size="sm"
            variant="link"
            disabled={isLoading}
            onClick={() => onHide(!isShow)}
          >
            {cancelText}
          </Button>
        )}
        <Button
          size="sm"
          variant={confirmBtnType}
          disabled={isLoading || !isForceConfirm}
          onClick={onSubmit}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  isShow: false,
  isLoading: false,
  modalWidth: 'w600',
  cancelBtn: true,
  preventClickOutside: true,
  confirmBtnType: 'secondary',
  confirmText: '削除',
  cancelText: 'キャンセル',
  jsxContent: false,
  onHide: () => {},
  content: '',
  styles: {
    modalHeader: '',
  },
  forceText: '',
};

ConfirmModal.propTypes = {
  isShow: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  modalWidth: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onHide: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  cancelBtn: PropTypes.bool,
  preventClickOutside: PropTypes.bool,
  confirmBtnType: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  jsxContent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  styles: PropTypes.shape({
    modalHeader: PropTypes.string,
  }),
  forceText: string,
};

export default ConfirmModal;
