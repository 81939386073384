// HANDLE COMMON ACTIONS
// ////////////////////////////////

const SET_DATA_LIST = 'ad-management-media/SET_DATA_LIST';
const SET_DATA_DETAIL = 'ad-management-media/SET_DATA_DETAIL';
const SET_ERROR_DETAIL = 'ad-management-media/SET_ERROR_DETAIL';
const SET_STATUS_DETAIL = 'ad-management-media/SET_STATUS_DETAIL';
const CLEAR_API_RESULT = 'ad-management-media/CLEAR_API_RESULT';

// HANDLE CREATE ACTION
// ////////////////////////////////

const CREATE = 'ad-management-media/CREATE';
const ADD = 'ad-management-media/ADD';

// HANDLE UPDATE ACTIONS
// ////////////////////////////////

const SET_STATUS_FETCH_BY_ID = 'ad-management-media/SET_STATUS_FETCH_BY_ID';
const GET_BY_ID = 'ad-management-media/GET_BY_ID';
const UPDATE = 'ad-management-media/UPDATE';

// HANDLE DELETE ACTIONS
// ////////////////////////////////

const SET_STATUS_FETCH_DELETE_LIST =
  'ad-management-media/SET_STATUS_FETCH_DELETE_LIST';
const FETCH_DELETE_LIST = 'ad-management-media/FETCH_DELETE_LIST';
const REMOVE = 'ad-management-media/REMOVE';

// LIST TABLE ACTIONS
// ////////////////////////////////

const UPDATE_PAGE = 'ad-management-media/UPDATE_PAGE';
const UPDATE_SORT = 'ad-management-media/UPDATE_SORT';
const CHANGE_SELECTED_ITEM = 'ad-management-media/CHANGE_SELECTED_ITEM';
const FETCH_LIST = 'ad-management-media/FETCH_LIST';
const FETCH_LIST_START = 'ad-management-media/FETCH_LIST_START';
const FETCH_LIST_SUCCEEDED = 'ad-management-media/FETCH_LIST_SUCCEEDED';

// LIST POSTBACK ACTIONS
// ////////////////////////////////

const FETCH_POSTBACK_HISTORY_LIST_START =
  'ad-management-media/FETCH_POSTBACK_HISTORY_LIST_START';
const FETCH_POSTBACK_HISTORY_LIST =
  'ad-management-media/FETCH_POSTBACK_HISTORY_LIST';
const FETCH_POSTBACK_HISTORY_LIST_SUCCEEDED =
  'ad-management-media/FETCH_POSTBACK_HISTORY_LIST_SUCCEEDED';

// HANDLE CSV ACTIONS
// ////////////////////////////////

const UPLOAD = 'ad-management-media/UPLOAD';
const SET_FILE_NAME_CONFIRM_UPLOAD =
  'ad-management-media/SET_FILE_NAME_CONFIRM_UPLOAD';
const SET_FETCH_UPLOAD_CONFIRM_LIST_STATUS =
  'ad-management-media/SET_FETCH_UPLOAD_CONFIRM_LIST_STATUS';
const UPLOAD_SHOW_CONFIRM_MODAL =
  'ad-management-media/UPLOAD_SHOW_CONFIRM_MODAL';
const UPLOAD_FETCH_CONFIRM_LIST =
  'ad-management-media/UPLOAD_FETCH_CONFIRM_LIST';
const UPLOAD_CONFIRM = 'ad-management-media/UPLOAD_CONFIRM';
const DOWNLOAD_CSV_TEMPLATE = 'ad-management-media/DOWNLOAD_CSV_TEMPLATE';
const DOWNLOAD_CSV_POSTBACK = 'ad-management-media/DOWNLOAD_CSV_POSTBACK';

const types = {
  SET_DATA_LIST,
  SET_DATA_DETAIL,
  SET_ERROR_DETAIL,
  SET_STATUS_DETAIL,
  CLEAR_API_RESULT,

  CREATE,
  ADD,

  SET_STATUS_FETCH_BY_ID,
  GET_BY_ID,
  UPDATE,

  SET_STATUS_FETCH_DELETE_LIST,
  FETCH_DELETE_LIST,
  REMOVE,

  UPDATE_PAGE,
  UPDATE_SORT,
  CHANGE_SELECTED_ITEM,
  FETCH_LIST,
  FETCH_LIST_START,
  FETCH_LIST_SUCCEEDED,

  FETCH_POSTBACK_HISTORY_LIST_START,
  FETCH_POSTBACK_HISTORY_LIST,
  FETCH_POSTBACK_HISTORY_LIST_SUCCEEDED,
  DOWNLOAD_CSV_POSTBACK,

  UPLOAD,
  SET_FILE_NAME_CONFIRM_UPLOAD,
  SET_FETCH_UPLOAD_CONFIRM_LIST_STATUS,
  UPLOAD_SHOW_CONFIRM_MODAL,
  UPLOAD_FETCH_CONFIRM_LIST,
  UPLOAD_CONFIRM,
  DOWNLOAD_CSV_TEMPLATE,
};

export default types;
