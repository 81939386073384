/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { arrayOf, bool, func, number, string, shape } from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isNil from 'lodash/isNil';
import classNames from 'classnames';

import './file-upload.scss';
import { Button } from 'react-bootstrap';
import { FileValidationRule } from 'services/consts';

const FILE_TYPES = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  swf: 'application/x-shockwave-flash',
  csv: 'application/vnd.ms-excel',
};

function FileUpload(props) {
  const {
    placeholder,
    accept,
    onFileUploadRejected,
    onFileUploadAccepted,
    maxSize,
    multiple,
    isClear,
    onClear,
    isError,
    defaultFiles,
    note,
  } = props;

  const acceptTypes = useMemo(() => {
    const newAccept = [...accept];
    if (newAccept.includes('png')) {
      newAccept.push('jpeg');
    }

    return newAccept.map((item) => FILE_TYPES[item]);
  }, [accept]);

  const inputRef = useRef(null);

  const [currentFiles, setCurrentFiles] = useState(() =>
    defaultFiles && defaultFiles.length > 0 ? defaultFiles : []
  );

  useEffect(() => {
    if (!isClear) return;
    setCurrentFiles([]);
  }, [isClear]);

  const validateFile = useCallback(
    (file) => {
      const { size, type } = file;
      const errors = [];
      // Currently support 2 types of validation
      // 1. File size
      if (maxSize && maxSize > 0 && size > maxSize) {
        errors.push(FileValidationRule.MAX_SIZE);
      }

      // 2. Invalid file types
      if (
        acceptTypes &&
        acceptTypes.length > 0 &&
        !acceptTypes.includes(type)
      ) {
        errors.push(FileValidationRule.FILE_TYPES);
      }

      return errors;
    },
    [acceptTypes, maxSize]
  );

  /**
   * @param {File[]} files
   */
  const validateFiles = useCallback(
    (files) => {
      // Here we do a validation on multiple files upload
      // If multiple is false, then we reject the upload if more than one files
      if (!multiple && files.length > 1) {
        // Get the 2nd file and create an error
        return [
          {
            file: files[1],
            errors: [FileValidationRule.MULTIPLE],
          },
        ];
      }

      const createFileErrors = (file) => {
        const errors = validateFile(file);
        if (errors.length <= 0) {
          return null;
        }
        return {
          file,
          errors,
        };
      };
      return files.map(createFileErrors).filter((error) => !isNil(error));
    },
    [multiple, validateFile]
  );

  const onDragOverEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   * @param {File[]} files
   */
  const handleFileUpload = useCallback(
    (files) => {
      const validationErrors = validateFiles(files);

      if (validationErrors.length > 0) {
        // There are validation errors. Fire onFileUploadRejected
        onFileUploadRejected(validationErrors);
      } else {
        // No validation errors. This file upload is valid, so fire onFileUploadAccepted
        setCurrentFiles(files);
        onFileUploadAccepted(files);
      }
    },
    [onFileUploadAccepted, onFileUploadRejected, validateFiles]
  );

  const onClick = () => {
    inputRef.current.click();
  };

  const onFileSelect = useCallback(
    (e) => {
      /**
       * @type {FileList|*}
       */
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      handleFileUpload(Array.from(files));
    },
    [handleFileUpload]
  );

  const onClearFiles = useCallback(
    (path) => {
      setCurrentFiles(currentFiles.filter((file) => file.path !== path));
      onClear();
    },
    [currentFiles, onClear]
  );

  const onDrop = useCallback(
    (e) => {
      e.preventDefault();
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      handleFileUpload(Array.from(files));
    },
    [handleFileUpload]
  );

  const FileUploadPlaceHolder = useMemo(
    () => () => (
      <>
        <input
          type="file"
          id="file"
          ref={inputRef}
          className="d-none"
          onChange={onFileSelect}
          accept={accept.length <= 0 ? undefined : accept.join(',')}
        />
        <div
          onClick={onClick}
          className="file-upload__dropzone"
          onDrop={onDrop}
          onDragOver={onDragOverEnter}
          onDragEnter={onDragOverEnter}
        >
          <div className="file-upload__placeholder">{placeholder}</div>
        </div>
        <div className="file-upload__note">{note}</div>
      </>
    ),
    [accept, onDrop, onFileSelect, placeholder, note]
  );

  const UploadedContent = useMemo(
    () => () => (
      <div>
        {currentFiles.map((currentFile) => (
          <div
            className="file-upload__content"
            key={currentFile.name}
            onDrop={onDragOverEnter}
            onDragOver={onDragOverEnter}
            onDragEnter={onDragOverEnter}
          >
            <span className="file-upload__name">{currentFile.name}</span>
            <Button
              variant="link"
              className="file-upload__clear"
              onClick={() => onClearFiles(currentFile.path)}
            >
              クリア
            </Button>
          </div>
        ))}
      </div>
    ),
    [onClearFiles, currentFiles]
  );

  const displayContent = useMemo(() => {
    if (currentFiles.length <= 0) {
      return <FileUploadPlaceHolder />;
    }
    return <UploadedContent />;
  }, [currentFiles]);

  const containerClass = classNames({
    'file-upload__container': currentFiles.length <= 0,
    'file-upload__container--uploaded': currentFiles.length > 0,
    'file-upload__container--error': isError,
  });

  return <div className={containerClass}>{displayContent}</div>;
}

FileUpload.propTypes = {
  multiple: bool,
  isError: bool,
  isClear: bool,
  onFileUploadAccepted: func,
  placeholder: string,
  accept: arrayOf(string),
  onFileUploadRejected: func,
  /**
   * This is maximum allowed file size (in bytes)
   */
  maxSize: number,
  onClear: func,
  defaultFiles: arrayOf(shape({ name: string, path: string })),
  note: string,
};

FileUpload.defaultProps = {
  multiple: false,
  isError: false,
  isClear: false,
  onFileUploadAccepted: () => {},
  placeholder: 'ファイルを選択、またはドラッグしてください',
  accept: [],
  onFileUploadRejected: () => {},
  maxSize: undefined,
  onClear: () => {},
  defaultFiles: [],
  note: '',
};

export default FileUpload;
