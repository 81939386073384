import {
  DASHBOARD,
  CATEGORY_ANALYZE,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
  CV_ATTRIBUTE,
  CV_FLOW,
  COST_ALLOCATION,
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
  LTV_ANALYZE,
  LANDING_PAGE_ANALYZE,
  USER_PROFILE,
  LOG_PERIOD_ANALYZE,
} from 'services/routes/constants';

const downloadTypeConfigs = (screenId) => {
  switch (screenId) {
    case CATEGORY_ANALYZE:
      return {
        csv: {
          status: true,
          enabled: false,
        },
        png: {
          status: true,
          enabled: false,
        },
        jpeg: {
          status: true,
          enabled: false,
        },
        allcsv: {
          status: true,
          enabled: false,
        },
      };
    case DETAILS_ANALYSIS:
      return {
        csv: {
          status: false,
          enabled: false,
        },
        png: {
          status: false,
          enabled: false,
        },
        jpeg: {
          status: false,
          enabled: false,
        },
        allcsv: {
          status: true,
          enabled: false,
        },
      };
    case DASHBOARD:
    case COMPARE_PERIOD:
    case LOG_PERIOD_ANALYZE:
    case COST_ALLOCATION:
      return {
        csv: {
          status: true,
          enabled: false,
        },
        png: {
          status: true,
          enabled: false,
        },
        jpeg: {
          status: true,
          enabled: false,
        },
        allcsv: {
          status: false,
          enabled: false,
        },
      };
    case CV_ATTRIBUTE:
    case CV_FLOW:
    case LANDING_PAGE_ANALYZE:
    case LTV_SETTINGS_PRODUCT:
    case LTV_SETTINGS_OFFER:
    case LTV_SETTINGS_CONDITION:
    case LTV_SETTINGS_AD:
    case LTV_ANALYZE:
    case USER_PROFILE:
    default:
      return {
        csv: {
          status: false,
          enabled: false,
        },
        png: {
          status: false,
          enabled: false,
        },
        jpeg: {
          status: false,
          enabled: false,
        },
        allcsv: {
          status: false,
          enabled: false,
        },
      };
  }
};

export default downloadTypeConfigs;
