import React, { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { func, bool, string } from 'prop-types';

import {
  TAG_MANAGEMENT_CONTENT_CATEGORY,
  TAG_MANAGEMENT,
} from 'services/routes/constants';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import './RemindSiteContentModal.scss';

function RemindSiteContentModal(props) {
  const {
    pageId,
    isLoading,
    ownedMediaFlag,
    onHide,
    onSubmit,
    onChange,
  } = props;

  const scrollbarWrapperRef = useRef(null);
  const [isForceConfirm, setIsForceConfirm] = useState(true);

  const onChangeRemindPopup = (value) => {
    onChange(value);
    setIsForceConfirm(value);
  };

  const handleHideModal = () => {
    setIsForceConfirm(false);
    onHide();
  };

  return (
    <>
      <Modal.Header className="justify-content-start">
        <Modal.Title className="mr-10">以下、ご確認ください。</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={scrollbarWrapperRef}>
        <DataSyncLoader isLoading={isLoading}>
          <ScrollbarWrapper
            ref={scrollbarWrapperRef}
            maxContent={540}
            alignScroll={10}
          >
            {ownedMediaFlag ? (
              <>
                サイトコンテンツが「対象」で登録された場合、
                <a
                  href={
                    pageId === TAG_MANAGEMENT
                      ? TAG_MANAGEMENT_TAB.CONTENT_CATEGORY
                      : TAG_MANAGEMENT_CONTENT_CATEGORY
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link_redirect"
                >
                  [コンテンツカテゴリ]画面
                  <i className="fas fa-external-link-alt color-gray-dark txt-standard" />
                </a>
                の設定にかかわらず、本ページIDの設定が分析画面に反映されます。
              </>
            ) : (
              <>
                サイトコンテンツが「対象外」で登録されていても、
                <a
                  href={
                    pageId === TAG_MANAGEMENT
                      ? TAG_MANAGEMENT_TAB.CONTENT_CATEGORY
                      : TAG_MANAGEMENT_CONTENT_CATEGORY
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link_redirect"
                >
                  [コンテンツカテゴリ]画面
                  <i className="fas fa-external-link-alt color-gray-dark txt-standard" />
                </a>
                のURL指定条件の設定次第ではサイトコンテンツ「対象」として分析画面に反映されます。
              </>
            )}
          </ScrollbarWrapper>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        <div className="force-confirm">
          <EbisCheckbox
            name="force-confirm"
            text="次回以降表示しない"
            checked={!isForceConfirm}
            disabled={isLoading}
            changeSelectBoxValue={() => onChangeRemindPopup(!isForceConfirm)}
          />
        </div>
        <Button size="sm" variant="link" onClick={handleHideModal}>
          戻る
        </Button>
        <Button
          size="sm"
          variant="secondary"
          className="m-0"
          disabled={isLoading}
          onClick={onSubmit}
        >
          次へ
        </Button>
      </Modal.Footer>
    </>
  );
}

RemindSiteContentModal.propTypes = {
  onHide: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  pageId: string,
  isLoading: bool,
  ownedMediaFlag: bool,
};

RemindSiteContentModal.defaultProps = {
  isLoading: false,
  ownedMediaFlag: false,
  pageId: '',
};

export default RemindSiteContentModal;
