import { spawn, all, call } from 'redux-saga/effects';

import commonOperations from 'store/common/operations';
import userManagementSaga from 'store/user-management/operations';
import categorySaga from 'store/category-analyze/saga';
import dashboardOperations from 'store/dashboard/operations';
import cvFlowOperations from 'store/cv-flow/operations';
import cvAttributeOperations from 'store/cv-attribute/operations';
import viewOperations from 'store/customview/operations';
import detailAnalyzeOperations from 'store/detail-analyze/operations';
import costAllocationSaga from 'store/cost-allocation/saga';
import { reflectionTimeSaga } from 'store/reflectiontime';
import { alertsSaga } from 'store/alerts';
import { notificationsSaga } from 'store/notifications';
import periodAnalysisSaga from 'store/period-analyze/saga';
import displayItemsOperations from 'store/display-items/operations';
import displayItemsOperationsForView from 'store/display-items-for-view/operations';
import csvSettingOperations from 'store/csv-setting/operations';
import masterDataOperations from 'store/master-data/operations';
import redirectOperations from 'store/redirect/operations';
import appVersionOperations from 'store/version/operations';
import authOperations from 'store/auth/operations';
import { loggerOperations } from 'store/logger';
import adManagementOperations from 'store/ad-management/operations';
import adManagementSettingsOperations from 'store/ad-management-settings/operations';
import adManagementMediaOperations from 'store/ad-management-media/operations';
import adManagementAdGroup1Operations from 'store/ad-management-adgroup1/operations';
import adManagementAdGroup2Operations from 'store/ad-management-adgroup2/operations';
import ltvSettingsProductOperations from 'store/ltv/settings/product/operations';
import ltvSettingsConditionOperations from 'store/ltv/settings/condition/operations';
import ltvSettingsAdOperations from 'store/ltv/settings/ad/operations';
import ltvSettingsOfferOperations from 'store/ltv/settings/offer/operations';
import ltvSettingsOtherOperations from 'store/ltv/settings/other/operations';
import ltvSettingsCsvOrders from 'store/ltv/settings/csv-orders/operations';
import ltvSettingsOrderOperations from 'store/ltv/settings/order/operations';
import ltvAnalyzeOperations from 'store/ltv/analyze/operations';
import agencyManagementOperations from 'store/agency-management/operations';
import tagManagementOperations from 'store/tag-management/operations';
import landingPageAnalyzeOperations from 'store/landing-page-analyze/operations';
import externalServicesOperations from 'store/external-services/operations';
import userProfileOperations from 'store/user-profile/operations';
import mediaSyncManagementOperations from 'store/media-sync-management/operations';
import dataExportOperations from 'store/data-export/operations';
import usageStatusOperations from 'store/usage-status/operations';
import apiManagementOperations from 'store/api-management/operations';
import contactDemandOperations from 'store/contact/demand/operations';
import contactOperations from 'store/contact/operations';
import SystemSettingOperation from 'store/system-setting/operations';
import SystemSettingDisplayOperation from 'store/system-setting/display/operations';
import pageManagementOperations from 'store/page-management/operations';
import impressionAdCostOperations from 'store/impression-ad-cost/operations';
import lpoLinkOperations from 'store/lpo/link/operations';
import lpoPeriodSage from 'store/lpo/period/saga';
import LogPageAnalyzeOperations from 'store/log/page-analyze/operations';
import LogPeriodAnalyzeOperations from 'store/log/period-analyze/operations';
import LogRouteAnalyzeOperations from 'store/log/route-analyze/operations';
import CapiSettingOperations from 'store/capi-setting/operations';
import SCSettingOperations from 'store/search-console-setting/operations';
import LineAddFriendSettingOperations from 'store/line-add-friend-settings/operations';

const sagas = [
  commonOperations,
  userManagementSaga,
  categorySaga,
  dashboardOperations,
  cvFlowOperations,
  cvAttributeOperations,
  viewOperations,
  detailAnalyzeOperations,
  costAllocationSaga,
  reflectionTimeSaga,
  alertsSaga,
  notificationsSaga,
  periodAnalysisSaga,
  displayItemsOperations,
  displayItemsOperationsForView,
  csvSettingOperations,
  masterDataOperations,
  redirectOperations,
  appVersionOperations,
  authOperations,
  loggerOperations,
  adManagementOperations,
  adManagementSettingsOperations,
  adManagementMediaOperations,
  adManagementAdGroup1Operations,
  adManagementAdGroup2Operations,
  ltvSettingsProductOperations,
  ltvSettingsConditionOperations,
  ltvSettingsAdOperations,
  ltvSettingsOfferOperations,
  ltvSettingsOtherOperations,
  ltvSettingsCsvOrders,
  ltvSettingsOrderOperations,
  ltvAnalyzeOperations,
  agencyManagementOperations,
  tagManagementOperations,
  landingPageAnalyzeOperations,
  externalServicesOperations,
  userProfileOperations,
  mediaSyncManagementOperations,
  dataExportOperations,
  usageStatusOperations,
  apiManagementOperations,
  contactDemandOperations,
  contactOperations,
  SystemSettingOperation,
  SystemSettingDisplayOperation,
  pageManagementOperations,
  impressionAdCostOperations,
  lpoLinkOperations,
  lpoPeriodSage,
  LogPageAnalyzeOperations,
  LogPeriodAnalyzeOperations,
  LogRouteAnalyzeOperations,
  CapiSettingOperations,
  SCSettingOperations,
  LineAddFriendSettingOperations,
];

export default function* rootSaga() {
  // keeping all sagas running
  // if one crash, restart it
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
