import * as DisplayItems from './display-items';

const SettingDisplayItems = {
  groupList: {
    [DisplayItems.ITEM]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.OFFER]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.CATEGORY]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_GROUP1]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_GROUP2]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AGENCY]: DisplayItems.DISPLAY_GROUP_DETAIL,
  },
  displayFreezeList: [
    DisplayItems.ITEM,
    DisplayItems.OFFER,
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
    DisplayItems.AGENCY,
  ],
  orderList: {
    [DisplayItems.CHANNEL]: 1,
    [DisplayItems.ITEM]: 2,
    [DisplayItems.OFFER]: 3,
    [DisplayItems.CATEGORY]: 4,
    [DisplayItems.AD_GROUP1]: 5,
    [DisplayItems.AD_GROUP2]: 6,
    [DisplayItems.AGENCY]: 7,
    [DisplayItems.CNT_ACCESS]: 8,
    [DisplayItems.CNT_CV_DETAIL]: 9,
    [DisplayItems.CVR_DETAIL]: 10,
    [DisplayItems.CNT_NEW_CV_DETAIL]: 11,
    [DisplayItems.NEW_CVR_DETAIL]: 12,
    [DisplayItems.CNT_CV_TOTAL]: 13,
    [DisplayItems.CVR_TOTAL]: 14,
    [DisplayItems.CNT_NEW_CV_TOTAL]: 15,
    [DisplayItems.NEW_CVR_TOTAL]: 16,
    [DisplayItems.CPA]: 17,
    [DisplayItems.COST]: 18,
    [DisplayItems.NEW_CPA_ACTUAL_RESULTS]: 19,
    [DisplayItems.NEW_CPA_UPPER_LIMIT]: 20,
    [DisplayItems.NEW_CPA_DIFFERENCE]: 21,
    [DisplayItems.F2_CPA]: 22,
    [DisplayItems.F1_AVERAGE_AMOUNT]: 23,
    [DisplayItems.F2_AVERAGE_AMOUNT]: 24,
    [DisplayItems.F3_OVER_AVERAGE_AMOUNT]: 25,
    [DisplayItems.F2_CV_RATIO]: 26,
    [DisplayItems.F3_CV_RATIO]: 27,
    [DisplayItems.PAYBACK_PERIOD_TARGET]: 28,
    [DisplayItems.PAYBACK_PERIOD_PREDICTION]: 29,
    [DisplayItems.PAYBACK_PERIOD_DIFFERENCE]: 30,
    [DisplayItems.LTV_AMOUNT]: 31,
    [DisplayItems.LTV_AMOUNT_TOTAL]: 32,
    [DisplayItems.LTV_PROFIT]: 33,
    [DisplayItems.LTV_PROFIT_TOTAL]: 34,
    [DisplayItems.MARGINAL_PROFIT]: 35,
    [DisplayItems.ROI]: 36,
  },
  displayDefaultList: [
    DisplayItems.CATEGORY,
    DisplayItems.CNT_ACCESS,
    DisplayItems.CNT_CV_TOTAL,
    DisplayItems.CVR_TOTAL,
    DisplayItems.CPA,
    DisplayItems.CNT_NEW_CV_DETAIL,
    DisplayItems.NEW_CVR_DETAIL,
    DisplayItems.CNT_NEW_CV_TOTAL,
    DisplayItems.NEW_CVR_TOTAL,
    DisplayItems.COST,
    DisplayItems.NEW_CPA_ACTUAL_RESULTS,
    DisplayItems.NEW_CPA_UPPER_LIMIT,
    DisplayItems.NEW_CPA_DIFFERENCE,
    DisplayItems.F2_CV_RATIO,
    DisplayItems.PAYBACK_PERIOD_PREDICTION,
    DisplayItems.PAYBACK_PERIOD_DIFFERENCE,
    DisplayItems.LTV_AMOUNT,
    DisplayItems.LTV_AMOUNT_TOTAL,
    DisplayItems.LTV_PROFIT,
    DisplayItems.LTV_PROFIT_TOTAL,
    DisplayItems.MARGINAL_PROFIT,
    DisplayItems.ROI,
  ],
  sortDefaultList: {
    [DisplayItems.CNT_CV_TOTAL]: DisplayItems.SORT_DIRECTION_DESC,
  },
  requiredList: [DisplayItems.ITEM, DisplayItems.OFFER],
};

const SettingAdDisplayItems = {
  ...SettingDisplayItems,
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    DisplayItems.CNT_CV_DETAIL,
    DisplayItems.CVR_DETAIL,
    DisplayItems.F1_AVERAGE_AMOUNT,
  ],
  sortDefaultList: {
    ...SettingDisplayItems.sortDefaultList,
    [DisplayItems.CATEGORY]: DisplayItems.SORT_DIRECTION_ASC,
  },
};
const SettingAllDisplayItems = {
  ...SettingDisplayItems,
  groupList: {
    ...SettingDisplayItems.groupList,
    [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_DETAIL,
  },
  displayFreezeList: [
    ...SettingDisplayItems.displayFreezeList,
    DisplayItems.CHANNEL,
  ],
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    DisplayItems.CHANNEL,
  ],
  sortDefaultList: {
    ...SettingDisplayItems.sortDefaultList,
    [DisplayItems.CHANNEL]: DisplayItems.SORT_DIRECTION_ASC,
    [DisplayItems.CATEGORY]: DisplayItems.SORT_DIRECTION_ASC,
  },
  requiredList: [...SettingDisplayItems.requiredList, DisplayItems.CHANNEL],
};

export const getAd = () => {
  const displayItemsBase = DisplayItems.getAd();
  return DisplayItems.getItems({
    ...SettingAdDisplayItems,
    displayItemsBase,
  });
};

export const getAdDefault = () => {
  const displayItemsBase = getAd();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAll = () => {
  const displayItemsBase = DisplayItems.getAll();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return DisplayItems.getDefault(displayItemsBase);
};
