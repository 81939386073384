import React, {
  useCallback,
  useContext,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { func, objectOf, shape, bool } from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { loggerActions } from 'store/logger';
import dataExportTypes from 'store/data-export/types';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import useCheckPermissionSettingReport from 'services/custom-hooks/useCheckPermissionSettingReport';
import { SETTING_USER_EDIT } from 'domain/permissions/contractPermissions';
import { FormContext } from 'views/molecules/Form';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import DisabledTooltipWrapper from 'views/atoms/tooltip/DisabledTooltipWrapper';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import DisplayItemSetting from 'views/pages/data-export/components/DisplayItemSetting';
import FilterSetting from 'views/pages/data-export/components/FilterSetting';
import DisplayItemSettingForRegularReport from 'views/pages/data-export/components/DisplayItemSettingForRegularReport';
import FilterSettingForRegularReport from 'views/pages/data-export/components/FilterSettingForRegularReport';
import SortDataSetting from 'views/pages/data-export/components/SortDataSetting';
import OtherSetting from 'views/pages/data-export/components/OtherSetting';
import ShareUserSetting from 'views/pages/data-export/components/ShareUserSetting';
import EmailSetting from 'views/pages/data-export/components/EmailSetting';
import SettingReportContext from 'views/pages/data-export/SettingReportContext';
import {
  CONTACT_HISTORIES,
  REPORT_FIELDS,
  MIN_DIMENSION,
  MAX_DIMENSION,
  MIN_METRIC,
  MAX_METRIC,
  REPORT_TYPE_CHANNEL,
  REPORT_TYPE_ACCESS,
  REPORT_TYPES_FOR_SETTING,
  REPORT_CHANNELS,
  CHANNEL_TYPE_ALL,
} from 'domain/data-export/consts';
import {
  checkIsRegularReport,
  validateDataSetting,
  formatDataToRequest,
  prepareDisplayItemForUIRegularReport,
  checkIsDataExport,
  getReportTypes,
  getSortableList,
} from 'services/data-export/dataExportService';
import { MAX_CUSTOM_VIEW_COUNT } from 'domain/consts';

const { CREATE, UPDATE } = dataExportTypes;
const {
  REPORT_TYPE,
  REPORT_NAME,
  SHARED_USERS,
  QUICK_SEARCH,
  DIMENSIONS,
  METRICS,
  PERIOD_MODE,
  PERIOD_INTERVAL,
  PERIOD_RECEIVE_REPORT_DAY,
  WAIT_COST,
  EMAIL_TITLE_MODE,
  CONTACT_HISTORY,
  RESULT_ERROR_CODE,
  REPORT_CHANNEL,
  FILTERS,
  DISPLAY_ITEMS,
  SUMMARY_MODE,
  SORT,
} = REPORT_FIELDS;
const FILEDS_CONVERT_NUMBER = [
  PERIOD_MODE,
  PERIOD_INTERVAL,
  PERIOD_RECEIVE_REPORT_DAY,
  WAIT_COST,
  EMAIL_TITLE_MODE,
];

const VIEW_DEFAULT = { id: 0, name: 'ビューを反映' };

function NormalReportSetting(props) {
  const {
    onCancel,
    onSubmit,
    filterableList,
    onApplyView,
    isAllowRegisterRegularReport,
    isAllowRegisterDataExport,
    statusSystemSync,
  } = props;

  const dispatch = useDispatch();

  const { dispatch: dispatchForm } = useContext(FormContext);
  const {
    dispatchSetting,
    isActionUpdate,
    masterdata,
    settingReport: {
      serviceStore,
      reportType,
      channel,
      normalReport,
      actionSubmit,
      error = {},
    },
  } = useContext(SettingReportContext);

  const [viewApplied, setView] = useState(VIEW_DEFAULT);
  const [displayItemSetting, setDisplayItems] = useState({});
  // state use for show display sortable list by channel and report type
  const [sortList, setSortList] = useState({});
  const [selectedSort, setSelectedSort] = useState({});

  const reportTypeRef = useRef();
  const reportChannelRef = useRef();
  const viewRef = useRef(null);

  const {
    dimension,
    metric,
    users,
    views,
    permissions,
    allowDisplayRowZero,
  } = masterdata;

  const {
    [REPORT_NAME]: reportName,
    [SHARED_USERS]: sharedUsers,
    [QUICK_SEARCH]: quickSearch,
    [FILTERS]: filters,
    [SUMMARY_MODE]: summaryMode,
    [SORT]: sort,
    [CONTACT_HISTORY]: contactHistorys = [],
    [DISPLAY_ITEMS]: displayitems = {},
    dimensions = [],
    metrics = [],
  } = normalReport;

  const displayItem = {
    ...(masterdata.displayItem?.[reportType]?.[channel] || {}),
    settingsCustom: displayitems,
  };

  const dependencyChangeView = JSON.stringify([
    dimensions,
    metrics,
    quickSearch,
    displayitems,
    filters,
  ]);

  const { checkPermissions } = useCheckPermissions();
  const {
    isHavePermissionChannelAccess,
    isAllowedSettingDataExport,
    isAllowedSettingChannelAll,
  } = useCheckPermissionSettingReport();

  const isRegularReport = checkIsRegularReport(reportType);
  const isDataExport = checkIsDataExport(reportType);

  const isAllowCopy =
    isActionUpdate &&
    ((isRegularReport && isAllowRegisterRegularReport) ||
      (isDataExport && isAllowRegisterDataExport));

  const reportTypes = useMemo(() => {
    const { regularReports, dataExports } = getReportTypes({
      isActionUpdate,
      reportType,
      isAllowedSettingDataExport,
      isAllowRegisterDataExport,
      isAllowRegisterRegularReport,
      isHavePermissionChannelAccess,
      statusSystemSync,
    });

    if (isAllowedSettingDataExport) {
      return [
        { title: '有償版', items: dataExports },
        { title: '無償版', items: regularReports },
      ];
    }

    return [{ title: '', items: regularReports }];
  }, [
    isActionUpdate,
    isAllowRegisterDataExport,
    isAllowRegisterRegularReport,
    isAllowedSettingDataExport,
    isHavePermissionChannelAccess,
    reportType,
    statusSystemSync,
  ]);

  const handleChangeValue = useCallback(
    (name, value) => {
      // field need format to number
      const valueFormatted = FILEDS_CONVERT_NUMBER.includes(name)
        ? Number(value)
        : value;

      dispatchSetting({
        type: 'setDataNormalReport',
        payload: { [name]: valueFormatted },
      });
      dispatchSetting({ type: 'clearErrorByFields', payload: [name] });
    },
    [dispatchSetting]
  );

  const handleChangeReportType = (value) => {
    dispatch(
      loggerActions.logConfirmModal({
        title: '変更すると設定中の内容がリセットされます。 ',
        content:
          'レポートタイプを変更すると、現在設定中の内容がすべてリセットされます。<br/>変更してもよろしいしょうか？',
        cancelBtn: true,
        callback: () => {
          dispatchSetting({ type: 'clearError' });
          dispatchSetting({ type: 'setReportType', payload: value });
        },
      })
    );
  };

  const handleChangeReportChannel = (value) => {
    // will be update title and message after Q&A
    dispatch(
      loggerActions.logConfirmModal({
        title: '対象チャネルを変すると編集中の内容が書き換わります',
        content:
          '対象チャネルを変更すると編集中の集計軸/指標軸/フィルタの内容が書き換わります。 よろしいでしょうか？',
        cancelBtn: true,
        callback: () => {
          // Update display items by channel
          handleChangeValue(
            DISPLAY_ITEMS,
            prepareDisplayItemForUIRegularReport({
              type: reportType,
              items: masterdata.displayItem[reportType][value].item,
              settings: {
                ...masterdata.displayItem[reportType][value].settingDefault,
                ...displayItemSetting,
              },
            })
          );
          // Change channel value
          dispatchSetting({
            type: 'setReportChannel',
            payload: value,
          });
          // clear errors
          dispatchSetting({
            type: 'clearErrorByFields',
            payload: [REPORT_CHANNEL, SORT, FILTERS],
          });
        },
      })
    );
  };

  const handleChangeDisplayItems = (value) => {
    // dislay item date = true => that mean is user turn on mode compare period by date
    // => user can not select the sort belong to the dimensions list
    if (masterdata.displayItem?.[reportType]?.[channel]?.sortItem) {
      const currentDisplayItems = { ...value } || {};
      const currentSortableList =
        masterdata.displayItem?.[reportType]?.[channel]?.sortItem || {};

      const nextSortableList = getSortableList(
        currentDisplayItems,
        currentSortableList,
        reportType
      );
      if (!nextSortableList?.[selectedSort?.field]) {
        setSelectedSort({});
        handleChangeValue(REPORT_FIELDS.SORT, {});
      }
      setSortList(nextSortableList);
    }

    setDisplayItems(value);
    dispatchSetting({ type: 'clearErrorByFields', payload: [DISPLAY_ITEMS] });
  };

  const handleChangeSortValue = (_, sortValue) => {
    setSelectedSort(sortValue);
    handleChangeValue(REPORT_FIELDS.SORT, sortValue);
  };

  const handleSubmit = (action) => {
    if (!isHavePermissionChannelAccess && reportType === REPORT_TYPE_ACCESS) {
      dispatch(
        loggerActions.logConfirmModal({
          title: 'データ更新に失敗しました ',
          content: 'ご指定のレポートを作成する契約または機能権限がありません。',
          callback: () => {},
        })
      );
      return;
    }

    let dataRequest = { ...normalReport };

    // set display item
    if (isRegularReport) {
      dataRequest = {
        ...dataRequest,
        [DISPLAY_ITEMS]: {
          ...dataRequest[DISPLAY_ITEMS],
          ...displayItemSetting,
        },
      };
      dispatchSetting({
        type: 'setDataNormalReport',
        payload: { [DISPLAY_ITEMS]: displayItemSetting },
      });
    }

    const oldError =
      actionSubmit === action || error?.[RESULT_ERROR_CODE] ? error : {};

    // Validate data
    const newError = { ...oldError, ...validateDataSetting(dataRequest) };
    dispatchSetting({ type: 'setError', payload: newError });
    dispatchSetting({ type: 'setActionSubmit', payload: action });

    // scroll to field error
    if (!isEmpty(newError)) {
      dispatchForm({ type: 'setSubmit', payload: true });
      return;
    }

    // Submit data
    onSubmit(
      action,
      formatDataToRequest(dataRequest, filterableList, permissions)
    );
  };

  const handleApplyView = ({ view, data }) => {
    setView(VIEW_DEFAULT);

    dispatchSetting({ type: 'setDataNormalReport', payload: data });
    dispatchSetting({
      type: 'clearErrorByFields',
      payload: [
        DIMENSIONS,
        METRICS,
        QUICK_SEARCH,
        CONTACT_HISTORY,
        DISPLAY_ITEMS,
        FILTERS,
      ],
    });
    setView({ id: view.id, name: view.name });
  };

  useEffect(() => {
    if (viewApplied.id !== 0) {
      setView(VIEW_DEFAULT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencyChangeView]);

  useEffect(() => {
    setDisplayItems(() => displayitems);
  }, [displayitems]);

  useEffect(() => {
    // handle for case default sortable and when user change channel or report type
    if (masterdata.displayItem?.[reportType]?.[channel]?.sortItem) {
      const currentSortableList =
        masterdata.displayItem[reportType][channel].sortItem;
      const nextSortableList = getSortableList(
        displayitems,
        currentSortableList,
        reportType
      );
      if (
        sort &&
        sort.field &&
        masterdata.displayItem[reportType][channel].sortItem[sort.field] &&
        nextSortableList[sort.field]
      ) {
        setSelectedSort(sort);
      } else {
        setSelectedSort({});
      }
      setSortList(() => nextSortableList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, displayitems, masterdata.displayItem, reportType]);

  return (
    <>
      <div
        className="data-export-setting__item data-export-setting__item--report-type"
        ref={reportTypeRef}
      >
        <div className="data-export-setting__title">レポートタイプ</div>
        <ErrorTooltipWrapper
          isError={!!error[REPORT_TYPE]}
          errorMess={error[REPORT_TYPE]}
          ref={reportTypeRef}
        >
          <div className="data-export-setting__content-report--types">
            {reportTypes.map((type) => (
              <>
                {type.title && (
                  <div className="data-export-setting__content-title">
                    {type.title}
                  </div>
                )}
                <div className="data-export-setting__content-items">
                  {type.items.map((item) => (
                    <EbisRadio
                      name={REPORT_TYPE}
                      label={item.label}
                      value={item.value}
                      checked={item.value === reportType}
                      disabled={item.disabled}
                      tooltip={item.tooltip}
                      onChange={handleChangeReportType}
                    />
                  ))}
                </div>
              </>
            ))}
          </div>
        </ErrorTooltipWrapper>
      </div>
      <div className="data-export-setting__item data-export-setting__item--report-name">
        <div className="data-export-setting__title">レポート名</div>
        <div className="data-export-setting__content">
          <FormInput
            name={REPORT_NAME}
            value={reportName}
            placeholder={REPORT_TYPES_FOR_SETTING[reportType]?.label}
            error={error[REPORT_NAME]}
            onChange={handleChangeValue}
          />
        </div>
      </div>
      {isRegularReport && (
        <div className="data-export-setting__item" ref={reportChannelRef}>
          <div className="data-export-setting__title">対象チャネル</div>
          <ErrorTooltipWrapper
            isError={!!error[REPORT_CHANNEL]}
            errorMess={error[REPORT_CHANNEL]}
            ref={reportChannelRef}
          >
            <div className="data-export-setting__content-report--channel">
              {REPORT_CHANNELS.map((channelItem) => (
                <EbisRadio
                  key={channelItem.value}
                  name={REPORT_CHANNEL}
                  label={channelItem.label}
                  value={channelItem.value}
                  checked={channelItem.value === channel}
                  disabled={
                    channelItem.value === CHANNEL_TYPE_ALL &&
                    !isAllowedSettingChannelAll
                  }
                  onChange={handleChangeReportChannel}
                />
              ))}
            </div>
          </ErrorTooltipWrapper>
        </div>
      )}
      {reportType !== REPORT_TYPE_ACCESS && (
        <div className="data-export-setting__item data-export-setting__item--customview">
          <div className="data-export-setting__title">レポート項目</div>
          <div className="data-export-setting__content">
            <Dropdown>
              <Dropdown.Toggle size="sm" variant="select">
                {viewApplied.name}
              </Dropdown.Toggle>
              {reportType === REPORT_TYPE_CHANNEL && (
                <DragonBall>
                  集計軸は最大{MAX_DIMENSION}件、指標軸は最大{MAX_METRIC}
                  件がレポートに反映されます。
                </DragonBall>
              )}
              <Dropdown.Menu className="dropdown-menu-shadow">
                <div className="setting-views-dropdown__list">
                  <Dropdown.Header bsPrefix="setting-views-dropdown__summary">{`保存したビュー ${views.length}/${MAX_CUSTOM_VIEW_COUNT}件`}</Dropdown.Header>
                  {views.length === 0 && (
                    <Dropdown.Item
                      as="div"
                      disabled
                      bsPrefix="setting-views-dropdown__empty"
                    >
                      保存したビューはありません
                    </Dropdown.Item>
                  )}
                  <div
                    ref={viewRef}
                    className="setting-views-dropdown__list-detail"
                  >
                    <ScrollbarWrapper
                      ref={viewRef}
                      maxContent={160}
                      sizeScroll={5}
                      alignScroll={5}
                    >
                      {views.map((item) => {
                        const {
                          id,
                          view_name: name,
                          media_sync: mediaSync,
                        } = item;
                        const classTitle = classNames({
                          'setting-views-dropdown__item-title': true,
                          'setting-views-dropdown__item-title--apply':
                            viewApplied.id === id,
                        });

                        return (
                          <OverlayTrigger
                            trigger={mediaSync ? ['hover', 'focus'] : ''}
                            placement="bottom"
                            overlay={
                              <Tooltip variant="disabled" placement="bottom">
                                集計軸に[媒体項目で集計]が選択されているビューは選択できません
                              </Tooltip>
                            }
                          >
                            <Dropdown.Item
                              as="div"
                              key={id}
                              role="button"
                              bsPrefix="setting-views-dropdown__item"
                              eventKey={id}
                              disabled={mediaSync}
                            >
                              <div
                                aria-hidden="true"
                                className={classTitle}
                                onClick={() =>
                                  viewApplied.id !== id &&
                                  !mediaSync &&
                                  onApplyView(id, handleApplyView)
                                }
                              >
                                {name}
                              </div>
                            </Dropdown.Item>
                          </OverlayTrigger>
                        );
                      })}
                    </ScrollbarWrapper>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}

      {isRegularReport ? (
        <>
          <DisplayItemSettingForRegularReport
            reportType={reportType}
            items={displayItem.item}
            settingsDefault={displayItem.settingDefault}
            settingsCustom={displayItem.settingsCustom}
            summaryMode={summaryMode}
            error={error[DISPLAY_ITEMS]}
            onChange={handleChangeValue}
            onSetDisplayItems={handleChangeDisplayItems}
          />
          <SortDataSetting
            item={sortList}
            selectedItem={selectedSort}
            error={error[SORT]}
            onChange={handleChangeSortValue}
          />
          <FilterSettingForRegularReport
            reportType={reportType}
            channel={channel}
            permissions={permissions}
            selected={filters}
            onChange={handleChangeValue}
          />
        </>
      ) : (
        <>
          <DisplayItemSetting
            reportType={reportType}
            dimension={{
              item: dimension.item,
              groups: dimension.type[reportType]?.groups,
              selected: dimensions,
              minItem: MIN_DIMENSION,
              maxItem: MAX_DIMENSION,
            }}
            metric={{
              item: metric.item,
              groups: metric.type[reportType]?.groups,
              selected: metrics,
              minItem: MIN_METRIC,
              maxItem: MAX_METRIC,
            }}
            contactHistory={{
              items: CONTACT_HISTORIES,
              selected: contactHistorys,
            }}
            error={error}
            onChange={handleChangeValue}
          />
          {reportType !== REPORT_TYPE_ACCESS && (
            <FilterSetting
              reportType={reportType}
              selected={quickSearch}
              permissions={permissions}
              onChange={handleChangeValue}
            />
          )}
        </>
      )}
      <OtherSetting
        reportType={reportType}
        report={normalReport}
        error={error}
        onChange={handleChangeValue}
        allowDisplayRowZero={allowDisplayRowZero}
        serviceStore={serviceStore}
        dispatchSetting={dispatchSetting}
      />
      {checkPermissions('anyOf', [SETTING_USER_EDIT]) && (
        <ShareUserSetting
          users={users}
          selected={sharedUsers}
          error={error[SHARED_USERS]}
          onChange={handleChangeValue}
        />
      )}
      <EmailSetting
        report={normalReport}
        onChange={handleChangeValue}
        error={error}
      />
      <div className="data-export-setting__item mb-0">
        <Button
          variant="link"
          size="sm"
          className="btn-cancel mr-10"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        {isActionUpdate && (
          <DisabledTooltipWrapper
            disabled={!isAllowCopy}
            message="レポート数が登録上限に達しています。"
            placement="bottom"
          >
            <div className="d-inline-block mr-10">
              <Button
                variant="secondary"
                size="sm"
                className="btn-no-focus"
                disabled={!isAllowCopy}
                onClick={() => handleSubmit(CREATE)}
              >
                新しい設定として保存
              </Button>
            </div>
          </DisabledTooltipWrapper>
        )}
        <Button
          variant="secondary"
          size="sm"
          className="btn-no-focus"
          onClick={() => handleSubmit(isActionUpdate ? UPDATE : CREATE)}
        >
          {isActionUpdate ? '保存' : '登録'}
        </Button>
      </div>
    </>
  );
}

NormalReportSetting.propTypes = {
  filterableList: objectOf(shape({})),
  onApplyView: func,
  onCancel: func,
  onSubmit: func,
  isAllowRegisterRegularReport: bool.isRequired,
  isAllowRegisterDataExport: bool.isRequired,
  statusSystemSync: bool,
};

NormalReportSetting.defaultProps = {
  filterableList: {},
  onApplyView: () => {},
  onCancel: () => {},
  onSubmit: () => {},
  statusSystemSync: false,
};

export default NormalReportSetting;
