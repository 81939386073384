import helper from 'store/helper';
import { getKeyTable } from 'views/organism/GridTable/GridTableService';

const MIN_HEIGHT_TABLE = 40; // px

const initialState = {
  highlightRows: [],
  selectedRows: [],
  heightHeader: null,
  heightRow: {},
  heightHorizontalScroll: 0,
  isResizing: false,
  tableInfo: {
    freeze: { widthTable: null, widthScroller: 0, isScrolling: false },
    main: { widthTable: null, widthScroller: 0, isScrolling: false },
  },
};

const setHighlightRow = (state, action) => {
  return {
    ...state,
    highlightRows: action.payload,
  };
};

const setSelectedRow = (state, action) => {
  return {
    ...state,
    selectedRows: action.payload,
  };
};

const setHeightHeader = (state, action) => {
  const heightHeader = Math.max(
    state.heightHeader,
    action.payload,
    MIN_HEIGHT_TABLE
  );

  return {
    ...state,
    heightHeader: heightHeader > 0 ? heightHeader : null,
  };
};

const setHeightRow = (state, action) => {
  return {
    ...state,
    heightRow: { ...state.heightRow, ...action.payload },
  };
};

const setInfoTable = (state, action) => {
  const { isFreeze = false, info } = action.payload;
  const key = getKeyTable(isFreeze);

  return {
    ...state,
    tableInfo: {
      ...state.tableInfo,
      [key]: {
        ...state.tableInfo[key],
        ...info,
      },
    },
  };
};

const setResizing = (state, action) => {
  return {
    ...state,
    isResizing: action.payload,
  };
};

const setHeightHorizontalScroll = (state, action) => {
  return {
    ...state,
    heightHorizontalScroll: action.payload,
  };
};

const GridTableReducer = helper.createReducer(initialState, {
  setHighlightRow,
  setSelectedRow,
  setHeightHeader,
  setHeightRow,
  setInfoTable,
  setResizing,
  setHeightHorizontalScroll,
});

const initialItemState = {
  headerRef: null,
  bodyRef: null,
  measuredColumnWidthRef: null,
};

const setAttrs = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const setTemplateColumns = (state, action) => {
  return {
    ...state,
    gridTemplateColumns: { ...state.gridTemplateColumns, ...action.payload },
  };
};

const GridTableItemReducer = helper.createReducer(initialItemState, {
  setAttrs,
  setTemplateColumns,
});

export {
  GridTableReducer,
  initialState,
  GridTableItemReducer,
  initialItemState,
};
