import moment from 'moment';
import helper from 'store/helper';
import types from './types';

export const initialState = {
  // Current version
  version: process.env.REACT_APP_VERSION,
  // Lastest version
  versionLastest: process.env.REACT_APP_VERSION,
  // Lastest check version
  checkLastest: moment().format('YYYY-MM-DD HH:mm:ss'), // YYYY-MM-DD HH:mm:ss
  // if true, user are already reload, don't force reload any more
  // Store session storage
  reloaded: false,
  reloadedLastest: moment().format('YYYY-MM-DD HH:mm:ss'), // YYYY-MM-DD HH:mm:ss
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null, // string | null,
};

const requestStart = (state) => {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: 'failed',
    error,
  };
};

const checkVersionSucceeded = (state, action) => {
  const { version } = action.payload;
  let { reloaded } = state;
  if (version === process.env.REACT_APP_VERSION) {
    reloaded = false;
  }

  return {
    ...state,
    versionLastest: version || process.env.REACT_APP_VERSION,
    checkLastest: moment().format('YYYY-MM-DD HH:mm:ss'),
    reloaded,
    status: 'succeeded',
  };
};

const forceReload = (state) => {
  return {
    ...state,
    reloaded: true,
    reloadedLastest: moment().format('YYYY-MM-DD HH:mm:ss'),
  };
};

const AppVersionReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.CHECK_VERSION_SUCCEEDED]: checkVersionSucceeded,
  [types.FORCE_RELOAD]: forceReload,
});

export default AppVersionReducer;
