import {
  DEFAULT_SORT_CHART,
  DEFAULT_SORT_TABLE,
  DIMENSIONS_TO_KEY_VALUES,
  MEMO_DATE_FORMAT,
  PERIOD_TYPE,
  PERIOD_TYPE_TO_API_MAP,
} from 'domain/period-analyze/consts';
import { PERIOD } from 'domain/settings/display-items';
import * as domainUtils from 'domain/utils';
import { containsByAllKeys, equalsAllKeys, HttpStatus } from 'services/utils';
import {
  API_DATE_FORMAT,
  API_UNLIMITED_VALUE,
  BOOKMARK_FUNC_NAME,
} from 'domain/consts';
import * as FIELD from 'domain/fields';
import moment from 'moment';
import uniq from 'lodash/uniq';
import range from 'lodash/range';
import isNil from 'lodash/isNil';
import periodAnalysisApi from 'services/period-analyze/periodAnalysisApi';
import apiUtils from 'services/apiUtils';
import bookmarkService from 'services/common/bookmarkService';
import { convertApiReportResponse, dowConverter } from 'domain/responseUtils';
import { createSeriesName } from 'domain/utils';

import chartService from 'services/period-analyze/chartService';

const getDimensionKeyValue = (dimension) =>
  DIMENSIONS_TO_KEY_VALUES.find((d) => d.field === dimension);

const getDimensionKeyValueList = (dimensions) =>
  DIMENSIONS_TO_KEY_VALUES.filter((dimension) =>
    dimensions.includes(dimension.field)
  );

const createDimensionKeyObjectForTable = (obj) =>
  [FIELD.PERIOD]
    .map((dimension) =>
      DIMENSIONS_TO_KEY_VALUES.find((d) => d.field === dimension)
    )
    .reduce((acc, { key }) => {
      acc[key] = obj[key];
      return acc;
    }, {});

const createLegends = (selectedCategories, list, visibleList) => {
  return list
    .map((item) => {
      const matchingCategory = selectedCategories.find((selectedCategory) =>
        Object.keys(selectedCategory).every(
          (key) => selectedCategory[key] === item[key]
        )
      );
      if (isNil(matchingCategory)) {
        return null;
      }

      return {
        key: matchingCategory,
        display: createSeriesName(item, matchingCategory),
        visible: containsByAllKeys(visibleList, matchingCategory),
      };
    })
    .filter((category) => category !== null);
};

const createGetChartDataRequest = ({
  start,
  end,
  channel,
  dimensions,
  filters,
  periodType,
  usePeriodAxisOnly,
  metrics,
  axis,
}) => {
  const sortQuery = apiUtils.buildSort([DEFAULT_SORT_CHART]);

  let requestDimensions;
  if (usePeriodAxisOnly) {
    requestDimensions = [FIELD.PERIOD];
  } else {
    requestDimensions = uniq([
      // FIELD.PERIOD,
      ...dimensions.filter((d) => d !== FIELD.CHANNEL_ACCESS_TYPE),
    ]);
  }

  return {
    dimensions: requestDimensions,
    metrics,
    channel,
    filters,
    start_date: start.format(API_DATE_FORMAT),
    end_date: end.format(API_DATE_FORMAT),
    offset: 0,
    sort: sortQuery,
    limit: API_UNLIMITED_VALUE,
    summary_mode: PERIOD_TYPE_TO_API_MAP[periodType],
    sum: false,
    axis,
  };
};

const createGetTableDataRequest = ({
  sorts,
  start,
  end,
  channel,
  filters,
  metrics,
  periodType,
  axis,
}) => {
  let sortQuery;
  if (sorts.length <= 0) {
    sortQuery = apiUtils.buildSort(DEFAULT_SORT_TABLE);
  } else {
    sortQuery = apiUtils.buildSort(
      domainUtils.getValidSortFields(sorts, [PERIOD], metrics)
    );
  }
  return {
    dimensions: [FIELD.PERIOD],
    metrics,
    channel,
    filters,
    start_date: start.format(API_DATE_FORMAT),
    end_date: end.format(API_DATE_FORMAT),
    offset: 0,
    sort: sortQuery,
    summary_mode: PERIOD_TYPE_TO_API_MAP[periodType],
    sum: true,
    axis,
  };
};

const buildCategoriesList = (
  list,
  dimensions,
  usePeriodAxisOnly,
  mapperKeys = DIMENSIONS_TO_KEY_VALUES
) => {
  const categoriesList = [];
  const dimensionsWithAxis = [...dimensions, FIELD.PRIORITY_AXIS];
  const masterDataKeys = mapperKeys
    .filter((dkv) => dimensionsWithAxis.includes(dkv.field))
    .flatMap((dkv) => [dkv.key, dkv.value]);
  list.forEach((elem) => {
    const dimensionKey = usePeriodAxisOnly
      ? { [FIELD.PERIOD]: FIELD.PERIOD }
      : chartService.createDimensionKeyObjectForChart(
          elem,
          dimensionsWithAxis,
          mapperKeys
        );
    const matchingCategory = categoriesList.find((category) =>
      equalsAllKeys(category.dimensionKey, dimensionKey)
    );
    if (matchingCategory) {
      matchingCategory.period[elem.date] = elem;
    } else {
      const newCategory = {
        dimensionKey,
        period: {
          [elem.date]: elem,
        },
      };
      masterDataKeys.forEach((key) => {
        newCategory[key] = elem[key];
      });
      categoriesList.push(newCategory);
    }
  });
  return categoriesList;
};

const createSaveBookmarkRequest = ({ primary, secondary }) => {
  return {
    metric: primary,
    compare_metric: secondary,
    func_id: BOOKMARK_FUNC_NAME.COMPARED_PERIOD,
  };
};

const addConverters = (options) => {
  const { periodType } = options;
  const converters = [];
  if (periodType === PERIOD_TYPE.DOW) {
    converters.push({ field: FIELD.PERIOD, converter: dowConverter });
  }
  return converters;
};

const getChartData = async (options) => {
  const request = createGetChartDataRequest(options);
  const response = await periodAnalysisApi.getDataChart(request);
  const converters = addConverters(options);
  return convertApiReportResponse(response, {
    converters,
  });
};

const getTableData = async (options) => {
  const request = createGetTableDataRequest(options);
  const response = await periodAnalysisApi.getDataTable(request);
  const converters = addConverters(options);
  return convertApiReportResponse(response, {
    converters,
  });
};

const saveBookmark = (options) => {
  const request = createSaveBookmarkRequest(options);
  return periodAnalysisApi.createBookmark(request);
};

const deleteBookmark = () => {
  return periodAnalysisApi.deleteBookmark();
};

const createModalData = (id, memoList) => {
  const memo = memoList.find((m) => m.id === id);
  return {
    start: memo?.start,
    end: memo?.end,
    category: memo?.category,
    content: memo?.content,
    id: memo?.id,
  };
};

const createSaveMemoRequest = ({ category, start, end, content }) => {
  return {
    start_date: start.format(MEMO_DATE_FORMAT),
    end_date: end.format(MEMO_DATE_FORMAT),
    category,
    content,
  };
};

const createLoadMemoRequest = ({ start, end }) => {
  return {
    start_date: start.format(MEMO_DATE_FORMAT),
    end_date: end.format(MEMO_DATE_FORMAT),
  };
};

const mapApiResponseToMemo = (memo) => ({
  start: memo.start_date,
  end: memo.end_date,
  category: memo.category,
  content: memo.content,
  id: memo.memo_id,
});

const loadMemo = async ({ start, end }) => {
  const request = createLoadMemoRequest({ start, end });
  const res = await periodAnalysisApi.loadMemo(request);
  return res.data.data?.detail
    ?.map((memo) => mapApiResponseToMemo(memo))
    .sort((m1, m2) => {
      const diff =
        moment(m1.start, API_DATE_FORMAT).valueOf() -
        moment(m2.start, API_DATE_FORMAT).valueOf();
      if (diff !== 0) return diff;
      return m1.id - m2.id;
    });
};

const createMemo = async (memo) => {
  const { category, content, start, end } = memo;
  const request = createSaveMemoRequest({ category, start, end, content });
  try {
    return await periodAnalysisApi.createMemo(request);
  } catch (e) {
    return e.response;
  }
};

const updateMemo = async (memo) => {
  const { id, category, content, memoStart: start, memoEnd: end } = memo;
  const request = createSaveMemoRequest({ category, start, end, content });
  try {
    return await periodAnalysisApi.updateMemo(id, request);
  } catch (e) {
    return e.response;
  }
};

const deleteMemo = async (id) => {
  try {
    return await periodAnalysisApi.deleteMemo(id);
  } catch (e) {
    return e.response;
  }
};

const updateBookmark = async ({ primary, secondary, enabled }) => {
  if (enabled) {
    return bookmarkService.saveMetrics({
      metric: primary,
      compareMetric: secondary,
      funcId: BOOKMARK_FUNC_NAME.COMPARED_PERIOD,
    });
  }
  return bookmarkService.removeMetrics({
    metric: primary,
    compareMetric: secondary,
    funcId: BOOKMARK_FUNC_NAME.COMPARED_PERIOD,
  });
};

const getBookmark = async () => {
  const {
    metric,
    compare_metric: compareMetric,
    status,
    show_chart: showChart,
  } = await bookmarkService.get({
    funcId: BOOKMARK_FUNC_NAME.COMPARED_PERIOD,
  });
  return {
    primary: metric,
    secondary: compareMetric,
    enabled: status,
    showChart,
  };
};

const updateShowChart = async (show) => {
  if (show) {
    return bookmarkService.showChart({
      funcId: BOOKMARK_FUNC_NAME.COMPARED_PERIOD,
    });
  }
  return bookmarkService.hideChart({
    funcId: BOOKMARK_FUNC_NAME.COMPARED_PERIOD,
  });
};

const getAvailableIndex = (indices, memo) => {
  if (!indices[memo.start]) {
    return 1;
  }

  const maxIndex = Math.max(...indices[memo.start]) + 1;

  return range(1, maxIndex + 1).find(
    (idx) => !indices[memo.start].includes(idx)
  );
};

const buildMemoListWithDisplayIndex = (memoList) => {
  const indicesByDate = {};
  return memoList.map((memo) => {
    const displayIndex = getAvailableIndex(indicesByDate, memo);
    if (!indicesByDate[memo.start]) {
      indicesByDate[memo.start] = [displayIndex];
    } else {
      indicesByDate[memo.start].push(displayIndex);
    }
    const momentStart = moment(memo.start, API_DATE_FORMAT);
    const momentEnd = moment(memo.end, API_DATE_FORMAT);

    // Fill the index for dates in period
    while (momentStart.isBefore(momentEnd)) {
      momentStart.add(1, 'day');
      const currentDate = momentStart.format(API_DATE_FORMAT);
      if (!indicesByDate[currentDate]) {
        indicesByDate[currentDate] = [displayIndex];
      } else {
        indicesByDate[currentDate].push(displayIndex);
      }
    }

    return {
      ...memo,
      displayIndex,
    };
  });
};

const shouldApplyBookmarkMetrics = (bookmark, comparedPeriodEnabled) => {
  const { secondary } = bookmark;
  if (secondary && comparedPeriodEnabled) {
    return false;
  }
  return true;
};

const isMemoNotExistError = (response) => {
  const { status, data } = response;
  if (status !== HttpStatus.BAD_REQUEST) {
    return false;
  }
  if (data.errors.length <= 0) {
    return false;
  }
  if (data.errors[0].code !== 'MEMO_DOES_NOT_EXIST') {
    return false;
  }
  return true;
};

export default {
  getDimensionKeyValue,
  getDimensionKeyValueList,
  createDimensionKeyObjectForTable,
  createSeriesName,
  createLegends,
  getChartData,
  getTableData,
  createModalData,
  createGetChartDataRequest,
  createGetTableDataRequest,
  saveBookmark,
  deleteBookmark,
  createSaveMemoRequest,
  createLoadMemoRequest,

  createMemo,
  loadMemo,
  deleteMemo,
  updateMemo,

  updateBookmark,
  getBookmark,

  updateShowChart,
  buildCategoriesList,
  buildMemoListWithDisplayIndex,
  shouldApplyBookmarkMetrics,
  isMemoNotExistError,
  addConverters,
};
