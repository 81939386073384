import * as DisplayItems from './display-items';

const SettingDisplayItems = {
  groupList: {
    [DisplayItems.CATEGORY]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_GROUP1]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_GROUP2]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_ID]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_NAME]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.LINK_URL]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AGENCY]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_NOTE]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_DATE]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.TERMINAL_TYPE]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.LANDING_PAGE_DOMAIN]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.LANDING_PAGE_URL]: DisplayItems.DISPLAY_GROUP_DETAIL,
  },
  // 媒体シンク軸優先時に非表示にする項目
  denyMediaSyncList: [],
  // エビス軸優先時に非表示にする項目
  denyEbisList: [],
  displayFreezeList: [
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
    DisplayItems.AD_ID,
    DisplayItems.AD_NAME,
    DisplayItems.AGENCY,
    DisplayItems.AD_NOTE,
    DisplayItems.AD_DATE,
    DisplayItems.TERMINAL_TYPE,
    DisplayItems.LANDING_PAGE_DOMAIN,
    DisplayItems.LANDING_PAGE_URL,
    DisplayItems.LINK_URL,
    DisplayItems.LINK_PAGE_TITLE,
    DisplayItems.TRANSITION_RATE,
  ],
  orderList: {
    [DisplayItems.CATEGORY]: 1,
    [DisplayItems.AD_GROUP1]: 2,
    [DisplayItems.AD_GROUP2]: 3,
    [DisplayItems.AD_ID]: 4,
    [DisplayItems.AD_NAME]: 5,
    [DisplayItems.LINK_URL]: 6,
    [DisplayItems.LINK_PAGE_TITLE]: 7,
    [DisplayItems.TRANSITION_RATE]: 8,
    [DisplayItems.AGENCY]: 9,
    [DisplayItems.AD_NOTE]: 10,
    [DisplayItems.AD_DATE]: 11,
    [DisplayItems.TERMINAL_TYPE]: 12,
    [DisplayItems.CNT_ACCESS]: 13,
    [DisplayItems.CNT_SESSION]: 14,
    [DisplayItems.CNT_PV]: 15,
    [DisplayItems.PV_AVG]: 16,
    [DisplayItems.CNT_RETURN]: 17,
    [DisplayItems.PER_RETURN]: 18,
    [DisplayItems.TOTAL_STAY_TIME]: 19,
    [DisplayItems.TOTAL_STAY_TIME_AVG]: 20,
    [DisplayItems.TOTAL_LATENCY_TIME]: 21,
    [DisplayItems.LATENCY_TIME_AVG]: 22,
    [DisplayItems.CNT_CV_DETAIL]: 23,
    [DisplayItems.CNT_CV_TOTAL]: 24,
    [DisplayItems.CVR_DETAIL]: 25,
    [DisplayItems.CVR_TOTAL]: 26,
    [DisplayItems.CNT_INDIRECT2]: 27,
    [DisplayItems.CNT_INDIRECT3]: 28,
    [DisplayItems.CNT_INDIRECT4]: 29,
    [DisplayItems.CNT_INDIRECT5]: 30,
    [DisplayItems.CNT_INDIRECT_OTHER]: 31,
    [DisplayItems.INDIRECT_TOTAL]: 32,
    [DisplayItems.DIRECT_RATE]: 33,
    [DisplayItems.INDIRECT_RATE]: 34,
    [DisplayItems.CNT_FIRST]: 35,
    [DisplayItems.RCV]: 36,
    [DisplayItems.AMOUNT]: 37,
    [DisplayItems.RAMOUNT]: 38,
    [DisplayItems.COST]: 39, // TODO: confirm
    [DisplayItems.CPC]: 40, // TODO: confirm
    [DisplayItems.CPA]: 41,
    [DisplayItems.TCPA]: 42,
    [DisplayItems.ROAS]: 43,
    [DisplayItems.TROAS]: 44,
  },
  displayDefaultList: [
    DisplayItems.AD_ID,
    DisplayItems.AD_NAME,
    DisplayItems.LINK_URL,
    DisplayItems.LINK_PAGE_TITLE,
    DisplayItems.TRANSITION_RATE,
    DisplayItems.CNT_ACCESS,
    DisplayItems.CNT_CV_DETAIL,
    DisplayItems.CNT_CV_TOTAL,
    DisplayItems.CVR_DETAIL,
    DisplayItems.CVR_TOTAL,
    DisplayItems.CNT_INDIRECT2,
    DisplayItems.CNT_INDIRECT3,
    DisplayItems.CNT_INDIRECT4,
    DisplayItems.CNT_INDIRECT5,
    DisplayItems.CNT_INDIRECT_OTHER,
    DisplayItems.INDIRECT_TOTAL,
    DisplayItems.CNT_FIRST,
    DisplayItems.RCV,
    DisplayItems.AMOUNT,
    DisplayItems.COST,
    DisplayItems.CPA,
    DisplayItems.TCPA,
    DisplayItems.ROAS,
  ],
  sortDefaultList: {
    [DisplayItems.AD_ID]: DisplayItems.SORT_DIRECTION_ASC,
  },
  sortDisabledList: [DisplayItems.CNT_CV_DETAIL, DisplayItems.CVR_DETAIL],
  ebisRequiredList: [DisplayItems.LINK_URL],
  syncRequiredList: [],
};

const SettingAdDisplayItems = {
  ...SettingDisplayItems,
};

export const getAd = () => {
  const displayItemsBase = DisplayItems.getAd();
  return DisplayItems.getItems({
    ...SettingAdDisplayItems,
    displayItemsBase,
  });
};

export const getAdDefault = () => {
  const displayItemsBase = getAd();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAll = () => {
  return {};
};

export const getAllDefault = () => {
  return {};
};
