import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import adManagementSettingsSelectors from 'store/ad-management-settings/selectors';
import adManagementSelectors from 'store/ad-management/selectors';
import { masterDataSelectors } from 'store/master-data';
import { settingsSelectors } from 'store/settings';
import FilterForm from 'views/organism/FilterForm/FilterForm';
import adManagementSettingsActions from 'store/ad-management-settings/actions';
import { AD_MANAGEMENT_MODE } from 'domain/ad-management/consts';
import { checkDenyFilterTerminalType } from 'services/ad-management/adManagementService';
import { DisplayItemsSelectors } from 'store/display-items';

function AdManagementFilterContainer() {
  const dispatch = useDispatch();

  const [page, tab, masterData, filterableList, selectedFilters, settings, priorityAxis, pageId] = [
    useSelector(settingsSelectors.getTab, shallowEqual),
    useSelector(adManagementSelectors.getTab, shallowEqual),
    useSelector(masterDataSelectors.getForAdManagement, shallowEqual),
    useSelector(adManagementSettingsSelectors.getAvailableFilters, shallowEqual),
    useSelector(adManagementSettingsSelectors.getFilterSettings, shallowEqual),
    useSelector(adManagementSettingsSelectors.getFilters, shallowEqual),
    useSelector(DisplayItemsSelectors.getDisplayItemPriorityAxis, shallowEqual),
    useSelector(settingsSelectors.getPage, shallowEqual)
  ];

  const [currentFilters, setCurrentFilters] = useState(selectedFilters);
  const [isAllowApply, setAllowApply] = useState(false);

  const denyFilterTerminalType = useMemo(
    () => checkDenyFilterTerminalType(currentFilters, tab),
    [currentFilters, tab]
  );

  const newFilterableList = useMemo(() => {
    if (denyFilterTerminalType) {
      const {
        terminal_type: terminalType,
        ...filterWithoutTerminalType
      } = filterableList;

      return filterWithoutTerminalType;
    }

    return filterableList;
  }, [denyFilterTerminalType, filterableList]);

  const newSelectedFilters = useMemo(() => {
    setAllowApply(!isEqual(currentFilters, selectedFilters));
    if (denyFilterTerminalType) {
      const {
        terminal_type: terminalType,
        ...filterWithoutTerminalType
      } = currentFilters;

      return filterWithoutTerminalType;
    }

    if (tab !== AD_MANAGEMENT_MODE.DELETED) {
      const { ad_type: adType, ...filterWithoutAdType } = currentFilters;

      return filterWithoutAdType;
    }

    return currentFilters;
  }, [tab, denyFilterTerminalType, currentFilters, selectedFilters]);

  const handleApplyFilter = useCallback(
    (items) => {
      setAllowApply(false);
      setCurrentFilters(items);
      const disabledSettingsForTab = pickBy(
        settings,
        (setting, key) => !filterableList[key]
      );
      const savingItems = {
        ...disabledSettingsForTab,
        ...items,
      };
      dispatch(adManagementSettingsActions.updateFilters(savingItems));
    },
    [filterableList, settings, dispatch]
  );

  useEffect(() => {
    setCurrentFilters(selectedFilters);
  }, [selectedFilters]);

  return (
    <FilterForm
      masterData={masterData}
      filterableList={newFilterableList}
      selectedFilters={newSelectedFilters}
      applyFilter={handleApplyFilter}
      onChange={(filter) => setCurrentFilters(filter)}
      isAllowApply={isAllowApply}
      currentTab={page}
      priorityAxis={priorityAxis}
      pageId={pageId}
    />
  );
}

export default AdManagementFilterContainer;
