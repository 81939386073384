import React from 'react';
import { oneOfType, object, any, number, string } from 'prop-types';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import isEqual from 'lodash/isEqual';
import GridTableTooltipWrapper from 'views/organism/GridTable/GridTableTooltipWrapper';
import GirdTableCellCheckbox from 'views/organism/GridTable/components/GirdTableCellCheckbox';
import GirdTableCellCollapse from 'views/organism/GridTable/components/GirdTableCellCollapse';
import GirdTableCellContent from 'views/organism/GridTable/components/GirdTableCellContent';
import GridTableCellCopyToClipboard from './GridTableCellCopyToClipboard';

const RenderContent = (props) => {
  const { cell, data, value } = props;
  const {
    field,
    type = '',
    variant = '',
    isEdit = false,
    onChange = () => {},
    onEdit = () => {},
    onClick = () => {},
  } = cell;
  const {
    rowId,
    rowindex,
    selected = false,
    tooltipCheckbox,
    isDisabledCheckbox = false,
    tooltipEdit,
    isDisabledEdit = false,
  } = data;

  switch (type) {
    case 'checkbox':
      return (
        <GirdTableCellCheckbox
          id={rowId}
          checked={selected}
          disabledCheckbox={isDisabledCheckbox}
          tooltipCheckbox={tooltipCheckbox}
          isEdit={isEdit}
          disabledEdit={isDisabledEdit}
          tooltipEdit={tooltipEdit}
          onEdit={onEdit}
          onChange={() => {
            onChange(!selected, rowId);
          }}
        />
      );
    case 'link-copy':
      return <GridTableCellCopyToClipboard value={value} />;
    case 'collapseByHeight':
    case 'collapseByLine':
      return (
        <GirdTableCellCollapse
          key={`${rowindex}-${field}`}
          field={`${rowindex}-${field}`}
          type={type}
          value={value}
          maxHeight={cell.maxHeight}
          maxLine={cell.maxLine}
          isInline={cell.isInline}
        />
      );
    case 'inlineTruncate':
      return (
        value && (
          <GridTableTooltipWrapper>
            <GirdTableCellContent
              inlineTruncate
              rowId={rowId}
              value={value}
              variant={variant}
              onClick={onClick}
            />
          </GridTableTooltipWrapper>
        )
      );
    default:
      return (
        <GirdTableCellContent
          rowId={rowId}
          value={value}
          variant={variant}
          onClick={onClick}
        />
      );
  }
};

RenderContent.propTypes = {
  cell: oneOfType([object]),
  data: oneOfType([object]),
  value: oneOfType([any]),
};

RenderContent.defaultProps = {
  cell: {},
  data: {},
  value: '',
};

function GirdTableCell(props) {
  const { cell, data, gridColumn, gridRow } = props;
  const {
    field,
    type = '',
    variant = '',
    textAlign = '',
    isContentCenter = false,
    isContentRight = false,
  } = cell;
  const { highlightCells = [] } = data;

  const cellClass = classNames({
    'grid-table__cell': true,
    'grid-table__cell--center': isContentCenter,
    [`grid-table__cell--text-${textAlign}`]: !!textAlign,
    [`grid-table__cell--${kebabCase(type)}`]: !!type,
    [`grid-table__cell--${variant}`]: !!variant,
    'grid-table__cell--right': isContentRight,
    'grid-table__cell--highlight': highlightCells.includes(field),
  });

  return (
    <div
      role="cell"
      className={cellClass}
      aria-label={field}
      style={{
        gridColumn: gridColumn || field,
        gridRow: gridRow ? `1/${gridRow + 1}` : null,
      }}
    >
      <div role="contentinfo" className="grid-table__cell-content">
        <RenderContent value={data[field]} data={data} cell={cell} />
      </div>
    </div>
  );
}

GirdTableCell.propTypes = {
  cell: oneOfType([object]),
  data: oneOfType([object]),
  gridRow: number,
  gridColumn: string,
};

GirdTableCell.defaultProps = {
  cell: {},
  data: {},
  gridRow: 0,
  gridColumn: '',
};

const equalFn = (prev, next) => {
  return isEqual(prev.data, next.data);
};

export default React.memo(GirdTableCell, equalFn);
