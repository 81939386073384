import {
  AGENCY_ID,
  MEDIA_DISPLAY_NAME,
  EBIS_MEDIA_ID,
  AGENCY,
  EBIS_MEDIA,
  SYNC_MEDIA_ID,
  EBIS_MEDIA_NAME,
  AGENCY_NAME,
} from 'domain/fields';
import { MAX_LENGTH_MEDIA_NAME } from 'domain/ad-management/consts';
import { SETTINGS_SPECIFICATIONS_SITE } from 'services/routes/constants';
import { cloneDeep, isEmpty, isArray } from 'lodash';
import {
  maxLength,
  notContainSpaces,
  requiredValue,
} from 'services/validations/commonValidations';
import { validateBasic } from 'services/validations/validateService';
import { getErrorMessageByCode } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';
import { REGEX_PASSWORD } from 'services/regexPatterns';
import { FALSE_FLAG, TRUE_FLAG } from 'domain/consts';
import mediaSyncManagementTypes from 'store/media-sync-management/types';
import {
  HEADER_CONFIG,
  FIELD_FORM,
  SYNC_MEDIA,
  SETTING_BASIC_FIELD,
  SYNC_MEDIA_ID_LN,
  MIN_MARGIN_RATE,
  MAX_MARGIN_RATE,
  MARGIN_DECIMAL_PLACE,
  NONE,
  LINE_AUTH_STEP_FIVE,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  AUTHENTICATION_SETTING,
  OPTION_SETTING,
  SENDMAIL_USER_FIELD,
  SENDMAIL_FLAG_FIELD,
  REQUEST_TYPE_REGISTER,
  REQUEST_TYPE_DELETE,
  SYNC_MEDIA_ID_YH,
  REQUEST_TYPE_UPDATE,
} from './consts';

const getHeader = (selectedRows, isCheckedAll, sort) => {
  const headerConfig = HEADER_CONFIG;
  if (sort) {
    return headerConfig.map((item) => {
      const newObj = { ...item };
      if (newObj.sort === 'none' && newObj.name === sort.field) {
        newObj.sort = sort.direction;
      }
      if (newObj.name === 'check_all') {
        newObj.checked = selectedRows.length > 0;
        newObj.variant =
          selectedRows.length > 0 && !isCheckedAll ? 'mixed' : '';
      }
      return newObj;
    });
  }
  return headerConfig;
};

const localValidate = (fieldName, param) => {
  let result = false;
  let message = '';

  // The setting of "Maximum number of characters in 媒体" on the "広告カテゴリ" screen is also adopted here.
  if (fieldName === MEDIA_DISPLAY_NAME) {
    let value = param;
    if (value === '')
      message = `${FIELD_FORM[MEDIA_DISPLAY_NAME]}を入力してください`;
    else {
      value = value.trim();
      if (Boolean(value) === false)
        message = `${FIELD_FORM[MEDIA_DISPLAY_NAME]}を「スペース、タブ、改行」のみで登録することはできません`;
      else if (value.length > MAX_LENGTH_MEDIA_NAME)
        message = `${MAX_LENGTH_MEDIA_NAME}文字以内でご入力ください`;
      else result = true;
    }
  }

  if (fieldName === EBIS_MEDIA_ID) {
    if (param === null) {
      result = false;
      message = `${FIELD_FORM[EBIS_MEDIA_ID]}を選択してください`;
    } else result = true;
  }

  return { result, message };
};

const checkResponse = (response, setErrorUpdate, handleShowErrorModal) => {
  const { errors: errorsResponse, type } = response;
  let errors = [...errorsResponse];
  let errorMessageTemplate = `
    もう一度お試しいただいてもエラーが発生する場合は、
    <br />
    <a
      href=${SETTINGS_SPECIFICATIONS_SITE}
      target="_blank"
      rel="noopener noreferrer"
    >
      サポートセンター
    </a>
    にお問い合わせください。
  `;
  let title = '';

  // Executes processing in response to the "error code" returned from the API
  if (!isEmpty(errors)) {
    // If get "AGENCY_ID_EXIST_CHECK" error or "MEDIA_EXIST_CHECK" error, remove the "MEDIA_AGENCY_EXIST_CHECK" error.
    //   * Because want user to pay attention to the "MEDIA_AGENCY_EXIST_CHECK" error only when the error indicated above is cleared.
    const needRejection = errors.some((error) =>
      ['AGENCY_ID_EXIST_CHECK', 'MEDIA_EXIST_CHECK'].includes(error.code)
    );
    if (needRejection)
      errors = errors.filter(
        (error) => error.code !== 'MEDIA_AGENCY_EXIST_CHECK'
      );

    // Judgment whether to stop displaying the edit modal and display the error modal.
    // Currently one type
    const errorModalCodes = [
      'SAID_EXIST_CHECK',
      'MEDIA_SYNC_HAVE_NOT_PERMISSION',
    ];
    const needErrorModal = errors.some((error) =>
      errorModalCodes.includes(error.code)
    );

    // Determine if you want to display the error message while still displaying the edit modal.
    const updateErrorCodes = [
      'AGENCY_ID_EXIST_CHECK',
      'MAX_LENGTH_CHECK',
      'SPTAB_CHECK',
      'MEDIA_EXIST_CHECK',
      'MEDIA_AGENCY_EXIST_CHECK',
      'REQUIRED_CHECK',
    ];
    const needUpdateErrorMessage = errors.some((error) =>
      updateErrorCodes.includes(error.code)
    );

    if (needErrorModal) {
      // display the error modal.
      if (errors[0].code === 'SAID_EXIST_CHECK') {
        title = 'データ編集に失敗しました';
        errorMessageTemplate =
          '選択された項目はすでに削除されています。<br />別の項目を選択してください。';
      }
      if (errors[0].code === 'MEDIA_SYNC_HAVE_NOT_PERMISSION') {
        if (type === mediaSyncManagementTypes.DELETE_ACCOUNT) {
          title = 'データ削除に失敗しました';
          errorMessageTemplate =
            'こちらの媒体シンクを削除する権限がありません。';
        } else {
          title = 'データ編集に失敗しました';
          errorMessageTemplate =
            'こちらの媒体シンクを編集する権限がありません。';
        }
      }
    } else if (needUpdateErrorMessage) {
      // while still displaying the edit modal.
      const updateErrorMessage = {};
      errors.forEach((error) => {
        if (error.code === 'AGENCY_ID_EXIST_CHECK')
          updateErrorMessage[AGENCY_ID] =
            '選択された代理店はすでに削除されています。';
        else if (error.code === 'MAX_LENGTH_CHECK') {
          updateErrorMessage[MEDIA_DISPLAY_NAME] = {
            result: false,
            message: `${MAX_LENGTH_MEDIA_NAME}文字以内でご入力ください`,
          };
        } else if (error.code === 'SPTAB_CHECK') {
          updateErrorMessage[MEDIA_DISPLAY_NAME] = {
            result: false,
            message: `${FIELD_FORM[MEDIA_DISPLAY_NAME]}を「スペース、タブ、改行」のみで登録することはできません`,
          };
        } else if (error.code === 'MEDIA_EXIST_CHECK') {
          updateErrorMessage[
            EBIS_MEDIA_ID
          ] = `選択された${FIELD_FORM[EBIS_MEDIA_ID]}はすでに削除されています。`;
        } else if (error.code === 'MEDIA_AGENCY_EXIST_CHECK') {
          updateErrorMessage[
            EBIS_MEDIA_ID
          ] = `選択された${FIELD_FORM[EBIS_MEDIA_ID]}に対する閲覧・編集権限がない代理店が指定されています。権限のある代理店を指定するか、代理店に${FIELD_FORM[EBIS_MEDIA_ID]}の閲覧・編集権限を設定してください`;
        } else if (error.code === 'REQUIRED_CHECK') {
          if (error.field === MEDIA_DISPLAY_NAME) {
            updateErrorMessage[MEDIA_DISPLAY_NAME] = {
              result: false,
              message: `${FIELD_FORM[MEDIA_DISPLAY_NAME]}が入力されていません。${FIELD_FORM[MEDIA_DISPLAY_NAME]}は入力必須項目です。`,
            };
          } else title = 'システムエラーが発生しました';
        }
      });
      setErrorUpdate(updateErrorMessage);
    } else title = 'システムエラーが発生しました';
  } else title = 'システムエラーが発生しました';
  if (title !== '') handleShowErrorModal(title, errorMessageTemplate);
};

export const prepareDataRequestForAPI = (data, fields) => {
  return fields.reduce((acc, field) => {
    const value = data[field];

    switch (field) {
      case MEDIA_DISPLAY_NAME:
        return {
          ...acc,
          [MEDIA_DISPLAY_NAME]: value || SYNC_MEDIA[data[SYNC_MEDIA_ID]],
        };

      case AGENCY:
        return { ...acc, [AGENCY_ID]: value?.id || null };

      case EBIS_MEDIA:
        return { ...acc, [EBIS_MEDIA_ID]: value?.id || null };

      case SENDMAIL_USER_FIELD:
        return {
          ...acc,
          [SENDMAIL_USER_FIELD]:
            data[SENDMAIL_FLAG_FIELD] === TRUE_FLAG ? value : [],
        };

      case SENDMAIL_FLAG_FIELD:
        return {
          ...acc,
          [SENDMAIL_FLAG_FIELD]: data[SENDMAIL_FLAG_FIELD] === TRUE_FLAG,
        };

      default:
        return { ...acc, [field]: value };
    }
  }, {});
};

export const prepareDataAddAdAccount = (basicSettingData, adAccountData) => {
  const { sync_media_id: mediaId } = basicSettingData;
  // handle for LINE media
  if ([SYNC_MEDIA_ID_LN].includes(mediaId)) {
    const {
      dsp_account_id: dspAccountId,
      ...restAdAccountData
    } = adAccountData.account;
    return {
      ...basicSettingData,
      account: restAdAccountData,
    };
  }
  return {
    ...basicSettingData,
    ...adAccountData,
  };
};

export const prepareDataRequestForUI = (data) => {
  return {
    ...data,
    [SENDMAIL_FLAG_FIELD]: data[SENDMAIL_FLAG_FIELD] ? TRUE_FLAG : FALSE_FLAG,
    [EBIS_MEDIA]: data[EBIS_MEDIA_ID]
      ? { id: data[EBIS_MEDIA_ID], name: data[EBIS_MEDIA_NAME] }
      : null,
    [AGENCY]: data[AGENCY_ID]
      ? { id: data[AGENCY_ID], name: data[AGENCY_NAME] }
      : null,
    [SENDMAIL_USER_FIELD]: isEmpty(data[SENDMAIL_USER_FIELD])
      ? []
      : data[SENDMAIL_USER_FIELD].sort(), // Sort to compare change while setting
  };
};

export const validateSettingBasic = (data) => {
  const cloneData = cloneDeep(data);

  const rule = {
    [MEDIA_DISPLAY_NAME]: [
      requiredValue(
        `${FIELD_FORM[MEDIA_DISPLAY_NAME]}が入力されていません。${FIELD_FORM[MEDIA_DISPLAY_NAME]}は入力必須項目です。`
      ),
      notContainSpaces(
        '{label}を「スペース、タブ、改行」のみで登録することはできません'
      ),
      maxLength(MAX_LENGTH_MEDIA_NAME, '{length}文字以内でご入力ください'),
    ],
  };

  // Default value display by "媒体" selected so "媒体（表示名）" is will null
  if (cloneData[MEDIA_DISPLAY_NAME] === null) {
    cloneData[MEDIA_DISPLAY_NAME] = SYNC_MEDIA[cloneData[SYNC_MEDIA_ID]];
  }

  // If any content of "担当代理店" is selected, "媒体種別" is required to be selected.
  if (!isEmpty(data[AGENCY])) {
    rule[EBIS_MEDIA] = [requiredValue('{label}を選択してください')];
  }

  const error = validateBasic({
    data: cloneData,
    rule,
    label: SETTING_BASIC_FIELD,
  });

  return error;
};

export const convertErrors = (errors) => {
  if (isEmpty(errors) || !isArray(errors)) return {};

  const message = {
    MAX_LENGTH_CHECK: '{index.0}文字以内でご入力ください',
    SPTAB_CHECK:
      '{label}を「スペース、タブ、改行」のみで登録することはできません',
    SELECT_EMPTY_CHECK: '{label}を選択してください',
    MEDIA_EXIST_CHECK: '選択された{label}はすでに削除されています。',
    MEDIA_AGENCY_EXIST_CHECK:
      '選択された{label}に対する閲覧・編集権限がない代理店が指定されています。権限のある代理店を指定するか、代理店に{label}の閲覧・編集権限を設定してください',
    AGENCY_ID_EXIST_CHECK: '選択された代理店はすでに削除されています。',
    EMAIL_USER_ID_EXIST_CHECK:
      '選択されたユーザーは既に削除されています。画面を再読み込みし、最新の状態をご確認ください。',
  };

  const mapperKey = {
    [AGENCY_ID]: AGENCY,
    [EBIS_MEDIA_ID]: EBIS_MEDIA,
  };

  return errors.reduce((acc, error) => {
    let [field] = error.field.split('.');
    field = mapperKey[field] ?? field;

    const errorMessage = getErrorMessageByCode(
      error,
      { ...messageError, ...message },
      {
        label: SETTING_BASIC_FIELD[field],
      }
    );

    return { ...acc, [field]: errorMessage };
  }, {});
};

export const getSteps = ({
  currentStep,
  currentSubStep,
  configs,
  isShowSubStep,
}) => {
  return configs.map((item) => {
    if (currentSubStep && item.step === AUTHENTICATION_SETTING) {
      return {
        ...item,
        label:
          currentStep === OPTION_SETTING || !isShowSubStep
            ? item.label
            : `${item.label}(${currentSubStep}/${LINE_AUTH_STEP_FIVE})`,
        active: item.step === currentStep,
      };
    }
    return {
      ...item,
      active: item.step === currentStep,
    };
  });
};

export const validatePasswordAuthLine = (value) => {
  return (
    !value.match(REGEX_PASSWORD) ||
    value.length < PASSWORD_MIN_LENGTH ||
    value.length > PASSWORD_MAX_LENGTH
  );
};

const getDecimalPlace = (rate) => {
  const [, placeRate] = String(rate).split('.');
  return placeRate ? placeRate.length : 0;
};

const validateMarginRate = (
  rate,
  minRate = MIN_MARGIN_RATE,
  maxRate = MAX_MARGIN_RATE
) => {
  const decimal = getDecimalPlace(rate);
  // rate is can be string number or number
  // handle for case EMPTY_CHECK
  if (String(rate).length === 0) {
    return '未入力の設定があります。数値を入力するか、「設定しない」を選択してください。';
  }
  // handle for case ACCEPT_VALUE_CHECK
  if (
    Number(rate) > maxRate ||
    Number(rate) < minRate ||
    decimal > MARGIN_DECIMAL_PLACE
  ) {
    return `「広告コストのマージン率」の入力に誤りがあります。数値は「${MIN_MARGIN_RATE}～${MAX_MARGIN_RATE}」の範囲で入力してください。※小数点${MARGIN_DECIMAL_PLACE}桁まで`;
  }
  return null;
};

export const validateOptionInfo = (optionInfo) => {
  const { margins, is_importing_costs: isImportCosts } = optionInfo;
  if (isImportCosts) {
    const resultValidate = Object.values(margins)
      .filter((item) => item.request_type !== REQUEST_TYPE_DELETE)
      .reduce(
        (resultErrorObj, margin, index) => {
          const {
            margin_rate: rate,
            margin_type: type,
            margin_id: id,
            start_date: date,
            editable,
          } = margin;
          let validateError = {};
          // validate for request type "NONE" and ediable = true
          if (type !== NONE && editable) {
            const errorMarginRate = validateMarginRate(rate);
            if (errorMarginRate) {
              // handle response format obj contain keyId obj to sync to local state margin for handle behavior form
              validateError = {
                ...validateError,
                error_margin_rate: errorMarginRate,
              };
            }
          }
          // check index > 0 to check cache have item
          // handle validate start_date is unique
          if (index > 0 && resultErrorObj?.cachePrevMargin?.includes(date)) {
            validateError = {
              ...validateError,
              error_start_date:
                '同じ適用開始日で複数の予定を設定することはできません。適用開始日を変更するか、重複している予定を削除してください。',
            };
          }
          // if have errors
          if (!isEmpty(validateError)) {
            return {
              ...resultErrorObj,
              cachePrevMargin: [...resultErrorObj.cachePrevMargin, date],
              error: {
                ...resultErrorObj.error,
                [id]: {
                  ...margin,
                  ...validateError,
                },
              },
            };
          }
          // else set cache margin
          return {
            ...resultErrorObj,
            cachePrevMargin: [...resultErrorObj.cachePrevMargin, date],
          };
        },
        {
          error: {},
          // use this key for compare margin_date(margin_date is unique)
          cachePrevMargin: [],
        }
      );
    return resultValidate.error;
  }
  return {};
};

export const formatMargins = (margins, initMargins) => {
  const initMarginsStartDateList = Object.values(initMargins)
    .filter((item) => item.editable && item.start_date !== '')
    .map((item) => item.start_date);
  const marginsStartDateListUpdate = Object.values(margins)
    .filter((item) => item.request_type === REQUEST_TYPE_UPDATE)
    .map((item) => item.start_date);
  const marginsDeleteList = Object.values(margins)
    .filter((item) => item.request_type === REQUEST_TYPE_DELETE)
    .map((item) => String(item.margin_id));

  return Object.values(margins).map((margin) => {
    // if margin_type === NONE, client will be set data margin_rate = 0 and send to API
    // if start_date === null => this margin is default
    let result = {
      margin_rate: margin.margin_type !== NONE ? margin.margin_rate : 0,
      margin_type: margin.margin_type,
      start_date: margin?.start_date || null,
      request_type: REQUEST_TYPE_REGISTER,
    };
    // for case margin delete => request_type = "delete"
    // for case margin update => request_type = "register" and pass margin_id
    // for case margin register => request_type = "register" and don't pass margin_id(margin_id will be auto generate)
    // for user don't edit margin => will be send margin like margin list margin get from API
    if (margin.start_date === '') {
      if (margin?.margin_id && !String(margin.margin_id).includes('new')) {
        result = {
          ...result,
          margin_id: margin.margin_id,
        };
      }
      return result;
    }

    if (margin.request_type === REQUEST_TYPE_UPDATE) {
      if (
        !initMarginsStartDateList.includes(margin.start_date) &&
        marginsStartDateListUpdate.includes(margin.start_date)
      ) {
        result = {
          ...result,
        };
      } else {
        result = {
          ...result,
          margin_id: margin.margin_id,
        };
      }
      return result;
    }

    if (margin.request_type === REQUEST_TYPE_REGISTER) {
      if (initMarginsStartDateList.includes(margin.start_date)) {
        const marginObj = Object.values(initMargins).find(
          (item) => item.start_date === margin.start_date
        );
        if (marginsDeleteList.includes(String(marginObj.margin_id))) {
          result = {
            ...result,
            request_type: REQUEST_TYPE_REGISTER,
          };
        } else {
          result = {
            ...result,
            request_type: REQUEST_TYPE_REGISTER,
            margin_id: marginObj.margin_id,
          };
        }
      }
      return result;
    }

    if (margin.request_type === REQUEST_TYPE_DELETE) {
      result = {
        ...result,
        request_type: margin.request_type,
        margin_id: margin.margin_id,
      };
      return result;
    }

    if (!margin.request_type) {
      result = {
        ...result,
        margin_id: margin.margin_id,
      };
      return result;
    }
    return result;
  });
};

export const prepareUsersDisplay = (users, convertValueToComponent) => {
  if (isEmpty(users)) return [];

  return users.reduce((acc, item) => {
    const [emailDisplay, ...emailTooltip] = (item.email || '').split(',');
    return [
      ...acc,
      {
        rowId: item.user_id,
        ...convertValueToComponent({ ...item, emailDisplay, emailTooltip }),
      },
    ];
  }, []);
};

export const getMessageDifferentAccount = (
  syncMediaId = SYNC_MEDIA_ID_YH,
  isError = false
) => {
  const mediaName = SYNC_MEDIA[syncMediaId];
  if (isError) {
    return `初回認証時の${mediaName}アカウントと異なるため再認証できません。\n別の${mediaName}アカウントで認証したい場合は、この媒体シンク設定を削除し、新しく設定を追加してください。`;
  }
  return `※初回認証時の${mediaName}アカウントと異なる場合は再認証ができません。別の${mediaName}アカウントで認証したい場合は、この媒体シンク設定を削除し、新しく設定を追加してください。`;
};

export const getAuthErrorMessageByCode = (code, error = '', syncMediaId) => {
  // get media name by sync media id, default is yahoo media
  let errorMessage = '';
  switch (code) {
    case '401':
      errorMessage = 'エラーが発生しました。';
      if (error != null) {
        if (
          error === 'FAILED_TO_ISSUE_ACCESS_TOKEN' ||
          error === 'FACEBOOK_ACCOUNT_VALIDATE_ERROR'
        ) {
          errorMessage =
            'アカウントの登録に失敗しました。\n必要な権限あるいは広告アカウントが不足しています。';
        } else if (error === 'DIFFERENT_ACCOUNT') {
          // handle change messaage when have error re authe with different account (is not parent account)
          errorMessage = getMessageDifferentAccount(syncMediaId, true);
        } else {
          errorMessage = 'エラーが発生しました。';
        }
      }
      break;
    case '403':
      if (error != null && error === 'NOT_ALLOWED_CAMPAIGN_MANAGE') {
        errorMessage =
          '「AdWordsキャンペーンの管理」の権限が付与されていないため登録できません。認証画面で「AdWordsキャンペーンの管理」にチェックを付けて再度登録してください。';
      } else if (
        error != null &&
        error === 'CANCELLATION_OF_AUTHENTICATION_CONSENT'
      ) {
        errorMessage = '認証をキャンセルしました。';
      } else {
        errorMessage = 'エラーが発生しました。';
      }
      break;
    default:
      errorMessage = 'エラーが発生しました。';
  }
  return errorMessage;
};

export default {
  getHeader,
  localValidate,
  checkResponse,
  getSteps,
  validatePasswordAuthLine,
};
