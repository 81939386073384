import { resolve } from 'domain/permissions/permissionTypes';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { isEmpty, isEqual, get, cloneDeep } from 'lodash';
import moment from 'moment';
import {
  FILTER_CONTACT_HISTORY_LENGTH,
  Metric,
  UNREGISTERED_DISPLAY_VALUE,
  UNREGISTERED_ID_FILTER_KEY,
  UNREGISTERED_IDS_METRICS,
  CALENDAR_DAY_FORMAT,
  MASTER_DATA_FILTER_LIMIT,
  ONE_BYTE_COMMA_SEPERATOR,
  UNLIMITED_VALUE,
} from 'domain/consts';
import { AD_GROUP1, AD_GROUP2 } from 'domain/fields';
import {
  FilterTypeTwoDisplayOptions,
  FILTER_KEY_AD_GROUP1,
  FILTER_KEY_AD_GROUP2,
  FILTER_KEY_AGENCY,
  FILTER_KEY_ADMNG_MEASUREMENT_TYPE,
  FILTER_KEY_ADMNG_AD_TYPE,
  FILTER_KEY_MEDIA_SIDE_GROUP,
  FILTER_KEY_MEDIA_SIDE_CAMPAIGN,
  FILTER_KEY_MEDIA_ACCOUNT,
  FILTER_KEY_CONTENT_CATEGORY,
  FILTER_KEY_CHANNEL_DISPLAY,
  FILTER_KEY_CHANNEL_DISPLAY_RANGE,
  FILTER_KEY_AD_GROUP1_EXCLUDE,
  FILTER_KEY_AD_GROUP2_EXCLUDE,
  FILTER_KEY_MEDIA_EXCLUDE,
  FILTER_KEY_CONTACT_HISTORY,
  FILTER_KEY_CONTACT_HISTORY_AD,
  FILTER_KEY_CONTACT_HISTORY_SEARCH,
  FILTER_KEY_CONTACT_HISTORY_PAGE,
  FILTER_KEY_OWNED_MEDIA_FLAG,
  FILTER_ITEMS_OWNED_MEDIA_FLAG,
  FILTER_KEY_CROSS_DEVICE,
  FILTER_KEY_SEARCH_CONSOLE,
} from 'services/consts';
import { arrToStr } from 'services/utils';
import FilterItem from 'views/organism/filter/FilterItem';
import FilterSelectionMenu from 'views/organism/filter/FilterSelectionMenu';
import Panel from 'views/organism/filter/Panel';
import FilterContactHistory from 'views/molecules/FilterContactHistory';
import * as DefinedConstant from 'domain/consts';
import './FilterForm.scss';
import FilterService from 'domain/FilterService';
import { settingsSelectors } from 'store/settings';
import * as pages from 'services/routes/constants';
import { CHANNEL_NAME } from 'domain/category-analyze/consts';
import commonSelectors from 'store/common/selectors';
import { TAB_AD } from 'store/settings/constant';
import {
  CROSSDEVICE_DIFF_COMPARISON,
  CONTACT_DEVICE,
  SEARCH_WORD,
} from 'domain/settings/display-items';
import DisplayItemsActions from 'store/display-items/actions';
import DisplayItemsApi from 'services/api/DisplayItemsApi';
import screenTitleConfigs from 'services/common/screenTitleConfigs';
import { getItemByKey } from '../filter/FilterTypes';

const contactHistoryDefault = Object.keys(
  DefinedConstant.FILTER_CONTACT_HISTORY
);
const contactHistorys = [
  FILTER_KEY_CONTACT_HISTORY,
  FILTER_KEY_CONTACT_HISTORY_AD,
  FILTER_KEY_CONTACT_HISTORY_SEARCH,
  FILTER_KEY_CONTACT_HISTORY_PAGE,
];

// function to check disabled apply button
const conditionApplied = (filters, selectedFilters, isAllowApply) => {
  if (isAllowApply) return false;
  if (
    FILTER_KEY_CONTACT_HISTORY in filters &&
    !(
      filters[FILTER_KEY_CONTACT_HISTORY].values.length ===
        FILTER_CONTACT_HISTORY_LENGTH &&
      !(FILTER_KEY_CONTACT_HISTORY in selectedFilters)
    )
  ) {
    return isEqual(filters, selectedFilters);
  }
  const {
    [FILTER_KEY_CONTACT_HISTORY]: selectedContactHistoryCondition,
    ...selectedNonContactHistory
  } = selectedFilters;
  const {
    [FILTER_KEY_CONTACT_HISTORY]: currentContactHistoryCondition,
    ...currentNonContactHistory
  } = filters;
  return isEqual(selectedNonContactHistory, currentNonContactHistory);
};

// function to get object filter is modifing
// to handle UI and UX for case user is modifing any filters
const getObjFiltersModifing = (filters, selectedFilters) => {
  const {
    [FILTER_KEY_CONTACT_HISTORY]: selectedContactHistoryCondition,
    ...selectedNonContactHistory
  } = selectedFilters;

  const {
    [FILTER_KEY_CONTACT_HISTORY]: currentContactHistoryCondition,
    ...currentNonContactHistory
  } = filters;

  const objResult = Object.keys(currentNonContactHistory).reduce(
    (result, key) => {
      let isKeyModifing = false;
      // handle for case updating filter
      if (
        key in selectedNonContactHistory &&
        !isEqual(selectedNonContactHistory[key], currentNonContactHistory[key])
      ) {
        isKeyModifing = true;
      }
      // handle for case handleChangeRealTime func with type = 1 call updateFilter func => will be trigger
      if (
        selectedNonContactHistory[key]?.type === 1 &&
        key in selectedNonContactHistory &&
        !isEqual(
          selectedNonContactHistory[key],
          currentNonContactHistory[key]
        ) &&
        isEqual(
          selectedNonContactHistory[key]?.ids || [],
          currentNonContactHistory[key]?.ids || []
        )
      ) {
        isKeyModifing = false;
      }
      // handle for case addtinal filter
      if (
        key in currentNonContactHistory &&
        !(key in selectedNonContactHistory)
      ) {
        if (
          key in currentNonContactHistory &&
          !isEqual(
            selectedNonContactHistory[key],
            currentNonContactHistory[key]
          ) &&
          isEqual(
            selectedNonContactHistory[key]?.values || [],
            currentNonContactHistory[key]?.values || []
          )
        ) {
          isKeyModifing = false;
        } else {
          isKeyModifing = true;
        }
      }

      return {
        ...result,
        [key]: isKeyModifing,
      };
    },
    {}
  );
  return objResult;
};

const convertToDisplayFilter = (item) => {
  const ret = cloneDeep(item);
  const values = [];
  const ids = [];
  const itemValues = item.values;
  Object.keys(itemValues).map((key) => {
    const channelName = CHANNEL_NAME[key];
    ids.push(key);
    return values.push(channelName);
  });
  ret.type = 3;
  ret.values = values;
  ret.ids = ids;
  return ret;
};

// format filter save on local State
const formatFilterState = ({ key, filters, pageId, item }) => {
  const maxOrder =
    Object.keys(filters).length > 0
      ? Math.max.apply(
          null,
          Object.keys(filters).map((k) => filters[k].order)
        ) + 1
      : 1;
  const existingFilter = filters[key] ? filters[key] : null;
  let newFilters;

  if (!existingFilter) {
    if (pageId === pages.CV_ATTRIBUTE && key === FILTER_KEY_CHANNEL_DISPLAY) {
      newFilters = {
        ...filters,
        display_channel_range: {
          ids: null,
          option: item.option,
          title: item.title,
          type: item.type,
          values: item.values,
          order: maxOrder,
        },
      };
      const channelDisplay = convertToDisplayFilter(item);
      newFilters[key] = { ...channelDisplay, order: maxOrder };
    } else {
      newFilters = {
        ...filters,
        [key]: {
          ...item,
          order: maxOrder,
        },
      };
    }
  } else if (
    pageId === pages.CV_ATTRIBUTE &&
    (key === FILTER_KEY_CHANNEL_DISPLAY ||
      key === FILTER_KEY_CHANNEL_DISPLAY_RANGE)
  ) {
    newFilters = {
      ...filters,
      display_channel_range: {
        ...existingFilter,
        ids: null,
        values: item.values,
        option: item.option,
        order: maxOrder,
      },
    };
    const channelDisplay = convertToDisplayFilter(item);
    newFilters[FILTER_KEY_CHANNEL_DISPLAY] = {
      ...existingFilter,
      values: channelDisplay.values,
      ids: channelDisplay.ids,
      option: channelDisplay.option,
      order: maxOrder,
    };
  } else {
    newFilters = {
      ...filters,
      [key]: {
        ...existingFilter,
        values: item.values,
        ids: item.ids,
        option: item.option,
        order: maxOrder,
      },
    };
  }
  // handle for case filter type 3 only have 1 option to select and when user uncheck => will be auto remove from filters
  if (
    [FILTER_KEY_SEARCH_CONSOLE].includes(key) &&
    item &&
    item.type === 3 &&
    item.ids.length < 1
  ) {
    const { [key]: currentFilters, ...restFilters } = filters;
    newFilters = restFilters;
  }

  return newFilters;
};

// function to format filters from local state to submit API
const getFitlersPayload = ({ filters, storeFilters, pageId, currentTab }) => {
  let filtersPayload = { ...filters };
  if (
    FILTER_KEY_SEARCH_CONSOLE in storeFilters &&
    (![
      pages.DETAILS_ANALYSIS,
      pages.CV_ATTRIBUTE,
      pages.LANDING_PAGE_ANALYZE,
    ].includes(pageId) ||
      currentTab === TAB_AD)
  ) {
    filtersPayload = {
      ...filtersPayload,
      [FILTER_KEY_SEARCH_CONSOLE]: {
        ...storeFilters[FILTER_KEY_SEARCH_CONSOLE],
      },
    };
  }
  return filtersPayload;
};

function useOutsideAlerter(ref, refPopupList, customView, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        refPopupList.current &&
        !refPopupList.current.contains(event.target) &&
        !customView &&
        callback
      ) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, customView, ref, refPopupList]);
}

// eslint-disable-next-line react/prop-types
const ClearFilterButton = ({ disabled, onClick }) => {
  return (
    <Button disabled={disabled} size="sm" variant="link" onClick={onClick}>
      クリア
    </Button>
  );
};

const FilterForm = (props) => {
  const {
    masterData,
    filterableList,
    selectedFilters,
    applyFilter,
    customView,
    onChange,
    userPermissions,
    pageId,
    currentTab,
    isAllowApply,
    priorityAxis,
    isPageSettings,
    period,
    crossDeviceReflectionData,
    displayItems,
    storeFilters,
  } = props;

  const refAddBtn = useRef();
  const refFilterContainer = useRef();
  const refMenu = useRef();
  const refPopupList = useRef();
  const refApplyButton = useRef();

  const [showSelectionMenu, setShowSelectionMenu] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [addtionalBtnStatus, setAddtionalStatus] = useState(null);
  const [popupDetailPosition, updatePopupDetailPosition] = useState({
    top: 0,
    left: 0,
  });
  const [
    popupDetailSelectionMenuPosition,
    updatePopupSelectionMenuPosition,
  ] = useState({
    top: 0,
    left: 0,
  });

  const funcId = useSelector(settingsSelectors.getFuncId, shallowEqual);
  const { displayitemsApi = DisplayItemsApi } = screenTitleConfigs[pageId];
  const dispatch = useDispatch();
  const newPeriod = useSelector(commonSelectors.periodSelector, shallowEqual);
  const [popupKey, setPopupKey] = useState(new Date().getTime());
  const [panelItem, setPanelItem] = useState({
    items: [],
    title: '',
    type: 0,
    key: '',
    tooltip: '',
    maxItemDisplay: MASTER_DATA_FILTER_LIMIT,
    maxItemSelect: MASTER_DATA_FILTER_LIMIT,
    defaultProps: {},
  });
  const [filters, setFilters] = useState({});
  const [okButtonEnabled, setOkButtonEnabled] = useState(false);
  const [currentFilterModifing, setCurrentFilterModifing] = useState(null);

  const [disabledApplyButton, setDisabledApplyButton] = useState(
    // default
    conditionApplied(filters, selectedFilters, isAllowApply)
  );

  // On new filters applied, clear this local filter state
  useEffect(() => {
    setFilters(selectedFilters);
  }, [selectedFilters, priorityAxis]);

  const refreshPopup = () => {
    setPopupKey(new Date().getTime());
  };

  const getPanelMasterData = useCallback(
    (key) => {
      let items;
      switch (key) {
        case Metric.device.key:
          items = masterData.devices;
          break;
        case Metric.adGroup1.key:
        case FILTER_KEY_AD_GROUP1_EXCLUDE:
          items = masterData.adGroup1;
          break;
        case Metric.adGroup2.key:
        case FILTER_KEY_AD_GROUP2_EXCLUDE:
          items = masterData.adGroup2;
          break;
        case Metric.displayChannel.key:
          items = masterData.displayChannels;
          break;
        case Metric.displayChannelRange.key:
          items = masterData.displayChannelsRange;
          break;
        case Metric.summaryChannel.key:
          items = masterData.summaryChannels;
          break;
        case Metric.summaryAllChannels.key:
          items = masterData.summaryAllChannels;
          break;
        case Metric.media.key:
        case FILTER_KEY_MEDIA_EXCLUDE:
          items = masterData.media;
          break;
        case Metric.targetCv.key:
          items = masterData.cv;
          break;
        case FILTER_KEY_ADMNG_MEASUREMENT_TYPE:
          items = masterData.measurementTypes;
          break;
        case FILTER_KEY_SEARCH_CONSOLE:
          items = masterData.searchConsole;
          break;
        case FILTER_KEY_ADMNG_AD_TYPE:
          items = masterData.adTypes;
          break;
        case FILTER_KEY_AGENCY:
          items = masterData.agency;
          break;
        case FILTER_KEY_MEDIA_SIDE_GROUP:
          items =
            pageId === pages.AD_MANAGEMENT
              ? masterData.mediaSideGroup
              : FilterService.createPairKeyItems(
                  masterData.mediaSideGroup,
                  masterData.adGroup2
                );
          break;
        case FILTER_KEY_MEDIA_SIDE_CAMPAIGN:
          items =
            pageId === pages.AD_MANAGEMENT
              ? masterData.mediaSideCampaign
              : FilterService.createPairKeyItems(
                  masterData.mediaSideCampaign,
                  masterData.adGroup1
                );
          break;
        case FILTER_KEY_MEDIA_ACCOUNT:
          items =
            pageId === pages.AD_MANAGEMENT
              ? masterData.mediaAccount
              : FilterService.createPairKeyItems(
                  masterData.mediaAccount,
                  masterData.media
                );
          break;
        case FILTER_KEY_CONTENT_CATEGORY:
          items = masterData.contentCategory;
          break;
        case FILTER_KEY_OWNED_MEDIA_FLAG:
          items = FILTER_ITEMS_OWNED_MEDIA_FLAG;
          break;
        case Metric.cross_device.key:
          items = masterData.cross_device;
          break;
        default:
          items = [];
          break;
      }
      return items
        .filter((item) => {
          // ignore item
          const denyScreens = get(item, `denyScreens`);
          if (denyScreens && denyScreens.includes(pageId)) return false;

          const allowScreens = get(item, `allowScreen`);
          return isEmpty(allowScreens) || allowScreens.includes(pageId);
        })
        .map((item) => {
          const permissionSet = get(item, `permissionSet.${currentTab}`);
          if (!permissionSet) {
            return item;
          }
          return {
            ...item,
            disabled: !resolve(permissionSet, userPermissions),
          };
        });
    },
    [
      pageId,
      currentTab,
      userPermissions,
      masterData.adGroup1,
      masterData.adGroup2,
      masterData.cv,
      masterData.devices,
      masterData.displayChannels,
      masterData.adTypes,
      masterData.measurementTypes,
      masterData.media,
      masterData.agency,
      masterData.summaryChannels,
      masterData.summaryAllChannels,
      masterData.mediaSideGroup,
      masterData.mediaSideCampaign,
      masterData.mediaAccount,
      masterData.contentCategory,
      masterData.displayChannelsRange,
      masterData.cross_device,
      masterData.searchConsole,
    ]
  );

  const onSelectFilter = (key, item, trigger) => {
    const popupListWidth = 320;
    const margin = 60;
    const filterContainerEl = refFilterContainer.current;
    let newKey = key;

    let positionLeft = 0;
    if (trigger.classList.contains('badge')) {
      positionLeft = trigger.offsetLeft;
    } else {
      positionLeft = trigger.closest('.filter-container__selection-menu')
        .offsetLeft;
    }

    const left =
      filterContainerEl.offsetWidth - positionLeft - popupListWidth - margin < 0
        ? positionLeft - popupListWidth + trigger.offsetWidth
        : positionLeft;

    updatePopupDetailPosition({
      top: 5 + trigger.offsetTop + trigger.offsetHeight,
      left,
    });
    if (key === FILTER_KEY_CHANNEL_DISPLAY_RANGE) {
      newKey = FILTER_KEY_CHANNEL_DISPLAY;
    }

    const {
      title,
      subTitle = '',
      defaultProps = [],
      maxItemDisplay = MASTER_DATA_FILTER_LIMIT,
      maxItemSelect = MASTER_DATA_FILTER_LIMIT,
      tooltip = '',
    } = filterableList[newKey] || {};

    const items = getPanelMasterData(newKey);
    setPanelItem({
      type: item.type,
      tooltip: tooltip || '',
      key: newKey,
      title: `${title}${subTitle ? `（${subTitle}）` : ''}`,
      defaultProps,
      items,
      maxItemDisplay,
      maxItemSelect,
    });
    refreshPopup();
    setShowPanel(true);
    setShowSelectionMenu(false);
  };

  const onAddNewButtonClick = (trigger) => {
    if (showPanel) {
      setShowPanel(false);
    }
    if (!customView) {
      setCurrentFilterModifing(null);
    }
    const filterContainerEl = refFilterContainer.current;
    const menuEl = refMenu.current;
    const positionLeft = trigger.closest('.filter-container__selection-menu')
      .offsetLeft;

    const left =
      filterContainerEl.offsetWidth - positionLeft - menuEl.offsetWidth < 0
        ? positionLeft - menuEl.offsetWidth + trigger.offsetWidth
        : positionLeft;

    // If filter only one item
    if (Object.keys(filterableList).length === 1) {
      const key = Object.keys(filterableList)[0];
      onSelectFilter(key, filterableList[key], refAddBtn.current);
    } else {
      refreshPopup();
      setShowSelectionMenu(true);
    }
    updatePopupSelectionMenuPosition({
      top: 5 + trigger.offsetTop + trigger.offsetHeight,
      left,
    });
  };

  // Update display items.
  const handleDisplayItemsSubmit = useCallback(
    (data) => {
      dispatch(
        DisplayItemsActions.create({
          data,
          funcId,
          displayitemsApi,
        })
      );
      dispatch(DisplayItemsActions.fetch({ funcId, displayitemsApi }));
    },
    [dispatch, funcId, displayitemsApi]
  );
  const updateDisplayItems = () => {
    if (
      CROSSDEVICE_DIFF_COMPARISON in displayItems &&
      displayItems.crossdevice_diff_comparison
    ) {
      displayItems.crossdevice_diff_comparison = false;
      handleDisplayItemsSubmit(displayItems);
    }
    if (CONTACT_DEVICE in displayItems && displayItems.contact_device) {
      displayItems.contact_device = false;
      handleDisplayItemsSubmit(displayItems);
    }
  };

  const submitApplyFilter = (filtersParam = filters) => {
    if (!(FILTER_KEY_CROSS_DEVICE in filtersParam) && !customView) {
      // Update display items when deleting cross-device
      updateDisplayItems();
    }
    const filtersPayload = getFitlersPayload({
      filters: filtersParam,
      storeFilters,
      pageId,
      currentTab,
    });
    applyFilter(filtersPayload);
  };

  const onApplyClick = useCallback(
    () => {
      if (currentFilterModifing && okButtonEnabled) {
        submitApplyFilter({ ...filters, ...currentFilterModifing });
      } else {
        submitApplyFilter(filters);
      }
      setShowSelectionMenu(false);
      setShowPanel(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, currentFilterModifing]
  );

  const onAddFilter = (key, item) => {
    const newFilters = formatFilterState({ key, filters, pageId, item });
    setFilters(() => newFilters);
    setShowPanel(() => false);
    setAddtionalStatus((currentStatus) => !currentStatus);
    refreshPopup();
    onChange(newFilters);
  };

  useEffect(() => {
    if (addtionalBtnStatus !== null && !customView) {
      const trigger = document.getElementById('btnAddtionalFilter');

      const filterContainerEl = refFilterContainer.current;
      const menuEl = refMenu.current;
      const positionLeft = trigger.closest('.filter-container__selection-menu')
        .offsetLeft;

      const left =
        filterContainerEl.offsetWidth - positionLeft - menuEl.offsetWidth < 0
          ? positionLeft - menuEl.offsetWidth + trigger.offsetWidth
          : positionLeft;
      setShowSelectionMenu(() => true);
      updatePopupSelectionMenuPosition({
        top: 5 + trigger.offsetTop + trigger.offsetHeight,
        left,
      });
    }
  }, [addtionalBtnStatus, customView]);

  const onRemoveFilter = (key) => {
    let removedFilter = {};
    if (
      key === FILTER_KEY_CHANNEL_DISPLAY_RANGE ||
      key === FILTER_KEY_CHANNEL_DISPLAY
    ) {
      const {
        display_channel: item1,
        display_channel_range: item2,
        ...newFilters
      } = filters;
      removedFilter = newFilters;
    } else {
      const { [key]: item, ...newFilters } = filters;
      removedFilter = newFilters;
    }
    setFilters(() => removedFilter);
    onChange(removedFilter);

    if (!customView) {
      const objFilterKeyModifing = getObjFiltersModifing(
        filters,
        selectedFilters
      );
      const trueCount = Object.values(objFilterKeyModifing).filter(
        (value) => value === true
      ).length;
      if (trueCount < 1) {
        submitApplyFilter(removedFilter);
      }
    }
  };

  const getPanelMasterDataWithDefault = useCallback(
    (key) => {
      const panelMasterData = getPanelMasterData(key);
      if (UNREGISTERED_IDS_METRICS.map((metric) => metric.key).includes(key)) {
        return [
          {
            key: UNREGISTERED_ID_FILTER_KEY,
            value: UNREGISTERED_DISPLAY_VALUE,
          },
        ].concat(panelMasterData);
      }
      return panelMasterData;
    },
    [getPanelMasterData]
  );

  const onClosePanel = useCallback(() => {
    if (!customView) {
      setCurrentFilterModifing(null);
    }
    refreshPopup();
    setShowPanel(false);
  }, []);

  const getMetricTitleByKey = useCallback(
    (key) => {
      const { display } = masterData;
      // Custom setting label
      if (display) {
        if (key === FILTER_KEY_AD_GROUP1) {
          return display[AD_GROUP1];
        }
        if (key === FILTER_KEY_AD_GROUP2) {
          return display[AD_GROUP2];
        }
        if (display[key]) {
          return display[key];
        }
      }

      const { title, subTitle = '' } = filterableList[key] || {};
      return title ? `${title}${subTitle ? `（${subTitle}）` : ''}` : '';
    },
    [filterableList, masterData]
  );

  const formatNumber = (value) => {
    return (+value).toLocaleString(undefined);
  };

  const getFilterTextType8 = useCallback((data, value, key) => {
    let content = getItemByKey(data, key).name;
    const min = parseInt(value.min, 10);
    const max = parseInt(value.max, 10);
    if (Number.isNaN(min) && Number.isNaN(max)) {
      return content;
    }
    content += ':';

    if (!Number.isNaN(min)) {
      content += `${min}以上`;
    }
    if (!Number.isNaN(max)) {
      content += `${max}以下`;
    }
    return content;
  }, []);

  // eslint-disable-next-line no-shadow
  const getNewFilterTextType3 = useCallback((data, id, filters) => {
    let content = getItemByKey(data, id).name;
    if (
      FILTER_KEY_CHANNEL_DISPLAY_RANGE in filters &&
      id in filters.display_channel_range.values
    ) {
      const value = filters.display_channel_range.values[id];
      const min = parseInt(value.min, 10);
      const max = parseInt(value.max, 10);
      if (Number.isNaN(min) && Number.isNaN(max)) {
        return content;
      }
      content += ':';

      if (!Number.isNaN(min)) {
        content += `${min}以上`;
      }
      if (!Number.isNaN(max)) {
        content += `${max}以下`;
      }
    }

    return content;
  }, []);

  const getFilterText = useCallback(
    // eslint-disable-next-line no-shadow
    (filters, key) => {
      let content;
      const filter = filters[key];
      let newKey = key;
      if (filter.type === 2) {
        const items = filter.option === null ? filter.values : [filter];
        const contents = items.reduce((acc, item) => {
          const valueString = arrToStr(
            item.values,
            false,
            UNLIMITED_VALUE,
            ONE_BYTE_COMMA_SEPERATOR
          );
          const matchType = FilterTypeTwoDisplayOptions.find(
            (filterOption) => filterOption.key === item.option
          ).value;
          return [...acc, `${valueString}${matchType}`];
        }, []);
        content = arrToStr(contents, true);
      } else if (filter.type === 3) {
        // For display channel filters with range specification
        if (
          pageId === pages.CV_ATTRIBUTE &&
          (key === FILTER_KEY_CHANNEL_DISPLAY_RANGE ||
            key === FILTER_KEY_CHANNEL_DISPLAY)
        ) {
          const channelNamesByKey = filters.display_channel.ids.map((id) => ({
            key: id,
            name: CHANNEL_NAME[id],
          }));

          let texts;
          texts = filters.display_channel.ids.map((id) => {
            return getNewFilterTextType3(channelNamesByKey, id, filters);
          });
          texts = texts.reduce((previous, current) => {
            if (current !== '') {
              return [...previous, current];
            }
            return previous;
          }, []);
          newKey = FILTER_KEY_CHANNEL_DISPLAY;
          content = texts.join(', ');
        } else {
          let { values } = filter;
          if ((!values || !values.length) && 'ids' in filter) {
            const data = getPanelMasterDataWithDefault(key);
            values = [];
            data.forEach((item) => {
              if (filter.ids.includes(item.key)) {
                values.push(item.value);
              }
            });
          }
          content = arrToStr(values);
        }
      } else if (filter.type === 5) {
        const content0 = filter.values[0]
          ? `${formatNumber(filter.values[0])}円以上`
          : '';
        const content1 = filter.values[1]
          ? `${formatNumber(filter.values[1])}円以下`
          : '';
        content = `${content0}${content1}`;
      } else if (filter.type === 6) {
        const content0 = filter.values[0]
          ? moment(filter.values[0]).format(CALENDAR_DAY_FORMAT)
          : '';
        const content1 = filter.values[1]
          ? moment(filter.values[1]).format(CALENDAR_DAY_FORMAT)
          : '';
        content = `${content0} ～ ${content1}`;
      } else if (filter.type === 7) {
        content = `${arrToStr(filter.values)}に完全一致`;
      } else if (filter.type === 8) {
        content = '';
        let texts = Object.keys(filter.values).map((mapkey) => {
          const value = filter.values[mapkey];
          return getFilterTextType8(masterData.cv, value, mapkey);
        });
        texts = texts.reduce((previous, current) => {
          if (current !== '') {
            return [...previous, current];
          }
          return previous;
        }, []);
        content = texts.join(',');
      } else if ([10, 11].includes(filter.type)) {
        content = filter.values;
      } else {
        // Lookup text to display by id
        let { values } = filter;
        if (!values && 'ids' in filter) {
          const data = getPanelMasterDataWithDefault(key);
          values = [];
          data.forEach((item) => {
            if (filter.ids.includes(item.key)) {
              values.push(item.value);
            }
          });
        }
        content = arrToStr(values);
      }
      return `${getMetricTitleByKey(newKey)}: ${content}`;
    },
    [getMetricTitleByKey, getPanelMasterDataWithDefault]
  );

  const handleContactHistoryChange = useCallback(
    (name, options) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: {
          ...filterableList[name],
          ...filters[name],
          values: options,
        },
      }));

      if (isEmpty(filters) && !isEmpty(selectedFilters)) {
        onChange({
          ...filters,
          ...selectedFilters,
          [name]: {
            ...filterableList[name],
            ...filters[name],
            values: options,
          },
        });
      } else {
        onChange({
          ...filters,
          [name]: {
            ...filterableList[name],
            ...filters[name],
            values: options,
          },
        });
      }
    },
    [filterableList, filters, onChange, selectedFilters]
  );

  const filtersKey = useMemo(() => Object.keys(filters).toString(), [filters]);
  const filterableListKey = useMemo(
    () => Object.keys(filterableList).toString(),
    [filterableList]
  );

  const presetsForPanelItem = useMemo(() => {
    let { key } = panelItem;
    if (pageId === pages.CV_ATTRIBUTE && key === FILTER_KEY_CHANNEL_DISPLAY) {
      key = FILTER_KEY_CHANNEL_DISPLAY_RANGE;
    }
    const existingFilter = filters[key] ? filters[key] : null;
    if (!existingFilter) {
      if (
        pageId === pages.CV_ATTRIBUTE &&
        key === FILTER_KEY_CHANNEL_DISPLAY_RANGE &&
        filters[FILTER_KEY_CHANNEL_DISPLAY]
      ) {
        return {
          values: {},
          ids: null,
          option: null,
        };
      }
      return {};
    }
    return {
      values: existingFilter.values,
      ids: existingFilter.ids,
      option: existingFilter.option,
    };
  }, [filters, panelItem.key]);

  // Update filter menu list
  const [filterableMenuList, updateFilterableMenuList] = useState({});
  useEffect(() => {
    const filterable = {};
    Object.keys(filterableList).map((key) => {
      let filtered = !!filters[key];
      // Do not display on menu
      if (contactHistorys.includes(key)) {
        filtered = true;
      }
      if (!filtered) {
        filterable[key] = filterableList[key];
      }
      return key;
    });
    updateFilterableMenuList(filterable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterableListKey, filtersKey, masterData]);

  const totalItemsFiltersExculedContactHistory = useMemo(() => {
    const filterKeys = Object.keys(filters);
    const filterContactHistory = filterKeys.filter(
      (key) =>
        (filters[key].type !== 0 && key !== FILTER_KEY_CONTACT_HISTORY) ||
        filters[key].values.length !== FILTER_CONTACT_HISTORY_LENGTH
    );
    return filterContactHistory.length;
  }, [filters]);

  const isNotAllowRemoveFilterGoogleSearchConsole = useMemo(() => {
    const filterKeys = Object.keys(filters);
    return (
      filterKeys.includes(FILTER_KEY_SEARCH_CONSOLE) &&
      SEARCH_WORD in displayItems &&
      displayItems.search_word
    );
  }, [displayItems, filters]);

  const isOnlyInlcudesFilterGoogleSearchConsole = useMemo(() => {
    return (
      totalItemsFiltersExculedContactHistory === 1 &&
      isNotAllowRemoveFilterGoogleSearchConsole
    );
  }, [
    isNotAllowRemoveFilterGoogleSearchConsole,
    totalItemsFiltersExculedContactHistory,
  ]);

  const onClearClick = () => {
    let actualFilters = {};
    // Keep default filter
    const contactHistoryFilters = contactHistorys.reduce((acc, field) => {
      if (filters[field]) {
        return { ...acc, [field]: { values: [...contactHistoryDefault] } };
      }
      return acc;
    }, {});
    actualFilters = { ...contactHistoryFilters };
    if (isNotAllowRemoveFilterGoogleSearchConsole) {
      actualFilters = {
        ...actualFilters,
        [FILTER_KEY_SEARCH_CONSOLE]: { ...filters[FILTER_KEY_SEARCH_CONSOLE] },
      };
    }
    setFilters(actualFilters);
    if (!customView) {
      setCurrentFilterModifing(null);
    }
    if (!(FILTER_KEY_CROSS_DEVICE in contactHistoryFilters) && !customView) {
      // Update display items when clearing cross device
      updateDisplayItems();
    }

    if (customView) {
      onChange(actualFilters);
    }
    if (conditionApplied(filters, selectedFilters, isAllowApply)) {
      applyFilter({});
      setShowSelectionMenu(false);
      setShowPanel(false);
    }
  };

  const contactHistoryValues = useMemo(() => {
    return contactHistorys.reduce((acc, key) => {
      if (!(key in filters)) return acc;

      return { ...acc, [key]: filters[key].values || [] };
    }, {});
  }, [filters]);

  const filterContainerClass = classNames({
    'filter-container': true,
    [`filter-container--${pageId.replace('/', '-')}`]: true,
    'filter-container--custom-view': customView,
    'filter-container--settings': isPageSettings,
  });

  const handleChangeRealTimeFilter = (filterKey, currentFilterSelected) => {
    const fitlerChange = formatFilterState({
      key: filterKey,
      filters,
      pageId,
      item: currentFilterSelected,
    });
    const disabledApply = conditionApplied(
      fitlerChange,
      selectedFilters,
      isAllowApply
    );
    const objFilterKeyModifing = getObjFiltersModifing(
      fitlerChange,
      selectedFilters
    );
    const trueCount = Object.values(objFilterKeyModifing).filter(
      (value) => value === true
    ).length;
    const enabledOkButton = FilterService.getEnabledOkButton({
      type: panelItem.type,
      filter: currentFilterSelected,
      pageId,
      name: filterKey,
      presets: presetsForPanelItem,
      maxItemSelect: panelItem.maxItemDisplay,
      filterInfo: filterableList[filterKey],
    });
    setOkButtonEnabled(enabledOkButton);
    if (trueCount < 1 && !enabledOkButton) {
      setDisabledApplyButton(true);
    } else {
      setDisabledApplyButton(disabledApply);
    }

    if (disabledApply) {
      setCurrentFilterModifing(null);
    } else {
      setCurrentFilterModifing({ [filterKey]: fitlerChange[filterKey] });
    }
    return disabledApply;
  };

  useEffect(() => {
    const disabledApply = conditionApplied(
      filters,
      selectedFilters,
      isAllowApply
    );
    setDisabledApplyButton(disabledApply);
  }, [filters, isAllowApply, selectedFilters]);

  const handleApplyFilter = (key, item) => {
    setShowPanel(() => false);
    if (item) {
      const newFilters = formatFilterState({ key, filters, pageId, item });
      setFilters(() => newFilters);
      const enabledOkButton = FilterService.getEnabledOkButton({
        type: panelItem.type,
        filter: item,
        pageId,
        name: key,
        presets: presetsForPanelItem,
        maxItemSelect: panelItem.maxItemDisplay,
        filterInfo: filterableList[key],
      });
      if (enabledOkButton) {
        submitApplyFilter(newFilters);
      } else {
        submitApplyFilter(filters);
      }
    } else {
      submitApplyFilter(filters);
    }
    setShowSelectionMenu(false);
    refreshPopup();
  };

  const handleClickOutsideApplyButton = () => {
    const objFilterKeyModifing = getObjFiltersModifing(
      filters,
      selectedFilters
    );
    const trueCount = Object.values(objFilterKeyModifing).filter(
      (value) => value === true
    ).length;
    setCurrentFilterModifing(null);
    if (trueCount < 1) {
      setDisabledApplyButton(true);
    }
  };

  useOutsideAlerter(
    refApplyButton,
    refPopupList,
    customView,
    handleClickOutsideApplyButton
  );

  return (
    <div>
      <div ref={refFilterContainer} className={filterContainerClass}>
        {!customView && (
          <div className="filter-container__label font-weight-bold">
            フィルタ
          </div>
        )}
        <div className="filter-container__content">
          <div className="filter-container__selected-area d-flex align-items-center">
            {Object.keys(filters)
              .filter(
                (key) =>
                  filters[key].type !== 0 && key !== FILTER_KEY_CONTACT_HISTORY
              )
              .filter((key) =>
                FilterService.acceptFilterWithAxis(priorityAxis, key)
              )
              .map((key) => {
                // Display new filter text only on the CV attribute screen.
                if (
                  (pageId === pages.CV_ATTRIBUTE &&
                    FILTER_KEY_CHANNEL_DISPLAY in filters &&
                    FILTER_KEY_CHANNEL_DISPLAY_RANGE in filters &&
                    key === FILTER_KEY_CHANNEL_DISPLAY) ||
                  (pageId !== pages.CV_ATTRIBUTE &&
                    FILTER_KEY_CHANNEL_DISPLAY in filters &&
                    FILTER_KEY_CHANNEL_DISPLAY_RANGE in filters &&
                    key === FILTER_KEY_CHANNEL_DISPLAY_RANGE)
                ) {
                  return <></>;
                }
                const filterToCompare =
                  currentFilterModifing && !customView
                    ? { ...filters, ...currentFilterModifing }
                    : filters;

                const objFilterKeyModifing = getObjFiltersModifing(
                  filterToCompare,
                  selectedFilters
                );

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <FilterItem
                    filterKey={key}
                    isFilterModifing={!!objFilterKeyModifing[key]}
                    filters={filters}
                    text={getFilterText(filters, key)}
                    onClose={() => onRemoveFilter(key)}
                    onEdit={(el) => onSelectFilter(key, filters[key], el)}
                    pageId={pageId}
                    period={
                      Object.keys(period).length === 0 ? newPeriod : period
                    }
                    crossDeviceReflectionData={crossDeviceReflectionData}
                    displayItems={displayItems}
                    handleDisplayItemsSubmit={handleDisplayItemsSubmit}
                  />
                );
              })}
            <div className="filter-container__action-area">
              <div className="filter-container__selection-menu">
                <Button
                  id="btnAddtionalFilter"
                  ref={refAddBtn}
                  size="sm"
                  variant="link"
                  onClick={() => onAddNewButtonClick(refAddBtn.current)}
                  disabled={Object.keys(filterableMenuList).length === 0}
                >
                  {customView ? '追加' : '条件を追加'}
                </Button>
                {customView && (
                  <div
                    className="filter-selection-menu-spacer--view"
                    style={{
                      display: showSelectionMenu ? 'inline-block' : 'none',
                      visibility: showSelectionMenu ? 'visible' : 'hidden',
                      ...popupDetailSelectionMenuPosition,
                    }}
                  />
                )}
                <div
                  ref={refMenu}
                  className="filter-selection-menu"
                  style={
                    customView
                      ? {
                          // Need to set display:'none' for custom view scrollbars
                          display: showSelectionMenu ? 'inline-block' : 'none',
                          visibility: showSelectionMenu ? 'visible' : 'hidden',
                          ...popupDetailSelectionMenuPosition,
                        }
                      : {
                          visibility: showSelectionMenu ? 'visible' : 'hidden',
                          ...popupDetailSelectionMenuPosition,
                        }
                  }
                >
                  <FilterSelectionMenu
                    ref={refMenu}
                    pageId={pageId}
                    key={popupKey}
                    displayItems={displayItems}
                    items={filterableMenuList}
                    period={
                      Object.keys(period).length === 0 ? newPeriod : period
                    }
                    setShowSelectionMenu={setShowSelectionMenu}
                    onSelect={(key, item) =>
                      onSelectFilter(key, item, refAddBtn.current)
                    }
                    priorityAxis={priorityAxis}
                    customView={customView}
                  />
                </div>
              </div>
              {!customView && (
                <>
                  <Button
                    ref={refApplyButton}
                    size="xs"
                    disabled={disabledApplyButton}
                    variant="secondary"
                    onClick={onApplyClick}
                  >
                    適用
                  </Button>
                  <ClearFilterButton
                    disabled={
                      totalItemsFiltersExculedContactHistory === 0 ||
                      isOnlyInlcudesFilterGoogleSearchConsole
                    }
                    onClick={onClearClick}
                  />
                  <span className="notice-text align-self-center">
                    {disabledApplyButton
                      ? ''
                      : '※まだ変更内容は適用されていません'}
                  </span>
                </>
              )}
            </div>
            <div
              ref={refPopupList}
              className="filter-container__popup-item-list"
              style={
                customView
                  ? {
                      // Need to set paddingBottom instead of marginBottom
                      marginBottom: 0,
                      paddingBottom: '7px',
                      display: showPanel ? 'block' : 'none',
                      ...popupDetailPosition,
                    }
                  : {
                      display: showPanel ? 'block' : 'none',
                      ...popupDetailPosition,
                    }
              }
            >
              <Panel
                show={showPanel}
                pageId={pageId}
                filterInfo={filterableList[panelItem.key] || {}}
                name={panelItem.key}
                key={popupKey}
                filterKey={panelItem.key}
                tooltip={panelItem.tooltip}
                items={panelItem.items}
                params={panelItem.params}
                type={panelItem.type}
                title={panelItem.title}
                maxItemDisplay={panelItem.maxItemDisplay}
                maxItemSelect={panelItem.maxItemSelect}
                onSubmit={(item) => onAddFilter(panelItem.key, item)}
                onCancel={onClosePanel}
                presets={presetsForPanelItem}
                defaultProps={panelItem.defaultProps}
                filters={filters}
                status={masterData.status}
                onApplyClick={handleApplyFilter}
                disabledApply={disabledApplyButton}
                onChangeFilter={handleChangeRealTimeFilter}
                customView={customView}
                selectedFilters={selectedFilters}
                filterableMenuList={filterableMenuList}
              />
            </div>
          </div>
        </div>
        {customView && (
          <div className="filter-container__label tail">
            <ClearFilterButton
              onClick={onClearClick}
              disabled={
                totalItemsFiltersExculedContactHistory === 0 ||
                isOnlyInlcudesFilterGoogleSearchConsole
              }
            />
          </div>
        )}
      </div>
      {contactHistorys
        .filter((name) => filterableList[name])
        .map((name) => (
          <FilterContactHistory
            key={name}
            customView={customView}
            name={name}
            title={filterableList[name].title}
            tooltip={filterableList[name].tooltip}
            defaultValue={contactHistoryValues[name]}
            onChange={handleContactHistoryChange}
          />
        ))}
    </div>
  );
};

FilterForm.propTypes = {
  masterData: shape({}).isRequired,
  /* {
    conv_id: {
      title: string,
      order: number,
      type: number,
    },
  } */
  filterableList: shape({}),
  /* {
    conv_id: {
      title: string,
      order: number,
      type: number,
      option: string,
      values: arrayOf(string),
      ids: arrayOf(string),
    },
  } */
  selectedFilters: shape({}),
  storeFilters: shape({}),
  applyFilter: func,
  customView: bool,
  isAllowApply: bool,
  isPageSettings: bool,
  onChange: func,
  userPermissions: arrayOf(string),
  pageId: string,
  currentTab: string.isRequired,
  priorityAxis: string,
  status: string,
  period: shape({}),
  crossDeviceReflectionData: shape([]),
  displayItems: shape({}),
};

FilterForm.defaultProps = {
  pageId: '',
  filterableList: {},
  selectedFilters: {},
  storeFilters: {},
  userPermissions: [],
  customView: false,
  isAllowApply: false,
  isPageSettings: false,
  applyFilter: () => {},
  onChange: () => {},
  priorityAxis: DefinedConstant.PRIORITY_AXIS_EBIS,
  status: '',
  period: {},
  crossDeviceReflectionData: [],
  displayItems: {},
};

export default React.memo(FilterForm);
