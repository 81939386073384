import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dropdown } from 'react-bootstrap';
import { objectOf, shape, func, string } from 'prop-types';
import moment from 'moment';
import { loggerActions } from 'store/logger';
import useCheckPermissionSettingReport from 'services/custom-hooks/useCheckPermissionSettingReport';
import useGoogleAuth from 'services/custom-hooks/useGoogleAuth';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import EbisSingleDatePickerInput from 'views/molecules/single-date-picker-input';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import Spinner from 'views/atoms/loader/Spinner';
import {
  API_DATE_FORMAT,
  GOOGLE_SCOPE_DRIVE_FILE,
  GOOGLE_AUTH_ERRORS,
  GOOGLE_INVALID_CREDENTIALS,
} from 'domain/consts';
import {
  STATUS_PERIOD_MODE_NORMAL,
  STATUS_PERIOD_MODE_PERIODIC,
  STATUS_PERIOD_INTERVAL_WEEK,
  PERIOD_INTERVALS,
  WEEK_DAYS,
  WAIT_COST_OFF,
  WAIT_COST_ON,
  REPORT_TYPE_CHANNEL,
  REPORT_TYPE_CV_ATTRIBUTE,
  REPORT_FIELDS,
  SERVICE_STORE_GOOGLE_DRIVE,
  REPORT_TYPE_CONVERSION,
  REPORT_TYPE_CATEGORY,
  REPORT_TYPE_DETAIL,
} from 'domain/data-export/consts';
import {
  checkIsRegularReport,
  checkIsReportTypePeriod,
} from 'services/data-export/dataExportService';

const {
  PERIOD,
  PERIOD_MODE,
  PERIOD_START,
  PERIOD_END,
  PERIOD_INTERVAL,
  PERIOD_RECEIVE_REPORT_DAY,
  WAIT_COST,
  DISPLAY,
  DISPLAY_TOTAL,
  DISPLAY_ROW_ZERO,
  DISPLAY_HEADER,
  DISPLAY_CLICK_LP_URL,
  GOOGLE_DIR_PATH,
  GOOGLE_SAVE_MODE,
  GOOGLE_ACCOUNT,
  RESULT_ERROR_CODE,
  CUSTOM_FILENAME,
  DIMENSIONS,
} = REPORT_FIELDS;

const OtherSetting = (props) => {
  const dispatch = useDispatch();

  const {
    reportType,
    report,
    error,
    allowDisplayRowZero,
    onChange,
    serviceStore,
    dispatchSetting,
  } = props;
  const { isAllowedSettingWaitCost } = useCheckPermissionSettingReport();
  const tableRef = useRef();

  const {
    [PERIOD_MODE]: periodMode,
    [PERIOD_START]: periodStart,
    [PERIOD_END]: periodEnd,
    [PERIOD_INTERVAL]: periodInterval,
    [PERIOD_RECEIVE_REPORT_DAY]: periodReceiveReportDay,
    [CUSTOM_FILENAME]: customFilename,
    [WAIT_COST]: waitCost,
    [DISPLAY_TOTAL]: displayTotal,
    [DISPLAY_ROW_ZERO]: displayRowZero,
    [DISPLAY_HEADER]: displayHeader,
    [DISPLAY_CLICK_LP_URL]: displayClickLpUrl,
    [GOOGLE_DIR_PATH]: googleDirPath,
    [GOOGLE_SAVE_MODE]: googleSaveMode,
    [GOOGLE_ACCOUNT]: googleAccount,
    [RESULT_ERROR_CODE]: resultErrorCode,
    [DIMENSIONS]: dimensions = [],
  } = report;

  const isRegularReport = checkIsRegularReport(reportType);
  const isReportTypePeriod = checkIsReportTypePeriod(reportType);

  const isReportTypeCv = [
    REPORT_TYPE_CV_ATTRIBUTE,
    REPORT_TYPE_CONVERSION,
  ].includes(reportType);

  const isDisplayZeroOption = ![
    REPORT_TYPE_CV_ATTRIBUTE,
    REPORT_TYPE_CONVERSION,
    REPORT_TYPE_CATEGORY,
    REPORT_TYPE_DETAIL,
  ].includes(reportType);

  const isDisabledDisplayRowZero =
    !isReportTypePeriod &&
    !dimensions.some((id) => allowDisplayRowZero.includes(id));

  const isPeriodicMode = periodMode === STATUS_PERIOD_MODE_PERIODIC;

  const isDisplayWaitCostOption =
    isAllowedSettingWaitCost &&
    isPeriodicMode &&
    ((isRegularReport && !isReportTypeCv) ||
      reportType === REPORT_TYPE_CHANNEL);

  const currentDate = moment();
  const minDate = moment().subtract(2, 'years');
  const maxDate = moment();

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);

  const handleChangeValue = (value, name) => {
    onChange(name, value);
  };

  const handleChangeCheckbox = (name) => {
    handleChangeValue(!report[name], name);
  };

  const handleChangePeriod = (dates, type) => {
    let [start, end] = dates;
    if (start && end && start.isAfter(end)) {
      if (type === PERIOD_START) {
        end = start;
      } else {
        start = end;
      }
    }

    setStartDate(start);
    setEndDate(end);

    handleChangeValue(start?.format(API_DATE_FORMAT), PERIOD_START);
    handleChangeValue(end?.format(API_DATE_FORMAT), PERIOD_END);
  };

  const handleAuthSuccess = useCallback(
    (authInfo) => {
      dispatchSetting({
        type: 'clearErrorByFields',
        payload: [GOOGLE_ACCOUNT],
      });
      dispatchSetting({
        type: 'setDataNormalReport',
        payload: { [GOOGLE_ACCOUNT]: authInfo },
      });
    },
    [dispatchSetting]
  );

  const handleAuthFailure = useCallback(
    (authError) => {
      dispatchSetting({
        type: 'setError',
        payload: {
          [GOOGLE_ACCOUNT]: GOOGLE_AUTH_ERRORS[authError[0].code],
        },
      });
    },
    [dispatchSetting]
  );

  const { openGoogleAuthWindow, isFetchingGoogleInfo } = useGoogleAuth({
    scope: GOOGLE_SCOPE_DRIVE_FILE,
    onSuccess: handleAuthSuccess,
    onFailure: handleAuthFailure,
  });

  const handleGetAuthInfo = () => {
    dispatchSetting({
      type: 'clearErrorByFields',
      payload: [GOOGLE_ACCOUNT, RESULT_ERROR_CODE],
    });
    openGoogleAuthWindow();
  };

  const handleClearAuthInfo = () => {
    dispatch(
      loggerActions.logConfirmModal({
        title: ' ',
        content:
          '設定されたGoogleアカウントの認証を解除します。よろしいですか？',
        cancelBtn: true,
        callback: () => {
          dispatchSetting({
            type: 'setDataNormalReport',
            payload: { [GOOGLE_ACCOUNT]: null, [RESULT_ERROR_CODE]: null },
          });
        },
      })
    );
  };

  useEffect(() => {
    setStartDate((periodStart && moment(periodStart)) || moment());
    setEndDate((periodEnd && moment(periodEnd)) || moment());
  }, [periodStart, periodEnd]);

  useEffect(() => {
    if (isDisabledDisplayRowZero) {
      onChange(DISPLAY_ROW_ZERO, false);
    }
  }, [onChange, isDisabledDisplayRowZero]);

  useEffect(() => {
    if (resultErrorCode === GOOGLE_INVALID_CREDENTIALS) {
      dispatchSetting({
        type: 'setError',
        payload: {
          [RESULT_ERROR_CODE]: GOOGLE_AUTH_ERRORS[resultErrorCode],
        },
      });
    } else {
      dispatchSetting({
        type: 'clearErrorByFields',
        payload: [RESULT_ERROR_CODE],
      });
    }
  }, [dispatchSetting, resultErrorCode]);

  const waitCostRegularReportTooltip =
    '媒体データ取得設定をされている場合、前日分の「表示回数/広告コスト」の媒体データ自動取得処理完了後にレポートを作成します。完了目安は9時~15時です。';
  const waitCostDataExportTooltip =
    '「表示回数/コスト」に関連する項目をレポート指標に設定されており、かつ媒体データ取得設定をされている場合、前日分の「表示回数/広告コスト」の媒体データ自動取得処理完了後にレポートを作成します。完了目安は9時~15時です。';

  return (
    <div className="data-export-setting__item mb-30">
      <div className="data-export-setting__title">その他設定</div>
      <div className="data-export-setting__table" ref={tableRef}>
        <div className="data-export-setting__row">
          <div className="data-export-setting__cell">集計タイミング</div>
          <ErrorTooltipWrapper
            isError={!!error[PERIOD]}
            errorMess={error[PERIOD]}
            ref={tableRef}
          >
            <div className="data-export-setting__cell">
              <div className="d-flex align-items-center">
                <EbisRadio
                  variant="period-mode-normal"
                  name={PERIOD_MODE}
                  label="今回のみ"
                  value={STATUS_PERIOD_MODE_NORMAL}
                  checked={periodMode === STATUS_PERIOD_MODE_NORMAL}
                  onChange={handleChangeValue}
                />
                <ErrorTooltipWrapper
                  isError={!!error[PERIOD_START]}
                  errorMess={error[PERIOD_START]}
                  ref={tableRef}
                >
                  <EbisSingleDatePickerInput
                    date={startDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    error={!!error[PERIOD_START]}
                    disabled={isPeriodicMode}
                    onChange={(date) =>
                      handleChangePeriod([date, endDate], PERIOD_START)
                    }
                  />
                </ErrorTooltipWrapper>
                <span className="single-date-picker-separator">～</span>
                <ErrorTooltipWrapper
                  isError={!!error[PERIOD_END]}
                  errorMess={error[PERIOD_END]}
                  ref={tableRef}
                >
                  <EbisSingleDatePickerInput
                    date={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    error={!!error[PERIOD_END]}
                    disabled={isPeriodicMode}
                    onChange={(date) =>
                      handleChangePeriod([startDate, date], PERIOD_END)
                    }
                  />
                </ErrorTooltipWrapper>
              </div>
              <div className="d-flex align-items-center">
                <EbisRadio
                  variant="period-mode-period"
                  name={PERIOD_MODE}
                  label="定期的に作成"
                  value={STATUS_PERIOD_MODE_PERIODIC}
                  checked={isPeriodicMode}
                  onChange={handleChangeValue}
                />
                <Dropdown
                  onSelect={(value) =>
                    handleChangeValue(value, PERIOD_INTERVAL)
                  }
                >
                  <Dropdown.Toggle
                    size="sm"
                    variant="select"
                    disabled={periodMode === STATUS_PERIOD_MODE_NORMAL}
                  >
                    {PERIOD_INTERVALS[periodInterval]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(PERIOD_INTERVALS).map((key) => (
                      <Dropdown.Item
                        key={key}
                        eventKey={key}
                        active={key === periodInterval}
                      >
                        {PERIOD_INTERVALS[key]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {isPeriodicMode &&
                  periodInterval === STATUS_PERIOD_INTERVAL_WEEK && (
                    <div className="d-flex align-items-center">
                      <DragonBall>
                        指定された曜日に毎週「前日より前週末のデータ」でレポートが作成されます。
                      </DragonBall>
                      {Object.keys(WEEK_DAYS).map((key) => {
                        const value = Number(key);
                        return (
                          <EbisRadio
                            key={value}
                            variant="week-day"
                            name={PERIOD_RECEIVE_REPORT_DAY}
                            label={WEEK_DAYS[value]}
                            value={value}
                            checked={value === periodReceiveReportDay}
                            onChange={handleChangeValue}
                          />
                        );
                      })}
                    </div>
                  )}
              </div>
            </div>
          </ErrorTooltipWrapper>
        </div>
        {isDisplayWaitCostOption && (
          <div className="data-export-setting__row">
            <div className="data-export-setting__cell">
              定期レポートの作成方式
            </div>
            <ErrorTooltipWrapper
              isError={!!error[WAIT_COST]}
              errorMess={error[WAIT_COST]}
              ref={tableRef}
            >
              <div className="data-export-setting__cell">
                <div className="d-flex">
                  <EbisRadio
                    name={WAIT_COST}
                    label="出力時間優先で作成"
                    value={WAIT_COST_OFF}
                    tooltip="前日分の「表示回数/広告コスト」取得を待たずにレポート作成を開始します。完了目安は9時です。"
                    checked={waitCost === WAIT_COST_OFF}
                    onChange={handleChangeValue}
                  />
                </div>
                <div className="d-flex">
                  <EbisRadio
                    name={WAIT_COST}
                    label="媒体データ取得完了後に作成"
                    value={WAIT_COST_ON}
                    tooltip={
                      isRegularReport
                        ? waitCostRegularReportTooltip
                        : waitCostDataExportTooltip
                    }
                    checked={waitCost === WAIT_COST_ON}
                    onChange={handleChangeValue}
                  />
                </div>
              </div>
            </ErrorTooltipWrapper>
          </div>
        )}
        <div className="data-export-setting__row">
          <div className="data-export-setting__cell">レポートフォーマット</div>
          <ErrorTooltipWrapper
            isError={!!error[DISPLAY]}
            errorMess={error[DISPLAY]}
            ref={tableRef}
          >
            <div className="data-export-setting__cell">
              {!isReportTypeCv && (
                <div className="d-flex">
                  <EbisCheckbox
                    name={DISPLAY_TOTAL}
                    text="合計行を表示する"
                    checked={displayTotal}
                    changeSelectBoxValue={handleChangeCheckbox}
                  />
                </div>
              )}
              {isDisplayZeroOption && (
                <div className="d-flex">
                  <EbisCheckbox
                    name={DISPLAY_ROW_ZERO}
                    text="時間軸・潜伏期間・接触回数で値が0件の行を表示する"
                    checked={displayRowZero}
                    disabled={isDisabledDisplayRowZero}
                    changeSelectBoxValue={handleChangeCheckbox}
                  />
                </div>
              )}
              <div className="d-flex">
                <EbisCheckbox
                  name={DISPLAY_HEADER}
                  text="ヘッダを非表示にする"
                  checked={displayHeader}
                  changeSelectBoxValue={handleChangeCheckbox}
                />
              </div>
            </div>
          </ErrorTooltipWrapper>
        </div>
        {[REPORT_TYPE_CHANNEL, REPORT_TYPE_CV_ATTRIBUTE].includes(
          reportType
        ) && (
          <div className="data-export-setting__row">
            <div className="data-export-setting__cell">表示内容オプション</div>
            <ErrorTooltipWrapper
              isError={!!error[DISPLAY_CLICK_LP_URL]}
              errorMess={error[DISPLAY_CLICK_LP_URL]}
              ref={tableRef}
            >
              <div className="data-export-setting__cell">
                <div className="d-flex">
                  <EbisCheckbox
                    name={DISPLAY_CLICK_LP_URL}
                    text="(チャネル種別が広告クリックの)ランディングページURLを表示する"
                    tooltip={
                      <>
                        本オプションがONのとき、
                        <br />
                        入力した表示回数・コストの行と
                        <br />
                        ランディングページを保有する行は別行に分かれます。
                      </>
                    }
                    checked={displayClickLpUrl}
                    changeSelectBoxValue={handleChangeCheckbox}
                  />
                </div>
              </div>
            </ErrorTooltipWrapper>
          </div>
        )}
        {serviceStore === SERVICE_STORE_GOOGLE_DRIVE && (
          <div className="data-export-setting__row">
            <div className="data-export-setting__cell">出力詳細設定</div>
            <ErrorTooltipWrapper
              isError={!!error[DISPLAY_CLICK_LP_URL]}
              errorMess={error[DISPLAY_CLICK_LP_URL]}
              ref={tableRef}
            >
              <div className="data-export-setting__cell">
                <div>
                  <div className="d-flex">
                    <div className="d-flex align-items-center mb-5">
                      <div className="data-export-setting_cell-title__output">
                        Googleアカウント認証
                      </div>
                      <ErrorTooltipWrapper
                        isError={
                          !!error[GOOGLE_ACCOUNT] || !!error[RESULT_ERROR_CODE]
                        }
                        errorMess={error[GOOGLE_ACCOUNT]}
                        ref={tableRef}
                      >
                        <div className="data-export-setting__google-area">
                          {isFetchingGoogleInfo ? (
                            <div className="d-flex align-items-center">
                              認証中
                              <Spinner />
                            </div>
                          ) : (
                            <div className="data-export-setting__google-area">
                              {googleAccount?.user?.email ? (
                                <div className="d-flex align-items-center">
                                  <div>{googleAccount.user.email}</div>
                                  <Button
                                    variant="link"
                                    className="flex"
                                    size="sm"
                                    onClick={handleClearAuthInfo}
                                  >
                                    クリア
                                  </Button>
                                </div>
                              ) : (
                                <div className="d-flex align-items-center">
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={handleGetAuthInfo}
                                    className="flex mr-10"
                                  >
                                    Google認証
                                  </Button>
                                  {!error[GOOGLE_ACCOUNT] && (
                                    <div>
                                      ※Googleアカウントの認証を行ってください
                                    </div>
                                  )}
                                </div>
                              )}
                              {error[RESULT_ERROR_CODE] && (
                                <div className="data-export-setting__error-text">
                                  {error[RESULT_ERROR_CODE]}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </ErrorTooltipWrapper>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-5">
                    <div className="data-export-setting_cell-title__output">
                      出力フォルダ設定
                    </div>
                    <FormInput
                      name={GOOGLE_DIR_PATH}
                      value={googleDirPath}
                      error={error[GOOGLE_DIR_PATH]}
                      placeholder=""
                      onChange={onChange}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="data-export-setting_cell-title__output">
                      出力形式
                    </div>
                    <div className="d-flex align-items-center">
                      <EbisRadio
                        name={GOOGLE_SAVE_MODE}
                        label="新規作成モード"
                        value="new"
                        checked={googleSaveMode === 'new'}
                        onChange={handleChangeValue}
                      />
                      <EbisRadio
                        name={GOOGLE_SAVE_MODE}
                        label="上書きモード"
                        value="override"
                        checked={googleSaveMode === 'override'}
                        onChange={handleChangeValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ErrorTooltipWrapper>
          </div>
        )}
        {isRegularReport && (
          <div className="data-export-setting__row">
            <div className="data-export-setting__cell">ファイル名</div>
            <div className="data-export-setting__cell">
              <div className="d-flex align-items-center">
                <FormInput
                  name={CUSTOM_FILENAME}
                  value={customFilename}
                  placeholder="出力ファイル名を入力"
                  error={error[CUSTOM_FILENAME]}
                  onChange={onChange}
                />
                <div className="data-export-setting__cell--custom_filename">
                  _YYYYMMDD.csv
                  <DragonBall>
                    「YYYYMMDD」部分はレポートが出力された日付に置き換わります。
                  </DragonBall>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

OtherSetting.propTypes = {
  reportType: string.isRequired,
  report: objectOf(shape({})).isRequired,
  error: objectOf(shape({})),
  allowDisplayRowZero: shape([]),
  onChange: func,
  serviceStore: string.isRequired,
  dispatchSetting: func.isRequired,
};

OtherSetting.defaultProps = {
  error: {},
  allowDisplayRowZero: [],
  onChange: () => {},
};

export default React.memo(OtherSetting);
