import helper from 'store/helper';
import loggerConstants from 'store/logger/constant';
import types from './types';

const requestStart = (screenId) =>
  helper.createAction(types.REQUEST_START, { screenId });
const requestFailed = (error) =>
  helper.createAction(
    types.REQUEST_FAILED,
    { error },
    loggerConstants.SCOPE_DONT_SHOW
  );
const requestError = (error) =>
  helper.createAction(types.REQUEST_ERROR, { error });
const uploadSucceeded = () => helper.createAction(types.UPLOAD_SUCCEEDED);

export default {
  requestStart,
  requestFailed,
  requestError,
  uploadSucceeded,
};
