import { TERMINAL_TYPE } from 'domain/fields';
import {
  CNT_PV,
  CNT_SESSION,
  PERIOD,
  CNT_UU,
  CNT_CV_TOTAL,
  CNT_CV_DETAIL,
  SORT_DIRECTION_ASC,
  DISPLAY_GROUP_RADIO_OTIONS,
  getItems,
  getAll as displayItemsGetAll,
  getDefault,
} from './display-items';

const SettingDisplayItems = {
  groupList: {
    [TERMINAL_TYPE]: DISPLAY_GROUP_RADIO_OTIONS,
    [PERIOD]: DISPLAY_GROUP_RADIO_OTIONS,
  },
  displayFreezeList: [PERIOD, TERMINAL_TYPE],
  orderList: {
    [PERIOD]: 1,
    [TERMINAL_TYPE]: 2,
    [CNT_PV]: 3,
    [CNT_SESSION]: 4,
    [CNT_UU]: 5,
    [CNT_CV_DETAIL]: 6,
    [CNT_CV_TOTAL]: 7,
  },
  displayDefaultList: [
    PERIOD,
    CNT_PV,
    CNT_SESSION,
    CNT_CV_TOTAL,
    CNT_CV_DETAIL,
  ],
  sortDefaultList: {
    [PERIOD]: SORT_DIRECTION_ASC,
  },
  requiredList: [PERIOD, CNT_PV, CNT_SESSION],
};

export const getAll = () => {
  const displayItemsBase = displayItemsGetAll();

  return getItems({
    ...SettingDisplayItems,
    displayItemsBase: {
      ...displayItemsBase,
      [CNT_PV]: {
        ...displayItemsBase[CNT_PV],
        title: 'PV',
      },
      [CNT_SESSION]: {
        ...displayItemsBase[CNT_SESSION],
        title: 'SU',
      },
      [PERIOD]: {
        ...displayItemsBase[PERIOD],
      },
      [TERMINAL_TYPE]: {
        ...displayItemsBase[TERMINAL_TYPE],
        title: '期間＋デバイス軸',
        titleTable: 'デバイス',
      },
    },
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return getDefault(displayItemsBase);
};
