import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import actions from 'store/logger/actions';
import SystemCrashContainer from 'views/molecules/modal/SystemCrashContainer';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';

import './error.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, showModal: true };
  }

  componentDidCatch(error, errorInfo) {
    const { logUIError } = this.props;

    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // dispatch errors to store
    logUIError(errorInfo);
  }

  handleConfirmBtn() {
    this.setState((prevState) => ({ ...prevState, showModal: false }));
    window.location.reload();
  }

  render() {
    const { error, errorInfo, showModal } = this.state;
    const { children } = this.props;

    if (error?.name === 'ChunkLoadError') {
      return (
        <ConfirmModal
          isShow={showModal}
          title="新バージョンがリリースされました"
          jsxContent="引き続きお使いいただくために、画面の再読み込みをお願いします。"
          onSubmit={(e) => this.handleConfirmBtn(e)}
          cancelBtn={false}
          confirmText="OK"
        />
      );
    }

    if (errorInfo) {
      // Error path
      return (
        <div className="error-container">
          <SystemCrashContainer
            showErrorNotify={showModal}
            latestError={JSON.stringify(
              error,
              Object.getOwnPropertyNames(error)
            )}
            handleConfirm={(e) => this.handleConfirmBtn(e)}
          />
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorBoundary.defaultProps = {
  logUIError: () => {},
  children: () => {},
};

ErrorBoundary.propTypes = {
  logUIError: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  logUIError: (payload) => dispatch(actions.logUIError(payload)),
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
