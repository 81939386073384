import { USER_ENDPOINT } from 'services/routes/constants';
import Api from './Api';

const AuthApiFactory = () => {
  const config = {
    baseURL: process.env.REACT_APP_ID_EBIS_API_HOST,
  };
  const url = '/token';
  const userUrl = USER_ENDPOINT;
  return {
    fetchToken: async (data) => Api.post(url, data, config),
    fetchRefreshToken: async (data) => Api.post(url, data, config),
    fetchUser: async (conf = {}) => Api.get(userUrl, conf),
  };
};

const AuthApi = AuthApiFactory();
export default AuthApi;
