import { communicationStatus } from 'services/utils';
import helper from 'store/helper';
import types from './types';

export const initialState = {
  filters: {},
  displayItems: {},
  displayItemsStatus: communicationStatus.IDLE,
};

const updateFilters = (state, action) => {
  const { items } = action.payload;
  return {
    ...state,
    filters: {
      ...state.filters,
      items,
    },
  };
};

const fetchDisplayItemsSucceeded = (state, action) => {
  const { items } = action.payload;
  return {
    ...state,
    displayItems: {
      ...state.displayItems,
      items,
    },
  };
};

const setStatusDisplayItems = (state, action) => {
  return {
    ...state,
    displayItemsStatus: action.payload.status,
  };
};

const AdManagementSettingsReducer = helper.createReducer(initialState, {
  [types.UPDATE_FILTERS]: updateFilters,
  [types.FETCH_DISPLAY_ITEMS_SUCCEEDED]: fetchDisplayItemsSucceeded,
  [types.CREATE_DISPLAY_ITEMS_SUCCEEDED]: fetchDisplayItemsSucceeded,
  [types.SET_STATUS_DISPLAY_ITEMS]: setStatusDisplayItems,
});

export default AdManagementSettingsReducer;
