import { PRIORITY_AXIS_EBIS, PRIORITY_AXIS_MEDIA_SYNC } from 'domain/consts';
import React from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../dragonball/dragon-ball.scss';
import { MEDIA_SYNC_NM_LINKAGE_LINK } from 'services/routes/constants';

const EbisAxisTooltip = ({ axis, isOpenTooltip, handleStayOpenTooltip, targetRef }) => {
  const ebisMessage = '広告管理画面で登録した広告情報を表示しています。';
  const syncMessage = (
    <>
      この広告IDには複数の媒体広告IDが紐づいているため、1件目の媒体情報のみ表示しています。できるだけ媒体広告IDと1対1で紐づくようにご設定ください。詳しくは
      <a
        href={MEDIA_SYNC_NM_LINKAGE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        こちら
      </a>
      。
    </>
  );

  let message = '';
  if (axis === PRIORITY_AXIS_EBIS) {
    message = ebisMessage;
  }
  if (axis === PRIORITY_AXIS_MEDIA_SYNC) {
    message = syncMessage;
  }

  return message !== '' ? (
    <Overlay
      show={isOpenTooltip}
      onToggle={(isShow) => handleStayOpenTooltip(isShow)}
      target={targetRef.current}
      placement="top"
    >
      <Tooltip
        onMouseOver={() => handleStayOpenTooltip(true)}
        onMouseOut={() => handleStayOpenTooltip(false)}
        className="dragonball-tooltip variant-base"
      >
        {message}
      </Tooltip>
    </Overlay>
  ) : null;
};

EbisAxisTooltip.propTypes = {
  axis: PropTypes.string.isRequired,
  targetRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }).isRequired,
  isOpenTooltip: PropTypes.bool.isRequired,
  handleStayOpenTooltip: PropTypes.func.isRequired,
};

export default EbisAxisTooltip;
