import {
  EMAIL_NOTIFICATION,
  EXECUTED_DATE,
  REFRESH_TOKEN,
  PAUSE_FLAG,
  REGIST_DATE,
  SEND_MAIL_USERS,
  SITE_URL,
  STATUS,
  UPDATE_BY,
  UPDATE_DATE,
  EMAIL,
  COMPANY_NAME,
  USER_ID,
  CHARGE_NAME,
} from 'domain/fields';
import * as LABELS from 'domain/labels';
import iconStop from 'assets/images/icon-stop.svg';
import iconStart from 'assets/images/icon-started.svg';

export const ACTIVE_FLAG = true;
export const INACTIVE_FLAG = false;
export const SC_PAUSE_FLAG = {
  [INACTIVE_FLAG]: {
    title: '連携',
    value: INACTIVE_FLAG,
    icon: iconStart,
  },
  [ACTIVE_FLAG]: {
    title: '一時停止',
    value: ACTIVE_FLAG,
    icon: iconStop,
  },
};

export const HEADER = [
  {
    field: 'rowId',
    fieldResponse: 'id',
    name: '',
    type: 'checkbox',
    isEdit: true,
    isFreeze: true,
    minWidth: '77px',
  },
  {
    field: PAUSE_FLAG,
    name: '',
    isFreeze: true,
    sort: 'none',
    width: '80px',
  },
  {
    field: STATUS,
    name: '最終連携結果',
    isFreeze: true,
    minWidth: '140px',
  },
  {
    field: SITE_URL,
    name: 'プロパティ',
    minWidth: '200px',
    isFreeze: true,
  },
  {
    field: UPDATE_BY,
    name: '設定ユーザーID',
    minWidth: '150px',
  },
  {
    field: EXECUTED_DATE,
    name: '最終連携日時',
    minWidth: '200px',
  },
  {
    field: REGIST_DATE,
    name: LABELS.REGIST_DATE,
    minWidth: '200px',
  },
  {
    field: UPDATE_DATE,
    name: LABELS.UPDATE_DATE,
    minWidth: '200px',
  },
];

export const HEADER_SEND_MAIL_USERS = [
  {
    isFreeze: true,
    field: 'rowId',
    width: '46px',
    type: 'checkbox',
  },
  {
    isFreeze: true,
    field: USER_ID,
    name: 'ユーザーID',
    minWidth: '133px',
  },
  {
    field: COMPANY_NAME,
    name: '会社名',
    minWidth: '152px',
  },
  {
    field: CHARGE_NAME,
    name: '名前',
    width: '159px',
  },
  {
    field: EMAIL,
    name: 'メールアドレス',
    width: 'max-content',
    minWidth: '225px',
  },
  {
    field: 'agent',
    name: 'エージェント',
    width: '107px',
    variant: 'icon-check',
  },
];

export const HEADER_CONFIRM_PAUSE_FLAG_MODAL = [
  {
    field: SITE_URL,
    name: 'プロパティ',
    width: '190px',
  },
  {
    field: UPDATE_BY,
    name: '設定ユーザーID',
    width: '170px',
  },
  {
    field: EXECUTED_DATE,
    name: '最終連携日時',
    width: '175px',
  },
];

export const NO_EXIST_EMAIL = -1;

export const MODAL_IDS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  ERROR: 'error',
  PAUSE_FLAG: 'pause_flag',
};

export const MAX_CREATE_SC_SETTING = 50;
export const SC_SETTING_ID = 'id';

export const LIST_STATUS_KEY = 'list';
export const SETTING_STATUS_KEY = 'setting';
export const MASTERDATA_STATUS_KEY = 'masterData';

export const STATUS_TYPE_PROCESSING = 1;
export const STATUS_TYPE_SUCCESS = 2;
export const STATUS_TYPE_ERR_SYS = 3;
export const STATUS_TYPE_ERR_AUTH = 4;

export const SC_STATUS_LABELS = {
  [STATUS_TYPE_PROCESSING]: '',
  [STATUS_TYPE_SUCCESS]: '成功',
  [STATUS_TYPE_ERR_SYS]: 'エラー',
  [STATUS_TYPE_ERR_AUTH]: '認証エラー',
};

// SC Setting
export const STEP_BASIC_CONFIGURATION = 1;
export const STEP_CONFIRMATION = 2;
export const STEP_REFLECTION = 3;

export const STEPS_SC_SETTING_FORM = [
  {
    step: STEP_BASIC_CONFIGURATION,
    label: '基本設定',
    active: false,
  },
  {
    step: STEP_CONFIRMATION,
    label: '確認',
    active: false,
  },
  {
    step: STEP_REFLECTION,
    label: '分析画面への反映',
    active: false,
  },
];

export const GOOGLE = 'gg';

export const acceptedMediumIds = [GOOGLE];
export const acceptedEmailNotifications = [true, false];

export const CREDENTIAL_OBJ = {
  [GOOGLE]: {
    [REFRESH_TOKEN]: {
      step: STEP_BASIC_CONFIGURATION,
      value: '',
    },
  },
};

export const FIELD_SC_SETTING_FORM = {
  [SITE_URL]: {
    label: 'プロパティ',
    value: '',
  },
  [EMAIL_NOTIFICATION]: {
    label: '認証エラー発生時のメール通知',
    value: true,
  },
  [SEND_MAIL_USERS]: {
    label: '送信先',
    value: [],
  },
};

export const CLIENT_ID = 'client_id';

const GOOGLE_CONVERSION_LABEL = 'Google 拡張コンバージョン';

export const MEDIUM_TITLE = {
  [GOOGLE]: GOOGLE_CONVERSION_LABEL,
};

export const MAX_LIMIT_SEARCH = 501;

export const DEFAULT_SELECT = { id: 0, name: '選択してください' };
