import React from 'react';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';
import './icon.scss';

const CommonbarIcon = (props) => {
  const { name, actived, onClick } = props;

  const cls = classNames({
    'nav-link': true,
    'nav-link--icon': true,
    active: actived,
  });

  return (
    <div className={cls} onClick={onClick} aria-hidden="true">
      <div>
        <div className="d-flex justify-content-center">
          <span className="icon-svg icon-setting" />
        </div>
        <div>{name}</div>
      </div>
    </div>
  );
};

CommonbarIcon.propTypes = {
  name: string.isRequired,
  onClick: func.isRequired,
  actived: bool,
};

CommonbarIcon.defaultProps = {
  actived: false,
};

export default CommonbarIcon;
