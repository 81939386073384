/**
 * Service for AD Media Type, AD Group 1, AD Group 2
 */
import {
  ADD_NEW,
  EDIT,
  REMOVE,
  UPLOAD,
  TYPE_MEDIA_TYPE,
  TYPE_AD_GROUP1,
  TYPE_AD_GROUP2,
  FIELD_NAME,
  FIELD_ID,
  VALUE_NOT_ALLOWED,
  FIELD_ATTRIBUTE,
  URL_MAX_LENGTH,
  HAS_COSTLINKING,
  HAS_POSTBACK,
  HAS_AD_LINKING,
  HAS_MEDIA_SYNC_SETTING,
  LINE,
  FIELD_LABEL,
} from 'domain/ad-management/media-group/constants';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { AD_GROUP1, AD_GROUP2, AGENCY_ID } from 'domain/fields';
import { getErrorMessageByCode } from 'services/utils';
import {
  DELETE_ERROR,
  inputMediaError,
  msgLineError,
  inputUrlError,
} from 'domain/ad-management/media-group/errorMessages';
import {
  FILTER_KEY_MEDIA_GROUP_AD_GROUP1_NAME,
  FILTER_KEY_MEDIA_GROUP_AD_GROUP2_NAME,
} from 'services/consts';
import {
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  AD_MANAGEMENT_MEDIA_TYPE,
} from 'services/routes/constants';

const allowFields = [FIELD_NAME];
/**
 * Get Item type name
 */
const getTypeName = (type, masterData) => {
  if (type === TYPE_MEDIA_TYPE) {
    return '媒体種別';
  }
  if (type === TYPE_AD_GROUP1) {
    return masterData[AD_GROUP1] ? masterData[AD_GROUP1] : '広告グループ01';
  }
  if (type === TYPE_AD_GROUP2) {
    return masterData[AD_GROUP2] ? masterData[AD_GROUP2] : '広告グループ02';
  }
  return '';
};

const getFieldLabel = (masterData) => {
  const labelMedia = getTypeName(TYPE_MEDIA_TYPE, masterData);
  const labelGroup1 = getTypeName(TYPE_AD_GROUP1, masterData);
  const labelGroup2 = getTypeName(TYPE_AD_GROUP2, masterData);
  return {
    [AD_MANAGEMENT_MEDIA_TYPE]: {
      ...FIELD_LABEL[TYPE_MEDIA_TYPE],
      media_name: { label: labelMedia },
      media_id: { label: labelMedia },
    },
    [AD_MANAGEMENT_AD_GROUP_1]: {
      ...FIELD_LABEL[TYPE_AD_GROUP1],
      ad_group1_name: { label: labelGroup1 },
      ad_group1_id: { label: labelGroup1 },
    },
    [AD_MANAGEMENT_AD_GROUP_2]: {
      ...FIELD_LABEL[TYPE_AD_GROUP2],
      ad_group2_name: { label: labelGroup2 },
      ad_group2_id: { label: labelGroup2 },
    },
  };
};

/**
 * Customize tabs label (AD Group1 / AD Group2)
 * @param {array} tabs
 * @param {object} masterData
 * @returns
 */
const mergeTabsLabel = (tabs, masterData) => {
  if (isEmpty(tabs)) {
    return [];
  }
  if (!masterData) {
    return tabs;
  }
  return tabs.map((item) => {
    return {
      ...item,
      label: masterData[item.key] ? masterData[item.key] : item.label,
    };
  });
};

const formatNumber = (value) => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const getDeleteErrorContent = (error, label, action) => {
  const title =
    action === EDIT ? 'データ編集に失敗しました' : 'データ削除に失敗しました';
  const ids =
    error.length === 1
      ? error[0].metadata.param[0]
      : error.map((item) => item.metadata.param[0]).join(', ');
  return {
    [error[0].code]: {
      title,
      message: getErrorMessageByCode(error[0], DELETE_ERROR, {
        id: ids,
        label,
      }),
    },
  };
};

const isValidUrl = (url) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

const validatePostback = (value) => {
  // Check max length
  if (value && value.length > URL_MAX_LENGTH) {
    return {
      result: false,
      message: getErrorMessageByCode({ code: 'URL_MAX_LENGTH' }, inputUrlError),
    };
  }

  if (Boolean(value) === true && !isValidUrl(value)) {
    return {
      result: false,
      message: getErrorMessageByCode({ code: 'INVALID_URL' }, inputUrlError),
    };
  }
  return { result: true, message: '' };
};

/**
 * Validate field by name
 * @returns {*}
 * @param action
 * @param value
 * @param label
 * @param tab
 */
const validate = (action, value, label, tab) => {
  if (Boolean(value) === false) {
    return {
      result: false,
      message: getErrorMessageByCode(
        { code: 'VALUE_IS_EMPTY' },
        inputMediaError,
        { label }
      ),
    };
  }

  // Check media name is default name
  if (value === VALUE_NOT_ALLOWED && action === EDIT) {
    return {
      result: false,
      message: getErrorMessageByCode(
        { code: 'VALUE_NOT_ALLOWED' },
        inputMediaError,
        { label }
      ),
    };
  }

  // Check max length
  const maxLength = FIELD_ATTRIBUTE[tab][action];
  if (maxLength && value && value.length > maxLength) {
    return {
      result: false,
      message: getErrorMessageByCode(
        { code: 'OVER_MAX_LENGTH' },
        inputMediaError,
        { label, length: maxLength }
      ),
    };
  }

  // Check max line
  const arrMediaNames = value.split('\n');
  const maxLine = FIELD_ATTRIBUTE[tab][LINE];
  if (arrMediaNames && arrMediaNames.length > maxLine) {
    return {
      result: false,
      message: getErrorMessageByCode(
        { code: 'OVER_MAX_ITEM' },
        inputMediaError,
        {
          label,
          length: formatNumber(maxLine),
        }
      ),
    };
  }

  // Check duplicate value
  const nonEmptyElementArr = arrMediaNames
    .map((item) => (Boolean(item.trim()) === true ? item.trim() : item))
    .filter((item) => Boolean(item.trim()) === true);
  const uniqueArrMediaNames = [...new Set(nonEmptyElementArr)];
  if (nonEmptyElementArr.length !== uniqueArrMediaNames.length) {
    return {
      result: false,
      message: getErrorMessageByCode(
        { code: 'VALUE_IS_DUPLICATED' },
        inputMediaError,
        { label }
      ),
    };
  }

  return { result: true, message: '' };
};

// Validate media name line-by-line
const validateLines = (arrLine, label, tab) => {
  return arrLine.map((value, index) => {
    const line = `${index + 1}行目`;

    // Check field is empty
    if (Boolean(value.trim()) === false) {
      return {
        result: false,
        line,
        message: getErrorMessageByCode(
          { code: 'LINE_VALUE_IS_EMPTY' },
          msgLineError,
          { label }
        ),
      };
    }

    // Check max length
    const maxLength = FIELD_ATTRIBUTE[tab][EDIT];
    if (value && value.length > maxLength) {
      return {
        result: false,
        line,
        message: getErrorMessageByCode(
          { code: 'LINE_OVER_MAX_LENGTH_INPUT' },
          msgLineError,
          { label, length: maxLength }
        ),
      };
    }

    // Check media name is default name
    if (value === VALUE_NOT_ALLOWED) {
      return {
        result: false,
        line,
        message: getErrorMessageByCode(
          { code: 'LINE_VALUE_NOT_ALLOWED' },
          msgLineError,
          { label }
        ),
      };
    }

    return { result: true, line: '', message: '' };
  });
};

// Check validate all field/line
const checkValidateResult = (data) =>
  Object.values(data).every((item) => item.result === true);

const isApiValidationError = ({ data, status }) => {
  if (status !== 400) {
    return false;
  }
  const { errors } = data;
  if (errors.length > 0) {
    return errors.some((error) => {
      return allowFields.includes(error.field);
    });
  }
  return false;
};

const getIdsString = (removableList) => {
  const ids = [];
  Object.values(removableList).forEach((item) => {
    ids.push(item.id);
  });
  return ids.toString();
};

/**
 * List header for table
 * @param type
 * @param {array} selectedRows
 * @param {boolean} isCheckedAll
 * @param {object} sort
 * @param masterData
 * @param {boolean} hasPostBack
 * @returns {array}
 */
const getHeader = (
  type,
  selectedRows,
  isCheckedAll,
  sort,
  masterData,
  hasPostBack = false
) => {
  const headerData = [
    {
      text: '',
      name: 'check_all',
      isDimension: true,
      isEdit: true,
      sort: false,
      checked: selectedRows.length > 0,
      variant: selectedRows.length > 0 && !isCheckedAll ? 'mixed' : '',
    },
    {
      text: 'ID',
      name: 'id',
      isDimension: true,
      sort: true,
    },
    {
      text: getTypeName(type, masterData),
      name: 'name',
      isDimension: false,
      sort: true,
    },
  ];

  if (type === TYPE_MEDIA_TYPE && hasPostBack) {
    headerData.push({
      text: 'ポストバック',
      name: 'postbacks',
      isDimension: false,
      sort: false,
      children: [
        {
          text: 'CV名',
          name: 'cv_name',
          isDimension: false,
          sort: false,
        },
        {
          text: 'URL',
          name: 'url',
          isDimension: false,
          sort: false,
        },
      ],
    });
  }

  if (sort) {
    return headerData.map((item) => {
      const newObj = { ...item };
      if (newObj.name === sort.field) {
        newObj.sort = sort.direction;
      }
      return newObj;
    });
  }

  return headerData;
};
/**
 * Get Data By Key
 * @param items
 * @param {string} key Example 'url', 'cv_name'
 * @returns {array} of string
 */
const getDataByKey = (items, key) => {
  if (isEmpty(items)) {
    return [];
  }
  return items.map((item) => {
    return item[key];
  });
};

/**
 * Format array For Table Display
 * @param {array} list
 * @param {array} selectedRows Array of ID
 * @param {boolean} hasPostBack
 * @returns
 */
const formatForTableDisplay = (
  list,
  selectedRows,
  hasPostBack = false,
  agencyId = ''
) => {
  if (isEmpty(list)) {
    return [];
  }
  if (hasPostBack) {
    return list.map((item) => {
      return {
        ...item,
        rowId: item[FIELD_ID],
        selected: selectedRows.includes(item[FIELD_ID]),
        cv_name: getDataByKey(item.postbacks, 'cv_name'),
        url: getDataByKey(item.postbacks, 'url'),
      };
    });
  }

  return list.map((item) => {
    const isDisabled = agencyId ? item[AGENCY_ID] !== agencyId : false;
    return {
      ...item,
      rowId: item[FIELD_ID],
      selected: selectedRows.includes(item[FIELD_ID]),
      isDisabledEdit: isDisabled,
      isDisabledCheckbox: isDisabled,
    };
  });
};

const findByMediaId = (id, items) => {
  if (id) {
    const index = findIndex(
      items,
      (item) => item[FIELD_ID].toString() === id.toString()
    );

    return index !== -1 ? items[index] : {};
  }

  return {};
};

/**
 * Filter by url is not empty
 * @param {array} postbacks Array object
 * @returns array Array object with url not empty
 */
const filterByUrlNotEmpty = (postbacks) => {
  return postbacks.map((postback) => {
    return { cv_id: postback.cv_id, url: postback.url };
  });
};

const getListByKey = (list, key, matchValue) => {
  if (isEmpty(list) || !isArray(list)) {
    return [];
  }
  return list.filter((item) => {
    if (key in item) {
      return item[key] === matchValue;
    }
    return !matchValue; // 検索キーが存在しない場合は、マッチしないものが全配列で、マッチするものが空と判断。
  });
};
const hasAdLinking = (deleteList) => {
  if (isEmpty(deleteList) || !isArray(deleteList)) {
    return false;
  }
  const listAdLinking = getListByKey(deleteList, HAS_AD_LINKING, true);
  return listAdLinking.length > 0;
};
const hasMediaSyncSetting = (deleteList) => {
  if (isEmpty(deleteList) || !isArray(deleteList) || isEmpty(deleteList.find((item) => HAS_MEDIA_SYNC_SETTING in item))) {
    return false;
  }
  const listMediaSyncSetting = getListByKey(deleteList, HAS_MEDIA_SYNC_SETTING, true);
  return listMediaSyncSetting.length > 0;
};

const getDeleteForceItems = (deleteList) => {
  if (isEmpty(deleteList) || !isArray(deleteList)) {
    return [];
  }
  return deleteList.filter(
    (item) => item[HAS_POSTBACK] || item[HAS_COSTLINKING]
  );
};

const getDeleteWarningMessage = (hasCostLinking, hasPostBack) => {
  if (hasCostLinking && hasPostBack) {
    return [
      '下記の媒体種別に紐づく媒体データ連携・ポストバック設定が解除',
      'されます。',
      '解除されると、媒体データ連携で過去に取得した広告コスト／表示回数も削除されます。',
      'また、解除したポストバック設定は復元できません。',
      '連携・設定が解除されても問題ありません',
    ];
  }
  if (hasCostLinking) {
    return [
      '下記の媒体種別に紐づく媒体データ連携が解除',
      'されます。',
      '過去に取得した広告コスト／表示回数も削除されます。',
      '',
      '連携が解除されても問題ありません',
    ];
  }
  if (hasPostBack) {
    return [
      '下記の媒体種別に紐づくポストバック設定が解除',
      'されます。',
      '一度解除した設定は復元できません。',
      '',
      '設定が解除されても問題ありません',
    ];
  }
  return [];
};
/**
 * Get Modal title
 * @param {string} type data type
 * @param {string} action
 * @param {object} masterData
 * @returns
 */
const getModalTitle = (type, action, masterData) => {
  const typeName = getTypeName(type, masterData);
  switch (action) {
    case ADD_NEW:
      return `${typeName}を追加`;
    case EDIT:
      return `${typeName}を編集`;
    case REMOVE:
      return `${typeName}を削除しますか？`;
    case UPLOAD:
      return `${typeName}のCSV一括登録`;
    default:
      return '';
  }
};
/**
 * Customize filterableList for Screens Media Types, AD Group 1, AD Group 2(filter type, label)
 * @param {object} originFilters
 * @param {object} display
 * @returns
 */
const customFilters = (originFilters, display) => {
  const filterableList = { ...originFilters };
  Object.keys(filterableList).forEach((key) => {
    switch (key) {
      case FILTER_KEY_MEDIA_GROUP_AD_GROUP1_NAME:
        filterableList[key].title = isEmpty(display)
          ? filterableList[key].title
          : display[AD_GROUP1];
        break;
      case FILTER_KEY_MEDIA_GROUP_AD_GROUP2_NAME:
        filterableList[key].title = isEmpty(display)
          ? filterableList[key].title
          : display[AD_GROUP2];
        break;
      default:
    }
  });
  return filterableList;
};

export default {
  validate,
  validateLines,
  isValidUrl,
  checkValidateResult,
  validatePostback,
  isApiValidationError,
  getIdsString,
  getHeader,
  getTypeName,
  getFieldLabel,
  mergeTabsLabel,
  formatForTableDisplay,
  findByMediaId,
  filterByUrlNotEmpty,
  getDeleteForceItems,
  getDeleteWarningMessage,
  getDeleteErrorContent,
  getModalTitle,
  customFilters,
  formatNumber,
  getListByKey,
  hasAdLinking,
  hasMediaSyncSetting,
};
