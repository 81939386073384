import types from './types';

const start = (type) => ({
  type,
});

const succeeded = (type, data) => ({
  type,
  payload: data,
});

const failed = (type, { error }) => ({
  type,
  payload: error,
  scope: error.scope,
});

const requestStart = () => start(types.REQUEST_START);
const requestFailed = (error) => failed(types.REQUEST_FAILED, error);
const removed = (data) => succeeded(types.DELETE_SUCCEEDED, data);
const remove = () => succeeded(types.DELETE_ALL_DATA);

export default {
  requestStart,
  requestFailed,
  removed,
  remove,
};
