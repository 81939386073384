import React from 'react';
import { Form } from 'react-bootstrap';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames';
import DragonBall from 'views/atoms/dragonball/DragonBall';

const EbisRadio = (props) => {
  const {
    name,
    label,
    value,
    variant,
    tooltip,
    checked,
    disabled,
    onChange,
  } = props;
  const id = `radio-${name}-${value}`;

  const radioClass = classNames({
    'form-check--ebis-radio': true,
    [`form-check--${variant}`]: !!variant,
  });

  const handleSelect = (e) => {
    onChange(e.target.value, name);
  };

  return (
    <Form.Check inline type="radio" className={radioClass}>
      <Form.Check.Input
        inline
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={handleSelect}
      />
      <Form.Check.Label for={id}>{label}</Form.Check.Label>
      {!!tooltip && <DragonBall>{tooltip}</DragonBall>}
    </Form.Check>
  );
};

EbisRadio.propTypes = {
  name: string.isRequired,
  value: string.isRequired,
  label: string,
  variant: string,
  tooltip: string,
  checked: bool,
  disabled: bool,
  onChange: func,
};

EbisRadio.defaultProps = {
  label: '',
  variant: '',
  tooltip: '',
  checked: false,
  disabled: false,
  onChange: () => {},
};

export default React.memo(EbisRadio);
