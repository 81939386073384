import React from 'react';
import { connect } from 'react-redux';
import { TAB_ALL } from 'services/consts';
import { CV_ATTRIBUTE } from 'services/routes/constants';
import { commonSelectors, commonActions } from 'store/common';
import { DisplayItemsSelectors } from 'store/display-items';
import settingsSelector from 'store/settings/selectors';

import DownloadFiles from 'views/molecules/commonbar/components/DownloadFiles';
import { ALL_SESSION_LOG_VIEW } from 'domain/permissions/contractPermissions';

const DownloadFilesContainer = (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DownloadFiles {...props} />;
};

const mapStateToProps = (state) => {
  const screenId = settingsSelector.getPage(state);
  const tab = settingsSelector.getTab(state);
  const isAllowedSettingMeasurementOption = state.user.permissions.includes(
    ALL_SESSION_LOG_VIEW
  );
  const isDisplayCsvClickIdSetting =
    state.user.account.acquisitionMediaClickId &&
    isAllowedSettingMeasurementOption &&
    tab === TAB_ALL &&
    !state.user.agentFlag &&
    screenId === CV_ATTRIBUTE;

  const isEnableMediaSync = DisplayItemsSelectors.hasContractMediaSync(state);
  return {
    screenId,
    downloadCsvDisabled: commonSelectors.isCsvDownloadDisabled(screenId)(state),
    downloadImageDisabled: commonSelectors.downloadImageDisabled(screenId)(
      state
    ),
    downloadConfigs: commonSelectors.getDownloadConfigs(state),
    allDataExportEnabled: isEnableMediaSync,
    isShowExportCsvClickIdSetting: isDisplayCsvClickIdSetting,
  };
};
const mapDispatchToProps = (dispatch) => ({
  downloadImage: (format) => dispatch(commonActions.downloadImage(format)),
  downloadCsv: (isAllData = false, getClickIdSetting = false) =>
    dispatch(commonActions.downloadCsv({ isAllData, getClickIdSetting })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DownloadFilesContainer));
