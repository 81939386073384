import { MASTER_DATA_FILTER_LIMIT } from 'domain/consts';
import { buildSearchMasterDataBody } from 'domain/utils';
import { SettingsApi as Api } from 'services/api/Api';
import { AD_MANAGEMENT_API_ENDPOINT } from '../AdManagementApi';

const MediaSideServiceFactory = (type = 'media_account') => {
    const baseUrl = AD_MANAGEMENT_API_ENDPOINT;
    const url = `${baseUrl}/${type}`
    const search = async (
        searchTerm = '',
        selected = [],
        offset = 0,
        limit = MASTER_DATA_FILTER_LIMIT + 1, // Plus 1 item to display message: 件数が多いため、500件まで表示しています。検索機能をご活用ください。
        agencyId = ''
    ) => {
        const params = buildSearchMasterDataBody(
            searchTerm,
            selected,
            offset,
            limit,
            agencyId,
            false
        );
        const response = await Api.post(`${url}/list`, params);
        const {
            data,
            data: items,
            metadata: { count: total },
            selected: selectedItems,
        } = response?.data;
        return { data, items, total, selected: selectedItems };
    };
    return {
        search
    }
}

const mediaAccountService = MediaSideServiceFactory('media_account')
const mediaSideCampaignService = MediaSideServiceFactory('media_side_campaign')
const mediaSideGroupService = MediaSideServiceFactory('media_side_group')

export {
    mediaAccountService,
    mediaSideCampaignService,
    mediaSideGroupService,
}