import React, {
  useContext,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { oneOfType, element } from 'prop-types';
import classNames from 'classnames';
import {
  GridTableContext,
  GridTableItemContext,
} from 'views/organism/GridTable/GridTableContext';
import {
  FULL_WIDTH_COLUMN,
  MIN_WIDTH_COLUMN,
  MAX_WIDTH_COLUMN,
} from './consts';

function GridTableHeaderWrapper(props) {
  const { children } = props;
  const {
    dispatch,
    top,
    isResizable,
    isEmptyData,
    isShowRowTotal,
    settings: { heightHeader },
  } = useContext(GridTableContext);
  const {
    dispatchItem,
    gridTemplateColumns,
    gridTemplateRows,
    headers,
    isFreeze,
    bodyRef,
  } = useContext(GridTableItemContext);

  const [isFixedHeader, setFixedHeader] = useState(false);

  const headerWrapperRef = useRef(null);
  const headerRef = useRef(null);

  // Set width column based on width of name in case initial display
  const measuredColumnWidthRef = useCallback(
    (node) => {
      if (!bodyRef || !node) return;

      const { parentNode } = node;

      const {
        attributes: {
          'aria-colindex': { value: ariaColIndex },
          'aria-label': { value: field },
        },
      } = parentNode;

      const column = headers
        .filter((item) => !item.isParent)
        .find((item) => item.field === field);

      if (!column) return;

      const cell = bodyRef.current.querySelectorAll(
        `[role="row"]:nth-child(${isShowRowTotal ? 2 : 1}) [role="cell"]`
      )[parseInt(ariaColIndex, 10) - 1];

      const widthContent = Math.ceil(
        Math.max(
          parentNode.getBoundingClientRect().width,
          isEmptyData ? 0 : cell?.getBoundingClientRect()?.width || 0
        )
      );

      let isAutoResize = !isResizable;

      if (column?.width === 'max-content') {
        isAutoResize = false;
        column.minWidth = widthContent;
        column.maxWidth = widthContent;
        column.width = null;
      } else if (column?.minWidth === 'max-content') {
        column.minWidth = widthContent;
      }

      const { isLastColumn = false, width = null } = column || {};

      if (parseInt(width, 10) < MIN_WIDTH_COLUMN || (!isLastColumn && width)) {
        return;
      }

      let minWidth = parseInt(column.minWidth, 10) || 0;
      minWidth =
        minWidth || (isLastColumn && !isFreeze ? MIN_WIDTH_COLUMN : minWidth);
      minWidth = `${Math.min(
        Math.max(MIN_WIDTH_COLUMN, minWidth, widthContent),
        MAX_WIDTH_COLUMN
      )}px`;

      const maxWidth =
        isLastColumn || isAutoResize ? FULL_WIDTH_COLUMN : minWidth;
      const actualWidth =
        minWidth === maxWidth ? minWidth : `minmax(${minWidth}, ${maxWidth})`;
      // Save data to context
      dispatchItem({
        type: 'setTemplateColumns',
        payload: { [field]: `[${field}] ${actualWidth}` },
      });
    },
    [
      bodyRef,
      headers,
      isShowRowTotal,
      isEmptyData,
      isResizable,
      isFreeze,
      dispatchItem,
    ]
  );

  useEffect(() => {
    // Set header fixed when scroll to target
    // Refer: https://www.smashingmagazine.com/2021/07/dynamic-header-intersection-observer/
    if (!headerWrapperRef?.current) return () => {};

    const headerEl = headerWrapperRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => setFixedHeader(entry.intersectionRatio === 1),
      {
        root: headerEl.parentNode,
        rootMargin: `-1px 0px 0px 0px`,
        threshold: 1,
      }
    );

    observer.observe(headerEl);
    return () => {
      observer.unobserve(headerEl);
    };
  }, [headerWrapperRef]);

  useEffect(() => {
    if (!headerRef?.current) return;

    dispatchItem({
      type: 'setAttrs',
      payload: { headerRef, measuredColumnWidthRef },
    });

    // Sync height header for tables
    dispatch({
      type: 'setHeightHeader',
      payload: headerRef.current.scrollHeight,
    });
  }, [dispatch, dispatchItem, measuredColumnWidthRef]);

  const headerWrapperClass = classNames({
    'grid-table__header-wrapper': true,
    'grid-table__header-wrapper--fixed': isFixedHeader,
  });

  return (
    <div ref={headerWrapperRef} className={headerWrapperClass} style={{ top }}>
      <div
        ref={headerRef}
        role="table"
        className="grid-table__header-container"
      >
        <div
          role="rowheader"
          className="grid-table__header"
          style={{
            height: heightHeader,
            gridTemplateRows,
            gridTemplateColumns: Object.values(gridTemplateColumns).join(' '),
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

GridTableHeaderWrapper.propTypes = {
  children: oneOfType([element]).isRequired,
};

GridTableHeaderWrapper.defaultProps = {};

export default GridTableHeaderWrapper;
