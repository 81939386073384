import classNames from 'classnames';
import { bool, func, oneOf } from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

function DisplayItemsToggle(props) {
  const { variant, isActive, onToggle } = props;

  const toggleClass = classNames({
    'nav-link': true,
    'nav-link--icon': true,
    active: isActive,
  });

  const iconClass = classNames({
    'icon-svg': true,
    'icon-display-item': true,
    [variant]: !isEmpty(variant),
    active: isActive,
  });

  const onClick = () => {
    if (!isActive) {
      onToggle('displayItems');
    } else {
      onToggle('');
    }
  };

  return (
    <div
      // css in commonbar.scss
      onClick={onClick}
      className={toggleClass}
      aria-hidden="true"
    >
      <div>
        <div className="d-flex justify-content-center">
          <span className={iconClass} />
        </div>
        <div>項目切替</div>
      </div>
    </div>
  );
}

DisplayItemsToggle.propTypes = {
  variant: oneOf(['ad-management']),
  isActive: bool,
  onToggle: func,
};

DisplayItemsToggle.defaultProps = {
  variant: '',
  isActive: false,
  onToggle: () => {},
};

export default DisplayItemsToggle;
