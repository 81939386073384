import {
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
} from 'services/routes/constants';
import {
  SETTING_AD_MEDIA_EDIT,
  SETTING_AD_AD_GROUP1_EDIT,
  SETTING_AD_AD_GROUP2_EDIT,
} from 'domain/permissions/contractPermissions';
import { MEDIA, AD_GROUP1, AD_GROUP2 } from 'domain/fields';

import pages from 'services/routes/pages';

// Item type
export const TYPE_MEDIA_TYPE = 'media';
export const TYPE_AD_GROUP1 = 'adgroup1';
export const TYPE_AD_GROUP2 = 'adgroup2';

// Actions
export const EDIT = 'edit';
export const ADD_NEW = 'add';
export const REMOVE = 'remove';
export const UPLOAD = 'upload';

export const URL_MAX_LENGTH = 2083;
export const MAXIMUM_ITEMS_MEDIA = 1000;
export const MAXIMUM_ITEMS_ADGROUP = 20000;
export const VALUE_NOT_ALLOWED = '[未登録]';

// Table field key
export const LINE = 'line';
export const FIELD_ID = 'id';
export const FIELD_NAME = 'name';
export const FIELD_POSTBACKS = 'postbacks';
export const HAS_COSTLINKING = 'has_costlinking';
export const HAS_POSTBACK = 'has_postback';
export const HAS_AD_LINKING = 'has_adlinking';
export const HAS_MEDIA_SYNC_SETTING = 'has_media_sync_setting';

export const MEDIA_ID = 'id';
export const MEDIA_NAME = 'name';
export const MEDIA_RANK = 'rank';

export const FIELD_ATTRIBUTE = {
  isRequired: true,
  name: FIELD_NAME,
  [TYPE_MEDIA_TYPE]: {
    [EDIT]: 80,
    [ADD_NEW]: 4000,
    [LINE]: 1000,
  },
  [TYPE_AD_GROUP1]: {
    [EDIT]: 255,
    [ADD_NEW]: 4000,
    [LINE]: 20000,
  },
  [TYPE_AD_GROUP2]: {
    [EDIT]: 255,
    [ADD_NEW]: 4000,
    [LINE]: 20000,
  },
};

export const FIELD_NOTE = {
  label: '媒体種別',
  [TYPE_MEDIA_TYPE]: {
    [EDIT]: '80文字以内',
    [ADD_NEW]: `改行で複数件登録可、1件あたり80文字まで、最大1,000件（4,000文字以内）`,
  },
  [TYPE_AD_GROUP1]: {
    [EDIT]: '255文字以内',
    [ADD_NEW]: `改行で複数件登録可、1件あたり255文字まで、最大20,000件（4,000文字以内）`,
  },
  [TYPE_AD_GROUP2]: {
    [EDIT]: '255文字以内',
    [ADD_NEW]: `改行で複数件登録可、1件あたり255文字まで、最大20,000件（4,000文字以内）`,
  },
  dragonBall: '広告をカテゴリ分けするための、任意の名前を入力してください。',
};

export const FIELD_LABEL = {
  [TYPE_MEDIA_TYPE]: {
    file: { label: 'ファイル' },
    media_id: { label: 'ID' },
    media_name: { label: '媒体種別' },
    media_rank: { label: '' },
  },
  [TYPE_AD_GROUP1]: {
    file: { label: 'ファイル' },
    ad_group1_id: { label: 'ID' },
    ad_group1_name: { label: '広告グループ1' },
    ad_group1_rank: { label: '' },
  },
  [TYPE_AD_GROUP2]: {
    file: { label: 'ファイル' },
    ad_group2_id: { label: 'ID' },
    ad_group2_name: { label: '広告グループ2' },
    ad_group2_rank: { label: '' },
  },
};

export const FIELD_TYPE = {
  [EDIT]: 'input',
  [ADD_NEW]: 'textarea',
};

// Tab on the top
export const TABS = [
  {
    key: MEDIA,
    label: '媒体種別',
    path: pages[AD_MANAGEMENT_MEDIA_TYPE].path,
    permission: SETTING_AD_MEDIA_EDIT,
  },
  {
    key: AD_GROUP1,
    label: '広告グループ1',
    path: pages[AD_MANAGEMENT_AD_GROUP_1].path,
    permission: SETTING_AD_AD_GROUP1_EDIT,
  },
  {
    key: AD_GROUP2,
    label: '広告グループ2',
    path: pages[AD_MANAGEMENT_AD_GROUP_2].path,
    permission: SETTING_AD_AD_GROUP2_EDIT,
  },
];

export const LIMIT_ADD_MULTIPLE_LINE_SYNC = 100;
