import { put, takeLatest, call } from 'redux-saga/effects';
import actions from 'store/reflectiontime/actions';
import types from 'store/reflectiontime/types';
import ReflectionTimeApi from 'services/api/common/ReflectionTimeApi';

function* startFetchData() {
  // eslint-disable-next-line camelcase
  const { data, errors_details } = yield call(
    ReflectionTimeApi.getRelectionTime
  );

  if (data) {
    yield put(actions.fetchReflectionSuccess(data.data));
  } else {
    yield put(actions.fetchReflectionError(errors_details));
  }
}

export default function* reflectionTimeSaga() {
  yield takeLatest(types.FETCH_REFLECTION_TIME_START, startFetchData);
}
