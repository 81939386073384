import React, { useState, useEffect } from 'react';
import { arrayOf, shape, string, func, bool } from 'prop-types';
import AxisSelectbox from 'views/atoms/AxisSelectbox';

import './ebis-axis-group.scss';

const EbisAxisGroup = ({ axis, option, defaultValue, onSelect, disabled, customView }) => {
  const [axisSelected, setAxisSelected] = useState({});
  const [axisItems, setAxisItems] = useState([]);

  const handleSelect = (name, value) => {
    const indexChange = axis.findIndex((item) => item.name === name);
    const selected = axis.reduce((acc, item, index) => {
      if (index > indexChange || (index === indexChange && !value)) return acc;

      return {
        ...acc,
        [item.name]: index === indexChange ? value : axisSelected[item.name],
      };
    }, {});

    setAxisSelected(selected);
    onSelect(Object.values(selected));
  };

  const getValueDefault = (name, value) => {
    return Object.keys(value).reduce((acc, key) => {
      const { allowAxis = [], displayDefault = false } = value[key];
      return allowAxis.includes(name) && displayDefault ? key : acc;
    }, '');
  };

  useEffect(() => {
    const selectedDefault = axis
      .filter((item) => item.required)
      .reduce((acc, item) => {
        return { ...acc, [item.name]: getValueDefault(item.name, option) };
      }, {});

    setAxisSelected(
      defaultValue.reduce((acc, value, index) => {
        if (axis[index]) {
          const { name } = axis[index];
          const i = index === 0 ? index : index - 1;
          const { callback = () => {} } = axis[i] || {};
          const actualOption =
            callback(defaultValue[i], option, defaultValue) || option;
          // for CustomViewModal screen switching
          if (typeof actualOption[value] === "undefined") {
            return acc;
          }
          if (actualOption[value].allowAxis.includes(name)) {
            return { ...acc, [name]: value };
          }
        }

        return acc;
      }, selectedDefault)
    );
  }, [axis, defaultValue, option]);

  useEffect(() => {
    setAxisItems(() => {
      let selecteds = [];
      const keysOption = Object.keys(option);
      let actualOption = { ...option };

      return axis.map((item, index) => {
        const selected = axisSelected[item.name];
        if (selected) {
          selecteds.push(selected);
        }

        const prevAxisSelected = index <= selecteds.length;

        if (prevAxisSelected && index > 0) {
          const { callback = () => {}, name } = axis[index - 1] || {};
          actualOption =
            callback(axisSelected[name], option, selecteds) || option;
        }

        const options = keysOption.reduce((acc, key) => {
          if (
            actualOption[key].allowAxis.includes(item.name) &&
            (selected === key || !selecteds.includes(key))
          ) {
            return { ...acc, [key]: actualOption[key] };
          }
          return acc;
        }, {});

        if (selected && !options[selected]) {
          selecteds = selecteds.filter(
            (itemSelected) => itemSelected === selected
          );
        }

        return {
          ...item,
          options,
          disabled: !prevAxisSelected || Object.keys(options).length === 0,
        };
      });
    });
  }, [axis, option, axisSelected]);

  return (
    <div className="ebis-axis-group">
      {axisItems.map((axisItem) => {
        return (
          <AxisSelectbox
            key={axisItem.name}
            required={axisItem.required}
            label={axisItem.label}
            name={axisItem.name}
            options={axisItem.options}
            defaultValue={axisSelected[axisItem.name]}
            disabled={disabled || axisItem.disabled}
            onSelect={handleSelect}
            customView={customView}
          />
        );
      })}
    </div>
  );
};

EbisAxisGroup.propTypes = {
  axis: arrayOf({}).isRequired,
  option: shape({}).isRequired,
  defaultValue: arrayOf(string),
  disabled: bool,
  onSelect: func,
  customView: bool,
};

EbisAxisGroup.defaultProps = {
  defaultValue: [],
  disabled: false,
  onSelect: () => {},
  customView: false,
};

export default React.memo(EbisAxisGroup);
