import { createSelector } from 'reselect';
import { createTableHeaders } from 'services/utils';
import * as DisplayItems from 'domain/settings/display-items';
import * as FIELDS from 'domain/fields';
import settingsSelectors from 'store/settings/selectors';
import displayItemsSelectors from 'store/display-items/selectors';

// Display items
const contactGroups = [
  DisplayItems.CONTACT_DIRECT,
  DisplayItems.CONTACT_INDIRECT2,
  DisplayItems.CONTACT_INDIRECT3,
  DisplayItems.CONTACT_INDIRECT4,
  DisplayItems.CONTACT_INDIRECT5,
  DisplayItems.CONTACT_INDIRECT6,
  DisplayItems.CONTACT_INDIRECT7,
  DisplayItems.CONTACT_INDIRECT8,
  DisplayItems.CONTACT_INDIRECT9,
  DisplayItems.CONTACT_INDIRECT10,
  DisplayItems.CONTACT_FIRST,
];
const contactItems = {
  [DisplayItems.CONTACT_ACCESS_TIME]: FIELDS.ACCESS_TIME,
  [DisplayItems.CONTACT_CHANNEL]: FIELDS.CHANNEL,
  [DisplayItems.CONTACT_DEVICE]: FIELDS.DEVICE,
  [DisplayItems.CONTACT_CATEGORY]: FIELDS.CATEGORY,
  [DisplayItems.CONTACT_AD_GROUP1]: FIELDS.AD_GROUP1,
  [DisplayItems.CONTACT_AD_GROUP2]: FIELDS.AD_GROUP2,
  [DisplayItems.CONTACT_AD_ID]: FIELDS.AD_ID,
  [DisplayItems.CONTACT_AD_NAME]: FIELDS.AD_NAME,
  [DisplayItems.CONTACT_SYNC_CATEGORY]: FIELDS.SYNC_CATEGORY,
  [DisplayItems.CONTACT_MEDIA_SIDE_CAMPAIGN]: FIELDS.MEDIA_SIDE_CAMPAIGN,
  [DisplayItems.CONTACT_MEDIA_SIDE_GROUP]: FIELDS.MEDIA_SIDE_GROUP,
  [DisplayItems.CONTACT_MEDIA_SIDE_AD_ID]: FIELDS.MEDIA_SIDE_AD_ID,
  [DisplayItems.CONTACT_MEDIA_SIDE_AD_NAME]: FIELDS.MEDIA_SIDE_AD_NAME,
  [DisplayItems.CONTACT_AGENCY]: FIELDS.AGENCY,
  [DisplayItems.CONTACT_LANDING_PAGE_DOMAIN]: FIELDS.LANDING_PAGE_DOMAIN,
  [DisplayItems.CONTACT_LANDING_PAGE_URL]: FIELDS.LANDING_PAGE_URL,
  [DisplayItems.CONTACT_CONTENT_CATEGORY]: FIELDS.CONTENT_CATEGORY,
  [DisplayItems.CONTACT_OPTIONAL_AD_ID]: FIELDS.AD_ID,
  [DisplayItems.CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID]: FIELDS.MEDIA_SIDE_AD_ID,
};

const getListReport = (state) => state.cache.cvFlow.table.data.list;
const getSumReport = (state) => state.cache.cvFlow.table.data.sum;
const getSort = (state) => state.cache.cvFlow.table.settings.sort;
const getPagination = (state) => state.cache.cvFlow.table.settings.pagination;
const getStatus = (state) => state.cache.cvFlow.status;
const getStatusTable = (state) => state.cache.cvFlow.table.status;

const dimensionsSelector = createSelector(
  [
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (dimensions, displayItems) =>
    displayItems
      .filter((item) => item.displayFreeze && dimensions.includes(item.key))
      .map((item) => item.key)
);

const metricsSelector = createSelector(
  [
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (dimensions, metrics, displayItems) => {
    return displayItems
      .filter(
        (item) =>
          !item.displayFreeze && dimensions.concat(metrics).includes(item.key)
      )
      .map((item) => item.key);
  }
);

const getMetricsRequest = createSelector(
  [dimensionsSelector, metricsSelector],
  (dimensions, metrics) => {
    const selectedItems = [...dimensions, ...metrics];

    const requestItems = [];
    selectedItems
      .filter((item) => !Object.keys(contactItems).includes(item))
      .forEach((item) => {
        if (contactGroups.includes(item)) {
          const nestedItems = Object.keys(contactItems)
            .filter((contactItem) => selectedItems.includes(contactItem))
            .map((contactItem) => `${item}_${contactItems[contactItem]}`);
          requestItems.push(...nestedItems);
        } else {
          requestItems.push(item);
        }
      });

    return requestItems;
  }
);

const sortSelector = createSelector(
  [dimensionsSelector, metricsSelector, getSort],
  (dimensions, metrics, sort) => {
    if (sort && dimensions.concat(metrics).includes(sort.field)) {
      return { sort: (sort.direction === 'asc' ? '' : '-') + sort.field };
    }

    return {};
  }
);

// Create header data
export const dimensionsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const dimensions = displayItems
    .filter((item) => item.displayFreeze)
    .map((item) => {
      return {
        text: item.title,
        name: item.key,
      };
    });

  return dimensions;
};

export const metricsConfig = (state) => {
  const items = displayItemsSelectors.getUserPermittedItems(state);
  const itemsReport = displayItemsSelectors.getItemsReport(state);
  const metricsRequest = getMetricsRequest(state);
  const metricsSelected = metricsSelector(state);

  const metrics = itemsReport
    .filter(
      (item) =>
        !item.displayFreeze && !Object.keys(contactItems).includes(item.key)
    )
    .map((item) => {
      const metric = {
        text: item.title,
        name: item.key,
        mergeTotal: ![
          DisplayItems.CV,
          DisplayItems.AMOUNT_AVG,
          DisplayItems.AMOUNT,
          DisplayItems.CONTACT_AVG,
          DisplayItems.LATENCY_TIME_AVG,
        ].includes(item.key),
      };
      if (contactGroups.includes(item.key)) {
        metric.children = Object.keys(contactItems)
          .filter(
            (contactItem) =>
              metricsSelected.includes(contactItem) &&
              metricsRequest.includes(
                `${item.key}_${contactItems[contactItem]}`
              )
          )
          .map((contactItem) => ({
            name: `${item.key}_${contactItems[contactItem]}`,
            text: items[contactItem].title,
            sort: false,
            mergeTotal: true,
          }));
      }

      return metric;
    });

  return metrics;
};

export const groupsConfig = (state) => [
  {
    text: 'コンバージョン属性',
    name: 'cv',
    children: [DisplayItems.CV_NAME],
  },
  {
    text: dimensionsSelector(state).length <= 0 ? 'コンバージョン属性' : '',
    name: 'cv_metrics',
    mergeColumn: true,
    children: [
      DisplayItems.OTHER1,
      DisplayItems.OTHER2,
      DisplayItems.OTHER3,
      DisplayItems.OTHER4,
      DisplayItems.OTHER5,
      DisplayItems.TERMINAL_TYPE,
    ],
  },
];

const getConfig = (state) => {
  return {
    dimensions: dimensionsConfig(state),
    metrics: metricsConfig(state),
    groups: groupsConfig(state),
  };
};

const headerSelector = createSelector(
  [
    settingsSelectors.getTab,
    dimensionsSelector,
    metricsSelector,
    getSort,
    getConfig,
  ],
  (channel, dimensions, metrics, sort, itemsConfig) => {
    return createTableHeaders({
      channel,
      dimensions,
      metrics,
      sorts: [sort],
      itemsConfig,
    });
  }
);

export default {
  getListReport,
  getSumReport,
  getPagination,
  getStatus,
  getStatusTable,
  getMetricsRequest,
  dimensionsSelector,
  metricsSelector,
  headerSelector,
  sortSelector,
};
