import { createSelector } from 'reselect';
import settingsSelectors from 'store/settings/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import { getSortString } from 'domain/utils';

import { CONTENT_CATEGORY_ID } from 'domain/fields';
import { UNREGISTERED_ID_FILTER_KEY } from 'domain/consts';

const getList = (state) => state.cache.pageManagement.data.list.items;
const getObjPageById = (state) => state.cache.pageManagement.data.objPageById;
const getSettings = (state) => state.cache.pageManagement.settings;
const getStatusList = (state) => state.cache.pageManagement.status.list;

const getPagination = (state) => state.cache.pageManagement.settings.pagination;
const getSort = (state) => state.cache.pageManagement.settings.sort;

const getSortRequest = createSelector([getSort], (sort) => {
  return getSortString(sort);
});

const getStatesForList = createSelector(
  [
    getList,
    getSettings,
    getObjPageById,
    settingsSelectors.getLoadingByStatus([getStatusList]),
  ],
  (listData, settings, objPageById, isLoading) => ({
    listData,
    settings,
    objPageById,
    isLoading,
  })
);

const getDetail = (state) => state.cache.pageManagement.data.detail.item;
const getStatusDetail = (state) => state.cache.pageManagement.status.detail;

const getStatesForEdit = createSelector(
  [getDetail, settingsSelectors.getLoadingByStatus([getStatusDetail])],
  (detail, isLoadingEdit) => ({
    detail,
    isLoadingEdit,
  })
);

const getContentCategory = createSelector(
  [
    // Initial data
    settingsSelectors.getLoadingByStatus([masterDataSelectors.getStatus]),
    masterDataSelectors.getContentCategory,
    // Search data
    masterDataSelectors.getSearchResult,
    masterDataSelectors.getSearchType,
  ],
  (isLoading, defaultItems, searchItems, searchType) => {
    const actualItems =
      searchType === CONTENT_CATEGORY_ID ? searchItems : { ...defaultItems };
    delete actualItems[+UNREGISTERED_ID_FILTER_KEY]; // Remove item [未登録]
    const items = Object.values(actualItems);

    return {
      isLoading,
      items,
    };
  }
);

const pageManagementSelectors = {
  getStatesForList,
  getStatesForEdit,
  getPagination,
  getSortRequest,
  getSettings,
  getContentCategory,
};

export default pageManagementSelectors;
