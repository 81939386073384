import { combineReducers } from 'redux';
import CommonReducer from 'store/common/reducers';
import ReflectionTimeReducer from 'store/reflectiontime';
import AlertsReducer from 'store/alerts';
import NotificationsReducer from 'store/notifications';
import LoggerReducer from 'store/logger';
import UploadReducer from 'store/upload';

export { default as commonSelectors } from 'store/common/selectors';
export { default as commonActions } from 'store/common/actions';

const CommonReducers = combineReducers({
  CommonState: CommonReducer,
  ReflectionTimeState: ReflectionTimeReducer,
  AlertsState: AlertsReducer,
  NotificationsState: NotificationsReducer,
  LoggerState: LoggerReducer,
  upload: UploadReducer,
});

export default CommonReducers;
