import { SettingsApi } from 'services/api/Api';
import pages from 'services/routes/pages';
import { SYSTEM_SETTING } from 'services/routes/constants';

const SystemSettingDisplayApiFactory = () => {
  const endpoint = `${pages[SYSTEM_SETTING].endpoint}/display`;

  return {
    fetchData: async () => SettingsApi.get(endpoint),
    updateVision: async (data) => SettingsApi.put(`${endpoint}/vision`, data),
    updateRcv: async (data) => SettingsApi.put(`${endpoint}/rcv`, data),
  };
};

const SystemSettingDisplayApi = SystemSettingDisplayApiFactory();
export default SystemSettingDisplayApi;
