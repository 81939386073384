/**
 * List of declared error messages based on validation methods
 */
const messagesList = {
  isEmpty: '$0をスペース、タブ、改行のみで登録することはできません。',
  isLengthmax: '$0は$1文字以内で入力してください。',
  isLengthmin: '$0は$1文字以上で入力してください。',
  valueMinMax: '$0(以上)は$0(以下)と同じか少ない数を入れてください。',
  isExist: '$0が重複しています。別名で保存してください。',
  default: 'Default Error Messages',
};

/**
 * This handles case where message method is not defined
 */
const handler = {
  get(target, name) {
    return Object.prototype.hasOwnProperty.call(target, name)
      ? target[name]
      : messagesList.default;
  },
};

const errorMessages = new Proxy(messagesList, handler);

export default errorMessages;
