import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { func, bool, shape } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { PAGE_ID } from 'domain/fields';
import {
  STEPS_FORM_CV,
  STEP_PAGE,
  STEP_CONVERSION,
  STEP_ATTRIBUTE,
  STEP_DETAIL_CV,
  FIELD_FORM_CV,
  TAG_MANAGEMENT_TAB,
  CONDITION_CV,
  STEPS_FORM_CV_SKIP_ATTRIBUTE,
} from 'domain/tag-management/consts';
import {
  getCvDataSettingInitial,
  getCvDataSettingRequest,
  getCvDataSettingError,
  checkDataSettingError,
  validateCvDataSetting,
} from 'services/tag-management/tagManagementServices';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';
import { FormContext } from 'views/molecules/Form';
import EbisStep from 'views/molecules/Step/EbisStep';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import PageSetting from 'views/pages/tag-management/components/PageSetting';
import ConversionSetting from 'views/pages/tag-management/components/ConversionSetting';
import AttributeSetting from 'views/pages/tag-management/components/AttributeSetting';
import ViewDetail from 'views/pages/tag-management/components/ViewDetail/ViewDetail';

function ConversionSettingsModal(props) {
  const { isEditing, isLoading, detail, onSubmit, onHide } = props;

  const { dispatch } = useContext(FormContext);
  const { isCapiSingleContract } = useCheckPermissionCapi();

  const scrollbarWrapperRef = useRef(null);
  const fieldForm = FIELD_FORM_CV;
  const [dataField, setDataField] = useState(fieldForm);
  const [currentId, setCurrentId] = useState(null);
  const [currentStep, setCurrentStep] = useState(STEP_PAGE);

  const isSkipStepAttribute =
    isCapiSingleContract || dataField.condition.value === CONDITION_CV.SCROLL;
  const stepDetailCv = isSkipStepAttribute ? STEP_ATTRIBUTE : STEP_DETAIL_CV;
  const stepsFormCv = isSkipStepAttribute
    ? STEPS_FORM_CV_SKIP_ATTRIBUTE
    : STEPS_FORM_CV;
  const stepSubmitData = isSkipStepAttribute ? STEP_CONVERSION : STEP_ATTRIBUTE;

  const steps = stepsFormCv.map((item) => ({
    ...item,
    active: item.step === currentStep,
  }));

  const disabledBtnNextStep = useMemo(() => {
    const {
      page_id: { value: pageId },
      page_title: { value: pageTitle },
      cv_title: { value: cvTitle },
    } = dataField;

    return (
      (currentStep === STEP_PAGE && (!pageId || !pageTitle)) ||
      (currentStep === STEP_CONVERSION && !(cvTitle ?? pageTitle))
    );
  }, [currentStep, dataField]);

  const handleChangeValue = useCallback((name, value) => {
    setDataField((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], error: null, value },
    }));
  }, []);

  const handleError = useCallback(
    (errors) => {
      if (isEmpty(errors)) {
        setCurrentStep(stepDetailCv);
      } else {
        const dataFieldError = getCvDataSettingError(dataField, errors);
        const { stepError } = checkDataSettingError(dataFieldError);
        setDataField(dataFieldError);
        setCurrentStep(stepError);
      }
    },
    [dataField, stepDetailCv]
  );

  const handleChangeStep = useCallback(
    (isNextStep) => () => {
      // Validate data
      const dataValidated = validateCvDataSetting(dataField, currentStep);
      const { hasError, stepError } = checkDataSettingError(dataValidated);

      // Show error
      if (isNextStep && hasError && stepError === currentStep) {
        setDataField(dataValidated);
        dispatch({ type: 'setSubmit', payload: true }); // scroll to field error
        return;
      }

      // Submit data
      if (isNextStep && currentStep === stepSubmitData) {
        const dataRequest = getCvDataSettingRequest(dataField);
        onSubmit(dataRequest, handleError, currentId);
        return;
      }

      // Change step
      let indexStep = steps.findIndex((item) => item.step === currentStep);
      indexStep = isNextStep ? indexStep + 1 : indexStep - 1;
      setCurrentStep(steps[indexStep].step);
    },
    [
      dispatch,
      onSubmit,
      handleError,
      currentStep,
      steps,
      dataField,
      currentId,
      stepSubmitData,
    ]
  );

  const handleClose = (isReload = false) => () => onHide(isReload);

  useEffect(() => {
    if (!isEditing) return;
    setCurrentId(detail[PAGE_ID]);
    setDataField(getCvDataSettingInitial(fieldForm, detail));
  }, [isEditing, detail, fieldForm]);

  return (
    <Modal
      show
      centered
      size="w800"
      dialogClassName="modal-conversion-setting"
      contentClassName="modal-content--settings"
    >
      <Modal.Header className="justify-content-start">
        <Modal.Title>{isEditing ? 'タグを編集' : 'タグを追加'}</Modal.Title>
        <EbisStep steps={steps} />
      </Modal.Header>
      <Modal.Body ref={scrollbarWrapperRef}>
        <DataSyncLoader
          isLoading={isLoading}
          className={`page-loader--${
            currentStep === STEP_ATTRIBUTE ? 'attribute' : 'default'
          }`}
        >
          <ScrollbarWrapper
            ref={scrollbarWrapperRef}
            maxContent={533}
            alignScroll={10}
          >
            {currentStep === STEP_PAGE && (
              <PageSetting
                isEditing={isEditing}
                currentTab={TAG_MANAGEMENT_TAB.CV}
                info={dataField}
                onChange={handleChangeValue}
                isCapiSingleContract={isCapiSingleContract}
              />
            )}
            {currentStep === STEP_CONVERSION && (
              <ConversionSetting
                info={dataField}
                onChange={handleChangeValue}
                isCapiSingle={isCapiSingleContract}
              />
            )}
            {currentStep === STEP_ATTRIBUTE && !isSkipStepAttribute && (
              <AttributeSetting info={dataField} onChange={handleChangeValue} />
            )}
            {currentStep === stepDetailCv && (
              <ViewDetail
                infoDetail={detail}
                currentTab={TAG_MANAGEMENT_TAB.CV}
                isCapiSingle={isCapiSingleContract}
              />
            )}
          </ScrollbarWrapper>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="link"
          hidden={currentStep === stepDetailCv}
          onClick={handleClose(false)}
        >
          キャンセル
        </Button>
        <Button
          size="sm"
          variant="link"
          hidden={[STEP_PAGE, stepDetailCv].includes(currentStep)}
          onClick={handleChangeStep(false)}
        >
          戻る
        </Button>
        <Button
          size="sm"
          variant="secondary"
          className="m-0"
          hidden={[
            STEP_ATTRIBUTE,
            stepDetailCv,
            isSkipStepAttribute ? STEP_CONVERSION : '',
          ].includes(currentStep)}
          disabled={isLoading || disabledBtnNextStep}
          onClick={handleChangeStep(true)}
        >
          次へ
        </Button>
        <Button
          size="sm"
          variant="secondary"
          className="m-0"
          hidden={currentStep !== stepSubmitData}
          disabled={isLoading}
          onClick={handleChangeStep(true)}
        >
          OK
        </Button>
        <Button
          size="sm"
          variant="link"
          hidden={currentStep !== stepDetailCv}
          onClick={handleClose(true)}
        >
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConversionSettingsModal.propTypes = {
  onHide: func.isRequired,
  onSubmit: func.isRequired,
  isLoading: bool,
  isEditing: bool,
  detail: shape({}),
};

ConversionSettingsModal.defaultProps = {
  isLoading: false,
  isEditing: false,
  detail: {},
};

export default React.memo(ConversionSettingsModal);
