import * as FIELD from 'domain/fields';
import { CV_FIELD_PREFIX, CV_METRICS, CVR_FIELD_PREFIX } from 'domain/consts';
import { containsByAllKeys } from 'services/utils';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const createConversionMetrics = (metric, conversions) =>
  conversions
    .sort((cv1, cv2) => cv1.order - cv2.order)
    .map((conversion) => {
      if (metric === FIELD.CNT_DIRECT_CV) {
        return `${CV_FIELD_PREFIX}${conversion.id}`;
      }
      return `${CVR_FIELD_PREFIX}${conversion.id}`;
    });

const getCvFieldByKey = (key) => {
  if (key.startsWith(FIELD.CNT_DIRECT_CV)) {
    return 'cv';
  }
  if (key.startsWith(FIELD.CNT_DIRECT_RATE_CV)) {
    return 'cvr';
  }
  return null;
};

const rowToCells = (row, headers, selectedRows) => {
  return {
    cells: headers.map((header) => {
      if (header.isDimension) {
        return {
          idKey: header.idKey,
          nameKey: header.nameKey,
          idValue: row[header.idKey],
          value: row[header.nameKey],
          isDimension: header.isDimension,
        };
      }
      return {
        idKey: header.idKey,
        nameKey: header.idKey,
        idValue: row[header.idKey],
        value: row[header.idKey],
        isDimension: header.isDimension,
      };
    }),
    dimensionKey: row.dimensionKey,
    selected: containsByAllKeys(selectedRows, row.dimensionKey),
  };
};

const buildDisplayRows = ({ list, selectedRows, headers }) => {
  return list.map((row) => {
    return rowToCells(row, headers, selectedRows);
  });
};

const sortToNumber = (sorts, key) =>
  sorts.reduce((acc, { field, asc }) => {
    if (field === key) {
      return asc ? 1 : -1;
    }
    return acc;
  }, 0);

function buildDimensionHeaders(dimensions, sorts, dimensionToKeyValues) {
  const dimensionColumns = dimensionToKeyValues
    .filter((d) => dimensions.includes(d.field))
    .map((dimension) => ({
      idKey: dimension.field,
      nameKey: dimension.value,
      sort: sortToNumber(sorts, dimension.field),
      sortKey: dimension.field,
      isDimension: true,
      sortable: true,
    }));
  return dimensionColumns;
}

function buildMetricColumns(metrics, conversions, sorts, availableMetrics) {
  const metricColumns = availableMetrics
    .filter((metric) => metrics.includes(metric))
    .map((metric) => {
      if (CV_METRICS.includes(metric)) {
        return {
          metric,
          isConversion: true,
        };
      }
      return {
        metric,
        columns: [metric],
        isConversion: false,
      };
    })
    .map(({ metric, columns, isConversion }) => {
      if (isConversion) {
        return createConversionMetrics(metric, conversions);
      }
      return columns;
    })
    .flatMap((columns) => [...columns])
    .map((column) => {
      return {
        idKey: column,
        sort: sortToNumber(sorts, column),
        isDimension: false,
        sortKey: column,
        isConversion:
          column.startsWith(CV_FIELD_PREFIX) ||
          column.startsWith(CVR_FIELD_PREFIX),
        conversionField: getCvFieldByKey(column),
        sortable: true,
      };
    });
  return metricColumns;
}

const buildDisplayHeaders = ({
  dimensions,
  metrics,
  sorts,
  conversions,
  availableMetrics,
  dimensionsToKeyValues,
}) => {
  const dimensionColumns = buildDimensionHeaders(
    dimensions,
    sorts,
    dimensionsToKeyValues
  );
  const metricColumns = buildMetricColumns(
    metrics,
    conversions,
    sorts,
    availableMetrics
  );
  return dimensionColumns.concat(metricColumns);
};

const buildDisplaySum = ({ sumObject, headers }) => {
  if (isEmpty(sumObject)) {
    return [];
  }
  return headers
    .flatMap((header) => {
      if (isNil(header.children)) {
        return [...[header]];
      }
      return [...header.children];
    })
    .map((header) => {
      if (header.isDimension) {
        return {
          isDimension: true,
        };
      }

      const value = sumObject[header.name];
      return {
        key: header.name,
        value,
      };
    });
};

const updateSelectedRows = ({ displayRows, selectedRows }) => {
  return displayRows.map((row) => {
    return {
      ...row,
      selected: containsByAllKeys(selectedRows, row.dimensionKey),
    };
  });
};

const buildSortString = (sorts, field) => {
  if (field === 'period') return false;
  return sorts
    .filter((sort) => sort.field === field)
    .reduce((acc, sort) => (sort.asc ? 'asc' : 'desc'), 'none');
};

const updateDisplayHeadersSorts = (displayHeaders, sorts) => {
  return displayHeaders.map((header) => {
    const sortedHeader = {
      ...header,
      sort: buildSortString(sorts, header.name),
    };
    if (!isNil(sortedHeader.children)) {
      sortedHeader.children = sortedHeader.children.map((child) => ({
        ...child,
        sort: buildSortString(sorts, child.name),
      }));
    }
    return sortedHeader;
  });
};

/**
 * @param {[]}data
 */
const convertToDataTable = (data) => {
  data.map((item) => {
    const { ...rest } = item;
    return {
      ...rest,
      selected: item.data[0].selected,
    };
  });
};

export default {
  buildDisplayRows,
  buildDisplayHeaders,
  buildDisplaySum,
  updateSelectedRows,
  updateDisplayHeadersSorts,
  convertToDataTable,
};
