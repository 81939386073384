import React, { useEffect, useState, useRef } from 'react';
import { func, bool, shape } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import DragonBall from 'views/atoms/dragonball/DragonBall';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import { isEmpty } from 'lodash';
import iconDrag from 'assets/images/icon_drag.svg';

const defaultCurrentPage = 1;
function SortListContentCategoryModal(props) {
  const {
    loading = false,
    isShow,
    onHide,
    onSubmit,
    list = [],
    handleOpenUploadCsv,
  } = props;
  const [currentPage] = useState(defaultCurrentPage);
  const [dataPages, setDataPages] = useState({});
  const [dataPagesChange, setDataPagesChange] = useState({});
  const [currentListData, setCurrentListData] = useState([]);

  // variable save value item is dragging and dragOver
  const draggingItem = useRef();
  const dragOverItem = useRef();
  useEffect(
    () => {
      if (list && list.length > 0) {
        setCurrentListData(list);
        setDataPages((prevDataPages) => ({
          ...prevDataPages,
          [currentPage]: list,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list]
  );

  const handleDragOver = (e) => e.preventDefault();

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
  };

  const handleDragEnter = (e, position) => {
    dragOverItem.current = position;
    const listCopy = [...currentListData];
    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1);
    listCopy.splice(dragOverItem.current, 0, draggingItemContent);

    draggingItem.current = dragOverItem.current;
    dragOverItem.current = null;
    setCurrentListData(listCopy);
  };

  const handleDragEnd = () => {
    // when drag end, set data rank change by page
    setDataPagesChange((prevDataPageChanges) => ({
      ...prevDataPageChanges,
      [currentPage]: currentListData,
    }));
    draggingItem.current = null;
  };

  const handleSubmit = () => {
    // handle get list pages is changed rank
    const pageIdsChange = Object.keys(dataPagesChange).map((item) => +item);
    // handle get list rank is changed
    const resultCompareData = pageIdsChange.reduce((result, page) => {
      // get list compare data pages change and set rank to id change
      const convertRank = dataPages[page].map((dataPage, index) => ({
        id: dataPagesChange[page][index].id,
        name: dataPagesChange[page][index].name,
        rank: dataPage.order,
      }));
      // get list rank is changed to submit data
      convertRank.forEach((item, index) => {
        if (item.id !== dataPages[page][index].id) {
          result.push(item);
        }
      });
      return result;
    }, []);
    onSubmit(list, resultCompareData);
  };

  return (
    <Modal
      show={isShow}
      centered
      size="w800"
      dialogClassName="sort-list__modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          優先順位の並べ替え
          <Button
            size="sm"
            variant="secondary"
            className="controls_button-sort-bulk"
            onClick={handleOpenUploadCsv}
          >
            CSVアップロード
          </Button>
        </Modal.Title>
        <span>
          ※上から表示されている順で適用されます。ドラッグアンドドロップで並べ替えをすることが可能です。
          <DragonBall>
            ランディングページが複数のコンテンツカテゴリにマッチする場合、優先順位のより高いものが適用されます。
          </DragonBall>
          <br />
          ※登録済みのコンテンツカテゴリのうち、上位300件まで並べ替えが可能です。301件以降の並べ替えはCSVアップロードをご利用ください。
        </span>
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader
          isLoading={loading}
          className={loading && 'sort-list__modal-loading'}
        >
          <div className="sort-list__container">
            <div className="sort-list__box">
              <ul className="sort-list__box-table">
                {currentListData.map((item, index) => (
                  <li
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    draggable
                    onDragOver={handleDragOver}
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragEnter={(e) => handleDragEnter(e, index)}
                    onDragEnd={handleDragEnd}
                    className={
                      draggingItem.current &&
                      draggingItem.current === Number(index)
                        ? 'sort-list__drop-area'
                        : ''
                    }
                  >
                    <img src={iconDrag} alt="icon-drag" />
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="link" disabled={loading} onClick={onHide}>
          キャンセル
        </Button>
        <Button
          size="sm"
          variant="secondary"
          disabled={loading || isEmpty(dataPagesChange)}
          onClick={handleSubmit}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SortListContentCategoryModal.propTypes = {
  loading: bool.isRequired,
  isShow: bool.isRequired,
  onSubmit: func.isRequired,
  onHide: func.isRequired,
  list: shape([]).isRequired,
  handleOpenUploadCsv: func.isRequired,
};

export default SortListContentCategoryModal;
