import { call, put, takeLatest } from 'redux-saga/effects';
import { communicationStatus, HttpStatus } from 'services/utils';
import adGroup1ServiceApi from 'services/api/settings/AdGroup1Service';
import loggerActions from 'store/logger/actions';
import loggerConstants from 'store/logger/constant';
import sharedSagas from 'store/sharedSagas';
import handleError from 'services/error/handleScopeError';
import adManagementAdGroup1Actions from './actions';
import types from './types';

const { LOADING, SUCCEEDED, FAILED } = communicationStatus;

function* handleCreate(action) {
  try {
    yield put(adManagementAdGroup1Actions.setStatusDetail(LOADING));

    const { name, agencyId } = action.payload;

    const createdData = yield call(adGroup1ServiceApi.create, name, agencyId);

    yield put(adManagementAdGroup1Actions.setDataDetail(createdData));

    yield put(adManagementAdGroup1Actions.setStatusDetail(SUCCEEDED));
  } catch (e) {
    const { error: response, scope } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(adManagementAdGroup1Actions.setErrorDetail(errors, scope));
    yield put(adManagementAdGroup1Actions.setStatusDetail(FAILED));
  }
}

function* errorHandler(err) {
  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];

  yield put(loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

export default function* watch() {
  yield takeLatest(types.CREATE, sharedSagas.safe(errorHandler, handleCreate));
}
