import React from 'react';
import { number } from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import iconCautionYellow from 'assets/images/icon-caution-yellow.svg';

import {
  SC_STATUS_LABELS,
  STATUS_TYPE_ERR_AUTH,
} from 'domain/search-console-setting/constants';

function StatusComponent({ status }) {

  return (
    <div className="sc-status">
      {status === STATUS_TYPE_ERR_AUTH && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className="dragonball-tooltip variant-base">
              認証エラーが発生しています。 媒体の認証情報をご確認ください。
            </Tooltip>
          }
        >
          <img src={iconCautionYellow} alt="icon-caution" />
        </OverlayTrigger>
      )}
      <span>{SC_STATUS_LABELS[status]}</span>
    </div>
  );
}

StatusComponent.propTypes = {
  status: number.isRequired,
};

export default StatusComponent;
