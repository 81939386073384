import { MEDIA_SIDE_ITEMS_AD_MANAGEMENT, PRIORITY_AXIS_BOTH } from 'domain/consts';
import { bool, func, objectOf, shape, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CheckboxElement from 'views/atoms/CheckboxElement';
import FormGroup from 'views/atoms/FormGroup';

function AdManagementDisplayItemsModal(props) {
  const { show, onHide, settingItems, defaultItems, onSubmit, priorityAxis } = props;
  const [currentItems, setCurrentItems] = useState({ ...settingItems });

  useEffect(() => {
    setCurrentItems(settingItems);
  }, [settingItems]);

  const activeLink = useMemo(() => {
    return JSON.stringify(currentItems) !== JSON.stringify(defaultItems);
  }, [currentItems, defaultItems]);

  const disabledButton = useMemo(() => {
    return JSON.stringify(currentItems) === JSON.stringify(settingItems);
  }, [currentItems, settingItems]);

  const handleCancel = () => {
    setCurrentItems(settingItems);
    onHide();
  };

  const handleSubmit = () => {
    onSubmit(currentItems);
    onHide();
  };

  const resetForm = () => {
    setCurrentItems(defaultItems);
  };

  const onChange = useCallback(
    (key) => {
      const updatedItems = {
        ...currentItems,
        [key]: {
          ...currentItems[key],
          checked: !currentItems[key].checked,
        },
      };
      setCurrentItems(updatedItems);
    },
    [currentItems]
  );

  const displayItemsCheckBoxes = useMemo(
    () =>
      Object.keys(currentItems)
        .filter((key) => !currentItems[key].disabled)
        .map((key) => {
          const item = currentItems[key];
          return (
            <div
              key={key}
              className="display-items-form__checkbox-item display-items-form__checkbox-item--ad-management"
              style={{ order: item.order }}
            >
              <CheckboxElement
                name={key}
                label={item.title}
                checked={item.checked || false}
                disabled={item.required}
                onChange={() => onChange(key)}
                hasMediaSyncIcon={
                  priorityAxis === PRIORITY_AXIS_BOTH &&
                  MEDIA_SIDE_ITEMS_AD_MANAGEMENT.includes(key)
                }
              />
            </div>
          );
        }),
    [currentItems, onChange]
  );

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="w800"
      className="display-items-modal"
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="display-items-modal__title">
            <div className="display-items-modal_title-text">項目切替</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup
          heading="表示項目"
          linkActive={activeLink}
          onClick={resetForm}
        >
          <div className="display-items-form__checkbox-list">
            {displayItemsCheckBoxes}
          </div>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer className="display-items-modal__footer">
        <Button
          className="display-items-modal__cancel"
          size="sm"
          variant="link"
          onClick={handleCancel}
        >
          キャンセル
        </Button>
        <Button
          className="display-items-modal__apply"
          size="sm"
          variant="secondary"
          onClick={handleSubmit}
          disabled={disabledButton}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AdManagementDisplayItemsModal.propTypes = {
  show: bool.isRequired,
  onHide: func,
  onSubmit: func.isRequired,
  settingItems: objectOf(
    shape({ title: string, checked: bool, required: bool })
  ),
  defaultItems: objectOf(
    shape({ title: string, checked: bool, required: bool })
  ),
  priorityAxis: string.isRequired,
};

AdManagementDisplayItemsModal.defaultProps = {
  onHide: () => {},
  settingItems: {},
  defaultItems: {},
};

export default AdManagementDisplayItemsModal;
