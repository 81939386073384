/* eslint-disable no-useless-escape */

/**
 * Validate a an email
 * Sample matching pattern: john@mail.com
 */
export const REGEX_EMAIL = /^[0-9a-zA-Z][0-9a-zA-Z_.+-]+@[0-9a-zA-Z][0-9a-zA-Z_.-]+\.[a-zA-Z]+$/;

/**
 * Validate comma separated emails
 * Can also validate list with 0 or 1 email
 * Sample matching pattern: john@mail.com,doe@mail.com
 */
export const REGEX_EMAIL_LIST = /^([0-9a-zA-Z][0-9a-zA-Z_.+-]+@[0-9a-zA-Z][0-9a-zA-Z_.-]+\.[a-zA-Z]+)([,]([0-9a-zA-Z][0-9a-zA-Z_.+-]+@[0-9a-zA-Z][0-9a-zA-Z_.-]+\.[a-zA-Z]+))*$/;

/**
 * Validate user account id
 */
export const REGEX_USER_ID = /^[0-9a-zA-Z_]+$/;

/**
 * Validate user password
 * At least 1 number, 1 character, 1 special character (! # $ % & - / ? @ _)
 */
export const REGEX_USER_PASSWORD = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[/#?!@$%&_-])([a-zA-Z0-9/#?!@$%&_-]+)$/;

/**
 * Validate Space and tab line break
 */
// eslint-disable-next-line no-irregular-whitespace
export const REGEX_SPACE_TAB = /^[ 　\t\r\n]+$/;

/**
 * Number or decimal number
 */
export const REGEX_DECIMAL_NUMBER = /^[1-9]\d*(\.\d+)?$/;
/**
 * refer https://gist.github.com/ryanmcgrath/982242
 */
export const REGEX_JAPANESE_CHARACTER = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;

export const REGEX_SPLIT_BY_COMMA = /[,]+/g;

/**
 * Validate url format
 */
export const REGEX_URL = /^https?:\/\/([a-zA-Z0-9_!~*\'()\[\];:\@\$\?&=+,#%\.\/-])+$/;

/**
 * Validate a an ip
 * Sample matching pattern: 111.111.111.111
 */
export const REGEX_IP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
/**
 * Validate filename format
 */
export const REGEX_FILENAME = /^[a-zA-Z0-9-._]+$/;
/**
 * Validate query_params filed
 */
export const REGEX_QUERY_PARAMS = /^[_0-9a-zA-Z-]+$/;
/**
 * Validate query_params filed
 */
export const REGEX_DIRECTORY_INDEX = /^[_0-9a-z-.]+$/;
/**
 * Validate password field of media line
 */
export const REGEX_PASSWORD = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/;

/**
 * Regex to check url include 'token'
 */
export const REGEX_TOKEN = /\btoken\b/;
