import { chain, isEmpty } from 'lodash';

/**
 * Validate data & return error message
 * @param {object} data
 * @param {object} rule
 * @param {object} label
 * @returns object
 */
export const validateBasic = ({ data, rule, label = {} }) => {
  return chain(data)
    .mapValues((value, field) => ({
      label: label[field],
      value: value || null,
    }))
    .mapValues((value, field) => {
      if (isEmpty(rule[field])) return [];

      const rulesByField = [...rule[field]];
      const errors = rulesByField
        .map((ruleByField) => ruleByField(value))
        .filter((ruleByField) => !isEmpty(ruleByField));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();
};

// TODO remove if add new const
export default validateBasic;
