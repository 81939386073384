import { combineReducers } from 'redux';
import AdManagementSettingsReducer from 'store/ad-management-settings';
import DisplayItemsReducer from 'store/display-items/reducer';
import DisplayItemsForViewReducer from 'store/display-items-for-view/reducer';
import CsvSettingReducer from 'store/csv-setting/reducer';
import CustomViewReducer from 'store/customview/reducer';
import ResizeTableReducer from 'store/resize-table/reducer';
import RedirectReducer from 'store/redirect/reducer';
import FilterReducers from 'store/filters/reducers';

import SettingsItemForViewReducer from 'store/settings-for-view/reducer';
import SettingsItemReducer from './reducer';

const SettingReducers = combineReducers({
  displayItems: DisplayItemsReducer,
  displayItemsForView: DisplayItemsForViewReducer,
  csvSetting: CsvSettingReducer,
  report: SettingsItemReducer,
  reportForView: SettingsItemForViewReducer,
  view: CustomViewReducer,
  redirect: RedirectReducer,
  resizeTable: ResizeTableReducer,
  filters: FilterReducers,
  adManagement: AdManagementSettingsReducer,
});

export { default as settingsSelectors } from 'store/settings/selectors';

export default SettingReducers;
