import React, { useRef } from 'react';
import { any, oneOfType } from 'prop-types';

const tooltipId = 'grid-table__tooltip';

function GridTableTooltipWrapper({ children }) {
  const refEl = useRef(null);

  const showTooltip = (el) => {
    const parentWidth = el.parentNode.offsetWidth;
    const contentWidth = el.offsetWidth;

    return parentWidth < contentWidth;
  };

  const removeTooltip = () => {
    if (document.getElementById(tooltipId)) {
      document.getElementById(tooltipId).remove();
    }
  };

  const onMouseOver = (event) => {
    if (!refEl?.current || !showTooltip(refEl.current)) {
      removeTooltip();
      return;
    }

    const contentEl = refEl.current;

    let tooltip = document.getElementById(tooltipId);
    if (!tooltip) {
      tooltip = document.createElement('DIV');
      tooltip.setAttribute('id', tooltipId);
      document.body.appendChild(tooltip);
    }

    tooltip.innerHTML = contentEl.innerHTML;

    const positionLeft =
      window.innerWidth - event.clientX - 50 > tooltip.clientWidth
        ? event.clientX + 2
        : event.clientX - tooltip.clientWidth + 10;

    Object.assign(tooltip.style, {
      top: `${event.clientY + 15}px`,
      left: `${positionLeft}px`,
    });
  };

  return (
    <span
      ref={refEl}
      onMouseOver={onMouseOver}
      onMouseOut={removeTooltip}
      onFocus={() => {}}
      onBlur={() => {}}
    >
      {children}
    </span>
  );
}

GridTableTooltipWrapper.propTypes = {
  children: oneOfType([any]).isRequired,
};

GridTableTooltipWrapper.defaultProps = {};

export default React.memo(GridTableTooltipWrapper);
