import React, { useRef, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { bool, func, string } from 'prop-types';
import isInteger from 'lodash/isInteger';
/**
 * Check string follow format 123,123,123,456,458
 * @param {string} strNumber
 * @returns bool
 */
const isValid = (strNumber) => {
  const numbers = strNumber.split(',');
  const filteredList = numbers.filter(
    (item) => item.indexOf('.') === -1 && isInteger(+item)
  );
  return filteredList.length === numbers.length;
};
const MultiNumericInput = (props) => {
  const { onChange, value } = props;
  const inputRef = useRef(value);
  const prevValue = useRef(value || '');
  const [inputValue, setInputValue] = useState(value);

  const onValueChange = (e) => {
    const targetValue = e.target.value;
    if (isValid(targetValue)) {
      setInputValue(targetValue);
      if (onChange) {
        onChange(e);
      }
      prevValue.current = targetValue;
    } else {
      setInputValue(inputValue || '');
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Form.Control
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      onChange={onValueChange}
      ref={inputRef}
      type="text"
      value={inputValue}
      inputMode="numeric"
    />
  );
};

MultiNumericInput.propTypes = {
  onChange: func,
  value: string,
  beginNumber: string,
  isDecimal: bool,
};

MultiNumericInput.defaultProps = {
  onChange: () => {},
  value: '',
  beginNumber: '0-9',
  isDecimal: false,
};

export default MultiNumericInput;
