import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';
import types from 'store/api-management/types';

const apiManagementActions = {
  getRefreshToken: () => helper.createAction(types.GET_REFRESH_TOKEN),
  generateRefreshToken: () => helper.createAction(types.GENERATE_REFRESH_TOKEN),
  setRefreshToken: (refreshToken) =>
    helper.createAction(types.SET_REFRESH_TOKEN, { refreshToken }),
  setStatusRefreshToken: (status) =>
    helper.createAction(types.SET_STATUS_REFRESH_TOKEN, { status }),
  setErrorRefreshToken: (errors = [], scope = loggerConstant.SCOPE_DONT_SHOW) =>
    helper.createAction(types.SET_ERROR_REFRESH_TOKEN, { errors }, scope),
};

export default apiManagementActions;
