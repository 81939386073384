import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { CATEGORY_ANALYZE, USER_ENDPOINT } from 'services/routes/constants';
import { BOOKMARK_FUNC_NAME } from 'domain/consts';

const SummaryApiFactory = () => {
  const BOOKMARK_URL = `${USER_ENDPOINT}/display-settings/bookmark`;
  const REPORT_URL = pages[CATEGORY_ANALYZE].endpoint;

  return {
    url: REPORT_URL,
    bookmarkUrl: BOOKMARK_URL,
    getDataChart: async (request) => {
      return Api.postRequest(REPORT_URL, request);
    },
    getDataTable: async (request) => {
      return Api.postRequest(REPORT_URL, request);
    },
    getBookmark: async () => Api.get(BOOKMARK_URL),
    createBookmark: async ({ x, y }) => {
      const bookmark = {
        metric: y,
        compare_metric: x,
        func_id: BOOKMARK_FUNC_NAME.CATEGORY_ANALYZE,
      };
      return Api.postRequest(BOOKMARK_URL, bookmark);
    },
    deleteBookmark: async () => Api.delete(BOOKMARK_URL),
  };
};

const SummaryApi = SummaryApiFactory();
export default SummaryApi;
