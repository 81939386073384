import React from 'react';
import { connect } from 'react-redux';

import { loggerActions } from 'store/logger';
import { getIsLoggedIn, isTopUserId, isNextUserId } from 'store/auth/selectors';

import PageNotAuthorized from './PageNotAuthorized';

// eslint-disable-next-line react/jsx-props-no-spreading
const PageNotAuthorizedContainer = (props) => <PageNotAuthorized {...props} />;

const mapStateToProps = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
  isTopUser: isTopUserId(state),
  isNextUser: isNextUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  logConfirm: (data) => dispatch(loggerActions.logConfirmModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageNotAuthorizedContainer);
