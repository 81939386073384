const FETCH = 'impression-ad-cost/FETCH';
const SET_DATA_TABLE = 'impression-ad-cost/SET_DATA_TABLE';
const SET_STATUS_TABLE = 'impression-ad-cost/SET_STATUS_TABLE';
const UPDATE_SORT = 'impression-ad-cost/UPDATE_SORT';
const UPDATE_PAGE = 'impression-ad-cost/UPDATE_PAGE';
const UPLOAD = 'impression-ad-cost/UPLOAD';
const DOWNLOAD_UPLOADED_CSV = 'impression-ad-cost/DOWNLOAD_UPLOADED_CSV';
const REQUEST_ERROR = 'impression-ad-cost/REQUEST_ERROR';
const RESET_PAGE_AND_SORT = 'impression-ad-cost/RESET_PAGE_AND_SORT';
const DOWNLOAD_CSV_FORMAT = 'impression-ad-cost/DOWNLOAD_CSV_FORMAT';

export default {
  FETCH,
  SET_DATA_TABLE,
  SET_STATUS_TABLE,
  UPDATE_SORT,
  UPDATE_PAGE,
  UPLOAD,
  DOWNLOAD_UPLOADED_CSV,
  REQUEST_ERROR,
  RESET_PAGE_AND_SORT,
  DOWNLOAD_CSV_FORMAT,
};
