import React from 'react';
import { connect } from 'react-redux';
import { getAccountInfo, getUserId, getChargeName, getCompanyName } from 'store/auth/selectors';
import User from 'views/molecules/topbar/components/User';

const UserContainer = (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <User {...props} />;
};

const mapStateToProps = (state) => ({
  userData: getAccountInfo(state),
  userId: getUserId(state),
  chargeName: getChargeName(state),
  companyName: getCompanyName(state),
});

export default connect(mapStateToProps)(UserContainer);
