import { call, put, throttle, takeLatest, select } from 'redux-saga/effects';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';
import LtvConditionApi from 'services/api/LtvConditionApi';
import handleError from 'services/error/handleScopeError';
import ltvConditionSelectors from 'store/ltv/settings/condition/selectors';
import filterSelectors from 'store/filters/selectors';
import { getSortString, getOffset } from 'domain/utils';
import uploadActions from 'store/upload/actions';
import { LTV_SETTINGS_CONDITION } from 'services/routes/constants';
import loggerConstants from 'store/logger/constant';
import Services from 'domain/ltv/settings/condition/Services';
import types from './types';
import actions from './actions';

function* fetchList() {
  try {
    const pagination = yield select(ltvConditionSelectors.getPagination);
    const sort = yield select(ltvConditionSelectors.getSort);
    yield put(actions.fetchListStart());
    const response = yield call(LtvConditionApi.fetchAll, {
      sort: getSortString(sort),
      limit: TABLE_ROWS_PER_PAGE,
      filters: yield select(filterSelectors.getForApi),
      offset: getOffset(pagination),
    });
    yield put(actions.fetchedList({ data: response.data.data }));
    yield put(actions.updateCurrentItemNumber(response.data.metadata.count));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

function* create(action) {
  try {
    yield put(actions.requestStart());
    const response = yield call(LtvConditionApi.create, action.payload);
    yield put(actions.created({ data: response.data.data }));
    if (response.status === 200) {
      yield put(actions.created({ data: response.data.data }));
      yield put(actions.fetchListStart());
      yield put(actions.fetchList());
    }
  } catch (error) {
    if (error.response && Services.isApiValidationError(error.response)) {
      yield put(
        actions.submitFailed({
          error: error.response,
          scope: loggerConstants.SCOPE_DONT_SHOW,
        })
      );
    } else {
      yield put(
        actions.requestFailed({
          error: handleError(error.response, [400, 403]),
        })
      );
    }
  }
}

function* update(action) {
  try {
    yield put(actions.requestStart());
    // eslint-disable-next-line no-unused-vars
    const response = yield call(LtvConditionApi.update, action.payload);
    if (response.status === 200) {
      yield put(actions.fetchListStart());
      yield put(actions.updated({ data: response.data.data }));
      yield put(actions.fetchList());
    }
  } catch (error) {
    yield put(
      actions.requestFailed({ error: handleError(error.response, [400, 403]) })
    );
  }
}

function* remove(action) {
  try {
    const ids = action.payload;
    yield put(actions.requestStart());
    const response = yield call(LtvConditionApi.remove, ids);
    if (response.status === 200) {
      yield put(actions.removed({ ids }));
      yield put(actions.fetchListStart());
      yield put(actions.fetchList());
    }
  } catch (error) {
    yield put(
      actions.requestFailed({ error: handleError(error.response, [400, 403]) })
    );
  }
}

function* upload(action) {
  try {
    const { file } = action.payload;
    yield put(uploadActions.requestStart(LTV_SETTINGS_CONDITION));

    const {
      data: {
        data: { location: uploadPath },
      },
    } = yield call(LtvConditionApi.getPresignedUrl);

    yield call(LtvConditionApi.upload, uploadPath, file);
    const url = new URL(uploadPath);
    // Get last element after `/` character
    const filename = url.pathname.split('/').slice(-1).pop();
    // validate & import data
    yield call(LtvConditionApi.doImport, filename);
    yield put(uploadActions.uploadSucceeded());

    // refresh data list
    yield put(actions.updatePage(1));
    yield put(actions.fetchList());
  } catch (error) {
    const { response = { status: 500 } } = error;
    const status = response?.status ?? 500;
    if (status === 400) {
      // validate error
      yield put(uploadActions.requestFailed(response.data.errors));
    } else {
      // api or app error
      yield put(uploadActions.requestError(handleError(response)));
    }
  }
}

export default function* watchView() {
  yield takeLatest(types.FETCH_LIST, fetchList);
  yield throttle(1000, types.CREATE, create);
  yield throttle(1000, types.UPDATE, update);
  yield throttle(1000, types.DELETE, remove);
  yield throttle(3000, types.UPLOAD, upload);
}
