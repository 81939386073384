import types from './types';

const {
  UPDATE_CHART_DATA,
  UPDATE_COMPARED_CHART_DATA,
  UPDATE_TABLE_DATA,
  UPDATE_COMPARED_TABLE_DATA,
  APPLY_BOOKMARK,
  CLEAN_STATES,
  CLEAN_TABLE,
  SET_TABLE_STATUS,
  SET_CHART_STATUS,
  LOG_ERROR,
  REFRESH_TABLE_DATA,
} = types;

const setShowChart = (payload) => ({
  type: types.SET_SHOW_CHART,
  payload,
});

const changeAxis = (payload) => ({
  type: types.UPDATE_AXIS,
  payload,
});

const updateMedian = (payload) => ({
  type: types.UPDATE_MEDIAN,
  payload,
});

const getBookmark = () => ({
  type: types.GET_BOOKMARK,
});

const getBookmarkError = (payload) => ({
  type: types.GET_BOOKMARK_ERROR,
  payload,
});

const updateBookmark = (payload) => ({
  type: types.UPDATE_BOOKMARK,
  payload,
});

const deleteBookmark = () => ({
  type: types.DELETE_BOOKMARK,
});

const deleteBookmarkError = (error) => ({
  type: types.DELETE_BOOKMARK_ERROR,
  payload: error,
});

const updateCategories = (payload) => ({
  type: types.UPDATE_CATEGORIES,
  payload,
});

const setTableCurrentPage = (page) => ({
  type: types.SET_TABLE_CURRENT_PAGE,
  payload: page,
});

const updateSort = (sorts) => ({
  type: types.UPDATE_SORT,
  payload: sorts,
});

const selectRow = (dimensionKey) => ({
  type: types.SELECT_ROW,
  payload: dimensionKey,
});

const deselectRow = (dimensionKey) => ({
  type: types.DESELECT_ROW,
  payload: dimensionKey,
});

const getComparedDataTable = () => ({
  type: types.GET_COMPARED_DATA_TABLE,
});

const setVisible = ({ categories }) => ({
  type: types.SET_VISIBLE,
  payload: { categories },
});

const updateVisible = ({ dimensionKey, visible }) => ({
  type: types.UPDATE_VISIBLE,
  payload: { dimensionKey, visible },
});

const getReport = () => ({
  type: types.GET_REPORT,
});

const getChartReport = () => ({
  type: types.GET_CHART_REPORT,
});

const getComparedChartReport = () => ({
  type: types.GET_COMPARED_CHART_REPORT,
});

const getTableReport = () => ({
  type: types.GET_TABLE_REPORT,
});

const getComparedTableReport = () => ({
  type: types.GET_COMPARED_TABLE_REPORT,
});

const updateChartData = ({ list, selectedCategories, displayData }) => ({
  type: UPDATE_CHART_DATA,
  payload: { list, selectedCategories, displayData },
});

const updateComparedChartData = ({
  list,
  comparedList,
  selectedCategories,
  displayData,
}) => ({
  type: UPDATE_COMPARED_CHART_DATA,
  payload: { list, comparedList, selectedCategories, displayData },
});

const updateTableData = ({
  list,
  sum,
  displayRows,
  displayHeaders,
  displaySum,
  count,
  selectedRows,
}) => ({
  type: UPDATE_TABLE_DATA,
  payload: {
    list,
    sum,
    displayRows,
    displayHeaders,
    displaySum,
    count,
    selectedRows,
  },
});

const updateComparedTableData = ({
  list,
  sum,
  comparedList,
  comparedSum,
  displayRows,
  displayHeaders,
  displaySum,
  selectedRows,
  count,
}) => ({
  type: UPDATE_COMPARED_TABLE_DATA,
  payload: {
    list,
    sum,
    comparedList,
    comparedSum,
    displayRows,
    displayHeaders,
    displaySum,
    selectedRows,
    count,
  },
});

const applyBookmark = ({ x, y, enabled, showChart }) => ({
  type: APPLY_BOOKMARK,
  payload: { x, y, enabled, showChart },
});

const cleanStates = () => ({
  type: CLEAN_STATES,
});

const cleanTable = () => ({
  type: CLEAN_TABLE,
});

const setTableStatus = (status) => ({
  type: SET_TABLE_STATUS,
  payload: status,
});

const setChartStatus = (status) => ({
  type: SET_CHART_STATUS,
  payload: status,
});

const logError = (errors, scope) => ({
  type: LOG_ERROR,
  payload: errors,
  scope,
});

const refreshTableData = () => ({
  type: REFRESH_TABLE_DATA,
});

export default {
  changeAxis,
  getBookmark,
  getBookmarkError,
  updateBookmark,
  deleteBookmark,
  deleteBookmarkError,
  setShowChart,
  updateCategories,
  updateMedian,
  setTableCurrentPage,
  getComparedDataTable,
  updateSort,
  selectRow,
  deselectRow,
  setVisible,
  updateVisible,
  // new types
  getReport,
  getChartReport,
  getComparedChartReport,
  getTableReport,
  getComparedTableReport,

  updateChartData,
  updateComparedChartData,
  updateTableData,
  updateComparedTableData,
  applyBookmark,
  cleanStates,
  cleanTable,

  setChartStatus,
  setTableStatus,
  logError,

  refreshTableData,
};
