import {
  EXECUTE_DATE,
  IMPORT_TYPE_NAME,
  DOWNLOAD_BTN_UPLOADED_CSV,
  STATUS,
  EXECUTE_USER,
  IMPORTING_TYPE,
  CSV_REGISTER_TYPE,
} from 'domain/fields';
import { APP_HELP_IMPRESSION_AD_COST_IMPORT_TYPE } from 'services/routes/constants';

export const HEADER_CONFIG = [
  {
    isDimension: false,
    name: EXECUTE_DATE,
    sort: 'none',
    text: '実行日時',
  },
  {
    isDimension: false,
    name: IMPORT_TYPE_NAME,
    sort: 'none',
    text: '取り込み粒度',
  },
  {
    isDimension: false,
    name: DOWNLOAD_BTN_UPLOADED_CSV,
    sort: false,
    text: 'アップロードファイル',
    isContentCenter: true,
  },
  {
    isDimension: false,
    name: STATUS,
    sort: 'none',
    text: 'ステータス',
    isContentCenter: true,
  },
  {
    isDimension: false,
    name: EXECUTE_USER,
    sort: false,
    text: '実行ユーザーID',
  },
];

export const CONFIG_SORT_FIELD_NAME_REPLACE = {
  [EXECUTE_DATE]: 'executed_at',
  [IMPORT_TYPE_NAME]: 'importing_type',
  // [EXECUTE_USER]: 'modified_by',   // MEMO: 一時的に 実行ユーザーID でのソートを停止させる為
};

export const STYLES = {
  container: 'impression-ad-cost-container',
  pagination: 'paginations',
  modalHeader: 'modal-header-bottom-initial',
};

export const DEFAULT_COLUMN_WIDTH = {
  execute_date: 171,
  import_type_name: 360,
  download_btn_uploaded_csv: 170,
  status: 135,
  execute_user: 170,
};

export const DEFAULT_TABLE_WIDTH = 1006;

export const TABLE_ROWS_PER_PAGE = 100;

export const MODAL_ID = { ERROR: 'error' };

export const DEFAULT_CSV_UPLOAD_OPTION = {
  [IMPORTING_TYPE]: '0',
  [CSV_REGISTER_TYPE]: 'false',
};
export const CSV_UPLOAD_CSV_REGISTER_TYPE_ITEMS = [
  { label: '許可しない', value: 'false' },
  { label: '許可する（合算して登録する）', value: 'true' },
];

export const ERROR_MODAL_TITLE = '表示回数/広告コストの登録エラー';

export const CSV_MAX_SIZE = 30 * 1024 * 1024;
export const CSV_MIN_SIZE = 3; // NOTE: For an empty file with UTF-8 with BOM, it will be 3 bytes, so use 3 instead of 0.

export const IMPORT_TYPE_AD_ID = 0;
export const IMPORT_TYPE_MEDIA = 1;
export const IMPORT_TYPE_MEDIA_AND_GROUP1 = 2;
export const IMPORT_TYPE_MEDIA_AND_GROUP1_AND_GROUP2 = 3;

export const STATUS_LABELS = {
  completed: '完了',
  running: '処理中',
  error: 'エラー',
  system_error: 'エラー',
};

export const CSV_UPLOAD_IMPORT_TYPE_SUPPLEMENT_COMMENT =
  `※広告カテゴリ（媒体種別・広告グループ1・広告グループ2）ごとに取り込む場合は、<a href="${APP_HELP_IMPRESSION_AD_COST_IMPORT_TYPE}" target="_blank" rel="noopener noreferrer">分析画面での表示について</a>ご確認ください`;
