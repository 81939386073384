import Api, { SettingsApi } from 'services/api/Api';
import pages from 'services/routes/pages';
import {
  SEARCH_CONSOLE_SETTING,
  USER_MANAGEMENT,
} from 'services/routes/constants';
import apiUtils from 'services/apiUtils';

const SearchConsoleApiFactory = () => {
  const endpoint = `${pages[SEARCH_CONSOLE_SETTING].endpoint}`;
  const endpointUser = pages[USER_MANAGEMENT].endpoint;

  return {
    fetchData: async (sort) => SettingsApi.get(`${endpoint}?sort=${sort}`),
    getDetail: async (id) => SettingsApi.get(`${endpoint}/${id}`),
    changeStatus: async (id, status) =>
      SettingsApi.put(`${endpoint}/${id}/status`, {
        pause_flag: status,
      }),
    create: async (data) => SettingsApi.post(endpoint, data),
    update: async (id, data) => SettingsApi.put(`${endpoint}/${id}`, data),
    delete: async (ids) =>
      SettingsApi.delete(apiUtils.buildUrlWithQueryStrings(endpoint, { ids })),
    fetchUsers: async () => Api.get(endpointUser),
  };
};

const SearchConsoleApi = SearchConsoleApiFactory();
export default SearchConsoleApi;
