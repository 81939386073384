/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Loader from 'views/atoms/loader/Loader';
import classNames from 'classnames';
import TableContext from 'views/templates/TableStatisticsTemplate/components/table-context';

import './Table.scss';

const MAX_WIDTH_COLUMN = 800;
const PADDING_COLUMN = 30;
const PREFIX_COLUMN = 'column__';

// Define min_width after resize columns
const MIN_WIDTH_COLUMN = 100;
// Apply min_width default for dimension columns
const MIN_WIDTH_COLUMN_DEFAULT = 150;
// Define min_width_border for dimension columns
const MIN_WIDTH_BORDER = 1;
// Don't apply min_width default for check_all and edit_btn columns
const FREE_WIDTH_COLUMNS = ['check_all', 'edit_btn'];

const getTableContainer = (scrollContainerEl) => {
  const wrapperHeaderEl = scrollContainerEl.querySelector(
    '.freeze-table__wrapper-header'
  );
  const wrapperBodyEl = scrollContainerEl.querySelector(
    '.freeze-table__wrapper-body'
  );

  return wrapperBodyEl.querySelector('tbody').children.length > 0
    ? {
        tableScrollEl: wrapperBodyEl,
        tableFloatEl: wrapperHeaderEl,
      }
    : {
        tableScrollEl: wrapperHeaderEl,
        tableFloatEl: wrapperBodyEl,
      };
};

const isHiddenResizeHandle = (tableContainerEl) => {
  const [resizeHandleEl] = [...tableContainerEl.children].filter((item) =>
    item.className.split(' ').includes('resize-handle')
  );
  const scrollContainerEl = resizeHandleEl.nextSibling;
  const { tableScrollEl } = getTableContainer(scrollContainerEl);

  return !tableScrollEl.scrollLeft;
};

const setScrollBarWidth = (scrollContainerEl) => {
  setTimeout(() => {
    const { tableScrollEl } = getTableContainer(scrollContainerEl);

    const scrollerEl = scrollContainerEl.querySelector('.scroller');
    const visibleRatio = tableScrollEl.offsetWidth / tableScrollEl.scrollWidth;
    const scrollerWidth = visibleRatio * tableScrollEl.offsetWidth;
    const display =
      scrollerWidth < tableScrollEl.offsetWidth ? 'block' : 'none';
    scrollerEl.style.width = `${scrollerWidth}px`;
    scrollerEl.style.display = display;
    scrollerEl.parentNode.setAttribute('display', display);
    const freezeTableEL = tableScrollEl.closest('.freeze-table');
    const scrollContentEl = freezeTableEL.querySelectorAll('.scroller');

    if (scrollContentEl[0].style.display === 'block') {
      freezeTableEL.childNodes[1].classList.add('resize-handle--left');
      freezeTableEL.childNodes[1].classList.remove('resize-handle--right');
    } else {
      freezeTableEL.childNodes[1].classList.add('resize-handle--right');
      freezeTableEL.childNodes[1].classList.remove('resize-handle--left');
    }

    freezeTableEL.classList.remove('freeze-table--scroll-none');
    if (
      scrollContentEl[0].style.display === 'none' &&
      scrollContentEl[1].style.display === 'none'
    ) {
      freezeTableEL.classList.add('freeze-table--scroll-none');
    }
  }, 600);
};

const getTableEmpty = (tableContainerEl) => {
  const tableEmpty = {
    freeze: true,
    main: true,
  };
  if (!tableContainerEl) return tableEmpty;

  const headers = tableContainerEl.getElementsByClassName(
    'freeze-table__header-all'
  );

  return {
    ...tableEmpty,
    freeze: headers[0].children.length <= 0,
    main: headers[1].children.length <= 0,
  };
};

const getWidthColumnInGroup = (column) => {
  if (column.className) {
    const matches = column.className.match(/group__([^\s]+)/);
    if (matches) {
      const children = column.closest('tr').getElementsByClassName(matches[0]);
      const groupEl = column
        .closest('tr')
        .previousSibling.querySelector(`.${matches[1]} span`);
      return (groupEl.offsetWidth + PADDING_COLUMN) / children.length;
    }
  }
  return 0;
};

const findClassPrefixColumn = (clsName) => {
  if (!clsName) return '';

  const pattern = new RegExp(`${PREFIX_COLUMN}([^\\s]+)`);
  const matches = clsName.match(pattern);
  return matches.length > 0 ? matches[0] : 'freeze-table__cell-content';
};

const getWidthLastColumn = (
  columnsWidth,
  freezeColumns,
  freezeWidth,
  prevFreezeWidth
) => {
  const lastColumn = freezeColumns[freezeColumns.length - 1];
  const currentWidth = freezeColumns.reduce((acc, column) => {
    let totalWidth = acc;
    if (lastColumn !== column) {
      totalWidth = acc + (columnsWidth[column]?.width || 0) + MIN_WIDTH_BORDER;
    }
    return totalWidth;
  }, 0);

  let widthLastColumn =
    columnsWidth[lastColumn]?.width ||
    columnsWidth[lastColumn]?.minWidth ||
    MIN_WIDTH_COLUMN;
  // set with column to compare: 100 or keep by header column width
  const columnWidthCompare =
    currentWidth + widthLastColumn > prevFreezeWidth
      ? widthLastColumn
      : MIN_WIDTH_COLUMN;
  widthLastColumn =
    freezeWidth - currentWidth > columnWidthCompare
      ? freezeWidth - currentWidth
      : widthLastColumn;

  return { lastColumn, keyWidth: 'width', widthLastColumn };
};

const CustomScroller = (props) => {
  const { className, headerTop, width, children } = props;
  const scrollContentWrapper = useRef(null);
  const scrollContainer = useRef(null);
  const scroller = useRef(null);

  const [scrollActive, setScrollActive] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const scrollEl = scroller.current;
    const scrollContentWrapperEl = scrollContentWrapper.current;
    const scrollContainerEl = scrollContainer.current;

    const { tableScrollEl, tableFloatEl } = getTableContainer(
      scrollContainerEl
    );

    const wrapperHeaderEl = scrollContentWrapperEl.querySelector(
      '.freeze-table__wrapper-header'
    );

    const headerHeight = wrapperHeaderEl.offsetHeight;

    setScrollBarWidth(scrollContainerEl);

    let contentPosition = 0;
    let scrollerBeingDragged = false;
    let normalizedPosition;

    const startDrag = (e) => {
      normalizedPosition = e.pageX;
      contentPosition = tableScrollEl.scrollLeft;
      scrollerBeingDragged = true;
    };

    const stopDrag = () => {
      scrollerBeingDragged = false;
    };

    const scrollBarScroll = (e) => {
      setScrollActive(scrollerBeingDragged);
      if (scrollerBeingDragged === true) {
        const mouseDifferential = e.pageX - normalizedPosition;
        const scrollEquivalent =
          mouseDifferential *
          (tableScrollEl.scrollWidth / tableScrollEl.offsetWidth);
        tableScrollEl.scrollLeft = contentPosition + scrollEquivalent;
      }
    };

    const moveScroller = () => {
      // Move Scroll bar to left offset
      const scrollPercentage =
        tableScrollEl.scrollLeft / tableScrollEl.scrollWidth;
      const leftPosition = scrollPercentage * tableScrollEl.offsetWidth;
      setScrollLeft(leftPosition);

      // Check resize-handle to show/hide shadow
      const [resizeHandleEl] = [
        ...scrollContainerEl.parentNode.children,
      ].filter((item) => item.className.split(' ').includes('resize-handle'));

      if (isHiddenResizeHandle(scrollContainerEl.parentNode)) {
        resizeHandleEl.classList.add('resize-handle--shadow-none');
      } else {
        resizeHandleEl.classList.remove('resize-handle--shadow-none');
      }
    };
    moveScroller();

    const isDisplayInlineHeader = (element) => {
      const { top, bottom } = element.getBoundingClientRect();
      return Math.round(top) >= headerTop || bottom <= headerHeight + headerTop;
    };

    const floatHeader = () => {
      let top;
      tableFloatEl.querySelector(
        'table'
      ).style.left = `-${tableScrollEl.scrollLeft}px`;

      if (isDisplayInlineHeader(scrollContainerEl)) {
        wrapperHeaderEl.classList.remove('header-fixed');
        top = 0;
      } else {
        wrapperHeaderEl.classList.add('header-fixed');
        top = headerTop;
      }
      wrapperHeaderEl.style.top = `${top}px`;
    };
    floatHeader();

    const smoothScrollTo = (event) => {
      if (event.target.className === 'scrollable__scroll-container') {
        const visibleRatio =
          tableScrollEl.offsetWidth / tableScrollEl.scrollWidth;
        const scrollerWidth = visibleRatio * tableScrollEl.offsetWidth;
        const left =
          event.offsetX > tableScrollEl.scrollLeft * visibleRatio
            ? (event.offsetX - scrollerWidth) / visibleRatio
            : event.offsetX / visibleRatio;

        tableScrollEl.scroll({
          left,
          behavior: 'smooth',
        });
      }
    };

    // attach related draggable listeners
    scrollEl.addEventListener('mousedown', startDrag);
    window.addEventListener('mouseup', stopDrag);
    window.addEventListener('mousemove', scrollBarScroll);
    window.addEventListener('scroll', floatHeader);
    tableScrollEl.addEventListener('scroll', floatHeader);
    tableScrollEl.addEventListener('scroll', moveScroller);
    scrollContainerEl.addEventListener('click', smoothScrollTo);

    return () => {
      scrollEl.removeEventListener('mousedown', startDrag);
      window.removeEventListener('mouseup', stopDrag);
      window.removeEventListener('mousemove', scrollBarScroll);
      window.removeEventListener('scroll', floatHeader);
      tableScrollEl.removeEventListener('scroll', floatHeader);
      tableScrollEl.removeEventListener('scroll', moveScroller);
      scrollContainerEl.removeEventListener('click', smoothScrollTo);
    };
  }, [headerTop, width, children]);

  return (
    <div
      ref={scrollContainer}
      className={`scrollable ${className}`}
      style={width ? { width: `${width}px` } : {}}
    >
      <div ref={scrollContentWrapper} className="scrollable__content-wrapper">
        {children}
      </div>
      <div className="scrollable__scroll-container">
        <div
          ref={scroller}
          className={`scroller ${scrollActive ? 'scroller--is-active' : ''}`}
          style={{ left: `${scrollLeft}px` }}
        />
      </div>
    </div>
  );
};

CustomScroller.propTypes = {
  // type: PropTypes.oneOf(['freeze', 'main']).isRequired,
  width: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  headerTop: PropTypes.number,
  className: PropTypes.string,
};

CustomScroller.defaultProps = {
  headerTop: 0,
  className: '',
};

const FreezeTable = (props) => {
  const {
    top,
    width,
    freeze,
    main,
    tablesWidth,
    onResize,
    loading,
    empty,
    variant,
    emptyMessage,
  } = props;

  const { attrs, dispatch } = useContext(TableContext);
  const tableContainer = useRef(null);
  const resizeHandleRef = useRef(null);

  const [resize, setResize] = useState(false);
  const [resizeFreezeWidth, setResizeFreezeWidth] = useState(0);
  const [resizeMainWidth, setResizeMainWidth] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [headerTable, setHeaderTable] = useState(70);
  const [hiddenResizeHandle, setHiddenResizeHandle] = useState(true);

  const [tableEmpty, setTableEmpty] = useState(
    getTableEmpty(tableContainer.current)
  );

  const freezeTableClass = classNames({
    'freeze-table': true,
    [variant]: !!variant,
    'freeze-table--loading': loading,
    'freeze-table--empty': empty,
  });
  const resizeHandleClass = classNames({
    'resize-handle': true,
    'resize-handle--right': true,
    'header--being-resized': resize,
    'resize-handle--shadow-none': hiddenResizeHandle,
    'd-none': tableEmpty.freeze || tableEmpty.main,
  });
  const resizeFreezeClass = classNames({
    'freeze-table__freeze-content': true,
    'd-none': tableEmpty.freeze,
    'mw-100': tableEmpty.main,
    'w-100': tableEmpty.main,
  });
  const resizeMainClass = classNames({
    'd-none': tableEmpty.main,
    'mw-100': tableEmpty.freeze,
    'w-100': tableEmpty.freeze,
  });

  // Set width last column of freeze table
  const setWidthLastColumn = (freezeWidth) => {
    const { columns: columnsWidth, freezeColumns = [] } = attrs;
    const { lastColumn, keyWidth, widthLastColumn } = getWidthLastColumn(
      columnsWidth,
      freezeColumns,
      freezeWidth,
      tablesWidth.freeze
    );
    onResize.setColumnsWidth(lastColumn, widthLastColumn);

    dispatch({
      type: 'setColumnAttrs',
      payload: { [lastColumn]: { [keyWidth]: widthLastColumn } },
    });
  };

  const onMouseMove = (e) =>
    requestAnimationFrame(() => {
      const tableContainerEl = tableContainer.current;
      // Calculate the desired width
      const { left } = tableContainerEl.getBoundingClientRect();
      let freezeWidth = Math.max(MIN_WIDTH_COLUMN, e.clientX - left);

      const contentWidth = tableContainerEl.offsetWidth;
      // width > 80% content width -> max: 80% content width
      const contentWidth80Percent = Math.ceil(contentWidth * 0.8);
      if (freezeWidth >= contentWidth80Percent) {
        freezeWidth = contentWidth80Percent - MIN_WIDTH_BORDER;
      }

      const mainWidth = contentWidth - freezeWidth;
      setResizeFreezeWidth(freezeWidth);
      setResizeMainWidth(mainWidth);

      setWidthLastColumn(freezeWidth);

      // Save store
      if (onResize.setRowsHeight) {
        onResize.setRowsHeight();
      }
      if (onResize.setTablesWidth) {
        onResize.setTablesWidth(freezeWidth);
      }
    });

  const onMouseUp = () => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    setResize(false);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    setResize(true);
    setHiddenResizeHandle(isHiddenResizeHandle(tableContainer.current));
  };

  useEffect(() => {
    if (tableEmpty.freeze && tableEmpty.main) {
      return;
    }
    const tableContainerEl = tableContainer.current;
    let freezeWidth = 0;
    if (!tableEmpty.freeze) {
      // Get header element
      const scrollable = tableContainerEl.getElementsByClassName('scrollable');
      const scrollableEls = [...scrollable];
      const [scrollableEl] = scrollableEls;
      const thead = scrollableEl.querySelectorAll(
        'thead tr.freeze-table__header-all'
      );

      if (thead[thead.length - 1]) {
        const th = [...thead[thead.length - 1].children];
        th.forEach((item, index) => {
          // Calculate total width of Group columns
          const widthGroupColumn = getWidthColumnInGroup(item);
          let maxWidth = Math.max(th[index].clientWidth, widthGroupColumn);

          [th[index]].forEach((itemEl) => {
            const cellContent = itemEl.getElementsByClassName(
              'freeze-table__cell-content'
            )[0];

            if (itemEl.style.display === 'none') {
              const previousTr = cellContent.closest('tr').previousSibling;
              const columnName = findClassPrefixColumn(cellContent?.className);
              const column = columnName.replace(PREFIX_COLUMN, '');

              // Calculate total width of Group columns have display='none'
              const itemGroup = previousTr.getElementsByClassName(
                columnName
              )[0];

              maxWidth = FREE_WIDTH_COLUMNS.includes(column)
                ? itemGroup.offsetWidth
                : Math.max(maxWidth, MIN_WIDTH_COLUMN_DEFAULT);
            }
          });
          const borderWidth = index === 0 ? 0 : MIN_WIDTH_BORDER;
          freezeWidth += maxWidth + borderWidth;
        });
      }
    }

    if (tablesWidth.freeze) {
      setWidthLastColumn(tablesWidth.freeze);
    }
    const contentWidth = width > 0 ? width : tableContainerEl.clientWidth;
    // width > 40% content width -> default max: 40% content width
    const contentWidth40Percent = contentWidth * 0.4;
    freezeWidth =
      tablesWidth.freeze ||
      (freezeWidth > contentWidth40Percent
        ? contentWidth40Percent
        : freezeWidth);
    const mainWidth = Math.floor(contentWidth) - Math.floor(freezeWidth);

    setResizeFreezeWidth(tableEmpty.freeze ? '' : freezeWidth);
    setResizeMainWidth(tableEmpty.main ? '' : mainWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableEmpty.freeze, tableEmpty.main, width]);

  useEffect(() => {
    // Set the initial width for the table header and contents
    const { columns: columnsWidth } = attrs;
    const tableContainerEl = tableContainer.current;
    if (tableEmpty.freeze && tableEmpty.main) {
      setTableEmpty(getTableEmpty(tableContainerEl));
      return;
    }

    // Get table freeze & main
    const scrollable = tableContainerEl.getElementsByClassName('scrollable');
    const scrollableEls = [...scrollable];
    if (tableEmpty.freeze) {
      scrollableEls.shift();
    }
    if (tableEmpty.main) {
      scrollableEls.pop();
    }

    // Set width columns
    scrollableEls.forEach((scrollableEl) => {
      const isFreezeTable = scrollableEl.classList.contains(
        'freeze-table__freeze-content'
      );
      const thead = scrollableEl.querySelectorAll(
        'thead tr.freeze-table__header-all'
      );
      const tbody = scrollableEl.querySelectorAll(
        'tbody tr:not(.freeze-table__total)'
      );

      if (thead[thead.length - 1] && tbody[0]) {
        const th = [...thead[thead.length - 1].children];
        const td = [...tbody[0].children];

        if (th.length !== td.length) {
          return;
        }

        const maxLengthColumn = th.length - 1;
        const scrollableWidth = scrollableEl.style.width;

        th.forEach((item, index) => {
          const cellEl = item.getElementsByClassName(
            'freeze-table__cell-content'
          )[0];
          const { width: cellWidth, minWidth: cellMinWidth } = cellEl.style;

          // Get column name
          const columnTitle = findClassPrefixColumn(cellEl?.className);
          const cellName = columnTitle.replace(PREFIX_COLUMN, '');
          if (cellWidth || cellMinWidth) {
            if (isEmpty(columnsWidth[cellName])) {
              const widthColumn = {};
              if (cellWidth) {
                widthColumn.width = parseInt(cellWidth, 10);
              } else {
                widthColumn.minWidth = parseInt(cellMinWidth, 10);
              }
              dispatch({
                type: 'setColumnAttrs',
                payload: {
                  [cellName]: widthColumn,
                },
              });
            }

            return;
          }

          const isLastColumn =
            maxLengthColumn === index && (!isFreezeTable || tableEmpty.main);

          const widthGroupColumn = getWidthColumnInGroup(item);

          const freezeMinWidthColumn = isFreezeTable
            ? MIN_WIDTH_COLUMN_DEFAULT
            : td[index].offsetWidth;
          let maxWidth = Math.max(
            th[index].offsetWidth,
            freezeMinWidthColumn,
            widthGroupColumn
          );

          if (maxLengthColumn === index) {
            scrollableEl.style.width = 'auto';
            maxWidth = Math.max(
              th[index].offsetWidth,
              freezeMinWidthColumn,
              widthGroupColumn
            );
          }

          [th[index], td[index]].forEach((itemEl) => {
            const cellContent = itemEl.getElementsByClassName(
              'freeze-table__cell-content'
            )[0];

            if (itemEl.style.display === 'none') {
              const previousTr = cellContent.closest('tr').previousSibling;
              const columnName = findClassPrefixColumn(cellContent?.className);
              const column = columnName.replace(PREFIX_COLUMN, '');
              const itemGroup = previousTr.getElementsByClassName(
                columnName
              )[0];

              const minWidthColumn = isFreezeTable
                ? MIN_WIDTH_COLUMN_DEFAULT
                : MIN_WIDTH_COLUMN;
              maxWidth = FREE_WIDTH_COLUMNS.includes(column)
                ? itemGroup.offsetWidth
                : Math.max(maxWidth, itemGroup.offsetWidth, minWidthColumn);

              maxWidth =
                maxWidth < MAX_WIDTH_COLUMN ? maxWidth : MAX_WIDTH_COLUMN;

              // Set width columns on the header
              const keyWidth = isLastColumn ? 'minWidth' : 'width';
              itemGroup.style[keyWidth] = `${maxWidth}px`;
              dispatch({
                type: 'setColumnAttrs',
                payload: { [column]: { [keyWidth]: maxWidth } },
              });
            }

            if (FREE_WIDTH_COLUMNS.includes(cellName)) {
              maxWidth = cellContent.offsetWidth;
            }

            // Set width columns on the body
            const keyWidth = isLastColumn ? 'minWidth' : 'width';
            cellContent.style[keyWidth] = `${maxWidth}px`;
            dispatch({
              type: 'setColumnAttrs',
              payload: { [cellName]: { [keyWidth]: maxWidth } },
            });
          });
        });

        scrollableEl.style.width = scrollableWidth;
      }
      setScrollBarWidth(scrollableEl);
    });

    const tables = tableContainerEl.getElementsByTagName('TABLE');
    Array.from(tables).forEach((table) => {
      table.classList.add('table-width');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableEmpty.freeze, tableEmpty.main, freeze, main, dispatch]);

  return (
    <div ref={tableContainer} className={freezeTableClass}>
      <CustomScroller
        headerTop={top}
        width={resizeFreezeWidth}
        className={resizeFreezeClass}
      >
        {freeze}
      </CustomScroller>
      <div
        ref={resizeHandleRef}
        className={resizeHandleClass}
        onMouseDown={handleMouseDown}
      />
      <CustomScroller
        headerTop={top}
        width={resizeMainWidth}
        className={resizeMainClass}
      >
        {main}
      </CustomScroller>
      {empty && !loading && (
        <div className="freeze-table__empty d-flex align-items-center justify-content-center">
          {emptyMessage}
        </div>
      )}
      {loading && (
        <div className="freeze-table__loader">
          <Loader top={top + headerTable} />
        </div>
      )}
    </div>
  );
};

FreezeTable.propTypes = {
  /**
   * Freeze area of table
   */
  freeze: PropTypes.node.isRequired,
  /**
   * Main content of table
   */
  main: PropTypes.node.isRequired,
  /**
   * Space for navigation & page title, notification
   */
  top: PropTypes.number,
  /**
   * Set width for table
   * default 0, width of table will equal table.clientWidth
   */
  width: PropTypes.number,
  /**
   * default false, show message table empty
   */
  empty: PropTypes.bool,
  /**
   * Message to show when the table is empty
   */
  emptyMessage: PropTypes.string,
  /**
   * default false, show box loader
   */
  loading: PropTypes.bool,
  tablesWidth: PropTypes.oneOfType([PropTypes.object]),
  onResize: PropTypes.shape({
    setRowsHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    setTablesWidth: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    setColumnsWidth: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  }),
  variant: PropTypes.string,
};

FreezeTable.defaultProps = {
  top: 0,
  width: 0,
  empty: false,
  emptyMessage: '表示するデータがありません',
  loading: false,
  tablesWidth: {
    freeze: 0,
    main: 0,
  },
  onResize: {
    setRowsHeight: false,
    setTablesWidth: false,
    setColumnsWidth: false,
  },
  variant: '',
};

const ResizeBar = (props) => {
  const { attrs, dispatch } = useContext(TableContext);
  const { column, onResize } = props;

  let targetColumn = null;

  // Set width last column of freeze table
  const setWidthLastColumn = (columnResize, columnWidth) => {
    const { columns: columnsWidth, freezeColumns = [] } = attrs;
    const preLastColumn = freezeColumns[freezeColumns.length - 2];
    if (columnResize === preLastColumn) {
      const freezeWidth = freezeColumns.reduce((acc, item) => {
        let totalWidth = acc;
        totalWidth =
          acc +
          (columnsWidth[item]?.width || MIN_WIDTH_COLUMN) +
          MIN_WIDTH_BORDER;
        return totalWidth;
      }, 0);

      const { lastColumn, keyWidth, widthLastColumn } = getWidthLastColumn(
        {
          ...columnsWidth,
          [columnResize]: columnWidth,
        },
        freezeColumns,
        freezeWidth - MIN_WIDTH_BORDER
      );

      onResize.setColumnsWidth(lastColumn, widthLastColumn);

      dispatch({
        type: 'setColumnAttrs',
        payload: { [lastColumn]: { [keyWidth]: widthLastColumn } },
      });
    }
  };

  const onMouseMoveResizable = (e) =>
    requestAnimationFrame(() => {
      const columns = document.querySelectorAll(
        `.freeze-table .${targetColumn}`
      );
      const columnsNowrap = document.querySelectorAll(
        `.freeze-table .${targetColumn}.text-right`
      );

      // Get width columns
      let columnWidth = Math.max(
        MIN_WIDTH_COLUMN,
        e.clientX - columns[0].getBoundingClientRect().left,
        getWidthColumnInGroup(columns[0].closest('th'))
      );

      if (columnWidth > MAX_WIDTH_COLUMN) {
        columnWidth = MAX_WIDTH_COLUMN;
      }

      // Set width columns
      const widthContents = [];
      Array.from(columns).forEach((element) => {
        const el = element;
        el.style.width = `${columnWidth}px`;

        // Get width content
        if (columnsNowrap.length > 0 && el.childNodes[0].nodeName === '#text') {
          const div = document.createElement('DIV');
          div.innerHTML = el.innerHTML;
          div.style.display = 'inline-block';
          el.innerHTML = '';
          el.append(div);
          widthContents.push(div.offsetWidth);
        }
      });

      // Set min-width columns text-align right
      const widthContent = Math.max(...widthContents);
      if (columnsNowrap.length > 0 && widthContents.length > 0) {
        Array.from(columnsNowrap).forEach((element) => {
          const el = element;
          el.innerHTML = `${`<div style="min-width: ${widthContent}`}px">${
            el.innerText
          }</div>`;
        });
      }

      const scrollContainerEl = columns[0].closest('.scrollable');
      setScrollBarWidth(scrollContainerEl);

      // detect resize column width
      if (onResize.setRowsHeight) {
        onResize.setRowsHeight(column);
      }
      if (onResize.setColumnsWidth) {
        onResize.setColumnsWidth(column, columnWidth);
        setWidthLastColumn(column, { width: columnWidth });
        dispatch({
          type: 'setColumnAttrs',
          payload: {
            [column]: {
              width: columnWidth,
            },
          },
        });
      }

      // TODO: Last column resize
      // const tableContainerEl = document.querySelector('.freeze-table');
      // const lastColumn = tableContainerEl.querySelector(
      //   `.freeze-table__freeze-content .freeze-table__header th:last-child .${targetColumn}`
      // );
      // if (lastColumn && columnWidth > MIN_WIDTH_COLUMN) {
      //   const { left } = tableContainerEl.getBoundingClientRect();
      //   let freezeWidth = e.clientX - left;
      //   const contentWidth = tableContainerEl.offsetWidth;
      //   // width > 40% content width -> max: 40% content width
      //   const contentWidth40Percent = Math.ceil(contentWidth * 0.4);
      //   if (freezeWidth >= contentWidth40Percent) {
      //     freezeWidth = contentWidth40Percent;
      //   }
      //   const mainWidth = contentWidth - freezeWidth;

      //   const scrollableEls = tableContainerEl.querySelectorAll('.scrollable');
      //   scrollableEls[0].style.width = `${freezeWidth}px`;
      //   scrollableEls[1].style.width = `${mainWidth}px`;
      //   setScrollBarWidth(scrollableEls[1]);
      // }
    });

  const onMouseUpResizable = () => {
    window.removeEventListener('mousemove', onMouseMoveResizable);
    window.removeEventListener('mouseup', onMouseUpResizable);
  };

  const handleMouseDownResizable = (e, className) => {
    targetColumn = className;
    e.preventDefault();
    window.addEventListener('mousemove', onMouseMoveResizable);
    window.addEventListener('mouseup', onMouseUpResizable);
  };

  return (
    <div
      className="resize-handle"
      onMouseDown={(e) => handleMouseDownResizable(e, column)}
    />
  );
};

ResizeBar.propTypes = {
  column: PropTypes.string.isRequired,
  onResize: PropTypes.shape({
    setRowsHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    setColumnsWidth: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  }),
};

ResizeBar.defaultProps = {
  onResize: {
    setRowsHeight: false,
    setColumnsWidth: false,
  },
};

export { FreezeTable, ResizeBar };
