import helper from 'store/helper';
import types from './types';

const changeTab = (tab) => helper.createAction(types.CHANGE_TAB, { tab });
const changeMode = (mode) => helper.createAction(types.CHANGE_MODE, { mode });
const changePage = (page) => {
  return helper.createAction(types.CHANGE_PAGE, { page });
};

export default {
  changeTab,
  changeMode,
  changePage,
};
