/* eslint-disable camelcase */
import types from 'store/ltv/settings/order/types';
import helper from 'store/helper';

const initialState = {
  list: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null, // string | null,
};

const requestStart = (state) => {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: 'failed',
    error,
  };
};

const fetchList = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    list: data.detail,
    status: 'succeeded',
  };
};

const fetch = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    data,
    status: 'succeeded',
  };
};


const LtvOrderReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_SUCCEEDED]: fetch,
  [types.FETCH_LIST_SUCCEEDED]: fetchList,
});

export default LtvOrderReducer;
