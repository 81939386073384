import { isEmpty } from 'lodash';
import { FILTER_OPERATOR_EXACT } from 'services/consts';
import { validateBasic } from 'services/validations/validateService';
import {
  arrToStr,
  splitStringToArrayByComma,
  convertNumberToArrayDigits,
  checkValueValid,
} from 'services/utils';
import {
  numberInRange,
  numberHalfWidth,
  requiredValue,
  validAdId,
  notContainSpaces,
} from 'services/validations/commonValidations';
import {
  ROUTE_SETTING_FIELDS,
  INFLOW_MODE_AD,
  INFLOW_MODE_URL,
  INFLOW_MODE_IGNORE,
  DISPLAY_RATE_MIN,
  DISPLAY_RATE_MAX,
  DISPLAY_CHANNELS,
  PATTERN_DISPLAY_TABLE,
  PATTERN_DISPLAY_ROUTE_ENTRANCE,
  PATTERN_DISPLAY_ROUTE_EXIT,
  PATTERN_DISPLAY_TOTURIAL,
} from 'domain/log/route-analyze/consts';
import {
  PAGE_ID,
  PAGE_TITLE,
  PAGE_URL,
  RANK,
} from 'domain/settings/display-items';
import { convertArray2Object, formatDecimal, formatNumber } from 'domain/utils';
import { CONTENT_CATEGORY } from 'domain/fields';
import {
  PAGE_DELETED_DISPLAY_VALUE,
  PAGE_ID_OVER_LIMITED,
  UNREGISTERED_DISPLAY_VALUE,
  UNREGISTERED_IDS,
} from 'domain/consts';

const {
  DISPLAY_LAYER,
  DISPLAY_CHANNEL,
  INFLOW_MODE,
  START_PAGE_ID,
  TRANSIT_PAGE_ID,
  END_PAGE_ID,
  DISPLAY_ITEM,
  DISPLAY_RATE,
  MEDIA_ID,
  AD_GROUP1_ID,
  AD_GROUP2_ID,
  AD_ID,
  LINK_URL,
  INFLOW_AD,
} = ROUTE_SETTING_FIELDS;

const adFields = [MEDIA_ID, AD_GROUP1_ID, AD_GROUP2_ID, AD_ID];
const linkFields = [LINK_URL];

export const checkConversion = (conversions, pageId) => {
  return !isEmpty(conversions[pageId]);
};

export const checkSettingRoute = (settings) => {
  const isSettingRate = checkValueValid(settings[DISPLAY_RATE], ['', null]);
  const isSettingChannel = Object.values(settings[DISPLAY_CHANNEL]).some(
    (channel) => !!channel
  );
  const isSettingInflow =
    settings[INFLOW_MODE] !== INFLOW_MODE_IGNORE ||
    !isEmpty(settings[START_PAGE_ID]) ||
    !isEmpty(settings[TRANSIT_PAGE_ID]) ||
    !isEmpty(settings[END_PAGE_ID]);

  return { isSettingRate, isSettingChannel, isSettingInflow };
};

export const handleDisplayItemOptions = (items) => {
  return Object.keys(items)
    .reduce((acc, key) => {
      const { order, title, ebisRequired, allowSetting = true } = items[key];

      if (!allowSetting) return acc;

      return [
        ...acc,
        { order, text: title, name: key, required: ebisRequired },
      ];
    }, [])
    .sort((o1, o2) => o1.order - o2.order);
};

export const validateSettings = (settings) => {
  let error = {};
  const cloneSettings = { ...settings };
  const rule = {
    [DISPLAY_RATE]: [numberInRange(DISPLAY_RATE_MIN, DISPLAY_RATE_MAX)],
  };

  // add rule validate inflow url
  if (settings[INFLOW_MODE] === INFLOW_MODE_URL) {
    cloneSettings[LINK_URL] = settings[LINK_URL].value || '';
    rule[LINK_URL] = [
      requiredValue(
        '流入経路指定で遷移元サイトを指定した場合は、遷移元サイトを入力してください。'
      ),
    ];
  }

  error = validateBasic({
    data: cloneSettings,
    rule,
    label: { [DISPLAY_RATE]: '表示する経路の割合', [LINK_URL]: '遷移元サイト' },
  });

  // validate inflow ad
  if (settings[INFLOW_MODE] === INFLOW_MODE_AD) {
    if (
      isEmpty(settings[MEDIA_ID]) &&
      isEmpty(settings[AD_GROUP1_ID]) &&
      isEmpty(settings[AD_GROUP2_ID]) &&
      isEmpty(settings[AD_ID])
    ) {
      error[INFLOW_AD] =
        '流入経路指定で広告を指定した場合は、広告の検索条件を指定してください';
    } else if (!isEmpty(settings[AD_ID])) {
      const values = splitStringToArrayByComma(settings[AD_ID], false);
      const ruleAdId = { [AD_ID]: [validAdId(), notContainSpaces()] };
      values.every((value) => {
        const errorAdId = validateBasic({
          data: { [AD_ID]: value },
          rule: ruleAdId,
          label: { [AD_ID]: '広告ID' },
        });

        if (!isEmpty(errorAdId)) {
          error[AD_ID] = errorAdId[AD_ID];
          return false;
        }
        return true;
      });
    }
  }

  return error;
};

export const prepareFiltersForAPI = (data) => {
  const ignoreFilterFields = [DISPLAY_ITEM, INFLOW_MODE];
  return Object.keys(data).reduce((acc, field) => {
    let isSkipField = ignoreFilterFields.includes(field);
    if (adFields.includes(field) && data[INFLOW_MODE] !== INFLOW_MODE_AD) {
      isSkipField = true;
    }
    if (linkFields.includes(field) && data[INFLOW_MODE] !== INFLOW_MODE_URL) {
      isSkipField = true;
    }

    if (isSkipField) return acc;

    const item = data[field];
    let value = item;
    let operator = null;

    switch (field) {
      case DISPLAY_CHANNEL:
        value = Object.keys(item || {}).filter((channel) => item[channel]);
        break;

      case AD_ID:
        operator = FILTER_OPERATOR_EXACT;
        value = item ? splitStringToArrayByComma(item, false) : [];
        break;

      case LINK_URL:
        operator = item.operator;
        value = item.value ? splitStringToArrayByComma(item.value, false) : [];
        break;

      case MEDIA_ID:
      case AD_GROUP1_ID:
      case AD_GROUP2_ID:
        value = Object.keys(item || {}) || [];
        break;

      case START_PAGE_ID:
      case TRANSIT_PAGE_ID:
      case END_PAGE_ID:
        value = item?.id || '';
        break;

      default:
        break;
    }

    if (!checkValueValid(value, [null, undefined, [], {}, ''])) return acc;

    return [...acc, { field, value, operator }];
  }, []);
};

export const prepareFiltersForUI = (filters, masterdata) => {
  return filters.reduce((accFilter, filter) => {
    const { field, operator, value } = filter;
    let valueConverted = value;

    switch (field) {
      case DISPLAY_CHANNEL:
        valueConverted = DISPLAY_CHANNELS.reduce((acc, item) => {
          return {
            ...acc,
            [item.key]: value.includes(item.key),
          };
        }, {});
        break;

      case AD_ID:
        valueConverted = arrToStr(value);
        break;

      case LINK_URL:
        valueConverted = {
          operator,
          value: arrToStr(value),
        };
        break;

      case MEDIA_ID:
      case AD_GROUP1_ID:
      case AD_GROUP2_ID: {
        const keyMasterdata = {
          [MEDIA_ID]: 'media',
          [AD_GROUP1_ID]: 'adGroup1',
          [AD_GROUP2_ID]: 'adGroup2',
        }[field];

        valueConverted = value
          .filter((id) => id in masterdata[keyMasterdata].options)
          .reduce((acc, id) => {
            return { ...acc, [id]: masterdata[keyMasterdata].options[id] };
          }, {});

        break;
      }

      case START_PAGE_ID:
      case TRANSIT_PAGE_ID:
      case END_PAGE_ID:
        valueConverted = value
          ? masterdata.page.options[value] || { id: value, name: `${value}：` }
          : null;
        break;

      default:
        break;
    }

    return { ...accFilter, [field]: valueConverted };
  }, {});
};

export const createLayers = (settings, pattern) => {
  const isSettingEntrance = !!settings[START_PAGE_ID];
  const isSettingExit = !!settings[END_PAGE_ID];

  const callbackConvertNumber = (i) => {
    const index = i + (pattern === PATTERN_DISPLAY_ROUTE_EXIT ? 1 : 2);
    return { field: `transit_${index}`, name: `${index}ページ目` };
  };

  const layerTransits = convertNumberToArrayDigits(
    settings[DISPLAY_LAYER],
    callbackConvertNumber
  );

  const layerStart = { field: 'start', name: '始点ページ' };
  const layerEnd = { field: 'end', name: '終点ページ' };
  const defaultLayers = [layerStart, ...layerTransits];

  switch (pattern) {
    case PATTERN_DISPLAY_TABLE:
      if (isSettingEntrance && isSettingExit) {
        return [...defaultLayers, layerEnd];
      }
      return defaultLayers;

    case PATTERN_DISPLAY_ROUTE_ENTRANCE:
      return defaultLayers;

    case PATTERN_DISPLAY_ROUTE_EXIT:
      return [...layerTransits.reverse(), layerEnd];

    default:
      return defaultLayers;
  }
};

export const getPatternDisplay = (settings) => {
  const isSettingInfollow = settings[INFLOW_MODE] !== INFLOW_MODE_IGNORE;
  const isSettingEntrance = !!settings[START_PAGE_ID];
  const isSettingTransit = !!settings[TRANSIT_PAGE_ID];
  const isSettingExit = !!settings[END_PAGE_ID];

  if (isSettingInfollow) {
    if (isSettingEntrance) {
      if (isSettingTransit) {
        if (isSettingExit) {
          return PATTERN_DISPLAY_TABLE;
        }
        return PATTERN_DISPLAY_ROUTE_ENTRANCE;
      }
      if (isSettingExit) {
        return PATTERN_DISPLAY_TABLE;
      }
      return PATTERN_DISPLAY_ROUTE_ENTRANCE;
    }
    if (isSettingTransit) {
      if (isSettingExit) {
        return PATTERN_DISPLAY_ROUTE_EXIT;
      }
      return PATTERN_DISPLAY_TABLE;
    }
    if (isSettingExit) {
      return PATTERN_DISPLAY_ROUTE_EXIT;
    }
    return PATTERN_DISPLAY_TABLE;
  }
  if (isSettingEntrance) {
    if (isSettingTransit) {
      if (isSettingExit) {
        return PATTERN_DISPLAY_TABLE;
      }
      return PATTERN_DISPLAY_ROUTE_ENTRANCE;
    }
    if (isSettingExit) {
      return PATTERN_DISPLAY_TABLE;
    }
    return PATTERN_DISPLAY_ROUTE_ENTRANCE;
  }
  if (isSettingTransit) {
    if (isSettingExit) {
      return PATTERN_DISPLAY_ROUTE_EXIT;
    }
    return PATTERN_DISPLAY_TABLE;
  }
  if (isSettingExit) {
    return PATTERN_DISPLAY_ROUTE_EXIT;
  }

  return PATTERN_DISPLAY_TOTURIAL;
};

export const calculateHeightRouteBack = (
  row,
  actualRow,
  currentRow,
  flowRow
) => {
  const callbackConvertNumber = (i) => currentRow - (i + 1);
  const alignBottom = 24;
  const lineArrow = 2;
  const level = currentRow - (row[flowRow]?.gridRow ?? flowRow);

  if (level < 0) return 0;

  const heightFixed =
    lineArrow + level * alignBottom + row[actualRow]?.height / 2;

  const rowIndexs = convertNumberToArrayDigits(
    level,
    callbackConvertNumber
  ).reverse();

  return rowIndexs.reduce((acc, rowIndex, index) => {
    const { height } = row[rowIndex] || {};
    return acc + (index === 0 ? height / 2 : height);
  }, heightFixed);
};

export const prepareDataDisplay = (prefix, data) => {
  const pageId = data[`${prefix}_${PAGE_ID}`];
  const isPageOverLimited = pageId === PAGE_ID_OVER_LIMITED;

  // Page title
  const keyPageTitle = `${prefix}_${PAGE_TITLE}`;
  let pageTitle = data[keyPageTitle];
  if (pageId) {
    if (isPageOverLimited) {
      pageTitle = '計測サイトページ登録上限超過';
    } else {
      pageTitle = data[`${prefix}_unregistered_page_flag`]
        ? PAGE_DELETED_DISPLAY_VALUE
        : data[keyPageTitle] || UNREGISTERED_DISPLAY_VALUE;
    }
  }

  // Content category
  const keyContentCategory = `${prefix}_${CONTENT_CATEGORY}`;
  const keyContentCategoryId = `${prefix}_${CONTENT_CATEGORY}_id`;
  let contentCategory = data[`${keyContentCategory}_name`];
  if (UNREGISTERED_IDS.includes(data[keyContentCategoryId])) {
    contentCategory = UNREGISTERED_DISPLAY_VALUE;
  }

  return {
    ...data,
    [keyPageTitle]: pageTitle,
    [keyContentCategory]: contentCategory,
  };
};

export const createHeaders = (headers, createComponent) => {
  return headers.map((header) => {
    const newHeader = { ...header };
    const { field: prefix } = header;
    if (prefix === 'route') {
      newHeader.children = header.children.map((item) => {
        const newItem = { ...item };
        if (newItem.field === RANK) {
          newItem.width = '60px';
        }
        return newItem;
      });
    } else {
      newHeader.children = header.children.map((item) => {
        const newItem = { ...item };
        const { field } = item;
        const pageTitle = `${prefix}_${PAGE_TITLE}`;
        const pageId = `${prefix}_${PAGE_ID}`;
        const contentCategory = `${prefix}_${CONTENT_CATEGORY}`;

        if (field === pageId) {
          newItem.callback = (data) => {
            return {
              ...data,
              [field]: createComponent(prefix, PAGE_ID, data),
            };
          };
        }
        if (field === pageTitle) {
          newItem.type = 'inlineTruncate';
          newItem.callback = (data) => {
            if (
              data[`${prefix}_unregistered_page_flag`] ||
              !data[`${prefix}_${PAGE_URL}`] ||
              !data[pageId] ||
              data[pageId] === PAGE_ID_OVER_LIMITED
            ) {
              return {
                ...data,
                [field]: prepareDataDisplay(prefix, data)[field],
              };
            }

            return {
              ...data,
              [field]: createComponent(prefix, PAGE_TITLE, data),
            };
          };
        }
        if (field === contentCategory) {
          newItem.callback = (data) => {
            return {
              ...data,
              [field]: prepareDataDisplay(prefix, data)[field],
            };
          };
        }
        return newItem;
      });
    }
    return newHeader;
  }, []);
};

export const convertArrayToObject = (arrs) => {
  return convertArray2Object(arrs, 'id', (item) => ({
    ...item,
    name: `${item.id}：${item.name}`,
  }));
};

export const prepareDataForRoute = (
  pattern,
  columns,
  dataRendered,
  dataRendering
) => {
  const firstColumn = 1;
  const lastColumn = columns.length;
  const isPatternEntrance = pattern === PATTERN_DISPLAY_ROUTE_ENTRANCE;
  const isPatternExit = pattern === PATTERN_DISPLAY_ROUTE_EXIT;

  return dataRendering.reduce((acc, row, index) => {
    const actualRow = index + 1;
    if (acc[actualRow]) return acc;

    const dataRow = {};
    let item = {};
    let numItemDisplay = 0;
    let exitRow = false;
    let flowRow = null;
    let gridRow = acc[actualRow]?.gridRow || actualRow;

    columns.every((column, cIndex) => {
      const gridColumn = cIndex + 1;
      const { field, name } = column;

      if (isEmpty(row[`${field}_page_id`])) {
        dataRow[field] = {};
        return true;
      }

      const rowFormated = prepareDataDisplay(field, row);
      const data = {
        page_id: rowFormated[`${field}_page_id`],
        page_title: rowFormated[`${field}_page_title`],
        page_url: rowFormated[`${field}_page_url`],
        content_category: rowFormated[`${field}_content_category`],
        cnt: formatNumber(rowFormated[`${field}_cnt`]),
        rate: formatDecimal(+rowFormated[`${field}_rate`] * 100),
      };

      let connectRow = row[`${field}_connect_row`] || null;
      if (connectRow && connectRow === actualRow) {
        connectRow = null;
      }

      const exitFlag =
        isPatternEntrance && (row[`${field}_exit_flag`] || false);

      item = {
        exitFlag,
        data,
        gridRow,
        gridColumn,
        flowRow: connectRow,
      };

      if (connectRow && connectRow !== actualRow) {
        flowRow = connectRow;
      }

      const { page_id: pageId } = acc[connectRow]?.dataRow?.[field]?.data || {};
      if (exitFlag && connectRow && pageId === data.page_id) {
        return !exitFlag; // return false will exits the loop
      }

      numItemDisplay += 1;

      dataRow[field] = {
        ...item,
        column: name,
        exitFlag: false,
        startFlag:
          isPatternEntrance && gridRow === 1 && gridColumn === firstColumn,
        endFlag: isPatternExit && gridRow === 1 && gridColumn === lastColumn,
      };

      return !exitFlag; // return false will exits the loop
    });

    if (item.exitFlag) {
      const field = 'exit';
      const rowFormated = prepareDataDisplay(field, row);
      exitRow = numItemDisplay === 0;

      dataRow[field] = {
        ...item,
        flowRow: exitRow ? item.flowRow : gridRow,
        gridColumn: item.gridColumn + 1,
        data: {
          ...item.data,
          cnt: formatNumber(rowFormated[`${field}_cnt`]),
          rate: formatDecimal(+rowFormated[`${field}_rate`] * 100),
        },
      };
    }

    const {
      gridRow: prevGridRow = 0,
      exitRow: prevExitRow = false,
      flowRow: prevFlowRow = null,
    } = acc[actualRow - 1] || {};

    if (isPatternEntrance) {
      const isCombineRow = exitRow && prevExitRow && prevFlowRow === flowRow;
      gridRow = isCombineRow && prevGridRow > 0 ? prevGridRow : prevGridRow + 1;
    }

    return {
      ...acc,
      [actualRow]: {
        gridRow,
        dataRow,
        exitRow,
        flowRow,
      },
    };
  }, dataRendered);
};
