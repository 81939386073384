import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  GridTableContext,
  GridTableItemContext,
} from 'views/organism/GridTable/GridTableContext';
import GirdTableColumn from 'views/organism/GridTable/components/GirdTableColumn';

function GridTableHeader() {
  const { dispatch, settings, rows } = useContext(GridTableContext);
  const { headers } = useContext(GridTableItemContext);
  const { selectedRows, hasSelectRowFunc } = settings;

  const [selected, setSelected] = useState({});

  const onSelect = useCallback(
    (isSelected, field) => {
      setSelected({ ...selected, [field]: { checked: isSelected } });

      if (hasSelectRowFunc) {
        dispatch({
          type: 'setSelectedRow',
          payload: isSelected
            ? rows
                .filter((row) => !row.isDisabledCheckbox)
                .map((row) => row.rowId)
            : [],
        });
        dispatch({ type: 'setHighlightRow', payload: [] });
      }
    },
    [dispatch, hasSelectRowFunc, rows, selected]
  );

  useEffect(() => {
    if (!hasSelectRowFunc) return;

    const field = 'rowId';
    const field2 = 'rowsId';
    const index = headers.findIndex((header) => header.field === field);
    if (index > -1) {
      setSelected({
        ...selected,
        [field]: {
          checked: !isEmpty(selectedRows),
          variant:
            !isEmpty(selectedRows) &&
            !isEmpty(rows) &&
            selectedRows.length < rows.length
              ? 'mixed'
              : '',
        },
      });
    }
    const index2 = headers.findIndex((header) => header.field === field2);
    if (index2 > -1) {
      setSelected({
        ...selected,
        [field2]: {
          checked: !isEmpty(selectedRows),
          variant:
            !isEmpty(selectedRows) &&
            !isEmpty(rows) &&
            selectedRows.length < rows.length
              ? 'mixed'
              : '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectRowFunc, headers, rows, selectedRows]);

  return useMemo(() => {
    return (
      <div role="row" className="grid-table__row">
        {headers.map((header) => {
          const { field } = header;
          return (
            <GirdTableColumn
              key={field}
              data={{ ...header, ...selected[field], onSelect }}
            />
          );
        })}
      </div>
    );
  }, [headers, selected, onSelect]);
}

GridTableHeader.propTypes = {};

GridTableHeader.defaultProps = {};

export default React.memo(GridTableHeader);
