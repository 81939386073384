import { chain, isEmpty, isArray } from 'lodash';
import {
  CONVERSIONIDS,
  CONVERSION_ID,
  CONVERSION_TITLE,
  PAGE_ID,
  RANK,
  RCV_DATA,
  RCV_MODEL_OPTION,
  AD_GROUP1,
  AD_GROUP2,
  AD_NOTE,
} from 'domain/fields';
import {
  SETTING_LABELS,
  MAX_CONVERSION_DISPLAY,
  RCV_PATTERN_CUSTOM,
  RCV_TOTAL,
} from 'domain/system-setting/consts-display-setting';
import { displaySettingVisionRule } from 'services/validations/systemSettingRules';
import { validateBasic } from 'services/validations/validateService';
import { SYSTEM_RESIST_BLACKLIST } from 'domain/backlist';
import { getErrorMessageByCode } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';

export const isCorrectRcvTotal = (total) => {
  return Math.abs(total - RCV_TOTAL) <= 0.001;
};

export const prepareLabelForDisplay = (data = {}, isAllowedSettingDisplay) => {
  const settingLabels = isAllowedSettingDisplay
    ? SETTING_LABELS
    : SETTING_LABELS.filter(
        (item) => ![AD_GROUP1, AD_GROUP2, AD_NOTE].includes(item.name)
      );
  return settingLabels.map((item) => {
    return {
      ...item,
      value: data[item.name] ?? item.title,
    };
  }, []);
};

export const prepareLabelForSetting = (data = {}) => {
  return SETTING_LABELS.reduce((acc, item) => {
    return {
      ...acc,
      [item.name]: data[item.name] || '',
    };
  }, {});
};

export const prepareConversionForDisplay = (conversions = []) => {
  const conversionsDisplay = conversions.slice(0, MAX_CONVERSION_DISPLAY);
  return conversionsDisplay.reduce((acc, item) => {
    return [
      ...acc,
      {
        value: `${item[RANK]}. ${item[CONVERSION_TITLE]}`,
      },
    ];
  }, []);
};

export const prepareConversionForSetting = (conversions = []) => {
  return conversions.reduce((acc, item) => {
    return [
      ...acc,
      {
        ...item,
        name: `${item[CONVERSION_TITLE]}（対応ページID = ${item[PAGE_ID]}）`,
      },
    ];
  }, []);
};

export const prepareDataVisionForRequest = (data = {}) => {
  return {
    ...data,
    [CONVERSIONIDS]: data[CONVERSIONIDS].map((item) => item[CONVERSION_ID]),
  };
};

export const prepareDataRcvForRequest = (data = {}) => {
  const pattern = data[RCV_MODEL_OPTION];
  return {
    ...data,
    [RCV_DATA]: data[RCV_DATA][pattern].reduce((rAcc, cells, rIndex) => {
      if (rIndex === 0) return rAcc; // Skip first row

      // ignore value by row & cell
      const value = cells.reduce((cAcc, cell, cIndex) => {
        if (cIndex > rIndex) return cAcc;
        return [...cAcc, cell];
      }, []);

      return [...rAcc, value];
    }, []),
  };
};

export const validateDataVision = (data) => {
  const rule = displaySettingVisionRule;
  const label = SETTING_LABELS.reduce((acc, item) => {
    return { ...acc, [item.name]: `【${item.title}】` };
  }, {});

  // Check common
  const error = validateBasic({ data, rule, label });

  // Check duplicate value
  const labelValue = prepareLabelForSetting(data);
  const values = Object.values(labelValue);
  if (new Set(values).size !== values.length) {
    // Get field has value duplicate
    const fieldDuplicate = chain(labelValue)
      .mapValues((value, field) => {
        return values.indexOf(value) !== values.lastIndexOf(value) ? field : '';
      })
      .pickBy((value) => value !== '')
      .value();

    // Set error message
    Object.entries(fieldDuplicate).forEach(([field]) => {
      if (!error[field]) {
        const errorMessage = `登録された${label[field]}は他の「ラベル名」と重複しているため変更してください`;
        error[field] = errorMessage;
      }
    });
  }

  // Check blacklist value
  const backlist = [
    ...SYSTEM_RESIST_BLACKLIST,
    `直接効果(${data[AD_GROUP1]})`,
    `直接効果(${data[AD_GROUP2]})`,
    `間接効果(${data[AD_GROUP1]})`,
    `間接効果(${data[AD_GROUP2]})`,
    `初回接触(${data[AD_GROUP1]})`,
    `初回接触(${data[AD_GROUP2]})`,
  ];

  // get field has value in backlist
  const fieldBacklist = chain(labelValue)
    .mapValues((value, field) => {
      return backlist.includes(value) ? field : '';
    })
    .pickBy((value) => value !== '')
    .value();

  // Set error message
  Object.entries(fieldBacklist).forEach(([field]) => {
    if (!error[field]) {
      const errorMessage = `登録された${label[field]}は他の「表示項目」で利用されているため変更してください`;
      error[field] = errorMessage;
    }
  });

  return error;
};

export const validateDataRcv = (data) => {
  const pattern = data[RCV_MODEL_OPTION];
  if (pattern !== RCV_PATTERN_CUSTOM) return {};

  const rowStart = 2;
  const error = data[RCV_DATA].reduce((rAcc, cells, rIndex) => {
    let total = 0;
    const rowIndex = rowStart + rIndex;

    // Check cell is empty
    const errorsByCell = cells.reduce((cAcc, value, cIndex) => {
      const cellIndex = cIndex + 1;
      if (value === '') {
        return {
          ...cAcc,
          [cellIndex]: `※【${cellIndex}列目のデータ】は半角整数の数字で入力してください。`,
        };
      }

      total += +value;
      return cAcc;
    }, {});

    // set error message for cell
    if (!isEmpty(errorsByCell)) {
      return { ...rAcc, ...errorsByCell };
    }

    // set error message for row
    if (!isCorrectRcvTotal(total)) {
      return {
        ...rAcc,
        [`${rowIndex}-total`]: `※【${rowIndex}行目のデータ】再配分CV算出用の比率は1行の合計を100になるように入力してください。`,
      };
    }

    return rAcc;
  }, {});

  return error;
};

export const convertErrorsVision = (errors) => {
  if (isEmpty(errors) || !isArray(errors)) return {};

  const label = SETTING_LABELS.reduce((acc, item) => {
    return { ...acc, [item.name]: item.title };
  }, {});

  return errors.reduce((acc, error) => {
    const [field] = error.field.split('.');

    const errorMessage = getErrorMessageByCode(error, messageError, {
      label: label[field],
    });

    return { ...acc, [field]: errorMessage };
  }, {});
};

export const convertErrorsRcv = (errors) => {
  if (isEmpty(errors) || !isArray(errors)) return {};

  return errors.reduce((acc, error) => {
    const { code, field } = error;
    if (code === 'SUM_RCV_ROW_DATA_CHECK') {
      const [, rowIndex] = field.split('.');
      return {
        ...acc,
        [`${rowIndex}-total`]: `※【${rowIndex}行目のデータ】再配分CV算出用の比率は1行の合計を100になるように入力してください。`,
      };
    }

    return acc;
  }, {});
};
