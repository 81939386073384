/* eslint-disable camelcase */
import types from 'store/user-management/types';
import helper from 'store/helper';
import { communicationStatus } from 'services/utils';

const { IDLE, LOADING, SUCCEEDED, FAILED } = communicationStatus;

const initialState = {
  byIds: {},
  settings: {
    isShowSettingModal: false,
    pagination: {
      totalItems: 0,
      currentPage: 1,
    },
    totalUserAgent: 0,
    totalUserNormal: 0,
  },
  selected: null,
  apiStatus: {
    list: {
      status: IDLE,
    },
  },
  error: null, // string | null,
};

const requestStart = (state, action) => {
  const { isFetchList } = action.payload;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      list: {
        ...state.apiStatus.list,
        status: isFetchList ? LOADING : state.apiStatus.list.status,
      },
    },
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      list: {
        ...state.apiStatus.list,
        status: FAILED,
      },
    },
    error,
  };
};

const fetchListRequest = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        currentPage: page,
      },
    },
    apiStatus: {
      ...state.apiStatus,
      list: {
        ...state.apiStatus.list,
        status: LOADING,
      },
    },
  };
};

const fetchListSucceeded = (state, action) => {
  const { byIds, metadata } = action.payload;
  const data = {};
  byIds.forEach((view) => {
    const { user_id } = view;
    data[user_id] = view;
  });
  return {
    ...state,
    byIds: data,
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        totalItems: metadata.count_all_user,
      },
      totalUserAgent: metadata.count_agent_user,
      totalUserNormal: metadata.count_normal_user,
    },
    apiStatus: {
      ...state.apiStatus,
      list: {
        ...state.apiStatus.list,
        status: SUCCEEDED,
      },
    },
  };
};

const changeSelectedItem = (state, action) => {
  const id = action.payload;

  return {
    ...state,
    selected: id,
  };
};

const showHideSettingModal = (state, action) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      isShowSettingModal: action.payload,
    },
    error: action.payload ? null : state.error,
  };
};

const UserManagementReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_LIST]: fetchListRequest,
  [types.FETCH_LIST_SUCCEEDED]: fetchListSucceeded,
  [types.CHANGE_SELECTED_ITEM]: changeSelectedItem,
  [types.SHOW_HIDE_MODAL]: showHideSettingModal,
});

export default UserManagementReducer;
