import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LOG_PAGE_ANALYZE } from 'services/routes/constants';

const PageAnalyzeApiFactory = () => {
  const { endpoint } = pages[LOG_PAGE_ANALYZE];

  return {
    getData: async (request) => Api.postRequest(endpoint, request),
  };
};

const PageAnalyzeApi = PageAnalyzeApiFactory();
export default PageAnalyzeApi;
