// Common error
export const IMPORT_ERROR = '処理に失敗しました。再度お試しください。';
export const FILE_REQUIRED = 'CSVファイルがアップロードされていません。';
export const FILE_EXTENSION_CHECK = 'CSVファイルをアップロードしてください。';
export const FILE_EXIST_CHECK = 'CSVファイルをアップロードしてください。';
export const FILE_SIZE_CHECK = '1ファイルあたり{size}MB以下。';
export const FILE_MAX_LINE_CHECK = 'CSVの登録上限は、{length} 行までです。';
export const FILE_ENCODING_CHECK =
  'CSVファイルの文字コードはSJIS形式にしてください。';
export const FILE_INVALID = FILE_ENCODING_CHECK;
export const FILE_HEADER_VALID_CHECK =
  'CSVフォーマットの形式が異なります。<br/><a href="{link}">最新フォーマット</a>でCSVを再アップロードしてください。';
export const FILE_CSV_HEADER_VALID_CHECK =
  'CSVフォーマットの形式が異なります。 <br/> 上記「CSVファイル」よりCSVをダウンロードし、再度アップロードしてください。';
export const FILE_HEADER_INVALID =
  'CSVフォーマットの形式が異なります。[CSVフォーマット]ボタンより最新のフォーマットをダウンロードし、再度アップロードしてください。';
export const FILE_CONTENT_VALID_CHECK =
  '{label}登録エラーが{length}件あります。対応方法を参考に、修正したCSVをアップロードしてください。';
export const TOO_MAX_ERROR =
  '{label}エラーが{length}件以上あります。<a href="{link}" target="_blank" rel="noopener noreferrer">こちら</a>を参考に、修正したCSVをアップロードしてください。';

export const FILE_OVER_MAX_LINE = 'CSVの登録上限は、{length}行までです。';
// Content error
export const NUM_CHECK = '{label}は半角英数の整数で入力してください。';
export const LIMIT_REGISTER_CHECK =
  '{label}の登録上限数{index.0}件を超えてしまうため、登録出来ません。';
export const REGISTER_CHECK =
  '{label}に使える文字は半角英数字と「_」「-」のみです。'; // Partern check:  /^[0-9a-zA-Z_-]+$/
export const UNIQUE_CHECK =
  '利用されている{label}です。別の{label}を入力してください。';
export const EXIST_CHECK =
  '{label}が存在しません。登録済みの{label}を指定してください。';
export const EMPTY_CHECK =
  '{label}は必須入力項目です。{label}を入力してください。';
export const SPTAB_CHECK =
  '{label}を「スペース、タブ、改行」のみで登録することはできません。<br/>{label}の入力欄に「スペース、タブ、改行」以外の文字列を入力してください。';
export const MAX_LENGTH_CHECK =
  '{label}の文字数を{index.0}文字以内になるよう修正してください。';
export const CONTROL_CHARACTER_CHECK =
  '{label}に制御文字を含めて登録することはできません。制御文字を削除してください。';
export const URL_CHECK =
  '{label}に、不正なURLが入力されています。<br/>{label}に入力した内容について、以下ご確認ください。<br/>・半角スペース、全角スペースは除いてください。<br/>・全角の文字列は除いてください。<br/>・{label}の始まりは、「http、https」で始まるようにしてください。';
export const LINE_CONTROL_CHARACTER_CHECK = '制御文字を削除してください。';
export const LINE_VALUE_IS_EXISTS = '入力された{label}はすでに登録済みです。';
export const LINE_VALUE_DUPLICATED =
  '登録された{label}の内容が一部重複しています。';
export const LINE_VALUE_IS_EMPTY = '{label}を入力してください。';
export const LINE_VALUE_NOT_ALLOWED =
  '{label}に[未登録]は使用できません。[未登録]とならないよう修正してください。';
export const LINE_SPTAB_CHECK =
  '{label}を「スペース、タブ、改行」のみで登録することはできません。「スペース、タブ、改行」以外の文字列を入力してください。';
export const LINE_OVER_MAX_LENGTH =
  '{label}は{length}字以下で入力してください。';
export const PAGE_REGISTED_BY_CONV =
  'コンバージョン計測が設定されているため、サイトコンテンツ計測対象を設定できません。';
export const PAGE_ID_REGIST_AUTO_PAGE_PREFIX =
  '{label}に使える文字は「#」と半角数値のみです。';
export const PAGE_ID_REGIST_AUTO_PAGE_OVER = '#0 は指定できません。';
export const LINE_NOT_EXISTS_DB =
  '{label}が存在しません。登録済みの{label}を指定してください。';
// Content error - PV計測タグ
export const PAGE_ID_LIMIT_REGISTER_CHECK = LIMIT_REGISTER_CHECK;
export const PAGE_ID_REGISTER_CHECK = REGISTER_CHECK;
export const PAGE_ID_UNIQUE_CHECK = UNIQUE_CHECK;
export const CONTENT_CATEGORY_NAME_EXIST_CHECK = EXIST_CHECK;
export const PAGE_ID_CONTAIN_0_CHECK = 'ページID「0」は登録できません';
export const LINE_NOT_EMPTY =
  'ファイルに改行を含めて登録することはできません。改行を削除してください。';
export const LINE_INVALID = `入力された内容が不正で登録することはできません。`;
export const ELEMENT_ENCODING_CHECK = `使用できない文字（特殊な記号や常用外漢字など）が含まれています`;
