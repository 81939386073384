import React, { useCallback, useMemo, useRef, useState } from 'react';
import { func, bool, shape, string } from 'prop-types';
import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  AD_CV_COST_MODE,
  REJECT_HOST,
  SESSION_EXPIRE,
  SESSION_EXPIRE_MAX_VALUE,
  EXPIREDAYS,
  CONV_DUPLICATION_FLAG,
  QUERY_PARAMS,
  DIRECTORY_INDEX,
  ALERT_AUTH_IDS,
  HEADER_USER,
  CROSS_DEVICE_MODE,
  CROSS_DEVICE_MODE_AI,
  CROSS_DEVICE_MODE_MEMBER,
  OPTION_AD_CV_COST_MODE_NONE,
  OPTION_AD_CV_COST_MODE_DIRECT,
  OPTION_AD_CV_COST_MODE_AVERAGE,
  CROSS_DEVICE_MODE_LABELS,
  AD_CV_COST_MODE_LABELS,
  INFO_FORM_MEASUREMENT_SETTING as FORM,
  MEASUREMENT_MODAL_SETTING_IDS as MODAL_IDS,
  CLICK_ID_SETTING,
  CLICK_MEDIA_SETTING_MEDIA,
} from 'domain/system-setting/consts';

import {
  validateMeasurementConfigSetting,
  validateMeasurementLogSetting,
  getErrorMessageResponse,
  formatUsersData,
} from 'services/system-setting/systemSettingServices';

import FormGroup from 'views/molecules/FormGroup/FormGroup';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import systemSettingActions from 'store/system-setting/actions';
import iconDataUpdateStatus from 'assets/images/data-update-status.svg';
import { FALSE_FLAG, TRUE_FLAG } from 'domain/consts';
import CheckboxElement from 'views/atoms/CheckboxElement';
import SettingModal from './Modal';
import AlertAuthSetting from './AlertAuthSetting';

function MeasurementSettingModal(props) {
  const {
    isShow,
    onHide,
    isLoading,
    data,
    modalId,
    isAllowedSettingMeasurementOption,
    isCapiSingleContract,
  } = props;
  const dispatch = useDispatch();
  const [valueForm, setValueForm] = useState(data);
  const [errorForm, setErrorForm] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [medium, setMedium] = useState(
    get(data[CLICK_ID_SETTING], 'medium_ids', [])
  );
  const enableFlag = get(valueForm[CLICK_ID_SETTING], 'enable_flag', false);

  const handleChangeValue = useCallback((name, newValue) => {
    setValueForm((prevValueForm) => ({
      ...prevValueForm,
      [name]: newValue,
    }));
    setErrorForm((prevValueForm) => ({
      ...prevValueForm,
      [name]: null,
    }));
  }, []);

  const handleResponseUpdate = ({ errors }) => {
    setIsUpdating(false);
    if (isEmpty(errors)) {
      onHide();
    } else {
      const errorResponse = getErrorMessageResponse(errors);
      setErrorForm(errorResponse);
    }
  };

  // handle for not allow measurement option for capi single contract
  const isAllowSettingOption =
    isAllowedSettingMeasurementOption && !isCapiSingleContract;

  const handleSubmit = () => {
    let dataRequest = {
      reject_host: valueForm[REJECT_HOST].replaceAll('\n', ','),
      session_expire: isCapiSingleContract
        ? SESSION_EXPIRE_MAX_VALUE
        : +valueForm[SESSION_EXPIRE],
      expiredays: +valueForm[EXPIREDAYS],
      conv_duplication_flag: valueForm[CONV_DUPLICATION_FLAG],
    };
    if (modalId === MODAL_IDS.LOG) {
      dataRequest = {
        query_params: isAllowSettingOption
          ? valueForm[QUERY_PARAMS].replaceAll('\n', ',')
          : '',
        directory_index: isAllowSettingOption
          ? valueForm[DIRECTORY_INDEX].replaceAll('\n', ',')
          : '',
        alert_auth_ids: valueForm[ALERT_AUTH_IDS].join(','),
      };
    }
    if (modalId === MODAL_IDS.CROSS_DEVICE_MODE) {
      dataRequest = {
        cross_device_mode: +valueForm[CROSS_DEVICE_MODE],
      };
    }
    if (modalId === MODAL_IDS.AD_CV_COST_MODE) {
      dataRequest = {
        ad_cv_cost_mode: +valueForm[AD_CV_COST_MODE],
      };
    }

    let error = {};
    if (modalId === MODAL_IDS.CONFIG) {
      error = validateMeasurementConfigSetting({
        ...dataRequest,
        session_expire: valueForm[SESSION_EXPIRE],
        expiredays: valueForm[EXPIREDAYS],
      });
    }
    if (modalId === MODAL_IDS.LOG) {
      error = validateMeasurementLogSetting(dataRequest, isAllowSettingOption);
    }

    if (modalId === MODAL_IDS.CLICK_ID_SETTING) {
      const clickIdSettingObj = {
        enable_flag: enableFlag,
        medium_ids: enableFlag ? medium : [],
      };
      dataRequest = clickIdSettingObj;
    }

    if (!isEmpty(error)) {
      setErrorForm(error);
    } else {
      setIsUpdating(true);
      dispatch(
        systemSettingActions.updateMeasurementSetting(
          modalId,
          dataRequest,
          handleResponseUpdate
        )
      );
    }
  };

  const handleChangeRadio = (value, name) => {
    const valueChange =
      name === CONV_DUPLICATION_FLAG ? value === TRUE_FLAG : +value;
    if (modalId === CLICK_ID_SETTING) {
      const clickIdSettingObj = {
        enable_flag: JSON.parse(value),
        medium_ids: get(valueForm[CLICK_ID_SETTING], 'medium_ids', []),
      };
      handleChangeValue('click_id_setting', clickIdSettingObj);
    } else {
      handleChangeValue(name, valueChange);
    }
  };

  const handleChangeMedia = (name) => {
    if (!medium.includes(name)) {
      setMedium([...medium, name]);
    } else {
      setMedium(medium.filter((item) => item !== name));
    }
  };

  const usersFormatted = useMemo(() => formatUsersData(valueForm.dtb_user), [
    valueForm.dtb_user,
  ]);

  const handleSelectRow = useCallback(
    (ids) => {
      handleChangeValue(ALERT_AUTH_IDS, ids);
    },
    [handleChangeValue]
  );

  const renderModalConfig = () => {
    return (
      <div>
        <FormGroup
          label={FORM[REJECT_HOST].label}
          tooltip={FORM[REJECT_HOST].tooltip}
          note={FORM[REJECT_HOST].note}
          styleError="keep-position"
        >
          <FormInput
            as="textarea"
            name={REJECT_HOST}
            value={valueForm[REJECT_HOST].replaceAll(',', '\r\n')}
            placeholder={FORM[REJECT_HOST].placeholder}
            error={errorForm[REJECT_HOST]}
            onChange={handleChangeValue}
          />
        </FormGroup>
        {!isCapiSingleContract && (
          <FormGroup
            label={FORM[SESSION_EXPIRE].label}
            tooltip={FORM[SESSION_EXPIRE].tooltip}
            note={FORM[SESSION_EXPIRE].note}
            required
          >
            <div className="form-group__number">
              <FormInput
                type="number"
                size="sm"
                name={SESSION_EXPIRE}
                value={valueForm[SESSION_EXPIRE]}
                error={errorForm[SESSION_EXPIRE]}
                onChange={handleChangeValue}
              />
              <span>分</span>
            </div>
          </FormGroup>
        )}
        <FormGroup
          label={FORM[EXPIREDAYS].label}
          tooltip={FORM[EXPIREDAYS].tooltip}
          note={FORM[EXPIREDAYS].note}
          required
        >
          <div className="form-group__number">
            <FormInput
              type="number"
              size="sm"
              name={EXPIREDAYS}
              value={valueForm[EXPIREDAYS]}
              error={errorForm[EXPIREDAYS]}
              onChange={handleChangeValue}
            />
            <span>日</span>
          </div>
        </FormGroup>
        <FormGroup
          label={FORM[CONV_DUPLICATION_FLAG].label}
          tooltip={FORM[CONV_DUPLICATION_FLAG].tooltip}
        >
          <EbisRadio
            name={CONV_DUPLICATION_FLAG}
            label="取得しない"
            value={TRUE_FLAG}
            checked={valueForm[CONV_DUPLICATION_FLAG]}
            onChange={handleChangeRadio}
          />
          <EbisRadio
            name={CONV_DUPLICATION_FLAG}
            value={FALSE_FLAG}
            label="取得する"
            checked={!valueForm[CONV_DUPLICATION_FLAG]}
            onChange={handleChangeRadio}
          />
        </FormGroup>
      </div>
    );
  };

  const renderModalLog = () => {
    return (
      <div>
        {isAllowSettingOption && (
          <>
            <FormGroup label={FORM[QUERY_PARAMS].label}>
              <div className="form-group__description">
                登録されたパラメータが付与されたURLは、別のページとして計測します。
                <br />
                例）「product_id」を設定すると、下記のようなURLを別のページとして計測します。
                <div>https://www.ebis.ne.jp/?product_id=7</div>
                <div>https:///www.ebis.ne.jp/?product_id=12</div>
              </div>
              <FormInput
                as="textarea"
                name={QUERY_PARAMS}
                value={valueForm[QUERY_PARAMS].replaceAll(',', '\r\n')}
                placeholder={FORM[QUERY_PARAMS].placeholder}
                error={errorForm[QUERY_PARAMS]}
                onChange={handleChangeValue}
              />
            </FormGroup>
            <FormGroup label={FORM[DIRECTORY_INDEX].label} required>
              <div className="form-group__description">
                ファイル名を省略してアクセスした場合に、表示されるページを設定します。
                <br />
                例）「index.html」を設定すると、下記のようなURLを同じページとして計測します。
                <div>http://www.ebis.ne.jp/</div>
                <div>http://www.ebis.ne.jp/index.html</div>
              </div>
              <FormInput
                as="textarea"
                name={DIRECTORY_INDEX}
                value={valueForm[DIRECTORY_INDEX].replaceAll(',', '\r\n')}
                placeholder={FORM[DIRECTORY_INDEX].placeholder}
                error={errorForm[DIRECTORY_INDEX]}
                onChange={handleChangeValue}
              />
            </FormGroup>
          </>
        )}
        <FormGroup
          label={FORM[ALERT_AUTH_IDS].label}
          tooltip={FORM[ALERT_AUTH_IDS].tooltip}
        >
          <AlertAuthSetting
            users={usersFormatted}
            selectedAuthIds={valueForm[ALERT_AUTH_IDS]}
            error={errorForm[ALERT_AUTH_IDS]}
            onChange={handleSelectRow}
            header={HEADER_USER}
          />
        </FormGroup>
      </div>
    );
  };

  const renderSettingCrossDevice = () => {
    return (
      <FormGroup label={FORM[CROSS_DEVICE_MODE].label}>
        <EbisRadio
          name={CROSS_DEVICE_MODE}
          label={CROSS_DEVICE_MODE_LABELS[CROSS_DEVICE_MODE_AI]}
          value={CROSS_DEVICE_MODE_AI}
          checked={valueForm[CROSS_DEVICE_MODE] === CROSS_DEVICE_MODE_AI}
          onChange={handleChangeRadio}
        />
        <EbisRadio
          name={CROSS_DEVICE_MODE}
          value={CROSS_DEVICE_MODE_MEMBER}
          label={CROSS_DEVICE_MODE_LABELS[CROSS_DEVICE_MODE_MEMBER]}
          checked={valueForm[CROSS_DEVICE_MODE] === CROSS_DEVICE_MODE_MEMBER}
          onChange={handleChangeRadio}
        />
        <div className="form-group__description form-group__cross-device">
          <div className="mb-10">
            <div className="form-group__description-bold">機械学習</div>
            <div>
              クリックやPVなどのアクセスデータを元に特徴やパターンを見つけ、同一ユーザーかを予測によって判断して紐づけます。
            </div>
          </div>
          <div>
            <div className="form-group__description-bold">ユーザー名</div>
            <div>
              コンバージョン属性取得用タグで取得しているユーザー名（member_name）を使い、同じユーザー名を持つものを同一ユーザーとして紐づけます。
              <br />
              [紐づかない条件］
              <br />
              ・過去30日間で、ユーザー名1件に対して1000件以上のユーザーが紐づく場合
              <br />
              ・ユーザー名が「null」「undefined」「2桁以下の数値」と合致する場合
            </div>
          </div>
        </div>
        <div />
        <div className="form-group__description form-group__cross-device">
          <div className="mb-10">
            <div className="form-group__description-bold">
              紐づけルール変更時のご注意
            </div>
            <div>
              ・紐づけルールを変更すると、変更前のクロスデバイス分析結果は画面表示およびCSVデータ出力ができなくなります。データのバックアップが必要な場合は、紐づけルール変更前に対象画面でデータのエクスポートを行ってください。
              <br />
              ・紐づけルールを変更した後、分析画面に反映されるのは次回のクロスデバイス分析・更新タイミングとなります。
              <br />
              ・クロスデバイス分析状況については画面右上の &nbsp;
              <img
                src={iconDataUpdateStatus}
                alt="icon-caution"
                width="14px"
                height="14px"
              />
              （反映時間）をクリックしてご確認ください。
            </div>
          </div>
        </div>
      </FormGroup>
    );
  };

  const renderAdCvCostMode = () => {
    return (
      <FormGroup label={FORM[AD_CV_COST_MODE].label}>
        <EbisRadio
          name={AD_CV_COST_MODE}
          value={OPTION_AD_CV_COST_MODE_NONE}
          label={AD_CV_COST_MODE_LABELS[OPTION_AD_CV_COST_MODE_NONE]}
          checked={valueForm[AD_CV_COST_MODE] === OPTION_AD_CV_COST_MODE_NONE}
          onChange={handleChangeRadio}
        />
        <EbisRadio
          name={AD_CV_COST_MODE}
          label={AD_CV_COST_MODE_LABELS[OPTION_AD_CV_COST_MODE_DIRECT]}
          value={OPTION_AD_CV_COST_MODE_DIRECT}
          checked={valueForm[AD_CV_COST_MODE] === OPTION_AD_CV_COST_MODE_DIRECT}
          onChange={handleChangeRadio}
        />
        <EbisRadio
          name={AD_CV_COST_MODE}
          value={OPTION_AD_CV_COST_MODE_AVERAGE}
          label={AD_CV_COST_MODE_LABELS[OPTION_AD_CV_COST_MODE_AVERAGE]}
          checked={
            valueForm[AD_CV_COST_MODE] === OPTION_AD_CV_COST_MODE_AVERAGE
          }
          onChange={handleChangeRadio}
        />
      </FormGroup>
    );
  };

  const renderClickIdSetting = () => {
    return (
      <FormGroup label="媒体クリックID等を取得する">
        <EbisRadio
          name="enable_flag"
          label="する"
          value
          checked={enableFlag}
          onChange={handleChangeRadio}
        />
        <EbisRadio
          name="enable_flag"
          label="しない"
          value={false}
          checked={!enableFlag}
          onChange={handleChangeRadio}
        />
        {enableFlag && (
          <div className="target-media-area">
            <FormGroup label="対応媒体">
              <div className="wrap-medium">
                {Object.values(CLICK_MEDIA_SETTING_MEDIA).map((item) => {
                  return (
                    <div
                      key={item.key}
                      className="display-items-form__checkbox-item max-content"
                    >
                      <CheckboxElement
                        name={item.key}
                        label={item.name}
                        checked={medium.includes(item.key)}
                        onChange={handleChangeMedia}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="consent">
                <div className="consent-wrap">
                  <div className="consent-wrap-content">
                    <div className="consent-wrap__header">
                      広告媒体のクリックＩＤ取得に関する同意事項
                    </div>
                    <div className="gap-bottom-20">
                      利用者（AD
                      EBiS利用契約約款（（https://www.ebis.ne.jp/rule/、以下「約款」といいます）　第1条に規定する利用者）は、AD
                      EBiS（以下、「本サービス」といいます）においてWeb広告媒体（以下、「広告媒体」といいます）が発行する識別子（以下、「クリックID等」といい、第1項において定義します）を取得する機能（以下「本機能」といいます）を利用する場合、以下の事項に同意するものとします。株式会社イルグルム（以下、「当社」といいます）は、利用者が本機能を利用したことをもって以下の同意事項（以下「本同意事項」といいます）に同意したものみなします。なお、本機能の有する機能・仕様は別途当社が定めるとおりとします。
                    </div>
                    <div className="gap-bottom-20">
                      1.クリックID等とは、広告主である利用者等（約款第21条第1項に規定）が広告媒体に出稿している広告や当該利用者等が管理・運用をしているウェブサイトへのアクセスの状況や履歴等を分析または管理するために広告媒体が発行する各種識別子を意味するものとします。クリックID等には広告媒体が発行する「クリックID」や「ブラウザID」等が含まれますが、これらの項目・名称に限りません。
                    </div>
                    <div className="gap-bottom-20">
                      2.利用者は、本同意事項に同意の上、本機能を利用することにより、利用者が指定する広告媒体のクリックID等を本サービスのタグが発行するcookieと紐づけ、本サービスの取得データ（約款第21条第1項に規定）の一部として取得することができます。
                    </div>
                    <div className="gap-bottom-20">
                      3.利用者は、本機能によりクリックID等を取得することについて、次の各号を確認し、了承するものとします。
                    </div>
                    <div className="sub-content gap-bottom-20">
                      <div className="gap-top-10">
                        3-1.利用者等による広告媒体の利用（クリックID等をはじめとして広告媒体が取得し、利用者等に提供するデータの利活用を含みます）には、各広告媒体の運営会社が定める利用規約その他当該運営会社が定めるルール等（以下、「媒体利用規約等」といいます）が適用されることを理解し、当該媒体利用規約等への抵触の有無について利用者等の責任において確認し、当社は一切の責任を負わないこと。
                      </div>
                      <div className="gap-top-10">
                        3-2.本機能の有効化、解除、または本機能に基づくクリックID等の取得・利用等は、利用者等または利用者等からウェブマーケティング関連業務の委託を受けた者の責任においてこれを行うものとし、本機能の利用の際の設定の誤りによって生じた損害に関して当社は一切の責任を負わないこと。
                      </div>
                      <div className="gap-top-10">
                        3-3.クリックID等のデータの正確性、有用性、安全性等について、当社は一切保証しないこと。
                      </div>
                      <div className="gap-top-10">
                        3-4.クリックID等を取得したことに起因して利用者等または第三者に生じた損害は利用者等において負担するものとし、当社は一切の責任を負わないこと。
                      </div>
                    </div>
                    <div className="gap-bottom-20">
                      4.利用者等または利用者等からウェブマーケティング関連業務の委託を受けた者が本機能の利用を中止、中断もしくは終了した場合、広告媒体のシステムやサービスが中止、中断もしくは終了した場合、またはその他当該広告媒体の仕様や方針、技術的な問題の発生等により、クリックID等が取得できなくなる場合や当社において制御できない障害やエラーが発生する場合があることを利用者は理解して本機能を利用するものとし、これらの場合、当社は一切の責任を負わないものとします。
                    </div>
                    <div className="gap-bottom-20">
                      5.利用者は、委託者（約款第21条第1項に規定）のために本機能により広告媒体のクリックID等を取得する場合、当該委託者に本条の内容を理解させ、これを遵守させるものとします。
                    </div>
                    <div className="gap-bottom-20">
                      6.広告媒体との間の広告配信に係る契約（以下、「広告配信契約」といいます）の当事者が利用者と異なる者である場合、利用者は以下の各号について表明し保証するものとします。
                    </div>
                    <div className="sub-content gap-bottom-20">
                      <div className="gap-top-10">
                        6-1.広告媒体が発行するクリックID等は、媒体利用規約等において認められる範囲において、本サービスのタグが発行するcookieに保存されるデータとともに広告主に該当する利用者等に帰属する（以下、「cookieの帰属先」といいます）こと。
                      </div>
                      <div className="gap-top-10">
                        6-2.利用者が、広告配信契約の契約者から、本機能を用いてクリックID等を取得し、本サービスの取得データと連携することについて同意を取得していること。
                      </div>
                      <div className="gap-top-10">
                        6-3.本機能は、cookieの帰属先であり広告主である利用者等の意思に基づく当該利用者等の委託先（間接的な場合を含み、当社および広告媒体を指す）間の個人データの提供であり、個人情報保護法の定める個人データの第三者提供には該当しないこと。
                      </div>
                    </div>
                    <div className="gap-bottom-20">
                      7.本機能により取得したクリックID等を利用者等が本サービスからダウンロードその他の方法で抽出し利用する場合、利用者等がその利用に関する一切の責任を負うものとし、かかる利用に伴い利用者に発生した損害について当社は一切の責任を負いません。
                    </div>
                    <div className="consent-wrap__footer">以上</div>
                  </div>
                </div>
              </div>
            </FormGroup>
          </div>
        )}
      </FormGroup>
    );
  };

  const renderModalId = () => {
    switch (modalId) {
      case MODAL_IDS.LOG:
        return renderModalLog();
      case MODAL_IDS.CROSS_DEVICE_MODE:
        return renderSettingCrossDevice();
      case MODAL_IDS.AD_CV_COST_MODE:
        return renderAdCvCostMode();
      case MODAL_IDS.CLICK_ID_SETTING:
        return renderClickIdSetting();
      default:
        return renderModalConfig();
    }
  };

  return (
    <SettingModal
      loading={isLoading || isUpdating}
      isShow={isShow}
      onSubmit={handleSubmit}
      onHide={onHide}
      title="計測オプションを編集"
      note={get(FORM[modalId], 'note', '')}
      dialogClassName="measurement-setting-modal"
      pairType={valueForm[CROSS_DEVICE_MODE]}
      modalId={modalId}
      pairTypeBeforeChange={data[CROSS_DEVICE_MODE]}
      confirmText={enableFlag ? get(FORM[modalId], 'confirmText', '') : ''}
      isShowCheckbox={modalId === MODAL_IDS.CLICK_ID_SETTING && enableFlag}
      disabledSubmit={enableFlag && isEmpty(medium)}
    >
      <div>{renderModalId()}</div>
    </SettingModal>
  );
}

MeasurementSettingModal.propTypes = {
  isShow: bool.isRequired,
  onHide: func.isRequired,
  isLoading: bool.isRequired,
  modalId: string.isRequired,
  data: shape({}).isRequired,
  isAllowedSettingMeasurementOption: bool.isRequired,
  isCapiSingleContract: bool.isRequired,
};

export default MeasurementSettingModal;
