import React from 'react';
import PropTypes from 'prop-types';

import Spinner from './Spinner';

import './PageLoader.scss';

const PageLoader = ({ isLoading, children }) => {
  if (isLoading)
    return (
      <div className="page-loader d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );

  return <div>{children}</div>;
};

PageLoader.defaultProps = {
  isLoading: true,
  children: () => <></>,
};

PageLoader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default PageLoader;
