const UPDATE_AXIS = 'periodAnalysis/UPDATE_AXIS';
const REMOVE_SECONDARY_METRIC = 'periodAnalysis/REMOVE_SECONDARY_METRIC';
const UPDATE_BOOKMARK = 'periodAnalysis/UPDATE_BOOKMARK';
const SET_SHOW_CHART = 'periodAnalysis/SET_SHOW_CHART';
const UPDATE_CATEGORIES = 'periodAnalysis/UPDATE_CATEGORIES';
const GET_BOOKMARK = 'periodAnalysis/GET_BOOKMARK';
const DELETE_BOOKMARK = 'periodAnalysis/DELETE_BOOKMARK';

const APPLY_BOOKMARK = 'periodAnalysis/APPLY_BOOKMARK';

const SET_TABLE_CURRENT_PAGE = 'periodAnalysis/SET_TABLE_CURRENT_PAGE';
const UPDATE_SORT = 'periodAnalysis/UPDATE_SORT';
const SELECT_ROW = 'periodAnalysis/SELECT_ROW';
const DESELECT_ROW = 'periodAnalysis/DESELECT_ROW';
const SET_TABLE_CURRENT_PAGE_COMPARED =
  'periodAnalysis/SET_TABLE_CURRENT_PAGE_COMPARED';
const UPDATE_SORT_COMPARED = 'periodAnalysis/UPDATE_SORT_COMPARED';
const SELECT_ROW_COMPARED = 'periodAnalysis/SELECT_ROW_COMPARED';
const DESELECT_ROW_COMPARED = 'periodAnalysis/DESELECT_ROW_COMPARED';
const SET_VISIBLE = 'periodAnalysis/SET_VISIBLE';
const UPDATE_VISIBLE = 'periodAnalysis/UPDATE_VISIBLE';
const UPDATE_PERIOD_TYPE = 'periodAnalysis/UPDATE_PERIOD_TYPE';
const TOGGLE_MEMO = 'periodAnalysis/TOGGLE_MEMO';
const CREATE_MEMO = 'periodAnalysis/CREATE_MEMO';
const CREATE_MEMO_SUCCESS = 'periodAnalysis/CREATE_MEMO_SUCCESS';
const CREATE_MEMO_ERROR = 'periodAnalysis/CREATE_MEMO_ERROR';
const CHANGE_ACTIVE_TABLE = 'periodAnalysis/CHANGE_ACTIVE_TABLE';
const SHOW_MODAL = 'periodAnalysis/SHOW_MODAL';
const HIDE_MODAL = 'periodAnalysis/HIDE_MODAL';
const SHOW_DELETE_MODAL = 'periodAnalysis/SHOW_DELETE_MODAL';
const HIDE_DELETE_MODAL = 'periodAnalysis/HIDE_DELETE_MODAL';
const SHOW_MEMO_ERROR_MODAL = 'periodAnalysis/SHOW_MEMO_ERROR_MODAL';
const HIDE_MEMO_ERROR_MODAL = 'periodAnalysis/HIDE_MEMO_ERROR_MODAL';

const LOAD_MEMO = 'periodAnalysis/LOAD_MEMO';
const LOAD_MEMO_SUCCESS = 'periodAnalysis/LOAD_MEMO_SUCCESS';
const LOAD_MEMO_FAILED = 'periodAnalysis/LOAD_MEMO_FAILED';

const DELETE_MEMO = 'periodAnalysis/DELETE_MEMO';
const DELETE_MEMO_SUCCESS = 'periodAnalysis/DELETE_MEMO_SUCCESS';
const DELETE_MEMO_FAILED = 'periodAnalysis/DELETE_MEMO_FAILED';

const UPDATE_MEMO = 'periodAnalysis/UPDATE_MEMO';
const UPDATE_MEMO_SUCCESS = 'periodAnalysis/UPDATE_MEMO_SUCCESS';
const UPDATE_MEMO_FAILED = 'periodAnalysis/UPDATE_MEMO_FAILED';

const UPDATE_MEMO_LIST = 'periodAnalysis/UPDATE_MEMO_LIST';
const SET_MEMO_SUBMITTING = 'periodAnalysis/SET_MEMO_SUBMITTING';

const GET_CHART_REPORT = 'periodAnalysis/GET_CHART_REPORT';
const GET_COMPARED_CHART_REPORT = 'periodAnalysis/GET_COMPARED_CHART_REPORT';
const GET_TABLE_REPORT = 'periodAnalysis/GET_TABLE_REPORT';
const GET_COMPARED_TABLE_REPORT = 'periodAnalysis/GET_COMPARED_TABLE_REPORT';

const UPDATE_CHART_DATA = 'periodAnalysis/UPDATE_CHART_DATA';
const UPDATE_COMPARED_CHART_DATA = 'periodAnalysis/UPDATE_COMPARED_CHART_DATA';
const UPDATE_TABLE_DATA = 'periodAnalysis/UPDATE_TABLE_DATA';
const UPDATE_COMPARED_TABLE_DATA = 'periodAnalysis/UPDATE_COMPARED_TABLE_DATA';
const UPDATE_TABLE_DISPLAY_ROWS = 'periodAnalysis/UPDATE_TABLE_DISPLAY_ROWS';

const CLEAN_STATES = 'periodAnalysis/CLEAN_STATES';

const SET_TABLE_STATUS = 'periodAnalysis/SET_TABLE_STATUS';
const SET_COMPARED_TABLE_STATUS = 'periodAnalysis/SET_COMPARED_TABLE_STATUS';
const SET_CHART_STATUS = 'periodAnalysis/SET_CHART_STATUS';

const UPDATE_CHART_DISPLAY_MEMO = 'periodAnalysis/UPDATE_CHART_DISPLAY_MEMO';

const SET_PERIOD_RANGE = 'periodAnalysis/SET_PERIOD_RANGE';
const SET_COMPARED_PERIOD_RANGE = 'periodAnalysis/SET_COMPARED_PERIOD_RANGE';

const UPDATE_CHART_DISPLAY_DATA = 'periodAnalysis/UPDATE_CHART_DISPLAY_DATA';

const SET_MEMO_ERRORS = 'periodAnalysis/SET_MEMO_ERRORS';

const LOG_ERROR = 'periodAnalysis/LOG_ERROR';

const REFRESH_TABLE_CURRENT = 'periodAnalysis/REFRESH_TABLE_CURRENT';

const REFRESH_TABLE_COMPARED = 'periodAnalysis/REFRESH_TABLE_COMPARED';

export default {
  UPDATE_AXIS,
  REMOVE_SECONDARY_METRIC,
  UPDATE_BOOKMARK,
  GET_BOOKMARK,
  DELETE_BOOKMARK,
  APPLY_BOOKMARK,
  SET_SHOW_CHART,
  UPDATE_CATEGORIES,
  SET_TABLE_CURRENT_PAGE,
  UPDATE_SORT,
  SELECT_ROW,
  DESELECT_ROW,
  SET_TABLE_CURRENT_PAGE_COMPARED,
  UPDATE_SORT_COMPARED,
  SELECT_ROW_COMPARED,
  DESELECT_ROW_COMPARED,
  SET_VISIBLE,
  UPDATE_VISIBLE,

  UPDATE_PERIOD_TYPE,
  TOGGLE_MEMO,
  CREATE_MEMO,
  CREATE_MEMO_SUCCESS,
  CREATE_MEMO_ERROR,
  CHANGE_ACTIVE_TABLE,

  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_DELETE_MODAL,
  HIDE_DELETE_MODAL,
  SHOW_MEMO_ERROR_MODAL,
  HIDE_MEMO_ERROR_MODAL,

  LOAD_MEMO,
  LOAD_MEMO_SUCCESS,
  LOAD_MEMO_FAILED,

  DELETE_MEMO,
  DELETE_MEMO_SUCCESS,
  DELETE_MEMO_FAILED,

  UPDATE_MEMO,
  UPDATE_MEMO_SUCCESS,
  UPDATE_MEMO_FAILED,

  UPDATE_MEMO_LIST,
  SET_MEMO_SUBMITTING,

  GET_CHART_REPORT,
  GET_COMPARED_CHART_REPORT,
  GET_TABLE_REPORT,
  GET_COMPARED_TABLE_REPORT,
  UPDATE_CHART_DATA,
  UPDATE_COMPARED_CHART_DATA,
  UPDATE_TABLE_DATA,
  UPDATE_COMPARED_TABLE_DATA,

  UPDATE_TABLE_DISPLAY_ROWS,

  CLEAN_STATES,
  SET_TABLE_STATUS,
  SET_COMPARED_TABLE_STATUS,
  SET_CHART_STATUS,

  UPDATE_CHART_DISPLAY_MEMO,

  SET_PERIOD_RANGE,
  SET_COMPARED_PERIOD_RANGE,

  UPDATE_CHART_DISPLAY_DATA,

  SET_MEMO_ERRORS,

  LOG_ERROR,

  REFRESH_TABLE_CURRENT,
  REFRESH_TABLE_COMPARED,
};
