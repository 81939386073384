import React from 'react';
import PropTypes from 'prop-types';

import EbisPagination from 'views/molecules/stateless-pagination';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import Table from 'views/templates/TableStatisticsTemplate/components/Table';

const TableStatisticsTemplate = ({
  screenId,
  screenName,
  isLoading,
  isLoadingTable,
  headerData,
  listData,
  emptyMessage,
  pagination,
  columnsWidth,
  tablesWidth,
  handleSort,
  handleChangePage,
  onResizeColumn,
  onResizeTable,
  styles,
  sumData,
  allowTotalRowOnly,
  handleColorCode,
  handleCheckAll,
  handleCheckRow,
  handleEdit,
  handleViewDetail,
  tableControl,
  priorityAxis,
  channel,
}) => (
  <div className={`h-100 ${styles.container}`}>
    <div id="pagination-table" className={`bg-white ${styles.secondContainer}`}>
      {tableControl}
      <div className={styles.pagination}>
        <EbisPagination
          totalItems={pagination.totalItems}
          itemsPerPage={pagination.itemsPerPage}
          currentPage={pagination.currentPage}
          changePage={handleChangePage}
        />
      </div>
    </div>
    <DataSyncLoader isLoading={isLoading}>
      <Table
        screenId={screenId}
        screenName={screenName}
        classData={styles.table}
        headerData={headerData}
        listData={listData}
        emptyMessage={emptyMessage}
        sumData={sumData}
        allowTotalRowOnly={allowTotalRowOnly}
        columnsWidth={columnsWidth}
        tablesWidth={tablesWidth}
        isLoading={isLoadingTable}
        handleSort={handleSort}
        onResizeColumn={onResizeColumn}
        onResizeTable={onResizeTable}
        handleColorCode={handleColorCode}
        handleCheckAll={handleCheckAll}
        handleCheckRow={handleCheckRow}
        handleEdit={handleEdit}
        handleViewDetail={handleViewDetail}
        priorityAxis={priorityAxis}
        channel={channel}
      />
    </DataSyncLoader>
  </div>
);

TableStatisticsTemplate.propTypes = {
  screenId: PropTypes.string,
  screenName: PropTypes.string,
  pagination: PropTypes.oneOfType([PropTypes.object]),
  headerData: PropTypes.oneOfType([PropTypes.array]),
  listData: PropTypes.oneOfType([PropTypes.array]),
  columnsWidth: PropTypes.oneOfType([PropTypes.object]),
  tablesWidth: PropTypes.oneOfType([PropTypes.object]),
  isLoadingTable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.func.isRequired,
  onResizeTable: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    container: PropTypes.string,
    secondContainer: PropTypes.string,
    pagination: PropTypes.string,
    table: PropTypes.string,
  }).isRequired,
  sumData: PropTypes.oneOfType([PropTypes.object]),
  allowTotalRowOnly: PropTypes.bool,
  handleColorCode: PropTypes.func,
  handleCheckAll: PropTypes.func,
  handleCheckRow: PropTypes.func,
  handleEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleViewDetail: PropTypes.func,
  tableControl: PropTypes.node,
  emptyMessage: PropTypes.string,
  priorityAxis: PropTypes.string,
  channel: PropTypes.string,
};

TableStatisticsTemplate.defaultProps = {
  screenId: '',
  screenName: '',
  headerData: [],
  listData: [],
  columnsWidth: {},
  tablesWidth: {},
  pagination: {
    totalItems: 1,
    itemsPerPage: 1,
  },
  sumData: null,
  allowTotalRowOnly: false,
  handleColorCode: null,
  tableControl: <></>,
  handleEdit: false,
  handleCheckAll: () => {},
  handleCheckRow: () => {},
  handleViewDetail: () => {},
  emptyMessage: '表示するデータがありません',
  priorityAxis: '',
  channel: '',
};

export default React.memo(TableStatisticsTemplate);
