/* eslint-disable no-unused-vars */
import { takeLatest, call, select, delay } from 'redux-saga/effects';
import moment from 'moment';

import { sendReport } from 'services/api/LoggerApi';

import types from 'store/logger/types';
import selectors from 'store/logger/selectors';
import { getAccountInfo } from 'store/auth/selectors';

function* sendErrorReport() {
  try {
    yield delay(200);

    const now = moment().format('YYYYMMDDhhmmss');

    const actionsLog = yield select(selectors.getActionLists);

    const errrosLog = yield select(selectors.getLatestErrorRequest);

    const accountInfo = yield select(getAccountInfo);

    const { accountId, accountStr } = accountInfo;

    const report = {
      date: now,
      account: `${accountId}_${accountStr}`,
      data: {
        actions: actionsLog,
        errors: errrosLog,
      },
    };

    // const { data } = yield call(sendReport, report);
  } catch (error) {
    // console.log(error);
  }
}

export default function* loggerSaga() {
  yield takeLatest(types.SEND_REPORT, sendErrorReport);
}
