import types from 'store/logger/types';
import constants from 'store/logger/constant';

const addToFixedArray = (arr, item, level) => {
  const newArr = [...arr];

  if (newArr.length === constants.MAX_ACTIONS) {
    newArr.pop();
  }

  return [{ type: item.type, details: item, level }, ...newArr];
};

const initialState = {
  actionsArr: [],
  UIErrorArr: [],
  errorsArr: [],
  errorsArrForModal: [],
  errorNotifyShow: false,
  confirmModalData: {
    title: '',
    content: '',
    jsxContent: null,
    cancelBtn: false,
    cancelText: '',
    callback: null,
    confirmText: 'OK',
  },
  irregularCaseModalShow: false,
};

const LoggerReducer = (state = initialState, action) => {
  const { type, payload, level } = action;
  switch (type) {
    case types.LOG_ACTION:
      return {
        ...state,
        actionsArr: addToFixedArray(state.actionsArr, payload, level),
      };
    case types.LOG_UI_ERROR:
      return {
        ...state,
        UIErrorArr: addToFixedArray(state.UIErrorArr, payload, level),
      };
    case types.LOG_DOWNLOAD_CSV_ERROR: {
      const { title, content, callback } = payload;

      return {
        ...state,
        irregularCaseModalShow: true,
        confirmModalData: { title, content, callback },
      };
    }
    case types.LOG_ERROR:
      return {
        ...state,
        errorsArr: addToFixedArray(state.errorsArr, payload, level),
        errorsArrForModal: addToFixedArray(
          state.errorsArrForModal,
          payload,
          level
        ),
        errorNotifyShow: true,
      };
    case types.CLOSE_ERROR: {
      return {
        ...state,
        errorsArrForModal: [],
        errorNotifyShow: false,
      };
    }
    case types.LOG_CONFIRM: {
      const {
        title,
        content,
        cancelBtn,
        cancelText,
        callback,
        confirmText,
        confirmBtnType,
      } = payload;

      return {
        ...state,
        confirmModalData: {
          title,
          content,
          cancelBtn,
          cancelText,
          confirmText,
          confirmBtnType,
          callback,
        },
        irregularCaseModalShow: true,
      };
    }
    case types.CLOSE_CONFIRM:
      // close modal
      return {
        ...state,
        confirmModalData: {
          title: '',
          content: '',
          callback: null,
        },
        irregularCaseModalShow: false,
      };

    default:
      return state;
  }
};

export default LoggerReducer;
