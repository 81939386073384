import mapValues from 'lodash/mapValues';
import DisplayItemsAdManagementService from 'domain/settings/display-items-ad-management';
import * as pages from 'services/routes/constants';
import { CV_NAME } from 'domain/fields';
import * as DisplayItems from 'domain/settings/display-items';
import {
  PERIOD,
  FUNC_CODE_INFLOW_ANALYSIS,
  FUNC_CODE_DETAIL_ANALYSIS,
  FUNC_CODE_CV_ATTR_ANALYSIS,
  FUNC_CODE_CV_FLOW_ANALYSIS,
  FUNC_CODE_AD_MANAGEMENT,
  FUNC_CODE_LTV_ANALYSIS,
  FUNC_CODE_LANDING_PAGE_ANALYZE,
  CONTENT_CATEGORY,
  LANDING_PAGE_URL,
  SYNC_CATEGORY,
  LANDING_PAGE_DOMAIN,
  DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  FUNC_CODE_LPO_LINK,
  FUNC_CODE_LPO_PERIOD,
  FUNC_CODE_LOG_PAGE_ANALYZE,
  FUNC_CODE_LOG_PERIOD_ANALYZE,
  FUNC_CODE_LOG_ROUTE_ANALYZE,
  MEDIA_SIDE_CAMPAIGN,
  MEDIA_SIDE_GROUP,
  CHANNEL,
  AD_GROUP1,
  AD_GROUP2,
  CATEGORY,
} from 'domain/settings/display-items';
import { PRIORITY_AXIS_EBIS } from 'domain/consts';
import * as DisplayItemsCategoryAnalysis from './display-items-category-analysis';
import * as DisplayItemsDetailAnalysis from './display-items-detail-analysis';
import * as DisplayItemsPeriodAnalysis from './display-items-period-analysis';
import * as DisplayItemsCostAllocationAnalysis from './display-items-cost-allocation-analysis';
import * as DisplayItemsCvAttributes from './display-items-cv-attributes';
import * as DisplayItemsCvFlow from './display-items-cv-flow';
import * as DisplayItemsLtvAnalysis from './display-items-ltv-analysis';
import * as DisplayItemsLandingPageAnalysis from './display-items-landing-page-analysis';
import * as DisplayItemsLpoLink from './display-items-lpo-link';
import * as DisplayItemsLpoPeriod from './display-items-lpo-period';
import * as DisplayItemsLogPageAnalysis from './display-items-log-page-analysis';
import * as DisplayItemsLogPeriodAnalysis from './display-items-log-period-analysis';
import * as DisplayItemsLogRouteAnalysis from './display-items-log-route-analysis';

const DisplayItemsServiceFactory = () => {
  // for getFuncIdByPageId, getPageIdByFuncId, getAllPageIdsByFuncId
  const funcIds = {
    [pages.INFLOW_ANALYSIS]: FUNC_CODE_INFLOW_ANALYSIS, // for CustomViewModal
    [pages.CATEGORY_ANALYZE]: FUNC_CODE_INFLOW_ANALYSIS,
    [pages.COST_ALLOCATION]: FUNC_CODE_INFLOW_ANALYSIS,
    [pages.DETAILS_ANALYSIS]: FUNC_CODE_DETAIL_ANALYSIS,
    [pages.COMPARE_PERIOD]: FUNC_CODE_INFLOW_ANALYSIS,
    [pages.CV_ATTRIBUTE]: FUNC_CODE_CV_ATTR_ANALYSIS,
    [pages.CV_FLOW]: FUNC_CODE_CV_FLOW_ANALYSIS,
    [pages.AD_MANAGEMENT]: FUNC_CODE_AD_MANAGEMENT,
    [pages.LTV_ANALYZE]: FUNC_CODE_LTV_ANALYSIS,
    [pages.LANDING_PAGE_ANALYZE]: FUNC_CODE_LANDING_PAGE_ANALYZE,
    [pages.LPO_LINK]: FUNC_CODE_LPO_LINK,
    [pages.LPO_PERIOD]: FUNC_CODE_LPO_PERIOD,
    [pages.LOG_PAGE_ANALYZE]: FUNC_CODE_LOG_PAGE_ANALYZE,
    [pages.LOG_PERIOD_ANALYZE]: FUNC_CODE_LOG_PERIOD_ANALYZE,
    [pages.LOG_ROUTE_ANALYZE]: FUNC_CODE_LOG_ROUTE_ANALYZE,
  };

  return {
    getCategoryAnalysisAd: () => DisplayItemsCategoryAnalysis.getAd(),
    getCategoryAnalysisAdDefault: () =>
      DisplayItemsCategoryAnalysis.getAdDefault(),
    getCategoryAnalysisAll: () => DisplayItemsCategoryAnalysis.getAll(),
    getCategoryAnalysisAllDefault: () =>
      DisplayItemsCategoryAnalysis.getAllDefault(),
    getDetailAnalysisAd: () => DisplayItemsDetailAnalysis.getAd(),
    getDetailAnalysisAdDefault: () => DisplayItemsDetailAnalysis.getAdDefault(),
    getDetailAnalysisAll: () => DisplayItemsDetailAnalysis.getAll(),
    getDetailAnalysisAllDefault: () =>
      DisplayItemsDetailAnalysis.getAllDefault(),
    getPeriodAnalysisAd: () => DisplayItemsPeriodAnalysis.getAd(),
    getPeriodAnalysisAdDefault: () => DisplayItemsPeriodAnalysis.getAdDefault(),
    getPeriodAnalysisAll: () => DisplayItemsPeriodAnalysis.getAll(),
    getPeriodAnalysisAllDefault: () =>
      DisplayItemsPeriodAnalysis.getAllDefault(),
    getCostAllocationAnalysisAd: () =>
      DisplayItemsCostAllocationAnalysis.getAd(),
    getCostAllocationAnalysisAdDefault: () =>
      DisplayItemsCostAllocationAnalysis.getAdDefault(),
    getCvAttributesAd: () => DisplayItemsCvAttributes.getAd(),
    getCvAttributesAdDefault: () => DisplayItemsCvAttributes.getAdDefault(),
    getCvAttributesAll: () => DisplayItemsCvAttributes.getAll(),
    getCvAttributesAllDefault: () => DisplayItemsCvAttributes.getAllDefault(),
    getCvFlowAd: () => DisplayItemsCvFlow.getAd(),
    getCvFlowAdDefault: () => DisplayItemsCvFlow.getAdDefault(),
    getCvFlowAll: () => DisplayItemsCvFlow.getAll(),
    getCvFlowAllDefault: () => DisplayItemsCvFlow.getAllDefault(),
    getAdManagementDefault: () => DisplayItemsAdManagementService.getDefault(),
    getAdManagementAll: () => DisplayItemsAdManagementService.getAll(),
    getLtvAnalysisAd: () => DisplayItemsLtvAnalysis.getAd(),
    getLtvAnalysisAdDefault: () => DisplayItemsLtvAnalysis.getAdDefault(),
    getLtvAnalysisAll: () => DisplayItemsLtvAnalysis.getAll(),
    getLtvAnalysisAllDefault: () => DisplayItemsLtvAnalysis.getAllDefault(),
    getLandingPageAnalysisAd: () => DisplayItemsLandingPageAnalysis.getAd(),
    getLandingPageAnalysisAdDefault: () =>
      DisplayItemsLandingPageAnalysis.getAdDefault(),
    getLandingPageAnalysisAll: () => DisplayItemsLandingPageAnalysis.getAll(),
    getLandingPageAnalysisAllDefault: () =>
      DisplayItemsLandingPageAnalysis.getAllDefault(),
    getLandingPageAnalysisAdAxis: () =>
      DisplayItemsLandingPageAnalysis.getAdAxis(),
    getLandingPageAnalysisAllAxis: () =>
      DisplayItemsLandingPageAnalysis.getAllAxis(),
    getLogPageAnalysisAll: () => DisplayItemsLogPageAnalysis.getAll(),
    getLogPageAnalysisAllDefault: () =>
      DisplayItemsLogPageAnalysis.getAllDefault(),
    getLogPageAnalysisAllAxis: () => DisplayItemsLogPageAnalysis.getAllAxis(),
    getLogPeriodAnalysisAll: () => DisplayItemsLogPeriodAnalysis.getAll(),
    getLogPeriodAnalysisAllDefault: () =>
      DisplayItemsLogPeriodAnalysis.getAllDefault(),
    getLogRouteAnalysisAll: () => DisplayItemsLogRouteAnalysis.getAll(),
    getLogRouteAnalysisAllDefault: () =>
      DisplayItemsLogRouteAnalysis.getAllDefault(),
    getFuncIdByPageId: (pageId) => {
      return funcIds[pageId] ? funcIds[pageId] : undefined;
    },
    getPageIdByFuncId: (funcId) => {
      // Get only the first pageId
      const pageId = Object.keys(funcIds).find((key) => {
        return funcIds[key] === funcId;
      });
      return pageId;
    },
    getAllPageIdsByFuncId: (funcId) => {
      // Get only the first pageId
      const pageId = Object.keys(funcIds).filter((key) => {
        return funcIds[key] === funcId;
      });
      return pageId;
    },
    getLpoLinkAd: () => DisplayItemsLpoLink.getAd(),
    getLpoLinkAdDefault: () => DisplayItemsLpoLink.getAdDefault(),
    getLpoPeriodAd: () => DisplayItemsLpoPeriod.getAd(),
    getLpoPeriodAdDefault: () => DisplayItemsLpoPeriod.getAdDefault(),
    /**
     * Convert display items for api request
     * @param items DisplayItems
     * @return Record<string, boolean>
     */
    getForApiRequest: (items) =>
      mapValues(items, (item) => item.checked || false),
    getItemsByContractLog: (funcId, data, hasContractLog) => {
      const { __sorting__: defaultSorting = [], ...items } = data;

      let sorting = [...defaultSorting];
      if (
        funcId === FUNC_CODE_LANDING_PAGE_ANALYZE &&
        !hasContractLog &&
        sorting.includes(CONTENT_CATEGORY)
      ) {
        sorting = sorting.filter((item) => item !== CONTENT_CATEGORY);
        items.content_category = false;
        if (
          !sorting.includes(LANDING_PAGE_URL) &&
          !sorting.includes(LANDING_PAGE_DOMAIN)
        ) {
          sorting = [LANDING_PAGE_URL, ...sorting];
          items.landing_page_url = true;
        }
      }

      return { items, sorting };
    },
    getformatForDisplayItems: (funcId, items, sorting) => {
      let formatSorting = [...sorting];
      const formatItems = { ...items };

      if (funcId === FUNC_CODE_LANDING_PAGE_ANALYZE) {
        // rearrange the data when the data is unordered
        const customDisplayItems = [
          CONTENT_CATEGORY,
          LANDING_PAGE_DOMAIN,
          LANDING_PAGE_URL,
        ];
        const sortDisplayItems = (item) => {
          if (formatSorting.includes(item)) {
            formatSorting = [item, ...formatSorting];
            return true;
          }
          return false;
        };

        if (customDisplayItems.indexOf(formatSorting[0]) === -1) {
          if (!customDisplayItems.some(sortDisplayItems)) {
            // return default state
            formatItems.landing_page_url = true;
            formatSorting = [LANDING_PAGE_URL];
            return { formatItems, formatSorting };
          }
        }

        // insert chanel_access_type when switching to tab all
        const itemsBehindChanel = [
          SYNC_CATEGORY,
          CATEGORY,
          MEDIA_SIDE_CAMPAIGN,
          MEDIA_SIDE_GROUP,
          AD_GROUP1,
          AD_GROUP2,
        ];

        const insertChannelBefore = (item) => {
          const index = formatSorting.indexOf(item);
          if (index !== -1) {
            formatItems.channel_access_type = true;
            formatSorting.splice(index, 0, CHANNEL);
            return true;
          }
          return false;
        };

        itemsBehindChanel.some(insertChannelBefore);

        // remove duplicate elements
        formatSorting = [...new Set(formatSorting)];
      }

      return { formatItems, formatSorting };
    },

    checkIsCvAnalysis: (items) => {
      return (
        CV_NAME in items &&
        items[CV_NAME].group === DISPLAY_GROUP_CONVERSION_ATTRIBUTES
      );
    },
    getSettingFallback: ({
      items,
      settings,
      priorityAxis = PRIORITY_AXIS_EBIS,
    }) => {
      let emptyDimension = true;
      const dimensions = Object.keys(items).filter((key) => {
        const isDimension =
          items[key].getGroup(priorityAxis) ===
            DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS && key in settings;
        // required selected least one for Period analysis
        if (isDimension && settings[key] && key !== PERIOD) {
          emptyDimension = false;
        }
        return isDimension;
      });

      if (!emptyDimension) return settings;

      return dimensions.reduce((acc, key) => {
        // required selected least one for Period analysis
        if (key !== PERIOD && items[key].displayDefault) {
          return { ...acc, [key]: true };
        }
        return acc;
      }, settings);
    },
    mergeSettingLabels: (settings, display) => {
      const obj = { ...settings };

      const customDisplays = [
        DisplayItems.AD_GROUP1,
        DisplayItems.AD_GROUP2,
        DisplayItems.AD_NOTE,
        DisplayItems.OTHER1,
        DisplayItems.OTHER2,
        DisplayItems.OTHER3,
        DisplayItems.OTHER4,
        DisplayItems.OTHER5,
      ];
      customDisplays.map((key) => {
        if (obj[key]) {
          obj[key].title = display[key];
        }
        return key;
      });
      if (obj[DisplayItems.CONTACT_AD_GROUP1]) {
        obj[DisplayItems.CONTACT_AD_GROUP1].title =
          display[DisplayItems.AD_GROUP1];
      }
      if (obj[DisplayItems.CONTACT_AD_GROUP2]) {
        obj[DisplayItems.CONTACT_AD_GROUP2].title =
          display[DisplayItems.AD_GROUP2];
      }

      return obj;
    },
    getGroupDimensionsByFuncId: (funcId) => {
      switch (funcId) {
        case FUNC_CODE_LOG_ROUTE_ANALYZE:
          return [];

        case FUNC_CODE_LOG_PAGE_ANALYZE:
          return [
            DisplayItems.PAGE_ID,
            DisplayItems.PAGE_TITLE,
            DisplayItems.PAGE_URL,
            DisplayItems.DIRECTORY,
            DisplayItems.TERMINAL_TYPE,
          ];
        case FUNC_CODE_LPO_LINK:
          return [
            DisplayItems.AD_ID,
            DisplayItems.CATEGORY,
            DisplayItems.AD_GROUP1,
            DisplayItems.AD_GROUP2,
            DisplayItems.AD_NAME,
            DisplayItems.LINK_URL,
            DisplayItems.AGENCY,
            DisplayItems.AD_NOTE,
            DisplayItems.AD_DATE,
            DisplayItems.DEVICE,
            DisplayItems.TERMINAL_TYPE,
          ];

        default:
          return [
            DisplayItems.CHANNEL,
            DisplayItems.ITEM,
            DisplayItems.OFFER,
            DisplayItems.CATEGORY,
            DisplayItems.AD_GROUP1,
            DisplayItems.AD_GROUP2,
            DisplayItems.SYNC_CATEGORY,
            DisplayItems.MEDIA_SIDE_CAMPAIGN,
            DisplayItems.MEDIA_SIDE_GROUP,
            DisplayItems.MEDIA_SIDE_AD_ID,
            DisplayItems.MEDIA_SIDE_AD_NAME,
            DisplayItems.AD_ID,
            DisplayItems.AD_NAME,
            DisplayItems.AGENCY,
            DisplayItems.AD_NOTE,
            DisplayItems.AD_DATE,
            DisplayItems.TERMINAL_TYPE,
            DisplayItems.CONTENT_CATEGORY,
            DisplayItems.LANDING_PAGE_DOMAIN,
            DisplayItems.SEARCH_WORD,
            DisplayItems.LANDING_PAGE_URL,
            DisplayItems.PERIOD,
            DisplayItems.CV_NAME,
            DisplayItems.CV_ACCESS_TIME,
            DisplayItems.TRACKING_DATA,
            DisplayItems.MEMBER_NAME,
            DisplayItems.OTHER1,
            DisplayItems.OTHER2,
            DisplayItems.OTHER3,
            DisplayItems.OTHER4,
            DisplayItems.OTHER5,
            DisplayItems.CV_CONTACT_LATENCY_TIME_AVG,
            DisplayItems.CNT_CONTACT,
            DisplayItems.LANDING_PAGE_TITLE,
          ];
      }
    },
  };
};

const DisplayItemsService = DisplayItemsServiceFactory();
export default DisplayItemsService;
