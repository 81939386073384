/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { func, string, shape, bool } from 'prop-types';
import React, { useRef } from 'react';
import './filterItem.scss';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import iconCautionYellow from 'assets/images/icon-caution-yellow.svg';
import { isWarningFilter } from 'services/utils';
import { FILTER_KEY_CROSS_DEVICE } from 'services/consts';

const getVariantFilters = (filter, isFilterModifing = false) => {
  if (isFilterModifing) return 'info';
  if (filter.isWarning && !filter.isApply) return 'danger';
  return 'secondary';
};

function FilterItem(props) {
  const {
    filterKey,
    filters,
    text,
    onClose,
    onEdit,
    pageId,
    period,
    crossDeviceReflectionData,
    displayItems,
    updateDisplayItems,
    isFilterModifing,
  } = props;

  const refEl = useRef();
  const filter = isWarningFilter(
    filterKey,
    filters,
    pageId,
    period,
    crossDeviceReflectionData,
    displayItems
  );
  const variant = getVariantFilters(filter, isFilterModifing);

  if (filter.isWarning && FILTER_KEY_CROSS_DEVICE === filterKey) {
    // Update display items if there are invalid filters at the time of transition.
    updateDisplayItems();
  }
  const onFilterClick = (el) => {
    if (filterKey !== FILTER_KEY_CROSS_DEVICE) {
      onEdit(el);
    }
  };

  return (
    <Badge
      pill
      variant={variant}
      className="filter-item d-flex align-items-center"
    >
      {!filter.isWarning && (
        <>
          <span
            ref={refEl}
            className="filter-item__text"
            onClick={() => onFilterClick(refEl.current.parentNode)}
          >
            {text}
          </span>
          <button
            className="filter-item__close"
            onClick={onClose}
            type="button"
            aria-label="Close"
          >
            ✕
          </button>
        </>
      )}
      {filter.isWarning && (
        <>
          <span ref={refEl} className="filter-item__danger_text">
            {text}
          </span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="dragonball-tooltip variant-warning">
                {filter.warningMessage}
              </Tooltip>
            }
          >
            <img src={iconCautionYellow} alt="icon-caution" />
          </OverlayTrigger>
        </>
      )}
    </Badge>
  );
}

FilterItem.propTypes = {
  filterKey: string.isRequired,
  filters: shape([]),
  text: string.isRequired,
  onClose: func,
  onEdit: func,
  pageId: string.isRequired,
  period: shape({}),
  displayItems: shape({}),
  crossDeviceReflectionData: shape([]),
  updateDisplayItems: func,
  isFilterModifing: bool,
};

FilterItem.defaultProps = {
  filters: [],
  onClose: () => {},
  onEdit: () => {},
  period: {},
  displayItems: {},
  crossDeviceReflectionData: [],
  updateDisplayItems: () => {},
  isFilterModifing: false,
};

export default FilterItem;
