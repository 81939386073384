import { createSelector } from 'reselect';
import { getSortString } from 'domain/utils';
import { STATUS_LOADING } from 'store/helper';

// COMMON SELECTORS
// ////////////////////////////////

const getDataDetail = (state) => state.cache.adManagementMedia.data.detail.data;
const getErrorDetail = (state) =>
  state.cache.adManagementMedia.data.detail.errors;
const getStatusDetail = (state) =>
  state.cache.adManagementMedia.apiStatus.detail.status;
const getApiError = (state) => {
  return state.cache.adManagementMedia.error;
};

// UPDATE SELECTOR
// ////////////////////////////////

const getStatusFetchById = (state) =>
  state.cache.adManagementMedia.fetchByIdStatus.detail.status;

// DELETE SELECTOR
// ////////////////////////////////

const getStatusFetchDeleteList = (state) =>
  state.cache.adManagementMedia.fetchDeleteListStatus.detail.status;

// POSTBACK SELECTORS
// ////////////////////////////////

const getPostbackHistory = (state) => {
  const { postbackHistory } = state.cache.adManagementMedia;
  return postbackHistory;
};
const getFetchPostbackHistoryStatus = (state) => {
  const { fetchPostbackStatus } = state.cache.adManagementMedia;
  return fetchPostbackStatus;
};

// CSV IMPORT SELECTORS
// ////////////////////////////////

const getFileNameConfirmUpload = (state) => {
  const { fileNameConfirmUpload } = state.cache.adManagementMedia;
  return fileNameConfirmUpload;
};
const getUploadConfirmListStatus = (state) => {
  const { fetchUploadConfirmListStatus } = state.cache.adManagementMedia;
  return fetchUploadConfirmListStatus;
};
const getUploadConfirmList = (state) => {
  const { uploadConfirmList } = state.cache.adManagementMedia;
  return uploadConfirmList;
};

// LIST TABLE SELECTORS
// ////////////////////////////////

const getFetchStatus = (state) => {
  const { fetchStatus } = state.cache.adManagementMedia;
  return fetchStatus;
};
const getList = (state) => {
  const { list } = state.cache.adManagementMedia;
  return list;
};
const getListByType = (type) => (state) => {
  const { list } = state.cache.adManagementMedia;
  const { tab } = state.cache.adManagementMedia.settings;
  return type === tab ? list : [];
};
const getPagination = (state) =>
  state.cache.adManagementMedia.settings.pagination;
const getSelectedItem = (state) => {
  const { selected } = state.cache.adManagementMedia;
  return selected;
};
const getSort = (state) => state.cache.adManagementMedia.settings.sort;
const getTotalRegistered = (state) =>
  state.cache.adManagementMedia.settings.totalRegistered;
const sortSelector = createSelector(
  (state) => state.cache.adManagementMedia.settings.sort,
  (sort) => {
    return { sort: getSortString(sort) };
  }
);

// CSV EXPORT SELECTORS
// ////////////////////////////////

const getStatus = (state) => {
  const { status } = state.cache.adManagementMedia;
  return status;
};
const csvDownloadEnabledSelector = createSelector(
  [getStatus, getPagination],
  (status, pagination) => {
    return status !== STATUS_LOADING && pagination.totalItems > 0;
  }
);

const selectors = {
  getDataDetail,
  getErrorDetail,
  getStatusDetail,
  getApiError,

  getStatusFetchById,

  getStatusFetchDeleteList,

  getPostbackHistory,
  getFetchPostbackHistoryStatus,

  getFileNameConfirmUpload,
  getUploadConfirmListStatus,
  getUploadConfirmList,

  getFetchStatus,
  getList,
  getListByType,
  getPagination,
  getSelectedItem,
  getSort,
  getTotalRegistered,
  sortSelector,

  getStatus,
  csvDownloadEnabledSelector,
};

export default selectors;
