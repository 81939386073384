import types from 'store/ad-management-media/types';
import helper from 'store/helper';

// HANDLE COMMON ACTIONS
// ////////////////////////////////

const setDataList = (data, tab) =>
  helper.createAction(types.SET_DATA_LIST, { data, tab });
const setDataDetail = (data) =>
  helper.createAction(types.SET_DATA_DETAIL, { data });
const setErrorDetail = (errors, scope) =>
  helper.createAction(types.SET_ERROR_DETAIL, { errors }, scope);
const setStatusDetail = (status) =>
  helper.createAction(types.SET_STATUS_DETAIL, { status });
const clearApiError = () => helper.createAction(types.CLEAR_API_RESULT);

// HANDLE CREATE ACTION
// ////////////////////////////////

const create = (name, agencyId) =>
  helper.createAction(types.CREATE, { name, agencyId });
const add = (payload) => helper.createAction(types.ADD, payload);

// HANDLE UPDATE ACTIONS
// ////////////////////////////////

const setStatusFetchById = (status) =>
  helper.createAction(types.SET_STATUS_FETCH_BY_ID, { status });
const getByID = (payload) => helper.createAction(types.GET_BY_ID, payload);
const update = (payload) => helper.createAction(types.UPDATE, payload);

// HANDLE DELETE ACTIONS
// ////////////////////////////////

const setStatusFetchDeleteList = (status) =>
  helper.createAction(types.SET_STATUS_FETCH_DELETE_LIST, { status });
const fetchDeleteList = (payload) =>
  helper.createAction(types.FETCH_DELETE_LIST, payload);
const remove = (payload) => helper.createAction(types.REMOVE, payload);

// LIST TABLE ACTIONS
// ////////////////////////////////

const updatePage = (payload) => helper.createAction(types.UPDATE_PAGE, payload);
const updateSort = (payload) => helper.createAction(types.UPDATE_SORT, payload);
const changeSelectedItem = (payload) =>
  helper.createAction(types.CHANGE_SELECTED_ITEM, payload);
const fetchList = (payload) => helper.createAction(types.FETCH_LIST, payload);
const fetchListStart = () => helper.createAction(types.FETCH_LIST_START);
const fetchedList = (payload) =>
  helper.createAction(types.FETCH_LIST_SUCCEEDED, payload);

// LIST POSTBACK ACTIONS
// ////////////////////////////////

const fetchPostBackListStart = () =>
  helper.createAction(types.FETCH_POSTBACK_HISTORY_LIST_START);
const fetchPostBackList = () =>
  helper.createAction(types.FETCH_POSTBACK_HISTORY_LIST);
const fetchedPostBackList = (payload) =>
  helper.createAction(types.FETCH_POSTBACK_HISTORY_LIST_SUCCEEDED, payload);

// HANDLE CSV ACTIONS
// ////////////////////////////////

const upload = (payload) => helper.createAction(types.UPLOAD, payload);
const setFileNameConfirmUpload = (payload) =>
  helper.createAction(types.SET_FILE_NAME_CONFIRM_UPLOAD, payload);
const setFetchUploadConfirmListStatus = (status) =>
  helper.createAction(types.SET_FETCH_UPLOAD_CONFIRM_LIST_STATUS, { status });
const fetchedUploadConfirmList = (payload) =>
  helper.createAction(types.UPLOAD_FETCH_CONFIRM_LIST, payload);
const setUploadConfirmModalStatus = (payload) =>
  helper.createAction(types.UPLOAD_SHOW_CONFIRM_MODAL, payload);
const uploadConfirm = (payload) =>
  helper.createAction(types.UPLOAD_CONFIRM, payload);
const downloadCsvTemplate = (payload) =>
  helper.createAction(types.DOWNLOAD_CSV_TEMPLATE, payload);
const downloadCsvPostback = () =>
  helper.createAction(types.DOWNLOAD_CSV_POSTBACK);

const actions = {
  setDataList,
  setDataDetail,
  setErrorDetail,
  setStatusDetail,
  clearApiError,

  create,
  add,

  setStatusFetchById,
  getByID,
  update,

  setStatusFetchDeleteList,
  fetchDeleteList,
  remove,

  updatePage,
  updateSort,
  changeSelectedItem,
  fetchList,
  fetchListStart,
  fetchedList,

  fetchPostBackListStart,
  fetchPostBackList,
  fetchedPostBackList,

  upload,
  setFileNameConfirmUpload,
  setFetchUploadConfirmListStatus,
  fetchedUploadConfirmList,
  setUploadConfirmModalStatus,
  uploadConfirm,
  downloadCsvTemplate,
  downloadCsvPostback,
};

export default actions;
