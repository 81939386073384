import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DragonBall from 'views/atoms/dragonball/DragonBall';

const EbisCheckbox = ({
  name,
  text,
  tooltip,
  checked,
  changeSelectBoxValue,
  disabled,
  subtext,
}) => {
  const id = Math.random();
  const labelClassName = classNames({
    active: checked,
  });
  const subTextClassName = classNames({
    'ebis--disabled': disabled,
    subtext: true,
  });
  return (
    <Form.Label for={`chk-${name}-${id}`} className={labelClassName}>
      <Form.Check
        id={`chk-${name}-${id}`}
        className="checkbox-item"
        inline
        label=""
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={() => changeSelectBoxValue(name)}
      />
      <span className={disabled ? 'ebis--disabled' : ''}>
        {text}
        {subtext ? (
          <>
            <br />
            <div className={subTextClassName}>{subtext}</div>
          </>
        ) : null}
      </span>
      {!!tooltip && <DragonBall>{tooltip}</DragonBall>}
    </Form.Label>
  );
};

EbisCheckbox.defaultProps = {
  text: '',
  tooltip: '',
  checked: false,
  disabled: false,
  subtext: '',
};

EbisCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  checked: PropTypes.bool,
  changeSelectBoxValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  subtext: PropTypes.string,
};

export default React.memo(EbisCheckbox);
