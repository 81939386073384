import FilterService from 'domain/FilterService';
import { createSelector } from 'reselect';
import settingsSelector from 'store/settings/selectors';
import settingsForViewSelector from 'store/settings-for-view/selectors';
import { getPermissions } from 'store/auth/selectors';
import {
  PRIORITY_AXIS_BOTH,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_MEDIA_SYNC,
} from 'domain/consts';
import { resolve } from 'domain/permissions/permissionTypes';
import { MEDIA_SYNC_PERMISSIONS } from 'domain/permissions/contractGroups';
import {
  TAG_MANAGEMENT,
  AGENCY_MANAGEMENT,
  MEASUREMENT_SITE_PAGE_MANAGEMENT,
} from 'services/routes/constants';
import * as pages from 'services/routes/constants';
import { TAB_ALL, FILTER_KEY_CHANNEL_DISPLAY_RANGE } from 'services/consts';

const getFilters = (state) => {
  const pageId = state.settings.report.page;
  switch (pageId) {
    case TAG_MANAGEMENT:
      return state.settings.filters.tagManagement;
    case AGENCY_MANAGEMENT:
      return state.settings.filters.agencyManagement;
    case MEASUREMENT_SITE_PAGE_MANAGEMENT:
      return state.settings.filters.pageManagement;

    default:
      return state.settings.filters.items;
  }
};
const getDisplayLabel = (state) => state.cache.masterData.display;

const getDisplayItemPriorityAxis = (state) => {
  const permissions = getPermissions(state);
  const tab = settingsSelector.getTab(state);
  const pageId = settingsSelector.getPage(state);
  if (resolve(MEDIA_SYNC_PERMISSIONS[tab], permissions)) {
    if (pageId === pages.AD_MANAGEMENT || pageId === pages.AGENCY_MANAGEMENT) {
      return PRIORITY_AXIS_BOTH;
    }
    return state.settings.displayItems.items.media_sync
      ? PRIORITY_AXIS_MEDIA_SYNC
      : PRIORITY_AXIS_EBIS;
  }
  return PRIORITY_AXIS_EBIS;
};

const getSettings = createSelector(
  [
    settingsSelector.getCurrentTab,
    settingsSelector.getPage,
    getFilters,
    getDisplayLabel,
    getPermissions,
  ],
  (currentTab, pageId, filters, display, permissions) => {
    const filterableList = FilterService.getBy({
      currentTab,
      pageId,
      display,
      permissions,
    });
    const filterList = {};
    const newFilters = FilterService.prepareForUI(filters, pageId, currentTab);
    Object.keys(newFilters).forEach((key) => {
      if (key in filterableList || key === FILTER_KEY_CHANNEL_DISPLAY_RANGE) {
        filterList[key] = {
          ...newFilters[key],
          title: filterableList[key]?.title || newFilters[key].title,
        };
      }
    });
    return filterList;
  }
);

const getSettingsForView = createSelector(
  [
    settingsForViewSelector.getTab,
    settingsForViewSelector.getPage,
    getFilters,
    getDisplayLabel,
    getPermissions,
  ],
  (currentTab, pageId, filters, display, permissions) => {
    const filterableList = FilterService.getBy({
      currentTab,
      pageId,
      display,
      permissions,
    });
    const filterList = {};
    const newFilters = FilterService.prepareForUI(filters, pageId, currentTab);
    Object.keys(newFilters).forEach((key) => {
      if (key in filterableList || key === FILTER_KEY_CHANNEL_DISPLAY_RANGE) {
        filterList[key] = {
          ...newFilters[key],
          title: filterableList[key]?.title || newFilters[key].title,
        };
      }
    });
    return filterList;
  }
);

const getApplicableSettings = createSelector(
  [
    settingsSelector.getCurrentTab,
    settingsSelector.getPage,
    getFilters,
    getPermissions,
  ],
  (currentTab, pageId, filters, permissions) => {
    const filterableList = FilterService.getFilterableList(
      currentTab,
      pageId,
      permissions
    );

    return Object.keys(filters)
      .filter((key) => key in filterableList)
      .reduce((acc, key) => {
        acc[key] = filters[key];
        return acc;
      }, {});
  }
);

const getForApi = (state) => {
  const filters = FilterService.prepareForApi(
    getSettings(state),
    getDisplayItemPriorityAxis(state)
  );
  const pageId = settingsSelector.getPage(state);
  return FilterService.prepareForRequestBody({ pageId, filters });
};

const getIdsSettings = createSelector([getSettings], (filters) => {
  const {
    conv_id: { ids: convIds = [] } = {},
    media_id: { ids: mediaIds = [] } = {},
    ad_group1_id: { ids: adGroup1Ids = [] } = {},
    ad_group2_id: { ids: adGroup2Ids = [] } = {},
    agency_id: { ids: agencyIds = [] } = {},
    media_side_campaign_id: { ids: mediaSideCampaignIds = [] } = {},
    media_side_group_id: { ids: mediaSideGroupIds = [] } = {},
    media_account_id: { ids: mediaAccountIds = [] } = {},
    content_category_id: { ids: contentCategoryIds = [] } = {},
  } = filters;

  return {
    convIds,
    mediaIds,
    adGroup1Ids,
    adGroup2Ids,
    agencyIds,
    mediaSideCampaignIds,
    mediaSideGroupIds,
    mediaAccountIds,
    contentCategoryIds,
  };
});

export default {
  getSettings,
  getSettingsForView,
  getForApi,
  getFilters,
  getApplicableSettings,
  getIdsSettings,
};
