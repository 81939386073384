import helper, {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_FAIL,
  STATUS_ERROR,
  STATUS_SUCCEEDED,
} from 'store/helper';
import types from './types';

const initialState = {
  screenId: null,
  status: STATUS_IDLE, // 'idle' | 'loading' | 'succeeded' | 'failed' | 'error',
  error: null, // string | null,
};

const requestStart = (state, action) => {
  const { screenId } = action.payload;
  return {
    ...state,
    screenId,
    status: STATUS_LOADING,
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: STATUS_FAIL,
    error,
  };
};

const requestError = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: STATUS_ERROR,
    error,
  };
};

const uploadSucceeded = (state) => {
  return {
    ...state,
    status: STATUS_SUCCEEDED,
    error: null,
  };
};

const UploadReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.REQUEST_ERROR]: requestError,
  [types.UPLOAD_SUCCEEDED]: uploadSucceeded,
});

export default UploadReducer;
