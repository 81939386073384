const GTMFactory = () => {
  const addUserInfo = (user, isCapiSingleContract) => {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      logId: user.account.accountId,
      userId: user.userId,
      singleUseCapico: isCapiSingleContract,
    });
  };

  return {
    addUserInfo: (user, isCapiSingleContract) =>
      addUserInfo(user, isCapiSingleContract),
  };
};

const GTM = GTMFactory();
export default GTM;
