import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { zip, unzip, isEmpty } from 'lodash';
import DomainFilterService from 'domain/FilterService';
import { FilterConfig } from 'domain/commonbar/FilterConfig';
import masterDataSelectors from 'store/master-data/selectors';
import settingsSelector from 'store/settings/selectors';
import { getPermissions, getAgentFlag } from 'store/auth/selectors';
import { FILTER_KEY_AD_GROUP1, FILTER_KEY_AD_GROUP2 } from 'services/consts';
import { NO_PERMISSION_DISPLAY_VALUE } from 'domain/consts';
import masterDataActions from 'store/master-data/actions';
import filterActions from 'store/filters/actions';

const useFilterData = (pageId, tab) => {
  const dispatch = useDispatch();

  let currentTab = useSelector(settingsSelector.getCurrentTab, shallowEqual);
  const currentPage = useSelector(settingsSelector.getPage, shallowEqual);
  const masterData = useSelector(masterDataSelectors.getAll, shallowEqual);
  const status = useSelector(masterDataSelectors.getStatus, shallowEqual);
  const permissions = useSelector(getPermissions, shallowEqual);
  const isUserAgent = useSelector(getAgentFlag, shallowEqual);

  currentTab = tab || currentTab;

  const { display } = masterData;

  const filterableList = useMemo(
    () =>
      DomainFilterService.getBy({
        currentTab,
        pageId,
        display,
        permissions,
        currentPage,
      }),
    [currentTab, display, pageId, permissions]
  );

  const FilterMasterData = useMemo(() => {
    const {
      cv,
      media,
      adGroup1,
      adGroup2,
      agency,
      mediaSideCampaign,
      mediaSideGroup,
      mediaAccount,
      contentCategory,
    } = masterData;
    return {
      display,
      cv: DomainFilterService.uiMapperData(cv),
      media: DomainFilterService.uiMapperData(media),
      adGroup1: DomainFilterService.uiMapperData(adGroup1),
      adGroup2: DomainFilterService.uiMapperData(adGroup2),
      agency: DomainFilterService.uiMapperData(agency),
      mediaSideCampaign: DomainFilterService.uiMapperData(mediaSideCampaign),
      mediaSideGroup: DomainFilterService.uiMapperData(mediaSideGroup),
      mediaAccount: DomainFilterService.uiMapperData(mediaAccount),
      contentCategory: DomainFilterService.uiMapperData(contentCategory),
    };
  }, [display, masterData]);

  const mergedMasterData = useMemo(
    () => ({ ...FilterConfig, ...FilterMasterData, status }),
    [FilterMasterData, status]
  );

  const handleResponse = useCallback(
    (filters) => (master) => {
      const filterKey = {
        adGroup1: FILTER_KEY_AD_GROUP1,
        adGroup2: FILTER_KEY_AD_GROUP2,
      };
      const currentFilters = { ...filters };

      Object.keys(master).reduce((acc, key) => {
        if (!filterKey[key] || !filters[filterKey[key]]) return acc;
        const allIds = Object.keys(master[key]);
        const filter = { ...filters[filterKey[key]] };

        const actualFilters = zip(filter.ids, filter.values).filter(([id]) =>
          allIds.includes(id)
        );
        const [ids, values] = unzip(actualFilters);
        if (ids) {
          currentFilters[filterKey[key]].ids = ids;
          currentFilters[filterKey[key]].values = values;
        } else {
          delete currentFilters[filterKey[key]];
        }
        return acc;
      }, {});

      dispatch(filterActions.updateFilters(currentFilters));
    },
    [dispatch]
  );

  const updateFilters = useCallback(
    (filters) => {
      const currentFilters = { ...filters };
      if (!isUserAgent) {
        dispatch(filterActions.updateFilters(currentFilters));
      } else {
        const filterKey = [FILTER_KEY_AD_GROUP1, FILTER_KEY_AD_GROUP2];

        // Get ids & values equal: ******** or undefined
        const masterDataIds = filterKey.reduce((acc, key) => {
          if (!filters[key]) return acc;

          const filter = { ...filters[key] };
          const mergeIdValue = zip(filter.ids, filter.values);
          const ignoreFilter = mergeIdValue.filter(([, name]) =>
            [NO_PERMISSION_DISPLAY_VALUE, undefined].includes(name)
          );

          const [ids] = unzip(ignoreFilter);

          if (ids) acc[key] = ids;

          return acc;
        }, {});

        if (!isEmpty(masterDataIds)) {
          dispatch(
            masterDataActions.updateMasterData({
              masterDataIds,
              callback: handleResponse(currentFilters),
            })
          );
        } else {
          dispatch(filterActions.updateFilters(currentFilters));
        }
      }
    },
    [dispatch, handleResponse, isUserAgent]
  );

  return [filterableList, mergedMasterData, updateFilters];
};

export default useFilterData;
