import React, { useRef, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { bool, func, string } from 'prop-types';

const NumericInput = (props) => {
  const { onChange, value, beginNumber, isDecimal, disabled } = props;
  const inputRef = useRef(value);
  const prevValue = useRef(value || '');
  const [inputValue, setInputValue] = useState(value);

  const onValueChange = (e) => {
    const patternWholeNumber = new RegExp(`^([${beginNumber}]|(^$))([0-9]+)?$`);
    const patternDecimal = new RegExp(
      `^([${beginNumber}]|(^$))([0-9]+)?(\\.[0-9]*)?$`
    );
    const pattern = isDecimal ? patternDecimal : patternWholeNumber;

    const targetValue = e.target.value;
    if (pattern.test(targetValue)) {
      setInputValue(targetValue ? value : targetValue);
      if (onChange) {
        onChange(e);
      }
      prevValue.current = targetValue;
    } else {
      setInputValue(inputValue || '');
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Form.Control
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      onChange={onValueChange}
      ref={inputRef}
      type="text"
      value={inputValue}
      inputMode="numeric"
      disabled={disabled}
    />
  );
};

NumericInput.propTypes = {
  onChange: func,
  value: string,
  beginNumber: string,
  isDecimal: bool,
  disabled: bool,
};

NumericInput.defaultProps = {
  onChange: () => {},
  value: '',
  beginNumber: '0-9',
  isDecimal: false,
  disabled: false,
};

export default NumericInput;
