import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';
import { string, func, oneOfType, array, oneOf } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { communicationStatus } from 'services/utils';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';

import './input-collapse.scss';

const { IDLE, LOADING, SUCCEEDED, FAILED } = communicationStatus;

function InputCollapse(props) {
  const { label, name, status, error, rules, onSubmit, onFinish } = props;
  const [isCollapse, setCollapse] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [isDisabledSubmit, setDisabledSubmit] = useState(true);
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const ref = useRef();

  useEffect(() => {
    if (!isSubmit || ![FAILED, SUCCEEDED].includes(status)) return;

    setLoading(false);
    setSubmit(false);
    if (status === SUCCEEDED) {
      setCollapse(true);
      onFinish(name, value);
    } else {
      setErrorMessage(error);
    }
  }, [name, value, status, error, isSubmit, onFinish]);

  useEffect(() => {
    setValue('');
    setErrorMessage('');
  }, [isCollapse]);

  const validate = useCallback(() => {
    if (isEmpty(rules)) return '';

    const messages = rules
      .map((rule) => rule({ value, label }))
      .filter((rule) => !isEmpty(rule));

    return messages[0];
  }, [rules, value, label]);

  const handleChange = (e) => {
    setErrorMessage('');
    setValue(e.target.value);
    setDisabledSubmit(isEmpty(e.target.value));
  };

  const handleSubmit = () => {
    const validateResults = validate();
    setErrorMessage(validateResults);
    if (isEmpty(validateResults)) {
      setLoading(true);
      setSubmit(true);
      onSubmit(value, name);
    }
  };

  return (
    <div className="input-collapse" ref={ref}>
      {isCollapse ? (
        <Button
          variant="link"
          size="sm"
          className="p-0"
          onClick={() => setCollapse(false)}
        >
          新しく追加
        </Button>
      ) : (
        <DataSyncLoader isLoading={isLoading}>
          <ErrorTooltipWrapper
            isError={!!errorMessage}
            errorMess={errorMessage}
            ref={ref}
          >
            <Form.Control
              size="sm"
              className={`text-left ${errorMessage ? 'error' : ''}`}
              defaultValue={value}
              placeholder="値を入力"
              onChange={handleChange}
            />
          </ErrorTooltipWrapper>
          <div className="text-right">
            <Button
              variant="link"
              size="xs"
              className="btn-cancel"
              onClick={() => {
                setCollapse(true);
                setDisabledSubmit(true);
              }}
            >
              キャンセル
            </Button>
            <Button
              variant="secondary"
              size="xs"
              disabled={isDisabledSubmit}
              onClick={handleSubmit}
            >
              OK
            </Button>
          </div>
        </DataSyncLoader>
      )}
    </div>
  );
}

InputCollapse.propTypes = {
  label: string,
  name: string,
  status: string,
  error: string,
  rules: oneOfType([array]),
  onSubmit: func,
  onFinish: func,
};

InputCollapse.defaultProps = {
  label: '',
  name: '',
  error: '',
  rules: [],
  status: oneOf([IDLE, LOADING, SUCCEEDED, FAILED]).isRequired,
  onSubmit: () => {},
  onFinish: () => {},
};

export default InputCollapse;
