import {
  CONVERSIONID,
  LINE_LOGIN_ENDPOINT_URL,
  LINE_LOGIN_SCOPE,
  LINE_LOGIN_BOT_PROMPT,
  LINE_MESSAGING_API_CHANNEL_ID,
  LINE_LOGIN_CHANNEL_ID,
} from 'domain/fields';
import { chain, get, isArray, isEmpty } from 'lodash';
import { getErrorMessageByCode } from 'services/utils';
import lineAddFriendSettingRules from 'services/validations/lineAddFriendRules';
import * as messageError from 'services/validations/messageErrorByCode';

export const customLineAddFriendButtonUrl = (value) => {
  return `<a href="${value}" class="ebis_line_add_friend_trigger" />`;
};

export const formatDataList = (data) => {
  return data.map((item) => ({
    ...item,
    isAllowSelectRow: true,
    add_friend_button_url: customLineAddFriendButtonUrl(
      get(item, 'add_friend_button_url', '')
    ),
  }));
};

/**
 * Format Line Add Friend Detail Setting data
 * @return object
 */
export const formatLineAddFriendSettingDetailData = (
  dataField = {},
  detailSetting = {}
) => {
  return Object.keys(dataField).reduce((obj, field) => {
    const error = '';
    return {
      ...obj,
      [field]: {
        ...dataField[field],
        value: detailSetting[field],
        error,
      },
    };
  }, {});
};

export const formatDefaultError = (dataField = {}) => {
  return Object.keys(dataField).reduce((obj, field) => {
    const error = '';
    return {
      ...obj,
      [field]: {
        ...dataField[field],
        error,
      },
    };
  }, {});
};

/**
 * Format Line Add Friend Detail Setting data
 * @return object
 */
export const formatErrorNotExistCv = (
  data = {},
  detailSetting = {},
  tagListData = []
) => {
  return Object.keys(data).reduce((obj, field) => {
    let error = '';
    const checkExistInTagList = tagListData.some((tag) => {
      return tag.conversionid === get(detailSetting, 'conversionid');
    });

    if (field === CONVERSIONID && !checkExistInTagList) {
      // CV_NAME_NOT_EXIST_CHECK
      error = messageError.CV_NAME_NOT_EXIST_CHECK;
    } else {
      error = '';
    }

    return {
      ...obj,
      [field]: {
        ...data[field],
        error,
      },
    };
  }, {});
};

export const validateDataSettings = (values, labels, rules) => {
  if (isEmpty(values) || isEmpty(rules)) return {};

  return chain(values)
    .mapValues((value, field) => ({
      label: labels[field],
      value: value || null,
    }))
    .mapValues((value, field) => {
      if (isEmpty(rules[field])) return [];

      const rulesByField = [...rules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();
};

export const validateLineAddFriendSettingData = (data) => {
  const { values, labels } = Object.keys(data).reduce(
    (acc, field) => {
      const item = { ...data[field] };

      return {
        values: { ...acc.values, [field]: item.value },
        labels: { ...acc.labels, [field]: item.label },
      };
    },
    { values: {}, labels: {} }
  );

  const error = validateDataSettings(values, labels, lineAddFriendSettingRules);

  return {
    hasError: !isEmpty(error),
    dataValidated: Object.keys(error).reduce((acc, field) => {
      return { ...acc, [field]: { ...acc[field], error: error[field] } };
    }, data),
  };
};

export const handleErrorResponse = (action, isError, errors) => {
  let contentError = '';
  let isErrorCommon = false;
  const isReloadList = !isError;

  if (isError && !isEmpty(errors) && isArray(errors)) {
    const [error] = errors;
    isErrorCommon = ['LINE_LOGIN_LIFF_ID_EXIST_CHECK'].includes(error.code);

    if (isErrorCommon) {
      isErrorCommon = true;
      const label = 'LINE友だち追加計測設定の設定';
      contentError = getErrorMessageByCode(error, messageError, { label });
    }
  }

  return {
    title: `データ更新に失敗しました`,
    content: contentError,
    isErrorCommon,
    isReloadList: isReloadList || isErrorCommon,
  };
};

export const getErrorTypesList = (errors, types, message) => {
  if (errors.some((item) => types.includes(item.code))) {
    return [message];
  }
  return [];
};

export const getErrorList = (errors, types) => {
  return errors
    .filter((err) => types.includes(err.code))
    .map((err) => {
      const label = 'LINE友だち追加計測設定の設定';
      return getErrorMessageByCode(err, messageError, { label });
    });
};

export const resetErrorMessages = (type, errorMessages) => {
  switch (type) {
    case 'errLineLoginList':
      return { ...errorMessages, errLineLoginList: [] };
    case 'errMessagingAPIList':
      return { ...errorMessages, errMessagingAPIList: [] };
    case null:
      return { ...errorMessages, errCommonList: [] };
    default:
      return {
        errCommonList: [],
        errLineLoginList: [],
        errMessagingAPIList: [],
      };
  }
};

export const getErrorMessages = (errors) => {
  const errCommon = [
    'USER_PERMISSION_CHECK',
    'LINE_ADD_FRIEND_SETTING_ID_EXIST_CHECK',
  ];
  const errLineLogin = {
    common: [
      'INVALID_LINE_LOGIN_CHANNEL_SECRET',
      'INVALID_LINE_LOGIN_CHANNEL_ID',
    ],
    [LINE_LOGIN_ENDPOINT_URL]: ['INVALID_LINE_LOGIN_ENDPOINT_URL'],
    [LINE_LOGIN_SCOPE]: ['INVALID_LINE_LOGIN_SCOPE'],
    [LINE_LOGIN_BOT_PROMPT]: ['INVALID_LINE_LOGIN_BOT_PROMPT'],
    [LINE_LOGIN_CHANNEL_ID]: ['LINE_LOGIN_CHANNEL_ID_EXIST_CHECK'],
  };
  const errLiffID = [
    'LINE_LOGIN_NOT_FOUND',
    'LINE_LOGIN_LIFF_ID_NOT_EXIST_CHECK',
  ];
  const errMessagingAPI = {
    common: [
      'INVALID_LINE_MESSAGING_API_CHANNEL_ID',
      'INVALID_LINE_MESSAGING_API_CHANNEL_SECRET',
      'LINE_MESSAGING_API_PERMISSION_CHECK',
      'LINE_MESSAGING_API_BOT_BASIC_ID_NOT_EXIST_CHECK',
    ],
    [LINE_MESSAGING_API_CHANNEL_ID]: [
      'LINE_MESSAGING_API_CHANNEL_ID_EXIST_CHECK',
    ],
  };

  return {
    errCommonList: getErrorList(
      errors,
      errCommon,
      messageError.USER_PERMISSION_CHECK
    ),
    errLineLoginList: [
      ...getErrorTypesList(
        errors,
        errLineLogin.common,
        messageError.LINE_LOGIN_OR_MESSAGING_API_ERROR
      ),
      ...getErrorTypesList(
        errors,
        errLineLogin[LINE_LOGIN_ENDPOINT_URL],
        messageError.INVALID_LINE_LOGIN_ENDPOINT_URL
      ),
      ...getErrorTypesList(
        errors,
        errLineLogin[LINE_LOGIN_SCOPE],
        messageError.INVALID_LINE_LOGIN_SCOPE
      ),
      ...getErrorTypesList(
        errors,
        errLineLogin[LINE_LOGIN_BOT_PROMPT],
        messageError.INVALID_LINE_LOGIN_BOT_PROMPT
      ),
      ...getErrorTypesList(errors, errLiffID, messageError.LIFF_ID_ERROR),
      ...getErrorList(errors, errLineLogin[LINE_LOGIN_CHANNEL_ID]),
    ],
    errMessagingAPIList: [
      ...getErrorTypesList(
        errors,
        errMessagingAPI.common,
        messageError.LINE_LOGIN_OR_MESSAGING_API_ERROR
      ),
      ...getErrorList(errors, errMessagingAPI[LINE_MESSAGING_API_CHANNEL_ID]),
    ],
  };
};

/**
 * Format data when error
 */
export const getLineAddFriendSettingDataError = (form, errors, params = {}) => {
  if (isEmpty(errors) || !isArray(errors)) return form;

  return errors.reduce((acc, error) => {
    const [field, index, name] = error.field.split('.');
    const item = { ...acc[field] };

    if (isEmpty(item)) return acc;

    let message = '';
    if (error.code === 'MAX_SETTING_ALLOW_REGIST_CHECK') {
      message = '[LINE友だち追加計測設定]は20件以内で登録してください。';
    }
    if (error.code === 'CV_NAME_NOT_EXIST_CHECK') {
      message = messageError.CV_NAME_NOT_EXIST_CHECK;
    }

    switch (field) {
      default:
        item.error = message;
        break;
    }

    return { ...acc, [field]: item };
  }, form);
};

export const formatDataRequest = (dataField) => {
  return {
    conversionid: dataField.conversionid.value.toString(),
    line_login_channel_id: dataField.line_login_channel_id.value,
    line_login_channel_secret: dataField.line_login_channel_secret.value,
    line_login_liff_id: dataField.line_login_liff_id.value,
    line_messaging_api_channel_id:
      dataField.line_messaging_api_channel_id.value,
    line_messaging_api_channel_secret:
      dataField.line_messaging_api_channel_secret.value,
  };
};

export const getLineSettingById = (data, id) => {
  return data.find((obj) => obj.id === id);
};

export const checkDataFieldEmpty = (data) => {
  return Object.values(data).some((item) => item.value === '');
};
