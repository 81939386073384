import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { array, object, func, oneOf, oneOfType } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { CSV_MAX_SIZE } from 'domain/consts';
import {
  COLUMNS_TABLE_CONFIRM_DELETE,
  TEMPLATE_REMOVE_AD,
} from 'domain/ad-management/consts';
import { COLUMNS_TABLE_CONFIRM_CONTENT_INVALID_MERGED } from 'services/consts';
import { communicationStatus, checkFormatErrorByLine } from 'services/utils';
import {
  requiredUpload,
  csvFile,
  maxFileSize,
} from 'services/validations/commonValidations';
import {
  formatGroupErrorCsv,
  getErrorMessageByCode,
} from 'services/ad-management/adManagementService';
import FileUpload from 'views/molecules/FileUpload';
import TableConfirmDetail from 'views/organism/TableConfirmDetail';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';

import './csv-upload-modal.scss';

const { IDLE, LOADING, SUCCEEDED, FAILED } = communicationStatus;
const STEP_UPLOAD = 1;
const STEP_CONFIRM = 2;

function AdDeleteCsvUploadModal(props) {
  const {
    status,
    data,
    errors,
    masterData,
    onHide,
    onUpload,
    onSubmit,
  } = props;
  const { display: configDisplay } = masterData;

  const [step, setStep] = useState(STEP_UPLOAD);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorConfirm, setErrorConfirm] = useState([]);
  const [files, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isUpload, setUpload] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const onFileUploadAccepted = (uploadedFile) => {
    setFile(uploadedFile);
    setErrorMessage(null);
    setErrorConfirm([]);
  };

  const onFileUploadClear = () => {
    setFile(null);
    setErrorMessage(null);
    setErrorConfirm([]);
  };

  const validateFile = useCallback(() => {
    const rules = [
      requiredUpload(),
      csvFile(),
      maxFileSize(CSV_MAX_SIZE),
    ];
    const [file] = files;
    return rules
      .map((rule) => rule({ value: file, label: 'CSV' }))
      .filter((rule) => !isEmpty(rule));
  }, [files]);

  const handleUpload = useCallback(() => {
    const fileErrors = validateFile();
    if (isEmpty(fileErrors)) {
      setUpload(true);
      setLoading(true);
      onUpload(files);
      setFile(null);
    } else {
      onFileUploadClear();
      setErrorMessage(fileErrors[0]);
    }
  }, [validateFile, onUpload, files]);

  const handleSubmit = useCallback(() => {
    setSubmit(true);
    setLoading(true);
    onSubmit();
  }, [onSubmit]);

  const handleBack = useCallback(() => {
    setStep(STEP_UPLOAD);
    setFile(null);
    setErrorMessage(null);
    setErrorConfirm([]);
  }, []);

  const handleClose = useCallback(() => {
    setFile(null);
    setErrorMessage(null);
    setErrorConfirm([]);
    onHide();
  }, [onHide]);

  useEffect(() => {
    if (status === SUCCEEDED && isSubmit) {
      handleClose();
    }

    if ([SUCCEEDED, FAILED].includes(status)) {
      setLoading(false);
      if (isUpload) {
        setUpload(false);
        if (status === SUCCEEDED) {
          setStep(STEP_CONFIRM);
        }
      }
      if (isSubmit) {
        setSubmit(false);
        setStep(STEP_UPLOAD);
      }
    }
  }, [handleClose, onHide, status, isUpload, isSubmit]);

  useEffect(() => {
    if (isEmpty(errors)) return;
    const isFormatErrorByLine = checkFormatErrorByLine(errors[0]);
    if (isFormatErrorByLine) {
      setErrorConfirm(
        formatGroupErrorCsv(errors, configDisplay, { action: '削除' })
      );
      setErrorMessage(
        getErrorMessageByCode({
          code: 'FILE_CONTENT_VALID_CHECK',
          metadata: {
            param: [errors.reduce((sum, curr) => sum + curr.count, 0)],
          },
        })
      );
    } else {
      setErrorMessage(
        getErrorMessageByCode(errors[0], { link: TEMPLATE_REMOVE_AD })
      );
    }
  }, [errors, configDisplay]);

  const modalBody = useMemo(() => {
    if (step === STEP_CONFIRM) {
      return (
        <TableConfirmDetail
          maxContent={434}
          columns={COLUMNS_TABLE_CONFIRM_DELETE}
          rows={data}
        />
      );
    }
    return (
      <>
        {errorMessage && (
          <div
            className="color-bad txt-standard error-message"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          />
        )}
        {!isEmpty(errorConfirm) && (
          <TableConfirmDetail
            maxContent={434}
            columns={COLUMNS_TABLE_CONFIRM_CONTENT_INVALID_MERGED}
            rows={errorConfirm}
          />
        )}
        <FileUpload
          isClear={!files}
          onFileUploadAccepted={onFileUploadAccepted}
          onClear={onFileUploadClear}
          note="30MB以下、10,000行まで登録可"
        />
      </>
    );
  }, [step, data, files, errorMessage, errorConfirm]);

  const modalFooter = useMemo(() => {
    if (step === STEP_UPLOAD) {
      return (
        <>
          <Button
            size="sm"
            variant="link"
            onClick={handleClose}
            disabled={isLoading}
          >
            キャンセル
          </Button>
          <Button
            size="sm"
            variant="secondary"
            onClick={handleUpload}
            disabled={isLoading || files === null}
          >
            OK
          </Button>
        </>
      );
    }
    return (
      <>
        <Button
          size="sm"
          variant="link"
          disabled={isLoading}
          onClick={handleBack}
        >
          戻る
        </Button>
        <Button
          size="sm"
          variant="secondary"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          OK
        </Button>
      </>
    );
  }, [
    handleUpload,
    handleClose,
    handleSubmit,
    handleBack,
    isLoading,
    step,
    files,
  ]);

  return (
    <Modal
      show
      centered
      backdrop="static"
      size="w800"
      className="modal--csv-upload modal--csv-upload-delete"
    >
      <Modal.Header>
        <Modal.Title>広告のCSV一括削除</Modal.Title>
        <Button
          as="a"
          href={TEMPLATE_REMOVE_AD}
          variant="secondary"
          size="xs"
          className="btn-icon--with-text"
        >
          <i className="far fa-arrow-to-bottom" />
          CSVフォーマット
        </Button>
        <div className="txt-standard modal-header__description">
          広告IDで指定された広告を一括削除します。
        </div>
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={isLoading}>{modalBody}</DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>{modalFooter}</Modal.Footer>
    </Modal>
  );
}

AdDeleteCsvUploadModal.propTypes = {
  onHide: func,
  onUpload: func,
  onSubmit: func,
  status: oneOf([IDLE, LOADING, SUCCEEDED, FAILED]).isRequired,
  data: oneOfType([array]),
  errors: oneOfType([array]),
  masterData: oneOfType([object]),
};

AdDeleteCsvUploadModal.defaultProps = {
  data: [],
  errors: [],
  masterData: [],
  onHide: () => {},
  onUpload: () => {},
  onSubmit: () => {},
};

export default AdDeleteCsvUploadModal;
