/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import useAuth from 'services/custom-hooks/useAuth';
import useCheckScreenExists from 'services/custom-hooks/useCheckScreenExists';
import PageNotAuthorizedContainer from 'views/pages/403';
import { getScreenPermissions, getScreenResolveType } from 'domain/utils';
import DummyMainContentContainer from 'views/pages/dummy-main-content/DummyMainContentContainer';
import { LTV_ANALYZE, LTV_ALL_SCREEN_IDS } from 'services/routes/constants';
import { ltvAllPermissions } from 'domain/permissions/screenPermissionConfigs';

const withAuth = (
  Component,
  { currentTab, screenId, onRedirect = () => <PageNotAuthorizedContainer /> }
) => {
  return function WithAuth(props) {
    const { isExistsScreen } = useCheckScreenExists();

    let { isAllowed } = useAuth(
      getScreenPermissions(screenId, currentTab),
      getScreenResolveType(screenId)
    );

    let component = isExistsScreen ? onRedirect() : <></>;

    const isLtvSubscribed = useAuth(ltvAllPermissions).isAllowed;
    const hasAnyLtvViewPermission = useAuth(
      getScreenPermissions(LTV_ANALYZE, currentTab)
    ).isAllowed;
    // Case: Request to "LTV Forecast" Service.
    if (LTV_ALL_SCREEN_IDS.includes(screenId)) {
      // Branch depending on whether the user subscribes to "LTV Forecast" Service.
      if (isLtvSubscribed) {
        // Case: User does not have the minimum required permissions.
        if (!hasAnyLtvViewPermission) {
          isAllowed = false;
        }
      } else {
        isAllowed = false;
        component = <DummyMainContentContainer screenId={screenId} />;
      }
    }

    return isAllowed ? <Component {...props} /> : component;
  };
};

/**
 * Since the former HoC withAuth just somehow doesn't work with Route and params (invalid hooks called ...)
 * This HoC is created to simply work with other cases
 * @param currentTab
 * @param screenId
 * @param onRedirect
 */
export const withAuthHoC = ({
  currentTab,
  screenId,
  onRedirect = () => <PageNotAuthorizedContainer />,
}) => (WrappedComponent) => {
  const { isAllowed } = useAuth(
    getScreenPermissions(screenId, currentTab),
    getScreenResolveType(screenId)
  );

  if (!isAllowed) {
    return onRedirect();
  }

  return WrappedComponent;
};

export default withAuth;
