/* eslint-disable no-unused-vars */

export const validateTwoValuesMinMax = () => (value, params, state) => {
  if (!value[0] || !value[1]) return true;
  return parseInt(value[0], 10) <= parseInt(value[1], 10);
};

export const validateNParisValueMinMax = () => (value, params, state) => {
  if (value === null || value === undefined) {
    return true;
  }
  const { min, max } = value;
  if (min === null || min === undefined || max === null || max === undefined) {
    return true;
  }
  return min <= max;
};

export const validateIsEmptyString = () => (value, params, state) => {
  return value === '' || value === null || value === undefined;
};

export const validateIsExistInArray = () => (value, params, state) => {
  if (params.list.find((item) => item === value)) {
    return true;
  }
  return false;
};

export const validateInRange = () => (value, params, state) => {
  return !value || (value >= params.min && value <= params.max);
};

export const validateIsNumInRange = () => (value, params, state) => {
  return (
    !value ||
    (!Number.isNaN(value) && value >= params.min && value <= params.max)
  );
};

export const validateIsInteger = () => (value) => {
  const n = Math.floor(Number(value));
  return n !== Infinity && String(n) === String(value) && n >= 0;
};
