const UPDATE_CONVERSIONS = 'masterData/UPDATE_CONVERSIONS';
const FETCH_CONVERSIONS = 'masterData/FETCH_CONVERSIONS';

const UPDATE_EXPIRES = 'master-data/UPDATE_EXPIRES';

const REQUEST_START = 'master-data/REQUEST_START';
const REQUEST_FAILED = 'master-data/REQUEST_FAILED';

const FETCH_DISPLAY = 'master-data/FETCH_DISPLAY';
const FETCH_DISPLAY_SUCCEEDED = 'master-data/FETCH_DISPLAY_SUCCEEDED';

const FETCH_CV = 'master-data/FETCH_CV';
const FETCH_CV_SUCCEEDED = 'master-data/FETCH_CV_SUCCEEDED';

const FETCH_MEDIA = 'master-data/FETCH_MEDIA';
const FETCH_MEDIA_SUCCEEDED = 'master-data/FETCH_MEDIA_SUCCEEDED';

const FETCH_AD_GROUP1 = 'master-data/FETCH_AD_GROUP1';
const FETCH_AD_GROUP1_SUCCEEDED = 'master-data/FETCH_AD_GROUP1_SUCCEEDED';

const FETCH_AD_GROUP2 = 'master-data/FETCH_AD_GROUP2';
const FETCH_AD_GROUP2_SUCCEEDED = 'master-data/FETCH_AD_GROUP2_SUCCEEDED';

const FETCH_MEDIA_SIDE_CAMPAIGN = 'master-data/FETCH_MEDIA_SIDE_CAMPAIGN';
const FETCH_MEDIA_SIDE_CAMPAIGN_SUCCEEDED =
  'master-data/FETCH_MEDIA_SIDE_CAMPAIGN_SUCCEEDED';

const FETCH_MEDIA_SIDE_GROUP = 'master-data/FETCH_MEDIA_SIDE_GROUP';
const FETCH_MEDIA_SIDE_GROUP_SUCCEEDED =
  'master-data/FETCH_MEDIA_SIDE_GROUP_SUCCEEDED';

const FETCH_MEDIA_ACCOUNT = 'master-data/FETCH_MEDIA_ACCOUNT';
const FETCH_MEDIA_ACCOUNT_SUCCEEDED =
  'master-data/FETCH_MEDIA_ACCOUNT_SUCCEEDED';

const FETCH_AGENCY = 'master-data/FETCH_AGENCY';
const FETCH_AGENCY_SUCCEEDED = 'master-data/FETCH_AGENCY_SUCCEEDED';

const FETCH_USER = 'master-data/FETCH_USER';
const FETCH_USER_SUCCEEDED = 'master-data/FETCH_USER_SUCCEEDED';

const FETCH_CONTENT_CATEGORY = 'master-data/FETCH_CONTENT_CATEGORY';
const FETCH_CONTENT_CATEGORY_SUCCEEDED =
  'master-data/FETCH_CONTENT_CATEGORY_SUCCEEDED';

const SEARCH_CV = 'master-data/SEARCH_CV';
const SEARCH_MEDIA = 'master-data/SEARCH_MEDIA';
const SEARCH_AD_GROUP1 = 'master-data/SEARCH_AD_GROUP1';
const SEARCH_AD_GROUP2 = 'master-data/SEARCH_AD_GROUP2';
const SEARCH_MEDIA_SIDE_CAMPAIGN = 'master-data/SEARCH_MEDIA_SIDE_CAMPAIGN';
const SEARCH_MEDIA_SIDE_GROUP = 'master-data/SEARCH_MEDIA_SIDE_GROUP';
const SEARCH_MEDIA_ACCOUNT = 'master-data/SEARCH_MEDIA_ACCOUNT';

const SEARCH_AGENCY = 'master-data/SEARCH_AGENCY';
const SEARCH_CONTENT_CATEGORY = 'master-data/SEARCH_CONTENT_CATEGORY';
const SEARCH_SUCCEEDED = 'master-data/SEARCH_SUCCEEDED';

const UPDATE_MASTERDATA = 'master-data/UPDATE_MASTERDATA';
const UPDATE_MASTERDATA_SUCCEEDED = 'master-data/UPDATE_MASTERDATA_SUCCEEDED';

const FETCH_ALL = 'master-data/FETCH_ALL';
const FETCH_ALL_FOR_AD_MANAGEMENT = 'master-data/FETCH_ALL_FOR_AD_MANAGEMENT';
const FETCH_ALL_FOR_SETTINGS = 'master-data/FETCH_ALL_FOR_SETTINGS';
const FETCH_ALL_SUCCEEDED = 'master-data/FETCH_ALL_SUCCEEDED';

const SET_TYPE = 'master-data/SET_TYPE';

const FETCH_MEDIA_BY_SETTING_MAIL = 'master-data/FETCH_MEDIA_BY_SETTING_MAIL';

export default {
  UPDATE_CONVERSIONS,
  FETCH_CONVERSIONS,
  UPDATE_EXPIRES,
  REQUEST_START,
  REQUEST_FAILED,
  FETCH_DISPLAY,
  FETCH_DISPLAY_SUCCEEDED,
  FETCH_CV,
  FETCH_CV_SUCCEEDED,
  FETCH_MEDIA,
  FETCH_MEDIA_SUCCEEDED,
  FETCH_AGENCY,
  FETCH_AGENCY_SUCCEEDED,
  FETCH_AD_GROUP1,
  FETCH_AD_GROUP1_SUCCEEDED,
  FETCH_AD_GROUP2,
  FETCH_AD_GROUP2_SUCCEEDED,
  FETCH_MEDIA_SIDE_CAMPAIGN,
  FETCH_MEDIA_SIDE_CAMPAIGN_SUCCEEDED,
  FETCH_MEDIA_SIDE_GROUP,
  FETCH_MEDIA_SIDE_GROUP_SUCCEEDED,
  FETCH_MEDIA_ACCOUNT,
  FETCH_MEDIA_ACCOUNT_SUCCEEDED,
  FETCH_CONTENT_CATEGORY,
  FETCH_CONTENT_CATEGORY_SUCCEEDED,
  SEARCH_CV,
  SEARCH_MEDIA,
  SEARCH_AD_GROUP1,
  SEARCH_AD_GROUP2,
  SEARCH_MEDIA_SIDE_CAMPAIGN,
  SEARCH_MEDIA_SIDE_GROUP,
  SEARCH_MEDIA_ACCOUNT,
  SEARCH_AGENCY,
  SEARCH_CONTENT_CATEGORY,
  SEARCH_SUCCEEDED,
  UPDATE_MASTERDATA,
  UPDATE_MASTERDATA_SUCCEEDED,
  FETCH_ALL,
  FETCH_ALL_FOR_AD_MANAGEMENT,
  FETCH_ALL_FOR_SETTINGS,
  FETCH_ALL_SUCCEEDED,
  SET_TYPE,
  FETCH_MEDIA_BY_SETTING_MAIL,
  FETCH_USER,
  FETCH_USER_SUCCEEDED,
};
