import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useReducer,
} from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bool, string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { loggerActions } from 'store/logger';
import dataExportTypes from 'store/data-export/types';
import dataExportSelectors from 'store/data-export/selectors';
import dataExportActions from 'store/data-export/actions';
import masterdataActions from 'store/master-data/actions';
import {
  REPORT_TYPE_AD_REPO,
  REPORT_FIELDS,
  SERVICE_STORE_S3,
} from 'domain/data-export/consts';
import {
  getServiceStores,
  formatDataError,
  checkIsRegularReport,
  getPageIdByReportType,
  checkIsDataExport,
} from 'services/data-export/dataExportService';
import {
  SettingReportReducer,
  initialState,
} from 'views/pages/data-export/SettingReportReducer';
import SettingReportContext from 'views/pages/data-export/SettingReportContext';
import pages from 'services/routes/pages';
import { DATA_EXPORT } from 'services/routes/constants';
import { getErrorMessageByCode } from 'services/utils';
import { ERROR_MESSAGE_COMMON } from 'services/validations/dataExportErrorMessages';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import TagCard from 'views/atoms/card/TagCard/TagCard';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import NormalReportSetting from 'views/pages/data-export/components/NormalReportSetting';
import AdrepoReportSetting from 'views/pages/data-export/components/AdrepoReportSetting';
import { MASTER_DATA_FILTER_LIMIT } from 'domain/consts';
import DomainFilterService from 'domain/FilterService';

import './data-export-setting.scss';

const {
  SERVICE_STORE,
  REPORT_TYPE,
  REPORT_NAME,
  REPORT_CHANNEL,
  QUICK_SEARCH,
  DIMENSIONS,
  METRICS,
  DISPLAY_ITEMS,
  SUMMARY_MODE,
} = REPORT_FIELDS;

function SettingReportContainer({
  reportId,
  isAllowedSettingAdrepoReport,
  isAllowRegisterAdrepoReport,
  isAllowedSettingNormalReport,
  isAllowRegisterNormalReport,
  isAllowedSettingDataExport,
  isAllowRegisterRegularReport,
  isAllowRegisterDataExport,
  statusSystemSync,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const serviceStoreRef = useRef();
  const isActionUpdate = !!reportId;

  const { isLoading, defaultReport, masterdata } = useSelector(
    dataExportSelectors.getStatesForSetting,
    shallowEqual
  );

  const {
    [SERVICE_STORE]: defaultServiceStore,
    [REPORT_TYPE]: defaultReportType,
    [REPORT_CHANNEL]: defaultChannel,
    [QUICK_SEARCH]: defaultQuickSearch,
  } = defaultReport;

  const [isHideContentPage, setHideContentPage] = useState(false);

  const [settingReport, dispatchSetting] = useReducer(SettingReportReducer, {
    ...initialState,
    serviceStore: defaultServiceStore,
    reportType: defaultReportType,
    channel: defaultChannel,
  });

  const {
    displayItem,
    dimension,
    metric,
    defaultReportName,
    display,
    permissions,
    summaryMode,
  } = masterdata;

  const { serviceStore, reportType, channel, errorReport = {} } = settingReport;

  const filterableList = useMemo(
    () =>
      DomainFilterService.getBy({
        currentTab: checkIsRegularReport(reportType) ? channel : reportType,
        pageId: getPageIdByReportType(reportType),
        display,
        permissions,
      }),
    [channel, display, permissions, reportType]
  );

  const serviceStores = useMemo(() => {
    return getServiceStores({
      isEdit: isActionUpdate,
      defaultReportType,
      currentReportType: reportType,
      isAllowedSettingAdrepoReport,
      isAllowRegisterAdrepoReport,
      isAllowedSettingNormalReport,
      isAllowRegisterNormalReport,
      isAllowedSettingDataExport,
      statusSystemSync,
    });
  }, [
    defaultReportType,
    reportType,
    isActionUpdate,
    isAllowedSettingAdrepoReport,
    isAllowedSettingNormalReport,
    isAllowRegisterNormalReport,
    isAllowRegisterAdrepoReport,
    isAllowedSettingDataExport,
    statusSystemSync,
  ]);

  const getDefaultReportByType = useCallback(
    (type) => {
      if (type !== REPORT_TYPE_AD_REPO) return defaultReport;

      return {
        [REPORT_TYPE]: type,
        [REPORT_NAME]: defaultReportName[type],
        [QUICK_SEARCH]: Object.entries(defaultReport[QUICK_SEARCH]).reduce(
          (acc, [key, value]) => {
            if (value === null) return acc;
            return { ...acc, [key]: value };
          },
          {}
        ),
      };
    },
    [defaultReport, defaultReportName]
  );

  const handleChangeOutputType = (value) => () => {
    if (value === serviceStore) return;

    dispatchSetting({ type: 'clearError' });
    if (value !== REPORT_TYPE_AD_REPO && serviceStore !== REPORT_TYPE_AD_REPO) {
      dispatchSetting({ type: 'setServiceStore', payload: value });
      return;
    }

    dispatch(
      loggerActions.logConfirmModal({
        title: '変更すると設定中の内容がリセットされます。',
        content:
          '出力先を変更すると、現在設定中の内容がすべてリセットされます。<br/>変更してもよろしいですか？',
        cancelBtn: true,
        callback: () => {
          dispatchSetting({ type: 'setServiceStore', payload: value });
          let type = defaultReportType;
          if (value === REPORT_TYPE_AD_REPO) {
            type = REPORT_TYPE_AD_REPO;
            dispatchSetting({
              type: 'setDataAdrepoReport',
              payload: {
                [REPORT_NAME]: defaultReportName[value],
                [QUICK_SEARCH]: defaultQuickSearch,
              },
            });
          }

          dispatchSetting({ type: 'setReportType', payload: type });
        },
      })
    );
  };

  const handleRedirectToListScreen = useCallback(() => {
    history.push(pages[DATA_EXPORT].path);
  }, [history]);

  const handleResponse = useCallback(
    ({ action, isError = false, errors = [], data }) => {
      // Registered/Updated successfully
      if (
        !isError &&
        [dataExportTypes.CREATE, dataExportTypes.UPDATE].includes(action)
      ) {
        handleRedirectToListScreen();
      }

      // Get detail/Register/Update failed
      if (isError && !isEmpty(errors) && isArray(errors)) {
        const [error] = errors;

        const isErrorCommon = [
          'ALLOWED_REPORT_CHECK',
          'LIMIT_REGISTER_CHECK',
          'REPORT_ID_EXISTS_CHECK',
          'ALLOW_USER_MODIFY_REPORT_CHECK',
          'MAX_REPORT_ALLOW_REGIST_CHECK',
        ].includes(error.code);

        if (isErrorCommon) {
          let { code } = error;
          if (
            code === 'MAX_REPORT_ALLOW_REGIST_CHECK' &&
            data.type !== REPORT_TYPE_AD_REPO
          ) {
            code = 'LIMIT_REGISTER_CHECK';
          }
          setHideContentPage(isErrorCommon);
          const content = getErrorMessageByCode(
            { ...error, code },
            ERROR_MESSAGE_COMMON
          );
          dispatch(
            loggerActions.logConfirmModal({
              title: 'データ更新に失敗しました',
              content,
              callback: handleRedirectToListScreen,
            })
          );
        } else {
          dispatchSetting({
            type: 'setError',
            payload: formatDataError(errors),
          });
        }
      }
    },
    [dispatch, handleRedirectToListScreen]
  );

  const handleSubmit = (action, dataRequest) => {
    if (action === dataExportTypes.UPDATE) {
      dispatch(dataExportActions.update(reportId, dataRequest, handleResponse));
    } else {
      dispatch(dataExportActions.create(dataRequest, handleResponse));
    }
  };

  const handleSearchCv = useCallback(
    (search, selected) => {
      dispatch(
        masterdataActions.searchCv({
          search,
          selected,
          limit: MASTER_DATA_FILTER_LIMIT + 1,
        })
      );
    },
    [dispatch]
  );

  const handleApplyView = useCallback(
    (viewId, callback) => {
      dispatch(
        loggerActions.logConfirmModal({
          title: 'ビューを反映すると編集中の内容が書き換わります ',
          content:
            'ビューを反映すると編集中の集計軸/指標軸/フィルタの内容が書き換わります。 よろしいでしょうか？',
          cancelBtn: true,
          callback: () =>
            dispatch(
              dataExportActions.applyView(
                viewId,
                { type: reportType, channel },
                callback
              )
            ),
        })
      );
    },
    [channel, dispatch, reportType]
  );

  // Set data default by permission
  useEffect(() => {
    let store = defaultServiceStore;
    let action = 'setDataNormalReport';
    if (defaultReportType === REPORT_TYPE_AD_REPO) {
      store = REPORT_TYPE_AD_REPO;
      action = 'setDataAdrepoReport';
    }

    dispatchSetting({ type: 'setServiceStore', payload: store });
    dispatchSetting({ type: 'setReportType', payload: defaultReportType });
    dispatchSetting({ type: 'setReportChannel', payload: defaultChannel });
    dispatchSetting({
      type: action,
      payload: getDefaultReportByType(defaultReportType),
    });
  }, [
    defaultServiceStore,
    defaultReportType,
    defaultChannel,
    getDefaultReportByType,
  ]);

  // Reset data when change report type
  useEffect(() => {
    const dataReset = {};

    if (checkIsRegularReport(reportType)) {
      dispatchSetting({ type: 'setServiceStore', payload: SERVICE_STORE_S3 });
      dataReset[SUMMARY_MODE] = summaryMode[reportType];
      dataReset[DISPLAY_ITEMS] =
        displayItem[reportType]?.[channel]?.settingDefault || {};
    }

    if (checkIsDataExport(reportType)) {
      dataReset[DIMENSIONS] = dimension.type[reportType]?.default;
      dataReset[METRICS] = metric.type[reportType]?.default;
    }

    if (!isActionUpdate) {
      dataReset[REPORT_NAME] = defaultReportName[reportType];
    }

    dispatchSetting({ type: 'setDataNormalReport', payload: dataReset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchSetting, reportType]);

  // Call api
  useEffect(() => {
    if (reportId) {
      dispatch(dataExportActions.getDetail(reportId, handleResponse));
    } else {
      dispatch(dataExportActions.setDataDetail({}));
    }
  }, [dispatch, handleResponse, reportId]);

  // Clear state
  useEffect(() => {
    return () => {
      dispatchSetting({ type: 'clearState' });
      dispatch(dataExportActions.setDataDetail({}));
    };
  }, [dispatch]);

  if (isHideContentPage) return <></>;

  return (
    <SettingReportContext.Provider
      value={{ isActionUpdate, masterdata, settingReport, dispatchSetting }}
    >
      <DataSyncLoader isLoading={isLoading}>
        <div className="data-export-setting">
          <div className="data-export-setting__title-page">
            データエクスポート設定
          </div>
          <div
            className="data-export-setting__item data-export-setting__item"
            ref={serviceStoreRef}
          >
            <div className="data-export-setting__title">出力先</div>
            <ErrorTooltipWrapper
              isError={!!errorReport[SERVICE_STORE]}
              errorMess={errorReport[SERVICE_STORE]}
              ref={serviceStoreRef}
            >
              <div className="data-export-setting__content">
                {serviceStores.map((item) => (
                  <TagCard
                    variant="vertical"
                    icon={item.icon}
                    text={item.label}
                    actived={item.value === serviceStore}
                    disabled={item.disabled}
                    messageTooltipDisabled={item.messageTooltipDisabled}
                    onChange={handleChangeOutputType(item.value)}
                  />
                ))}
              </div>
            </ErrorTooltipWrapper>
          </div>
          {serviceStore === REPORT_TYPE_AD_REPO ? (
            <AdrepoReportSetting
              defaultReport={getDefaultReportByType(reportType)}
              onSearchCv={handleSearchCv}
              onSubmit={handleSubmit}
              onCancel={handleRedirectToListScreen}
            />
          ) : (
            <NormalReportSetting
              filterableList={filterableList}
              onApplyView={handleApplyView}
              onSubmit={handleSubmit}
              onCancel={handleRedirectToListScreen}
              isAllowRegisterRegularReport={isAllowRegisterRegularReport}
              isAllowRegisterDataExport={isAllowRegisterDataExport}
              statusSystemSync={statusSystemSync}
            />
          )}
          {serviceStore !== REPORT_TYPE_AD_REPO && isActionUpdate && (
            <div className="data-export-setting__note mt-20">
              <p className="color-primary">
                ※意図せず設定条件が外れる可能性があるため、他ユーザーIDで作成されたレポートは、許可なく上書き登録されないようご注意ください。
              </p>
              <p>
                ※ユーザーID毎に設定されている「権限（チャネル種別/共有ユーザー）」や「フィルタ」以外の設定ができません。
              </p>
            </div>
          )}
        </div>
      </DataSyncLoader>
    </SettingReportContext.Provider>
  );
}

SettingReportContainer.defaultProps = {
  reportId: '',
  isAllowedSettingAdrepoReport: false,
  isAllowRegisterAdrepoReport: false,
  isAllowedSettingNormalReport: false,
  isAllowRegisterNormalReport: false,
  isAllowedSettingDataExport: false,
  isAllowRegisterRegularReport: false,
  isAllowRegisterDataExport: false,
  statusSystemSync: false,
};

SettingReportContainer.propTypes = {
  reportId: string,
  isAllowedSettingAdrepoReport: bool,
  isAllowRegisterAdrepoReport: bool,
  isAllowedSettingNormalReport: bool,
  isAllowRegisterNormalReport: bool,
  isAllowedSettingDataExport: bool,
  isAllowRegisterRegularReport: bool,
  isAllowRegisterDataExport: bool,
  statusSystemSync: bool,
};

export default SettingReportContainer;
