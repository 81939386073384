import React, { useContext, useRef, useState, useEffect, useMemo } from 'react';
import { node, number } from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import {
  GridTableContext,
  GridTableItemContext,
} from 'views/organism/GridTable/GridTableContext';

const GridTableScrollbarWrapper = ({ children, widthTable }) => {
  const {
    dispatch,
    settings: { tableInfo },
  } = useContext(GridTableContext);
  const { isFreeze, headerRef, bodyRef, gridTemplateColumns } = useContext(
    GridTableItemContext
  );
  const scrollableWrapperRef = useRef(null);
  const scrollerRef = useRef(null);

  const [leftScroller, setLeftScroller] = useState(0);
  const [widthScroller, setWidthScroller] = useState(0);
  const [scrollActive, setScrollActive] = useState(false);

  const isScrolling = useMemo(() => leftScroller > 0, [leftScroller]);

  const scrollerClass = classNames({
    'grid-table__scroller': true,
    'grid-table__scroller--active': scrollActive,
  });

  const scrollableClass = classNames({
    'grid-table__scrollable-wrapper': true,
    'grid-table__scrollable-wrapper--display': widthScroller > 0,
  });

  useEffect(() => {
    dispatch({
      type: 'setInfoTable',
      payload: { isFreeze, info: { isScrolling, widthScroller } },
    });
  }, [dispatch, isFreeze, widthScroller, isScrolling]);

  useEffect(() => {
    if (!scrollableWrapperRef?.current) return;

    const scrollableWrapperEl = scrollableWrapperRef.current;
    dispatch({
      type: 'setHeightHorizontalScroll',
      payload: scrollableWrapperEl.offsetHeight,
    });
  }, [dispatch, widthTable]);

  useEffect(() => {
    if (!headerRef?.current || !bodyRef?.current) {
      return () => {};
    }

    const scrollableWrapperEl = scrollableWrapperRef.current;
    const scrollerEl = scrollerRef.current;
    const tableFloatEl = headerRef.current;
    const tableScrollEl = bodyRef.current;

    const { offsetWidth, scrollWidth } = tableScrollEl;
    const visibleRatio = offsetWidth / scrollWidth;
    const scrollerWidth = visibleRatio * offsetWidth;

    let contentPosition = 0;
    let scrollerBeingDragged = false;
    let normalizedPosition;

    const startDrag = (e) => {
      normalizedPosition = e.pageX;
      contentPosition = tableScrollEl.scrollLeft;
      scrollerBeingDragged = true;
    };

    const stopDrag = () => {
      scrollerBeingDragged = false;
    };

    const scrollBarScroll = (e) => {
      setScrollActive(scrollerBeingDragged);
      if (scrollerBeingDragged === true) {
        const mouseDifferential = e.pageX - normalizedPosition;
        const scrollEquivalent =
          mouseDifferential * (scrollWidth / offsetWidth);
        tableFloatEl.scrollLeft = contentPosition + scrollEquivalent;
      }
    };

    const moveScroller = (e) => {
      if (!e) return;

      window.requestAnimationFrame(() => {
        // Move Scroll bar to left offset
        const scrollPercentage = e.target.scrollLeft / e.target.scrollWidth;
        const leftPosition = scrollPercentage * e.target.offsetWidth;
        setLeftScroller(leftPosition);

        if (e.target.querySelector('[role="rowheader"]')) {
          tableScrollEl.scrollLeft = e.target.scrollLeft;
        } else {
          tableFloatEl.scrollLeft = e.target.scrollLeft;
        }
      });
    };

    const smoothScrollTo = (e) => {
      if (e && e.target.getAttribute('role') !== 'scrollbar') return;

      tableFloatEl.scrollLeft =
        e.offsetX > tableScrollEl.scrollLeft * visibleRatio
          ? (e.offsetX - scrollerWidth) / visibleRatio
          : e.offsetX / visibleRatio;
    };

    moveScroller({ target: tableScrollEl });
    setWidthScroller(scrollerWidth < offsetWidth ? scrollerWidth : 0);
    // attach related draggable listeners
    scrollerEl.addEventListener('mousedown', startDrag);
    window.addEventListener('mouseup', stopDrag);
    window.addEventListener('mousemove', throttle(scrollBarScroll, 100));
    scrollableWrapperEl.addEventListener(
      'click',
      throttle(smoothScrollTo, 100)
    );
    tableScrollEl.addEventListener('scroll', moveScroller);
    tableFloatEl.addEventListener('scroll', moveScroller);

    return () => {
      scrollerEl.removeEventListener('mousedown', startDrag);
      window.removeEventListener('mouseup', stopDrag);
      window.removeEventListener('mousemove', scrollBarScroll);
      tableScrollEl.removeEventListener('scroll', moveScroller);
      tableFloatEl.removeEventListener('scroll', moveScroller);
      scrollableWrapperEl.addEventListener('click', smoothScrollTo);
    };
  }, [headerRef, bodyRef, gridTemplateColumns, widthTable]);

  return (
    <>
      {children}
      <div
        ref={scrollableWrapperRef}
        role="scrollbar"
        aria-orientation="horizontal"
        aria-controls=""
        aria-valuenow=""
        className={scrollableClass}
        hidden={
          tableInfo.freeze.widthScroller === 0 &&
          tableInfo.main.widthScroller === 0
        }
      >
        <div
          ref={scrollerRef}
          className={scrollerClass}
          hidden={widthScroller <= 0}
          style={{
            width: widthScroller,
            willChange: 'transform',
            transform: `translateX(${leftScroller}px)`,
          }}
        />
      </div>
    </>
  );
};

GridTableScrollbarWrapper.propTypes = {
  children: node.isRequired,
  widthTable: number,
};

GridTableScrollbarWrapper.defaultProps = {
  widthTable: null,
};

export default React.memo(GridTableScrollbarWrapper);
