const getLatestErrorRequest = (state) =>
  state.commonState.LoggerState.errorsArrForModal;

const getActionLists = (state) => state.commonState.LoggerState.actionsArr;

const getStateShowError = (state) =>
  state.commonState.LoggerState.errorNotifyShow;

const getIrregularModalData = (state) =>
  state.commonState.LoggerState.confirmModalData;

const getIrregularModalShow = (state) =>
  state.commonState.LoggerState.irregularCaseModalShow;

export default {
  getLatestErrorRequest,
  getActionLists,
  getStateShowError,
  getIrregularModalData,
  getIrregularModalShow,
};
