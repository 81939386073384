import types from 'store/agency-management/types';
import helper from 'store/helper';
import { communicationStatus } from 'services/utils';
import { INTRODUCE } from 'domain/agency-management/consts';

const { IDLE, FAILED, SUCCEEDED } = communicationStatus;

const initialState = {
  data: {
    list: {
      items: [],
      metadata: { count: 0 },
      errors: [],
    },
    detail: {
      item: {},
      errors: [],
    },
    upload: {
      fileName: null,
      list: null,
      errors: [],
    },
    agentSync: {
      data: [],
      metadata: {},
    },
  },
  errors: [],
  apiStatus: {
    list: {
      status: IDLE,
    },
    detail: {
      status: IDLE,
    },
    upload: {
      status: IDLE,
    },
    agentSync: IDLE,
    openCompletedModal: false,
  },
  stateForAgentSyncModal: {
    isOpenAgentSyncModal: false,
    step: INTRODUCE,
    selectedUsers: [],
    allowSync: false,
    isSyncData: false,
  },
};

const mapperKeyStatus = {
  [types.GET_AGENT]: 'agentSync',
  [types.SYNC_AGENT]: 'agentSync',
  [types.DOWNLOAD_CSV_AGENT]: 'agentSync',
  [types.DOWNLOAD_CSV_AD_AGENT]: 'agentSync',
};

const setDataList = (state, action) => {
  const { data } = action.payload;

  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        items: data.data,
        metadata: data.metadata,
        errors: [],
      },
    },
    apiStatus: {
      ...state.apiStatus,
      list: {
        ...state.apiStatus.list,
        status: SUCCEEDED,
      },
    },
  };
};

const setErrorList = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        errors: action.payload.errors,
      },
    },
    apiStatus: {
      ...state.apiStatus,
      list: {
        ...state.apiStatus.list,
        status: FAILED,
      },
    },
  };
};

const setStatusList = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      list: {
        ...state.apiStatus.list,
        status: action.payload.status,
      },
    },
  };
};

const setStatusDetail = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    detail: {
      ...state.apiStatus.detail,
      status: action.payload.status,
    },
  },
});

const setStatusUpload = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    upload: {
      ...state.apiStatus.upload,
      upload: action.payload.status,
    },
  },
});

const setStatusOpenCompletedModal = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    openCompletedModal: action.payload.status,
  },
});

const setErrorDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      errors: action.payload.errors,
    },
  },
  apiStatus: {
    ...state.apiStatus,
    detail: {
      ...state.apiStatus.detail,
      status: FAILED,
    },
  },
});

const setDataDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      item: action.payload.data,
      errors: [],
    },
  },
});

const setStatus = (state, action) => {
  const { type, status } = action.payload;
  const key = mapperKeyStatus[type];
  if (!key) return { ...state };

  return {
    ...state,
    apiStatus: { ...state.apiStatus, [key]: status },
  };
};

const setErrors = (state, action) => {
  const { type, errors } = action.payload;
  const key = mapperKeyStatus[type];
  if (!key) return { ...state };

  return {
    ...state,
    errors,
    apiStatus: { ...state.apiStatus, [key]: FAILED },
  };
};

const setAgent = (state, action) => {
  const { data } = action.payload;

  return {
    ...state,
    data: { ...state.data, agentSync: data },
    apiStatus: { ...state.apiStatus, agentSync: SUCCEEDED },
  };
};

const resetAgent = (state) => {
  return {
    ...state,
    data: { ...state.data, agentSync: initialState.data.agentSync },
    errors: [],
    apiStatus: { ...state.apiStatus, agentSync: IDLE },
  };
};

const setStateAgentSyncModal = (state, action) => {
  return {
    ...state,
    stateForAgentSyncModal: {
      ...state.stateForAgentSyncModal,
      ...action.payload.data,
    },
  };
};

const resetStateAgentSyncModal = (state) => {
  return {
    ...state,
    stateForAgentSyncModal: { ...initialState.stateForAgentSyncModal },
  };
};

const AgencyManagementReducer = helper.createReducer(initialState, {
  [types.SET_DATA_LIST]: setDataList,
  [types.SET_ERROR_LIST]: setErrorList,
  [types.SET_STATUS_LIST]: setStatusList,
  [types.SET_STATUS_DETAIL]: setStatusDetail,
  [types.SET_ERROR_DETAIL]: setErrorDetail,
  [types.SET_DATA_DETAIL]: setDataDetail,
  [types.SET_STATUS]: setStatus,
  [types.SET_ERRORS]: setErrors,
  [types.SET_UPLOAD_STATUS]: setStatusUpload,
  [types.SET_AGENT]: setAgent,
  [types.RESET_AGENT]: resetAgent,
  [types.SET_STATE_AGENT_SYNC_MODAL]: setStateAgentSyncModal,
  [types.RESET_STATE_AGENT_SYNC_MODAL]: resetStateAgentSyncModal,
  [types.SET_STATUS_OPEN_COMPLETED_MODAL]: setStatusOpenCompletedModal,
});

export default AgencyManagementReducer;
