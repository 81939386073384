// View name is exist
const ERROR_CODE_VALUE_IS_EXISTS = 'VALUE_IS_EXISTS';
const ERROR_CODE_VIEW_TEMPLATE_MAX = 'VIEW_TEMPLATE_OVER_SETTING';
const ERROR_CODE_MAX_LENGTH = 'OVER_MAX_LENGTH';
const ERROR_CODE_NOT_EXIST = 'NOT_EXISTS_DB';
const ERROR_CODE_VALUE_NOT_ALLOWED = 'VALUE_NOT_ALLOWED';

export default {
  ERROR_CODE_VALUE_IS_EXISTS,
  ERROR_CODE_VIEW_TEMPLATE_MAX,
  ERROR_CODE_MAX_LENGTH,
  ERROR_CODE_NOT_EXIST,
  ERROR_CODE_VALUE_NOT_ALLOWED,
};
