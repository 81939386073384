import { useSelector } from 'react-redux';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import { getIsLoggedIn, getPermissions } from 'store/auth/selectors';
import { resolve } from 'domain/permissions/permissionTypes';

const useAuth = (requiredRoles, resolveType = 'anyOf') => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const ownerRoles = useSelector(getPermissions);

  // authorization
  let isAllowed = false;
  if (isLoggedIn) {
    switch (resolveType) {
      case 'permitted':
        isAllowed = true;
        break;
      case 'anyOf': {
        const allow = intersection(requiredRoles, ownerRoles);
        isAllowed = allow.length > 0;
        break;
      }
      case 'allBy':
        if (isArray(requiredRoles)) {
          isAllowed = resolve(
            { type: resolveType, permissions: ownerRoles },
            requiredRoles
          );
        }
        break;

      default:
        break;
    }
  }
  return { isLoggedIn, isAllowed };
};

export default useAuth;
