import {
  FIELD_IS_USING,
  MAX_OFFER,
  FIELD_ID,
  FIELD_NAME,
} from 'services/ltv/settings/offer/constants';
import { ErrorCode } from 'services/utils';
import findIndex from 'lodash/findIndex';

const allowFields = [FIELD_NAME];

/**
 * Validate field by name
 * @param  string itemName
 * @param bool isRequired
 * @param int maxLength
 * @returns {*}
 */
const validate = (value, isRequired, maxLength) => {
  const message = `オファーを入力してください。`;
  // Check field is empty
  if (isRequired) {
    if (Boolean(value) === false) {
      return { result: false, message };
    }
  }

  if (maxLength && value && value.length > maxLength) {
    return {
      result: false,
      message: `オファーは${maxLength}字以下で入力してください。`,
    };
  }

  // Check invalid character
  return { result: true, message: '' };
};

const getErrorMess = (errorCode) => {
  const rules = {
    [ErrorCode.OVER_USER]: `※ オファー登録上限（${MAX_OFFER}件）を超えています。`,
    [ErrorCode.NOT_EXISTS_DB]: '※ Offer is deleted',
  };
  return (
    rules?.[errorCode] ??
    'システムエラーが発生しました。何度も失敗する際はお手数ですがエビスサポートセンターまでご連絡ください'
  );
};

const getIdsString = (ids) => {
  const initialValue = '';
  return ids.reduce((prevVal, currVal, idx) => {
    return idx === 0 ? currVal[FIELD_ID] : `${prevVal},${currVal[FIELD_ID]}`;
  }, initialValue);
};

const isApiValidationError = ({ data, status }) => {
  if (status !== 400) {
    return false;
  }
  const { errors } = data;
  if (errors.length > 0) {
    return errors.some((error) => {
      return allowFields.includes(error.field);
    });
  }
  return false;
};

const getHeader = (selectedRows, isCheckedAll, sort) => {
  const headerData = [
    {
      text: '',
      name: 'check_all',
      isDimension: true,
      isEdit: true,
      sort: false,
      checked: selectedRows.length > 0,
      variant: selectedRows.length > 0 && !isCheckedAll ? 'mixed' : '',
    },
    {
      text: 'オファー',
      name: 'offer_name',
      isDimension: true,
      sort: true,
    },
  ];

  if (sort) {
    return headerData.map((item) => {
      const newObj = { ...item };
      if (newObj.name === sort.field) {
        newObj.sort = sort.direction;
      }
      return newObj;
    });
  }

  return headerData;
};

const addNewKeyForDataList = (list, selectedRows) => {
  return list.map((item) => {
    return {
      ...item,
      rowId: item[FIELD_ID],
      selected: selectedRows.includes(item[FIELD_ID]),
    };
  });
};

const findByOfferId = (id, items) => {
  if (id) {
    const index = findIndex(
      items,
      (item) => item[FIELD_ID].toString() === id.toString()
    );

    return index !== -1 ? items[index] : {};
  }

  return {};
};

const getItemsByUsingStatus = (selectedIds, items, isUsing) => {
  return items.filter((item) => {
    return (
      item[FIELD_IS_USING] === isUsing && selectedIds.includes(item[FIELD_ID])
    );
  });
};

export default {
  validate,
  getErrorMess,
  getIdsString,
  isApiValidationError,
  getHeader,
  addNewKeyForDataList,
  findByOfferId,
  getItemsByUsingStatus,
};
