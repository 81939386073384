import { AD_MANAGEMENT_MODE } from 'domain/ad-management/consts';
import { func } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import adManagementActions from 'store/ad-management/actions';
import adManagementSelectors from 'store/ad-management/selectors';
import { commonSelectors } from 'store/common';
import { settingsSelectors } from 'store/settings';
import DownloadFiles from 'views/molecules/commonbar/components/DownloadFiles';

function AdManagementDownloadFileContainer(props) {
  const { toggleActiveFunc } = props;
  const screenId = useSelector(settingsSelectors.getPage);
  const downloadCsvDisabled = useSelector(
    commonSelectors.isCsvDownloadDisabled(screenId)
  );
  const mode = useSelector(adManagementSelectors.getTab);
  const downloadConfigs = useSelector(commonSelectors.getDownloadConfigs);
  const dispatch = useDispatch();
  const downloadCsv = () => dispatch(adManagementActions.downloadCsv());

  return (
    <DownloadFiles
      downloadCsvDisabled={downloadCsvDisabled}
      downloadConfigs={downloadConfigs}
      toggleActiveFunc={toggleActiveFunc}
      variant="sub-icon-settings"
      disabled={[AD_MANAGEMENT_MODE.DELETED].includes(mode)}
      downloadCsv={downloadCsv}
    />
  );
}

AdManagementDownloadFileContainer.propTypes = {
  toggleActiveFunc: func.isRequired,
};

export default AdManagementDownloadFileContainer;
