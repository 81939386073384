import * as FIELD from 'domain/fields';

const PRIMARY_CHART_METRICS = [
  FIELD.IMPRESSION,
  FIELD.CNT_ACCESS,
  FIELD.CTR,
  FIELD.CNT_SESSION,
  FIELD.CNT_PV,
  FIELD.PER_PAGE_VIEW,
  FIELD.CNT_RETURN,
  FIELD.PER_RETURN,
  FIELD.TOTAL_STAY_TIME,
  FIELD.TOTAL_STAY_TIME_AVG,
  FIELD.TOTAL_LATENCY_TIME,
  FIELD.LATENCY_TIME_AVG,
  FIELD.CV_TOTAL,
  FIELD.CVR_TOTAL,
  FIELD.INDIRECT_TOTAL,
  FIELD.CNT_FIRST,
  FIELD.AMOUNT,
  FIELD.RCV,
  FIELD.RAMOUNT,
  FIELD.COST,
  FIELD.CPC,
  FIELD.CPA,
  FIELD.TCPA,
  FIELD.ROAS,
  FIELD.TROAS,
];

const SECONDARY_CHART_METRICS = [
  FIELD.IMPRESSION,
  FIELD.CNT_ACCESS,
  FIELD.CNT_SESSION,
  FIELD.CNT_PV,
  FIELD.CNT_RETURN,
  FIELD.TOTAL_STAY_TIME,
  FIELD.TOTAL_LATENCY_TIME,
  FIELD.CV_TOTAL,
  FIELD.CVR_TOTAL,
  FIELD.INDIRECT_TOTAL,
  FIELD.CNT_FIRST,
  FIELD.RCV,
  FIELD.AMOUNT,
  FIELD.RAMOUNT,
  FIELD.COST,
];

const PERIOD_TYPE = {
  DAY: 'day',
  MONTH: 'month',
  DOW: 'dow',
  HOUR: 'hour',
};

const PERIOD_TYPE_TO_API_MAP = {
  [PERIOD_TYPE.DAY]: 'daily',
  [PERIOD_TYPE.MONTH]: 'monthly',
  [PERIOD_TYPE.DOW]: 'day_of_week',
  [PERIOD_TYPE.HOUR]: 'hourly',
};

const AVAILABLE_CHART_DIMENSIONS = [
  FIELD.CHANNEL_ACCESS_TYPE,
  FIELD.CATEGORY,
  FIELD.AD_GROUP1,
  FIELD.AD_GROUP2,
  FIELD.SYNC_CATEGORY,
  FIELD.MEDIA_SIDE_CAMPAIGN,
  FIELD.MEDIA_SIDE_GROUP,
  FIELD.MEDIA_SIDE_AD_NAME,
  FIELD.PERIOD,
];

const AVAILABLE_TABLE_DIMENSIONS = [];

const AVAILABLE_TABLE_METRICS = [
  FIELD.IMPRESSION,
  FIELD.CNT_ACCESS,
  FIELD.CTR,
  FIELD.CNT_SESSION,
  FIELD.CNT_PV,
  FIELD.PER_PAGE_VIEW,
  FIELD.CNT_RETURN,
  FIELD.PER_RETURN,
  FIELD.TOTAL_STAY_TIME,
  FIELD.TOTAL_STAY_TIME_AVG,
  FIELD.TOTAL_LATENCY_TIME,
  FIELD.LATENCY_TIME_AVG,
  FIELD.CNT_DIRECT_CV,
  FIELD.CV_TOTAL,
  FIELD.CNT_DIRECT_RATE_CV,
  FIELD.CVR_TOTAL,
  FIELD.CPA,
  FIELD.CNT_INDIRECT_2,
  FIELD.CNT_INDIRECT_3,
  FIELD.CNT_INDIRECT_4,
  FIELD.CNT_INDIRECT_5,
  FIELD.CNT_INDIRECT_OTHER,
  FIELD.INDIRECT_TOTAL,
  FIELD.CNT_FIRST,
  FIELD.DIRECT_RATE,
  FIELD.RCV,
  FIELD.AMOUNT,
  FIELD.RAMOUNT,
  FIELD.COST,
  FIELD.CPC,
  FIELD.TCPA,
  FIELD.ROAS,
  FIELD.TROAS,
];

const PERIOD_TYPE_HOUR_EXCLUDED_FIELDS = [
  FIELD.IMPRESSION,
  FIELD.CTR,
  FIELD.CPA,
  FIELD.COST,
  FIELD.CPC,
  FIELD.TCPA,
  FIELD.ROAS,
  FIELD.TROAS,
];

const DIMENSIONS_TO_KEY_VALUES = [
  {
    field: FIELD.CHANNEL_ACCESS_TYPE,
    key: FIELD.CHANNEL,
    value: FIELD.CHANNEL,
  },
  {
    field: FIELD.CATEGORY,
    key: FIELD.CATEGORY_ID,
    value: FIELD.CATEGORY,
  },
  {
    field: FIELD.AD_GROUP1,
    key: FIELD.AD_GROUP1_ID,
    value: FIELD.AD_GROUP1_NAME,
  },
  {
    field: FIELD.AD_GROUP2,
    key: FIELD.AD_GROUP2_ID,
    value: FIELD.AD_GROUP2_NAME,
  },
  {
    field: FIELD.SYNC_CATEGORY,
    key: FIELD.SYNC_CATEGORY_ID,
    value: FIELD.SYNC_CATEGORY,
  },
  {
    field: FIELD.MEDIA_SIDE_CAMPAIGN,
    key: FIELD.MEDIA_SIDE_CAMPAIGN_ID,
    value: FIELD.MEDIA_SIDE_CAMPAIGN_NAME,
  },
  {
    field: FIELD.MEDIA_SIDE_GROUP,
    key: FIELD.MEDIA_SIDE_GROUP_ID,
    value: FIELD.MEDIA_SIDE_GROUP_NAME,
  },
  {
    field: FIELD.MEDIA_SIDE_AD_ID,
    key: FIELD.MEDIA_SIDE_AD_ID,
    value: FIELD.MEDIA_SIDE_AD_ID,
  },
  {
    field: FIELD.MEDIA_SIDE_AD_NAME,
    key: FIELD.MEDIA_SIDE_AD_NAME,
    value: FIELD.MEDIA_SIDE_AD_NAME,
  },
  {
    field: FIELD.PERIOD,
    key: FIELD.PERIOD,
    value: FIELD.PERIOD,
  },
  {
    field: FIELD.PRIORITY_AXIS,
    key: FIELD.PRIORITY_AXIS,
    value: FIELD.PRIORITY_AXIS,
  },
];

const MEMO_CATEGORY = {
  ONLINE: 'ONLINE',
  OFLLINE: 'OFFLINE',
  WEBSITE: 'WEB_RENO',
  ANALYSIS: 'ANALYZE_INSPEC',
  OTHERS: 'OTHER',
};

const MEMO_CATEGORIES_PATTERNS = [
  { key: MEMO_CATEGORY.ONLINE, color: '#F8CF92' },
  { key: MEMO_CATEGORY.OFLLINE, color: '#F9CACF' },
  { key: MEMO_CATEGORY.WEBSITE, color: '#C7D5E2' },
  { key: MEMO_CATEGORY.ANALYSIS, color: '#C5DBBD' },
  { key: MEMO_CATEGORY.OTHERS, color: '#D1C2DF' },
];

const TABLE_PERIOD_DATE_FORMAT = 'YYYY/MM/DD (dd)';

const TABLE_PERIOD_MONTH_FORMAT = 'YYYY/MM';

const MEMO_DATE_FORMAT = 'YYYY-MM-DD';

const DEFAULT_SORT_CHART = { field: FIELD.PERIOD, asc: true };

const DEFAULT_SORT_TABLE = [{ field: FIELD.PERIOD, asc: true }];

const MEMO_HIDDEN_PERIOD_TYPES = [
  PERIOD_TYPE.MONTH,
  PERIOD_TYPE.HOUR,
  PERIOD_TYPE.DOW,
];

const MEMO_ERRORS = {
  MEMO_CONTENT_TOO_LONG: {
    code: 'MEMO_CONTENT_TOO_LONG',
    msg: 'MEMO_CONTENT_TOO_LONG',
  },
  DURATION_OVER: {
    code: 'DURATION_OVER',
    msg: 'MEMO_INVALID_DURATION',
  },
  MEMO_IS_OVER_MAX: {
    code: 'MEMO_IS_OVER_MAX',
    msg: 'MEMO_OVER_MAX',
  },
};

const MEMO_API_ERRORS = [
  MEMO_ERRORS.DURATION_OVER,
  MEMO_ERRORS.MEMO_IS_OVER_MAX,
];

const MEMO_API_FIELD = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  CATEGORY: 'category',
  CONTENT: 'content',
  ID: 'id',
};

const MEMO_MAX_CONTENT_LENGTH = 300;

const TOOLTIP_MODE = {
  MONO: 1,
  DUAL: 2,
  COMPARED: 3,
};

const APPEND_PERIOD_ON_COMPARED_TOOLTIP = [PERIOD_TYPE.DOW, PERIOD_TYPE.HOUR];

const MEMO_MAX_TOOLTIP_CONTENT_LENGTH = 50;

export {
  PRIMARY_CHART_METRICS,
  SECONDARY_CHART_METRICS,
  PERIOD_TYPE,
  PERIOD_TYPE_TO_API_MAP,
  MEMO_DATE_FORMAT,
  TABLE_PERIOD_DATE_FORMAT,
  TABLE_PERIOD_MONTH_FORMAT,
  AVAILABLE_CHART_DIMENSIONS,
  AVAILABLE_TABLE_DIMENSIONS,
  DIMENSIONS_TO_KEY_VALUES,
  AVAILABLE_TABLE_METRICS,
  PERIOD_TYPE_HOUR_EXCLUDED_FIELDS,
  MEMO_CATEGORY,
  MEMO_CATEGORIES_PATTERNS,
  DEFAULT_SORT_CHART,
  DEFAULT_SORT_TABLE,
  MEMO_HIDDEN_PERIOD_TYPES,
  MEMO_API_ERRORS,
  MEMO_API_FIELD,
  MEMO_MAX_CONTENT_LENGTH,
  MEMO_ERRORS,
  TOOLTIP_MODE,
  APPEND_PERIOD_ON_COMPARED_TOOLTIP,
  MEMO_MAX_TOOLTIP_CONTENT_LENGTH,
};
