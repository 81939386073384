import {
  DIMENSIONS_TO_KEY_VALUES,
  ITEM_KEY_RESOLUTION,
} from 'domain/category-analyze/consts';
import {
  CALENDAR_DAY_FORMAT,
  CV_FIELD_PREFIX,
  CV_METRICS,
  CVR_FIELD_PREFIX,
  subHeaderDefinitions,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_MEDIA_SYNC,
  PRIORITY_AXIS_BOTH,
  CROSS_DEVICE_METRICS,
} from 'domain/consts';
import priorityAxisService from 'services/common/priorityAxisService';
import * as FIELD from 'domain/fields';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { containsByAllKeys, equalsAllKeys } from 'services/utils';
import {
  createFieldsFromSettingItems,
  formatDateRange,
  createFieldsFromConversions,
  createCrossDeviceLinkege,
} from 'domain/utils';
import {
  DISPLAY_GROUP_AGGREGATION_AXIS,
  CROSSDEVICE_DIFF_COMPARISON,
} from 'domain/settings/display-items';

const createConversionMetrics = (metric, conversions) =>
  conversions
    .sort((cv1, cv2) => cv1.order - cv2.order)
    .map((conversion) => {
      if (metric === FIELD.CNT_DIRECT_CV) {
        return `${CV_FIELD_PREFIX}${conversion.id}`;
      }
      return `${CVR_FIELD_PREFIX}${conversion.id}`;
    });

const getCvFieldByKey = (key) => {
  if (key.startsWith(FIELD.CNT_DIRECT_CV)) {
    return 'cv';
  }
  if (key.startsWith(FIELD.CNT_DIRECT_RATE_CV)) {
    return 'cvr';
  }
  return null;
};

const rowToCells = (row, headers, selectedRows) => {
  return {
    dimensionCells: headers
      .filter((header) => header.isDimension)
      .flatMap((header) => {
        if (isNil(header.children)) {
          return [...[header]];
        }
        return [...header.children];
      })
      .map((header) => {
        return {
          idKey: header.name,
          nameKey: ITEM_KEY_RESOLUTION[header.name].name,
          idValue: row[ITEM_KEY_RESOLUTION[header.name].id],
          value: row[ITEM_KEY_RESOLUTION[header.name].name],
          isDimension: header.isDimension,
          title: header.text,
          priorityAxis: row.priority_axis,
        };
      }),
    metricCells: headers
      .filter((header) => !header.isDimension)
      .flatMap((header) => {
        if (isNil(header.children)) {
          return [...[header]];
        }
        return [...header.children];
      })
      .map((header) => {
        return {
          idKey: header.name,
          nameKey: header.name,
          idValue: row[header.name],
          value: row[header.name],
          isDimension: header.isDimension,
          title: header.text,
          ebisViewPattern: row[FIELD.EBIS_VIEW_PATTERN],
          syncViewPattern: row[FIELD.SYNC_VIEW_PATTERN],
        };
      }),
    dimensionKey: row.dimensionKey,
    selected: containsByAllKeys(selectedRows, row.dimensionKey),
  };
};

const buildDisplayRows = ({ list, selectedRows, headers }) => {
  return list.map((row) => {
    return rowToCells(row, headers, selectedRows);
  });
};

const sortToNumber = (sorts, key) =>
  sorts.reduce((acc, { field, asc }) => {
    if (field === key) {
      return asc ? 1 : -1;
    }
    return acc;
  }, 0);

function buildDimensionHeaders(dimensions, sorts, channel) {
  const displayDimensions =
    channel.toLowerCase() === 'ad'
      ? dimensions
      : dimensions.concat(FIELD.CHANNEL_ACCESS_TYPE);
  const dimensionColumns = DIMENSIONS_TO_KEY_VALUES.filter((d) =>
    displayDimensions.includes(d.field)
  ).map((dimension) => ({
    idKey: dimension.field,
    nameKey: dimension.value,
    sort: sortToNumber(sorts, dimension.field),
    sortKey: dimension.field,
    isDimension: true,
    sortable: true,
  }));
  return dimensionColumns;
}

function buildMetricColumns(metrics, conversions, sorts) {
  const metricColumns = metrics
    .map((metric) => {
      if (CV_METRICS.includes(metric)) {
        return {
          metric,
          isConversion: true,
        };
      }
      return {
        metric,
        columns: [metric],
        isConversion: false,
      };
    })
    .map(({ metric, columns, isConversion }) => {
      if (isConversion) {
        return createConversionMetrics(metric, conversions);
      }
      return columns;
    })
    .flatMap((columns) => [...columns])
    .map((column) => {
      return {
        idKey: column,
        sort: sortToNumber(sorts, column),
        isDimension: false,
        sortKey: column,
        isConversion:
          column.startsWith(CV_FIELD_PREFIX) ||
          column.startsWith(CVR_FIELD_PREFIX),
        conversionField: getCvFieldByKey(column),
        sortable: true,
      };
    });
  return metricColumns;
}

const buildSortString = (sorts, field) => {
  if (field === 'period') return false;
  return sorts
    .filter((sort) => sort.field === field)
    .reduce((acc, sort) => (sort.asc ? 'asc' : 'desc'), 'none');
};

const buildDisplayHeaders = ({
  items,
  settingItems,
  sorts,
  conversions,
  priorityAxis,
  compared = false,
}) => {
  const buildHeaderSortString = (field) => buildSortString(sorts, field);

  const isSubHeaderField = (field) =>
    subHeaderDefinitions.some((def) => def.children.includes(field.key));

  const isIncludeByPriorityAxis = (field) => {
    const bothAxisList = priorityAxisService.getSelectableAxisList(
      PRIORITY_AXIS_BOTH
    );
    if (bothAxisList.includes(field.key)) {
      return true;
    }
    if (priorityAxis === PRIORITY_AXIS_EBIS) {
      return (
        !priorityAxisService
          .getSelectableAxisList(PRIORITY_AXIS_MEDIA_SYNC)
          .includes(field.key) &&
        (field.ebisGroup !== DISPLAY_GROUP_AGGREGATION_AXIS ||
          priorityAxisService
            .getSelectableAxisList(PRIORITY_AXIS_EBIS)
            .includes(field.key))
      );
    }
    if (priorityAxis === PRIORITY_AXIS_MEDIA_SYNC) {
      return (
        !priorityAxisService
          .getSelectableAxisList(PRIORITY_AXIS_EBIS)
          .includes(field.key) &&
        (field.syncGroup !== DISPLAY_GROUP_AGGREGATION_AXIS ||
          priorityAxisService
            .getSelectableAxisList(PRIORITY_AXIS_MEDIA_SYNC)
            .includes(field.key))
      );
    }
    return false;
  };

  const fields = createFieldsFromSettingItems(
    items,
    settingItems,
    priorityAxis
  );

  const subHeaders = subHeaderDefinitions
    .filter((def) => fields.some((field) => def.children.includes(field.key)))
    .map((def) => {
      return {
        text: def.text,
        name: def.name,
        isDimension: false,
        children: def.children
          .filter((subHeader) =>
            fields.some((field) => field.key === subHeader)
          )
          .map((subHeader) => {
            const field = fields.find((fld) => fld.key === subHeader);
            return {
              text: field.titleTable || field.title,
              name: field.key,
              isDimension: false,
              sort: buildHeaderSortString(field.key),
            };
          }),
      };
    });
  const selectedCvFields = fields
    .filter((field) => CV_METRICS.includes(field.key))
    .map((field) => field.key);

  const cvHeaders = createFieldsFromConversions(conversions, selectedCvFields);

  // 優先軸にそぐわない項目は除外
  const headerWithoutUnmatchedAxis = fields.filter((field) =>
    isIncludeByPriorityAxis(field)
  );

  // CV_METRICSの項目は一つだけ含める。
  const headersWithoutCVs = headerWithoutUnmatchedAxis.filter(
    (field, index) => {
      return (
        !CV_METRICS.includes(field.key) ||
        (index > 0 &&
          headerWithoutUnmatchedAxis
            .slice(0, index)
            .every((prevField) => !CV_METRICS.includes(prevField.key)))
      );
    }
  );

  const headers = headersWithoutCVs
    .map((field, index) => {
      if (CV_METRICS.includes(field.key)) {
        // Use cvHeaders
        return cvHeaders.map((cvHeader) => cvHeader);
      }
      // create crossDeviceHeaders
      if (
        CROSS_DEVICE_METRICS.includes(field.key) &&
        CROSSDEVICE_DIFF_COMPARISON in settingItems &&
        settingItems.crossdevice_diff_comparison
      ) {
        const crossDeviceHeaders = createCrossDeviceLinkege(field.key);
        if (!crossDeviceHeaders) {
          return crossDeviceHeaders;
        }
        return [crossDeviceHeaders];
      }

      if (!isSubHeaderField(field)) {
        return [
          {
            text: field.title,
            name: field.key,
            isDimension:
              field.getGroup(priorityAxis) === DISPLAY_GROUP_AGGREGATION_AXIS,
            sort: buildHeaderSortString(field.key),
            children: null,
          },
        ];
      }

      const currentSubHeader = subHeaders.find((subHeader) =>
        subHeader.children.some((child) => child.name === field.key)
      );

      if (index > 0 && isSubHeaderField(headersWithoutCVs[index - 1])) {
        const prevSubHeader = subHeaders.find((subHeader) =>
          subHeader.children.some(
            (child) => child.name === headersWithoutCVs[index - 1].key
          )
        );
        if (prevSubHeader.name === currentSubHeader.name) return null;
      }

      return [currentSubHeader];
    })
    .filter((header) => !isNil(header))
    .flatMap((headerArr) => [...headerArr]);

  if (compared) {
    const firstMetricIndex = headers.findIndex((field) => !field.isDimension);
    headers.splice(firstMetricIndex, 0, {
      text: '期間',
      name: 'period',
      isDimension: false,
      sort: false,
    });
  }

  return headers;
};

const buildDisplaySum = ({ sumObject, headers, period }) => {
  if (isEmpty(sumObject)) {
    return [];
  }
  return headers
    .flatMap((header) => {
      if (isNil(header.children)) {
        return [...[header]];
      }
      return [...header.children];
    })
    .map((header) => {
      if (header.isDimension) {
        return {
          isDimension: true,
        };
      }

      let value;
      if (header.name === 'period') {
        value = formatDateRange(period.start, period.end);
      } else {
        value = sumObject[header.name];
      }
      return {
        key: header.name,
        value,
      };
    });
};

const buildDisplayComparedRows = ({
  list,
  selectedRows,
  headers,
  comparedList,
  period,
  comparedPeriod,
}) => {
  const comparedRows = comparedList.map((row) =>
    rowToCells(row, headers, selectedRows)
  );
  return list
    .map((row) => rowToCells(row, headers, selectedRows))
    .map((row) => {
      const { dimensionKey } = row;
      const comparedRow = comparedRows.find((cRow) =>
        equalsAllKeys(cRow.dimensionKey, dimensionKey)
      );
      return {
        ...row,
        dimensionCells: row.dimensionCells.map((cell) => {
          const comparedCell = comparedRow?.dimensionCells.find(
            (cCell) => cell.idKey === cCell.idKey
          );
          const comparedValue = comparedCell?.value;
          return {
            ...cell,
            comparedValue,
          };
        }),
        metricCells: row.metricCells.map((cell) => {
          if (cell.idKey === 'period') {
            return {
              ...cell,
              value: `${period.start.format(
                CALENDAR_DAY_FORMAT
              )} ～ ${period.end.format(CALENDAR_DAY_FORMAT)}`,
              comparedValue: `${comparedPeriod.start.format(
                CALENDAR_DAY_FORMAT
              )} ～ ${comparedPeriod.end.format(CALENDAR_DAY_FORMAT)}`,
            };
          }
          const comparedCell = comparedRow?.metricCells.find(
            (cCell) => cell.idKey === cCell.idKey
          );
          const comparedValue = comparedCell?.value;
          const comparedEbisViewPattern = comparedCell?.ebisViewPattern;
          const comparedSyncViewPattern = comparedCell?.syncViewPattern;
          return {
            ...cell,
            comparedValue,
            comparedEbisViewPattern,
            comparedSyncViewPattern,
          };
        }),
      };
    });
};

const buildDisplayComparedHeaders = ({
  dimensions,
  metrics,
  sorts,
  conversions,
  channel,
}) => {
  const dimensionColumns = buildDimensionHeaders(dimensions, sorts, channel);
  const metricColumns = buildMetricColumns(metrics, conversions, sorts);
  const periodColumn = {
    idKey: 'period',
    sort: null,
    sortable: false,
  };
  return dimensionColumns.concat(periodColumn).concat(metricColumns);
};

const buildDisplayComparedSum = ({
  sumObject,
  comparedSumObject,
  headers,
  period,
  comparedPeriod,
}) => {
  if (isEmpty(sumObject)) {
    return [];
  }
  return headers
    .flatMap((header) => {
      if (isNil(header.children)) {
        return [...[header]];
      }
      return [...header.children];
    })
    .map((header) => {
      if (header.isDimension) {
        return {
          isDimension: true,
          value: null,
          comparedValue: null,
        };
      }

      let value;
      if (header.name === 'period') {
        value = formatDateRange(period.start, period.end);
      } else {
        value = sumObject[header.name];
      }

      let comparedValue;
      if (header.name === 'period') {
        comparedValue = formatDateRange(
          comparedPeriod.start,
          comparedPeriod.end
        );
      } else {
        comparedValue = comparedSumObject[header.name];
      }

      return {
        key: header.name,
        value,
        comparedValue,
      };
    });
};

const updateSelectedRows = ({ displayRows, selectedRows }) => {
  return displayRows.map((row) => {
    return {
      ...row,
      selected: containsByAllKeys(selectedRows, row.dimensionKey),
    };
  });
};

const updateSelectedRow = ({ displayRows, row, selected }) => {
  return displayRows.map((displayRow) => {
    const match = equalsAllKeys(row, displayRow.dimensionKey);
    if (match) {
      return {
        ...displayRow,
        selected,
      };
    }
    return displayRow;
  });
};

const updateDisplayHeadersSorts = (displayHeaders, sorts) => {
  return displayHeaders.map((header) => {
    const sortedHeader = {
      ...header,
      sort: buildSortString(sorts, header.name),
    };
    if (!isNil(sortedHeader.children)) {
      sortedHeader.children = sortedHeader.children.map((child) => ({
        ...child,
        sort: buildSortString(sorts, child.name),
      }));
    }
    return sortedHeader;
  });
};

export default {
  buildDisplayRows,
  buildDisplayHeaders,
  buildDisplaySum,
  buildDisplayComparedRows,
  buildDisplayComparedHeaders,
  buildDisplayComparedSum,
  updateSelectedRows,
  updateSelectedRow,
  updateDisplayHeadersSorts,
};
