import types from 'store/logger/types';
import constant from 'store/logger/constant';

const logAction = (action, level = constant.LOG_LEVEL_INFO) => ({
  type: types.LOG_ACTION,
  payload: action,
  level,
});

const logUIError = (error, level = constant.LOG_LEVEL_FATAL) => ({
  type: types.LOG_UI_ERROR,
  payload: error,
  scope: constant.SCOPE_DONT_SHOW,
  level,
});

const logDownloadCsvError = () => ({
  type: types.LOG_DOWNLOAD_CSV_ERROR,
  scope: constant.SCOPE_DONT_SHOW,
  payload: {
    title: 'ダウンロードに失敗しました',
    content:
      '再度実行してもエラーとなる場合は件数を減らして出力してください。</br>件数を減らす場合は出力期間を短くするかフィルタ条件を設定してください。</br>出力件数は表の上の表示件数で確認することが可能です。',
    callback: () => {},
  },
});

const sendReport = () => ({
  type: types.SEND_REPORT,
});

const logError = (action, level = constant.LOG_LEVEL_WARN) => ({
  type: types.LOG_ERROR,
  payload: action,
  level,
});

const closeError = () => ({
  type: types.CLOSE_ERROR,
});

const logConfirmModal = ({
  title,
  content,
  cancelBtn = false,
  cancelText = '',
  confirmText,
  confirmBtnType,
  callback = () => {},
}) => ({
  type: types.LOG_CONFIRM,
  payload: {
    title,
    content,
    cancelBtn,
    cancelText,
    confirmText,
    confirmBtnType,
    callback,
  },
});

const closeConfirmModal = () => ({
  type: types.CLOSE_CONFIRM,
});

export default {
  logAction,
  sendReport,
  logUIError,
  logDownloadCsvError,
  logError,
  closeError,
  logConfirmModal,
  closeConfirmModal,
};
