import {
  put,
  takeEvery,
  call,
  select,
  takeLatest,
  all,
  fork,
  debounce,
} from 'redux-saga/effects';
import { UPDATE_BOOKMARK_DEBOUNCE_LENGTH } from 'services/consts';
import {
  clonePeriod,
  communicationStatus,
  containsByAllKeys,
} from 'services/utils';
import logging from 'domain/logging';
import { COMPARE_PERIOD } from 'services/routes/constants';
import loggerConstants from 'store/logger/constant';
import actions from 'store/period-analyze/actions';
import types from 'store/period-analyze/types';

import HttpStatus from 'domain/httpUtils';
import periodAnalysisService from 'services/period-analyze/periodAnalysisService';
import paSelectors from 'store/period-analyze/selectors';
import commonSelectors from 'store/common/selectors';
import * as FIELD from 'domain/fields';
import sharedSagas from 'store/sharedSagas';
import chartService from 'services/period-analyze/chartService';
import tableService from 'services/period-analyze/tableService';
import settingsSelector from 'store/settings/selectors';
import filterSelector from 'store/filters/selectors';
import displayItemSelectors from 'store/display-items/selectors';
import { DEFAULT_SELECTED_CATEGORIES } from 'domain/consts';
import masterDataSelectors from 'store/master-data/selectors';
import { DisplayItemsSelectors } from 'store/display-items';
import masterDataActions from '../master-data/actions';

const { LOADING, SUCCEEDED } = communicationStatus;

function* getStatesForChartAPIRequest() {
  const {
    dimensionsSelector,
    periodSelector,
    comparePeriodSelector,
    periodTypeSelector,
    usePeriodAxisOnlySelector,
    getChartMetrics,
  } = paSelectors;
  const { primary, secondary } = yield select(getChartMetrics);
  return {
    dimensions: yield select(dimensionsSelector),
    period: yield select(periodSelector),
    comparedPeriod: yield select(comparePeriodSelector),
    channel: yield select(settingsSelector.getTab),
    periodType: yield select(periodTypeSelector),
    usePeriodAxisOnly: yield select(usePeriodAxisOnlySelector),
    filters: yield select(filterSelector.getForApi),
    metrics: [...new Set(primary.concat(secondary))],
    axis: yield select(DisplayItemsSelectors.getDisplayItemPriorityAxis),
  };
}

/**
 *
 * @return {{comparedPeriod, comparedSorts, period, periodType, pagination, channel, metrics, comparedPagination, sorts}}
 */
function* getStatesForTableAPIRequest() {
  const {
    periodTypeSelector,
    metricsSelector,
    periodSelector,
    comparePeriodSelector,
    sortsSelector,
    comparedSortsSelector,
    paginationSelector,
    comparedPaginationSelector,
    dimensionsSelector,
  } = paSelectors;
  return {
    period: yield select(periodSelector),
    periodType: yield select(periodTypeSelector),
    comparedPeriod: yield select(comparePeriodSelector),
    dimensions: yield select(dimensionsSelector),
    metrics: yield select(metricsSelector),
    sorts: yield select(sortsSelector),
    comparedSorts: yield select(comparedSortsSelector),
    pagination: yield select(paginationSelector),
    comparedPagination: yield select(comparedPaginationSelector),
    channel: yield select(settingsSelector.getTab),
    filters: yield select(filterSelector.getForApi),
    conversions: yield select(masterDataSelectors.conversionsSelector),
    axis: yield select(DisplayItemsSelectors.getDisplayItemPriorityAxis),
  };
}

function* reuseOrCreateCategories(options, usePeriodAxisOnly, categoriesList) {
  let selectedCategories;
  let visibleList;
  if (options.shouldResetCategories) {
    selectedCategories = usePeriodAxisOnly
      ? [{ [FIELD.PERIOD]: FIELD.PERIOD }]
      : categoriesList
          .slice(0, DEFAULT_SELECTED_CATEGORIES)
          .map((category) => category.dimensionKey);
    visibleList = selectedCategories;
  } else {
    // Find existing categories and filter if not exist in new data
    const allCategoriesKeys = categoriesList.map(
      (category) => category.dimensionKey
    );
    const existingSelectedCategories = yield select(
      paSelectors.selectedCategoriesSelector
    );
    selectedCategories = existingSelectedCategories.filter((existingCategory) =>
      containsByAllKeys(allCategoriesKeys, existingCategory)
    );

    // Find existing visible list and filter if not exist in new data
    const existingVisibleList = yield select(paSelectors.visibleListSelector);
    visibleList = existingVisibleList.filter((existingVisibleCategory) =>
      containsByAllKeys(allCategoriesKeys, existingVisibleCategory)
    );
  }
  return {
    selectedCategories,
    visibleList,
  };
}

function* getChartReport(options = { shouldResetCategories: true }) {
  yield put(actions.setChartStatus(LOADING));
  const apiOptions = yield getStatesForChartAPIRequest();
  const {
    period: originalPeriod,
    dimensions,
    channel,
    comparedPeriod: originalComparedPeriod,
    periodType,
    usePeriodAxisOnly,
    filters,
    metrics,
    axis: priorityAxis,
  } = apiOptions;
  if (dimensions.length <= 0) return;

  const period = clonePeriod(originalPeriod);
  const comparedPeriod = clonePeriod(originalComparedPeriod);
  const res = yield sharedSagas.getChartReportByAPICall(
    {
      start: period.start.clone(),
      end: period.end.clone(),
      dimensions,
      channel: channel.toLowerCase(),
      periodType,
      usePeriodAxisOnly,
      filters,
      metrics,
      axis: priorityAxis,
    },
    periodAnalysisService.getChartData
  );

  const rawList = res.data.data.detail;

  const categoriesList = periodAnalysisService.buildCategoriesList(
    rawList,
    dimensions,
    usePeriodAxisOnly
  );

  const { selectedCategories, visibleList } = yield* reuseOrCreateCategories(
    options,
    usePeriodAxisOnly,
    categoriesList
  );

  const axis = yield select(paSelectors.axisSelector);
  const memoList = yield select(paSelectors.memoListSelector);
  const memoEnabled = yield select(paSelectors.memoEnabledSelector);

  const displayData = chartService.createChartSeriesList({
    dimensions,
    period,
    periodType,
    comparedPeriod,
    comparedList: [],
    memoList,
    memoEnabled,
    axis,
    list: categoriesList,
    selectedCategories,
    visibleList,
  });

  yield put(
    actions.updateChartData({
      list: categoriesList,
      selectedCategories,
      displayData,
      visibleList,
      // categories,
    })
  );
  yield put(actions.setChartStatus(SUCCEEDED));
}

function* getComparedChartReport(options = { shouldResetCategories: true }) {
  yield put(actions.setChartStatus(LOADING));
  const apiOptions = yield getStatesForChartAPIRequest();
  const {
    period: originalPeriod,
    dimensions,
    channel,
    comparedPeriod: originalComparedPeriod,
    periodType,
    usePeriodAxisOnly,
    filters,
    metrics,
    axis: priorityAxis,
  } = apiOptions;
  if (dimensions.length <= 0) return;

  const period = clonePeriod(originalPeriod);
  const comparedPeriod = clonePeriod(originalComparedPeriod);

  const [res, comparedRes] = yield all([
    call(periodAnalysisService.getChartData, {
      start: period.start.clone(),
      end: period.end.clone(),
      dimensions,
      channel: channel.toLowerCase(),
      periodType,
      usePeriodAxisOnly,
      filters,
      metrics,
      axis: priorityAxis,
    }),
    call(periodAnalysisService.getChartData, {
      start: comparedPeriod.start.clone(),
      end: comparedPeriod.end.clone(),
      dimensions,
      channel: apiOptions.channel.toLowerCase(),
      periodType,
      usePeriodAxisOnly,
      filters,
      metrics,
      axis: priorityAxis,
    }),
  ]);
  const rawList = res.data.data.detail;

  const categoriesList = periodAnalysisService.buildCategoriesList(
    rawList,
    dimensions,
    usePeriodAxisOnly
  );

  const { selectedCategories, visibleList } = yield* reuseOrCreateCategories(
    options,
    usePeriodAxisOnly,
    categoriesList
  );

  const rawComparedList = comparedRes.data.data.detail;
  const comparedCategoriesLIst = periodAnalysisService.buildCategoriesList(
    rawComparedList,
    dimensions,
    usePeriodAxisOnly
  );

  const axis = yield select(paSelectors.axisSelector);
  const selectedRows = yield select(paSelectors.selectedRowsSelector);
  const memoList = yield select(paSelectors.memoListSelector);
  const memoEnabled = yield select(paSelectors.memoEnabledSelector);

  const displayData = chartService.createChartSeriesList({
    list: categoriesList,
    comparedList: comparedCategoriesLIst,
    axis,
    selectedRows,
    selectedCategories,
    visibleList,
    memoEnabled,
    memoList,
    comparedPeriod,
    periodType,
    period,
    dimensions,
  });

  yield put(
    actions.updateComparedChartData({
      list: categoriesList,
      comparedList: comparedCategoriesLIst,
      selectedCategories,
      displayData,
      visibleList,
    })
  );

  yield put(actions.setChartStatus(SUCCEEDED));
}

function createDisplayData(
  list,
  dimensions,
  metrics,
  conversions,
  sum,
  sorts,
  selectedRows,
  periodType,
  settingItems,
  items,
  priorityAxis
) {
  const displayHeaders = tableService.buildDisplayHeaders({
    items,
    settingItems,
    conversions,
    sorts,
    priorityAxis,
  });

  const displayRows = tableService.buildDisplayRows({
    list,
    selectedRows,
    headers: displayHeaders,
    periodType,
  });

  const displaySum = tableService.buildDisplaySum({
    sumObject: sum,
    headers: displayHeaders,
  });

  return {
    displayRows,
    displayHeaders,
    displaySum,
  };
}

const updateDisplayData = ({
  list,
  selectedRows,
  displayHeaders,
  sum,
  period,
  periodType,
}) => {
  const displayRows = tableService.buildDisplayRows({
    list,
    selectedRows,
    headers: displayHeaders,
    periodType,
  });

  const displaySum = tableService.buildDisplaySum({
    sumObject: sum,
    headers: displayHeaders,
    period,
  });
  return {
    displayRows,
    displaySum,
  };
};

function* getTableReportByAPICall({
  period,
  dimensions,
  metrics,
  sorts,
  pagination,
  channel,
  periodType,
  filters,
  axis,
}) {
  return yield sharedSagas.getTableReportByAPICall(
    {
      start: period.start,
      end: period.end,
      dimensions,
      metrics,
      sorts,
      pagination,
      channel: channel.toLowerCase(),
      periodType,
      filters,
      axis,
    },
    periodAnalysisService.getTableData
  );
}

function* initTableReport() {
  const apiOptions = yield getStatesForTableAPIRequest();
  const {
    dimensions,
    metrics,
    sorts,
    period,
    pagination,
    channel,
    periodType,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (apiOptions.dimensions.length <= 0) return;

  yield put(actions.setTableStatus(LOADING));

  if (!apiOptions.conversions || apiOptions.conversions.length <= 0) {
    yield put(masterDataActions.fetchConversions());
  }

  const { sum, rawList, count } = yield getTableReportByAPICall({
    dimensions,
    metrics,
    sorts,
    period,
    pagination,
    channel,
    periodType,
    filters,
    axis: priorityAxis,
  });

  const list = rawList.map((item) => ({
    ...item,
    dimensionKey: periodAnalysisService.createDimensionKeyObjectForTable(
      item,
      dimensions
    ),
  }));
  const conversions = yield select(paSelectors.conversionsSelector);
  const selectedRows = [];

  const items = yield select(displayItemSelectors.getUserPermittedItems);
  const settingItems = yield select(displayItemSelectors.getSettings);

  const { displayRows, displayHeaders, displaySum } = createDisplayData(
    list,
    dimensions,
    metrics,
    conversions,
    sum,
    sorts,
    selectedRows,
    periodType,
    settingItems,
    items,
    priorityAxis
  );

  yield put(
    actions.updateTableData({
      list,
      sum,
      displayRows,
      displayHeaders,
      displaySum,
      count,
      selectedRows,
    })
  );
  yield put(actions.setTableStatus(SUCCEEDED));
}

function* initComparedTableReport() {
  const apiOptions = yield getStatesForTableAPIRequest();
  const {
    comparedPeriod: period,
    dimensions,
    metrics,
    sorts,
    comparedPagination: pagination,
    channel,
    periodType,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (dimensions.length <= 0) return;

  yield put(actions.setTableStatus(LOADING));

  if (!apiOptions.conversions || apiOptions.conversions.length <= 0) {
    yield put(masterDataActions.fetchConversions());
  }

  const { sum, rawList, count } = yield getTableReportByAPICall({
    dimensions,
    metrics,
    sorts,
    period,
    pagination,
    channel,
    periodType,
    filters,
    axis: priorityAxis,
  });

  const list = rawList.map((item) => ({
    ...item,
    dimensionKey: periodAnalysisService.createDimensionKeyObjectForTable(
      item,
      dimensions
    ),
  }));
  const conversions = yield select(paSelectors.conversionsSelector);
  const selectedRows = [];

  const items = yield select(displayItemSelectors.getUserPermittedItems);
  const settingItems = yield select(displayItemSelectors.getSettings);

  const { displayRows, displayHeaders, displaySum } = createDisplayData(
    list,
    dimensions,
    metrics,
    conversions,
    sum,
    sorts,
    selectedRows,
    periodType,
    settingItems,
    items,
    priorityAxis
  );

  yield put(
    actions.updateComparedTableData({
      comparedList: list,
      comparedSum: sum,
      displayRows,
      displayHeaders,
      displaySum,
      count,
      selectedRows,
    })
  );

  yield put(actions.setComparedTableStatus(SUCCEEDED));
}

function* refreshTableDataNonCompared() {
  const apiOptions = yield getStatesForTableAPIRequest();
  const {
    dimensions,
    metrics,
    sorts,
    period,
    pagination,
    channel,
    periodType,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (apiOptions.dimensions.length <= 0) return;
  yield put(actions.setTableStatus(LOADING));
  const { sum, rawList, count } = yield getTableReportByAPICall({
    dimensions,
    metrics,
    sorts,
    period,
    pagination,
    channel,
    periodType,
    filters,
    axis: priorityAxis,
  });

  const list = rawList.map((item) => ({
    ...item,
    dimensionKey: periodAnalysisService.createDimensionKeyObjectForTable(
      item,
      dimensions
    ),
  }));
  const selectedRows = yield select(paSelectors.selectedRowsSelector);

  const displayHeaders = yield select(paSelectors.tableDisplayHeadersSelector);

  const { displayRows, displaySum } = updateDisplayData({
    period,
    list,
    selectedRows,
    displayHeaders,
    sum,
    periodType,
  });

  yield put(
    actions.updateTableData({
      list,
      sum,
      displayRows,
      displayHeaders,
      displaySum,
      count,
    })
  );
  yield put(actions.setTableStatus(SUCCEEDED));
}

function* refreshTableDataCompared() {
  const apiOptions = yield getStatesForTableAPIRequest();
  const {
    dimensions,
    metrics,
    sorts,
    comparedPeriod: period,
    comparedPagination: pagination,
    channel,
    periodType,
    filters,
    axis: priorityAxis,
  } = apiOptions;
  if (apiOptions.dimensions.length <= 0) return;
  yield put(actions.setTableStatus(LOADING));
  const { sum, rawList, count } = yield getTableReportByAPICall({
    dimensions,
    metrics,
    sorts,
    period,
    pagination,
    channel,
    periodType,
    filters,
    axis: priorityAxis,
  });

  const list = rawList.map((item) => ({
    ...item,
    dimensionKey: periodAnalysisService.createDimensionKeyObjectForTable(
      item,
      dimensions
    ),
  }));
  const selectedRows = yield select(paSelectors.selectedRowsSelector);
  const displayHeaders = yield select(paSelectors.tableDisplayHeadersSelector);

  const { displayRows, displaySum } = updateDisplayData({
    period,
    list,
    selectedRows,
    displayHeaders,
    sum,
    periodType,
  });

  yield put(
    actions.updateComparedTableData({
      comparedList: list,
      comparedSum: sum,
      displayRows,
      displaySum,
      count,
    })
  );

  yield put(actions.setComparedTableStatus(SUCCEEDED));
}

function* updateMemoSeriesForChart() {
  const memoEnabled = yield select(paSelectors.memoEnabledSelector);
  const memoList = yield select(paSelectors.memoListSelector);
  const displayList = yield select(paSelectors.chartDisplayDataSelector);
  const periodRange = yield select(paSelectors.periodRangeSelector);
  const updatedDisplayList = chartService.updateMemoListForChart({
    periodRange,
    memoEnabled,
    memoList,
    displayRows: displayList,
  });
  yield put(actions.updateChartDisplayMemo(updatedDisplayList));
}

function* fetchMemo() {
  const period = yield select(commonSelectors.periodSelector);
  const memoList = yield call(periodAnalysisService.loadMemo, {
    start: period.start,
    end: period.end,
  });
  const memoListWithIndex = periodAnalysisService.buildMemoListWithDisplayIndex(
    memoList
  );
  return memoListWithIndex;
}

function* loadMemo() {
  const memoList = yield fetchMemo();
  yield put(actions.loadMemoSuccess(memoList));

  yield fork(updateMemoSeriesForChart);
}

function* loadMemoIfNecessary() {
  let memoList = yield select(paSelectors.memoListSelector);
  const memoEnabled = yield select(paSelectors.memoEnabledSelector);
  const memoHidden = yield select(paSelectors.memoHiddenSelector);

  if (memoEnabled && !memoHidden && (!memoList || memoList.length <= 0)) {
    memoList = yield fetchMemo();
    yield put(actions.loadMemoSuccess(memoList));
  }

  yield fork(updateMemoSeriesForChart);
}

function* createMemo(action) {
  yield put(actions.setMemoErrors([]));
  yield put(actions.setMemoSubmitting(true));
  const { category, content, memoStart: start, memoEnd: end } = action.payload;
  const response = yield call(periodAnalysisService.createMemo, {
    category,
    content,
    start,
    end,
  });
  const { status, data } = response;
  if (status === HttpStatus.OK) {
    yield put(actions.setHideModal());
    yield fork(loadMemo);
  } else if (status === HttpStatus.BAD_REQUEST) {
    // TODO handle create error
    yield put(actions.setMemoErrors(data.errors));
  }
  yield put(actions.setMemoSubmitting(false));
}

function* deleteMemo(action) {
  yield put(actions.setHideDeleteModal());
  yield put(actions.setHideModal());
  yield put(actions.setMemoErrors([]));
  yield put(actions.setMemoSubmitting(true));
  const memoId = action.payload;
  const response = yield call(periodAnalysisService.deleteMemo, memoId);
  if (periodAnalysisService.isMemoNotExistError(response)) {
    yield put(actions.showMemoErrorModal());
  }
  yield fork(loadMemo);
  yield put(actions.setMemoSubmitting(false));
}

function* updateMemo(action) {
  yield put(actions.setMemoErrors([]));
  yield put(actions.setMemoSubmitting(true));
  const memo = action.payload;
  const response = yield call(periodAnalysisService.updateMemo, memo);
  if (periodAnalysisService.isMemoNotExistError(response)) {
    yield put(actions.showMemoErrorModal());
  } else {
    const { status, data } = response;
    if (status === HttpStatus.OK) {
      yield put(actions.setHideModal());
      yield fork(loadMemo);
    } else if (status === HttpStatus.BAD_REQUEST) {
      yield put(actions.setMemoErrors(data.errors));
    }
  }

  yield put(actions.setMemoSubmitting(false));
}

function* refreshTableData() {
  yield fork(refreshTableDataNonCompared);
  const comparedPeriod = yield select(paSelectors.comparePeriodSelector);
  if (comparedPeriod.enabled) {
    yield fork(refreshTableDataCompared);
  }
}

// Bookmark
function* getBookmark() {
  const bookmark = yield call(periodAnalysisService.getBookmark);
  const comparedPeriod = yield select(paSelectors.comparePeriodSelector);
  const shouldApplyMetrics = periodAnalysisService.shouldApplyBookmarkMetrics(
    bookmark,
    comparedPeriod.enabled
  );
  yield put(actions.applyBookmark(bookmark, shouldApplyMetrics));
}

function* updateBookmark(action) {
  const request = action.payload;
  yield call(periodAnalysisService.updateBookmark, request);
}

function* updateShowChart(action) {
  const show = action.payload;
  yield call(periodAnalysisService.updateShowChart, show);
}

function* updateCategories() {
  const list = yield select(paSelectors.chartListSelector);
  const comparedList = yield select(paSelectors.chartComparedListSelector);
  const visibleList = yield select(paSelectors.visibleListSelector);
  const comparedPeriod = yield select(paSelectors.comparePeriodSelector);
  const selectedCategories = yield select(
    paSelectors.selectedCategoriesSelector
  );
  const memoList = yield select(paSelectors.memoListSelector);
  const memoEnabled = yield select(paSelectors.memoEnabledSelector);
  const period = yield select(paSelectors.periodSelector);
  const periodType = yield select(paSelectors.periodTypeSelector);
  const axis = yield select(paSelectors.axisSelector);
  const dimensions = yield select(paSelectors.dimensionsSelector);

  const displayData = chartService.createChartSeriesList({
    list,
    visibleList,
    comparedPeriod,
    comparedList,
    selectedCategories,
    memoList,
    memoEnabled,
    period,
    periodType,
    axis,
    dimensions,
  });

  yield put(actions.updateChartDisplayData(displayData));
}

function* errorHandler(err) {
  const page = yield select(settingsSelector.getPage);
  if (page !== COMPARE_PERIOD) {
    logging.error('Period Analyze unmount error', err);
    return;
  }

  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];

  yield put(actions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

function* handleGetChartActions(action) {
  yield sharedSagas.waitingRedirect();

  const page = yield select(settingsSelector.getPage);
  if (page !== COMPARE_PERIOD) return;

  switch (action.type) {
    case types.GET_CHART_REPORT:
      yield getChartReport();
      break;
    case types.GET_COMPARED_CHART_REPORT:
      yield getComparedChartReport();
      break;
    case types.UPDATE_PERIOD_TYPE: {
      const comparedEnabled = (yield select(paSelectors.comparePeriodSelector))
        .enabled;
      if (comparedEnabled) {
        yield getComparedChartReport({ shouldResetCategories: false });
      } else {
        yield getChartReport({ shouldResetCategories: false });
      }
      break;
    }
    default:
      break;
  }
}

function* handleGetComparedTableActions() {
  yield sharedSagas.waitingRedirect();
  const page = yield select(settingsSelector.getPage);
  if (page !== COMPARE_PERIOD) return;

  yield initComparedTableReport();
}

function* handleGetTableActions() {
  yield sharedSagas.waitingRedirect();
  const page = yield select(settingsSelector.getPage);
  if (page !== COMPARE_PERIOD) return;

  yield initTableReport();
}

export default function* saga() {
  const {
    GET_CHART_REPORT,
    GET_COMPARED_CHART_REPORT,
    GET_TABLE_REPORT,
    GET_COMPARED_TABLE_REPORT,
    UPDATE_BOOKMARK,
    GET_BOOKMARK,
    UPDATE_SORT,
    SET_SHOW_CHART,
    UPDATE_CATEGORIES,
    LOAD_MEMO,
    TOGGLE_MEMO,
    DELETE_MEMO,
    UPDATE_MEMO,
    REFRESH_TABLE_CURRENT,
    REFRESH_TABLE_COMPARED,
  } = types;

  yield takeEvery(types.CREATE_MEMO, createMemo);

  yield takeLatest(LOAD_MEMO, sharedSagas.safe(errorHandler, loadMemo));
  yield takeLatest(
    TOGGLE_MEMO,
    sharedSagas.safe(errorHandler, loadMemoIfNecessary)
  );
  yield takeEvery(DELETE_MEMO, sharedSagas.safe(errorHandler, deleteMemo));
  yield takeEvery(UPDATE_MEMO, updateMemo);

  yield takeLatest(
    [GET_CHART_REPORT, GET_COMPARED_CHART_REPORT],
    sharedSagas.safe(
      errorHandler,
      sharedSagas.skipIfNotReady(handleGetChartActions)
    )
  );

  yield takeLatest(
    [GET_TABLE_REPORT],
    sharedSagas.safe(
      errorHandler,
      sharedSagas.skipIfNotReady(handleGetTableActions)
    )
  );
  yield takeLatest(
    [GET_COMPARED_TABLE_REPORT],
    sharedSagas.safe(
      errorHandler,
      sharedSagas.skipIfNotReady(handleGetComparedTableActions)
    )
  );

  yield debounce(
    UPDATE_BOOKMARK_DEBOUNCE_LENGTH,
    UPDATE_BOOKMARK,
    sharedSagas.safe(errorHandler, updateBookmark)
  );
  yield takeLatest(GET_BOOKMARK, sharedSagas.safe(errorHandler, getBookmark));

  yield takeLatest(
    REFRESH_TABLE_CURRENT,
    sharedSagas.safe(errorHandler, refreshTableDataNonCompared)
  );
  yield takeLatest(
    REFRESH_TABLE_COMPARED,
    sharedSagas.safe(errorHandler, refreshTableDataCompared)
  );

  yield takeLatest(UPDATE_SORT, refreshTableData);

  yield debounce(
    UPDATE_BOOKMARK_DEBOUNCE_LENGTH,
    SET_SHOW_CHART,
    updateShowChart
  );

  yield takeLatest(UPDATE_CATEGORIES, updateCategories);
}
