export const SCHEDULE = 'schedule';
export const SCHEDULE_WEEKDAYS = 'schedule_weekdays';
export const EMAIL_TITLE = 'email_title';
export const INCLUDE_ALL = 'include_all';
export const VIEW_INDICATOR_ALL = 'view_indicator_all';
export const MEDIA_IDS = 'media_ids';
export const VIEW_INDICATOR_MEDIA = 'view_indicator_media';
export const USERS = 'users';

export const SCHEDULE_NOT_SEND_MAIL = '0';
export const SCHEDULE_SEND_MAIL = '1';
export const MAX_SELECTED_DASHBOARD_KPI = 8;
export const MAX_EMAIL_TITLE_LENGTH = 500;
export const NO_EXIST_EMAIL = -1;
export const PICKUP_IDS = 'pickup_ids';

export const FIELD_REQUEST_API = {
  [SCHEDULE]: 'schedule',
  [SCHEDULE_WEEKDAYS]: 'schedule_weekdays',
  [EMAIL_TITLE]: 'email_title',
  [INCLUDE_ALL]: 'include_all',
  [VIEW_INDICATOR_ALL]: 'view_indicator_all',
  [MEDIA_IDS]: 'media_ids',
  [VIEW_INDICATOR_MEDIA]: 'view_indicator_media',
  [USERS]: 'users',
};
