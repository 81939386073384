import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LANDING_PAGE_ANALYZE } from 'services/routes/constants';

const LandingPageAnalyzeApiFactory = () => {
  const url = pages[LANDING_PAGE_ANALYZE].endpoint;

  return {
    url,
    fetchReport: async (data) => {
      return Api.post(url, data);
    },
  };
};

const LandingPageAnalyzeApi = LandingPageAnalyzeApiFactory();
export default LandingPageAnalyzeApi;
