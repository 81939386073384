import { createSelector } from 'reselect';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { communicationStatus, LOADING_FINISH_STATUSES } from 'services/utils';
import { PAGES_SETTINGS, TAB_AD } from 'services/consts';
import { MEASUREMENT_SITE_PAGE_MANAGEMENT } from 'services/routes/constants';

const getTab = (state) => {
  const { tab } = state.settings.report;
  return tab;
};

const getMode = (state) => {
  const { mode } = state.settings.report;
  return mode;
};

const getPage = (state) => {
  const { page } = state.settings.report;
  return page;
};

const isPageSettings = (state) => {
  const { page } = state.settings.report;
  return PAGES_SETTINGS.includes(page);
};

const getCurrentTab = createSelector(
  [getTab, getMode, isPageSettings, getPage],
  (tab, mode, isSettings, page) => {
    if ([MEASUREMENT_SITE_PAGE_MANAGEMENT].includes(page)) {
      return TAB_AD;
    }
    return isSettings ? mode : tab;
  }
);

const isAppliedCustomView = (state) => {
  const { current, changed } = state.settings.view.applied;
  return current !== null && changed === false;
};

/* Setting cross: Category/Cost allocation/Period analysis
other screen have owner setting */
const getFuncId = (state) => {
  const { page } = state.settings.report;
  return DisplayItemsService.getFuncIdByPageId(page);
};

const getLoadingByStatus = (selectors, ignoreStatus = []) => (state) => {
  return selectors
    .map((selector) => selector(state))
    .some(
      (status) =>
        ![...LOADING_FINISH_STATUSES, ...ignoreStatus].includes(status)
    );
};

const getErrorByStatus = (selectors) => (state) => {
  return selectors
    .map((selector) => selector(state))
    .some((status) => status === communicationStatus.FAILED);
};

export default {
  getTab,
  getMode,
  getPage,
  isPageSettings,
  getCurrentTab,
  getFuncId,
  isAppliedCustomView,
  getLoadingByStatus,
  getErrorByStatus,
};
