import React from 'react';
import StatusComponent from 'views/pages/search-console-setting/components/StatusComponent';
import PauseComponent from 'views/pages/search-console-setting/components/PauseComponent';
import { getErrorMessageByCode } from 'services/utils';
import { chain, isArray, isEmpty } from 'lodash';
import * as messageError from 'services/validations/messageErrorByCode';
import {
  MEDIUM_TITLE,
  GOOGLE,
  NO_EXIST_EMAIL,
  CREDENTIAL_OBJ,
  STEP_BASIC_CONFIGURATION,
  MAX_LIMIT_SEARCH,
} from 'domain/search-console-setting/constants';
import {
  EMAIL_NOTIFICATION,
  MEDIUM_ID,
  REFRESH_TOKEN,
  SEND_MAIL_USERS,
  SITE_URL,
} from 'domain/fields';
import scSettingRules from 'services/validations/scSettingRules';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import { IconMultiplePerson } from 'views/atoms/icon/SvgIcon';
import parse from 'tld-extract';

export const displaySCDomainWithoutPrefix = (propertyUrl) => {
  if (propertyUrl.startsWith('sc-domain:')) {
    return propertyUrl.replace('sc-domain:', '');
  }
  return propertyUrl;
};

export const formatDataList = (data, onChangeStatus) => {
  return data.map((item) => ({
    ...item,
    medium_id: MEDIUM_TITLE[item.medium_id],
    pause_flag: (
      <PauseComponent
        onChangeStatus={onChangeStatus}
        id={item.id}
        defaultValue={item.pause_flag}
      />
    ),
    site_url: displaySCDomainWithoutPrefix(item.site_url),
    status: <StatusComponent status={item.status} />,
    isAllowSelectRow: true,
  }));
};

export const validateDataSettings = (values, labels, rules) => {
  if (isEmpty(values) || isEmpty(rules)) return {};

  return chain(values)
    .mapValues((value, field) => ({
      label: labels[field],
      value: value || null,
    }))
    .mapValues((value, field) => {
      if (isEmpty(rules[field])) return [];

      const rulesByField = [...rules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();
};

export const validateSCSettingData = (data) => {
  const { values, labels } = Object.keys(data).reduce(
    (acc, field) => {
      const item = { ...data[field] };

      return {
        values: { ...acc.values, [field]: item.value },
        labels: { ...acc.labels, [field]: item.label },
      };
    },
    { values: {}, labels: {} }
  );

  const error = validateDataSettings(values, labels, scSettingRules);

  return Object.keys(error).reduce((acc, field) => {
    return { ...acc, [field]: { ...acc[field], error: error[field] } };
  }, data);
};

/**
 * Check data has errors
 * @param {object} data
 * @return object
 */
export const checkDataSettingError = (
  data,
  currentStep,
  isValidate = false
) => {
  return Object.keys(data).reduce(
    (acc, field) => {
      const item = data[field];
      if (currentStep !== item.step && isValidate) return acc;

      const hasError =
        acc.hasError || !isEmpty(item.error) || item.error === true;

      return {
        hasError,
        stepError: hasError
          ? Math.min(acc.stepError, item.step)
          : acc.stepError,
      };
    },
    { hasError: false, stepError: STEP_BASIC_CONFIGURATION }
  );
};

/**
 * Format data when error
 */
export const getSCSettingDataError = (form, errors, params = {}) => {
  if (isEmpty(errors) || !isArray(errors)) return form;

  return errors.reduce((acc, error) => {
    const [field] = error.field.split('.');
    const item = { ...acc[field] };

    if (isEmpty(item)) return acc;

    const { label } = item;
    const message = getErrorMessageByCode(error, messageError, {
      ...params,
      label,
    });

    switch (field) {
      default:
        item.error = message;
        break;
    }

    return { ...acc, [field]: item };
  }, form);
};

const getCredentialSetting = (data) => {
  let credentialSetting = {};

  const {
    [REFRESH_TOKEN]: { value: refreshToken },
  } = data;

  credentialSetting = {
    [REFRESH_TOKEN]: refreshToken,
  };

  return credentialSetting;
};

export const getSCSettingDataRequest = (
  data = {},
  hasUserEditPermission = false
) => {
  const {
    [EMAIL_NOTIFICATION]: { value: sendMailFlag },
    [SITE_URL]: { value: siteUrl },
    [SEND_MAIL_USERS]: { value: sendMailUsers },
  } = data;

  const credentialSetting = getCredentialSetting(data);

  const scSetting = {
    [SITE_URL]: siteUrl,
    [EMAIL_NOTIFICATION]: sendMailFlag,
    [SEND_MAIL_USERS]:
      hasUserEditPermission && sendMailFlag ? sendMailUsers : [],
  };

  return {
    ...scSetting,
    ...credentialSetting,
  };
};

export const formatDisplayEmailInfo = (emailInfo = []) => {
  if (emailInfo === NO_EXIST_EMAIL) {
    return '';
  }
  if (emailInfo?.length === 1) {
    return emailInfo[0].email;
  }
  if (emailInfo?.length > 1) {
    const email = emailInfo.reduce(
      (obj, item, index) => {
        const result = obj;
        if (index === 0) {
          result.displayEmail = item.email;
        } else {
          result.tooltipEmail = (
            <>
              {result.tooltipEmail} {isEmpty(result.tooltipEmail) ? '' : <br />}
              {item.email}
            </>
          );
        }
        return result;
      },
      {
        displayEmail: '',
        tooltipEmail: '',
      }
    );
    return (
      <div className="multiple-emails">
        <span>{email.displayEmail}</span>
        <DragonBall
          svg={<IconMultiplePerson />}
          variant="modal-white"
          placement="bottom-end"
        >
          {email.tooltipEmail}
        </DragonBall>
      </div>
    );
  }

  return '';
};

export const formatUsersData = (users = []) => {
  if (isEmpty(users)) return [];

  const icon = <i className="fas fa-check" />;
  return users.map((user) => {
    const { agent_flag: agentFlag } = user;

    let email = user.email || '';
    const emails = email.split(',').map((item) => ({ email: item }));
    if (emails.length > 1) {
      email = formatDisplayEmailInfo(emails);
    }

    return {
      ...user,
      email,
      agent: agentFlag ? icon : null,
      rowId: user.user_id,
    };
  });
};

export const updateSCSettingForm = (mediumId = GOOGLE, dataField = {}) => {
  return {
    ...dataField,
    ...CREDENTIAL_OBJ[mediumId],
  };
};

/**
 * Format SC Detail Setting data
 * @return object
 */
export const formatSCSettingDetailData = (
  dataField = {},
  detailSetting = {}
) => {
  const { [MEDIUM_ID]: mediumId } = detailSetting;
  dataField = updateSCSettingForm(mediumId, dataField);

  return Object.keys(dataField).reduce((obj, field) => {
    return {
      ...obj,
      [field]: {
        ...dataField[field],
        value: detailSetting[field],
      },
    };
  }, {});
};

const isIPv4Address = (domain) => {
  const ipv4Regex = /^(http:\/\/|https:\/\/)?(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})\/?$/;

  return ipv4Regex.test(domain);
};

const splitDomain = (array) => {
  return array.map((url) => {
    const { domain, subdomain } = parse(url);
    return `${subdomain ? `${subdomain}.` : ''}${domain}`;
  });
};

export const matchingUrlProperties = (
  ebisPropertiesList,
  googlePropertiesList = []
) => {
  const ebisHostNameList = ebisPropertiesList?.split(',') || [];

  const googleListExceptIp = googlePropertiesList.filter(
    (item) => !isIPv4Address(item.siteUrl)
  );

  const googlePropertiesListName = googleListExceptIp.map(
    (item) => item.siteUrl
  );

  // Compare matching URLs in google based on ebis list
  const sCWithoutPrefix = splitDomain(googlePropertiesListName);

  const matchingUrls = [
    ...new Set(sCWithoutPrefix.filter((url) => ebisHostNameList.includes(url))),
  ];

  const result = googleListExceptIp.filter((item) =>
    matchingUrls.includes(parse(item.siteUrl).domain)
  );

  // Remove duplicates from matchingURLs
  return result;
};

export const filterSearchList = (
  list = [],
  searchText = '',
  limit = MAX_LIMIT_SEARCH
) => {
  if (searchText.length >= 1) {
    let count = 0;
    const filteredOptions = list.filter((item) => {
      const targetSearch = item.siteUrl
        .toLowerCase()
        .includes(searchText.toLowerCase());
      if (count < limit && targetSearch) {
        count += 1;
        return true;
      }
      return false;
    });
    return filteredOptions;
  }
  return list.slice(0, limit);
};

export default {
  formatDataList,
  checkDataSettingError,
  getSCSettingDataError,
  getSCSettingDataRequest,
  formatUsersData,
  matchingUrlProperties,
  filterSearchList,
};
