import { put, takeLatest, call } from 'redux-saga/effects';
import actions from 'store/alerts/actions';
import types from 'store/alerts/types';
import AlertsApi from 'services/api/common/AlertsApi';

function* startFetchData() {
  try {
    const { data } = yield call(AlertsApi.getAlerts);
    yield put(actions.fetchAlertsSuccess(data.data));
  } catch (error) {
    yield put(actions.fetchAlertsError(error));
  }
}

export default function* alertsSaga() {
  yield takeLatest(types.FETCH_ALERTS_START, startFetchData);
}
