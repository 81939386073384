import types from 'store/user-management/types';

const start = (type, isFetchList) => ({
  type,
  payload: { isFetchList },
});

const succeeded = (type, data, callback = () => {}) => ({
  type,
  payload: data,
  callback,
});

const failed = (type, { error }) => ({
  type,
  payload: error,
  scope: error.scope,
});

const handleShowHideModal = (data) => ({
  type: types.SHOW_HIDE_MODAL,
  payload: data,
});

const requestStart = (isFetchList = false) =>
  start(types.REQUEST_START, isFetchList);
const requestFailed = (error) => failed(types.REQUEST_FAILED, error);
const fetchedList = (data) => succeeded(types.FETCH_LIST_SUCCEEDED, data);
const fetchList = (page) => succeeded(types.FETCH_LIST, { page });
const create = (data, callback) => succeeded(types.CREATE, data, callback);
const update = (data, callback) => succeeded(types.UPDATE, data, callback);
const remove = (id) => succeeded(types.DELETE, id);
const changeSelectedItem = (id) => succeeded(types.CHANGE_SELECTED_ITEM, id);
const fetchListByCode = () => succeeded(types.FETCH_LIST_BY_CODE);

export default {
  requestStart,
  requestFailed,
  fetchedList,
  fetch,
  fetchList,
  create,
  update,
  remove,
  handleShowHideModal,
  changeSelectedItem,
  fetchListByCode,
};
