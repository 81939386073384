import Api from 'services/api/Api';
import { USER_ENDPOINT } from 'services/routes/constants';

const DisplayItemsApiFactory = () => {
  const url = `${USER_ENDPOINT}/display-settings/display-items`;
  return {
    fetch: async (funcId) => Api.get(`${url}/${funcId}`),
    // Delete and create
    create: async (funcId, data) => Api.post(`${url}/${funcId}`, data),
    normalize: (items, data) => {
      const settingItems = {};
      const values = Object.values(data).reduce(
        (next, current) => [...current, ...next],
        []
      );
      Object.keys(items).map((key) => {
        settingItems[key] = false;
        if (values.includes(key)) {
          settingItems[key] = true;
        }
        return key;
      });
      return settingItems;
    },
  };
};

const DisplayItemsApi = DisplayItemsApiFactory();
export default DisplayItemsApi;
