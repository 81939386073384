import { call, put, takeLatest } from 'redux-saga/effects';
import { isNil } from 'lodash';
import sharedSagas from 'store/sharedSagas';
import contactApi from 'services/api/ContactApi';
import { HttpStatus } from 'services/utils';
import { loggerActions } from 'store/logger';
import loggerConstants from 'store/logger/constant';
import handleError from 'services/error/handleScopeError';
import types from './types';

const { BAD_REQUEST } = HttpStatus;

function* handleUploadFile(file) {
  const contentType = file.type;
  const { uploadUrl, fileName } = yield call(
    contactApi.getUploadUrl,
    contentType
  );

  yield call(contactApi.uploadFile, file, uploadUrl, contentType);

  return fileName;
}

function* handleRequest(action) {
  const { data, callback } = action.payload;
  const { attachment = null } = data;
  let attachmentOrigin = '';
  let attachmentS3 = '';
  if (!isNil(attachment)) {
    attachmentOrigin = attachment.name;
    attachmentS3 = yield handleUploadFile(attachment);
  }

  yield call(contactApi.sendRequest, {
    ...data,
    attachment: attachmentOrigin,
    attachment_url: attachmentS3,
  });

  callback();
}

function* errorHandler(err, action) {
  const { callback = () => {} } = action.payload || {};
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [{ message: err.message }];

  if (scope === loggerConstants.SCOPE_DONT_SHOW) {
    callback(true, errors);
  } else {
    callback(true);
    yield put(loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
  }
}

export default function* watch() {
  yield takeLatest(
    types.SEND_REQUEST,
    sharedSagas.safe(errorHandler, handleRequest)
  );
}
