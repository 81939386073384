import helper from 'store/helper';
import types from 'store/resize-table/types';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';
import {
  CATEGORY_ANALYZE,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
  CV_ATTRIBUTE,
  CV_FLOW,
  COST_ALLOCATION,
  AD_MANAGEMENT,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
  LTV_ANALYZE,
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  AGENCY_MANAGEMENT,
  TAG_MANAGEMENT,
  LANDING_PAGE_ANALYZE,
  MEDIA_SYNC_MANAGEMENT,
  DATA_EXPORT,
  SYSTEM_SETTING_COOKIE_PROGRAM,
  MEASUREMENT_SITE_PAGE_MANAGEMENT,
  SYSTEM_SETTING_DNS,
  LPO_LINK,
  LPO_PERIOD,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  LOG_ROUTE_ANALYZE,
  CAPI_SETTING,
  SEARCH_CONSOLE_SETTING,
  LINE_ADD_FRIEND_SETTINGS,
  DELETE_LINE_ADD_FRIEND_SETTINGS,
} from 'services/routes/constants';

const pages = [
  CATEGORY_ANALYZE,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
  CV_FLOW,
  CV_ATTRIBUTE,
  COST_ALLOCATION,
  LANDING_PAGE_ANALYZE,
  AD_MANAGEMENT,
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  LTV_ANALYZE,
  LTV_SETTINGS_AD,
  LTV_SETTINGS_CONDITION,
  AGENCY_MANAGEMENT,
  `${TAG_MANAGEMENT}-${TAG_MANAGEMENT_TAB.CV}`,
  `${TAG_MANAGEMENT}-${TAG_MANAGEMENT_TAB.PV}`,
  `${TAG_MANAGEMENT}-${TAG_MANAGEMENT_TAB.CONTENT_CATEGORY}`,
  MEDIA_SYNC_MANAGEMENT,
  DATA_EXPORT,
  SYSTEM_SETTING_COOKIE_PROGRAM,
  MEASUREMENT_SITE_PAGE_MANAGEMENT,
  SYSTEM_SETTING_DNS,
  LPO_LINK,
  LPO_PERIOD,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  LOG_ROUTE_ANALYZE,
  CAPI_SETTING,
  SEARCH_CONSOLE_SETTING,
  LINE_ADD_FRIEND_SETTINGS,
  DELETE_LINE_ADD_FRIEND_SETTINGS,
];

const initialState = pages.reduce((acc, page) => {
  return {
    ...acc,
    [page]: {
      columnsWidth: {},
      tablesWidth: { freeze: null, main: null },
    },
  };
}, {});

const updateColumnsWidth = (state, action) => {
  const { page, columnsWidth } = action.payload;
  return {
    ...state,
    [page]: {
      ...state[page],
      columnsWidth: {
        ...state[page].columnsWidth,
        ...columnsWidth,
      },
    },
  };
};

const updateColumnWidth = (state, action) => {
  const { page, column, width } = action.payload;
  return {
    ...state,
    [page]: {
      ...state[page],
      columnsWidth: {
        ...state[page].columnsWidth,
        [column]: width,
      },
    },
  };
};

const updateTableWidth = (state, action) => {
  const { page, freezeWidth } = action.payload;
  return {
    ...state,
    [page]: {
      ...state[page],
      tablesWidth: {
        ...state[page].tablesWidth,
        freeze: freezeWidth,
      },
    },
  };
};

const updateColumnWidthMulitiple = (state, action) => {
  const { page, config } = action.payload;
  return {
    ...state,
    [page]: {
      ...state[page],
      columnsWidth: config,
    },
  };
};

const ResizeTableReducer = helper.createReducer(initialState, {
  [types.UPDATE_COLUMNS_WIDTH]: updateColumnsWidth,
  [types.UPDATE_COLUMN_WIDTH]: updateColumnWidth,
  [types.UPDATE_TABLE_WIDTH]: updateTableWidth,
  [types.UPDATE_TABLE_WIDTH_MULITIPLE]: updateColumnWidthMulitiple,
});

export default ResizeTableReducer;
