import * as FIELDS from 'domain/fields';

export const MAX_CONVERSION_DISPLAY = 5;

export const MAX_LENGTH_AD_GROUP_NAME = 40;
export const MAX_LENGTH_AD_NOTE_NAME = 20;
export const MAX_LENGTH_OTHER_NAME = 20;
export const MAX_LENGTH_RCV_VALUE = 5;

export const RCV_TOTAL = 100;
export const RCV_PATTERN_LINEAR = 1;
export const RCV_PATTERN_FIRST_INTERACTION = 2;
export const RCV_PATTERN_LAST_INTERACTION = 3;
export const RCV_PATTERN_POSITION_BASED = 4;
export const RCV_PATTERN_CUSTOM = 5;

export const RCV_PATTERN = {
  [RCV_PATTERN_LINEAR]: {
    key: RCV_PATTERN_LINEAR,
    name: '均等配分',
    description: '全接触に均等に成果を配分します。',
  },
  [RCV_PATTERN_FIRST_INTERACTION]: {
    key: RCV_PATTERN_FIRST_INTERACTION,
    name: '初回接触重視',
    description:
      '初回接触に80％の重みづけを行い、残り20％を他の接触で均等配分します。',
  },
  [RCV_PATTERN_LAST_INTERACTION]: {
    key: RCV_PATTERN_LAST_INTERACTION,
    name: 'ラスト接触重視',
    description:
      '直接効果に80％の重みづけを行い、残り20％を他接触で均等配分します。',
  },
  [RCV_PATTERN_POSITION_BASED]: {
    key: RCV_PATTERN_POSITION_BASED,
    name: '初回/ラスト重視',
    description:
      '初回/初回接触に40％、直接効果に40％、残り20％を他接触で均等配分します。',
  },
  [RCV_PATTERN_CUSTOM]: {
    key: RCV_PATTERN_CUSTOM,
    name: 'カスタム',
    description: '状況に合わせて数値を変更できます。',
  },
};

export const SETTING_LABELS = [
  {
    name: FIELDS.AD_GROUP1,
    title: '広告グループ1',
    note: `${MAX_LENGTH_AD_GROUP_NAME}文字以内`,
  },
  {
    name: FIELDS.AD_GROUP2,
    title: '広告グループ2',
    note: `${MAX_LENGTH_AD_GROUP_NAME}文字以内`,
  },
  {
    name: FIELDS.AD_NOTE,
    title: '備考',
    note: `${MAX_LENGTH_AD_NOTE_NAME}文字以内`,
  },
  {
    name: FIELDS.OTHER1,
    title: '項目1',
    note: `${MAX_LENGTH_OTHER_NAME}文字以内`,
  },
  {
    name: FIELDS.OTHER2,
    title: '項目2',
    note: `${MAX_LENGTH_OTHER_NAME}文字以内`,
  },
  {
    name: FIELDS.OTHER3,
    title: '項目3',
    note: `${MAX_LENGTH_OTHER_NAME}文字以内`,
  },
  {
    name: FIELDS.OTHER4,
    title: '項目4',
    note: `${MAX_LENGTH_OTHER_NAME}文字以内`,
  },
  {
    name: FIELDS.OTHER5,
    title: '項目5',
    note: `${MAX_LENGTH_OTHER_NAME}文字以内`,
  },
];
