import { SettingsApi } from 'services/api/Api';
import pages from 'services/routes/pages';
import { USAGE_STATUS_SITE } from 'services/routes/constants';

const url = pages[USAGE_STATUS_SITE].endpoint;
const urlUsageStatus = `${url}/usage-status`;

const UsageStatusFactory = () => {
  return {
    fetchDataUsageStatus: async () => SettingsApi.get(urlUsageStatus),
  };
};

const UsageStatusApi = UsageStatusFactory();
export default UsageStatusApi;
