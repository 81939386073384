import * as DisplayItems from './display-items';

const SettingDisplayItems = {
  groupList: [],
  displayFreezeList: [DisplayItems.RANK, DisplayItems.RATE, DisplayItems.CNT],
  orderList: {
    [DisplayItems.RANK]: 1,
    [DisplayItems.RATE]: 2,
    [DisplayItems.CNT]: 3,
    [DisplayItems.PAGE_TITLE]: 4,
    [DisplayItems.PAGE_URL]: 5,
    [DisplayItems.PAGE_ID]: 6,
    [DisplayItems.CONTENT_CATEGORY]: 7,
  },
  displayDefaultList: [
    DisplayItems.RANK,
    DisplayItems.RATE,
    DisplayItems.CNT,
    DisplayItems.PAGE_TITLE,
    DisplayItems.PAGE_URL,
  ],
  sortDefaultList: {
    [DisplayItems.CNT]: DisplayItems.SORT_DIRECTION_DESC,
  },
  requiredList: [
    DisplayItems.RANK,
    DisplayItems.CNT,
    DisplayItems.RATE,
    DisplayItems.PAGE_TITLE,
    DisplayItems.PAGE_URL,
  ],
};

export const getAll = () => {
  const displayItemsBase = DisplayItems.getAll();
  return DisplayItems.getItems({
    ...SettingDisplayItems,
    displayItemsBase: {
      ...displayItemsBase,
      [DisplayItems.RANK]: {
        ...displayItemsBase[DisplayItems.RANK],
        title: 'No',
        allowSetting: false,
      },
      [DisplayItems.CNT]: {
        ...displayItemsBase[DisplayItems.CNT],
        allowSetting: false,
      },
      [DisplayItems.RATE]: {
        ...displayItemsBase[DisplayItems.RATE],
        allowSetting: false,
      },
      [DisplayItems.PAGE_URL]: {
        ...displayItemsBase[DisplayItems.PAGE_URL],
        allowSetting: false,
      },
      [DisplayItems.PAGE_TITLE]: {
        ...displayItemsBase[DisplayItems.RANK],
        title: 'ページタイトル(ページURL)',
        titleTable: 'ページタイトル',
      },
    },
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return DisplayItems.getDefault(displayItemsBase);
};
