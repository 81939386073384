import { FUNC_CODE_CSV_SETTING } from 'domain/settings/display-items';
import helper from 'store/helper';
import types from './types';

const initialState = {
  modalItems: {
    /* : {
      channel: true,
      category: false,
      ...
    }, */
  },
  sorting: [],
  modalStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null, // string | null,
  funcId: FUNC_CODE_CSV_SETTING,
};

const requestStart = (state) => {
  return {
    ...state,
    modalStatus: 'loading',
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    modalStatus: 'failed',
    error,
  };
};

const fetchSucceeded = (state, action) => {
  const { funcId, items, sorting = [], override = false } = action.payload;

  let csvSetting;
  if (override) {
    csvSetting = items;
  } else {
    csvSetting = { ...state.modalItems, ...items };
  }

  return {
    ...state,
    modalItems: csvSetting,
    sorting,
    modalStatus: 'succeeded',
    funcId: funcId || state.funcId,
  };
};

const createSucceeded = (state, action) => {
  const { items, sorting = [] } = action.payload;
  return {
    ...state,
    modalItems: {
      ...state.modalItems,
      ...items,
    },
    sorting,
    modalStatus: 'succeeded',
  };
};

const CsvSettingReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_SUCCEEDED]: fetchSucceeded,
  [types.CREATE_SUCCEEDED]: createSucceeded,
});

export default CsvSettingReducer;
