import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
  SORT_DIRECTION_OTHER,
} from 'domain/settings/display-items';

function GridTableSortIcon(props) {
  const { direction } = props;

  const classIcon = classNames({
    'grid-table__column-sort': true,
    fas: true,
    'fa-arrow-up': direction === SORT_DIRECTION_ASC,
    'fa-arrow-down': [SORT_DIRECTION_DESC, SORT_DIRECTION_OTHER].includes(
      direction
    ),
  });

  return <i className={classIcon} />;
}

GridTableSortIcon.propTypes = {
  direction: string.isRequired,
};

export default React.memo(GridTableSortIcon);
