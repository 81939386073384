import { USAGE_STATUS_SITE, INTERNAL } from 'services/routes/constants';

import { ACCOUNT_USAGE_VIEW } from 'domain/permissions/contractPermissions';

const userConfigs = [
  {
    order: 1,
    text: 'ご利用状況',
    url: USAGE_STATUS_SITE,
    type: INTERNAL,
    permissions: [ACCOUNT_USAGE_VIEW],
    newTab: false,
  },
];

export const UserConfigsCapiSingle = [
  {
    order: 1,
    text: 'ご利用状況',
    url: USAGE_STATUS_SITE,
    type: INTERNAL,
    permissions: [ACCOUNT_USAGE_VIEW],
    newTab: false,
  },
];

export default userConfigs;
