import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LOG_PERIOD_ANALYZE, USER_ENDPOINT } from 'services/routes/constants';
import apiUtils from 'services/apiUtils';
import { FUNC_CODE_LOG_PERIOD_ANALYZE } from 'domain/settings/display-items';

const PeriodAnalyzeFactory = () => {
  const { endpoint } = pages[LOG_PERIOD_ANALYZE];
  const MEMO_ENDPOINT = `${USER_ENDPOINT}/memos/${FUNC_CODE_LOG_PERIOD_ANALYZE}`;

  return {
    getData: async (request) => Api.post(endpoint, request),
    createMemo: async (request) => Api.post(`${MEMO_ENDPOINT}`, request),
    updateMemo: async (id, request) => {
      const url = `${MEMO_ENDPOINT}/${id}`;
      return Api.put(url, request);
    },
    loadMemo: async (request) => {
      const url = apiUtils.buildUrlWithQueryStrings(
        `${MEMO_ENDPOINT}`,
        request
      );
      return Api.get(url);
    },
    deleteMemo: async (id) => Api.delete(`${MEMO_ENDPOINT}/${id}`),
  };
};

const PeriodAnalyzeApi = PeriodAnalyzeFactory();
export default PeriodAnalyzeApi;
