const GET_MASTERDATA = 'data-export/GET_MASTERDATA';
const SET_MASTERDATA = 'data-export/SET_MASTERDATA';
const SET_STATUS_MASTERDATA = 'data-export/SET_STATUS_MASTERDATA';

const GET_LIST = 'data-export/GET_LIST';
const SET_DATA_LIST = 'data-export/SET_DATA_LIST';
const SET_ERROR_LIST = 'data-export/SET_ERROR_LIST';
const SET_STATUS_LIST = 'data-export/SET_STATUS_LIST';

const GET_LIST_HISTORY = 'data-export/GET_LIST_HISTORY';
const SET_DATA_LIST_HISTORY = 'data-export/SET_DATA_LIST_HISTORY';
const SET_ERROR_LIST_HISTORY = 'data-export/SET_ERROR_LIST_HISTORY';
const SET_STATUS_LIST_HISTORY = 'data-export/SET_STATUS_LIST_HISTORY';

const GET_DETAIL = 'data-export/GET_DETAIL';
const SET_DATA_DETAIL = 'data-export/SET_DATA_DETAIL';
const SET_ERROR_DETAIL = 'data-export/SET_ERROR_DETAIL';
const SET_STATUS_DETAIL = 'data-export/SET_STATUS_DETAIL';

const CREATE = 'data-export/CREATE';
const UPDATE = 'data-export/UPDATE';
const DELETE_REPORT = 'data-export/DELETE_REPORT';
const DOWNLOAD_CSV = 'data-export/DOWNLOAD_CSV';

const GET_DATA_SYSTEM_SYNC = 'data-export/GET_DATA_SYSTEM_SYNC';
const SET_STATUS_SYSTEM_SYNC = 'data-export/SET_STATUS_SYSTEM_SYNC';

const APPLY_VIEW = 'data-export/APPLY_VIEW';

const GET_METADATA = 'data-export/GET_METADATA';
const SET_METADATA = 'data-export/SET_METADATA';
const SET_STATUS_METADATA = 'data-export/SET_STATUS_METADATA';

export default {
  GET_MASTERDATA,
  SET_MASTERDATA,
  SET_STATUS_MASTERDATA,
  GET_LIST,
  SET_DATA_LIST,
  SET_ERROR_LIST,
  SET_STATUS_LIST,
  GET_LIST_HISTORY,
  SET_DATA_LIST_HISTORY,
  SET_ERROR_LIST_HISTORY,
  SET_STATUS_LIST_HISTORY,
  GET_DETAIL,
  SET_DATA_DETAIL,
  SET_ERROR_DETAIL,
  SET_STATUS_DETAIL,
  CREATE,
  UPDATE,
  DELETE_REPORT,
  DOWNLOAD_CSV,
  GET_DATA_SYSTEM_SYNC,
  SET_STATUS_SYSTEM_SYNC,
  APPLY_VIEW,
  GET_METADATA,
  SET_METADATA,
  SET_STATUS_METADATA,
};
