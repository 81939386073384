import { communicationStatus } from 'services/utils';
import types from 'store/usage-status/types';
import helper from 'store/helper';

const { IDLE, SUCCEEDED } = communicationStatus;

const inititalState = {
  data: {
    detail: {
      item: {},
      errors: [],
    },
  },
  status: {
    detail: IDLE,
  },
};

const setUsageStatus = (state, action) => {
  return {
    ...state,
    status: {
      ...state.status,
      detail: action.payload.status,
    },
  };
};

const setDataUsageStatus = (state, action) => {
  const { data } = action.payload.data;

  return {
    ...state,
    data: {
      ...state.data,
      detail: {
        ...state.data.detail,
        item: data.data,
        errors: [],
      },
    },
    status: {
      ...state.status,
      detail: SUCCEEDED,
    },
  };
};

const UsageStatusReducer = helper.createReducer(inititalState, {
  [types.SET_USAGE_STATUS]: setUsageStatus,
  [types.SET_DATA_USAGE_STATUS]: setDataUsageStatus,
});

export default UsageStatusReducer;
