import { USER_ENDPOINT } from 'services/routes/constants';
import Api from './Api';

const ViewApiFactory = () => {
  const url = `${USER_ENDPOINT}/display-settings/view-template`;
  return {
    fetchAll: async () => Api.get(url),
    fetch: async (id) => Api.get(`${url}/${id}`),
    create: async (data) => Api.post(url, data),
    update: async (id, data) => Api.put(`${url}/${id}`, data),
    delete: async (id) => Api.delete(`${url}/${id}`),
    toggleFavorite: async (id) => Api.put(`${url}/${id}/status`),
  };
};

const ViewApi = ViewApiFactory();
export default ViewApi;
