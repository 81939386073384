import React, {useState} from 'react'
import { Button, Modal } from 'react-bootstrap';
import {
    arrayOf,
    objectOf,
    func,
    oneOfType,
    number,
    object,
    bool,
  } from 'prop-types';
  import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
  import DragonBall from 'views/atoms/dragonball/DragonBall';
  import EbisStep from 'views/molecules/Step/EbisStep';
import TableModal from 'views/organism/TableModal';
import Spinner from 'views/atoms/loader/Spinner';
import { COOKIE_PROGRAM_CHECK_TOOLTIP_MESSAGE } from 'domain/system-setting/consts';
import { COOKIE_PROGRAM_CHECK_NOTE_LINK } from 'services/routes/constants';

function CookieProgramCheckModal(props){

    const {
        isAdd,
        steps,
        checkColumns,
        checkList,
        onCancel,
        onSubmit,
        isLoading,
        isChecking,
        } = props;

    const [agreeChecked, agreeCheckedClick] = useState(false);

    return (
        <Modal show centered size="w800" dialogClassName="modal-cookie-program-setting" contentClassName="modal-content--settings">
            <Modal.Header className="justify-content-start">
              <Modal.Title style={{ marginRight: 10 }}>
                {isAdd ? (
                  <>
                    ITP対応プログラム設定を追加
                  </>
                ) : (
                  <>
                    ITP対応プログラム設定を編集
                  </>
                )}
              </Modal.Title>
              <EbisStep steps={steps} />
            </Modal.Header>
            <Modal.Body>
            {isLoading ? (
              <div className='checkModalSpinner'>
                <Spinner/>
              </div>
            ) : (
              <TableModal
                header={
                  <>
                    <tr>
                      <th rowSpan={2} colSpan={1} className='cpcc_domain'><div className='cookie_program_check_modal_domain'>{checkColumns[0].text}</div></th>
                      <th rowSpan={2} colSpan={1} className='cpcc_url'><div className='cookie_program_check_modal_url'>{checkColumns[1].text}</div></th>
                      <th rowSpan={1} colSpan={2} className='cpcc_check'>
                        <div className='cookie_program_check_modal_check'>
                          {checkColumns[2].text}
                          <DragonBall stayOpen>{COOKIE_PROGRAM_CHECK_TOOLTIP_MESSAGE}</DragonBall>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th className='cpcc_status'><div className='cookie_program_check_modal_status_header'>{checkColumns[2].children[0].text}</div></th>
                      <th className='cpcc_cookie_value'><div className='cookie_program_check_modal_cookie_value_header'>{checkColumns[2].children[1].text}</div></th>
                    </tr>
                  </>
                }
                body={
                  checkList.map((item, index) => (
                    <tr key={index}>
                      <td className='cpcc_domain'><div className='cookie_program_check_modal_domain'>{item.measurement_fqdn}</div></td>
                      <td className='cpcc_url'><div className='cookie_program_check_modal_url'>{item.program_url}</div></td>
                      <td className='cpcc_status'><div className='cookie_program_check_modal_status'>{item.first_party_cookie_program_url_status}</div></td>
                      <td className='cpcc_cookie_value'><div className='cookie_program_check_modal_cookie_value'>{item.first_party_cookie_program_cookie_value}</div></td>
                    </tr>
                  ))
                }
                variant='cookie_program_check_modal'
                maxContent='235'
                alignScroll='0'
                isNoScrollWrapper={false}
              />
            )}
            </Modal.Body>
            <Modal.Footer className='modal-footer__has-ebis-checkbox'>
              {isLoading ? (
                <></>
              ) : (
                <div>
                  下記注意事項を必ずご確認ください。
                  <div className='check-modal-footer-notes-text'>
                    プログラムを追加するドメインで当サービスのNS/CNAME設定をされている場合、NS/CNAME設定が無効になります。
                    詳細は<a
                    href={COOKIE_PROGRAM_CHECK_NOTE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    >こちら。</a>
                  </div>
                </div>
              )}
              <div>
                <EbisCheckbox
                  name="agree-for-register"
                  text='注意事項を確認しました。'
                  checked={agreeChecked}
                  changeSelectBoxValue={() => agreeCheckedClick(!agreeChecked)}
                  disabled={isLoading}
                />
              </div>
              <div>
                <Button
                  variant="link"
                  size="sm"
                  onClick={onCancel}
                  disabled={isLoading}
                >
                  戻る
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={onSubmit}
                  disabled={isChecking || !agreeChecked || isLoading}
                >
                  OK
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
    );
}

CookieProgramCheckModal.propTypes = {
    isAdd:bool,
    steps:number,
    checkColumns:objectOf(oneOfType([object])),
    checkList:arrayOf(oneOfType([object])),
    onCancel:func,
    onSubmit:func,
    isLoading:bool,
    isChecking:bool,
};

CookieProgramCheckModal.defaultProps = {
    isAdd:false,
    steps:1,
    checkColumns:[{}],
    checkList:[{}],
    onCancel:() => {},
    onSubmit:() => {},
    isLoading:false,
    isChecking:false,
};

export default CookieProgramCheckModal;
