import React, { useCallback, useMemo, useRef, useState } from 'react';

const useEbisAxisTooltipHook = () => {
  const [isOpenTooltip, toggleOpenTooltip] = useState(false);
  const handleStayOpenTooltip = useCallback(
    (isToggleOpen) => toggleOpenTooltip(isToggleOpen),
    []
  );
  const hookRef = useRef(null);
  const cellRef = useRef(null);
  const hook = useMemo(() => {
    let top = 0;
    let left = 0;
    if (cellRef.current !== null) {
      top = cellRef.current.offsetTop + cellRef.current.clientHeight - 10;
      left = cellRef.current.offsetLeft + cellRef.current.clientWidth - 10;
    }
    return React.createElement(
      'div',
      {
        style: {
          top,
          left,
          width: '10px',
          height: '10px',
          position: 'absolute',
          backgroundColor: 'transparent',
        },
        ref: hookRef,
        onMouseOver: () => handleStayOpenTooltip(true),
        onMouseOut: () => handleStayOpenTooltip(false),
      },
      null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleStayOpenTooltip,
    hookRef,
    hookRef.current,
    cellRef,
    cellRef.current,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cellRef.current?.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cellRef.current?.clientWidth,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cellRef.current?.offsetTop,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cellRef.current?.offsetLeft,
  ]);

  return {
    hook,
    cellRef,
    hookRef,
    isOpenTooltip,
    handleStayOpenTooltip,
  };
};

export default useEbisAxisTooltipHook;
