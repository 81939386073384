import React, { useRef } from 'react';
import { oneOfType, bool, node, string, func } from 'prop-types';
import FormGroup from 'views/molecules/FormGroup';
import FileUpload from 'views/molecules/FileUpload';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';

function FormGroupUpload(props) {
  const {
    required,
    label,
    note,
    tooltip,
    value,
    error,
    onClear,
    onFileUploadAccepted,
  } = props;

  const ref = useRef();

  return (
    <FormGroup required={required} label={label} tooltip={tooltip} ref={ref}>
      <ErrorTooltipWrapper isError={!!error} errorMess={error} ref={ref}>
        <FileUpload
          onFileUploadAccepted={onFileUploadAccepted}
          onClear={onClear}
          isError={!!error}
          defaultFiles={value ? [{ name: value }] : undefined}
          note={note}
        />
      </ErrorTooltipWrapper>
    </FormGroup>
  );
}

FormGroupUpload.propTypes = {
  required: bool,
  label: string,
  note: string,
  tooltip: oneOfType([string, node]),
  error: string,
  value: string,
  onClear: func,
  onFileUploadAccepted: func,
};

FormGroupUpload.defaultProps = {
  required: false,
  label: '',
  note: '',
  tooltip: '',
  error: '',
  value: '',
  onClear: () => {},
  onFileUploadAccepted: () => {},
};

export default FormGroupUpload;
