import helper from 'store/helper';
import isEmpty from 'lodash/isEmpty';
import types from 'store/log/period-analyze/types';
import { CNT_PV, SORT_DIRECTION_ASC } from 'domain/settings/display-items';
import { communicationStatus, equalsAllKeys } from 'services/utils';
import { PERIOD } from 'domain/fields';
import {
  PERIOD_TYPE,
  MEMO_HIDDEN_PERIOD_TYPES,
} from 'domain/period-analyze/consts';
import periodAnalysisService from 'services/period-analyze/periodAnalysisService';

const { IDLE, SUCCEEDED, FAILED } = communicationStatus;

const initialState = {
  data: {
    table: {
      sum: {},
      detail: [],
      metadata: {},
      comparedMetadata: [],
      comparedDetail: [],
      comparedSum: [],
    },
    memo: {
      list: [],
      errors: [],
    },
    chart: {
      list: [],
      categories: [],
      comparedList: null,
    },
  },
  settings: {
    chart: {
      enabled: true,
      bookmark: false,
      categories: [],
      axis: {
        primary: CNT_PV,
        secondary: null,
      },
    },
    memo: {
      enabled: false,
      hidden: false,
      showModal: false,
      modalReset: new Date().getTime(),
      modalData: null,
      showDeleteModal: false,
      showErrorModal: false,
    },
    sort: { field: PERIOD, direction: SORT_DIRECTION_ASC },
    periodType: PERIOD_TYPE.DAY,
    periodRange: [],
    comparedPeriodRange: [],
    comparedSort: { field: PERIOD, direction: SORT_DIRECTION_ASC },
    currentPeriodActive: true,
  },
  errors: [],
  status: {
    chart: IDLE,
    table: IDLE,
    memo: false,
  },
};

const mapperKeyStatusByAction = {
  [types.GET_DATA_CHART]: 'chart',
  [types.GET_DATA_TABLE]: 'table',
  [types.GET_DATA_COMPARED_TABLE]: 'table',
};

const setStatusTable = (state, action) => {
  const { status } = action.payload;
  return {
    ...state,
    status: {
      ...state.status,
      table: status,
    },
  };
};

const setDataTable = (state, action) => {
  const { data, metadata } = action.payload.data;
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        detail: data.detail,
        sum: data.sum,
        metadata,
      },
    },
    status: { ...state.status, table: SUCCEEDED },
  };
};

const setDataComparedTable = (state, action) => {
  const { data, metadata } = action.payload.data;
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        comparedDetail: data.detail,
        comparedSum: data.sum,
        comparedMetadata: metadata,
      },
    },
    status: { ...state.status, table: SUCCEEDED },
  };
};

const setErrors = (state, action) => {
  const { type, errors } = action.payload;
  const key = mapperKeyStatusByAction[type];
  if (!key) return [...state];

  return {
    ...state,
    errors,
    status: { ...state.status, [key]: FAILED },
  };
};

const changeSortTable = (state, action) => {
  const sort = action.payload;
  return {
    ...state,
    settings: { ...state.settings, sort },
  };
};

const setStatusChart = (state, action) => {
  return {
    ...state,
    status: { ...state.status, chart: action.payload.status },
  };
};

const setDataChart = (state, action) => {
  const { list, comparedList, categories } = action.payload.data;
  return {
    ...state,
    data: {
      ...state.data,
      chart: { ...state.data.chart, list, comparedList, categories },
    },
    settings: {
      ...state.settings,
      chart: { ...state.settings.chart, categories },
    },
    status: { ...state.status, chart: SUCCEEDED },
  };
};

const changeAxis = (state, action) => {
  const { field, value } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      chart: {
        ...state.settings.chart,
        bookmark: false,
        axis: { ...state.settings.chart.axis, [field]: value },
      },
    },
  };
};

const changeCategory = (state, action) => {
  const { category, selected } = action.payload;
  let categories = [...state.settings.chart.categories];
  if (selected) {
    categories.push(category);
  } else {
    categories = categories.filter((item) => !equalsAllKeys(item, category));
  }

  return {
    ...state,
    settings: {
      ...state.settings,
      chart: { ...state.settings.chart, categories },
    },
  };
};

const changeComparedSortTable = (state, action) => {
  const sort = action.payload;
  return {
    ...state,
    settings: { ...state.settings, comparedSort: sort },
  };
};

const changeActivePeriod = (state, action) => {
  const { currentPeriodActive } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      currentPeriodActive,
    },
  };
};

// reducer for memo

const toggleMemo = (state, { payload }) => {
  const { enabled } = payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      memo: {
        ...state.settings.memo,
        enabled,
      },
    },
  };
};

const toggleChart = (state, action) => {
  const { enabled } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      chart: { ...state.settings.chart, enabled },
    },
  };
};

const updateChartDisplayMemo = (state, { payload }) => {
  return {
    ...state,
    data: {
      ...state.data,
      chart: {
        ...state.data.chart,
        list: payload,
      },
    },
  };
};

const setSetting = (state, action) => {
  const { settings, shouldApplyMetrics } = action.payload;
  const isApplyBookmark = settings.enabled;

  return {
    ...state,
    settings: {
      ...state.settings,
      chart: {
        ...state.settings.chart,
        enabled: settings.showChart ?? true,
        bookmark: isApplyBookmark,
        axis: {
          ...state.settings.chart.axis,
          primary: isApplyBookmark && shouldApplyMetrics
            ? settings.primary
            : state.settings.chart.axis.primary,
          secondary: isApplyBookmark && shouldApplyMetrics
            ? settings.secondary
            : state.settings.chart.axis.secondary,
        },
      },
    },
  };
};

const saveBookmark = (state, action) => {
  const { enabled } = action.payload;

  return {
    ...state,
    settings: {
      ...state.settings,
      chart: {
        ...state.settings.chart,
        bookmark: enabled,
      },
    },
  };
};
const showMemoModal = (state, { payload }) => {
  const memoList = state.data.memo.list;
  const { id, reset } = payload;
  let modalData = null;
  if (id) {
    modalData = periodAnalysisService.createModalData(id, memoList);
  }
  const modalReset = reset
    ? new Date().getTime()
    : state.settings.memo.modalReset;
  return {
    ...state,
    settings: {
      ...state.settings,
      memo: {
        ...state.settings.memo,
        showModal: true,
        modalData,
        modalReset,
      },
    },
  };
};

const hideMemoModal = (state) => ({
  ...state,
  settings: {
    ...state.settings,
    memo: {
      ...state.settings.memo,
      showModal: false,
    },
  },
  data: {
    ...state.data,
    memo: {
      ...state.data.memo,
      errors: [],
    },
  },
});

const showDeleteModal = (state) => ({
  ...state,
  settings: {
    ...state.settings,
    memo: {
      ...state.settings.memo,
      showDeleteModal: true,
    },
  },
});

const hideDeleteModal = (state) => ({
  ...state,
  settings: {
    ...state.settings,
    memo: {
      ...state.settings.memo,
      showDeleteModal: false,
    },
  },
});

const showMemoErrorModal = (state) => ({
  ...state,
  settings: {
    ...state.settings,
    memo: {
      ...state.settings.memo,
      showErrorModal: true,
      showModal: false,
      showDeleteModal: false,
    },
  },
});

const hideMemoErrorModal = (state) => ({
  ...state,
  settings: {
    ...state.settings,
    memo: {
      ...state.settings.memo,
      showErrorModal: false,
    },
  },
});

const loadMemoSuccess = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    memo: {
      ...state.data.memo,
      list: payload,
    },
  },
});

const setMemoSubmitting = (state, { payload: { submitting } }) => ({
  ...state,
  status: {
    ...state.status,
    memo: submitting,
  },
});

const setMemoErrors = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    memo: {
      ...state.data.memo,
      errors: payload.errors,
    },
  },
});

const changePeriodType = (state, { payload }) => {
  const memoHidden = MEMO_HIDDEN_PERIOD_TYPES.includes(payload.type);
  const memoEnabled = memoHidden ? false : state.settings.memo.enabled;
  return {
    ...state,
    settings: {
      ...state.settings,
      periodType: payload.type,
      memo: {
        ...state.settings.memo,
        hidden: memoHidden,
        enabled: memoEnabled,
      },
    },
  };
};

const removeSecondaryMetric = (state) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      chart: {
        ...state.settings.chart,
        axis: {
          ...state.settings.chart.axis,
          secondary: null,
        },
      },
    },
  };
};

const LogPeriodAnalyzeReducer = helper.createReducer(initialState, {
  [types.SET_SETTING]: setSetting,
  [types.SAVE_BOOKMARK]: saveBookmark,
  [types.SET_STATUS_CHART]: setStatusChart,
  [types.SET_DATA_CHART]: setDataChart,
  [types.SET_STATUS_TABLE]: setStatusTable,
  [types.SET_DATA_TABLE]: setDataTable,
  [types.CHANGE_AXIS]: changeAxis,
  [types.CHANGE_CATEGORY]: changeCategory,
  [types.TOGGLE_CHART]: toggleChart,
  [types.SET_ERRORS]: setErrors,
  [types.CHANGE_SORT_TABLE]: changeSortTable,
  [types.CHANGE_ACTIVE_PERIOD]: changeActivePeriod,
  [types.CHANGE_SORT_COMPARED_TABLE]: changeComparedSortTable,
  [types.SET_DATA_COMPARED_TABLE]: setDataComparedTable,
  [types.TOGGLE_MEMO]: toggleMemo,
  [types.SHOW_MEMO_MODAL]: showMemoModal,
  [types.HIDE_MEMO_MODAL]: hideMemoModal,
  [types.SHOW_DELETE_MODAL]: showDeleteModal,
  [types.HIDE_DELETE_MODAL]: hideDeleteModal,
  [types.SHOW_MEMO_ERROR_MODAL]: showMemoErrorModal,
  [types.HIDE_MEMO_ERROR_MODAL]: hideMemoErrorModal,
  [types.LOAD_MEMO_SUCCESS]: loadMemoSuccess,
  [types.SET_MEMO_SUBMITTING]: setMemoSubmitting,
  [types.SET_MEMO_ERRORS]: setMemoErrors,
  [types.CHANGE_PERIOD_TYPE]: changePeriodType,
  [types.UPDATE_CHART_DISPLAY_MEMO]: updateChartDisplayMemo,
  [types.REMOVE_SECONDARY_METRIC]: removeSecondaryMetric,
});

export default LogPeriodAnalyzeReducer;
