import types from 'store/external-services/types';
import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';

const getList = () => helper.createAction(types.GET_LIST);
const setDataList = (data) =>
  helper.createAction(types.SET_DATA_LIST, { data });
const setErrorList = (errors, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERROR_LIST, { errors }, scope);
const setStatusList = (status) =>
  helper.createAction(types.SET_STATUS_LIST, { status });

const getDetail = (id, callback = () => {}, convert = false) =>
  helper.createAction(types.GET_DETAIL, {
    id,
    convert, // false: limit data link media/adgroup, true: get full data link media/adgroup
    callback,
  });
const setDetail = (data) =>
  helper.createAction(types.SET_DATA_DETAIL, { data });
const setErrorDetail = (errors, scope) =>
  helper.createAction(types.SET_ERROR_DETAIL, { errors }, scope);
const setStatusDetail = (status) =>
  helper.createAction(types.SET_STATUS_DETAIL, { status });

const active = (data, callback) =>
  helper.createAction(types.ACTIVE, { data, callback });
const deactive = (key, callback) =>
  helper.createAction(types.DEACTIVE, { key, callback });

const externalServicesActions = {
  getList,
  setDataList,
  setErrorList,
  setStatusList,
  getDetail,
  setDetail,
  setErrorDetail,
  setStatusDetail,
  active,
  deactive,
};

export default externalServicesActions;
