import React, { useRef, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { objectOf, shape, string, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import {
  REPORT_TYPE_CV_ATTRIBUTE,
  REPORT_FIELDS,
  MIN_DIMENSION,
  MAX_DIMENSION,
  MIN_METRIC,
  MAX_METRIC,
  DIMENSION_GROUP_LABEL,
  METRIC_GROUP_LABEL,
} from 'domain/data-export/consts';
import SortListModal from 'views/molecules/modal/sort/SortListModal';

const { DIMENSIONS, METRICS, CONTACT_HISTORY } = REPORT_FIELDS;

const DisplayItemSetting = (props) => {
  const dimensionRef = useRef(null);
  const metricRef = useRef(null);
  const contactHistoryRef = useRef(null);

  const {
    reportType,
    dimension,
    metric,
    contactHistory,
    error,
    onChange,
  } = props;

  const isReportTypeCvAttr = reportType === REPORT_TYPE_CV_ATTRIBUTE;

  const {
    item: dimensionItem = {},
    groups: dimensionGroups = [],
    selected: dimensionSelected = [],
    minItem: dimensionMinItem = MIN_DIMENSION,
    maxItem: dimensionMaxItem = MAX_DIMENSION,
  } = dimension;
  const disabledDimensionUnCheck =
    dimensionSelected.length >= dimensionMaxItem && !isReportTypeCvAttr;
  const disabledDimensionChecked =
    dimensionSelected.length <= dimensionMinItem && !isReportTypeCvAttr;

  const {
    item: metricItem = {},
    groups: metricGroups = [],
    selected: metricSelected = [],
    minItem: metricMinItem = MIN_METRIC,
    maxItem: metricMaxItem = MAX_METRIC,
  } = metric;
  const disabledMetricUnCheck =
    metricSelected.length >= metricMaxItem && !isReportTypeCvAttr;
  const disabledMetricChecked =
    metricSelected.length <= metricMinItem && !isReportTypeCvAttr;

  const {
    items: contactHistoryItems = [],
    selected: contactHistorySelected = [],
  } = contactHistory;

  const [currentTypeSort, setCurrentTypeSort] = useState(null);

  const itemsToSort = useMemo(() => {
    if (currentTypeSort === DIMENSIONS) {
      return dimensionSelected.map((id) => ({
        id,
        name: dimensionItem[id]?.display_name,
      }));
    }
    return metricSelected.map((id) => ({
      id,
      name: metricItem[id]?.display_name,
    }));
  }, [
    currentTypeSort,
    dimensionItem,
    dimensionSelected,
    metricItem,
    metricSelected,
  ]);

  const handleChangeItem = (name) => (id) => {
    let selectedItems = [];
    switch (name) {
      case DIMENSIONS:
        selectedItems = [...dimensionSelected];
        break;
      case METRICS:
        selectedItems = [...metricSelected];
        break;
      case CONTACT_HISTORY:
        selectedItems = [...contactHistorySelected];
        break;
      default:
        break;
    }

    const newItems = selectedItems.includes(id)
      ? selectedItems.filter((item) => item !== id)
      : [...selectedItems, id];

    onChange(name, newItems);
  };

  const onOpenSortList = (type) => () => {
    setCurrentTypeSort(type);
  };

  const onHideSortList = () => {
    setCurrentTypeSort(null);
  };

  const handleSortList = (listSorts) => {
    const resultIds = listSorts.map((item) => item.id);
    onChange(currentTypeSort, resultIds);
    return onHideSortList();
  };

  return (
    <>
      {!isEmpty(dimensionGroups) && (
        <div
          className="data-export-setting__item data-export-setting__item--displayitem mb-30"
          ref={dimensionRef}
        >
          <ErrorTooltipWrapper
            isError={!!error[DIMENSIONS]}
            errorMess={error[DIMENSIONS]}
            ref={dimensionRef}
          >
            <div
              className={`data-export-setting__title-line${
                error[DIMENSIONS] ? ' is-error' : ''
              }`}
            >
              集計軸
            </div>
          </ErrorTooltipWrapper>
          {dimensionGroups.map((group) => {
            return (
              <div className="data-export-setting__item">
                {DIMENSION_GROUP_LABEL[group.name] && (
                  <div className="data-export-setting__title">
                    {DIMENSION_GROUP_LABEL[group.name]}
                  </div>
                )}
                <div className="data-export-setting__content">
                  {group.items.map((item) => {
                    const checked = dimensionSelected.includes(item.id);
                    const disabled =
                      (disabledDimensionChecked && checked) ||
                      (disabledDimensionUnCheck && !checked);
                    return (
                      <EbisCheckbox
                        key={item.id}
                        name={item.id}
                        text={item.display_name}
                        checked={item.fixed || checked}
                        disabled={item.fixed || disabled}
                        changeSelectBoxValue={handleChangeItem(DIMENSIONS)}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
          {!isReportTypeCvAttr && (
            <div className="data-export-setting__button">
              <Button
                variant="secondary"
                size="sm"
                disabled={dimensionSelected.length < 2}
                onClick={onOpenSortList(DIMENSIONS)}
              >
                集計軸の並び替え
              </Button>
            </div>
          )}
        </div>
      )}
      {!isEmpty(metricGroups) && !isReportTypeCvAttr && (
        <div
          className="data-export-setting__item data-export-setting__item--displayitem mb-30"
          ref={metricRef}
        >
          <ErrorTooltipWrapper
            isError={!!error[METRICS]}
            errorMess={error[METRICS]}
            ref={metricRef}
          >
            <div
              className={`data-export-setting__title-line${
                error[METRICS] ? ' is-error' : ''
              }`}
            >
              指標軸
            </div>
          </ErrorTooltipWrapper>
          {metricGroups.map((group) => {
            return (
              <div className="data-export-setting__item">
                {METRIC_GROUP_LABEL[group.name] && (
                  <div className="data-export-setting__title">
                    {METRIC_GROUP_LABEL[group.name]}
                  </div>
                )}
                <div className="data-export-setting__content">
                  {group.items.map((item) => {
                    const checked = metricSelected.includes(item.id);
                    const disabled =
                      (disabledMetricChecked && checked) ||
                      (disabledMetricUnCheck && !checked);
                    return (
                      <EbisCheckbox
                        key={item.id}
                        name={item.id}
                        text={item.display_name}
                        checked={checked}
                        disabled={disabled}
                        changeSelectBoxValue={handleChangeItem(METRICS)}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
          {!isReportTypeCvAttr && (
            <div className="data-export-setting__button">
              <Button
                variant="secondary"
                size="sm"
                disabled={metricSelected.length < 2}
                onClick={onOpenSortList(METRICS)}
              >
                指標軸の並び替え
              </Button>
            </div>
          )}
        </div>
      )}
      {!isEmpty(contactHistoryItems) && isReportTypeCvAttr && (
        <div
          className="data-export-setting__item data-export-setting__item--displayitem mb-30"
          ref={contactHistoryRef}
        >
          <ErrorTooltipWrapper
            isError={!!error[CONTACT_HISTORY]}
            errorMess={error[CONTACT_HISTORY]}
            ref={metricRef}
          >
            <div
              className={`data-export-setting__title-line${
                error[CONTACT_HISTORY] ? ' is-error' : ''
              }`}
            >
              接触タイミング
            </div>
          </ErrorTooltipWrapper>
          <div className="data-export-setting__item">
            <div className="data-export-setting__content">
              {contactHistoryItems.map((item) => (
                <EbisCheckbox
                  key={item.key}
                  name={item.name}
                  text={item.text}
                  checked={contactHistorySelected.includes(item.name)}
                  changeSelectBoxValue={handleChangeItem(CONTACT_HISTORY)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {!!currentTypeSort && (
        <SortListModal
          isShow
          onHide={onHideSortList}
          onSubmit={handleSortList}
          list={itemsToSort}
          pagination={{
            totalItems: itemsToSort.length,
            itemsPerPage: itemsToSort.length,
            visiblePageNum: false,
          }}
          title={
            currentTypeSort === DIMENSIONS
              ? '集計軸の並び替え'
              : '指標軸の並び替え'
          }
          subTitle={{
            title:
              '※上から表示されている順で適用されます。ドラッグアンドドロップで並べ替えをすることが可能です',
          }}
          isPagination
        />
      )}
    </>
  );
};

DisplayItemSetting.propTypes = {
  reportType: string.isRequired,
  dimension: objectOf(shape({})),
  metric: objectOf(shape({})),
  contactHistory: objectOf(shape({})),
  error: objectOf(shape({})),
  onChange: func,
};

DisplayItemSetting.defaultProps = {
  dimension: {},
  metric: {},
  contactHistory: {},
  error: {},
  onChange: () => {},
};

export default React.memo(DisplayItemSetting);
