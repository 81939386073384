import { useSelector, shallowEqual } from 'react-redux';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import {
  LINE_SETTING_EDIT,
  LINE_SETTING_VIEW,
} from 'domain/permissions/contractPermissions';
import { getPermissions } from 'store/auth/selectors';

const useCheckPermissionLineAddFriend = () => {
  const { checkPermissions } = useCheckPermissions();
  const permissions = useSelector(getPermissions, shallowEqual);

  const isLinenMeasureContract = checkPermissions('anyOf', [
    LINE_SETTING_VIEW,
    LINE_SETTING_EDIT,
  ]);

  let isLineAddFriendSettingPermissionOnly = false;
  if (permissions.length <= 2) {
    isLineAddFriendSettingPermissionOnly = checkPermissions('anyOf', [
      LINE_SETTING_VIEW,
      LINE_SETTING_EDIT,
    ]);
  }
  return {
    isLinenMeasureContract,
    isLineAddFriendSettingPermissionOnly,
  };
};

export default useCheckPermissionLineAddFriend;
