import {
  PRIORITY_AXIS_BOTH,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_MEDIA_SYNC,
} from 'domain/consts';
import * as DisplayItems from 'domain/settings/display-items';

const getSelectableContactList = (axis) => {
  const ALLOWED_AXIS_EBIS_LIST = [
    DisplayItems.CONTACT_CATEGORY,
    DisplayItems.CONTACT_AD_GROUP1,
    DisplayItems.CONTACT_AD_GROUP2,
    DisplayItems.CONTACT_AD_ID,
    DisplayItems.CONTACT_AD_NAME,
    DisplayItems.CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID,
  ];
  const ALLOWED_AXIS_MEDIA_SYNC_LIST = [
    DisplayItems.CONTACT_SYNC_CATEGORY,
    DisplayItems.CONTACT_MEDIA_SIDE_CAMPAIGN,
    DisplayItems.CONTACT_MEDIA_SIDE_GROUP,
    DisplayItems.CONTACT_MEDIA_SIDE_AD_ID,
    DisplayItems.CONTACT_MEDIA_SIDE_AD_NAME,
    DisplayItems.CONTACT_OPTIONAL_AD_ID,
  ];
  return axis === PRIORITY_AXIS_MEDIA_SYNC
    ? ALLOWED_AXIS_MEDIA_SYNC_LIST
    : ALLOWED_AXIS_EBIS_LIST;
};

const getSelectableAxisList = (axis, removeBoth) => {
  const ALLOWED_AXIS_EBIS_LIST = [
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
    DisplayItems.AD_ID,
    DisplayItems.AD_NAME,
    DisplayItems.AD_NOTE,
    DisplayItems.AD_DATE,
    DisplayItems.DIRECTORY,
    DisplayItems.PAGE_ID,
    DisplayItems.PAGE_TITLE,
    DisplayItems.PAGE_URL,
  ];
  const ALLOWED_AXIS_MEDIA_SYNC_LIST = [
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
    DisplayItems.MEDIA_SIDE_AD_ID,
    DisplayItems.MEDIA_SIDE_AD_NAME,
  ];
  const ALLOWED_AXIS_BOTH = [
    DisplayItems.CONTENT_CATEGORY,
    DisplayItems.CHANNEL,
    DisplayItems.AGENCY,
    DisplayItems.TERMINAL_TYPE,
    DisplayItems.LANDING_PAGE_DOMAIN,
    DisplayItems.LANDING_PAGE_URL,
    DisplayItems.SEARCH_WORD,
    DisplayItems.CV_NAME,
    DisplayItems.CV_ACCESS_TIME,
    DisplayItems.TRACKING_DATA,
    DisplayItems.MEMBER_NAME,
    DisplayItems.AMOUNT, // TODO: new key
    DisplayItems.OTHER1,
    DisplayItems.OTHER2,
    DisplayItems.OTHER3,
    DisplayItems.OTHER4,
    DisplayItems.OTHER5,
    DisplayItems.TERMINAL_TYPE,
    DisplayItems.LANDING_PAGE_TITLE,
  ];
  let axisList = [];
  if (axis === PRIORITY_AXIS_EBIS) {
    axisList = [...ALLOWED_AXIS_EBIS_LIST];
    if (!removeBoth) {
      axisList = [...axisList, ...ALLOWED_AXIS_BOTH];
    }
  }
  if (axis === PRIORITY_AXIS_MEDIA_SYNC) {
    axisList = [...ALLOWED_AXIS_MEDIA_SYNC_LIST];
    if (!removeBoth) {
      axisList = [...axisList, ...ALLOWED_AXIS_BOTH];
    }
  }
  if (axis === PRIORITY_AXIS_BOTH) {
    axisList = ALLOWED_AXIS_BOTH;
  }
  return axisList;
};

const getSelectableAxisListByMediaSync = (mediaSync, removeBoth = false) => {
  return getSelectableAxisList(
    mediaSync ? PRIORITY_AXIS_MEDIA_SYNC : PRIORITY_AXIS_EBIS,
    removeBoth
  );
};

const getSelectableContactListByMediaSync = (mediaSync) => {
  return getSelectableContactList(
    mediaSync ? PRIORITY_AXIS_MEDIA_SYNC : PRIORITY_AXIS_EBIS
  );
};

const getAxisNameByAxisNumber = (index) => {
  switch (index) {
    case '1':
    case 1:
      return PRIORITY_AXIS_EBIS;
    case '2':
    case 2:
      return PRIORITY_AXIS_MEDIA_SYNC;
    default:
      return '';
  }
};

export default {
  getSelectableAxisList,
  getSelectableAxisListByMediaSync,
  getSelectableContactList,
  getSelectableContactListByMediaSync,
  getAxisNameByAxisNumber,
};
