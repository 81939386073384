import helper from 'store/helper';
import types from 'store/log/page-analyze/types';

const pageAnalyzeActions = {
  getData: () => helper.createAction(types.GET_DATA),

  setData: (data) => helper.createAction(types.SET_DATA, { data }),
  setStatus: (status) => helper.createAction(types.SET_STATUS, { status }),
  setErrors: (errors, scope = null) =>
    helper.createAction(types.SET_ERRORS, { errors }, scope),

  changePage: (page) => helper.createAction(types.CHANGE_PAGE, { page }),
  changeSort: (field, direction) =>
    helper.createAction(types.CHANGE_SORT, { field, direction }),
};

export default pageAnalyzeActions;
