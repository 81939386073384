import { PAGE_NOT_AUTHORIZED, PAGE_NOT_FOUND } from './constants';
import pages from './pages';

const getPageNotAuthorizedPath = () => {
  return pages[PAGE_NOT_AUTHORIZED].path;
};

const getPageNotFoundPath = () => {
  return pages[PAGE_NOT_FOUND].path;
};

const getPageByPath = (path) => {
  const currentId = Object.keys(pages).find(
    (screenId) =>
      pages[screenId].path === path ||
      (pages[screenId].pathRegex &&
        new RegExp(pages[screenId].pathRegex).test(path))
  );
  if (currentId) {
    return pages[currentId];
  }
  return null;
};

const getModeByPath = (path) => {
  const page = getPageByPath(path);
  if (page) {
    const matches = page.path.match(/^.*?(?=:)/);
    return matches ? path.replace(matches[0], '') : null;
  }
  return null;
};

export {
  getPageNotAuthorizedPath,
  getPageNotFoundPath,
  getPageByPath,
  getModeByPath,
};
