import {
  SCHEDULE,
  SCHEDULE_WEEKDAYS,
  EMAIL_TITLE,
  INCLUDE_ALL,
  VIEW_INDICATOR_ALL,
  MEDIA_IDS,
  USERS,
} from 'domain/setting-mail/constants';

// Setting Mail
const ACCEPT_VALUE_CHECK_SCHEDULE =
  '送信有無に[未登録]は使用できません。[未登録]とならないよう修正してください。';
const ACCEPT_SELECTED_SCHEDULE_WEEKDAYS = '送信曜日を選択してください。';
const MAX_EMAIL_TITLE_LENGTH = '500字以内で入力してください。';
const EMAIL_TITLE_SPTAB_CHECK =
  'メールタイトルをスペース、タブ、改行のみで登録することはできません';
const ACCEPT_VALUE_CHECK_INCLUDE_ALL =
  '集計対象に[未登録]は使用できません。[未登録]とならないよう修正してください。';
const MAX_SELECTED_CHECK_VIEW_INDICATOR_ALLS =
  '全体評価は8件以内で選択してください。';
const MAX_SELECTED_CHECK_MEDIA_IDS = '媒体種別は5件以内で選択してください。';

export const INVALID_USERS =
  '共有ユーザー設定に誤りがあります。正しく設定してください。';

export const errorMessages = {
  [SCHEDULE]: {
    ACCEPT_VALUE_CHECK: ACCEPT_VALUE_CHECK_SCHEDULE,
  },
  [SCHEDULE_WEEKDAYS]: {
    ACCEPT_SELECTED_SCHEDULE_WEEKDAYS,
  },
  [EMAIL_TITLE]: {
    MAX_LENGTH_CHECK: MAX_EMAIL_TITLE_LENGTH,
    SPTAB_CHECK: EMAIL_TITLE_SPTAB_CHECK, // recheck
  },
  [INCLUDE_ALL]: {
    ACCEPT_VALUE_CHECK: ACCEPT_VALUE_CHECK_INCLUDE_ALL,
  },
  [VIEW_INDICATOR_ALL]: {
    MAX_SELECTED_CHECK_VIEW_INDICATOR_ALLS,
  },
  [MEDIA_IDS]: {
    MAX_SELECTED_CHECK_MEDIA_IDS,
  },
  [USERS]: {
    INVALID_USER_CHECK: INVALID_USERS,
  },
};
