import React, { useContext, useRef, useState, useEffect } from 'react';
import { GridTableContext } from 'views/organism/GridTable/GridTableContext';

const GridTableScrollbarVertical = () => {
  const {
    gridTableRef,
    settings: { heightHeader, heightRow, heightHorizontalScroll },
  } = useContext(GridTableContext);

  const [heightScroller, setHeightScroller] = useState(0);
  const [topScroller, setTopScroller] = useState(0);
  const [isHideScroller, setHideScroller] = useState(false);

  const scrollableWrapperRef = useRef(null);
  const scrollerRef = useRef(null);

  useEffect(() => {
    if (!gridTableRef?.current) return () => {};

    const scrollableWrapperEl = scrollableWrapperRef.current;
    const scrollerEl = scrollerRef.current;
    const tableScrollEl = gridTableRef.current;

    const { offsetHeight, scrollHeight } = tableScrollEl;
    const visibleRatio = offsetHeight / scrollHeight;
    const scrollerHeight =
      visibleRatio * (offsetHeight - heightHeader) - heightHorizontalScroll;

    let contentPosition = 0;
    let scrollerBeingDragged = false;
    let normalizedPosition;

    const startDrag = (e) => {
      normalizedPosition = e.pageY;
      contentPosition = tableScrollEl.scrollTop;
      scrollerBeingDragged = true;
    };

    const stopDrag = () => {
      scrollerBeingDragged = false;
    };

    const scrollBarScroll = (e) => {
      if (scrollerBeingDragged === true) {
        const mouseDifferential = e.pageY - normalizedPosition;
        const scrollEquivalent =
          mouseDifferential * (scrollHeight / offsetHeight);
        tableScrollEl.scrollTop = contentPosition + scrollEquivalent;
      }
    };

    const moveScroller = (e) => {
      if (!e) return;

      window.requestAnimationFrame(() => {
        // Move Scroll bar to top offset
        const scrollPercentage = e.target.scrollTop / e.target.scrollHeight;
        let topPosition = scrollPercentage * e.target.offsetHeight;
        topPosition -= (topPosition * heightHeader) / e.target.offsetHeight;
        setTopScroller(topPosition);
      });
    };

    const smoothScrollTo = (e) => {
      if (e && e.target.getAttribute('role') !== 'scrollbar') return;

      const offsetY =
        e.offsetY > tableScrollEl.scrollTop * visibleRatio
          ? (e.offsetY - scrollerHeight) / visibleRatio
          : e.offsetY / visibleRatio;

      tableScrollEl.scroll({ top: offsetY, behavior: 'smooth' });
    };

    moveScroller({ target: tableScrollEl });
    setHeightScroller(scrollerHeight < offsetHeight ? scrollerHeight : 0);
    setHideScroller(scrollHeight <= offsetHeight);

    // attach related draggable listeners
    scrollerEl.addEventListener('mousedown', startDrag);
    window.addEventListener('mouseup', stopDrag);
    window.addEventListener('mousemove', scrollBarScroll);
    tableScrollEl.addEventListener('scroll', moveScroller);
    scrollableWrapperEl.addEventListener('click', smoothScrollTo);

    return () => {
      scrollerEl.removeEventListener('mousedown', startDrag);
      window.removeEventListener('mouseup', stopDrag);
      window.removeEventListener('mousemove', scrollBarScroll);
      tableScrollEl.removeEventListener('scroll', moveScroller);
      scrollableWrapperEl.addEventListener('click', smoothScrollTo);
    };
  }, [gridTableRef, heightHeader, heightRow, heightHorizontalScroll]);

  return (
    <div
      ref={scrollableWrapperRef}
      className="grid-table__scrollable-wrapper"
      role="scrollbar"
      aria-orientation="vertical"
      aria-controls=""
      aria-valuenow=""
      hidden={isHideScroller}
      style={{
        bottom: heightHorizontalScroll,
        height: `calc(100% - ${heightHeader + heightHorizontalScroll + 1}px)`, // 1 => border bottom of header
      }}
    >
      <div
        ref={scrollerRef}
        className="grid-table__scroller"
        style={{
          height: heightScroller,
          willChange: 'transform',
          transform: `translateY(${topScroller}px)`,
        }}
      />
    </div>
  );
};

GridTableScrollbarVertical.propTypes = {};

GridTableScrollbarVertical.defaultProps = {};

export default React.memo(GridTableScrollbarVertical);
