import types from 'store/media-sync-management/types';
import helper from 'store/helper';

// About Table
const getDataTable = () => helper.createAction(types.FETCH);
const updateSort = (field, direction) =>
  helper.createAction(types.UPDATE_SORT, { field, direction });
const setDataTable = (data) => helper.createAction(types.SET_DATA_TABLE, data);
const setStatusTable = (status) =>
  helper.createAction(types.SET_STATUS_TABLE, status);
const requestFailedTable = () =>
  helper.createAction(types.REQUEST_FAILED_TABLE);

// About Detail
const getDataDetail = (id, callback) =>
  helper.createAction(types.GET, { id, callback });
const setDataDetail = (data) =>
  helper.createAction(types.SET_DATA_DETAIL, data);
const setStatusDetail = (status) =>
  helper.createAction(types.SET_STATUS_DETAIL, status);

// About Validate
const validate = (id, data, callback) =>
  helper.createAction(types.VALIDATE, { id, data, callback });
const setStatusValidate = (status) =>
  helper.createAction(types.SET_STATUS_VALIDATE, status);

const setStatus = (type, status) =>
  helper.createAction(types.SET_STATUS, { type, status });
const setErrors = (type, scope = null) =>
  helper.createAction(types.SET_ERRORS, { type }, scope);

// Settings
const create = (data, callback) =>
  helper.createAction(types.CREATE, { data, callback });
const update = (id, data, callback) =>
  helper.createAction(types.UPDATE, { id, data, callback });
const setStatusUpdate = (status) =>
  helper.createAction(types.SET_STATUS_UPDATE, status);

const setStatusDelete = (status) =>
  helper.createAction(types.SET_STATUS_DELETE, status);
const getMasterData = () => helper.createAction(types.GET_MASTER_DATA);

const setStatusAccount = (status) =>
  helper.createAction(types.SET_STATUS_ACCOUNT, { status });

const getStatusAccount = (callback) =>
  helper.createAction(types.GET_STATUS_ACCOUNT, { callback });

const issueAccount = () => helper.createAction(types.ISSUE_ACCOUNT);
const deleteAccount = (data, callback) =>
  helper.createAction(types.DELETE_ACCOUNT, { data, callback });
const changeStatus = (requestObj, callback) =>
  helper.createAction(types.CHANGE_STATUS, { requestObj, callback });
const setStatusStop = (status) =>
  helper.createAction(types.SET_STATUS_STOP, status);

// setting ad account
// get auth Url
const generateAuthUrl = (data, callback) =>
  helper.createAction(types.GENERATE_AUTH_URL, { data, callback });
// get list ad
const getListAdvertiser = (
  { syncMediaId, data, isEdit, advertiserId, id },
  callback
) =>
  helper.createAction(types.GET_LIST_ADVERTISER, {
    syncMediaId,
    data,
    isEdit,
    advertiserId,
    callback,
    id,
  });
const setListAdvertiser = (data, account) =>
  helper.createAction(types.SET_LIST_ADVERTISER, { data, account });

// get list margin
const getMargins = (id, data, callback) =>
  helper.createAction(types.GET_MARGINS, { id, data, callback });
const setMargins = (data) => helper.createAction(types.SET_MARGINS, { data });

// action for verify ad id registered
const verifyAdvertiserIdRegistered = (data, handleResponse) =>
  helper.createAction(types.VERIFY_ADVERTISER_ID_REGISTERED, {
    data,
    handleResponse,
  });

// authentication Line
const setStatusAuthLine = (status) =>
  helper.createAction(types.SET_STATUS_AUTH_LINE, status);
const checkAdvertiserId = (data, handleResponse) =>
  helper.createAction(types.CHECK_ADVERTISER_ID, {
    data,
    handleResponse,
  });

const sendLinkAuthLine = (data, handleResponse) =>
  helper.createAction(types.SEND_LINK_AUTH_LINE, {
    data,
    handleResponse,
  });

const verifyClientApproval = (data, handleResponse) =>
  helper.createAction(types.VERIFY_CLIENT_APPROVAL, {
    data,
    handleResponse,
  });

const verifyDowloadedImage = (data, handleResponse) =>
  helper.createAction(types.VERIFY_DOWLOADED_IMAGE, {
    data,
    handleResponse,
  });

const verifyPasswordLine = (data, handleResponse) =>
  helper.createAction(types.VERIFY_PASSWORD_LINE, {
    data,
    handleResponse,
  });

export default {
  getDataTable,
  updateSort,
  setDataTable,
  setStatusTable,
  requestFailedTable,

  getDataDetail,
  setDataDetail,
  setStatusDetail,
  setStatusDelete,
  validate,
  setStatusValidate,

  setStatus,
  setErrors,

  create,
  setStatusAccount,
  issueAccount,
  deleteAccount,
  update,
  setStatusUpdate,
  getMasterData,
  getStatusAccount,

  getListAdvertiser,
  setListAdvertiser,

  getMargins,
  setMargins,
  generateAuthUrl,
  setStatusAuthLine,
  checkAdvertiserId,
  sendLinkAuthLine,
  verifyClientApproval,
  verifyDowloadedImage,
  changeStatus,
  setStatusStop,
  verifyPasswordLine,
  verifyAdvertiserIdRegistered,
};
