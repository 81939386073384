// async actions
const REQUEST_START = 'csv-setting/REQUEST_START';
const REQUEST_FAILED = 'csv-setting/REQUEST_FAILED';
const FETCH = 'csv-setting/FETCH';
const CREATE = 'csv-setting/CREATE';
// sync actions
const FETCH_SUCCEEDED = 'csv-setting/FETCH_SUCCEEDED';
const CREATE_SUCCEEDED = 'csv-setting/CREATE_SUCCEEDED';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  FETCH,
  CREATE,
  FETCH_SUCCEEDED,
  CREATE_SUCCEEDED,
};
