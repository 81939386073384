import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  CATEGORY_ANALYZE,
  COMPARE_PERIOD,
  COST_ALLOCATION,
} from 'services/routes/constants';
import { dateRangeOf, getToday, isValidDate } from 'services/utils';
import { CPA, AD_COST, CNT_ACCESS } from 'domain/settings/display-items';
import {
  API_DATE_FORMAT,
  DASHBOARD_ANALYSIS_TYPE,
  DATE_RANGE_TYPE,
  UNREGISTERED_IDS,
} from 'domain/consts';

/**
 * Returns an array of dates between the two dates
 * @param {string} startDate Format: YYYY-MM-DD, YYYY/MM/DD
 * @param {string} endDate Format: YYYY-MM-DD, YYYY/MM/DD
 * @return {array}
 */
const enumerateDaysBetweenDates = (startDate, endDate) => {
  if (!isValidDate(startDate) || !isValidDate(endDate)) return [];

  const dates = [];
  const now = moment(startDate);

  while (now.isBefore(moment(endDate)) || now.isSame(moment(endDate))) {
    dates.push(now.format('YYYY-MM-DD'));
    now.add(1, 'days');
  }

  return dates;
};

/**
 * Get pickup name
 * @param {string} pickupId
 * @param {object} metadata
 * @return {string}
 */
const getMetricDisplayName = (pickupId, metadata) => {
  if (isEmpty(pickupId) || isEmpty(metadata)) return '';

  const [metricId, convId] = pickupId.split('__');
  const { metric_name: metricName } = metadata.metrics.find(
    (metric) => metric.metric_id === metricId
  );

  let convName = '';
  if (!isEmpty(convId)) {
    const conversion = metadata.conversions.find(
      (item) => item.conv_id === +convId
    );
    convName = conversion?.conv_name || '';
  }

  let pickupName = `${metricName} (${convName})`;
  if (+convId === 0) {
    pickupName = `${convName}の${metricName}`;
  } else if ([AD_COST, CNT_ACCESS].includes(metricId)) {
    pickupName = metricName;
  }

  return pickupName;
};

/**
 * @param {string} metricId
 * @param {number} value
 * @param {object} kpiStatus
 * @return {string}
 */
const getKpiStatus = (metricId, value, kpiStatus) => {
  if (metricId === CPA) {
    if (value <= kpiStatus[metricId].good) {
      return 'good';
    }
    if (value <= kpiStatus[metricId].normal) {
      return 'normal';
    }
    return 'bad';
  }
  if (metricId === AD_COST) {
    if (value > kpiStatus[metricId].good || value <= kpiStatus[metricId].bad) {
      return 'bad';
    }
    if (value <= kpiStatus[metricId].normal) {
      return 'normal';
    }
    return 'good';
  }
  // Other metricId
  if (value <= kpiStatus[metricId].bad) {
    return 'bad';
  }
  if (value <= kpiStatus[metricId].normal) {
    return 'normal';
  }
  return 'good';
};

/**
 * @param {string} start Format: YYYY-MM-DD
 * @param {string} end Format: YYYY-MM-DD
 * @return {null}
 */
const getCurrentPresetForCalendarUpdate = ({ start, end }) => {
  let currentPeriodPreset = null;
  if (start && end) {
    // Handle special case. Should update preset to THIS_MONTH_EXCLUDED to avoid conflict with yesterday / today case
    const { startDate, endDate } = dateRangeOf(
      DATE_RANGE_TYPE.THIS_MONTH_EXCLUDED
    );
    if (
      startDate.isSame(moment(start), 'date') &&
      endDate.isSame(moment(end), 'date')
    ) {
      currentPeriodPreset = DATE_RANGE_TYPE.THIS_MONTH_EXCLUDED;
    }
  }
  return currentPeriodPreset;
};

const buildAnalysisOptions = (
  period,
  comparedPeriod,
  targetScreen,
  items,
  filters = {}
) => {
  return {
    targetScreen,
    period: {
      ...period,
      start: moment(period.start),
      end: moment(period.end),
    },
    comparedPeriod: {
      ...comparedPeriod,
      start: (comparedPeriod.start && moment(comparedPeriod.start)) || null,
      end: (comparedPeriod.end && moment(comparedPeriod.end)) || null,
    },
    items,
    filters,
  };
};

const buildPerMediaAnalysisOptions = ({ start, end }) => {
  const preset = DATE_RANGE_TYPE.CUSTOM;
  const items = {
    category: true,
    ad_group1: false,
    ad_group2: false,
  };
  const filters = {};
  return buildAnalysisOptions(
    { start, end, preset },
    { enabled: false, start: null, end: null, preset: null },
    CATEGORY_ANALYZE,
    items,
    filters
  );
};

const buildPerMediaLast7DaysAnalysisOptions = () => {
  const currentPreset = DATE_RANGE_TYPE.CUSTOM;
  const comparedPreset = DATE_RANGE_TYPE.LAST_PERIOD;
  const currentPeriod = dateRangeOf(DATE_RANGE_TYPE.DAYS_AGO_7_EXCLUDED);
  const comparedPeriod = dateRangeOf(comparedPreset, currentPeriod);
  const filters = {};
  const items = {
    category: true,
    ad_group1: false,
    ad_group2: false,
    date: false,
  };

  const targetScreen = COMPARE_PERIOD;
  return buildAnalysisOptions(
    {
      start: currentPeriod.startDate,
      end: currentPeriod.endDate,
      preset: currentPreset,
    },
    {
      enabled: true,
      start: comparedPeriod.startDate.format(API_DATE_FORMAT),
      end: comparedPeriod.endDate.format(API_DATE_FORMAT),
      preset: comparedPreset,
    },
    targetScreen,
    items,
    filters
  );
};

const buildTransitionCurrentMonthAnalysisOptions = ({ start, end }) => {
  const currentPreset = DATE_RANGE_TYPE.CUSTOM;
  const targetScreen = COMPARE_PERIOD;
  const filters = {};
  const items = {
    category: true,
    ad_group1: false,
    ad_group2: false,
    date: false,
  };

  return buildAnalysisOptions(
    {
      start,
      end,
      preset: currentPreset,
    },
    { enabled: false, start: null, end: null, preset: null },
    targetScreen,
    items,
    filters
  );
};

const formatFilters = (media) => {
  if (!media) return {};

  const values = [media.media_name];
  const ids = [media.media_id];
  if (media.others.length > 0) {
    media.others.forEach((item) => {
      values.push(item.media_name);
      ids.push(item.media_id);
    });
  }

  return {
    media_id: {
      type: 1,
      values,
      ids: ids.map((id) => String(UNREGISTERED_IDS.includes(id) ? 0 : id)),
      option: null,
      title: '媒体種別',
      order: 1,
    },
  };
};

const buildChangedFactorsAnalysisOptions = ({ start, end }, filters) => {
  const currentPreset = DATE_RANGE_TYPE.CUSTOM;
  let comparedPreset;
  if (moment(start).month() === getToday().month()) {
    comparedPreset = DATE_RANGE_TYPE.LAST_PERIOD;
  } else {
    comparedPreset = DATE_RANGE_TYPE.LAST_MONTH_THIS_PERIOD;
  }

  const comparedPeriod = dateRangeOf(comparedPreset, {
    startDate: moment(start),
    endDate: moment(end),
  });
  const targetScreen = CATEGORY_ANALYZE;
  const items = {
    category: true,
    ad_group1: true,
    ad_group2: false,
  };

  const formattedFilters = formatFilters(filters);

  return buildAnalysisOptions(
    { start, end, preset: currentPreset },
    {
      enabled: true,
      start: comparedPeriod.startDate.format(API_DATE_FORMAT),
      end: comparedPeriod.endDate.format(API_DATE_FORMAT),
      preset: comparedPreset,
    },
    targetScreen,
    items,
    formattedFilters
  );
};

const buildCostAllocationReviewAnalysisOptions = ({ start, end }, filters) => {
  const currentPreset = DATE_RANGE_TYPE.CUSTOM;
  const targetScreen = COST_ALLOCATION;
  const items = {
    category: true,
    ad_group1: true,
    ad_group2: false,
  };
  const formattedFilters = formatFilters(filters);
  return buildAnalysisOptions(
    { start, end, preset: currentPreset },
    { enabled: false, start: null, end: null, preset: null },
    targetScreen,
    items,
    formattedFilters
  );
};

const buildOtherChangesAnalysisOptions = ({ start, end }) => {
  const currentPreset = DATE_RANGE_TYPE.CUSTOM;
  const targetScreen = CATEGORY_ANALYZE;
  const filters = {};
  const items = {
    category: true,
    ad_group1: false,
    ad_group2: false,
  };

  return buildAnalysisOptions(
    { start, end, preset: currentPreset },
    { enabled: false, start: null, end: null, preset: null },
    targetScreen,
    items,
    filters
  );
};

/**
 *
 * @param {(DASHBOARD_ANALYSIS_TYPE.PER_MEDIA,
 * DASHBOARD_ANALYSIS_TYPE.PER_MEDIA_LAST_7_DAYS,
 * DASHBOARD_ANALYSIS_TYPE.TRANSITIONS_PER_MEDIA_CURRENT_MONTH,
 * DASHBOARD_ANALYSIS_TYPE.SEARCH_CHANGED_FACTORS,
 * DASHBOARD_ANALYSIS_TYPE.COST_ALLOCATION_REVIEW,
 * DASHBOARD_ANALYSIS_TYPE.OTHER_CHANGES)} type
 * @param start
 * @param end
 * @param analysisOptions
 * @return {{targetScreen, period, comparedPeriod, filters, items}}
 */
const getAnalysisOptions = (type, { start, end }, analysisOptions = {}) => {
  const {
    PER_MEDIA,
    PER_MEDIA_LAST_7_DAYS,
    OTHER_CHANGES,
    COST_ALLOCATION_REVIEW,
    SEARCH_CHANGED_FACTORS,
    TRANSITIONS_PER_MEDIA_CURRENT_MONTH,
  } = DASHBOARD_ANALYSIS_TYPE;
  let options;
  switch (type) {
    case PER_MEDIA:
      options = buildPerMediaAnalysisOptions({ start, end });
      break;
    case PER_MEDIA_LAST_7_DAYS:
      options = buildPerMediaLast7DaysAnalysisOptions();
      break;
    case TRANSITIONS_PER_MEDIA_CURRENT_MONTH:
      options = buildTransitionCurrentMonthAnalysisOptions({ start, end });
      break;
    case SEARCH_CHANGED_FACTORS: {
      const { filters } = analysisOptions;
      options = buildChangedFactorsAnalysisOptions(
        {
          start,
          end,
        },
        filters
      );
      break;
    }
    case COST_ALLOCATION_REVIEW: {
      const { filters } = analysisOptions;
      options = buildCostAllocationReviewAnalysisOptions(
        {
          start,
          end,
        },
        filters
      );
      break;
    }
    case OTHER_CHANGES:
      options = buildOtherChangesAnalysisOptions({ start, end });
      break;
    default:
      throw new Error('Invalid analysis option specified');
  }

  return options;
};

export const DashboardServiceFactory = () => ({
  enumerateDaysBetweenDates,
  getMetricDisplayName,
  getKpiStatus,
  getCurrentPresetForCalendarUpdate,
  getAnalysisOptions,
});

const DashboardService = DashboardServiceFactory();
export default DashboardService;
