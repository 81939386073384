import { func } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonSelectors } from 'store/common';
import DownloadFiles from 'views/molecules/commonbar/components/DownloadFiles';
import actions from 'store/user-profile/actions';
import selectors from 'store/user-profile/selectors';

function UserProfileDownloadFileContainer(props) {
  const { toggleActiveFunc } = props;
  const downloadConfigs = useSelector(commonSelectors.getDownloadConfigs);
  const param = useSelector(selectors.getUserProfileParameter);
  const dispatch = useDispatch();
  const downloadCsv = () => {
    dispatch(actions.downloadCsv(param));
  };
  return (
    <DownloadFiles
      downloadCsvDisabled={false}
      downloadConfigs={downloadConfigs}
      toggleActiveFunc={toggleActiveFunc}
      disabled={false}
      downloadCsv={downloadCsv}
    />
  );
}

UserProfileDownloadFileContainer.propTypes = {
  toggleActiveFunc: func.isRequired,
};

export default UserProfileDownloadFileContainer;
