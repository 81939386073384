import React from 'react';
import { oneOfType, number, string, func } from 'prop-types';
import FormInput from 'views/molecules/FormGroup/components/FormInput';

const FilterTypeEleven = (props) => {
  const { name, value, note, error, onChange } = props;

  const errorMess = (error && Object.values(error)[0]) || '';

  const handleChangeValue = (_, data) => {
    onChange(data);
  };

  return (
    <div className="filter-type__eleven">
      <FormInput
        as="textarea"
        name={name}
        value={value}
        error={errorMess}
        onChange={handleChangeValue}
      />
      {note && <div className="filter-type__note">{note}</div>}
    </div>
  );
};

FilterTypeEleven.propTypes = {
  name: string,
  note: string,
  error: string,
  value: oneOfType([string, number]),
  onChange: func,
};

FilterTypeEleven.defaultProps = {
  name: '',
  note: '',
  error: '',
  value: '',
  onChange: () => {},
};

export default React.memo(FilterTypeEleven);
