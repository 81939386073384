import helper from 'store/helper';
import types from 'store/log/route-analyze/types';

const routeAnalyzeActions = {
  getData: () => helper.createAction(types.GET_DATA),
  getSettings: () => helper.createAction(types.GET_SETTINGS),
  getPageViewBiggest: () => helper.createAction(types.GET_PAGE_VIEW_BIGGEST),

  setData: (data) => helper.createAction(types.SET_DATA, { data }),
  setStatus: (type, status) =>
    helper.createAction(types.SET_STATUS, { type, status }),
  setSettings: (settings) =>
    helper.createAction(types.SET_SETTINGS, { settings }),
  setErrors: (type, errors, scope = null) =>
    helper.createAction(types.SET_ERRORS, { type, errors }, scope),

  setDisplayToturial: () => helper.createAction(types.SET_DISPLAY_TOTURIAL),

  setMasterdata: (type, data) =>
    helper.createAction(types.SET_MASTERDATA, { type, data }),
  setStatusMasterdata: (type, status) =>
    helper.createAction(types.SET_STATUS_MASTERDATA, { type, status }),
  searchMasterdata: ({ type, value, field = '' }) =>
    helper.createAction(types.SEARCH_MASTERDATA, { type, value, field }),

  changeSettings: (settings) =>
    helper.createAction(types.CHANGE_SETTINGS, { settings }),
  changePage: (page) => helper.createAction(types.CHANGE_PAGE, { page }),

  downloadCsv: () => helper.createAction(types.DOWNLOAD_CSV),
};

export default routeAnalyzeActions;
