import React from 'react';
import PropTypes from 'prop-types';
import ViewSelectModal from 'views/molecules/modal/ViewSelectModal';

// Initially created as an overwritten version of DeleteModal.jsx
// TODO: Call ViewSelectModal.jsx directly from CustomViewDropdown.jsx and delete this file (no need to overwrite generic modal)
const ViewOverwriteModal = ({ isShow, title, content, onHide, onSubmit, loading }) => (
  <ViewSelectModal
    isShow={isShow}
    isLoading={loading}
    title={title}
    content={content}
    onHide={onHide}
    onSubmit={onSubmit}
    cancelBtn
    confirmText="OK"
    isContentDropdown
  />
);

ViewOverwriteModal.propTypes = {
  isShow: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
ViewOverwriteModal.defaultProps = {
  isShow: false,
  loading: false,
  content: undefined,
};

export default React.memo(ViewOverwriteModal);
