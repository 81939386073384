/* eslint-disable react/no-danger */
import React, { useState, useRef } from 'react';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import timeoutNotify from 'domain/timeout-notify';
import logging from 'domain/logging';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import './confirm-modal.scss';
import './view-select-modal.scss';

// Initially created as a general-purpose modal based on ConfirmModal.jsx
const ViewSelectModal = ({
  isShow,
  isLoading,
  title,
  modalWidth,
  content,
  jsxContent,
  onHide,
  onSubmit,
  cancelBtn,
  confirmBtnType,
  confirmText,
  cancelText,
  preventClickOutside,
  styles,
  maxContent,
}) => {
  const [selected, setSelected] = useState(0);
  const scrollbarWrapperRef = useRef(null);

  const handleSelect = (value) => {
    setSelected(value);
  };

  let jsxContentBody = jsxContent;
  if (timeoutNotify.count()) {
    timeoutNotify.reset();
    jsxContentBody =
      'タイムアウトが発生しました。お手数ですが再度お試しください。';
    logging.info('timeoutNotify', jsxContentBody);
  }

  const modalClass = classNames({
    'confirm-modal': true,
    'confirm-modal--loading': isLoading,
  });

  return (
    <Modal
      backdrop={preventClickOutside ? 'static' : true}
      show={isShow}
      centered
      size={modalWidth}
      className={modalClass}
    >
      <Modal.Header className={styles?.modalHeader}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={isLoading}>
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle
              className="selectLabel"
              variant="select"
              disabled={content.length === 0}
            >
              <div className="selectLabel-text">
                {selected in content ? content[selected].name : ''}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="selectMenu"
            >
              <div ref={scrollbarWrapperRef}>
                <ScrollbarWrapper
                  ref={scrollbarWrapperRef}
                  maxContent={maxContent}
                >
                  {Object.keys(content).map((id, name) => (
                        <Dropdown.Item key={id} eventKey={id} active={id === selected}>
                          {content[id].name}
                        </Dropdown.Item>
                  ))}
                </ScrollbarWrapper>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        {cancelBtn && (
          <Button
            size="sm"
            variant="link"
            disabled={isLoading}
            onClick={() => onHide(!isShow)}
          >
            {cancelText}
          </Button>
        )}
        <Button
          size="sm"
          variant={confirmBtnType}
          disabled={isLoading}
          onClick={() => onSubmit(content[selected])}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ViewSelectModal.defaultProps = {
  isShow: false,
  isLoading: false,
  modalWidth: 'w600',
  cancelBtn: true,
  preventClickOutside: true,
  confirmBtnType: 'secondary',
  confirmText: '削除',
  cancelText: 'キャンセル',
  jsxContent: false,
  onHide: () => {},
  content: '',
  styles: {
    modalHeader: '',
  },
  maxContent: 268,
};

ViewSelectModal.propTypes = {
  isShow: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  modalWidth: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onHide: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  cancelBtn: PropTypes.bool,
  preventClickOutside: PropTypes.bool,
  confirmBtnType: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  jsxContent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  styles: PropTypes.shape({
    modalHeader: PropTypes.string,
  }),
  maxContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ViewSelectModal;
