import classNames from 'classnames';
import { PRIORITY_AXIS_EBIS, PRIORITY_AXIS_MEDIA_SYNC } from 'domain/consts';
import React from 'react';
import PropTypes from 'prop-types';
import './PriorityAxisBar.scss';
import { DisplayItemsSelectors } from 'store/display-items';
import { shallowEqual, useSelector } from 'react-redux';

const PriorityAxisBar = ({ priorityAxis }) => {
  const containerClassName = classNames({
    clearfix: true,
    'priority-axis__container': true,
    'priority-axis--ebis': priorityAxis === PRIORITY_AXIS_EBIS,
    'priority-axis--sync': priorityAxis === PRIORITY_AXIS_MEDIA_SYNC,
  });
  const messageClassName = classNames({
    'priority-axis__message': true,
    'priority-axis__message--ebis': priorityAxis === PRIORITY_AXIS_EBIS,
    'priority-axis__message--sync': priorityAxis === PRIORITY_AXIS_MEDIA_SYNC,
  });
  const isEnableMediaSync = useSelector(
    DisplayItemsSelectors.hasContractMediaSync,
    shallowEqual
  );
  return isEnableMediaSync ? (
    <div id="priority-axis-bar" className={containerClassName}>
      <div className={messageClassName} style={{ top: '0px' }}>
        {priorityAxis === PRIORITY_AXIS_MEDIA_SYNC
          ? '集計軸：媒体項目で集計'
          : '集計軸：アドエビス項目で集計'}
      </div>
    </div>
  ) : null;
};

PriorityAxisBar.defaultProps = {
  priorityAxis: PRIORITY_AXIS_MEDIA_SYNC,
};
PriorityAxisBar.propTypes = {
  priorityAxis: PropTypes.string,
};
export default PriorityAxisBar;
