import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const withGridTablePage = (Component) => {
  const WrappedComponent = (props) => {
    const { top: scrollTopDefault } = props;
    const [scrollTop, setScrollTop] = useState(scrollTopDefault);

    useEffect(() => {
      const topbarEL = document.getElementById('top-bar');
      const commonBarEl = document.getElementById('common-bar');
      const freezeHeadAreaEl = document.getElementById('freeze-head-area');
      const mainContentEl = document.getElementById('main-content');

      if (!mainContentEl) return () => {};

      let heightPagination = 0;
      if (freezeHeadAreaEl) {
        heightPagination = freezeHeadAreaEl.offsetHeight;
        freezeHeadAreaEl.style.top = `${scrollTop - heightPagination}px`;
      }

      const defaultTop = topbarEL.offsetHeight + heightPagination;

      const heightObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (
            Array.isArray(entries) &&
            entries.length &&
            entries[0].contentRect.height !== scrollTop - defaultTop
          ) {
            setScrollTop(defaultTop + commonBarEl.offsetHeight);
          }
        });
      });

      heightObserver.observe(commonBarEl);
      return () => {
        heightObserver.unobserve(commonBarEl);
      };
    }, [scrollTop]);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component {...props} top={scrollTop} />
    );
  };

  WrappedComponent.propTypes = {
    top: PropTypes.number,
    width: PropTypes.number,
  };

  WrappedComponent.defaultProps = {
    top: 160,
    width: 0,
  };

  return WrappedComponent;
};

export default withGridTablePage;
