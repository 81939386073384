import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { EXPORT_TYPE, CSV_MAX_DOWNLOADABLE_RECORDS } from 'domain/consts';
import useDisplay from 'services/custom-hooks/useDisplay';
import { formatNumber } from 'domain/utils';
import TooltipWrapper from 'views/molecules/conditional-tooltip/TooltipWrapper';
import CsvSettingModal from 'views/organism/CsvSettingModal';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { SYSTEM_SETTING_MEASUREMENT } from 'services/routes/constants';

const DownloadCsvMenuItem = ({
  downloadCsvText,
  downloadCsvDisabled,
  downloadCsv,
  isOverData,
  isAllData,
  isShowExportCsvClickIdSetting,
}) => {
  const { displayTooltip } = useDisplay();

  const bsPrefix = downloadCsvDisabled
    ? 'dropdown-item ebis--disabled'
    : 'dropdown-item';

  if (downloadCsvDisabled && isOverData) {
    return (
      <TooltipWrapper
        placement="bottom"
        tooltip={
          <Tooltip variant="disabled" placement="bottom">
            {displayTooltip('csv_download_max_records').replace(
              'CSV_MAX_DOWNLOADABLE_RECORDS',
              formatNumber(CSV_MAX_DOWNLOADABLE_RECORDS)
            )}
          </Tooltip>
        }
      >
        {isAllData ? (
          <Dropdown.Item
            onClick={() => !downloadCsvDisabled && downloadCsv(isAllData)}
            bsPrefix={
              downloadCsvDisabled
                ? 'dropdown-item ebis--disabled'
                : 'dropdown-item'
            }
          >
            全データを出力（CSV）
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            onClick={() => !downloadCsvDisabled && downloadCsv()}
            bsPrefix={
              downloadCsvDisabled
                ? 'dropdown-item ebis--disabled'
                : 'dropdown-item'
            }
          >
            表を出力（CSV）
          </Dropdown.Item>
        )}
      </TooltipWrapper>
    );
  }

  const iconClass = classNames({
    fas: true,
    'fa-question-circle': true,
    dragonball: true,
  });

  return (
    <>
      {isAllData ? (
        <Dropdown.Item
          bsPrefix={bsPrefix}
          onClick={() => !downloadCsvDisabled && downloadCsv(isAllData)}
        >
          {downloadCsvText}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="dragonball-tooltip variant-base">
                媒体項目とアドエビス項目を紐づけたデータを出力します
              </Tooltip>
            }
          >
            <div className={iconClass} />
          </OverlayTrigger>
        </Dropdown.Item>
      ) : (
        <Dropdown.Item
          onClick={() => !downloadCsvDisabled && downloadCsv()}
          bsPrefix={bsPrefix}
        >
          {downloadCsvText}
        </Dropdown.Item>
      )}
      {isShowExportCsvClickIdSetting && (
        <Dropdown.Item
          onClick={() =>
            !downloadCsvDisabled &&
            downloadCsv(false, isShowExportCsvClickIdSetting)
          }
          bsPrefix={
            downloadCsvDisabled
              ? 'dropdown-item ebis--disabled'
              : 'dropdown-item'
          }
        >
          <div className="click-id-csv-wrap">
            <span className="click-id-csv break-line">
              媒体クリックID等を含む <br />
              <span className="break-line">表を出力（CSV）</span>
            </span>
            <DragonBall placement="top" stayOpen>
              媒体クリックID等を出力する対象媒体を変更する場合は
              <Link to={`/${SYSTEM_SETTING_MEASUREMENT}`}>こちら</Link>
            </DragonBall>
          </div>
        </Dropdown.Item>
      )}
    </>
  );
};

DownloadCsvMenuItem.propTypes = {
  downloadCsv: PropTypes.func,
  downloadCsvText: PropTypes.string.isRequired,
  downloadCsvDisabled: PropTypes.bool.isRequired,
  isOverData: PropTypes.bool,
  isAllData: PropTypes.bool,
  isShowExportCsvClickIdSetting: PropTypes.bool,
};

DownloadCsvMenuItem.defaultProps = {
  downloadCsv: () => {},
  isOverData: false,
  isAllData: false,
  isShowExportCsvClickIdSetting: false,
};

const DropdownItem = ({ text, disabled, tooltip, onClick }) => {
  const cls = classNames({
    'dropdown-item': true,
    'disabled-event-tooltip': disabled,
  });

  const handleClick = (e) => {
    if (disabled) {
      e.closest('.dropdown').stopPropagation();
      e.closest('.dropdown').preventDefault();
    } else {
      onClick();
    }
  };

  return (
    <OverlayTrigger
      placement="bottom"
      trigger={disabled && tooltip ? ['hover'] : ''}
      overlay={
        <Tooltip variant="disabled" placement="bottom">
          {tooltip}
        </Tooltip>
      }
    >
      <Dropdown.Item onClick={handleClick} bsPrefix={cls}>
        {text}
      </Dropdown.Item>
    </OverlayTrigger>
  );
};

DropdownItem.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

DropdownItem.defaultProps = {
  tooltip: '',
};

function DownloadFiles({
  downloadImage,
  downloadCsv,
  downloadCsvDisabled,
  downloadImageDisabled,
  allDataExportEnabled,
  downloadConfigs,
  downloadCsvOptions,
  variant,
  disabled,
  onActive = false,
  isShowExportCsvClickIdSetting,
}) {
  const [iconToggle, toggleIcon] = useState(false);
  const [csvSettingModalShow, toggleCsvSettingModal] = useState(false);

  const { csv, png, jpeg, allcsv } = downloadConfigs;

  const {
    disabled: imageDisabled,
    tooltip: imageTooltip,
  } = downloadImageDisabled;

  const downloadClass = classNames({
    'icon-fontawesome': true,
    [variant]: !isEmpty(variant),
  });

  const handleCreateNew = (e) => {
    e.preventDefault();
    toggleIcon(true);
    toggleCsvSettingModal(true);
  };

  const handleToggleViewMenu = (isShow) => {
    toggleIcon(isShow);
    if (isShow) {
      onActive({ target: 'DownloadFiles' });
    }
  };

  const handleHideModal = useCallback(() => {
    toggleCsvSettingModal(false);
    toggleIcon(true);
  }, []);

  return (
    <>
      <Dropdown
        as="div"
        role="button"
        bsPrefix="dropdown nav-link nav-link--icon"
        className={`${iconToggle ? 'active' : ''}`}
        disabled={disabled}
        onToggle={handleToggleViewMenu}
        show={iconToggle}
      >
        <Dropdown.Toggle
          as="div"
          bsPrefix={`${
            iconToggle ? 'nav-link-export active' : 'nav-link-export'
          }`}
        >
          <span className={downloadClass}>
            <i
              className="far fa-arrow-to-bottom"
              aria-hidden="true"
              active={iconToggle.toString()}
            />
          </span>
          <div>エクスポート</div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          alignRight="true"
          className="dropdown-menu-padding dropdown-menu-shadow"
        >
          {downloadCsvOptions
            .filter((option) => !option.isHidden)
            .map((option, index) => {
              const key = `option-download${index}`;
              const {
                downloadCsvText,
                downloadCsv: onDownloadCsv,
                downloadCsvDisabled: optionDownloadCsvDisabled = false,
                isOverData: optionIsOverData = false,
              } = option;
              const disabledOption = optionDownloadCsvDisabled || !csv.enabled;

              return (
                <DownloadCsvMenuItem
                  key={key}
                  downloadCsv={onDownloadCsv}
                  downloadCsvText={downloadCsvText}
                  downloadCsvDisabled={disabledOption}
                  isOverData={optionIsOverData}
                />
              );
            })}
          {csv.status && downloadCsvOptions.length <= 0 && (
            <DownloadCsvMenuItem
              downloadCsvText="表を出力（CSV）"
              downloadCsvDisabled={!csv.enabled || downloadCsvDisabled}
              downloadCsv={downloadCsv}
              isOverData={downloadCsvDisabled}
              isShowExportCsvClickIdSetting={isShowExportCsvClickIdSetting}
            />
          )}
          {png.status && (
            <DropdownItem
              text="グラフを出力（PNG）"
              disabled={!png.enabled || imageDisabled}
              tooltip={imageDisabled && imageTooltip}
              onClick={() => downloadImage(EXPORT_TYPE.PNG)}
            />
          )}
          {jpeg.status && (
            <DropdownItem
              text="グラフを出力（JPEG）"
              disabled={!jpeg.enabled || imageDisabled}
              tooltip={imageDisabled && imageTooltip}
              onClick={() => downloadImage(EXPORT_TYPE.JPEG)}
            />
          )}
          {allcsv.status && allDataExportEnabled && (
            <>
              <Dropdown.Divider />
              <DownloadCsvMenuItem
                isAllData
                downloadCsvText="全データを出力（CSV）"
                downloadCsvDisabled={!allcsv.enabled || downloadCsvDisabled}
                downloadCsv={downloadCsv}
                isOverData={downloadCsvDisabled}
              />
            </>
          )}
          {csv.status && downloadCsvOptions.length <= 0 && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item as="div" className="link-text">
                <Button
                  variant="ghost"
                  size="sm"
                  className="csv-setting"
                  onClick={(e) => {
                    handleCreateNew(e);
                  }}
                >
                  CSV出力設定
                </Button>
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {csvSettingModalShow && <CsvSettingModal onHide={handleHideModal} />}
    </>
  );
}

DownloadFiles.propTypes = {
  onActive: PropTypes.func,
  downloadImage: PropTypes.func,
  downloadCsv: PropTypes.func,
  allDataExportEnabled: PropTypes.bool,
  downloadCsvDisabled: PropTypes.bool.isRequired,
  downloadImageDisabled: PropTypes.arrayOf(PropTypes.shape({})),
  downloadCsvOptions: PropTypes.arrayOf(PropTypes.shape({})),
  isShowExportCsvClickIdSetting: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  downloadConfigs: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['sub-icon-settings']),
  disabled: PropTypes.bool,
};

DownloadFiles.defaultProps = {
  onActive: () => {},
  downloadImage: () => {},
  downloadCsv: () => {},
  downloadImageDisabled: {},
  downloadCsvOptions: [],
  variant: '',
  disabled: false,
  allDataExportEnabled: false,
  isShowExportCsvClickIdSetting: false,
};

export default DownloadFiles;
