const { useEffect } = require('react');

const useHoverDropdown = (ref, callback) => {
  const el = ref.current;

  const handleMouseOver = () => {
    callback(true);
  };

  const handelMouseOut = (event) => {
    let e = event.toElement || event.relatedTarget;

    while (e && e.parentNode && e.parentNode !== window) {
      if (e.parentNode === el || e === el) {
        if (e.preventDefault) e.preventDefault();
        callback(true);
        return true;
      }
      e = e.parentNode;
    }
    callback(false);
    return false;
  };

  useEffect(() => {
    if (!el) return () => {};

    el.addEventListener('mouseover', handleMouseOver);
    el.addEventListener('mouseout', handelMouseOut);
    return () => {
      el.removeEventListener('mouseover', handleMouseOver);
      el.removeEventListener('mouseout', handelMouseOut);
    };
  });
};

export default useHoverDropdown;
