import { SettingsApi as Api } from 'services/api/Api';
import isEmpty from 'lodash/isEmpty';
import pages from 'services/routes/pages';
import { TAG_MANAGEMENT } from 'services/routes/constants';
import apiUtils from 'services/apiUtils';
import { saveAs } from 'services/utils';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';
import { MASTER_DATA_FILTER_LIMIT, CSV_DOWNLOAD_TYPE } from 'domain/consts';
import { buildSearchMasterDataParams } from 'domain/utils';

const PATH_BY_TAB = {
  [TAG_MANAGEMENT_TAB.CV]: 'cv',
  [TAG_MANAGEMENT_TAB.PV]: 'pv',
  [TAG_MANAGEMENT_TAB.CONTENT_CATEGORY]: 'content-category',
};

const TagManagementApiFactory = () => {
  const url = pages[TAG_MANAGEMENT].endpoint;

  const validateCsvContentCategory = async (fileName) => {
    const urlSubmit = `${url}/content-category/validate`;
    return Api.requestAsync(urlSubmit, { csv_file: fileName }, 'POST', {
      pollTokenKey: 'task',
      pollUrlFormat: `${urlSubmit}/{task}`,
    });
  };

  const submitCsv = async (tab, filename) => {
    const urlSubmitCsv = `${url}/${PATH_BY_TAB[tab]}/csv-import`;
    const response = await Api.requestAsync(
      urlSubmitCsv,
      {
        csv_file: filename,
      },
      'POST',
      {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlSubmitCsv}/{task}`,
      }
    );

    return response;
  };

  return {
    getList: async (tab, request) =>
      Api.post(`${url}/${PATH_BY_TAB[tab]}/list`, request),
    getDetail: async (tab, id) =>
      Api.get(`${url}/${PATH_BY_TAB[tab]}/${encodeURIComponent(id)}`),
    create: async (tab, data) => Api.post(`${url}/${PATH_BY_TAB[tab]}`, data),
    update: async (tab, id, data) =>
      Api.put(`${url}/${PATH_BY_TAB[tab]}/${encodeURIComponent(id)}`, data),
    delete: async (tab, ids) => {
      const queryObj =
        tab === TAG_MANAGEMENT_TAB.CONTENT_CATEGORY
          ? {
              cat_ids: ids,
            }
          : {
              page_ids: ids,
            };
      return Api.delete(
        apiUtils.buildUrlWithQueryStrings(
          `${url}/${PATH_BY_TAB[tab]}`,
          queryObj
        )
      );
    },
    getFileDataJson: async (changeUrl) => Api.get(`${changeUrl}`),
    validateCsvContentCategory,
    submitCsv,
    getContentCategory: async (
      search = '',
      selected = [],
      offset = 0,
      limit = MASTER_DATA_FILTER_LIMIT
    ) =>
      Api.get(
        `${url}/content-category`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      ),
    updateRankPriorityContentCategory: async (originData, data) =>
      Api.put(`${url}/content-category`, {
        origin_data: originData,
        update_data: data,
      }),
    downloadCsv: async (tab, type, sort, filters, ids) => {
      let parts = [url, PATH_BY_TAB[tab], type, 'csv'];
      const request = { sort, filters };

      if (type === CSV_DOWNLOAD_TYPE.CONTENT_CATEGORY) {
        parts = [url, PATH_BY_TAB[tab], 'csv'];
        request.type = 'content-category';
        request.sort = 'rank';
        request.filters = [];
      }

      const urlCsv = parts.filter((part) => !!part).join('/');

      if (type === CSV_DOWNLOAD_TYPE.VARIOUS_TAGS) {
        request.filters = isEmpty(ids)
          ? []
          : [{ field: 'page_id', operator: 3, value: ids }];
      }

      const response = await Api.requestAsync(urlCsv, request, 'POST', {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlCsv}/{task}`,
      });
      const { location } = response.data.data;
      if (location) {
        saveAs(location);
      }
    },
  };
};

const TagManagementApi = TagManagementApiFactory();
export default TagManagementApi;
