import { useSelector, shallowEqual } from 'react-redux';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import {
  MEDIA_SYNC_MANAGEMENT_EDIT,
  MEDIA_SYNC_MANAGEMENT_VIEW,
} from 'domain/permissions/contractPermissions';
import { getPermissions } from 'store/auth/selectors';

const useCheckPermissionMediaSync = () => {
  const { checkPermissions } = useCheckPermissions();
  const permissions = useSelector(getPermissions, shallowEqual);

  let isSingleMediaSyncPermission = false;

  if (permissions.length <= 2) {
    isSingleMediaSyncPermission = checkPermissions('anyOf', [
      MEDIA_SYNC_MANAGEMENT_EDIT,
      MEDIA_SYNC_MANAGEMENT_VIEW,
    ]);
  }

  return {
    isSingleMediaSyncPermission,
  };
};

export default useCheckPermissionMediaSync;
