import { call, put, takeEvery, select } from 'redux-saga/effects';
import settingViewActions from 'store/customview/actions';
import { checkHasContractLog } from 'store/auth/selectors';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { FUNC_CODE_LANDING_PAGE_ANALYZE } from 'domain/settings/display-items';
import types from './types';
import actions from './actions';

function* fetch(action) {
  try {
    const { funcId, displayitemsApi } = action.payload;
    const hasContractLog = yield select(checkHasContractLog);
    yield put(actions.requestStart());
    const {
      data: { data },
    } = yield call(displayitemsApi.fetch, funcId);
    const { items, sorting } = DisplayItemsService.getItemsByContractLog(
      funcId,
      data,
      hasContractLog
    );

    yield put(
      actions.fetchSucceeded({
        override: true,
        funcId,
        items,
        sorting,
      })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* create(action) {
  try {
    const { funcId, data, displayitemsApi } = action.payload;
    yield put(actions.requestStart());
    const {
      data: { data: displayItems },
    } = yield call(displayitemsApi.create, funcId, data);
    const { __sorting__: sorting = [], ...items } = displayItems;
    yield put(actions.createSucceeded(items, sorting));
    if (funcId === FUNC_CODE_LANDING_PAGE_ANALYZE) {
      yield put(actions.fetchDisplayItemsLandingPageAnalyzeSucceed({ items }));
    }
    yield put(settingViewActions.applyIsChanged({ changed: true }));
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

function* fetchDisplayItemsLandingPageAnalyze(action) {
  try {
    const { funcId, displayitemsApi } = action.payload;
    const {
      data: { data },
    } = yield call(displayitemsApi.fetch, funcId);

    yield put(
      actions.fetchDisplayItemsLandingPageAnalyzeSucceed({ items: data })
    );
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

export default function* watchView() {
  yield takeEvery(types.FETCH, fetch);
  yield takeEvery(
    types.FETCH_DISPLAY_ITEMS_LANDING_PAGE_ANALYZE,
    fetchDisplayItemsLandingPageAnalyze
  );
  yield takeEvery(types.CREATE, create);
}
