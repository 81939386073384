import types from 'store/alerts/types';

const initialState = {
  control: {},
  data: {
    alert: [],
    warning: [],
  },
  communications: {
    start: false,
    fetching: false,
    success: false,
    error: false,
  },
};

const AlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ALERTS_START:
      return {
        ...state,
        communications: {
          ...state.communications,
          start: true,
          fetching: true,
          error: false,
          success: false,
        },
      };
    case types.FETCH_ALERTS_SUCCESS:
      return {
        ...state,
        communications: {
          ...state.communications,
          start: false,
          fetching: false,
          error: false,
          success: true,
        },
        data: {
          alert: action.payload.data.alert,
          warning: action.payload.data.warning,
        },
      };
    case types.FETCH_ALERTS_ERROR:
      return {
        ...state,
        communications: {
          ...state.communications,
          start: false,
          fetching: false,
          error: true,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default AlertsReducer;
