import moment from 'moment';
import {
  REPORT_FIELDS,
  SERVICE_STORE_S3,
  SERVICE_STORE_GOOGLE_DRIVE,
  REPORT_TYPE_AD_REPO,
  MIN_DIMENSION,
  MIN_METRIC,
  MAX_METRIC,
  STATUS_PERIOD_MODE_NORMAL,
  STATUS_PERIOD_MODE_PERIODIC,
  PERIOD_INTERVALS,
  WEEK_DAYS,
  MAX_LENGTH_REPORT_NAME,
  MAX_LENGTH_CUSTOM_FILENAME,
  MAX_LENGTH_EMAIL_TITLE,
  EMAIL_TITLE_MODE_DEFAULT,
  EMAIL_TITLE_MODE_CUSTOM,
  MAX_LENGTH_GOOGLE_DIR_PATH,
  REPORT_TYPES_REGULAR_REPORT,
  REPORT_TYPES_DATA_EXPORT,
  REPORT_CHANNELS,
} from 'domain/data-export/consts';
import {
  requiredValue,
  notContainSpaces,
  maxLength,
  acceptsValue,
  isBetweenPeriod,
  minLine,
  maxLine,
  duplicateValue,
  numberInRange,
  validFilename,
} from 'services/validations/commonValidations';
import { CALENDAR_DAY_FORMAT, MASTER_DATA_FILTER_LIMIT } from 'domain/consts';
import {
  MAX_LENGTH_MEMBER_NAME,
  MAX_LENGTH_OTHER,
  MAX_VALUE_AMOUNT,
} from 'services/consts';

// commonRules apply for regular report, data export, adrepo
export const commonRules = {
  [REPORT_FIELDS.REPORT_NAME]: [
    requiredValue('レポート名を入力してください'),
    notContainSpaces(
      'レポート名をスペース、タブ、改行のみで登録することはできません'
    ),
    maxLength(MAX_LENGTH_REPORT_NAME),
  ],
}

// dataExportRules apply for regular report
export const regularReportRules = {
  [REPORT_FIELDS.SERVICE_STORE]: [
    acceptsValue(
      [SERVICE_STORE_S3],
      '出力タイプを正しく選択してください。'
    ),
  ],
  [REPORT_FIELDS.REPORT_TYPE]: [
    acceptsValue(
      REPORT_TYPES_REGULAR_REPORT,
      'レポートタイプを正しく選択してください。'
    ),
  ],
  [REPORT_FIELDS.REPORT_CHANNEL]: [
    acceptsValue(
      REPORT_CHANNELS.map((item) => item.value),
      '対象チャネルを正しく選択してください。'
    ),
  ],
  [REPORT_FIELDS.CUSTOM_FILENAME]: [
    requiredValue('ファイル名を入力してください'),
    validFilename('ファイル名に、半角英数字・ハイフン・アンダーバー・ドット以外は使用できません'),
    maxLength(MAX_LENGTH_CUSTOM_FILENAME),
  ],
};

// dataExportRules apply for data export
export const dataExportRules = {
  [REPORT_FIELDS.SERVICE_STORE]: [
    acceptsValue(
      [SERVICE_STORE_S3, SERVICE_STORE_GOOGLE_DRIVE],
      '出力タイプを正しく選択してください。'
    ),
  ],
  [REPORT_FIELDS.REPORT_TYPE]: [
    acceptsValue(
      [
        ...REPORT_TYPES_DATA_EXPORT,
        REPORT_TYPE_AD_REPO,
      ],
      'レポートタイプを正しく選択してください。'
    ),
  ],
  [REPORT_FIELDS.DIMENSIONS]: [
    minLine(MIN_DIMENSION, '集計軸と指標をそれぞれ1件以上選択してください。'),
    duplicateValue('集計軸を正しく選択してください。'),
  ],
  [REPORT_FIELDS.PERIOD_MODE]: [
    acceptsValue(
      [STATUS_PERIOD_MODE_NORMAL, STATUS_PERIOD_MODE_PERIODIC],
      '集計期間を正しく設定してください。'
    ),
  ],
};

export const googleAccountRules = {
  [REPORT_FIELDS.GOOGLE_ACCOUNT]: [
    requiredValue(
      'Googleスプレッドシートでの出力は、Googleアカウント認証が必須となります。'
    ),
  ],
  [REPORT_FIELDS.GOOGLE_DIR_PATH]: [maxLength(MAX_LENGTH_GOOGLE_DIR_PATH)],
};

export const periodRules = {
  [REPORT_FIELDS.PERIOD_START]: [
    requiredValue('集計期間(開始日)を設定してください。'),
    isBetweenPeriod(
      moment().subtract(2, 'years').format(CALENDAR_DAY_FORMAT),
      moment().format(CALENDAR_DAY_FORMAT),
      '集計期間の開始日に明日以降の日付を設定することはできません。'
    ),
  ],
  [REPORT_FIELDS.PERIOD_END]: [
    requiredValue('集計期間(終了日)を設定してください。'),
  ],
};

export const periodIntervalRules = {
  [REPORT_FIELDS.PERIOD_INTERVAL]: [
    acceptsValue(
      Object.keys(PERIOD_INTERVALS).map(Number),
      '集計期間を正しく設定してください。'
    ),
  ],
  [REPORT_FIELDS.PERIOD_RECEIVE_REPORT_DAY]: [
    acceptsValue(
      Object.keys(WEEK_DAYS).map(Number),
      '集計期間を正しく設定してください。'
    ),
  ],
};

export const metricRules = {
  [REPORT_FIELDS.METRICS]: [
    minLine(MIN_METRIC, '集計軸と指標をそれぞれ1件以上選択してください。'),
    maxLine(MAX_METRIC, '選択できる指標は最大{max}件です。'),
    duplicateValue('指標を正しく選択してください。'),
  ],
};

export const emailTitleModeRules = {
  [REPORT_FIELDS.EMAIL_TITLE_MODE]: [
    acceptsValue(
      [EMAIL_TITLE_MODE_DEFAULT, EMAIL_TITLE_MODE_CUSTOM],
      'メールタイトルに誤りがあります。正しく設定してください。'
    ),
  ],
};

export const emailTitleRules = {
  [REPORT_FIELDS.EMAIL_TITLE]: [
    requiredValue('メールタイトルを入力してください'),
    notContainSpaces(
      'メールタイトルをスペース、タブ、改行のみで登録することはできません'
    ),
    maxLength(MAX_LENGTH_EMAIL_TITLE),
  ],
};

export const filterAdrepoRules = {
  conversion_ids: [
    maxLine(
      MASTER_DATA_FILTER_LIMIT,
      `対象コンバージョンは、${MASTER_DATA_FILTER_LIMIT}件までの選択となります`
    ),
  ],
  amount_from: [
    numberInRange(
      0,
      MAX_VALUE_AMOUNT,
      `売上金額は(以上)${MAX_VALUE_AMOUNT}以下で入力してください`
    ),
  ],
  amount_to: [
    numberInRange(
      0,
      MAX_VALUE_AMOUNT,
      `売上金額は(以下)${MAX_VALUE_AMOUNT}以下で入力してください`
    ),
  ],
  member_name: [notContainSpaces(), maxLength(MAX_LENGTH_MEMBER_NAME)],
  other1: [notContainSpaces(), maxLength(MAX_LENGTH_OTHER)],
  other2: [notContainSpaces(), maxLength(MAX_LENGTH_OTHER)],
  other3: [notContainSpaces(), maxLength(MAX_LENGTH_OTHER)],
  other4: [notContainSpaces(), maxLength(MAX_LENGTH_OTHER)],
  other5: [notContainSpaces(), maxLength(MAX_LENGTH_OTHER)],
};
