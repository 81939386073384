// async actions
const REQUEST_START = 'ltv/settings/order/REQUEST_START';
const REQUEST_FAILED = 'ltv/settings/order/REQUEST_FAILED';
const FETCH = 'ltv/settings/order/FETCH';
const FETCH_LIST = 'ltv/settings/order/FETCH_LIST';
const DOWNLOAD_CSV_ORDER_HISTORY =
  'ltv/settings/order/DOWNLOAD_CSV_ORDER_HISTORY';
const DOWNLOAD_CSV = 'ltv/settings/order/DOWNLOAD_CSV';
const UPLOAD = 'ltv/settings/order/UPLOAD';

// sync actions
const FETCH_SUCCEEDED = 'ltv/settings/order/FETCH_SUCCEEDED';
const FETCH_LIST_SUCCEEDED = 'ltv/settings/order/FETCH_LIST_SUCCEEDED';
const UPLOAD_SUCCEEDED = 'ltv/settings/order/UPLOAD_SUCCEEDED';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  FETCH,
  DOWNLOAD_CSV_ORDER_HISTORY,
  FETCH_LIST,
  FETCH_LIST_SUCCEEDED,
  FETCH_SUCCEEDED,
  DOWNLOAD_CSV,
  UPLOAD,
  UPLOAD_SUCCEEDED,
};
