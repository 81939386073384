import React from 'react';
import { bool, func, number } from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CountItemSelected from 'views/molecules/CountItemSelected';
import UploadCsvDropdown from 'views/atoms/dropdown/UploadCsvDropdown';
import EbisCustomCheckbox from 'views/atoms/checkbox/EbisCustomCheckbox';
import { REMAIN_ITEMS_REGISTER } from 'domain/ad-management/consts';
import TableTooltips from 'services/common/TableTooltips';

function TableControlTabLpo(props) {
  const {
    count,
    totalItems,
    remain,
    emptyData,
    onSelectedAllPages,
    onRegister,
    onChangeAd,
    onDelete,
    onSelectCsvDelete,
    onSelectCsvRegister,
    lpoPermission,
  } = props;

  const checkboxVariant = count > 0 && count < totalItems ? 'mixed' : undefined;

  const onSelect = (type) => {
    if (type === 'register') {
      onSelectCsvRegister();
    } else if (type === 'delete') {
      onSelectCsvDelete();
    }
  };

  return (
    <div className="d-flex table-control">
      <div className="d-flex table-control__icon">
        {lpoPermission ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip className="tooltip-common">広告を追加</Tooltip>}
          >
            <Button
              variant="secondary"
              size="sm"
              className="btn-icon--no-text rounded-circle"
              disabled={remain === 0}
              onClick={() => onRegister(true)}
            >
              <i className="fal fa-plus fs-22" />
            </Button>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="bottom"
            overlay={
              <Tooltip variant="disabled" placement="bottom">
                {TableTooltips.lpoCancelledTooltipMassage}
              </Tooltip>
            }
          >
            <div style={{ marginRight: '10px' }}>
              <Button
                variant="secondary"
                size="sm"
                className="btn-icon--no-text rounded-circle"
                disabled={remain === 0 || !lpoPermission}
                onClick={() => onRegister(true)}
              >
                <i className="fal fa-plus fs-22" />
              </Button>
            </div>
          </OverlayTrigger>
        )}
        <UploadCsvDropdown
          items={[
            {
              key: 'register',
              title: 'CSV一括登録',
            },
            {
              key: 'delete',
              title: 'CSV一括削除',
            },
          ]}
          onSelect={onSelect}
          lpoPermission={lpoPermission}
          mode="lpo"
        />
        {remain >= 0 && (
          <div className="table-control__remain txt-note color-gray-dark">
            ※あと{remain}件登録可能です
          </div>
        )}
      </div>
      <CountItemSelected count={count}>
        <EbisCustomCheckbox
          id="check-all-pages"
          label="ページ跨ぎで全件を選択"
          checked={count > 0}
          disabled={emptyData}
          variant={checkboxVariant}
          onChange={() => onSelectedAllPages(count <= 0)}
        />
        <Button
          variant="secondary"
          size="sm"
          disabled={count === 0}
          onClick={() => onChangeAd()}
        >
          AD広告に変更
        </Button>
        <Button
          variant="secondary"
          size="sm"
          disabled={count === 0}
          onClick={() => onDelete()}
        >
          削除
        </Button>
      </CountItemSelected>
    </div>
  );
}

TableControlTabLpo.propTypes = {
  count: number,
  totalItems: number.isRequired,
  remain: number,
  emptyData: bool,
  onSelectedAllPages: func,
  onRegister: func,
  onChangeAd: func,
  onDelete: func,
  onSelectCsvRegister: func,
  onSelectCsvDelete: func,
  lpoPermission: bool,
};

TableControlTabLpo.defaultProps = {
  count: 0,
  remain: REMAIN_ITEMS_REGISTER,
  emptyData: false,
  onSelectedAllPages: () => {},
  onRegister: () => {},
  onChangeAd: () => {},
  onDelete: () => {},
  onSelectCsvRegister: () => {},
  onSelectCsvDelete: () => {},
  lpoPermission: false,
};

export default TableControlTabLpo;
