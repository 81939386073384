import {
  maxLength,
  notContainSpaces,
  notContainControlCharacters,
  requiredValue,
  newlineIpCheck,
  numberInRange,
  maxLine,
  newlineFormatCheck,
  duplicateValue,
  maxLengthByLine,
  validUrlByLine,
  newLineEmailCheck,
  validLabel,
} from 'services/validations/commonValidations';
import {
  MAX_LENGTH_HOST_NAME,
  REJECT_HOST_MAX_LENGTH,
  MAX_LENGTH_ALERT_MAIL,
  MAX_LENGTH_LOGIN_IP,
  SESSION_EXPIRE_MAX_VALUE,
  EXPIREDAYS_MAX_VALUE,
  QUERY_PARAMS_MAX_REGIST_LINE,
  QUERY_PARAMS_MAX_LENGTH_PER_LINE,
  DIRECTORY_INDEX_MAX_REGIST_LINE,
  DIRECTORY_INDEX_MAX_LENGTH_PER_LINE,
} from 'domain/system-setting/consts';
import {
  DIRECTORY_INDEX_FORMAT_CHECK,
  MAX_REGISTER_CHECK,
  MAX_VALUE_CHECK,
} from 'services/validations/messageErrorByCode';
import {
  AD_GROUP1,
  AD_GROUP2,
  AD_NOTE,
  OTHER1,
  OTHER2,
  OTHER3,
  OTHER4,
  OTHER5,
} from 'domain/fields';
import {
  MAX_LENGTH_AD_GROUP_NAME,
  MAX_LENGTH_AD_NOTE_NAME,
  MAX_LENGTH_OTHER_NAME,
} from 'domain/system-setting/consts-display-setting';
import { REGEX_DIRECTORY_INDEX } from 'services/regexPatterns';

export const basicSettingRules = {
  host_name: [
    requiredValue(),
    notContainSpaces(),
    maxLength(MAX_LENGTH_HOST_NAME),
  ],
};

export const measurementConfigRules = {
  reject_host: [
    notContainSpaces(),
    maxLength(REJECT_HOST_MAX_LENGTH),
    newlineIpCheck(),
  ],
  session_expire: [
    requiredValue(),
    numberInRange(1, SESSION_EXPIRE_MAX_VALUE, MAX_VALUE_CHECK),
  ],
  expiredays: [
    requiredValue(),
    numberInRange(1, EXPIREDAYS_MAX_VALUE, MAX_VALUE_CHECK),
  ],
};

export const measurementLogRules = {
  query_params: {
    maxLine: maxLine(QUERY_PARAMS_MAX_LENGTH_PER_LINE, MAX_REGISTER_CHECK),
    maxLengthByLine: maxLengthByLine(QUERY_PARAMS_MAX_REGIST_LINE),
    newlineFormatCheck: newlineFormatCheck(),
    duplicateValue: duplicateValue(),
  },
  directory_index: {
    requiredValue: requiredValue(),
    maxLine: maxLine(DIRECTORY_INDEX_MAX_REGIST_LINE, MAX_REGISTER_CHECK),
    maxLengthByLine: maxLengthByLine(DIRECTORY_INDEX_MAX_LENGTH_PER_LINE),
    newlineFormatCheck: newlineFormatCheck(
      DIRECTORY_INDEX_FORMAT_CHECK,
      REGEX_DIRECTORY_INDEX
    ),
    duplicateValue: duplicateValue(),
  },
};

export const otherSettingRules = {
  alert_email: [maxLength(MAX_LENGTH_ALERT_MAIL), newLineEmailCheck()],
  login_ip: [maxLength(MAX_LENGTH_LOGIN_IP), newlineIpCheck()],
};

export const displaySettingVisionRule = {
  [AD_GROUP1]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    validLabel(),
    maxLength(
      MAX_LENGTH_AD_GROUP_NAME,
      '{label}{length}文字以内で入力してください'
    ),
  ],
  [AD_GROUP2]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    validLabel(),
    maxLength(
      MAX_LENGTH_AD_GROUP_NAME,
      '{label}{length}文字以内で入力してください'
    ),
  ],
  [AD_NOTE]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    validLabel(),
    maxLength(
      MAX_LENGTH_AD_NOTE_NAME,
      '{label}{length}文字以内で入力してください'
    ),
  ],
  [OTHER1]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    validLabel(),
    maxLength(
      MAX_LENGTH_OTHER_NAME,
      '{label}{length}文字以内で入力してください'
    ),
  ],
  [OTHER2]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    validLabel(),
    maxLength(
      MAX_LENGTH_OTHER_NAME,
      '{label}{length}文字以内で入力してください'
    ),
  ],
  [OTHER3]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    validLabel(),
    maxLength(
      MAX_LENGTH_OTHER_NAME,
      '{label}{length}文字以内で入力してください'
    ),
  ],
  [OTHER4]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    validLabel(),
    maxLength(
      MAX_LENGTH_OTHER_NAME,
      '{label}{length}文字以内で入力してください'
    ),
  ],
  [OTHER5]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    validLabel(),
    maxLength(
      MAX_LENGTH_OTHER_NAME,
      '{label}{length}文字以内で入力してください'
    ),
  ],
};
