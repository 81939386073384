import { useSelector, shallowEqual } from 'react-redux';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import {
  SC_SETTING_EDIT,
  SC_SETTING_VIEW,
  ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
  ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
  ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
} from 'domain/permissions/contractPermissions';
import { getPermissions } from 'store/auth/selectors';

const useCheckPermissionSC = () => {
  const { checkPermissions } = useCheckPermissions();

  const isSCEditPermission = checkPermissions('anyOf', [SC_SETTING_EDIT]);
  const permissions = useSelector(getPermissions, shallowEqual);

  const isSCViewPermission = checkPermissions('anyOf', [SC_SETTING_VIEW]);

  let isHaveOnlySearchConsolePermission = false;
  if (permissions.length <= 2) {
    isHaveOnlySearchConsolePermission = checkPermissions('anyOf', [
      SC_SETTING_VIEW,
      SC_SETTING_EDIT,
    ]);
  }

  const hasSEOContractPermissions = checkPermissions('anyOf', [
    ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
    ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
    ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
  ]);

  return {
    isSCEditPermission,
    isSCViewPermission,
    isHaveOnlySearchConsolePermission,
    hasSEOContractPermissions,
  };
};

export default useCheckPermissionSC;
