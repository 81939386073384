import { put, call, takeLatest, select, fork, all } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import sharedSagas from 'store/sharedSagas';
import ViewService from 'domain/ViewService';
import { convertArray2Object } from 'domain/utils';
import routeAnalyzeSelectors from 'store/log/route-analyze/selectors';
import settingsSelectors from 'store/settings/selectors';
import commonSelectors from 'store/common/selectors';
import commonActions from 'store/common/actions';
import masterDataActions from 'store/master-data/actions';
import ViewApi from 'services/api/ViewApi';
import handleError from 'services/error/handleScopeError';
import RouteAnalyzeApi from 'services/api/log/RouteAnalyzeApi';
import PageAnalyzeApi from 'services/api/log/PageAnalyzeApi';
import MasterDataApi from 'services/api/MasterDataApi';
import { communicationStatus, HttpStatus } from 'services/utils';
import {
  convertArrayToObject,
  prepareFiltersForAPI,
} from 'services/log/LogService';
import {
  PAGE_ID,
  CNT_PV,
  FUNC_CODE_LOG_ROUTE_ANALYZE,
  PAGE_TITLE,
} from 'domain/settings/display-items';
import {
  FILTER_KEY_AD_GROUP1,
  FILTER_KEY_AD_GROUP2,
  FILTER_KEY_MEDIA,
  TAB_ALL,
} from 'services/consts';
import { LOG_ROUTE_ANALYZE } from 'services/routes/constants';
import {
  API_UNLIMITED_VALUE,
  TABLE_ROWS_PER_PAGE,
  FILTER_UNREGISTERED_ITEM,
  MASTER_DATA_FILTER_LIMIT,
} from 'domain/consts';
import {
  CUSTOMVIEW_ROUTE_NAME,
  PATTERN_DISPLAY_TABLE,
  ROUTE_SETTING_FIELDS,
} from 'domain/log/route-analyze/consts';

import actions from './actions';
import types from './types';

const MASTER_DATA_PAGE_LIMIT = MASTER_DATA_FILTER_LIMIT + 1;
const { BAD_REQUEST } = HttpStatus;
const { LOADING, SUCCEEDED } = communicationStatus;
const {
  DISPLAY_ITEM,
  INFLOW_MODE,
  START_PAGE_ID,
  TRANSIT_PAGE_ID,
  END_PAGE_ID,
} = ROUTE_SETTING_FIELDS;

function* getStatesRequest() {
  const [period, paging, metrics, filters, inflowMode, pattern] = [
    yield select(commonSelectors.periodSelector),
    yield select(routeAnalyzeSelectors.getPaging),
    yield select(routeAnalyzeSelectors.metricsSelector),
    yield select(routeAnalyzeSelectors.getFilters),
    yield select(routeAnalyzeSelectors.getInflowMode),
    yield select(routeAnalyzeSelectors.getPattern),
  ];

  return {
    channel: TAB_ALL,
    inflow_mode: inflowMode,
    start_date: period.start.format('YYYY-MM-DD'),
    end_date: period.end.format('YYYY-MM-DD'),
    metrics,
    filters,
    offset: (paging - 1) * TABLE_ROWS_PER_PAGE,
    limit:
      pattern === PATTERN_DISPLAY_TABLE
        ? TABLE_ROWS_PER_PAGE
        : API_UNLIMITED_VALUE,
  };
}

function* handleGetMasterData() {
  yield all([
    put(masterDataActions.fetchConversions()),
    put(actions.setStatusMasterdata('media', LOADING)),
    put(actions.setStatusMasterdata('adGroup1', LOADING)),
    put(actions.setStatusMasterdata('adGroup2', LOADING)),
  ]);

  // prepare param
  const filters = yield select(routeAnalyzeSelectors.getFilters);
  const selected = filters.reduce(
    (acc, { field, value }) => {
      switch (field) {
        case FILTER_KEY_MEDIA:
        case FILTER_KEY_AD_GROUP1:
        case FILTER_KEY_AD_GROUP2:
          return { ...acc, [field]: value };

        case START_PAGE_ID:
        case TRANSIT_PAGE_ID:
        case END_PAGE_ID:
          return { ...acc, page: [...acc.page, value] };

        default:
          return acc;
      }
    },
    {
      [FILTER_KEY_MEDIA]: [],
      [FILTER_KEY_AD_GROUP1]: [],
      [FILTER_KEY_AD_GROUP2]: [],
      page: [],
    }
  );

  // Get data
  const [
    { data: resDisplay },
    { data: resMedia },
    { data: resAdGroup1 },
    { data: resAdGroup2 },
    { data: resPage },
  ] = yield all([
    call(MasterDataApi.fetchDisplay),
    call(MasterDataApi.fetchMedia, '', selected[FILTER_KEY_MEDIA]),
    call(MasterDataApi.fetchAdGroup1, '', selected[FILTER_KEY_AD_GROUP1]),
    call(MasterDataApi.fetchAdGroup2, '', selected[FILTER_KEY_AD_GROUP2]),
    call(MasterDataApi.fetchPage, {
      limit: MASTER_DATA_PAGE_LIMIT,
      selected: [...new Set(selected.page)],
    }),
  ]);

  // Save data to store
  yield all([
    put(actions.setMasterdata('display', resDisplay.data)),
    put(
      actions.setMasterdata('media', {
        status: SUCCEEDED,
        data: {
          ...FILTER_UNREGISTERED_ITEM,
          ...convertArray2Object([...resMedia.selected, ...resMedia.data]),
        },
      })
    ),
    put(
      actions.setMasterdata('adGroup1', {
        status: SUCCEEDED,
        data: {
          ...FILTER_UNREGISTERED_ITEM,
          ...convertArray2Object([
            ...resAdGroup1.selected,
            ...resAdGroup1.data,
          ]),
        },
      })
    ),
    put(
      actions.setMasterdata('adGroup2', {
        status: SUCCEEDED,
        data: {
          ...FILTER_UNREGISTERED_ITEM,
          ...convertArray2Object([
            ...resAdGroup2.selected,
            ...resAdGroup2.data,
          ]),
        },
      })
    ),
    put(
      actions.setMasterdata('page', {
        status: SUCCEEDED,
        data: {
          ...convertArrayToObject([...resPage.selected, ...resPage.data]),
        },
      })
    ),
  ]);
}

function* handleSearchMasterData(action) {
  const { type, field, value = '' } = action.payload;

  if (value === '') {
    yield put(
      actions.setMasterdata(type, {
        status: SUCCEEDED,
        isSearching: false,
        search: {},
      })
    );
    return;
  }

  yield put(actions.setStatusMasterdata(type, LOADING));

  let res = {};
  switch (type) {
    case 'media': {
      res = yield call(MasterDataApi.fetchMedia, value);
      break;
    }

    case 'adGroup1': {
      res = yield call(MasterDataApi.fetchAdGroup1, value);
      break;
    }

    case 'adGroup2': {
      res = yield call(MasterDataApi.fetchAdGroup2, value);
      break;
    }

    case 'page': {
      res = yield call(MasterDataApi.fetchPage, {
        field,
        value,
        limit: MASTER_DATA_PAGE_LIMIT,
      });
      break;
    }

    default:
      break;
  }

  yield put(
    actions.setMasterdata(type, {
      isSearching: true,
      status: SUCCEEDED,
      search:
        type === 'page'
          ? convertArrayToObject(res.data.data)
          : convertArray2Object(res.data.data),
    })
  );
}

function* handleGetPageViewBiggest() {
  // get page with PV biggest to set for start_page_id
  const period = yield select(commonSelectors.periodSelector);
  const resPage = yield call(PageAnalyzeApi.getData, {
    sum: false,
    channel: TAB_ALL,
    sort: `-${CNT_PV}`,
    start_date: period.start.format('YYYY-MM-DD'),
    end_date: period.end.format('YYYY-MM-DD'),
    dimensions: [PAGE_ID, PAGE_TITLE],
    metrics: [CNT_PV],
    offset: 0,
    limit: 1,
    filters: [
      { field: 'unregistered_page_flag', value: false, operator: null },
    ],
  });

  const pageId = resPage?.data?.data?.detail?.[0]?.page_id;
  let filters = [];
  if (pageId) {
    filters = [{ field: START_PAGE_ID, value: pageId, operator: null }];

    const res = yield call(MasterDataApi.fetchPage, {
      limit: MASTER_DATA_PAGE_LIMIT,
      selected: [pageId],
    });

    yield put(
      actions.setMasterdata('page', {
        status: SUCCEEDED,
        data: convertArrayToObject([...res.data.selected, ...res.data.data]),
      })
    );
  }

  yield put(actions.setSettings({ filters }));
}

function* handleGetSetting(action) {
  yield put(actions.setStatus(action.type, LOADING));

  const resList = yield call(ViewApi.fetchAll);
  const view = resList.data.data.find(
    (item) => item.view_name === CUSTOMVIEW_ROUTE_NAME
  );

  if (isEmpty(view)) {
    yield put(actions.setStatus(action.type, SUCCEEDED));
  } else {
    const { data } = yield call(ViewApi.fetch, view.id);
    const {
      id: viewId,
      filters,
      display_items: displayItems,
      inflow_mode: inflowMode,
    } = data.data;

    yield put(
      actions.setSettings({
        viewId,
        filters,
        inflowMode,
        displayItem: displayItems[FUNC_CODE_LOG_ROUTE_ANALYZE],
      })
    );
  }

  yield fork(handleGetMasterData);
}

// Save settings as a item in customview
function* handleChangeSetting(action) {
  yield put(actions.setStatus(action.type, LOADING));

  const { settings } = action.payload;
  const filters = prepareFiltersForAPI(settings);
  const [isAlreadySetting, period] = [
    yield select(routeAnalyzeSelectors.checkAlreadySetting),
    yield select(commonSelectors.periodSelector),
  ];

  const data = ViewService.prepareForApi(
    {
      period,
      filters,
      name: CUSTOMVIEW_ROUTE_NAME,
      displayItems: settings[DISPLAY_ITEM],
      skipPrepareFilter: true,
      options: { inflow_mode: settings[INFLOW_MODE] },
    },
    FUNC_CODE_LOG_ROUTE_ANALYZE
  );

  let viewId = yield select(routeAnalyzeSelectors.getViewId);
  if (isAlreadySetting) {
    yield call(ViewApi.update, viewId, data);
  } else {
    const res = yield call(ViewApi.create, data);
    viewId = res.data.data.id;
  }

  yield put(
    actions.setSettings({
      viewId,
      filters,
      displayItem: settings[DISPLAY_ITEM],
      inflowMode: settings[INFLOW_MODE],
    })
  );

  yield fork(handleGetMasterData);
}

function* handleGetData(action) {
  yield put(actions.setStatus(action.type, LOADING));
  const pageId = yield select(settingsSelectors.getPage);

  if (pageId !== LOG_ROUTE_ANALYZE) {
    return;
  }

  const statesRequest = yield getStatesRequest();

  const { data } = yield call(RouteAnalyzeApi.getData, statesRequest);

  yield put(
    actions.setData({ data: data.data.detail, metadata: data.metadata })
  );
}

function* handleDownloadCsv() {
  yield put(commonActions.setDownloadNotify('CSVファイルを作成しています...'));

  const statesRequest = yield getStatesRequest();
  yield call(RouteAnalyzeApi.downloadCsv, {
    ...statesRequest,
    limit: API_UNLIMITED_VALUE,
  });

  yield put(commonActions.setDownloadNotify('CSVファイルを作成しました'));
}

function* errorHandler(err, action) {
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [];

  yield put(actions.setErrors(action.type, errors, scope));
}

export default function* watch() {
  yield takeLatest(
    types.SEARCH_MASTERDATA,
    sharedSagas.safe(errorHandler, handleSearchMasterData)
  );
  yield takeLatest(
    types.GET_SETTINGS,
    sharedSagas.safe(errorHandler, handleGetSetting)
  );
  yield takeLatest(
    types.GET_PAGE_VIEW_BIGGEST,
    sharedSagas.safe(errorHandler, handleGetPageViewBiggest)
  );
  yield takeLatest(
    types.CHANGE_SETTINGS,
    sharedSagas.safe(errorHandler, handleChangeSetting)
  );
  yield takeLatest(
    types.GET_DATA,
    sharedSagas.safe(errorHandler, handleGetData)
  );
  yield takeLatest(
    types.DOWNLOAD_CSV,
    sharedSagas.safe(errorHandler, handleDownloadCsv)
  );
}
