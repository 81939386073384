import { select, debounce, put } from 'redux-saga/effects';
import moment from 'moment';
import logout from 'domain/logout';
import { updateLatestActivity } from './actions';

function* userSessionExpire(action) {
  const state = yield select();
  const {
    user: { latestActivity },
  } = state;
  if (latestActivity) {
    // expire in minutes
    const userSessionExpired = process.env.REACT_APP_USER_SESSION_EXPIRES || 30;
    // check diff in seconds
    if (
      moment().diff(moment(latestActivity), 'seconds') >
      userSessionExpired * 60
    ) {
      logout();
    }
  }

  // Update activity
  if (
    action.type.indexOf('reflectiontime') === -1 &&
    action.type.indexOf('logger') === -1
  ) {
    yield put(updateLatestActivity({ latestActivity: moment() }));
  }
}

export default function* watchAuth() {
  // user session expires
  yield debounce(2000, '*', userSessionExpire);
}
