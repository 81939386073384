import React, { useRef } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import jaLocale from 'moment/locale/ja';
import { DayPickerSingleDateController } from 'react-dates/esm';
import { bool, func, object, objectOf, oneOfType } from 'prop-types';

import './singleDatePicker.scss';
import CalendarDay from './CalendarDay';

function CalendarMonth(props) {
  const { month } = props;
  return (
    <div
      style={{
        justifyContent: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'black',
        marginBottom: '30px',
      }}
    >
      <span>
        {month.format('YYYY')}/{month.format('MM')}
      </span>
    </div>
  );
}

CalendarMonth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  month: object.isRequired,
};

function EbisSingleDatePicker(props) {
  const {
    focus,
    setFocus,
    date,
    onDateChange,
    onOutsideClick,
    maxDate,
    minDate,
  } = props;
  moment.locale('ja', jaLocale);

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const datepickerRef = useRef(null);

  const focusChange = ({ focused }) => {
    setFocus(focused);
  };

  const PrevIcon = () => (
    <span className="icon-hover">
      <i className="fas fa-chevron-left" aria-hidden="true" />
    </span>
  );

  const NextIcon = () => (
    <span className="icon-hover">
      <i className="fas fa-chevron-right" aria-hidden="true" />
    </span>
  );

  return (
    <div className="single-date-picker">
      <div className="main-panel" ref={datepickerRef}>
        <DayPickerSingleDateController
          numberOfMonths={1}
          date={date}
          onDateChange={onDateChange}
          onFocusChange={focusChange}
          focused={focus}
          monthFormat="YYYY/MM"
          showInputs
          hideKeyboardShortcutsPanel
          noBorder
          onOutsideClick={onOutsideClick}
          daySize={26}
          firstDayOfWeek={1}
          maxDate={maxDate}
          minDate={minDate}
          isOutsideRange={(d) => !d.isBetween(minDate, maxDate, 'day', '[]')}
          renderMonthElement={(monthProps) => (
            <CalendarMonth month={monthProps.month} />
          )}
          renderCalendarDay={(d) => <CalendarDay day={d} />}
          initialVisibleMonth={() => (date ? moment(date) : moment())}
          navPrev={<PrevIcon />}
          navNext={<NextIcon />}
          transitionDuration={0}
        />
      </div>
    </div>
  );
}

EbisSingleDatePicker.propTypes = {
  /**
   * <p>
   * Handler when dates are changed. <br />
   * Args: (date): type of Moment Object
   * </p>
   */
  onDateChange: func,
  /**
   * <p>
   * Handler when click is outside of datepicker. <br />
   * </p>
   */
  onOutsideClick: func,
  /**
   * <p>
   *  Selected date of this datepicker
   * </p>
   */
  // eslint-disable-next-line react/forbid-prop-types
  date: object,
  /**
   * Indicate if this datepicker is focus.
   */
  focus: bool,
  /**
   * Set focus for this datepicker.
   */
  setFocus: func,

  maxDate: objectOf(oneOfType([object])),
  minDate: objectOf(oneOfType([object])),
};

EbisSingleDatePicker.defaultProps = {
  date: null,
  focus: false,
  setFocus: () => {},
  onDateChange: () => {},
  onOutsideClick: () => {},
  maxDate: moment(),
  minDate: moment().subtract(2, 'year'),
};

export default EbisSingleDatePicker;
