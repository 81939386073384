import types from 'store/ad-management-media/types';
import helper, {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
} from 'store/helper';
import { TYPE_MEDIA_TYPE } from 'domain/ad-management/media-group/constants';

const initialState = {
  /**
   * Array list media type to show on table
   */
  list: [],
  /**
   * Array postback History, show in Postback history modal
   */
  postbackHistory: [],
  /**
   * Array item, show in Upload confirm modal
   */
  uploadConfirmList: {
    data: {
      detail: [],
    },
    metadata: {},
  },
  data: {
    detail: {
      data: {},
      errors: [],
    },
  },
  apiStatus: {
    detail: {
      status: 'idle',
    },
  },
  fetchByIdStatus: {
    detail: {
      status: 'idle',
    },
  },
  fetchDeleteListStatus: {
    detail: {
      status: 'idle',
    },
  },
  settings: {
    tab: TYPE_MEDIA_TYPE,
    totalRegistered: 0,
    pagination: {
      currentPage: 1,
      totalItems: 0,
    },
    sort: {
      field: '',
      direction: '',
    },
  },
  fetchStatus: STATUS_IDLE, // Fetch list status
  fetchPostbackStatus: STATUS_IDLE, // Fetch list status
  fetchUploadConfirmListStatus: STATUS_IDLE, // Fetch list upload confirm list status
  fileNameConfirmUpload: '', // File name confirm upload
  selected: null, // Integer the media type id when click edit
  isShowUploadConfirmModal: false, // Boolean show/hide upload confirm modal
};

const setDataList = (state, action) => ({
  ...state,
  list: action.payload.data,
  settings: {
    ...state.settings,
    tab: action.payload.tab,
  },
});

const setDataDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      data: action.payload.data,
      errors: [],
    },
  },
});

const setErrorDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      errors: action.payload.errors,
    },
  },
});

const setStatusDetail = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    detail: {
      ...state.apiStatus.detail,
      status: action.payload.status,
    },
  },
});
const setStatusFetchById = (state, action) => ({
  ...state,
  fetchByIdStatus: {
    ...state.fetchByIdStatus,
    detail: {
      ...state.fetchByIdStatus.detail,
      status: action.payload.status,
    },
  },
});
const setStatusFetchDeleteList = (state, action) => ({
  ...state,
  fetchDeleteListStatus: {
    ...state.fetchDeleteListStatus,
    detail: {
      ...state.fetchDeleteListStatus.detail,
      status: action.payload.status,
    },
  },
});
const fetchListStart = (state) => ({
  ...state,
  fetchStatus: STATUS_LOADING,
});

const fetchedList = (state, action) => {
  const { data, metadata } = action.payload;
  return {
    ...state,
    list: data.detail,
    fetchStatus: STATUS_SUCCEEDED,
    settings: {
      ...state.settings,
      totalRegistered: metadata.total,
      pagination: {
        ...state.settings.pagination,
        totalItems: metadata.count,
      },
    },
  };
};
const fetchPostBackListStart = (state) => ({
  ...state,
  fetchPostbackStatus: STATUS_LOADING,
});

const fetchedPostBackHistory = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    postbackHistory: data.data.detail,
    fetchPostbackStatus: STATUS_SUCCEEDED,
  };
};

const updatePage = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    fetchStatus: STATUS_LOADING,
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        currentPage: page,
      },
    },
  };
};

const updateSort = (state, action) => {
  const { field, direction } = action.payload;
  return {
    ...state,
    fetchStatus: STATUS_LOADING,
    settings: {
      ...state.settings,
      sort: {
        ...state.settings.sort,
        field,
        direction,
      },
    },
  };
};

const changeSelectedItem = (state, action) => {
  const id = action.payload;

  return {
    ...state,
    selected: id,
  };
};

const clearApiError = (state) => {
  return {
    ...state,
    status: STATUS_IDLE,
    error: null,
    data: {
      ...state.data,
      detail: {
        ...state.data.detail,
        errors: null,
      },
    },
  };
};
const setFetchUploadConfirmListStatus = (state, action) => {
  return {
    ...state,
    fetchUploadConfirmListStatus: action.payload.status,
  };
};

const setFileNameConfirmUpload = (state, action) => {
  const { filename } = action.payload;
  return {
    ...state,
    fileNameConfirmUpload: filename,
  };
};

const fetchedUploadConfirmList = (state, action) => {
  const { data, metadata } = action.payload;
  return {
    ...state,
    uploadConfirmList: { data, metadata },
    fetchUploadConfirmListStatus: STATUS_SUCCEEDED,
  };
};

const setUploadConfirmModalStatus = (state, action) => {
  return {
    ...state,
    isShowUploadConfirmModal: action.payload,
  };
};

const AdManagementMediaReducer = helper.createReducer(initialState, {
  [types.SET_DATA_LIST]: setDataList,
  [types.SET_DATA_DETAIL]: setDataDetail,
  [types.SET_ERROR_DETAIL]: setErrorDetail,
  [types.SET_STATUS_DETAIL]: setStatusDetail,
  [types.SET_STATUS_FETCH_BY_ID]: setStatusFetchById,
  [types.SET_STATUS_FETCH_DELETE_LIST]: setStatusFetchDeleteList,
  [types.FETCH_LIST_START]: fetchListStart,
  [types.FETCH_LIST_SUCCEEDED]: fetchedList,
  [types.FETCH_POSTBACK_HISTORY_LIST_START]: fetchPostBackListStart,
  [types.FETCH_POSTBACK_HISTORY_LIST_SUCCEEDED]: fetchedPostBackHistory,
  [types.FETCH_LIST_SUCCEEDED]: fetchedList,
  [types.UPDATE_PAGE]: updatePage,
  [types.UPDATE_SORT]: updateSort,
  [types.CHANGE_SELECTED_ITEM]: changeSelectedItem,
  [types.CLEAR_API_RESULT]: clearApiError,
  [types.UPLOAD_FETCH_CONFIRM_LIST]: fetchedUploadConfirmList,
  [types.SET_FETCH_UPLOAD_CONFIRM_LIST_STATUS]: setFetchUploadConfirmListStatus,
  [types.SET_FILE_NAME_CONFIRM_UPLOAD]: setFileNameConfirmUpload,
  [types.UPLOAD_SHOW_CONFIRM_MODAL]: setUploadConfirmModalStatus,
});

export default AdManagementMediaReducer;
