import Api, { SettingsApi } from 'services/api/Api';
import pages from 'services/routes/pages';
import {
  DATA_EXPORT,
  USER_MANAGEMENT,
  USER_ENDPOINT,
  ACCOUNT_ENDPOINT,
} from 'services/routes/constants';
import apiUtils from 'services/apiUtils';
import { saveAs } from 'services/utils';

const url = pages[DATA_EXPORT].endpoint;
const urlReports = `${url}/reports`;
const urlRegularReport = `${url}/regular-reports`;
const endpointUser = pages[USER_MANAGEMENT].endpoint;
const endpointView = `${USER_ENDPOINT}/display-settings/view-template`;
const endpointSystemSync = `${ACCOUNT_ENDPOINT}/system/sync/v2eon_sync_master`;

const DataExportFactory = () => {
  return {
    fetchUsers: async () => Api.get(endpointUser),
    fetchViews: async () => Api.get(endpointView),
    fetchView: async (id) => Api.get(`${endpointView}/${id}`),
    fetchDimensions: async () => SettingsApi.get(`${url}/dimensions`),
    fetchMetrics: async () => SettingsApi.get(`${url}/metrics`),
    fetchMetadata: async () => SettingsApi.get(`${urlReports}/metadata`),
    fetchList: async () => SettingsApi.get(urlReports),
    fetchListHistory: async (id) =>
      SettingsApi.get(`${urlReports}/${id}/history`),
    fetchDetail: async (id) => SettingsApi.get(`${urlReports}/${id}`),
    create: async (data) => SettingsApi.post(urlReports, data),
    update: async (id, data) =>
      SettingsApi.put(`${urlReports}/${encodeURIComponent(id)}`, data),
    createRegularReport: async (data) => SettingsApi.post(urlRegularReport, data),
    updateRegularReport: async (id, data) =>
      SettingsApi.put(`${urlRegularReport}/${encodeURIComponent(id)}`, data),
    delete: async (ids) =>
      SettingsApi.delete(
        apiUtils.buildUrlWithQueryStrings(urlReports, { ids })
      ),
    downloadCsv: async (id) => {
      const response = await SettingsApi.get(`${urlReports}/${id}/csv`);
      const { location } = response.data.data;
      if (location) {
        saveAs(location);
      }
    },
    fetchStatusSystemSyncDataExport: async () => Api.get(endpointSystemSync),
  };
};

const DataExportApi = DataExportFactory();
export default DataExportApi;
