import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { call, delay, select, take } from 'redux-saga/effects';
import { GET_REPORT_DEBOUNCE_LENGTH } from 'services/consts';
import redirectTypes from 'store/redirect/types';
import redirectSelectors from 'store/redirect/selectors';
import displayItemsTypes from 'store/display-items/types';
import ltvAnalyzeTypes from 'store/ltv/analyze/types';
import displayItemsSelectors from 'store/display-items/selectors';
import settingViewTypes from 'store/customview/types';
import settingsViewSelectors from 'store/customview/selectors';
import settingsSelectors from 'store/settings/selectors';
import screenTitleConfigs from 'services/common/screenTitleConfigs';
import ltvAnalyzeSelectors from 'store/ltv/analyze/selectors';

import { communicationStatus } from 'services/utils';

function* getTableReportByAPICall(params, fnGetTableData = async () => {}) {
  const res = yield call(fnGetTableData, params);

  const { sum, detail: rawList } = res.data.data;
  const { count } = res.data.metadata;

  return {
    sum,
    rawList,
    count,
  };
}

function* getChartReportByAPICall(params, fnGetChartData = async () => {}) {
  const res = yield call(fnGetChartData, params);
  return res;
}

function* waitingRedirect() {
  const redirectStatus = yield select(redirectSelectors.getStatus);
  if (redirectStatus === 'redirecting') {
    yield take(redirectTypes.REDIRECT_SUCCEEDED);
  }
}

/**
 * <p>
 * Check if the get report actions should be performed. Also take necessary actions, and wait for their completion.
 * This is applied for custom views, fetch display items.
 * </p>
 */
function* waitingFetchSettings() {
  const [
    isAppliedView,
    viewStatus,
    displayItemsStatus,
    currentFuncId,
    currentPage,
  ] = [
    yield select(settingsSelectors.isAppliedCustomView),
    yield select(settingsViewSelectors.getStatus),
    yield select(displayItemsSelectors.getStatus),
    yield select(displayItemsSelectors.getDisplayItemFuncId),
    yield select(settingsSelectors.getPage),
  ];

  // FIXME: It is dedicated to LTV and we want to make it common.
  // LTV status check
  const { ltvStatus = false } = screenTitleConfigs[currentPage];

  if (ltvStatus) {
    const ltvStatusA = yield select(ltvAnalyzeSelectors.getApiStatus);
    if (ltvStatusA === communicationStatus.IDLE) {
      yield take(ltvAnalyzeTypes.FETCH_STATUS_SUCCEEDED);
    }
  }

  if (
    // !isAppliedView &&
    currentFuncId !== DisplayItemsService.getFuncIdByPageId(currentPage) || // page changed but display items haven't
    displayItemsStatus === 'loading'
  ) {
    yield take(displayItemsTypes.FETCH_SUCCEEDED);
  }

  if (
    isAppliedView &&
    (viewStatus === 'loading' || displayItemsStatus === 'loading')
  ) {
    yield take(settingViewTypes.APPLY_SUCCEEDED);
  }
}

const safe = (errorHandler, saga, ...args) =>
  function* safeOperation(action) {
    try {
      yield call(saga, ...args, action);
    } catch (err) {
      yield call(errorHandler, err, action);
    }
  };

/**
 * Skip getReportOperation if it is considered not ready to fetch data from API
 * @param getReportOperation
 * @param args
 */
const skipIfNotReady = (getReportOperation, ...args) =>
  function* skipIfNotReadyOperation(action) {
    yield waitingFetchSettings();
    yield delay(GET_REPORT_DEBOUNCE_LENGTH);
    yield call(getReportOperation, ...args, action);
  };

export default {
  getTableReportByAPICall,
  getChartReportByAPICall,
  waitingRedirect,
  waitingFetchSettings,
  safe,
  skipIfNotReady,
};
