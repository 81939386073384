import React from 'react';

import '../custom.scss';
import 'views/app.scss';
import 'assets/facss/all.css';

import Layout from 'views/layout';
import ErrorBoundary from 'views/pages/error-page/ErrorBoundary';

const App = () => {
  return (
    <ErrorBoundary>
      <Layout />
    </ErrorBoundary>
  );
};

export default App;
