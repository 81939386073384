import helper from 'store/helper';
import types from 'store/api-management/types';
import { communicationStatus } from 'services/utils';

const { IDLE, SUCCEEDED, FAILED } = communicationStatus;

const initialState = {
  data: {
    refreshToken: '',
  },
  errors: {
    refreshToken: [],
  },
  status: {
    refreshToken: IDLE,
  },
};

const setRefreshToken = (state, action) => {
  const { refreshToken } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      refreshToken,
    },
    status: {
      ...state.status,
      refreshToken: SUCCEEDED,
    },
  };
};

const setStatusRefreshToken = (state, action) => {
  const { status } = action.payload;
  return {
    ...state,
    status: {
      ...state.status,
      refreshToken: status,
    },
  };
};

const setErrorRefreshToken = (state, action) => {
  const { errors } = action.payload;
  return {
    ...state,
    errors: {
      ...state.errors,
      refreshToken: errors,
    },
    status: {
      ...state.status,
      refreshToken: FAILED,
    },
  };
};

const ApiManagementReducer = helper.createReducer(initialState, {
  [types.SET_REFRESH_TOKEN]: setRefreshToken,
  [types.SET_STATUS_REFRESH_TOKEN]: setStatusRefreshToken,
  [types.SET_ERROR_REFRESH_TOKEN]: setErrorRefreshToken,
});

export default ApiManagementReducer;
