import {
  PAGE_ID,
  PAGE_TITLE,
  PAGE_URL,
  CV_TITLE,
  CV_URLS,
  ROUTE_URLS,
  MEMBER_NAME,
  AMOUNT,
  OTHER1,
  OTHER2,
  OTHER3,
  OTHER4,
  OTHER5,
  CONTENT_CATEGORY_NAME,
} from 'domain/fields';
import {
  maxLength,
  notContainControlCharacters,
  notContainSpaces,
  requiredValue,
  validId,
  validUrl,
  maxLine,
  notEqualValue,
} from 'services/validations/commonValidations';
import {
  PAGE_ID_MAX,
  PAGE_TITLE_MAX,
  PAGE_URL_CV_MAX,
  PAGE_URL_PV_MAX,
  CV_TITLE_MAX,
  URL_ITEM_MAX,
  URL_LENGTH_MAX,
  ATTRIBUTE_MAX,
  CONTENT_CATEGORY_NAME_MAX,
  TAG_FIELD,
} from 'domain/tag-management/consts';
import * as messageError from 'services/validations/messageErrorByCode';

export const cvRules = {
  [PAGE_ID]: [
    requiredValue(),
    notContainSpaces(),
    notEqualValue(['0'], messageError.PAGE_ID_CONTAIN_0_CHECK),
    validId(messageError.PAGE_ID_REGISTER_CHECK),
    maxLength(PAGE_ID_MAX),
  ],
  [PAGE_TITLE]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    maxLength(PAGE_TITLE_MAX),
  ],
  [PAGE_URL]: [notContainSpaces(), validUrl(), maxLength(PAGE_URL_CV_MAX)],
  [CV_TITLE]: [requiredValue(), notContainSpaces(), maxLength(CV_TITLE_MAX)],
  [ROUTE_URLS]: [maxLine(URL_ITEM_MAX, messageError.MAX_ITEM_CHECK)],
  [CV_URLS]: [
    // minLine(URL_ITEM_MIN, messageError.MIN_ITEM_CHECK), // This rule check based on condition
    maxLine(URL_ITEM_MAX, messageError.MAX_ITEM_CHECK),
  ],
  [MEMBER_NAME]: [maxLength(ATTRIBUTE_MAX)],
  [AMOUNT]: [maxLength(ATTRIBUTE_MAX)],
  [OTHER1]: [maxLength(ATTRIBUTE_MAX)],
  [OTHER2]: [maxLength(ATTRIBUTE_MAX)],
  [OTHER3]: [maxLength(ATTRIBUTE_MAX)],
  [OTHER4]: [maxLength(ATTRIBUTE_MAX)],
  [OTHER5]: [maxLength(ATTRIBUTE_MAX)],
};

export const pvRules = {
  [PAGE_ID]: [
    requiredValue(),
    notContainSpaces(),
    validId(messageError.PAGE_ID_REGISTER_CHECK),
    maxLength(PAGE_ID_MAX),
  ],
  [PAGE_TITLE]: [
    requiredValue(),
    notContainSpaces(),
    notContainControlCharacters(),
    maxLength(PAGE_TITLE_MAX),
  ],
  [PAGE_URL]: [notContainSpaces(), validUrl(), maxLength(PAGE_URL_PV_MAX)],
};

export const contentCategoryNameRules = {
  [CONTENT_CATEGORY_NAME]: [
    requiredValue(),
    notContainSpaces(),
    maxLength(CONTENT_CATEGORY_NAME_MAX),
    notContainControlCharacters(),
  ],
};

// Apply for tags: CV計測タグ, コンテンツカテゴリ
export const matchingUrlsRules = {
  [TAG_FIELD.MATCH_URL]: [
    notContainSpaces(),
    notContainControlCharacters(),
    maxLength(URL_LENGTH_MAX),
  ],
};
