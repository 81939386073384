import { combineReducers } from 'redux';
import types from 'store/common/types';

import CommonReducer from 'store/common';
import SettingReducers from 'store/settings';
import CacheReducers from 'store/cache';
import AppVersionReducer from 'store/version';
import AuthReducers from 'store/auth';

const appReducer = combineReducers({
  commonState: CommonReducer,
  settings: SettingReducers,
  cache: CacheReducers,
  appVersion: AppVersionReducer,
  user: AuthReducers,
});

// Create wrapper reducer for root reducer
const rootReducer = (state, action) => {
  if (action.type !== types.CLEAR_CACHE) {
    return appReducer(state, action);
  }

  // Exluding states that will be reset
  const {
    cache: { masterData, periodAnalysis },
    ...withoutCache
  } = state;

  return appReducer(
    { ...withoutCache, cache: { masterData, periodAnalysis } },
    action
  );
};

export default rootReducer;
