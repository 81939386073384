import { call, put, throttle } from 'redux-saga/effects';
import LtvCsvOrdersApi from 'services/api/LtvCsvOrdersApi';
import handleError from 'services/error/handleScopeError';
import types from './types';
import actions from './actions';

function* remove() {
  try {
    yield put(actions.requestStart());
    const response = yield call(LtvCsvOrdersApi.delete);

    yield put(actions.removed({ data: response.data }));
  } catch (error) {
    yield put(
      actions.requestFailed({ error: handleError(error.response, [400, 403]) })
    );
    //    yield put(actions.fetchList());
  }
}

export default function* watchView() {
  yield throttle(1000, types.DELETE_ALL_DATA, remove);
}
