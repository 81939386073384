import { createSelector } from 'reselect';
import { communicationStatus } from 'services/utils';
import settingsSelectors from 'store/settings/selectors';
import { getSortString } from 'domain/utils';
import tagSelectors from 'store/tag-management/selectors';

const getData = (state) => state.cache.capiSetting.data.list.data;
const getMetadata = (state) => state.cache.capiSetting.data.list.metadata;
const getSettings = (state) => state.cache.capiSetting.settings;
const getStatusList = (state) => state.cache.capiSetting.status.list;

const getSort = (state) => state.cache.capiSetting.settings.sort;

const getHistory = (state) => state.cache.capiSetting.data.history;
const getStatusHistory = (state) => state.cache.capiSetting.status.history;

const getSortRequest = createSelector([getSort], (sort) => {
  return getSortString(sort);
});

const getStatusSetting = (state) => state.cache.capiSetting.status.setting;
const getDetailSetting = (state) => state.cache.capiSetting.data.setting.item;

const getStatusMasterData = (state) =>
  state.cache.capiSetting.status.masterData;
const getMasterData = (state) => state.cache.capiSetting.data.masterData;

const getStatesForList = createSelector(
  [
    getData,
    getMetadata,
    getSettings,
    settingsSelectors.getLoadingByStatus([getStatusList]),
  ],
  (data, metadata, settings, isLoading) => {
    return {
      isLoading,
      data,
      metadata,
      settings,
    };
  }
);

const getStatesForHistory = createSelector(
  [getHistory, settingsSelectors.getLoadingByStatus([getStatusHistory])],
  (history, isLoading) => {
    return {
      history,
      isLoading,
    };
  }
);

const getStatesForSetting = createSelector(
  [
    getStatusSetting,
    settingsSelectors.getLoadingByStatus([tagSelectors.getStatusList]),
    tagSelectors.getList,
    getDetailSetting,
    getMasterData,
    getStatusMasterData,
  ],
  (
    statusSetting,
    statusTagList,
    tagListData,
    detailSetting,
    masterData,
    statusMasterData
  ) => {
    return {
      statusSetting: statusSetting === communicationStatus.LOADING,
      statusTagList,
      tagListData,
      detailSetting,
      masterData,
      statusMasterData: statusMasterData === communicationStatus.LOADING,
    };
  }
);

const capiSettingSelector = {
  getStatesForList,
  getSortRequest,
  getStatesForHistory,
  getStatesForSetting,
};

export default capiSettingSelector;
