import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../dragonball/dragon-ball.scss';
import './MediaSyncIcon.scss';

const MediaSyncIcon = ({className}) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip className="dragonball-tooltip variant-base">
          媒体シンク機能によって自動登録された項目です。
        </Tooltip>
      }
    >
      <svg className={`media-sync-icon ${className}`} />
    </OverlayTrigger>
  );
};

export default MediaSyncIcon;
