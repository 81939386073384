import React from 'react';
import { arrayOf, func, string, shape, bool } from 'prop-types';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TableTooltips from 'services/common/TableTooltips';

function UploadCsvDropdown(props) {
  const { items, onSelect, lpoPermission, mode } = props;

  return (
    <Dropdown className="d-inline" onSelect={(value) => onSelect(value)}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip className="tooltip-common">CSV一括操作</Tooltip>}
      >
        <Dropdown.Toggle
          bsPrefix="btn"
          variant="secondary"
          size="sm"
          className="btn-icon--no-text rounded-circle"
        >
          <i className="far fa-arrow-from-bottom fs-18" />
        </Dropdown.Toggle>
      </OverlayTrigger>
      <Dropdown.Menu className="dropdown-menu-icon dropdown-menu-padding dropdown-menu-shadow">
        {items.map((item) => (
          <>
            {item.key === 'register' && mode === 'lpo' && !lpoPermission ? (
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="bottom"
                overlay={
                  <Tooltip variant="disabled" placement="bottom">
                    {TableTooltips.lpoCancelledTooltipMassage}
                  </Tooltip>
                }
              >
                <div>
                  <Dropdown.Item key={item.key} eventKey={item.key} disabled>
                    {item.title}
                  </Dropdown.Item>
                </div>
              </OverlayTrigger>
            ) : (
              <Dropdown.Item key={item.key} eventKey={item.key}>
                {item.title}
              </Dropdown.Item>
            )}
          </>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

UploadCsvDropdown.propTypes = {
  onSelect: func,
  items: arrayOf(shape({ key: string, title: string })),
  lpoPermission: bool,
  mode: string,
};

UploadCsvDropdown.defaultProps = {
  onSelect: () => {},
  items: [],
  lpoPermission: false,
  mode: '',
};

export default UploadCsvDropdown;
