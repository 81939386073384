export const CPA = 'cpa';
export const CPC = 'cpc';
export const CTR = 'ctr';
/**
 * CV analyze screens usage only
 * @type {string}
 */
export const CNT_CV = 'cv_cnt';

/**
 * CV（CV別）
 * @type {string}
 */
export const CNT_DIRECT_CV = 'cnt_direct_cv';

/**
 * CV（合計）
 * @type {string}
 */
export const CV_TOTAL = 'cv_total';

/**
 * CV（合計）紐づけ前
 * @type {string}
 */
export const CV_TOTAL_LINKED_BEFORE = 'cv_total_cross_linked_before';

/**
 * CV（合計）紐づけ後
 * @type {string}
 */
export const CV_TOTAL_LINKED_AFTER = 'cv_total';

/**
 * CV（合計）差分
 * @type {string}
 */
export const CV_TOTAL_DIFFERENCE = 'cv_total_cross_difference';

/**
 * CV（合計）比率
 * @type {string}
 */
export const CV_TOTAL_RATE = 'cv_total_cross_rate';

/**
 * CVR（CV別）
 * @type {string}
 */
export const CNT_DIRECT_RATE_CV = 'cnt_direct_rate_cv';
/**
 * CVR（合計）
 * @type {string}
 */
export const CVR_TOTAL = 'cvr_total';

/**
 * CV名
 * @type {string}
 */
export const CV_NAME = 'cv_name';

/**
 * CV時間
 * @type {string}
 */
export const CV_DATE = 'cv_date';
export const ROAS = 'roas';
export const TCPA = 'tcpa';
export const TROAS = 'troas';

/**
 * カテゴリ (request)
 * @type {string}
 */
export const CATEGORY = 'category';

/**
 * カテゴリ ID (response)
 * @type {string}
 */
export const CATEGORY_ID = 'category_id';

/**
 * カテゴリ name (response)
 * @type {string}
 */
export const CATEGORY_NAME = 'category_name';

/**
 * ID field of category, when channel type != ad
 * @type {string}
 */
export const ENGINE_ID = 'engine_id';

/**
 * 媒体種別 (request)
 * @type {string}
 */
export const MEDIA = 'media';

/**
 * 媒体種別 id (response)
 * @type {string}
 */
export const MEDIA_ID = 'media_id';
/**
 * 媒体種別 name (response)
 * @type {string}
 */
export const MEDIA_NAME = 'media_name';

/**
 * クリック／流入回数
 * @type {string}
 */
export const CNT_ACCESS = 'cnt_access';

/**
 * セッション数
 * @type {string}
 */
export const CNT_SESSION = 'cnt_session';
/**
 * チャネル種別
 * @type {string}
 */
export const CHANNEL_ACCESS_TYPE = 'channel_access_type';

/**
 * Channel
 * @type {string}
 */
export const CHANNEL = 'channel';

/**
 * デバイス
 * @type {string}
 */
export const TERMINAL_TYPE = 'terminal_type';

/**
 * ユーザーID
 * @type {string}
 */
export const TRACKING_DATA = 'tracking_data';
/**
 * ユーザー名
 * @type {string}
 */
export const MEMBER_NAME = 'member_name';

/**
 * ランディングページURL
 * @type {string}
 */
export const LANDING_PAGE_URL = 'landing_page_url';

/**
 * ランディングページドメイン
 * @type {string}
 */
export const LANDING_PAGE_DOMAIN = 'landing_page_domain';
/**
 * 備考
 * @type {string}
 */
export const AD_NOTE = 'ad_note';

/**
 * 再配分CV
 * @type {string}
 */
export const RCV = 'rcv';

/**
 * 再配分CV(クロスデバイス)
 * @type {string}
 */
export const RCV_CROSS = 'rcv_cross';
/**
 * 再配分CV割合
 * @type {string}
 */
export const REALLOCATION_CV_RATIO = 'reallocation_cv_ratio';

/**
 * 再配分CV 紐づけ前
 * @type {string}
 */
export const RCV_LINKED_BEFORE = 'rcv_cross_linked_before';

/**
 * 再配分CV 紐づけ後
 * @type {string}
 */
export const RCV_LINKED_AFTER = 'rcv';

/**
 * 再配分CV 差分
 * @type {string}
 */
export const RCV_DIFFERENCE = 'rcv_cross_difference';

/**
 * 再配分CV 比率
 * @type {string}
 */
export const RCV_RATE = 'rcv_cross_rate';

/**
 * 再配分売上, 再配分売上総額
 * @type {string}
 */
export const RAMOUNT = 'ramount';
/**
 * 再配分売上, 再配分売上総額(クロスデバイス)
 * @type {string}
 */
export const RAMOUNT_CROSS = 'ramount_cross';
/**
 * 再配分売上割合
 * @type {string}
 */
export const REALLOCATION_SALES_RATIO = 'reallocation_sales_ratio';
/**
 * 再配分売上 紐づけ前
 * @type {string}
 */
export const RAMOUNT_LINKED_BEFORE = 'ramount_cross_linked_before';
/**
 * 再配分売上 紐づけ後
 * @type {string}
 */
export const RAMOUNT_LINKED_AFTER = 'ramount';
/**
 * 再配分売上 差分
 * @type {string}
 */
export const RAMOUNT_DIFFERENCE = 'ramount_cross_difference';
/**
 * 再配分売上 比率
 * @type {string}
 */
export const RAMOUNT_RATE = 'ramount_cross_rate';

/**
 * 出稿期間
 * @type {string}
 */
export const AD_DATE = 'ad_date';
/**
 * 初回接触 (CV analyze)
 * @type {string}
 */
export const AD_FIRST = 'ad_first';
/**
 * 初回接触 (non CV analyze)
 * @type {string}
 */
export const CNT_FIRST = 'cnt_first';

/**
 * 初回接触 紐づけ前
 * @type {string}
 */
export const CNT_FIRST_LINKED_BEFORE = 'cnt_first_cross_linked_before';

/**
 * 初回接触 紐づけ後
 * @type {string}
 */
export const CNT_FIRST_LINKED_AFTER = 'cnt_first';

/**
 * 初回接触 差分
 * @type {string}
 */
export const CNT_FIRST_DIFFERENCE = 'cnt_first_cross_difference';

/**
 * 初回接触 比率
 * @type {string}
 */
export const CNT_FIRST_RATE = 'cnt_first_cross_rate';

/**
 * 名称
 * @type {string}
 */
export const AD_NAME = 'ad_name';
/**
 * 売上, 売上総額, 売上金額
 * @type {string}
 */
export const AMOUNT = 'amount';
/**
 * 平均PV
 * @type {string}
 */
export const PER_PAGE_VIEW = 'per_page_view';
/**
 * 平均売上
 * @type {string}
 */
export const AMOUNT_AVG = 'amount_avg';
/**
 * 平均接触履歴回数
 * @type {string}
 */
export const CONTACT_HISTORY_AVG = 'contact_history_avg';
/**
 * 平均滞在時間
 * @type {string}
 */
export const TOTAL_STAY_TIME_AVG = 'total_stay_time_avg';
/**
 * 平均潜伏期間
 * @type {string}
 */
export const LATENCY_TIME_AVG = 'latency_time_avg';
/**
 * 広告ID
 * @type {string}
 */
export const AD_ID = 'ad_id';
/**
 * 広告グループ１
 * @type {string}
 */
export const AD_GROUP1 = 'ad_group1';

/**
 * 広告グループ１ ID (response)
 * @type {string}
 */
export const AD_GROUP1_ID = 'ad_group1_id';
/**
 * 広告グループ１ name (response)
 * @type {string}
 */
export const AD_GROUP1_NAME = 'ad_group1_name';
/**
 * 広告グループ２ (request)
 * @type {string}
 */
export const AD_GROUP2 = 'ad_group2';
/**
 * 広告グループ２ ID (response)
 * @type {string}
 */
export const AD_GROUP2_ID = 'ad_group2_id';
/**
 * 広告グループ２ name (response)
 * @type {string}
 */
export const AD_GROUP2_NAME = 'ad_group2_name';
/**
 * (媒体シンク)カテゴリー(request)
 * @type {string}
 */
export const SYNC_CATEGORY = 'sync_category';
/**
 * (媒体シンク)媒体種別(response)
 * @type {string}
 */
export const SYNC_CATEGORY_ID = 'sync_category_id';
/**
 * (媒体シンク) 媒体種別名(reponse)
 */
export const SYNC_CATEGORY_NAME = 'sync_category_name';
/**
 * 媒体(request, response)
 * @type {string}
 */
export const SYNC_MEDIA_ID = 'sync_media_id';
/**
 * 優先軸(response)
 */
export const PRIORITY_AXIS = 'priority_axis';
/**
 * 強調表示、ハイフン表示(response)
 */
export const EBIS_VIEW_PATTERN = 'ebis_view_pattern';
/**
 * 強調表示、ハイフン表示(response)
 */
export const SYNC_VIEW_PATTERN = 'sync_view_pattern';
/**
 * 媒体キャンペーン(request)
 * @type {string}
 */
export const MEDIA_SIDE_CAMPAIGN = 'media_side_campaign';
/**
 * 媒体キャンペーンID(response)
 * @type {string}
 */
export const MEDIA_SIDE_CAMPAIGN_ID = 'media_side_campaign_id';
/**
 * 媒体キャンペーン名(response)
 * @type {string}
 */
export const MEDIA_SIDE_CAMPAIGN_NAME = 'media_side_campaign_name';
/**
 * 媒体グループ(request)
 * @type {string}
 */
export const MEDIA_SIDE_GROUP = 'media_side_group';
/**
 * 媒体広告グループID(response)
 * @type {string}
 */
export const MEDIA_SIDE_GROUP_ID = 'media_side_group_id';
/**
 * 媒体広告グループ名(response)
 * @type {string}
 */
export const MEDIA_SIDE_GROUP_NAME = 'media_side_group_name';
/**
 * 媒体(request)
 */
export const MEDIA_ACCOUNT = 'media_account';
/**
 * 媒体(response)
 * @type {string}
 */
export const MEDIA_ACCOUNT_ID = 'media_account_id';
/**
 * 媒体(response)
 * @type {string}
 */
export const MEDIA_ACCOUNT_NAME = 'media_account_name';
/**
 * 媒体広告ID(response)
 * @type {string}
 */
export const MEDIA_SIDE_AD_ID = 'media_side_ad_id';
/**
 * 媒体広告名(response,request)
 * @type {string}
 */
export const MEDIA_SIDE_AD_NAME = 'media_side_ad_name';
/**
 * 媒体最終リンク先URL
 * @type {string}
 */
export const MEDIA_SIDE_FINAL_URL = 'media_side_final_url';

/**
 * カンマ区切り媒体広告ID(request,response)
 */
export const OPTIONAL_MEDIA_SIDE_AD_ID = 'optional_media_side_ad_id';

/**
 * カンマ区切り広告ID(request,response)
 */
export const OPTIONAL_AD_ID = 'optional_ad_id';

/**
 * 優先軸
 * @type {string}
 */
export const MEDIA_SYNC = 'media_sync';

/**
 * 広告コスト
 * @type {string}
 */
export const COST = 'cost';
/**
 * 広告コスト割合
 * @type {string}
 */
export const AD_COST_RATIO = 'ad_cost_ratio';
/**
 * 接触回数
 * @type {string}
 */
export const CNT_CONTACT = 'cnt_contact';
/**
 * 潜伏期間
 * @type {string}
 */
export const LATENCY_TIME = 'latency_time';
/**
 * 生産性（再配分CV）
 * @type {string}
 */
export const RCV_COST_EFFECTIVENESS = 'rcv_cost_effectiveness';
/**
 * 生産性（再配分売上）
 * @type {string}
 */
export const RAMOUNT_COST_EFFECTIVENESS = 'ramount_cost_effectiveness';
/**
 * 発生日時
 * @type {string}
 */
export const ACCESS_TIME = 'access_time';
/**
 * デバイス
 * @type {string}
 */
export const DEVICE = 'device';
/**
 * 直帰数
 * @type {string}
 */
export const CNT_RETURN = 'cnt_return';
/**
 * 直帰率
 * @type {string}
 */
export const PER_RETURN = 'per_return';
/**
 * 直接
 * @type {string}
 */
export const DIRECT_RATE = 'direct_rate';
/**
 * 直接効果
 * @type {string}
 */
export const AD_DIRECT = 'ad_direct';
/**
 * 総PV
 * @type {string}
 */
export const CNT_PV = 'cnt_pv';
/**
 * 総滞在時間
 * @type {string}
 */
export const TOTAL_STAY_TIME = 'total_stay_time';
/**
 * 総潜伏期間
 * @type {string}
 */
export const TOTAL_LATENCY_TIME = 'total_latency_time';
/**
 * 表示回数
 * @type {string}
 */
export const IMPRESSION = 'impression';
/**
 * 間接
 * @type {string}
 */
export const INDIRECT_RATE = 'indirect_rate';
/**
 * 間接効果 (CV analyze)
 * @type {string}
 */
export const AD_INDIRECT = 'ad_indirect';
/**
 * 間接効果（合計）
 * @type {string}
 */
export const INDIRECT_TOTAL = 'indirect_total';
/**
 * 間接効果10 (CV analyze)
 * @type {string}
 */
export const INDIRECT_10 = 'indirect_10';
/**
 * 間接効果2 (CV analyze)
 * @type {string}
 */
export const INDIRECT_2 = 'indirect_2';
/**
 * 間接効果2
 * @type {string}
 */
export const CNT_INDIRECT_2 = 'cnt_indirect_2';
/**
 * 間接効果3 (CV analyze)
 * @type {string}
 */
export const INDIRECT_3 = 'indirect_3';
/**
 * 間接効果3
 * @type {string}
 */
export const CNT_INDIRECT_3 = 'cnt_indirect_3';
/**
 * 間接効果4 (CV analyze)
 * @type {string}
 */
export const INDIRECT_4 = 'indirect_4';
/**
 * 間接効果4
 * @type {string}
 */
export const CNT_INDIRECT_4 = 'cnt_indirect_4';
/**
 * 間接効果5 (CV analyze)
 * @type {string}
 */
export const INDIRECT_5 = 'indirect_5';
/**
 * 間接効果5
 * @type {string}
 */
export const CNT_INDIRECT_5 = 'cnt_indirect_5';
/**
 * 間接効果6 (CV analyze)
 * @type {string}
 */
export const INDIRECT_6 = 'indirect_6';
/**
 * 間接効果6-10
 * @type {string}
 */
export const CNT_INDIRECT_OTHER = 'cnt_indirect_other';
/**
 * 間接効果7 (CV analyze)
 * @type {string}
 */
export const INDIRECT_7 = 'indirect_7';
/**
 * 間接効果8 (CV analyze)
 * @type {string}
 */
export const INDIRECT_8 = 'indirect_8';
/**
 * 間接効果9 (CV analyze)
 * @type {string}
 */
export const INDIRECT_9 = 'indirect_9';
/**
 * 項目1
 * @type {string}
 */
export const OTHER1 = 'other1';
/**
 * 項目2
 * @type {string}
 */
export const OTHER2 = 'other2';
/**
 * 項目3
 * @type {string}
 */
export const OTHER3 = 'other3';
/**
 * 項目4
 * @type {string}
 */
export const OTHER4 = 'other4';
/**
 * 項目5
 * @type {string}
 */
export const OTHER5 = 'other5';
/**
 * 抑制対象, 削減対象
 * @type {string}
 */
export const COL1 = 'col1';
/**
 *  減少再配分CV
 * @type {string}
 */
export const COL2_NUM = 'col2_num';
/**
 * 抑制コスト, 減少再配分売上
 * @type {string}
 */
export const COL2_COST = 'col2_cost';
/**
 *  再配分CV差分
 * @type {string}
 */
export const COL3_NUM = 'col3_num';
/**
 * コスト差分, 再配分売上差分
 * @type {string}
 */
export const COL3_COST = 'col3_cost';
/**
 * 期間（日／月／曜日／時間）
 * @type {string}
 */
export const PERIOD = 'date';
/**
 * 単価
 * @type {string}
 */
export const BASE_COST_TYPE = 'base_cost_type';
/**
 * クリック単価
 * @type {string}
 */
export const BASE_CPC = 'base_cpc';
/**
 * CV単価
 * @type {string}
 */
export const BASE_CPCONV = 'base_cpconv';
/**
 * 広告名
 * @type {string}
 */
export const KEYWORD = 'keyword';
/**
 * リンク先
 * @type {string}
 */
export const URLS = 'urls';
/**
 * リンク先URL
 * @type {string}
 */
export const URL = 'url';
/**
 * 親広告ID
 * @type {string}
 */
export const PARENT_AD_ID = 'parent_ad_id';
/**
 * 入稿用URL
 * @type {string}
 */
export const PASTE_URL = 'paste_url';
/**
 * 入稿用URL(Google 広告/YSS用)
 * @type {string}
 */
export const PASTE_URL_PARALLEL = 'paste_url_parallel';
/**
 * 入稿用URL(NS/CNAME用)
 * @type {string}
 */
export const PASTE_URL_NS_CNAME = 'paste_url_ns_cname';
/**
 * リンク先URL
 * @type {string}
 */
export const AD_URL = 'ad_url';
/**
 * リンク先タイトル
 * @type {string}
 */
export const PAGE_TITLE = 'page_title';
/**
 * テキスト原稿
 * @type {string}
 */
export const TEXT_DATA = 'text_data';
/**
 * バナー原稿
 * @type {string}
 */
export const BANNER = 'banner';
/**
 * 計測方式
 * @type {string}
 */
export const MEASUREMENT_TYPE = 'measurement_type';
/**
 * 入稿用URL
 * @type {string}
 */
export const AD_PASTE_URL = 'ad_paste_url';
/**
 * QRコード発行
 * @type {string}
 */
export const QR_CODE = 'qr_code';
/**
 * 出稿開始日
 */
export const AD_START_DATE = 'ad_start_date';
/**
 * 出稿終了日
 */
export const AD_END_DATE = 'ad_end_date';
/**
 * 担当代理店 (request)
 * @type {string}
 */
export const AGENCY = 'agency';
/**
 * 担当代理店 (id) (response)
 */
export const AGENCY_ID = 'agency_id';
/**
 * 担当代理店 (name) (response)
 */
export const AGENCY_NAME = 'agency_name';

export const SETTING_VALUE = 'setting_value';

export const AGENT_FLAG = 'agent_flag';

export const AGENCY_ROLES = 'agency_roles';
/**
 * ページID (response)
 * @type {string}
 */
export const PAGE_ID = 'page_id';
/**
 * ページタイトル (response)
 * @type {string}
 */
export const PAGE_NAME = 'page_name';
/**
 * ページURL (response)
 * @type {array}
 */
export const PAGE_URL = 'page_url';
/**
 * 整形後URL (response)
 * @type {string}
 */
export const PAGE_KEY = 'page_key';
/**
 * CV名 (response)
 * @type {string}
 */
export const CV_TITLE = 'cv_title';
/**
 * CV条件 (response)
 * @type {string}
 */
export const CV_CONDITION = 'condition';
/**
 * 経由ページ (response)
 * @type {array}
 */
export const ROUTE_URLS = 'route_urls';
/**
 * CVページ (response)
 * @type {array}
 */
export const CV_URLS = 'cv_urls';
/**
 * URL条件 (response)
 * @type {array}
 */
export const PAGE_URLS = 'page_urls';
/**
 * ページID (response)
 * @type {array}
 */
export const PAGE_IDS = 'page_ids';
/**
 * 登録日時 (response)
 * @type {string}
 */
export const REGIST_DATE = 'regist_date';
/**
 * 最終更新日時 (response)
 * @type {string}
 */
export const UPDATE_DATE = 'update_date';
/**
 * 優先順位 (response)
 * @type {number}
 */
export const RANK = 'rank';
/**
 * フラグメントを利用する
 * @type {string}
 */
export const USE_FRAGMENT = 'use_fragment';
/**
 * タグマネージャーの利用 (response)
 * @type {string}
 */
export const TAG_MANAGER_TYPE = 'tag_manager_type';
export const TAG_MANAGER_NAME = 'tag_manager_name';
/**
 * コンテンツカテゴリ
 * @type {string}
 */
export const CONTENT_CATEGORY = 'content_category'; // request
export const CONTENT_CATEGORY_ID = 'content_category_id'; // response
export const CONTENT_CATEGORY_NAME = 'content_category_name'; // response
/**
/**
 * サイトコンテンツ (response)
 * @type {string}
 */
export const OWNED_MEDIA_FLAG = 'owned_media_flag';
/**
 * User management fields
 */
export const USER_ID = 'user_id';

export const USER_PASSWORD = 'user_password';

export const COMPANY_NAME = 'company_name';

export const CHARGE_NAME = 'charge_name';

export const EMAIL = 'email';

export const USER_ROLES = 'user_roles';

/**
 * Capi setting fields
 */

export const CAPI_SETTING_ID = 'capi_setting_id';

export const CAPI_SETTING_NAME = 'capi_name';

export const MEDIUM_ID = 'medium_id';

export const UPDATE_BY = 'update_by';

export const CV_POINTS = 'cv_points';

export const PAUSE_FLAG = 'pause_flag';

export const EXECUTED_DATE = 'executed_date';

export const PIXEL_ID = 'pixel_id';

export const ACCESS_TOKEN = 'access_token';

export const AUTHEN_WITH_MCC_ACCOUNT = 'authen_with_mcc_account';

export const CUSTOMER_ID = 'customer_id';

export const LOGIN_CUSTOMER_ID = 'login_customer_id';

export const REFRESH_TOKEN = 'refresh_token';

export const LINE_TAG_ID = 'line_tag_id';

export const LINE_TAG_ACCESS_TOKEN = 'line_tag_access_token';

export const YAHOO_ACCOUNT_ID = 'account_id';

export const YAHOO_YDN_CONV_IO = 'yahoo_ydn_conv_io';

export const YAHOO_BASE_ACCOUNT_ID = 'x_z_base_account_id';

export const PIXEL_CODE = 'pixel_code';

export const DOMAIN_NAME = 'domain_name';

export const REDIRECT_FLAG = 'redirect_flag';

export const EMAIL_NOTIFICATION = 'send_mail_flag';

export const SEND_WITH_PRIVATE_INFO = 'send_with_private_info_flag';

export const SEND_MAIL_USERS = 'send_mail_users';

export const NOTIFICATION_CONTENT = 'notification_content';

export const ETLD1 = 'etld1';

/**
 * LTV fields
 */
export const ITEM = 'item';
export const OFFER = 'offer';
export const CNT_NEW_CV = 'cnt_new_cv';
export const CNT_NEW_CV_TOTAL = 'cnt_new_cv_total';
export const NEW_CVR = 'new_cvr';
export const NEW_CVR_TOTAL = 'new_cvr_total';
export const NEW_CPA_ACTUAL_RESULTS = 'new_cpa_actual_results';
export const NEW_CPA_UPPER_LIMIT = 'new_cpa_upper_limit';
export const NEW_CPA_DIFFERENCE = 'new_cpa_difference';
export const F2_CPA = 'f2_cpa';
export const F1_AVERAGE_AMOUNT = 'f1_average_amount';
export const F2_AVERAGE_AMOUNT = 'f2_average_amount';
export const F3_OVER_AVERAGE_AMOUNT = 'f3_over_average_amount';
export const F2_CV_RATIO = 'f2_cv_ratio';
export const F3_CV_RATIO = 'f3_cv_ratio';
export const PAYBACK_PERIOD_TARGET = 'payback_period_target';
export const PAYBACK_PERIOD_PREDICTION = 'payback_period_prediction';
export const PAYBACK_PERIOD_DIFFERENCE = 'payback_period_difference';
export const LTV_AMOUNT = 'ltv_amount';
export const LTV_AMOUNT_TOTAL = 'ltv_amount_total';
export const LTV_PROFIT = 'ltv_profit';
export const LTV_PROFIT_TOTAL = 'ltv_profit_total';
export const MARGINAL_PROFIT = 'marginal_profit';
export const ROI = 'roi';

/**
 * LTV Settings Fields
 */
export const LINKED_AD_ID = 'ads';

/**
 * MediaAdGroup1,2 Fields
 */
export const MEDIA_AGROUP12_ID = 'id';
export const MEDIA_ADGROUP12_NAME = 'name';

/**
 * Media Sync. Management
 */
export const ADVERTISER_ID = 'advertiser_id'; // 広告主ID
export const ADVERTISER_NAME = 'advertiser_name'; // 広告主表示名称
export const MEDIA_DISPLAY_NAME = 'media_display_name'; // 媒体（表示名）
export const ADVERTISING_PARAMETER = 'advertising_parameter'; // 入稿用パラメータ
export const EBIS_MEDIA = 'ebis_media'; // 媒体種別
export const EBIS_MEDIA_ID = 'ebis_media_id'; // 媒体種別_ID
export const EBIS_MEDIA_NAME = 'ebis_media_name'; // 媒体種別_名前
export const IS_IMPORTING_COSTS = 'is_importing_costs'; // 表示回数/広告コスト
export const LINKED_DATE = 'linked_date'; // 媒体データ更新日時
export const IS_ACTIVATED_FIELD = 'is_activated';
export const ACCOUNT_NAME = 'account_name'; // アカウントID
export const MARGINS = 'margins'; // 広告コストのマージン率
export const PASSWORD = 'password'; // パスワード

/**
 * 1st party cookie program
 *
 * ※ NS/CNAMEリダイレクト案件にて画面上の文言変更
 * 「1st Party Cookieプログラム」→「ITP対応プログラム」
 * 影響範囲が大きくなるため以下の変数名は維持
 */

export const MEASUREMENT_FQDN = 'measurement_fqdn';
export const PROGRAM_URL = 'program_url';
export const FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK =
  'first_party_cookie_program_url_check';
export const FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS =
  'first_party_cookie_program_url_status';
export const FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE =
  'first_party_cookie_program_cookie_value';
export const MODAL_MEASUREMENT_FQDN = 'measurement_fqdn';
export const MODAL_PROGRAM_URL = 'modal_program_url';
export const MODAL_FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK =
  'modal_first_party_cookie_program_url_check';
export const MODAL_FIRST_PARTY_COOKIE_PROGRAM_URL_STATUS =
  'modal_first_party_cookie_program_url_status';
export const MODAL_FIRST_PARTY_COOKIE_PROGRAM_COOKIE_VALUE =
  'modal_first_party_cookie_program_cookie_value';

/**
 * System setting
 */
export const CONVERSIONS = 'conversions';
export const CONVERSIONIDS = 'conversionids';
export const CONVERSION_ID = 'conversion_id';
export const CONVERSIONTYPE_NAME = 'conversiontype_name';
export const CONVERSION_TITLE = 'conversion_title';
export const RCV_DATA = 'rcv_data';
export const RCV_MODEL_OPTION = 'rcv_model_option';

/**
 * クロスデバイス差分比較
 */
export const CROSSDEVICE_DIFF_COMPARISON = 'crossdevice_diff_comparison';
export const NS_DOMAIN_FIELD = 'ns_domain';
export const CNAME_DOMAIN_FIELD = 'cname_domain';

/**
 * Impression Ad Cost (表示回数/広告コスト管理)
 */
export const EXECUTE_DATE = 'execute_date';
export const IMPORT_TYPE_NAME = 'import_type_name';
export const DOWNLOAD_BTN_UPLOADED_CSV = 'download_btn_uploaded_csv';
export const STATUS = 'status';
export const EXECUTE_USER = 'execute_user';
export const IMPORTING_TYPE = 'importing_type';
export const CSV_REGISTER_TYPE = 'csv_register_type';

/**
 * リンク先URL
 */
export const LINK_URL = 'link_url';
/**
 * リンク先タイトル
 */
export const LINK_PAGE_TITLE = 'link_page_title';
/**
 * 遷移割合
 */
export const TRANSITION_RATE = 'transition_rate';

/**
 * Search console setting fields
 */

export const SITE_URL = 'site_url';

export const FACEBOOK = 'fb';
export const LINE = 'ln';
export const GOOGLE = 'gf';
export const YAHOO_DISPLAY_AD = 'yd';
export const YAHOO_SEARCH_AD = 'ys';
export const TIKTOK = 'tt';

/**
 * Line add friend settings fields
 */
export const AUTH_ID = 'auth_id';
export const LINE_LOGIN_CHANNEL_ID = 'line_login_channel_id';
export const LINE_LOGIN_CHANNEL_SECRET = 'line_login_channel_secret';
export const LINE_LOGIN_ENDPOINT_URL = 'line_login_endpoint_url';
export const LINE_LOGIN_SCOPE = 'line_login_scope';
export const LINE_LOGIN_BOT_PROMPT = 'line_login_bot_prompt';
export const LINE_MESSAGING_API_CHANNEL_ID = 'line_messaging_api_channel_id';
export const LINE_MESSAGING_API_CHANNEL_SECRET =
  'line_messaging_api_channel_secret';
export const LINE_LOGIN_LIFF_ID = 'line_login_liff_id';
export const ADD_FRIEND_BUTTON_URL = 'add_friend_button_url';
export const CONVERSIONID = 'conversionid';
