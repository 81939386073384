import types from 'store/data-export/types';
import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';

const getList = () => helper.createAction(types.GET_LIST);

const setDataList = (data) =>
  helper.createAction(types.SET_DATA_LIST, { data });
const setStatusList = (status) =>
  helper.createAction(types.SET_STATUS_LIST, { status });
const setErrorList = (errors, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERROR_LIST, { errors }, scope);

const getListHistory = (id, callback) =>
  helper.createAction(types.GET_LIST_HISTORY, { id, callback });
const setDataListHistory = (data) =>
  helper.createAction(types.SET_DATA_LIST_HISTORY, { data });
const setStatusListHistory = (status) =>
  helper.createAction(types.SET_STATUS_LIST_HISTORY, { status });
const setErrorListHistory = (errors, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERROR_LIST_HISTORY, { errors }, scope);

const getDetail = (id, callback = () => {}) =>
  helper.createAction(types.GET_DETAIL, { id, callback });
const setDataDetail = (data) =>
  helper.createAction(types.SET_DATA_DETAIL, { data });
const setErrorDetail = (errors, scope) =>
  helper.createAction(types.SET_ERROR_DETAIL, { errors }, scope);
const setStatusDetail = (status) =>
  helper.createAction(types.SET_STATUS_DETAIL, { status });

const getMasterdata = () =>
  helper.createAction(types.GET_MASTERDATA);
const setMasterdata = (data) =>
  helper.createAction(types.SET_MASTERDATA, { data });
const setStatusMasterdata = (status) =>
  helper.createAction(types.SET_STATUS_MASTERDATA, { status });

const create = (data, callback = () => {}) =>
  helper.createAction(types.CREATE, { data, callback });
const update = (id, data, callback = () => {}) =>
  helper.createAction(types.UPDATE, { id, data, callback });
const deleteItems = (ids, callback) =>
  helper.createAction(types.DELETE_REPORT, { ids, callback });
const downloadCsv = (id, callback) =>
  helper.createAction(types.DOWNLOAD_CSV, { id, callback });

const getDataSystemSync = () => helper.createAction(types.GET_DATA_SYSTEM_SYNC);

const setStatusSystemSync = ({ statusFetching, statusSystemSync }) =>
  helper.createAction(types.SET_STATUS_SYSTEM_SYNC, {
    statusFetching,
    statusSystemSync,
  });

const applyView = (id, params, callback) =>
  helper.createAction(types.APPLY_VIEW, { id, params, callback });

const getMetadata = () => helper.createAction(types.GET_METADATA);
const setMetadata = (data) => helper.createAction(types.SET_METADATA, { data });
const setSatusMetadata = (status) =>
  helper.createAction(types.SET_STATUS_METADATA, { status });

const dataExportActions = {
  getList,
  setDataList,
  setErrorList,
  setStatusList,
  getListHistory,
  setDataListHistory,
  setStatusListHistory,
  setErrorListHistory,
  getDetail,
  setDataDetail,
  setErrorDetail,
  setStatusDetail,
  getMasterdata,
  setMasterdata,
  setStatusMasterdata,
  create,
  update,
  deleteItems,
  downloadCsv,
  getDataSystemSync,
  setStatusSystemSync,
  applyView,
  getMetadata,
  setMetadata,
  setSatusMetadata,
};

export default dataExportActions;
