import React from 'react';
import Spinner from 'views/atoms/loader/Spinner';

const LoadingScreen = () => {
  const styles = {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={styles}>
      <Spinner />
    </div>
  );
};

export default LoadingScreen;
