import types from 'store/external-services/types';
import helper from 'store/helper';
import { communicationStatus } from 'services/utils';

const { IDLE, FAILED, SUCCEEDED } = communicationStatus;

const initialState = {
  data: {
    list: {
      items: [],
    },
    detail: {
      item: {},
      errors: [],
    },
  },
  apiStatus: {
    list: {
      status: IDLE,
    },
    detail: {
      status: IDLE,
    },
  },
};

const setDataList = (state, action) => {
  const { data } = action.payload;

  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        items: data.data.services,
        errors: [],
      },
    },
    apiStatus: {
      ...state.apiStatus,
      list: {
        status: SUCCEEDED,
      },
    },
  };
};

const setErrorList = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        errors: action.payload.errors,
      },
    },
    apiStatus: {
      ...state.apiStatus,
      list: {
        ...state.apiStatus.list,
        status: FAILED,
      },
    },
  };
};

const setStatusList = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      list: {
        ...state.apiStatus.list,
        status: action.payload.status,
      },
    },
  };
};

const setStatusDetail = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    detail: {
      ...state.apiStatus.detail,
      status: action.payload.status,
    },
  },
});

const setErrorDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      errors: action.payload.errors,
    },
  },
  apiStatus: {
    ...state.apiStatus,
    detail: {
      ...state.apiStatus.detail,
      status: FAILED,
    },
  },
});

const setDataDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      item: action.payload.data,
      errors: [],
    },
  },
});

const ExternalServicesReducer = helper.createReducer(initialState, {
  [types.SET_DATA_LIST]: setDataList,
  [types.SET_ERROR_LIST]: setErrorList,
  [types.SET_STATUS_LIST]: setStatusList,
  [types.SET_STATUS_DETAIL]: setStatusDetail,
  [types.SET_ERROR_DETAIL]: setErrorDetail,
  [types.SET_DATA_DETAIL]: setDataDetail,
});

export default ExternalServicesReducer;
