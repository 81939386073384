import helper from 'store/helper';
import constants from 'store/logger/constant';
import types from './types';

// SKIP write log check version
const requestStart = () =>
  helper.createAction(types.REQUEST_START, {}, constants.SKIP_WRITE_LOG);
// Skip silent if error, make recheck at next time
const requestFailed = (error) =>
  helper.createAction(types.REQUEST_FAILED, error, constants.SCOPE_DONT_SHOW);
const checkVersionSucceeded = (data) =>
  helper.createAction(
    types.CHECK_VERSION_SUCCEEDED,
    data,
    constants.SKIP_WRITE_LOG
  );
const checkVersion = (data) =>
  helper.createAction(types.CHECK_VERSION, data, constants.SKIP_WRITE_LOG);
const forceReload = () =>
  helper.createAction(types.FORCE_RELOAD, {}, constants.SKIP_WRITE_LOG);

export default {
  requestStart,
  requestFailed,
  checkVersionSucceeded,
  checkVersion,
  forceReload,
};
