import pages from 'services/routes/pages';
import * as pathConstant from 'services/routes/constants';

const ViewNavBarConfig = {
  aside: {
    itemsCommon: [
      {
        screenId: pathConstant.INFLOW_ANALYSIS,
        title: pages[pathConstant.INFLOW_ANALYSIS].viewKey,
      },
      // This screen has been merged into INFLOW_ANALYSIS
      {
        screenId: pathConstant.CATEGORY_ANALYZE,
        title: pages[pathConstant.CATEGORY_ANALYZE].key,
      },
      {
        screenId: pathConstant.DETAILS_ANALYSIS,
        title: pages[pathConstant.DETAILS_ANALYSIS].key,
      },
      // This screen has been merged into INFLOW_ANALYSIS
      {
        screenId: pathConstant.COMPARE_PERIOD,
        title: pages[pathConstant.COMPARE_PERIOD].key,
      },
      {
        screenId: pathConstant.LANDING_PAGE_ANALYZE,
        title: pages[pathConstant.LANDING_PAGE_ANALYZE].key,
      },
      {
        screenId: pathConstant.CV_ATTRIBUTE,
        title: pages[pathConstant.CV_ATTRIBUTE].key,
      },
      {
        screenId: pathConstant.CV_FLOW,
        title: pages[pathConstant.CV_FLOW].key,
      },
      // This screen has been merged into INFLOW_ANALYSIS
      {
        screenId: pathConstant.COST_ALLOCATION,
        title: pages[pathConstant.COST_ALLOCATION].key,
      },
      {
        screenId: pathConstant.LPO_LINK,
        title: pages[pathConstant.LPO_LINK].viewKey,
      },
      {
        screenId: pathConstant.LPO_PERIOD,
        title: pages[pathConstant.LPO_PERIOD].viewKey,
      },
      {
        screenId: pathConstant.LOG_PAGE_ANALYZE,
        title: pages[pathConstant.LOG_PAGE_ANALYZE].viewKey,
      },
      {
        screenId: pathConstant.LOG_PERIOD_ANALYZE,
        title: pages[pathConstant.LOG_PERIOD_ANALYZE].viewKey,
      },
    ],
  },
};

export default ViewNavBarConfig;
