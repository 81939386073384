import React, { useCallback, useEffect, useReducer } from 'react';
import { arrayOf, func, string, bool } from 'prop-types';
import classNames from 'classnames';

import './FilterContactHistory.scss';

import * as DefinedConstant from 'domain/consts';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import CollapsableFilter from 'views/organism/filter/CollapsableFilter';
import { useSelector } from 'react-redux';
import settingsSelector from 'store/settings/selectors';
import { TAB_AD } from 'services/consts';

const {
  [DefinedConstant.FILTER_CONTACT_HISTORY_DIRECT]: direct,
  [DefinedConstant.FILTER_CONTACT_HISTORY_FIRST]: first,
  ...contactHistoryIndirectList
} = DefinedConstant.FILTER_CONTACT_HISTORY;

function reducer(state, action) {
  switch (action.type) {
    case 'reset':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function init(initState) {
  const optionsIndirectData = initState.filter(
    (item) =>
      ![
        DefinedConstant.FILTER_CONTACT_HISTORY_DIRECT,
        DefinedConstant.FILTER_CONTACT_HISTORY_FIRST,
      ].includes(item)
  );

  return {
    contactFirst: initState.includes(
      DefinedConstant.FILTER_CONTACT_HISTORY_FIRST
    ),
    contactDirect: initState.includes(
      DefinedConstant.FILTER_CONTACT_HISTORY_DIRECT
    ),
    groupIndirect: optionsIndirectData.length > 0,
    contactIndirect: optionsIndirectData,
  };
}

const FilterContactHistory = (props) => {
  const { name, title, tooltip, onChange, defaultValue, customView } = props;
  const currentTab = useSelector(settingsSelector.getTab);

  const [state, dispatch] = useReducer(reducer, defaultValue, init);

  useEffect(() => {
    dispatch({ type: 'reset', payload: init(defaultValue) });
  }, [defaultValue]);

  const handleChangeContactValues = useCallback(
    (setting) => {
      const contactValues = [];
      if (setting.contactDirect) {
        contactValues.push(DefinedConstant.FILTER_CONTACT_HISTORY_DIRECT);
      }
      if (setting.groupIndirect) {
        contactValues.push(...setting.contactIndirect);
      }
      if (setting.contactFirst) {
        contactValues.push(DefinedConstant.FILTER_CONTACT_HISTORY_FIRST);
      }
      onChange(name, contactValues);
    },
    [name, onChange]
  );

  const handleIndirectChange = useCallback(
    (values) => {
      handleChangeContactValues({
        ...state,
        contactIndirect: Object.keys(values).filter((key) => values[key]),
      });
    },
    [state, handleChangeContactValues]
  );

  const handleDirectToggle = () => {
    handleChangeContactValues({
      ...state,
      contactDirect: !state.contactDirect,
    });
  };
  const handleFirstToggle = () => {
    handleChangeContactValues({ ...state, contactFirst: !state.contactFirst });
  };
  const handleCheckboxIndirectToggle = () => {
    let contactIndirect = [];
    if (!state.groupIndirect && state.contactIndirect.length <= 0) {
      contactIndirect = Object.keys(contactHistoryIndirectList);
    }
    handleChangeContactValues({
      ...state,
      contactIndirect,
      groupIndirect: !state.groupIndirect,
    });
  };

  const contactClass = classNames({
    'filter-container-contact-history': true,
    'filter-container-contact-history--setting-view': customView,
  });

  const tooltipByTab =
    currentTab === TAB_AD
      ? '接触タイミングのみ指定されている場合は適用されません。広告・URL（ランディングページ）のいずれかの条件も合わせて設定してください。'
      : '接触タイミングのみ指定されている場合は適用されません。広告・検索ワード・表示チャネル・URL（ランディングページ）のいずれかの条件も合わせて設定してください。';

  return (
    <div className={contactClass}>
      <div className="filter-container-contact-history__item">
        {title}
        <DragonBall className="filter-tooltip">
          {tooltip || tooltipByTab}
        </DragonBall>
      </div>
      <div className="filter-container-contact-history__item">
        <EbisCheckbox
          name={DefinedConstant.FILTER_CONTACT_HISTORY_DIRECT}
          text="直接効果"
          checked={state.contactDirect}
          changeSelectBoxValue={handleDirectToggle}
        />
      </div>
      <div className="filter-container-contact-history__item filter-container-contact-history__item--indirect">
        <EbisCheckbox
          name={DefinedConstant.FILTER_CONTACT_HISTORY_DIRECT}
          text="間接効果"
          checked={state.groupIndirect}
          changeSelectBoxValue={handleCheckboxIndirectToggle}
        />
      </div>
      <div className="filter-container-contact-history__item">
        <CollapsableFilter
          items={contactHistoryIndirectList}
          selectedKeys={state.contactIndirect}
          onChange={handleIndirectChange}
          disabled={!state.groupIndirect}
        />
      </div>
      <div className="filter-container-contact-history__item">
        <EbisCheckbox
          name={DefinedConstant.FILTER_CONTACT_HISTORY_FIRST}
          text="初回接触"
          checked={state.contactFirst}
          changeSelectBoxValue={handleFirstToggle}
        />
      </div>
    </div>
  );
};

FilterContactHistory.propTypes = {
  name: bool.isRequired,
  customView: bool,
  title: string,
  tooltip: string,
  defaultValue: arrayOf(string),
  onChange: func,
};

FilterContactHistory.defaultProps = {
  customView: false,
  title: '接触タイミング',
  tooltip: '',
  defaultValue: [],
  onChange: () => {},
};

export default React.memo(FilterContactHistory);
