import { call, put, takeLatest, select } from 'redux-saga/effects';
import sharedSagas from 'store/sharedSagas';
import LineAddFriendApi from 'services/api/LineAddFriendApi';
import handleError from 'services/error/handleScopeError';
import { communicationStatus, HttpStatus } from 'services/utils';
import {
  LIST_STATUS_KEY,
  SETTING_STATUS_KEY,
} from 'domain/line-add-friend-settings/constants';
import { isEmpty } from 'lodash';

import actions from './actions';
import types from './types';
import selectors from './selectors';

const { BAD_REQUEST } = HttpStatus;
const { LOADING, SUCCEEDED } = communicationStatus;

function* handleGetData() {
  yield put(actions.setStatus(LOADING, LIST_STATUS_KEY));
  const sort = yield select(selectors.getSortRequest);
  const {
    data: { data, metadata },
  } = yield call(LineAddFriendApi.fetchData, sort);

  yield put(actions.setStatus(SUCCEEDED, LIST_STATUS_KEY));
  yield put(actions.setData({ data, metadata }));
}

function* handleGetDetail(action) {
  const { id, callback } = action.payload;
  yield put(actions.setStatus(LOADING, SETTING_STATUS_KEY));
  const {
    data: { data },
  } = yield call(LineAddFriendApi.getDetail, id);
  yield put(actions.setStatus(SUCCEEDED, SETTING_STATUS_KEY));
  yield put(actions.setDataSetting(data));
  callback({ action: action.type });
}

function* handleCreate(action) {
  const { data: dataRequest, callback } = action.payload;
  yield put(actions.setStatus(LOADING, SETTING_STATUS_KEY));
  const {
    data: { data, errors },
  } = yield call(LineAddFriendApi.create, dataRequest);
  yield put(actions.setStatus(SUCCEEDED, SETTING_STATUS_KEY));
  yield put(actions.setDataSetting(data));
  callback({ action: action.type, isError: !isEmpty(errors), errors });
}

function* handleUpdate(action) {
  const { id, data: dataRequest, callback } = action.payload;
  yield put(actions.setStatus(LOADING, SETTING_STATUS_KEY));
  const {
    data: { data, errors },
  } = yield call(LineAddFriendApi.update, id, dataRequest);
  yield put(actions.setStatus(SUCCEEDED, SETTING_STATUS_KEY));
  yield put(actions.setDataSetting(data));
  callback({ action: action.type, isError: !isEmpty(errors), errors });
}

function* handleDelete(action) {
  const { ids, callback } = action.payload;
  yield call(LineAddFriendApi.delete, ids);
  callback({ isError: false });
}

function* errorHandler(err, action) {
  const { callback = () => {} } = action.payload || {};
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [];
  switch (action.type) {
    case types.CREATE:
    case types.UPDATE:
    case types.DELETE:
      yield put(actions.setErrorSetting(errors, scope));
      break;
    default:
      break;
  }

  if (
    [types.DELETE, types.CREATE, types.UPDATE, types.GET_DETAIL].includes(
      action.type
    )
  ) {
    callback({ action: action.type, isError: true, errors });
  }

  yield put(actions.setErrors(errors, action.type, scope));
}

export default function* watch() {
  yield takeLatest(
    types.GET_DATA,
    sharedSagas.safe(errorHandler, handleGetData)
  );
  yield takeLatest(
    types.GET_DETAIL,
    sharedSagas.safe(errorHandler, handleGetDetail)
  );
  yield takeLatest(types.CREATE, sharedSagas.safe(errorHandler, handleCreate));
  yield takeLatest(types.UPDATE, sharedSagas.safe(errorHandler, handleUpdate));
  yield takeLatest(types.DELETE, sharedSagas.safe(errorHandler, handleDelete));
}
