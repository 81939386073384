import { MASTER_DATA_FILTER_LIMIT, API_UNLIMITED_VALUE } from 'domain/consts';
import {
  buildSearchMasterDataParams,
  buildSearchMasterdataPage,
} from 'domain/utils';
import { ACCOUNT_ENDPOINT, USER_MANAGEMENT } from 'services/routes/constants';
import { FILTER_OPERATOR_INCLUDE } from 'services/consts';
import pages from 'services/routes/pages';
import Api from './Api';

const MasterDataApiFactory = () => {
  const url = `${ACCOUNT_ENDPOINT}/system`;
  return {
    fetchDisplay: async () => Api.get(`${url}/display`),
    fetchCv: async (
      search = '',
      selected = [],
      offset = 0,
      limit = MASTER_DATA_FILTER_LIMIT
    ) =>
      Api.get(
        `${url}/conversion`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      ),
    fetchMedia: async (
      search = '',
      selected = [],
      offset = 0,
      limit = MASTER_DATA_FILTER_LIMIT
    ) =>
      Api.get(
        `${url}/media`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      ),
    fetchAdGroup1: async (
      search = '',
      selected = [],
      offset = 0,
      limit = MASTER_DATA_FILTER_LIMIT
    ) =>
      Api.get(
        `${url}/ad_group1`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      ),
    fetchAdGroup2: async (
      search = '',
      selected = [],
      offset = 0,
      limit = MASTER_DATA_FILTER_LIMIT
    ) =>
      Api.get(
        `${url}/ad_group2`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      ),
    fetchAgency: async (
      search = '',
      selected = [],
      offset = 0,
      limit = API_UNLIMITED_VALUE
    ) => {
      return Api.get(
        `${url}/agency`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      );
    },
    fetchMediaSideCampaign: async (
      search = '',
      selected = [],
      offset = 0,
      limit = MASTER_DATA_FILTER_LIMIT
    ) => {
      return Api.get(
        `${url}/media_side_campaign`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      );
    },
    fetchMediaSideGroup: async (
      search = '',
      selected = [],
      offset = 0,
      limit = MASTER_DATA_FILTER_LIMIT
    ) => {
      return Api.get(
        `${url}/media_side_group`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      );
    },
    fetchMediaAccount: async (
      search = '',
      selected = [],
      offset = 0,
      limit = MASTER_DATA_FILTER_LIMIT
    ) => {
      return Api.get(
        `${url}/media_account`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      );
    },
    fetchContentCategory: async (
      search = '',
      selected = [],
      offset = 0,
      limit = MASTER_DATA_FILTER_LIMIT
    ) => {
      return Api.get(
        `${url}/content-category`,
        buildSearchMasterDataParams(selected, offset, limit, search)
      );
    },
    fetchPage: async (params = {}) => {
      const {
        field = '', // page_id, page_title, page_url
        value = '',
        selected = [],
        operator = FILTER_OPERATOR_INCLUDE,
        limit = MASTER_DATA_FILTER_LIMIT,
      } = params;
      return Api.post(
        `${url}/page`,
        buildSearchMasterdataPage({
          field,
          value,
          selected,
          operator,
          limit,
        })
      );
    },
    fetchUsers: async () => Api.get(pages[USER_MANAGEMENT].endpoint),
  };
};

const MasterDataApi = MasterDataApiFactory();
export default MasterDataApi;
