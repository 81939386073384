const REQUEST_START = 'ltv-analyze/REQUEST_START';
const REQUEST_FAILED = 'ltv-analyze/REQUEST_FAILED';

const FETCH_REPORT = 'ltv-analyze/FETCH_REPORT';
const FETCH_REPORT_SUCCEEDED = 'ltv-analyze/FETCH_SUCCEEDED';

const UPDATE_PAGE = 'ltv-analyze/UPDATE_PAGE';
const UPDATE_SORT = 'ltv-analyze/UPDATE_SORT';

const CHANGE_SELECTED_TARGET = 'ltv-analyze/CHANGE_SELECTED_TARGET';
const SHOW_KPI_STATUS_MODAL = 'dashboard/SHOW_KPI_STATUS_MODAL';

const FETCH_STATUS = 'ltv/analyze/FETCH_STATUS';
const FETCH_STATUS_SUCCEEDED = 'ltv/analyze/FETCH_STATUS_SUCCEEDED';

export default {
  REQUEST_START,
  REQUEST_FAILED,

  FETCH_REPORT,
  FETCH_REPORT_SUCCEEDED,

  UPDATE_PAGE,
  UPDATE_SORT,

  CHANGE_SELECTED_TARGET,
  SHOW_KPI_STATUS_MODAL,

  FETCH_STATUS,
  FETCH_STATUS_SUCCEEDED,
};
