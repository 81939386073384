import Validator from 'services/validations/validator';

// Export default Validator class
export default Validator;

// Export rules creator
export * from 'services/validations/rulesCreator';

// Export custom validation methods
export * from 'services/validations/methods';
