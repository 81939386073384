import QRCode from 'qrcode';
import isEmpty from 'lodash/isEmpty';

const qrcodeToDataUrl = (url, type = 'png', width = 225) => {
  if (isEmpty(url)) return '';
  let qrImage = '';

  const opts = {
    errorCorrectionLevel: 'M',
    type: `image/${type}`,
    width,
    quality: 1,
    margin: 4,
    color: {
      dark: '#000000',
      light: '#FFFFFF',
    },
  };

  QRCode.toDataURL(url, opts, (err, src) => {
    if (err) throw err;
    qrImage = src;
  });

  return qrImage;
};

export default qrcodeToDataUrl;
