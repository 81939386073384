import React, { useEffect } from 'react';
import { node, number } from 'prop-types';

const VirtualScrollWrapper = React.forwardRef((props, ref) => {
  const { children, minHeight, offsetY } = props;

  const viewportStyle = { minHeight, position: 'relative' };
  const scrollableStyle = {
    willChange: 'transform',
    transform: `translateY(${offsetY}px)`,
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.classList.add('virtual-scroll');
    }
  }, [ref]);

  return (
    <div className="virtual-scroll__viewport" style={viewportStyle}>
      <div className="virtual-scroll__scrollable" style={scrollableStyle}>
        {children}
      </div>
    </div>
  );
});

VirtualScrollWrapper.propTypes = {
  children: node.isRequired,
  minHeight: number,
  offsetY: number,
};

VirtualScrollWrapper.defaultProps = {
  minHeight: null,
  offsetY: 0,
};

export default VirtualScrollWrapper;
