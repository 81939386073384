import * as DisplayItems from './display-items';

const SettingDisplayItems = {
  groupList: {
    [DisplayItems.CV_NAME]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.OTHER1]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.OTHER2]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.OTHER3]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.OTHER4]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.OTHER5]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.TERMINAL_TYPE]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.CONTACT_DIRECT]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT2]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT3]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT4]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT5]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT6]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT7]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT8]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT9]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT10]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_FIRST]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_DEVICE]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_CATEGORY]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AD_GROUP1]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AD_GROUP2]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AD_ID]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AD_NAME]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AGENCY]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_LANDING_PAGE_DOMAIN]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_SYNC_CATEGORY]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_MEDIA_SIDE_CAMPAIGN]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_MEDIA_SIDE_GROUP]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_MEDIA_SIDE_AD_ID]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_MEDIA_SIDE_AD_NAME]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_LANDING_PAGE_URL]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_CONTENT_CATEGORY]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_OPTIONAL_AD_ID]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  // エビス軸選択時に非表示にする項目
  denyEbisList: [
    DisplayItems.CONTACT_SYNC_CATEGORY,
    DisplayItems.CONTACT_MEDIA_SIDE_CAMPAIGN,
    DisplayItems.CONTACT_MEDIA_SIDE_GROUP,
    DisplayItems.CONTACT_MEDIA_SIDE_GROUP,
    DisplayItems.CONTACT_MEDIA_SIDE_AD_ID,
    DisplayItems.CONTACT_MEDIA_SIDE_AD_NAME,
    DisplayItems.CONTACT_OPTIONAL_AD_ID,
  ],
  // 媒体軸優先時に非表示にする項目
  denyMediaSyncList: [
    DisplayItems.CONTACT_CATEGORY,
    DisplayItems.CONTACT_AD_GROUP1,
    DisplayItems.CONTACT_AD_GROUP2,
    DisplayItems.CONTACT_AD_ID,
    DisplayItems.CONTACT_AD_NAME,
    DisplayItems.CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID,
  ],
  displayFreezeList: [DisplayItems.CV_NAME, DisplayItems.CV_ACCESS_TIME],
  orderList: {
    [DisplayItems.CV_NAME]: 1, // order in same group must be unique
    [DisplayItems.OTHER1]: 2,
    [DisplayItems.OTHER2]: 3,
    [DisplayItems.OTHER3]: 4,
    [DisplayItems.OTHER4]: 5,
    [DisplayItems.OTHER5]: 6,
    [DisplayItems.TERMINAL_TYPE]: 7,
    [DisplayItems.CONTACT_DIRECT]: 8,
    [DisplayItems.CONTACT_INDIRECT2]: 9,
    [DisplayItems.CONTACT_INDIRECT3]: 10,
    [DisplayItems.CONTACT_INDIRECT4]: 11,
    [DisplayItems.CONTACT_INDIRECT5]: 12,
    [DisplayItems.CONTACT_INDIRECT6]: 13,
    [DisplayItems.CONTACT_INDIRECT7]: 14,
    [DisplayItems.CONTACT_INDIRECT8]: 15,
    [DisplayItems.CONTACT_INDIRECT9]: 16,
    [DisplayItems.CONTACT_INDIRECT10]: 17,
    [DisplayItems.CONTACT_FIRST]: 18,
    [DisplayItems.LATENCY_TIME_AVG]: 19,
    [DisplayItems.CONTACT_AVG]: 20,
    [DisplayItems.AMOUNT]: 21,
    [DisplayItems.AMOUNT_AVG]: 22,
    [DisplayItems.CV]: 23,
    [DisplayItems.CONTACT_CHANNEL]: 1,
    [DisplayItems.CONTACT_DEVICE]: 2,
    [DisplayItems.CONTACT_CATEGORY]: 3, // order in same group must be unique
    [DisplayItems.CONTACT_AD_GROUP1]: 4,
    [DisplayItems.CONTACT_AD_GROUP2]: 5,
    [DisplayItems.CONTACT_AD_ID]: 6,
    [DisplayItems.CONTACT_AD_NAME]: 7,
    [DisplayItems.CONTACT_AGENCY]: 8,
    [DisplayItems.CONTACT_LANDING_PAGE_DOMAIN]: 9,
    [DisplayItems.CONTACT_SYNC_CATEGORY]: 3,
    [DisplayItems.CONTACT_MEDIA_SIDE_CAMPAIGN]: 4,
    [DisplayItems.CONTACT_MEDIA_SIDE_GROUP]: 5,
    [DisplayItems.CONTACT_MEDIA_SIDE_AD_ID]: 6,
    [DisplayItems.CONTACT_MEDIA_SIDE_AD_NAME]: 7,
    [DisplayItems.CONTACT_LANDING_PAGE_URL]: 10,
    [DisplayItems.CONTACT_CONTENT_CATEGORY]: 11,
    [DisplayItems.CONTACT_OPTIONAL_AD_ID]: 12,
    [DisplayItems.CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID]: 13,
  },
  displayDefaultList: [
    DisplayItems.CV_NAME,
    DisplayItems.CONTACT_DIRECT,
    DisplayItems.CONTACT_FIRST,
    DisplayItems.LATENCY_TIME_AVG,
    DisplayItems.CONTACT_AVG,
    DisplayItems.AMOUNT_AVG,
    DisplayItems.CV,
    // DisplayItems.CONTACT_CHANNEL,
    DisplayItems.CONTACT_CATEGORY,
    DisplayItems.CONTACT_SYNC_CATEGORY,
  ],
  sortDefaultList: {
    [DisplayItems.CV]: DisplayItems.SORT_DIRECTION_DESC,
  },
  requiredList: [DisplayItems.CV],
};

const SettingAdDisplayItems = {
  ...SettingDisplayItems,
};
const SettingAllDisplayItems = {
  ...SettingDisplayItems,
  groupList: {
    ...SettingDisplayItems.groupList,
    [DisplayItems.CONTACT_CHANNEL]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    DisplayItems.CONTACT_CHANNEL,
  ],
};

export const getAd = () => {
  const displayItemsBase = DisplayItems.getAd();
  return DisplayItems.getItems({
    ...SettingAdDisplayItems,
    displayItemsBase,
  });
};

export const getAdDefault = () => {
  const displayItemsBase = getAd();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAll = () => {
  const displayItemsBase = DisplayItems.getAll();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getCommon = () => {
  const displayItemsBase = DisplayItems.getCommon();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getCommonDefault = () => {
  const displayItemsBase = getCommon();
  return DisplayItems.getDefault(displayItemsBase);
};
