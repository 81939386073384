import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';

const ErrorModal = ({ latestError, showErrorNotify, handleConfirm, title }) => {
  const handleFocus = (event) => event.target.select();

  const body = (
    <>
      <div className="error-title">{title}</div>
      <Form.Group className="mb-0" controlId="exampleForm.ControlTextarea1">
        <Form.Control
          as="textarea"
          className="error-content"
          rows="4"
          value={JSON.stringify(latestError)}
          onChange={() => {}}
          onFocus={handleFocus}
        />
      </Form.Group>
    </>
  );
  return (
    <ConfirmModal
      isShow={showErrorNotify}
      title="システムエラーが発生しました"
      jsxContent={body}
      onSubmit={handleConfirm}
      cancelBtn={false}
      confirmText="OK"
    />
  );
};

ErrorModal.defaultProps = {
  latestError: [],
  title: 'Error Modal',
};

ErrorModal.propTypes = {
  latestError: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showErrorNotify: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default React.memo(ErrorModal);
