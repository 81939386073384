import { delay, put, call, takeLatest, select } from 'redux-saga/effects';
import { COST_ALLOCATION } from 'services/routes/constants';
import actions from 'store/cost-allocation/actions';
import types from 'store/cost-allocation/types';
import CostAllocationApi from 'services/api/CostAllocationApi';
import { HttpStatus } from 'services/utils';
import filterSelector from 'store/filters/selectors';
import settingsSelectors from 'store/settings/selectors';
import { convertApiReportResponse } from 'domain/responseUtils';
import sharedSagas from 'store/sharedSagas';

function* fetchData(params) {
  try {
    yield sharedSagas.waitingRedirect();

    const page = yield select(settingsSelectors.getPage);

    if (page !== COST_ALLOCATION) return;

    const { status, data } = yield call(CostAllocationApi.getDataTable, {
      ...params.payload.data,
      filters: yield select(filterSelector.getForApi),
    });
    if (status === 200) {
      yield put(actions.getSuccess(data));
    }
  } catch (error) {
    yield put(actions.getError(error));
  }
}

function* fetchDataFull(params) {
  try {
    yield sharedSagas.waitingRedirect();

    const page = yield select(settingsSelectors.getPage);

    if (page !== COST_ALLOCATION) return;

    const response = yield call(CostAllocationApi.getDataTable, {
      ...params.payload.data,
      filters: yield select(filterSelector.getForApi),
    });
    if (response.status === 200) {
      const { data } = convertApiReportResponse(response);

      yield put(actions.getFullDataSuccess(data));
    }
  } catch (error) {
    yield put(actions.getFullDataError(error));
  }
}

function* getBookmark() {
  try {
    yield delay(200);
    yield sharedSagas.waitingRedirect();
    const { status, data } = yield call(CostAllocationApi.getBookmark);
    if (status === HttpStatus.OK) {
      yield put(actions.getBookmarkSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getBookmarkError(error));
  }
}

function* updateBookmark(params) {
  yield delay(200);
  try {
    yield sharedSagas.waitingRedirect();
    yield call(CostAllocationApi.updateBookmark, params.payload);
    // No need wait API result
    // if (status === HttpStatus.OK) {
    //   yield put(actions.updateBookmarkSuccess(params.payload));
    // }
  } catch (error) {
    yield put(actions.updateBookmarkError(error));
  }
}

export default function* costAllocationSaga() {
  yield takeLatest(
    types.GET_FULL_DATA,
    sharedSagas.skipIfNotReady(fetchDataFull)
  );
  yield takeLatest(types.GET_DATA, sharedSagas.skipIfNotReady(fetchData));
  yield takeLatest(types.CHANGE_PAGE, fetchData);
  yield takeLatest(types.UPDATE_BOOKMARK, updateBookmark);
  yield takeLatest(types.GET_BOOKMARK, getBookmark);
}
