import React from 'react';
import PropTypes from 'prop-types';

const PageNotAuthorized = ({
  logConfirm,
  isLoggedIn,
  isTopUser,
  isNextUser,
  messege,
}) => {
  const getTitle = () => {
    return isLoggedIn && (isTopUser || isNextUser)
      ? '本サービスに対する権限がありません'
      : '本サービスに対する権限がありません';
  };

  // Cannot pass jsx content to redux
  const getContent = () => {
    let content = '';
    if (isLoggedIn && (isTopUser || isNextUser) === false) {
      content += `
      <div>
        お使いのユーザーIDでは、本サービスに対する操作が許可されていません。
        <br />
        ご契約元の管理者ユーザー様にご確認ください。
      </div>`;
    }

    return content;
  };

  logConfirm({
    title: getTitle(),
    content: (isTopUser || isNextUser) === false ? getContent() : messege,
  });

  return <></>;
};

PageNotAuthorized.propTypes = {
  logConfirm: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isTopUser: PropTypes.bool.isRequired,
  isNextUser: PropTypes.bool.isRequired,
  messege: PropTypes.string,
};

PageNotAuthorized.defaultProps = {
  messege: '',
};

export default React.memo(PageNotAuthorized);
