/* eslint-disable camelcase */
import types from 'store/ltv/settings/offer/types';
import helper, {
  STATUS_FAIL,
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
} from 'store/helper';

const initialState = {
  list: [],
  settings: {
    pagination: {
      currentPage: 1,
      totalItems: 0,
    },
    sort: {
      field: '',
      direction: '',
    },
  },
  selected: null,
  status: STATUS_IDLE, // API request POST/ PUT/ DELETE status
  fetchStatus: STATUS_IDLE, // Fetch list status
  error: null, // string | null | array,
};

const requestStart = (state) => {
  return {
    ...state,
    status: STATUS_LOADING,
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    status: STATUS_FAIL,
    error: data?.errors || null,
  };
};

const fetchList = (state, action) => {
  const { data } = action.payload;

  return {
    ...state,
    list: data.detail,
    fetchStatus: STATUS_SUCCEEDED,
  };
};

const fetchListStart = (state) => {
  return {
    ...state,
    fetchStatus: STATUS_LOADING,
    error: null,
  };
};

const fetch = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    data,
    fetchStatus: STATUS_SUCCEEDED,
  };
};

const remove = (state) => {
  return {
    ...state,
    status: STATUS_SUCCEEDED,
  };
};

const changeSelectedItem = (state, action) => {
  const id = action.payload;

  return {
    ...state,
    selected: id,
  };
};

const create = (state) => {
  return {
    ...state,
    status: STATUS_SUCCEEDED,
  };
};

const update = (state) => {
  return {
    ...state,
    status: STATUS_SUCCEEDED,
  };
};

const updatePage = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    fetchStatus: STATUS_LOADING,
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        currentPage: page,
      },
    },
  };
};

const updateCurrentItemNumber = (state, action) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        totalItems: action.payload,
      },
    },
  };
};

const updateSort = (state, action) => {
  const { field, direction } = action.payload;
  return {
    ...state,
    fetchStatus: STATUS_LOADING,
    settings: {
      ...state.settings,
      sort: {
        ...state.settings.sort,
        field,
        direction,
      },
    },
  };
};

const submitFailed = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    status: STATUS_FAIL,
    error: data.errors,
  };
};

const clearApiError = (state) => {
  return {
    ...state,
    status: STATUS_IDLE,
    error: null,
  };
};

const LtvOfferReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_SUCCEEDED]: fetch,
  [types.FETCH_LIST_START]: fetchListStart,
  [types.FETCH_LIST_SUCCEEDED]: fetchList,
  [types.CREATE_SUCCEEDED]: create,
  [types.UPDATE_SUCCEEDED]: update,
  [types.DELETE_SUCCEEDED]: remove,
  [types.SUBMIT_FAILED]: submitFailed,
  [types.CHANGE_SELECTED_ITEM]: changeSelectedItem,
  [types.UPDATE_CURRENT_ITEM_NUMBER]: updateCurrentItemNumber,
  [types.UPDATE_PAGE]: updatePage,
  [types.UPDATE_SORT]: updateSort,
  [types.CLEAR_API_RESULT]: clearApiError,
});

export default LtvOfferReducer;
