import helper from 'store/helper';
import {
  FILTER_UNREGISTERED_ITEM,
  CAMPAIGN_GROUP_FILTER_UNREGISTERED_ITEM,
  MEDIA_ACCOUNT_FILTER_UNREGISTERED_ITEM,
} from 'domain/consts';
import types from './types';

const initialState = {
  conversions: [],
  display: {
    ad_group1: '広告グループ１',
    ad_group2: '広告グループ２',
    ad_note: '備考',
    other1: '項目1',
    other2: '項目2',
    other3: '項目3',
    other4: '項目4',
    other5: '項目5',
  },
  cv: {},
  media: {},
  adGroup1: {},
  adGroup2: {},
  user: [],
  agency: {},
  mediaSideCampaign: {},
  mediaSideGroup: {},
  mediaAccount: {},
  contentCategory: {},
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  statusConversions: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null, // string | null,
  type: '', // There are two types: report, setting
  expires: null, // if not expire skip fetch data
  searchResult: {},
  searchResultTotal: 0,
  searchType: null,
};

/**
 * Update conversions
 * @param state
 * @param action
 */
const updateConversions = (state, action) => {
  const conversions = action.payload;
  return {
    ...state,
    conversions,
    statusConversions: 'succeeded',
  };
};

const updateExpires = (state, action) => {
  const expires = action.payload;
  return {
    ...state,
    expires,
  };
};

const requestStart = (state) => {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: 'failed',
    error,
  };
};

const fetchDisplaySucceeded = (state, action) => {
  return {
    ...state,
    display: action.payload,
    status: 'succeeded',
  };
};

const fetchCvSucceeded = (state, action) => {
  return {
    ...state,
    cv: action.payload,
    status: 'succeeded',
  };
};

const fetchMediaSucceeded = (state, action) => {
  return {
    ...state,
    media: { ...FILTER_UNREGISTERED_ITEM, ...action.payload },
    status: 'succeeded',
  };
};

const fetchUserSucceeded = (state, action) => {
  return {
    ...state,
    user: action.payload,
    status: 'succeeded',
  };
};

const fetchAdGroup1Succeeded = (state, action) => {
  return {
    ...state,
    adGroup1: { ...FILTER_UNREGISTERED_ITEM, ...action.payload },
    status: 'succeeded',
  };
};

const fetchAdGroup2Succeeded = (state, action) => {
  return {
    ...state,
    adGroup2: { ...FILTER_UNREGISTERED_ITEM, ...action.payload },
    status: 'succeeded',
  };
};
const fetchAgencySucceeded = (state, action) => {
  return {
    ...state,
    agency: { ...FILTER_UNREGISTERED_ITEM, ...action.payload },
    status: 'succeeded',
  };
};
const fetchContentCategorySucceeded = (state, action) => {
  return {
    ...state,
    contentCategory: { ...FILTER_UNREGISTERED_ITEM, ...action.payload },
    status: 'succeeded',
  };
};

const searchSucceeded = (state, action) => {
  return {
    ...state,
    searchResult: action.payload.items,
    status: 'succeeded',
    searchResultTotal: action.payload.total,
    searchType: action.payload.type,
  };
};

const fetchAllSucceeded = (state, action) => {
  return {
    ...state,
    display: action.payload.display,
    cv: action.payload.cv,
    conversions: action.payload.conversions,
    agency: { ...FILTER_UNREGISTERED_ITEM, ...action.payload.agency },
    media: { ...FILTER_UNREGISTERED_ITEM, ...action.payload.media },
    adGroup1: { ...FILTER_UNREGISTERED_ITEM, ...action.payload.adGroup1 },
    adGroup2: { ...FILTER_UNREGISTERED_ITEM, ...action.payload.adGroup2 },
    mediaSideCampaign: {
      ...CAMPAIGN_GROUP_FILTER_UNREGISTERED_ITEM,
      ...action.payload.mediaSideCampaign,
    },
    mediaSideGroup: {
      ...CAMPAIGN_GROUP_FILTER_UNREGISTERED_ITEM,
      ...action.payload.mediaSideGroup,
    },
    mediaAccount: {
      ...MEDIA_ACCOUNT_FILTER_UNREGISTERED_ITEM,
      ...action.payload.mediaAccount,
    },
    contentCategory: {
      ...FILTER_UNREGISTERED_ITEM,
      ...action.payload.contentCategory,
    },
    status: 'succeeded',
  };
};

const updateMasterDataSucceeded = (state, action) => {
  return {
    ...state,
    cv: action.payload.cv,
    agency: { ...FILTER_UNREGISTERED_ITEM, ...action.payload.agency },
    media: { ...FILTER_UNREGISTERED_ITEM, ...action.payload.media },
    adGroup1: { ...FILTER_UNREGISTERED_ITEM, ...action.payload.adGroup1 },
    adGroup2: { ...FILTER_UNREGISTERED_ITEM, ...action.payload.adGroup2 },
    mediaSideCampaign: {
      ...CAMPAIGN_GROUP_FILTER_UNREGISTERED_ITEM,
      ...action.payload.mediaSideCampaign,
    },
    mediaSideGroup: {
      ...CAMPAIGN_GROUP_FILTER_UNREGISTERED_ITEM,
      ...action.payload.mediaSideGroup,
    },
    mediaAccount: {
      ...MEDIA_ACCOUNT_FILTER_UNREGISTERED_ITEM,
      ...action.payload.mediaAccount,
    },
    contentCategory: {
      ...FILTER_UNREGISTERED_ITEM,
      ...action.payload.contentCategory,
    },
    status: 'succeeded',
  };
};

const setType = (state, action) => {
  return {
    ...state,
    type: action.payload.type,
  };
};

const MasterDataReducer = helper.createReducer(initialState, {
  [types.UPDATE_CONVERSIONS]: updateConversions,
  [types.UPDATE_EXPIRES]: updateExpires,
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_DISPLAY_SUCCEEDED]: fetchDisplaySucceeded,
  [types.FETCH_CV_SUCCEEDED]: fetchCvSucceeded,
  [types.FETCH_MEDIA_SUCCEEDED]: fetchMediaSucceeded,
  [types.FETCH_AD_GROUP1_SUCCEEDED]: fetchAdGroup1Succeeded,
  [types.FETCH_AD_GROUP2_SUCCEEDED]: fetchAdGroup2Succeeded,
  [types.FETCH_AGENCY_SUCCEEDED]: fetchAgencySucceeded,
  [types.FETCH_CONTENT_CATEGORY_SUCCEEDED]: fetchContentCategorySucceeded,
  [types.SEARCH_SUCCEEDED]: searchSucceeded,
  [types.FETCH_ALL_SUCCEEDED]: fetchAllSucceeded,
  [types.UPDATE_MASTERDATA_SUCCEEDED]: updateMasterDataSucceeded,
  [types.SET_TYPE]: setType,
  [types.FETCH_USER_SUCCEEDED]: fetchUserSucceeded,
});

export default MasterDataReducer;
