import { createSelector } from 'reselect';

import {
  CV_METRICS,
  NEW_CV_METRICS,
  NEW_CPA_METRICS,
  PAYBACK_PERIOD_METRICS,
} from 'domain/consts';
import {
  createFieldsFromConversions,
  createNewCpaFields,
  createPaybackPeriodFields,
} from 'domain/utils';

import masterDataSelectors from 'store/master-data/selectors';
import displayItemsSelectors from 'store/display-items/selectors';
import settingsSelectors from 'store/settings/selectors';

import {
  createTableHeaders,
  getStartEndDate,
  timezoneFormat,
} from 'services/utils';
import { isEmpty } from 'lodash';

const getSort = (state) => state.cache.ltvAnalyze.table.settings.sort;
const getPagination = (state) =>
  state.cache.ltvAnalyze.table.settings.pagination;

const getListReport = (state) => state.cache.ltvAnalyze.table.data.list;
const getPredictionMetrics = (state) =>
  state.cache.ltvAnalyze.table.settings.predictionMetrics || [];

const getStatus = (state) => state.cache.ltvAnalyze.status;
const getStatusTable = (state) => state.cache.ltvAnalyze.table.status;
const getApiStatus = (state) => state.cache.ltvAnalyze.apiStatus.status.status;
const getSumReport = (state) => state.cache.ltvAnalyze.table.data.sum;

const getCvMetrics = (state) => {
  const conversions = masterDataSelectors.conversionsForDisplaySelector(state);
  const metricSelected = displayItemsSelectors.getMetricsSelector(state);
  return createFieldsFromConversions(
    conversions,
    metricSelected.filter(
      (metric) => CV_METRICS.includes(metric) || NEW_CV_METRICS.includes(metric)
    )
  );
};

const getNewCpaMetrics = (state) => {
  const metricSelected = displayItemsSelectors.getMetricsSelector(state);
  return createNewCpaFields(
    metricSelected.filter((metric) => NEW_CPA_METRICS.includes(metric))
  );
};

const getPaybackPeriodMetrics = (state) => {
  const metricSelected = displayItemsSelectors.getMetricsSelector(state);
  return createPaybackPeriodFields(
    metricSelected.filter((metric) => PAYBACK_PERIOD_METRICS.includes(metric))
  );
};

const dimensionsSelector = createSelector(
  [
    displayItemsSelectors.getDimensionsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (dimensions, displayItems) =>
    displayItems
      .filter((item) => item.displayFreeze && dimensions.includes(item.key))
      .map((item) => item.key)
);

const metricsSelector = createSelector(
  [
    displayItemsSelectors.getMetricsSelector,
    displayItemsSelectors.getItemsReport,
  ],
  (metrics, displayItems) => {
    return displayItems
      .filter((item) => !item.displayFreeze && metrics.includes(item.key))
      .map((item) => item.key);
  }
);

const sortSelector = createSelector(
  [
    dimensionsSelector,
    metricsSelector,
    getCvMetrics,
    getNewCpaMetrics,
    getPaybackPeriodMetrics,
    getSort,
  ],
  (
    dimensions,
    metrics,
    cvMetrics,
    newCpaMetrics,
    paybackPeriodMetrics,
    sort
  ) => {
    const newMetrics = [...metrics];
    cvMetrics
      .filter((cntDirect) => cntDirect.children.length > 0)
      .forEach((cntDirect) =>
        cntDirect.children.map((conversion) => newMetrics.push(conversion.name))
      );
    if (newCpaMetrics.children.length > 0) {
      newCpaMetrics.children.map((newCpaColumn) =>
        newMetrics.push(newCpaColumn.name)
      );
    }
    if (paybackPeriodMetrics.children.length > 0) {
      paybackPeriodMetrics.children.map((paybackPeriodColumn) =>
        newMetrics.push(paybackPeriodColumn.name)
      );
    }

    if (sort && dimensions.concat(newMetrics).includes(sort.field)) {
      return { sort: (sort.direction === 'asc' ? '' : '-') + sort.field };
    }

    return {};
  }
);

// Create header data
export const dimensionsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const dimensions = displayItems
    .filter((item) => item.displayFreeze)
    .map((item) => {
      return {
        text: item.title,
        name: item.key,
      };
    });

  return dimensions;
};

export const metricsConfig = (state) => {
  const displayItems = displayItemsSelectors.getItemsReport(state);
  const cvMetrics = getCvMetrics(state);
  const newCpaMetrics = getNewCpaMetrics(state);
  const paybackPeriodMetrics = getPaybackPeriodMetrics(state);
  const predictionMetrics = getPredictionMetrics(state);
  const metrics = [];

  let cvMetricsAdded = false;
  let newCpaMetricsAdded = false;
  let paybackPeriodMetricsAdded = false;

  displayItems
    .filter((item) => !item.displayFreeze)
    .forEach((item) => {
      if (CV_METRICS.includes(item.key) || NEW_CV_METRICS.includes(item.key)) {
        if (!cvMetricsAdded) {
          metrics.push(...cvMetrics);
          cvMetricsAdded = true;
        }
      } else if (NEW_CPA_METRICS.includes(item.key)) {
        if (!newCpaMetricsAdded) {
          metrics.push(newCpaMetrics);
          newCpaMetricsAdded = true;
        }
      } else if (PAYBACK_PERIOD_METRICS.includes(item.key)) {
        if (!paybackPeriodMetricsAdded) {
          metrics.push(paybackPeriodMetrics);
          paybackPeriodMetricsAdded = true;
        }
      } else {
        metrics.push({
          text: item.title,
          name: item.key,
        });
      }
    });

  // Add prediction information to metrics
  metrics.forEach((metric) => {
    if (metric.children) {
      metric.children.forEach((child) => {
        const tmp = child;
        tmp.isPrediction = predictionMetrics.includes(child.name);
      });
    } else {
      const tmp = metric;
      tmp.isPrediction = predictionMetrics.includes(metric.name);
    }
  });

  return metrics;
};

export const groupsConfig = () => [];

const getConfig = (state) => {
  return {
    dimensions: dimensionsConfig(state),
    metrics: metricsConfig(state),
    groups: groupsConfig(),
  };
};

const headerSelector = createSelector(
  [
    settingsSelectors.getTab,
    dimensionsSelector,
    metricsSelector,
    getCvMetrics,
    getNewCpaMetrics,
    getPaybackPeriodMetrics,
    getSort,
    getConfig,
  ],
  (
    channel,
    dimensions,
    metrics,
    cvMetrics,
    newCpaMetrics,
    paybackPeriodMetrics,
    sort,
    itemsConfig
  ) => {
    return createTableHeaders({
      channel,
      dimensions,
      metrics: [
        ...metrics,
        ...cvMetrics.map((cntDirect) => cntDirect.name),
        newCpaMetrics.name,
        paybackPeriodMetrics.name,
      ],
      sorts: [sort],
      itemsConfig,
    });
  }
);

// 期間選択
const getTargetPeriod = (state) =>
  state.cache.ltvAnalyze.table.settings.targetPeriod;
// 期間取得
const getLastOrderDate = (state) =>
  state.cache.ltvAnalyze.table.data.detail.last_order_date;
const getAnalyzedDate = (state) => {
  let analyzedDate = state.cache.ltvAnalyze.table.data.detail.analyzed_date;
  if (!isEmpty(analyzedDate)) {
    analyzedDate = timezoneFormat(analyzedDate);
  }
  return analyzedDate;
};

// ステータス取得 (前日分データの取込ステータス: bool)
const getAnalyzedStatus = (state) =>
  state.cache.ltvAnalyze.table.data.detail.status;

// 開始日と終了日を取得
const periodSelector = (state) => {
  let periods = state.cache.ltvAnalyze.table.settings.targetPeriod;
  if (periods === null || periods === undefined || periods === '') {
    periods = state.cache.ltvAnalyze.table.settings.period;
  } else {
    periods = getStartEndDate(periods);
  }
  return periods;
};

export default {
  getListReport,
  getPagination,
  getStatus,
  getStatusTable,
  getApiStatus,
  dimensionsSelector,
  metricsSelector,
  headerSelector,
  sortSelector,
  getSumReport,

  getTargetPeriod,
  getLastOrderDate,
  getAnalyzedDate,
  getAnalyzedStatus,
  periodSelector,
  getConfig,
};
