import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { INFLOW_ANALYSIS } from 'services/routes/constants';
import ViewMenuItem from 'views/molecules/viewNavBar/component/ViewMenuItem';

const ViewMenuListItem = ({
  item,
  active,
  isDisabled,
  handleChangePage,
  integrationTitle,
}) => {
  const { t } = useTranslation();

  return (
    <ViewMenuItem
      title={item.screenId !== INFLOW_ANALYSIS ? t(item.title) : integrationTitle}
      screenId={item.screenId}
      active={active}
      disabled={isDisabled}
      handleChangePage={handleChangePage}
    />
  );
};

ViewMenuListItem.defaultProps = {
  isDisabled: false,
  integrationTitle: '',
};

ViewMenuListItem.propTypes = {
  item: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  handleChangePage: PropTypes.func.isRequired,
  integrationTitle: PropTypes.string,
};

export default React.memo(ViewMenuListItem);
