import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { shape, func, bool } from 'prop-types';
import {
  PAGE_TITLE,
  CV_TITLE,
  TAG_MANAGER_TYPE,
  CV_CONDITION,
  ROUTE_URLS,
  USE_FRAGMENT,
  CV_URLS,
} from 'domain/fields';
import {
  MATCH_TYPES,
  MATCH_TYPE_DEFAULT,
  CONDITION_CV_OPTIONS,
  CONDITION_CV,
  TAG_MANAGER_GTM,
  TAG_MANAGER_OTHER,
  TAG_FIELD,
} from 'domain/tag-management/consts';
import iconGtm from 'assets/images/gtm.png';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import FormMatching from 'views/molecules/FormGroup/components/FormMatching';
import EbisDropdown from 'views/atoms/dropdown/EbisDropdown';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import TagCard from 'views/atoms/card/TagCard/TagCard';

const {
  HAS_ROUTE,
  PAGE_CONDITION,
  MATCH_TYPE,
  MATCH_URL,
  MATCH_URL_ERROR,
} = TAG_FIELD;
const { PAGE_PV_URL, PAGE_PV_ID, BUTTON_CLICK, SCROLL } = CONDITION_CV;

const ConversionSetting = (props) => {
  const { info, onChange, isCapiSingle } = props;

  const {
    [CV_TITLE]: { value: cvTitle },
    [PAGE_TITLE]: { value: pageTitle },
    [CV_CONDITION]: { value: cvCondition },
    [PAGE_CONDITION]: { value: pageCondition },
    [ROUTE_URLS]: { value: routeUrls },
    [CV_URLS]: { value: cvUrls },
    [TAG_MANAGER_TYPE]: { value: tagManagerType },
    [HAS_ROUTE]: { value: hasRoute },
  } = info;

  const isNotCoditionPV = [BUTTON_CLICK, SCROLL].includes(cvCondition);
  const disabledCvUrls = isNotCoditionPV || pageCondition === PAGE_PV_ID;
  const optionCondition = isCapiSingle
    ? CONDITION_CV_OPTIONS.filter(
        (condition) => condition.key !== CONDITION_CV.SCROLL
      )
    : CONDITION_CV_OPTIONS;

  const handleSelectTag = (value) => () => {
    onChange(TAG_MANAGER_TYPE, value);
  };

  const handleSelectRoute = (name) => (checked) => {
    onChange(name, checked);

    // Clear error of route_urls when this field disabled
    if (!checked) {
      const urls = routeUrls.map((item) => {
        return { ...item, [MATCH_URL_ERROR]: null };
      });
      onChange(ROUTE_URLS, urls);
    }
  };

  const handleSelectCondition = (isSetPage) => (value) => {
    const valueConverted = +value;
    if (isSetPage) {
      onChange(PAGE_CONDITION, valueConverted);
    } else {
      onChange(CV_CONDITION, valueConverted);
      if (valueConverted !== PAGE_PV_URL) {
        handleSelectTag(TAG_MANAGER_OTHER)();
      }
    }

    // Clear error of cv_urls when this field disabled
    const isDisabledCvUrls =
      (isSetPage && valueConverted !== cvCondition) ||
      (!isSetPage && valueConverted !== pageCondition);

    if (isDisabledCvUrls) {
      const urls = cvUrls.map((item) => {
        return { ...item, [MATCH_URL_ERROR]: null };
      });
      onChange(CV_URLS, urls);
    }
  };

  const handleUrlsMaching = (name) => (urls) => {
    onChange(name, urls);
  };

  useEffect(() => {
    if (cvTitle === undefined) {
      onChange(CV_TITLE, pageTitle);
    }
  }, [cvTitle, pageTitle, onChange]);

  return (
    <Form className="form-conversion-setting" autoComplete="off">
      <FormGroup
        required
        variant={CV_TITLE}
        label={info[CV_TITLE].label}
        note={info[CV_TITLE].note}
        tooltip={
          isCapiSingle
            ? info[CV_TITLE].tooltipCapiSingle
            : info[CV_TITLE].tooltip
        }
        isError={!!info[CV_TITLE].error}
        styleError="keep-position"
      >
        <FormInput
          name={CV_TITLE}
          value={cvTitle}
          error={info[CV_TITLE].error}
          onChange={onChange}
        />
      </FormGroup>
      {!isCapiSingle && (
        <FormGroup
          label={info[TAG_MANAGER_TYPE].label}
          tooltip={info[TAG_MANAGER_TYPE].tooltip}
        >
          <TagCard
            icon={iconGtm}
            text="Googleタグマネージャー"
            actived={tagManagerType === TAG_MANAGER_GTM}
            disabled={isNotCoditionPV}
            onChange={handleSelectTag(TAG_MANAGER_GTM)}
          />
          <TagCard
            text="その他のタグマネージャー/利用なし"
            actived={tagManagerType === TAG_MANAGER_OTHER}
            onChange={handleSelectTag(TAG_MANAGER_OTHER)}
          />
        </FormGroup>
      )}
      <FormGroup
        variant={CV_CONDITION}
        label={info[CV_CONDITION].label}
        tooltip={info[CV_CONDITION].tooltip}
      >
        <EbisDropdown
          optionObject={optionCondition}
          defaultVal={cvCondition}
          error={info[CV_CONDITION].error}
          selectCallback={handleSelectCondition(false)}
        />
      </FormGroup>
      <div className="ebis-form-group--matching">
        <FormGroup
          label={info[PAGE_CONDITION].label}
          tooltip={info[PAGE_CONDITION].tooltip}
        >
          <EbisRadio
            name={PAGE_CONDITION}
            label="URL指定"
            value={PAGE_PV_URL}
            checked={pageCondition === PAGE_PV_URL}
            disabled={isNotCoditionPV}
            onChange={handleSelectCondition(true)}
          />
          <EbisRadio
            name={PAGE_CONDITION}
            label="ページID指定"
            value={PAGE_PV_ID}
            checked={pageCondition === PAGE_PV_ID}
            disabled={isNotCoditionPV}
            onChange={handleSelectCondition(true)}
          />
        </FormGroup>
        <FormGroup
          variant={ROUTE_URLS}
          label={info[ROUTE_URLS].label}
          tooltip={info[ROUTE_URLS].tooltip}
        >
          <EbisCheckbox
            text={info[HAS_ROUTE].label}
            name={!hasRoute}
            checked={hasRoute}
            changeSelectBoxValue={handleSelectRoute(HAS_ROUTE)}
          />
          {hasRoute && (
            <>
              <FormMatching
                items={routeUrls}
                options={MATCH_TYPES}
                optionDefault={MATCH_TYPE_DEFAULT}
                optionKey={MATCH_TYPE}
                inputKey={MATCH_URL}
                note="10件まで登録可、1,000文字以内"
                error={info[ROUTE_URLS].error}
                placeholder="https://"
                onChange={handleUrlsMaching(ROUTE_URLS)}
              />
              <EbisCheckbox
                text={info[USE_FRAGMENT].label}
                name={!info[USE_FRAGMENT].value}
                checked={info[USE_FRAGMENT].value}
                changeSelectBoxValue={handleSelectRoute(USE_FRAGMENT)}
              />
            </>
          )}
        </FormGroup>
        <FormGroup
          required={!disabledCvUrls}
          label={info[CV_URLS].label}
          tooltip={info[CV_URLS].tooltip}
        >
          <FormMatching
            items={cvUrls}
            options={MATCH_TYPES}
            optionDefault={MATCH_TYPE_DEFAULT}
            optionKey={MATCH_TYPE}
            inputKey={MATCH_URL}
            note="10件まで登録可、1,000文字以内"
            error={info[CV_URLS].error}
            placeholder="https://"
            disabled={disabledCvUrls}
            onChange={handleUrlsMaching(CV_URLS)}
          />
        </FormGroup>
      </div>
    </Form>
  );
};

ConversionSetting.propTypes = {
  info: shape({}).isRequired,
  onChange: func.isRequired,
  isCapiSingle: bool.isRequired,
};

ConversionSetting.defaultProps = {};

export default React.memo(ConversionSetting);
