import helper from 'store/helper';
import types from 'store/log/page-analyze/types';
import { CNT_PV, SORT_DIRECTION_DESC } from 'domain/settings/display-items';
import { communicationStatus } from 'services/utils';

const { IDLE, SUCCEEDED, FAILED } = communicationStatus;
const FIRST_PAGE = 1;

const initialState = {
  data: {
    sum: {},
    detail: [],
    metadata: {},
  },
  settings: {
    paging: FIRST_PAGE,
    sort: { field: CNT_PV, direction: SORT_DIRECTION_DESC },
  },
  errors: [],
  status: IDLE,
};

const setStatus = (state, action) => {
  const { status } = action.payload;
  return { ...state, status };
};

const setData = (state, action) => {
  const { data, metadata } = action.payload.data;
  return {
    ...state,
    data: { ...state.data, detail: data.detail, sum: data.sum, metadata },
    status: SUCCEEDED,
  };
};

const setErrors = (state, action) => {
  const { errors } = action.payload;
  return {
    ...state,
    errors,
    status: FAILED,
    data: { ...state.data, detail: [], sum: {}, metadata: {} },
  };
};

const changePage = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    settings: { ...state.settings, paging: page },
  };
};

const changeSort = (state, action) => {
  const sort = action.payload;
  return {
    ...state,
    settings: { ...state.settings, sort },
  };
};

const LogPageAnalyzeReducer = helper.createReducer(initialState, {
  [types.SET_STATUS]: setStatus,
  [types.SET_DATA]: setData,
  [types.SET_ERRORS]: setErrors,
  [types.CHANGE_PAGE]: changePage,
  [types.CHANGE_SORT]: changeSort,
});

export default LogPageAnalyzeReducer;
