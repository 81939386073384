import DisplayItemsService from 'domain/settings/DisplayItemsService';

const getTab = (state) => {
  const { tab } = state.settings.reportForView;
  return tab;
};

const getPage = (state) => {
  const { page } = state.settings.reportForView;
  return page;
};

const getFuncId = (state) => {
  const { page } = state.settings.reportForView;
  return DisplayItemsService.getFuncIdByPageId(page);
};

export default {
  getTab,
  getPage,
  getFuncId,
};
