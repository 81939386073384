import { isEmpty } from 'lodash';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import sharedSagas from 'store/sharedSagas';
import { getPermissions, getAccountId, getUserId } from 'store/auth/selectors';
import handleError from 'services/error/handleScopeError';
import CapiSettingApi from 'services/api/CapiSettingApi';
import { communicationStatus, HttpStatus } from 'services/utils';
import { SETTING_USER_EDIT } from 'domain/permissions/contractPermissions';
import { YAHOO_AD_ACCOUNT_EMPTY } from 'domain/consts';
import {
  LIST_STATUS_KEY,
  HISTORY_STATUS_KEY,
  MASTERDATA_STATUS_KEY,
  STEP_SETTING_MEDIA,
  STEP_SETTING_CV_POINTS,
  DEFAULT_SELECT_YAHOO_ACCOUNT_ID,
} from 'domain/capi-setting/consts';

import commonActions from 'store/common/actions';
import { formatCapiSettingDetail } from 'services/capi-setting/capiSettingServices';
import actions from './actions';
import types from './types';
import selectors from './selectors';

const { BAD_REQUEST } = HttpStatus;
const { LOADING, SUCCEEDED, FAILED } = communicationStatus;
const OAUTH_EXCEPTION = 'OAuthException';
const GRAPH_METHOD_EXCEPTION = 'GraphMethodException';

function* handleGetData() {
  yield put(actions.setStatus(LOADING, LIST_STATUS_KEY));

  const sort = yield select(selectors.getSortRequest);
  const {
    data: { data, metadata },
  } = yield call(CapiSettingApi.fetchData, sort);

  yield put(actions.setData({ data, metadata }));
}

function* changeStatus(action) {
  const { id, status } = action.payload;
  yield call(CapiSettingApi.changeStatus, id, status);
}

function* handleDelete(action) {
  const { ids, callback } = action.payload;
  yield call(CapiSettingApi.delete, ids);
  callback({ isError: false });
}

function* handleGetHistory() {
  yield put(actions.setStatus(LOADING, HISTORY_STATUS_KEY));

  const {
    data: { data },
  } = yield call(CapiSettingApi.fetchHistory);

  // append row total data to summary
  const dataResponse = {
    ...data,
    cv_history: [
      {
        executed_date: '合計',
        ...data.sum,
      },
      ...data.cv_history,
    ],
  };

  yield put(actions.setHistory(dataResponse));
}

function* errorHandler(err, action) {
  const { callback = () => {} } = action.payload || {};
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [];

  switch (action.type) {
    case types.DELETE:
    case types.DOWNLOAD_CSV_POSTBACK_HISTORY:
      yield put(actions.setErrors(errors, scope));
      break;
    case types.CREATE:
    case types.GET_DETAIL:
    case types.UPDATE:
      yield put(actions.setErrorSetting(errors, scope));
      break;
    default:
      break;
  }

  if (
    [types.DELETE, types.CREATE, types.GET_DETAIL, types.UPDATE].includes(
      action.type
    )
  ) {
    callback({ action: action.type, isError: true, errors });
  }

  yield put(actions.setErrors(errors, action.type, scope));
}

function* handleAuthenticationCheck(action) {
  const { data: dataRequest, changeStep, callback } = action.payload;
  try {
    yield put(actions.setStatusSetting(LOADING));
    yield call(CapiSettingApi.authenticationCheck, dataRequest);
  } catch (err) {
    const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.error || [];
    const status = error?.status ?? 400;
    let errorResponse = [];

    if (
      errors?.code === HttpStatus.CONTINUE &&
      errors.type === OAUTH_EXCEPTION &&
      status === BAD_REQUEST
    ) {
      // Pixel ID and Access Token are valid
      yield put(actions.setStatusSetting(SUCCEEDED));
      changeStep(STEP_SETTING_CV_POINTS);
    } else if (
      errors?.code === HttpStatus.CONTINUE &&
      errors?.type === GRAPH_METHOD_EXCEPTION &&
      status === BAD_REQUEST
    ) {
      errorResponse = [
        {
          code: 'INVALID_PIXEL_ID',
          field: 'pixel_id',
          message: 'Pixel ID is invalid',
        },
      ];
      yield put(actions.setStatusSetting(FAILED));
      changeStep(STEP_SETTING_MEDIA);
      callback(true, errorResponse);
    } else {
      errorResponse = [
        {
          code: 'INVALID_ACCESS_TOKEN',
          field: 'pixel_id',
          message: 'Pixel ID or Access Token are invalid',
        },
      ];
      yield put(actions.setStatusSetting(FAILED));
      changeStep(STEP_SETTING_MEDIA);
      callback(true, errorResponse);
    }
  }
}

function* handleGoogleAuthenticationCheck(action) {
  const { data: dataRequest, changeStep, callback } = action.payload;
  try {
    yield put(actions.setStatusSetting(LOADING));
    yield call(CapiSettingApi.ggAuthenticationCheck, dataRequest);
    yield put(actions.setStatusSetting(SUCCEEDED));
    changeStep(STEP_SETTING_CV_POINTS);
  } catch (err) {
    const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
    const errorResponse = error?.data?.errors || [];
    yield put(actions.setStatusSetting(FAILED));
    changeStep(STEP_SETTING_MEDIA);
    callback(true, errorResponse);
  }
}

function* handleLineAuthenticationCheck(action) {
  const { data: dataRequest, changeStep, callback } = action.payload;
  try {
    yield put(actions.setStatusSetting(LOADING));
    yield call(CapiSettingApi.lnAuthenticationCheck, dataRequest);
    yield put(actions.setStatusSetting(SUCCEEDED));
    changeStep(STEP_SETTING_CV_POINTS);
  } catch (err) {
    const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
    const errorResponse = error?.data?.errors || [];
    yield put(actions.setStatusSetting(FAILED));
    changeStep(STEP_SETTING_MEDIA);
    callback(true, errorResponse);
  }
}

function* handleYahooAuthenticationCheck(action) {
  const { data: dataRequest, changeStep, callback } = action.payload;
  try {
    yield put(actions.setStatusSetting(LOADING));
    yield call(CapiSettingApi.yahooAuthenticationCheck, dataRequest);
    yield put(actions.setStatusSetting(SUCCEEDED));
    changeStep(STEP_SETTING_CV_POINTS);
  } catch (err) {
    const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
    const errorResponse = error?.data?.errors || [];
    yield put(actions.setStatusSetting(FAILED));
    changeStep(STEP_SETTING_MEDIA);
    callback(true, errorResponse);
  }
}

function* handleTiktokAuthenticationCheck(action) {
  const { data: dataRequest, changeStep, callback } = action.payload;
  try {
    yield put(actions.setStatusSetting(LOADING));
    yield call(CapiSettingApi.ttAuthenticationCheck, dataRequest);
    yield put(actions.setStatusSetting(SUCCEEDED));
    changeStep(STEP_SETTING_CV_POINTS);
  } catch (err) {
    const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
    const errorResponse = error?.data?.errors || [];
    yield put(actions.setStatusSetting(FAILED));
    changeStep(STEP_SETTING_MEDIA);
    callback(true, errorResponse);
  }
}

function* handleCreate(action) {
  const { data: dataRequest, callback } = action.payload;
  yield put(actions.setStatusSetting(LOADING));
  const {
    data: { data },
  } = yield call(CapiSettingApi.create, dataRequest);
  yield put(actions.setDataSetting(data));
  callback({ action: action.type });
}

function* handleGetDetail(action) {
  const { id, callback } = action.payload;
  yield put(actions.setStatusSetting(LOADING));
  const {
    data: { data },
  } = yield call(CapiSettingApi.getDetail, id);
  const dataResponse = formatCapiSettingDetail(data);
  yield put(actions.setDataSetting(dataResponse));
  callback({ action: action.type });
}

function* handleUpdate(action) {
  const { id, data: dataRequest, callback } = action.payload;
  yield put(actions.setStatusSetting(LOADING));
  yield call(CapiSettingApi.update, id, dataRequest);
  yield put(actions.setStatusSetting(SUCCEEDED));
  callback({ action: action.type });
}

function* handleGetMasterData() {
  yield put(actions.setStatus(LOADING, MASTERDATA_STATUS_KEY));

  const permissions = yield select(getPermissions);
  const response = {};
  if (permissions.includes(SETTING_USER_EDIT)) {
    const userRes = yield call(CapiSettingApi.fetchUsers);
    response.users = userRes.data.data.detail;
  }

  yield put(actions.setMasterData(response));
}

function* handleGetYahooAccounts(action) {
  const { data: dataRequest, handleSuccess, handleFailed } = action.payload;
  try {
    const { yahooAccountId } = dataRequest;
    yield put(actions.setStatusSetting(LOADING));
    const {
      data: { data },
    } = yield call(CapiSettingApi.getYahooAccounts, dataRequest);
    const isExistAdAccount = Object.prototype.hasOwnProperty.call(
      data,
      yahooAccountId
    );

    const selectedYahooAccountId = isExistAdAccount
      ? data[yahooAccountId]
      : DEFAULT_SELECT_YAHOO_ACCOUNT_ID;
    selectedYahooAccountId.id = selectedYahooAccountId.key.toString();

    if (isEmpty(data)) {
      handleFailed([{ code: YAHOO_AD_ACCOUNT_EMPTY }]);
      yield put(actions.setStatusSetting(FAILED));
    } else {
      handleSuccess(data, isExistAdAccount, selectedYahooAccountId);
      yield put(actions.setStatusSetting(SUCCEEDED));
    }
  } catch (err) {
    const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
    const errorResponse = error?.data?.errors || [];
    handleFailed(errorResponse);
    yield put(actions.setStatusSetting(FAILED));
  }
}

function* handleGetGoogleAccounts(action) {
  const { data: dataRequest, handleSuccess, handleFailed } = action.payload;
  try {
    const { loginCustomerId } = dataRequest;
    yield put(actions.setStatusSetting(LOADING));
    const {
      data: { data },
    } = yield call(CapiSettingApi.getGoogleAccounts, dataRequest);

    if (isEmpty(data)) {
      handleFailed([{ code: YAHOO_AD_ACCOUNT_EMPTY }]);
      yield put(actions.setStatusSetting(FAILED));
    } else {
      // Check loginCustomerId exist in loginCustomerIds
      const isValid = Object.prototype.hasOwnProperty.call(
        data,
        loginCustomerId
      );

      handleSuccess(data, isValid);
      yield put(actions.setStatusSetting(SUCCEEDED));
    }
  } catch (err) {
    const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
    const errorResponse = error?.data?.errors || [];
    handleFailed(errorResponse);
    yield put(actions.setStatusSetting(FAILED));
  }
}

function* handleDownloadCsvPostbackHistory() {
  yield put(commonActions.setDownloadNotify('CSVファイルを作成しています...'));
  yield call(CapiSettingApi.downloadCsvPostback);
  yield put(commonActions.setDownloadNotify(''));
}

export default function* watch() {
  yield takeLatest(
    types.GET_DATA,
    sharedSagas.safe(errorHandler, handleGetData)
  );
  yield takeLatest(
    types.CHANGE_STATUS,
    sharedSagas.safe(errorHandler, changeStatus)
  );
  yield takeLatest(types.DELETE, sharedSagas.safe(errorHandler, handleDelete));
  yield takeLatest(
    types.GET_HISTORY,
    sharedSagas.safe(errorHandler, handleGetHistory)
  );
  yield takeLatest(types.AUTHENTICATION_CHECK, handleAuthenticationCheck);
  yield takeLatest(
    types.GOOGLE_AUTHENTICATION_CHECK,
    handleGoogleAuthenticationCheck
  );
  yield takeLatest(
    types.LINE_AUTHENTICATION_CHECK,
    handleLineAuthenticationCheck
  );
  yield takeLatest(
    types.YAHOO_AUTHENTICATION_CHECK,
    handleYahooAuthenticationCheck
  );
  yield takeLatest(
    types.TIKTOK_AUTHENTICATION_CHECK,
    handleTiktokAuthenticationCheck
  );
  yield takeLatest(types.CREATE, sharedSagas.safe(errorHandler, handleCreate));
  yield takeLatest(
    types.GET_DETAIL,
    sharedSagas.safe(errorHandler, handleGetDetail)
  );
  yield takeLatest(types.UPDATE, sharedSagas.safe(errorHandler, handleUpdate));
  yield takeLatest(
    types.GET_MASTERDATA,
    sharedSagas.safe(errorHandler, handleGetMasterData)
  );
  yield takeLatest(types.GET_YAHOO_ACCOUNTS, handleGetYahooAccounts);
  yield takeLatest(types.GET_GOOGLE_ACCOUNTS, handleGetGoogleAccounts);
  yield takeLatest(
    types.DOWNLOAD_CSV_POSTBACK_HISTORY,
    sharedSagas.safe(errorHandler, handleDownloadCsvPostbackHistory)
  );
}
