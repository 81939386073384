// async actions
const REQUEST_START = 'display-items-for-view/REQUEST_START';
const REQUEST_FAILED = 'display-items-for-view/REQUEST_FAILED';
const FETCH = 'display-items-for-view/FETCH';
const FETCH_DEFAULT = 'display-items-for-view/FETCH_DEFAULT';
const CHANGE_FUNC_ID = 'display-items-for-view/CHANGE_FUNC_ID';
// sync actions
const FETCH_SUCCEEDED = 'display-items-for-view/FETCH_SUCCEEDED';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  FETCH,
  FETCH_DEFAULT,
  CHANGE_FUNC_ID,
  FETCH_SUCCEEDED,
};
