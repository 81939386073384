import { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';

// Subtract 3 (a small arbitrary number) to allow for browser reporting a difference of 1 when no scrollbar is present
const WRONG_NUMBER = 3;

const useHoverTooltip = (ref, content = '') => {
  const id = 'hook-tooltip';
  const el = ref.current;
  const isDisplayTooltip =
    content || el?.scrollHeight - WRONG_NUMBER > el?.offsetHeight;

  const removeTooltip = () => {
    if (document.getElementById(id)) {
      document.getElementById(id).remove();
    }
  };

  const handleMouseOver = (event) => {
    if (!isDisplayTooltip) {
      removeTooltip();
      return;
    }

    const tooltipEl = document.getElementById(id)
      ? document.getElementById(id)
      : document.createElement('div');
    tooltipEl.setAttribute('id', id);
    tooltipEl.setAttribute('style', 'position: fixed');
    if (!document.getElementById(id)) {
      el.appendChild(tooltipEl);
    }

    tooltipEl.innerHTML = content
      ? ReactDOMServer.renderToStaticMarkup(content).toString()
      : `<div class="hook-tooltip">${el?.innerHTML}</div>`;

    tooltipEl.addEventListener('click', (e) => {
      e.preventDefault();
      // Use stopImmediatePropagation to stop the element from passing this click to wrapping parents - because a click on a child is technically a click on the parent as well.
      e.stopImmediatePropagation();
    });

    const positionLeft =
      window.innerWidth - event.clientX - 50 > tooltipEl.clientWidth
        ? event.clientX - 2
        : event.clientX - tooltipEl.clientWidth + 10;

    Object.assign(tooltipEl.style, {
      position: 'fixed',
      top: `${event.clientY - 2}px`,
      left: `${positionLeft}px`,
      zIndex: 2000,
    });
  };

  const handelMouseOut = () => {
    removeTooltip();
  };

  useEffect(() => {
    if (!el) return () => {};

    el.addEventListener('mouseenter', handleMouseOver);
    el.addEventListener('mouseleave', handelMouseOut);
    return () => {
      el.removeEventListener('mouseenter', handleMouseOver);
      el.removeEventListener('mouseleave', handelMouseOut);
    };
  });
};

export default useHoverTooltip;
