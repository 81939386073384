import helper from 'store/helper';
import { getFieldError } from 'services/data-export/dataExportService';
import { REPORT_FIELDS, REPORT_TYPE_AD_REPO } from 'domain/data-export/consts';

const {
  PERIOD_MODE,
  PERIOD_START,
  PERIOD_END,
  SEND_EMAIL_MODE,
  EMAIL_TITLE_MODE,
  EMAIL_TITLE,
  SERVICE_STORE,
  REPORT_TYPE,
} = REPORT_FIELDS;

const initialState = {
  serviceStore: '',
  reportType: '',
  channel: '',
  normalReport: {},
  adrepoReport: {},
  error: {},
  actionSubmit: '', // CREATE, UPDATE
};

const setServiceStore = (state, action) => {
  if (action.payload === REPORT_TYPE_AD_REPO) {
    return { ...state, serviceStore: action.payload };
  }
  return {
    ...state,
    serviceStore: action.payload,
    normalReport: { ...state.normalReport, [SERVICE_STORE]: action.payload },
  };
};

const setReportType = (state, action) => {
  const keyState =
    action.payload === REPORT_TYPE_AD_REPO ? 'adrepoReport' : 'normalReport';

  return {
    ...state,
    reportType: action.payload,
    [keyState]: { ...state[keyState], [REPORT_TYPE]: action.payload },
  };
};

const setReportChannel = (state, action) => {
  return {
    ...state,
    channel: action.payload,
    normalReport: {
      ...state.normalReport,
      [REPORT_FIELDS.REPORT_CHANNEL]: action.payload,
    },
  };
};

const setDataNormalReport = (state, action) => {
  return {
    ...state,
    normalReport: {
      ...state.normalReport,
      ...action.payload,
    },
  };
};

const setDataAdrepoReport = (state, action) => {
  return {
    ...state,
    adrepoReport: {
      ...state.adrepoReport,
      ...action.payload,
    },
  };
};

const setError = (state, action) => {
  return { ...state, error: action.payload };
};

const clearError = (state) => {
  return { ...state, error: {} };
};

const clearErrorByFields = (state, action) => {
  const fields = action.payload.map((field) => getFieldError(field));
  if (action.payload.includes(PERIOD_MODE)) {
    fields.push(PERIOD_START, PERIOD_END);
  }
  if (
    [SEND_EMAIL_MODE, EMAIL_TITLE_MODE].some((item) =>
      action.payload.includes(item)
    )
  ) {
    fields.push(EMAIL_TITLE);
  }
  const error = Object.keys(state.error).reduce((acc, field) => {
    if (fields.includes(field)) {
      return acc;
    }
    return { ...acc, [field]: state.error[field] };
  }, {});

  return { ...state, error };
};

const setActionSubmit = (state, action) => {
  return { ...state, actionSubmit: action.payload };
};

const clearState = () => {
  return initialState;
};

const SettingReportReducer = helper.createReducer(initialState, {
  setServiceStore,
  setReportType,
  setReportChannel,
  setDataNormalReport,
  setDataAdrepoReport,
  setError,
  clearError,
  clearErrorByFields,
  setActionSubmit,
  clearState,
});

export { SettingReportReducer, initialState };
