import { MASTER_DATA_FILTER_LIMIT } from 'domain/consts';
import { buildSearchMasterDataBody } from 'domain/utils';
import { AD_MANAGEMENT_API_ENDPOINT } from 'services/api/AdManagementApi';
import { SettingsApi as Api } from 'services/api/Api';
import { saveAs } from 'services/utils';
import axios from 'axios';
import { LIMIT_ADD_MULTIPLE_LINE_SYNC } from 'domain/ad-management/media-group/constants';

const MediaServiceFactory = () => {
  const getUrl = (type = null) => {
    if (type) {
      return `${AD_MANAGEMENT_API_ENDPOINT}/${type}`;
    }
    return `${AD_MANAGEMENT_API_ENDPOINT}/media`;
  };
  const baseUrl = process.env.REACT_APP_SETTINGS_API_HOST;
  const urlCsv = `${getUrl()}/csv`;

  const create = async (mediaName, agencyId) => {
    const url = getUrl();
    const request = {
      name: mediaName,
      agency_id: agencyId,
    };
    const response = await Api.post(url, request);
    const { id, name, order } = response.data.data.detail[0];
    return {
      id: `${id}`,
      name,
      order,
    };
  };
  /**
   * Add Multi item
   * @param {object} param0
   * @returns object
   */
  const add = async ({ type, name }) => {
    const url = getUrl(type);
    const lines = name.split('\n');
    if (lines.length < LIMIT_ADD_MULTIPLE_LINE_SYNC) {
      const response = await Api.post(url, { name });
      return response;
    }
    const response = Api.requestAsync(`${url}/add`, { name }, 'POST', {
      pollTokenKey: 'token',
      pollUrlFormat: `${url}/add/{token}`,
    });
    return response;
  };

  const getByID = async ({ type, id }) => {
    const url = getUrl(type);
    const response = await Api.get(`${url}/${id}`);
    const { name, postbacks } = response.data.data.detail;
    return {
      id: `${id}`,
      name,
      postbacks,
    };
  };

  const fetchDeleteList = async ({ type, ids }) => {
    const url = getUrl(type);
    const response = await Api.delete(`${url}/?ids=${ids}`);
    const { detail } = response.data.data;
    return detail;
  };

  const update = async (data) => {
    const { type, item, postbacks, targetId } = data;
    const url = getUrl(type);
    const response = await Api.put(`${url}/${targetId}`, {
      name: item.name,
      postbacks,
    });
    return response.data.data.detail;
  };

  const remove = async ({ type, ids }) => {
    const url = getUrl(type);
    const response = Api.requestAsync(
      `${url}/delete?ids=${ids}&confirm=1`,
      null,
      'DELETE',
      {
        pollTokenKey: 'token',
        pollUrlFormat: `${url}/delete/{token}`,
      }
    );
    return response;
  };

  // handle delete async media because when delete media linkage ad media agency is take long time > 30s
  const deleteAsyncMedia = async ({ type, ids }) => {
    const url = getUrl(type);
    const response = Api.requestAsync(
      `${url}/delete-async?ids=${ids}&confirm=1`,
      null,
      'DELETE',
      {
        pollTokenKey: 'task',
        pollUrlFormat: `${url}/delete-async/{task}`,
      }
    );
    return response;
  };

  /**
   * Search media
   * @param searchTerm
   * @param selected
   * @param offset
   * @param limit
   * @return {Promise<{total, items}>}
   */
  const search = async (
    searchTerm = '',
    selected = [],
    offset = 0,
    limit = MASTER_DATA_FILTER_LIMIT + 1, // Plus 1 item to display message: 件数が多いため、500件まで表示しています。検索機能をご活用ください。
    agencyId = ''
  ) => {
    const params = buildSearchMasterDataBody(
      searchTerm,
      selected,
      offset,
      limit,
      agencyId
    );
    const url = getUrl();
    const response = await Api.post(`${url}/list`, params);

    const {
      data,
      data: items,
      metadata: { count: total },
      selected: selectedItems,
    } = response?.data;

    return { data, items, total, selected: selectedItems };
  };

  const fetchAll = async ({ type, params }) => {
    const url = getUrl(type);
    const response = await Api.post(`${url}/list`, params);
    return response?.data;
  };

  const fetchPostBackHistory = async (params) => {
    const url = getUrl();
    return Api.get(`${url}/postback`, params);
  };

  const upload = async (uploadPath, file, contentType = 'text/csv') => {
    await axios.put(uploadPath, file, {
      headers: {
        'Content-Type': contentType,
        'X-Amz-Acl': 'bucket-owner-full-control',
      },
    });
  };

  const validate = async ({ type, filename }) => {
    const url = getUrl(type);
    return Api.requestAsync(`${url}/validation`, { file: filename }, 'POST', {
      pollTokenKey: 'token',
      pollUrlFormat: `${url}/validation/{token}`,
    });
  };

  const confirm = async ({ type, filenameConfirm }) => {
    const url = getUrl(type);
    return Api.get(`${url}/confirm?file=${filenameConfirm}`);
  };

  const requestImportStatus = async ({ type, file }) => {
    const url = getUrl(type);
    return Api.requestAsync(`${url}/confirm`, { file }, 'POST', {
      pollTokenKey: 'token',
      pollUrlFormat: `${url}/confirm/{token}`,
    });
  };

  const getPresignedUrl = async (type) => {
    const url = getUrl(type);
    return Api.post(`${url}/csv`, {}, { baseUrl });
  };

  const downloadCsvTemplate = async (type) => {
    const url = getUrl(type);
    const response = await Api.get(`${url}/template`);
    return response;
  };

  const downloadCsvPostback = async () => {
    const urlPostbackCsv = `${getUrl()}/postback/csv`;
    const response = await Api.requestAsync(urlPostbackCsv, null, 'GET', {
      pollTokenKey: 'token',
      pollUrlFormat: `${urlPostbackCsv}/{token}`,
    });
    const { location } = response.data.data;
    if (location) {
      saveAs(location);
    }
  };

  return {
    url: getUrl(),
    urlCsv,
    baseUrl,
    create,
    add,
    update,
    remove,
    upload,
    validate,
    confirm,
    requestImportStatus,
    getPresignedUrl,
    getByID,
    search,
    fetchAll,
    fetchPostBackHistory,
    fetchDeleteList,
    downloadCsvTemplate,
    downloadCsvPostback,
    deleteAsyncMedia,
  };
};

const mediaService = MediaServiceFactory();

export default mediaService;
