import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { string, number, func } from 'prop-types';

function ExpandCellTable(props) {
  const { value, metric, rowNumber, handleResizeColumn } = props;
  const [isExpand, setExpand] = useState(false);
  let strSecond = '';
  const strFirst = (value || '').split('\n');
  strSecond = (value || '').slice(strFirst[0].length + 1);

  return (
    <>
      <div
        id={`collapse-${metric}-${rowNumber}`}
        className="overflow-hidden"
      >
        <span className="d-inline-block">{strFirst[0]}</span>
        <br />
        <span hidden={!isExpand}>{strSecond}</span>
      </div>
      {strSecond.length > 0 && (
        <Button
          size="xs"
          variant="link"
          className="p-0 border-0"
          onClick={(e) => {
            e.stopPropagation();
            setExpand(!isExpand);
            handleResizeColumn(metric);
          }}
        >
          {isExpand ? '省略して表示' : 'すべて表示'}
        </Button>
      )}
    </>
  );
}

ExpandCellTable.propTypes = {
  value: string,
  metric: string.isRequired,
  rowNumber: number.isRequired,
  handleResizeColumn: func.isRequired,
};

ExpandCellTable.defaultProps = {
  value: '',
};

export default ExpandCellTable;
