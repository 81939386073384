import { CSV_MAX_SIZE } from 'domain/consts';
import {
  func,
  array,
  oneOfType,
  oneOf,
  object,
  string,
  number,
} from 'prop-types';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import {
  AD_MANAGEMENT_MODE,
  COLUMNS_TABLE_CONFIRM_EMPTY_MEASUREMENT_TYPE,
  COLUMNS_TABLE_CONFIRM_CHANGE_URL,
  MAX_HEIGHT_TABLE_CONFIRM,
  MIN_HEIGHT_TABLE_CONFIRM,
  TEMPLATE_REGISTER_AD,
  TEMPLATE_REGISTER_LISTING,
  TEMPLATE_REGISTER_AGENCY_AD,
  TEMPLATE_REGISTER_AGENCY_LISTING,
} from 'domain/ad-management/consts';
import { COLUMNS_TABLE_CONFIRM_CONTENT_INVALID_MERGED } from 'services/consts';
import { APP_HELP_DIRECT_MEASUREMENT_SETTINGS, NS_CNAME_CSV_CHANGE_URL_HELP_LINK } from 'services/routes/constants';
import { communicationStatus, checkFormatErrorByLine } from 'services/utils';
import {
  requiredUpload,
  csvFile,
  maxFileSize,
} from 'services/validations/commonValidations';
import {
  formatGroupErrorCsv,
  formatDataConfirmUpload,
  getErrorMessageByCode,
} from 'services/ad-management/adManagementService';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import FileUpload from 'views/molecules/FileUpload';
import TableConfirmDetail from 'views/organism/TableConfirmDetail';
import useCheckPermissionAgency from 'services/custom-hooks/useCheckPermissionAgency';

import './csv-upload-modal.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { DisplayItemsSelectors } from 'store/display-items';
import ExcelJS from 'exceljs';
import encoding from 'encoding-japanese';

const { IDLE, LOADING, SUCCEEDED, FAILED } = communicationStatus;
const STEP_UPLOAD = 1;
const STEP_CONFIRM = 2;
const AD_LPO_CSV_HEADER = [
  { key: 'ad_id', header: '広告ID' },
  { key: 'ad_group1_name', header: '広告グループ1' },
  { key: 'ad_group2_name', header: '広告グループ2' },
  { key: 'media_name', header: '媒体種別' },
  { key: 'keyword', header: '広告名※必須' },
  { key: 'agency_name', header: '担当代理店' },
  { key: 'ad_note', header: '備考' },
  { key: 'base_cpc', header: 'クリック単価' },
  { key: 'ad_start_date', header: '出稿開始日' },
  { key: 'ad_end_date', header: '出稿終了日' },
  { key: 'text_data', header: 'テキスト原稿' },
  { key: 'url1', header: 'リンク先URL1※必須' },
  { key: 'page_title1', header: 'タイトル1※必須' },
  { key: 'transition_rate1', header: '遷移割合1※必須' },
  { key: 'url2', header: 'リンク先URL2※必須' },
  { key: 'page_title2', header: 'タイトル2※必須' },
  { key: 'transition_rate2', header: '遷移割合2※必須' },
];

function AdRegisterCsvUploadModal(props) {
  const {
    mode,
    status,
    data,
    errors,
    masterData,
    onHide,
    onUpload,
    onSubmit,
    lpupCount,
  } = props;
  const { display: configDisplay } = masterData;

  const { isAgentUser, hasContractAgent } = useCheckPermissionAgency();
  const hasMediaSyncContract = useSelector(
    DisplayItemsSelectors.hasContractMediaSync,
    shallowEqual
  );

  const [step, setStep] = useState(STEP_UPLOAD);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorConfirm, setErrorConfirm] = useState([]);
  const [files, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isUpload, setUpload] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isChecked, toggleCheck] = useState(false);

  const handleCsvFormat = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('ad_lpo');
    const worksheet = workbook.getWorksheet('ad_lpo');
    const headersNoAgency = AD_LPO_CSV_HEADER.filter(item => item.key !== 'agency_name');
    const headers =
      isAgentUser || hasContractAgent
        ? [...AD_LPO_CSV_HEADER]
        : headersNoAgency
    for (let i = 3; i <= lpupCount; i += 1) {
      headers.push({
        key: `url${i}`,
        header: `リンク先URL${i}`,
      });
      headers.push({
        key: `page_title${i}`,
        header: `タイトル${i}`,
      });
      headers.push({
        key: `transition_rate${i}`,
        header: `遷移割合${i}`,
      });
    }
    headers.push({
      key: 'paste_url',
      header: '入稿用URL',
    });
    worksheet.columns = headers;

    const uint8Array = new Uint8Array(
      encoding.convert(await workbook.csv.writeBuffer(), {
        from: 'UTF8',
        to: 'SJIS',
      })
    );
    const blob = new Blob([uint8Array], {
      type: 'application/octet-binary',
    });
    return window.URL.createObjectURL(blob);
  }, [isAgentUser, hasContractAgent, lpupCount]);

  const handleCsvFormatClick = useCallback(async () => {
    const url = await handleCsvFormat();
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ad_lpo.csv';
    a.click();
    a.remove();
  }, [handleCsvFormat]);

  const linkCsvTemplate = useMemo(() => {
    const linkAd =
      isAgentUser || hasContractAgent
        ? TEMPLATE_REGISTER_AGENCY_AD
        : TEMPLATE_REGISTER_AD;

    const linkListing =
      isAgentUser || hasContractAgent
        ? TEMPLATE_REGISTER_AGENCY_LISTING
        : TEMPLATE_REGISTER_LISTING;

    let temp = '';
    switch (mode) {
      case AD_MANAGEMENT_MODE.AD:
        temp = linkAd;
        setFileName('ad.csv');
        break;
      case AD_MANAGEMENT_MODE.LISTING:
        temp = linkListing;
        setFileName('listing.csv');
        break;
      case AD_MANAGEMENT_MODE.LPO:
        temp = handleCsvFormat();
        setFileName('ad_lpo.csv');
        break;
      default:
        temp = linkListing;
    }
    return temp;
  }, [mode, isAgentUser, hasContractAgent, handleCsvFormat]);

  const onFileUploadAccepted = (uploadedFile) => {
    setFile(uploadedFile);
    setErrorMessage(null);
    setErrorConfirm([]);
  };
  const onFileUploadClear = () => {
    setFile(null);
    setErrorMessage(null);
    setErrorConfirm([]);
  };

  const validateFile = useCallback(() => {
    const rules = [requiredUpload(), csvFile(), maxFileSize(CSV_MAX_SIZE)];
    const [file] = files;
    return rules
      .map((rule) => rule({ value: file, label: 'CSV' }))
      .filter((rule) => !isEmpty(rule));
  }, [files]);

  const handleUpload = useCallback(() => {
    const fileErrors = validateFile();
    if (isEmpty(fileErrors)) {
      setLoading(true);
      setUpload(true);
      onUpload(files);
      setFile(null);
    } else {
      onFileUploadClear();
      setErrorMessage(fileErrors[0]);
    }
  }, [validateFile, onUpload, files]);

  const handleSubmit = useCallback(() => {
    setSubmit(true);
    setLoading(true);
    onSubmit();
  }, [onSubmit]);

  const handleBack = useCallback(() => {
    setStep(STEP_UPLOAD);
    setFile(null);
    setErrorMessage(null);
    setErrorConfirm([]);
  }, []);

  const handleClose = useCallback(() => {
    setFile(null);
    setErrorMessage(null);
    setErrorConfirm([]);
    onHide();
  }, [onHide]);

  useEffect(() => {
    if (status === SUCCEEDED && isSubmit) {
      handleClose();
    }

    if ([SUCCEEDED, FAILED].includes(status)) {
      setLoading(false);
      if (isUpload) {
        setUpload(false);
        if (status === SUCCEEDED) {
          setStep(STEP_CONFIRM);
          if (!data?.needConfirm) {
            setSubmit(true);
          }
        }
      }
      if (isSubmit) {
        setSubmit(false);
        setStep(STEP_UPLOAD);
      }
    }
  }, [handleClose, onHide, status, isUpload, isSubmit, data]);

  useEffect(() => {
    if (isEmpty(errors)) return;
    const isFormatErrorByLine = checkFormatErrorByLine(errors[0]);
    if (isFormatErrorByLine) {
      setErrorConfirm(
        formatGroupErrorCsv(
          errors,
          configDisplay,
          { action: '編集' },
          mode,
          lpupCount
        )
      );
      setErrorMessage(
        getErrorMessageByCode({
          code: 'FILE_CONTENT_VALID_CHECK',
          metadata: {
            param: [errors.reduce((sum, curr) => sum + curr.count, 0)],
          },
        })
      );
    } else {
      (async () => {
        setErrorMessage(
          getErrorMessageByCode(errors[0], {
            link: await linkCsvTemplate,
            fileName,
          })
        );
      })();
    }
  }, [errors, configDisplay, linkCsvTemplate, mode, lpupCount, fileName]);

  const modalHeader = useMemo(() => {
    if ([AD_MANAGEMENT_MODE.AD, AD_MANAGEMENT_MODE.LPO].includes(mode)) {
      return (
        <>
          <Modal.Title>広告のCSV一括登録</Modal.Title>
          {mode === AD_MANAGEMENT_MODE.LPO ? (
            <div>
              <Button
                as="a"
                variant="secondary"
                size="xs"
                className="btn-icon--with-text"
                onClick={() => {
                  handleCsvFormatClick();
                }}
              >
                <i className="far fa-arrow-to-bottom" />
                CSVフォーマット
              </Button>
            </div>
          ) : (
            <Button
              as="a"
              href={linkCsvTemplate}
              variant="secondary"
              size="xs"
              className="btn-icon--with-text"
            >
              <i className="far fa-arrow-to-bottom" />
              CSVフォーマット
            </Button>
          )}

          <div className="w-100 color-gray-dark txt-note">
            ※ダイレクト計測が推奨です。ダイレクト計測の設定方法・注意点は
            <a
              href={APP_HELP_DIRECT_MEASUREMENT_SETTINGS}
              rel="noreferrer"
              target="_blank"
            >
              こちら
            </a>
            をご確認ください
            <br />
            {hasMediaSyncContract
              ? '※自動登録された広告に対しては「クリック単価」「コンバージョン単価」「計測方式」「リンク先URL」「タイトル」は登録されません'
              : null}
          </div>
        </>
      );
    }
    return (
      <>
        <Modal.Title>広告のCSV一括更新</Modal.Title>
        <Button
          as="a"
          href={linkCsvTemplate}
          variant="secondary"
          size="xs"
          className="btn-icon--with-text"
        >
          <i className="far fa-arrow-to-bottom" />
          CSVフォーマット
        </Button>
        <div className="w-100">
          登録済みの広告の更新のみ可能です。新規登録は行えません。
        </div>
      </>
    );
  }, [mode, linkCsvTemplate]);

  const modalBody = useMemo(() => {
    if (step === STEP_UPLOAD) {
      return (
        <>
          {errorMessage && (
            <div
              className="color-bad txt-standard error-message"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          )}
          {!isEmpty(errorConfirm) && (
            <TableConfirmDetail
              maxContent={264}
              columns={COLUMNS_TABLE_CONFIRM_CONTENT_INVALID_MERGED}
              rows={errorConfirm}
            />
          )}
          <FileUpload
            isClear={!files}
            onFileUploadAccepted={onFileUploadAccepted}
            onClear={onFileUploadClear}
            note="30MB以下、100,000行まで登録可"
          />
        </>
      );
    }
    if (step === STEP_CONFIRM && data?.needConfirm) {
      const contents = [];
      const maxContent =
        isEmpty(data?.emptyMeasurementType) || isEmpty(data?.changeUrl)
          ? MAX_HEIGHT_TABLE_CONFIRM
          : MIN_HEIGHT_TABLE_CONFIRM;

      if (!isEmpty(data?.emptyMeasurementType)) {
        contents.push(
          <>
            <div className="warning-message">
              計測方式が空白のものが{data.emptyMeasurementType.length}
              件あります。空白の場合、ダイレクト形式で登録されます。
              <br />
              ダイレクト計測の設定方法・注意点は
              <a
                href={APP_HELP_DIRECT_MEASUREMENT_SETTINGS}
                rel="noreferrer"
                target="_blank"
              >
                こちら
              </a>
              をご確認ください。
            </div>
            <TableConfirmDetail
              columns={COLUMNS_TABLE_CONFIRM_EMPTY_MEASUREMENT_TYPE}
              rows={formatDataConfirmUpload(data.emptyMeasurementType)}
              maxContent={maxContent}
            />
          </>
        );
      }
      if (!isEmpty(data?.changeUrl)) {
        contents.push(
          <>
            <div className="warning-message">
              リンク先URLが変更されるものが{data.changeUrl.length}
              件あります。
              <br />
              このまま登録してもよろしいですか？
            </div>
            <TableConfirmDetail
              columns={COLUMNS_TABLE_CONFIRM_CHANGE_URL}
              rows={formatDataConfirmUpload(data.changeUrl)}
              maxContent={maxContent}
            />
          </>
        );
      }

      return contents;
    }
    return <></>;
  }, [step, data, files, errorMessage, errorConfirm]);

  const modalFooter = useMemo(() => {
    if (step === STEP_UPLOAD) {
      return (
        <>
          <div className="d-flex align-items-center">
            <EbisCheckbox
              name="confirm"
              checked={isChecked}
              disabled={isLoading}
              changeSelectBoxValue={() => toggleCheck(!isChecked)}
            />
            <div>
              リンク先URLに変更がある場合でも、広告情報を編集して問題ありません
              <div className="w-100 color-gray-dark txt-note">
                ※リダイレクト計測かつNS/CNAME用のドメインを入稿している場合、<br />
                リンク先URLが変更されることによって正常に計測できない可能性がありますのでご注意ください。詳しくは
                <a
                  href={NS_CNAME_CSV_CHANGE_URL_HELP_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
              </div>
            </div>
          </div>
          <div>
            <Button
              size="sm"
              variant="link"
              onClick={handleClose}
              disabled={isLoading}
            >
              キャンセル
            </Button>
            <Button
              size="sm"
              variant="secondary"
              onClick={handleUpload}
              disabled={files === null || isLoading || !isChecked}
            >
              OK
            </Button>
          </div>
        </>
      );
    }
    return (
      <>
        <Button
          size="sm"
          variant="link"
          disabled={isLoading}
          onClick={handleBack}
        >
          戻る
        </Button>
        <Button
          size="sm"
          variant="secondary"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          OK
        </Button>
      </>
    );
  }, [
    handleUpload,
    handleClose,
    handleSubmit,
    handleBack,
    isLoading,
    step,
    files,
    isChecked
  ]);

  return (
    <Modal
      show
      centered
      backdrop="static"
      size="w800"
      className="modal--csv-upload"
    >
      <Modal.Header>{modalHeader}</Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={isLoading}>{modalBody}</DataSyncLoader>
      </Modal.Body>
      <Modal.Footer className='modal-footer__has-ebis-checkbox'>
        {modalFooter}
      </Modal.Footer>
    </Modal>
  );
}

AdRegisterCsvUploadModal.propTypes = {
  onHide: func,
  onUpload: func,
  onSubmit: func,
  mode: string,
  data: oneOfType([object]),
  errors: oneOfType([array]),
  masterData: oneOfType([object]),
  status: oneOf([IDLE, LOADING, SUCCEEDED, FAILED]).isRequired,
  lpupCount: number,
};

AdRegisterCsvUploadModal.defaultProps = {
  onHide: () => {},
  onUpload: () => {},
  onSubmit: () => {},
  mode: '',
  data: {},
  errors: [],
  masterData: {},
  lpupCount: null,
};

export default AdRegisterCsvUploadModal;
