import * as DisplayItems from './display-items';

const SettingDisplayItems = {
  groupList: {
    // [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.CATEGORY]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_GROUP1]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_GROUP2]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_ID]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_NAME]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AGENCY]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_NOTE]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.AD_DATE]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.TERMINAL_TYPE]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.LANDING_PAGE_DOMAIN]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.SYNC_CATEGORY]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.MEDIA_SIDE_GROUP]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.MEDIA_SIDE_AD_ID]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.MEDIA_SIDE_AD_NAME]: DisplayItems.DISPLAY_GROUP_DETAIL,
    [DisplayItems.LANDING_PAGE_URL]: DisplayItems.DISPLAY_GROUP_DETAIL,
  },
  // 媒体シンク軸優先時に非表示にする項目
  denyMediaSyncList: [
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
    DisplayItems.AD_NOTE,
    DisplayItems.AD_DATE,
    DisplayItems.AD_NAME,
    DisplayItems.AD_ID,
    DisplayItems.OPTIONAL_MEDIA_SIDE_AD_ID,
  ],
  // エビス軸優先時に非表示にする項目
  denyEbisList: [
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
    DisplayItems.MEDIA_SIDE_AD_ID,
    DisplayItems.MEDIA_SIDE_AD_NAME,
    DisplayItems.OPTIONAL_AD_ID,
  ],
  displayFreezeList: [
    // DisplayItems.CHANNEL,
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
    DisplayItems.MEDIA_SIDE_AD_ID,
    DisplayItems.MEDIA_SIDE_AD_NAME,
    DisplayItems.AD_ID,
    DisplayItems.AD_NAME,
    DisplayItems.AGENCY,
    DisplayItems.AD_NOTE,
    DisplayItems.AD_DATE,
    DisplayItems.TERMINAL_TYPE,
    DisplayItems.LANDING_PAGE_DOMAIN,
    DisplayItems.LANDING_PAGE_URL,
  ],
  orderList: {
    [DisplayItems.CHANNEL]: 1,
    [DisplayItems.SYNC_CATEGORY]: 2,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]: 3,
    [DisplayItems.MEDIA_SIDE_GROUP]: 4,
    [DisplayItems.MEDIA_SIDE_AD_ID]: 5,
    [DisplayItems.MEDIA_SIDE_AD_NAME]: 6,
    [DisplayItems.CATEGORY]: 7,
    [DisplayItems.AD_GROUP1]: 8,
    [DisplayItems.AD_GROUP2]: 9,
    [DisplayItems.AD_ID]: 10,
    [DisplayItems.AD_NAME]: 11,
    [DisplayItems.AGENCY]: 12,
    [DisplayItems.AD_NOTE]: 13,
    [DisplayItems.AD_DATE]: 14,
    [DisplayItems.TERMINAL_TYPE]: 15,
    [DisplayItems.LANDING_PAGE_DOMAIN]: 16,
    [DisplayItems.LANDING_PAGE_URL]: 17,
    [DisplayItems.IMPRESSION]: 18,
    [DisplayItems.CNT_ACCESS]: 19,
    [DisplayItems.CTR]: 20,
    [DisplayItems.CNT_SESSION]: 21,
    [DisplayItems.CNT_PV]: 22,
    [DisplayItems.PV_AVG]: 23,
    [DisplayItems.CNT_RETURN]: 24,
    [DisplayItems.PER_RETURN]: 25,
    [DisplayItems.TOTAL_STAY_TIME]: 26,
    [DisplayItems.TOTAL_STAY_TIME_AVG]: 27,
    [DisplayItems.TOTAL_LATENCY_TIME]: 28,
    [DisplayItems.LATENCY_TIME_AVG]: 29,
    [DisplayItems.CNT_CV_DETAIL]: 30,
    [DisplayItems.CVR_DETAIL]: 31,
    [DisplayItems.CNT_CV_TOTAL]: 32,
    [DisplayItems.CVR_TOTAL]: 33,
    [DisplayItems.CNT_INDIRECT2]: 34,
    [DisplayItems.CNT_INDIRECT3]: 35,
    [DisplayItems.CNT_INDIRECT4]: 36,
    [DisplayItems.CNT_INDIRECT5]: 37,
    [DisplayItems.CNT_INDIRECT_OTHER]: 38,
    [DisplayItems.INDIRECT_TOTAL]: 39,
    [DisplayItems.DIRECT_RATE]: 40,
    [DisplayItems.INDIRECT_RATE]: 41,
    [DisplayItems.CNT_FIRST]: 42,
    [DisplayItems.RCV]: 43,
    [DisplayItems.AMOUNT]: 44,
    [DisplayItems.RAMOUNT]: 45,
    [DisplayItems.COST]: 46, // TODO: confirm
    [DisplayItems.CPC]: 47, // TODO: confirm
    [DisplayItems.CPA]: 48,
    [DisplayItems.TCPA]: 49,
    [DisplayItems.ROAS]: 50,
    [DisplayItems.TROAS]: 51,
    [DisplayItems.OPTIONAL_AD_ID]: 52,
    [DisplayItems.OPTIONAL_MEDIA_SIDE_AD_ID]: 53,
    [DisplayItems.CROSSDEVICE_DIFF_COMPARISON]: 54,
  },
  displayDefaultList: [
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
    DisplayItems.AD_ID,
    DisplayItems.AD_NAME,
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
    DisplayItems.MEDIA_SIDE_AD_ID,
    DisplayItems.MEDIA_SIDE_AD_NAME,
    DisplayItems.IMPRESSION,
    DisplayItems.CNT_ACCESS,
    DisplayItems.CTR,
    DisplayItems.CNT_CV_DETAIL,
    DisplayItems.CNT_CV_TOTAL,
    DisplayItems.CVR_DETAIL,
    DisplayItems.CVR_TOTAL,
    DisplayItems.CNT_INDIRECT2,
    DisplayItems.CNT_INDIRECT3,
    DisplayItems.CNT_INDIRECT4,
    DisplayItems.CNT_INDIRECT5,
    DisplayItems.CNT_INDIRECT_OTHER,
    DisplayItems.INDIRECT_TOTAL,
    DisplayItems.CNT_FIRST,
    DisplayItems.RCV,
    DisplayItems.AMOUNT,
    DisplayItems.COST,
    DisplayItems.CPA,
    DisplayItems.TCPA,
    DisplayItems.ROAS,
  ],
  sortDefaultList: {
    [DisplayItems.CNT_CV_TOTAL]: DisplayItems.SORT_DIRECTION_DESC,
  },
  sortDisabledList: [
    DisplayItems.CNT_CV_DETAIL,
    DisplayItems.CVR_DETAIL,
  ],
  ebisRequiredList: [DisplayItems.AD_ID, DisplayItems.AD_NAME],
  syncRequiredList: [
    DisplayItems.MEDIA_SIDE_AD_ID,
    DisplayItems.MEDIA_SIDE_AD_NAME,
  ],
};

const SettingAdDisplayItems = {
  ...SettingDisplayItems,
};
const SettingAllDisplayItems = {
  ...SettingDisplayItems,
  groupList: {
    ...SettingDisplayItems.groupList,
    [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_DETAIL,
  },
  displayFreezeList: [
    ...SettingDisplayItems.displayFreezeList,
    DisplayItems.CHANNEL,
  ],
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    DisplayItems.CHANNEL,
  ],
  ebisRequiredList: [
    ...SettingDisplayItems.ebisRequiredList,
    DisplayItems.CHANNEL,
    DisplayItems.CATEGORY,
  ],
  syncRequiredList: [
    ...SettingDisplayItems.syncRequiredList,
    DisplayItems.CHANNEL,
    DisplayItems.SYNC_CATEGORY,
  ],
};

export const getAd = () => {
  const displayItemsBase = DisplayItems.getAd();
  return DisplayItems.getItems({
    ...SettingAdDisplayItems,
    displayItemsBase,
  });
};

export const getAdDefault = () => {
  const displayItemsBase = getAd();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAll = () => {
  const displayItemsBase = DisplayItems.getAll();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getCommon = () => {
  const displayItemsBase = DisplayItems.getCommon();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getCommonDefault = () => {
  const displayItemsBase = getCommon();
  return DisplayItems.getDefault(displayItemsBase);
};
