import commonTableService from 'services/tableService';
import {
  PERIOD_TYPE,
  TABLE_PERIOD_DATE_FORMAT,
  TABLE_PERIOD_MONTH_FORMAT,
} from 'domain/lpo/period/consts';
import {
  DISPLAY_GROUP_AGGREGATION_AXIS,
  DISPLAY_GROUP_ITEM,
  PERIOD,
} from 'domain/settings/display-items';
import {
  CV_METRICS,
  JP_DAY_OF_WEEK,
  subHeaderDefinitions,
} from 'domain/consts';
import {
  createFieldsFromSettingItems,
  createFieldsFromConversions,
} from 'domain/utils';
import isNil from 'lodash/isNil';
import { containsByAllKeys } from 'services/utils';
import moment from 'moment';

const formatPeriodField = (periodType, value) => {
  switch (periodType) {
    case PERIOD_TYPE.DAY:
      return moment(value).format(TABLE_PERIOD_DATE_FORMAT);
    case PERIOD_TYPE.MONTH:
      return moment(value).format(TABLE_PERIOD_MONTH_FORMAT);
    case PERIOD_TYPE.DOW: {
      const dowAsInt = parseInt(value, 10);
      return `${JP_DAY_OF_WEEK[dowAsInt]}曜`;
    }
    case PERIOD_TYPE.HOUR:
      return `${value}時`;
    default:
      return value;
  }
};

const rowToCells = (row, headers, selectedRows, periodType) => {
  return {
    dimensionCells: headers
      .filter((header) => header.isDimension)
      .flatMap((header) => {
        if (isNil(header.children)) {
          return [...[header]];
        }
        return [...header.children];
      })
      .map((header) => {
        return {
          idKey: header.name,
          nameKey: header.name,
          idValue: row[header.name],
          value:
            header.name === PERIOD
              ? formatPeriodField(periodType, row[header.name])
              : row[header.name],
          isDimension: true,
        };
      }),
    metricCells: headers
      .filter((header) => !header.isDimension)
      .flatMap((header) => {
        if (isNil(header.children)) {
          return [...[header]];
        }
        return [...header.children];
      })
      .map((header) => {
        return {
          idKey: header.name,
          nameKey: header.name,
          idValue: row[header.name],
          value: row[header.name],
          isDimension: false,
        };
      }),
    dimensionKey: row.dimensionKey,
    selected: containsByAllKeys(selectedRows, row.dimensionKey),
  };
};

const buildDisplayRows = ({ list, selectedRows, headers, periodType }) => {
  return list.map((row) => {
    return rowToCells(row, headers, selectedRows, periodType);
  });
};

const buildSortString = (sorts, field) => {
  if (field === 'period') return false;
  return sorts
    .filter((sort) => sort.field === field)
    .reduce((acc, sort) => (sort.asc ? 'asc' : 'desc'), 'none');
};

const buildDisplayHeaders = ({
  items,
  settingItems,
  sorts,
  conversions,
  priorityAxis,
}) => {
  const buildHeaderSortString = (field) => buildSortString(sorts, field);

  const isSubHeaderField = (field) =>
    subHeaderDefinitions.some((def) => def.children.includes(field.key));

  const fields = [{ ...items[PERIOD], key: PERIOD }].concat(
    createFieldsFromSettingItems(items, settingItems).filter(
      (field) => field.getGroup(priorityAxis) === DISPLAY_GROUP_ITEM
    )
  );

  const subHeaders = subHeaderDefinitions
    .filter((def) => fields.some((field) => def.children.includes(field.key)))
    .map((def) => {
      return {
        text: def.text,
        name: def.name,
        isDimension: false,
        children: def.children
          .filter((subHeader) =>
            fields.some((field) => field.key === subHeader)
          )
          .map((subHeader) => {
            const field = fields.find((fld) => fld.key === subHeader);
            return {
              text: field.titleTable || field.title,
              name: field.key,
              isDimension: false,
              sort: buildHeaderSortString(field.key),
            };
          }),
      };
    });

  const selectedCvFields = fields
    .filter((field) => CV_METRICS.includes(field.key))
    .map((field) => field.key);

  const cvHeaders = createFieldsFromConversions(conversions, selectedCvFields);

  const headersWithoutCVs = fields
    .filter(
      (field) =>
        field.getGroup(priorityAxis) !== DISPLAY_GROUP_AGGREGATION_AXIS ||
        field.key === PERIOD
    )
    .filter((field, index) => {
      return (
        !CV_METRICS.includes(field.key) ||
        (index > 0 &&
          fields
            .slice(0, index)
            .every((prevField) => !CV_METRICS.includes(prevField.key)))
        // (fields[index - 1] && !CV_METRICS.includes(fields[index - 1].key))
      );
    });
  const headers = headersWithoutCVs
    .map((field, index) => {
      if (CV_METRICS.includes(field.key)) {
        // Use cvHeaders
        return cvHeaders.map((cvHeader) => cvHeader);
      }

      if (!isSubHeaderField(field)) {
        return [
          {
            text: field.title,
            name: field.key,
            isDimension: field.key === PERIOD,
            sort: buildHeaderSortString(field.key),
            children: null,
          },
        ];
      }

      const currentSubHeader = subHeaders.find((subHeader) =>
        subHeader.children.some((child) => child.name === field.key)
      );

      if (index > 0 && isSubHeaderField(headersWithoutCVs[index - 1])) {
        const prevSubHeader = subHeaders.find((subHeader) =>
          subHeader.children.some(
            (child) => child.name === headersWithoutCVs[index - 1].key
          )
        );
        if (prevSubHeader.name === currentSubHeader.name) return null;
      }

      return [currentSubHeader];
    })
    .filter((header) => !isNil(header))
    .flatMap((headerArr) => [...headerArr]);

  return headers;
};

const buildDisplaySum = ({ sumObject, headers }) =>
  commonTableService.buildDisplaySum({ sumObject, headers });

const updateSelectedRows = ({ displayRows, selectedRows }) =>
  commonTableService.updateSelectedRows({ displayRows, selectedRows });

const updateDisplayHeadersSorts = ({ displayHeaders, sorts }) =>
  commonTableService.updateDisplayHeadersSorts(displayHeaders, sorts);

export default {
  buildDisplayRows,
  buildDisplayHeaders,
  buildDisplaySum,
  updateSelectedRows,
  updateDisplayHeadersSorts,
};
