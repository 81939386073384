const REQUEST_START = 'app-version/REQUEST_START';
const REQUEST_FAILED = 'app-version/REQUEST_FAILED';
const CHECK_VERSION = 'app-version/CHECK_VERSION';
const CHECK_VERSION_SUCCEEDED = 'app-version/CHECK_VERSION_SUCCEEDED';
const FORCE_RELOAD = 'app-version/FORCE_RELOAD';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  CHECK_VERSION,
  CHECK_VERSION_SUCCEEDED,
  FORCE_RELOAD,
};
