import React, { useCallback } from 'react';
import NavBar from 'views/molecules/navbar/component/NavBar';
import { connect, useDispatch } from 'react-redux';
import SettingsActions from 'store/settings/actions';
import settingsSelector from 'store/settings/selectors';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/jsx-props-no-spreading
const NavBarContainer = ({ currentTab, pageId, changeTabType, ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const getChangeTabType = useCallback(
    (key) => {
      const queryParams = new URLSearchParams(location.search);

      if (queryParams.has('mode')) {
        queryParams.delete('mode');
        history.push(`/${pageId}`);

        dispatch(SettingsActions.changeTab(key));
      } else {
        dispatch(SettingsActions.changeTab(key));
      }
    },
    [dispatch, history, location.search, pageId]
  );

  return (
    <NavBar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      pageId={pageId}
      currentTab={currentTab}
      changeTabType={getChangeTabType}
    />
  );
};

const mapStateToProps = (state) => ({
  currentTab: settingsSelector.getTab(state),
  pageId: settingsSelector.getPage(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeTabType: (params) => {
    dispatch(SettingsActions.changeTab(params));
  },
});

NavBarContainer.propTypes = {
  currentTab: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  changeTabType: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarContainer);
