const getDataDetail = (state) =>
  state.cache.adManagementAdGroup2.data.detail.data;
const getErrorDetail = (state) =>
  state.cache.adManagementAdGroup2.data.detail.errors;

const getStatusDetail = (state) =>
  state.cache.adManagementAdGroup2.apiStatus.detail.status;

const adManagementAdGroup2Selectors = {
  getDataDetail,
  getErrorDetail,
  getStatusDetail,
};

export default adManagementAdGroup2Selectors;
