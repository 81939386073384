import types from 'store/tag-management/types';
import helper from 'store/helper';
import { communicationStatus, getFinalPage } from 'services/utils';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';

const { IDLE, SUCCEEDED, FAILED } = communicationStatus;
const FIRST_PAGE = 1;

const initialState = {
  data: {
    list: {
      items: [],
      errors: [],
    },
    detail: {
      item: {},
      errors: [],
    },
    upload: {
      fileName: null,
      list: null,
      errors: [],
    },
  },
  settings: {
    tab: TAG_MANAGEMENT_TAB.CV,
    maxItems: 0, // CV: based on config, PV: 20000, ContentCategory: 10000
    selectedRows: [],
    commonTag: '',
    openConfirmUpload: false,
    pagination: {
      totalItems: 0,
      currentPage: FIRST_PAGE,
    },
    sort: {
      field: '',
      direction: '',
    },
    isShowRemindSC: true,
    isShowRemindNotSC: true,
    contentCategories: {
      data: {},
      errors: [],
    },
  },
  status: {
    list: IDLE,
    detail: IDLE,
    upload: IDLE,
    contentCategories: IDLE,
  },
};

const changeTab = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    tab: action.payload.tab,
  },
});

const updateSort = (state, action) => {
  const { field, direction } = action.payload;

  return {
    ...state,
    settings: {
      ...state.settings,
      sort: {
        field,
        direction,
      },
      pagination: {
        ...state.settings.pagination,
        currentPage: FIRST_PAGE,
      },
    },
  };
};

const updatePage = (state, action) => {
  const { page } = action.payload;

  return {
    ...state,
    settings: {
      ...state.settings,
      pagination: {
        ...state.settings.pagination,
        currentPage: page,
      },
    },
  };
};

const selectRows = (state, action) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      selectedRows: action.payload.rows,
    },
  };
};

const setDataList = (state, action) => {
  const {
    items,
    totalItems,
    maxItems,
    changedToLastPage,
    commonTag,
    isRemindScTagManagement,
    isRemindNotScTagManagement,
  } = action.payload;
  const currentPage = changedToLastPage
    ? getFinalPage(totalItems, TABLE_ROWS_PER_PAGE)
    : state.settings.pagination.currentPage;

  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        items,
        errors: [],
      },
    },
    settings: {
      ...state.settings,
      maxItems,
      pagination: {
        ...state.settings.pagination,
        currentPage,
        totalItems,
      },
      isShowRemindSC: isRemindScTagManagement,
      isShowRemindNotSC: isRemindNotScTagManagement,
      commonTag,
    },
    status: {
      ...state.status,
      list: SUCCEEDED,
    },
  };
};

const setErrorList = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      list: {
        ...state.data.list,
        errors: action.payload.errors,
      },
    },
    status: {
      ...state.status,
      list: FAILED,
    },
  };
};

const setStatusList = (state, action) => ({
  ...state,
  status: {
    ...state.status,
    list: action.payload.status,
  },
});

const setStatusDetail = (state, action) => ({
  ...state,
  status: {
    ...state.status,
    detail: action.payload.status,
  },
});

const setErrorDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      errors: action.payload.errors,
    },
  },
  status: {
    ...state.status,
    detail: FAILED,
  },
});

const setDataDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      item: action.payload.data,
      errors: [],
    },
  },
  status: {
    ...state.status,
    detail: SUCCEEDED,
  },
});

const setDataUpload = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    upload: {
      ...state.data.upload,
      list: action.payload.data.list,
      fileName: action.payload.data.fileName,
    },
  },
  settings: {
    ...state.settings,
    openConfirmUpload: true,
  },
  status: {
    ...state.status,
    upload: SUCCEEDED,
  },
});

const setStatusOpenConfirmUpload = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    openConfirmUpload: action.payload.status,
  },
});

const setStatusUpload = (state, action) => ({
  ...state,
  status: {
    ...state.status,
    upload: action.payload.status,
  },
});

const setErrorUpload = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    upload: {
      ...state.data.upload,
      errors: action.payload.errors,
    },
  },
  status: {
    ...state.status,
    upload: FAILED,
  },
});

const setCsvFileName = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    upload: {
      ...state.data.upload,
      fileName: action.payload.fileName,
    },
  },
});

const setDataContentCategories = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    contentCategories: {
      ...state.settings.contentCategories,
      data: action.payload.data,
      errors: [],
    },
  },
  status: {
    ...state.status,
    contentCategories: SUCCEEDED,
  },
});

const setStatusContentCategories = (state, action) => ({
  ...state,
  status: {
    ...state.status,
    contentCategories: action.payload.status,
  },
});

const setErrorContentCategories = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    contentCategories: {
      ...state.settings.contentCategories,
      data: {},
      errors: action.payload.errors,
    },
  },
  status: {
    ...state.status,
    contentCategories: FAILED,
  },
});

const cleanStates = (state, action) => ({
  ...initialState,
  settings: {
    ...initialState.settings,
    tab: action.payload.tab,
    sort: action.payload.sort,
  },
});

const TagManagementReducer = helper.createReducer(initialState, {
  [types.CHANGE_TAB]: changeTab,
  [types.UPDATE_SORT]: updateSort,
  [types.UPDATE_PAGE]: updatePage,
  [types.SELECT_ROWS]: selectRows,
  [types.SET_DATA_LIST]: setDataList,
  [types.SET_ERROR_LIST]: setErrorList,
  [types.SET_STATUS_LIST]: setStatusList,
  [types.SET_STATUS_DETAIL]: setStatusDetail,
  [types.SET_ERROR_DETAIL]: setErrorDetail,
  [types.SET_DATA_DETAIL]: setDataDetail,
  [types.SET_DATA_UPLOAD]: setDataUpload,
  [types.SET_STATUS_UPLOAD]: setStatusUpload,
  [types.SET_ERROR_UPLOAD]: setErrorUpload,
  [types.SET_STATUS_OPEN_UPLOAD]: setStatusOpenConfirmUpload,
  [types.SET_CSV_FILE_NAME]: setCsvFileName,
  [types.SET_DATA_CONTENT_CATEGORIES]: setDataContentCategories,
  [types.SET_STATUS_CONTENT_CATEGORIES]: setStatusContentCategories,
  [types.SET_ERROR_CONTENT_CATEGORIES]: setErrorContentCategories,
  [types.CLEAN_STATES]: cleanStates,
});

export default TagManagementReducer;
