import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './AxisSelectbox.scss';

const AxisSelectbox = ({
  className,
  label,
  name,
  disabled,
  options,
  defaultValue,
  onSelect,
  emptyText,
  required,
  customView,
}) => {
  const [selected, setSelected] = useState(null);

  const handleSelect = (value) => {
    onSelect(name, value);
    setSelected(value);
  };

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const buttonClass = classNames({
    'd-flex': true,
    'justify-content-between': true,
    'axis-selectbox__button': true,
    'axis-selectbox__button--view': customView,
    // 'axis-selectbox__button--disabled': disabled,
  });
  const labelClass = classNames({
    'axis-selectbox__label': true,
    // 'axis-selectbox__label--disabled': disabled,
  });

  const axisSelectboxClass = classNames({
    'axis-selectbox': true,
    'axis-selectbox--disabled': disabled,
    [className]: className,
  });

  return (
    <div className={axisSelectboxClass}>
      <div className={labelClass}>{label}</div>
      <Dropdown className="axis-selectbox__dropdown" onSelect={handleSelect}>
        <Dropdown.Toggle
          className={buttonClass}
          size="sm"
          variant="select"
          disabled={disabled || options.length === 0}
        >
          <span className="text-truncate">
            {selected in options ? options[selected].title : emptyText}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {!required && (
            <Dropdown.Item active={selected === null}>
              {emptyText}
            </Dropdown.Item>
          )}
          {Object.keys(options).map((key) => (
            <Dropdown.Item key={key} eventKey={key} active={key === selected}>
              {options[key].title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

AxisSelectbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  onSelect: PropTypes.func,
  emptyText: PropTypes.string,
  required: PropTypes.bool,
  customView: PropTypes.bool,
};

AxisSelectbox.defaultProps = {
  className: null,
  disabled: false,
  defaultValue: null,
  onSelect: () => {},
  emptyText: '未選択',
  required: false,
  customView: false,
};

export default React.memo(AxisSelectbox);
