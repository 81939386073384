/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes, { node, string, bool, oneOfType, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EXTERNAL, LTV_ANALYZE } from 'services/routes/constants';
import { TAB_AD } from 'services/consts';
import EbisIcon from 'views/atoms/icon/EbisIcon';

import screenTitleConfigs from 'services/common/screenTitleConfigs';
import useAuth from 'services/custom-hooks/useAuth';

import { getScreenPermissions, getScreenResolveType } from 'domain/utils';
import screenPermissionConfigs, {
  ltvAllPermissions,
} from 'domain/permissions/screenPermissionConfigs';
import MenuItem from 'views/molecules/navbar/component/MenuItem';
import PermissionWrapperRPC from 'services/appconfig/PermissionWrapperRPC';

const MenuIcon = ({
  svgIcon,
  fontIcon,
  active,
  customBgColor,
  iconColor,
  isNewIcon,
  isIconMenu,
}) => {
  if (svgIcon) {
    return (
      <span role="button" className="fa-stack has-background">
        <span className={`bk-svg ${svgIcon}`} />
      </span>
    );
  }

  return (
    <EbisIcon
      isIconMenu={isIconMenu}
      iconName={fontIcon}
      active={active}
      customBgColor={customBgColor}
      iconColor={iconColor}
      newIconDisplay={isNewIcon}
    />
  );
};

MenuIcon.defaultProps = {
  isIconMenu: true,
  svgIcon: '',
  fontIcon: '',
  customBgColor: '',
  iconColor: '#AAAAAA',
  isNewIcon: false,
};

MenuIcon.propTypes = {
  isIconMenu: bool,
  active: bool.isRequired,
  svgIcon: oneOfType([node, string]),
  fontIcon: string,
  customBgColor: string,
  iconColor: string,
  isNewIcon: bool,
};

const MenuListItem = ({
  screenId,
  item,
  active,
  changeHoverItem,
  tab,
  activeHoverItem,
  isDisabled,
  onClickMenuItem,
}) => {
  const { t } = useTranslation();
  let { newIconDisplay = false } = screenTitleConfigs[item.screenId];
  const isMenuTypeExternal = item.type === EXTERNAL;
  const hasSubmenu = !isEmpty(item.submenu);

  const [isActive, setActive] = useState(false);
  const [isShowSubActiveMenu, showSubActiveMenu] = useState(false);

  const { isAllowed } = useAuth(
    getScreenPermissions(item.screenId, tab),
    getScreenResolveType(item.screenId)
  );

  // Case: Request to "LTV Forecast" Service.
  //       User has subscribed to the "LTV Forecast" service, But does not have the minimum required permissions.
  const isLtvSubscribed = useAuth(ltvAllPermissions).isAllowed;
  const isMenuLtvAnalyze = item.screenId === LTV_ANALYZE;
  if (isMenuLtvAnalyze && isLtvSubscribed && !isAllowed) {
    newIconDisplay = false;
    isDisabled = !isAllowed;
  }

  const clsMenu = classNames({
    active: isActive,
    subActive: isShowSubActiveMenu,
    'menu-item': true,
    'menu-item--sub': item.submenu,
    'border-top-menu': isMenuTypeExternal || isMenuLtvAnalyze,
    external: isMenuTypeExternal,
    middle: isMenuLtvAnalyze,
    'ebis--disabled': isDisabled,
  });

  const handleShowHideSubmenu = () => {
    showSubActiveMenu((prev) => !prev);
  };

  useEffect(() => {
    setActive(active);
  }, [screenId, active]);

  useEffect(() => {
    if (hasSubmenu) {
      const subMenuActive = item.submenu.some(
        (itemSubmenu) => screenId === itemSubmenu.screenId
      );
      showSubActiveMenu(subMenuActive);
    }
  }, [screenId, hasSubmenu, item.submenu]);

  return (
    <li
      key={item.title}
      className={clsMenu}
      onMouseEnter={() => !isDisabled && changeHoverItem(item.title)}
      onMouseLeave={() => !isDisabled && changeHoverItem('')}
      onClick={onClickMenuItem}
    >
      <MenuItem
        title={t(item.title)}
        url={item.url}
        type={item.type}
        newTab={item.newTab}
        disabled={isDisabled}
        leftIcon={<MenuIcon svgIcon={item.svg} fontIcon={item.iconClass} />}
        rightIcon={
          item.screenId !== LTV_ANALYZE &&
          newIconDisplay &&
          !isAllowed && (
            <MenuIcon
              isIconMenu={false}
              fontIcon="new"
              active={isActive || activeHoverItem}
              customBgColor={tab === TAB_AD ? '#F5E5E3' : '#F5EDDF'}
              iconColor={tab === TAB_AD ? '#CE4242' : '#B79863'}
              isNewIcon={newIconDisplay}
            />
          )
        }
        onClick={() => hasSubmenu && handleShowHideSubmenu()}
      />
      {hasSubmenu && (
        <ul className="menu-nav">
          {item.submenu.map((subItem) => (
            <PermissionWrapperRPC
              key={subItem.screenId}
              requiredPermissions={
                screenPermissionConfigs[subItem.screenId][tab]
              }
              resolveType="anyOf"
              screenId={subItem.screenId}
              render={(allowed) => {
                const isSubActive = screenId === subItem.screenId;
                const clsSubmenuItem = classNames({
                  'menu-item': true,
                  'ebis--disabled': !allowed,
                  active: isSubActive,
                });

                return (
                  <li className={clsSubmenuItem}>
                    <MenuItem
                      title={t(subItem.title)}
                      url={subItem.url}
                      leftIcon={
                        <MenuIcon
                          svgIcon={subItem.svg}
                          fontIcon={subItem.iconClass}
                        />
                      }
                      type={subItem.type}
                      newTab={subItem.newTab}
                    />
                  </li>
                );
              }}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

MenuListItem.defaultProps = {
  isDisabled: false,
  onClickMenuItem: () => {},
};

MenuListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  screenId: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  changeHoverItem: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  activeHoverItem: PropTypes.bool.isRequired,
  onClickMenuItem: func,
};

export default React.memo(MenuListItem);
