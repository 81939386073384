import React from 'react';
import useCheckSwitchUser from 'services/custom-hooks/useCheckSwitchUser';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';

const SwitchUserModalContainer = () => {
  const [isSwitchUser, handleSubmit] = useCheckSwitchUser();

  if (isSwitchUser === false) {
    return <></>;
  }

  return (
    <ConfirmModal
      isShow
      title="画面を再読み込みしてください"
      jsxContent={
        <>
          別のアカウントでのログインが確認されました。
          <br />
          画面を再読み込みし、最新の状態をご確認ください。
        </>
      }
      onSubmit={handleSubmit}
      cancelBtn={false}
      confirmText="OK"
    />
  );
};

export default SwitchUserModalContainer;
