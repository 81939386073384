import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { PropTypes, shape } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { DISPLAY_GROUP_CONVERSION_ATTRIBUTES } from 'domain/settings/display-items';
import './DisplayItemsModal.scss';
import { PRIORITY_AXIS_EBIS } from 'domain/consts';
import filterSelectors from 'store/filters/selectors';
import commonSelectors from 'store/common/selectors';
import settingsSelector from 'store/settings/selectors';
import DisplayItemsForm from 'views/organism/DisplayItemsForm';
import DisplayItemsCvForm from 'views/organism/DisplayItemsCvForm';
import { isWarningFilter } from 'services/utils';
import { FILTER_KEY_CROSS_DEVICE } from 'services/consts';

const DisplayItemsModal = (props) => {
  const {
    items,
    axisList,
    sortItems,
    defaultValue,
    settingsDefault,
    show,
    funcId,
    onSubmit,
    onHide,
    priorityAxis,
    enabledPriorityAxis,
    crossDeviceReflectionData,
  } = props;

  const [formState, updateFormState] = useState({});
  const [isShow, updateShow] = useState(show);
  const filters = useSelector(filterSelectors.getSettings, shallowEqual);
  const period = useSelector(commonSelectors.periodSelector, shallowEqual);
  const pageId = useSelector(settingsSelector.getPage, shallowEqual);

  const handleCancel = () => {
    updateShow(false);
    onHide();
  };

  const handleFormChange = (data) => {
    updateFormState(data);
  };

  const handleSubmit = () => {
    if (Object.keys(formState).length) {
      onSubmit(formState);
    } else {
      handleCancel();
    }
  };

  useEffect(() => {
    updateShow(show);
  }, [show]);

  // TODO: need clearly logic detect cv analysis
  const isCvAnalysis =
    'cv_name' in items &&
    // eslint-disable-next-line react/prop-types
    items.cv_name.getGroup(priorityAxis) ===
      DISPLAY_GROUP_CONVERSION_ATTRIBUTES;

  const [isCvDisplayDefault, toggleCvDisplayDefault] = useState(true);

  const [resetCvSettings, updateResetCvSettings] = useState(false);
  const handleResetCvForm = () => {
    if (!isCvDisplayDefault) {
      updateResetCvSettings((prev) => !prev);
    }
  };
  useEffect(() => {
    if (!isCvDisplayDefault) {
      updateResetCvSettings(false);
    }
  }, [isCvDisplayDefault]);

  const linkResetCvFormClass = classNames({
    'display-items-modal__reset-link': true,
    'display-items-modal__reset-link--active': !isCvDisplayDefault,
  });

  const modalClass = classNames({
    'display-items-modal': true,
    [`display-items-modal--${funcId}`]: true,
  });

  const warningFilter = isWarningFilter(
    FILTER_KEY_CROSS_DEVICE,
    filters,
    pageId,
    period,
    crossDeviceReflectionData
  );

  return (
    <Modal
      show={isShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="w800"
      className={modalClass}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <>
            {!isCvAnalysis && '項目切替'}
            {isCvAnalysis && (
              <div className="display-items-modal__title">
                <div className="display-items-modal_title-text">項目切替</div>
                <div
                  onClick={handleResetCvForm}
                  className={linkResetCvFormClass}
                  aria-hidden="true"
                >
                  デフォルトに戻す
                </div>
              </div>
            )}
          </>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {isCvAnalysis === false && (
            <DisplayItemsForm
              funcId={funcId}
              items={items}
              axisList={axisList}
              sortItems={sortItems}
              defaultValue={defaultValue}
              settingsDefault={settingsDefault}
              onChange={handleFormChange}
              priorityAxis={priorityAxis}
              enabledPriorityAxis={enabledPriorityAxis}
              isWarning={warningFilter.isWarning}
              filters={filters}
            />
          )}
          {isCvAnalysis && (
            <DisplayItemsCvForm
              items={items}
              axisList={axisList}
              defaultValue={defaultValue}
              settingsDefault={settingsDefault}
              onChange={handleFormChange}
              resetCvSettings={resetCvSettings}
              onCheckDisplayDefault={toggleCvDisplayDefault}
              priorityAxis={priorityAxis}
              enabledPriorityAxis={enabledPriorityAxis}
              isWarning={warningFilter.isWarning}
              filters={filters}
            />
          )}
        </>
      </Modal.Body>
      <Modal.Footer className="display-items-modal__footer">
        <Button
          className="display-items-modal__cancel"
          size="sm"
          variant="link"
          onClick={handleCancel}
        >
          キャンセル
        </Button>
        <Button
          className="display-items-modal__apply"
          size="sm"
          variant="secondary"
          onClick={handleSubmit}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
DisplayItemsModal.propTypes = {
  items: PropTypes.shape({}).isRequired,
  axisList: PropTypes.arrayOf({}),
  sortItems: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.shape({}),
  settingsDefault: PropTypes.shape({}),
  show: PropTypes.bool,
  funcId: PropTypes.string,
  onSubmit: PropTypes.func,
  onHide: PropTypes.func,
  priorityAxis: PropTypes.string,
  enabledPriorityAxis: PropTypes.bool,
  crossDeviceReflectionData: shape([]),
};

DisplayItemsModal.defaultProps = {
  axisList: [],
  sortItems: [],
  defaultValue: {},
  settingsDefault: {},
  show: false,
  funcId: '',
  onSubmit: () => {},
  onHide: () => {},
  priorityAxis: PRIORITY_AXIS_EBIS,
  enabledPriorityAxis: false,
  crossDeviceReflectionData: [],
};

export default React.memo(DisplayItemsModal);
