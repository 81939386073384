import Api from 'services/api/Api';
import apiUtils from 'services/apiUtils';
import { USER_ENDPOINT } from 'services/routes/constants';

const BOOKMARK_ENDPOINT = `${USER_ENDPOINT}/display-settings/bookmark`;
const BOOKMARK_CHART_ENDPOINT = `${USER_ENDPOINT}/display-settings/bookmark/chart`;

const save = async (request) => {
  return Api.postRequest(BOOKMARK_ENDPOINT, request);
};

const get = async (request) => {
  const url = apiUtils.buildUrlWithQueryStrings(BOOKMARK_ENDPOINT, request);
  return Api.getRequest(url);
};

const saveChartSetting = async (request) => {
  return Api.postRequest(BOOKMARK_CHART_ENDPOINT, request);
};

export default {
  save,
  get,
  saveChartSetting,
};
