import { call, put, throttle } from 'redux-saga/effects';
import AppVersionApi from 'services/api/AppVersion';
import types from './types';
import actions from './actions';

function* checkVersion() {
  try {
    yield put(actions.requestStart());
    const response = yield call(AppVersionApi.getVersion);
    yield put(actions.checkVersionSucceeded(response.data));
  } catch (error) {
    yield put(actions.requestFailed({ error }));
  }
}

export default function* watchAppVersion() {
  yield throttle(500, types.CHECK_VERSION, checkVersion);
}
