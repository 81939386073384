const LOG_ACTION = 'logger/LOG_ACTION';
const LOG_UI_ERROR = 'logger/LOG_UI_ERROR';
const LOG_DOWNLOAD_CSV_ERROR = 'logger/LOG_DOWNLOAD_CSV_ERROR';
const SEND_REPORT = 'logger/SEND_REPORT';

// uncatched exception errors
const LOG_ERROR = 'logger/LOG_ERROR';
const CLOSE_ERROR = 'logger/CLOSE_ERROR';

const LOG_CONFIRM = 'logger/LOG_CONFIRM';
const CLOSE_CONFIRM = 'logger/CLOSE_CONFIRM';

export default {
  LOG_ACTION,
  SEND_REPORT,
  LOG_UI_ERROR,
  LOG_DOWNLOAD_CSV_ERROR,
  LOG_ERROR,
  CLOSE_ERROR,
  LOG_CONFIRM,
  CLOSE_CONFIRM,
};
