import React from 'react';
import {
  USER_ID,
  USER_PASSWORD,
  COMPANY_NAME,
  CHARGE_NAME,
  EMAIL,
  AGENT_FLAG,
  AGENCY_ID,
  USER_ROLES,
} from 'domain/fields';
import {
  ROLE_VIEW_AD,
  ROLE_SETTING_OTHER_AGENT,
  ROLE_VIEW_ALL,
  ROLE_VIEW_ALL_AD_ONLY,
  ROLE_SETTING,
  ROLE_EBIS_API,
  ROLE_MANAGE_ACCOUNT,
  ROLE_SETTING_MEDIA_SYNC,
  ROLE_CAPI_SETTING,
  ROLE_CAPI_AGENT_SETTING,
  ROLE_SEARCH_CONSOLE_SETTING,
  ROLE_LINE_ADD_FRIEND_SETTING,
} from 'domain/permissions/roles';
import {
  CAPI_SETTING_VIEW,
  LINE_SETTING_VIEW,
  MANAGE_LTV_EDIT,
  MEDIA_SYNC_MANAGEMENT_EDIT,
  SC_SETTING_VIEW,
  SETTING_EBIS_API_VIEW,
} from 'domain/permissions/contractPermissions';
import { MEDIA_SYNC_MANAGEMENT_DRAGONBALL_LINK } from 'services/routes/constants';
import { get } from 'lodash';
import {
  TOOLTIP_USER_MANAGEMENT_FOR_AGENT_SETTING,
  subNameSettings,
} from 'domain/line-add-friend-settings/constants';

export const SETTING_LIST = [
  {
    name: '基本設定',
    dragonBall:
      '計測対象サイトの登録やページ設定といった、アドエビスの計測に関する設定を行うことができる画面です。',
    permision: [ROLE_MANAGE_ACCOUNT],
    isIgnoreAgent: true,
  },
  {
    name: 'ページ設定',
    id: 'setting',
    dragonBall:
      '共通タグの発行やカスタムタグの登録、編集を行うことができる画面です。',
    permision: [ROLE_SETTING, ROLE_SETTING_OTHER_AGENT],
    isIgnoreAgent: false,
  },
  {
    name: 'ページ設定',
    id: 'settingLine',
    subNames: ['LINE友だち追加計測設定'],
    dragonBall:
      'LINE友だち追加計測設定は、LINE友だち追加をコンバージョンとして計測するための設定を行うことができる画面です。',
    permision: [ROLE_LINE_ADD_FRIEND_SETTING],
    requirePermission: LINE_SETTING_VIEW,
    isIgnoreAgent: false,
  },
  {
    name: '広告設定',
    subNames: ['広告管理', '広告カテゴリ管理', '配信管理'],
    dragonBall:
      '広告の登録や編集・広告パラメーターの発行を行うことができる画面です。',
    permision: [ROLE_SETTING, ROLE_SETTING_OTHER_AGENT],
    isIgnoreAgent: false,
  },
  {
    name: '広告設定',
    subNames: ['媒体シンク設定'],
    dragonBall: (
      <>
        媒体シンク機能に関連する設定や、入稿用のパラメータが発行できる画面です。詳しくは
        <a
          href={MEDIA_SYNC_MANAGEMENT_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    permision: [ROLE_SETTING, ROLE_SETTING_MEDIA_SYNC],
    isIgnoreAgent: false,
    requirePermission: MEDIA_SYNC_MANAGEMENT_EDIT,
  },
  {
    name: 'コスト設定',
    dragonBall:
      'コスト設定は、表示回数・広告コストデータの登録や媒体データ取得の連携設定を行うことができる画面です。',
    permision: [ROLE_SETTING, ROLE_SETTING_OTHER_AGENT],
    isIgnoreAgent: false,
  },
  {
    name: 'LTVForecast分析設定',
    dragonBall: 'LTVForecastの分析に関わる設定を行うことができる画面です。',
    permision: [ROLE_SETTING, ROLE_SETTING_OTHER_AGENT],
    isIgnoreAgent: false,
    requirePermission: MANAGE_LTV_EDIT,
  },
  {
    name: '外部サービス連携設定',
    subNames: ['外部サービス連携設定'],
    dragonBall:
      '外部連携サービス連携設定は、アドエビスと連携することのできる外部サービスの設定を行うことができる画面です。',
    permision: [ROLE_SETTING],
    isIgnoreAgent: true,
  },
  {
    name: '外部サービス連携設定',
    subNames: ['Google Search Console\n連携設定'],
    dragonBall:
      'Google Search Console連携設定は、Google Search Consoleのデータをアドエビスに連携するための設定を行うことができる画面です。',
    permision: [ROLE_SEARCH_CONSOLE_SETTING],
    isIgnoreAgent: false,
    requirePermission: SC_SETTING_VIEW,
  },
  {
    name: 'API',
    dragonBall:
      'API形式で分析画面と同じ集計データが取得できます。権限を剥奪すると発行済みのリフレッシュトークンは無効になります。',
    permision: [ROLE_EBIS_API],
    isIgnoreAgent: true,
    requirePermission: SETTING_EBIS_API_VIEW,
  },
  {
    name: 'CAPiCO設定',
    dragonBall:
      'CAPiCOは広告配信の最適化のために、各媒体とCVデータを紐付ける設定を行うことができる画面です。',
    permision: [ROLE_CAPI_SETTING, ROLE_CAPI_AGENT_SETTING],
    requirePermission: CAPI_SETTING_VIEW,
  },
];

const SETTING_LIST_CAPI_SINGLE = [
  {
    name: '基本設定',
    dragonBall: '',
    permision: [ROLE_MANAGE_ACCOUNT],
  },
  {
    name: 'タグ設定',
    dragonBall: '',
    permision: [ROLE_SETTING],
  },
  {
    name: 'CAPiCO設定',
    dragonBall: '',
    permision: [ROLE_CAPI_SETTING],
  },
];

export const SCREEN_LIST = [
  {
    name: '広告',
    dragonBall: '広告のみを集計対象とした各分析画面を閲覧することができます。',
    permision: [ROLE_VIEW_ALL, ROLE_VIEW_AD],
  },
  {
    name: '全トラフィック',
    dragonBall:
      'すべてのチャネルを集計対象とした各分析画面を閲覧することができます。ご契約プランによって、集計対象となるチャネル種別が異なります。',
    permision: [ROLE_VIEW_ALL],
  },
];

export const createScreenListUserAgent = (agencyRoles = []) => {
  let [permissionAd, permissionAll] = [[], []];
  if (agencyRoles.includes(ROLE_VIEW_AD)) permissionAd = [ROLE_VIEW_AD];
  if (
    agencyRoles.includes(ROLE_VIEW_ALL_AD_ONLY) ||
    agencyRoles.includes(ROLE_VIEW_ALL)
  ) {
    permissionAd = [ROLE_VIEW_ALL, ...permissionAd];
    permissionAll = [ROLE_VIEW_ALL];
  }

  return [
    {
      name: '広告',
      dragonBall:
        '広告のみを集計対象とした各分析画面を閲覧することができます。',
      permision: permissionAd,
    },
    {
      name: '全トラフィック',
      dragonBall:
        'すべてのチャネルを集計対象とした各分析画面を閲覧することができます。ご契約プランによって、集計対象となるチャネル種別が異なります。',
      permision: permissionAll,
    },
  ];
};

export const createSettingListUserAgent = (agencyRoles = []) => {
  const hasRoleSetting = agencyRoles.includes(ROLE_SETTING_OTHER_AGENT);
  const hasRoleMediaSyncSetting = agencyRoles.includes(ROLE_SETTING_MEDIA_SYNC);
  const hasRoleCapiAgentSetting = agencyRoles.includes(ROLE_CAPI_AGENT_SETTING);
  const hasRoleSearchConsoleSetting = agencyRoles.includes(
    ROLE_SEARCH_CONSOLE_SETTING
  );
  const hasRoleLineAddFriendSetting = agencyRoles.includes(
    ROLE_LINE_ADD_FRIEND_SETTING
  );
  return SETTING_LIST.map((settings) => {
    const isIgnoreAgent =
      settings.isIgnoreAgent ||
      (!hasRoleSetting &&
        settings.permision.includes(ROLE_SETTING_OTHER_AGENT)) ||
      (!hasRoleMediaSyncSetting &&
        settings.permision.includes(ROLE_SETTING_MEDIA_SYNC)) ||
      (!hasRoleCapiAgentSetting &&
        settings.permision.includes(ROLE_CAPI_AGENT_SETTING)) ||
      (!hasRoleSearchConsoleSetting &&
        settings.permision.includes(ROLE_SEARCH_CONSOLE_SETTING)) ||
      (!hasRoleLineAddFriendSetting &&
        settings.permision.includes(ROLE_LINE_ADD_FRIEND_SETTING));
    return {
      ...settings,
      permision: isIgnoreAgent ? [] : settings.permision,
      subNames:
        settings && settings.id === 'setting'
          ? subNameSettings
          : get(settings, 'subNames', []),
      dragonBall:
        settings && settings.id === 'setting'
          ? TOOLTIP_USER_MANAGEMENT_FOR_AGENT_SETTING
          : get(settings, 'dragonBall', ''),
    };
  });
};

export const permissionUserAgentConfig = [
  {
    name: ROLE_VIEW_ALL,
    label: (
      <>
        閲覧
        <br />
        （全トラフィック）
      </>
    ),
  },
  {
    name: ROLE_VIEW_AD,
    label: (
      <>
        閲覧
        <br />
        （広告）
      </>
    ),
  },
  {
    name: ROLE_SETTING_OTHER_AGENT,
    label: <>編集</>,
  },
  {
    name: ROLE_SETTING_MEDIA_SYNC,
    label: <>媒体シンク設定</>,
    requirePermission: MEDIA_SYNC_MANAGEMENT_EDIT,
  },
  {
    name: ROLE_SEARCH_CONSOLE_SETTING,
    label: <>Google Search Console連携設定</>,
    requirePermission: SC_SETTING_VIEW,
  },
  {
    name: ROLE_CAPI_AGENT_SETTING,
    label: <>CAPiCO設定</>,
    requirePermission: CAPI_SETTING_VIEW,
  },
  {
    name: ROLE_LINE_ADD_FRIEND_SETTING,
    label: (
      <>
        LINE友だち追加
        <br />
        計測設定
      </>
    ),
    width: '120px',
    requirePermission: LINE_SETTING_VIEW,
  },
];

export const MAX_USER_HAS_CONTRACT_AGENT = 70;
export const MAX_USER_NO_HAS_CONTRACT_AGENT = 20;

export const TABLE_BODY_CONFIG = [
  {
    subHeader: '分析画面',
    subContent: SCREEN_LIST,
  },
  {
    subHeader: '設定画面',
    subContent: SETTING_LIST,
  },
];

export const TABLE_BODY_CONFIG_CAPI_SINGLE = [
  {
    subHeader: '設定画面',
    subContent: SETTING_LIST_CAPI_SINGLE,
  },
];

export const EDIT = 'edit';

export const FIELD_FORM = {
  [USER_ID]: 'ユーザーID',
  [USER_PASSWORD]: 'パスワード',
  [COMPANY_NAME]: '会社名',
  [CHARGE_NAME]: '名前',
  [EMAIL]: 'メールアドレス',
  [AGENT_FLAG]: 'エージェントユーザー',
  [AGENCY_ID]: '担当代理店',
};

export const FIELD_REQUEST_API = {
  [USER_ID]: USER_ID,
  [USER_PASSWORD]: USER_PASSWORD,
  [COMPANY_NAME]: COMPANY_NAME,
  [CHARGE_NAME]: CHARGE_NAME,
  [EMAIL]: EMAIL,
  [AGENT_FLAG]: AGENT_FLAG,
  [AGENCY_ID]: AGENCY_ID,
  [USER_ROLES]: USER_ROLES,
};

export const TABLE_ROWS_PER_PAGE = 35;

export const INITIAL_USER_INFO = {
  [USER_ID]: null,
  [USER_PASSWORD]: null,
  [COMPANY_NAME]: null,
  [CHARGE_NAME]: null,
  [EMAIL]: null,
  [AGENT_FLAG]: false,
  [AGENCY_ID]: '',
};

export const INITAL_DEFAULT_ERRORS = {
  [USER_ID]: null,
  [USER_PASSWORD]: null,
  [COMPANY_NAME]: null,
  [CHARGE_NAME]: null,
  [EMAIL]: null,
  [USER_ROLES]: null,
};

// list error code to fetch list user when create/edit/delete failed
export const LIST_ERROR_TO_FETCH_LIST = [
  'OVER_USER',
  'NOT_EXISTS_DB',
  'ACCEPTED_VALUE',
];
