import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_HEIGHT = 120; // Total height of Topbar + Commonbar

const FreezeHeadContent = ({ children }) => {
  const [scrollTop, setScrollTop] = useState(DEFAULT_HEIGHT);

  useEffect(() => {
    const topbarEL = document.getElementById('top-bar');
    const commonBarEl = document.getElementById('common-bar');
    const freezeHeadAreaEl = document.getElementById('freeze-head-area');
    const mainContentEl = document.getElementById('main-content');

    if (!mainContentEl) return () => {};

    // Set position freeze
    let heightHead = 0;
    if (freezeHeadAreaEl) {
      heightHead = freezeHeadAreaEl.getBoundingClientRect().height;
      freezeHeadAreaEl.style.top = `${scrollTop - heightHead}px`;
    }

    const defaultTop = topbarEL.getBoundingClientRect().height + heightHead;

    // Listen commonbar change height
    const heightObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (
          Array.isArray(entries) &&
          entries.length &&
          entries[0].contentRect.height !== scrollTop - defaultTop
        ) {
          setScrollTop(defaultTop + commonBarEl.getBoundingClientRect().height);
        }
      });
    });

    heightObserver.observe(commonBarEl);
    return () => {
      heightObserver.unobserve(commonBarEl);
    };
  }, [scrollTop]);

  return (
    <div id="freeze-head-area" className="bg-white">
      {children}
    </div>
  );
};

FreezeHeadContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FreezeHeadContent;
