import { put, all, take, select } from 'redux-saga/effects';

import commonActions from 'store/common/actions';
import settingViewActions from 'store/customview/actions';
import displayItemsActions from 'store/display-items/actions';
import displayItemsSelectors from 'store/display-items/selectors';
import displayItemsTypes from 'store/display-items/types';
import SettingViewTypes from 'store/customview/types';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { DASHBOARD } from 'services/routes/constants';

import types from './types';
import actions from './actions';

export default function* watchRedirectFlow() {
  while (true) {
    // Waiting action Rediect
    const action = yield take(types.REDIRECT_REQUEST_START);
    yield put(actions.redirectStart());

    const { screens, items, period, comparedPeriod } = action.payload;
    const funcIdFrom = DisplayItemsService.getFuncIdByPageId(screens.from);
    const funcIdTo = DisplayItemsService.getFuncIdByPageId(screens.to);

    const takeActions = [];
    if (funcIdFrom !== funcIdTo) {
      takeActions.push(take(displayItemsTypes.FETCH_SUCCEEDED));
    }
    if (screens.from === DASHBOARD) {
      takeActions.push(take(SettingViewTypes.FETCH_LIST_SUCCEEDED));
    }

    // Waiting actions
    yield all(takeActions);
    yield put(settingViewActions.applyIsChanged({ changed: true }));
    const displayItems = yield select(displayItemsSelectors.getSettings);
    yield put(
      displayItemsActions.fetchSucceeded({
        items: { ...displayItems, ...items },
      })
    );

    if (period && comparedPeriod) {
      yield put(
        commonActions.updatePeriodWithCompared({
          period,
          comparedPeriod,
        })
      );
    }

    yield put(actions.redirectSucceeded());
  }
}
