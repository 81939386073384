import React, { useState, useMemo } from 'react';
import { string, shape, bool } from 'prop-types';
import { Button } from 'react-bootstrap';

import TableViewDetail from 'views/organism/table-view-detail/TableViewDetail';
import VariousTag from 'views/pages/tag-management/components/ViewDetail/VariousTag';
import {
  CONDITION_LABELS,
  TAG_MANAGER_LABELS,
  TAG_MANAGER_GTM,
  TAG_MANAGEMENT_TAB,
  TAGS,
  CV_TAG_STRING_EMPTY,
  MODE_FILE_NAME_DOWNLOAD,
} from 'domain/tag-management/consts';
import { onOpenNewWindow, downloadTxtFile } from 'services/utils';
import { TEXT_NON_SETUP } from 'domain/consts';
import {
  getTagString,
  getTagMode,
} from 'services/tag-management/tagManagementServices';
import useCheckPermissionLog from 'services/custom-hooks/useCheckPermissionLog';

const { CUSTOMIZE, ONLY_CT, COMMON, MOBILE, GTM_DATALAYER } = TAGS;

function ViewDetail({ infoDetail, currentTab, isCapiSingle }) {
  const {
    tags,
    page_id: pageId,
    page_title: pageTitle,
    // CV
    condition,
    has_attribute: hasAttribute,
    tag_manager_type: tagManagerType,
    cv_title: cvTitle,
    // PV
    owned_media_flag: ownedMediaFlag,
    content_category_name: contentCategoryName,
  } = infoDetail;

  const isCvTab = currentTab === TAG_MANAGEMENT_TAB.CV;
  const isPVTab = currentTab === TAG_MANAGEMENT_TAB.PV;
  const isUsingGtm = tagManagerType === TAG_MANAGER_GTM;
  const isCvInfo =
    condition !== null || hasAttribute !== null || tagManagerType !== null;

  const { hasContractLog } = useCheckPermissionLog();

  const [currentMode, setMode] = useState(CUSTOMIZE);

  const tagMode = useMemo(() => {
    return getTagMode({
      hasAttribute,
      condition,
      isUsingGtm,
    });
  }, [hasAttribute, condition, isUsingGtm]);

  const [tagCv, tagCommon, tagString] = useMemo(() => {
    return [
      tagMode !== ONLY_CT ? getTagString({ tags, tagMode }) : '',
      getTagString({ tags, tagMode: COMMON }),
      getTagString({ tags, tagMode: currentMode }),
    ];
  }, [currentMode, tagMode, tags]);

  const tagText = useMemo(() => {
    if (currentMode === CUSTOMIZE) {
      if (!isCvInfo && isCvTab) return CV_TAG_STRING_EMPTY;
      return tagCv ? `${tagCv}\n \n${tagCommon}` : tagCommon;
    }
    if (currentMode === MOBILE) {
      return tagString;
    }
    return `${tagString}\n \n${tagCommon}`;
  }, [isCvTab, isCvInfo, currentMode, tagString, tagCommon, tagCv]);

  const handleDownloadTag = () => {
    let value = tagText;
    if (currentMode === CUSTOMIZE) {
      value = tagCv ? `${tagCv}\n \n${tagCommon}` : tagCommon;
    }
    downloadTxtFile(value, `${MODE_FILE_NAME_DOWNLOAD[currentMode]}${pageId}`);
  };

  const onOpenNewWindowGoogle = () => {
    const mode = getTagMode({
      displayMode: 'info',
      hasAttribute,
      condition,
      isUsingGtm,
    });

    const tag =
      tagMode !== ONLY_CT ? getTagString({ tags, tagMode: mode }) : '';
    const gtmDatalayer = getTagString({ tags, tagMode: GTM_DATALAYER });

    const dataSession = {
      condition,
      gtmDatalayer,
      tagCommon,
      tagText: tag ? `${tag}\n \n${tagCommon}` : tagCommon,
    };

    sessionStorage.setItem(pageId, JSON.stringify(dataSession));
    onOpenNewWindow({
      url: `/google-tag.html?pageId=${pageId}`,
      type: '_blank',
      optional:
        'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=725,height=1137',
    });
  };

  const allData = [
    {
      allowDisplay: true,
      title: 'ページID',
      value: pageId,
    },
    {
      allowDisplay: true,
      title: 'ページタイトル',
      value: pageTitle,
    },
    {
      allowDisplay: isPVTab && hasContractLog,
      title: 'サイトコンテンツ',
      value: ownedMediaFlag ? '対象' : '対象外',
    },
    {
      allowDisplay: isPVTab && hasContractLog,
      title: 'コンテンツカテゴリ',
      value: contentCategoryName || TEXT_NON_SETUP,
    },
    {
      allowDisplay: isCvTab,
      title: 'CV名',
      value: cvTitle,
    },
    {
      allowDisplay: isCvTab && !isCapiSingle,
      title: 'タグマネージャー',
      value: (
        <div className="d-flex align-items-center">
          <span>{TAG_MANAGER_LABELS[tagManagerType] || TEXT_NON_SETUP}</span>
          {isUsingGtm && (
            <Button
              variant="secondary"
              size="sm"
              className="google-tag-button"
              onClick={onOpenNewWindowGoogle}
            >
              設定方法
            </Button>
          )}
        </div>
      ),
    },
    {
      allowDisplay: isCvTab,
      title: 'CV条件',
      value: CONDITION_LABELS[condition] || TEXT_NON_SETUP,
    },
    {
      allowDisplay: isCvTab,
      title: '属性の取得',
      value: isCvInfo
        ? `${hasAttribute ? '取得する' : '取得しない'}`
        : TEXT_NON_SETUP,
    },
    {
      allowDisplay: true,
      title: '各種タグ',
      value: (
        <VariousTag
          isPvTag={isPVTab}
          isCapiSingle={isCapiSingle}
          isUsingGtm={isUsingGtm}
          tagOption={currentMode}
          tagText={tagText}
          tagCommon={tagCommon}
          handleChangeMode={setMode}
          handleDownloadTag={handleDownloadTag}
        />
      ),
    },
  ];

  const dataDisplay = allData.filter((item) => item.allowDisplay);

  return (
    <div className="tag-detail-container">
      <TableViewDetail data={dataDisplay} />
    </div>
  );
}

ViewDetail.propTypes = {
  infoDetail: shape({}).isRequired,
  currentTab: string.isRequired,
  isCapiSingle: bool,
};

ViewDetail.defaultProps = {
  isCapiSingle: false,
};

export default ViewDetail;
