const UPDATE_FILTERS = 'filters/UPDATE_FILTERS';
const UPDATE_AGENCY_FILTERS = 'filters/UPDATE_AGENCY_FILTERS';
const UPDATE_FILTERS_TAG_MANAGEMENT = 'filters/UPDATE_FILTERS_TAG_MANAGEMENT';
const UPDATE_FILTERS_PAGE_MANAGEMENT = 'filters/UPDATE_FILTERS_PAGE_MANAGEMENT';

export default {
  UPDATE_FILTERS,
  UPDATE_AGENCY_FILTERS,
  UPDATE_FILTERS_TAG_MANAGEMENT,
  UPDATE_FILTERS_PAGE_MANAGEMENT,
};
