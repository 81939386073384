const LINE_CONTROL_CHARACTER_CHECK = `制御文字を削除してください。`;
const LINE_VALUE_IS_EXISTS = `入力された{label}はすでに登録済みです。`;
const LINE_VALUE_DUPLICATED = `登録された{label}の内容が一部重複しています。`;
const LINE_VALUE_IS_EMPTY = `{label}を入力してください。`;
const LINE_VALUE_NOT_ALLOWED = `{label}に[未登録]は使用できません。[未登録]とならないよう修正してください。`;
const LINE_SPTAB_CHECK = `{label}を「スペース、タブ、改行」のみで登録することはできません。「スペース、タブ、改行」以外の文字列を入力してください。`;
const ELEMENT_ENCODING_CHECK = `使用できない文字（特殊な記号や常用外漢字など）が含まれています`;

export const inputUrlError = {
  INVALID_URL: `ポストバックURLが不正です。入力した内容や登録方法をご確認ください。`,
  URL_MAX_LENGTH: `2,083字以内で入力してください。`,
};

export const DELETE_ERROR = {
  ASSOCIATED_ID: `{label}ID:{id}は登録広告で使用されているため、削除できませんでした。`,
  ASSOCIATED_MEDIA_SYNC_ID: `{label}ID:{id}は媒体シンク設定に設定されているため、削除できませんでした。`,
  NOT_EXISTS_DB: `選択された{label}は既に削除されています。画面を再読み込みし、最新の状態をご確認ください。`,
  AGENCY_HAVE_NOT_PERMISSION:
    'こちらの{label}を削除する権限がありません。アカウント管理権限があるユーザーにご確認ください。',
};

export const inputMediaError = {
  OVER_MAX_ITEM: `1度に登録できる{label}は{length}件までです。`,
  OVER_MAX_LENGTH: `{length}文字以内で入力してください。`,
  OVER_MAX_RECORD: `{label}に登録上限数{length}件を超えてしまうため、登録できません。`,
  VALUE_IS_DUPLICATED: LINE_VALUE_DUPLICATED,
  VALUE_IS_EMPTY: LINE_VALUE_IS_EMPTY,
  CONTROL_CHARACTER_CHECK: LINE_CONTROL_CHARACTER_CHECK,
  VALUE_NOT_ALLOWED: LINE_VALUE_NOT_ALLOWED,
  SPTAB_CHECK: LINE_SPTAB_CHECK,
  LINE_VALUE_IS_EXISTS,
  ELEMENT_ENCODING_CHECK,
};

export const ERROR_LINE_MAIN_MESSAGE = `{label}登録エラーが{length}件あります。対応方法を参考に、修正してください。`;
export const msgLineError = {
  LINE_OVER_MAX_LENGTH_INPUT: `{label}は{length}字以下で入力してください。`,
  LINE_CONTROL_CHARACTER_CHECK,
  LINE_VALUE_IS_EXISTS,
  LINE_VALUE_DUPLICATED,
  LINE_VALUE_IS_EMPTY,
  LINE_VALUE_NOT_ALLOWED,
  LINE_SPTAB_CHECK,
  AGENCY_HAVE_NOT_PERMISSION:
    'こちらの{label}を編集する権限がありません。アカウント管理権限があるユーザーにご確認ください。',
  ELEMENT_ENCODING_CHECK,
};

export const msgLineCsvError = {
  LINE_NOT_EMPTY: `ファイルに改行を含めて登録することはできません。改行を削除してください。`,
  LINE_VALUE_DUPLICATED: `{label}{index.0}が重複しています。`,
  LINE_INVALID: `入力された内容が不正で登録することはできません。`,
  LINE_NOT_EXISTS_DB: `{label}IDが存在しません。登録済みの{label}IDを指定してください。`,
  LINE_OVER_MAX_LENGTH: `{label}は{index.0}字以下で入力してください。`,
  LINE_OVER_MAX_RANK: `並び順は18字以下で入力してください。`,
  LINE_INVALID_RANK: `並び順は半角英数の整数で入力してください。`,
  LINE_INVALID_ID: `IDは半角英数の整数で入力してください。`,
  LINE_VALUE_IS_EXISTS: `入力された{index.0}はすでに登録済みです。`,
  LINE_CONTROL_CHARACTER_CHECK,
  LINE_VALUE_IS_EMPTY,
  LINE_VALUE_NOT_ALLOWED,
  LINE_SPTAB_CHECK,
  AGENCY_HAVE_NOT_PERMISSION:
    'こちらの{label}を編集する権限がありません。アカウント管理権限があるユーザーにご確認ください。',
  AD_GROUP_HAVE_NOT_PERMISSION:
    'こちらの{label}を編集する権限がありません。アカウント管理権限があるユーザーにご確認ください。',
  ELEMENT_ENCODING_CHECK,
};

export const msgMediaFileCsvError = {
  FILE_OVER_MAX_SIZE: `1ファイルあたり{size}MB以下`,
  FILE_OVER_MAX_LINE: `CSVの登録上限は、{length}行までです。`,
  FILE_HEADER_INVALID: `CSVフォーマットの形式が異なります。[CSVフォーマット]ボタンより最新のフォーマットをダウンロードし、再度アップロードしてください。`,
  FILE_OVER_MAX_RECORD: `登録できる{label}は{length}件までです。`,
  FILE_INVALID: `指定されたCSVのフォーマットと異なっています。CSVフォーマットを再度ダウンロードし、正しいフォーマットで再度登録してください。`,
};
