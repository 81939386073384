import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPageByPath, getModeByPath } from 'services/routes/page-helpers';
import getDocumentTitle from 'services/appconfig/DocumentTitleService';
import SettingsActions from 'store/settings/actions';
import settingsSelector from 'store/settings/selectors';
import logging from 'domain/logging';
import pages from 'services/routes/pages';
import useAllowScreenOrder from 'services/custom-hooks/useAllowScreenOrder';
import useCheckScreenExists from 'services/custom-hooks/useCheckScreenExists';
import { TAB_AD, TAB_ALL, PAGES_SETTINGS } from 'services/consts';
import { USER_PROFILE } from 'services/routes/constants';

const usePageChange = () => {
  const currentTab = useSelector(settingsSelector.getTab);
  const currentPage = useSelector(settingsSelector.getPage);
  const { firstAllowedScreen } = useAllowScreenOrder();
  const { isExistsScreen } = useCheckScreenExists();
  // update document title
  // listen history.location changed
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const unlisten = history.listen((location) => {
      const page = getPageByPath(location.pathname);
      if (currentPage && page && currentPage !== page) {
        // update state page changed
        dispatch(SettingsActions.changePage(page.screenId));
        if (PAGES_SETTINGS.includes(page.screenId)) {
          const mode = getModeByPath(location.pathname);
          dispatch(SettingsActions.changeMode(mode));
        }
        logging.info('usePageChange.SettingsActions.changePage', page);
      } else {
        logging.info('Not found page matching path', window.location.pathname);
      }
    });
    const page = getPageByPath(window.location.pathname);
    if (!currentPage || (currentPage && currentPage !== page)) {
      if (isExistsScreen) {
        const targetTab = currentTab || TAB_AD;
        if (page.screenId === USER_PROFILE) {
          dispatch(SettingsActions.changeTab(TAB_ALL));
        } else {
          dispatch(SettingsActions.changeTab(targetTab));
        }
        dispatch(SettingsActions.changePage(page.screenId));
      } else if (firstAllowedScreen) {
        const { screenId, tab } = firstAllowedScreen;
        const targetPage = pages[screenId];
        const targetTab = [TAB_AD, TAB_ALL].includes(tab) ? tab : TAB_AD;
        dispatch(SettingsActions.changeTab(targetTab));
        dispatch(SettingsActions.changePage(screenId));
        history.push(targetPage.pathDefault ?? targetPage.path);
      } else {
        // TODO: if user doesn't have any permissions, redirect here
        // history.push(getScreenPath(PAGE_NOT_AUTHORIZED));
      }
    }
    return () => {
      unlisten();
    };
  }, [
    t,
    currentPage,
    currentTab,
    dispatch,
    firstAllowedScreen,
    history,
    isExistsScreen,
  ]);

  useEffect(() => {
    const page = pages[currentPage];
    document.title = getDocumentTitle(t, page, currentTab);
  }, [t, currentTab, currentPage]);
};

export default usePageChange;
