// Filters
const UPDATE_FILTERS = 'ad-management-settings/UPDATE_FILTERS';

// Display items
const CREATE_DISPLAY_ITEMS = 'ad-management-settings/CREATE_DISPLAY_ITEMS';
const SET_STATUS_DISPLAY_ITEMS =
  'ad-management-settings/SET_STATUS_DISPLAY_ITEMS';
const FETCH_DISPLAY_ITEMS = 'ad-management-settings/FETCH_DISPLAY_ITEMS';
const CREATE_DISPLAY_ITEMS_SUCCEEDED =
  'ad-management-settings/CREATE_DISPLAY_ITEMS_SUCCEEDED';
const FETCH_DISPLAY_ITEMS_SUCCEEDED =
  'ad-management-settings/FETCH_DISPLAY_ITEMS_SUCCEEDED';
const REQUEST_FAILED = 'ad-management-settings/REQUEST_FAILED';

export default {
  UPDATE_FILTERS,
  CREATE_DISPLAY_ITEMS,
  SET_STATUS_DISPLAY_ITEMS,
  FETCH_DISPLAY_ITEMS,
  CREATE_DISPLAY_ITEMS_SUCCEEDED,
  REQUEST_FAILED,
  FETCH_DISPLAY_ITEMS_SUCCEEDED,
};
