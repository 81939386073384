import moment from 'moment';
import {
  MEASUREMENT_TYPE_DIRECT,
  MEASUREMENT_TYPE_REDIRECT,
  API_DATE_FORMAT,
  UNREGISTERED_ID_FILTER_KEY,
} from 'domain/consts';
import { FILTER_KEY_ADMNG_AD_TYPE } from 'services/consts';
import { formatNumber } from 'domain/utils';
import { isEmpty, isObject } from 'lodash';
import mapValues from 'lodash/mapValues';
import {
  MEASUREMENT_TYPE,
  URL,
  URLS,
  AD_URL,
  BASE_CPC,
  BASE_CPCONV,
  AD_ID,
  KEYWORD,
  MEDIA_ID,
  MEDIA_NAME,
  AD_GROUP1_ID,
  AD_GROUP1_NAME,
  AD_GROUP2_ID,
  AD_GROUP2_NAME,
  AGENCY_ID,
  AGENCY_NAME,
  BANNER,
  QR_CODE,
  TERMINAL_TYPE,
  PAGE_TITLE,
  AD_START_DATE,
  AD_END_DATE,
  AD_NOTE,
} from 'domain/fields';
import { APP_HELP_UPLOAD_CSV_TOO_MAX_ERROR } from 'services/routes/constants';
import {
  AD_MANAGEMENT_MODE,
  DELIVERY_TYPE_TDV,
  URL_MAX,
  PAGE_TITLE_MAX,
} from 'domain/ad-management/consts';
import {
  formatMessage,
  getErrorMessageByCode as getErrorMessage,
  getLinesString,
} from 'services/utils';
import adErrorMessages from 'services/validations/adErrorMessages';

const addNewKeyForDataList = (list, selectedRows) => {
  return list.map((item) => {
    return {
      ...item,
      rowId: item.ad_id,
      selected: selectedRows.includes(item.ad_id),
      isDisabledEdit: !isEmpty(item.parent_ad_id),
    };
  });
};

const updateHeaderCheckAll = (headers, isAllChecked, selectedRows) => {
  const newHeaders = [...headers];
  newHeaders[0].checked = selectedRows.length > 0;
  newHeaders[0].variant =
    selectedRows.length > 0 && !isAllChecked ? 'mixed' : '';

  return newHeaders;
};

/**
 * Set default value for form registe ad
 * @param {object} fieldForm
 * @param {object} data
 * @return {{key, value}}
 */
const setDefaultValueFormRegister = (
  fieldForm,
  data,
  unknownAd = false,
  isLpo,
  newLpupCount
) => {
  if (isEmpty(data)) {
    const initialData = mapValues(fieldForm, () => '');
    initialData[MEASUREMENT_TYPE] = isLpo
      ? MEASUREMENT_TYPE_REDIRECT
      : MEASUREMENT_TYPE_DIRECT;
    return {
      ...initialData,
    };
  }

  const results = {};
  Object.entries(fieldForm).forEach(([name]) => {
    let value = data[name] || '';
    if (URL === name) {
      value = isEmpty(data[AD_URL]) ? '' : data[AD_URL][0][URL];
    } else if (URLS === name) {
      if (isLpo) {
        const lpoUrls = data[AD_URL][0].url.map((url, index) => {
          const urls = {
            url,
            page_title: data[AD_URL][0].page_title[index],
            transition_rate: data[AD_URL][0].transition_rate[index],
          };
          return urls;
        });
        if (lpoUrls.length < newLpupCount) {
          for (
            let i = 0;
            i < newLpupCount - data[AD_URL][0].url.length;
            i += 1
          ) {
            lpoUrls.push({ url: '', page_title: '', transition_rate: '' });
          }
        }
        value = lpoUrls;
      } else {
        value = data[AD_URL].map((item) => ({
          ...item,
          [TERMINAL_TYPE]: item[TERMINAL_TYPE] || '',
        }));
      }
    } else if (BANNER === name && value) {
      value = value.split('/').pop();
    } else if (MEASUREMENT_TYPE === name && unknownAd) {
      value = MEASUREMENT_TYPE_REDIRECT;
    } else if ([AD_END_DATE, AD_START_DATE].includes(name) && value) {
      value = moment(value).format(API_DATE_FORMAT);
    } else if ([BASE_CPC, BASE_CPCONV].includes(name) && +value === 0) {
      value = '';
    }

    results[name] = value;
  });

  return results;
};

const isMeasureHost = (url, hosts) => {
  const host = url.replace(/^https?/, '');
  return hosts.some((item) => {
    if (item && host.indexOf(item.replace(/^https?/, '')) === 0) {
      return true;
    }
    return false;
  });
};

const checkDenyFilterTerminalType = (filter, mode) => {
  const hasFilterAdType = FILTER_KEY_ADMNG_AD_TYPE in filter;
  const hasFilterAdTypeAll =
    hasFilterAdType && filter.ad_type.ids.includes(AD_MANAGEMENT_MODE.ALL);

  if (mode === AD_MANAGEMENT_MODE.ALL) {
    return true;
  }

  if (
    mode === AD_MANAGEMENT_MODE.DELETED &&
    (!hasFilterAdType || hasFilterAdTypeAll)
  ) {
    return true;
  }

  return false;
};

const checkFieldDetailDisplay = (field, data, isDisplayAgencyField) => {
  const {
    delivery_type: deliveryType,
    ydn_flag: isYdn,
    base_cpc: baseCpc,
    base_cpconv: baseCpconv,
    lpo_flag: lpoFlag,
  } = data;

  const allField = { ...field };
  const adType = lpoFlag ? 'lpo' : 'ad';

  if (!isDisplayAgencyField) {
    delete allField[AGENCY_NAME];
  }
  if (adType === 'lpo') {
    delete allField[PAGE_TITLE];
    delete allField[MEASUREMENT_TYPE];
  }

  const isDisplayBaseCpconv =
    isEmpty(baseCpc) && !isEmpty(baseCpconv) && !lpoFlag;

  const fieldsDisplay = Object.entries(allField)
    .filter(([name]) => {
      return (
        [
          AD_ID,
          KEYWORD,
          MEDIA_NAME,
          AD_GROUP1_NAME,
          AD_GROUP2_NAME,
          BANNER,
        ].includes(name) ||
        (deliveryType !== DELIVERY_TYPE_TDV &&
          (![BASE_CPCONV, QR_CODE, PAGE_TITLE].includes(name) ||
            (name === PAGE_TITLE && !isYdn) ||
            (name === QR_CODE && !isYdn) ||
            (name === BASE_CPCONV && isDisplayBaseCpconv)))
      );
    })
    .map(([name, title]) => ({ name, title }));

  return fieldsDisplay;
};

const checkFieldFormDisplay = (
  field,
  labels,
  isListing,
  isYdn,
  isDisplayAgencyField,
  isLpo
) => {
  const allField = { ...field };

  if (!isDisplayAgencyField) {
    delete allField[AGENCY_ID];
  }

  const fieldDisplay = Object.keys(allField).reduce((acc, key) => {
    const obj = { ...acc };
    if ([AD_NOTE, AD_GROUP1_ID, AD_GROUP2_ID].includes(key)) {
      obj[key] = { ...field[key], label: labels[key] };
    } else if (
      (isLpo && ![URL].includes(key)) ||
      (!isLpo && !isListing && ![URLS].includes(key))
    ) {
      obj[key] = field[key];
    } else if (
      (!isLpo && !isYdn && ![BASE_CPCONV].includes(key)) ||
      ((isLpo || isYdn) && ![URL, PAGE_TITLE, BASE_CPCONV].includes(key))
    ) {
      obj[key] = field[key];
    }

    return obj;
  }, {});

  return fieldDisplay;
};

/**
 * @param {[]} data
 * @return {[]}
 */
const formatDataConfirmUpload = (data) => {
  return data.map((item) => {
    const newItem = { ...item, line: `${item.line}行目`, url: item.after_url };
    if (item.before_url) {
      newItem.url = [
        `【変更前】${item.before_url}`,
        `【変更後】${item.after_url}`,
      ];
    }
    return newItem;
  });
};

const getErrorMessageByCode = (error, options) => {
  const { code, metadata } = error;
  const params = metadata?.param || [];

  const replace = { ...options };

  switch (code) {
    case 'TOO_MAX_ERROR': {
      const [length] = params;
      replace.length = length || 200;
      replace.link = APP_HELP_UPLOAD_CSV_TOO_MAX_ERROR;
      break;
    }
    case 'FILE_MAX_LINE_CHECK':
    case 'FILE_CONTENT_VALID_CHECK':
    case 'LIMIT_AD_CHECK': {
      const [length] = params;
      replace.length = formatNumber(length);
      break;
    }
    case 'FILE_SIZE_CHECK': {
      const [size] = params;
      replace.size = size;
      break;
    }
    default:
      break;
  }

  return formatMessage(adErrorMessages[`MSG_CSV_${code}`], { ...replace });
};

/**
 * Format Group by Error CODE
 * @param {array} errors
 * @param {object} configDisplay
 * @param {object} options
 * @return {array}
 */
const formatGroupErrorCsv = (
  errors,
  configDisplay,
  options = {},
  mode = null,
  lpupCount = 0
) => {
  if (isEmpty(errors)) return [];

  const { MSG_BY_FIELD } = adErrorMessages;
  if (mode === AD_MANAGEMENT_MODE.LPO) {
    for (let i = 1; i <= lpupCount; i += 1) {
      MSG_BY_FIELD[`url${i}`] = {
        replace: {
          label: `リンク先URL${i}`,
          length: URL_MAX,
        },
        message: adErrorMessages.LINK_URL_MASSAGE,
      };
      MSG_BY_FIELD[`page_title${i}`] = {
        replace: {
          label: `タイトル${i}`,
          length: PAGE_TITLE_MAX,
        },
        message: adErrorMessages.PAGE_TITLE_MASSAGE,
      };
      MSG_BY_FIELD[`transition_rate${i}`] = {
        replace: {
          label: `遷移割合${i}`,
          length: URL_MAX,
          min: 0,
          max: 100,
        },
        message: adErrorMessages.TRANSITION_RATE_MASSAGE,
      };
    }
  }
  const mappingField = {
    media_name: MEDIA_ID,
    ad_group1_name: AD_GROUP1_ID,
    ad_group2_name: AD_GROUP2_ID,
    agency_name: AGENCY_ID,
  };
  const mappingLabel = {
    ad_note: configDisplay.ad_note,
    [AD_GROUP1_ID]: configDisplay.ad_group1,
    [AD_GROUP2_ID]: configDisplay.ad_group2,
  };

  return errors.map((error) => {
    const { lines, field } = error;

    if (!lines) return { ...error };
    const LINE_SIZE = 10;

    const name = mappingField[field] || field;
    const replace = MSG_BY_FIELD[name]?.replace || {};
    const messages = MSG_BY_FIELD[name]?.message.csv || {};

    let message = '';

    if (['LIMIT_AD_CHECK'].includes(error.code)) {
      message = getErrorMessage(error, {
        LIMIT_AD_CHECK: adErrorMessages.MSG_CSV_LIMIT_AD_CHECK,
      });
    } else {
      message = formatMessage(messages[error.code] || '', {
        ...replace,
        ...options,
        label: mappingLabel[name] || replace.label,
      });
    }

    return {
      ...error,
      line: getLinesString(lines, LINE_SIZE),
      message: message.split('<br/>'),
    };
  });
};

/**
 * @param {array} errors
 * @param {object} configDisplay
 * @return {object}
 */
const formatDataErrorForForm = (errors, configDisplay) => {
  if (isEmpty(errors)) return {};

  const { MSG_BY_FIELD } = adErrorMessages;

  const mappingLabel = {
    ad_note: configDisplay.ad_note,
    [AD_GROUP1_ID]: configDisplay.ad_group1,
    [AD_GROUP2_ID]: configDisplay.ad_group2,
  };

  return errors.reduce((acc, error) => {
    const { field, code } = error;
    const replace = MSG_BY_FIELD[field]?.replace || {};
    const messages = MSG_BY_FIELD[field]?.message.form || {};

    let message = '';
    if (['LIMIT_AD_CHECK'].includes(code)) {
      message = getErrorMessage(error, {
        LIMIT_AD_CHECK: adErrorMessages.MSG_TOOLTIP_LIMIT_AD_CHECK,
      });
    } else {
      message = formatMessage(messages[code] || '', {
        ...replace,
        label: mappingLabel[field] || replace.label,
      });
    }

    acc[field] = message;

    return acc;
  }, {});
};

/**
 * @param {array} errors
 * @param {object} configDisplay
 * @return {object}
 */
const formatDataErrorForLpoForm = (errors, configDisplay) => {
  if (isEmpty(errors)) return {};

  const { MSG_BY_FIELD } = adErrorMessages;

  const mappingLabel = {
    ad_note: configDisplay.ad_note,
    [AD_GROUP1_ID]: configDisplay.ad_group1,
    [AD_GROUP2_ID]: configDisplay.ad_group2,
  };

  const acc = {};
  const urls = {};
  errors.forEach(error => {
    const { field, code } = error;

    const newField = field.replace(/^urls\.[0-9]*\./, 'lpo_api_');
    const replace = field.indexOf('urls.') === 0 ? MSG_BY_FIELD[newField]?.replace || {} : MSG_BY_FIELD[field]?.replace || {};
    const messages = field.indexOf('urls.') === 0 ? MSG_BY_FIELD[newField]?.message.form || {} : MSG_BY_FIELD[field]?.message.form || {};

    let message = '';
    if (['LIMIT_AD_CHECK'].includes(code)) {
      message = getErrorMessage(error, {
        LIMIT_AD_CHECK: adErrorMessages.MSG_TOOLTIP_LIMIT_AD_CHECK,
      });
    } else {
      message = formatMessage(messages[code] || '', {
        ...replace,
        label: mappingLabel[field] || replace.label,
      });
    }

    if (field.indexOf('urls.') === 0) {
      urls[field] = message;
    } else {
      acc[field] = message;
    }
  });

  const accUrls = [];
  if (Object.keys(urls).length > 0) {
    for (let i = 0; i <= 4; i += 1) {
      const url = {};
      const fields = ['url', 'page_title', 'transition_rate'];
      for (let j = 0; j <= fields.length; j += 1) {
        const field = `urls.${i}.${fields[j]}`;
        url[fields[j]] = field in urls ? urls[field] : "";
      }
      accUrls.push(url);
    }
  }
  acc.urls = accUrls;

  return acc;
};

/**
 * @param {object} data
 * @return {array}
 */
const formatMasterdataForSettings = (data) => {
  if (isEmpty(data) || !isObject(data)) return data;

  return Object.keys(data)
    .filter((id) => id !== UNREGISTERED_ID_FILTER_KEY)
    .map((id) => ({ ...data[id], id: `${id}` }));
};

export {
  addNewKeyForDataList,
  updateHeaderCheckAll,
  setDefaultValueFormRegister,
  checkFieldDetailDisplay,
  checkFieldFormDisplay,
  formatDataConfirmUpload,
  formatGroupErrorCsv,
  formatDataErrorForForm,
  formatDataErrorForLpoForm,
  getErrorMessageByCode,
  isMeasureHost,
  checkDenyFilterTerminalType,
  formatMasterdataForSettings,
};
