import { createSelector } from 'reselect';
import displayItemsSelectors from 'store/display-items/selectors';
import settingsSelectors from 'store/settings/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import * as DisplayItems from 'domain/settings/display-items';
import { communicationStatus } from 'services/utils';
import { createHeadersByDisplayItems } from 'views/organism/GridTable/GridTableService';
import { convertArray2Object } from 'domain/utils';
import {
  prepareFiltersForUI,
  createLayers,
  getPatternDisplay,
} from 'services/log/LogService';
import { TAB_ALL } from 'services/consts';
import {
  ROUTE_SETTING_FIELDS,
  ROUTE_SETTING_DEFAULT,
  PATTERN_DISPLAY_TOTURIAL,
} from 'domain/log/route-analyze/consts';

const { LOADING } = communicationStatus;

const { DISPLAY_ITEM, INFLOW_MODE } = ROUTE_SETTING_FIELDS;

const GROUP_ITEMS = [
  DisplayItems.PAGE_TITLE,
  DisplayItems.PAGE_ID,
  DisplayItems.CONTENT_CATEGORY,
];

const checkStayingPage = (state) =>
  state.cache.logRouteAnalyze.settings.isStayingPage;
const getDisplayItem = (state) =>
  state.cache.logRouteAnalyze.settings.displayItem;
const getInflowMode = (state) =>
  state.cache.logRouteAnalyze.settings.inflowMode;
const getFilters = (state) => state.cache.logRouteAnalyze.settings.filters;
const getViewId = (state) => state.cache.logRouteAnalyze.settings.viewId;
const getPaging = (state) => state.cache.logRouteAnalyze.settings.paging;
const checkDisplayToturial = (state) =>
  state.cache.logRouteAnalyze.settings.isDisplayToturial;
const getStatusData = (state) => state.cache.logRouteAnalyze.status.data;
const getStatusSettings = (state) =>
  state.cache.logRouteAnalyze.status.settings;
const getDataDetail = (state) => state.cache.logRouteAnalyze.data.detail;
const getMetadataTotal = (state) =>
  state.cache.logRouteAnalyze.data.metadata.count || 0;
const getCntTotal = (state) =>
  state.cache.logRouteAnalyze.data.metadata.cnt_total || 0;

const getMasterdataDisplay = (state) =>
  state.cache.logRouteAnalyze.masterdata.display;
const getMasterdataMedia = (state) =>
  state.cache.logRouteAnalyze.masterdata.media;
const getMasterdataAdGroup1 = (state) =>
  state.cache.logRouteAnalyze.masterdata.adGroup1;
const getMasterdataAdGroup2 = (state) =>
  state.cache.logRouteAnalyze.masterdata.adGroup2;
const getMasterdataPage = (state) =>
  state.cache.logRouteAnalyze.masterdata.page;

const checkAlreadySetting = createSelector([getViewId], (viewId) => {
  return !!viewId;
});

const getMasterdataDefault = createSelector(
  [
    getMasterdataDisplay,
    getMasterdataMedia,
    getMasterdataAdGroup1,
    getMasterdataAdGroup2,
    getMasterdataPage,
  ],
  (display, media, adGroup1, adGroup2, page) => {
    return {
      display,
      media: { options: media.data },
      adGroup1: { options: adGroup1.data },
      adGroup2: { options: adGroup2.data },
      page: { options: page.data },
    };
  }
);

const getMasterdata = createSelector(
  [
    getMasterdataDefault,
    getMasterdataMedia,
    getMasterdataAdGroup1,
    getMasterdataAdGroup2,
    getMasterdataPage,
  ],
  (masterdata, media, adGroup1, adGroup2, page) => {
    return {
      ...masterdata,
      media: {
        loading: media.status === LOADING,
        options: media.isSearching ? media.search : media.data,
      },
      adGroup1: {
        loading: adGroup1.status === LOADING,
        options: adGroup1.isSearching ? adGroup1.search : adGroup1.data,
      },
      adGroup2: {
        loading: adGroup2.status === LOADING,
        options: adGroup2.isSearching ? adGroup2.search : adGroup2.data,
      },
      page: {
        loading: page.status === LOADING,
        options: page.isSearching ? page.search : page.data,
      },
    };
  }
);

const getSettings = createSelector(
  [
    checkAlreadySetting,
    getInflowMode,
    getDisplayItem,
    getFilters,
    getMasterdataDefault,
  ],
  (isAlreadySetting, inflowMode, displayItem, filters, masterdata) => {
    const settings = {
      ...ROUTE_SETTING_DEFAULT,
      ...prepareFiltersForUI(filters, masterdata),
    };

    if (!isAlreadySetting) return settings;

    return {
      ...settings,
      [INFLOW_MODE]: inflowMode,
      [DISPLAY_ITEM]: displayItem,
    };
  }
);

const getPattern = createSelector(
  [checkAlreadySetting, checkDisplayToturial, getSettings],
  (isAlreadySetting, isDisplayToturial, settings) => {
    if (!isAlreadySetting || isDisplayToturial) return PATTERN_DISPLAY_TOTURIAL;

    return getPatternDisplay(settings);
  }
);

const layersSelector = createSelector(
  [getSettings, getPattern],
  (settings, pattern) => createLayers(settings, pattern)
);

const displayItemsSelector = createSelector(
  [displayItemsSelectors.getItemsReport, layersSelector],
  (items, layers) => {
    return items.reduce((acc, item) => {
      if (item.key === DisplayItems.PAGE_URL) return acc;
      if (!GROUP_ITEMS.includes(item.key)) return [...acc, item];

      const layerItems = layers.map(({ field }) => ({
        ...item,
        title: item.titleTable || item.title,
        key: `${field}_${item.key}`,
      }));

      return [...acc, ...layerItems];
    }, []);
  }
);

const metricsSelector = createSelector([getDisplayItem], (displayItem) => {
  return Object.entries(displayItem)
    .filter(([, value]) => value)
    .map(([key]) => key);
});

const headerSelector = createSelector(
  [displayItemsSelector, metricsSelector, layersSelector],
  (displayItems, items, layers) => {
    const metrics = items.reduce(
      (acc, key) => {
        if (key === DisplayItems.PAGE_URL) return acc;
        if (!GROUP_ITEMS.includes(key)) return [...acc, key];

        const newItems = layers.map(({ field }) => `${field}_${key}`);
        return [...acc, ...newItems];
      },
      [DisplayItems.RANK]
    );

    const routeGroup = {
      field: 'route',
      name: '経路',
      children: [DisplayItems.RANK, DisplayItems.RATE, DisplayItems.CNT],
    };

    const groups = layers.reduce(
      (acc, { field, name }) => {
        const children = [
          `${field}_${DisplayItems.PAGE_TITLE}`,
          `${field}_${DisplayItems.PAGE_ID}`,
          `${field}_${DisplayItems.CONTENT_CATEGORY}`,
        ];
        return [...acc, { field, name, children }];
      },
      [routeGroup]
    );

    const headers = createHeadersByDisplayItems({
      currentTab: TAB_ALL,
      displayItems,
      metrics,
      groups,
    });

    return headers;
  }
);

const getStatesForTable = createSelector(
  [
    settingsSelectors.getLoadingByStatus([getStatusData]),
    headerSelector,
    getDataDetail,
    getMetadataTotal,
    getCntTotal,
    getPaging,
    masterDataSelectors.conversionsForDisplaySelector,
  ],
  (isLoading, headers, rows, totalItems, cntTotal, paging, conversions) => {
    return {
      isLoading,
      headers,
      rows,
      totalItems,
      cntTotal,
      paging,
      conversions: convertArray2Object(conversions, DisplayItems.PAGE_ID),
    };
  }
);

const getStatesForRoute = createSelector(
  [
    settingsSelectors.getLoadingByStatus([getStatusData]),
    getPattern,
    layersSelector,
    getDataDetail,
    getDisplayItem,
  ],
  (isLoading, pattern, layers, rows, displayItem) => {
    return {
      isLoading,
      pattern,
      layers,
      rows,
      displayItem,
    };
  }
);

export default {
  getViewId,
  checkAlreadySetting,
  getSettings,
  getStatesForTable,
  getStatesForRoute,
  getPaging,
  metricsSelector,
  getFilters,
  getPattern,
  getInflowMode,
  getMasterdata,
  getStatusSettings,
  getStatusData,
  getMetadataTotal,
  getDisplayItem,
  checkStayingPage,
  getDataDetail,
};
