import React from 'react';
import { string, number, oneOfType, bool, object, func } from 'prop-types';
import { formatValueForTable } from 'domain/utils';
import {
  CVR_FIELD_PREFIX,
  CV_FIELD_PREFIX,
  EBIS_SOURCE_ITEMS,
  METRICS_VIEW_HIDE,
  SYNC_SOURCE_ITEMS,
} from 'domain/consts';

const CellFormatter = ({
  value,
  metric,
  showZeros,
  useTranslation,
  syncViewPattern,
  ebisViewPattern,
  isIgnoneFormat,
}) => {
  let formattedValue = isIgnoneFormat
    ? value
    : formatValueForTable(value, metric, {
        showZeros,
        useTranslation,
      });
  const zeroValues = ['0', '', '0.00', '0.0', null, undefined];
  if (SYNC_SOURCE_ITEMS.includes(metric)) {
    if (syncViewPattern === METRICS_VIEW_HIDE && zeroValues.includes(value)) {
      formattedValue = '-';
    }
  }
  if (
    EBIS_SOURCE_ITEMS.includes(metric) ||
    metric.startsWith(CV_FIELD_PREFIX) ||
    metric.startsWith(CVR_FIELD_PREFIX)
  ) {
    if (ebisViewPattern === METRICS_VIEW_HIDE && zeroValues.includes(value)) {
      formattedValue = '-';
    }
  }

  return <>{formattedValue}</>;
};

CellFormatter.propTypes = {
  value: oneOfType([string, number]),
  metric: string.isRequired,
  showZeros: bool,
  useTranslation: oneOfType([bool, object, func]),
  syncViewPattern: string,
  ebisViewPattern: string,
  isIgnoneFormat: bool,
};

CellFormatter.defaultProps = {
  value: null,
  showZeros: false,
  useTranslation: false,
  syncViewPattern: '0',
  ebisViewPattern: '0',
  isIgnoneFormat: false,
};

export default React.memo(CellFormatter);
