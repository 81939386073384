import helper from 'store/helper';
import { DASHBOARD } from '../../services/routes/constants';
import { TAB_AD } from './constant';
import types from './types';
import { getPageByPath } from '../../services/routes/page-helpers';

/**
 * Get The current screen id from url path on initial load.
 * Default to dashboard if path not found.
 * This prevents unnecessary initial page change events from occurring.
 * @type {{page: (*|string)}}
 */
export const initialState = {
  tab: TAB_AD,
  page: getPageByPath(window.location.pathname)?.screenId ?? DASHBOARD,
};

const changeTab = (state, action) => {
  const { tab } = action.payload;
  return {
    ...state,
    tab,
  };
};

const changePage = (state, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page,
  };
};

const SettingsForViewReducer = helper.createReducer(initialState, {
  [types.CHANGE_TAB]: changeTab,
  [types.CHANGE_PAGE]: changePage,
});

export default SettingsForViewReducer;
