/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import IconDropdown from 'views/atoms/dropdown/IconDropdown';
import NotificationItem from './NotificationItem';

import 'views/molecules/notification/notifications.scss';

/**
 * Component to show notifications for Ebis Front Page
 *
 * notificationList: array of data
 * newNotifications: array of indices created from notificationList (unread only)
 * updateNotification: function to update read notification
 */
const Notification = ({
  notificationList,
  newNotifications,
  // eslint-disable-next-line no-unused-vars
  updateNotification,
  addToNewsModal,
  newsModalState,
}) => {
  const [isActiveDropdown, toggleActive] = useState(false);
  // State for red dot on icon
  const [isReadAll, toggleIsReadAll] = useState(false);
  // State for current open item
  // eslint-disable-next-line no-unused-vars
  const [currentOpenId, changeOpenId] = useState(null);

  // INFO: change request to display in modal
  // const handleOnClickNews = useCallback(
  //   (id) => {
  //     changeOpenId((currentid) =>
  //       id !== currentid ? changeOpenId(id) : changeOpenId(null)
  //     );

  //     // If not read -> click -> update to read
  //     if (id !== null && newNotifications.includes(id)) updateNotification(id);
  //   },
  //   [newNotifications, updateNotification]
  // );

  // Decide to show red dot on icon or not
  useEffect(() => {
    if (newNotifications && newNotifications.length === 0) {
      toggleIsReadAll(true);
    } else {
      toggleIsReadAll(false);
    }
  }, [newNotifications]);

  // INFO: change request to display in modal
  // When close Notification, collapse content with currentOpenId
  // useEffect(() => {
  //   if (isActiveDropdown === false) changeOpenId(null);
  // }, [isActiveDropdown]);

  // Hack: don't close dropdown when modal is still open
  const [cancelCloseModalEvent, toggleCloseModalEvent] = useState(false);

  useEffect(() => {
    if (cancelCloseModalEvent !== newsModalState)
      toggleCloseModalEvent(newsModalState);
  }, [newsModalState, cancelCloseModalEvent]);

  const handleToggle = (isShow) => {
    if (cancelCloseModalEvent) return;

    toggleActive(isShow);
  };

  const handleOnClickNews = useCallback(
    (id) => {
      addToNewsModal(id);
    },
    [addToNewsModal]
  );

  return (
    <Dropdown
      bsPrefix="dropdown"
      className="navbar-notification"
      show={isActiveDropdown}
      onToggle={(isShow) => handleToggle(isShow)}
    >
      <Dropdown.Toggle as={IconDropdown}>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip className="tooltip-common">お知らせ</Tooltip>}
        >
          <div className="d-flex justify-content-center">
            <span
              className={`icon-svg icon-notification icon-svg__w36 ${
                !isReadAll ? 'new-status' : ''
              }`}
            />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu
        alignRight="true"
        className="dropdown-menu-shadow notification__drawer navbar--border-top-red"
      >
        <div className="d-flex justify-content-center flex-column">
          <div className="notification__header">
            <div>
              お知らせ
              <Button
                variant="link"
                onClick={() => toggleActive(!isActiveDropdown)}
              >
                <i className="fal fa-times" />
              </Button>
            </div>
          </div>
          <div className="notification__content-box">
            {
              // Only load contents when Notification is open
              isActiveDropdown &&
                Object.keys(notificationList).length !== 0 &&
                notificationList.map(
                  ({ id, title, detail, date_from: date }) => (
                    <NotificationItem
                      key={id}
                      handleOnClickNews={handleOnClickNews}
                      id={id}
                      title={title}
                      detail={detail}
                      date={date}
                      isNew={newNotifications.includes(id)}
                      isExpanded={id === currentOpenId}
                    />
                  )
                )
            }
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

Notification.defaultProps = {
  newNotifications: [],
  updateNotification: () => {},
  addToNewsModal: () => {},
};

Notification.propTypes = {
  notificationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      date_from: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      detail: PropTypes.any.isRequired,
      target_type: PropTypes.number.isRequired,
      display_type: PropTypes.number.isRequired,
      priority: PropTypes.number.isRequired,
      read: PropTypes.bool.isRequired,
    })
  ).isRequired,
  newNotifications: PropTypes.arrayOf(PropTypes.number),
  updateNotification: PropTypes.func,
  addToNewsModal: PropTypes.func,
  newsModalState: PropTypes.bool.isRequired,
};

export default React.memo(Notification);
