import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  notificationsActions as actions,
  notificationsSelectors as selectors,
  notificationsTypes as types,
} from 'store/notifications';
import NotificationsApi from 'services/api/common/NotificationsApi';

import loggerConstants from 'store/logger/constant';

function* startFetchData() {
  try {
    const { data } = yield call(NotificationsApi.getNotificationsList);

    if (data.data.length > 0) {
      const isNotReadArrId = data.data
        .map(({ id, read }) => !read && id)
        .filter((item) => item);

      const newsData = [...data.data];

      // Display modal immidiately after login
      // Find latest display_notification_type = 0 and unread news
      const modalNewsData = newsData?.find(
        (news) => news.display_notification_type === 0 && !news.read
      )?.id;

      // Add data into state
      yield put(
        actions.fetchNotificationsSuccess(
          data.data,
          isNotReadArrId
          // modalNewsData
        )
      );

      if (modalNewsData) {
        // Add data into modal
        yield put(actions.addNotificationModal(modalNewsData));

        // Mark first time show modal is true
        yield put(actions.changeNewsModalStatus(true));
      }

      // if (!modalNewsData?.read) yield put(actions.toggleNewsModal());
    }
  } catch (error) {
    yield put(
      actions.fetchNotificationsError({
        error: error.response || error,
        scope: loggerConstants.SCOPE_DONT_SHOW,
      })
    );
  }
}

function* updateReadNotification(args) {
  const { id } = args.payload;
  // Error not needed to handle here since not important
  // eslint-disable-next-line no-unused-vars
  const response = yield call(NotificationsApi.putNotificationById, id);
}

export default function* notificationsTimeSaga() {
  yield takeLatest(types.FETCH_NOTIFICATIONS_START, startFetchData);
  yield takeLatest(types.UPDATE_NOTIFICATION_READ, updateReadNotification);
}
