const MGROUP_AGENCY_EXIST =
  '選択された{label}に対する閲覧・編集権限がない代理店が指定されています。権限のある代理店を指定するか、代理店に{label}の閲覧・編集権限を設定してください';
const MGROUP_IDS_EXIST =
  '選択された{label}はすでに削除されています。別の{label}を選択してください';
const NAME_EXIST = '利用されている{label}です。別の{label}を入力してください';
const EXIST_CHECK =
  '選択された{label}はすでに削除されています。<br/>別の{label}を選択してください';
const LIMIT_REGISTER_CHECK =
  '{label}の登録上限数({index.0}件)を超えてしまうため、登録することができません';

export const EMPTY_CHECK = '{label}を入力してください';
export const SELECT_EMPTY_CHECK = 'お問い合わせ{label}を選択してください。';
export const SPTAB_CHECK =
  '{label}をスペース、タブ、改行のみで登録することはできません';
export const CONTROL_CHARACTER_CHECK =
  '{label}に制御文字を含めて登録することはできません。制御文字を削除してください';
export const FILE_NAME_CHECK =
  '{label}に、半角英数字・ハイフン・アンダーバー・ドット以外は使用できません';
export const NUM_CHECK = '{label}は半角英数の整数で入力してください';
export const URL_CHECK =
  '{label}に不正な情報が入力されています。入力内容をご確認ください';
export const MAX_LENGTH_CHECK = '{index.0}文字以内で入力してください';
export const MAX_LENGTH_CHECK_CONTACT =
  '{label}は{index}字以内で入力してください。';
export const NO_TAB_CHECK = '{label}にタブは含めないで下さい';
export const NO_LINE_BREAK_CHECK = '{label}に改行は含めないで下さい';
export const MEDIA_IDS_EXIST_CHECK = MGROUP_IDS_EXIST;
export const AD_GROUP1_IDS_EXIST_CHECK = MGROUP_IDS_EXIST;
export const AD_GROUP2_IDS_EXIST_CHECK = MGROUP_IDS_EXIST;
export const AD_NOT_EXIST_CHECK =
  '選択された広告は既に削除されています。画面を再読み込みし、最新の状態をご確認ください。';
// Agency screen
export const DELETE_AGENCY_NOT_LINK_USER_CHECK =
  'ユーザーが設定されている代理店は削除できません。<br/>ユーザーを削除するか、担当代理店を変更してから削除してください。';
export const AGENCY_LIMIT_CHECK = LIMIT_REGISTER_CHECK;
export const AGENCY_NAME_UNIQUE_CHECK = NAME_EXIST;
export const AGENCY_ID_EXIST_CHECK =
  '選択された代理店はすでに削除されています。<br/>別の代理店を選択してください';
// Media/Adgroup
export const AD_GROUP1_NAME_EXIST_CHECK = NAME_EXIST;
export const AD_GROUP1_AGENCY_EXIST_CHECK = MGROUP_AGENCY_EXIST;
export const AD_GROUP2_NAME_EXIST_CHECK = NAME_EXIST;
export const AD_GROUP2_AGENCY_EXIST_CHECK = MGROUP_AGENCY_EXIST;
export const MEDIA_NAME_EXIST_CHECK = NAME_EXIST;
export const MEDIA_AGENCY_EXIST_CHECK = MGROUP_AGENCY_EXIST;
export const MAX_RANK_CHECK = '並び順は{index.0}字以下で入力してください';
export const LIMIT_CHECK =
  '{label}に登録上限数{index.0}件を超えてしまうため、登録できません';
// User management Screen
export const NOT_EMPTY =
  '{label}が入力されていません。{label}は入力必須項目です。';
export const ACCEPTED_VALUE =
  '代理店の変更はできません。変更したい場合は一度ユーザを削除してから作成しなおしてください。';
// AD settings
export const UNREGISTER_PARENT_AD_ID =
  'デバイス別計測の親広告IDとして使用されているため、削除できませんでした。<br/>親広告IDとそれに紐付く子広告IDをすべて同時に削除するか、親広告IDに紐付く子広告IDをすべて削除してから、再度削除を実行してください。';
export const AD_NOT_IN_AGENCY_CHECK =
  'こちらの広告を{action}する権限がありません。アカウント管理権限があるユーザーにご確認ください。'; // action: 編集 / 削除 / 再登録
export const NOT_EXISTS_DB =
  '未登録または削除済みの集計条件が入力されています。';
export const VALUE_IS_EXISTS =
  '利用されている商品名です。別の商品名を入力してください。';
// Tag Mangement screen
export const MIN_ITEM_CHECK =
  'URL指定の場合はコンバージョンページを1件以上ご入力ください';
export const MAX_ITEM_CHECK = '{label}は{max}件以内で登録してください';
export const PAGE_ID_EXIST_CHECK = EXIST_CHECK;
export const PAGE_ID_LIMIT_REGISTER_CHECK = LIMIT_REGISTER_CHECK;
export const PAGE_ID_IS_NOT_CONVERSION_CHECK = EXIST_CHECK;
export const PAGE_ID_CONTAIN_0_CHECK = 'ページID「0」は登録できません';
export const PAGE_ID_REGISTER_CHECK =
  '{label}に使える文字は半角英数字と「_」「-」のみです';
export const PAGE_ID_UNIQUE_CHECK =
  '利用されている{label}です。別の{label}を入力してください';
export const PAGE_ID_LINKED_POSTBACK_CHECK =
  'ポストバック設定されているページIDが含まれるため削除できません。<br/>管理者権限でポストバック設定を解除してから、ページIDを削除してください。';
export const PAGE_ID_CAPI_CHECK =
  'CAPiCO設定されているページIDが含まれるため削除できません。<br/>管理者権限でCAPiCO設定を解除してから、ページIDを削除してください。';
export const PAGE_ID_LINE_ADD_FRIEND_CHECK =
  'LINE友だち追加計測設定されているページIDが含まれるため削除できません。<br/>管理者権限でLINE友だち追加計測設定を解除してから、ページIDを削除してください。';
export const CONVERSION_ID_EXIST_CHECK = EXIST_CHECK;
export const CONVERSION_ID_LIMIT_REGISTER_CHECK = LIMIT_REGISTER_CHECK;
export const CONTENT_CATEGORY_ID_EXIST_CHECK =
  '{label}IDが存在しません。登録済みの{label}IDを指定してください';
export const CONTENT_CATEGORY_UPLOAD_SAME_TIME =
  '同時に優先順位の並べ替えが実行されたため、正常に処理できませんでした。<br/> 設定した内容を反映するためには、画面を読み込んでいただき、再度、優先順位の並べ替えを行ってください。';
export const CONTENT_CATEGORY_NAME_EXIST_CHECK =
  '{label}が存在しません。登録済みの{label}を指定してください';
export const CONTENT_CATEGORY_NAME_UNIQUE_CHECK = NAME_EXIST;
export const CONTENT_CATEGORY_LIMIT_REGISTER_CHECK = LIMIT_REGISTER_CHECK;
export const CONTENT_CATEGORY_RANK_UNIQUE_CHECK =
  '利用されている{label}です。別の{label}を入力してください';
export const REPORT_ID_EXISTS_CHECK =
  '選択されたレポートはすでに削除されています。<br />[OK]をクリックすることで、最新の状態を読み込みます。';
// will update message after Q&A answered
export const REPORT_RUNNING_CHECK =
  '現在レポート作成中のため、削除できません。';
export const ALLOW_USER_MODIFY_REPORT_CHECK =
  '選択されたレポートを操作する契約または権限がありません。';
export const REPORT_FILE_NAME_CHECK =
  '選択されたレポートはすでに削除されています。';
export const PHONE_CHECK = '電話番号はすべての項目を入力してください。';
export const FILE_SIZE_CHECK = 'の容量が{size}MBを超えています';
export const FILE_EXTENSION_CHECK =
  '{label}で許可されている形式は、{extension}です。';
export const DOMAINS_IN_USE_CHECK =
  '計測対象サイトを変更する前に該当計測用DNS設定を解除してください。';
export const CROSS_DOMAIN_LT1_CHECK = '計測対象サイトを設定してください。';
export const CROSS_DOMAIN_LT2_CHECK =
  '計測対象サイトのドメインは一つしかないのでクロスドメイン設定は不要です。';
export const IP_ADDRESS_CHECK =
  '上から {index}番目の{label}を正しく入力してください。';
export const MAX_VALUE_CHECK =
  '{label}は{min}~{max}の半角整数で入力してください。';
export const LINE_VALUE_DUPLICATED =
  '登録された{label}の内容が一部重複しています。';
export const MAX_REGISTER_CHECK = '{label}の登録は、{max}件までとなります。';
export const QUERY_PARAMS_FORMAT_CHECK =
  '{label}に使える文字は半角英数字と｢_｣｢-｣のみです。';
export const DIRECTORY_INDEX_FORMAT_CHECK =
  '{label}に使える文字は半角英数小文字と｢.｣｢_｣｢-｣のみです。';
// System Setting - Display tab
export const LABEL_TAG_CHECK = '{label}に許可されていないタグが含まれています';
export const DUPLICATE_VALUE_CHECK =
  '登録された{label}は他の「ラベル名」と重複しているため変更してください';
export const BLACKLIST_VALUE_CHECK =
  '登録された{label}は他の「表示項目」で利用されているため変更してください';
export const PAGE_REGISTED_BY_CONV =
  'コンバージョン計測が設定されているため、サイトコンテンツ計測対象を設定できません。';
export const PAGE_ID_REGIST_AUTO_PAGE_PREFIX =
  '{label}に使える文字は「#」と半角数値のみです。';
export const PAGE_ID_REGIST_AUTO_PAGE_OVER = '#0 は指定できません。';
export const ERROR_LINE_MAIN_MESSAGE = `{label}登録エラーが{length}件あります。対応方法を参考に、修正してください。`;
export const FQDN_EXIST_CHECK =
  '入力されたドメインはご利用頂けません。別のドメインを入力してください。';
export const MAX_ENTRIES_PER_REGIST_CHECK =
  '一度に登録ができるドメインの上限は{max}件までです。';
export const GENERIC_ERROR_CHECK =
  '※システムエラーが発生しました。<a href="{link}" target="_blank">こちら</a>をご確認の上、ご不明点があればエビス・サポートセンターにお問い合わせください。';
export const MAX_REGISTERED_DOMAINS_CHECK =
  '400件までの計測対象サイトに対してDNS登録が可能です。';
export const ELEMENT_ENCODING_CHECK = `使用できない文字（特殊な記号や常用外漢字など）が含まれています`;
export const ADVERTISER_ID_UNIQUE_CHECK =
  'この広告アカウントIDは登録済みです。';
export const LINE_PASSWORD_INVALID = 'パスワードが誤っています。';

// NS/CNAME Setting
export const ETLD1_VALID_CHECK =
  '選択された{label}を操作する権限がありません。';
export const FORMAT_CHECK =
  '{label}の形式が不正です。';
export const REQUIRED_CHECK =
  '{label}を入力してください';
export const TYPE_CHECK =
  '{label}の形式が不正です。';
export const ELEMENT_TYPE_CHECK =
  '{label}の形式が不正です。';

// CAPI Setting
export const SETTING_ID_EXIST_CHECK =
  '編集中の[CAPiCO設定]は既に削除されています。画面を再読み込みし、最新の状態をご確認ください。';
export const USER_PERMISSION_CHECK =
  '選択された詳細設定を操作する権限がありません。';
export const MAX_SETTING_ALLOW_REGIST_CHECK =
  '[CAPiCO設定]は100件以内で登録してください。';
export const ACCEPT_VALUE_CHECK = '{label}を正しく選択してください。';
export const INVALID_PIXEL_ID =
  '存在しないピクセルIDです。正しいピクセルIDを入力してください。';
export const PIXEL_ID_EXIST_CHECK =
  'このピクセルIDはすでに設定されています。別のピクセルIDを入力してください。';
export const INVALID_ACCESS_TOKEN =
  'ピクセルIDまたはアクセストークンに誤りがあります。正しいピクセルIDとアクセストークンを入力してください。';
export const VALID_SEND_MAIL_USERS_CHECK =
  '選択されたユーザーは既に削除されています。画面を再読み込みし、最新の状態をご確認ください。';
export const ACCEPTED_CONVERSIONID_CHECK = 'ACCEPTED_CONVERSIONID_CHECK';
export const DUPLICATE_CV_POINT_CHECK = 'DUPLICATE_CV_POINT_CHECK';
export const INVALID_LINE_TAG_ID =
  '存在しないline_tag_idです。正しいline_tag_idを入力してください。';
export const INVALID_LINE_TAG_ACCESS_TOKEN =
  '存在しないX-Line-TagAccessTokenです。正しいX-Line-TagAccessTokenを入力してください。';
export const LINE_TAG_ID_EXIST_CHECK =
  'このline_tag_idはすでに設定されています。別のline_tag_idを入力してください。';
export const INVALID_CUSTOMER_ID =
  '存在しないcustomerIdです。正しいcustomerIdを入力してください。';
export const USER_PERMISSION_DENIED =
  '認証されたGoogleアカウントは入力されているcustomerIdへのアクセス権限がありません。再度認証してください。';
export const CUSTOMER_ID_EXIST_CHECK =
  'このcustomerIdはすでに設定されています。別のcustomerIdを入力してください。';
export const NOT_CHECKED_PERSONAL_DATA_CHECKBOX =
  '個人情報の取り扱いについて同意をお願いします。';
export const SITE_URL_EXIST_CHECK =
  'この{label}はすでに設定されています。別の{label}を入力してください。';
export const INVALID_ACCOUNT_ID =
  '存在しないaccount_idです。正しいaccount_idを入力してください。';
export const ACCOUNT_ID_EXIST_CHECK =
  'このaccount_idはすでに設定されています。別のaccount_idを入力してください。';
export const YAHOO_YDN_CONV_IO_EXIST_CHECK =
  'このyahoo_ydn_conv_ioはすでに設定されています。別のyahoo_ydn_conv_ioを入力してください。';
export const INVALID_PIXEL_CODE =
  'ピクセルIDに誤りがあります。入力内容をご確認ください。';
export const INVALID_TIKTOK_ACCESS_TOKEN =
  'ピクセルIDまたはアクセストークンに誤りがあります。入力内容をご確認ください。';
export const PIXEL_CODE_EXIST_CHECK =
  'このピクセルIDはすでに設定されています。別のピクセルIDを入力してください。';

// Line Add Friend Setting
export const LINE_ADD_FRIEND_SETTING_ID_EXIST_CHECK =
  '選択されたLineAddFriend設定はすでに削除されています。別のLineAddFriend設定を選択してください。';
export const LIFF_ID_ERROR =
  '※LINEログインに紐づいていないLIFF IDが入力されています。正しいLIFF IDを入れてください。';
export const LINE_LOGIN_OR_MESSAGING_API_ERROR =
  '※チャネルIDもしくはチャネルシークレットに誤りがあります。正しいチャネルIDとチャネルシークレットを入れてください。';
export const INVALID_LINE_LOGIN_ENDPOINT_URL =
  '※LIFF IDに設定されているエンドポイントURLに誤りがあります。正しいエンドポイントURLを入れてください。';
export const INVALID_LINE_LOGIN_SCOPE =
  '※LIFF IDに設定されているScopeに誤りがあります。openidとprofileにチェックを入れてください。';
export const INVALID_LINE_LOGIN_BOT_PROMPT =
  '※LIFF IDに設定されている友だち追加オプションに誤りがあります。On (Aggressive)にチェックを入れてください。';
export const LINE_LOGIN_CHANNEL_ID_EXIST_CHECK =
  'このチャネルIDはすでに設定されています。別のチャネルIDを入力してください。';
export const LINE_MESSAGING_API_CHANNEL_ID_EXIST_CHECK =
  'このチャネルIDはすでに設定されています。別のチャネルIDを入力してください。';
export const CV_NAME_NOT_EXIST_CHECK =
  '現在設定されているCV名は存在しません。別のCV名を選択してください。';
