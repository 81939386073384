import React from 'react';
import * as FILTERS from 'services/consts';
import {
  REPORT_TYPE as REPORT_TYPE_SETTING,
  CHANNEL_TYPE_ID,
  FILTER_CONTACT_HISTORY_DIRECT,
  FILTER_CONTACT_HISTORY_INDIRECT2,
  FILTER_CONTACT_HISTORY_INDIRECT3,
  FILTER_CONTACT_HISTORY_INDIRECT4,
  FILTER_CONTACT_HISTORY_INDIRECT5,
  FILTER_CONTACT_HISTORY_INDIRECT6,
  FILTER_CONTACT_HISTORY_INDIRECT7,
  FILTER_CONTACT_HISTORY_INDIRECT8,
  FILTER_CONTACT_HISTORY_INDIRECT9,
  FILTER_CONTACT_HISTORY_INDIRECT10,
  FILTER_CONTACT_HISTORY_FIRST,
  PRIORITY_AXIS_EBIS,
} from 'domain/consts';
import { TAB_ALL, TAB_AD } from 'store/settings/constant';
import iconcsv from 'assets/images/icon-csv.png';
import iconspreadsheet from 'assets/images/icon-spreadsheet.png';
import iconadrepo from 'assets/images/icon-adrepo.png';
import {
  CATEGORY_ANALYZE,
  COMPARE_PERIOD,
  CV_ATTRIBUTE,
  DETAILS_ANALYSIS,
} from 'services/routes/constants';

export const REPORT_LIMIT = 0;
// update when user want to apply paginations
export const TABLE_ROWS_PER_PAGE = -1;

// report field
const REPORT_NAME = 'name';
const REPORT_TYPE = 'type';
const REPORT_OUTPUT = 'output';
const REPORT_STATUS = 'status';
const PERIOD_TIME = 'periodTime';
const COMPLETE_DATETIME = 'completeDatetime';
const MODIFY_USER = 'modifyUser';
const MODIFY_DATE = 'modifyDate';
export const EMAIL_INFO = 'emailInfo';
export const VIEW_HISTORY = 'view';

// Result google error
export const INVALID_CREDENTIALS = 1401;
export const NO_PERMISSION = 1403;
export const OVER_MILLION_CELLS = 1413;

// status report key
export const STATUS_TYPE_WAITING = 0;
export const STATUS_TYPE_RUNNING = 2;
export const STATUS_TYPE_SKIP = 4;
export const STATUS_TYPE_DELETE = 7;
export const STATUS_TYPE_COMPLETED = 9;

// status report display
export const STATUS_REPORT_ERROR = 'エラー';
export const STATUS_REPORT_PROCESSING = '処理中';
export const STATUS_REPORT_SUCCESS = '完了';
export const STATUS_REPORT_EMAIL_FAIL = '送信エラー';
export const STATUS_REPORT_EXPIRED = '期限切れ';
export const STATUS_REPORT_ERROR_INVALID_CREDENTIALS = '認証エラー';
export const STATUS_REPORT_ERROR_NO_PERMISSION = '権限エラー';
export const STATUS_REPORT_ERROR_OVER_MILLION_CELLS = '出力エラー';

// Result run batch
export const RESULT_TYPE_SUCCESS = 1;
export const RESULT_TYPE_ERROR_APP = 4;
export const RESULT_TYPE_ERROR_SYSTEM = 9;

// services store
export const SERVICE_STORE_REPORT_GOOGLE_DRIVE = 'google_drive';
export const SERVICE_STORE_REPORT_S3 = 's3';

// Report period
export const STATUS_PERIOD_MODE_NORMAL = 1;
export const STATUS_PERIOD_MODE_PERIODIC = 2;

export const WAIT_COST_OFF = 0;
export const WAIT_COST_ON = 1;

// Report period interval
export const STATUS_PERIOD_INTERVAL_DAY = 1;
export const STATUS_PERIOD_INTERVAL_WEEK = 2;
export const STATUS_PERIOD_INTERVAL_MONTH = 3;

export const PERIOD_INTERVAL_DAY = '昨日';
export const PERIOD_INTERVAL_WEEK = '先週';
export const PERIOD_INTERVAL_MONTH = '先月';

// report channels
export const CHANNEL_TYPE_ALL = TAB_ALL;
export const CHANNEL_TYPE_AD = TAB_AD;

export const PERIOD_MONDAY_KEY = 1;
export const PERIOD_SUNDAY_KEY = 7;

export const NO_EXIST_EMAIL = -1;

// report types for data export
export const REPORT_TYPE_CHANNEL = REPORT_TYPE_SETTING.CHANNEL;
export const REPORT_TYPE_ACCESS = REPORT_TYPE_SETTING.ACCESS;
export const REPORT_TYPE_CV_ATTRIBUTE = REPORT_TYPE_SETTING.CVATTR;
// report types for regular report
export const REPORT_TYPE_CATEGORY = REPORT_TYPE_SETTING.CATEGORY;
export const REPORT_TYPE_DETAIL = REPORT_TYPE_SETTING.DETAIL;
export const REPORT_TYPE_PERIOD = REPORT_TYPE_SETTING.PERIOD;
export const REPORT_TYPE_CONVERSION = REPORT_TYPE_SETTING.CONVERSION;
export const REPORT_TYPE_CATEGORY_PERIOD = REPORT_TYPE_SETTING.CATEGORY_PERIOD;
export const REPORT_TYPE_DETAIL_PERIOD = REPORT_TYPE_SETTING.DETAIL_PERIOD;
// report type for user have contract ad repo
export const REPORT_TYPE_AD_REPO = REPORT_TYPE_SETTING.ADREPO;

// Time axis
export const TIME_AXIS_DAILY = 'daily';
export const TIME_AXIS_MONTHLY = 'monthly';
export const TIME_AXIS_DAY_OF_WEEK = 'day_of_week';
export const TIME_AXIS_HOURLY = 'hourly';

// Group displayitem
export const DISPLAYITEM_GROUP_CHANNEL = 'channel';
export const DISPLAYITEM_GROUP_TIME = 'time';
export const DISPLAYITEM_GROUP_CONVERSION = 'conversion';
export const DISPLAYITEM_GROUP_SITE = 'site';
export const DISPLAYITEM_GROUP_CV_ATTRIBUTE = 'cv_attr';
export const DISPLAYITEM_FIXED = {
  [REPORT_TYPE_CV_ATTRIBUTE]: ['conversion_title'],
};

// service store
export const SERVICE_STORE_S3 = 's3';
export const SERVICE_STORE_GOOGLE_DRIVE = 'google_drive';
export const GOOGLE_DRIVE_DIR_PATH = '/adebis';

export const EMAIL_TITLE_MODE_DEFAULT = 1;
export const EMAIL_TITLE_MODE_CUSTOM = 2;

export const MIN_DIMENSION = 1;
export const MAX_DIMENSION = 10;

export const MIN_METRIC = 1;
export const MAX_METRIC = 15;

export const MAX_LENGTH_REPORT_NAME = 30;
export const MAX_LENGTH_EMAIL_TITLE = 1000;
export const MAX_LENGTH_GOOGLE_DIR_PATH = 10000;
export const MAX_LENGTH_CUSTOM_FILENAME = 50;

export const WEEKDAY_MONDAY = 1;

export const WEEK_DAYS = {
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
  7: '日',
};

export const PERIOD_INTERVALS = {
  [STATUS_PERIOD_INTERVAL_DAY]: '日別',
  [STATUS_PERIOD_INTERVAL_WEEK]: '週別',
  [STATUS_PERIOD_INTERVAL_MONTH]: '月別',
};

export const SERVICE_STORES = {
  [SERVICE_STORE_S3]: {
    label: 'CSV',
    value: 's3',
    icon: iconcsv,
    disabled: false,
  },
  [SERVICE_STORE_GOOGLE_DRIVE]: {
    label: 'Googleスプレッドシート',
    value: 'google_drive',
    icon: iconspreadsheet,
    disabled: false,
  },
  [REPORT_TYPE_AD_REPO]: {
    label: 'アドレポ連携用',
    value: 'neo',
    icon: iconadrepo,
    disabled: false,
  },
};

// label group displayitem
export const DIMENSION_GROUP_LABEL = {
  [DISPLAYITEM_GROUP_CHANNEL]: 'チャネル軸',
  [DISPLAYITEM_GROUP_TIME]: '時間軸',
  [DISPLAYITEM_GROUP_CONVERSION]: 'コンバージョン軸',
  [DISPLAYITEM_GROUP_SITE]: 'サイト軸',
};

export const METRIC_GROUP_LABEL = {
  [DISPLAYITEM_GROUP_CHANNEL]: 'チャネル別流入関連',
  [DISPLAYITEM_GROUP_CONVERSION]: 'コンバージョン関連',
  [DISPLAYITEM_GROUP_SITE]: 'サイト内アクセス関連',
};

// labels or report type
export const REPORT_TYPES = {
  [REPORT_TYPE_CHANNEL]: 'チャネル',
  [REPORT_TYPE_ACCESS]: 'アクセス解析',
  [REPORT_TYPE_CV_ATTRIBUTE]: 'コンバージョン属性',
  [REPORT_TYPE_CATEGORY]: 'カテゴリ分析レポート',
  [REPORT_TYPE_DETAIL]: '詳細分析レポート',
  [REPORT_TYPE_PERIOD]: '期間分析レポート',
  [REPORT_TYPE_CONVERSION]: 'コンバージョン属性レポート', // will be change after Q&A
  [REPORT_TYPE_CATEGORY_PERIOD]: 'カテゴリ分析×期間分析レポート',
  [REPORT_TYPE_DETAIL_PERIOD]: '詳細分析×期間分析レポート',
  [REPORT_TYPE_AD_REPO]: 'アドレポ連携',
};

export const REPORT_TYPES_REGULAR_REPORT = [
  REPORT_TYPE_CATEGORY,
  REPORT_TYPE_DETAIL,
  REPORT_TYPE_PERIOD,
  REPORT_TYPE_CONVERSION,
  REPORT_TYPE_CATEGORY_PERIOD,
  REPORT_TYPE_DETAIL_PERIOD,
];

export const REPORT_TYPES_DATA_EXPORT = [
  REPORT_TYPE_CHANNEL,
  REPORT_TYPE_CV_ATTRIBUTE,
  REPORT_TYPE_ACCESS,
];

export const PAGE_ID_BY_REPORT_TYPE = {
  [REPORT_TYPE_CATEGORY]: CATEGORY_ANALYZE,
  [REPORT_TYPE_CATEGORY_PERIOD]: CATEGORY_ANALYZE,
  [REPORT_TYPE_DETAIL]: DETAILS_ANALYSIS,
  [REPORT_TYPE_DETAIL_PERIOD]: DETAILS_ANALYSIS,
  [REPORT_TYPE_PERIOD]: COMPARE_PERIOD,
  [REPORT_TYPE_CONVERSION]: CV_ATTRIBUTE,
};

export const ERRORS_SHEET = {
  [INVALID_CREDENTIALS]:
    'Googleアカウントの認証が無効になっている可能性があります。[編集]＞【データエクスポート設定】にてGoogleアカウントの認証を行ってください。',
  [NO_PERMISSION]:
    '設定されたGoogleアカウントの権限に「編集権限」がないため、スプレットシートを作成できませんでした。[編集]＞【データエクスポート設定】にて「編集権限」のあるGoogleアカウントを設定してください。',
  [OVER_MILLION_CELLS]:
    '出力データがGoogleスプレッドシートのセル上限(1000万セル)を超過しました。レポートの出力条件を変更してください。',
};

export const MSG_NO_PERMISSION_SETTING =
  'レポート作成に必要な契約または機能権限がありません。';

export const HEADER_LIST_REPORT = [
  {
    field: 'rowId',
    name: '',
    type: 'checkbox',
    isFreeze: true,
    isEdit: true,
  },
  {
    field: REPORT_NAME,
    name: 'レポート名',
    isFreeze: true,
  },
  {
    field: REPORT_TYPE,
    name: 'レポートタイプ',
    isFreeze: true,
  },
  {
    field: REPORT_OUTPUT,
    name: '出力先',
    minWidth: '147px',
    isContentCenter: true,
  },
  {
    field: REPORT_STATUS,
    name: 'ステータス',
    isContentCenter: true,
  },
  {
    field: PERIOD_TIME,
    name: '集計期間',
    minWidth: '115px',
    isContentCenter: true,
  },
  {
    field: MODIFY_DATE,
    name: '最終設定更新日時',
    minWidth: '140px',
    isContentCenter: true,
  },
  {
    field: MODIFY_USER,
    name: '更新ユーザーID',
    minWidth: '122px',
    isContentCenter: true,
  },
  {
    field: COMPLETE_DATETIME,
    name: '最終レポート作成日時',
    minWidth: '164px',
    isContentCenter: true,
  },
  {
    field: VIEW_HISTORY,
    name: 'レポート作成履歴',
    isContentCenter: true,
    isViewDetail: true,
    variant: 'btn-link',
  },
];

export const HEADER_VIEW_HISTORY = [
  {
    field: REPORT_NAME,
    name: '生成済レポート名',
    width: '151px',
  },
  {
    field: PERIOD_TIME,
    name: '集計期間',
    width: '115px',
  },
  {
    field: COMPLETE_DATETIME,
    name: 'レポート作成完了時間',
    width: '165px',
  },
  {
    field: EMAIL_INFO,
    name: '通知先',
    width: '212px',
  },
  {
    field: REPORT_STATUS,
    name: 'ステータス',
    width: '100px',
  },
];

export const CONTACT_HISTORIES = [
  { name: 'direct', text: '直接効果' },
  { name: 'indirect_2', text: '間接効果2' },
  { name: 'indirect_3', text: '間接効果3' },
  { name: 'indirect_4', text: '間接効果4' },
  { name: 'indirect_5', text: '間接効果5' },
  { name: 'indirect_6', text: '間接効果6' },
  { name: 'indirect_7', text: '間接効果7' },
  { name: 'indirect_8', text: '間接効果8' },
  { name: 'indirect_9', text: '間接効果9' },
  { name: 'indirect_10', text: '間接効果10' },
  { name: 'first_contract', text: '初回接触' },
];

// report field for setting
export const REPORT_FIELDS = {
  REPORT_NAME: 'name',
  REPORT_TYPE: 'type',
  REPORT_CHANNEL: 'channel',
  SERVICE_STORE: 'service_store',
  DISPLAY_ITEMS: 'display_items',
  DIMENSIONS: 'dimensions',
  METRICS: 'metrics',
  CONTACT_HISTORY: 'contact_history',
  PERIOD: 'period',
  PERIOD_MODE: 'period_mode',
  PERIOD_START: 'period_start',
  PERIOD_END: 'period_end',
  PERIOD_INTERVAL: 'period_interval',
  PERIOD_RECEIVE_REPORT_DAY: 'period_receive_report_day',
  CUSTOM_FILENAME: 'custom_filename',
  WAIT_COST: 'wait_cost',
  DISPLAY: 'display',
  DISPLAY_TOTAL: 'display_total',
  DISPLAY_ROW_ZERO: 'display_row_zero',
  DISPLAY_HEADER: 'display_header',
  DISPLAY_CLICK_LP_URL: 'display_click_lp_url',
  SHARED_USERS: 'shared_users',
  EMAIL: 'email',
  SEND_EMAIL_MODE: 'send_email_mode',
  EMAIL_TITLE_MODE: 'email_title_mode',
  EMAIL_TITLE: 'email_title',
  QUICK_SEARCH: 'quick_search',
  FILTERS: 'filters',
  GOOGLE_ACCOUNT: 'google_account',
  GOOGLE_DIR_PATH: 'google_dir_path',
  GOOGLE_SAVE_MODE: 'google_save_mode',
  RESULT_ERROR_CODE: 'result_error_code',
  SUMMARY_MODE: 'summary_mode',
  AXIS: 'axis',
  SORT: 'sort',
};

export const DEFAULT_DETAIL_REPORT = {
  [REPORT_FIELDS.SERVICE_STORE]: SERVICE_STORE_S3,
  [REPORT_FIELDS.REPORT_TYPE]: REPORT_TYPE_CATEGORY,
  [REPORT_FIELDS.REPORT_CHANNEL]: CHANNEL_TYPE_AD,
  [REPORT_FIELDS.REPORT_NAME]: REPORT_TYPES[REPORT_TYPE_CATEGORY].label,
  [REPORT_FIELDS.DIMENSIONS]: [],
  [REPORT_FIELDS.METRICS]: [],
  [REPORT_FIELDS.CONTACT_HISTORY]: CONTACT_HISTORIES.map((item) => item.name),
  [REPORT_FIELDS.PERIOD_MODE]: STATUS_PERIOD_MODE_NORMAL,
  [REPORT_FIELDS.PERIOD_START]: '',
  [REPORT_FIELDS.PERIOD_END]: '',
  [REPORT_FIELDS.PERIOD_INTERVAL]: STATUS_PERIOD_INTERVAL_DAY,
  [REPORT_FIELDS.PERIOD_RECEIVE_REPORT_DAY]: WEEKDAY_MONDAY,
  [REPORT_FIELDS.CUSTOM_FILENAME]: '',
  [REPORT_FIELDS.WAIT_COST]: WAIT_COST_OFF,
  [REPORT_FIELDS.DISPLAY_TOTAL]: false,
  [REPORT_FIELDS.DISPLAY_ROW_ZERO]: false,
  [REPORT_FIELDS.DISPLAY_HEADER]: false,
  [REPORT_FIELDS.DISPLAY_CLICK_LP_URL]: false,
  [REPORT_FIELDS.GOOGLE_ACCOUNT]: null,
  [REPORT_FIELDS.SHARED_USERS]: [],
  [REPORT_FIELDS.SEND_EMAIL_MODE]: true,
  [REPORT_FIELDS.EMAIL_TITLE_MODE]: EMAIL_TITLE_MODE_DEFAULT,
  [REPORT_FIELDS.EMAIL_TITLE]: '',
  [REPORT_FIELDS.GOOGLE_DIR_PATH]: GOOGLE_DRIVE_DIR_PATH,
  [REPORT_FIELDS.GOOGLE_SAVE_MODE]: 'new',
  [REPORT_FIELDS.QUICK_SEARCH]: {},
  [REPORT_FIELDS.FILTERS]: {},
  [REPORT_FIELDS.DISPLAY_ITEMS]: {},
  [REPORT_FIELDS.SUMMARY_MODE]: TIME_AXIS_DAILY,
  [REPORT_FIELDS.AXIS]: PRIORITY_AXIS_EBIS,
  [REPORT_FIELDS.SORT]: {},
};

const ignoreRegularReportFields = [
  REPORT_FIELDS.REPORT_CHANNEL,
  REPORT_FIELDS.FILTERS,
  REPORT_FIELDS.AXIS,
  REPORT_FIELDS.SORT,
  REPORT_FIELDS.DISPLAY_ITEMS,
  REPORT_FIELDS.SUMMARY_MODE,
  REPORT_FIELDS.CUSTOM_FILENAME,
];

const ignoreDataExportFields = [
  REPORT_FIELDS.DIMENSIONS,
  REPORT_FIELDS.METRICS,
  REPORT_FIELDS.QUICK_SEARCH,
  REPORT_FIELDS.CONTACT_HISTORY,
  REPORT_FIELDS.DISPLAY_CLICK_LP_URL,
];

export const REPORT_TYPES_FOR_SETTING = {
  [REPORT_TYPE_CHANNEL]: {
    label: 'チャネルレポート',
    value: REPORT_TYPE_CHANNEL,
    ignoreFields: [...ignoreRegularReportFields, REPORT_FIELDS.CONTACT_HISTORY],
  },
  [REPORT_TYPE_CV_ATTRIBUTE]: {
    label: 'コンバージョン属性レポート',
    value: REPORT_TYPE_CV_ATTRIBUTE,
    ignoreFields: [
      ...ignoreRegularReportFields,
      REPORT_FIELDS.METRICS,
      REPORT_FIELDS.WAIT_COST,
      REPORT_FIELDS.DISPLAY_TOTAL,
      REPORT_FIELDS.DISPLAY_ROW_ZERO,
    ],
  },
  [REPORT_TYPE_ACCESS]: {
    label: 'アクセス解析レポート',
    value: REPORT_TYPE_ACCESS,
    ignoreFields: [
      ...ignoreRegularReportFields,
      REPORT_FIELDS.CONTACT_HISTORY,
      REPORT_FIELDS.QUICK_SEARCH,
      REPORT_FIELDS.WAIT_COST,
      REPORT_FIELDS.DISPLAY_CLICK_LP_URL,
    ],
  },
  [REPORT_TYPE_CATEGORY]: {
    label: 'カテゴリ分析レポート',
    value: REPORT_TYPE_CATEGORY,
    ignoreFields: [
      ...ignoreDataExportFields,
      REPORT_FIELDS.SUMMARY_MODE,
      REPORT_FIELDS.DISPLAY_ROW_ZERO,
    ],
  },
  [REPORT_TYPE_DETAIL]: {
    label: '詳細分析レポート',
    value: REPORT_TYPE_DETAIL,
    ignoreFields: [
      ...ignoreDataExportFields,
      REPORT_FIELDS.SUMMARY_MODE,
      REPORT_FIELDS.DISPLAY_ROW_ZERO,
    ],
  },
  [REPORT_TYPE_PERIOD]: {
    label: '期間分析レポート',
    value: REPORT_TYPE_PERIOD,
    ignoreFields: [...ignoreDataExportFields],
  },
  [REPORT_TYPE_CONVERSION]: {
    label: 'コンバージョン属性レポート',
    value: REPORT_TYPE_CONVERSION,
    ignoreFields: [
      ...ignoreDataExportFields,
      REPORT_FIELDS.WAIT_COST,
      REPORT_FIELDS.SUMMARY_MODE,
      REPORT_FIELDS.DISPLAY_TOTAL,
      REPORT_FIELDS.DISPLAY_ROW_ZERO,
    ],
  },
  [REPORT_TYPE_CATEGORY_PERIOD]: {
    label: 'カテゴリ分析×期間分析レポート',
    value: REPORT_TYPE_CATEGORY_PERIOD,
    ignoreFields: [...ignoreDataExportFields],
  },
  [REPORT_TYPE_DETAIL_PERIOD]: {
    label: '詳細分析×期間分析レポート',
    value: REPORT_TYPE_DETAIL_PERIOD,
    ignoreFields: [...ignoreDataExportFields],
  },
};

export const REPORT_CHANNELS = [
  {
    label: '広告のみ',
    value: TAB_AD,
  },
  {
    label: '全トラフィック',
    value: TAB_ALL,
  },
];

export const HEADER_USER = [
  {
    isFreeze: true,
    field: 'rowId',
    name: '',
    sort: 'none',
    width: '45px',
    type: 'checkbox',
  },
  {
    isFreeze: true,
    field: 'user_id',
    name: 'ユーザーID',
    width: 'max-content',
    minWidth: '150px',
  },
  {
    field: 'company_name',
    name: '会社名',
    width: '201px',
  },
  {
    field: 'charge_name',
    name: '名前',
    width: '121px',
  },
  {
    field: 'email',
    name: 'メールアドレス',
    width: 'max-content',
    minWidth: '231px',
  },
  {
    field: 'roles',
    name: '閲覧',
    children: [
      {
        field: 'view_all',
        name: '全トラフィック',
        width: '122px',
        variant: 'icon-check',
      },
      {
        field: 'view_ad',
        name: '広告',
        width: '57px',
        variant: 'icon-check',
      },
    ],
  },
  {
    field: 'agent',
    name: 'エージェント',
    width: '109px',
    variant: 'icon-check',
  },
];

// Mapping key filter between new (key) & old (value) system
export const FILTER_KEY_MAPPER = {
  amount_min: 'amount_from',
  amount_max: 'amount_to',
  ad_date_min: 'ad_date_from',
  ad_date_max: 'ad_date_to',
  [FILTERS.FILTER_KEY_CHANNELS]: 'channel_access_type',
  [FILTERS.FILTER_KEY_CV]: 'conversion_ids',
  [FILTERS.FILTER_KEY_MEMBER_NAME_EXCLUDE]: 'member_name',
  [FILTERS.FILTER_KEY_OTHER1_EXCLUDE]: 'other1',
  [FILTERS.FILTER_KEY_OTHER2_EXCLUDE]: 'other2',
  [FILTERS.FILTER_KEY_OTHER3_EXCLUDE]: 'other3',
  [FILTERS.FILTER_KEY_OTHER4_EXCLUDE]: 'other4',
  [FILTERS.FILTER_KEY_OTHER5_EXCLUDE]: 'other5',
  [FILTERS.FILTER_KEY_AD_ID_EXCLUDE]: 'ad_id',
  [FILTERS.FILTER_KEY_AD_NAME_EXCLUDE]: 'ad_name',
  [FILTERS.FILTER_KEY_AD_GROUP1]: 'ad_group1_ids_selected',
  [FILTERS.FILTER_KEY_AD_GROUP1_EXCLUDE]: 'ad_group1_ids_selected',
  [FILTERS.FILTER_KEY_AD_GROUP2]: 'ad_group2_ids_selected',
  [FILTERS.FILTER_KEY_AD_GROUP2_EXCLUDE]: 'ad_group2_ids_selected',
  [FILTERS.FILTER_KEY_MEDIA]: 'ad_media_ids_selected',
  [FILTERS.FILTER_KEY_MEDIA_EXCLUDE]: 'ad_media_ids_selected',
  [FILTERS.FILTER_KEY_LANDING_PAGE_URL]: 'others_request_uri',
  [FILTERS.FILTER_KEY_PAGE_ID]: 'om_page_id',
  [FILTERS.FILTER_KEY_PAGE_TITLE]: 'om_page_title',
  [FILTERS.FILTER_KEY_CONTENT_CATEGORY]: 'om_page_category_ids_selected',
  [FILTERS.FILTER_KEY_CONTACT_HISTORY_AD]: 'target_ad',
  [FILTERS.FILTER_KEY_CONTACT_HISTORY_SEARCH]: 'search_target_ad',
  [FILTERS.FILTER_KEY_CONTACT_HISTORY_PAGE]: 'om_target_ad',
};

// Mapping operator value between old & new system
export const FILTER_OPERATOR_MAPPER = {
  [FILTERS.FILTER_OPERATOR_INCLUDE]: 0,
  [FILTERS.FILTER_OPERATOR_EXCLUDE]: 2,
  [FILTERS.FILTER_OPERATOR_EXACT]: 1,
  [FILTERS.FILTER_OPERATOR_FRONT]: 3,
};
// Mapping channel value between old & new system
export const FILTER_CHANNEL_MAPPER = {
  [CHANNEL_TYPE_ID.CLICK]: 'CLICK',
  [CHANNEL_TYPE_ID.SEARCH]: 'SEARCH',
  [CHANNEL_TYPE_ID.REFERRAL]: 'REFERRAL',
  [CHANNEL_TYPE_ID.DIRECT]: 'DIRECT',
  [CHANNEL_TYPE_ID.SITE_CONTENT]: 'OWNED_MEDIA',
  [CHANNEL_TYPE_ID.VIEW]: 'VIEW',
};

// Mapping contact history value between old & new system
export const FILTER_INDIRECT_MAPPER = {
  [FILTER_CONTACT_HISTORY_INDIRECT2]: 'ad_2',
  [FILTER_CONTACT_HISTORY_INDIRECT3]: 'ad_3',
  [FILTER_CONTACT_HISTORY_INDIRECT4]: 'ad_4',
  [FILTER_CONTACT_HISTORY_INDIRECT5]: 'ad_5',
  [FILTER_CONTACT_HISTORY_INDIRECT6]: 'ad_6',
  [FILTER_CONTACT_HISTORY_INDIRECT7]: 'ad_7',
  [FILTER_CONTACT_HISTORY_INDIRECT8]: 'ad_8',
  [FILTER_CONTACT_HISTORY_INDIRECT9]: 'ad_9',
  [FILTER_CONTACT_HISTORY_INDIRECT10]: 'ad_10',
};

export const FILTER_CONTACT_HISTORY_MAPPER = {
  [FILTER_CONTACT_HISTORY_DIRECT]: 'ad_1',
  ...FILTER_INDIRECT_MAPPER,
  [FILTER_CONTACT_HISTORY_FIRST]: 'ad_11',
};

// key (Old system), value (new system)
export const DISPLAYITEM_KEY_MAPPER = {
  // dimension
  channel_access_type: ['channel_access_type', 'contact_channel'],
  group0_name: ['category', 'contact_category'],
  group1_name: ['ad_group1', 'contact_ad_group1'],
  group2_name: ['ad_group2', 'contact_ad_group2'],
  ad_start_date: ['ad_date'],
  ad_end_date: ['ad_date'],
  daily: ['date'],
  ad_id: ['ad_id', 'contact_ad_id'],
  keyword: ['ad_name', 'contact_ad_name'],
  ad_name: ['ad_name', 'contact_ad_name'],
  terminal_type_modified: ['terminal_type'],
  page_category_name: ['content_category', 'contact_content_category'],
  conversion_title: ['cv_name'],
  contact_cnt: ['cnt_contact'],
  contact_count: ['cnt_contact'],
  normalized_request_uri: ['landing_page_url', 'contact_landing_page_url'],
  normalized_request_domain: [
    'landing_page_domain',
    'contact_landing_page_domain',
  ],

  // metric
  per_pv: ['per_page_view'],
  avg_contact_count: ['contact_avg'],
  avg_latency_time: ['latency_time_avg'],
  avg_total_stay_time: ['total_stay_time_avg'],
  latency_time_str: ['cv_contact_latency_time_avg'],
  latency_time: ['cv_contact_latency_time_avg'],
  latency_time_group: ['cv_contact_latency_time_avg'],
  direct: ['contact_direct'],
  indirect_2: ['contact_indirect2', 'cnt_indirect_2'],
  indirect_3: ['contact_indirect3', 'cnt_indirect_3'],
  indirect_4: ['contact_indirect4', 'cnt_indirect_4'],
  indirect_5: ['contact_indirect5', 'cnt_indirect_5'],
  indirect_6: ['contact_indirect6', 'cnt_indirect_other'],
  indirect_7: ['contact_indirect7', 'cnt_indirect_other'],
  indirect_8: ['contact_indirect8', 'cnt_indirect_other'],
  indirect_9: ['contact_indirect9', 'cnt_indirect_other'],
  indirect_10: ['contact_indirect10', 'cnt_indirect_other'],
  first_contract: ['contact_first', 'cnt_first'],
  cvr: ['cvr_total'],
  first_cnt: ['cnt_first', 'contact_first'],
  direct_cnt: ['cv_total', 'cnt_cv_flow'],
  indirect_total_cnt: ['indirect_total'],
  indirect2_cnt: ['cnt_indirect_2', 'contact_indirect2'],
  indirect3_cnt: ['cnt_indirect_3', 'contact_indirect3'],
  indirect4_cnt: ['cnt_indirect_4', 'contact_indirect4'],
  indirect5_cnt: ['cnt_indirect_5', 'contact_indirect5'],
  indirect_other_cnt: [
    'cnt_indirect_other',
    'contact_indirect6',
    'contact_indirect7',
    'contact_indirect8',
    'contact_indirect9',
    'contact_indirect10',
  ],
};

export const MESSAGE_DISABLED_SYSTEM_SYC = (
  <div>
    現在、データを準備中です。
    <br />
    本機能はお申し込み完了後から約3～5営業日ほどでご利用いただけます。
    <br />
    準備完了まで今しばらくお待ちください。
  </div>
);
