const getUserBehaviors = (state) => {
  return state.cache.userProfile.userBehaviors;
};
const getUserProfile = (state) => {
  return state.cache.userProfile.userProfile;
};
const getBaseDate = (state) => {
  return state.cache.userProfile.baseDate;
};
const getAdExpireTime = (state) => {
  return state.cache.userProfile.adExpireTime;
};
const getSortOrder = (state) => {
  return state.cache.userProfile.order;
};
const getPagenation = (state) => {
  return state.cache.userProfile.pagenation;
};
const getUserBehaviorsStatus = (state) => {
  return state.cache.userProfile.userBehaviors.status;
};
const getUserProfileStatus = (state) => {
  return state.cache.userProfile.userProfile.status;
};
const getUserProfileParameter = (state) => {
  return state.cache.userProfile.param;
};
const getUserBehaviorsViewSt = (state) => {
  return state.cache.userProfile.userBehaviorsViewSt;
};
const isOpenAllImpl = (state, open) => {
  const viewSt = state.cache.userProfile.userBehaviorsViewSt;
  const days = Object.keys(viewSt);
  let openSt = true;
  for (let d = 0; d < days.length; d += 1) {
    if (!openSt) {
      break;
    }
    const day = days[d];
    if (open !== viewSt[day].open) {
      openSt = false;
      break;
    } else {
      const inflows = Object.keys(viewSt[day].inflows);
      for (let i = 0; i < inflows.length; i += 1) {
        if (!openSt) {
          break;
        }
        const inflow = inflows[i];
        if (open !== viewSt[day].inflows[inflow].open) {
          openSt = false;
          break;
        } else {
          const accesses = Object.keys(viewSt[day].inflows[inflow].accesses);
          for (let a = 0; a < accesses.length; a += 1) {
            const access = accesses[a];
            if (viewSt[day].inflows[inflow].accesses[access].permission) {
              if (open !== viewSt[day].inflows[inflow].accesses[access].open) {
                openSt = false;
                break;
              }
            }
          }
        }
      }
    }
  }
  return openSt;
};

const isOpenAll = (state) => {
  return isOpenAllImpl(state, true);
};

const isCloseAll = (state) => {
  return isOpenAllImpl(state, false);
};

export default {
  getUserBehaviors,
  getUserProfile,
  getBaseDate,
  getSortOrder,
  getPagenation,
  getUserBehaviorsStatus,
  getAdExpireTime,
  getUserProfileStatus,
  getUserProfileParameter,
  getUserBehaviorsViewSt,
  isOpenAll,
  isCloseAll,
};
