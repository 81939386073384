import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EXTERNAL } from 'services/routes/constants';

const LinkMapper = ({ children, path, type, disabled, newTab }) => {
  return type === EXTERNAL ? (
    <a href={path} target={newTab ? '_blank' : '_self'} rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link to={path} onClick={(e) => disabled && e.preventDefault()}>
      {children}
    </Link>
  );
};

LinkMapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  newTab: PropTypes.bool.isRequired,
};

export default React.memo(LinkMapper);
