import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shape, string, bool } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import adManagementSettingsSelectors from 'store/ad-management-settings/selectors';
import masterDataActions from 'store/master-data/actions';
import adManagementSettingsActions from 'store/ad-management-settings/actions';
import AdManagementCommonBar from 'views/molecules/commonbar/components/AdManagementCommonBar';

function AdManagementCommonBarContainer(props) {
  const { title, tooltip, allowedScreen } = props;
  const filters = useSelector(adManagementSettingsSelectors.getFilterSettings);

  const dispatch = useDispatch();

  useEffect(() => {
    if (allowedScreen) {
      dispatch(masterDataActions.fetchAllForAdManagement());
      dispatch(adManagementSettingsActions.fetchDisplayItems());
    }
  }, [dispatch, allowedScreen]);

  return (
    <AdManagementCommonBar
      title={title}
      tooltip={tooltip}
      allowedScreen={allowedScreen}
      hasFilters={!isEmpty(filters)}
    />
  );
}

AdManagementCommonBarContainer.propTypes = {
  title: string,
  tooltip: shape({}),
  allowedScreen: bool,
};

AdManagementCommonBarContainer.defaultProps = {
  title: '広告管理',
  tooltip: {},
  allowedScreen: false,
};

export default AdManagementCommonBarContainer;
