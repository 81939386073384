export default {
  // types for list function
  GET_DATA: 'search-console-setting/GET_DATA',
  SET_DATA: 'search-console-setting/SET_DATA',
  SET_STATUS: 'search-console-setting/SET_STATUS',
  SET_ERRORS: 'search-console-setting/SET_ERRORS',
  UPDATE_SORT: 'search-console-setting/UPDATE_SORT',
  CHANGE_STATUS: 'search-console-setting/CHANGE_STATUS',
  DELETE: 'search-console-setting/DELETE',
  // types for setting function
  SET_STATUS_SETTING: 'search-console-setting/SET_STATUS_SETTING',
  SET_ERROR_SETTING: 'search-console-setting/SET_ERROR_SETTING',
  SET_DATA_SETTING: 'search-console-setting/SET_DATA_SETTING',
  CREATE: 'search-console-setting/CREATE',
  GET_DETAIL: 'search-console-setting/GET_DETAIL',
  UPDATE: 'search-console-setting/UPDATE',
  GET_MASTERDATA: 'search-console-setting/GET_MASTERDATA',
  SET_MASTERDATA: 'search-console-setting/SET_MASTERDATA',
};
