import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LTV_PERIOD_DROP_DOWN } from 'services/routes/constants';
import logging from 'domain/logging';

const LtvPeriodDropDownApiFactory = () => {
  const url = pages[LTV_PERIOD_DROP_DOWN].endpoint;
  const baseURL = process.env.REACT_APP_LTV_API_HOST;
  return {
    fetchAll: async (data) =>
      Api.get(url, {
        baseURL,
        params: data,
      }),

    create: async (data) => {
      return Api.post(url, data, { baseURL });
    },
    update: async ({ targetId, item }) => {
      return Api.put(`${url}/${targetId}/`, item, { baseURL });
    },
    remove: async (ids) => {
      return Api.delete(`${url}/${ids}`, { baseURL });
    },
    fetchStatus: async () => {
      logging.debug('fetchStatus API', baseURL);
      return Api.get(url, { baseURL });
    },
  };
};

const LtvPeriodDropDownApi = LtvPeriodDropDownApiFactory();
export default LtvPeriodDropDownApi;
