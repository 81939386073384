import React, { useCallback, useEffect } from 'react';
import filterActions from 'store/filters/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import filterSelectors from 'store/filters/selectors';
import settingsSelector from 'store/settings/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import masterDataActions from 'store/master-data/actions';
import settingViewActions from 'store/customview/actions';
import useFilterData from 'services/custom-hooks/useFilterData';
import { getPermissions } from 'store/auth/selectors';
import FilterService from 'domain/FilterService';
import Services from 'services/ad-management/adMediaGroupService';
import isEqual from 'lodash/isEqual';
import {
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
} from 'services/routes/constants';
import FilterForm from './FilterForm';

const MediaGroupFilterContainer = () => {
  const selectedFilters = useSelector(
    filterSelectors.getSettings,
    shallowEqual
  );

  const currentFilters = useSelector(filterSelectors.getFilters);
  const dispatch = useDispatch();

  const pageId = useSelector(settingsSelector.getPage, shallowEqual);
  const currentTab = useSelector(settingsSelector.getTab);
  const display = useSelector(masterDataSelectors.getDisplay, shallowEqual);

  const userPermissions = useSelector(getPermissions);

  const [originFilterableList, mergedMasterData] = useFilterData(pageId);
  const filterableList = Services.customFilters(originFilterableList, display);

  const handleApplyFilter = useCallback(
    (filters) => {
      const mergedFilters = FilterService.getMergedObj(
        filterableList,
        currentFilters,
        filters
      );

      dispatch(filterActions.updateFilters(mergedFilters));
      dispatch(settingViewActions.applyIsChanged({ changed: true }));
    },
    [currentFilters, dispatch, filterableList]
  );
  useEffect(() => {
    // Handle case where values of disabled contracts still remain in session storage
    const contractFilteredValues = FilterService.filterNonContractValues(
      selectedFilters,
      userPermissions
    );
    if (!isEqual(contractFilteredValues, selectedFilters)) {
      handleApplyFilter(contractFilteredValues);
    }
  }, [handleApplyFilter, selectedFilters, userPermissions]);

  useEffect(() => {
    const pages = [
      AD_MANAGEMENT_MEDIA_TYPE,
      AD_MANAGEMENT_AD_GROUP_1,
      AD_MANAGEMENT_AD_GROUP_2,
    ];
    if (pages.includes(pageId)) {
      dispatch(masterDataActions.fetchDisplay());
    }
  }, [dispatch, pageId]);

  return (
    <FilterForm
      masterData={mergedMasterData}
      filterableList={filterableList}
      selectedFilters={selectedFilters}
      applyFilter={handleApplyFilter}
      userPermissions={userPermissions}
      currentTab={currentTab}
    />
  );
};

export default MediaGroupFilterContainer;
