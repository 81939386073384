const REQUEST_START = 'lpo/link/REQUEST_START';
const REQUEST_FAILED = 'lpo/link/REQUEST_FAILED';

const FETCH_REPORT = 'lpo/link/FETCH_REPORT';
const FETCH_REPORT_SUCCEEDED = 'lpo/link/FETCH_SUCCEEDED';

const UPDATE_PAGE = 'lpo/link/UPDATE_PAGE';
const UPDATE_SORT = 'lpo/link/UPDATE_SORT';

export default {
  REQUEST_START,
  REQUEST_FAILED,

  FETCH_REPORT,
  FETCH_REPORT_SUCCEEDED,

  UPDATE_PAGE,
  UPDATE_SORT,
};
