import * as DisplayItems from './display-items';

const {
  DIRECTORY,
  TERMINAL_TYPE,
  PAGE_ID,
  PAGE_TITLE,
  PAGE_URL,
  CNT_PV,
  CNT_SESSION,
  TOTAL_STAY_TIME,
  CONTENT_CATEGORY,
} = DisplayItems;

const SettingDisplayItems = {
  groupList: {
    [DisplayItems.PAGE_ID]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.PAGE_TITLE]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.PAGE_URL]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.DIRECTORY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.TERMINAL_TYPE]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  displayFreezeList: [
    DisplayItems.PAGE_ID,
    DisplayItems.PAGE_TITLE,
    DisplayItems.PAGE_URL,
    DisplayItems.DIRECTORY,
    DisplayItems.TERMINAL_TYPE,
  ],
  orderList: {
    [DisplayItems.PAGE_ID]: 1,
    [DisplayItems.PAGE_TITLE]: 2,
    [DisplayItems.PAGE_URL]: 3,
    [DisplayItems.DIRECTORY]: 4,
    [DisplayItems.TERMINAL_TYPE]: 5,
    [DisplayItems.CONTENT_CATEGORY]: 5,
    [DisplayItems.CNT_PV]: 6,
    [DisplayItems.PER_PV]: 7,
    [DisplayItems.CNT_SESSION]: 8,
    [DisplayItems.PER_SESSION]: 9,
    [DisplayItems.CNT_UU]: 10,
    [DisplayItems.PER_UU]: 11,
    [DisplayItems.CNT_ENTRANCE]: 12,
    [DisplayItems.CNT_EXIT]: 13,
    [DisplayItems.CNT_RETURN]: 14,
    [DisplayItems.PER_RETURN]: 15,
    [DisplayItems.TOTAL_STAY_TIME]: 16,
    [DisplayItems.TOTAL_STAY_TIME_AVG]: 17,
    [DisplayItems.CNT_CV_CONTRIBUTE]: 18,
    [DisplayItems.CNT_CVR_CONTRIBUTE]: 19,
    [DisplayItems.CV_CONTRIBUTE_TOTAL]: 20,
    [DisplayItems.CVR_CONTRIBUTE_TOTAL]: 21,
  },
  displayDefaultList: [
    DisplayItems.PAGE_ID,
    DisplayItems.CNT_PV,
    DisplayItems.CNT_SESSION,
    DisplayItems.CNT_UU,
    DisplayItems.CNT_ENTRANCE,
    DisplayItems.CNT_EXIT,
    DisplayItems.CNT_RETURN,
    DisplayItems.TOTAL_STAY_TIME,
    DisplayItems.TOTAL_STAY_TIME_AVG,
    DisplayItems.CNT_CV_CONTRIBUTE,
    DisplayItems.CNT_CVR_CONTRIBUTE,
    DisplayItems.CV_CONTRIBUTE_TOTAL,
    DisplayItems.CVR_CONTRIBUTE_TOTAL,
  ],
  sortDefaultList: {
    [DisplayItems.CNT_PV]: DisplayItems.SORT_DIRECTION_ASC,
  },
};

const SettingAllAxis = [
  {
    required: true,
    label: '1列目',
    name: 'axis1',
    callback: (selected, option) => {
      switch (selected) {
        case DIRECTORY:
          return {
            ...option,
            [PAGE_ID]: {
              ...option[PAGE_ID],
              allowAxis: ['axis1'],
            },
            [PAGE_TITLE]: {
              ...option[PAGE_TITLE],
              allowAxis: [],
            },
            [PAGE_URL]: {
              ...option[PAGE_URL],
              allowAxis: [],
            },
          };

        case TERMINAL_TYPE:
          return {
            ...option,
            [DIRECTORY]: {
              ...option[DIRECTORY],
              allowAxis: ['axis1', 'axis2'],
            },
            [PAGE_TITLE]: {
              ...option[PAGE_TITLE],
              allowAxis: ['axis3', 'axis4'],
            },
            [PAGE_URL]: {
              ...option[PAGE_URL],
              allowAxis: ['axis3', 'axis4'],
            },
          };

        case PAGE_ID:
          return {
            ...option,
            [DIRECTORY]: {
              ...option[DIRECTORY],
              allowAxis: ['axis1'],
            },
            [TERMINAL_TYPE]: {
              ...option[TERMINAL_TYPE],
              allowAxis: ['axis1', 'axis2', 'axis3', 'axis4'],
            },
            [PAGE_TITLE]: {
              ...option[PAGE_TITLE],
              allowAxis: ['axis2', 'axis3', 'axis4'],
            },
            [PAGE_URL]: {
              ...option[PAGE_URL],
              allowAxis: ['axis2', 'axis3', 'axis4'],
            },
          };

        default:
          return option;
      }
    },
  },
  {
    label: '2列目',
    name: 'axis2',
    callback: (selected, option, selecteds) => {
      const axis1IsDirectory = selecteds?.[0] === DIRECTORY;
      const axis1IsDevice = selecteds?.[0] === TERMINAL_TYPE;
      switch (selected) {
        case TERMINAL_TYPE:
          return {
            ...option,
            [PAGE_TITLE]: {
              ...option[PAGE_TITLE],
              allowAxis: axis1IsDirectory ? [] : ['axis3', 'axis4'],
            },
            [PAGE_URL]: {
              ...option[PAGE_URL],
              allowAxis: axis1IsDirectory ? [] : ['axis3', 'axis4'],
            },
          };

        case DIRECTORY:
          return {
            ...option,
            [PAGE_TITLE]: {
              ...option[PAGE_TITLE],
              allowAxis: axis1IsDevice ? [] : ['axis3', 'axis4'],
            },
            [PAGE_URL]: {
              ...option[PAGE_URL],
              allowAxis: axis1IsDevice ? [] : ['axis3', 'axis4'],
            },
          };

        default:
          return option;
      }
    },
  },
  {
    label: '3列目',
    name: 'axis3',
    callback: (selected, option, selecteds) => {
      const isHaveAxisDevice = !!selecteds?.includes([TERMINAL_TYPE]);
      switch (selected) {
        case PAGE_TITLE:
          return {
            ...option,
            [TERMINAL_TYPE]: {
              ...option[TERMINAL_TYPE],
              allowAxis: isHaveAxisDevice ? [] : ['axis4'],
            },
          };

        case PAGE_URL:
          return {
            ...option,
            [TERMINAL_TYPE]: {
              ...option[TERMINAL_TYPE],
              allowAxis: isHaveAxisDevice ? [] : ['axis4'],
            },
          };

        default:
          return option;
      }
    },
  },
  {
    label: '4列目',
    name: 'axis4',
  },
];

const DISABLED_TOOLTIP =
  'ページIDが集計軸に選ばれていないため、この指標は追加できません';

const hasPage = (data) => data[PAGE_ID] || data[PAGE_TITLE] || data[PAGE_URL];

export const getAll = () => {
  const displayItemsBase = DisplayItems.getAll();
  return DisplayItems.getItems({
    ...SettingDisplayItems,
    displayItemsBase: {
      ...displayItemsBase,
      [DIRECTORY]: {
        ...displayItemsBase[DIRECTORY],
        allowAxis: ['axis1', 'axis2'],
      },
      [TERMINAL_TYPE]: {
        ...displayItemsBase[TERMINAL_TYPE],
        allowAxis: ['axis1', 'axis2', 'axis3'],
      },
      [PAGE_ID]: {
        ...displayItemsBase[PAGE_ID],
        allowAxis: ['axis1', 'axis2'],
      },
      [PAGE_TITLE]: {
        ...displayItemsBase[PAGE_TITLE],
        allowAxis: ['axis2', 'axis3', 'axis4'],
      },
      [PAGE_URL]: {
        ...displayItemsBase[PAGE_URL],
        allowAxis: ['axis2', 'axis3', 'axis4'],
      },
      [CNT_PV]: {
        ...displayItemsBase[CNT_PV],
        title: 'PV',
      },
      [CNT_SESSION]: {
        ...displayItemsBase[CNT_SESSION],
        title: 'SU',
      },
      [TOTAL_STAY_TIME]: {
        ...displayItemsBase[TOTAL_STAY_TIME],
        title: '合計滞在時間',
      },
      [CONTENT_CATEGORY]: {
        ...displayItemsBase[CONTENT_CATEGORY],
        callback: (...params) => {
          const [item, setting] = params;
          return {
            ...item,
            disabled: !hasPage(setting),
            checked: hasPage(setting),
            disabledTooltip: DISABLED_TOOLTIP,
          };
        },
      },
    },
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAllAxis = () => {
  return SettingAllAxis;
};
