import helper from 'store/helper';
import types from 'store/system-setting/display/types';
import { communicationStatus } from 'services/utils';
import { RCV_PATTERN_LINEAR } from 'domain/system-setting/consts-display-setting';
import { prepareLabelForSetting } from 'services/system-setting/systemSettingDisplayServices';
import { RCV_DATA, RCV_MODEL_OPTION } from 'domain/fields';

const { IDLE, SUCCEEDED, FAILED } = communicationStatus;

const initialState = {
  data: {
    label: prepareLabelForSetting(),
    conversions: [],
    rcv: {
      [RCV_DATA]: {},
      [RCV_MODEL_OPTION]: RCV_PATTERN_LINEAR,
    },
  },
  status: {
    fetchAction: IDLE,
    updateAction: IDLE,
  },
};

const keyStatusByType = {
  [types.GET_DATA]: 'fetchAction',
  [types.UPDATE_VISION]: 'updateAction',
  [types.UPDATE_RCV]: 'updateAction',
};

const setStatus = (state, action) => {
  const { status, type } = action.payload;

  return {
    ...state,
    status: { ...state.status, [keyStatusByType[type]]: status },
  };
};

const setData = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    data: { ...state.data, ...data },
    status: { ...state.status, fetchAction: SUCCEEDED },
  };
};

const setErrors = (state, action) => {
  const { type } = action.payload;

  return {
    ...state,
    status: { ...state.status, [keyStatusByType[type]]: FAILED },
  };
};

const SystemSettingDisplayReducer = helper.createReducer(initialState, {
  [types.SET_STATUS]: setStatus,
  [types.SET_DATA]: setData,
  [types.SET_ERRORS]: setErrors,
});

export default SystemSettingDisplayReducer;
