export const HYPERLINK_HASH = '#';

export const EXTERNAL = 'external';
export const INTERNAL = 'internal';

// Screen IDs
export const DASHBOARD = 'dashboard';
export const CATEGORY_ANALYZE = 'category-analyze';
export const DETAILS_ANALYSIS = 'details-analysis';
export const COMPARE_PERIOD = 'compare-period';
export const LANDING_PAGE_ANALYZE = 'landing-page-analyze';
export const CV_ATTRIBUTE = 'cv-attribute';
export const CV_FLOW = 'cv-flow';
export const COST_ALLOCATION = 'cost-allocation';
export const COMMON_VALIDATE = 'common-validate';
export const USER_MANAGEMENT = 'user-management';
export const AGENCY_MANAGEMENT = 'agency-management';
export const TAG_MANAGEMENT = 'tag-management';
export const TAG_MANAGEMENT_PV = 'tag-management/pv';
export const TAG_MANAGEMENT_CONTENT_CATEGORY =
  'tag-management/content-category';
export const MEASUREMENT_SITE_PAGE_MANAGEMENT = 'page-management';
export const LINE_ADD_FRIEND_SETTINGS = 'line-add-friend-settings';
export const DELETE_LINE_ADD_FRIEND_SETTINGS =
  'delete-line-add-friend-settings';
export const PAGE_NOT_FOUND = '404';
export const PAGE_NOT_AUTHORIZED = '403';
export const AD_MANAGEMENT = 'ad-management';
export const AD_MANAGEMENT_MEDIA_TYPE = 'ad-management/media';
export const AD_MANAGEMENT_AD_GROUP_1 = 'ad-management/group1';
export const AD_MANAGEMENT_AD_GROUP_2 = 'ad-management/group2';
export const LTV_SETTINGS_PRODUCT = 'ltv/settings/product';
export const LTV_SETTINGS_CONDITION = 'ltv/settings/condition';
export const LTV_SETTINGS_AD = 'ltv/settings/ad';
export const LTV_SETTINGS_OFFER = 'ltv/settings/offer';
export const LTV_SETTINGS_OTHER = 'ltv/settings/other';
export const LTV_SETTINGS_ORDER = 'ltv/settings/order';
export const LTV_SETTINGS_CLEAR_CSV_ORDERS = 'ltv/settings/clear-csv-orders';
export const LTV_ANALYZE = 'ltv-forecast';
export const LTV_DISPLAY_SETTINGS = 'ltv-forecast/display-settings';
export const LTV_PERIOD_DROP_DOWN = 'ltv-forecast/status';
export const EXTERNAL_SERVICE_SETTINGS = 'external-services';
export const SEARCH_CONSOLE_SETTING = 'search-console-setting';
export const USER_PROFILE = 'user_profile';
export const MEDIA_SYNC_MANAGEMENT = 'media-sync-management';
export const DATA_EXPORT = 'data-export';
export const API_MANAGEMENT = 'api-management';
export const CONTACT_DEMAND_MANAGEMENT = 'contact/demand';
export const CONTACT_MANAGEMENT = 'contact';
export const SYSTEM_SETTING = 'system-setting';
export const SYSTEM_SETTING_BASIC = 'system-setting/basic';
export const SYSTEM_SETTING_DNS = 'system-setting/dns';
export const SYSTEM_SETTING_CNAME = 'system-setting/cname';
export const SYSTEM_SETTING_NS = 'system-setting/ns';
export const SYSTEM_SETTING_MEASUREMENT = 'system-setting/measurement';
export const SYSTEM_SETTING_DISPLAY = 'system-setting/display';
export const SYSTEM_SETTING_NOTIFICATIONS = 'system-setting/notifications';
export const SYSTEM_SETTING_COOKIE_PROGRAM = 'system-setting/cookie-program';
export const IMPRESSION_AD_COST = 'impression-ad-cost';
export const LOG_EBIS = 'log-ebis';
export const LOG_PAGE_ANALYZE = 'website-analyze/page';
export const LOG_PERIOD_ANALYZE = 'website-analyze/period';
export const LOG_ROUTE_ANALYZE = 'website-analyze/flow';
export const CAPI_SETTING = 'capi-setting';
export const LPO_EBIS = 'lpo-ebis';
export const LPO_LINK = 'lpo/link';
export const LPO_PERIOD = 'lpo/period';
export const CSV_SETTING = 'csv-setting';
export const INFLOW_ANALYSIS = 'inflow-analyze'; // for CustomViewModal
export const USER_ANALYSIS_SCREEN = 'user-analysis';
export const CUSTOM_JOURNEY_SCREEN = 'custom-journey';
export const RATE_ANALYZE = 'rate-analyze';

export const LTV_ALL_SCREEN_IDS = [
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_OTHER,
  LTV_SETTINGS_ORDER,
  LTV_SETTINGS_CLEAR_CSV_ORDERS,
  LTV_ANALYZE,
  LTV_DISPLAY_SETTINGS,
  LTV_PERIOD_DROP_DOWN,
];

export const PRIORITY_AXIS_SCREEN_IDS = [
  COMPARE_PERIOD,
  DETAILS_ANALYSIS,
  CATEGORY_ANALYZE,
  CV_ATTRIBUTE,
  CV_FLOW,
  LANDING_PAGE_ANALYZE,
  COST_ALLOCATION,
];

// Navbar external routes
export const USER_ANALYSIS = `${process.env.REACT_APP_HOTEI_HOST}/audience/profile`;
export const EMAIL_SETTING = `${process.env.REACT_APP_HOTEI_HOST}/setup/kpi/mail_setting.php`;
export const CUSTOM_JOURNEY = `${process.env.REACT_APP_HOTEI_HOST}/audience/journey`;
export const RATE_ANALYSIS = `${process.env.REACT_APP_HOTEI_HOST}/audience/journey/general.php`;

/**
 * Support.jsx
 */
export const SUPPORT_SITE = 'https://support.ebis.ne.jp/s/'; // サポートサイト
// TODO: update this when links are available
export const TUTORIAL_SITE = ''; // チュートリアル
export const SUCCESS_STORY_SITE = 'https://www.ebis.ne.jp/actual/'; // 成功事例
export const SETTINGS_SPECIFICATIONS_SITE = `${process.env.REACT_APP_HOST}/contact`; // （お問い合わせ）設定/仕様について
export const OPINION_AND_REQUEST_SITE = `${process.env.REACT_APP_HOST}/contact/demand`; // （お問い合わせ）改善要望
export const DASHBOARD_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004670';
export const CATEGORY_ANALYZE_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004671';
export const DETAILS_ANALYSIS_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004672';
export const LANDING_PAGE_ANALYZE_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004721';
export const COMPARE_PERIOD_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004673';
export const CV_ATTRIBUTE_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004674';
export const CV_FLOW_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004675';
export const COST_ALLOCATION_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004676';
export const DATA_EXPORT_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/30859';
export const FUNCTION_COMPATIBLE_IN_NEW_SYSTEM_LINK =
  'https://support.ebis.ne.jp/s/article/000004680';
export const URL_REDIRECT_NOT_RECOMMENDED =
  'https://support.ebis.ne.jp/s/article/27410';
export const LPO_LINK_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004833';
export const LPO_PERIOD_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004834';

export const APP_HELP_DIRECT_MEASUREMENT_SETTINGS =
  'https://support.ebis.ne.jp/s/article/15918';
export const APP_HELP_UPLOAD_CSV_TOO_MAX_ERROR =
  'https://support.ebis.ne.jp/s/article/15918';
export const APP_HELP_AD_MANAGEMENT =
  'https://support.ebis.ne.jp/s/article/000004692';
export const APP_HELP_AGENCY_MANAGEMENT_PAGE =
  'https://support.ebis.ne.jp/s/article/000004695';
export const APP_HELP_AGENCY_MANAGEMENT_FUNCTION =
  'https://support.ebis.ne.jp/s/article/000004696';
export const APP_HELP_TAG_MANAGEMENT_PAGE =
  'https://support.ebis.ne.jp/s/article/000004722';
// update after confirm with JP
export const APP_HELP_MEASUREMENT_SITE_PAGE_MANAGEMENT =
  'https://support.ebis.ne.jp/s/article/000004722';
export const APP_HELP_TAG_MANAGEMENT_MANUAL_VARIOUS_TAG =
  'https://support.ebis.ne.jp/s/article/000004722';
export const APP_HELP_API_MANAGEMENT_PAGE =
  'https://support.ebis.ne.jp/s/article/000004769';
export const APP_HELP_SYSTEM_SETTING =
  'https://support.ebis.ne.jp/s/article/000004768';
export const APP_HELP_DNS_SETTING =
  'https://support.ebis.ne.jp/s/article/34763';
export const APP_HELP_IMPRESSION_AD_COST_PAGE =
  'https://support.ebis.ne.jp/s/article/000004818';
export const APP_HELP_IMPRESSION_AD_COST_UPLOAD_MODAL =
  'https://support.ebis.ne.jp/s/article/000004819';
export const APP_HELP_IMPRESSION_AD_COST_IMPORT_TYPE =
  'https://support.ebis.ne.jp/s/article/000004820';
export const APP_HELP_IMPRESSION_AD_COST_DEVICE =
  'https://support.ebis.ne.jp/s/article/000004819';
export const APP_HELP_FOR_NEW_STAFF =
  'https://support.ebis.ne.jp/s/for-new-staff'; // 担当者引継ぎLP
// LOG
export const APP_HELP_LOG_PAGE_ANALYZE =
  'https://support.ebis.ne.jp/s/article/000004821';
export const APP_HELP_LOG_PERIOD_ANALYZE =
  'https://support.ebis.ne.jp/s/article/000004822';
export const APP_HELP_LOG_ROUTE_ANALYZE =
  'https://support.ebis.ne.jp/s/article/000004823';
export const APP_HELP_CAPI_SETTING =
  'https://support.ebis.ne.jp/s/article/000004886';
export const APP_HELP_SC_SETTING =
  'https://support.ebis.ne.jp/s/article/000004923';

// Line Measure
export const APP_HELP_LINE_ADD_FRIEND =
  'https://support.ebis.ne.jp/s/article/000004941';

/**
 * User.jsx
 */
export const USAGE_STATUS_SITE = 'setup'; // ご利用状況
export const EBIS_LOG_OUT_SITE = `${process.env.REACT_APP_ID_EBIS_HOST}/logout.php`; // ログアウト

/**
 * Topbar
 */
export const OLD_EBIS_HOME_SITE = `${process.env.REACT_APP_HOTEI_HOST}`; // 旧バージョンへ切り替える
export const OLD_EBIS_FEEDBUCK_GOOGLE_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSev7-8JSxDFvqiHellNNf0oFSEEPByYjZmI-uhhzAFoZXkPLw/viewform?usp=sf_link'; // 旧バージョンへ切り替え時にリンクするGoogleフォーム

/**
 * Setting.jsx
 */
export const AD_MNGT_SITE = `${process.env.REACT_APP_HOTEI_HOST}/ad_management/`; // 広告管理
export const DELIVERY_MNGT_SITE = `${process.env.REACT_APP_HOTEI_HOST}/ad_management/pixeling/`; // 配信管理
export const ONE_TAG_MNGT_SITE = `${process.env.REACT_APP_HOTEI_HOST}/setup/onetag/`; // ワンタグ管理
export const SYSTEM_SETTING_SITE = `${process.env.REACT_APP_HOTEI_HOST}/setup/system/`; // システム設定
export const DNS_SETTING_SITE = `${process.env.REACT_APP_HOTEI_HOST}/setup/dns/`; // 計測用DNS設定
export const GOAL_MNGT_SETTING_SITE = `${process.env.REACT_APP_HOTEI_HOST}/setup/kpi/`; // 目標管理設定
export const MEDIA_DATA_LINK_SETTING_SITE = `${process.env.REACT_APP_HOTEI_HOST}/setup/cost_linking/`; // 媒体データ連携設定

/**
 * Footer link
 */
export const PRIVATE_POLICIES_PERSONAL_DATA =
  'https://www.yrglm.co.jp/policy/privacy_f/';
export const PRIVATE_POLICIES =
  'https://support.ebis.ne.jp/s/article/000004890'; // プライバシーポリシー
export const TERMS_OF_USE = 'https://support.ebis.ne.jp/s/article/000004891'; // AD EBiS 利用契約約款
export const PRIVATE_POLICIES_CAPI =
  'https://support.ebis.ne.jp/s/article/000004890'; // policies capi single
export const TERMS_OF_USE_CAPI =
  'https://support.ebis.ne.jp/s/article/000004891'; // AD EBiS capico single

/**
 * User management
 */
export const USER_MANAGEMENT_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004678';
export const USER_MANAGEMENT_HELP_ARTICLE =
  'https://support.ebis.ne.jp/s/article/000004678'; // ※各機能権限で制御できる機能の一覧はこちら

/**
 * Ad management
 */
export const AD_MANAGEMENT_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/11835';

/**
 * NS/CNAME settings
 */
export const REDIRECT_TRACKING_HELP_LINK =
  'https://support.ebis.ne.jp/s/article/000004945';
export const NS_CNAME_REDIRECT_HELP_LINK =
  'https://support.ebis.ne.jp/s/article/000004945';
export const NS_CNAME_DELETE_HELP_LINK =
  'https://support.ebis.ne.jp/s/article/000004945';
export const NS_CNAME_EMAIL_HELP_LINK =
  'https://support.ebis.ne.jp/s/article/000004945';
export const NS_CNAME_AD_DETAIL_URL_HELP_LINK =
  'https://support.ebis.ne.jp/s/article/000004945';
export const NS_CNAME_AD_CHANGE_URL_HELP_LINK =
  'https://support.ebis.ne.jp/s/article/000004945';
export const NS_CNAME_CSV_CHANGE_URL_HELP_LINK =
  'https://support.ebis.ne.jp/s/article/000004945';

/**
 * Media Sync. Management
 */
export const MEDIA_SYNC_MANAGEMENT_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004738';

export const COOKIE_PROGRAM_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004797';

export const COOKIE_PROGRAM_CHECK_NOTE_LINK =
  'https://support.ebis.ne.jp/s/article/000004757';

export const MEDIA_SYNC_MANAGMENT_MARGIN_HELP =
  'https://support.ebis.ne.jp/s/article/000004761#002'; // update later
/**
 * Media Group management
 */
export const AD_MANAGEMENT_MEDIA_POSTBACK_SETUP =
  'https://support.ebis.ne.jp/s/article/23146';
export const APP_HELP_AD_CATEGORY =
  'https://support.ebis.ne.jp/s/article/000004693';
export const APP_HELP_POSTBACK_HISTORY_RESULT =
  'https://support.ebis.ne.jp/s/article/000004694';

/**
 * User Profile
 */
export const USER_PROFILE_DRAGONBALL_LINK =
  'https://support.ebis.ne.jp/s/article/000004703';

/**
 * Media Sync
 */
export const MEDIA_SYNC_NM_LINKAGE_LINK =
  'https://support.ebis.ne.jp/s/article/000004739';
export const MEDIA_SYNC_AGENCY_LINK =
  'https://support.ebis.ne.jp/s/article/000004737';

export const MEDIA_SYNC_MANAGEMENT_ISSUE_ACCOUNT_LINK =
  'https://support.ebis.ne.jp/s/article/000004716'; // todo update later

/**
 * Endpoint
 */
export const ACCOUNT_ENDPOINT = '/accounts/self';
export const USER_ENDPOINT = '/accounts/self/users/self';
export const LTV_USER_ENDPOINT = '/accounts/self/users/self';
export const LTV_SETTINGS_MARGIN_GUIDE =
  'https://support.ebis.ne.jp/s/article/000004691';

/**
 * Template
 */
export const TEMPLATE_CSV_ORDER_PATH = '/templates/ltv/template_order.csv';
export const TEMPLATE_CSV_PRODUCT_PATH = '/templates/ltv/template_product.csv';
export const TEMPLATE_CSV_OFFER_PATH = '/templates/ltv/template_offer.csv';
export const TEMPLATE_CSV_CONDITION_PATH =
  '/templates/ltv/template_conditions.csv';
export const TEMPLATE_CSV_PRODUCT_LINK_AD_PATH =
  '/templates/ltv/template_product_link_ad.csv';
export const TEMPLATE_CSV_MEDIA_TYPE_PATH =
  '/templates/ad-management/media.csv';
export const TEMPLATE_CSV_TAG_MANAGEMENT_PV =
  '/templates/tag-management/page.csv';
export const TEMPLATE_CSV_TAG_MANAGEMENT_PV_LOG =
  '/templates/tag-management/page_log.csv';
export const TEMPLATE_CSV_TAG_MANAGEMENT_CONTENT_CATEGORY =
  '/templates/tag-management/content_category.csv';

export const TEMPLATE_CSV_AGENCY_MANAGEMENT =
  '/templates/agency-management/agency_management.csv';

/**
 * Docs manual
 */
export const MANUAL_ADEBIS_API =
  '/docs/manual/ADEBiS_API_system_document_v2.5.pdf';

/**
 * dummydata display
 */
export const DUMMY_DATA_DISPLAY = 'http://example.com/';

/**
 * reflecting time guide link
 */
export const REFLECTING_TIME_GUIDE_LINK =
  'https://support.ebis.ne.jp/s/article/000004766';

/**
 * agency sync
 */
export const AGENCY_SYNC_GUIDE =
  'https://doc.support.ebis.ne.jp/tool/success/agency_tool.pdf';
