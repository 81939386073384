import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { LTV_ANALYZE } from 'services/routes/constants';
import * as DisplayItems from 'domain/settings/display-items';
import { saveAs } from 'services/utils';
import logging from 'domain/logging';

const getParameters = (options = {}) => {
  let ltvPeriodDurationMonths;
  let f2CvPeriodDurationDays;
  let f3CvPeriodDurationDays;
  if (
    options.display_conditions === null ||
    options.display_conditions === ''
  ) {
    ltvPeriodDurationMonths =
      DisplayItems.LTV_PERIOD_DURATION_MONTHS_DEFULT_VALUE;
    f2CvPeriodDurationDays =
      DisplayItems.F2_CV_PERIOD_DURATION_DAYS_DEFULT_VALUE;
    f3CvPeriodDurationDays =
      DisplayItems.F3_CV_PERIOD_DURATION_DAYS_DEFULT_VALUE;
  } else {
    ltvPeriodDurationMonths =
      options.display_conditions.ltv_period_duration_months;
    f2CvPeriodDurationDays =
      options.display_conditions.f2_cv_period_duration_days;
    f3CvPeriodDurationDays =
      options.display_conditions.f3_cv_period_duration_days;
  }
  // Add channel column to aggregation columns if in "all" mode
  const { dimensions } = options;
  const aggregationColumns = [...dimensions];
  if (options.channel === 'all') {
    aggregationColumns.unshift('channel');
  }

  return {
    target_period_start: options.start_date,
    target_period_end: options.end_date,
    ltv_period_duration_months: ltvPeriodDurationMonths,
    f2_cv_period_duration_days: f2CvPeriodDurationDays,
    f3_cv_period_duration_days: f3CvPeriodDurationDays,
    aggregation_columns: aggregationColumns,
    result_columns: options.metrics,
    filters: options.filters,
  };
};

const LtvAnalyzeApiFactory = () => {
  const url = pages[LTV_ANALYZE].endpoint;
  const baseURL = process.env.REACT_APP_LTV_API_HOST;
  const urlCsv = `${baseURL}${url}/exports/csv`;

  return {
    url,
    urlCsv,
    baseURL,
    fetchReport: async (params, data) => {
      logging.debug('fetchReport API', params.start_date)
      let parameters = getParameters(params);

      parameters = Object.assign(parameters, data);

      return Api.post(url, parameters, { baseURL });
    },
    downloadCsv: async (
      downloadexportUrl,
      downloadexportUrlCsv,
      tokenInPath,
      batchRequest,
      timeout,
      baseUrl,
      pollUrl,
      params,
      displayConditions,
      dimensionsConfig
    ) => {
      let parameters = getParameters({
        channel: params.channel,
        start_date: params.start_date,
        end_date: params.end_date,
        display_conditions: displayConditions,
        dimensions: params.dimensions,
        metrics: params.metrics,
        filters: params.filters,
      });

      const displayConfig = {};
      const csvHeaders = dimensionsConfig.dimensions;
      Object.keys(csvHeaders).map((key) => {
        const item = csvHeaders[key];
        displayConfig[item.name] = item.text;
        return displayConfig;
      });
      parameters = Object.assign(parameters, {
        sort: params.sort,
        offset: 0,
        limit: Number.MAX_SAFE_INTEGER,
        custom_csv_headers: displayConfig,
      });

      const response = await Api.requestAsync(
        downloadexportUrlCsv,
        parameters,
        'POST',
        {
          pollTokenKey: 'task',
          pollUrlFormat: `${urlCsv}/{task}`,
        }
      );
      const { location } = response.data.data;
      if (location) {
        saveAs(location);
      }
    },
  };
};

const LtvAnalyzeApi = LtvAnalyzeApiFactory();
export default LtvAnalyzeApi;
