import Api, { SettingsApi } from 'services/api/Api';
import { SYSTEM_SETTING, USER_MANAGEMENT } from 'services/routes/constants';
import pages from 'services/routes/pages';
import { MEASUREMENT_SETTING_ENDPOINTS } from 'domain/system-setting/consts';

const SystemSettingApiFactory = () => {
  const url = pages[SYSTEM_SETTING].endpoint;
  const endpointUser = pages[USER_MANAGEMENT].endpoint;
  return {
    getBasicSetting: async () => {
      return SettingsApi.get(`${url}/basic`);
    },
    updateBasicSetting: async (data) => {
      return SettingsApi.put(`${url}/basic`, data);
    },
    getCrossDomainList: async (data) => {
      return SettingsApi.post(`${url}/tld`, data);
    },
    getMeasurementSetting: async () => {
      return SettingsApi.get(`${url}/measure`);
    },
    updateMeasurementSetting: async (endpoint, data) => {
      return SettingsApi.put(
        `${url}/measure/${MEASUREMENT_SETTING_ENDPOINTS[endpoint]}`,
        data
      );
    },
    getOtherSetting: async () => {
      return SettingsApi.get(`${url}/other`);
    },
    updateOtherSetting: async (data) => {
      return SettingsApi.put(`${url}/other`, data);
    },
    getDnsSetting: async (tab, setupType = 1) => {
      return SettingsApi.get(`${url}/dns/${tab}?setup=${setupType}`);
    },
    addDnsSetting: async (tab, certType, data) => {
      return SettingsApi.post(`${url}/dns/${tab}?cert=${certType}`, data);
    },
    deleteDnsSetting: async (tab, data) => {
      return SettingsApi.delete(`${url}/dns/${tab}`, { data: { etld1: data } });
    },
    updateDnsSetting: async (tab, data) => {
      const sendMailUsers = data.send_mail_flag ? data.send_mail_users : [];
      return SettingsApi.update(`${url}/dns/${tab}/update`, {
        update_type: 'email',
        etld1: data.etld1,
        redirect_flag: data.redirect_flag,
        send_mail_flag: data.send_mail_flag,
        send_mail_users: sendMailUsers,
      });
    },
    fetchUsers: async () => Api.get(endpointUser),
  };
};

const SystemSettingApi = SystemSettingApiFactory();
export default SystemSettingApi;
