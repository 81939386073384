import React, { useRef, useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { number, string, bool, func, array, oneOfType } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { GridTableContext } from 'views/organism/GridTable/GridTableContext';
import { getHeightRow } from 'views/organism/GridTable/GridTableService';
import useHoverTooltip from 'services/custom-hooks/useHoverTooltip';

const ContentItem = ({ value, hidden, isInline }) => {
  const tooltipRef = useRef(null);
  useHoverTooltip(tooltipRef);

  const cls = classNames('grid-table__content-item', {
    'grid-table__content-item--inline': isInline,
  });

  return (
    <div ref={tooltipRef} className={cls} hidden={hidden}>
      {value}
    </div>
  );
};

ContentItem.propTypes = {
  value: string.isRequired,
  hidden: bool.isRequired,
  isInline: bool.isRequired,
};

const CollapseByHeight = ({ isExpand, value, maxHeight, onCollapse }) => {
  const contentRef = useRef();
  const contentEl = contentRef?.current || {};
  const { scrollHeight = 0 } = contentEl;

  useEffect(() => {
    onCollapse(maxHeight > 0 && scrollHeight - 1 > maxHeight);
  }, [onCollapse, scrollHeight, maxHeight]);

  return (
    <div
      ref={contentRef}
      style={{ overflow: 'hidden', maxHeight: isExpand ? null : maxHeight }}
    >
      {value}
    </div>
  );
};

CollapseByHeight.propTypes = {
  isExpand: bool.isRequired,
  value: string.isRequired,
  maxHeight: number.isRequired,
  onCollapse: func.isRequired,
};

const CollapseByLine = ({
  isExpand,
  field,
  items,
  maxLine,
  isInline,
  onCollapse,
}) => {
  useEffect(() => {
    onCollapse(items.length > maxLine);
  }, [onCollapse, items, maxLine]);

  return (
    <>
      {(items || []).map((value, index) => {
        const keyIndex = `${field}-${index}`;
        return (
          <ContentItem
            key={keyIndex}
            value={value}
            hidden={index >= maxLine && !isExpand}
            isInline={isInline}
          />
        );
      })}
    </>
  );
};

CollapseByLine.propTypes = {
  isExpand: bool.isRequired,
  field: string.isRequired,
  items: oneOfType([array]),
  maxLine: number.isRequired,
  isInline: bool.isRequired,
  onCollapse: func.isRequired,
};
CollapseByLine.defaultProps = {
  items: [],
};

function GirdTableCellCollapse(props) {
  const { type, field, value, maxHeight, maxLine, isInline } = props;
  const { dispatch } = useContext(GridTableContext);

  const [isExpand, setExpand] = useState(false);
  const [isCollapse, setCollapse] = useState(false);

  const handleExpand = (e) => {
    e.stopPropagation();
    setExpand(!isExpand);
    debounce(() => {
      getHeightRow(e.target.closest('[role="row"]'), dispatch);
    }, 50)();
  };

  return (
    <>
      {type === 'collapseByLine' ? (
        <CollapseByLine
          isExpand={isExpand}
          field={field}
          items={value}
          maxLine={maxLine}
          isInline={isInline}
          onCollapse={setCollapse}
        />
      ) : (
        <CollapseByHeight
          isExpand={isExpand}
          value={value}
          maxHeight={maxHeight}
          onCollapse={setCollapse}
        />
      )}
      {isCollapse && (
        <Button
          size="xs"
          variant="link"
          className="p-0 border-0"
          onClick={(e) => handleExpand(e)}
        >
          {isExpand ? '省略して表示' : 'すべて表示'}
        </Button>
      )}
    </>
  );
}

GirdTableCellCollapse.propTypes = {
  type: string,
  field: string,
  value: oneOfType([string, array]),
  maxHeight: number,
  maxLine: number,
  isInline: bool,
};

GirdTableCellCollapse.defaultProps = {
  type: '',
  field: '',
  value: '',
  maxHeight: 54, // = 3 lines * 18px
  maxLine: 1, // 1 line
  isInline: false, // inline content item
};

export default GirdTableCellCollapse;
