import ltvPeriodDropDownApi from 'services/api/ltv/LtvPeriodDropDownApi';
import { TAB_AD, TAB_ALL } from 'services/consts';
import HttpStatus from 'domain/httpUtils';

import logging from 'domain/logging';

function ltvPermissionFilterService() {
  // In case of SERVICE_UNAVAILABLE Api error, remove LTV permissions.
  const permissionFilter = async (permissions, ltvPermissionsConfig) => {
    const permissionsConfig = [
      ...ltvPermissionsConfig[TAB_AD],
      ...ltvPermissionsConfig[TAB_ALL],
      ...ltvPermissionsConfig.entry,
    ];

    const haveLtvPermission =
      [...permissions].filter((permission) =>
        permissionsConfig.includes(permission)
      ).length > 0;
    if (haveLtvPermission) {
      try {
        const response = await ltvPeriodDropDownApi.fetchStatus();
        logging.debug('response', response);
      } catch (error) {
        logging.debug('response error', error);
        if (error.response.status === HttpStatus.SERVICE_UNAVAILABLE) {
          const newPermissions = permissions.filter((permission) => {
            return !permissionsConfig.includes(permission);
          });
          return newPermissions;
        }
        return permissions;
      }
    }
    return permissions;
  };
  return { permissionFilter };
}

export default ltvPermissionFilterService;
