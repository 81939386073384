import helper from 'store/helper';
import types from './types';

const changeTab = (tab) => helper.createAction(types.CHANGE_TAB, { tab });
const changePage = (page) => helper.createAction(types.CHANGE_PAGE, { page });

export default {
  changeTab,
  changePage,
};
