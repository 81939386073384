export const PERIOD_TYPE = {
  DAY: 'day',
  MONTH: 'month',
  DOW: 'dow',
  HOUR: 'hour',
};

export const MEMO_CATEGORY = {
  ONLINE: 'ONLINE',
  OFLLINE: 'OFFLINE',
  WEBSITE: 'WEB_RENO',
  ANALYSIS: 'ANALYZE_INSPEC',
  OTHERS: 'OTHER',
};

export const MEMO_ERRORS = {
  MEMO_CONTENT_TOO_LONG: {
    code: 'MEMO_CONTENT_TOO_LONG',
    msg: 'MEMO_CONTENT_TOO_LONG',
  },
  DURATION_OVER: {
    code: 'DURATION_OVER',
    msg: 'MEMO_INVALID_DURATION',
  },
  MEMO_IS_OVER_MAX: {
    code: 'MEMO_IS_OVER_MAX',
    msg: 'MEMO_OVER_MAX',
  },
};

export const MEMO_API_ERRORS = [
  MEMO_ERRORS.DURATION_OVER,
  MEMO_ERRORS.MEMO_IS_OVER_MAX,
];

export const MEMO_API_FIELD = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  CATEGORY: 'category',
  CONTENT: 'content',
  ID: 'id',
};

export const MEMO_CATEGORIES_PATTERNS = [
  { key: MEMO_CATEGORY.ONLINE, color: '#F8CF92' },
  { key: MEMO_CATEGORY.OFLLINE, color: '#F9CACF' },
  { key: MEMO_CATEGORY.WEBSITE, color: '#C7D5E2' },
  { key: MEMO_CATEGORY.ANALYSIS, color: '#C5DBBD' },
  { key: MEMO_CATEGORY.OTHERS, color: '#D1C2DF' },
];

export const MEMO_DATE_FORMAT = 'YYYY-MM-DD';

export const TABLE_PERIOD_DATE_FORMAT = 'YYYY/MM/DD (dd)';

export const TABLE_PERIOD_MONTH_FORMAT = 'YYYY/MM';

export const MEMO_MAX_CONTENT_LENGTH = 300;
