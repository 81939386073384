import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { bool, shape, string, number } from 'prop-types';
import { Table, Button } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import iconCautionYellow from 'assets/images/icon-caution-yellow.svg';
import {
  MAX_ROW_DNS_SETTING,
  NS_SETTING,
  STATUS_DNS_SETTING,
  DNS_AVAILABLE_STATUS,
  DNS_ERROR_STATUS,
  DNS_WARMUP_STATUS,
  DNS_SETTING_STATUS,
  DNS_UNHEALTHY_STATUS,
  APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_4,
  CNAME_SETTING,
  FIELD_EMAIL_SETTING_FORM,
  STEPS_DNS_SETTING_FORM,
  STEPS_DNS_SETTING_FORM_EDIT,
  STEP_SETTING_DNS_SUBDOMAIN,
  STEP_SETTING_DNS_EMAIL,
  STEP_CONFIRM,
  STEP_SETTING_DNS_EMAIL_EDIT,
  STEP_CONFIRM_EDIT,
} from 'domain/system-setting/consts';
import { CNAME_DOMAIN_FIELD, NS_DOMAIN_FIELD, EMAIL_NOTIFICATION, SEND_MAIL_USERS, REDIRECT_FLAG } from 'domain/fields';
import { SETTING_USER_EDIT } from 'domain/permissions/contractPermissions';

import { getEmail, getUserId } from 'store/auth/selectors';
import systemSettingActions from 'store/system-setting/actions';
import systemSettingSelectors from 'store/system-setting/selectors';

import { getHeaderDnsTable, getTitleDnsSetting } from 'services/system-setting/systemSettingServices';
import { getErrorMessageByCode } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';
import { SYSTEM_SETTING_DNS, NS_CNAME_DELETE_HELP_LINK } from 'services/routes/constants';

import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import EbisCollapseCustom from 'views/atoms/Collapse/EbisCollapseCustom';
import CustomConfirmModal from 'views/molecules/modal/DeleteConfirmModal/CustomConfirmModal';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';
import ControlBar from 'views/molecules/control-bar';
import GridTableTemplate from 'views/templates/GridTableTemplate';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import useModalController from 'services/custom-hooks/useModalController';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import DnsSettingConfirmModal from 'views/pages/system-setting/components/DnsSettingConfirmModal';
import DnsSettingModal from './DnsSettingModal';
import DnsSettingEmailModal from './DnsSettingEmailModal';

const MODAL_IDS = {
  ADD: 'add',
  CONFIRM_DELETE: 'confirm_delete',
  EMAIL_SETTING: 'email_setting',
  CONFIRM: 'confirm',
  ERROR: 'error',
};

const RenderStatusText = ({ status, healthStatus, unhealthyCount, statusText, statusTextExtra, tab }) => {
  if (healthStatus === "unhealthy" && unhealthyCount >= 2) {
    status = DNS_UNHEALTHY_STATUS
    statusText = "疎通エラー"
    statusTextExtra = ""
  }
  return (
    <div>
      <div className="dns-status">
        {(status === DNS_ERROR_STATUS || status === DNS_UNHEALTHY_STATUS) && (
          <img src={iconCautionYellow} alt="icon-caution" />
        )}
        {status === DNS_AVAILABLE_STATUS && <i className="fas fa-check" />}
        <span>{statusText}</span>
        {[DNS_WARMUP_STATUS, DNS_SETTING_STATUS].includes(
          status
        ) && <DragonBall>{STATUS_DNS_SETTING[status][tab]}</DragonBall>}
        {[DNS_ERROR_STATUS, DNS_UNHEALTHY_STATUS].includes(
          status
        ) && <DragonBall stayOpen>{STATUS_DNS_SETTING[status][tab]}</DragonBall>}
      </div>
      {statusTextExtra && (
        <div className="dns-status__extra">{statusTextExtra}</div>
      )}
    </div>
  );
};

RenderStatusText.propTypes = {
  status: string.isRequired,
  healthStatus: string.isRequired,
  unhealthyCount: number.isRequired,
  statusText: string.isRequired,
  statusTextExtra: string.isRequired,
  tab: string.isRequired,
};

const RenderRowTable = ({ row, userMasterEmailData, isScreenComplete, userId, email }) => {
  /* Requirement change: Email settings are not displayed on the list screen
  const getSendMailUsers = (sendMailUsers) => {
    if (userMasterEmailData && Object.prototype.hasOwnProperty.call(userMasterEmailData, 'users')) {
      return Object.keys(sendMailUsers).map(user => {
        const sendMailAddress = Object.keys(userMasterEmailData.users)
          .filter(key =>
            userMasterEmailData.users[key].user_id === sendMailUsers[user]
          )
          .map(key =>
            userMasterEmailData.users[key].email
          )
        return (
          <div className="dns-setting-email-settings">
            {sendMailUsers[user]}：<br />
            {sendMailAddress.join("<br />")}
          </div>
        )
      });
    }
    return Object.keys(sendMailUsers).map(user => {
      if (sendMailUsers[user] === userId) {
        return (
          <div className="dns-setting-email-settings">
            {userId}：<br />
            {email}
          </div>
        )
      }
      return (
        <></>
      )
    });
  }
  */

  return (
    <EbisCollapseCustom
      title={
        row.ns_fqdn || (row.asp_flag ? `${row.cname_fqdn}*` : row.cname_fqdn)
      }
      isAlwayOpenExpand={isScreenComplete && !row.asp_flag}
      isShowIconExpand={!isScreenComplete && !row.asp_flag}
    >
      {row.asp_flag ? (
        <span />
      ) : (
        <div>
          <Table striped bordered hover className="dns-expand-table">
            <thead>
              <tr>
                <th> </th>
                <th>ホスト名</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>計測用</td>
                <td>{row.ns_fqdn || row.cname_fqdn}</td>
                <td>
                  {row.ebis_fqdn ? (
                    `${row.ebis_fqdn}.`
                  ) : (
                    <>
                      {row.name_server_1}.
                      <br /> {row.name_server_2}.
                      <br />
                      {row.name_server_3}.
                      <br />
                      {row.name_server_4}.
                    </>
                  )}
                </td>
              </tr>
              {!row.emails && (
                <tr>
                  <td className="dns-row-record">
                    証明書審査用
                    {row.ns_fqdn && (
                      <>
                        <br />
                        （弊社設定用）
                        <DragonBall>
                          NSの場合は弊社で設定を行うため、お客様のDNSに「証明書審査用」の登録は不要です。
                        </DragonBall>
                      </>
                    )}
                  </td>
                  <td className="blur-column">
                    {row.acm_record_name || row.record_name}
                  </td>
                  <td className="blur-column">
                    {row.acm_record_value || row.record_value}
                  </td>
                </tr>
              )}
              {/* Requirement change: Email settings are not displayed on the list screen
                <tr>
                  <td>アラートメール<br />通知</td>
                  <td>{row.send_mail_flag ? 'する' : 'しない'}</td>
                  <td>
                    {row.send_mail_flag ? (
                      <>
                        <div>
                          する
                        </div>
                        {getSendMailUsers(row.send_mail_users_by_user_id)}
                      </>
                    ) : (
                      'しない'
                    )}
                  </td>
                </tr>
              */}
            </tbody>
          </Table>
          {row.emails && (
            <div className="dns-contain-email">
              <div>審査メール送付先</div>
              <div>{row.emails}</div>
            </div>
          )}
        </div>
      )}
    </EbisCollapseCustom>
  );
};

RenderRowTable.propTypes = {
  row: shape({}).isRequired,
  userMasterEmailData: shape({}).isRequired,
  isScreenComplete: bool.isRequired,
  userId: string.isRequired,
  email: string.isRequired,
};

function DnsSettingContainer({ tab }) {
  const { openModal, closeModals, isOpen } = useModalController({
    ids: Object.values(MODAL_IDS),
  });

  const {
    isLoading,
    data,
    dnsAdd,
    isEmptyHostName,
    isDisabledRegister,
    healthStatusList,
    unhealthyCountList,
    isScreenComplete,
  } = useSelector(systemSettingSelectors.getStatesDnsSetting);

  const userMasterEmailData = useSelector(systemSettingSelectors.getMasterData, shallowEqual);
  const email = useSelector(getEmail, shallowEqual);
  const userId = useSelector(getUserId, shallowEqual);

  const dispatch = useDispatch();

  const [listItemUpdate, setListItemUpdate] = useState([]);
  const [listIdsDelete, setListIdsDelete] = useState([]);
  const [listItemDelete, setListItemDelete] = useState([]);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [isAddEmailMode, setIsAddEmailMode] = useState(false);
  const [error, setError] = useState({ title: '', message: '' });
  const [errorMainMessage, setErrorMainMessage] = useState('');
  const [errorForm, setErrorForm] = useState({});
  const { checkPermissions } = useCheckPermissions();
  const hasUserEditPermission = checkPermissions('anyOf', [SETTING_USER_EDIT]);

  // To share data between modals, define variables in this container
  // step management
  const [currentStep, setCurrentStep] = useState();
  const [currentStepEdit, setCurrentStepEdit] = useState();
  const steps = STEPS_DNS_SETTING_FORM.map((item) => ({
    ...item,
    active: item.step === currentStep,
  }));
  const stepsEditMode = STEPS_DNS_SETTING_FORM_EDIT.map((item) => ({
    ...item,
    active: item.step === currentStepEdit,
  }));

  // 1st step
  const [domainFormValue, setDomainFormValue] = useState(
    data.reduce((obj, item) => ({ ...obj, [item.etld1]: '' }), {})
  );

  // 2nd step
  const [mailFormValue, SetMailFormValue] = useState(FIELD_EMAIL_SETTING_FORM);

  // edit
  const [editItems, SetEditItems] = useState();

  const handleRefreshWindow = () => window.location.reload();

  const handleHideModal = () => {
    closeModals();
    setIsAddEmailMode(false);
  };

  const handleSelectRow = (ids) => setListIdsDelete(() => ids);

  const handleOpenSettings = useCallback(() => {
    setDomainFormValue({});
    setErrorForm({});
    setErrorMainMessage('');
    SetMailFormValue(FIELD_EMAIL_SETTING_FORM);
    setCurrentStep(STEP_SETTING_DNS_SUBDOMAIN);
    openModal(MODAL_IDS.ADD);
  }, [openModal]);

  const handleOpenMailSettings = (tab, [selectedItems]) => {
    SetEditItems([selectedItems]);
    setCurrentStepEdit(STEP_SETTING_DNS_EMAIL_EDIT);
    const domainName = tab === NS_SETTING ? selectedItems.ns_fqdn : selectedItems.cname_fqdn;
    setListItemUpdate([{
      "etld1": selectedItems.etld1,
      "domain_name": domainName,
      "redirect_flag": selectedItems.redirect_flag,
      "send_mail_flag": selectedItems.send_mail_flag,
      "send_mail_users": selectedItems.send_mail_users_by_user_id,
    }]);
    openModal(MODAL_IDS.EMAIL_SETTING);
  };

  const handleConfirmDelete = useCallback(() => {
    const list = data.reduce((listResult, selectedItems, currentIndex) => {
      if (listIdsDelete.includes(selectedItems.etld1)) {
        return [
          ...listResult,
          {
            value: data[currentIndex].ns_fqdn || data[currentIndex].cname_fqdn,
            order: currentIndex,
          },
        ];
      }
      return listResult;
    }, []);
    setListItemDelete(list);
    openModal(MODAL_IDS.CONFIRM_DELETE);
  }, [data, listIdsDelete, openModal]);

  const getList = useCallback(() => {
    dispatch(systemSettingActions.getMasterData());
    dispatch(systemSettingActions.getDnsSetting(tab));
  }, [tab, dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleConfirmModal = () => {
    closeModals();
    getList();
  };

  const handleShowErrorModal = useCallback(
    (title, message) => {
      setError({ title, message });
      openModal(MODAL_IDS.ERROR);
    },
    [openModal]
  );

  const handleResponseDelete = ({ errors }) => {
    setListIdsDelete([]);
    closeModals();
    setLoadingDelete(false);

    if (isEmpty(errors)) {
      setListIdsDelete([]);
    } else {
      const message = getErrorMessageByCode(errors[0], messageError);
      handleShowErrorModal('データ更新に失敗しました', message);
    }
  };

  const handleSubmitDelete = () => {
    setLoadingDelete(true);
    dispatch(
      systemSettingActions.deleteDnsSetting(listIdsDelete, handleResponseDelete)
    );
  };

  const getCurrentItem = (data, etld1) => {
    return data.filter((columns) => {
      return columns.etld1 === etld1;
    });
  };

  const rows = useMemo(() => {
    return (
      data?.length > 0 &&
      data.reduce((prevItem, selectedItems) => {
        let healthStatus = ""
        let unhealthyCount = 0
        let fqdn = ""
        if (tab === NS_SETTING && selectedItems.ns_fqdn) {
          fqdn = selectedItems.ns_fqdn
        } else if (tab === CNAME_SETTING && selectedItems.cname_fqdn) {
          fqdn = selectedItems.cname_fqdn
        }
        if (healthStatusList && Object.prototype.hasOwnProperty.call(healthStatusList, fqdn)) {
          healthStatus = healthStatusList[fqdn]
        }
        if (unhealthyCountList && Object.prototype.hasOwnProperty.call(unhealthyCountList, fqdn) && !Number.isNaN(unhealthyCountList[fqdn])) {
          unhealthyCount = unhealthyCountList[fqdn]
        }
        return [
          ...prevItem,
          {
            etld1: selectedItems.etld1,
            [selectedItems.cname_fqdn && !isScreenComplete
              ? CNAME_DOMAIN_FIELD
              : NS_DOMAIN_FIELD]: (
              <RenderRowTable
                row={selectedItems}
                userMasterEmailData={userMasterEmailData}
                isScreenComplete={isScreenComplete}
                isAddEmailMode={isAddEmailMode}
                userId={userId}
                email={email}
              />
            ),
            status_text: (
              <RenderStatusText
                status={selectedItems.status}
                healthStatus={healthStatus}
                unhealthyCount={unhealthyCount}
                statusTextExtra={selectedItems.status_text_extra}
                statusText={selectedItems.status_text}
                tab={tab}
              />
            ),
            isAllowSelectRow: true,
          },
        ];
      }, [])
    );
  }, [data, healthStatusList, unhealthyCountList, isAddEmailMode, isScreenComplete, tab]);

  return (
    <div className="dns-setting h-100">
      <DataSyncLoader isLoading={isLoading}>
        {isEmptyHostName ? (
          <div className="dns-setting__empty-hostname">
            <Link to="/system-setting/basic" className="txt-decoration-none">
              計測対象サイト
            </Link>
            を設定していただくことで、 <br />
            計測用DNSの設定が行えます。
          </div>
        ) : (
          <GridTableTemplate
            isLoading={isLoading}
            idFreedHead="freeze-head-area-dns"
            pageId={SYSTEM_SETTING_DNS}
            variant={SYSTEM_SETTING_DNS}
            header={getHeaderDnsTable(isScreenComplete, tab)}
            rows={rows || []}
            onSelectRow={handleSelectRow}
            onEditRow={(etld1) => { handleOpenMailSettings(tab, getCurrentItem(data, etld1)); }}
            isTableCenter
            tableControl={[
              <div
                className={
                  isScreenComplete
                    ? 'dns-setting-table__complete'
                    : 'dns-setting-table'
                }
              >
                <div className="dns-setting__left">
                  <div className="dns-setting__title">
                    {tab === NS_SETTING ? 'NSドメイン' : 'CNAMEドメイン'}
                  </div>
                  {!isScreenComplete && (
                    <ControlBar
                      page={SYSTEM_SETTING_DNS}
                      currentItems={data?.length || 0}
                      maximumItems={MAX_ROW_DNS_SETTING}
                      checkedItemsNum={isLoading ? 0 : listIdsDelete.length}
                      setShowConfirmModal={handleConfirmDelete}
                      handleShowSettingModal={handleOpenSettings}
                      disabledRegister={
                        isLoading || isDisabledRegister || dnsAdd.length < 1
                      }
                    />
                  )}
                </div>
                {isScreenComplete && (
                  <div>
                    <div>登録を受け付けました。</div>
                    <div className="dns-setting-table__complete-note">
                      <div className="dns-setting-table__complete-bold">
                        登録はまだ完了していません
                      </div>
                      {isAddEmailMode ? (
                        <div>
                          設定を完了させるためには、以下2つの設定が必要です。
                          <br />
                          なお設定期間は、疎通確認の時間も含めた期限となっております、期限切れの数時間前にはDNSの登録を行ってください。
                          <br />
                          <br />
                          ・ドメインの「計測用」レコードをお客様のDNSに登録
                          <br />
                          ・Amazon Web
                          Servicesから送信された証明書審査メールの案内に沿ったお手続き
                          <div className="dns-note">
                            ※メール審査の概要については、詳しくは
                            <a
                              href={
                                APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_4
                              }
                              target="__blank"
                            >
                              こちら
                            </a>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            {tab === NS_SETTING
                              ? '設定を完了させるためには、ドメインの「計測用」レコードをお客様のDNSに登録してください。'
                              : '設定を完了させるためには、ドメインの「計測用」レコード（CNAMEの場合は「証明書審査用」も併せて）をお客様のDNSに登録してください。'}
                            <br />
                            {tab === NS_SETTING
                              ? '発行から62日以内に設定を完了する必要があり、期限切れまでの時間がステータス列に表示されます。'
                              : '発行から72時間以内に設定を完了する必要があり、期限切れまでの時間がステータス列に表示されます。'}
                            <br />
                            期限切れの場合は、一度設定を削除して再登録いただく必要があります。
                            <br />
                            なお、期限内に疎通確認も行っていただく必要があるため、早めに設定を行ってください。
                          </div>
                          {tab === CNAME_SETTING && (
                            <div className="mt-10">
                              ※[*]がついているASPドメインの場合、上記作業は必要ありません。
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {!isScreenComplete && (
                  <div className="paginations">計{data?.length || 0}件</div>
                )}
              </div>,
              <div className="dns-setting-return-top">
                {/*
                  {isScreenComplete && tab === NS_SETTING && (
                    <Link to="/system-setting/ns#" className="dns-setting-return-top">設定一覧に戻る</Link>
                  )}
                  {isScreenComplete && tab === CNAME_SETTING && (
                    <Link to="/system-setting/cname#" className="dns-setting-return-top">設定一覧に戻る</Link>
                  )}
                */}
                {isScreenComplete && (
                  <Button
                    variant="link"
                    size="sm"
                    className="dns-setting-return-top"
                    onClick={() => { handleRefreshWindow(); }}
                  >
                    設定一覧に戻る
                  </Button>
                )}
              </div>
            ]}
          />
        )}
        {isOpen(MODAL_IDS.ADD) && currentStep === STEP_SETTING_DNS_SUBDOMAIN && (
          <DnsSettingModal
            onHide={handleHideModal}
            isShow={isOpen(MODAL_IDS.ADD) && currentStep === STEP_SETTING_DNS_SUBDOMAIN}
            isLoading={isLoading}
            data={dnsAdd}
            isAddEmailMode={isAddEmailMode}
            setIsAddEmailMode={setIsAddEmailMode}
            tab={tab}
            steps={steps}
            setCurrentStep={setCurrentStep}
            domainFormValue={domainFormValue}
            setDomainFormValue={setDomainFormValue}
            setListItemUpdate={setListItemUpdate}
            errorForm={errorForm}
            setErrorForm={setErrorForm}
            errorMainMessage={errorMainMessage}
            setErrorMainMessage={setErrorMainMessage}
          />
        )}
        {isOpen(MODAL_IDS.ADD) && currentStep === STEP_SETTING_DNS_EMAIL && (
          <DnsSettingEmailModal
            onHide={handleHideModal}
            isShow={isOpen(MODAL_IDS.ADD) && currentStep === STEP_SETTING_DNS_EMAIL}
            // isLoading={isLoading || isCreating} // This modal does not send API
            isLoading={isLoading}
            listItemUpdate={listItemUpdate}
            userMasterEmailData={userMasterEmailData}
            title={getTitleDnsSetting(tab, isAddEmailMode, false)}
            steps={steps}
            setCurrentStep={setCurrentStep}
            mailFormValue={mailFormValue}
            SetMailFormValue={SetMailFormValue}
            hasUserEditPermission={hasUserEditPermission}
            errorForm={errorForm}
            setErrorForm={setErrorForm}
          />
        )}
        {isOpen(MODAL_IDS.ADD) && currentStep === STEP_CONFIRM && (
          <DnsSettingConfirmModal
            onHide={handleHideModal}
            isShow={isOpen(MODAL_IDS.ADD) && currentStep === STEP_CONFIRM}
            isLoading={isLoading}
            isAddEmailMode={isAddEmailMode}
            tab={tab}
            steps={steps}
            setCurrentStep={setCurrentStep}
            dnsList={dnsAdd}
            domainFormValue={domainFormValue}
            mailFormValue={mailFormValue}
            userMasterEmailData={userMasterEmailData}
            hasUserEditPermission={hasUserEditPermission}
            errorForm={errorForm}
            setErrorForm={setErrorForm}
            errorMainMessage={errorMainMessage}
            setErrorMainMessage={setErrorMainMessage}
          />
        )}
        {isOpen(MODAL_IDS.EMAIL_SETTING) && currentStepEdit === STEP_SETTING_DNS_EMAIL_EDIT && (
          <DnsSettingEmailModal
            onHide={handleHideModal}
            isShow={isOpen(MODAL_IDS.EMAIL_SETTING) && currentStepEdit === STEP_SETTING_DNS_EMAIL_EDIT}
            // isLoading={isLoading || isCreating} // This modal does not send API
            isLoading={isLoading}
            listItemUpdate={listItemUpdate}
            userMasterEmailData={userMasterEmailData}
            title={getTitleDnsSetting(tab, isAddEmailMode, true)}
            steps={stepsEditMode}
            setCurrentStep={setCurrentStepEdit}
            isEdit="true"
            mailFormValue={mailFormValue}
            SetMailFormValue={SetMailFormValue}
            hasUserEditPermission={hasUserEditPermission}
            errorForm={errorForm}
            setErrorForm={setErrorForm}
          />
        )}
        {isOpen(MODAL_IDS.EMAIL_SETTING) && currentStepEdit === STEP_CONFIRM_EDIT && (
          <DnsSettingConfirmModal
            onHide={handleHideModal}
            isShow={isOpen(MODAL_IDS.EMAIL_SETTING) && currentStepEdit === STEP_CONFIRM_EDIT}
            isLoading={isLoading}
            isAddEmailMode={isAddEmailMode}
            tab={tab}
            steps={stepsEditMode}
            setCurrentStep={setCurrentStepEdit}
            isEdit="true"
            dnsList={dnsAdd}
            selectedItems={editItems}
            domainFormValue={domainFormValue}
            mailFormValue={mailFormValue}
            userMasterEmailData={userMasterEmailData}
            hasUserEditPermission={hasUserEditPermission}
            errorForm={errorForm}
            setErrorForm={setErrorForm}
            errorMainMessage={errorMainMessage}
            setErrorMainMessage={setErrorMainMessage}
          />
        )}
        {isOpen(MODAL_IDS.CONFIRM_DELETE) && (
          <CustomConfirmModal
            isShow
            isLoading={isLoadingDelete}
            modalWidth="w800"
            title={`${
              tab === NS_SETTING ? 'NS' : 'CNAME'
            }ドメインを削除しますか？`}
            content={
              <>
                <div className="dns-setting-note-text">下記の設定を使った計測ができなくなります。</div>
                <div className="dns-setting-alert-text">リダイレクト計測にて下記ドメインを入稿している場合、削除後に該当の広告がリンク先に遷移しなくなります。<br />詳しくは<a href={NS_CNAME_DELETE_HELP_LINK} target="_blank" rel="noopener noreferrer">こちら</a></div>
              </>
            }
            items={listItemDelete}
            forceText={[
              "ドメインを削除しても問題ありません",
              "リダイレクト計測に上記ドメインは入稿していません"
            ]}
            onSubmit={handleSubmitDelete}
            onHide={closeModals}
          />
        )}
        {isOpen(MODAL_IDS.ERROR) && (
          <ConfirmModal
            isShow
            title={error.title}
            content={error.message}
            confirmText="OK"
            cancelBtn={false}
            onSubmit={handleConfirmModal}
          />
        )}
      </DataSyncLoader>
    </div>
  );
}

DnsSettingContainer.propTypes = {
  tab: string.isRequired,
};

export default DnsSettingContainer;
