import { resolve } from 'domain/permissions/permissionTypes';
import { isItemDisplayable } from 'domain/utils';
import get from 'lodash/get';
import {
  PRIMARY_CHART_METRICS,
  SECONDARY_CHART_METRICS,
  PERIOD_TYPE_TO_API_MAP,
} from 'domain/period-analyze/consts';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import { CHANNEL_ACCESS_TYPE, PERIOD } from 'domain/fields';
import * as domainUtils from 'domain/utils';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import apiUtils from 'services/apiUtils';
import masterDataSelectors from 'store/master-data/selectors';
import displayItemsSelectors from 'store/display-items/selectors';
import {
  DISPLAY_GROUP_AGGREGATION_AXIS,
  DISPLAY_GROUP_ITEM,
} from 'domain/settings/display-items';
import * as DisplayItems from 'domain/settings/display-items';
import filterSelector from 'store/filters/selectors';
import { TAB_AD } from 'store/settings/constant';
import settingsSelector from 'store/settings/selectors';
import { getPermissions } from 'store/auth/selectors';

const chartListSelector = (state) => state.cache.periodAnalysis.data.chart.list;
const axisSelector = (state) => state.cache.periodAnalysis.settings.chart.axis;
const selectedCategoriesSelector = (state) =>
  state.cache.periodAnalysis.settings.chart.selectedCategories;
const categoriesSelector = (state) =>
  state.cache.periodAnalysis.settings.chart.categories;

const dimensionsSelector = createSelector(
  [
    displayItemsSelectors.getUserPermittedItems,
    displayItemsSelectors.getSettings,
    settingsSelector.getTab,
    settingsSelector.getPage,
    displayItemsSelectors.getDisplayItemPriorityAxis,
  ],
  (items, settingItems, currentTab, currentPage, priorityAxis) => {
    const dimensions = Object.keys(items)
      .filter(
        (key) =>
          items[key].getGroup(priorityAxis) ===
            DISPLAY_GROUP_AGGREGATION_AXIS &&
          items[key].name !== DisplayItems.PERIOD
      )
      .filter((key) => isItemDisplayable(items[key], settingItems[key]))
      .sort((key1, key2) => items[key1].order - items[key2].order);

    // Switch all(select channel only) -> ad
    // get default display DISPLAY_GROUP_AGGREGATION_AXIS
    if (dimensions.length === 0) {
      return Object.keys(items)
        .filter(
          (key) =>
            items[key].getGroup(priorityAxis) === DISPLAY_GROUP_AGGREGATION_AXIS
        )
        .filter((key) => {
          return key !== PERIOD && items[key].displayDefault;
        })
        .sort((key1, key2) => items[key1].order - items[key2].order);
    }
    return dimensions;
  }
);

const getDimensionsForCsvExport = (state) => {
  // Have to copy the same of dimensionsSelector since somehow the sort order changed.
  const items = displayItemsSelectors.getUserPermittedItems(state);
  const settingItems = displayItemsSelectors.getSettings(state);
  const priorityAxis = displayItemsSelectors.getDisplayItemPriorityAxis(state);

  const dimensions = Object.keys(items)
    .filter((key) => isItemDisplayable(items[key], settingItems[key]))
    .filter(
      (key) =>
        items[key].getGroup(priorityAxis) === DISPLAY_GROUP_AGGREGATION_AXIS &&
        key !== CHANNEL_ACCESS_TYPE
    )
    .sort((key1, key2) => items[key1].order - items[key2].order);

  if (dimensions.includes(PERIOD)) {
    return [PERIOD];
  }
  return dimensions;
};

const periodSelector = (state) => state.commonState.CommonState.settings.period;
const comparePeriodSelector = (state) =>
  state.commonState.CommonState.settings.comparedPeriod;

const chartComparedListSelector = (state) =>
  state.cache.periodAnalysis.data.chart.comparedList;
const metricsSelector = createSelector(
  [
    displayItemsSelectors.getUserPermittedItems,
    displayItemsSelectors.getSettings,
    settingsSelector.getTab,
    settingsSelector.getPage,
    displayItemsSelectors.getDisplayItemPriorityAxis,
  ],
  (items, settingItems, tab, page, priorityAxis) => {
    return Object.keys(items)
      .filter((key) => items[key].getGroup(priorityAxis) === DISPLAY_GROUP_ITEM) // only select "metrics"
      .filter((key) => isItemDisplayable(items[key], settingItems[key]))
      .sort((key1, key2) => items[key1].order - items[key2].order);
  }
);
const conversionsSelector = masterDataSelectors.conversionsForDisplaySelector;

const sortsSelector = (state) =>
  state.cache.periodAnalysis.settings.table.sorts;
const getSortsForApiRequest = (state) => {
  const dimensions = getDimensionsForCsvExport(state);
  const metrics = metricsSelector(state);
  const sorts = sortsSelector(state);
  return {
    sort:
      apiUtils.buildSort(
        domainUtils.getValidSortFields(sorts, dimensions, metrics)
      ) || 'date',
  };
};

const comparedSortsSelector = (state) =>
  state.cache.periodAnalysis.settings.table.comparedSorts;
const tableListSelector = (state) => state.cache.periodAnalysis.data.table.list;

const selectedRowsSelector = (state) =>
  state.cache.periodAnalysis.data.table.selectedRows;
const comparedSelectedRowsSelector = (state) =>
  state.cache.periodAnalysis.data.table.comparedSelectedRows;
const sumSelector = (state) => state.cache.periodAnalysis.data.table.sum;

const bookmarkSelector = (state) =>
  state.cache.periodAnalysis.settings.chart.bookmark;
const paginationSelector = (state) =>
  state.cache.periodAnalysis.settings.table.pagination;
const comparedPaginationSelector = (state) =>
  state.cache.periodAnalysis.settings.table.comparedPagination;
const visibleListSelector = (state) =>
  state.cache.periodAnalysis.settings.chart.visibleList;

const currentTabSelector = (state) => state.settings.report.tab;

const periodTypeSelector = (state) =>
  state.cache.periodAnalysis.settings.periodType;
const getPeriodTypeForApi = createSelector(
  [periodTypeSelector],
  (periodType) => PERIOD_TYPE_TO_API_MAP[periodType]
);
const memoEnabledSelector = (state) =>
  state.cache.periodAnalysis.settings.memo.enabled;
const memoHiddenSelector = (state) =>
  state.cache.periodAnalysis.settings.memo.hidden;

const tableCurrentPeriodActiveSelector = (state) =>
  state.cache.periodAnalysis.settings.table.currentPeriodActive;

const showModalSelector = (state) =>
  state.cache.periodAnalysis.settings.memo.showModal;
const showDeleteModalSelector = (state) =>
  state.cache.periodAnalysis.settings.memo.showDeleteModal;
const memoModalDataSelector = (state) =>
  state.cache.periodAnalysis.settings.memo.modalData;
const memoModalResetSelector = (state) =>
  state.cache.periodAnalysis.settings.memo.modalReset;
const memoSubmittingSelector = (state) =>
  state.cache.periodAnalysis.apiStatus.memo.submitting;

const showChartSelector = (state) =>
  state.cache.periodAnalysis.settings.display.showChart;

const memoListSelector = (state) => state.cache.periodAnalysis.data.memo.list;

const chartDisplayDataSelector = (state) =>
  state.cache.periodAnalysis.displayData.chart.list;
const chartDisplayDataComparedSelector = (state) =>
  state.cache.periodAnalysis.displayData.chart.compared;

const tableDisplayHeadersSelector = (state) =>
  state.cache.periodAnalysis.displayData.table.headers;

const tableDisplaySumSelector = (state) =>
  state.cache.periodAnalysis.displayData.table.sum;

const tableDisplayRowsSelector = (state) =>
  state.cache.periodAnalysis.displayData.table.rows;

const tableComparedDisplayHeadersSelector = (state) =>
  state.cache.periodAnalysis.displayData.table.comparedHeaders;

const tableComparedDisplaySumSelector = (state) =>
  state.cache.periodAnalysis.displayData.table.comparedSums;

const tableComparedDisplayRowsSelector = (state) =>
  state.cache.periodAnalysis.displayData.table.comparedRows;

const tableStatusSelector = (state) =>
  state.cache.periodAnalysis.apiStatus.table.status;
const chartStatusSelector = (state) =>
  state.cache.periodAnalysis.apiStatus.chart.status;

const usePeriodAxisOnlySelector = (state) => {
  const settingItems = displayItemsSelectors.getSettings(state);
  const items = displayItemsSelectors.getUserPermittedItems(state);
  return isItemDisplayable(items[PERIOD], settingItems[PERIOD]);
};

const periodRangeSelector = (state) =>
  state.cache.periodAnalysis.settings.periodRange;

const comparedPeriodRangeSelector = (state) =>
  state.cache.periodAnalysis.settings.comparedPeriodRange;

const memoErrorsSelector = (state) =>
  state.cache.periodAnalysis.data.memo.errors;

const memoErrorModalSelector = (state) =>
  state.cache.periodAnalysis.settings.memo.showErrorModal;

const getChartMetrics = (state) => {
  const userPermissions = getPermissions(state);
  const tab = settingsSelector.getTab(state);
  const displayItems =
    tab === TAB_AD
      ? DisplayItemsService.getPeriodAnalysisAd()
      : DisplayItemsService.getPeriodAnalysisAll();
  return {
    primary: PRIMARY_CHART_METRICS.filter((metric) => {
      const permissionSet = get(displayItems, `${metric}.permissionSet.${tab}`);
      if (!permissionSet) {
        return true;
      }
      return resolve(permissionSet, userPermissions);
    }),
    secondary: SECONDARY_CHART_METRICS.filter((metric) => {
      const permissionSet = get(displayItems, `${metric}.permissionSet.${tab}`);
      if (!permissionSet) {
        return true;
      }
      return resolve(permissionSet, userPermissions);
    }),
  };
};

const csvDownloadEnabledSelector = createSelector(
  [
    settingsSelector.getLoadingByStatus([
      tableStatusSelector,
      displayItemsSelectors.getStatus,
    ]),
    paginationSelector,
  ],
  (tableLoading, pagination) => {
    return !tableLoading && pagination.totalRows > 0;
  }
);

const imageDownloadEnabledSelector = createSelector(
  [
    settingsSelector.getLoadingByStatus([
      chartStatusSelector,
      displayItemsSelectors.getStatus,
    ]),
    chartDisplayDataSelector,
    showChartSelector,
  ],
  (chartFetching, chartDisplayData, showChart) => {
    return chartDisplayData.length > 0 && !chartFetching && showChart;
  }
);

export default {
  //   chartListSelector,
  axisSelector,
  selectedCategoriesSelector,
  categoriesSelector,
  periodSelector,
  comparePeriodSelector,
  //   comparedPeriodEnabledSelector,
  chartComparedListSelector,
  metricsSelector,
  conversionsSelector,
  sortsSelector,
  getSortsForApiRequest,
  tableListSelector,
  //   comparedTableListSelector,
  selectedRowsSelector,
  sumSelector,
  //   comparedSumSelector,
  bookmarkSelector,
  periodTypeSelector,
  getPeriodTypeForApi,
  memoEnabledSelector,
  memoHiddenSelector,
  chartListSelector,
  dimensionsSelector,
  getDimensionsForCsvExport,
  paginationSelector,
  visibleListSelector,
  currentTabSelector,
  tableCurrentPeriodActiveSelector,
  comparedSelectedRowsSelector,
  comparedSortsSelector,
  comparedPaginationSelector,
  showModalSelector,
  showDeleteModalSelector,
  memoModalDataSelector,
  memoListSelector,
  memoModalResetSelector,
  memoSubmittingSelector,
  showChartSelector,

  chartDisplayDataSelector,
  chartDisplayDataComparedSelector,
  tableDisplayHeadersSelector,
  tableDisplayRowsSelector,
  tableDisplaySumSelector,

  tableComparedDisplayHeadersSelector,
  tableComparedDisplayRowsSelector,
  tableComparedDisplaySumSelector,

  tableStatusSelector,
  chartStatusSelector,

  usePeriodAxisOnlySelector,
  periodRangeSelector,
  comparedPeriodRangeSelector,
  memoErrorsSelector,
  filterSelector,
  memoErrorModalSelector,
  getChartMetrics,
  csvDownloadEnabledSelector,
  imageDownloadEnabledSelector,
};

export function usePeriodAnalysisSelectors() {
  return {
    bookmarkLoaded: useSelector(bookmarkSelector).loaded,
    tableFetching: useSelector(
      settingsSelector.getLoadingByStatus([
        tableStatusSelector,
        displayItemsSelectors.getStatus,
      ])
    ),
    chartFetching: useSelector(
      settingsSelector.getLoadingByStatus([
        chartStatusSelector,
        displayItemsSelectors.getStatus,
      ])
    ),
  };
}
