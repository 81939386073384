import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import './ebis-step.scss';

function EbisStep({ steps, active }) {
  return (
    <ul className="ebis-step">
      {steps.map((item) => {
        const isActive = item.active || item.step === active;
        return (
          <li className={isActive ? 'active' : ''} key={item.step}>
            <span className="circle">{item.step}</span>
            <span className="label">{item.label}</span>
          </li>
        );
      })}
    </ul>
  );
}

EbisStep.propTypes = {
  active: oneOfType([string, number]),
  steps: arrayOf(shape({ step: number, label: string, active: bool }))
    .isRequired,
};
EbisStep.defaultProps = {
  active: '',
};

export default EbisStep;
