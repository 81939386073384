const getNotificationsList = (state) =>
  state.commonState.NotificationsState.data.viewList;

const getUnreadNotificationsList = (state) =>
  state.commonState.NotificationsState.control.notRead;

const getNewsModalData = (state) =>
  state.commonState.NotificationsState.data.newsModal;

const getNewsModalState = (state) =>
  state.commonState.NotificationsState.control.showNewsModal;

const getTargetNews = (newsList, id) => {
  return newsList.find((news) => news.id === id);
};

const getNewsModalStatus = (state) =>
  state.commonState.NotificationsState.control.status;

export default {
  getNotificationsList,
  getUnreadNotificationsList,
  getNewsModalData,
  getNewsModalState,
  getTargetNews,
  getNewsModalStatus,
};
