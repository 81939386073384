import React, { useRef, useMemo, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { shape, func, string } from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import { REPORT_FIELDS } from 'domain/data-export/consts';
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
} from 'domain/settings/display-items';

const SORT_TYPE = {
  [SORT_DIRECTION_DESC]: '降順',
  [SORT_DIRECTION_ASC]: '昇順',
};
const FIELD_DEFAULT = 'field_default';

const SortDataSetting = (props) => {
  const { item, selectedItem, error, onChange } = props;
  const {
    direction = SORT_DIRECTION_DESC,
    field = FIELD_DEFAULT,
  } = selectedItem;

  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  // add field default
  const newItem = {
    ...item,
    [FIELD_DEFAULT]: { order: -1, title: '未選択' },
  };

  // sort item
  const items = useMemo(() => {
    return Object.keys(newItem)
      .sort((key1, key2) => newItem[key1].order - newItem[key2].order)
      .map((key) => ({ ...newItem[key], key }));
  }, [newItem]);

  const handleChangeValue = (key, value) => {
    let newSort = { direction, field, [key]: value };

    if (newSort.field === FIELD_DEFAULT) {
      newSort = {};
    }

    onChange(REPORT_FIELDS.SORT, newSort);
  };

  return (
    <div className="data-export-setting__item data-export-setting__item--sortdata mb-30">
      <div className="data-export-setting__title">ソート項目</div>
      <div className="data-export-setting__content d-flex" ref={dropdownRef}>
        <ErrorTooltipWrapper
          isError={!!error}
          errorMess={error}
          ref={dropdownRef}
        >
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              variant="select"
              className={error ? 'dropdown-toggle--is-error' : ''}
            >
              {newItem[field]?.title}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-shadow">
              <div className="setting-views-dropdown__list">
                <div
                  ref={menuRef}
                  className="setting-views-dropdown__list-detail"
                >
                  <ScrollbarWrapper
                    ref={menuRef}
                    maxContent={160}
                    sizeScroll={5}
                    alignScroll={5}
                  >
                    {items.map((sort) => {
                      const classTitle = classNames({
                        'setting-views-dropdown__item-title': true,
                        'setting-views-dropdown__item-title--apply':
                          sort.key === field,
                      });
                      return (
                        <Dropdown.Item
                          as="div"
                          key={sort.key}
                          role="button"
                          bsPrefix="setting-views-dropdown__item"
                          eventKey={sort.key}
                        >
                          <div
                            aria-hidden="true"
                            className={classTitle}
                            onClick={() => handleChangeValue('field', sort.key)}
                          >
                            {sort.title}
                          </div>
                        </Dropdown.Item>
                      );
                    })}
                  </ScrollbarWrapper>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ErrorTooltipWrapper>
        <Dropdown className="sortdata-direction">
          <Dropdown.Toggle
            size="sm"
            variant="select"
            disabled={field === FIELD_DEFAULT}
          >
            {SORT_TYPE[direction]}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-shadow">
            <div className="setting-views-dropdown__list">
              <div className="setting-views-dropdown__list-detail">
                {Object.keys(SORT_TYPE).map((key) => {
                  const classTitle = classNames({
                    'setting-views-dropdown__item-title': true,
                    'setting-views-dropdown__item-title--apply':
                      key === direction,
                  });
                  return (
                    <Dropdown.Item
                      as="div"
                      key={key}
                      role="button"
                      bsPrefix="setting-views-dropdown__item"
                      eventKey={key}
                    >
                      <div
                        aria-hidden="true"
                        className={classTitle}
                        onClick={() => handleChangeValue('direction', key)}
                      >
                        {SORT_TYPE[key]}
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

SortDataSetting.propTypes = {
  item: shape({}).isRequired,
  onChange: func.isRequired,
  selectedItem: shape({}),
  error: string,
};

SortDataSetting.defaultProps = {
  selectedItem: {},
  error: '',
};

export default React.memo(SortDataSetting);
