import { isEqual, isEmpty, omit, get, cloneDeep, sortBy } from 'lodash';

import {
  TAB_ALL,
  FILTER_KEY_AMOUNT,
  FILTER_KEY_CHANNEL,
  FILTER_KEY_CHANNEL_DISPLAY,
  FILTER_KEY_CHANNEL_DISPLAY_RANGE,
  FILTER_KEY_CONTACT_HISTORY,
  FILTER_KEY_ADMNG_AD_REGISTER_DATE,
  FILTER_KEY_ADMNG_AD_UPDATE_DATE,
  FILTER_KEY_ADMNG_MEASUREMENT_TYPE,
  FILTER_KEY_ADMNG_AD_TYPE,
  FILTER_KEY_SEARCH_WORD,
  FILTER_KEY_AGENCY,
  FILTER_KEY_REGISTER_DATE,
  FILTER_KEY_REGISTER_DATE_MIN,
  FILTER_KEY_REGISTER_DATE_MAX,
  FILTER_KEY_UPDATE_DATE,
  FILTER_KEY_UPDATE_DATE_MIN,
  FILTER_KEY_UPDATE_DATE_MAX,
  FILTER_KEY_CONTENT_CATEGORY,
  FILTER_KEY_AD_DATE,
  FILTER_KEY_AD_DATE_MIN,
  FILTER_KEY_AD_DATE_MAX,
  FILTER_KEY_SEARCH_CONSOLE,
} from 'services/consts';

import * as FilterConstant from 'services/consts';
import {
  Metric,
  CHANNEL_TYPE,
  CHANNEL_TYPE_ID,
  CHANNEL_SUMMARY_KEY,
  FILTER_CONTACT_HISTORY_LENGTH,
  MEASUREMENT_TYPE_REDIRECT,
  MEASUREMENT_TYPE_DIRECT,
  UNREGISTERED_ID_FILTER_KEY,
  UNREGISTERED_DISPLAY_VALUE,
  PRIORITY_AXIS_MEDIA_SYNC,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_BOTH,
  MEDIA_ACCOUNT_UNREGISTERED_ID_FILTER_KEY,
  CAMPAIGN_GROUP_UNREGISTERED_ID_FILTER_KEY,
  UNREGISTERED_UNSYNCED_DISPLAY_VALUE,
} from 'domain/consts';
import { FilterConfig } from 'domain/commonbar/FilterConfig';
import {
  AD_GROUP1,
  AD_GROUP2,
  OTHER1,
  OTHER2,
  OTHER3,
  OTHER4,
  OTHER5,
} from 'domain/fields';
import * as FIELD from 'domain/fields';
import * as DisplayItemsAdManagement from 'domain/settings/display-items-ad-management';
import * as pages from 'services/routes/constants';
import {
  AGENCY_CONTRACT_PERMISSIONS,
  VIEW_ALL_AD_ONLY_PERMISSIONS,
  LOG_CONTRACT_PERMISSIONS,
  LOG_CONTRACT_PERMISSIONS_FOR_SETTINGS,
} from 'domain/permissions/contractGroups';

import { resolve } from 'domain/permissions/permissionTypes';
import {
  LANDING_PAGE_ANALYZE,
  CV_ATTRIBUTE,
  CV_FLOW,
  CATEGORY_ANALYZE,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
} from 'services/routes/constants';
import { changeInfoFilterPageUrl } from 'services/log/pageAnalyzeServices';
import { applyFilterOrder } from 'services/agency-management/agencyManagementService';
import { changeInfoFilter } from 'services/log/periodAnalyzeService';

const SUFFIX_COLUMN = '_id';

const getFilterableList = (
  currentTab,
  pageId,
  permissions = [],
  currentPage
) => {
  let { allFilterableList } = FilterConstant;

  if (pageId === pages.AGENCY_MANAGEMENT) {
    allFilterableList = applyFilterOrder(allFilterableList);
  }

  return Object.keys(allFilterableList).reduce((filterableList, key) => {
    const filter = allFilterableList[key];
    let allowTab = true;
    if (filter.allowTab) {
      allowTab = filter.allowTab.indexOf(currentTab) !== -1;
    }
    let allowScreen = true;
    if (filter.allowScreen) {
      allowScreen = filter.allowScreen.indexOf(pageId) !== -1;
    }
    if ('denyScreen' in filter) {
      allowScreen = filter.denyScreen.indexOf(pageId) === -1 && allowScreen;
    }
    if (
      currentPage === pages.DATA_EXPORT &&
      key === FILTER_KEY_SEARCH_CONSOLE
    ) {
      allowScreen = false;
    }

    // Check permission
    let hasPermission = true;
    if (
      'permissionSet' in filter &&
      filter.permissionSet[pageId]?.[currentTab]
    ) {
      hasPermission = resolve(
        filter.permissionSet[pageId][currentTab],
        permissions
      );
    }

    const denyFilter = {
      [FILTER_KEY_AGENCY]: !resolve(AGENCY_CONTRACT_PERMISSIONS, permissions),
      [FILTER_KEY_SEARCH_WORD]: resolve(
        VIEW_ALL_AD_ONLY_PERMISSIONS,
        permissions
      ),
    };
    if (key in denyFilter && denyFilter[key]) {
      hasPermission = false;
    }

    if (allowTab && allowScreen && hasPermission) {
      return { ...filterableList, [key]: filter };
    }

    return filterableList;
  }, {});
};

const getBy = ({ currentTab, pageId, display, permissions, currentPage }) => {
  const filterableList = getFilterableList(
    currentTab,
    pageId,
    permissions,
    currentPage
  );
  // Merge setting label
  Object.keys(filterableList).forEach((key) => {
    if (pageId === pages.LOG_PERIOD_ANALYZE) {
      filterableList[key] = changeInfoFilter(key, filterableList[key]);
    }

    switch (key) {
      case FilterConstant.FILTER_KEY_AD_GROUP1:
      case FilterConstant.FILTER_KEY_AD_GROUP1_EXCLUDE:
        filterableList[key].title = display[AD_GROUP1];
        break;

      case FilterConstant.FILTER_KEY_AD_GROUP2:
      case FilterConstant.FILTER_KEY_AD_GROUP2_EXCLUDE:
        filterableList[key].title = display[AD_GROUP2];
        break;

      case FilterConstant.FILTER_KEY_OTHER1:
      case FilterConstant.FILTER_KEY_OTHER1_EXCLUDE:
        filterableList[key].title = display[OTHER1];
        break;

      case FilterConstant.FILTER_KEY_OTHER2:
      case FilterConstant.FILTER_KEY_OTHER2_EXCLUDE:
        filterableList[key].title = display[OTHER2];
        break;

      case FilterConstant.FILTER_KEY_OTHER3:
      case FilterConstant.FILTER_KEY_OTHER3_EXCLUDE:
        filterableList[key].title = display[OTHER3];
        break;

      case FilterConstant.FILTER_KEY_OTHER4:
      case FilterConstant.FILTER_KEY_OTHER4_EXCLUDE:
        filterableList[key].title = display[OTHER4];
        break;

      case FilterConstant.FILTER_KEY_OTHER5:
      case FilterConstant.FILTER_KEY_OTHER5_EXCLUDE:
        filterableList[key].title = display[OTHER5];
        break;

      case FilterConstant.FILTER_KEY_PAGE_URL:
        if ([pages.LOG_PAGE_ANALYZE].includes(pageId)) {
          filterableList[key] = changeInfoFilterPageUrl(filterableList[key]);
        }
        break;

      default:
        if (display[key]) {
          filterableList[key].title = display[key];
        }
        break;
    }
  });

  return filterableList;
};

const getFilterByName = (name) => {
  let keyName = name;
  if (name.includes('amount')) {
    keyName = 'amount';
  } else if (name === FILTER_KEY_CHANNEL_DISPLAY_RANGE) {
    // For the new display channel filter, use the old display channel filter.
    keyName = FILTER_KEY_CHANNEL_DISPLAY;
  }

  return FilterConstant.allFilterableList[keyName]
    ? FilterConstant.allFilterableList[keyName]
    : null;
};

const getMaster = (key) => {
  let items;
  switch (key) {
    case Metric.device.key:
      items = FilterConfig.devices;
      break;
    case Metric.displayChannel.key:
      items = FilterConfig.displayChannels;
      break;
    case Metric.displayChannelRange.key:
      items = FilterConfig.displayChannelsRange;
      break;
    case Metric.summaryChannel.key:
      items = FilterConfig.summaryChannels;
      break;
    case Metric.summaryAllChannels.key:
      items = FilterConfig.summaryAllChannels;
      break;
    default:
      items = [];
      break;
  }
  return items;
};

const splitTwoValues = (values, fields, operator) => {
  const [firstValue, secondValue] = values;
  const [firstField, secondField] = fields;
  let field = '';
  let value = '';
  let filterSecond = {};
  if (firstValue && secondValue) {
    field = firstField;
    value = firstValue;
    filterSecond = {
      field: secondField,
      value: secondValue,
      operator,
    };
  } else if (firstValue) {
    field = firstField;
    value = firstValue;
  } else if (secondValue) {
    field = secondField;
    value = secondValue;
  }

  return { field, value, filterSecond };
};

const parseCountChannelValues = (channelRange) => {
  const values = {};
  Object.keys(channelRange.values).forEach((key) => {
    values[key] = {
      min: Number.parseInt(channelRange.values[key].min, 10),
      max: Number.parseInt(channelRange.values[key].max, 10),
    };
  });
  return values;
};

const acceptFilterWithAxis = (priorityAxis, key) => {
  return (
    (priorityAxis !== PRIORITY_AXIS_MEDIA_SYNC &&
      priorityAxis !== PRIORITY_AXIS_EBIS) ||
    (priorityAxis === PRIORITY_AXIS_MEDIA_SYNC &&
      !FilterConstant.ONLY_EBIS_AXIS_FILTERS.includes(key)) ||
    (priorityAxis === PRIORITY_AXIS_EBIS &&
      !FilterConstant.ONLY_MEDIA_SYNC_AXIS_FILTERS.includes(key))
  );
};

const prepareForRequestBody = ({ pageId, filters }) => {
  const newfilters = filters.reduce((acc, filter) => {
    // Ignore special field
    if (
      pageId !== pages.CV_ATTRIBUTE &&
      filter.field === FILTER_KEY_CHANNEL_DISPLAY_RANGE
    ) {
      return acc;
    }

    return [...acc, filter];
  }, []);

  // Exclude the filter for new display channels when getting list data other than the CV attribute screen
  if (pageId === pages.CV_ATTRIBUTE) {
    const displayChannels = filters.filter((value) => {
      return value.field === FILTER_KEY_CHANNEL_DISPLAY;
    });
    if (displayChannels.length === 1) {
      const displayChannelRanges = filters.filter((value) => {
        return value.field === FILTER_KEY_CHANNEL_DISPLAY_RANGE;
      });
      const [displayChannel] = displayChannels;
      if (displayChannelRanges.length === 0) {
        const displayChannelRange = cloneDeep(displayChannel);
        displayChannelRange.value = {};
        displayChannelRange.field = FILTER_KEY_CHANNEL_DISPLAY_RANGE;
        displayChannel.value.forEach((value) => {
          displayChannelRange.value[value] = {
            min: null,
            max: null,
          };
        });
        newfilters.push(displayChannelRange);
      }
    }
  }

  return newfilters;
};

const prepareForApi = (filters, priorityAxis = PRIORITY_AXIS_BOTH) => {
  let filtersNomalized = [];
  const filtersSecond = [];
  filtersNomalized = Object.keys(filters)
    .filter((key) => {
      return acceptFilterWithAxis(priorityAxis, key);
    })
    .map((key) => {
      const filter = filters[key];
      let value;
      let field = key;
      if ([1, 3, 4].includes(filter.type) && 'ids' in filter && filter.ids) {
        // Filter list master using id
        value = filter.ids;
        switch (key) {
          case FILTER_KEY_CHANNEL:
            if (isEqual(filter.ids, ['1'])) {
              value = ['10', '20', '30', '40'];
            } else if (isEqual(filter.ids, ['2'])) {
              value = ['10', '20'];
            } else if (isEqual(filter.ids, ['3'])) {
              value = ['10', '20', '30', '40', '50'];
            }
            break;
          case FILTER_KEY_ADMNG_MEASUREMENT_TYPE:
            if (isEqual(filter.ids, ['0'])) {
              value = [MEASUREMENT_TYPE_REDIRECT, MEASUREMENT_TYPE_DIRECT];
            }
            break;
          case FILTER_KEY_SEARCH_CONSOLE:
            if (isEqual(filter.ids, ['1'])) {
              value = '1';
            }
            break;
          case FILTER_KEY_ADMNG_AD_TYPE:
            value = filter.ids[0] || '';
            break;
          case FILTER_KEY_CHANNEL_DISPLAY: {
            value = filter.ids;
            break;
          }
          default:
            value = filter.ids;
            break;
        }
      } else {
        switch (key) {
          case FILTER_KEY_AMOUNT: {
            const results = splitTwoValues(
              filter.values,
              ['amount_min', 'amount_max'],
              filter.option
            );
            field = results.field;
            value = parseInt(results.value, 10);
            if (!isEmpty(results.filterSecond)) {
              filtersSecond.push({
                ...results.filterSecond,
                value: parseInt(results.filterSecond.value, 10),
              });
            }
            break;
          }

          case FILTER_KEY_AD_DATE:
          case FILTER_KEY_REGISTER_DATE:
          case FILTER_KEY_UPDATE_DATE:
          case FILTER_KEY_ADMNG_AD_UPDATE_DATE:
          case FILTER_KEY_ADMNG_AD_REGISTER_DATE: {
            const fields = {
              [FILTER_KEY_AD_DATE]: [
                FILTER_KEY_AD_DATE_MIN,
                FILTER_KEY_AD_DATE_MAX,
              ],
              [FILTER_KEY_REGISTER_DATE]: [
                FILTER_KEY_REGISTER_DATE_MIN,
                FILTER_KEY_REGISTER_DATE_MAX,
              ],
              [FILTER_KEY_UPDATE_DATE]: [
                FILTER_KEY_UPDATE_DATE_MIN,
                FILTER_KEY_UPDATE_DATE_MAX,
              ],
              [FILTER_KEY_ADMNG_AD_REGISTER_DATE]: [
                FILTER_KEY_REGISTER_DATE_MIN,
                FILTER_KEY_REGISTER_DATE_MAX,
              ],
              [FILTER_KEY_ADMNG_AD_UPDATE_DATE]: [
                FILTER_KEY_UPDATE_DATE_MIN,
                FILTER_KEY_UPDATE_DATE_MAX,
              ],
            }[key];

            const results = splitTwoValues(
              filter.values,
              fields,
              filter.option
            );
            field = results.field;
            value = results.value;
            if (!isEmpty(results.filterSecond)) {
              filtersSecond.push(results.filterSecond);
            }
            break;
          }
          case FILTER_KEY_CHANNEL_DISPLAY_RANGE: {
            value = parseCountChannelValues(filter);
            break;
          }
          default:
            value = filter.values;
            break;
        }
      }

      return {
        field,
        value,
        operator: filter.option,
      };
    });

  return [...filtersNomalized, ...filtersSecond];
};

const prepareForUI = (filters, pageId, currentTab) => {
  const isCvPages = [LANDING_PAGE_ANALYZE, CV_ATTRIBUTE, CV_FLOW].includes(
    pageId
  );
  // Analysis screen will also be supported to disable filters.
  const isDisabledFilterPages = [
    CATEGORY_ANALYZE,
    DETAILS_ANALYSIS,
    COMPARE_PERIOD,
  ].includes(pageId);

  const newFilters = Object.keys(filters).reduce((acc, key) => {
    const filter = { ...filters[key] };
    let isIgnoreFilter = false;
    const { SITE_CONTENT } = CHANNEL_TYPE_ID;
    const { CLICK_SEARCH_REFERRAL_DIRECT_SITECONTENT } = CHANNEL_SUMMARY_KEY;

    switch (key) {
      case FILTER_KEY_CHANNEL:
        if (
          !isCvPages &&
          !isDisabledFilterPages &&
          (filter.ids.includes(CLICK_SEARCH_REFERRAL_DIRECT_SITECONTENT) ||
            filter.ids.includes(SITE_CONTENT))
        ) {
          isIgnoreFilter = true;
        }
        break;

      case FILTER_KEY_CHANNEL_DISPLAY:
        if (!isCvPages && filter.ids.includes(SITE_CONTENT)) {
          if (filter.ids.length === 1) {
            isIgnoreFilter = true;
          } else {
            const ids = filters[key].ids.filter((id) => id !== SITE_CONTENT);
            const values = filters[key].values.filter(
              (name) => name !== CHANNEL_TYPE.site_content
            );
            return { ...acc, [key]: { ...filter, ids, values } };
          }
        }
        break;

      case FILTER_KEY_CHANNEL_DISPLAY_RANGE:
        if (!isCvPages || currentTab !== TAB_ALL) {
          isIgnoreFilter = true;
        }
        break;

      default:
        break;
    }

    if (isIgnoreFilter) {
      return { ...acc };
    }

    return { ...acc, [key]: { ...filter } };
  }, {});

  return newFilters;
};

const uiMapperData = (data) => {
  return Object.keys(data)
    .map((id) => {
      const { name, order } = data[id];
      return {
        key: id,
        value: name,
        id,
        name,
        order,
      };
    })
    .sort((o1, o2) => o1.order - o2.order);
};

const isFilterChanged = (
  filters,
  screenId,
  currentTab,
  permissions,
  priorityAxis
) => {
  const filterableList = getFilterableList(currentTab, screenId, permissions);
  return (
    Object.keys(filters)
      .filter((key) => acceptFilterWithAxis(priorityAxis, key))
      .filter((key) => filterableList[key])
      .filter(
        (filterKey) =>
          filterKey !== FILTER_KEY_CONTACT_HISTORY ||
          filters[filterKey].values.length !== FILTER_CONTACT_HISTORY_LENGTH
      ).length > 0
  );
};

const filterNonContractValues = (
  filterValues,
  permissions,
  pageId = '',
  currentTab = ''
) => {
  const keys = Object.keys(filterValues);

  // Check permission
  const allowedPemissions = {
    [FILTER_KEY_AGENCY]: resolve(AGENCY_CONTRACT_PERMISSIONS, permissions),
    [FILTER_KEY_SEARCH_WORD]: !resolve(
      VIEW_ALL_AD_ONLY_PERMISSIONS,
      permissions
    ),
  };
  if (pageId && currentTab) {
    const logContract =
      pageId === pages.TAG_MANAGEMENT
        ? LOG_CONTRACT_PERMISSIONS_FOR_SETTINGS
        : LOG_CONTRACT_PERMISSIONS[currentTab];

    allowedPemissions[FILTER_KEY_CONTENT_CATEGORY] = resolve(
      logContract,
      permissions
    );
  }

  return keys.reduce((acc, key) => {
    const master = getMaster(key);
    const filter = filterValues[key] || {};

    // Check allow filter on screen or tab
    let allowScreen = true;
    if (!isEmpty(pageId) && filter.allowScreen) {
      allowScreen = filter.allowScreen.indexOf(pageId) !== -1;
    }
    let allowTab = true;
    if (!isEmpty(currentTab) && filter.allowTab) {
      allowTab = filter.allowTab.indexOf(currentTab) !== -1;
    }

    const hasPermission = get(allowedPemissions, key, true);

    if (!allowScreen || !allowTab || !hasPermission) {
      return acc;
    }

    switch (key) {
      case FILTER_KEY_CHANNEL: {
        const value = filter.values[0] || '';
        const channelValue = master.find((item) => isEqual(item.value, value));
        let valuePermissions = false;
        if (channelValue.permissionSet) {
          valuePermissions = channelValue.permissionSet[TAB_ALL] || false;
        }

        if (!valuePermissions || resolve(valuePermissions, permissions)) {
          acc[key] = filter;
        }
        break;
      }
      case FILTER_KEY_CHANNEL_DISPLAY: {
        // INFO: based on UI selections, ids are not sorted but values are sorted
        const ids = filterValues.display_channel?.ids || [];
        // ids = [...ids].sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

        const filteredValues = [];
        // filter ids restricted by contract
        const filteredIds = ids.filter((id, idIndex) => {
          const target = master.find((item) => item.key === id);
          const valuePermissions = target.permissionSet?.[TAB_ALL] || false;

          if (!valuePermissions || resolve(valuePermissions, permissions)) {
            filteredValues.push(filterValues.display_channel.values[idIndex]);
            return id;
          }
          // filter removed values with ids
          return false;
        });

        if (filteredIds.length === 0) {
          return acc;
        }

        acc[key] = {
          ...filter,
          ids: filteredIds,
          values: filteredValues,
        };
        break;
      }
      case FILTER_KEY_CHANNEL_DISPLAY_RANGE: {
        // INFO: based on UI selections, ids are not sorted but values are sorted
        const values = filterValues.display_channel_range.values || [];
        const filteredValues = {};
        // filter ids restricted by contract
        Object.keys(values).map((mapkey) => {
          const target = master.find((item) => item.key === mapkey);
          const valuePermissions = target.permissionSet?.[TAB_ALL] || false;
          const value = values[mapkey];
          if (!valuePermissions || resolve(valuePermissions, permissions)) {
            filteredValues[mapkey] = value;
            return mapkey;
          }
          // filter removed values with ids
          return false;
        });

        if (Object.keys(filteredValues).length === 0) {
          return acc;
        }

        acc[key] = {
          ...filter,
          ids: null,
          values: filteredValues,
        };
        break;
      }
      default:
        acc[key] = filter;
    }

    return acc;
  }, {});
};

const getForComparison = (filters) => {
  return Object.keys(filters).reduce((acc, key) => {
    acc[key] = omit(filters[key], ['title', 'type']);
    return acc;
  }, {});
};

const isSameFilter = (filter, otherFilter) => {
  const forComparisonFilter = getForComparison(filter);
  const forComparisonOtherFilter = getForComparison(otherFilter);
  return isEqual(forComparisonFilter, forComparisonOtherFilter);
};

/**
 * Get Merged Obj, only change enable current key and keep another key
 * @param {*} filterableList  All enable filter of current page
 * @param {*} currentFilters current object filter in state
 * @param {*} filters after update object
 * @returns Obj only change the current key, keep another key
 */
const getMergedObj = (filterableList, currentFilters, filters) => {
  const before = { ...currentFilters };
  Object.keys(filterableList).map((key) => {
    if (before[key] && !filters[key]) {
      delete before[key];
    } else if (filters[key]) {
      before[key] = filters[key];
    }
    return true;
  });
  return before;
};

/**
 * Combine data filter
 * @param {*} currentFilter current object filter in state
 * @param {`key` : [{id: '', name: ''}]} actualFilter
 * @returns new object filters
 */
const combineFilters = (currentFilter, actualFilter) => {
  return Object.keys(actualFilter).reduce((acc, key) => {
    if (!currentFilter[key]) return acc;

    const filter = actualFilter[key].reduce(
      (obj, data) => {
        return {
          ids: [...obj.ids, `${data.id}`],
          values: [...obj.values, data.name],
        };
      },
      { ids: [], values: [] }
    );

    const ids = currentFilter[key].ids.filter(
      (id) =>
        id === UNREGISTERED_ID_FILTER_KEY ||
        id ===
          `${UNREGISTERED_ID_FILTER_KEY}:${MEDIA_ACCOUNT_UNREGISTERED_ID_FILTER_KEY}` ||
        id ===
          `${UNREGISTERED_ID_FILTER_KEY}:${CAMPAIGN_GROUP_UNREGISTERED_ID_FILTER_KEY}` ||
        filter.ids.includes(id)
    );
    const values = currentFilter[key].values.filter(
      (name) =>
        name === UNREGISTERED_DISPLAY_VALUE ||
        name === UNREGISTERED_UNSYNCED_DISPLAY_VALUE ||
        filter.values.includes(name)
    );

    if (ids.length <= 0) {
      return omit(acc, key);
    }

    return {
      ...acc,
      [key]: {
        ...acc[key],
        ids,
        values,
      },
    };
  }, currentFilter);
};

/**
 * Prepare a mapping between the filter key list and the table's display column name
 * @param {Object} filters
 * @param {string} pageId
 * @returns {array}
 */
const prepareFilterKeyMapping = (filters, pageId, priorityAxis) => {
  const filterKeyList = [];
  Object.keys(filters).forEach((key) => {
    switch (key) {
      case FilterConstant.FILTER_KEY_AD_GROUP1:
      case FilterConstant.FILTER_KEY_AD_GROUP2:
      case FilterConstant.FILTER_KEY_MEDIA_SIDE_GROUP:
      case FilterConstant.FILTER_KEY_MEDIA_SIDE_CAMPAIGN:
        filterKeyList.push(key.replace(SUFFIX_COLUMN, ''));
        break;
      case FilterConstant.FILTER_KEY_MEDIA: {
        const field =
          pageId === pages.AD_MANAGEMENT || pageId === pages.AGENCY_MANAGEMENT
            ? DisplayItemsAdManagement.MEDIA_ID
            : FIELD.CATEGORY;
        filterKeyList.push(field);
        break;
      }
      case FilterConstant.FILTER_KEY_MEDIA_ACCOUNT: {
        const field =
          pageId === pages.AD_MANAGEMENT
            ? DisplayItemsAdManagement.MEDIA_ACCOUNT
            : FIELD.SYNC_CATEGORY;
        filterKeyList.push(field);
        break;
      }
      case FilterConstant.FILTER_KEY_CV:
        filterKeyList.push(FIELD.CV_NAME);
        break;
      case FilterConstant.FILTER_KEY_LANDING_PAGE_URL:
        filterKeyList.push(FIELD.LANDING_PAGE_DOMAIN);
        break;
      case FilterConstant.FILTER_KEY_REGISTER_DATE:
      case FilterConstant.FILTER_KEY_ADMNG_AD_REGISTER_DATE:
        filterKeyList.push(DisplayItemsAdManagement.REGISTER_DATE);
        break;
      case FilterConstant.FILTER_KEY_UPDATE_DATE:
      case FilterConstant.FILTER_KEY_ADMNG_AD_UPDATE_DATE:
        filterKeyList.push(DisplayItemsAdManagement.UPDATED_DATE);
        break;
      case FilterConstant.FILTER_KEY_LTV_PRODUCT_NAME: {
        const field = pageId === pages.LTV_ANALYZE ? FIELD.ITEM : key;
        filterKeyList.push(field);
        break;
      }
      case FilterConstant.FILTER_KEY_LTV_OFFER_NAME: {
        const field = pageId === pages.LTV_ANALYZE ? FIELD.OFFER : key;
        filterKeyList.push(field);
        break;
      }
      case FilterConstant.FILTER_KEY_AD_ID: {
        if (priorityAxis !== PRIORITY_AXIS_MEDIA_SYNC) {
          const field =
            pageId === pages.LTV_SETTINGS_AD ? FIELD.LINKED_AD_ID : key;
          filterKeyList.push(field);
        }
        break;
      }
      case FilterConstant.FILTER_KEY_MEDIA_SIDE_AD_ID: {
        if (priorityAxis !== PRIORITY_AXIS_EBIS) {
          const field = FIELD.MEDIA_SIDE_AD_ID;
          filterKeyList.push(field);
        }
        break;
      }
      case FilterConstant.FILTER_KEY_MEDIA_GROUP_MEDIA_ID:
      case FilterConstant.FILTER_KEY_MEDIA_GROUP_AD_GROUP1_ID:
      case FilterConstant.FILTER_KEY_MEDIA_GROUP_AD_GROUP2_ID:
        filterKeyList.push(FIELD.MEDIA_AGROUP12_ID);
        break;
      case FilterConstant.FILTER_KEY_MEDIA_GROUP_MEDIA_NAME:
      case FilterConstant.FILTER_KEY_MEDIA_GROUP_AD_GROUP1_NAME:
      case FilterConstant.FILTER_KEY_MEDIA_GROUP_AD_GROUP2_NAME:
        filterKeyList.push(FIELD.MEDIA_ADGROUP12_NAME);
        break;
      case FilterConstant.FILTER_KEY_CONTENT_CATEGORY: {
        filterKeyList.push(FIELD.CONTENT_CATEGORY);
        break;
      }
      case FilterConstant.FILTER_KEY_CONTACT_HISTORY:
        if (pageId !== pages.CV_ATTRIBUTE && pageId !== pages.CV_FLOW) return;

        filters[FilterConstant.FILTER_KEY_CONTACT_HISTORY].values.forEach(
          (keyContactHistory) => {
            if (FilterConstant.FILTER_KEY_CHANNEL in filters) {
              filterKeyList.push(`contact_${keyContactHistory}_channel`);
            }
            if (FilterConstant.FILTER_KEY_MEDIA in filters) {
              filterKeyList.push(
                `contact_${keyContactHistory}_${FIELD.CATEGORY}`
              );
            }
            if (FilterConstant.FILTER_KEY_AD_GROUP1 in filters) {
              filterKeyList.push(
                `contact_${keyContactHistory}_${FIELD.AD_GROUP1}`
              );
            }
            if (FilterConstant.FILTER_KEY_AD_GROUP2 in filters) {
              filterKeyList.push(
                `contact_${keyContactHistory}_${FIELD.AD_GROUP2}`
              );
            }
            if (FilterConstant.FILTER_KEY_AD_ID in filters) {
              if (priorityAxis === PRIORITY_AXIS_EBIS) {
                filterKeyList.push(
                  `contact_${keyContactHistory}_${FIELD.AD_ID}`
                );
              }
            }
            if (FilterConstant.FILTER_KEY_AD_NAME in filters) {
              filterKeyList.push(
                `contact_${keyContactHistory}_${FIELD.AD_NAME}`
              );
            }
            if (FilterConstant.FILTER_KEY_LANDING_PAGE_URL in filters) {
              filterKeyList.push(
                `contact_${keyContactHistory}_${FIELD.LANDING_PAGE_DOMAIN}`
              );
            }
            if (FilterConstant.FILTER_KEY_MEDIA_ACCOUNT in filters) {
              filterKeyList.push(
                `contact_${keyContactHistory}_${FIELD.SYNC_CATEGORY}`
              );
            }
            if (FilterConstant.FILTER_KEY_MEDIA_SIDE_CAMPAIGN in filters) {
              filterKeyList.push(
                `contact_${keyContactHistory}_${FIELD.MEDIA_SIDE_CAMPAIGN}`
              );
            }
            if (FilterConstant.FILTER_KEY_MEDIA_SIDE_GROUP in filters) {
              filterKeyList.push(
                `contact_${keyContactHistory}_${FIELD.MEDIA_SIDE_GROUP}`
              );
            }
            if (FilterConstant.FILTER_KEY_MEDIA_SIDE_AD_ID in filters) {
              if (priorityAxis === PRIORITY_AXIS_MEDIA_SYNC) {
                filterKeyList.push(
                  `contact_${keyContactHistory}_${FIELD.MEDIA_SIDE_AD_ID}`
                );
              }
            }
            if (FilterConstant.FILTER_KEY_MEDIA_SIDE_AD_NAME in filters) {
              filterKeyList.push(
                `contact_${keyContactHistory}_${FIELD.MEDIA_SIDE_AD_NAME}`
              );
            }
          }
        );
        break;
      case FILTER_KEY_AGENCY:
        filterKeyList.push(FIELD.AGENCY_NAME);
        break;
      default:
        filterKeyList.push(key);
        break;
    }
  });

  return filterKeyList;
};

const createPairKey = (ebisKey, syncKey) => {
  return `${ebisKey}:${syncKey}`;
};

const parsePairKeyIds = (ids) => {
  const mediaSideItemsIds = [];
  const ebisItemsIds = [];
  ids.map((id) => {
    const idPair = id.split(':');
    if (idPair[0] !== 'n') {
      ebisItemsIds.push(idPair[0]);
    }
    if (idPair[1] !== 'n') {
      const [, ...compositeKeys] = idPair;
      mediaSideItemsIds.push(compositeKeys.join(':'));
    }
    return id;
  });
  return {
    ebisItemsIds,
    mediaSideItemsIds,
  };
};

const tryParsePairKeyIds = (ids, filterKey) => {
  if (ids.length === 0) {
    return {
      ebisItemsIds: [],
      mediaSideItemsIds: ids,
    };
  }
  const id = ids[0];
  const pairKey = id.split(':');
  // 2つ以上に分割できなければ複合キーではない。
  if (pairKey.length < 2) {
    return {
      ebisItemsIds: [],
      mediaSideItemsIds: ids,
    };
  }
  // どちらかがnなら複合キー確定
  if (pairKey[0] === 'n' || pairKey[1] === 'n') {
    return parsePairKeyIds(ids);
  }
  //
  switch (filterKey) {
    case FilterConstant.FILTER_KEY_MEDIA_ACCOUNT:
      // 媒体種別ID(エビス軸):広告主ID:媒体IDとなっていたら複合キー
      if (pairKey.length === 3) {
        return parsePairKeyIds(ids);
      }
      break;
    case FilterConstant.FILTER_KEY_MEDIA_SIDE_CAMPAIGN:
    case FilterConstant.FILTER_KEY_MEDIA_SIDE_GROUP:
      // 広告グループID(エビス軸):広告主ID:媒体ID:媒体アイテムIDとなっていたら複合キー
      if (pairKey.length === 4) {
        return parsePairKeyIds(ids);
      }
      break;
    default:
      break;
  }
  return {
    ebisItemsIds: [],
    mediaSideItemsIds: ids,
  };
};

const createPairKeyItems = (mediaSideItems, ebisItems) => {
  const mergedItems = [];
  const makeNames = (previous, current) => {
    return {
      ...previous,
      [current.id]: current,
    };
  };

  const mediaSideItemsNames = mediaSideItems.reduce(makeNames, {});
  const ebisItemsNames = ebisItems.reduce(makeNames, {});

  const duplicateEbisIds = [];
  const duplicateSyncIds = [];

  Object.keys(mediaSideItemsNames).forEach((syncKey) => {
    Object.keys(ebisItemsNames).forEach((ebisKey) => {
      if (!mediaSideItemsNames[syncKey].name || !ebisItemsNames[ebisKey].name) {
        return;
      }
      if (
        mediaSideItemsNames[syncKey].name === ebisItemsNames[ebisKey].name ||
        (mediaSideItemsNames[syncKey].name ===
          UNREGISTERED_UNSYNCED_DISPLAY_VALUE &&
          ebisItemsNames[ebisKey].name === UNREGISTERED_DISPLAY_VALUE)
      ) {
        const key = createPairKey(ebisKey, syncKey);
        mergedItems.push({
          ...mediaSideItemsNames[syncKey],
          key,
          id: key,
        });
        duplicateEbisIds.push(ebisKey);
        duplicateSyncIds.push(syncKey);
      }
    });
  });

  mediaSideItems
    .filter((value) => {
      return !duplicateSyncIds.includes(value.id);
    })
    .map((value) => {
      const key = createPairKey('n', value.id);
      mergedItems.push({
        ...value,
        key,
        id: key,
      });
      return key;
    });

  //
  ebisItems
    .filter((value) => {
      return !duplicateEbisIds.includes(value.id);
    })
    .map((value) => {
      const key = createPairKey(value.id, 'n');
      mergedItems.push({
        ...value,
        key,
        id: key,
      });
      return key;
    });
  return mergedItems;
};

const convertToEbisMasterNameFromMediaSideItemsMasterName = (key) => {
  const keys = {
    media_side_campaign_id: 'ad_group1_id',
    media_side_group_id: 'ad_group2_id',
    media_account_id: 'media_id',
  };
  if (key in keys) {
    return keys[key];
  }
  return '';
};

const getPairKeysFromName = (name, mediaSideItems, ebisItems) => {
  const pairKeysItems = createPairKeyItems(mediaSideItems, ebisItems);
  return pairKeysItems
    .filter((pairKeyItem) => name === pairKeyItem.value)
    .map((pairKeyItem) => pairKeyItem.id);
};

const getMasterDataIdsSelected = (filters = []) => {
  const masterDataIds = {
    conv_id: [],
    media_id: [],
    ad_group1_id: [],
    ad_group2_id: [],
    agency_id: [],
    content_category_id: [],
  };

  filters.forEach(({ field, value }) => {
    if (field in masterDataIds && Array.isArray(value) && value.length > 0) {
      masterDataIds[field] = value;
    }
  });

  return masterDataIds;
};

const okButtonEnabledType8 = (filter) => {
  let enable = true;
  enable = filter && filter.values && Object.keys(filter.values).length > 0;
  if (!enable) {
    return enable;
  }

  return enable;
};

const getEnabledOkButton = ({
  type,
  filter,
  pageId,
  name,
  presets,
  maxItemSelect,
  filterInfo,
}) => {
  switch (type) {
    case 1:
    case 3: {
      if (
        pageId === pages.CV_ATTRIBUTE &&
        (name === FILTER_KEY_CHANNEL_DISPLAY ||
          name === FILTER_KEY_CHANNEL_DISPLAY_RANGE)
      ) {
        return okButtonEnabledType8(filter);
      }
      const values = filter?.ids || filter?.values;
      // handle for case filter type 3 only have 1 option to select and when user uncheck => will be enabled for user click button OK
      if ([FILTER_KEY_SEARCH_CONSOLE].includes(name)) {
        const enabledOkButton =
          values &&
          (!isEmpty(presets)
            ? !isEqual(sortBy(presets.ids), sortBy(values)) // !!! Don't use values.sort() since it will change array
            : true);
        return enabledOkButton;
      }
      return (
        values &&
        values.length > 0 &&
        values.length <= maxItemSelect &&
        (!isEmpty(presets)
          ? !isEqual(sortBy(presets.ids), sortBy(values)) // !!! Don't use values.sort() since it will change array
          : true)
      );
    }
    case 2: {
      const { multiple = false } = filterInfo;
      const isEnable =
        (filter &&
          filter.values &&
          filter.values.length > 0 &&
          !isEmpty(filter.values[0])) ||
        filter?.option === FilterConstant.FILTER_OPERATOR_UNREGISTER;

      if (multiple && isEnable) {
        const isFilledData = filter.values.every(
          (item) => !isEmpty(item.values.filter((str) => !isEmpty(str)))
        );
        return isFilledData;
      }
      return isEnable;
    }
    case 4:
      return (
        filter &&
        filter.values &&
        filter.values.length > 0 &&
        filter.values[0].length
      );
    case 5:
      return (
        filter &&
        filter.values &&
        filter.values.length > 1 &&
        (filter.values[0] || filter.values[1])
      );
    case 6:
      return (
        filter &&
        filter.values &&
        filter.values.length > 1 &&
        (filter.values[0] || filter.values[1])
      );
    case 7:
      return (
        filter &&
        filter.values &&
        filter.values.length > 0 &&
        filter.values[0].length &&
        filter.values[filter.values.length - 1].length
      );
    case 8:
      return okButtonEnabledType8(filter);
    case 9:
      return okButtonEnabledType8(filter);
    case 10:
    case 11:
      return filter?.values?.length > 0;
    default:
      return false;
  }
};

export const FilterServiceFactory = () => ({
  getFilterableList,
  getBy,
  getFilterByName,
  getMaster,
  prepareForRequestBody,
  prepareForApi,
  prepareForUI,
  uiMapperData,
  isFilterChanged,
  filterNonContractValues,
  isSameFilter,
  getMergedObj,
  combineFilters,
  prepareFilterKeyMapping,
  acceptFilterWithAxis,
  parsePairKeyIds,
  createPairKeyItems,
  createPairKey,
  tryParsePairKeyIds,
  convertToEbisMasterNameFromMediaSideItemsMasterName,
  getPairKeysFromName,
  getMasterDataIdsSelected,
  getEnabledOkButton,
});

const FilterService = FilterServiceFactory();
export default FilterService;
