import {
  takeLatest,
  takeEvery,
  call,
  select,
  fork,
  put,
  delay,
} from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import types from 'store/common/types';
import {
  API_DATE_FORMAT,
  API_UNLIMITED_VALUE,
  EXPORT_TYPE,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_MEDIA_SYNC,
} from 'domain/consts';
import {
  exportImage,
  saveAs,
  communicationStatus,
  checkUrlReachable,
} from 'services/utils';
import { getDownloadImageName } from 'domain/utils';
import {
  CHANNEL,
  CROSSDEVICE_DIFF_COMPARISON,
} from 'domain/settings/display-items';
import {
  CATEGORY_ANALYZE,
  COST_ALLOCATION,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
  CV_ATTRIBUTE,
  CV_FLOW,
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
  LTV_ANALYZE,
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  PRIORITY_AXIS_SCREEN_IDS,
  LANDING_PAGE_ANALYZE,
  LPO_LINK,
  LPO_PERIOD,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
} from 'services/routes/constants';
import settingsSelectors from 'store/settings/selectors';
import filterSelectors from 'store/filters/selectors';
import CostAllocationSelectors from 'store/cost-allocation/selectors';
import PeriodAnalysisSelectors from 'store/period-analyze/selectors';
import DetailAnalysisSelectors from 'store/detail-analyze/selectors';
import CvAttrSelectors from 'store/cv-attribute/selectors';
import CvFlowSelectors from 'store/cv-flow/selectors';
import LandingPageAnalyzeSeletors from 'store/landing-page-analyze/selectors';
import ltvSettingsProductSelectors from 'store/ltv/settings/product/selectors';
import ltvSettingsOfferSelectors from 'store/ltv/settings/offer/selectors';
import ltvSettingsConditionSelectors from 'store/ltv/settings/condition/selectors';
import ltvSettingsAdSelectors from 'store/ltv/settings/ad/selectors';
import LtvAnalyzeSelectors from 'store/ltv/analyze/selectors';
import adManagementMediaSelectors from 'store/ad-management-media/selectors';
import LpoLinkSelectors from 'store/lpo/link/selectors';
import LpoPeriodSelectors from 'store/lpo/period/selectors';

import logPageAnalyzeSelectors from 'store/log/page-analyze/selectors';
import logPeriodAnalyzeSelectors from 'store/log/period-analyze/selectors';
import Api from 'services/api/Api';
import SummaryApi from 'services/api/SummaryApi';
import CostAllocationApi from 'services/api/CostAllocationApi';
import DetailAnalyzeApi from 'services/api/DetailAnalyzeApi';
import PeriodAnalysisApi from 'services/period-analyze/periodAnalysisApi';
import LandingPageAnalyzeApi from 'services/api/LandingPageAnalyzeApi';
import CategoryAnalysisSelectors from 'store/category-analyze/selectors';
import CVAttributeApi from 'services/api/CVAttributeApi';
import CVFlowApi from 'services/api/CVFlowApi';
import LtvProductApi from 'services/api/LtvProductApi';
import LtvOfferApi from 'services/api/LtvOfferApi';
import LtvConditionApi from 'services/api/LtvConditionApi';
import LtvAdApi from 'services/api/LtvAdApi';
import LtvAnalyzeApi from 'services/api/LtvAnalyzeApi';
import TutorialApi from 'services/api/common/TutorialApi';
import MediaService from 'services/api/settings/MediaService';
import AdGroup1Service from 'services/api/settings/AdGroup1Service';
import AdGroup2Service from 'services/api/settings/AdGroup2Service';
import LpoLinkApi from 'services/api/LpoLinkApi';
import LpoPeriodApi from 'services/lpo/period/lpoPeriodApi';

import SystemApi from 'services/api/common/SystemApi';
import loggerConstants from 'store/logger/constant';
import { updateTutorialStatus } from 'store/auth/actions';
import { loggerActions } from 'store/logger';
import screenTitleConfigs from 'services/common/screenTitleConfigs';
import displayItemsSelectors from 'store/display-items/selectors';
import { DisplayItemsSelectors } from 'store/display-items';

import { getPermissions } from 'store/auth/selectors';
import { resolve } from 'domain/permissions/permissionTypes';
import { CROSS_DEVICE_PERMISSIONS } from 'domain/permissions/contractGroups';
import pages from 'services/routes/pages';
import { TAB_ALL } from 'services/consts';
import commonSelectors from './selectors';
import commonActions from './actions';

const { DOWNLOADING, SUCCEEDED, FAILED } = communicationStatus;

const getFormatByExportType = (exportType) => {
  switch (exportType) {
    case EXPORT_TYPE.PNG:
      return 'png';
    case EXPORT_TYPE.JPEG:
      return 'jpeg';
    default:
      return null;
  }
};

function* downloadImage(params) {
  try {
    yield delay(1000);
    const pageId = yield select(settingsSelectors.getPage);
    const exportType = params.payload;
    const format = getFormatByExportType(exportType);
    if (!format) {
      return;
    }

    yield fork(exportImage, pageId, (canvas) => {
      const image = canvas.toDataURL();
      saveAs(image, `${getDownloadImageName(pageId)}.${format}`);
    });
  } catch (e) {
    // TODO: add error log
  }
}

const requestParamsMapper = {
  [CATEGORY_ANALYZE]: {
    url: SummaryApi.url,
    selectors: {
      getDimensions: CategoryAnalysisSelectors.getDimensions,
      getMetrics: CategoryAnalysisSelectors.getMetrics,
      getSort: CategoryAnalysisSelectors.getSortsForApiRequest,
      getComparePeriod: commonSelectors.comparePeriodSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [COST_ALLOCATION]: {
    url: CostAllocationApi.url,
    selectors: {
      getDimensions: CostAllocationSelectors.dimensionsSelector,
      getMetrics: CostAllocationSelectors.metricsSelector,
      getSort: CostAllocationSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [DETAILS_ANALYSIS]: {
    url: DetailAnalyzeApi.url,
    selectors: {
      getDimensions: DetailAnalysisSelectors.dimensionsSelector,
      getMetrics: DetailAnalysisSelectors.metricsSelector,
      getSort: DetailAnalysisSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [COMPARE_PERIOD]: {
    url: PeriodAnalysisApi.url,
    selectors: {
      getDimensions: PeriodAnalysisSelectors.getDimensionsForCsvExport,
      getMetrics: PeriodAnalysisSelectors.metricsSelector,
      getSort: PeriodAnalysisSelectors.getSortsForApiRequest,
      getPeriodType: PeriodAnalysisSelectors.getPeriodTypeForApi,
      getComparePeriod: commonSelectors.comparePeriodSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [CV_ATTRIBUTE]: {
    url: CVAttributeApi.url,
    selectors: {
      getMetrics: CvAttrSelectors.getMetricsRequest,
      getSort: CvAttrSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [CV_FLOW]: {
    url: CVFlowApi.url,
    selectors: {
      getMetrics: CvFlowSelectors.getMetricsRequest,
      getSort: CvFlowSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [LANDING_PAGE_ANALYZE]: {
    url: LandingPageAnalyzeApi.url,
    selectors: {
      getDimensions: LandingPageAnalyzeSeletors.dimensionsSelector,
      getMetrics: LandingPageAnalyzeSeletors.metricsSelector,
      getSort: LandingPageAnalyzeSeletors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [LTV_SETTINGS_PRODUCT]: {
    url: LtvProductApi.url,
    urlCsv: LtvProductApi.urlCsv,
    baseUrl: LtvProductApi.baseUrl,
    tokenInPath: true,
    selectors: {
      getMetrics: () => [],
      getSort: ltvSettingsProductSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [LTV_SETTINGS_OFFER]: {
    url: LtvOfferApi.url,
    urlCsv: LtvOfferApi.urlCsv,
    baseUrl: LtvOfferApi.baseUrl,
    tokenInPath: true,
    selectors: {
      getMetrics: () => [],
      getSort: ltvSettingsOfferSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [LTV_SETTINGS_CONDITION]: {
    url: LtvConditionApi.url,
    urlCsv: LtvConditionApi.urlCsv,
    baseUrl: LtvConditionApi.baseUrl,
    tokenInPath: true,
    selectors: {
      getMetrics: () => [],
      getSort: ltvSettingsConditionSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [LTV_SETTINGS_AD]: {
    url: LtvAdApi.url,
    urlCsv: LtvAdApi.urlCsv,
    baseUrl: LtvAdApi.baseUrl,
    tokenInPath: true,
    selectors: {
      getMetrics: () => [],
      getSort: ltvSettingsAdSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [LTV_ANALYZE]: {
    url: LtvAnalyzeApi.url,
    urlCsv: LtvAnalyzeApi.urlCsv,
    baseUrl: LtvAnalyzeApi.baseURL,
    selectors: {
      getDimensions: LtvAnalyzeSelectors.dimensionsSelector,
      getMetrics: LtvAnalyzeSelectors.metricsSelector,
      getSort: LtvAnalyzeSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: LtvAnalyzeSelectors.periodSelector,
      getDisplayConditions: displayItemsSelectors.getSettings,
      getConfig: LtvAnalyzeSelectors.getConfig,
    },
  },

  [AD_MANAGEMENT_MEDIA_TYPE]: {
    url: MediaService.url,
    urlCsv: MediaService.urlCsv,
    baseUrl: MediaService.baseUrl,
    tokenInPath: true,
    selectors: {
      getMetrics: () => [],
      getSort: adManagementMediaSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },

  [AD_MANAGEMENT_AD_GROUP_1]: {
    url: AdGroup1Service.url,
    urlCsv: AdGroup1Service.urlCsv,
    baseUrl: AdGroup1Service.baseUrl,
    tokenInPath: true,
    selectors: {
      getMetrics: () => [],
      getSort: adManagementMediaSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },

  [AD_MANAGEMENT_AD_GROUP_2]: {
    url: AdGroup2Service.url,
    urlCsv: AdGroup2Service.urlCsv,
    baseUrl: AdGroup2Service.baseUrl,
    tokenInPath: true,
    selectors: {
      getMetrics: () => [],
      getSort: adManagementMediaSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [LOG_PAGE_ANALYZE]: {
    url: pages[LOG_PAGE_ANALYZE].endpoint,
    selectors: {
      getDimensions: logPageAnalyzeSelectors.dimensionsSelector,
      getMetrics: logPageAnalyzeSelectors.metricsSelector,
      getSort: logPageAnalyzeSelectors.sortSelector,
      getFilters: logPageAnalyzeSelectors.filtersSelector,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [LOG_PERIOD_ANALYZE]: {
    url: pages[LOG_PERIOD_ANALYZE].endpoint,
    selectors: {
      getDimensions: logPeriodAnalyzeSelectors.dimensionsSelector,
      getMetrics: logPeriodAnalyzeSelectors.metricsSelector,
      getSort: logPeriodAnalyzeSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getComparePeriod: commonSelectors.comparePeriodSelector,
      getPeriodType: logPeriodAnalyzeSelectors.getPeriodTypeForApi,
      getDisplayConditions: null,
    },
  },
  [LPO_LINK]: {
    url: LpoLinkApi.url,
    selectors: {
      getDimensions: LpoLinkSelectors.dimensionsSelectorApi,
      getMetrics: LpoLinkSelectors.metricsSelectorApi,
      getSort: LpoLinkSelectors.sortSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
  [LPO_PERIOD]: {
    url: LpoPeriodApi.url,
    selectors: {
      getDimensions: LpoPeriodSelectors.getDimensionsForCsvExport,
      getMetrics: LpoPeriodSelectors.metricsSelector,
      getSort: LpoPeriodSelectors.getSortsForApiRequest,
      getPeriodType: LpoPeriodSelectors.getPeriodTypeForApi,
      getComparePeriod: commonSelectors.comparePeriodSelector,
      getFilters: filterSelectors.getForApi,
      periodSelector: commonSelectors.periodSelector,
      getDisplayConditions: null,
    },
  },
};

const downloadQueues = {};
/**
 * Waiting previous request finish before start new
 * For now only apply for CV Flow endpoint!
 * @param string url
 */
const isWaitDownload = (urlOrigin) => {
  const url = `${urlOrigin}/exports/csv`;
  if (url.indexOf('cv-flow') !== -1) {
    if (url in downloadQueues) {
      return true;
    }
    downloadQueues[url] = url;
  }
  return false;
};

const downloadFinish = (url) => {
  if (url in downloadQueues) {
    delete downloadQueues[url];
  }
};

const CommonApi = () => {
  return {
    *downloadCsv(
      url,
      urlCsv,
      tokenInPath,
      batchRequest,
      timeout,
      baseURL,
      pollUrl,
      params
    ) {
      const pageId = yield select(settingsSelectors.getPage);

      const dimensionKeysFilter = [CHANNEL];
      const keepAllDimension = [LANDING_PAGE_ANALYZE].includes(pageId);
      const dimensions = params.dimensions.filter(
        (key) => keepAllDimension || !dimensionKeysFilter.includes(key)
      );

      const newParams = { ...params, dimensions };

      let res;
      if (urlCsv === null) {
        // downloadCsv auto add suffix `exports/csv` to url
        res = yield call(Api.downloadCsv, url, newParams, {
          url: `${url}/csv`,
        });
      } else {
        res = yield call(Api.get, urlCsv, {
          tokenInPath,
          batchRequest,
          timeout,
          baseURL,
          pollUrl,
          params: newParams,
        });
      }
      return res;
    },
  };
};

function* downloadCsv(payload) {
  try {
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    const { isAllData, getClickIdSetting } = payload.payload;
    const pageId = yield select(settingsSelectors.getPage);
    const tab = yield select(settingsSelectors.getTab);
    const { downLoadApi = CommonApi() } = screenTitleConfigs[pageId];
    const {
      url,
      selectors: pageSelectors,
      urlCsv = null,
      baseUrl = null,
      tokenInPath = null,
    } = requestParamsMapper[pageId] || {
      url: undefined,
      selectors: undefined,
    };

    const isCvFlow = url.indexOf('cv-flow') !== -1;
    if (url && isWaitDownload(url) === false) {
      if (isCvFlow) {
        yield put(commonActions.setDownloadStatus({ status: DOWNLOADING }));
      }

      const channel = yield select(settingsSelectors.getTab);
      const period = yield select(pageSelectors.periodSelector);

      const compare = pageSelectors.getComparePeriod
        ? yield select(pageSelectors.getComparePeriod)
        : {};
      const comparedPeriod =
        !isEmpty(compare) && compare.enabled === true
          ? {
              start_date_2: compare.start.format(API_DATE_FORMAT),
              end_date_2: compare.end.format(API_DATE_FORMAT),
            }
          : {};

      const dimensions = pageSelectors.getDimensions
        ? yield select(pageSelectors.getDimensions)
        : [];

      const fullDimensions = isAllData
        ? yield select(DisplayItemsSelectors.getFullDimensionsSelector)
        : [];

      const metrics = pageSelectors.getMetrics
        ? yield select(pageSelectors.getMetrics)
        : [];

      const sortable = pageSelectors.getSort
        ? yield select(pageSelectors.getSort)
        : {};

      const filters = yield select(pageSelectors.getFilters);

      // For period analysis screen only
      const periodType = pageSelectors.getPeriodType
        ? yield select(pageSelectors.getPeriodType)
        : undefined;

      const displayConditions = pageSelectors.getDisplayConditions
        ? yield select(pageSelectors.getDisplayConditions)
        : null;

      const dimensionsConfig = pageSelectors.getConfig
        ? yield select(pageSelectors.getConfig)
        : {};

      const userPermissions = yield select(getPermissions);

      let priorityAxis = '';
      let newFullMetrics = [];
      if (isAllData) {
        priorityAxis = yield select(DisplayItemsSelectors.hasContractMediaSync)
          ? PRIORITY_AXIS_MEDIA_SYNC
          : PRIORITY_AXIS_EBIS;
        const fullMetrics = yield select(
          DisplayItemsSelectors.getFullMetricsSelector
        );
        if (resolve(CROSS_DEVICE_PERMISSIONS[tab], userPermissions)) {
          newFullMetrics = fullMetrics;
        } else {
          newFullMetrics = fullMetrics.filter((fullMetric) => {
            return CROSSDEVICE_DIFF_COMPARISON !== fullMetric;
          });
        }
      } else {
        priorityAxis = PRIORITY_AXIS_SCREEN_IDS.includes(pageId)
          ? yield select(DisplayItemsSelectors.getDisplayItemPriorityAxis)
          : PRIORITY_AXIS_EBIS;
      }

      const params = {
        channel,
        start_date: period.start.format(API_DATE_FORMAT),
        end_date: period.end.format(API_DATE_FORMAT),
        ...comparedPeriod,
        dimensions: isAllData ? fullDimensions : dimensions,
        metrics: isAllData ? newFullMetrics : metrics,
        filters: isAllData ? [] : filters,
        ...sortable,
        sum: false,
        summary_mode: periodType,
        offset: 0,
        limit: API_UNLIMITED_VALUE,
      };
      if (isAllData) {
        params.isCsvAllData = isAllData;
      }

      if (channel === TAB_ALL && pageId === CV_ATTRIBUTE && getClickIdSetting) {
        params.metrics = [...params.metrics, ...['click_id']];
      }

      // LTVなど優先軸対応不可の画面に影響を及ぼさないため。
      if (PRIORITY_AXIS_SCREEN_IDS.includes(pageId)) {
        params.axis = priorityAxis;
      }

      const batchRequest = true;
      const timeout = 0;
      const baseURL = baseUrl;
      const pollUrl = urlCsv;

      const res = yield call(
        downLoadApi.downloadCsv,
        url,
        urlCsv,
        tokenInPath,
        batchRequest,
        timeout,
        baseURL,
        pollUrl,
        params,
        displayConditions,
        dimensionsConfig
      );

      // Update queues for limit request
      if (isCvFlow && 'data' in res && 'urlDownload' in res.data) {
        downloadFinish(res.data.urlDownload);
        yield put(commonActions.setDownloadStatus({ status: SUCCEEDED }));
      }

      // When success, close notify
      yield put(commonActions.setDownloadNotify('CSVファイルを作成しました'));
    }
  } catch (error) {
    yield put(
      commonActions.downloadCsvFail({
        error: error?.response || error,
        scope: loggerConstants.SCOPE_DONT_SHOW,
      })
    );
    yield put(loggerActions.logDownloadCsvError());
    yield put(commonActions.setDownloadNotify(''));

    // Update queues for limit request
    if ('urlDownload' in error) {
      downloadFinish(error.urlDownload);
      yield put(commonActions.setDownloadStatus({ status: FAILED }));
    }
  }
}

// Time out for showing alert
function* watchDownloadNotify(content) {
  const { payload = '' } = content;
  if (payload.length === 0) {
    // Do nothing
  } else {
    yield delay(5000);
    yield put(commonActions.setDownloadNotify(''));
  }
}

function* handleTutorialStatus() {
  try {
    yield call(TutorialApi.postTutorialStatus);
    yield put(updateTutorialStatus(true));
  } catch (error) {
    yield put({
      type: types.TUTORIAL_UPDATE_FAILED,
      payload: error?.response || error,
    });
  }
}

function* handleCheckUrl(action) {
  const { url, callback } = action.payload;
  try {
    // checkUrlReachable => UI check, if UI check failed then API check again
    let isReachable = yield call(checkUrlReachable, url);
    if (!isReachable) {
      const res = yield call(SystemApi.checkUrl, url);
      isReachable = res.data.result;
    }
    callback(isReachable);
  } catch (error) {
    callback(false);
  }
}

export default function* commonOperations() {
  yield takeLatest(types.SET_DOWNLOAD_NOTIFY, watchDownloadNotify);
  yield takeLatest(types.DOWNLOAD_IMAGE, downloadImage);
  yield takeEvery(types.DOWNLOAD_CSV, downloadCsv);
  yield takeLatest(types.CHANGE_TUTORIAL_STATUS, handleTutorialStatus);
  yield takeLatest(types.CHECK_URL, handleCheckUrl);
}
