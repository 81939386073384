import { put, call, select, takeLatest } from 'redux-saga/effects';
import sharedSagas from 'store/sharedSagas';
import commonSelectors from 'store/common/selectors';
import settingsSelectors from 'store/settings/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import pageAnalyzeSelectors from 'store/log/page-analyze/selectors';
import displayItemsSelectors from 'store/display-items/selectors';
import masterDataActions from 'store/master-data/actions';
import PageAnalyzeApi from 'services/api/log/PageAnalyzeApi';
import handleError from 'services/error/handleScopeError';
import { communicationStatus, HttpStatus } from 'services/utils';
import { FUNC_CODE_LOG_PAGE_ANALYZE } from 'domain/settings/display-items';
import { TAB_ALL } from 'services/consts';
import { LOG_PAGE_ANALYZE } from 'services/routes/constants';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';

import actions from './actions';
import types from './types';

const { BAD_REQUEST } = HttpStatus;
const { LOADING } = communicationStatus;

function* handleGetData() {
  yield put(actions.setStatus(LOADING));
  const [
    pageId,
    funcId,
    conversions,
    period,
    paging,
    sort,
    dimensions,
    metrics,
    filters,
  ] = [
    yield select(settingsSelectors.getPage),
    yield select(displayItemsSelectors.getDisplayItemFuncId),
    yield select(masterDataSelectors.conversionsSelector),
    yield select(commonSelectors.periodSelector),
    yield select(pageAnalyzeSelectors.getSettingPaging),
    yield select(pageAnalyzeSelectors.sortSelector),
    yield select(pageAnalyzeSelectors.dimensionsSelector),
    yield select(pageAnalyzeSelectors.metricsSelector),
    yield select(pageAnalyzeSelectors.filtersSelector),
  ];

  if (pageId !== LOG_PAGE_ANALYZE || funcId !== FUNC_CODE_LOG_PAGE_ANALYZE) {
    return;
  }

  if (!conversions || conversions.length <= 0) {
    yield put(masterDataActions.fetchConversions());
  }

  const { data } = yield call(PageAnalyzeApi.getData, {
    ...sort,
    sum: true,
    channel: TAB_ALL,
    start_date: period.start.format('YYYY-MM-DD'),
    end_date: period.end.format('YYYY-MM-DD'),
    dimensions,
    metrics,
    filters,
    limit: TABLE_ROWS_PER_PAGE,
    offset: (paging - 1) * TABLE_ROWS_PER_PAGE,
  });

  yield put(actions.setData(data));
}

function* errorHandler(err) {
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [];

  yield put(actions.setErrors(errors, scope));
}

export default function* watch() {
  yield takeLatest(
    types.GET_DATA,
    sharedSagas.safe(errorHandler, handleGetData)
  );
}
