import React from 'react';
import { number } from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import iconCautionYellow from 'assets/images/icon-caution-yellow.svg';

import {
  CAPI_STATUS_LABELS,
  STATUS_TYPE_FAILED,
  STATUS_TYPE_AUTH_EXPRIED,
  STATUS_TYPE_SUCCESS,
} from 'domain/capi-setting/consts';

const WARNING_STATUS = [STATUS_TYPE_FAILED, STATUS_TYPE_AUTH_EXPRIED];
function StatusComponent({ status }) {
  if (![STATUS_TYPE_SUCCESS, ...WARNING_STATUS].includes(status)) {
    return '';
  }

  return (
    <div className="capi-status">
      {WARNING_STATUS.includes(status) &&
        (status === STATUS_TYPE_AUTH_EXPRIED ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="dragonball-tooltip variant-base">
                認証エラーが発生しています。 媒体の認証情報をご確認ください。
              </Tooltip>
            }
          >
            <img src={iconCautionYellow} alt="icon-caution" />
          </OverlayTrigger>
        ) : (
          <img src={iconCautionYellow} alt="icon-caution" />
        ))}
      <span>{CAPI_STATUS_LABELS[status]}</span>
    </div>
  );
}

StatusComponent.propTypes = {
  status: number.isRequired,
};

export default StatusComponent;
