import { createSelector } from 'reselect';
import { getSortString } from 'domain/utils';
import { communicationStatus } from 'services/utils';
import settingsSelectors from 'store/settings/selectors';

const getData = (state) => state.cache.scSetting.data.list.data;
const getMetadata = (state) => state.cache.scSetting.data.list.metadata;
const getSettings = (state) => state.cache.scSetting.settings;
const getStatusList = (state) => state.cache.scSetting.status.list;

const getSort = (state) => state.cache.scSetting.settings.sort;

const getStatusSetting = (state) => state.cache.scSetting.status.setting;
const getDetailSetting = (state) => state.cache.scSetting.data.setting.item;

const getStatusMasterData = (state) => state.cache.scSetting.status.masterData;
const getMasterData = (state) => state.cache.scSetting.data.masterData;

const getSortRequest = createSelector([getSort], (sort) => {
  return getSortString(sort);
});

const getStatesForList = createSelector(
  [
    getData,
    getMetadata,
    getSettings,
    settingsSelectors.getLoadingByStatus([getStatusList]),
  ],
  (data, metadata, settings, isLoading) => ({
    isLoading,
    data,
    metadata,
    settings,
  })
);

const getStatesForSetting = createSelector(
  [
    getStatusSetting,
    getDetailSetting,
    getMasterData,
    settingsSelectors.getLoadingByStatus([getStatusMasterData]),
  ],
  (statusSetting, detailSetting, masterData, isFetchingUser) => ({
    isLoadingSetting: statusSetting === communicationStatus.LOADING,
    detailSetting,
    masterData,
    isFetchingUser,
  })
);

const scSettingSelector = {
  getStatesForList,
  getSortRequest,
  getStatesForSetting,
  getMetadata
};

export default scSettingSelector;
