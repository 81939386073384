import { DIRECTORY, PAGE_ID, PAGE_URL } from 'domain/settings/display-items';
import { allFilterableList, FILTER_KEY_PAGE_TITLE } from 'services/consts';

export const checkAggregationByDirectory = (setting) => {
  return setting[DIRECTORY];
};

export const changeInfoFilterPageUrl = (filter) => {
  return {
    ...filter,
    title: 'ページURL',
    order: allFilterableList[FILTER_KEY_PAGE_TITLE].order + 1,
  };
};

export const createHeaders = (headers, options) => {
  const { createComponent } = options;

  return headers.map((header) => {
    let option = {};
    switch (header.field) {
      case PAGE_ID:
        option = {
          callback: (data) => {
            return { ...data, [PAGE_ID]: createComponent(header.field, data) };
          },
        };
        break;
      case PAGE_URL:
        option = {
          type: 'inlineTruncate',
          callback: (data) => {
            return { ...data, [PAGE_URL]: createComponent(header.field, data) };
          },
        };
        break;

      default:
        break;
    }
    return { ...header, ...option };
  });
};

export const setHttpsProtocal = (url) => {
  return `https://${url.replace(/^http[s]?:\/\//, '')}`;
};
