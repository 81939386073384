import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import configureStore from 'store/store';
import throttle from 'lodash/throttle';

import * as serviceWorker from 'services/serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import App from 'views/App';
import 'services/i18n';
import { loadState, saveState } from 'services/LocalStorageService';
import PageLoader from 'views/atoms/loader/PageLoader';
import LoadingScreen from 'views/atoms/loader/LoadingScreen';
import Auth from 'domain/auth';

import './index.scss';

(async () => {
  ReactDOM.render(<LoadingScreen />, document.getElementById('root'));

  const user = await Auth.authentication();
  if (user) {
    const persistedState = loadState(user);
    const store = configureStore(persistedState);
    store.subscribe(
      throttle(() => {
        saveState(store.getState());
      }, 1000)
    );
    ReactDOM.render(
      <Provider store={store}>
        <React.StrictMode>
          <BrowserRouter>
            <Suspense fallback={<PageLoader />}>
              <App />
            </Suspense>
          </BrowserRouter>
        </React.StrictMode>
      </Provider>,
      document.getElementById('root')
    );
  }
})();
