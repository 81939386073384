import { MAINTENANCE_TYPE } from 'domain/consts';
import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { getMaintenanceData } from 'store/auth/selectors';
import NavbarContainer from 'views/molecules/navbar';
import AlertBar from 'views/molecules/alertbar';
import Footer from 'views/molecules/footer';
import CommonBarContainer from 'views/molecules/commonbar';
import TopbarContainer from 'views/molecules/topbar';
import NewsModalContainer from 'views/molecules/modal/NewsModalContainer';
import TransitionModalContainer from 'views/molecules/modal/TransitionModalContainer';
import SystemErrorModalContainer from 'views/molecules/modal/SystemErrorModalContainer';
import ConfirmModalContainer from 'views/molecules/modal/ConfirmModalContainer';
import SwitchUserModalContainer from 'views/molecules/modal/SwitchUserModalContainer';
import useCheckNewVersion from 'services/custom-hooks/useCheckNewVersion';
import DownloadAlertContainer from 'views/atoms/alert/DownloadAlertContainer';
import commonSelectors from 'store/common/selectors';
import settingsSelector from 'store/settings/selectors';
import usePageChange from 'services/custom-hooks/usePageChange';
import useAllowScreenOrder from 'services/custom-hooks/useAllowScreenOrder';
import LoadingScreen from 'views/atoms/loader/LoadingScreen';
import MainRoutes from 'services/routes/MainRoutes';
import MaintenanceMode from 'views/pages/maintenance-mode';
import { useLocation } from 'react-router-dom';
import SettingsActions from 'store/settings/actions';
import 'views/layout/layout.scss';
import { DisplayItemsSelectors } from 'store/display-items';
import { PRIORITY_AXIS_SCREEN_IDS } from 'services/routes/constants';
import PriorityAxisBar from 'views/atoms/PriorityAxisBar/PriorityAxisBar';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';

const Layout = React.memo(
  ({ isShowNavbar, currentTab, screenId, priorityAxis }) => {
    useCheckNewVersion();
    usePageChange();
    const { firstAllowedScreen } = useAllowScreenOrder();
    const { isCapiSingleContract } = useCheckPermissionCapi();

    const { status, message, permissions } =
      useSelector(getMaintenanceData) || {};

    // Don't render ui when user don't have any permission
    if (firstAllowedScreen === undefined) {
      return <LoadingScreen />;
    }

    if (status === MAINTENANCE_TYPE.IN_PROGRESS && isEmpty(permissions)) {
      return <MaintenanceMode message={message} />;
    }

    return (
      <>
        <TopbarContainer />
        <div
          className={`d-flex ${
            isShowNavbar && !isCapiSingleContract ? '' : 'hide-navbar'
          }`}
        >
          {!isCapiSingleContract && <NavbarContainer />}
          <div className="main-content-wrapper" screen-name={screenId}>
            <div id="common-bar">
              <AlertBar />
              <CommonBarContainer />
            </div>
            {PRIORITY_AXIS_SCREEN_IDS.includes(screenId) ? (
              <PriorityAxisBar priorityAxis={priorityAxis} />
            ) : null}
            <div id="main-content">
              <MainRoutes currentTab={currentTab} />
            </div>
            <Footer />
          </div>
        </div>
        <NewsModalContainer />
        <TransitionModalContainer />
        {/* This modal for unhandled api errors */}
        <SystemErrorModalContainer />
        {/* System crash modal is in ErrorBoundary */}
        {/* Modal for handle custom mess for failed api error */}
        <ConfirmModalContainer />
        <DownloadAlertContainer />
        {/* Modal for handle switch user */}
        <SwitchUserModalContainer />
      </>
    );
  }
);

Layout.propTypes = {
  isShowNavbar: PropTypes.bool.isRequired,
  currentTab: PropTypes.string.isRequired,
  screenId: PropTypes.string.isRequired,
  priorityAxis: PropTypes.string.isRequired,
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LayoutContainer = () => {
  const dispatch = useDispatch();

  const isShowNavbar = useSelector(commonSelectors.isShowNavbar, shallowEqual);
  let currentTab = useSelector(settingsSelector.getCurrentTab, shallowEqual);
  const screenId = useSelector(settingsSelector.getPage, shallowEqual);
  const priorityAxis = useSelector(
    DisplayItemsSelectors.getDisplayItemPriorityAxis,
    shallowEqual
  );

  const currentTabGet = useQuery().get('mode');
  if (currentTabGet) {
    currentTab = currentTabGet;
    dispatch(SettingsActions.changeTab(currentTab));
  }

  return (
    <Layout
      isShowNavbar={isShowNavbar}
      currentTab={currentTab}
      screenId={screenId}
      priorityAxis={priorityAxis}
    />
  );
};

export default React.memo(LayoutContainer);
