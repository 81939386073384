import { useSelector } from 'react-redux';
import { getPageByPath } from 'services/routes/page-helpers';
import settingsSelector from 'store/settings/selectors';
import screenPermissionConfigs from 'domain/permissions/screenPermissionConfigs';
import { TAB_AD, TAB_ALL } from 'services/consts';

const useCheckScreenExists = () => {
  const currentTab = useSelector(settingsSelector.getTab);
  const currentPage = useSelector(settingsSelector.getPage);

  let isExistsScreen = false;

  const page = getPageByPath(window.location.pathname);
  if (page) {
    const allowedTabs = Object.keys(
      screenPermissionConfigs[currentPage]
    ).filter((tab) => [TAB_AD, TAB_ALL].includes(tab));

    isExistsScreen =
      allowedTabs.length <= 0 || allowedTabs.includes(currentTab);
  }

  return { isExistsScreen };
};

export default useCheckScreenExists;
