import { put, call, takeLatest, select } from 'redux-saga/effects';
import types from 'store/user-profile/types';
import apis from 'services/api/UserProfileApi';
import actions from 'store/user-profile/actions';
import commonActions from 'store/common/actions';
import selectors from 'store/user-profile/selectors';
import handleError from 'services/error/handleScopeError';
import loggerConstants from 'store/logger/constant';

function* fetchUserBehaviors(data) {
  try {
    const [pagenation, order] = [
      yield select(selectors.getPagenation),
      yield select(selectors.getSortOrder),
    ];
    yield put(actions.requestUserBehaviors());
    const result = yield call(apis.userProfileApi.fetchUserBehaviors, {
      tracking_data: data.payload.trackingData,
      base_cv_record_id: data.payload.baseCvRecordId,
      limit: pagenation.itemsPerPage,
      offset: (pagenation.currentPage - 1) * pagenation.itemsPerPage,
      sort: order,
    });
    yield put(actions.fetchReportUserBehaviorsSucceeded(result.data.result));
  } catch (error) {
    yield put(
      actions.fetchReportUserBehaviorsFailed({
        error: handleError(error.response),
      })
    );
  }
}
function* fetchUserProfile(data) {
  try {
    yield put(actions.requestUserProfile());
    const result = yield call(apis.userProfileApi.fetchUserProfile, {
      trackingData: data.payload.trackingData,
      baseCvRecordId: data.payload.baseCvRecordId,
    });
    yield put(actions.fetchReportUserProfileSucceeded(result.data.result));
  } catch (error) {
    yield put(
      actions.fetchReportUserProfileFailed({
        error: handleError(error.response),
      })
    );
  }
}

function* downloadCsv(data) {
  try {
    const [order] = [yield select(selectors.getSortOrder)];
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    const { trackingData, baseCvRecordId } = data.payload;
    yield call(
      apis.userProfileApi.downloadCsv,
      trackingData,
      baseCvRecordId,
      order
    );
    yield put(commonActions.setDownloadNotify(''));
  } catch (error) {
    yield put(
      commonActions.downloadCsvFail({
        error: error?.response || error,
        scope: loggerConstants.SCOPE_DONT_SHOW,
      })
    );
  }
}

export default function* userProfileOperations() {
  yield takeLatest(types.FETCH_REPORT_USER_BEHAVIORS, fetchUserBehaviors);
  yield takeLatest(types.FETCH_REPORT_USER_PROFILE, fetchUserProfile);
  yield takeLatest(types.DOWNLOAD_CSV, downloadCsv);
}
