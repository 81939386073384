import types from 'store/ltv/settings/other/types';

const start = (type) => ({
  type,
});

const succeeded = (type, data) => ({
  type,
  payload: data,
});

const failed = (type, { error }) => ({
  type,
  payload: error,
  scope: error.scope,
});

const requestStart = () => start(types.REQUEST_START);
const requestFailed = (error) => failed(types.REQUEST_FAILED, error);
const fetched = (data) => succeeded(types.FETCH_SUCCEEDED, data);
const updated = (data) => succeeded(types.UPDATE_SUCCEEDED, data);
const fetch = (data) => succeeded(types.FETCH, data);
const update = (data) => succeeded(types.UPDATE, data);

export default {
  requestStart,
  requestFailed,
  fetched,
  updated,
  fetch,
  update,
};
