import React, { useEffect } from 'react';
import { node } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

const tooltipElementId = 'filter-panel-tooltip';
const MAX_CONTENT_WIDTH = 285;

const getDivElement = (ref) => {
  return ref.current.tagName === 'DIV' ? ref.current : ref.current.parentNode;
};

const getCellValue = (ref) => {
  const divEl = getDivElement(ref);
  return divEl.firstChild.innerHTML;
};

const needShowTooltip = (ref) => {
  if (isEmpty(ref?.current)) return false;
  const divEl = getDivElement(ref);
  if (!divEl) return false;
  const contentWidth = divEl.firstChild.offsetWidth;
  return contentWidth >= MAX_CONTENT_WIDTH;
};

const PanelWrapper = React.forwardRef(({ children }, ref) => {
  useEffect(() => {
    if (isEmpty(ref?.current)) return () => {};
    let tooltip = document.getElementById(tooltipElementId);
    if (!tooltip) {
      tooltip = document.createElement('DIV');
      tooltip.setAttribute('id', tooltipElementId);
      document.body.appendChild(tooltip);
    }

    const handleMouseOver = (event) => {
      if (!needShowTooltip(ref)) return () => {};
      tooltip.innerHTML = getCellValue(ref);

      const positionLeft =
        window.innerWidth - event.clientX - 50 > tooltip.clientWidth
          ? event.clientX + 2
          : event.clientX - tooltip.clientWidth + 10;

      Object.assign(tooltip.style, {
        position: 'fixed',
        top: `${event.clientY + 15}px`,
        left: `${positionLeft}px`,
        display: 'block',
      });
      return true;
    };

    const handelMouseOut = () => {
      if (tooltip) {
        tooltip.style.display = 'none';
      }
      return false;
    };

    // Event
    const el = getDivElement(ref);
    if (!el) return () => {};

    el.addEventListener('mouseover', handleMouseOver);
    el.addEventListener('mouseout', handelMouseOut);
    return () => {
      el.removeEventListener('mouseover', handleMouseOver);
      el.removeEventListener('mouseout', handelMouseOut);
    };
  }, [ref, children]);

  return <>{children}</>;
});

PanelWrapper.propTypes = {
  children: node.isRequired,
};

PanelWrapper.defaultProps = {};

export default PanelWrapper;
