import React from 'react';

import PropTypes from 'prop-types';

const ListItems = ({ children, items }) => {
  if (!items?.length) return <></>;

  return (
    <>
      {children && <p className="note">{children}</p>}
      <div className="confirm-list bordered">
        {items.map((item) => {
          return (
            <p className="text-nowrap" key={item.measurement_fqdn}>
              {item.measurement_fqdn}（URL = {item.program_url}）
            </p>
          );
        })}
      </div>
    </>
  );
};

ListItems.propTypes = {
  children: PropTypes.shape({}),
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

ListItems.defaultProps = {
  children: null,
  items: [],
};

export default ListItems;
