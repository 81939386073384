import { combineReducers } from 'redux';
import UserManagementReducer from 'store/user-management/reducer';
import LtvSettingsProductReducer from 'store/ltv/settings/product/reducer';
import LtvSettingsConditionReducer from 'store/ltv/settings/condition/reducer';
import LtvSettingsAdReducer from 'store/ltv/settings/ad/reducer';
import LtvSettingsOfferReducer from 'store/ltv/settings/offer/reducer';
import LtvSettingsOrderReducer from 'store/ltv/settings/order/reducer';
import LtvAnalyzeReducer from 'store/ltv/analyze';
import MasterDataReducer from 'store/master-data';
import PeriodAnalysisReducer from 'store/period-analyze';
import CategoryAnalysisReducer from 'store/category-analyze';
import DetailAnalysisReducer from 'store/detail-analyze';
import CVAttributeReducer from 'store/cv-attribute';
import CVFlowReducer from 'store/cv-flow';
import CostAllocationReducer from 'store/cost-allocation';
import dashboardReducer from 'store/dashboard';
import AdManagementReducer from 'store/ad-management';
import AdManagementMediaReducer from 'store/ad-management-media';
import AdManagementAdGroup1Reducer from 'store/ad-management-adgroup1';
import AdManagementAdGroup2Reducer from 'store/ad-management-adgroup2';
import LtvSettingsOther from 'store/ltv/settings/other';
import LtvSettingsCsvOrders from 'store/ltv/settings/csv-orders';
import AgencyManagementReducer from 'store/agency-management';
import TagManagementReducer from 'store/tag-management';
import LandingPageAnalyzeReducer from 'store/landing-page-analyze';
import ExternalServicesReducer from 'store/external-services';
import UserProfileReducer from 'store/user-profile';
import MediaSyncManagementReducer from 'store/media-sync-management';
import DataExportReducer from 'store/data-export';
import UsageStatusReducer from 'store/usage-status';
import ApiManagementReducer from 'store/api-management';
import ContactDemandReducer from 'store/contact/demand';
import ContactReducer from 'store/contact';
import SystemSettingReducer from 'store/system-setting';
import SystemSettingDisplayReducer from 'store/system-setting/display';
import PageManagementReducer from 'store/page-management';
import ImpressionAdCostReducer from 'store/impression-ad-cost';
import LpoLinkReducer from 'store/lpo/link';
import LpoPeriodReducer from 'store/lpo/period';
import LogPageAnalyzeReducer from 'store/log/page-analyze';
import LogPeriodAnalyzeReducer from 'store/log/period-analyze';
import LogRouteAnalyzeReducer from 'store/log/route-analyze';
import CapiSettingReducer from 'store/capi-setting';
import SCSettingReducer from 'store/search-console-setting';
import LineAddFriendSettingReducer from 'store/line-add-friend-settings';

const CacheReducers = combineReducers({
  dashboard: dashboardReducer,
  userManagement: UserManagementReducer,
  ltvSettingsProduct: LtvSettingsProductReducer,
  ltvSettingsCondition: LtvSettingsConditionReducer,
  ltvSettingsAd: LtvSettingsAdReducer,
  ltvSettingsOffer: LtvSettingsOfferReducer,
  ltvSettingsOrder: LtvSettingsOrderReducer,
  ltvAnalyze: LtvAnalyzeReducer,
  masterData: MasterDataReducer,
  periodAnalysis: PeriodAnalysisReducer,
  categoryAnalysis: CategoryAnalysisReducer,
  detailAnalysis: DetailAnalysisReducer,
  landingPageAnalyze: LandingPageAnalyzeReducer,
  cvAttribute: CVAttributeReducer,
  cvFlow: CVFlowReducer,
  costAllocation: CostAllocationReducer,
  adManagement: AdManagementReducer,
  adManagementMedia: AdManagementMediaReducer,
  adManagementAdGroup1: AdManagementAdGroup1Reducer,
  adManagementAdGroup2: AdManagementAdGroup2Reducer,
  ltvSettingsOther: LtvSettingsOther,
  ltvSettingsCsvOrders: LtvSettingsCsvOrders,
  agencyManagement: AgencyManagementReducer,
  tagManagement: TagManagementReducer,
  externalServices: ExternalServicesReducer,
  userProfile: UserProfileReducer,
  mediaSyncManagement: MediaSyncManagementReducer,
  dataExport: DataExportReducer,
  usageStatus: UsageStatusReducer,
  apiManagement: ApiManagementReducer,
  contactDemand: ContactDemandReducer,
  contact: ContactReducer,
  systemSetting: SystemSettingReducer,
  systemSettingDisplay: SystemSettingDisplayReducer,
  pageManagement: PageManagementReducer,
  impressionAdCost: ImpressionAdCostReducer,
  lpoLink: LpoLinkReducer,
  lpoPeriod: LpoPeriodReducer,
  logPageAnalyze: LogPageAnalyzeReducer,
  logPeriodAnalyze: LogPeriodAnalyzeReducer,
  logRouteAnalyze: LogRouteAnalyzeReducer,
  capiSetting: CapiSettingReducer,
  scSetting: SCSettingReducer,
  lineAddFriendSetting: LineAddFriendSettingReducer,
});

export default CacheReducers;
