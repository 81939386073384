import React, { useState, useMemo, useRef } from 'react';
import { Dropdown, Form, Button } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import {
  array,
  object,
  string,
  number,
  func,
  arrayOf,
  shape,
  oneOfType,
  oneOf,
  node,
  bool,
} from 'prop-types';

import { MASTER_DATA_FILTER_LIMIT } from 'domain/consts';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';

import './searchable-select.scss';
import classNames from 'classnames';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <Button
      ref={ref}
      variant="link"
      size="sm"
      className="searchable-select__toggle"
      onKeyDown={(e) => e.preventDefault()}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  );
});

CustomToggle.propTypes = {
  children: oneOfType([array]).isRequired,
  onClick: func.isRequired,
};

const CustomMenu = React.forwardRef(
  (
    {
      isLoading,
      children,
      style,
      className,
      title,
      width,
      maxItem,
      footer,
      onSearch,
    },
    ref
  ) => {
    const [keyword, setKeyword] = useState('');
    const scrollbarWrapperRef = useRef(null);

    const isEmptyData = !isLoading && children.length <= 0;

    const doSearch = debounce((e) => {
      const { value } = e.target;
      setKeyword(value);
      onSearch(value);
    }, 200);

    return (
      <div ref={ref} style={{ ...style, width }} className={className}>
        <div className="searchable-select__header">{title}</div>
        <div
          className="searchable-select__search"
          hidden={isEmptyData && !keyword}
        >
          <Form.Group className="has-icon">
            <Form.Control
              size="sm"
              className="text-left"
              placeholder="値を入力"
              onChange={doSearch}
            />
            <i className="fa fa-search" />
          </Form.Group>
        </div>
        <div
          className="searchable-select__content"
          ref={scrollbarWrapperRef}
          hidden={isEmptyData}
        >
          <DataSyncLoader isLoading={isLoading}>
            <ScrollbarWrapper
              ref={scrollbarWrapperRef}
              maxContent={160}
              sizeScroll={5}
              alignScroll={5}
            >
              {React.Children.toArray(children).filter(
                (child) => child.props.children
              )}
              {children.length > maxItem && (
                <div className="searchable-select__message">
                  件数が多いため、{maxItem}件まで表示しています。
                  <br />
                  検索機能をご活用ください。
                </div>
              )}
            </ScrollbarWrapper>
          </DataSyncLoader>
        </div>
        <div className="searchable-select__empty" hidden={!isEmptyData}>
          {`設定できる${title}がありません`}
        </div>
        {footer && <div className="searchable-select__footer">{footer}</div>}
      </div>
    );
  }
);

CustomMenu.propTypes = {
  isLoading: bool,
  children: oneOfType([array]).isRequired,
  style: oneOfType([object]).isRequired,
  className: string.isRequired,
  title: string.isRequired,
  width: number.isRequired,
  maxItem: string.isRequired,
  footer: oneOf([node, string]),
  onSearch: func,
};

CustomMenu.defaultProps = {
  isLoading: false,
  footer: '',
  onSearch: () => {},
};

function SearchableSelect(props) {
  const {
    isLoading,
    isDisbaled,
    isDropdownSelected,
    title,
    items,
    selected,
    maxItem,
    footer,
    error,
    width,
    onToggle,
    onSelect,
    isHideClearBtn,
    onClear,
    onSearch,
  } = props;

  const ref = useRef();
  const [alignRight, setAlignRight] = useState(false);
  const [key, setKey] = useState(Math.random());

  const isSelected = useMemo(
    () => Object.values(selected).some((value) => !!value),
    [selected]
  );

  const handleToggle = (isShow, e) => {
    onToggle(isShow);
    setAlignRight(false);
    const keyCodeTab = 9;
    if (!isShow && e.keyCode !== keyCodeTab) {
      // Re-render to show default style for next time
      setKey(Math.random());
      // Get list without filters for next time
      onSearch();
    }

    // Calculate position display left or right of the dropdown menu
    if (!isDropdownSelected) {
      if (!isShow || !isSelected || isEmpty(ref.current)) return;
      const { offsetWidth: widthLabel } = ref.current.querySelector(
        '.searchable-select__label'
      );
      if (widthLabel > width) {
        setAlignRight(true);
      }
    }
  };

  return (
    <div
      className={classNames('searchable-select', {
        'dropdown-select': isDropdownSelected,
      })}
      ref={ref}
    >
      {!isDropdownSelected && isSelected && (
        <ErrorTooltipWrapper isError={!!error} errorMess={error} ref={ref}>
          <div className="searchable-select__label">{selected.name}</div>
        </ErrorTooltipWrapper>
      )}
      <Dropdown
        key={key}
        onSelect={(value) => onSelect(value)}
        onToggle={(isShow, e) => handleToggle(isShow, e)}
      >
        {isDropdownSelected ? (
          <ErrorTooltipWrapper isError={!!error} errorMess={error} ref={ref}>
            <Dropdown.Toggle size="sm" variant="select" disabled={isDisbaled}>
              <div
                className="dropdown-select-search__label"
                title={selected.name}
              >
                {selected.name}
              </div>
            </Dropdown.Toggle>
          </ErrorTooltipWrapper>
        ) : (
          <ErrorTooltipWrapper
            isError={!!error && !isSelected}
            errorMess={error}
            ref={ref}
          >
            <Dropdown.Toggle as={CustomToggle}>
              {isSelected ? '変更' : '選択'}
            </Dropdown.Toggle>
          </ErrorTooltipWrapper>
        )}

        <Dropdown.Menu
          flip={false}
          isLoading={isLoading}
          className="dropdown-menu-shadow searchable-select__menu"
          alignRight={alignRight}
          as={CustomMenu}
          width={width}
          title={title}
          maxItem={maxItem}
          footer={footer}
          error={error}
          onSearch={onSearch}
        >
          {items.map((item) => (
            <Dropdown.Item
              eventKey={item.id}
              active={item.id === selected.id}
              key={item.id}
              disabled={!!item.disabled}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {!isHideClearBtn && (
        <Button
          variant="link"
          size="sm"
          className="searchable-select__clear"
          disabled={!isSelected}
          onClick={onClear}
        >
          クリア
        </Button>
      )}
    </div>
  );
}

SearchableSelect.propTypes = {
  isLoading: bool,
  isDropdownSelected: bool,
  isDisbaled: bool,
  title: string.isRequired,
  items: arrayOf(shape({ id: string, name: string })).isRequired,
  selected: shape({ id: string, name: string }),
  maxItem: number,
  footer: oneOf([node, string]),
  error: string,
  width: number,
  onToggle: func,
  isHideClearBtn: bool,
  onSelect: func.isRequired,
  onClear: func.isRequired,
  onSearch: func,
};

SearchableSelect.defaultProps = {
  isLoading: false,
  isDisbaled: false,
  isDropdownSelected: false,
  selected: {},
  maxItem: MASTER_DATA_FILTER_LIMIT,
  isHideClearBtn: false,
  footer: '',
  error: '',
  width: 300,
  onToggle: () => {},
  onSearch: () => {},
};

export default SearchableSelect;
