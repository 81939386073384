/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PropTypes, shape } from 'prop-types';
import classNames from 'classnames';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { STATUS_LOADING, STATUS_SUCCEEDED } from 'store/helper';
import selectors from 'store/customview/selectors';
import actions from 'store/customview/actions';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import Loader from 'views/atoms/loader/Loader';
import ViewOverwriteModal from 'views/molecules/modal/ViewOverwriteModal';
import DeleteModal from 'views/molecules/modal/DeleteModal';
import CustomViewModal from 'views/organism/customview/component/CustomViewModal';
import { MAX_CUSTOM_VIEW_COUNT } from 'domain/consts';
import SettingSelectors from 'store/settings/selectors';
import SettingsForViewActions from 'store/settings-for-view/actions';
import SettingsForViewSelectors from 'store/settings-for-view/selectors';
import DisplayItemsForViewActions from 'store/display-items-for-view/actions';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import DisplayItemsApi from 'services/api/DisplayItemsApi';
import screenTitleConfigs from 'services/common/screenTitleConfigs';
import screenPermissionConfigs from 'domain/permissions/screenPermissionConfigs';
import { TAB_AD, TAB_ALL, TAB_COMMON } from 'services/consts';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import { INFLOW_ANALYSIS } from 'services/routes/constants';
import { FUNC_CODE_INFLOW_ANALYSIS } from 'domain/settings/display-items';

const CustomViewDropdown = (props) => {
  const { onActive, active = false, crossDeviceReflectionData, pageId, tabId } = props;
  const [customViewModalShow, toggleCustomViewModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [overwriteViewValue, setOverwriteViewValue] = useState(false);
  const [iconToggle, toggleIcon] = useState(active);
  const [overwriteView, setOverwriteView] = useState(null);
  const [deleteView, updateDeleteView] = useState(null);
  const scrollbarWrapperRef = useRef(null);
  const dispatch = useDispatch();
  const { checkPermissions } = useCheckPermissions();
  const [permissionErrorOccured, setPermissionErrorOccured] = useState(false);

  const viewsList = useSelector(selectors.getList, shallowEqual);
  const funcId = useSelector(SettingSelectors.getFuncId, shallowEqual);
  const status = useSelector(selectors.getStatus, shallowEqual);
  const statusForView = useSelector(selectors.getStatusForView, shallowEqual);
  const viewPageId = useSelector(SettingsForViewSelectors.getPage, shallowEqual);

  const handleOnApplyView = (e, id) => {
    e.preventDefault();
    dispatch(actions.apply({ id }));
  };

  const handleToggleFavorite = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actions.toggleFavorite({ id }));
  };

  // Check permissions and select tabs
  const checkAndSetPermittedTab = (chkPageId) => {
    setPermissionErrorOccured(false);
    if ((screenPermissionConfigs[chkPageId][TAB_AD].length === 0 || checkPermissions('anyOf', screenPermissionConfigs[chkPageId][TAB_AD]))
      && (checkPermissions('anyOf', screenPermissionConfigs[chkPageId][TAB_ALL]))) {
      dispatch(SettingsForViewActions.changeTab(TAB_COMMON));
      dispatch(actions.setLoadedViewTab());
      return true;
    }
    if (checkPermissions('anyOf', screenPermissionConfigs[chkPageId][TAB_AD])) {
      dispatch(SettingsForViewActions.changeTab(TAB_AD));
      dispatch(actions.setLoadedViewTab());
      return true;
    }
    setPermissionErrorOccured(true);
    return false;
  }

  const viewSelected = viewsList.some(v => v.selected)
  const handleCreateNew = (e) => {
    e.preventDefault();
    dispatch(actions.requestStartForView());
    const funcId = DisplayItemsService.getFuncIdByPageId(pageId);
    const viewPageId = funcId === FUNC_CODE_INFLOW_ANALYSIS ? INFLOW_ANALYSIS : pageId;
    dispatch(SettingsForViewActions.changePage(viewPageId));
    if (!checkAndSetPermittedTab(viewPageId)) {
      return;
    }
    if (!viewSelected) {
      const { displayitemsApi = DisplayItemsApi } = screenTitleConfigs[viewPageId];
      dispatch(DisplayItemsForViewActions.fetch({ funcId, displayitemsApi }));
    }
    dispatch(actions.fetchEditSucceeded({ edit: {} }));
    setIsEdit(false);
    setOverwriteViewValue(null);
    toggleCustomViewModal(true);
  };

  const handleOverWriteNew = (viewValue) => {
    dispatch(actions.requestStartForView());
    const funcId = DisplayItemsService.getFuncIdByPageId(pageId);
    const viewPageId = funcId === FUNC_CODE_INFLOW_ANALYSIS ? INFLOW_ANALYSIS : pageId;
    dispatch(SettingsForViewActions.changePage(viewPageId));
    if (!checkAndSetPermittedTab(viewPageId)) {
      return;
    }
    if (!viewSelected) {
      const { displayitemsApi = DisplayItemsApi } = screenTitleConfigs[viewPageId];
      dispatch(DisplayItemsForViewActions.fetch({ funcId, displayitemsApi }));
    }
    dispatch(actions.fetchEditSucceeded({ edit: {} }));
    setIsEdit(false);
    setOverwriteView(false);
    setOverwriteViewValue(viewValue);
    toggleCustomViewModal(true);
  };

  const handleEditView = (e, id) => {
    e.preventDefault();
    dispatch(actions.requestStartForView());
    dispatch(actions.fetchEditSucceeded({ edit: {} }));
    dispatch(actions.fetchEdit({ id }));
    setIsEdit(true);
    setOverwriteViewValue(null);
    toggleCustomViewModal(true);
  };

  useEffect(() => {
    if (!isEdit) {
      return;
    }
    if (!statusForView && statusForView !== STATUS_SUCCEEDED) {
      return;
    }
    if (!checkAndSetPermittedTab(viewPageId)) {
      return;
    }
    dispatch(actions.setLoadedViewTab());
  }, [isEdit, viewPageId, statusForView]);

  useEffect(() => {
    if (permissionErrorOccured) {
      throw new Error('Unauthorized pageId selected');
    }
  }, [permissionErrorOccured]);

  const handleOverWrite = (viewName = null) => {
    handleOverWriteNew(viewName);
  };

  const handleOverWriteView = (e) => {
    e.preventDefault();
    setOverwriteView(true);
  };

  const handleDeleteView = (e, id, name) => {
    e.preventDefault();
    updateDeleteView({ id, name });
  };

  const handleDelete = (id = null) => {
    if (id) {
      dispatch(actions.remove({ id }));
    }
    updateDeleteView(null);
  };

  const handleToggleViewMenu = (isShow) => {
    toggleIcon(isShow);
    if (isShow) {
      onActive({ target: 'CustomViewDropdown' });
    }
  };

  useEffect(() => {
    if (funcId) {
      dispatch(actions.fetchList());
    }
  }, [dispatch, funcId]);

  const [isHover, setHover] = useState(0);

  const handleHideModal = useCallback(() => {
    toggleCustomViewModal(false);
    setIsEdit(false);
  }, []);

  return (
    <>
      <Dropdown
        as="a"
        role="button"
        bsPrefix="dropdown nav-link nav-link--icon"
        className={`${iconToggle ? 'active' : ''}`}
        onToggle={handleToggleViewMenu}
      >
        <Dropdown.Toggle as="div" bsPrefix="nav-link-custom-view">
          <div className="d-flex justify-content-center">
            <span className="icon-svg icon-custom-view" />
          </div>

          <div>ビュー</div>
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix="dropdown-menu setting-views-dropdown">
          <Dropdown.Item
            as="div"
            role="button"
            disabled={viewsList.length === MAX_CUSTOM_VIEW_COUNT}
            onClick={(e) => handleCreateNew(e)}
            bsPrefix={
              viewsList.length === MAX_CUSTOM_VIEW_COUNT
                ? 'setting-views-dropdown__create ebis--disabled'
                : 'setting-views-dropdown__create'
            }
          >
            現在の設定でビューを新規作成
          </Dropdown.Item>
          <Dropdown.Item
            as="div"
            role="button"
            onClick={(e) => handleOverWriteView(e)}
            bsPrefix="setting-views-dropdown__create"
          >
            現在の設定で既存のビューを更新
          </Dropdown.Item>
          <div className="setting-views-dropdown__list">
            <Dropdown.Header bsPrefix="setting-views-dropdown__summary">{`保存したビュー ${viewsList.length}/${MAX_CUSTOM_VIEW_COUNT}件`}</Dropdown.Header>
            {viewsList.length === 0 && (
              <Dropdown.Item
                as="div"
                disabled
                bsPrefix="setting-views-dropdown__empty"
              >
                {status !== STATUS_LOADING ? (
                  <>
                    保存したビューはありません
                  </>
                ) : (
                  <Loader />
                )}
              </Dropdown.Item>
            )}
            <div
              ref={scrollbarWrapperRef}
              className="setting-views-dropdown__list-detail"
            >
              <ScrollbarWrapper
                ref={scrollbarWrapperRef}
                maxContent={160}
                sizeScroll={5}
                alignScroll={5}
              >
                {viewsList.map((item) => {
                  const { id, name, selected, favorite, order } = item;
                  return (
                    <Dropdown.Item
                      as="div"
                      key={id}
                      style={{ order }}
                      role="button"
                      bsPrefix="setting-views-dropdown__item"
                    >
                      <div
                        className="fa-stack setting-views-dropdown__favorite"
                        onClick={(e) => handleToggleFavorite(e, id)}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip className="tooltip-common--bookmark">デフォルトでこのビューを表示する</Tooltip>
                          }
                        >
                          <i
                            onMouseEnter={() => setHover(id)}
                            onMouseLeave={() => setHover(0)}
                            className={classNames({
                              'setting-views-dropdown__favorite-icon': true,
                              fal: true,
                              'unpinned': true,
                              fas: favorite || isHover === id,
                              'pinned': favorite || isHover === id,
                              'setting-views-dropdown__favorite--active':
                                favorite || isHover === id,
                            })}
                          />
                        </OverlayTrigger>
                      </div>
                      <div
                        className={classNames({
                          'setting-views-dropdown__item-title': true,
                          'setting-views-dropdown__item-title--apply': selected,
                        })}
                        onClick={(e) => handleOnApplyView(e, id)}
                      >
                        {name}
                      </div>
                      <div
                        className="fa-stack setting-views-dropdown__action"
                        onClick={(e) => {
                          handleEditView(e, id);
                        }}
                      >
                        <i className="fas fa-circle fa-stack-2x setting-views-dropdown__action-icon-bg" />
                        <i className="fas fa-pen fa-stack-1x fa-inverse setting-views-dropdown__action-icon" />
                      </div>
                      <div
                        className="fa-stack setting-views-dropdown__action"
                        onClick={(e) => {
                          handleDeleteView(e, id, name);
                        }}
                      >
                        <i className="fas fa-circle fa-stack-2x setting-views-dropdown__action-icon-bg" />
                        <i className="fas fa-trash-alt fa-stack-1x fa-inverse setting-views-dropdown__action-icon" />
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </ScrollbarWrapper>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {customViewModalShow && (
        <CustomViewModal
          show
          onHide={handleHideModal}
          isEdit={isEdit}
          isOverwrite={overwriteViewValue !== null}
          overwriteViewValue={overwriteViewValue}
          viewsCount={viewsList.length}
          crossDeviceReflectionData={crossDeviceReflectionData}
        />
      )}
      {overwriteView && (
        <ViewOverwriteModal
          isShow
          loading={status === STATUS_LOADING}
          title="既存のビューを選択"
          content={viewsList}
          onHide={() => setOverwriteView(false)}
          onSubmit={(viewName) => handleOverWrite(viewName)}
        />
      )}
      {deleteView && (
        <DeleteModal
          isShow
          title="ビューを削除しますか？"
          content={deleteView.name}
          onHide={() => handleDelete()}
          onSubmit={() => handleDelete(deleteView.id)}
        />
      )}
    </>
  );
};

CustomViewDropdown.propTypes = {
  onActive: PropTypes.func,
  active: PropTypes.bool,
  crossDeviceReflectionData: shape([]),
  pageId: shape([]),
  tabId: shape([]),
};

CustomViewDropdown.defaultProps = {
  onActive: () => {},
  active: false,
  crossDeviceReflectionData: [],
  pageId: [],
  tabId: [],
};

export default CustomViewDropdown;
