import * as DisplayItems from './display-items';

const SettingDisplayItems = {
  groupList: {
    [DisplayItems.PERIOD]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    // [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.CATEGORY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AD_GROUP1]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.AD_GROUP2]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.SYNC_CATEGORY]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
    [DisplayItems.MEDIA_SIDE_GROUP]:
      DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  // 媒体シンク軸優先時に非表示にする項目
  denyMediaSyncList: [
    DisplayItems.CATEGORY,
    DisplayItems.AD_GROUP1,
    DisplayItems.AD_GROUP2,
  ],
  // エビス軸優先時に非表示にする項目
  denyEbisList: [
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.MEDIA_SIDE_CAMPAIGN,
    DisplayItems.MEDIA_SIDE_GROUP,
  ],
  displayFreezeList: [DisplayItems.PERIOD],
  orderList: {
    [DisplayItems.PERIOD]: 1,
    [DisplayItems.CHANNEL]: 2,
    [DisplayItems.CATEGORY]: 3,
    [DisplayItems.AD_GROUP1]: 4,
    [DisplayItems.AD_GROUP2]: 5,
    [DisplayItems.IMPRESSION]: 6,
    [DisplayItems.CNT_ACCESS]: 7,
    [DisplayItems.CTR]: 8,
    [DisplayItems.CNT_SESSION]: 11,
    [DisplayItems.CNT_PV]: 12,
    [DisplayItems.PV_AVG]: 13,
    [DisplayItems.CNT_RETURN]: 14,
    [DisplayItems.PER_RETURN]: 15,
    [DisplayItems.TOTAL_STAY_TIME]: 16,
    [DisplayItems.TOTAL_STAY_TIME_AVG]: 17,
    [DisplayItems.TOTAL_LATENCY_TIME]: 18,
    [DisplayItems.LATENCY_TIME_AVG]: 19,
    [DisplayItems.CNT_CV_DETAIL]: 20,
    [DisplayItems.CNT_CV_TOTAL]: 21,
    [DisplayItems.CVR_DETAIL]: 22,
    [DisplayItems.CVR_TOTAL]: 23,
    [DisplayItems.CPA]: 24,
    [DisplayItems.CNT_INDIRECT2]: 25,
    [DisplayItems.CNT_INDIRECT3]: 26,
    [DisplayItems.CNT_INDIRECT4]: 27,
    [DisplayItems.CNT_INDIRECT5]: 28,
    [DisplayItems.CNT_INDIRECT_OTHER]: 29,
    [DisplayItems.INDIRECT_TOTAL]: 30,
    [DisplayItems.CNT_FIRST]: 31,
    [DisplayItems.DIRECT_RATE]: 32,
    [DisplayItems.INDIRECT_RATE]: 33,
    [DisplayItems.RCV]: 34,
    [DisplayItems.AMOUNT]: 35,
    [DisplayItems.RAMOUNT]: 36,
    [DisplayItems.COST]: 37,
    [DisplayItems.CPC]: 38,
    [DisplayItems.TCPA]: 39,
    [DisplayItems.ROAS]: 40,
    [DisplayItems.TROAS]: 41,
    [DisplayItems.SYNC_CATEGORY]: 41,
    [DisplayItems.MEDIA_SIDE_CAMPAIGN]: 43,
    [DisplayItems.MEDIA_SIDE_GROUP]: 44,
    [DisplayItems.CROSSDEVICE_DIFF_COMPARISON]: 45,
  },
  displayDefaultList: [
    DisplayItems.PERIOD,
    DisplayItems.SYNC_CATEGORY,
    DisplayItems.CATEGORY,
    DisplayItems.IMPRESSION,
    DisplayItems.CNT_ACCESS,
    DisplayItems.CTR,
    DisplayItems.PV_AVG,
    DisplayItems.CNT_CV_DETAIL,
    DisplayItems.CNT_CV_TOTAL,
    DisplayItems.CPA,
    DisplayItems.CNT_FIRST,
    DisplayItems.AMOUNT,
    DisplayItems.COST,
    DisplayItems.ROAS,
  ],
  sortDefaultList: {
    [DisplayItems.PERIOD]: DisplayItems.SORT_DIRECTION_ASC,
  },
  sortDisabledList: [DisplayItems.CNT_CV_DETAIL, DisplayItems.CVR_DETAIL],
};

const SettingAdDisplayItems = {
  ...SettingDisplayItems,
};
const SettingAllDisplayItems = {
  ...SettingDisplayItems,
  groupList: {
    ...SettingDisplayItems.groupList,
    [DisplayItems.CHANNEL]: DisplayItems.DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  displayFreezeList: [...SettingDisplayItems.displayFreezeList],
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    DisplayItems.CHANNEL,
  ],
  requiredList: [DisplayItems.CHANNEL],
};

export const getAd = () => {
  const displayItemsBase = DisplayItems.getAd();
  return DisplayItems.getItems({
    ...SettingAdDisplayItems,
    displayItemsBase,
  });
};

export const getAdDefault = () => {
  const displayItemsBase = getAd();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAll = () => {
  const displayItemsBase = DisplayItems.getAll();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return DisplayItems.getDefault(displayItemsBase);
};
