import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import {
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
  node,
  object,
} from 'prop-types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import useClickOutside from 'services/custom-hooks/useClickOutside';
import CheckboxElement from 'views/atoms/CheckboxElement';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import {
  MASTER_DATA_FILTER_LIMIT,
  MAX_SELECTED_MEDIA_SETTING_MAIL,
} from 'domain/consts';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import VirtualScrollWrapper from 'views/atoms/scrollbar/VirtualScrollWrapper';
import useVirtualScroller from 'services/custom-hooks/useVirtualScroller';

import './SelectPopup.scss';

const SelectPopupMenu = ({
  loading,
  title,
  options,
  value,
  limitDisplay,
  onChange,
  onSearch,
  onHide,
  show,
  mergeValueLable,
  register,
  isSettingMail,
  maxItemSelect,
  mapperKey,
}) => {
  const keyLabel = mapperKey.label;
  const keyValue = mapperKey.value;

  const [multipleValue, setMultipleValue] = useState(value);
  const multipleValueCount = Object.keys(multipleValue).length;
  const [keyword, setKeyword] = useState(null);
  const [addedBySearch, addBySearch] = useState({});
  const scrollbarWrapperRef = useRef(null);
  const refInput = useRef(null);
  const clickRef = useRef(null);

  const { isExtendRegister, formRegister, itemsRegister } = register;

  const totalItems = Object.keys(options).length;

  const onClickOutside = () => {
    if (show) {
      onHide();
      setMultipleValue(value);
    }
  };
  useClickOutside(clickRef, onClickOutside);

  const optionsDisplay = useMemo(() => {
    let cloneOptions = { ...options };

    if (!isEmpty(keyword)) return cloneOptions;

    const valueSelected = { ...itemsRegister, ...value };
    cloneOptions = { ...cloneOptions, ...addedBySearch };

    // Remove selected items that exceed limitDisplay
    let optionsSelecteds = Object.keys(valueSelected || {});
    if (limitDisplay && optionsSelecteds.length > limitDisplay) {
      optionsSelecteds = optionsSelecteds.slice(0, limitDisplay);
    }

    // Remove items that exceed limitDisplay
    cloneOptions = omit(cloneOptions, optionsSelecteds);
    const optionsKey = Object.keys(cloneOptions);
    const totalItemsDisplay = optionsKey.length + optionsSelecteds.length;
    if (limitDisplay && totalItemsDisplay > limitDisplay) {
      const keysDelete = optionsKey.slice(limitDisplay - totalItemsDisplay);
      cloneOptions = omit(cloneOptions, keysDelete);
    }

    // Format data to display UI: id: {label: '', value: '', order: 0}
    // order: 0 => items selected always on top
    const optionsSelected = optionsSelecteds.reduce((acc, id) => {
      const obj = { ...acc, [id]: { ...valueSelected[id], order: 0 } };
      return obj;
    }, {});

    return { ...optionsSelected, ...cloneOptions };
  }, [addedBySearch, itemsRegister, keyword, limitDisplay, options, value]);

  const isEmptyData = Object.keys(optionsDisplay).length <= 0;
  const isHiddenSearchBox = isEmptyData && !keyword;

  /**
   * Chack all key in display is in selected too
   * @param {Object} display display obj
   * @param {Object} selected Selected obj
   * @returns bool
   */
  const isAllInArray = (display, selected) => {
    let result = true;
    Object.keys(display).map((key) => {
      if (!selected[key]) {
        result = false;
      }
      return true;
    });
    return result;
  };

  const [isChange, isSelected, isSelectedAll] = useMemo(() => {
    const newIdsSelected = Object.keys(multipleValue).sort();
    const oldIdsSelected = Object.keys(value).sort();
    return [
      !isEqual(newIdsSelected, oldIdsSelected),
      loading || newIdsSelected.length === 0,
      loading || isAllInArray(optionsDisplay, multipleValue),
    ];
  }, [value, multipleValue, optionsDisplay, loading]);

  const doSearch = debounce((query) => {
    onSearch(query);
  }, 200);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setKeyword(query);
    doSearch(query);
  };

  const handleMultipleChange = (name, isChecked) => {
    const state = { ...multipleValue };
    if (isChecked) {
      state[name] = { ...optionsDisplay[name] };
      addBySearch({ ...addedBySearch, [name]: state[name] });
    } else {
      delete state[name];
    }
    setMultipleValue(state);
  };

  useEffect(() => {
    if (show) {
      refInput.current.focus();
    } else if (keyword !== null) {
      setKeyword('');
      doSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, keyword]);

  const handleMultipleCheckAll = () => {
    const data = { ...multipleValue };
    Object.keys(optionsDisplay).map((key) => {
      data[key] = optionsDisplay[key];
      return key;
    });

    setMultipleValue(data);
  };
  const handleMultipleClearAll = () => {
    setMultipleValue({});
  };

  // const canSelect = multipleValueCount < displayMax;
  const canSelect = multipleValueCount < maxItemSelect;
  const selectedLimitNotify = isSettingMail
    ? `残り${maxItemSelect - multipleValueCount}件選択可`
    : `${multipleValueCount}件選択中`;

  const handleClickCancel = () => {
    if (show) {
      onHide();
      setMultipleValue(value);
    }
  };

  const handleClickChangeMultiple = () => {
    onHide();
    onChange(multipleValue);
  };

  useEffect(() => {
    setMultipleValue(value);
  }, [value]);

  useEffect(() => {
    if (isEmpty(itemsRegister)) return;
    setKeyword('');
    setMultipleValue({ ...itemsRegister, ...multipleValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsRegister]);

  const selectPopupListClass = classNames({
    'select-popup__item-list': true,
    'select-popup__item-list--register': !isEmpty(formRegister),
  });

  return (
    <div
      className="select-popup__content"
      style={{
        display: show ? 'block' : 'none',
        top: 20,
        left: 0,
        position: 'absolute',
      }}
      ref={clickRef}
    >
      <div className="select-popup__title">{title}</div>
      <div className="select-popup__body">
        <div
          className="select-popup__search-group"
          hidden={isExtendRegister || isHiddenSearchBox}
        >
          <input
            ref={refInput}
            placeholder="値を入力"
            value={keyword}
            type="text"
            className="select-popup__search-input"
            onChange={handleSearchChange}
          />
          <i className="select-popup__search-icon fa fa-search" />
        </div>
        <div
          className="select-popup__multiple-actions"
          hidden={isExtendRegister || isHiddenSearchBox}
        >
          {!isSettingMail && (
            <Button
              variant="link"
              size="sm"
              className="p-0"
              disabled={isSelectedAll}
              onClick={handleMultipleCheckAll}
            >
              すべて選択
            </Button>
          )}
          <Button
            variant="link"
            size="sm"
            className="p-0"
            disabled={isSelected}
            onClick={handleMultipleClearAll}
          >
            クリア
          </Button>
          <span className="select-popup__multiple-limit">
            {selectedLimitNotify}
          </span>
        </div>
        <DataSyncLoader isLoading={loading}>
          <div
            ref={scrollbarWrapperRef}
            className={selectPopupListClass}
            hidden={isExtendRegister || isEmptyData}
          >
            <ScrollbarWrapper
              ref={scrollbarWrapperRef}
              maxContent={160}
              sizeScroll={5}
              alignScroll={5}
            >
              <div className="select-popup__item-list--order">
                {Object.keys(optionsDisplay).map((key, index) => {
                  const option = optionsDisplay[key];
                  const checked = option[keyValue] in multipleValue;
                  const itemMultipleClass = classNames('select-popup__item', {
                    'select-popup__item--selected': checked,
                    'select-popup__item--multiple': true,
                    [option[keyValue]]: true,
                  });
                  return (
                    <div
                      key={option[keyValue]}
                      style={{
                        order: option?.order ?? index + 1,
                      }}
                      className={itemMultipleClass}
                    >
                      <CheckboxElement
                        name={option[keyValue]}
                        label={
                          mergeValueLable
                            ? `${option[keyValue]}[${option[keyLabel]}]`
                            : option[keyLabel]
                        }
                        checked={checked}
                        // disabled={!checked && !canSelect} // User can select more than DisplayMax items
                        disabled={isSettingMail && !checked && !canSelect}
                        onChange={(n, isChecked) =>
                          handleMultipleChange(n, isChecked)
                        }
                      />
                    </div>
                  );
                })}
              </div>
              {totalItems > limitDisplay && (
                <div className="select-popup__item-list--warning">
                  件数が多いため、{limitDisplay}件まで表示しています。
                  <br />
                  検索機能をご活用ください。
                </div>
              )}
            </ScrollbarWrapper>
          </div>
          <div
            className="select-popup__empty"
            hidden={isExtendRegister || !isEmptyData}
          >
            {`設定できる${title}がありません`}
          </div>
          {!isEmpty(formRegister) && (
            <div className="select-popup__register" hidden={!isExtendRegister}>
              {formRegister}
            </div>
          )}
          <div className="select-popup__buttons" hidden={isExtendRegister}>
            <Button
              variant="link"
              size="sm"
              className="btn-cancel"
              onClick={handleClickCancel}
            >
              キャンセル
            </Button>
            <Button
              variant="secondary"
              size="sm"
              disabled={!isChange}
              onClick={handleClickChangeMultiple}
            >
              OK
            </Button>
          </div>
        </DataSyncLoader>
      </div>
    </div>
  );
};

SelectPopupMenu.propTypes = {
  title: string.isRequired,
  options: shape({}).isRequired,
  mapperKey: shape({}).isRequired,
  value: shape({}),
  limitDisplay: number,
  show: bool,
  onChange: func,
  onSearch: func,
  onHide: func,
  loading: bool,
  mergeValueLable: bool,
  register: shape({
    isExtendRegister: bool,
    formRegister: oneOfType([node, bool]),
    itemsRegister: oneOfType([object]),
  }),
  isSettingMail: bool,
  maxItemSelect: number,
};

SelectPopupMenu.defaultProps = {
  value: {},
  // multiple: false,
  limitDisplay: undefined,
  show: false,
  onChange: () => {},
  onSearch: () => {},
  onHide: () => {},
  loading: false,
  mergeValueLable: false,
  register: {
    isExtendRegister: false,
    formRegister: false,
    itemsRegister: {},
  },
  isSettingMail: false,
  maxItemSelect: MAX_SELECTED_MEDIA_SETTING_MAIL,
};

function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  return {
    top: rect.top + rect.height + 4,
    left: rect.left + scrollLeft,
    width: rect.width,
    height: rect.height,
  };
}

const SelectPopupMultiple = ({
  value,
  displayMax,
  title,
  options,
  onChange,
  onSearch,
  onToggle,
  loading,
  limitDisplay,
  note,
  error,
  mergeValueLable,
  register,
  renderAhead,
  isSettingMail,
  maxItemSelect,
  mapperKey,
}) => {
  const keyLabel = mapperKey.label;
  const keyValue = mapperKey.value;

  const [state, updateState] = useState({
    position: { top: 0, left: 0, width: 0, height: 0 },
    show: false,
  });
  const [item, setItem] = useState({});
  const [errorStr, changeErrorStr] = useState('');

  const [dataOrigin, itemCount] = useMemo(() => {
    const items = Object.values(item);
    return [items, items.length];
  }, [item]);

  useEffect(() => {
    changeErrorStr(error?.message || '');
  }, [error]);

  const handleChange = (val) => {
    changeErrorStr('');
    onChange(val);
    setItem(val);
    updateState((prev) => {
      return { ...prev, show: false };
    });
  };
  const handleClear = () => {
    onChange({});
    setItem({});
  };
  const handleClickChoice = (e) => {
    onToggle(true);
    updateState({ position: offset(e.target), show: true });
  };
  const handleHide = () => {
    onToggle(false);
    updateState((prev) => {
      return { ...prev, show: false };
    });
  };

  useEffect(() => {
    setItem(value);
  }, [value]);

  const tooltipRef = useRef();
  const scrollRef = useRef();
  const [isAllItemsRendered, setAllItemsRendered] = useState(false);

  const {
    startNode,
    visibleNodeCount,
    offsetY,
    totalHeight,
  } = useVirtualScroller({
    ref: scrollRef,
    minHeight: 16,
    itemCount,
    renderAhead,
    isAllItemsRendered,
  });

  const visibleData = useMemo(() => {
    return dataOrigin.slice(startNode, startNode + visibleNodeCount);
  }, [dataOrigin, startNode, visibleNodeCount]);

  useEffect(() => {
    setAllItemsRendered(visibleData.length === dataOrigin.length);
  }, [visibleData, dataOrigin]);

  const SelectPopupSettingMail = () => {
    return (
      <div className="select-popup__setting-mail" hidden={itemCount <= 0}>
        {visibleData.map((data, index) => (
          <div key={data[keyValue]} style={{ order: index + 1 }}>
            {mergeValueLable ? `${data[keyValue]}[${data[keyLabel]}]` : data[keyLabel]}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="select-popup select-popup--multiple" ref={tooltipRef}>
      <ErrorTooltipWrapper
        isError={errorStr !== ''}
        errorMess={errorStr}
        ref={tooltipRef}
      >
        <Button
          variant="link"
          size="sm"
          className="select-popup__choice"
          onClick={handleClickChoice}
        >
          {itemCount > 0 ? '変更' : '選択'}
        </Button>
      </ErrorTooltipWrapper>
      <Button
        variant="link"
        size="sm"
        className="p-0"
        disabled={itemCount === 0}
        onClick={handleClear}
      >
        クリア
      </Button>
      <SelectPopupMenu
        loading={loading}
        title={title}
        value={item}
        displayMax={displayMax}
        limitDisplay={limitDisplay}
        options={options}
        onChange={handleChange}
        onSearch={onSearch}
        onHide={handleHide}
        show={state.show}
        top={state.position.top}
        left={state.position.left}
        mergeValueLable={mergeValueLable}
        register={register}
        isSettingMail={isSettingMail}
        maxItemSelect={maxItemSelect}
        mapperKey={mapperKey}
      />
      {isSettingMail ? (
        <SelectPopupSettingMail />
      ) : (
        <div
          ref={scrollRef}
          className="select-popup__value"
          hidden={itemCount <= 0}
        >
          <VirtualScrollWrapper
            ref={scrollRef}
            minHeight={totalHeight}
            offsetY={offsetY}
          >
            {visibleData.map((data, index) => (
              <div key={data[keyValue]} style={{ order: index + 1 }}>
                {mergeValueLable ? `${data[keyValue]}[${data[keyLabel]}]` : data[keyLabel]}
              </div>
            ))}
          </VirtualScrollWrapper>
        </div>
      )}
      {note && itemCount > limitDisplay && (
        <div className="select-popup__note">{note}</div>
      )}
    </div>
  );
};

/*
options = {
  '1': { value: '1', label: '洗顔' },
  '2': { value: '2', label: '化粧水' },
};
onChange = ({ value: '1', label: '洗顔' })
onSearch = ('word')
onToggle = (true/false)
*/
SelectPopupMultiple.propTypes = {
  title: string.isRequired,
  displayMax: number,
  value: shape({}),
  options: shape({}).isRequired,
  onChange: func,
  onSearch: func,
  onToggle: func,
  loading: bool,
  limitDisplay: number,
  renderAhead: number,
  note: string,
  error: shape({}),
  mergeValueLable: bool,
  register: shape({
    isExtendRegister: bool,
    formRegister: oneOfType([node, bool]),
    itemsRegister: oneOfType([object]),
  }),
  isSettingMail: bool,
  maxItemSelect: number,
  mapperKey: shape({}),
};
SelectPopupMultiple.defaultProps = {
  value: {},
  displayMax: MASTER_DATA_FILTER_LIMIT,
  onChange: () => {},
  onSearch: () => {},
  onToggle: () => {},
  loading: false,
  limitDisplay: undefined, // undefined => unlimit
  note: '',
  error: { result: true, message: '' },
  mergeValueLable: false,
  renderAhead: 30,
  register: {
    isExtendRegister: false,
    formRegister: false,
    itemsRegister: {},
  },
  isSettingMail: false,
  maxItemSelect: MAX_SELECTED_MEDIA_SETTING_MAIL,
  mapperKey: { value: 'value', label: 'label' },
};

export default SelectPopupMultiple;
