import React from 'react';
import { MAX_SELECTED_MEDIA_SETTING_MAIL } from 'domain/consts';
import { ROLE_VIEW_AD, ROLE_VIEW_ALL } from 'domain/permissions/roles';
import { isEmpty, isObject, isArray } from 'lodash';
import { isStringEmpty } from 'services/utils';
import {
  SCHEDULE,
  SCHEDULE_WEEKDAYS,
  EMAIL_TITLE,
  VIEW_INDICATOR_ALL,
  MEDIA_IDS,
  VIEW_INDICATOR_MEDIA,
  USERS,
  MAX_SELECTED_DASHBOARD_KPI,
  MAX_EMAIL_TITLE_LENGTH,
  NO_EXIST_EMAIL,
} from 'domain/setting-mail/constants';
import { errorMessages } from 'domain/setting-mail/errorMessages';
import { IconMultiplePerson } from 'views/atoms/icon/SvgIcon';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import { AD_COST, CNT_ACCESS } from 'domain/settings/display-items';

// scheduleWeekdays use for Setting Mail Modal
export const scheduleWeekdays = {
  '1': '月曜日',
  '2': '火曜日',
  '3': '水曜日',
  '4': '木曜日',
  '5': '金曜日',
  '6': '土曜日',
  '7': '日曜日',
};

export const formatDataOptions = (data) => {
  if (!isObject(data)) return {};

  return Object.keys(data).reduce(
    (obj, id) => ({
      ...obj,
      [id]: {
        label: data[id]?.name ?? '',
        value: id,
      },
    }),
    {}
  );
};

export const convertDataState = (data, keyName, keyId) => {
  if (!isArray(data) || !keyName || !keyId) return {};

  return data.reduce((obj, item) => {
    if (item[keyId] === undefined) return obj;

    return {
      ...obj,
      [item[keyId]]: {
        label: item[keyName],
        value: item[keyId],
      },
    };
  }, {});
};

export const schedules = [
  {
    label: '送信する',
    value: '1',
  },
  {
    label: '送信しない',
    value: '0',
  },
];

const formatDisplayEmail = (emailInfo = []) => {
  const email = emailInfo || '';
  const emails = email.split(',');

  if (emails === NO_EXIST_EMAIL) {
    return '';
  }

  if (emails.length === 1) {
    return emails[0];
  }

  if (emails.length > 1) {
    const emailUser = emails.reduce(
      (obj, item, index) => {
        const result = obj;
        if (index === 0) {
          result.displayEmail = item;
        } else {
          result.tooltipEmail = (
            <>
              {result.tooltipEmail} {isEmpty(result.tooltipEmail) ? '' : <br />}
              {item}
            </>
          );
        }
        return result;
      },
      {
        displayEmail: '',
        tooltipEmal: '',
      }
    );

    return (
      <div>
        {emailUser.displayEmail}
        <DragonBall
          svg={<IconMultiplePerson />}
          variant="modal-white"
          placement="bottom"
        >
          {emailUser.tooltipEmail}
        </DragonBall>
      </div>
    );
  }

  return '';
};

export const formatUsersData = (users) => {
  if (users.length === 0) {
    return users;
  }

  return users.map((user) => {
    const viewAll = user.user_roles.includes(ROLE_VIEW_ALL) ? (
      <i className="fas fa-check" />
    ) : null;
    const viewAd = user.user_roles.includes(ROLE_VIEW_AD) ? (
      <i className="fas fa-check" />
    ) : null;

    return {
      ...user,
      rowId: user.user_id,
      view_all: viewAll,
      view_ad: viewAd,
      email: formatDisplayEmail(user.email),
    };
  });
};

/**
 * Is checkbox checked
 * @param {string} key
 * @param {object} selected
 * @returns boolean
 */
export const isChecked = (key = '', selected = {}) => {
  if (isEmpty(selected)) {
    return false;
  }
  return !!selected[key];
};

export const countChecked = (selected, options) => {
  if (isEmpty(selected)) {
    return 0;
  }
  return Object.keys(options).filter((value) => selected[value]).length;
};

export const validateSettingMailInfo = (data) => {
  const errors = {};
  if (!['0', '1'].includes(data[SCHEDULE])) {
    errors[SCHEDULE] =
      '送信有無に[未登録]は使用できません。[未登録]とならないよう修正してください。';
  }

  if (
    isStringEmpty(data[SCHEDULE_WEEKDAYS]) ||
    countChecked(data[SCHEDULE_WEEKDAYS], scheduleWeekdays) === 0
  ) {
    errors[SCHEDULE_WEEKDAYS] = '送信曜日を入力してください';
  }

  if (data[EMAIL_TITLE].length > MAX_EMAIL_TITLE_LENGTH) {
    errors[EMAIL_TITLE] = '500字以内で入力してください。';
  }

  if (
    Object.keys(data[VIEW_INDICATOR_ALL]).filter(
      (key) => data[VIEW_INDICATOR_ALL][key] === true
    ).length === 0
  ) {
    errors[VIEW_INDICATOR_ALL] = '全体評価を選択してください。';
  }

  if (
    Object.keys(data[VIEW_INDICATOR_ALL]).filter(
      (key) => data[VIEW_INDICATOR_ALL][key] === true
    ).length > MAX_SELECTED_DASHBOARD_KPI
  ) {
    errors[VIEW_INDICATOR_ALL] = '全体評価は8件以内で選択してください。';
  }

  if (Object.keys(data[MEDIA_IDS]).length > MAX_SELECTED_MEDIA_SETTING_MAIL) {
    errors[MEDIA_IDS] = '媒体種別は5件以内で選択してください。';
  }

  if (
    Object.keys(data[VIEW_INDICATOR_MEDIA]).filter(
      (key) => data[VIEW_INDICATOR_MEDIA][key] === true
    ).length === 0 &&
    !isEmpty(data[MEDIA_IDS])
  ) {
    errors[VIEW_INDICATOR_MEDIA] = '媒体評価の表示項目を選択してください。';
  }

  if (data[USERS].length === 0) {
    errors[USERS] = errorMessages[USERS].INVALID_USER_CHECK;
  }

  return errors;
};

export const getErrorMessage = (errors, fieldName) => {
  return errors && errors[fieldName] ? errors[fieldName] : null;
};

export const convertArray2Object = (data, key = 'field', value = 'code') => {
  const dataObject = {};
  data.map((item) => {
    const msg = !isEmpty(errorMessages[item[key]][item[value]])
      ? errorMessages[item[key]][item[value]]
      : '';
    dataObject[item[key]] = msg;
    return true;
  });
  return dataObject;
};

/**
 * Get pickup name for Setting Mail
 * @param {string} pickupId
 * @param {object} metadata
 * @return {string}
 */
export const getMetricDisplayName = (pickupId, metadata) => {
  if (isEmpty(pickupId) || isEmpty(metadata)) return '';

  const [metricId, convId] = pickupId.split('__');
  const { metric_name: metricName } = metadata.metrics.find(
    (metric) => metric.metric_id === metricId
  );

  let convName = '';
  if (!isEmpty(convId)) {
    const conversion = metadata.conversions.find(
      (item) => item.conv_id === +convId
    );
    convName = conversion?.conv_name || '';
  }

  let pickupName = `${metricName} (${convName})`;
  if (+convId === 0) {
    pickupName = `${convName}の${metricName}`;
  } else if (metricId === AD_COST) {
    pickupName = metricName;
  } else if (metricId === CNT_ACCESS) {
    pickupName = '流入回数/クリック数';
  }

  return pickupName;
};

/**
 * Map Setting Mail's ViewIndicatorAll with current kpi of Dashboard
 * @param {object} viewIndicatorAll
 * @param {object} metadata
 * @return {object}
 */
export const mapCurrentDashboardKpi = (viewIndicatorAll, metadata) => {
  const viewIndicators = Object.keys(viewIndicatorAll)
    .filter((key) => metadata.currentKpiPeriod.pickup_ids.includes(key))
    .map((pickupId) => {
      return {
        pickupId,
        defaultValue: true,
      };
    });

  return viewIndicators.reduce(
    (acc, { pickupId, defaultValue }) => ({
      ...acc,
      [pickupId]: defaultValue,
    }),
    {}
  );
};
