const GET_LIST = 'tag-management/GET_LIST';
const SET_DATA_LIST = 'tag-management/SET_DATA_LIST';
const SET_ERROR_LIST = 'tag-management/SET_ERROR_LIST';
const SET_STATUS_LIST = 'tag-management/SET_STATUS_LIST';

const GET_DETAIL = 'tag-management/GET_DETAIL';
const SET_DATA_DETAIL = 'tag-management/SET_DATA_DETAIL';
const SET_ERROR_DETAIL = 'tag-management/SET_ERROR_DETAIL';
const SET_STATUS_DETAIL = 'tag-management/SET_STATUS_DETAIL';

const CREATE = 'tag-management/CREATE';
const UPDATE = 'tag-management/UPDATE';
const DELETE = 'tag-management/DELETE';

const CHANGE_TAB = 'tag-management/CHANGE_TAB';
const UPDATE_SORT = 'tag-management/UPDATE_SORT';
const UPDATE_PAGE = 'tag-management/UPDATE_PAGE';
const DOWNLOAD_CSV = 'tag-management/DOWNLOAD_CSV';
const SELECT_ROWS = 'tag-management/SELECT_ROWS';

const UPLOAD_CSV = 'tag-management/UPLOAD_CSV';
const UPLOAD_CSV_DELETE = 'tag-management/UPLOAD_CSV_DELETE';
const SET_DATA_UPLOAD = 'tag-management/SET_DATA_UPLOAD';
const SET_ERROR_UPLOAD = 'tag-management/SET_ERROR_UPLOAD';
const SET_STATUS_UPLOAD = 'tag-management/SET_STATUS_UPLOAD';
const SET_STATUS_OPEN_UPLOAD = 'tag-management/SET_STATUS_OPEN_UPLOAD';
const SET_CSV_FILE_NAME = 'tag-management/SET_CSV_FILE_NAME';

const SUBMIT_CSV = 'tag-management/SUBMIT_CSV';

const CLEAN_STATES = 'ad-management/CLEAN_STATES';

const CREATE_CONTENT_CATEGORY = 'tag-management/CREATE_CONTENT_CATEGORY';
const GET_CONTENT_CATEGORIES = 'tag-management/GET_CONTENT_CATEGORIES';
const SET_DATA_CONTENT_CATEGORIES =
  'tag-management/SET_DATA_CONTENT_CATEGORIES';
const SET_ERROR_CONTENT_CATEGORIES =
  'tag-management/SET_ERROR_CONTENT_CATEGORIES';
const SET_STATUS_CONTENT_CATEGORIES =
  'tag-management/SET_STATUS_CONTENT_CATEGORIES';

const UPDATE_RANK_PRIORITY_CONTENT_CATEGORY =
  'tag-management/UPDATE_RANK_PRIORITY_CONTENT_CATEGORY';

export default {
  GET_LIST,
  SET_DATA_LIST,
  SET_ERROR_LIST,
  SET_STATUS_LIST,
  GET_DETAIL,
  SET_DATA_DETAIL,
  SET_STATUS_DETAIL,
  SET_ERROR_DETAIL,
  CREATE,
  UPDATE,
  DELETE,
  CHANGE_TAB,
  UPDATE_SORT,
  UPDATE_PAGE,
  UPLOAD_CSV,
  UPLOAD_CSV_DELETE,
  DOWNLOAD_CSV,
  SELECT_ROWS,
  SET_DATA_UPLOAD,
  SET_ERROR_UPLOAD,
  SET_STATUS_UPLOAD,
  CLEAN_STATES,
  SUBMIT_CSV,
  SET_STATUS_OPEN_UPLOAD,
  SET_CSV_FILE_NAME,
  CREATE_CONTENT_CATEGORY,
  GET_CONTENT_CATEGORIES,
  SET_DATA_CONTENT_CATEGORIES,
  SET_ERROR_CONTENT_CATEGORIES,
  SET_STATUS_CONTENT_CATEGORIES,
  UPDATE_RANK_PRIORITY_CONTENT_CATEGORY,
};
