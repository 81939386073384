const getColumnWidth = (state) => {
  const { page } = state.settings.report;
  if (state.settings.resizeTable[page]) {
    return state.settings.resizeTable[page].columnsWidth;
  }
  return {};
};

const getTableWidth = (state) => {
  const { page } = state.settings.report;
  if (state.settings.resizeTable[page]) {
    return state.settings.resizeTable[page].tablesWidth;
  }
  return {
    freeze: null,
    main: null,
  };
};

const getWidthTableByTab = (currentTab) => (state) => {
  const {
    settings: { report, resizeTable },
  } = state;
  const pageId = currentTab ? `${report.page}-${currentTab}` : report.page;

  return resizeTable[pageId]?.tablesWidth || { freeze: null, main: null };
};

const getWidthColumnByTab = (currentTab) => (state) => {
  const { report, resizeTable } = state.settings;
  const pageId = currentTab ? `${report.page}-${currentTab}` : report.page;

  return resizeTable[pageId]?.columnsWidth || {};
};

const getWidthTableByMode = (state) => {
  const { page, mode } = state.settings.report;
  const pageId = mode ? `${page}/${mode}` : page;
  if (state.settings.resizeTable[pageId]) {
    return state.settings.resizeTable[pageId].tablesWidth;
  }
  return {};
};

const getWidthColumnByMode = (state) => {
  const { page, mode } = state.settings.report;
  const pageId = mode ? `${page}/${mode}` : page;

  if (state.settings.resizeTable[pageId]) {
    return state.settings.resizeTable[pageId].columnsWidth;
  }
  return {};
};

export default {
  getColumnWidth,
  getTableWidth,
  getWidthTableByTab,
  getWidthColumnByTab,
  getWidthTableByMode,
  getWidthColumnByMode,
};
