import * as DisplayItems from './display-items';

const SettingDisplayItems = {
  groupList: {
    [DisplayItems.CV_NAME]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.CV_ACCESS_TIME]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.TRACKING_DATA]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.MEMBER_NAME]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.AMOUNT]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES, // TODO: new key
    [DisplayItems.OTHER1]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.OTHER2]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.OTHER3]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.OTHER4]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.OTHER5]: DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.TERMINAL_TYPE]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.CV_CONTACT_LATENCY_TIME_AVG]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.CNT_CONTACT]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
    [DisplayItems.CONTACT_DIRECT]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT2]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT3]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT4]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT5]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT6]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT7]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT8]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT9]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_INDIRECT10]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_FIRST]:
      DisplayItems.DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
    [DisplayItems.CONTACT_ACCESS_TIME]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_DEVICE]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    // [DisplayItems.CONTACT_CHANNEL]:
    //   DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_CATEGORY]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AD_GROUP1]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AD_GROUP2]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AD_ID]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AD_NAME]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AGENCY]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_LANDING_PAGE_DOMAIN]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_LANDING_PAGE_URL]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_CONTENT_CATEGORY]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_AVG]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_SYNC_CATEGORY]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_MEDIA_SIDE_CAMPAIGN]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_MEDIA_SIDE_GROUP]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_MEDIA_SIDE_AD_ID]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_MEDIA_SIDE_AD_NAME]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_OPTIONAL_AD_ID]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
    [DisplayItems.CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  // エビス軸選択時に非表示にする項目
  denyEbisList: [
    DisplayItems.CONTACT_SYNC_CATEGORY,
    DisplayItems.CONTACT_MEDIA_SIDE_CAMPAIGN,
    DisplayItems.CONTACT_MEDIA_SIDE_GROUP,
    DisplayItems.CONTACT_MEDIA_SIDE_GROUP,
    DisplayItems.CONTACT_MEDIA_SIDE_AD_ID,
    DisplayItems.CONTACT_MEDIA_SIDE_AD_NAME,
    DisplayItems.CONTACT_OPTIONAL_AD_ID,
  ],
  denyMediaSyncList: [
    DisplayItems.CONTACT_CATEGORY,
    DisplayItems.CONTACT_AD_GROUP1,
    DisplayItems.CONTACT_AD_GROUP2,
    DisplayItems.CONTACT_AD_ID,
    DisplayItems.CONTACT_AD_NAME,
    DisplayItems.CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID,
  ],
  displayFreezeList: [DisplayItems.CV_NAME, DisplayItems.CV_ACCESS_TIME],
  orderList: {
    [DisplayItems.CV_NAME]: 1, // order in same group must be unique
    [DisplayItems.CV_ACCESS_TIME]: 2,
    [DisplayItems.TRACKING_DATA]: 3,
    [DisplayItems.MEMBER_NAME]: 4,
    [DisplayItems.AMOUNT]: 5,
    [DisplayItems.OTHER1]: 6,
    [DisplayItems.OTHER2]: 7,
    [DisplayItems.OTHER3]: 8,
    [DisplayItems.OTHER4]: 9,
    [DisplayItems.OTHER5]: 10,
    [DisplayItems.TERMINAL_TYPE]: 11,
    [DisplayItems.CV_CONTACT_LATENCY_TIME_AVG]: 12,
    [DisplayItems.CNT_CONTACT]: 13,
    [DisplayItems.CONTACT_DIRECT]: 14,
    [DisplayItems.CONTACT_INDIRECT2]: 15,
    [DisplayItems.CONTACT_INDIRECT3]: 16,
    [DisplayItems.CONTACT_INDIRECT4]: 17,
    [DisplayItems.CONTACT_INDIRECT5]: 18,
    [DisplayItems.CONTACT_INDIRECT6]: 19,
    [DisplayItems.CONTACT_INDIRECT7]: 20,
    [DisplayItems.CONTACT_INDIRECT8]: 21,
    [DisplayItems.CONTACT_INDIRECT9]: 22,
    [DisplayItems.CONTACT_INDIRECT10]: 23,
    [DisplayItems.CONTACT_FIRST]: 24,
    [DisplayItems.CONTACT_ACCESS_TIME]: 1, // order in same group must be unique
    [DisplayItems.CONTACT_DEVICE]: 2,
    [DisplayItems.CONTACT_CHANNEL]: 3,
    [DisplayItems.CONTACT_CATEGORY]: 4,
    [DisplayItems.CONTACT_AD_GROUP1]: 5,
    [DisplayItems.CONTACT_AD_GROUP2]: 6,
    [DisplayItems.CONTACT_AD_ID]: 7,
    [DisplayItems.CONTACT_AD_NAME]: 8,
    [DisplayItems.CONTACT_AGENCY]: 9,
    [DisplayItems.CONTACT_LANDING_PAGE_DOMAIN]: 10,
    [DisplayItems.CONTACT_SYNC_CATEGORY]: 4,
    [DisplayItems.CONTACT_MEDIA_SIDE_CAMPAIGN]: 5,
    [DisplayItems.CONTACT_MEDIA_SIDE_GROUP]: 6,
    [DisplayItems.CONTACT_MEDIA_SIDE_AD_ID]: 7,
    [DisplayItems.CONTACT_MEDIA_SIDE_AD_NAME]: 8,
    [DisplayItems.CONTACT_LANDING_PAGE_URL]: 11,
    [DisplayItems.CONTACT_CONTENT_CATEGORY]: 12,
    [DisplayItems.CONTACT_OPTIONAL_AD_ID]: 13,
    [DisplayItems.CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID]: 14,
  },
  displayDefaultList: [
    DisplayItems.CV_NAME,
    DisplayItems.CV_ACCESS_TIME,
    DisplayItems.AMOUNT,
    DisplayItems.CV_CONTACT_LATENCY_TIME_AVG,
    DisplayItems.CNT_CONTACT,
    DisplayItems.CONTACT_DIRECT,
    DisplayItems.CONTACT_INDIRECT2,
    DisplayItems.CONTACT_INDIRECT3,
    DisplayItems.CONTACT_INDIRECT4,
    DisplayItems.CONTACT_INDIRECT5,
    DisplayItems.CONTACT_INDIRECT6,
    DisplayItems.CONTACT_INDIRECT7,
    DisplayItems.CONTACT_INDIRECT8,
    DisplayItems.CONTACT_INDIRECT9,
    DisplayItems.CONTACT_INDIRECT10,
    DisplayItems.CONTACT_FIRST,
    DisplayItems.CONTACT_ACCESS_TIME,
    // DisplayItems.CONTACT_CHANNEL,
    DisplayItems.CONTACT_CATEGORY,
    DisplayItems.CONTACT_SYNC_CATEGORY,
  ],
  sortDefaultList: {
    [DisplayItems.CV_ACCESS_TIME]: DisplayItems.SORT_DIRECTION_DESC,
  },
  requiredList: [DisplayItems.CV_NAME],
  sortDisabledList: [
    DisplayItems.CONTACT_DIRECT,
    DisplayItems.CONTACT_INDIRECT2,
    DisplayItems.CONTACT_INDIRECT3,
    DisplayItems.CONTACT_INDIRECT4,
    DisplayItems.CONTACT_INDIRECT5,
    DisplayItems.CONTACT_INDIRECT6,
    DisplayItems.CONTACT_INDIRECT7,
    DisplayItems.CONTACT_INDIRECT8,
    DisplayItems.CONTACT_INDIRECT9,
    DisplayItems.CONTACT_INDIRECT10,
    DisplayItems.CONTACT_FIRST,
    DisplayItems.CONTACT_ACCESS_TIME,
    DisplayItems.CONTACT_CHANNEL,
    DisplayItems.CONTACT_CATEGORY,
    DisplayItems.CONTACT_AD_GROUP1,
    DisplayItems.CONTACT_AD_GROUP2,
    DisplayItems.CONTACT_AD_ID,
    DisplayItems.CONTACT_AD_NAME,
    DisplayItems.CONTACT_SYNC_CATEGORY,
    DisplayItems.CONTACT_MEDIA_SIDE_CAMPAIGN,
    DisplayItems.CONTACT_MEDIA_SIDE_GROUP,
    DisplayItems.CONTACT_MEDIA_SIDE_AD_ID,
    DisplayItems.CONTACT_MEDIA_SIDE_AD_NAME,
    DisplayItems.CONTACT_AGENCY,
    DisplayItems.CONTACT_LANDING_PAGE_DOMAIN,
    DisplayItems.CONTACT_LANDING_PAGE_URL,
    DisplayItems.CONTACT_CONTENT_CATEGORY,
    DisplayItems.CONTACT_OPTIONAL_AD_ID,
    DisplayItems.CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID,
  ]
};

const SettingAdDisplayItems = {
  ...SettingDisplayItems,
};
const SettingAllDisplayItems = {
  ...SettingDisplayItems,
  groupList: {
    ...SettingDisplayItems.groupList,
    [DisplayItems.CONTACT_CHANNEL]:
      DisplayItems.DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  displayDefaultList: [
    ...SettingDisplayItems.displayDefaultList,
    DisplayItems.CONTACT_CHANNEL,
  ],
};

export const getAd = () => {
  const displayItemsBase = DisplayItems.getAd();
  displayItemsBase[DisplayItems.AMOUNT].title = '売上金額';
  return DisplayItems.getItems({
    ...SettingAdDisplayItems,
    displayItemsBase,
  });
};

export const getAdDefault = () => {
  const displayItemsBase = getAd();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getAll = () => {
  const displayItemsBase = DisplayItems.getAll();
  displayItemsBase[DisplayItems.AMOUNT].title = '売上金額';
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getAllDefault = () => {
  const displayItemsBase = getAll();
  return DisplayItems.getDefault(displayItemsBase);
};

export const getCommon = () => {
  const displayItemsBase = DisplayItems.getCommon();
  return DisplayItems.getItems({
    ...SettingAllDisplayItems,
    displayItemsBase,
  });
};

export const getCommonDefault = () => {
  const displayItemsBase = getCommon();
  return DisplayItems.getDefault(displayItemsBase);
};
