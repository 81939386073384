import React from 'react';

import * as screens from 'services/routes/constants';
import pages from 'services/routes/pages';
import { retry } from 'services/utils';

import withResetState from 'services/appconfig/withResetState';

const DashboardContainer = React.lazy(() =>
  retry(() => import('views/pages/dashboard'))
);
const SummaryContainer = React.lazy(() =>
  retry(() => import('views/pages/category-analyze'))
);
const LandingPageAnalyzeContainer = React.lazy(() =>
  retry(() => import('views/pages/landing-page-analyze'))
);
const CVFlowContainer = React.lazy(() =>
  retry(() => import('views/pages/cv-flow'))
);
const CVAttributeContainer = React.lazy(() =>
  retry(() => import('views/pages/cv-attribute'))
);
const DetailAnalyzeContainer = React.lazy(() =>
  retry(() => import('views/pages/detail-analyze'))
);
const CostAllocationContainer = React.lazy(() =>
  retry(() => import('views/pages/cost-allocation'))
);
const UserManagementContainer = React.lazy(() =>
  retry(() => import('views/pages/user-management'))
);
const AgencyManagementContainer = React.lazy(() =>
  retry(() => import('views/pages/agency-management'))
);
const PeriodAnalysisContainer = React.lazy(() =>
  retry(() => import('views/pages/period-analyze'))
);
const LtvSettingsProductContainer = React.lazy(() =>
  retry(() => import('views/pages/ltv/settings/product'))
);
const LtvConditionContainer = React.lazy(() =>
  retry(() => import('views/pages/ltv/settings/condition'))
);
const LtvAdContainer = React.lazy(() =>
  retry(() => import('views/pages/ltv/settings/ad'))
);
const LtvSettingsOfferContainer = React.lazy(() =>
  retry(() => import('views/pages/ltv/settings/offer'))
);
const LtvSettingsOtherContainer = React.lazy(() =>
  retry(() => import('views/pages/ltv/settings/other'))
);
const LtvCsvOrdersContainer = React.lazy(() =>
  retry(() => import('views/pages/ltv/settings/clear-csv-orders'))
);
const LtvAnalyzeContainer = React.lazy(() =>
  retry(() => import('views/pages/ltv/analyze'))
);
const MediaTypeContainer = React.lazy(() =>
  retry(() => import('views/pages/ad-management/media'))
);
const AdGroup1Container = React.lazy(() =>
  retry(() => import('views/pages/ad-management/group1'))
);
const AdGroup2Container = React.lazy(() =>
  retry(() => import('views/pages/ad-management/group2'))
);
const ExternalServicesContainer = React.lazy(() =>
  retry(() => import('views/pages/external-services'))
);
const UserProfileContainer = React.lazy(() =>
  retry(() => import('views/pages/user-profile'))
);
const MediaSyncLoader = React.lazy(() =>
  retry(() => import('views/pages/media-sync-management'))
);
const ApiManagementContainer = React.lazy(() =>
  retry(() => import('views/pages/api-management'))
);
const SystemSettingCookiePersistenceContainer = React.lazy(() =>
  retry(() => import('views/pages/system-setting'))
);
const UsageStatusContainer = React.lazy(() =>
  retry(() => import('views/pages/usage-status'))
);
const ContactDemandManagementContainer = React.lazy(() =>
  retry(() => import('views/pages/contact/demand'))
);
const ContactManagementContainer = React.lazy(() =>
  retry(() => import('views/pages/contact'))
);
const PageManagementContainer = React.lazy(() =>
  retry(() => import('views/pages/page-management'))
);
const LineAddFriendSettingContainer = React.lazy(() =>
  retry(() => import('views/pages/line-add-friend-settings'))
);
const LpoLinkContainer = React.lazy(() =>
  retry(() => import('views/pages/lpo/link'))
);
const LpoPeriodContainer = React.lazy(() =>
  retry(() => import('views/pages/lpo/period'))
);

const LogPageAnalyzeContainer = React.lazy(() =>
  retry(() => import('views/pages/log/page-analyze'))
);
const LogPeriodAnalyzeContainer = React.lazy(() =>
  retry(() => import('views/pages/log/period-analyze'))
);
const ImpressionAdCostContainer = React.lazy(() =>
  retry(() => import('views/pages/impression-ad-cost'))
);
const LogRouteAnalyzeContainer = React.lazy(() =>
  retry(() => import('views/pages/log/route-analyze'))
);
const CapiSettingContainer = React.lazy(() =>
  retry(() => import('views/pages/capi-setting'))
);
const SCSettingContainer = React.lazy(() =>
  retry(() => import('views/pages/search-console-setting'))
);

const routes = [
  {
    exact: true,
    component: withResetState(DashboardContainer),
    path: pages[screens.DASHBOARD].path,
    screenId: pages[screens.DASHBOARD].screenId,
  },
  {
    exact: true,
    component: withResetState(SummaryContainer),
    path: pages[screens.CATEGORY_ANALYZE].path,
    screenId: pages[screens.CATEGORY_ANALYZE].screenId,
  },
  {
    exact: true,
    component: withResetState(LandingPageAnalyzeContainer),
    path: pages[screens.LANDING_PAGE_ANALYZE].path,
    screenId: pages[screens.LANDING_PAGE_ANALYZE].screenId,
  },
  {
    exact: true,
    component: withResetState(DetailAnalyzeContainer),
    path: pages[screens.DETAILS_ANALYSIS].path,
    screenId: pages[screens.DETAILS_ANALYSIS].screenId,
  },
  {
    exact: true,
    component: withResetState(CVFlowContainer),
    path: pages[screens.CV_FLOW].path,
    screenId: pages[screens.CV_FLOW].screenId,
  },
  {
    exact: true,
    component: withResetState(CVAttributeContainer),
    path: pages[screens.CV_ATTRIBUTE].path,
    screenId: pages[screens.CV_ATTRIBUTE].screenId,
  },
  {
    exact: true,
    component: withResetState(PeriodAnalysisContainer),
    path: pages[screens.COMPARE_PERIOD].path,
    screenId: pages[screens.COMPARE_PERIOD].screenId,
  },
  {
    exact: true,
    component: withResetState(CostAllocationContainer),
    path: pages[screens.COST_ALLOCATION].path,
    screenId: pages[screens.COST_ALLOCATION].screenId,
  },
  {
    exact: true,
    component: withResetState(UserManagementContainer),
    path: pages[screens.USER_MANAGEMENT].path,
    screenId: pages[screens.USER_MANAGEMENT].screenId,
  },
  {
    exact: true,
    component: withResetState(AgencyManagementContainer),
    path: pages[screens.AGENCY_MANAGEMENT].path,
    screenId: pages[screens.AGENCY_MANAGEMENT].screenId,
  },
  {
    exact: true,
    component: withResetState(LtvSettingsProductContainer),
    path: pages[screens.LTV_SETTINGS_PRODUCT].path,
    screenId: pages[screens.LTV_SETTINGS_PRODUCT].screenId,
  },
  {
    exact: true,
    component: withResetState(LtvConditionContainer),
    path: pages[screens.LTV_SETTINGS_CONDITION].path,
    screenId: pages[screens.LTV_SETTINGS_CONDITION].screenId,
  },
  {
    exact: true,
    component: withResetState(LtvAdContainer),
    path: pages[screens.LTV_SETTINGS_AD].path,
    screenId: pages[screens.LTV_SETTINGS_AD].screenId,
  },
  {
    exact: true,
    component: withResetState(LtvSettingsOfferContainer),
    path: pages[screens.LTV_SETTINGS_OFFER].path,
    screenId: pages[screens.LTV_SETTINGS_OFFER].screenId,
  },
  {
    exact: true,
    component: withResetState(LtvSettingsOtherContainer),
    path: pages[screens.LTV_SETTINGS_OTHER].path,
    screenId: pages[screens.LTV_SETTINGS_OTHER].screenId,
  },
  {
    exact: true,
    component: withResetState(LtvCsvOrdersContainer),
    path: pages[screens.LTV_SETTINGS_CLEAR_CSV_ORDERS].path,
    screenId: pages[screens.LTV_SETTINGS_CLEAR_CSV_ORDERS].screenId,
  },
  {
    exact: true,
    component: withResetState(LtvAnalyzeContainer),
    path: pages[screens.LTV_ANALYZE].path,
    screenId: pages[screens.LTV_ANALYZE].screenId,
  },
  {
    exact: true,
    component: withResetState(MediaTypeContainer),
    path: pages[screens.AD_MANAGEMENT_MEDIA_TYPE].path,
    screenId: pages[screens.AD_MANAGEMENT_MEDIA_TYPE].screenId,
  },
  {
    exact: true,
    component: withResetState(AdGroup1Container),
    path: pages[screens.AD_MANAGEMENT_AD_GROUP_1].path,
    screenId: pages[screens.AD_MANAGEMENT_AD_GROUP_1].screenId,
  },
  {
    exact: true,
    component: withResetState(AdGroup2Container),
    path: pages[screens.AD_MANAGEMENT_AD_GROUP_2].path,
    screenId: pages[screens.AD_MANAGEMENT_AD_GROUP_2].screenId,
  },
  {
    exact: true,
    component: withResetState(ExternalServicesContainer),
    path: pages[screens.EXTERNAL_SERVICE_SETTINGS].path,
    screenId: pages[screens.EXTERNAL_SERVICE_SETTINGS].screenId,
  },
  {
    exact: true,
    component: withResetState(UserProfileContainer),
    path: pages[screens.USER_PROFILE].path,
    screenId: pages[screens.USER_PROFILE].screenId,
  },
  {
    exact: true,
    component: withResetState(MediaSyncLoader),
    path: pages[screens.MEDIA_SYNC_MANAGEMENT].path,
    screenId: pages[screens.MEDIA_SYNC_MANAGEMENT].screenId,
  },
  {
    exact: true,
    component: withResetState(ApiManagementContainer),
    path: pages[screens.API_MANAGEMENT].path,
    screenId: pages[screens.API_MANAGEMENT].screenId,
  },
  {
    exact: true,
    component: withResetState(UsageStatusContainer),
    path: pages[screens.USAGE_STATUS_SITE].path,
    screenId: pages[screens.USAGE_STATUS_SITE].screenId,
  },
  {
    exact: true,
    component: withResetState(ContactDemandManagementContainer),
    path: pages[screens.CONTACT_DEMAND_MANAGEMENT].path,
    screenId: pages[screens.CONTACT_DEMAND_MANAGEMENT].screenId,
  },
  {
    exact: true,
    component: withResetState(ContactManagementContainer),
    path: pages[screens.CONTACT_MANAGEMENT].path,
    screenId: pages[screens.CONTACT_MANAGEMENT].screenId,
  },
  {
    exact: true,
    component: withResetState(PageManagementContainer),
    path: pages[screens.MEASUREMENT_SITE_PAGE_MANAGEMENT].path,
    screenId: pages[screens.MEASUREMENT_SITE_PAGE_MANAGEMENT].screenId,
  },
  {
    exact: true,
    component: withResetState(LineAddFriendSettingContainer),
    path: pages[screens.LINE_ADD_FRIEND_SETTINGS].path,
    screenId: pages[screens.LINE_ADD_FRIEND_SETTINGS].screenId,
  },
  {
    exact: true,
    component: withResetState(ImpressionAdCostContainer),
    path: pages[screens.IMPRESSION_AD_COST].path,
    screenId: pages[screens.IMPRESSION_AD_COST].screenId,
  },
  {
    exact: true,
    component: withResetState(LogPageAnalyzeContainer),
    path: pages[screens.LOG_PAGE_ANALYZE].path,
    screenId: pages[screens.LOG_PAGE_ANALYZE].screenId,
  },
  {
    exact: true,
    component: withResetState(LogPeriodAnalyzeContainer),
    path: pages[screens.LOG_PERIOD_ANALYZE].path,
    screenId: pages[screens.LOG_PERIOD_ANALYZE].screenId,
  },
  {
    exact: true,
    component: withResetState(LogRouteAnalyzeContainer),
    path: pages[screens.LOG_ROUTE_ANALYZE].path,
    screenId: pages[screens.LOG_ROUTE_ANALYZE].screenId,
  },
  {
    exact: true,
    component: withResetState(CapiSettingContainer),
    path: pages[screens.CAPI_SETTING].path,
    screenId: pages[screens.CAPI_SETTING].screenId,
  },
  {
    exact: true,
    component: withResetState(SCSettingContainer),
    path: pages[screens.SEARCH_CONSOLE_SETTING].path,
    screenId: pages[screens.SEARCH_CONSOLE_SETTING].screenId,
  },
  {
    exact: true,
    component: withResetState(LpoLinkContainer),
    path: pages[screens.LPO_LINK].path,
    screenId: pages[screens.LPO_LINK].screenId,
  },
  {
    exact: true,
    component: withResetState(LpoPeriodContainer),
    path: pages[screens.LPO_PERIOD].path,
    screenId: pages[screens.LPO_PERIOD].screenId,
  },
];

export default routes;
