/* eslint-disable camelcase */
import helper from 'store/helper';
import types from './types';

const initialState = {
  newReJudgmentPeriod: 0,
  orderIdOption: 'other1',
  memberIdOption: 'other1',
  options: {},
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null, // string | null,
};

const requestStart = (state) => {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: 'failed',
    error,
  };
};

const fetch = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    data,
    status: 'succeeded',
  };
};

const update = (state, action) => {
  const { data } = action.payload;

  return {
    ...state,
    data,
    status: 'succeeded',
  };
};

const LtvSettingsReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_SUCCEEDED]: fetch,
  [types.UPDATE_SUCCEEDED]: update,
});

export default LtvSettingsReducer;
