import {
  AD_ID,
  KEYWORD,
  AGENCY_ID,
  AGENCY_NAME,
  MEDIA_ID,
  MEDIA_NAME,
  AD_GROUP1_ID,
  AD_GROUP1_NAME,
  AD_GROUP2_ID,
  AD_GROUP2_NAME,
  AD_NOTE,
  BASE_CPC,
  BASE_CPCONV,
  AD_DATE,
  AD_URL,
  PAGE_TITLE,
  AD_PASTE_URL,
  TEXT_DATA,
  BANNER,
  MEASUREMENT_TYPE,
  QR_CODE,
  URL,
  URLS,
  AD_START_DATE,
  AD_END_DATE,
  TRANSITION_RATE,
} from 'domain/fields';

const UNREGISTERED_DISPLAY_VALUE = '未登録';

const DELIVERY_TYPE_UNKNOWN = '0';
const DELIVERY_TYPE_TAD = '1';
const DELIVERY_TYPE_TDV = '2';

const MAX_HEIGHT_TABLE_CONFIRM = 396;
const MIN_HEIGHT_TABLE_CONFIRM = 144;

const AD_CV_COST_MODE_NONE_VALUE = '0';

const REMAIN_ITEMS_REGISTER = -1; // Not display ※あと{remain}件登録可能です

const BANNER_MAX_BYTES = 1048576;
const BANNER_MAX_MEGABYTES = BANNER_MAX_BYTES / 1048576;
const BANNER_ACCEPT_EXTENSION = 'JPG,GIF,PNG';

const TEMPLATE_REMOVE_AD = '/templates/ad-management/remove_ad.csv';
const TEMPLATE_REGISTER_AD = '/templates/ad-management/ad.csv';
const TEMPLATE_REGISTER_LISTING = '/templates/ad-management/listing.csv';
const TEMPLATE_REGISTER_AGENCY_AD = '/templates/ad-management/agency/ad.csv';
const TEMPLATE_REGISTER_AGENCY_LISTING =
  '/templates/ad-management/agency/listing.csv';

const MAX_LENGTH_MEDIA_NAME = 80;
const MAX_LENGTH_AD_GROUP_NAME = 255;
const AD_GROUP_MAX_VALUE = 20000;
const MEDIA_MAX_VALUE = 1000;
const RANK_MAX = 18;
const AD_ID_MAX = 18;
const AD_KEYWORD_MAX = 300;
const AD_NOTE_MAX = 400;
const URL_MAX = 2083;
const PAGE_TITLE_MAX = 1000;
const TEXT_DATA_MAX = 4000;
const BASE_COST_RANGE = [1, 999999];
const DATE_RANGE = ['2001/1/1', '2037/12/31'];

const AD_MANAGEMENT_MODE = {
  AD: 'ad',
  LPO: 'lpo',
  LISTING: 'listing',
  ALL: 'all',
  DELETED: 'deleted',
};

const AD_MANAGEMENT_TITLE = {
  ad: 'AD広告',
  lpo: 'LPO広告',
  listing: 'リスティング連携広告',
  all: 'すべて',
  deleted: '削除済み',
};

const MODAL_TITLE = {
  add: '広告を追加',
  edit: '広告を編集',
};

const STEPS = [
  {
    step: 1,
    label: '広告設定',
    active: false,
  },
  {
    step: 2,
    label: '完了',
    active: false,
  },
];

const FIELD_FORM = {
  [AD_ID]: {
    label: '広告ID',
  },
  [KEYWORD]: {
    label: '広告名',
  },
  [MEASUREMENT_TYPE]: {
    label: '計測方式',
  },
  [URL]: {
    label: 'リンク先URL',
  },
  [URLS]: {
    label: 'リンク先',
  },
  [PAGE_TITLE]: {
    label: 'リンク先タイトル',
  },
  [AGENCY_ID]: {
    label: '担当代理店',
  },
  [MEDIA_ID]: {
    label: '媒体種別',
  },
  [AD_GROUP1_ID]: {
    label: '広告グループ1',
  },
  [AD_GROUP2_ID]: {
    label: '広告グループ2',
  },
  [AD_NOTE]: {
    label: '備考',
  },
  [BASE_CPC]: {
    label: 'クリック単価',
  },
  [BASE_CPCONV]: {
    label: 'CV単価',
  },
  [AD_START_DATE]: {
    label: '出稿期間',
  },
  [AD_END_DATE]: {
    label: '出稿期間',
  },
  [TEXT_DATA]: {
    label: 'テキスト原稿',
  },
  [BANNER]: {
    label: 'バナー原稿',
  },
  [TRANSITION_RATE]: {
    label: '遷移割合',
  },
};

const FIELD_DETAIL = {
  [AD_ID]: '広告ID',
  [KEYWORD]: '広告名',
  [AGENCY_NAME]: '担当代理店',
  [MEDIA_NAME]: '媒体種別',
  [AD_GROUP1_NAME]: '広告グループ01',
  [AD_GROUP2_NAME]: '広告グループ02',
  [AD_NOTE]: '備考',
  [BASE_CPC]: 'クリック単価',
  [BASE_CPCONV]: 'CV単価',
  [AD_DATE]: '出稿期間',
  [AD_URL]: 'リンク先URL',
  [PAGE_TITLE]: 'リンク先タイトル',
  [AD_PASTE_URL]: '入稿用URL',
  [MEASUREMENT_TYPE]: '計測方式',
  [TEXT_DATA]: 'テキスト原稿',
  [BANNER]: 'バナー原稿',
  [QR_CODE]: 'QRコード発行',
};

const COLUMNS_TABLE_CONFIRM_EMPTY_MEASUREMENT_TYPE = [
  {
    name: 'line',
    title: '対象行',
    width: 100,
  },
  {
    name: 'keyword',
    title: '広告名',
    width: 150,
  },
  {
    name: 'url',
    title: 'リンク先URL',
    width: 0,
  },
];

const COLUMNS_TABLE_CONFIRM_CHANGE_URL = [
  {
    name: 'line',
    title: '対象行',
    width: 100,
  },
  {
    name: 'ad_id',
    title: '広告ID',
    width: 140,
  },
  {
    name: 'keyword',
    title: '広告名',
    width: 115,
  },
  {
    name: 'url',
    title: 'リンク先URL',
    width: 0,
  },
];

const COLUMNS_TABLE_CONFIRM_CONTENT_INVALID = [
  {
    name: 'line',
    title: '対象行',
    width: 130,
  },
  {
    name: 'message',
    title: '対応方法',
    width: 0,
  },
];

const COLUMNS_TABLE_CONFIRM_DELETE = [
  {
    name: 'ad_id',
    title: '広告ID',
    width: 140,
  },
  {
    name: 'keyword',
    title: '広告名',
    width: 115,
  },
  {
    name: 'device',
    title: 'デバイス',
    width: 125,
  },
  {
    name: 'url',
    title: 'リンク先URL',
    width: 190,
  },
  {
    name: 'comment',
    title: 'メッセージ',
    width: 0,
  },
];

export {
  UNREGISTERED_DISPLAY_VALUE,
  DELIVERY_TYPE_UNKNOWN,
  DELIVERY_TYPE_TAD,
  DELIVERY_TYPE_TDV,
  AD_CV_COST_MODE_NONE_VALUE,
  AD_MANAGEMENT_MODE,
  AD_MANAGEMENT_TITLE,
  MODAL_TITLE,
  STEPS,
  FIELD_FORM,
  FIELD_DETAIL,
  COLUMNS_TABLE_CONFIRM_EMPTY_MEASUREMENT_TYPE,
  COLUMNS_TABLE_CONFIRM_CHANGE_URL,
  COLUMNS_TABLE_CONFIRM_CONTENT_INVALID,
  COLUMNS_TABLE_CONFIRM_DELETE,
  MAX_HEIGHT_TABLE_CONFIRM,
  MIN_HEIGHT_TABLE_CONFIRM,
  BANNER_MAX_BYTES,
  BANNER_MAX_MEGABYTES,
  BANNER_ACCEPT_EXTENSION,
  TEMPLATE_REMOVE_AD,
  TEMPLATE_REGISTER_AD,
  TEMPLATE_REGISTER_LISTING,
  TEMPLATE_REGISTER_AGENCY_AD,
  TEMPLATE_REGISTER_AGENCY_LISTING,
  MAX_LENGTH_MEDIA_NAME,
  MAX_LENGTH_AD_GROUP_NAME,
  AD_GROUP_MAX_VALUE,
  MEDIA_MAX_VALUE,
  RANK_MAX,
  AD_ID_MAX,
  AD_KEYWORD_MAX,
  AD_NOTE_MAX,
  URL_MAX,
  PAGE_TITLE_MAX,
  TEXT_DATA_MAX,
  BASE_COST_RANGE,
  DATE_RANGE,
  REMAIN_ITEMS_REGISTER,
};
