import React from 'react';
import { string, node, bool, oneOfType, func } from 'prop-types';
import LinkMapper from 'views/molecules/navbar/component/LinkMapper';
import { HYPERLINK_HASH } from 'services/routes/constants';

const MenuItem = (props) => {
  const { title, url, leftIcon, rightIcon, type, newTab, disabled, onClick } = props;

  const Item = () => (
    <>
      {leftIcon}
      <span>{title}</span>
      {rightIcon && rightIcon}
    </>
  );

  if (url === HYPERLINK_HASH) {
    return (
      <div className="menu-label" onClick={onClick} aria-hidden="true">
        <Item />
      </div>
    );
  }

  return (
    <LinkMapper path={url} type={type} newTab={newTab} disabled={disabled}>
      <Item />
    </LinkMapper>
  );
};

MenuItem.defaultProps = {
  disabled: false,
  rightIcon: false,
  onClick: () => {},
};

MenuItem.propTypes = {
  title: string.isRequired,
  url: string.isRequired,
  leftIcon: node.isRequired,
  type: string.isRequired,
  newTab: bool.isRequired,
  rightIcon: oneOfType([node, bool]),
  disabled: bool,
  onClick: func,
};

export default React.memo(MenuItem);
