import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { shape, bool, arrayOf, func } from 'prop-types';
import { TAG_MANAGER_TYPE } from 'domain/fields';
import { TRUE_FLAG, FALSE_FLAG } from 'domain/consts';
import {
  ATTRIBUTE_FIELDS,
  ATTRIBUTE_LABELS,
  TAG_FIELD,
  TAG_MANAGER_GTM,
} from 'domain/tag-management/consts';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import TableModal from 'views/organism/TableModal';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import DragonBall from 'views/atoms/dragonball/DragonBall';

const { HAS_ATTRIBUTE, ATTRIBUTES, GTM_ATTRIBUTES } = TAG_FIELD;
const COLUMN_TAG_OTHER = [
  { name: 'label', title: '変数名' },
  { name: ATTRIBUTES, title: '値' },
];
const COLUMN_TAG_GTM = [
  { name: 'label', title: '変数名' },
  {
    name: ATTRIBUTES,
    title: '計測サイトで設定した変数',
    tooltip:
      '属性情報を引き継ぐために「計測サイトで用意した変数」を入力してください。\n未確認の場合は空白で登録の上、サイトに「Googleタグマネージャーに引き継ぐためコード」を設置する際に手動でご設定ください。',
  },
  {
    name: GTM_ATTRIBUTES,
    title: 'Googleタグマネージャーで設定した変数名',
    tooltip:
      'Googleタグマネージャーで設定した「データレイヤーの変数」を入力してください。\n未確認の場合は空白で登録の上、Googleタグマネージャーにタグを設置する際に手動でご設定ください。',
  },
];

const RowItems = (props) => {
  const { columns, rows, disabled, onChange } = props;

  return (
    <>
      {rows.map((row, index) => {
        const keyIndex = index;
        return (
          <tr key={keyIndex}>
            {columns.map((column) => {
              const item = row[column.name];
              if (column.name === 'label') {
                return <td>{item}</td>;
              }
              return (
                <td>
                  <FormInput
                    name={row.field}
                    value={item.value}
                    error={item.error}
                    disabled={disabled}
                    onChange={onChange(column.name)}
                  />
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

RowItems.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  rows: arrayOf(shape({})).isRequired,
  onChange: func.isRequired,
  disabled: bool,
};

RowItems.defaultProps = {
  disabled: false,
};

const AttributeSetting = (props) => {
  const { info, onChange } = props;

  const {
    [HAS_ATTRIBUTE]: { value: hasAttribute },
    [TAG_MANAGER_TYPE]: { value: tagManagerType },
    [GTM_ATTRIBUTES]: gtmAttributes,
    attributes,
  } = info;

  const isTagGtm = tagManagerType === TAG_MANAGER_GTM;

  const columns = isTagGtm ? COLUMN_TAG_GTM : COLUMN_TAG_OTHER;

  const rows = useMemo(() => {
    return ATTRIBUTE_FIELDS.map((field) => ({
      field,
      label: ATTRIBUTE_LABELS[field],
      [ATTRIBUTES]: {
        value: info[ATTRIBUTES].value[field],
        error: info[ATTRIBUTES].error?.[field],
      },
      [GTM_ATTRIBUTES]: {
        value: info[GTM_ATTRIBUTES].value[field],
        error: info[GTM_ATTRIBUTES].error?.[field],
      },
    }));
  }, [info]);

  const handleSelectAttribute = (value) => {
    const isEnable = value === TRUE_FLAG;
    onChange(HAS_ATTRIBUTE, isEnable);

    // Clear error if any when disabled
    if (!isEnable) {
      onChange(ATTRIBUTES, attributes.value);
      if (isTagGtm) {
        onChange(GTM_ATTRIBUTES, gtmAttributes.value);
      }
    }
  };

  const handleChangeValue = (field) => (name, value) => {
    onChange(field, { ...info[field].value, [name]: value });
  };

  return (
    <Form className="form-attribute-setting" autoComplete="off">
      <FormGroup label={info[HAS_ATTRIBUTE].label}>
        <EbisRadio
          name="has_attribute_flag"
          label="する"
          value={TRUE_FLAG}
          checked={hasAttribute}
          onChange={handleSelectAttribute}
        />
        <EbisRadio
          name="has_attribute_flag"
          label="しない"
          value={FALSE_FLAG}
          checked={!hasAttribute}
          onChange={handleSelectAttribute}
        />
      </FormGroup>
      <FormGroup label={info[ATTRIBUTES].label}>
        <TableModal
          header={
            <tr>
              {columns.map((column) => (
                <th>
                  {column.title}
                  {!!column.tooltip && (
                    <DragonBall variant="form-label">
                      {column.tooltip}
                    </DragonBall>
                  )}
                </th>
              ))}
            </tr>
          }
          body={
            <RowItems
              disabled={!hasAttribute}
              columns={columns}
              rows={rows}
              onChange={handleChangeValue}
            />
          }
          isNoScrollWrapper
        />
      </FormGroup>
    </Form>
  );
};

AttributeSetting.propTypes = {
  info: shape({}).isRequired,
  onChange: func.isRequired,
};

AttributeSetting.defaultProps = {};

export default React.memo(AttributeSetting);
