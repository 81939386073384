const GET_LIST = 'agency-management/GET_LIST';
const SET_DATA_LIST = 'agency-management/SET_DATA_LIST';
const SET_ERROR_LIST = 'agency-management/SET_ERROR_LIST';
const SET_STATUS_LIST = 'agency-management/SET_STATUS_LIST';

const GET_DETAIL = 'agency-management/GET_DETAIL';
const SET_DATA_DETAIL = 'agency-management/SET_DATA_DETAIL';
const SET_ERROR_DETAIL = 'agency-management/SET_ERROR_DETAIL';
const SET_STATUS_DETAIL = 'agency-management/SET_STATUS_DETAIL';

const VALIDATE = 'agency-management/VALIDATE';
const CREATE = 'agency-management/CREATE';
const UPDATE = 'agency-management/UPDATE';
const DELETE = 'agency-management/DELETE';

const SET_STATUS = 'agency-management/SET_STATUS';
const SET_ERRORS = 'agency-management/SET_ERRORS';

const GET_AGENT = 'agency-management/GET_AGENT';
const SET_AGENT = 'agency-management/SET_AGENT';
const SYNC_AGENT = 'agency-management/SYNC_AGENT';
const RESET_AGENT = 'agency-management/RESET_AGENT';
const DOWNLOAD_CSV_AGENT = 'agency-management/DOWNLOAD_CSV_AGENT';
const DOWNLOAD_CSV_AD_AGENT = 'agency-management/DOWNLOAD_CSV_AD_AGENT';
const DOWNLOAD_CSV_AGENCY_MANAGEMENT =
  'agency-management/DOWNLOAD_CSV_AGENCY_MANAGEMENT';

const SET_STATE_AGENT_SYNC_MODAL =
  'agency-management/SET_STATE_AGENT_SYNC_MODAL';

const RESET_STATE_AGENT_SYNC_MODAL =
  'agency-management/RESET_STATE_AGENT_SYNC_MODAL';

const UPLOAD_CSV = 'agency-management/UPLOAD_CSV';
const SET_UPLOAD_STATUS = 'agency-management/SET_UPLOAD_STATUS';
const SET_STATUS_OPEN_COMPLETED_MODAL =
  'agency-management/SET_STATUS_OPEN_COMPLETED_MODAL';

export default {
  GET_LIST,
  SET_DATA_LIST,
  SET_ERROR_LIST,
  SET_STATUS_LIST,
  GET_DETAIL,
  SET_DATA_DETAIL,
  SET_STATUS_DETAIL,
  SET_ERROR_DETAIL,
  VALIDATE,
  CREATE,
  UPDATE,
  DELETE,
  SET_STATUS,
  SET_ERRORS,
  GET_AGENT,
  SET_AGENT,
  SYNC_AGENT,
  RESET_AGENT,
  DOWNLOAD_CSV_AGENT,
  DOWNLOAD_CSV_AD_AGENT,
  SET_STATE_AGENT_SYNC_MODAL,
  RESET_STATE_AGENT_SYNC_MODAL,
  UPLOAD_CSV,
  SET_UPLOAD_STATUS,
  DOWNLOAD_CSV_AGENCY_MANAGEMENT,
  SET_STATUS_OPEN_COMPLETED_MODAL,
};
