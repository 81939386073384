import types from 'store/reflectiontime/types';

const initialState = {
  control: {},
  data: {},
  settings: {
    detail: {
      account_str: null,
      last_order_date: null,
      analyzed_date: null,
    },
  },
  communications: {
    start: false,
    fetching: false,
    success: false,
    error: false,
    errorMess: [],
  },
};

const ReflectionTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_REFLECTION_TIME_START:
      return {
        ...state,
        communications: {
          ...state.communications,
          start: true,
          fetching: true,
          error: false,
          success: false,
        },
      };
    case types.FETCH_REFLECTION_TIME_SUCCESS:
      return {
        ...state,
        communications: {
          ...state.communications,
          start: false,
          fetching: false,
          error: false,
          success: true,
        },
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case types.FETCH_REFLECTION_TIME_ERROR:
      return {
        ...state,
        communications: {
          ...state.communications,
          start: false,
          fetching: false,
          error: true,
          success: false,
          errorMess: [...state.communications.errorMess, action.payload],
        },
      };
    default:
      return state;
  }
};

export default ReflectionTimeReducer;
