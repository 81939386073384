import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import './DisplayItemsCvForm.scss';

import FormGroup from 'views/atoms/FormGroup';
import CheckboxElement from 'views/atoms/CheckboxElement';
import {
  DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  CONTACT_DIRECT,
  CONTACT_FIRST,
  DISPLAY_GROUP_ITEM,
  CONTACT_DEVICE,
} from 'domain/settings/display-items';
import { PRIORITY_AXIS_EBIS, PRIORITY_AXIS_MEDIA_SYNC } from 'domain/consts';
import priorityAxisService from 'services/common/priorityAxisService';
import PriorityAxisCheckBox from 'views/atoms/PriorityAxisCheckBox/PriorityAxisCheckBox';

const DisplayItemsCvForm = (props) => {
  const {
    items,
    onChange,
    defaultValue,
    settingsDefault,
    onCheckDisplayDefault,
    resetCvSettings,
    priorityAxis,
    enabledPriorityAxis,
    resetLink,
    isWarning,
    filters,
    customView,
  } = props;

  // true: 媒体軸優先(PRIORITY_AXIS_MEDIA_SYNC)
  // false:エビス軸優先(PRIORITY_AXIS_EBIS)
  const [mediaSync, toggleMediaSync] = useState(
    enabledPriorityAxis ? priorityAxis === PRIORITY_AXIS_MEDIA_SYNC : false
  );

  const getDefaultValue = useCallback(
    (group, sync) => {
      const defaultState = {};
      Object.keys(items).forEach((key) => {
        if (items[key].getGroup(sync) === group) {
          defaultState[key] = items[key].displayDefault;
          if (!items[key].getRequired(sync) && key in defaultValue) {
            defaultState[key] = defaultValue[key];
          }
        }
      });
      return defaultState;
    },
    [defaultValue, items]
  );
  const getSettingsDefault = useCallback(
    (group, sync) => {
      const settings = {};
      Object.keys(items).forEach((key) => {
        if (items[key].getGroup(sync) === group) {
          settings[key] = items[key].displayDefault;
          if (!items[key].getRequired(sync) && key in settingsDefault) {
            settings[key] = settingsDefault[key];
          }
        }
      });
      return settings;
    },
    [settingsDefault, items]
  );

  // const cvContactDefaultState = getDefaultValue(
  //   DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY
  // );
  // const cvAttrDefaultState = getDefaultValue(
  //   DISPLAY_GROUP_CONVERSION_ATTRIBUTES
  // );
  // const cvHistoryInfoDefaultState = getDefaultValue(
  //   DISPLAY_GROUP_ITEMS_CONTACT_HISTORY
  // );
  // const cvFlowItemDefaultState = getDefaultValue(DISPLAY_GROUP_ITEM);

  const [cvContactFormState, updateCvContactFormState] = useState({});
  const [cvAttrFormState, updateCvAttrFormState] = useState({});
  const [cvHistoryInfoFormState, updateCvHistoryInfoFormState] = useState({});
  const [cvFlowItemState, updateCvFlowItemState] = useState({});

  const [indirect, toggleIndirect] = useState(false);
  const [indirectCount, updateIndirectCount] = useState(0);

  const [cvEbisHistoryInfoFormState, setCvEbisHistoryInfoFormState] = useState(
    getDefaultValue(DISPLAY_GROUP_ITEMS_CONTACT_HISTORY, false)
  );
  const [cvSyncHistoryInfoFormState, setCvSyncHistoryInfoFormState] = useState(
    getDefaultValue(DISPLAY_GROUP_ITEMS_CONTACT_HISTORY, true)
  );
  const cvEbisHistoryInfoSettingDefault = useMemo(() => {
    return getSettingsDefault(DISPLAY_GROUP_ITEMS_CONTACT_HISTORY, false);
  }, [getSettingsDefault]);
  const cvSyncHistoryInfoSettingDefault = useMemo(() => {
    return getSettingsDefault(DISPLAY_GROUP_ITEMS_CONTACT_HISTORY, true);
  }, [getSettingsDefault]);

  const [isCvAxisDefault, toggleCvAxisDefault] = useState(true);
  const [isCvItemDefault, toggleCvItemDefault] = useState(true);

  // for CustomViewModal screen switching
  const updatePriorityAxisForView = useCallback(
    (axis) => {
      const newMediaSync = axis === PRIORITY_AXIS_MEDIA_SYNC;
      if (newMediaSync !== mediaSync) {
        toggleMediaSync(newMediaSync);
      }
    },
    [mediaSync]
  );

  useEffect(() => {
    const cvContactDefaultState = getDefaultValue(
      DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
      priorityAxis === PRIORITY_AXIS_MEDIA_SYNC
    );
    updateCvContactFormState(cvContactDefaultState);

    const enabledIndirect =
      Object.keys(cvContactDefaultState).filter(
        (key) =>
          ![CONTACT_DIRECT, CONTACT_FIRST].includes(key) &&
          cvContactDefaultState[key]
      ).length > 0;
    toggleIndirect(enabledIndirect);

    const cvAttrDefaultState = getDefaultValue(
      DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
      priorityAxis === PRIORITY_AXIS_MEDIA_SYNC
    );
    updateCvAttrFormState(cvAttrDefaultState);
    const cvHistoryInfoDefaultState = getDefaultValue(
      DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
      priorityAxis === PRIORITY_AXIS_MEDIA_SYNC
    );
    updateCvHistoryInfoFormState(cvHistoryInfoDefaultState);

    if (priorityAxis === PRIORITY_AXIS_MEDIA_SYNC) {
      setCvEbisHistoryInfoFormState(cvEbisHistoryInfoSettingDefault);
    } else {
      setCvSyncHistoryInfoFormState(cvSyncHistoryInfoSettingDefault);
    }

    const cvFlowItemDefaultState = getDefaultValue(
      DISPLAY_GROUP_ITEM,
      priorityAxis === PRIORITY_AXIS_MEDIA_SYNC
    );
    updateCvFlowItemState(cvFlowItemDefaultState);

    // for CustomViewModal screen switching
    updatePriorityAxisForView(priorityAxis);
  }, [
    getDefaultValue,
    priorityAxis,
    cvEbisHistoryInfoSettingDefault,
    cvSyncHistoryInfoSettingDefault,
  ]);

  // axis group
  const cvAttrItems = {};
  Object.keys(items).forEach((key) => {
    if (
      items[key].getGroup(mediaSync) === DISPLAY_GROUP_CONVERSION_ATTRIBUTES
    ) {
      cvAttrItems[key] = items[key];
    }
  });
  const cvHistoryInfoItems = {};
  Object.keys(items).forEach((key) => {
    if (
      items[key].getGroup(mediaSync) === DISPLAY_GROUP_ITEMS_CONTACT_HISTORY
    ) {
      cvHistoryInfoItems[key] = items[key];
    }
  });
  const cvContactItems = Object.keys(items).reduce((acc, key) => {
    if (
      items[key].getGroup(mediaSync) ===
      DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY
    ) {
      return { ...acc, [key]: items[key] };
    }
    return acc;
  }, {});
  const cvFlowItems = {};
  Object.keys(items).forEach((key) => {
    if (items[key].getGroup(mediaSync) === DISPLAY_GROUP_ITEM) {
      cvFlowItems[key] = items[key];
    }
  });

  const getIndirectItems = useCallback(
    (indirectChecked) => {
      const indirectItems = {};
      Object.keys(cvContactItems).forEach((key) => {
        if (![CONTACT_DIRECT, CONTACT_FIRST].includes(key)) {
          indirectItems[key] = indirectChecked
            ? cvContactFormState[key]
            : false;
        }
        return key;
      });
      return indirectItems;
    },
    [cvContactFormState, cvContactItems]
  );

  const getContactCount = useCallback((contactState, indirectEnabled) => {
    return Object.keys(contactState).filter(
      (key) =>
        ([CONTACT_DIRECT, CONTACT_FIRST].includes(key) && contactState[key]) ||
        (![CONTACT_DIRECT, CONTACT_FIRST].includes(key) &&
          indirectEnabled &&
          contactState[key])
    ).length;
  }, []);

  // 優先軸切替
  const updatePriorityAxis = useCallback(
    (axis) => {
      const newMediaSync = axis === PRIORITY_AXIS_MEDIA_SYNC;
      if (newMediaSync !== mediaSync) {
        if (mediaSync) {
          setCvSyncHistoryInfoFormState(cvHistoryInfoFormState);
          updateCvHistoryInfoFormState(cvEbisHistoryInfoFormState);
        } else {
          setCvEbisHistoryInfoFormState(cvHistoryInfoFormState);
          updateCvHistoryInfoFormState(cvSyncHistoryInfoFormState);
        }
        const indirectItems = getIndirectItems(indirect);
        toggleMediaSync(newMediaSync);
        onChange({
          media_sync: newMediaSync,
          ...cvAttrFormState,
          ...cvContactFormState,
          ...indirectItems,
          ...cvHistoryInfoFormState,
          ...cvFlowItemState,
        });
      }
    },
    [
      mediaSync,
      getIndirectItems,
      toggleMediaSync,
      cvAttrFormState,
      cvContactFormState,
      cvFlowItemState,
      cvHistoryInfoFormState,
      cvEbisHistoryInfoFormState,
      cvSyncHistoryInfoFormState,
      indirect,
      onChange,
    ]
  );

  // 優先軸の切り替え
  const handlePriorityAxisSelect = (axis) => {
    updatePriorityAxis(axis);
  };

  const handleCvAttrChange = (field, isChecked) => {
    updateCvAttrFormState((prev) => {
      return { ...prev, [field]: isChecked };
    });
    const indirectItems = getIndirectItems(indirect);
    onChange({
      media_sync: mediaSync,
      ...cvAttrFormState,
      ...cvContactFormState,
      ...indirectItems,
      ...cvHistoryInfoFormState,
      ...cvFlowItemState,
      [field]: isChecked,
    });
  };
  const handleCvContactChange = (field, isChecked) => {
    updateCvContactFormState((prev) => {
      return { ...prev, [field]: isChecked };
    });
    const indirectItems = getIndirectItems(indirect);
    onChange({
      media_sync: mediaSync,
      ...cvAttrFormState,
      ...cvContactFormState,
      ...indirectItems,
      ...cvHistoryInfoFormState,
      ...cvFlowItemState,
      [field]: isChecked,
    });
  };
  const handleCvHistoryInfoChange = (field, isChecked) => {
    updateCvHistoryInfoFormState((prev) => {
      return { ...prev, [field]: isChecked };
    });
    const indirectItems = getIndirectItems(indirect);
    onChange({
      media_sync: mediaSync,
      ...cvAttrFormState,
      ...cvContactFormState,
      ...indirectItems,
      ...cvHistoryInfoFormState,
      ...cvFlowItemState,
      [field]: isChecked,
    });
  };
  const handleCvFlowItemChange = (field, isChecked) => {
    updateCvFlowItemState((prev) => {
      return { ...prev, [field]: isChecked };
    });
    const indirectItems = getIndirectItems(indirect);
    onChange({
      media_sync: mediaSync,
      ...cvAttrFormState,
      ...cvContactFormState,
      ...indirectItems,
      ...cvHistoryInfoFormState,
      ...cvFlowItemState,
      [field]: isChecked,
    });
  };

  const handleIndirectItemChange = useCallback(
    (checked) => {
      const checkedItems = {};
      Object.keys(cvContactItems).forEach((key) => {
        if (![CONTACT_DIRECT, CONTACT_FIRST].includes(key)) {
          checkedItems[key] = checked;
        }
      });
      updateCvContactFormState((prev) => ({ ...prev, ...checkedItems }));
      onChange({
        media_sync: mediaSync,
        ...cvAttrFormState,
        ...cvContactFormState,
        ...cvHistoryInfoFormState,
        ...cvFlowItemState,
        ...checkedItems,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cvAttrFormState,
      cvContactFormState,
      cvContactItems,
      cvFlowItemState,
      cvHistoryInfoFormState,
      getContactCount,
      indirect,
      onChange,
      mediaSync,
    ]
  );

  const handleIndirectCheckAll = useCallback(
    () => indirectCount < 9 && handleIndirectItemChange(true),
    [handleIndirectItemChange, indirectCount]
  );
  const handleIndirectClearAll = useCallback(
    () => indirectCount > 0 && handleIndirectItemChange(false),
    [handleIndirectItemChange, indirectCount]
  );

  const handleIndirectChange = (name, checked) => {
    toggleIndirect((prev) => !prev);
    const indirectItems = getIndirectItems(checked);
    onChange({
      media_sync: mediaSync,
      ...cvAttrFormState,
      ...cvContactFormState,
      ...cvHistoryInfoFormState,
      ...cvFlowItemState,
      ...indirectItems,
    });
  };

  useEffect(() => {
    const indirectItems = getIndirectItems(indirect);
    const formState = {
      ...cvAttrFormState,
      ...cvContactFormState,
      ...indirectItems,
      ...cvHistoryInfoFormState,
      ...cvFlowItemState,
    };
    const formStateAxis = {
      ...cvAttrFormState,
      ...cvContactFormState,
      ...indirectItems,
      ...cvHistoryInfoFormState,
    };
    const formStateItem = {
      ...cvFlowItemState,
    };
    const settingsDefaultItem = getSettingsDefault(
      DISPLAY_GROUP_ITEM,
      mediaSync
    );
    const settingsDefaultFull = {};
    const settingsDefaultAxis = {};
    Object.keys(items).forEach((key) => {
      if (
        !priorityAxisService
          .getSelectableContactListByMediaSync(!mediaSync)
          .includes(key)
      ) {
        settingsDefaultFull[key] = !!settingsDefault[key];
        // Get dimensions by excluding items from all
        if (!Object.prototype.hasOwnProperty.call(settingsDefaultItem, key)) {
          settingsDefaultAxis[key] = !!settingsDefault[key];
        }
      }
    });
    onCheckDisplayDefault(isEqual(formState, settingsDefaultFull));
    toggleCvAxisDefault(isEqual(formStateAxis, settingsDefaultAxis));
    toggleCvItemDefault(isEqual(formStateItem, settingsDefaultItem));
  }, [
    cvAttrFormState,
    cvContactFormState,
    cvFlowItemState,
    cvHistoryInfoFormState,
    items,
    onCheckDisplayDefault,
    settingsDefault,
    indirect,
    getIndirectItems,
    getContactCount,
  ]);

  useEffect(() => {
    if (resetCvSettings) {
      const cvContactSettingsDefault = getSettingsDefault(
        DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
        mediaSync
      );
      const cvAttrSettingsDefault = getSettingsDefault(
        DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
        mediaSync
      );
      const cvHistoryInfoSettingsDefault = getSettingsDefault(
        DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
        mediaSync
      );
      const cvFlowItemSettingsDefault = getSettingsDefault(
        DISPLAY_GROUP_ITEM,
        mediaSync
      );
      updateCvAttrFormState(cvAttrSettingsDefault);
      updateCvContactFormState(cvContactSettingsDefault);
      updateCvFlowItemState(cvFlowItemSettingsDefault);
      updateCvHistoryInfoFormState(cvHistoryInfoSettingsDefault);
      toggleIndirect(
        Object.keys(cvContactSettingsDefault).filter(
          (key) =>
            ![CONTACT_DIRECT, CONTACT_FIRST].includes(key) &&
            cvContactSettingsDefault[key]
        ).length > 0
      );
      onChange({
        media_sync: mediaSync,
        ...cvAttrFormState,
        ...cvContactFormState,
        ...cvHistoryInfoFormState,
        ...cvFlowItemState,
        ...cvAttrSettingsDefault,
        ...cvContactSettingsDefault,
        ...cvFlowItemSettingsDefault,
        ...cvHistoryInfoSettingsDefault,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetCvSettings]);

  // for CustomViewModal
  const handleResetCvAxisForm = () => {
    const cvContactSettingsDefault = getSettingsDefault(
      DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
      mediaSync
    );
    const cvAttrSettingsDefault = getSettingsDefault(
      DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
      mediaSync
    );
    const cvHistoryInfoSettingsDefault = getSettingsDefault(
      DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
      mediaSync
    );
    updateCvAttrFormState(cvAttrSettingsDefault);
    updateCvContactFormState(cvContactSettingsDefault);
    updateCvHistoryInfoFormState(cvHistoryInfoSettingsDefault);
    toggleIndirect(
      Object.keys(cvContactSettingsDefault).filter(
        (key) =>
          ![CONTACT_DIRECT, CONTACT_FIRST].includes(key) &&
          cvContactSettingsDefault[key]
      ).length > 0
    );
    onChange({
      media_sync: mediaSync,
      ...cvAttrFormState,
      ...cvContactFormState,
      ...cvHistoryInfoFormState,
      ...cvFlowItemState,
      ...cvAttrSettingsDefault,
      ...cvContactSettingsDefault,
      ...cvHistoryInfoSettingsDefault,
    });
  };
  const handleResetCvItemForm = () => {
    const cvFlowItemSettingsDefault = getSettingsDefault(
      DISPLAY_GROUP_ITEM,
      mediaSync
    );
    updateCvFlowItemState(cvFlowItemSettingsDefault);
    onChange({
      ...cvFlowItemSettingsDefault,
    });
  };

  useEffect(() => {
    const count = Object.keys(cvContactFormState).filter(
      (key) =>
        ![CONTACT_DIRECT, CONTACT_FIRST].includes(key) &&
        indirect &&
        cvContactFormState[key]
    ).length;
    updateIndirectCount(count);
  }, [cvContactFormState, getContactCount, indirect]);

  const checkAllClass = classNames({
    'display-items-form__action-link': true,
    'display-items-form__action-link--disabled':
      indirectCount === 9 || !indirect,
  });
  const clearAllClass = classNames({
    'display-items-form__action-link': true,
    'display-items-form__action-link--disabled':
      indirectCount === 0 || !indirect,
  });
  const historyInfoClass = classNames({
    'display-items-form__checkbox-list': true,
    'display-items-form__checkbox-list-hisotry-info-view': customView,
  });
  const isDisplayItems = (key) => {
    switch (key) {
      case CONTACT_DEVICE:
        if (filters.cross_device) {
          return !isWarning;
        }
        return false;
      default:
        return true;
    }
  };

  return (
    <div className="display-items-form">
      <FormGroup
        heading="集計軸"
        linkActive={!isCvAxisDefault}
        onClick={handleResetCvAxisForm}
        resetLink={resetLink}
        cvForm="true"
        customView={customView}
      >
        <PriorityAxisCheckBox
          mediaSync={mediaSync}
          enabledPriorityAxis={enabledPriorityAxis}
          handlePriorityAxisSelect={handlePriorityAxisSelect}
        />
        <FormGroup heading="コンバージョン属性" resetLink={false}>
          <div className="display-items-form__checkbox-list">
            {Object.keys(cvAttrItems).map((key) => {
              const item = cvAttrItems[key];
              return (
                <div
                  key={key}
                  className="display-items-form__checkbox-item"
                  style={{ order: item.order }}
                >
                  <CheckboxElement
                    name={key}
                    label={item.title}
                    checked={cvAttrFormState[key]}
                    disabled={item.getRequired(mediaSync)}
                    onChange={handleCvAttrChange}
                  />
                </div>
              );
            })}
          </div>
        </FormGroup>
        <FormGroup
          className="display-items-form__group-contact-history"
          heading="コンバージョンに至るまでの接触履歴"
          resetLink={false}
        >
          <div className="display-items-form__contact-history">
            <div
              className="display-items-form__direct display-items-form__checkbox-item"
              style={{ order: cvContactItems[CONTACT_DIRECT].order }}
            >
              <CheckboxElement
                name={CONTACT_DIRECT}
                label={cvContactItems[CONTACT_DIRECT].title}
                checked={cvContactFormState[CONTACT_DIRECT]}
                disabled={cvContactItems[CONTACT_DIRECT].getRequired(mediaSync)}
                onChange={handleCvContactChange}
              />
            </div>
            <div
              className="display-items-form__indirect display-items-form__checkbox-item"
              style={{ order: cvContactItems[CONTACT_DIRECT].order }}
            >
              <CheckboxElement
                name="indirect"
                label="間接効果"
                checked={indirect}
                onChange={handleIndirectChange}
              />
            </div>
            <div className="display-items-form__actions">
              <span
                aria-hidden="true"
                className={checkAllClass}
                onClick={handleIndirectCheckAll}
              >
                すべて選択
              </span>
              <span
                aria-hidden="true"
                className={clearAllClass}
                onClick={handleIndirectClearAll}
              >
                クリア
              </span>
            </div>
            <div className="display-items-form__indirect-list">
              {Object.keys(cvContactItems).map((key) => {
                if ([CONTACT_DIRECT, CONTACT_FIRST].includes(key)) {
                  return <></>;
                }
                const item = cvContactItems[key];
                return (
                  <div
                    key={key}
                    className="display-items-form__indirect-item"
                    style={{ order: item.order }}
                  >
                    <CheckboxElement
                      name={key}
                      label={item.title}
                      checked={cvContactFormState[key]}
                      disabled={!indirect}
                      onChange={handleCvContactChange}
                    />
                  </div>
                );
              })}
            </div>
            <div
              className="display-items-form__first display-items-form__checkbox-item"
              style={{ order: cvContactItems[CONTACT_FIRST].order }}
            >
              <CheckboxElement
                name={CONTACT_FIRST}
                label={cvContactItems[CONTACT_FIRST].title}
                checked={cvContactFormState[CONTACT_FIRST]}
                disabled={cvContactItems[CONTACT_FIRST].getRequired(mediaSync)}
                onChange={handleCvContactChange}
              />
            </div>
          </div>
        </FormGroup>
        <div className="display-items-form__history-info-list">
          <h3 className="display-items-form__history-info-heading">
            接触履歴の表示項目
          </h3>
          <div className={historyInfoClass}>
            {Object.keys(cvHistoryInfoItems).map((key) => {
              // Skip display item
              if (!isDisplayItems(key)) {
                cvHistoryInfoFormState[key] = false;
                return <></>;
              }
              const item = cvHistoryInfoItems[key];
              return (
                <div
                  key={key}
                  className="display-items-form__history-info-item"
                  style={{ order: item.order }}
                >
                  <CheckboxElement
                    name={key}
                    label={item.title}
                    checked={cvHistoryInfoFormState[key]}
                    disabled={item.getRequired(mediaSync)}
                    onChange={handleCvHistoryInfoChange}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </FormGroup>
      {Object.keys(cvFlowItems).length > 0 && (
        <div className="custom-view-modal__items--margin-bottom">
          <FormGroup
            heading="表示項目"
            linkActive={!isCvItemDefault}
            onClick={handleResetCvItemForm}
            resetLink={resetLink}
            cvForm="true"
            customView={customView}
          >
            <div className="display-items-form__checkbox-list">
              {Object.keys(cvFlowItems).map((key) => {
                const item = cvFlowItems[key];
                return (
                  <div
                    key={key}
                    className="display-items-form__checkbox-item"
                    style={{ order: item.order }}
                  >
                    <CheckboxElement
                      name={key}
                      label={item.title}
                      checked={cvFlowItemState[key]}
                      disabled={item.getRequired(mediaSync)}
                      onChange={handleCvFlowItemChange}
                    />
                  </div>
                );
              })}
            </div>
          </FormGroup>
        </div>
      )}
    </div>
  );
};

DisplayItemsCvForm.propTypes = {
  items: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.shape({}),
  settingsDefault: PropTypes.shape({}),
  onChange: PropTypes.func,
  onCheckDisplayDefault: PropTypes.func,
  resetCvSettings: PropTypes.number,
  priorityAxis: PropTypes.string,
  enabledPriorityAxis: PropTypes.bool,
  resetLink: PropTypes.bool,
  isWarning: PropTypes.bool,
  filters: PropTypes.func,
  customView: PropTypes.bool,
};

DisplayItemsCvForm.defaultProps = {
  defaultValue: {},
  settingsDefault: {},
  onChange: () => {},
  onCheckDisplayDefault: () => {},
  resetCvSettings: 0,
  priorityAxis: PRIORITY_AXIS_EBIS,
  enabledPriorityAxis: false,
  resetLink: false,
  isWarning: false,
  filters: {},
  customView: false,
};

export default DisplayItemsCvForm;
