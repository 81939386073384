import React from 'react';
import { func, number } from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CountItemSelected from 'views/molecules/CountItemSelected';
import { REMAIN_ITEMS_REGISTER } from 'domain/ad-management/consts';

function TableControlTabCookieProgram(props) {
  const {
    count,
    totalItems,
    remain,
    onRegister,
    onDelete,
    handleCheckURL,
  } = props;

  return (
    <div className="d-flex table-control">
      <div className="d-flex table-control__icon">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip className="tooltip-common">設定を追加</Tooltip>}
        >
          <Button
            variant="secondary"
            size="sm"
            className="btn-icon--no-text rounded-circle"
            disabled={remain === 0}
            onClick={() => onRegister(true)}
          >
            <i className="fal fa-plus fs-22"/>
          </Button>
        </OverlayTrigger>
      </div>
      <CountItemSelected count={count}>
        <Button
          variant="secondary"
          size="sm"
          disabled={count === 0}
          onClick={() => onDelete()}
        >
          削除
        </Button>
        <Button
          size="sm"
          variant="secondary"
          disabled={count === 0}
          onClick={() => handleCheckURL()}
        >
          動作確認
        </Button>
      </CountItemSelected>
      <div className='count-item'>
        計{totalItems}件
      </div>
    </div>
  );
}

TableControlTabCookieProgram.propTypes = {
  count: number,
  totalItems: number.isRequired,
  remain: number,
  onRegister: func,
  onDelete: func,
  handleCheckURL:func,
};

TableControlTabCookieProgram.defaultProps = {
  count: 0,
  remain: REMAIN_ITEMS_REGISTER,
  onRegister: () => {},
  onDelete: () => {},
  handleCheckURL:()=>{},
};

export default TableControlTabCookieProgram;
