import { CNT_ACCESS } from 'domain/fields';
import moment from 'moment';
import isNil from 'lodash/isNil';
import {
  DEFAULT_SORT_TABLE,
  MEMO_HIDDEN_PERIOD_TYPES,
  PERIOD_TYPE,
} from 'domain/period-analyze/consts';
import {
  communicationStatus,
  containsByAllKeys,
  equalsAllKeys,
} from 'services/utils';
import periodAnalysisService from 'services/period-analyze/periodAnalysisService';

import tableService from 'services/period-analyze/tableService';
import chartService from 'services/period-analyze/chartService';
import { buildPeriodRange } from 'domain/utils';
import { API_DATE_FORMAT } from 'domain/consts';

import types from './types';

const {
  UPDATE_CHART_DATA,
  UPDATE_COMPARED_CHART_DATA,
  UPDATE_TABLE_DATA,
  UPDATE_COMPARED_TABLE_DATA,
  SET_CHART_STATUS,
  SET_TABLE_STATUS,
  SET_SHOW_CHART,
  CLEAN_STATES,
  GET_BOOKMARK,
  APPLY_BOOKMARK,
  UPDATE_CHART_DISPLAY_MEMO,
  SET_MEMO_SUBMITTING,
  SET_PERIOD_RANGE,
  SET_MEMO_ERRORS,
  UPDATE_CHART_DISPLAY_DATA,
  REMOVE_SECONDARY_METRIC,
} = types;

const { IDLE, UPDATING } = communicationStatus;

const initialState = {
  settings: {
    chart: {
      axis: {
        primary: CNT_ACCESS,
        secondary: null,
      },
      bookmark: {
        primary: null,
        secondary: null,
        enabled: false,
        loaded: false,
      },
      categories: [],
      selectedCategories: [],
      instance: null,
      visibleList: [],
    },
    table: {
      pagination: {
        totalRows: 0,
        currentPage: 1,
      },
      sorts: DEFAULT_SORT_TABLE,
      comparedPagination: {
        totalRows: 0,
        currentPage: 1,
      },
      comparedSorts: DEFAULT_SORT_TABLE,
      currentPeriodActive: true,
    },
    display: {
      showChart: true,
    },
    memo: {
      enabled: false,
      hidden: false,
      showModal: false,
      modalReset: new Date().getTime(),
      modalData: null,
      showDeleteModal: false,
      showErrorModal: false,
    },
    periodType: PERIOD_TYPE.DAY,
    periodRange: [],
    comparedPeriodRange: [],
  },
  data: {
    chart: {
      list: [],
      comparedList: null,
    },
    table: {
      list: [],
      sum: {},
      comparedList: [],
      comparedSum: null,
      selectedRows: [],
      comparedSelectedRows: [],
    },
    memo: {
      list: [],
      errors: [],
    },
  },
  displayData: {
    chart: {
      list: [],
    },
    table: {
      headers: [],
      rows: [],
      sum: [],
      comparedHeaders: [],
      comparedRows: [],
      comparedSums: [],
    },
  },
  apiStatus: {
    chart: {
      status: IDLE,
    },
    table: {
      status: IDLE,
    },
    memo: {
      submitting: false,
    },
  },
};

const PeriodAnalysisReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_AXIS: {
      const { primary, secondary } = payload;
      const {
        primary: curPrimary,
        secondary: curSecondary,
      } = state.settings.chart.axis;

      if (primary === curPrimary && secondary === curSecondary) {
        return state;
      }

      const seriesList = state.displayData.chart.list;
      const categoriesList = state.data.chart.list;
      const comparedCategoriesList = state.data.chart.comparedList;
      const { periodRange, comparedPeriodRange } = state.settings;

      let newSeries;
      if (primary !== curPrimary) {
        newSeries = chartService.updatePrimaryAxis({
          seriesList,
          metric: primary,
          categoriesList,
          periodRange,
          comparedCategoriesList,
          comparedPeriodRange,
        });
      } else if (isNil(curSecondary)) {
        newSeries = chartService.addSecondaryAxis({
          categoriesList,
          seriesList,
          periodRange,
          metric: secondary,
        });
      } else if (isNil(secondary)) {
        newSeries = chartService.removeSecondaryAxis(seriesList);
      } else {
        newSeries = chartService.updateSecondaryAxis({
          seriesList,
          metric: secondary,
          categoriesList,
          periodRange,
        });
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            axis: payload,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: newSeries,
          },
        },
      };
    }

    case types.UPDATE_BOOKMARK: {
      const { primary, secondary, enabled } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            bookmark: {
              primary,
              secondary,
              enabled,
            },
          },
        },
      };
    }

    case types.UPDATE_PERIOD_TYPE: {
      const { periodType } = payload;
      const memoHidden = MEMO_HIDDEN_PERIOD_TYPES.includes(periodType);
      let memoEnabled;
      if (memoHidden) {
        memoEnabled = false;
      } else {
        memoEnabled = state.settings.memo.enabled;
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          periodType,
          memo: {
            ...state.settings.memo,
            hidden: memoHidden,
            enabled: memoEnabled,
          },
        },
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            status: UPDATING,
          },
          table: {
            ...state.apiStatus.table,
            status: UPDATING,
          },
        },
      };
    }

    case types.TOGGLE_MEMO: {
      const { enabled } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          memo: {
            ...state.settings.memo,
            enabled,
          },
        },
      };
    }

    case types.UPDATE_CATEGORIES: {
      const newSelectedCategories = payload;
      const { selectedCategories } = state.settings.chart;
      const { visibleList } = state.settings.chart;
      const hiddenList = selectedCategories.filter(
        (category) => !containsByAllKeys(visibleList, category)
      );

      const newVisibleList = newSelectedCategories.filter(
        (category) => !containsByAllKeys(hiddenList, category)
      );

      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            selectedCategories: newSelectedCategories,
            visibleList: newVisibleList,
          },
        },
      };
    }

    case types.SET_TABLE_CURRENT_PAGE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            pagination: {
              ...state.settings.table.pagination,
              currentPage: payload,
            },
          },
        },
      };
    }

    case types.SET_TABLE_CURRENT_PAGE_COMPARED: {
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            comparedPagination: {
              ...state.settings.table.comparedPagination,
              currentPage: payload,
            },
          },
        },
      };
    }

    //
    case types.UPDATE_SORT: {
      const sorts = payload;
      const displayHeaders = tableService.updateDisplayHeadersSorts({
        displayHeaders: state.displayData.table.headers,
        sorts,
      });
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            sorts,
            pagination: {
              ...state.settings.table.pagination,
              currentPage: 1,
            },
            comparedPagination: {
              ...state.settings.table.comparedPagination,
              currentPage: 1,
            },
          },
        },
        displayData: {
          ...state.displayData,
          table: {
            ...state.displayData.table,
            headers: displayHeaders,
          },
        },
      };
    }

    case types.UPDATE_SORT_COMPARED:
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            comparedSorts: payload,
          },
        },
      };

    case types.SELECT_ROW: {
      const row = payload;
      const { selectedRows } = state.data.table;
      if (!containsByAllKeys(selectedRows, row)) {
        const newSelectedRows = selectedRows.concat(row);
        return {
          ...state,
          data: {
            ...state.data,
            table: {
              ...state.data.table,
              selectedRows: newSelectedRows,
            },
          },
          displayData: {
            ...state.displayData,
            table: {
              ...state.displayData.table,
              rows: tableService.updateSelectedRows({
                selectedRows: newSelectedRows,
                displayRows: state.displayData.table.rows,
              }),
            },
          },
        };
      }
      return state;
    }

    case types.SELECT_ROW_COMPARED: {
      const row = payload;
      const { comparedSelectedRows } = state.data.table;
      if (!containsByAllKeys(comparedSelectedRows, row)) {
        const newSelectedRows = comparedSelectedRows.concat(row);
        return {
          ...state,
          data: {
            ...state.data,
            table: {
              ...state.data.table,
              comparedSelectedRows: newSelectedRows,
            },
          },
          displayData: {
            ...state.displayData,
            table: {
              ...state.displayData.table,
              comparedRows: tableService.updateSelectedRows({
                selectedRows: newSelectedRows,
                displayRows: state.displayData.table.comparedRows,
              }),
            },
          },
        };
      }
      return state;
    }

    case types.DESELECT_ROW: {
      const row = payload;
      const { selectedRows } = state.data.table;

      const newSelectedRows = selectedRows.filter(
        (selectedRow) => !equalsAllKeys(row, selectedRow)
      );
      return {
        ...state,
        data: {
          ...state.data,
          table: {
            ...state.data.table,
            selectedRows: newSelectedRows,
          },
        },
        displayData: {
          ...state.displayData,
          table: {
            ...state.displayData.table,
            rows: tableService.updateSelectedRows({
              selectedRows: newSelectedRows,
              displayRows: state.displayData.table.rows,
            }),
          },
        },
      };
    }

    case types.DESELECT_ROW_COMPARED: {
      const row = payload;
      const { comparedSelectedRows } = state.data.table;
      const newSelectedRows = comparedSelectedRows.filter(
        (selectedRow) => !equalsAllKeys(row, selectedRow)
      );
      return {
        ...state,
        data: {
          ...state.data,
          table: {
            ...state.data.table,
            comparedSelectedRows: comparedSelectedRows.filter(
              (selectedRow) => !equalsAllKeys(row, selectedRow)
            ),
          },
        },
        displayData: {
          ...state.displayData,
          table: {
            ...state.displayData.table,
            comparedRows: tableService.updateSelectedRows({
              selectedRows: newSelectedRows,
              displayRows: state.displayData.table.comparedRows,
            }),
          },
        },
      };
    }

    case types.SHOW_MODAL: {
      const memoList = state.data.memo.list;
      const { id, reset } = payload;
      let modalData = null;
      if (id) {
        modalData = periodAnalysisService.createModalData(id, memoList);
      }
      const modalReset = reset
        ? new Date().getTime()
        : state.settings.memo.modalReset;
      return {
        ...state,
        settings: {
          ...state.settings,
          memo: {
            ...state.settings.memo,
            showModal: true,
            modalData,
            modalReset,
          },
        },
      };
    }

    case types.HIDE_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          memo: {
            ...state.settings.memo,
            showModal: false,
          },
        },
        data: {
          ...state.data,
          memo: {
            ...state.data.memo,
            errors: [],
          },
        },
      };

    case types.SHOW_DELETE_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          memo: {
            ...state.settings.memo,
            showDeleteModal: true,
          },
        },
      };
    case types.HIDE_DELETE_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          memo: {
            ...state.settings.memo,
            showDeleteModal: false,
          },
        },
      };

    case types.SHOW_MEMO_ERROR_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          memo: {
            ...state.settings.memo,
            showErrorModal: true,
            showModal: false,
            showDeleteModal: false,
          },
        },
      };

    case types.HIDE_MEMO_ERROR_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          memo: {
            ...state.settings.memo,
            showErrorModal: false,
          },
        },
      };

    case types.UPDATE_VISIBLE: {
      const { dimensionKey, visible } = payload;
      const { visibleList } = state.settings.chart;
      let newVisibleList;
      if (visible) {
        newVisibleList = visibleList.concat(dimensionKey);
      } else {
        newVisibleList = visibleList.filter(
          (item) => !equalsAllKeys(item, dimensionKey)
        );
      }

      const seriesList = state.displayData.chart.list;
      const updatedChartDisplay = chartService.updateVisibleRow({
        seriesList,
        visible,
        row: dimensionKey,
      });

      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            visibleList: newVisibleList,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: updatedChartDisplay,
          },
        },
      };
    }

    case types.CHANGE_ACTIVE_TABLE: {
      const { currentPeriodActive } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            currentPeriodActive,
          },
        },
      };
    }

    case types.LOAD_MEMO_SUCCESS: {
      const memoList = payload;
      return {
        ...state,
        data: {
          ...state.data,
          memo: {
            ...state.data.memo,
            list: memoList,
          },
        },
      };
    }

    case SET_SHOW_CHART: {
      const show = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          display: {
            ...state.settings.display,
            showChart: show,
          },
        },
      };
    }

    case UPDATE_CHART_DATA: {
      const {
        list,
        selectedCategories,
        displayData,
        categories,
        visibleList,
      } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            categories,
            selectedCategories,
            visibleList,
          },
        },
        data: {
          ...state.data,
          chart: {
            ...state.data.chart,
            list,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: displayData,
            compared: false,
          },
        },
      };
    }

    case UPDATE_COMPARED_CHART_DATA: {
      const {
        list,
        comparedList,
        selectedCategories,
        displayData,
        visibleList,
      } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            selectedCategories,
            visibleList,
          },
        },
        data: {
          ...state.data,
          chart: {
            ...state.data.chart,
            list,
            comparedList,
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: displayData,
            compared: true,
          },
        },
      };
    }

    case UPDATE_TABLE_DATA: {
      const {
        list,
        displayRows,
        displayHeaders,
        displaySum,
        count,
        selectedRows,
      } = payload;
      const newSelectedRows = selectedRows || state.data.table.selectedRows;
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            pagination: {
              ...state.settings.table.pagination,
              totalRows: count,
            },
          },
        },
        data: {
          ...state.data,
          table: {
            ...state.data.table,
            list,
            selectedRows: newSelectedRows,
          },
        },
        displayData: {
          ...state.displayData,
          table: {
            ...state.displayData.table,
            headers: displayHeaders,
            rows: displayRows,
            sum: displaySum,
          },
        },
      };
    }

    case UPDATE_COMPARED_TABLE_DATA: {
      const {
        comparedList,
        comparedSum,
        displayRows,
        displayHeaders,
        displaySum,
        selectedRows,
        count,
      } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          table: {
            ...state.settings.table,
            comparedPagination: {
              ...state.settings.table.comparedPagination,
              totalRows: count,
            },
          },
        },
        data: {
          ...state.data,
          table: {
            ...state.data.table,
            selectedRows: selectedRows || state.data.table.selectedRows,
            comparedSum,
            comparedList,
          },
        },
        displayData: {
          ...state.displayData,
          table: {
            ...state.displayData.table,
            comparedHeaders: displayHeaders,
            comparedRows: displayRows,
            comparedSums: displaySum,
          },
        },
      };
    }

    case GET_BOOKMARK: {
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            bookmark: {
              ...state.settings.chart.bookmark,
              loaded: false,
            },
          },
        },
      };
    }

    case APPLY_BOOKMARK: {
      const {
        primary,
        secondary,
        enabled,
        showChart,
        shouldApplyMetrics,
      } = payload;
      if (!enabled) {
        return {
          ...state,
          settings: {
            ...state.settings,
            display: {
              ...state.settings.display,
              showChart: showChart !== false,
            },
            chart: {
              ...state.settings.chart,
              bookmark: {
                ...state.settings.chart.bookmark,
                enabled: false,
                loaded: true,
              },
            },
          },
        };
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          display: {
            ...state.settings.display,
            showChart: showChart !== false,
          },
          chart: {
            ...state.settings.chart,
            axis: {
              primary: shouldApplyMetrics
                ? primary
                : state.settings.chart.axis.primary,
              secondary: shouldApplyMetrics
                ? secondary
                : state.settings.chart.axis.secondary,
            },
            bookmark: {
              enabled,
              primary,
              secondary,
              loaded: true,
            },
          },
        },
      };
    }

    case UPDATE_CHART_DISPLAY_MEMO: {
      const displayData = payload;
      return {
        ...state,
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: displayData,
          },
        },
      };
    }

    case SET_TABLE_STATUS: {
      const status = payload;
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          table: {
            ...state.apiStatus.table,
            status,
          },
        },
      };
    }

    case SET_CHART_STATUS: {
      const status = payload;
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          chart: {
            ...state.apiStatus.chart,
            status,
          },
        },
      };
    }

    case SET_MEMO_SUBMITTING: {
      const submitting = payload;
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          memo: {
            ...state.apiStatus.memo,
            submitting,
          },
        },
      };
    }

    case SET_MEMO_ERRORS: {
      return {
        ...state,
        data: {
          ...state.data,
          memo: {
            ...state.data.memo,
            errors: payload,
          },
        },
      };
    }

    case SET_PERIOD_RANGE: {
      const { periodType, comparedPeriod, period } = payload;
      const start = moment(period.start, API_DATE_FORMAT);
      const end = moment(period.end, API_DATE_FORMAT);
      const periodRange = buildPeriodRange(periodType, { start, end });
      let comparedPeriodRange;
      if (!comparedPeriod.enabled) {
        comparedPeriodRange = [];
      } else {
        const { start: comparedStart, end: comparedEnd } = comparedPeriod;
        comparedPeriodRange = buildPeriodRange(periodType, {
          start: moment(comparedStart),
          end: moment(comparedEnd),
        });
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          periodRange,
          comparedPeriodRange,
        },
      };
    }

    case UPDATE_CHART_DISPLAY_DATA: {
      const chartDisplayData = payload;
      return {
        ...state,
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: chartDisplayData,
          },
        },
      };
    }

    case REMOVE_SECONDARY_METRIC: {
      const seriesList = state.displayData.chart.list;
      const newSeries = chartService.removeSecondaryAxis(seriesList);
      return {
        ...state,
        settings: {
          ...state.settings,
          chart: {
            ...state.settings.chart,
            axis: {
              ...state.settings.chart.axis,
              secondary: null,
            },
          },
        },
        displayData: {
          ...state.displayData,
          chart: {
            ...state.displayData.chart,
            list: newSeries,
          },
        },
      };
    }

    case CLEAN_STATES: {
      return initialState;
    }

    default:
      return state;
  }
};

export default PeriodAnalysisReducer;
