import { call, put, takeLatest, select } from 'redux-saga/effects';
import handleError from 'services/error/handleScopeError';
import { loggerActions } from 'store/logger';
import { communicationStatus, HttpStatus } from 'services/utils';
import { TABLE_ROWS_PER_PAGE, CONTENT_TYPE } from 'domain/consts';
import loggerConstants from 'store/logger/constant';
import sharedSagas from 'store/sharedSagas';
import tagServiceApi from 'services/api/TagManagementApi';
import uploadActions from 'store/upload/actions';
import adManagementServiceApi from 'services/api/AdManagementApi';
import {
  TAG_MANAGEMENT_TAB,
  CONTENT_CATEGORY_LIMIT_SORT_LIST,
} from 'domain/tag-management/consts';
import filterSelectors from 'store/filters/selectors';
import commonActions from 'store/common/actions';
import types from './types';
import actions from './actions';
import selectors from './selectors';

const { BAD_REQUEST } = HttpStatus;
const { LOADING, SUCCEEDED } = communicationStatus;
const { LOG_LEVEL_ERROR } = loggerConstants;

function* errorHandler(err, action) {
  const { callback = () => {} } = action.payload || {};
  const { error, scope } = handleError(err?.response || {}, [BAD_REQUEST]);
  const errors = error?.data?.errors || [];

  switch (action.type) {
    case types.GET_LIST:
    case types.DELETE:
      yield put(actions.setErrorList(errors, scope));
      break;
    case types.GET_DETAIL:
    case types.CREATE:
    case types.UPDATE:
      yield put(actions.setErrorDetail(errors, scope));
      break;
    case types.SUBMIT_CSV:
      yield put(actions.setErrorUploadConfirm(errors, scope));
      break;
    case types.UPDATE_RANK_PRIORITY_CONTENT_CATEGORY:
    case types.CREATE_CONTENT_CATEGORY:
      yield put(actions.setErrorContentCategories(errors, scope));
      break;

    default:
      yield put(loggerActions.logError(errors, LOG_LEVEL_ERROR));
      break;
  }

  if (
    [
      types.GET_DETAIL,
      types.CREATE,
      types.UPDATE,
      types.DELETE,
      types.UPDATE_RANK_PRIORITY_CONTENT_CATEGORY,
    ].includes(action.type)
  ) {
    callback({ action: action.type, isError: true, errors });
  }
}

function* fetch(page) {
  const tab = yield select(selectors.getTab);
  const sort = yield select(selectors.getSortRequest);

  const request = {
    sort,
    filters: yield select(filterSelectors.getForApi),
    limit: TABLE_ROWS_PER_PAGE,
    offset: (page - 1) * TABLE_ROWS_PER_PAGE,
  };

  const response = yield call(tagServiceApi.getList, tab, request);
  const {
    data: items,
    metadata: {
      count: totalItems,
      total_accepted: maxItems,
      common_tag: commonTag = '',
      is_remind_sc_tag_management: isRemindScTagManagement = true,
      is_remind_not_sc_tag_management: isRemindNotScTagManagement = true,
    },
  } = response.data;
  return {
    items,
    totalItems,
    maxItems,
    commonTag,
    isRemindScTagManagement,
    isRemindNotScTagManagement,
  };
}

function* fetchLastPageIfEmpty() {
  const { currentPage } = yield select(selectors.getPagination);
  const firstResult = yield fetch(currentPage);
  let secondResult = null;
  let changedToLastPage = false;
  if (firstResult.totalItems > 0 && firstResult.items.length <= 0) {
    // more items available in previous page
    // calculate final page
    const finalPage = Math.ceil(firstResult.totalItems / TABLE_ROWS_PER_PAGE);
    secondResult = yield fetch(finalPage);
    changedToLastPage = true;
  }

  const result = secondResult || firstResult;

  return { ...result, changedToLastPage };
}

function* handleGetList() {
  yield put(actions.setStatusList(LOADING));

  const {
    items,
    totalItems,
    maxItems,
    changedToLastPage,
    commonTag,
    isRemindScTagManagement,
    isRemindNotScTagManagement,
  } = yield fetchLastPageIfEmpty();
  yield put(
    actions.setDataList(
      items,
      totalItems,
      maxItems,
      changedToLastPage,
      commonTag,
      isRemindScTagManagement,
      isRemindNotScTagManagement
    )
  );
}

function* handleGetDetail(action) {
  const { id } = action.payload;
  const tab = yield select(selectors.getTab);
  yield put(actions.setStatusDetail(LOADING));
  const {
    data: { data },
  } = yield call(tagServiceApi.getDetail, tab, id);
  yield put(actions.setDataDetail(data));
}

function* handleCreate(action) {
  const { callback, data: dataRequest } = action.payload;
  const tab = yield select(selectors.getTab);
  yield put(actions.setStatusDetail(LOADING));
  const {
    data: { data },
  } = yield call(tagServiceApi.create, tab, dataRequest);
  yield put(actions.setDataDetail(data));
  callback({ action: action.type });
}

function* handleUpdate(action) {
  const { callback, id, data: dataRequest } = action.payload;
  const tab = yield select(selectors.getTab);
  yield put(actions.setStatusDetail(LOADING));
  const {
    data: { data },
  } = yield call(tagServiceApi.update, tab, id, dataRequest);
  yield put(actions.setDataDetail(data));
  callback({ action: action.type });
}

function* handleDelete(action) {
  const { ids, callback } = action.payload;
  const tab = yield select(selectors.getTab);
  yield call(tagServiceApi.delete, tab, ids);
  callback({ action: action.type });
}

function* handleDownloadCsv(action) {
  try {
    const { type } = action.payload;
    const [tab, sort, filters, ids] = [
      yield select(selectors.getTab),
      yield select(selectors.getSortRequest),
      yield select(filterSelectors.getForApi),
      yield select(selectors.getSelectedRows),
    ];
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    yield call(tagServiceApi.downloadCsv, tab, type, sort, filters, ids);
    yield put(commonActions.setDownloadNotify('CSVファイルを作成しました'));
  } catch (error) {
    yield put(loggerActions.logDownloadCsvError());
    yield put(commonActions.setDownloadNotify(''));
  }
}

function* handleUploadCsv(action) {
  try {
    const { screenId, file } = action.payload;
    const tab = yield select(selectors.getTab);

    yield put(uploadActions.requestStart(screenId));
    const { uploadUrl, filename } = yield call(
      adManagementServiceApi.getUploadUrl,
      CONTENT_TYPE.CSV
    );

    yield call(
      adManagementServiceApi.uploadFile,
      file,
      uploadUrl,
      CONTENT_TYPE.CSV
    );
    yield call(tagServiceApi.submitCsv, tab, filename);
    yield put(uploadActions.uploadSucceeded());
    yield put(actions.setStatusUpload(SUCCEEDED));
    yield put(actions.setStatusOpenUploadConfirm(false));
    yield put(actions.getList());
  } catch (error) {
    const { error: response } = handleError(error.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    const status = response?.status ?? 500;

    if (status === 400) {
      // validate error
      yield put(uploadActions.requestFailed(errors));
    } else {
      // api or app error
      yield put(uploadActions.requestError(handleError(error)));
    }
  }
}

function* handleGetContentCategories(action) {
  const {
    params: { offset = 0, limit = CONTENT_CATEGORY_LIMIT_SORT_LIST, getAll },
  } = action.payload;
  yield put(actions.setStatusContentCategories(LOADING));
  const { data } = yield call(
    tagServiceApi.getContentCategory,
    '',
    [],
    offset,
    limit,
    getAll
  );
  yield put(actions.setDataContentCategories(data));
}

function* handleCreateContentCategory(action) {
  yield put(actions.setStatusContentCategories(LOADING));
  const { data: dataRequest } = action.payload;
  const {
    data: { data = {} },
  } = yield call(
    tagServiceApi.create,
    TAG_MANAGEMENT_TAB.CONTENT_CATEGORY,
    dataRequest
  );

  yield put(
    actions.setDataContentCategories({
      id: data.content_category_id,
      name: data.content_category_name,
    })
  );
}

function* handleUpdateRankPriorityContentCategory(action) {
  const { originData, data, callback } = action.payload;
  yield put(actions.setStatusContentCategories(LOADING));
  yield call(tagServiceApi.updateRankPriorityContentCategory, originData, data);
  yield put(actions.setStatusContentCategories(SUCCEEDED));
  yield put(actions.setDataContentCategories({}));
  callback({ action: action.type });
}

export default function* watch() {
  yield takeLatest(
    types.GET_LIST,
    sharedSagas.safe(errorHandler, handleGetList)
  );
  yield takeLatest(
    types.GET_DETAIL,
    sharedSagas.safe(errorHandler, handleGetDetail)
  );
  yield takeLatest(types.CREATE, sharedSagas.safe(errorHandler, handleCreate));
  yield takeLatest(types.UPDATE, sharedSagas.safe(errorHandler, handleUpdate));
  yield takeLatest(
    types.DOWNLOAD_CSV,
    sharedSagas.safe(errorHandler, handleDownloadCsv)
  );
  yield takeLatest(types.DELETE, sharedSagas.safe(errorHandler, handleDelete));
  yield takeLatest(types.UPLOAD_CSV, handleUploadCsv);
  yield takeLatest(
    types.GET_CONTENT_CATEGORIES,
    sharedSagas.safe(errorHandler, handleGetContentCategories)
  );
  yield takeLatest(
    types.CREATE_CONTENT_CATEGORY,
    sharedSagas.safe(errorHandler, handleCreateContentCategory)
  );
  yield takeLatest(
    types.UPDATE_RANK_PRIORITY_CONTENT_CATEGORY,
    sharedSagas.safe(errorHandler, handleUpdateRankPriorityContentCategory)
  );
}
