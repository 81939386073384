import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { func, objectOf, shape } from 'prop-types';
import { isEmpty, isNumber, chain, invert, isEqual } from 'lodash';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import SelectPopup from 'views/organism/SelectPopup';
import dataExportTypes from 'store/data-export/types';
import { loggerActions } from 'store/logger';
import {
  REPORT_FIELDS,
  FILTER_CHANNEL_MAPPER,
} from 'domain/data-export/consts';
import {
  FILTER_KEY_CHANNEL,
  FILTER_KEY_AMOUNT,
  FILTER_KEY_MEMBER_NAME,
  FILTER_KEY_OTHER1,
  FILTER_KEY_OTHER2,
  FILTER_KEY_OTHER3,
  FILTER_KEY_OTHER4,
  FILTER_KEY_OTHER5,
} from 'services/consts';
import {
  CHANNEL_TYPE_ID,
  CHANNEL_TYPE_LABEL,
  MASTER_DATA_FILTER_LIMIT,
} from 'domain/consts';
import { CHANNEL_VIEW_PERMISSIONS } from 'domain/permissions/contractGroups';
import { resolve } from 'domain/permissions/permissionTypes';
import SettingReportContext from 'views/pages/data-export/SettingReportContext';
import { filterAdrepoRules } from 'services/validations/dataExportRules';
import { numberInRange } from 'services/validations/commonValidations';

const { CREATE, UPDATE } = dataExportTypes;
const { REPORT_NAME, QUICK_SEARCH } = REPORT_FIELDS;
const { CLICK, VIEW } = CHANNEL_TYPE_ID;
const FILTER_KEY_CONVERSION_IDS = 'conversion_ids';
const FILTER_KEY_MOUNT_FROM = 'amount_from';
const FILTER_KEY_MOUNT_TO = 'amount_to';
const FILTER_CHANNELS = [
  {
    key: '1',
    name: FILTER_KEY_CHANNEL,
    label: '広告クリック',
    value: FILTER_CHANNEL_MAPPER[CLICK],
  },
  {
    key: '2',
    name: FILTER_KEY_CHANNEL,
    label: '広告クリック+広告ビュー',
    value: `${FILTER_CHANNEL_MAPPER[CLICK]},${FILTER_CHANNEL_MAPPER[VIEW]}`,
    permissionDef: CHANNEL_VIEW_PERMISSIONS,
  },
];
const ATTRIBUTE_FIELDS = [
  FILTER_KEY_MEMBER_NAME,
  FILTER_KEY_AMOUNT,
  FILTER_KEY_OTHER1,
  FILTER_KEY_OTHER2,
  FILTER_KEY_OTHER3,
  FILTER_KEY_OTHER4,
  FILTER_KEY_OTHER5,
];

// Swap key value
const filterChannelMapperInvert = invert(FILTER_CHANNEL_MAPPER);

function AdrepoReportSetting(props) {
  const { defaultReport, onCancel, onSubmit, onSearchCv } = props;
  const dispatch = useDispatch();

  const {
    dispatchSetting,
    isActionUpdate,
    masterdata: { display, conversion, permissions },
    settingReport: { adrepoReport },
  } = useContext(SettingReportContext);

  const {
    [REPORT_NAME]: reportName,
    [QUICK_SEARCH]: quickSearch = {},
  } = adrepoReport;

  // Allow filter channel has permissions
  const filterChannels = FILTER_CHANNELS.filter(
    (channel) =>
      !('permissionDef' in channel) ||
      resolve(channel.permissionDef, permissions)
  );

  const {
    [FILTER_KEY_CHANNEL]: channels = filterChannels[0]?.value?.split(',') || [],
    [FILTER_KEY_CONVERSION_IDS]: conversionIds = [],
    [FILTER_KEY_MOUNT_FROM]: amountFrom = '',
    [FILTER_KEY_MOUNT_TO]: amountTo = '',
  } = quickSearch;

  // Check filter setting
  const isHasFilterConversion = conversionIds.length > 0;
  const isHasFilterAttribute = ATTRIBUTE_FIELDS.some(
    (field) =>
      !!quickSearch[field] ||
      (field === FILTER_KEY_AMOUNT && (amountFrom || amountTo))
  );

  const [isShowFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState(quickSearch);
  const [filterError, setErrorFilter] = useState({});

  const handleSelectChannel = (value, name) => {
    setFilter((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectConversion = (value) => {
    setFilter((prevData) => ({
      ...prevData,
      [FILTER_KEY_CONVERSION_IDS]: value,
    }));
    setErrorFilter((prevData) => ({
      ...prevData,
      [FILTER_KEY_CONVERSION_IDS]: null,
    }));
  };

  const handleChangeAttribute = (name, value) => {
    setFilter((prevData) => ({ ...prevData, [name]: value }));
    setErrorFilter((prevData) => ({ ...prevData, [name]: null }));
  };

  const handleValidateFilter = (data) => {
    const rules = { ...filterAdrepoRules };
    if (data[FILTER_KEY_MOUNT_FROM] && data[FILTER_KEY_MOUNT_TO]) {
      rules[FILTER_KEY_MOUNT_FROM] = [
        ...rules[FILTER_KEY_MOUNT_FROM],
        numberInRange(
          0,
          Number(data[FILTER_KEY_MOUNT_TO]),
          `売上金額(以上)は売上金額(以下)と同じか少ない数を入れてください`
        ),
      ];
    }

    return chain(data)
      .mapValues((value, key) => ({
        label: display[key] || '',
        value: value || null,
      }))
      .mapValues((value, field) => {
        if (isEmpty(rules[field])) return [];

        const rulesByField = [...rules[field]];
        const errors = rulesByField
          .map((rule) => rule(value))
          .filter((rule) => !isEmpty(rule));
        return errors;
      })
      .pickBy((errors) => !isEmpty(errors))
      .mapValues((errors) => errors[0])
      .value();
  };

  const handleApplyFilter = () => {
    // Only get filter has data
    const newFilter = Object.keys(filter).reduce((acc, key) => {
      let value = filter[key];
      if (isEmpty(value) && !isNumber(value)) return acc;
      if (key === FILTER_KEY_CHANNEL) {
        value = value.split(',');
      } else if (key === FILTER_KEY_CONVERSION_IDS) {
        value = Object.keys(value);
      } else if ([FILTER_KEY_MOUNT_FROM, FILTER_KEY_MOUNT_TO].includes(key)) {
        value = Number(value);
      }

      return { ...acc, [key]: value };
    }, {});

    const error = handleValidateFilter(newFilter);

    if (isEmpty(error)) {
      setShowFilter(false);
      dispatchSetting({
        type: 'setDataAdrepoReport',
        payload: { [QUICK_SEARCH]: newFilter },
      });
    } else {
      setErrorFilter(error);
    }
  };

  const handleSetFilter = () => {
    setFilter({
      ...quickSearch,
      [FILTER_KEY_CHANNEL]: channels.join(','),
      [FILTER_KEY_CONVERSION_IDS]: conversionIds.reduce(
        (acc, id) => ({
          ...acc,
          [id]: {
            value: id,
            label: conversion.defaultOptions[id]?.label || '',
          },
        }),
        {}
      ),
    });
  };

  const handleSearchCv = useCallback(
    (value) => {
      const selected = value ? [] : conversionIds;
      onSearchCv(value, selected);
    },
    [conversionIds, onSearchCv]
  );

  const handleSubmit = (action) => {
    if (isEqual(defaultReport, adrepoReport)) {
      onSubmit(action, adrepoReport);
    } else {
      dispatch(
        loggerActions.logConfirmModal({
          title: '',
          content:
            '今回登録された条件で本日より過去1年分のデータをアドレポに連携します。',
          cancelBtn: true,
          confirmText: '連携開始',
          callback: () => onSubmit(action, adrepoReport),
        })
      );
    }
  };

  useEffect(() => {
    handleSetFilter();
    // Set default filter
    if (isEmpty(quickSearch)) {
      dispatchSetting({
        type: 'setDataAdrepoReport',
        payload: { [QUICK_SEARCH]: { [FILTER_KEY_CHANNEL]: channels } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickSearch]);

  // Only get cv for the first time
  useEffect(() => {
    handleSearchCv('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="data-export-setting__item data-export-setting__item--report-name">
        <div className="data-export-setting__title">レポート名</div>
        <div className="data-export-setting__content">
          <FormInput name={REPORT_NAME} value={reportName} disabled />
        </div>
      </div>
      <div className="data-export-setting__item">
        <div className="data-export-setting__title">集計条件</div>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => setShowFilter(true)}
        >
          設定
        </Button>
        <div className="data-export-setting__confirm">
          <div className="data-export-setting__confirm-title">チャネル種別</div>
          <div className="data-export-setting__confirm-content">
            {channels
              .map(
                (channel) =>
                  CHANNEL_TYPE_LABEL[filterChannelMapperInvert[channel]]
              )
              .join('+')}
          </div>
          {isHasFilterConversion > 0 && (
            <>
              <div className="data-export-setting__confirm-title">
                対象コンバージョン
              </div>
              <div className="data-export-setting__confirm-content">
                {Object.values(filter[FILTER_KEY_CONVERSION_IDS])
                  .map((item) => item.label)
                  .join(', ')}
              </div>
            </>
          )}
          {isHasFilterAttribute && (
            <>
              <div className="data-export-setting__confirm-title">
                コンバージョン属性
              </div>
              <div className="data-export-setting__confirm-content">
                {ATTRIBUTE_FIELDS.map((key) => {
                  let content = quickSearch[key];
                  if (key === FILTER_KEY_AMOUNT) {
                    if (amountFrom || amountFrom === 0) {
                      content = <>{amountFrom}円以上 &nbsp;&nbsp;</>;
                    }
                    if (amountTo || amountTo === 0) {
                      content = (
                        <>
                          {content}
                          {amountTo}円以下
                        </>
                      );
                    }
                  }

                  return (
                    content && (
                      <p>
                        {display[key]}: {content}
                      </p>
                    )
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="data-export-setting__item mb-0">
        <Button
          variant="link"
          size="sm"
          className="btn-cancel mr-10"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => handleSubmit(isActionUpdate ? UPDATE : CREATE)}
        >
          {isActionUpdate ? '保存' : '登録'}
        </Button>
      </div>
      {isShowFilter && (
        <Modal
          centered
          show={isShowFilter}
          size="w800"
          dialogClassName="data-export-setting__modal-filter"
        >
          <Modal.Header>
            <Modal.Title>集計条件の設定</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="data-export-setting__table">
              <div className="data-export-setting__row">
                <div className="data-export-setting__cell">集計タイミング</div>
                <div className="data-export-setting__cell">
                  {filterChannels.map((item) => {
                    return (
                      <EbisRadio
                        key={item.value}
                        name={item.name}
                        label={item.label}
                        value={item.value}
                        checked={item.value === filter[item.name]}
                        onChange={handleSelectChannel}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="data-export-setting__row">
                <div className="data-export-setting__cell">
                  対象コンバージョン
                </div>
                <div className="data-export-setting__cell">
                  <SelectPopup
                    multiple
                    loading={conversion.isLoading}
                    title="対象コンバージョン"
                    value={filter[FILTER_KEY_CONVERSION_IDS]}
                    error={{ message: filterError[FILTER_KEY_CONVERSION_IDS] }}
                    options={conversion.displayOptions}
                    limitDisplay={MASTER_DATA_FILTER_LIMIT}
                    onChange={handleSelectConversion}
                    onSearch={handleSearchCv}
                  />
                </div>
              </div>
              <div className="data-export-setting__row">
                <div className="data-export-setting__cell bg-white">
                  コンバージョン属性
                </div>
                <div className="data-export-setting__cell p-0">
                  <div className="data-export-setting__table data-export-setting__table--attribute">
                    <div className="data-export-setting__row">
                      <div className="data-export-setting__cell">
                        ユーザー名
                      </div>
                      <div className="data-export-setting__cell data-export-setting__cell--member-name">
                        <FormInput
                          name={FILTER_KEY_MEMBER_NAME}
                          value={filter[FILTER_KEY_MEMBER_NAME]}
                          error={filterError[FILTER_KEY_MEMBER_NAME]}
                          placeholder=""
                          onChange={handleChangeAttribute}
                        />
                      </div>
                    </div>
                    <div className="data-export-setting__row">
                      <div className="data-export-setting__cell">売上金額</div>
                      <div className="data-export-setting__cell data-export-setting__cell--amount">
                        <FormInput
                          type="number"
                          name={FILTER_KEY_MOUNT_FROM}
                          value={filter[FILTER_KEY_MOUNT_FROM]}
                          error={filterError[FILTER_KEY_MOUNT_FROM]}
                          placeholder=""
                          onChange={handleChangeAttribute}
                        />
                        <span className="mr-10">円以上</span>
                        <FormInput
                          type="number"
                          name={FILTER_KEY_MOUNT_TO}
                          value={filter[FILTER_KEY_MOUNT_TO]}
                          error={filterError[FILTER_KEY_MOUNT_TO]}
                          placeholder=""
                          onChange={handleChangeAttribute}
                        />
                        <span>円以下</span>
                      </div>
                    </div>
                    {[
                      FILTER_KEY_OTHER1,
                      FILTER_KEY_OTHER2,
                      FILTER_KEY_OTHER3,
                      FILTER_KEY_OTHER4,
                      FILTER_KEY_OTHER5,
                    ].map((key) => (
                      <div className="data-export-setting__row" key={key}>
                        <div className="data-export-setting__cell">
                          {display[key]}
                        </div>
                        <div className="data-export-setting__cell">
                          <FormInput
                            name={key}
                            value={filter[key]}
                            error={filterError[key]}
                            placeholder="通常は部分一致、スペース区切りで複数選択、””で囲めば完全一致"
                            onChange={handleChangeAttribute}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="link"
              onClick={() => {
                setShowFilter(false);
                handleSetFilter();
                setErrorFilter({});
              }}
            >
              キャンセル
            </Button>
            <Button size="sm" variant="secondary" onClick={handleApplyFilter}>
              保存
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

AdrepoReportSetting.propTypes = {
  defaultReport: objectOf(shape({})).isRequired,
  onCancel: func,
  onSubmit: func,
  onSearchCv: func,
};

AdrepoReportSetting.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  onSearchCv: () => {},
};

export default AdrepoReportSetting;
