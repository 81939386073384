import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'react-bootstrap';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';

const EbisDropdown = ({
  titleName,
  optionObject,
  selectCallback,
  defaultVal,
  isDisabled,
  error,
  onToggle,
  children,
  isCustomDropdownToggle,
}) => {
  const DEFAULT_KEY = '';
  const DEFAULT_TEXT = 'empty';
  const ref = useRef();
  const [selectedValue, changeSelectedValue] = useState(DEFAULT_KEY);
  const handleSelected = (value) => {
    if (value === selectedValue) return;
    changeSelectedValue(value);
    selectCallback(value);
  };

  const getCurrentText = (key) => {
    if (key === DEFAULT_KEY) return DEFAULT_TEXT;

    // eslint-disable-next-line eqeqeq
    const foundValue = optionObject.find((item) => item.key == key);

    return foundValue ? foundValue.name : DEFAULT_KEY;
  };

  useEffect(() => {
    if (defaultVal === undefined) return;
    const preselectedVal = getCurrentText(defaultVal);
    if (preselectedVal !== DEFAULT_KEY) changeSelectedValue(defaultVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVal]);

  return (
    <Form.Group>
      {titleName && <Form.Label>{titleName}</Form.Label>}
      <Dropdown
        onSelect={(value) => handleSelected(value)}
        ref={ref}
        onToggle={onToggle}
      >
        <ErrorTooltipWrapper isError={!!error} errorMess={error} ref={ref}>
          <Dropdown.Toggle
            size="sm"
            variant="select"
            disabled={isDisabled}
            className={error ? 'dropdown-toggle--is-error' : ''}
          >
            {isCustomDropdownToggle ? (
              <div>{getCurrentText(selectedValue)}</div>
            ) : (
              getCurrentText(selectedValue)
            )}
          </Dropdown.Toggle>
        </ErrorTooltipWrapper>
        <Dropdown.Menu>
          {optionObject.map((item) => (
            <Dropdown.Item key={item.key} eventKey={item.key}>
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {children}
    </Form.Group>
  );
};

EbisDropdown.defaultProps = {
  optionObject: {
    name: '',
    key: '',
  },
  defaultVal: '',
  onToggle: () => {},
  isDisabled: false,
  error: '',
  children: <></>,
  isCustomDropdownToggle: false,
};

EbisDropdown.propTypes = {
  titleName: PropTypes.string.isRequired,
  optionObject: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  selectCallback: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  defaultVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.any]),
  isCustomDropdownToggle: PropTypes.bool,
};
export default EbisDropdown;
