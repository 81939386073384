import React from 'react';
import classNames from 'classnames';
import { PRIORITY_AXIS_EBIS, PRIORITY_AXIS_MEDIA_SYNC } from 'domain/consts';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { func, bool } from 'prop-types';

import './PriorityAxisCheckBox.scss';

const PriorityAxisCheckBox = ({
  mediaSync = false,
  enabledPriorityAxis = false,
  handlePriorityAxisSelect = () => {},
}) => {
  const iconClass = classNames({
    fas: true,
    'fa-question-circle': true,
    'priority-axis-checkbox__dragonball': true,
  });
  return enabledPriorityAxis ? (
    <div>
      <div className="priority-axis-checkbox">
        <Form.Check
          className="priority-axis-checkbox__item priority-axis-checkbox__item--media-sync"
          type="radio"
        >
          <Form.Check.Input
            type="radio"
            checked={mediaSync}
            onChange={() => {
              handlePriorityAxisSelect(PRIORITY_AXIS_MEDIA_SYNC);
            }}
          />
          <Form.Check.Label
            className="priority-axis-checkbox__label"
            onClick={() => {
              handlePriorityAxisSelect(PRIORITY_AXIS_MEDIA_SYNC);
            }}
          >
            媒体項目で集計
          </Form.Check.Label>
        </Form.Check>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className="dragonball-tooltip variant-base">
              媒体シンク機能によって媒体から自動取得した項目を集計軸にします。
              <br/>
              ※自動連携対象外の広告情報は、広告管理画面で登録いただいた情報を元に集計します。
            </Tooltip>
          }
        >
          <div className={iconClass} />
        </OverlayTrigger>
        <Form.Check
          className="priority-axis-checkbox__item priority-axis-checkbox__item--ebis"
          type="radio"
        >
          <Form.Check.Input
            type="radio"
            checked={!mediaSync}
            onChange={() => {
              handlePriorityAxisSelect(PRIORITY_AXIS_EBIS);
            }}
          />
          <Form.Check.Label
            className="priority-axis-checkbox__label"
            onClick={() => {
              handlePriorityAxisSelect(PRIORITY_AXIS_EBIS);
            }}
          >
            アドエビス項目で集計
          </Form.Check.Label>
        </Form.Check>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className="dragonball-tooltip variant-base">
              アドエビスで登録された広告の項目を集計軸にします。
            </Tooltip>
          }
        >
          <div className={iconClass} />
        </OverlayTrigger>
      </div>
    </div>
  ) : null;
};

PriorityAxisCheckBox.propType = {
  mediaSync: bool.isRequired,
  enabledPriorityAxis: bool.isRequired,
  handlePriorityAxisSelect: func.isRequired,
};

export default PriorityAxisCheckBox;
