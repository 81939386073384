import { SettingsApi as Api } from 'services/api/Api';
import pages from 'services/routes/pages';
import { EXTERNAL_SERVICE_SETTINGS } from 'services/routes/constants';
import apiUtils from 'services/apiUtils';

const ExternalServicesApiFactory = () => {
  const url = pages[EXTERNAL_SERVICE_SETTINGS].endpoint;
  // const url = 'http://localhost:3000/data/external-services-list.json';

  const checkRequestAsync = (urlRequest, key) => {
    const isAsync = false;
    const endpoint = isAsync
      ? `${urlRequest}/${key}/async`
      : `${urlRequest}/${key}`;

    return [isAsync, endpoint];
  };

  return {
    getList: async () => Api.get(url),
    getDetail: async (id, convert) => {
      let response = await Api.get(
        apiUtils.buildUrlWithQueryStrings(`${url}/${id}`, {
          convert,
        })
      );

      response = await Api.get(response.data.data.location);
      return response;
    },
    active: async (data) => {
      const { serviceKey, setting } = data;
      const [isAsync, endpoint] = checkRequestAsync(url, serviceKey);
      if (!isAsync) {
        return Api.post(endpoint, { ...setting, active: 'true' });
      }

      const response = await Api.requestAsync(endpoint, data, 'POST', {
        pollUrlFormat: `${endpoint}/{task}`,
        pollTokenKey: 'task',
      });

      return response;
    },
    update: async (id, data) => {
      const [isAsync, endpoint] = checkRequestAsync(`${url}/${id}`, data);
      if (!isAsync) {
        return Api.put(endpoint, data);
      }

      const response = await Api.requestAsync(endpoint, data, 'PUT', {
        pollUrlFormat: `${endpoint}/{task}`,
        pollTokenKey: 'task',
      });

      return response;
    },
    deactive: async (key) => {
      return Api.post(`${url}/${key}`, { active: false });
    },
  };
};

const ExternalServicesApi = ExternalServicesApiFactory();
export default ExternalServicesApi;
