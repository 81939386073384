import React from 'react';
import ViewNavBar from 'views/molecules/viewNavBar/component/ViewNavBar';
import { connect } from 'react-redux';
import settingsForViewSelector from 'store/settings-for-view/selectors';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/jsx-props-no-spreading
const ViewNavBarContainer = ({ pageId, handleChangePage, ...props }) => {
  return (
    <ViewNavBar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      pageId={pageId}
      handleChangePage={handleChangePage}
    />
  );
};

const mapStateToProps = (state) => ({
  pageId: settingsForViewSelector.getPage(state),
});

ViewNavBarContainer.propTypes = {
  pageId: PropTypes.string.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ViewNavBarContainer);
