import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FormGroup.scss';

const FormGroup = (props) => {
  const {
    className,
    heading,
    resetLink,
    linkActive,
    children,
    onClick,
    cvForm,
    customView,
  } = props;
  const groupClass = classNames('form-group', className);
  const groupHeadingClass = classNames({
    'form-group__heading': true,
    'form-group__heading--cv-form': !customView && cvForm,
    'form-group__heading--custom-view': customView,
  });
  const linkClass = classNames({
    'form-group__link': true,
    'form-group__link--active': linkActive,
    'form-group__link--custom-view': customView,
  });

  const handleClick = (e) => {
    if (linkActive) {
      onClick(e);
    }
  };
  return (
    <div className={groupClass}>
      <div className={groupHeadingClass}>
        {!customView && !cvForm && (
          <h3 className="form-group__heading-text">{heading}</h3>
        )}
        {!customView && cvForm && (
          <h3 className="form-group__heading-text--cv-form">{heading}</h3>
        )}
        {!customView && resetLink && (
          <div onClick={handleClick} className={linkClass} aria-hidden="true">
            デフォルトに戻す
          </div>
        )}
        {customView && !resetLink && (
          <h3 className="form-group__heading-text--custom-view">{heading}</h3>
        )}
        {customView && resetLink && (
          <h3 className="form-group__heading-text--custom-view">
            {heading}
            <div onClick={handleClick} className={linkClass} aria-hidden="true">
              デフォルトに戻す
            </div>
          </h3>
        )}
      </div>
      <div className="form-group__elements">{children}</div>
    </div>
  );
};

FormGroup.propTypes = {
  heading: PropTypes.string.isRequired,
  linkActive: PropTypes.bool,
  resetLink: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  cvForm:  PropTypes.bool,
  customView: PropTypes.bool,
};

FormGroup.defaultProps = {
  linkActive: false,
  resetLink: true,
  onClick: () => {},
  className: null,
  cvForm: false,
  customView: false,
};

export default FormGroup;
