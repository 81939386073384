import helper from 'store/helper';
import types from 'store/log/period-analyze/types';
import loggerConstants from 'store/logger/constant';

const periodAnalyzeActions = {
  setErrors: (type, errors, scope = null) =>
    helper.createAction(types.SET_ERRORS, { type, errors }, scope),

  getDataChart: () => helper.createAction(types.GET_DATA_CHART),
  setStatusChart: (status) =>
    helper.createAction(types.SET_STATUS_CHART, { status }),
  setDataChart: (data) => helper.createAction(types.SET_DATA_CHART, { data }),
  toggleChart: (enabled) =>
    helper.createAction(types.TOGGLE_CHART, { enabled }),
  removeSecondaryMetric: () =>
    helper.createAction(types.REMOVE_SECONDARY_METRIC),

  getSetting: () => helper.createAction(types.GET_SETTING),
  setSetting: (settings, shouldApplyMetrics) =>
    helper.createAction(types.SET_SETTING, {
      settings,
      shouldApplyMetrics,
    }),
  saveBookmark: (enabled) =>
    helper.createAction(types.SAVE_BOOKMARK, { enabled }),

  changeAxis: (field, value) =>
    helper.createAction(types.CHANGE_AXIS, { field, value }),
  changeCategory: (category, selected) =>
    helper.createAction(types.CHANGE_CATEGORY, { category, selected }),
  // actions for normal table
  getDataTable: () => helper.createAction(types.GET_DATA_TABLE),
  setDataTable: (data) => helper.createAction(types.SET_DATA_TABLE, { data }),
  setStatusTable: (status) =>
    helper.createAction(types.SET_STATUS_TABLE, { status }),
  changeSortTable: (field, direction) =>
    helper.createAction(types.CHANGE_SORT_TABLE, { field, direction }),

  updateChartDisplayMemo: (displayData) =>
    helper.createAction(types.UPDATE_CHART_DISPLAY_MEMO, displayData),

  // actions for compared table
  getDataComparedTable: () =>
    helper.createAction(types.GET_DATA_COMPARED_TABLE),
  setDataComparedTable: (data) =>
    helper.createAction(types.SET_DATA_COMPARED_TABLE, { data }),
  setStatusComparedTable: (status) =>
    helper.createAction(types.SET_STATUS_COMPARED_TABLE, { status }),
  changeSortComparedTable: (field, direction) =>
    helper.createAction(types.CHANGE_SORT_COMPARED_TABLE, { field, direction }),

  // actions for memo functions
  toggleMemo: (enabled) => helper.createAction(types.TOGGLE_MEMO, { enabled }),
  showMemoErrorModal: () => helper.createAction(types.SHOW_MEMO_ERROR_MODAL),
  hideMemoErrorModal: () => helper.createAction(types.HIDE_MEMO_ERROR_MODAL),
  loadMemo: () => helper.createAction(types.LOAD_MEMO),
  loadMemoSuccess: (memoList) =>
    helper.createAction(types.LOAD_MEMO_SUCCESS, memoList),
  deleteMemo: (id) => helper.createAction(types.DELETE_MEMO, id),
  deleteMemoSuccess: () => helper.createAction(types.DELETE_MEMO_SUCCESS),
  updateMemo: ({ id, category, memoStart, memoEnd, content }) =>
    helper.createAction(types.UPDATE_MEMO, {
      id,
      category,
      memoStart,
      memoEnd,
      content,
    }),

  createMemo: ({ category, memoStart, memoEnd, content }) =>
    helper.createAction(types.CREATE_MEMO, {
      category,
      memoStart,
      memoEnd,
      content,
    }),

  createMemoSuccess: () => helper.createAction(types.CREATE_MEMO_SUCCESS),

  createMemoError: (error) =>
    helper.createAction(types.CREATE_MEMO_ERROR, {
      error,
    }),

  setMemoErrors: (errors) =>
    helper.createAction(
      types.SET_MEMO_ERRORS,
      { errors },
      loggerConstants.SCOPE_DONT_SHOW
    ),

  showDeleteModal: () => helper.createAction(types.SHOW_DELETE_MODAL),
  hideDeleteModal: () => helper.createAction(types.HIDE_DELETE_MODAL),
  setMemoSubmitting: (submitting) =>
    helper.createAction(types.SET_MEMO_SUBMITTING, { submitting }),

  updateMemoSuccess: () => helper.createAction(types.UPDATE_MEMO_SUCCESS),
  updateMemoList: ({ memoList }) =>
    helper.createAction(types.UPDATE_MEMO_LIST, { memoList }),
  showMemoModal: (id, reset) =>
    helper.createAction(types.SHOW_MEMO_MODAL, { id, reset }),
  hideMemoModal: () => helper.createAction(types.HIDE_MEMO_MODAL),

  changeActivePeriod: (currentPeriodActive) =>
    helper.createAction(types.CHANGE_ACTIVE_PERIOD, { currentPeriodActive }),

  changePeriodType: (type) =>
    helper.createAction(types.CHANGE_PERIOD_TYPE, { type }),
};

export default periodAnalyzeActions;
