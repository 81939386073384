import { createSelector } from 'reselect';

const getSort = (state) => state.cache.impressionAdCost.data.table.sort;
const getPagination = (state) =>
  state.cache.impressionAdCost.data.table.pagination;

const getImpressionAdCostState = createSelector(
  [(state) => state.cache.impressionAdCost],
  (state) => {
    const {
      data: {
        table: { list: tableData, sort, pagination },
      },
      apiStatus: { table: tableStatus },
    } = state;
    return [tableData, tableStatus, sort, pagination];
  }
);

export default {
  getSort,
  getPagination,
  getImpressionAdCostState,
};
