import * as domainUtils from 'domain/utils';
import { buildPeriodRange, formatDateRange } from 'domain/utils';
import {
  containsByAllKeys,
  equalsAllKeys,
  getDiffByMonth,
} from 'services/utils';
import {
  API_DATE_FORMAT,
  API_MONTH_FORMAT,
  CALENDAR_DAY_FORMAT,
  CHART_COLORS,
  COMPARE_PERIOD_X_LABEL_DATE_FORMAT,
  COMPARE_PERIOD_X_LABEL_MONTH_FORMAT,
  JP_DAY_OF_WEEK,
} from 'domain/consts';
import {
  APPEND_PERIOD_ON_COMPARED_TOOLTIP,
  DIMENSIONS_TO_KEY_VALUES,
  MEMO_CATEGORIES_PATTERNS,
  MEMO_CATEGORY,
  MEMO_MAX_TOOLTIP_CONTENT_LENGTH,
  PERIOD_TYPE,
  TOOLTIP_MODE,
} from 'domain/period-analyze/consts';
import moment from 'moment';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import uniqWith from 'lodash/uniqWith';
import get from 'lodash/get';
import { getMemoCategoryDisplayKey } from 'domain/period-analyze/display';
import displayService from 'services/display';

const buildDateDisplayForTooltip = (value, periodType) => {
  if (isNil(value)) {
    // When compared period and the date is not available (date range is shorter)
    return null;
  }
  switch (periodType) {
    case PERIOD_TYPE.DAY:
      return `${moment(value, API_DATE_FORMAT).format(CALENDAR_DAY_FORMAT)}`;
    case PERIOD_TYPE.HOUR:
      return `${value}時`;
    case PERIOD_TYPE.MONTH: {
      const momentValue = moment(value, API_MONTH_FORMAT);
      return `${momentValue.year()}年${momentValue.month() + 1}月`;
    }
    case PERIOD_TYPE.DOW: {
      const dowIndex = parseInt(value, 10);
      return `${JP_DAY_OF_WEEK[dowIndex]}曜`;
    }
    default:
      return value;
  }
};

const addPeriodDisplayForTooltip = (value, { start, end }) => {
  const dateRangeStr = formatDateRange(start, end);
  return `${dateRangeStr}の${value}`;
};

const buildTooltipForMonoSeries = ({ xValue, yValue, yLabel, color }) => {
  return `
    <div class="highcharts-tooltip-formatter">
      <div class="highcharts-tooltip-formatter__title">${xValue}</div>
      <div class="highcharts-tooltip-formatter__item">
        <span class="highcharts-tooltip-formatter__point" style="background-color:${color};"></span>${yLabel} : <b>${yValue}</b>
      </div>
    </div>`;
};

const buildTooltipForDualSeriers = ({
  metric1,
  metric2,
  value1,
  value2,
  color1,
  date,
}) => {
  return `
    <div class="highcharts-tooltip-formatter">
      <div class="highcharts-tooltip-formatter__title">${date}</div>
      <div class="highcharts-tooltip-formatter__item">
        <span class="highcharts-tooltip-formatter__point" style="background-color:${color1};"></span>${metric1} : <b>${value1}</b>
      </div>
      <div class="highcharts-tooltip-formatter__item">
        <span class="highcharts-tooltip-formatter__point" style="background-color:${color1}; opacity: 0.6;"></span>${metric2} : <b>${value2}</b>
      </div>
    </div>`;
};

// const buildTooltipForSingleSeries = () => {};
const buildTooltipForComparedSeries = ({
  metric,
  value1,
  value2,
  color,
  date1,
  date2,
}) => {
  const currentPeriodTooltip = date1
    ? `
  <div class="highcharts-tooltip-formatter__title">${date1}</div>
      <div class="highcharts-tooltip-formatter__item">
        <span class="highcharts-tooltip-formatter__line" style="border-color:${color}; border-top-style: solid;"></span>${metric} : <b>${value1}</b>
      </div>
  `
    : '';
  const comparedPeriodTooltip = date2
    ? `
  <div class="highcharts-tooltip-formatter__title">${date2}</div>
      <div class="highcharts-tooltip-formatter__item">
        <span class="highcharts-tooltip-formatter__line" style="border-color:${color}; border-top-style: dotted;"></span>${metric} : <b>${value2}</b>
      </div>
  `
    : '';
  return `
    <div class="highcharts-tooltip-formatter">
      ${currentPeriodTooltip}
      ${comparedPeriodTooltip}
    </div>
`;
};

const buildTooltipForMemo = ({ start, end, category, content }) => {
  // Decide if we should show all chars, or show ellipsis
  let displayContent = content.substring(0, MEMO_MAX_TOOLTIP_CONTENT_LENGTH);
  if (content.length > MEMO_MAX_TOOLTIP_CONTENT_LENGTH) {
    displayContent += '...';
  }

  return `
   <div class="highcharts-tooltip-formatter memo">
     <div class="highcharts-tooltip-formatter__title">${start} ～ ${end}</div>
     <div class="highcharts-tooltip-formatter__item">${category}</div>
     <div class="highcharts-tooltip-formatter__content">${displayContent}</div>
   </div>`;
};
/*

const buildSeriesWithKey = ({
  dataList,
  selectedCategories,
  dimensions,
  metric,
}) =>
  dataList
    // Filter list by selected categories
    .filter((data) => containsByAllKeys(selectedCategories, data.dimensionKey))
    // Create each dimension key
    .map((data) => {
      const dataPeriod = data[FIELD.PERIOD];
      return {
        period: dataPeriod,
        key: createDimensionKeyObjectForChart(
          data,
          dimensions
        ),
        value: round(data[metric], 2),
      };
    });
*/

const buildSeries = ({
  dataList,
  selectedCategories,
  metric,
  period,
  actualPeriod,
  periodType,
  seriesType,
  yAxisId,
  dashStyle,
  compared,
  visibleList,
}) => {
  let periodRange = buildPeriodRange(periodType, period);
  const timeUnit = periodType === PERIOD_TYPE.MONTH ? 'month' : 'day';
  if (actualPeriod) {
    const actualPeriodLength =
      actualPeriod.end
        .startOf('day')
        .diff(actualPeriod.start.startOf(timeUnit), timeUnit) + 1;
    const chartPeriodLength =
      period.end
        .startOf(timeUnit)
        .diff(period.start.startOf(timeUnit), timeUnit) + 1;
    if (actualPeriodLength < chartPeriodLength) {
      periodRange = periodRange.splice(0, actualPeriodLength);
    }
  }

  const colorPalette = yAxisId === 0 ? 'primary' : 'secondary';

  return selectedCategories
    .map((category, index) => {
      const matchingCategory = dataList.find((elem) =>
        equalsAllKeys(category, elem.dimensionKey)
      );
      if (!matchingCategory && !compared) {
        return null;
      }
      return {
        data: periodRange.map((curMoment) =>
          round(get(matchingCategory, `period.${curMoment}.${metric}`, 0), 2)
        ),
        type: seriesType,
        yAxis: yAxisId,
        color: CHART_COLORS[index + 1][colorPalette],
        dashStyle,
        xAxis: 0,
        dimensionKey: category,
        compared,
        colorPalette,
        colorIndex: index + 1,
        maxPointWidth: 39,
        visible: containsByAllKeys(visibleList, category),
        // name: getRandomId('series'),
        // name: `${}`,
        memo: false,
        marker: {
          enabled: periodRange.length <= 30,
        },
        // turboThreshold: 30,
      };
    })
    .filter((series) => series != null);
};

const buildYSeriesForMonoAxis = ({
  dataList,
  selectedCategories,
  dimensions,
  metric,
  period,
  periodType,
  visibleList,
}) => {
  return buildSeries({
    dataList,
    selectedCategories,
    dimensions,
    metric,
    period,
    periodType,
    yAxisId: 0,
    seriesType: 'line',
    visibleList,
  });
};

const buildYSeriesForDualAxis = ({
  dataList,
  selectedCategories,
  dimensions,
  axis: { primary, secondary },
  period,
  periodType,
  visibleList,
}) => {
  const lineSeriesList = buildSeries({
    dataList,
    selectedCategories,
    dimensions,
    metric: primary,
    period,
    periodType,
    seriesType: 'line',
    yAxisId: 0,
    visibleList,
  });
  const columnsSeriesList = buildSeries({
    dataList,
    selectedCategories,
    dimensions,
    metric: secondary,
    period,
    periodType,
    seriesType: 'column',
    yAxisId: 1,
    visibleList,
  });
  return columnsSeriesList.concat(lineSeriesList);
};

const buildExtendedPeriod = (period, comparedPeriod, timeUnit) => {
  const { start, end } = period;
  const { start: comparedStart, end: comparedEnd } = comparedPeriod;
  let periodDiff;
  let comparedPeriodDiff;
  if (timeUnit === 'month') {
    periodDiff = getDiffByMonth(start, end);
    comparedPeriodDiff = getDiffByMonth(comparedStart, comparedEnd);
  } else {
    periodDiff = end.diff(start, timeUnit);
    comparedPeriodDiff = comparedEnd.diff(comparedStart, timeUnit);
  }
  if (comparedPeriodDiff > periodDiff) {
    return {
      start: start.clone().startOf(timeUnit),
      end: start.clone().startOf(timeUnit).add(comparedPeriodDiff, timeUnit),
    };
  }
  return {
    start: period.start.clone().startOf(timeUnit),
    end: period.end.clone().startOf(timeUnit),
  };
};

const buildYSeriesForCompared = ({
  dataList,
  comparedDataList,
  selectedCategories,
  dimensions,
  axis: { primary },
  period,
  comparedPeriod,
  periodType,
  visibleList,
}) => {
  const timeUnit = periodType === PERIOD_TYPE.MONTH ? 'month' : 'day';
  const extendedPeriod = buildExtendedPeriod(period, comparedPeriod, timeUnit);
  const comparedExtendedPeriod = buildExtendedPeriod(
    comparedPeriod,
    period,
    timeUnit
  );
  const mainPeriodSeriesList = buildSeries({
    dataList,
    selectedCategories,
    dimensions,
    metric: primary,
    period: extendedPeriod,
    actualPeriod: period,
    periodType,
    seriesType: 'line',
    yAxisId: 0,
    visibleList,
  });

  const comparedPeriodSeriesList = buildSeries({
    dataList: comparedDataList,
    selectedCategories,
    dimensions,
    metric: primary,
    period: comparedExtendedPeriod,
    actualPeriod: comparedPeriod,
    periodType,
    seriesType: 'line',
    dashStyle: 'shortdot',
    yAxisId: 0,
    compared: true,
    visibleList,
  });

  return mainPeriodSeriesList.concat(comparedPeriodSeriesList);
};

const getIndexOfMemo = (memoDate, periodRange) => {
  // const formattedDate = moment(memoDate, API_DATE_FORMAT).format(
  //   CALENDAR_DAY_FORMAT
  // );
  const formattedDate = memoDate;
  if (formattedDate < periodRange[0]) {
    return 0;
  }
  if (formattedDate > periodRange[periodRange.length - 1]) {
    return periodRange.length;
  }
  return periodRange.findIndex((p) => p === formattedDate);
};

function buildMemoSeries(memoList, periodRange) {
  const memoSeries = {
    data: memoList.map((memo) => ({
      x: getIndexOfMemo(memo.start, periodRange),
      x2: getIndexOfMemo(memo.end, periodRange, periodRange.length - 1) + 1,
      y: memo.displayIndex,
      category: memo.category,
      color: MEMO_CATEGORIES_PATTERNS.find(
        (category) => category.key.toLowerCase() === memo.category.toLowerCase()
      ).color,
      memoId: memo.id,
      memoStart: memo.start,
      memoEnd: memo.end,
      content: memo.content,
    })),
    type: 'xrange',
    pointWidth: 5,
    borderRadius: 0,
    showInLegend: false,
    yAxis: 2,
    pointStart: 0,
    xAxis: 1,
    cursor: 'pointer',
    allowPointSelect: false,
    memo: true,
    name: 'series-memo',
    visible: true,
  };
  return memoSeries;
}

const createChartSeriesList = ({
  list,
  axis,
  selectedCategories,
  dimensions,
  period,
  comparedPeriod,
  memoEnabled,
  periodType,
  comparedList,
  visibleList,
  memoList,
}) => {
  let seriesList;
  if (!comparedPeriod || !comparedPeriod.enabled || !comparedList) {
    if (!axis.secondary) {
      seriesList = buildYSeriesForMonoAxis({
        period,
        dataList: list,
        selectedCategories,
        dimensions,
        metric: axis.primary,
        periodType,
        visibleList,
      });
    } else {
      //
      seriesList = buildYSeriesForDualAxis({
        period,
        dataList: list,
        selectedCategories,
        dimensions,
        axis,
        periodType,
        visibleList,
      });
    }
  } else {
    seriesList = buildYSeriesForCompared({
      dataList: list,
      comparedDataList: comparedList,
      comparedPeriod,
      period,
      periodType,
      dimensions,
      selectedCategories,
      axis,
      visibleList,
    });
  }
  if (memoEnabled) {
    const periodRange = buildPeriodRange(PERIOD_TYPE.DAY, period);
    const memoSeries = buildMemoSeries(memoList, periodRange);
    seriesList = seriesList.concat([memoSeries]);
  }
  return seriesList;
};

const buildCategories = (list) =>
  uniqWith(list, (elem1, elem2) =>
    equalsAllKeys(elem1.dimensionKey, elem2.dimensionKey)
  );

const updateMemoListForChart = ({
  displayRows,
  memoEnabled,
  memoList,
  periodRange,
}) => {
  if (memoEnabled) {
    // const periodRange = buildPeriodRange(PERIOD_TYPE.DAY, period);
    const memoSeries = buildMemoSeries(memoList, periodRange);
    return displayRows.filter((row) => !row.memo).concat(memoSeries);
  }
  return displayRows.filter((row) => !row.memo);
};

const createDimensionKeyObjectForChart = (
  obj,
  dimensions,
  mapperKeys = DIMENSIONS_TO_KEY_VALUES
) => {
  return dimensions
    .map((dimension) => mapperKeys.find((d) => d.field === dimension))
    .reduce((acc, { key }) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

const updatePrimaryAxis = ({
  seriesList,
  metric,
  categoriesList,
  periodRange,
  comparedCategoriesList,
  comparedPeriodRange,
}) => {
  return seriesList.map((series) => {
    if (series.type !== 'line') {
      return series;
    }
    const lookupList = series.compared
      ? comparedCategoriesList
      : categoriesList;
    const lookupRange = series.compared ? comparedPeriodRange : periodRange;
    const matchingCategory = lookupList.find((category) =>
      equalsAllKeys(category.dimensionKey, series.dimensionKey)
    );

    return {
      ...series,
      data: lookupRange.map((timestamp) =>
        Number(get(matchingCategory, `period.${timestamp}.${metric}`, 0))
      ),
    };
  });
};

const updateSecondaryAxis = ({
  seriesList,
  metric,
  categoriesList,
  periodRange,
}) => {
  if (isNil(metric)) {
    return seriesList.filter((series) => series.type !== 'column');
  }

  return seriesList.map((series) => {
    if (series.type !== 'column') {
      return series;
    }
    const matchingCategory = categoriesList.find((category) =>
      equalsAllKeys(category.dimensionKey, series.dimensionKey)
    );
    return {
      ...series,
      data: periodRange.map((timestamp) =>
        Number(get(matchingCategory, `period.${timestamp}.${metric}`, 0))
      ),
    };
  });
};

const addSecondaryAxis = ({
  categoriesList,
  seriesList,
  periodRange,
  metric,
}) => {
  const secondarySeries = seriesList
    .filter((series) => series.type === 'line')
    .map((series) => {
      const matchingCategory = categoriesList.find((category) =>
        equalsAllKeys(category.dimensionKey, series.dimensionKey)
      );
      return {
        ...series,
        type: 'column',
        yAxis: 1,
        colorIndex: series.colorIndex,
        color: CHART_COLORS[series.colorIndex].secondary,
        colorPalette: 'secondary',
        data: periodRange.map((timestamp) =>
          Number(get(matchingCategory, `period.${timestamp}.${metric}`, 0))
        ),
      };
    });

  return secondarySeries.concat(seriesList);
};

const removeSecondaryAxis = (seriesList) =>
  seriesList.filter((series) => series.type !== 'column');

const updateVisibleRow = ({ seriesList, row, visible }) =>
  seriesList.map((series) => {
    if (series.memo || !equalsAllKeys(series.dimensionKey, row)) {
      return series;
    }
    return {
      ...series,
      visible,
    };
  });

const immutableChartOptions = {
  chart: {
    height: 287,
    spacingRight: 18,
    spacingBottom: 0,
    style: {
      fontFamily: 'メイリオ, Meiryo, sans-serif',
    },
    lang: {
      decimalPoint: '.',
      thousandsSep: ',',
    },
  },
  title: {
    text: '',
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      visible: true,
      // Set this option to false make crosshair not work as expected
      // stickyTracking: false,
      dataLabels: {
        enabled: true,
        format: '{point.name}',
        style: {
          width: '200px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
        allowOverlap: false,
      },
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 5,
        states: {
          hover: {
            radiusPlus: 0.5,
          },
        },
      },
      tooltip: {},
      point: {
        events: {
          // click: memoClickFn,
        },
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
      },
      states: {
        hover: {
          brightness: 0,
        },
      },
      borderWidth: 0, // This removes the blank space between 2 items in the stacked column
      // pointPadding: 0,
      // groupPadding: 0,
    },
    line: {
      // pointPlacement: 'on',
      states: {
        hover: {
          lineWidthPlus: 0,
          halo: {
            opacity: 0.2,
            size: 12,
          },
        },
      },
    },
    xrange: {
      tooltip: {
        followPointer: true,
      },
    },
  },
};

const getTooltipMode = (comparedEnabled, axis) => {
  if (comparedEnabled) {
    return TOOLTIP_MODE.COMPARED;
  }
  if (axis.secondary) {
    return TOOLTIP_MODE.DUAL;
  }
  return TOOLTIP_MODE.MONO;
};

const getNumberOfLabels = (periodType, periodLength) => {
  let numberOfLabels;
  switch (periodType) {
    case PERIOD_TYPE.DAY:
      numberOfLabels = Math.min(periodLength, 14);
      break;
    case PERIOD_TYPE.MONTH: // max 12 months
    case PERIOD_TYPE.DOW: // max 7 days
    case PERIOD_TYPE.HOUR: // max 24
    default:
      numberOfLabels = periodLength;
      break;
  }
  return numberOfLabels;
};

const getXAxisLabelSettings = (periodType) => {
  let whiteSpace;
  let wordWrap;
  let maxWidth;
  switch (periodType) {
    case PERIOD_TYPE.DAY:
      whiteSpace = 'normal';
      maxWidth = '46px';
      break;
    case PERIOD_TYPE.HOUR:
      // whiteSpace = 'normal';
      break;
    case PERIOD_TYPE.DOW:
    case PERIOD_TYPE.MONTH:
      break;
    default:
      break;
  }
  return { whiteSpace, wordWrap, maxWidth };
};

const buildCategoryOptions = (
  {
    seriesList,
    periodRange,
    periodType,
    comparedPeriod,
    period,
    currentTab,
    axis,
    axisTitle = {},
    memoEnabled,
    memoClickFn,
  },
  { t, i18n }
) => {
  const displayField = (field) =>
    displayService.getField(t, i18n)(field, currentTab);

  const dualPeriods = () => {
    const currentPeriodSlice = buildPeriodRange(periodType, period);
    let timeSlice;
    if (comparedPeriod && comparedPeriod.enabled) {
      const comparedPeriodSlice = buildPeriodRange(periodType, comparedPeriod);
      timeSlice = [currentPeriodSlice, comparedPeriodSlice];
    } else {
      timeSlice = [currentPeriodSlice];
    }
    return timeSlice;
  };
  const primaryAxisText = axisTitle.primary || displayField(axis.primary);
  const secondaryAxisText = axisTitle.secondary || displayField(axis.secondary);

  const memoLabels = {
    [MEMO_CATEGORY.ONLINE]: t(getMemoCategoryDisplayKey(MEMO_CATEGORY.ONLINE)),
    [MEMO_CATEGORY.OFLLINE]: t(
      getMemoCategoryDisplayKey(MEMO_CATEGORY.OFLLINE)
    ),
    [MEMO_CATEGORY.WEBSITE]: t(
      getMemoCategoryDisplayKey(MEMO_CATEGORY.WEBSITE)
    ),
    [MEMO_CATEGORY.ANALYSIS]: t(
      getMemoCategoryDisplayKey(MEMO_CATEGORY.ANALYSIS)
    ),
    [MEMO_CATEGORY.OTHERS]: t(getMemoCategoryDisplayKey(MEMO_CATEGORY.OTHERS)),
  };
  let chartSeries;
  const nonMemoSeriesList = seriesList.filter((series) => !series.memo);
  if (nonMemoSeriesList.length <= 0) {
    chartSeries = seriesList.concat({
      id: 'empty-series',
      xAxis: 0,
      yAxis: 0,
      data: [],
    });
  } else {
    chartSeries = seriesList;
  }
  const memoSeries = seriesList.find((series) => series.memo);
  let memoAxisMax;
  if (memoSeries) {
    memoAxisMax = Math.max(...memoSeries.data.map((data) => data.y)) + 1;
  } else {
    memoAxisMax = 1;
  }

  // const numberOfLabel = Math.min(periodRange.length, 16);
  const numberOfLabel = getNumberOfLabels(periodType, periodRange.length);

  const labelInterval =
    numberOfLabel <= 1
      ? 1
      : round((periodRange.length - 1) / (numberOfLabel - 1), 0);

  const { whiteSpace, maxWidth } = getXAxisLabelSettings(periodType);

  return {
    ...immutableChartOptions,
    series: chartSeries,
    xAxis: [
      {
        // id: 'values',
        visible: true,
        labels: {
          useHTML: true,
          formatter() {
            switch (periodType) {
              case PERIOD_TYPE.DAY:
                return moment(this.value).format(
                  COMPARE_PERIOD_X_LABEL_DATE_FORMAT
                );
              case PERIOD_TYPE.MONTH:
                return moment(this.value).format(
                  COMPARE_PERIOD_X_LABEL_MONTH_FORMAT
                );
              case PERIOD_TYPE.DOW: {
                const dows = JP_DAY_OF_WEEK;
                return `${dows[this.value]}曜`;
              }
              case PERIOD_TYPE.HOUR:
                return `${this.value}時`;
              default:
                return '';
            }
          },
          style: {
            color: '#000000',
            fontSize: '11px',
            paddingTop: '3px',
            textAlign: 'center',
            // wordWrap: periodType === PERIOD_TYPE.DAY ? 'break-word' : undefined,
            maxWidth,
            whiteSpace,
          },
          // Can not use autoRotation when we have step
          // autoRotation: [-30],
          step: labelInterval,
        },
        categories: periodRange,
        tickWidth: 1,
        // tickAmount: 10,
        tickInterval: 1,
        // endOnTick: true,
        // tickPositions,
        startOnTick: true,
        lineColor: '#E2E2E2',
        tickColor: '#E2E2E2',
        crosshair: {
          color: 'rgba(244, 244, 244, 0.6)',
        },
        // categories: range(0, 8),
      },
      {
        // id: 'memo',
        visible: false,
        // categories: range(0, periodRange.length),
        min: 0,
        max: periodRange.length,
      },
    ],
    yAxis: [
      {
        // id: 'primary',
        labels: {
          style: {
            fontSize: '11px',
            color: '#000000',
          },
        },
        title: {
          text: primaryAxisText,
          style: {
            color: '#000000',
            fontSize: '11px',
          },
          x: -10,
        },
        bottom: memoEnabled ? '50%' : '100%',
        height: memoEnabled ? '80%' : '100%',
        min: 0,
        max: nonMemoSeriesList.length <= 0 ? 0 : null,
      },
      {
        // id: 'secondary',
        labels: {
          style: {
            fontSize: '11px',
            color: '#000000',
          },
        },
        title: {
          text: secondaryAxisText,
          style: {
            color: '#000000',
            fontSize: '11px',
          },
        },
        bottom: memoEnabled ? '50%' : '100%',
        height: memoEnabled ? '80%' : '100%',
        opposite: true,
        visible: !!axis.secondary,
        min: 0,
        max: nonMemoSeriesList.length <= 0 ? 0 : null,
      },
      {
        // id: 'memo',
        labels: {
          enabled: false,
        },
        title: null,
        height: '20%',
        // tickInterval: 1,
        top: '80%',
        visible: false,
        min: 0,
        max: memoAxisMax,
      },
    ],
    tooltip: {
      useHTML: true,
      backgroundColor: '#FFFFFF',
      borderRadius: 6,
      borderWidth: 1,
      padding: 0,
      style: {
        // color: '#000000',
        fontSize: '11px',
      },
      shadow: {
        color: 'rgba(0,0,0,0.16)',
        width: 6,
      },
      formatter() {
        if (this.series.options.memo) {
          const { memoStart, memoEnd, category, content } = this.point.options;
          return buildTooltipForMemo({
            start: moment(memoStart, API_DATE_FORMAT).format(
              CALENDAR_DAY_FORMAT
            ),
            end: moment(memoEnd, API_DATE_FORMAT).format(CALENDAR_DAY_FORMAT),
            category: memoLabels[category],
            content,
          });
        }
        const mode = getTooltipMode(comparedPeriod.enabled, axis);
        switch (mode) {
          case TOOLTIP_MODE.DUAL: {
            const { dimensionKey } = this.series.options;
            const xCategory = this.point.category;
            const matchingSeries = this.series.chart.series
              .filter(
                (s) =>
                  !s.options.memo &&
                  equalsAllKeys(s.options.dimensionKey, dimensionKey)
              )
              .reverse();

            const linePoint = matchingSeries[0]?.points.find(
              (point) => point.category === xCategory
            );
            const columnPoint = matchingSeries[1]?.points.find(
              (point) => point.category === xCategory
            );

            const displayDate = buildDateDisplayForTooltip(
              xCategory,
              periodType
            );
            return buildTooltipForDualSeriers({
              date: displayDate,
              metric1: primaryAxisText,
              metric2: secondaryAxisText,
              value1: domainUtils.formatValueForTable(
                linePoint?.y,
                axis.primary,
                { showZeros: true }
              ),
              value2: domainUtils.formatValueForTable(
                columnPoint?.y,
                axis.secondary,
                { showZeros: true }
              ),
              color1: linePoint?.color,
              color2: columnPoint?.color,
            });
          }
          case TOOLTIP_MODE.COMPARED: {
            const { dimensionKey } = this.series.options;
            const { index } = this.point;
            const timeSlice = dualPeriods();
            const series1 = this.series.chart.series.find(
              (s) =>
                !s.options.memo &&
                equalsAllKeys(s.options.dimensionKey, dimensionKey) &&
                !s.options.compared
            );
            const series2 = this.series.chart.series.find(
              (s) =>
                !s.options.memo &&
                equalsAllKeys(s.options.dimensionKey, dimensionKey) &&
                s.options.compared
            );
            const value1 = series1?.points[index]?.y || 0;
            const value2 = series2?.points[index]?.y || 0;

            const [displayDate1, displayDate2] = [period, comparedPeriod].map(
              (timePeriod, timeSliceIndex) => {
                const dateDisplay = buildDateDisplayForTooltip(
                  timeSlice[timeSliceIndex][index],
                  periodType
                );
                if (APPEND_PERIOD_ON_COMPARED_TOOLTIP.includes(periodType)) {
                  return addPeriodDisplayForTooltip(dateDisplay, {
                    start: timePeriod.start,
                    end: timePeriod.end,
                  });
                }
                return dateDisplay;
              }
            );

            return buildTooltipForComparedSeries({
              value1: domainUtils.formatValueForTable(value1, axis.primary, {
                showZeros: true,
              }),
              value2: domainUtils.formatValueForTable(value2, axis.primary, {
                showZeros: true,
              }),
              metric: primaryAxisText,
              color: this.color,
              date1: displayDate1,
              date2: displayDate2,
            });
          }

          case TOOLTIP_MODE.MONO:
          default: {
            const axisLabel = this.series.yAxis.chart.yAxis[0].axisTitle
              .textStr;
            const displayDate = buildDateDisplayForTooltip(this.x, periodType);
            return buildTooltipForMonoSeries({
              xValue: displayDate,
              yValue: domainUtils.formatValueForTable(this.y, axis.primary, {
                showZeros: true,
              }),
              yLabel: axisLabel,
              color: this.color,
            });
          }
        }
      },
    },
    plotOptions: {
      ...immutableChartOptions.plotOptions,
      series: {
        ...immutableChartOptions.plotOptions.series,
        point: {
          ...immutableChartOptions.plotOptions.series.point,
          events: {
            click: memoClickFn,
          },
        },
      },
    },
  };
};

export default {
  buildDateDisplayForTooltip,
  buildTooltipForComparedSeries,
  buildTooltipForDualSeriers,
  buildTooltipForMemo,
  buildTooltipForMonoSeries,
  createChartSeriesList,
  buildCategories,
  updateMemoListForChart,
  createDimensionKeyObjectForChart,
  updatePrimaryAxis,
  updateSecondaryAxis,
  removeSecondaryAxis,
  addSecondaryAxis,

  // visible
  updateVisibleRow,

  buildCategoryOptions,
  buildExtendedPeriod,
};
