import React from 'react';
import { arrayOf, string } from 'prop-types';

import './list-items-card.scss';

const ListItemsCardCustom = ({ items, bgcolor, border, maxHeight }) => {
  return (
    <div className="list-items-card" style={{ backgroundColor: bgcolor, border, maxHeight }}>
      {items.map((item, index) => {
        const keyIndex = `list-items-card-${index}`;
        return (
          <div key={keyIndex} style={{ order: item.order }}>
            {item.value}
          </div>
        );
      })}
    </div>
  );
};

ListItemsCardCustom.propTypes = {
  items: arrayOf({}).isRequired,
  bgcolor: string,
  border: string,
  maxHeight: string,
};

ListItemsCardCustom.defaultProps = {
  bgcolor: "#FFFFFF",
  border: "1px solid #CCCCCC",
  maxHeight: "100px",
};

export default ListItemsCardCustom;
