import {
  AD_GROUP1_ID,
  AD_GROUP2_ID,
  MEDIA_ID,
  AD_ID,
  AGENCY_ID,
} from 'domain/fields';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import useModalController from 'services/custom-hooks/useModalController';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';
import {
  AD_MANAGEMENT_MODE,
  AD_MANAGEMENT_TITLE,
} from 'domain/ad-management/consts';
import { AD_MANAGEMENT } from 'services/routes/constants';
import { getErrorMessageByCode, communicationStatus } from 'services/utils';
import * as ErrorMessages from 'services/validations/messageErrorByCode';
import {
  addNewKeyForDataList,
  updateHeaderCheckAll,
} from 'services/ad-management/adManagementService';
import adManagementSettingsSelectors from 'store/ad-management-settings/selectors';
import resizeTableSelectors from 'store/resize-table/selectors';
import adManagementSelectors from 'store/ad-management/selectors';
import masterDataActions from 'store/master-data/actions';
import settingsSelectors from 'store/settings/selectors';
import {
  getConfigAdCvCostMode,
  getConfigMeasurementHosts,
} from 'store/auth/selectors';
import resizeTableActions from 'store/resize-table/actions';
import adManagementActions from 'store/ad-management/actions';
import adManagementMediaActions from 'store/ad-management-media/actions';
import adManagementAdGroup1Actions from 'store/ad-management-adgroup1/actions';
import adManagementAdGroup2Actions from 'store/ad-management-adgroup2/actions';
import AdDeleteTrashConfirmModal from 'views/molecules/modal/AdDeleteTrashConfirmModal';
import AdDeleteCsvUploadModal from 'views/molecules/modal/AdDeleteCsvUploadModal';
import AdRegisterCsvUploadModal from 'views/molecules/modal/AdRegisterCsvUploadModal';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';
import TableStatisticsTemplate from 'views/templates/TableStatisticsTemplate';
import {
  TableControlTabAd,
  TableControlTabLpo,
  TableControlTabListing,
  TableControlTabAll,
  TableControlTabDeleted,
} from 'views/pages/ad-management/components/TableControl';
import AdDetailViewModal from 'views/pages/ad-management/components/AdDetailViewModal';
import AdSettingsModal from 'views/pages/ad-management/components/AdSettingsModal';
import useToggleDownload from 'services/custom-hooks/useToggleDownload';
import { FormWrapper } from 'views/molecules/Form';
import useCheckPermissionAgency from 'services/custom-hooks/useCheckPermissionAgency';

import 'views/pages/ad-management/ad-management.scss';
import 'views/molecules/Tabs/tabs.scss';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import { AD_ANALYSIS_LPO_VIEW } from 'domain/permissions/contractPermissions';
import { reflectionTimeSelectors } from 'store/reflectiontime';

const { SUCCEEDED, FAILED } = communicationStatus;

const ModalId = {
  CSV_REGISTER: 'csvRegister',
  CSV_DELETE: 'csvDelete',
  AD_SETTINGS: 'adSettings',
  AD_DETAIL: 'adDetail',
  AD_DELETE_CONFIRM: 'adDeleteConfirm',
  AD_DELETE_DURATION_CONFIRM: 'adDeleteDurationConfirm',
  AD_DELETE_TRASH_CONFIRM: 'adDeleteTrashConfirm',
  AD_DELETE_OR_DROP_ERROR: 'adDeleteOrDropError',
  AD_RESTORE_CONFIRM: 'adRestoreConfirm',
  AD_RESTORE_ERROR: 'adRestoreError',
  AD_CHANGE_CONFIRM: 'adChangeConfirm',
  AD_CHANGE_DURATION_CONFIRM: 'adChangeDurationConfirm',
  AD_CHANGE_CONFIRM_ERROR: 'adChangeConfirmError',
  CSV_REGISTER_SUBMIT_CONFIRM: 'csvRegisterSubmitConfirm',
  ERROR: 'error',
};

const ACTION = {
  REGISTER: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  RESTORE: 'restore',
  CHANGE: 'change',
};

function AdManagementContainer() {
  const [
    screenId,
    mode,
    pagination,
    headerData,
    listData,
    listError,
    detailData,
    detailError,
    uploadData,
    uploadError,
    masterData,
    columnsWidth,
    tablesWidth,
    adCvCostMode,
    measurementHosts,
    filters,
    sorts,
    remain,
    isLoadingPage,
    isTableUpdating,
    isDetailLoading,
    tabledStatus,
    detailStatus,
    uploadStatus,
    isContractedLpoBefore,
    lpupCount,
  ] = [
    useSelector(settingsSelectors.getPage, shallowEqual),
    useSelector(adManagementSelectors.getTab),
    useSelector(adManagementSelectors.getPagination, shallowEqual),
    useSelector(adManagementSelectors.getHeaders, shallowEqual),
    useSelector(adManagementSelectors.getList, shallowEqual),
    useSelector(adManagementSelectors.getListError, shallowEqual),
    useSelector(adManagementSelectors.getDetailData, shallowEqual),
    useSelector(adManagementSelectors.getDetailError, shallowEqual),
    useSelector(adManagementSelectors.getUploadData, shallowEqual),
    useSelector(adManagementSelectors.getUploadError, shallowEqual),
    useSelector(adManagementSelectors.masterData, shallowEqual),
    useSelector(resizeTableSelectors.getColumnWidth, shallowEqual),
    useSelector(resizeTableSelectors.getTableWidth, shallowEqual),
    useSelector(getConfigAdCvCostMode),
    useSelector(getConfigMeasurementHosts),
    useSelector(adManagementSettingsSelectors.getFilters),
    useSelector(adManagementSelectors.getSort),
    useSelector(adManagementSelectors.getRemain),
    useSelector(adManagementSelectors.isLoadingPage),
    useSelector(adManagementSelectors.isTableUpdating),
    useSelector(adManagementSelectors.isDetailLoading),
    useSelector(adManagementSelectors.getStatusTable),
    useSelector(adManagementSelectors.getStatusDetail),
    useSelector(adManagementSelectors.getStatusUpload),
    useSelector(adManagementSelectors.isContractedLpoBefore),
    useSelector(adManagementSelectors.getLpoRegistPageMax),
  ];
  const isDisplayAdListing = useSelector(
    reflectionTimeSelectors.getIsDisplayAdListing,
    shallowEqual
  );

  const { hasContractAgent } = useCheckPermissionAgency();
  const { checkPermissions } = useCheckPermissions();
  const lpoPermission = checkPermissions('anyOf', [AD_ANALYSIS_LPO_VIEW]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [isSelectedAllPages, setSelectedAllPages] = useState(false);
  const [actionSettings, setActionSettings] = useState('');
  const [errorInfo, setErrorInfo] = useState({
    title: '',
    message: '',
    callback: () => {},
  });

  const [toggleCSV] = useToggleDownload(AD_MANAGEMENT);
  const { openModal, closeModals, isOpen } = useModalController({
    ids: [
      ModalId.AD_DETAIL,
      ModalId.AD_SETTINGS,
      ModalId.AD_DELETE_CONFIRM,
      ModalId.AD_DELETE_DURATION_CONFIRM,
      ModalId.AD_DELETE_TRASH_CONFIRM,
      ModalId.AD_DELETE_OR_DROP_ERROR,
      ModalId.AD_CHANGE_CONFIRM,
      ModalId.AD_CHANGE_DURATION_CONFIRM,
      ModalId.AD_CHANGE_CONFIRM_ERROR,
      ModalId.AD_RESTORE_CONFIRM,
      ModalId.AD_RESTORE_ERROR,
      ModalId.CSV_REGISTER,
      ModalId.CSV_DELETE,
      ModalId.ERROR,
    ],
  });

  useEffect(() => {
    if (!isEmpty(listData) && !isLoadingPage) {
      toggleCSV(true);
    } else {
      toggleCSV(false);
    }
  }, [isLoadingPage, listData, toggleCSV]);

  const allAdIds = useMemo(() => listData.map((item) => item[AD_ID]), [
    listData,
  ]);

  const newPagination = useMemo(
    () => ({ ...pagination, itemsPerPage: TABLE_ROWS_PER_PAGE }),
    [pagination]
  );

  const newHeaderData = useMemo(() => {
    const isAllChecked =
      listData.length > 0 && listData.length === selectedRows.length;

    return updateHeaderCheckAll(headerData, isAllChecked, selectedRows);
  }, [headerData, listData.length, selectedRows]);

  const newListData = useMemo(
    () => addNewKeyForDataList(listData, selectedRows),
    [listData, selectedRows]
  );

  const countItemSelected = useMemo(
    () =>
      isSelectedAllPages
        ? pagination.totalItems - (listData.length - selectedRows.length)
        : selectedRows.length,
    [
      isSelectedAllPages,
      pagination.totalItems,
      listData.length,
      selectedRows.length,
    ]
  );

  const excludedIds = useMemo(
    () =>
      listData
        .filter((listItem) => !selectedRows.includes(listItem.ad_id))
        .map((listItem) => listItem.ad_id),
    [listData, selectedRows]
  );

  const dispatch = useDispatch();

  const clearState = () => {
    setSelectedRows([]);
    setSelectedAllPages(false);
  };

  const getList = useCallback(() => {
    dispatch(adManagementActions.getList());
  }, [dispatch]);

  const handleCheckAll = useCallback(
    (checked) => {
      setSelectedRows(checked ? allAdIds : []);
    },
    [allAdIds]
  );

  const handleCheckRow = useCallback(
    (checked, id) => {
      const findAd = selectedRows.find((item) => item === id);
      if ((checked && !findAd) || (!checked && findAd)) {
        const newSelectedRows = checked
          ? [...selectedRows, id]
          : selectedRows.filter((item) => item !== id);
        setSelectedRows(newSelectedRows);
      }
    },
    [selectedRows]
  );

  const handleCheckAllPages = useCallback(
    (checked) => {
      setSelectedAllPages(checked);
      setSelectedRows(checked ? allAdIds : []);
    },
    [allAdIds]
  );

  const handleShowErrorModal = useCallback(
    (title, message, callback = () => {}) => {
      setErrorInfo({ title, message, callback });
      openModal(ModalId.ERROR);
    },
    [openModal]
  );

  const handleCloseModal = useCallback(() => {
    closeModals();
    setActionSettings('');
    dispatch(adManagementActions.setDetail({}));
  }, [dispatch, closeModals]);

  const handleResponse = useCallback(
    (isError = false, errors) => {
      if (!isError) {
        handleCloseModal();
      } else if (
        !isEmpty(errors) &&
        ['AD_NOT_IN_AGENCY_CHECK', 'AD_NOT_EXIST_CHECK'].includes(
          errors[0].code
        )
      ) {
        const messages = {
          AD_NOT_IN_AGENCY_CHECK: ErrorMessages.AD_NOT_IN_AGENCY_CHECK,
          AD_NOT_EXIST_CHECK: ErrorMessages.AD_NOT_EXIST_CHECK,
        };
        handleCloseModal();
        const message = getErrorMessageByCode(errors[0], messages, {
          action: '編集',
        });
        handleShowErrorModal('データ更新に失敗しました', message, getList);
      }
    },
    [handleShowErrorModal, handleCloseModal, getList]
  );

  const handleEdit = useCallback(
    (adId) => {
      dispatch(adManagementActions.getOne(adId, handleResponse));
      setActionSettings(ACTION.EDIT);
      openModal(ModalId.AD_SETTINGS);
    },
    [dispatch, openModal, handleResponse]
  );

  const handleViewDetail = useCallback(
    (adId) => {
      dispatch(adManagementActions.getOne(adId, handleResponse));
      openModal(ModalId.AD_DETAIL);
    },
    [dispatch, openModal, handleResponse]
  );

  const handleChangePage = useCallback(
    (currentPage) => {
      dispatch(adManagementActions.updatePage(currentPage));
    },
    [dispatch]
  );

  const handleSort = useCallback(
    (newSorts) => {
      const [{ field, direction }] = newSorts;
      dispatch(adManagementActions.updateSort(field, direction));
      dispatch(adManagementActions.getList());
    },
    [dispatch]
  );

  const onResizeColumn = useCallback(
    (column, width) => {
      dispatch(
        resizeTableActions.updateColumnWidth(AD_MANAGEMENT, column, width)
      );
    },
    [dispatch]
  );

  const onResizeTable = useCallback(
    (freezeWidth) => {
      dispatch(resizeTableActions.updateTableWidth(AD_MANAGEMENT, freezeWidth));
    },
    [dispatch]
  );

  const onFetchMasterData = useCallback(
    (agencyId) => {
      if (hasContractAgent) {
        dispatch(adManagementActions.fetchMasterdataByAgency(agencyId));
      }
    },
    [dispatch, hasContractAgent]
  );

  const handleSubmitFormSettings = useCallback(
    (data, isAdd) => {
      if (isAdd) {
        dispatch(adManagementActions.create(data));
      } else {
        dispatch(adManagementActions.update(data.ad_id, data));
      }
    },
    [dispatch]
  );

  const handleCloseFormSettings = useCallback(() => {
    closeModals();
    dispatch(adManagementActions.setDetail({}));
    onFetchMasterData();
  }, [dispatch, closeModals, onFetchMasterData]);

  const onChangeAdSubmit = useCallback(() => {
    let type = '';
    if (mode === AD_MANAGEMENT_MODE.AD) {
      type = 'change_lpo';
    } else if (mode === AD_MANAGEMENT_MODE.LPO) {
      type = 'change_ad';
    }
    if (isSelectedAllPages) {
      dispatch(
        adManagementActions.changeAdByFilter(
          excludedIds,
          type,
          isSelectedAllPages
        )
      );
    } else {
      dispatch(
        adManagementActions.changeAd(selectedRows, type, isSelectedAllPages)
      );
    }
  }, [dispatch, mode, selectedRows, excludedIds, isSelectedAllPages]);

  const onRegisterCsvUpload = (files) => {
    if (files && files.length > 0) {
      dispatch(adManagementActions.uploadCsv(files[0]));
    }
  };

  const onSubmitRegisterCsvUpload = () => {
    dispatch(adManagementActions.submitCsv());
  };

  const onHideRegisterCsvUpload = () => {
    closeModals();
    dispatch(adManagementActions.clearCsvUploadData());
  };

  const onDeleteCsvUpload = (files) => {
    if (files && files.length > 0) {
      dispatch(adManagementActions.uploadCsvDelete(files[0]));
    }
  };

  const onSubmitDeleteCsvUpload = () => {
    dispatch(adManagementActions.submitCsvDelete());
  };

  const onHideDeleteCsvUpload = () => {
    closeModals();
    dispatch(adManagementActions.clearCsvUploadData());
  };

  const onDeleteDurationConfirmSubmit = () => {
    if (!isSelectedAllPages) {
      dispatch(adManagementActions.delete(selectedRows));
    } else {
      dispatch(adManagementActions.deleteByFilters(excludedIds));
    }
  };

  const onDeleteTrashConfirmSubmit = () => {
    if (!isSelectedAllPages) {
      dispatch(adManagementActions.drop(selectedRows));
    } else {
      dispatch(adManagementActions.dropByFilters(excludedIds));
    }
  };

  const onRestoreConfirmSubmit = () => {
    if (!isSelectedAllPages) {
      dispatch(adManagementActions.restore(selectedRows));
    } else {
      dispatch(adManagementActions.restoreByFilters(excludedIds));
    }
  };

  const DeleteConfirmModal = () => (
    <ConfirmModal
      isShow={isOpen(ModalId.AD_DELETE_CONFIRM)}
      title="広告を削除しますか？"
      content="削除すると、過去データを含めすべて閲覧できなくなります。"
      onHide={closeModals}
      onSubmit={() => openModal(ModalId.AD_DELETE_DURATION_CONFIRM)}
    />
  );

  const DeleteOrDropErrorModal = () => {
    if (!isOpen(ModalId.AD_DELETE_OR_DROP_ERROR)) return <></>;

    // Only show first error
    const [error] = listError;
    const message = {
      UNREGISTER_PARENT_AD_ID: ErrorMessages.UNREGISTER_PARENT_AD_ID,
      UNDELETE_PARENT_AD_ID: ErrorMessages.UNREGISTER_PARENT_AD_ID,
      AD_NOT_IN_AGENCY_CHECK: ErrorMessages.AD_NOT_IN_AGENCY_CHECK,
    };

    return (
      <ConfirmModal
        isShow
        title="データ削除に失敗しました"
        content={getErrorMessageByCode(error, message, { action: '削除' })}
        confirmText="OK"
        cancelBtn={false}
        onSubmit={() => {
          closeModals();
          setActionSettings('');
          dispatch(adManagementActions.setErrorTable([]));
        }}
      />
    );
  };

  const ConfirmDeleteDurationModal = () => (
    <ConfirmModal
      isShow={isOpen(ModalId.AD_DELETE_DURATION_CONFIRM)}
      isLoading={isTableUpdating}
      title="広告を削除しますか？"
      jsxContent={
        <div style={{ lineHeight: '2.4' }}>
          対象広告の件数が多い場合、削除処理に時間がかかります。
          <br />
          例）10万件 : 約1分 &nbsp;&nbsp;/&nbsp;&nbsp; 150万件 : 約5分
          <br />
          削除処理中は他の画面に移動したり、ブラウザ・タブを閉じないようにご注意ください。
        </div>
      }
      onHide={closeModals}
      onSubmit={onDeleteDurationConfirmSubmit}
    />
  );

  const RestoreConfirmModal = () => (
    <ConfirmModal
      isShow={isOpen(ModalId.AD_RESTORE_CONFIRM)}
      isLoading={isTableUpdating}
      title="広告を再登録しますか？"
      confirmText="OK"
      onHide={closeModals}
      onSubmit={onRestoreConfirmSubmit}
    />
  );

  const RestoreErrorModal = () => {
    if (!isOpen(ModalId.AD_RESTORE_ERROR)) return <></>;

    // Only show first error & first parentId
    const [error] = listError;
    const [parentId] = error.metadata?.param || [];
    const message = {
      UNREGISTER_PARENT_AD_ID:
        '広告ID:{parentId}の親広告IDがアクティブではないため、再登録することができません。対象広告の親広告IDと共に再登録を実行してください。',
      AD_NOT_IN_AGENCY_CHECK: ErrorMessages.AD_NOT_IN_AGENCY_CHECK,
    };

    return (
      <ConfirmModal
        isShow
        title="再登録に失敗しました"
        content={getErrorMessageByCode(error, message, {
          parentId,
          action: '再登録',
        })}
        confirmText="OK"
        cancelBtn={false}
        onSubmit={() => {
          closeModals();
          setActionSettings('');
          dispatch(adManagementActions.setErrorTable([]));
        }}
      />
    );
  };

  const PopUpMessage = () => {
    let msg;
    let title;
    switch (mode) {
      case 'lpo':
        title = 'AD広告に変更しますか？';
        msg = (
          <div>
            LPO広告からAD広告に変更します。
            <br />
            変更後はLPO広告設定画面にて一番上に登録されているURLに統一されます。
            <br />
            <br />
            以前にLPO広告で取得されたデータが削除されますがよろしいですか？
          </div>
        );
        break;
      case 'ad':
        title = 'LPO広告に変更しますか？';
        msg = <div>AD広告からLPO広告に変更します。よろしいですか？</div>;
        break;
      default:
        break;
    }
    return { title, massage: msg };
  };

  const ChangeConfirmModal = () => {
    if (!isOpen(ModalId.AD_CHANGE_CONFIRM)) return <></>;
    const popup = PopUpMessage();
    return (
      <ConfirmModal
        isShow={isOpen(ModalId.AD_CHANGE_CONFIRM)}
        title={popup.title}
        jsxContent={popup.massage}
        confirmText="OK"
        onHide={closeModals}
        onSubmit={() => openModal(ModalId.AD_CHANGE_DURATION_CONFIRM)}
      />
    );
  };

  const ChangeDurationConfirmModal = () => {
    if (!isOpen(ModalId.AD_CHANGE_DURATION_CONFIRM)) return <></>;
    const popup = PopUpMessage();
    return (
      <ConfirmModal
        isShow={isOpen(ModalId.AD_CHANGE_DURATION_CONFIRM)}
        title={popup.title}
        isLoading={isTableUpdating}
        jsxContent={
          <div style={{ lineHeight: '2.4' }}>
            対象広告の件数が多い場合、変更処理に時間がかかります。
            <br />
            例）10万件 : 約15分 &nbsp;&nbsp;/&nbsp;&nbsp; 30万件 : 約1時間
            <br />
            変更処理中は他の画面に移動したり、ブラウザ・タブを閉じないようにご注意ください。
            <br />
            ※また一度に変更可能は上限数は30万件となっております。
          </div>
        }
        confirmText="OK"
        onHide={closeModals}
        onSubmit={onChangeAdSubmit}
      />
    );
  };

  const ChangeConfirmErrorModal = () => {
    if (!isOpen(ModalId.AD_CHANGE_CONFIRM_ERROR)) return <></>;
    const [error] = listError;
    const [parentId] = error.metadata?.param || [];
    const message = {
      ACCEPT_VALUE_CHECK: '「リダイレクト計測」のみ選択してください',
      DELETE_AD_LIMIT_CHECK: '一度に変更可能な上限数は30万件となっております。',
    };
    return (
      <ConfirmModal
        isShow
        title="広告変更に失敗しました"
        content={getErrorMessageByCode(error, message, {
          parentId,
          action: 'OK',
        })}
        confirmText="OK"
        cancelBtn={false}
        onSubmit={() => {
          closeModals();
          setActionSettings('');
          dispatch(adManagementActions.setErrorTable([]));
        }}
      />
    );
  };

  const onSearchMasterData = useCallback(
    (type, search, selected, agencyId) => {
      let action;
      switch (type) {
        case MEDIA_ID:
          action = masterDataActions.searchMedia;
          break;
        case AD_GROUP1_ID:
          action = masterDataActions.searchAdGroup1;
          break;
        case AD_GROUP2_ID:
          action = masterDataActions.searchAdGroup2;
          break;
        case AGENCY_ID:
          action = masterDataActions.searchAgency;
          break;
        default:
          break;
      }
      if (action) {
        dispatch(action({ search, selected, agencyId }));
      }
    },
    [dispatch]
  );

  const onCreateMasterData = useCallback(
    (value, name, agencyId) => {
      let action;
      switch (name) {
        case MEDIA_ID:
          action = adManagementMediaActions.create;
          break;
        case AD_GROUP1_ID:
          action = adManagementAdGroup1Actions.create;
          break;
        case AD_GROUP2_ID:
          action = adManagementAdGroup2Actions.create;
          break;
        default:
          break;
      }
      dispatch(action(value, agencyId));
    },
    [dispatch]
  );

  const AdManagementTableControl = useMemo(() => {
    const emptyData = listData.length <= 0;
    switch (mode) {
      case AD_MANAGEMENT_MODE.AD:
        return (
          <TableControlTabAd
            key="table-control-tab-ad"
            count={countItemSelected}
            totalItems={pagination.totalItems}
            remain={remain}
            emptyData={emptyData}
            onSelectedAllPages={handleCheckAllPages}
            onRegister={() => {
              openModal(ModalId.AD_SETTINGS);
              setActionSettings(ACTION.REGISTER);
            }}
            onChangeAd={() => {
              openModal(ModalId.AD_CHANGE_CONFIRM);
              setActionSettings(ACTION.CHANGE);
            }}
            onDelete={() => {
              openModal(ModalId.AD_DELETE_CONFIRM);
              setActionSettings(ACTION.DELETE);
            }}
            onSelectCsvDelete={() => openModal(ModalId.CSV_DELETE)}
            onSelectCsvRegister={() => openModal(ModalId.CSV_REGISTER)}
            lpoPermission={lpoPermission}
          />
        );
      case AD_MANAGEMENT_MODE.LPO:
        return (
          <TableControlTabLpo
            key="table-control-tab-lpo"
            count={countItemSelected}
            totalItems={pagination.totalItems}
            remain={remain}
            emptyData={emptyData}
            onSelectedAllPages={handleCheckAllPages}
            onRegister={() => {
              openModal(ModalId.AD_SETTINGS);
              setActionSettings(ACTION.REGISTER);
            }}
            onChangeAd={() => {
              openModal(ModalId.AD_CHANGE_CONFIRM);
              setActionSettings(ACTION.CHANGE);
            }}
            onDelete={() => {
              openModal(ModalId.AD_DELETE_CONFIRM);
              setActionSettings(ACTION.DELETE);
            }}
            onSelectCsvDelete={() => openModal(ModalId.CSV_DELETE)}
            onSelectCsvRegister={() => openModal(ModalId.CSV_REGISTER)}
            lpoPermission={lpoPermission}
          />
        );
      case AD_MANAGEMENT_MODE.LISTING:
        return (
          <TableControlTabListing
            key="table-control-tab-listing"
            count={countItemSelected}
            totalItems={pagination.totalItems}
            remain={remain}
            emptyData={emptyData}
            onSelectedAllPages={handleCheckAllPages}
            onDelete={() => {
              openModal(ModalId.AD_DELETE_CONFIRM);
              setActionSettings(ACTION.DELETE);
            }}
            onSelectCsvDelete={() => openModal(ModalId.CSV_DELETE)}
            onSelectCsvRegister={() => openModal(ModalId.CSV_REGISTER)}
          />
        );
      case AD_MANAGEMENT_MODE.ALL:
        return (
          <TableControlTabAll
            key="table-control-tab-all"
            count={countItemSelected}
            totalItems={pagination.totalItems}
            emptyData={emptyData}
            onSelectedAllPages={handleCheckAllPages}
            onDelete={() => {
              openModal(ModalId.AD_DELETE_CONFIRM);
              setActionSettings(ACTION.DELETE);
            }}
          />
        );
      case AD_MANAGEMENT_MODE.DELETED:
        return (
          <TableControlTabDeleted
            key="table-control-tab-deleted"
            count={countItemSelected}
            totalItems={pagination.totalItems}
            emptyData={emptyData}
            onSelectedAllPages={handleCheckAllPages}
            onRestore={() => {
              openModal(ModalId.AD_RESTORE_CONFIRM);
              setActionSettings(ACTION.RESTORE);
            }}
            onDelete={() => {
              openModal(ModalId.AD_DELETE_TRASH_CONFIRM);
              setActionSettings(ACTION.DELETE);
            }}
          />
        );

      default:
        return <></>;
    }
  }, [
    listData.length,
    mode,
    countItemSelected,
    pagination.totalItems,
    remain,
    handleCheckAllPages,
    openModal,
  ]);

  const AdManagementTab = useMemo(() => {
    return (
      <div
        className="ad-management__tabs data-list-tabs"
        key="ad-management-tab"
      >
        <Tab.Container activeKey={mode}>
          <Nav className="nav-tabs">
            <Nav.Item>
              {/* using span to avoid a href="#" on render, which makes clicking difficult */}
              <Nav.Link as="span" eventKey={AD_MANAGEMENT_MODE.AD}>
                <Link to="/ad-management/ad">AD広告</Link>
              </Nav.Link>
            </Nav.Item>
            {(lpoPermission || (!lpoPermission && isContractedLpoBefore)) && (
              <Nav.Item>
                <Nav.Link as="span" eventKey={AD_MANAGEMENT_MODE.LPO}>
                  <Link to="/ad-management/lpo">LPO広告</Link>
                </Nav.Link>
              </Nav.Item>
            )}
            {isDisplayAdListing && (
              <Nav.Item>
                <Nav.Link as="span" eventKey={AD_MANAGEMENT_MODE.LISTING}>
                  <Link to="/ad-management/listing">リスティング連携広告</Link>
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link as="span" eventKey={AD_MANAGEMENT_MODE.ALL}>
                <Link to="/ad-management/all">すべて</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as="span" eventKey={AD_MANAGEMENT_MODE.DELETED}>
                <Link to="/ad-management/deleted">削除済み</Link>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Tab.Container>
      </div>
    );
  }, [isContractedLpoBefore, lpoPermission, mode, isDisplayAdListing]);

  useEffect(() => {
    dispatch(adManagementActions.checkContractedLpoBefore());
  }, [dispatch, mode]);

  useEffect(() => {
    dispatch(adManagementActions.getList());
  }, [filters, pagination.currentPage, sorts, dispatch, mode]);

  useEffect(() => {
    if (
      tabledStatus !== FAILED ||
      ![ACTION.RESTORE, ACTION.DELETE, ACTION.CHANGE].includes(actionSettings)
    ) {
      return;
    }

    closeModals();
    // Change the previous status to continue taking another action
    dispatch(adManagementActions.setStatusTable(SUCCEEDED));

    if (isEmpty(listError)) return;

    if (actionSettings === ACTION.RESTORE) {
      openModal(ModalId.AD_RESTORE_ERROR);
    } else if (actionSettings === ACTION.DELETE) {
      openModal(ModalId.AD_DELETE_OR_DROP_ERROR);
    } else if (actionSettings === ACTION.CHANGE) {
      openModal(ModalId.AD_CHANGE_CONFIRM_ERROR);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, actionSettings, isTableUpdating, listError]);

  useEffect(() => {
    if (isLoadingPage) {
      closeModals();
      clearState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPage]);

  return (
    <div className="ad-management">
      <TableStatisticsTemplate
        screenId={screenId}
        screenName={AD_MANAGEMENT_TITLE[mode]}
        isLoading={isLoadingPage}
        isLoadingTable={isLoadingPage}
        styles={{
          container: 'ad-management__list',
          secondContainer: 'clearfix',
          pagination: 'float-right',
          table: 'freeze-table--settings',
        }}
        pagination={newPagination}
        headerData={newHeaderData}
        listData={newListData}
        columnsWidth={columnsWidth}
        tablesWidth={tablesWidth}
        handleChangePage={handleChangePage}
        handleSort={handleSort}
        onResizeColumn={onResizeColumn}
        onResizeTable={onResizeTable}
        handleCheckAll={handleCheckAll}
        handleCheckRow={handleCheckRow}
        handleEdit={handleEdit}
        handleViewDetail={handleViewDetail}
        tableControl={[AdManagementTab, AdManagementTableControl]}
      />
      {isOpen(ModalId.AD_DETAIL) && (
        <AdDetailViewModal
          isLoading={isDetailLoading}
          data={detailData}
          onHide={closeModals}
          masterData={masterData}
        />
      )}
      {isOpen(ModalId.AD_SETTINGS) && (
        <FormWrapper>
          <AdSettingsModal
            status={detailStatus}
            action={actionSettings}
            masterData={masterData}
            detailData={detailData}
            detailError={detailError}
            config={{ adCvCostMode, measurementHosts }}
            onSubmit={handleSubmitFormSettings}
            onHide={handleCloseFormSettings}
            onFinish={getList}
            onFetchMasterData={onFetchMasterData}
            onSearchMasterData={onSearchMasterData}
            onCreateMasterData={onCreateMasterData}
            mode={mode}
            lpupCount={lpupCount}
          />
        </FormWrapper>
      )}
      {isOpen(ModalId.CSV_REGISTER) && (
        <AdRegisterCsvUploadModal
          mode={mode}
          status={uploadStatus}
          errors={uploadError}
          data={uploadData}
          masterData={masterData}
          onUpload={onRegisterCsvUpload}
          onSubmit={onSubmitRegisterCsvUpload}
          onHide={onHideRegisterCsvUpload}
          lpupCount={lpupCount}
        />
      )}
      {isOpen(ModalId.CSV_DELETE) && (
        <AdDeleteCsvUploadModal
          status={uploadStatus}
          errors={uploadError}
          data={uploadData.deleteAds}
          masterData={masterData}
          onUpload={onDeleteCsvUpload}
          onSubmit={onSubmitDeleteCsvUpload}
          onHide={onHideDeleteCsvUpload}
        />
      )}
      <RestoreConfirmModal />
      <RestoreErrorModal />
      <DeleteConfirmModal />
      <ChangeConfirmModal />
      <ChangeDurationConfirmModal />
      <ChangeConfirmErrorModal />
      <ConfirmDeleteDurationModal />
      <AdDeleteTrashConfirmModal
        isShow={isOpen(ModalId.AD_DELETE_TRASH_CONFIRM)}
        isLoading={isTableUpdating}
        onHide={closeModals}
        onSubmit={onDeleteTrashConfirmSubmit}
      />
      <DeleteOrDropErrorModal />
      {isOpen(ModalId.ERROR) && (
        <ConfirmModal
          isShow
          title={errorInfo.title}
          content={errorInfo.message}
          confirmText="OK"
          cancelBtn={false}
          onSubmit={() => {
            closeModals();
            errorInfo.callback();
          }}
        />
      )}
    </div>
  );
}

export default AdManagementContainer;
