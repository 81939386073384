import { bool, node, shape, string } from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DisabledTooltipWrapper = ({
  message,
  children,
  placement,
  disabled,
  inContainer,
  style,
}) => {
  const target = useRef();
  const [isShow, toggleShow] = useState(false);
  const handleToggleShow = useCallback((show) => {
    toggleShow(show);
  }, []);
  const show = !!(isShow && disabled);

  return (
    <OverlayTrigger
      show={show}
      placement={placement}
      onToggle={handleToggleShow}
      target={target}
      container={inContainer ? target : document.body}
      overlay={
        <Tooltip variant="disabled" style={style}>
          {message}
        </Tooltip>
      }
    >
      {React.cloneElement(children, { ref: target })}
    </OverlayTrigger>
  );
};

DisabledTooltipWrapper.propTypes = {
  message: string,
  placement: string,
  children: node.isRequired,
  disabled: bool,
  inContainer: bool,
  style: shape({}),
};
DisabledTooltipWrapper.defaultProps = {
  message: '',
  placement: 'bottom',
  disabled: false,
  inContainer: false,
  style: {},
};
export default DisabledTooltipWrapper;
