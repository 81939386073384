import React, { useRef } from 'react';
import { Form, Table } from 'react-bootstrap';
import { oneOfType, bool, node, string, func, array } from 'prop-types';
import FormGroup from 'views/molecules/FormGroup';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import { AD_MANAGEMENT_MODE } from 'domain/ad-management/consts';

function FormGroupMultiUrl(props) {
  const {
    required,
    label,
    tooltip,
    name,
    items,
    errors,
    onChange,
    mode,
    isLpo,
  } = props;

  const ref = useRef();

  const handleChangeValue = (e) => {
    const { value: targetValue, name: targetName } = e.target;
    const [position, key] = targetName.split('.');

    const value = items.map((item, index) => {
      const newData = { ...item };
      if (index === +position) {
        newData[key] = targetValue;
      }
      return newData;
    });

    onChange(name, value);
  };
  const titles = () => {
    if (mode === AD_MANAGEMENT_MODE.LPO || isLpo) {
      return (
        <>
          <th width="330">URL</th>
          <th width="285">タイトル</th>
          <th width="122">遷移割合</th>
        </>
      );
    }
    return (
      <>
        <th width="123">デバイス</th>
        <th>URL</th>
        <th>タイトル</th>
      </>
    );
  };

  return (
    <FormGroup required={required} label={label} tooltip={tooltip}>
      <Table bordered className="ad-table-multi-url">
        <thead>
          <tr>{titles()}</tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            const error = errors[index] || {};
            return (
              <tr style={{ height: '40px' }}>
                {mode !== AD_MANAGEMENT_MODE.LPO && !isLpo && (
                  <td>{item.device}</td>
                )}
                <td>
                  <ErrorTooltipWrapper
                    isError={!!error.url}
                    errorMess={error.url}
                    ref={ref}
                  >
                    <Form.Control
                      size="sm"
                      name={`${index}.url`}
                      value={item.url}
                      placeholder="https://"
                      style={{ width: 300 }}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </ErrorTooltipWrapper>
                </td>
                <td>
                  <ErrorTooltipWrapper
                    isError={!!error.page_title}
                    errorMess={error.page_title}
                    ref={ref}
                  >
                    <Form.Control
                      size="sm"
                      name={`${index}.page_title`}
                      value={item.page_title}
                      placeholder="値を入力"
                      style={{ width: 254 }}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </ErrorTooltipWrapper>
                </td>
                {(mode === AD_MANAGEMENT_MODE.LPO || isLpo) && (
                  <td>
                    <ErrorTooltipWrapper
                      isError={!!error.transition_rate}
                      errorMess={error.transition_rate}
                      ref={ref}
                    >
                      <div className="symbol">
                        <Form.Control
                          size="sm"
                          name={`${index}.transition_rate`}
                          value={item.transition_rate}
                          placeholder=""
                          style={{ width: 60 }}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        &nbsp;
                        <span>%</span>
                      </div>
                    </ErrorTooltipWrapper>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </FormGroup>
  );
}

FormGroupMultiUrl.propTypes = {
  required: bool,
  label: string,
  tooltip: oneOfType([string, node]),
  name: string,
  items: oneOfType([array]),
  errors: oneOfType([array]),
  onChange: func,
  mode: string,
  isLpo: bool,
};

FormGroupMultiUrl.defaultProps = {
  required: false,
  label: '',
  tooltip: '',
  name: '',
  items: [],
  errors: [],
  onChange: () => {},
  mode: '',
  isLpo: false,
};

export default FormGroupMultiUrl;
