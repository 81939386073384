import { FUNC_CODE_DASHBOARD_ANALYSIS } from 'domain/settings/display-items';
import helper from 'store/helper';
import types from './types';

const initialState = {
  items: {
    /* : {
      channel: true,
      category: false,
      ...
    }, */
  },
  sorting: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null, // string | null,
  funcId: FUNC_CODE_DASHBOARD_ANALYSIS,
};

const requestStart = (state) => {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
};

const requestFailed = (state, action) => {
  const { error } = action.payload;
  return {
    ...state,
    status: 'failed',
    error,
  };
};

const fetchSucceeded = (state, action) => {
  const { funcId, items, sorting = [], override = false } = action.payload;

  let displayItemsForView;
  if (override) {
    displayItemsForView = items;
  } else {
    displayItemsForView = { ...state.items, ...items };
  }

  return {
    ...state,
    items: displayItemsForView,
    sorting,
    status: 'succeeded',
    funcId: funcId || state.funcId,
  };
};

const changeFuncId = (state, action) => {
  const { funcId } = action.payload;
  return {
    ...state,
    funcId,
  };
};

const DisplayItemsForViewReducer = helper.createReducer(initialState, {
  [types.REQUEST_START]: requestStart,
  [types.REQUEST_FAILED]: requestFailed,
  [types.FETCH_SUCCEEDED]: fetchSucceeded,
  [types.CHANGE_FUNC_ID]: changeFuncId,
});

export default DisplayItemsForViewReducer;
