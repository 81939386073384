import { Button, Form, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { arrayOf, func, string, bool } from 'prop-types';
import './collapsableFilter.scss';
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler';
import BelowPanel from 'views/atoms/BelowPanel';
import { arrToStr } from 'services/utils';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';

const arrayToObject = (options) => {
  const selectedObject = {};
  options.map((key) => {
    selectedObject[key] = true;
    return key;
  });
  return selectedObject;
};

const CollapsableFilter = React.memo((props) => {
  const { disabled, onChange, items, selectedKeys } = props;
  const [selected, updateSelected] = useState({});
  const [showPanel, setShowPanel] = useState(false);

  const handleCancel = () => {
    updateSelected(arrayToObject(selectedKeys));
    setShowPanel(false);
  };

  const handleEdit = () => {
    if (!disabled) {
      if (!showPanel) {
        setShowPanel(true);
      } else {
        handleCancel();
      }
    }
  };

  const handleCheckAll = () => {
    updateSelected(arrayToObject(Object.keys(items)));
  };
  const handleClearAll = () => updateSelected({});
  const handleCheck = (key) => {
    updateSelected((prev) => {
      const value = key in prev ? !prev[key] : true;
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleSubmit = () => {
    onChange(selected);
    setShowPanel(false);
  };

  useEffect(() => {
    updateSelected(arrayToObject(selectedKeys));
  }, [selectedKeys]);

  const selectedLength = Object.keys(selected).filter((key) => selected[key])
    .length;
  const isCheckAll = selectedLength === 9;
  const isClearAll = selectedLength === 0;
  const indirectText = arrToStr(
    Object.keys(items)
      .filter((key) => selectedKeys.length === 0 || selectedKeys.includes(key))
      .map((key) => items[key])
  );

  return (
    <OutsideClickHandler onOutsideClick={handleCancel}>
      <div className="collapsable-filter">
        <button
          type="button"
          className={`collapsable-filter__view d-flex ${
            showPanel ? 'active' : ''
          }`}
          onClick={handleEdit}
          disabled={disabled}
        >
          <span title={indirectText}>{indirectText}</span>
        </button>
        <div
          className={`collapsable-filter__panel ${
            showPanel ? 'd-flex' : 'd-none'
          }`}
        >
          <Form.Group>
            <Button
              variant="link"
              size="sm"
              onClick={handleCheckAll}
              disabled={isCheckAll}
              className="collapsable-filter__check-all"
            >
              すべて選択
            </Button>
            <Button
              size="sm"
              variant="link"
              disabled={isClearAll}
              onClick={handleClearAll}
              className="collapsable-filter__clear-all"
            >
              クリア
            </Button>
          </Form.Group>
          <div className="collapsable-filter__list">
            <Form.Group>
              {Object.keys(items).map((key) => {
                const title = items[key];
                return (
                  <Row>
                    <Col>
                      <EbisCheckbox
                        name={key}
                        text={title}
                        checked={key in selected && selected[key]}
                        changeSelectBoxValue={() => handleCheck(key)}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Form.Group>
          </div>
          <BelowPanel
            onOkClick={handleSubmit}
            onCancelClick={handleCancel}
            okDisabled={isClearAll}
          />
        </div>
      </div>
    </OutsideClickHandler>
  );
});

CollapsableFilter.propTypes = {
  onChange: func,
  items: arrayOf(string),
  selectedKeys: arrayOf(string),
  disabled: bool,
};

CollapsableFilter.defaultProps = {
  onChange: () => {},
  items: [],
  selectedKeys: [],
  disabled: false,
};

export default CollapsableFilter;
