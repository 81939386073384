import helper from 'store/helper';
import types from 'store/usage-status/types';

const getDataUsageStatus = () =>
  helper.createAction(types.GET_DATA_USAGE_STATUS);

const setUsageStatus = (status) =>
  helper.createAction(types.SET_USAGE_STATUS, { status });
const setDataUsageStatus = (data) =>
  helper.createAction(types.SET_DATA_USAGE_STATUS, { data });

const usageStatusActions = {
  getDataUsageStatus,
  setUsageStatus,
  setDataUsageStatus,
};

export default usageStatusActions;
