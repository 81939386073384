import React, { useState } from 'react';
import { element, string } from 'prop-types';
import { Collapse } from 'react-bootstrap';

import './ebis-collapse.scss';

function EbisCollapse(props) {
  const { title, children } = props;
  const [isExpand, setExpand] = useState(false);

  return (
    <div className="ebis-collapse">
      <div
        className="ebis-collapse__title"
        role="button"
        aria-hidden="true"
        onClick={() => setExpand(!isExpand)}
      >
        <span>{title}</span>
        <i className={`fas fa-chevron-down ${isExpand ? 'show' : ''}`} />
      </div>
      <Collapse in={isExpand}>{children}</Collapse>
    </div>
  );
}

EbisCollapse.propTypes = {
  title: string.isRequired,
  children: element.isRequired,
};

export default EbisCollapse;
