import React, { useRef, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { number, string, oneOfType, bool, node, oneOf } from 'prop-types';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import 'views/organism/TableModal/table-modal.scss';
import { isString } from 'lodash';

function TableModal(props) {
  const {
    isHover,
    isEmpty,
    variant,
    header,
    body,
    bodyLength,
    emptyMessage,
    maxContent,
    alignScroll,
    scrollIntoView,
    isNoScrollWrapper,
  } = props;
  const scrollbarWrapperRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    if (!scrollbarWrapperRef.current) return () => {};

    const scrollbarContentEl = scrollbarWrapperRef.current.querySelector(
      '.scrollbar-wrapper__content'
    );

    const setShadow = () => {
      if (scrollbarContentEl.scrollTop > 0) {
        headerRef.current.classList.add('table-shadow');
      } else {
        headerRef.current.classList.remove('table-shadow');
      }
    };

    scrollbarContentEl.addEventListener('scroll', setShadow);
    return () => {
      scrollbarContentEl.removeEventListener('scroll', setShadow);
    };
  }, []);

  return (
    <div className={`table-modal ${variant}`}>
      <div className="table-modal__thead">
        <Table bordered ref={headerRef}>
          <thead>{header}</thead>
        </Table>
      </div>
      {isEmpty ? (
        <div className="table-modal__empty">{emptyMessage}</div>
      ) : (
        <div
          className="table-modal__tbody"
          ref={isNoScrollWrapper ? null : scrollbarWrapperRef}
        >
          {isNoScrollWrapper ? (
            <Table bordered striped hover={isHover}>
              <tbody key={bodyLength}>{body}</tbody>
            </Table>
          ) : (
            <ScrollbarWrapper
              ref={scrollbarWrapperRef}
              maxContent={maxContent}
              alignScroll={alignScroll}
              scrollIntoView={scrollIntoView}
            >
              <Table bordered striped hover={isHover}>
                <tbody key={bodyLength}>{body}</tbody>
              </Table>
            </ScrollbarWrapper>
          )}
        </div>
      )}
    </div>
  );
}

TableModal.propTypes = {
  isHover: bool,
  isEmpty: bool,
  variant: isString,
  header: oneOfType([string, node]),
  body: oneOfType([string, node]),
  emptyMessage: string,
  maxContent: number,
  alignScroll: number,
  bodyLength: number,
  scrollIntoView: oneOf([false, 'top', 'bottom']),
  isNoScrollWrapper: bool,
};

TableModal.defaultProps = {
  isHover: false,
  isEmpty: false,
  variant: '',
  header: '',
  body: '',
  emptyMessage: '表示するデータがありません',
  maxContent: -1,
  alignScroll: -20,
  bodyLength: 0,
  scrollIntoView: false,
  isNoScrollWrapper: false,
};

export default TableModal;
