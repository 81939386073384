import React from 'react';
import PropTypes from 'prop-types';


const InsertBreakLine = ({ text }) => {
    const texts = text.split(/(\n)/).map((item) => {
      return (
        <React.Fragment key={text.id}>
          { item.match(/\n/) ? <br /> : item }
        </React.Fragment>
      );
    });
    return <span>{texts}</span>;
  }

InsertBreakLine.defaultProps = {
    text: '',
};

InsertBreakLine.propTypes = {
    text: PropTypes.string
  };

export default React.memo(InsertBreakLine);