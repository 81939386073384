import React from 'react';
import { func, bool, string, number } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { CROSS_DEVICE_MODE_MEMBER } from 'domain/system-setting/consts';

function CrossDeviceModal(props) {
  const {
    onSubmit,
    dialogClassName,
    pairType,
    onModal,
    isMemberNameModalShow,
    isDefaultModalShow,
    onOkClick,
    onCancelClick,
  } = props;

  const defaultModal = () => {
    return (
      <Modal
        show={isDefaultModalShow}
        centered
        size="w800"
        dialogClassName={dialogClassName}
        backdrop="static"
        className="modal--scroll"
      >
        <Modal.Body>
          <div className="modal-scroll">
            <div>
              クロスデバイス紐づけルールを変更すると、変更前のクロスデバイス分析結果は画面表示およびCSVデータ出力ができなくなります。データのバックアップが必要な場合は、紐づけルール変更前に対象画面でデータのエクスポートを行ってください。
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="link" onClick={onCancelClick}>
            キャンセル
          </Button>
          <Button size="sm" variant="secondary" onClick={onSubmit}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // Modal when "User name tied" is changed to "User name tied" and the registration button is clicked.
  const memberNameModal = () => {
    return (
      <>
        <Modal
          show={isMemberNameModalShow}
          centered
          size="w800"
          dialogClassName={dialogClassName}
          backdrop="static"
          className="modal--scroll"
        >
          <Modal.Body>
            <div className="modal-scroll">
              <div>
                クロスデバイス紐づけの「ユーザー名」を利用する場合に、属性取得用タグでユーザー名の項目にユーザー特有の値を取得していない値（例えば、固定の値や注文番号等）が含まれる場合、クロスデバイスを有効にしてもユーザーが正しく紐づきません。
                <br />
                <br />
                クロスデバイス有効時の集計結果が想定外の値で出力された場合は、属性取得用タグの設定を見直すか、紐づけルール「機械学習」をご利用ください。
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="link" onClick={onCancelClick}>
              キャンセル
            </Button>
            <Button size="sm" variant="secondary" onClick={onOkClick}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        {onModal && defaultModal()}
      </>
    );
  };

  return pairType === CROSS_DEVICE_MODE_MEMBER
    ? memberNameModal()
    : defaultModal();
}

CrossDeviceModal.propTypes = {
  isShow: bool.isRequired,
  onSubmit: func.isRequired,
  dialogClassName: string,
  pairType: number.isRequired,
  onModal: func.isRequired,
  isMemberNameModalShow: bool.isRequired,
  isDefaultModalShow: bool.isRequired,
  onOkClick: func.isRequired,
  onCancelClick: func.isRequired,
};

CrossDeviceModal.defaultProps = {
  dialogClassName: '',
};

export default CrossDeviceModal;
