import Api from 'services/api/Api';
import { ACCOUNT_ENDPOINT } from 'services/routes/constants';

const TutorialApiFactory = () => {
  const url = `${ACCOUNT_ENDPOINT}/users/self/tutorial`;

  return {
    postTutorialStatus: async (data) => Api.post(`${url}`, data),
  };
};

const TutorialApi = TutorialApiFactory();

export default TutorialApi;
