import types from 'store/page-management/types';
import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';

const getList = () => helper.createAction(types.GET_LIST);

const setDataList = (data) =>
  helper.createAction(types.SET_DATA_LIST, { data });
const setStatusList = (status) =>
  helper.createAction(types.SET_STATUS_LIST, { status });
const setErrorList = (errors, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERROR_LIST, { errors }, scope);

const getDetail = (id, callback = () => {}) =>
  helper.createAction(types.GET_DETAIL, { id, callback });
const setDataDetail = (data) =>
  helper.createAction(types.SET_DATA_DETAIL, { data });
const setErrorDetail = (errors, scope) =>
  helper.createAction(types.SET_ERROR_DETAIL, { errors }, scope);
const setStatusDetail = (status) =>
  helper.createAction(types.SET_STATUS_DETAIL, { status });

const update = (id, data, callback = () => {}) =>
  helper.createAction(types.UPDATE, { id, data, callback });
const deleteItems = (ids, callback) =>
  helper.createAction(types.DELETE, { ids, callback });
const downloadCsv = () => helper.createAction(types.DOWNLOAD_CSV);

const updateSort = (field, direction) =>
  helper.createAction(types.UPDATE_SORT, { field, direction });
const updatePage = (page) => helper.createAction(types.UPDATE_PAGE, { page });

const uploadCsvDelete = (file, callback) =>
  helper.createAction(types.UPLOAD_CSV_DELETE, { file, callback });

const submitCsv = (filename, callback, mode) =>
  helper.createAction(types.SUBMIT_CSV, { filename, callback, mode });

const uploadCsvUpdate = (file, callback) =>
  helper.createAction(types.UPLOAD_CSV_UPDATE, { file, callback });

const uploadCsvErrors = (errors, scope) =>
  helper.createAction(types.UPLOAD_CSV_ERRORS, { errors }, scope);

const setCsvFileName = (fileName) =>
  helper.createAction(types.SET_CSV_FILE_NAME, { fileName });

const exportCsvDeletedPages = () =>
  helper.createAction(types.EXPORT_CSV_DELETED_PAGES);

const pageManagementActions = {
  getList,
  setDataList,
  setErrorList,
  setStatusList,
  getDetail,
  setDataDetail,
  setErrorDetail,
  setStatusDetail,
  update,
  deleteItems,
  downloadCsv,
  updateSort,
  updatePage,
  uploadCsvDelete,
  uploadCsvUpdate,
  setCsvFileName,
  submitCsv,
  exportCsvDeletedPages,
  uploadCsvErrors,
};

export default pageManagementActions;
