import types from 'store/impression-ad-cost/types';
import helper from 'store/helper';
import { communicationStatus } from 'services/utils';

import { TABLE_ROWS_PER_PAGE } from 'domain/impression-ad-cost/consts';

const { IDLE, SUCCEEDED } = communicationStatus;
export const initialState = {
  data: {
    table: {
      list: [],
      sort: {
        field: 'execute_date',
        direction: 'desc',
      },
      pagination: {
        totalItems: null,
        itemsPerPage: TABLE_ROWS_PER_PAGE,
        currentPage: 1,
      },
    },
  },
  apiStatus: {
    table: IDLE,
  },
};

const setDataTable = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        list: action.payload?.data || [],
        pagination: {
          ...state.data.table.pagination,
          totalItems: action.payload?.metadata?.total || null,
        },
      },
    },
    apiStatus: {
      ...state.apiStatus,
      table: SUCCEEDED,
    },
  };
};

const setStatusTable = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      table: action.payload,
    },
  };
};

const updateSort = (state, action) => {
  const { field, direction } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        sort: {
          field,
          direction,
        },
        pagination: {
          ...state.data.table.pagination,
          currentPage: 1,
        },
      },
    },
  };
};

const updatePage = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        pagination: {
          ...state.data.table.pagination,
          currentPage: action.payload,
        },
      },
    },
  };
};

const resetPageAndSort = (state) => {
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        sort: {
          field: 'execute_date',
          direction: 'desc',
        },
        pagination: {
          totalItems: null,
          itemsPerPage: TABLE_ROWS_PER_PAGE,
          currentPage: 1,
        },
      }
    }
  }
}

export default helper.createReducer(initialState, {
  [types.SET_DATA_TABLE]: setDataTable,
  [types.SET_STATUS_TABLE]: setStatusTable,
  [types.UPDATE_SORT]: updateSort,
  [types.UPDATE_PAGE]: updatePage,
  [types.RESET_PAGE_AND_SORT]: resetPageAndSort,
});
