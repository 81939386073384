import types from 'store/reflectiontime/types';

const fetchReflectionStart = (type = null) => ({
  type: types.FETCH_REFLECTION_TIME_START,
  payload: { type },
});

const fetchReflectionSuccess = (data, scope = null) => ({
  type: types.FETCH_REFLECTION_TIME_SUCCESS,
  payload: { data, scope },
});

const fetchReflectionError = (error) => ({
  type: types.FETCH_REFLECTION_TIME_ERROR,
  payload: error,
});

export default {
  fetchReflectionStart,
  fetchReflectionSuccess,
  fetchReflectionError,
};
