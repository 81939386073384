import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { CV_FLOW } from 'services/routes/constants';

const CVFlowApiFactory = () => {
  const url = pages[CV_FLOW].endpoint;

  return {
    url,
    fetchReport: async (data) => Api.postRequest(url, data),
  };
};

const CVFlowApi = CVFlowApiFactory();
export default CVFlowApi;
