import types from 'store/media-sync-management/types';
import helper from 'store/helper';
import { communicationStatus } from 'services/utils';
import { ACCOUNT_STATUS_ISSUE_COMPLETED } from 'domain/media-sync-management/consts';

const { IDLE, SUCCEEDED, FAILED } = communicationStatus;
export const initialState = {
  data: {
    table: {
      list: [],
      sort: {
        field: 'media_name',
        direction: 'desc',
      },
      count: 0,
    },
    advertiserList: [],
    accountAdvertiser: {},
    media_account: {
      is_importing_costs: false,
      margins: [],
      used_margins: [],
    },
    detail: {},
  },
  apiStatus: {
    table: IDLE,
    detail: IDLE,
    validate: IDLE,
    margin: IDLE,
    advertiser: IDLE,
    authLine: IDLE,
    update: IDLE,
    delete: IDLE,
    stop: IDLE,
  },
  statusAccount: ACCOUNT_STATUS_ISSUE_COMPLETED,
};

const mapperKeyStatus = {
  [types.GET]: 'detail',
  [types.CREATE]: 'detail',
  [types.GET_MARGINS]: 'margin',
  [types.GET_LIST_ADVERTISER]: 'advertiser',
  [types.VERIFY_ADVERTISER_ID_REGISTERED]: 'advertiser',
  [types.UPDATE]: 'detail',
  [types.VALIDATE]: 'detail',
  [types.CHECK_ADVERTISER_ID]: 'authLine',
  [types.SEND_LINK_AUTH_LINE]: 'authLine',
  [types.VERIFY_CLIENT_APPROVAL]: 'authLine',
  [types.VERIFY_DOWLOADED_IMAGE]: 'authLine',
  [types.VERIFY_PASSWORD_LINE]: 'authLine',
};

const updateSort = (state, action) => {
  const { field, direction } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        sort: {
          ...state.data.table.sort,
          field,
          direction,
        },
      },
    },
  };
};

const setDataTable = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        list: action.payload.data,
        count: action.payload.metadata.count,
      },
    },
    apiStatus: {
      ...state.apiStatus,
      table: SUCCEEDED,
    },
  };
};

const setStatusTable = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      table: action.payload,
    },
  };
};

const requestFailedTable = (state) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      table: FAILED,
    },
  };
};

const setDataDetail = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      detail: action.payload,
    },
    apiStatus: {
      ...state.apiStatus,
      detail: SUCCEEDED,
    },
  };
};

const setStatusDetail = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      detail: action.payload,
    },
  };
};

const setStatusValidate = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      validate: action.payload,
    },
  };
};

const setStatusUpdate = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      update: action.payload,
    },
  };
};

const setStatus = (state, action) => {
  const { type, status } = action.payload;
  const key = mapperKeyStatus[type];
  if (!key) return state;
  return { ...state, apiStatus: { ...state.apiStatus, [key]: status } };
};

const setErrors = (state, action) => {
  const { type } = action.payload;
  const key = mapperKeyStatus[type];
  if (!key) return state;
  return { ...state, apiStatus: { ...state.apiStatus, [key]: FAILED } };
};

const setStatusAccount = (state, action) => {
  return {
    ...state,
    statusAccount: action.payload.status,
  };
};
const setStatusDelete = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      delete: action.payload,
    },
  };
};
const setStatusStop = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      stop: action.payload,
    },
  };
};

const setListAdvertiser = (state, action) => {
  const { data, account } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      advertiserList: data,
      accountAdvertiser: account,
    },
  };
};

const setMargins = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      media_account: { ...data },
    },
  };
};

const setStatusAuthLine = (state, action) => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      authLine: action.payload,
    },
  };
};

export default helper.createReducer(initialState, {
  [types.UPDATE_SORT]: updateSort,
  [types.SET_DATA_TABLE]: setDataTable,
  [types.SET_STATUS_TABLE]: setStatusTable,
  [types.REQUEST_FAILED_TABLE]: requestFailedTable,
  [types.SET_DATA_DETAIL]: setDataDetail,
  [types.SET_STATUS_DETAIL]: setStatusDetail,
  [types.SET_STATUS_VALIDATE]: setStatusValidate,
  [types.SET_STATUS_ACCOUNT]: setStatusAccount,
  [types.SET_STATUS]: setStatus,
  [types.SET_ERRORS]: setErrors,
  [types.SET_STATUS_UPDATE]: setStatusUpdate,
  [types.SET_LIST_ADVERTISER]: setListAdvertiser,
  [types.SET_MARGINS]: setMargins,
  [types.SET_STATUS_AUTH_LINE]: setStatusAuthLine,
  [types.SET_STATUS_DELETE]: setStatusDelete,
  [types.SET_STATUS_STOP]: setStatusStop,
});
