import React from 'react';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import {
  LTV_ANALYZE,
  LTV_SETTINGS_AD,
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_CLEAR_CSV_ORDERS,
} from 'services/routes/constants';
import pages from 'services/routes/pages';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import {
  MANAGE_LTV_EDIT,
  SETTING_LTV_AD_LINKING_EDIT,
  SETTING_LTV_BASE_EDIT,
} from 'domain/permissions/contractPermissions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';

function IconSettingsLtv(props) {
  const { screenId } = props;

  const { checkPermissions } = useCheckPermissions();
  const hasManageLtv = checkPermissions('anyOf', [MANAGE_LTV_EDIT]);
  const hasSettingLtvAdLinking = checkPermissions('anyOf', [SETTING_LTV_AD_LINKING_EDIT]);
  const hasSettingLtvBase = checkPermissions('anyOf', [SETTING_LTV_BASE_EDIT]);

  if (screenId !== LTV_ANALYZE) {
    return <></>;
  }

  const mainComponents =
    <div>
      <div className="d-flex justify-content-center">
        <span className="icon-svg icon-setting" />
      </div>
      <div>分析設定</div>
    </div>

  const clsName = classNames({
    'nav-link': true,
    'nav-link--icon': true,
    'setting': true,
    'disabled': !hasManageLtv && !hasSettingLtvAdLinking && !hasSettingLtvBase,
  })

  if (!hasManageLtv && !hasSettingLtvAdLinking && !hasSettingLtvBase) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip variant="disabled" className="tooltip-common">
            分析設定を行う権限がありません
          </Tooltip>
        }
      >
        <div className={clsName}>
          {mainComponents}
        </div>
      </OverlayTrigger>
    );
  }

  let page = pages[LTV_SETTINGS_PRODUCT];
  if (!hasSettingLtvBase) {
    if (hasSettingLtvAdLinking) { page = pages[LTV_SETTINGS_AD];
    } else { page = pages[LTV_SETTINGS_CLEAR_CSV_ORDERS]; }
  }

  return (
    <Link to={page.path} className={clsName}>
      {mainComponents}
    </Link>
  );
}

IconSettingsLtv.propTypes = {
  screenId: string,
};

IconSettingsLtv.defaultProps = {
  screenId: '',
};

export default IconSettingsLtv;
