import React from 'react';
import { oneOfType, func, object } from 'prop-types';
import isArray from 'lodash/isArray';
import EbisCustomCheckbox from 'views/atoms/checkbox/EbisCustomCheckbox';

function GirdTableColumnCheckbox(props) {
  const { onChange, data } = props;
  const { field, name, variant = '', checked = false, disabled = false } = data;

  const label = isArray(name) ? name.map((item) => <div>{item}</div>) : name;

  return (
    <EbisCustomCheckbox
      id={field}
      label={label}
      checked={checked}
      disabled={disabled}
      variant={variant}
      onChange={onChange}
    />
  );
}

GirdTableColumnCheckbox.propTypes = {
  data: oneOfType([object]),
  onChange: func,
};

GirdTableColumnCheckbox.defaultProps = {
  data: {},
  onChange: () => {},
};

export default React.memo(GirdTableColumnCheckbox);
