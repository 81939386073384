import React from 'react';

const GridTableContext = React.createContext({
  top: null,
  header: [],
  rows: [],
  gridTableRef: null,
  settings: {
    // highlightRows: [],
    // selectedRows: [],
    // heightHeader: null,
    // tableInfo: {
    //   freeze: { widthScroller: 0, isScrolling: false },
    //   main: { widthScroller: 0, isScrolling: false },
    // },
  },
  dispatch: () => {},
});

const GridTableItemContext = React.createContext({
  isFreeze: false,
  headers: [],
  gridTemplateColumns: {},
  gridTemplateRows: null,
  dispatchItem: () => {},
});

export { GridTableContext, GridTableItemContext };
