import React from 'react';

import { ReactComponent as Point1Icon } from 'assets/images/tutorial/point1.svg';
import { ReactComponent as Point2Icon } from 'assets/images/tutorial/point2.svg';
import { ReactComponent as Point3Icon } from 'assets/images/tutorial/point3.svg';
import { ReactComponent as WelcomeEbis1 } from 'assets/images/tutorial/welcome_ebis1.svg';
import { ReactComponent as DashboardIcon } from 'assets/images/navbar/dashboard.svg';
import { ReactComponent as CategoryIcon } from 'assets/images/navbar/category.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search-plus.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar-alt.svg';
import { ReactComponent as FlagIcon } from 'assets/images/navbar/cv_attr.svg';
import { ReactComponent as RandomIcon } from 'assets/images/random.svg';
import { ReactComponent as YenIcon } from 'assets/images/yen-sign.svg';
import NewEbis1 from 'assets/images/tutorial/new_ebis1.png';
import NewEbis2 from 'assets/images/tutorial/new_ebis2.png';
import Step21 from 'assets/images/tutorial/step2_1.png';
import Step22 from 'assets/images/tutorial/step2_2.png';
import Step23 from 'assets/images/tutorial/step2_3.png';
import Step24 from 'assets/images/tutorial/step2_4.png';
import Step25 from 'assets/images/tutorial/step2_5.png';
import Tutorial1 from 'assets/images/tutorial/tutorial_1.png';
import Tutorial2 from 'assets/images/tutorial/tutorial_2.png';
import Step1GIF from 'assets/images/tutorial/step1.gif';

export const WELCOME_TUTORIAL = 'init';
export const WELCOME_EBIS = 'ebis';
export const WELCOME_STEP_1 = 'step1';
export const WELCOME_STEP_2 = 'step2';
export const CHOOSE_STEPS = 'steps';

// Check carousel.jsx for definitions
export const TYPE1 = 'type_1';
export const TYPE2 = 'type_2';
export const TYPE3 = 'type_3';
export const TYPE4 = 'type_4';

const OLD_USER = 'old';
const FIRST_TIME_USER = 'new';

export const selectTutorial = {
  [OLD_USER]: WELCOME_TUTORIAL,
  [FIRST_TIME_USER]: WELCOME_EBIS,
};

export const tutorialPatterns = {
  [WELCOME_TUTORIAL]: {
    prev: null,
    next: null,
  },
  [WELCOME_EBIS]: {
    prev: WELCOME_TUTORIAL,
    next: WELCOME_STEP_1,
  },
  [WELCOME_STEP_1]: {
    prev: WELCOME_EBIS,
    next: WELCOME_STEP_2,
  },
  [WELCOME_STEP_2]: {
    prev: WELCOME_STEP_1,
    next: null,
  },
};

const TutorialConfigs = {
  [WELCOME_TUTORIAL]: {
    0: {
      type: TYPE1,
      id: 'tutorial-1.1',
      header: () => (
        <div className="tutorial-header__type1">新しいアドエビスへようこそ</div>
      ),
      subHeader: () => (
        <div className="tutorial-subheader__type1">
          「誰でも」「簡単に」「スピーディーな」意思決定ができるように、 <br />
          アドエビスは生まれ変わりました。
        </div>
      ),
      closeBtn: {
        isShow: false,
      },
      backBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      checkbox: {
        isShow: false,
        text: '',
      },
      continueBtn: {
        isShow: true,
        text: '次へ',
        class: 'tutorial-modal__button--lg',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          id: 'card-1',
          img: Point1Icon,
          title: '読み込み速度改善！',
          caption: '見たいデータを素早くチェックできるようになりました',
        },
        {
          id: 'card-2',
          img: Point2Icon,
          title: 'UIを刷新！',
          caption:
            '整理されたデザインと大きな文字でデータの確認がしやすくなりました',
        },
        {
          id: 'card-3',
          img: Point3Icon,
          title: '分析動線を再設計！',
          caption: 'スムーズに分析できるようになりました',
        },
      ],
    },
    1: {
      type: TYPE4,
      id: 'tutorial-1.2',
      header: () => (
        <div className="tutorial-header__type1">新しいアドエビスへようこそ</div>
      ),
      subHeader: () => (
        <div className="tutorial-subheader__type1">
          目的のページに遷移しやすいよう、画面構成も刷新。
        </div>
      ),
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: false,
      },
      backBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      continueBtn: {
        isShow: true,
        text: '次へ',
        class: 'tutorial-modal__button--lg',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          title: '分析メニュー',
          caption:
            '「広告」「全トラフィック」の2タブに分かれており、各画面でデータの確認ができます。',
        },
        {
          title: '分析画面',
          caption: '計測結果をグラフや表で確認できます。',
        },
        {
          img: NewEbis1,
          alt: 'tutorial-new-ebis-1',
          caption: '',
        },
        {
          title: '共通機能',
          caption:
            'カレンダーの設定やデータのエクスポートなど、各画面共通の機能です。',
        },
        {
          title: '設定/管理メニュー',
          caption:
            '基本設定や広告登録などの計測に伴う設定や、各種情報の確認ができます。',
        },
      ],
    },
    2: {
      type: TYPE2,
      id: 'tutorial-1.3',
      header: () => (
        <div className="tutorial-header__type1">新しいアドエビスへようこそ</div>
      ),
      subHeader: () => (
        <div className="tutorial-subheader__type1">
          いつでも前の管理画面に戻すことができます。旧バージョンをご利用の場合は、
          <br />
          管理画面左上の「旧バージョンへ切り替える」をクリックしてください。
        </div>
      ),
      checkbox: {
        isShow: true,
        text: '次回以降、ログイン時に表示しない',
      },
      closeBtn: {
        isShow: false,
      },
      backBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      continueBtn: {
        isShow: true,
        text: '新しいアドエビスを始める',
        class: 'tutorial-modal__button--lg',
      },
      replayBtn: {
        isShow: true,
        text: 'チュートリアルを確認',
        class: 'tutorial-modal__button--lg',
      },
      data: [
        {
          img: () => (
            <div className="carousel-type2__img">
              <img
                src={NewEbis2}
                alt="tutorial-1.3-img"
                width="560"
                height="162"
              />
            </div>
          ),
          caption: 'まずは新しいアドエビスをお試しください！',
          txtAlign: 'center',
          txtColor: 'primary',
          fontWeight: 'bold',
        },
      ],
    },
  },
  [WELCOME_EBIS]: {
    0: {
      type: TYPE2,
      id: 'tutorial-2.1',
      header: () => (
        <div className="tutorial-header__type2">アドエビスへようこそ</div>
      ),
      subHeader: () => (
        <div className="mb-30 fs-16">
          アドエビスの基本的な使い方をご紹介します。
        </div>
      ),
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: false,
      },
      backBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      continueBtn: {
        isShow: true,
        text: '次へ',
        class: 'tutorial-modal__button--lg',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          img: () => (
            <div className="carousel-type2__img mb-20">
              <WelcomeEbis1 />
            </div>
          ),
          alt: 'welcome-ebis-logo',
          caption: '',
          txtAlign: '',
          txtColor: '',
          fontWeight: 'bold',
        },
      ],
    },
    1: {
      type: TYPE4,
      id: 'tutorial-2.2',
      header: () => (
        <div className="tutorial-header__type1">
          アドエビスの管理画面をご紹介します
        </div>
      ),
      subHeader: () => (
        <div className="tutorial-subheader__type1">
          {/* ダミーダミーダミーダミーダミーダミー。 <br />
          ダミーダミーダミーダミーダミーダミー。 */}
        </div>
      ),
      checkbox: {
        isShow: true,
        text: '次回以降、ログイン時に表示しない',
      },
      closeBtn: {
        isShow: false,
      },
      backBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      continueBtn: {
        isShow: true,
        text: 'チュートリアルへ',
        class: 'tutorial-modal__button--lg',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          title: '分析メニュー',
          caption:
            '「広告」「全トラフィック」の2タブに分かれており、各画面でデータの確認ができます。',
        },
        {
          title: '分析画面',
          caption: '計測結果をグラフや表で確認できます。',
        },
        {
          img: NewEbis1,
          alt: 'tutorial-new-ebis-1',
          caption: '',
        },
        {
          title: '共通機能',
          caption:
            'カレンダーの設定やデータのエクスポートなど、各画面共通の機能です。',
        },
        {
          title: '設定/管理メニュー',
          caption:
            '基本設定や広告登録などの計測に伴う設定や、各種情報の確認ができます。',
        },
      ],
    },
  },
  [WELCOME_STEP_1]: {
    0: {
      type: TYPE2,
      id: 'tutorial-3.1',
      header: () => (
        <div className="tutorial-header__type1">STEP1.分析ナビの使い方</div>
      ),
      subHeader: () => <></>,
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: true,
      },
      backBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      continueBtn: {
        isShow: true,
        text: '次へ',
        class: 'tutorial-modal__button--md',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          img: () => (
            <div className="carousel-type2__img mt-20 mb-20">
              <img src={Step1GIF} alt="step-1-gif" width="740" height="233" />
            </div>
          ),
          caption:
            '集計対象を「広告」「全トラフィック」のどちらかを選択し、集計対象を切り替え、分析したい粒度・軸に応じてメニューを選択してください。',
          txtAlign: '',
          txtColor: '',
          fontWeight: 'normal',
        },
      ],
    },
    1: {
      type: TYPE3,
      id: 'tutorial-3.2',
      header: () => (
        <div className="tutorial-header__type1">STEP1.分析ナビの使い方</div>
      ),
      subHeader: () => <></>,
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: true,
      },
      backBtn: {
        isShow: true,
        text: '戻る',
        class: 'tutorial-modal__button--md',
      },
      continueBtn: {
        isShow: true,
        text: '次へ',
        class: 'tutorial-modal__button--md',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          title: '分析メニュー（1/2）',
          children: [
            {
              id: 'step-1.1',
              icon: DashboardIcon,
              header: 'ダッシュボード',
              paragraph:
                '1ヶ月間（当日を含まない）の全体成果の現状把握ができます。',
            },
            {
              id: 'step-1.2',
              icon: CategoryIcon,
              header: 'カテゴリ分析',
              paragraph:
                '計測データを「媒体種別、広告グループ」単位で確認でき各カテゴリごとの評価ができます。',
            },
            {
              id: 'step-1.3',
              icon: SearchIcon,
              header: '詳細分析',
              paragraph:
                '最も細かい粒度で確認することができ、広告単位のようなより詳細な施策評価ができます。',
            },
            {
              id: 'step-1.4',
              icon: CalendarIcon,
              header: '期間分析',
              paragraph:
                '時間軸別で確認することができ、期間中の傾向分析ができます。',
            },
          ],
        },
      ],
    },
    2: {
      type: TYPE3,
      id: 'tutorial-3.3',
      header: () => (
        <div className="tutorial-header__type1">STEP1.分析ナビの使い方</div>
      ),
      subHeader: () => <></>,
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: true,
      },
      backBtn: {
        isShow: true,
        text: '戻る',
        class: 'tutorial-modal__button--md',
      },
      continueBtn: {
        isShow: true,
        text: 'STEP2へ',
        class: 'tutorial-modal__button--md',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          title: '分析メニュー（2/2）',
          children: [
            {
              id: 'step-1.1',
              icon: FlagIcon,
              header: 'コンバージョン属性情報',
              paragraph:
                'コンバージョンに至るまでに接触した広告（チャネル）や属性情報を確認することができます。',
            },
            {
              id: 'step-1.2',
              icon: RandomIcon,
              header: 'コンバージョンフロー',
              paragraph:
                'コンバージョンに至るまでに接触した広告（チャネル）の傾向分析をすることができます。',
            },
            {
              id: 'step-1.3',
              icon: YenIcon,
              header: 'コストアロケーション分析',
              paragraph:
                '広告成果とコストから生産性をスコアリングし、抑制可能なコストを算出できます。',
            },
          ],
        },
      ],
    },
  },
  [WELCOME_STEP_2]: {
    0: {
      type: TYPE2,
      id: 'tutorial-4.1',
      header: () => (
        <div className="tutorial-header__type1">STEP2.共通機能の使い方</div>
      ),
      subHeader: () => <></>,
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: true,
      },
      backBtn: {
        isShow: true,
        text: '戻る',
        class: 'tutorial-modal__button--md',
      },
      continueBtn: {
        isShow: true,
        text: '次へ',
        class: 'tutorial-modal__button--md',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          img: () => (
            <div className="carousel-type2__img mt-20 mb-20">
              <img
                src={Step21}
                alt="tutorial-1.3-img"
                width="740"
                height="233"
              />
            </div>
          ),
          caption: (
            <>
              <strong>【期間指定】</strong>
              表示期間指定や比較期間指定ができます。比較することで前後の変化を確認できます。
            </>
          ),
          txtAlign: '',
          txtColor: '',
          fontWeight: 'normal',
        },
      ],
    },
    1: {
      type: TYPE2,
      id: 'tutorial-4.2',
      header: () => (
        <div className="tutorial-header__type1">STEP2.共通機能の使い方</div>
      ),
      subHeader: () => <></>,
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: true,
      },
      backBtn: {
        isShow: true,
        text: '戻る',
        class: 'tutorial-modal__button--md',
      },
      continueBtn: {
        isShow: true,
        text: '次へ',
        class: 'tutorial-modal__button--md',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          img: () => (
            <div className="carousel-type2__img mt-20 mb-20">
              <img
                src={Step22}
                alt="tutorial-1.3-img"
                width="740"
                height="233"
              />
            </div>
          ),
          caption: (
            <>
              <strong>【項目切替】</strong>
              集計軸や表示項目が変更できます。{' '}
            </>
          ),
          txtAlign: '',
          txtColor: '',
          fontWeight: 'normal',
        },
      ],
    },
    2: {
      type: TYPE2,
      id: 'tutorial-4.3',
      header: () => (
        <div className="tutorial-header__type1">STEP2.共通機能の使い方</div>
      ),
      subHeader: () => <></>,
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: true,
      },
      backBtn: {
        isShow: true,
        text: '戻る',
        class: 'tutorial-modal__button--md',
      },
      continueBtn: {
        isShow: true,
        text: '次へ',
        class: 'tutorial-modal__button--md',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          img: () => (
            <div className="carousel-type2__img mt-20 mb-20">
              <img
                src={Step23}
                alt="tutorial-1.3-img"
                width="740"
                height="233"
              />
            </div>
          ),
          caption: (
            <>
              <strong>【フィルタ】</strong>
              広告の絞り込みやコンバージョン名などの検索条件の指定が行えます。
            </>
          ),
          txtAlign: '',
          txtColor: '',
          fontWeight: 'normal',
        },
      ],
    },
    3: {
      type: TYPE2,
      id: 'tutorial-3.3',
      header: () => (
        <div className="tutorial-header__type1">STEP2.共通機能の使い方</div>
      ),
      subHeader: () => <></>,
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: true,
      },
      backBtn: {
        isShow: true,
        text: '戻る',
        class: 'tutorial-modal__button--md',
      },
      continueBtn: {
        isShow: true,
        text: '次へ',
        class: 'tutorial-modal__button--md',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          img: () => (
            <div className="carousel-type2__img mt-20 mb-20">
              <img
                src={Step24}
                alt="tutorial-1.3-img"
                width="740"
                height="233"
              />
            </div>
          ),
          caption: (
            <>
              <strong>【ビュー】</strong>
              フィルタ、表示項目、集計期間を一括保存できます。よく行う分析などを保存しておくと便利です。
            </>
          ),
          txtAlign: '',
          txtColor: '',
          fontWeight: 'normal',
        },
      ],
    },
    4: {
      type: TYPE2,
      id: 'tutorial-4.4',
      header: () => (
        <div className="tutorial-header__type1">STEP2.共通機能の使い方</div>
      ),
      subHeader: () => <></>,
      checkbox: {
        isShow: false,
        text: '',
      },
      closeBtn: {
        isShow: true,
      },
      backBtn: {
        isShow: true,
        text: '戻る',
        class: 'tutorial-modal__button--md',
      },
      continueBtn: {
        isShow: true,
        text: '管理画面へ',
        class: 'tutorial-modal__button--md',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          img: () => (
            <div className="carousel-type2__img mt-20 mb-20">
              <img
                src={Step25}
                alt="tutorial-1.3-img"
                width="740"
                height="233"
              />
            </div>
          ),
          caption: (
            <>
              <strong>【エクスポート】</strong>
              CSVやグラフのダウンロードができます。
            </>
          ),
          txtAlign: '',
          txtColor: '',
          fontWeight: 'normal',
        },
      ],
    },
  },
  [CHOOSE_STEPS]: {
    0: {
      type: null,
      id: 'tutorial-steps-0',
      header: () => (
        <div className="tutorial-header__type1">チュートリアル</div>
      ),
      subHeader: () => (
        <div className="tutorial-subheader__type1">
          基本的な機能の使い方は2つです。
        </div>
      ),
      closeBtn: {
        isShow: true,
      },
      backBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      checkbox: {
        isShow: false,
        text: '',
      },
      continueBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      replayBtn: {
        isShow: false,
        text: '',
        class: '',
      },
      data: [
        {
          id: 'tutorial-step-1',
          img: Tutorial1,
          caption: 'STEP1.分析ナビの使い方',
          type: WELCOME_STEP_1,
        },
        {
          id: 'tutorial-step-2',
          img: Tutorial2,
          caption: 'STEP2.共通機能の使い方',
          type: WELCOME_STEP_2,
        },
      ],
    },
  },
};

export default TutorialConfigs;
