import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { commonSelectors } from 'store/common';
import settingsSelector from 'store/settings/selectors';
import EbisAlert from 'views/atoms/alert';

/**
 * Show/hide based on content.value length
 *
 * See src\store\common\operations.js, watchDownloadNotify
 * This decide the timer for hide alert
 *  Current delay: 5000ms
 */
const DownloadAlertContainer = ({ content, currentPage }) => {
  const [isShow, toggleShow] = React.useState(false);
  const [contentCache, setContentCache] = React.useState({});

  React.useEffect(() => {
    if (content.value?.length > 0) {
      const contentObj = { ...content };
      // If user is already on the page want to go to, do not show the link.
      if (contentObj?.pageTransitionTarget === currentPage) {
        contentObj.pageTransitionTarget = null;
      }
      setContentCache(() => contentObj);
    }
    toggleShow(content.value?.length > 0);
  }, [content]);

  return <EbisAlert content={contentCache} bottom={0} isShow={isShow} />;
};

DownloadAlertContainer.propTypes = {
  content: PropTypes.shape({
    value: PropTypes.string,
    pageTransitionLinkTitle: PropTypes.string,
    pageTransitionTarget: PropTypes.string,
  }).isRequired,
  currentPage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  content: commonSelectors.getDownloadNotify(state),
  currentPage: settingsSelector.getPage(state),
});

export default connect(mapStateToProps)(DownloadAlertContainer);
