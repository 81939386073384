import { bool, func, string } from 'prop-types';
import React from 'react';

const CopyToClipboard = ({ value, className, callback, isShowPopup }) => {
  const copyToClipboard = (str) => {
    const el = document.createElement('input');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'fixed';
    el.style.opacity = 0;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    callback();
    if (isShowPopup) {
      // eslint-disable-next-line no-alert
      alert('クリップボードにコピーしました');
    }
  };
  return (
    <span
      role="button"
      className={`${className} icon-hover`}
      aria-hidden="true"
      onClick={() => copyToClipboard(value)}
    >
      <i className="far fa-clone" />
    </span>
  );
};

CopyToClipboard.propTypes = {
  value: string.isRequired,
  className: string,
  callback: func,
  isShowPopup: bool,
};

CopyToClipboard.defaultProps = {
  className: '',
  callback: () => {},
  isShowPopup: true,
};

export default CopyToClipboard;
