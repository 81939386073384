/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useCallback } from 'react';
import { PropTypes, shape } from 'prop-types';
import DisplayItemsModalContainer from 'views/organism/DisplayItemsModal/DisplayItemsModalContainer';
import 'views/organism/displayitems/displayitems.scss';
import classNames from 'classnames';

const DisplayItems = (props) => {
  const {
    onActive,
    active = false,
    displayitemsApi,
    displayConditions,
    crossDeviceReflectionData,
  } = props;

  const [modalShow, setModalShow] = useState(active);

  const handleHide = useCallback(() => {
    setModalShow(false);
    onActive({ target: '' });
  }, [onActive]);

  const [hover, setHover] = useState(false);
  const toggleHover = () => {
    setHover((prev) => !prev);
  };

  const displayItemClass = classNames({
    'icon-svg': true,
    'icon-display-item': true,
    'display-item--applied': hover === false && !modalShow,
  });

  return (
    <>
      <div
        // css in commonbar.scss
        onClick={() => {
          setModalShow(true);
          onActive({ target: 'DisplayItems' });
        }}
        className={`nav-link nav-link--icon ${modalShow ? 'active' : ''}`}
        aria-hidden="true"
      >
        <div onMouseOver={toggleHover} onMouseOut={toggleHover}>
          <div className="d-flex justify-content-center">
            <span className={displayItemClass} />
          </div>
          <div>項目切替</div>
        </div>
      </div>
      <DisplayItemsModalContainer
        show={modalShow}
        onHide={handleHide}
        displayitemsApi={displayitemsApi}
        displayConditions={displayConditions}
        crossDeviceReflectionData={crossDeviceReflectionData}
      />
    </>
  );
};

DisplayItems.propTypes = {
  onActive: PropTypes.func,
  active: PropTypes.bool,
  displayitemsApi: PropTypes.func.isRequired,
  displayConditions: PropTypes.bool.isRequired,
  crossDeviceReflectionData: shape([]),
};

DisplayItems.defaultProps = {
  onActive: () => {},
  active: false,
  crossDeviceReflectionData: [],
};

export default DisplayItems;
