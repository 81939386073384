import React from 'react';
import { number, func, bool } from 'prop-types';
import { Button } from 'react-bootstrap';
import CountItemSelected from 'views/molecules/CountItemSelected';
import EbisCustomCheckbox from 'views/atoms/checkbox/EbisCustomCheckbox';

function TableControlTabAll(props) {
  const { count, totalItems, emptyData, onSelectedAllPages, onDelete } = props;

  const checkboxVariant = count > 0 && count < totalItems ? 'mixed' : undefined;

  return (
    <div className="d-flex table-control">
      <CountItemSelected count={count}>
        <EbisCustomCheckbox
          id="check-all-pages"
          label="ページ跨ぎで全件を選択"
          checked={count > 0}
          disabled={emptyData}
          variant={checkboxVariant}
          onChange={() => onSelectedAllPages(count <= 0)}
        />
        <Button
          variant="secondary"
          size="sm"
          disabled={count === 0}
          onClick={() => onDelete()}
        >
          削除
        </Button>
      </CountItemSelected>
    </div>
  );
}

TableControlTabAll.propTypes = {
  count: number,
  totalItems: number.isRequired,
  emptyData: bool,
  onSelectedAllPages: func,
  onDelete: func,
};

TableControlTabAll.defaultProps = {
  count: 0,
  emptyData: false,
  onSelectedAllPages: () => {},
  onDelete: () => {},
};

export default TableControlTabAll;
