import helper from 'store/helper';
import types from './types';

const initialState = {
  status: 'idle', // 'idle' | 'redirecting' | 'succeeded',
};

const redirectStart = (state) => {
  return {
    ...state,
    status: 'redirecting',
  };
};

const redirectSucceeded = (state) => {
  return {
    ...state,
    status: 'succeeded',
  };
};

const RedirectReducer = helper.createReducer(initialState, {
  [types.REDIRECT_START]: redirectStart,
  [types.REDIRECT_SUCCEEDED]: redirectSucceeded,
});

export default RedirectReducer;
