const REQUEST_START = 'detail-analyze/REQUEST_START';
const REQUEST_FAILED = 'detail-analyze/REQUEST_FAILED';

const FETCH_REPORT = 'detail-analyze/FETCH_REPORT';
const FETCH_REPORT_SUCCEEDED = 'detail-analyze/FETCH_SUCCEEDED';

const UPDATE_PAGE = 'detail-analyze/UPDATE_PAGE';
const UPDATE_SORT = 'detail-analyze/UPDATE_SORT';

export default {
  REQUEST_START,
  REQUEST_FAILED,

  FETCH_REPORT,
  FETCH_REPORT_SUCCEEDED,

  UPDATE_PAGE,
  UPDATE_SORT,
};
