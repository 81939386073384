const GET_LIST = 'page-management/GET_LIST';
const SET_DATA_LIST = 'page-management/SET_DATA_LIST';
const SET_ERROR_LIST = 'page-management/SET_ERROR_LIST';
const SET_STATUS_LIST = 'page-management/SET_STATUS_LIST';

const GET_DETAIL = 'page-management/GET_DETAIL';
const SET_DATA_DETAIL = 'page-management/SET_DATA_DETAIL';
const SET_ERROR_DETAIL = 'page-management/SET_ERROR_DETAIL';
const SET_STATUS_DETAIL = 'page-management/SET_STATUS_DETAIL';

const UPDATE = 'page-management/UPDATE';
const DELETE = 'page-management/DELETE';
const DOWNLOAD_CSV = 'page-management/DOWNLOAD_CSV';

const UPLOAD_CSV_UPDATE = 'page-management/UPLOAD_CSV_UPDATE';
const UPLOAD_CSV_DELETE = 'page-management/UPLOAD_CSV_DELETE';
const SUBMIT_CSV = 'page-management/SUBMIT_CSV';
const SET_CSV_FILE_NAME = 'page-management/SET_CSV_FILE_NAME';
const UPLOAD_CSV_ERRORS = 'page-management/UPLOAD_CSV_ERRORS';

const EXPORT_CSV_DELETED_PAGES = 'page-management/EXPORT_CSV_DELETED_PAGES';

const UPDATE_SORT = 'page-management/UPDATE_SORT';
const UPDATE_PAGE = 'page-management/UPDATE_PAGE';

export default {
  GET_LIST,
  SET_DATA_LIST,
  SET_ERROR_LIST,
  SET_STATUS_LIST,
  GET_DETAIL,
  SET_DATA_DETAIL,
  SET_ERROR_DETAIL,
  SET_STATUS_DETAIL,
  UPDATE,
  DELETE,
  DOWNLOAD_CSV,
  UPDATE_SORT,
  UPDATE_PAGE,
  UPLOAD_CSV_UPDATE,
  UPLOAD_CSV_DELETE,
  SET_CSV_FILE_NAME,
  EXPORT_CSV_DELETED_PAGES,
  SUBMIT_CSV,
  UPLOAD_CSV_ERRORS,
};
