import * as pathConstant from 'services/routes/constants';
import { AD_MANAGEMENT_MODE } from 'domain/ad-management/consts';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';
import { SYSTEM_SETTING_TAB } from 'domain/system-setting/consts';

const pages = {
  [pathConstant.PAGE_NOT_AUTHORIZED]: {
    key: '403',
    path: '/403',
    endpoint: '',
    screenId: pathConstant.PAGE_NOT_AUTHORIZED,
    documentTitle: '403',
  },
  [pathConstant.PAGE_NOT_FOUND]: {
    key: '404',
    path: '/404',
    endpoint: '',
    screenId: pathConstant.PAGE_NOT_FOUND,
    documentTitle: 'Opss...',
  },
  [pathConstant.DASHBOARD]: {
    key: 'common.pages.page_dashboard',
    path: '/dashboard',
    endpoint: `${pathConstant.USER_ENDPOINT}/dashboard`,

    screenId: pathConstant.DASHBOARD,
  },
  [pathConstant.CATEGORY_ANALYZE]: {
    key: 'common.pages.page_category_analyze',
    path: '/category-analyze',
    endpoint: `${pathConstant.USER_ENDPOINT}/category-analyze`,
    screenId: pathConstant.CATEGORY_ANALYZE,
  },
  [pathConstant.DETAILS_ANALYSIS]: {
    key: 'common.pages.page_details_analysis',
    path: '/details-analysis',
    endpoint: `${pathConstant.USER_ENDPOINT}/detail-analyze`,
    screenId: pathConstant.DETAILS_ANALYSIS,
  },
  [pathConstant.COMPARE_PERIOD]: {
    key: 'common.pages.page_compare_period',
    path: '/compare-period',
    endpoint: `${pathConstant.USER_ENDPOINT}/period-analyze`,
    screenId: pathConstant.COMPARE_PERIOD,
  },
  [pathConstant.LANDING_PAGE_ANALYZE]: {
    key: 'common.pages.page_landing_page_analyze',
    path: '/landing-page-analyze',
    endpoint: `${pathConstant.USER_ENDPOINT}/lp-analyze`,
    screenId: pathConstant.LANDING_PAGE_ANALYZE,
  },
  [pathConstant.CV_ATTRIBUTE]: {
    key: 'common.pages.page_cv_attribute',
    path: '/cv-attribute',
    endpoint: `${pathConstant.USER_ENDPOINT}/cv-attr`,
    screenId: pathConstant.CV_ATTRIBUTE,
  },
  [pathConstant.CV_FLOW]: {
    key: 'common.pages.page_cv_flow',
    path: '/cv-flow',
    endpoint: `${pathConstant.USER_ENDPOINT}/cv-flow`,
    screenId: pathConstant.CV_FLOW,
  },
  [pathConstant.COST_ALLOCATION]: {
    key: 'common.pages.page_cost_allocation',
    path: '/cost-allocation',
    endpoint: `${pathConstant.USER_ENDPOINT}/cost-allocation`,
    screenId: pathConstant.COST_ALLOCATION,
  },
  [pathConstant.LPO_EBIS]: {
    key: 'common.pages.page_lpo_ebis',
    path: '',
    endpoint: '',
    screenId: pathConstant.LPO_EBIS,
  },
  [pathConstant.LOG_EBIS]: {
    key: 'common.pages.page_log_ebis',
    path: '',
    endpoint: '',
    screenId: pathConstant.LOG_EBIS,
  },
  [pathConstant.USER_ANALYSIS]: {
    key: 'common.pages.page_user_analysis',
    path: '',
    endpoint: '',
    screenId: pathConstant.USER_ANALYSIS,
  },
  [pathConstant.USER_MANAGEMENT]: {
    key: 'common.pages.page_user_management',
    path: '/user-management',
    endpoint: `${pathConstant.ACCOUNT_ENDPOINT}/users`,
    screenId: pathConstant.USER_MANAGEMENT,
    documentTitle: 'common.pages.page_user_management',
  },
  [pathConstant.AGENCY_MANAGEMENT]: {
    key: 'common.pages.page_agency_management',
    path: '/agency-management',
    endpoint: `${pathConstant.USER_ENDPOINT}/agency`,
    screenId: pathConstant.AGENCY_MANAGEMENT,
    documentTitle: 'common.pages.page_agency_management',
  },
  [pathConstant.TAG_MANAGEMENT]: {
    key: 'common.pages.page_tag_management',
    path: '/tag-management/:mode',
    pathDefault: `/tag-management/${TAG_MANAGEMENT_TAB.CV}`,
    pathRegex: `/tag-management/((${TAG_MANAGEMENT_TAB.CV})|(${TAG_MANAGEMENT_TAB.PV})|(${TAG_MANAGEMENT_TAB.CONTENT_CATEGORY}))$`,
    endpoint: `${pathConstant.USER_ENDPOINT}/tag-management`,
    screenId: pathConstant.TAG_MANAGEMENT,
    documentTitle: 'common.pages.page_tag_management',
  },
  [pathConstant.AD_MANAGEMENT]: {
    key: 'common.pages.page_ad_management',
    path: '/ad-management/:mode',
    pathDefault: `/ad-management/${AD_MANAGEMENT_MODE.AD}`,
    pathRegex: `/ad-management/((${AD_MANAGEMENT_MODE.AD})|(${AD_MANAGEMENT_MODE.LPO})|(${AD_MANAGEMENT_MODE.LISTING})|(${AD_MANAGEMENT_MODE.ALL})|(${AD_MANAGEMENT_MODE.DELETED}))$`,
    endpoint: `${pathConstant.USER_ENDPOINT}/ad-management`,
    screenId: pathConstant.AD_MANAGEMENT,
    documentTitle: 'common.pages.page_ad_management',
  },
  [pathConstant.AD_MANAGEMENT_MEDIA_TYPE]: {
    key: 'common.pages.page_ad_category_management',
    path: '/ad-management/media',
    endpoint: `${pathConstant.USER_ENDPOINT}/ad-management`,
    screenId: pathConstant.AD_MANAGEMENT_MEDIA_TYPE,
    documentTitle: 'common.pages.page_ad_category_management',
  },
  [pathConstant.AD_MANAGEMENT_AD_GROUP_1]: {
    key: 'common.pages.page_ad_category_management',
    path: '/ad-management/group1',
    endpoint: `${pathConstant.USER_ENDPOINT}/ad-management`,
    screenId: pathConstant.AD_MANAGEMENT_AD_GROUP_1,
    documentTitle: 'common.pages.page_ad_category_management',
  },
  [pathConstant.AD_MANAGEMENT_AD_GROUP_2]: {
    key: 'common.pages.page_ad_category_management',
    path: '/ad-management/group2',
    endpoint: `${pathConstant.USER_ENDPOINT}/ad-management`,
    screenId: pathConstant.AD_MANAGEMENT_AD_GROUP_2,
    documentTitle: 'common.pages.page_ad_category_management',
  },

  [pathConstant.LTV_SETTINGS_PRODUCT]: {
    key: 'common.pages.page_ltv_settings_product',
    path: '/ltv/settings/product',
    endpoint: `${pathConstant.LTV_USER_ENDPOINT}/products`,
    screenId: pathConstant.LTV_SETTINGS_PRODUCT,
    documentTitle: 'common.pages.page_ltv_analyze',
  },
  [pathConstant.LTV_SETTINGS_CONDITION]: {
    key: 'common.pages.page_ltv_settings_condition',
    path: '/ltv/settings/condition',
    endpoint: `${pathConstant.LTV_USER_ENDPOINT}/conditions`,
    screenId: pathConstant.LTV_SETTINGS_CONDITION,
    documentTitle: 'common.pages.page_ltv_analyze',
  },
  [pathConstant.LTV_SETTINGS_AD]: {
    key: 'common.pages.page_ltv_settings_ad',
    path: '/ltv/settings/ad',
    endpoint: `${pathConstant.LTV_USER_ENDPOINT}/product-linking`,
    screenId: pathConstant.LTV_SETTINGS_AD,
    documentTitle: 'common.pages.page_ltv_analyze',
  },
  [pathConstant.LTV_SETTINGS_OFFER]: {
    key: 'common.pages.page_ltv_settings_offer',
    path: '/ltv/settings/offer',
    endpoint: `${pathConstant.LTV_USER_ENDPOINT}/offers`,
    screenId: pathConstant.LTV_SETTINGS_OFFER,
    documentTitle: 'common.pages.page_ltv_analyze',
  },
  [pathConstant.LTV_SETTINGS_OTHER]: {
    key: 'common.pages.page_ltv_settings_other',
    path: '/ltv/settings/other',
    endpoint: `${pathConstant.LTV_USER_ENDPOINT}/settings`,
    screenId: pathConstant.LTV_SETTINGS_OTHER,
    documentTitle: 'common.pages.page_ltv_analyze',
  },
  [pathConstant.LTV_SETTINGS_CLEAR_CSV_ORDERS]: {
    key: 'common.pages.page_ltv_csv_orders',
    path: '/ltv/clear-csv-orders',
    endpoint: `${pathConstant.LTV_USER_ENDPOINT}/orders`,
    screenId: pathConstant.LTV_SETTINGS_CLEAR_CSV_ORDERS,
    documentTitle: 'common.pages.page_ltv_analyze',
  },
  [pathConstant.LTV_ANALYZE]: {
    key: 'common.pages.page_ltv_analyze',
    path: '/ltv-forecast',
    endpoint: `${pathConstant.LTV_USER_ENDPOINT}/analysis`,
    screenId: pathConstant.LTV_ANALYZE,
    documentTitle: 'common.pages.page_ltv_analyze',
  },
  [pathConstant.LTV_DISPLAY_SETTINGS]: {
    key: 'common.pages.page_ltv_display_settings',
    endpoint: `${pathConstant.LTV_USER_ENDPOINT}/display-settings`,
    screenId: pathConstant.LTV_DISPLAY_SETTINGS,
  },
  [pathConstant.LTV_PERIOD_DROP_DOWN]: {
    key: 'common.pages.page_ltv_status',
    endpoint: `${pathConstant.LTV_USER_ENDPOINT}/status`,
    screenId: pathConstant.LTV_PERIOD_DROP_DOWN,
  },
  [pathConstant.EXTERNAL_SERVICE_SETTINGS]: {
    key: 'common.pages.page_external_services',
    path: '/external-services',
    endpoint: `${pathConstant.USER_ENDPOINT}/external-services`,
    screenId: pathConstant.EXTERNAL_SERVICE_SETTINGS,
    documentTitle: 'common.pages.page_external_services',
  },
  [pathConstant.USER_PROFILE]: {
    key: 'common.pages.page_user_profile',
    path: '/user_profile/:tracking_data/:base_cv_record_id',
    pathDefault: `/cv-attribute`,
    pathRegex: `/user_profile/.*`,
    endpoint: `${pathConstant.USER_ENDPOINT}/user_profile`,
    screenId: pathConstant.USER_PROFILE,
  },
  [pathConstant.MEDIA_SYNC_MANAGEMENT]: {
    key: 'common.pages.page_media_sync_management',
    path: '/media-sync-management',
    endpoint: `${pathConstant.USER_ENDPOINT}/media-sync-management`,
    screenId: pathConstant.MEDIA_SYNC_MANAGEMENT,
    documentTitle: 'common.pages.page_media_sync_management',
  },
  [pathConstant.DATA_EXPORT]: {
    key: 'common.pages.data_export',
    path: `/${pathConstant.DATA_EXPORT}`,
    pathDefault: `/${pathConstant.DATA_EXPORT}`,
    pathRegex: `/${pathConstant.DATA_EXPORT}((/setting)|(/setting/\\d+))$`,
    endpoint: `${pathConstant.USER_ENDPOINT}/${pathConstant.DATA_EXPORT}`,
    screenId: pathConstant.DATA_EXPORT,
    documentTitle: 'common.pages.data_export',
  },
  [pathConstant.USAGE_STATUS_SITE]: {
    key: 'common.pages.usage_status',
    path: `/${pathConstant.USAGE_STATUS_SITE}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/${pathConstant.USAGE_STATUS_SITE}`,
    screenId: pathConstant.USAGE_STATUS_SITE,
  },
  [pathConstant.API_MANAGEMENT]: {
    key: 'common.pages.api_management',
    path: `/${pathConstant.API_MANAGEMENT}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/${pathConstant.API_MANAGEMENT}`,
    screenId: pathConstant.API_MANAGEMENT,
    documentTitle: 'common.pages.api_management',
  },
  [pathConstant.CONTACT_DEMAND_MANAGEMENT]: {
    key: 'field.contact_demand.page_title',
    path: `/${pathConstant.CONTACT_DEMAND_MANAGEMENT}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/contact`,
    screenId: pathConstant.CONTACT_DEMAND_MANAGEMENT,
    documentTitle: 'common.pages.page_contact_demand_management',
  },
  [pathConstant.CONTACT_MANAGEMENT]: {
    key: 'field.contact.page_title',
    path: `/${pathConstant.CONTACT_MANAGEMENT}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/contact`,
    screenId: pathConstant.CONTACT_MANAGEMENT,
    documentTitle: 'common.pages.page_contact_management',
  },
  [pathConstant.SYSTEM_SETTING]: {
    key: 'common.pages.system-setting',
    path: '/system-setting/:mode',
    pathDefault: `/system-setting/${SYSTEM_SETTING_TAB.BASIC}`,
    pathRegex: `/system-setting/((${SYSTEM_SETTING_TAB.BASIC})|(${SYSTEM_SETTING_TAB.NS})|(${SYSTEM_SETTING_TAB.CNAME})|(${SYSTEM_SETTING_TAB.MEASUREMENT})|(${SYSTEM_SETTING_TAB.DISPLAY})|(${SYSTEM_SETTING_TAB.NOTIFICATIONS})|(${SYSTEM_SETTING_TAB.COOKIE_PROGRAM}))$`,
    endpoint: `${pathConstant.USER_ENDPOINT}/setup/system`,
    screenId: pathConstant.SYSTEM_SETTING,
    documentTitle: 'common.pages.system-setting',
  },
  [pathConstant.MEASUREMENT_SITE_PAGE_MANAGEMENT]: {
    key: 'common.pages.page_management',
    path: `/${pathConstant.MEASUREMENT_SITE_PAGE_MANAGEMENT}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/${pathConstant.MEASUREMENT_SITE_PAGE_MANAGEMENT}`,
    screenId: pathConstant.MEASUREMENT_SITE_PAGE_MANAGEMENT,
    documentTitle: 'common.pages.page_management',
  },
  [pathConstant.LINE_ADD_FRIEND_SETTINGS]: {
    key: 'common.pages.line_add_friend_settings',
    path: `/${pathConstant.LINE_ADD_FRIEND_SETTINGS}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/line-add-friend/settings`,
    screenId: pathConstant.LINE_ADD_FRIEND_SETTINGS,
    documentTitle: 'common.pages.line_add_friend_settings',
  },
  [pathConstant.IMPRESSION_AD_COST]: {
    key: 'common.pages.page_impression_ad_cost',
    path: `/cost-management/impression-cost`,
    endpoint: `${pathConstant.USER_ENDPOINT}/cost-management/impression-cost`,
    screenId: pathConstant.IMPRESSION_AD_COST,
  },
  [pathConstant.COMMON_VALIDATE]: {
    endpoint: `${pathConstant.USER_ENDPOINT}/common/validate`,
  },
  [pathConstant.LOG_PAGE_ANALYZE]: {
    key: 'common.pages.log_page_analyze',
    path: `/${pathConstant.LOG_PAGE_ANALYZE}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/${pathConstant.LOG_PAGE_ANALYZE}`,
    screenId: pathConstant.LOG_PAGE_ANALYZE,
    documentTitle: 'common.pages.log_page_analyze',
  },
  [pathConstant.LOG_PERIOD_ANALYZE]: {
    key: 'common.pages.log_period_analyze',
    path: `/${pathConstant.LOG_PERIOD_ANALYZE}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/${pathConstant.LOG_PERIOD_ANALYZE}`,
    screenId: pathConstant.LOG_PERIOD_ANALYZE,
    documentTitle: 'common.pages.log_period_analyze',
  },
  [pathConstant.LOG_ROUTE_ANALYZE]: {
    key: 'common.pages.log_route_analyze',
    path: `/${pathConstant.LOG_ROUTE_ANALYZE}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/${pathConstant.LOG_ROUTE_ANALYZE}`,
    screenId: pathConstant.LOG_ROUTE_ANALYZE,
    documentTitle: 'common.pages.log_route_analyze',
  },
  [pathConstant.CAPI_SETTING]: {
    key: 'common.pages.capi_setting',
    path: `/${pathConstant.CAPI_SETTING}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/capi/settings`,
    screenId: pathConstant.CAPI_SETTING,
    documentTitle: 'common.pages.capi_setting',
  },
  [pathConstant.SEARCH_CONSOLE_SETTING]: {
    key: 'common.pages.search_console',
    path: `/${pathConstant.SEARCH_CONSOLE_SETTING}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/search-console/setting`,
    screenId: pathConstant.SEARCH_CONSOLE_SETTING,
    documentTitle: 'common.pages.search_console',
  },
  [pathConstant.LPO_LINK]: {
    key: 'common.pages.lpo_link',
    viewKey: 'common.pages_view.lpo_link',
    path: `/${pathConstant.LPO_LINK}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/${pathConstant.LPO_LINK}`,
    screenId: pathConstant.LPO_LINK,
    documentTitle: 'common.pages.lpo_link',
  },
  [pathConstant.LPO_PERIOD]: {
    key: 'common.pages.lpo_period',
    viewKey: 'common.pages_view.lpo_period',
    path: `/${pathConstant.LPO_PERIOD}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/${pathConstant.LPO_PERIOD}`,
    screenId: pathConstant.LPO_PERIOD,
    documentTitle: 'common.pages.lpo_period',
  },
  [pathConstant.LOG_PAGE_ANALYZE]: {
    key: 'common.pages.log_page_analyze',
    viewKey: 'common.pages_view.log_page_analyze',
    path: `/${pathConstant.LOG_PAGE_ANALYZE}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/log/page-analyze`,
    screenId: pathConstant.LOG_PAGE_ANALYZE,
    documentTitle: 'common.pages.log_page_analyze',
  },
  [pathConstant.LOG_PERIOD_ANALYZE]: {
    key: 'common.pages.log_period_analyze',
    viewKey: 'common.pages_view.log_period_analyze',
    path: `/${pathConstant.LOG_PERIOD_ANALYZE}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/log/period-analyze`,
    screenId: pathConstant.LOG_PERIOD_ANALYZE,
    documentTitle: 'common.pages.log_period_analyze',
  },
  [pathConstant.LOG_ROUTE_ANALYZE]: {
    key: 'common.pages.log_route_analyze',
    path: `/${pathConstant.LOG_ROUTE_ANALYZE}`,
    endpoint: `${pathConstant.USER_ENDPOINT}/log/flow-analyze`,
    screenId: pathConstant.LOG_ROUTE_ANALYZE,
    documentTitle: 'common.pages.log_route_analyze',
  },
  [pathConstant.CSV_SETTING]: {
    key: 'common.pages.csv_setting',
    path: '',
    endpoint: '',
    screenId: pathConstant.CSV_SETTING,
  },
  // for CustomViewModal
  [pathConstant.INFLOW_ANALYSIS]: {
    key: 'common.pages.inflow_analysis',
    viewKey: 'common.pages_view.inflow_analysis',
    path: '',
    endpoint: '',
    screenId: pathConstant.INFLOW_ANALYSIS,
  },
};

export default pages;
