import bookmarkApi from 'services/api/bookmarkApi';

const buildBookmarkRequest = ({ metric, compareMetric, funcId, isDelete }) => {
  const request = {
    metric,
    func_id: funcId,
    status: !isDelete,
  };
  if (compareMetric) {
    request.compare_metric = compareMetric;
  }
  return request;
};

const buildUpsertBookmarkRequest = ({ metric, compareMetric, funcId }) => {
  return buildBookmarkRequest({
    metric,
    compareMetric,
    funcId,
    isDelete: false,
  });
};

const buildDeleteBookmarkRequest = ({ metric, compareMetric, funcId }) => {
  return buildBookmarkRequest({
    metric,
    compareMetric,
    funcId,
    isDelete: true,
  });
};

const buildGetBookmarkRequest = ({ funcId }) => {
  return {
    func_id: funcId,
  };
};

const buildShowChartRequest = ({ funcId }) => {
  return {
    func_id: funcId,
    show_chart: true,
  };
};

const buildHideChartRequest = ({ funcId }) => {
  return {
    func_id: funcId,
    show_chart: false,
  };
};

const saveMetrics = async ({ metric, compareMetric, funcId }) => {
  const request = buildUpsertBookmarkRequest({ metric, compareMetric, funcId });
  return bookmarkApi.save(request);
};

const removeMetrics = async ({ metric, compareMetric, funcId }) => {
  const request = buildDeleteBookmarkRequest({ metric, compareMetric, funcId });
  return bookmarkApi.save(request);
};

const get = async ({ funcId }) => {
  const request = buildGetBookmarkRequest({ funcId });
  return (await bookmarkApi.get(request)).data.data;
};

const showChart = async ({ funcId }) => {
  const request = buildShowChartRequest({ funcId });
  return bookmarkApi.saveChartSetting(request);
};

const hideChart = async ({ funcId }) => {
  const request = buildHideChartRequest({ funcId });
  return bookmarkApi.saveChartSetting(request);
};

export default { saveMetrics, removeMetrics, showChart, hideChart, get };
