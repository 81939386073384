import React from 'react';

export const IconCautionYellow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.001"
    height="14"
    viewBox="0 0 14.001 14"
  >
    <rect
      className="a"
      fill="none"
      width="14"
      height="14"
      transform="translate(0.001)"
    />
    <g transform="translate(-275.125 -70.521)">
      <g transform="translate(275.125 71.321)">
        <path
          className="b"
          fill="#ffd76a"
          d="M514.788,130.922a1.327,1.327,0,0,1-1.144.66H502.287a1.32,1.32,0,0,1-1.144-1.986l5.678-9.832a1.321,1.321,0,0,1,2.284,0l5.678,9.832A1.328,1.328,0,0,1,514.788,130.922Z"
          transform="translate(-500.963 -119.107)"
        />
      </g>
      <path
        className="c"
        fill="#333"
        d="M508.228,130.459a.936.936,0,1,1,.863-.577A.929.929,0,0,1,508.228,130.459Z"
        transform="translate(-226.094 -48.168)"
      />
      <path
        className="c"
        d="M508.221,127.619a.663.663,0,0,1-.656-.607l-.288-3.952a.947.947,0,0,1,.873-1.008.98.98,0,0,1,1.007.943l-.287,4.015a.66.66,0,0,1-.646.608Z"
        transform="translate(-226.093 -47.904)"
      />
    </g>
  </svg>
);

export const IconMultiplePerson = () => (
  <svg
    id="icon_複数ユーザー"
    xmlns="http://www.w3.org/2000/svg"
    width="18.998"
    height="11"
    viewBox="0 0 18.998 11"
  >
    <g
      id="Group_4683"
      data-name="Group 4683"
      transform="translate(393.8 -120.351)"
    >
      <g id="Group_4681" data-name="Group 4681">
        <path
          id="Path_8033"
          data-name="Path 8033"
          d="M-388.358,126.643a3.3,3.3,0,0,0-.71-.338,2.194,2.194,0,0,0,1.268-1.985,2.2,2.2,0,0,0-2.2-2.2,2.2,2.2,0,0,0-2.2,2.2,2.192,2.192,0,0,0,1.268,1.985A4.773,4.773,0,0,0-393.8,131c0,.118.007.233.014.349h4.139c-.009-.165-.015-.292-.015-.416A7.505,7.505,0,0,1-388.358,126.643Z"
          fill="#666"
        />
      </g>
      <g id="Group_4682" data-name="Group 4682">
        <path
          id="Path_8034"
          data-name="Path 8034"
          d="M-380.244,126.643a3.3,3.3,0,0,1,.71-.338,2.194,2.194,0,0,1-1.268-1.985,2.2,2.2,0,0,1,2.2-2.2,2.2,2.2,0,0,1,2.2,2.2,2.193,2.193,0,0,1-1.269,1.985A4.773,4.773,0,0,1-374.8,131c0,.118-.007.233-.014.349h-4.139c.009-.165.015-.292.015-.416A7.505,7.505,0,0,0-380.244,126.643Z"
          fill="#666"
        />
      </g>
    </g>
    <path
      id="Path_8035"
      data-name="Path 8035"
      d="M-379.785,131.351c.008-.138.016-.276.016-.416,0-2.7-1.457-4.968-3.424-5.6a2.613,2.613,0,0,0,1.512-2.366,2.619,2.619,0,0,0-2.62-2.619,2.619,2.619,0,0,0-2.62,2.619,2.613,2.613,0,0,0,1.512,2.366c-1.967.631-3.424,2.9-3.424,5.6,0,.14.008.278.016.416Z"
      transform="translate(393.8 -120.351)"
      fill="#666"
    />
  </svg>
);
