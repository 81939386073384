import React, { useRef } from 'react';
import { objectOf, shape, func } from 'prop-types';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import {
  REPORT_FIELDS,
  EMAIL_TITLE_MODE_DEFAULT,
  EMAIL_TITLE_MODE_CUSTOM,
} from 'domain/data-export/consts';

const { EMAIL, SEND_EMAIL_MODE, EMAIL_TITLE_MODE, EMAIL_TITLE } = REPORT_FIELDS;

const EmailSetting = (props) => {
  const { report, error, onChange } = props;
  const ref = useRef(null);

  const {
    [SEND_EMAIL_MODE]: sendEmailMode,
    [EMAIL_TITLE_MODE]: emailTitleMode,
    [EMAIL_TITLE]: emailTitle,
  } = report;

  const handleChangeRadio = (value, name) => {
    onChange(name, value);
  };

  const handleChangeCheckbox = (name) => {
    onChange(name, !report[name]);
  };

  return (
    <div
      className="data-export-setting__item data-export-setting__item--send-mail mb-30"
      ref={ref}
    >
      <div className="data-export-setting__title">メール設定</div>
      <div className="data-export-setting__table">
        <div className="data-export-setting__row">
          <div className="data-export-setting__cell">
            メール通知
            <DragonBall>
              メールアドレスが設定されている共有ユーザーに送付されます。
            </DragonBall>
          </div>
          <ErrorTooltipWrapper
            isError={!!error[EMAIL]}
            errorMess={error[EMAIL]}
            ref={ref}
          >
            <div className="data-export-setting__cell">
              <div className="d-flex align-items-center">
                <EbisCheckbox
                  name={SEND_EMAIL_MODE}
                  text="レポート作成完了時／エラー時にメール通知する"
                  checked={sendEmailMode}
                  changeSelectBoxValue={handleChangeCheckbox}
                />
              </div>
              <div className="d-flex align-items-center">
                <EbisRadio
                  name={EMAIL_TITLE_MODE}
                  label="メールタイトルとしてレポート名を使用"
                  value={EMAIL_TITLE_MODE_DEFAULT}
                  checked={emailTitleMode === EMAIL_TITLE_MODE_DEFAULT}
                  disabled={!sendEmailMode}
                  onChange={handleChangeRadio}
                />
                <EbisRadio
                  name={EMAIL_TITLE_MODE}
                  label="カスタム"
                  value={EMAIL_TITLE_MODE_CUSTOM}
                  checked={emailTitleMode === EMAIL_TITLE_MODE_CUSTOM}
                  disabled={!sendEmailMode}
                  onChange={handleChangeRadio}
                />
                <FormInput
                  name={EMAIL_TITLE}
                  value={emailTitle}
                  error={error[EMAIL_TITLE]}
                  placeholder="メールタイトルを入力してください"
                  disabled={
                    !sendEmailMode ||
                    emailTitleMode === EMAIL_TITLE_MODE_DEFAULT
                  }
                  onChange={onChange}
                />
              </div>
            </div>
          </ErrorTooltipWrapper>
        </div>
      </div>
    </div>
  );
};

EmailSetting.propTypes = {
  report: objectOf(shape({})).isRequired,
  error: objectOf(shape({})),
  onChange: func,
};

EmailSetting.defaultProps = {
  error: {},
  onChange: () => {},
};

export default React.memo(EmailSetting);
