const REQUEST_START = 'upload/REQUEST_START';
const REQUEST_FAILED = 'upload/REQUEST_FAILED';
const REQUEST_ERROR = 'upload/REQUEST_ERROR';
const UPLOAD_SUCCEEDED = 'upload/UPLOAD_SUCCEEDED';

export default {
  REQUEST_START,
  REQUEST_FAILED,
  REQUEST_ERROR,
  UPLOAD_SUCCEEDED,
};
