import types from 'store/ltv/settings/order/types';

const start = (type) => ({
  type,
});

const succeeded = (type, data) => ({
  type,
  payload: data,
});

const failed = (type, { error }) => ({
  type,
  payload: error,
  scope: error.scope,
});

const upload = (payload) => ({
  type: types.UPLOAD,
  payload,
});
const requestStart = () => start(types.REQUEST_START);
const requestFailed = (error) => failed(types.REQUEST_FAILED, error);
const fetchedList = (data) => succeeded(types.FETCH_LIST_SUCCEEDED, data);
const fetchList = (data) => succeeded(types.FETCH_LIST, data);
const download = (data) => succeeded(types.DOWNLOAD_CSV_ORDER_HISTORY, data);
const uploaded = (data) => succeeded(types.UPLOAD_SUCCEEDED, data);

export default {
  requestStart,
  requestFailed,
  fetchedList,
  fetchList,
  download,
  upload,
  uploaded,
};
