import isArray from 'lodash/isArray';

import loggerConstants from 'store/logger/constant';

const handleError = (error, errorCodes = []) => {
  if (
    !isArray(errorCodes) ||
    errorCodes.length === 0 ||
    !errorCodes.includes(error?.status || null)
  ) {
    return { error, scope: null };
  }

  return { error, scope: loggerConstants.SCOPE_DONT_SHOW };
};

export default handleError;
