import { SettingsApi, AuthApi } from 'services/api/Api';
import pages from 'services/routes/pages';
import { API_MANAGEMENT } from 'services/routes/constants';
import { loadAuth } from 'services/LocalStorageService';

const ApiManagementApiFactory = () => {
  const { endpoint } = pages[API_MANAGEMENT];

  return {
    fetchRefreshToken: async () => SettingsApi.get(`${endpoint}/refresh-key`),
    generateRefreshToken: async () => {
      const token = loadAuth();
      return AuthApi.post('/refresh-token', {
        grant_type: 'implicit',
        access_token: `Bearer ${token?.accessToken}`,
      });
    },
  };
};

const ApiManagementApi = ApiManagementApiFactory();
export default ApiManagementApi;
