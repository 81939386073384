import Api from 'services/api/Api';
import pages from 'services/routes/pages';
import { CV_ATTRIBUTE } from 'services/routes/constants';

const CVAttributeApiFactory = () => {
  const url = pages[CV_ATTRIBUTE].endpoint;

  return {
    url,
    fetchReport: async (data) => {
      return Api.postRequest(url, data);
    },
  };
};

const CVAttributeApi = CVAttributeApiFactory();
export default CVAttributeApi;
