/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loggerSelectors } from 'store/logger';
import settingsSelectors from 'store/settings/selectors';
import actions from 'store/logger/actions';
import { SETTINGS_SPECIFICATIONS_SITE } from 'services/routes/constants';

import ErrorModal from 'views/molecules/modal/ErrorModal';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';

const Title = () => (
  <>
    もう一度お試しいただいてもエラーが発生する場合は、
    <br />
    <a
      href={SETTINGS_SPECIFICATIONS_SITE}
      target="_blank"
      rel="noopener noreferrer"
    >
      サポートセンター
    </a>
    にお問い合わせください。
  </>
);

const SystemErrorModalContainer = ({
  isShow,
  handleConfirm,
  latestError,
  pageId,
}) => {
  const handleErrorConfirm = () => {
    // sendReport();
    handleConfirm();
  };

  // All data from env files are string format
  if (process.env.REACT_APP_DEBUG === 'true') {
    return (
      <ErrorModal
        title={<Title pageId={pageId} />}
        latestError={latestError}
        showErrorNotify={isShow}
        handleConfirm={handleErrorConfirm}
      />
    );
  }

  return (
    <ConfirmModal
      isShow={isShow}
      title="システムエラーが発生しました"
      jsxContent={<Title />}
      confirmText="OK"
      onSubmit={handleErrorConfirm}
      cancelBtn={false}
    />
  );
};

SystemErrorModalContainer.defaultProps = {
  latestError: [],
};

SystemErrorModalContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  isShow: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  latestError: PropTypes.any,
  pageId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isShow: loggerSelectors.getStateShowError(state),
  latestError: loggerSelectors.getLatestErrorRequest(state),
  pageId: settingsSelectors.getPage(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleConfirm: () => dispatch(actions.closeError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SystemErrorModalContainer));
