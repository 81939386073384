import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { string, func, bool, number, node, oneOfType } from 'prop-types';

function GirdTableCellContent(props) {
  const { inlineTruncate, rowId, value, variant, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(rowId, value);
  }, [onClick, rowId, value]);

  switch (variant) {
    case 'btn-link':
      return (
        <Button
          size="sm"
          variant="link"
          className={inlineTruncate ? '' : 'd-inline-block'}
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
        >
          {value}
        </Button>
      );
    case 'link-external':
      return (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </a>
      );
    default:
      return value;
  }
}

GirdTableCellContent.propTypes = {
  inlineTruncate: bool,
  rowId: oneOfType([string, number]),
  value: oneOfType([string, node]),
  variant: string,
  onClick: func,
};

GirdTableCellContent.defaultProps = {
  inlineTruncate: false,
  rowId: '',
  value: '',
  variant: '',
  onClick: () => {},
};

export default GirdTableCellContent;
