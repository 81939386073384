import helper from 'store/helper';
import types from './types';

// Filters
const updateFilters = (items) =>
  helper.createAction(types.UPDATE_FILTERS, { items });

// Display items
const createDisplayItems = (items) =>
  helper.createAction(types.CREATE_DISPLAY_ITEMS, { items });
const createDisplayItemsSucceeded = (items) =>
  helper.createAction(types.CREATE_DISPLAY_ITEMS_SUCCEEDED, { items });
const setStatusDisplayItems = (status) =>
  helper.createAction(types.SET_STATUS_DISPLAY_ITEMS, { status });
const requestFailed = () => helper.createAction(types.REQUEST_FAILED);

const fetchDisplayItems = () => helper.createAction(types.FETCH_DISPLAY_ITEMS);
const fetchDisplayItemsSucceeded = (items) =>
  helper.createAction(types.FETCH_DISPLAY_ITEMS_SUCCEEDED, { items });

const adManagementSettingsActions = {
  updateFilters,
  createDisplayItems,
  setStatusDisplayItems,
  fetchDisplayItems,
  createDisplayItemsSucceeded,
  requestFailed,
  fetchDisplayItemsSucceeded,
};

export default adManagementSettingsActions;
