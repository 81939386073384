/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import commonActions from 'store/common/actions';
import TransitionModal from 'views/molecules/modal/TransitionModal';

const TransitionModalContainer = ({
  headerText,
  imgSrc,
  externalLink,
  onHide,
  onHideHome,
  onHideListing,
  show,
  isNewTab,
  disabled,
  disabledHome,
}) => (
  <TransitionModal
    show={show}
    onHide={onHide}
    onHideHome={onHideHome}
    onHideListing={onHideListing}
    headerText={headerText || undefined}
    imgSrc={imgSrc || undefined}
    externalLink={externalLink || undefined}
    isNewTab={isNewTab || false}
    settingDisabled={disabled}
    settingDisabledHome={disabledHome}
  />
);

TransitionModalContainer.propTypes = {
  headerText: PropTypes.string,
  imgSrc: PropTypes.string,
  externalLink: PropTypes.string,
  onHide: PropTypes.func,
  onHideHome: PropTypes.func,
  onHideListing: PropTypes.func,
  show: PropTypes.bool,
  isNewTab: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledHome: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  show: state.commonState.CommonState.settings.transitionModal.isOpen,
  headerText: state.commonState.CommonState.settings.transitionModal.headerText,
  imgSrc: state.commonState.CommonState.settings.transitionModal.imgSrc,
  externalLink:
    state.commonState.CommonState.settings.transitionModal.externalLink,
  isNewTab: state.commonState.CommonState.settings.transitionModal.isNewTab,
  disabled: state.commonState.CommonState.settings.transitionModal.disabled,
  disabledHome: state.commonState.CommonState.settings.transitionModal.disabledHome,
});

const mapDispatchToProps = (dispatch) => ({
  onHide: (transitionToOldScreenDisabled) => dispatch(commonActions.closeTransitionModal(transitionToOldScreenDisabled)),
  onHideHome: (transitionToOldScreenDisabledHome) => dispatch(commonActions.closeTransitionModalHome(transitionToOldScreenDisabledHome)),
  onHideListing: () => dispatch(commonActions.closeTransitionModalListing()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransitionModalContainer);
