import { useSelector, shallowEqual } from 'react-redux';
import {
  LOG_CONTRACT_PERMISSIONS,
  LOG_CONTRACT_PERMISSIONS_FOR_SETTINGS,
} from 'domain/permissions/contractGroups';
import { resolve } from 'domain/permissions/permissionTypes';
import settingsSelectors from 'store/settings/selectors';
import { getPermissions } from 'store/auth/selectors';
import { PAGES_REPORTS } from 'services/consts';

const useCheckPermissionLog = () => {
  const permissions = useSelector(getPermissions, shallowEqual);
  const page = useSelector(settingsSelectors.getPage, shallowEqual);
  const tab = useSelector(settingsSelectors.getCurrentTab, shallowEqual);

  const logContractPermissions = PAGES_REPORTS.includes(page)
    ? LOG_CONTRACT_PERMISSIONS[tab]
    : LOG_CONTRACT_PERMISSIONS_FOR_SETTINGS;

  return {
    hasContractLog: resolve(logContractPermissions, permissions),
  };
};

export default useCheckPermissionLog;
