import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TABS,
  SYSTEM_SETTING_TAB,
  TABS_CAPI_SINGLE_CONTRACT,
} from 'domain/system-setting/consts';

import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';

import systemSettingSelectors from 'store/system-setting/selectors';
import settingsSelectors from 'store/settings/selectors';
import systemSettingActions from 'store/system-setting/actions';

import FreezeHeadContent from 'views/molecules/FreezeHeadContent/FreezeHeadContent';
import NavTabs from 'views/molecules/Tabs/NavTabs';
import BasicSettingContainer from 'views/pages/system-setting/components/BasicSettingContainer';
import MeasurementSettingContainer from 'views/pages/system-setting/components/MeasurementSettingContainer';
import DisplaySettingContainer from 'views/pages/system-setting/display/DisplaySettingContainer';
import CookieProgram from 'views/pages/system-setting/components/CookieProgram';

import OtherSettingContainer from 'views/pages/system-setting/components/OtherSettingContainer';
import DnsSettingContainer from 'views/pages/system-setting/components/DnsSettingContainer';
import './system-setting.scss';

const renderTab = (tab) => {
  switch (tab) {
    case SYSTEM_SETTING_TAB.NS:
      return <DnsSettingContainer tab={tab} />;
    case SYSTEM_SETTING_TAB.CNAME:
      return <DnsSettingContainer tab={tab} />;
    case SYSTEM_SETTING_TAB.MEASUREMENT:
      return <MeasurementSettingContainer />;
    case SYSTEM_SETTING_TAB.DISPLAY:
      return <DisplaySettingContainer />;
    case SYSTEM_SETTING_TAB.NOTIFICATIONS:
      return <OtherSettingContainer />;
    case SYSTEM_SETTING_TAB.COOKIE_PROGRAM:
      return <CookieProgram />;
    default:
      return <BasicSettingContainer />;
  }
};

function SystemSettingContainer() {
  const currentMode = useSelector(systemSettingSelectors.getTab);
  const mode = useSelector(settingsSelectors.getMode);

  const { isCapiSingleContract } = useCheckPermissionCapi();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentMode === mode) return;

    dispatch(
      systemSettingActions.cleanStates({
        tab: mode,
      })
    );
  }, [dispatch, currentMode, mode]);

  const handleClickSubTab = (tab) => {
    if (tab === currentMode) {
      dispatch(systemSettingActions.getDnsSetting(tab));
    }
  };

  return (
    <div className="system-setting h-100">
      <FreezeHeadContent>
        <NavTabs
          tabs={isCapiSingleContract ? TABS_CAPI_SINGLE_CONTRACT : TABS}
          onClickSubTab={handleClickSubTab}
        />
      </FreezeHeadContent>
      {renderTab(currentMode)}
    </div>
  );
}

export default SystemSettingContainer;
