import React from 'react';
import { func, bool, string } from 'prop-types';
import { Modal, Button, Form } from 'react-bootstrap';
import { downloadTxtFile } from 'services/utils';

function CommonTag({ handleCloseModal, show, commonTag = '' }) {
  const handleFocus = (event) => event.target.select();

  const handleDownloadTxtFile = () => downloadTxtFile(commonTag, 'common_tag');

  return (
    <Modal show={show} centered size="w800" dialogClassName="modal-common-tag">
      <Modal.Header className="justify-content-start">
        <Modal.Title className="d-flex align-items-center">
          <span className="common-tag__title">共通タグ</span>
          <Button
            variant="secondary"
            size="sm"
            className="common-tag__button d-flex align-items-center"
            onClick={handleDownloadTxtFile}
          >
            <span className="icon-fontawesome">
              <i className="far fa-arrow-to-bottom" aria-hidden="true" />
            </span>
            <div>テキストファイル</div>
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="common-tag__note">
          ※同一ページですでに共通タグが設置されている場合は不要です
        </div>
        <Form.Control
          id="textTagCommon"
          as="textarea"
          className="common-tag__content"
          rows="6"
          defaultValue={commonTag}
          onFocus={handleFocus}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="link" onClick={handleCloseModal}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CommonTag.propTypes = {
  handleCloseModal: func.isRequired,
  show: bool.isRequired,
  commonTag: string.isRequired,
};

export default CommonTag;
