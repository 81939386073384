export default {
  // types for list function
  GET_DATA: 'line-add-friend-setting/GET_DATA',
  SET_DATA: 'line-add-friend-setting/SET_DATA',
  SET_STATUS: 'line-add-friend-setting/SET_STATUS',
  SET_ERRORS: 'line-add-friend-setting/SET_ERRORS',
  UPDATE_SORT: 'line-add-friend-setting/UPDATE_SORT',
  DELETE: 'line-add-friend-setting/DELETE',
  // types for setting function
  SET_ERROR_SETTING: 'line-add-friend-setting/SET_ERROR_SETTING',
  SET_DATA_SETTING: 'line-add-friend-setting/SET_DATA_SETTING',
  CREATE: 'line-add-friend-setting/CREATE',
  GET_DETAIL: 'line-add-friend-setting/GET_DETAIL',
  UPDATE: 'line-add-friend-setting/UPDATE',
  GET_MASTERDATA: 'line-add-friend-setting/GET_MASTERDATA',
  SET_MASTERDATA: 'line-add-friend-setting/SET_MASTERDATA',
};
