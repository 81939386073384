import { put, call, select, takeLatest } from 'redux-saga/effects';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';
import { LPO_LINK } from 'services/routes/constants';
import handleError from 'services/error/handleScopeError';
import LpoLinkApi from 'services/api/LpoLinkApi';
import types from 'store/lpo/link/types';
import actions from 'store/lpo/link/actions';
import masterDataActions from 'store/master-data/actions';
import settingsSelector from 'store/settings/selectors';
import commonSelectors from 'store/common/selectors';
import filterSelectors from 'store/filters/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import lpoLinkSelectors from 'store/lpo/link/selectors';
import sharedSagas from 'store/sharedSagas';
import { DisplayItemsSelectors } from 'store/display-items';

function* fetchReport() {
  try {
    yield sharedSagas.waitingRedirect();

    const [page, conversions, period, pagination, sort, priorityAxis] = [
      yield select(settingsSelector.getPage),
      yield select(masterDataSelectors.conversionsSelector),
      yield select(commonSelectors.periodSelector),
      yield select(lpoLinkSelectors.getPagination),
      yield select(lpoLinkSelectors.sortSelector),
      yield select(DisplayItemsSelectors.getDisplayItemPriorityAxis),
    ];

    if (page !== LPO_LINK) return;

    yield put(actions.requestStart());

    if (!conversions || conversions.length <= 0) {
      yield put(masterDataActions.fetchConversions());
    }

    const { data } = yield call(LpoLinkApi.fetchReport, {
      channel: yield select(settingsSelector.getTab),
      start_date: period.start.format('YYYY-MM-DD'),
      end_date: period.end.format('YYYY-MM-DD'),
      dimensions: yield select(lpoLinkSelectors.dimensionsSelectorApi),
      metrics: yield select(lpoLinkSelectors.metricsSelectorApi),
      filters: yield select(filterSelectors.getForApi),
      sum: true,
      limit: TABLE_ROWS_PER_PAGE,
      axis: priorityAxis,
      offset: (pagination.currentPage - 1) * TABLE_ROWS_PER_PAGE,
      ...sort,
    });

    yield put(actions.fetchReportSucceeded(data));
  } catch (error) {
    yield put(actions.requestFailed({ error: handleError(error.response) }));
  }
}

export default function* lpoLinkOperations() {
  yield takeLatest(types.FETCH_REPORT, sharedSagas.skipIfNotReady(fetchReport));
}
