import {
  SYSTEM_SETTING_BASIC,
  SYSTEM_SETTING_MEASUREMENT_OPTION,
  SYSTEM_SETTING_DISPLAY,
  SYSTEM_SETTING_CROSS_DEVICE,
  SETTING_SYSTEM_EDIT,
  ACCOUNT_USAGE_LOG_VIEW,
} from 'domain/permissions/contractPermissions';
import { EBISADMIN } from 'services/consts';
import useCheckPermissions, {
  useGetUserInfo,
} from 'services/custom-hooks/useCheckPermissions';

const useCheckPermissionSystemSetting = () => {
  const { checkPermissions } = useCheckPermissions();
  const [getAgentFlag, getUserId, getGetAccountId] = useGetUserInfo();

  const isAllowedSettingBasic =
    checkPermissions('anyOf', [SYSTEM_SETTING_BASIC]) &&
    !getAgentFlag &&
    (getUserId === EBISADMIN || getUserId === getGetAccountId);
  const isAllowedSettingMeasurementOption = checkPermissions('anyOf', [
    SYSTEM_SETTING_MEASUREMENT_OPTION,
  ]);
  const isAllowedSettingDisplay = checkPermissions('anyOf', [
    SETTING_SYSTEM_EDIT,
    SYSTEM_SETTING_DISPLAY,
  ]);
  const isAllowedSettingCrossDevice = checkPermissions('anyOf', [
    SYSTEM_SETTING_CROSS_DEVICE,
  ]);
  const hasLogPermission = checkPermissions('anyOf', [ACCOUNT_USAGE_LOG_VIEW]);

  return {
    isAllowedSettingBasic,
    isAllowedSettingMeasurementOption,
    isAllowedSettingDisplay,
    isAllowedSettingCrossDevice,
    hasLogPermission,
  };
};

export default useCheckPermissionSystemSetting;
